import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { ADJ_APPELPRE, ADJ_OUTCOME, APPA_PH1_APPELUS, TAB_TYPE, ADJ_PH1_ADJUNOT, LETTER_GSTIN, STATE_LIST, Apa_PH1_PREDEPOAPP, ADJ_ATTEND, ADJ_DEPTPRE, APA_PH1_PAY_MODE, ISSUE_RISK_PARAM, ISSUE_CONCLUSION, ISSUE_CONCLUSION_A, QUARTER, YES_NO, INT_RATE, ISSUING_AUTHORITY_TYPE, MODE_OF_RECEIPT, PAYMENT, MODE_OF_PAYMENT, FORM_TYPE_DETAILS_OF_APPEAL, FORUM_APPEAL_COMMISSIONER, FORUM_APPEAL_APPELLATE_TRIBUNAL, ISSUE_CONCLUSION_COMMISSIONER, MEMORANDOM_OUTCOME, CASE_TYPES, ADJ_WRITPRE, FORUM_APPEAL_APPELLATE_TRIBUNAL_1, ISSUE_CONCLUSION_FAVOURABLE_ONLY, ISSUE_CONCLUSION_COMMISSIONERS, ISSUE_GST, MODE_OF_PH, MODE_OF_TIME_PH, APPEAL_DECISION } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { LitigationServiceService } from '../services/litigation-service.service';
import { InrService } from 'src/app/shared/services/inr.service';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { MasterService } from 'src/app/businessManagement/services/master.service';
import { forkJoin } from 'rxjs';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { UserSettingsService } from 'src/app/businessManagement/services/user-settings.service';
export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  PAN = 'pan',
  GSTIN ='gstin',
  DIVISION ='gstinDiv',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  PERSON_RESPONSIBLE = 'Person Responsible',
}

interface PaymentDetails {
  issueName: any;
  igst: number;
  cgst: number;
  sgst: number;
  cess: number;
  tag: any;
  stage: any;
  challanNo:any;
  dateOfPayment:any;
  type:any;
  markedForPayment: boolean;
}
 
interface ConsultantList{
  srNo: number;
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}
interface tagData {
  tags: string;
}
@Component({
  selector: 'app-appellate-trib',
  templateUrl: './appellate-trib.component.html',
  styleUrls: ['./appellate-trib.component.css']
})
export class AppellateTribComponent implements OnInit {
  parentCaseId: any;
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveCaseId = new EventEmitter<boolean>();
  @Output() enablePrevStageTab: EventEmitter<string[] | null> = new EventEmitter<string[] | null>();
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  @Input() activeStages: string[] | null = null;
  dataSource: any;
  rmvIssueModalRef!: NgbModalRef;
  rmvDetailModalRef!: NgbModalRef;
  issueReferenceRef!: NgbModalRef;
  selectedTab: any;
  selectedPhase: any;
  savedCaseId: any;
  caseId: any;
  action: any;
  companyId: any;
  caseData: any = {};
  selectedField: any;
  selectedFile: any;
  selectedIssues:any
  issueData: any; 
  fileUrl: any;
  navContext: any;
  obj0: any = {};
  maxDate = new Date();
  authTribP1Form!: UntypedFormGroup;
  authTribP2Form!: UntypedFormGroup;
  authTribP3Form!: UntypedFormGroup;
  appaPh1Appelus: { label: string; value: string; }[];
  tabType: { key: string; value: string; }[];
  outcome: { key: string; value: string; }[];
  paymentMode: { key: string; value: string; }[];
  riskParam: { key: string; value: string; }[];
  issueConclusion: { key: string; value: string; }[];
  issueConclusion2: { key: string; value: string; }[];
  whetherAppealPreferred: { label: string; value: boolean; }[];
  furtherAppealDecision: { label: string; value: boolean }[];
  whetherDptAppealPreferred: { label: string; value: boolean; }[];
  adjAttend: { label: string; value: boolean; }[];
  appealPreferred: { label: string; value: boolean; }[];
  adjPHAdjuorNotList: { label: string; value: boolean; }[];
  p1Gstin: { key: string; value: string; }[];
  p1stateList: { stateName: string; stateCode: string; id: number; }[];
  preDepoApplicableList: { label: string; value: boolean; }[];
  quarterList: { key: string; value: string; disabled: string; }[];
  yesNoList: { label: string; value: boolean; }[];
  isSubmitted: boolean = false;
  isDataSaved: boolean = false;
  isUpdate: boolean = false;
  isCaseData: boolean = true;
  isCaseID: boolean = false;
  isadjorNot: boolean = false;
  isIssueAdd: boolean = false;
  isCreateBtn: boolean = false;
  isUpdateBtn: boolean = false;
  isProvAdd: boolean = false;
  isPhase2: boolean = false;
  isPhase3: boolean = false;
  isDisableIcons: boolean = false;
  isUpdateBtnDisable : boolean = false;
  callBookDisabled = false;
  duplicateData:any
  hideData:boolean = false;
  isToggleConsultantDropdown = true;
  isToggleConsultantDropdown2 = true;
  isToggleConsultantDropdown3 = true;
  isToggleSegmentDropdown = true;
  isToggleSegmentDropdown2 = true;


  disablField: boolean = false;
  disablBankChallan: boolean = false;

  hideDiv: boolean = true;

  disabledWeatherAppl: boolean = false;
  disabledWeatherDept: boolean = false;

  isUPbankChallanLoc: boolean = true;
  isDWbankChallanLoc: boolean = true;
  isbankChallanLocFile: boolean = false;
  isbankChallanLocText: boolean = true;

  isUPdrc03CopyLoc: boolean = true;
  isDWdrc03CopyLoc: boolean = true;
  isdrc03CopyLocFile: boolean = false;
  isdrc03CopyLocText: boolean = true;

  isUPappealBookLoc: boolean = true;
  isDWappealBookLoc: boolean = true;
  isappealBookLocFile: boolean = false;
  isappealBookLocText: boolean = true;

  isUPotherAttachmentsLoc1: boolean = true;
  isDWotherAttachmentsLoc1: boolean = true;
  isotherAttachmentsLoc1File: boolean = false;
  isotherAttachmentsLoc1Text: boolean = true;

  isUPcopyOfAckLoc: boolean = true;
  isDWcopyOfAckLoc: boolean = true;
  iscopyOfAckLocFile: boolean = false;
  iscopyOfAckLocText: boolean = true;

  isUPotherAttachmentsLoc2: boolean = true;
  isDWotherAttachmentsLoc2: boolean = true;
  isotherAttachmentsLoc2File: boolean = false;
  isotherAttachmentsLoc2Text: boolean = true;

  isUPphCopyLoc: boolean = true;
  isDWphCopyLoc: boolean = true;
  isphCopyLocFile: boolean = false;
  isphCopyLocText: boolean = true;

  isUPadditionalSubmissionLoc: boolean = true;
  isDWadditionalSubmissionLoc: boolean = true;
  isadditionalSubmissionLocFile: boolean = false;
  isadditionalSubmissionLocText: boolean = true;

  isUPphRecordIfAnyLoc: boolean = true;
  isDWphRecordIfAnyLoc: boolean = true;
  isphRecordIfAnyLocFile: boolean = false;
  isphRecordIfAnyLocText: boolean = true;

  isUPgstatOrderCopyLoc: boolean = true;
  isDWgstatOrderCopyLoc: boolean = true;
  isgstatOrderCopyLocFile: boolean = false;
  isgstatOrderCopyLocText: boolean = true;

  isUPpaymentChallanLoc: boolean = true;
  isDWpaymentChallanLoc: boolean = true;
  ispaymentChallanLocFile: boolean = false;
  ispaymentChallanLocText: boolean = true;


  isonupCreate: boolean = true;
  isondwCreate: boolean = true;

  copyOfAckLocReq: boolean = false;
  gstatOrderCopyLocReq: boolean = false;
  // hideData:boolean = false;


  drc03CopyLocF: any;
  paperBookOfAppealLocF: any;
  otherAttachmentsLoc1F: any;
  copyOfAckLocF: any;
  otherAttachmentsLoc2F: any;
  phCopyLocF: any;
  additionalSubmissionLocF: any;
  phRecordIfAnyLocF: any;
  paymentChallanLocF: any;
  gstatOrderCopyLocF: any;
  getAccessTabData: any;
  selectedFilling: any;
  selectedGstin: any;
  selectedGstinDiv: any;
  selectedState: any;
  selectedStateCode: any;
  selectedStateName: any;
  currentYear: any;
  matches: any;
  userRoleLs: any;
  consultantList : ConsultantList[] = [];
  // duplicateData:any;
  formData: FormData = new FormData();
  filePath: any;
  backStage: any = [];
  autoIssueList:any;
  autoTagList:any;

  // dwddrc03CopyLoc: any;
  // dwdpaperBookOfAppealLoc: any;
  // dwdotherAttachmentsLoc1: any;
  // dwdcopyOfAckLoc: any;
  // dwdotherAttachmentsLoc2: any;
  // dwdgstatOrderCopyLoc: any;
  // dwdpaymentChallanLoc: any;
  finyearData: any[] = [];
  caseItemList: any = [];
  typeOfAuthority = ISSUING_AUTHORITY_TYPE
  orderReceiptMode = MODE_OF_RECEIPT
  detailsOfPreDepositeExpanded: boolean = false
  formOfPayment = PAYMENT
  modeOfPayment = MODE_OF_PAYMENT;
  modeOfPH = MODE_OF_PH;
  modeOfTime = MODE_OF_TIME_PH;
  isCopyOfFormLocFile = false;
  isCopyOfFormLocText = true;
  isCopyOfFormLocDWText = true;
  isCopyOfFormLocUPText = true;

  isCopyOfBankChallanLocFile = false;
  isCopyOfBankChallanLocText = true;
  isCopyOfBankChallanLocDWText = true;
  isCopyOfBankChallanLocUPText = true;

  preDepositeInterestTotal = 0;
  preDepositeTaxTotal = 0;
  preDepositeCgstTotal = 0;
  preDepositeSgstTotal = 0;
  preDepositeCessTotal = 0;
  preDepositePenaltyTotal = 0;
  preDepositeTotal = 0;
  rmvDetailsOfPreDepositeRef: any
  preDepositeDetailItemList: any = [];
  protestDetailItemList: any = [];

  protestDetailsExpanded: boolean = false
  protestInterestTotal = 0;
  protestTaxTotal = 0;
  protestcgstTotal = 0;
  protestsgstTotal = 0;
  protestcessTotal = 0;
  protestPenaltyTotal = 0;
  protestTotal = 0;

  isProtestDetailCopyOfFormLocFile = false;
  isProtestDetailCopyOfFormLocText = true;
  isProtestDetailCopyOfFormLocDWText = true;
  isProtestDetailCopyOfFormLocUPText = true;

  isProtestDetailCopyOfBankChallanLocFile = false;
  isProtestDetailCopyOfBankChallanLocText = true;
  isProtestDetailCopyOfBankChallanLocDWText = true;
  isProtestDetailCopyOfBankChallanLocUPText = true;

  rmvDetailsOfPaymentRef: any
  intCalPopData:any;
  intCalIssueData:any;

  deatilsOfAppealExpanded: boolean = false
  detailsOfAppealFormType = FORM_TYPE_DETAILS_OF_APPEAL
  rmvDetailsOfAppealRef: any
  modeOfAppealOptions = MODE_OF_RECEIPT
  copyOfAckLocDocFile = false;
  copyOfAckLocDocText = true;
  copyOfAckLocDWText = true;
  copyOfAckLocUPText = true;
  isToggleDropdown = true;


  isOtherAttachmentDWText = true;
  isOtherAttachmentUPText = true;
  isOtherAttachmentText = true;
  isOtherAttachmentFile = false;

  appealDetailItemList: any = []

  consultantPanleExpanded: boolean = false
  rmvConsultantModalRef: any
  feesChargedTotal = 0

  isconsultantattachmentsLocText: boolean = true;
  isconsultantDWattachmentsLoc: boolean = true;
  isconsultantattachmentsLocFile: boolean = false;
  isconsultantUPattachmentsLoc: boolean = true;

  disablePhase3: boolean = false
  keepCaseInCallBookOptions = YES_NO;
  consultantChargesItemList: any = []

  forumAppeal = FORUM_APPEAL_APPELLATE_TRIBUNAL_1;
  proceedWithPreferredForumAppeal: any

  disabledOnload: boolean = false;

  detailsOfLiabilityAddmittedAndPaidExpanded: Boolean = false
  rmvLiabilityAddmittedAndPaidModalRef: any
  liabilityAddmittedAndPaidInterestTotal = 0;
  liabilityAddmittedAndPaidCgstTotal = 0;
  liabilityAddmittedAndPaidSgstTotal = 0;
  liabilityAddmittedAndPaidCessTotal = 0;
  liabilityAddmittedAndPaidTaxTotal = 0;
  liabilityAddmittedAndPaidPenaltyTotal = 0;
  liabilityAddmittedAndPaidTotal = 0;

  isLiabilityCopyOfFormLocDocFile: boolean = false;
  isLiabilityCopyOfFormLocDocText: boolean = true;
  isLiabilityCopyOfFormLocDocDWText: boolean = true;
  isLiabilityCopyOfFormLocUPText: boolean = true;

  isLiabilityCopyOfBankChallanLocFile: boolean = false;
  isLiabilityCopyOfBankChallanLocText: boolean = true;
  isLiabilityCopyOfBankChallanLocDWText: boolean = true;
  isLiabilityCopyOfBankChallanLocUPText: boolean = true;

  cumulativeCount = [
    { label: "Cumulative Pre-Deposit", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative payment made under protest", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative payment made for admitted liability", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative Consultant Charges", tax: null, interest: null, penalty: null, total: 0 }
  ]

  detailsOfAllPaymentMadeExpansion: boolean = false

  liabilityAdmittedItemList: any = [];
  modeOfPaymentObjectLiabilityAdmitted = MODE_OF_PAYMENT;
  caseType: any = null;
  caseTypeData = CASE_TYPES;
  disabledWeatherWrit = false;
  writAppPreferred = ADJ_WRITPRE;
  appealBookLocReq: boolean = false
  oldProtestDetailsValues: any = [];
  oldLiabilityDetailValues: any = []
  oldPredepositeDetailsValues: any = []
  oldConsulatntValues: any = []
  prevStage: any
  currentTab = 'apt';
  activeTab: any;
  sorting: {
    caseId: boolean;
    state: boolean;
    panName: boolean;
    gstin: boolean;
    caseLabel: boolean;
    gstinDiv: boolean;
    periodFromDate: boolean;
    periodToDate: boolean;
    personResponsible: boolean;
  } = {
      caseId: false,
      state: false,
      panName: false,
      caseLabel: false,
      gstin:false,
      gstinDiv:false,
      periodFromDate: false,
      periodToDate: false,
      personResponsible: false,
    };
  roleName: any;
  tagDataList: tagData[] = [] ;
  selectedCompanyId: any;
  navData:any;
  intRate: { label: string; value: string }[];
  //  isNotEmpty!: boolean ;


  constructor(
    private router: Router,
    private fBuild: UntypedFormBuilder,
    private litService: LitigationServiceService,
    private toaster: ToasterService,
    private activeSnapShot: ActivatedRoute,
    private commonServ: CommonService,
    private modalService: NgbModal,
    private http: HttpClient,
    private excelService: ExcelService,
    private inrService: InrService,
    private masterService: MasterService,
    private GS: GetterSetterService,
    private US: UserSettingsService,
  ) {
    this.appaPh1Appelus = APPA_PH1_APPELUS
    this.tabType = TAB_TYPE;
    this.outcome = MEMORANDOM_OUTCOME;
    this.paymentMode = APA_PH1_PAY_MODE;
    this.riskParam = ISSUE_RISK_PARAM;
    this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS;
    this.issueConclusion2 = ISSUE_CONCLUSION_A;
    this.whetherAppealPreferred = ADJ_APPELPRE;
    this.furtherAppealDecision = APPEAL_DECISION;
    this.whetherDptAppealPreferred = ADJ_DEPTPRE;
    this.appealPreferred = ADJ_APPELPRE;
    this.adjPHAdjuorNotList = ADJ_PH1_ADJUNOT;
    this.adjAttend = ADJ_ATTEND;
    this.p1Gstin = LETTER_GSTIN;
    this.p1stateList = STATE_LIST;
    this.preDepoApplicableList = Apa_PH1_PREDEPOAPP;
    this.caseId = this.activeSnapShot.snapshot.params.caseId;
    this.action = this.activeSnapShot.snapshot.params.action;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.activeSnapShot.snapshot.params.memorandum == 'true' ? this.caseType = CASE_TYPES.MEMORANDUM : null;
    this.quarterList = QUARTER;
    this.yesNoList = YES_NO;
    this.intRate = INT_RATE;
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    if (this.activeSnapShot.snapshot.params.memorandom) {
      this.outcome = MEMORANDOM_OUTCOME
    }
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.navData = JSON.parse(this.GS.getNavContextData() || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.selectedGstinDiv = this.selectedFilling.gstinDiv;
    this.navContext = this.commonServ.getNavContext();
    this.setStateAutoPop();
    //this.getCompanyTags();
    this.litService.getAccessTabData().subscribe((val) => {
      if (val) {
        this.getAccessTabData = val;
      }
    });
    this.setDate();
    this.personResponsibleData();
    this.consultantData();
    this.selectedTab = 'tab1';
    this.selectedPhase = 'phase0';
    this.formInitialize();
    this.getYears();
    if (this.caseId || this.parentCaseId) {
      this.isCaseID = true;
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.initializeData();
    }
    if (this.action === 'create') {
      this.isCreateBtn = true;
      this.isonupCreate = false;
      this.isondwCreate = false;
      this.isDisableIcons = true;
      this.getCompanyTags(this.selectedGstin);
      this.addIssueInv();
      this.addDetailsOfPreDeposite();
      this.addDetailOfPayment();
      this.addDetailOfAppeal();
      this.addConsultantInv();
      this.addDetailsOfLiabilityAddmittedAndPaid();
      this.createAdjDiv();

      //this.addProv();

      this.isbankChallanLocFile = true;
      this.isbankChallanLocText = false;
      this.isUPbankChallanLoc = false;

      this.isdrc03CopyLocFile = true;
      this.isdrc03CopyLocText = false;
      this.isUPdrc03CopyLoc = false;

      this.isappealBookLocFile = true;
      this.isappealBookLocText = false;
      this.isUPappealBookLoc = false;

      this.isotherAttachmentsLoc1File = true;
      this.isotherAttachmentsLoc1Text = false;
      this.isUPotherAttachmentsLoc1 = false;

      this.iscopyOfAckLocFile = true;
      this.iscopyOfAckLocText = false;
      this.isUPcopyOfAckLoc = false;

      this.isotherAttachmentsLoc2File = true;
      this.isotherAttachmentsLoc2Text = false;
      this.isUPotherAttachmentsLoc2 = false;

      this.isphCopyLocFile = true;
      this.isphCopyLocText = false;
      this.isUPphCopyLoc = false;

      this.isadditionalSubmissionLocFile = true;
      this.isadditionalSubmissionLocText = false;
      this.isUPadditionalSubmissionLoc = false;

      this.isphRecordIfAnyLocFile = true;
      this.isphRecordIfAnyLocText = false;
      this.isUPphRecordIfAnyLoc = false;

      this.isgstatOrderCopyLocFile = true;
      this.isgstatOrderCopyLocText = false;
      this.isUPgstatOrderCopyLoc = false;

      this.ispaymentChallanLocFile = true;
      this.ispaymentChallanLocText = false;
      this.isUPpaymentChallanLoc = false;



    } else if (this.action === 'update') {
      this.createAdjDiv();
      this.addIssueInv();
      this.isUpdateBtn = true;
      this.isDataSaved = true;
      this.disabledOnload = true;
      this.isDisableIcons = true;
    } else if (this.action === 'view') {
      this.isDataSaved = true;
      this.isonupCreate = false;
      this.disabledOnload = true;
      this.isDisableIcons = false;


      this.isPhase2 = true;
      this.isPhase3 = true;

      this.isUPbankChallanLoc = false;
      this.isDWbankChallanLoc = true;

      this.isDWdrc03CopyLoc = true;
      this.isUPdrc03CopyLoc = false;

      this.isDWappealBookLoc = true;
      this.isUPappealBookLoc = false;

      this.isDWotherAttachmentsLoc1 = true;
      this.isUPotherAttachmentsLoc1 = false;

      this.isDWcopyOfAckLoc = true;
      this.isUPcopyOfAckLoc = false;

      this.isDWotherAttachmentsLoc2 = true;
      this.isUPotherAttachmentsLoc2 = false;

      this.isDWphCopyLoc = true;
      this.isUPphCopyLoc = false;

      this.isDWadditionalSubmissionLoc = true;
      this.isUPadditionalSubmissionLoc = false;

      this.isDWphRecordIfAnyLoc = true;
      this.isUPphRecordIfAnyLoc = false;

      this.isDWgstatOrderCopyLoc = true;
      this.isUPgstatOrderCopyLoc = false;

      this.isDWpaymentChallanLoc = true;
      this.isUPpaymentChallanLoc = false;
    }
    this.issueDropdown();
    if (this.caseId != ' ' && this.caseId != undefined) {
      this.isCreateBtn = false;
      this.isUpdateBtn = true;
    } else {
      this.isCreateBtn = true;
      // this.isUpdateBtn = false;

    }
    const userId: any = sessionStorage.getItem('user');
    this.authTribP1Form.controls.personResponsible1.patchValue(userId);

    sessionStorage.setItem('sectionType', this.tabType[3].key);

    this.litService.getUploadFileData().subscribe((res: any) => {
      if (res == "true") {
        const fileData: any = sessionStorage.getItem('fileResponse');
        const selectedfield = sessionStorage.getItem('selectedfield');
        const type = sessionStorage.getItem('type');
        const parseData = JSON.parse(fileData);

        this.getFileUrl(parseData.url, parseData.path, selectedfield, type);
      }
    })


    if (localStorage.getItem('roleName') === 'NoRole') {
      this.roleName = localStorage.getItem('role');
      console.log('roleName 1', this.roleName)
    } else {
      this.roleName = localStorage.getItem('roleName');
      console.log('roleName 2', this.roleName)
    }
  }

  tabChanged(tab: any, check?: any) {
    this.authTribP1Form.patchValue(this.authTribP1Form.value);
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.initializeData();
    } else if (tab === 'tab2') {
      //this.p2validation();

    } else if (tab === 'tab3') {
      // this.p3validation();

    }
  }
  phaseChanged(phase: any, i: any) {
    this.selectedPhase = phase;
    if (phase === ('phase' + i)) {

    }
  }
  checkpreDepositNo(event: any) {
    if (event.target.value === "false") {
      this.disablField = true;
    } else {
      this.disablField = false;
    }
  }
  changePayMode(event: any) {
    if (event.target.value === "ITC") {
      this.disablBankChallan = true;
    } else {
      this.disablBankChallan = false;
    }
  }
  /* Form initialization */
  formInitialize() {
    this.authTribP1Form = this.fBuild.group({
      caseId: [this.caseData?.caseId],
      // gstin: [this.selectedGstin],
      gstin: [this.caseData?.gstin ? this.caseData?.gstin : this.selectedGstin],
      gstinDiv: [this.selectedGstinDiv],
      state: [this.selectedStateName],
      typeOfAuthority1: [this.caseData?.typeOfAuthority1, [Validators.required]],
      din1: [this.caseData?.din1],
      orderReceiptMode1: [this.caseData?.orderReceiptMode1],
      orderAgainst: [this.caseData?.orderAgainst],
      orderNo1: [this.caseData?.orderNo1, [Validators.required]],
      dateOfOrder1: [this.caseData?.dateOfOrder1, [Validators.required]],
      periodFromDate: [this.caseData?.periodFromDate, [Validators.required]],
      periodToDate: [this.caseData?.periodToDate, [Validators.required]],
      dateOfReceiptOfOrder1: [this.caseData?.dateOfReceiptOfOrder1, [Validators.required]],
      prevOrderLoc: [this.caseData?.prevOrderLoc],
      prevPreDepositValue: [this.caseData?.prevPreDepositValue],
      updatedBy: [this.caseData?.updatedBy],
      updatedOn: [this.caseData?.updatedOn],
      // oioNo: [this.caseData?.oioNo, [Validators.required]],
      // dateOfOio: [this.caseData?.dateOfOio],
      // dateOfReceiptOfOio: [this.caseData?.dateOfReceiptOfOio, [Validators.required]],
      // oiaNo: [this.caseData?.oiaNo],
      // dateOfOia: [this.caseData?.dateOfOia],
      // dateOfReceiptOfOia: [this.caseData?.dateOfReceiptOfOia],
      // appealedUs: [this.caseData?.appealedUs],
      dueDateOfAppeal: [this.caseData?.dueDateOfAppeal, [Validators.required]],
      internalDueDate: [this.caseData?.internalDueDate],
      // preDepositApplicable: [this.caseData?.preDepositApplicable, [Validators.required]],
      // preDepositPaid: [this.caseData?.preDepositPaid, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cPreDepositPaid: [this.caseData?.cPreDepositPaid, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      consultant1: [this.caseData?.consultant1],
      personResponsible1: [this.caseData?.personResponsible1, [Validators.required]],
      //dateOfsubmission1: [this.caseData?.dateOfsubmission1],
      // dateOfSubmission: [this.caseData?.dateOfSubmission],//
      // consultant2: [this.caseData?.consultant2],//
      // personResponsible2: [this.caseData?.personResponsible2],//
      issues: new UntypedFormArray([]),
      caseSummary: [this.caseData?.caseSummary],
      internalRemarks: [this.caseData?.internalRemarks],
      provisionDetails: this.fBuild.array([]),
      // igstTax1: [this.caseData?.igstTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cgstTax1: [this.caseData?.cgstTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // sgstTax1: [this.caseData?.sgstTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cessTax1: [this.caseData?.cessTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // totalTax1: [this.action == 'create'? '':this.caseData?.totalTax1, []],
      // igstInterest1: [this.caseData?.igstInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // igstPenalty1: [this.caseData?.igstPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cgstInterest1: [this.caseData?.cgstInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cgstPenalty1: [this.caseData?.cgstPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // sgstInterest1: [this.caseData?.sgstInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // sgstPenalty1: [this.caseData?.sgstPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cessInterest1: [this.caseData?.cessInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cessPenalty1: [this.caseData?.cessPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // paymentMode: [this.caseData?.paymentMode],
      // bankChallanLoc: [this.caseData?.bankChallanLoc],
      // drc03CopyLoc: [this.caseData?.drc03CopyLoc],
      // drc03Tax1: [this.caseData?.drc03Tax1, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Interest1: [this.caseData?.drc03Interest1, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Penalty1: [this.caseData?.drc03Penalty1, [Validators.pattern(/^[1-9]\d*$/)]],
      caseLabel: [this.caseData?.caseLabel, [Validators.required, Validators.maxLength(100), Validators.minLength(5)]],
      predepositDetails: new UntypedFormArray([]),
      protestDetails: new UntypedFormArray([]),
      

      appealBookLoc: new UntypedFormArray([]),
      otherAttachmentsLoc1: new UntypedFormArray([]),

      // copyOfAckLoc: [this.caseData?.copyOfAckLoc],//
      // otherAttachmentsLoc2: [this.caseData?.otherAttachmentsLoc2],
      appealDetails: new UntypedFormArray([]),
      consultantCharges: new UntypedFormArray([]),
      whetherCaseCallBook: [false],


      orderNo3: [this.caseData?.orderNo3],
      dateOfOrder3: [this.caseData?.dateOfOrder3],//
      dateOfReceiptOfOrder3: [this.caseData?.dateOfReceiptOfOrder3],//
      gstatOrderCopyLoc: new UntypedFormArray([]),//

      outcome: [this.caseData?.outcome],//
      typeOfAuthority3: [this.caseData?.typeOfAuthority3],
      din3: [this.caseData?.din3],
      orderReceiptMode3: [this.caseData?.orderReceiptMode3],
      forumOfAppeal: [null],
      whetherAppealPreferred: [this.caseData?.whetherAppealPreferred],//
      furtherAppealDecision: [this.caseData?.furtherAppealDecision], //
      writAppPreferred: [this.caseData?.writAppPreferred],
      whetherDptAppealPreferred: [this.caseData?.whetherDptAppealPreferred],//
      //appealPreferred: [this.caseData?.appealPreferred],
      // paymentChallanLoc: [this.caseData?.paymentChallanLoc],
      orderSummary: [this.caseData?.orderSummary],
      liabilityDetails: new UntypedFormArray([]),
      originalCaseId: [this.caseData?.originalCaseId],
      // igstTax3: [this.caseData?.igstTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cgstTax3: [this.caseData?.cgstTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // sgstTax3: [this.caseData?.sgstTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cessTax3: [this.caseData?.cessTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // totalTax3: [this.caseData?.totalTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // igstInterest3: [this.caseData?.igstInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // igstPenalty3: [this.caseData?.igstPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cgstInterest3: [this.caseData?.cgstInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cgstPenalty3: [this.caseData?.cgstPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // sgstInterest3: [this.caseData?.sgstInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // sgstPenalty3: [this.caseData?.sgstPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cessInterest3: [this.caseData?.cessInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cessPenalty3: [this.caseData?.cessPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // totalInterest3: [this.caseData?.totalInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // totalPenalty3: [this.caseData?.totalPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // totalInterest1: [this.action == 'create'? '':this.caseData?.totalInterest1, []],
      // totalPenalty1: [this.action == 'create'? '':this.caseData?.totalPenalty1, []],
      drc03CopyLocFN: [this.drc03CopyLocF],//
      paperBookOfAppealLocFN: [this.paperBookOfAppealLocF],//
      otherAttachmentsLoc1FN: [this.otherAttachmentsLoc1F],//
      copyOfAckLocFN: [this.copyOfAckLocF],//
      otherAttachmentsLoc2FN: [this.otherAttachmentsLoc2F],//
      phCopyLocFN: [this.phCopyLocF],//
      additionalSubmissionLocFN: [this.additionalSubmissionLocF],//
      phRecordIfAnyLocFN: [this.phRecordIfAnyLocF],//
      gstatOrderCopyLocFN: [this.gstatOrderCopyLocF],//
      paymentChallanLocFN: [this.paymentChallanLocF],//
      // drc03Tax3: [this.caseData?.drc03Tax3, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Interest3: [this.caseData?.drc03Interest3, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Penalty3: [this.caseData?.drc03Penalty3, [Validators.pattern(/^[1-9]\d*$/)]],
      // dwddrc03CopyLoc: [this.dwddrc03CopyLoc],//
      // dwdpaperBookOfAppealLoc: [this.dwdpaperBookOfAppealLoc],//
      // dwdotherAttachmentsLoc1: [this.dwdotherAttachmentsLoc1],//
      // dwdcopyOfAckLoc: [this.dwdcopyOfAckLoc],//
      // dwdotherAttachmentsLoc2: [this.dwdotherAttachmentsLoc2],//
      // dwdgstatOrderCopyLoc: [this.dwdgstatOrderCopyLoc],//
      // dwdpaymentChallanLoc: [this.dwdpaymentChallanLoc],//

      itemList: this.fBuild.array([]),

      igst1Total: [this.caseData?.igst1Total],
      cgst1Total: [this.caseData?.cgst1Total],
      sgst1Total: [this.caseData?.sgst1Total],
      cess1Total: [this.caseData?.cess1Total],
      interest1Total: [this.caseData?.interest1Total],
      penalty1Total: [this.caseData?.penalty1Total],
      totOfTotal1: [this.caseData?.totOfTotal1],
      igst3Total: [this.caseData?.igst3Total],
      cgst3Total: [this.caseData?.cgst3Total],
      sgst3Total: [this.caseData?.sgst3Total],
      cess3Total: [this.caseData?.cess3Total],
      interest3Total: [this.caseData?.interest3Total],
      penalty3Total: [this.caseData?.penalty3Total],
      totOfTotal3: [this.caseData?.totOfTotal3],
      status: [this.caseData?.status],

    });
    this.authTribP1Form.controls['protestDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldProtestDetailsValues = this.authTribP1Form.value['protestDetails'];
        this.setCummulativeCount(this.oldProtestDetailsValues, selectedValue, 'protestDetails');
      });

    this.authTribP1Form.controls['predepositDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldPredepositeDetailsValues = this.authTribP1Form.value['predepositDetails'];
        this.setCummulativeCount(this.oldPredepositeDetailsValues, selectedValue, 'predepositDetails');
      });

    this.authTribP1Form.controls['consultantCharges']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldConsulatntValues = this.authTribP1Form.value['consultantCharges'];
        this.setCummulativeCount(this.oldConsulatntValues, selectedValue, 'consultantCharges');
      });

    this.authTribP1Form.controls['liabilityDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldLiabilityDetailValues = this.authTribP1Form.value['liabilityDetails'];
        this.setCummulativeCount(this.oldLiabilityDetailValues, selectedValue, 'liabilityDetails');
      });

  }
  get p1() { return this.authTribP1Form.controls; }

  getAppealBookLoc(): UntypedFormGroup[] {
    return (this.authTribP1Form.get('appealBookLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getOtherAttachmentLoc1(): UntypedFormGroup[] {
    return (this.authTribP1Form.get('otherAttachmentsLoc1') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getProtestCopyForm(protestCopyForm: UntypedFormGroup): UntypedFormGroup[] {
    return (protestCopyForm.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getProtestBankChallan(protestBankChallan: UntypedFormGroup): UntypedFormGroup[] {
    return (protestBankChallan.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPreDepositCopyForm(preDepoCopyForm: UntypedFormGroup): UntypedFormGroup[] {
    return (preDepoCopyForm.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPreDepositBankChallan(preDepoBankChallan: UntypedFormGroup): UntypedFormGroup[] {
    return (preDepoBankChallan.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAppealAckLoc(ackLocFrmGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (ackLocFrmGrp.get('ackCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAppealOtherAttachLoc(otherAttachFrmGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (otherAttachFrmGrp.get('otherAttachmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getConsultOtherAttachment(consultAttachGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (consultAttachGrp.get('otherAttachmentLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getphCopyLoc(phCopyLocFrmGrp: any): UntypedFormGroup[] {
    return (phCopyLocFrmGrp.get('phCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getAdditionalSub(addSubLocFrmGrp: any): UntypedFormGroup[] {
    return (addSubLocFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhRecord(phRecordFrmGrp: any): UntypedFormGroup[] {
    return (phRecordFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAdjournmentLetter(phAdjournmentLetterFrmGrp: any): UntypedFormGroup[] {
    return (phAdjournmentLetterFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getgstatOrderCopyLoc(): UntypedFormGroup[] {
    return (this.authTribP1Form.get('gstatOrderCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLiabilityCopyForm(liabilitycopyFormLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (liabilitycopyFormLoc.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLiabilityBankChallan(liabilitybankChallanFormLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (liabilitybankChallanFormLoc.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getAppealBookLocLength() {
    let count = 0;
    let predepositFrmArray = this.authTribP1Form.get('appealBookLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getOtherAttachmentLoc1Length() {
    let count = 0;
    let predepositFrmArray = this.authTribP1Form.get('otherAttachmentsLoc1') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getPreDepositCopyFormLength(j?: any) {
    let count = 0;
    let predepositDetailsArray = this.authTribP1Form.get('predepositDetails') as UntypedFormArray;
    let letterLocFrmArray = predepositDetailsArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPreDepositBankChallanLength(j?: any) {
    let count = 0;
    let predepositDetailsArray = this.authTribP1Form.get('predepositDetails') as UntypedFormArray;
    let letterLocFrmArray = predepositDetailsArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getProtestCopyLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.authTribP1Form.get('protestDetails') as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getProtestBankChallanLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.authTribP1Form.get('protestDetails') as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAppealAckLocLength(j?: any) {
    let count = 0;
    let appealDetailsArray = this.authTribP1Form.get('appealDetails') as UntypedFormArray;
    let letterLocFrmArray = appealDetailsArray.controls[j]?.get('ackCopyLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAppealOtherAttachLocLength(j?: any) {
    let count = 0;
    let appealDetailsArray = this.authTribP1Form.get('appealDetails') as UntypedFormArray;
    let otherAttachmentsLocArray = appealDetailsArray.controls[j]?.get('otherAttachmentsLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhCopyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.authTribP1Form.get('itemList') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = phCopyLocArray.controls[j]?.get('phCopyLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAdditionalSubmissionLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.authTribP1Form.get('itemList') as UntypedFormArray;
    let additionalSubmissionLocFrmArray = phCopyLocArray.controls[j]?.get('additionalSubmissionLoc') as UntypedFormArray;
    let lettercount = additionalSubmissionLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getphAdjournmentLetterLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.authTribP1Form.get('itemList') as UntypedFormArray;
    let phAdjournmentLetterLocFrmArray = phCopyLocArray.controls[j]?.get('phAdjournmentLetterLoc') as UntypedFormArray;
    let lettercount = phAdjournmentLetterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhRecordIfAnyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.authTribP1Form.get('itemList') as UntypedFormArray;
    let phRecordIfAnyLocFrmArray = phCopyLocArray.controls[j]?.get('phRecordIfAnyLoc') as UntypedFormArray;
    let lettercount = phRecordIfAnyLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getConsultOtherAttachmentLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.authTribP1Form.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('otherAttachmentLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getgstatOrderCopyLocLength() {
    let count = 0;
    let predepositFrmArray = this.authTribP1Form.get('gstatOrderCopyLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getLiabilityCopyFormLength(j?: any) {
    let count = 0;
    let liabilityDetailsArray = this.authTribP1Form.get('liabilityDetails') as UntypedFormArray;
    let otherAttachmentLocFrmArray = liabilityDetailsArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null );
    return lettercount.length;
  }
  getLiabilityBankChallanLength(j?: any) {
    let count = 0;
    let liabilityDetailsArray = this.authTribP1Form.get('liabilityDetails') as UntypedFormArray;
    let otherAttachmentLocFrmArray = liabilityDetailsArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }

  createAppealBookLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOtherAttachmentLoc1(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPreDepoCopyForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPreDepoBankChallanForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createProtestCopyForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createProtestBankChallanForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAppealAckCopy(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAppealOtherAttach(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createConsultAttach(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createphCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAdditionalSubLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhRecord(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhAdjournmentLetterLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  creategstatOrderCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createLiabilityCopyForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createLiabilityBankChallan(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  openAppealBookLoc(content: any) {
    this.addAppealBookLoc()
    this.modalService.open(content);
  }
  openAdjournmentLetter(content: any, i: any) {
    this.addAdjournmentLetter(i, 0);
    this.modalService.open(content);
  }
  openOtherAttachmentLoc1(content: any) {
    this.addOtherAttachmentLoc1()
    this.modalService.open(content);
  }
  openPreDepoCopyForm(content: any, i: any) {
    this.addPreDepoCopyForm(i, 0);
    this.modalService.open(content)
  }
  openPreDepoBankChallanForm(content: any, i: any) {
    this.addPreDepoBankChallanForm(i, 0);
    this.modalService.open(content)
  }
  openProtestCopyForm(content: any, i: any) {
    this.addProtestCopyForm(i, 0);
    this.modalService.open(content)
  }
  openProtestBankChallan(content: any, i: any) {
    this.addProtestBankChallan(i, 0);
    this.modalService.open(content)
  }
  openAppealAckCopy(content: any, i: any) {
    this.addAppealAckCopy(i, 0);
    this.modalService.open(content);
  }
  openAppealOtherAttach(content: any, i: any) {
    this.addAppealOtherAttach(i, 0);
    this.modalService.open(content);

  }
  openConsultAttach(content: any, i: any) {
    this.addConsultAttach(i, 0);
    this.modalService.open(content);
  }
  openphCopyLoc(content: any, i: any) {
    this.addphCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openAdditionSub(content: any, i: any) {
    this.addAdditionalCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openPhRecord(content: any, i: any) {
    this.addPhRecord(i, 0);
    this.modalService.open(content);
  }
  opengstatOrderCopyLoc(content: any) {
    this.addgstatOrderCopyLoc()
    this.modalService.open(content);
  }
  openLiabilityCopyOfForm(content: any, i: any) {
    this.addLiabilityCopyOfForm(i, 0);
    this.modalService.open(content);
  }
  openLiabilityBankChallan(content: any, i: any) {
    this.addLiabilityCopyOfProBankChalan(i, 0);
    this.modalService.open(content);
  }
  addAppealBookLoc() {
    let appealBookLocArray = this.authTribP1Form.get('appealBookLoc') as UntypedFormArray;
    let i = 0;

    if (appealBookLocArray.length > 0) {
      let length = appealBookLocArray.length;

      while (i < 5 - length) {
        appealBookLocArray.push(this.createAppealBookLoc());

        i++;
      }
    } else {
      while (i < 5) {
        appealBookLocArray.push(this.createAppealBookLoc());

        i++;
      }
    }
  }
  uploadAppealBookLoc(bookLocIndex: any) {
    let bookLocArray = this.authTribP1Form.controls.appealBookLoc as UntypedFormArray;
    if (bookLocArray.controls.length > 0) {
      for (let index = 0; index < bookLocArray.controls.length; index++) {
        if (index === bookLocIndex) {
          let itemListuploadControl = bookLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAppealBookLoc(bookLocIndex: any) {
    let bookLocArray = this.authTribP1Form.controls.appealBookLoc as UntypedFormArray;
    if (bookLocArray.controls.length > 0) {
      for (let index = 0; index < bookLocArray.controls.length; index++) {
        if (index === bookLocIndex) {
          let itemListuploadControl = bookLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadOtherAttachmentLoc1(otherAttachLoc1Index: any) {
    let otherAttachLoc1Array = this.authTribP1Form.controls.otherAttachmentsLoc1 as UntypedFormArray;
    if (otherAttachLoc1Array.controls.length > 0) {
      for (let index = 0; index < otherAttachLoc1Array.controls.length; index++) {
        if (index === otherAttachLoc1Index) {
          let itemListuploadControl = otherAttachLoc1Array.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttachmentLoc1(otherAttachLoc1Index: any) {
    let otherAttachLoc1Array = this.authTribP1Form.controls.otherAttachmentsLoc1 as UntypedFormArray;
    if (otherAttachLoc1Array.controls.length > 0) {
      for (let index = 0; index < otherAttachLoc1Array.controls.length; index++) {
        if (index === otherAttachLoc1Index) {
          let itemListuploadControl = otherAttachLoc1Array.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addOtherAttachmentLoc1() {
    let otherAttachLoc1Array = this.authTribP1Form.get('otherAttachmentsLoc1') as UntypedFormArray;
    let i = 0;

    if (otherAttachLoc1Array.length > 0) {
      let length = otherAttachLoc1Array.length;

      while (i < 5 - length) {
        otherAttachLoc1Array.push(this.createOtherAttachmentLoc1());

        i++;
      }
    } else {
      while (i < 5) {
        otherAttachLoc1Array.push(this.createOtherAttachmentLoc1());

        i++;
      }
    }
  }

  addAdjournmentLetter(j: any, i: any) {
    let dtCaseFrmArray = this.authTribP1Form.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
  }
  uploadAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.authTribP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.authTribP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  addgstatOrderCopyLoc() {
    let orderCopyLocArray = this.authTribP1Form.get('gstatOrderCopyLoc') as UntypedFormArray;
    let i = 0;

    if (orderCopyLocArray.length > 0) {
      let length = orderCopyLocArray.length;

      while (i < 5 - length) {
        orderCopyLocArray.push(this.creategstatOrderCopyLoc());

        i++;
      }
    } else {
      while (i < 5) {
        orderCopyLocArray.push(this.creategstatOrderCopyLoc());

        i++;
      }
    }
  }
  uploadgstatOrderCopyLocFile(gstatOrderCopyLocArrayIndex: any) {
    let gstatOrderCopyLocArray = this.authTribP1Form.controls.gstatOrderCopyLoc as UntypedFormArray;
    if (gstatOrderCopyLocArray.controls.length > 0) {
      for (let index = 0; index < gstatOrderCopyLocArray.controls.length; index++) {
        if (index === gstatOrderCopyLocArrayIndex) {
          let itemListuploadControl = gstatOrderCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }

    // for (let index = 0; index < this.authTribP1Form.value.gstatOrderCopyLoc.length; index++) {
    //   const element = this.authTribP1Form.value.gstatOrderCopyLoc[index];
    //   if (element.fileLoc == '') {
    //     this.isNotEmpty = false;
    //   } else {
    //     this.isNotEmpty = true;
    //     break;
    //   }
    // }
  }
  deletegstatOrderCopyLoc(gstatOrderCopyLocArrayIndex: any) {
    let gstatOrderCopyLocArray = this.authTribP1Form.controls.gstatOrderCopyLoc as UntypedFormArray;
    if (gstatOrderCopyLocArray.controls.length > 0) {
      for (let index = 0; index < gstatOrderCopyLocArray.controls.length; index++) {
        if (index === gstatOrderCopyLocArrayIndex) {
          let itemListuploadControl = gstatOrderCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addPreDepoCopyForm(j: any, i: any) {
    let preDepoArray = this.authTribP1Form.get('predepositDetails') as UntypedFormArray;
    let copyFormArray = preDepoArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormArray.length > 0) {
      let length = copyFormArray.length
      while (i < 5 - length) {
        copyFormArray.push(this.createPreDepoCopyForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormArray.push(this.createPreDepoCopyForm());
        i++;
      }
    }
  }
  addPreDepoBankChallanForm(j: any, i: any) {
    let preDepoArray = this.authTribP1Form.get('predepositDetails') as UntypedFormArray;
    let bankChallanArray = preDepoArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.length > 0) {
      let length = bankChallanArray.length
      while (i < 5 - length) {
        bankChallanArray.push(this.createPreDepoBankChallanForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        bankChallanArray.push(this.createPreDepoBankChallanForm());
        i++;
      }
    }
  }
  addProtestCopyForm(j: any, i: any) {
    let protestDtlsArray = this.authTribP1Form.get('protestDetails') as UntypedFormArray;
    let copyFormArray = protestDtlsArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormArray.length > 0) {
      let length = copyFormArray.length
      while (i < 5 - length) {
        copyFormArray.push(this.createProtestCopyForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormArray.push(this.createProtestCopyForm());
        i++;
      }
    }
  }
  addProtestBankChallan(j: any, i: any) {
    let protestDtlsArray = this.authTribP1Form.get('protestDetails') as UntypedFormArray;
    let bankChallanArray = protestDtlsArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.length > 0) {
      let length = bankChallanArray.length
      while (i < 5 - length) {
        bankChallanArray.push(this.createProtestBankChallanForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        bankChallanArray.push(this.createProtestBankChallanForm());
        i++;
      }
    }
  }
  uploadPreDepoCopyForm(copyListIndex: any, copyAckLocIndex: any) {

    let preDepoDtlsArray = this.authTribP1Form.controls.predepositDetails as UntypedFormGroup;
    let copyFormLocFrmArray = preDepoDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePreDepoCopyForm(copyListIndex: any, copyAckLocIndex: any) {
    let preDepoDtlsArray = this.authTribP1Form.controls.predepositDetails as UntypedFormGroup;
    let copyFormLocFrmArray = preDepoDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadPreDepoBankChallan(copyListIndex: any, copyAckLocIndex: any) {

    let preDepoDtlsArray = this.authTribP1Form.controls.predepositDetails as UntypedFormGroup;
    let bankChallanArray = preDepoDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.controls.length > 0) {
      for (let index = 0; index < bankChallanArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePreDepoBankChallan(copyListIndex: any, copyAckLocIndex: any) {
    let preDepoDtlsArray = this.authTribP1Form.controls.predepositDetails as UntypedFormGroup;
    let bankChallanArray = preDepoDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.controls.length > 0) {
      for (let index = 0; index < bankChallanArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadProtestCopyForm(copyListIndex: any, copyAckLocIndex: any) {

    let protestDtlsArray = this.authTribP1Form.controls.protestDetails as UntypedFormGroup;
    let copyFormLocFrmArray = protestDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteProtestCopyForm(copyListIndex: any, copyAckLocIndex: any) {
    let protestDtlsArray = this.authTribP1Form.controls.protestDetails as UntypedFormGroup;
    let copyFormLocFrmArray = protestDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadProtestBankChallan(copyListIndex: any, copyAckLocIndex: any) {

    let protestDtlsArray = this.authTribP1Form.controls.protestDetails as UntypedFormGroup;
    let bankChallanArray = protestDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.controls.length > 0) {
      for (let index = 0; index < bankChallanArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteProtestBankChallan(copyListIndex: any, copyAckLocIndex: any) {
    let protestDtlsArray = this.authTribP1Form.controls.protestDetails as UntypedFormGroup;
    let bankChallanArray = protestDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.controls.length > 0) {
      for (let index = 0; index < bankChallanArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAppealAckCopy(j: any, i: any) {
    let appealDetails = this.authTribP1Form.get('appealDetails') as UntypedFormArray;
    let ackCopyLocArray = appealDetails.controls[j].get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray.length > 0) {
      let length = ackCopyLocArray.length
      while (i < 5 - length) {
        ackCopyLocArray.push(this.createAppealAckCopy());
        i++;
      }
    }
    else {
      while (i < 5) {
        ackCopyLocArray.push(this.createAppealAckCopy());
        i++;
      }
    }
  }
  uploadAppealAckCopy(ackCopyListIndex: any, ackCopyLocIndex: any) {
    let appealDetails = this.authTribP1Form.get('appealDetails') as UntypedFormArray;
    let ackCopyLocArray = appealDetails.controls[ackCopyListIndex].get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAppealAckCopy(ackCopyListIndex: any, ackCopyLocIndex: any) {
    let appealDetails = this.authTribP1Form.get('appealDetails') as UntypedFormArray;
    let ackCopyLocArray = appealDetails.controls[ackCopyListIndex].get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadAppealOtherAttach(otherAttachListIndex: any, otherAttachLocIndex: any) {
    let appealDetails = this.authTribP1Form.get('appealDetails') as UntypedFormArray;
    let otherAttachLocArray = appealDetails.controls[otherAttachListIndex].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttachLocArray.controls.length > 0) {
      for (let index = 0; index < otherAttachLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = otherAttachLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAppealOtherAttach(otherAttachListIndex: any, otherAttachLocIndex: any) {
    let appealDetails = this.authTribP1Form.get('appealDetails') as UntypedFormArray;
    let otherAttachLocArray = appealDetails.controls[otherAttachListIndex].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttachLocArray.controls.length > 0) {
      for (let index = 0; index < otherAttachLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = otherAttachLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAppealOtherAttach(j: any, i: any) {
    let appealDetails = this.authTribP1Form.get('appealDetails') as UntypedFormArray;
    let otherAttachLocArray = appealDetails.controls[j].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttachLocArray.length > 0) {
      let length = otherAttachLocArray.length
      while (i < 5 - length) {
        otherAttachLocArray.push(this.createAppealOtherAttach());
        i++;
      }
    }
    else {
      while (i < 5) {
        otherAttachLocArray.push(this.createAppealOtherAttach());
        i++;
      }
    }
  }
  addConsultAttach(j: any, i: any) {
    let consultFrmArray = this.authTribP1Form.get('consultantCharges') as UntypedFormArray;
    let consultAttachFrmArray = consultFrmArray.controls[j].get('otherAttachmentLoc') as UntypedFormArray;

    if (consultAttachFrmArray.length > 0) {
      let length = consultAttachFrmArray.length
      while (i < 5 - length) {
        consultAttachFrmArray.push(this.createConsultAttach());
        i++;
      }
    }
    else {
      while (i < 5) {
        consultAttachFrmArray.push(this.createConsultAttach());
        i++;
      }
    }
  }
  uploadConsultAttach(consultAttachListIndex: any, consultAttachLocIndex: any) {
    let consultArray = this.authTribP1Form.controls.consultantCharges as UntypedFormGroup;
    let consultAttachLocFrmArray = consultArray.controls[consultAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;

    if (consultAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < consultAttachLocFrmArray.controls.length; index++) {
        if (index === consultAttachLocIndex) {
          let itemListuploadControl = consultAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteConsultAttach(consultAttachListIndex: any, consultAttachLocIndex: any) {
    let consultArray = this.authTribP1Form.controls.consultantCharges as UntypedFormGroup;
    let consultAttachLocFrmArray = consultArray.controls[consultAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;
    if (consultAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < consultAttachLocFrmArray.controls.length; index++) {
        if (index === consultAttachLocIndex) {
          let itemListuploadControl = consultAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addphCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.authTribP1Form.get('itemList') as UntypedFormArray;
    let phCopyLocFrmArray = dtCaseFrmArray.controls[j].get('phCopyLoc') as UntypedFormArray;

    if (phCopyLocFrmArray.length > 0) {
      let length = phCopyLocFrmArray.length
      while (i < 5 - length) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
  }
  uploadPhCopy(phCopyListIndex: any, phCopyLocIndex: any) {

    let itemListsArray = this.authTribP1Form.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    let itemListsArray = this.authTribP1Form.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAdditionalCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.authTribP1Form.get('itemList') as UntypedFormArray;
    let additionalSubLocFrmArray = dtCaseFrmArray.controls[j].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.length > 0) {
      let length = additionalSubLocFrmArray.length
      while (i < 5 - length) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
  }
  uploadAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.authTribP1Form.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.authTribP1Form.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;
    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addPhRecord(j: any, i: any) {
    let dtCaseFrmArray = this.authTribP1Form.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
  }
  uploadPhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.authTribP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.authTribP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addLiabilityCopyOfForm(j: any, i: any) {
    let liabilityFrmArray = this.authTribP1Form.get('liabilityDetails') as UntypedFormArray;
    let copyFormLocFrmArray = liabilityFrmArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.length > 0) {
      let length = copyFormLocFrmArray.length
      while (i < 5 - length) {
        copyFormLocFrmArray.push(this.createLiabilityCopyForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormLocFrmArray.push(this.createLiabilityCopyForm());
        i++;
      }
    }
  }
  addLiabilityCopyOfProBankChalan(j: any, i: any) {
    let liabilityFrmArray = this.authTribP1Form.get('liabilityDetails') as UntypedFormArray;
    let proBankChallanLocFrmArray = liabilityFrmArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (proBankChallanLocFrmArray.length > 0) {
      let length = proBankChallanLocFrmArray.length
      while (i < 5 - length) {
        proBankChallanLocFrmArray.push(this.createLiabilityBankChallan());
        i++;
      }
    }
    else {
      while (i < 5) {
        proBankChallanLocFrmArray.push(this.createLiabilityBankChallan());
        i++;
      }
    }
  }
  uploadLiabilityCopyForm(copyListIndex: any, copyAckLocIndex: any) {

    let liabilityDtlsArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormGroup;
    let copyFormLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLiabilityCopyForm(copyListIndex: any, copyAckLocIndex: any) {
    let liabilityDtlsArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormGroup;
    let copyFormFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadLiabilityBankChallanForm(copyListIndex: any, copyAckLocIndex: any) {

    let liabilityDtlsArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanLocFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLiabilityBankChallanForm(copyListIndex: any, copyAckLocIndex: any) {
    let liabilityDtlsArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanLocFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  p2validation() {
    var appealDetailsArr = this.authTribP1Form.controls.appealDetails as UntypedFormArray;
    if (appealDetailsArr.controls.length > 0) {
      for (var i = 0; i < appealDetailsArr.controls.length; i++) {
        var issues1Control = appealDetailsArr.controls[i] as UntypedFormGroup;
        if (issues1Control.controls.appealRefNo.value === null || issues1Control.controls.appealRefNo.value === '') {
          issues1Control.controls.appealRefNo.setErrors({ 'required': true });
        } else {
          issues1Control.controls.appealRefNo.setErrors(null);
        }
        if (issues1Control.controls.filingAppealDate.value === null || issues1Control.controls.filingAppealDate.value === '') {
          issues1Control.controls.filingAppealDate.setErrors({ 'required': true });
        } else {
          issues1Control.controls.filingAppealDate.setErrors(null);
        }
      }
    }
    return true;

  }
  p3validation() {
    var issues1Array = this.authTribP1Form.controls.issues as UntypedFormArray
    if (issues1Array.controls.length > 0) {
      for (var i = 0; i < issues1Array.controls.length; i++) {
        var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        if (issues1Control.controls.status.value === null || issues1Control.controls.status.value === "null" || issues1Control.controls.status.value === '' ||
          issues1Control.controls.status.value === "null") {
          issues1Control.controls.status.setErrors({ 'required': true });
        } else {
          issues1Control.controls.status.setErrors(null);
        }
      }
    }
    if (this.authTribP1Form.value['orderNo3'] === null) {
      this.authTribP1Form.controls['orderNo3'].setErrors({ 'required': true });
    }
    if (this.authTribP1Form.value['dateOfOrder3'] === null) {
      this.authTribP1Form.controls['dateOfOrder3'].setErrors({ 'required': true });
    }
    if (this.authTribP1Form.value['dateOfReceiptOfOrder3'] === null) {
      this.authTribP1Form.controls['dateOfReceiptOfOrder3'].setErrors({ 'required': true });
    }
    if (this.authTribP1Form.value['typeOfAuthority3'] === null) {
      this.authTribP1Form.controls['typeOfAuthority3'].setErrors({ 'required': true });
    }

    if (this.authTribP1Form.value['outcome'] === null) {
      this.authTribP1Form.controls['outcome'].setErrors({ 'required': true });

    }
    if (
      this.authTribP1Form.value['furtherAppealDecision'] === null ||
      this.authTribP1Form.value['furtherAppealDecision'] === 'null' ||
      this.authTribP1Form.value['furtherAppealDecision'] === '' ||
      this.authTribP1Form.value['furtherAppealDecision'] === undefined
    ) {
      this.authTribP1Form.controls['furtherAppealDecision'].setErrors({ 'required': true });
    }
    if (this.authTribP1Form.value['forumOfAppeal'] === null || this.authTribP1Form.value['forumOfAppeal'] === "null" || this.authTribP1Form.value['forumOfAppeal'] == "" || this.authTribP1Form.value['forumOfAppeal'] === undefined) {
      this.authTribP1Form.controls['forumOfAppeal'].setErrors({ 'required': true });
    }
    return true;
    // if (this.authTribP1Form.value['gstatOrderCopyLoc'] == null || this.authTribP1Form.value['gstatOrderCopyLoc'] === '') {
    //   this.gstatOrderCopyLocReq = true;
    //   //this.authTribP1Form.controls['gstatOrderCopyLoc'].setErrors({'required': true});
    // }else {
    //   this.gstatOrderCopyLocReq = false;
    //   //this.authTribP1Form.controls['gstatOrderCopyLoc'].setErrors(null);
    // }
    // return !this.gstatOrderCopyLocReq;
  }

  aptDivArr(): UntypedFormArray {
    return this.authTribP1Form.get("itemList") as UntypedFormArray
  }

  addDetailsOfPreDeposite() {
    let depositDetailsFrmGrp = this.detailOfPreDeposite();
    (this.authTribP1Form.get('predepositDetails') as UntypedFormArray).push(depositDetailsFrmGrp);
  }
  openRemoveDetailsOfPreDepositePop(content: any) {
    this.rmvDetailsOfPreDepositeRef = this.modalService.open(content);
  }
  detailOfPreDeposite(data: any = null) {
    return this.fBuild.group({
      issueName: [data ? data?.issueName : ''],
      tag: [data ? data?.tag : ''],
      dateOfPayment: [data ? data?.dateOfPayment : ''],
      markedForPayment: [data ? data?.markedForPayment : ''],
      formOfPayment: [data ? data?.formOfPayment : ''],
      modeOfPayment: [data ? data?.modeOfPayment : ''],
      challanNo: [data ? data?.challanNo : ''],
      accountingRef: [data ? data?.accountingRef : ' ', [Validators.maxLength(100)]],
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: [data ? data?.igst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst: [data ? data?.cgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst: [data ? data?.sgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess: [data ? data?.cess : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
    })
  }

  getDetailOfPaymentArr(): UntypedFormGroup[] {
    return (this.authTribP1Form.get('protestDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  rmPaymentUnderProtest(i: number) {
    (this.authTribP1Form.get('protestDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfPaymentRef.close();
    this.protestDetailsChanged();
  }

  addDetailOfPayment() {
    let protestDetailsFrmGrp = this.detailOfprotestDetails();
    (this.authTribP1Form.get('protestDetails') as UntypedFormArray).push(protestDetailsFrmGrp);
  }

  openRemoveDetailsOfPaymentPop(content: any) {
    this.rmvDetailsOfPaymentRef = this.modalService.open(content);

  }


  detailOfprotestDetails(data: any = null) {
    return this.fBuild.group({
      issueName: [data ? data?.issueName : null],
      tag: [data ? data?.tag : null],
      dateOfPayment: [data ? data?.dateOfPayment : null],      
      markedForPayment: [data ? data?.markedForPayment : null],      
      formOfPayment: [data ? data?.formOfPayment : null],
      modeOfPayment: [data ? data?.modeOfPayment : null],
      challanNo: [data ? data?.challanNo : null],
      accountingRef: [data ? data?.accountingRef : null, [Validators.maxLength(100)]],
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: [data ? data?.igst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst: [data ? data?.cgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst: [data ? data?.sgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess: [data ? data?.cess : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
    })
  }

  rmDetailsOfPreDeposite(i: number) {
    (this.authTribP1Form.get('predepositDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfPreDepositeRef.close();
    this.preDepositeChanged();
  }

  detailsOfPreDepositeArr() {
    return (this.authTribP1Form.get('predepositDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initializePreDepositeList() {
    if (this.caseData.predepositDetails !== undefined) {
      this.caseData.predepositDetails.forEach((predepositDetails: any) => {
        let depositDetailsFrmGrp: UntypedFormGroup = this.detailOfPreDeposite(predepositDetails);
        (this.authTribP1Form.get('predepositDetails') as UntypedFormArray).push(depositDetailsFrmGrp);

        if (predepositDetails.copyOfFormLoc) {
          predepositDetails.copyOfFormLoc.forEach((not: any) => {
            let copyFormGrp: UntypedFormGroup = this.createPreDepoCopyForm(not);
            (depositDetailsFrmGrp.get('copyOfFormLoc') as UntypedFormArray).push(copyFormGrp);
          });
        }

        if (predepositDetails.copyOfBankChallanLoc) {
          predepositDetails.copyOfBankChallanLoc.forEach((not: any) => {
            let bankChallanFrmGrp: UntypedFormGroup = this.createPreDepoBankChallanForm(not);
            (depositDetailsFrmGrp.get('copyOfBankChallanLoc') as UntypedFormArray).push(bankChallanFrmGrp);
          });
        }

      });
    }
  }

  initializePaymentDetailList() {
    if (this.caseData.protestDetails !== undefined) {
      this.caseData.protestDetails.forEach((protestDetails: any) => {
        let depositDetailsFrmGrp: UntypedFormGroup = this.detailOfPreDeposite(protestDetails);
        (this.authTribP1Form.get('protestDetails') as UntypedFormArray).push(depositDetailsFrmGrp);

        if (protestDetails.copyOfFormLoc) {
          protestDetails.copyOfFormLoc.forEach((not: any) => {
            let copyFormGrp: UntypedFormGroup = this.createProtestCopyForm(not);
            (depositDetailsFrmGrp.get('copyOfFormLoc') as UntypedFormArray).push(copyFormGrp);
          });
        }

        if (protestDetails.copyOfBankChallanLoc) {
          protestDetails.copyOfBankChallanLoc.forEach((not: any) => {
            let bankChallanFrmGrp: UntypedFormGroup = this.createProtestBankChallanForm(not);
            (depositDetailsFrmGrp.get('copyOfBankChallanLoc') as UntypedFormArray).push(bankChallanFrmGrp);
          });
        }

      });
    }
  }

  setPreDepositelDetailList() {
    this.authTribP1Form.value.predepositDetails.forEach((data: any, i: number) => {
      if (!data.copyOfFormLoc) {
        data.copyOfFormLoc = this.preDepositeDetailItemList[i]?.copyOfFormLoc;
      }
      if (!data.copyOfBankChallanLoc) {
        data.copyOfBankChallanLoc = this.preDepositeDetailItemList[i]?.copyOfBankChallanLoc;
      }
    })
  }

  savePortestlDetailList() {
    const appealDetailList = this.authTribP1Form.value.protestDetails;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.protestDetailItemList[i]) {
          this.protestDetailItemList.push({});
        }
        if (item.copyOfFormLoc) {
          this.protestDetailItemList[i].copyOfFormLoc = item.copyOfFormLoc;
        }
        if (item.copyOfBankChallanLoc) {
          this.protestDetailItemList[i].copyOfBankChallanLoc = item.copyOfBankChallanLoc;

        }
      })
    }
    this.authTribP1Form.controls.protestDetails.patchValue(appealDetailList);
  }
  setPortestlDetailList() {
    this.authTribP1Form.value.protestDetails.forEach((data: any, i: number) => {
      if (!data.copyOfFormLoc) {
        data.copyOfFormLoc = this.protestDetailItemList[i]?.copyOfFormLoc;
      }
      if (!data.copyOfBankChallanLoc) {
        data.copyOfBankChallanLoc = this.protestDetailItemList[i]?.copyOfBankChallanLoc;
      }
    })
  }

  uploadCopyOfFormLoc(i: any) {
    var predepositDetailsArray = this.authTribP1Form.controls.predepositDetails as UntypedFormArray
    if (predepositDetailsArray.controls.length > 0) {
      for (var index = 0; index < predepositDetailsArray.controls.length; index++) {
        if (index === i) {
          var predepositDetailsControl = predepositDetailsArray.controls[index] as UntypedFormGroup;
          predepositDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.isCopyOfFormLocFile = true;
    this.isCopyOfFormLocText = false;
    this.isCopyOfFormLocDWText = true;
    this.isCopyOfFormLocUPText = false;
  }

  uploadCopyOfBankChallanLoc(i: any) {
    var predepositDetailsArray = this.authTribP1Form.controls.predepositDetails as UntypedFormArray
    if (predepositDetailsArray.controls.length > 0) {
      for (var index = 0; index < predepositDetailsArray.controls.length; index++) {
        if (index === i) {
          var predepositDetailsControl = predepositDetailsArray.controls[index] as UntypedFormGroup;
          predepositDetailsControl.value['isCopyOfBankChallanLocDocUploadedClicked'] = true
        }
      }
    }
    this.isCopyOfBankChallanLocFile = true;
    this.isCopyOfBankChallanLocText = false;
    this.isCopyOfBankChallanLocDWText = true;
    this.isCopyOfBankChallanLocUPText = false;
  }

  deletepredepositFile(i: any) {
    let predepositArray = this.authTribP1Form.controls.predepositDetails as UntypedFormArray
    if (predepositArray.controls.length > 0) {
      for (let index = 0; index < predepositArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = predepositArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['iscopyOfFormUploadedClicked'] = true;
        }
      }
    }
  }

  deletepredeposit2File(i: any) {
    let predepositArray = this.authTribP1Form.controls.predepositDetails as UntypedFormArray
    if (predepositArray.controls.length > 0) {
      for (let index = 0; index < predepositArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = predepositArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['ispredepositDetailsUploadedClicked'] = true;
        }
      }
    }
  }

  uploadProtestDetailsCopyOfFormLoc(i: any) {
    var protestDetailsArray = this.authTribP1Form.controls.protestDetails as UntypedFormArray
    if (protestDetailsArray.controls.length > 0) {
      for (var index = 0; index < protestDetailsArray.controls.length; index++) {
        if (index === i) {
          var protestDetailsControl = protestDetailsArray.controls[index] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.isProtestDetailCopyOfFormLocFile = true;
    this.isProtestDetailCopyOfFormLocText = false;
    this.isProtestDetailCopyOfFormLocDWText = true;
    this.isProtestDetailCopyOfFormLocUPText = false;
  }

  uploadProtestDetailsCopyOfBankChallanLoc(i: any) {
    var protestDetailsArray = this.authTribP1Form.controls.protestDetails as UntypedFormArray
    if (protestDetailsArray.controls.length > 0) {
      for (var index = 0; index < protestDetailsArray.controls.length; index++) {
        if (index === i) {
          var protestDetailsControl = protestDetailsArray.controls[index] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfBankChallanLocDocUploadedClicked'] = true
        }
      }
    }
    this.isProtestDetailCopyOfBankChallanLocFile = true;
    this.isProtestDetailCopyOfBankChallanLocText = false;
    this.isProtestDetailCopyOfBankChallanLocDWText = true;
    this.isProtestDetailCopyOfBankChallanLocUPText = false;
  }
  deleteUploadFile(i: any) {
    let protestArray = this.authTribP1Form.controls.protestDetails as UntypedFormArray
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['copyOfFormDocUploadedClicked'] = true;
        }
      }
    }
  }

  deleteProtestFile(i: any) {
    let protestArray = this.authTribP1Form.controls.protestDetails as UntypedFormArray
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['iscopyOfBankChallanUploadedClicked'] = true;
        }
      }
    }
  }

  savePreDepositeDetailList() {
    const preDepositeDetailList = this.authTribP1Form.value.predepositDetails;
    if (preDepositeDetailList.length > 0) {
      preDepositeDetailList.forEach((item: any, i: number) => {
        if (!this.preDepositeDetailItemList[i]) {
          this.preDepositeDetailItemList.push({});
        }
        if (item.copyOfStayLoc) {
          this.preDepositeDetailItemList[i].copyOfStayLoc = item.copyOfStayLoc;
        }
      })
    }
    this.authTribP1Form.controls.predepositDetails.patchValue(preDepositeDetailList);
  }

  getDetailOfAppeal() {
    return (this.authTribP1Form.get('appealDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  addDetailOfAppeal() {
    let appealDetailsFrmGrp = this.detailOfAppeal();
    (this.authTribP1Form.get('appealDetails') as UntypedFormArray).push(appealDetailsFrmGrp);
  }

  detailOfAppeal(data: any = null) {
    return this.fBuild.group({
      formType: [data ? data?.formType : null],
      appealRefNo: [data ? data?.appealRefNo : null],
      filingAppealDate: [data ? data?.filingAppealDate : null],
      appealMode: [data ? data?.appealMode : null],
      ackCopyLoc: new UntypedFormArray([]),
      otherAttachmentsLoc: new UntypedFormArray([]),
    })
  }

  rmDetailsOfAppeal(i: number) {
    (this.authTribP1Form.get('appealDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfAppealRef.close();
  }

  openRemoveDetailsOfAppeal(content: any) {
    this.rmvDetailsOfAppealRef = this.modalService.open(content);
  }

  initializeDetailOfAppealList() {
    if (this.caseData.appealDetails !== undefined) {
      this.caseData.appealDetails.forEach((appealDetails: any) => {
        let appealDetailsFrmGrp: UntypedFormGroup = this.detailOfAppeal(appealDetails);
        (this.authTribP1Form.get('appealDetails') as UntypedFormArray).push(appealDetailsFrmGrp);

        if (appealDetails.ackCopyLoc) {
          appealDetails.ackCopyLoc.forEach((not: any) => {
            let ackCopyGrp: UntypedFormGroup = this.createAppealAckCopy(not);
            (appealDetailsFrmGrp.get('ackCopyLoc') as UntypedFormArray).push(ackCopyGrp);
          });
        }
        if (appealDetails.otherAttachmentsLoc) {
          appealDetails.otherAttachmentsLoc.forEach((not: any) => {
            let otherAttachFrmGrp: UntypedFormGroup = this.createAppealOtherAttach(not);
            (appealDetailsFrmGrp.get('otherAttachmentsLoc') as UntypedFormArray).push(otherAttachFrmGrp);
          });
        }


      });
    }
  }

  uploadCopyOfAckLoc(i: any) {
    var appealDetailsArray = this.authTribP1Form.controls.appealDetails as UntypedFormArray
    if (appealDetailsArray.controls.length > 0) {
      for (var index = 0; index < appealDetailsArray.controls.length; index++) {
        if (index === i) {
          var appealDetailControl = appealDetailsArray.controls[index] as UntypedFormGroup;
          appealDetailControl.value['isAckCopyLocDocUploadedClicked'] = true
        }
      }
    }
    this.copyOfAckLocDocFile = true;
    this.copyOfAckLocDocText = false;
    this.copyOfAckLocDWText = true;
    this.copyOfAckLocUPText = false;
  }

  uploadotherAttachments(i: any) {
    var appealDetailsArray = this.authTribP1Form.controls.appealDetails as UntypedFormArray
    if (appealDetailsArray.controls.length > 0) {
      for (var index = 0; index < appealDetailsArray.controls.length; index++) {
        if (index === i) {
          var appealDetailControl = appealDetailsArray.controls[index] as UntypedFormGroup;
          appealDetailControl.value['isOtherAttachmentsLocDocUploadedClicked'] = true
        }
      }
    }
    this.isOtherAttachmentDWText = true;
    this.isOtherAttachmentUPText = false;
    this.isOtherAttachmentText = false;
    this.isOtherAttachmentFile = true;
  }
  // deleteSubmissions2UploadFile(i:any){
  //   let submissionArray = this.authTribP1Form.controls.appealDetails as FormArray
  //   if(submissionArray.controls.length > 0) {
  //     for(let index = 0; index < submissionArray.controls.length; index++){
  //       if(index == i){
  //         let issuesControl = submissionArray.controls[index] as FormGroup;
  //         issuesControl.controls.otherAttachmentsLoc.setValue(null);
  //       issuesControl.value['isOtherAttachmentsLocUploadedClicked'] = true;
  //       }
  //     }
  //   }
  // }

  deleteSubmissionsUploadFile(i: any) {
    let submissionArray = this.authTribP1Form.controls.appealDetails as UntypedFormArray
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.ackCopyLoc.setValue(null);
          issuesControl.value['iscopyOfAckLocUploadedClicked'] = true;
        }
      }
    }
  }

  setDetailOfAppealList() {
    this.authTribP1Form.value.appealDetails.forEach((data: any, i: number) => {
      if (!data.ackCopyLoc) {
        data.ackCopyLoc = this.appealDetailItemList[i]?.ackCopyLoc;
      }
      if (!data.otherAttachmentsLoc) {
        data.otherAttachmentsLoc = this.appealDetailItemList[i]?.otherAttachmentsLoc;
      }
    })
  }

  saveDetailOfAppealList() {
    const detailOfAppealList = this.authTribP1Form.value.appealDetails;
    if (detailOfAppealList.length > 0) {
      detailOfAppealList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.ackCopyLoc) {
          this.appealDetailItemList[i].ackCopyLoc = item.ackCopyLoc;
        }
        if (item.otherAttachmentsLoc) {
          this.appealDetailItemList[i].otherAttachmentsLoc = item.otherAttachmentsLoc;
        }
      })
    }
    this.authTribP1Form.controls.appealDetails.patchValue(detailOfAppealList);
  }

  updateDetailsOdAppeal() {
    (this.authTribP1Form.get('appealDetails') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ ackCopyLoc: c.value.ackCopyLoc });
      c.patchValue({ otherAttachmentsLoc: c.value.otherAttachmentsLoc });
    })
  }

  consultantDataAutofil(index: number) {
    console.log('index:', index);
    const consultantGrp = this.consultantInvArr()[index];
    const selectedConsultantName = consultantGrp.get('consultant')?.value;

    // Find the selected consultant in the consultantList
    const selectedConsultant = this.consultantList.find(
      (obj: any) => obj.consultantName === selectedConsultantName
    );

    if (selectedConsultant) {
      // Auto-fill the remaining fields
      consultantGrp.get('firm')?.setValue(selectedConsultant.firmName || '');
      consultantGrp.get('email')?.setValue(selectedConsultant.email || '');
      consultantGrp.get('contact')?.setValue(selectedConsultant.contact || '');
    } else {
      // Clear the fields if consultant is not found
      consultantGrp.get('firm')?.reset();
      consultantGrp.get('email')?.reset();
      consultantGrp.get('contact')?.reset();
      consultantGrp.get('natureOfWork')?.reset();
      consultantGrp.get('fees')?.reset();
      consultantGrp.get('otherAttachmentLoc')?.reset();
    }

    console.log('data populated succesfully !!')
  }

  consultantInvArr(): UntypedFormGroup[] {
    return (this.authTribP1Form.get('consultantCharges') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  addConsultantInv() {
    let consutantFrmGrp = this.consultantChargesList();
    (this.authTribP1Form.get('consultantCharges') as UntypedFormArray).push(consutantFrmGrp);
    return false;
  }

  rmConsultantInv(i: number) {
    (this.authTribP1Form.get('consultantCharges') as UntypedFormArray).removeAt(i);
    this.rmvConsultantModalRef.close();
    this.feesChargedChanged();
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (this.authTribP1Form.get('consultantCharges') as UntypedFormArray).controls.forEach((c: any) => {
      const fees = this.inrService.removeCommasAndParseNumber(c.value.fees);
      feesChargedTotal += Number(fees);
    });

    this.feesChargedTotal = feesChargedTotal;
  }
  deleteConsultantFile(i: any) {
    let consultantArray = this.authTribP1Form.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentLoc.setValue(null);
          issuesControl.value['isOtherAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }
  saveConsultantChargesList() {
    const appealDetailList = this.authTribP1Form.value.consultantCharges;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.consultantChargesItemList[i]) {
          this.consultantChargesItemList.push({});
        }
        if (item.otherAttachmentLoc) {
          this.consultantChargesItemList[i].otherAttachmentLoc = item.otherAttachmentLoc;
        }
      })
    }
    this.authTribP1Form.controls.consultantCharges.patchValue(appealDetailList);
  }
  setConsultantChargesList() {
    this.authTribP1Form.value.consultantCharges.forEach((data: any, i: number) => {
      if (!data.otherAttachmentLoc) {
        data.otherAttachmentLoc = this.consultantChargesItemList[i]?.otherAttachmentLoc;
      }
    })
  }


  consultantChargesList(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      firm: [data ? data.firm : ''],
      consultant: [data ? data.consultant : null],
      natureOfWork: [data ? data.natureOfWork : null],
      fees: [data ? data.fees : 0],
      otherAttachmentLoc: new UntypedFormArray([]),
      email: [data ? data.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [data ? data.contact : null]
    })

  }

  uploadotherattachmentsLoc(i: any) {
    var consultantArray = this.authTribP1Form.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (var index = 0; index < consultantArray.controls.length; index++) {
        if (index === i) {
          var consultantControl = consultantArray.controls[index] as UntypedFormGroup;
          consultantControl.value['isOtherAttachmentLocDocUploadedClicked'] = true
        }
      }
    }
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLoc = true;
    this.isconsultantUPattachmentsLoc = false;
  }

  nextPhase() {
    this.disablePhase3 = (this.authTribP1Form.value.whetherCaseCallBook === "true" || this.authTribP1Form.value.whetherCaseCallBook == true) ? true : false;
  }


  // phTabArr(): FormArray {
  //   return this.adjP1Form.get("itemList") as FormArray
  // }
  newphArray(item: any = null): UntypedFormGroup {
    return this.fBuild.group({
      phNoticeDate: ['', []],
      dateOfPhScheduled: ['', []],
      phCopyLoc: new UntypedFormArray([]),
      adjournedOrNot: [false],
      attended: ['', []],
      mode: ['', []],
      phRefNo: ['', []],
      phTime: ['', []],
      consultant: [this.caseData?.consultant1, []],
      personResponsible: [this.caseData?.personResponsible1, []],
      dateOfsubmission: ['', []],
      additionalSubmissionLoc: new UntypedFormArray([]),
      phDiscussionNotes: ['', []],
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phAdjournmentLetterLoc: new UntypedFormArray([]),
      dateOfSubmission: ['', []],
    })
  }
  createAdjDiv() {
    this.aptDivArr().push(this.newphArray());
  }
  removeLetter(i: number) {
    this.aptDivArr().removeAt(i);
  }
  deleteitemListFile() {
    var itemListArray = this.authTribP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.phRecordIfAnyLoc.setValue(null);
      }

    }
  }
  deletePHNoticeFile() {
    var itemListArray = this.authTribP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.phCopyLoc.setValue(null);
      }
    }
  }
  deleteSubmissionFile() {
    var itemListArray = this.authTribP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.additionalSubmissionLoc.setValue(null);
      }
    }
  }
  changeadjOrNot(event: any, i: any) {
    if (event.target.value === "true") {
      this.createAdjDiv();
      this.isadjorNot = true;
    } else {
      this.isadjorNot = false;
      this.removeLetter(i);
    }

  }
  /*Issue invloved div itration */


  issuInvArr(): UntypedFormGroup[] {
    return (this.authTribP1Form.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newIssueInvArray(): UntypedFormGroup {
    return this.fBuild.group({
      issue: [null, [Validators.required]],
      keyword: [null, []],
      riskParam: [null, [Validators.required]],
      tag: [null, []],
      periodFromDate1: [null, []],
      periodToDate1: [null, []],
      periodFromDate3: [null, []],
      periodToDate3: [null, []],
      interestRate: [null, []],
      remark1: [null, [Validators.maxLength(500)]],
      igst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      total1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      igst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      total3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      details: new UntypedFormArray([]),
      status: [null, []],
      userEnteredInterest1: [null, []],
      userEnteredInterest3: [null, []],
      calculatedInterest1: [null, []],
      calculatedInterest3: [null, []],
      interestRate1: [null, []],
      interestRate3: [null, []],


    })
  }
  addIssueInv() {
    let issueFrmGrp = this.newIssueInvArray();
    (issueFrmGrp.get('details') as UntypedFormArray).push(this.newDetailsFrmGrp());
    (this.authTribP1Form.get('issues') as UntypedFormArray).push(issueFrmGrp);
  }
  rmIssueInv(i: number) {
    (this.authTribP1Form.get('issues') as UntypedFormArray).removeAt(i);
    this.rmvIssueModalRef.close();
  }
  // details array
  getdetails(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newDetailsFrmGrp() {
    return new UntypedFormGroup({
      finYear: new UntypedFormControl(null),
      quarter: new UntypedFormControl(null),
      provisionAmt1: new UntypedFormControl(null),
      contLiabilityAmt1: new UntypedFormControl(null),
      otherLiability1: new UntypedFormControl(null),
      provisionChangeReason1: new UntypedFormControl(null),
      provisionNotMadeReason1: new UntypedFormControl(null),
      contLiabReason1: new UntypedFormControl(null),
      otherLiabilityReason1: new UntypedFormControl(null),
      considerInCaroReport1: new UntypedFormControl(null),
      caroAmt1: new UntypedFormControl(null),

    })
  }
  addDetail(j: any) {
    let issuesFrmArray = this.authTribP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[j].get('details') as UntypedFormArray;
    detailsFrmArray.push(this.newDetailsFrmGrp());
  }
  rmDetail(i: number, k: number) {
    let issuesFrmArray = this.authTribP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    detailsFrmArray.removeAt(k);
    this.rmvDetailModalRef.close();

  }
  /*Issue invloved div itration */
  /*detaild provision div itration */

  // provArr(): FormArray {
  //   return this.authTribP1Form.get("provisionDetails") as FormArray
  // }

  // newProvArr(): FormGroup {
  //   return this.fBuild.group({
  //     finYear: [null],
  //     percentageOfProvision: [null],
  //     amountOfProvision: [null],
  //   })
  // }
  // addProv() {
  //   this.provArr().push(this.newProvArr());
  //   this.isProvAdd = true;
  // }
  // rmProv(i: number) {
  //   this.provArr().removeAt(i);
  // }
  /*detaild provision div itration */
  /*upload file selection */
  // onFileSelect(event: any,type:any=null) {
  //   this.litService.onFileSelect(event, type);

  // }


  onFileSelect(event: any, type: any = null) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace('&', 'And')
        this.selectedFile = new File([originalFile], modifiedFilename, { type: originalFile.type });
        // this.formData = new FormData();
        // this.formData.append('fname', this.selectedFile);
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, type);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.selectedFile = null;
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  onUpload(selectedfield: any, type: any) {
    let urlData = {
      gstinOrPan: this.authTribP1Form.controls.gstin.value,
      type: this.tabType[4].key,
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.litService.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFileUrl(response.response.url, response.response.path, selectedfield, type);
      } else {
      }
    })
  }


  // onUpload(selectedfield:any, type :any) {
  //   let requestBody: any = new FormData();
  //   requestBody.append('caseId', this.caseId ? this.caseId : this.savedCaseId);
  //   requestBody.append('type', this.tabType[3].key);
  //   requestBody.append('field', selectedfield);
  //   requestBody.append('fmime', this.selectedFile.type);
  //   requestBody.append('fname', this.selectedFile.name);
  //   requestBody.append('file', this.selectedFile);
  //   this.litService.uploadFile(requestBody).subscribe((response: any) => {
  //     if (response && response.status === 1) {
  //       this.setFormData(response.response, selectedfield, type)
  //     }
  //   })
  // }


  getFileUrl(url: any, path: any, selectedfield: any, type: any = null) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      console.log("response receved is ", response);
      if (this.selectedFile && this.p1.itemList.value && this.matches && !type) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.p1.itemList.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;//this.filePath;
              this.savecaseItemList();
            }
          }
        });

      } else if (this.selectedFile && this.matches != null && type === 'predepositDetails') {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.p1.predepositDetails.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;//this.filePath;
              this.savePreDepositeDetailList();
            }
          }
        });
      }
      else if (this.selectedFile && this.matches != null && type === 'protestDetails') {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.p1.protestDetails.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;//this.filePath;
              this.savePortestlDetailList();
            }
          }
        });
      }
      else if (this.selectedFile && this.matches != null && type === 'appealDetails') {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.p1.appealDetails.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;//this.filePath;
              this.saveDetailOfAppealList();
            }
          }
        })
      }
      else if (this.selectedFile && this.matches != null && type === 'liabilityDetails') {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.p1.liabilityDetails.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;//this.filePath;
              this.saveLiabilityDetailsList();
            }
          }
        });
      }
      else if (this.selectedFile && this.matches != null && type === 'consultantCharges') {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.p1.consultantCharges.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;//this.filePath;
              this.saveConsultantChargesList();
            }
          }
        });
      }
      if (selectedfield) {
        var toupdate = this.authTribP1Form.value;
        let pathToVeriabl = this.litService.commonCreatePath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.authTribP1Form.patchValue(this.authTribP1Form.value);
      }
      else {
        let field = selectedfield;//this.selectedField;
        let adjForm = this.authTribP1Form.value;
        adjForm[field] = path;//this.filePath;
        if (this.caseData == null) {
          this.caseData = {};
        }
        this.caseData[field] = path;
        this.authTribP1Form.get(field)?.patchValue(path);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }



  // setFormData(path: any, selectedfield: any,type: any = null) {
  //     if(this.selectedFile && this.p1.itemList.value && this.matches && !type){
  //       let letterIndex = this.matches[1];
  //       let attrFieldNm = this.matches.input.split('.');
  //       this.p1.itemList.value.forEach((element: any, index : any) => {
  //         if(letterIndex){
  //           if(letterIndex == index){
  //             element[attrFieldNm[1]] = path;//this.filePath;
  //             this.savecaseItemList();
  //           }
  //         }
  //         });

  //     }else if (this.selectedFile && this.matches!=null  && type === 'predepositDetails') {
  //       let letterIndex = this.matches[1];
  //       let attrFieldNm = this.matches.input.split('.');
  //       this.p1.predepositDetails.value.forEach((element: any, index: any) => {
  //         if (letterIndex) {
  //           if (letterIndex == index) {
  //             element[attrFieldNm[1]] = path;//this.filePath;
  //             this.savePreDepositeDetailList();
  //           }
  //         }
  //       });
  //     }
  //     else if (this.selectedFile && this.matches!=null  && type === 'protestDetails') {
  //       let letterIndex = this.matches[1];
  //       let attrFieldNm = this.matches.input.split('.');
  //       this.p1.protestDetails.value.forEach((element: any, index: any) => {
  //         if (letterIndex) {
  //           if (letterIndex == index) {
  //             element[attrFieldNm[1]] = path;//this.filePath;
  //             this.savePortestlDetailList();
  //           }
  //         }
  //       });
  //     }
  //     else if (this.selectedFile && this.matches!=null  && type === 'appealDetails') {
  //       let letterIndex = this.matches[1];
  //       let attrFieldNm = this.matches.input.split('.');
  //       this.p1.appealDetails.value.forEach((element: any, index: any) => {
  //         if (letterIndex) {
  //           if (letterIndex == index) {
  //             element[attrFieldNm[1]] = path;//this.filePath;
  //             this.saveDetailOfAppealList();
  //           }
  //         }
  //       })
  //     }
  //     else if (this.selectedFile && this.matches!=null  && type === 'liabilityDetails') {
  //       let letterIndex = this.matches[1];
  //       let attrFieldNm = this.matches.input.split('.');
  //       this.p1.liabilityDetails.value.forEach((element: any, index: any) => {
  //         if (letterIndex) {
  //           if (letterIndex == index) {
  //             element[attrFieldNm[1]] = path;//this.filePath;
  //             this.saveLiabilityDetailsList();
  //           }
  //         }
  //       });
  //     }
  //     else if (this.selectedFile && this.matches!=null && type === 'consultantCharges') {
  //       let letterIndex = this.matches[1];
  //       let attrFieldNm = this.matches.input.split('.');
  //       this.p1.consultantCharges.value.forEach((element: any, index: any) => {
  //         if (letterIndex) {
  //           if (letterIndex == index) {
  //             element[attrFieldNm[1]] = path;//this.filePath;
  //             this.saveConsultantChargesList();
  //           }
  //         }
  //       });
  //     }
  //     else{
  //       let field = selectedfield;//this.selectedField;
  //       let adjForm = this.authTribP1Form.value;
  //       adjForm[field] = path;//this.filePath;
  //       if(this.caseData == null){
  //         this.caseData = {};
  //       }
  //       this.caseData[field] = path;
  //       this.authTribP1Form.get(field)?.patchValue(path);
  //     }
  //     this.toaster.showSuccess('File Uploaded Successfully');
  //   }

  changeOutcome(event: any = null) {
    let data;
    if (!event) {
      data = this.authTribP1Form.value.outcome;
    } else {
      data = event.target.value;
    }
    if (data === "Favorable") {
      this.disabledWeatherWrit = true;
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = false;
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Favorable');
      this.authTribP1Form.get("whetherAppealPreferred")?.clearValidators();
      this.authTribP1Form.get("whetherAppealPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("whetherAppealPreferred")?.setValue(false);
      this.authTribP1Form.get("writAppPreferred")?.clearValidators();
      this.authTribP1Form.get("writAppPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("writAppPreferred")?.setValue(false);
      this.authTribP1Form.get("whetherDptAppealPreferred")?.clearValidators();
      this.authTribP1Form.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("whetherDptAppealPreferred")?.setValue(true);
      var issues1Array = this.authTribP1Form.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
          issues1Control.controls.status.setValue(data);
        }
      }
    } else if (data === "Unfavorable") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Unfavorable' || x.value == 'Admitted');
      this.authTribP1Form.get("whetherAppealPreferred")?.clearValidators();
      this.authTribP1Form.get("whetherAppealPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("whetherAppealPreferred")?.setValue(true);
      this.authTribP1Form.get("whetherDptAppealPreferred")?.clearValidators();
      this.authTribP1Form.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("whetherDptAppealPreferred")?.setValue(false);
      // this.disabledWeatherWrit = false;
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = true;
      // var issues1Array = this.authTribP1Form.controls.issues as FormArray
      // if(issues1Array.controls.length > 0) {
      //   for(var i = 0; i < issues1Array.controls.length; i++){
      //       var issues1Control = issues1Array.controls[i] as FormGroup;
      //       issues1Control.controls.status.setValue(data);
      //     }
      // }
    } else if (data === "Remanded Back") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Remanded Back');
      this.authTribP1Form.get("whetherAppealPreferred")?.setValue(false);
      this.authTribP1Form.get("writAppPreferred")?.setValue(false);
      this.authTribP1Form.get("whetherDptAppealPreferred")?.setValue(false);
      this.disabledWeatherWrit = true;
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = true;
      var issues1Array = this.authTribP1Form.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
          issues1Control.controls.status.setValue(data);
        }
      }
    } else if (data === "Partly Favorable") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Partly Favorable' || x.value == 'Favorable' || x.value == 'Unfavorable' || x.value == 'Partly Unfavorable' || x.value == 'Admitted');
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
    } else if (data === "Partly Remanded Back") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Partly Remanded Back' || x.value == 'Favorable' || x.value == 'Unfavorable' || x.value == 'Partly Favorable' || x.value == 'Partly Unfavorable' || x.value == 'Remanded Back' || x.value == 'Admitted');
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
    } else if (data === 'Partly Unfavorable') {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Favorable' || x.value == 'Unfavorable' || x.value == 'Partly Favorable' || x.value == 'Partly Unfavorable' || x.value == 'Admitted');
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
    }
  }

  // changeIssueConclusionValue(){
  //   var issues1Array = this.authTribP1Form.controls.issues as FormArray;

  //   switch(this.authTribP1Form.value?.outcome){
  //     case 'Favorable' :  this.issueConclusion = [{ key: "F", value: "Favorable" }];
  //                         if(issues1Array.controls.length > 0) {
  //                         for(var i = 0; i < issues1Array.controls.length; i++){
  //                             var issues1Control = issues1Array.controls[i] as FormGroup;
  //                             issues1Control.controls.status.setValue('Favorable');
  //                           }
  //                         }
  //                         break;
  //     case 'Unfavorable' :    this.issueConclusion = [ { key: "U", value: "Unfavorable" }, { key: "A", value: "Admitted"}];
  //                             if(issues1Array.controls.length > 0) {
  //                               for(var i = 0; i < issues1Array.controls.length; i++){
  //                                   var issues1Control = issues1Array.controls[i] as FormGroup;
  //                                   issues1Control.controls.status.setValue('Unfavorable');
  //                                   // issues1Control.controls.status.setValue(issues1Control.controls.status.value?issues1Control.controls.status.value:'');
  //                                 }
  //                             }
  //                             break;
  //     case 'Remanded Back' :  this.issueConclusion = [ { key: "R", value: "Remanded Back" }];
  //                             if(issues1Array.controls.length > 0) {
  //                               for(var i = 0; i < issues1Array.controls.length; i++){
  //                                   var issues1Control = issues1Array.controls[i] as FormGroup;
  //                                   issues1Control.controls.status.setValue('Remanded Back');
  //                                 }
  //                             }
  //                             break;
  //     case 'Partly Favorable': this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS;
  //                              let index1 =  this.issueConclusion.findIndex(value => value.value === 'Remanded Back');
  //                              this.issueConclusion.splice(index1,1);
  //                              let index2 = this.issueConclusion.findIndex(value => value.value === 'Partly Remanded Back');
  //                              this.issueConclusion.splice(index2,1);
  //                               if(issues1Array.controls.length > 0) {
  //                                 for(var i = 0; i < issues1Array.controls.length; i++){
  //                                     var issues1Control = issues1Array.controls[i] as FormGroup;
  //                                     issues1Control.controls.status.setValue(issues1Control.controls.status.value?issues1Control.controls.status.value:'');
  //                                   }
  //                               }
  //                               break;
  //     default :
  //               this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS;
  //               if(issues1Array.controls.length > 0) {
  //                 for(var i = 0; i < issues1Array.controls.length; i++){
  //                     var issues1Control = issues1Array.controls[i] as FormGroup;
  //                     issues1Control.controls.status.setValue(issues1Control.controls.status.value?issues1Control.controls.status.value:'');
  //                   }
  //               }
  //               break;
  //   }
  // }
  checkfurAppDeciYes(event: any = null) {
    let data = null;
    if (!event) {
      data = this.authTribP1Form.value.furtherAppealDecision;
    } else {
      data = event.target.value;
    }
    if (data === 'true' || data === true) {
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = true;
      this.disabledWeatherWrit = true;
      this.authTribP1Form.get('whetherAppealPreferred')?.clearValidators();
      this.authTribP1Form.get('whetherAppealPreferred')?.updateValueAndValidity();
      this.authTribP1Form.get('whetherAppealPreferred')?.setValue('false');
      
      this.authTribP1Form.get('whetherDptAppealPreferred')?.clearValidators();
      this.authTribP1Form.get('whetherDptAppealPreferred')?.updateValueAndValidity();
      this.authTribP1Form.get('whetherDptAppealPreferred')?.setValue('false');

      this.authTribP1Form.get('writAppPreferred')?.clearValidators();
      this.authTribP1Form.get('writAppPreferred')?.updateValueAndValidity();
      this.authTribP1Form.get('writAppPreferred')?.setValue('false');
      //this.rmWeatherApplErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
      this.disabledWeatherWrit = false;
    }
    this.setForumOfAppeal();
    this.authTribP1Form.controls['forumOfAppeal'].setValue(
      this.caseData?.forumOfAppeal
    );
  }
  checkAppYes(event: any = null) {
    let data;
    if (!event) {
      data = this.authTribP1Form.value.whetherAppealPreferred;
    } else {
      data = event.target.value;
    }
    if (data == "true") {
      this.disabledWeatherWrit = true;
      this.disabledWeatherDept = true;
      this.authTribP1Form.get("writAppPreferred")?.clearValidators();
      this.authTribP1Form.get("writAppPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("writAppPreferred")?.setValue(false);
      this.authTribP1Form.get("whetherDptAppealPreferred")?.clearValidators();
      this.authTribP1Form.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("whetherDptAppealPreferred")?.setValue(false);
      // this.adjP1Form.controls['writAppPreferred'].setErrors({'required': false});
      // this.rmWeatherWritErrorBorder();
    } else {
      this.disabledWeatherWrit = false;
      this.disabledWeatherDept = false;
    }
    this.setForumOfAppeal();

  }

  checkDeptYes(event: any = null) {
    let data;
    if (!event) {
      data = this.authTribP1Form.value.whetherDptAppealPreferred;
    } else {
      data = event.target.value;
    }
    if (data === "true") {
      this.disabledWeatherAppl = true;
      this.disabledWeatherWrit = true;
      this.authTribP1Form.get("whetherAppealPreferred")?.clearValidators();
      this.authTribP1Form.get("whetherAppealPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("whetherAppealPreferred")?.setValue(false);
      this.authTribP1Form.get("writAppPreferred")?.clearValidators();
      this.authTribP1Form.get("writAppPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("writAppPreferred")?.setValue(false);
      // this.adjP1Form.controls['writAppPreferred'].setErrors({'required': false});
      // this.rmWeatherWritErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherWrit = false;
    }
    this.setForumOfAppeal();

  }
  /*create phase 1 (submit function)*/
  createP1() {
    this.isSubmitted = true;
    let data: any = {};
    for (const property in this.authTribP1Form.controls) {
      if (this.authTribP1Form.controls[property].status === "INVALID")
        console.log(`${property}: ${this.authTribP1Form.controls[property].status}`)
    }
    let dataAppealBookLoc = (this.authTribP1Form.get('appealBookLoc') as UntypedFormArray).controls;
    var count = dataAppealBookLoc?.some((x) => x.get('fileLoc')?.value != null);
    if (!count) {
      this.toaster.showError('Required Fields Are Empty');
      return;
    }
    // let protestArray = this.authTribP1Form.controls.protestDetails as UntypedFormArray
    // for (let index = 0; index < protestArray.controls.length; index++) {
    //   let issuesControl = protestArray.controls[index] as UntypedFormGroup;

    //   if (issuesControl.controls.challanNo.value) {
    //     let copyOfFormLocControls = (issuesControl.get('copyOfFormLoc') as UntypedFormArray).controls;

    //     let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
    //     if (!hasSupportingDoc) {
    //       this.toaster.showError("Required Fields Are Empty for Challan No.");
    //       return;
    //     }
    //   }
    // }
    let protestArray = this.authTribP1Form.controls.protestDetails as UntypedFormArray
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;

        if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
          let copyOfFormLocControls = (issuesControl.get('copyOfFormLoc') as UntypedFormArray).controls;

          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = issuesControl.controls.issueName.value;
          let tagControls = issuesControl.controls.tag.value;
          let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;        
          let challanNoControls = issuesControl.controls.challanNo.value ;
          //let hasissueName = issueNameControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
            this.toaster.showError("Required Fields Are Empty for Payment table.");
            return;
          }
        }
       
      
      }
      let predepositArray = this.authTribP1Form.controls.predepositDetails as UntypedFormArray
      for (let index = 0; index < predepositArray.controls.length; index++) {
        let issuesControl = predepositArray.controls[index] as UntypedFormGroup;

        if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
          let copyOfFormLocControls = (issuesControl.get('copyOfFormLoc') as UntypedFormArray).controls;

          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = issuesControl.controls.issueName.value;
          let tagControls = issuesControl.controls.tag.value;
          let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;        
          let challanNoControls = issuesControl.controls.challanNo.value ;
          //let hasissueName = issueNameControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
            this.toaster.showError("Required Fields Are Empty for Payment table.");
            return;
          }
        }
       
      
      }
    if (this.authTribP1Form.valid) {
      data = this.authTribP1Form.value;
      data.caseType = this.caseType;
      let urlData = {
        typeVal: this.tabType[3].key,
        gstin: this.selectedGstin,
        companyId: this.companyId
      }
      Object.assign(
        data,
        { action: 'CREATE' },
        { phase: '1' },
        { keyword: 'keyword1' }
      );
      let bookLocArray: any[] = [];
      let otherAttachmentLoc1Array: any[] = [];
      let protestCopyFormArray: any[] = [];
      let protestBankChallanArray: any[] = [];
      let preDepoCopyFormArray: any[] = [];
      let preDepoBankChallanArray: any[] = [];
      for (let i = 0; i < this.authTribP1Form.controls.appealBookLoc.value.length; i++) {
        const element = this.authTribP1Form.controls.appealBookLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          bookLocArray.push(element)
        }

      }
      for (let i = 0; i < this.authTribP1Form.controls.otherAttachmentsLoc1.value.length; i++) {
        const element = this.authTribP1Form.controls.otherAttachmentsLoc1.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachmentLoc1Array.push(element)
        }

      }
      for (let i = 0; i < this.authTribP1Form.controls.predepositDetails.value.length; i++) {
        preDepoCopyFormArray = []
        const copyFormArray = this.authTribP1Form.controls.predepositDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            preDepoCopyFormArray.push(element)
          }
        }
        this.authTribP1Form.controls.predepositDetails.value[i].copyOfFormLoc = preDepoCopyFormArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.predepositDetails.value.length; i++) {
        preDepoBankChallanArray = [];
        const bankChallanArray = this.authTribP1Form.controls.predepositDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            preDepoBankChallanArray.push(element)
          }
        }
        this.authTribP1Form.controls.predepositDetails.value[i].copyOfBankChallanLoc = preDepoBankChallanArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.protestDetails.value.length; i++) {
        protestCopyFormArray = []
        const copyFormArray = this.authTribP1Form.controls.protestDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestCopyFormArray.push(element)
          }
        }
        this.authTribP1Form.controls.protestDetails.value[i].copyOfFormLoc = protestCopyFormArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.protestDetails.value.length; i++) {
        protestBankChallanArray = [];
        const bankChallanArray = this.authTribP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestBankChallanArray.push(element)
          }
        }
        this.authTribP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc = protestBankChallanArray;
      }
      this.authTribP1Form.value.appealBookLoc = bookLocArray;
      this.authTribP1Form.value.otherAttachmentsLoc1 = otherAttachmentLoc1Array;
      this.inrFormattingRemoving();
      // this.letterP1Form.value.dateOfLetter = this.datePipe.transform(this.letterP1Form.value.dateOfLetter, 'yyyy-MM-dd');
      this.litService.saveAppellateTribData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        if (response.status === 1) {
          this.formatTableValues();
          this.toaster.showSuccess(response.message);
          this.isDataSaved = true;
          this.isPhase2 = true;
          this.isCreateBtn = false;
          this.isUpdateBtn = true;
          this.savedCaseId = response.response;
          this.saveCaseId.emit(this.savedCaseId);
          this.initializeData();
        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  /*get prefilled data (View)*/
  initializeData() {
    if (this.currentTab != this.activeTab) {
      this.enablePrevStageTab.emit(this.activeStages);
    }
    if (this.savedCaseId) {
      this.caseId = this.savedCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        // caseId: this.caseId,
        caseId: this.caseId,
      }
      this.litService.getTribunalData(model).subscribe((response: any) => {
        if (response.status === 1) {
          if (response.response.caseType === CASE_TYPES.MEMORANDUM) {
            this.caseType = CASE_TYPES.MEMORANDUM;
            this.outcome = MEMORANDOM_OUTCOME
          }
          if (this.currentTab === this.activeTab) {
            this.enablePrevStageTab.emit(response.response?.prevStages);
          }
          this.caseData = response.response;
          // const itemlistData = this.caseData.issues;
          // this.autoIssueList = []; 
          // this.autoTagList = []; 
          // const uniqueIssues = new Set(); 
          // const uniqueTags = new Set();
          //   itemlistData.forEach((issue : any) => {
          //     if (!uniqueIssues.has(issue.issue)) {
          //       uniqueIssues.add(issue.issue);
          //       this.autoIssueList.push(issue.issue); // Add to issue list
          //     }
          //     if (issue.tag !== null && issue.tag !== undefined && !uniqueTags.has(issue.tag)) {
          //       uniqueTags.add(issue.tag);
          //       this.autoTagList.push(issue.tag); // Add to tag list
          //     }
          //   });
          this.getCompanyTags(this.caseData.gstin);
          this.selectedStateName = response.response.state;
          if (this.caseData.status === "MOVED") {
            this.callBookDisabled = true;
          }

          if (this.caseData?.issues) {
            this.caseData.issues.forEach((issue: any) => {
              issue.igst1 = this.inrService.formatCurrency(String(issue.igst1))
              issue.cgst1 = this.inrService.formatCurrency(String(issue.cgst1))
              issue.sgst1 = this.inrService.formatCurrency(String(issue.sgst1))
              issue.cess1 = this.inrService.formatCurrency(String(issue.cess1))
              issue.interest1 = this.inrService.formatCurrency(String(issue.interest1))
              issue.penalty1 = this.inrService.formatCurrency(String(issue.penalty1))
              issue.total1 = this.inrService.formatCurrency(String(issue.total1))
              issue.igst3 = this.inrService.formatCurrency(String(issue.igst3))
              issue.cgst3 = this.inrService.formatCurrency(String(issue.cgst3))
              issue.sgst3 = this.inrService.formatCurrency(String(issue.sgst3))
              issue.cess3 = this.inrService.formatCurrency(String(issue.cess3))
              issue.interest3 = this.inrService.formatCurrency(String(issue.interest3))
              issue.penalty3 = this.inrService.formatCurrency(String(issue.penalty3))
              issue.total3 = this.inrService.formatCurrency(String(issue.total3))
              if (issue.details && issue.details.length) {
                issue.details.forEach((details: any) => {
                  details.provisionAmt1 = this.inrService.formatCurrency(String(details.provisionAmt1))
                  details.contLiabilityAmt1 = this.inrService.formatCurrency(String(details.contLiabilityAmt1))
                  details.otherLiability1 = this.inrService.formatCurrency(String(details.otherLiability1))
                  details.caroAmt1 = this.inrService.formatCurrency(String(details.caroAmt1))
                })
              }
            })
          }

          if (this.caseData?.consultantCharges) {
            this.caseData.consultantCharges.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees))
            })
          }

          if (this.caseData?.protestDetails) {
            this.caseData.protestDetails.forEach((protest: any) => {
              protest.total = this.inrService.formatCurrency(
                String(protest.total)
              );
              protest.igst = this.inrService.formatCurrency(String(protest.igst));
              protest.cgst = this.inrService.formatCurrency(String(protest.cgst));
              protest.sgst = this.inrService.formatCurrency(String(protest.sgst));
              protest.cess = this.inrService.formatCurrency(String(protest.cess));
              protest.interest = this.inrService.formatCurrency(String(protest.interest) );
              protest.penalty = this.inrService.formatCurrency(
                String(protest.penalty)
              );
            });
          }
          if (this.caseData?.predepositDetails) {
            this.caseData.predepositDetails.forEach((predeposit: any) => {
              predeposit.total = this.inrService.formatCurrency(String(predeposit.total))
              predeposit.igst = this.inrService.formatCurrency(String(predeposit.igst));
              predeposit.cgst = this.inrService.formatCurrency(String(predeposit.cgst));
              predeposit.sgst = this.inrService.formatCurrency(String(predeposit.sgst));
              predeposit.cess = this.inrService.formatCurrency(String(predeposit.cess));
              // protest.tax = this.inrService.formatCurrency(String(protest.tax))
              predeposit.interest = this.inrService.formatCurrency(String(predeposit.interest))
              predeposit.penalty = this.inrService.formatCurrency(String(predeposit.penalty))
            })
          }

          if (this.caseData?.liabilityDetails) {
            this.caseData.liabilityDetails.forEach((liability: any) => {
              liability.total = this.inrService.formatCurrency(  String(liability.total));
              liability.igst = this.inrService.formatCurrency(String(liability.igst));
              liability.cgst = this.inrService.formatCurrency(String(liability.cgst))
              liability.sgst = this.inrService.formatCurrency(String(liability.sgst))
              liability.cess = this.inrService.formatCurrency(String(liability.cess))
              liability.interest = this.inrService.formatCurrency(
                String(liability.interest)
              );
              liability.penalty = this.inrService.formatCurrency(
                String(liability.penalty)
              );
            });
          }
          this.paperBookOfAppealLocF = this.litService.getFileName(this.caseData.paperBookOfAppealLoc);
          this.formInitialize();
          this.nextPhase();
          if (this.caseData.itemList && this.caseData.itemList.length > 0) {
            this.initializeItemList();
          } else {
            this.initializeItemList();
            var list: UntypedFormGroup = this.newphArray();
            this.aptDivArr().push(list);
          }

          if (this.caseData.issues && this.caseData.issues.length > 0) {
            this.initializeIssueList();
          } else {
            this.addIssueInv();
          }

          if (this.caseData.predepositDetails && this.caseData.predepositDetails.length > 0) {
            this.initializePreDepositeList();
          } else {
            this.addDetailsOfPreDeposite();
          }

          if (this.caseData.protestDetails && this.caseData.protestDetails.length > 0) {
            this.initializePaymentDetailList();
          } else {
            this.addDetailOfPayment();
          }

          if (this.caseData.appealDetails && this.caseData.appealDetails.length > 0) {
            this.initializeDetailOfAppealList();
          } else {
            this.addDetailOfAppeal();
          }

          if (this.caseData.consultantCharges && this.caseData.consultantCharges.length > 0) {
            this.initializeConsultantList();
          } else {
            this.addConsultantInv();
          }

          if (this.caseData.liabilityDetails && this.caseData.liabilityDetails.length > 0) {
            this.initializeDetailOfLiability();
          } else {
            this.addDetailsOfLiabilityAddmittedAndPaid();
          }

          if (this.caseData.appealBookLoc && this.caseData.appealBookLoc.length > 0) {
            this.initializeAppealBookLoc();
          } else {
            this.addAppealBookLoc();
          }

          if (this.caseData.otherAttachmentsLoc1 && this.caseData.otherAttachmentsLoc1.length > 0) {
            this.initializeOtherAttachmentLoc1();
          } else {
            this.addOtherAttachmentLoc1();
          }
          if (this.caseData.gstatOrderCopyLoc && this.caseData.gstatOrderCopyLoc.length > 0) {
            this.initializegstatOrderCopyLoc();
          } else {
            this.addgstatOrderCopyLoc();
          }
          this.cumulativeCount = this.caseData.cumulative ? this.caseData.cumulative : this.cumulativeCount
          this.prevStage = this.caseData.prevStage;

          this.liabilityAddmittedAndPaidChanged();
          this.protestDetailsChanged();
          this.preDepositeChanged();
          this.feesChargedChanged();
          this.setCummulativeCount();
          this.checkDeptYes();
          this.checkAppYes();
          this.checkwritAppYes();
          this.issueValueChange();
          // this.changeIssueConclusionValue();
          // if (this.caseData.provisionDetails && this.caseData.provisionDetails.length > 0) {
          //   this.initializeProvList();
          // }else{
          //   this.addProv();
          // }
          this.isUpdate = true;
          this.isadjorNot = true;

        }
        this.nextPhase();

      });
    }
  }
  initializeAppealBookLoc() {
    if (this.caseData.appealBookLoc) {
      this.caseData.appealBookLoc.forEach((not: any) => {

        let appealBookLocGrp = this.createAppealBookLoc(not);

        (this.authTribP1Form.get('appealBookLoc') as UntypedFormArray).push(appealBookLocGrp);

      })
    }

    this.authTribP1Form.patchValue(this.caseData);
  }
  initializeOtherAttachmentLoc1() {
    if (this.caseData.otherAttachmentsLoc1) {
      this.caseData.otherAttachmentsLoc1.forEach((not: any) => {

        let otherAttachLoc1Grp = this.createOtherAttachmentLoc1(not);

        (this.authTribP1Form.get('otherAttachmentsLoc1') as UntypedFormArray).push(otherAttachLoc1Grp);

      })
    }

    this.authTribP1Form.patchValue(this.caseData);
  }
  initializegstatOrderCopyLoc() {
    if (this.caseData.gstatOrderCopyLoc) {
      this.caseData.gstatOrderCopyLoc.forEach((not: any) => {
        let gstatOrderCopyLocGrp = this.creategstatOrderCopyLoc(not);
        (this.authTribP1Form.get('gstatOrderCopyLoc') as UntypedFormArray).push(gstatOrderCopyLocGrp);

      })
    }

    this.authTribP1Form.patchValue(this.caseData);
    this.isUpdateBtnDisable = true;
  }
  initializeItemList() {
    if (this.caseData.itemList) {
      this.caseData.itemList.forEach((items: any) => {
        let itemListFrmGrp = this.newphArray(items);
        (this.authTribP1Form.get('itemList') as UntypedFormArray).push(
          itemListFrmGrp
        );

        if (items.phCopyLoc) {
          items.phCopyLoc.forEach((not: any) => {
            let phcopyFormGrp: UntypedFormGroup = this.createphCopyLoc(not);
            (itemListFrmGrp.get('phCopyLoc') as UntypedFormArray).push(phcopyFormGrp);
          });
        }

        if (items.phRecordIfAnyLoc) {
          items.phRecordIfAnyLoc.forEach((not: any) => {
            let phRecordAnyGrp: UntypedFormGroup = this.createPhRecord(not);
            (itemListFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).push(phRecordAnyGrp);
          });
        }
        if (items.phAdjournmentLetterLoc) {
          items.phAdjournmentLetterLoc.forEach((not: any) => {
            let phAdjournmentLetterLocGrp: UntypedFormGroup = this.createPhAdjournmentLetterLoc(not);
            (itemListFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).push(phAdjournmentLetterLocGrp);
          });
        }
        if (items.additionalSubmissionLoc) {
          items.additionalSubmissionLoc.forEach((not: any) => {
            let additionalSubmissionFrmGrp: UntypedFormGroup = this.createAdditionalSubLoc(not);
            (itemListFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).push(additionalSubmissionFrmGrp);
          });
        }

      });
      this.authTribP1Form.patchValue(this.caseData);
    }
  }


  initializeConsultantList() {
    if (this.caseData.consultantCharges) {
      this.caseData.consultantCharges.forEach((consultantCharges: any) => {
        let consultantListFrmGrp: UntypedFormGroup = this.consultantChargesList(consultantCharges);
        (this.authTribP1Form.get('consultantCharges') as UntypedFormArray).push(consultantListFrmGrp);

        if (consultantCharges.otherAttachmentLoc) {
          consultantCharges.otherAttachmentLoc.forEach((not: any) => {
            let consultAttachLocFrmGrp: UntypedFormGroup = this.createConsultAttach(not);
            (consultantListFrmGrp.get('otherAttachmentLoc') as UntypedFormArray).push(consultAttachLocFrmGrp);
          });
        }
      });
    }
  }
  initializeIssueList() {
    if (this.caseData.issues) {
      this.caseData.issues.forEach((issue: any) => {
        let issuesFrmGrp: UntypedFormGroup = this.newIssueInvArray();
        (this.authTribP1Form.get('issues') as UntypedFormArray).push(issuesFrmGrp);
        issue.details.forEach((detail: any) => {
          let detailFrmGrp: UntypedFormGroup = this.newDetailsFrmGrp();
          (issuesFrmGrp.get('details') as UntypedFormArray).push(detailFrmGrp);
        });
      });
      this.authTribP1Form.patchValue(this.caseData);

      this.issueValueChange();
    }
  }
  //   initializeProvList() {
  //     if(this.caseData.provisionDetails){
  //     this.caseData.provisionDetails.forEach((t: any) => {
  //       var provlist: FormGroup = this.newProvArr();
  //       this.provArr().push(provlist);
  //     });
  //     this.authTribP1Form.patchValue(this.caseData);
  //   }
  // }
  UpdateModelData() {
    if (this.caseData != null) {
      // this.authTribP1Form.value.copyOfAckLoc = this.caseData['copyOfAckLoc'];
      // if(this.caseData.otherAttachmentsLoc2 != null){
      //   this.authTribP1Form.value.otherAttachmentsLoc2 = this.caseData['otherAttachmentsLoc2'];
      // }else{
      //   this.authTribP1Form.value.otherAttachmentsLoc2 = '';
      // }
      // this.authTribP1Form.value.drc03CopyLoc = this.caseData['drc03CopyLoc'];
      // this.authTribP1Form.value.bankChallanLoc = this.caseData['bankChallanLoc'];
      if (this.caseData.gstatOrderCopyLoc != null) {
        this.authTribP1Form.value.gstatOrderCopyLoc = this.caseData['gstatOrderCopyLoc'];
      } else {
        this.authTribP1Form.value.gstatOrderCopyLoc = null;
      }
      // if(this.caseData.paymentChallanLoc != null){
      //   this.authTribP1Form.value.paymentChallanLoc = this.caseData['paymentChallanLoc'];
      // }else{
      //   this.authTribP1Form.value.paymentChallanLoc = '';
      // }
    }
  }
  doValidation(phaseTab: any) {
    this.UpdateModelData();
    if (phaseTab === 'p2') {
      this.consultantValidation();
      return this.p2validation();
    } else if (phaseTab === 'p3') {
      this.liabilityValidation();
      return this.p3validation();
    } else {
      return true;
    }
  }

  consultantValidation() {
    let consultantArray = this.authTribP1Form.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(issuesControl.controls.fees.value)
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (issuesControl.controls.firm.value === null || issuesControl.controls.firm.value === '') {
            issuesControl.controls.firm.setErrors({ 'required': true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }

  deleteisUPgstatOrderCopy() {
    if (this.authTribP1Form.get('gstatOrderCopyLoc')?.value) {
      this.authTribP1Form.get('gstatOrderCopyLoc')?.setValue(null);
      this.caseData['gstatOrderCopyLoc'] = null;
      this.isgstatOrderCopyLocFile = false;
      this.isgstatOrderCopyLocText = true;
      this.isDWgstatOrderCopyLoc = true;
      this.isUPgstatOrderCopyLoc = true;
    }
  }
  deleteAppealBookLoc1() {
    if (this.authTribP1Form.get('appealBookLoc')?.value) {
      this.authTribP1Form.get('appealBookLoc')?.setValue(null);
      this.caseData['appealBookLoc'] = null;
      this.isappealBookLocFile = false;
      this.isappealBookLocText = true;
      this.isDWappealBookLoc = true;
      this.isUPappealBookLoc = true;
    }
  }
  deleteOtherAttachmentsLoc1() {
    if (this.authTribP1Form.get('otherAttachmentsLoc1')?.value) {
      this.authTribP1Form.get('otherAttachmentsLoc1')?.setValue(null);
      this.caseData['otherAttachmentsLoc1'] = null;
      this.isotherAttachmentsLoc1File = false;
      this.isotherAttachmentsLoc1Text = true;
      this.isDWotherAttachmentsLoc1 = true;
      this.isUPotherAttachmentsLoc1 = true;
    }
  }

  p1validation() {
    if ((this.authTribP1Form.value['appealBookLoc'] == null || this.authTribP1Form.value['appealBookLoc'] === '') && this.caseType === this.caseTypeData.MEMORANDUM) {
      this.appealBookLocReq = true;
    } else {
      this.appealBookLocReq = false;
    }
    return !this.appealBookLocReq;
  }


  liabilityValidation() {
    let liabilityArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.challanNo.value != null && issuesControl.controls.challanNo.value) {
          if (issuesControl.controls.copyOfFormLoc.value === null || issuesControl.controls.copyOfFormLoc.value === '') {
            issuesControl.controls.copyOfFormLoc.setErrors({ 'required': true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }
  predepositValidation() {
    let predepositArray = this.authTribP1Form.controls.predepositDetails as UntypedFormArray
    if (predepositArray.controls.length > 0) {
      for (let index = 0; index < predepositArray.controls.length; index++) {
        let issuesControl = predepositArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.challanNo.value != null && issuesControl.controls.challanNo.value) {
          if (issuesControl.controls.copyOfFormLoc.value === null || issuesControl.controls.copyOfFormLoc.value === undefined) {
            issuesControl.controls.copyOfFormLoc.setErrors({ 'required': true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }

  updateIssues() {
    (this.authTribP1Form.get('issues') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ igst3: c.value.igst1 })
      c.patchValue({ cgst3: c.value.cgst1 })
      c.patchValue({ sgst3: c.value.sgst1 })
      c.patchValue({ cess3: c.value.cess1 })
      c.patchValue({ interest3: c.value.interest1 })
      c.patchValue({ penalty3: c.value.penalty1 })
      c.patchValue({ total3: c.value.total1 })
      c.patchValue({ userEnteredInterest3: c.value.userEnteredInterest1 })
    })
  }

  updatePreDepositeDetails() {
    (this.authTribP1Form.get('predepositDetails') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ copyOfFormLoc: c.value.copyOfFormLoc });
      c.patchValue({ copyOfBankChallanLoc: c.value.copyOfBankChallanLoc });
    })
  }

  updateProtestDetails() {
    (this.authTribP1Form.get('protestDetails') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ copyOfFormLoc: c.value.copyOfFormLoc });
      c.patchValue({ copyOfBankChallanLoc: c.value.copyOfBankChallanLoc });
    })
  }

  /*Update function*/
  updateLetter(phaseTab: any) {
    console.log(this.authTribP1Form, 'authTribP1Form');
    this.setCaseItemList();
    this.setPreDepositelDetailList();
    this.setPortestlDetailList();
    this.setDetailOfAppealList();
    this.setConsultantChargesList();
    this.setLiabilityDetailsList();

    this.isSubmitted = true;
    if (phaseTab === 'p1') {
      this.updateIssues();
      this.updatePreDepositeDetails();
      this.updateProtestDetails();
      this.predepositValidation();
      // let protestArray = this.authTribP1Form.controls.protestDetails as UntypedFormArray
      // for (let index = 0; index < protestArray.controls.length; index++) {
      //   let issuesControl = protestArray.controls[index] as UntypedFormGroup;
  
      //   if (issuesControl.controls.challanNo.value) {
      //     let copyOfFormLocControls = (issuesControl.get('copyOfFormLoc') as UntypedFormArray).controls;
  
      //     let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
      //     if (!hasSupportingDoc) {
      //       this.toaster.showError("Required Fields Are Empty for Challan No.");
      //       return;
      //     }
      //   }
      // }
      let protestArray = this.authTribP1Form.controls.protestDetails as UntypedFormArray
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;

        if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
          let copyOfFormLocControls = (issuesControl.get('copyOfFormLoc') as UntypedFormArray).controls;
         
          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = issuesControl.controls.issueName.value;
        let tagControls = issuesControl.controls.tag.value;
        let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;
        let challanNoControls = issuesControl.controls.challanNo.value ;
        //let hasissueName = issueNam eControls.some((docControl) => docControl.get('fileLoc')?.value != null);
        if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
          this.toaster.showError("Required Fields Are Empty.");
          return;
        }
        }      
        
       
      }
      let predepositArray = this.authTribP1Form.controls.predepositDetails as UntypedFormArray
      for (let index = 0; index < predepositArray.controls.length; index++) {
        let issuesControl = predepositArray.controls[index] as UntypedFormGroup;

        if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
          let copyOfFormLocControls = (issuesControl.get('copyOfFormLoc') as UntypedFormArray).controls;

          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = issuesControl.controls.issueName.value;
          let tagControls = issuesControl.controls.tag.value;
          let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;        
          let challanNoControls = issuesControl.controls.challanNo.value ;
          //let hasissueName = issueNameControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
            this.toaster.showError("Required Fields Are Empty for Payment table.");
            return;
          }
        }
       
      
      }
    }else{
      this.p2validation();
    }
    let data: any = {};
    let isTabValid = this.doValidation(phaseTab);
    this.doValidation(phaseTab);

    for (const property in this.authTribP1Form.controls) {
      if (this.authTribP1Form.controls[property].status === "INVALID")
        console.log(`${property}: ${this.authTribP1Form.controls[property].status}`)
    }
    if (phaseTab === 'p2') {
      // this.p1validation();
      let submissionsFrmArray = (this.authTribP1Form.get('appealDetails') as UntypedFormArray).controls;
      let element1;
      for (let i = 0; i < submissionsFrmArray.length; i++) {
        element1 = (submissionsFrmArray[i].get('ackCopyLoc') as UntypedFormArray).controls;
      }
      let count = element1?.some((x: any) =>
        x.get('fileLoc')?.value != null);
      if (!count) {
        this.toaster.showError("Required Fields Are Empty");
        return;
      }
    }
    if (this.authTribP1Form.valid && isTabValid) {
      if (phaseTab === 'p3') {
        this.p2validation();
        let dataGstatOrderCopyLoc = (this.authTribP1Form.get('gstatOrderCopyLoc') as UntypedFormArray).controls;
        var count = dataGstatOrderCopyLoc?.some((x) => x.get('fileLoc')?.value != '');
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
        let alArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormArray
      for (let index = 0; index < alArray.controls.length; index++) {
        let alControl = alArray.controls[index] as UntypedFormGroup;

        if (alControl.controls.challanNo.value || alControl.controls.total.value) {
          let copyOfFormLocControls = (alControl.get('copyOfFormLoc') as UntypedFormArray).controls;
         
          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = alControl.controls.issueName.value;
          let tagControls = alControl.controls.tag.value;
          let dateOfPaymentControls = alControl.controls.dateOfPayment.value ;
          let challanNoControls = alControl.controls.challanNo.value ;
          //let hasissueName = issueNam eControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
            this.toaster.showError("Required Fields Are Empty in Payment Table.");
            return;
          }
        }      
       
       
      }
      }
      data = this.authTribP1Form.value;
      data.state = this.selectedStateName;
      data.caseType = this.caseType;
      let urlData = {
        typeVal: this.tabType[3].key,
        gstin: this.selectedGstin,
      }
      data.cumulative = this.cumulativeCount;
      data.prevStage = this.caseData.prevStage;


      Object.assign(
        data,
        { action: 'UPDATE' },
        { phase: '1' },
        { keyword: 'keyword1' }
      );
      let bookLocArray: any[] = [];
      let otherAttachmentLoc1Array: any[] = [];
      let protestCopyFormArray: any[] = [];
      let protestBankChallanArray: any[] = [];
      let preDepoCopyFormArray: any[] = [];
      let preDepoBankChallanArray: any[] = [];
      let appealAckCopyArray: any[] = [];
      let phAdjournmentLetterLocArray: any[] = [];
      let appealOtherAttachArray: any[] = [];
      let phCopyLocArray: any[] = [];
      let additionalSubmissionLocArray: any[] = [];
      let phRecordIfAnyLocArray: any[] = [];
      let gstatOrderCopyLocArray: any[] = [];
      let liabilityCopyFormArray: any[] = [];
      let liabilityBankChallanArray: any[] = [];
      for (let i = 0; i < this.authTribP1Form.controls.appealBookLoc.value.length; i++) {
        const element = this.authTribP1Form.controls.appealBookLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          bookLocArray.push(element)
        }

      }
      for (let i = 0; i < this.authTribP1Form.controls.otherAttachmentsLoc1.value.length; i++) {
        const element = this.authTribP1Form.controls.otherAttachmentsLoc1.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachmentLoc1Array.push(element)
        }

      }
      for (let i = 0; i < this.authTribP1Form.controls.predepositDetails.value.length; i++) {
        preDepoCopyFormArray = []
        const copyFormArray = this.authTribP1Form.controls.predepositDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            preDepoCopyFormArray.push(element)
          }
        }
        this.authTribP1Form.controls.predepositDetails.value[i].copyOfFormLoc = preDepoCopyFormArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.predepositDetails.value.length; i++) {
        preDepoBankChallanArray = [];
        const bankChallanArray = this.authTribP1Form.controls.predepositDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            preDepoBankChallanArray.push(element)
          }
        }
        this.authTribP1Form.controls.predepositDetails.value[i].copyOfBankChallanLoc = preDepoBankChallanArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.protestDetails.value.length; i++) {
        protestCopyFormArray = []
        const copyFormArray = this.authTribP1Form.controls.protestDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestCopyFormArray.push(element)
          }
        }
        this.authTribP1Form.controls.protestDetails.value[i].copyOfFormLoc = protestCopyFormArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.protestDetails.value.length; i++) {
        protestBankChallanArray = [];
        const bankChallanArray = this.authTribP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestBankChallanArray.push(element)
          }
        }
        this.authTribP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc = protestBankChallanArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.appealDetails.value.length; i++) {
        appealAckCopyArray = [];
        const appealAckCopyArrayData = this.authTribP1Form.controls.appealDetails.value[i].ackCopyLoc;

        for (let j = 0; j < appealAckCopyArrayData.length; j++) {
          const element = appealAckCopyArrayData[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            appealAckCopyArray.push(element)
          }
        }
        this.authTribP1Form.controls.appealDetails.value[i].ackCopyLoc = appealAckCopyArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.appealDetails.value.length; i++) {
        appealOtherAttachArray = [];
        const appealOtherAttachArrayData = this.authTribP1Form.controls.appealDetails.value[i].otherAttachmentsLoc;

        for (let j = 0; j < appealOtherAttachArrayData.length; j++) {
          const element = appealOtherAttachArrayData[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            appealOtherAttachArray.push(element)
          }
        }
        this.authTribP1Form.controls.appealDetails.value[i].otherAttachmentsLoc = appealOtherAttachArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.itemList.value.length; i++) {
        phCopyLocArray = [];
        const elementPhCopyArray = this.authTribP1Form.controls.itemList.value[i].phCopyLoc;

        for (let j = 0; j < elementPhCopyArray.length; j++) {
          const element = elementPhCopyArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phCopyLocArray.push(element)
          }
        }
        this.authTribP1Form.controls.itemList.value[i].phCopyLoc = phCopyLocArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.itemList.value.length; i++) {
        additionalSubmissionLocArray = [];
        const elementAddSubArray = this.authTribP1Form.controls.itemList.value[i].additionalSubmissionLoc;

        for (let j = 0; j < elementAddSubArray.length; j++) {
          const element = elementAddSubArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            additionalSubmissionLocArray.push(element)
          }
        }
        this.authTribP1Form.controls.itemList.value[i].additionalSubmissionLoc = additionalSubmissionLocArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.itemList.value.length; i++) {
        phAdjournmentLetterLocArray = [];
        const phAdjournmentArray = this.authTribP1Form.controls.itemList.value[i].phAdjournmentLetterLoc;

        for (let j = 0; j < phAdjournmentArray.length; j++) {
          const element = phAdjournmentArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phAdjournmentLetterLocArray.push(element)
          }
        }
        this.authTribP1Form.controls.itemList.value[i].phAdjournmentLetterLoc = phAdjournmentLetterLocArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.itemList.value.length; i++) {
        phRecordIfAnyLocArray = [];
        const phRecordArray = this.authTribP1Form.controls.itemList.value[i].phRecordIfAnyLoc;

        for (let j = 0; j < phRecordArray.length; j++) {
          const element = phRecordArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phRecordIfAnyLocArray.push(element)
          }
        }
        this.authTribP1Form.controls.itemList.value[i].phRecordIfAnyLoc = phRecordIfAnyLocArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.gstatOrderCopyLoc.value.length; i++) {
        const element = this.authTribP1Form.controls.gstatOrderCopyLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          gstatOrderCopyLocArray.push(element)
        }

      }
      for (let i = 0; i < this.authTribP1Form.controls.liabilityDetails.value.length; i++) {
        liabilityCopyFormArray = [];
        const liabilitycopyFormArray = this.authTribP1Form.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < liabilitycopyFormArray.length; j++) {
          const element = liabilitycopyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityCopyFormArray.push(element)
          }
        }
        this.authTribP1Form.controls.liabilityDetails.value[i].copyOfFormLoc = liabilityCopyFormArray;
      }
      for (let i = 0; i < this.authTribP1Form.controls.liabilityDetails.value.length; i++) {
        liabilityBankChallanArray = [];
        const bankChallanArray = this.authTribP1Form.controls.liabilityDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityBankChallanArray.push(element)
          }
        }
        this.authTribP1Form.controls.liabilityDetails.value[i].copyOfBankChallanLoc = liabilityBankChallanArray;
      }
      this.authTribP1Form.value.appealBookLoc = bookLocArray;
      this.authTribP1Form.value.otherAttachmentsLoc1 = otherAttachmentLoc1Array;
      this.authTribP1Form.value.gstatOrderCopyLoc = gstatOrderCopyLocArray;

      this.inrFormattingRemoving();
      this.litService.updateAppellateTribDate(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          if (phaseTab === 'p1') {
            this.isPhase2 = true;
          } else if (phaseTab === 'p2') {
            this.isPhase3 = true;
          } else if (phaseTab === 'p3') {
            if ((this.authTribP1Form.value.whetherAppealPreferred === true || this.authTribP1Form.value.whetherAppealPreferred === 'true') && this.authTribP1Form.value.outcome === 'Remanded Back') {
              this.router.navigate(['/litigationSummary']);
            }
            else if ((this.authTribP1Form.value.whetherAppealPreferred === true || this.authTribP1Form.value.whetherAppealPreferred === 'true') && this.authTribP1Form.value.forumOfAppeal === 'hc') {
              this.litService.setAccessTabData({ scnFlag: true, ajaFlag: true, apaFlag: true, aptFlag: true, hcFlag: false, scFlag: false });
              this.callTabFunction.emit('tab5');
            } else if ((this.authTribP1Form.value.whetherAppealPreferred === true || this.authTribP1Form.value.whetherAppealPreferred === 'true') && this.authTribP1Form.value.forumOfAppeal === 'sc') {
              this.litService.setAccessTabData({ scnFlag: true, ajaFlag: true, apaFlag: true, aptFlag: true, hcFlag: true, scFlag: false });
              this.callTabFunction.emit('tab6');
            } else if (this.authTribP1Form.value.forumOfAppeal === 'hc' && (this.authTribP1Form.value.writAppPreferred === true || this.authTribP1Form.value.writAppPreferred === 'true')) {
              this.litService.setAccessTabData({ scnFlag: true, ajaFlag: true, apaFlag: true, aptFlag: true, hcFlag: false, scFlag: false });
              this.callTabFunction.emit('tab5');
            } else if (this.authTribP1Form.value.forumOfAppeal === 'sc' && (this.authTribP1Form.value.writAppPreferred === true || this.authTribP1Form.value.writAppPreferred === 'true')) {
              this.litService.setAccessTabData({ scnFlag: true, ajaFlag: true, apaFlag: true, aptFlag: true, hcFlag: false, scFlag: false });
              this.callTabFunction.emit('tab6');
            }
            else if (this.authTribP1Form.value.forumOfAppeal === 'sc' && (this.authTribP1Form.value.whetherDptAppealPreferred === true || this.authTribP1Form.value.whetherDptAppealPreferred === 'true')) {
              this.callTabFunction.emit('tab6');
            } else if (this.authTribP1Form.value.whetherDptAppealPreferred === true || this.authTribP1Form.value.whetherDptAppealPreferred === 'true') {
              this.litService.setAccessTabData({ scnFlag: true, ajaFlag: true, apaFlag: true, aptFlag: true, hcFlag: false, scFlag: false });
              this.callTabFunction.emit('tab5');
            } else {
              this.router.navigate(['/litigationSummary']);
            }
          }
          this.toaster.showSuccess(response.message);
          this.isDataSaved = true;
        } else {
          this.toaster.showError(response.message);
        }
        //console.log("updateLetter");
        //console.log(this.authTribP1Form.value);
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }

  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2011;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push({ year: financialYrRange, disabled: false });
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }
  getFileNameDta(fileUrl: any) {
    if (fileUrl && typeof (fileUrl) == 'string')
      return this.litService.getFileName(fileUrl);

  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.litService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        //this.downloadfileUrl() ;
        window.open(this.fileUrl, '_blank');

      } else {
      }
    })
  }

  uploadbankChallanLoc() {
    this.isbankChallanLocFile = true;
    this.isbankChallanLocText = false;
    this.isDWbankChallanLoc = false;
    this.isUPbankChallanLoc = false;
    this.authTribP1Form.value.bankChallanLoc = null;
  }

  uploaddrc03CopyLoc() {
    this.isdrc03CopyLocFile = true;
    this.isdrc03CopyLocText = false;
    this.isDWdrc03CopyLoc = false;
    this.isUPdrc03CopyLoc = false;
    this.authTribP1Form.value.drc03CopyLoc = null;
  }
  uploadappealBookLoc() {
    this.isappealBookLocFile = true;
    this.isappealBookLocText = false;
    this.isDWappealBookLoc = false;
    this.isUPappealBookLoc = false;
    this.appealBookLocReq = false;
    this.authTribP1Form.value.appealBookLoc = null;
  }

  uploadotherAttachmentsLoc1() {
    this.isotherAttachmentsLoc1File = true;
    this.isotherAttachmentsLoc1Text = false;
    this.isDWotherAttachmentsLoc1 = false;
    this.isUPotherAttachmentsLoc1 = false;
    this.authTribP1Form.value.otherAttachmentsLoc1 = null;
  }

  uploadcopyOfAckLoc() {
    this.iscopyOfAckLocFile = true;
    this.iscopyOfAckLocText = false;
    this.isDWcopyOfAckLoc = false;
    this.isUPcopyOfAckLoc = false;
    // this.authTribP1Form.value.copyOfAckLoc = null;
  }

  uploadotherAttachmentsLoc2() {
    this.isotherAttachmentsLoc2File = true;
    this.isotherAttachmentsLoc2Text = false;
    this.isDWotherAttachmentsLoc2 = false;
    this.isUPotherAttachmentsLoc2 = false;
    // this.authTribP1Form.value.otherAttachmentsLoc2 = null;
  }

  uploadphCopyLoc(index: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;
    var itemListArray = this.authTribP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhCopyLocUploaded'] = true
        }
      }
    }
  }

  uploadadditionalSubmissionLoc(index: any) {
    this.isadditionalSubmissionLocFile = true;
    this.isadditionalSubmissionLocText = false;
    this.isDWadditionalSubmissionLoc = false;
    this.isUPadditionalSubmissionLoc = false;
    var itemListArray = this.authTribP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isAdditionalSubmissionLocUploaded'] = true
        }
      }
    }
  }


  uploadphRecordIfAnyLoc(index: any) {
    this.isphRecordIfAnyLocFile = true;
    this.isphRecordIfAnyLocText = false;
    this.isDWphRecordIfAnyLoc = false;
    this.isUPphRecordIfAnyLoc = false;
    var itemListArray = this.authTribP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhRecordIfAnyLocUploaded'] = true
        }
      }
    }
  }

  uploadgstatOrderCopyLoc() {
    this.isgstatOrderCopyLocFile = true;
    this.isgstatOrderCopyLocText = false;
    this.isDWgstatOrderCopyLoc = false;
    this.isUPgstatOrderCopyLoc = false;
    this.authTribP1Form.value.gstatOrderCopyLoc = null;
  }

  uploadpaymentChallanLoc() {
    this.ispaymentChallanLocFile = true;
    this.ispaymentChallanLocText = false;
    this.isDWpaymentChallanLoc = false;
    this.isUPpaymentChallanLoc = false;
    // this.authTribP1Form.value.paymentChallanLoc = null;
  }
  setStateAutoPop() {
    if (this.selectedFilling.gstin) {
      this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
    }
    this.selectedStateName = this.commonServ.getStateName(this.selectedStateCode);
  }
  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  personResponsibleData() {
    let model: any = {};
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    this.litService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });

  }

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        console.log('list:', this.consultantList);
        const userMailID: any = sessionStorage.getItem('UserId');
        const matchingConsultant = this.consultantList.find(
          (consultant: any) => consultant.email === userMailID
        );

        if (matchingConsultant) {
          this.authTribP1Form.controls.consultant1.patchValue(matchingConsultant.consultantName)
        }
      }
    });
  }
  onClickToggleConsultantDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleConsultantDropdown = !this.isToggleConsultantDropdown;
    } else if (phase === 'p2') {
      this.isToggleConsultantDropdown2 = !this.isToggleConsultantDropdown2;
    } else if(phase === 'CC'){
      this.isToggleConsultantDropdown3 = !this.isToggleConsultantDropdown3;
    }
  }
  onClickToggleSegmentDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleSegmentDropdown = !this.isToggleSegmentDropdown;
    } else if (phase === 'p2') {
      this.isToggleSegmentDropdown2 = !this.isToggleSegmentDropdown2;
    }
  }

  issueDropdown() {
    this.dataSource = ISSUE_GST;
  }
  openRmvIssuePop(content: any, i: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }
  openDetailPop(content: any, i: any) {
    this.modalService.open(content);
    this.finYearSelelctEvent(i);
    this.quarterSelectEvent(i);
  }
  openRmvDetailPop(content: any, i: any, k: any) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }
  savecaseItemList() {
    const itemList = this.authTribP1Form.value.itemList;
    if (itemList.length > 0) {
      itemList.forEach((item: any, i: number) => {
        if (!this.caseItemList[i]) {
          this.caseItemList.push({});
        }
        if (item.phCopyLoc) {
          this.caseItemList[i].phCopyLoc = item.phCopyLoc;
        }
        if (item.additionalSubmissionLoc) {
          this.caseItemList[i].additionalSubmissionLoc = item.additionalSubmissionLoc;

        }
        if (item.phRecordIfAnyLoc) {
          this.caseItemList[i].phRecordIfAnyLoc = item.phRecordIfAnyLoc;
        }
        if (item.phAdjournmentLetterLoc) {
          this.caseItemList[i].phAdjournmentLetterLoc = item.phAdjournmentLetterLoc;
        }
      })

      // this.caseItemList.push()
    }
  }

  setCaseItemList() {
    this.authTribP1Form.value.itemList.forEach((data: any, i: number) => {
      if (!data.phCopyLoc) {
        data.phCopyLoc = this.caseItemList[i]?.phCopyLoc;
      }
      if (!data.additionalSubmissionLoc) {
        data.additionalSubmissionLoc = this.caseItemList[i]?.additionalSubmissionLoc;

      }
      if (!data.phRecordIfAnyLoc) {
        data.phRecordIfAnyLoc = this.caseItemList[i]?.phRecordIfAnyLoc;

      }
      if (!data.phAdjournmentLetterLoc) {
        data.phAdjournmentLetterLoc = this.caseItemList[i]?.phAdjournmentLetterLoc;
      }
    })
  }
  finYearSelelctEvent(i: any) {
    let issuesFrmArray = this.authTribP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    const alreadySelectedFinYear = detailsFrmArray.value?.map((data: any) => data.finYear);
    this.finyearData.forEach(f => {
      if (alreadySelectedFinYear.includes(f.year)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }
  quarterSelectEvent(i: any) {
    let issuesFrmArray = this.authTribP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    const alreadySelectedQuarter = detailsFrmArray.value?.map((data: any) => data.quarter);
    this.quarterList.forEach((f: any) => {
      if (alreadySelectedQuarter.includes(f.value)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }

  issueValueChange() {
    let igst1Total = 0
    let cgst1Total = 0;
    let sgst1Total = 0;
    let cess1Total = 0;
    let interest1Total = 0;
    let penalty1Total = 0;

    let cess3Total = 0;
    let cgst3Total = 0;
    let igst3Total = 0;
    let interest3Total = 0;
    let penalty3Total = 0;
    let sgst3Total = 0;

    (this.authTribP1Form.get('issues') as UntypedFormArray).controls.forEach((c) => {
      const igst1 = this.inrService.removeCommasAndParseNumber(c.value.igst1);
      const cgst1 = this.inrService.removeCommasAndParseNumber(c.value.cgst1);
      const sgst1 = this.inrService.removeCommasAndParseNumber(c.value.sgst1);
      const cess1 = this.inrService.removeCommasAndParseNumber(c.value.cess1);
      const interest1 = this.inrService.removeCommasAndParseNumber(c.value.interest1);
      const penalty1 = this.inrService.removeCommasAndParseNumber(c.value.penalty1);
      const igst3 = this.inrService.removeCommasAndParseNumber(c.value.igst3);
      const cgst3 = this.inrService.removeCommasAndParseNumber(c.value.cgst3);
      const sgst3 = this.inrService.removeCommasAndParseNumber(c.value.sgst3);
      const cess3 = this.inrService.removeCommasAndParseNumber(c.value.cess3);
      const interest3 = this.inrService.removeCommasAndParseNumber(c.value.interest3);
      const penalty3 = this.inrService.removeCommasAndParseNumber(c.value.penalty3);

      igst1Total += Number(igst1);
      cgst1Total += Number(cgst1);
      sgst1Total += Number(sgst1);
      cess1Total += Number(cess1);
      interest1Total += Number(interest1);
      penalty1Total += Number(penalty1);
      igst3Total += Number(igst3);
      cgst3Total += Number(cgst3);
      sgst3Total += Number(sgst3);
      cess3Total += Number(cess3);
      interest3Total += Number(interest3);
      penalty3Total += Number(penalty3);
      c.patchValue({ total1: this.inrService.formatCurrency(String(Number(igst1) + Number(cgst1) + Number(sgst1) + Number(cess1) + Number(interest1) + Number(penalty1))) });
      c.patchValue({ total3: this.inrService.formatCurrency(String(Number(igst3) + Number(cgst3) + Number(sgst3) + Number(cess3) + Number(interest3) + Number(penalty3))) });

    })
    this.authTribP1Form.controls['igst1Total'].setValue(this.inrService.formatCurrency(String(igst1Total)));
    this.authTribP1Form.controls['cgst1Total'].setValue(this.inrService.formatCurrency(String(cgst1Total)));
    this.authTribP1Form.controls['sgst1Total'].setValue(this.inrService.formatCurrency(String(sgst1Total)));
    this.authTribP1Form.controls['cess1Total'].setValue(this.inrService.formatCurrency(String(cess1Total)));
    this.authTribP1Form.controls['interest1Total'].setValue(this.inrService.formatCurrency(String(interest1Total)));
    this.authTribP1Form.controls['penalty1Total'].setValue(this.inrService.formatCurrency(String(penalty1Total)));
    this.authTribP1Form.controls['cess3Total'].setValue(this.inrService.formatCurrency(String(cess3Total)));
    this.authTribP1Form.controls['cgst3Total'].setValue(this.inrService.formatCurrency(String(cgst3Total)));
    this.authTribP1Form.controls['igst3Total'].setValue(this.inrService.formatCurrency(String(igst3Total)));
    this.authTribP1Form.controls['sgst3Total'].setValue(this.inrService.formatCurrency(String(sgst3Total)));
    this.authTribP1Form.controls['penalty3Total'].setValue(this.inrService.formatCurrency(String(penalty3Total)));
    this.authTribP1Form.controls['interest3Total'].setValue(this.inrService.formatCurrency(String(interest3Total)));

    this.authTribP1Form.controls['totOfTotal1'].setValue(this.inrService.formatCurrency(String(Number((+igst1Total) + (+cgst1Total) + (+sgst1Total) + (+cess1Total) + (+interest1Total) + (+penalty1Total)))));
    this.authTribP1Form.controls['totOfTotal3'].setValue(this.inrService.formatCurrency(String(Number((+cess3Total) + (+cgst3Total) + (+igst3Total) + (+sgst3Total) + (+penalty3Total) + (+interest3Total)))));
  }

  checkTypeOfAuthoritySelected(event: any) {
    if (event.target.value === 'CGST') {
      this.authTribP1Form.get('din1')?.setErrors({ 'required': true });
    } else {
      this.authTribP1Form.get('din1')?.setErrors(null);
    }
  }

  checkTypeOfAuthority3Selected(event: any) {
    if (event.target.value === 'CGST') {
      this.authTribP1Form.get('din3')?.setErrors({ 'required': true });
    } else {
      this.authTribP1Form.get('din3')?.setErrors(null);
    }
  }

  preDepositeChanged() {
    let igstTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let cessTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let preDepositeTotal = 0;

    (this.authTribP1Form.get('predepositDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
      const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
      const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
      const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);
      const penalty = this.inrService.removeCommasAndParseNumber(c.value.penalty);
      igstTotal += Number(igst);
      cgstTotal += Number(cgst);
      sgstTotal += Number(sgst);
      cessTotal += Number(cess);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(igst)) + (+Number(cgst)) + (+Number(sgst)) + (+Number(cess)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      preDepositeTotal += Number(total);
    })

    this.preDepositeInterestTotal = interestTotal;
    this.preDepositeTaxTotal = igstTotal;
    this.preDepositeCgstTotal = cgstTotal;;
    this.preDepositeSgstTotal = sgstTotal;
    this.preDepositeCessTotal = cessTotal;
    this.preDepositePenaltyTotal = penaltyTotal;
    this.preDepositeTotal = preDepositeTotal;
  }

  protestDetailsChanged() {
    let igstTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let protestDetailTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let cessTotal = 0;

    (this.authTribP1Form.get('protestDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
      const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
      const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
      const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);
      const penalty = this.inrService.removeCommasAndParseNumber(c.value.penalty);
      igstTotal += Number(igst);
      cgstTotal += Number(cgst);
      sgstTotal += Number(sgst);
      cessTotal += Number(cess);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(igst)) + Number(cgst) + Number(sgst) + Number(cess) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      protestDetailTotal += Number(total);


    })

    this.protestInterestTotal = interestTotal;
    this.protestTaxTotal = igstTotal;
    this.protestcgstTotal = cgstTotal;
    this.protestsgstTotal = sgstTotal;
    this.protestcessTotal = cessTotal;
    this.protestPenaltyTotal = penaltyTotal;
    this.protestTotal = protestDetailTotal;
  }

  openProceedWithPreferredForumAppealPop(content: any) {
    this.proceedWithPreferredForumAppeal = this.modalService.open(content);
  }

  rmProceedWithPreferredForumAppeal(removeSelectedValue: any = false) {
    if (removeSelectedValue) this.authTribP1Form.get('forumOfAppeal')?.setValue('');
    this.proceedWithPreferredForumAppeal.close();
  }

  getForumAppealValue(value: any) {

    let [findValue] = this.forumAppeal.filter(forum => {
      return forum.key === value
    })
    if (findValue) return findValue.value
    return
  }

  addDetailsOfLiabilityAddmittedAndPaid() {
    let liabilityAddmittedAndPaidFrmGrp = this.detailsOfLiabilityAddmittedAndPaid();
    (this.authTribP1Form.get('liabilityDetails') as UntypedFormArray).push(liabilityAddmittedAndPaidFrmGrp);

  }

  detailsOfLiabilityAddmittedAndPaidArr(): UntypedFormGroup[] {
    return (this.authTribP1Form.get('liabilityDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  rmLiabilityAddmittedAndPaidInv(i: number) {
    (this.authTribP1Form.get('liabilityDetails') as UntypedFormArray).removeAt(i);
    this.rmvLiabilityAddmittedAndPaidModalRef.close();
    this.liabilityAddmittedAndPaidChanged()
  }

  openLiabilityAddmittedAndPaidPop(content: any) {
    this.rmvLiabilityAddmittedAndPaidModalRef = this.modalService.open(content);
  }

  detailsOfLiabilityAddmittedAndPaid(data: any = null) {
    return this.fBuild.group({
      issueName: [data ? data?.issueName : null],
      tag: [data ? data?.tag : null],
      dateOfPayment: [data ? data?.dateOfPayment : null],
      markedForPayment: [data ? data?.markedForPayment : null],
      formOfPayment: [data ? data?.formOfPayment : null],
      challanNo: [data ? data?.challanNo : null],
      modeOfPayment: [data ? data?.modeOfPayment : null],
      accountingRef: [data ? data?.accountingRef : null, [Validators.maxLength(100)]],
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: [data ? data?.igst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst: [data ? data?.cgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst: [data ? data?.sgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess: [data ? data?.cess : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
    })
  }

  deleteliabilityDetailsFile(i: any) {
    let liabilityArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  deleteliabilityFile(i: any) {
    let liabilityArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  liabilityAddmittedAndPaidChanged() {
    let igstTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let cessTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let liabilityPaidTotal = 0;

    (this.authTribP1Form.get('liabilityDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
      const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
      const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
      const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);
      const penalty = this.inrService.removeCommasAndParseNumber(c.value.penalty);
      igstTotal += Number(igst);
      cgstTotal += Number(cgst);
      sgstTotal += Number(sgst);
      cessTotal += Number(cess);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(igst)) + (Number(cgst)) + (Number(sgst)) + (Number(cess)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      liabilityPaidTotal += Number(total);
    })

    this.liabilityAddmittedAndPaidInterestTotal = interestTotal;
    this.liabilityAddmittedAndPaidTaxTotal = igstTotal;
    this.liabilityAddmittedAndPaidCgstTotal = cgstTotal;
    this.liabilityAddmittedAndPaidSgstTotal = sgstTotal;
    this.liabilityAddmittedAndPaidCessTotal = cessTotal;
    this.liabilityAddmittedAndPaidPenaltyTotal = penaltyTotal;
    this.liabilityAddmittedAndPaidTotal = liabilityPaidTotal;
  }

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  // setCummulativeCount(){
  //   let preDepositeTax = 0;
  //   let preDepositePenalty   = 0;
  //   let preDepositeInterest = 0;
  //   let preDepositeTotal = 0;

  //   let protestPaymentTax = 0;
  //    let protestPaymentPenalty = 0;
  //    let protestPaymentInterest = 0;
  //    let protestPaymentTotal = 0;

  //    let liabilityPaymentTax = 0
  //    let liabilityPaymentPenalty = 0;
  //    let liabilityPaymentInterest = 0;
  //    let liabilityPaymentTotal = 0;

  //    let consultantFees = 0;

  //   if(this.caseData.cumulative && this.caseData.cumulative.length > 0){
  //     this.caseData.cumulative.forEach((p: any) =>{
  //       if(p.label === 'Cumulative payment made under protest'){
  //         p.tax ? protestPaymentTax += p.tax : null;
  //         p.penalty ? protestPaymentPenalty += p.penalty : null;
  //         p.interest ? protestPaymentInterest += p.interest : null;
  //         p.total ? protestPaymentTotal +=p.total : null;
  //       }else if(p.label === 'Cumulative payment made for admitted liability'){
  //         p.tax ? liabilityPaymentTax += p.tax : null;
  //         p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //         p.interest ? liabilityPaymentInterest += p.interest : null;
  //         p.total ? liabilityPaymentTotal +=p.total : null;
  //       }else if(p.label === 'Cumulative Consultant Charges'){
  //         p.total ? consultantFees += p.total : null;

  //       }
  //       })
  //    }
  //   this.authTribP1Form.value.protestDetails.forEach((p : any) =>{
  //     p.tax ? protestPaymentTax += p.tax : null;
  //     p.penalty ? protestPaymentPenalty += p.penalty : null;
  //     p.interest ? protestPaymentInterest += p.interest : null;
  //     p.total ? protestPaymentTotal +=p.total : null;
  //   });
  //   this.authTribP1Form.value.liabilityDetails.forEach((p : any) =>{
  //     p.tax ? liabilityPaymentTax += p.tax : null;
  //     p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //     p.interest ? liabilityPaymentInterest += p.interest : null;
  //     p.total ? liabilityPaymentTotal +=p.total : null;

  //   });

  //   this.authTribP1Form.value.predepositDetails.forEach((p:any) => {
  //     p.tax ? preDepositeTax += p.tax : null;
  //     p.penalty ? preDepositePenalty += p.penalty : null;
  //     p.interest ? preDepositeInterest += p.interest : null;
  //     p.total ? preDepositeTotal +=p.total : null;
  //    });

  //   this.authTribP1Form.value.consultantCharges.forEach((c : any) =>{
  //     c.fees ? consultantFees += c.fees : null;
  //   });
  //   this.cumulativeCount.forEach((c: any) =>{
  //     if(c.label === 'Cumulative Pre-Deposit'){
  //       preDepositeTax ?  c.tax = preDepositeTax  : null;
  //       preDepositePenalty ?  c.penalty = preDepositePenalty  : null;
  //       preDepositeInterest ?  c.interest = preDepositeInterest  : null;
  //       preDepositeTotal ?  c.total = preDepositeTotal  : null;
  //       }
  //       if(c.label === 'Cumulative payment made under protest'){
  //         protestPaymentTax ?  c.tax = protestPaymentTax  : null;
  //         protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
  //         protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
  //         protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
  //       }else if(c.label === 'Cumulative payment made for admitted liability'){
  //         liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
  //         liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
  //         liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
  //         liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
  //       }else if(c.label === 'Cumulative Consultant Charges'){
  //         consultantFees ? c.total = consultantFees  : null;
  //       }
  //   });
  // }

  setCummulativeCount(oldArr: any = null, newArr: any = null, tableName: any = null) {
    // let protestPaymentTax = 0;
    // let protestPaymentPenalty = 0;
    // let protestPaymentInterest = 0;
    // let protestPaymentTotal = 0;

    // let liabilityPaymentTax = 0
    // let liabilityPaymentPenalty = 0;
    // let liabilityPaymentInterest = 0;
    // let liabilityPaymentTotal = 0;

    // let consultantFees = 0;

    // let preDepositeTax = 0;
    // let preDepositePenalty = 0;
    // let preDepositeInterest = 0;
    // let preDepositeTotal = 0;

    // let oldTotalTax :any= 0, oldTotalInterrest :any= 0 , oldTotalPenalty:any = 0;
    // let newTotalTax :any= 0, newTotalInterrest :any= 0 , newTotalPenalty:any = 0;
    // let oldFees :any=0, newFees:any= 0;

    // if(this.caseData.cumulative && this.caseData.cumulative.length > 0){
    //   this.caseData.cumulative.forEach((p: any) =>{
    //     if(p.label === 'Cumulative Pre-Deposit'){
    //       p.tax ? preDepositeTax += p.tax : null;
    //       p.penalty ? preDepositePenalty += p.penalty : null;
    //       p.interest ? preDepositeInterest += p.interest : null;
    //       p.total ? preDepositeTotal +=p.total : null;
    //     }
    //     if(p.label === 'Cumulative payment made under protest'){
    //       p.tax ? protestPaymentTax += p.tax : null;
    //       p.penalty ? protestPaymentPenalty += p.penalty : null;
    //       p.interest ? protestPaymentInterest += p.interest : null;
    //       p.total ? protestPaymentTotal +=p.total : null;
    //     }else if(p.label === 'Cumulative payment made for admitted liability'){
    //       p.tax ? liabilityPaymentTax += p.tax : null;
    //       p.penalty ? liabilityPaymentPenalty += p.penalty : null;
    //       p.interest ? liabilityPaymentInterest += p.interest : null;
    //       p.total ? liabilityPaymentTotal +=p.total : null;
    //     }else if(p.label === 'Cumulative Consultant Charges'){
    //       p.total ? consultantFees += p.total : null;
    //     }
    //     })
    //  }

    // //array is not null and empty
    // if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== 'consultantCharges'){
    //   oldArr.forEach((p : any) =>{
    //     if( p.tax != null)
    //     oldTotalTax +=  p.tax ;
    //     if( p.penalty != null)
    //     oldTotalPenalty += p.penalty;
    //     if( p.interest != null)
    //     oldTotalInterrest += p.interest;

    //   });
    //   newArr.forEach((p : any) =>{
    //     if( p.tax != null)
    //     newTotalTax +=  p.tax ;
    //     if( p.penalty != null)
    //     newTotalPenalty += p.penalty;
    //     if( p.interest != null)
    //     newTotalInterrest += p.interest;
    //   });
    // }else if(tableName === 'consultantCharges'){
    //   oldArr.forEach((p : any) =>{
    //    if(p.fees != null){
    //      oldFees += p.fees;
    //    }
    //   });

    //   newArr.forEach((p : any) =>{
    //     if(p.fees != null){
    //       newFees += p.fees;
    //     }
    //   })
    // }

    // if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== null){
    //   this.cumulativeCount.forEach((c: any) =>{
    //     if(c.label === 'Cumulative Pre-Deposit' && tableName === 'predepositDetails'){
    //       c.tax = preDepositeTax - oldTotalTax + newTotalTax ;
    //       c.penalty = preDepositePenalty - oldTotalPenalty + newTotalPenalty ;
    //       c.interest = preDepositeInterest - oldTotalInterrest + newTotalInterrest ;
    //       c.total = c.tax + c.penalty + c.interest  ;
    //     }
    //   if(c.label === 'Cumulative payment made under protest' &&  tableName === 'protestDetails'){
    //     c.tax = protestPaymentTax - oldTotalTax + newTotalTax  ;
    //     c.penalty = protestPaymentPenalty - oldTotalPenalty + newTotalPenalty  ;
    //     c.interest = protestPaymentInterest - oldTotalInterrest + newTotalInterrest  ;
    //     c.total = c.tax + c.penalty + c.interest  ;
    //   }
    //   else if(c.label === 'Cumulative payment made for admitted liability' && tableName === 'liabilityDetails'){
    //     c.tax = liabilityPaymentTax - oldTotalTax + newTotalTax  ;
    //     c.penalty = liabilityPaymentPenalty - oldTotalPenalty + newTotalPenalty ;
    //     c.interest = liabilityPaymentInterest - oldTotalInterrest + newTotalInterrest ;
    //     c.total = c.tax + c.penalty + c.interest  ;
    //   }else if(c.label === 'Cumulative Consultant Charges' && tableName === 'consultantCharges'){
    //     c.total = consultantFees - oldFees + newFees  ;
    //   }
    //   });
    //   this.caseData.cumulative = this.cumulativeCount;
    // }else{
    //   this.cumulativeCount.forEach((c: any) =>{
    //     if(c.label === 'Cumulative Pre-Deposit'){
    //       preDepositeTax ?  c.tax = preDepositeTax  : null;
    //       preDepositePenalty ?  c.penalty = preDepositePenalty  : null;
    //       preDepositeInterest ?  c.interest = preDepositeInterest  : null;
    //       preDepositeTotal ?  c.total = preDepositeTotal  : null;
    //     }
    //     if(c.label === 'Cumulative payment made under protest'){
    //       protestPaymentTax ?  c.tax = protestPaymentTax  : null;
    //       protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
    //       protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
    //       protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
    //     }else if(c.label === 'Cumulative payment made for admitted liability'){
    //       liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
    //       liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
    //       liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
    //       liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
    //     }else if(c.label === 'Cumulative Consultant Charges'){
    //       consultantFees ? c.total = consultantFees  : null;
    //     }
    //   });
    // }
    // if(tableName === 'protestDetails') this.protestDetailsChanged();
    // if(tableName === 'predepositDetails') this.preDepositeChanged();
    // if(tableName === 'consultantCharges') this.feesChargedChanged();
    // if(tableName === 'liabilityDetails') this.liabilityAddmittedAndPaidChanged();
  }

  initializeDetailOfLiability() {
    if (this.caseData.liabilityDetails !== undefined) {
      this.caseData.liabilityDetails.forEach((liabilityDetails: any) => {
        let liabilityAddmittedAndPaidFrmGrp = this.detailsOfLiabilityAddmittedAndPaid(liabilityDetails);
        (this.authTribP1Form.get('liabilityDetails') as UntypedFormArray).push(liabilityAddmittedAndPaidFrmGrp);

        if (liabilityDetails.copyOfFormLoc) {
          liabilityDetails.copyOfFormLoc.forEach((not: any) => {
            let copyFormGrp: UntypedFormGroup = this.createLiabilityCopyForm(not);
            (liabilityAddmittedAndPaidFrmGrp.get('copyOfFormLoc') as UntypedFormArray).push(copyFormGrp);
          });
        }
        if (liabilityDetails.copyOfBankChallanLoc) {
          liabilityDetails.copyOfBankChallanLoc.forEach((not: any) => {
            let banckChallanGrp: UntypedFormGroup = this.createLiabilityBankChallan(not);
            (liabilityAddmittedAndPaidFrmGrp.get('copyOfBankChallanLoc') as UntypedFormArray).push(banckChallanGrp);
          });
        }
      })
    }
  }

  saveLiabilityDetailsList() {
    const appealDetailList = this.authTribP1Form.value.liabilityDetails;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.liabilityAdmittedItemList[i]) {
          this.liabilityAdmittedItemList.push({});
        }
        if (item.copyOfFormLoc) {
          this.liabilityAdmittedItemList[i].copyOfFormLoc = item.copyOfFormLoc;
        }
        if (item.copyOfBankChallanLoc) {
          this.liabilityAdmittedItemList[i].copyOfBankChallanLoc = item.copyOfBankChallanLoc;
        }
      })
    }
    this.authTribP1Form.controls.liabilityDetails.patchValue(appealDetailList);
  }
  setLiabilityDetailsList() {
    this.authTribP1Form.value.liabilityDetails.forEach((data: any, i: number) => {
      if (!data.copyOfFormLoc) {
        data.copyOfFormLoc = this.liabilityAdmittedItemList[i]?.copyOfFormLoc;
      }
      if (!data.copyOfBankChallanLoc) {
        data.copyOfBankChallanLoc = this.liabilityAdmittedItemList[i]?.copyOfBankChallanLoc;
      }
    })
  }

  LiabilityuploadCopyOfFormLoc(i: any) {
    var liabilityDetailsArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityDetailsArray.controls.length > 0) {
      for (var index = 0; index < liabilityDetailsArray.controls.length; index++) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.isLiabilityCopyOfFormLocDocFile = true;
    this.isLiabilityCopyOfFormLocDocText = false;
    this.isLiabilityCopyOfFormLocDocDWText = true;
    this.isLiabilityCopyOfFormLocUPText = false;
  }

  LiabilityuploadCopyOfBankChallanLoc(i: any) {
    var liabilityDetailsArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityDetailsArray.controls.length > 0) {
      for (var index = 0; index < liabilityDetailsArray.controls.length; index++) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfBankChallanLocDocUploadedClicked'] = true
        }
      }
    }
    this.isLiabilityCopyOfBankChallanLocFile = true;
    this.isLiabilityCopyOfBankChallanLocText = false;
    this.isLiabilityCopyOfBankChallanLocDWText = true;
    this.isLiabilityCopyOfBankChallanLocUPText = false;
  }

  openRmvconsultantPop(content: any, i: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }

  checkwritAppYes(event: any = null) {
    let data = null;
    if (!event) {
      data = this.authTribP1Form.value.writAppPreferred;
    } else {
      data = event.target.value;
    }
    if (data === 'true') {
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = true;
      this.authTribP1Form.get("whetherAppealPreferred")?.clearValidators();
      this.authTribP1Form.get("whetherAppealPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("whetherAppealPreferred")?.setValue(false);
      this.authTribP1Form.get("whetherDptAppealPreferred")?.clearValidators();
      this.authTribP1Form.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      this.authTribP1Form.get("whetherDptAppealPreferred")?.setValue(false);
      //this.rmWeatherApplErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;

    }
    this.setForumOfAppeal();
  }

  setForumOfAppeal() {
    this.forumAppeal = FORUM_APPEAL_APPELLATE_TRIBUNAL_1;


    if ((this.authTribP1Form.value.whetherAppealPreferred === false || this.authTribP1Form.value.whetherAppealPreferred === "false") &&
      (this.authTribP1Form.value.whetherDptAppealPreferred === false || this.authTribP1Form.value.whetherDptAppealPreferred === "false") &&
      (this.authTribP1Form.value.writAppPreferred === false || this.authTribP1Form.value.writAppPreferred === "false")) {
      this.forumAppeal = this.forumAppeal.filter((c) => (c.key === 'Appeal/Writ'));

      return;
    }
    if ((this.authTribP1Form.value.whetherAppealPreferred === true || this.authTribP1Form.value.whetherAppealPreferred === "true") &&
      (this.authTribP1Form.value.whetherDptAppealPreferred === false || this.authTribP1Form.value.whetherDptAppealPreferred === "false") &&
      (this.authTribP1Form.value.writAppPreferred === false || this.authTribP1Form.value.writAppPreferred === "false")) {
      this.forumAppeal = this.forumAppeal.filter((c) => (c.key === 'hc' || c.key === 'sc'));

      return;
    }
    if ((this.authTribP1Form.value.whetherAppealPreferred === false || this.authTribP1Form.value.whetherAppealPreferred === "false") &&
      (this.authTribP1Form.value.whetherDptAppealPreferred === true || this.authTribP1Form.value.whetherDptAppealPreferred === "true") &&
      (this.authTribP1Form.value.writAppPreferred === false || this.authTribP1Form.value.writAppPreferred === "false")) {
      this.forumAppeal = this.forumAppeal.filter((c) => (c.key === 'hc' || c.key === 'sc'));

      return;
    }
    if ((this.authTribP1Form.value.whetherAppealPreferred === false || this.authTribP1Form.value.whetherAppealPreferred === "false") &&
      (this.authTribP1Form.value.whetherDptAppealPreferred === false || this.authTribP1Form.value.whetherDptAppealPreferred === "false") &&
      (this.authTribP1Form.value.writAppPreferred === true || this.authTribP1Form.value.writAppPreferred === "true")) {
      this.forumAppeal = this.forumAppeal.filter((c) => (c.key === 'hc' || c.key === 'sc'));

      return;
    }
    this.forumAppeal = FORUM_APPEAL_APPELLATE_TRIBUNAL_1;

  }
  refreshCumulativeTable(event: any) {
    event.stopPropagation();
    let refreshValue = this.authTribP1Form.value;
    refreshValue['cumulative'] = this.caseData.cumulative
    refreshValue['prevStage'] = this.prevStage

    if (refreshValue.consultantCharges && refreshValue.consultantCharges.length) {
      refreshValue.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
    }

    if (refreshValue.protestDetails && refreshValue.protestDetails.length) {
      refreshValue.protestDetails.forEach((protestDetail: any) => {
        protestDetail.igst = this.inrService.removeCommasAndParseNumber(protestDetail.igst)
        protestDetail.cgst = this.inrService.removeCommasAndParseNumber(protestDetail.cgst);
        protestDetail.sgst = this.inrService.removeCommasAndParseNumber(protestDetail.sgst);
        protestDetail.cess = this.inrService.removeCommasAndParseNumber(protestDetail.cess);
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
        protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
      })
    }
    if (refreshValue.predepositDetails && refreshValue.predepositDetails.length) {
      refreshValue.predepositDetails.forEach((predepositDetails: any) => {
        predepositDetails.igst = this.inrService.removeCommasAndParseNumber(predepositDetails.igst)
        predepositDetails.cgst = this.inrService.removeCommasAndParseNumber(predepositDetails.cgst);
        predepositDetails.sgst = this.inrService.removeCommasAndParseNumber(predepositDetails.sgst);
        predepositDetails.cess = this.inrService.removeCommasAndParseNumber(predepositDetails.cess);
        predepositDetails.interest = this.inrService.removeCommasAndParseNumber(predepositDetails.interest)
        predepositDetails.penalty = this.inrService.removeCommasAndParseNumber(predepositDetails.penalty)
        predepositDetails.total = this.inrService.removeCommasAndParseNumber(predepositDetails.total)

      })
    }
    if (refreshValue.liabilityDetails && refreshValue.liabilityDetails.length) {
      refreshValue.liabilityDetails.forEach((liability: any) => {
        liability.igst = this.inrService.removeCommasAndParseNumber(liability.igst)
        liability.cgst = this.inrService.removeCommasAndParseNumber(liability.cgst);
        liability.sgst = this.inrService.removeCommasAndParseNumber(liability.sgst);
        liability.cess = this.inrService.removeCommasAndParseNumber(
          liability.cess);
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
      })
    }

    if (refreshValue.issues && refreshValue.issues.length) {
      refreshValue.issues.forEach((issue: any) => {
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
        issue.cgst1 = this.inrService.removeCommasAndParseNumber(issue.cgst1)
        issue.sgst1 = this.inrService.removeCommasAndParseNumber(issue.sgst1)
        issue.cess1 = this.inrService.removeCommasAndParseNumber(issue.cess1)
        issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
        issue.cgst3 = this.inrService.removeCommasAndParseNumber(issue.cgst3)
        issue.sgst3 = this.inrService.removeCommasAndParseNumber(issue.sgst3)
        issue.cess3 = this.inrService.removeCommasAndParseNumber(issue.cess3)
        issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)
      })
    }

    refreshValue.igst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.igst1Total)
    refreshValue.cgst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.cgst1Total)
    refreshValue.sgst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.sgst1Total)
    refreshValue.cess1Total = this.inrService.removeCommasAndParseNumber(refreshValue.cess1Total)
    refreshValue.interest1Total = this.inrService.removeCommasAndParseNumber(refreshValue.interest1Total)
    refreshValue.penalty1Total = this.inrService.removeCommasAndParseNumber(refreshValue.penalty1Total)
    refreshValue.totOfTotal1 = this.inrService.removeCommasAndParseNumber(refreshValue.totOfTotal1)
    refreshValue.igst3Total = this.inrService.removeCommasAndParseNumber(refreshValue.igst3Total)
    refreshValue.cgst3Total = this.inrService.removeCommasAndParseNumber(refreshValue.cgst3Total)
    refreshValue.sgst3Total = this.inrService.removeCommasAndParseNumber(refreshValue.sgst3Total)
    refreshValue.cess3Total = this.inrService.removeCommasAndParseNumber(refreshValue.cess3Total)
    refreshValue.interest3Total = this.inrService.removeCommasAndParseNumber(refreshValue.interest3Total)
    refreshValue.penalty3Total = this.inrService.removeCommasAndParseNumber(refreshValue.penalty3Total)
    refreshValue.totOfTotal3 = this.inrService.removeCommasAndParseNumber(refreshValue.totOfTotal3)
    this.litService.getCumulativeCount(this.tabType[3].key, refreshValue).subscribe((response: any) => {
      this.formatTableValues();
      this.caseData.cumulative = response.response;
      this.cumulativeCount = response.response
    })
  }

  handlePaste(event: any) {
    this.inrService.handlePaste(event)
  }
  formatTableValues() {
    const issues1Array = this.authTribP1Form.controls.issues as UntypedFormArray
    const liabilityDetailsArray = this.authTribP1Form.controls.liabilityDetails as UntypedFormArray
    const protestDetailsArray = this.authTribP1Form.controls.protestDetails as UntypedFormArray
    const predepositDetailsArray = this.authTribP1Form.controls.predepositDetails as UntypedFormArray
    const consultantChargesArray = this.authTribP1Form.controls.consultantCharges as UntypedFormArray

    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (var index = 0; index < consultantChargesArray.controls.length; index++) {
        const consultantChargesControl = consultantChargesArray.controls[index] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(this.inrService.formatCurrency(consultantChargesControl.value.fees))
      }
    }

    if (protestDetailsArray && protestDetailsArray.controls.length) {
      for (var index = 0; index < protestDetailsArray.controls.length; index++) {
        const protestDetailsControl = protestDetailsArray.controls[index] as UntypedFormGroup;
        protestDetailsControl.controls['igst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.igst))
        protestDetailsControl.controls['cgst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.cgst))
        protestDetailsControl.controls['sgst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.sgst))
        protestDetailsControl.controls['cess'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.cess))
        protestDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.interest))
        protestDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.penalty))
        protestDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.total))
      }
    }
    if (predepositDetailsArray && predepositDetailsArray.controls.length) {
      for (var index = 0; index < predepositDetailsArray.controls.length; index++) {
        const predepositDetailsControl = predepositDetailsArray.controls[index] as UntypedFormGroup;
        predepositDetailsControl.controls['igst'].setValue(this.inrService.formatCurrency(predepositDetailsControl.value.igst))
        predepositDetailsControl.controls['cgst'].setValue(this.inrService.formatCurrency(predepositDetailsControl.value.cgst))
        predepositDetailsControl.controls['sgst'].setValue(this.inrService.formatCurrency(predepositDetailsControl.value.sgst))
        predepositDetailsControl.controls['cess'].setValue(this.inrService.formatCurrency(predepositDetailsControl.value.cess))
        predepositDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(predepositDetailsControl.value.interest))
        predepositDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(predepositDetailsControl.value.penalty))
        predepositDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(predepositDetailsControl.value.total))
      }
    }

    if (liabilityDetailsArray.controls.length > 0) {
      for (let index = 0; index < liabilityDetailsArray.controls.length; index++) {
        const liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
        liabilityDetailsControl.controls['igst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.igst))
        liabilityDetailsControl.controls['cgst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.cgst))
        liabilityDetailsControl.controls['sgst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.sgst))
        liabilityDetailsControl.controls['cess'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.cess))
        liabilityDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.interest))
        liabilityDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.penalty))
        liabilityDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.total))
      }
    }

    if (issues1Array.controls.length > 0) {
      for (let i = 0; i < issues1Array.controls.length; i++) {
        const issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        issues1Control.controls['igst1'].setValue(this.inrService.formatCurrency(issues1Control.value.igst1))
        issues1Control.controls['cgst1'].setValue(this.inrService.formatCurrency(issues1Control.value.cgst1))
        issues1Control.controls['sgst1'].setValue(this.inrService.formatCurrency(issues1Control.value.sgst1))
        issues1Control.controls['cess1'].setValue(this.inrService.formatCurrency(issues1Control.value.cess1))
        issues1Control.controls['interest1'].setValue(this.inrService.formatCurrency(issues1Control.value.interest1))
        issues1Control.controls['penalty1'].setValue(this.inrService.formatCurrency(issues1Control.value.penalty1))
        issues1Control.controls['total1'].setValue(this.inrService.formatCurrency(issues1Control.value.total1))
        issues1Control.controls['igst3'].setValue(this.inrService.formatCurrency(issues1Control.value.igst3))
        issues1Control.controls['cgst3'].setValue(this.inrService.formatCurrency(issues1Control.value.cgst3))
        issues1Control.controls['sgst3'].setValue(this.inrService.formatCurrency(issues1Control.value.sgst3))
        issues1Control.controls['cess3'].setValue(this.inrService.formatCurrency(issues1Control.value.cess3))
        issues1Control.controls['interest3'].setValue(this.inrService.formatCurrency(issues1Control.value.interest3))
        issues1Control.controls['penalty3'].setValue(this.inrService.formatCurrency(issues1Control.value.penalty3))
        issues1Control.controls['total3'].setValue(this.inrService.formatCurrency(issues1Control.value.total3))
      }
    }
    this.issueValueChange();
  }
  inrFormattingRemoving() {

    const data = this.authTribP1Form.value;

    //phase 2 form

    if (data.consultantCharges && data.consultantCharges.length) {

      data.consultantCharges.forEach((charge: any) => {

        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)

      })

    }

    //phase 1 form

    if (data.protestDetails && data.protestDetails.length) {

      data.protestDetails.forEach((protestDetail: any) => {

        protestDetail.igst = this.inrService.removeCommasAndParseNumber(protestDetail.igst)
        protestDetail.cgst = this.inrService.removeCommasAndParseNumber(protestDetail.cgst);
        protestDetail.sgst = this.inrService.removeCommasAndParseNumber(protestDetail.sgst);
        protestDetail.cess = this.inrService.removeCommasAndParseNumber(protestDetail.cess);

        protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)

        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)

        protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)

      })

    }

    if (data.predepositDetails && data.predepositDetails.length) {
      data.predepositDetails.forEach((predeposit: any) => {
        predeposit.igst = this.inrService.removeCommasAndParseNumber(predeposit.igst)
        predeposit.cgst = this.inrService.removeCommasAndParseNumber(predeposit.cgst);
        predeposit.sgst = this.inrService.removeCommasAndParseNumber(predeposit.sgst);
        predeposit.cess = this.inrService.removeCommasAndParseNumber(predeposit.cess);
        predeposit.interest = this.inrService.removeCommasAndParseNumber(predeposit.interest)
        predeposit.penalty = this.inrService.removeCommasAndParseNumber(predeposit.penalty)
        predeposit.total = this.inrService.removeCommasAndParseNumber(predeposit.total)
      })
    }

    //phase 3 form

    if (data.liabilityDetails && data.liabilityDetails.length) {

      data.liabilityDetails.forEach((liability: any) => {

        liability.igst = this.inrService.removeCommasAndParseNumber(liability.igst)
        liability.cgst = this.inrService.removeCommasAndParseNumber(liability.cgst);
        liability.sgst = this.inrService.removeCommasAndParseNumber(liability.sgst);
        liability.cess = this.inrService.removeCommasAndParseNumber(liability.cess);
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)

      })

    }

    // //phase 1 and 3 form

    if (data.issues && data.issues.length) {

      data.issues.forEach((issue: any) => {

        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)

        issue.cgst1 = this.inrService.removeCommasAndParseNumber(issue.cgst1)

        issue.sgst1 = this.inrService.removeCommasAndParseNumber(issue.sgst1)

        issue.cess1 = this.inrService.removeCommasAndParseNumber(issue.cess1)

        issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)

        issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)

        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)

        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)

        issue.cgst3 = this.inrService.removeCommasAndParseNumber(issue.cgst3)

        issue.sgst3 = this.inrService.removeCommasAndParseNumber(issue.sgst3)

        issue.cess3 = this.inrService.removeCommasAndParseNumber(issue.cess3)

        issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)

        issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)

        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)

        if (issue.details && issue.details.length) {

          issue.details.forEach((details: any) => {

            details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(details.provisionAmt1)

            details.contLiabilityAmt1 = this.inrService.removeCommasAndParseNumber(details.contLiabilityAmt1)

            details.otherLiability1 = this.inrService.removeCommasAndParseNumber(details.otherLiability1)

            details.caroAmt1 = this.inrService.removeCommasAndParseNumber(details.caroAmt1)

          })
        }

      })

    }

    // //phase 1 and 3 form

    data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)

    data.cgst1Total = this.inrService.removeCommasAndParseNumber(data.cgst1Total)

    data.sgst1Total = this.inrService.removeCommasAndParseNumber(data.sgst1Total)

    data.cess1Total = this.inrService.removeCommasAndParseNumber(data.cess1Total)

    data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total)

    data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total)

    data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1)

    data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total)

    data.cgst3Total = this.inrService.removeCommasAndParseNumber(data.cgst3Total)

    data.sgst3Total = this.inrService.removeCommasAndParseNumber(data.sgst3Total)

    data.cess3Total = this.inrService.removeCommasAndParseNumber(data.cess3Total)

    data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total)

    data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total)

    data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3)
  }
  selectElement: any
  getCurrentTime() {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();

    // Round minutes to nearest half-hour
    const roundedMinute = Math.round(currentMinute / 30) * 30;

    // Adjust current hour for AM/PM format
    const timeFormat = currentHour >= 12 ? 'PM' : 'AM';
    const hour12 = (currentHour % 12) || 12; // Convert hour to 12-hour format

    // Construct current selected time
    const currentSelectedTime = `${hour12.toString().padStart(2, '0')}:${roundedMinute.toString().padStart(2, '0')} ${timeFormat}`;
    var itemListArray = this.authTribP1Form.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.phTime.setValue(currentSelectedTime);

      }
    }
  }
  
  getissueData(content: any, i:any){
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    this.selectedIssues = this.authTribP1Form.value.issues[i].issue;
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = localStorage.getItem('selectedId'),
    model.module = "GST",
    model.role = "issueRef",
    model.criterias= [
      {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
      }
  ]
  console.log(this.selectedIssues,"this.authTribP1Form.value.issue");
  this.litService.getIssueRefData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.status === 1) {
        this.issueData = response?.response;
        if(this.issueData.length === 0){
          this.hideData =  false
      }else{
        this.hideData =  true
      }
      }      
    });
  }

  getperiodToValue(content: any){
    if(this.action === "create"){
      let model: any = {};
      model.page = 0,
      model.size = 10,
      model.sortfield = "createdOn",
      model.sortdir = "DESC",
      model.companyId = "7437",
      model.module = "GST",
      model.gstin = this.selectedGstin,
      model.criterias= [
        {
          "p": "periodFromDate",
          "o": "between",
          "v1": this.authTribP1Form.value.periodFromDate,
          "v2": this.authTribP1Form.value.periodToDate,
      },{
          "p": "periodToDate",
          "o": "between",
          "v1":  this.authTribP1Form.value.periodFromDate,
          "v2": this.authTribP1Form.value.periodToDate,
      }
]
this.litService.getDuplicateData(model).subscribe((response: any) => {
  console.log(response.response.length,"response.length");
    if (response.response.length === 0) {
      this.modalService.dismissAll();
    }
    else{
      this.duplicateData = response?.response;
      this.duplicateData?.forEach((obj: any, index: number) => {
        if (obj.forum === 'Appellate Authority')
          this.duplicateData[index].forum = 'Commissioner (A)'
      })
     this.modalService.open(content)
    }
    
  });
}
}
exportAsXLSX() {
  if (this.issueData.length === 0) {
    this.toaster.showError('No data to export.');
    return;
  }
  const data= this.issueData.map((source: any) => ({
    'PAN Name': source.panName,
    'GSTIN': source.gstin,
    'GSTIN Name': source.gstinName,
    'Division': source.gstinDiv,
    'Case id': source.caseId,
    'Case Label': source.caseLabel,
    'Period From': source.periodFromDate,
    'Period to': source.periodToDate,
    'Person Responsible': source.personResponsible,

  }));
  this.excelService.exportAsExcelFile(data, 'Issue Reference');
}
SortingIssue(column: string) {
  switch (column) {
    case SORT_ISSUE.CASE_ID: {
      if (!this.sorting.caseId) {
        this.sorting.caseId = true;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'caseId',
          'asc'
        );
      } else {
        this.sorting.caseId = false;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'caseId',
          'desc'
        );
      }
      break;
    }
    case SORT_ISSUE.STATE: {
      if (!this.sorting.state) {
        this.sorting.state = true;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'state',
          'asc'
        );
      } else {
        this.sorting.state = false;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'state',
          'desc'
        );
      }
      break;
    }
    case SORT_ISSUE.PAN: {
      if (!this.sorting.panName) {
        this.sorting.panName = true;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'panName',
          'asc'
        );
      } else {
        this.sorting.panName = false;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'panName',
          'desc'
        );
      }
      break;
    }
    case SORT_ISSUE.CASE_LABEL: {
      if (!this.sorting.caseLabel) {
        this.sorting.caseLabel = true;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'caseLabel',
          'asc'
        );
      } else {
        this.sorting.caseLabel = false;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'caseLabel',
          'desc'
        );
      }
      break;
    }
    case SORT_ISSUE.PERIOD_FROM: {
      if (!this.sorting.periodFromDate) {
        this.sorting.periodFromDate = true;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'periodFromDate',
          'asc'
        );
      } else {
        this.sorting.periodFromDate = false;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'periodFromDate',
          'desc'
        );
      }
      break;
    }

    case SORT_ISSUE.PERIOD_TO: {
      if (!this.sorting.periodToDate) {
        this.sorting.periodToDate = true;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'periodToDate',
          'asc'
        );
      } else {
        this.sorting.periodToDate = false;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'periodToDate',
          'desc'
        );
      }
      break;
    }
    case SORT_ISSUE.PERSON_RESPONSIBLE: {
      if (!this.sorting.personResponsible) {
        this.sorting.personResponsible = true;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,'personResponsible','asc');
      } else {
        this.sorting.personResponsible = false;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,'personResponsible', 'desc' );
      }
      break;
    }
    case SORT_ISSUE.GSTIN: {
      if (!this.sorting.gstin) {
        this.sorting.gstin = true;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'gstin',
          'asc'
        );
      } else {
        this.sorting.gstin = false;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'gstin',
          'desc'
        );
      }
      break;
    }
    case SORT_ISSUE.DIVISION: {
      if (!this.sorting.gstinDiv) {
        this.sorting.gstinDiv = true;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'gstinDiv',
          'asc'
        );
      } else {
        this.sorting.gstinDiv = false;
        this.issueData = this.commonServ.sortObjectsByKey(
          this.issueData,
          'gstinDiv',
          'desc'
        );
      }
      break;
    }
  }
}
  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }
  goToSummary(){
    this.router.navigate(['/litigationSummary']);
    this.modalService.dismissAll();
  }
  getCompanyTags(gstin:any) {
    const regNo = gstin;
    this.masterService.getEntityTagsCase(regNo).subscribe((response: any) => {
      if (response.status === 1) {
        const allTags = response.response.data;
        
        // Filter tags based on the selected company ID
        this.tagDataList = allTags
          .filter((tagItem: any) => tagItem.regNo === regNo)
          .flatMap((tagItem: any) =>
            tagItem.tags
              .filter((tag: any) => tag.active) // Filter tags with active: true
              .map((tag: any) => tag.tag)
          ); 
  
      } else {        
        this.toaster.showSuccess(response.message);
      }
    });
  }
  openIntCalPop(content: any) {
    this.modalService.open(content, { windowClass: 'preanalytics-pop' });
    this.initIntCalPopData();
  }
  closeIntCalPop(){
    this.initializeData();
    this.modalService.dismissAll();
  }
  
  initIntCalPopData() {
    let model = {
           type: this.tabType[3].key,
           phase: this.selectedTab == 'tab1'? 'PHASE-1' : 'PHASE-3',
           caseId : this.caseId,
           module : 'GST',
           companyId: localStorage.getItem('selectedId'),
         };
       this.litService.getIntCalPopData(model).subscribe((response: any) => {
       if (response.status === 1) {
          this.intCalPopData = response.response.issueDetails;
          if(this.intCalPopData == null) return;
          const keys = Object.keys(this.intCalPopData);
          this.intCalIssueData = [];
          keys.forEach((key: any) => {
            const valuesArray  = this.intCalPopData[key];    
            valuesArray.forEach((values: any) => {
              this.intCalIssueData.push({
                issue: values.issue,
                tag: values.tag,
                periodFromDate: this.selectedTab == 'tab1'? values.periodFromDate1 : values.periodFromDate3,
                periodToDate: this.selectedTab == 'tab1'? values.dateOfOrderRecieved ? values.dateOfOrderRecieved : values.periodToDate1 : values.periodToDate3,
                interestRate: this.selectedTab == 'tab1'? values.interestRate1 : values.interestRate3,
                totalTax: values.totalTax,
                refId: values.refId,
                igst1: values.igst1,
                cgst1: values.cgst1,
                sgst1: values.sgst1,
                cess1: values.cess1,
                igst3: values.igst3,
                cgst3: values.cgst3,
                sgst3: values.sgst3,
                cess3: values.cess3,
                userEnteredInterest: this.selectedTab == 'tab1'? this.inrService.formatCurrency(String(values.userEnteredInterest1)) : this.inrService.formatCurrency(String(values.userEnteredInterest3)),
                interest: this.selectedTab == 'tab1'? values.interest1 : values.interest3,
                calculatedInterest: this.selectedTab == 'tab1'? values.calculatedInterest1 : values.calculatedInterest3,
                taxAdjustedAgainstIssue: values.taxAdjustedAgainstIssue,   
                paymentDetails: values.paymentDetails,                 
                expanded: false,              
                periodFromDateP1: values.periodFromDate1,
                periodToDateP1: values.periodToDate1,
                interestRateP1:values.interestRate1,
                userEnteredInterestP1:values.userEnteredInterest1,
                interestP1:values.interest1,
                calculatedInterestP1:values.calculatedInterest1,
              });
            });        
            
          })
       }       
       });
   }
   doIntCalData(){
    const payload = {
      type: this.tabType[3].key,
      phase: this.selectedTab === "tab1" ? "PHASE-1" : "PHASE-3",
      caseId: this.caseId,
      module : 'GST',
      companyId: localStorage.getItem('selectedId'),
      issues: [],
      protestDetails: [] as PaymentDetails[],
      predepositDetails: [] as PaymentDetails[],
      admittedLiabDetails: [] as PaymentDetails[]
  };
  if(this.selectedTab === "tab1"){
  payload.issues = this.intCalIssueData.map((issue:any) => ({
    issue: issue.issue,
    tag: issue.tag,
    interestRate1: issue.interestRate ? parseFloat(issue.interestRate.toString().replace(/,/g, ''))  : 0,
    periodFromDate1: issue.periodFromDate, 
    periodToDate1: issue.periodToDate, 
    igst1: issue.igst1,
    cgst1: issue.cgst1,
    sgst1: issue.sgst1,
    cess1: issue.cess1,
    igst3: issue.igst3,
    cgst3: issue.cgst3,
    sgst3: issue.sgst3,
    cess3: issue.cess3,
    userEnteredInterest1: issue.userEnteredInterest ? parseFloat(issue.userEnteredInterest.toString().replace(/,/g, ''))  : 0,
    interest1: issue.interest,
    calculatedInterest1: issue.calculatedInterest,
    taxAdjustedAgainstIssue: issue.taxAdjustedAgainstIssue,   
  }));
}else{
  payload.issues = this.intCalIssueData.map((issue:any) => ({
    issue: issue.issue,
    tag: issue.tag,    
    interestRate3: issue.interestRate ? parseFloat(issue.interestRate.toString().replace(/,/g, ''))  : 0,
    periodFromDate3: issue.periodFromDate, 
    periodToDate3: issue.periodToDate, 
    igst1: issue.igst1,
    cgst1: issue.cgst1,
    sgst1: issue.sgst1,
    cess1: issue.cess1,
    igst3: issue.igst3,
    cgst3: issue.cgst3,
    sgst3: issue.sgst3,
    cess3: issue.cess3,
    userEnteredInterest3: issue.userEnteredInterest ? parseFloat(issue.userEnteredInterest.toString().replace(/,/g, ''))  : 0,
    interest3: issue.interest,
    calculatedInterest3: issue.calculatedInterest,
    taxAdjustedAgainstIssue: issue.taxAdjustedAgainstIssue, 
    periodFromDate1: issue.periodFromDateP1,
    periodToDate1: issue.periodToDate1,
    interestRate1:issue.interestRateP1,
    userEnteredInterest1:issue.userEnteredInterestP1,
    interest1:issue.interestP1,
    calculatedInterest1:issue.calculatedInterestP1,  
  }));
}
  this.intCalIssueData.forEach((issue:any) => {
    issue.paymentDetails.forEach((payment:any) => {
      const baseDetails = {
        issueName: issue.issue,
        tag: payment.tag,
        stage: payment.stage,
        challanNo:payment.challanNo,
        igst: payment.igst,
        cgst: payment.cgst,
        sgst: payment.sgst,
        cess: payment.cess,
        dateOfPayment: payment.dateOfPayment,
        type:payment.type,
        markedForPayment: payment.markedForPayment
      };
      
      if (payment.type === 'PROTEST_DETAILS') {
        payload.protestDetails.push(baseDetails);
      } else if (payment.type === 'PREDEPOSIT_DETAILS') {
        payload.predepositDetails.push(baseDetails);
      } else if (payment.type === 'ADMITTED_LIABILITY') {
        payload.admittedLiabDetails.push(baseDetails);
      }
    });
  });

    this.litService.intCalPopData(payload).subscribe((response: any) => {
      if (response.status === 1) {
        this.intCalPopData = response.response.issueDetails;
        if(this.intCalPopData == null) return;
        const keys = Object.keys(this.intCalPopData);
        this.intCalIssueData = [];
        keys.forEach((key: any) => {
          const valuesArray  = this.intCalPopData[key];    
          valuesArray.forEach((values: any) => {
            this.intCalIssueData.push({
              issue: values.issue,
                tag: values.tag,
                periodFromDate: this.selectedTab == 'tab1'? values.periodFromDate1 : values.periodFromDate3,
                periodToDate: this.selectedTab == 'tab1'? values.dateOfOrderRecieved ? values.dateOfOrderRecieved : values.periodToDate1 : values.periodToDate3,
                interestRate: this.selectedTab == 'tab1'? values.interestRate1 : values.interestRate3,
                totalTax: values.totalTax,
                refId: values.refId,
                igst1: values.igst1,
                cgst1: values.cgst1,
                sgst1: values.sgst1,
                cess1: values.cess1,
                igst3: values.igst3,
                cgst3: values.cgst3,
                sgst3: values.sgst3,
                cess3: values.cess3,
                userEnteredInterest: this.selectedTab == 'tab1'? this.inrService.formatCurrency(String(values.userEnteredInterest1)) : this.inrService.formatCurrency(String(values.userEnteredInterest3)),
                interest: this.selectedTab == 'tab1'? values.interest1 : values.interest3,
                calculatedInterest: this.selectedTab == 'tab1'? values.calculatedInterest1 : values.calculatedInterest3,
                taxAdjustedAgainstIssue: values.taxAdjustedAgainstIssue,   
                paymentDetails: values.paymentDetails, 
                periodFromDateP1: values.periodFromDate1,
                periodToDateP1: values.periodToDate1,
                interestRateP1:values.interestRate1,
                userEnteredInterestP1:values.userEnteredInterest1,
                interestP1:values.interest1,
                calculatedInterestP1:values.calculatedInterest1,
              expanded: false,              
            });
          });        
          
        })
     }else{
        this.toaster.showError(response.message);
      }
    });
   }
   saveIntCalData(){
    const payload = {
      type: this.tabType[3].key,
      phase: this.selectedTab === "tab1" ? "PHASE-1" : "PHASE-3",
      caseId: this.caseId,
      module : 'GST',
      companyId: localStorage.getItem('selectedId'),
      issues: [],
      protestDetails: [] as PaymentDetails[],
      predepositDetails: [] as PaymentDetails[],
      admittedLiabDetails: [] as PaymentDetails[]
  };
  if(this.selectedTab === "tab1"){
    payload.issues = this.intCalIssueData.map((issue:any) => ({
      issue: issue.issue,
      tag: issue.tag,
      interestRate1: issue.interestRate ? parseFloat(issue.interestRate.toString().replace(/,/g, ''))  : 0,
      periodFromDate1: issue.periodFromDate, 
      periodToDate1: issue.periodToDate, 
      igst1: issue.igst1,
      cgst1: issue.cgst1,
      sgst1: issue.sgst1,
      cess1: issue.cess1,
      igst3: issue.igst3,
      cgst3: issue.cgst3,
      sgst3: issue.sgst3,
      cess3: issue.cess3,
      userEnteredInterest1: issue.userEnteredInterest ? parseFloat(issue.userEnteredInterest.toString().replace(/,/g, ''))  : 0,
      interest1: issue.interest,
      calculatedInterest1: issue.calculatedInterest,
      taxAdjustedAgainstIssue: issue.taxAdjustedAgainstIssue,   
    }));
  }else{
    payload.issues = this.intCalIssueData.map((issue:any) => ({
      issue: issue.issue,
      tag: issue.tag,     
      interestRate3: issue.interestRate ? parseFloat(issue.interestRate.toString().replace(/,/g, ''))  : 0,
      periodFromDate3: issue.periodFromDate, 
      periodToDate3: issue.periodToDate, 
      igst1: issue.igst1,
      cgst1: issue.cgst1,
      sgst1: issue.sgst1,
      cess1: issue.cess1,
      igst3: issue.igst3,
      cgst3: issue.cgst3,
      sgst3: issue.sgst3,
      cess3: issue.cess3,     
      userEnteredInterest3: issue.userEnteredInterest ? parseFloat(issue.userEnteredInterest.toString().replace(/,/g, ''))  : 0,
      interest3: issue.interest,
      calculatedInterest3: issue.calculatedInterest,
      taxAdjustedAgainstIssue: issue.taxAdjustedAgainstIssue,
      periodFromDate1: issue.periodFromDateP1,
      periodToDate1: issue.periodToDate1,
      interestRate1:issue.interestRateP1,
      userEnteredInterest1:issue.userEnteredInterestP1,
      interest1:issue.interestP1,
      calculatedInterest1:issue.calculatedInterestP1,     
    }));
  }
  this.intCalIssueData.forEach((issue:any) => {
    issue.paymentDetails.forEach((payment:any) => {
      const baseDetails = {
        issueName: issue.issue,
        tag: payment.tag,
        stage: payment.stage,
        challanNo:payment.challanNo,
        igst: payment.igst,
        cgst: payment.cgst,
        sgst: payment.sgst,
        cess: payment.cess,
        dateOfPayment: payment.dateOfPayment,
        type:payment.type,
        markedForPayment: payment.markedForPayment
      };
      
      if (payment.type === 'PROTEST_DETAILS') {
        payload.protestDetails.push(baseDetails);
      } else if (payment.type === 'PREDEPOSIT_DETAILS') {
        payload.predepositDetails.push(baseDetails);
      } else if (payment.type === 'ADMITTED_LIABILITY') {
        payload.admittedLiabDetails.push(baseDetails);
      }
    });
  });

    this.litService.saveIntCalPopData(payload).subscribe((response: any) => {
      if (response.status === 1) {
        this.toaster.showSuccess(response.message);
        this.initIntCalPopData();
      }else{
        this.toaster.showError(response.message);
      }
    });
   }
}


