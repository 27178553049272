import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PreGstServiceService } from 'src/app/preGstLitigation/services/pre-gst-service.service';
import { ADJ_APPELPRE, ADJ_OUTCOME, APPA_PH1_APPELUS, TAB_TYPE, ADJ_PH1_ADJUNOT, ADJ_ATTEND, Apa_PH1_PREDEPOAPP, APA_PH1_PAY_MODE, ADJ_HAS_DEPT_APPEK, LETTER_GSTIN, STATE_LIST, ADJ_DEPTPRE, ISSUE_RISK_PARAM, ISSUE_CONCLUSION, ISSUE_CONCLUSION_A, QUARTER, YES_NO, MODE_OF_RECEIPT, PAYMENT, MODE_OF_PAYMENT, TYPE_OF_AUTHORITY, FORUM_APPEAL, PRE_ACT_TYPE, DESIGNATION, FORM_TYPE_DETAILS_OF_APPEAL, ADJ_WRITPRE, ISSUE_CONCLUSION_COMMISSIONER, MEMORANDOM_OUTCOME, ISSUE_CONCLUSION_FAVOURABLE_ONLY, CASE_TYPES, FORUM_APPEAL_COMMISONER_ALL_OPTIONS, DESIGNATION_COMMISSIONER_GST, ISSUING_AUTHORITY_TYPE, ADJ_ACT_TYPE, ISSUE_GST, MODE_OF_TIME_PH, MODE_OF_PH } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { LitigationServiceService } from '../services/litigation-service.service';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { InrService } from 'src/app/shared/services/inr.service';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from 'src/app/shared/services/excel.service';
export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  PAN = 'pan',
  GSTIN ='gstin',
  DIVISION ='gstinDiv',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  PERSON_RESPONSIBLE = 'Person Responsible',
}
@Component({
  selector: 'app-appellate-auth',
  templateUrl: './appellate-auth.component.html',
  styleUrls: ['./appellate-auth.component.css']
})
export class AppellateAuthComponent implements OnInit {
  parentCaseId: any;
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToHC: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToSC: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveCaseId = new EventEmitter<boolean>();
  @Output() enablePrevStageTab: EventEmitter<string[] | null> = new EventEmitter<string[] | null>();
  @Input() isRemandedUpdated: any

  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  @Input() activeStages: string[] | null = null;
  dataSource: any;
  rmvIssueModalRef!: NgbModalRef;
  rmvDetailModalRef!: NgbModalRef;
  selectedTab: any;
  selectedPhase: any;
  savedCaseId: any;
  caseId: any;
  action: any;
  companyId: any;
  caseData: any = {};
  selectedField: any;
  selectedFile: any;
  fileUrl: any;
  navContext: any;
  obj0: any = {};
  adjornot: any;
  issueData: any;  
  issueReferenceRef!: NgbModalRef;
  appAuthP1Form!: UntypedFormGroup;
  appAuthP2Form!: UntypedFormGroup;
  appAuthP3Form!: UntypedFormGroup;
  isSubmitted: boolean = false;
  isDataSaved: boolean = false;
  isUpdate: boolean = false;
  isCaseData: boolean = true;
  isCaseID: boolean = false;
  isadjorNot: boolean = false;
  isIssueAdd: boolean = false;
  isCreateBtn: boolean = false;
  isUpdateBtn: boolean = false;
  isProvAdd: boolean = false;
  isPhase2: boolean = false;
  isPhase3: boolean = false;
  isDisableIcons: boolean = false;
  callBookDisabled = false;
  viewIons:boolean = false;
  hideData:boolean = false;

  // isMemorandum: boolean = false;
  caseType: any = null;
  disablField: boolean = false;
  disablBankChallan: boolean = false;

  hideDiv: boolean = true;

  disabledWeatherAppl: boolean = false;
  disabledWeatherDept: boolean = false;

  isUPbankChallanLoc: boolean = true;
  isDWbankChallanLoc: boolean = true;
  isbankChallanLocFile: boolean = false;
  isbankChallanLocText: boolean = true;

  isUPappealBookLoc: boolean = true;
  isDWappealBookLoc: boolean = true;
  isappealBookLocFile: boolean = false;
  isappealBookLocText: boolean = true;

  isUPotherAttachmentsLoc1: boolean = true;
  isDWotherAttachmentsLoc1: boolean = true;
  isotherAttachmentsLoc1File: boolean = false;
  isotherAttachmentsLoc1Text: boolean = true;

  isUPdrc03CopyLoc: boolean = true;
  isDWdrc03CopyLoc: boolean = true;
  isdrc03CopyLocFile: boolean = false;
  isdrc03CopyLocText: boolean = true;


  isUPcopyOfAckLoc: boolean = true;
  isDWcopyOfAckLocc: boolean = true;
  iscopyOfAckLocFile: boolean = false;
  iscopyOfAckLocText: boolean = true;

  isUPotherAttachmentsLoc2: boolean = true;
  isDWotherAttachmentsLoc2: boolean = true;
  isotherAttachmentsLoc2File: boolean = false;
  isotherAttachmentsLoc2Text: boolean = true;

  isUPphCopyLoc: boolean = true;
  isDWphCopyLoc: boolean = true;
  isphCopyLocFile: boolean = false;
  isphCopyLocText: boolean = true;

  isUPadditionalSubmissionLoc: boolean = true;
  isDWadditionalSubmissionLoc: boolean = true;
  isadditionalSubmissionLocFile: boolean = false;
  isadditionalSubmissionLocText: boolean = true;

  isUPphRecordIfAnyLoc: boolean = true;
  isDWphRecordIfAnyLoc: boolean = true;
  isphRecordIfAnyLocFile: boolean = false;
  isphRecordIfAnyLocText: boolean = true;

  isUPoiaLoc: boolean = true;
  isDWoiaLoc: boolean = true;
  isoiaLocFile: boolean = false;
  isoiaLocText: boolean = true;

  isUPpaymentChallanLoc: boolean = true;
  isDWpaymentChallanLoc: boolean = true;
  ispaymentChallanLocFile: boolean = false;
  ispaymentChallanLocText: boolean = true;

  isonupCreate: boolean = true;
  isondwCreate: boolean = true;

  copyOfAckLocReq: boolean = false;
  oiaLocReq: boolean = false;

  appaPh1Appelus: { label: string; value: string; }[];
  tabType: { key: string; value: string; }[];
  outcome: { key: string; value: string; }[];
  riskParam: { key: string; value: string; }[];
  paymentMode: { key: string; value: string; }[];
  issueConclusion: { key: string; value: string; }[];
  issueConclusion2: { key: string; value: string; }[];
  whetherAppealPreferred: { label: string; value: boolean; }[];
  // hasDptPreferredAppeal: { label: string; value: boolean; }[];
  whetherDptAppealPreferred: { label: string; value: boolean; }[];
  writAppPreferred: { label: string; value: boolean; }[];
  adjPHAdjuorNotList: { label: string; value: boolean; }[];
  preDepoApplicableList: { label: string; value: boolean; }[];
  adjAttend: { label: string; value: boolean; }[];
  p1stateList: { stateName: string; stateCode: string; id: number; }[];
  p1Gstin: { key: string; value: string; }[];
  quarterList: { key: string; value: string; disabled: string; }[];
  yesNoList: { label: string; value: boolean; }[];
  sorting: {
    caseId: boolean;
    state: boolean;
    panName: boolean;
    gstin: boolean;
    caseLabel: boolean;
    gstinDiv: boolean;
    periodFromDate: boolean;
    periodToDate: boolean;
    personResponsible: boolean;
  } = {
      caseId: false,
      state: false,
      panName: false,
      caseLabel: false,
      gstin:false,
      gstinDiv:false,
      periodFromDate: false,
      periodToDate: false,
      personResponsible: false,
    };
    issueSorting = SORT_ISSUE;
  drc03CopyLocF: any;
  copyOfAckLocF: any;
  otherAttachmentsLoc2F: any;
  phCopyLocF: any;
  additionalSubmissionLocF: any;
  phRecordIfAnyLocF: any;
  oiaLocF: any;
  paymentChallanLocF: any;
  getAccessTabData: any;
  selectedFilling: any;
  selectedGstin: any;
  selectedGstinDiv: any;
  selectedState: any;
  matches: any;
  selectedStateCode: any;
  selectedStateName: any;
  isIssueDiv: any;
  maxDate = new Date();
  currentYear: any;
  userRoleLs: any;
  duplicateData: any;
  formData: FormData = new FormData();
  filePath: any;
  // dwdscnLoc: any;
  // dwdattachmentsLoc: any;
  // dwdcopyOfAckLoc: any;
  // dwdotherAttachmentsLoc: any;
  // dwdoioLoc: any;
  // dwdpaymentChallanLoc: any;
  // dwddrc03CopyLoc: any;
  // dwdotherAttachmentsLoc2: any;
  // dwdoiaLoc: any;
  finyearData: any[] = [];
  caseItemList: any = [];
  modeOfAppealOptions = MODE_OF_RECEIPT;
  detailsOfPreDepositeExpanded = false;
  rmvDetailsOfPreDepositeRef: any
  preDepositeInterestTotal = 0;
  preDepositeCgstTotal= 0;
  preDepositeSgstTotal= 0;
  preDepositeCessTotal= 0;
  preDepositeTaxTotal = 0;
  preDepositePenaltyTotal = 0;
  preDepositeTotal = 0;
  formPayment = PAYMENT;

  //  Details of Liability Admitted and Paid
  iscopyOfUPFormLoc: boolean = true;
  iscopyOfDWFormLoc: boolean = true;
  iscopyOfFormLocFile: boolean = false;
  iscopyOfFormLocText: boolean = true;

  iscopyOfBankUPChallanLoc: boolean = true;
  iscopyOfBankDWChallanLoc: boolean = true;
  iscopyOfBankChallanLocFile: boolean = false;
  iscopyOfBankChallanLocText: boolean = true;
  rmvDetailsOfPaymentRef: any;

  protestInterestTotal = 0;
  protestTaxTotal = 0;
  protestcgstTotal= 0;
  protestsgstTotal= 0;
  protestcessTotal= 0;
  protestPenaltyTotal = 0;
  protestTotal = 0;
  feesChargedTotal = 0;


  isSupportingFile = false;
  isSupportingText = true;
  isSupportingDWText = true;
  isSupportingUPText = true;
  modeOfPayment = MODE_OF_PAYMENT;
  modeOfPH = MODE_OF_PH;
  modeOfTime =MODE_OF_TIME_PH;
  typeOfAuthorityDropdown = ISSUING_AUTHORITY_TYPE;
  detailsOfAppealExp = true;
  rmvDetailsOfAppealRef: any;

  copyOfAckLocDocFile = false;
  copyOfAckLocDocText = true;
  copyOfAckLocDWText = true;
  copyOfAckLocUPText = true;


  isOtherAttachmentDWText = true;
  isOtherAttachmentUPText = true;
  isOtherAttachmentText = true;
  isOtherAttachmentFile = false;

  rmvConsultantModalRef: any;

  isconsultantattachmentsLocText: boolean = true;
  isconsultantDWattachmentsLoc: boolean = true;
  isconsultantattachmentsLocFile: boolean = false;
  isconsultantUPattachmentsLoc: boolean = true;
  keepCaseInCallBookOptions = YES_NO;
  disablePhase3 = false;
  consultantPanleExpansion = false;
  protestDetailsExpansion = false;
  formTypeDropdown = FORM_TYPE_DETAILS_OF_APPEAL;
  formOfPayment = PAYMENT;


  liabilityTotals = { 
    igstTotal: 0,
    cgstTotal: 0,
    sgstTotal: 0,
    cessTotal: 0,
    interestTotal: 0,
    penaltyTotal: 0,
    allTotal: 0,
  };
  liabilityExpansionPanel = false;
  rmvLiabilityModalRef: any;
  forumAppeal = FORUM_APPEAL_COMMISONER_ALL_OPTIONS;
  selectedRemandedBackCaseDetail: any = null;
  cumulativeCount = [
    { label: "Cumulative Pre-Deposit", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative payment made under protest", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative payment made for admitted liability", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative Consultant Charges", tax: null, interest: null, penalty: null, total: 0 }
  ];
  detailsOfAllPaymentMadeExpansion = false;
  isRemandedBack = false;
  remandedCaseObj: any = [];
  paymentUnderRemandedBackExpansion = false;
  preAdjType = ADJ_ACT_TYPE;
  designation = DESIGNATION_COMMISSIONER_GST;
  proceedWithPreferredForumAppeal: any


  iscopyOfBankChallanLocFile2 = false;
  iscopyOfBankChallanLocText2 = true;
  iscopyOfBankDWChallanLoc2 = true;
  iscopyOfBankUPChallanLoc2 = true;

  isProtestDetailCopyOfFormLocFile = false;
  isProtestDetailCopyOfFormLocText = true;
  isProtestDetailCopyOfFormLocDWText = true;
  isProtestDetailCopyOfFormLocUPText = true;

  isProtestDetailCopyOfBankChallanLocFile = false;
  isProtestDetailCopyOfBankChallanLocText = true;
  isProtestDetailCopyOfBankChallanLocDWText = true;
  isProtestDetailCopyOfBankChallanLocUPText = true;

  detailOfIssuingAuthorityExpansion1 = false;
  detailsOfAllPaymentMadeExpansion1 = false;
  disabledWeatherWrit: boolean = false;
  appealBookLocReq: boolean = false;
  caseTypeData = CASE_TYPES;
  selectedIndex: any

  isUPnoticeLoc: boolean = true;
  isDWnoticeLoc: boolean = true;
  isnoticeLocFile: boolean = false;
  isnoticeLocText: boolean = true;

  isInitialised: any;
  isToggleDropdown = true;


  oldProtestDetailsValues: any = [];
  oldLiabilityDetailValues: any = []
  oldPredepositeDetailsValues: any = []
  oldConsulatntValues: any = []
  remandBackCaseList = [];
  backStage: any = [];
  prevStage: any
  currentTab = 'apa';
  activeTab: any;
  //  isNotEmpty!: boolean ;

  constructor(
    private router: Router,
    private fBuild: UntypedFormBuilder,
    private litService: LitigationServiceService,
    private toaster: ToasterService,
    private activeSnapShot: ActivatedRoute,
    private commonServ: CommonService,
    private modalService: NgbModal,
    private preLitService: PreGstServiceService,
    private inrService: InrService,
    private excelService: ExcelService,
    private http: HttpClient

  ) {
    this.appaPh1Appelus = APPA_PH1_APPELUS
    this.tabType = TAB_TYPE;
    this.outcome = ADJ_OUTCOME;
    this.paymentMode = APA_PH1_PAY_MODE;
    this.whetherAppealPreferred = ADJ_APPELPRE;
    // this.hasDptPreferredAppeal = ADJ_HAS_DEPT_APPEK;
    this.whetherDptAppealPreferred = ADJ_DEPTPRE;
    this.writAppPreferred = ADJ_WRITPRE;
    this.adjPHAdjuorNotList = ADJ_PH1_ADJUNOT;
    this.preDepoApplicableList = Apa_PH1_PREDEPOAPP;
    this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER;
    this.issueConclusion2 = ISSUE_CONCLUSION_A;
    this.riskParam = ISSUE_RISK_PARAM;
    this.adjAttend = ADJ_ATTEND;
    this.p1Gstin = LETTER_GSTIN;
    this.p1stateList = STATE_LIST;
    this.caseId = this.activeSnapShot.snapshot.params.caseId;
    this.action = this.activeSnapShot.snapshot.params.action;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.quarterList = QUARTER;
    this.yesNoList = YES_NO;
    this.isRemandedBack = (this.activeSnapShot.snapshot.params.caseId)?.includes('R-') ? true : this.activeSnapShot.snapshot.params.remanded;

  }

  ngOnInit() {
    if (this.isRemandedUpdated) {
      this.isRemandedBack = false
    }
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.selectedGstinDiv = this.selectedFilling.gstinDiv;
    this.navContext = this.commonServ.getNavContext();
    this.setStateAutoPop();
    window.scrollTo(0, 0);
    this.litService.getAccessTabData().subscribe((val) => {
      if (val) {
        this.getAccessTabData = val;
      }
    });
    this.setDate();
    this.personResponsibleData();
    this.selectedTab = 'tab1';
    this.selectedPhase = 'phase0';
    this.isIssueDiv = 'issueDiv0'
    this.formInitialize();
    this.getYears();
    if (this.caseId || this.parentCaseId) {
      this.isCaseID = true;
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.initializeData();
    }
    if (this.action === 'create') {
      this.isCreateBtn = true;
      this.isonupCreate = false;
      this.isondwCreate = false;
      this.isDisableIcons = true;
      this.viewIons = false;


      this.isappealBookLocFile = true;
      this.isappealBookLocText = false;
      this.isUPappealBookLoc = false;

      this.isotherAttachmentsLoc1File = true;
      this.isotherAttachmentsLoc1Text = false;
      this.isUPotherAttachmentsLoc1 = false;

      this.isbankChallanLocFile = true;
      this.isbankChallanLocText = false;
      this.isUPbankChallanLoc = false;

      this.isdrc03CopyLocFile = true;
      this.isdrc03CopyLocText = false;
      this.isUPdrc03CopyLoc = false;

      this.iscopyOfAckLocFile = true;
      this.iscopyOfAckLocText = false;
      this.isUPcopyOfAckLoc = false;

      this.isotherAttachmentsLoc2File = true;
      this.isotherAttachmentsLoc2Text = false;
      this.isUPotherAttachmentsLoc2 = false;

      this.isphCopyLocFile = true;
      this.isphCopyLocText = false;
      this.isUPphCopyLoc = false;

      this.isadditionalSubmissionLocFile = true;
      this.isadditionalSubmissionLocText = false;
      this.isUPadditionalSubmissionLoc = false;

      this.isphRecordIfAnyLocFile = true;
      this.isphRecordIfAnyLocText = false;
      this.isUPphRecordIfAnyLoc = false;

      this.ispaymentChallanLocFile = true;
      this.ispaymentChallanLocText = false;
      this.isUPpaymentChallanLoc = false;

      this.isoiaLocFile = true;
      this.isoiaLocText = false;
      this.isUPoiaLoc = false;

      this.isnoticeLocFile = false;
      this.isnoticeLocText = true;
      this.isUPnoticeLoc = false;
      this.addDetailsOfPreDeposite();
      this.addIssueInv();
      this.addDetailOfPayment();
      this.addDetailOfAppeal();
      this.addConsultantInv();

    } else if (this.action === 'update') {
      this.isUpdateBtn = true;
      this.isDataSaved = true;
      this.isDisableIcons = true;
      this.viewIons = true;

      this.createAdjDiv();
      this.addIssueInv();
      //this.addIssue3Inv();
      // this.addProv();
    } else if (this.action === 'view') {
      this.isDataSaved = true;
      this.isonupCreate = false;
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.isDisableIcons = false;
      this.viewIons = false;

      this.isUPappealBookLoc = false;
      this.isDWappealBookLoc = true;

      this.isUPotherAttachmentsLoc1 = false;
      this.isDWotherAttachmentsLoc1 = true;

      this.isUPbankChallanLoc = false;
      this.isDWbankChallanLoc = true;

      this.isUPdrc03CopyLoc = false;
      this.isDWdrc03CopyLoc = true;

      this.isUPcopyOfAckLoc = false;
      this.isDWcopyOfAckLocc = true;

      this.isUPotherAttachmentsLoc2 = false;
      this.isDWotherAttachmentsLoc2 = true;

      this.isUPphCopyLoc = false;
      this.isDWphCopyLoc = true;

      this.isUPadditionalSubmissionLoc = false;
      this.isDWadditionalSubmissionLoc = true;

      this.isUPphRecordIfAnyLoc = false;
      this.isDWphRecordIfAnyLoc = true;

      this.isUPoiaLoc = false;
      this.isDWoiaLoc = true;

      this.isUPnoticeLoc = false;
      this.isDWnoticeLoc = true;

      this.isUPpaymentChallanLoc = false;
      this.isDWpaymentChallanLoc = true;
    }
    this.issueDropdown();
    if (this.caseId != ' ' && this.caseId != undefined) {
      this.isCreateBtn = false;
      this.isUpdateBtn = true;
    } else {
      this.isCreateBtn = true;
      // this.isUpdateBtn = false;

    }
    sessionStorage.setItem('sectionType', this.tabType[2].key);

    const userId: any = sessionStorage.getItem('user');
    this.appAuthP1Form.controls.personResponsible1.patchValue(userId);
  }

  ngAfterViewInit() {
    if (this.action === 'update') {
      this.chkFrmValidated();
    }
  }
  tabChanged(tab: any, check?: any) {
    this.appAuthP1Form.patchValue(this.appAuthP1Form.value);
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.initializeData();
    } else if (tab === 'tab2') {
      this.p2validation();

    } else if (tab === 'tab3') {
      //this.p3validation();

    }
  }
  phaseChanged(phase: any, i: any) {
    this.selectedPhase = phase;
    if (phase === ('phase' + i)) {

    }
  }
  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }
  /* Form initialization */
  formInitialize() {
    this.appAuthP1Form = this.fBuild.group({
      caseId: [this.caseData?.caseId],
      // gstin: [this.selectedGstin],
      gstin: [this.caseData?.gstin ? this.caseData?.gstin : this.selectedGstin],
      gstinDiv: [this.selectedGstinDiv],
      state: [this.selectedStateName],
      prevOrderLoc: [this.caseData?.prevOrderLoc],
      oioNo: [this.caseData?.oioNo, [Validators.required]],
      dateOfOio: [this.caseData?.dateOfOio, [Validators.required]],
      dateOfReceiptOfOio: [this.caseData?.dateOfReceiptOfOio, [Validators.required]],
      periodFromDate: [this.caseData?.periodFromDate, [Validators.required]],
      periodToDate: [this.caseData?.periodToDate, [Validators.required]],

      // appealedUs: [this.caseData?.appealedUs],
      dueDateOfAppeal: [this.caseData?.dueDateOfAppeal, [Validators.required]],
      internalDueDate: [this.caseData?.internalDueDate],
      // preDepositApplicable: [this.caseData?.preDepositApplicable, [Validators.required]],
      // preDepositPaid: [this.caseData?.preDepositPaid, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],

      consultant1: [this.caseData?.consultant1],
      personResponsible1: [this.caseData?.personResponsible1, [Validators.required]],
      dateOfsubmission1: [this.caseData?.dateOfsubmission1],
      issues: new UntypedFormArray([]),
      caseSummary: [this.caseData?.caseSummary],
      internalRemarks: [this.caseData?.internalRemarks],
      provisionDetails: this.fBuild.array([]),
      copyOfOioLoc: new UntypedFormArray([]),
      updatedBy: [this.caseData?.updatedBy],
      updatedOn: [this.caseData?.updatedOn],
      // igstTax1: [this.caseData?.igstTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cgstTax1: [this.caseData?.cgstTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // sgstTax1: [this.caseData?.sgstTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cessTax1: [this.caseData?.cessTax1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // totalTax1: [this.action == 'create'? '':this.caseData?.totalTax1, []],
      // igstInterest1: [this.caseData?.igstInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // igstPenalty1: [this.caseData?.igstPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cgstInterest1: [this.caseData?.cgstInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cgstPenalty1: [this.caseData?.cgstPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // sgstInterest1: [this.caseData?.sgstInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // sgstPenalty1: [this.caseData?.sgstPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cessInterest1: [this.caseData?.cessInterest1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // cessPenalty1: [this.caseData?.cessPenalty1, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      // totalInterest1: [this.action == 'create'? '':this.caseData?.totalInterest1, []],
      // totalPenalty1: [this.action == 'create'? '':this.caseData?.totalPenalty1, []],
      // paymentMode: [this.caseData?.paymentMode],
      // bankChallanLoc: [this.caseData?.bankChallanLoc],
      // appealBookLoc: [this.caseData?.appealBookLoc],
      appealBookLoc: new UntypedFormArray([]),
      otherAttachmentsLoc1: new UntypedFormArray([]),
      // drc03CopyLoc: [this.caseData?.drc03CopyLoc],
      // drc03Tax1: [this.caseData?.drc03Tax1, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Interest1: [this.caseData?.drc03Interest1, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Penalty1: [this.caseData?.drc03Penalty1, [Validators.pattern(/^[1-9]\d*$/)]],
      drc03Tax3: [this.caseData?.drc03Tax3, [Validators.pattern(/^[1-9]\d*$/)]],
      drc03Interest3: [this.caseData?.drc03Interest3, [Validators.pattern(/^[1-9]\d*$/)]],
      drc03Penalty3: [this.caseData?.drc03Penalty3, [Validators.pattern(/^[1-9]\d*$/)]],
      paperBookOfAppealLoc: [this.caseData?.paperBookOfAppealLoc],
      // dateOfSubmission: [this.caseData?.dateOfSubmission],//
      // consultant2: [this.caseData?.consultant2],//
      // personResponsible2: [this.caseData?.personResponsible2],//
      // copyOfAckLoc: [this.caseData?.copyOfAckLoc],//
      // otherAttachmentsLoc2: [this.caseData?.otherAttachmentsLoc2],
      //p3
      oiaNo: [this.caseData?.oiaNo],//
      dateOfOia: [this.caseData?.dateOfOia],//
      dateOfReceiptOfOia: [this.caseData?.dateOfReceiptOfOia],//
      oiaLoc: new UntypedFormArray([]),//
      outcome: [this.caseData?.outcome],//
      // hasDptPreferredAppeal: [this.caseData?.hasDptPreferredAppeal],//
      whetherAppealPreferred: [this.caseData?.whetherAppealPreferred],//
      whetherDptAppealPreferred: [this.caseData?.whetherDptAppealPreferred],//
      writAppPreferred: [this.caseData?.writAppPreferred],
      paymentChallanLoc: [this.caseData?.paymentChallanLoc],
      orderSummary: [this.caseData?.orderSummary],
      // igstTax3: [this.caseData?.igstTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cgstTax3: [this.caseData?.cgstTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // sgstTax3: [this.caseData?.sgstTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cessTax3: [this.caseData?.cessTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // totalTax3: [this.caseData?.totalTax3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // igstInterest3: [this.caseData?.igstInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // igstPenalty3: [this.caseData?.igstPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cgstInterest3: [this.caseData?.cgstInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cgstPenalty3: [this.caseData?.cgstPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // sgstInterest3: [this.caseData?.sgstInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // sgstPenalty3: [this.caseData?.sgstPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cessInterest3: [this.caseData?.cessInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // cessPenalty3: [this.caseData?.cessPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // totalInterest3: [this.caseData?.totalInterest3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      // totalPenalty3: [this.caseData?.totalPenalty3, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],//
      issues3: this.fBuild.array([]),
      drc03CopyLocFN: [this.drc03CopyLocF],//
      copyOfAckLocFN: [this.copyOfAckLocF],//
      otherAttachmentsLoc2FN: [this.otherAttachmentsLoc2F],//
      phCopyLocFN: [this.phCopyLocF],//
      additionalSubmissionLocFN: [this.additionalSubmissionLocF],//
      phRecordIfAnyLocFN: [this.phRecordIfAnyLocF],//
      oiaLocFN: [this.oiaLocF],//
      paymentChallanLocFN: [this.paymentChallanLocF],//
      // dwddrc03CopyLoc: [this.dwddrc03CopyLoc],
      // dwdcopyOfAckLoc: [this.dwdcopyOfAckLoc],
      // dwdotherAttachmentsLoc2: [this.dwdotherAttachmentsLoc2],
      // dwdoiaLoc: [this.dwdoiaLoc],
      // dwdpaymentChallanLoc: [this.dwdpaymentChallanLoc],

      itemList: this.fBuild.array([]),
      igst1Total: [this.caseData?.igst1Total],
      cgst1Total: [this.caseData?.cgst1Total],
      sgst1Total: [this.caseData?.sgst1Total],
      cess1Total: [this.caseData?.cess1Total],
      interest1Total: [this.caseData?.interest1Total],
      penalty1Total: [this.caseData?.penalty1Total],
      totOfTotal1: [this.caseData?.totOfTotal1],
      igst3Total: [this.caseData?.igst3Total],
      cgst3Total: [this.caseData?.cgst3Total],
      sgst3Total: [this.caseData?.sgst3Total],
      cess3Total: [this.caseData?.cess3Total],
      interest3Total: [this.caseData?.interest3Total],
      penalty3Total: [this.caseData?.penalty3Total],
      totOfTotal3: [this.caseData?.totOfTotal3],
      status: [this.caseData?.status],
      typeOfAuthority1: [this.caseData?.typeOfAuthority1, [Validators.required]],
      din1: [this.caseData?.din1],
      orderReceiptMode1: [this.caseData?.orderReceiptMode1],
      caseLabel: [this.caseData?.caseLabel, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
      predepositDetails: new UntypedFormArray([]),
      protestDetails: new UntypedFormArray([]),
      appealDetails: new UntypedFormArray([]),
      consultantCharges: new UntypedFormArray([]),
      whetherCaseCallBook: [this.caseData?.whetherCaseCallBook],
      typeOfAuthority3: [this.caseData?.typeOfAuthority3],
      din3: [this.caseData?.din3],
      orderReceiptMode3: [this.caseData?.orderReceiptMode3],
      forumOfAppeal: [this.caseData?.forumOfAppeal],
      liabilityDetails: new UntypedFormArray([]),
      officer3: [this.caseData?.issuingAuth3?.officer],
      designation3: [this.caseData?.issuingAuth3?.designation],
      jurisdiction3: [this.caseData?.issuingAuth3?.jurisdiction],
      address3: [this.caseData?.issuingAuth3?.address],
      contact3: [this.caseData?.issuingAuth3?.contact],
      // email3:[this.caseData?.issuingAuth3?.email],
      email3: [this.caseData?.issuingAuth3?.email, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      remandedPaymentMade: new UntypedFormArray([]),

      remandedBackForum: [this.caseData?.remandedBackForum],
      remandedCaseId: [null],
    });
    this.appAuthP1Form.controls['protestDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldProtestDetailsValues = this.appAuthP1Form.value['protestDetails'];
        this.setCummulativeCount(this.oldProtestDetailsValues, selectedValue, 'protestDetails');
      });

    this.appAuthP1Form.controls['predepositDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldPredepositeDetailsValues = this.appAuthP1Form.value['predepositDetails'];
        this.setCummulativeCount(this.oldPredepositeDetailsValues, selectedValue, 'predepositDetails');
      });

    this.appAuthP1Form.controls['consultantCharges']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldConsulatntValues = this.appAuthP1Form.value['consultantCharges'];
        this.setCummulativeCount(this.oldConsulatntValues, selectedValue, 'consultantCharges');
      });

    this.appAuthP1Form.controls['liabilityDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldLiabilityDetailValues = this.appAuthP1Form.value['liabilityDetails'];
        this.setCummulativeCount(this.oldLiabilityDetailValues, selectedValue, 'liabilityDetails');
      });

  }
  get p1() { return this.appAuthP1Form.controls; }
  getAppealBookLoc(): UntypedFormGroup[] {
    return (this.appAuthP1Form.get('appealBookLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getOtherAttachmentLoc1(): UntypedFormGroup[] {
    return (this.appAuthP1Form.get('otherAttachmentsLoc1') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getCopyOrderLoc(): UntypedFormGroup[] {
    return (this.appAuthP1Form.get('copyOfOioLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPreDepositCopyForm(preDepoCopyForm: UntypedFormGroup): UntypedFormGroup[] {
    return (preDepoCopyForm.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPreDepositBankChallan(preDepoBankChallan: UntypedFormGroup): UntypedFormGroup[] {
    return (preDepoBankChallan.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getProtestCopyForm(protestCopyForm: UntypedFormGroup): UntypedFormGroup[] {
    return (protestCopyForm.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getProtestBankChallan(protestBankChallan: UntypedFormGroup): UntypedFormGroup[] {
    return (protestBankChallan.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAppealAckLoc(ackLocFrmGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (ackLocFrmGrp.get('ackCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAppealOtherAttachLoc(otherAttachFrmGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (otherAttachFrmGrp.get('otherAttachmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getphCopyLoc(phCopyLocFrmGrp: any): UntypedFormGroup[] {
    return (phCopyLocFrmGrp.get('phCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getAdditionalSub(addSubLocFrmGrp: any): UntypedFormGroup[] {
    return (addSubLocFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhRecord(phRecordFrmGrp: any): UntypedFormGroup[] {
    return (phRecordFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAdjournmentLetter(phAdjournmentLetterFrmGrp: any): UntypedFormGroup[] {
    return (phAdjournmentLetterFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getConsultOtherAttachment(consultAttachGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (consultAttachGrp.get('otherAttachmentLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getOiaLoc(): UntypedFormGroup[] {
    return (this.appAuthP1Form.get('oiaLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLiabilityCopyForm(liabilitycopyFormLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (liabilitycopyFormLoc.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLiabilityBankChallan(liabilitybankChallanFormLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (liabilitybankChallanFormLoc.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAppealBookLocLength() {
    let count = 0;
    let predepositFrmArray = this.appAuthP1Form.get('appealBookLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getOtherAttachmentLoc1Length() {
    let count = 0;
    let predepositFrmArray = this.appAuthP1Form.get('otherAttachmentsLoc1') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getCopyOrderLocLength() {
    let count = 0;
    let predepositFrmArray = this.appAuthP1Form.get('copyOfOioLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getPreDepositCopyFormLength(j?: any) {
    let count = 0;
    let predepositDetailsArray = this.appAuthP1Form.get('predepositDetails') as UntypedFormArray;
    let letterLocFrmArray = predepositDetailsArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPreDepositBankChallanLength(j?: any) {
    let count = 0;
    let predepositDetailsArray = this.appAuthP1Form.get('predepositDetails') as UntypedFormArray;
    let letterLocFrmArray = predepositDetailsArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getProtestCopyLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.appAuthP1Form.get('protestDetails') as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getProtestBankChallanLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.appAuthP1Form.get('protestDetails') as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAppealAckLocLength(j?: any) {
    let count = 0;
    let appealDetailsArray = this.appAuthP1Form.get('appealDetails') as UntypedFormArray;
    let letterLocFrmArray = appealDetailsArray.controls[j]?.get('ackCopyLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAppealOtherAttachLocLength(j?: any) {
    let count = 0;
    let appealDetailsArray = this.appAuthP1Form.get('appealDetails') as UntypedFormArray;
    let otherAttachmentsLocArray = appealDetailsArray.controls[j]?.get('otherAttachmentsLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhCopyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.appAuthP1Form.get('itemList') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = phCopyLocArray.controls[j]?.get('phCopyLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAdditionalSubmissionLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.appAuthP1Form.get('itemList') as UntypedFormArray;
    let additionalSubmissionLocFrmArray = phCopyLocArray.controls[j]?.get('additionalSubmissionLoc') as UntypedFormArray;
    let lettercount = additionalSubmissionLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getphAdjournmentLetterLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.appAuthP1Form.get('itemList') as UntypedFormArray;
    let phAdjournmentLetterLocFrmArray = phCopyLocArray.controls[j]?.get('phAdjournmentLetterLoc') as UntypedFormArray;
    let lettercount = phAdjournmentLetterLocFrmArray.value.filter((x: any) => x.fileLoc != '');
    return lettercount.length;
  }
  getPhRecordIfAnyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.appAuthP1Form.get('itemList') as UntypedFormArray;
    let phRecordIfAnyLocFrmArray = phCopyLocArray.controls[j]?.get('phRecordIfAnyLoc') as UntypedFormArray;
    let lettercount = phRecordIfAnyLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getConsultOtherAttachmentLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.appAuthP1Form.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('otherAttachmentLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getOiaLocLength() {
    let count = 0;
    let predepositFrmArray = this.appAuthP1Form.get('oiaLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getLiabilityCopyFormLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.appAuthP1Form.get('liabilityDetails') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '');
    return lettercount.length;
  }
  getLiabilityBankChallanLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.appAuthP1Form.get('liabilityDetails') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '');
    return lettercount.length;
  }
  createAppealBookLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOtherAttachmentLoc1(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createCopyOrderForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPreDepoCopyForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPreDepoBankChallanForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createProtestCopyForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createProtestBankChallanForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAppealAckCopy(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAppealOtherAttach(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createphCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAdditionalSubLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhRecord(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhAdjournmentLetterLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createConsultAttach(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOiaLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createLiabilityCopyForm(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createLiabilityBankChallan(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  openAppealBookLoc(content: any) {
    this.addAppealBookLoc()
    this.modalService.open(content);
  }
  openOtherAttachmentLoc1(content: any) {
    this.addOtherAttachmentLoc1()
    this.modalService.open(content);
  }
  openCopyOrderModel(content: any) {
    this.addCopyOrder();
    this.modalService.open(content);
  }
  openPreDepoCopyForm(content: any, i: any) {
    this.addPreDepoCopyForm(i, 0);
    this.modalService.open(content)
  }
  openPreDepoBankChallanForm(content: any, i: any) {
    this.addPreDepoBankChallanForm(i, 0);
    this.modalService.open(content)
  }
  openProtestCopyForm(content: any, i: any) {
    this.addProtestCopyForm(i, 0);
    this.modalService.open(content)
  }
  openProtestBankChallan(content: any, i: any) {
    this.addProtestBankChallan(i, 0);
    this.modalService.open(content)
  }
  openAppealAckCopy(content: any, i: any) {
    this.addAppealAckCopy(i, 0);
    this.modalService.open(content);
  }
  openAppealOtherAttach(content: any, i: any) {
    this.addAppealOtherAttach(i, 0);
    this.modalService.open(content);

  }
  openphCopyLoc(content: any, i: any) {
    this.addphCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openAdjournmentLetter(content: any, i: any) {
    this.addAdjournmentLetter(i, 0);
    this.modalService.open(content);
  }
  openAdditionSub(content: any, i: any) {
    this.addAdditionalCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openPhRecord(content: any, i: any) {
    this.addPhRecord(i, 0);
    this.modalService.open(content);
  }
  openConsultAttach(content: any, i: any) {
    this.addConsultAttach(i, 0);
    this.modalService.open(content);
  }
  openOiaLocModel(content: any) {
    this.addOiaLoc();
    this.modalService.open(content);
  }
  openLiabilityCopyOfForm(content: any, i: any) {
    this.addLiabilityCopyOfForm(i, 0);
    this.modalService.open(content);
  }
  openLiabilityBankChallan(content: any, i: any) {
    this.addLiabilityCopyOfProBankChalan(i, 0);
    this.modalService.open(content);
  }
  addAppealBookLoc() {
    let appealBookLocArray = this.appAuthP1Form.get('appealBookLoc') as UntypedFormArray;
    let i = 0;

    if (appealBookLocArray.length > 0) {
      let length = appealBookLocArray.length;

      while (i < 5 - length) {
        appealBookLocArray.push(this.createAppealBookLoc());

        i++;
      }
    } else {
      while (i < 5) {
        appealBookLocArray.push(this.createAppealBookLoc());

        i++;
      }
    }
  }
  uploadAppealBookLoc(bookLocIndex: any) {
    let bookLocArray = this.appAuthP1Form.controls.appealBookLoc as UntypedFormArray;
    if (bookLocArray.controls.length > 0) {
      for (let index = 0; index < bookLocArray.controls.length; index++) {
        if (index === bookLocIndex) {
          let itemListuploadControl = bookLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAppealBookLoc(bookLocIndex: any) {
    let bookLocArray = this.appAuthP1Form.controls.appealBookLoc as UntypedFormArray;
    if (bookLocArray.controls.length > 0) {
      for (let index = 0; index < bookLocArray.controls.length; index++) {
        if (index === bookLocIndex) {
          let itemListuploadControl = bookLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addOtherAttachmentLoc1() {
    let otherAttachLoc1Array = this.appAuthP1Form.get('otherAttachmentsLoc1') as UntypedFormArray;
    let i = 0;

    if (otherAttachLoc1Array.length > 0) {
      let length = otherAttachLoc1Array.length;

      while (i < 5 - length) {
        otherAttachLoc1Array.push(this.createOtherAttachmentLoc1());

        i++;
      }
    } else {
      while (i < 5) {
        otherAttachLoc1Array.push(this.createOtherAttachmentLoc1());

        i++;
      }
    }
  }
  uploadOtherAttachmentLoc1(otherAttachLoc1Index: any) {
    let otherAttachLoc1Array = this.appAuthP1Form.controls.otherAttachmentsLoc1 as UntypedFormArray;
    if (otherAttachLoc1Array.controls.length > 0) {
      for (let index = 0; index < otherAttachLoc1Array.controls.length; index++) {
        if (index === otherAttachLoc1Index) {
          let itemListuploadControl = otherAttachLoc1Array.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttachmentLoc1(otherAttachLoc1Index: any) {
    let otherAttachLoc1Array = this.appAuthP1Form.controls.otherAttachmentsLoc1 as UntypedFormArray;
    if (otherAttachLoc1Array.controls.length > 0) {
      for (let index = 0; index < otherAttachLoc1Array.controls.length; index++) {
        if (index === otherAttachLoc1Index) {
          let itemListuploadControl = otherAttachLoc1Array.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  addCopyOrder() {
    let copyOrderArray = this.appAuthP1Form.get('copyOfOioLoc') as UntypedFormArray;
    let i = 0;
    if (copyOrderArray.length > 0) {
      let length = copyOrderArray.length;
      while (i < 5 - length) {
        copyOrderArray.push(this.createCopyOrderForm());

        i++;
      }
    } else {
      while (i < 5) {
        copyOrderArray.push(this.createCopyOrderForm());

        i++;
      }
    }
  }
  uploadOioLoc(oioLocIndex: any) {
    let scnLocArray = this.appAuthP1Form.controls.copyOfOioLoc as UntypedFormArray;
    if (scnLocArray.controls.length > 0) {
      for (let index = 0; index < scnLocArray.controls.length; index++) {
        if (index === oioLocIndex) {
          let itemListuploadControl = scnLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOioLoc(oioLocIndex: any) {
    let scnLocArray = this.appAuthP1Form.controls.copyOfOioLoc as UntypedFormArray;
    if (scnLocArray.controls.length > 0) {
      for (let index = 0; index < scnLocArray.controls.length; index++) {
        if (index === oioLocIndex) {
          let itemListuploadControl = scnLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addPreDepoCopyForm(j: any, i: any) {
    let preDepoArray = this.appAuthP1Form.get('predepositDetails') as UntypedFormArray;
    let copyFormArray = preDepoArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormArray.length > 0) {
      let length = copyFormArray.length
      while (i < 5 - length) {
        copyFormArray.push(this.createPreDepoCopyForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormArray.push(this.createPreDepoCopyForm());
        i++;
      }
    }
  }
  addPreDepoBankChallanForm(j: any, i: any) {
    let preDepoArray = this.appAuthP1Form.get('predepositDetails') as UntypedFormArray;
    let bankChallanArray = preDepoArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.length > 0) {
      let length = bankChallanArray.length
      while (i < 5 - length) {
        bankChallanArray.push(this.createPreDepoBankChallanForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        bankChallanArray.push(this.createPreDepoBankChallanForm());
        i++;
      }
    }
  }
  addProtestCopyForm(j: any, i: any) {
    let protestDtlsArray = this.appAuthP1Form.get('protestDetails') as UntypedFormArray;
    let copyFormArray = protestDtlsArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormArray.length > 0) {
      let length = copyFormArray.length
      while (i < 5 - length) {
        copyFormArray.push(this.createProtestCopyForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormArray.push(this.createProtestCopyForm());
        i++;
      }
    }
  }
  addProtestBankChallan(j: any, i: any) {
    let protestDtlsArray = this.appAuthP1Form.get('protestDetails') as UntypedFormArray;
    let bankChallanArray = protestDtlsArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.length > 0) {
      let length = bankChallanArray.length
      while (i < 5 - length) {
        bankChallanArray.push(this.createProtestBankChallanForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        bankChallanArray.push(this.createProtestBankChallanForm());
        i++;
      }
    }
  }
  uploadPreDepoCopyForm(copyListIndex: any, copyAckLocIndex: any) {

    let preDepoDtlsArray = this.appAuthP1Form.controls.predepositDetails as UntypedFormGroup;
    let copyFormLocFrmArray = preDepoDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePreDepoCopyForm(copyListIndex: any, copyAckLocIndex: any) {
    let preDepoDtlsArray = this.appAuthP1Form.controls.predepositDetails as UntypedFormGroup;
    let copyFormLocFrmArray = preDepoDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadPreDepoBankChallan(copyListIndex: any, copyAckLocIndex: any) {

    let preDepoDtlsArray = this.appAuthP1Form.controls.predepositDetails as UntypedFormGroup;
    let bankChallanArray = preDepoDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.controls.length > 0) {
      for (let index = 0; index < bankChallanArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePreDepoBankChallan(copyListIndex: any, copyAckLocIndex: any) {
    let preDepoDtlsArray = this.appAuthP1Form.controls.predepositDetails as UntypedFormGroup;
    let bankChallanArray = preDepoDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.controls.length > 0) {
      for (let index = 0; index < bankChallanArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadProtestCopyForm(copyListIndex: any, copyAckLocIndex: any) {

    let protestDtlsArray = this.appAuthP1Form.controls.protestDetails as UntypedFormGroup;
    let copyFormLocFrmArray = protestDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteProtestCopyForm(copyListIndex: any, copyAckLocIndex: any) {
    let protestDtlsArray = this.appAuthP1Form.controls.protestDetails as UntypedFormGroup;
    let copyFormLocFrmArray = protestDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadProtestBankChallan(copyListIndex: any, copyAckLocIndex: any) {

    let protestDtlsArray = this.appAuthP1Form.controls.protestDetails as UntypedFormGroup;
    let bankChallanArray = protestDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.controls.length > 0) {
      for (let index = 0; index < bankChallanArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteProtestBankChallan(copyListIndex: any, copyAckLocIndex: any) {
    let protestDtlsArray = this.appAuthP1Form.controls.protestDetails as UntypedFormGroup;
    let bankChallanArray = protestDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanArray.controls.length > 0) {
      for (let index = 0; index < bankChallanArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAppealAckCopy(j: any, i: any) {
    let appealDetails = this.appAuthP1Form.get('appealDetails') as UntypedFormArray;
    let ackCopyLocArray = appealDetails.controls[j].get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray.length > 0) {
      let length = ackCopyLocArray.length
      while (i < 5 - length) {
        ackCopyLocArray.push(this.createAppealAckCopy());
        i++;
      }
    }
    else {
      while (i < 5) {
        ackCopyLocArray.push(this.createAppealAckCopy());
        i++;
      }
    }
  }
  uploadAppealAckCopy(ackCopyListIndex: any, ackCopyLocIndex: any) {
    let appealDetails = this.appAuthP1Form.get('appealDetails') as UntypedFormArray;
    let ackCopyLocArray = appealDetails.controls[ackCopyListIndex].get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAppealAckCopy(ackCopyListIndex: any, ackCopyLocIndex: any) {
    let appealDetails = this.appAuthP1Form.get('appealDetails') as UntypedFormArray;
    let ackCopyLocArray = appealDetails.controls[ackCopyListIndex].get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadAppealOtherAttach(otherAttachListIndex: any, otherAttachLocIndex: any) {
    let appealDetails = this.appAuthP1Form.get('appealDetails') as UntypedFormArray;
    let otherAttachLocArray = appealDetails.controls[otherAttachListIndex].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttachLocArray.controls.length > 0) {
      for (let index = 0; index < otherAttachLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = otherAttachLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAppealOtherAttach(otherAttachListIndex: any, otherAttachLocIndex: any) {
    let appealDetails = this.appAuthP1Form.get('appealDetails') as UntypedFormArray;
    let otherAttachLocArray = appealDetails.controls[otherAttachListIndex].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttachLocArray.controls.length > 0) {
      for (let index = 0; index < otherAttachLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = otherAttachLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAppealOtherAttach(j: any, i: any) {
    let appealDetails = this.appAuthP1Form.get('appealDetails') as UntypedFormArray;
    let otherAttachLocArray = appealDetails.controls[j].get('otherAttachmentsLoc') as UntypedFormArray;
    if (otherAttachLocArray.length > 0) {
      let length = otherAttachLocArray.length
      while (i < 5 - length) {
        otherAttachLocArray.push(this.createAppealOtherAttach());
        i++;
      }
    }
    else {
      while (i < 5) {
        otherAttachLocArray.push(this.createAppealOtherAttach());
        i++;
      }
    }
  }
  addphCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.appAuthP1Form.get('itemList') as UntypedFormArray;
    let phCopyLocFrmArray = dtCaseFrmArray.controls[j].get('phCopyLoc') as UntypedFormArray;

    if (phCopyLocFrmArray.length > 0) {
      let length = phCopyLocFrmArray.length
      while (i < 5 - length) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
  }
  uploadPhCopy(phCopyListIndex: any, phCopyLocIndex: any) {

    let itemListsArray = this.appAuthP1Form.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    let itemListsArray = this.appAuthP1Form.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAdditionalCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.appAuthP1Form.get('itemList') as UntypedFormArray;
    let additionalSubLocFrmArray = dtCaseFrmArray.controls[j].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.length > 0) {
      let length = additionalSubLocFrmArray.length
      while (i < 5 - length) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
  }
  uploadAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.appAuthP1Form.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.appAuthP1Form.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;
    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addPhRecord(j: any, i: any) {
    let dtCaseFrmArray = this.appAuthP1Form.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
  }
  uploadPhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.appAuthP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.appAuthP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAdjournmentLetter(j: any, i: any) {
    let dtCaseFrmArray = this.appAuthP1Form.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
  }
  uploadAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.appAuthP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.appAuthP1Form.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addConsultAttach(j: any, i: any) {
    let consultFrmArray = this.appAuthP1Form.get('consultantCharges') as UntypedFormArray;
    let consultAttachFrmArray = consultFrmArray.controls[j].get('otherAttachmentLoc') as UntypedFormArray;

    if (consultAttachFrmArray.length > 0) {
      let length = consultAttachFrmArray.length
      while (i < 5 - length) {
        consultAttachFrmArray.push(this.createConsultAttach());
        i++;
      }
    }
    else {
      while (i < 5) {
        consultAttachFrmArray.push(this.createConsultAttach());
        i++;
      }
    }
  }
  uploadConsultAttach(consultAttachListIndex: any, consultAttachLocIndex: any) {
    let consultArray = this.appAuthP1Form.controls.consultantCharges as UntypedFormGroup;
    let consultAttachLocFrmArray = consultArray.controls[consultAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;

    if (consultAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < consultAttachLocFrmArray.controls.length; index++) {
        if (index === consultAttachLocIndex) {
          let itemListuploadControl = consultAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteConsultAttach(consultAttachListIndex: any, consultAttachLocIndex: any) {
    let consultArray = this.appAuthP1Form.controls.consultantCharges as UntypedFormGroup;
    let consultAttachLocFrmArray = consultArray.controls[consultAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;
    if (consultAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < consultAttachLocFrmArray.controls.length; index++) {
        if (index === consultAttachLocIndex) {
          let itemListuploadControl = consultAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addOiaLoc() {
    let oioArray = this.appAuthP1Form.get('oiaLoc') as UntypedFormArray;
    let i = 0;

    if (oioArray.length > 0) {
      let length = oioArray.length;

      while (i < 5 - length) {
        oioArray.push(this.createOiaLoc());

        i++;
      }
    } else {
      while (i < 5) {
        oioArray.push(this.createOiaLoc());

        i++;
      }
    }
    this.appAuthP1Form.updateValueAndValidity();
  }
  uploadOiaCopy(oiaLocIndex: any) {

    let oiaLocArray = this.appAuthP1Form.controls.oiaLoc as UntypedFormArray;
    if (oiaLocArray.controls.length > 0) {
      for (let index = 0; index < oiaLocArray.controls.length; index++) {
        if (index === oiaLocIndex) {
          let itemListuploadControl = oiaLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
    // for (let index = 0; index < this.appAuthP1Form.value.oiaLoc.length; index++) {
    //   const element = this.appAuthP1Form.value.oiaLoc[index];
    //   if (element.fileLoc == '') {
    //     this.isNotEmpty = false;
    //   } else {
    //     this.isNotEmpty = true;
    //     break;
    //   }
    // }
  }
  deleteOiaCopy(oiaLocIndex: any) {
    let oiaLocArray = this.appAuthP1Form.controls.oiaLoc as UntypedFormArray;
    if (oiaLocArray.controls.length > 0) {
      for (let index = 0; index < oiaLocArray.controls.length; index++) {
        if (index === oiaLocIndex) {
          let itemListuploadControl = oiaLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addLiabilityCopyOfForm(j: any, i: any) {
    let liabilityFrmArray = this.appAuthP1Form.get('liabilityDetails') as UntypedFormArray;
    let copyFormLocFrmArray = liabilityFrmArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.length > 0) {
      let length = copyFormLocFrmArray.length
      while (i < 5 - length) {
        copyFormLocFrmArray.push(this.createLiabilityCopyForm());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormLocFrmArray.push(this.createLiabilityCopyForm());
        i++;
      }
    }
  }
  addLiabilityCopyOfProBankChalan(j: any, i: any) {
    let liabilityFrmArray = this.appAuthP1Form.get('liabilityDetails') as UntypedFormArray;
    let proBankChallanLocFrmArray = liabilityFrmArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (proBankChallanLocFrmArray.length > 0) {
      let length = proBankChallanLocFrmArray.length
      while (i < 5 - length) {
        proBankChallanLocFrmArray.push(this.createLiabilityBankChallan());
        i++;
      }
    }
    else {
      while (i < 5) {
        proBankChallanLocFrmArray.push(this.createLiabilityBankChallan());
        i++;
      }
    }
  }
  uploadLiabilityCopyForm(copyListIndex: any, copyAckLocIndex: any) {

    let liabilityDtlsArray = this.appAuthP1Form.controls.liabilityDetails as UntypedFormGroup;
    let copyFormLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLiabilityCopyForm(copyListIndex: any, copyAckLocIndex: any) {
    let liabilityDtlsArray = this.appAuthP1Form.controls.liabilityDetails as UntypedFormGroup;
    let copyFormFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = copyFormFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadLiabilityBankChallanForm(copyListIndex: any, copyAckLocIndex: any) {

    let liabilityDtlsArray = this.appAuthP1Form.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanLocFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLiabilityBankChallanForm(copyListIndex: any, copyAckLocIndex: any) {
    let liabilityDtlsArray = this.appAuthP1Form.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanLocFrmArray = liabilityDtlsArray.controls[copyListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanLocFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocFrmArray.controls.length; index++) {
        if (index === copyAckLocIndex) {
          let itemListuploadControl = bankChallanLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  p2validation() {
    var appealDetailsArr = this.appAuthP1Form.controls.appealDetails as UntypedFormArray;
    if (appealDetailsArr.controls.length > 0) {
      for (var i = 0; i < appealDetailsArr.controls.length; i++) {
        var issues1Control = appealDetailsArr.controls[i] as UntypedFormGroup;

        if (issues1Control.controls?.appealRefNo.value === null || issues1Control.controls?.appealRefNo.value === '') {
          issues1Control.controls?.appealRefNo.setErrors({ 'required': true });
        } else {
          issues1Control.controls.appealRefNo.setErrors(null);
        }
        if (issues1Control.controls.filingAppealDate.value === null || issues1Control.controls.filingAppealDate.value === '') {
          issues1Control.controls.filingAppealDate.setErrors({ 'required': true });
        } else {
          issues1Control.controls.filingAppealDate.setErrors(null);
        }
        // if (issues1Control.controls.ackCopyLoc.value === null || issues1Control.controls.ackCopyLoc.value === '') {
        //   issues1Control.controls.ackCopyLoc.setErrors({'required': true});
        // }  else {
        //   issues1Control.controls.ackCopyLoc.setErrors(null);
        // }
      }
    }
    return !this.copyOfAckLocReq;    // //phase2

  }
  p3validation() {
    var issues1Array = this.appAuthP1Form.controls.issues as UntypedFormArray
    for (var i = 0; i < issues1Array.controls.length; i++) {
      var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
      if (issues1Control.controls.status.value === null || issues1Control.controls.status.value === "null" || issues1Control.controls.status.value === '') {
        issues1Control.controls.status.setErrors({ 'required': true });
      } else {
        issues1Control.controls.status.setErrors(null);
      }
    }


    if (this.appAuthP1Form.value['oiaNo'] === null) {
      this.appAuthP1Form.controls['oiaNo'].setErrors({ 'required': true });
    }
    if (this.appAuthP1Form.value['dateOfOia'] === null) {
      this.appAuthP1Form.controls['dateOfOia'].setErrors({ 'required': true });
    }
    if (this.appAuthP1Form.value['dateOfReceiptOfOia'] === null) {
      this.appAuthP1Form.controls['dateOfReceiptOfOia'].setErrors({ 'required': true });
    }
    if (this.appAuthP1Form.value['outcome'] === null) {
      this.appAuthP1Form.controls['outcome'].setErrors({ 'required': true });
    }

    if (this.appAuthP1Form.value['forumOfAppeal'] === null || this.appAuthP1Form.value['forumOfAppeal'] === "null" || this.appAuthP1Form.value['forumOfAppeal'] === undefined) {
      this.appAuthP1Form.controls['forumOfAppeal'].setErrors({ 'required': true });
    }
    if (this.appAuthP1Form.value['typeOfAuthority3'] === null) {
      this.appAuthP1Form.controls['typeOfAuthority3'].setErrors({ 'required': true });
    }
    // if (this.appAuthP1Form.value['whetherAppealPreferred'] === null) {
    // this.appAuthP1Form.controls['whetherAppealPreferred'].setErrors({'required': true});
    // }
    if (this.appAuthP1Form.value['oiaLoc'] == null || this.appAuthP1Form.value['oiaLoc'] === '') {
      this.oiaLocReq = true;
      //this.appAuthP1Form.controls['oiaLoc'].setErrors({'required': true});
    } else {
      this.oiaLocReq = false;
      //this.appAuthP1Form.controls['oiaLoc'].setErrors(null);
    }
    return this.appAuthP1Form.valid;

    //this.appAuthP1Form.updateValueAndValidity({ onlySelf: true });
  }
  apaDivArr(): UntypedFormArray {
    return this.appAuthP1Form.get("itemList") as UntypedFormArray
  }

  // phTabArr(): FormArray {
  //   return this.appAuthP1Form.get("itemList") as FormArray
  // }
  newphArray(item: any = null): UntypedFormGroup {
    return this.fBuild.group({
      phNoticeDate: ['', []],
      dateOfPhScheduled: ['', []],
      phCopyLoc: new UntypedFormArray([]),
      adjournedOrNot: [false],
      attended: ['', []],
      mode: ['', []],
      phRefNo:['', []],
      phTime:['', []],
      consultant: [this.caseData?.consultant1, []],
      personResponsible: [this.caseData?.personResponsible1, []],
      dateOfsubmission: ['', []],
      additionalSubmissionLoc: new UntypedFormArray([]),
      phDiscussionNotes: ['', []],
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phAdjournmentLetterLoc: new UntypedFormArray([]),
      dateOfSubmission: ['', []],


    })
  }
  createAdjDiv() {
    this.apaDivArr().push(this.newphArray());
  }
  removeLetter(i: number) {
    this.apaDivArr().removeAt(i);
  }
  deleteitemListFile() {
    var itemListArray = this.appAuthP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.phRecordIfAnyLoc.setValue(null);
      }

    }
  }
  deletePHNoticeFile() {
    var itemListArray = this.appAuthP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.phCopyLoc.setValue(null);
      }
    }
  }
  deleteSubmissionFile() {
    var itemListArray = this.appAuthP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.additionalSubmissionLoc.setValue(null);
      }
    }
  }
  changeadjOrNot(event: any, i: any) {
    let formVal = this.p1.itemList.value;
    if (event.target.value === "true") {
      this.createAdjDiv();
      for (let i = 0; i < formVal.length; i++) {
        var b = (formVal.length - 1);
        if (i == b)
          formVal[i].IsActive = true;
        else
          formVal[i].IsActive = false;
      }
    }

  }
  checkpreDepositNo(event: any) {
    if (event.target.value === "false") {
      this.disablField = true;
    } else {
      this.disablField = false;
    }
  }
  changePayMode(event: any) {
    if (event.target.value === "ITC") {
      this.disablBankChallan = true;
    } else {
      this.disablBankChallan = false;
    }
  }

  /*Issue P1 div itration */

  issuInvArr(): UntypedFormGroup[] {
    return (this.appAuthP1Form.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  newIssueInvArray(): UntypedFormGroup {
    return this.fBuild.group({
      issue: [null, [Validators.required]],
      keyword: [null, []],
      riskParam: [null, [Validators.required]],
      remark1: [null, [Validators.maxLength(100)]],
      igst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      total1: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      igst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      total3: [null, [Validators.pattern(/^-?[0-9,]+$/)]],
      details: new UntypedFormArray([]),
      status: [null, []],


    })
  }
  addIssueInv() {
    let issueFrmGrp = this.newIssueInvArray();
    (issueFrmGrp.get('details') as UntypedFormArray).push(this.newDetailsFrmGrp());
    (this.appAuthP1Form.get('issues') as UntypedFormArray).push(issueFrmGrp);
  }
  rmIssueInv(i: number, isRemandedBack: boolean = false) {
    (this.appAuthP1Form.get('issues') as UntypedFormArray).removeAt(i);
    this.issueValueChange();
    if (!isRemandedBack) this.rmvIssueModalRef.close();
  }
  // details array
  getdetails(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newDetailsFrmGrp() {
    return new UntypedFormGroup({
      finYear: new UntypedFormControl(''),
      quarter: new UntypedFormControl(''),
      provisionAmt1: new UntypedFormControl(''),
      contLiabilityAmt1: new UntypedFormControl(''),
      provisionChangeReason1: new UntypedFormControl(''),
      provisionNotMadeReason1: new UntypedFormControl(''),
      contLiabReason1: new UntypedFormControl(''),
      considerInCaroReport1: new UntypedFormControl(''),
      caroAmt1: new UntypedFormControl(''),
      otherLiability1: new UntypedFormControl(''),
      otherLiabilityReason1: new UntypedFormControl(''),


    })
  }
  addDetail(j: any) {
    let issuesFrmArray = this.appAuthP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[j].get('details') as UntypedFormArray;
    detailsFrmArray.push(this.newDetailsFrmGrp());
  }
  rmDetail(i: number, k: number) {
    let issuesFrmArray = this.appAuthP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    detailsFrmArray.removeAt(k);
    this.rmvDetailModalRef.close();

  }
  /*Issue P1 div itration */
  /*Issue P2 div itration */

  // issu3InvArr(): FormArray {
  //   return this.appAuthP1Form.get("issues3") as FormArray
  // }

  // newIssue3InvArray(): FormGroup {
  //   return this.fBuild.group({
  //     issue: [null, []],
  //     keyword: [null, []],
  //     riskParam: [null, []],
  //     igst: [null, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
  //     cgst: [null, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
  //     sgst: [null, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
  //     cess: [null, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
  //     interest: [null, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
  //     penalty: [null, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
  //     status: [null, []],

  //   })
  // }
  // addIssue3Inv() {
  //   this.issu3InvArr().push(this.newIssue3InvArray());
  //   this.isIssueAdd = true;
  // }
  // rmIssue3Inv(i: number) {
  //   this.issu3InvArr().removeAt(i);
  // }
  /*Issue P2 div itration */
  /*detaild provision div itration */

  // provArr(): FormArray {
  //   return this.appAuthP1Form.get("provisionDetails") as FormArray
  // }

  // newProvArr(): FormGroup {
  //   return this.fBuild.group({
  //     finYear: [null],
  //     percentageOfProvision: [null],
  //     amountOfProvision: [null],
  //   })
  // }
  // addProv() {
  //   this.provArr().push(this.newProvArr());
  //   this.isProvAdd = true;
  // }
  // rmProv(i: number) {
  //   this.provArr().removeAt(i);
  // }
  /*detaild provision div itration */
  /*upload file selection */
  // onFileSelect(event: any, type: any = null) {
  //   this.litService.onFileSelect(event, type);

  // }

  onFileSelect(event: any, type: any = null) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        // this.formData = new FormData();
        // this.formData.append('fname', this.selectedFile);
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, type);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.selectedFile = null;
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  onUpload(selectedfield: any, type: any) {
    let urlData = {
      gstinOrPan: this.appAuthP1Form.controls.gstin.value,
      type: this.tabType[2].key,
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.litService.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.setFormData(response.response.url, response.response.path, selectedfield, type);
        // this.setFormData(parseData.url, parseData.path, selectedfield, type);
      } else {
      }
    })
  }



  setFormData(url: any, path: any, selectedfield: any, type: any = null) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      console.log("response receved is ", response);
      if (this.selectedFile && type === 'predepositDetails' && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.appAuthP1Form.get('predepositDetails') as UntypedFormArray).controls.forEach((c: any, index) => {
          if (letterIndex == index) {
            c.patchValue(attrFieldNm[1], path);
            c.controls[attrFieldNm[1]].setValue(path);
          }

        });

      } else if (this.selectedFile && type === 'protestDetails' && this.matches) {

        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.appAuthP1Form.get('protestDetails') as UntypedFormArray).controls.forEach((c: any, index) => {
          if (letterIndex == index) {
            c.patchValue(attrFieldNm[1], path);
            c.controls[attrFieldNm[1]].setValue(path);
          }
        });
      } else if (this.selectedFile && type === 'liabilityDetails' && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.appAuthP1Form.get('liabilityDetails') as UntypedFormArray).controls.forEach((c: any, index) => {
          if (letterIndex == index) {
            c.patchValue(attrFieldNm[1], path);
            c.controls[attrFieldNm[1]].setValue(path);
          }

        });
      } else if (this.selectedFile && type === 'appealDetails' && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.appAuthP1Form.get('appealDetails') as UntypedFormArray).controls.forEach((c: any, index) => {
          if (letterIndex == index) {
            c.patchValue(attrFieldNm[1], path);
            c.controls[attrFieldNm[1]].setValue(path);
          }
        });
      }
      else if (this.selectedFile && type === 'consultantCharges' && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.appAuthP1Form.get('consultantCharges') as UntypedFormArray).controls.forEach((c: any, index) => {
          if (letterIndex == index) {
            c.patchValue(attrFieldNm[1], path);
            c.controls[attrFieldNm[1]].setValue(path);
          }

        });
      }

      else if (this.selectedFile && this.p1.itemList.value && this.matches && !type) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.p1.itemList.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;//this.filePath;
              this.savecaseItemList();
            }
          }
        });

      }
      if (selectedfield) {
        var toupdate = this.appAuthP1Form.value;
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.appAuthP1Form.patchValue(this.appAuthP1Form.value);
      }
      else {
        let field = selectedfield;//this.selectedField;
        let adjForm = this.appAuthP1Form.value;
        adjForm[field] = path;//this.filePath;
        if (this.caseData == null) {
          this.caseData = {};
        }
        this.caseData[field] = path;
        //console.log(adjForm);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    });
  }
  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
      // path.push(pathChunk.substr(0, indexOfBrc));
      // path.push(pathChunk.charAt(indexOfBrc + 1));
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  changeOutcome(event: any = null) {
    let data;
    if (!event) {
      data = this.appAuthP1Form.value.outcome;
    } else {
      data = event.target.value;
    }
    if (data === "Favorable" || data === "Partly Favorable") {
      this.disabledWeatherAppl = true;
      this.disabledWeatherWrit = true;
      this.disabledWeatherDept = false;

      this.appAuthP1Form.get("whetherAppealPreferred")?.clearValidators();
      this.appAuthP1Form.get("whetherAppealPreferred")?.updateValueAndValidity();
      // this.appAuthP1Form.get("whetherAppealPreferred")?.setValue(false);
      this.appAuthP1Form.get("writAppPreferred")?.clearValidators();
      this.appAuthP1Form.get("writAppPreferred")?.updateValueAndValidity();
      // this.appAuthP1Form.get("writAppPreferred")?.setValue(false);
      this.appAuthP1Form.get("whetherDptAppealPreferred")?.clearValidators();
      this.appAuthP1Form.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      // this.appAuthP1Form.get("whetherDptAppealPreferred")?.setValue(true);
    } else if (data === "Unfavorable" || data === "Partly Unfavorable") {
      this.disabledWeatherAppl = false;
      this.disabledWeatherWrit = false;
      this.disabledWeatherDept = true;
      this.appAuthP1Form.get("whetherAppealPreferred")?.clearValidators();
      this.appAuthP1Form.get("whetherAppealPreferred")?.updateValueAndValidity();
      // this.appAuthP1Form.get("whetherAppealPreferred")?.setValue(false);
      this.appAuthP1Form.get("writAppPreferred")?.clearValidators();
      this.appAuthP1Form.get("writAppPreferred")?.updateValueAndValidity();
      // this.appAuthP1Form.get("writAppPreferred")?.setValue(false);
      this.appAuthP1Form.get("whetherDptAppealPreferred")?.clearValidators();
      this.appAuthP1Form.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      // this.appAuthP1Form.get("whetherDptAppealPreferred")?.setValue(false);
    } else {
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
    }
    this.changeIssueConclusionValue();
    this.setForumAppeal();
  }

  changeIssueConclusionValue() {
    var issues1Array = this.appAuthP1Form.controls.issues as UntypedFormArray;

    switch (this.appAuthP1Form.value?.outcome) {
      case 'Favorable': this.issueConclusion = [{ key: "F", value: "Favorable" }];
        if (issues1Array.controls.length > 0) {
          for (var i = 0; i < issues1Array.controls.length; i++) {
            var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
            issues1Control.controls.status.setValue('Favorable');
          }
        }
        break;
      case 'Unfavorable': this.issueConclusion = [{ key: "U", value: "Unfavorable" }, { key: "A", value: "Admitted" }];
        // if(issues1Array.controls.length > 0) {
        //   for(var i = 0; i < issues1Array.controls.length; i++){
        //       var issues1Control = issues1Array.controls[i] as FormGroup;
        //       issues1Control.controls.status.setValue('Unfavorable');
        //       // issues1Control.controls.status.setValue(issues1Control.controls.status.value?issues1Control.controls.status.value:'Unfavorable');
        //     }
        // }
        break;
      case 'Remanded Back': this.issueConclusion = [{ key: "R", value: "Remanded Back" }];
        if (issues1Array.controls.length > 0) {
          for (var i = 0; i < issues1Array.controls.length; i++) {
            var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
            issues1Control.controls.status.setValue('Remanded Back');
          }
        }
        break;
      // case 'Partly Favorable': this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER;
      //                         //  let index1 =  this.issueConclusion.findIndex(value => value.value === 'Remanded Back');
      //                         //  this.issueConclusion.splice(index1,1);
      //                         //  let index2 = this.issueConclusion.findIndex(value => value.value === 'Partly Remanded Back');
      //                         //  this.issueConclusion.splice(index2,1);
      //                           if(issues1Array.controls.length > 0) {
      //                             for(var i = 0; i < issues1Array.controls.length; i++){
      //                                 var issues1Control = issues1Array.controls[i] as FormGroup;
      //                                 issues1Control.controls.status.setValue(issues1Control.controls.status.value?issues1Control.controls.status.value:'');
      //                               }
      //                           }
      //                           break;
      default:
        this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER;
        if (issues1Array.controls.length > 0) {
          for (var i = 0; i < issues1Array.controls.length; i++) {
            var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
            issues1Control.controls.status.setValue(issues1Control.controls.status.value ? issues1Control.controls.status.value : '');
          }
        }
        break;
    }
  }
  checkAppYes(event: any = null) {
    let data;
    if (!event) {
      data = this.appAuthP1Form.value.whetherAppealPreferred;
    } else {
      data = event.target.value;
    }
    if (data === true || data === 'true') {
      this.disabledWeatherWrit = true;
      this.disabledWeatherDept = true;
      this.appAuthP1Form.get("writAppPreferred")?.clearValidators();
      this.appAuthP1Form.get("writAppPreferred")?.updateValueAndValidity();
      this.appAuthP1Form.get("writAppPreferred")?.setValue(false);
      this.appAuthP1Form.get("whetherDptAppealPreferred")?.clearValidators();
      this.appAuthP1Form.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      this.appAuthP1Form.get("whetherDptAppealPreferred")?.setValue(false);
      // this.appAuthP1Form.controls['writAppPreferred'].setErrors({'required': false});
      // this.rmWeatherWritErrorBorder();
    } else {
      this.disabledWeatherWrit = false;
      this.disabledWeatherDept = false;
      this.forumAppeal = FORUM_APPEAL_COMMISONER_ALL_OPTIONS;
    }
    this.setForumAppeal();

  }

  checkDeptYes(event: any = null) {
    let data;
    if (!event) {
      data = this.appAuthP1Form.value.whetherDptAppealPreferred;
    } else {
      data = event.target.value;
    }
    if (data === "true" || data === true) {
      this.disabledWeatherAppl = true;
      this.disabledWeatherWrit = true;
      this.appAuthP1Form.get("writAppPreferred")?.clearValidators();
      this.appAuthP1Form.get("writAppPreferred")?.updateValueAndValidity();
      this.appAuthP1Form.get("writAppPreferred")?.setValue(false);
      this.appAuthP1Form.get("whetherAppealPreferred")?.clearValidators();
      this.appAuthP1Form.get("whetherAppealPreferred")?.updateValueAndValidity();
      this.appAuthP1Form.get("whetherAppealPreferred")?.setValue(false);
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherWrit = false;
      this.forumAppeal = FORUM_APPEAL_COMMISONER_ALL_OPTIONS;

    }
    this.setForumAppeal();
  }


  checkwritAppYes(event: any = null) {
    let data;
    if (!event) {
      data = this.appAuthP1Form.value.writAppPreferred;
    } else {
      data = event.target.value;
    }
    if (data === "true" || data === true) {
      // const data = this.forumAppeal.filter((c) => (c.key === 'sc' || c.key === 'hc'));
      // this.forumAppeal = data;
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = true;
      this.appAuthP1Form.get("whetherAppealPreferred")?.clearValidators();
      this.appAuthP1Form.get("whetherAppealPreferred")?.updateValueAndValidity();
      this.appAuthP1Form.get("whetherAppealPreferred")?.setValue(false);

      this.appAuthP1Form.get("whetherDptAppealPreferred")?.clearValidators();
      this.appAuthP1Form.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      this.appAuthP1Form.get("whetherDptAppealPreferred")?.setValue(false);
      //this.rmWeatherApplErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
      this.forumAppeal = FORUM_APPEAL_COMMISONER_ALL_OPTIONS;
    }
    this.setForumAppeal();

  }


  setForumAppeal() {
    this.forumAppeal = FORUM_APPEAL_COMMISONER_ALL_OPTIONS;

    if (((this.appAuthP1Form.value.whetherAppealPreferred === true || this.appAuthP1Form.value.whetherAppealPreferred === "true") ||
      (this.appAuthP1Form.value.whetherDptAppealPreferred === true || this.appAuthP1Form.value.whetherDptAppealPreferred === "true")) &&
      (this.appAuthP1Form.value.writAppPreferred === false || this.appAuthP1Form.value.writAppPreferred === "false")) {
      this.forumAppeal = this.forumAppeal.filter((c) => (c.key === 'hc' || c.key === 'apt'));

      return;
    }

    if ((this.appAuthP1Form.value.whetherAppealPreferred === false || this.appAuthP1Form.value.whetherAppealPreferred === "false") &&
      (this.appAuthP1Form.value.whetherDptAppealPreferred === false || this.appAuthP1Form.value.whetherDptAppealPreferred === "false") &&
      (this.appAuthP1Form.value.writAppPreferred === true || this.appAuthP1Form.value.writAppPreferred === "true")) {
      this.forumAppeal = this.forumAppeal.filter((c) => (c.key === 'hc' || c.key === 'sc'));

      return;
    }

    if ((this.appAuthP1Form.value.whetherAppealPreferred === false || this.appAuthP1Form.value.whetherAppealPreferred === "false") &&
      (this.appAuthP1Form.value.whetherDptAppealPreferred === false || this.appAuthP1Form.value.whetherDptAppealPreferred === "false") &&
      (this.appAuthP1Form.value.writAppPreferred === false || this.appAuthP1Form.value.writAppPreferred === "false")) {
      this.forumAppeal = this.forumAppeal.filter((c) => (c.key === 'Appeal/Writ'));

      return;
    }

    this.forumAppeal = FORUM_APPEAL_COMMISONER_ALL_OPTIONS
  }

  /*create phase 1 (submit function)*/
  createP1() {
    console.log(this.appAuthP1Form)
    this.isSubmitted = true;
    let data: any = {};
    if (this.appAuthP1Form.valid) {

      data.cumulative = this.cumulativeCount;
      this.calculateRemandBackTotal();
      data.remandedPaymentMade = this.appAuthP1Form.value.remandedPaymentMade;
      let urlData = {
        typeVal: this.tabType[2].key,
        gstin: this.selectedGstin,
        companyId: this.companyId
      }
      Object.assign(
        data,
        { action: 'CREATE' },
        { phase: '1' },
        { keyword: 'keyword1' }
      );
      let copyOrder: any[] = [];
      let preDepoCopyFormArray: any[] = [];
      let preDepoBankChallanArray: any[] = [];
      let protestCopyFormArray: any[] = [];
      let protestBankChallanArray: any[] = [];

      for (let i = 0; i < this.appAuthP1Form.controls.predepositDetails.value.length; i++) {
        preDepoCopyFormArray = []
        const copyFormArray = this.appAuthP1Form.controls.predepositDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            preDepoCopyFormArray.push(element)
          }
        }
        this.appAuthP1Form.controls.predepositDetails.value[i].copyOfFormLoc = preDepoCopyFormArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.predepositDetails.value.length; i++) {
        preDepoBankChallanArray = [];
        const bankChallanArray = this.appAuthP1Form.controls.predepositDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            preDepoBankChallanArray.push(element)
          }
        }
        this.appAuthP1Form.controls.predepositDetails.value[i].copyOfBankChallanLoc = preDepoBankChallanArray;
      }

      for (let i = 0; i < this.appAuthP1Form.controls.protestDetails.value.length; i++) {
        protestCopyFormArray = []
        const copyFormArray = this.appAuthP1Form.controls.protestDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestCopyFormArray.push(element)
          }
        }
        this.appAuthP1Form.controls.protestDetails.value[i].copyOfFormLoc = protestCopyFormArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.protestDetails.value.length; i++) {
        protestBankChallanArray = [];
        const bankChallanArray = this.appAuthP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestBankChallanArray.push(element)
          }
        }
        this.appAuthP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc = protestBankChallanArray;
      }
      this.appAuthP1Form.value.copyOfOioLoc = copyOrder;

      data = this.appAuthP1Form.value;
      data.issuingAuth1 = {
        officer: this.appAuthP1Form.value.officer,
        designation: this.appAuthP1Form.value.designation,
        jurisdiction: this.appAuthP1Form.value.jurisdiction,
        address: this.appAuthP1Form.value.address,
        contact: this.appAuthP1Form.value.contact,
        email: this.appAuthP1Form.value.email
      }
      data.issuingAuth3 = {
        officer: this.appAuthP1Form.value.officer3,
        designation: this.appAuthP1Form.value.designation3,
        jurisdiction: this.appAuthP1Form.value.jurisdiction3,
        address: this.appAuthP1Form.value.address3,
        contact: this.appAuthP1Form.value.contact3,
        email: this.appAuthP1Form.value.email3
      }
      this.inrFormattingRemoving();

      console.log("Data", data)
      // this.letterP1Form.value.dateOfLetter = this.datePipe.transform(this.letterP1Form.value.dateOfLetter, 'yyyy-MM-dd');
      this.litService.saveCommissionerData(data).subscribe((response: any) => {
        this.isSubmitted = false;

        if (response.status === 1) {
          this.formatTableValues();
          this.toaster.showSuccess(response.message);
          this.formatTableValues();
          this.isDataSaved = true;
          this.isPhase2 = true;
          this.savedCaseId = response.response;
          this.saveCaseId.emit(this.savedCaseId);
          this.isCreateBtn = false;
          this.isUpdateBtn = true;
          this.initializeData();
        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }

  /*get prefilled data (View)*/
  initializeData() {
    if (this.currentTab != this.activeTab) {
      this.enablePrevStageTab.emit(this.activeStages);
    }
    if (this.savedCaseId) {
      this.caseId = this.savedCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        type: this.tabType[2].key,
        // caseId: this.caseId,
        caseId: this.caseId,
      }
      this.litService.getCommissionerCaseData(model).subscribe((response: any) => {
        if (response.status === 1) {
          if (response.response.caseType === CASE_TYPES.MEMORANDUM) {
            // this.isMemorandum = true;
            this.caseType = CASE_TYPES.MEMORANDUM;
          }
          if (this.currentTab === this.activeTab) {
            this.enablePrevStageTab.emit(response.response?.prevStages);
          }
          this.caseData = response.response;
          this.selectedStateName = response.response.state;
          if (this.caseData?.consultantCharges) {
            this.caseData.consultantCharges.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees))
            })
          }
          if (this.caseData?.predepositDetails) {
            this.caseData.predepositDetails.forEach((predeposit: any) => {
              predeposit.total = this.inrService.formatCurrency(String(predeposit.total))
              predeposit.igst = this.inrService.formatCurrency(String(predeposit.igst))
              predeposit.cgst = this.inrService.formatCurrency(String(predeposit.cgst));
              predeposit.sgst = this.inrService.formatCurrency(String(predeposit.sgst));
              predeposit.cess = this.inrService.formatCurrency(String(predeposit.cess));
              predeposit.interest = this.inrService.formatCurrency(String(predeposit.interest))
              predeposit.penalty = this.inrService.formatCurrency(String(predeposit.penalty))
            })
          }
          if (this.caseData?.protestDetails) {
            this.caseData.protestDetails.forEach((protest: any) => {
              protest.total = this.inrService.formatCurrency(String(protest.total))
              protest.igst = this.inrService.formatCurrency(String(protest.igst))
              protest.cgst = this.inrService.formatCurrency(String(protest.cgst));
              protest.sgst = this.inrService.formatCurrency(String(protest.sgst));
              protest.cess = this.inrService.formatCurrency(String(protest.cess));
              protest.interest = this.inrService.formatCurrency(String(protest.interest))
              protest.penalty = this.inrService.formatCurrency(String(protest.penalty))
            })
          }
          if (this.caseData?.issues) {
            this.caseData.issues.forEach((issue: any) => {
              issue.igst1 = this.inrService.formatCurrency(String(issue.igst1))
              issue.cgst1 = this.inrService.formatCurrency(String(issue.cgst1))
              issue.sgst1 = this.inrService.formatCurrency(String(issue.sgst1))
              issue.cess1 = this.inrService.formatCurrency(String(issue.cess1))
              issue.interest1 = this.inrService.formatCurrency(String(issue.interest1))
              issue.penalty1 = this.inrService.formatCurrency(String(issue.penalty1))
              issue.total1 = this.inrService.formatCurrency(String(issue.total1))
              issue.igst3 = this.inrService.formatCurrency(String(issue.igst3))
              issue.cgst3 = this.inrService.formatCurrency(String(issue.cgst3))
              issue.sgst3 = this.inrService.formatCurrency(String(issue.sgst3))
              issue.cess3 = this.inrService.formatCurrency(String(issue.cess3))
              issue.interest3 = this.inrService.formatCurrency(String(issue.interest3))
              issue.penalty3 = this.inrService.formatCurrency(String(issue.penalty3))
              issue.total3 = this.inrService.formatCurrency(String(issue.total3))

              if (issue.details && issue.details.length) {
                issue.details.forEach((details: any) => {
                  details.provisionAmt1 = this.inrService.formatCurrency(String(details.provisionAmt1))
                  details.contLiabilityAmt1 = this.inrService.formatCurrency(String(details.contLiabilityAmt1))
                  details.otherLiability1 = this.inrService.formatCurrency(String(details.otherLiability1))
                  details.caroAmt1 = this.inrService.formatCurrency(String(details.caroAmt1))
                })
              }
            })
          }
          if (this.caseData?.liabilityDetails) {
            this.caseData.liabilityDetails.forEach((liability: any) => {
              liability.total = this.inrService.formatCurrency(String(liability.total))
              liability.igst = this.inrService.formatCurrency(String(liability.igst))
              liability.cgst = this.inrService.formatCurrency(String(liability.cgst))
              liability.sgst = this.inrService.formatCurrency(String(liability.sgst))              
              liability.cess = this.inrService.formatCurrency(String(liability.cess))              
              liability.interest = this.inrService.formatCurrency(String(liability.interest))
              liability.penalty = this.inrService.formatCurrency(String(liability.penalty))
            })
          }
          this.formInitialize();
          this.nextPhase();
          if (this.caseData.itemList && this.caseData.itemList.length > 0) {
            this.initializeItemList();
          } else {
            this.initializeItemList();
            var list: UntypedFormGroup = this.newphArray();
            this.apaDivArr().push(list);
          }
          if (this.caseData.issues && this.caseData.issues.length > 0) {
            this.initializeIssueList(this.caseData);
          } else {
            this.addIssueInv();
          }
          if (this.caseData.predepositDetails && this.caseData.predepositDetails.length > 0) {
            this.initializePreDepositeList();
          } else {
            this.addDetailsOfPreDeposite();
          }
          if (this.caseData.protestDetails && this.caseData.protestDetails.length > 0) {
            this.initializePaymentDetailList();
          } else {
            this.addDetailOfPayment();
          }
          if (this.caseData.appealDetails && this.caseData.appealDetails.length > 0) {
            this.initializeDetailsOfAppealList();
          } else {
            this.addDetailOfAppeal();
          }
          if (this.caseData.consultantCharges && this.caseData.consultantCharges.length > 0) {
            this.initializeConsultantData();
          } else {
            this.addConsultantInv();
          }
          if (this.caseData.appealBookLoc && this.caseData.appealBookLoc.length > 0) {
            this.initializeAppealBookLoc();
          } else {
            this.addAppealBookLoc();
          }

          if (this.caseData.otherAttachmentsLoc1 && this.caseData.otherAttachmentsLoc1.length > 0) {
            this.initializeOtherAttachmentLoc1();
          } else {
            this.addOtherAttachmentLoc1();
          }
          if (this.caseData.liabilityDetails && this.caseData.liabilityDetails.length > 0) {
            this.initializeliabilityDetailsAndPaid();
            this.liabilityTotalCalculation();
          } else {
            this.addLiabilityInv(null);
          }
          if (this.caseData.copyOfOioLoc && this.caseData.copyOfOioLoc.length > 0) {
            this.initializecopyOfOioLoc();
          } else {
            this.addCopyOrder();
          }
          if (this.caseData.status === "MOVED") {
            this.callBookDisabled = true;
          }
          if (this.caseData.writAppPreferred == true) {
            this.disabledWeatherAppl = true;
            this.disabledWeatherDept = true;
          } else {
            this.disabledWeatherAppl = false;
            this.disabledWeatherDept = false;
          }

          if (this.caseData.outcome === 'Favorable') {
            this.issueConclusion = ISSUE_CONCLUSION_FAVOURABLE_ONLY
          }

          if (this.caseData.remandedCaseId) {
            if (this.selectedRemandedBackCaseDetail) {
              this.selectedRemandedBackCaseDetail.cumulative = this.caseData.cumulative;
            } else {
              this.selectedRemandedBackCaseDetail = { cumulative: this.caseData.cumulative };
            }
            this.initializeRemandedBackPaymentData(this.caseData.remandedPaymentMade ? this.caseData.remandedPaymentMade : this.caseData.cumulative);

          } else {
            this.isRemandedBack = false;
          }
          this.cumulativeCount = this.caseData.cumulative ? this.caseData.cumulative : this.cumulativeCount
          this.prevStage = this.caseData.prevStage;

          this.isUpdate = true;
          this.isadjorNot = true;
          this.changeIssueConclusionValue();
          this.paymentUnderProtestp1Changed();
          this.issueValueChange();
          this.liabilityTotalCalculation();
          this.feesChargedChanged();
          this.preDepositeChanged();
          this.checkAppYes();
          this.checkDeptYes();
          this.checkwritAppYes();
          this.setForumAppeal();
          this.changeOutcome();
          this.setCummulativeCount();
        }
      });
    }
  }
  initializeItemList() {
    if (this.caseData.itemList) {
      this.caseData.itemList.forEach((items: any) => {
        let itemListFrmGrp = this.newphArray(items);
        (this.appAuthP1Form.get('itemList') as UntypedFormArray).push(
          itemListFrmGrp
        );

        if (items.phCopyLoc) {
          items.phCopyLoc.forEach((not: any) => {
            let phcopyFormGrp: UntypedFormGroup = this.createphCopyLoc(not);
            (itemListFrmGrp.get('phCopyLoc') as UntypedFormArray).push(phcopyFormGrp);
          });
        }
        if (items.phRecordIfAnyLoc) {
          items.phRecordIfAnyLoc.forEach((not: any) => {
            let phRecordAnyGrp: UntypedFormGroup = this.createPhRecord(not);
            (itemListFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).push(phRecordAnyGrp);
          });
        }
        if (items.phAdjournmentLetterLoc) {
          items.phAdjournmentLetterLoc.forEach((not: any) => {
            let phAdjournmentLetterLocGrp: UntypedFormGroup = this.createPhAdjournmentLetterLoc(not);
            (itemListFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).push(phAdjournmentLetterLocGrp);
          });
        }

        if (items.additionalSubmissionLoc) {
          items.additionalSubmissionLoc.forEach((not: any) => {
            let additionalSubmissionFrmGrp: UntypedFormGroup = this.createAdditionalSubLoc(not);
            (itemListFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).push(additionalSubmissionFrmGrp);
          });
        }

      });
      // this.initializePreDepositeList();
      // this.initializePaymentDetailList();
      this.appAuthP1Form.patchValue(this.caseData);
    }
    this.initializeOiaCopy();
    // this.initializecopyOfOioLoc();
  }
  initializeAppealBookLoc() {
    if (this.caseData.appealBookLoc) {
      this.caseData.appealBookLoc.forEach((not: any) => {

        let appealBookLocGrp = this.createAppealBookLoc(not);

        (this.appAuthP1Form.get('appealBookLoc') as UntypedFormArray).push(appealBookLocGrp);

      })
      this.appAuthP1Form.patchValue(this.caseData);
    }
  }
  initializeOtherAttachmentLoc1() {
    if (this.caseData.otherAttachmentsLoc1) {
      this.caseData.otherAttachmentsLoc1.forEach((not: any) => {

        let otherAttachLoc1Grp = this.createOtherAttachmentLoc1(not);

        (this.appAuthP1Form.get('otherAttachmentsLoc1') as UntypedFormArray).push(otherAttachLoc1Grp);

      })
    }

    this.appAuthP1Form.patchValue(this.caseData);
  }
  initializecopyOfOioLoc() {
    if (this.caseData.copyOfOioLoc) {
      this.caseData.copyOfOioLoc.forEach((not: any) => {
        let otherAttachCopyFrmGrp = this.createCopyOrderForm(not);
        (this.appAuthP1Form.get('copyOfOioLoc') as UntypedFormArray).push(otherAttachCopyFrmGrp);
      })
      this.appAuthP1Form.patchValue(this.caseData);
    }
  }
  initializeIssueList(data: any, isRemandedBackData: any = false) {
    if (isRemandedBackData) {
      data['issues'] = data.gstIssues
    }
    if (data.issues) {
      data.issues.forEach((issue: any) => {
        let issuesFrmGrp: UntypedFormGroup = this.newIssueInvArray();
        (this.appAuthP1Form.get('issues') as UntypedFormArray).push(issuesFrmGrp);
        issue.details.forEach((detail: any) => {
          let detailFrmGrp: UntypedFormGroup = this.newDetailsFrmGrp();
          (issuesFrmGrp.get('details') as UntypedFormArray).push(detailFrmGrp);
        });
      });
      this.appAuthP1Form.patchValue(data);
      this.issueValueChange();
    }
  }
  initializeOiaCopy() {
    if (this.caseData.oiaLoc) {
      this.caseData.oiaLoc.forEach((not: any) => {
        let oiaLocFrmGrp = this.createOiaLoc(not);
        (this.appAuthP1Form.get('oiaLoc') as UntypedFormArray).push(oiaLocFrmGrp);
      })
    }
    this.appAuthP1Form.patchValue(this.caseData);
  }
  deleteAppealBookLoc1() {
    if (this.appAuthP1Form.get('appealBookLoc')?.value) {
      this.appAuthP1Form.get('appealBookLoc')?.setValue(null);
      this.caseData['appealBookLoc'] = null;
      this.isappealBookLocFile = false;
      this.isappealBookLocText = true;
      this.isDWappealBookLoc = true;
      this.isUPappealBookLoc = true;
    }
  }

  // initializeIssue3List() {
  //   if (this.caseData.issues3) {
  //     this.caseData.issues3.forEach((t: any) => {
  //       var listIssue3: FormGroup = this.newIssueInvArray();
  //       this.issu3InvArr().push(listIssue3);
  //       // (list.get("itemList") as FormArray).push(list)
  //     });
  //     this.appAuthP1Form.patchValue(this.caseData);
  //   }
  // }
  // initializeProvList() {
  //   if (this.caseData.provisionDetails) {
  //     this.caseData.provisionDetails.forEach((t: any) => {
  //       var provlist: FormGroup = this.newProvArr();
  //       this.provArr().push(provlist);
  //     });
  //     this.appAuthP1Form.patchValue(this.caseData);
  //   }
  // }
  /* check form validation */
  chkFrmValidated() {
    if (this.appAuthP1Form.valid) {
      this.isPhase2 = true;
      this.isPhase3 = true;
    }
  }


  calculateRemandBackTotal() {
    (this.appAuthP1Form.get('remandedPaymentMade') as UntypedFormArray).controls.forEach((c: any) => {
      if (c.value.label !== 'Cumulative Consultant Charges') {
        c.patchValue({ total: (+c.value.tax) + (+c.value.interest) + (+c.value.penalty) });
      } else {
        c.patchValue({ total: +(c.value.total) })
      }
    })
  }

  UpdateModelData() {
    if (this.caseData != null) {
      this.appAuthP1Form.value.copyOfAckLoc = this.caseData['copyOfAckLoc'];
      this.appAuthP1Form.value.appealBookLoc = this.caseData['appealBookLoc'];
      if (this.caseData.otherAttachmentsLoc2 != null) {
        this.appAuthP1Form.value.otherAttachmentsLoc2 = this.caseData['otherAttachmentsLoc2'];
      } else {
        this.appAuthP1Form.value.otherAttachmentsLoc2 = '';
      }
      this.appAuthP1Form.value.drc03CopyLoc = this.caseData['drc03CopyLoc'];
      this.appAuthP1Form.value.bankChallanLoc = this.caseData['bankChallanLoc'];
      if (this.caseData.oiaLoc != null) {
        this.appAuthP1Form.value.oiaLoc = this.caseData['oiaLoc'];
      } else {
        this.appAuthP1Form.value.oiaLoc = '';
      }
      if (this.caseData.paymentChallanLoc != null) {
        this.appAuthP1Form.value.paymentChallanLoc = this.caseData['paymentChallanLoc'];
      } else {
        this.appAuthP1Form.value.paymentChallanLoc = '';
      }
    }
  }

  doValidation(phaseTab: any) {
    this.UpdateModelData();
    if (phaseTab === 'p2') {
      this.consultantValidation();
      return this.p2validation();
    } else if (phaseTab === 'p3') {
      this.liabilityValidation();
      return this.p3validation();
    } else {
      return true;
    }
  }

  // p1validation() {
  //   if ((this.appAuthP1Form.value['appealBookLoc'] == null || this.appAuthP1Form.value['appealBookLoc'] === '') && this.caseType === this.caseTypeData.MEMORANDUM  ) {
  //     this.appealBookLocReq = true;
  //   } else {
  //     this.appealBookLocReq = false;
  //   }
  //   return !this.appealBookLocReq;
  // }
  protestValidation() {
    let protestArray = this.appAuthP1Form.controls.protestDetails as UntypedFormArray
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.challanNo.value != null && issuesControl.controls.challanNo.value) {
          if (issuesControl.controls.copyOfFormLoc.value === null || issuesControl.controls.copyOfFormLoc.value === '') {
            issuesControl.controls.copyOfFormLoc.setErrors({ 'required': true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }

  predepositValidation() {
    let protestArray = this.appAuthP1Form.controls.predepositDetails as UntypedFormArray
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.challanNo.value != null && issuesControl.controls.challanNo.value) {
          if (issuesControl.controls.copyOfFormLoc.value === null || issuesControl.controls.copyOfFormLoc.value === '') {
            issuesControl.controls.copyOfFormLoc.setErrors({ 'required': true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }

  liabilityValidation() {
    let protestArray = this.appAuthP1Form.controls.liabilityDetails as UntypedFormArray
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.challanNo.value != null && issuesControl.controls.challanNo.value) {
          if (issuesControl.controls.copyOfFormLoc.value === null || issuesControl.controls.copyOfFormLoc.value === '') {
            issuesControl.controls.copyOfFormLoc.setErrors({ 'required': true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }
  inrFormattingRemoving() {
    const data = this.appAuthP1Form.value;
    //phase 2 form
    if (data.consultantCharges && data.consultantCharges.length) {
      data.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
    }
    //phase 1 form
    if (data.protestDetails && data.protestDetails.length) {
      data.protestDetails.forEach((protestDetail: any) => {
        protestDetail.igst = this.inrService.removeCommasAndParseNumber(protestDetail.igst)
        protestDetail.cgst = this.inrService.removeCommasAndParseNumber(protestDetail.cgst)
        protestDetail.sgst = this.inrService.removeCommasAndParseNumber(protestDetail.sgst)
        protestDetail.cess = this.inrService.removeCommasAndParseNumber(protestDetail.cess)
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
        protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
      })
    }
    if (data.predepositDetails && data.predepositDetails.length) {
      data.predepositDetails.forEach((predeposit: any) => {
        predeposit.igst = this.inrService.removeCommasAndParseNumber(predeposit.igst)
        predeposit.cgst = this.inrService.removeCommasAndParseNumber(predeposit.cgst)
        predeposit.sgst = this.inrService.removeCommasAndParseNumber(predeposit.sgst)
        predeposit.cess = this.inrService.removeCommasAndParseNumber(predeposit.cess)
        predeposit.interest = this.inrService.removeCommasAndParseNumber(predeposit.interest)
        predeposit.penalty = this.inrService.removeCommasAndParseNumber(predeposit.penalty)
        predeposit.total = this.inrService.removeCommasAndParseNumber(predeposit.total)
      })
    }
    //phase 3 form
    if (data.liabilityDetails && data.liabilityDetails.length) {
      data.liabilityDetails.forEach((liability: any) => {
        liability.igst = this.inrService.removeCommasAndParseNumber(liability.igst)
        liability.cgst = this.inrService.removeCommasAndParseNumber(liability.cgst)
        liability.sgst = this.inrService.removeCommasAndParseNumber(liability.sgst)
        liability.cess = this.inrService.removeCommasAndParseNumber(liability.cess)
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
      })
    }
    // //phase 1 and 3 form
    if (data.issues && data.issues.length) {
      data.issues.forEach((issue: any) => {
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
        issue.cgst1 = this.inrService.removeCommasAndParseNumber(issue.cgst1)
        issue.sgst1 = this.inrService.removeCommasAndParseNumber(issue.sgst1)
        issue.cess1 = this.inrService.removeCommasAndParseNumber(issue.cess1)
        issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
        issue.cgst3 = this.inrService.removeCommasAndParseNumber(issue.cgst3)
        issue.sgst3 = this.inrService.removeCommasAndParseNumber(issue.sgst3)
        issue.cess3 = this.inrService.removeCommasAndParseNumber(issue.cess3)
        issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)
        if (issue.details && issue.details.length) {
          issue.details.forEach((details: any) => {
            details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(details.provisionAmt1)
            details.contLiabilityAmt1 = this.inrService.removeCommasAndParseNumber(details.contLiabilityAmt1)
            details.otherLiability1 = this.inrService.removeCommasAndParseNumber(details.otherLiability1)
            details.caroAmt1 = this.inrService.removeCommasAndParseNumber(details.caroAmt1)
          })
        }
      })
    }
    // //phase 1 and 3 form
    data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)
    data.cgst1Total = this.inrService.removeCommasAndParseNumber(data.cgst1Total)
    data.sgst1Total = this.inrService.removeCommasAndParseNumber(data.sgst1Total)
    data.cess1Total = this.inrService.removeCommasAndParseNumber(data.cess1Total)
    data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total)
    data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total)
    data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1)
    data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total)
    data.cgst3Total = this.inrService.removeCommasAndParseNumber(data.cgst3Total)
    data.sgst3Total = this.inrService.removeCommasAndParseNumber(data.sgst3Total)
    data.cess3Total = this.inrService.removeCommasAndParseNumber(data.cess3Total)
    data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total)
    data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total)
    data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3)

  }
  updateIssues() {
    (this.appAuthP1Form.get('issues') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ igst3: c.value.igst1 })
      c.patchValue({ cgst3: c.value.cgst1 })
      c.patchValue({ sgst3: c.value.sgst1 })
      c.patchValue({ cess3: c.value.cess1 })
      c.patchValue({ interest3: c.value.interest1 })
      c.patchValue({ penalty3: c.value.penalty1 })
      c.patchValue({ total3: c.value.total1 })
    })
  }
  /*Update function*/
  updateLetter(phaseTab: any) {
    console.log(this.appAuthP1Form, 'appAuthP1Form');
    this.setCaseItemList();
    this.isSubmitted = true;
    if (phaseTab === 'p1') {
      this.updateIssues();
      this.protestValidation();
      this.predepositValidation();
      if (this.caseType === this.caseTypeData.MEMORANDUM) {
        let dataAppealBookLoc = (this.appAuthP1Form.get('appealBookLoc') as UntypedFormArray).controls;
        var count = dataAppealBookLoc?.some((x) => x.get('fileLoc')?.value != null);
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
      }
    }

    let data: any = {};
    for (const property in this.appAuthP1Form.controls) {
      if (this.appAuthP1Form.controls[property].status === "INVALID")
        console.log(`${property}: ${this.appAuthP1Form.controls[property].status}`)
    }
    let isTabValid = this.doValidation(phaseTab);
    if (this.appAuthP1Form.valid && isTabValid) {
      if (phaseTab === 'p2') {
        // this.p1validation();
        let submissionsFrmArray = (this.appAuthP1Form.get('appealDetails') as UntypedFormArray).controls;
        let element1;
        for (let i = 0; i < submissionsFrmArray.length; i++) {
          element1 = (submissionsFrmArray[i].get('ackCopyLoc') as UntypedFormArray).controls;
        }
        let count = element1?.some((x: any) =>
          x.get('fileLoc')?.value != null);
        if (!count) {
          this.toaster.showError("Required Fields Are Empty");
          return;
        }
      }
      if (phaseTab === 'p3') {
        this.p2validation();
        let dataOioLoc = (this.appAuthP1Form.get('oiaLoc') as UntypedFormArray).controls;
        var count = dataOioLoc?.some((x) => x.get('fileLoc')?.value != '');
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
      }

      data.state = this.selectedStateName;
      data.caseType = this.caseType;
      let urlData = {
        typeVal: this.tabType[2].key,
        gstin: this.selectedGstin,
      }
      data.cumulative = this.cumulativeCount;
      data.prevStage = this.caseData.prevStage;
      this.calculateRemandBackTotal();
      data.remandedPaymentMade = this.appAuthP1Form.value.remandedPaymentMade;
      let copyOrder: any[] = [];
      let bookLocArray: any[] = [];
      let preDepoCopyFormArray: any[] = [];
      let preDepoBankChallanArray: any[] = [];
      let protestCopyFormArray: any[] = [];
      let protestBankChallanArray: any[] = [];
      let phCopyLocArray: any[] = [];
      let phAdjournmentLetterLocArray: any[] = [];
      let additionalSubmissionLocArray: any[] = [];
      let phRecordIfAnyLocArray: any[] = [];
      let consultOtherAttachmentLocArray: any[] = [];
      let appealAckCopyArray: any[] = [];
      let appealOtherAttachArray: any[] = [];
      let liabilityCopyFormArray: any[] = [];
      let liabilityBankChallanArray: any[] = [];
      let oiaLocArray: any[] = [];

      for (let i = 0; i < this.appAuthP1Form.controls.appealBookLoc.value.length; i++) {
        const element = this.appAuthP1Form.controls.appealBookLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          bookLocArray.push(element)
        }
        this.appAuthP1Form.value.appealBookLoc = bookLocArray;

      }

      for (let i = 0; i < this.appAuthP1Form.controls.predepositDetails.value.length; i++) {
        preDepoCopyFormArray = []
        const copyFormArray = this.appAuthP1Form.controls.predepositDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            preDepoCopyFormArray.push(element)
          }
        }
        this.appAuthP1Form.controls.predepositDetails.value[i].copyOfFormLoc = preDepoCopyFormArray;
      }

      for (let i = 0; i < this.appAuthP1Form.controls.predepositDetails.value.length; i++) {
        preDepoBankChallanArray = [];
        const bankChallanArray = this.appAuthP1Form.controls.predepositDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            preDepoBankChallanArray.push(element)
          }
        }
        this.appAuthP1Form.controls.predepositDetails.value[i].copyOfBankChallanLoc = preDepoBankChallanArray;
      }

      for (let i = 0; i < this.appAuthP1Form.controls.protestDetails.value.length; i++) {
        protestCopyFormArray = []
        const copyFormArray = this.appAuthP1Form.controls.protestDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestCopyFormArray.push(element)
          }
        }
        this.appAuthP1Form.controls.protestDetails.value[i].copyOfFormLoc = protestCopyFormArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.protestDetails.value.length; i++) {
        protestBankChallanArray = [];
        const bankChallanArray = this.appAuthP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestBankChallanArray.push(element)
          }
        }
        this.appAuthP1Form.controls.protestDetails.value[i].copyOfBankChallanLoc = protestBankChallanArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.itemList.value.length; i++) {
        phCopyLocArray = [];
        const elementPhCopyArray = this.appAuthP1Form.controls.itemList.value[i].phCopyLoc;

        for (let j = 0; j < elementPhCopyArray.length; j++) {
          const element = elementPhCopyArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phCopyLocArray.push(element)
          }
        }
        this.appAuthP1Form.controls.itemList.value[i].phCopyLoc = phCopyLocArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.itemList.value.length; i++) {
        additionalSubmissionLocArray = [];
        const elementAddSubArray = this.appAuthP1Form.controls.itemList.value[i].additionalSubmissionLoc;

        for (let j = 0; j < elementAddSubArray.length; j++) {
          const element = elementAddSubArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            additionalSubmissionLocArray.push(element)
          }
        }
        this.appAuthP1Form.controls.itemList.value[i].additionalSubmissionLoc = additionalSubmissionLocArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.itemList.value.length; i++) {
        phAdjournmentLetterLocArray = [];
        const phAdjournmentArray = this.appAuthP1Form.controls.itemList.value[i].phAdjournmentLetterLoc;

        for (let j = 0; j < phAdjournmentArray.length; j++) {
          const element = phAdjournmentArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phAdjournmentLetterLocArray.push(element)
          }
        }
        this.appAuthP1Form.controls.itemList.value[i].phAdjournmentLetterLoc = phAdjournmentLetterLocArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.itemList.value.length; i++) {
        phRecordIfAnyLocArray = [];
        const phRecordArray = this.appAuthP1Form.controls.itemList.value[i].phRecordIfAnyLoc;

        for (let j = 0; j < phRecordArray.length; j++) {
          const element = phRecordArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phRecordIfAnyLocArray.push(element)
          }
        }
        this.appAuthP1Form.controls.itemList.value[i].phRecordIfAnyLoc = phRecordIfAnyLocArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.consultantCharges.value.length; i++) {
        consultOtherAttachmentLocArray = [];
        const otherAttachesConsultArray = this.appAuthP1Form.controls.consultantCharges.value[i].otherAttachmentLoc;

        for (let j = 0; j < otherAttachesConsultArray.length; j++) {
          const element = otherAttachesConsultArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            consultOtherAttachmentLocArray.push(element)
          }
        }
        this.appAuthP1Form.controls.consultantCharges.value[i].otherAttachmentLoc = consultOtherAttachmentLocArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.appealDetails.value.length; i++) {
        appealAckCopyArray = [];
        const appealAckCopyArrayData = this.appAuthP1Form.controls.appealDetails.value[i].ackCopyLoc;

        for (let j = 0; j < appealAckCopyArrayData.length; j++) {
          const element = appealAckCopyArrayData[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            appealAckCopyArray.push(element)
          }
        }
        this.appAuthP1Form.controls.appealDetails.value[i].ackCopyLoc = appealAckCopyArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.appealDetails.value.length; i++) {
        appealOtherAttachArray = [];
        const appealOtherAttachArrayData = this.appAuthP1Form.controls.appealDetails.value[i].otherAttachmentsLoc;

        for (let j = 0; j < appealOtherAttachArrayData.length; j++) {
          const element = appealOtherAttachArrayData[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            appealOtherAttachArray.push(element)
          }
        }
        this.appAuthP1Form.controls.appealDetails.value[i].otherAttachmentsLoc = appealOtherAttachArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.liabilityDetails.value.length; i++) {
        liabilityCopyFormArray = [];
        const liabilitycopyFormArray = this.appAuthP1Form.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < liabilitycopyFormArray.length; j++) {
          const element = liabilitycopyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityCopyFormArray.push(element)
          }
        }
        this.appAuthP1Form.controls.liabilityDetails.value[i].copyOfFormLoc = liabilityCopyFormArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.liabilityDetails.value.length; i++) {
        liabilityBankChallanArray = [];
        const bankChallanArray = this.appAuthP1Form.controls.liabilityDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityBankChallanArray.push(element)
          }
        }
        this.appAuthP1Form.controls.liabilityDetails.value[i].copyOfBankChallanLoc = liabilityBankChallanArray;
      }
      for (let i = 0; i < this.appAuthP1Form.controls.oiaLoc.value.length; i++) {
        const element = this.appAuthP1Form.controls.oiaLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          oiaLocArray.push(element)
        }

      }
      this.appAuthP1Form.value.oiaLoc = oiaLocArray;

      Object.assign(
        data,
        { action: 'UPDATE' },
        { phase: '1' },
        { keyword: 'keyword1' }
      );
      this.inrFormattingRemoving();

      data = this.appAuthP1Form.value;
      data.issuingAuth1 = {
        officer: this.appAuthP1Form.value.officer,
        designation: this.appAuthP1Form.value.designation,
        jurisdiction: this.appAuthP1Form.value.jurisdiction,
        address: this.appAuthP1Form.value.address,
        contact: this.appAuthP1Form.value.contact,
        email: this.appAuthP1Form.value.email
      }
      data.issuingAuth3 = {
        officer: this.appAuthP1Form.value.officer3,
        designation: this.appAuthP1Form.value.designation3,
        jurisdiction: this.appAuthP1Form.value.jurisdiction3,
        address: this.appAuthP1Form.value.address3,
        contact: this.appAuthP1Form.value.contact3,
        email: this.appAuthP1Form.value.email3
      }
      console.log(this.appAuthP1Form.value.remandedPaymentMade)
      this.litService.updateCommissionerData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          if (phaseTab === 'p1') {
            this.isPhase2 = true;
          } else if (phaseTab === 'p2') {
            this.isPhase3 = true;
          } else if (phaseTab === 'p3') {
            if (this.appAuthP1Form.value.forumOfAppeal === 'hc' && (this.appAuthP1Form.value.writAppPreferred == true || this.appAuthP1Form.value.writAppPreferred === 'true')) {
              this.moveToHC.emit('');
            } else if (this.appAuthP1Form.value.forumOfAppeal === 'sc' && this.appAuthP1Form.value.writAppPreferred) {
              this.moveToSC.emit('');
            }
            else if (this.appAuthP1Form.value.forumOfAppeal === 'hc' && (this.appAuthP1Form.value.whetherAppealPreferred === true || this.appAuthP1Form.value.whetherAppealPreferred === 'true')) {
              this.moveToHC.emit('');
            }
            else if (this.appAuthP1Form.value.whetherAppealPreferred === true || this.appAuthP1Form.value.whetherAppealPreferred === 'true') {
              this.litService.setAccessTabData({ scnFlag: true, ajaFlag: true, apaFlag: true, aptFlag: false, hcFlag: false, scFlag: false });
              this.callTabFunction.emit('');
            } else if (this.appAuthP1Form.value.forumOfAppeal === 'hc' && (this.appAuthP1Form.value.whetherDptAppealPreferred === true || this.appAuthP1Form.value.whetherDptAppealPreferred === 'true')) {
              this.moveToHC.emit('');
            }
            else if (this.appAuthP1Form.value.whetherDptAppealPreferred === true || this.appAuthP1Form.value.whetherDptAppealPreferred === 'true') {
              this.litService.setAccessTabData({ scnFlag: true, ajaFlag: true, apaFlag: true, aptFlag: false, hcFlag: false, scFlag: false });
              this.callTabFunction.emit('');
            } else {
              this.router.navigate(['/litigationSummary']);
            }
          }
          this.toaster.showSuccess(response.message);
          this.isDataSaved = true;

        } else {
          this.toaster.showError(response.message);
        }
        //console.log("updateLetter");
        ///console.log(this.appAuthP1Form.value);
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2011;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push({ year: financialYrRange, disabled: false });
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }
  getFileNameDta(fileUrl: any) {
    if (fileUrl && typeof (fileUrl) == 'string')
      return this.litService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.litService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        //this.downloadfileUrl() ;
        window.open(this.fileUrl, '_blank');

      } else {
      }
    })
  }


  deleteOiaLoc() {
    if (this.appAuthP1Form.get('oiaLoc')?.value) {
      this.appAuthP1Form.get('oiaLoc')?.setValue(null);
      this.caseData['oiaLoc'] = null;
      this.isoiaLocFile = false;
      this.isoiaLocText = true;
      this.isDWoiaLoc = true;
      this.isUPoiaLoc = true;
    }
  }

  uploadappealBookLoc() {
    this.isappealBookLocFile = true;
    this.isappealBookLocText = false;
    this.isDWappealBookLoc = false;
    this.isUPappealBookLoc = false;
    this.appAuthP1Form.value.appealBookLoc = null;
  }

  uploadbankChallanLoc() {
    this.isbankChallanLocFile = true;
    this.isbankChallanLocText = false;
    this.isDWbankChallanLoc = false;
    this.isUPbankChallanLoc = false;
    this.appAuthP1Form.value.bankChallanLoc = null;
  }
  uploaddrc03CopyLoc() {
    this.isdrc03CopyLocFile = true;
    this.isdrc03CopyLocText = false;
    this.isDWdrc03CopyLoc = false;
    this.isUPdrc03CopyLoc = false;
    this.appAuthP1Form.value.drc03CopyLoc = null;
  }

  uploadcopyOfAckLoc() {
    this.iscopyOfAckLocFile = true;
    this.iscopyOfAckLocText = false;
    this.isDWcopyOfAckLocc = false;
    this.isUPcopyOfAckLoc = false;
    this.appAuthP1Form.value.copyOfAckLoc = null;
  }

  uploadotherAttachmentsLoc2() {
    this.isotherAttachmentsLoc2File = true;
    this.isotherAttachmentsLoc2Text = false;
    this.isDWotherAttachmentsLoc2 = false;
    this.isUPotherAttachmentsLoc2 = false;
    this.appAuthP1Form.value.otherAttachmentsLoc2 = null;
  }

  uploadphCopyLoc(index: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;
    var itemListArray = this.appAuthP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhCopyLocUploaded'] = true
        }
      }
    }
  }

  uploadadditionalSubmissionLoc(index: any) {
    this.isadditionalSubmissionLocFile = true;
    this.isadditionalSubmissionLocText = false;
    this.isDWadditionalSubmissionLoc = false;
    this.isUPadditionalSubmissionLoc = false;
    var itemListArray = this.appAuthP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isAdditionalSubmissionLocUploaded'] = true
        }
      }
    }
  }

  uploadphRecordIfAnyLoc(index: any) {
    this.isphRecordIfAnyLocFile = true;
    this.isphRecordIfAnyLocText = false;
    this.isDWphRecordIfAnyLoc = false;
    this.isUPphRecordIfAnyLoc = false;
    var itemListArray = this.appAuthP1Form.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhRecordIfAnyLocUploaded'] = true
        }
      }
    }
  }

  uploadoiaLoc() {
    this.isoiaLocFile = true;
    this.isoiaLocText = false;
    this.isDWoiaLoc = false;
    this.isUPoiaLoc = false;
    this.appAuthP1Form.value.oiaLoc = null;
  }

  uploadpaymentChallanLoc() {
    this.ispaymentChallanLocFile = true;
    this.ispaymentChallanLocText = false;
    this.isDWpaymentChallanLoc = false;
    this.isUPpaymentChallanLoc = false;
    this.appAuthP1Form.value.paymentChallanLoc = null;
  }

  setStateAutoPop() {
    if (this.selectedFilling.gstin) {
      this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
    }
    this.selectedStateName = this.commonServ.getStateName(this.selectedStateCode);
  }
  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  personResponsibleData() {
    let model: any = {};
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    this.litService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });

  }
  issueDropdown() {
    this.dataSource = ISSUE_GST;
  }
  openRmvIssuePop(content: any, i: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }
  openDetailPop(content: any, i: any) {
    this.modalService.open(content);
    this.finYearSelelctEvent(i);
    this.quarterSelectEvent(i);

  }
  openProceedWithPreferredForumAppealPop(content: any) {
    this.proceedWithPreferredForumAppeal = this.modalService.open(content);
  }

  rmProceedWithPreferredForumAppeal(removeSelectedValue: any = false) {
    if (removeSelectedValue) this.appAuthP1Form.get('forumOfAppeal')?.setValue('');
    this.proceedWithPreferredForumAppeal.close();
  }

  getForumAppealValue(value: any) {

    let [findValue] = this.forumAppeal.filter(forum => {
      return forum.key === value
    })
    if (findValue) return findValue.value
    return
  }
  openRmvDetailPop(content: any, i: any, k: any) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }
  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }
  savecaseItemList() {
    const itemList = this.appAuthP1Form.value.itemList;
    if (itemList.length > 0) {
      itemList.forEach((item: any, i: number) => {
        if (!this.caseItemList[i]) {
          this.caseItemList.push({});
        }
        if (item.phCopyLoc) {
          this.caseItemList[i].phCopyLoc = item.phCopyLoc;
        }
        if (item.additionalSubmissionLoc) {
          this.caseItemList[i].additionalSubmissionLoc = item.additionalSubmissionLoc;

        }
        if (item.phRecordIfAnyLoc) {
          this.caseItemList[i].phRecordIfAnyLoc = item.phRecordIfAnyLoc;

        }
        if (item.phAdjournmentLetterLoc) {
          this.caseItemList[i].phAdjournmentLetterLoc = item.phAdjournmentLetterLoc;
        }
      })

      // this.caseItemList.push()
    }
  }

  setCaseItemList() {
    this.appAuthP1Form.value.itemList.forEach((data: any, i: number) => {
      if (!data.phCopyLoc) {
        data.phCopyLoc = this.caseItemList[i]?.phCopyLoc;
      }
      if (!data.additionalSubmissionLoc) {
        data.additionalSubmissionLoc = this.caseItemList[i]?.additionalSubmissionLoc;

      }
      if (!data.phRecordIfAnyLoc) {
        data.phRecordIfAnyLoc = this.caseItemList[i]?.phRecordIfAnyLoc;
      }
      if (!data.phAdjournmentLetterLoc) {
        data.phAdjournmentLetterLoc = this.caseItemList[i]?.phAdjournmentLetterLoc;
      }
    })
  }
  finYearSelelctEvent(i: any) {
    let issuesFrmArray = this.appAuthP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    const alreadySelectedFinYear = detailsFrmArray.value?.map((data: any) => data.finYear);
    this.finyearData.forEach(f => {
      if (alreadySelectedFinYear.includes(f.year)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }



  quarterSelectEvent(i: any) {
    let issuesFrmArray = this.appAuthP1Form.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    const alreadySelectedQuarter = detailsFrmArray.value?.map((data: any) => data.quarter);
    this.quarterList.forEach((f: any) => {
      if (alreadySelectedQuarter.includes(f.value)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }

  issueValueChange() {
    let igst1Total = 0
    let cgst1Total = 0;
    let sgst1Total = 0;
    let cess1Total = 0;
    let interest1Total = 0;
    let penalty1Total = 0;

    let cess3Total = 0;
    let cgst3Total = 0;
    let igst3Total = 0;
    let interest3Total = 0;
    let penalty3Total = 0;
    let sgst3Total = 0;

    (this.appAuthP1Form.get('issues') as UntypedFormArray).controls.forEach((c) => {
      const igst1 = this.inrService.removeCommasAndParseNumber(c.value.igst1);
      const cgst1 = this.inrService.removeCommasAndParseNumber(c.value.cgst1);
      const sgst1 = this.inrService.removeCommasAndParseNumber(c.value.sgst1);
      const cess1 = this.inrService.removeCommasAndParseNumber(c.value.cess1);
      const interest1 = this.inrService.removeCommasAndParseNumber(c.value.interest1);
      const penalty1 = this.inrService.removeCommasAndParseNumber(c.value.penalty1);
      const igst3 = this.inrService.removeCommasAndParseNumber(c.value.igst3);
      const cgst3 = this.inrService.removeCommasAndParseNumber(c.value.cgst3);
      const sgst3 = this.inrService.removeCommasAndParseNumber(c.value.sgst3);
      const cess3 = this.inrService.removeCommasAndParseNumber(c.value.cess3);
      const interest3 = this.inrService.removeCommasAndParseNumber(c.value.interest3);
      const penalty3 = this.inrService.removeCommasAndParseNumber(c.value.penalty3);

      igst1Total += Number(igst1);
      cgst1Total += Number(cgst1);
      sgst1Total += Number(sgst1);
      cess1Total += Number(cess1);
      interest1Total += Number(interest1);
      penalty1Total += Number(penalty1);
      igst3Total += Number(igst3);
      cgst3Total += Number(cgst3);
      sgst3Total += Number(sgst3);
      cess3Total += Number(cess3);
      interest3Total += Number(interest3);
      penalty3Total += Number(penalty3);

      c.patchValue({ total1: this.inrService.formatCurrency(String(Number(igst1) + Number(cgst1) + Number(sgst1) + Number(cess1) + Number(interest1) + Number(penalty1))) });
      c.patchValue({ total3: this.inrService.formatCurrency(String(Number(igst3) + Number(cgst3) + Number(sgst3) + Number(cess3) + Number(interest3) + Number(penalty3))) });

    })

    this.appAuthP1Form.controls['igst1Total'].setValue(this.inrService.formatCurrency(String(igst1Total)));
    this.appAuthP1Form.controls['cgst1Total'].setValue(this.inrService.formatCurrency(String(cgst1Total)));
    this.appAuthP1Form.controls['sgst1Total'].setValue(this.inrService.formatCurrency(String(sgst1Total)));
    this.appAuthP1Form.controls['cess1Total'].setValue(this.inrService.formatCurrency(String(cess1Total)));
    this.appAuthP1Form.controls['interest1Total'].setValue(this.inrService.formatCurrency(String(interest1Total)));
    this.appAuthP1Form.controls['penalty1Total'].setValue(this.inrService.formatCurrency(String(penalty1Total)));
    this.appAuthP1Form.controls['cess3Total'].setValue(this.inrService.formatCurrency(String(cess3Total)));
    this.appAuthP1Form.controls['cgst3Total'].setValue(this.inrService.formatCurrency(String(cgst3Total)));
    this.appAuthP1Form.controls['igst3Total'].setValue(this.inrService.formatCurrency(String(igst3Total)));
    this.appAuthP1Form.controls['sgst3Total'].setValue(this.inrService.formatCurrency(String(sgst3Total)));
    this.appAuthP1Form.controls['penalty3Total'].setValue(this.inrService.formatCurrency(String(penalty3Total)));
    this.appAuthP1Form.controls['interest3Total'].setValue(this.inrService.formatCurrency(String(interest3Total)));

    this.appAuthP1Form.controls['totOfTotal1'].setValue(this.inrService.formatCurrency(String(Number((+igst1Total) + (+cgst1Total) + (+sgst1Total) + (+cess1Total) + (+interest1Total) + (+penalty1Total)))));
    this.appAuthP1Form.controls['totOfTotal3'].setValue(this.inrService.formatCurrency(String(Number((+cess3Total) + (+cgst3Total) + (+igst3Total) + (+sgst3Total) + (+penalty3Total) + (+interest3Total)))));
  }

  issuingAuthChange1() {
    if (this.appAuthP1Form.value.typeOfAuthority1 === 'CGST' && (this.appAuthP1Form.value.din1 === null || this.appAuthP1Form.value.din1 === '')) {
      this.appAuthP1Form.controls['din1']?.setErrors({ 'required': true });

    } else {
      this.appAuthP1Form.controls['din1']?.setErrors(null);

    }

  }

  issuingAuthChange3() {
    if (this.appAuthP1Form.value.typeOfAuthority3 === 'CGST' && (this.appAuthP1Form.value.din3 === null || this.appAuthP1Form.value.din3 === '')) {
      this.appAuthP1Form.controls['din3']?.setErrors({ 'required': true });
    } else {
      this.appAuthP1Form.controls['din3']?.setErrors(null);
    }
  }

  rmDetailsOfPreDeposite(i: number) {
    (this.appAuthP1Form.get('predepositDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfPreDepositeRef.close();
    this.preDepositeChanged();
  }

  detailsOfPreDepositeArr() {
    return (this.appAuthP1Form.get('predepositDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  preDepositeChanged() {
    let igstTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let cessTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let preDepositeTotal = 0;
 
    (this.appAuthP1Form.get('predepositDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
      const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
      const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
      const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);
      const penalty = this.inrService.removeCommasAndParseNumber(c.value.penalty);
      igstTotal += Number(igst);
      cgstTotal += Number(cgst);
      sgstTotal += Number(sgst);
      cessTotal += Number(cess);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(igst)) +(+Number(cgst)) + (+Number(sgst)) +(+Number(cess)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      preDepositeTotal += Number(total);


    })

    this.preDepositeInterestTotal = interestTotal;
    this.preDepositeTaxTotal = igstTotal;
    this.preDepositeCgstTotal= cgstTotal;;
    this.preDepositeSgstTotal= sgstTotal;
    this.preDepositeCessTotal= cessTotal;
    this.preDepositePenaltyTotal = penaltyTotal;
    this.preDepositeTotal = preDepositeTotal;
  }

  addDetailsOfPreDeposite() {
    let depositDetailsFrmGrp = this.detailOfPreDeposite();
    (this.appAuthP1Form.get('predepositDetails') as UntypedFormArray).push(depositDetailsFrmGrp);

  }

  detailOfPreDeposite(data: any = null) {
    return this.fBuild.group({
      formOfPayment: [data ? data?.formOfPayment : ''],
      challanNo: [data ? data?.challanNo : ''],
      modeOfPayment: [data ? data.modeOfPayment : ''],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: [data ? data?.igst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst: [data ? data?.cgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst: [data ? data?.sgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess: [data ? data?.cess : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      // supportingDocLoc: [data ? data?.supportingDocLoc:'']
    })
  }

  initializePreDepositeList() {
    if (this.caseData.predepositDetails) {
      this.caseData.predepositDetails.forEach((depositDetails: any) => {
        let depositDetailsFrmGrp: UntypedFormGroup = this.detailOfPreDeposite(depositDetails);
        (this.appAuthP1Form.get('predepositDetails') as UntypedFormArray).push(depositDetailsFrmGrp);

        if (depositDetails.copyOfFormLoc) {
          depositDetails.copyOfFormLoc.forEach((not: any) => {
            let copyFormGrp: UntypedFormGroup = this.createPreDepoCopyForm(not);
            (depositDetailsFrmGrp.get('copyOfFormLoc') as UntypedFormArray).push(copyFormGrp);
          });
        }

        if (depositDetails.copyOfBankChallanLoc) {
          depositDetails.copyOfBankChallanLoc.forEach((not: any) => {
            let bankChallanFrmGrp: UntypedFormGroup = this.createPreDepoBankChallanForm(not);
            (depositDetailsFrmGrp.get('copyOfBankChallanLoc') as UntypedFormArray).push(bankChallanFrmGrp);
          });
        }
      });
    }
    this.preDepositeChanged();
  }

  initializeConsultantData() {
    if (this.caseData.consultantCharges) {
      this.caseData.consultantCharges.forEach((consultantCharges: any) => {
        let detailOfConsultant: UntypedFormGroup = this.ConsultantCharges(consultantCharges);
        (this.appAuthP1Form.get('consultantCharges') as UntypedFormArray).push(detailOfConsultant);

        if (consultantCharges.otherAttachmentLoc) {
          consultantCharges.otherAttachmentLoc.forEach((not: any) => {
            let consultAttachLocFrmGrp: UntypedFormGroup = this.createConsultAttach(not);
            (detailOfConsultant.get('otherAttachmentLoc') as UntypedFormArray).push(consultAttachLocFrmGrp);
          });
        }

      });
    }
  }

  //  Details of Liability Admitted and Paid
  uploadcopyOfForm(i: any) {
    var predepositDetailsArray = this.appAuthP1Form.controls.predepositDetails as UntypedFormArray
    if (predepositDetailsArray.controls.length > 0) {
      for (var index = 0; index < predepositDetailsArray.controls.length; index++) {
        if (index === i) {
          var predepositDetailsControl = predepositDetailsArray.controls[index] as UntypedFormGroup;
          predepositDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.iscopyOfFormLocFile = true;
    this.iscopyOfFormLocText = false;
    this.iscopyOfUPFormLoc = false;
    this.iscopyOfDWFormLoc = true;
  }

  deletepredepositFile(i: any) {
    let predepositArray = this.appAuthP1Form.controls.predepositDetails as UntypedFormArray
    if (predepositArray.controls.length > 0) {
      for (let index = 0; index < predepositArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = predepositArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['iscopyOfFormUploadedClicked'] = true;
        }
      }
    }
  }

  deletepredeposit2File(i: any) {
    let predepositArray = this.appAuthP1Form.controls.predepositDetails as UntypedFormArray
    if (predepositArray.controls.length > 0) {
      for (let index = 0; index < predepositArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = predepositArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['ispredepositDetailsUploadedClicked'] = true;
        }
      }
    }
  }

  uploadLiabilityDetailsCopyOfForm(i: any) {
    var liabilityDetailsArray = this.appAuthP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityDetailsArray.controls.length > 0) {
      for (var index = 0; index < liabilityDetailsArray.controls.length; index++) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    // this.iscopyOfFormLocFile = true;
    // this.iscopyOfFormLocText = false;
    // this.iscopyOfUPFormLoc = false;
    // this.iscopyOfDWFormLoc  = true;
  }

  uploadcopyOfBankChallan(i: any) {
    var predepositDetailsArray = this.appAuthP1Form.controls.predepositDetails as UntypedFormArray
    if (predepositDetailsArray.controls.length > 0) {
      for (var index = 0; index < predepositDetailsArray.controls.length; index++) {
        if (index === i) {
          var predepositDetailsControl = predepositDetailsArray.controls[index] as UntypedFormGroup;
          predepositDetailsControl.value['isCopyOfBankChallanLocDocUploadedClicked'] = true
        }
      }
    }
    this.iscopyOfBankChallanLocFile = true;
    this.iscopyOfBankChallanLocText = false;
    this.iscopyOfBankDWChallanLoc = true;
    this.iscopyOfBankUPChallanLoc = false;
  }

  uploadcopyOfBankChallan2(i: any) {
    var liabilityDetailsArray = this.appAuthP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityDetailsArray.controls.length > 0) {
      for (var index = 0; index < liabilityDetailsArray.controls.length; index++) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfBankChallanLocDocUploadedClicked'] = true
        }
      }
    }
    this.iscopyOfBankChallanLocFile2 = true;
    this.iscopyOfBankChallanLocText2 = false;
    this.iscopyOfBankDWChallanLoc2 = true;
    this.iscopyOfBankUPChallanLoc2 = false;
  }

  uploadProtestDetailsCopyOfFormLoc(i: any) {
    var protestDetailsArray = this.appAuthP1Form.controls.protestDetails as UntypedFormArray
    if (protestDetailsArray.controls.length > 0) {
      for (var index = 0; index < protestDetailsArray.controls.length; index++) {
        if (index === i) {
          var protestDetailsControl = protestDetailsArray.controls[index] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.isProtestDetailCopyOfFormLocFile = true;
    this.isProtestDetailCopyOfFormLocText = false;
    this.isProtestDetailCopyOfFormLocDWText = true;
    this.isProtestDetailCopyOfFormLocUPText = false;
  }
  deleteUploadFile(i: any) {
    let protestArray = this.appAuthP1Form.controls.protestDetails as UntypedFormArray
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['copyOfFormDocUploadedClicked'] = true;
        }
      }
    }
  }
  deleteProtestFile(i: any) {
    let protestArray = this.appAuthP1Form.controls.protestDetails as UntypedFormArray
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['iscopyOfBankChallanUploadedClicked'] = true;
        }
      }
    }
  }

  uploadProtestDetailsCopyOfBankChallanLoc(i: any) {
    var protestDetailsArray = this.appAuthP1Form.controls.protestDetails as UntypedFormArray
    if (protestDetailsArray.controls.length > 0) {
      for (var index = 0; index < protestDetailsArray.controls.length; index++) {
        if (index === i) {
          var protestDetailsControl = protestDetailsArray.controls[index] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfBankChallanLocDocUploadedClicked'] = true
        }
      }
    }
    this.isProtestDetailCopyOfBankChallanLocFile = true;
    this.isProtestDetailCopyOfBankChallanLocText = false;
    this.isProtestDetailCopyOfBankChallanLocDWText = true;
    this.isProtestDetailCopyOfBankChallanLocUPText = false;
  }
  getDetailOfPaymentArr(): UntypedFormGroup[] {
    return (this.appAuthP1Form.get('protestDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  rmPaymentUnderProtest(i: number) {
    (this.appAuthP1Form.get('protestDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfPaymentRef.close();
    this.paymentUnderProtestp1Changed();
  }

  paymentUnderProtestp1Changed() {
    let igstTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let protestTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let cessTotal = 0;

    (this.appAuthP1Form.get('protestDetails') as UntypedFormArray).controls.forEach((c: any) => {

      const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
      const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
      const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
      const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);
      const penalty = this.inrService.removeCommasAndParseNumber(c.value.penalty);
      igstTotal += Number(igst);
      cgstTotal += Number(cgst);
      sgstTotal += Number(sgst);
      cessTotal += Number(cess);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(igst)) + Number(cgst) + Number(sgst) + Number(cess) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      protestTotal += Number(total);
    })

    this.protestInterestTotal = interestTotal;
    this.protestTaxTotal = igstTotal;
    this.protestcgstTotal = cgstTotal;
    this.protestsgstTotal = sgstTotal;
    this.protestcessTotal = cessTotal;
    this.protestPenaltyTotal = penaltyTotal;
    this.protestTotal = protestTotal;
  }

  initializePaymentDetailList() {
    if (this.caseData.protestDetails) {
      this.caseData.protestDetails.forEach((protest: any) => {
        let protestDetailsFrmGrp = this.detailOfprotestDetails(protest);
        (this.appAuthP1Form.get('protestDetails') as UntypedFormArray).push(protestDetailsFrmGrp);
        this.oldProtestDetailsValues.push(protestDetailsFrmGrp)

        if (protest.copyOfFormLoc) {
          protest.copyOfFormLoc.forEach((not: any) => {
            let copyFormGrp: UntypedFormGroup = this.createProtestCopyForm(not);
            (protestDetailsFrmGrp.get('copyOfFormLoc') as UntypedFormArray).push(copyFormGrp);
          });
        }

        if (protest.copyOfBankChallanLoc) {
          protest.copyOfBankChallanLoc.forEach((not: any) => {
            let bankChallanFrmGrp: UntypedFormGroup = this.createProtestBankChallanForm(not);
            (protestDetailsFrmGrp.get('copyOfBankChallanLoc') as UntypedFormArray).push(bankChallanFrmGrp);
          });
        }

      });
    }


  }

  addDetailOfPayment() {
    let protestDetailsFrmGrp = this.detailOfprotestDetails();
    (this.appAuthP1Form.get('protestDetails') as UntypedFormArray).push(protestDetailsFrmGrp);
  }

  detailOfprotestDetails(data: any = null) {
    return this.fBuild.group({
      formOfPayment: [data ? data?.formOfPayment : ''],
      modeOfPayment: [data ? data?.modeOfPayment : ''],
      challanNo: [data ? data?.challanNo : ''],
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: [data ? data?.igst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cgst: [data ? data?.cgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      sgst: [data ? data?.sgst : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      cess: [data ? data?.cess : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],

    })
  }

  uploadSupporting(i: any) {
    var protestDetailArray = this.appAuthP1Form.controls.protestDetails as UntypedFormArray
    if (protestDetailArray.controls.length > 0) {
      for (var index = 0; index < protestDetailArray.controls.length; index++) {
        if (index === i) {
          var protestDetailControl = protestDetailArray.controls[index] as UntypedFormGroup;
          protestDetailControl.value['isSupportingDocUploadedClicked'] = true
        }
      }
    }
    this.isSupportingFile = true;
    this.isSupportingText = false;
    this.isSupportingDWText = true;
    this.isSupportingUPText = false;
  }


  getDetailOfAppeal() {
    return (this.appAuthP1Form.get('appealDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }


  addDetailOfAppeal() {
    let appealFrmGrp = this.detailOfAppeal();
    (this.appAuthP1Form.get('appealDetails') as UntypedFormArray).push(appealFrmGrp);
  }

  openRemoveDetailsOfAppeal(content: any) {
    this.rmvDetailsOfAppealRef = this.modalService.open(content);
  }

  rmDetailsOfAppeal(i: number) {
    (this.appAuthP1Form.get('appealDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfAppealRef.close();
  }

  detailOfAppeal(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      appealRefNo: [data ? data?.appealRefNo : null],
      formType: [data ? data?.formType : null],
      filingAppealDate: [data ? data?.filingAppealDate : null],
      appealMode: [data ? data?.appealMode : null],
      ackCopyLoc: new UntypedFormArray([]),
      otherAttachmentsLoc: new UntypedFormArray([])
    })
  }

  deleteSubmissions2UploadFile(i: any) {
    let submissionArray = this.appAuthP1Form.controls.appealDetails as UntypedFormArray
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentsLoc.setValue(null);
          issuesControl.value['isOtherAttachmentsLocUploadedClicked'] = true;
        }
      }
    }
  }

  deleteSubmissionsUploadFile(i: any) {
    let submissionArray = this.appAuthP1Form.controls.appealDetails as UntypedFormArray
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.ackCopyLoc.setValue(null);
          issuesControl.value['iscopyOfAckLocUploadedClicked'] = true;
        }
      }
    }
  }


  initializeDetailsOfAppealList() {
    if (this.caseData.appealDetails) {
      this.caseData.appealDetails.forEach((appealDetails: any) => {
        let appealDetailsFrmGrp: UntypedFormGroup = this.detailOfAppeal(appealDetails);
        (this.appAuthP1Form.get('appealDetails') as UntypedFormArray).push(appealDetailsFrmGrp);

        if (appealDetails.ackCopyLoc) {
          appealDetails.ackCopyLoc.forEach((not: any) => {
            let ackCopyGrp: UntypedFormGroup = this.createAppealAckCopy(not);
            (appealDetailsFrmGrp.get('ackCopyLoc') as UntypedFormArray).push(ackCopyGrp);

          });
        }
        if (appealDetails.otherAttachmentsLoc) {
          appealDetails.otherAttachmentsLoc.forEach((not: any) => {
            let otherAttachFrmGrp: UntypedFormGroup = this.createAppealOtherAttach(not);
            (appealDetailsFrmGrp.get('otherAttachmentsLoc') as UntypedFormArray).push(otherAttachFrmGrp);
          });
        }
      });
    }
  }

  uploadCopyOfAckLoc(i: any) {
    var appealDetailsArray = this.appAuthP1Form.controls.appealDetails as UntypedFormArray
    if (appealDetailsArray.controls.length > 0) {
      for (var index = 0; index < appealDetailsArray.controls.length; index++) {
        if (index === i) {
          var appealDetailControl = appealDetailsArray.controls[index] as UntypedFormGroup;
          appealDetailControl.value['isAckCopyLocDocUploadedClicked'] = true
        }
      }
    }
    this.copyOfAckLocDocFile = true;
    this.copyOfAckLocDocText = false;
    this.copyOfAckLocDWText = true;
    this.copyOfAckLocUPText = false;
  }

  uploadotherAttachments(i: any) {
    var appealDetailsArray = this.appAuthP1Form.controls.appealDetails as UntypedFormArray
    if (appealDetailsArray.controls.length > 0) {
      for (var index = 0; index < appealDetailsArray.controls.length; index++) {
        if (index === i) {
          var appealDetailControl = appealDetailsArray.controls[index] as UntypedFormGroup;
          appealDetailControl.value['isOtherAttachmentsLocDocUploadedClicked'] = true
        }
      }
    }
    this.isOtherAttachmentDWText = true;
    this.isOtherAttachmentUPText = false;
    this.isOtherAttachmentText = false;
    this.isOtherAttachmentFile = true;
  }


  consultantInvArr(): UntypedFormGroup[] {
    return (this.appAuthP1Form.get('consultantCharges') as UntypedFormArray).controls as UntypedFormGroup[];
  }


  // Consultant Charges Table

  ConsultantCharges(c: any = null): UntypedFormGroup {
    return this.fBuild.group({
      srNo: [c?.srNo],
      firm: [c?.firm],
      consultant: [c?.consultant],
      natureOfWork: [c?.natureOfWork],
      fees: [c?.fees ? c.fees : 0],
      otherAttachmentLoc: new UntypedFormArray([]),
      email: [c?.email, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [c?.contact]
    })

  }

  addConsultantInv() {
    let detailOfConsultant = this.ConsultantCharges();
    (this.appAuthP1Form.get('consultantCharges') as UntypedFormArray).push(detailOfConsultant);
  }

  rmConsultantInv(i: number) {
    (this.appAuthP1Form.get('consultantCharges') as UntypedFormArray).removeAt(i);
    this.feesChargedChanged();
    this.rmvConsultantModalRef.close();
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (this.appAuthP1Form.get('consultantCharges') as UntypedFormArray).controls.forEach((c: any) => {
      const fees = this.inrService.removeCommasAndParseNumber(c.value.fees);
      feesChargedTotal += Number(fees);
    });

    this.feesChargedTotal = feesChargedTotal;
  }

  openRmvconsultantPop(content: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }

  uploadoConsultantAttachmentsLoc(i: any) {
    var consultantArray = this.appAuthP1Form.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (var index = 0; index < consultantArray.controls.length; index++) {
        if (index === i) {
          var consultantControl = consultantArray.controls[index] as UntypedFormGroup;
          consultantControl.value['isOtherAttachmentLocDocUploadedClicked'] = true
        }
      }
    }
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLoc = true;
    this.isconsultantUPattachmentsLoc = false;
  }
  deleteConsultantFile(i: any) {
    let consultantArray = this.appAuthP1Form.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentLoc.setValue(null);
          issuesControl.value['isOtherAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  consultantValidation() {
    let consultantArray = this.appAuthP1Form.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(issuesControl.controls.fees.value)
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (issuesControl.controls.firm.value === null || issuesControl.controls.firm.value === '') {
            issuesControl.controls.firm.setErrors({ 'required': true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }

  nextPhase() {
    this.disablePhase3 = (this.appAuthP1Form.value.whetherCaseCallBook === "true" || this.appAuthP1Form.value.whetherCaseCallBook === true) ? true : false;
  }
  openRemoveDetailsOfPreDepositePop(content: any) {
    this.rmvDetailsOfPreDepositeRef = this.modalService.open(content);
  }
  openRemoveDetailsOfPaymentPop(content: any) {
    this.rmvDetailsOfPaymentRef = this.modalService.open(content);
  }

  // Liability Table
  detailsOfLiabilityFrmGrp(liability: any = null): UntypedFormGroup {
    return this.fBuild.group({
      formOfPayment: new UntypedFormControl(liability ? liability.formOfPayment : null, []),
      challanNo: new UntypedFormControl(liability ? liability.challanNo : null, []),
      modeOfPayment: new UntypedFormControl(liability ? liability.modeOfPayment : null, []),
      accountingRef:[liability ? liability?.accountingRef:null,[Validators.maxLength(100)]],
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      igst: new UntypedFormControl(liability ? liability.igst : 0, []),
      cgst: new UntypedFormControl(liability ? liability.cgst :0, []),
      sgst: new UntypedFormControl(liability ? liability.sgst :0, []),
      cess: new UntypedFormControl(liability ? liability.cess :0, []),
      interest: new UntypedFormControl(liability ? liability.interest : 0, []),
      penalty: new UntypedFormControl(liability ? liability.penalty : 0, []),
      total: new UntypedFormControl(liability ? liability.total : 0, []),
    })
  }

  initializeliabilityDetailsAndPaid() {
    if (this.caseData.liabilityDetails) {
      this.caseData.liabilityDetails.forEach((liability: any) => {
        let liabilityDetails = this.detailsOfLiabilityFrmGrp(liability);
        (this.appAuthP1Form.get('liabilityDetails') as UntypedFormArray).push(liabilityDetails);

        if (liability.copyOfFormLoc) {
          liability.copyOfFormLoc.forEach((not: any) => {
            let copyFormGrp: UntypedFormGroup = this.createLiabilityCopyForm(not);
            (liabilityDetails.get('copyOfFormLoc') as UntypedFormArray).push(copyFormGrp);
          });
        }
        if (liability.copyOfBankChallanLoc) {
          liability.copyOfBankChallanLoc.forEach((not: any) => {
            let banckChallanGrp: UntypedFormGroup = this.createLiabilityBankChallan(not);
            (liabilityDetails.get('copyOfBankChallanLoc') as UntypedFormArray).push(banckChallanGrp);
          });
        }
      })
    }
  }

  liabilityPanelExpansion() {
    this.liabilityExpansionPanel = !this.liabilityExpansionPanel;

  }
  // Details of Liability Admitted and Paid
  getLiability(): UntypedFormGroup[] {
    return (this.appAuthP1Form.get('liabilityDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  addLiabilityInv(event: any = null) {
    let liabilityFrmGrp = this.detailsOfLiabilityFrmGrp();
    (this.appAuthP1Form.get('liabilityDetails') as UntypedFormArray).push(liabilityFrmGrp);

    return false;

  }
  rmLiabilityInv(i: number) {
    (this.appAuthP1Form.get('liabilityDetails') as UntypedFormArray).removeAt(i);
    this.rmvLiabilityModalRef.close();
    this.liabilityTotalCalculation();
  }

  deleteliabilityFile(i: any) {
    let liabilityArray = this.appAuthP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }
  deleteliabilityDetailsFile(i: any) {
    let liabilityArray = this.appAuthP1Form.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentUploadedClicked'] = true;
        }
      }
    }
  }
  liabilityTotalCalculation() {
    let igstTotal = 0;
    let cgstTotal = 0;
    let sgstTotal = 0;
    let cessTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let liabilityPaidTotal = 0;
    (this.appAuthP1Form.get('liabilityDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const  igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
      const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
      const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
      const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);
      const penalty = this.inrService.removeCommasAndParseNumber(c.value.penalty);
      igstTotal += Number(igst);
      cgstTotal += Number(cgst);
      sgstTotal += Number(sgst);
      cessTotal += Number(cess);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(igst)) + (Number(cgst)) + (Number(sgst)) + (Number(cess)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      liabilityPaidTotal += Number(total);
    });

    this.liabilityTotals.allTotal = liabilityPaidTotal;
    this.liabilityTotals.interestTotal = interestTotal;
    this.liabilityTotals.penaltyTotal = penaltyTotal;
    this.liabilityTotals.igstTotal = igstTotal;
    this.liabilityTotals.cgstTotal = cgstTotal;
    this.liabilityTotals.sgstTotal = sgstTotal;
    this.liabilityTotals.cessTotal = cessTotal;
  }

  getperiodToValue(content: any){
    if(this.action === "create"){
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = localStorage.getItem('selectedId'),
    model.module = "GST",
    model.gstin = this.selectedGstin,
    model.criterias= [
      {
        "p": "periodFromDate",
        "o": "between",
        "v1": this.appAuthP1Form.value.periodFromDate,
        "v2": this.appAuthP1Form.value.periodToDate,
    },{
        "p": "periodToDate",
        "o": "between",
        "v1":  this.appAuthP1Form.value.periodFromDate,
        "v2": this.appAuthP1Form.value.periodToDate,
    }
  ]
  this.litService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.response.length === 0) {
        this.modalService.dismissAll();
      }
      else{
        this.duplicateData = response?.response;
        this.duplicateData?.forEach((obj: any, index: number) => {
          if (obj.forum === 'Appellate Authority')
            this.duplicateData[index].forum = 'Commissioner (A)'
        })
       this.modalService.open(content)
      }
      
    });
  }
  }
  
  goToSummary(){
    this.router.navigate(['/litigationSummary']);
    this.modalService.dismissAll();
  }

  //   setCummulativeCount1(){
  //     let protestPaymentTax = 0;
  //     let protestPaymentPenalty   = 0;
  //     let protestPaymentInterest = 0;
  //     let protestPaymentTotal = 0;
  //     let liabilityPaymentTax = 0
  //     let liabilityPaymentPenalty = 0;
  //     let liabilityPaymentInterest = 0;
  //     let liabilityPaymentTotal = 0;
  //     let consultantFees = 0;
  //    if(this.selectedRemandedBackCaseDetail && this.selectedRemandedBackCaseDetail?.cumulative?.length > 0){
  //    this.selectedRemandedBackCaseDetail.cumulative.forEach((p: any) =>{
  //      if(p.label === 'Cumulative payment made under protest'){
  //        p.tax ? protestPaymentTax += p.tax : null;
  //        p.penalty ? protestPaymentPenalty += p.penalty : null;
  //        p.interest ? protestPaymentInterest += p.interest : null;
  //        p.protestPaymentTotal ? protestPaymentTotal +=p.protestPaymentTotal : null;
  //      }else if(p.label === 'Cumulative payment made for admitted liability'){
  //        p.tax ? liabilityPaymentTax += p.tax : null;
  //        p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //        p.interest ? liabilityPaymentInterest += p.interest : null;
  //        p.total ? liabilityPaymentTotal +=p.total : null;
  //      }else if(p.label === 'Cumulative Consultant Charges'){
  //        p.fees ? consultantFees += p.fees : null;
  //      }
  //      })
  //    }
  //    if(this.caseData.cumulative && this.caseData.cumulative.length > 0){
  //     this.caseData.cumulative.forEach((p: any) =>{
  //       if(p.label === 'Cumulative payment made under protest'){
  //         p.tax ? protestPaymentTax += p.tax : null; //50
  //         p.penalty ? protestPaymentPenalty += p.penalty : null; //50
  //         p.interest ? protestPaymentInterest += p.interest : null; //50
  //         p.total ? protestPaymentTotal +=p.total : null; //150
  //       }else if(p.label === 'Cumulative payment made for admitted liability'){
  //         p.tax ? liabilityPaymentTax += p.tax : null;
  //         p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //         p.interest ? liabilityPaymentInterest += p.interest : null;
  //         p.total ? liabilityPaymentTotal +=p.total : null;
  //       }else if(p.label === 'Cumulative Consultant Charges'){
  //         p.total ? consultantFees += p.total : null;
  //       }
  //       })
  //    }
  //    this.appAuthP1Form.value.protestDetails.forEach((p : any) =>{
  //      p.tax ? protestPaymentTax += p.tax : null;
  //      p.penalty ? protestPaymentPenalty += p.penalty : null;
  //      p.interest ? protestPaymentInterest += p.interest : null;
  //      p.total ? protestPaymentTotal +=p.total : null;
  //    });
  //    this.appAuthP1Form.value.liabilityDetails.forEach((p : any) =>{
  //      p.tax ? liabilityPaymentTax += p.tax : null;
  //      p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //      p.interest ? liabilityPaymentInterest += p.interest : null;
  //      p.total ? liabilityPaymentTotal +=p.total : null;
  //    });
  //    this.appAuthP1Form.value.consultantCharges.forEach((c : any) =>{
  //      c.fees ? consultantFees += c.fees : null;
  //    });
  //    this.cumulativeCount.forEach((c: any) =>{
  //        if(c.label === 'Cumulative payment made under protest'){
  //          protestPaymentTax ?  c.tax = protestPaymentTax  : null;
  //          protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
  //          protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
  //          protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
  //        }else if(c.label === 'Cumulative payment made for admitted liability'){
  //          liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
  //          liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
  //          liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
  //          liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
  //        }else if(c.label === 'Cumulative Consultant Charges'){
  //          consultantFees ? c.total = consultantFees  : null;
  //        }
  //    });
  //  }


  setCummulativeCount(oldArr: any = null, newArr: any = null, tableName: any = null) {
    // let protestPaymentTax = 0;
    // let protestPaymentPenalty   = 0;
    // let protestPaymentInterest = 0;
    // let protestPaymentTotal = 0;

    // let liabilityPaymentTax = 0
    // let liabilityPaymentPenalty = 0;
    // let liabilityPaymentInterest = 0;
    // let liabilityPaymentTotal = 0;

    // let consultantFees = 0;

    // let preDepositeTax = 0;
    // let preDepositePenalty = 0;
    // let preDepositeInterest = 0;
    // let preDepositeTotal = 0;

    // let oldTotalTax :any= 0, oldTotalInterrest :any= 0 , oldTotalPenalty:any = 0;
    // let newTotalTax :any= 0, newTotalInterrest :any= 0 , newTotalPenalty:any = 0;
    // let oldFees :any=0, newFees:any= 0;

    // if(this.selectedRemandedBackCaseDetail && this.selectedRemandedBackCaseDetail?.cumulative?.length > 0){
    //   this.selectedRemandedBackCaseDetail.cumulative.forEach((p: any) =>{
    //     if(p.label === 'Cumulative payment made under protest'){
    //       p.tax ? protestPaymentTax += p.tax : null;
    //       p.penalty ? protestPaymentPenalty += p.penalty : null;
    //       p.interest ? protestPaymentInterest += p.interest : null;
    //       p.protestPaymentTotal ? protestPaymentTotal +=p.protestPaymentTotal : null;
    //     }else if(p.label === 'Cumulative payment made for admitted liability'){
    //       p.tax ? liabilityPaymentTax += p.tax : null;
    //       p.penalty ? liabilityPaymentPenalty += p.penalty : null;
    //       p.interest ? liabilityPaymentInterest += p.interest : null;
    //       p.total ? liabilityPaymentTotal +=p.total : null;
    //     }else if(p.label === 'Cumulative Consultant Charges'){
    //       p.fees ? consultantFees += p.fees : null;
    //     }
    //     })
    // }

    // if(this.caseData.cumulative && this.caseData.cumulative.length > 0){
    //   this.caseData.cumulative.forEach((p: any) =>{
    //     if(p.label === 'Cumulative Pre-Deposit'){
    //       p.tax ? preDepositeTax += p.tax : null;
    //       p.penalty ? preDepositePenalty += p.penalty : null;
    //       p.interest ? preDepositeInterest += p.interest : null;
    //       p.total ? preDepositeTotal +=p.total : null;
    //     }
    //     if(p.label === 'Cumulative payment made under protest'){
    //       p.tax ? protestPaymentTax += p.tax : null;
    //       p.penalty ? protestPaymentPenalty += p.penalty : null;
    //       p.interest ? protestPaymentInterest += p.interest : null;
    //       p.total ? protestPaymentTotal +=p.total : null;
    //     }else if(p.label === 'Cumulative payment made for admitted liability'){
    //       p.tax ? liabilityPaymentTax += p.tax : null;
    //       p.penalty ? liabilityPaymentPenalty += p.penalty : null;
    //       p.interest ? liabilityPaymentInterest += p.interest : null;
    //       p.total ? liabilityPaymentTotal +=p.total : null;
    //     }else if(p.label === 'Cumulative Consultant Charges'){
    //       p.total ? consultantFees += p.total : null;
    //     }
    //     })
    //  }

    // //array is not null and empty
    // if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== 'consultantCharges'){
    //   oldArr.forEach((p : any) =>{
    //     if( p.tax != null)
    //     oldTotalTax +=  p.tax ;
    //     if( p.penalty != null)
    //     oldTotalPenalty += p.penalty;
    //     if( p.interest != null)
    //     oldTotalInterrest += p.interest;

    //   });
    //   newArr.forEach((p : any) =>{
    //     if( p.tax != null)
    //     newTotalTax +=  p.tax ;
    //     if( p.penalty != null)
    //     newTotalPenalty += p.penalty;
    //     if( p.interest != null)
    //     newTotalInterrest += p.interest;
    //   });
    // }else if(tableName === 'consultantCharges'){
    //   oldArr.forEach((p : any) =>{
    //    if(p.fees != null){
    //      oldFees += p.fees;
    //    }
    //   });

    //   newArr.forEach((p : any) =>{
    //     if(p.fees != null){
    //       newFees += p.fees;
    //     }
    //   })
    // }

    // if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== null){
    //   this.cumulativeCount.forEach((c: any) =>{
    //     if(c.label === 'Cumulative Pre-Deposit' && tableName === 'predepositDetails'){
    //       c.tax = preDepositeTax - oldTotalTax + newTotalTax ;
    //       c.penalty = preDepositePenalty - oldTotalPenalty + newTotalPenalty ;
    //       c.interest = preDepositeInterest - oldTotalInterrest + newTotalInterrest ;
    //       c.total = c.tax + c.penalty + c.interest  ;
    //     }
    //   if(c.label === 'Cumulative payment made under protest' &&  tableName === 'protestDetails'){
    //     c.tax = protestPaymentTax - oldTotalTax + newTotalTax  ;
    //     c.penalty = protestPaymentPenalty - oldTotalPenalty + newTotalPenalty  ;
    //     c.interest = protestPaymentInterest - oldTotalInterrest + newTotalInterrest  ;
    //     c.total = c.tax + c.penalty + c.interest  ;
    //   }
    //   else if(c.label === 'Cumulative payment made for admitted liability' && tableName === 'liabilityDetails'){
    //     c.tax = liabilityPaymentTax - oldTotalTax + newTotalTax  ;
    //     c.penalty = liabilityPaymentPenalty - oldTotalPenalty + newTotalPenalty ;
    //     c.interest = liabilityPaymentInterest - oldTotalInterrest + newTotalInterrest ;
    //     c.total = c.tax + c.penalty + c.interest  ;
    //   }else if(c.label === 'Cumulative Consultant Charges' && tableName === 'consultantCharges'){
    //     c.total = consultantFees - oldFees + newFees  ;
    //   }
    //   });
    //   this.caseData.cumulative = this.cumulativeCount;
    // }else{
    //   this.cumulativeCount.forEach((c: any) =>{
    //     if(c.label === 'Cumulative Pre-Deposit'){
    //       preDepositeTax ?  c.tax = preDepositeTax  : null;
    //       preDepositePenalty ?  c.penalty = preDepositePenalty  : null;
    //       preDepositeInterest ?  c.interest = preDepositeInterest  : null;
    //       preDepositeTotal ?  c.total = preDepositeTotal  : null;
    //     }
    //     if(c.label === 'Cumulative payment made under protest'){
    //       protestPaymentTax ?  c.tax = protestPaymentTax  : null;
    //       protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
    //       protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
    //       protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
    //     }else if(c.label === 'Cumulative payment made for admitted liability'){
    //       liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
    //       liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
    //       liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
    //       liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
    //     }else if(c.label === 'Cumulative Consultant Charges'){
    //       consultantFees ? c.total = consultantFees  : null;
    //     }
    //   });
    // }
    // if(tableName === 'protestDetails') this.paymentUnderProtestp1Changed();
    // if(tableName === 'predepositDetails') this.preDepositeChanged();
    // if(tableName === 'consultantCharges') this.feesChargedChanged();
    // if(tableName === 'liabilityDetails') this.liabilityTotalCalculation();
  }

  selectedRemandedBackCaseId(event: any) {
    this.selectedRemandedBackCaseDetail = this.remandedCaseObj.find((c: any) => c.value === event.target.value);
    this.initializeRemandedBackPaymentData(this.selectedRemandedBackCaseDetail.cumulative)
    this.setCummulativeCount();
    this.setRemandBackData();
  }

  setRemandBackData() {
    // this.disabledOnload = true;
    let remandBackCaseData: any = this.remandBackCaseList.find((data: any) => data.caseId == this.appAuthP1Form.value.remandedCaseId);
    this.appAuthP1Form.controls['caseId'].setValue(remandBackCaseData?.caseId);
    // this.appAuthP1Form.controls['actType'].setValue(remandBackCaseData?.actType );
    // this.changeTax(null);
    this.appAuthP1Form.controls['state'].setValue(remandBackCaseData?.state);
    // this.changeState(null);
    // this.appAuthP1Form.controls['regNo'].setValue(remandBackCaseData?.regNo);
    if (remandBackCaseData?.gstIssues?.length) this.rmIssueInv(0, true)
    this.initializeIssueList(remandBackCaseData, true);

  }
  changeRemandedBackForum(event: any) {
    this.remandedCaseObj = []
    let model: any = {};
    const criteriaArray: any = [{
      "p": "type",
      "o": "eq",
      "v": event.target.value
    }]
    model.criterias = criteriaArray;
    model.companyId = localStorage.getItem('selectedId'),
      this.litService.getRemandedBackForumGSTOptions(model).subscribe((response: any) => {
        if (response) {
          this.remandBackCaseList = response?.response;
          response?.response.forEach((res: any) => {
            let obj = { 'key': res.caseId, 'value': res.caseId, cumulative: res.cumulative, issues: res.issues }
            this.remandedCaseObj.push(obj)
          });

        }
      });
  }

  openRmvLiabilityPop(content: any, i: any) {
    this.rmvLiabilityModalRef = this.modalService.open(content);
  }

  uploadnoticeLoc() {
    this.isnoticeLocFile = true;
    this.isnoticeLocText = false;
    this.isDWnoticeLoc = false;
    this.isUPnoticeLoc = false;
  }
  getFormName() {
    return (this.preAdjType.find(type => type.key === this.appAuthP1Form?.get('remandedBackForum')?.value))?.value
  }

  newRemandedPaymentMade(data: any): UntypedFormGroup {
    return this.fBuild.group({
      label: [data ? data?.label : ''],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
    })
  }

  initializeRemandedBackPaymentData(data: any) {
    data.forEach((cumulative: any) => {
      let detailOfCumulative = this.newRemandedPaymentMade(cumulative);
      (this.appAuthP1Form.get('remandedPaymentMade') as UntypedFormArray).push(detailOfCumulative);
    });

  }

  getREmandedBackData() {
    return (this.appAuthP1Form.get('remandedPaymentMade') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  remandedBackPaymentValueChange() {
    (this.appAuthP1Form.get('remandedPaymentMade') as UntypedFormArray).controls.forEach((c: any) => {
      if (c.value.label !== 'Cumulative Consultant Charges') {
        c.patchValue({ total: (+c.value.tax) + (+c.value.interest) + (+c.value.penalty) }, { emitEvent: false, onlySelf: true });
      } else {
        c.patchValue({ total: +(c.value.total) })
      }
    });
  }
  refreshCumulativeTable(event: any) {
    event.stopPropagation();
    let refreshValue = this.appAuthP1Form.value;
    refreshValue['cumulative'] = this.caseData.cumulative
    refreshValue['prevStage'] = this.prevStage

    if (refreshValue.consultantCharges && refreshValue.consultantCharges.length) {
      refreshValue.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
    }

    if (refreshValue.predepositDetails && refreshValue.predepositDetails.length) {
      refreshValue.predepositDetails.forEach((predepositDetail: any) => {
        predepositDetail.igst = this.inrService.removeCommasAndParseNumber(predepositDetail.igst)
        predepositDetail.cgst = this.inrService.removeCommasAndParseNumber(predepositDetail.cgst);
        predepositDetail.sgst = this.inrService.removeCommasAndParseNumber(predepositDetail.sgst);
        predepositDetail.cess = this.inrService.removeCommasAndParseNumber(predepositDetail.cess);
        predepositDetail.interest = this.inrService.removeCommasAndParseNumber(predepositDetail.interest)
        predepositDetail.penalty = this.inrService.removeCommasAndParseNumber(predepositDetail.penalty)
        predepositDetail.total = this.inrService.removeCommasAndParseNumber(predepositDetail.total)
      })
    }
    if (refreshValue.protestDetails && refreshValue.protestDetails.length) {
      refreshValue.protestDetails.forEach((protestDetail: any) => {
        protestDetail.igst = this.inrService.removeCommasAndParseNumber(protestDetail.igst)
        protestDetail.cgst = this.inrService.removeCommasAndParseNumber(protestDetail.cgst);
        protestDetail.sgst = this.inrService.removeCommasAndParseNumber(protestDetail.sgst);
        protestDetail.cess = this.inrService.removeCommasAndParseNumber(protestDetail.cess);
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
        protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
      })
    }

    if (refreshValue.liabilityDetails && refreshValue.liabilityDetails.length) {
      refreshValue.liabilityDetails.forEach((liability: any) => {
        liability.igst = this.inrService.removeCommasAndParseNumber(liability.igst)
        liability.cgst = this.inrService.removeCommasAndParseNumber(
          liability.cgst
        );
        liability.sgst = this.inrService.removeCommasAndParseNumber(
          liability.sgst
        );
        liability.cess = this.inrService.removeCommasAndParseNumber(
          liability.cess
        );
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
      })
    }

    if (refreshValue.issues && refreshValue.issues.length) {
      refreshValue.issues.forEach((issue: any) => {
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
        issue.cgst1 = this.inrService.removeCommasAndParseNumber(issue.cgst1)
        issue.sgst1 = this.inrService.removeCommasAndParseNumber(issue.sgst1)
        issue.cess1 = this.inrService.removeCommasAndParseNumber(issue.cess1)
        issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
        issue.cgst3 = this.inrService.removeCommasAndParseNumber(issue.cgst3)
        issue.sgst3 = this.inrService.removeCommasAndParseNumber(issue.sgst3)
        issue.cess3 = this.inrService.removeCommasAndParseNumber(issue.cess3)
        issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)
      })
    }

    refreshValue.igst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.igst1Total)
    refreshValue.cgst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.cgst1Total)
    refreshValue.sgst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.sgst1Total)
    refreshValue.cess1Total = this.inrService.removeCommasAndParseNumber(refreshValue.cess1Total)
    refreshValue.interest1Total = this.inrService.removeCommasAndParseNumber(refreshValue.interest1Total)
    refreshValue.penalty1Total = this.inrService.removeCommasAndParseNumber(refreshValue.penalty1Total)
    refreshValue.totOfTotal1 = this.inrService.removeCommasAndParseNumber(refreshValue.totOfTotal1)
    refreshValue.igst3Total = this.inrService.removeCommasAndParseNumber(refreshValue.igst3Total)
    refreshValue.cgst3Total = this.inrService.removeCommasAndParseNumber(refreshValue.cgst3Total)
    refreshValue.sgst3Total = this.inrService.removeCommasAndParseNumber(refreshValue.sgst3Total)
    refreshValue.cess3Total = this.inrService.removeCommasAndParseNumber(refreshValue.cess3Total)
    refreshValue.interest3Total = this.inrService.removeCommasAndParseNumber(refreshValue.interest3Total)
    refreshValue.penalty3Total = this.inrService.removeCommasAndParseNumber(refreshValue.penalty3Total)
    refreshValue.totOfTotal3 = this.inrService.removeCommasAndParseNumber(refreshValue.totOfTotal3)
    this.litService.getCumulativeCount(this.tabType[2].key, refreshValue).subscribe((response: any) => {
      console.log(response.response);
      this.formatTableValues();
      this.caseData.cumulative = response.response;
      this.cumulativeCount = response.response
    })
  }

  setRemandBackTableData(label: any, id: any, value: any) {
    (this.appAuthP1Form.get('remandedPaymentMade') as UntypedFormArray).controls.forEach((c: any) => {
      if (c.value.label === label) {
        c.value[id] = value
      }
    });
  }

  handlePaste(event: any) {
    this.inrService.handlePaste(event)
  }
  formatTableValues() {
    const issues1Array = this.appAuthP1Form.controls.issues as UntypedFormArray
    const liabilityDetailsArray = this.appAuthP1Form.controls.liabilityDetails as UntypedFormArray
    const predepositDetailsArray = this.appAuthP1Form.controls.predepositDetails as UntypedFormArray
    const protestDetailsArray = this.appAuthP1Form.controls.protestDetails as UntypedFormArray
    const consultantChargesArray = this.appAuthP1Form.controls.consultantCharges as UntypedFormArray

    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (var index = 0; index < consultantChargesArray.controls.length; index++) {
        const consultantChargesControl = consultantChargesArray.controls[index] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(this.inrService.formatCurrency(consultantChargesControl.value.fees))
      }
    }

    if (predepositDetailsArray && predepositDetailsArray.controls.length) {
      for (var index = 0; index < predepositDetailsArray.controls.length; index++) {
        const predepositDetailControl = predepositDetailsArray.controls[index] as UntypedFormGroup;
        predepositDetailControl.controls['igst'].setValue(this.inrService.formatCurrency(predepositDetailControl.value.igst))
        predepositDetailControl.controls['cgst'].setValue(this.inrService.formatCurrency(predepositDetailControl.value.cgst))
        predepositDetailControl.controls['sgst'].setValue(this.inrService.formatCurrency(predepositDetailControl.value.sgst))
        predepositDetailControl.controls['cess'].setValue(this.inrService.formatCurrency(predepositDetailControl.value.cess))
        predepositDetailControl.controls['interest'].setValue(this.inrService.formatCurrency(predepositDetailControl.value.interest))
        predepositDetailControl.controls['penalty'].setValue(this.inrService.formatCurrency(predepositDetailControl.value.penalty))
        predepositDetailControl.controls['total'].setValue(this.inrService.formatCurrency(predepositDetailControl.value.total))
      }
    }
    if (protestDetailsArray && protestDetailsArray.controls.length) {
      for (var index = 0; index < protestDetailsArray.controls.length; index++) {
        const protestDetailsControl = protestDetailsArray.controls[index] as UntypedFormGroup;
        protestDetailsControl.controls['igst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.igst))
        protestDetailsControl.controls['cgst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.cgst))
        protestDetailsControl.controls['sgst'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.sgst))
        protestDetailsControl.controls['cess'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.cess))
        protestDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.interest))
        protestDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.penalty))
        protestDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.total))
      }
    }
    if (liabilityDetailsArray.controls.length > 0) {
      for (let index = 0; index < liabilityDetailsArray.controls.length; index++) {
        const liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
        liabilityDetailsControl.controls['igst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.igst))
        liabilityDetailsControl.controls['cgst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.cgst))
        liabilityDetailsControl.controls['sgst'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.sgst))
        liabilityDetailsControl.controls['cess'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.cess))
        liabilityDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.interest))
        liabilityDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.penalty))
        liabilityDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.total))
      }
    }

    if (issues1Array.controls.length > 0) {
      for (let i = 0; i < issues1Array.controls.length; i++) {
        const issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        issues1Control.controls['igst1'].setValue(this.inrService.formatCurrency(issues1Control.value.igst1))
        issues1Control.controls['cgst1'].setValue(this.inrService.formatCurrency(issues1Control.value.cgst1))
        issues1Control.controls['sgst1'].setValue(this.inrService.formatCurrency(issues1Control.value.sgst1))
        issues1Control.controls['cess1'].setValue(this.inrService.formatCurrency(issues1Control.value.cess1))
        issues1Control.controls['interest1'].setValue(this.inrService.formatCurrency(issues1Control.value.interest1))
        issues1Control.controls['penalty1'].setValue(this.inrService.formatCurrency(issues1Control.value.penalty1))
        issues1Control.controls['total1'].setValue(this.inrService.formatCurrency(issues1Control.value.total1))
        issues1Control.controls['igst3'].setValue(this.inrService.formatCurrency(issues1Control.value.igst3))
        issues1Control.controls['cgst3'].setValue(this.inrService.formatCurrency(issues1Control.value.cgst3))
        issues1Control.controls['sgst3'].setValue(this.inrService.formatCurrency(issues1Control.value.sgst3))
        issues1Control.controls['cess3'].setValue(this.inrService.formatCurrency(issues1Control.value.cess3))
        issues1Control.controls['interest3'].setValue(this.inrService.formatCurrency(issues1Control.value.interest3))
        issues1Control.controls['penalty3'].setValue(this.inrService.formatCurrency(issues1Control.value.penalty3))
        issues1Control.controls['total3'].setValue(this.inrService.formatCurrency(issues1Control.value.total3))
      }
    }
    this.issueValueChange();
  }
  selectElement:any
  getCurrentTime(){
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();

    // Round minutes to nearest half-hour
    const roundedMinute = Math.round(currentMinute / 30) * 30;

    // Adjust current hour for AM/PM format
    const timeFormat = currentHour >= 12 ? 'PM' : 'AM';
    const hour12 = (currentHour % 12) || 12; // Convert hour to 12-hour format

    // Construct current selected time
    const currentSelectedTime = `${hour12.toString().padStart(2, '0')}:${roundedMinute.toString().padStart(2, '0')} ${timeFormat}`;
    var itemListArray = this.appAuthP1Form.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
        issues1Control.controls.phTime.setValue(currentSelectedTime);

      }
    } 
   }

  selectedIssues:any
  getissueData(content: any, i:any){
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    this.selectedIssues = this.appAuthP1Form.value.issues[i].issue;
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = "7437",
    model.module = "GST",
    model.role = "issueRef",
    model.criterias= [
      {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
      }
  ]
  console.log(this.selectedIssues,"this.appAuthP1Form.value.issue");
  this.litService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.status === 1) {
        this.issueData = response?.response;
        if(this.issueData.length === 0){
          this.hideData =  false
      }else{
        this.hideData =  true
      }
      }      
    });
  
  }
  exportAsXLSX() {
    if (this.issueData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    const data= this.issueData.map((source: any) => ({
      'PAN Name': source.panName,
      'GSTIN': source.gstin,
      'GSTIN Name': source.gstinName,
      'Division': source.gstinDiv,
      'Case id': source.caseId,
      'Case Label': source.caseLabel,
      'Period From': source.periodFromDate,
      'Period to': source.periodToDate,
      'Person Responsible': source.personResponsible,

    }));
    this.excelService.exportAsExcelFile(data, 'Issue Reference');
  }
  SortingIssue(column: string) {
    switch (column) {
      case SORT_ISSUE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'asc'
          );
        } else {
          this.sorting.caseId = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.STATE: {
        if (!this.sorting.state) {
          this.sorting.state = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'asc'
          );
        } else {
          this.sorting.state = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PAN: {
        if (!this.sorting.panName) {
          this.sorting.panName = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'panName',
            'asc'
          );
        } else {
          this.sorting.panName = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'panName',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.CASE_LABEL: {
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'asc'
          );
        } else {
          this.sorting.caseLabel = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERIOD_FROM: {
        if (!this.sorting.periodFromDate) {
          this.sorting.periodFromDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'asc'
          );
        } else {
          this.sorting.periodFromDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'desc'
          );
        }
        break;
      }

      case SORT_ISSUE.PERIOD_TO: {
        if (!this.sorting.periodToDate) {
          this.sorting.periodToDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'asc'
          );
        } else {
          this.sorting.periodToDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERSON_RESPONSIBLE: {
        if (!this.sorting.personResponsible) {
          this.sorting.personResponsible = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,'personResponsible','asc');
        } else {
          this.sorting.personResponsible = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,'personResponsible', 'desc' );
        }
        break;
      }
      case SORT_ISSUE.GSTIN: {
        if (!this.sorting.gstin) {
          this.sorting.gstin = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstin',
            'asc'
          );
        } else {
          this.sorting.gstin = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstin',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.DIVISION: {
        if (!this.sorting.gstinDiv) {
          this.sorting.gstinDiv = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstinDiv',
            'asc'
          );
        } else {
          this.sorting.gstinDiv = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'gstinDiv',
            'desc'
          );
        }
        break;
      }
    }
  }
}
