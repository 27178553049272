<div class="d-flex justify-content-end my-3 me-3 btnPos">
    <div class="switch-box">
        <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
        <div class="form-check form-switch m-0">
            <input [(ngModel)]="selectedIRN" (change)="changeStatsValue(selectedIRN,$event)" class="form-check-input"
                type="checkbox" role="switch" [checked]="amountMetric === 'crore'" />
        </div>
        <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
    </div>
</div>

<div class="row" *ngIf="isinLakh">
    <div class="table-responsive">
        <table class="table table-bordered divTbl">
            <thead>
                <tr class="bgGred">
                    <th>Division</th>
                    <th>Tax </th>
                    <th>Interest </th>
                    <th>Penalty </th>
                    <th>Total </th>
                </tr>
            </thead>
            <tbody class="divTbody">
                <ng-container *ngFor="let obj of taxData">
                    <tr>
                        <td class="main-row-label">{{obj.division | uppercase}} <a class="letterTbl"><span
                                    class="expand" (click)="obj.expanded = !obj.expanded">{{ obj.expanded ? '&ndash;' :
                                    '+'}}</span></a> </td>
                        <td class="textRight">{{obj.totalTax | INR : 2}}</td>
                        <td class="textRight">{{obj.totalInterest | INR : 2}}</td>
                        <td class="textRight">{{obj.totalPenalty | INR : 2}}</td>
                        <td class="textRight totalBold">{{obj.total | INR : 2}} </td>


                    </tr>
                    <ng-container *ngIf="obj.expanded">
                        <tr *ngFor="let item of obj.actData" class="expandTr">
                            <td class="pl-4">{{item.act}}</td>
                            <td class="textRight">{{item.actdata.totalTax | INR : 2}}</td>
                            <td class="textRight">{{item.actdata.totalInterest | INR : 2 }}</td>
                            <td class="textRight">{{item.actdata.totalPenalty | INR : 2}}</td>
                            <td class="textRight totalBold">{{item.actdata.total | INR : 2}}
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="divSumColTotal">
                    <tr style="background: #ede8e8;font-weight: 600;">
                        <td>TOTAL</td>
                        <td class="textRight">{{divSumColTotal.totalTax | INR : 2}}</td>
                        <td class="textRight">{{divSumColTotal.totalInterest | INR : 2}}</td>
                        <td class="textRight">{{divSumColTotal.totalPenalty | INR : 2}}</td>
                        <td class="textRight">{{divSumColTotal.grandtotal | INR : 2}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

<div class="row" *ngIf="isinCR">
    <div class="table-responsive">
        <table class="table table-bordered divTbl">
            <thead>
                <tr class="bgGred">
                    <th>Division</th>
                    <th>Tax </th>
                    <th>Interest </th>
                    <th>Penalty </th>
                    <th>Total </th>
                </tr>
            </thead>
            <tbody class="divTbody">
                <ng-container *ngFor="let obj of taxData">
                    <tr>
                        <td class="main-row-label">{{obj.division | uppercase}} <a class="letterTbl"><span
                                    class="expand" (click)="obj.expanded = !obj.expanded">{{ obj.expanded ? '&ndash;' :
                                    '+'}}</span></a> </td>
                        <td class="textRight">{{obj.totalTaxInCr | INR : 2}}</td>
                        <td class="textRight">{{obj.totalInterestInCr | INR : 2}}</td>
                        <td class="textRight">{{obj.totalPenaltyInCr | INR : 2}}</td>
                        <td class="textRight totalBold">{{obj.totalInCr | INR : 2}} </td>


                    </tr>
                    <ng-container *ngIf="obj.expanded">
                        <tr *ngFor="let item of obj.stateData" class="expandTr">
                            <td class="pl-4">{{item.state}}</td>
                            <td class="textRight">{{item.statedata.taxInCr | INR : 2}}</td>
                            <td class="textRight">{{item.statedata.interestInCr | INR : 2}}</td>
                            <td class="textRight">{{item.statedata.penaltyInCr | INR : 2}}</td>
                            <td class="textRight totalBold">{{item.statedata.totalInCr| INR:
                                2}}
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="divSumColTotal">
                    <tr style="background: #ede8e8;font-weight: 600;">
                        <td>TOTAL</td>
                        <td class="textRight">{{divSumColTotal.totalTaxInCr | INR : 2}}</td>
                        <td class="textRight">{{divSumColTotal.totalInterestInCr | INR : 2}}
                        </td>
                        <td class="textRight">{{divSumColTotal.totalPenaltyInCr | INR : 2}}</td>
                        <td class="textRight">{{divSumColTotal.grandtotalInCr | INR : 2}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>

