import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AuditService } from 'src/app/audit/service/audit.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { TaxServiceService } from '../../service/tax-service.service';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent implements OnInit {
  @Input() tableData: any[] = [];
  @Output() rowEdited: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();

  navContext = { entityName: '', isTaxPayer: false, entityType: '', pan: '', gstin: '' };
  selectedField: any;
  selectedFile: any;
  matches: any;
  fileUrl: any;
  caseId: any;
  gstinOrPan:any;
  selectedPan: any;
  selectedPanName: any;
  selectedFileName: any;
  formData: FormData = new FormData();

  attachmentFormGrp!: UntypedFormGroup;
  rmvDetailModalRef!: NgbModalRef;
  isSubmitted: boolean = false;


  isUPjpLoc = true;
  isDWjpLoc = true;
  isjpLocFile = false;
  isjpLocText= true;

  constructor(
    private fBuild: UntypedFormBuilder,
    private toaster: ToasterService,
    private taxService: TaxServiceService,
    private commonServ: CommonService,    
    private modalService: NgbModal,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.navContext= this.commonServ.getNavContext();
    this.selectedPan = this.navContext.pan;
    if(this.navContext.entityType === 'Business'){
      this.selectedPanName = sessionStorage.getItem('company');
    }else{
      this.selectedPanName = this.navContext.entityName;
    }
    this.formInitialize();
    this.addRow();
  }

  formInitialize() {
    this.attachmentFormGrp = this.fBuild.group({
      attachmentList: new UntypedFormArray([]),
    })
  }
  get formGrp() { return this.attachmentFormGrp.controls; }

  /*upload file selection */
  onFileSelect(event: any) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  onUpload(selectedfield: any) {
    let urlData = {
      gstinOrPan: this.navContext.pan,
      type: 'taxpos',
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.taxService.uploadFile(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFileUrl(response.url, response.path, selectedfield);
      } else {
      }
    })
  }

  getFileUrl(url: any, path: any, selectedfield: any) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      console.log("response receved is ", response);
      if (selectedfield) {
        var toupdate = this.attachmentFormGrp.value;
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.attachmentFormGrp.patchValue(this.attachmentFormGrp.value);

      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }

    /* get download file path function */
    getFileNameDta(fileUrl: any) {
      if (fileUrl)
        return this.taxService.getFileName(fileUrl);
    }
    /* get download file path function */
    
    createPath(str: string) {
      let path = [];
      let splitedPath = str.split('.');
      for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
        let pathChunk = splitedPath[splitedPathIndex];
        let indexOfBrc = pathChunk.indexOf('(');
        if (indexOfBrc === -1) {
          path.push(pathChunk);
        } else {
          path.push(pathChunk.substr(0, indexOfBrc));
          path.push(pathChunk.charAt(indexOfBrc + 1));
        }
      }
      path.push(splitedPath[splitedPath.length - 1]);
      return path;
    }
  
    /*upload file selection */
    /*download file function */
    download(fileUrl: any) {
      let urlData = {
        fname: fileUrl
      }
      this.taxService.downloadFile(urlData).subscribe((response: any) => {
        if (response != null) {
          this.fileUrl = response.url;
          window.open(this.fileUrl, '_blank');
        } else {
        }
      })
    }

    getAttachmentData(): UntypedFormGroup[] {
      return (this.attachmentFormGrp.get('attachmentList') as UntypedFormArray).controls as UntypedFormGroup[];
    }
  
    initTaxPositionGrp(): UntypedFormGroup {
      return new UntypedFormGroup({
        fileLoc: new UntypedFormControl('', [Validators.required]),
        fileName: new UntypedFormControl(this.selectedFile, [Validators.required]),
        remarks: new UntypedFormControl(''),
      })
    }
    addRow() {
      let attachmentDataGrp = this.initTaxPositionGrp();
      (this.attachmentFormGrp.get('attachmentList') as UntypedFormArray).push(attachmentDataGrp);
    }
    rmProv(i: number) {
      (this.attachmentFormGrp.get('attachmentList') as UntypedFormArray).removeAt(i);
      this.rmvDetailModalRef.close();
  
    }
  
    opentaxPop(content: any) {
      this.rmvDetailModalRef = this.modalService.open(content);
    }

    uploadjudicialPredLoc(k:any) {
      let attachmentArray = this.attachmentFormGrp.controls.attachmentList as UntypedFormArray
      if(attachmentArray.controls.length > 0) {
        for(let index = 0; index < attachmentArray.controls.length; index++){
            if(index === k){
              let attachmentControl = attachmentArray.controls[index] as UntypedFormGroup;
              attachmentControl.value['isCopyOfFormLocDocUploadedClicked'] = true
            }
          }
      }
      this.isjpLocFile = true;
      this.isjpLocText = false;
      this.isDWjpLoc = true;
      this.isUPjpLoc = false; 
    }
    onRowEdit(data: any) {
      this.rowEdited.emit(this.attachmentFormGrp);
    }
}
