import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LitigationServiceService } from '../services/litigation-service.service';

@Component({
  selector: 'app-case-master',
  templateUrl: './case-master.component.html',
  styleUrls: ['./case-master.component.css']
})
export class CaseMasterComponent implements OnInit {
  selectedTab: any;
  type: any;
  tab: any;
  isActionUpdate:any;
  isActionView:any;
  getAccessTabData: any;
  someElement: any;
  tabDisableFlag= {'tab1':false, 'tab2':false, 'tab3':false, 'tab4':false, 'tab5':false, 'tab6':false};
  selectedCaseId: any;
  isRemandedUpdated:any
  activeStages: string[] | null = null;
  constructor(
    private litService: LitigationServiceService,
    private activeSnapShot: ActivatedRoute,
    private router: Router,
  ) {
    this.type = this.activeSnapShot.snapshot.params.type;
    this.tab = this.activeSnapShot.snapshot.params.tab;
    this.isActionUpdate = this.activeSnapShot.snapshot.params.action === 'update' ? true :false;
    this.isActionView = this.activeSnapShot.snapshot.params.action === 'view' ? true :false;
  }

  ngOnInit(){
    // this.litService.getAccessTabData().subscribe((val) => {
    //   if (val) {
    //     this.getAccessTabData = val;
    //   }
    // });
    this.selectedTab = 'tab1';
    if(this.type === 'scn' || this.tab === 'tab1'){
      this.selectedTab = 'tab1';
    }else if(this.type === 'aja' || this.tab === 'tab2'){
      this.selectedTab = 'tab2';
    }else if(this.type === 'apa'|| this.tab === 'tab3'){
      this.selectedTab = 'tab3';
    }else if(this.type === 'apt'|| this.tab === 'tab4'){
      this.selectedTab = 'tab4';
    }else if(this.type === 'hc'|| this.tab === 'tab5'){
      this.selectedTab = 'tab5';
    }else if(this.type === 'sc'|| this.tab === 'tab7'){
      this.selectedTab = 'tab6';
    }
    this.tabChanged(this.tab);

  }
  tabChanged(tab:any, check?:any,isMoveToHC:any=false) {
    if(tab){
    this.selectedTab = tab;
    this.disabledTab();
    this.enabledTab(this.tab);
    if((this.type === 'aja' && this.activeSnapShot.snapshot.params.action ==='create' && this.tab === 'tab2') || (this.activeSnapShot.snapshot.params.caseId?.includes('R-') &&  this.activeSnapShot.snapshot.params.action ==='update' && this.type === 'aja') || (this.activeSnapShot.snapshot.params.action ==='view' && this.type === 'aja')){
      this.isRemandedUpdated = true;
    }
    if((this.type === 'aja' && this.activeSnapShot.snapshot.params.action ==='create' && this.tab === 'tab2') || (this.activeSnapShot.snapshot.params.caseId?.includes('AUD') &&  this.activeSnapShot.snapshot.params.action ==='update'&& this.type === 'aja') || (this.activeSnapShot.snapshot.params.action ==='view' &&  this.type === 'aja')){
      this.tabDisableFlag.tab1 = false;
    }
    if(this.activeSnapShot.snapshot.params.action ==='create' && this.tab === 'tab3'){
      this.tabDisableFlag.tab1 = false;
      this.tabDisableFlag.tab2 = false;

    }
    if(isMoveToHC){
      this.tabDisableFlag.tab1 = false;
      this.tabDisableFlag.tab2 = false;
      this.tabDisableFlag.tab3 = false;
      this.tabDisableFlag.tab4 = false;

    }
  }
  }
  gotoSummary(){
    this.router.navigate(['/litigationSummary'])
  }
  disabledTab(){
    switch (this.selectedTab) {
      case 'tab1':
       this.tabDisableFlag = {'tab1':true, 'tab2':false, 'tab3':false, 'tab4':false, 'tab5':false, 'tab6':false};
      break;
      case 'tab2':
        this.tabDisableFlag = {'tab1':true, 'tab2':true, 'tab3':false, 'tab4':false, 'tab5':false, 'tab6':false};
      break;
      case 'tab3':
        this.tabDisableFlag = {'tab1':true, 'tab2':true, 'tab3':true, 'tab4':false, 'tab5':false, 'tab6':false};
      break;
      case 'tab4':
        this.tabDisableFlag = {'tab1':true, 'tab2':true, 'tab3':true, 'tab4':true, 'tab5':false, 'tab6':false};
      break;
      case 'tab5':
        this.tabDisableFlag = {'tab1':true, 'tab2':true, 'tab3':true, 'tab4':true, 'tab5':true, 'tab6':false};
      break;
      case 'tab6':
        this.tabDisableFlag = {'tab1':true, 'tab2':true, 'tab3':true, 'tab4':true, 'tab5':true, 'tab6':true};
      break;
    }

  }

  enabledTab(tab:any){
    if(tab === 'tab1'){
      this.tabDisableFlag = {'tab1':true, 'tab2':false, 'tab3':false, 'tab4':false, 'tab5':false, 'tab6':false};
      this.someElement= document.getElementById("notice-tab");
      this.someElement.classList.remove("disabled");
    }
    if(tab === 'tab2'){
      this.tabDisableFlag = {'tab1':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab2':true, 'tab3':false, 'tab4':false, 'tab5':false, 'tab6':false};
      this.someElement= document.getElementById("adjudication-tab");
      this.someElement.classList.remove("disabled");
    }
    if(tab === 'tab3'){
      this.tabDisableFlag = {'tab1':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab2':(this.isActionUpdate ? false :true|| this.isActionView ? false :true), 'tab3':true, 'tab4':false, 'tab5':false, 'tab6':false};
      this.someElement= document.getElementById("AppelAuth-tab");
      this.someElement.classList.remove("disabled");
    }
    if(tab === 'tab4'){
      this.tabDisableFlag = {'tab1':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab2':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab3':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab4':true, 'tab5':false, 'tab6':false};
      this.someElement= document.getElementById("AppelTrib-tab");
      this.someElement.classList.remove("disabled");
    }
    if(tab === 'tab5'){
      this.tabDisableFlag = {'tab1':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab2':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab3':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab4':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab5':true, 'tab6':false};
      this.someElement= document.getElementById("HC-tab");
      this.someElement.classList.remove("disabled");
    }
    if(tab === 'tab6'){
      this.tabDisableFlag = {'tab1':(this.isActionUpdate ? false :true || this.isActionView ? false :true ), 'tab2':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab3':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab4':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab5':(this.isActionUpdate ? false :true ||this.isActionView ? false :true), 'tab6':true};
      this.someElement= document.getElementById("SC-tab");
      this.someElement.classList.remove("disabled");
    }

  }

  saveCaseId(event : any){
    this.selectedCaseId = event;
  }

  enablePrevStageTab(stage:any) {
    this.activeStages = stage;
    if (this.activeStages && this.activeStages.length) {
   for(let  i = 0; i < stage.length; i++){
    switch(stage[i]){
      case 'scn' : this.tabDisableFlag.tab1 = true;
                   break;
      case 'aja' : this.tabDisableFlag.tab2 = true;
                   break;
      case 'apa' : this.tabDisableFlag.tab3 = true;
                   break;
      case 'apt' : this.tabDisableFlag.tab4 = true;
                   break;
      case 'hc' : this.tabDisableFlag.tab5 = true;
                   break;
      case 'sc' : this.tabDisableFlag.tab6 = true;
                   break;
    }
  }
}}
}
