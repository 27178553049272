<!-- <app-filter [isPreGST]=true (messageEvent)="onFilterEvent($event)" [filterData]="filterObj" *ngIf="isFilterDivOpen"></app-filter> -->

<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <a class="notify"><i class="fa fa-bell" (click)="showHidePop()"></i></a>

                            <div class="notifyDiv top-2" id="text" *ngIf="showPop">
                                <h2 class="report-header" *ngIf="!showHeader"> MASTER REPORT </h2>
                                <h2 class="report-header" *ngIf="showHeader">CONTINGENCY REPORT </h2>

                                <i class="settings-close mdi mdi-close" (click)="closeDiv()"></i>
                                <h6 class="progTxt" ng-show="flag2PData.downloadInProgress" *ngIf="showStatus">
                                    <i class="fa fa-hourglass-start fs14 progIcon"
                                        style="margin-right: 5px; color: orange;"></i> <span
                                        style="line-height: 1.4;">Download started for Master Report. Please check
                                        status.</span>
                                    <button class="reports" (click)="statusReport()"> Check Latest Status</button>
                                </h6>
                                <h6 class="progTxt" ng-show="flag2PData.downloadCompleted" *ngIf="!showStatus">
                                    <i class="fa fa-check-square-o fs14 compIcon"
                                        style="margin-right: 5px; color: #025c48;" *ngIf="checkIcon"></i>
                                    <i class="fa fa-check-square-o fs14 compIcon"
                                        style="margin-right: 5px; color: orange;" *ngIf="!checkIcon"></i> Download
                                    Completed for Master Report.
                                    <button class="report-link" (click)="downLoadMasterReport()">Click to
                                        download</button>
                                </h6>
                            </div>

                            <p class="card-title">Pre GST Summary <span title="Open Cases">({{opneCase}}</span>/<span
                                    title="Total Cases">{{totalCase}})</span>
                                <!-- <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="sortFilterLbl" title="Filter"><i class="fa fa-filter clrOrange notifyIcon"></i></a>
                                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="download" title="Download" ><i class="fa fa-download clrOrange notifyIcon"></i></a> -->
                                <!-- <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="sortFilterLbl" title="Filter" (click)="openDiv()" [ngClass]="{'filterapplied':isFilterhighlited}"><i
                                    class="fa fa-filter clrOrange notifyIcon" [ngClass]="{'filterapplied':isFilterhighlited}"></i> Filter</a> -->
                                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="sortFilterLbl" title="Filter"><i
                                        class="fa fa-filter clrOrange notifyIcon" (click)="openModal(filter)"></i><span
                                        (click)="openModal(filter)"> Filter</span></a>
                                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="download" title="Master Report"
                                    (click)="generatedReport('Master')" *ngIf="!showHeader1"><i
                                        class="fa fa-download clrOrange notifyIcon"></i> Master Report</a>
                                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="download" title="Contingency Report"
                                    (click)="contingencyReport('Contingency')" *ngIf="1showHeader"><i
                                        class="fa fa-download clrOrange notifyIcon"></i> Contingency Report</a>
                            </p>
                            <div class="row m-0 highDiv">
                                <div class="col-12 nopadding">
                                    <ul class="action-bar">
                                        <li class="bg-transparent">
                                            <ng-container *ngFor="let badge of badgesList;let i=index">
                                                <small [ngClass]="[badgeColorArray[i], textColorArray[i]]"
                                                    class="bg-opacity-10 border  d-inline-flex fw-semibold mb-3 px-2 py-1 me-2 rounded-2 ">{{badge.name}}:
                                                    {{ badge.value }}
                                                    <i type="button" class="mdi mdi-close ms-2"
                                                        (click)="removeFilter(badge)"></i>
                                                </small>
                                            </ng-container>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <ng-template #filter let-modal>

                                <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Filter</h4>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                    <!-- <h5 class="modal-title pull-left ">Filter</h5>
                                      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true" class="visually-hidden">&times;</span>
                                      </button> -->
                                </div>
                                <div class="modal-body">
                                    <div class="row">
                                        <div class="col-sm-3">
                                            <div class="side_nav ms-0" style="width: auto">
                                                <ul class="font-size-14">
                                                    <li (click)="filterType = 'Act'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Act' }">Act</a>
                                                    </li>
                                                    <li (click)="filterType = 'Case Id'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Case Id' }">Case
                                                            Id</a>
                                                    </li>
                                                    <li (click)="filterType = 'Case Label'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Case Label' }">Case
                                                            Label</a>
                                                    </li>
                                                    <li (click)="filterType = 'Consultant'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Consultant' }">Consultant</a>
                                                    </li>
                                                    <li (click)="filterType = 'Demand Amount'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Demand Amount' }">Demand
                                                            Amount</a>
                                                    </li>
                                                    <!-- <li (click)="filterType = 'Divison'">
                                                <a class=" cursor-pointer"  [ngClass]="{ 'filter-active': filterType == 'Divison' }">Divison</a>
                                              </li> -->
                                                    <li (click)="filterType = 'Due Date'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Due Date' }">Due
                                                            Date</a>
                                                    </li>
                                                    <li (click)="filterType = 'Issue'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Issue' }">Issue</a>
                                                    </li>
                                                    <li (click)="filterType = 'Key words'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Key words' }">Key
                                                            words</a>
                                                    </li>
                                                    <li (click)="filterType = 'Period From'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Period From' }">Period
                                                            From</a>
                                                    </li>
                                                    <li (click)="filterType = 'Period To'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Period To' }">Period
                                                            To</a>
                                                    </li>
                                                    <li (click)="filterType = 'Person Responsible'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Person Responsible' }">Person
                                                            Responsible</a>
                                                    </li>
                                                    <li (click)="filterType = 'Registration Number'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Registration Number' }">Registration
                                                            Number</a>
                                                    </li>
                                                    <li (click)="filterType = 'Status'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'Status' }">Status</a>
                                                    </li>
                                                    <li (click)="filterType = 'State'">
                                                        <a class=" cursor-pointer"
                                                            [ngClass]="{ 'filter-active': filterType == 'State' }">State</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-8 mt-2">
                                            <form [formGroup]="filterForm" class="formSec">
                                                <div *ngIf="filterType == 'Act'">
                                                    <label>Act</label>
                                                    <ng-multiselect-dropdown formControlName="actType"
                                                        [placeholder]="'Select Act'" [settings]="dropdownSettings"
                                                        [data]="actOptions">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                                <div *ngIf="filterType == 'Case Id'">
                                                    <label>Case Id</label>
                                                    <input type="text" class="form-control mt-2"
                                                        formControlName="caseId" />

                                                </div>
                                                <div *ngIf="filterType == 'Case Label'">
                                                    <label>Case Label</label>
                                                    <input type="text" class="form-control mt-2"
                                                        formControlName="caseLabel" />

                                                </div>
                                                <div *ngIf="filterType == 'Consultant'">
                                                    <label>Consultant</label>
                                                    <input type="text" class="form-control mt-2"
                                                        formControlName="consultant" />

                                                </div>
                                                <div *ngIf="filterType == 'Demand Amount'">
                                                    <label>Demand Amount</label>
                                                    <select class="form-control form-select me-3 "
                                                        formControlName="amtQuery" [value]="amtQuery.o">
                                                        <option [value]="null">-- Select --</option>
                                                        <ng-container *ngFor="let obj of demandAmtOptions">
                                                            <option [value]="obj.key">{{obj.value}}</option>
                                                        </ng-container>
                                                    </select>
                                                    <input type="text" class="form-control mt-2"
                                                        formControlName="amtQueryValue" />
                                                </div>

                                                <!-- <div *ngIf="filterType == 'Divison'">
                                              <label>Divison</label>
                                              <ng-multiselect-dropdown formControlName="div"
                                              [placeholder]="'Select Divison'"
                                              [settings]="dropdownSettings"
                                              [data]="division"
                                             >
                                          </ng-multiselect-dropdown>
                                            </div> -->

                                                <div *ngIf="filterType == 'Due Date'">
                                                    <label>Due Date</label>
                                                    <!-- <div class="col-md-12">
                                                <button class="btn btn-outline-primary mr-1" (click)="nextWeek()">Next Week</button>
                                                <button class="btn btn-outline-primary mr-1" (click)="nextMonth()">Next Month</button>
                                                <button class="btn btn-outline-primary mr-1" (click)="custom()">Custom</button>
                                              </div> -->
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Start </label>
                                                            <div id="dd1frmDate" class="input-group date datepicker ">
                                                                <p-calendar [monthNavigator]="true"
                                                                    [yearNavigator]="true"
                                                                    yearRange="2016:{{currentYear}}"
                                                                    dateFormat="dd-mm-yy" [showIcon]="true" class="form-control"
                                                                    dataType="string"
                                                                    formControlName="dd1"></p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="">End</label>
                                                            <div id="dd2toDate" class="input-group date datepicker ">
                                                                <p-calendar dateFormat="dd-mm-yy" class="form-control"
                                                                    [monthNavigator]="true" [yearNavigator]="true"
                                                                    yearRange="2016:{{currentYear}}" [showIcon]="true"
                                                                    dataType="string"
                                                                    formControlName="dd2"></p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="filterType == 'Issue'">
                                                    <label>Issue</label>
                                                    <select class="form-control form-select " id="issue"
                                                        formControlName="issue">
                                                        <option [value]="null">-- Select --</option>
                                                        <optgroup *ngFor='let grp of issueGst' label="{{grp.group}}">
                                                            <option *ngFor='let item of grp.items' [value]="item.name"
                                                                title="{{item.name}}">
                                                                {{item.name}}</option>
                                                        </optgroup>
                                                    </select>
                                                </div>
                                                <div *ngIf="filterType == 'Key words'">
                                                    <label>Key Word</label>
                                                    <input type="text" class="form-control mt-2" formControlName="kw" />
                                                </div>
                                                <div *ngIf="filterType == 'Period From'">
                                                    <label>Period From</label>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Start </label>
                                                            <div id="pf1rmDate" class="input-group date datepicker">
                                                                <p-calendar [monthNavigator]="true"
                                                                    [yearNavigator]="true"
                                                                    yearRange="2016:{{currentYear}}" class="form-control"
                                                                    dateFormat="dd-mm-yy" [showIcon]="true"
                                                                    formControlName="pf1"
                                                                    dataType="string"></p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="">End</label>
                                                            <div id="pf2toDate" class="input-group date datepicker ">
                                                                <p-calendar dateFormat="dd-mm-yy" class="form-control"
                                                                    [monthNavigator]="true" [yearNavigator]="true"
                                                                    yearRange="2016:{{currentYear}}" [showIcon]="true"
                                                                    formControlName="pf2"
                                                                    dataType="string"></p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="filterType == 'Period To'">
                                                    <label>Period To</label>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="">Start </label>
                                                            <div id="pt1frmDate" class="input-group date datepicker ">
                                                                <p-calendar [monthNavigator]="true"
                                                                    [yearNavigator]="true"
                                                                    yearRange="2016:{{currentYear}}" class="form-control"
                                                                    dateFormat="dd-mm-yy" [showIcon]="true"
                                                                    formControlName="pt1"
                                                                    dataType="string"></p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label for="">End</label>
                                                            <div id="pt2toDate" class="input-group date datepicker ">
                                                                <p-calendar dateFormat="dd-mm-yy" class="form-control"
                                                                    [monthNavigator]="true" [yearNavigator]="true"
                                                                    yearRange="2016:{{currentYear}}" [showIcon]="true"
                                                                    formControlName="pt2"
                                                                    dataType="string"></p-calendar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="filterType == 'Person Responsible'">
                                                    <label for="exampleSelectGender">Person Responsible</label>
                                                    <select class="form-control form-select " id="personResponsible1"
                                                        formControlName="pr">
                                                        <option [value]="null" disabled>-- Select --</option>
                                                        <ng-container *ngFor="let obj of userRoleLs">
                                                            <option [value]="obj.userEmailId">{{obj.userEmailId}}
                                                            </option>
                                                        </ng-container>
                                                    </select>
                                                </div>
                                                <div *ngIf="filterType == 'Registration Number'">
                                                    <label for="exampleSelectGender">Registration Number</label>
                                                    <ng-multiselect-dropdown
                                                        [placeholder]="'Select Registration Number'"
                                                        [settings]="dropdownSettings" [data]="preGstregGstinData"
                                                        formControlName="regNo">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                                <div *ngIf="filterType == 'Status'">
                                                    <label for="exampleSelectGender">Status</label>
                                                    <ng-multiselect-dropdown formControlName="st" [placeholder]="'Select Status'"
                                                      [settings]="dropdownSettings" [data]="filterStatus">
                                                    </ng-multiselect-dropdown>
                                                  </div>
                                                <div *ngIf="filterType == 'State'">
                                                    <label for="exampleSelectGender">State</label>
                                                    <ng-multiselect-dropdown formControlName="state"
                                                        [placeholder]="'Select Status'" [settings]="dropdownSettings"
                                                        [data]="stateNames">
                                                    </ng-multiselect-dropdown>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn_primary_theme font-size-16 ms-5"
                                        (click)="clearAll()">
                                        Clear All
                                    </button>
                                    <button type="button" class="btn btn_primary_theme font-size-16"
                                        (click)="applyFilter(true)">
                                        Apply
                                    </button>
                                </div>

                            </ng-template>
                            <!-- delete modal -->
                            <ng-template #deletePop let-modal>
                                <div class="modal-header">
                                    <h4 class="modal-title">
                                        <p style="color: red;">WARNING!</p> The case once deleted it will get deleted
                                        permanently
                                    </h4>
                                    <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true" style="color: red;">×</span>
                                    </button>
                                </div>
                                <div class="modal-body m-10">
                                    <form #myForm="ngForm" novalidate>
                                        <div class="custom-control custom-radio">
                                            <input id="current-forum" type="radio" class="custom-control-input"
                                                [(ngModel)]="deleteForum" value="current-forum" name="case" ngModel
                                                required>
                                            <label class="custom-control-label" for="current-forum">Do you want to
                                                delete the case from
                                                <span class="bold-font">Current Forum?</span>
                                            </label>
                                        </div>
                                        <!-- AT -->
                                        <div class="custom-control custom-radio">
                                            <input id="entire-case" type="radio" class="custom-control-input"
                                                [(ngModel)]="deleteForum" value="entire-case" name="case" ngModel
                                                required>
                                            <label class="custom-control-label" for="entire-case">Do you want to delete
                                                the
                                                <span class="bold-font">Entire case?</span> </label>
                                        </div>
                                        <br>
                                        <div class="submit-footer">
                                            <button type="submit" class="btn btn-outline-primary mr-1 "
                                                [disabled]="!myForm.value.case" (click)="submitForm()">Yes</button>

                                            <button type="submit" class="btn btn-outline-primary mr-1 "
                                                aria-label="Close" (click)="modal.close('No click')">NO</button>
                                        </div>
                                    </form>
                                </div>
                            </ng-template>
                            <!-- delete modal -->
                            <div class="mt-4">
                                <div class="accordion" id="accordion" role="tablist">
                                    <div class="card border-bottom">
                                        <div class="card-header nopadding" role="tab" id="heading-1">
                                            <h6 class="mb-0">
                                                <a id="pre-collapseToggle-1" data-toggle="collapse" href="#pre-collapse-1" aria-expanded="false"
                                                    aria-controls="collapse-1" (click)="toggleForumTab('preaja'); isFilterApplied('preaja')">
                                                    Adjudication Stage <span
                                                        class="AccCount">({{cntPreAJAOp}}/{{cntPreAJA}})</span>
                                                </a>
                                            </h6>
                                        </div>

                                        <div id="pre-collapse-1" class="collapse" role="tabpanel"
                                            aria-labelledby="heading-1" data-parent="#accordion">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12 nopadding mb10">
                                                        <a class="pr5 iris-gst-pull-right pt-2"
                                                            (click)="createCase('create', 'preaja', 'tab1')">
                                                            <span class="badge badge-outline-success"
                                                                title="Create Case File"><i class="fa fa-pencil-square "
                                                                    *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"></i></span>
                                                        </a>
                                                    </div>
                                                    <div class="table-responsive nopadding">
                                                        <table class="table table-bordered table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width:181px;"><span>Case ID</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.caseId"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.CASE_ID)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.caseId"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.CASE_ID)"></i>
                                                                    </th>
                                                                    <th><span>State</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.state"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.STATE)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.state"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.STATE)"></i>
                                                                    </th>
                                                                    <th style="width:92px;"><span>Act Name</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.actType"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.ACT)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.actType"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.ACT)"></i>
                                                                    </th>
                                                                    <th><span>Case Label</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.caseLabel"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.CASE_LABEL)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.caseLabel"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.CASE_LABEL)"></i>
                                                                    </th>
                                                                    <!-- <th>Keyword</th> -->
                                                                    <th style="width: 107px;"><span>Period From</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.periodFromDate"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.PERIOD_FROM)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.periodFromDate"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.PERIOD_FROM)"></i>
                                                                    </th>
                                                                    <th style="width: 107px;"><span>Period To</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.periodToDate"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.PERIOD_TO)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.periodToDate"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.PERIOD_TO)"></i>
                                                                    </th>
                                                                    <th style="width: 107px;"><span>Demand (₹)</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.totOfTotal1"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.DEMAND)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.totOfTotal1"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.DEMAND)"></i>
                                                                    </th>
                                                                    <!-- <th>Due date to Appeal</th> -->
                                                                    <th><span>Current Status</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.status"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.STATUS)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.status"
                                                                            (click)="getPreGstSumm('preaja', preLitigationSort.STATUS)"></i>
                                                                    </th>
                                                                    <!-- <th>Person Responsible</th> -->
                                                                    <!-- <th>Notice Copy</th> -->
                                                                    <th style="width:121px;">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <ng-container
                                                                    *ngFor="let obj of SumofAJA | paginate: tableSection1Config;"
                                                                    let i="index">
                                                                    <tr>
                                                                        <td><a
                                                                                (click)="getCaseData(obj.caseId, 'view', 'preaja', 'tab1')">{{obj.caseId}}</a>
                                                                        </td>
                                                                        <td>{{obj.state | uppercase}}</td>
                                                                        <td>{{obj.actType}}</td>
                                                                        <!-- <td class="tdElpsis" title="{{getKeyword(obj)}}">{{getKeyword(obj)}}</td> -->
                                                                        <td>{{obj.caseLabel}}</td>
                                                                        <td>{{obj.periodFromDate}}</td>
                                                                        <td>{{obj.periodToDate}}</td>
                                                                        <td class="txtRight">{{obj.totOfTotal1 | INR:
                                                                            0}}</td>
                                                                        <!-- <td class="txtRight">
                                                                            <span *ngIf="obj.actType == 'EXCISE'">{{(totOfTotal1)  | currency:'₹':'':'1.0'}}</span>
                                                                            <span *ngIf="obj.actType == 'SERVICE TAX'">{{(obj.serviceTax1Total + obj.ec1Total + obj.shec1Total + obj.kkc1Total + obj.sbc1Total + obj.interest1Total + obj.penalty1Total)  | currency:'₹':'':'1.0'}}</span>
                                                                            <span *ngIf="obj.actType == 'VAT'">{{(obj.vat1Total + obj.interest1Total + obj.penalty1Total)  | currency:'₹':'':'1.0'}}</span>
                                                                            <span *ngIf="obj.actType == 'CST'">{{(obj.cst1Total + obj.interest1Total + obj.penalty1Total)  | currency:'₹':'':'1.0'}}</span>
                                                                            <span *ngIf="obj.actType == 'CUSTOMS DUTY'">{{(obj.bcd1Total + obj.interest1Total + obj.penalty1Total)  | currency:'₹':'':'1.0'}}</span>
                                                                        </td> -->
                                                                        <!-- <td>{{obj.dueDateOfReply}}</td> -->
                                                                        <td>
                                                                            <label class="badge badge-success"
                                                                                (click)="currentStatusFilter(obj.status,'preaja')"
                                                                                *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning"
                                                                                (click)="currentStatusFilter(obj.status,'preaja')"
                                                                                *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR REPLY"
                                                                                (click)="currentStatusFilter(obj.status,'preaja')"
                                                                                *ngIf="obj.status == 'PENDING FOR REPLY'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR HEARING"
                                                                                (click)="currentStatusFilter(obj.status,'preaja')"
                                                                                *ngIf="obj.status == 'PENDING FOR HEARING'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR ORDER"
                                                                                (click)="currentStatusFilter(obj.status,'preaja')"
                                                                                *ngIf="obj.status == 'PENDING FOR ORDER'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="APPEAL YET TO BE FILED"
                                                                                (click)="currentStatusFilter(obj.status,'preaja')"
                                                                                *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="CALL BOOK"
                                                                                (click)="currentStatusFilter(obj.status,'preaja')"
                                                                                *ngIf="obj.status == 'CALL BOOK'">{{obj.status}}</label>

                                                                        </td>
                                                                        <td>
                                                                            <a class="pr5"
                                                                                (click)="openDetailPop(deletePop, 'preaja',obj.caseId)"
                                                                                *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                                                                    class="badge badge-outline-trash "
                                                                                    title="Delete"><i
                                                                                        class="fa fa-trash"></i></span>
                                                                            </a>
                                                                            <a class="pr5"
                                                                                (click)="getCaseData(obj.caseId, 'view', 'preaja', 'tab1')"><span
                                                                                    class="badge badge-outline-success "
                                                                                    title="View"><i
                                                                                        class="fa fa-eye "></i></span></a>
                                                                            <a class="pr5" *ngIf="this.pan == obj.pan"
                                                                                (click)="getCaseData(obj.caseId, 'update', 'preaja', 'tab1')"><span
                                                                                    class="badge badge-outline-primary "
                                                                                    title="Edit"><i
                                                                                        class="fa fa-pencil-square-o "
                                                                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"></i></span>
                                                                                    </a>
                                                                                    <!-- <a (click)="navDownload(obj.caseId, 'PREGST')"
                                                                                            *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                                                                            class="pr5"><span class="badge badge-outline-success " style="border-color: #e88e30;"
                                                                                            title="Litigation Report"><i
                                                                                              style="color: #e88e30;"  class="fa fa-file "></i></span>
                                                                                            </a>
                                                                                            class="pr5"><span class="badge badge-outline-success " title="Download"><i
                                                                                                class="fa fa-file "></i></span>
                                                                                            </a> -->

                                                                                            
                                                                        </td>
                                                                    </tr>

                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row noMargin">
                                                    <div class="col-md-10 nopadding">
                                                        <pagination-controls (pageChange)="onTableDataChangeAja($event)"
                                                            id="tableSection1Config"></pagination-controls>
                                                    </div>
                                                    <div class="col-md-2 nopadding">
                                                        <select class="form-select jumptoPagination"
                                                            [(ngModel)]="perPageLimitSection1"
                                                            (ngModelChange)="getAjaData(perPageLimitSection1,1,'select')"
                                                            id="authority">
                                                            <option value="10">10</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="1000">1000</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border-bottom">
                                        <div class="card-header nopadding" role="tab" id="heading-2">
                                            <h6 class="mb-0">
                                                <a id="pre-collapseToggle-2" class="collapsed" data-toggle="collapse" href="#pre-collapse-2"
                                                    aria-expanded="false" aria-controls="collapse-2"
                                                    (click)="toggleForumTab('preapa');isFilterApplied('preapa')">
                                                    Commissioner (A) <span
                                                        class="AccCount">({{cntPreAPAOp}}/{{cntPreAPA}})</span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="pre-collapse-2" class="collapse " role="tabpanel"
                                            aria-labelledby="heading-2" data-parent="#accordion">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12 nopadding mb10">
                                                        <a class="pr5 iris-gst-pull-right pt-2"
                                                            (click)="createCase('create', 'preapa', 'tab2')">
                                                            <span class="badge badge-outline-success"
                                                                title="Create Case File"><i class="fa fa-pencil-square "
                                                                    *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"></i></span>
                                                        </a>
                                                    </div>
                                                    <div class="table-responsive nopadding">
                                                        <table class="table table-bordered table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width:181px;"><span>Case ID</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.caseId"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.CASE_ID)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.caseId"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.CASE_ID)"></i>
                                                                    </th>
                                                                    <th><span>State</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.state"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.STATE)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.state"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.STATE)"></i>
                                                                    </th>
                                                                    <th style="width:92px;"><span>Act Name</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.actType"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.ACT)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.actType"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.ACT)"></i>
                                                                    </th>
                                                                    <th><span>Case Label</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.caseLabel"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.CASE_LABEL)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.caseLabel"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.CASE_LABEL)"></i>
                                                                    </th>
                                                                    <!-- <th>Keyword</th> -->
                                                                    <th style="width: 107px;"><span>Period From</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.periodFromDate"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.PERIOD_FROM)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.periodFromDate"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.PERIOD_FROM)"></i>
                                                                    </th>
                                                                    <th style="width: 107px;"><span>Period To</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.periodToDate"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.PERIOD_TO)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.periodToDate"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.PERIOD_TO)"></i>
                                                                    </th>
                                                                    <th style="width: 107px;"><span>Demand (₹)</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.totOfTotal1"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.DEMAND)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.totOfTotal1"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.DEMAND)"></i>
                                                                    </th>
                                                                    <!-- <th>Due date to Appeal</th> -->
                                                                    <th><span>Current Status</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.status"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.STATUS)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.status"
                                                                            (click)="getPreGstSumm('preapa', preLitigationSort.STATUS)"></i>
                                                                    </th>
                                                                    <!-- <th>Order in Appeal</th> -->
                                                                    <th style="width:121px;">Action</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                <ng-container
                                                                    *ngFor="let obj of SumofAPA | paginate: tableSection2Config ;"
                                                                    let i="index">
                                                                    <tr>
                                                                        <td><a
                                                                                (click)="getCaseData(obj.caseId, 'view', 'preapa', 'tab2')">{{obj.caseId}}</a>
                                                                        </td>
                                                                        <td>{{obj.state | uppercase}}</td>
                                                                        <td>{{obj.actType}}</td>
                                                                        <!-- <td class="tdElpsis" title="{{getKeyword(obj)}}">{{getKeyword(obj)}}</td> -->
                                                                        <td>{{obj.caseLabel}}</td>
                                                                        <td>{{obj.periodFromDate}}</td>
                                                                        <td>{{obj.periodToDate}}</td>
                                                                        <td class="txtRight">{{obj.totOfTotal1 | INR:
                                                                            0}}</td>
                                                                        <td>
                                                                            <label class="badge badge-success"
                                                                                (click)="currentStatusFilter(obj.status,'preapa')"
                                                                                *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning"
                                                                                (click)="currentStatusFilter(obj.status,'preapa')"
                                                                                *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR REPLY"
                                                                                (click)="currentStatusFilter(obj.status,'preapa')"
                                                                                *ngIf="obj.status == 'PENDING FOR REPLY'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR HEARING"
                                                                                (click)="currentStatusFilter(obj.status,'preapa')"
                                                                                *ngIf="obj.status == 'PENDING FOR HEARING'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR ORDER"
                                                                                (click)="currentStatusFilter(obj.status,'preapa')"
                                                                                *ngIf="obj.status == 'PENDING FOR ORDER'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="APPEAL YET TO BE FILED"
                                                                                (click)="currentStatusFilter(obj.status,'preapa')"
                                                                                *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="CALL BOOK"
                                                                                (click)="currentStatusFilter(obj.status,'preapa')"
                                                                                *ngIf="obj.status == 'CALL BOOK'">{{obj.status}}</label>

                                                                        </td>
                                                                        <td>
                                                                            <a class="pr5"
                                                                                (click)="openDetailPop(deletePop, 'preapa',obj.caseId)"
                                                                                *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                                                                    class="badge badge-outline-trash "
                                                                                    title="Delete"><i
                                                                                        class="fa fa-trash"></i></span>
                                                                            </a>
                                                                            <a class="pr5"
                                                                                (click)="getCaseData(obj.caseId, 'view', 'preapa', 'tab2')"><span
                                                                                    class="badge badge-outline-success "
                                                                                    title="View"><i
                                                                                        class="fa fa-eye "></i></span></a>
                                                                            <a class="pr5" *ngIf="this.pan == obj.pan"
                                                                                (click)="getCaseData(obj.caseId, 'update', 'preapa', 'tab2')"><span
                                                                                    class="badge badge-outline-primary "
                                                                                    title="Edit"><i
                                                                                        class="fa fa-pencil-square-o "
                                                                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"></i></span></a>

                                                                                        <!-- <a (click)="navDownload(obj.caseId, 'PREGST')"
                                                                                            *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                                                                            class="pr5"><span class="badge badge-outline-success " style="border-color: #e88e30;"
                                                                                            title="Litigation Report"><i
                                                                                              style="color: #e88e30;"  class="fa fa-file "></i></span>
                                                                                            </a>
                                                                                            class="pr5"><span class="badge badge-outline-success " title="Download"><i
                                                                                                class="fa fa-file "></i></span>
                                                                                            </a> -->
                                                                        </td>
                                                                    </tr>

                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row noMargin">
                                                    <div class="col-md-10 nopadding">
                                                        <pagination-controls (pageChange)="onTableDataChangeApa($event)"
                                                            id="tableSection2Config"></pagination-controls>
                                                    </div>
                                                    <div class="col-md-2 nopadding">
                                                        <!-- <select id="itemsPerPage" [(ngModel)]="config.itemsPerPage">
                                                      <option *ngFor="let option of [5, 10, 20, 50]">{{ option }}</option>
                                                    </select> -->
                                                        <select class="form-select jumptoPagination"
                                                            [(ngModel)]="perPageLimitSection2"
                                                            (ngModelChange)="getApaData(perPageLimitSection2,1,'select')"
                                                            id="authority">
                                                            <option value="10">10</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="1000">1000</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-bottom">
                                        <div class="card-header nopadding" role="tab" id="heading-3">
                                            <h6 class="mb-0">
                                                <a id="pre-collapseToggle-3" class="collapsed" data-toggle="collapse" href="#pre-collapse-3"
                                                    aria-expanded="false" aria-controls="collapse-3"
                                                    (click)="toggleForumTab('preapt');isFilterApplied('preapt')">
                                                    Appellate Tribunal <span
                                                        class="AccCount">({{cntPreAPTOp}}/{{cntPreAPT}})</span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="pre-collapse-3" class="collapse" role="tabpanel"
                                            aria-labelledby="heading-3" data-parent="#accordion">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12 nopadding mb10">
                                                        <a class="pr5 iris-gst-pull-right pt-2"
                                                            (click)="createCase('create', 'preapt', 'tab3')">
                                                            <span class="badge badge-outline-success"
                                                                title="Create Case File"><i class="fa fa-pencil-square "
                                                                    *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"></i></span>
                                                        </a>
                                                    </div>
                                                    <div class="table-responsive nopadding">
                                                        <table class="table table-bordered table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width:181px;"><span>Case ID</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.caseId"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.CASE_ID)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.caseId"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.CASE_ID)"></i>
                                                                    </th>
                                                                    <th><span>State</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.state"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.STATE)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.state"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.STATE)"></i>
                                                                    </th>
                                                                    <th style="width:92px;"><span>Act Name</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.actType"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.ACT)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.actType"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.ACT)"></i>
                                                                    </th>
                                                                    <th><span>Case Label</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.caseLabel"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.CASE_LABEL)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.caseLabel"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.CASE_LABEL)"></i>
                                                                    </th>
                                                                    <!-- <th>Keyword</th> -->
                                                                    <th style="width: 107px;"><span>Period From</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.periodFromDate"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.PERIOD_FROM)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.periodFromDate"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.PERIOD_FROM)"></i>
                                                                    </th>
                                                                    <th style="width: 107px;"><span>Period To</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.periodToDate"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.PERIOD_TO)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.periodToDate"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.PERIOD_TO)"></i>
                                                                    </th>
                                                                    <th style="width: 107px;"><span>Demand (₹)</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.totOfTotal1"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.DEMAND)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.totOfTotal1"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.DEMAND)"></i>
                                                                    </th>
                                                                    <th><span>Current Status</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.status"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.STATUS)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.status"
                                                                            (click)="getPreGstSumm('preapt', preLitigationSort.STATUS)"></i>
                                                                    </th>
                                                                    <th style="width:118px;">Action</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                <ng-container
                                                                    *ngFor="let obj of SumofAPT | paginate: tableSection3Config;"
                                                                    let i="index">
                                                                    <tr>
                                                                        <td><a
                                                                                (click)="getCaseData(obj.caseId, 'view', 'preapt', 'tab3')">{{obj.caseId}}</a>
                                                                        </td>
                                                                        <td>{{obj.state | uppercase}}</td>
                                                                        <td>{{obj.actType}}</td>
                                                                        <td>{{obj.caseLabel}}</td>
                                                                        <td>{{obj.periodFromDate}}</td>
                                                                        <td>{{obj.periodToDate}}</td>
                                                                        <td class="txtRight">{{obj.totOfTotal1 | INR:
                                                                            0}}</td>
                                                                        <td>
                                                                            <label class="badge badge-success"
                                                                                (click)="currentStatusFilter(obj.status,'preapt')"
                                                                                *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning"
                                                                                (click)="currentStatusFilter(obj.status,'preapt')"
                                                                                *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR REPLY"
                                                                                (click)="currentStatusFilter(obj.status,'preapt')"
                                                                                *ngIf="obj.status == 'PENDING FOR REPLY'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR HEARING"
                                                                                (click)="currentStatusFilter(obj.status,'preapt')"
                                                                                *ngIf="obj.status == 'PENDING FOR HEARING'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR ORDER"
                                                                                (click)="currentStatusFilter(obj.status,'preapt')"
                                                                                *ngIf="obj.status == 'PENDING FOR ORDER'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="APPEAL YET TO BE FILED"
                                                                                (click)="currentStatusFilter(obj.status,'preapt')"
                                                                                *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="CALL BOOK"
                                                                                (click)="currentStatusFilter(obj.status,'preapt')"
                                                                                *ngIf="obj.status == 'CALL BOOK'">{{obj.status}}</label>

                                                                        </td>
                                                                        <td>
                                                                            <a class="pr5"
                                                                                (click)="openDetailPop(deletePop, 'preapt',obj.caseId)"
                                                                                *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                                                                    class="badge badge-outline-trash "
                                                                                    title="Delete"><i
                                                                                        class="fa fa-trash"></i></span>
                                                                            </a>
                                                                            <a class="pr5"
                                                                                (click)="getCaseData(obj.caseId, 'view', 'preapt', 'tab3')"><span
                                                                                    class="badge badge-outline-success "
                                                                                    title="View"><i
                                                                                        class="fa fa-eye "></i></span></a>
                                                                            <a class="pr5" *ngIf="this.pan == obj.pan"
                                                                                (click)="getCaseData(obj.caseId, 'update', 'preapt', 'tab3')"><span
                                                                                    class="badge badge-outline-primary "
                                                                                    title="Edit"><i
                                                                                        class="fa fa-pencil-square-o "
                                                                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"></i></span></a>

                                                                                        <!-- <a (click)="navDownload(obj.caseId, 'PREGST')"
                                                                                            *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                                                                            class="pr5"><span class="badge badge-outline-success " style="border-color: #e88e30;"
                                                                                            title="Litigation Report"><i
                                                                                              style="color: #e88e30;"  class="fa fa-file "></i></span>
                                                                                            </a>
                                                                                            class="pr5"><span class="badge badge-outline-success " title="Download"><i
                                                                                                class="fa fa-file "></i></span>
                                                                                            </a> -->
                                                                        </td>
                                                                    </tr>

                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row noMargin">
                                                    <div class="col-md-10 nopadding">
                                                        <pagination-controls (pageChange)="onTableDataChangeApt($event)"
                                                            id="tableSection3Config"></pagination-controls>
                                                    </div>
                                                    <div class="col-md-2 nopadding">
                                                        <select class="form-select jumptoPagination"
                                                            [(ngModel)]="perPageLimitSection3"
                                                            (ngModelChange)="getAptData(perPageLimitSection3,1,'select')"
                                                            id="authority">
                                                            <option value="10">10</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="1000">1000</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card border-bottom">
                                        <div class="card-header nopadding" role="tab" id="heading-4">
                                            <h6 class="mb-0">
                                                <a id="pre-collapseToggle-4" class="collapsed" data-toggle="collapse" href="#pre-collapse-4"
                                                    aria-expanded="false" aria-controls="collapse-4"
                                                    (click)="toggleForumTab('prehc'); isFilterApplied('prehc')">
                                                    High Court <span
                                                        class="AccCount">({{cntPreHCOp}}/{{cntPreHC}})</span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="pre-collapse-4" class="collapse" role="tabpanel"
                                            aria-labelledby="heading-4" data-parent="#accordion">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12 nopadding mb10">
                                                        <a class="pr5 iris-gst-pull-right pt-2"
                                                            (click)="createCase('create', 'prehc', 'tab4')">
                                                            <span class="badge badge-outline-success"
                                                                title="Create Case File"><i class="fa fa-pencil-square "
                                                                    *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"></i></span>
                                                        </a>
                                                    </div>
                                                    <div class="table-responsive nopadding">
                                                        <table class="table table-bordered table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width:181px;"><span>Case ID</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.caseId"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.CASE_ID)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.caseId"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.CASE_ID)"></i>
                                                                    </th>
                                                                    <th><span>State</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.state"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.STATE)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.state"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.STATE)"></i>
                                                                    </th>
                                                                    <th style="width:92px;"><span>Act Name</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.actType"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.ACT)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.actType"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.ACT)"></i>
                                                                    </th>
                                                                    <th><span>Case Label</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.caseLabel"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.CASE_LABEL)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.caseLabel"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.CASE_LABEL)"></i>
                                                                    </th>
                                                                    <!-- <th>Keyword</th> -->
                                                                    <th style="width: 107px;"><span>Period From</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.periodFromDate"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.PERIOD_FROM)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.periodFromDate"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.PERIOD_FROM)"></i>
                                                                    </th>
                                                                    <th style="width: 107px;"><span>Period To</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.periodToDate"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.PERIOD_TO)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.periodToDate"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.PERIOD_TO)"></i>
                                                                    </th>
                                                                    <th style="width: 107px;"><span>Demand (₹)</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.totOfTotal1"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.DEMAND)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.totOfTotal1"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.DEMAND)"></i>
                                                                    </th>
                                                                    <th><span>Current Status</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.status"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.STATUS)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.status"
                                                                            (click)="getPreGstSumm('prehc', preLitigationSort.STATUS)"></i>
                                                                    </th>
                                                                    <!-- <th>Person Responsible</th> -->
                                                                    <!-- <th>Notice Copy</th> -->
                                                                    <th style="width:118px;">Action</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                <ng-container
                                                                    *ngFor="let obj of SumofHC | paginate: tableSection4Config;"
                                                                    let i="index">
                                                                    <tr>
                                                                        <td><a
                                                                                (click)="getCaseData(obj.caseId, 'view', 'prehc', 'tab4')">{{obj.caseId}}</a>
                                                                        </td>
                                                                        <td>{{obj.state | uppercase}}</td>
                                                                        <td>{{obj.actType}}</td>
                                                                        <td>{{obj.caseLabel}}</td>
                                                                        <td>{{obj.periodFromDate}}</td>
                                                                        <td>{{obj.periodToDate}}</td>
                                                                        <td class="txtRight">{{obj.totOfTotal1 | INR:
                                                                            0}}</td>
                                                                        <td>
                                                                            <label class="badge badge-success"
                                                                                (click)="currentStatusFilter(obj.status,'prehc')"
                                                                                *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning"
                                                                                (click)="currentStatusFilter(obj.status,'prehc')"
                                                                                *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR REPLY"
                                                                                (click)="currentStatusFilter(obj.status,'prehc')"
                                                                                *ngIf="obj.status == 'PENDING FOR REPLY'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR HEARING"
                                                                                (click)="currentStatusFilter(obj.status,'prehc')"
                                                                                *ngIf="obj.status == 'PENDING FOR HEARING'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR ORDER"
                                                                                (click)="currentStatusFilter(obj.status,'prehc')"
                                                                                *ngIf="obj.status == 'PENDING FOR ORDER'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="APPEAL YET TO BE FILED"
                                                                                (click)="currentStatusFilter(obj.status,'prehc')"
                                                                                *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="CALL BOOK"
                                                                                (click)="currentStatusFilter(obj.status,'prehc')"
                                                                                *ngIf="obj.status == 'CALL BOOK'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PETITION YET TO BE FILED"
                                                                                (click)="currentStatusFilter(obj.status,'prehc')"
                                                                                *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>

                                                                        </td>
                                                                        <td>
                                                                            <a class="pr5"
                                                                                (click)="openDetailPop(deletePop, 'prehc',obj.caseId)"
                                                                                *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                                                                    class="badge badge-outline-trash "
                                                                                    title="Delete"><i
                                                                                        class="fa fa-trash"></i></span>
                                                                            </a>
                                                                            <a class="pr5"
                                                                                (click)="getCaseData(obj.caseId, 'view', 'prehc', 'tab4')"><span
                                                                                    class="badge badge-outline-success "
                                                                                    title="View"><i
                                                                                        class="fa fa-eye "></i></span></a>
                                                                            <a class="pr5" *ngIf="this.pan == obj.pan"
                                                                                (click)="getCaseData(obj.caseId, 'update', 'prehc', 'tab4')"><span
                                                                                    class="badge badge-outline-primary "
                                                                                    title="Edit"><i
                                                                                        class="fa fa-pencil-square-o "
                                                                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"></i></span></a>

                                                                                        <!-- <a (click)="navDownload(obj.caseId, 'PREGST')"
                                                                                            *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                                                                            class="pr5"><span class="badge badge-outline-success " style="border-color: #e88e30;"
                                                                                            title="Litigation Report"><i
                                                                                              style="color: #e88e30;"  class="fa fa-file "></i></span>
                                                                                            </a>
                                                                                            class="pr5"><span class="badge badge-outline-success " title="Download"><i
                                                                                                class="fa fa-file "></i></span>
                                                                                            </a> -->
                                                                        </td>
                                                                    </tr>

                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row noMargin">
                                                    <div class="col-md-10 nopadding">
                                                        <pagination-controls (pageChange)="onTableDataChangeHc($event)"
                                                            id="tableSection4Config"></pagination-controls>
                                                    </div>
                                                    <div class="col-md-2 nopadding">
                                                        <!-- <select id="itemsPerPage" [(ngModel)]="config.itemsPerPage">
                                                      <option *ngFor="let option of [5, 10, 20, 50]">{{ option }}</option>
                                                    </select> -->
                                                        <select class="form-select jumptoPagination"
                                                            [(ngModel)]="perPageLimitSection4"
                                                            (ngModelChange)="getHcData(perPageLimitSection4,1,'select')"
                                                            id="authority">
                                                            <option value="10">10</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="1000">1000</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="card border-bottom">
                                        <div class="card-header nopadding" role="tab" id="heading-5">
                                            <h6 class="mb-0">
                                                <a id="pre-collapseToggle-5" class="collapsed" data-toggle="collapse" href="#pre-collapse-5"
                                                    aria-expanded="false" aria-controls="collapse-5"
                                                    (click)="toggleForumTab('presc'); isFilterApplied('presc')">
                                                    Supreme Court <span
                                                        class="AccCount">({{cntPreSCOp}}/{{cntPreSC}})</span>
                                                </a>
                                            </h6>
                                        </div>
                                        <div id="pre-collapse-5" class="collapse" role="tabpanel"
                                            aria-labelledby="heading-5" data-parent="#accordion">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12 nopadding mb10">
                                                        <a class="pr5 iris-gst-pull-right pt-2"
                                                            (click)="createCase('create', 'presc', 'tab5')">
                                                            <span class="badge badge-outline-success"
                                                                title="Create Case File"><i class="fa fa-pencil-square "
                                                                    *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"></i></span>
                                                        </a>
                                                    </div>
                                                    <div class="table-responsive nopadding">
                                                        <table class="table table-bordered table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th style="width:181x;"><span>Case ID</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.caseId"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.CASE_ID)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.caseId"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.CASE_ID)"></i>
                                                                    </th>
                                                                    <th><span>State</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.state"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.STATE)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.state"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.STATE)"></i>
                                                                    </th>
                                                                    <th style="width:92px;"><span>Act Name</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.actType"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.ACT)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.actType"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.ACT)"></i>
                                                                    </th>
                                                                    <th><span>Case Label</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.caseLabel"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.CASE_LABEL)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.caseLabel"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.CASE_LABEL)"></i>
                                                                    </th>
                                                                    <!-- <th>Keyword</th> -->
                                                                    <th style="width: 107px;"><span>Period From</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.periodFromDate"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.PERIOD_FROM)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.periodFromDate"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.PERIOD_FROM)"></i>
                                                                    </th>
                                                                    <th style="width: 107px;"><span>Period To</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.periodToDate"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.PERIOD_TO)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.periodToDate"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.PERIOD_TO)"></i>
                                                                    </th>
                                                                    <th style="width: 107px;"><span>Demand (₹)</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="!sorting.totOfTotal1"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.DEMAND)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true"
                                                                            *ngIf="sorting.totOfTotal1"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.DEMAND)"></i>
                                                                    </th>
                                                                    <th><span>Current Status</span>
                                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                                            aria-hidden="true" *ngIf="!sorting.status"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.STATUS)"></i>
                                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                                            aria-hidden="true" *ngIf="sorting.status"
                                                                            (click)="getPreGstSumm('presc', preLitigationSort.STATUS)"></i>
                                                                    </th>
                                                                    <th style="width:114px;">Action</th>
                                                                </tr>

                                                            </thead>
                                                            <tbody>
                                                                <ng-container
                                                                    *ngFor="let obj of SumofSC | paginate: tableSection5Config;"
                                                                    let i="index">
                                                                    <tr>
                                                                        <td><a
                                                                                (click)="getCaseData(obj.caseId, 'view','presc', 'tab5')">{{obj.caseId}}</a>
                                                                        </td>
                                                                        <td>{{obj.state | uppercase}}</td>
                                                                        <td>{{obj.actType}}</td>
                                                                        <td>{{obj.caseLabel}}</td>
                                                                        <td>{{obj.periodFromDate}}</td>
                                                                        <td>{{obj.periodToDate}}</td>
                                                                        <td class="txtRight">{{obj.totOfTotal1 | INR:
                                                                            0}}</td>
                                                                        <td>
                                                                            <label class="badge badge-success"
                                                                                (click)="currentStatusFilter(obj.status,'presc')"
                                                                                *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning"
                                                                                (click)="currentStatusFilter(obj.status,'presc')"
                                                                                *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR REPLY"
                                                                                (click)="currentStatusFilter(obj.status,'presc')"
                                                                                *ngIf="obj.status == 'PENDING FOR REPLY'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR HEARING"
                                                                                (click)="currentStatusFilter(obj.status,'presc')"
                                                                                *ngIf="obj.status == 'PENDING FOR HEARING'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PENDING FOR ORDER"
                                                                                (click)="currentStatusFilter(obj.status,'presc')"
                                                                                *ngIf="obj.status == 'PENDING FOR ORDER'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="APPEAL YET TO BE FILED"
                                                                                (click)="currentStatusFilter(obj.status,'presc')"
                                                                                *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="CALL BOOK"
                                                                                (click)="currentStatusFilter(obj.status,'presc')"
                                                                                *ngIf="obj.status == 'CALL BOOK'">{{obj.status}}</label>
                                                                            <label class="badge badge-warning tdElpsis"
                                                                                title="PETITION YET TO BE FILED"
                                                                                (click)="currentStatusFilter(obj.status,'presc')"
                                                                                *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                                                                style="min-width: 125px; padding: 3px;">{{obj.status}}</label>

                                                                        </td>
                                                                        <!-- <td>{{obj.deptCorrespondence}}--</td> -->
                                                                        <!-- <td class="tdElpsis">{{obj.personResponsible1}}</td> -->
                                                                        <!-- <td title="{{getFileNameDta(obj.noticeLoc)}}"><a (click)="download(obj.noticeLoc)">{{getFileNameDta(obj.noticeLoc)}}</a></td> -->
                                                                        <td>
                                                                            <a class="pr5"
                                                                                (click)="openDetailPop(deletePop, 'presc',obj.caseId)"
                                                                                *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                                                                    class="badge badge-outline-trash "
                                                                                    title="Delete"><i
                                                                                        class="fa fa-trash"></i></span>
                                                                            </a>
                                                                            <a class="pr5"
                                                                                (click)="getCaseData(obj.caseId, 'view', 'presc', 'tab5')"><span
                                                                                    class="badge badge-outline-success "
                                                                                    title="View"><i
                                                                                        class="fa fa-eye "></i></span></a>
                                                                            <a class="pr5" *ngIf="this.pan == obj.pan"
                                                                                (click)="getCaseData(obj.caseId, 'update', 'presc', 'tab5')"><span
                                                                                    class="badge badge-outline-primary "
                                                                                    title="Edit"><i
                                                                                        class="fa fa-pencil-square-o "
                                                                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"></i></span></a>
                                                                        
                                                                                        <!-- <a (click)="navDownload(obj.caseId, 'PREGST')"
                                                                                            *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                                                                            class="pr5"><span class="badge badge-outline-success " style="border-color: #e88e30;"
                                                                                            title="Litigation Report"><i
                                                                                              style="color: #e88e30;"  class="fa fa-file "></i></span>
                                                                                            </a>
                                                                                            class="pr5"><span class="badge badge-outline-success " title="Download"><i
                                                                                                class="fa fa-file "></i></span>
                                                                                            </a> -->
                                                                                    </td>
                                                                    </tr>

                                                                </ng-container>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row noMargin">
                                                    <div class="col-md-10 nopadding">
                                                        <pagination-controls (pageChange)="onTableDataChangeSc($event)"
                                                            id="tableSection5Config"></pagination-controls>
                                                    </div>
                                                    <div class="col-md-2 nopadding">
                                                        <!-- <select id="itemsPerPage" [(ngModel)]="config.itemsPerPage">
                                                      <option *ngFor="let option of [5, 10, 20, 50]">{{ option }}</option>
                                                    </select> -->
                                                        <select class="form-select jumptoPagination"
                                                            [(ngModel)]="perPageLimitSection5"
                                                            (ngModelChange)="getScData(perPageLimitSection5,1,'select')"
                                                            id="authority">
                                                            <option value="10">10</option>
                                                            <option value="50">50</option>
                                                            <option value="100">100</option>
                                                            <option value="1000">1000</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</div>
