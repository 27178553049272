import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, delayWhen, map, retryWhen, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, of, throwError, timer } from 'rxjs';
import { data } from 'jquery';

export interface DtNmStats {
  dtnoticesCount: number;
  adjournmentCount: number;
  videoConferencingCount: number;
  proceedingsCount: number;
  newDTNoticesCount: number;
  newAdjournmentCount: number;
  newVideoConferencingCount: number;
  newProceedingsCount: number;
}

@Injectable({
  providedIn: 'root'
})
export class NoticeServiceService {
  AccessTabData$ = new BehaviorSubject<any>({ scnFlag: false, ajaFlag: false, apaFlag: false, aptFlag: false, hcFlag: false, scFlag: false });
  dtNmStats: null | DtNmStats = null;
  rootCompanyId: number = Number(sessionStorage.getItem('companyId'));
  constructor(
    private http: HttpClient
  ) {

  }

  httpOptions = {
    withCredentials: true
  };

  private selectedFilling: Subject<any> = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('selectedFilling') || '{}'));
  selectedFilling$ = this.selectedFilling.asObservable();
  getCompanyId() {
    const navContext = JSON.parse(sessionStorage.getItem('navContext') ?? '{}');
    return navContext?.companyId ?? this.rootCompanyId;
  }
  modifyFilling(data: any) {
    this.selectedFilling.next(data);
  }
  saveOrgId(data: any) {
    return this.http.post(`${environment.apiUrl}/lms4/org/create`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  getOrgID(data: any) {
    return this.http.get(`${environment.apiUrl}/lms4/org/get?companyId=${data.companyId}`).pipe(map((resp) => {
      return resp;
    }));
  }

  getNoticeCount(data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/lms4/notice/count`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  getAdditionalNoticeCount(data:any) : Observable<any>{
    return this.http.post(`${environment.apiUrl}/lms4/count/additionalNotice`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  getSubmissionCount(data: any) {
    return this.http.post(`${environment.apiUrl}/lms4/submission/count`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  getAddNoticeCount(data: any) {
    return this.http.post(`${environment.apiUrl}/lms4/additionalNotice/count`, data).pipe(map((resp) => {
      return resp;
    }));
  }


  getNoticeData(data: any) {
    return this.http.post(`${environment.apiUrl}/lms4/notice/get`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  getSubmissionData(data: any) {
    return this.http.post(`${environment.apiUrl}/lms4/submission/get`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  getAddNoticeData(data: any) {
    return this.http.post(`${environment.apiUrl}/lms4/additionalNotice/get`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  updateNoticePr(data: any) {
    return this.http.put(`${environment.apiUrl}/lms4/notice/updateNotice`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  updateSubmissionPr(data: any) {
    return this.http.put(`${environment.apiUrl}/lms4/submission/updateSubmission`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  updateAddNoticePr(data: any) {
    return this.http.put(`${environment.apiUrl}/lms4/additionalNotice/updateNotice`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  // getNoticeDetail(data: any) {
  //   return this.http.get(`${environment.apiUrl}/lms4/notice/byApplnId?applnId=${data.applnId}&companyId=${data.companyId}`).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }
  // getSubmissionDetail(data: any) {
  //   return this.http.get(`${environment.apiUrl}/lms4/submission/byApplnId?applnId=${data.applnId}&companyId=${data.companyId}`).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }
  getAddNoticeDetail(data: any) {
    return this.http.post(`${environment.apiUrl}/lms4/additionalNotice/getAddtnlNoticeBy/caseId`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  getAddNoticeDwdList(data: any) {
    return this.http.post(`${environment.apiUrl}/lms4/additionalNotice/getAddtnlNoticeBy`,data).pipe(map((resp) => {
      return resp;
    }));
  }

  downloadANFile(urlData: any) {
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.get(`${environment.apiUrl}/lms4/download/files?s3Key=${urlData.s3Key}&id=${urlData.id}&section=ADDITIONALNOTICE`, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }
  downloadNFile(urlData: any) {
    //let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.get(`${environment.apiUrl}/lms4/download/files?s3Key=${urlData.s3Key}&id=${urlData.id}&section=NOTICE`, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }
  downloadEXNotice(urlData: any) {
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(`${environment.apiUrl}/lms4/download/notice`, urlData, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }
  downloadEXSub(urlData: any) {
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(`${environment.apiUrl}/lms4/download/submission`, urlData, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }
  downloadEXAddNotice(urlData: any) {
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(`${environment.apiUrl}/lms4/download/additionalNotice`, urlData, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }

  gstGstins(data: any) {
    return this.http.post(`${environment.apiUrl}/lms4/notice/getGstins`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  getDtNmCount(data: any) {
    return this.http.post(`${environment.apiUrl}/lms4/proceedingNotice/count`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  //DT Notice APIs
  // getDtNmCount(data: any): Observable<DtNmStats | null> {
  //   if (this.dtNmStats) return of(this.dtNmStats);

  //   return this.http.get(`${environment.apiUrl}/lms4/proceedingNotice/count?companyId=${data.companyId}`, data).pipe(map((resp) => {
  //     const { status, response } = <any>resp;
  //     this.dtNmStats = status === 1 ? response : null;
  //     return this.dtNmStats;
  //   }));
  // }

  getProceedingListData(data: any) {
    const payload = {
      ...data,
      companyId: this.getCompanyId(),
      sortdir: 'DESC',
      sortfield: 'lastResponseSubmittedOn',
    }
    return this.http
      .post(`${environment.apiUrl}/lms4/dt/query/proceeding`, payload).pipe(map((resp: any) => {
        return resp;
      })
      );
  }
  getNoticeListData(data: any) {
    const payload = {
      ...data,
      companyId: this.getCompanyId(),
      sortdir: 'DESC',
      sortfield: 'parsedIssuedOn',
    }
    return this.http
      .post(`${environment.apiUrl}/lms4/dt/query/notices`, payload).pipe(map((resp: any) => {
        return resp;
      })
      );
  }
  getadjListData(data: any) {
    const payload = {
      ...data,
      companyId: this.getCompanyId(),
      sortdir: 'DESC',
      sortfield: 'parsedRequestDate',
    }
    return this.http
      .post(`${environment.apiUrl}/lms4/dt/query/adjournments`, payload).pipe(map((resp: any) => {
        return resp;
      })
      );
  }
  getVcListData(data: any) {
    const payload = {
      ...data,
      companyId: this.getCompanyId(),
      sortdir: 'DESC',
      sortfield: 'vcAdjRqstDate',
    }
    return this.http
      .post(`${environment.apiUrl}/lms4/dt/query/videoConferencing`, payload).pipe(map((resp: any) => {
        return resp;
      })
      );
  }

  confirmDtUserAction(data: any) {
    return this.http.put(`${environment.apiUrl}/lms4/dt/update`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  getFilterValue(data: any) {
    const payload = {
      ...data,
      companyId: this.getCompanyId()
    }
    return this.http
      .post(`${environment.apiUrl}/lms4/dt/getSectionsAndItrTypes`, payload).pipe(map((resp: any) => {
        return resp;
      })
      );
  }
  dwdDtExportExcel(data: any) {
    const payload = {
      ...data,
      sortdir: 'DESC',
      companyId: this.getCompanyId()
    }
    return this.http.post(`${environment.apiUrl}/lms4/export/dtNotices`, payload, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }
  dwdDTNoticesFile(urlData: any) {
    //let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.get(`${environment.apiUrl}/lms4/download/dtFiles?s3Key=${urlData.s3Key}&id=${urlData.id}&section=DTNOTICE`, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }

  dwdDTVcFile(urlData: any) {
    //let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.get(`${environment.apiUrl}/lms4/download/dtFiles?s3Key=${urlData.s3Key}&id=${urlData.id}&section=DTVIDEOCONFERENCING`, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }

  dwdDTProcFile(urlData: any) {
    //let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.get(`${environment.apiUrl}/lms4/download/dtFiles?s3Key=${urlData.s3Key}&id=${urlData.id}&section=DTPROCEEDING`, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }

  //LMS Case ID update API
  updateLMSCaseId(criterias: any) {
    return this.http.put(`${environment.apiUrl}/lms4/api/updateCase`, criterias).pipe(map((resp) => {
      return resp;
    }));
  }
}
