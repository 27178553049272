<div class="panel-wrapper">
  <div class="panel-head">
    <div class="page-title">
      <h1><a [routerLink]="'/dtNmSummary'">Notice Management Direct Tax</a>>> Proceedings</h1>
      <div class="action-bar">

        <a title="Video Conferencing" [routerLink]="'/dtNmVc'" class="iris-gst-pull-right"><i
            class="fa fa-video-camera"></i><span>{{dtNmStats?.videoConferencingCount}}</span></a>
        <a title="Adjournment" [routerLink]="'/dtNmAdj'" class="iris-gst-pull-right"><i
            class="fa fa-legal"></i><span>{{dtNmStats?.adjournmentCount}}</span></a>
        <a title="Notices" [routerLink]="'/dtNmNotices'" class="iris-gst-pull-right "><i
            class="fa fa-file-text-o"></i><span>{{dtNmStats?.dtnoticesCount}}</span></a>
      </div>
    </div>
    <div class="filter-section">
      <div class="filters-bar">
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.assessmentYear.v !== null}">AY<span
              (click)="clearFilter('assessmentYear')" [ngClass]="{'highlightclose' : filters.assessmentYear.v !== null}"
              class="clearBtn"><i class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.assessmentYear.v" (ngModelChange)="proceedingListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let ay of ayrange">
              <option [ngValue]="ay.value">{{ ay.label }}</option>
            </ng-container>
          </select>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.itrType.v !== null}">ITR<span (click)="clearFilter('itrType')"
              [ngClass]="{'highlightclose' : filters.itrType.v !== null}" class="clearBtn"><i
                class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.itrType.v" (ngModelChange)="proceedingListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let itr of filterDropDown.itrType">
              <option [value]="itr">{{ itr }}</option>
            </ng-container>
          </select>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.noticeName.v !== null}">Proceeding Section<span
              (click)="clearFilter('noticeName')" [ngClass]="{'highlightclose' : filters.noticeName.v !== null}"
              class="clearBtn"><i class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.noticeName.v" (ngModelChange)="proceedingListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let sec of filterDropDown.noticeNames">
              <option [ngValue]="sec">{{ sec }}</option>
            </ng-container>
          </select>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.pan.v !== null}">PAN <span (click)="clearFilter('pan')"
              [ngClass]="{'highlightclose' : filters.pan.v !== null}" class="clearBtn"><i
                class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.pan.v" (ngModelChange)="proceedingListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let pans of filterDropDown.panName">
              <option [ngValue]="pans.pan">{{ pans.pan }} - {{pans.name}}</option>
            </ng-container>
          </select>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.proceedingStatus.v !== null}">Status <span
              (click)="clearFilter('proceedingStatus')"
              [ngClass]="{'highlightclose' : filters.proceedingStatus.v !== null}" class="clearBtn"><i
                class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.proceedingStatus.v" (ngModelChange)="proceedingListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let obj of status" container="body">
              <option [value]="obj.value">{{obj.label}}</option>
            </ng-container>
          </select>
        </div>

      </div>
      <p><span class="totalRecord">{{totalCount}}</span>
        <a class="iris-gst-pull-right " id="download" title="Export To Excel" (click)="downloadEXFile()"><i
            class="fa fa-file-excel-o  "></i> EXPORT TO EXCEL</a>
      </p>
    </div>


  </div>
  <div class="section nopadding" *ngIf="totalCount !== 0">
    <div class="row">

      <div class="col-md-12 ">
        <div class="table-responsive nopadding">
          <table class="table">
            <thead>
              <tr>

                <th>PAN
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.pan"
                    (click)="sort(proceedingsSort.PAN)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.pan"
                    (click)="sort(proceedingsSort.PAN)"></i> -->
                </th>
                <th>Proceeding Id
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.proceedingReqId"
                    (click)="sort(proceedingsSort.PROCEEDING_ID)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.proceedingReqId"
                    (click)="sort(proceedingsSort.PROCEEDING_ID)"></i> -->
                </th>
                <th>AY
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.assessmentYear"
                    (click)="sort(proceedingsSort.AY)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.assessmentYear"
                    (click)="sort(proceedingsSort.AY)"></i> -->
                </th>
                <th>Last Response Date
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.lastResponseSubmittedOn"
                    (click)="sort(proceedingsSort.LAST_RESPONSE_DATE)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.lastResponseSubmittedOn"
                    (click)="sort(proceedingsSort.LAST_RESPONSE_DATE)"></i> -->

                </th>
                <th>Proceeding Section
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.noticeName"
                    (click)="sort(proceedingsSort.PROCEEDING_SECTION)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.noticeName"
                    (click)="sort(proceedingsSort.PROCEEDING_SECTION)"></i> -->
                </th>
                <th>ITR
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.itrType"
                    (click)="sort(proceedingsSort.ITR)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.itrType"
                    (click)="sort(proceedingsSort.ITR)"></i> -->
                </th>
                <th>Status
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.proceedingStatus"
                    (click)="sort(proceedingsSort.STATUS)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.proceedingStatus"
                    (click)="sort(proceedingsSort.STATUS)"></i> -->
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let obj of proceedingData | paginate: proceedingConfig; " let i="index">
                <tr>
                  <td>
                    <p>{{obj.proceeding.pan}}</p>
                    <p class="subTd">{{obj.proceeding.panName}}</p>
                  </td>
                  <td>
                    <p><a class="anchor"
                        (click)="toProcDetail(obj.proceeding.proceedingReqId)">{{obj.proceeding.proceedingReqId}}</a>
                    </p>
                    <p class="subTd">{{obj.proceeding.proceedingName}}</p>
                  </td>
                  <td>{{obj.proceeding.assessmentYear}}</td>
                  <td>{{obj.proceeding.lastResponseSubmittedOn}}</td>
                  <td>{{obj.proceeding.noticeName}}</td>
                  <td>{{obj.proceeding.itrType}}</td>
                  <td>
                    <p><span class="closeBg" *ngIf="obj.proceeding.proceedingStatus == 'C'">Close</span>
                      <span class="openBg" *ngIf="obj.proceeding.proceedingStatus == 'O'">Open</span>
                    </p>


                </tr>

              </ng-container>
            </tbody>
          </table>
          <br />
          <div class="row noMargin plr15">
            <div class="col-md-10 nopadding">
              <pagination-controls (pageChange)="onTableDataChangeSection1($event)"
                id="proceedingConfig"></pagination-controls>
            </div>
            <div class="col-md-2 nopadding">
              <!-- <select id="itemsPerPage" [(ngModel)]="config.itemsPerPage">
                <option *ngFor="let option of [5, 10, 20, 50]">{{ option }}</option>
              </select> -->
              <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection1"
                (ngModelChange)="getProceedingData(perPageLimitSection1,1)" id="authority">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <ng-container *ngIf="totalCount == 0">
    <p class="noData">No Data Found</p>
  </ng-container>
  <div class="modal fade" id="confirmModel" tabindex="-1" role="dialog" aria-labelledby="confirmModelLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p>Do you want to mark this as a read ?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="confirmMarkRead()">Yes</button>
          <button type="button" class="btn btn-primary" #closeModal data-dismiss="modal" aria-label="Close">No</button>
        </div>
      </div>
    </div>
  </div>
</div>
