<div class="tab-pane" id="adjudication-1" role="tabpanel" aria-labelledby="adjudication-tab">
   <div class="row">
      <div class="col-md-4">
         <div class="d-flex justify-content-between">
            <ul class="nav nav-tabs iris-gst-display-flex" role="tablist">
               <li class="nav-item">
                  <a class="nav-link active" id="adjudp1-tab" data-toggle="tab" href="#adjudp1-1" role="tab"
                     aria-controls="adjudp1-1" aria-selected="true" (click)="tabChanged('tab1')">Phase 1</a>
               </li>

               <li class="nav-item" [ngClass]="{'disabled': (!isPhase2 && !(action == 'update')) || disablePhase2}">
                  <a class="nav-link" id="adjudp2-tab" data-toggle="tab" href="#adjudp2-1" role="tab"
                     aria-controls="adjudp2-1" aria-selected="false" (click)="tabChanged('tab2')" #submision>Phase 2</a>
               </li>
               <li class="nav-item" [ngClass]="{'disabled': (!isPhase3)}">
                  <a class="nav-link" id="adjudp3-tab" data-toggle="tab" href="#adjudp3-1" role="tab"
                     aria-controls="adjudp3-1" aria-selected="false" (click)="tabChanged('tab3')">Phase 3</a>
               </li>
            </ul>
         </div>
      </div>
      <div class="col-md-4">
         <div class="textCenter pt10">
            <span class="infoVal fs16" *ngIf="isVisibleCaseId" title="Case ID">{{caseData.caseId}}</span>
         </div>
      </div>
      <div class="col-md-4">
         <div>
            <span class="updatedData" *ngIf="caseData">
               <span class="infoTitle">Last Updated By :</span>{{caseData.updatedBy}} <span class="infoVal mr5"></span>
               <span class="infoTitle">On:</span> {{caseData.updateDate}} <span class="infoVal"></span>
            </span>
         </div>
      </div>

   </div>
   <div class="tab-content nopadding">
      <div class="tab-pane fade show active" id="adjudp1-1" role="tabpanel" aria-labelledby="adjudp1-tab">
         <div class="card borderOrange">
            <div class="card-body ptl10 plr010">
               <div class="row">
                  <div class="col-2" id="verticalTab6" *ngIf="!sidemenu">
                     <ul class="nav nav-tabs nav-tabs-vertical" role="tablist">
                        <li class="nav-item">
                           <a class="nav-link active" id="igi-tab-vertical" data-toggle="tab" href="#asgi-2" role="tab"
                              aria-controls="asgi-2" aria-selected="true">
                              General Info
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab2Disable && !(action == 'update') && !(action == 'view'))}">
                           <a class="nav-link" id="asNo-tab-vertical" data-toggle="tab" href="#asNo-2" role="tab"
                              aria-controls="asNo-2" aria-selected="false" #noticeTab>
                              Notice
                           </a>
                        </li>

                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab3Disable && !(action == 'update') && !(action == 'view'))}"
                           *ngIf="caseType !='PENALTY'">
                           <a class="nav-link" id="asitr-tab-vertical" data-toggle="tab" href="#asitr-2" role="tab"
                              aria-controls="asitr-2" aria-selected="false" #itrTab>
                              ITR Details
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab4Disable && !(action == 'update' ) && !(action == 'view'))}"
                           *ngIf="caseType !='PENALTY'">
                           <a class="nav-link" id="asWhc-tab-vertical" data-toggle="tab" href="#asWhc-2" role="tab"
                              aria-controls="asWhc-2" aria-selected="false" #WritTab>
                              Writ to High Court
                           </a>
                        </li>

                        <li class="nav-item" [ngClass]="{'disabled': (!tab3Disable && !(action == 'update' ))}"
                           *ngIf="!updateBtn">
                           <a class="btn btn-outline-primary w100"
                              [ngClass]="{'disabled': (!tab3Disable && !(action == 'update' ))}"
                              (click)="SaveForm('NEW')" #save1> Save </a>
                        </li>

                        <li class="nav-item" *ngIf="updateBtn">
                           <a *ngIf="caseType !='PENALTY'" class="btn btn-outline-primary w100"
                              (click)="submitNoticeFormUpdate(1,'NEW')" [ngClass]="{'disabled': (action == 'view')}">
                              Update</a>
                           <a *ngIf="caseType ==='PENALTY'" class="btn btn-outline-primary w100"
                              (click)="submitNoticeFormUpdate(1,'PENALTY')"
                              [ngClass]="{'disabled': (action == 'view')}"> Update</a>

                        </li>

                     </ul>
                  </div>
                  <div class="col-10 border " id="tabArea6" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
                     <div class="tab-content tab-content-vertical">
                        <div class="tab-pane fade show active" id="asgi-2" role="tabpanel"
                           aria-labelledby="asgi-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div [formGroup]="generalInfo">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <p class="textCenter">General Info</p>
                                       <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                          <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                          <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                       </span>
                                    </div>
                                    <div class="col-md-3" *ngIf="isVisibleCaseId">
                                       <div class="form-group">
                                          <label>Case ID
                                             <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="caseId" class="form-control" id="caseId"
                                             readonly />
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>PAN<span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="pan" class="form-control" readonly id=""
                                             placeholder="" />
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Legal PAN Name <span class="mandate"> <i
                                                   class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="panName" class="form-control" readonly
                                             placeholder="" />
                                       </div>
                                    </div>
                                    <div class="col-md-3" [ngClass]="{'disabled':isDisabledSelect}">
                                       <div class="form-group">
                                          <label>Assessment Year
                                             <span class="mandate"><i class="fa fa-asterisk"></i></span> </label>
                                          <select class="form-control form-select sd" id="gstin" formControlName="ay"
                                             (change)="getperiodToValue(ayPop)"
                                             [ngClass]="{errorBorder:isNextBtnClicked && !generalInfo.controls.ay.value }">
                                             <!-- <option [value]="null">-- Select --</option> -->
                                             <ng-container *ngFor="let obj of finyearData">
                                                <option [value]="obj">{{ obj }}</option> 
                                             </ng-container>
                                          </select>
                                          <div *ngIf="isNextBtnClicked && !generalInfo.controls.ay.value">
                                             <span class="text-danger">Assessment Year Required</span>
                                          </div>

                                          <ng-template #ayPop let-modal>
                                             <div class="modal-header" style="border: none; padding-bottom: 15px; padding-top:9px" >
                                               <button type="button" class="close" aria-label="Close"
                                                 (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                                               </button>
                                             </div>
                                             <div class="col-md-12" >
                                               <div class="table-responsive">
                                                 <p class="title-data">Following cases are already exist for same selected Assessment Year,<span style="font-weight: bold;">Do you want to still proceed?</span></p>
                                                 <table style="margin-left: 5px;">
                                                   <thead>
                                                     <tr class="bg-primary text-dblue">
                                                       <th class="sl-no-cla">Sr No</th>
                                                       <th class="clas">Case id</th>
                                                       <th>Case label</th>
                                                       <th class="clas">Assessment Year</th>
                                                       <th>Forum</th>
                                                       <th class="ac-no-clas">Total Demand(₹)</th>
                                                     </tr>
                                                   </thead>
                                                   <tbody>
                                                     <tr
                                                       *ngFor="let obj of duplicateData  let i = index">
                                                       <td class="sl-no-cla">{{i + 1}}</td>
                                                       <td class="clas">{{obj.caseId}}</td>
                                                       <td>{{obj.caseLabel}}</td>
                                                       <td class="clas">{{obj.ay}}</td>
                                                       <td>{{obj.forum}}</td>
                                                       <td class="ac-no-clas" style="text-align: right;">{{obj.demandAmount | INR: 0}} </td>
                                                     </tr>
                                                   </tbody>
                                                 </table>
                                               </div>
                                             </div>
                           
                                             <div class="modal-footer" style="border: none; padding-top: 5px;" >
                                               <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                                               (click)="modal.close('No click')">Yes</button>
                                                 <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                                                 (click)="goToSummary()">NO</button>
                                             </div>
                                           </ng-template>
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Financial Year<span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="finYear" readonly class="form-control"
                                             placeholder=""
                                             [ngClass]="{errorBorder: isNextBtnClicked && !generalInfo.controls.finYear.value}" />
                                          <div *ngIf="isNextBtnClicked && !generalInfo.controls.finYear.value">
                                             <span class="text-danger">Financial Year Required</span>
                                          </div>
                                       </div>
                                    </div>

                                    <!-- <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Consultant Name</label>
                                          <input type="text" formControlName="consultant" class="form-control"
                                             placeholder=""
                                             [ngClass]="{ errorBorder: generalInfo.controls.consultant.hasError('maxlength')}" />
                                       </div>
                                       <div class="text-danger"
                                          *ngIf="generalInfo.controls.consultant.touched &&  generalInfo.controls.consultant.hasError('maxlength')">
                                          Maximum of 100 characters
                                       </div>
                                    </div> -->

                                    <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create' )">
                                       <div class="form-group">
                                         <label for="exampleInputPassword1">Consultant</label>
                                     
                                         <!-- For Consultant Role: Create -->
                                         <div *ngIf="roleName === 'Consultant' && action === 'create'">
                                           <input 
                                             type="text" 
                                             class="form-control" 
                                             formControlName="consultant" 
                                             [readOnly]="action === 'create'">
                                         </div>
                                     
                                         <!-- For Non-Consultant Role -->
                                         <div *ngIf="roleName !== 'Consultant'">
                                           <div *ngIf="isToggleConsultantDropdown && (action === 'update' || action === 'view')" class="input-group">
                                             <input 
                                               type="text" 
                                               class="form-control" 
                                               placeholder="" 
                                               formControlName="consultant" 
                                               readonly>
                                             <button 
                                               *ngIf="isToggleConsultantDropdown && action === 'update'" 
                                               class="btn btn-outline-secondary" 
                                               type="button" 
                                               (click)="onClickToggleConsultantDropdown('p1')">
                                               <i class="fa fa-pencil"></i>
                                             </button>
                                           </div>
                                     
                                           <select 
                                             class="form-control form-select" 
                                             id="consultant" 
                                             *ngIf="!isToggleConsultantDropdown || action === 'create'" 
                                             formControlName="consultant">
                                             <option [value]="null">-- Select --</option>
                                             <ng-container *ngFor="let obj of consultantList">
                                               <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                                             </ng-container>
                                           </select>
                                         </div>
                                       </div>
                                     </div>
                                     


                                    
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Person responsible <span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span></label>
                                                  
                                                   <div *ngIf="isToggleDropdown && action==='update' || action==='view'" class="input-group">
																		<input class="form-control" type="text" formControlName="personResp" readonly>
																		<button *ngIf="isToggleDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleDropdown()">
																		  <i class="fa fa-pencil"></i>
																		</button>
																	  </div>
													
																	  <select *ngIf="!isToggleDropdown || action==='create'" class="form-control form-select" id="personResp" formControlName="personResp" (change)="onClickToggleDropdown()"
																		[ngClass]="{ 'errorBorder': isNextBtnClicked && generalInfo.controls.personResp.errors }">
																		<option [value]="null">-- Select --</option>
																		<ng-container *ngFor="let obj of userRoleLs">
																		  <option [value]="obj.username">{{obj.username}}</option>
																		</ng-container>
																	  </select>
                                          <div *ngIf="isNextBtnClicked && !generalInfo.controls.personResp.value">
                                             <span class="text-danger">Person responsible Required</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="row">
                                       <div class="col-md-12">
                                          <div class="form-group">
                                             <label>Case Label <span class="mandate"><i
                                                      class="fa fa-asterisk"></i></span><a class="clrOrange infoIcon"
                                                   title="Please give a one line description to identify the case."><i
                                                      class="fa fa-info-circle"
                                                      style="margin-left: 3px"></i></a></label>
                                             <textarea class="form-control" id="exampleTextarea1" rows="4"
                                                formControlName="caseLabel"
                                                [ngClass]="{ errorBorder:(isNextBtnClicked && !generalInfo.controls.caseLabel.value) || (isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length < 5) || (isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length > 125) }"></textarea>
                                          </div>
                                          <div *ngIf=" isNextBtnClicked &&  !generalInfo.controls.caseLabel.value">
                                             <span class="text-danger">Case Label Required</span>
                                          </div>
                                          <div
                                             *ngIf="isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length < 5">
                                             <span class="text-danger">Please enter Min 5 Characters</span>
                                          </div>
                                          <div
                                             *ngIf=" isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length > 125">
                                             <span class="text-danger">Please Enter Max 125 Characters</span>
                                          </div>
                                       </div>

                                       <div class="col-md-12">
                                          <div class="form-group">
                                             <label>Case Summary </label>
                                             <textarea class="form-control" id="exampleTextarea1"
                                                formControlName="caseSummary" rows="4"
                                                [ngClass]="{ errorBorder: generalInfo.controls.caseSummary.hasError('maxlength')}">
                                                       </textarea>
                                          </div>
                                          <div class="text-danger"
                                             *ngIf="generalInfo.controls.caseSummary.touched &&  generalInfo.controls.caseSummary.hasError('maxlength')">
                                             Maximum of 1000 Characters
                                          </div>
                                       </div>

                                       <div class="col-md-12">
                                          <div class="form-group">
                                             <label>Internal Remarks </label>
                                             <textarea class="form-control" id="internalRemarks1" rows="4"
                                                formControlName="internalRemarks1"
                                                [ngClass]="{ errorBorder: generalInfo.controls.internalRemarks1.hasError('maxlength')}"></textarea>
                                          </div>
                                          <div class="text-danger"
                                             *ngIf="generalInfo.controls.internalRemarks1.touched &&  generalInfo.controls.internalRemarks1.hasError('maxlength')">
                                             Maximum of 1000 Characters
                                          </div>
                                       </div>


                                       <div class="col-md-12 mt15 mb-3">
                                          <button class="btn btn-outline-primary mr-1" (click)="submitgeneralInfo()">
                                             Next </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </fieldset>

                        </div>
                        <div class="tab-pane fade" id="asNo-2" role="tabpanel" aria-labelledby="asNo-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="row">
                              <div class="col-md-12">
                                 <p class="textCenter">Notice</p>
                                 <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                    <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                    <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                 </span>
                              </div>
                              <div class="col-md-12">
                                 <fieldset [disabled]="action == 'view'">
                                    <form [formGroup]="noticeForm">
                                       <div formArrayName="dtNotices">
                                          <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                                             <li class="nav-item" id="asnoticeTab{{i}}"
                                                *ngFor="let div of getFormControlNotice(); let i=index"
                                                [formGroupName]="i">
                                                <a class="nav-link" id="asnotice1-tab" data-toggle="tab"
                                                   (click)="phaseChanged('phase' + i, i)"
                                                   [ngClass]="(selectedNotice === 'phase' + i) ? 'active': ''"
                                                   role="tab" aria-controls="asnotice1-1"
                                                   aria-selected="true">Notice{{i+1}}</a>
                                             </li>
                                          </ul>
                                          <div class="tab-content nopadding">
                                             <div class="tab-pane fade show active" id="asnotice1-1" role="tabpanel"
                                                aria-labelledby="asnotice1-tab">
                                                <div class="col-md-12 noPadding " id="noticeTabDiv{{i}}"
                                                   *ngFor="let noticeTabdiv of getFormControlNotice(); let i=index"
                                                   [formGroupName]="i">
                                                   <div *ngIf="selectedNotice === 'phase' + i" class="show-tab-content">

                                                      <div class="col-md-12 mt15 mb-3">
                                                         <button class="btn btn-outline-primary mr-1 addNoticeTab"
                                                            (click)="addNotice(i)">Add Notice</button>
                                                         <button class="btn btn-outline-primary mr-1 " *ngIf="i != 0"
                                                            (click)="removeLetter(i)">Remove Notice</button>
                                                      </div>

                                                      <div class="accordion accordion-solid-header " id="accordion-16"
                                                         role="tablist">
                                                         <div id="collapse-16" class="collapse show" role="tabpanel"
                                                            aria-labelledby="heading-16" data-parent="#accordion-16">
                                                            <div class="card-body noPadding">
                                                               <div class="row">
                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Type of Issuing Authority <span
                                                                              class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span></label>
                                                                        <select class="form-control form-select"
                                                                           [ngClass]="{errorBorder: isSubmittedNotice && !getFormControlNotice()[i].controls['issuingAuth'].value}"
                                                                           id="authority" formControlName="issuingAuth">
                                                                           <option [value]="null"> -- Select --
                                                                           </option>
                                                                           <ng-container
                                                                              *ngFor="let obj of preLitPH1Preceded">
                                                                              <option [value]="obj.value">{{ obj.label}}
                                                                              </option>
                                                                           </ng-container>
                                                                        </select>
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedNotice && !getFormControlNotice()[i].controls['issuingAuth'].value">
                                                                        <span class="text-danger"
                                                                           *ngIf="!getFormControlNotice()[i].controls['issuingAuth'].value">Type
                                                                           of Issuing Authority Required</span>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Notice Reference Number / DIN <span
                                                                              class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span></label>
                                                                        <input type="text" formControlName="noticeRefNo"
                                                                           class="form-control" placeholder=""
                                                                           [ngClass]="{errorBorder: isSubmittedNotice && !getFormControlNotice()[i].controls['noticeRefNo'].value }" />
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedNotice && !getFormControlNotice()[i].controls['noticeRefNo'].value">
                                                                        <span class="text-danger"
                                                                           *ngIf="!getFormControlNotice()[i].controls['noticeRefNo'].value">Notice
                                                                           Reference Number / DIN Required</span>
                                                                     </div>
                                                                  </div>

                                                                  <!-- Issued U/s -->
                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Notice Issued u/s<span class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span>
                                                                        </label>
                                                                        <select class="form-control form-select"
                                                                           id="authority"
                                                                           formControlName="noticeIssuedUs"
                                                                           [ngClass]="{errorBorder: isSubmittedNotice && !getFormControlNotice()[i].controls['noticeIssuedUs'].value }">
                                                                           <option [value]="null">-- Select --</option>
                                                                           <ng-container
                                                                              *ngFor="let obj of noticeIssued ">
                                                                              <option [value]="obj.value">{{obj.label}}
                                                                              </option>
                                                                           </ng-container>
                                                                        </select>
                                                                        <div
                                                                           *ngIf="isSubmittedNotice && !getFormControlNotice()[i].controls['noticeIssuedUs'].value">
                                                                           <span class="text-danger dateError"
                                                                              *ngIf="!getFormControlNotice()[i].controls['noticeIssuedUs'].value"
                                                                              style="margin-top:13%;">
                                                                              Notice Issued u/s Required
                                                                           </span>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Notice Type </label>
                                                                        <select class="form-control form-select"
                                                                           formControlName="noticeType" id="location">
                                                                           <option [value]="null"> -- Select --
                                                                           </option>
                                                                           <ng-container
                                                                              *ngFor=" let obj of typeOfNotice">
                                                                              <option [value]="obj.value"> {{ obj.label
                                                                                 }} </option>
                                                                           </ng-container>
                                                                        </select>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Date of Notice <span class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span></label>
                                                                        <div id="iris-gst-filter-frmDate"
                                                                           class="input-group date datepicker">
                                                                           <p-calendar class="form-control"
                                                                              formControlName="noticeDate"
                                                                              [maxDate]="maxDate"
                                                                              [monthNavigator]="true"
                                                                              [yearNavigator]="true"
                                                                              yearRange="2010:{{currentYear}}"
                                                                              dateFormat="dd-mm-yy" [showIcon]="true"
                                                                              dataType="string"
                                                                              [ngClass]="{errorBorder: isSubmittedNotice && !getFormControlNotice()[i].controls['noticeDate'].value}"></p-calendar>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedNotice &&!getFormControlNotice()[i].controls['noticeDate'].value">
                                                                        <span class="text-danger"
                                                                           *ngIf="!getFormControlNotice()[i].controls['noticeDate'].value">
                                                                           Date of Notice Required</span>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Date of Receipt of Notice<span
                                                                              class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span></label>
                                                                        <div id="iris-gst-filter-frmDate"
                                                                           class="input-group date datepicker">
                                                                           <p-calendar class="form-control"
                                                                              formControlName="noticeReceiptDate"
                                                                              [maxDate]="maxDate"
                                                                              [monthNavigator]="true"
                                                                              [yearNavigator]="true"
                                                                              yearRange="2010:{{currentYear}}"
                                                                              dateFormat="dd-mm-yy" [showIcon]="true"
                                                                              dataType="string"
                                                                              [ngClass]="{errorBorder: isSubmittedNotice && !getFormControlNotice()[i].controls['noticeReceiptDate'].value}"></p-calendar>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedNotice &&!getFormControlNotice()[i].controls['noticeReceiptDate'].value">
                                                                        <span class="text-danger"
                                                                           *ngIf="!getFormControlNotice()[i].controls['noticeReceiptDate'].value">
                                                                           Date of Receipt of Notice Required</span>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3 disabledSelect">
                                                                     <div class="form-group">
                                                                        <label>Mode of Receipt of Notice</label>
                                                                        <select class="form-control form-select"
                                                                           formControlName="noticeReceiptMode">
                                                                           <option [value]="null"> -- Select --</option>
                                                                           <ng-container
                                                                              *ngFor=" let obj of modeOfAppealOptions">
                                                                              <option [value]="obj.value"> {{
                                                                                 obj.value}} </option>
                                                                           </ng-container>
                                                                        </select>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3" style="margin-top: -8px;">
                                                                     <div class="form-group">
                                                                        <div class="form-group-Extension">
                                                                           <label>Reply due date <span
                                                                                 class="mandate-icon"><i
                                                                                    class="fa fa-asterisk"
                                                                                    style="margin-top: 14px;"></i></span></label>
                                                                           <div id="iris-gst-filter-frmDate"
                                                                              class="input-group date datepicker">
                                                                              <p-calendar class="form-control"
                                                                                 formControlName="legalDueDate"
                                                                                 [monthNavigator]="true"
                                                                                 [yearNavigator]="true"
                                                                                 yearRange="2010:{{currentYear}}"
                                                                                 dateFormat="dd-mm-yy" [showIcon]="true"
                                                                                 dataType="string"
                                                                                 [ngClass]="{ errorBorder:isSubmittedNotice &&!getFormControlNotice()[i].controls['legalDueDate'].value}">
                                                                              </p-calendar>
                                                                           </div>
                                                                           <div
                                                                              *ngIf="isSubmittedNotice &&!getFormControlNotice()[i].controls['legalDueDate'].value">
                                                                              <span class="text-danger"
                                                                                 *ngIf="!getFormControlNotice()[i].controls['legalDueDate'].value">
                                                                                 Reply due date Required</span>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3" style="margin-top: -13px;">
                                                                     <div class="form-group">
                                                                        <div class="form-group-Extension">
                                                                           <label>Internal Due Date</label>
                                                                           <div id="iris-gst-filter-frmDate"
                                                                              class="input-group date datepicker">
                                                                              <p-calendar class="form-control"
                                                                                 formControlName="internalDueDate"
                                                                                 [monthNavigator]="true"
                                                                                 [yearNavigator]="true"
                                                                                 yearRange="2010:{{currentYear}}"
                                                                                 dateFormat="dd-mm-yy" [showIcon]="true"
                                                                                 dataType="string">
                                                                              </p-calendar>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Is Adjournment Preferred Or Extension
                                                                           Applied?</label>
                                                                        <select class="form-control form-select"
                                                                           id="adjApp1" formControlName="isExtApplied">
                                                                           <ng-container
                                                                              *ngFor=" let obj of ajaSubDetailsOptions">
                                                                              <option [value]="obj.value">{{obj.label}}
                                                                              </option>
                                                                           </ng-container>
                                                                        </select>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3" id="showAdjExtDueDate1"
                                                                     *ngIf="getFormControlNotice()[i].controls['isExtApplied'].value == 'true' || getFormControlNotice()[i].controls['isExtApplied'].value == true">
                                                                     <div class="form-group">
                                                                        <label>Adjourned/ Extended Due Date <span
                                                                              class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span></label>
                                                                        <div id="iris-gst-filter-frmDate"
                                                                           class="input-group date datepicker">
                                                                           <p-calendar class="form-control"
                                                                              formControlName="extDueDate"
                                                                              [monthNavigator]="true"
                                                                              [yearNavigator]="true"
                                                                              yearRange="2010:{{currentYear}}"
                                                                              dateFormat="dd-mm-yy" [showIcon]="true"
                                                                              dataType="string"
                                                                              [ngClass]="{ errorBorder:isSubmittedNotice &&!getFormControlNotice()[i].controls['extDueDate'].value}">
                                                                           </p-calendar>
                                                                        </div>
                                                                        <div
                                                                           *ngIf="isSubmittedNotice && !getFormControlNotice()[i].controls['extDueDate'].value">
                                                                           <span class="text-danger"
                                                                              *ngIf="!getFormControlNotice()[i].controls['extDueDate'].value">
                                                                              Adjourned/Extended Due Date Required
                                                                           </span>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-12" id="showAdjTbl1"
                                                                     *ngIf="getFormControlNotice()[i].controls['isExtApplied'].value == 'true' || getFormControlNotice()[i].controls['isExtApplied'].value == true">
                                                                     <div class="accordion accordion-solid-header"
                                                                        id="accordion-02" role="tablist">
                                                                        <div class="card border-bottom">
                                                                           <div class="card-header" role="tab"
                                                                              id="heading-02">
                                                                              <h6 class="mb-0">
                                                                                 <a data-toggle="collapse"
                                                                                    href="#collapse-03"
                                                                                    aria-expanded="true"
                                                                                    aria-controls="collapse-2">
                                                                                    Adjournment Submission Details </a>
                                                                              </h6>
                                                                           </div>
                                                                           <div id="collapse-03" class="collapse show"
                                                                              role="tabpanel"
                                                                              aria-labelledby="heading-02"
                                                                              data-parent="#accordion-02">
                                                                              <div class="card-body">
                                                                                 <div class="row">
                                                                                    <div class="table-responsive">
                                                                                       <table
                                                                                          class="search-table inner">
                                                                                          <thead>
                                                                                             <tr class="bgGred">
                                                                                                <th class="w-40">Sr.No
                                                                                                </th>
                                                                                                <th>Date of Adjournment
                                                                                                   Filed</th>
                                                                                                <th>New Adjourned Date
                                                                                                </th>
                                                                                                <th>Reason For
                                                                                                   Adjournment</th>
                                                                                                <th>Granted</th>
                                                                                                <th>Upload </th>
                                                                                                <th class="w-80">Action
                                                                                                </th>
                                                                                             </tr>
                                                                                          </thead>
                                                                                          <tbody>
                                                                                             <tr *ngFor="let ajaSubDetailsFrmGrp of getAjaSubDetails(noticeTabdiv); let k=index"
                                                                                                [formGroup]="ajaSubDetailsFrmGrp">
                                                                                                <td
                                                                                                   class="sl-no-cls w-40">
                                                                                                   {{k + 1}}</td>
                                                                                                <td>
                                                                                                   <div
                                                                                                      id="iris-gst-filter-frmDate"
                                                                                                      class="input-group date datepicker">
                                                                                                      <p-calendar
                                                                                                         appendTo="body"
                                                                                                         class="form-control"
                                                                                                         formControlName="adjFiledDate"
                                                                                                         dateFormat="dd-mm-yy"
                                                                                                         [showIcon]="true"
                                                                                                         dataType="string"
                                                                                                         [maxDate]="maxDate"
                                                                                                         [monthNavigator]="true"
                                                                                                         [yearNavigator]="true"
                                                                                                         yearRange="2010:{{currentYear}}"
                                                                                                         dateFormat="dd-mm-yy"></p-calendar>
                                                                                                   </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                   <div
                                                                                                      id="iris-gst-filter-frmDate"
                                                                                                      class="input-group date datepicker"
                                                                                                      [ngClass]="{ 'errorBorder': isSubmittedNotice && ajaSubDetailsFrmGrp.get('newAdjDate')?.errors}">
                                                                                                      <p-calendar
                                                                                                         appendTo="body"
                                                                                                         class="form-control"
                                                                                                         formControlName="newAdjDate"
                                                                                                         dateFormat="dd-mm-yy"
                                                                                                         [showIcon]="true"
                                                                                                         dataType="string"
                                                                                                         [monthNavigator]="true"
                                                                                                         [yearNavigator]="true"
                                                                                                         yearRange="2010:{{currentYear}}"
                                                                                                         dateFormat="dd-mm-yy"></p-calendar>
                                                                                                   </div>
                                                                                                </td>
                                                                                                <td><input type="text"
                                                                                                      class="form-control"
                                                                                                      placeholder=""
                                                                                                      formControlName="adjReason" />
                                                                                                </td>
                                                                                                <td>
                                                                                                   <select
                                                                                                      class="form-control form-select"
                                                                                                      id="isGranted"
                                                                                                      formControlName="isGranted">
                                                                                                      <option
                                                                                                         [value]="null">
                                                                                                         -- Select --
                                                                                                      </option>
                                                                                                      <ng-container
                                                                                                         *ngFor="let obj of grantedOptions">
                                                                                                         <option
                                                                                                            [value]="obj.value">
                                                                                                            {{obj.label}}
                                                                                                         </option>
                                                                                                      </ng-container>
                                                                                                   </select>
                                                                                                </td>
                                                                                                <td><span
                                                                                                      (click)="openDetailPop(adjSubDetailsFormPop, i)"><i
                                                                                                         class="icon fa fa fa-paperclip"
                                                                                                         id="paperclip"
                                                                                                         title="Attach file"></i>
                                                                                                   </span><span
                                                                                                      *ngIf="getadjDocLocLength(i, k) != 0">
                                                                                                      {{getadjDocLocLength(i,
                                                                                                      k)}}
                                                                                                      Attachment</span>
                                                                                                </td>
                                                                                                <td class="w-80"> <a
                                                                                                      class="addExtLetter"
                                                                                                      (click)="addAjaSubDetails(i) "><i
                                                                                                         class="fa fa-plus"></i></a>
                                                                                                   <a class="addExtLetter"
                                                                                                      *ngIf="k!= 0"
                                                                                                      (click)="openRemoveAjaSubDetailsPop(rmvadjSubDetailsModalRef,i)"><i
                                                                                                         class="fa fa-minus"></i></a>
                                                                                                   <ng-template
                                                                                                      #rmvadjSubDetailsModalRef
                                                                                                      let-modal>
                                                                                                      <div
                                                                                                         class="modal-header">
                                                                                                         <h4 class="modal-title"
                                                                                                            id="modal-basic-title">
                                                                                                            Remove
                                                                                                            Warning!
                                                                                                         </h4>
                                                                                                         <button
                                                                                                            type="button"
                                                                                                            class="close"
                                                                                                            aria-label="Close"
                                                                                                            (click)="modal.dismiss('Cross click' )"><span
                                                                                                               aria-hidden="true">×</span>
                                                                                                         </button>
                                                                                                      </div>
                                                                                                      <div
                                                                                                         class="modal-body">
                                                                                                         <p> Are you
                                                                                                            sure, you
                                                                                                            want to
                                                                                                            remove?</p>
                                                                                                      </div>
                                                                                                      <div
                                                                                                         class="modal-footer">
                                                                                                         <button
                                                                                                            type="button"
                                                                                                            class="btn btn-outline-dark"
                                                                                                            (click)="rmAdjSubDetail(i,k)">
                                                                                                            Remove</button>
                                                                                                      </div>
                                                                                                   </ng-template>
                                                                                                   <ng-template
                                                                                                      #adjSubDetailsFormPop
                                                                                                      let-modal>
                                                                                                      <div
                                                                                                         class="modal-header"
                                                                                                         style="border: none; padding-bottom: 0px;">
                                                                                                         <button
                                                                                                            type="button"
                                                                                                            class="close"
                                                                                                            aria-label="Close"
                                                                                                            (click)="modal.dismiss('Cross click')">
                                                                                                            <span
                                                                                                               aria-hidden="true">×</span>
                                                                                                         </button>
                                                                                                      </div>
                                                                                                      <div
                                                                                                         class="col-md-12">
                                                                                                         <label>Attachment</label>
                                                                                                         <div
                                                                                                            class="table-responsive">
                                                                                                            <table
                                                                                                               class="table table-bordered">
                                                                                                               <thead>
                                                                                                                  <tr
                                                                                                                     class="bgGred">
                                                                                                                     <th>
                                                                                                                        Upload
                                                                                                                     </th>
                                                                                                                     <th
                                                                                                                        style="width:50%;">
                                                                                                                        Remark
                                                                                                                     </th>
                                                                                                                  </tr>
                                                                                                               </thead>
                                                                                                               <tbody>
                                                                                                                  <tr *ngFor="let getadjDocFromGrp of getadjDocLoc(ajaSubDetailsFrmGrp); let j=index"
                                                                                                                     [formGroup]="getadjDocFromGrp">
                                                                                                                     <td>
                                                                                                                        <div
                                                                                                                           class="row">
                                                                                                                           <div
                                                                                                                              class="col-md-9 nopadding">
                                                                                                                              <div
                                                                                                                                 class="form-group">
                                                                                                                                 <input
                                                                                                                                    *ngIf="getadjDocFromGrp.value.isdocLocUploadedClicked"
                                                                                                                                    type="file"
                                                                                                                                    id="dtNotices({{i}}).adjSubDetails({{k}}).adjDocsLoc({{j}}).fileLoc"
                                                                                                                                    class="filetype form-control"
                                                                                                                                    name="myfile"
                                                                                                                                    (change)="onFileSelect($event,'notice')">
                                                                                                                                 <p *ngIf="!getadjDocFromGrp.value.isdocLocUploadedClicked || getadjDocFromGrp.value.fileLoc"
                                                                                                                                    class="form-control pt10">
                                                                                                                                    {{getFileNameDta(getadjDocFromGrp.value.fileLoc)}}
                                                                                                                                 </p>
                                                                                                                              </div>
                                                                                                                           </div>
                                                                                                                           <div
                                                                                                                              class="col-md-3 nopadding">
                                                                                                                              <label>
                                                                                                                                 <a class="downupIcon"
                                                                                                                                    *ngIf="getadjDocFromGrp.value.fileLoc"
                                                                                                                                    title="Download file"
                                                                                                                                    (click)="download(getadjDocFromGrp.value.fileLoc)"><i
                                                                                                                                       class="fa fa-download"></i></a>
                                                                                                                                 <a class="downupIcon"
                                                                                                                                    *ngIf="!(getadjDocFromGrp.value.fileLoc || getadjDocFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                                                    title="Upload file"
                                                                                                                                    (click)="uploadAdjDetails(i,j,k)"><i
                                                                                                                                       class="fa fa-upload"></i></a>
                                                                                                                                 <a class="downupIcon"
                                                                                                                                    (click)="deletedocLocFile(i,j,k)"
                                                                                                                                    *ngIf="(getadjDocFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                                                       class="fa fa-trash"
                                                                                                                                       aria-hidden="true"></i></a>
                                                                                                                              </label>

                                                                                                                           </div>
                                                                                                                        </div>
                                                                                                                     </td>
                                                                                                                     <td>
                                                                                                                        <input
                                                                                                                           type="text"
                                                                                                                           class="form-control"
                                                                                                                           placeholder=""
                                                                                                                           id="remarks"
                                                                                                                           formControlName="remarks"
                                                                                                                           [title]="getadjDocFromGrp.value.remarks ? getadjDocFromGrp.value.remarks: ''"
                                                                                                                           maxlength="100" />
                                                                                                                     </td>
                                                                                                                  </tr>
                                                                                                               </tbody>
                                                                                                            </table>
                                                                                                         </div>
                                                                                                      </div>
                                                                                                      <div
                                                                                                         class="modal-footer"
                                                                                                         style="border: none; padding-top: 0px;">
                                                                                                         <button
                                                                                                            type="button"
                                                                                                            class="btn btn-outline-dark"
                                                                                                            (click)="modal.close('Save click')">Save</button>
                                                                                                      </div>
                                                                                                   </ng-template>
                                                                                                </td>
                                                                                             </tr>
                                                                                          </tbody>
                                                                                       </table>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-12">
                                                                     <div class="accordion accordion-solid-header"
                                                                        id="accordion-03" role="tablist">
                                                                        <div class="card border-bottom">
                                                                           <div class="card-header" role="tab"
                                                                              id="heading-03">
                                                                              <h6 class="mb-0">
                                                                                 <a data-toggle="collapse"
                                                                                    href="#collapse-03"
                                                                                    aria-expanded="true"
                                                                                    aria-controls="collapse-03">
                                                                                    List of Details/Documents Required
                                                                                 </a>
                                                                              </h6>
                                                                           </div>
                                                                           <div id="collapse-03" class="collapse show"
                                                                              role="tabpanel"
                                                                              aria-labelledby="heading-03"
                                                                              data-parent="#accordion-03">
                                                                              <div class="card-body">
                                                                                 <div class="row">
                                                                                    <div class="table-responsive">
                                                                                       <table
                                                                                          class="table table-bordered">
                                                                                          <tr class="bgGred">
                                                                                             <th class="w-40">Sr.No.
                                                                                             </th>
                                                                                             <th
                                                                                                style="min-width:444px;">
                                                                                                Document/ Details
                                                                                                Required</th>
                                                                                             <th
                                                                                                style="min-width:400px;">
                                                                                                Remarks</th>
                                                                                             <th class="w-80">Action
                                                                                             </th>
                                                                                          </tr>
                                                                                          <tr *ngFor="let div of getreqDetails(noticeTabdiv); let j= index"
                                                                                             [formGroup]="div">
                                                                                             <td class="sl-no-cls w-40">
                                                                                                {{j + 1}}</td>
                                                                                             <td> <input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="docReq"
                                                                                                   [ngClass]="{ errorBorder:div.get('docReq')?.errors?.pattern}" />
                                                                                                <div class="text-danger"
                                                                                                   *ngIf="div.get('docReq')?.errors?.pattern">
                                                                                                   Enter atleast 3
                                                                                                   characters </div>
                                                                                             </td>
                                                                                             <td> <input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   id="remarks"
                                                                                                   formControlName="remarks"
                                                                                                   [title]="div.value.remarks ? div.value.remarks :''"
                                                                                                   maxlength="100" />
                                                                                             </td>
                                                                                             <td class="w-80">
                                                                                                <a class="addExtLetter"
                                                                                                   (click)="addreqDetails(i) "><i
                                                                                                      class="fa fa-plus"></i></a>
                                                                                                <a class="addExtLetter"
                                                                                                   *ngIf="j!= 0"
                                                                                                   (click)="openRemovereqDetailsPop(rmreqDetailsPop,i)"><i
                                                                                                      class="fa fa-minus"></i></a>
                                                                                             </td>
                                                                                             <ng-template
                                                                                                #rmreqDetailsPop
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header">
                                                                                                   <h4 class="modal-title"
                                                                                                      id="modal-basic-title">
                                                                                                      Remove Warning!
                                                                                                   </h4>
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="modal-body">
                                                                                                   <p>Are you sure, you
                                                                                                      want to remove?
                                                                                                   </p>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="rmreqDetailsInv(i,j)">Remove</button>
                                                                                                </div>
                                                                                             </ng-template>
                                                                                          </tr>
                                                                                       </table>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>


                                                                  <div class="col-md-12">
                                                                     <div class="form-group">
                                                                        <label>Notice Summary </label>
                                                                        <textarea id="exampleTextarea1" rows="4"
                                                                           class="form-control ng-pristine ng-valid ng-touched"
                                                                           formControlName="noticeSummary"
                                                                           [ngClass]="{ errorBorder:noticeTabdiv.get('noticeSummary')?.hasError('maxlength')}">
                                                                    </textarea>
                                                                     </div>
                                                                     <div class="text-danger"
                                                                        *ngIf="noticeTabdiv.get('noticeSummary')?.touched && noticeTabdiv.get('noticeSummary')?.hasError('maxlength')">
                                                                        Maximum of 1000 characters
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <div class="form-group">
                                                                        <label>Internal Remarks</label>
                                                                        <textarea id="exampleTextarea1" rows="4"
                                                                           class="form-control ng-pristine ng-valid ng-touched"
                                                                           formControlName="internalRemarks"
                                                                           [ngClass]="{ errorBorder:noticeTabdiv.get('internalRemarks')?.hasError('maxlength')}"></textarea>
                                                                     </div>
                                                                     <div class="text-danger"
                                                                        *ngIf="noticeTabdiv.get('internalRemarks')?.touched && noticeTabdiv.get('internalRemarks')?.hasError('maxlength')">
                                                                        Maximum of 1000 characters
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Does the Correspondence Involves Amounts?
                                                                           <span class="mandate-icon"><i
                                                                                 class="fa fa-asterisk"
                                                                                 style="margin-top: -1px;"></i></span></label>
                                                                        <select class="form-control form-select"
                                                                           id="adjApp1"
                                                                           formControlName="doesCorrespondenceInvolvesAmts"
                                                                           [ngClass]="{ errorBorder:isSubmittedNotice && (getFormControlNotice()[i].controls['doesCorrespondenceInvolvesAmts'].value == null)}">
                                                                           <ng-container
                                                                              *ngFor=" let obj of correspondence">
                                                                              <option [value]="obj.value">{{obj.label}}
                                                                              </option>
                                                                           </ng-container>
                                                                        </select>
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedNotice && (getFormControlNotice()[i].controls['doesCorrespondenceInvolvesAmts'].value == null)">
                                                                        <span class="text-danger"> Does the
                                                                           Correspondence Involves Amounts
                                                                           Required</span>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-12 " id="showAdjTbl"
                                                                     *ngIf="getFormControlNotice()[i].controls['doesCorrespondenceInvolvesAmts'].value == 'true' || getFormControlNotice()[i].controls['doesCorrespondenceInvolvesAmts'].value == true">
                                                                     <div class="accordion accordion-solid-header"
                                                                        id="accordion-02" role="tablist">
                                                                        <div class="card border-bottom">
                                                                           <div class="card-header" role="tab"
                                                                              id="heading-02">
                                                                              <h6 class="mb-0">
                                                                                 <a data-toggle="collapse"
                                                                                    href="#collapse-02"
                                                                                    aria-expanded="true"
                                                                                    aria-controls="collapse-2">
                                                                                    Issue Table
                                                                                 </a>
                                                                                 <span class="addIssueRow"><i
                                                                                       class="fa fa-plus"
                                                                                       (click)="addIssueInv(i)"
                                                                                       title="Add row"></i></span>
                                                                              </h6>
                                                                           </div>
                                                                           <div id="collapse-02" class="collapse show"
                                                                              role="tabpanel"
                                                                              aria-labelledby="heading-02"
                                                                              data-parent="#accordion-02">
                                                                              <div class="card-body">
                                                                                 <div class=" row">
                                                                                    <div class="col-md-12">
                                                                                       <div
                                                                                          class="search-table-outter wrapper">
                                                                                          <table
                                                                                             class="search-table inner ">
                                                                                             <thead>
                                                                                                <tr class="bgGred">
                                                                                                   <th class="sl-no-cla" *ngIf="updateBtn">Info</th>
                                                                                                   <th
                                                                                                      style="min-width: 288px;">
                                                                                                      Issues<span
                                                                                                         class="mandatetd"><i
                                                                                                            class="fa fa-asterisk"
                                                                                                            style="font-size: 7px;"></i></span>
                                                                                                   </th>
                                                                                                   <th>Keyword (Max 150
                                                                                                      Character)</th>
                                                                                                   <th>Risk<span
                                                                                                         class="mandatetd"><i
                                                                                                            class="fa fa-asterisk"
                                                                                                            style="font-size: 7px;"></i></span>
                                                                                                   </th>
                                                                                                   <th
                                                                                                      *ngIf="caseType !='PENALTY'">
                                                                                                      Addition of
                                                                                                      Income/
                                                                                                      Disallowance of
                                                                                                      Expenses (₹)</th>
                                                                                                   <th
                                                                                                      *ngIf="caseType !='PENALTY'">
                                                                                                      Tax Rate
                                                                                                      (Inclusive of
                                                                                                      Surcharge and
                                                                                                      Cess) </th>
                                                                                                   <th
                                                                                                      *ngIf="caseType !='PENALTY'">
                                                                                                      Tax Impact
                                                                                                      (Inclusive of
                                                                                                      Surcharge and
                                                                                                      Cess) (₹) </th>
                                                                                                   <th
                                                                                                      *ngIf="caseType !='PENALTY'">
                                                                                                      Interest (₹) </th>
                                                                                                   <th
                                                                                                      *ngIf="caseType ==='PENALTY'">
                                                                                                      Penalty (₹) </th>
                                                                                                   <th
                                                                                                      *ngIf="caseType !='PENALTY'">
                                                                                                      Total(₹) </th>
                                                                                                   <th>Remark </th>
                                                                                                   <th class="w-80">
                                                                                                      Action</th>
                                                                                                </tr>
                                                                                             </thead>
                                                                                             <tbody>
                                                                                                <ng-container>
                                                                                                   <tr *ngFor="let issueFromGrp of getIssues(noticeTabdiv); let k=index"
                                                                                                      [formGroup]="issueFromGrp">
                                                                                                      <td  class="sl-no-cla" *ngIf="updateBtn">
                                                                                                         <p (click)="getissueData(issuePop,i,k)" class="pr5"><span
                                                                                                           class="badge-clo" title="Cases with similar issue"><i class="fa fa-info-circle"></i></span>
                                                                                                         </p>
                                                                                                     </td>
                                                                                                      <td class="w250"
                                                                                                         *ngIf="caseType ==='PENALTY'">
                                                                                                         <select
                                                                                                            class="form-control form-select"
                                                                                                            formControlName="issue"
                                                                                                            [title]="issueFromGrp.value.issue ? issueFromGrp.value.issue:''"
                                                                                                            [ngClass]="{ 'errorBorder': isSubmittedNotice && issueFromGrp.get('issue')?.errors  }">
                                                                                                            <option
                                                                                                               [value]="null"
                                                                                                               disabled>
                                                                                                               -- Select
                                                                                                               --
                                                                                                            </option>
                                                                                                            <optgroup
                                                                                                               *ngFor='let grp of dataSourceIssue'
                                                                                                               label="{{grp.group}}">
                                                                                                               <option
                                                                                                                  *ngFor='let item of grp.items'
                                                                                                                  [value]="item.name"
                                                                                                                  title="{{item.name}}">
                                                                                                                  {{item.name}}
                                                                                                               </option>
                                                                                                            </optgroup>
                                                                                                         </select>
                                                                                                         <div
                                                                                                            *ngIf="isSubmittedNotice && issueFromGrp.get('issue')?.errors">
                                                                                                            <span
                                                                                                               class="text-danger"
                                                                                                               *ngIf="issueFromGrp.get('issue')?.errors?.required ">Issues
                                                                                                               Required
                                                                                                            </span>
                                                                                                         </div>
                                                                                                      </td>
                                                                                                      <td class="w250"
                                                                                                         *ngIf="caseType !='PENALTY'">
                                                                                                         <select
                                                                                                            class="form-control form-select"
                                                                                                            formControlName="issue"
                                                                                                            [title]="issueFromGrp.value.issue ? issueFromGrp.value.issue:''"
                                                                                                            [ngClass]="{ 'errorBorder': isSubmittedNotice && issueFromGrp.get('issue')?.errors  }">
                                                                                                            <option
                                                                                                               [value]="null"
                                                                                                               disabled>
                                                                                                               -- Select
                                                                                                               --
                                                                                                            </option>
                                                                                                            <optgroup
                                                                                                               *ngFor='let grp of dataSource'
                                                                                                               label="{{grp.group}}">
                                                                                                               <option
                                                                                                                  *ngFor='let item of grp.items'
                                                                                                                  [value]="item.name"
                                                                                                                  title="{{item.name}}">
                                                                                                                  {{item.name}}
                                                                                                               </option>
                                                                                                            </optgroup>
                                                                                                         </select>
                                                                                                         <div
                                                                                                            *ngIf="isSubmittedNotice && issueFromGrp.get('issue')?.errors">
                                                                                                            <span
                                                                                                               class="text-danger"
                                                                                                               *ngIf="issueFromGrp.get('issue')?.errors?.required ">Issues
                                                                                                               Required
                                                                                                            </span>
                                                                                                         </div>
                                                                                                      </td>
                                                                                                      <td><input
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            id="tax"
                                                                                                            placeholder=""
                                                                                                            formControlName="keyword"
                                                                                                            [title]="issueFromGrp.value.keyword ? issueFromGrp.value.keyword:''">
                                                                                                         <div
                                                                                                            *ngIf="(issueFromGrp.controls.keyword.value &&  issueFromGrp.controls.keyword.value.length > 150)">
                                                                                                            <span
                                                                                                               class="text-danger"
                                                                                                               *ngIf="(issueFromGrp.controls.keyword.value &&  issueFromGrp.controls.keyword.value.length > 150)">Max
                                                                                                               Length
                                                                                                               150
                                                                                                               character
                                                                                                            </span>
                                                                                                         </div>
                                                                                                      </td>
                                                                                                      <td>
                                                                                                         <select
                                                                                                            class="form-control form-select"
                                                                                                            id="riskParam"
                                                                                                            formControlName="riskParam"
                                                                                                            [title]="issueFromGrp.value.riskParam ? issueFromGrp.value.riskParam: ''"
                                                                                                            [ngClass]="{ 'errorBorder': isSubmittedNotice && issueFromGrp.get('riskParam')?.errors  }">
                                                                                                            <option
                                                                                                               [value]="null">
                                                                                                               -- Select
                                                                                                               --
                                                                                                            </option>
                                                                                                            <ng-container
                                                                                                               *ngFor="let obj of riskParam">
                                                                                                               <option
                                                                                                                  [value]="obj.value">
                                                                                                                  {{obj.value}}
                                                                                                               </option>
                                                                                                            </ng-container>
                                                                                                         </select>
                                                                                                         <div
                                                                                                            *ngIf="isSubmittedNotice && issueFromGrp.get('riskParam')?.errors">
                                                                                                            <span
                                                                                                               class="text-danger"
                                                                                                               *ngIf="issueFromGrp.get('riskParam')?.errors?.required ">Risk
                                                                                                               Required
                                                                                                            </span>
                                                                                                         </div>
                                                                                                      </td>

                                                                                                      <td
                                                                                                         *ngIf="caseType !='PENALTY'">
                                                                                                         <input
                                                                                                            type="text"
                                                                                                            class="form-control align-right "
                                                                                                            placeholder=""
                                                                                                            formControlName="addnOrDisOfExpensesF"
                                                                                                            [title]="issueFromGrp.value.addnOrDisOfExpensesF ? issueFromGrp.value.addnOrDisOfExpensesF :''"
                                                                                                            maxlength="19"
                                                                                                            InrFormat
                                                                                                            (input)="issueValueChange()"
                                                                                                            (paste)="handlePaste($event)"
                                                                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                                                      </td>

                                                                                                      <td
                                                                                                         *ngIf="caseType !='PENALTY'">
                                                                                                         <div
                                                                                                            class="input-container">
                                                                                                            <input
                                                                                                               type="text"
                                                                                                               class="form-control input-field align-right"
                                                                                                               placeholder=""
                                                                                                               formControlName="taxRateF"
                                                                                                               id="taxRateF"
                                                                                                               maxlength="19"
                                                                                                               (blur)="handleDecimalPaste(i)"
                                                                                                               (input)="issueValueChange();">
                                                                                                            <i class="icon"
                                                                                                               style="position: absolute; transform: translateY(-50%);">%</i>
                                                                                                         </div>
                                                                                                         <div
                                                                                                            *ngIf="issueFromGrp.get('taxRateF')?.errors">
                                                                                                            <span
                                                                                                               class="text-danger"
                                                                                                               *ngIf="issueFromGrp.get('taxRateF')?.errors?.pattern">
                                                                                                               Please
                                                                                                               Enter 0
                                                                                                               to
                                                                                                               100</span>
                                                                                                         </div>
                                                                                                      </td>
                                                                                                      <td
                                                                                                         *ngIf="caseType !='PENALTY'">
                                                                                                         <input
                                                                                                            type="text"
                                                                                                            class="form-control align-right "
                                                                                                            placeholder=""
                                                                                                            formControlName="taxImpactF"
                                                                                                            maxlength="19"
                                                                                                            InrFormat
                                                                                                            [title]="issueFromGrp.value.taxImpactF ? issueFromGrp.value.taxImpactF:''"
                                                                                                            (input)="issueValueChange()"
                                                                                                            (paste)="handlePaste($event)"
                                                                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                                                      </td>
                                                                                                      <td
                                                                                                         *ngIf="caseType !='PENALTY'">
                                                                                                         <input
                                                                                                            type="text"
                                                                                                            class="form-control align-right "
                                                                                                            placeholder=""
                                                                                                            formControlName="interestF"
                                                                                                            maxlength="19"
                                                                                                            InrFormat
                                                                                                            [title]="issueFromGrp.value.interestF ? issueFromGrp.value.interestF:''"
                                                                                                            (input)="issueValueChange()"
                                                                                                            (paste)="handlePaste($event)"
                                                                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                                                      </td>
                                                                                                      <td
                                                                                                         *ngIf="caseType ==='PENALTY'">
                                                                                                         <input
                                                                                                            type="text"
                                                                                                            class="form-control align-right "
                                                                                                            placeholder=""
                                                                                                            formControlName="penaltyF"
                                                                                                            maxlengissueValueChangeth="19"
                                                                                                            InrFormat
                                                                                                            [title]="issueFromGrp.value.penaltyF ? issueFromGrp.value.penaltyF:''"
                                                                                                            (input)="issueValueChange()"
                                                                                                            (paste)="handlePaste($event)"
                                                                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                                                      </td>
                                                                                                      <td
                                                                                                         *ngIf="caseType !='PENALTY'">
                                                                                                         <input
                                                                                                            type="text"
                                                                                                            class="form-control align-right "
                                                                                                            placeholder=""
                                                                                                            readonly
                                                                                                            formControlName="totalF"
                                                                                                            maxlength="19"
                                                                                                            InrFormat
                                                                                                            (input)="issueValueChange()"
                                                                                                            [title]="issueFromGrp.value.totalF ? issueFromGrp.value.totalF:''">
                                                                                                      </td>
                                                                                                      <td> <input
                                                                                                            type="text"
                                                                                                            class="form-control"
                                                                                                            placeholder=""
                                                                                                            formControlName="remarkF"
                                                                                                            [title]="issueFromGrp.value.remarkF ? issueFromGrp.value.remarkF:''">
                                                                                                         <div
                                                                                                            *ngIf="issueFromGrp.get('remarkF')?.value && issueFromGrp.get('remarkF')?.value.length > 100">
                                                                                                            <span
                                                                                                               class="text-danger"
                                                                                                               *ngIf="issueFromGrp.get('remarkF')?.value && issueFromGrp.get('remarkF')?.value.length > 100">Max
                                                                                                               Length is
                                                                                                               100
                                                                                                               character
                                                                                                            </span>
                                                                                                         </div>
                                                                                                      </td>
                                                                                                      <td class="w-80">
                                                                                                         <!-- <a class=" addExtLetter" (click)="addIssueInv(i)"><i class="fa fa-plus" title="Add row"></i></a> -->
                                                                                                         <a class="removeDiv"
                                                                                                            data-toggle="modal"
                                                                                                            data-target="#removeIssuePopup"
                                                                                                            (click)="openRmvIssuePop(removeIssuePopup, i,k)"
                                                                                                            style="margin-left: 15%;"><i
                                                                                                               class="fa fa-minus"></i></a>
                                                                                                      </td>
                                                                                                      <ng-template
                                                                                                         #removeIssuePopup
                                                                                                         let-modal>
                                                                                                         <div
                                                                                                            class="modal-header">
                                                                                                            <h4 class="modal-title"
                                                                                                               id="modal-basic-title">
                                                                                                               Remove
                                                                                                               Warning!
                                                                                                            </h4>
                                                                                                            <button
                                                                                                               type="button"
                                                                                                               class="close"
                                                                                                               aria-label="Close"
                                                                                                               (click)="modal.dismiss('Cross click')">
                                                                                                               <span
                                                                                                                  aria-hidden="true">×</span>
                                                                                                            </button>
                                                                                                         </div>
                                                                                                         <div
                                                                                                            class="modal-body">
                                                                                                            <p>Are you
                                                                                                               sure, you
                                                                                                               want to
                                                                                                               remove?
                                                                                                            </p>
                                                                                                         </div>
                                                                                                         <div
                                                                                                            class="modal-footer">
                                                                                                            <button
                                                                                                               type="button"
                                                                                                               class="btn btn-outline-dark"
                                                                                                               (click)="rmIssueInv(i, k)">Remove</button>
                                                                                                         </div>
                                                                                                      </ng-template>
                                                                                                      <ng-template #issuePop let-modal>
                                                                                                         <div class="modal-header">
                                                                                                           <button type="button" class="close" aria-label="Close"
                                                                                                             (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                                                                                                           </button>
                                                                                                         </div>
                                                                                                         <div class="col-md-12" >
                                                                                                           <div class="table-responsive">
                                                                                                             <p class="title-data">For your reference following latest 15 cases are exist in LMS with selected issue: <span style="font-weight: bold;">{{issueFromGrp.value.issue}}</span></p>
                                                                                                             <div class="btn btn-outline-success btn-download btnRep" (click)="exportAsXLSX()" >
                                                                                                               <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
                                                                                                             </div>
                                                                                                             <table *ngIf="this.hideData">
                                                                                                               <thead >
                                                                                                                 <tr class="bgGred1">
                                                                                                                   <th class="sl-no-cla">Sr No</th>
                                                                                                                   <th><span>PAN</span>&nbsp;
                                                                                                                     <i class="fa fa-arrow-up cursor-pointer" 
                                                                                                                         aria-hidden="true" *ngIf="!sorting.panName"
                                                                                                                         (click)="SortingIssue(issueSorting.PAN)"></i>
                                                                                                                     <i class="fa fa-arrow-down cursor-pointer"
                                                                                                                         aria-hidden="true" *ngIf="sorting.panName"
                                                                                                                         (click)="SortingIssue(issueSorting.PAN)"></i>
                                                                                                                   </th>
                                                                                                                   <th><span>Case ID</span>&nbsp;
                                                                                                                     <i class="fa fa-arrow-up cursor-pointer"
                                                                                                                         aria-hidden="true" *ngIf="!sorting.caseId"
                                                                                                                         (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                                                                                                     <i class="fa fa-arrow-down cursor-pointer"
                                                                                                                         aria-hidden="true" *ngIf="sorting.caseId"
                                                                                                                         (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                                                                                                 </th>
                                                                                                                   <th class="clas"><span>Case Label</span>&nbsp;
                                                                                                                     <i class="fa fa-arrow-up cursor-pointer"
                                                                                                                         aria-hidden="true" *ngIf="!sorting.caseLabel"
                                                                                                                         (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                                                                                                     <i class="fa fa-arrow-down cursor-pointer"
                                                                                                                         aria-hidden="true" *ngIf="sorting.caseLabel"
                                                                                                                         (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                                                                                                   </th>
                                                                                                                   <th ><span>Assessment Year</span>&nbsp;
                                                                                                                     <i class="fa fa-arrow-up cursor-pointer"
                                                                                                                        aria-hidden="true"
                                                                                                                        *ngIf="!sorting.ay"
                                                                                                                        (click)="SortingIssue(issueSorting.AY)"></i>
                                                                                                                     <i class="fa fa-arrow-down cursor-pointer"
                                                                                                                        aria-hidden="true"
                                                                                                                        *ngIf="sorting.ay"
                                                                                                                        (click)="SortingIssue(issueSorting.AY)"></i>
                                                                                                                  </th>
                                                                                                                   <th><span>Person Responsible</span>&nbsp;
                                                                                                                     <i class="fa fa-arrow-up cursor-pointer"
                                                                                                                         aria-hidden="true"
                                                                                                                         *ngIf="!sorting.personResponsible"
                                                                                                                         (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                                                                                                     <i class="fa fa-arrow-down cursor-pointer"
                                                                                                                         aria-hidden="true"
                                                                                                                         *ngIf="sorting.personResponsible"
                                                                                                                         (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                                                                                                 </th>
                                                                                                                 </tr>
                                                                                                               </thead>
                                                                                                               <tbody>
                                                                                                                 <tr
                                                                                                                   *ngFor="let obj of issueRefData  let i = index">
                                                                                                                   <td class="sl-no-cla">{{i + 1}}</td>
                                                                                                                   <td >{{obj.panName}}</td>
                                                                                                                   <td >{{obj.caseId}}</td>
                                                                                                                   <td class="clas">{{obj.caseLabel}}</td>
                                                                                                                   <td >{{obj.ay}}</td>
                                                                                                                   <td>{{obj.personResponsible}}</td>
                                                                                                                 </tr>
                                                                                                               </tbody>
                                                                                                             </table>
                                                                                                             <div *ngIf="!this.hideData" style="font-size: large;text-align: center;font-weight: bold;">
                                                                                                               No data found.
                                                                                                             </div>
                                                                                                             
                                                                                                           </div>
                                                                                                         </div>
                                                                                                      </ng-template>
                                                                                                   </tr>
                                                                                                </ng-container>
                                                                                                <ng-container>
                                                                                                   <tr>
                                                                                                      <th *ngIf="!updateBtn" colspan="3"
                                                                                                         class="total-titel">
                                                                                                         Total</th>
                                                                                                      <th  *ngIf="updateBtn" colspan="4"
                                                                                                         class="total-titel">
                                                                                                         Total</th>
                                                                                                      <td
                                                                                                         *ngIf="caseType !=='PENALTY'">
                                                                                                         <input
                                                                                                            type="text"
                                                                                                            class="form-control bold-font align-right"
                                                                                                            formControlName="totOfAddnOrDisOfExpensesF"
                                                                                                            id="tax"
                                                                                                            placeholder=""
                                                                                                            InrFormat
                                                                                                            maxlength="19"
                                                                                                            readonly>
                                                                                                      </td>
                                                                                                      <td *ngIf="caseType !=='PENALTY'"
                                                                                                         colspan="1">
                                                                                                         &nbsp;</td>
                                                                                                      <td
                                                                                                         *ngIf="caseType !=='PENALTY'">
                                                                                                         <input
                                                                                                            type="text"
                                                                                                            class="form-control bold-font align-right"
                                                                                                            formControlName="taxImpactFTotal"
                                                                                                            id="tax"
                                                                                                            placeholder=""
                                                                                                            InrFormat
                                                                                                            maxlength="19"
                                                                                                            readonly>
                                                                                                      </td>
                                                                                                      <td
                                                                                                         *ngIf="caseType !=='PENALTY'">
                                                                                                         <input
                                                                                                            type="text"
                                                                                                            class="form-control bold-font align-right"
                                                                                                            formControlName="interestFTotal"
                                                                                                            id="tax"
                                                                                                            placeholder=""
                                                                                                            InrFormat
                                                                                                            maxlength="19"
                                                                                                            readonly>
                                                                                                      </td>
                                                                                                      <td
                                                                                                         *ngIf="caseType ==='PENALTY'">
                                                                                                         <input
                                                                                                            type="text"
                                                                                                            class="form-control bold-font align-right"
                                                                                                            formControlName="penaltyFTotal"
                                                                                                            id="tax"
                                                                                                            placeholder=""
                                                                                                            InrFormat
                                                                                                            maxlength="19"
                                                                                                            readonly>
                                                                                                      </td>
                                                                                                      <td
                                                                                                         *ngIf="caseType !=='PENALTY'">
                                                                                                         <input
                                                                                                            type="text"
                                                                                                            class="form-control bold-font align-right"
                                                                                                            formControlName="totOfTotal"
                                                                                                            id="tax"
                                                                                                            placeholder=""
                                                                                                            InrFormat
                                                                                                            maxlength="19"
                                                                                                            readonly>
                                                                                                      </td>
                                                                                                      <td colspan="9"
                                                                                                         *ngIf="caseType !=='PENALTY'">
                                                                                                         &nbsp;</td>
                                                                                                      <td colspan="4"
                                                                                                         *ngIf="caseType =='PENALTY'">
                                                                                                         &nbsp;</td>
                                                                                                   </tr>
                                                                                                </ng-container>
                                                                                             </tbody>

                                                                                          </table>
                                                                                       </div>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-12">
                                                                     <a class="showAuthDetailTbl anchorClick"
                                                                        (click)="detailsOfIssuingAuthority = !detailsOfIssuingAuthority"><i
                                                                           class="fa fa-id-badge mr5"></i>Add Authority
                                                                        Details</a>
                                                                  </div>
                                                                  <div class="col-md-12 AuthDetailTbl"
                                                                     *ngIf="detailsOfIssuingAuthority">
                                                                     <div class="accordion accordion-solid-header"
                                                                        id="accordion-03" role="tablist">
                                                                        <div class="card border-bottom">
                                                                           <div id="collapse-03" class="collapse show"
                                                                              role="tabpanel"
                                                                              aria-labelledby="heading-03"
                                                                              data-parent="#accordion-03">
                                                                              <div class="card-body">
                                                                                 <div class="row">
                                                                                    <div class="table-responsive">
                                                                                       <table
                                                                                          class="table table-bordered">
                                                                                          <tr class="bgGred">
                                                                                             <th>Notice Number/ DIN</th>
                                                                                             <th>Officer Name </th>
                                                                                             <th>Designation</th>
                                                                                             <th>Jurisdiction</th>
                                                                                             <th>Address </th>
                                                                                             <th>Contact Number </th>
                                                                                             <th>Email ID </th>
                                                                                             <th>Action</th>
                                                                                          </tr>
                                                                                          <tr *ngFor="let div of getIssuingAuthority(noticeTabdiv); let j=index"
                                                                                             [formGroup]="div">
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="noticeNo"
                                                                                                   maxlength="100"
                                                                                                   [title]="div.value.noticeNo ? div.value.noticeNo:''">
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="officer"
                                                                                                   maxlength="100"
                                                                                                   [title]="div.value.officer ? div.value.officer: ''">
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="designation"
                                                                                                   maxlength="100"
                                                                                                   [title]="div.value.designation ? div.value.designation:''">
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="jurisdiction"
                                                                                                   maxlength="100"
                                                                                                   [title]="div.value.jurisdiction ? div.value.jurisdiction: ''" />
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="address"
                                                                                                   maxlength="100"
                                                                                                   [title]="div.value.address ? div.value.address: ''">
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="contact"
                                                                                                   maxlength="12"
                                                                                                   oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="email"
                                                                                                   [title]="div.value.email ? div.value.email:''">
                                                                                                <div
                                                                                                   *ngIf="div.get('email')?.errors">
                                                                                                   <span
                                                                                                      class="text-danger"
                                                                                                      *ngIf="div.get('email')?.errors?.pattern">
                                                                                                      Please Enter Valid
                                                                                                      Email ID</span>
                                                                                                </div>
                                                                                             </td>
                                                                                             <td>
                                                                                                <a class="addExtLetter"
                                                                                                   (click)="addIssuingAuthority(i) "><i
                                                                                                      class="fa fa-plus"></i></a>
                                                                                                <a class="addExtLetter"
                                                                                                   *ngIf="j != 0"
                                                                                                   (click)="openIssuingAuthorityPop(rmissuingAuthorityPop,i)"><i
                                                                                                      class="fa fa-minus"></i></a>
                                                                                             </td>
                                                                                             <ng-template
                                                                                                #rmissuingAuthorityPop
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header">
                                                                                                   <h4 class="modal-title"
                                                                                                      id="modal-basic-title">
                                                                                                      Remove Warning!
                                                                                                   </h4>
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="modal-body">
                                                                                                   <p>Are you sure, you
                                                                                                      want to remove?
                                                                                                   </p>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="rmIssuingAuthorityInv(i,j)">Remove</button>
                                                                                                </div>
                                                                                             </ng-template>
                                                                                          </tr>
                                                                                       </table>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Notice Copy<span class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span>
                                                                        </label>
                                                                        <input type="file" class="file-upload-default"
                                                                           #file>
                                                                        <div class="attachment-block"
                                                                           [ngClass]="{'errorBorder': isSubmittedNotice &&  getnoticeLength(i) === 0 }">
                                                                           <span
                                                                              (click)="openNoticeLocPop(noticeCopyFormPop, i)">
                                                                              <i class="icon fa fa fa-paperclip"
                                                                                 style="cursor: pointer;"
                                                                                 title="Attach file"></i><span
                                                                                 *ngIf="getnoticeLength(i) != 0">
                                                                                 {{getnoticeLength(i)}}
                                                                                 Attachment</span></span>
                                                                        </div>
                                                                        <div
                                                                           *ngIf="isSubmittedNotice && getnoticeLength(i) === 0">
                                                                           <span class="text-danger">Notice Copy
                                                                              Required</span>
                                                                        </div>
                                                                        <div>
                                                                           <ng-template #noticeCopyFormPop let-modal>
                                                                              <div class="modal-header"
                                                                                 style="border: none; padding-bottom: 0px;">
                                                                                 <button type="button" class="close"
                                                                                    aria-label="Close"
                                                                                    (click)="modal.dismiss('Cross click')">
                                                                                    <span aria-hidden="true">×</span>
                                                                                 </button>
                                                                              </div>
                                                                              <div class="col-md-12">
                                                                                 <label> Attachment</label>
                                                                                 <div class="table-responsive">
                                                                                    <table class="table table-bordered">
                                                                                       <thead>
                                                                                          <tr class="bgGred">
                                                                                             <th>Upload</th>
                                                                                             <th style="width:50%;">
                                                                                                Remark</th>
                                                                                          </tr>
                                                                                       </thead>
                                                                                       <tbody>
                                                                                          <tr *ngFor="let noticeFromGrp of getnoticeLoc(noticeTabdiv); let j = index"
                                                                                             [formGroup]="noticeFromGrp">
                                                                                             <td>
                                                                                                <div class="row">
                                                                                                   <div
                                                                                                      class="col-md-9 nopadding">
                                                                                                      <div
                                                                                                         class="form-group">
                                                                                                         <input
                                                                                                            *ngIf="noticeFromGrp.value.isdocLocUploadedClicked"
                                                                                                            type="file"
                                                                                                            id="dtNotices({{i}}).noticeLoc({{j}}).fileLoc"
                                                                                                            class="filetype form-control"
                                                                                                            name="myfile"
                                                                                                            (change)="onFileSelect($event,'notice')">
                                                                                                         <p *ngIf="!noticeFromGrp.value.isdocLocUploadedClicked || noticeFromGrp.value.fileLoc"
                                                                                                            class="form-control pt10">
                                                                                                            {{getFileNameDta(noticeFromGrp.value.fileLoc)}}
                                                                                                         </p>
                                                                                                      </div>
                                                                                                   </div>
                                                                                                   <div
                                                                                                      class="col-md-3 nopadding">
                                                                                                      <label>
                                                                                                         <a class="downupIcon"
                                                                                                            *ngIf="noticeFromGrp.value.fileLoc"
                                                                                                            title="Download file"
                                                                                                            (click)="download(noticeFromGrp.value.fileLoc)"><i
                                                                                                               class="fa fa-download"></i></a>
                                                                                                         <a class="downupIcon"
                                                                                                            *ngIf="(!noticeFromGrp.value.fileLoc || noticeFromGrp.value.fileLoc)  && !['DT_Viewer'].includes(roleName)"
                                                                                                            title="Upload file"
                                                                                                            (click)="uploadNticeLocCopy(i,j)"><i
                                                                                                               class="fa fa-upload"></i></a>
                                                                                                         <a class="downupIcon"
                                                                                                            (click)="deletenNticeLocCopyFile(i,j)"
                                                                                                            *ngIf="(noticeFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                               class="fa fa-trash"
                                                                                                               aria-hidden="true"></i></a>
                                                                                                      </label>
                                                                                                   </div>
                                                                                                </div>
                                                                                             </td>
                                                                                             <td> <input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   id="remarks"
                                                                                                   formControlName="remarks"
                                                                                                   [title]="noticeFromGrp.value.remarks ? noticeFromGrp.value.remarks:''"
                                                                                                   maxlength="100" />
                                                                                             </td>
                                                                                          </tr>
                                                                                       </tbody>
                                                                                    </table>
                                                                                 </div>
                                                                              </div>
                                                                              <div class="modal-footer"
                                                                                 style="border: none; padding-top: 0px;">
                                                                                 <button type="button"
                                                                                    class="btn btn-outline-dark"
                                                                                    (click)="modal.close('Save click')">Save</button>
                                                                              </div>
                                                                           </ng-template>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-12 mt15 mb-3">
                                                            <button class="btn btn-outline-primary mr-1 addNoticeTab"
                                                               (click)="addNotice(i)">Add Notice</button>
                                                            <button class="btn btn-outline-primary mr-1 " *ngIf="i != 0"
                                                               (click)="removeLetter(i)">Remove Notice</button>
                                                         </div>
                                                      </div>
                                                      <div class="col-md-12 mt15 mb-3" *ngIf="caseType !='PENALTY'">
                                                         <button class="btn btn-outline-primary mr-1 savePhase1"
                                                            id="isNext" (click)="submitNoticeForm()"
                                                            #nextBtn>Next</button>
                                                      </div>
                                                      <div class="col-md-12 mt15 mb-3"
                                                         *ngIf="caseType =='PENALTY' && !updateBtn">
                                                         <button class="btn btn-outline-primary mr-1 savePhase1"
                                                            id="isNext" (click)="submitNoticeFormPentyle('PENALTY')"
                                                            #nextBtn>Save</button>
                                                      </div>

                                                      <div class="col-md-12 mt15 mb-3"
                                                         *ngIf="caseType =='PENALTY' && updateBtn">
                                                         <button class="btn btn-outline-primary mr-1 savePhase1"
                                                            id="isNext" (click)="submitNoticeFormUpdate(1,'PENALTY')"
                                                            #nextBtn>Update</button>
                                                      </div>

                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </form>
                                 </fieldset>
                              </div>
                           </div>
                        </div>

                        <div class="tab-pane fade" id="asitr-2" role="tabpanel" aria-labelledby="asitr-tab-vertical"
                           *ngIf="caseType !='PENALTY'">
                           <div class="row">
                              <div class="col-md-12">
                                 <p class="textCenter">ITR Details</p>
                                 <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                    <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                    <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                 </span>
                              </div>
                              <div [formGroup]="iTRDetailsForm">
                                 <div class="col-md-12">
                                    <div class="row">
                                       <div class="col-md-12 table-responsive">
                                          <table class="table table-bordered">
                                             <tr class="bgGred">
                                                <th style="min-width:315px;">Return Filed U/s</th>
                                                <th>ITR Form Type</th>
                                                <th>Ack Number</th>
                                                <th>Date of Filing</th>
                                                <th>Total Taxable Income </th>
                                                <th>Total Tax Liability (₹)</th>
                                                <th>Advance Tax(₹)</th>
                                                <th>TDS (₹)</th>
                                                <th>TCS (₹)</th>
                                                <th>Self Assessment Tax (₹)</th>
                                                <th>Total Tax Paid(₹) </th>
                                                <th>(Refund)/ Demand (₹)</th>
                                                <th class="w-80">Action</th>
                                             </tr>
                                             <ng-container formArrayName="itrDetails">
                                                <tr *ngFor="let itrDetailsGrp of getItrDetails(); let i=index"
                                                   [formGroup]="itrDetailsGrp">
                                                   <td>
                                                      <select class="form-control form-select" id="status"
                                                         formControlName="returnFiledUs"
                                                         [title]="itrDetailsGrp.value.returnFiledUs ? itrDetailsGrp.value.returnFiledUs:''">
                                                         <option [value]="null">-- Select --</option>
                                                         <ng-container *ngFor="let obj of returnFiledUs">
                                                            <option [value]="obj.value">{{obj.value}}</option>
                                                         </ng-container>
                                                      </select>
                                                   </td>

                                                   <td>
                                                      <select class="form-control form-select" id="status"
                                                         formControlName="itrForm"
                                                         [title]="itrDetailsGrp.value.itrForm ? itrDetailsGrp.value.itrForm: ''">
                                                         <option [value]="null">-- Select --</option>
                                                         <ng-container *ngFor="let obj of itrFormList">
                                                            <option [value]="obj.value">{{obj.value}}</option>
                                                         </ng-container>
                                                      </select>
                                                   </td>

                                                   <td><input type="text" class="form-control" placeholder=""
                                                         maxlength="50" formControlName="ackNo"
                                                         [title]="itrDetailsGrp.value.ackNo ? itrDetailsGrp.value.ackNo: ''">
                                                   </td>
                                                   <td>
                                                      <div id="iris-gst-filter-frmDate"
                                                         class="input-group date datepicker">
                                                         <p-calendar appendTo="body" class="form-control"
                                                            formControlName="filingDate" name="filingDate"
                                                            [title]="itrDetailsGrp.value.filingDate ? itrDetailsGrp.value.filingDate: ''"
                                                            [monthNavigator]="true" [yearNavigator]="true"
                                                            yearRange="2010:{{currentYear}}" dateFormat="dd-mm-yy"
                                                            [showIcon]="true" dataType="string" [maxDate]="maxDate">
                                                         </p-calendar>
                                                      </div>
                                                   </td>
                                                   <td><input type="text" class="form-control align-right "
                                                         placeholder="" InrFormat formControlName="totTaxIncome"
                                                         maxlength="19"
                                                         [title]="itrDetailsGrp.value.totTaxIncome ? itrDetailsGrp.value.totTaxIncome:''">
                                                   </td>
                                                   <td><input type="text" class="form-control align-right "
                                                         placeholder="" InrFormat formControlName="totTaxLiability"
                                                         (input)="itrDetailsPaidChanged()" maxlength="19"
                                                         (paste)="handlePaste($event)"
                                                         [title]="itrDetailsGrp.value.totTaxLiability ? itrDetailsGrp.value.totTaxLiability :''">
                                                   </td>
                                                   <td><input type="text" class="form-control align-right "
                                                      placeholder="" InrFormat formControlName="advanceTax"
                                                      (input)="itrDetailsPaidChanged()" maxlength="19"
                                                      (paste)="handlePaste($event)"
                                                      [title]="itrDetailsGrp.value.advanceTax ? itrDetailsGrp.value.advanceTax :''">
                                                   </td>
                                                   <td><input type="text" class="form-control align-right "
                                                      placeholder="" InrFormat formControlName="tdsAmount"
                                                      (input)="itrDetailsPaidChanged()" maxlength="19"
                                                      (paste)="handlePaste($event)"
                                                      [title]="itrDetailsGrp.value.tdsAmount ? itrDetailsGrp.value.tdsAmount :''">
                                                   </td>
                                                   <td><input type="text" class="form-control align-right "
                                                      placeholder="" InrFormat formControlName="tcsAmount"
                                                      (input)="itrDetailsPaidChanged()" maxlength="19"
                                                      (paste)="handlePaste($event)"
                                                      [title]="itrDetailsGrp.value.tcsAmount ? itrDetailsGrp.value.tcsAmount :''">
                                                   </td>
                                                   <td><input type="text" class="form-control align-right "
                                                      placeholder="" InrFormat formControlName="selfAssessmentAmount"
                                                      (input)="itrDetailsPaidChanged()" maxlength="19"
                                                      (paste)="handlePaste($event)"
                                                      [title]="itrDetailsGrp.value.selfAssessmentAmount ? itrDetailsGrp.value.selfAssessmentAmount :''">
                                                   </td>

                                                   <td><input type="text" class="form-control align-right "
                                                         placeholder="" InrFormat formControlName="totTaxPaid"
                                                         (input)="itrDetailsPaidChanged()" maxlength="19"
                                                         (paste)="handlePaste($event)"
                                                         [title]="itrDetailsGrp.value.totTaxPaid ? itrDetailsGrp.value.totTaxPaid:''">
                                                   </td>
                                                   <td><input type="text" class="form-control align-right "
                                                         placeholder="" InrFormat formControlName="refundDemand"
                                                         (input)="itrDetailsPaidChanged()" maxlength="19"
                                                         (paste)="handlePaste($event)"
                                                         [title]="itrDetailsGrp.value.refundDemand ? itrDetailsGrp.value.refundDemand:''">
                                                   </td>
                                                   <td class="w-80">
                                                      <a class="addExtLetter" (click)="addItrDetails() "><i
                                                            class="fa fa-plus"></i></a>
                                                      <a class="addExtLetter" *ngIf="i != 0"
                                                         (click)="openItrDetailsPop(rmItrDetailsPop,i)"><i
                                                            class="fa fa-minus"></i></a>
                                                   </td>
                                                   <ng-template #rmItrDetailsPop let-modal>
                                                      <div class="modal-header">
                                                         <h4 class="modal-title" id="modal-basic-title">Remove Warning!
                                                         </h4>
                                                         <button type="button" class="close" aria-label="Close"
                                                            (click)="modal.dismiss('Cross click')">
                                                            <span aria-hidden="true">×</span>
                                                         </button>
                                                      </div>
                                                      <div class="modal-body">
                                                         <p>Are you sure, you want to remove?</p>
                                                      </div>
                                                      <div class="modal-footer">
                                                         <button type="button" class="btn btn-outline-dark"
                                                            (click)="rmItrDetailsInv(i)">Remove</button>
                                                      </div>
                                                   </ng-template>
                                                </tr>
                                             </ng-container>
                                          </table>
                                          <div class="col-md-12 mt15" *ngIf="!updateBtn">
                                             <button class="btn btn-outline-primary mr-1" id="isNext"
                                                (click)="SaveForm('NEW')" #nextBtn>Save</button>
                                          </div>
                                          <div class="col-md-12 mt15" *ngIf="updateBtn">
                                             <button class="btn btn-outline-primary mr-1 savePhase1" id="isupdate"
                                                (click)="submitiTRDetailsForm()" #update>Next</button>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div class="tab-pane fade" id="asWhc-2" role="tabpanel" aria-labelledby="asWhc-tab-vertical"
                           *ngIf="caseType !='PENALTY'">
                           <div class="row">
                              <div class="col-md-12">
                                 <p class="textCenter">Writ to High Court</p>
                                 <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                    <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                    <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                 </span>
                              </div>
                              <div [formGroup]="writToHcForm" *ngIf="caseType !='PENALTY'">
                                 <div class="col-md-4">
                                    <div class="form-group">
                                       <label>Whether Writ Application Preferred to High Court?</label>
                                       <select class="form-control form-select" formControlName="writToHc" id="writToHc"
                                          (change)="whetherWritToHCChange()">
                                          <ng-container *ngFor=" let obj of writToHcOption">
                                             <option [value]="obj.value"> {{obj.label }} </option>
                                          </ng-container>
                                       </select>
                                    </div>
                                 </div>

                                 <div class="col-md-12 mt15 mb-3">
                                    <button class="btn btn-outline-primary mr-1"
                                       (click)="submitNoticeFormUpdate(1,'NEW')">Update</button>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="tab-pane fade" id="adjudp2-1" role="tabpanel" aria-labelledby="adjudp2-tab">
         <div class="card borderOrange">
            <div class="card-body ptl10 plr010">
               <div class="row">
                  <div class="col-2" id="verticalTab7" *ngIf="!sidemenu">
                     <ul class="nav nav-tabs nav-tabs-vertical" role="tablist">

                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab5Disable && !(action == 'update') && !(action == 'view'))}">
                           <a class="nav-link active" id="assub-tab-vertical" data-toggle="tab" href="#assub-2"
                              role="tab" aria-controls="assub-2" aria-selected="true" #submision> Submission
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab6Disable && !(action == 'update' )  && !(action == 'view'))}">
                           <a class="nav-link" id="ashd-tab-vertical" data-toggle="tab" href="#ashd-2" role="tab"
                              aria-controls="ashd-2" aria-selected="false" #hearingTab> Hearing / Video Conferencing
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab7Disable && !(action == 'update' )  && !(action == 'view'))}">
                           <a class="nav-link" id="assd-tab-vertical" data-toggle="tab" href="#assd-2" role="tab"
                              aria-controls="assd-2" aria-selected="false" #summonTab> Summon Details
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab8Disable && !(action == 'update' ) && !(action == 'view'))}">
                           <a class="nav-link" id="ascc-tab-vertical" data-toggle="tab" href="#ascc-2" role="tab"
                              aria-controls="ascc-2" aria-selected="false" #consultantTab> Consultant Charges
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab9Disable && !(action == 'update' )  && !(action == 'view'))}">
                           <a class="nav-link" id="ascon-tab-vertical" data-toggle="tab" href="#ascon-2" role="tab"
                              aria-controls="ascon-2" aria-selected="false" #orderReceivedTab> Whether Order Received?
                           </a>
                        </li>
                        <li class="nav-item">
                           <a *ngIf="caseType !='PENALTY'" class="btn btn-outline-primary w100"
                              (click)="submitUpdate(2,'NEW')" [ngClass]="{'disabled': (action == 'view')}"> Update</a>
                           <a *ngIf="caseType ==='PENALTY'" class="btn btn-outline-primary w100"
                              (click)="submitUpdate(2,'PENALTY')" [ngClass]="{'disabled': (action == 'view')}">
                              Update</a>
                        </li>
                     </ul>
                  </div>
                  <div class="col-10 border" id="tabArea22" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
                     <div class="tab-content tab-content-vertical">
                        <div class="tab-pane fade show active" id="assub-2" role="tabpanel"
                           aria-labelledby="assub-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="row">
                              <div class="col-md-12">
                                 <p class="textCenter">Submission</p>
                                 <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                    <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                    <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                 </span>
                              </div>
                              <fieldset [disabled]="action == 'view'">
                                 <form [formGroup]="noticeForm">
                                    <div formArrayName="dtNotices">
                                       <ul class="nav nav-pills iris-gst-display-flex" role="tablist">
                                          <li class="nav-item" id="asnoticeTab{{i}}"
                                             *ngFor="let div of getFormControlNotice(); let i=index"
                                             [formGroupName]="i">
                                             <a class="nav-link" id="asnotice1-tab" data-toggle="tab" role="tab"
                                                (click)="phaseRNChanged('phase' + i, i)"
                                                [ngClass]="(selectedReplyNotice === 'phase' + i) ? 'active': ''"
                                                aria-controls="asnotice1-1" aria-selected="true"> Reply to Notice
                                                {{i+1}}</a>
                                          </li>
                                       </ul>
                                       <div class="tab-content nopadding">
                                          <div class="tab-pane fade show active" id="asnotice1-1" role="tabpanel"
                                             aria-labelledby="asnotice1-tab">
                                             <div class="col-md-12 noPadding " id="noticeTabDiv{{i}}"
                                                *ngFor="let noticeTabdiv of getFormControlNotice(); let i=index"
                                                [formGroup]="noticeTabdiv">
                                                <div *ngIf="selectedReplyNotice === 'phase' + i"
                                                   class="show-tab-content">

                                                   <div class="col-md-12 nopadding">
                                                      <button class="btn btn-outline-primary mb15 "
                                                         (click)="addSubmissions(i)">Add Submission</button>
                                                   </div>

                                                   <div
                                                      *ngFor="let SubmisionFrmGrp of getSubmissions(noticeTabdiv); let k=index"
                                                      [formGroup]="SubmisionFrmGrp">
                                                      <div id="rnotice1-1" role="tabpanel"
                                                         aria-labelledby="rnotice1-tab">
                                                         <div class="subDiv col-md-12">
                                                            <div class="row">
                                                               <div class="col-md-12 mb-1">
                                                                  <a class="card-title"
                                                                     (click)="addSubmissions(i)">Submission {{k+1}} <i
                                                                        class="fa fa-plus" title="Add"></i></a>
                                                                  <a class="addExtLetter pull-right"
                                                                     (click)="openRemoveSubmissionsPop(removeSubPopup, i, k)"><i
                                                                        class="fa fa-minus"></i></a>
                                                               </div>
                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Date of Submission <span class="mandate"><i
                                                                              class="fa fa-asterisk"></i></span></label>
                                                                     <div id="iris-gst-filter-frmDate"
                                                                        class="input-group date datepicker">
                                                                        <p-calendar class="form-control"
                                                                           formControlName="subDate"
                                                                           [ngClass]="{errorBorder:isSubmittedSubmission && SubmisionFrmGrp.get('subDate')?.errors}"
                                                                           [maxDate]="maxDate" [monthNavigator]="true"
                                                                           [yearNavigator]="true"
                                                                           yearRange="2010:{{currentYear}}"
                                                                           dateFormat="dd-mm-yy" [showIcon]="true"
                                                                           dataType="string"></p-calendar>
                                                                     </div>
                                                                  </div>
                                                                  <div
                                                                     *ngIf="isSubmittedSubmission && SubmisionFrmGrp.get('subDate')?.errors">
                                                                     <span class="text-danger"
                                                                        *ngIf="SubmisionFrmGrp.get('subDate')?.errors?.required ">
                                                                        Date of Submission Required</span>
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Mode of Reply of Notice</label>
                                                                     <select class="form-control form-select"
                                                                        formControlName="subMode">
                                                                        <option [value]="null"> -- Select --</option>
                                                                        <ng-container
                                                                           *ngFor=" let obj of modeOfAppealOptions">
                                                                           <option [value]="obj.value"> {{ obj.value}}
                                                                           </option>
                                                                        </ng-container>
                                                                     </select>
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Response Type <span class="mandate"><i
                                                                              class="fa fa-asterisk"></i></span></label>
                                                                     <select class="form-control form-select"
                                                                        formControlName="respType">
                                                                        <option [value]="null"> -- Select --</option>
                                                                        <ng-container *ngFor=" let obj of TypeOfRespo">
                                                                           <option [value]="obj.value"> {{ obj.label}}
                                                                           </option>
                                                                        </ng-container>
                                                                     </select>
                                                                  </div>
                                                                  <!-- <div *ngIf="isSubmittedSubmission && SubmisionFrmGrp.get('respType')?.errors">
                                                              <span class="text-danger" *ngIf="SubmisionFrmGrp.get('respType')?.errors?.required ">Response Type Required</span>
                                                            </div> -->
                                                               </div>

                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Acknowledgement No. <span class="mandate"><i
                                                                              class="fa fa-asterisk"></i></span>
                                                                     </label>
                                                                     <input type="text" class="form-control"
                                                                        placeholder="" formControlName="ackNo"
                                                                        [ngClass]="{errorBorder:isSubmittedSubmission && SubmisionFrmGrp.get('ackNo')?.errors}">
                                                                  </div>
                                                                  <div
                                                                     *ngIf="isSubmittedSubmission && SubmisionFrmGrp.get('ackNo')?.errors">
                                                                     <span class="text-danger"
                                                                        *ngIf="SubmisionFrmGrp.get('ackNo')?.errors?.required ">Acknowledgement
                                                                        No Required</span>
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Copy of Acknowledgement <span
                                                                           class="mandate"><i
                                                                              class="fa fa-asterisk"></i></span>
                                                                     </label>
                                                                     <input type="file" class="file-upload-default"
                                                                        #file>
                                                                     <div class="attachment-block"
                                                                        [ngClass]="{'errorBorder': isSubmittedSubmission && getAckLength(i, k) === 0 }">
                                                                        <span
                                                                           (click)="openSubDetailPop(copyAcknowledgementFormPop, i)">
                                                                           <i class="icon fa fa fa-paperclip"
                                                                              style="cursor: pointer;"
                                                                              title="Attach file"></i></span> <span
                                                                           *ngIf="getAckLength(i, k) != 0">
                                                                           {{getAckLength(i, k)}} Attachment</span>
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedSubmission && getAckLength(i, k) === 0">
                                                                        <span class="text-danger">Copy of
                                                                           Acknowledgement Required</span>
                                                                     </div>
                                                                     <div>
                                                                        <ng-template #copyAcknowledgementFormPop
                                                                           let-modal>
                                                                           <div class="modal-header"
                                                                              style="border: none; padding-bottom: 0px;">
                                                                              <button type="button" class="close"
                                                                                 aria-label="Close"
                                                                                 (click)="modal.dismiss('Cross click')">
                                                                                 <span aria-hidden="true">×</span>
                                                                              </button>
                                                                           </div>
                                                                           <div class="col-md-12">
                                                                              <label> Attachment</label>
                                                                              <div class="table-responsive">
                                                                                 <table class="table table-bordered">
                                                                                    <thead>
                                                                                       <tr class="bgGred">
                                                                                          <th>Upload</th>
                                                                                          <th style="width:50%;">Remark
                                                                                          </th>
                                                                                       </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                       <tr *ngFor="let acknowledgeFromGrp of getAckCopyLoc(SubmisionFrmGrp); let j = index"
                                                                                          [formGroup]="acknowledgeFromGrp">
                                                                                          <td>
                                                                                             <div class="row">
                                                                                                <div
                                                                                                   class="col-md-9 nopadding">
                                                                                                   <div
                                                                                                      class="form-group">
                                                                                                      <input
                                                                                                         *ngIf="acknowledgeFromGrp.value.isdocLocUploadedClicked"
                                                                                                         type="file"
                                                                                                         id="dtNotices({{i}}).submissions({{k}}).ackCopyLoc({{j}}).fileLoc"
                                                                                                         class="filetype form-control"
                                                                                                         name="myfile"
                                                                                                         (change)="onFileSelect($event,'notice')">
                                                                                                      <p *ngIf="!acknowledgeFromGrp.value.isdocLocUploadedClicked || acknowledgeFromGrp.value.fileLoc"
                                                                                                         class="form-control pt10">
                                                                                                         {{getFileNameDta(acknowledgeFromGrp.value.fileLoc)}}
                                                                                                      </p>
                                                                                                   </div>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="col-md-3 nopadding">
                                                                                                   <label>
                                                                                                      <a class="downupIcon"
                                                                                                         *ngIf="acknowledgeFromGrp.value.fileLoc"
                                                                                                         title="Download file"
                                                                                                         (click)="download(acknowledgeFromGrp.value.fileLoc)"><i
                                                                                                            class="fa fa-download"></i></a>
                                                                                                      <a class="downupIcon"
                                                                                                         *ngIf="!(acknowledgeFromGrp.value.fileLoc || acknowledgeFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                         title="Upload file"
                                                                                                         (click)="uploadAckCopyLoc(i,j,k)"><i
                                                                                                            class="fa fa-upload"></i></a>
                                                                                                      <a class="downupIcon"
                                                                                                         (click)="deleteAckCopyLocFile(i,j,k)"
                                                                                                         *ngIf="(acknowledgeFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                            class="fa fa-trash"
                                                                                                            aria-hidden="true"></i></a>
                                                                                                   </label>

                                                                                                </div>
                                                                                             </div>
                                                                                          </td>
                                                                                          <td> <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder=""
                                                                                                id="remarks"
                                                                                                formControlName="remarks"
                                                                                                [title]="acknowledgeFromGrp.value.remarks ? acknowledgeFromGrp.value.remarks:''"
                                                                                                maxlength="100" />
                                                                                          </td>
                                                                                       </tr>
                                                                                    </tbody>
                                                                                 </table>
                                                                              </div>
                                                                           </div>
                                                                           <div class="modal-footer"
                                                                              style="border: none; padding-top: 0px;">
                                                                              <button type="button"
                                                                                 class="btn btn-outline-dark"
                                                                                 (click)="modal.close('Save click')">Save</button>
                                                                           </div>
                                                                        </ng-template>
                                                                     </div>
                                                                  </div>
                                                               </div>


                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Submission Copy</label>
                                                                     <input type="file" class="file-upload-default"
                                                                        #file>
                                                                     <div class="attachment-block">
                                                                        <span
                                                                           (click)="openSubDetailPop(submisionFormPop, i)">
                                                                           <i class="icon fa fa fa-paperclip"
                                                                              style="cursor: pointer;"
                                                                              title="Attach file"></i><span
                                                                              *ngIf="getSubLength(i, k) != 0">
                                                                              {{getSubLength(i, k)}}
                                                                              Attachment</span></span>
                                                                     </div>
                                                                     <div>
                                                                        <ng-template #submisionFormPop let-modal>
                                                                           <div class="modal-header"
                                                                              style="border: none; padding-bottom: 0px;">
                                                                              <button type="button" class="close"
                                                                                 aria-label="Close"
                                                                                 (click)="modal.dismiss('Cross click')">
                                                                                 <span aria-hidden="true">×</span>
                                                                              </button>
                                                                           </div>
                                                                           <div class="col-md-12">
                                                                              <label> Attachment</label>
                                                                              <div class="table-responsive">
                                                                                 <table class="table table-bordered">
                                                                                    <thead>
                                                                                       <tr class="bgGred">
                                                                                          <th>Upload</th>
                                                                                          <th style="width:50%;">Remark
                                                                                          </th>
                                                                                       </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                       <tr *ngFor="let subCopyFromGrp of getSubLoc(SubmisionFrmGrp); let j = index"
                                                                                          [formGroup]="subCopyFromGrp">
                                                                                          <td>
                                                                                             <div class="row">
                                                                                                <div
                                                                                                   class="col-md-9 nopadding">
                                                                                                   <div
                                                                                                      class="form-group">
                                                                                                      <input
                                                                                                         *ngIf="subCopyFromGrp.value.isdocLocUploadedClicked"
                                                                                                         type="file"
                                                                                                         id="dtNotices({{i}}).submissions({{k}}).subCopyLoc({{j}}).fileLoc"
                                                                                                         class="filetype form-control"
                                                                                                         name="myfile"
                                                                                                         (change)="onFileSelect($event,'notice')">
                                                                                                      <p *ngIf="!subCopyFromGrp.value.isdocLocUploadedClicked || subCopyFromGrp.value.fileLoc"
                                                                                                         class="form-control pt10">
                                                                                                         {{getFileNameDta(subCopyFromGrp.value.fileLoc)}}
                                                                                                      </p>
                                                                                                   </div>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="col-md-3 nopadding">
                                                                                                   <label>
                                                                                                      <a class="downupIcon"
                                                                                                         *ngIf="subCopyFromGrp.value.fileLoc"
                                                                                                         title="Download file"
                                                                                                         (click)="download(subCopyFromGrp.value.fileLoc)"><i
                                                                                                            class="fa fa-download"></i></a>
                                                                                                      <a class="downupIcon"
                                                                                                         *ngIf="!(subCopyFromGrp.value.fileLoc || subCopyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                         title="Upload file"
                                                                                                         (click)="uploadSubLoc(i,j,k)"><i
                                                                                                            class="fa fa-upload"></i></a>
                                                                                                      <a class="downupIcon"
                                                                                                         (click)="deleteSubLoc(i,j,k)"
                                                                                                         *ngIf="(subCopyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                            class="fa fa-trash"
                                                                                                            aria-hidden="true"></i></a>
                                                                                                   </label>

                                                                                                </div>
                                                                                             </div>
                                                                                          </td>
                                                                                          <td> <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder=""
                                                                                                id="remarks"
                                                                                                formControlName="remarks"
                                                                                                [title]="subCopyFromGrp.value.remarks ? subCopyFromGrp.value.remarks: ''"
                                                                                                maxlength="100" />
                                                                                          </td>
                                                                                       </tr>
                                                                                    </tbody>
                                                                                 </table>
                                                                              </div>
                                                                           </div>
                                                                           <div class="modal-footer"
                                                                              style="border: none; padding-top: 0px;">
                                                                              <button type="button"
                                                                                 class="btn btn-outline-dark"
                                                                                 (click)="modal.close('Save click')">Save</button>
                                                                           </div>
                                                                        </ng-template>
                                                                     </div>
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Person Who Submitted </label>
                                                                     <input type="text" class="form-control"
                                                                        placeholder=""
                                                                        formControlName="personWhoSubmitted">
                                                                  </div>
                                                               </div>
                                                               <div class="col-md-12">
                                                                  <div class="form-group">
                                                                     <label>Submission Summary</label>
                                                                     <textarea id="subSummary" rows="4"
                                                                        class="form-control ng-pristine ng-valid ng-touched"
                                                                        placeholder="" formControlName="subSummary"
                                                                        [title]="SubmisionFrmGrp.value.subSummary ? SubmisionFrmGrp.value.subSummary: ''"
                                                                        [ngClass]="{'errorBorder': isSubmittedSubmission && SubmisionFrmGrp.get('subSummary')?.hasError('maxlength') }"></textarea>
                                                                  </div>
                                                                  <div class="text-danger"
                                                                     *ngIf="SubmisionFrmGrp.get('subSummary')?.touched && SubmisionFrmGrp.get('subSummary')?.hasError('maxlength')">
                                                                     Maximum of 1000 characters
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-12">
                                                                  <div class="form-group">
                                                                     <label>Internal Remarks</label>
                                                                     <textarea id="internalRemarks" rows="4"
                                                                        class="form-control ng-pristine ng-valid ng-touched"
                                                                        placeholder="" formControlName="internalRemarks"
                                                                        [title]="SubmisionFrmGrp.value.internalRemarks ? SubmisionFrmGrp.value.internalRemarks: ''"
                                                                        [ngClass]="{'errorBorder': SubmisionFrmGrp.get('internalRemarks')?.hasError('maxlength') }"></textarea>
                                                                  </div>
                                                                  <div class="text-danger"
                                                                     *ngIf="SubmisionFrmGrp.get('internalRemarks')?.touched && SubmisionFrmGrp.get('internalRemarks')?.hasError('maxlength')">
                                                                     Maximum of 1000 characters
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-12">
                                                                  <div class="accordion accordion-solid-header"
                                                                     id="accordion-04" role="tablist">
                                                                     <div class="card border-bottom">
                                                                        <div class="card-header" role="tab"
                                                                           id="heading-04">
                                                                           <h6 class="mb-0">
                                                                              <a data-toggle="collapse"
                                                                                 href="#collapse-04"
                                                                                 aria-expanded="true"
                                                                                 aria-controls="collapse-04"> Details of
                                                                                 Submission</a>
                                                                           </h6>
                                                                        </div>
                                                                        <div id="collapse-04" class="collapse show"
                                                                           role="tabpanel" aria-labelledby="heading-04"
                                                                           data-parent="#accordion-04">
                                                                           <div class="card-body">
                                                                              <div class="row">
                                                                                 <div class="table-responsive">
                                                                                    <table class="table table-bordered">
                                                                                       <tr class="bgGred">
                                                                                          <th class="w-40">Sr. No.</th>
                                                                                          <th>Document / Details
                                                                                             Submitted </th>
                                                                                          <th>Remark </th>
                                                                                          <th>Attachment</th>
                                                                                          <th class="w-80">Action</th>
                                                                                       </tr>
                                                                                       <ng-container>
                                                                                          <tr *ngFor="let subDetailsFormGrp of getsubDetails(SubmisionFrmGrp); let j=index"
                                                                                             [formGroup]="subDetailsFormGrp">
                                                                                             <td class="w-40">{{j+1}}
                                                                                             </td>

                                                                                             <td> <input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="docReq"
                                                                                                   [title]="subDetailsFormGrp.value.docReq ? subDetailsFormGrp.value.docReq:''"
                                                                                                   [ngClass]="{ errorBorder:subDetailsFormGrp.get('docReq')?.errors?.pattern}" />
                                                                                                <div class="text-danger"
                                                                                                   *ngIf="subDetailsFormGrp.get('docReq')?.errors?.pattern">
                                                                                                   Enter atleast 3
                                                                                                   characters </div>
                                                                                             </td>

                                                                                             <td> <input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   id="remarks"
                                                                                                   formControlName="remarks"
                                                                                                   [title]="subDetailsFormGrp.value.remarks"
                                                                                                   maxlength="100" />
                                                                                             </td>

                                                                                             <td><span
                                                                                                   (click)="openSubDetailsPop(docLocFormPop, i)"><i
                                                                                                      class="icon fa fa fa-paperclip"
                                                                                                      id="paperclip"
                                                                                                      title="Attach file"></i></span><span
                                                                                                   *ngIf="getDocLoength(i, k,j) != 0">
                                                                                                   {{getDocLoength(i,
                                                                                                   k,j)}}
                                                                                                   Attachment</span>
                                                                                             </td>

                                                                                             <td class="w-80"><a
                                                                                                   class=" addExtLetter"
                                                                                                   (click)="addSubDetails(i,k)"><i
                                                                                                      class="fa fa-plus"></i></a>
                                                                                                <a class="addExtLetter"
                                                                                                   *ngIf="j!=0"
                                                                                                   (click)="openRemoveSubDetailsPop(rmvSubDetailsRef,i)"><i
                                                                                                      class="fa fa-minus"></i></a>
                                                                                             </td>
                                                                                             <ng-template
                                                                                                #rmvSubDetailsRef
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header">
                                                                                                   <h4 class="modal-title"
                                                                                                      id="modal-basic-title">
                                                                                                      Remove Warning!
                                                                                                   </h4>
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="modal-body">
                                                                                                   <p>Are you sure, you
                                                                                                      want to remove?
                                                                                                   </p>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="rmSubDetailstInv(i,k,j)">Remove</button>
                                                                                                </div>
                                                                                             </ng-template>

                                                                                             <ng-template #docLocFormPop
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header"
                                                                                                   style="border: none; padding-bottom: 0px;">
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="col-md-12">
                                                                                                   <label>Attachment</label>
                                                                                                   <div
                                                                                                      class="table-responsive">
                                                                                                      <table
                                                                                                         class="table table-bordered">
                                                                                                         <thead>
                                                                                                            <tr
                                                                                                               class="bgGred">
                                                                                                               <th>
                                                                                                                  Upload
                                                                                                               </th>
                                                                                                               <th
                                                                                                                  style="width:50%;">
                                                                                                                  Remark
                                                                                                               </th>
                                                                                                            </tr>
                                                                                                         </thead>
                                                                                                         <tbody>
                                                                                                            <tr *ngFor="let docLocFromGrp of getDocLoc(subDetailsFormGrp); let l=index"
                                                                                                               [formGroup]="docLocFromGrp">
                                                                                                               <td>
                                                                                                                  <div
                                                                                                                     class="row">
                                                                                                                     <div
                                                                                                                        class="col-md-9 nopadding">
                                                                                                                        <div
                                                                                                                           class="form-group">
                                                                                                                           <input
                                                                                                                              *ngIf="docLocFromGrp.value.isdocLocUploadedClicked"
                                                                                                                              type="file"
                                                                                                                              id="dtNotices({{i}}).submissions({{k}}).subDetails({{j}}).docLoc({{l}}).fileLoc"
                                                                                                                              class="filetype form-control"
                                                                                                                              name="myfile"
                                                                                                                              (change)="onFileSelect($event,'notice')">
                                                                                                                           <p *ngIf="!docLocFromGrp.value.isdocLocUploadedClicked || docLocFromGrp.value.fileLoc"
                                                                                                                              class="form-control pt10">
                                                                                                                              {{getFileNameDta(docLocFromGrp.value.fileLoc)}}
                                                                                                                           </p>
                                                                                                                        </div>
                                                                                                                     </div>
                                                                                                                     <div
                                                                                                                        class="col-md-3 nopadding">
                                                                                                                        <label>
                                                                                                                           <a class="downupIcon"
                                                                                                                              *ngIf="docLocFromGrp.value.fileLoc"
                                                                                                                              title="Download file"
                                                                                                                              (click)="download(docLocFromGrp.value.fileLoc)"><i
                                                                                                                                 class="fa fa-download"></i></a>
                                                                                                                           <a class="downupIcon"
                                                                                                                              *ngIf="!(docLocFromGrp.value.fileLoc || docLocFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                                              title="Upload file"
                                                                                                                              (click)="uploadDocLoc(i,j,k,l)"><i
                                                                                                                                 class="fa fa-upload"></i></a>
                                                                                                                           <a class="downupIcon"
                                                                                                                              (click)="deleteDocLo(i,j,k,l)"
                                                                                                                              *ngIf="(docLocFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                                                 class="fa fa-trash"
                                                                                                                                 aria-hidden="true"></i></a>
                                                                                                                        </label>

                                                                                                                     </div>
                                                                                                                  </div>
                                                                                                               </td>
                                                                                                               <td>
                                                                                                                  <input
                                                                                                                     type="text"
                                                                                                                     class="form-control"
                                                                                                                     placeholder=""
                                                                                                                     id="remarks"
                                                                                                                     formControlName="remarks"
                                                                                                                     [title]="docLocFromGrp.value.remarks ? docLocFromGrp.value.remarks:''"
                                                                                                                     maxlength="100" />
                                                                                                               </td>
                                                                                                            </tr>
                                                                                                         </tbody>
                                                                                                      </table>
                                                                                                   </div>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer"
                                                                                                   style="border: none; padding-top: 0px;">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="modal.close('Save click')">Save</button>
                                                                                                </div>
                                                                                             </ng-template>
                                                                                          </tr>
                                                                                       </ng-container>
                                                                                    </table>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <ng-template #removeSubPopup let-modal>
                                                                  <div class="modal-header">
                                                                     <h4 class="modal-title" id="modal-basic-title">
                                                                        Remove Warning!</h4>
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')">
                                                                        <span aria-hidden="true">×</span>
                                                                     </button>
                                                                  </div>
                                                                  <div class="modal-body">
                                                                     <p>Are you sure, you want to remove?</p>
                                                                  </div>
                                                                  <div class="modal-footer">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="rmSubmissionDiv(i, k)">Remove</button>
                                                                  </div>
                                                               </ng-template>

                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div class="col-md-12">
                                                      <div class="accordion accordion-solid-header" id="accordion-04"
                                                         role="tablist">
                                                         <div class="card border-bottom">
                                                            <div class="card-header" role="tab" id="heading-04">
                                                               <h6 class="mb-0">
                                                                  <a data-toggle="collapse" href="#collapse-04"
                                                                     aria-expanded="true" aria-controls="collapse-04">
                                                                     Details of Physical Meeting/ Video Conferencing
                                                                     Department
                                                                  </a>
                                                               </h6>
                                                            </div>
                                                            <div id="collapse-04" class="collapse show" role="tabpanel"
                                                               aria-labelledby="heading-04" data-parent="#accordion-04">
                                                               <div class="card-body">
                                                                  <div class="row">
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <tr class="bgGred">
                                                                              <th class="w-40">Sr. No. </th>
                                                                              <th style="width: 171px;">Date of Meeting
                                                                              </th>
                                                                              <th>Persons who attended </th>
                                                                              <th>Hearing details</th>
                                                                              <th>Copy of Submissions made</th>
                                                                              <th class="w-80">Action</th>
                                                                           </tr>
                                                                           <ng-container>
                                                                              <tr *ngFor="let departmentFromGrp of departmentInvArr(noticeTabdiv); let k=index"
                                                                                 [formGroup]="departmentFromGrp">
                                                                                 <td class="w-40">{{k+1}}</td>
                                                                                 <td>
                                                                                    <div id="iris-gst-filter-frmDate"
                                                                                       class="input-group date datepicker">
                                                                                       <p-calendar appendTo="body"
                                                                                          class="form-control"
                                                                                          formControlName="dateOfMeeting"
                                                                                          name="dateOfMeeting"
                                                                                          [monthNavigator]="true"
                                                                                          [yearNavigator]="true"
                                                                                          yearRange="2010:{{currentYear}}"
                                                                                          dateFormat="dd-mm-yy"
                                                                                          [showIcon]="true"
                                                                                          dataType="string"
                                                                                          [maxDate]="maxDate">
                                                                                       </p-calendar>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td><input type="text"
                                                                                       class="form-control"
                                                                                       id="personAttended"
                                                                                       placeholder=""
                                                                                       formControlName="personAttended">
                                                                                 </td>
                                                                                 <td><input type="text"
                                                                                       class="form-control"
                                                                                       id="hearingDetails"
                                                                                       placeholder=""
                                                                                       formControlName="hearingDetails"
                                                                                       [title]="departmentFromGrp.value.hearingDetails ? departmentFromGrp.value.hearingDetails:''">
                                                                                 </td>
                                                                                 <td><span
                                                                                       (click)="openDepartmentPop(copyOfSubFormPop, i)"><i
                                                                                          class="icon fa fa fa-paperclip"
                                                                                          id="paperclip"
                                                                                          title="Attach file"></i></span><span
                                                                                       *ngIf="getCopyOfSubLength(i, k) != 0">
                                                                                       {{getCopyOfSubLength(i, k)}}
                                                                                       Attachment</span></td>
                                                                                 <td class="w-80">
                                                                                    <a class=" addExtLetter"
                                                                                       (click)="addDepartmentInv(i)"><i
                                                                                          class="fa fa-plus"></i></a>
                                                                                    <a class="addExtLetter" *ngIf="k!=0"
                                                                                       (click)="openRemovDepartmentPop(rmvDepartmentRef,i,k)"><i
                                                                                          class="fa fa-minus"></i></a>
                                                                                 </td>
                                                                                 <ng-template #rmvDepartmentRef
                                                                                    let-modal>
                                                                                    <div class="modal-header">
                                                                                       <h4 class="modal-title"
                                                                                          id="modal-basic-title">Remove
                                                                                          Warning!</h4>
                                                                                       <button type="button"
                                                                                          class="close"
                                                                                          aria-label="Close"
                                                                                          (click)="modal.dismiss('Cross click')">
                                                                                          <span
                                                                                             aria-hidden="true">×</span>
                                                                                       </button>
                                                                                    </div>
                                                                                    <div class="modal-body">
                                                                                       <p>Are you sure, you want to
                                                                                          remove?</p>
                                                                                    </div>
                                                                                    <div class="modal-footer">
                                                                                       <button type="button"
                                                                                          class="btn btn-outline-dark"
                                                                                          (click)="rmDepartmentInv(i,k)">Remove</button>
                                                                                    </div>
                                                                                 </ng-template>

                                                                                 <ng-template #copyOfSubFormPop
                                                                                    let-modal>
                                                                                    <div class="modal-header"
                                                                                       style="border: none; padding-bottom: 0px;">
                                                                                       <button type="button"
                                                                                          class="close"
                                                                                          aria-label="Close"
                                                                                          (click)="modal.dismiss('Cross click')">
                                                                                          <span
                                                                                             aria-hidden="true">×</span>
                                                                                       </button>
                                                                                    </div>
                                                                                    <div class="col-md-12">
                                                                                       <label>Attachment</label>
                                                                                       <div class="table-responsive">
                                                                                          <table
                                                                                             class="table table-bordered">
                                                                                             <thead>
                                                                                                <tr class="bgGred">
                                                                                                   <th> Upload </th>
                                                                                                   <th
                                                                                                      style="width:50%;">
                                                                                                      Remark </th>
                                                                                                </tr>
                                                                                             </thead>
                                                                                             <tbody>
                                                                                                <tr *ngFor="let  CopyOfSubFromGrp  of getCopyOfSub(departmentFromGrp); let j=index"
                                                                                                   [formGroup]="CopyOfSubFromGrp">
                                                                                                   <td>
                                                                                                      <div class="row">
                                                                                                         <div
                                                                                                            class="col-md-9 nopadding">
                                                                                                            <div
                                                                                                               class="form-group">
                                                                                                               <input
                                                                                                                  *ngIf="CopyOfSubFromGrp.value.isdocLocUploadedClicked"
                                                                                                                  type="file"
                                                                                                                  id="dtNotices({{i}}).meetingDetails({{k}}).copyOfSubmissionLoc({{j}}).fileLoc"
                                                                                                                  class="filetype form-control"
                                                                                                                  name="myfile"
                                                                                                                  (change)="onFileSelect($event,'notice')">
                                                                                                               <p *ngIf="!CopyOfSubFromGrp.value.isdocLocUploadedClicked || CopyOfSubFromGrp.value.fileLoc"
                                                                                                                  class="form-control pt10">
                                                                                                                  {{getFileNameDta(CopyOfSubFromGrp.value.fileLoc)}}
                                                                                                               </p>
                                                                                                            </div>
                                                                                                         </div>
                                                                                                         <div
                                                                                                            class="col-md-3 nopadding">
                                                                                                            <label>
                                                                                                               <a class="downupIcon"
                                                                                                                  *ngIf="CopyOfSubFromGrp.value.fileLoc"
                                                                                                                  title="Download file"
                                                                                                                  (click)="download(CopyOfSubFromGrp.value.fileLoc)"><i
                                                                                                                     class="fa fa-download"></i></a>
                                                                                                               <a class="downupIcon"
                                                                                                                  *ngIf="!(CopyOfSubFromGrp.value.fileLoc || CopyOfSubFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                                  title="Upload file"
                                                                                                                  (click)="uploadCopyOfSub(i,j,k)"><i
                                                                                                                     class="fa fa-upload"></i></a>
                                                                                                               <a class="downupIcon"
                                                                                                                  (click)="deleteCopyOfSub(i,j,k)"
                                                                                                                  *ngIf="(CopyOfSubFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                                     class="fa fa-trash"
                                                                                                                     aria-hidden="true"></i></a>
                                                                                                            </label>
                                                                                                         </div>
                                                                                                      </div>
                                                                                                   </td>
                                                                                                   <td> <input
                                                                                                         type="text"
                                                                                                         class="form-control"
                                                                                                         placeholder=""
                                                                                                         id="remarks"
                                                                                                         formControlName="remarks"
                                                                                                         [title]="CopyOfSubFromGrp.value.remarks ? CopyOfSubFromGrp.value.remarks:''"
                                                                                                         maxlength="100" />
                                                                                                   </td>
                                                                                                </tr>
                                                                                             </tbody>
                                                                                          </table>
                                                                                       </div>
                                                                                    </div>
                                                                                    <div class="modal-footer"
                                                                                       style="border: none; padding-top: 0px;">
                                                                                       <button type="button"
                                                                                          class="btn btn-outline-dark"
                                                                                          (click)="modal.close('Save click')">Save</button>
                                                                                    </div>
                                                                                 </ng-template>


                                                                              </tr>
                                                                           </ng-container>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div class="col-md-12 mt15  mb-3">
                                                      <button class="btn btn-outline-primary mr-1"
                                                         (click)="submitSubmissionForm()">Next</button>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </fieldset>
                           </div>
                        </div>

                        <div class="tab-pane fade" id="ashd-2" role="tabpanel" aria-labelledby="ashd-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="col-md-12 nopadding">
                              <p class="textCenter">Hearing / Video Conferencing</p>
                              <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                 <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                 <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                              </span>
                           </div>

                           <div class="col-md-12 nopadding">
                              <fieldset [disabled]="action == 'view'">

                                 <form [formGroup]="hearingForm">
                                    <div formArrayName="hearingDetails" [ngClass]="{'bgGray': (action == 'view')}">
                                       <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                                          <li class="nav-item" id="adjPhTab{{i}}"
                                             *ngFor="let adjPHtab of phDivArr(); let i=index" [formGroupName]="i">
                                             <a class="nav-link" id="adjudph1-tab" data-toggle="tab" role="tab"
                                                aria-controls="adjudph1-1" (click)="phaseChangedph('phase' + i, i)"
                                                [ngClass]="(selectedPhase === 'phase' + i) ? 'active': ''"
                                                aria-selected="true">Hearing {{i+1}} <span *ngIf="i > 0"
                                                   (click)="removephArr(i)" class="closeTab">x</span></a>
                                          </li>
                                       </ul>

                                       <div class="tab-content nopadding">
                                          <div class="tab-pane fade show active" id="adjudph1-1" role="tabpanel"
                                             aria-labelledby="adjudph1-tab">
                                             <div class="ml5" id="adjPhDiv{{i}}"
                                                *ngFor="let adjPHdiv of phDivArr(); let i=index" [formGroupName]="i">
                                                <div *ngIf="selectedPhase === 'phase' + i" class="show-tab-content">
                                                   <div class="row">
                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Hearing Ref.No </label>
                                                           <input type="text" class="form-control" id="phRefNo" placeholder="" formControlName="phRefNo">
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Hearing Notice Date </label>
                                                           <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                                             <p-calendar class="form-control" formControlName="phNoticeDate"
                                                               [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}"
                                                               dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                                                           </div>
                                                         </div>
                                                       </div>

                                                       <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Hearing Notice </label>
                                                            <input type="file" class="file-upload-default" #file>
                                                            <div class="attachment-block">
                                                               <span (click)="openPhNoticePop(phNoticeFormPop, i)"> <i
                                                                     class="icon fa fa fa-paperclip"
                                                                     style="cursor: pointer;"
                                                                     title="Attach file"></i><span
                                                                     *ngIf="getPhNoticeLength(i) != 0">
                                                                     {{getPhNoticeLength(i)}} Attachment</span></span>
                                                            </div>
                                                            <div>
                                                               <ng-template #phNoticeFormPop let-modal>
                                                                  <div class="modal-header"
                                                                     style="border: none; padding-bottom: 0px;">
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')"> <span
                                                                           aria-hidden="true">×</span> </button>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <label> Attachment</label>
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <thead>
                                                                              <tr class="bgGred">
                                                                                 <th>Upload</th>
                                                                                 <th style="width:50%;">Remark</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr *ngFor="let phNoticeFromGrp of getPhNotice(adjPHdiv); let j = index"
                                                                                 [formGroup]="phNoticeFromGrp">
                                                                                 <td>
                                                                                    <div class="row">
                                                                                       <div class="col-md-9 nopadding">
                                                                                          <div class="form-group">
                                                                                             <input
                                                                                                *ngIf="phNoticeFromGrp.value.isdocLocUploadedClicked"
                                                                                                type="file"
                                                                                                id="hearingDetails({{i}}).phNoticeLoc({{j}}).fileLoc"
                                                                                                class="filetype form-control"
                                                                                                name="myfile"
                                                                                                (change)="onFileSelect($event,'hearing')">
                                                                                             <p *ngIf="!phNoticeFromGrp.value.isdocLocUploadedClicked || phNoticeFromGrp.value.fileLoc"
                                                                                                class="form-control pt10">
                                                                                                {{getFileNameDta(phNoticeFromGrp.value.fileLoc)}}
                                                                                             </p>
                                                                                          </div>
                                                                                       </div>
                                                                                       <div class="col-md-3 nopadding">
                                                                                          <label>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="phNoticeFromGrp.value.fileLoc"
                                                                                                title="Download file"
                                                                                                (click)="download(phNoticeFromGrp.value.fileLoc)"><i
                                                                                                   class="fa fa-download"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="(!phNoticeFromGrp.value.fileLoc || phNoticeFromGrp.value.fileLoc)  && !['DT_Viewer'].includes(roleName)"
                                                                                                title="Upload file"
                                                                                                (click)="uploadPhNoticeCopy(i,j)"><i
                                                                                                   class="fa fa-upload"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                (click)="deletePhNoticeCopyFile(i,j)"
                                                                                                *ngIf="(phNoticeFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                   class="fa fa-trash"
                                                                                                   aria-hidden="true"></i></a>
                                                                                          </label>
                                                                                       </div>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td> <input type="text"
                                                                                       class="form-control"
                                                                                       placeholder="" id="remarks"
                                                                                       formControlName="remarks"
                                                                                       [title]="phNoticeFromGrp.value.remarks ? phNoticeFromGrp.value.remarks: ''"
                                                                                       maxlength="100" />
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                                  <div class="modal-footer"
                                                                     style="border: none; padding-top: 0px;">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="modal.close('Save click')">Save</button>
                                                                  </div>
                                                               </ng-template>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Hearing Date</label>
                                                            <div id="iris-gst-filter-frmDate"
                                                               class="input-group date datepicker">
                                                               <p-calendar class="form-control"
                                                                  formControlName="phScheduledDate"
                                                                  [monthNavigator]="true" [yearNavigator]="true"
                                                                  yearRange="2000:{{currentYear}}" dateFormat="dd-mm-yy"
                                                                  [showIcon]="true" dataType="string">
                                                               </p-calendar>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div class="row">
                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Time of Hearing  </label>
                                                           <select class="form-control form-select" id="phTime" formControlName="phTime">
                                                             <option [value]="null">-- Select --</option>
                                                             <ng-container *ngFor="let obj of modeOfTime">
                                                               <option [value]="obj.value">{{obj.key}}</option>
                                                             </ng-container>
                                                           </select>
                                                         </div>
                                                       </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                         <label for="exampleInputPassword1">Mode of Hearing  </label>
                                                         <select class="form-control form-select" id="gstin" formControlName="mode">
                                                            <option [value]="null">-- Select --</option>
                                                            <ng-container *ngFor="let obj of modeOfPH">
                                                               <option [value]="obj.value">{{obj.key}}</option>
                                                            </ng-container>
                                                         </select>
                                                         </div>
                                                      </div>

                                                      
                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Attended </label>
                                                            <select class="form-control form-select" id="attended"
                                                               formControlName="attended">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of adjAttend">
                                                                  <option [value]="obj.value">{{obj.label}}</option>
                                                               </ng-container>
                                                            </select>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Adjourned/ New Hearing</label>
                                                            <select class="form-control form-select" id="adjournedOrNot"
                                                               formControlName="adjournedOrNot"
                                                               (change)="changeadjOrNot($event, i)">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of adjPHAdjuorNotList">
                                                                  <option [value]="obj.value">{{obj.label}}</option>
                                                               </ng-container>
                                                            </select>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Adjournment Letter</label>
                                                           <input type="file" class="form-control file-upload-default" #file>
                                                           <div class="attachment-block">
                               
                                                             <span (click)="openPhAdjournmentLetterLocPop(phAdjournmentLetterLoc, i)" style="margin-left: 5px;">
                                                               <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                                                 title="Attach file"></i></span><span *ngIf="getPhAdjournmentLetterLocLength(i) != 0">
                                                               {{getPhAdjournmentLetterLocLength(i)}} Attachment</span>
                                                           </div>
                                                           <div>
                                                             <ng-template #phAdjournmentLetterLoc let-modal>
                                                               <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                                                 <button type="button" class="close" aria-label="Close"
                                                                   (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                                                 </button>
                                                               </div>
                                                               <div class="col-md-12">
                                                                 <label> Attachment</label>
                                                                 <div class="table-responsive">
                                                                   <table class="table table-bordered tablepop">
                                                                     <thead>
                                                                       <tr class="bgGred">
                                                                         <th>Upload</th>
                                                                         <th style="width:50%;">Remark</th>
                                                                       </tr>
                                                                     </thead>
                                                                     <tbody>
                                                                       <tr *ngFor="let phAdjournmentLetterGrp of getPhAdjournmentLetterLoc(adjPHdiv); let j = index"
                                                                         [formGroup]="phAdjournmentLetterGrp">
                                                                         <td>
                                                                           <div class="row">
                                                                             <div class="col-md-9 nopadding">
                                                                               <div class="form-group">
                                                                                 <input *ngIf="phAdjournmentLetterGrp.value.isdocLocUploadedClicked" type="file"
                                                                                   id="hearingDetails({{i}}).phAdjournmentLetterLoc({{j}}).fileLoc"
                                                                                   class="filetype form-control" name="myfile"
                                                                                   (change)="onFileSelect($event,'hearing')">
                                                                                 <p *ngIf="!phAdjournmentLetterGrp.value.isdocLocUploadedClicked || phAdjournmentLetterGrp.value.fileLoc"
                                                                                   class="form-control pt10">
                                                                                   {{getFileNameDta(phAdjournmentLetterGrp.value.fileLoc)}}
                                                                                 </p>
                                                                               </div>
                                                                             </div>
                                                                             <div class="col-md-3 nopadding">
                                                                               <label for="exampleInputPassword1">
                                                                                 <a class="downupIcon" *ngIf="phAdjournmentLetterGrp.value.fileLoc"
                                                                                   title="Download file"
                                                                                   (click)="download(phAdjournmentLetterGrp.value.fileLoc)"><i
                                                                                     class="fa fa-download"></i></a>
                                                                                 <a class="downupIcon"
                                                                                   *ngIf="!(phAdjournmentLetterGrp.value.fileLoc || phAdjournmentLetterGrp.value.fileLoc)"
                                                                                   title="Upload file" (click)="uploadPhAdjournmentLetterLocCopy(i,j)"><i
                                                                                     class="fa fa-upload"></i></a>
                                                                                 <a class="downupIcon" (click)="deletePhAdjournmentLetterLocFile(i,j)"
                                                                                   *ngIf="(phAdjournmentLetterGrp.value.fileLoc)"><i
                                                                                     class="fa fa-trash" aria-hidden="true"></i></a>
                                                                               </label>
                                                                             </div>
                                                                           </div>
                                                                         </td>
                                                                         <td>
                                                                           <input type="text" class="form-control" formControlName="remarks"
                                                                             [title]="phAdjournmentLetterGrp.value.remarks ? phAdjournmentLetterGrp.value.remarks:''">
                                                                           <div
                                                                             *ngIf="phAdjournmentLetterGrp.value.remarks && phAdjournmentLetterGrp.value.remarks.length > 100">
                                                                             <span class="text-danger">Max character Length 100</span>
                                                                           </div>
                                                                         </td>
                                                                       </tr>
                                                                     </tbody>
                                                                   </table>
                                                                 </div>
                                                               </div>
                                                               <div class="modal-footer" style="border: none; padding-top: 0px;">
                                                                 <button type="button" class="btn btn-outline-dark"
                                                                   (click)="modal.close('Save click')">Save</button>
                                                               </div>
                                                             </ng-template>
                                                           </div>
                                                         </div>
                                                       </div>
                                                      </div>
                                                   </div>
                                                   <div class="row">
                                                      <!-- <div class="col-md-3">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Consultant</label>
                                         
                                                           <div *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" class="input-group">
                                                             <input type="text" class="form-control"
                                                               *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" placeholder=""
                                                               formControlName="consultant" readonly>
                                                             <button *ngIf="isToggleConsultantDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown()">
                                                               <i class="fa fa-pencil"></i>
                                                             </button> 
                                                           </div>
                                         
                                                           <select class="form-control form-select" id="consultant"
                                                               *ngIf="!isToggleConsultantDropdown || action==='create'" formControlName="consultant">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of consultantList">
                                                                 <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                                               </ng-container>
                                                             </select>
                                                         </div>
                                                      </div> -->

                                                      <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create' )">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Consultant</label>
                                                       
                                                           <!-- For Consultant Role: Create, View, and Update -->
                                                           <div *ngIf="roleName === 'Consultant' && action === 'create'">
                                                             <input 
                                                               type="text" 
                                                               class="form-control" 
                                                               placeholder="" 
                                                               formControlName="consultant" 
                                                               readOnly>
                                                           </div>
                                                       
                                                           <!-- For Non-Consultant Role -->
                                                           <div *ngIf="roleName !== 'Consultant'">
                                                             <div *ngIf="isToggleConsultantDropdown2 && (action === 'update' || action === 'view')" class="input-group">
                                                               <input 
                                                                 type="text" 
                                                                 class="form-control" 
                                                                 placeholder="" 
                                                                 formControlName="consultant" 
                                                                 readonly>
                                                               <button 
                                                                 *ngIf="isToggleConsultantDropdown2 && action === 'update'" 
                                                                 class="btn btn-outline-secondary" 
                                                                 type="button" 
                                                                 (click)="onClickToggleConsultantDropdown('p2')">
                                                                 <i class="fa fa-pencil"></i>
                                                               </button>
                                                             </div>
                                                       
                                                             <select 
                                                               class="form-control form-select" 
                                                               id="consultant" 
                                                               *ngIf="!isToggleConsultantDropdown2 || action === 'create'" 
                                                               formControlName="consultant">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of consultantList">
                                                                 <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                                                               </ng-container>
                                                             </select>
                                                           </div>
                                                         </div>
                                                      </div>
                                                       
                                                      
                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Date of Additional Written Submissions</label>
                                                            <div id="iris-gst-filter-frmDate"
                                                               class="input-group date datepicker">
                                                               <p-calendar class="form-control"
                                                                  formControlName="addWrittenSubDate"
                                                                  [monthNavigator]="true" [yearNavigator]="true"
                                                                  [maxDate]="maxDate" yearRange="2000:{{currentYear}}"
                                                                  dateFormat="dd-mm-yy" [showIcon]="true"
                                                                  dataType="string">
                                                               </p-calendar>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Upload the additional submission </label>
                                                            <input type="file" class="file-upload-default" #file>
                                                            <div class="attachment-block">
                                                               <span (click)="openAddSubPop(addSubFormPop, i)"> <i
                                                                     class="icon fa fa fa-paperclip"
                                                                     style="cursor: pointer;"
                                                                     title="Attach file"></i><span
                                                                     *ngIf="getAddSubLength(i) != 0">
                                                                     {{getAddSubLength(i)}} Attachment</span></span>
                                                            </div>
                                                            <div>
                                                               <ng-template #addSubFormPop let-modal>
                                                                  <div class="modal-header"
                                                                     style="border: none; padding-bottom: 0px;">
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')"> <span
                                                                           aria-hidden="true">×</span> </button>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <label> Attachment</label>
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <thead>
                                                                              <tr class="bgGred">
                                                                                 <th>Upload</th>
                                                                                 <th style="width:50%;">Remark</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr *ngFor="let addSubFromGrp of getAddSub(adjPHdiv); let j = index"
                                                                                 [formGroup]="addSubFromGrp">
                                                                                 <td>
                                                                                    <div class="row">
                                                                                       <div class="col-md-9 nopadding">
                                                                                          <div class="form-group">
                                                                                             <input
                                                                                                *ngIf="addSubFromGrp.value.isdocLocUploadedClicked"
                                                                                                type="file"
                                                                                                id="hearingDetails({{i}}).addSubLoc({{j}}).fileLoc"
                                                                                                class="filetype form-control"
                                                                                                name="myfile"
                                                                                                (change)="onFileSelect($event,'hearing')">
                                                                                             <p *ngIf="!addSubFromGrp.value.isdocLocUploadedClicked || addSubFromGrp.value.fileLoc"
                                                                                                class="form-control pt10">
                                                                                                {{getFileNameDta(addSubFromGrp.value.fileLoc)}}
                                                                                             </p>
                                                                                          </div>
                                                                                       </div>
                                                                                       <div class="col-md-3 nopadding">
                                                                                          <label>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="addSubFromGrp.value.fileLoc"
                                                                                                title="Download file"
                                                                                                (click)="download(addSubFromGrp.value.fileLoc)"><i
                                                                                                   class="fa fa-download"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="(!addSubFromGrp.value.fileLoc || addSubFromGrp.value.fileLoc)  && !['DT_Viewer'].includes(roleName)"
                                                                                                title="Upload file"
                                                                                                (click)="uploadAddSubCopy(i,j)"><i
                                                                                                   class="fa fa-upload"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                (click)="deleteAddSubFile(i,j)"
                                                                                                *ngIf="(addSubFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                   class="fa fa-trash"
                                                                                                   aria-hidden="true"></i></a>
                                                                                          </label>
                                                                                       </div>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td> <input type="text"
                                                                                       class="form-control"
                                                                                       placeholder="" id="remarks"
                                                                                       formControlName="remarks"
                                                                                       [title]="addSubFromGrp.value.remarks ? addSubFromGrp.value.remarks: ''"
                                                                                       maxlength="100" />
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                                  <div class="modal-footer"
                                                                     style="border: none; padding-top: 0px;">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="modal.close('Save click')">Save</button>
                                                                  </div>
                                                               </ng-template>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <!-- <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Consultant</label>
                                                            <input type="text" class="form-control" id="caseId"
                                                               formControlName="consultant">
                                                         </div>
                                                      </div> -->

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Consultant</label>
                                         
                                                           <div *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" class="input-group">
                                                             <input type="text" class="form-control"
                                                               *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" placeholder=""
                                                               formControlName="consultant" readonly>
                                                             <button *ngIf="isToggleConsultantDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown()">
                                                               <i class="fa fa-pencil"></i>
                                                             </button> 
                                                           </div>
                                         
                                                           <select class="form-control form-select" id="consultant"
                                                               *ngIf="!isToggleConsultantDropdown || action==='create'" formControlName="consultant">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of consultantList">
                                                                 <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                                               </ng-container>
                                                             </select>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Hearing Record, If Any</label>
                                                            <input type="file" class="file-upload-default" #file>
                                                            <div class="attachment-block">
                                                               <span
                                                                  (click)="openPhRecordIfAnyPop(phRecordIfAnyFormPop, i)">
                                                                  <i class="icon fa fa fa-paperclip"
                                                                     style="cursor: pointer;"
                                                                     title="Attach file"></i><span
                                                                     *ngIf="getPhRecordIfAnyLength(i) != 0">
                                                                     {{getPhRecordIfAnyLength(i)}}
                                                                     Attachment</span></span>
                                                            </div>
                                                            <div>
                                                               <ng-template #phRecordIfAnyFormPop let-modal>
                                                                  <div class="modal-header"
                                                                     style="border: none; padding-bottom: 0px;">
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')"> <span
                                                                           aria-hidden="true">×</span> </button>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <label> Attachment</label>
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <thead>
                                                                              <tr class="bgGred">
                                                                                 <th>Upload</th>
                                                                                 <th style="width:50%;">Remark</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr *ngFor="let PhRecordIfAnyFromGrp of getPhRecordIfAny(adjPHdiv); let j = index"
                                                                                 [formGroup]="PhRecordIfAnyFromGrp">
                                                                                 <td>
                                                                                    <div class="row">
                                                                                       <div class="col-md-9 nopadding">
                                                                                          <div class="form-group">
                                                                                             <input
                                                                                                *ngIf="PhRecordIfAnyFromGrp.value.isdocLocUploadedClicked"
                                                                                                type="file"
                                                                                                id="hearingDetails({{i}}).phRecordIfAnyLoc({{j}}).fileLoc"
                                                                                                class="filetype form-control"
                                                                                                name="myfile"
                                                                                                (change)="onFileSelect($event,'hearing')">
                                                                                             <p *ngIf="!PhRecordIfAnyFromGrp.value.isdocLocUploadedClicked || PhRecordIfAnyFromGrp.value.fileLoc"
                                                                                                class="form-control pt10">
                                                                                                {{getFileNameDta(PhRecordIfAnyFromGrp.value.fileLoc)}}
                                                                                             </p>
                                                                                          </div>
                                                                                       </div>
                                                                                       <div class="col-md-3 nopadding">
                                                                                          <label>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="PhRecordIfAnyFromGrp.value.fileLoc"
                                                                                                title="Download file"
                                                                                                (click)="download(PhRecordIfAnyFromGrp.value.fileLoc)"><i
                                                                                                   class="fa fa-download"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="(!PhRecordIfAnyFromGrp.value.fileLoc || PhRecordIfAnyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                title="Upload file"
                                                                                                (click)="uploadPhRecordIfAnyCopy(i,j)"><i
                                                                                                   class="fa fa-upload"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                (click)="deletePhRecordIfAnyFile(i,j)"
                                                                                                *ngIf="(PhRecordIfAnyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                   class="fa fa-trash"
                                                                                                   aria-hidden="true"></i></a>
                                                                                          </label>
                                                                                       </div>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td> <input type="text"
                                                                                       class="form-control"
                                                                                       placeholder="" id="remarks"
                                                                                       formControlName="remarks"
                                                                                       [title]="PhRecordIfAnyFromGrp.value.remarks ? PhRecordIfAnyFromGrp.value.remarks: ''"
                                                                                       maxlength="100" />
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                                  <div class="modal-footer"
                                                                     style="border: none; padding-top: 0px;">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="modal.close('Save click')">Save</button>
                                                                  </div>
                                                               </ng-template>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="col-md-12">
                                                         <div class="form-group">
                                                            <label>Hearing discussion notes</label>
                                                            <textarea class="form-control" id="phDiscNotes" rows="4"
                                                               formControlName="phDiscNotes"
                                                               [ngClass]="{'errorBorder': adjPHdiv.get('phDiscNotes')?.hasError('maxlength') }"></textarea>
                                                         </div>
                                                         <div class="text-danger"
                                                            *ngIf=" adjPHdiv.get('phDiscNotes')?.touched && adjPHdiv.get('phDiscNotes')?.hasError('maxlength')">
                                                            Maximum of 1000 characters
                                                         </div>
                                                      </div>

                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                    </div>
                                    
                                    <div class="col-md-12 noPadding mt15 mb-3">
                                       <button class="btn btn-outline-primary mr-1"
                                          (click)="hearingSubmit()">Next</button>
                                    </div>
                                 </form>
                              </fieldset>
                           </div>
                        </div>


                        <div class="tab-pane fade" id="assd-2" role="tabpanel" aria-labelledby="assd-tab-vertical">
                           <div class="col-md-12 nopadding">
                              <p class="textCenter">Summon Details</p>
                              <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                 <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                 <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                              </span>
                           </div>

                           <div class="col-md-12 noPadding">
                              <form [formGroup]="summonForm">
                                 <div formArrayName="summonDetails">
                                    <ul class="nav nav-pills iris-gst-display-flex pl5" role="tablist">
                                       <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                                          <li class="nav-item" id="summonTab{{i}}"
                                             *ngFor="let div of getSummon(); let i=index" [formGroupName]="i">
                                             <a class="nav-link" id="summon1-tab" data-toggle="tab" role="tab"
                                                aria-controls="summon1-1" (click)="phaseChangedSummon('phase' + i, i)"
                                                [ngClass]="(selectedSummon === 'phase' + i) ? 'active': ''"
                                                aria-selected="true">Summon {{i+1}} <span *ngIf="i > 0"
                                                   (click)="removeSummon(i)" class="closeTab">x</span></a>
                                          </li>
                                       </ul>
                                       <div class="tab-content nopadding">
                                          <div class="tab-pane fade show active" id="summon1-1" role="tabpanel"
                                             aria-labelledby="summon1-tab">
                                             <div class="ml5" id="summonDiv{{i}}"
                                                *ngFor="let SummonData of getSummon(); let i=index" [formGroupName]="i">
                                                <div *ngIf="selectedSummon === 'phase' + i" class="show-tab-content">
                                                   <div class="row">
                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label class="fw">Date of Summon Scheduled</label>
                                                            <div id="iris-gst-filter-frmDate"
                                                               class="input-group date datepicker">
                                                               <p-calendar class="form-control"
                                                                  formControlName="summonDate" [monthNavigator]="true"
                                                                  [yearNavigator]="true"
                                                                  yearRange="2000:{{currentYear}}" dateFormat="dd-mm-yy"
                                                                  [showIcon]="true" dataType="string">
                                                               </p-calendar>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label class="fw">Notice for Summon </label>
                                                            <input type="file" class="file-upload-default" #file>
                                                            <div class="attachment-block">
                                                               <span
                                                                  (click)="openSummonNoticePop(summonNoticeFormPop, i)">
                                                                  <i class="icon fa fa fa-paperclip"
                                                                     style="cursor: pointer;"
                                                                     title="Attach file"></i><span
                                                                     *ngIf="getSummonNoticeLength(i) != 0">
                                                                     {{getSummonNoticeLength(i)}}
                                                                     Attachment</span></span>
                                                            </div>
                                                            <div>
                                                               <ng-template #summonNoticeFormPop let-modal>
                                                                  <div class="modal-header"
                                                                     style="border: none; padding-bottom: 0px;">
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')"> <span
                                                                           aria-hidden="true">×</span> </button>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <label> Attachment</label>
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <thead>
                                                                              <tr class="bgGred">
                                                                                 <th>Upload</th>
                                                                                 <th style="width:50%;">Remark</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr *ngFor="let summonNoticeFromGrp of getSummonNotice(SummonData); let j = index"
                                                                                 [formGroup]="summonNoticeFromGrp">
                                                                                 <td>
                                                                                    <div class="row">
                                                                                       <div class="col-md-9 nopadding">
                                                                                          <div class="form-group">
                                                                                             <input
                                                                                                *ngIf="summonNoticeFromGrp.value.isdocLocUploadedClicked"
                                                                                                type="file"
                                                                                                id="summonDetails({{i}}).summonNoticeLoc({{j}}).fileLoc"
                                                                                                class="filetype form-control"
                                                                                                name="myfile"
                                                                                                (change)="onFileSelect($event,'summon')">
                                                                                             <p *ngIf="!summonNoticeFromGrp.value.isdocLocUploadedClicked || summonNoticeFromGrp.value.fileLoc"
                                                                                                class="form-control pt10">
                                                                                                {{getFileNameDta(summonNoticeFromGrp.value.fileLoc)}}
                                                                                             </p>
                                                                                          </div>
                                                                                       </div>
                                                                                       <div class="col-md-3 nopadding">
                                                                                          <label>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="summonNoticeFromGrp.value.fileLoc"
                                                                                                title="Download file"
                                                                                                (click)="download(summonNoticeFromGrp.value.fileLoc)"><i
                                                                                                   class="fa fa-download"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="(!summonNoticeFromGrp.value.fileLoc || summonNoticeFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                title="Upload file"
                                                                                                (click)="uploadSummonNoticeCopy(i,j)"><i
                                                                                                   class="fa fa-upload"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                (click)="deleteSummonNoticeCopyFile(i,j)"
                                                                                                *ngIf="(summonNoticeFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                   class="fa fa-trash"
                                                                                                   aria-hidden="true"></i></a>
                                                                                          </label>
                                                                                       </div>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td> <input type="text"
                                                                                       class="form-control"
                                                                                       placeholder="" id="remarks"
                                                                                       formControlName="remarks"
                                                                                       [title]="summonNoticeFromGrp.value.remarks ? summonNoticeFromGrp.value.remarks:''"
                                                                                       maxlength="100" />
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                                  <div class="modal-footer"
                                                                     style="border: none; padding-top: 0px;">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="modal.close('Save click')">Save</button>
                                                                  </div>
                                                               </ng-template>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label class="fw">Adjourned/ New Summon</label>
                                                            <select class="form-control form-select" id="adjournedOrNot"
                                                               formControlName="adjournedOrNot"
                                                               (change)="changeSummonOrNot($event,i)">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of adjPHAdjuorNotList">
                                                                  <option [value]="obj.value">{{obj.label}}</option>
                                                               </ng-container>
                                                            </select>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label class="fw">Attended </label>
                                                            <select class="form-control form-select" id="attended"
                                                               formControlName="attended">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of adjAttend">
                                                                  <option [value]="obj.value">{{obj.label}}</option>
                                                               </ng-container>
                                                            </select>
                                                         </div>
                                                      </div>
                                                   </div>

                                                   <div class="row">
                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label class="fw">Person Who Attended</label>
                                                            <input type="text" class="form-control"
                                                               id="personWhoAttended"
                                                               formControlName="personWhoAttended">
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label class="fw">Date of Additional Written
                                                               Submissions</label>
                                                            <div id="iris-gst-filter-frmDate"
                                                               class="input-group date datepicker">
                                                               <p-calendar class="form-control"
                                                                  formControlName="addWritSubDate"
                                                                  [monthNavigator]="true" [yearNavigator]="true"
                                                                  [maxDate]="maxDate" yearRange="2000:{{currentYear}}"
                                                                  dateFormat="dd-mm-yy" [showIcon]="true"
                                                                  dataType="string">
                                                               </p-calendar>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label class="fw">Upload the Additional Submission </label>
                                                            <input type="file" class="file-upload-default" #file>
                                                            <div class="attachment-block">
                                                               <span
                                                                  (click)="openSummonAddSubPop(summonaddSubFormPop, i)">
                                                                  <i class="icon fa fa fa-paperclip"
                                                                     style="cursor: pointer;"
                                                                     title="Attach file"></i><span
                                                                     *ngIf="getSummonAddSubLength(i) != 0">
                                                                     {{getSummonAddSubLength(i)}}
                                                                     Attachment</span></span>
                                                            </div>
                                                            <div>
                                                               <ng-template #summonaddSubFormPop let-modal>
                                                                  <div class="modal-header"
                                                                     style="border: none; padding-bottom: 0px;">
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')"> <span
                                                                           aria-hidden="true">×</span> </button>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <label> Attachment</label>
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <thead>
                                                                              <tr class="bgGred">
                                                                                 <th>Upload</th>
                                                                                 <th style="width:50%;">Remark</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr *ngFor="let addSubSummonFromGrp of getSummonAddSub(SummonData); let j = index"
                                                                                 [formGroup]="addSubSummonFromGrp">
                                                                                 <td>
                                                                                    <div class="row">
                                                                                       <div class="col-md-9 nopadding">
                                                                                          <div class="form-group">
                                                                                             <input
                                                                                                *ngIf="addSubSummonFromGrp.value.isdocLocUploadedClicked"
                                                                                                type="file"
                                                                                                id="summonDetails({{i}}).addSubLoc({{j}}).fileLoc"
                                                                                                class="filetype form-control"
                                                                                                name="myfile"
                                                                                                (change)="onFileSelect($event,'summon')">
                                                                                             <p *ngIf="!addSubSummonFromGrp.value.isdocLocUploadedClicked || addSubSummonFromGrp.value.fileLoc"
                                                                                                class="form-control pt10">
                                                                                                {{getFileNameDta(addSubSummonFromGrp.value.fileLoc)}}
                                                                                             </p>
                                                                                          </div>
                                                                                       </div>
                                                                                       <div class="col-md-3 nopadding">
                                                                                          <label>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="addSubSummonFromGrp.value.fileLoc"
                                                                                                title="Download file"
                                                                                                (click)="download(addSubSummonFromGrp.value.fileLoc)"><i
                                                                                                   class="fa fa-download"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="(!addSubSummonFromGrp.value.fileLoc || addSubSummonFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                title="Upload file"
                                                                                                (click)="uploadSummonAddSubCopy(i,j)"><i
                                                                                                   class="fa fa-upload"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                (click)="deleteSummonAddSubFile(i,j)"
                                                                                                *ngIf="(addSubSummonFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                   class="fa fa-trash"
                                                                                                   aria-hidden="true"></i></a>
                                                                                          </label>
                                                                                       </div>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td> <input type="text"
                                                                                       class="form-control"
                                                                                       placeholder="" id="remarks"
                                                                                       formControlName="remarks"
                                                                                       [title]="addSubSummonFromGrp.value.remarks ? addSubSummonFromGrp.value.remarks :''"
                                                                                       maxlength="100" />
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                                  <div class="modal-footer"
                                                                     style="border: none; padding-top: 0px;">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="modal.close('Save click')">Save</button>
                                                                  </div>
                                                               </ng-template>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label class="fw">Summon Record Received From Dept.</label>
                                                            <input type="file" class="file-upload-default" #file>
                                                            <div class="attachment-block">
                                                               <span
                                                                  (click)="openDeptSummonReceivedPop(summonRecordIfAnyFormPop, i)">
                                                                  <i class="icon fa fa fa-paperclip"
                                                                     style="cursor: pointer;"
                                                                     title="Attach file"></i><span
                                                                     *ngIf="getDeptSummonReceivedLength(i) != 0">
                                                                     {{getDeptSummonReceivedLength(i)}}
                                                                     Attachment</span></span>
                                                            </div>
                                                            <div>
                                                               <ng-template #summonRecordIfAnyFormPop let-modal>
                                                                  <div class="modal-header"
                                                                     style="border: none; padding-bottom: 0px;">
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')"> <span
                                                                           aria-hidden="true">×</span> </button>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <label> Attachment</label>
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <thead>
                                                                              <tr class="bgGred">
                                                                                 <th>Upload</th>
                                                                                 <th style="width:50%;">Remark</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr *ngFor="let deptSummonReceivedFromGrp of getDeptSummonReceived(SummonData); let j = index"
                                                                                 [formGroup]="deptSummonReceivedFromGrp">
                                                                                 <td>
                                                                                    <div class="row">
                                                                                       <div class="col-md-9 nopadding">
                                                                                          <div class="form-group">
                                                                                             <input
                                                                                                *ngIf="deptSummonReceivedFromGrp.value.isdocLocUploadedClicked"
                                                                                                type="file"
                                                                                                id="summonDetails({{i}}).deptSummonReceivedLoc({{j}}).fileLoc"
                                                                                                class="filetype form-control"
                                                                                                name="myfile"
                                                                                                (change)="onFileSelect($event,'summon')">
                                                                                             <p *ngIf="!deptSummonReceivedFromGrp.value.isdocLocUploadedClicked || deptSummonReceivedFromGrp.value.fileLoc"
                                                                                                class="form-control pt10">
                                                                                                {{getFileNameDta(deptSummonReceivedFromGrp.value.fileLoc)}}
                                                                                             </p>
                                                                                          </div>
                                                                                       </div>
                                                                                       <div class="col-md-3 nopadding">
                                                                                          <label>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="deptSummonReceivedFromGrp.value.fileLoc"
                                                                                                title="Download file"
                                                                                                (click)="download(deptSummonReceivedFromGrp.value.fileLoc)"><i
                                                                                                   class="fa fa-download"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="(!deptSummonReceivedFromGrp.value.fileLoc || deptSummonReceivedFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                title="Upload file"
                                                                                                (click)="uploadDeptSummonReceivedCopy(i,j)"><i
                                                                                                   class="fa fa-upload"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                (click)="deleteDeptSummonReceivedFile(i,j)"
                                                                                                *ngIf="(deptSummonReceivedFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                   class="fa fa-trash"
                                                                                                   aria-hidden="true"></i></a>
                                                                                          </label>
                                                                                       </div>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td> <input type="text"
                                                                                       class="form-control"
                                                                                       placeholder="" id="remarks"
                                                                                       formControlName="remarks"
                                                                                       [title]="deptSummonReceivedFromGrp.value.remarks ? deptSummonReceivedFromGrp.value.remarks: ''"
                                                                                       maxlength="100" />
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                                  <div class="modal-footer"
                                                                     style="border: none; padding-top: 0px;">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="modal.close('Save click')">Save</button>
                                                                  </div>
                                                               </ng-template>
                                                            </div>
                                                         </div>
                                                      </div>
                                                      <div class="col-md-12">
                                                         <div class="form-group">
                                                            <label class="fw">Hearing Discussion Notes</label>
                                                            <textarea class="form-control" id="discNotes" rows="4"
                                                               formControlName="discNotes"
                                                               [ngClass]="{'errorBorder': SummonData.get('discNotes')?.hasError('maxlength') }"></textarea>
                                                         </div>
                                                         <div class="text-danger"
                                                            *ngIf=" SummonData.get('discNotes')?.touched && SummonData.get('discNotes')?.hasError('maxlength')">
                                                            Maximum of 1000 characters
                                                         </div>
                                                      </div>

                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </ul>
                                 </div>

                                 <div class="col-md-12 noPadding mt15">
                                    <button class="btn btn-outline-primary mr-1" (click)="summonSubmit()">Next</button>
                                 </div>
                              </form>
                           </div>
                        </div>



                        <div class="tab-pane fade" id="ascc-2" role="tabpanel" aria-labelledby="ascc-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="col-md-12 nopadding">
                              <p class="textCenter">Consultant Charges</p>
                              <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                 <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                 <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                              </span>
                           </div>
                           <fieldset [disabled]="action == 'view'">
                              <form [formGroup]="consultantForm">
                                 <div formArrayName="cc">
                                    <div class="col-md-12 nopadding">
                                       <div class="row">
                                          <div class="col-md-12 table-responsive">
                                             <table class="search-table inner">
                                                <tr class="bgGred">
                                                   <th class=" w-40">Sr.No.</th>
                                                   <th>Name of the Consultant</th>
                                                   <th>Name of Consulting Firm</th>
                                                   <th>Nature of Work</th>
                                                   <th>Fees Charged (₹)</th>
                                                   <th class="w-300">Supporting Document</th>
                                                   <th>Email id</th>
                                                   <th>Contact Number</th>
                                                   <th class="w-80"> Action </th>
                                                </tr>
                                                <ng-container>
                                                   <tr *ngFor="let div of consultantInvArr() let i=index;"
                                                      [formGroup]="div">
                                                      <td class="w-40">{{i+1}}</td>
                                                      <td>
                                                         <!-- <input type="text" class="form-control" id="consultant"
                                                         placeholder="" formControlName="consultant"
                                                         [title]="div.value.consultant ? div.value.consultant: ''"> -->
                                                         <div *ngIf="isToggleConsultantDropdownCC && action==='update' || action==='view'" class="input-group">
                                                            <input type="text" class="form-control"
                                                              *ngIf="isToggleConsultantDropdownCC && action==='update' || action==='view'" placeholder=""
                                                              formControlName="consultant" readonly>
                                                            <button *ngIf="isToggleConsultantDropdownCC && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('CC')">
                                                              <i class="fa fa-pencil"></i>
                                                            </button> 
                                                          </div>
                                                  
                                                            <select class="form-control form-select" id="consultant"
                                                              *ngIf="!isToggleConsultantDropdownCC || action==='create'" formControlName="consultant" (change)="consultantDataAutofil(i)">
                                                              <option [value]="null">-- Select --</option>
                                                              <ng-container *ngFor="let obj of consultantList">
                                                              <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                                              </ng-container>
                                                            </select>
                                                      </td>
                                                      <td>
                                                         <input type="text" class="form-control" id="tax"
                                                         placeholder="" formControlName="firm"
                                                         [title]="div.value.firm ? div.value.firm : ''" 
                                                         readonly style="cursor: not-allowed;">
                                                      </td>
                                                      <td><input type="text" class="form-control" id="natureOfWork"
                                                            placeholder="" formControlName="natureOfWork"
                                                            [title]="div.value.natureOfWork ? div.value.natureOfWork: ''">
                                                      </td>
                                                      <td><input type="text" class="form-control align-right" id="fees"
                                                            placeholder="" formControlName="fees"
                                                            (input)="feesChargedChanged()" maxlength="19" InrFormat
                                                            (paste)="handlePaste($event)"></td>
                                                      <td><span (click)="openCCDetailPop(consultantFormPop, i)"><i
                                                               class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                                               title="Attach file"></i><span
                                                               *ngIf="getConsultantLength(i) != 0">{{getConsultantLength(i)}}
                                                               Attachment</span></span></td>
                                                      <td>
                                                         <input type="text" class="form-control" placeholder=""
                                                         formControlName="email" 
                                                         [title]="div.value.email? div.value.email: ''" readonly  style="cursor: not-allowed;">
                                                      </td>
                                                      <td><input class="form-control" id="contact"
                                                            formControlName="contact"
                                                            [title]="div.value.contact ? div.value.contact: ''"
                                                            maxlength="12"
                                                            readonly  style="cursor: not-allowed;">
                                                      </td>
                                                      <td class="w-80">
                                                         <a class=" addExtLetter" (click)="addConsultantInv()"><i
                                                               class="fa fa-plus" title="Add row"></i></a>
                                                         <a class=" addExtLetter" *ngIf="i != 0"
                                                            (click)="openRmvconsultantPop(removeConsultantPopup)"><i
                                                               class="fa fa-minus"></i></a>
                                                         <ng-template #removeConsultantPopup let-modal>
                                                            <div class="modal-header">
                                                               <h4 class="modal-title" id="modal-basic-title">Remove
                                                                  Warning!</h4>
                                                               <button type="button" class="close" aria-label="Close"
                                                                  (click)="modal.dismiss('Cross click')">
                                                                  <span aria-hidden="true">×</span>
                                                               </button>
                                                            </div>
                                                            <div class="modal-body">
                                                               <p>Are you sure, you want to remove?</p>
                                                            </div>
                                                            <div class="modal-footer">
                                                               <button type="button" class="btn btn-outline-dark"
                                                                  (click)="rmConsultantInv(i)">Remove</button>
                                                            </div>
                                                         </ng-template>
                                                      </td>
                                                      <ng-template #consultantFormPop let-modal>
                                                         <div class="modal-header"
                                                            style="border: none; padding-bottom: 0px;">
                                                            <button type="button" class="close" aria-label="Close"
                                                               (click)="modal.dismiss('Cross click')"> <span
                                                                  aria-hidden="true">×</span> </button>
                                                         </div>
                                                         <div class="col-md-12"> <label>Attachment</label>
                                                            <div class="table-responsive">
                                                               <table class="table table-bordered">
                                                                  <thead>
                                                                     <tr class="bgGred">
                                                                        <th>Upload </th>
                                                                        <th style="width:50%;">Remark </th>
                                                                     </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                     <tr *ngFor="let consultantFromGrp of getConsultant(div); let j=index"
                                                                        [formGroup]="consultantFromGrp">
                                                                        <td>
                                                                           <div class="row">
                                                                              <div class="col-md-9 nopadding">
                                                                                 <div class="form-group">
                                                                                    <input
                                                                                       *ngIf="consultantFromGrp.value.isdocLocUploadedClicked"
                                                                                       type="file"
                                                                                       id="cc({{i}}).supportingDocsLoc({{j}}).fileLoc"
                                                                                       class="filetype form-control"
                                                                                       name="myfile"
                                                                                       (change)="onFileSelect($event,'consultant')">
                                                                                    <p *ngIf="!consultantFromGrp.value.isdocLocUploadedClicked || consultantFromGrp.value.fileLoc"
                                                                                       class="form-control pt10">
                                                                                       {{getFileNameDta(consultantFromGrp.value.fileLoc)}}
                                                                                    </p>
                                                                                 </div>
                                                                              </div>
                                                                              <div class="col-md-3 nopadding">
                                                                                 <label>
                                                                                    <a class="downupIcon"
                                                                                       *ngIf="consultantFromGrp.value.fileLoc"
                                                                                       title="Download file"
                                                                                       (click)="download(consultantFromGrp.value.fileLoc)"><i
                                                                                          class="fa fa-download"></i></a>
                                                                                    <a class="downupIcon"
                                                                                       *ngIf="!(consultantFromGrp.value.fileLoc || consultantFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                       title="Upload file"
                                                                                       (click)="uploadConsultant(i,j)"><i
                                                                                          class="fa fa-upload"></i></a>
                                                                                    <a class="downupIcon"
                                                                                       (click)="deleteConsultant(i,j)"
                                                                                       *ngIf="(consultantFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                          class="fa fa-trash"
                                                                                          aria-hidden="true"></i></a>
                                                                                 </label>

                                                                              </div>
                                                                           </div>
                                                                        </td>
                                                                        <td> <input type="text" class="form-control"
                                                                              placeholder="" id="remarks"
                                                                              formControlName="remarks"
                                                                              [title]="consultantFromGrp.value.remarks ? consultantFromGrp.value.remarks: ''"
                                                                              maxlength="100" />
                                                                        </td>
                                                                     </tr>
                                                                  </tbody>
                                                               </table>
                                                            </div>
                                                         </div>
                                                         <div class="modal-footer"
                                                            style="border: none; padding-top: 0px;">
                                                            <button type="button" class="btn btn-outline-dark"
                                                               (click)="modal.close('Save click')">Save</button>
                                                         </div>
                                                      </ng-template>
                                                   </tr>
                                                   <tr>
                                                      <th colspan="4">Total</th>
                                                      <td class="align-right bold-font">{{feesChargedTotal | INR : 0}}
                                                      </td>
                                                   </tr>
                                                </ng-container>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-12 noPadding mt15 mb-3">
                                       <button class="btn btn-outline-primary mr-1"
                                          (click)="consultantFormSubmit()">Next</button>
                                    </div>
                                 </div>
                              </form>
                           </fieldset>
                        </div>

                        <div class="tab-pane fade" id="ascon-2" role="tabpanel" aria-labelledby="ascon-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="col-md-12 nopadding">
                              <p class="textCenter">Whether Order Received? </p>
                              <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                 <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                 <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                              </span>
                           </div>
                           <fieldset [disabled]="action == 'view'">
                              <form [formGroup]="orderReceivedForm">
                                 <div class="row mt5">
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label for="exampleInputPassword1">Whether Order Received? </label>
                                          <select class="form-control" id="location" id="whetherOrderReceived"
                                             formControlName="whetherOrderReceived"
                                             (change)="whetherOrderReceivedChanged()">
                                             <ng-container *ngFor="let obj of OrderReceived">
                                                <option [value]="obj.value">{{obj.label}}</option>
                                             </ng-container>
                                          </select>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-12 mt15 mb-3">
                                    <button *ngIf="caseType !='PENALTY'" class="btn btn-outline-primary mr-1"
                                       (click)="orderReceived(2,'NEW')">Update</button>
                                    <button *ngIf="caseType ==='PENALTY'" class="btn btn-outline-primary mr-1"
                                       (click)="orderReceived(2,'PENALTY')">Update</button>

                                 </div>
                              </form>
                           </fieldset>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>


      <div class="tab-pane fade" id="adjudp3-1" role="tabpanel" aria-labelledby="adjudp3-tab">
         <div class="card borderOrange">
            <div class="card-body ptl10 plr010">
               <div class="row">
                  <div class="col-2" id="verticalTab8">
                     <ul class="nav nav-tabs nav-tabs-vertical" role="tablist" *ngIf="!sidemenu">
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab10Disable && !(action == 'update') && !(action == 'view'))}">
                           <a class="nav-link active" id="assord-tab-vertical" data-toggle="tab" href="#assord-2"
                              role="tab" aria-controls="assord-2" aria-selected="true" #orderTab>
                              Order
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab11Disable && !(action == 'update') && !(action == 'view'))}">
                           <a class="nav-link" id="asstp-tab-vertical" data-toggle="tab" href="#asstp-2" role="tab"
                              aria-controls="asstp-2" aria-selected="false" #taxTab>
                              Tax Payment and Refund Details
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab12Disable && !(action == 'update') && !(action == 'view'))}">
                           <a class="nav-link" id="asct-tab-vertical" data-toggle="tab" href="#asct-2" role="tab"
                              aria-controls="asct-2" aria-selected="false" #cumulativeTab>
                              Cumulative Tax Details
                           </a>
                        </li>
                        <li class="nav-item" #cumulativeTab>
                           <a *ngIf="caseType !='PENALTY'" class="btn btn-outline-primary w100"
                              (click)="cumulativeFormSubmit(3,'NEW')" [ngClass]="{'disabled': (action == 'view')}">
                              Update</a>
                           <a *ngIf="caseType ==='PENALTY'" class="btn btn-outline-primary w100"
                              (click)="cumulativeFormSubmit(3,'PENALTY')" [ngClass]="{'disabled': (action == 'view')}">
                              Update</a>
                        </li>

                     </ul>
                  </div>
                  <div class="col-10 border " id="tabArea8" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
                     <div class="tab-content tab-content-vertical">
                        <div class="tab-pane fade show active" id="assord-2" role="tabpanel"
                           aria-labelledby="assord-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="row">
                              <div class="col-md-12">
                                 <p class="textCenter">Order </p>
                                 <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                    <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                    <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                 </span>
                              </div>
                              <fieldset [disabled]="action == 'view'">
                                 <form [formGroup]="orderForm">
                                    <div formArrayName="orderDetails">
                                       <div class="col-md-12">
                                          <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                                             <li class="nav-item" id="rnoticeTab{{i}}"
                                                *ngFor="let div of getOrderDetails(); let i=index" [formGroupName]="i">
                                                <a class="nav-link" id="rnotice1-tab" data-toggle="tab" role="tab"
                                                   (click)="phaseRectification('phase' + i, i)"
                                                   [ngClass]="(selectedRectif === 'phase' + i) ? 'active': ''"
                                                   aria-controls="rnotice1-1" aria-selected="true"> Order {{i+1}} <span
                                                      *ngIf="i > 0" (click)="removeOrderDetails(i)"
                                                      class="closeTab">x</span></a>
                                             </li>
                                          </ul>
                                          <div class="tab-content nopadding">
                                             <div class="tab-pane fade show active" id="rnotice1-1" role="tabpanel"
                                                aria-labelledby="rnotice1-tab">
                                                <div class="col-md-12 noPadding" id="rnoticeDiv{{i}}"
                                                   *ngFor="let rnoticediv of getOrderDetails(); let i=index"
                                                   [formGroupName]="i">
                                                   <div *ngIf="selectedRectif === 'phase' + i" class="show-tab-content">
                                                      <div class="col-md-12 nopadding">
                                                         <button class="btn btn-outline-primary mb15 "
                                                            (click)="removeOrderDetails(i)" *ngIf="i != 0"
                                                            style="margin-left: 5px;">Remove Order</button>
                                                      </div>
                                                      <div class="row">
                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Order u/s <span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <input type="text" formControlName="orderUs"
                                                                  class="form-control" id="orderUs"
                                                                  [ngClass]="{ errorBorder:isSubmittedOrder && !getOrderDetails()[i].controls['orderUs'].value}">

                                                               <!-- <select class="form-control form-select" formControlName="orderUs" id="orderUs"
                                                               [ngClass]="{ errorBorder:isSubmittedOrder && !getOrderDetails()[i].controls['orderUs'].value}">
                                                                  <option [value]="null"> -- Select --</option>
                                                                  <ng-container *ngFor=" let obj of orderData">
                                                                     <option [value]="obj.value"> {{ obj.value}} </option>
                                                                  </ng-container>
                                                               </select> -->
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedOrder && !getOrderDetails()[i].controls['orderUs'].value">
                                                               <span class="text-danger">Order u/s Required</span>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Order Ref. Number/DIN<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <input type="text" class="form-control" placeholder=""
                                                                  formControlName="orderNo" id="orderNo"
                                                                  [ngClass]="{ errorBorder:isSubmittedOrder && !getOrderDetails()[i].controls['orderNo'].value}">
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedOrder && !getOrderDetails()[i].controls['orderNo'].value">
                                                               <span class="text-danger">Order Ref. Number/DIN</span>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Date of Order<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar class="form-control"
                                                                     formControlName="orderDate" id="orderDate"
                                                                     [maxDate]="maxDate" [monthNavigator]="true"
                                                                     [yearNavigator]="true"
                                                                     yearRange="2010:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string"
                                                                     [ngClass]="{errorBorder: isSubmittedOrder && !getOrderDetails()[i].controls['orderDate'].value}"></p-calendar>
                                                               </div>
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedOrder && !getOrderDetails()[i].controls['orderDate'].value">
                                                               <span class="text-danger"
                                                                  *ngIf="!getOrderDetails()[i].controls['orderDate'].value ">Date
                                                                  of Order Required</span>
                                                            </div>
                                                         </div>


                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Date of Receipt of Order<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar class="form-control"
                                                                     formControlName="orderRecepitDate"
                                                                     id="orderRecepitDate" [maxDate]="maxDate"
                                                                     [monthNavigator]="true" [yearNavigator]="true"
                                                                     yearRange="2010:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string"
                                                                     [ngClass]="{errorBorder: isSubmittedOrder && !getOrderDetails()[i].controls['orderRecepitDate'].value}"></p-calendar>
                                                               </div>
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedOrder && !getOrderDetails()[i].controls['orderRecepitDate'].value">
                                                               <span class="text-danger"
                                                                  *ngIf="!getOrderDetails()[i].controls['orderRecepitDate'].value ">Date
                                                                  of Receipt of Order Required</span>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Mode of Receipt of Order </label>
                                                               <select class="form-control form-select"
                                                                  formControlName="orderReceiptMode"
                                                                  id="orderReceiptMode">
                                                                  <option [value]="null"> -- Select --</option>
                                                                  <ng-container *ngFor=" let obj of rectifModeOptions">
                                                                     <option [value]="obj.value"> {{ obj.value}}
                                                                     </option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Order Passed By</label>
                                                               <select class="form-control form-select"
                                                                  id="orderPassedBy" formControlName="orderPassedBy">
                                                                  <option [value]="null"> -- Select --</option>
                                                                  <ng-container *ngFor=" let obj of orderPassedBy">
                                                                     <option [value]="obj.value"> {{ obj.value}}
                                                                     </option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                         </div>


                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Order Copy<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span> </label>
                                                               <input type="file" class="file-upload-default" #file>
                                                               <div class="attachment-block"
                                                                  [ngClass]="{'errorBorder': isSubmittedOrder && getOrderLocength(i) === 0 }">
                                                                  <span (click)="openOrderGrpPop(orderFormPop, i)"> <i
                                                                        class="icon fa fa fa-paperclip"
                                                                        style="cursor: pointer;"
                                                                        title="Attach file"></i><span
                                                                        *ngIf="getOrderLocength(i) != 0">
                                                                        {{getOrderLocength(i)}} Attachment</span></span>
                                                               </div>
                                                               <div
                                                                  *ngIf="isSubmittedOrder && getOrderLocength(i) === 0">
                                                                  <span class="text-danger">Order Copy Required</span>
                                                               </div>
                                                               <div>
                                                                  <ng-template #orderFormPop let-modal>
                                                                     <div class="modal-header"
                                                                        style="border: none; padding-bottom: 0px;">
                                                                        <button type="button" class="close"
                                                                           aria-label="Close"
                                                                           (click)="modal.dismiss('Cross click')"> <span
                                                                              aria-hidden="true">×</span> </button>
                                                                     </div>
                                                                     <div class="col-md-12">
                                                                        <label> Attachment</label>
                                                                        <div class="table-responsive">
                                                                           <table class="table table-bordered">
                                                                              <thead>
                                                                                 <tr class="bgGred">
                                                                                    <th>Upload</th>
                                                                                    <th style="width:50%;">Remark</th>
                                                                                 </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                                 <tr *ngFor="let orderFromGrp of getOrderLoc(rnoticediv); let j=index"
                                                                                    [formGroup]="orderFromGrp">
                                                                                    <td>
                                                                                       <div class="row">
                                                                                          <div
                                                                                             class="col-md-9 nopadding">
                                                                                             <div class="form-group">
                                                                                                <input
                                                                                                   *ngIf="orderFromGrp.value.isdocLocUploadedClicked"
                                                                                                   type="file"
                                                                                                   id="orderDetails({{i}}).orderLoc({{j}}).fileLoc"
                                                                                                   class="filetype form-control"
                                                                                                   name="myfile"
                                                                                                   (change)="onFileSelect($event,'rectif')">
                                                                                                <p *ngIf="!orderFromGrp.value.isdocLocUploadedClicked || orderFromGrp.value.fileLoc"
                                                                                                   class="form-control pt10">
                                                                                                   {{getFileNameDta(orderFromGrp.value.fileLoc)}}
                                                                                                </p>
                                                                                             </div>
                                                                                          </div>
                                                                                          <div
                                                                                             class="col-md-3 nopadding">
                                                                                             <label>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="orderFromGrp.value.fileLoc"
                                                                                                   title="Download file"
                                                                                                   (click)="download(orderFromGrp.value.fileLoc)"><i
                                                                                                      class="fa fa-download"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="!(orderFromGrp.value.fileLoc || orderFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                   title="Upload file"
                                                                                                   (click)="uploadOrderLoc(i,j)"><i
                                                                                                      class="fa fa-upload"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   (click)="deleteOrderLocFile(i,j)"
                                                                                                   *ngIf="(orderFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                      class="fa fa-trash"
                                                                                                      aria-hidden="true"></i></a>
                                                                                             </label>

                                                                                          </div>
                                                                                       </div>
                                                                                    </td>
                                                                                    <td> <input type="text"
                                                                                          class="form-control"
                                                                                          placeholder="" id="remarks"
                                                                                          formControlName="remarks"
                                                                                          [title]="orderFromGrp.value.remarks ? orderFromGrp.value.remarks : ''"
                                                                                          maxlength="100" />
                                                                                    </td>
                                                                                 </tr>
                                                                              </tbody>
                                                                           </table>
                                                                        </div>
                                                                     </div>
                                                                     <div class="modal-footer"
                                                                        style="border: none; padding-top: 0px;">
                                                                        <button type="button"
                                                                           class="btn btn-outline-dark"
                                                                           (click)="modal.close('Save click')">Save</button>
                                                                     </div>
                                                                  </ng-template>
                                                               </div>
                                                            </div>
                                                         </div>


                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Outcome <span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <select class="form-control form-select" id="outcome"
                                                                  formControlName="outcome"
                                                                  [ngClass]="{errorBorder: isSubmittedOrder && !getOrderDetails()[i].controls['outcome'].value}">
                                                                  <option [value]="null"> -- Select --</option>
                                                                  <ng-container *ngFor=" let obj of outcomeData">
                                                                     <option [value]="obj.value"> {{ obj.value}}
                                                                     </option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedOrder && !getOrderDetails()[i].controls['outcome'].value">
                                                               <span class="text-danger"
                                                                  *ngIf="!getOrderDetails()[i].controls['outcome'].value ">Outcome
                                                                  Required</span>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Rectification Applied </label>
                                                               <select class="form-control form-select"
                                                                  id="isAppliedForRectif"
                                                                  formControlName="isAppliedForRectif"
                                                                  (change)="changeadjOrNot($event, i)">
                                                                  <option [value]="null">-- Select --</option>
                                                                  <ng-container
                                                                     *ngFor="let obj of whetherRectification">
                                                                     <option [value]="obj.value">{{obj.label}}</option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Whether Rectified Order Received</label>
                                                               <select class="form-control form-select"
                                                                  id="rectifOrderReceived"
                                                                  formControlName="rectifOrderReceived"
                                                                  (change)="changeOrderDetails($event, i)">
                                                                  <option [value]="null">-- Select --</option>
                                                                  <ng-container *ngFor="let obj of yesNoDropdown">
                                                                     <option [value]="obj.value">{{obj.label}}</option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-12">
                                                            <div class="form-group">
                                                               <label>Order Summary </label>
                                                               <textarea class="form-control" id="orderSummary"
                                                                  formControlName="orderSummary" rows="4"
                                                                  [ngClass]="{ errorBorder: rnoticediv.controls.orderSummary.hasError('maxlength')}">
                                                               </textarea>
                                                            </div>
                                                            <div class="text-danger"
                                                               *ngIf="rnoticediv.get('orderSummary')?.touched && rnoticediv.get('orderSummary')?.hasError('maxlength')">
                                                               Maximum of 1000 characters
                                                            </div>
                                                         </div>

                                                         <div class="col-md-12">
                                                            <div class="form-group">
                                                               <label>Internal Remarks</label>
                                                               <textarea class="form-control" id="internalRemarks"
                                                                  formControlName="internalRemarks" rows="4"
                                                                  [ngClass]="{ errorBorder: rnoticediv.controls.internalRemarks.hasError('maxlength')}">
                                                               </textarea>
                                                            </div>
                                                            <div class="text-danger"
                                                               *ngIf="rnoticediv.get('internalRemarks')?.touched && rnoticediv.get('internalRemarks')?.hasError('maxlength')">
                                                               Maximum of 1000 characters
                                                            </div>
                                                         </div>

                                                         <div class="col-md-12"
                                                            *ngIf="getOrderDetails()[i].controls['isAppliedForRectif'].value == 'true' || getOrderDetails()[i].controls['isAppliedForRectif'].value == true">
                                                            <div class="accordion accordion-solid-header"
                                                               id="accordion-12" role="tablist">
                                                               <div class="card border-bottom">
                                                                  <div class="card-header" role="tab"
                                                                     id="heading-02999">
                                                                     <h6 class="mb-0">
                                                                        <a class="bg-transparent text-dark main p-0"
                                                                           data-toggle="collapse" href="#collapse-02"
                                                                           aria-expanded="true"
                                                                           aria-controls="collapse-2">
                                                                           Details of Rectifications
                                                                        </a>
                                                                     </h6>
                                                                  </div>
                                                                  <div id="collapse-02" class="collapse show"
                                                                     role="tabpanel" aria-labelledby="heading-02"
                                                                     data-parent="#accordion-02">
                                                                     <div class="card-body">
                                                                        <div class=" row">
                                                                           <div class="col-md-12">
                                                                              <div class="search-table-outter wrapper">
                                                                                 <table class="search-table inner ">
                                                                                    <thead>
                                                                                       <tr class="bgGred">
                                                                                          <th class=" w-40">Sr.No.</th>
                                                                                          <th>Order Detail For Which
                                                                                             Rectification Required</th>
                                                                                          <th>Rectification Application
                                                                                             Date <span
                                                                                                class="mandatetd"><i
                                                                                                   class="fa fa-asterisk"
                                                                                                   style="font-size: 7px;"></i></span>
                                                                                          </th>
                                                                                          <th>Copy of Application</th>
                                                                                          <th>Acknowledgement Copy</th>
                                                                                          <th>Mode of Application </th>
                                                                                          <th>Rectification Details
                                                                                          </th>
                                                                                          <th class="w-80">Action</th>
                                                                                       </tr>

                                                                                    </thead>
                                                                                    <tbody>
                                                                                       <tr *ngFor="let div of getFormControlRectif(rnoticediv) let k=index;"
                                                                                          [formGroup]="div">
                                                                                          <td class="w-40">{{k+1}}</td>
                                                                                          <td><input type="text"
                                                                                                class="form-control"
                                                                                                id="tax" placeholder=""
                                                                                                formControlName="docNo"
                                                                                                [title]="div.value.docNo ? div.value.docNo: ''">
                                                                                          </td>

                                                                                          <td>
                                                                                             <div
                                                                                                id="iris-gst-filter-frmDate"
                                                                                                class="input-group date datepicker"
                                                                                                [title]="div.value.rectifDate ? div.value.rectifDat: ''">
                                                                                                <p-calendar
                                                                                                   appendTo="body"
                                                                                                   class="form-control"
                                                                                                   formControlName="rectifDate"
                                                                                                   dateFormat="dd-mm-yy"
                                                                                                   [showIcon]="true"
                                                                                                   dataType="string"
                                                                                                   [maxDate]="maxDate"
                                                                                                   [monthNavigator]="true"
                                                                                                   [yearNavigator]="true"
                                                                                                   yearRange="2010:{{currentYear}}"
                                                                                                   dateFormat="dd-mm-yy"></p-calendar>
                                                                                             </div>
                                                                                             <div
                                                                                                *ngIf="isSubmittedRectif && div.get('rectifDate')?.errors">
                                                                                                <span
                                                                                                   class="text-danger"
                                                                                                   *ngIf="div.get('rectifDate')?.errors?.required">
                                                                                                   Rectification
                                                                                                   Application Date
                                                                                                   Required</span>
                                                                                             </div>
                                                                                          </td>
                                                                                          <td><span
                                                                                                (click)="openAppCopyPop(appCopyLocFormPop, i)"><i
                                                                                                   class="icon fa fa fa-paperclip"
                                                                                                   style="cursor: pointer;"
                                                                                                   title="Attach file"></i><span
                                                                                                   *ngIf="getAppCopyLocLength(i, k) != 0">{{getAppCopyLocLength(i,k)}}
                                                                                                   Attachment</span></span>
                                                                                          </td>
                                                                                          <td><span
                                                                                                (click)="openAckCopyPop(ackCopyLocFormPop, i)"><i
                                                                                                   class="icon fa fa fa-paperclip"
                                                                                                   style="cursor: pointer;"
                                                                                                   title="Attach file"></i><span
                                                                                                   *ngIf="getAckCopyLength(i, k) != 0">{{getAckCopyLength(i,k)}}
                                                                                                   Attachment</span></span>
                                                                                          </td>
                                                                                          <td>
                                                                                             <select
                                                                                                class="form-control form-select"
                                                                                                id="rectifMode"
                                                                                                formControlName="rectifMode"
                                                                                                [title]="div.value.rectifMode ? div.value.rectifMode: ''">
                                                                                                <option [value]="null">
                                                                                                   -- Select --</option>
                                                                                                <ng-container
                                                                                                   *ngFor="let obj of modeOfAppealOptions">
                                                                                                   <option
                                                                                                      [value]="obj.value">
                                                                                                      {{obj.value}}
                                                                                                   </option>
                                                                                                </ng-container>
                                                                                             </select>
                                                                                          </td>
                                                                                          <td><input type="text"
                                                                                                class="form-control"
                                                                                                id="tax" placeholder=""
                                                                                                formControlName="rectifDetails"
                                                                                                [title]="div.value.rectifDetails ? div.value.rectifDetails: ''">
                                                                                          </td>
                                                                                          <ng-template
                                                                                             #appCopyLocFormPop
                                                                                             let-modal>
                                                                                             <div class="modal-header"
                                                                                                style="border: none; padding-bottom: 0px;">
                                                                                                <button type="button"
                                                                                                   class="close"
                                                                                                   aria-label="Close"
                                                                                                   (click)="modal.dismiss('Cross click')">
                                                                                                   <span
                                                                                                      aria-hidden="true">×</span>
                                                                                                </button>
                                                                                             </div>
                                                                                             <div class="col-md-12">
                                                                                                <label>Attachment</label>
                                                                                                <div
                                                                                                   class="table-responsive">
                                                                                                   <table
                                                                                                      class="table table-bordered">
                                                                                                      <thead>
                                                                                                         <tr
                                                                                                            class="bgGred">
                                                                                                            <th>Upload
                                                                                                            </th>
                                                                                                            <th
                                                                                                               style="width:50%;">
                                                                                                               Remark
                                                                                                            </th>
                                                                                                         </tr>
                                                                                                      </thead>
                                                                                                      <tbody>
                                                                                                         <tr *ngFor="let orderDetailsFromGrp of getAppCopyLoc(div); let j=index"
                                                                                                            [formGroup]="orderDetailsFromGrp">
                                                                                                            <td>
                                                                                                               <div
                                                                                                                  class="row">
                                                                                                                  <div
                                                                                                                     class="col-md-9 nopadding">
                                                                                                                     <div
                                                                                                                        class="form-group">
                                                                                                                        <input
                                                                                                                           *ngIf="orderDetailsFromGrp.value.isdocLocUploadedClicked"
                                                                                                                           type="file"
                                                                                                                           id="orderDetails({{i}}).rectifDetails({{k}}).appCopyLoc({{j}}).fileLoc"
                                                                                                                           class="filetype form-control"
                                                                                                                           name="myfile"
                                                                                                                           (change)="onFileSelect($event,'rectif')">
                                                                                                                        <p *ngIf="!orderDetailsFromGrp.value.isdocLocUploadedClicked || orderDetailsFromGrp.value.fileLoc"
                                                                                                                           class="form-control pt10">
                                                                                                                           {{getFileNameDta(orderDetailsFromGrp.value.fileLoc)}}
                                                                                                                        </p>
                                                                                                                     </div>
                                                                                                                  </div>
                                                                                                                  <div
                                                                                                                     class="col-md-3 nopadding">
                                                                                                                     <label>
                                                                                                                        <a class="downupIcon"
                                                                                                                           *ngIf="orderDetailsFromGrp.value.fileLoc"
                                                                                                                           title="Download file"
                                                                                                                           (click)="download(orderDetailsFromGrp.value.fileLoc)"><i
                                                                                                                              class="fa fa-download"></i></a>
                                                                                                                        <a class="downupIcon"
                                                                                                                           *ngIf="!(orderDetailsFromGrp.value.fileLoc || orderDetailsFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                                           title="Upload file"
                                                                                                                           (click)="uploadAppCopy(i,j,k)"><i
                                                                                                                              class="fa fa-upload"></i></a>
                                                                                                                        <a class="downupIcon"
                                                                                                                           (click)="deleteAppCopyFile(i,j,k)"
                                                                                                                           *ngIf="(orderDetailsFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                                              class="fa fa-trash"
                                                                                                                              aria-hidden="true"></i></a>
                                                                                                                     </label>

                                                                                                                  </div>
                                                                                                               </div>
                                                                                                            </td>
                                                                                                            <td> <input
                                                                                                                  type="text"
                                                                                                                  class="form-control"
                                                                                                                  placeholder=""
                                                                                                                  id="remarks"
                                                                                                                  formControlName="remarks"
                                                                                                                  [title]="orderDetailsFromGrp.value.remarks ? orderDetailsFromGrp.value.remarks :''"
                                                                                                                  maxlength="100" />
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                      </tbody>
                                                                                                   </table>
                                                                                                </div>
                                                                                             </div>
                                                                                             <div class="modal-footer"
                                                                                                style="border: none; padding-top: 0px;">
                                                                                                <button type="button"
                                                                                                   class="btn btn-outline-dark"
                                                                                                   (click)="modal.close('Save click')">Save</button>
                                                                                             </div>
                                                                                          </ng-template>

                                                                                          <ng-template
                                                                                             #ackCopyLocFormPop
                                                                                             let-modal>
                                                                                             <div class="modal-header"
                                                                                                style="border: none; padding-bottom: 0px;">
                                                                                                <button type="button"
                                                                                                   class="close"
                                                                                                   aria-label="Close"
                                                                                                   (click)="modal.dismiss('Cross click')">
                                                                                                   <span
                                                                                                      aria-hidden="true">×</span>
                                                                                                </button>
                                                                                             </div>
                                                                                             <div class="col-md-12">
                                                                                                <label>Attachment</label>
                                                                                                <div
                                                                                                   class="table-responsive">
                                                                                                   <table
                                                                                                      class="table table-bordered">
                                                                                                      <thead>
                                                                                                         <tr
                                                                                                            class="bgGred">
                                                                                                            <th>Upload
                                                                                                            </th>
                                                                                                            <th
                                                                                                               style="width:50%;">
                                                                                                               Remark
                                                                                                            </th>
                                                                                                         </tr>
                                                                                                      </thead>
                                                                                                      <tbody>
                                                                                                         <tr *ngFor="let ackCopyLocFromGrp of getAckCopy(div); let j=index"
                                                                                                            [formGroup]="ackCopyLocFromGrp">
                                                                                                            <td>
                                                                                                               <div
                                                                                                                  class="row">
                                                                                                                  <div
                                                                                                                     class="col-md-9 nopadding">
                                                                                                                     <div
                                                                                                                        class="form-group">
                                                                                                                        <input
                                                                                                                           *ngIf="ackCopyLocFromGrp.value.isdocLocUploadedClicked"
                                                                                                                           type="file"
                                                                                                                           id="orderDetails({{i}}).rectifDetails({{k}}).ackCopyLoc({{j}}).fileLoc"
                                                                                                                           class="filetype form-control"
                                                                                                                           name="myfile"
                                                                                                                           (change)="onFileSelect($event,'rectif')">
                                                                                                                        <p *ngIf="!ackCopyLocFromGrp.value.isdocLocUploadedClicked || ackCopyLocFromGrp.value.fileLoc"
                                                                                                                           class="form-control pt10">
                                                                                                                           {{getFileNameDta(ackCopyLocFromGrp.value.fileLoc)}}
                                                                                                                        </p>
                                                                                                                     </div>
                                                                                                                  </div>
                                                                                                                  <div
                                                                                                                     class="col-md-3 nopadding">
                                                                                                                     <label>
                                                                                                                        <a class="downupIcon"
                                                                                                                           *ngIf="ackCopyLocFromGrp.value.fileLoc"
                                                                                                                           title="Download file"
                                                                                                                           (click)="download(ackCopyLocFromGrp.value.fileLoc)"><i
                                                                                                                              class="fa fa-download"></i></a>
                                                                                                                        <a class="downupIcon"
                                                                                                                           *ngIf="!(ackCopyLocFromGrp.value.fileLoc || ackCopyLocFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                                           title="Upload file"
                                                                                                                           (click)="uploadAckCopy(i,j,k)"><i
                                                                                                                              class="fa fa-upload"></i></a>
                                                                                                                        <a class="downupIcon"
                                                                                                                           (click)="deletedAckCopyLocFile(i,j,k)"
                                                                                                                           *ngIf="(ackCopyLocFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                                              class="fa fa-trash"
                                                                                                                              aria-hidden="true"></i></a>
                                                                                                                     </label>

                                                                                                                  </div>
                                                                                                               </div>
                                                                                                            </td>
                                                                                                            <td> <input
                                                                                                                  type="text"
                                                                                                                  class="form-control"
                                                                                                                  placeholder=""
                                                                                                                  id="remarks"
                                                                                                                  formControlName="remarks"
                                                                                                                  [title]="ackCopyLocFromGrp.value.remarks ? ackCopyLocFromGrp.value.remarks:''"
                                                                                                                  maxlength="100" />
                                                                                                            </td>
                                                                                                         </tr>
                                                                                                      </tbody>
                                                                                                   </table>
                                                                                                </div>
                                                                                             </div>
                                                                                             <div class="modal-footer"
                                                                                                style="border: none; padding-top: 0px;">
                                                                                                <button type="button"
                                                                                                   class="btn btn-outline-dark"
                                                                                                   (click)="modal.close('Save click')">Save</button>
                                                                                             </div>
                                                                                          </ng-template>

                                                                                          <td class="w-80">
                                                                                             <a class=" addExtLetter"
                                                                                                (click)="addRectif(i)"><i
                                                                                                   class="fa fa-plus"
                                                                                                   title="Add row"></i></a>
                                                                                             <a class=" addExtLetter"
                                                                                                *ngIf="k != 0"
                                                                                                (click)="openRemoveRectifDetailsPop(removeRectifPopup,i)"><i
                                                                                                   class="fa fa-minus"></i></a>
                                                                                             <ng-template
                                                                                                #removeRectifPopup
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header">
                                                                                                   <h4 class="modal-title"
                                                                                                      id="modal-basic-title">
                                                                                                      Remove Warning!
                                                                                                   </h4>
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="modal-body">
                                                                                                   <p>Are you sure, you
                                                                                                      want to remove?
                                                                                                   </p>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="removeRectif(i,k)">Remove</button>
                                                                                                </div>
                                                                                             </ng-template>
                                                                                          </td>
                                                                                       </tr>
                                                                                    </tbody>
                                                                                 </table>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>

                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label for="exampleInputPassword1">Whether SCN Issued For Penalty</label>
                                          <select class="form-control" id="location" formControlName="whetherScnIssued"
                                             (change)="isWhetherSCNIssued()">
                                             <ng-container *ngFor="let obj of whetherSCNIssued">
                                                <option [value]="obj.value">{{obj.label}}</option>
                                             </ng-container>
                                          </select>
                                       </div>
                                    </div>
                                    <div class="col-md-12" *ngIf="showTable">
                                       <div class="accordion accordion-solid-header" id="accordion-12" role="tablist">
                                          <div class="card border-bottom">
                                             <div class="card-header" role="tab" id="heading-12">
                                                <h6 class="mb-0">
                                                   <a data-toggle="collapse" href="#collapse-12" aria-expanded="true"
                                                      aria-controls="collapse-12">
                                                      SCN Details
                                                   </a>
                                                </h6>
                                             </div>
                                             <div id="collapse-2" class="collapse show" role="tabpanel"
                                                aria-labelledby="heading-2" data-parent="#accordion-2">
                                                <div class="card-body">
                                                   <div class="row">
                                                      <div class="table-responsive">
                                                         <table class="table table-bordered">
                                                            <tr class="bgGred">
                                                               <th>Date of SCN<span class="mandatetd"><i
                                                                        class="fa fa-asterisk"
                                                                        style="font-size: 7px;"></i></span></th>
                                                               <th>DIN<span class="mandatetd"><i class="fa fa-asterisk"
                                                                        style="font-size: 7px;"></i></span></th>
                                                               <th>Date of Receipt of SCN <span class="mandatetd"><i
                                                                        class="fa fa-asterisk"
                                                                        style="font-size: 7px;"></i></span></th>
                                                               <th>Due to Reply of SCN <span class="mandatetd"><i
                                                                        class="fa fa-asterisk"
                                                                        style="font-size: 7px;"></i></span></th>
                                                            </tr>
                                                            <tr>
                                                               <td>
                                                                  <div id="iris-gst-filter-frmDate"
                                                                     class="input-group date datepicker"
                                                                     [ngClass]="{errorBorder:isSubmittedScn && !orderForm.controls.scnDate.value }">
                                                                     <p-calendar appendTo="body" class="form-control"
                                                                        formControlName="scnDate" name="scnDate"
                                                                        [monthNavigator]="true" [yearNavigator]="true"
                                                                        yearRange="2010:{{currentYear}}"
                                                                        dateFormat="dd-mm-yy" [showIcon]="true"
                                                                        dataType="string" [maxDate]="maxDate">
                                                                     </p-calendar>
                                                                  </div>

                                                                  <div
                                                                     *ngIf="isSubmittedScn && !orderForm.controls.scnDate.value">
                                                                     <span class="text-danger">Date of SCN
                                                                        Required</span>
                                                                  </div>
                                                               </td>
                                                               <td> <input type="text" class="form-control"
                                                                     formControlName="scnDin"
                                                                     [ngClass]="{errorBorder:isSubmittedScn && !orderForm.controls.scnDin.value }">
                                                                  <div
                                                                     *ngIf="isSubmittedScn && !orderForm.controls.scnDin.value">
                                                                     <span class="text-danger">DIN Required</span>
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div id="iris-gst-filter-frmDate"
                                                                     class="input-group date datepicker">
                                                                     <p-calendar appendTo="body" class="form-control"
                                                                        formControlName="scnReceivedDate"
                                                                        name="scnReceivedDate" [monthNavigator]="true"
                                                                        [yearNavigator]="true"
                                                                        yearRange="2010:{{currentYear}}"
                                                                        dateFormat="dd-mm-yy" [showIcon]="true"
                                                                        dataType="string" [maxDate]="maxDate"
                                                                        [ngClass]="{errorBorder:isSubmittedScn && !orderForm.controls.scnReceivedDate.value }">
                                                                     </p-calendar>
                                                                  </div>
                                                                  <div
                                                                     *ngIf="isSubmittedScn && !orderForm.controls.scnReceivedDate.value">
                                                                     <span class="text-danger">Date of Receipt of SCN
                                                                        Required</span>
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <div id="iris-gst-filter-frmDate"
                                                                     class="input-group date datepicker">
                                                                     <p-calendar appendTo="body" class="form-control"
                                                                        formControlName="scnReplyDueDate"
                                                                        name="scnReplyDueDate" [monthNavigator]="true"
                                                                        [yearNavigator]="true"
                                                                        yearRange="2010:{{currentYear}}"
                                                                        dateFormat="dd-mm-yy" [showIcon]="true"
                                                                        dataType="string"
                                                                        [ngClass]="{errorBorder:isSubmittedScn && !orderForm.controls.scnReplyDueDate.value }">
                                                                     </p-calendar>
                                                                  </div>
                                                                  <div
                                                                     *ngIf="isSubmittedScn && !orderForm.controls.scnReplyDueDate.value">
                                                                     <span class="text-danger">Due to Reply of SCN
                                                                        Required</span>
                                                                  </div>
                                                               </td>
                                                               <!-- <td> <input type="text" class="form-control" formControlName="scnReplyDueDate"> </td> -->
                                                            </tr>
                                                         </table>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-md-12">
                                       <div class="accordion accordion-solid-header" id="accordion-12" role="tablist">
                                          <div class="card border-bottom">
                                             <div class="card-header" role="tab" id="heading-02">
                                                <h6 class="mb-0">
                                                   <a class="bg-transparent text-dark main p-0" data-toggle="collapse"
                                                      href="#collapse-02" aria-expanded="true"
                                                      aria-controls="collapse-2">
                                                      Order Details
                                                   </a>
                                                </h6>
                                             </div>
                                             <div id="collapse-02" class="collapse show" role="tabpanel"
                                                aria-labelledby="heading-02" data-parent="#accordion-02">
                                                <div class="card-body">
                                                   <div class=" row">
                                                      <div class="col-md-12">
                                                         <div class="search-table-outter wrapper">
                                                            <table class="search-table inner ">
                                                               <thead>
                                                                  <tr class="bgGred">
                                                                     <th style="min-width: 288px;">Issues<span
                                                                           class="mandatetd"><i class="fa fa-asterisk"
                                                                              style="font-size: 7px;"></i></span></th>
                                                                     <th>Keyword (Max 150 Character)</th>
                                                                     <th>Issue Wise Conclusion <span
                                                                           class="mandatetd"><i class="fa fa-asterisk"
                                                                              style="font-size: 7px;"></i></span></th>
                                                                     <th *ngIf="caseType !=='PENALTY'">Addition of
                                                                        Income/ Disallowance of Expenses (₹)</th>
                                                                     <th *ngIf="caseType !=='PENALTY'">Tax Rate
                                                                        (Inclusive of Surcharge and Cess) </th>
                                                                     <th *ngIf="caseType !=='PENALTY'">Tax Impact
                                                                        (Inclusive of Surcharge and Cess) (₹) </th>
                                                                     <th *ngIf="caseType !=='PENALTY'">Interest (₹)
                                                                     </th>
                                                                     <th>Penalty (₹) </th>
                                                                     <th *ngIf="caseType !=='PENALTY'">Total (₹)</th>
                                                                     <th>Remark </th>
                                                                     <th class="w-80">Action</th>
                                                                  </tr>

                                                               </thead>
                                                               <tbody>
                                                                  <tr *ngFor="let div of getAllIssues(); let j=index"
                                                                     [formGroup]="div">
                                                                     <td class="w250" *ngIf="caseType !=='PENALTY'">
                                                                        <select class="form-control form-select"
                                                                           formControlName="issue"
                                                                           [title]="div.value.issue ? div.value.issue: ''"
                                                                           [ngClass]="{ 'errorBorder': isSubmittedOrder && div.get('issue')?.errors  }">
                                                                           <optgroup *ngFor='let grp of dataSource'
                                                                              label="{{grp.group}}">
                                                                              <option *ngFor='let item of grp.items'
                                                                                 [value]="item.name"
                                                                                 title="{{item.name}}">{{item.name}}
                                                                              </option>
                                                                           </optgroup>
                                                                        </select>
                                                                     </td>

                                                                     <td class="w250" *ngIf="caseType ==='PENALTY'">
                                                                        <select class="form-control form-select"
                                                                           formControlName="issue"
                                                                           [title]="div.value.issue ? div.value.issue: ''"
                                                                           [ngClass]="{ 'errorBorder': isSubmittedNotice && div.get('issue')?.errors  }">
                                                                           <optgroup *ngFor='let grp of dataSourceIssue'
                                                                              label="{{grp.group}}">
                                                                              <option *ngFor='let item of grp.items'
                                                                                 [value]="item.name"
                                                                                 title="{{item.name}}">{{item.name}}
                                                                              </option>
                                                                           </optgroup>
                                                                        </select>
                                                                        <div
                                                                           *ngIf="isSubmittedNotice && div.get('issue')?.errors">
                                                                           <span class="text-danger"
                                                                              *ngIf="div.get('issue')?.errors?.required ">Issues
                                                                              Required
                                                                           </span>
                                                                        </div>
                                                                     </td>

                                                                     <td><input type="text" class="form-control"
                                                                           id="tax" placeholder="" maxlength="150"
                                                                           formControlName="keyword" maxlength="150"
                                                                           [title]="div.value.keyword ? div.value.keyword:''">
                                                                     </td>

                                                                     <td>
                                                                        <select class="form-control form-select"
                                                                           id="status" formControlName="status"
                                                                           [title]="div.value.status ? div.value.status: ''">
                                                                           <ng-container
                                                                              *ngFor="let obj of issueConclusion">
                                                                              <option [value]="obj.value">{{obj.value}}
                                                                              </option>
                                                                           </ng-container>
                                                                        </select>
                                                                        <div
                                                                           *ngIf="isSubmittedOrder && div.get('status')?.errors">
                                                                           <span class="text-danger"
                                                                              *ngIf="div.get('status')?.errors?.required ">
                                                                              Issue Wise Conclusion Required
                                                                           </span>
                                                                        </div>
                                                                     </td>

                                                                     <td *ngIf="caseType !=='PENALTY'"> <input
                                                                           type="text" class="form-control align-right "
                                                                           placeholder=""
                                                                           formControlName="addnOrDisOfExpensesL"
                                                                           maxlength="19" InrFormat
                                                                           (input)="issueValueChange()"
                                                                           (paste)="handlePaste($event)"
                                                                           oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                     </td>

                                                                     <td *ngIf="caseType !=='PENALTY'">
                                                                        <div class="input-container">
                                                                           <input type="text"
                                                                              class="form-control input-field align-right"
                                                                              placeholder="" formControlName="taxRateL"
                                                                              id="taxRateL" maxlength="19"
                                                                              (blur)="handleDecimalPaste(i)"
                                                                              (input)="issueValueChange();">
                                                                           <i class="icon"
                                                                              style="position: absolute; transform: translateY(-50%);">%</i>
                                                                        </div>
                                                                        <div *ngIf="div.get('taxRateL')?.errors">
                                                                           <span class="text-danger"
                                                                              *ngIf="div.get('taxRateL')?.errors?.pattern">
                                                                              Please Enter 0 to 100</span>
                                                                        </div>
                                                                     </td>
                                                                     <td *ngIf="caseType !=='PENALTY'"> <input
                                                                           type="text" class="form-control align-right "
                                                                           placeholder="" formControlName="taxImpactL"
                                                                           maxlength="19" InrFormat
                                                                           (input)="issueValueChange()"
                                                                           (paste)="handlePaste($event)"
                                                                           oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                     </td>
                                                                     <td *ngIf="caseType !=='PENALTY'"> <input
                                                                           type="text" class="form-control align-right "
                                                                           placeholder="" formControlName="interestL"
                                                                           maxlength="19" InrFormat
                                                                           (input)="issueValueChange()"
                                                                           (paste)="handlePaste($event)"
                                                                           oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                     </td>
                                                                     <td> <input type="text"
                                                                           class="form-control align-right "
                                                                           placeholder="" formControlName="penaltyL"
                                                                           maxlength="19" InrFormat
                                                                           (input)="issueValueChange()"
                                                                           (paste)="handlePaste($event)"
                                                                           oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                     </td>
                                                                     <td *ngIf="caseType !=='PENALTY'"> <input
                                                                           type="text" class="form-control align-right "
                                                                           placeholder="" readonly
                                                                           formControlName="totalL" maxlength="19"
                                                                           InrFormat (input)="issueValueChange()"> </td>
                                                                     <td> <input type="text" class="form-control"
                                                                           placeholder="" formControlName="remarkL">
                                                                        <div
                                                                           *ngIf="div.get('remarkL')?.value && div.get('remarkL')?.value.length > 100">
                                                                           <span class="text-danger"
                                                                              *ngIf="div.get('remarkL')?.value && div.get('remarkL')?.value.length > 100">Max
                                                                              Length is 100 character
                                                                           </span>
                                                                        </div>
                                                                     </td>
                                                                     <td class="w-80">
                                                                        <a class="removeDiv" data-toggle="modal"
                                                                           data-target="#removeIssuePopup"
                                                                           (click)="openRmvIssuePop(removeIssuePopup, i)"
                                                                           style="margin-left: 15%;"><i
                                                                              class="fa fa-minus"></i></a>
                                                                     </td>
                                                                     <ng-template #removeIssuePopup let-modal>
                                                                        <div class="modal-header">
                                                                           <h4 class="modal-title"
                                                                              id="modal-basic-title">Remove Warning!
                                                                           </h4>
                                                                           <button type="button" class="close"
                                                                              aria-label="Close"
                                                                              (click)="modal.dismiss('Cross click')">
                                                                              <span aria-hidden="true">×</span>
                                                                           </button>
                                                                        </div>
                                                                        <div class="modal-body">
                                                                           <p>Are you sure, you want to remove?</p>
                                                                        </div>
                                                                        <div class="modal-footer">
                                                                           <button type="button"
                                                                              class="btn btn-outline-dark" *ngIf="i!=0"
                                                                              (click)="rmIssueInv(i)">Remove</button>
                                                                        </div>
                                                                     </ng-template>
                                                                  </tr>
                                                                  <ng-container>
                                                                     <tr>
                                                                        <th colspan="3" class="total-titel">Total</th>
                                                                        <td *ngIf="caseType !=='PENALTY'"><input
                                                                              type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="addnOrDisOfExpensesLTotal"
                                                                              id="tax" placeholder="" InrFormat
                                                                              maxlength="19" readonly></td>
                                                                        <td *ngIf="caseType !=='PENALTY'" colspan="1">
                                                                           &nbsp;</td>
                                                                        <td *ngIf="caseType !=='PENALTY'"><input
                                                                              type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="taxImpactLTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <td *ngIf="caseType !=='PENALTY'"><input
                                                                              type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="interestLTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <td *ngIf="caseType !=='PENALTY'"><input
                                                                              type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="penaltyLTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <td *ngIf="caseType !=='PENALTY'"><input
                                                                              type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="totalLTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <td *ngIf="caseType !=='PENALTY'" colspan="9">
                                                                           &nbsp;</td>
                                                                        <td *ngIf="caseType ==='PENALTY'" colspan="7">
                                                                           &nbsp;</td>
                                                                     </tr>
                                                                  </ng-container>
                                                               </tbody>

                                                            </table>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-md-10">
                                       <div class="accordion accordion-solid-header">
                                          <div role="tab" id="heading-04">
                                             <h6 class="mb-0" class="table-heder"> Appeal/Writ Details </h6>
                                          </div>
                                          <div class="col-md-10">
                                             <div class="accordion accordion-solid-header">
                                                <div class="card border-bottom">
                                                   <div>
                                                      <div>
                                                         <div class="row">
                                                            <div class="table-responsive">
                                                               <table class="table table-bordered">
                                                                  <tr class="bgGred">
                                                                     <th class="w-40">Sr. No. </th>
                                                                     <th style="width: 250px;">Action <span
                                                                           class="mandatetd"><i class="fa fa-asterisk"
                                                                              style="font-size: 7px; margin-left:-7px;"></i></span>
                                                                     </th>
                                                                     <th>Forum</th>
                                                                  </tr>
                                                                  <ng-container>
                                                                     <tr *ngFor="let appealFromGrp of getappealDetail(); let i=index"
                                                                        [formGroup]="appealFromGrp">
                                                                        <td class="w-40">{{i+1}}</td>
                                                                        <td>
                                                                           <select class="form-control form-select"
                                                                              id="action" formControlName="action"
                                                                              (change)="setForumOfAppeal()"
                                                                              [title]="appealFromGrp.value.action ? appealFromGrp.value.action :''"
                                                                              [ngClass]="{errorBorder: isSubmittedAppeal && appealFromGrp.get('action')?.errors?.required}">
                                                                              <ng-container
                                                                                 *ngFor="let obj of actionData">
                                                                                 <option [value]="obj.value">
                                                                                    {{obj.label}}</option>
                                                                              </ng-container>
                                                                           </select>
                                                                           <div
                                                                              *ngIf="isSubmittedAppeal && appealFromGrp.get('action')?.errors">
                                                                              <span class="text-danger"
                                                                                 *ngIf="appealFromGrp.get('action')?.errors?.required ">
                                                                                 Required </span>
                                                                           </div>
                                                                        </td>
                                                                        <td>
                                                                           <select class="form-control form-select"
                                                                              formControlName="forum"
                                                                              [title]="appealFromGrp.value.forum ? appealFromGrp.value.forum:''"
                                                                              [attr.disabled]="isSelectDisabled">
                                                                              <ng-container
                                                                                 *ngFor="let obj of forumAppeal">
                                                                                 <option [value]="obj.value">
                                                                                    {{obj.label}}</option>
                                                                              </ng-container>
                                                                           </select>
                                                                           <!-- <input type="text" class="form-control" id="forum" placeholder="" formControlName="forum" [title]="appealFromGrp.value.forum" > -->
                                                                        </td>
                                                                        <!-- <td><input type="text" class="form-control" id="forum" placeholder="" formControlName="forum" [title]="appealFromGrp.value.forum" ></td>                                                               -->
                                                                     </tr>
                                                                  </ng-container>
                                                               </table>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-md-12 noPadding mt15 mb-3">
                                       <button class="btn btn-outline-primary mr-1"
                                          (click)="orderSubmit()">Next</button>
                                    </div>
                                 </form>
                              </fieldset>
                           </div>
                        </div>

                        <div class="tab-pane fade" id="asstp-2" role="tabpanel" aria-labelledby="asstp-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="col-md-12 nopadding">
                              <p class="textCenter"> Assessed Tax and Assessed Refund Details</p>
                              <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                 <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                 <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                              </span>
                           </div>
                           <fieldset [disabled]="action == 'view'">
                              <div class="col-md-12">
                                 <div class="accordion accordion-solid-header" id="accordion-30" role="tablist">
                                    <div class="card border-bottom">
                                       <div class="card-header" role="tab" id="heading-30">
                                          <h6 class="mb-0">
                                             <a data-toggle="collapse" href="#collapse-3330" aria-expanded="true"
                                                aria-controls="collapse-30777">
                                                Details of Assessed Tax Paid
                                             </a>
                                          </h6>
                                       </div>
                                       <div id="collapse-30" class="collapse show" role="tabpanel"
                                          aria-labelledby="heading-30" data-parent="#accordion-30">
                                          <div class="card-body">
                                             <div class="row">
                                                <div class="table-responsive">
                                                   <table class="search-table inner">
                                                      <tr class="bgGred">
                                                         <th class="w-40">Sr.No</th>
                                                         <th>CIN/ Ref. No. </th>
                                                         <th>Date </th>
                                                         <th>BSR Code </th>
                                                         <th>Tax (₹)</th>
                                                         <th>Interest (₹)</th>
                                                         <th>Total (₹) </th>
                                                         <th>Challan Copy</th>
                                                         <th class="w-80">Action</th>
                                                      </tr>
                                                      <ng-container>
                                                         <tr *ngFor="let libDetailsGrp of getLibDetails(); let i=index"
                                                            [formGroup]="libDetailsGrp">
                                                            <td class="sl-no-cls w-40"> {{i+1}}</td>
                                                            <td><input type="text" class="form-control" placeholder=""
                                                                  formControlName="cinRefNo" maxlength="50"
                                                                  [title]="libDetailsGrp.value.cinRefNo ? libDetailsGrp.value.cinRefNo: ''">
                                                            </td>
                                                            <td>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar appendTo="body" class="form-control"
                                                                     formControlName="date" name="date"
                                                                     [title]="libDetailsGrp.value.date ? libDetailsGrp.value.date:''"
                                                                     [monthNavigator]="true" [yearNavigator]="true"
                                                                     yearRange="2010:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string" [maxDate]="maxDate">
                                                                  </p-calendar>
                                                               </div>
                                                            </td>

                                                            <td><input type="text" class="form-control" placeholder=""
                                                                  formControlName="bsrCode" maxlength="50"
                                                                  [title]="libDetailsGrp.value.bsrCode ? libDetailsGrp.value.bsrCode:''">
                                                            </td>
                                                            <td><input type="text" class="form-control align-right"
                                                                  placeholder="" formControlName="tax" InrFormat
                                                                  (input)="liabilityAddmittedAndPaidChanged()"
                                                                  maxlength="19" (paste)="handlePaste($event)"></td>
                                                            <td><input type="text" class="form-control align-right"
                                                                  placeholder="" formControlName="interest" InrFormat
                                                                  (input)="liabilityAddmittedAndPaidChanged()"
                                                                  maxlength="19" (paste)="handlePaste($event)"></td>
                                                            <td><input type="text" class="form-control align-right"
                                                                  placeholder="" formControlName="total" InrFormat
                                                                  (input)="liabilityAddmittedAndPaidChanged()"
                                                                  maxlength="19">

                                                            <td><span
                                                                  (click)="openLibDetailsGrpPop(ChallanDetailsFormPop, i)"><i
                                                                     class="icon fa fa fa-paperclip" id="paperclip"
                                                                     title="Attach file"></i></span><span
                                                                  *ngIf="getChallanLength(i) != 0">{{getChallanLength(i)}}
                                                                  Attachment</span></td>
                                                            <td class="w-80"><a class=" addExtLetter"
                                                                  (click)="addLibDetails()"><i
                                                                     class="fa fa-plus"></i></a>
                                                               <a class="addExtLetter" *ngIf="i !=0"
                                                                  (click)="openLibDetailsPop(rmvLiabilityAddmittedAndPaidModalRef,i)"><i
                                                                     class="fa fa-minus"></i></a>
                                                            </td>
                                                            <ng-template #rmvLiabilityAddmittedAndPaidModalRef
                                                               let-modal>
                                                               <div class="modal-header">
                                                                  <h4 class="modal-title" id="modal-basic-title">Remove
                                                                     Warning!</h4>
                                                                  <button type="button" class="close" aria-label="Close"
                                                                     (click)="modal.dismiss('Cross click')">
                                                                     <span aria-hidden="true">×</span>
                                                                  </button>
                                                               </div>
                                                               <div class="modal-body">
                                                                  <p>Are you sure, you want to remove?</p>
                                                               </div>
                                                               <div class="modal-footer">
                                                                  <button type="button" class="btn btn-outline-dark"
                                                                     (click)="rmLibDetailsInv(i)">Remove</button>
                                                               </div>
                                                            </ng-template>
                                                            <ng-template #ChallanDetailsFormPop let-modal>
                                                               <div class="modal-header"
                                                                  style="border: none; padding-bottom: 0px;">
                                                                  <button type="button" class="close" aria-label="Close"
                                                                     (click)="modal.dismiss('Cross click')"> <span
                                                                        aria-hidden="true">×</span> </button>
                                                               </div>
                                                               <div class="col-md-12"> <label>Attachment</label>
                                                                  <div class="table-responsive">
                                                                     <table class="table table-bordered">
                                                                        <thead>
                                                                           <tr class="bgGred">
                                                                              <th> Upload </th>
                                                                              <th style="width:50%;">Remark </th>
                                                                           </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                           <tr *ngFor="let getChallanFromGrp of getChallan(libDetailsGrp); let j=index"
                                                                              [formGroup]="getChallanFromGrp">
                                                                              <td>
                                                                                 <div class="row">
                                                                                    <div class="col-md-9 nopadding">
                                                                                       <div class="form-group">
                                                                                          <input
                                                                                             *ngIf="getChallanFromGrp.value.isdocLocUploadedClicked"
                                                                                             type="file"
                                                                                             id="libDetails({{i}}).challanCopyLoc({{j}}).fileLoc"
                                                                                             class="filetype form-control"
                                                                                             name="myfile"
                                                                                             (change)="onFileSelect($event,'tax')">
                                                                                          <p *ngIf="!getChallanFromGrp.value.isdocLocUploadedClicked || getChallanFromGrp.value.fileLoc"
                                                                                             class="form-control pt10">
                                                                                             {{getFileNameDta(getChallanFromGrp.value.fileLoc)}}
                                                                                          </p>
                                                                                       </div>
                                                                                    </div>
                                                                                    <div class="col-md-3 nopadding">
                                                                                       <label>
                                                                                          <a class="downupIcon"
                                                                                             *ngIf="getChallanFromGrp.value.fileLoc"
                                                                                             title="Download file"
                                                                                             (click)="download(getChallanFromGrp.value.fileLoc)"><i
                                                                                                class="fa fa-download"></i></a>
                                                                                          <a class="downupIcon"
                                                                                             *ngIf="!(getChallanFromGrp.value.fileLoc || getChallanFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                             title="Upload file"
                                                                                             (click)="uploadChallan(i,j)"><i
                                                                                                class="fa fa-upload"></i></a>
                                                                                          <a class="downupIcon"
                                                                                             (click)="deleteChallanCopyFile(i,j)"
                                                                                             *ngIf="(getChallanFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                class="fa fa-trash"
                                                                                                aria-hidden="true"></i></a>
                                                                                       </label>

                                                                                    </div>
                                                                                 </div>
                                                                              </td>
                                                                              <td> <input type="text"
                                                                                    class="form-control" placeholder=""
                                                                                    id="remarks"
                                                                                    formControlName="remarks"
                                                                                    [title]="getChallanFromGrp.value.remarks ? getChallanFromGrp.value.remarks:''"
                                                                                    maxlength="100" />
                                                                              </td>
                                                                           </tr>
                                                                        </tbody>
                                                                     </table>
                                                                  </div>
                                                               </div>
                                                               <div class="modal-footer"
                                                                  style="border: none; padding-top: 0px;">
                                                                  <button type="button" class="btn btn-outline-dark"
                                                                     (click)="modal.close('Save click')">Save</button>
                                                               </div>
                                                            </ng-template>
                                                         </tr>
                                                      </ng-container>
                                                      <ng-container>
                                                         <tr>
                                                            <th colspan="4" class="total-titel">Total</th>
                                                            <td class="align-right bold-font">
                                                               {{liabilityAddmittedAndPaidTaxTotal| INR: 0}}</td>
                                                            <td class="align-right bold-font">
                                                               {{liabilityAddmittedAndPaidInterestTotal| INR: 0 }}</td>
                                                            <td class="align-right bold-font">
                                                               {{liabilityAddmittedAndPaidTotal | INR: 0}}</td>
                                                         </tr>
                                                      </ng-container>
                                                   </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-12">
                                 <div class="accordion accordion-solid-header" id="accordion-30" role="tablist">
                                    <div class="card border-bottom">
                                       <div class="card-header" role="tab" id="heading-30">
                                          <h6 class="mb-0">
                                             <a data-toggle="collapse" href="#collapse-38880" aria-expanded="true"
                                                aria-controls="collapse-38880">
                                                Details of Refund Received
                                             </a>
                                          </h6>
                                       </div>
                                       <div id="collapse-30" class="collapse show" role="tabpanel"
                                          aria-labelledby="heading-30" data-parent="#accordion-30">
                                          <div class="card-body">
                                             <div class="row">
                                                <div class="table-responsive">
                                                   <table class="search-table inner">
                                                      <tr class="bgGred">
                                                         <th class="w-40">Sr.No</th>
                                                         <th>Date of Receipt</th>
                                                         <th>Mode </th>
                                                         <th>Tax (₹)</th>
                                                         <th>Interest (₹)</th>
                                                         <th>Total (₹)</th>
                                                         <th class="w-80">Action</th>
                                                      </tr>
                                                      <ng-container>
                                                         <tr *ngFor="let refundDetailsGrp of getRefundDetails(); let i=index"
                                                            [formGroup]="refundDetailsGrp">
                                                            <td class="sl-no-cls w-40"> {{i+1}}</td>
                                                            <td>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar appendTo="body" class="form-control"
                                                                     formControlName="receiptDate"
                                                                     [title]="refundDetailsGrp.value.receiptDate ? refundDetailsGrp.value.receiptDate:''"
                                                                     [monthNavigator]="true" [yearNavigator]="true"
                                                                     yearRange="2010:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string" [maxDate]="maxDate">
                                                                  </p-calendar>
                                                               </div>
                                                            </td>
                                                            <td>
                                                               <select class="form-control form-select" id="status"
                                                                  formControlName="mode"
                                                                  [title]="refundDetailsGrp.value.mode ? refundDetailsGrp.value.mode:''">
                                                                  <option [value]="null">-- Select --</option>
                                                                  <ng-container *ngFor="let obj of modeOfPayemt">
                                                                     <option [value]="obj.value">{{obj.value}}</option>
                                                                  </ng-container>
                                                               </select>
                                                            </td>
                                                            <td><input type="text" class="form-control align-right "
                                                                  placeholder="" formControlName="tax" InrFormat
                                                                  maxlength="19" (input)="refundDetailsChanged()"
                                                                  (paste)="handlePaste($event)"></td>
                                                            <td><input type="text" class="form-control align-right "
                                                                  placeholder="" formControlName="interest" InrFormat
                                                                  maxlength="19" (input)="refundDetailsChanged()"
                                                                  (paste)="handlePaste($event)"></td>
                                                            <td><input type="text" class="form-control align-right "
                                                                  placeholder="" formControlName="total" InrFormat
                                                                  readonly maxlength="19"
                                                                  (input)="refundDetailsChanged()"
                                                                  (paste)="handlePaste($event)"></td>
                                                            <td class="w-80">
                                                               <a class="addExtLetter" (click)="addRefundDetails() "><i
                                                                     class="fa fa-plus"></i></a>
                                                               <a class="addExtLetter" *ngIf="i != 0"
                                                                  (click)="openRefundDetailsPop(rmrefundDetailsop,i)"><i
                                                                     class="fa fa-minus"></i></a>
                                                            </td>
                                                            <ng-template #rmrefundDetailsop let-modal>
                                                               <div class="modal-header">
                                                                  <h4 class="modal-title" id="modal-basic-title">Remove
                                                                     Warning! </h4>
                                                                  <button type="button" class="close" aria-label="Close"
                                                                     (click)="modal.dismiss('Cross click')">
                                                                     <span aria-hidden="true">×</span>
                                                                  </button>
                                                               </div>
                                                               <div class="modal-body">
                                                                  <p>Are you sure, you want to remove?</p>
                                                               </div>
                                                               <div class="modal-footer">
                                                                  <button type="button" class="btn btn-outline-dark"
                                                                     (click)="rmRefundDetailsInv(i)">Remove</button>
                                                               </div>
                                                            </ng-template>
                                                         </tr>
                                                         <tr>
                                                            <th colspan="3" class="total-titel">Total</th>
                                                            <td class="align-right bold-font">{{refundPaidTaxTotal| INR:
                                                               0}}</td>
                                                            <td class="align-right bold-font">{{refundPaidInterestTotal|
                                                               INR: 0 }}</td>
                                                            <td class="align-right bold-font">{{refundPaidTotal | INR:
                                                               0}}</td>
                                                         </tr>
                                                      </ng-container>
                                                   </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>

                              <div class="col-md-12 noPadding mt15 mb-3">
                                 <button class="btn btn-outline-primary mr-1"
                                    (click)="taxPaymentFormSubmit()">Next</button>
                              </div>
                           </fieldset>
                        </div>

                        <div class="tab-pane fade" id="asct-2" role="tabpanel" aria-labelledby="asct-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="col-md-12 nopadding">
                              <p class="textCenter"> Cumulative Tax Details</p>
                              <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                 <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                 <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                              </span>
                           </div>
                           <fieldset [disabled]="action == 'view'">
                              <div class="col-md-12">
                                 <div class="row">
                                    <div class="col-md-12 table-responsive">
                                       <table class="table table-bordered">
                                          <tr class="bgGred">
                                             <th>Particulars</th>
                                             <th>Tax(₹)</th>
                                             <th>Interest (₹) </th>
                                             <th>Penalty (₹) </th>
                                             <th>Total (₹) </th>
                                          </tr>
                                          <tr *ngFor="let c of cumulativeData">
                                             <td>{{c.label}}</td>
                                             <td class="align-right">{{c.tax | INR :0 }}</td>
                                             <td class="align-right">{{ c.interest | INR :0 }}</td>
                                             <td class="align-right">{{ c.penalty | INR :0 }}</td>
                                             <td class="align-right">{{ c.total | INR :0 }}</td>
                                          </tr>
                                       </table>
                                    </div>
                                 </div>
                              </div>
                              <div class="col-md-12 noPadding mt15 mb-3">
                                 <button *ngIf="caseType !='PENALTY'" class="btn btn-outline-primary mr-1"
                                    (click)="cumulativeFormSubmit(3,'NEW')">Submit</button>
                                 <button *ngIf="caseType ==='PENALTY'" class="btn btn-outline-primary mr-1"
                                    (click)="cumulativeFormSubmit(3,'PENALTY')">Submit</button>

                              </div>
                           </fieldset>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
