<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">
        <p><span class="card-title">Company details - GST </span>: <span *ngIf="navContext?.entityType == 'FILING'"
            class="title-heder">{{navContext.gstin}}</span><span
            *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'"
            class="title-heder">{{navContext.pan}}</span>

          <button class="btn btn-outline-primary ml-1 iris-gst-pull-right" (click)="saveCompanyGst()"
            *ngIf="!['GST_Viewer','Viewer'].includes(role)">Save</button>
        </p>
        <P><i class="fa fa-info-circle"></i> The shown details are pertaining to Parent entity, to edit the existing
          details please save it first.</P>
        <form [formGroup]="auditCompanyGstfrmGrp">
          <div class="card borderOrange mt15">
            <div class="card-body">
              <fieldset>
                <div class="row" formGroupName="basicDetails">
                  <p class="card-title">COMPANY INFORMATION</p>

                  <div class="col-md-4 pt-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Date of Incorporation </label>
                      <div id="dtIncorp" class="input-group date datepicker">
                        <p-calendar class="form-control" formControlName="dateOfIncorp" [maxDate]="maxDate"
                          [monthNavigator]="true" [yearNavigator]="true" yearRange="1960:{{currentYear}}"
                          dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                      </div>

                    </div>
                  </div>
                  <div class="col-md-4 pt-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Corporate Identification Number (CIN)</label>
                      <input type="text" class="form-control" id="" formControlName="incorpCertNo" maxlength="21">
                      <!-- <div *ngIf="isSubmitted && auditCompanyGstfrmGrp.get('basicDetails').get(incorpCertNo)?.errors">
                        <span class="text-danger" *ngIf="auditCompanyGstfrmGrp.get('basicDetails').get(incorpCertNo)?.errors?.required"> Required
                        </span>
                      </div> -->
                    </div>
                  </div>
                  <div class="col-md-4 pt-2">
                    <!-- <div class="form-group">
                      <label for="exampleInputPassword1">Certificate of incorporation</label>
                      <input type="file" class="file-upload-default" #file>
                      <div class="attachment-block">
                        <span style="cursor: pointer;" (click)="openCmpFilePopup(companyFilePop, 'certIncorpLoc')"> <i
                            class="icon fa fa fa-paperclip" style="cursor: pointer;"
                            title="Attach file"></i>
                            {{getFileLength('certIncorpLoc')}} Attachment
                        </span>
                      </div>
                    </div> -->

                    <div class="form-group">
                      <label for="exampleInputPassword1">Certificate of incorporation</label>
                      <input type="file" class="form-control file-upload-default" #file>
                      <div class="attachment-block">
                        <span (click)="openCertIncorpLocLocAttach(CertIncorpLocAttachPop)" style="margin-left: 5px;">
                          <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                            title="Attach file"></i></span><span *ngIf="getNoticeLocLength() != 0">
                          {{getNoticeLocLength()}} Attachment</span>
                      </div>
                      <div>
                        <ng-template #CertIncorpLocAttachPop let-modal>
                          <div class="modal-header" style="border: none; padding-bottom: 0px;">
                            <button type="button" class="close" aria-label="Close"
                              (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                          </div>
                          <div class="col-md-12">
                            <label> Attachment</label>
                            <div class="table-responsive">
                              <table class="table table-bordered tablepop">
                                <thead>
                                  <tr class="bgGred">
                                    <th>Upload</th>
                                    <th style="width:50%;">Remark</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let noticeLocGrp of getCertIncorpLocLoc(); let i = index"
                                    [formGroup]="noticeLocGrp">
                                    <td>
                                      <div class="row">
                                        <div class="col-md-9 nopadding">
                                          <div class="form-group">
                                            <input *ngIf="noticeLocGrp.value.isdocLocUploadedClicked" type="file"
                                              id="certIncorpLoc({{i}}).fileLoc" class="filetype form-control"
                                              name="myfile" (change)="onFileSelect($event)">
                                            <p *ngIf="!noticeLocGrp.value.isdocLocUploadedClicked || noticeLocGrp.value.fileLoc"
                                              class="form-control pt10"> {{getFileNameDta(noticeLocGrp.value.fileLoc)}}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-md-3 nopadding">
                                          <label for="exampleInputPassword1">
                                            <a class="downupIcon" *ngIf="noticeLocGrp.value.fileLoc"
                                              title="Download file" (click)="download(noticeLocGrp.value.fileLoc)"><i
                                                class="fa fa-download"></i></a>
                                            <a class="downupIcon"
                                              *ngIf="!(noticeLocGrp.value.fileLoc || noticeLocGrp.value.fileLoc)"
                                              title="Upload file" (click)="uploadOtherAttach(i)"><i
                                                class="fa fa-upload"></i></a>
                                            <a class="downupIcon" (click)="deleteUploadFile(i)"
                                              *ngIf="(noticeLocGrp.value.fileLoc)"><i class="fa fa-trash"
                                                aria-hidden="true"></i></a>
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input type="text" class="form-control" formControlName="remarks"
                                        [title]="noticeLocGrp.value.remarks ? noticeLocGrp.value.remarks:''">
                                      <div
                                        *ngIf="noticeLocGrp.value.remarks && noticeLocGrp.value.remarks.length > 100">
                                        <span class="text-danger">Max character Length 100</span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="modal-footer" style="border: none; padding-top: 0px;">
                            <button type="button" class="btn btn-outline-dark"
                              (click)="modal.close('Save click')">Save</button>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 pt-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1">PAN NO. </label>
                      <input type="text" class="form-control" id="" formControlName="panComp" readonly>

                    </div>
                  </div>
                  <div class="col-md-4 pt-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1">PAN card of company</label>
                      <input type="file" class="form-control file-upload-default" #file>
                      <div class="attachment-block">
                        <span (click)="openPanCompLoc(PanCompLocPop)" style="margin-left: 5px;">
                          <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                            title="Attach file"></i></span><span *ngIf="getPanCompLocLength() != 0">
                          {{getPanCompLocLength()}} Attachment</span>
                      </div>
                      <div>
                        <ng-template #PanCompLocPop let-modal>
                          <div class="modal-header" style="border: none; padding-bottom: 0px;">
                            <button type="button" class="close" aria-label="Close"
                              (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                          </div>
                          <div class="col-md-12">
                            <label> Attachment</label>
                            <div class="table-responsive">
                              <table class="table table-bordered tablepop">
                                <thead>
                                  <tr class="bgGred">
                                    <th>Upload</th>
                                    <th style="width:50%;">Remark</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let panCompLocGrp of getPanCompLoc(); let i = index"
                                    [formGroup]="panCompLocGrp">
                                    <td>
                                      <div class="row">
                                        <div class="col-md-9 nopadding">
                                          <div class="form-group">
                                            <input *ngIf="panCompLocGrp.value.isdocLocUploadedClicked" type="file"
                                              id="panCompLoc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                              (change)="onFileSelect($event)">
                                            <p *ngIf="!panCompLocGrp.value.isdocLocUploadedClicked || panCompLocGrp.value.fileLoc"
                                              class="form-control pt10"> {{getFileNameDta(panCompLocGrp.value.fileLoc)}}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-md-3 nopadding">
                                          <label for="exampleInputPassword1">
                                            <a class="downupIcon" *ngIf="panCompLocGrp.value.fileLoc"
                                              title="Download file" (click)="download(panCompLocGrp.value.fileLoc)"><i
                                                class="fa fa-download"></i></a>
                                            <a class="downupIcon"
                                              *ngIf="!(panCompLocGrp.value.fileLoc || panCompLocGrp.value.fileLoc)"
                                              title="Upload file" (click)="uploadtPanCompLoc(i)"><i
                                                class="fa fa-upload"></i></a>
                                            <a class="downupIcon" (click)="deleteNoticeLoc(i)"
                                              *ngIf="panCompLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                aria-hidden="true"></i></a>
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input type="text" class="form-control" formControlName="remarks"
                                        [title]="panCompLocGrp.value.remarks ? panCompLocGrp.value.remarks:''">
                                      <div
                                        *ngIf="panCompLocGrp.value.remarks && panCompLocGrp.value.remarks.length > 100">
                                        <span class="text-danger">Max character Length 100</span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="modal-footer" style="border: none; padding-top: 0px;">
                            <button type="button" class="btn btn-outline-dark"
                              (click)="modal.close('Save click')">Save</button>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4 pt-2" *ngIf="navContext?.entityType == 'FILING'">
                    <div class="form-group">
                      <label for="exampleInputPassword1">GST Registration Certificate Copy</label>
                      <input type="file" class="form-control file-upload-default" #file>
                      <div class="attachment-block">
                        <span (click)="opengstRegCertLoc(gstRegCertLocPop)" style="margin-left: 5px;">
                          <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                            title="Attach file"></i></span><span *ngIf="getgstRegCertLocLength() != 0">
                          {{getgstRegCertLocLength()}} Attachment</span>
                      </div>
                      <div>
                        <ng-template #gstRegCertLocPop let-modal>
                          <div class="modal-header" style="border: none; padding-bottom: 0px;">
                            <button type="button" class="close" aria-label="Close"
                              (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                          </div>
                          <div class="col-md-12">
                            <label> Attachment</label>
                            <div class="table-responsive">
                              <table class="table table-bordered tablepop">
                                <thead>
                                  <tr class="bgGred">
                                    <th>Upload</th>
                                    <th style="width:50%;">Remark</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let gstRegCertLocGrp of getGstRegCertLoc(); let i = index"
                                    [formGroup]="gstRegCertLocGrp">
                                    <td>
                                      <div class="row">
                                        <div class="col-md-9 nopadding">
                                          <div class="form-group">
                                            <input *ngIf="gstRegCertLocGrp.value.isdocLocUploadedClicked" type="file"
                                              id="gstRegCertLoc({{i}}).fileLoc" class="filetype form-control"
                                              name="myfile" (change)="onFileSelect($event)">
                                            <p *ngIf="!gstRegCertLocGrp.value.isdocLocUploadedClicked || gstRegCertLocGrp.value.fileLoc"
                                              class="form-control pt10">
                                              {{getFileNameDta(gstRegCertLocGrp.value.fileLoc)}}
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-md-3 nopadding">
                                          <label for="exampleInputPassword1">
                                            <a class="downupIcon" *ngIf="gstRegCertLocGrp.value.fileLoc"
                                              title="Download file"
                                              (click)="download(gstRegCertLocGrp.value.fileLoc)"><i
                                                class="fa fa-download"></i></a>
                                            <a class="downupIcon"
                                              *ngIf="!(gstRegCertLocGrp.value.fileLoc || gstRegCertLocGrp.value.fileLoc)"
                                              title="Upload file" (click)="uploadGstRegCertLoc(i)"><i
                                                class="fa fa-upload"></i></a>
                                            <a class="downupIcon" (click)="deleteGstRegCertLoc(i)"
                                              *ngIf="gstRegCertLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                aria-hidden="true"></i></a>
                                          </label>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input type="text" class="form-control" formControlName="remarks"
                                        [title]="gstRegCertLocGrp.value.remarks ? gstRegCertLocGrp.value.remarks:''">
                                      <div
                                        *ngIf="gstRegCertLocGrp.value.remarks && gstRegCertLocGrp.value.remarks.length > 100">
                                        <span class="text-danger">Max character Length 100</span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div class="modal-footer" style="border: none; padding-top: 0px;">
                            <button type="button" class="btn btn-outline-dark"
                              (click)="modal.close('Save click')">Save</button>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Any Specific industry regulation applicable</label>
                      <textarea type="text" class="form-control" rows="4" formControlName="regAppl"
                        [ngClass]="{'errorBorder':basicDetailValue.value.regAppl && basicDetailValue.value.regAppl.length > 1000}"></textarea>
                    </div>
                    <div *ngIf="basicDetailValue.value.regAppl && basicDetailValue.value.regAppl.length > 1000">
                      <span class="text-danger">Max character Length 1000</span>
                    </div>

                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Brief about company operations</label>
                      <textarea type="text" class="form-control" formControlName="breifComp" rows="4"
                        [ngClass]="{'errorBorder':basicDetailValue.value.breifComp && basicDetailValue.value.breifComp.length > 1000}"></textarea>
                    </div>
                    <div *ngIf="basicDetailValue.value.breifComp && basicDetailValue.value.breifComp.length > 1000">
                      <span class="text-danger">Max character Length 1000</span>
                    </div>
                  </div>
                </div>
                <div>
                  <!-- <ng-template #companyFilePop let-modal>
                    <div class="modal-header" style="border: none; padding-bottom: 0px;">
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')"> <span
                          aria-hidden="true">×</span> </button>
                    </div>
                    <div class="col-md-12">
                      <label> Attachment</label>
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr class="bgGred">
                              <th>Upload</th>
                              <th style="width:50%;">Remark</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let fileFormGrp of getFileLoc(); let j = index" [formGroup]="fileFormGrp">
                              <td>
                                <div class="row">
                                  <div class="col-md-9 nopadding">
                                    <div class="form-group">
                                      <input *ngIf="fileFormGrp?.value?.isdocLocUploadedClicked" type="file"
                                        id="{{fileType}}({{j}}).fileLoc" class="filetype form-control" name="myfile"
                                        (change)="onFileSelect($event,'fileFormGrp',j)">
                                      <p *ngIf="!fileFormGrp?.value?.isdocLocUploadedClicked || getFileData(j)" class="form-control pt10"
                                        [title]="fileFormGrp.value.fileName"> {{getFileData(j)}}</p>
                                    </div>
                                  </div>
                                  <div class="col-md-3 nopadding">
                                    <label for="exampleInputPassword1">
                                      <a class="downupIcon" *ngIf="getFileData(j)" title="Download file"
                                        (click)="download(j)"><i class="fa fa-download"></i></a>
                                      <a class="downupIcon" title="Upload file" (click)="uploadLoc(j)"><i
                                          class="fa fa-upload"></i></a>
                                      <a class="downupIcon" (click)="deleteUploadFile(j)"
                                        *ngIf="(fileFormGrp.value.fileLoc)"><i class="fa fa-trash"
                                          aria-hidden="true"></i></a>
                                    </label>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" class="form-control" formControlName="remarks"
                                  [title]="fileFormGrp.value.remarks">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="modal-footer" style="border: none; padding-top: 0px;">
                      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
                    </div>
                  </ng-template> -->
                </div>
              </fieldset>
            </div>
          </div>
          <div class="card borderOrange mt15">
            <div class="card-body">
              <fieldset>
                <div data-toggle="collapse" data-target="#financials" class="expansion-div mb-15"
                  (click)="protestDetailsExpansion = !protestDetailsExpansion">
                  FINANCIALS
                  <span class="consultant-icons"> <i *ngIf="!protestDetailsExpansion" class="fa fa-angle-down"
                      aria-hidden="true"></i> <i *ngIf="protestDetailsExpansion" class="fa fa-angle-up"
                      aria-hidden="true"></i></span>
                </div>
                <div id="financials" class="collapse">
                  <div class="d-flex justify-content-end my-3 me-3">
                    <p class="cursor-pointer card-title p-0 m-0 export-to-excel" (click)="exportAsXLSX('basicDetails')">
                      <i class="fa fa-file-excel-o me-2" aria-hidden="true"></i>Export to Excel
                    </p>
                  </div>
                  <div class="row" formGroupName="basicDetails">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr class="bgGred">
                              <th>Financial Year</th>
                              <th>Balance Sheet and Profit & Loss Account<a class="clrOrange infoIcon"
                                  title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                              </th>
                              <th>Trial balance<a class="clrOrange infoIcon"
                                  title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                              </th>
                              <th class="w-98">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let financialsGrp of getFinancials(); let i=index" [formGroup]="financialsGrp">
                              <td>
                                <input type="text" class="form-control" formControlName="finYear">
                              </td>
                              <td>
                                <span (click)="openDetailPop(financialsPop, i , 'balSheetLoc')"> <i
                                    class="icon fa fa fa-paperclip" title="Attach file"></i></span>
                                <span *ngIf="getFinancialFileLength(i, 'balSheetLoc') != 0"> {{getFinancialFileLength(i,
                                  'balSheetLoc')}} Attachment</span>
                              </td>
                              <td>
                                <span (click)="openDetailPop(trialBalancePop, i , 'trialBalLoc')"> <i
                                    class="icon fa fa fa-paperclip" title="Attach file"></i></span>
                                <span *ngIf="getFinancialFileLength(i, 'trialBalLoc') != 0"> {{getFinancialFileLength(i
                                  , 'trialBalLoc')}} Attachment</span>
                              </td>
                              <!-- <td>
                                <div class="row" >
                                  <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="financialsGrp?.value?.isfinFileLocFileUploadedClicked"  type="file" id="basicDetails(0).financials({{i}}).balSheetLoc" class="filetype4 form-control" name="myfile" (change)='onFileSelect($event)'>
                                        <p *ngIf="!financialsGrp?.value?.isfinFileLocFileUploadedClicked || formGrp?.basicDetails?.get('financials')?.value[i]?.balSheetLoc"  class="form-control pt10">{{getFileNameDta(financialsGrp.value.balSheetLoc)}}</p>
                                      </div>
                                  </div>
                                  <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="formGrp?.basicDetails?.get('financials')?.value[i]?.balSheetLoc"  title="Download file" (click)="download(financialsGrp.value.balSheetLoc)"><i class="fa fa-download"></i></a>
                                        <a class="downupIcon" *ngIf="!formGrp?.basicDetails?.get('financials')?.value[i]?.balSheetLoc || formGrp?.basicDetails?.get('financials')?.value[i]?.balSheetLoc" title="Upload file" (click)="uploadFinfileLoc(i)"><i class="fa fa-upload"></i></a>
                                        <a  class="downupIcon" (click)="deleteBalSheetLocFile(i)" *ngIf="(financialsGrp.value.balSheetLoc)"><i  class="fa fa-trash" aria-hidden="true"></i></a>
                                      </label>
                                  </div>
                              </div>
                           </td>
                           <td>
                            <div class="row" >
                              <div class="col-md-9 nopadding">
                                  <div class="form-group">
                                    <input *ngIf="financialsGrp?.value?.isTrialBalLocFileUploadedClicked" type="file" id="basicDetails(0).financials({{i}}).trialBalLoc" class="filetype4 form-control" name="myfile" (change)='onFileSelect($event)'>
                                    <p *ngIf="!financialsGrp?.value?.isTrialBalLocFileUploadedClicked || formGrp?.basicDetails?.get('financials')?.value[i]?.trialBalLoc" class="form-control pt10"> {{getFileNameDta(financialsGrp.value.trialBalLoc)}}</p>
                                  </div>
                              </div>
                              <div class="col-md-3 nopadding">
                                  <label for="exampleInputPassword1">
                                    <a class="downupIcon" *ngIf="formGrp?.basicDetails?.get('financials')?.value[i]?.trialBalLoc" title="Download file" (click)="download(financialsGrp.value.trialBalLoc)"><i class="fa fa-download"></i></a>
                                    <a class="downupIcon" *ngIf="!formGrp?.basicDetails?.get('financials')?.value[i]?.trialBalLoc || formGrp?.basicDetails?.get('financials')?.value[i]?.trialBalLoc" title="Upload file" (click)="uploadtbFileLoc(i)"><i class="fa fa-upload"></i></a>
                                    <a  class="downupIcon" (click)="deleteFinancialsFile(i)" *ngIf="(financialsGrp.value.trialBalLoc)"><i  class="fa fa-trash" aria-hidden="true"></i></a>
                                  </label>
                              </div>
                          </div>
                          </td> -->
                              <td class="w-98">
                                <a class="pl5 addExtLetter" (click)="addFinancial()"><i class="fa fa-plus"
                                    title="Add row"></i></a>
                                <a class="pl5 addExtLetter" *ngIf="i !=0" (click)="openfinPop(finPop, i)"><i
                                    class="fa fa-minus" style="margin-left: 10px;"></i></a>
                              </td>
                              <ng-template #finPop let-modal>
                                <div class="modal-header">
                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <p>Are you sure, you want to remove?</p>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="rmFinancial(i)">Remove</button>
                                </div>
                              </ng-template>
                              <ng-template #financialsPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment </label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th>Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let financialFromGrp of getFinancialLoc(financialsGrp, 'balSheetLoc'); let j=index"
                                          [formGroup]="financialFromGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="financialFromGrp?.value?.isdocLocUploadedClicked"
                                                    type="file" id="financials({{i}}).balSheetLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelectFinancial($event,'balSheetLoc',j,financialsGrp)"
                                                    [ngClass]="{ 'errorBorder': isSubmitted && auditCompanyGstfrmGrp.value.basicDetails.financials[i].balSheetLoc[j].fileLoc.errors}">
                                                  <p *ngIf="!financialFromGrp?.value?.isdocLocUploadedClicked || auditCompanyGstfrmGrp.value.basicDetails.financials[i].balSheetLoc[j].fileLoc"
                                                    class="form-control pt10"
                                                    [ngClass]="{ 'errorBorder': isSubmitted && auditCompanyGstfrmGrp.value.basicDetails.financials[i].balSheetLoc[j].fileLoc.errors}"
                                                    [title]="financialFromGrp.value.fileName ? financialFromGrp.value.fileName:''">
                                                    {{getFileNameDta(auditCompanyGstfrmGrp.value.basicDetails.financials[i].balSheetLoc[j].fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon"
                                                    *ngIf="auditCompanyGstfrmGrp.value.basicDetails.financials[i].balSheetLoc[j].fileLoc"
                                                    title="Download file"
                                                    (click)="download(auditCompanyGstfrmGrp.value.basicDetails.financials[i].balSheetLoc[j].fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(auditCompanyGstfrmGrp.value.basicDetails.financials[i].balSheetLoc[j].fileLoc || auditCompanyGstfrmGrp.value.basicDetails.financials[i].balSheetLoc[j].fileLoc)"
                                                    title="Upload file" (click)="uploadbsLoc(i,j, 'balSheetLoc')"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteJudicialPredFile(i,j)"
                                                    *ngIf="(financialFromGrp.value.fileLoc)"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <!-- remark -->
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="financialFromGrp.value.remarks ? financialFromGrp.value.remarks:''">
                                            <div
                                              *ngIf="financialFromGrp.value.remarks && financialFromGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                              <ng-template #trialBalancePop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment </label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th>Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let financialFromGrp of getFinancialLoc(financialsGrp, 'trialBalLoc'); let j=index"
                                          [formGroup]="financialFromGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="financialFromGrp?.value?.isdocLocUploadedClicked"
                                                    type="file" id="financials({{i}}).trialBalLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelectFinancial($event,'trialBalLoc',j,financialsGrp)"
                                                    [ngClass]="{ 'errorBorder': isSubmitted && auditCompanyGstfrmGrp.value.basicDetails.financials[i].trialBalLoc[j].fileLoc.errors}">
                                                  <p *ngIf="!financialFromGrp?.value?.isdocLocUploadedClicked || auditCompanyGstfrmGrp.value.basicDetails.financials[i].trialBalLoc[j].fileLoc"
                                                    class="form-control pt10"
                                                    [ngClass]="{ 'errorBorder': isSubmitted && auditCompanyGstfrmGrp.value.basicDetails.financials[i].trialBalLoc[j].fileLoc.errors}"
                                                    [title]="financialFromGrp.value.fileName ? financialFromGrp.value.fileName:''">
                                                    {{getFileNameDta(auditCompanyGstfrmGrp.value.basicDetails.financials[i].trialBalLoc[j].fileLoc)}}
                                                  </p>
                                                  <!-- <div *ngIf="isSubmitted && auditCompanyGstfrmGrp.value.basicDetails.financials[i].trialBalLoc[j].fileLoc.errors"> -->
                                                  <!-- <span class="text-danger" *ngIf="auditCompanyGstfrmGrp.value.basicDetails.financials[i].trialBalLoc[j].fileLoc.errors.required"> Required </span> -->
                                                  <!-- </div> -->
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon"
                                                    *ngIf="auditCompanyGstfrmGrp.value.basicDetails.financials[i].trialBalLoc[j].fileLoc"
                                                    title="Download file"
                                                    (click)="download(auditCompanyGstfrmGrp.value.basicDetails.financials[i].trialBalLoc[j].fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(auditCompanyGstfrmGrp.value.basicDetails.financials[i].trialBalLoc[j].fileLoc || auditCompanyGstfrmGrp.value.basicDetails.financials[i].trialBalLoc[j].fileLoc)"
                                                    title="Upload file" (click)="uploadbsLoc(i,j, 'trialBalLoc')"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteJudicialPredFile(i,j)"
                                                    *ngIf="(financialFromGrp.value.fileLoc)"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <!-- remark -->
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="financialFromGrp.value.remarks ? financialFromGrp.value.remarks:''">
                                            <div
                                              *ngIf="financialFromGrp.value.remarks && financialFromGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </tr>
                          </tbody>

                        </table>
                      </div>
                    </div>

                  </div>

                </div>
              </fieldset>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 grid-margin stretch-card mt15">
              <div class="card borderOrange">
                <div class="card-body">
                  <!-- <p class="card-title">SCN</p>	                 -->
                  <ul class="nav nav-pills iris-gst-display-flex" role="tablist" id="pills-tab">
                    <li class="nav-item">
                      <a class="nav-link " (click)="tabChanged('tab1')" [ngClass]="{'active':selectedTab === 'tab1'}"
                        id="outputTax-tab" data-toggle="tab" role="tab" aria-controls="outputTax"
                        aria-selected="true">Output Tax</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" (click)="tabChanged('tab2')" [ngClass]="{'active':selectedTab === 'tab2'}"
                        id="reverseCharge-tab" data-toggle="tab" role="tab" aria-controls="reverseCharge"
                        aria-selected="true">Reverse Charge</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" (click)="tabChanged('tab3')" [ngClass]="{'active':selectedTab === 'tab3'}"
                        id="inputTaxCredit-tab" data-toggle="tab" role="tab" aria-controls="inputTaxCredit"
                        aria-selected="true">Input Tax Credit </a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" (click)="tabChanged('tab4')" [ngClass]="{'active':selectedTab === 'tab4'}"
                        id="otherSpecificItem-tab" data-toggle="tab" role="tab" aria-controls="otherSpecificItem"
                        aria-selected="true">Other Specific Items</a>
                    </li>

                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane fade show active" id="outputTax" role="tabpanel"
                      aria-labelledby="outputTax-tab">
                      <!-- <app-audit-company-output-tax ></app-audit-company-output-tax> -->
                      <div class="row" *ngIf="selectedTab === 'tab1'">
                        <p class="card-title">Revenue Streams</p>


                        <div class="col-md-12">
                          <div class="form-group">
                            <div data-toggle="collapse" data-target="#operatin" class="expansion-div mb-15"
                              (click)="operatingExpansion = !operatingExpansion">
                              Operating & Other Income <span class="consultant-icons"> <i *ngIf="!operatingExpansion"
                                  class="fa fa-angle-down" aria-hidden="true"></i> <i *ngIf="operatingExpansion"
                                  class="fa fa-angle-up" aria-hidden="true"></i></span> </div>
                            <div id="operatin" class="collapse">
                              <div class="d-flex justify-content-end my-3 me-3">
                                <p class="cursor-pointer card-title p-0 m-0 export-to-excel"
                                  (click)="exportAsXLSX('operAndOtherIncome')"><i class="fa fa-file-excel-o me-2"
                                    aria-hidden="true"></i>Export to Excel</p>
                              </div>
                              <div class="row col-md-12 nopadding">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr class="bgGred">
                                        <th class="sl-no-cls">Sr. No.</th>
                                        <th>Name of Goods/Service</th>
                                        <th>HSN/SAC Code</th>
                                        <th class="w-98">GST Rate</th>
                                        <th>Exemption Notification</th>
                                        <th>Period<a class="clrOrange infoIcon"
                                            title="Maximum Length Limit is 50 Characters."><i
                                              class="fa fa-info-circle"></i></a></th>
                                        <th>Remark</th>
                                        <th class="A-no-cls">Action <a class="addExtLetter"
                                            (click)="addOperatingOtherIncm1()"><i class="fa fa-plus" title="Add row"
                                              style="margin-left: -7px;"></i></a></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let operAndOtherIncomeGrp of getOperAndOtherIncome(); let i=index"
                                        [formGroup]="operAndOtherIncomeGrp">
                                        <td class="sl-no-cls">{{i+1}}</td>
                                        <td><input type="text" class="form-control" formControlName="nameOfService"
                                            [title]="operAndOtherIncomeGrp.value.nameOfService ? operAndOtherIncomeGrp.value.nameOfService:''">
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="sacCode"
                                            maxlength="8"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            [title]="operAndOtherIncomeGrp.value.sacCode?operAndOtherIncomeGrp.value.sacCode:''">
                                        </td>

                                        <td class="w-98">
                                          <select class="form-control form-select" id="" formControlName="gstRate"
                                            [title]="operAndOtherIncomeGrp.value.gstRate ? operAndOtherIncomeGrp.value.gstRate:''">
                                            <ng-container *ngFor="let obj of gstRateList">
                                              <option [value]="obj.key">{{obj.value}}</option>
                                            </ng-container>
                                          </select>
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="exempNotify"
                                            data-bs-toggle="tooltip" data-bs-placement="top"
                                            [title]="operAndOtherIncomeGrp.value.exempNotify ? operAndOtherIncomeGrp.value.exempNotify:''">
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="period"
                                            [title]="operAndOtherIncomeGrp.value.period ? operAndOtherIncomeGrp.value.period:''"
                                            maxlength="50"></td>
                                        <td><input type="text" class="form-control" formControlName="descService"
                                            data-bs-toggle="tooltip" data-bs-placement="top"
                                            [title]="operAndOtherIncomeGrp.value.descService ? operAndOtherIncomeGrp.value.descService:''"
                                            (click)="operAndOtherBoxModal('Remark', 'descService',auditCompanyGstfrmGrp,i)">
                                        </td>

                                        <td class="A-no-cls">
                                          <a class="addExtLetter" *ngIf="i !=0"
                                            (click)="openoperateOtherPop(operateOtherPop, i)"><i
                                              class="fa fa-minus"></i></a>
                                        </td>

                                        <ng-template #operateOtherPop let-modal>
                                          <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <p>Are you sure, you want to remove?</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="rmOperatingOtherIncm1(i)">Remove</button>
                                          </div>
                                        </ng-template>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group" style="margin-top: 30;">
                            <div data-toggle="collapse" data-target="#Supply" class="expansion-div mb-15"
                              (click)="supplyExpansion = !supplyExpansion">
                              Supply of Capital Goods (like Office Equipment, Motor Cars, Furniture etc.)/ Scrap / Any
                              Other Goods <span class="consultant-icons"> <i *ngIf="!supplyExpansion"
                                  class="fa fa-angle-down" aria-hidden="true"></i> <i *ngIf="supplyExpansion"
                                  class="fa fa-angle-up" aria-hidden="true"></i></span> </div>
                            <div id="Supply" class="collapse">
                              <div class="d-flex justify-content-end my-3 me-3">
                                <p class="cursor-pointer card-title p-0 m-0 export-to-excel"
                                  (click)="exportAsXLSX('supplyOfCapitalGoods')"><i class="fa fa-file-excel-o me-2"
                                    aria-hidden="true"></i>Export to Excel</p>
                              </div>
                              <div class="row col-md-12 nopadding">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr class="bgGred">
                                        <th class="sl-no-cls">Sr.No.</th>
                                        <th>Name of Goods</th>
                                        <!-- <th>Description of Service</th> -->
                                        <th>HSN Code</th>
                                        <th>GST Rate</th>
                                        <th>Exemption Notification</th>
                                        <th>Period <a class="clrOrange infoIcon"
                                            title="Maximum Length Limit is 50 Characters."><i
                                              class="fa fa-info-circle"></i></a></th>
                                        <th>Remark</th>
                                        <th class="A-no-cls">Action <a class="addExtLetter"
                                            (click)="addsupplyofCap()"><i class="fa fa-plus" title="Add row"
                                              style="margin-left: -7px;"></i></a></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let supplyOfCapitalGoodsGrp of getSupplyOfCapitalGoods(); let i=index"
                                        [formGroup]="supplyOfCapitalGoodsGrp">
                                        <td class="sl-no-cls">{{i+1}}</td>
                                        <td><input type="text" class="form-control" formControlName="nameOfGoods"
                                            [title]="supplyOfCapitalGoodsGrp.value.nameOfGoods ?supplyOfCapitalGoodsGrp.value.nameOfGoods:''">
                                        </td>
                                        <!-- <td><input type="text" class="form-control" formControlName="descService2"> </td> -->
                                        <td><input type="text" class="form-control" formControlName="hsnCode"
                                            maxlength="8" (keypress)="omitChar($event)"
                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                            [title]="supplyOfCapitalGoodsGrp.value.hsnCode?supplyOfCapitalGoodsGrp.value.hsnCode:''">
                                        </td>
                                        <td> <select class="form-control form-select" id="" formControlName="gstRate"
                                            [title]="supplyOfCapitalGoodsGrp.value.gstRate ? supplyOfCapitalGoodsGrp.value.gstRate:''">
                                            <ng-container *ngFor="let obj of gstRateList">
                                              <option [value]="obj.key">{{obj.value}}</option>
                                            </ng-container>
                                          </select> </td>
                                        <td><input type="text" class="form-control" formControlName="exempNotify"
                                            [title]="supplyOfCapitalGoodsGrp.value.exempNotify ? supplyOfCapitalGoodsGrp.value.exempNotify:''">
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="period"
                                            maxlength="50"
                                            [title]="supplyOfCapitalGoodsGrp.value.period ? supplyOfCapitalGoodsGrp.value.period:''">
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="remark"
                                            [title]="supplyOfCapitalGoodsGrp.value.remark ? supplyOfCapitalGoodsGrp.value.remark:''"
                                            (click)="supplyOfCapitalBoxModal('Remark', 'remark',auditCompanyGstfrmGrp,i)">
                                        </td>
                                        <td class="A-no-cls">
                                          <a class="addExtLetter" *ngIf="i !=0"
                                            (click)="opensupplyofCap(supplyofCapPop, i)"><i class="fa fa-minus"></i></a>
                                        </td>
                                        <ng-template #supplyofCapPop let-modal>
                                          <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <p>Are you sure, you want to remove?</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="rmsupplyofCap(i)">Remove</button>
                                          </div>
                                        </ng-template>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>

                        <!-- Advances Received  -->
                        <div class="col-md-12">
                          <div class="form-group" style="margin-top: 30;">
                            <div data-toggle="collapse" data-target="#demo" class="expansion-div mb-15"
                              (click)="advancesReceivedExpansion = !advancesReceivedExpansion">
                              Details of Advances Received From Customers <span class="consultant-icons"> <i
                                  *ngIf="!advancesReceivedExpansion" class="fa fa-angle-down" aria-hidden="true"></i> <i
                                  *ngIf="advancesReceivedExpansion" class="fa fa-angle-up"
                                  aria-hidden="true"></i></span> </div>
                            <div id="demo" class="collapse">
                              <div class="d-flex justify-content-end my-3 me-3">
                                <p class="cursor-pointer card-title p-0 m-0 export-to-excel"
                                  (click)="exportAsXLSX('advancesReceived')"><i class="fa fa-file-excel-o me-2"
                                    aria-hidden="true"></i>Export to Excel</p>
                              </div>
                              <div class="row col-md-12 nopadding">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr class="bgGred">
                                        <th class="sl-no-cls">Sr. No.</th>
                                        <th>Name of Goods Services on Which Advances Have Taken</th>
                                        <th>Period <a class="clrOrange infoIcon"
                                            title="Maximum Length Limit is 50 Characters."><i
                                              class="fa fa-info-circle"></i></a></th>
                                        <th>Remark</th>
                                        <th class="A-no-cls">Action <a class="addExtLetter"
                                            (click)="addAdvancesOfReceived()"><i class="fa fa-plus" title="Add row"
                                              style="margin-left: -7px;"></i></a></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        *ngFor="let advancesOfReceivedGoodsGrp of getAdvancesOfReceivedGoods(); let i=index"
                                        [formGroup]="advancesOfReceivedGoodsGrp">
                                        <td class="sl-no-cls">{{i+1}}</td>
                                        <td><input type="text" class="form-control" formControlName="nameOfGoods"
                                            [title]="advancesOfReceivedGoodsGrp.value.nameOfGoods ? advancesOfReceivedGoodsGrp.value.nameOfGoods:''">
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="period"
                                            maxlength="50"
                                            [title]="advancesOfReceivedGoodsGrp.value.period ? advancesOfReceivedGoodsGrp.value.period:''">
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="remark"
                                            [title]="advancesOfReceivedGoodsGrp.value.remark ? advancesOfReceivedGoodsGrp.value.remark:''"
                                            (click)="advancesReceivedBoxModal('Remark', 'remark',auditCompanyGstfrmGrp,i)">
                                        </td>

                                        <!-- <td> <select class="form-control form-select" id="" formControlName="gstRate">
                                        <ng-container *ngFor="let obj of gstRateList">
                                          <option [value]="obj.key">{{obj.value}}</option>
                                        </ng-container>
                                      </select> </td> -->
                                        <td class="A-no-cls">
                                          <a class="addExtLetter" *ngIf="i !=0"
                                            (click)="openadvancesfCap(advanceofCapPop, i)"><i
                                              class="fa fa-minus"></i></a>
                                        </td>
                                        <ng-template #advanceofCapPop let-modal>
                                          <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <p>Are you sure, you want to remove?</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="rmAdvancesOfReceived(i)">Remove</button>
                                          </div>
                                        </ng-template>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Whether Advances is received from Customers</label>
                            <select class="form-control form-select" id="proceededToScn"
                              (change)="checkWeatherAdvRecYes($event)" formControlName="weatherAdvRec">
                              <option [value]="null" disabled>-- Select --</option>
                              <ng-container *ngFor="let obj of yesNoList">
                                <option [value]="obj.value">{{obj.label}}</option>
                              </ng-container>
                            </select>
                          </div>
                        </div> -->
                        <!-- <div class="col-md-12" [hidden]="isAdvRecYes">
                          <div class="form-group">
                            <label for="exampleInputPassword1">List of Goods/ Services on which advances have
                              taken</label>
                            <textarea class="form-control" id="exampleTextarea1" rows="4"
                              formControlName="advancesReceived"></textarea>
                          </div>
                        </div> -->
                      </div>
                    </div>

                    <div class="tab-pane fade show active" id="reverseCharge" role="tabpanel"
                      aria-labelledby="reverseCharge-tab">
                      <!-- <app-audit-company-reverse-charge *ngIf="selectedTab === 'tab2'"></app-audit-company-reverse-charge> -->
                      <div class="row" *ngIf="selectedTab === 'tab2'">
                        <div class="col-md-12">
                          <p class="card-title">Reverse Charge Mechanism</p>
                        </div>
                        <div class="col-md-12">
                          <div data-toggle="collapse" data-target="#general" class="expansion-div mb-15"
                            (click)="generalExpansion = !generalExpansion">
                            General<span class="consultant-icons"> <i *ngIf="!generalExpansion" class="fa fa-angle-down"
                                aria-hidden="true"></i> <i *ngIf="generalExpansion" class="fa fa-angle-up"
                                aria-hidden="true"></i></span> </div>
                          <div id="general" class="collapse">
                            <div class="d-flex justify-content-end my-3 me-3">
                              <p class="cursor-pointer card-title p-0 m-0  export-to-excel"
                                (click)="exportAsXLSX('reverseChrgGenInfo')"><i class="fa fa-file-excel-o me-2"
                                  aria-hidden="true"></i>Export to Excel</p>
                            </div>
                            <!-- <p class="">General  -->
                            <!-- <a class="addGSAvail" (click)="addReverseChrgGenInfo()"><i
                                  class="fa fa-plus"></i></a> -->
                            <!-- </p> -->

                            <div class="row col-md-12 nopadding">
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th class="sl-no-cls">Sr.No.</th>
                                      <th class="upload-column-cls">Name of Service</th>
                                      <th>Applicability?</th>
                                      <th class="Gs-no-cls">GST Rate</th>
                                      <th>Period <a class="clrOrange infoIcon"
                                          title="Maximum Length Limit is 50 Characters."><i
                                            class="fa fa-info-circle"></i></a></th>
                                      <th style="min-width: 230px;">Remark</th>
                                      <th class="A-no-cls">Action <a class="addGSAvail"
                                          (click)="addReverseChrgGenInfo()"><i class="fa fa-plus"
                                            title="Add row"></i></a></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let reverseChrgGenInfoGrp of getreverseChrgGenInfo(); let i=index"
                                      [formGroup]="reverseChrgGenInfoGrp">
                                      <td class="sl-no-cls">{{i+1}}</td>
                                      <td class="upload-column-cls">
                                        <input *ngIf="i>=7" type="text" class="form-control"
                                          formControlName="servicesFrom"
                                          [title]="reverseChrgGenInfoGrp.value.servicesFrom ? reverseChrgGenInfoGrp.value.servicesFrom:''">
                                        <label *ngIf="i<7">{{reverseChrgGenInfoGrp.controls.servicesFrom.value}}</label>
                                      </td>
                                      <td><select class="form-control form-select" id="gstin"
                                          formControlName="applicability"
                                          [title]="reverseChrgGenInfoGrp.value.applicability ? reverseChrgGenInfoGrp.value.applicability:''">
                                          <ng-container *ngFor="let obj of yesNoList">
                                            <option [value]="obj.value">{{obj.label}}</option>
                                          </ng-container>
                                        </select>

                                      </td>
                                      <td class="Gs-no-cls"> <select class="form-control form-select" id=""
                                          formControlName="gstRate"
                                          [title]="reverseChrgGenInfoGrp.value.gstRate ?reverseChrgGenInfoGrp.value.gstRate:''"
                                          [ngClass]="{'disabled' : reverseChrgGenInfoGrp.controls.applicability.value == 'false'}">
                                          <ng-container *ngFor="let obj of gstRateList">
                                            <option [value]="obj.key">{{obj.value}}</option>
                                          </ng-container>
                                        </select>
                                        <div class="error-msg"
                                          *ngIf="(reverseChrgGenInfoGrp.controls.applicability.value == 'true') && (!reverseChrgGenInfoGrp.controls.gstRate.value)">
                                          Please add GST rate</div>
                                      </td>
                                      <td><input type="text" class="form-control" formControlName="period"
                                          maxlength="50"
                                          [title]="reverseChrgGenInfoGrp.value.period ?reverseChrgGenInfoGrp.value.period:''">
                                      </td>
                                      <td><input type="text" class="form-control" formControlName="remark"
                                          [title]="reverseChrgGenInfoGrp.value.remark ?reverseChrgGenInfoGrp.value.remark:''"
                                          (click)="openTextBoxModal1('Remark', 'remark',auditCompanyGstfrmGrp,i)"></td>
                                      <td class="A-no-cls">
                                        <a class="addExtLetter" *ngIf="i>6" (click)="openReverseChrg(reverGovPop,i)"><i
                                            class="fa fa-minus"></i></a>
                                      </td>
                                      <ng-template #reverGovPop let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmReverseChrgGenInfo(i)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 mt-2">
                          <div class="form-group">
                            <div data-toggle="collapse" data-target="#services" class="expansion-div mb-15"
                              (click)="servicesExpansion = !servicesExpansion">
                              Services from Government
                              <span class="consultant-icons"> <i *ngIf="!servicesExpansion" class="fa fa-angle-down"
                                  aria-hidden="true"></i> <i *ngIf="servicesExpansion" class="fa fa-angle-up"
                                  aria-hidden="true"></i></span>
                            </div>
                            <div id="services" class="collapse">
                              <!-- <label for="exampleInputPassword1">Services from Government
                              <a class="addExtLetter" (click)="addServGov()"><i class="fa fa-plus"></i></a>
                              </label> -->
                              <div class="d-flex justify-content-end my-3 me-3">
                                <p class="cursor-pointer card-title p-0 m-0  export-to-excel"
                                  (click)="exportAsXLSX('servicesFromGov')"><i class="fa fa-file-excel-o me-2"
                                    aria-hidden="true"></i>Export to Excel</p>
                              </div>
                              <div class="row col-md-12 nopadding">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr class="bgGred">
                                        <th class="sl-no-cls">Sr.No.</th>
                                        <th style="min-width: 360px;">Name of Service</th>
                                        <th class="Gs-no-cls">GST Rate</th>
                                        <th>Period <a class="clrOrange infoIcon"
                                            title="Maximum Length Limit is 50 Characters."><i
                                              class="fa fa-info-circle"></i></a></th>
                                        <th style="min-width:280px;">Remark</th>
                                        <th class="A-no-cls">Action<a class="addExtLetter" (click)="addServGov()"><i
                                              class="fa fa-plus" title="Add row" style="margin-left: -7px;"></i></a>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let servicesFromGovGrp of getservicesFromGov(); let i=index"
                                        [formGroup]="servicesFromGovGrp">
                                        <td class="sl-no-cls">{{i+1}}</td>
                                        <td>
                                          <input type="text" class="form-control" formControlName="nameOfService"
                                            [title]="servicesFromGovGrp.value.nameOfService?servicesFromGovGrp.value.nameOfService:''">
                                          <!-- <label>{{servicesFromGovGrp.controls.nameOfService.value}}</label> -->
                                          <!-- <select class="form-control form-select" id="gstin"
                                          formControlName="nameOfService">
                                          <ng-container *ngFor="let obj of serviceFrmGovList">
                                            <option [value]="obj.value">{{obj.value}}</option>
                                          </ng-container>
                                        </select> -->

                                        <td class="Gs-no-cls"><select class="form-control form-select" id=""
                                            formControlName="gstRate"
                                            [title]="servicesFromGovGrp.value.gstRate?servicesFromGovGrp.value.gstRate:''">
                                            <ng-container *ngFor="let obj of gstRateList">
                                              <option [value]="obj.key">{{obj.value}}</option>
                                            </ng-container>
                                          </select>
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="period"
                                            maxlength="50"
                                            [title]="servicesFromGovGrp.value.period ? servicesFromGovGrp.value.period:''">
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="descService"
                                            data-bs-toggle="tooltip" data-bs-placement="top"
                                            [title]="servicesFromGovGrp.value.descService?servicesFromGovGrp.value.descService:''"
                                            (click)="servicesFromGovBoxModal('Remark', 'descService',auditCompanyGstfrmGrp,i)">
                                        </td>
                                        <td class="A-no-cls">
                                          <a class="addExtLetter" *ngIf="i !=0"
                                            (click)="openRmvservGovPop(servGovPop, i)"><i class="fa fa-minus"></i></a>
                                        </td>
                                        <ng-template #servGovPop let-modal>
                                          <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <p>Are you sure, you want to remove?</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="rmServGov(i)">Remove</button>
                                          </div>
                                        </ng-template>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <div data-toggle="collapse" data-target="#import" class="expansion-div mb-15"
                              (click)="importExpansion = !importExpansion">
                              Import of Services <span class="consultant-icons"> <i *ngIf="!importExpansion"
                                  class="fa fa-angle-down" aria-hidden="true"></i> <i *ngIf="importExpansion"
                                  class="fa fa-angle-up" aria-hidden="true"></i></span> </div>
                            <div id="import" class="collapse">
                              <!-- <label for="exampleInputPassword1">Import of Services </label> -->
                              <div class="d-flex justify-content-end my-3 me-3">
                                <p class="cursor-pointer card-title p-0 m-0  export-to-excel"
                                  (click)="exportAsXLSX('importOfServices')"><i class="fa fa-file-excel-o me-2"
                                    aria-hidden="true"></i>Export to Excel</p>
                              </div>
                              <div class="row col-md-12 nopadding">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr class="bgGred">
                                        <th class="sl-no-cls">Sr.No.</th>
                                        <th style="min-width: 360px;">Name of Service</th>
                                        <th class="Gs-no-cls">GST Rate</th>
                                        <th>Period <a class="clrOrange infoIcon"
                                            title="Maximum Length Limit is 50 Characters."><i
                                              class="fa fa-info-circle"></i></a></th>
                                        <th style="min-width:280px;">Remark</th>
                                        <th class="A-no-cls">Action <a class="addExtLetter" (click)="addImpSrc()"><i
                                              class="fa fa-plus" title="Add row" style="margin-left: -7px;"></i></a>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let importOfServicesGrp of getimportOfServices(); let i=index"
                                        [formGroup]="importOfServicesGrp">
                                        <td class="sl-no-cls">{{i+1}}</td>
                                        <td><input type="text" class="form-control" formControlName="nameOfService"
                                            [title]="importOfServicesGrp.value.nameOfService?importOfServicesGrp.value.nameOfService:''">
                                        </td>
                                        <td class="Gs-no-cls"><select class="form-control form-select" id=""
                                            formControlName="gstRate"
                                            [title]="importOfServicesGrp.value.gstRate ? importOfServicesGrp.value.gstRate:''">
                                            <ng-container *ngFor="let obj of gstRateList">
                                              <option [value]="obj.key">{{obj.value}}</option>
                                            </ng-container>
                                          </select>
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="period"
                                            maxlength="50"
                                            [title]="importOfServicesGrp.value.period?importOfServicesGrp.value.period:''">
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="descService"
                                            data-bs-toggle="tooltip" data-bs-placement="top"
                                            [title]="importOfServicesGrp.value.descService ?importOfServicesGrp.value.descService:''"
                                            (click)="importOfServicesBoxModal('Remark', 'descService',auditCompanyGstfrmGrp,i)">
                                        </td>
                                        <td class="A-no-cls">
                                          <!-- <a class="addExtLetter" (click)="addImpSrc()"><i class="fa fa-plus"></i></a> -->
                                          <a class="addExtLetter" *ngIf="i !=0" (click)="openImpSrcPop(impSrcPop, i)"><i
                                              class="fa fa-minus"></i></a>
                                        </td>
                                        <ng-template #impSrcPop let-modal>
                                          <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <p>Are you sure, you want to remove?</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="rmImpSrc(i)">Remove</button>
                                          </div>
                                        </ng-template>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="tab-pane fade show active" id="inputTaxCredit-1" role="tabpanel"
                      aria-labelledby="inputTaxCredit-tab">
                      <!-- <app-audit-company-input-tax-credit *ngIf="selectedTab === 'tab3'"></app-audit-company-input-tax-credit> -->
                      <div class="row" *ngIf="selectedTab === 'tab3'">
                        <div class="col-md-12">
                          <div class="col-md-12 mt15">
                            <div data-toggle="collapse" data-target="#input" class="expansion-div mb-15"
                              (click)="inputCreditExpansion = !inputCreditExpansion">
                              Input Tax Credit - Ineligible Credit
                              <span class="consultant-icons"> <i *ngIf="!inputCreditExpansion" class="fa fa-angle-down"
                                  aria-hidden="true"></i> <i *ngIf="inputCreditExpansion" class="fa fa-angle-up"
                                  aria-hidden="true"></i></span>
                            </div>
                            <div id="input" class="collapse">
                              <!-- <p class="">Input Tax Credit  -->
                              <!-- <a class="addGSAvail" (click)="additcIneligibleCreditArr()"><i
                                  class="fa fa-plus"></i></a> -->
                              <!-- </p> -->
                              <div class="d-flex justify-content-end my-3 me-3">
                                <p class="cursor-pointer card-title p-0 m-0  export-to-excel"
                                  (click)="exportAsXLSX('itcIneligibleCredit')"><i class="fa fa-file-excel-o me-2"
                                    aria-hidden="true"></i>Export to Excel</p>
                              </div>
                              <div class="row col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr class="bgGred">
                                        <th class="sl-no-cls">Sr.No.</th>
                                        <th class="upload-column-cls">Items</th>
                                        <th>Applicability?</th>
                                        <th>Period <a class="clrOrange infoIcon"
                                            title="Maximum Length Limit is 50 Characters."><i
                                              class="fa fa-info-circle"></i></a></th>
                                        <th style="min-width: 230px;">Remark</th>
                                        <th class="A-no-cls">Action <a class="addGSAvail"
                                            (click)="additcIneligibleCreditArr()"><i class="fa fa-plus"
                                              title="Add row"></i></a></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let itcIneligibleCreditGrp of getitcIneligibleCredit(); let i=index"
                                        [formGroup]="itcIneligibleCreditGrp">
                                        <td class="sl-no-cls">{{i+1}}</td>
                                        <td class="upload-column-cls">
                                          <div>
                                            <input *ngIf="i>=9" type="text" class="form-control" formControlName="item"
                                              [title]="itcIneligibleCreditGrp.value.item ? itcIneligibleCreditGrp.value.item:''"
                                              (click)="openTextBoxModal('Items', 'item',auditCompanyGstfrmGrp,i)">
                                            <label *ngIf="i<9">{{itcIneligibleCreditGrp.controls.item.value}}</label>
                                          </div>
                                        </td>
                                        <td>
                                          <select class="form-control" id="gstin" formControlName="applicability"
                                            [title]="itcIneligibleCreditGrp.value.applicability? itcIneligibleCreditGrp.value.applicability:''">
                                            <ng-container *ngFor="let obj of yesNoList">
                                              <option [value]="obj.value">{{obj.label}}</option>
                                            </ng-container>
                                          </select>
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="period"
                                            maxlength="50"
                                            [title]="itcIneligibleCreditGrp.value.period? itcIneligibleCreditGrp.value.period:''">
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="remark"
                                            [title]="itcIneligibleCreditGrp.value.remark?itcIneligibleCreditGrp.value.remark:''"
                                            (click)="openTextBoxModal('	Remark', 'remark',auditCompanyGstfrmGrp,i)">
                                        </td>
                                        <td class="A-no-cls">
                                          <a class="addExtLetter" *ngIf="i>8"
                                            (click)="openitcIneligible(itcIneligiblePop,i)"><i
                                              class="fa fa-minus"></i></a>
                                        </td>
                                        <ng-template #itcIneligiblePop let-modal>
                                          <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <p>Are you sure, you want to remove?</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="rmitcIneligibleCreditArr(i)">Remove</button>
                                          </div>
                                        </ng-template>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="col-md-12 mt15">
                            <div data-toggle="collapse" data-target="#reversals" class="expansion-div mb-15"
                              (click)="reversalsExpansion = !reversalsExpansion">
                              Reversals
                              <span class="consultant-icons"> <i *ngIf="!reversalsExpansion" class="fa fa-angle-down"
                                  aria-hidden="true"></i> <i *ngIf="reversalsExpansion" class="fa fa-angle-up"
                                  aria-hidden="true"></i></span>
                            </div>
                            <div id="reversals" class="collapse">
                              <!-- <p class="">Reversals  -->
                              <!-- <a class="addGSAvail" (click)="addItcReversals()"><i class="fa fa-plus"></i></a> -->
                              <!-- </p> -->
                              <div class="d-flex justify-content-end my-3 me-3">
                                <p class="cursor-pointer card-title p-0 m-0  export-to-excel"
                                  (click)="exportAsXLSX('itcReversals')"><i class="fa fa-file-excel-o me-2"
                                    aria-hidden="true"></i>Export to Excel</p>
                              </div>
                              <div class="row col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr class="bgGred">
                                        <th class="sl-no-cls">Sr. No.</th>
                                        <th class="upload-column-cls">Type of Reversal</th>
                                        <th>Applicability?</th>
                                        <th>Period <a class="clrOrange infoIcon"
                                            title="Maximum Length Limit is 50 Characters."><i
                                              class="fa fa-info-circle"></i></a></th>
                                        <th style="min-width: 270px;">Remark</th>
                                        <th class="A-no-cls">Action<a class="addGSAvail" (click)="addItcReversals()"><i
                                              class="fa fa-plus" title="Add row"></i></a></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr *ngFor="let itcReversalsGrp of getitcReversals(); let i=index"
                                        [formGroup]="itcReversalsGrp">
                                        <td class="sl-no-cls">{{i+1}}</td>
                                        <td class="upload-column-cls">
                                          <input type="text" class="form-control" formControlName="type"
                                            [title]="itcReversalsGrp.value.type?itcReversalsGrp.value.type:''">
                                          <!-- <label>{{itcReversalsGrp.controls.type.value}}</label> -->
                                        </td>

                                        <td><select class="form-control form-select" id=""
                                            formControlName="applicability"
                                            [title]="itcReversalsGrp.value.applicability?itcReversalsGrp.value.applicability:''">
                                            <ng-container *ngFor="let obj of yesNoList">
                                              <option [value]="obj.value">{{obj.label}}</option>
                                            </ng-container>
                                          </select>
                                          <!-- <input *ngIf="showAnyOther" type="text" class="form-control"
                                        formControlName="applicability">  -->
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="period"
                                            maxlength="50"
                                            [title]="itcReversalsGrp.value.period?itcReversalsGrp.value.period:''"></td>
                                        <td style="min-width: 270px;"><input type="text" class="form-control"
                                            formControlName="remark"
                                            [title]="itcReversalsGrp.value.remark?itcReversalsGrp.value.remark:''"
                                            (click)="openTextBoxReversals('Remark', 'remark',auditCompanyGstfrmGrp,i)">
                                        </td>
                                        <td class="A-no-cls">
                                          <a class="addExtLetter" *ngIf="i !=0"
                                            (click)="openReversals(reversalsPop,i)"><i class="fa fa-minus"></i></a>
                                        </td>

                                        <ng-template #reversalsPop let-modal>
                                          <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <p>Are you sure, you want to remove?</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="rmItcReversals(i)">Remove</button>
                                          </div>
                                        </ng-template>
                                      </tr>
                                      <!-- <tr *ngFor="let itcReversalsAOGrp of getitcReversalsAO(); let i=index"
                                  [formGroup]="itcReversalsAOGrp">
                                    <th>{{k+1+reverseChrgGenInfoGrp.length()}}</th> -->
                                      <!-- <td colspan="2">
                                      <div class="row">
                                        <div class="col-md-10"><input  type="text" class="form-control" formControlName="type"></div>
                                        <div class="col-md-2 pt10"><a class="addExtLetter" (click)="setRemoveModalData(removeTemplate, 'rmitcReversalsAO', i)"><i class="fa fa-minus"></i></a></div>
                                       </div>

                                      </td>
                                    <td><select class="form-control form-select" id="" formControlName="reference">
                                      <ng-container *ngFor="let obj of itcReveRefList">
                                        <option [value]="obj.value">{{obj.value}}</option>
                                      </ng-container>
                                    </select>
                                  </td>
                                  <td> <select class="form-control form-select" id=""
                                    formControlName="applicability">
                                    <ng-container *ngFor="let obj of yesNoList">
                                      <option [value]="obj.value">{{obj.label}}</option>
                                    </ng-container>
                                  </select></td>
                                </tr>   -->
                                      <!-- <tr>
                                  <td colspan="5">
                                  <a class="addGSAvail " (click)="additcReversalsAO()"><i
                                    class="fa fa-plus"></i> <span class="card-title2"> Any Other Reversal (please provide details)</span></a>
                                  </td>
                                </tr>  -->
                                    </tbody>

                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="col-md-12 mt15">
                            <div data-toggle="collapse" data-target="#goodsServices" class="expansion-div mb-15"
                              (click)="goodsServicesExpansion = !goodsServicesExpansion">
                              Goods/Services (Other Than Above) on Which ITC Not Availed?
                              <span class="consultant-icons">
                                <i *ngIf="!goodsServicesExpansion" class="fa fa-angle-down" aria-hidden="true"></i> <i
                                  *ngIf="goodsServicesExpansion" class="fa fa-angle-up" aria-hidden="true"></i></span>
                            </div>
                            <div id="goodsServices" class="collapse">
                              <!-- <div class="col-md-12">
                            <p class="">Goods/Services (other than above) on which ITC not availed? <a
                                class="addGSAvail" (click)="addGSAvail()"><i class="fa fa-plus"></i></a> </p>
                          </div> -->
                              <div class="d-flex justify-content-end my-3 me-3">
                                <p class="cursor-pointer card-title p-0 m-0  export-to-excel"
                                  (click)="exportAsXLSX('itcNotAvailed')"><i class="fa fa-file-excel-o me-2"
                                    aria-hidden="true"></i>Export to Excel</p>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="table-responsive">
                                    <table class="table table-bordered">
                                      <thead>
                                        <tr class="bgGred">
                                          <th class="sl-no-cls">Sr.No.</th>
                                          <th class="upload-column-cls">Items</th>
                                          <th>Reason</th>
                                          <th>Period <a class="clrOrange infoIcon"
                                              title="Maximum Length Limit is 50 Characters."><i
                                                class="fa fa-info-circle"></i></a></th>
                                          <th style="min-width: 250px;">Remark</th>
                                          <th class="A-no-cls">Action <a class="addGSAvail" (click)="addGSAvail()"><i
                                                class="fa fa-plus" title="Add row"></i></a></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let itcNotAvailedGrp of getitcNotAvailed(); let i=index"
                                          [formGroup]="itcNotAvailedGrp">
                                          <td class="sl-no-cls">{{i+1}}</td>
                                          <td class="upload-column-cls">
                                            <input type="text" class="form-control" formControlName="item"
                                              [title]="itcNotAvailedGrp.value.item?itcNotAvailedGrp.value.item:''">
                                          </td>
                                          <td><input type="text" class="form-control" formControlName="reason"
                                              data-bs-toggle="tooltip" data-bs-placement="top"
                                              [title]="itcNotAvailedGrp.value.reason?itcNotAvailedGrp.value.reason:''">
                                          </td>
                                          <td><input type="text" class="form-control" formControlName="period"
                                              [title]="itcNotAvailedGrp.value.period?itcNotAvailedGrp.value.period:''"
                                              maxlength="50"></td>
                                          <td><input type="text" class="form-control" formControlName="remark"
                                              [title]="itcNotAvailedGrp.value.remark?itcNotAvailedGrp.value.remark:''"
                                              (click)="itcNotAvailedBoxModal('Remark', 'remark',auditCompanyGstfrmGrp,i)">
                                          </td>
                                          <td class="A-no-cls">
                                            <a class="addExtLetter" *ngIf="i !=0" (click)="gsAvailPop(GSAvailPop, i)"><i
                                                class="fa fa-minus"></i></a>
                                          </td>
                                          <ng-template #GSAvailPop let-modal>
                                            <div class="modal-header">
                                              <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                              <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">×</span>
                                              </button>
                                            </div>
                                            <div class="modal-body">
                                              <p>Are you sure, you want to remove?</p>
                                            </div>
                                            <div class="modal-footer">
                                              <button type="button" class="btn btn-outline-dark"
                                                (click)="rmGSAvail(i)">Remove</button>
                                            </div>
                                          </ng-template>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="col-md-12 mt15">
                            <div data-toggle="collapse" data-target="#demo" class="expansion-div mb-15"
                              (click)="operatingExpansion = !operatingExpansion">
                              Treatment of Specific Items
                              <span class="consultant-icons">
                                <i *ngIf="!operatingExpansion" class="fa fa-angle-down" aria-hidden="true"></i> <i
                                  *ngIf="operatingExpansion" class="fa fa-angle-up" aria-hidden="true"></i></span>
                            </div>
                            <div id="demo" class="collapse">
                              <div class="d-flex justify-content-end my-3 me-3">
                                <p class="cursor-pointer card-title p-0 m-0  export-to-excel"
                                  (click)="exportAsXLSX('specificItemTreatment')"><i class="fa fa-file-excel-o me-2"
                                    aria-hidden="true"></i>Export to Excel</p>
                              </div>
                              <div class="col-md-12 mt15">
                                <!-- <p class="">Treatment of Specific Items -->
                                <!-- <a class="addGSAvail" (click)="addspecificItemTreatmentArr()"><i class="fa fa-plus"></i></a> -->
                                <!-- </p> -->

                              </div>
                              <div class="row col-md-12">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr class="bgGred">
                                        <th class="sl-no-cls">Sr.No.</th>
                                        <th class="upload-column-cls">Specific Items</th>
                                        <th>ITC Availed</th>
                                        <th>Period <a class="clrOrange infoIcon"
                                            title="Maximum Length Limit is 50 Characters."><i
                                              class="fa fa-info-circle"></i></a></th>
                                        <th style="min-width: 250px;">Remark</th>
                                        <th class="A-no-cls">Action<a class="addGSAvail"
                                            (click)="addspecificItemTreatmentArr()"><i class="fa fa-plus"
                                              title="Add row"></i></a></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        *ngFor="let specificItemTreatmentGrp of getspecificItemTreatment(); let i=index"
                                        [formGroup]="specificItemTreatmentGrp">
                                        <td class="sl-no-cls">{{i+1}}</td>
                                        <td class="upload-column-cls">
                                          <input type="text" class="form-control" formControlName="item"
                                            [title]="specificItemTreatmentGrp.value.item?specificItemTreatmentGrp.value.item:''">
                                          <!-- <label>{{specificItemTreatmentGrp.controls.item.value}}</label> -->
                                          <!-- <select class="form-control form-select" id="gstin" formControlName="item">
                                        <ng-container *ngFor="let obj of specificItemTreatList">
                                          <option [value]="obj.value">{{obj.value}}</option>
                                        </ng-container>
                                      </select> -->
                                        </td>
                                        <td><select class="form-control form-select" id="gstin"
                                            formControlName="itcAvailed"
                                            [title]="specificItemTreatmentGrp.value.itcAvailed ? specificItemTreatmentGrp.value.itcAvailed:''">
                                            <ng-container *ngFor="let obj of yesNoList">
                                              <option [value]="obj.value">{{obj.label}}</option>
                                            </ng-container>
                                          </select>
                                        </td>
                                        <td><input type="text" class="form-control" formControlName="period"
                                            maxlength="50"
                                            [title]="specificItemTreatmentGrp.value.period? specificItemTreatmentGrp.value.period:''">
                                        </td>
                                        <td style="min-width: 250px;"><input type="text" class="form-control"
                                            formControlName="remark"
                                            [title]="specificItemTreatmentGrp.value.remark?specificItemTreatmentGrp.value.remark:''"
                                            (click)="specificItemTreatmentBoxModal('Remark', 'remark',auditCompanyGstfrmGrp,i)">
                                        </td>
                                        <td class="A-no-cls">
                                          <a class="addExtLetter" *ngIf="i !=0"
                                            (click)="openSpecificItems(SpecificPop,i)"><i class="fa fa-minus"></i></a>
                                        </td>
                                        <ng-template #SpecificPop let-modal>
                                          <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')">
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="modal-body">
                                            <p>Are you sure, you want to remove?</p>
                                          </div>
                                          <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="rmspecificItemTreatmentArr(i)">Remove</button>
                                          </div>
                                        </ng-template>
                                      </tr>
                                    </tbody>

                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade show active" id="otherSpecificItem-1" role="tabpanel"
                        aria-labelledby="otherSpecificItem-tab">
                        <!-- <app-audit-company-other-specific-item *ngIf="selectedTab === 'tab4'"></app-audit-company-other-specific-item> -->
                        <div class="row" *ngIf="selectedTab === 'tab4'">
                          <div class="col-md-12">
                            <div class="col-md-12 mt15">
                              <div data-toggle="collapse" data-target="#specific" class="expansion-div mb-15"
                                (click)="specificExpansion = !specificExpansion">
                                Other Specific Items
                                <span class="consultant-icons">
                                  <i *ngIf="!specificExpansion" class="fa fa-angle-down" aria-hidden="true"></i> <i
                                    *ngIf="specificExpansion" class="fa fa-angle-up" aria-hidden="true"></i></span>
                              </div>
                              <div id="specific" class="collapse">
                                <!-- <p class="">Other Specific Items  -->
                                <!-- <a class="addGSAvail"
                                (click)="addotherSpecificItemsArr()"><i class="fa fa-plus"></i></a> -->
                                <!-- </p> -->
                                <div class="d-flex justify-content-end my-3 me-3">
                                  <p class="cursor-pointer card-title p-0 m-0  export-to-excel"
                                    (click)="exportAsXLSX('otherSpecificItems')"><i class="fa fa-file-excel-o me-2"
                                      aria-hidden="true"></i>Export to Excel</p>
                                </div>

                                <div class="row col-md-12">
                                  <div class="table-responsive">
                                    <table class="table table-bordered">
                                      <thead>
                                        <tr class="bgGred">
                                          <th class="sl-no-cls">Sr. No.</th>
                                          <th class="upload-column-cls">Items</th>
                                          <!-- <th>Details</th> -->
                                          <th>Period <a class="clrOrange infoIcon"
                                              title="Maximum Length Limit is 50 Characters."><i
                                                class="fa fa-info-circle"></i></a></th>
                                          <th style="min-width: 250px;">Remark</th>
                                          <th class="A-no-cls">Action <a class="addExtLetter"
                                              (click)="addotherSpecificItemsArr()"><i class="fa fa-plus" title="Add row"
                                                style="margin-left: -7px;"></i></a></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let otherSpecificItemsGrp of getotherSpecificItems(); let i=index"
                                          [formGroup]="otherSpecificItemsGrp">
                                          <td class="sl-no-cls">{{i+1}}</td>
                                          <td class="upload-column-cls">
                                            <input *ngIf="i>=10" type="text" class="form-control" formControlName="item"
                                              data-bs-toggle="tooltip" data-bs-placement="top"
                                              [title]="otherSpecificItemsGrp.value.item ? otherSpecificItemsGrp.value.item:''"
                                              (click)="openTextBoxOtherSpecificItems('Items', 'item',auditCompanyGstfrmGrp,i)">
                                            <label *ngIf="i<10">{{otherSpecificItemsGrp.controls.item.value}}</label>
                                            <!-- <select class="form-control form-select" id="gstin" formControlName="item">
                                        <ng-container *ngFor="let obj of otherSpecficItemList">
                                          <option [value]="obj.value">{{obj.value}}</option>
                                        </ng-container>
                                      </select> -->
                                          </td>
                                          <!-- <td><input type="text" class="form-control" formControlName="detail" data-bs-toggle="tooltip" data-bs-placement="top" [title]="otherSpecificItemsGrp.value.detail"></td> -->
                                          <td><input type="text" class="form-control" formControlName="period"
                                              maxlength="50"
                                              [title]="otherSpecificItemsGrp.value.period ? otherSpecificItemsGrp.value.period:''">
                                          </td>
                                          <td style="min-width: 250px;"><input type="text" class="form-control"
                                              formControlName="remark"
                                              [title]="otherSpecificItemsGrp.value.remark ? otherSpecificItemsGrp.value.remark:''"
                                              (click)="openTextBoxOtherSpecificItems('Remark', 'remark',auditCompanyGstfrmGrp,i)">
                                          </td>
                                          <td class="A-no-cls">
                                            <a class="addExtLetter" *ngIf="i>9"
                                              (click)="openOtherSpecificItems(otherSpecificPop,i)"><i
                                                class="fa fa-minus"></i></a>
                                          </td>
                                          <ng-template #otherSpecificPop let-modal>
                                            <div class="modal-header">
                                              <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                              <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">×</span>
                                              </button>
                                            </div>
                                            <div class="modal-body">
                                              <p>Are you sure, you want to remove?</p>
                                            </div>
                                            <div class="modal-footer">
                                              <button type="button" class="btn btn-outline-dark"
                                                (click)="rmotherSpecificItemsArr(i)">Remove</button>
                                            </div>
                                          </ng-template>


                                        </tr>
                                      </tbody>

                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <button class="btn btn-outline-primary ml-1" (click)="saveCompanyGst()"
                *ngIf="!['GST_Viewer','Viewer'].includes(role)">Save</button>
              <!-- <button class="btn btn-outline-primary ml-1" >Update</button> -->
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #removeTemplate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure, you want to remove?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="removeClicked()">Remove</button>
  </div>
</ng-template>