<div class="panel-wrapper">
    <div class="panel-head">
        <div class="page-title">
            <h1><a [routerLink]="'/dtNmProceeding'">Proceedings</a> / <span>Detail View</span></h1>
            <div class="action-bar">
                <a class="iris-gst-pull-right close" (click)="toPreviousPage(noticeData?.data?.noticeObj?.proceedingReqId)" id="download" title="close">x</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-header">
                                <i class="fa fa-info-circle"></i> Basic Information
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Reference ID</label>
                                        <p>{{noticeData?.data?.noticeObj?.documentReferenceId}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Assessment Year</label>
                                        <p>{{noticeData?.data?.noticeObj?.ay}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Proceeding Type</label>
                                        <p>{{noticeData?.data?.noticeObj?.proceedingType}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Notice Section </label>
                                        <p>{{noticeData?.data?.noticeObj?.noticeSection}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Proceeding Section </label>
                                        <p>{{noticeData?.data?.noticeObj?.noticeName}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Notice Issued On </label>
                                        <p>{{noticeData?.data?.noticeObj?.parsedIssuedOn}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Response Due Date</label>
                                        <p>{{noticeData?.data?.noticeObj?.parsedResponseDueDate}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Last Response Type</label>
                                        <p>{{getresTypeLabel(noticeData?.data?.noticeObj?.respType)}}</p>
                                    </div>
                                    <!-- <div class="col-md-4">
                                        <label>Header Sequence No</label>
                                        <p>{{noticeData?.data?.noticeObj?.headerSeqNo}}</p>
                                    </div> -->
                                    <div class="col-md-4">
                                        <label>Proceeding Request ID </label>
                                        <p>{{noticeData?.data?.noticeObj?.proceedingReqId}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Notice Id </label>
                                        <p>{{noticeData?.data?.noticeObj?.noticeId}}</p>
                                    </div>
                                    <div class="col-md-12">
                                        <label>Description </label>
                                        <p>{{noticeData?.data?.noticeObj?.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-header">
                                <i class="fa fa-cloud-download" aria-hidden="true"></i> Files
                            </div>
                            <div class="card-body">
                                <div class="attachmentDiv">
                                    <ng-container *ngIf="noticeData.data.files.length != 0">
                                        <ul *ngFor="let obj of noticeData.data.files">
                                            <li class="wrapText"><a title="{{obj.name}}"
                                                    (click)="download(obj.s3Key, obj.noticeId, obj.docName)"><i
                                                        class="fa fa-download clrOrange notifyIcon"></i>
                                                    {{splitfilename(obj.docName)}}</a></li>
                                        </ul>
                                    </ng-container>
                                    <ng-container *ngIf="noticeData.data.files.length == 0">
                                        <p class="noData">No Attachments Found</p>
                                    </ng-container>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-header">
                                <i class="fa fa-file-text-o"></i> Response
                            </div>
                            <div class="card-body ">
                                <ng-container *ngIf="noticeData?.data?.noticeObj?.remarks">
                                    <p>{{noticeData?.data?.noticeObj?.remarks}}</p>
                                </ng-container>
                                <ng-container *ngIf="!noticeData?.data?.noticeObj?.remarks">
                                    <p class="noData">No Response Found</p>
                                </ng-container>
                                <div class="attachmentDiv">
                                    <ng-container *ngIf="noticeData.data.respFiles.length != 0">
                                        <ul *ngFor="let obj of noticeData.data.respFiles">
                                            <li class="wrapText"><a title="{{obj.name}}"
                                                    (click)="download(obj.s3Key, obj.noticeId, obj.docName)"><i
                                                        class="fa fa-download clrOrange notifyIcon"></i>
                                                    {{obj.docName}}</a></li>
                                        </ul>
                                    </ng-container>
                                    <ng-container *ngIf="noticeData.data.respFiles.length == 0">
                                        <p class="noData">No Attachments Found</p>
                                    </ng-container>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-header">
                                <i class="fa fa-file-text-o"></i> Adjournment
                            </div>
                            <div class="card-body plr0">
                                <ng-container *ngIf="adjDataCount !== 0">
                                    <div class="table-responsive nopadding">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>PAN</th>
                                                    <th>Adjournment Request ID</th>
                                                    <th>Reason</th>
                                                    <th>Adjournment Request Date</th>
                                                    <th>Adjournment Sought Up to</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let obj of adjData " let i="index">
                                                    <tr>

                                                        <td>
                                                            <p>{{obj.data.pan}}</p>
                                                            <p class="subTd">{{obj.data.panName}}</p>
                                                        </td>
                                                        <td>
                                                            <p><a class="anchor"
                                                                    (click)="toAdjDetail(obj.data.adjVcReqstId)">{{obj.data.adjVcReqstId}}</a>
                                                            </p>
                                                        </td>
                                                        <td>{{obj.data.adjReason}}</td>
                                                        <td>{{obj.data.parsedRequestDate}}</td>
                                                        <td>{{obj.data.parsedResDueDate}}</td>

                                                    </tr>

                                                </ng-container>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="adjDataCount === 0">
                                    <p class="noData">No Records Found</p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-header">
                                <i class="fa fa-file-text-o"></i> Video Conferencing
                            </div>
                            <div class="card-body plr0">
                                <ng-container *ngIf="vcDataCount !== 0">
                                    <div class="table-responsive nopadding">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>PAN</th>
                                                    <th>Notice ID</th>
                                                    <th>VC Reason</th>
                                                    <th>Request Date</th>
                                                    <th>VC Date</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container *ngFor="let obj of vcData " let i="index">
                                                    <tr>
                                                        <td>
                                                            <p>{{obj.data.videoconferencingObj.pan}}</p>
                                                            <p class="subTd">{{obj.data.videoconferencingObj.panName}}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p><a (click)="toVcDetail(obj.data.videoconferencingObj.parentNoticeheaderSeqNo)"
                                                                    class="anchor">{{obj.data.videoconferencingObj.parentNoticeheaderSeqNo}}</a>
                                                            </p>
                                                        </td>
                                                        <td>{{obj.data.videoconferencingObj.adjReason}}</td>
                                                        <td>{{obj.data.videoconferencingObj.parsedRequestDate}}</td>
                                                        <td>{{obj.data.videoconferencingObj.vcAdjRqstDate}}</td>
                                                        <td>
                                                            <a class="mr-3 anchor"
                                                                (click)="onClickFileVCName(obj.data.filesList)"
                                                                data-toggle="modal" data-target="#downloadModelvc"
                                                                id="download">Downloads
                                                            </a>

                                                        </td>
                                                    </tr>

                                                </ng-container>
                                            </tbody>
                                        </table>
                                        <br />

                                    </div>
                                </ng-container>
                                <ng-container *ngIf="vcDataCount === 0 ">
                                    <p class="noData">No Records Found</p>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>
</div>
<div class="modal fade" id="downloadModel" tabindex="-1" role="dialog" aria-labelledby="downloadModelLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="downloadModelLabel">Attachment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closeModal>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="noticeFiles.length !== 0">
                    <ul *ngFor="let item of noticeFiles">
                        <li class="wrapText"><a title="{{item.docName}}"
                                (click)="download(item.s3Key, item.noticeId, item.docName)"><i
                                    class="fa fa-download clrOrange notifyIcon"></i> {{item.docName}}</a></li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="noticeFiles.length == 0">
                    <p class="noData">No Attachments Found</p>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="downloadModelvc" tabindex="-1" role="dialog" aria-labelledby="downloadModelLabelvc"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="downloadModelLabelvc">Attachment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closeModal>&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ng-container *ngIf="vcFiles.length !== 0">
                    <ul *ngFor="let item of vcFiles">
                        <li class="wrapText"><a title="{{item.docName}}"
                                (click)="downloadvc(item.s3Key, item.parentNoticeHeaderSeqNo, item.docName)"><i
                                    class="fa fa-download clrOrange notifyIcon"></i> {{item.docName}}</a></li>
                    </ul>
                </ng-container>
                <ng-container *ngIf="vcFiles.length == 0">
                    <p class="noData">No Attachments Found</p>
                </ng-container>
            </div>
        </div>
    </div>
</div>
