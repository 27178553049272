import { Component, Input, OnInit } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { CommonService } from 'src/app/shared/services/common.service';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-a2-chart',
  templateUrl: './a2-chart.component.html',
  styleUrls: ['./a2-chart.component.css']
})
export class A2ChartComponent implements OnInit {
  public barChartOptions: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: 'Act',
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: 'Demand in ₹ in Lakh',
        },
      },
    },
  };

  public barChartLabels: string[] = [];
  public barChartType: any = 'bar';
  public barChartLegend: boolean = true;

  a2BarChartLabels: any = [];
  a2BarChartLegend = true;
  a2BarChartData: any = [];
  @Input() a2Data: any;
  chartColors: any = [];
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.initializeA2Data();
  }

  initializeA2Data() {
    let a2: any = this.a2Data;
    let subLabel: any = [];
    this.a2BarChartLabels = Object.keys(a2);
    this.a2BarChartLabels.forEach((a2Labels: any) => {
      subLabel.push(...Object.keys(a2[a2Labels].details));
    });

    subLabel = [...new Set(subLabel)];
    subLabel.forEach((label: any) => {
      this.a2BarChartData.push({ data: [], label: label });
    });

    this.a2BarChartData.forEach((d: any) => {
      this.a2BarChartLabels.forEach((label: any, index: any) => {
        d.data.push(a2[label].details[d.label] ? (a2[label].total / a2[label].details[d.label].total) : 0);
      });
    });
    this.a2BarChartData.forEach((a: any) => {
      const randomColor = this.commonService.getRandomColor();
      a.backgroundColor = randomColor;
      a.borderColor = randomColor;
      this.chartColors.push(randomColor);
    });

    // this.a2BarChartData.forEach((data: any) =>{
    //   data.data = data.data.map((d:any) => this.commonService.convertToLac(d));
    // });  
  }
}


