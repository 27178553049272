String.prototype.toColor = strToColor;

interface String {
    toColor: typeof strToColor;
}

function strToColor(this: string, exclude?: string[]): string {
    let colors = [
        "#3f51b5", "#5677fc", "#00bcd4", "#ff9800", "#009688", "#00BBE0", "#ffc107", "#afb42b",
        "#03a9f4", "#ff5722", "#9c27b0", "#ff8da1", "#e91e63", "#673ab7", "#795548", "#607d8b",
        "#d32f2f", "#c2185b", "#7b1fa2", "#512da8", "#303f9f", "#1976d2", "#0288d1", "#0097a7",
        "#00796b", "#388e3c", "#689f38", "#afb42b", "#fbc02d", "#ffa000", "#f57c00", "#e64a19",
        "#5d4037", "#616161", "#455a64", "#ff5252", "#e040fb", "#7c4dff", "#536dfe", "#448aff",
        "#40c4ff", "#18ffff", "#64ffda", "#69f0ae", "#b2ff59", "#eeff41", "#ffff00", "#ffd740",
        "#ffab40", "#ff6e40", "#8e24aa", "#d81b60", "#3d5afe", "#304ffe", "#0288d1", "#00796b",
        "#388e3c", "#f57f17", "#f4511e", "#6d4c41", "#546e7a", "#d50000", "#c51162", "#aa00ff",
        "#6200ea", "#304ffe", "#2962ff", "#0091ea", "#00b8d4", "#00bfa5", "#00c853", "#64dd17",
        "#aeea00", "#ffd600", "#ffab00", "#ff6d00", "#dd2c00", "#6200ea", "#304ffe", "#0091ea",
        "#00bfa5", "#64dd17", "#aeea00", "#ffd600", "#ffab00", "#ff6d00", "#dd2c00", "#aa00ff",
        "#6200ea", "#2962ff", "#00b0ff", "#00e676", "#76ff03", "#c6ff00", "#ffea00", "#ffc400",
        "#ff9100", "#ff3d00", "#d500f9", "#651fff", "#00b0ff", "#00e5ff", "#1de9b6", "#00c853",
        "#64dd17", "#aeea00", "#ffd600", "#ffab00", "#ff6d00", "#dd2c00", "#ff1744", "#f50057",
        "#d500f9", "#651fff", "#3d5afe", "#2979ff", "#00e5ff", "#00b8d4", "#1de9b6", "#00c853",
        "#64dd17", "#aeea00", "#ffd600", "#ffab00", "#ff6d00", "#dd2c00"
    ];

    colors = colors.filter(c => !exclude || !exclude.includes(c));

    var hash = 0;
    if (this.length === 0) return '#259b24';
    for (var i = 0; i < this.length; i++) {
        hash = this.charCodeAt(i) + ((hash << 5) - hash);
        hash = hash & hash;
    }
    hash = ((hash % colors.length) + colors.length) % colors.length;
    return colors[hash];
}
