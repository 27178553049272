import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { NoticeServiceService } from '../../services/notification.service';

@Component({
  selector: 'app-vc-detail-view',
  templateUrl: './vc-detail-view.component.html',
  styleUrls: ['./vc-detail-view.component.css']
})
export class VcDetailViewComponent implements OnInit {
  parentNoticeheaderSeqNo: any;
  vcData: any;
  constructor(
    private noticeService: NoticeServiceService,
    private activeSnapShot: ActivatedRoute,
    private toaster: ToasterService,
  ) {
    this.parentNoticeheaderSeqNo = this.activeSnapShot.snapshot.params.parentNoticeheaderSeqNo;
  }

  ngOnInit(): void {
    this.vcListData();
  }
  //vc list data api
  vcListData() {
    const payload: any = {
      page: 0,
      size: 50,
      module: "DT",
      criterias: [{
        "p": "parentNoticeheaderSeqNo",
        "o": "in",
        "v": this.parentNoticeheaderSeqNo
      }]
    }
    this.noticeService.getVcListData(payload).subscribe((res) => {
      this.vcData = res.response.results[0];

    });
  }
  //vc list data api


}
