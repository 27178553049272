<div class="row">
  <div class="col-6" *ngIf="!sidemenu">
    <select class="form-control form-select" id="selectedFile"
      (change)="selectedFileUrlUpdate($event.target.value)">
      <ng-container *ngFor="let obj of uploadedFileUrlList ">
        <option [value]="obj.url">{{obj.name}}</option>
      </ng-container>
    </select>
    <embed [src]="selectedFileUrl" class="w100" style="height:100%;" *ngIf="!sidemenu" />
  </div>

<div class="col-6" [ngClass]="[sidemenu ? 'col-12' : 'col-6']" >
  <div class="row">

  <div class="col-md-5">
    <ul class="nav nav-tabs nav nav-tabs iris-gst-display-flex b1" role="tablist">
      <li class="nav-item">
        <a class="nav-link" id="noticep1-tab" data-toggle="tab" (click)="tabChanged('tab1')"
          [ngClass]="(selectedTab === 'tab1') ? 'active': ''" role="tab" aria-controls="notice-1"
          aria-selected="true">Phase
          1</a>
      </li>
      <li class="nav-item" [ngClass]="{'disabled': !isPhase2 && !(action == 'update' && getAccessTabData.scnFlag)}">
        <a class="nav-link" id="noticep2-tab" data-toggle="tab" (click)="tabChanged('tab2')"
          [ngClass]="(selectedTab === 'tab2') ? 'active': ''" role="tab" aria-controls="noticep2-1"
          aria-selected="false">Phase 2</a>
      </li>
    </ul>
  </div>
  <div class="col-md-3">
    <div class="textCenter pt11">
      <span class="infoVal fs16" *ngIf="isUpdate" title="Case ID">{{caseData.caseId}}</span>
    </div>
  </div>
  <div class="col-md-4">
    <span class="computationBtn" *ngIf="caseData">
      <span class="nav-data" *ngIf="caseData"><span class="text-data"> Last Updated By : </span>{{caseData.updatedBy}}
        &nbsp;
        <span class="text-data"> On : </span> {{caseData.updatedOn}} </span>
    </span>
  </div>
  </div>

<div class="tab-content nopadding">
  <form [formGroup]="letterP1Form" (keydown.enter)="$event.preventDefault()">
    <div class="tab-pane fade show active" id="noticep1-1" role="tabpanel" aria-labelledby="noticep1-tab">
      <div class="card borderOrange" [hidden]="selectedTab === 'tab2'"
        [ngClass]="{'bgGray': (action == 'view' )} ">
        <div class="card-body">

          <fieldset [disabled]="action == 'view'  ">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">GSTIN <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="" readonly placeholder="" formControlName="gstin">


                </div>
              </div>
              <div class="col-md-3" >
                <div class="form-group">
                  <label for="exampleInputPassword1">Division <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input class="form-control" id="" readonly placeholder="" formControlName="gstinDiv">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">State <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="" readonly formControlName="state">

                </div>
              </div>
              <div class="col-md-3" *ngIf="isUpdate">
                <div class="form-group">
                  <label for="exampleInputPassword1">Case ID <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="caseId" readonly formControlName="caseId">

                </div>
              </div>
              <!-- new -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Consultant</label>
                   <input type="text" class="form-control" id="" placeholder="" formControlName="consultant"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.consultant.errors }">
                  <div *ngIf="isSubmitted && p1.consultant.errors">
                    <p class="text-danger" *ngIf="letterP1Form.controls['consultant'].hasError('maxlength')">
                      Maximum 50 characters allowed.
                    </p>
                  </div>

                  <div *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" class="input-group">
                    <input type="text" class="form-control"
                      *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" placeholder=""
                      formControlName="consultant" readonly>
                    <button *ngIf="isToggleConsultantDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('p1')">
                      <i class="fa fa-pencil"></i>
                    </button> 
                  </div>

                  <select class="form-control form-select" id=""
                    *ngIf="!isToggleConsultantDropdown || action==='create'" formControlName="consultant">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of consultantList">
                      <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                    </ng-container>
                  </select>

                </div>
              </div> -->

              <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create')">
                <div class="form-group">
                  <label for="exampleInputPassword1">Consultant</label>
              
                  <!-- For Consultant Role: Create, View, and Update -->
                  <div *ngIf="roleName === 'Consultant' && action === 'create'">
                    <input 
                      type="text" 
                      class="form-control" 
                      formControlName="consultant" 
                      [readOnly]="action === 'create'">
                  </div>
              
                  <!-- For Non-Consultant Role -->
                  <div *ngIf="roleName !== 'Consultant'">
                    <div *ngIf="isToggleConsultantDropdown && (action === 'update' || action === 'view')" class="input-group">
                      <input 
                        type="text" 
                        class="form-control" 
                        placeholder="" 
                        formControlName="consultant" 
                        readonly>
                      <button 
                        *ngIf="isToggleConsultantDropdown && action === 'update'" 
                        class="btn btn-outline-secondary" 
                        type="button" 
                        (click)="onClickToggleConsultantDropdown('p1')">
                        <i class="fa fa-pencil"></i>
                      </button>
                    </div>
              
                    <select 
                      class="form-control form-select" 
                      id="" 
                      *ngIf="!isToggleConsultantDropdown || action === 'create'" 
                      formControlName="consultant">
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of consultantList">
                        <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>
               
              <!-- new -->
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Person responsible <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                    <div *ngIf="isToggleDropdown && action==='update' || action==='view'" class="input-group">
                      <input type="text" class="form-control"
                        *ngIf="isToggleDropdown && action==='update' || action==='view'" placeholder="" formControlName="personResponsible" readonly>
                      <button *ngIf="isToggleDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleDropdown()">
                        <i class="fa fa-pencil"></i>
                      </button> 
                    </div>

                  <select class="form-control form-select" id="personResponsible"
                    *ngIf="!isToggleDropdown || action==='create'" formControlName="personResponsible"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.personResponsible.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of userRoleLs">
                      <option [value]="obj.userName">{{obj.userName}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.personResponsible.errors">
                    <span class="text-danger" *ngIf="p1.personResponsible.errors.required"> Required
                    </span>
                  </div>
                </div>
              </div>

              <!-- case Label -->
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Case Label <span class="mandate"><i
                        class="fa fa-asterisk"></i></span><a class="clrOrange infoIcon"
                      title="Please give a one line description to identify the case."><i class="fa fa-info-circle"
                        style="margin-left:3px;"></i></a></label>
                  <textarea class="form-control" id="exampleTextarea1" rows="4" formControlName="caseLabel"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.caseLabel.errors }"></textarea>
                </div>
                <div *ngIf="isSubmitted && p1.caseLabel.errors">
                  <span class="text-danger" *ngIf="p1.caseLabel.errors.required"> Required</span>
                </div>
                <div *ngIf="isSubmitted && p1.caseLabel.errors">
                  <span class="text-danger" *ngIf="p1.caseLabel.errors.minlength">Please enter min. 5 chars.</span>
                </div>
                <div *ngIf="isSubmitted && p1.caseLabel.errors">
                  <span class="text-danger" *ngIf="p1.caseLabel.errors.maxlength">Cannot enter more than 100
                    chars.</span>
                </div>
              </div>
              <div class="col-md-3">
                <button class="btn btn-outline-primary mt33" (click)="createLetter()" *ngIf="isCaseData">Add
                  Letter</button>
              </div>
              <div>
                <div class="col-md-12 mt-4" id="letter{{i}}" *ngFor="let div of getletters(); let i=index"
                  [formGroup]="div">
                  <div class="card border-bottom">
                    <div class="card-header" role="tab" id="letter-1">
                      <h6 class="mb-0">
                        <a data-toggle="collapse" href="#letter-1" aria-expanded="true" aria-controls="letter-1">
                          Letter {{i+1}}</a>
                      </h6>
                    </div>
                    <div id="letter-1" class="collapse show" role="tabpanel" aria-labelledby="letter-1"
                      data-parent="#accordion"
                      [ngClass]="{'bgGray': action === 'view' }">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-3" *ngIf="hideOcrFiled && (i === getletters().length - 1)">
                            <div class="form-group">
                              <span class="row" *ngIf="hidlabels"> <button class="btn-beta"> Beta</button></span>
                              <label for="exampleInputPassword1" style="font-weight: 600;">Upload PDF Notice/Letter for AI Smart fill</label>
                              <input type="file" class="file-upload-default" #file>
                              <div class="attachment-block">
                                <span (click)="openNoticePdfPop(noticePdfFormPop, i)">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getLetterLocLength(i) != 0">
                                  {{getLetterLocLength(i)}} Attachment</span>
                              </div>
                              <div>
                                <ng-template #noticePdfFormPop let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment  <span style="font-weight: 500;color: #025c48;">
                                       <br>Note: First upload system generated notice followed by annexure
                                     </span></label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let letterFromGrp of getletterLoc(div); let j = index" [formGroup]="letterFromGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="letterFromGrp.value.isdocLocUploadedClicked"
                                                      type="file" id="itemList({{i}}).letterLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelectSmart($event,i)" style="border: 1px solid #0000;">
                                                    <p *ngIf="!letterFromGrp.value.isdocLocUploadedClicked || letterFromGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(letterFromGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="letterFromGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(letterFromGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(letterFromGrp.value.fileLoc || letterFromGrp.value.fileLoc)&& isDisableIcons"
                                                      title="Upload file" (click)="uploadLetterLocCopy(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deleteLetterLocAi(i,j)"
                                                      *ngIf="(letterFromGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>

                          </div>
                          <div class="col-md-3" *ngIf="hiddenbtn  && (i == getletters().length - 1)" style="margin-top: 16px;" >                           
                              <button class="btn1" (click)="onSmartUpload('p1',i)">AI Smart Fill</button> <i class="fa fa-hand-o-left smartinfo"></i>
                              <span class="blink2">Please click here</span>                           
                          </div>
                          <div class="col-md-6" *ngIf="!processed   && (i == getletters().length - 1)">
                            <div style="text-align: left; font-size:14px;margin-top:28px;" >
                              <i class="fa fa-spinner fa-spin refreshIcon"  *ngIf="hideRefreshIcon">
                              </i> <span  class="errmsg">{{statusData}}</span>
                            </div> 
                          </div>

                        </div>
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Letter Reference Number <span class="mandate"><i
                                    class="fa fa-asterisk"></i></span></label>
                              <input type="text" class="form-control" id="refNum" placeholder=""
                                formControlName="letterRefNo"
                                [ngClass]="{ 'errorBorder': isSubmitted && div.get('letterRefNo')?.errors  }">
                              <div *ngIf="isSubmitted && div.get('letterRefNo')?.errors">
                                <span class="text-danger" *ngIf="div.get('letterRefNo')?.errors?.required "> Required
                                </span>
                              </div>
                            </div>
                          </div>
                          <!--  Type of Issuing Authority  -->
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Type of Issuing Authority <span class="mandate"><i
                                    class="fa fa-asterisk"></i></span></label>
                              <select class="form-control form-select" id="authority" formControlName="typeOfAuthority"
                                (change)="issuingAuthChange()"
                                [ngClass]="{ 'errorBorder': isSubmitted && div.get('typeOfAuthority')?.errors  }">
                                <!-- [ngClass]="{ 'errorBorder': isSubmitted && div.get('typeOfAuthority')?.errors }"> -->
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of preLitPH1Preceded ">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                              <div *ngIf="isSubmitted && div.get('typeOfIssuingAuthority')?.errors">
                                <span class="text-danger dateErrors"
                                  *ngIf="div.get('typeOfIssuingAuthority')?.errors?.required">
                                  Required
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">DIN </label>
                              <input type="text" class="form-control" id="din" placeholder="" formControlName="din"
                                [ngClass]="{ 'errorBorder': isSubmitted && div.get('din')?.errors }">
                              <div *ngIf="isSubmitted && div.get('din')?.errors">
                                <span class="text-danger dateErrors" *ngIf="div.get('din')?.errors?.required" style="margin-top: -19px;">
                                  Required
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-3 disabledSelect">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Mode of receipt of notice</label>
                              <select class="form-control form-select" formControlName="orderReceiptMode">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfReceiptNotice">
                                  <option [value]="obj.value">{{obj.value}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Date of Letter <span class="mandate"><i
                                    class="fa fa-asterisk"></i></span></label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="dateOfLetter" [monthNavigator]="true"
                                  [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                                  [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                  [ngClass]="{ 'errorBorder': isSubmitted && div.get('dateOfLetter')?.errors }">
                                </p-calendar>
                                <div *ngIf="isSubmitted && div.get('dateOfLetter')?.errors">
                                  <span class="text-danger dateError" *ngIf="div.get('dateOfLetter')?.errors?.required">
                                    Required
                                  </span>
                                </div>
                              </div>

                            </div>
                          </div>
                          <!-- Date of Receipt of Letter* -->
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Date of Receipt of Letter<span class="mandate"><i
                                    class="fa fa-asterisk"></i></span></label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="letterReceiptDate"
                                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                  [ngClass]="{ 'errorBorder': isSubmitted && div.get('letterReceiptDate')?.errors }">
                                </p-calendar>
                                <div *ngIf="isSubmitted && div.get('letterReceiptDate')?.errors">
                                  <span class="text-danger dateError"
                                    *ngIf="div.get('letterReceiptDate')?.errors?.required">
                                    Required
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Issued U/s -->
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Issued U/s</label>
                              <select class="form-control form-select" id="authority" formControlName="issuedUs">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of preLitIssuedPH1Preceded ">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                              <div *ngIf="isSubmitted && div.get('issued')?.errors">
                                <span class="text-danger dateError" *ngIf="div.get('issued')?.errors?.required">
                                  Required
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- Type of form/Notice -->
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Type of form/Notice</label>
                              <select class="form-control form-select" id="authority" formControlName="typeOfForm">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of typeOfFormNotice">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                              <div *ngIf="isSubmitted && div.get('typeOfForm')?.errors">
                                <span class="text-danger dateError" *ngIf="div.get('typeOfForm')?.errors?.required">
                                  Required
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- Extension of time granted -->
                          <div class="col-md-3">
                            <div class="form-group">
                              <div class="form-group-Extension">
                                <label for="exampleInputPassword1">Extension of Time Granted</label>
                                <select class="form-control form-select" id="authority"
                                  formControlName="isExtensionGranted">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of extensionOfTimeGranted ">
                                    <option [value]="obj.value">{{obj.label}}</option>
                                  </ng-container>
                                </select>
                                <div *ngIf="isSubmitted && div.get('isExtensionGranted')?.errors">
                                  <span class="text-danger dateError"
                                    *ngIf="div.get('isExtensionGranted')?.errors?.required">
                                    Required
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Extension of time granted in days -->
                          <div class="col-md-3">
                            <div class="form-group">
                              <div class="form-group-Extension">
                                <label for="exampleInputPassword1">Extension of Time Granted in Days</label>
                                <input type="text" class="form-control" placeholder="" formControlName="extensionDays"
                                  maxlength="3"
                                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-md-3">
                            <div class="form-group">
                              <div class ="form-group-Extension">
                              <label for="exampleInputPassword1">Type of Form</label>
                              <input type="text" class="form-control" placeholder="" formControlName="typeOfForm">
                            </div>
                            </div>
                          </div> -->
                          <div class="col-md-3">
                            <div class="form-group">
                              <div class="form-group-Extension">
                                <label for="exampleInputPassword1">Period From <span class="mandate-icon"><i
                                      class="fa fa-asterisk"></i></span></label>
                                <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                  <p-calendar class="form-control" formControlName="periodFromDate" [maxDate]="maxDate"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                    dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('periodFromDate')?.errors  }">
                                  </p-calendar>
                                  <div *ngIf="isSubmitted && div.get('periodFromDate')?.errors">
                                    <span class="text-danger dateError"
                                      *ngIf="div.get('periodFromDate')?.errors?.required">Required
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Period To
                                <span class="mandate"><i class="fa fa-asterisk"></i></span>
                              </label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="periodToDate"
                                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                                  [ngClass]="{ 'errorBorder': isSubmitted && div.get('periodToDate')?.errors }"
                                  (onSelect)="getperiodToValue(periodToPop,i)"></p-calendar>
                                <div *ngIf="isSubmitted && div.get('periodToDate')?.errors">
                                  <span class="text-danger dateError"
                                    *ngIf="div.get('periodToDate')?.errors?.required">Required
                                  </span>
                                </div>
                                <ng-template #periodToPop let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 15px; padding-top:9px" >
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12" >
                                    <div class="table-responsive">
                                     <p class="title-data">Following cases are already exist for same selected period, <span style="font-weight: bold;">Do you want to still proceed?</span></p>
                                      <table style="margin-left: 5px;">
                                        <thead>
                                          <tr class="bg-primary text-dblue">
                                            <th class="sl-no-cla">Sr No</th>
                                            <th class="clas">Case id</th>
                                            <th>Case label</th>
                                            <th class="clas">Financial Year</th>
                                            <th class="clas">Period From</th>
                                            <th class="clas">Period To </th>
                                            <th class="clas">Forum</th>
                                            <th class="ac-no-clas">Total Demand(₹)</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            *ngFor="let obj of duplicateData  let i = index">
                                            <td class="sl-no-cla">{{i + 1}}</td>
                                            <td class="clas">{{obj.caseId}}</td>
                                            <td>{{obj.caseLabel}}</td>
                                            <td class="clas">{{obj.fy}}</td>
                                            <td class="clas">{{obj.periodFromDate}}</td>
                                            <td class="clas">{{obj.periodToDate}}</td>
                                            <td class="clas">{{obj.forum}}</td>
                                            <td class="ac-no-clas" style="text-align: right;">{{obj.demandAmount | INR: 0}} </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                
                                  <div class="modal-footer" style="border: none; padding-top: 5px;" >
                                    <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                                    (click)="modal.close('No click')">Yes</button>
                                      <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                                      (click)="goToSummary()">No</button>
                                  </div>
                                </ng-template>
                              </div>

                            </div>

                          </div>
                          <!-- new -->
                          <div class="col-md-3">
                            <div class="form-group">
                              <div class="form-group-Extension">
                                <label for="exampleInputPassword1">Internal due date </label>
                                <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                  <p-calendar class="form-control" formControlName="internalDueDate"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                    dateFormat="dd-mm-yy" [showIcon]="true" dataType="string">
                                  </p-calendar>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <div class="form-group-Extension">
                                <label for="exampleInputPassword1">Legal due date <span class="mandate-icon"><i
                                      class="fa fa-asterisk"></i></span></label>
                                <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                  <p-calendar class="form-control" formControlName="legalDueDate"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                    dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                                    [ngClass]="{ 'errorBorder': isSubmitted &&  div.get('legalDueDate')?.errors  }">
                                  </p-calendar>
                                  <div *ngIf="isSubmitted && div.get('legalDueDate')?.errors">
                                    <span class="text-danger dateError"
                                      *ngIf="div.get('legalDueDate')?.errors?.required">
                                      Required
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <div class="form-group-Extension">
                                <label for="exampleInputPassword1">Does the correspondence involves amounts? <span
                                    class="mandate-icon"><i class="fa fa-asterisk"></i></span></label>
                                <select class="form-control form-select" id="proceededToScn"
                                  formControlName="correspondenceAmtInvolved"  (change)="chCorresInv($event, i)"
                                  [ngClass]="{'errorBorder': isSubmitted &&  div.get('correspondenceAmtInvolved')?.errors  }">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of corresInvAmt">
                                    <option [value]="obj.value">{{obj.label}}</option>
                                  </ng-container>
                                </select>
                                <div *ngIf="isSubmitted && div.get('isExtensionGranted')?.errors">
                                  <span class="text-danger" *ngIf="div.get('isExtensionGranted')?.errors?.required">
                                    Required
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- Summary of the correspondence -->
                          <div class="col-md-12">
                            <div class="form-group">
                              <div class="form-group-Extension">
                                <label for="exampleInputPassword1">Summary of the correspondence </label>
                                <textarea type="text" class="form-control" placeholder="" rows="4"
                                  formControlName="summaryOfCorrespondence"></textarea>
                              </div>
                            </div>
                          </div>

                          <div class="row mlr0" [hidden]="!showifcorresInvAmt(i)">
                            <div class="col-md-12 nopadding">
                              <div class="form-group">
                                <label for="exampleInputPassword1">Issue Wise Details
                                  <a class="addExtLetter" (click)="addIssueInv(i)"><i class="fa fa-plus"
                                      title="Add row"></i></a>
                                </label>
                                <div class="search-table-outter wrapper">
                                  <table class="search-table inner issue-table">
                                    <thead>
                                      <tr class="bgGred ">
                                        <th  rowspan="3" class="sl-no-cla" *ngIf="isUpdateBtn">Info</th>
                                        <th rowspan="2">Issue <span class="mandate top7"><i
                                              class="fa fa-asterisk"></i></span></th>
                                        <th rowspan="2">Keyword <a class="clrOrange infoIcon"
                                            title="Maximum Length Limit is 150 Characters."><i
                                              class="fa fa-info-circle"></i></a></th>
                                        <th rowspan="2" class="txtCenter">Risk Parameter <span class="mandate top7"><i
                                              class="fa fa-asterisk"></i></span></th>
                                        <th colspan="7" class="txtCenter">Amount Demanded (₹)</th>
                                        <th rowspan="2">Remark <a class="clrOrange infoIcon"
                                            title="Max 100 characters allowed."><i class="fa fa-info-circle"></i></a>
                                        </th>
                                        <th rowspan="2">Action</th>
                                      </tr>
                                      <tr class="bgGred">
                                        <th>IGST </th>
                                        <th>CGST </th>
                                        <th>SGST</th>
                                        <th>CESS</th>
                                        <th>Interest</th>
                                        <th>Penalty</th>
                                        <th>Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <ng-container>
                                        <tr *ngFor="let issueFromGrp of getIssues(div); let k=index"
                                          [formGroup]="issueFromGrp">
                                          <td  class="sl-no-cla" *ngIf="isUpdateBtn">
                                            <p (click)="getissueData(issuePop,i,k)" class="pr5"><span
                                              class="badge-clo" title="Cases with similar issue"><i class="fa fa-info-circle"></i></span>
                                            </p>
                                        </td>
                                          <td class="w250">
                                            <!-- <input type="text" class="form-control" id="issue" placeholder=""
                                          formControlName="issue"> -->
                                            <select class="form-control form-select" formControlName="issue"
                                              [title]="issueFromGrp.value.issue ? issueFromGrp.value.issue:''"
                                              [ngClass]="{ 'errorBorder': isSubmitted && issueFromGrp.get('issue')?.errors  }">
                                              <option [value]="null" disabled>-- Select --</option>
                                              <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                                <option *ngFor='let item of grp.items' [value]="item.name"
                                                  title="{{item.name}}">{{item.name}}</option>
                                              </optgroup>
                                            </select>
                                            <div *ngIf="isSubmitted && issueFromGrp.get('issue')?.errors">
                                              <span class="text-danger"
                                                *ngIf="issueFromGrp.get('issue')?.errors?.required "> Required
                                              </span>
                                            </div>
                                          </td>

                                          <td><input type="text" class="form-control" id="keyword" placeholder=""
                                              maxlength="150" formControlName="keyword"
                                              [title]="div.value.keyword ? div.value.keyword:''"></td>
                                          <td>
                                            <select class="form-control form-select" id="status"
                                              formControlName="riskParam"
                                              [ngClass]="{ 'errorBorder': isSubmitted && issueFromGrp.get('riskParam')?.errors  }">
                                              <option [value]="null">-- Select --</option>
                                              <ng-container *ngFor="let obj of riskParam">
                                                <option [value]="obj.value">{{obj.value}}</option>
                                              </ng-container>
                                            </select>
                                            <div *ngIf="isSubmitted && issueFromGrp.get('riskParam')?.errors">
                                              <span class="text-danger"
                                                *ngIf="issueFromGrp.get('riskParam')?.errors?.required "> Required
                                              </span>
                                            </div>
                                          </td>

                                          <td><input type="text" class="form-control align-right" id="igst1"
                                              placeholder="" formControlName="igst1" maxlength="19"
                                              (input)="issueChangeEvent()" InrFormat (paste)="handlePaste($event)"></td>
                                          <td><input type="text" class="form-control align-right" id="cgst1"
                                              placeholder="" formControlName="cgst1" maxlength="19"
                                              (input)="issueChangeEvent()" InrFormat (paste)="handlePaste($event)"></td>
                                          <td><input type="text" class="form-control align-right" id="sgst1"
                                              placeholder="" formControlName="sgst1" maxlength="19"
                                              (input)="issueChangeEvent()" InrFormat (paste)="handlePaste($event)"></td>
                                          <td><input type="text" class="form-control align-right" id="cess1"
                                              placeholder="" formControlName="cess1" maxlength="19"
                                              (input)="issueChangeEvent()" InrFormat (paste)="handlePaste($event)"></td>
                                          <td><input type="text" class="form-control align-right" id="interest1"
                                              placeholder="" formControlName="interest1" maxlength="19"
                                              (input)="issueChangeEvent()" InrFormat (paste)="handlePaste($event)"></td>
                                          <td><input type="text" class="form-control align-right" id="penalty1"
                                              placeholder="" formControlName="penalty1" maxlength="19"
                                              (input)="issueChangeEvent()" InrFormat (paste)="handlePaste($event)"></td>
                                          <td><input type="text" class="form-control align-right" id="total1" readonly
                                              placeholder="" formControlName="total1" maxlength="19"
                                              (input)="issueChangeEvent()"></td>
                                          <td><input type="text" class="form-control" id="remark1" placeholder=""
                                              formControlName="remark1"
                                              [title]="issueFromGrp.value.remark1 ? issueFromGrp.value.remark1:''">
                                            <div
                                              *ngIf="issueFromGrp.value.remark1 && issueFromGrp.value.remark1.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                          <td><a class="addExtLetter"
                                              (click)="openRmvIssuePop(removeIssuePopup, i, k)"><i
                                                class="fa fa-minus"></i></a></td>
                                          <!-- (click)="rmIssueInv(i, k)" -->
                                          <ng-template #removeIssuePopup let-modal>
                                            <div class="modal-header">
                                              <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                              <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">×</span>
                                              </button>
                                            </div>
                                            <div class="modal-body">
                                              <p>Are you sure, you want to remove?</p>
                                            </div>
                                            <div class="modal-footer">
                                              <button type="button" class="btn btn-outline-dark"
                                                (click)="rmIssueInv(i, k)">Remove</button>
                                            </div>
                                          </ng-template>
                                          <ng-template #issuePop let-modal>
                                            <div class="modal-header">
                                              <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                                              </button>
                                            </div>
                                            <div class="col-md-12" >
                                              <div class="table-responsive">
                                                <p class="title-data">For your reference following latest 15 cases are exist in LMS with selected issue: <span style="font-weight: bold;">{{issueFromGrp.value.issue}}</span></p>
                                                <div class="btn btn-outline-success btn-download btnRep" (click)="exportAsXLSX()" >
                                                  <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
                                                </div>
                                                <table *ngIf="this.hideData">
                                                  <thead >
                                                    <tr class="bgGred1">
                                                      <th class="sl-no-cla">Sr No</th>
                                                      <th><span>PAN</span>&nbsp;
                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                            aria-hidden="true" *ngIf="!sorting.panName"
                                                            (click)="SortingIssue(issueSorting.PAN)"></i>
                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                            aria-hidden="true" *ngIf="sorting.panName"
                                                            (click)="SortingIssue(issueSorting.PAN)"></i>
                                                      </th>
                                                      <th><span>GSTIN</span>&nbsp;
                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                            aria-hidden="true" *ngIf="!sorting.gstin"
                                                            (click)="SortingIssue(issueSorting.GSTIN)"></i>
                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                            aria-hidden="true" *ngIf="sorting.gstin"
                                                            (click)="SortingIssue(issueSorting.GSTIN)"></i>
                                                    </th>
                                                      <th><span>Division</span>
                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                            aria-hidden="true" *ngIf="!sorting.gstinDiv"
                                                            (click)="SortingIssue(issueSorting.DIVISION)"></i>
                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                            aria-hidden="true" *ngIf="sorting.gstinDiv"
                                                            (click)="SortingIssue(issueSorting.DIVISION)"></i>
                                                    </th>
                                                      <th><span>Case ID</span>&nbsp;
                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                            aria-hidden="true" *ngIf="!sorting.caseId"
                                                            (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                            aria-hidden="true" *ngIf="sorting.caseId"
                                                            (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                                    </th>
                                                      <th><span>Case Label</span>&nbsp;
                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                            aria-hidden="true" *ngIf="!sorting.caseLabel"
                                                            (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                            aria-hidden="true" *ngIf="sorting.caseLabel"
                                                            (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                                      </th>
                                                      <th ><span>Period From</span>&nbsp;
                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                            aria-hidden="true"
                                                            *ngIf="!sorting.periodFromDate"
                                                            (click)="SortingIssue(issueSorting.PERIOD_FROM)"></i>
                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                            aria-hidden="true"
                                                            *ngIf="sorting.periodFromDate"
                                                            (click)="SortingIssue(issueSorting.PERIOD_FROM)"></i>
                                                    </th>
                                                    <th ><span>Period To</span>&nbsp;
                                                      <i class="fa fa-arrow-up cursor-pointer"
                                                          aria-hidden="true"
                                                          *ngIf="!sorting.periodToDate"
                                                          (click)="SortingIssue(issueSorting.PERIOD_TO)"></i>
                                                      <i class="fa fa-arrow-down cursor-pointer"
                                                          aria-hidden="true"
                                                          *ngIf="sorting.periodToDate"
                                                          (click)="SortingIssue(issueSorting.PERIOD_TO)"></i>
                                                  </th>
                                                      <th><span>Person Responsible</span>&nbsp;
                                                        <i class="fa fa-arrow-up cursor-pointer"
                                                            aria-hidden="true"
                                                            *ngIf="!sorting.personResponsible"
                                                            (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                                        <i class="fa fa-arrow-down cursor-pointer"
                                                            aria-hidden="true"
                                                            *ngIf="sorting.personResponsible"
                                                            (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                                    </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr
                                                      *ngFor="let obj of issueData  let i = index">
                                                      <td class="sl-no-cla">{{i + 1}}</td>
                                                      <td >{{obj.panName}}</td>
                                                      <td >{{obj.gstin}} 
                                                       <div style="color: rgb(126 132 131);">
                                                        {{obj.gstinName}} </div></td>
                                                      <td>{{obj.gstinDiv}}</td>
                                                      <td >{{obj.caseId}}</td>
                                                      <td>{{obj.caseLabel}}</td>
                                                      <td >{{obj.periodFromDate}}</td>
                                                      <td >{{obj.periodToDate}}</td>
                                                      <td>{{obj.personResponsible}}</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                                <div *ngIf="!this.hideData" style="font-size: large;text-align: center;font-weight: bold;">
                                                  No data found.
                                                </div>
                                                
                                              </div>
                                            </div>
                                          </ng-template>
                                        </tr>
                                      </ng-container>
                                      <ng-container>
                                        <tr>
                                          <th colspan="4" *ngIf="isUpdateBtn">Total</th>
                                          <th colspan="3"  *ngIf="!isUpdateBtn">Total</th>
                                          <td>
                                            <input type="text" class="form-control align-right bold-font" maxlength="19"
                                              InrFormat id="" placeholder="" formControlName="igst1Total" readonly>
                                          </td>
                                          <td><input type="text" class="form-control align-right bold-font"
                                              maxlength="19" InrFormat id="" placeholder="" formControlName="cgst1Total"
                                              readonly></td>
                                          <td><input type="text" class="form-control align-right bold-font"
                                              maxlength="19" InrFormat id="sgstTotal" placeholder=""
                                              formControlName="sgst1Total" readonly></td>
                                          <td><input type="text" class="form-control align-right bold-font"
                                              maxlength="19" InrFormat id="cessTotal" placeholder=""
                                              formControlName="cess1Total" readonly></td>
                                          <td><input type="text" class="form-control align-right bold-font"
                                              maxlength="19" InrFormat id="interestTotal" placeholder=""
                                              formControlName="interest1Total" readonly></td>
                                          <td><input type="text" class="form-control align-right bold-font"
                                              maxlength="19" InrFormat id="penaltyTotal" placeholder=""
                                              formControlName="penalty1Total" readonly></td>
                                          <td><input type="text" class="form-control align-right bold-font"
                                              maxlength="19" InrFormat id="penaltyTotal" placeholder=""
                                              formControlName="totOfTotal1" readonly></td>
                                          <td>&nbsp;</td>
                                        </tr>
                                      </ng-container>
                                    </tbody>

                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>


                          <!-- new -->
                        </div>
                        <!-- new -->
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Copy of Letter<span class="mandate"><i
                                    class="fa fa-asterisk"></i></span>
                              </label>
                              <input type="file" class="file-upload-default" #file>
                              <div class="attachment-block"
                                [ngClass]="{'errorBorder': isSubmitted &&  getLetterLocLength(i) === 0 }">
                                <span (click)="openLetterLocPop(letterCopyFormPop, i)">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getLetterLocLength(i) != 0">
                                  {{getLetterLocLength(i)}} Attachment</span>
                              </div>
                              <div *ngIf="isSubmitted &&  getLetterLocLength(i) === 0">
                                <span class="text-danger">Required</span>
                              </div>
                              <div>
                                <ng-template #letterCopyFormPop let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let letterFromGrp of getletterLoc(div); let j = index"
                                            [formGroup]="letterFromGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="letterFromGrp.value.isdocLocUploadedClicked"
                                                      type="file" id="itemList({{i}}).letterLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)" style="border: 1px solid #0000;">
                                                    <p *ngIf="!letterFromGrp.value.isdocLocUploadedClicked || letterFromGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(letterFromGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="letterFromGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(letterFromGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(letterFromGrp.value.fileLoc || letterFromGrp.value.fileLoc)&& isDisableIcons"
                                                      title="Upload file" (click)="uploadLetterLocCopy(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deleteLetterLoc(i,j)"
                                                      *ngIf="(letterFromGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control remark" formControlName="remarks"
                                                [title]="letterFromGrp.value.remarks ? letterFromGrp.value.remarks:''">
                                              <div
                                                *ngIf="letterFromGrp.value.remarks && letterFromGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>

                          </div>
                          <div *ngIf="isSubmitted && getletterLoc(div)?.status == 'INVALID'">
                            <span class="text-danger"> Required
                            </span>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Other Attachments </label>
                              <input type="file" class="file-upload-default" #file>
                              <div class="attachment-block">
                                <span (click)="openOtherAttachPop(othAttachmentFormPop, i)">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getotherAttachmentsLoc1Length(i) != 0">
                                  {{getotherAttachmentsLoc1Length(i)}} Attachment</span>
                              </div>
                              <div>
                                <ng-template #othAttachmentFormPop let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            *ngFor="let othAttachFromGrp of getotherAttachmentsLoc1(div); let j = index"
                                            [formGroup]="othAttachFromGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="othAttachFromGrp.value.isdocLocUploadedClicked"
                                                      type="file"
                                                      id="itemList({{i}}).otherAttachmentsLoc1({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)" style="border: 1px solid #0000;">
                                                    <p *ngIf="!othAttachFromGrp.value.isdocLocUploadedClicked || othAttachFromGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(othAttachFromGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="othAttachFromGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(othAttachFromGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(othAttachFromGrp.value.fileLoc || othAttachFromGrp.value.fileLoc)&& isDisableIcons"
                                                      title="Upload file" (click)="uploadOthAttachLocCopy(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deleteOtherAttachmentsLoc(i,j)"
                                                      *ngIf="(othAttachFromGrp.value.fileLoc)&& isDisableIcons "><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control remark" formControlName="remarks"
                                                [title]="othAttachFromGrp.value.remarks ? othAttachFromGrp.value.remarks:''">
                                              <div
                                                *ngIf="othAttachFromGrp.value.remarks && othAttachFromGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>

                          </div>
                        </div>

                        <!-- Details of Issuing Authority   -->
                        <div data-toggle="collapse" data-target="#demo" class="expansion-collapse"
                          (click)="authorityPanleExpansion()">Details of Issuing Authority <span
                            class="consultant-icons"> <i *ngIf="!authorityExpansion" class="fa fa-angle-down"
                              aria-hidden="true"></i> <i *ngIf="authorityExpansion" class="fa fa-angle-up"
                              aria-hidden="true"></i></span></div>
                        <div id="demo" class="collapse">
                          <div class="col-md-12">
                            <div class=" row">
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Name of the Officer</label>
                                  <input type="text" class="form-control" id="" formControlName="officer">
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Designation</label>
                                  <select class="form-control form-select" id="authority" formControlName="designation">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of typeOfDesignation ">
                                      <option [value]="obj.value">{{obj.key}}</option>
                                    </ng-container>
                                  </select>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label> Jurisdiction</label>
                                  <input type="text" class="form-control" id="" formControlName="jurisdiction">
                                </div>
                              </div>
                            </div>
                            <div class=" row">

                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Address</label>
                                  <input type="text" class="form-control" id="" formControlName="address">
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>Contact No.</label>
                                  <input type="text" class="form-control" id="" formControlName="contact" maxlength="12"
                                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                </div>
                              </div>
                              <div class="col-md-4">
                                <div class="form-group">
                                  <label>E-mail ID</label>
                                  <input type="email" class="form-control" id="" formControlName="email"
                                    maxlength="100">
                                  <div *ngIf="div.get('email')?.errors">
                                    <span class="text-danger" *ngIf="div.get('email')?.errors?.pattern"> Please Enter
                                      Valid Email ID
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 nopadding">
                          <!-- <button class="btn btn-outline-primary mr-1" (click)="saveLetter()">Save</button> -->
                          <button class="btn btn-outline-primary ml-1" (click)="openRmvLetterPop(removeLetterPopup, i)"
                            *ngIf="isCaseData">Remove Letter</button>
                          <button class="btn btn-outline-primary ml-1" (click)="createLetter()" *ngIf="isCaseData">Add
                            Another Letter</button>
                        </div>
                      </div>

                    </div>

                  </div>
                  <ng-template #removeLetterPopup let-modal>
                    <div class="modal-header">
                      <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <p>Are you sure, you want to remove?</p>
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-outline-dark" (click)="removeLetter(i)">Remove</button>
                    </div>
                  </ng-template>

                </div>

              </div>
              <!-- Case Summary -->
              <div class="col-md-12 mt15 nopadding">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Case Summary</label>
                    <textarea class="form-control" id="exampleTextarea1" rows="4"
                      formControlName="orderSummary"></textarea>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt15 nopadding">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Internal Remarks </label>
                    <textarea type="text" class="form-control" id="CPResponsible" placeholder="" rows="4"
                      formControlName="internalRemarks"></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 mt15 nopadding" *ngIf="caseId">
              <div class="form-group">
                <label>Whether writ application preferred to High Court</label>
                <select class="form-control form-select" id="authority" formControlName="whetherWritAppealPrefHc">
                  <option [value]="null">-- Select --</option>
                  <ng-container *ngFor="let obj of whetherWritApplication ">
                    <option [value]="obj.value">{{obj.label}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-md-12 mt15 nopadding">
              <button class="btn btn-outline-primary mr-1" (click)="saveLetter()" *ngIf="isCreateBtn">Save &
                Next</button>
              <button class="btn btn-outline-primary mr-1" *ngIf="isCaseCreated || isUpdate"
                (click)="updateLetter('p1')">Update</button>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show active" id="noticep2-1" role="tabpanel" aria-labelledby="noticep2-tab">
      <div class="card borderOrange" [hidden]="selectedTab === 'tab1'"
        [ngClass]="{'bgGray': (action == 'view' )}">
        <div class="card-body">
          <fieldset [disabled]="action == 'view'">
            <div class="row mb-2">
              <div class="col-md-12">

                <div class="card border-bottom mt-4" *ngFor="let div of getletters(); let i=index" [formGroup]="div">
                  <div class="card-header" role="tab" id="letter-1">
                    <h6 class="mb-0">
                      <a data-toggle="collapse" href="#letter-1" aria-expanded="true" aria-controls="letter-1">
                        Letter {{i+1}} </a>
                    </h6>
                  </div>
                  <div id="letter-1" class="collapse show" role="tabpanel" aria-labelledby="letter-1"
                    data-parent="#accordion"
                    [ngClass]="{'bgGray': action === 'view' }">
                    <div class="card-body">
                      <div class="col-md-12 nopadding">
                        <button class="btn btn-outline-primary mb15 " (click)="addSubmissionDiv(i)">Add
                          Submission</button>
                      </div>
                      <div>
                        <!-- <p class="ml-22 card-title"><a class="addExtLetter" (click)="addSubmissionDiv(i)">Add Submission <i class="fa fa-plus"></i></a></p> -->
                        <div *ngFor="let submissionFromGrp of getSubmissions(div); let k=index"
                          [formGroup]="submissionFromGrp" class="row subDiv">
                          <div class="col-md-12 mb-1">
                            <a class="card-title" (click)="addSubmissionDiv(i)">Submission {{k+1}} <i class="fa fa-plus"
                                title="Add"></i></a>
                            <a class="addExtLetter pull-right" (click)="openRmSubPop(removeSubPopup, i, k)"><i
                                class="fa fa-minus"></i></a>
                          </div>

                          <div class="row"> 
                           <div class="col-md-3" *ngIf="hideOcrFiledp2 &&  (k === getSubmissions(div).length - 1)">
                            <div class="form-group">
                              <!-- hidlabelsub  -->
                               <span class="row"> <button class="btn-beta"> Beta</button></span>
                              <label for="exampleInputPassword1" style="font-weight: 600;">Upload PDF Reply for AI Smart fill </label>
                              <input type="file" class="file-upload-default" #file>
                              <div class="attachment-block">
                                <span (click)="openOCRopyLoc(OCRFormPop, i)">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span> <span *ngIf="getCopyOfAckLocLength(i, k) != 0">
                                  {{getCopyOfAckLocLength(i, k)}} Attachment
                                </span>
                              </div>
                              <div *ngIf="isSubmitted &&  getCopyOfAckLocLength(i, k) === 0">
                                <span class="text-danger">Required</span>
                              </div>
                              <div>
                                <ng-template #OCRFormPop let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment   <span style="font-weight: 500;color: #025c48;">
                                      <br>Note: First upload system generated Reply followed by annexure
                                     </span>
                                    </label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let ackFromGrp of getAckCOpyLoc(submissionFromGrp); let j = index"
                                            [formGroup]="ackFromGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="ackFromGrp.value.isdocLocUploadedClicked" type="file"
                                                      id="itemList({{i}}).submissions({{k}}).copyOfAckLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelectSmart($event,i)" style="border: 1px solid #0000;">
                                                    <p *ngIf="!ackFromGrp.value.isdocLocUploadedClicked || ackFromGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(ackFromGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label>
                                                    <a class="downupIcon" *ngIf="ackFromGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(ackFromGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(ackFromGrp.value.fileLoc || ackFromGrp.value.fileLoc)&& isDisableIcons"
                                                      title="Upload file" (click)="uploadAckCopy(i,j,k)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deleteAckCopyFileAi(i,j,k)"
                                                      *ngIf="(ackFromGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3" style="margin-top: 16px;" *ngIf="hiddenbtn && (k === getSubmissions(div).length - 1)">                           
                             <button class="btn1" (click)="onSmartUpload('p2',i,k)">AI Smart Fill</button> <i class="fa fa-hand-o-left smartinfo"></i>
                             <span class="blink2">Please click here</span>                                 
                           </div>

                           <div class="col-md-6" *ngIf="!processed  && (k === getSubmissions(div).length - 1)">
                            <div style="text-align: left; font-size:14px;margin-top:28px;" >
                              <i class="fa fa-spinner fa-spin refreshIcon"  *ngIf="hideRefreshIcon">
                              </i> <span  class="errmsg">{{statusData}}</span>
                            </div> 
                          </div>
                         </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Submission Ref. No. <span class="mandate"><i
                                    class="fa fa-asterisk"></i></span></label>
                              <input type="text" class="form-control" id="" placeholder="" formControlName="refNo"
                                [title]="submissionFromGrp.value.refNo ? submissionFromGrp.value.refNo:''"
                                [ngClass]="{ 'errorBorder': isSubmitted && submissionFromGrp.get('refNo')?.errors  }">
                              <div *ngIf="isSubmitted &&  submissionFromGrp.get('refNo')?.errors">
                                <span class="text-danger" *ngIf="submissionFromGrp.get('refNo')?.errors?.required">
                                  Required
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- Reply Form Type -->
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Reply Form Type<span class="mandate"><i
                                    class="fa fa-asterisk"></i></span></label>
                              <select class="form-control form-select" id="replyFormType"
                                formControlName="replyFormType"
                                [ngClass]="{ 'errorBorder': isSubmitted && submissionFromGrp.get('replyFormType')?.errors }">
                                <option [value]="">-- Select --</option>
                                <ng-container *ngFor="let obj of replyFormType ">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                              <div *ngIf="isSubmitted &&  submissionFromGrp.get('replyFormType')?.errors">
                                <span class="text-danger"
                                  *ngIf="submissionFromGrp.get('replyFormType')?.errors?.required"> Required
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Date of submission
                                <span class="mandate"><i class="fa fa-asterisk"></i></span>
                              </label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="submissionDate"
                                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                  [ngClass]="{ 'errorBorder': isSubmitted && submissionFromGrp.get('submissionDate')?.errors  }">
                                </p-calendar>
                                <div *ngIf="isSubmitted &&  submissionFromGrp.get('submissionDate')?.errors">
                                  <span class="text-danger dateError"
                                    *ngIf="submissionFromGrp.get('submissionDate')?.errors?.required"> Required
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Submission Mode </label>
                              <select class="form-control form-select" id="submissionMode"
                                formControlName="submissionMode">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfAppealOptions">
                                  <option [value]="obj.value">{{obj.value}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                 

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Copy of Acknowledgement<span class="mandate"><i
                                    class="fa fa-asterisk"></i></span>
                              </label>
                              <input type="file" class="file-upload-default" #file>

                              <div class="attachment-block"
                                [ngClass]="{'errorBorder': isSubmitted && getCopyOfAckLocLength(i, k) === 0 }">
                                <span (click)="openAckCopyLoc(ackCopyFormPop, i)">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span> <span *ngIf="getCopyOfAckLocLength(i, k) != 0">
                                  {{getCopyOfAckLocLength(i, k)}} Attachment</span>
                              </div>
                              <div *ngIf="isSubmitted &&  getCopyOfAckLocLength(i, k) === 0">
                                <span class="text-danger">Required</span>
                              </div>
                              <div>
                                <ng-template #ackCopyFormPop let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let ackFromGrp of getAckCOpyLoc(submissionFromGrp); let j = index"
                                            [formGroup]="ackFromGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="ackFromGrp.value.isdocLocUploadedClicked" type="file"
                                                      id="itemList({{i}}).submissions({{k}}).copyOfAckLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)" style="border: 1px solid #0000;">
                                                    <p *ngIf="!ackFromGrp.value.isdocLocUploadedClicked || ackFromGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(ackFromGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label>
                                                    <a class="downupIcon" *ngIf="ackFromGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(ackFromGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(ackFromGrp.value.fileLoc || ackFromGrp.value.fileLoc)&& isDisableIcons"
                                                      title="Upload file" (click)="uploadAckCopy(i,j,k)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deleteAckCopyFile(i,j,k)"
                                                      *ngIf="(ackFromGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control remark" formControlName="remarks"
                                                [title]="ackFromGrp.value.remarks ? ackFromGrp.value.remarks:''">
                                              <div
                                                *ngIf="ackFromGrp.value.remarks && ackFromGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Other Attachments
                              </label>
                              <input type="file" class="file-upload-default" #file>
                              <div class="attachment-block">
                                <span (click)="openSubOthCopyLoc(subothAttachFormPop, i)">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span> <span *ngIf="getOthCopyLocLength(i,k) != 0">
                                  {{getOthCopyLocLength(i,k)}} Attachment</span>
                              </div>
                              <div>
                                <ng-template #subothAttachFormPop let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            *ngFor="let subOthFromGrp of getSubOthAttachLoc(submissionFromGrp); let j = index"
                                            [formGroup]="subOthFromGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="subOthFromGrp.value.isdocLocUploadedClicked"
                                                      type="file"
                                                      id="itemList({{i}}).submissions({{k}}).otherAttachmentsLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)" style="border: 1px solid #0000;">
                                                    <p *ngIf="!subOthFromGrp.value.isdocLocUploadedClicked || subOthFromGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(subOthFromGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="subOthFromGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(subOthFromGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(subOthFromGrp.value.fileLoc || subOthFromGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadSubOtherAttach(i,j,k)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deleteOtherAttachmentFile(i,j,k)"
                                                      *ngIf="(subOthFromGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control remark" formControlName="remarks"
                                                [title]="subOthFromGrp.value.remarks ? subOthFromGrp.value.remarks:''">
                                              <div
                                                *ngIf="subOthFromGrp.value.remarks && subOthFromGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>

                          </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Internal Remarks </label>
                              <textarea type="text" class="form-control" id="remarks" placeholder=""
                                formControlName="internalRemarks" rows="4"></textarea>
                            </div>
                          </div>

                          <ng-template #removeSubPopup let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p>Are you sure, you want to remove?</p>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="rmSubmissionDiv(i, k)">Remove</button>
                            </div>
                          </ng-template>

                        </div>
                      </div>
                      <div class="row mt15" [hidden]="!showifcorresInvAmt(i)">
                        <div class="col-md-12 nopadding">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Issue Wise Details
                              <!-- <a class="addExtLetter" (click)="addIssueInv()"><i class="fa fa-plus"></i></a> -->
                            </label>
                            <div class="search-table-outter wrapper">
                              <table class="search-table inner">
                                <thead>
                                  <tr class="bgGred">
                                    <th>Issues</th>
                                    <th class="w10">Key word</th>
                                    <th style="min-width: 167px;">Conclusion <span class="mandate top7"><i
                                          class="fa fa-asterisk"></i></span></th>
                                    <th class="w10">IGST (₹)</th>
                                    <th class="w10">CGST (₹)</th>
                                    <th class="w10">SGST (₹)</th>
                                    <th class="w10">CESS (₹)</th>
                                    <th class="w10">Interest (₹)</th>
                                    <th class="w10">Penalty (₹)</th>
                                    <th class="w10">Total (₹)</th>
                                    <!-- <th>Remove </th> -->
                                  </tr>
                                </thead>
                                <tbody>
                                  <!--  <ng-container formArrayName="issues">
                                  <tr *ngFor="let div of issuInvArr(div).controls; let j=index" [formGroupName]="j"> -->
                                  <ng-container>
                                    <tr *ngFor="let issueFromGrp of getIssues(div); let k=index"
                                      [formGroup]="issueFromGrp">
                                      <td class="w250">
                                        <!-- <input type="text" class="form-control" id="tax" placeholder=""
                                          formControlName="issue"> -->
                                        <select class="form-control form-select" formControlName="issue"
                                          [title]="div.value.issue ? div.value.issue:''"
                                          [ngClass]="{ 'errorBorder': isSubmitted && issueFromGrp.get('issue')?.errors  }">
                                          <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                            <option *ngFor='let item of grp.items' [value]="item.name"
                                              title="{{item.name}}">{{item.name}}</option>
                                          </optgroup>
                                        </select>
                                        <div *ngIf="isSubmitted && issueFromGrp.get('issue')?.errors">
                                          <span class="text-danger"
                                            *ngIf="issueFromGrp.get('issue')?.errors?.required "> Required
                                          </span>
                                        </div>
                                      </td>
                                      <td><input type="text" class="form-control" id="tax" placeholder=""
                                          formControlName="keyword" maxlength="150"
                                          [title]="div.value.keyword ? div.value.keyword:''"></td>
                                      <td>
                                        <select class="form-control form-select" id="status" formControlName="status"
                                          [ngClass]="{ 'errorBorder': isSubmitted && issueFromGrp.get('status')?.errors  }">
                                          <!-- <option [value]="null">-- Select --</option> -->
                                          <ng-container *ngFor="let obj of issueConclusion">
                                            <option [value]="obj.value">{{obj.value}}</option>
                                            <!-- <option value="Pending">Pending</option> -->
                                          </ng-container>
                                        </select>
                                        <div *ngIf="isSubmitted && issueFromGrp.get('status')?.errors">
                                          <span class="text-danger"
                                            *ngIf="issueFromGrp.get('status')?.errors?.required "> Required
                                          </span>
                                        </div>
                                      </td>
                                      <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                          (input)="issueChangeEvent()" formControlName="igst3" maxlength="19" InrFormat
                                          (paste)="handlePaste($event)"></td>
                                      <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                          (input)="issueChangeEvent()" formControlName="cgst3" maxlength="19" InrFormat
                                          (paste)="handlePaste($event)"></td>
                                      <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                          (input)="issueChangeEvent()" formControlName="sgst3" maxlength="19" InrFormat
                                          (paste)="handlePaste($event)"></td>
                                      <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                          (input)="issueChangeEvent()" formControlName="cess3" maxlength="19" InrFormat
                                          (paste)="handlePaste($event)"></td>
                                      <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                          (input)="issueChangeEvent()" formControlName="interest3" maxlength="19"
                                          InrFormat (paste)="handlePaste($event)"></td>
                                      <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                          (input)="issueChangeEvent()" formControlName="penalty3" maxlength="19"
                                          InrFormat (paste)="handlePaste($event)"></td>
                                      <td><input type="text" class="form-control align-right" id="tax" readonly
                                          placeholder="" (input)="issueChangeEvent()" formControlName="total3"
                                          maxlength="19" InrFormat></td>

                                      <!-- <td><a class="addExtLetter" (click)="rmIssueInv(j)" *ngIf="isIssueAdd"><i
                                          class="fa fa-minus"></i></a></td> -->
                                      <!-- <td><a class="addExtLetter" ><i
                                            class="fa fa-minus"></i></a></td> -->
                                    </tr>
                                  </ng-container>
                                  <ng-container>
                                    <tr>
                                      <th colspan="3">Total</th>
                                      <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                          InrFormat id="igstTotal" placeholder="" formControlName="igst3Total" readonly>
                                      </td>
                                      <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                          InrFormat id="cgstTotal" placeholder="" formControlName="cgst3Total" readonly>
                                      </td>
                                      <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                          InrFormat id="sgstTotal" placeholder="" formControlName="sgst3Total" readonly>
                                      </td>
                                      <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                          InrFormat id="cessTotal" placeholder="" formControlName="cess3Total" readonly>
                                      </td>
                                      <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                          InrFormat id="interestTotal" placeholder="" formControlName="interest3Total"
                                          readonly></td>
                                      <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                          InrFormat id="penaltyTotal" placeholder="" formControlName="penalty3Total"
                                          readonly></td>
                                      <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                          InrFormat id="penaltyTotal" placeholder="" formControlName="totOfTotal3"
                                          readonly></td>

                                    </tr>
                                  </ng-container>
                                </tbody>

                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Details of Meeting With Department -->
                      <div class="col-md-12 nopadding">
                        <div data-toggle="collapse" data-target="#authority" class="expansion-collapse"
                          (click)="authorityPanleExpansion()">Details of Meeting With Department <span
                            class="consultant-icons"> <i *ngIf="!authorityExpansion" class="fa fa-angle-down"
                              aria-hidden="true"></i> <i *ngIf="authorityExpansion" class="fa fa-angle-up"
                              aria-hidden="true"></i></span> </div>
                        <div id="authority" class="collapse">
                          <div class=" row">
                            <div class="col-md-12">
                              <div class="search-table-outter wrapper p-15">
                                <table class="search-table inner">
                                  <thead>
                                    <tr class="bgGred">
                                      <th class="w-40">Sr.No.</th>
                                      <th class="col-3">Date of Meeting</th>
                                      <th class="col-3">Persons Who Attended</th>
                                      <th class="col-3">Internal Remarks</th>
                                      <th class="col-3">Supporting Document</th>
                                      <th class="w-40"> Action </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <ng-container>
                                      <tr *ngFor="let departmentFromGrp of departmentInvArr(div); let k=index"
                                        [formGroup]="departmentFromGrp">
                                        <td class="w-40">{{k+1}}</td>
                                        <td>
                                          <div id="iris-gst-filter-frmDate"
                                            class="input-group date datepicker tbldatePicker">
                                            <p-calendar appendTo="body" class="form-control"
                                              formControlName="dateOfMeeting" name="dateOfmeeting"
                                              [monthNavigator]="true" [yearNavigator]="true"
                                              yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                              dataType="string" [maxDate]="maxDate">
                                            </p-calendar>
                                          </div>
                                        </td>
                                        <td><input type="text" class="form-control" id=" " placeholder=""
                                            formControlName="personAttended">
                                        </td>
                                        <td><input type="text" class="form-control" id="issueInternalRemarks"
                                            placeholder=""
                                            [title]="departmentFromGrp.value.internalRemarks ? departmentFromGrp.value.internalRemarks:''"
                                            formControlName="internalRemarks">
                                        </td>
                                        <td>
                                          <span (click)="openDetailPop(meetingDtlsPop, i)">
                                            <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                              title="Attach file"></i></span> <span
                                            *ngIf="getMeetingDetailsLength(i, k) != 0">
                                            {{getMeetingDetailsLength(i, k)}} Attachment</span>

                                          <!-- <span style="cursor: pointer;" (click)="openDetailPop(meetingDtlsPop, i)">
                                             <i class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span>
                                             <span style="cursor: pointer;" (click)="openDetailPop(meetingDtlsPop, i)"> Attachment</span> -->
                                        </td>
                                        <ng-template #meetingDtlsPop let-modal>
                                          <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="col-md-12">
                                            <label> Attachment</label>
                                            <div class="table-responsive">
                                              <table class="table table-bordered tablepop">
                                                <thead>
                                                  <tr class="bgGred">
                                                    <th>Upload</th>
                                                    <th style="width:50%;">Remark</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    *ngFor="let meetingDtlsFromGrp of getMeetingDtlsLoc(departmentFromGrp); let j = index"
                                                    [formGroup]="meetingDtlsFromGrp">
                                                    <td>
                                                      <div class="row">
                                                        <div class="col-md-9 nopadding">
                                                          <div class="form-group">
                                                            <input
                                                              *ngIf="meetingDtlsFromGrp.value.isdocLocUploadedClicked"
                                                              type="file"
                                                              id="itemList({{i}}).meetingDetails({{k}}).copyOfSubmissionLoc({{j}}).fileLoc"
                                                              class="filetype form-control" name="myfile"
                                                              (change)="onFileSelect($event)"
                                                              style="border: 1px solid #0000;">
                                                            <p *ngIf="!meetingDtlsFromGrp.value.isdocLocUploadedClicked || meetingDtlsFromGrp.value.fileLoc"
                                                              class="form-control pt10">
                                                              {{getFileNameDta(meetingDtlsFromGrp.value.fileLoc)}}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-3 nopadding">
                                                          <label for="exampleInputPassword1">
                                                            <a class="downupIcon"
                                                              *ngIf="meetingDtlsFromGrp.value.fileLoc"
                                                              title="Download file"
                                                              (click)="download(meetingDtlsFromGrp.value.fileLoc)"><i
                                                                class="fa fa-download"></i></a>
                                                            <a class="downupIcon"
                                                              *ngIf="!(meetingDtlsFromGrp.value.fileLoc || meetingDtlsFromGrp.value.fileLoc) && isDisableIcons"
                                                              title="Upload file" (click)="uploadMeetingCopy(i,j,k)"><i
                                                                class="fa fa-upload"></i></a>
                                                            <a class="downupIcon" (click)="deleteMeetingCopy(i,j,k)"
                                                              *ngIf="(meetingDtlsFromGrp.value.fileLoc) && isDisableIcons"><i
                                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <input type="text" class="form-control remark"
                                                        formControlName="remarks"
                                                        [title]="meetingDtlsFromGrp.value.remarks ? meetingDtlsFromGrp.value.remarks:''">
                                                      <div
                                                        *ngIf="meetingDtlsFromGrp.value.remarks && meetingDtlsFromGrp.value.remarks.length > 100">
                                                        <span class="text-danger">Max character Length 100</span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div class="modal-footer" style="border: none; padding-top: 0px;">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="modal.close('Save click')">Save</button>
                                          </div>
                                        </ng-template>
                                        <td class="w-40">
                                          <a class="addExtLetter" (click)="addDepartmentInv(i,$event)"><i
                                              class="fa fa-plus" title="Add row"></i> </a>
                                          <a *ngIf="k != 0" class="addExtLetter"
                                            (click)="openRmDepartmentPop(removeDepartmentPopup, i, k)"><i
                                              class="fa fa-minus"></i></a>
                                          <ng-template #removeDepartmentPopup let-modal>
                                            <div class="modal-header">
                                              <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                              <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">×</span>
                                              </button>
                                            </div>
                                            <div class="modal-body">
                                              <p>Are you sure, you want to remove?</p>
                                            </div>
                                            <div class="modal-footer">
                                              <button type="button" class="btn btn-outline-dark"
                                                (click)="rmDepartmentInv(i,k)">Remove</button>
                                            </div>
                                          </ng-template>
                                        </td>
                                      </tr>
                                    </ng-container>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

               <div class="row mb-12">
                <label>Details of Hearings </label>
              </div>
              <div formArrayName="hearingDetails">
                <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                  <li class="nav-item" id="adjPhTab{{i}}" *ngFor="let adjPHtab of phDivArr().controls; let i=index"
                    [formGroupName]="i">
                    <a class="nav-link" id="adjudph1-tab" data-toggle="tab" (click)="phaseChanged('phase' + i, i)"
                      [ngClass]="(selectedPhase === 'phase' + i) ? 'active': ''" role="tab" aria-controls="adjudph1-1"
                      aria-selected="true">PH {{i+1}} <span *ngIf="i > 0" (click)="removeAdjDiv(i)"
                        class="closeTab">x</span></a>
                  </li>
                </ul>
                <div class="tab-content nopadding">
                  <div class="tab-pane fade show active" id="adjudph1-1" role="tabpanel" aria-labelledby="adjudph1-tab">
                    <div class=" ml5" id="adjPhDiv{{i}}" *ngFor="let adjPHdiv of phDivArr().controls; let i=index"
                      [formGroupName]="i">
                      <div *ngIf="selectedPhase === 'phase' + i">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice Ref.No </label>
                              <input type="text" class="form-control" id="phRefNo" placeholder="" formControlName="phRefNo">
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice Date </label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="phNoticeDate"
                                  [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice
                              </label>
                              <input type="file" class="form-control file-upload-default" #file>
                              <div class="attachment-block">
                                <span (click)="openphCopyLoc(phCopyLoc, i)" style="margin-left: 5px;">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getPhCopyLocLength(i) != 0">
                                  {{getPhCopyLocLength(i)}} Attachment</span>
                              </div>
                              <div>
                                <ng-template #phCopyLoc let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let phCopyGrp of getphCopyLoc(adjPHdiv); let j = index"
                                            [formGroup]="phCopyGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="phCopyGrp.value.isdocLocUploadedClicked" type="file"
                                                      id="hearingDetails({{i}}).phCopyLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)">
                                                    <p *ngIf="!phCopyGrp.value.isdocLocUploadedClicked || phCopyGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(phCopyGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="phCopyGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(phCopyGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(phCopyGrp.value.fileLoc || phCopyGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadPhCopy(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deletePhCopy(i,j)"
                                                      *ngIf="(phCopyGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" formControlName="remarks"
                                                [title]="phCopyGrp.value.remarks ? phCopyGrp.value.remarks:''">
                                              <div
                                                *ngIf="phCopyGrp.value.remarks && phCopyGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Date of PH scheduled </label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="dateOfPhScheduled"
                                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                          </div>
                          <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Time of PH </label>
                              <select class="form-control form-select" id="phTime" formControlName="phTime">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfTime">
                                  <option [value]="obj.value">{{obj.key}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Mode of PH </label>
                              <select class="form-control form-select" id="gstin" formControlName="mode">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfPH">
                                  <option [value]="obj.value">{{obj.key}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Attended </label>
                              <select class="form-control form-select" id="gstin" formControlName="attended">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of adjAttend">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                         

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="">Adjourned/ New PH </label>
                              <select class="form-control form-select" id="gstin" formControlName="adjournedOrNot"
                                (change)="changeadjOrNot($event, i)">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of adjPHAdjuorNotList">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Adjournment Letter</label>
                            <input type="file" class="form-control file-upload-default" #file>
                            <div class="attachment-block">

                              <span (click)="openAdjournmentLetter(phAdjournmentLetterLocpop, i)" style="margin-left: 5px;">
                                <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span><span *ngIf="getphAdjournmentLetterLocLength(i) != 0">
                                {{getphAdjournmentLetterLocLength(i)}} Attachment</span>
                            </div>
                            <div>
                              <ng-template #phAdjournmentLetterLocpop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let phAdjournmentLetterGrp of getAdjournmentLetter(adjPHdiv); let j = index"
                                          [formGroup]="phAdjournmentLetterGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="phAdjournmentLetterGrp.value.isdocLocUploadedClicked" type="file"
                                                    id="hearingDetails({{i}}).phAdjournmentLetterLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!phAdjournmentLetterGrp.value.isdocLocUploadedClicked || phAdjournmentLetterGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(phAdjournmentLetterGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="phAdjournmentLetterGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(phAdjournmentLetterGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(phAdjournmentLetterGrp.value.fileLoc || phAdjournmentLetterGrp.value.fileLoc) && isDisableIcons"
                                                    title="Upload file" (click)="uploadAdjournmentLetter(i,j)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAdjournmentLetter(i,j)"
                                                    *ngIf="(phAdjournmentLetterGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="phAdjournmentLetterGrp.value.remarks ? phAdjournmentLetterGrp.value.remarks:''">
                                            <div
                                              *ngIf="phAdjournmentLetterGrp.value.remarks && phAdjournmentLetterGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Date of Additional Written Submissions</label>
                            <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                              <p-calendar class="form-control" formControlName="dateOfsubmission"
                                [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate"
                                yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                dataType="string">
                              </p-calendar>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Upload the additional submission</label>
                            <input type="file" class="form-control file-upload-default" #file>
                            <div class="attachment-block">
                              <span (click)="openAdditionSub(additionalSubLoc, i)" style="margin-left: 5px;">
                                <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span><span
                                *ngIf="getAdditionalSubmissionLocLength(i) != 0">
                                {{getAdditionalSubmissionLocLength(i)}} Attachment</span>
                              <!-- <span style="cursor: pointer;" (click)="openAdditionSub(additionalSubLoc,i)"> <i
                              class="icon fa fa fa-paperclip" style="cursor: pointer;"  
                              title="Attach file"></i></span><span style="cursor: pointer;" (click)="openAdditionSub(additionalSubLoc,i)">Attachment</span> -->
                            </div>
                            <div>
                              <ng-template #additionalSubLoc let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let additionaSubGrp of getAdditionalSub(adjPHdiv); let j = index"
                                          [formGroup]="additionaSubGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="additionaSubGrp.value.isdocLocUploadedClicked"
                                                    type="file"
                                                    id="hearingDetails({{i}}).additionalSubmissionLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!additionaSubGrp.value.isdocLocUploadedClicked || additionaSubGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(additionaSubGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="additionaSubGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(additionaSubGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(additionaSubGrp.value.fileLoc || additionaSubGrp.value.fileLoc) && isDisableIcons"
                                                    title="Upload file" (click)="uploadAdditionalSub(i,j)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAdditionalSub(i,j)"
                                                    *ngIf="(additionaSubGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="additionaSubGrp.value.remarks ? additionaSubGrp.value.remarks:''">
                                            <div
                                              *ngIf="additionaSubGrp.value.remarks && additionaSubGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                        <!-- <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Consultant</label>
                            <input type="text" class="form-control" id="caseId" formControlName="consultant"> 

                            <div *ngIf="isToggleConsultantDropdown2 && action==='update' || action==='view'" class="input-group">
                              <input type="text" class="form-control"
                                *ngIf="isToggleConsultantDropdown2 && action==='update' || action==='view'" placeholder=""
                                formControlName="consultant" readonly>
                              <button *ngIf="isToggleConsultantDropdown2 && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('p2')">
                                <i class="fa fa-pencil"></i>
                              </button> 
                            </div>
          
                            <select class="form-control form-select" id="caseId"
                              *ngIf="!isToggleConsultantDropdown2 || action==='create'" formControlName="consultant">
                              <option [value]="null">-- Select --</option>
                              <ng-container *ngFor="let obj of consultantList">
                                <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                              </ng-container>
                            </select>
                          </div>
                        </div> -->

                        <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create')">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Consultant</label>
                        
                            <!-- For Consultant Role: Create, View, and Update -->
                            <div *ngIf="roleName === 'Consultant' && action === 'create'">
                              <input 
                                type="text" 
                                class="form-control" 
                                formControlName="consultant" 
                                [readOnly]="action === 'create'">
                            </div>
                        
                            <!-- For Non-Consultant Role -->
                            <div *ngIf="roleName !== 'Consultant'">
                              <div *ngIf="isToggleConsultantDropdown2 && (action === 'update' || action === 'view')" class="input-group">
                                <input 
                                  type="text" 
                                  class="form-control" 
                                  placeholder="" 
                                  formControlName="consultant" 
                                  readonly>
                                <button 
                                  *ngIf="isToggleConsultantDropdown2 && action === 'update'" 
                                  class="btn btn-outline-secondary" 
                                  type="button" 
                                  (click)="onClickToggleConsultantDropdown('p2')">
                                  <i class="fa fa-pencil"></i>
                                </button>
                              </div>
                        
                              <select 
                                class="form-control form-select" 
                                id="CaseId" 
                                *ngIf="!isToggleConsultantDropdown2 || action === 'create'" 
                                formControlName="consultant">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of consultantList">
                                  <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH record, if any
                              </label>
                              <input type="file" class="form-control file-upload-default" #file>
                              <div class="attachment-block">

                                <span (click)="openPhRecord(phRecordLoc, i)" style="margin-left: 5px;">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getPhRecordIfAnyLocLength(i) != 0">
                                  {{getPhRecordIfAnyLocLength(i)}} Attachment</span>

                                <!-- <span style="cursor: pointer;" (click)="openPhRecord(phRecordLoc,i)"> <i
                                class="icon fa fa fa-paperclip" style="cursor: pointer;"  
                                title="Attach file"></i></span><span style="cursor: pointer;" (click)="openPhRecord(phRecordLoc,i)">Attachment</span> -->

                              </div>
                              <div>
                                <ng-template #phRecordLoc let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let phRecordGrp of getPhRecord(adjPHdiv); let j = index"
                                            [formGroup]="phRecordGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="phRecordGrp.value.isdocLocUploadedClicked" type="file"
                                                      id="hearingDetails({{i}}).phRecordIfAnyLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)">
                                                    <p *ngIf="!phRecordGrp.value.isdocLocUploadedClicked || phRecordGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(phRecordGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="phRecordGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(phRecordGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(phRecordGrp.value.fileLoc || phRecordGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadPhRecord(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deletePhRecord(i,j)"
                                                      *ngIf="(phRecordGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" formControlName="remarks"
                                                [title]="phRecordGrp.value.remarks ? phRecordGrp.value.remarks:''">
                                              <div
                                                *ngIf="phRecordGrp.value.remarks && phRecordGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH discussion notes</label>
                              <textarea class="form-control" id="exampleTextarea1" rows="4"
                                formControlName="phDiscussionNotes"></textarea>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> 

              <!-- Details of Consultant Charges  -->
              <div data-toggle="collapse" data-target="#demo" class="expansion-collapse"
                (click)="consultantPanleExpansion()">Details of Consultant Charges <span class="consultant-icons"> <i
                    *ngIf="!consultantExpansion" class="fa fa-angle-down" aria-hidden="true"></i> <i
                    *ngIf="consultantExpansion" class="fa fa-angle-up" aria-hidden="true"></i></span> </div>
              <div id="demo" class="collapse">
                <div class=" row">
                  <div class="col-md-12">
                    <div class="search-table-outter wrapper p-15">
                      <table class="search-table inner">
                        <thead>
                          <tr class="bgGred">
                            <th class=" w-40">Sr.No.</th>
                            <th>Name of the Consultant</th>
                            <th>Name of Consulting Firm</th>
                            <th>Nature of Work</th>
                            <th>Fees Charged</th>
                            <th>Supporting Document </th>
                            <th>Email id</th>
                            <th>Contact Number</th>
                            <th>Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container>
                            <tr *ngFor="let consultantGrp of consultantInvArr(); let  i=index;"
                              [formGroup]="consultantGrp">
                              <td class="w-40"> {{i+1}} </td>
                              <td>
                                <!-- <input type="text" class="form-control" id="tax" placeholder=""
                                formControlName="consultant"
                                [title]="consultantGrp.value.consultant? consultantGrp.value.consultant:''"> -->
                                <div *ngIf="isToggleConsultantDropdown3 && action==='update' || action==='view'" class="input-group">
                                  <input type="text" class="form-control"
                                    *ngIf="isToggleConsultantDropdown3 && action==='update' || action==='view'" placeholder=""
                                    formControlName="consultant" readonly>
                                  <button *ngIf="isToggleConsultantDropdown3 && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('CC')">
                                    <i class="fa fa-pencil"></i>
                                  </button> 
                                </div>
                        
                                  <select class="form-control form-select" id="tax"
                                    *ngIf="!isToggleConsultantDropdown3 || action==='create'" formControlName="consultant" (change)="consultantDataAutofil(i)">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of consultantList">
                                    <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                    </ng-container>
                                  </select>
                              </td>
                              <td>
                                <input type="text" class="form-control" id="tax" placeholder="" formControlName="firm"
                                  [title]="consultantGrp.value.firm ? consultantGrp.value.firm:''" readonly style="cursor: not-allowed;" >
                                <!-- <div *ngIf="isSubmitted && consultantGrp.get('firm')?.errors">
                                  <span class="text-danger" *ngIf="consultantGrp.get('firm')?.errors?.required"> Name of
                                    Consulting Firm Required</span>
                                </div> -->
                              </td>

                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                  formControlName="natureOfWork"
                                  [title]="consultantGrp.value.natureOfWork ? consultantGrp.value.natureOfWork:'' ">
                              </td>
                              <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                  formControlName="fees" (input)="getConsultantChargesTotal()" maxlength="19" InrFormat
                                  (paste)="handlePaste($event)"></td>
                              <td>
                                <span (click)="openCOnsultOtherAttach(consultantPop, i)">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getCOnsultOtherAttachLength(i) != 0">
                                  {{getCOnsultOtherAttachLength(i)}} Attachment</span>


                                <ng-template #consultantPop let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            *ngFor="let attachDtlsFromGrp of getConsultotherAttachmentLoc(consultantGrp); let j = index"
                                            [formGroup]="attachDtlsFromGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="attachDtlsFromGrp.value.isdocLocUploadedClicked"
                                                      type="file"
                                                      id="consultantCharges({{i}}).otherAttachmentLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)" style="border: 1px solid #0000;">
                                                    <p *ngIf="!attachDtlsFromGrp.value.isdocLocUploadedClicked || attachDtlsFromGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(attachDtlsFromGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="attachDtlsFromGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(attachDtlsFromGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(attachDtlsFromGrp.value.fileLoc || attachDtlsFromGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadOtherAttach(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deleteOtherAttach(i,j)"
                                                      *ngIf="(attachDtlsFromGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control remark" formControlName="remarks"
                                                [title]="attachDtlsFromGrp.value.remarks ? attachDtlsFromGrp.value.remarks:''">
                                              <div
                                                *ngIf="attachDtlsFromGrp.value.remarks && attachDtlsFromGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </td>
                              <td>
                                <input type="text" class="form-control" placeholder="" formControlName="email" readonly style="cursor: not-allowed;"  [title]="consultantGrp.value.email ? consultantGrp.value.email:''">
                                <!-- <div *ngIf="consultantGrp.get('email')?.errors">
                                  <span class="text-danger" *ngIf="consultantGrp.get('email')?.errors?.pattern"> Please
                                    Enter
                                    Valid Email ID</span>
                                </div> -->
                              </td>
                              <td><input class="form-control" id="contact" formControlName="contact"
                                  [title]="consultantGrp.value.contact ? consultantGrp.value.contact:''" maxlength="12" readonly style="cursor: not-allowed;" />
                              </td>
                              <td class="w-40"><a class=" addExtLetter" *ngIf="i != 0"
                                  (click)="openRmvconsultantPop(removeConsultantPopup, i)"><i
                                    class="fa fa-minus"></i></a>
                                <a class="addExtLetter" (click)="addConsultantInv($event)">
                                  <i class="fa fa-plus" title="Add row"></i> </a>
                                <ng-template #removeConsultantPopup let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p>Are you sure, you want to remove?</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="rmConsultantInv(i)">Remove</button>
                                  </div>
                                </ng-template>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="4"></td>
                              <td class="align-right bold-font">{{feesChargedTotal | INR : 0}}</td>
                              <td></td>
                              <td></td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!--  Details of Liability Admitted and Paid-->

              <div data-toggle="collapse" data-target="#liability" class="expansion-collapse"
                (click)="liabilityPanelExpansion()"> Details of Liability Admitted and Paid<span
                  class="consultant-icons"> <i *ngIf="!liabilityExpansionPanel" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="liabilityExpansionPanel" class="fa fa-angle-up"
                    aria-hidden="true"></i></span> </div>
              <div id="liability" class="collapse">
                <div class=" row">
                  <div class="col-md-12">
                    <div class="search-table-outter wrapper p-15">
                      <table class="search-table inner">
                        <thead>
                          <tr class="bgGred">
                            <th class="col-3 w-40">Sr.No.</th>
                            <th class="col-3">Form of Payment</th>
                            <th class="col-3">Challan No. / Ref. No.</th>
                            <th class="col-3">Mode of Payment</th>
                            <th class="col- 3">IGST (₹)</th>
                            <th class="col- 3">CGST (₹)</th>
                            <th class="col- 3">SGST (₹)</th>
                            <th class="col- 3">CESS (₹)</th>
                            <th class="col- 3"> Interest (₹)</th>
                            <th class="col- 3"> Penalty (₹)</th>
                            <th class="col- 3"> Total (₹)</th>
                            <th>Accounting Reference</th>
                            <th class="col-3">Copy of Form</th>
                            <th class="col- 3"> Copy of Bank Challan </th>
                            <th class="col- 3 w-40"> Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container>
                            <tr *ngFor="let detailsOfLiabilityGrp of getLiability(); let  i=index;"
                              [formGroup]=" detailsOfLiabilityGrp">
                              <td class="w-40"> {{i+1}} </td>
                              <td>
                                <select class="form-control form-select" id="authority" formControlName="formOfPayment">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of formPayment ">
                                    <option [value]="obj.value">{{obj.label}}</option>
                                  </ng-container>
                                </select>
                              </td>
                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                  formControlName="challanNo"
                                  [ngClass]="{ 'errorBorder': isSubmitted && detailsOfLiabilityGrp.get('copyOfFormLoc')?.errors  }">
                                <div *ngIf="isSubmitted && detailsOfLiabilityGrp.get('copyOfFormLoc')?.errors">
                                  <span class="text-danger"
                                    *ngIf="detailsOfLiabilityGrp.get('copyOfFormLoc')?.errors?.required">Please Upload
                                    Copy of Challan</span>
                                </div>
                              </td>
                              <td>
                                <select class="form-control form-select" id="authority" formControlName="modeOfPayment">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of modeOfPayment ">
                                    <option [value]="obj.value">{{obj.key}}</option>
                                  </ng-container>
                                </select>
                              </td>


                              <td><input type="text" class="form-control align-right" id="igst" placeholder=""
                                  (input)="liabilityTotalCalculation()" formControlName="igst" maxlength="19" InrFormat
                                  (paste)="handlePaste($event)"></td>

                               <td><input type="text" class="form-control align-right" id="cgst" placeholder=""
                                    (input)="liabilityTotalCalculation()" formControlName="cgst" maxlength="19" InrFormat
                                    (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" id="sgst" placeholder=""
                                      (input)="liabilityTotalCalculation()" formControlName="sgst" maxlength="19" InrFormat
                                      (paste)="handlePaste($event)"></td>
                               <td><input type="text" class="form-control align-right" id="cess" placeholder=""
                                       (input)="liabilityTotalCalculation()" formControlName="cess" maxlength="19" InrFormat
                                        (paste)="handlePaste($event)"></td>
                                      

                              <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                  (input)="liabilityTotalCalculation()" formControlName="interest" maxlength="19"
                                  InrFormat (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                  (input)="liabilityTotalCalculation()" formControlName="penalty" maxlength="19"
                                  InrFormat (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" id="tax" readonly placeholder=""
                                  (input)="liabilityTotalCalculation()" formControlName="total" maxlength="19"
                                  InrFormat></td>
                                  <td>
                                    <input type="text" class="form-control" formControlName="accountingRef"
                                    [ngClass]="{ 'errorBorder': detailsOfLiabilityGrp.value.accountingRef?.length > 100 }"
                                      [title]="detailsOfLiabilityGrp.value.accountingRef ? detailsOfLiabilityGrp.value.accountingRef:''">
                                    <div
                                      *ngIf="detailsOfLiabilityGrp.value.accountingRef && detailsOfLiabilityGrp.value.accountingRef.length > 100">
                                      <span class="text-danger">Max character Length 100</span>
                                    </div>
                                </td>
                              <td>
                                <div class="row">
                                  <div class="col-sm-9 nopadding">
                                    <span (click)="openCopyOfForm(copyFormPop, i)" style="margin-left: 5px;">
                                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                        title="Attach file"></i></span><span *ngIf="getCopyFormLength(i) != 0">
                                      {{getCopyFormLength(i)}} Attachment</span>

                                    <!-- <div class="form-group">
                                    <input *ngIf="detailsOfLiabilityGrp.value.isCopyOfFormLocDocUploadedClicked" type="file" id="liabilityDetails({{i}}).copyOfFormLoc" class="filetype file-placement form-control" name="myfile" (change)='onFileSelect($event, "liabilityDetails")'[ngClass]="{ 'errorBorder': isSubmitted && detailsOfLiabilityGrp.get('copyOfFormLoc')?.errors  }">
                                    <p *ngIf="!detailsOfLiabilityGrp.value.isCopyOfFormLocDocUploadedClicked || p1.liabilityDetails.value[i].copyOfFormLoc" class="form-control pt10"  style="margin: 10px;"[ngClass]="{ 'errorBorder': isSubmitted && detailsOfLiabilityGrp.get('copyOfFormLoc')?.errors  }">{{getFileNameDta(detailsOfLiabilityGrp.value.copyOfFormLoc)}}</p>
                                    <div *ngIf="isSubmitted && detailsOfLiabilityGrp.get('copyOfFormLoc')?.errors">
                                      <span class="text-danger" *ngIf="detailsOfLiabilityGrp.get('copyOfFormLoc')?.errors?.required">Required</span>
                                    </div> 
                                  </div> -->
                                    <ng-template #copyFormPop let-modal>
                                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="col-md-12">
                                        <label> Attachment</label>
                                        <div class="table-responsive">
                                          <table class="table table-bordered tablepop">
                                            <thead>
                                              <tr class="bgGred">
                                                <th>Upload</th>
                                                <th style="width:50%;">Remark</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                *ngFor="let copyFormGrp of getLiablityCOpyFormLoc(detailsOfLiabilityGrp); let j = index"
                                                [formGroup]="copyFormGrp">
                                                <td>
                                                  <div class="row">
                                                    <div class="col-md-9 nopadding">
                                                      <div class="form-group">
                                                        <input *ngIf="copyFormGrp.value.isdocLocUploadedClicked"
                                                          type="file"
                                                          id="liabilityDetails({{i}}).copyOfFormLoc({{j}}).fileLoc"
                                                          class="filetype form-control" name="myfile"
                                                          (change)="onFileSelect($event)"
                                                          style="border: 1px solid #0000;">
                                                        <p *ngIf="!copyFormGrp.value.isdocLocUploadedClicked || copyFormGrp.value.fileLoc"
                                                          class="form-control pt10">
                                                          {{getFileNameDta(copyFormGrp.value.fileLoc)}}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 nopadding">
                                                      <label for="exampleInputPassword1">
                                                        <a class="downupIcon" *ngIf="copyFormGrp.value.fileLoc"
                                                          title="Download file"
                                                          (click)="download(copyFormGrp.value.fileLoc)"><i
                                                            class="fa fa-download"></i></a>
                                                        <a class="downupIcon"
                                                          *ngIf="!(copyFormGrp.value.fileLoc || copyFormGrp.value.fileLoc) && isDisableIcons"
                                                          title="Upload file" (click)="uploadCopyForm(i,j)"><i
                                                            class="fa fa-upload"></i></a>
                                                        <a class="downupIcon" (click)="deleteCopyForm(i,j)"
                                                          *ngIf="(copyFormGrp.value.fileLoc) && isDisableIcons"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type="text" class="form-control remark"
                                                    formControlName="remarks"
                                                    [title]="copyFormGrp.value.remarks  ? copyFormGrp.value.remarks:''">
                                                  <div
                                                    *ngIf="copyFormGrp.value.remarks && copyFormGrp.value.remarks.length > 100">
                                                    <span class="text-danger">Max character Length 100</span>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Save</button>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <!-- <div class="col-sm-3 nopadding">
                                   <label for="exampleInputPassword1 " class="up-down-icons">
                                    <a class="downupIcon" *ngIf="p1.liabilityDetails.value[i].copyOfFormLoc" title="Download file" (click)="download(detailsOfLiabilityGrp.value.copyOfFormLoc)"><i class="fa fa-download"></i></a>
                                    <a class="downupIcon" *ngIf="isDisableIcons && !(p1.liabilityDetails.value[i].copyOfFormLoc || p1.liabilityDetails.value[i].copyOfFormLoc)" title="Upload file" (click)="uploadcopyOfForm(i)"><i class="fa fa-upload" ></i></a>
                                    <a  class="downupIcon" (click)="deleteliabilityDetailsFile(i)"  *ngIf="isDisableIcons && (detailsOfLiabilityGrp.value.copyOfFormLoc)"><i  class="fa fa-trash" aria-hidden="true"></i></a>
                                  </label>
                               </div> -->
                                </div>
                              </td>

                              <td>
                                <div class="row">
                                  <div class="col-sm-9 nopadding">
                                    <!-- <span style="cursor: pointer;" (click)="openBankChallan(bankChallanPop, i)">
                                   <i class="icon fa fa fa-paperclip" title="Attach file" style="cursor: pointer;"></i></span>
                                   <span style="cursor: pointer;" (click)="openBankChallan(bankChallanPop, i)"> Attachment</span> -->

                                    <span (click)="openBankChallan(bankChallanPop, i)" style="margin-left: 5px;">
                                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                        title="Attach file"></i></span><span *ngIf="getBankChallanLocLength(i) != 0">
                                      {{getBankChallanLocLength(i)}} Attachment</span>

                                    <!-- <div class="form-group">
                                    <input  *ngIf="detailsOfLiabilityGrp.value.isCopyOfBankChallanLocDocUploadedClicked" type="file" id="liabilityDetails({{i}}).copyOfBankChallanLoc" class="filetype file-placement form-control" name="myfile" (change)='onFileSelect($event, "liabilityDetails")'>
                                    <p *ngIf="!detailsOfLiabilityGrp.value.isCopyOfBankChallanLocDocUploadedClicked || p1.liabilityDetails.value[i].copyOfBankChallanLoc" class="form-control pt10"  style="margin: 10px;">{{getFileNameDta(detailsOfLiabilityGrp.value.copyOfBankChallanLoc)}}</p>
                                  </div> -->
                                  </div>
                                  <!-- <div class="col-sm-3 nopadding">
                                  <label for="exampleInputPassword1 " class="up-down-icons">
                                    <a class="downupIcon" *ngIf="p1.liabilityDetails.value[i].copyOfBankChallanLoc" title="Download file" (click)="download(detailsOfLiabilityGrp.value.copyOfBankChallanLoc)"><i class="fa fa-download"></i></a>
                                    <a class="downupIcon" *ngIf="isDisableIcons && !(p1.liabilityDetails.value[i].copyOfBankChallanLoc || p1.liabilityDetails.value[i].copyOfBankChallanLoc)" title="Upload file" (click)="uploadcopyOfBankChallan(i)"><i class="fa fa-upload"  ></i></a>
                                    <a  class="downupIcon" (click)="deleteliabilityFile(i)"  *ngIf="isDisableIcons && (detailsOfLiabilityGrp.value.copyOfBankChallanLoc)"><i  class="fa fa-trash" aria-hidden="true"></i></a>
                                  </label>
                              </div> -->
                                  <ng-template #bankChallanPop let-modal>
                                    <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                      <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="col-md-12">
                                      <label> Attachment</label>
                                      <div class="table-responsive">
                                        <table class="table table-bordered tablepop">
                                          <thead>
                                            <tr class="bgGred">
                                              <th>Upload</th>
                                              <th style="width:50%;">Remark</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              *ngFor="let bankChallanGrp of getBankChallanLoc(detailsOfLiabilityGrp); let j = index"
                                              [formGroup]="bankChallanGrp">
                                              <td>
                                                <div class="row">
                                                  <div class="col-md-9 nopadding">
                                                    <div class="form-group">
                                                      <input *ngIf="bankChallanGrp.value.isdocLocUploadedClicked"
                                                        type="file"
                                                        id="liabilityDetails({{i}}).copyOfBankChallanLoc({{j}}).fileLoc"
                                                        class="filetype form-control" name="myfile"
                                                        (change)="onFileSelect($event)"
                                                        style="border: 1px solid #0000;">
                                                      <p *ngIf="!bankChallanGrp.value.isdocLocUploadedClicked || bankChallanGrp.value.fileLoc"
                                                        class="form-control pt10">
                                                        {{getFileNameDta(bankChallanGrp.value.fileLoc)}}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-3 nopadding">
                                                    <label for="exampleInputPassword1">
                                                      <a class="downupIcon" *ngIf="bankChallanGrp.value.fileLoc"
                                                        title="Download file"
                                                        (click)="download(bankChallanGrp.value.fileLoc)"><i
                                                          class="fa fa-download"></i></a>
                                                      <a class="downupIcon"
                                                        *ngIf="!(bankChallanGrp.value.fileLoc || bankChallanGrp.value.fileLoc) && isDisableIcons"
                                                        title="Upload file" (click)="uploadBankChallanForm(i,j)"><i
                                                          class="fa fa-upload"></i></a>
                                                      <a class="downupIcon" (click)="deleteBankChallan(i,j)"
                                                        *ngIf="(bankChallanGrp.value.fileLoc) && isDisableIcons"><i
                                                          class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </label>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control remark" formControlName="remarks"
                                                  [title]="bankChallanGrp.value.remarks ? bankChallanGrp.value.remarks:''">
                                                <div
                                                  *ngIf="bankChallanGrp.value.remarks && bankChallanGrp.value.remarks.length > 100">
                                                  <span class="text-danger">Max character Length 100</span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="modal-footer" style="border: none; padding-top: 0px;">
                                      <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Save</button>
                                    </div>
                                  </ng-template>
                                </div>
                              </td>
                              <td class="w-40"> <a class="addExtLetter" (click)="addLiabilityInv($event)"><i
                                    class="fa fa-plus" title="Add row"></i> </a>
                                <a class=" addExtLetter" *ngIf="i != 0"
                                  (click)="openRmvLiabilityPop(removeConsultantPopup, i)"><i
                                    class="fa fa-minus"></i></a>
                                <ng-template #removeConsultantPopup let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p>Are you sure, you want to remove?</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="rmLiabilityInv(i)">Remove</button>
                                  </div>
                                </ng-template>
                              </td>
                            </tr>
                          </ng-container>
                          <ng-container>
                            <tr>
                              <th colspan="4" class="text-right">Total</th>
                              <td class="align-right bold-font ">
                                <!-- <input type="text" class="form-control" maxlength="19" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" id="taxgstTotal"
                            placeholder="" formControlName="taxTotal" readonly> -->
                                {{liabilityTotals.igstTotal | INR :0 }}
                              </td>
                              <td class="align-right bold-font ">
                                {{liabilityTotals.cgstTotal | INR :0 }}
                              </td>
                              <td class="align-right bold-font ">
                                {{liabilityTotals.sgstTotal | INR :0 }}
                              </td>
                              <td class="align-right bold-font ">
                                {{liabilityTotals.cessTotal | INR :0 }}
                              </td>
                              <td class="align-right bold-font">
                                <!-- <input type="text" class="form-control" maxlength="19" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" id="interestgstTotal"
                              placeholder="" formControlName="interestTotal" readonly> -->
                                {{liabilityTotals.interestTotal | INR :0 }}
                              </td>
                              <td class="align-right bold-font">
                                <!-- <input type="text" class="form-control" maxlength="19" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" id="penaltygstTotal"
                              placeholder="" formControlName="penaltyTotal" readonly> -->
                                {{liabilityTotals.penaltyTotal | INR :0 }}
                              </td>
                              <td class="align-right bold-font">
                                <!-- <input type="text" class="form-control" maxlength="19" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" id="totOfgstTotal"
                                placeholder="" formControlName="totalOfTotal" readonly> -->
                                {{liabilityTotals.allTotal | INR :0 }}
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row col-md-12 mt15">
                <div class="col-md-3 ">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Case Conclusion </label>

                    <select class="form-control form-select" id="proceededToScn" formControlName="status"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.status.errors }" (change)="checkSCNTrig($event)">
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of proceededToScn">
                        <option [value]="obj.value">{{obj.label}}</option>
                      </ng-container>
                    </select>
                    <!-- <div *ngIf="isSubmitted && p1.status.errors">
                      <span class="text-danger" *ngIf="p1.status.errors.required"> Select Conclusion of the letter
                      </span>
                    </div> -->
                  </div>
                </div>
                <div class="col-md-3" *ngIf="showFields">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Date of receipt of SCN <span class="mandate"><i
                          class="fa fa-asterisk"></i></span></label>
                    <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                      <p-calendar class="form-control" formControlName="dateOfReceiptOfScn" [maxDate]="maxDate"
                        [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                        dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                        [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfReceiptOfScn.errors }"></p-calendar>
                    </div>
                    <div *ngIf="isSubmitted && p1.dateOfReceiptOfScn.errors">
                      <span class="text-danger" *ngIf="p1.dateOfReceiptOfScn.errors.required"> Required</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3" *ngIf="showFields">
                  <div class="form-group">
                    <label for="exampleInputPassword1">SCN Number <span class="mandate"><i
                          class="fa fa-asterisk"></i></span></label>
                    <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="scnNo"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.scnNo.errors }">
                    <div *ngIf="isSubmitted && p1.scnNo.errors">
                      <span class="text-danger" *ngIf="p1.scnNo.errors.required"> Required</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3" *ngIf="showClosedFields">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Date of receipt of ASMT-12 </label>
                    <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                      <p-calendar class="form-control" formControlName="asmt12RecepitDate" [maxDate]="maxDate"
                        [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                        dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                    </div>
                  </div>
                </div>

                <div class="col-md-3" *ngIf="showClosedFields">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Copy of ASMT12<span class="mandate"><i
                          class="fa fa-asterisk"></i></span>
                    </label>
                    <input type="file" class="file-upload-default" #file>
                    <!-- <div class="attachment-block">
                      <span style="cursor: pointer;" (click)="openAstmtLoc(astmt12Pop)"> <i
                          class="icon fa fa fa-paperclip" style="cursor: pointer;" (click)="openAstmtLoc(astmt12Pop)"
                          title="Attach file"></i></span><span style="cursor: pointer;" (click)="openAstmtLoc(astmt12Pop)">Attachment</span>
                
                    </div> -->
                    <div class="attachment-block"
                      [ngClass]="{'errorBorder': isSubmitted && getAsmt12LocLength() === 0 }">
                      <span (click)="openAstmtLoc(astmt12Pop)" style="margin-left: 5px;">
                        <i class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span><span
                        *ngIf="getAsmt12LocLength() != 0"> {{getAsmt12LocLength()}} Attachment</span>
                    </div>
                    <div *ngIf="isSubmitted &&  getAsmt12LocLength() === 0">
                      <span class="text-danger"> Required</span>
                    </div>
                    <ng-template #astmt12Pop let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment</label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                                <th style="width:50%;">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let astmt12FromGrp of getasmt12Loc(); let i = index"
                                [formGroup]="astmt12FromGrp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="astmt12FromGrp.value.isdocLocUploadedClicked" type="file"
                                          id="asmt12Loc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                          (change)="onFileSelect($event)" style="border: 1px solid #0000;">
                                        <p *ngIf="!astmt12FromGrp.value.isdocLocUploadedClicked || astmt12FromGrp.value.fileLoc"
                                          class="form-control pt10"> {{getFileNameDta(astmt12FromGrp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="astmt12FromGrp.value.fileLoc" title="Download file"
                                          (click)="download(astmt12FromGrp.value.fileLoc)"><i
                                            class="fa fa-download"></i></a>
                                        <a class="downupIcon"
                                          *ngIf="!(astmt12FromGrp.value.fileLoc || astmt12FromGrp.value.fileLoc)&& isDisableIcons"
                                          title="Upload file" (click)="uploadastMtLocCopy(i)"><i
                                            class="fa fa-upload"></i></a>
                                        <a class="downupIcon" (click)="deleteUploadAmtFile(i)"
                                          *ngIf="(astmt12FromGrp.value.fileLoc)&& isDisableIcons"><i class="fa fa-trash"
                                            aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input type="text" class="form-control remark" formControlName="remarks"
                                    [title]="astmt12FromGrp.value.remarks ? astmt12FromGrp.value.remarks:''">
                                  <div
                                    *ngIf="astmt12FromGrp.value.remarks && astmt12FromGrp.value.remarks.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>
                </div>
                
                <div class="col-md-3"  *ngIf="showClosedFields">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Reference Number</label>
                    <input type="text" class="form-control" id="referenceNo" placeholder=""  formControlName="referenceNo" maxlength="50">
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt15 nopadding">
                <button class="btn btn-outline-primary mr-1" (click)="updateLetter('p2')"
                  *ngIf="isViewMode">Submit</button>


                <!-- <button class="btn btn-outline-primary mr-1">Delete</button> -->
              </div>
            </div>
          </fieldset>
          <ng-template #scnTrigPop let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Warning! </h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Please check at least one issue should be unfavorable, do you want to proceed to add SCN details?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">OK</button>
            </div>
          </ng-template>        
        </div>
      </div>
    </div>
  </form>
</div>
</div>
</div>
