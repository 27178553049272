import { Component, Input, OnInit } from '@angular/core';
import { PreGstServiceService } from 'src/app/preGstLitigation/services/pre-gst-service.service';
import { STATE_LIST } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-act-wise-table',
  templateUrl: './act-wise-table.component.html',
  styleUrls: ['./act-wise-table.component.css']
})
export class ActWiseTableComponent implements OnInit {

  @Input() a2Data: any;
  @Input() a2summary: any
  data: any = [];
  taxData: any = [];
  selectedState: any;
  stateList: any;
  navContext: any;
  entityType: any;
  pan: any;
  selectedIRN: boolean = false;
  isinCR: boolean = false;
  isinLakh: boolean = true;
  amountMetric: 'lakh' | 'crore' = 'lakh';


  @Input() divSumColTotal: any;
  @Input() divSumColTotals: any

  constructor(public commonService: CommonService,
    private pregstService: PreGstServiceService,
    private loaderService: LoaderService,
    private eEmit: EventEmitterService,
    private getterSetter: GetterSetterService) {

  }

  ngOnInit() {
    this.stateList = STATE_LIST;
    this.selectedState = localStorage.getItem('preAnalyticState');
    // this.preAnalytics();
    var regionKeys = Object.keys(this.a2Data);
    var mainLabel = Object.keys(this.a2Data);
    mainLabel.forEach((label: any) => {
      this.data.push({ label: label, data: this.a2Data[label] })
    })

    this.taxData = [];
    for (var i = 0; i < regionKeys.length; i++) {
      let regiondata = this.a2Data[regionKeys[i]];
      let row: any = {
        division: regionKeys[i],
        phases: regiondata.details,
        total: regiondata.total,
        totalTax: regiondata.totalTax,
        totalInterest: regiondata.totalInterest,
        totalPenalty: regiondata.totalPenalty,

        totalInCr: regiondata.totalInCr,
        totalTaxInCr: regiondata.totalTaxInCr,
        totalInterestInCr: regiondata.totalInterestInCr,
        totalPenaltyInCr: regiondata.totalPenaltyInCr
      };

      let stateDataArray = [];
      for (let state in regiondata.details) {
        let rawStateData = regiondata.details[state];
        let stateData = {
          state: state,
          statedata: rawStateData
        }
        stateDataArray.push(stateData);
      }
      row.stateData = stateDataArray;
      this.taxData.push(row);
    }
    this.taxData = this.commonService.sortObjectsByKey(this.taxData, 'division');
    if (this.taxData.length > 0) {
      this.taxData.forEach((element: any) => {
        element.expanded = false;

      });
    }




    //   mainLabel.forEach((label: any) => {
    //     this.data.push({ label: label, data: this.a4Data[label] })
    //   })

    //   this.data.forEach((d: any) => {
    //     const gstinKeys = Object.keys(d.data);
    //     d.gstinData = [];

    //     gstinKeys.forEach((key: any) => {
    //       let stageData = d.data.details;

    //       d.gstinData.push({gstin: stageData.ke, data:stageData})
    //     })

    //   })



    // this.getTotalOfTotal();
  }

  changeStatsValue(selected: any, event: Event) {
    const isCrore = (event.target as HTMLInputElement).checked;
    this.amountMetric = isCrore ? 'crore' : 'lakh';
    if (selected == false) {
      this.isinCR = false;
      this.isinLakh = true;
    }
    else if (selected == true) {
      this.isinCR = true;
      this.isinLakh = false;
    }
  }

  // preAnalytics() {
  //   this.navContext = this.commonService.getNavContext();
  //   if (this.navContext.entityType === 'FILING') {
  //     this.pan = this.navContext.gstin;
  //   } else if (this.navContext.entityType === 'LEGAL') {
  //     this.pan = this.navContext.pan;
  //   } else if (this.navContext.entityType === 'Business') {
  //     this.pan = '';
  //   }
  //   const navContext = JSON.parse(this.getterSetter.getNavContextData() || '{}');
  //   let model = {
  //     selectedState: this.selectedState,
  //     pan: this.navContext.entityType === 'LEGAL' ? this.pan : null
  //   }
  //   // this.pregstService.getPreAnalytics(model).subscribe((response) =>{
  //   //   this.divSumColTotals = response;
  //   //   this.divSumColTotal= this.divSumColTotals.a2summary;
  //   //   console.log(this.divSumColTotal,"divSumColTotal")
  //   // })
  // }

  setState(event: any) {
    this.selectedState = event.target.value;
    localStorage.setItem('preAnalyticState', this.selectedState);
    // this.preAnalytics();
  }
}


