<!-- partial -->
<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12">
          <!-- <div class="grid-margin stretch-card">
              <div class="card">
                <div class="row ">
                  <div class="col-md-12 stretch-card">
                    <div class="card-body pb0 pt5">
                      <div class="row ">
                        <div class="col-md-1 updateCount">
                          <p class="tx-12 clrGreen"><i class="fa fa-bullhorn"></i></p>
                        </div>
                        <div class="col-md-11  updates">
                          <div class="scroll-left">
                            <p> 
                              <span>
                                <ul class="dueDateList" >
                                  <li class="dueDateHeader">Due Dates: </li>
                                  <!- <li *ngFor="let item of dueData.aja"><a (click)="getCaseData(item.caseId, 'view', 'aja', 'tab2')" class="dueDtCase clrOrange pr-1 fs14">{{item.caseId}}</a> -
                                    <span class="pl-1 pr-1">{{item.dueDate}}</span> </li> ->
                                </ul>
                              </span>
                             </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          <div class="row">
            <div class="col-md-7">
              <div class="grid-margin stretch-card">
                <div class="card">
                  <div class="row ">
                    <div class="col-md-12 stretch-card">
                      <div class="card-body ">
                        <p class="card-title">Audit summary</p>
                        <div class="row ">
                          <div class="col-md-3  justify-content-between  mt-2 mb-2 updateCount">
                            <p class="tx-12 clrOrange"><i class="fa fa-legal"></i></p>
                          </div>
                          <div class="col-md-9 justify-content-between mt-2 mb-2 updates">
                            <p><a class="fs30">{{cntOPEN}} Cases</a></p>
                            <!-- <p>Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem </p> -->
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="grid-margin stretch-card">
                <div class="card">
                  <div class="row ">
                    <div class="col-md-12 stretch-card">
                      <div class="card-body ">
                        <p class="card-title">Statistics</p>
                        <div class="row ">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tr>
                                <th>State</th>
                                <th>FY - 17-18 </th>
                                <th>FY - 18-19</th>
                                <th>FY - 19-20 </th>
                                <th>FY - 20-21 </th>
                              </tr>
                              <tr>
                                <td>Maharashtra </td>
                                <td><label class="badge badge-success">Closed</label></td>
                                <td><label class="badge badge-warning">SCN issued </label></td>
                                <td><label class="badge badge-warning">Open</label> </td>
                                <td><label class="badge badge-warning">Not initiated</label></td>
                              </tr>
                              <tr>
                                <td>Maharashtra </td>
                                <td><label class="badge badge-warning">No SCN</label></td>
                                <td><label class="badge badge-success">Closed </label></td>
                                <td><label class="badge badge-warning">Open</label> </td>
                                <td><label class="badge badge-warning">Not initiated</label></td>
                              </tr>
                            </table>
                          </div>

                        </div>


                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div class="col-md-5">
              <div class="grid-margin stretch-card">
                <div class="card">
                  <div class="row ">
                    <div class="col-md-12 stretch-card">
                      <div class="card-body pb0">
                        <p class="card-title">Due Date

                          <!-- <select class="form-control dashDropdown form-select" id="gstin"
                                  (change)="getDueDateData()" [(ngModel)]="dueDays"> 
                                  <ng-container *ngFor="let obj of dueDateSpan">
                                    <option [value]="obj.value">{{obj.key}}</option>
                                  </ng-container>
                                </select> -->
                        </p>
                        <div class="accordion" id="accordion" role="tablist">
                          <div class="card border-bottom">
                            <div class="card-header" role="tab" id="heading-1">
                              <h6 class="mb-0">
                                <a data-toggle="collapse" href="#collapse-1" aria-expanded="true"
                                  aria-controls="collapse-1">
                                  Replies to ADT - 01
                                </a>
                              </h6>
                            </div>
                            <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1"
                              data-parent="#accordion">
                              <div class="card-body ">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Reference No</th>
                                        <th>Due Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td><a>2021-09-21-381494</a></td>
                                        <td>2021-09-21</td>
                                      </tr>

                                    </tbody>
                                    <!-- <tbody *ngIf="dueData">
                                        <tr *ngFor="let item of dueData.aja">
                                          <td><a (click)="getCaseData(item.caseId, 'view', 'aja', 'tab2')">{{item.caseId}}</a></td>
                                          <td>{{item.dueDate}}</td>
                                        </tr>
                                        
                                      </tbody> -->
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="card border-bottom">
                            <div class="card-header" role="tab" id="heading-2">
                              <h6 class="mb-0">
                                <a class="collapsed" data-toggle="collapse" href="#collapse-2" aria-expanded="false"
                                  aria-controls="collapse-2">
                                  Replies to Audit findings
                                </a>
                              </h6>
                            </div>
                            <div id="collapse-2" class="collapse" role="tabpanel" aria-labelledby="heading-2"
                              data-parent="#accordion">
                              <div class="card-body">
                                <div class="table-responsive">
                                  <table class="table table-bordered">
                                    <thead>
                                      <tr>
                                        <th>Reference No</th>
                                        <th>Due Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td><a>2021-09-21-381494</a></td>
                                        <td>2021-09-21</td>
                                      </tr>

                                    </tbody>
                                    <!-- <tbody *ngIf="dueData">
                                        <tr *ngFor="let item of dueData.apa">
                                          <td><a (click)="getCaseData(item.caseId, 'view', 'apa', 'tab3')">{{item.caseId}}</a></td>
                                          <td>{{item.dueDate}}</td>
                                        </tr>
                                       
                                      </tbody> -->
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-3">
            <div class="grid-margin stretch-card">
              <div class="card">
                <div class="card-body">
                  <p class="card-title">News and Updates</p>
                  <div class="row ">
                    <div class="col-md-12 justify-content-between mt-2 updates ">
                      <ul class="dashList dashul">
                        <li *ngFor="let itr of newsList; let i=index">
                          <span><i class="fa fa-angle-double-right"></i>
                            {{itr.newsHeader}}</span>                        
                          <p>
                            {{itr.newsBody}}
                          </p>                      
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

      </div>
    </div>
    <!-- content-wrapper ends -->
    <!-- partial:partials/_footer.html -->

    <!-- partial -->
  </div>
  <!-- main-panel ends -->
</div>