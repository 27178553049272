import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseResponse } from '../../../models/response';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { TokenService } from '../../../shared/services/token.service';
import { GetterSetterService } from '../../../shared/services/getter-setter.service';
@Component({
  selector: 'app-token-verify',
  templateUrl: './token-verify.component.html',
  styleUrls: ['./token-verify.component.css']
})
export class TokenVerifyComponent implements OnInit {
  tokenParam: any;
  orgID:any;
 
  constructor(
    private authService: AuthService,
    private router: Router,
    private activeSnapShot: ActivatedRoute,
    private toaster: ToasterService,
    private tokenService: TokenService,
    private getSet: GetterSetterService,
  ) {
    this.tokenParam = this.activeSnapShot.snapshot.queryParams.token;
   }

  ngOnInit() {
    if (this.tokenParam != undefined && this.tokenParam != null) {

      sessionStorage.clear();
      /*checking token is valid or not*/
      this.authService.tokenVerifyExternal(this.tokenParam).subscribe((response: any) => {
        if (response.status == 'SUCCESS') {
          
          this.toaster.showSuccess('Login Successful.');
          this.tokenService.setter(response.response);
          this.authService.setLoginIndicator(2);
          this.getSet.getSetCompany(response.response.rootCompanyName);
          this.getSet.getSetUser(response.response.username);
          this.getSet.getSetUserId(response.response.email);
          this.getSet.setProduct(response.response.products);
          //this.BusinessHeaderComponent.getBusinessHierarchy()
          if (response.response.rootCompanyid != undefined) {
            this.getSet.getSetCompanyId(response.response.rootCompanyid);
            //this.orgID = '123'
            //this.getSet.getSetOrgId(this.orgID);
          } 
          this.router.navigate(['/dashboard']);
          // this.username = response.response.username;
        } else {
          this.toaster.showError("Your token has been expired");
          this.router.navigate(['/logout-all-session']);
        }
      });
    } 
  }
  }


