<div id="chart-wrapper" class="chartjs-container">
  <div class="chart-box">
    <div class="chart-canvas">
      <canvas baseChart  style="cursor: pointer;" 
      [data]="chartData"
        [type]="chartType" 
        [options]="chartOptions" 
        (chartClick)="openPieChart(details, $event)">
      </canvas>
    </div>
    <div class="chart-legends">
      <div class="legend-item" *ngFor="let legend of legends">
        <p><span [style]="{ color: legend.color }">&#9679;</span> {{ legend.label }}</p>
        <p>{{ legend.value }} ({{ legend.percent }}%)</p>
      </div>
    </div>
  </div>
</div>

<ng-template #details let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Ageing Analysis - Registration No </h4>

    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="switch-box" style="float: inline-end;">
      <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
      <div class="form-check form-switch m-0">
        <input [(ngModel)]="selectedIRN" (change)="changeStatsValue(selectedIRN,$event)" class="form-check-input"
          type="checkbox" role="switch" [checked]="amountMetric === 'crore'" />
      </div>
      <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
      <p class="btn btn-outline-success btn-download" (click)="exportAsXLSX()">
        <i class="fa fa-file-excel-o me-2" aria-hidden="true"></i> Export
      </p>
    </div>
    <p class="pieChartLabel txtCenter">{{currentLabel | uppercase}}</p>
    <div class="table-responsive autoScroll">
      <table id="" class="table table-bordered">
        <thead>
          <tr class="bgGred">
            <th>Sr. No</th>
            <th><span> Registration</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(caseId.REGISTRATION_NO)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(caseId.REGISTRATION_NO)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th>Act</th>
            <th><span>Total</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(caseId.DEMAND_AMOUNT)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(caseId.DEMAND_AMOUNT)"
                *ngIf="!isAnalyticsConOne"></i></th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngFor="let obj of tableData; let i=index">
              <td>{{i+1}}</td>
              <td>{{obj.key}}</td>
              <td>{{obj.act}}</td>
              <td *ngIf="isinLakh" class="textRight">{{obj.value | INR : 2}}</td>
              <td *ngIf="isinCR" class="textRight">{{obj.value1 | INR : 2}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

</ng-template>
