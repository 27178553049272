<div class="container-fluid page-body-wrapper">
    <div class="breadCrumb">
        <a (click)="backtoOrder()">Additional Notice/Order</a>>><span>Case Details </span>>>
        <span>{{caseType}}</span>:<span>{{caseId}}</span>
    </div>
    <div class="main-panel">
        <div class="detailSec">
            <p><span>GSTIN Name:</span> {{gstinName}}</p>
            <p><span>GSTIN Number:</span> {{gstin}}</p>
        </div>
        <div class="row mt10 plr20">
            <div class="col-md-12 " *ngIf="!isResData">
                <div class="table-responsive nopadding">
                    <table class="table tblBorder">
                        <thead>
                            <tr>
                                <th colspan="10">Case Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let obj of noticedetailData;" let i="index">
                                <tr class="borderB">
                                    <td>
                                        {{gstinName}}
                                    </td>
                                    <td title="Folder">
                                        <span>{{ getCaseFolderTypeLabel(obj.gstcase.caseFolderTypeCd) }}</span>

                                    </td>
                                    <td>
                                        <p title="{{obj.gstcase.caseType}}" class="ellipsis"> {{obj.gstcase.caseType}}
                                        </p>
                                    </td>
                                    <td title="ReferenceID">
                                        {{obj.gstcase.refId}}
                                    </td>
                                    <td>
                                        <span class="colorGreen" title="Date of Order"
                                            *ngIf="obj.gstcase.parsedOrderDate">{{obj.gstcase.parsedOrderDate}}</span>
                                        <span class="colorGreen" title="Date of Intimation/Notice "
                                            *ngIf="obj.gstcase.parsedIssuedOn">{{obj.gstcase.parsedIssuedOn}}</span>
                                        <span class="colorGreen" title="Date of Reply"
                                            *ngIf="obj.gstcase.parsedReplyDate">{{obj.gstcase.parsedReplyDate}} </span>
                                    </td>
                                    <td title="Hearing Date">
                                        <span class="colorRed"
                                            *ngIf="obj.gstcase.parsedHrngDate">{{obj.gstcase.parsedHrngDate}} </span>
                                    </td>
                                    <td title="Due Date">
                                        <span class="colorRed">{{obj.gstcase.parsedDueDate}}</span>
                                    </td>
                                    <td title="Section">
                                        {{obj.gstcase.sec}}
                                    </td>
                                    <td title="Replied For">
                                        <span><span *ngIf="obj.gstcase.isReplied ==  true">Replied</span><span
                                                *ngIf="obj.gstcase.replyFor">({{obj.gstcase.replyFor}})</span></span>

                                    </td>
                                    <td title="Download Attachment">
                                        <a class="pr5  mt-1 fs18 mr-3" (click)="onClickFileName(obj.gstCaseFiles)"
                                            data-toggle="modal" data-target="#downloadModel" id="download"><i
                                                class="fa fa-download clrOrange notifyIcon"></i> </a>
                                    </td>

                                </tr>



                            </ng-container>


                        </tbody>
                    </table>
                    <br />

                </div>
            </div>
            <div class="col-md-12" *ngIf="isResData">
                <ng-container>
                    <p class="noData">No Case Details Found</p>
                </ng-container>
            </div>
        </div>


        <div class="modal fade" id="downloadModel" tabindex="-1" role="dialog" aria-labelledby="downloadModelLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="downloadModelLabel">Attachment</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" #closeModal>&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ng-container *ngFor="let obj of gstNoticeFile">
                            <ul>
                                <li><a (click)="download(obj.s3Key, obj.itemId, obj.name)"><i
                                            class="fa fa-download clrOrange notifyIcon"></i> {{obj.name}}</a></li>
                            </ul>
                        </ng-container>

                        <!-- <ul *ngFor="let item of noticedetailData">
                        <li><a (click)="download(item.gstCaseFiles.s3Key, item.gstCaseFiles.itemId)"><i class="fa fa-download clrOrange notifyIcon"></i> {{item.gstCaseFiles.name}}</a></li>
                    </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>