import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash-es';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { AUDITDESIGNATION, AUDIT_WHFOLLOWUP } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { AuditService } from '../service/audit.service';
import { InrService } from 'src/app/shared/services/inr.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-audit-intimation-letter',
  templateUrl: './audit-intimation-letter.component.html',
  styleUrls: ['./audit-intimation-letter.component.css']
})
export class AuditIntimationLetterComponent implements OnInit {
  @Output() moveToAFL: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('auditFindingPop', { read: TemplateRef }) auditFindingPop!: TemplateRef<any>;
  @Output() saveCaseId = new EventEmitter<boolean>();
  parentCaseId: any;
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  selectedTab: any;
  currentYear: any;
  navContext: any;
  userRoleLs: any;
  companyId: any;
  auditAilData: any;
  savedStatus: any;
  selectedStateCode: any;
  selectedStateName: any;
  submissionDocListData: any;
  selectedGstinDiv: any;
  maxDate = new Date();
  auditIntimLtrForm!: UntypedFormGroup;
  whfollowup: { label: string; value: boolean; }[];
  rmvLetterModalRef!: NgbModalRef;
  rmvDocListModalRef!: NgbModalRef;
  rmvSubmissionModalRef!: NgbModalRef;
  rmvDocSubListModalRef!: NgbModalRef;
  rmvConsultantModalRef!: NgbModalRef;


  /*save function declaration */
  isSubmitted: boolean = false;
  isauditAilData: boolean = true;
  isPhase2: boolean = false;
  isActionView: boolean = true;

  selectedFilling: any;
  selectedGstin: any;
  navData: any;
  savedAuditId: any;
  isSaved: boolean = false;
  isSaveBtn: boolean = false;
  isUpdateBtn: boolean = false;
  isUpdate: boolean = false;
  showonSave: boolean = false;
  isDisableIcons: boolean = false;
  /*save function declaration */
  /*upload function declaration */
  selectedField: any;
  selectedFile: any;
  fileUrl: any;
  filePath: any;
  matches: any;
  auditId: any;
  action: any;
  type: any;
  tab: any;
  savedData: any;
  formData: FormData = new FormData();
  designation = AUDITDESIGNATION;
  feesChargedTotal = 0;
  gstinOrPan: any;


  isUPformAdt1Adt3CopyLoc: boolean = true;
  isDWformAdt1Adt3CopyLoc: boolean = true;
  isformAdt1Adt3CopyLocFile: boolean = false;
  isformAdt1Adt3CopyLocText: boolean = true;

  isUPadditionalDataLoc: boolean = true;
  isDWadditionalDataLoc: boolean = true;
  isadditionalDataLocFile: boolean = false;
  isadditionalDataLocText: boolean = true;

  isUPdocsSubmittedLoc: boolean = true;
  isDWdocsSubmittedLoc: boolean = true;
  isdocsSubmittedLocFile: boolean = false;
  isdocsSubmittedLocText: boolean = true;

  isUPackReplyCopyLoc: boolean = true;
  isDWackReplyCopyLoc: boolean = true;
  isackReplyCopyLocFile: boolean = false;
  isackReplyCopyLocText: boolean = true;

  isUPfollowUpDocReqLoc: boolean = true;
  isDWfollowUpDocReqLoc: boolean = true;
  isfollowUpDocReqLocFile: boolean = false;
  isfollowUpDocReqLocText: boolean = true;

  isUPfollowUpLetterLoc: boolean = true;
  isDWfollowUpLetterLoc: boolean = true;
  isfollowUpLetterLocFile: boolean = false;
  isfollowUpLetterLocText: boolean = true;

  isUPfollowUpAckCopyLoc: boolean = true;
  isDWfollowUpAckCopyLoc: boolean = true;
  isfollowUpAckCopyLocFile: boolean = false;
  isfollowUpAckCopyLocText: boolean = true;

  isUPdocLoc: boolean = true;
  isDWdocLoc: boolean = true;
  isdocLocFile: boolean = false;
  isdocLocText: boolean = true;

  isconsultantattachmentsLocFile = false;
  isconsultantattachmentsLocText = true;
  isconsultantDWattachmentsLocText = true;
  isconsultantUPattachmentsLocText = true;

  consultantPanleExpansion: boolean = false;
  oldConsulatntValues: any = [];
  olditemListValues: any = [];
  selectedCompanyId: any;
  duplicateData:any;
  isToggleDropdown = true;
  /*upload function declaration */

  constructor(
    private fBuild: UntypedFormBuilder,
    private GS: GetterSetterService,
    private auditService: AuditService,
    private toaster: ToasterService,
    private activeSnapShot: ActivatedRoute,
    private commonServ: CommonService,
    private modalService: NgbModal,
    private router: Router,
    private inrService: InrService,
    private http: HttpClient
  ) {
    this.whfollowup = AUDIT_WHFOLLOWUP;
    this.auditId = this.activeSnapShot.snapshot.params.auditId;
    this.action = this.activeSnapShot.snapshot.params.action;
    this.type = this.activeSnapShot.snapshot.params.type;
    this.tab = this.activeSnapShot.snapshot.params.tab;
  }

  ngOnInit() {
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.navData = JSON.parse(this.GS.getNavContextData() || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.selectedGstinDiv = this.selectedFilling.gstinDiv;
    this.navContext = this.commonServ.getNavContext();
    this.selectedCompanyId = this.GS.getSetCompanyId();
    this.selectedTab = 'tab1';
    this.setDate();
    this.setStateAutoPop();
    this.personResponsibleData();
    if (this.auditId || this.parentCaseId) {
      this.isPhase2 = true;
      this.initializeData();
    }
    if (this.action === 'create') {
      this.isSaveBtn = true;
      this.isActionView = true;
      this.isDisableIcons = true;
      this.isformAdt1Adt3CopyLocFile = true;
      this.isformAdt1Adt3CopyLocText = false;
      this.isUPformAdt1Adt3CopyLoc = false;

      this.isadditionalDataLocFile = true;
      this.isadditionalDataLocText = false;
      this.isUPadditionalDataLoc = false;

      this.isdocsSubmittedLocFile = true;
      this.isdocsSubmittedLocText = false;
      this.isUPdocsSubmittedLoc = false;

      this.isackReplyCopyLocFile = true;
      this.isackReplyCopyLocText = false;
      this.isUPackReplyCopyLoc = false;

      this.isfollowUpDocReqLocFile = true;
      this.isfollowUpDocReqLocText = false;
      this.isUPfollowUpDocReqLoc = false;

      this.isfollowUpLetterLocFile = true;
      this.isfollowUpLetterLocText = false;
      this.isUPfollowUpLetterLoc = false;

      this.isfollowUpAckCopyLocFile = true;
      this.isfollowUpAckCopyLocText = false;
      this.isUPfollowUpAckCopyLoc = false;

      // this.isdocLocFile = true;
      // this.isdocLocText = false;
      // this.isUPdocLoc = false;

    } else if (this.action === 'view') {
      this.isSaved = true;
      this.isPhase2 = true;
      this.isDisableIcons = false;
      this.isActionView = false;
      // this.isUpdate = true;


      this.isUPformAdt1Adt3CopyLoc = false;
      this.isDWformAdt1Adt3CopyLoc = true;

      this.isUPadditionalDataLoc = false;
      this.isDWadditionalDataLoc = true;

      this.isUPdocsSubmittedLoc = false;
      this.isDWdocsSubmittedLoc = true;

      this.isUPackReplyCopyLoc = false;
      this.isDWackReplyCopyLoc = true;

      this.isUPfollowUpDocReqLoc = false;
      this.isDWfollowUpDocReqLoc = true;

      this.isUPfollowUpLetterLoc = false;
      this.isDWfollowUpLetterLoc = true;

      this.isUPfollowUpAckCopyLoc = false;
      this.isDWfollowUpAckCopyLoc = true;

      // this.isUPdocLoc = false;
      // this.isDWdocLoc = true;
    } else if (this.action === 'update') {
      this.isUpdateBtn = true;
      this.isDisableIcons = true;
      // this.isUpdate = true;
      this.isSaved = true;
      this.isActionView = true;
      this.isUPformAdt1Adt3CopyLoc = true;
      this.isUPadditionalDataLoc = true;
      this.isUPdocsSubmittedLoc = true;
      this.isUPackReplyCopyLoc = true;
      this.isUPfollowUpDocReqLoc = true;
      this.isUPfollowUpLetterLoc = true;
      this.isUPfollowUpAckCopyLoc = true;
      this.isUPdocLoc = true;

    }
    this.formInitialize();
    const userId: any = sessionStorage.getItem('user');
    this.auditIntimLtrForm.controls.personResponsible.patchValue(userId);
  }

  tabChanged(tab: any) {
    this.selectedTab = tab;
    this.auditIntimLtrForm.patchValue(this.auditIntimLtrForm.value);
  }
  formInitialize() {
    this.auditIntimLtrForm = this.fBuild.group({
      companyId: [this.selectedCompanyId],
      auditId: [this.auditAilData?.auditId],
      gstin: [this.auditAilData?.gstin ? this.auditAilData?.gstin : this.selectedGstin],
      // gstin: [this.selectedGstin],
      gstinDiv: [this.selectedGstinDiv],
      state: [this.selectedStateName],
      personResponsible: [this.auditAilData?.personResponsible, [Validators.required]],
      consultant: [this.auditAilData?.consultant],
      userName: [this.auditAilData?.userName],
      updatedOn: [this.auditAilData?.updatedOn],
      periodFromDate: [this.auditAilData?.periodFromDate, [Validators.required]],
      periodToDate: [this.auditAilData?.periodToDate, [Validators.required]],
      internalRemarks: [this.auditAilData?.internalRemarks],
      itemList: new UntypedFormArray([]),
      consultantCharges: new UntypedFormArray([]),
      //Reply to Audit Intimnation letter
      nameOfOfficer: new UntypedFormControl(this.auditAilData?.nameOfOfficer, []),
      rank: new UntypedFormControl(this.auditAilData?.rank, []),
      phoneNo: new UntypedFormControl(this.auditAilData?.phoneNo, []),
      emailId: new UntypedFormControl(this.auditAilData?.emailId, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]),
      address: new UntypedFormControl(this.auditAilData?.address, []),
      otherDetails: new UntypedFormControl(this.auditAilData?.otherDetails, []),
      additionalDataLoc: [this.auditAilData?.additionalDataLoc],
      replyDate: [this.auditAilData?.replyDate],
      docsSubmittedLoc: [this.auditAilData?.docsSubmittedLoc],
      findingLetterIssued: [this.auditAilData?.findingLetterIssued],
      status: [this.auditAilData?.status],

    })
    if ((this.auditIntimLtrForm.value.itemList?.length === 0) && !(this.auditId)) {
      this.addALetter();
    }

    this.auditIntimLtrForm.controls['itemList']
      .valueChanges
      .subscribe(selectedValue => {
        this.olditemListValues = this.auditIntimLtrForm.value['itemList'];
      });

    if ((this.auditIntimLtrForm.value.consultantCharges?.length === 0) && !(this.auditId)) {
    
      this.addConsultantInv();
    }
    // this.auditIntimLtrForm.controls['consultantCharges'].valueChanges.subscribe(selectedValue => {
    //     this.oldConsulatntValues = this.auditIntimLtrForm.value['consultantCharges'];
    //   });


  }
  get formGrp() { return this.auditIntimLtrForm.controls; }


  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }
  getFormAdt1Adt3CopyLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('formAdt1Adt3CopyLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }

  getformAdt1Adt3CopyLoc(formAdt1Adt3CopyLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (formAdt1Adt3CopyLocGrp.get('formAdt1Adt3CopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  createformAdt1Adt3CopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      fileName: new UntypedFormControl(not ? not.fileName : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)])
    })
  }
  openformAdt1Adt3CopyLocModel(content: any, i: any) {
    this.addformAdt1Adt3CopyLoc(i, 0);
    this.modalService.open(content);
  }
  addformAdt1Adt3CopyLoc(j: any, i: any) {
    let itemListArray = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    let formAdt1Adt3CopyLocArray = itemListArray.controls[j].get('formAdt1Adt3CopyLoc') as UntypedFormArray;

    if (formAdt1Adt3CopyLocArray.length > 0) {
      let length = formAdt1Adt3CopyLocArray.length
      while (i < 5 - length) {
        formAdt1Adt3CopyLocArray.push(this.createformAdt1Adt3CopyLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        formAdt1Adt3CopyLocArray.push(this.createformAdt1Adt3CopyLoc());
        i++;
      }
    }
  }
  uploadformAdt1Adt3CopyLoc1(itemListIndex: any, formAdt1Adt3CopyLocIndex: any) {
    this.isformAdt1Adt3CopyLocFile = true;
    this.isformAdt1Adt3CopyLocText = false;
    this.isDWformAdt1Adt3CopyLoc = false;
    this.isUPformAdt1Adt3CopyLoc = false;

    let itemListsArray = this.auditIntimLtrForm.controls.itemList as UntypedFormGroup;
    let formAdt1Adt3CopyLocArray = itemListsArray.controls[itemListIndex].get('formAdt1Adt3CopyLoc') as UntypedFormArray;
    if (formAdt1Adt3CopyLocArray.controls.length > 0) {
      for (let index = 0; index < formAdt1Adt3CopyLocArray.controls.length; index++) {
        if (index === formAdt1Adt3CopyLocIndex) {
          let itemListuploadControl = formAdt1Adt3CopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteformAdt1Adt3CopyLoc(itemListIndex: any, formAdt1Adt3CopyLocIndex: any) {
    let itemListsArray = this.auditIntimLtrForm.controls.itemList as UntypedFormGroup;
    let formAdt1Adt3CopyLocArray = itemListsArray.controls[itemListIndex].get('formAdt1Adt3CopyLoc') as UntypedFormArray;
    if (formAdt1Adt3CopyLocArray.controls.length > 0) {
      for (let index = 0; index < formAdt1Adt3CopyLocArray.controls.length; index++) {
        if (index === formAdt1Adt3CopyLocIndex) {
          let itemListuploadControl = formAdt1Adt3CopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  getConsultOtherAttachmentLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.auditIntimLtrForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('otherAttachmentLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getconsultotherAttachmentLoc(otherAttachmentLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (otherAttachmentLocGrp.get('otherAttachmentLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  createconsultotherAttachmentLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      fileName: new UntypedFormControl(not ? not.fileName : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)])
    })
  }
  openconsultotherAttachmentLoc(content: any, i: any) {
    this.addconsultotherAttachmentLoc(i, 0);
    this.modalService.open(content);
  }
  addconsultotherAttachmentLoc(j: any, i: any) {
    let consultantChargesArray = this.auditIntimLtrForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.length > 0) {
      let length = otherAttachmentLocFrmArray.length
      while (i < 5 - length) {
        otherAttachmentLocFrmArray.push(this.createconsultotherAttachmentLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        otherAttachmentLocFrmArray.push(this.createconsultotherAttachmentLoc());
        i++;
      }
    }
  }
  uploadconsultotherAttachmentLoc(consultantChargesIndex: any, otherAttachmentLocIndex: any) {
    let consultantChargesFrmArray = this.auditIntimLtrForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesFrmArray.controls[consultantChargesIndex].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachmentLocFrmArray.controls.length; index++) {
        if (index === otherAttachmentLocIndex) {
          let itemListuploadControl = otherAttachmentLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteconsultotherAttachmentLoc(consultantChargesIndex: any, otherAttachmentLocIndex: any) {
    let consultantChargesFrmArray = this.auditIntimLtrForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesFrmArray.controls[consultantChargesIndex].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachmentLocFrmArray.controls.length; index++) {
        if (index === otherAttachmentLocIndex) {
          let itemListuploadControl = otherAttachmentLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  getDocLocLength(noticeFormIndex: number, submissionFormIndex: number, docSubmissionsFormIndex: number): number {
    const itemListFrmArray = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    const submissionFrmArray = itemListFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const ackCopyLocArray = submissionFrmArray.at(submissionFormIndex).get('docSubmissions') as UntypedFormArray;
    const docLocArray = ackCopyLocArray.at(docSubmissionsFormIndex).get('docLoc') as UntypedFormArray;
    if (docLocArray && docLocArray.length > -1) {
      let countOfackCopyLoc = 0;
      for (let i = 0; i < docLocArray.length; i++) {
        const singleAckLoc = docLocArray.value[i];
        if (singleAckLoc.fileLoc != '' && singleAckLoc.fileLoc != null) {
          countOfackCopyLoc++;
        }
      }
      return countOfackCopyLoc;
    }
    return 0;
  }

  getdocLoc(docLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (docLocGrp.get('docLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createdocLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      fileName: new UntypedFormControl(not ? not.fileName : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)])
    })
  }
  adddocLoc(j: any, i: any) {
    let itemFrmArray: any = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    for (let index = 0; index < itemFrmArray.length; index++) {
      const element = itemFrmArray.controls[index];

      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1Array = submissionArray[i].controls.docSubmissions;
        for (let j = 0; j < element1Array.length; j++) {
          const docEle = element1Array.controls[j].controls.docLoc;
          if (docEle.controls.length === 0) {
            for (let k = 0; k < 5; k++) {
              docEle.push(this.createdocLoc());
            }
          }

        }

      }
    }

  }
  opendocLoc(content: any, i: any) {
    this.adddocLoc(i, 0);
    this.modalService.open(content);
  }

  uploadDocLoc(itemListIndex: any, docSubmissionIndex: any, submissionIndex: any, docLocIndex: any) {
    this.isdocLocFile = true;
    this.isdocLocText = false;
    this.isDWdocLoc = false;
    this.isUPdocLoc = false;
    let itemListsArray = this.auditIntimLtrForm.controls.itemList as UntypedFormGroup;
    let submissionsArray = itemListsArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let docSubmissionsArray = submissionsArray.controls[submissionIndex].get('docSubmissions') as UntypedFormArray
    let docLocArray = docSubmissionsArray.controls[docSubmissionIndex].get('docLoc') as UntypedFormArray
    if (docLocArray.controls.length > 0) {
      for (let index = 0; index < docLocArray.controls.length; index++) {
        if (index === docLocIndex) {
          let itemListuploadControl = docLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteDocLoc(itemListIndex: any, docSubmissionIndex: any, submissionIndex: any, docLocIndex: any) {
    let itemListsArray = this.auditIntimLtrForm.controls.itemList as UntypedFormGroup;
    let submissionsArray = itemListsArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let docSubmissionsArray = submissionsArray.controls[submissionIndex].get('docSubmissions') as UntypedFormArray
    let docLocArray = docSubmissionsArray.controls[docSubmissionIndex].get('docLoc') as UntypedFormArray

    if (docLocArray.controls.length > 0) {
      for (let index = 0; index < docLocArray.controls.length; index++) {
        if (index === docLocIndex) {
          let itemListuploadControl = docLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  p1validation() {
    var itemListArray = (this.auditIntimLtrForm.get('itemList') as UntypedFormArray)
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var itemListControl = itemListArray.controls[i] as UntypedFormGroup;
        if ((itemListControl.controls.formAdt1Adt3CopyLoc.value == null || itemListControl.controls.formAdt1Adt3CopyLoc.value === '') && (itemListControl.value.isActive)) {
          itemListControl.controls.formAdt1Adt3CopyLoc.setErrors({ 'required': true });
        } else {
          itemListControl.controls.formAdt1Adt3CopyLoc.setErrors(null);
        }
      }
    }

  }
  p2validation() {
    var itemListArray = this.auditIntimLtrForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var itemListIndex = 0; itemListIndex < itemListArray.controls.length; itemListIndex++) {
        var submissionsArray = itemListArray.controls[itemListIndex].get('submissions') as UntypedFormArray
        //var itemListControl = itemListArray.controls[0] as FormGroup;
        for (var submissionIndex = 0; submissionIndex < submissionsArray.controls.length; submissionIndex++) {
          var submissionContol = submissionsArray.controls[submissionIndex] as UntypedFormGroup
          if (submissionContol.controls.dateOfSubmission.value === null || submissionContol.controls.dateOfSubmission.value === '') {
            submissionContol.controls.dateOfSubmission.setErrors({ 'required': true });
          } else {
            submissionContol.controls.dateOfSubmission.setErrors(null);
          }
        }
      }
    }

    if (this.auditIntimLtrForm.value['findingLetterIssued'] === null) {
      this.auditIntimLtrForm.controls['findingLetterIssued'].setErrors({ 'required': true });
    }


  }
  consultantValidation() {
    let consultantArray = this.auditIntimLtrForm.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(issuesControl.controls.fees.value)
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (issuesControl.controls.firm.value === null || issuesControl.controls.firm.value === '') {
            issuesControl.controls.firm.setErrors({ 'required': true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }
  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  /* finding letter array */
  getauditLetter(): UntypedFormGroup[] {
    return (this.auditIntimLtrForm.get('itemList') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  initauditLetterGrp() {
    return new UntypedFormGroup(
      {
        letterRefNo: new UntypedFormControl(null, [Validators.required]),
        letterDate: new UntypedFormControl(null, [Validators.required]),
        dateOfReceiptOfLetter: new UntypedFormControl(null, []),
        internalDueDate: new UntypedFormControl(null, []),
        legalDueDate: new UntypedFormControl(null, [Validators.required]),
        din: new UntypedFormControl(null, []),
        docReqAsPerLetter: new UntypedFormArray([]),
        formAdt1Adt3CopyLoc: new UntypedFormArray([]),
        isActive: new UntypedFormControl(false),
        //phase 2

        submissions: new UntypedFormArray([]),
        //docName: new FormControl('', []),
      }
    )
  }

  addALetter() {
    let auditLetterGrp = this.initauditLetterGrp();
    (auditLetterGrp.get('docReqAsPerLetter') as UntypedFormArray).push(this.initDocListGrp());
    (auditLetterGrp.get('submissions') as UntypedFormArray).push(this.initSubmissionGrp());
    (this.auditIntimLtrForm.get('itemList') as UntypedFormArray).push(auditLetterGrp);

  }
  rmALetter(i: number) {
    (this.auditIntimLtrForm.get('itemList') as UntypedFormArray).removeAt(i);
    this.rmvLetterModalRef.close();
  }
  /*finding letter array */

  /*add doc req list */

  getDocList(auditLetterGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (auditLetterGrp.get('docReqAsPerLetter') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initDocListGrp() {
    return new UntypedFormGroup({
      // docReqAsPerLetter: new FormControl('', [Validators.required, Validators.pattern(/(.*[a-zA-Z]){3}/)]),
      docName: new UntypedFormControl(null, [Validators.required, Validators.pattern(/(.*[a-zA-Z]){3}/)]),
      remark: new UntypedFormControl(null),
    })
  }
  addDocList(j: any) {
    let itemListFrmArray = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    let docListFrmArray = itemListFrmArray.controls[j].get('docReqAsPerLetter') as UntypedFormArray;
    docListFrmArray.push(this.initDocListGrp());
  }

  rmDocList(i: number, k: number) {
    let itemListFrmArray = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    let docListFrmArray = itemListFrmArray.controls[i].get('docReqAsPerLetter') as UntypedFormArray;
    docListFrmArray.removeAt(k);
    this.rmvDocListModalRef.close();
  }
  /*add doc req list */
  /*add submission array row */
  getSubmission(auditLetterGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (auditLetterGrp.get('submissions') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initSubmissionGrp() {
    const submissionFormControl = this.fBuild.group({
      dateOfSubmission: new UntypedFormControl(null),
      personResponsible: new UntypedFormControl(this.auditAilData?.personResponsible),
      consultant: new UntypedFormControl(this.auditAilData?.consultant),
      docSubmissions: new UntypedFormArray([]),

    })
    if (this.action === 'create') {
      (submissionFormControl.get('docSubmissions') as UntypedFormArray).push(this.initSubItemListGrp());
    }
    return submissionFormControl;
  }
  addSubmission(j: any) {
    let itemListFrmArray = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    let submissionArray = itemListFrmArray.controls[j].get('submissions') as UntypedFormArray;
    submissionArray.push(this.initSubmissionGrp());
    this.addSubDocList(j);
  }
  addSubDocList(j: any) {
    this.submissionDocListData = this.auditIntimLtrForm.value.itemList;
    let itemListFrmArray = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    let submissionArray = itemListFrmArray.controls[j].get('submissions') as UntypedFormArray;
    if (this.submissionDocListData[j].docReqAsPerLetter.length > 0) {
      let count = this.auditIntimLtrForm.value.itemList[j].submissions.length - 1;
      this.submissionDocListData[j].docReqAsPerLetter.forEach((doc: any) => {
        let subItemListArray = submissionArray.controls[count].get('docSubmissions') as UntypedFormArray;
        subItemListArray.push(
          new UntypedFormGroup({
            docName: new UntypedFormControl(doc ? doc.docName : null),
            remark: new UntypedFormControl(doc ? doc.remark : null),
            docLoc: new UntypedFormArray([]),
          })
        );
      })
    }
  }

  rmSubmission(i: number, k: number) {
    let itemListFrmArray = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    let submissionArray = itemListFrmArray.controls[i].get('submissions') as UntypedFormArray;
    submissionArray.removeAt(k);
    this.rmvSubmissionModalRef.close();

  }

  /*add submission array row */

  // Consultant Charges Table

  consultantInvArr(): UntypedFormGroup[] {
    return (this.auditIntimLtrForm.get('consultantCharges') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  ConsultantCharges(consultants: any = null): UntypedFormGroup {
    return this.fBuild.group({
      firm: new UntypedFormControl(consultants ? consultants.firm : null, []),
      consultant: new UntypedFormControl(consultants ? consultants.consultant : null, []),
      natureOfWork: new UntypedFormControl(consultants ? consultants.natureOfWork : null, []),
      fees: new UntypedFormControl(consultants ? consultants.fees : null, []),
      otherAttachmentLoc: new UntypedFormArray([]),
      email: [consultants ? consultants.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [consultants ? consultants.contact : null]
    })
  }

  addConsultantInv() {
    let detailOfConsultant = this.ConsultantCharges();
    (this.auditIntimLtrForm.get('consultantCharges') as UntypedFormArray).push(detailOfConsultant);
    return false;
  }

  rmConsultantInv(i: number) {
    (this.auditIntimLtrForm.get('consultantCharges') as UntypedFormArray).removeAt(i);
    this.rmvConsultantModalRef.close();
    this.feesChargedChanged();
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (this.auditIntimLtrForm.get('consultantCharges') as UntypedFormArray).controls.forEach((c: any) => {
      const fees = typeof c.value.fees === 'string' ? c.value.fees.replace(/,/g, '') : c.value.fees;
      feesChargedTotal += Number(fees);
    });

    this.feesChargedTotal = feesChargedTotal;
  }

  openRmvconsultantPop(content: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }

  onKeyDownEvent(event: any, type: any) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      if (type === 'consultantCharges') {
        this.feesChargedChanged()
      }
    }
  }

  onPaste(event: any, id: any, tableName: any, index: any) {
    let keyCodeEvent = { keyCode: 8 }
    event.preventDefault();
    let value: any = Math.round(+event.clipboardData.getData('Text'));
    let data = this.auditIntimLtrForm.get(tableName)?.value;
    data[index][id] = value;
    this.auditIntimLtrForm.get(tableName)?.setValue(data)
    this.onKeyDownEvent(keyCodeEvent, tableName);
  }


  uploadoConsultantAttachmentsLoc(i: any) {
    var consultantArray = this.auditIntimLtrForm.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (var index = 0; index < consultantArray.controls.length; index++) {
        if (index === i) {
          var consultantControl = consultantArray.controls[index] as UntypedFormGroup;
          consultantControl.value['isOtherAttachmentLocDocUploadedClicked'] = true
        }
      }
    }
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLocText = true;
    this.isconsultantUPattachmentsLocText = false;
  }
  // consultant change functions
  deleteConsultantFile(i: any) {
    var consultantArray = this.auditIntimLtrForm.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (var index = 0; index < consultantArray.controls.length; index++) {
        if (index === i) {
          var consultantControl = consultantArray.controls[index] as UntypedFormGroup;
          consultantControl.value['isOtherAttachmentLocDocUploadedClicked'] = true
        }
      }
    }
  }


  /*add submission doclist array row */
  getSubItemList(submissionGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (submissionGrp.get('docSubmissions') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initSubItemListGrp() {
    return new UntypedFormGroup({
      docName: new UntypedFormControl(null),
      remark: new UntypedFormControl(null),
      docLoc: new UntypedFormArray([]),
    })
  }
  addSubItemList(i: any, j: any) {
    let itemListFrmArray = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    let submissionArray = itemListFrmArray.controls[i].get('submissions') as UntypedFormArray;
    let subItemListArray = submissionArray.controls[j].get('docSubmissions') as UntypedFormArray;
    subItemListArray.push(this.initSubItemListGrp());
    // const control = ((<FormArray>this.auditIntimLtrForm.controls['itemList']).at(i).get('submissions') as FormArray).at(j).get('docSubmissions') as FormArray;
    // control.push(this.initSubItemListGrp());
  }

  rmSubItemList(i: number, k: number, j: number) {
    let itemListFrmArray = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
    let submissionArray = itemListFrmArray.controls[i].get('submissions') as UntypedFormArray;
    let subItemListArray = submissionArray.controls[k].get('docSubmissions') as UntypedFormArray;
    subItemListArray.removeAt(j);
    this.rmvDocSubListModalRef.close();

  }



  deletedocLocFile(itemListIndex: any, docSubmissionIndex: any, submissionIndex: any) {
    let itemListsArray = this.auditIntimLtrForm.controls.itemList as UntypedFormGroup;
    let submissionsArray = itemListsArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let docSubmissionsArray = submissionsArray.controls[submissionIndex].get('docSubmissions') as UntypedFormArray
    if (docSubmissionsArray.controls.length > 0) {
      for (let index = 0; index < docSubmissionsArray.controls.length; index++) {
        if (index === docSubmissionIndex) {
          let itemListuploadControl = docSubmissionsArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  /*add submission doclist array row */

  saveIntimLetter() {
    this.isSubmitted = true;
    let data: any = {};
    if (this.auditIntimLtrForm.valid) {
      data = this.auditIntimLtrForm.value;
      data.state = this.selectedStateName;
      let formAdt1Adt3CopyFrmArray = (this.auditIntimLtrForm.get('itemList') as UntypedFormArray).controls;
      let element1;
      for (let i = 0; i < formAdt1Adt3CopyFrmArray.length; i++) {
        element1 = (formAdt1Adt3CopyFrmArray[i].get('formAdt1Adt3CopyLoc') as UntypedFormArray).controls;
      }
      let count = element1?.some((x: any) =>
        x.get('fileLoc')?.value != null);
      if (!count) {
        this.toaster.showError("Required Fields Are Empty");
        return;
      }
      let urlData = {
        type: 'ail',
        gstin: this.selectedGstin,
        companyId: this.companyId
      }
      let formAdt1Adt3CopyLocArray: any[] = [];
      for (let i = 0; i < this.auditIntimLtrForm.controls.itemList.value.length; i++) {
        formAdt1Adt3CopyLocArray = [];
        const elementPhCopyArray = this.auditIntimLtrForm.controls.itemList.value[i].formAdt1Adt3CopyLoc;

        for (let j = 0; j < elementPhCopyArray.length; j++) {
          const element = elementPhCopyArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            formAdt1Adt3CopyLocArray.push(element)
          }
        }
        this.auditIntimLtrForm.controls.itemList.value[i].formAdt1Adt3CopyLoc = formAdt1Adt3CopyLocArray;
      }
      this.inrFormattingRemoving()
      this.auditService.saveAilData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          this.showonSave = true;
          if (data.itemList.length > 0) {
            data.itemList.forEach((e: any) => {
              e.isActive = true;
            })
          }

          this.savedAuditId = response.response;
          this.saveCaseId.emit(this.savedAuditId);
          this.initData();
          this.isPhase2 = true;
          this.isSaved = true;
          this.isSaveBtn = false;
          this.isUpdateBtn = true;
          this.toaster.showSuccess(response.message);
          this.updateDocSubmission();
        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError("Required fields are empty");
    }
  }
  /*save function */
  weatherLetterIssued(event: any) {
    if (event.target.value === "true") {
      this.openauditFindingPop(this.auditFindingPop);
    }
  }
  openauditFindingPop(content: any) {
    this.modalService.open(content);
  }
  /*fetch data function */
  initializeData() {
    if (this.savedAuditId) {
      this.auditId = this.savedAuditId;
    } if (this.parentCaseId) {
      this.auditId = this.parentCaseId;
    }
    else {
      this.auditId = this.auditId;
    }
    if (this.auditId != null) {
      let model = {
        type: 'ail',
        auditId: this.auditId,
      }
      this.auditService.getAllCaseSummaryAil(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.auditAilData = response.response;
          if (this.auditAilData?.consultantCharges) {
            this.auditAilData.consultantCharges.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees))
            })
          }
          this.submissionDocListData = { ...this.auditAilData.itemList }; //... will populate itemlist in variable
          if (this.auditAilData.itemList) {
            if (this.auditAilData.itemList.length > 0) {
              this.auditAilData.itemList.forEach((element: any) => {
                element.isActive = true;
                if (element.submissions) {
                  if (element.submissions.length > 0) {
                    element.submissions.forEach((s: any) => {
                      if (s.docSubmissions) {
                        if (s.docSubmissions.length > 0) {
                          this.initDocSubmissions();
                        }
                      }
                    })
                  }
                }

              });
              this.initializedocList();
            }
          }
          if (this.auditAilData.consultantCharges && this.auditAilData.consultantCharges.length > 0) {
            this.initializeConsultantCharges();
            this.feesChargedChanged();
          }
          

          if (this.action === 'view') {
            this.isauditAilData = false;
          } else {
            this.isauditAilData = true;
          }
          this.auditAilData.state = this.selectedStateName
          // this.formInitialize();
          //this.toaster.showSuccess(response.message);
          this.isUpdate = true;
          this.isUpdateBtn = true;
          this.isSaveBtn = false;
        }
      });
    }
  }


  initializeItemList() {
    if (this.auditAilData.itemList) {
      this.auditAilData.itemList.forEach((item: any, index: any) => {
        let findingletterGrp: UntypedFormGroup = this.initauditLetterGrp();
        (this.auditIntimLtrForm.get('itemList') as UntypedFormArray).push(findingletterGrp);

        if (item.docReqAsPerLetter) {
          if (item.docReqAsPerLetter.length > 0) {
            item.docReqAsPerLetter.forEach((doc: any) => {
              this.addDocList(index);
            })
          }
        }

        if (item.formAdt1Adt3CopyLoc) {
          item.formAdt1Adt3CopyLoc.forEach((not: any) => {
            let formAdt1Adt3CopyLocGrp: UntypedFormGroup = this.createformAdt1Adt3CopyLoc(not);
            (findingletterGrp.get('formAdt1Adt3CopyLoc') as UntypedFormArray).push(formAdt1Adt3CopyLocGrp);
          });
        }
        if (item.submissions) {
          item.submissions.forEach((sub: any) => {
            let submissionGrp: UntypedFormGroup = this.initSubmissionGrp();
            (findingletterGrp.get('submissions') as UntypedFormArray).push(submissionGrp);
            if (sub.docSubmissions) {
              sub.docSubmissions.forEach((docSub: any) => {
                let subItemListGrp: UntypedFormGroup = this.initSubItemListGrp();
                (submissionGrp.get('docSubmissions') as UntypedFormArray).push(subItemListGrp);

                if (docSub.docLoc) {
                  docSub.docLoc.forEach((not: any) => {
                    let docLocFrmGrp: UntypedFormGroup = this.createdocLoc(not);
                    (subItemListGrp.get('docLoc') as UntypedFormArray).push(docLocFrmGrp);
                  });
                }

              });
            }

          });
        }

      });
    }
    this.auditIntimLtrForm.patchValue(this.auditAilData);
  }
  initializedocList() {
    if (this.auditAilData.itemList) {
      if (this.auditAilData.itemList.length > 0) {
        this.auditAilData.itemList.forEach((item: any) => {
          let tempDocVal: any = [];

          if (item.docReqAsPerLetter) {
            item.docReqAsPerLetter.forEach((doc: any) => {
              tempDocVal.push({ docName: doc, });
            })
          }
          item.docName = tempDocVal;
        })
      }
      this.initializeItemList();
    }
  }

  // consultant change functions
  initializeConsultantCharges() {
    if (this.auditAilData.consultantCharges) {
      this.auditAilData.consultantCharges.forEach((consultantCharges: any) => {
        let consultantGrp = this.ConsultantCharges(consultantCharges);
        (this.auditIntimLtrForm.get('consultantCharges') as UntypedFormArray).push(consultantGrp);


        if (consultantCharges.otherAttachmentLoc) {
          consultantCharges.otherAttachmentLoc.forEach((not: any) => {
            let consultantChargesFrmGrp: UntypedFormGroup = this.createconsultotherAttachmentLoc(not);
            (consultantGrp.get('otherAttachmentLoc') as UntypedFormArray).push(consultantChargesFrmGrp);
          });
        }

      });
      this.feesChargedChanged();
    }
  }


  initDocSubmissions() {
    if (this.auditAilData.itemList) {
      if (this.auditAilData.itemList.length > 0) {
        this.auditAilData.itemList.forEach((item: any, index: any) => {
          let findingletterGrp: UntypedFormGroup = this.initauditLetterGrp();
          if (Object.keys(item).length > 0) {

            item.submissions.forEach((sub: any) => {
              let submissionGrp: UntypedFormGroup = this.initSubmissionGrp();
              (findingletterGrp.get('submissions') as UntypedFormArray).push(submissionGrp);
              if (sub.docSubmissions) {
                sub.docSubmissions.forEach((docSub: any) => {
                  let subItemListGrp: UntypedFormGroup = this.initSubItemListGrp();
                  (submissionGrp.get('docSubmissions') as UntypedFormArray).push(subItemListGrp);

                  if (docSub.docLoc) {
                    docSub.docLoc.forEach((not: any) => {
                      let docLocFrmGrp: UntypedFormGroup = this.createdocLoc(not);
                      (subItemListGrp.get('docLoc') as UntypedFormArray).push(docLocFrmGrp);
                    });
                  }
                });
              }
            });
          } else {
            this.auditIntimLtrForm.patchValue(this.auditAilData);
          }
        });
        this.auditIntimLtrForm.patchValue(this.auditAilData);
      }
    }
  }

  initData(redirect = true) {
    if (this.savedAuditId) {
      this.auditId = this.savedAuditId;
    } else {
      this.auditId = this.auditId;
    }
    if (this.auditId != null) {
      let model = {
        type: 'ail',
        auditId: this.auditId,
      }
      this.auditService.getAllCaseSummaryAil(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.auditAilData = response.response;
          if (this.auditAilData.itemList) {
            if (this.auditAilData.itemList.length > 0) {
              this.auditAilData.itemList.forEach((element: any) => {
                element.isActive = true;
                let tempDocVal: any = [];
                if (element.docReqAsPerLetter.length > 0) {
                  element.docReqAsPerLetter.forEach((doc: any) => {
                    tempDocVal.push({
                      docName: doc,
                    });
                  })
                }
                element.docName = tempDocVal;
                if (element.submissions.length > 0) {
                  element.submissions.forEach((s: any) => {
                    if (s.docSubmissions.length > 0) {
                      this.initDocSubmissions();
                    }
                  });
                }
                // if (this.auditAilData.consultantCharges && this.auditAilData.consultantCharges.length > 0) {
                //   this.initializeConsultantCharges();
                //   this.feesChargedChanged();
                // }
              });
            }
          }
          this.isUpdate = true;
        }
      });
    }
  }


  // getCaseDataAil(auditId: number, action: string, type: string, tab: string) {
  //   this.router.navigate(['/createAudit', auditId, { action: action, type: type, tab: tab }]);
  // }
  /*fetch data function */
  doValidation(phaseTab: any) {
    if (phaseTab === 'p1') {
      this.p1validation();
    } else if (phaseTab === 'p2') {
      this.consultantValidation();
      this.p2validation();
    }
  }
  /*update letter*/
  updateData(phaseTab: any) {
    this.isSubmitted = true;

    let letterAttachmentMissing = false;
    this.auditIntimLtrForm.value.itemList.forEach((i: any) => {
      if (i.formAdt1Adt3CopyLoc == null || i.formAdt1Adt3CopyLoc == "") {
        letterAttachmentMissing = true;
      }
    })

    if (letterAttachmentMissing && phaseTab == 'p2') {
      this.toaster.showError("Please add missing attachment of Copy of Form ADT1/AD3 in Audit Intimation Letter");
      return;
    }

    let data: any = {};
    this.doValidation(phaseTab);
    if (this.auditIntimLtrForm.valid) {
      data = this.auditIntimLtrForm.value;
      data.state = this.selectedStateName;
      if (phaseTab === 'p1') {
        let formAdt1Adt3CopyFrmArray = (this.auditIntimLtrForm.get('itemList') as UntypedFormArray).controls;
        let element1;
        for (let i = 0; i < formAdt1Adt3CopyFrmArray.length; i++) {
          element1 = (formAdt1Adt3CopyFrmArray[i].get('formAdt1Adt3CopyLoc') as UntypedFormArray).controls;
        }
        let count = element1?.some((x: any) =>
          x.get('fileLoc')?.value != null || x.get('fileLoc')?.value != '');
        if (!count) {
          this.toaster.showError("Required Fields Are Empty");
          return;
        }
      }
      if (phaseTab === 'p2') {
        let itemListArray: any = this.auditIntimLtrForm.get('itemList') as UntypedFormArray;
        let element1;
        for (let index = 0; index < itemListArray.length; index++) {
          const element = itemListArray.controls[index];
          var submissionArray: any = element.controls.submissions.controls;

          for (let index = 0; index < submissionArray.length; index++) {
            const element = submissionArray[index];
            var docSubmissionsArray: any = element.controls.docSubmissions.controls;

            for (let i = 0; i < docSubmissionsArray.length; i++) {
              element1 = (docSubmissionsArray[i].get('docLoc') as UntypedFormArray).controls;
            }
          }
        }
        var count = element1?.some((x: any) =>
          x.get('fileLoc')?.value != null
        );
        if (!count) {
          this.toaster.showError("Required Fields Are Empty");
          return;
        }
      }

      if (this.savedAuditId) {
        data.auditId = this.savedAuditId;
      }
      if (this.savedStatus) {
        data.status = this.savedStatus;
      }
      let formVal = (cloneDeep(this.auditIntimLtrForm.value));
      if (formVal.itemList.length > 0) {
        formVal.itemList.forEach((e: any) => {
          e.isActive = true;
        });
        formVal.itemList.forEach((item: any) => {
          let tempDocVal: any = [];
          if (item.submissions.length > 0) {
            item.submissions.forEach((s: any) => {
              if (s.docSubmissions.length > 0) {
              }
            })
          }
          if (item.docReqAsPerLetter.length > 0) {
            item.docReqAsPerLetter.forEach((doc: any) => {
              tempDocVal.push(doc.docReqAsPerLetter)
            })
          }
          item.docReqAsPerLetter = tempDocVal;
        })
      }

      data = this.auditIntimLtrForm.value;
      formVal.state = this.selectedStateName;
      let urlData = {
        type: 'ail',
        gstin: this.selectedGstin,
      }
      formVal = data;
      let formAdt1Adt3CopyLocArray: any[] = [];
      for (let i = 0; i < this.auditIntimLtrForm.controls.itemList.value.length; i++) {
        formAdt1Adt3CopyLocArray = [];
        const elementPhCopyArray = this.auditIntimLtrForm.controls.itemList.value[i].formAdt1Adt3CopyLoc;

        for (let j = 0; j < elementPhCopyArray.length; j++) {
          const element = elementPhCopyArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            formAdt1Adt3CopyLocArray.push(element)
          }
        }
        this.auditIntimLtrForm.controls.itemList.value[i].formAdt1Adt3CopyLoc = formAdt1Adt3CopyLocArray;
      }
      let consultantChargesotherAttachmentLocArray: any[] = [];
      for (let i = 0; i < this.auditIntimLtrForm.controls.consultantCharges.value.length; i++) {
        consultantChargesotherAttachmentLocArray = [];
        const copyFormArray = this.auditIntimLtrForm.controls.consultantCharges.value[i].otherAttachmentLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            consultantChargesotherAttachmentLocArray.push(element)
          }
        }
        this.auditIntimLtrForm.controls.consultantCharges.value[i].otherAttachmentLoc = consultantChargesotherAttachmentLocArray;
      }
      let docLocArray: any[] = [];
      for (let i = 0; i < this.auditIntimLtrForm.controls.itemList.value.length; i++) {
        const submmissionArray = this.auditIntimLtrForm.controls.itemList.value[i].submissions;
        for (let k = 0; k < submmissionArray.length; k++) {
          //docLocArray = [];

          const elementArray = submmissionArray[k].docSubmissions

          for (let j = 0; j < elementArray.length; j++) {
            const element = elementArray[j].docLoc;

            const finalDocLoc = element;
            this.auditIntimLtrForm.controls.itemList.value[i].submissions[k].docSubmissions[j].docLoc = finalDocLoc;
          }
        }
      }
      this.inrFormattingRemoving();
      this.auditService.updateAilData(formVal, urlData).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          // active
          if (this.auditIntimLtrForm.value.itemList.length > 0) {
            this.auditIntimLtrForm.value.itemList.forEach((element: any) => {
              element.isActive = true;
            });
          }
          this.auditAilData.itemList = formVal.itemList;
          if (phaseTab === 'p1') {
            this.updateDocSubmission();
          }
          if (phaseTab === 'p2') {
            if (formVal.findingLetterIssued === true || formVal.findingLetterIssued === 'true') {
              this.auditService.setAccessTabData({ ailFlag: true, aflFlag: true, });
              this.moveToAFL.emit('');
            }
          }
          this.toaster.showSuccess(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }



  openTextBoxModal1(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('docReqAsPerLetter')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('docSubmissions') as UntypedFormArray).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  updateDocSubmission() {
    this.auditAilData.itemList.forEach((auditItemList: any, index: any) => {
      auditItemList.submissions.forEach((auditSubmission: any, auditSubmissionIndex: any) => {
        auditItemList.docReqAsPerLetter.forEach((d: any, i: any) => {
          if (!(((((this.auditIntimLtrForm.get('itemList') as UntypedFormArray)
            .controls[index] as UntypedFormGroup).controls.submissions as UntypedFormArray)
            .controls[auditSubmissionIndex].get('docSubmissions') as UntypedFormArray)).value[i]) {
            (((((this.auditIntimLtrForm.get('itemList') as UntypedFormArray)
              .controls[index] as UntypedFormGroup).controls.submissions as UntypedFormArray)
              .controls[auditSubmissionIndex].get('docSubmissions') as UntypedFormArray))
              .controls[i] = new UntypedFormGroup({
                docName: new UntypedFormControl(d ? d.docName : ' '),
                remark: new UntypedFormControl(d ? d.remark : ' '),
                docLoc: new UntypedFormArray([]),
              })
          }
        })
      })
    })
  }
  /*update letter*/
  /*upload file function */
  onFileSelect(event: any) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        // this.formData = new FormData();
        // this.formData.append('fname', this.selectedFile);
        var regExp = /\(([^)]+)\)/; //GET THE VALUE of (0) FROM itemList(0).letterLOC
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  onUpload(selectedfield: any) {
    if (this.navContext.entityType === 'FILING') {
      this.gstinOrPan = this.navContext.gstin;
    } else if (this.navContext.entityType === 'LEGAL') {
      this.gstinOrPan = this.navContext.pan;
    } else if (this.navContext.entityType === 'Business') {
      this.gstinOrPan = this.navContext.pan;
    }
    let urlData = {
      gstinOrPan: this.gstinOrPan,
      auditId: this.auditId ? this.auditId : this.savedAuditId,
      type: 'ail',
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.auditService.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        //this.fileUrl = response.url;
        //this.filePath = response.path;
        this.getFileUrl(response.response.url, response.response.path, selectedfield);
      } else {
      }
    })
  }
  // onUpload(selectedfield:any) {
  //   let requestBody: any = new FormData();
  //   requestBody.append('caseId', this.auditId ? this.auditId : this.savedAuditId);
  //   requestBody.append('type', 'ail');
  //   requestBody.append('field', selectedfield);
  //   requestBody.append('fmime', this.selectedFile.type);
  //   requestBody.append('fname', this.selectedFile.name);
  //   requestBody.append('file', this.selectedFile);
  //   this.auditService.uploadFile(requestBody).subscribe((response: any) => {
  //     if (response && response.status === 1) {
  //       this.setFormData(response.response, selectedfield)
  //     }
  //   })
  // }

  // setFormData(path: any, selectedfield: any) {
  getFileUrl(url: any, path: any, selectedfield: any) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      if (selectedfield) {
        switch (selectedfield) {
          case 'certIncorpLoc':
          case 'panCompLoc':
          case 'panDirLoc':
            //this.basicDetailValue[selectedfield] = path;
            break;
          default:
            let toupdate, pathToVeriabl;
            if (selectedfield.includes('basicDetails(0)')) {
              let rmBasicDet = selectedfield.replace('basicDetails(0).', ''); //replaced basicDetails(0) with blank 
              //toupdate = this.basicDetailValue;
              pathToVeriabl = this.createPath(rmBasicDet);
            } else {
              toupdate = this.auditIntimLtrForm.value;
              pathToVeriabl = this.createPath(selectedfield);
            }
            for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
              if (pathIndex !== pathToVeriabl.length - 1) {
                toupdate = toupdate[pathToVeriabl[pathIndex]];
              } else {
                toupdate[pathToVeriabl[pathIndex]] = path;
              }
            }
        }
        this.auditIntimLtrForm.patchValue(this.auditIntimLtrForm.value);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }


  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  /*upload function */

  /* get download file path function */
  getFileNameDta(fileUrl: any) {
    if (fileUrl && typeof (fileUrl) == 'string')
      return this.auditService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.auditService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        //this.downloadfileUrl() ;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }
  /* get download file path function */
  /*person responsible function */
  personResponsibleData() {
    let model: any = {};
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
      model.companyId = '';
    } else {
      model.companyId = this.selectedCompanyId;
      model.gstin = ''
    }
    this.auditService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });

  }
  deleteADT1ADT3File(i: any) {
    let itemListArray = this.auditIntimLtrForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (let index = 0; index < itemListArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = itemListArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.formAdt1Adt3CopyLoc.setValue(null);
          issuesControl.value['isSupportingDocLocDocUploadedClicked'] = true;
        }
      }
    }
  }
  /*person responsible function */
  uploadformAdt1Adt3CopyLoc() {
    this.isformAdt1Adt3CopyLocFile = true;
    this.isformAdt1Adt3CopyLocText = false;
    this.isDWformAdt1Adt3CopyLoc = false;
    this.isUPformAdt1Adt3CopyLoc = false;
  }
  uploadadditionalDataLoc() {
    this.isadditionalDataLocFile = true;
    this.isadditionalDataLocText = false;
    this.isDWadditionalDataLoc = false;
    this.isUPadditionalDataLoc = false;
  }
  uploaddocsSubmittedLoc() {
    this.isdocsSubmittedLocFile = true;
    this.isdocsSubmittedLocText = false;
    this.isDWdocsSubmittedLoc = false;
    this.isUPdocsSubmittedLoc = false;
  }
  uploadackReplyCopyLoc() {
    this.isackReplyCopyLocFile = true;
    this.isackReplyCopyLocText = false;
    this.isDWackReplyCopyLoc = false;
    this.isUPackReplyCopyLoc = false;
  }
  uploadfollowUpDocReqLoc() {
    this.isfollowUpDocReqLocFile = true;
    this.isfollowUpDocReqLocText = false;
    this.isDWfollowUpDocReqLoc = false;
    this.isUPfollowUpDocReqLoc = false;
  }
  uploadfollowUpLetterLoc() {
    this.isfollowUpLetterLocFile = true;
    this.isfollowUpLetterLocText = false;
    this.isDWfollowUpLetterLoc = false;
    this.isUPfollowUpLetterLoc = false;
  }
  uploadfollowUpAckCopyLoc() {
    this.isfollowUpAckCopyLocFile = true;
    this.isfollowUpAckCopyLocText = false;
    this.isDWfollowUpAckCopyLoc = false;
    this.isUPfollowUpAckCopyLoc = false;
  }
  // uploadDocLoc() {
  //   this.isdocLocFile = true;
  //   this.isdocLocText = false;
  //   this.isDWdocLoc = false;
  //   this.isUPdocLoc = false;
  // }
  setStateAutoPop() {
    if (this.selectedFilling.gstin) {
      this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
    }
    this.selectedStateName = this.commonServ.getStateName(this.selectedStateCode);

  }
  openRmvLetterPop(content: any, i: any) {
    this.rmvLetterModalRef = this.modalService.open(content);
  }
  openRmDocListPop(content: any, i: any) {
    this.rmvDocListModalRef = this.modalService.open(content);
  }
  openRmvSubmissionPop(content: any, i: any) {
    this.rmvSubmissionModalRef = this.modalService.open(content);
  }
  openRmvDocSubListPop(content: any, i: any) {
    this.rmvDocSubListModalRef = this.modalService.open(content);
  }
  formatTableValues() {
    const consultantChargesArray = this.auditIntimLtrForm.controls.consultantCharges as UntypedFormArray
    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (var index = 0; index < consultantChargesArray.controls.length; index++) {
        const consultantChargesControl = consultantChargesArray.controls[index] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(this.inrService.formatCurrency(consultantChargesControl.value.fees))
      }
    }
  }

  inrFormattingRemoving() {
    const data = this.auditIntimLtrForm.value;
    //phase 2 form
    if (data.consultantCharges && data.consultantCharges.length) {
      data.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
    }
  }

  handlePaste(event: any) {
    this.inrService.handlePaste(event)
  }

  getperiodToValue(content: any){
    if(this.action === "create"){
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.module = "GST",
    model.companyId = localStorage.getItem('selectedId'),
    model.role = "duplicateCheck",
    model.gstin = this.selectedGstin,
    model.module="GST",
    model.criterias= [
      {
          "p": "periodFromDate",
          "o": "between",
          "v1": this.auditIntimLtrForm.value.periodFromDate,
          "v2":  this.auditIntimLtrForm.value.periodToDate,
      },{
          "p": "periodToDate",
          "o": "between",
          "v1": this.auditIntimLtrForm.value.periodFromDate,
          "v2": this.auditIntimLtrForm.value.periodToDate,
      }
  ]
  this.auditService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.response.length === 0) {
        this.modalService.dismissAll();
      }
      else{
        this.duplicateData = response?.response;
       this.modalService.open(content)
      }
      
    });
  }
  }
  
  goToSummary(){
    this.router.navigate(['/auditSummary']);
    this.modalService.dismissAll();
  }
}
