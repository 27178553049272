<!-- <span class="nav-data" *ngIf="auditAilData"><span class="text-data"> Last Updated By : </span>{{auditAilData.updatedBy}} &nbsp; <span  class="text-data"> On : </span> {{auditAilData.updatedOn}} </span> -->
<div class="row">
  <div class="col-md-4">
    <ul class="nav nav-tabs nav nav-tabs iris-gst-display-flex" role="tablist">
      <li class="nav-item">
        <a class="nav-link" id="adtLetter-tab" data-toggle="tab" (click)="tabChanged('tab1')"
          [ngClass]="(selectedTab === 'tab1') ? 'active': ''" role="tab" aria-controls="adtLetter-1"
          aria-selected="true">Audit letter</a>
      </li>
      <li class="nav-item" [ngClass]="{'disabled': !isPhase2 }">
        <a class="nav-link" id="liasDept-tab" data-toggle="tab" (click)="tabChanged('tab2')"
          [ngClass]="(selectedTab === 'tab2') ? 'active': ''" role="tab" aria-controls="liasDept-1"
          aria-selected="false">Reply to Audit Letter </a>
      </li>
    </ul>
  </div>
  <div class="col-md-4">
    <div class="textCenter pt10">
      <span class="infoVal fs16" *ngIf="isUpdate" title="Case ID">{{auditAilData.auditId}}</span>
    </div>
  </div>
  <div class="col-md-4">
    <span class="computationBtn" *ngIf="auditAilData">
      <span class="nav-data" *ngIf="auditAilData"><span class="text-data"> Last Updated By :
        </span>{{auditAilData.userName}} &nbsp;
        <span class="text-data"> On : </span> {{auditAilData.updatedOn}} </span>
    </span>
  </div>
</div>

<div class="tab-content nopadding">
  <form [formGroup]="auditIntimLtrForm">
    <div class="tab-pane fade show active" id="adtLetter-1" role="tabpanel" aria-labelledby="adtLetter-tab">
      <div class="card borderOrange" [hidden]="(selectedTab === 'tab2')" [ngClass]="{'bgGray': (action == 'view')} ">
        <div class="card-body">
          <div class="row">
            <div class="col-md-3" *ngIf="isUpdate">
              <div class="form-group">
                <label for="exampleInputPassword1">Audit ID </label>
                <input type="text" class="form-control" id="auditId" readonly formControlName="auditId">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="exampleInputPassword1">GSTIN <span class="mandate"><i
                      class="fa fa-asterisk"></i></span></label>
                <input type="text" class="form-control" id="" readonly placeholder="" formControlName="gstin">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="exampleInputPassword1">State <span class="mandate"><i
                      class="fa fa-asterisk"></i></span></label>
                <input type="text" class="form-control" id="" readonly formControlName="state">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label for="exampleInputPassword1">Person responsible <span class="mandate"><i
                      class="fa fa-asterisk"></i></span></label>
                <!-- <select class="form-control form-select" id="personResponsible"
                  *ngIf="" formControlName="personResponsible"
                  [ngClass]="{ 'errorBorder': isSubmitted && formGrp.personResponsible.errors }">
                  <option [value]="null">-- Select --</option>
                  <ng-container *ngFor="let obj of userRoleLs">
                    <option [value]="obj.userName">{{obj.userName}}</option>
                  </ng-container>
                 </select>
                <input type="text" class="form-control"
                 placeholder="" formControlName="personResponsible"> -->

                 <div *ngIf="isToggleDropdown && action==='update' || action==='view'" class="input-group">
                  <input type="text" class="form-control" placeholder="" formControlName="personResponsible" readonly>
                  <button *ngIf="isToggleDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleDropdown()">
                    <i class="fa fa-pencil"></i>
                  </button>
                  </div>
        
                  <select class="form-control form-select" id="personResponsible"
                  *ngIf="!isToggleDropdown || action==='create'" formControlName="personResponsible"
                  [ngClass]="{ 'errorBorder': isSubmitted && formGrp.personResponsible.errors }">
                  <option [value]="null">-- Select --</option>
                  <ng-container *ngFor="let obj of userRoleLs">
                    <option [value]="obj.userName">{{obj.userName}}</option>
                  </ng-container>
                 </select>
                  
                <div *ngIf="isSubmitted && formGrp.personResponsible.errors">
                  <span class="text-danger" *ngIf="formGrp.personResponsible.errors.required"> Required
                  </span>
                </div>
              </div>
            </div>

            <!-- <div class="col-md-3">
              <div class="form-group">
                <label for="exampleInputPassword1">Consultant</label>
                <input type="text" class="form-control" id="" placeholder="" formControlName="consultant">
              </div>
            </div> -->

            <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create')">
              <div class="form-group">
                <label for="exampleInputPassword1">Consultant</label>
            
                <!-- For Consultant Role: Create, View, and Update -->
                <div *ngIf="roleName === 'Consultant' && action === 'create'">
                  <input 
                    type="text" 
                    class="form-control" 
                    placeholder="Consultant Name" 
                    formControlName="consultant" 
                    [readOnly]="action === 'create'">
                </div>
            
                <!-- For Non-Consultant Role -->
                <div *ngIf="roleName !== 'Consultant'">
                  <div *ngIf="isToggleConsultantDropdown && (action === 'update' || action === 'view')" class="input-group">
                    <input 
                      type="text" 
                      class="form-control" 
                      placeholder="" 
                      formControlName="consultant" 
                      readonly>
                    <button 
                      *ngIf="isToggleConsultantDropdown && action === 'update'" 
                      class="btn btn-outline-secondary" 
                      type="button" 
                      (click)="onClickToggleConsultantDropdown('p1')">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>
            
                  <select 
                    class="form-control form-select" 
                    id="consultant" 
                    *ngIf="!isToggleConsultantDropdown || action === 'create'" 
                    formControlName="consultant">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of consultantList">
                      <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Period From <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                  <p-calendar class="form-control" formControlName="periodFromDate" [monthNavigator]="true"
                    [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [maxDate]="maxDate"
                    [showIcon]="true" dataType="string"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.periodFromDate.errors }">
                  </p-calendar>
                </div>
                <div *ngIf="isSubmitted && formGrp.periodFromDate.errors">
                  <span class="text-danger" *ngIf="formGrp.periodFromDate.errors.required"> Required
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Period To <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                  <p-calendar class="form-control" formControlName="periodToDate" [monthNavigator]="true"
                    [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [maxDate]="maxDate"
                    [showIcon]="true" dataType="string"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.periodToDate.errors }" (onSelect)="getperiodToValue(periodToPop)">
                  </p-calendar>
                </div>
                <div *ngIf="isSubmitted && formGrp.periodToDate.errors">
                  <span class="text-danger" *ngIf="formGrp.periodToDate.errors.required"> Required
                  </span>
                </div>
                <ng-template #periodToPop let-modal>
                  <div class="modal-header" style="border: none; padding-bottom: 15px; padding-top:9px" >
                    <button type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                    </button>
                  </div>
                  <div class="col-md-12" >
                    <div class="table-responsive">
                      <p class="title-data">Following cases are already exist for same selected period, <span style="font-weight: bold;">Do you want to still proceed?</span></p>
                      <table>
                        <thead>
                          <tr class="bg-primary text-dblue">
                            <th class="sl-no-cla">Sr No</th>
                            <th class="clas">Case id</th>
                            <th class="clas">Financial Year</th>
                            <th class="clas">Period From</th>
                            <th class="clas">Period to</th>
                            <th class="clas">Forum</th>
                            <th>Person Responsible</th>
                            <th class="ac-no-clas">Total Demand(₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let obj of duplicateData  let i = index">
                            <td class="sl-no-cla">{{i + 1}}</td>
                            <td class="clas">{{obj.auditId}}</td>
                            <td class="clas">{{obj.fy}}</td>
                            <td class="clas">{{obj.periodFromDate}}</td>
                            <td class="clas">{{obj.periodToDate}}</td>
                            <td class="clas">{{obj.forum}}</td>
                            <td>{{obj.personResponsible}}</td>
                            <td class="ac-no-clas" style="text-align: right;">{{obj.demandAmount | INR: 0}} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="modal-footer" style="border: none; padding-top: 5px;" >
                    <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                    (click)="modal.close('No click')">Yes</button>
                      <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                      (click)="goToSummary()">No</button>
                  </div>
                </ng-template>
              </div>
            </div>
            <div class="col-md-12">
              <button class="btn btn-outline-primary" (click)="addALetter()">Add letter</button>
            </div>
          </div>
          <div id="auditletter{{i}}" class="mt-2" *ngFor="let auditLetterGrp of getauditLetter(); let i=index"
            [formGroup]="auditLetterGrp">
            <div class="card border-bottom">
              <div class="card-header" role="tab" id="auditletter-1">
                <h6 class="mb-0">
                  <a data-toggle="collapse" href="#auditletter-1" aria-expanded="true" aria-controls="auditletter-1">
                    Audit Letter {{i+1}}</a>
                </h6>
              </div>
              <div id="auditletter-1" class="collapse show" role="tabpanel" aria-labelledby="auditletter-1"
                data-parent="#accordion" [ngClass]="{'bgGray': (action == 'view')} ">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Letter Reference No. <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                        <input type="text" class="form-control" id="" formControlName="letterRefNo"
                          [ngClass]="{ 'errorBorder': isSubmitted && auditLetterGrp.get('letterRefNo')?.errors  }">
                        <div *ngIf="isSubmitted && auditLetterGrp.get('letterRefNo')?.errors">
                          <span class="text-danger" *ngIf="auditLetterGrp.get('letterRefNo')?.errors?.required ">
                            Required
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="exampleInputPassword1">DIN</label>
                        <input type="text" class="form-control" id="din1" placeholder="" formControlName="din">
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="exampleInputPassword1">Date of letter <span class="mandate"><i
                              class="fa fa-asterisk"></i></span></label>
                        <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                          <p-calendar class="form-control" formControlName="letterDate" [monthNavigator]="true"
                            [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                            [showIcon]="true" dataType="string" [maxDate]="maxDate"
                            [ngClass]="{ 'errorBorder': isSubmitted && auditLetterGrp.get('letterDate')?.errors }">
                          </p-calendar>
                          <div *ngIf="isSubmitted && auditLetterGrp.get('letterDate')?.errors">
                            <span class="text-danger dateError"
                              *ngIf="auditLetterGrp.get('letterDate')?.errors?.required">
                              Required
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label for="exampleInputPassword1">Date of receipt of letter </label>
                        <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                          <p-calendar class="form-control" formControlName="dateOfReceiptOfLetter"
                            [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                            dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate">
                          </p-calendar>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Internal Due Date</label>
                        <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                          <p-calendar class="form-control" formControlName="internalDueDate" [monthNavigator]="true"
                            [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                            [showIcon]="true" dataType="string">
                          </p-calendar>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group">
                        <label>Legal Due Date <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                        <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                          <p-calendar class="form-control" formControlName="legalDueDate" [monthNavigator]="true"
                            [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                            [showIcon]="true" dataType="string"
                            [ngClass]="{ 'errorBorder': isSubmitted && auditLetterGrp.get('legalDueDate')?.errors }">
                          </p-calendar>
                          <div *ngIf="isSubmitted && auditLetterGrp.get('legalDueDate')?.errors">
                            <span class="text-danger dateError"
                              *ngIf="auditLetterGrp.get('legalDueDate')?.errors?.required">
                              Required
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="col-md-12">
                      <label>List of documents required <a class="addExtLetter" (click)="addDocList(i)"
                          *ngIf="isActionView"><i class="fa fa-plus"></i></a></label>
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr class="bgGred">
                              <th style="width: 500px;">Document Name <span class="mandate"><i class="fa fa-asterisk"
                                    style="margin-top: 41px;"></i></span></th>
                              <th style="width: 500px;">Remark</th>
                              <th>Remove</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let docListGrp of getDocList(auditLetterGrp); let k=index"
                              [formGroup]="docListGrp" style="margin-top: 20px;">
                              <td>
                                <input type="text" class="form-control mb-1" id="" formControlName="docName"
                                  [ngClass]="{ 'errorBorder': isSubmitted && docListGrp.get('docName')?.errors }">
                                <div *ngIf="isSubmitted && docListGrp.get('docName')?.errors">
                                  <span class="text-danger dateError ml-2"
                                    *ngIf="docListGrp.get('docName')?.errors?.required" style="margin-top:70px;">
                                    Required
                                  </span>
                                  <span class="text-danger dateError ml-2"
                                    *ngIf="docListGrp.get('docName')?.errors?.pattern" style="margin-top:70px;">
                                    Enter atleast 3 characters
                                  </span>
                                </div>
                              </td>

                              <td>
                                <input type="text" class="form-control" formControlName="remark"
                                  [title]="docListGrp.value.remark ? docListGrp.value.remark:''"
                                  (click)="openTextBoxModal1('Remark', 'remark',auditLetterGrp,k)">
                              </td>

                              <td>
                                <a class="addExtLetter" (click)="openRmDocListPop(rmDocListDiv, i)"
                                  *ngIf="k !=0 && isActionView"><i class="fa fa-minus"></i></a>
                              </td>
                              <ng-template #rmDocListDiv let-modal>
                                <div class="modal-header">
                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <p>Are you sure, you want to remove?</p>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="rmDocList(i, k)">Remove</button>
                                </div>
                              </ng-template>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group">
                        <div class="col-md-3">
                          <label for="exampleInputPassword1">Copy of Form ADT1/ ADT3 <span class="mandate"><i
                                class="fa fa-asterisk"></i></span>
                          </label>
                          <input type="file" class="form-control file-upload-default" #file>
                          <div class="attachment-block"
                            [ngClass]="{'errorBorder': isSubmitted && getFormAdt1Adt3CopyLength(i) === 0 }">
                            <span (click)="openformAdt1Adt3CopyLocModel(phCopyLoc,i)" style="margin-left: 5px;">
                              <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                title="Attach file"></i></span>
                            <span *ngIf="getFormAdt1Adt3CopyLength(i) != 0"> {{getFormAdt1Adt3CopyLength(i)}}
                              Attachment</span>
                          </div>
                          <div *ngIf="isSubmitted && auditLetterGrp.get('formAdt1Adt3CopyLoc')?.value.length === 0">
                            <span class="text-danger">Required</span>
                          </div>
                          <div>
                            <ng-template #phCopyLoc let-modal>
                              <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                <button type="button" class="close" aria-label="Close"
                                  (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                              </div>
                              <div class="col-md-12">
                                <label> Attachment</label>
                                <div class="table-responsive">
                                  <table class="table table-bordered tablepop">
                                    <thead>
                                      <tr class="bgGred">
                                        <th>Upload</th>
                                        <th style="width:50%;">Remark</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        *ngFor="let formAdt1Adt3CopyLocGrp of getformAdt1Adt3CopyLoc(auditLetterGrp); let j = index"
                                        [formGroup]="formAdt1Adt3CopyLocGrp">
                                        <td>
                                          <div class="row">
                                            <div class="col-md-9 nopadding">
                                              <div class="form-group">
                                                <input *ngIf="formAdt1Adt3CopyLocGrp.value.isdocLocUploadedClicked"
                                                  type="file" id="itemList({{i}}).formAdt1Adt3CopyLoc({{j}}).fileLoc"
                                                  class="filetype form-control" name="myfile"
                                                  (change)="onFileSelect($event)">
                                                <p *ngIf="!formAdt1Adt3CopyLocGrp.value.isdocLocUploadedClicked || formAdt1Adt3CopyLocGrp.value.fileLoc"
                                                  class="form-control pt10">
                                                  {{getFileNameDta(formAdt1Adt3CopyLocGrp.value.fileLoc)}}
                                                </p>
                                              </div>
                                            </div>
                                            <div class="col-md-3 nopadding">
                                              <label for="exampleInputPassword1">
                                                <a class="downupIcon" *ngIf="formAdt1Adt3CopyLocGrp.value.fileLoc"
                                                  title="Download file"
                                                  (click)="download(formAdt1Adt3CopyLocGrp.value.fileLoc)"><i
                                                    class="fa fa-download"></i></a>
                                                <a class="downupIcon"
                                                  *ngIf="!(formAdt1Adt3CopyLocGrp.value.fileLoc || formAdt1Adt3CopyLocGrp.value.fileLoc) && isDisableIcons"
                                                  title="Upload file" (click)="uploadformAdt1Adt3CopyLoc1(i,j)"><i
                                                    class="fa fa-upload"></i></a>
                                                <a class="downupIcon" (click)="deleteformAdt1Adt3CopyLoc(i,j)"
                                                  *ngIf="(formAdt1Adt3CopyLocGrp.value.fileLoc) && isDisableIcons"><i
                                                    class="fa fa-trash" aria-hidden="true"></i></a>
                                              </label>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          <input type="text"
                                            [title]="formAdt1Adt3CopyLocGrp.value.remarks? formAdt1Adt3CopyLocGrp.value.remarks:''"
                                            class="form-control" formControlName="remarks">
                                          <div
                                            *ngIf="formAdt1Adt3CopyLocGrp.value.remarks && formAdt1Adt3CopyLocGrp.value.remarks.length > 100">
                                            <span class="text-danger">Max character Length 100</span>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div class="modal-footer" style="border: none; padding-top: 0px;">
                                <button type="button" class="btn btn-outline-dark"
                                  (click)="modal.close('Save click')">Save</button>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12 mt15 ">
                        <button class="btn btn-outline-primary ml-1" (click)="openRmvLetterPop(removeLetterPopup, i)"
                          *ngIf="(isauditAilData) && i != 0 ">Remove Letter</button>
                        <button class="btn btn-outline-primary ml-1" (click)="addALetter()" *ngIf="isauditAilData">Add
                          Another Letter</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ng-template #removeLetterPopup let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure, you want to remove?</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="rmALetter(i)">Remove</button>
              </div>
            </ng-template>




          </div>
          <div class="col-md-12 mt15 nopadding">
            <div class="form-group">
              <label for="exampleInputPassword1">Summary </label>
              <textarea type="text" class="form-control" id="CPResponsible" placeholder="" rows="3"
                formControlName="internalRemarks"></textarea>
            </div>
          </div>
          <div class="col-md-12 mt15 nopadding">
            <button class="btn btn-outline-primary mr-1" (click)="saveIntimLetter()" *ngIf="isSaveBtn">Save </button>
            <button class="btn btn-outline-primary mr-1" (click)="updateData('p1')" *ngIf="isUpdateBtn">Update</button>
          </div>

        </div>
      </div>
    </div>
    <div class="tab-pane fade show" id="liasDept-1" role="tabpanel" aria-labelledby="liasDept-tab">
      <div class="card borderOrange" [hidden]="(selectedTab === 'tab1')" [ngClass]="{'bgGray': (action == 'view')} ">
        <div class="card-body">
          <div class="row">
            <p class="card-title">Department Officer details</p>
            <div class="col-md-3">
              <div class="form-group">
                <label>Name of the Officer</label>
                <input type="text" class="form-control" id="" formControlName="nameOfOfficer">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Designation</label>
                <!-- <input type="text" class="form-control" id="" formControlName="rank"> -->
                <select class="form-control form-select" formControlName="rank">
                  <ng-container *ngFor="let obj of designation">
                    <option [value]="obj.value">{{obj.key}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Phone Number</label>
                <input type="text" class="form-control" id="" formControlName="phoneNo" maxlength="12"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>E-mail ID</label>
                <input type="email" class="form-control" id="" formControlName="emailId"
                  [ngClass]="{ 'errorBorder': formGrp.emailId.errors }">
                <div *ngIf="formGrp.emailId.errors">
                  <span class="text-danger" *ngIf="formGrp.emailId.errors.pattern"> Enter Valid Email ID
                  </span>
                </div>
                <!-- 
                        [ngClass]="{ 'errorBorder': auditLetterGrp.get('emailId')?.errors }"
                        <div *ngIf=".get('emailId')?.errors">                      
                        <span class="text-danger dateError2" *ngIf="auditLetterGrp.get('emailId')?.errors?.pattern">
                          Enter Valid Email ID
                        </span>
                      </div> -->
              </div>
            </div>
            <div class="col-md-9">
              <div class="form-group">
                <label>Address</label>
                <input type="text" class="form-control" id="" formControlName="address">
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <label>Any other details </label>
                <input type="text" class="form-control" id="" formControlName="otherDetails">
              </div>
            </div>
          </div>
          <div id="auditletter{{i}}" class="mt-2" *ngFor="let auditLetterGrp of getauditLetter(); let i=index"
            [formGroup]="auditLetterGrp">
            <div class="card border-bottom">
              <div class="card-header" role="tab" id="auditletter-1">
                <h6 class="mb-0">
                  <a data-toggle="collapse" href="#auditletter-1" aria-expanded="true" aria-controls="auditletter-1">
                    Reply to Audit Letter {{i+1}}</a>
                </h6>
              </div>
              <div id="auditletter-1" class="collapse show" role="tabpanel" aria-labelledby="auditletter-1"
                data-parent="#accordion" [ngClass]="{'bgGray': (action == 'view')} ">
                <div class="card-body">
                  <div class="row">

                    <div class="col-md-12 plr20">
                      <div class="form-group">
                        <label for="exampleInputPassword1" class="card-title">Add Submission
                          <a class="addExtLetter" (click)="addSubmission(i)" *ngIf="isActionView"><i
                              class="fa fa-plus"></i></a>
                        </label>
                        <div *ngFor="let submissionGrp of getSubmission(auditLetterGrp); let k=index"
                          [formGroup]="submissionGrp">
                          <div class="row subDiv ">
                            <p class="card-title">Submission {{k+1}}
                              <a class="addExtLetter" (click)="addSubmission(i)" *ngIf="isActionView"><i
                                  class="fa fa-plus"></i></a>
                              <a class="addExtLetter pull-right" (click)="openRmvSubmissionPop(rmSubmissionPopup, i)"
                                *ngIf="isActionView"><i class="fa fa-minus"></i></a>
                            </p>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label>Date of submission <span class="mandate"><i
                                      class="fa fa-asterisk"></i></span></label>
                                <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                  <p-calendar class="form-control" formControlName="dateOfSubmission"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                    dateFormat="dd-mm-yy" [maxDate]="maxDate" [showIcon]="true" dataType="string"
                                    [ngClass]="{ 'errorBorder': isSubmitted && submissionGrp.get('dateOfSubmission')?.errors  }">
                                  </p-calendar>
                                  <div *ngIf="isSubmitted &&  submissionGrp.get('dateOfSubmission')?.errors">
                                    <span class="text-danger dateError"
                                      *ngIf="submissionGrp.get('dateOfSubmission')?.errors?.required"> Required
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-3">
                              <div class="form-group">
                                <label for="exampleInputPassword1">Person responsible </label>
                                <select class="form-control form-select" id="personResponsible"
                                  *ngIf="navContext?.entityType == 'FILING'" formControlName="personResponsible">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of userRoleLs">
                                    <option [value]="obj.userEmailId">{{obj.userEmailId}}</option>
                                  </ng-container>
                                </select>
                                <input type="text" class="form-control"
                                  *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'"
                                  placeholder="" formControlName="personResponsible">
                              </div>
                            </div>

                            <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create')">
                              <div class="form-group">
                                <label for="exampleInputPassword1">Consultant</label>
                            
                                <!-- For Consultant Role: Create, View, and Update -->
                                <div *ngIf="roleName === 'Consultant' && action === 'create'">
                                  <input 
                                    type="text" 
                                    class="form-control" 
                                    formControlName="consultant" 
                                    [readOnly]="action === 'create'">
                                </div>
                            
                                <!-- For Non-Consultant Role -->
                                <div *ngIf="roleName !== 'Consultant'">
                                  <div *ngIf="isToggleConsultantDropdown2 && (action === 'update' || action === 'view')" class="input-group">
                                    <input 
                                      type="text" 
                                      class="form-control" 
                                      placeholder="" 
                                      formControlName="consultant" 
                                      readonly>
                                    <button 
                                      *ngIf="isToggleConsultantDropdown2 && action === 'update'" 
                                      class="btn btn-outline-secondary" 
                                      type="button" 
                                      (click)="onClickToggleConsultantDropdown('p2')">
                                      <i class="fa fa-pencil"></i>
                                    </button>
                                  </div>
                            
                                  <select 
                                    class="form-control form-select" 
                                    id="" 
                                    *ngIf="!isToggleConsultantDropdown2 || action === 'create'" 
                                    formControlName="consultant">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of consultantList">
                                      <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                                    </ng-container>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <label>List of Documents Submitted <a class="addExtLetter" (click)="addSubItemList(i, k)"
                                  *ngIf="isActionView"><i class="fa fa-plus"></i></a></label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Document Name</th>
                                      <th>Upload</th>
                                      <th>Remark</th>
                                      <th>Remove</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let subItemListGrp of getSubItemList(submissionGrp); let j=index"
                                      [formGroup]="subItemListGrp">
                                      <td><input type="text" class="form-control mb-1" id="docName"
                                          formControlName="docName"></td>
                                      <td>
                                        <div class="row">
                                          <!-- <div class="col-md-9 nopadding">                                               
                                            <div class="form-group"> 
                                              <input *ngIf="subItemListGrp.value.isdocLocUploadedClicked" type="file" id="itemList({{i}}).submissions({{k}}).docSubmissions({{j}}).docLoc" class="filetype4 form-control" name="myfile" (change)='onFileSelect($event)' [ngClass]="{ 'errorBorder': isSubmitted && subItemListGrp.get('docLoc')?.errors  }">
                                              <p *ngIf="!subItemListGrp.value.isdocLocUploadedClicked || subItemListGrp.value.docLoc" class="form-control pt10" [ngClass]="{ 'errorBorder': isSubmitted && subItemListGrp.get('docLoc')?.errors  }">{{getFileNameDta(subItemListGrp.value.docLoc)}}</p>       
                                                  <div *ngIf="isSubmitted &&  subItemListGrp.get('docLoc')?.errors">
                                                <span class="text-danger" *ngIf="subItemListGrp.get('docLoc')?.errors?.required"> Required
                                                </span>
                                              </div>
                                            </div> 
                                        </div>
                                        <div class="col-md-3 nopadding">    
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon" *ngIf="subItemListGrp.value.docLoc" title="Download file" (click)="download(subItemListGrp.value.docLoc)"><i class="fa fa-download"></i></a>
                                              <a class="downupIcon" *ngIf="isDisableIcons && !(subItemListGrp.value.docLoc || subItemListGrp.value.docLoc)" title="Upload file" (click)="uploadDocLoc(i,j,k)"><i class="fa fa-upload"></i></a>
                                              <a  class="downupIcon" (click)="deletedocLocFile(i,j,k)"  *ngIf=" isDisableIcons && (subItemListGrp.value.docLoc)"><i  class="fa fa-trash" aria-hidden="true"></i></a>
                                            </label> 
                                        </div> -->
                                          <div class="col-sm-12 nopadding">
                                            <div class="attachment-block m510"
                                              [ngClass]="{'errorBorder': isSubmitted &&  getDocLocLength(i, k,j) === 0 }">
                                              <span style="cursor: pointer;" (click)="opendocLoc(docLocPop, i)"> <i
                                                  class="icon fa fa fa-paperclip" title="Attach file"
                                                  style="cursor: pointer;"></i></span>
                                              <span *ngIf="getDocLocLength(i, k,j) != 0"> {{getDocLocLength(i, k,j)}}
                                                Attachment</span>
                                            </div>
                                            <div *ngIf="isSubmitted && getDocLocLength(i, k,j) === 0">
                                              <span class="text-danger">Required</span>
                                            </div>
                                            <ng-template #docLocPop let-modal>
                                              <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                                <button type="button" class="close" aria-label="Close"
                                                  (click)="modal.dismiss('Cross click')"> <span
                                                    aria-hidden="true">×</span> </button>
                                              </div>
                                              <div class="col-md-12">
                                                <label> Attachment</label>
                                                <div class="table-responsive">
                                                  <table class="table table-bordered tablepop">
                                                    <thead>
                                                      <tr class="bgGred">
                                                        <th>Upload</th>
                                                        <th style="width:50%;">Remark</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr
                                                        *ngFor="let docLocPopGrp of getdocLoc(subItemListGrp); let l = index"
                                                        [formGroup]="docLocPopGrp">
                                                        <td>
                                                          <div class="row">
                                                            <div class="col-md-9 nopadding">
                                                              <div class="form-group">
                                                                <input
                                                                  *ngIf="docLocPopGrp.value.isdocLocUploadedClicked"
                                                                  type="file"
                                                                  id="itemList({{i}}).submissions({{k}}).docSubmissions({{j}}).docLoc({{l}}).fileLoc"
                                                                  class="filetype form-control" name="myfile"
                                                                  (change)="onFileSelect($event)">
                                                                <p *ngIf="!docLocPopGrp.value.isdocLocUploadedClicked || docLocPopGrp.value.fileLoc"
                                                                  class="form-control pt10">
                                                                  {{getFileNameDta(docLocPopGrp.value.fileLoc)}}
                                                                </p>
                                                              </div>
                                                            </div>
                                                            <div class="col-md-3 nopadding">
                                                              <label for="exampleInputPassword1">
                                                                <a class="downupIcon" *ngIf="docLocPopGrp.value.fileLoc"
                                                                  title="Download file"
                                                                  (click)="download(docLocPopGrp.value.fileLoc)"><i
                                                                    class="fa fa-download"></i></a>
                                                                <a class="downupIcon"
                                                                  *ngIf="!(docLocPopGrp.value.fileLoc || docLocPopGrp.value.fileLoc) && isDisableIcons"
                                                                  title="Upload file" (click)="uploadDocLoc(i,j,k,l)"><i
                                                                    class="fa fa-upload"></i></a>
                                                                <a class="downupIcon" (click)="deleteDocLoc(i,j,k,l)"
                                                                  *ngIf="(docLocPopGrp.value.fileLoc) && isDisableIcons"><i
                                                                    class="fa fa-trash" aria-hidden="true"></i></a>
                                                              </label>
                                                            </div>
                                                          </div>
                                                        </td>
                                                        <td>
                                                          <input type="text" class="form-control"
                                                            formControlName="remarks"
                                                            [title]="docLocPopGrp.value.remarks ? docLocPopGrp.value.remarks:''">
                                                          <div
                                                            *ngIf="docLocPopGrp.value.remarks && docLocPopGrp.value.remarks.length > 100">
                                                            <span class="text-danger">Max character Length 100</span>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                              <div class="modal-footer" style="border: none; padding-top: 0px;">
                                                <button type="button" class="btn btn-outline-dark"
                                                  (click)="modal.close('Save click')">Save</button>
                                              </div>
                                            </ng-template>
                                          </div>

                                        </div>
                                      </td>
                                      <!-- remark -->
                                      <td>
                                        <input type="text" class="form-control" formControlName="remark"
                                          [title]="subItemListGrp.value.remark ? subItemListGrp.value.remark:''"
                                          (click)="openTextBoxModal('Remark', 'remark',submissionGrp,j)">
                                      </td>

                                      <td><a class="addExtLetter" (click)="openRmvDocSubListPop(rmDocSubListPop, i)"
                                          *ngIf="j!=0 && isActionView"><i class="fa fa-minus"></i></a></td>
                                      <ng-template #rmDocSubListPop let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmSubItemList(i, k, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          <ng-template #rmSubmissionPopup let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p>Are you sure, you want to remove?</p>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="rmSubmission(i, k)">Remove</button>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <!-- Consultant charges table -->
          <div data-toggle="collapse" data-target="#demo" class="expansion-div mb-15"
            (click)="consultantPanleExpansion != consultantPanleExpansion">Details of Consultant Charges <span
              class="consultant-icons"> <i *ngIf="!consultantPanleExpansion" class="fa fa-angle-down"
                aria-hidden="true"></i> <i *ngIf="consultantPanleExpansion" class="fa fa-angle-up"
                aria-hidden="true"></i></span> </div>
          <br>
          <div id="demo" class="collapse">
            <div class=" row">
              <div class="col-md-12">
                <div class="search-table-outter wrapper p-15">
                  <table class="search-table inner">
                    <thead>
                      <tr class="bgGred">
                        <th class="sl-no-cls">Sr.No.</th>
                        <th>Name of the Consultant</th>
                        <th>Name of Consulting Firm</th>
                        <th>Nature of Work</th>
                        <th>Fees Charged (₹)</th>
                        <th>Supporting Document</th>
                        <th>Email id</th>
                        <th>Contact Number</th>
                        <th>Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container>
                        <tr *ngFor="let consultantGrp of consultantInvArr() let  i=index;" [formGroup]="consultantGrp">
                          <td class="sl-no-cls">{{i+1}}</td>
                          <td>
                            <!-- <input type="text" class="form-control" id="tax" placeholder=""
                            formControlName="consultant"> -->
                            <div *ngIf="isToggleConsultantDropdown3 && action==='update' || action==='view'" class="input-group">
                              <input type="text" class="form-control"
                                *ngIf="isToggleConsultantDropdown3 && action==='update' || action==='view'" placeholder=""
                                formControlName="consultant" readonly>
                              <button *ngIf="isToggleConsultantDropdown3 && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('CC')">
                                <i class="fa fa-pencil"></i>
                              </button> 
                              </div>
                    
                              <select class="form-control form-select" id="consultant"
                                *ngIf="!isToggleConsultantDropdown3 || action==='create'" formControlName="consultant" (change)="consultantDataAutofil(i)">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of consultantList">
                                <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                </ng-container>
                              </select>
                          </td>
                          <td>
                            <input type="text" class="form-control" id="tax" placeholder="" formControlName="firm"
                              [title]="consultantGrp.value.firm ? consultantGrp.value.firm:''" readonly style="cursor: not-allowed;" >
                            <!-- <div *ngIf="isSubmitted && consultantGrp.get('firm')?.errors">
                              <span class="text-danger" *ngIf="consultantGrp.get('firm')?.errors?.required"> Name of
                                Consulting Firm Required</span>
                            </div> -->
                          </td>
                          <td><input type="text" class="form-control" id="tax" placeholder=""
                              formControlName="natureOfWork"></td>
                          <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                              formControlName="fees" maxlength="19" InrFormat (input)="feesChargedChanged()"
                              (paste)="handlePaste($event)"></td>
                          <td>
                            <div class="row">
                              <div class="col-sm-9 nopadding">
                                <span style="cursor: pointer;"
                                    (click)="openconsultotherAttachmentLoc(supportingDocLocPop, i)"> <i
                                      class="icon fa fa fa-paperclip" title="Attach file"
                                      style="cursor: pointer;"></i></span>
                                  <span *ngIf="getConsultOtherAttachmentLength(i) != 0">
                                    {{getConsultOtherAttachmentLength(i)}} Attachment</span>
                                <ng-template #supportingDocLocPop let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            *ngFor="let supportingDocGrp of getconsultotherAttachmentLoc(consultantGrp); let j = index"
                                            [formGroup]="supportingDocGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="supportingDocGrp.value.isdocLocUploadedClicked"
                                                      type="file"
                                                      id="consultantCharges({{i}}).otherAttachmentLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)">
                                                    <p *ngIf="!supportingDocGrp.value.isdocLocUploadedClicked || supportingDocGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(supportingDocGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="supportingDocGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(supportingDocGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(supportingDocGrp.value.fileLoc || supportingDocGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file"
                                                      (click)="uploadconsultotherAttachmentLoc(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deleteconsultotherAttachmentLoc(i,j)"
                                                      *ngIf="(supportingDocGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" formControlName="remarks"
                                                [title]="supportingDocGrp.value.remarks ? supportingDocGrp.value.remarks:''">
                                              <div
                                                *ngIf="supportingDocGrp.value.remarks && supportingDocGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" placeholder="" formControlName="email"
                            [title]="consultantGrp.value.email ? consultantGrp.value.email:''" readonly style="cursor: not-allowed;">
                            <!-- <div *ngIf="consultantGrp.get('email')?.errors">
                              <span class="text-danger" *ngIf="consultantGrp.get('email')?.errors?.pattern"> Please
                                Enter
                                Valid Email ID</span>
                            </div> -->
                          </td>
                          <td><input class="form-control" id="contact" formControlName="contact"
                              [title]="consultantGrp.value.contact ? consultantGrp.value.contact:''" maxlength="12" readonly style="cursor: not-allowed;">
                          </td>
                          <td>

                            <a class=" addExtLetter" (click)="addConsultantInv()" *ngIf="isActionView"><i
                                class="fa fa-plus"></i></a>
                            <a class=" addExtLetter" *ngIf="i !=0 && isActionView"
                              (click)="openRmvconsultantPop(removeConsultantPopup)"><i class="fa fa-minus"></i></a>
                            <ng-template #removeConsultantPopup let-modal>
                              <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                <button type="button" class="close" aria-label="Close"
                                  (click)="modal.dismiss('Cross click')">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <p>Are you sure, you want to remove?</p>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-outline-dark"
                                  (click)="rmConsultantInv(i)">Remove</button>
                              </div>
                            </ng-template>
                          </td>
                        </tr>
                        <tr>
                          <th colspan="4">Total</th>
                          <td class="align-right bold-font">{{feesChargedTotal | INR: 0 }}</td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Consultant charges table 2 -->
          <div class="row">
            <div class="col-md-3 mt-2">
              <div class="form-group">
                <label>Whether audit finding letter issued? <span class="mandate"><i
                      class="fa fa-asterisk"></i></span></label>
                <select class="form-control form-select" id="gstin" formControlName="findingLetterIssued"
                  (change)="weatherLetterIssued($event)"
                  [ngClass]="{ 'errorBorder': isSubmitted && formGrp.findingLetterIssued.errors }">
                  <ng-container *ngFor="let obj of whfollowup">
                    <option [value]="obj.value">{{obj.label}}</option>
                  </ng-container>
                </select>
                <div *ngIf="isSubmitted && formGrp.findingLetterIssued.errors">
                  <span class="text-danger" *ngIf="formGrp.findingLetterIssued.errors.required"> Required
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt15 nopadding">
              <button class="btn btn-outline-primary mr-1" (click)="updateData('p2')"
                *ngIf="isUpdateBtn">Update</button>
            </div>
          </div>
          <ng-template #auditFindingPop let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">Warning! </h4>
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Do you want to proceed to add Audit Finding Letter details?</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">OK</button>
            </div>
          </ng-template>

        </div>
      </div>
    </div>

  </form>
</div>
