<div class="table-responsive">
  <table class="table table-bordered divTbl">
    <thead>
      <tr class="bgGred">
        <th>Division & GSTIN</th>
        <th>Demand as per Current Stage Notice/Order Amount</th>
        <th>Cumulative Pre Deposit paid Amount</th>
        <th>Cumulative Admitted Liability Paid Amount</th>
        <th>Cumulative Amount Paid under Protest Amount</th>
      </tr>

    </thead>
    <tbody class="divTbody">
      <ng-container *ngFor="let obj of data">
        <tr>
          <td colspan="5" style="background: #e9e9e9;"> <a class="letterTbl"
              (click)="obj.expanded = !obj.expanded">{{obj.division}}<span class="expand">{{ obj.expanded ? '&ndash;' :
                '+'}}</span></a></td>

        </tr>
        <ng-container *ngIf="obj.expanded">
          <tr *ngFor="let item of obj.paymentGstinData">
            <td>{{item.gstin}}</td>
            <td class="textRight">{{item.rowSummary.demand | INR : 2}}</td>
            <td class="textRight">{{item.rowSummary.cPredeposit | INR : 2}}</td>
            <td class="textRight">{{item.rowSummary.aLiability | INR : 2}}</td>
            <td class="textRight">{{item.rowSummary.pAmount | INR : 2}}</td>

          </tr>
        </ng-container>
      </ng-container>

    </tbody>
  </table>
</div>
