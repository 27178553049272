<div class="container-fluid page-body-wrapper">
  <div class="theme-setting-wrapper">
    <!-- <div id="settings-trigger"><i class="mdi mdi-tune"></i></div> -->
    <div id="theme-settings" class="settings-panel ">
      <i class="settings-close mdi mdi-close" (click)="closeDiv()"></i>
      <!-- <a (click)="reset()" class="pull-right clrOrange refreshFilter settings-close" title="Clear Filter">Clear</a> -->
      <h4 class="settings-heading"><i class="fa fa-filter clrOrange"></i> Filter</h4>
      <div class="iris-gst-applied-filter" style="background: #f4f4f4;" >
        <form [formGroup]="filterForm">
        <div class="row" style="display: flex;">

          <div class="col-md-12 plr10 iris-gst-aaplied-section">
            <p class="mt-1"><span>Applied Filter</span><a (click)="reset()" class="pull-right clrBlue">Clear All</a>
            <ul class="iris-gst-filter-sec">
              <li *ngIf="filterForm.controls['pf1'].value !== null"><span>Period From Start </span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('pf1')"></i></a></li>
              <li *ngIf="filterForm.controls['pf2'].value !== null"><span>Period From End </span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('pf2')"></i></a></li>
              <li *ngIf="filterForm.controls['pt1'].value !== null"><span>Period To Start </span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('pt1')"></i></a></li>
              <li *ngIf="filterForm.controls['pt2'].value !== null"><span>Period To End </span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('pt2')"></i></a></li>
              <li *ngIf="filterForm.controls['dd1'].value !== null"><span>Due Date Start</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('dd1')"></i></a></li>
              <li *ngIf="filterForm.controls['dd2'].value !== null"><span>Due Date End</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('dd2')"></i></a></li>
              <li *ngIf="filterForm.controls['kw'].value !== null"><span>Key words</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('kw')"></i></a></li>
              <li *ngIf="filterForm.controls['pr'].value !== null"><span>Person Responsible</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('pr')"></i></a></li>
              <li *ngIf="filterForm.controls['st'].value !== null"><span>Status</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('st')"></i></a></li>
              <li *ngIf="filterForm.controls['caseId'].value !== null"><span>Case ID</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('caseId')"></i></a></li>
              <li *ngIf="filterForm.controls['caseLabel'].value !== null"><span>Case Label</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('caseLabel')"></i></a></li>
              <li *ngIf="filterForm.controls['consultant'].value !== null"><span>Consultant</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('consultant')"></i></a></li>
              <li *ngIf="filterForm.controls['amtQuery'].value !== null && filterForm.controls['amtQuery'].value >= 0"><span>Demand Amount</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('amtQuery')"></i></a></li>
              <li *ngIf="(filterForm.controls['issue'].value !== null || isAnalyticIssueFilter)"><span>Issue</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('issue')"></i></a></li>
              <li *ngIf="filterForm.controls['div'].value !== null"><span>Division</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('div')"></i></a></li>
              <li *ngIf="filterForm.controls['act'].value !== null"><span>Act</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('act')"></i></a></li>
              <li *ngIf="filterForm.controls['state'].value !== null"><span>State</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('state')"></i></a></li>
              <li *ngIf="filterForm.controls['regNo'].value !== null"><span>Registration Number</span><a class="iris-gst-filter-close"><i class="fa fa-times" (click)="resetControl('regNo')"></i></a></li>

            </ul>
          </div>
          <div class="col-3">
            <ul class="nav nav-tabs nav-tabs-vertical-custom" role="tablist">
            <li *ngIf="!isGST && !isAudit" class="nav-item">
              <a class="nav-link" [ngClass]="{'active': !isGST}" id="criteria13-tab-custom" data-toggle="tab" href="#criteria13" role="tab"
                aria-controls="criteria13" aria-selected="false">Act
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" [ngClass]="{'active': isGST || isAudit}" id="criteria7-tab-custom" data-toggle="tab" href="#criteria7" role="tab"
                aria-controls="criteria7" aria-selected="false">Case ID
              </a>
            </li>
            <li class="nav-item" *ngIf="!isAudit">
              <a class="nav-link" id="criteria8-tab-custom" data-toggle="tab" href="#criteria8" role="tab"
                aria-controls="criteria8" aria-selected="false">Case Label
              </a>
            </li>
            <li class="nav-item" >
              <a class="nav-link"  id="criteria9-tab-custom" data-toggle="tab" href="#criteria9" role="tab"
                aria-controls="criteria9" aria-selected="false">Consultant
              </a>
            </li>
            <li class="nav-item" *ngIf="!isAudit">
              <a class="nav-link" id="criteria10-tab-custom" data-toggle="tab" href="#criteria10" role="tab"
                aria-controls="criteria10" aria-selected="false">Demand Amount
              </a>
            </li>
            <li class="nav-item" *ngIf="!isPreGST">
              <a class="nav-link" id="criteria12-tab-custom" data-toggle="tab" href="#criteria12" role="tab"
                aria-controls="criteria12" aria-selected="false">Division
              </a>
            </li>
            <li class="nav-item" *ngIf="!isAudit">
              <a class="nav-link" id="criteria3-tab-custom" data-toggle="tab" href="#criteria3" role="tab"
                aria-controls="criteria3" aria-selected="false">Due Date
              </a>
            </li>
            <li class="nav-item" *ngIf="!isAudit">
              <a class="nav-link" id="criteria11-tab-custom" data-toggle="tab" href="#criteria11" role="tab"
                aria-controls="criteria11" aria-selected="false">Issue
              </a>
            </li>
            <li class="nav-item" *ngIf="!isAudit">
              <a class="nav-link" id="criteria4-tab-custom" data-toggle="tab" href="#criteria4" role="tab"
                aria-controls="criteria4" aria-selected="false">Key words
              </a>
            </li>
              <li class="nav-item" *ngIf="!isAudit">
                <a class="nav-link" id="criteria1-tab-custom" data-toggle="tab" href="#criteria1" role="tab"
                  aria-controls="criteria1" aria-selected="false">Period From
                </a>
              </li>
              <li class="nav-item" *ngIf="!isAudit">
                <a class="nav-link" id="criteria2-tab-custom" data-toggle="tab" href="#criteria2" role="tab"
                  aria-controls="criteria2" aria-selected="false">Period To
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="criteria5-tab-custom" data-toggle="tab" href="#criteria5" role="tab"
                  aria-controls="criteria5" aria-selected="false">Person Responsible
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="criteria15-tab-custom" data-toggle="tab" href="#criteria15" role="tab"
                  aria-controls="criteria15-tab-custom" aria-selected="false">Registration Number
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="criteria6-tab-custom" data-toggle="tab" href="#criteria6" role="tab"
                  aria-controls="criteria6" aria-selected="false">Status
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="criteria14-tab-custom" data-toggle="tab" href="#criteria14" role="tab"
                  aria-controls="criteria14" aria-selected="false">State
                </a>
              </li>
            </ul>
          </div>

          <div class="col-9 col-lg-8 pt15">

              <div class="tab-content tab-content-vertical tab-content-vertical-custom">

                <div class="tab-pane fade" id="criteria1" role="tabpanel"
                  aria-labelledby="criteria1-tab-custom">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Start </label>
                      <div id="pf1rmDate" class="input-group date datepicker">
                        <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                          dateFormat="dd-mm-yy" [showIcon]="true" formControlName="pf1" dataType="string"></p-calendar>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">End</label>
                      <div id="pf2toDate" class="input-group date datepicker ">
                        <p-calendar dateFormat="dd-mm-yy" [monthNavigator]="true" [yearNavigator]="true"
                          yearRange="2016:{{currentYear}}" [showIcon]="true" formControlName="pf2" dataType="string"></p-calendar>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade show " id="criteria2" role="tabpanel" aria-labelledby="criteria2-tab-custom">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Start </label>
                      <div id="pt1frmDate" class="input-group date datepicker ">
                        <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                          dateFormat="dd-mm-yy" [showIcon]="true" formControlName="pt1" dataType="string"></p-calendar>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">End</label>
                      <div id="pt2toDate" class="input-group date datepicker ">
                        <p-calendar dateFormat="dd-mm-yy" [monthNavigator]="true" [yearNavigator]="true"
                          yearRange="2016:{{currentYear}}" [showIcon]="true" formControlName="pt2" dataType="string"></p-calendar>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade show " id="criteria3" role="tabpanel" aria-labelledby="criteria3-tab-custom">
                  <div class="col-md-12">
                    <button class="btn btn-outline-primary mr-1" (click)="nextWeek()">Next Week</button>
                    <button class="btn btn-outline-primary mr-1" (click)="nextMonth()">Next Month</button>
                    <button class="btn btn-outline-primary mr-1" (click)="custom()">Custom</button>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">Start </label>
                      <div id="dd1frmDate" class="input-group date datepicker ">
                        <p-calendar [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                          dateFormat="dd-mm-yy" [showIcon]="true" formControlName="dd1" dataType="string"></p-calendar>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="">End</label>
                      <div id="dd2toDate" class="input-group date datepicker ">
                        <p-calendar dateFormat="dd-mm-yy" [monthNavigator]="true" [yearNavigator]="true"
                          yearRange="2016:{{currentYear}}" [showIcon]="true" formControlName="dd2" dataType="string"></p-calendar>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria4" role="tabpanel" aria-labelledby="criteria4-tab-custom">
                  <div class="form-group">
                    <label for="">Key words </label>
                    <input type="text" class="form-control " formControlName="kw">
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria5" role="tabpanel" aria-labelledby="criteria5-tab-custom" *ngIf="!isPreGST">
                  <div class="form-group">
                    <label for="exampleSelectGender">Person Responsible</label>
                    <select class="form-control form-select " id="personResponsible" formControlName="pr">
                      <option [value]="null" disabled>-- Select --</option>
                      <ng-container *ngFor="let obj of userRoleLs">
                        <option [value]="obj.userEmailId">{{obj.userEmailId}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria5" role="tabpanel" aria-labelledby="criteria5-tab-custom" *ngIf="!isGST  || !isAudit">
                  <div class="form-group">
                    <label for="exampleSelectGender">Person Responsible</label>
                    <select class="form-control form-select " id="personResponsible" formControlName="pr">
                      <option [value]="null" disabled>-- Select --</option>
                      <ng-container *ngFor="let obj of userRole">
                        <option [value]="obj.email">{{obj.email}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria6" role="tabpanel" aria-labelledby="criteria6-tab-custom" *ngIf="isAudit">
                  <div class="form-group">
                    <label for="exampleSelectGender">Status</label>
                    <select class="form-control form-select " id="location" formControlName="st">
                      <ng-container *ngFor="let obj of statusAudit">
                        <option [value]="obj.value">{{obj.value}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>

                <div class="tab-pane fade" id="criteria6" role="tabpanel" aria-labelledby="criteria6-tab-custom"   *ngIf="isGST||isPreGST">
                  <div class="form-group">
                    <label for="exampleSelectGender">Status</label>
                    <select class="form-control form-select " id="location" formControlName="st">
                      <ng-container *ngFor="let obj of status">
                        <option [value]="obj.value">{{obj.value}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
                <div class="tab-pane fade" [ngClass]="{'show' : isGST || isAudit , 'active': isGST || isAudit}" id="criteria7" role="tabpanel" aria-labelledby="criteria7-tab-custom">
                  <div class="form-group">
                    <label for="exampleSelectGender">CaseID</label>
                    <input type="text" class="form-control " id="caseId" formControlName="caseId">
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria8" role="tabpanel" aria-labelledby="criteria8-tab-custom">
                  <div class="form-group">
                    <label for="exampleSelectGender">Case Label</label>
                    <input type="text" class="form-control " id="caseLabel" formControlName="caseLabel">
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria9" role="tabpanel" aria-labelledby="criteria9-tab-custom">
                  <div class="form-group">
                    <label for="exampleSelectGender">Consultant</label>
                    <input type="text" class="form-control " id="consultant" formControlName="consultant">
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria10" role="tabpanel" aria-labelledby="criteria10-tab-custom">
                  <div class="form-group">
                    <label for="exampleSelectGender">Demand Amount</label>
                    <div class="demandAmtCls">
                      <select class="form-control form-select me-3 " (change)="demandAmtOptChanged($event)" [value]="amtQuery.o">
                        <option [value]="null">-- Select --</option>
                        <ng-container *ngFor="let obj of demandAmtOptions">
                          <option [value]="obj.key">{{obj.value}}</option>
                        </ng-container>
                      </select>
                      <input type="text" class="form-control" id="amtQuery" formControlName="amtQuery" (change)="demandAmtValueChanged()">
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria11" role="tabpanel" aria-labelledby="criteria11-tab-custom">
                  <div class="form-group">
                    <label for="exampleSelectGender">Issue</label>
                      <select class="form-control form-select " id="issue" formControlName="issue">
                        <option [value]="null">-- Select --</option>
                        <optgroup *ngFor = 'let grp of issueGst' label="{{grp.group}}">
                          <option *ngFor = 'let item of grp.items' [value]="item.name" title="{{item.name}}">{{item.name}}</option>
                        </optgroup>
                      </select>
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria12" role="tabpanel" aria-labelledby="criteria12-tab-custom" *ngIf="!isPreGST">
                  <div class="form-group">
                    <label for="exampleSelectGender">Division</label>
                      <!-- <select class="form-control form-select " id="div" formControlName="div">
                        <option [value]="null">-- Select --</option>
                        <ng-container *ngFor="let obj of division">
                          <option [value]="obj">{{obj}}</option>
                        </ng-container>
                      </select> -->
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Divison'"
                        [settings]="dropdownSettings"
                        [data]="division"
                        formControlName="div">
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="tab-pane fade" [ngClass]="{'show' : (!isGST && !isAudit) , 'active': (!isGST && !isAudit)}" id="criteria13" role="tabpanel" aria-labelledby="criteria13-tab-custom">
                  <div class="form-group">
                    <label for="exampleSelectGender">Act</label>
                      <!-- <select class="form-control form-select " id="act" formControlName="act">
                        <option [value]="null">-- Select --</option>
                        <ng-container *ngFor="let obj of actOptions">
                          <option [value]="obj.value">{{obj.key}}</option>
                        </ng-container>
                      </select> -->
                      <ng-multiselect-dropdown
                        [placeholder]="'Select Act'"
                        [settings]="dropdownSettings"
                        [data]="actOptions"
                        formControlName="act">
                    </ng-multiselect-dropdown>
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria14" role="tabpanel" aria-labelledby="criteria14-tab-custom">
                  <div class="form-group">
                    <label for="exampleSelectGender">State</label>
                      <select class="form-control form-select " id="state" formControlName="state">
                        <option [value]="null">-- Select --</option>
                        <ng-container *ngFor="let obj of stateOpt">
                          <option [value]="obj.stateName">{{obj.stateName}}</option>
                        </ng-container>
                      </select>
                  </div>
                </div>
                <div class="tab-pane fade" id="criteria15" role="tabpanel" aria-labelledby="criteria15-tab-custom">
                  <div class="form-group">
                    <label for="exampleSelectGender">Registration Number</label>
                      <!-- <select *ngIf="isGST" class="form-control form-select " id="regNo" formControlName="regNo" onfocus='this.size=11;' onblur='this.size=1;' onchange='this.size=1; this.blur();'>
                        <option [value]="null">-- Select --</option>
                        <ng-container *ngFor="let obj of leagalRegNum" >
                          <option [value]="obj">{{obj}}</option>
                        </ng-container>
                      </select> -->
                      <ng-multiselect-dropdown  *ngIf="isGST"
                        [placeholder]="'Select Registration Number'"
                        [settings]="dropdownSettings"
                        [data]="leagalRegNum"
                        formControlName="regNo">
                    </ng-multiselect-dropdown>

                    <ng-multiselect-dropdown  *ngIf="isAudit"
                        [placeholder]="'Select Registration Number'"
                        [settings]="dropdownSettings"
                        [data]="leagalRegNum"
                        formControlName="regNo">
                    </ng-multiselect-dropdown>
                      <!-- <select *ngIf="!isGST" class="form-control form-select " id="regNo" formControlName="regNo" onfocus='this.size=11;' onblur='this.size=1;' onchange='this.size=1; this.blur();'>
                        <option [value]="null">-- Select --</option>
                        <ng-container *ngFor="let obj of preGstregGstinData" >
                          <option [value]="obj">{{obj}}</option>
                        </ng-container>
                      </select> -->
                      <ng-multiselect-dropdown  *ngIf="!isGST && !isAudit "
                      [placeholder]="'Select Registration Number'"
                      [settings]="dropdownSettings"
                      [data]="preGstregGstinData"
                      formControlName="regNo">
                  </ng-multiselect-dropdown>
                  </div>
                </div>

              </div>

          </div>
          <div class="iris-gst-filter-bottom"><button type="button" class="btn btn-primary pull-right"
              (click)="sendFilter()">Apply</button>
          </div>

        </div>
      </form>
      </div>

    </div>
  </div>
</div>
