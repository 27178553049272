import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  DEMAND_AMT_OPTIONS,
  FILTER_STATUS_DATA,
  ISSUE_GST,
  PREGST_ISSUE_DROPDOWN,
  PRE_TAB_TYPE,
  STATE_LIST,
  TYPE_OF_ACT,
  badgeMapInvoice,
} from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { PreGstServiceService } from '../services/pre-gst-service.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { DatePipe, formatDate } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateCaseModalComponent } from 'src/app/shared/components/create-case-modal/create-case-modal.component';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { cloneDeep, sortBy } from 'lodash-es';
import { data } from 'jquery';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BusinessService } from 'src/app/businessManagement/services/business.service';
import { LitigationServiceService } from 'src/app/litigation/services/litigation-service.service';
import { BusinessEntityService } from 'src/app/shared/services/business-entity.service';
import { AdjudicationAuthComponent } from 'src/app/litigation/adjudication-auth/adjudication-auth.component';
import { SessionClearComponent } from 'src/app/auth/components/session-clear/session-clear.component';
import _ from 'lodash';
import { MasterService } from 'src/app/businessManagement/services/master.service';
export const SUMMARY_SUM_CACHE = 'SUMMARY_SUM_CACHE'; // used to store in session storage

export enum PRELITIGATION_SORT {
  CASE_ID = 'CaseId',
  STATE = 'State',
  ACT = 'Act',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'status',
  DEMAND = 'Demaand',
}

export enum COMMISSIONER_STAGE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  ACT = 'Act',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'status',
  DEMAND = 'Demaand',
}

export enum APPELLATET_STAGE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  ACT = 'Act',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'status',
  DEMAND = 'Demaand',
}

export enum HIGHCOURT_STAGE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  ACT = 'Act',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'status',
  DEMAND = 'Demaand',
}

export enum SUPREMECOURT_STAGE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  ACT = 'Act',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'status',
  DEMAND = 'Demaand',
}

type Forum =  'preaja'| 'preapa'|'preapt' | 'prehc'| 'presc';

interface SortBy {
  sortField :string; sortDir:'DESC' | 'ASC';
}
interface ForumSortMap {
    preaja: SortBy,
    preapa: SortBy,
    preapt: SortBy,
    prehc: SortBy,
    presc: SortBy,
}

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
})
export class SummaryComponent implements OnInit {
  tabType: { key: string; value: string }[];
  navContext: any;
  pan: any;
  companyId: any;
  fileUrl: any;
  cntPreAJA: any = 0;
  cntPreAPA: any = 0;
  cntPreAPT: any = 0;
  cntPreHC: any = 0;
  cntPreSC: any = 0;
  totalCase: any = 0;
  consultantList: any;

  cntPreAJAOp: any = 0;
  cntPreAPAOp: any = 0;
  cntPreAPTOp: any = 0;
  cntPreHCOp: any = 0;
  cntPreSCOp: any = 0;
  opneCase: any = 0;

  SumofAJA: any = [];
  SumofAPA: any = [];
  SumofAPT: any = [];
  SumofHC: any = [];
  SumofSC: any = [];


  selectedCompanyId: any;
  isFilterDivOpen: boolean = false;
  filterObj: any = {};
  roleName: any;
  statusData: any;
  isFilterhighlited: boolean = false;
  showHeader: boolean = false;
  showHeader1: boolean = false;
  showStatus: boolean = true;
  showPop: boolean = false;
  checkIcon: boolean = false;
  page: number = 0;
  pageSection1: number = 1;
  pageSection2: number = 1;
  pageSection3: number = 1;
  pageSection4: number = 1;
  pageSection5: number = 1;
  perPageLimit = 10;
  perPageLimitSection1 = 10;
  perPageLimitSection2 = 10;
  perPageLimitSection3 = 10;
  perPageLimitSection4 = 10;
  perPageLimitSection5 = 10;

  tableSize: number = 1000;
  filterType: string = 'Act';
  badgesList!: any[];
  badgesMap: any = badgeMapInvoice;
  badgeColorArray = [
    'border-success',
    'border-danger',
    'border-warning',
    'border-dark',
    'border-success',
    'border-danger',
    'border-warning',
    'border-dark',
    'border-success',
  ];
  textColorArray = [
    'text-success',
    'text-danger',
    'text-warning',
    'text-dark',
    'text-success',
    'text-danger',
    'text-warning',
    'text-dark',
    'text-success',
  ];
  queryData: any;
  criteria: any = [];
  criteriaObje: any = {};
  filterPayload: any = {};
  isApplyFilter!: boolean;
  filteredQueryData: any;
  pageSection6: number = 1;
  selectedGstin: any;
  contextData: any;
  perPageLimitSection6 = 10;
  nextWeekDt: any;
  nextMonthDt: any;
  nxtDtdd1: any;
  nxtDtdd2: any;
  nxtMonthdd1: any;
  nxtMonthdd2: any;
  userRoleLs: any;
  issueGst: any;
  preGstregGstinNum: any;
  preGstregGstinData: any;
  activeTab: any;
  @Input() isPreGST: any;
  currentYear: any;
  filterForm!: UntypedFormGroup;
  demandAmtOptions = DEMAND_AMT_OPTIONS;
  filterStatus = FILTER_STATUS_DATA;
  actOptions = TYPE_OF_ACT.map((actType) => actType.value);
  stateNames = STATE_LIST.map((state) => state.stateName);
  amtQuery: any = {};
  leagalRegNum: any;
  selectedUserId: any;
  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'value',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    // itemsShowLimit: 3,
    allowSearchFilter: true
  };
  division: any;

  preLitigationSort = PRELITIGATION_SORT;
  sorting: {
    caseId: boolean;
    state: boolean;
    actType: boolean;
    caseLabel: boolean;
    periodFromDate: boolean;
    periodToDate: boolean;
    status: boolean;
    totOfTotal1: boolean;
  } = {
      caseId: false,
      state: false,
      actType: false,
      caseLabel: false,
      periodFromDate: false,
      periodToDate: false,
      status: false,
      totOfTotal1: false,
    };
  tableSection1Config = {
    itemsPerPage: this.perPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'tableSection1Config',
  };
  tableSection2Config = {
    itemsPerPage: this.perPageLimitSection2,
    currentPage: this.pageSection2,
    totalItems: 0,
    id: 'tableSection2Config',
  };
  tableSection3Config = {
    itemsPerPage: this.perPageLimitSection3,
    currentPage: this.pageSection3,
    totalItems: 0,
    id: 'tableSection3Config',
  };
  tableSection4Config = {
    itemsPerPage: this.perPageLimitSection4,
    currentPage: this.pageSection4,
    totalItems: 0,
    id: 'tableSection4Config',
  };
  tableSection5Config = {
    itemsPerPage: this.perPageLimitSection5,
    currentPage: this.pageSection5,
    totalItems: 0,
    id: 'tableSection5Config',
  };
  tableSection6Config = {
    itemsPerPage: this.perPageLimitSection6,
    currentPage: this.pageSection6,
    totalItems: 0,
    id: 'tableSection6Config',
  };
  criteriaArray: any;

  forumSortMap: ForumSortMap = {
    preaja: {sortField: 'CaseId', sortDir: 'DESC'},
    preapa: {sortField: 'CaseId', sortDir: 'DESC'},
    preapt: {sortField: 'CaseId', sortDir: 'DESC'},
    prehc: {sortField: 'CaseId', sortDir: 'DESC'},
    presc: {sortField: 'CaseId', sortDir: 'DESC'},
  }
  isExpanded : boolean = false;

  constructor(
    private router: Router,
    private commonServ: CommonService,
    private loaderService: LoaderService,
    private preLitService: PreGstServiceService,
    private toaster: ToasterService,
    private modalService: NgbModal,
    private getterSetter: GetterSetterService,
    private datePipe: DatePipe,
    private excelSepreLitServicervice: ExcelService,
    private formBuilder: UntypedFormBuilder,
    private businessService: BusinessService,
    private litService: LitigationServiceService,
    private entityService: BusinessEntityService,
    private excelService: ExcelService,
    private masterService: MasterService
  ) {
    this.tabType = PRE_TAB_TYPE;
  }

  ngOnInit() {
    const filters = sessionStorage.getItem('pregst-filters-Data');
    this.preGstregGstinNum = localStorage.getItem('filterPreGstRegNum');
    this.preGstregGstinData = this.preGstregGstinNum.split(',');
    this.navContext = this.commonServ.getNavContext();
    this.selectedCompanyId = this.getterSetter.getSetCompanyId();
    this.pan = this.navContext.pan;
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    this.setDate();
    this.callAllFunction();
    if (
      localStorage.getItem('preAnalyticIssueLbl') ||
      localStorage.getItem('preAnalyticState')
    ) {
      this.isFilterDivOpen = true;
      this.filterDataCount();
    } else {
    }
    if (localStorage.getItem('roleName') === 'NoRole') {
      this.roleName = localStorage.getItem('role');
    } else {
      this.roleName = localStorage.getItem('roleName');
    }
    const selectedId: any = localStorage.getItem('selectedId');

    let countModel: any = {};
    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    countModel.companyId = selectedId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    this.preLitService.getAllPreGstCaseCount(countModel).subscribe((res: any) => {
      this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
      this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
      this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
      this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
      this.tableSection5Config.totalItems = res.response[4].totCaseCnt;

      this.cntPreAJA = res.response[0].totCaseCnt;
      this.cntPreAPA = res.response[1].totCaseCnt;
      this.cntPreAPT = res.response[2].totCaseCnt;
      this.cntPreHC = res.response[3].totCaseCnt;
      this.cntPreSC = res.response[4].totCaseCnt;
      this.totalCase = res.response[5].totCaseCnt;


      this.cntPreAJAOp = res.response[0].openCaseCnt;
      this.cntPreAPAOp = res.response[1].openCaseCnt;
      this.cntPreAPTOp = res.response[2].openCaseCnt;
      this.cntPreHCOp = res.response[3].openCaseCnt;
      this.cntPreSCOp = res.response[4].openCaseCnt;
      this.opneCase = res.response[5].openCaseCnt;

    });
    this.amtQuery = {
      p: 'totOfTotal1',
      o: null,
      v: null,
    };
    this.issueGst = this.isPreGST ? ISSUE_GST : PREGST_ISSUE_DROPDOWN;
    this.selectedUserId = this.getterSetter.getSetUserId();
  }

  set CACHE(data: any) {
    sessionStorage.setItem(SUMMARY_SUM_CACHE, JSON.stringify(data));
  }
  get CACHE() {
    let cacheData = sessionStorage.getItem(SUMMARY_SUM_CACHE);
    if (cacheData) {
      return JSON.parse(cacheData);
    } else {
      return null;
    }

    //return JSON.parse(sessionStorage.getItem(CACHINGKEY)|| '{}');
  }
  callAllFunction() {
    const filters = localStorage.getItem('filters');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    this.initFilterForm(savedFilters);
    const column = sessionStorage.getItem('pregst_Column_Key');
    const storedAcctype = sessionStorage.getItem('pregst_Acctype_Key');
    const validAccTypes: Forum[] = ['preaja', 'preapa', 'preapt', 'prehc', 'presc'];
    const acctype = validAccTypes.includes(storedAcctype as Forum) ? (storedAcctype as Forum) : 'preaja';
    this.toggleForumTab(acctype);
    if(sessionStorage.getItem('pregst_SortDir')!){
      const sortingDir = sessionStorage.getItem('pregst_SortDir')!;
      this.getPreGstSumm(acctype,column,sortingDir)
    }else{
      this.getPreGstSumm(acctype, column);
    }
    this.personResponsibleData();
    this.consultantData();
    this.getLegalRegNum();
  }

  toggleForumTab(acctype: Forum) {

    sessionStorage.setItem('pregst_Acctype_Key', acctype);


    // Map acctype values to their corresponding toggle and content IDs
    const toggleMap = {
        'preaja': { toggleId: 'pre-collapseToggle-1', contentId: 'pre-collapse-1' },
        'preapa': { toggleId: 'pre-collapseToggle-2', contentId: 'pre-collapse-2' },
        'preapt': { toggleId: 'pre-collapseToggle-3', contentId: 'pre-collapse-3' },
        'prehc': { toggleId: 'pre-collapseToggle-4', contentId: 'pre-collapse-4' },
        'presc': { toggleId: 'pre-collapseToggle-5', contentId: 'pre-collapse-5' }
    };

    if (acctype && toggleMap[acctype]) {
      const { toggleId, contentId } = toggleMap[acctype];
      const collapseToggle = document.getElementById(toggleId);
      const collapseContent = document.getElementById(contentId);

      if (collapseToggle && collapseContent) {
          this.isExpanded = !this.isExpanded;
          collapseToggle.setAttribute('aria-expanded', this.isExpanded.toString());
          if (this.isExpanded) {
              collapseContent.classList.remove('collapse'); 
              collapseContent.classList.add('collapsing'); 
              setTimeout(() => {
                  collapseContent.classList.remove('collapsing');
                  collapseContent.classList.add('collapse', 'show');
              }, 350); 
            } else {
              collapseContent.classList.remove('show'); 
              collapseContent.classList.add('collapsing'); 
              setTimeout(() => {
                  collapseContent.classList.remove('collapsing', 'collapse');
                  collapseContent.classList.add('collapse'); 
              }, 350);
            }
        }
    }
  }

  personResponsibleData() {
    let model: any = {};
    const navCOntextData: any = sessionStorage.getItem('navContext');
    model.companyId = navCOntextData.companyId;
    this.litService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        console.log('list:', this.consultantList);
      }
    });
  }
  getLegalRegNum() {
    const navContext = JSON.parse(
      this.getterSetter.getNavContextData() || '{}'
    );
    let model = {
      companyId: localStorage.getItem('selectedId'),
      userMailId: this.getterSetter.getSetUserId(),
    };
    this.entityService.getLegalRegNum(model).subscribe((response: any) => {
      if (response.status == 'SUCCESS') {
        this.leagalRegNum = response.response;
        localStorage.setItem('filterLegalRegNum', this.leagalRegNum);
      }
    });
  }
  getFormControlVal(val: any) {
    return this.filterForm.controls[val].value;
  }

  navDownload(caseId: any, module:any){
    //litigation-download
    this.router.navigate(['/litigationDownload', module ,caseId]);
 }

  createCase(action: string, type: string, tab: string) {
    const modalRef = this.modalService.open(CreateCaseModalComponent, {
      size: 'lg',
      windowClass: 'modal-xl preGStCreate-pop',
    });
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.isPre = true;
    modalRef.dismissed.subscribe((data) => {
      if (data === 'remanded') {
        this.router.navigate([
          '/createPreGstCase',
          { action: action, type: type, tab: tab, remanded: true },
        ]);
      } else if (data === 'new') {
        this.router.navigate([
          '/createPreGstCase',
          { action: action, type: type, tab: tab },
        ]);
      } else if (data === 'pre-Appellate') {
        this.router.navigate([
          '/createPreGstCase',
          { action: action, type: type, tab: tab, memorandum: true },
        ]);
      } else if (data === 'pre-HC') {
        this.router.navigate([
          '/createPreGstCase',
          { action: action, type: type, tab: tab, writHC: true },
        ]);
      }
    });

    // this.router.navigate(['/createPreGstCase', { action: action, type: type, tab: tab }]);
  }
  generateFilterData() {
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const data = JSON.parse(navCOntextData);
    const queryData: any = {
      actType: this.getFormControlVal('actType'),
      caseId: this.getFormControlVal('caseId'),
      caseLabel: this.getFormControlVal('caseLabel'),
      consultant: this.getFormControlVal('consultant'),
      amtQuery: this.getFormControlVal('amtQuery'),
      amtQueryValue: this.getFormControlVal('amtQueryValue'),
      div: this.getFormControlVal('div'),
      dd1: this.getFormControlVal('dd1'),
      dd2: this.getFormControlVal('dd2'),
      issue: this.getFormControlVal('issue'),
      kw: this.getFormControlVal('kw'),
      pf1: this.getFormControlVal('pf1'),
      pf2: this.getFormControlVal('pf2'),
      pt1: this.getFormControlVal('pt1'),
      pt2: this.getFormControlVal('pt2'),
      personResponsible1: this.getFormControlVal('pr'),
      regNo: this.getFormControlVal('regNo'),
      st: this.getFormControlVal('st'),
      state: this.getFormControlVal('state'),
    };
    this.criteria = [];
    for (const key in queryData) {
      // const criteriaObje: any = {};
      this.criteriaObje = {};
      if (
        queryData[key] != null &&
        key != 'dd2' &&
        key != 'amtQueryValue' &&
        key != 'pf2' &&
        key != 'pt2'
      ) {
        this.criteriaObje.p = key;
        this.criteriaObje.v = queryData[key];

        if (
          key == 'dd1' ||
          key == 'dd2' ||
          key == 'pf1' ||
          key == 'pf2' ||
          key == 'pt1' ||
          key == 'pt2'
        ) {
          this.criteriaObje.o = 'between';
        } else if (key == 'totOfTotal1') {
          if (queryData[key] === 'gt') {
            this.criteriaObje.o = 'gt';
          } else if (queryData[key] === 'gte') {
            this.criteriaObje.o = 'gte';
          } else if (queryData[key] === 'lt') {
            this.criteriaObje.o = 'lt';
          } else if (queryData[key] === 'lte') {
            this.criteriaObje.o = 'lte';
          } else if (queryData[key] === 'eq') {
            this.criteriaObje.o = 'eq';
          }
        } else if (key === 'state') {
          this.criteriaObje.o = 'in';
        } else if (key === 'st') {
          this.criteriaObje.o = 'in';
        }
        else if (key === 'actType') {
          this.criteriaObje.o = 'in';
        } else if (key === 'personResponsible1') {
          this.criteriaObje.o = 'eq';
        }
        else if (key === 'issue') {
          this.criteriaObje.o = 'eq';
        }
        else {
          this.criteriaObje.o = 'contains';
        }

        if (key == 'amtQuery') {
          this.criteriaObje.p = 'totOfTotal1';
          if (queryData[key] === 'gt') {
            this.criteriaObje.o = 'gt';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'gte') {
            this.criteriaObje.o = 'gte';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'lt') {
            this.criteriaObje.o = 'lt';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'lte') {
            this.criteriaObje.o = 'lte';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'eq') {
            this.criteriaObje.o = 'eq';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          }
          this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
        } else if (key == 'div') {
          this.criteriaObje.p = 'gstinDiv';
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        } else if (key == 'dd1') {
          this.criteriaObje.p = 'dueDate';
          this.criteriaObje.v1 = this.getFormControlVal('dd1');
          this.criteriaObje.v2 = this.getFormControlVal('dd2');
          delete this.criteriaObje.v;
          delete this.criteria.p;
        } else if (key == 'pf1') {
          this.criteriaObje.p = 'periodFromDate';
          this.criteriaObje.v1 = this.getFormControlVal('pf1');
          this.criteriaObje.v2 = this.getFormControlVal('pf2');
          delete this.criteriaObje.v;
          delete this.criteria.p;
        } else if (key == 'pt1') {
          this.criteriaObje.p = 'periodToDate';
          this.criteriaObje.v1 = this.getFormControlVal('pt1');
          this.criteriaObje.v2 = this.getFormControlVal('pt2');
          delete this.criteriaObje.v;
          delete this.criteria.p;
        } else if (key == 'st') {
          this.criteriaObje.p = 'status';
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        } else if (key == 'state') {
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        } else if (key == 'regNo') {
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        } else if (key == 'issue') {
          this.criteriaObje.p = 'issues.issue';
        } else if (key == 'kw') {
          this.criteriaObje.p = 'keyword';
        } else if (key == 'pr') {
          this.criteriaObje.p = 'personResponsible1';
        } else if (key == 'caseId') {
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray;
          this.criteriaObje.v = values;
        } else if (key == 'actType') {
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        }
        this.criteria.push(this.criteriaObje);
      }
    }

    this.filteredQueryData = _.omitBy(
      queryData,
      (v) => _.isUndefined(v) || _.isNull(v) || v === ''
    );
    this.generateBadges(this.filteredQueryData);
    return this.filteredQueryData;
  }
  initFilterForm(savedFilters: any) {
    if (savedFilters) {
      // this.first = savedFilters.start;
      // this.rows = savedFilters.cnt;
    }
    this.filterForm = this.formBuilder.group({
      pf1: [savedFilters?.pf1 ? savedFilters.pf1 : null],
      pf2: [savedFilters?.pf2 ? savedFilters.pf2 : null],
      pt1: [savedFilters?.pt1 ? savedFilters.pt1 : null],
      pt2: [savedFilters?.pt2 ? savedFilters.pt2 : null],
      dd1: [savedFilters?.dd1 ? savedFilters.dd1 : null],
      dd2: [savedFilters?.dd2 ? savedFilters.dd2 : null],
      kw: [savedFilters?.kw ? savedFilters.kw : null],
      pr: [savedFilters?.pr ? savedFilters.pr : null],
      st: [savedFilters?.st ? savedFilters.st : null],
      caseId: [savedFilters?.caseId ? savedFilters.caseId : null],
      caseLabel: [savedFilters?.caseLabel ? savedFilters.caseLabel : null],
      consultant: [savedFilters?.consultant ? savedFilters.consultant : null],
      amtQuery: [savedFilters?.amtQuery ? savedFilters.amtQuery : null],
      amtQueryValue: [
        savedFilters?.amtQueryValue ? savedFilters.amtQueryValue : null,
      ],
      issue: [savedFilters?.issue ? savedFilters.issue : null],
      div: [savedFilters?.div ? savedFilters.div : null],
      actType: [savedFilters?.actType ? savedFilters.actType : null],
      state: [savedFilters?.state ? savedFilters?.state : null],
      regNo: [savedFilters?.regNo ? savedFilters.regNo : null],
      isAnalyticIssueFilter: [
        savedFilters?.isAnalyticIssueFilter
          ? savedFilters.isAnalyticIssueFilter
          : null,
      ],
      isAnalyticStateFilter: [
        savedFilters?.isAnalyticStateFilter
          ? savedFilters.isAnalyticStateFilter
          : null,
      ],
      isAnalyticDivFilter: [
        savedFilters?.isAnalyticDivFilter
          ? savedFilters.isAnalyticDivFilter
          : null,
      ],
      showAcc: [true],
    });
  }
  applyFilter(isAddNewFilter: boolean, acctype: Forum, column?:any, sortingDir?:any) {
    this.isApplyFilter = true;
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const data = JSON.parse(navCOntextData);
    const filters = sessionStorage.getItem('pregst-filters-Data');
    let savedFilters = null;
    this.badgesList = [];
    if (isAddNewFilter) {
      this.generateFilterData();
    } else {
      if (filters) {
        savedFilters = !!filters ? JSON.parse(filters) : null;
        for (let i = 0; i < savedFilters.criterias.length; i++) {
          const element = savedFilters.criterias[i];
          const filteredQueryData: any = {};
          if (element.v1) {
            filteredQueryData[element.p] = element.v1;
          } else {
            filteredQueryData[element.p] = element.v;
          }
          this.generateBadges(filteredQueryData);
          const value = element.v;
          const keyName = element.p;
          this.filterForm.get(keyName)?.setValue(value);
        }
      }
    }
    if (!data.companyId) {
      data.companyId = sessionStorage.getItem('companyId');
    }

    // const sortingDir = sessionStorage.getItem('pregst_SortDir')!;
    const { sortField, sortDir } = this.getSortValues(acctype, column, sortingDir);
    this.forumSortMap[acctype]=  {sortField: sortField, sortDir: sortDir}

    const contextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(contextData);
    this.filterPayload = {
      page: 0,
      size: 10,
      sortfield: sortField,
      sortdir: sortDir,
      companyId: parse.companyId ? parse.companyId : data.companyId,
      criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
    };

    sessionStorage.setItem('Pregst-Filter', JSON.stringify(this.filterPayload));
    if(acctype){
      if(acctype == 'preaja'){
        const payload = {
          page: 0,
          size: this.perPageLimitSection1,
          sortfield: this.forumSortMap.preaja.sortField,
          sortdir: this.forumSortMap.preaja.sortDir,
          companyId: parse.companyId ? parse.companyId : data.companyId,
        criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        };
        this.preLitService.getPreGstSummaryAja(payload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofAJA = [];
          } else {
            this.SumofAJA = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
      } else if(acctype == 'preapa'){
        const payload = {
          page: 0,
          size: this.perPageLimitSection2,
          sortfield: this.forumSortMap.preapa.sortField,
          sortdir: this.forumSortMap.preapa.sortDir,
          companyId: parse.companyId ? parse.companyId : data.companyId,
        criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        };
        this.preLitService.getPreGstSummaryApa(payload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofAPA = [];
          } else {
            this.SumofAPA = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
      } else if(acctype == 'preapt'){
        const payload = {
          page: 0,
          size: this.perPageLimitSection3,
          sortfield: this.forumSortMap.preapt.sortField,
          sortdir: this.forumSortMap.preapt.sortDir,
          companyId: parse.companyId ? parse.companyId : data.companyId,
        criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        };
        this.preLitService.getPreGstSummaryApt(payload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofAPT = [];
          } else {
            this.SumofAPT = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
      } else if(acctype == 'prehc') {
        const payload = {
          page: 0,
          size: this.perPageLimitSection4,
          sortfield: this.forumSortMap.prehc.sortField,
          sortdir: this.forumSortMap.prehc.sortDir,
          companyId: parse.companyId ? parse.companyId : data.companyId,
        criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        };
        this.preLitService.getPreGstSummaryHc(payload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofHC = [];
          } else {
            this.SumofHC = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
      } else if(acctype == 'presc') {
        const payload = {
          page: 0,
          size: this.perPageLimitSection5,
          sortfield: this.forumSortMap.presc.sortField,
          sortdir: this.forumSortMap.presc.sortDir,
          companyId: parse.companyId ? parse.companyId : data.companyId,
        criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        };
        this.preLitService.getPreGstSummarySc(payload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofSC = [];
          } else {
            this.SumofSC = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
      }
    } else{
      const forumType = sessionStorage.getItem('pregst_Acctype_Key')!;
      if(forumType === 'preaja'){
        this.preLitService.getPreGstSummaryAja(this.filterPayload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofAJA = [];
          } else {
            this.SumofAJA = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
      } else if(forumType === 'preapa'){
        this.preLitService.getPreGstSummaryApa(this.filterPayload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofAPA = [];
          } else {
            this.SumofAPA = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
      } else if(forumType === 'preapt'){
        this.preLitService.getPreGstSummaryApt(this.filterPayload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofAPT = [];
          } else {
            this.SumofAPT = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
      } else if(forumType === 'prehc'){
        this.preLitService.getPreGstSummaryHc(this.filterPayload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofHC = [];
          } else {
            this.SumofHC = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
      } else if(forumType === 'presc') {
        this.preLitService.getPreGstSummarySc(this.filterPayload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofSC = [];
          } else {
            this.SumofSC = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
      }
    }
    
    this.filterPayload = {
      companyId: localStorage.getItem('selectedId'),
      criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
    }
    this.preLitService.getAllPreGstCaseCount(this.filterPayload).subscribe((res: any) => {
      this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
      this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
      this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
      this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
      this.tableSection5Config.totalItems = res.response[4].totCaseCnt;

      this.cntPreAJA = res.response[0].totCaseCnt;
      this.cntPreAPA = res.response[1].totCaseCnt;
      this.cntPreAPT = res.response[2].totCaseCnt;
      this.cntPreHC = res.response[3].totCaseCnt;
      this.cntPreSC = res.response[4].totCaseCnt;
      this.totalCase = res.response[5].totCaseCnt;


      this.cntPreAJAOp = res.response[0].openCaseCnt;
      this.cntPreAPAOp = res.response[1].openCaseCnt;
      this.cntPreAPTOp = res.response[2].openCaseCnt;
      this.cntPreHCOp = res.response[3].openCaseCnt;
      this.cntPreSCOp = res.response[4].openCaseCnt;
      this.opneCase = res.response[5].openCaseCnt;
    });

    if (this.filterPayload) {
      sessionStorage.setItem('pregst-filters-Data', JSON.stringify(this.filterPayload));
    }
    this.modalService.dismissAll();
  }
  currentStatusFilter(status: any, type: any) {
    this.badgesList = [];
    this.badgesList.push('Status');
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const data = JSON.parse(navCOntextData);
    const companyId = sessionStorage.getItem('companyId');
    const badgeObjStatus: any = {};
    badgeObjStatus.key = 'status';
    badgeObjStatus.name = 'Status';
    badgeObjStatus.value = status;
    this.badgesList.push(badgeObjStatus);
    const criteriaData = [
      {
        p: badgeObjStatus.key,
        o: 'contains',
        v: badgeObjStatus.value,
      },
    ];
    if (!data.companyId) {
      data.companyId = sessionStorage.getItem('companyId');
    }
    this.filterPayload = {
      page: 0,
      size: 10,
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: data.companyId ? data.companyId : companyId,
      criterias: criteriaData,
    };
    if (type == 'preaja') {
      this.preLitService
        .getPreGstSummaryAja(this.filterPayload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofAJA = [];
          } else {
            this.SumofAJA = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
    } else if (type == 'preapa') {
      this.preLitService
        .getPreGstSummaryApa(this.filterPayload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofAPA = [];
          } else {
            this.SumofAPA = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
    } else if (type == 'preapt') {
      this.preLitService
        .getPreGstSummaryApt(this.filterPayload).subscribe((res: any) => {
          if (res.response == null) {
            this.SumofAPT = [];
          } else {
            this.SumofAPT = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
    } else if (type == 'prehc') {
      this.preLitService
        .getPreGstSummaryHc(this.filterPayload)
        .subscribe((res: any) => {
          if (res.response == null) {
            this.SumofHC = [];
          } else {
            this.SumofHC = res.response;
            res.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                res.response[index].actType = 'OTHER TAX'
            })
          }
        });
    } else if (type == 'presc') {
      this.preLitService.getPreGstSummarySc(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.SumofSC = [];
        } else {
          this.SumofSC = res.response;
          res.response.forEach((obj: any, index: number) => {
            if (obj.actType.toUpperCase() === 'ENTRY TAX')
              res.response[index].actType = 'OTHER TAX'
          })
        }
      });
    }
  }
  generateBadges(queryData: any) {
    if (queryData != null) {
      if (this.badgesMap) {
        for (var key in this.badgesMap) {
          const badgeObj: any = {};
          if (queryData.hasOwnProperty(key)) {
            badgeObj.key = key;
            badgeObj.name = this.badgesMap[key];
            badgeObj.value = queryData[key];
            // this.badgesList.push(this.badgesMap[key]);
            this.badgesList.push(badgeObj);
          }
        }
      }
    }
  }
  reloadSummary() {
    this.getPreGstSumm('preaja');
  }
  removeFilter(badge: any) {
    this.badgesList = this.badgesList.filter((e) => e !== badge);
    switch (badge) {
      case 'Act':
        delete this.queryData['actType'];
        this.filterForm.patchValue({
          actType: null,
        });
        break;

      case 'Case Id':
        delete this.queryData['caseId'];
        this.filterForm.patchValue({
          caseId: null,
        });
        break;

      case 'Case Label':
        delete this.queryData['caseLabel'];
        this.filterForm.patchValue({
          caseLabel: null,
        });
        break;

      case 'Consultant':
        delete this.queryData['consultant'];
        this.filterForm.patchValue({
          consultant: null,
        });
        break;

      case 'Demand Amount':
        delete this.queryData['amtQuery'];
        delete this.queryData['amtQueryValue'];

        this.filterForm.patchValue({
          amtQuery: null,
          amtQueryValue: null,
        });
        break;

      case 'Divison':
        delete this.queryData['div'];

        this.filterForm.patchValue({
          div: null,
        });
        break;

      case 'Due Date':
        delete this.queryData['dt1'];
        delete this.queryData['dt2'];

        this.filterForm.patchValue({
          dt1: null,
          dt2: null,
        });
        break;

      case 'Key words':
        delete this.queryData['kw'];

        this.filterForm.patchValue({
          kw: null,
        });
        break;

      case 'Period From':
        delete this.queryData['pf1'];
        delete this.queryData['pf2'];

        this.filterForm.patchValue({
          pf1: null,
          pf2: null,
        });
        break;

      case 'Period To':
        delete this.queryData['pt1'];
        delete this.queryData['pt2'];

        this.filterForm.patchValue({
          pt1: null,
          pt2: null,
        });
        break;

      case 'Person Responsible':
        delete this.queryData['pr'];

        this.filterForm.patchValue({
          pr: null,
        });
        break;

      case 'Registration Number':
        delete this.queryData['regNo'];

        this.filterForm.patchValue({
          regNo: null,
        });
        break;

      case 'Status':
        delete this.queryData['st'];

        this.filterForm.patchValue({
          st: null,
        });
        break;

      case 'State':
        delete this.queryData['state'];

        this.filterForm.patchValue({
          state: null,
        });
        break;
    }
    const BadgArray = this.badgesList;

    const filterData: any = sessionStorage.getItem('Pregst-Filter');
    const parseData = JSON.parse(filterData);
    const criteriaArray: any = parseData.criterias;

    let matchedObjects = criteriaArray.filter((obj1: any) => {
      return this.badgesList.some(
        (obj2) => obj1.p === obj2.key && obj1.v === obj2.value
      );
    });

    const revisedFilter: any = sessionStorage.getItem('pregst-filters-Data');
    const parseFilterData = JSON.parse(revisedFilter);
    parseData.criterias = matchedObjects;
    parseFilterData.criterias = matchedObjects;

    sessionStorage.setItem('Pregst-Filter', JSON.stringify(parseData));
    sessionStorage.setItem('pregst-filters-Data', JSON.stringify(parseFilterData));

    this.reloadSummary();
    this.clearAll();
  }
  getPreGstSumm(acctype: Forum, column?:any, sortingDir?:any ) {

    const filters = sessionStorage.getItem('pregst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    

    if (filters) {
      this.applyFilter(false, acctype, column, sortingDir);
    } else {
      const { sortField, sortDir } = this.getSortValues(acctype, column, sortingDir);
      this.forumSortMap[acctype]=  {sortField: sortField, sortDir: sortDir}

      this.SumofAJA = [];
      let mainModel: any = {};
      const companyId = sessionStorage.getItem('companyId');
      mainModel.companyId = parse.companyId ? parse.companyId : companyId;

      if (acctype === 'preaja') {
        // let tempKeyword: any = [];
        this.SumofAJA = [];
        let model: any = {};
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        let countModel: any = {};
        countModel.page = this.pageSection2;
        countModel.size = this.tableSize;
        countModel.sortdir = 'DESC';
        countModel.companyId = parse.companyId ? parse.companyId : companyId;
        let tempKeyword: any = [];
        this.preLitService.getAllPreGstCaseCount(countModel).subscribe((res: any) => {
          this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
        });
        Object.assign(model, {
          page: this.tableSection1Config.currentPage == 1
            ? 0 : this.tableSection1Config.currentPage - 1,
          size: this.tableSection1Config.itemsPerPage,
          sortfield:sortField,
          sortdir: sortDir,
          companyId: parse.companyId ? parse.companyId : companyId
        });


        this.preLitService.getPreGstSummaryAja(model).subscribe(
          (response: any) => {
            if (response.status == 1) {
              this.SumofAJA = response.response;
              response.response.forEach((obj: any, index: number) => {
                if (obj.actType.toUpperCase() === 'ENTRY TAX')
                  response.response[index].actType = 'OTHER TAX'
              })
              this.SumofAJA.sort((a: any, b: any) => a.caseId - b.caseId);
              if (this.SumofAJA != null) {
                if (this.SumofAJA.length > 0) {
                  this.SumofAJA.forEach((element: any) => {
                    element.expanded = false;
                    tempKeyword = [];
                    if (element.issues1 && element.issues1.length > 0) {
                      element.issues1.forEach((e1: any) => {
                        tempKeyword.push(e1.issue);
                      });
                      element.ajakeywordName = tempKeyword.toString();
                    }
                  });
                }
              }
            }
          },
          (err) => {
            this.toaster.showError('User Role Based Access Restricted');
          }
        );
      } else if (acctype === 'preapa') {
        let tempKeyword: any = [];
        let model: any = {};
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        const companyId = sessionStorage.getItem('companyId');
        let countModel: any = {};
        countModel.page = this.pageSection3;
        countModel.size = this.tableSize;
        countModel.sortdir = 'DESC';
        countModel.companyId = parse.companyId ? parse.companyId : companyId;
        this.companyId = parse.companyId ? parse.companyId : companyId;

        this.preLitService.getAllPreGstCaseCount(countModel).subscribe((res: any) => {
          this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
        });
        Object.assign(model, {
          page:
            this.tableSection2Config.currentPage == 1 ? 0 : this.tableSection2Config.currentPage - 1,
          size: this.tableSection2Config.itemsPerPage,
          sortfield:sortField,
          sortdir: sortDir,
          companyId: parse.companyId ? parse.companyId : companyId
        });
        this.preLitService.getPreGstSummaryApa(model).subscribe(
          (response: any) => {
            if (response.status == 1) {
              this.SumofAPA = response.response;
              response.response.forEach((obj: any, index: number) => {
                if (obj.actType.toUpperCase() === 'ENTRY TAX')
                  response.response[index].actType = 'OTHER TAX'
              })
              this.SumofAPA.sort((a: any, b: any) => a.caseId - b.caseId);
              if (this.SumofAPA != null) {

                if (this.SumofAPA.length > 0) {
                  this.SumofAPA.forEach((element: any) => {
                    element.expanded = false;

                    if (element.issues1 && element.issues1.length > 0) {
                      //let tempKeyword: any = [];
                      element.issues1.forEach((e1: any) => {
                        tempKeyword.push(e1.issue);
                      });
                      element.apakeywordName = tempKeyword.toString();
                    }
                  });
                }
              }
            }
          },
          (err) => {
            this.toaster.showError('User Role Based Access Restricted');
          }
        );
      } else if (acctype === 'preapt') {
        let tempKeyword: any = [];
        let model: any = {};
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        const companyId = sessionStorage.getItem('companyId');
        let countModel: any = {};
        countModel.page = this.pageSection4;
        countModel.size = this.tableSize;

        countModel.sortdir = 'DESC';
        countModel.companyId = parse.companyId ? parse.companyId : companyId;

        this.companyId = parse.companyId ? parse.companyId : companyId;
        this.preLitService.getAllPreGstCaseCount(countModel).subscribe((res: any) => {
          this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
        });
        Object.assign(model, {
          page:
            this.tableSection3Config.currentPage == 1
              ? 0
              : this.tableSection3Config.currentPage - 1,
          size: this.perPageLimitSection3,
          sortfield:sortField,
          sortdir: sortDir,
          companyId: parse.companyId ? parse.companyId : companyId
        });
        this.preLitService.getPreGstSummaryApt(model).subscribe((response: any) => {
          if (response.status == 1) {
            this.SumofAPT = response.response;
            response.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                response.response[index].actType = 'OTHER TAX'
            })
            this.SumofAPT.sort((a: any, b: any) => a.caseId - b.caseId);
            if (this.SumofAPT != null) {
              if (this.SumofAPT.length > 0) {
                this.SumofAPT.forEach((element: any) => {
                  element.expanded = false;
                  if (element.issues1 && element.issues1.length > 0) {
                    //let tempKeyword: any = [];
                    element.issues1.forEach((e1: any) => {
                      tempKeyword.push(e1.issue);
                    });
                    element.aptkeywordName = tempKeyword.toString();
                  }
                });
              }
            }
          }
        });
      } else if (acctype === 'prehc') {
        let tempKeyword: any = [];
        let model: any = {};
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        const companyId = sessionStorage.getItem('companyId');
        let countModel: any = {};
        countModel.page = this.pageSection5;
        countModel.size = this.tableSize;

        countModel.sortdir = 'DESC';
        countModel.companyId = parse.companyId ? parse.companyId : companyId;
        this.companyId = parse.companyId ? parse.companyId : companyId;
        this.preLitService.getAllPreGstCaseCount(countModel).subscribe((res: any) => {
          this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
        });
        Object.assign(model, {
          page: this.tableSection4Config.currentPage == 1 ? 0 : this.tableSection4Config.currentPage - 1,
          size: this.perPageLimitSection4,
          sortfield:sortField,
          sortdir: sortDir,
          companyId: parse.companyId ? parse.companyId : companyId
        });
        this.preLitService
          .getPreGstSummaryHc(model)
          .subscribe((response: any) => {
            if (response.status == 1) {
              this.SumofHC = response.response;
              response.response.forEach((obj: any, index: number) => {
                if (obj.actType.toUpperCase() === 'ENTRY TAX')
                  response.response[index].actType = 'OTHER TAX'
              })
              this.SumofHC.sort((a: any, b: any) => a.caseId - b.caseId);
              if (this.SumofHC != null) {
                if (this.SumofHC.length > 0) {
                  this.SumofHC.forEach((element: any) => {
                    element.expanded = false;
                    if (element.issues1 && element.issues1.length > 0) {
                      //let tempKeyword: any = [];
                      element.issues1.forEach((e1: any) => {
                        tempKeyword.push(e1.issue);
                      });
                      element.hckeywordName = tempKeyword.toString();
                    }
                  });
                }
              }
            }
          });
      } else if (acctype === 'presc') {
        let tempKeyword: any = [];
        let model: any = {};
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        const companyId = sessionStorage.getItem('companyId');
        let countModel: any = {};
        countModel.page = this.pageSection6;
        countModel.size = this.tableSize;

        countModel.sortdir = 'DESC';
        countModel.companyId = parse.companyId ? parse.companyId : companyId;
        this.companyId = parse.companyId ? parse.companyId : companyId;
        this.preLitService.getAllPreGstCaseCount(countModel).subscribe((res: any) => {
          this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
        });
        Object.assign(model, {
          page:
            this.tableSection5Config.currentPage == 1
              ? 0
              : this.tableSection5Config.currentPage - 1,
          size: this.perPageLimitSection5,
          sortfield:sortField,
          sortdir: sortDir,
          companyId: parse.companyId ? parse.companyId : companyId
        });
        this.preLitService.getPreGstSummarySc(model).subscribe((response: any) => {
          if (response.status == 1) {
            this.SumofSC = response.response;
            response.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                response.response[index].actType = 'OTHER TAX'
            })
            this.SumofSC.sort((a: any, b: any) => a.caseId - b.caseId);
            if (this.SumofSC != null) {
              if (this.SumofSC.length > 0) {
                this.SumofSC.forEach((element: any) => {
                  element.expanded = false;
                  if (element.issues1 && element.issues1.length > 0) {
                    element.issues1.forEach((e1: any) => {
                      tempKeyword.push(e1.issue);
                    });
                    element.sckeywordName = tempKeyword.toString();
                  }
                });
              }
            }
          }
        });
      } else {
        this.preLitService.getPreGstSummaryAja(mainModel).subscribe((response: any) => {
          if (response.status == 1) {
            this.SumofAJA = response.response;
            response.response.forEach((obj: any, index: number) => {
              if (obj.actType.toUpperCase() === 'ENTRY TAX')
                response.response[index].actType = 'OTHER TAX'
            })
          }
        });
      }
    }

  }
  getCaseData(caseId: number, action: string, type: string, tab: string) {
    console.log(type);
    this.router.navigate([
      '/createPreGstCase',
      caseId,
      { action: action, type: type, tab: tab },
    ]);
  }
  getFileNameDta(fileUrl: any) {
    return this.preLitService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl,
    };
    this.preLitService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.url;
        //this.downloadfileUrl() ;
        window.open(this.fileUrl, '_blank');
      }
    });
  }


  showHidePop() {
    this.showPop = !this.showPop;
    setTimeout(() => {
      // set it to run again after ten minutes
      this.showPop = false;
      this.toaster.showError(
        'Request is time out, please place new request after some time.'
      );
    }, 600000);
  }


  generatedReport(option: string) {
    if (option == 'Contingency') {
      this.showHeader = true;
    } else if (option == 'Master') {
      this.showHeader = false;
    }
    this.showHidePop();
    const filters = sessionStorage.getItem('pregst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    let model: any = {};
    const criteriaArray: any = [{
      "p": "pan",
      "o": "eq",
      "v": this.navContext.pan
    }]
    if (this.navContext.entityType === 'Business') {
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 2;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? savedFilters.criterias : [];

    } else if (this.navContext.entityType === 'LEGAL') {
      const savedFilterCritera = savedFilters !== null ? savedFilters.criterias : [];
      const mergeCriteriaArray = [...savedFilterCritera, ...criteriaArray];
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 2;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? savedFilters.criterias : [];
      //model.criterias = (savedFilters && !criteriaArray) ? this.criteria : (!savedFilters && criteriaArray) ? criteriaArray : mergeCriteriaArray;

    }
    this.preLitService.generateReport(model, '2').subscribe((response) => {
      this.toaster.showSuccess("Request submitted successfully");
    })

  }
  closeDiv() {
    this.showPop = false;
  }

  relopageRefresh() {
    if (this.router.url.includes('preGstSummary')) {
      this.commonServ.reloadCurrentRoute();
    }
  }

  statusReport() {
    this.preLitService.statusMasterReport().subscribe((response: any) => {
      this.statusData = response.response.status;
      console.log(this.statusData, ' this.statusData');
      if (this.statusData == 1) {
        this.showStatus = false;
        this.toaster.showSuccess('Request processed successfully');
      } else {
        this.toaster.showWarning('Request in progress');
      }
    });
  }

  contingencyReport(option: string) {
    if (option == 'Contingency') {
      this.showHeader = true;
    } else if (option == 'Master') {
      this.showHeader = false;
    }
    this.showHidePop();
    const filters = sessionStorage.getItem('pregst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    let model: any = {};
    const criteriaArray: any = [{
      "p": "pan",
      "o": "eq",
      "v": this.navContext.pan
    }]
    if (this.navContext.entityType === 'Business') {
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 4;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? savedFilters.criterias : [];

    } else if (this.navContext.entityType === 'LEGAL') {
      const savedFilterCritera = savedFilters !== null ? savedFilters.criterias : [];
      const mergeCriteriaArray = [...savedFilterCritera, ...criteriaArray];
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 4;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? savedFilters.criterias : [];
      //model.criterias = (savedFilters && !criteriaArray) ? this.criteria : (!savedFilters && criteriaArray) ? criteriaArray : mergeCriteriaArray;

    }
    this.preLitService.generateReport(model, '4').subscribe((response) => {
      this.toaster.showSuccess("Request submitted successfully");
    })

  }

  downLoadMasterReport() {
    this.preLitService.downLoadMasterReports().subscribe((response) => {
      const rootPan = sessionStorage.getItem('company');
      const date = this.commonServ.getCurrentDate();
      let fileName = `${rootPan}_PREGST Litigation Report_${date}`;
      if (this.showHeader) {
        fileName = `${rootPan}_PROV & CONT__PREGST_Litigation Report_${date}`;
      }
      this.excelService.saveAsExcelFile(response, fileName, true);
      this.checkIcon = true;
      this.toaster.showSuccess('File downloaded successfully');
      this.relopageRefresh();
    });
  }
  // downLoadMasterReport() {
  //   this.preLitService.downLoadMasterReports().subscribe((response) => {
  //     const rootPan = sessionStorage.getItem('company');
  //     const date = this.commonServ.getCurrentDate();
  //     let fileName = `${rootPan}_GST Litigation Report_${date}`;
  //     if (this.showHeader) {
  //       fileName = `${rootPan}_PROV & CONT_Litigation Report_${date}`;
  //     }
  //     this.excelService.saveAsExcelFile(response, fileName, true);
  //     this.checkIcon = true;
  //     this.toaster.showSuccess('File downloaded successfully');
  //     this.relopageRefresh();
  //   });
  // }

  downloadFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute('href', url);
    let datetime = formatDate(new Date(), 'dd-MM-yyyy', 'en-US', '+0530');

    dwldLink.setAttribute(
      'download',
      `${this.pan}_Pregst_Report_${datetime}.xlsx`
    );
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
    this.toaster.showSuccess('Report download successfully');
  }

  getKeyword(obj: any) {
    let keyword: any = [];
    if (obj.issues) {
      if (obj.issues.length > 0) {
        keyword = obj.issues.map((i: any) => i.keyword?.trim());
      }
    } else {
      if (obj.itemList.length > 0) {
        obj.itemList.forEach((item: any) => {
          const itemKeywords = item.issues.map((i: any) => i.keyword?.trim());
          keyword = [...keyword, ...itemKeywords];
        });
      }
    }
    const filterKeyword = keyword.filter((item: any) => item);
    return filterKeyword.length > 0
      ? filterKeyword.join(', ')
        ? filterKeyword.join(', ').length > 0
          ? filterKeyword.join(', ')
          : '--'
        : '--'
      : '--';
  }

  openDiv() {
    this.isFilterDivOpen = true;

  }



  filterDataCount() {
    if (Object.keys(this.filterObj).length) {
      let body = cloneDeep(this.filterObj);
      if (body.pf1 && body.pf2) {
        body.pf1 = this.datePipe.transform(body.pf1, 'dd-MM-yyyy');
        body.pf2 = this.datePipe.transform(body.pf2, 'dd-MM-yyyy');
      }
      if (body.pt1 && body.pt2) {
        body.pt1 = this.datePipe.transform(body.pt1, 'dd-MM-yyyy');
        body.pt2 = this.datePipe.transform(body.pt2, 'dd-MM-yyyy');
      }
      if (body.dd1 && body.dd2) {
        body.dd1 = this.datePipe.transform(body.dd1, 'dd-MM-yyyy');
        body.dd2 = this.datePipe.transform(body.dd2, 'dd-MM-yyyy');
      }

      if (this.navContext.entityType === 'LEGAL') {
        body.pan = this.navContext.pan;
        body.companyId = this.companyId;
      }

      // this.preLitService.getFilterCount(body).subscribe((response: any) => {
      //   if (response.status == 1) {

      //   }
      // });
    }
  }
  isFilterApplied(acctype: any) {
    this.activeTab = acctype;
    if (this.isApplyFilter) {
      // if (acctype == 'preaja') {
      //   const payLoad = sessionStorage.getItem('Pregst-Filter')!;
      //   const data = JSON.parse(payLoad);

      //   for (let index = 0; index < data.criterias.length; index++) {
      //     const element = data.criterias[index];

      //     if (element.p == 'dueDate') {
      //       element.p = 'dueDate';
      //     } else if (element.p == 'itemList.periodFromDate') {
      //       element.p = 'periodFromDate';
      //     } else if (element.p == 'itemList.periodToDate') {
      //       element.p = 'periodToDate';
      //     } else if (element.p == 'itemList.issues.issue') {
      //       element.p = 'issues.issue';
      //     } else if (element.p == 'itemList.issues.keyword') {
      //       element.p = 'issues.keyword';
      //     }
      //   }
      //   this.preLitService.getPreGstSummaryAja(data).subscribe((res: any) => {
      //     this.SumofAJA = res.response;
      //   });
      // } 
      if(acctype == 'preaja'){
        const payLoad = sessionStorage.getItem('Pregst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection1;
        data.sortfield = this.forumSortMap.preaja.sortField;
        data.sortdir = this.forumSortMap.preaja.sortDir;

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.preLitService.getPreGstSummaryAja(data).subscribe((res: any) => {
          if(res.response == null){
            this.SumofAJA = [];
          } else if(res.response !== null){
          this.SumofAJA = res.response;
          res.response.forEach((obj: any, index: number) => {
            if (obj.actType.toUpperCase() === 'ENTRY TAX')
              res.response[index].actType = 'OTHER TAX'
          })
          }
        });
      } else if (acctype == 'preapa') {
        const payLoad = sessionStorage.getItem('Pregst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection2;
        data.sortfield = this.forumSortMap.preapa.sortField;
        data.sortdir = this.forumSortMap.preapa.sortDir;

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.preLitService.getPreGstSummaryApa(data).subscribe((res: any) => {
          if(res.response == null){
            this.SumofAPA = [];
          } else{
          this.SumofAPA = res.response;
          res.response.forEach((obj: any, index: number) => {
            if (obj.actType.toUpperCase() === 'ENTRY TAX')
              res.response[index].actType = 'OTHER TAX'
          })
          }
        });
      } else if (acctype == 'preapt') {
        const payLoad = sessionStorage.getItem('Pregst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection3;
        data.sortfield = this.forumSortMap.preapt.sortField;
        data.sortdir = this.forumSortMap.preapt.sortDir;

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.preLitService.getPreGstSummaryApt(data).subscribe((res: any) => {
          if(res.response == null){
            this.SumofAPT = []; 
          } else {
              this.SumofAPT = res.response;
              res.response.forEach((obj: any, index: number) => {
                if (obj.actType.toUpperCase() === 'ENTRY TAX')
                  res.response[index].actType = 'OTHER TAX'
              });
          }
        });
      } else if (acctype == 'prehc') {
        const payLoad = sessionStorage.getItem('Pregst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection4;
        data.sortfield = this.forumSortMap.prehc.sortField;
        data.sortdir = this.forumSortMap.prehc.sortDir;


        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.preLitService.getPreGstSummaryHc(data).subscribe((res: any) => {
          if(res.response == null){
            this.SumofHC = [];
          }else {
            this.SumofHC = res.response;
              res.response.forEach((obj: any, index: number) => {
                if (obj.actType.toUpperCase() === 'ENTRY TAX')
                  res.response[index].actType = 'OTHER TAX'
              })
          }
        });
      } else if (acctype == 'presc') {
        const payLoad = sessionStorage.getItem('Pregst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection5;
        data.sortfield = this.forumSortMap.presc.sortField;
        data.sortdir = this.forumSortMap.presc.sortDir;

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.preLitService.getPreGstSummarySc(data).subscribe((res: any) => {
          if(res.response == null){
            this.SumofSC = [];
          }else {
            this.SumofSC = res.response;
              res.response.forEach((obj: any, index: number) => {
                if (obj.actType.toUpperCase() === 'ENTRY TAX')
                  res.response[index].actType = 'OTHER TAX'
              })
          }
        });
      }
    } else {
      this.getPreGstSumm(acctype);
    }
  }
  getSortValues(acctype: Forum, column?: any, sortingDir?:string): SortBy {
    sessionStorage.setItem('pregst_Column_Key', column);

    let result: SortBy;


    switch (acctype) {
        case 'preaja':
            result = this.sort(column, sortingDir);
            break;
        case 'preapa':
            result = this.commissionerSort(column, sortingDir);
            break;
        case 'preapt':
            result = this.appellatetribunalSort(column, sortingDir);
            break;
        case 'prehc':
            result = this.highCourtSort(column, sortingDir);
            break;
        case 'presc':
            result = this.supremeCourtSort(column, sortingDir);
            break;
        default:
            result = { sortField: 'createdOn', sortDir: 'DESC' };
            break;
    }

    sessionStorage.setItem('pregst_SortDir', result.sortDir);
    sessionStorage.setItem('pregst_SortField', result.sortField);

    return result;
  }

  sort(column?: string, sortingDir?:string) {
  let sortField = '';
  let sortDir = '';

  switch (column) {
    case PRELITIGATION_SORT.CASE_ID: {
      sortField = 'caseId';
      if (sortingDir){
        if(sortingDir === 'ASC'){
          this.sorting.caseId = true;
          sortDir = 'ASC';
        } else {
          this.sorting.caseId = false;
          sortDir = 'DESC';
        }
      } else{
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          sortDir = 'ASC';
        } else {
          this.sorting.caseId = false;
          sortDir = 'DESC';
        }
      }
      break;
    }
    case PRELITIGATION_SORT.STATE: {
      sortField = 'state';
      if (sortingDir){
        if(sortingDir === 'ASC'){
          this.sorting.state = true;
          sortDir = 'ASC';
        } else {
          this.sorting.state = false;
          sortDir = 'DESC';
        }
      } else{
        if (!this.sorting.state) {
          this.sorting.state = true;
          sortDir = 'ASC';
        } else {
          this.sorting.state = false;
          sortDir = 'DESC';
        }
      }
      break;
    }
    case PRELITIGATION_SORT.ACT: {
      sortField = 'actType';
      if (sortingDir){
        if(sortingDir === 'ASC'){
          this.sorting.actType = true;
          sortDir = 'ASC';
        } else {
          this.sorting.actType = false;
          sortDir = 'DESC';
        }
      } else{
        if (!this.sorting.actType) {
          this.sorting.actType = true;
          sortDir = 'ASC';
        } else {
          this.sorting.actType = false;
          sortDir = 'DESC';
        }
      }
      break;
    }
    case PRELITIGATION_SORT.CASE_LABEL: {
      sortField = 'caseLabel';
      if (sortingDir){
        if(sortingDir === 'ASC'){
          this.sorting.caseLabel = true;
          sortDir = 'ASC';
        } else {
          this.sorting.caseLabel = false;
          sortDir = 'DESC';
        }
      } else{
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          sortDir = 'ASC';
        } else {
          this.sorting.caseLabel = false;
          sortDir = 'DESC';
        }
      }
      break;
    }
    case PRELITIGATION_SORT.PERIOD_FROM: {
      sortField = 'periodFromDate';
      if (sortingDir){
        if(sortingDir === 'ASC'){
          this.sorting.periodFromDate = true;
          sortDir = 'ASC';
        } else {
          this.sorting.periodFromDate = false;
          sortDir = 'DESC';
        }
      } else{
        if (!this.sorting.periodFromDate) {
          this.sorting.periodFromDate = true;
          sortDir = 'ASC';
        } else {
          this.sorting.periodFromDate = false;
          sortDir = 'DESC';
        }
      }
      break;
    }
    case PRELITIGATION_SORT.PERIOD_TO: {
      sortField = 'periodToDate';
      if (sortingDir){
        if(sortingDir === 'ASC'){
          this.sorting.periodToDate = true;
          sortDir = 'ASC';
        } else {
          this.sorting.periodToDate = false;
          sortDir = 'DESC';
        }
      } else{
        if (!this.sorting.periodToDate) {
          this.sorting.periodToDate = true;
          sortDir = 'ASC';
        } else {
          this.sorting.periodToDate = false;
          sortDir = 'DESC';
        }
      }
      break;
    }
    case PRELITIGATION_SORT.STATUS: {
      sortField = 'status';
      if (sortingDir){
        if(sortingDir === 'ASC'){
          this.sorting.status = true;
          sortDir = 'ASC';
        } else {
          this.sorting.status = false;
          sortDir = 'DESC';
        }
      } else{
        if (!this.sorting.status) {
          this.sorting.status = true;
          sortDir = 'ASC';
        } else {
          this.sorting.status = false;
          sortDir = 'DESC';
        }
      }
      break;
    }
    case PRELITIGATION_SORT.DEMAND: {
      sortField = 'totOfTotal1';
      if (sortingDir){
        if(sortingDir === 'ASC'){
          this.sorting.totOfTotal1 = true;
          sortDir = 'ASC';
        } else {
          this.sorting.totOfTotal1 = false;
          sortDir = 'DESC';
        }
      } else{
        if (!this.sorting.totOfTotal1) {
          this.sorting.totOfTotal1 = true;
          sortDir = 'ASC';
        } else {
          this.sorting.totOfTotal1 = false;
          sortDir = 'DESC';
        }
      }
      break;
    }
    default: {
      sortField = 'createdOn';
      sortDir = 'DESC';
      break;
    }
  }

  return { sortField, sortDir } as SortBy;
  }

  commissionerSort(column?: string, sortingDir?:string) {
    let sortField = '';
    let sortDir = '';
  
    switch (column) {
      case COMMISSIONER_STAGE.CASE_ID: {
        sortField = 'caseId';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseId) {
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.STATE: {
        sortField = 'state';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.state) {
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.ACT:{
        sortField = 'actType'
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.actType = true;
            sortDir = 'ASC';
          } else {
            this.sorting.actType = false;
            sortDir = 'DESC';
          }
        } else{
          if(!this.sorting.actType){
            this.sorting.actType = true;
            sortDir = 'ASC';
          } else {
            this.sorting.actType = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.CASE_LABEL: {
        sortField = 'caseLabel';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseLabel) {
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.PERIOD_FROM: {
        sortField = 'periodFromDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodFromDate) {
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.PERIOD_TO: {
        sortField = 'periodToDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;  
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodToDate) {
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.STATUS: {
        sortField = 'status';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.status) {
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.DEMAND: {
        sortField = 'totOfTotal1';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.totOfTotal1) {
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      default: {
        sortField = 'createdOn';
        sortDir = 'DESC';
        break;
      }
    }
  
    return { sortField, sortDir } as SortBy;
  }

  appellatetribunalSort(column?: string, sortingDir?:string) {
    let sortField = '';
    let sortDir = '';
  
    switch (column) {
      case APPELLATET_STAGE.CASE_ID: {
        sortField = 'caseId';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseId) {
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.STATE: {
        sortField = 'state';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.state) {
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.ACT: {
        sortField = 'actType';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.actType = true;
            sortDir = 'ASC';
          } else {
            this.sorting.actType = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.actType) {
            this.sorting.actType = true;
            sortDir = 'ASC';
          } else {
            this.sorting.actType = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.CASE_LABEL: {
        sortField = 'caseLabel';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseLabel) {
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.PERIOD_FROM: {
        sortField = 'periodFromDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodFromDate) {
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.PERIOD_TO: {
        sortField = 'periodToDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodToDate) {
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.STATUS: {
        sortField = 'status';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.status) {
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.DEMAND: {
        sortField = 'totOfTotal1';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.totOfTotal1) {
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      default: {
        sortField = 'createdOn';
        sortDir = 'DESC';
        break;
      }
    }
  
    return { sortField, sortDir } as SortBy;
  }

  highCourtSort(column?: string, sortingDir?:string) {
    let sortField = '';
    let sortDir = '';
  
    switch (column) {
      case PRELITIGATION_SORT.CASE_ID: {
        sortField = 'caseId';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;  
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseId) {
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.STATE: {
        sortField = 'state';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.state) {
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.ACT: {
        sortField = 'actType';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.actType = true;
            sortDir = 'ASC';
          } else {
            this.sorting.actType = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.actType) {
            this.sorting.actType = true;
            sortDir = 'ASC';
          } else {
            this.sorting.actType = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.CASE_LABEL: {
        sortField = 'caseLabel';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseLabel) {
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.PERIOD_FROM: {
        sortField = 'periodFromDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodFromDate) {
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.PERIOD_TO: {
        sortField = 'periodToDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodToDate) {
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.STATUS: {
        sortField = 'status';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.status) {
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.DEMAND: {
        sortField = 'totOfTotal1';
      if (sortingDir){
        if(sortingDir === 'ASC'){
          this.sorting.totOfTotal1 = true;
          sortDir = 'ASC';
        } else {
          this.sorting.totOfTotal1 = false;
          sortDir = 'DESC';
        }
      } else{
        if (!this.sorting.totOfTotal1) {
          this.sorting.totOfTotal1 = true;
          sortDir = 'ASC';
        } else {
          this.sorting.totOfTotal1 = false;
          sortDir = 'DESC';
        }
      }
        break;
      }
      default: {
        sortField = 'createdOn';
        sortDir = 'DESC';
        break;
      }
    }
  
    return { sortField, sortDir } as SortBy;
  }

  supremeCourtSort(column?: string, sortingDir?:string) {
    let sortField = '';
    let sortDir = '';
  
    switch (column) {
      case PRELITIGATION_SORT.CASE_ID: {
        sortField = 'caseId';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseId) {
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.STATE: {
        sortField = 'state';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.state) {
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.ACT: {
        sortField = 'actType';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.actType = true;
            sortDir = 'ASC';
          } else {
            this.sorting.actType = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.actType) {
            this.sorting.actType = true;
            sortDir = 'ASC';
          } else {
            this.sorting.actType = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.CASE_LABEL: {
        sortField = 'caseLabel';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseLabel) {
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.PERIOD_FROM: {
        sortField = 'periodFromDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodFromDate) {
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.PERIOD_TO: {
        sortField = 'periodToDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodToDate) {
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.STATUS: {
        sortField = 'status';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.status) {
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.DEMAND: {
        sortField = 'totOfTotal1';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false; 
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.totOfTotal1) {
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      default: {
        sortField = 'createdOn';
        sortDir = 'DESC';
        break;
      }
    }
  
    return { sortField, sortDir } as SortBy;
  }
  
  getAjaData(perPageLimitSection1: any, section1page: number, option: string) {
    this.SumofAJA = [];
    let tempKeyword: any = [];
    let model: any = {};

    const sortField =  this.forumSortMap.preaja.sortField
    const sortDir = this.forumSortMap.preaja.sortDir
    
    const contextData: any = sessionStorage.getItem('navContext');
    // const parse = JSON.parse(contextData);
    // const contextData: any = sessionStorage.getItem('navContext');
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    const companyId = sessionStorage.getItem('companyId')
    const filters = sessionStorage.getItem('pregst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    };
    if (this.navContext.entityType === 'Business') {
      model.pan = '';
      model.type = this.tabType[0].key;
      model.companyId = parse.companyId ? parse.companyId : companyId;
    } else if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.type = this.tabType[0].key;
      model.companyId = parse.companyId ? parse.companyId : companyId;
    }
    let countModel: any = {};
    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    countModel.caseId = 'caseId';
    countModel.companyId = this.selectedCompanyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    this.preLitService.getAllPreGstCaseCount(countModel).subscribe((res: any) => {
        this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
      });
    Object.assign(model, {
      page: section1page == 1 ? 0 : section1page - 1,
      size: perPageLimitSection1,
      sortfield: sortField ,
      sortdir: sortDir,
      criterias: savedFilters ? savedFilters.criterias : []
    });
    this.preLitService.getPreGstSummaryAja(model).subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.SumofAJA = response.response;
          response.response.forEach((obj: any, index: number) => {
            if (obj.actType.toUpperCase() === 'ENTRY TAX')
              response.response[index].actType = 'OTHER TAX'
          })
          this.preLitService
            .getAllPreGstCaseCount(countModel)
            .subscribe((res: any) => {
              this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
            });
          if (option === "select") {
            this.tableSection1Config.totalItems = response.response.count;
            this.tableSection1Config.itemsPerPage = perPageLimitSection1;
            this.tableSection1Config.currentPage = model.pageNumber + 1;
          }
          if (this.SumofAJA) {
            if (this.SumofAJA.length > 0) {
              this.SumofAJA.forEach((element: any) => {
                element.expanded = false;
                tempKeyword = [];
                if (element.issues1 && element.issues1.length > 0) {
                  element.issues1.forEach((e1: any) => {
                    tempKeyword.push(e1.issue);
                  });
                  element.ajakeywordName = tempKeyword.toString();
                }
              });
            }
          }
        }
      },
      (err) => {
        this.toaster.showError('User Role Based Access Restricted');
      }
    );
  }
  getApaData(perPageLimitSection2: any, section2page: number, option: string) {
    this.SumofAPA = [];
    let tempKeyword: any = [];
    let model: any = {};

    const sortField =  this.forumSortMap.preapa.sortField
    const sortDir = this.forumSortMap.preapa.sortDir

    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    const companyId = sessionStorage.getItem('companyId');

    const filters = sessionStorage.getItem('pregst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }

    if (this.navContext.entityType === 'Business') {
      model.pan = '';
      model.type = this.tabType[1].key;
      model.companyId = parse.companyId ? parse.companyId : companyId;
    } else if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.type = this.tabType[1].key;
      model.companyId = parse.companyId ? parse.companyId : companyId;
    }

    let countModel: any = {};
    countModel.page = this.pageSection2;
    countModel.size = this.tableSize;
    countModel.caseId = 'caseId';
    countModel.sortdir = 'DESC';
    countModel.companyId = this.selectedCompanyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    this.preLitService
      .getAllPreGstCaseCount(countModel)
      .subscribe((res: any) => {
        this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
      });
    Object.assign(model, {
      page: section2page == 1 ? 0 : section2page - 1,
      size: perPageLimitSection2,
      sortfield: sortField ,
      sortdir: sortDir,
      criterias:  savedFilters ? savedFilters.criterias : []
    });
    this.preLitService.getPreGstSummaryApa(model).subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.SumofAPA = response.response;
          response.response.forEach((obj: any, index: number) => {
            if (obj.actType.toUpperCase() === 'ENTRY TAX')
              response.response[index].actType = 'OTHER TAX'
          })
          this.preLitService
            .getAllPreGstCaseCount(countModel)
            .subscribe((res: any) => {
              this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
            });
          if (option === "select") {
            this.tableSection2Config.totalItems = response.response.count;
            this.tableSection2Config.itemsPerPage = perPageLimitSection2;
            this.tableSection2Config.currentPage = model.pageNumber + 1;
          }
          if (this.SumofAPA.length > 0) {
            this.SumofAPA.forEach((element: any) => {
              element.expanded = false;
              if (element.issues1 && element.issues1.length > 0) {
                element.issues1.forEach((e1: any) => {
                  tempKeyword.push(e1.issue);
                });
                element.apakeywordName = tempKeyword.toString();
              }
            });
          }
        }
      },
      (err) => {
        this.toaster.showError('User Role Based Access Restricted');
      }
    );
  }
  getAptData(perPageLimitSection3: any, section3page: number, option: string) {
    this.SumofAPT = [];
    let tempKeyword: any = [];
    let model: any = {};

    const sortField =  this.forumSortMap.preapt.sortField
    const sortDir = this.forumSortMap.preapt.sortDir

    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    const companyId = sessionStorage.getItem('companyId');
    const filters = sessionStorage.getItem('pregst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    if (this.navContext.entityType === 'Business') {
      model.pan = '';
      model.type = this.tabType[2].key;
      model.companyId = parse.companyId ? parse.companyId : companyId;
    } else if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.type = this.tabType[2].key;
      model.companyId = parse.companyId ? parse.companyId : companyId;
    }
    let countModel: any = {};
    countModel.page = this.pageSection3;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    countModel.companyId = parse.companyId ? parse.companyId : companyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];

    this.preLitService
      .getAllPreGstCaseCount(countModel)
      .subscribe((res: any) => {
        this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
      });
    Object.assign(model, {
      page: section3page == 1 ? 0 : section3page - 1,
      size: perPageLimitSection3,
      sortfield: sortField ,
      sortdir: sortDir,
      criterias:  savedFilters ? savedFilters.criterias : []
    });
    this.preLitService.getPreGstSummaryApt(model).subscribe(
      (response: any) => {
        if (response.status == 1) {
          this.SumofAPT = response.response;
          response.response.forEach((obj: any, index: number) => {
            if (obj.actType.toUpperCase() === 'ENTRY TAX')
              response.response[index].actType = 'OTHER TAX'
          })
          this.preLitService
            .getAllPreGstCaseCount(countModel)
            .subscribe((res: any) => {
              this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
            });
          if (option === "select") {
            this.tableSection3Config.totalItems = response.response.count;
            this.tableSection3Config.itemsPerPage = perPageLimitSection3;
            this.tableSection3Config.currentPage = model.pageNumber + 1;
          }
          if (this.SumofAPT.length > 0) {
            this.SumofAPT.forEach((element: any) => {
              element.expanded = false;
              if (element.issues1 && element.issues1.length > 0) {
                element.issues1.forEach((e1: any) => {
                  tempKeyword.push(e1.issue);
                });
                element.aptkeywordName = tempKeyword.toString();
              }
            });
          }
        }
      },
      (err) => {
        this.toaster.showError('User Role Based Access Restricted');
      }
    );
  }
  getHcData(perPageLimitSection4: any, section4page: number, option: string) {
    this.SumofHC = [];
    let tempKeyword: any = [];
    let model: any = {};

    const sortField =  this.forumSortMap.prehc.sortField
    const sortDir = this.forumSortMap.prehc.sortDir

    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    const companyId = sessionStorage.getItem('companyId');
    const filters = sessionStorage.getItem('pregst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    if (this.navContext.entityType === 'Business') {
      model.pan = '';
      model.type = this.tabType[3].key;
      model.companyId = parse.companyId ? parse.companyId : companyId;
    } else if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.type = this.tabType[3].key;
      model.companyId = parse.companyId ? parse.companyId : companyId;
    }

    let countModel: any = {};
    countModel.page = this.pageSection4;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    countModel.companyId = parse.companyId ? parse.companyId : companyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];

    this.preLitService.getAllPreGstCaseCount(countModel).subscribe((res: any) => {
      this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
    });

    Object.assign(model, {
      page: section4page == 1 ? 0 : section4page - 1,
      size: perPageLimitSection4,
      sortfield: sortField ,
      sortdir: sortDir,
      criterias:  savedFilters ? savedFilters.criterias : []
    });
    this.preLitService.getPreGstSummaryHc(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.SumofHC = response.response;
        response.response.forEach((obj: any, index: number) => {
          if (obj.actType.toUpperCase() === 'ENTRY TAX')
            response.response[index].actType = 'OTHER TAX'
        })
        this.preLitService.getAllPreGstCaseCount(countModel).subscribe((res: any) => {
          this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
        });
        if (option === "select") {
          this.tableSection4Config.totalItems = response.response.count;
          this.tableSection4Config.itemsPerPage = perPageLimitSection4;
          this.tableSection4Config.currentPage = model.pageNumber + 1;
        }
        if (this.SumofHC.length > 0) {
          this.SumofHC.forEach((element: any) => {
            element.expanded = false;
            if (element.issues1 && element.issues1.length > 0) {
              element.issues1.forEach((e1: any) => {
                tempKeyword.push(e1.issue);
              });
              element.hckeywordName = tempKeyword.toString();
            }
          });
        }
      }
    });
  }
  getScData(perPageLimitSection5: any, section5page: number, option: string) {
    this.SumofSC = [];
    let tempKeyword: any = [];
    let model: any = {};

    const sortField =  this.forumSortMap.presc.sortField
    const sortDir = this.forumSortMap.presc.sortDir

    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    const companyId = sessionStorage.getItem('companyId');
    const filters = sessionStorage.getItem('pregst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    if (this.navContext.entityType === 'Business') {
      model.pan = this.navContext.pan;
      model.type = this.tabType[4].key;
      model.companyId = parse.companyId ? parse.companyId : companyId;
    } else if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.type = this.tabType[4].key;
      model.companyId = parse.companyId ? parse.companyId : companyId;
    }
    let countModel: any = {};
    countModel.page = this.pageSection5;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    countModel.companyId = parse.companyId ? parse.companyId : companyId;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];

    // countModel.criterias = this.criteriaArray;
    this.preLitService
      .getAllPreGstCaseCount(countModel)
      .subscribe((res: any) => {
        this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
      });
    Object.assign(model, {
      page: section5page == 1 ? 0 : section5page - 1,
      size: perPageLimitSection5,
      sortfield: sortField ,
      sortdir: sortDir,
      criterias:  savedFilters ? savedFilters.criterias : []
    });
    this.preLitService.getPreGstSummarySc(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.SumofSC = response.response;
        response.response.forEach((obj: any, index: number) => {
          if (obj.actType.toUpperCase() === 'ENTRY TAX')
            response.response[index].actType = 'OTHER TAX'
        })
        this.preLitService
          .getAllPreGstCaseCount(countModel)
          .subscribe((res: any) => {
            this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
          });
        if (option === "select") {
          this.tableSection5Config.totalItems = response.response.count;
          this.tableSection5Config.itemsPerPage = perPageLimitSection5;
          this.tableSection5Config.currentPage = model.pageNumber + 1;
        }
        if (this.SumofSC.length > 0) {
          this.SumofSC.forEach((element: any) => {
            element.expanded = false;
            if (element.issues1 && element.issues1.length > 0) {
              element.issues1.forEach((e1: any) => {
                tempKeyword.push(e1.issue);
              });
              element.sckeywordName = tempKeyword.toString();
            }
          });
        }
      }
    });
  }
  onTableDataChangeAja(pageNumber: number): void {
    this.tableSection1Config.currentPage = pageNumber;
    this.getAjaData(
      this.tableSection1Config.itemsPerPage,
      this.tableSection1Config.currentPage, ''
    );
    // this.fetchPosts();
  }
  onTableDataChangeApa(pageNumber: number) {
    this.tableSection2Config.currentPage = pageNumber;
    this.getApaData(
      this.tableSection2Config.itemsPerPage,
      this.tableSection2Config.currentPage, ''
    );
  }
  onTableDataChangeApt(pageNumber: number) {
    this.tableSection3Config.currentPage = pageNumber;
    this.getAptData(
      this.tableSection3Config.itemsPerPage,
      this.tableSection3Config.currentPage, ''
    );
  }
  onTableDataChangeHc(pageNumber: number) {
    this.tableSection4Config.currentPage = pageNumber;
    this.getHcData(
      this.tableSection4Config.itemsPerPage,
      this.tableSection4Config.currentPage, ''
    );
  }
  onTableDataChangeSc(pageNumber: number) {
    this.tableSection5Config.currentPage = pageNumber;
    this.getScData(
      this.tableSection5Config.itemsPerPage,
      this.tableSection5Config.currentPage, ''
    );
  }
  openModal(content: any) {
    this.filterType = 'Act';
    this.modalService.open(content, { windowClass: 'pregstfilter-pop' });
  }
  nextWeek() {
    let today = new Date();
    this.nextWeekDt = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    this.nxtDtdd1 = today;
    this.nxtDtdd2 = this.nextWeekDt;
    this.filterForm.get('dd1')?.setValue(this.nxtDtdd1);
    this.filterForm.get('dd2')?.setValue(this.nxtDtdd2);
  }
  nextMonth() {
    let today = new Date();
    this.nextMonthDt = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 30
    );
    this.nxtMonthdd1 = today;
    this.nxtMonthdd2 = this.nextMonthDt;
    this.filterForm.get('dd1')?.setValue(this.nxtMonthdd1);
    this.filterForm.get('dd2')?.setValue(this.nxtMonthdd2);
  }
  custom() {
    //  this.reset();
  }
  clearAll() {
    this.filterForm.reset();
  }
  setDate() {
    this.currentYear = new Date().getFullYear() + 1;
  }
  countData() {
    let countModel: any = {};
    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    countModel.companyId = localStorage.getItem('selectedId'),
      this.preLitService.getAllPreGstCaseCount(countModel).subscribe((res: any) => {
        this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
        this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
        this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
        this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
        this.tableSection5Config.totalItems = res.response[4].totCaseCnt;

        this.cntPreAJA = res.response[0].totCaseCnt;
        this.cntPreAPA = res.response[1].totCaseCnt;
        this.cntPreAPT = res.response[2].totCaseCnt;
        this.cntPreHC = res.response[3].totCaseCnt;
        this.cntPreSC = res.response[4].totCaseCnt;
        this.totalCase = res.response[5].totCaseCnt;

        this.cntPreAJAOp = res.response[0].openCaseCnt;
        this.cntPreAPAOp = res.response[1].openCaseCnt;
        this.cntPreAPTOp = res.response[2].openCaseCnt;
        this.cntPreHCOp = res.response[3].openCaseCnt;
        this.cntPreSCOp = res.response[4].openCaseCnt;
        this.opneCase = res.response[5].openCaseCnt;
      });
  }

  deleteForum: any;
  deleteCurrentForumData: boolean = false;
  caseIdData: any;
  isSubmitted = false;
  formtype: any;
  data: any[] = [];

  submitForm() {
    this.isSubmitted = true;
    let model: any = {}
    let caseIds: any = [];
    if (this.deleteForum == 'current-forum') {
      caseIds.push(this.caseIdData);
      model.caseIds = caseIds;
      model.pan = this.navContext.pan;
      model.deleteCurrentForumData = true;
    } else {
      caseIds.push(this.caseIdData);
      model.caseIds = caseIds;
      model.pan = this.navContext.pan;
      model.deleteCurrentForumData = false;
    }
    if (this.formtype === 'preaja') {
      this.preLitService.deleteAdjudication(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('preaja');
      });
    }
    else if (this.formtype === 'preapa') {
      this.preLitService.deleteCommissioner(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('preapa');
      });
    }
    else if (this.formtype === 'preapt') {
      this.preLitService.deleteTribunal(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('preapt');
      });
    }
    else if (this.formtype === 'prehc') {
      this.preLitService.deleteHc(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('prehc');
      })
    }
    else if (this.formtype === 'presc') {
      this.preLitService.deleteSc(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('presc');
      });
    }
    this.reset_filter();
    this.countData();
    this.modalService.dismissAll();


  }
  reset_filter() {
    this.deleteForum = null;
  }
  openDetailPop(content: any, type: string, caseId: any,) {
    this.formtype = type;
    this.caseIdData = caseId;
    this.modalService.open(content, { size: 'lg', windowClass: 'modal-xl, pregstDelete-pop' });
  }
}
