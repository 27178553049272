<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <a class="notify"><i class="fa fa-bell" (click)="showHidePop()"></i></a>

              <div class="notifyDiv top-2" id="text" *ngIf="showPop">
                <h2 class="report-header" *ngIf="!showHeader"> MASTER REPORT </h2>
                <h2 class="report-header" *ngIf="showHeader">CONTINGENCY REPORT </h2>

                <i class="settings-close mdi mdi-close" (click)="closeDiv()"></i>
                <h6 class="progTxt" ng-show="flag2PData.downloadInProgress" *ngIf="showStatus">
                  <i class="fa fa-hourglass-start fs14 progIcon" style="margin-right: 5px; color: orange;"></i> <span
                    style="line-height: 1.4;">Download started for Master Report. Please check status.</span>
                  <button class="reports" (click)="statusReport()"> Check Latest Status</button>
                </h6>
                <h6 class="progTxt" ng-show="flag2PData.downloadCompleted" *ngIf="!showStatus">
                  <i class="fa fa-check-square-o fs14 compIcon" style="margin-right: 5px; color: #025c48;"
                    *ngIf="checkIcon"></i>
                  <i class="fa fa-check-square-o fs14 compIcon" style="margin-right: 5px; color: orange;"
                    *ngIf="!checkIcon"></i>
                  Download Completed for Master Report.
                  <button class="report-link" (click)="downLoadMasterReport()">Click to download</button>
                </h6>
              </div>

              <p class="card-title">Direct Tax Summary <span class="card-title">({{opneCase}}/{{totalCase}})</span>
                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="sortFilterLbl" title="Filter"
                  [ngClass]="{'filterapplied':isFilterhighlited}">
                  <i class="fa fa-filter clrOrange notifyIcon" [ngClass]="{'filterapplied':isFilterhighlited}"
                    (click)="openModal(filter)"></i><span (click)="openModal(filter)"> Filter</span></a>
                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="download" title="Master Report"
                  (click)="generatedReport('Master')" *ngIf="!showHeader1"><i
                    class="fa fa-download clrOrange notifyIcon"></i> Master Report</a>
                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="download" title="Contingency Report"
                  (click)="contingencyReport('Contingency')" *ngIf="1showHeader"><i
                    class="fa fa-download clrOrange notifyIcon"></i>
                  Contingency Report</a>
              </p>
              <div class="row m-0 highDiv">
                <div class="col-12 nopadding">
                  <ul class="action-bar">
                    <li class="bg-transparent">
                      <ng-container *ngFor="let badge of badgesList;let i=index">
                        <small [ngClass]="[badgeColorArray[i], textColorArray[i]]"
                          class="bg-opacity-10 border  d-inline-flex fw-semibold mb-3 px-2 py-1 me-2 rounded-2 ">{{badge.name}}:
                          {{ badge.value }}
                          <i type="button" class="mdi mdi-close ms-2" (click)="removeFilter(badge)"></i>
                        </small>
                      </ng-container>
                    </li>
                  </ul>
                </div>
              </div>

              <ng-template #filter let-modal>

                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Filter</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="side_nav ms-0" style="width: auto">
                        <ul class="font-size-14">

                          <li (click)="filterType = 'Case Id'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Assesment Year' }">Assessment
                              Year</a>
                          </li>
                          <li (click)="filterType = 'Case Id'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Case Id' }">Case
                              Id</a>
                          </li>
                          <li (click)="filterType = 'Case Label'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Case Label' }">Case
                              Label</a>
                          </li>
                          <li (click)="filterType = 'Consultant'" *ngIf="!['Consultant'].includes(roleName)">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Consultant' }">Consultant</a>
                          </li>
                          <li (click)="filterType = 'Demand Amount'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Demand Amount' }">Demand Amount</a>
                          </li>
                          <li (click)="filterType = 'Due Date'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Due Date' }">Due
                              Date</a>
                          </li>
                          <li (click)="filterType = 'Issue'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Issue' }">Issue</a>
                          </li>
                          <li (click)="filterType = 'Key words'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Key words' }">Key
                              words</a>
                          </li>
                          <!-- <li (click)="filterType = 'Period From'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Period From' }">Period From</a>
                          </li>
                          <li (click)="filterType = 'Period To'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Period To' }">Period
                              To</a>
                          </li> -->
                          <li (click)="filterType = 'Person Responsible'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Person Responsible' }">Person Responsible</a>
                          </li>
                          <!-- <li (click)="filterType = 'Pan'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Pan' }">PAN</a>
                          </li> -->
                          <li (click)="filterType = 'Status'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Status' }">Status</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-8 mt-2">
                      <form [formGroup]="filterForm">
                        <div *ngIf="filterType == 'Assesment Year'">
                          <label>Assesment Year</label>
                          <ng-multiselect-dropdown formControlName="ay" [placeholder]="'Select Assesment Year'"
                            [settings]="dropdownSettings" [data]="filterAssesmentYear">
                          </ng-multiselect-dropdown>
                        </div>
                        <div *ngIf="filterType == 'Case Id'">
                          <label>Case Id</label>
                          <input type="text" class="form-control mt-2" formControlName="caseId" />

                        </div>
                        <div *ngIf="filterType == 'Case Label'">
                          <label>Case Label</label>
                          <input type="text" class="form-control mt-2" formControlName="caseLabel" />

                        </div>
                        <div *ngIf="filterType == 'Consultant'">
                          <label>Consultant</label>
                          <!-- <input type="text" class="form-control mt-2" formControlName="consultant" /> -->
                          <select class="form-control mt-2" id="consultant" formControlName="consultant">
                            <option [value]="null">-- Select --</option>
                            <ng-container *ngFor="let obj of consultantList">
                              <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                            </ng-container>
                          </select>
                        </div>
                        <div *ngIf="filterType == 'Demand Amount'">
                          <label>Demand Amount</label>
                          <select class="form-control form-select me-3 " formControlName="amtQuery"
                            [value]="amtQuery.o">
                            <option [value]="null">-- Select --</option>
                            <ng-container *ngFor="let obj of demandAmtOptions">
                              <option [value]="obj.key">{{obj.value}}</option>
                            </ng-container>
                          </select>
                          <input type="text" class="form-control mt-2" formControlName="amtQueryValue" />
                        </div>
                        <div *ngIf="filterType == 'Due Date'">
                          <label>Due Date</label>
                          <div class="col-md-12">
                            <button class="btn btn-outline-primary mr-1" (click)="nextWeek()">Next Week</button>
                            <button class="btn btn-outline-primary mr-1" (click)="nextMonth()">Next Month</button>
                            <button class="btn btn-outline-primary mr-1" (click)="custom()">Custom</button>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="">Start </label>
                              <div id="dd1frmDate" class="input-group date datepicker ">
                                <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                  yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true" class="form-control"
                                  dataType="string" formControlName="dd1"></p-calendar>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="">End</label>
                              <div id="dd2toDate" class="input-group date datepicker ">
                                <p-calendar dateFormat="dd-mm-yy" [monthNavigator]="true" [yearNavigator]="true" class="form-control"
                                  yearRange="2016:{{currentYear}}" [showIcon]="true" dataType="string"
                                  formControlName="dd2"></p-calendar>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="filterType == 'Issue'">
                          <label>Issue</label>
                          <select class="form-control form-select " id="issue" formControlName="issue">
                            <option [value]="null">-- Select --</option>
                            <optgroup *ngFor='let grp of isDtIssue' label="{{grp.group}}">
                              <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                {{item.name}}</option>
                            </optgroup>
                          </select>
                        </div>
                        <div *ngIf="filterType == 'Key words'">
                          <label>Key Word</label>
                          <input type="text" class="form-control mt-2" formControlName="kw" />
                        </div>
                        <div *ngIf="filterType == 'Person Responsible'">
                          <label for="exampleSelectGender">Person Responsible</label>
                          <select class="form-control form-select " id="personResp" formControlName="personResp">
                            <option [value]="null" disabled>-- Select --</option>
                            <ng-container *ngFor="let obj of userRoleLs">
                              <option [value]="obj.email">{{obj.email}}</option>
                            </ng-container>
                          </select>
                        </div>
                        <div *ngIf="filterType == 'Pan Id'">
                          <label for="exampleSelectGender">PAN</label>
                          <select class="form-control form-select " id="Pan" formControlName="pan">
                            <option [value]="null" disabled>-- Select --</option>
                            <ng-container *ngFor="let obj of userRoleLs">
                              <option [value]="obj.email">{{obj.email}}</option>
                            </ng-container>
                          </select>
                        </div>
                        <div *ngIf="filterType == 'Status'">
                          <label for="exampleSelectGender">Status</label>
                          <ng-multiselect-dropdown formControlName="st" [placeholder]="'Select Status'"
                            [settings]="dropdownSettings" [data]="filterStatus">
                          </ng-multiselect-dropdown>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn_primary_theme font-size-16 ms-5" (click)="clearAll()">
                    Clear All
                  </button>
                  <button type="button" class="btn btn_primary_theme font-size-16" (click)="applyFilter(true)">
                    Apply
                  </button>
                </div>

              </ng-template>


              <ng-template #deletePop let-modal>
                <div class="modal-header">
                  <h4 class="modal-title">
                    <p style="color: red;">WARNING!</p> The case once deleted it will get deleted permanently
                  </h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true" style="color: red;">×</span>
                  </button>
                </div>
                <div class="modal-body m-10">
                  <form #myForm="ngForm" novalidate>
                    <div class="custom-control custom-radio"
                      *ngIf="!(formtype =='dtenqproc') && !(formtype =='dtintimation') && !(formtype =='dtassessment')">
                      <input id="current-forum" type="radio" class="custom-control-input" [(ngModel)]="deleteForum"
                        value="current-forum" name="case" ngModel required>
                      <label class="custom-control-label" for="current-forum">Do you want to delete the case from
                        <span class="bold-font">Current Forum?</span>
                      </label>
                    </div>
                    <!-- AT -->
                    <div class="custom-control custom-radio">
                      <input id="entire-case" type="radio" class="custom-control-input" [(ngModel)]="deleteForum"
                        value="entire-case" name="case" ngModel required>
                      <label class="custom-control-label" for="entire-case">Do you want to delete the
                        <span class="bold-font">Entire case?</span> </label>
                    </div>
                    <br>
                    <div class="submit-footer">
                      <button type="submit" class="btn btn-outline-primary mr-1 " [disabled]="!myForm.value.case"
                        (click)="submitForm()">Yes</button>

                      <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                        (click)="modal.close('No click')">NO</button>
                    </div>
                  </form>
                </div>
              </ng-template>
              <div class="mt-4">
                <div class="accordion" id="accordion" role="tablist">
                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-1">
                      <h6 class="mb-0">
                        <a data-toggle="collapse" (click)="isFilterApplied('dtenqproc')" href="#collapse-1"
                          aria-expanded="true" aria-controls="collapse-1">
                          Enquiry Proceeding <span class="AccCount">({{cntDtEnqprocOp}}/{{cntDtEnqproc}})</span>
                        </a>
                      </h6>
                    </div>

                    <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <a class="pr5 iris-gst-pull-right pt-2"
                              (click)="getCaseData(null, 'create', 'dtenqproc', 'tab1')">
                              <span class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "
                                  *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span>
                            </a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th>Case ID</th>
                                  <th>PAN</th>
                                  <th>PAN Name</th>
                                  <th>A.Y.</th>
                                  <th style="min-width: 110px;">Case Label</th>
                                  <th>Current Status</th>
                                  <th style="width:116px">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngFor="let obj of SumofEnquiry | paginate: tableSection1Config;" let
                                  i="index">
                                  <tr>
                                    <td><a
                                        (click)="getCaseData(obj.caseId, 'view','dtenqproc','tab1')">{{obj.caseId}}</a>
                                    </td>
                                    <td>{{obj.pan}}</td>
                                    <td>{{obj.panName}}</td>
                                    <td>{{obj.ay}}</td>
                                    <td>{{obj.caseLabel}}</td>
                                    <td>
                                      <label class="badge badge-success"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PROCESSED AND ACCEPTED"
                                        *ngIf="obj.status == 'PROCESSED AND ACCEPTED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="DEPT RESPONSE PENDING"
                                        *ngIf="obj.status == 'DEPT RESPONSE PENDING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PETITION YET TO BE FILED"
                                        *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL PREFERRED"
                                        *ngIf="obj.status == 'APPEAL PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="NO RESPONSE"
                                        *ngIf="obj.status == 'NO RESPONSE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="REMANDED BACK"
                                        *ngIf="obj.status == 'REMANDED BACK'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="ORDER RECEIVED"
                                        *ngIf="obj.status == 'ORDER RECEIVED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RE RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RE RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFICATION"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFICATION'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR SUMMON"
                                        *ngIf="obj.status == 'PENDING FOR SUMMON'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFIED ORDER"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFIED ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="KEPT IN ABEYANCE"
                                        *ngIf="obj.status == 'KEPT IN ABEYANCE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>


                                    </td>
                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'dtenqproc',obj.caseId)"><span
                                          class="badge badge-outline-trash " title="Delete"><i class="fa fa-trash"
                                            *ngIf="!(['DT_Viewer', 'Viewer'].includes(roleName) || ['DT_Preparer','Preparer','Consultant'].includes(roleName))"></i></span></a>

                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'view','dtenqproc' ,'tab1')"><span
                                          class="badge badge-outline-success " title="View"><i
                                            class="fa fa-eye "></i></span></a>
                                      <a class="pr5"
                                        (click)="getCaseData(obj.caseId, 'update','dtenqproc','tab1')"><span
                                          class="badge badge-outline-primary " title="Edit"><i
                                            class="fa fa-pencil-square-o "
                                            *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span></a>

                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br>
                        <div class="row noMargin">
                          <div class="col-md-10 nopadding">
                            <pagination-controls (pageChange)="onTableDataChangeEnquery($event)"
                              id="tableSection1Config"></pagination-controls>
                          </div>
                          <div class="col-md-2 nopadding">
                            <select class="form-select jumptoPagination" [(ngModel)]="dtPageLimitSection1"
                              (ngModelChange)="getEnqueryData(dtPageLimitSection1,1)" id="authority">
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-2">
                      <h6 class="mb-0">
                        <a data-toggle="collapse" (click)="isFilterApplied('dtintimation')" href="#collapse-2"
                          aria-expanded="false" aria-controls="collapse-2">
                          Intimation Order <span class="AccCount">({{cntDtIntimationOp}}/{{cntDTtIntimation}})</span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapse-2" class="collapse " role="tabpanel" aria-labelledby="heading-2"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <a class="pr5 iris-gst-pull-right pt-2"
                              (click)="getCaseData(null, 'create','dtintimation' ,'tab2')">
                              <span class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "
                                  *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span>
                            </a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:180px">Case ID</th>
                                  <th>PAN</th>
                                  <th>PAN Name</th>
                                  <th style="width:100px">A.Y.</th>
                                  <th>Case Label</th>
                                  <th>Addn of Income/ Disallow of Exp (₹)</th>
                                  <th>Demand(₹)</th>
                                  <th>Current Status</th>
                                  <th style="width:116px">Action</th>
                                </tr>

                              </thead>
                              <tbody>
                                <ng-container *ngFor="let obj of sumofIntimation | paginate: tableSection2Config;" let
                                  i="index">
                                  <tr>
                                    <td><a
                                        (click)="getCaseData(obj.caseId, 'view','dtintimation','tab2')">{{obj.caseId}}</a>
                                    </td>
                                    <td>{{obj.pan}}</td>
                                    <td>{{obj.panName}}</td>
                                    <td>{{obj.ay}}</td>
                                    <td>{{obj.caseLabel}}</td>
                                    <td class="txtRight">{{obj.totOfAddnOrDisOfExpensesF | INR: 0}}</td>
                                    <td>{{obj.totOfTotal | INR: 0}}</td>
                                    <td>
                                      <label class="badge badge-success"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PROCESSED AND ACCEPTED"
                                        *ngIf="obj.status == 'PROCESSED AND ACCEPTED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="DEPT RESPONSE PENDING"
                                        *ngIf="obj.status == 'DEPT RESPONSE PENDING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PETITION YET TO BE FILED"
                                        *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL PREFERRED"
                                        *ngIf="obj.status == 'APPEAL PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="NO RESPONSE"
                                        *ngIf="obj.status == 'NO RESPONSE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="REMANDED BACK"
                                        *ngIf="obj.status == 'REMANDED BACK'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="ORDER RECEIVED"
                                        *ngIf="obj.status == 'ORDER RECEIVED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RE RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RE RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFICATION"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFICATION'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR SUMMON"
                                        *ngIf="obj.status == 'PENDING FOR SUMMON'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFIED ORDER"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFIED ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="KEPT IN ABEYANCE"
                                        *ngIf="obj.status == 'KEPT IN ABEYANCE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                    </td>
                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'dtintimation',obj.caseId)"><span
                                          class="badge badge-outline-trash " title="Delete"><i class="fa fa-trash"
                                            *ngIf="!(['DT_Viewer', 'Viewer'].includes(roleName) || ['DT_Preparer','Preparer','Consultant'].includes(roleName))"></i></span></a>
                                      <a class="pr5"
                                        (click)="getCaseData(obj.caseId, 'view','dtintimation' ,'tab2')"><span
                                          class="badge badge-outline-success " title="View"><i
                                            class="fa fa-eye "></i></span></a>
                                      <a class="pr5"
                                        (click)="getCaseData(obj.caseId, 'update','dtintimation' ,'tab2')"><span
                                          class="badge badge-outline-primary " title="Edit"><i
                                            class="fa fa-pencil-square-o "
                                            *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span></a>
                                    </td>
                                  </tr>

                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br>
                        <div class="row noMargin">
                          <div class="col-md-10 nopadding">
                            <pagination-controls (pageChange)="onTableDataChangeSection2($event)"
                              id="tableSection2Config"></pagination-controls>
                          </div>
                          <div class="col-md-2 nopadding">
                            <select class="form-select jumptoPagination" [(ngModel)]="dtPageLimitSection2"
                              (ngModelChange)="getIntimationData(dtPageLimitSection2,1)" id="authority">
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-3">
                      <h6 class="mb-0">
                        <a class="collapsed" (click)="isFilterApplied('dtassessment')" data-toggle="collapse"
                          href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">Assessment<span
                            class="AccCount">({{cntDtAssessmentOp}}/{{cntDtAssessment}})</span> </a>
                      </h6>
                    </div>
                    <div id="collapse-3" class="collapse" role="tabpanel" aria-labelledby="heading-3"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <a class="pr5 iris-gst-pull-right pt-2"
                              (click)="createCase('create','dtassessment', 'tab3')">
                              <span class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "
                                  *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span>
                            </a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:170px">Case ID</th>
                                  <th>PAN</th>
                                  <th>PAN Name</th>
                                  <th style="width:100px">A.Y.</th>
                                  <th>Case Label</th>
                                  <th>Addn of Income/ Disallow of Exp (₹)</th>
                                  <th>Demand(₹)</th>
                                  <th>Current Status</th>
                                  <th style="width:116px">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngFor="let obj of sumofAssessment | paginate: tableSection3Config;" let
                                  i="index">
                                  <tr>
                                    <td><a
                                        (click)="getCaseData(obj.caseId, 'view','dtassessment','tab3')">{{obj.caseId}}</a>
                                    </td>
                                    <td>{{obj.pan}}</td>
                                    <td>{{obj.panName}}</td>
                                    <td>{{obj.ay}}</td>
                                    <td>{{obj.caseLabel}}</td>
                                    <td class="txtRight">{{obj.totOfAddnOrDisOfExpensesF | INR: 0}}</td>
                                    <td>{{obj.totOfTotal | INR: 0}}</td>
                                    <td>
                                      <label class="badge badge-success"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PROCESSED AND ACCEPTED"
                                        *ngIf="obj.status == 'PROCESSED AND ACCEPTED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="DEPT RESPONSE PENDING"
                                        *ngIf="obj.status == 'DEPT RESPONSE PENDING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PETITION YET TO BE FILED"
                                        *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL PREFERRED"
                                        *ngIf="obj.status == 'APPEAL PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="NO RESPONSE"
                                        *ngIf="obj.status == 'NO RESPONSE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="REMANDED BACK"
                                        *ngIf="obj.status == 'REMANDED BACK'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="ORDER RECEIVED"
                                        *ngIf="obj.status == 'ORDER RECEIVED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RE RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RE RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFICATION"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFICATION'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR SUMMON"
                                        *ngIf="obj.status == 'PENDING FOR SUMMON'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFIED ORDER"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFIED ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="KEPT IN ABEYANCE"
                                        *ngIf="obj.status == 'KEPT IN ABEYANCE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                    </td>

                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'dtassessment',obj.caseId)"><span
                                          class="badge badge-outline-trash " title="Delete"><i class="fa fa-trash"
                                            *ngIf="!(['DT_Viewer', 'Viewer'].includes(roleName) || ['DT_Preparer','Preparer','Consultant'].includes(roleName))"></i></span></a>
                                      <a class="pr5"
                                        (click)="getCaseData(obj.caseId, 'view', 'dtassessment','tab3')"><span
                                          class="badge badge-outline-success " title="View"><i
                                            class="fa fa-eye "></i></span></a>
                                      <a class="pr5"
                                        (click)="getCaseData(obj.caseId, 'update', 'dtassessment','tab3')"><span
                                          class="badge badge-outline-primary " title="Edit"><i
                                            class="fa fa-pencil-square-o "
                                            *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span></a>
                                    </td>
                                  </tr>

                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br>
                        <div class="row noMargin">
                          <div class="col-md-10 nopadding">
                            <pagination-controls (pageChange)="onTableDataChangeSection3($event)"
                              id="tableSection3Config"></pagination-controls>
                          </div>
                          <div class="col-md-2 nopadding">
                            <select class="form-select jumptoPagination" [(ngModel)]="dtPageLimitSection3"
                              (ngModelChange)="getAssessmentData(dtPageLimitSection3,1)" id="authority">
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-4">
                      <h6 class="mb-0"><a class="collapsed" (click)="isFilterApplied('dtcommissioner')"
                          data-toggle="collapse" href="#collapse-4" aria-expanded="false"
                          aria-controls="collapse-4">Commissioner (A)<span
                            class="AccCount">({{cntDtCommissionerOp}}/{{cntDtCommissioner}})</span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapse-4" class="collapse" role="tabpanel" aria-labelledby="heading-4"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <a class="pr5 iris-gst-pull-right pt-2"
                              (click)="createCase('create', 'dtcommissioner','tab4')">
                              <span class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "
                                  *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span>
                            </a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:170px">Case ID</th>
                                  <th>PAN</th>
                                  <th>PAN Name</th>
                                  <th style="width:100px">A.Y.</th>
                                  <th>Case Label</th>
                                  <th>Addn of Income/ Disallow of Exp (₹)</th>
                                  <th>Demand(₹)</th>
                                  <th>Current Status</th>
                                  <th style="width:116px">Action</th>
                                </tr>

                              </thead>
                              <tbody>
                                <ng-container *ngFor="let obj of sumofCommissioner | paginate: tableSection4Config;" let
                                  i="index">
                                  <tr>
                                    <td><a
                                        (click)="getCaseData(obj.caseId, 'view','dtcommissioner','tab4')">{{obj.caseId}}</a>
                                    </td>
                                    <td>{{obj.pan}}</td>
                                    <td>{{obj.panName}}</td>
                                    <td>{{obj.ay}}</td>
                                    <td>{{obj.caseLabel}}</td>
                                    <td class="txtRight">{{obj.totOfAddnOrDisOfExpensesF | INR: 0}}</td>
                                    <td>{{obj.totOfTotal | INR: 0}}</td>
                                    <td> <label class="badge badge-success"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PROCESSED AND ACCEPTED"
                                        *ngIf="obj.status == 'PROCESSED AND ACCEPTED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="DEPT RESPONSE PENDING"
                                        *ngIf="obj.status == 'DEPT RESPONSE PENDING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PETITION YET TO BE FILED"
                                        *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL PREFERRED"
                                        *ngIf="obj.status == 'APPEAL PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="NO RESPONSE"
                                        *ngIf="obj.status == 'NO RESPONSE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="REMANDED BACK"
                                        *ngIf="obj.status == 'REMANDED BACK'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="ORDER RECEIVED"
                                        *ngIf="obj.status == 'ORDER RECEIVED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RE RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RE RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFICATION"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFICATION'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR SUMMON"
                                        *ngIf="obj.status == 'PENDING FOR SUMMON'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFIED ORDER"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFIED ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="KEPT IN ABEYANCE"
                                        *ngIf="obj.status == 'KEPT IN ABEYANCE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                    </td>

                                    <td>
                                      <a class="pr5"
                                        (click)="openDetailPop(deletePop, 'dtcommissioner',obj.caseId)"><span
                                          class="badge badge-outline-trash " title="Delete"><i class="fa fa-trash"
                                            *ngIf="!(['DT_Viewer', 'Viewer'].includes(roleName) || ['DT_Preparer','Preparer','Consultant'].includes(roleName))"></i></span></a>
                                      <a class="pr5"
                                        (click)="getCaseData(obj.caseId, 'view', 'dtcommissioner','tab4')"><span
                                          class="badge badge-outline-success " title="View"><i
                                            class="fa fa-eye "></i></span></a>
                                      <a class="pr5"
                                        (click)="getCaseData(obj.caseId, 'update', 'dtcommissioner','tab4')"><span
                                          class="badge badge-outline-primary " title="Edit"><i
                                            class="fa fa-pencil-square-o "
                                            *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span></a>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br>
                        <div class="row noMargin">
                          <div class="col-md-10 nopadding">
                            <pagination-controls (pageChange)="onTableDataChangeSection4($event)"
                              id="tableSection4Config"></pagination-controls>
                          </div>
                          <div class="col-md-2 nopadding">
                            <select class="form-select jumptoPagination" [(ngModel)]="dtPageLimitSection4"
                              (ngModelChange)="getCommissionerData(dtPageLimitSection4,1)" id="authority">
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-5">
                      <h6 class="mb-0"><a class="collapsed" (click)="isFilterApplied('dtitat')" data-toggle="collapse"
                          href="#collapse-5" aria-expanded="false" aria-controls="collapse-5">
                          Appellate Tribunal<span class="AccCount">({{cntDtItatOp}}/{{cntDtItat}})</span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapse-5" class="collapse" role="tabpanel" aria-labelledby="heading-5"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <a class="pr5 iris-gst-pull-right pt-2" (click)="createCase('create', 'dtitat' ,'tab5')">
                              <span class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "
                                  *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span>
                            </a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:170px">Case ID</th>
                                  <th>PAN</th>
                                  <th>PAN Name</th>
                                  <th style="width:100px">A.Y.</th>
                                  <th>Case Label</th>
                                  <th>Addn of Income/ Disallow of Exp (₹)</th>
                                  <th>Demand(₹)</th>
                                  <th>Current Status</th>
                                  <th style="width:116px">Action</th>
                                </tr>

                              </thead>
                              <tbody>
                                <ng-container *ngFor="let obj of sumofItat | paginate: tableSection5Config;" let
                                  i="index">
                                  <tr>
                                    <td><a (click)="getCaseData(obj.caseId, 'view','dtitat','tab5')">{{obj.caseId}}</a>
                                    </td>
                                    <td>{{obj.pan}}</td>
                                    <td>{{obj.panName}}</td>
                                    <td>{{obj.ay}}</td>
                                    <td>{{obj.caseLabel}}</td>
                                    <td class="txtRight">{{obj.totOfAddnOrDisOfExpensesF | INR: 0}}</td>
                                    <td>{{obj.totOfTotal | INR: 0}}</td>
                                    <td>
                                      <label class="badge badge-success"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PROCESSED AND ACCEPTED"
                                        *ngIf="obj.status == 'PROCESSED AND ACCEPTED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="DEPT RESPONSE PENDING"
                                        *ngIf="obj.status == 'DEPT RESPONSE PENDING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PETITION YET TO BE FILED"
                                        *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL PREFERRED"
                                        *ngIf="obj.status == 'APPEAL PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="NO RESPONSE"
                                        *ngIf="obj.status == 'NO RESPONSE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="REMANDED BACK"
                                        *ngIf="obj.status == 'REMANDED BACK'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="ORDER RECEIVED"
                                        *ngIf="obj.status == 'ORDER RECEIVED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RE RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RE RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFICATION"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFICATION'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR SUMMON"
                                        *ngIf="obj.status == 'PENDING FOR SUMMON'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFIED ORDER"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFIED ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="KEPT IN ABEYANCE"
                                        *ngIf="obj.status == 'KEPT IN ABEYANCE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                    </td>
                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'dtitat',obj.caseId)"><span
                                          class="badge badge-outline-trash " title="Delete"><i class="fa fa-trash"
                                            *ngIf="!(['DT_Viewer', 'Viewer'].includes(roleName) || ['DT_Preparer','Preparer','Consultant'].includes(roleName))"></i></span></a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'view', 'dtitat','tab5')"><span
                                          class="badge badge-outline-success " title="View"><i
                                            class="fa fa-eye "></i></span></a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'update', 'dtitat','tab5')"><span
                                          class="badge badge-outline-primary " title="Edit"><i
                                            class="fa fa-pencil-square-o "
                                            *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span></a>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br>
                        <div class="row noMargin">
                          <div class="col-md-10 nopadding">
                            <pagination-controls (pageChange)="onTableDataChangeSection5($event)"
                              id="tableSection5Config"></pagination-controls>
                          </div>
                          <div class="col-md-2 nopadding">
                            <select class="form-select jumptoPagination" [(ngModel)]="dtPageLimitSection5"
                              (ngModelChange)="getItatData(dtPageLimitSection5,1)" id="authority">
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-6">
                      <h6 class="mb-0"><a class="collapsed" (click)="isFilterApplied('dthc')" data-toggle="collapse"
                          href="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                          High Court <span class="AccCount">({{cntDtHCOp}}/{{cntDtHC}})</span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapse-6" class="collapse" role="tabpanel" aria-labelledby="heading-6"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <a class="pr5 iris-gst-pull-right pt-2" (click)="createCase('create', 'dthc','tab6')">
                              <span class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "
                                  *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span>
                            </a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:170px">Case ID</th>
                                  <th>PAN</th>
                                  <th>PAN Name</th>
                                  <th style="width:100px">A.Y.</th>
                                  <th>Case Label</th>
                                  <th>Addn of Income/ Disallow of Exp (₹)</th>
                                  <th>Demand(₹)</th>
                                  <th>Current Status</th>
                                  <th style="width:116px">Action</th>
                                </tr>

                              </thead>
                              <tbody>
                                <ng-container *ngFor="let obj of sumofHc | paginate: tableSection6Config;" let
                                  i="index">
                                  <tr>
                                    <td><a (click)="getCaseData(obj.caseId, 'view','dthc','tab6')">{{obj.caseId}}</a>
                                    </td>
                                    <td>{{obj.pan}}</td>
                                    <td>{{obj.panName}}</td>
                                    <td>{{obj.ay}}</td>
                                    <td>{{obj.caseLabel}}</td>
                                    <td class="txtRight">{{obj.totOfAddnOrDisOfExpensesF | INR: 0}}</td>
                                    <td>{{obj.totOfTotal | INR: 0}}</td>
                                    <td>
                                      <label class="badge badge-success"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PROCESSED AND ACCEPTED"
                                        *ngIf="obj.status == 'PROCESSED AND ACCEPTED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="DEPT RESPONSE PENDING"
                                        *ngIf="obj.status == 'DEPT RESPONSE PENDING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PETITION YET TO BE FILED"
                                        *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL PREFERRED"
                                        *ngIf="obj.status == 'APPEAL PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="NO RESPONSE"
                                        *ngIf="obj.status == 'NO RESPONSE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="REMANDED BACK"
                                        *ngIf="obj.status == 'REMANDED BACK'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="ORDER RECEIVED"
                                        *ngIf="obj.status == 'ORDER RECEIVED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RE RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RE RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFICATION"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFICATION'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR SUMMON"
                                        *ngIf="obj.status == 'PENDING FOR SUMMON'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFIED ORDER"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFIED ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="KEPT IN ABEYANCE"
                                        *ngIf="obj.status == 'KEPT IN ABEYANCE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>


                                    </td>
                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'dthc',obj.caseId)"><span
                                          class="badge badge-outline-trash " title="Delete"><i class="fa fa-trash"
                                            *ngIf="!(['DT_Viewer', 'Viewer'].includes(roleName) || ['DT_Preparer','Preparer','Consultant'].includes(roleName))"></i></span></a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'view', 'dthc','tab6')"><span
                                          class="badge badge-outline-success " title="View"><i
                                            class="fa fa-eye "></i></span></a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'update', 'dthc','tab6')"><span
                                          class="badge badge-outline-primary " title="Edit"><i
                                            class="fa fa-pencil-square-o "
                                            *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span></a>
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br>
                        <div class="row noMargin">
                          <div class="col-md-10 nopadding">
                            <pagination-controls (pageChange)="onTableDataChangeSection6($event)"
                              id="tableSection6Config"></pagination-controls>
                          </div>
                          <div class="col-md-2 nopadding">
                            <select class="form-select jumptoPagination" [(ngModel)]="dtPageLimitSection6"
                              (ngModelChange)="getHcData(dtPageLimitSection6,1)" id="authority">
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-7">
                      <h6 class="mb-0">
                        <a class="collapsed" (click)="isFilterApplied('dtsc')" data-toggle="collapse" href="#collapse-7"
                          aria-expanded="false" aria-controls="collapse-7">Supreme Court<span
                            class="AccCount">({{cntDtSCOp}}/{{cntDteSC}})</span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapse-7" class="collapse" role="tabpanel" aria-labelledby="heading-7"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <a class="pr5 iris-gst-pull-right pt-2" (click)="createCase('create','dtsc' ,'tab7')">
                              <span class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "
                                  *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span>
                            </a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:170px">Case ID</th>
                                  <th>PAN</th>
                                  <th>PAN Name</th>
                                  <th style="width:100px">A.Y.</th>
                                  <th>Case Label</th>
                                  <th>Addn of Income/ Disallow of Exp (₹)</th>
                                  <th>Demand(₹)</th>
                                  <th>Current Status</th>
                                  <th style="width: 114px;">Action</th>
                                </tr>

                              </thead>
                              <tbody>
                                <ng-container *ngFor="let obj of sumofSc | paginate: tableSection7Config;" let
                                  i="index">
                                  <tr>
                                    <td><a (click)="getCaseData(obj.caseId, 'view','dtsc','tab7')">{{obj.caseId}}</a>
                                    </td>
                                    <td>{{obj.pan}}</td>
                                    <td>{{obj.panName}}</td>
                                    <td>{{obj.ay}}</td>
                                    <td>{{obj.caseLabel}}</td>
                                    <td class="txtRight">{{obj.totOfAddnOrDisOfExpensesF | INR: 0}}</td>
                                    <td>{{obj.totOfTotal | INR: 0}}</td>
                                    <td>
                                      <label class="badge badge-success"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PROCESSED AND ACCEPTED"
                                        *ngIf="obj.status == 'PROCESSED AND ACCEPTED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="DEPT RESPONSE PENDING"
                                        *ngIf="obj.status == 'DEPT RESPONSE PENDING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PETITION YET TO BE FILED"
                                        *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL PREFERRED"
                                        *ngIf="obj.status == 'APPEAL PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="NO RESPONSE"
                                        *ngIf="obj.status == 'NO RESPONSE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="REMANDED BACK"
                                        *ngIf="obj.status == 'REMANDED BACK'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="ORDER RECEIVED"
                                        *ngIf="obj.status == 'ORDER RECEIVED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="RE RECTIFICATION PREFERRED"
                                        *ngIf="obj.status == 'RE RECTIFICATION PREFERRED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFICATION"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFICATION'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR SUMMON"
                                        *ngIf="obj.status == 'PENDING FOR SUMMON'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR RECTIFIED ORDER"
                                        *ngIf="obj.status == 'PENDING FOR RECTIFIED ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="KEPT IN ABEYANCE"
                                        *ngIf="obj.status == 'KEPT IN ABEYANCE'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                    </td>
                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'dtsc',obj.caseId)"><span
                                          class="badge badge-outline-trash " title="Delete"><i class="fa fa-trash"
                                            *ngIf="!(['DT_Viewer', 'Viewer'].includes(roleName) || ['DT_Preparer','Preparer','Consultant'].includes(roleName))"></i></span></a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'view', 'dtsc','tab7')"><span
                                          class="badge badge-outline-success " title="View"><i
                                            class="fa fa-eye "></i></span></a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'update', 'dtsc','tab7')"><span
                                          class="badge badge-outline-primary " title="Edit"><i
                                            class="fa fa-pencil-square-o "
                                            *ngIf="!['DT_Viewer', 'Viewer'].includes(roleName)"></i></span></a>


                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <br>
                        <div class="row noMargin">
                          <div class="col-md-10 nopadding">
                            <pagination-controls (pageChange)="onTableDataChangeSection7($event)"
                              id="tableSection7Config"></pagination-controls>
                          </div>
                          <div class="col-md-2 nopadding">
                            <select class="form-select jumptoPagination" [(ngModel)]="dtPageLimitSection7"
                              (ngModelChange)="getDTScData(dtPageLimitSection7,1)" id="authority">
                              <option value="10">10</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                              <option value="1000">1000</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
