
  <div class="modal-header" style="border: none; padding-bottom: 0px;" >
    <h4 class="modal-title" id="modal-basic-title">Attachments</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
<div class="modal-body">
  <p>
    <a class="pl5 addExtLetter" (click)="addRow()" title="Add More Detail">
      <i class="fa fa-plus"></i> Add another row for Attachments
    </a>
  </p>
  <div class="col-md-12">
    <div class="search-table-outter wrapper">
      <table class="table">
        <thead>
          <tr class="bgGred">
            <th>Upload</th>
            <th>File Name</th>
            <th>Remarks</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let attachmentDataGrp of getAttachmentData(); let k = index" [formGroup]="attachmentDataGrp">
            <td>
              <div class="row">
                <div class="col-sm-9 nopadding">
                  <div class="form-group">
                    <input *ngIf="attachmentDataGrp.value.isCopyOfFormLocDocUploadedClicked" type="file"
                      id="attachmentList({{k}}).fileLoc" class="filetype file-placement form-control" name="myfile"
                      (change)='onFileSelect($event)'
                      [ngClass]="{ 'errorBorder': isSubmitted && attachmentDataGrp.get('fileLoc')?.errors  }">
                    <p *ngIf="!attachmentDataGrp.value.isCopyOfFormLocDocUploadedClicked || formGrp.attachmentList.value[k].fileLoc"
                      class="form-control pt10" style="margin: 10px;"
                      [ngClass]="{ 'errorBorder': isSubmitted && attachmentDataGrp.get('fileLoc')?.errors  }">
                      {{getFileNameDta(attachmentDataGrp.value.fileLoc)}}</p>
                    <div *ngIf="isSubmitted && attachmentDataGrp.get('fileLoc')?.errors">
                      <span class="text-danger"
                        *ngIf="attachmentDataGrp.get('fileLoc')?.errors?.required">Required</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 nopadding">
                  <label for="exampleInputPassword1">
                    <a class="downupIcon" *ngIf="formGrp.attachmentList.value[k].fileLoc" title="Download file"
                      (click)="download(attachmentDataGrp.value.fileLoc)" style=" margin-left: 7px; "><i
                        class="fa fa-download"></i></a>
                    <a class="downupIcon"
                      *ngIf="!(formGrp.attachmentList.value[k].fileLoc || formGrp.attachmentList.value[k].fileLoc)"
                      title="Upload file" (click)="uploadjudicialPredLoc(k)"><i class="fa fa-upload"
                        style=" padding-left: 4px; padding-top: 10px;"></i></a>
                  </label>
                </div>
              </div>
            </td>
            <td>
              <input type="text" class="form-control align-right" formControlName="fileName" id="tax" placeholder=""
                readonly>
              <div *ngIf="isSubmitted && attachmentDataGrp.get('fileName')?.errors">
                <span class="text-danger" *ngIf="attachmentDataGrp.get('fileName')?.errors?.required "> Required</span>
              </div>
            </td>
            <td><input type="text" class="form-control align-right" formControlName="remarks" id="tax" placeholder="">
            </td>
            <td class="sl-no-cls">
              <a class="addExtLetter" (click)="opentaxPop(removeDetailPopup)"><i class="fa fa-minus"></i></a>
            </td>
            <ng-template #removeDetailPopup let-modal>
              <div class="modal-header">
                <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div class="modal-body">
                <p>Are you sure, you want to remove?</p>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-dark" (click)="rmProv(k)">Remove</button>
              </div>
            </ng-template>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
  <div class="modal-footer" style="border: none; padding-top: 0px;">
    <button type="button" (click)="onSaveClick()" class="btn btn-outline-dark" >Save</button>
  </div>