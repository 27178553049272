import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DtNmStats, NoticeServiceService } from '../../services/notification.service';
import { asessmentYears } from 'src/app/helpers/date.utils';
import { DT_NM_STATUS } from 'src/app/shared/constant';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
export enum VC_SORT {
  PAN = 'PAN',
  VIDEO_CONF_REQ_ID = 'Video Confr Request ID',
  VC_REASON = 'VC Reason',
  REQUEST_DATE = 'Request Date',
  VC_DATE = 'VC Date',
}
interface Filters {
  p: 'parsedRequestDate' | 'vcAdjRqstDate' | 'pan';
  o: 'in' | 'eq' | 'between';
  v?: string | number | null;
  v1?: string | number | null;
  v2?: string | number | null;
}
@Component({
  selector: 'app-video-conf',
  templateUrl: './video-conf.component.html',
  styleUrls: ['./video-conf.component.css']
})
export class VideoConfComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal!: ElementRef;
  @ViewChild('closeUnModal', { static: false }) closeUnModal!: ElementRef;
  isMarkRead: boolean = false;
  dtNmStats: null | DtNmStats = null;
  proceedCnt: any = Number(sessionStorage.getItem('DtproceedCnt') ?? 10);
  rootCompanyId: number = Number(sessionStorage.getItem('companyId'));
  companyId: number = Number(localStorage.getItem('selectedId'));
  currentYear: any = ((new Date()).getFullYear() + 1);
  childCompanyId: number | null = null;
  vcData: any;
  totalCount: any;
  perPageLimitSection1 = 10;
  pageSection1: number = 1;
  tableSize: number = 10;
  assessYears = asessmentYears();
  status = DT_NM_STATUS;
  filterDropDown: any = [];
  reqDateArray: Array<string> | null = null;
  vcDateArray: Array<string> | null = null;
  vcFiles: any[] = [];
  filters: { [key: string]: Filters } = this.initFilters();
  vcConfig = {
    itemsPerPage: this.perPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'vcConfig',
  };
  userActionData: {
    "_id": string,
    "companyId": number,
    "type": string,
    "userAction": string
  } = {
      _id: '',
      companyId: 0,
      type: '',
      userAction: ''
    };

  vcSort = VC_SORT;
  sorting: { pan: boolean, parentNoticeheaderSeqNo: boolean, adjReason: boolean, parsedRequestDate: boolean, vcAdjRqstDate: boolean } =
    { pan: false, parentNoticeheaderSeqNo: false, adjReason: false, parsedRequestDate: false, vcAdjRqstDate: false, };

  constructor(
    private noticeService: NoticeServiceService,
    private toaster: ToasterService,
    private router: Router,
    private commonServ: CommonService,
  ) { }
  ngOnInit(): void {
    this.filters = <any>JSON.parse(sessionStorage.getItem('dtNmVcFilter') || '{}');
    if ((this.filters && Object.keys(this.filters).length == 0) || !this.filters) {
      this.filters = this.initFilters();
    }
    this.vcListData();
    this.filterValue();
    this.countData();
    this.vcConfig.totalItems = this.proceedCnt;
    this.tableSize = this.proceedCnt;
  }
  initFilters(): { [key: string]: Filters } {
    return {
      parsedRequestDate: { p: 'parsedRequestDate', o: 'between', v1: null, v2: null },
      vcAdjRqstDate: { p: 'vcAdjRqstDate', o: 'between', v1: null, v2: null },
      pan: { p: 'pan', o: 'in', v: null },
    }
  }
  getFilters() {
    sessionStorage.setItem('dtNmVcFilter', JSON.stringify(this.filters));
    return Object.values(this.filters).filter((el) => !!el.v || !!el.v1 || !!el.v2);
  }
  //count api 
  countData() {
    let model: any = {};
    model.companyId = sessionStorage.getItem('companyId');
    model.sortdir = "ASC",
      model.sortfield = "createdAt",
      model.module = "DT",
      this.noticeService.getDtNmCount(model).subscribe((response:any) => {
        if (response.status === 1) {
            this.dtNmStats = response.response;
        }
      });
  }
  //count api
  //vc list data api
  vcListData() {
    if (this.reqDateArray && this.reqDateArray.length > 0) {
      this.filters.parsedRequestDate.v1 = this.reqDateArray[0];
      if (!this.reqDateArray[1]) {
        return;
      } else {
        this.filters.parsedRequestDate.v2 = this.reqDateArray[1];
      }
    }
    if (this.vcDateArray && this.vcDateArray.length > 0) {
      this.filters.vcAdjRqstDate.v1 = this.vcDateArray[0];
      if (!this.vcDateArray[1]) {
        return;
      } else {
        this.filters.vcAdjRqstDate.v2 = this.vcDateArray[1];
      }
    }
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.vcConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      criterias: this.getFilters(),
      newRecord: isNewCase === "Newnotice"
    }
    this.noticeService.getVcListData(payload).subscribe((res) => {
      this.vcData = res.response.results;
      this.vcConfig.totalItems = res.response.count;
      this.totalCount = res.response.count;

    });
  }
  //vc list data api

  // set user action
  setAction(data: any) {
    this.userActionData = {
      _id: '',
      companyId: 0,
      type: '',
      userAction: ''

    };

    this.userActionData._id = data._id;
    this.userActionData.companyId = this.companyId;
    this.userActionData.type = 'DtVideoConferencing';
    if (data.userAction === 'Yet to read' || !data.userAction) {
      this.userActionData.userAction = 'Read by user';
    } else {
      this.userActionData.userAction = 'Yet to read';
    }
  }
  confirmMarkRead() {
    this.noticeService.confirmDtUserAction(this.userActionData).subscribe((response: any) => {
      if (response.status == 1) {
        this.vcListData();
        this.isMarkRead = true;
        this.closeModal.nativeElement.click();
        this.toaster.showSuccess(response.response);
      } else {
        this.toaster.showError(response.errorList[0].message);
      }

    });

  }
  confirmMarkUnRead() {
    this.noticeService.confirmDtUserAction(this.userActionData).subscribe((response: any) => {
      if (response.status == 1) {
        this.vcListData();
        this.isMarkRead = true;
        this.closeUnModal.nativeElement.click();
        this.toaster.showSuccess(response.response);
      } else {
        this.toaster.showError(response.errorList[0].message);
      }

    });

  }
  // set user action

  //filter dropdown Values
  filterValue() {
    const payload: any = {
      type: 'DtNotice',
      module: "DT",
    }
    this.noticeService.getFilterValue(payload).subscribe((res) => {
      this.filterDropDown = res.response;
    });

  }
  //filter  dropdown Values

  //Export to excel download
  downloadEXFile() {
    this.getFilters();
    this.dwdExpExcel();
  }
  dwdExpExcel() {
    const payload: any = {
      page: this.vcConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      reportType: 'DtVideoConferencing',
      sortfield: 'vcAdjRqstDate',
      module: "DT",
      criterias: this.getFilters()
    }
    this.noticeService.dwdDtExportExcel(payload).subscribe((response: any) => {
      this.downloadExFile(response);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadExFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `Video_Conferencing.xlsx`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  //Export to excel download


  //file download
  onClickFileName(NoticeFileData: any[] = []) {
    this.vcFiles = [];
    this.vcFiles = NoticeFileData;
  }
  download(key: any, id: any, fileName: string) {
    let urlData = {
      s3Key: key,
      id: id,
      module: "DT",
    }
    this.noticeService.dwdDTVcFile(urlData).subscribe((response: any) => {
      this.downloadcontFile(response, fileName);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadcontFile(data: any, fileName: any) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${fileName}`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  //file download


  //pagination  
  onTableDataChangeVc(pageNumber: number): void {
    this.vcConfig.currentPage = pageNumber;
    this.vcListData();
  }
  getVcData(perPageLimitSection1: any, section1page: number) {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: section1page == 1 ? 0 : section1page - 1,
      size: perPageLimitSection1,
      module: "DT",
      criterias: this.getFilters(),
      newRecord: isNewCase === "Newnotice"
    }
    this.noticeService.getVcListData(payload).subscribe((res) => {
      this.vcData = res.response.results;
      this.vcConfig.totalItems = res.response.count;
      this.vcConfig.itemsPerPage = perPageLimitSection1;
      this.vcConfig.currentPage = payload.pageNumber + 1;
    });
  }
  //pagination 
  toVcDetail(adjVcReqstId: number) {
    this.router.navigate(['/dtNmVcDetail', adjVcReqstId]);
  }

  getVcListData() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.vcConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      module: "DT",
      criterias: this.getFilters(),
      newRecord: isNewCase === "Newnotice"
    }
    this.noticeService.getVcListData(payload).subscribe((res) => {
      this.vcData = res.response.results;
      this.vcConfig.totalItems = res.response.count;
      this.totalCount = res.response.count;

    });
  }
  clearDt1() {
    if (this.reqDateArray && this.reqDateArray.length > 0) {
      this.filters.parsedRequestDate.v1 = null;
      this.filters.parsedRequestDate.v2 = null;
    }
    this.getVcListData();
    this.reqDateArray = null;
  }
  clearDt2() {
    if (this.vcDateArray && this.vcDateArray.length > 0) {
      this.filters.vcAdjRqstDate.v1 = null;
      this.filters.vcAdjRqstDate.v2 = null;
    }
    this.getVcListData();
    this.vcDateArray = null;
  }
  clearFilter(inputName: string) {
    switch (inputName) {
      case 'pan':
        this.filters.pan.v = null;
        break;
      default:
        break;
    }
    this.getVcListData();
  }
  sort(column: string) {
    switch (column) {
      case VC_SORT.PAN: {
        if (!this.sorting.pan) {
          this.sorting.pan = true;
          this.vcData = this.commonServ.sortNoticeVc(this.vcData, 'data', 'videoconferencingObj', 'pan', 'asc');
        } else {
          this.sorting.pan = false;
          this.vcData = this.vcData.sortNoticeVc(this.vcData, 'data', 'videoconferencingObj', 'pan', 'desc');
        }
        break;
      }
      case VC_SORT.VIDEO_CONF_REQ_ID: {
        if (!this.sorting.parentNoticeheaderSeqNo) {
          this.sorting.parentNoticeheaderSeqNo = true;
          this.vcData = this.commonServ.sortNoticeVc(this.vcData, 'data', 'videoconferencingObj', 'parentNoticeheaderSeqNo', 'asc');
        } else {
          this.sorting.parentNoticeheaderSeqNo = false;
          this.vcData = this.commonServ.sortNoticeVc(this.vcData, 'data', 'videoconferencingObj', 'parentNoticeheaderSeqNo', 'desc');
        }
        break;
      }
      case VC_SORT.VC_REASON: {
        if (!this.sorting.adjReason) {
          this.sorting.adjReason = true;
          this.vcData = this.commonServ.sortNoticeVc(this.vcData, 'data', 'videoconferencingObj', 'noticeId', 'asc');
        } else {
          this.sorting.adjReason = false;
          this.vcData = this.commonServ.sortNoticeVc(this.vcData, 'data', 'videoconferencingObj', 'noticeId', 'desc');
        }
        break;
      }
      case VC_SORT.REQUEST_DATE: {
        if (!this.sorting.parsedRequestDate) {
          this.sorting.parsedRequestDate = true;
          this.vcData = this.commonServ.sortNoticeVc(this.vcData, 'data', 'videoconferencingObj', 'parsedRequestDt', 'asc');
        } else {
          this.sorting.parsedRequestDate = false;
          this.vcData = this.commonServ.sortNoticeVc(this.vcData, 'data', 'videoconferencingObj', 'parsedRequestDt', 'desc');
        }
        break;
      }
      case VC_SORT.VC_DATE: {
        if (!this.sorting.vcAdjRqstDate) {
          this.sorting.vcAdjRqstDate = true;
          this.vcData = this.commonServ.sortNoticeVc(this.vcData, 'data', 'videoconferencingObj', 'vcAdjRqstDt', 'asc');
        } else {
          this.sorting.vcAdjRqstDate = false;
          this.vcData = this.commonServ.sortNoticeVc(this.vcData, 'data', 'videoconferencingObj', 'vcAdjRqstDt', 'desc');
        }
        break;
      }
    }
  }
}
