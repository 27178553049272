<div class="chart-box" *ngIf="values.length > 0">
  <div class="chart-canvas">
    <canvas
      baseChart
      class="chart"
      [type]="type"
      [data]="chartdata"
      [options]="options"
      (chartClick)="handleClick($event)"
    >
    </canvas>
  </div>
  <div class="chart-legends">
    <div class="legend-item" *ngFor="let legend of legends">
      <p><span [style]="{ color: legend.color }">&#9679;</span> {{ legend.label }}</p>
      <p>{{ legend.value }} ({{ legend.percent }}%)</p>
    </div>
  </div>
</div>

<ng-container *ngIf="values.length < 1">
  <div class="text-center no-records-found">
    <i class="fa fa-pie-chart" aria-hidden="true"></i>
    <p class="text-md text-gray">No records found.</p>
  </div>
</ng-container>
