import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormGroupDirective, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ADJ_APPELPRE, ADJ_ATTEND, ADJ_DEPTPRE, ADJ_ORDER_PASSED_BY, ADJ_OUTCOME, ADJ_PH1_ADJUNOT, ADJ_WRITPRE, DESIGNATION, ISSUE_CONCLUSION, ISSUE_DIVISION, ISSUE_RISK_PARAM, PREGST_ISSUE_DROPDOWN, PRE_ACT_TYPE, QUARTER, STATE_LIST, TYPE_OF_ACT, TYPE_OF_AUTHORITY, YES_NO, FORM_OF_PAYMENT, MODE_OF_PAYMENT, FORUM_APPEAL, MODE_OF_RECEIPT, ISSUE_CONCLUSION_3, PRE_TAB_TYPE, ISSUE_CONCLUSION_COMMISSIONER, MODE_OF_BGSTATUS, MODE_OF_PH, MODE_OF_TIME_PH } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { PreGstServiceService } from '../services/pre-gst-service.service';
import { BusinessService } from 'src/app/businessManagement/services/business.service';
import { data, event } from 'jquery';
import { Key } from 'protractor';
import { values } from 'lodash-es';
import { LitigationServiceService } from 'src/app/litigation/services/litigation-service.service';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { DatePipe } from '@angular/common';
import { InrService } from 'src/app/shared/services/inr.service';
import { HttpClient } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { ExcelService } from 'src/app/shared/services/excel.service';

export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  PAN = 'pan',
  CASE_LABEL = 'Case Label',
  ACT = 'Act',
  RGENO = 'RgeNo',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  PERSON_RESPONSIBLE = 'Person Responsible',
}
@Component({
  selector: 'app-pre-adjudication-auth',
  templateUrl: './pre-adjudication-auth.component.html',
  styleUrls: ['./pre-adjudication-auth.component.css']
})
export class PreAdjudicationAuthComponent implements OnInit {
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToHC: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToAT: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToSC: EventEmitter<string> = new EventEmitter<string>();
  @Output() enablePrevStageTab: EventEmitter<string[] | null> = new EventEmitter<string[] | null>();
  @Output() saveCaseId = new EventEmitter<boolean>();
  parentCaseId: any;
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  @Input() activeStages: string[] | null = null;
 
  preGstinDivList: any;
  selectedTab: any;
  getAccessTabData: any;
  action: any;
  caseId: any;
  currentYear: any;
  dataSource: any;
  userRoleLs: any;
  userRole: any;
  actStateList: any;
  actTypes: any;
  navContext: any;
  issueData: any;
  selectedGstin: any;
  selectedFilling: any;
  selectedPhase: any;
  pan: any;
  companyId: any;
  selectedGstinDiv: any;
  //  selectedDiv:any;
  caseType: any;
  selectedCompanyId: any;
  selectedStateName: any;
  selectedStateCode: any;
  savedPreCaseId: any;
  caseData: any = {};
  regNum: any;
  selectedUserId: any
  userEmailId: any;
  selectedActType: any
  selectedState: any
  resStateName: any
  selectedField: any;
  selectedFile: any;
  fileUrl: any;
  filePath: any;
  matches: any;
  regNumRes: any;
  selectedRegNo:any
  someElement: any;
  duplicateData:any;
  savedCaseId: any;
  proceedWithPreferredForumAppeal: any
  isShow: any;
  currentTab = 'preaja';
  activeTab: any;
  topPosToStartShowing = 100;


  //production fix , will comment late (start)
  preDepositTax = null;
  preDepositInterest = null;
  preDepositPenalty = null;
  preDepositTotal = null;
  paymentUnderProtestTax = null;
  paymentUnderProtestInterest = null;
  paymentUnderProtestPenalty = null;
  paymentUnderProtestTotal = null;
  liabilityPaidTax = null;
  liabilityPaidInterest = null;
  liabilityPaidPenalty = null;
  liabilityPaidTotal = null;
  consultantChargesTotal = null;
  //production fix , will comment late (end)


  preAjaForm!: UntypedFormGroup;
  rmvIssueModalRef!: NgbModalRef;
  rmvDetailModalRef!: NgbModalRef;
  issueReferenceRef!: NgbModalRef;

  formData: FormData = new FormData();

  isPhase2: boolean = false;
  isPhase3: boolean = false;
  isExcise: boolean = false;
  isServiceTax: boolean = false;
  isVat: boolean = false;
  isCst: boolean = false;
  isCustomsDuty: boolean = false;
  isEntryTax: boolean = false;
  isSubmitted: boolean = false;
  isUpdate: boolean = false;
  isCreateBtn: boolean = false;
  isUpdateBtn: boolean = false;
  isDataSaved: boolean = false;
  disabledOnload: boolean = false;
  isDisableIcons: boolean = false;
  isActdisable: boolean = false;
  viewIons = false;
  hideData:boolean = false;

  disablePhase3 = false;
  disablePhase2 = false;
  callBookDisabled = false;



  disabledWeatherAppl: boolean = false;
  disabledWeatherWrit: boolean = false;
  disabledWeatherDept: boolean = false;

  isUPnoticeLoc: boolean = true;
  isDWnoticeLoc: boolean = true;
  isnoticeLocFile: boolean = false;
  isnoticeLocText: boolean = true;

  isUPattachmentsLoc: boolean = true;
  isDWattachmentsLoc: boolean = true;
  isattachmentsLocFile: boolean = false;
  isattachmentsLocText: boolean = true;

  isUPcopyOfAckLoc: boolean = true;
  isDWcopyOfAckLoc: boolean = true;
  iscopyOfAckLocFile: boolean = false;
  iscopyOfAckLocText: boolean = true;

  isUPotherAttachmentsLoc: boolean = true;
  isDWotherAttachmentsLoc: boolean = true;
  isotherAttachmentsLocFile: boolean = false;
  isotherAttachmentsLocText: boolean = true;

  isUPphCopyLoc: boolean = true;
  isDWphCopyLoc: boolean = true;
  isphCopyLocFile: boolean = false;
  isphCopyLocText: boolean = true;

  isUPadditionalSubmissionLoc: boolean = true;
  isDWadditionalSubmissionLoc: boolean = true;
  isUadditionalSubmissionLocFile: boolean = false;
  isadditionalSubmissionLocText: boolean = true;

  isUPphRecordIfAnyLoc: boolean = true;
  isDWphRecordIfAnyLoc: boolean = true;
  isphRecordIfAnyLocFile: boolean = false;
  isphRecordIfAnyLocText: boolean = true;

  isUPoioLoc: boolean = true;
  isDWoioLoc: boolean = true;
  isoioLocFile: boolean = false;
  isoioLocText: boolean = true;

  isUPdrc03CopyLoc: boolean = true;
  isDWdrc03CopyLoc: boolean = true;
  isdrc03CopyLocFile: boolean = false;
  isdrc03CopyLocText: boolean = true;


  copyOfAckLocReq: boolean = false;
  noticeLocReq: boolean = false;
  oioLocReq: boolean = false;

  maxDate = new Date();
  finyearData: any[] = [];
  caseItemList: any = [];
  stateList: any = [];
  division: { key: string; value: string; }[];
  riskParam: { key: string; value: string; }[];
  orderPassedBy: { key: string; value: string; }[];
  outcome: { key: string; value: string; }[];
  typeOfAct: { key: string; value: string; }[];
  issueConclusion: { key: string; value: string; }[];
  quarterList: { key: string; value: string; disabled: string; }[];
  tabType: { key: string; value: string; }[];
  modeOfReceiptNotice: { key: string; value: string; }[];
  yesNoList: { label: string; value: boolean; }[];
  whetherAppealPreferred: { label: string; value: boolean; }[];
  writAppPreferred: { label: string; value: boolean; }[];
  whetherDptAppealPreferred: { label: string; value: boolean; }[];
  adjPHAdjuorNotList: { label: string; value: boolean; }[];
  adjAttend: { label: string; value: boolean; }[];
  sorting: {
    caseId: boolean;
    state: boolean;
    panName: boolean;
    caseLabel: boolean;
    periodFromDate: boolean;
    periodToDate: boolean;
    personResponsible: boolean;
    actyType: boolean;
    regNo: boolean;
  } = {
      caseId: false,
      state: false,
      panName: false,
      caseLabel: false,
      periodFromDate: false,
      periodToDate: false,
      personResponsible: false,
      actyType: false,
      regNo: false,
    };
    issueSorting = SORT_ISSUE;

  // isRemandedBack = false;
  typeOfAuthorityDropdown = TYPE_OF_AUTHORITY;
  keepCaseInCallBookOptions = YES_NO;
  yesNoDropdown = YES_NO;
  modeOfPH = MODE_OF_PH;
  modeOfTime =MODE_OF_TIME_PH;
  designation = DESIGNATION;
  formOfPayment = FORM_OF_PAYMENT;
  modeOfPayment = MODE_OF_PAYMENT;
  modeOfAppealOptions = MODE_OF_RECEIPT
  bgStatusOptions = MODE_OF_BGSTATUS
  isCopyOfFile = false;
  isCopyOfFileText = true;
  isconsultantattachmentsDWLocText = false;
  isconsultantattachmentsUPLocText = true;

  iscopyOfBankChallanFile = false;
  iscopyOfBankChallanText = true;
  iscopyOfBankChallanDWText = false;
  iscopyOfBankChallanUPText = true;

  rmvDetailsOfPaymentRef: any;
  rmvbankGuaranteeRef:any;
  protestDetailsExpansion = false;
  guaranteDetailsExpansion = false;
  detailsOfIssuingAuthority = false;
  paymentUnderRemandedBackExpansion = false;

  isCopyOfAckFile = false;
  isCopyOfAckText = true;
  isCopyOfAckDWText = true;
  isCopyOfAckUPText = true;

  isOtherAttachmentFile = false;
  isOtherAttachmentText = true;
  isOtherAttachmentDWText = true;
  isOtherAttachmentUPText = true;

  isSupportingDocFile = false;
  isSupportingDocText = true;
  isSupportingDocDWText = true;
  isSupportingDocUPText = true;

  isconsultantattachmentsLocFile = false;
  isconsultantattachmentsLocText = true;
  isconsultantDWattachmentsLocText = true;
  isconsultantUPattachmentsLocText = true;

  isSupportingDocFile2 = false;
  isSupportingDocText2 = true;
  isSupportingDocDWText2 = true;
  isSupportingDocUPText2 = true;

  copyOfAckLocDocFile = false;
  copyOfAckLocDocText = true;
  copyOfAckLocDWText = false;
  copyOfAckLocUPText = true;

  isToggleDropdown = true;

  preAdjType = PRE_ACT_TYPE;
  remandedCaseObj: any = [];
  detailsOfSubmissionsExpansion = false;

  rmvSubmissionDetailsRef: any;

  forumAppeal = FORUM_APPEAL;
  rmvConsultantModalRef!: NgbModalRef;

  consultantPanleExpansion = false;


  adjOutcome = ADJ_OUTCOME;
  appealDetailItemList: any = [];
  modeOfPaymentObjectLiabilityAdmitted = MODE_OF_PAYMENT;
  liabilityExpansion = false;
  rmvLiabilityAddmittedAndPaidModalRef: any;
  liabilityAddmittedAndPaidInterestTotal = 0;
  liabilityAddmittedAndPaidTaxTotal = 0;
  liabilityAddmittedAndPaidPenaltyTotal = 0;
  liabilityAddmittedAndPaidTotal = 0;
  detailsOfLiabilityAddmittedAndPaidExpanded: Boolean = false
  detailsOfAllPaymentMadeExpansion = false;
  detailOfIssuingAuthorityExpansion = false;


  cumulativeCount = [
    // {label :"Cumulative Pre-Deposit", tax: 0, interest:0, penalty: 0, total: 0},
    { label: "Cumulative payment made under protest", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative payment made for admitted liability", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative Consultant Charges", tax: null, interest: null, penalty: null, total: 0 }
  ]

  protestInterestTotal = 0;
  protestTaxTotal = 0;
  protestPenaltyTotal = 0;
  protestTotal = 0;

  liabilityTotals = { taxTotal: 0, interestTotal: 0, penaltyTotal: 0, allTotal: 0 };

  feesChargedTotal = 0;


  remandedPaymentMade = null;
  selectedRemandedBackCaseDetail: any = null;
  remandBackCaseList = [];
  oldProtestDetailsValues: any = [];
  oldLiabilityDetailValues: any = []
  oldPredepositeDetailsValues: any = []
  oldConsulatntValues: any = []
  prevStage: any


  constructor(
    private preLitService: PreGstServiceService,
    private litService: LitigationServiceService,
    private bussinessService: BusinessService,
    private activeSnapShot: ActivatedRoute,
    private fBuild: UntypedFormBuilder,
    private commonServ: CommonService,
    private modalService: NgbModal,
    private toaster: ToasterService,
    private excelService: ExcelService,
    private router: Router,
    private datePipe: DatePipe,
    private getterSetter: GetterSetterService,
    private inrService: InrService,
    private http: HttpClient,
  ) {
    this.action = this.activeSnapShot.snapshot.params.action;
    this.caseId = this.activeSnapShot.snapshot.params.caseId;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.activeSnapShot.snapshot.params.remanded == 'true' ? (this.caseType = 'REMANDED') : null;
    this.adjPHAdjuorNotList = ADJ_PH1_ADJUNOT;
    this.adjAttend = ADJ_ATTEND;
    this.division = ISSUE_DIVISION;
    this.riskParam = ISSUE_RISK_PARAM;
    this.orderPassedBy = DESIGNATION;
    this.outcome = ADJ_OUTCOME;
    this.whetherAppealPreferred = ADJ_APPELPRE;
    this.writAppPreferred = ADJ_WRITPRE;
    this.whetherDptAppealPreferred = ADJ_DEPTPRE;
    this.typeOfAct = TYPE_OF_ACT;
    this.modeOfReceiptNotice = MODE_OF_RECEIPT;
    this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER;
    this.quarterList = QUARTER;
    this.yesNoList = YES_NO;
    this.tabType = PRE_TAB_TYPE;
  }

  ngOnInit() {
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.selectedGstinDiv = this.selectedFilling.gstinDiv;
    this.selectedUserId = this.getterSetter.getSetUserId();
    // this.selectedDiv = this. selectedFilling.div;
    this.selectedCompanyId = this.getterSetter.getSetCompanyId();
    this.navContext = this.commonServ.getNavContext();
    this.pan = this.navContext.pan;
    this.selectedTab = 'tab1';
    this.selectedPhase = 'phase0';
    this.personResponsibleData();
    this.gstinDivData();
    this.actTypeFetch();

    // this.setStateAutoPop();
    this.stateList = STATE_LIST;
    this.preLitService.getAccessTabData().subscribe((val) => {
      if (val) {
        this.getAccessTabData = val;
      }
    });
    if (this.caseId || this.parentCaseId) {
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.initializeData();
      // this.fetchRegNo();
    }
    this.formInitialize();
    this.issueDropdown();
    this.setDate();
    this.getYears();
    if (this.action === 'create') {
      this.createAdjDiv();
      this.addIssueInv();
      this.addDetailOfPayment();
      this.addDetailOfBankGuarantee();
      this.addDetailsOfLiabilityAddmittedAndPaid();
      this.addSubmissionDetailsArr();
      this.addConsultantInv();

      this.isCreateBtn = true;
      this.isDisableIcons = true;
      this.viewIons = false;


      this.isnoticeLocFile = true;
      this.isnoticeLocText = false;
      this.isUPnoticeLoc = false;

      this.isattachmentsLocFile = true;
      this.isattachmentsLocText = false;
      this.isUPattachmentsLoc = false;

      this.iscopyOfAckLocFile = true;
      this.iscopyOfAckLocText = false;
      this.isUPcopyOfAckLoc = false;

      this.isotherAttachmentsLocFile = true;
      this.isotherAttachmentsLocText = false;
      this.isUPotherAttachmentsLoc = false;

      this.isphCopyLocFile = true;
      this.isphCopyLocText = false;
      this.isUPphCopyLoc = false;

      this.isUadditionalSubmissionLocFile = true;
      this.isadditionalSubmissionLocText = false;
      this.isUPadditionalSubmissionLoc = false;

      this.isphRecordIfAnyLocFile = true;
      this.isphRecordIfAnyLocText = false;
      this.isUPphRecordIfAnyLoc = false;

      this.isoioLocFile = true;
      this.isoioLocText = false;
      this.isUPoioLoc = false;

      this.isdrc03CopyLocFile = true;
      this.isdrc03CopyLocText = false;
      this.isUPdrc03CopyLoc = false;

    } else if (this.action === 'update') {
      this.isUpdateBtn = true;
      this.isDataSaved = true;
      this.disabledOnload = true;
      this.isDisableIcons = true;
      this.viewIons = true;

    } else if (this.action === 'view') {
      this.disabledOnload = true;
      this.isDataSaved = true;
      this.isUpdateBtn = true;
      this.isPhase3 = true;
      this.isDisableIcons = false;
      this.viewIons = false;



      this.isUPnoticeLoc = false;
      this.isDWnoticeLoc = true;

      this.isUPattachmentsLoc = false;
      this.isDWattachmentsLoc = true;

      this.isUPcopyOfAckLoc = false;
      this.isDWcopyOfAckLoc = true;

      this.isUPotherAttachmentsLoc = false;
      this.isDWotherAttachmentsLoc = true;

      this.isUPphCopyLoc = false;
      this.isDWphCopyLoc = true;

      this.isUPadditionalSubmissionLoc = false;
      this.isDWadditionalSubmissionLoc = true;

      this.isUPphRecordIfAnyLoc = false;
      this.isDWphRecordIfAnyLoc = true;

      this.isUPoioLoc = false;
      this.isDWoioLoc = true;

      this.isUPdrc03CopyLoc = false;
      this.isDWdrc03CopyLoc = true;

    }
    const userId: any = sessionStorage.getItem('user');
    this.preAjaForm.controls.personResponsible1.patchValue(userId);
  }

  formInitialize() {
    this.preAjaForm = this.fBuild.group({
      //phase1
      companyId: [this.selectedCompanyId],
      gstinDiv: [this.selectedGstinDiv],
      // div:[this.selectedDiv],
      // pan: [this.pan],
      pan: [this.caseData?.pan ? this.caseData?.pan : this.pan],
      status: [this.caseData?.status],
      caseId: [this.caseData?.caseId],
      prevOrderLoc: [this.caseData?.prevOrderLoc],
      actType: [this.caseData?.actType, [Validators.required]],
      state: [this.resStateName, [Validators.required]],
      regNo: [this.caseData?.regNo ? this.caseData?.regNo : this.regNum, [Validators.required]],
      updatedBy: [this.caseData?.updatedBy],
      updatedOn: [this.caseData?.updatedOn],
      noticeNo: [this.caseData?.noticeNo, [Validators.required]],
      noticeDate: [this.caseData?.noticeDate, [Validators.required]],
      noticeReceiptDate: [this.caseData?.noticeReceiptDate, [Validators.required]],
      dueDateOfReply: [this.caseData?.dueDateOfReply, [Validators.required]],
      internalDueDate: [this.caseData?.internalDueDate],
      periodFromDate: [this.caseData?.periodFromDate, [Validators.required]],
      periodToDate: [this.caseData?.periodToDate, [Validators.required]],
      consultant1: [this.caseData?.consultant1],
      personResponsible1: [this.caseData?.personResponsible1, [Validators.required]],
      orderReceiptMode1: [this.caseData?.orderReceiptMode1],
      orderReceiptMode3: [this.caseData?.orderReceiptMode3],
      remandedCaseId: [this.caseData?.remandedCaseId],
      issues: new UntypedFormArray([]),
      noticeLoc: new UntypedFormArray([]),
      attachmentsLoc: new UntypedFormArray([]),
      caseSummary: [this.caseData?.caseSummary],
      internalRemarks: [this.caseData?.internalRemarks],
      exciseDuty1Total: [this.action == 'create' ? '0' : this.caseData?.exciseDuty1Total],
      ec1Total: [this.action == 'create' ? '0' : this.caseData?.ec1Total],
      shec1Total: [this.action == 'create' ? '0' : this.caseData?.shec1Total],
      serviceTax1Total: [this.action == 'create' ? '0' : this.caseData?.serviceTax1Total],
      kkc1Total: [this.action == 'create' ? '0' : this.caseData?.kkc1Total],
      sbc1Total: [this.action == 'create' ? '0' : this.caseData?.sbc1Total],
      totalservTax5: [this.action == 'create' ? '0' : this.caseData?.totalservTax5],
      vat1Total: [this.action == 'create' ? '0' : this.caseData?.vat1Total],
      cst1Total: [this.action == 'create' ? '0' : this.caseData?.cst1Total],
      bcd1Total: [this.action == 'create' ? '0' : this.caseData?.bcd1Total],
      sad1Total: [this.action == 'create' ? '0' : this.caseData?.sad1Total],
      antiDumpingDuty1Total: [this.action == 'create' ? '0' : this.caseData?.antiDumpingDuty1Total],
      igst1Total: [this.action == 'create' ? '0' : this.caseData?.igst1Total],
      ent1Total: [this.action == 'create' ? '0' : this.caseData?.ent1Total],
      interest1Total: [this.action == 'create' ? '0' : this.caseData?.interest1Total],
      penalty1Total: [this.action == 'create' ? '0' : this.caseData?.penalty1Total],
      totOfTotal1: [this.action == 'create' ? '0' : this.caseData?.totOfTotal1],

      //phase2
      // dateOfSubmission:[this.caseData?.dateOfSubmission],
      // consultant2:[this.caseData?.consultant2],
      // personResponsible2: [this.caseData?.personResponsible2],
      // copyOfAckLoc:[this.caseData?.copyOfAckLoc],
      // otherAttachmentsLoc:[this.caseData?.otherAttachmentsLoc],
      itemList: this.fBuild.array([]),
      //phase3
      oioNo: [this.caseData?.oioNo],
      dateOfOio: [this.caseData?.dateOfOio],
      dateOfReceiptOfOio: [this.caseData?.dateOfReceiptOfOio],
      oioLoc: new UntypedFormArray([]),
      orderPassedBy: [this.caseData?.orderPassedBy],
      outcome: [this.caseData?.outcome],
      whetherAppealPreferred: [this.caseData?.whetherAppealPreferred],
      writAppPreferred: [this.caseData?.writAppPreferred],
      whetherDptAppealPreferred: [this.caseData?.whetherDptAppealPreferred],
      orderSummery: [this.caseData?.orderSummery],
      // // drc03CopyLoc: [this.caseData?.drc03CopyLoc],
      // // drc03Tax: [this.caseData?.drc03Tax, [Validators.pattern(/^[1-9]\d*$/)]],
      // // drc03Interest: [this.caseData?.drc03Interest, [Validators.pattern(/^[1-9]\d*$/)]],
      // // drc03Penalty: [this.caseData?.drc03Penalty, [Validators.pattern(/^[1-9]\d*$/)]],

      exciseDuty3Total: [this.action == 'create' ? '0' : this.caseData?.exciseDuty3Total],
      ec3Total: [this.action == 'create' ? '0' : this.caseData?.ec3Total],
      shec3Total: [this.action == 'create' ? '0' : this.caseData?.shec3Total],
      serviceTax3Total: [this.action == 'create' ? '0' : this.caseData?.serviceTax3Total],
      kkc3Total: [this.action == 'create' ? '0' : this.caseData?.kkc3Total],
      sbc3Total: [this.action == 'create' ? '0' : this.caseData?.sbc3Total],
      vat3Total: [this.action == 'create' ? '0' : this.caseData?.vat3Total],
      cst3Total: [this.action == 'create' ? '0' : this.caseData?.cst3Total],
      bcd3Total: [this.action == 'create' ? '0' : this.caseData?.bcd3Total],
      sad3Total: [this.action == 'create' ? '0' : this.caseData?.sad3Total],
      antiDumpingDuty3Total: [this.action == 'create' ? '0' : this.caseData?.antiDumpingDuty3Total],
      igst3Total: [this.action == 'create' ? '0' : this.caseData?.igst3Total],
      ent3Total: [this.action == 'create' ? '0' : this.caseData?.ent3Total],
      interest3Total: [this.action == 'create' ? '0' : this.caseData?.interest3Total],
      penalty3Total: [this.action == 'create' ? '0' : this.caseData?.penalty3Total],
      totOfTotal3: [this.action == 'create' ? '0' : this.caseData?.totOfTotal3],
      // new fields
      // predeposit: [null],
      // paymentUnderProtest: [null],
      // typeOfAuthority: [null],
      din1: [null],
      din3: [null],
      isExtensionGranted: [false],
      extensionDays: [null],
      portCode: [null],
      portName: [null],
      extPeriodOfLimitationInvoked: [false],
      caseLabel: [this.caseData?.caseLabel, [Validators.required, Validators.minLength(5), Validators.maxLength(100)]],
      whetherWritPreferredHc: [false],
      whetherCaseCallBook: [this.caseData?.whetherCaseCallBook],
      officer: [this.caseData?.issuingAuth1?.officer],
      designation: [this.caseData?.issuingAuth1?.designation],
      jurisdiction: [this.caseData?.issuingAuth1?.jurisdiction],
      address: [this.caseData?.issuingAuth1?.address],
      contact: [this.caseData?.issuingAuth1?.contact],
      email: [this.caseData?.issuingAuth1?.email, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/), Validators.maxLength(100)]],
      protestDetails: new UntypedFormArray([]),
      bankGuarantee: new UntypedFormArray([]),
      liabilityDetails: new UntypedFormArray([]),
      submissions: new UntypedFormArray([]),
      consultantCharges: new UntypedFormArray([]),
      whetherRemandedBack: [null],
      fromWhichForumCaseIsRemandedBack: [null],
      remandedBackForum: [null],
      preDepositTax: [null],
      preDepositInterest: [null],
      preDepositPenalty: [null],
      preDepositTotal: [null],
      paymentUnderProtestTax: [0],
      paymentUnderProtestInterest: [0],
      paymentUnderProtestPenalty: [0],
      paymentUnderProtestTotal: [0],
      liabilityPaidTax: [0],
      liabilityPaidInterest: [0],
      liabilityPaidPenalty: [0],
      liabilityPaidTotal: [0],
      consultantChargesTotal: [0],
      din2: [null],
      forumOfAppeal: [null],
      officer3: [this.caseData?.issuingAuth3?.officer],
      jurisdiction3: [this.caseData?.issuingAuth3?.jurisdiction],
      contact3: [this.caseData?.issuingAuth3?.contact],
      designation3: [this.caseData?.issuingAuth3?.designation],
      address3: [this.caseData?.issuingAuth3?.address],
      email3: [this.caseData?.issuingAuth3?.email, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/), Validators.maxLength(100)]],
      remandedPaymentMade: new UntypedFormArray([])

    });
    this.preAjaForm.controls['protestDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldProtestDetailsValues = this.preAjaForm.value['protestDetails'];
        this.setCummulativeCount(this.oldProtestDetailsValues, selectedValue, 'protestDetails');
      });

    this.preAjaForm.controls['consultantCharges'].valueChanges
      .subscribe(selectedValue => {
        this.oldConsulatntValues = this.preAjaForm.value['consultantCharges'];
        this.setCummulativeCount(this.oldConsulatntValues, selectedValue, 'consultantCharges');
      });

    this.preAjaForm.controls['liabilityDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldLiabilityDetailValues = this.preAjaForm.value['liabilityDetails'];
        this.setCummulativeCount(this.oldLiabilityDetailValues, selectedValue, 'liabilityDetails');
      });
  }
  getoioLoc(): UntypedFormGroup[] {
    return (this.preAjaForm.get('oioLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getNoticeLoc(): UntypedFormGroup[] {
    return (this.preAjaForm.get('noticeLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAttachmentsLoc(): UntypedFormGroup[] {
    return (this.preAjaForm.get('attachmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getProtestSupportingDocLocAttachment(supportingDocLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (supportingDocLocGrp.get('supportingDocLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLiabilitySupportingDocLocAttachment(copyOfFormLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfFormLocGrp.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getcopyOfAckLocAttachment(copyOfAckLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfAckLocGrp.get('copyOfAckLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getOtherAttachments(otherAttachmentsLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (otherAttachmentsLocGrp.get('otherAttachmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getphCopyLoc(phCopyLocFrmGrp: any): UntypedFormGroup[] {
    return (phCopyLocFrmGrp.get('phCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getAdditionalSub(addSubLocFrmGrp: any): UntypedFormGroup[] {
    return (addSubLocFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhRecord(phRecordFrmGrp: any): UntypedFormGroup[] {
    return (phRecordFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAdjournmentLetter(phAdjournmentLetterFrmGrp: any): UntypedFormGroup[] {
    return (phAdjournmentLetterFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getConsultOtherAttachment(consultAttachGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (consultAttachGrp.get('otherAttachmentLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getNoticeLocLength() {
    let count = 0;
    let predepositFrmArray = this.preAjaForm.get('noticeLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }

  getAttachmentsLocLength() {
    let count = 0;
    let predepositFrmArray = this.preAjaForm.get('attachmentsLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getProtestCopyLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.preAjaForm.get('protestDetails') as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get('supportingDocLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getCopyOfAckLocLength(j?: any) {
    let count = 0;
    let submissionsFrmArray = this.preAjaForm.get('submissions') as UntypedFormArray;
    let letterLocFrmArray = submissionsFrmArray.controls[j]?.get('copyOfAckLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getOtherAttachmentsLocLength(j?: any) {
    let count = 0;
    let submissionsFrmArray = this.preAjaForm.get('submissions') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionsFrmArray.controls[j]?.get('otherAttachmentsLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhCopyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preAjaForm.get('itemList') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = phCopyLocArray.controls[j]?.get('phCopyLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAdditionalSubmissionLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preAjaForm.get('itemList') as UntypedFormArray;
    let additionalSubmissionLocFrmArray = phCopyLocArray.controls[j]?.get('additionalSubmissionLoc') as UntypedFormArray;
    let lettercount = additionalSubmissionLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhRecordIfAnyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preAjaForm.get('itemList') as UntypedFormArray;
    let phRecordIfAnyLocFrmArray = phCopyLocArray.controls[j]?.get('phRecordIfAnyLoc') as UntypedFormArray;
    let lettercount = phRecordIfAnyLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getphAdjournmentLetterLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preAjaForm.get('itemList') as UntypedFormArray;
    let phAdjournmentLetterLocFrmArray = phCopyLocArray.controls[j]?.get('phAdjournmentLetterLoc') as UntypedFormArray;
    let lettercount = phAdjournmentLetterLocFrmArray.value.filter((x: any) => x.fileLoc != '');
    return lettercount.length;
  }
  getConsultOtherAttachmentLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.preAjaForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('otherAttachmentLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getOioLocLength() {
    let count = 0;
    let predepositFrmArray = this.preAjaForm.get('oioLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getLiabilityCopyFormLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.preAjaForm.get('liabilityDetails') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }

  openOioLocModel(content: any) {
    this.addOioLoc();
    this.modalService.open(content);
  }
  openNoticeLocModel(content: any) {
    this.addNoticeLoc();
    this.modalService.open(content);
  }
  openAttachmentsLocModel(content: any) {
    this.addOtherAttachment();
    this.modalService.open(content);
  }
  openProtestSupportingDocLoctAttach(content: any, i: any) {
    this.addProtestSupportingDoc(i, 0);
    this.modalService.open(content);
  }
  openLiabilitySupportingDocLocAttachment(content: any, i: any) {
    this.addLiabilitySupportingDoc(i, 0);
    this.modalService.open(content);
  }
  openCopyOfAckLoc(content: any, i: any) {
    this.addCopyOfAckLoc(i, 0);
    this.modalService.open(content);
  }
  openOtherAttachmentsLoc(content: any, i: any) {
    this.addotherAttachmentsLoc(i, 0);
    this.modalService.open(content);
  }

  openphCopyLoc(content: any, i: any) {
    this.addphCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openAdditionSub(content: any, i: any) {
    this.addAdditionalCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openPhRecord(content: any, i: any) {
    this.addPhRecord(i, 0);
    this.modalService.open(content);
  }
  openAdjournmentLetter(content: any, i: any) {
    this.addAdjournmentLetter(i, 0);
    this.modalService.open(content);
  }
  openConsultotherAttachmentLoc(content: any, i: any) {
    this.addConsultotherAttachmentLoc(i, 0);
    this.modalService.open(content);
  }
  creatOioLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createNoticeLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOtherAttachment(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createProtestSupportingDoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createLiabilitySupportingDocLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createCopyOfAckLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOtherAttachmentsLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createphCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAdditionalSubLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhRecord(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhAdjournmentLetterLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createConsultotherAttachmentLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addProtestSupportingDoc(j: any, i: any) {
    let protestDetailsFrmArray = this.preAjaForm.get('protestDetails') as UntypedFormArray;
    let supportingDocLocFrmArray = protestDetailsFrmArray.controls[j].get('supportingDocLoc') as UntypedFormArray;

    if (supportingDocLocFrmArray.length > 0) {
      let length = supportingDocLocFrmArray.length
      while (i < 5 - length) {
        supportingDocLocFrmArray.push(this.createProtestSupportingDoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        supportingDocLocFrmArray.push(this.createProtestSupportingDoc());
        i++;
      }
    }
  }
  addLiabilitySupportingDoc(j: any, i: any) {
    let liabilityDetailsFrmArray = this.preAjaForm.get('liabilityDetails') as UntypedFormArray;
    let copyOfFormLocFrmArray = liabilityDetailsFrmArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;

    if (copyOfFormLocFrmArray.length > 0) {
      let length = copyOfFormLocFrmArray.length
      while (i < 5 - length) {
        copyOfFormLocFrmArray.push(this.createLiabilitySupportingDocLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyOfFormLocFrmArray.push(this.createLiabilitySupportingDocLoc());
        i++;
      }
    }
  }
  addCopyOfAckLoc(j: any, i: any) {
    let submissionFrmArray = this.preAjaForm.get('submissions') as UntypedFormArray;
    let copyOfAckLocFrmArray = submissionFrmArray.controls[j].get('copyOfAckLoc') as UntypedFormArray;

    if (copyOfAckLocFrmArray.length > 0) {
      let length = copyOfAckLocFrmArray.length
      while (i < 5 - length) {
        copyOfAckLocFrmArray.push(this.createCopyOfAckLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyOfAckLocFrmArray.push(this.createCopyOfAckLoc());
        i++;
      }
    }
  }
  addotherAttachmentsLoc(j: any, i: any) {
    let submissionFrmArray = this.preAjaForm.get('submissions') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionFrmArray.controls[j].get('otherAttachmentsLoc') as UntypedFormArray;

    if (otherAttachmentsLocFrmArray.length > 0) {
      let length = otherAttachmentsLocFrmArray.length
      while (i < 5 - length) {
        otherAttachmentsLocFrmArray.push(this.createOtherAttachmentsLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        otherAttachmentsLocFrmArray.push(this.createOtherAttachmentsLoc());
        i++;
      }
    }
  }
  addphCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.preAjaForm.get('itemList') as UntypedFormArray;
    let phCopyLocFrmArray = dtCaseFrmArray.controls[j].get('phCopyLoc') as UntypedFormArray;

    if (phCopyLocFrmArray.length > 0) {
      let length = phCopyLocFrmArray.length
      while (i < 5 - length) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
  }
  addConsultotherAttachmentLoc(j: any, i: any) {
    let consultOtherAttachFrmArray = this.preAjaForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultOtherAttachFrmArray.controls[j].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.length > 0) {
      let length = otherAttachmentLocFrmArray.length
      while (i < 5 - length) {
        otherAttachmentLocFrmArray.push(this.createConsultotherAttachmentLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        otherAttachmentLocFrmArray.push(this.createConsultotherAttachmentLoc());
        i++;
      }
    }
  }
  uploadPhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;

    let itemListsArray = this.preAjaForm.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    let itemListsArray = this.preAjaForm.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addAdditionalCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.preAjaForm.get('itemList') as UntypedFormArray;
    let additionalSubLocFrmArray = dtCaseFrmArray.controls[j].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.length > 0) {
      let length = additionalSubLocFrmArray.length
      while (i < 5 - length) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
  }
  uploadAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.preAjaForm.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.preAjaForm.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;
    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addPhRecord(j: any, i: any) {
    let dtCaseFrmArray = this.preAjaForm.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
  }
  uploadPhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.preAjaForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.preAjaForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  addAdjournmentLetter(j: any, i: any) {
    let dtCaseFrmArray = this.preAjaForm.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
  }
  uploadAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.preAjaForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.preAjaForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadProtestSupportingDoc(protestDetailsListIndex: any, supportingDocLocIndex: any) {
    let protestDetailsFrmArray = this.preAjaForm.get('protestDetails') as UntypedFormArray;
    let supportingDocLocFrmArray = protestDetailsFrmArray.controls[protestDetailsListIndex].get('supportingDocLoc') as UntypedFormArray;

    if (supportingDocLocFrmArray.controls.length > 0) {
      for (let index = 0; index < supportingDocLocFrmArray.controls.length; index++) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = supportingDocLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteProtestSupportingDoc(protestDetailsListIndex: any, supportingDocLocIndex: any) {
    let protestDetailsFrmArray = this.preAjaForm.get('protestDetails') as UntypedFormArray;
    let supportingDocLocFrmArray = protestDetailsFrmArray.controls[protestDetailsListIndex].get('supportingDocLoc') as UntypedFormArray;

    if (supportingDocLocFrmArray.controls.length > 0) {
      for (let index = 0; index < supportingDocLocFrmArray.controls.length; index++) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = supportingDocLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadLiabilitySupportingDoc(liabilityDetailsListIndex: any, copyOfFormLocIndex: any) {
    let liabilityDetailsFrmArray = this.preAjaForm.get('liabilityDetails') as UntypedFormArray;
    let copyOfFormLocFrmArray = liabilityDetailsFrmArray.controls[liabilityDetailsListIndex].get('copyOfFormLoc') as UntypedFormArray;

    if (copyOfFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyOfFormLocFrmArray.controls.length; index++) {
        if (index === copyOfFormLocIndex) {
          let itemListuploadControl = copyOfFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLiabilitySupportingDoc(liabilityDetailsListIndex: any, copyOfFormLocIndex: any) {
    let liabilityDetailsFrmArray = this.preAjaForm.get('liabilityDetails') as UntypedFormArray;
    let copyOfFormLocFrmArray = liabilityDetailsFrmArray.controls[liabilityDetailsListIndex].get('copyOfFormLoc') as UntypedFormArray;

    if (copyOfFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyOfFormLocFrmArray.controls.length; index++) {
        if (index === copyOfFormLocIndex) {
          let itemListuploadControl = copyOfFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadCopyOfAckLoc(submissionListIndex: any, copyOfAckLocIndex: any) {
    let submissionFrmArray = this.preAjaForm.get('submissions') as UntypedFormArray;
    let copyOfAckLocFrmArray = submissionFrmArray.controls[submissionListIndex].get('copyOfAckLoc') as UntypedFormArray;

    if (copyOfAckLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyOfAckLocFrmArray.controls.length; index++) {
        if (index === copyOfAckLocIndex) {
          let itemListuploadControl = copyOfAckLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteCopyOfAckLoc(submissionListIndex: any, copyOfAckLocIndex: any) {
    let submissionFrmArray = this.preAjaForm.get('submissions') as UntypedFormArray;
    let copyOfAckLocFrmArray = submissionFrmArray.controls[submissionListIndex].get('copyOfAckLoc') as UntypedFormArray;

    if (copyOfAckLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyOfAckLocFrmArray.controls.length; index++) {
        if (index === copyOfAckLocIndex) {
          let itemListuploadControl = copyOfAckLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadOtherAttachmentsLoc(submissionListIndex: any, otherAttachmentsLocIndex: any) {
    let submissionFrmArray = this.preAjaForm.get('submissions') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionFrmArray.controls[submissionListIndex].get('otherAttachmentsLoc') as UntypedFormArray;

    if (otherAttachmentsLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachmentsLocFrmArray.controls.length; index++) {
        if (index === otherAttachmentsLocIndex) {
          let itemListuploadControl = otherAttachmentsLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttachmentsLoc(submissionListIndex: any, otherAttachmentsLocIndex: any) {
    let submissionFrmArray = this.preAjaForm.get('submissions') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionFrmArray.controls[submissionListIndex].get('otherAttachmentsLoc') as UntypedFormArray;

    if (otherAttachmentsLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachmentsLocFrmArray.controls.length; index++) {
        if (index === otherAttachmentsLocIndex) {
          let itemListuploadControl = otherAttachmentsLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadConsulOthertAttach(consultOtherAttachListIndex: any, supportingDocLocIndex: any) {
    let consultOtherAttachFrmArray = this.preAjaForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultOtherAttachFrmArray.controls[consultOtherAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachmentLocFrmArray.controls.length; index++) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = otherAttachmentLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteConsulOthertAttach(consultOtherAttachListIndex: any, supportingDocLocIndex: any) {
    let consultOtherAttachFrmArray = this.preAjaForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultOtherAttachFrmArray.controls[consultOtherAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachmentLocFrmArray.controls.length; index++) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = otherAttachmentLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  addOioLoc() {
    let oioLocCopyArray = this.preAjaForm.get('oioLoc') as UntypedFormArray;
    let i = 0;

    if (oioLocCopyArray.length > 0) {
      let length = oioLocCopyArray.length;

      while (i < 5 - length) {
        oioLocCopyArray.push(this.creatOioLoc());

        i++;
      }
    } else {
      while (i < 5) {
        oioLocCopyArray.push(this.creatOioLoc());

        i++;
      }
    }

  }
  addNoticeLoc() {
    let noticeLocCopyArray = this.preAjaForm.get('noticeLoc') as UntypedFormArray;
    let i = 0;

    if (noticeLocCopyArray.length > 0) {
      let length = noticeLocCopyArray.length;

      while (i < 5 - length) {
        noticeLocCopyArray.push(this.createNoticeLoc());

        i++;
      }
    } else {
      while (i < 5) {
        noticeLocCopyArray.push(this.createNoticeLoc());

        i++;
      }
    }

  }
  addOtherAttachment() {
    let attachmentsArray = this.preAjaForm.get('attachmentsLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createOtherAttachment());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createOtherAttachment());

        i++;
      }
    }
  }
  uploadOioLoc(oioLocIndex: any) {
    let oioLocCopyArray = this.preAjaForm.controls.oioLoc as UntypedFormArray;
    if (oioLocCopyArray.controls.length > 0) {
      for (let index = 0; index < oioLocCopyArray.controls.length; index++) {
        if (index === oioLocIndex) {
          let itemListuploadControl = oioLocCopyArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOioLocF(oioLocIndex: any) {
    let oioLocCopyArray = this.preAjaForm.controls.oioLoc as UntypedFormArray;
    if (oioLocCopyArray.controls.length > 0) {
      for (let index = 0; index < oioLocCopyArray.controls.length; index++) {
        if (index === oioLocIndex) {
          let itemListuploadControl = oioLocCopyArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadNoticeLoc(noticeLocIndex: any) {
    let noticeLocCopyArray = this.preAjaForm.controls.noticeLoc as UntypedFormArray;
    if (noticeLocCopyArray.controls.length > 0) {
      for (let index = 0; index < noticeLocCopyArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocCopyArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteNoticeLoc(noticeLocIndex: any) {
    let noticeLocCopyArray = this.preAjaForm.controls.noticeLoc as UntypedFormArray;
    if (noticeLocCopyArray.controls.length > 0) {
      for (let index = 0; index < noticeLocCopyArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocCopyArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadOtherAttach(otherAttachLocIndex: any) {
    let attachmentLocArray = this.preAjaForm.controls.attachmentsLoc as UntypedFormArray;
    if (attachmentLocArray.controls.length > 0) {
      for (let index = 0; index < attachmentLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = attachmentLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttach(otherAttachLocIndex: any) {
    let attachmentLocArray = this.preAjaForm.controls.attachmentsLoc as UntypedFormArray;
    if (attachmentLocArray.controls.length > 0) {
      for (let index = 0; index < attachmentLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = attachmentLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  get formGrp() { return this.preAjaForm.controls; }
  p1validation() {
    if (this.preAjaForm.value['noticeLoc'] == null || this.preAjaForm.value['noticeLoc'] === '') {
      this.noticeLocReq = true;
    } else {
      this.noticeLocReq = false;
    }
    return !this.noticeLocReq;
  }
  p2validation() {
    var appealDetailsArr = this.preAjaForm.controls.submissions as UntypedFormArray;
    if (appealDetailsArr.controls.length > 0) {
      for (var i = 0; i < appealDetailsArr.controls.length; i++) {
        var issues1Control = appealDetailsArr.controls[i] as UntypedFormGroup;
        if (issues1Control.controls.refNo.value === null || issues1Control.controls.refNo.value === '') {
          issues1Control.controls.refNo.setErrors({ 'required': true });
        } else {
          issues1Control.controls.refNo.setErrors(null);
        }
        if (issues1Control.controls.submissionDate.value === null || issues1Control.controls.submissionDate.value === '') {
          issues1Control.controls.submissionDate.setErrors({ 'required': true });
        } else {
          issues1Control.controls.submissionDate.setErrors(null);
        }
      }
    }
    return true;
  }
  p3validation() {
    var issues1Array = this.preAjaForm.controls.issues as UntypedFormArray
    if (issues1Array.controls.length > 0) {
      for (var i = 0; i < issues1Array.controls.length; i++) {
        var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        if (issues1Control.controls.status.value === null || issues1Control.controls.status.value === '' || issues1Control.controls.status.value === "null") {
          issues1Control.controls.status.setErrors({ 'required': true });
        } else {
          issues1Control.controls.status.setErrors(null);
        }
      }
    }

    if (this.preAjaForm.value['oioNo'] === null || this.preAjaForm.value['oioNo'] === '') {
      this.preAjaForm.controls['oioNo'].setErrors({ 'required': true });
    }
    if (this.preAjaForm.value['dateOfOio'] === null) {
      this.preAjaForm.controls['dateOfOio'].setErrors({ 'required': true });
    }
    if (this.preAjaForm.value['dateOfReceiptOfOio'] === null) {
      this.preAjaForm.controls['dateOfReceiptOfOio'].setErrors({ 'required': true });
    }
    if (this.preAjaForm.value['outcome'] === null) {
      this.preAjaForm.controls['outcome'].setErrors({ 'required': true });
    }
    if (this.preAjaForm.value['orderPassedBy'] === null) {
      this.preAjaForm.controls['orderPassedBy'].setErrors({ 'required': true });
    }
    if (this.preAjaForm.value['forumOfAppeal'] === null || this.preAjaForm.value['forumOfAppeal'] === "") {
      this.preAjaForm.controls['forumOfAppeal'].setErrors({ 'required': true });
    }
    return true;
  }
 

  liabilityValidation() {
    let liabilityArray = this.preAjaForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.challanNo.value != null && issuesControl.controls.challanNo.value) {
          if (issuesControl.controls.copyOfFormLoc.value === null || issuesControl.controls.copyOfFormLoc.value === '') {
            issuesControl.controls.copyOfFormLoc.setErrors({ 'required': true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }

  issuInvArr(): UntypedFormGroup[] {
    return (this.preAjaForm.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  newIssueInvArray(): UntypedFormGroup {
    return this.fBuild.group({
      issue: [null, [Validators.required]],
      div: [null],
      keyword: [null],
      riskParam: [null, [Validators.required]],
      remark1: [null, [Validators.maxLength(100)]],
      exciseDuty1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.exciseDuty1, [Validators.pattern(/^-?[0-9,]+$/)]],
      ec1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.ec1, [Validators.pattern(/^-?[0-9,]+$/)]],
      shec1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.shec1, [Validators.pattern(/^-?[0-9,]+$/)]],
      serviceTax1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.serviceTax1, [Validators.pattern(/^-?[0-9,]+$/)]],
      kkc1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.kkc1, [Validators.pattern(/^-?[0-9,]+$/)]],
      sbc1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.sbc1, [Validators.pattern(/^-?[0-9,]+$/)]],
      vat1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.vat1, [Validators.pattern(/^-?[0-9,]+$/)]],
      cst1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.cst1, [Validators.pattern(/^-?[0-9,]+$/)]],
      bcd1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.bcd1, [Validators.pattern(/^-?[0-9,]+$/)]],
      sad1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.sad1, [Validators.pattern(/^-?[0-9,]+$/)]],
      antiDumpingDuty1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.antiDumpingDuty1, [Validators.pattern(/^-?[0-9,]+$/)]],
      igst1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.igst1, [Validators.pattern(/^-?[0-9,]+$/)]],
      ent1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.ent1, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.interest1, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.penalty1, [Validators.pattern(/^-?[0-9,]+$/)]],
      total1: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.total1, [Validators.pattern(/^-?[0-9,]+$/)]],
      exciseDuty3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.exciseDuty3, [Validators.pattern(/^-?[0-9,]+$/)]],
      ec3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.ec3, [Validators.pattern(/^-?[0-9,]+$/)]],
      shec3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.shec3, [Validators.pattern(/^-?[0-9,]+$/)]],
      serviceTax3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.serviceTax3, [Validators.pattern(/^-?[0-9,]+$/)]],
      kkc3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.kkc3, [Validators.pattern(/^-?[0-9,]+$/)]],
      sbc3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.sbc3, [Validators.pattern(/^-?[0-9,]+$/)]],
      vat3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.vat3, [Validators.pattern(/^-?[0-9,]+$/)]],
      cst3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.cst3, [Validators.pattern(/^-?[0-9,]+$/)]],
      bcd3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.bcd3, [Validators.pattern(/^-?[0-9,]+$/)]],
      sad3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.sad3, [Validators.pattern(/^-?[0-9,]+$/)]],
      antiDumpingDuty3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.antiDumpingDuty3, [Validators.pattern(/^-?[0-9,]+$/)]],
      igst3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.igst3, [Validators.pattern(/^-?[0-9,]+$/)]],
      ent3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.ent3, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.interest3, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.penalty3, [Validators.pattern(/^-?[0-9,]+$/)]],
      total3: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.total3, [Validators.pattern(/^-?[0-9,]+$/)]],
      status: [null],
      details: new UntypedFormArray([]),

    })
  }
  addIssueInv() {
    let issueFrmGrp = this.newIssueInvArray();
    (issueFrmGrp.get('details') as UntypedFormArray).push(this.newDetailsFrmGrp());
    (this.preAjaForm.get('issues') as UntypedFormArray).push(issueFrmGrp);
  }
  rmIssueInv(i: number, isRemandedBack: boolean = false) {
    (this.preAjaForm.get('issues') as UntypedFormArray).removeAt(i);
    if (!isRemandedBack) this.rmvIssueModalRef.close();
    this.issueValueChange();
  }

  getDetailOfBankGuaranteeArr(): UntypedFormGroup[] {
    return (this.preAjaForm.get('bankGuarantee') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  detailOfBankGuarantee(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      bgNo: [data ? data?.bgNo : null,[Validators.maxLength(100)]],
      issueDate: [data ? data?.issueDate : null],
      expiryDate: [data ? data?.expiryDate : null],
      bgAmount: [data ? data?.bgAmount : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      fee: [data ? data?.fee : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      bgStatus: [data ? data?.bgStatus  : null],
      remark:[data ? data?.remark  : null, [Validators.maxLength(100)]],
    })
  }

  addDetailOfBankGuarantee() {
    let bankGuaranteeDetailsFrmGrp = this.detailOfBankGuarantee();
    (this.preAjaForm.get('bankGuarantee') as UntypedFormArray).push(bankGuaranteeDetailsFrmGrp);
  }

  rmBankGuarantee(i: number) {
    (this.preAjaForm.get('bankGuarantee') as UntypedFormArray).removeAt(i);
    this.rmvbankGuaranteeRef.close();
  }



  detailOfprotestDetails(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      challanNo: [data ? data?.challanNo : null],
      modeOfPayment: [data ? data?.modeOfPayment : null, ],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      supportingDocLoc: new UntypedFormArray([])
    })
  }

  addDetailOfPayment() {
    let depositDetailsFrmGrp = this.detailOfprotestDetails();
    (this.preAjaForm.get('protestDetails') as UntypedFormArray).push(depositDetailsFrmGrp);
  }

  getDetailOfPaymentArr(): UntypedFormGroup[] {
    return (this.preAjaForm.get('protestDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  rmPaymentUnderProtest(i: number) {
    (this.preAjaForm.get('protestDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfPaymentRef.close();
    this.paymentUnderProtestp1Changed();
  }

  uploadProtestDetailsCopyOfFormLoc(i: any) {
    let protestDetailsArray = this.preAjaForm.controls.protestDetails as UntypedFormArray
    if (protestDetailsArray.controls.length > 0) {
      for (let index = 0; index < protestDetailsArray.controls.length; index++) {
        if (index === i) {
          let protestDetailsControl = protestDetailsArray.controls[index] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.isSupportingDocFile2 = true;
    this.isSupportingDocText2 = false;
    this.isSupportingDocDWText2 = true;
    this.isSupportingDocUPText2 = false;
  }
  deleteUploadFile(i: any) {
    let protestArray = this.preAjaForm.controls.protestDetails as UntypedFormArray
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.supportingDocLoc.setValue(null);
          issuesControl.value['isSupportingDocLocDocUploadedClicked'] = true;
        }
      }
    }
  }


  detailsOfLiabilityAddmittedAndPaid(data: any = null) {
    return this.fBuild.group({
      // modeOfPayment: [null],
      // challanNo: [null],
      // tax: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.tax, [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)]],
      // interest: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.interest, [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)]],
      // penalty: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.penalty, [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)]],
      // total: [(this.action == 'create' || this.action == 'update') ? '0' : this.caseData?.total, [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)]],

      challanNo: [data ? data?.challanNo : null],
      modeOfPayment: [data ? data?.modeOfPayment : null],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      copyOfFormLoc: new UntypedFormArray([])
    })
  }

  addDetailsOfLiabilityAddmittedAndPaid() {
    let liabilityAddmittedAndPaidFrmGrp = this.detailsOfLiabilityAddmittedAndPaid();
    (this.preAjaForm.get('liabilityDetails') as UntypedFormArray).push(liabilityAddmittedAndPaidFrmGrp);

  }

  detailsOfLiabilityAddmittedAndPaidArr(): UntypedFormGroup[] {
    return (this.preAjaForm.get('liabilityDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  rmLiabilityAddmittedAndPaidInv(i: number) {
    (this.preAjaForm.get('liabilityDetails') as UntypedFormArray).removeAt(i);
    this.rmvLiabilityAddmittedAndPaidModalRef.close();
    this.liabilityAddmittedAndPaidChanged();
  }

  openLiabilityAddmittedAndPaidPop(content: any) {
    this.rmvLiabilityAddmittedAndPaidModalRef = this.modalService.open(content);
  }

  //  Details of Liability Admitted and Paid
  uploadcopyOfForm(i: any) {
    var liabilityDetailsArray = this.preAjaForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityDetailsArray.controls.length > 0) {
      for (var index = 0; index < liabilityDetailsArray.controls.length; index++) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.isSupportingDocFile = true;
    this.isSupportingDocText = false;
    this.isSupportingDocDWText = true;
    this.isSupportingDocUPText = false;
  }
  deleteliabilityDetailsFile(i: any) {
    let liabilityArray = this.preAjaForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  liabilityAddmittedAndPaidChanged() {
    let taxTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let liabilityPaidTotal = 0;

    (this.preAjaForm.get('liabilityDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const tax = typeof c.value.tax === 'string' ? c.value.tax.replace(/,/g, '') : c.value.tax;
      const interest = typeof c.value.interest === 'string' ? c.value.interest.replace(/,/g, '') : c.value.interest;
      const penalty = typeof c.value.penalty === 'string' ? c.value.penalty.replace(/,/g, '') : c.value.penalty;
      taxTotal += Number(tax);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(tax)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = typeof c.value.total === 'string' ? c.value.total.replace(/,/g, '') : c.value.total;
      liabilityPaidTotal += Number(total);
    })
    // this.setCummulativeCount();
    this.liabilityAddmittedAndPaidInterestTotal = interestTotal;
    this.liabilityAddmittedAndPaidTaxTotal = taxTotal;
    this.liabilityAddmittedAndPaidPenaltyTotal = penaltyTotal;
    this.liabilityAddmittedAndPaidTotal = liabilityPaidTotal;
  }
  // Details of submissions
  detailOfSubmissionsDetails(submission: any = null): UntypedFormGroup {
    return this.fBuild.group({
      refNo: [submission ? submission.refNo : null],
      submissionMode: [submission ? submission.submissionMode : null],
      submissionDate: [submission ? submission.submissionDate : null],
      copyOfAckLoc: new UntypedFormArray([]),
      otherAttachmentsLoc: new UntypedFormArray([]),
    })

  }

  getSubmissionDetailsArr(): UntypedFormGroup[] {
    return (this.preAjaForm.get('submissions') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  addSubmissionDetailsArr() {
    let issueFrmGrp = this.detailOfSubmissionsDetails();
    (this.preAjaForm.get('submissions') as UntypedFormArray).push(issueFrmGrp);
  }

  rmSubmissionDetailProtest(i: number) {
    (this.preAjaForm.get('submissions') as UntypedFormArray).removeAt(i);
    this.rmvSubmissionDetailsRef.close();
  }

  uploadCopyOfAck(i: any) {
    var submissionArray = this.preAjaForm.controls.submissions as UntypedFormArray
    if (submissionArray.controls.length > 0) {
      for (var index = 0; index < submissionArray.controls.length; index++) {
        if (index === i) {
          var submissionControl = submissionArray.controls[index] as UntypedFormGroup;
          submissionControl.value['isCopyOfAckLocDocUploadedClicked'] = true
        }
      }
    }
    this.isCopyOfAckFile = true;
    this.isCopyOfAckText = false;
    this.isCopyOfAckDWText = true;
    this.isCopyOfAckUPText = false;
  }

  deleteSubmissionsUploadFile(i: any) {
    let submissionArray = this.preAjaForm.controls.submissions as UntypedFormArray
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfAckLoc.setValue(null);
          issuesControl.value['iscopyOfAckLocUploadedClicked'] = true;
        }
      }
    }
  }

  uploadotherAttachments(i: any) {
    var submissionArray = this.preAjaForm.controls.submissions as UntypedFormArray
    if (submissionArray.controls.length > 0) {
      for (var index = 0; index < submissionArray.controls.length; index++) {
        if (index === i) {
          var submissionControl = submissionArray.controls[index] as UntypedFormGroup;
          submissionControl.value['isOtherAttachmentsLocDocUploadedClicked'] = true
        }
      }
    }
    this.isOtherAttachmentFile = true;
    this.isOtherAttachmentText = false;
    this.isOtherAttachmentDWText = true;
    this.isOtherAttachmentUPText = false;
  }
  deleteSubmissions2UploadFile(i: any) {
    let submissionArray = this.preAjaForm.controls.submissions as UntypedFormArray
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentsLoc.setValue(null);
          issuesControl.value['isOtherAttachmentsLocUploadedClicked'] = true;
        }
      }
    }
  }
  //issueArray
  // details array
  getdetails(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newDetailsFrmGrp() {
    return new UntypedFormGroup({
      finYear: new UntypedFormControl(null),
      quarter: new UntypedFormControl(null),
      provisionAmt1: new UntypedFormControl(null),
      contLiabilityAmt1: new UntypedFormControl(null),
      otherLiability1: new UntypedFormControl(null),
      provisionChangeReason1: new UntypedFormControl(null),
      provisionNotMadeReason1: new UntypedFormControl(null),
      contLiabReason1: new UntypedFormControl(null),
      otherLiabReason1: new UntypedFormControl(null),
      considerInCaroReport1: new UntypedFormControl(null),
      caroAmt1: new UntypedFormControl(0),

    })
  }
  addDetail(j: any) {
    let issuesFrmArray = this.preAjaForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[j].get('details') as UntypedFormArray;
    detailsFrmArray.push(this.newDetailsFrmGrp());
  }
  rmDetail(i: number, k: number) {
    let issuesFrmArray = this.preAjaForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    detailsFrmArray.removeAt(k);
    this.rmvDetailModalRef.close();

  }
  // details array
  //PH div array
  phDivArr(): UntypedFormArray {
    return this.preAjaForm.get("itemList") as UntypedFormArray
  }

  newphArray(): UntypedFormGroup {
    return this.fBuild.group({
      phNoticeDate: [null, []],
      dateOfPhScheduled: [null, []],
      phCopyLoc: new UntypedFormArray([]),
      adjournedOrNot: [false, []],
      attended: [null, []],
      consultant: [this.caseData?.consultant1, []],
      // personResponsible: [this.caseData?.personResponsible1, []],
      dateOfsubmission: [null, []],
      additionalSubmissionLoc: new UntypedFormArray([]),
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phDiscussionNotes: [null, []],
      phAdjournmentLetterLoc: new UntypedFormArray([]),
      mode: ['', []],
      phRefNo:['', []],
      phTime:['', []],
    })
  }

  createAdjDiv() {
    // this.adjPhTablist.push(this.newphArray());
    this.phDivArr().push(this.newphArray());
  }
  removeLetter(i: number) {
    this.phDivArr().removeAt(i);
  }



  /*ph div array */


  // createAdjDiv() {
  //   this.apaDivArr().push(this.newphArray());
  //   // var list: FormGroup = this.newphArray();
  //   // (list.get('consultantList') as FormArray).push(this.ConsultantCharges());
  //   this.phDivArr().push(list);
  // }

  // removeLetter(i: number) {
  //   this.phDivArr().removeAt(i);
  // }
  //PH div array

  tabChanged(tab: any, check?: any) {
    this.preAjaForm.patchValue(this.preAjaForm.value);
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.initializeData();
    } else if (tab === 'tab2') {

    } else if (tab === 'tab3') {
      this.setIssuingAuthorityData();
    }

  }
  phaseChanged(phase: any, i: any) {
    this.selectedPhase = phase;
  }
  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2011;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push({ year: financialYrRange, disabled: false });
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }

  personResponsibleData() {
    let model = {
      companyid: this.selectedCompanyId
    }
    this.preLitService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }

  setStateAutoPop() {
    if (this.selectedFilling.gstin) {
      this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
      this.selectedStateName = this.commonServ.getStateName(this.selectedStateCode);
    }
  }

  changeadjOrNot(event: any, i: any) {
    if (event.target.value === "true") {
      this.createAdjDiv();
    }
  }
  changeState(event: any) {
    this.selectedState = event ? event.target.value : this.preAjaForm.value.state;
    this.fetchRegNo(2);
  }
  changeTax(event: any,) {
    this.selectedActType = event ? event.target.value : this.preAjaForm.value.actType;
    // this.fetchRegNo();

    let model = {
      companyid: this.selectedCompanyId,
      actType: this.selectedActType,
    }
    this.getState(model);

    if (this.selectedActType === "EXCISE") {
      this.isExcise = true;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preAjaForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ serviceTax1: '0', kkc1: '0', sbc1: '0', vat1: '0', cst1: '0', bcd1: '0', ent1: '0',sad1:'0',antiDumpingDuty1:'0',igst1:'0' });
        }
      }
    } else if (this.selectedActType === "SERVICE TAX") {
      this.isExcise = false;
      this.isServiceTax = true;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preAjaForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ exciseDuty1: '0', vat1: '0', cst1: '0', bcd1: '0', ent1: '0' ,sad1:'0',antiDumpingDuty1:'0',igst1:'0'});
        }
      }
    } else if (this.selectedActType === "VAT") {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = true;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preAjaForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ exciseDuty1: '0', ec1: '0', shec1: '0', serviceTax1: '0', kkc1: '0', sbc1: '0', cst1: '0', bcd1: '0', ent1: '0',sad1:'0',antiDumpingDuty1:'0',igst1:'0' });
        }
      }
    } else if (this.selectedActType === "CST") {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = true;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preAjaForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ exciseDuty1: '0', ec1: '0', shec1: '0', serviceTax1: '0', kkc1: '0', sbc1: '0', vat1: '0', bcd1: '0', ent1: '0',sad1:'0',antiDumpingDuty1:'0',igst1:'0' });
        }
      }
    } else if (this.selectedActType === "CUSTOMS DUTY") {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = true;
      this.isEntryTax = false;
      var issues1Array = this.preAjaForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ exciseDuty1: '0', ec1: '0', shec1: '0', serviceTax1: '0', kkc1: '0', sbc1: '0', vat1: '0', cst1: '0' });
        }
      }
    } else if (this.selectedActType === "ENTRY TAX") {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = true;
      var issues1Array = this.preAjaForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ exciseDuty1: '0', ec1: '0', shec1: '0', serviceTax1: '0', kkc1: '0', sbc1: '0', vat1: '0', cst1: '0' });
        }
      }
    }
  }
  actTypeFetch() {
    // let model = {
    //   companyId: localStorage.getItem('selectedId'),
    //   userMailId: this.selectedUserId
    // }
    // this.preLitService.fetchWatchListFormData(model).subscribe((response: any) => {
    //   if (response.status === 1) {
    //     this.isActdisable = true;
    //     this.actTypes = response.response;
    //     this.toaster.showSuccess(response.message);
    //   }
    //   else {
    //     this.toaster.showError(response.message);
    //   }
    // });
    this.isActdisable = false;
    this.actTypes = this.typeOfAct

  }



  getState(model: any) {
    if (this.caseData) {
      model.pan = this.caseData.pan ? this.caseData.pan : this.pan;
    } else {
      model.pan = this.pan;
    }
    this.preLitService.getActwiseState(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.actStateList = response.response;
        this.toaster.showSuccess(response.message);
      }
      else {
        this.toaster.showError(response.message);
      }
    });
  }
  openRmvIssuePop(content: any, i: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }

  openRemoveDetailsOfPaymentPop(content: any) {
    this.rmvDetailsOfPaymentRef = this.modalService.open(content);

  }
  openRemoveBankGuaranteePop(content: any) {
    this.rmvbankGuaranteeRef = this.modalService.open(content);

  }

  openRemoveSubmissionDetailsPopup(content: any) {
    this.rmvSubmissionDetailsRef = this.modalService.open(content);

  }

  openProceedWithPreferredForumAppealPop(content: any) {
    this.proceedWithPreferredForumAppeal = this.modalService.open(content);
  }
  rmProceedWithPreferredForumAppeal(removeSelectedValue: any = false) {
    if (removeSelectedValue) this.preAjaForm.get('forumOfAppeal')?.setValue('');
    this.proceedWithPreferredForumAppeal.close();
  }

  getForumAppealValue(value: any) {
    let [findValue] = this.forumAppeal.filter(forum => {
      return forum.key === value
    })
    if (findValue) return findValue.value
    return;
  }
  openDetailPop(content: any, i: any) {
    this.modalService.open(content);
    this.finYearSelelctEvent(i);
    this.quarterSelectEvent(i);
  }
  openRmvDetailPop(content: any, i: any, k: any) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }
  //save case
  save(phaseTab: any) {
    if (this.caseType !== 'REMANDED') {
      this.preAjaForm.get('remandedBackForum')?.clearValidators();
      this.preAjaForm.get('remandedBackForum')?.updateValueAndValidity();

      this.preAjaForm.get('remandedCaseId')?.clearValidators();
      this.preAjaForm.get('remandedCaseId')?.updateValueAndValidity();
    }
    this.isSubmitted = true;
    let data: any = {};
    if (this.preAjaForm.valid) {
      data = this.preAjaForm.value;
      data.issuingAuth1 = {
        officer: this.preAjaForm.value.officer,
        designation: this.preAjaForm.value.designation,
        jurisdiction: this.preAjaForm.value.jurisdiction,
        address: this.preAjaForm.value.address,
        contact: this.preAjaForm.value.contact,
        email: this.preAjaForm.value.email
      }
      data.issuingAuth3 = {
        officer: this.preAjaForm.value.officer3,
        designation: this.preAjaForm.value.designation3,
        jurisdiction: this.preAjaForm.value.jurisdiction3,
        address: this.preAjaForm.value.address3,
        contact: this.preAjaForm.value.contact3,
        email: this.preAjaForm.value.email3
      }
      if (phaseTab === 'p1') {
        let dataNoticeLoc = (this.preAjaForm.get('noticeLoc') as UntypedFormArray).controls;
        var count = dataNoticeLoc?.some((x) => x.get('fileLoc')?.value != null);
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
        let protestArray = this.preAjaForm.controls.protestDetails as UntypedFormArray
        for (let index = 0; index < protestArray.controls.length; index++) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
        
          if (issuesControl.controls.challanNo.value) {
            let supportingDocControls = (issuesControl.get('supportingDocLoc') as UntypedFormArray).controls;
            
            let hasSupportingDoc = supportingDocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
            
            if (!hasSupportingDoc) {
              this.toaster.showError("Required Fields Are Empty for Challan No.");
              return;
            }
          }
        }
      }
      //data.state = this.preAjaForm.value.state.toUpperCase();
      let urlData = {
        type: this.tabType[0].key,
        companyId: this.companyId

      }
      data.caseType = this.caseType;
      data.cumulative = this.cumulativeCount;
      this.calculateRemandBackTotal();
      data.remandedPaymentMade = this.preAjaForm.value.remandedPaymentMade;

      if (data.protestDetails && data.protestDetails.length) {
        data.protestDetails.forEach((protestDetail: any) => {
          protestDetail.tax = this.inrService.removeCommasAndParseNumber(protestDetail.tax)
          protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
          protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
          protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
        })
      }
      if (data.consultantCharges && data.consultantCharges.length) {
        data.consultantCharges.forEach((charge: any) => {
          charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
        })
      }
      if (data.bankGuarantee && data.bankGuarantee.length) {
        data.bankGuarantee.forEach((bank: any) => {
          bank.bgAmount = this.inrService.removeCommasAndParseNumber(bank.bgAmount)
          bank.fee = this.inrService.removeCommasAndParseNumber(bank.fee)
        })
      }


      if (data.liabilityDetails && data.liabilityDetails.length) {
        data.liabilityDetails.forEach((liability: any) => {
          liability.tax = this.inrService.removeCommasAndParseNumber(liability.tax)
          liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
          liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
          liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
        })
      }

      if (data.issues && data.issues.length) {
        data.issues.forEach((issue: any) => {
          issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty1)
          issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(issue.serviceTax1)
          issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1)
          issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1)
          issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1)
          issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1)
          issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1)
          issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1)
          issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1)
          issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1)
          issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1)
          issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
          issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1)
          issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
          issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
          issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)

          issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty3)
          issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(issue.serviceTax3)
          issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3)
          issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3)
          issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3)
          issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3)
          issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3)
          issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3)
          issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3)
          issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3)
          issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3)
          issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
          issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3)
          issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
          issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
          issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)
        })
      }

      data.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty1Total)
      data.serviceTax1Total = this.inrService.removeCommasAndParseNumber(data.serviceTax1Total)
      data.ec1Total = this.inrService.removeCommasAndParseNumber(data.ec1Total)
      data.shec1Total = this.inrService.removeCommasAndParseNumber(data.shec1Total)
      data.kkc1Total = this.inrService.removeCommasAndParseNumber(data.kkc1Total)
      data.vat1Total = this.inrService.removeCommasAndParseNumber(data.vat1Total)
      data.cst1Total = this.inrService.removeCommasAndParseNumber(data.cst1Total)
      data.sbc1Total = this.inrService.removeCommasAndParseNumber(data.sbc1Total)
      data.bcd1Total = this.inrService.removeCommasAndParseNumber(data.bcd1Total)
      data.sad1Total = this.inrService.removeCommasAndParseNumber(data.sad1Total)
      data.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty1Total)
      data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)
      data.ent1Total = this.inrService.removeCommasAndParseNumber(data.ent1Total)
      data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total)
      data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total)
      data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1)

      data.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty3Total)
      data.serviceTax3Total = this.inrService.removeCommasAndParseNumber(data.serviceTax3Total)
      data.ec3Total = this.inrService.removeCommasAndParseNumber(data.ec3Total)
      data.shec3Total = this.inrService.removeCommasAndParseNumber(data.shec3Total)
      data.kkc3Total = this.inrService.removeCommasAndParseNumber(data.kkc3Total)
      data.vat3Total = this.inrService.removeCommasAndParseNumber(data.vat3Total)
      data.cst3Total = this.inrService.removeCommasAndParseNumber(data.cst3Total)
      data.sbc3Total = this.inrService.removeCommasAndParseNumber(data.sbc3Total)
      data.bcd3Total = this.inrService.removeCommasAndParseNumber(data.bcd3Total)
      data.sad3Total = this.inrService.removeCommasAndParseNumber(data.sad3Total)
      data.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty3Total)
      data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total)
      data.ent3Total = this.inrService.removeCommasAndParseNumber(data.ent3Total)
      data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total)
      data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total)
      data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3)
      Object.assign(
        data,
        { action: 'CREATE' },
        { phase: '1' },
        { keyword: 'keyword1' }
      );
      let noticeLocArray: any[] = [];
      let otherAttachLocArray: any[] = [];
      let protestSupportingDocLocArray: any[] = [];
      for (let i = 0; i < this.preAjaForm.controls.noticeLoc.value.length; i++) {
        const element = this.preAjaForm.controls.noticeLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          noticeLocArray.push(element)
        }

      }
      for (let i = 0; i < this.preAjaForm.controls.attachmentsLoc.value.length; i++) {
        const element = this.preAjaForm.controls.attachmentsLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachLocArray.push(element)
        }

      }
      for (let i = 0; i < this.preAjaForm.controls.protestDetails.value.length; i++) {
        const copyFormArray = this.preAjaForm.controls.protestDetails.value[i].supportingDocLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestSupportingDocLocArray.push(element)
          }
        }
        this.preAjaForm.controls.protestDetails.value[i].supportingDocLoc = protestSupportingDocLocArray;
      }
      this.preAjaForm.value.noticeLoc = noticeLocArray;
      this.preAjaForm.value.attachmentsLoc = otherAttachLocArray;
      this.inrFormattingRemoving();
      // this.letterP1Form.value.dateOfLetter = this.datePipe.transform(this.letterP1Form.value.dateOfLetter, 'yyyy-MM-dd');
      this.preLitService.saveAjaData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          this.toaster.showSuccess(response.message);
          this.savedPreCaseId = response.response;
          this.isDataSaved = true;
          this.isPhase2 = true;
          this.isCreateBtn = false;
          this.isUpdateBtn = true;
          this.saveCaseId.emit(this.savedPreCaseId);
          this.initializeData();
        } else {
          this.toaster.showSuccess(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }

  }


  //save case
  //fetch case
  initializeData() {
    if (this.currentTab != this.activeTab) {
      this.enablePrevStageTab.emit(this.activeStages);
    }
    let data: any = {};
    if (this.savedPreCaseId) {
      this.caseId = this.savedPreCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        caseId: this.caseId,
      }
      this.preLitService.getAllCaseSummaryAja(model).subscribe((response: any) => {
        if (response.status === 1) {
          if (this.currentTab === this.activeTab) {
            this.enablePrevStageTab.emit(response.response?.prevStages);
          }
          this.caseData = response.response;
          this.resStateName = response.response.state;
          this.caseType = this.caseData.caseType;

          if (this.caseData.status === "MOVED") {
            this.callBookDisabled = true;
          }

          if (this.caseData?.protestDetails) {
            this.caseData.protestDetails.forEach((protest: any) => {
              protest.total = this.inrService.formatCurrency(String(protest.total))
              protest.tax = this.inrService.formatCurrency(String(protest.tax))
              protest.interest = this.inrService.formatCurrency(String(protest.interest))
              protest.penalty = this.inrService.formatCurrency(String(protest.penalty))
            })
          }

          if (this.caseData?.consultantCharges) {
            this.caseData.consultantCharges.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees))
            })
          }
          if (this.caseData?.bankGuarantee) {
            this.caseData.bankGuarantee.forEach((bank: any) => {
              bank.bgAmount = this.inrService.formatCurrency(String(bank.bgAmount))
              bank.fee = this.inrService.formatCurrency(String(bank.fee))
            })
          }


          if (this.caseData?.liabilityDetails) {
            this.caseData.liabilityDetails.forEach((liability: any) => {
              liability.total = this.inrService.formatCurrency(String(liability.total))
              liability.tax = this.inrService.formatCurrency(String(liability.tax))
              liability.interest = this.inrService.formatCurrency(String(liability.interest))
              liability.penalty = this.inrService.formatCurrency(String(liability.penalty))
            })
          }

          if (this.caseData?.issues) {
            this.caseData.issues.forEach((issue: any) => {
              issue.exciseDuty1 = this.inrService.formatCurrency(String(issue.exciseDuty1))
              issue.serviceTax1 = this.inrService.formatCurrency(String(issue.serviceTax1))
              issue.ec1 = this.inrService.formatCurrency(String(issue.ec1))
              issue.shec1 = this.inrService.formatCurrency(String(issue.shec1))
              issue.kkc1 = this.inrService.formatCurrency(String(issue.kkc1))
              issue.vat1 = this.inrService.formatCurrency(String(issue.vat1))
              issue.cst1 = this.inrService.formatCurrency(String(issue.cst1))
              issue.sbc1 = this.inrService.formatCurrency(String(issue.sbc1))
              issue.bcd1 = this.inrService.formatCurrency(String(issue.bcd1))
              issue.sad1 = this.inrService.formatCurrency(String(issue.sad1))
              issue.antiDumpingDuty1 = this.inrService.formatCurrency(String(issue.antiDumpingDuty1))
              issue.igst1 = this.inrService.formatCurrency(String(issue.igst1))
              issue.ent1 = this.inrService.formatCurrency(String(issue.ent1))
              issue.interest1 = this.inrService.formatCurrency(String(issue.interest1))
              issue.penalty1 = this.inrService.formatCurrency(String(issue.penalty1))
              issue.total1 = this.inrService.formatCurrency(String(issue.total1))

              issue.exciseDuty3 = this.inrService.formatCurrency(String(issue.exciseDuty3))
              issue.serviceTax3 = this.inrService.formatCurrency(String(issue.serviceTax3))
              issue.ec3 = this.inrService.formatCurrency(String(issue.ec3))
              issue.shec3 = this.inrService.formatCurrency(String(issue.shec3))
              issue.kkc3 = this.inrService.formatCurrency(String(issue.kkc3))
              issue.vat3 = this.inrService.formatCurrency(String(issue.vat3))
              issue.cst3 = this.inrService.formatCurrency(String(issue.cst3))
              issue.sbc3 = this.inrService.formatCurrency(String(issue.sbc3))
              issue.bcd3 = this.inrService.formatCurrency(String(issue.bcd3))
              issue.sad3 = this.inrService.formatCurrency(String(issue.sad3))
              issue.antiDumpingDuty3 = this.inrService.formatCurrency(String(issue.antiDumpingDuty3))
              issue.igst3 = this.inrService.formatCurrency(String(issue.igst3))
              issue.ent3 = this.inrService.formatCurrency(String(issue.ent3))
              issue.interest3 = this.inrService.formatCurrency(String(issue.interest3))
              issue.penalty3 = this.inrService.formatCurrency(String(issue.penalty3))
              issue.total3 = this.inrService.formatCurrency(String(issue.total3))

              if (issue.details && issue.details.length) {
                issue.details.forEach((details: any) => {
                  details.provisionAmt1 = this.inrService.formatCurrency(String(details.provisionAmt1))
                  details.contLiabilityAmt1 = this.inrService.formatCurrency(String(details.contLiabilityAmt1))
                  details.otherLiability1 = this.inrService.formatCurrency(String(details.otherLiability1))
                  details.caroAmt1 = this.inrService.formatCurrency(String(details.caroAmt1))
                })
              }
            })
          }

          let model = {
            companyid: this.selectedCompanyId,
            actType: this.caseData.actType,
          }
          this.getState(model);
          this.fetchRegNo(1);
          if (this.caseData.actType === "EXCISE") {
            this.isExcise = true;
            this.isServiceTax = false;
            this.isVat = false;
            this.isCst = false;
            this.isEntryTax = false;
            this.isCustomsDuty = false;
          } else if (this.caseData.actType === "SERVICE TAX") {
            this.isExcise = false;
            this.isServiceTax = true;
            this.isVat = false;
            this.isCst = false;
            this.isEntryTax = false;
            this.isCustomsDuty = false;
          } else if (this.caseData.actType === "VAT") {
            this.isExcise = false;
            this.isServiceTax = false;
            this.isVat = true;
            this.isCst = false;
            this.isEntryTax = false;
            this.isCustomsDuty = false;
          } else if (this.caseData.actType === "CST") {
            this.isExcise = false;
            this.isServiceTax = false;
            this.isVat = false;
            this.isCst = true;
            this.isEntryTax = false;
            this.isCustomsDuty = false;
          } else if (this.caseData.actType === "CUSTOMS DUTY") {
            this.isExcise = false;
            this.isServiceTax = false;
            this.isVat = false;
            this.isCst = false;
            this.isEntryTax = false;
            this.isCustomsDuty = true;
          } else if (this.caseData.actType === "ENTRY TAX") {
            this.isExcise = false;
            this.isServiceTax = false;
            this.isVat = false;
            this.isCst = false;
            this.isCustomsDuty = false;
            this.isEntryTax = true;
          }
          this.formInitialize();
          this.whetherCallBookChanged();
          this.remandedPaymentMade = this.caseData.remandedPaymentMade;
          // this.whetherWritApplicationHC();
          if (this.caseData.itemList && this.caseData.itemList.length > 0) {
            this.initializeItemList();
          } else {
            this.initializeItemList();
            var list: UntypedFormGroup = this.newphArray();
            this.phDivArr().push(list);
          }

          if (this.caseData.consultantCharges && this.caseData.consultantCharges.length > 0) {
            this.initializeConsultantCharges();
          } else {
            this.addConsultantInv();
          }
          if (this.caseData.issues && this.caseData.issues.length > 0) {
            this.initializeIssueList(this.caseData);
          } else {
            this.addIssueInv();
          }

          if (this.caseData.liabilityDetails && this.caseData.liabilityDetails.length > 0) {
            this.initializeDetailOfLiability();
          } else {
            this.addDetailsOfLiabilityAddmittedAndPaid();
          }

          if (this.caseData.protestDetails && this.caseData.protestDetails.length > 0) {
            this.initializeProtestDetails();
          } else {
            this.addDetailOfPayment();
          }

          
          if (this.caseData.bankGuarantee && this.caseData.bankGuarantee.length > 0) {
            this.initializeBankGuarantee();
          } else {
            this.addDetailOfBankGuarantee();
          }

          if (this.caseData.submissions && this.caseData.submissions.length > 0) {
            this.initializeDetailOfSubmission();
          } else {
            this.addSubmissionDetailsArr();
          }
          if (this.caseData.writAppPreferred == true) {
            this.disabledWeatherAppl = true;
            this.disabledWeatherDept = true;
          } else {
            this.disabledWeatherAppl = false;
            this.disabledWeatherDept = false;
          }
          if (this.caseData.remandedCaseId) {
            if (this.selectedRemandedBackCaseDetail) {
              this.selectedRemandedBackCaseDetail.cumulative = this.caseData.cumulative;
            } else {
              this.selectedRemandedBackCaseDetail = { cumulative: this.caseData.cumulative };
            }
            this.initializeRemandedBackPaymentData(this.caseData.remandedPaymentMade ? this.caseData.remandedPaymentMade : this.caseData.cumulative);
          }

          if (this.caseData.noticeLoc && this.caseData.noticeLoc > 0) {
            this.initializeNoticeLocCopy();
          } else {
            this.addNoticeLoc();
          }
          if (this.caseData.attachmentsLoc && this.caseData.attachmentsLoc > 0) {
            this.initializeOtherAttachmentsCopy();
            // this.initializeScnCopy();
          } else {
            this.addOtherAttachment();
          }
          if (this.caseData.oioLoc && this.caseData.oioLoc > 0) {
            this.initializeOioLocCopy();
          } else {
            this.addOioLoc();
          }
          this.cumulativeCount = this.caseData.cumulative ? this.caseData.cumulative : this.cumulativeCount
          this.prevStage = this.caseData.prevStage;

          this.isUpdate = true;
          this.paymentUnderProtestp1Changed();
          this.liabilityAddmittedAndPaidChanged();
          this.feesChargedChanged();
          this.setCummulativeCount();
          this.issueValueChange();
        }
        this.changeOutcome();
      });
    }
  }
  //fetch case
  //initialise ph div array

  initializeNoticeLocCopy() {
    if (this.caseData.noticeLoc) {

      this.caseData.noticeLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createNoticeLoc(not);

        (this.preAjaForm.get('noticeLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.preAjaForm.patchValue(this.caseData);
    }
  }
  initializeOtherAttachmentsCopy() {
    if (this.caseData.attachmentsLoc) {
      this.caseData.attachmentsLoc.forEach((not: any) => {

        let otherAttachCopyFrmGrp = this.createOtherAttachment(not);

        (this.preAjaForm.get('attachmentsLoc') as UntypedFormArray).push(otherAttachCopyFrmGrp);

      })
    }
    this.preAjaForm.patchValue(this.caseData);
  }
  initializeOioLocCopy() {
    if (this.caseData.oioLoc) {
      this.caseData.oioLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.creatOioLoc(not);

        (this.preAjaForm.get('oioLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
    }
    this.preAjaForm.patchValue(this.caseData);
  }
  initializeItemList() {
    if (this.caseData.itemList) {
      this.caseData.itemList.forEach((items: any) => {
        let itemListFrmGrp = this.newphArray();
        (this.preAjaForm.get('itemList') as UntypedFormArray).push(itemListFrmGrp);

        if (items.phCopyLoc) {
          items.phCopyLoc.forEach((not: any) => {
            let phcopyFormGrp: UntypedFormGroup = this.createphCopyLoc(not);
            (itemListFrmGrp.get('phCopyLoc') as UntypedFormArray).push(phcopyFormGrp);
          });
        }

        if (items.phRecordIfAnyLoc) {
          items.phRecordIfAnyLoc.forEach((not: any) => {
            let phRecordAnyGrp: UntypedFormGroup = this.createPhRecord(not);
            (itemListFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).push(phRecordAnyGrp);
          });
        }
        if (items.phAdjournmentLetterLoc) {
          items.phAdjournmentLetterLoc.forEach((not: any) => {
            let phAdjournmentLetterLocGrp: UntypedFormGroup = this.createPhAdjournmentLetterLoc(not);
            (itemListFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).push(phAdjournmentLetterLocGrp);
          }); 
        }
        if (items.additionalSubmissionLoc) {
          items.additionalSubmissionLoc.forEach((not: any) => {
            let additionalSubmissionFrmGrp: UntypedFormGroup = this.createAdditionalSubLoc(not);
            (itemListFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).push(additionalSubmissionFrmGrp);
          });
        }
      });

      this.preAjaForm.patchValue(this.caseData);
      this.initializeNoticeLocCopy();
      this.initializeOtherAttachmentsCopy();
      this.initializeOioLocCopy();

    }
  }
  //initialise issue array
  initializeIssueList(data: any) {
    if (data.issues) {
      data.issues.forEach((issue: any) => {
        let issuesFrmGrp: UntypedFormGroup = this.newIssueInvArray();
        (this.preAjaForm.get('issues') as UntypedFormArray).push(issuesFrmGrp);
        if (issue.details) {
          issue.details.forEach((detail: any) => {
            let detailFrmGrp: UntypedFormGroup = this.newDetailsFrmGrp();
            (issuesFrmGrp.get('details') as UntypedFormArray).push(detailFrmGrp);
          });
        }

      });
      this.preAjaForm.patchValue(data);
      this.issueValueChange();
    }
  }


  // Consultant Charges

  initializeConsultantCharges() {
    if (this.caseData.consultantCharges) {
      this.caseData.consultantCharges.forEach((consultantCharges: any) => {
        let detailOfConsultant = this.ConsultantCharges(consultantCharges);
        (this.preAjaForm.get('consultantCharges') as UntypedFormArray).push(detailOfConsultant);

        if (consultantCharges.otherAttachmentLoc) {
          consultantCharges.otherAttachmentLoc.forEach((not: any) => {
            let ConsultotherAttachmentFrmGrp: UntypedFormGroup = this.createConsultotherAttachmentLoc(not);
            (detailOfConsultant.get('otherAttachmentLoc') as UntypedFormArray).push(ConsultotherAttachmentFrmGrp);
          });
        }

      })
    }
  }

  //  initialise liabilityDetails array
  initializeDetailOfLiability() {
    if (this.caseData.liabilityDetails) {
      this.caseData.liabilityDetails.forEach((liabilityDetails: any) => {
        let liabilityAddmittedAndPaidFrmGrp = this.detailsOfLiabilityAddmittedAndPaid(liabilityDetails);
        (this.preAjaForm.get('liabilityDetails') as UntypedFormArray).push(liabilityAddmittedAndPaidFrmGrp);

        if (liabilityDetails.copyOfFormLoc) {
          liabilityDetails.copyOfFormLoc.forEach((not: any) => {
            let LiabilitySupportingDocFrmGrp: UntypedFormGroup = this.createLiabilitySupportingDocLoc(not);
            (liabilityAddmittedAndPaidFrmGrp.get('copyOfFormLoc') as UntypedFormArray).push(LiabilitySupportingDocFrmGrp);
          });
        }

      })
    }
  }

  //  initialise Protest array
  initializeProtestDetails() {
    if (this.caseData.protestDetails) {
      this.caseData.protestDetails.forEach((protest: any) => {
        let depositDetailsFrmGrp = this.detailOfprotestDetails(protest);
        (this.preAjaForm.get('protestDetails') as UntypedFormArray).push(depositDetailsFrmGrp);

        if (protest.supportingDocLoc) {
          protest.supportingDocLoc.forEach((not: any) => {
            let protestDetailsSupportingFrmGrp: UntypedFormGroup = this.createProtestSupportingDoc(not);
            (depositDetailsFrmGrp.get('supportingDocLoc') as UntypedFormArray).push(protestDetailsSupportingFrmGrp);
          });
        }

      })
    }
  }

  initializeBankGuarantee() {
    if (this.caseData.bankGuarantee) {
      this.caseData.bankGuarantee.forEach((bank: any) => {
        let bankGuaranteeDetailsFrmGrp = this.detailOfBankGuarantee(bank);
        (this.preAjaForm.get('bankGuarantee') as UntypedFormArray).push(bankGuaranteeDetailsFrmGrp);
      })
    }
  }

  // initialise submissions array
  initializeDetailOfSubmission() {
    if (this.caseData.submissions) {
      this.caseData.submissions.forEach((submissions: any) => {
        let detailOfSubmission = this.detailOfSubmissionsDetails(submissions);
        (this.preAjaForm.get('submissions') as UntypedFormArray).push(detailOfSubmission);

        if (submissions.copyOfAckLoc) {
          submissions.copyOfAckLoc.forEach((not: any) => {
            let submissionsFrmGrp: UntypedFormGroup = this.createCopyOfAckLoc(not);
            (detailOfSubmission.get('copyOfAckLoc') as UntypedFormArray).push(submissionsFrmGrp);
          });
        }

        if (submissions.otherAttachmentsLoc) {
          submissions.otherAttachmentsLoc.forEach((not: any) => {
            let submissionsFrmGrp: UntypedFormGroup = this.createOtherAttachmentsLoc(not);
            (detailOfSubmission.get('otherAttachmentsLoc') as UntypedFormArray).push(submissionsFrmGrp);
          });
        }

      })
    }
  }

  setIssuingAuthorityData() {
    this.preAjaForm.get("officer3")?.setValue(this.preAjaForm.value.officer3 ? this.preAjaForm.value.officer3 : this.preAjaForm.value.officer);
    this.preAjaForm.get("jurisdiction3")?.setValue(this.preAjaForm.value.jurisdiction3 ? this.preAjaForm.value.jurisdiction3 : this.preAjaForm.value.jurisdiction);
    this.preAjaForm.get("contact3")?.setValue(this.preAjaForm.value.contact3 ? this.preAjaForm.value.contact3 : this.preAjaForm.value.contact);
    this.preAjaForm.get("designation3")?.setValue(this.preAjaForm.value.designation3 ? this.preAjaForm.value.designation3 : this.preAjaForm.value.designation);
    this.preAjaForm.get("address3")?.setValue(this.preAjaForm.value.address3 ? this.preAjaForm.value.address3 : this.preAjaForm.value.address);
    this.preAjaForm.get("email3")?.setValue(this.preAjaForm.value.email3 ? this.preAjaForm.value.email3 : this.preAjaForm.value.email);

  }

  UpdateModelData() {
    if (this.caseData != null) {
      this.preAjaForm.value.noticeLoc = this.caseData['noticeLoc'];
      this.preAjaForm.value.attachmentsLoc = this.caseData['attachmentsLoc'];
      this.preAjaForm.value.otherAttachmentsLoc = this.caseData['otherAttachmentsLoc'];
      this.preAjaForm.value.supportingDocLoc = this.caseData['supportingDocLoc']
      this.preAjaForm.value.drc03CopyLoc = this.caseData['drc03CopyLoc'];
      if (this.caseData.oioLoc != null) {
        this.preAjaForm.value.oioLoc = this.caseData['oioLoc'];
      } else {
        this.preAjaForm.value.oioLoc = null;
      }
    }
  }
  doValidation(phaseTab: any) {
    this.UpdateModelData();
    // if (phaseTab === 'p1') {
    //   this.protestValidation();
    // }
    if (phaseTab === 'p2') {
      this.consultantValidation();
      return this.p2validation();
    } else if (phaseTab === 'p3') {
      this.liabilityValidation();
      return this.p3validation();
    } else {
      return true;
    }
  }

  consultantValidation() {
    let consultantArray = this.preAjaForm.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(issuesControl.controls.fees.value)
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (issuesControl.controls.firm.value === null || issuesControl.controls.firm.value === '') {
            issuesControl.controls.firm.setErrors({ 'required': true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }

  updateIssues() {
    (this.preAjaForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ exciseDuty3: c.value.exciseDuty1 })
      c.patchValue({ ec3: c.value.ec1 })
      c.patchValue({ shec3: c.value.shec1 })
      c.patchValue({ serviceTax3: c.value.serviceTax1 })
      c.patchValue({ kkc3: c.value.kkc1 })
      c.patchValue({ sbc3: c.value.sbc1 })
      c.patchValue({ vat3: c.value.vat1 })
      c.patchValue({ bcd3: c.value.bcd1 })
      c.patchValue({ sad3: c.value.sad1 })
      c.patchValue({ antiDumpingDuty3: c.value.antiDumpingDuty1 })
      c.patchValue({ igst3: c.value.igst1 })
      c.patchValue({ ent3: c.value.ent1 })
      c.patchValue({ interest3: c.value.interest1 })
      c.patchValue({ penalty3: c.value.penalty1 })
      c.patchValue({ total3: c.value.total1 })
    })
  }

  updateSubmissions() {
    (this.preAjaForm.get('submissions') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ copyOfAckLoc: c.value.copyOfAckLoc });
      // c.patchValue()
    })
  }

  //update case
  updateCase(phaseTab: any) {
    let allLetterNotUpdated = false;
    this.setCaseItemList();
    this.setAppealDetailItemList();
    this.setPortestlDetailList();
    this.setLiabilityDetailsList();
    this.setConsultantChargesList();
    this.feesChargedChanged();
    this.paymentUnderProtestp1Changed();
    this.issueValueChange();
    this.liabilityAddmittedAndPaidChanged();
    this.whetherCallBookChanged();
    if (!this.preAjaForm.value.noticeLoc) {
      allLetterNotUpdated = true;

    }
    if (allLetterNotUpdated && (phaseTab === 'p2')) {
      this.toaster.showError("Please Upload Notice ");
      return;
    }
    if (phaseTab === 'p1') {
      this.updateIssues();
      // this.protestValidation();
    }
    if (phaseTab === 'p2') {
      this.updateSubmissions();
      this.p1validation();
    }

    if (phaseTab === 'p3') {
      this.p2validation();
    }
    this.isSubmitted = true;
    let data: any = {};
    let isTabValid = this.doValidation(phaseTab);
    if (this.preAjaForm.valid && isTabValid) {
      if (phaseTab === 'p1') {
        let dataNoticeLoc = (this.preAjaForm.get('noticeLoc') as UntypedFormArray).controls;
        var count = dataNoticeLoc?.some((x) => x.get('fileLoc')?.value != null);
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
        let protestArray = this.preAjaForm.controls.protestDetails as UntypedFormArray
        for (let index = 0; index < protestArray.controls.length; index++) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
        
          if (issuesControl.controls.challanNo.value) {
            let supportingDocControls = (issuesControl.get('supportingDocLoc') as UntypedFormArray).controls;
            
            let hasSupportingDoc = supportingDocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
            
            if (!hasSupportingDoc) {
              this.toaster.showError("Required Fields Are Empty for Challan No.");
              return;
            }
          }
        }
      }
      if (phaseTab === 'p2') {
        let submissionsFrmArray = (this.preAjaForm.get('submissions') as UntypedFormArray).controls;
        let element1;
        for (let i = 0; i < submissionsFrmArray.length; i++) {
          element1 = (submissionsFrmArray[i].get('copyOfAckLoc') as UntypedFormArray).controls;
        }
        let count = element1?.some((x: any) =>
          x.get('fileLoc')?.value != null);
        if (!count) {
          this.toaster.showError("Required Fields Are Empty");
          return;
        }
      }

      if (phaseTab === 'p3') {
        let dataOioLoc = (this.preAjaForm.get('oioLoc') as UntypedFormArray).controls;
        var count = dataOioLoc?.some((x) => x.get('fileLoc')?.value != '');
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
      }
      this.calculateRemandBackTotal();
      data.remandedPaymentMade = this.preAjaForm.value.remandedPaymentMade;

      //data.state = this.selectedStateCode;
      if (this.savedPreCaseId) {
        data.caseId = this.savedPreCaseId;
      }
      let urlData = {
        typeVal: this.tabType[0].key
      }

      data.prevStage = this.caseData.prevStage;
      data.caseType = this.caseType
      data.cumulative = this.cumulativeCount


      if (data.protestDetails && data.protestDetails.length) {
        data.protestDetails.forEach((protestDetail: any) => {
          protestDetail.tax = this.inrService.removeCommasAndParseNumber(protestDetail.tax)
          protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
          protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
          protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
        })
      }

      if (data.consultantCharges && data.consultantCharges.length) {
        data.consultantCharges.forEach((charge: any) => {
          charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
        })
      }
      if (data.bankGuarantee && data.bankGuarantee.length) {
        data.bankGuarantee.forEach((bank: any) => {
          bank.bgAmount = this.inrService.removeCommasAndParseNumber(bank.bgAmount)
          bank.fee = this.inrService.removeCommasAndParseNumber(bank.fee)
        })
      }

      if (data.liabilityDetails && data.liabilityDetails.length) {
        data.liabilityDetails.forEach((liability: any) => {
          liability.tax = this.inrService.removeCommasAndParseNumber(liability.tax)
          liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
          liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
          liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
        })
      }

      if (data.issues && data.issues.length) {
        data.issues.forEach((issue: any) => {
          issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty1)
          issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(issue.serviceTax1)
          issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1)
          issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1)
          issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1)
          issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1)
          issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1)
          issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1)
          issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1)
          issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1)
          issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1)
          issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
          issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1)
          issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
          issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
          issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)

          issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty3)
          issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(issue.serviceTax3)
          issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3)
          issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3)
          issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3)
          issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3)
          issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3)
          issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3)
          issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3)
          issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3)
          issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3)
          issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
          issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3)
          issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
          issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
          issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)
        })
      }

      data.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty1Total)
      data.serviceTax1Total = this.inrService.removeCommasAndParseNumber(data.serviceTax1Total)
      data.ec1Total = this.inrService.removeCommasAndParseNumber(data.ec1Total)
      data.shec1Total = this.inrService.removeCommasAndParseNumber(data.shec1Total)
      data.kkc1Total = this.inrService.removeCommasAndParseNumber(data.kkc1Total)
      data.vat1Total = this.inrService.removeCommasAndParseNumber(data.vat1Total)
      data.cst1Total = this.inrService.removeCommasAndParseNumber(data.cst1Total)
      data.sbc1Total = this.inrService.removeCommasAndParseNumber(data.sbc1Total)
      data.bcd1Total = this.inrService.removeCommasAndParseNumber(data.bcd1Total)
      data.sad1Total = this.inrService.removeCommasAndParseNumber(data.sad1Total)
      data.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty1Total)
      data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)
      data.ent1Total = this.inrService.removeCommasAndParseNumber(data.ent1Total)
      data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total)
      data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total)
      data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1)

      data.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty3Total)
      data.serviceTax3Total = this.inrService.removeCommasAndParseNumber(data.serviceTax3Total)
      data.ec3Total = this.inrService.removeCommasAndParseNumber(data.ec3Total)
      data.shec3Total = this.inrService.removeCommasAndParseNumber(data.shec3Total)
      data.kkc3Total = this.inrService.removeCommasAndParseNumber(data.kkc3Total)
      data.vat3Total = this.inrService.removeCommasAndParseNumber(data.vat3Total)
      data.cst3Total = this.inrService.removeCommasAndParseNumber(data.cst3Total)
      data.sbc3Total = this.inrService.removeCommasAndParseNumber(data.sbc3Total)
      data.bcd3Total = this.inrService.removeCommasAndParseNumber(data.bcd3Total)
      data.sad3Total = this.inrService.removeCommasAndParseNumber(data.sad3Total)
      data.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty3Total)
      data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total)
      data.ent3Total = this.inrService.removeCommasAndParseNumber(data.ent3Total)
      data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total)
      data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total)
      data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3)

      let oioLocArray: any[] = [];
      let noticeLocArray: any[] = [];
      let otherAttachLocArray: any[] = [];
      let protestSupportingDocLocArray: any[] = [];
      let LiabilitySupportingDocArray: any[] = [];
      let copyOfAckLocArray: any[] = [];
      let otherAttachmentsLocArray: any[] = [];
      let phAdjournmentLetterLocArray: any[] = [];
      let phCopyLocArray: any[] = [];
      let additionalSubmissionLocArray: any[] = [];
      let phRecordIfAnyLocArray: any[] = [];
      let consultotherAttachmentLocArray: any[] = [];

      for (let i = 0; i < this.preAjaForm.controls.noticeLoc.value.length; i++) {
        const element = this.preAjaForm.controls.noticeLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          noticeLocArray.push(element)
        }

      }
      for (let i = 0; i < this.preAjaForm.controls.attachmentsLoc.value.length; i++) {
        const element = this.preAjaForm.controls.attachmentsLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachLocArray.push(element)
        }
      }
      for (let i = 0; i < this.preAjaForm.controls.liabilityDetails.value.length; i++) {
        LiabilitySupportingDocArray = [];
        const copyFormArray = this.preAjaForm.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            LiabilitySupportingDocArray.push(element)
          }
        }
        this.preAjaForm.controls.liabilityDetails.value[i].copyOfFormLoc = LiabilitySupportingDocArray;
      }
      for (let i = 0; i < this.preAjaForm.controls.protestDetails.value.length; i++) {
        protestSupportingDocLocArray = [];
        const copyFormArray = this.preAjaForm.controls.protestDetails.value[i].supportingDocLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestSupportingDocLocArray.push(element)
          }
        }
        this.preAjaForm.controls.protestDetails.value[i].supportingDocLoc = protestSupportingDocLocArray;
      }
      for (let i = 0; i < this.preAjaForm.controls.liabilityDetails.value.length; i++) {
        LiabilitySupportingDocArray = [];
        const copyFormArray = this.preAjaForm.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            LiabilitySupportingDocArray.push(element)
          }
        }
        this.preAjaForm.controls.liabilityDetails.value[i].copyOfFormLoc = LiabilitySupportingDocArray;
      }
      for (let i = 0; i < this.preAjaForm.controls.submissions.value.length; i++) {
        copyOfAckLocArray = [];
        const copyFormArray = this.preAjaForm.controls.submissions.value[i].copyOfAckLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            copyOfAckLocArray.push(element)
          }
        }
        this.preAjaForm.controls.submissions.value[i].copyOfAckLoc = copyOfAckLocArray;
      }
      for (let i = 0; i < this.preAjaForm.controls.submissions.value.length; i++) {
        otherAttachmentsLocArray = [];
        const copyFormArray = this.preAjaForm.controls.submissions.value[i].otherAttachmentsLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            otherAttachmentsLocArray.push(element)
          }
        }
        this.preAjaForm.controls.submissions.value[i].otherAttachmentsLoc = otherAttachmentsLocArray;
      }
      for (let i = 0; i < this.preAjaForm.controls.itemList.value.length; i++) {
        phCopyLocArray = [];
        const elementPhCopyArray = this.preAjaForm.controls.itemList.value[i].phCopyLoc;

        for (let j = 0; j < elementPhCopyArray.length; j++) {
          const element = elementPhCopyArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phCopyLocArray.push(element)
          }
        }
        this.preAjaForm.controls.itemList.value[i].phCopyLoc = phCopyLocArray;
      }
      for (let i = 0; i < this.preAjaForm.controls.itemList.value.length; i++) {
        additionalSubmissionLocArray = [];
        const elementAddSubArray = this.preAjaForm.controls.itemList.value[i].additionalSubmissionLoc;

        for (let j = 0; j < elementAddSubArray.length; j++) {
          const element = elementAddSubArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            additionalSubmissionLocArray.push(element)
          }
        }
        this.preAjaForm.controls.itemList.value[i].additionalSubmissionLoc = additionalSubmissionLocArray;
      }
      for (let i = 0; i < this.preAjaForm.controls.itemList.value.length; i++) {
        phRecordIfAnyLocArray = [];
        const phRecordArray = this.preAjaForm.controls.itemList.value[i].phRecordIfAnyLoc;

        for (let j = 0; j < phRecordArray.length; j++) {
          const element = phRecordArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phRecordIfAnyLocArray.push(element)
          }
        }
        this.preAjaForm.controls.itemList.value[i].phRecordIfAnyLoc = phRecordIfAnyLocArray;
      }
       for (let i = 0; i < this.preAjaForm.controls.itemList.value.length; i++) {
        phAdjournmentLetterLocArray = [];
        const phAdjournmentArray = this.preAjaForm.controls.itemList.value[i].phAdjournmentLetterLoc;

        for (let j = 0; j < phAdjournmentArray.length; j++) {
          const element = phAdjournmentArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phAdjournmentLetterLocArray.push(element)
          }
        }
        this.preAjaForm.controls.itemList.value[i].phAdjournmentLetterLoc = phAdjournmentLetterLocArray;
      }
      for (let i = 0; i < this.preAjaForm.controls.consultantCharges.value.length; i++) {
        consultotherAttachmentLocArray = [];
        const copyFormArray = this.preAjaForm.controls.consultantCharges.value[i].otherAttachmentLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            consultotherAttachmentLocArray.push(element)
          }
        }
        this.preAjaForm.controls.protestDetails.value[i].otherAttachmentLoc = consultotherAttachmentLocArray;
      }
      for (let i = 0; i < this.preAjaForm.controls.oioLoc.value.length; i++) {
        const element = this.preAjaForm.controls.oioLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          oioLocArray.push(element)
        }

      }
      this.inrFormattingRemoving();
      this.preAjaForm.value.oioLoc = oioLocArray;
      this.preAjaForm.value.noticeLoc = noticeLocArray;
      this.preAjaForm.value.attachmentsLoc = otherAttachLocArray;

      data = this.preAjaForm.value;
      data.issuingAuth1 = {
        officer: this.preAjaForm.value.officer,
        designation: this.preAjaForm.value.designation,
        jurisdiction: this.preAjaForm.value.jurisdiction,
        address: this.preAjaForm.value.address,
        contact: this.preAjaForm.value.contact,
        email: this.preAjaForm.value.email
      }

      data.issuingAuth3 = {
        officer: this.preAjaForm.value.officer3,
        designation: this.preAjaForm.value.designation3,
        jurisdiction: this.preAjaForm.value.jurisdiction3,
        address: this.preAjaForm.value.address3,
        contact: this.preAjaForm.value.contact3,
        email: this.preAjaForm.value.email3
      }
      this.preLitService.updateAjaData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          if (phaseTab === 'p1') {
            this.isPhase2 = true;
            if ((this.preAjaForm.value.whetherWritPreferredHc === true || this.preAjaForm.value.whetherWritPreferredHc === 'true')) {
              this.moveToHC.emit('');
            }
          } else if (phaseTab === 'p2') {
            this.isPhase3 = true;
          } else if (phaseTab === 'p3') {
            if ((this.preAjaForm.value.forumOfAppeal === 'presc' && (this.preAjaForm.value.writAppPreferred === true || this.preAjaForm.value.writAppPreferred === 'true'))) {
              this.moveToSC.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState("", "", url);
            } else if ((this.preAjaForm.value.forumOfAppeal === 'prehc' && (this.preAjaForm.value.writAppPreferred === true || this.preAjaForm.value.writAppPreferred === 'true'))) {
              this.preLitService.setAccessTabData({ preajaFlag: true, preapaFlag: false, preaptFlag: false, prehcFlag: false, prescFlag: false });
              this.moveToHC.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState("", "", url);
            } else if ((this.preAjaForm.value.forumOfAppeal === 'preapt' && (this.preAjaForm.value.whetherDptAppealPreferred === true || this.preAjaForm.value.whetherDptAppealPreferred === 'true'))) {
              this.moveToAT.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState("", "", url);
            } else if ((this.preAjaForm.value.forumOfAppeal === 'preapa' && (this.preAjaForm.value.whetherDptAppealPreferred === true || this.preAjaForm.value.whetherDptAppealPreferred === 'true'))) {
              this.preLitService.setAccessTabData({ preajaFlag: true, preapaFlag: false, preaptFlag: false, prehcFlag: false, prescFlag: false });
              this.callTabFunction.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState("", "", url);
            } else if ((this.preAjaForm.value.forumOfAppeal === 'preapt' && (this.preAjaForm.value.whetherAppealPreferred === true || this.preAjaForm.value.whetherAppealPreferred === 'true'))) {
              this.moveToAT.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState("", "", url);
            } else if (this.preAjaForm.value.whetherAppealPreferred === true || this.preAjaForm.value.whetherAppealPreferred === 'true') {
              this.preLitService.setAccessTabData({ preajaFlag: true, preapaFlag: false, preaptFlag: false, prehcFlag: false, prescFlag: false });
              this.callTabFunction.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState("", "", url);
            }
            else {
              this.router.navigate(['/preGstSummary']);
            }

          }
          // this.isDataSaved = true;
          this.toaster.showSuccess(response.message);

        } else {
          this.toaster.showError(response.message);
        }
      });
    }
    else {
      this.toaster.showError("Required Fields Are Empty");
    }

  }
  //fetch Registration Number
  fetchRegNo(from: any) {
    let model: any = {};
    if (this.caseData) {
      model.actType = this.caseData.actType ? this.caseData.actType : this.selectedActType;
      model.state = this.caseData.state ? this.caseData.state : this.selectedState;
      model.pan = this.caseData.pan ? this.caseData.pan : this.pan;
    } else {
      model.actType = this.selectedActType;
      model.state = this.selectedState;
      model.pan = this.pan;
    }

    this.preLitService.fetchRegNum(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.regNumRes = response.response;
        this.toaster.showSuccess(response.message);
        if(this.regNumRes  !== null){
          this.selectedRegNo = this.regNumRes[0].regNo;
        }
        // this.regNumRes.forEach((element: any) => {
        //   this.preAjaForm.patchValue({ regNo: element.regNo });
        // });
        console.log(this.selectedRegNo, "regNo")
      } else {
        this.preAjaForm.patchValue({ regNo: '' });
      }
    });

  }
  //fetch Registration Number case
  getFileNameDta(fileUrl: any) {
    if (fileUrl && typeof (fileUrl) == 'string')
      return this.preLitService.getFileName(fileUrl);
  }
  /*upload file selection */
  onFileSelect(event: any, isFromAppealDetails: boolean = false) {
    this.selectedField = event.target.getAttribute('id')
    if (this.preAjaForm.value['oioLoc'] !== null || this.preAjaForm.value['oioLoc'] !== '') {
      this.oioLocReq = false;
    }
    if (event.target.files[0] !== undefined) {

      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, isFromAppealDetails);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.selectedFile = null;
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  onUpload(selectedfield: any, isFromAppealDetails: boolean = false) {
    let urlData = {
      gstinOrPan: this.preAjaForm.value.pan,
      type: this.tabType[0].key,
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.preLitService.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        //this.fileUrl = response.url;
        //this.filePath = response.path;
        this.getFileUrl(response.response.url, response.response.path, selectedfield, isFromAppealDetails);
      } else {
      }
    })
  }
  // onUpload(selectedfield: any, isFromAppealDetails:boolean = false) {
  //   let requestBody: any = new FormData();
  //   requestBody.append('caseId', this.caseId ? this.caseId : this.savedPreCaseId);
  //   requestBody.append('type', this.tabType[0].key);
  //   requestBody.append('field', selectedfield);
  //   requestBody.append('fmime', this.selectedFile.type);
  //   requestBody.append('fname', this.selectedFile.name);
  //   requestBody.append('file', this.selectedFile);
  //   this.preLitService.uploadFile(requestBody).subscribe((response: any) => {
  //     if (response && response.status === 1) {
  //       this.setFormData(response.response, selectedfield, isFromAppealDetails)
  //     }
  //   })
  // }

  // setFormData(path: any, selectedfield: any, isFromAppealDetails:boolean = false) {
  getFileUrl(url: any, path: any, selectedfield: any, isFromAppealDetails: boolean = false) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      console.log("response receved is ", response);
      if (this.selectedFile && this.formGrp.itemList.value && this.matches && !isFromAppealDetails) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.itemList.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;
              this.savecaseItemList();
            }
          }
        });
      } else if (this.selectedFile && this.matches != null && this.matches.input.includes('supportingDocLoc')) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.protestDetails.value.forEach((element: any, index: any) => {
          if (letterIndex) {

            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;
              this.savePortestlDetailList();
            }
          }
        });
      } else if (this.selectedFile && this.matches != null && this.matches.input.includes('otherAttachmentLoc')) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.consultantCharges.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;//this.filePath;
              this.saveConsultantChargesList();
            }
          }
        });
      } else if (this.selectedFile && this.matches != null && this.matches.input.includes('copyOfFormLoc')) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.liabilityDetails.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;
              this.saveLiabilityDetailsList();
            }
          }
        });
      } else if (this.selectedFile && this.matches && isFromAppealDetails) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.submissions.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;
              this.saveAppealDetailList();
            }
          }
        });
      }
      if (selectedfield) {
        var toupdate = this.preAjaForm.value;
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.preAjaForm.patchValue(this.preAjaForm.value);
      }
      else {
        let field = selectedfield;
        let adjForm = this.preAjaForm.value;
        adjForm[field] = path;
        if (this.caseData == null) {
          this.caseData = {};
        }
        this.caseData[field] = path;
        this.preAjaForm.patchValue(adjForm);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }
  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
      // path.push(pathChunk.substr(0, indexOfBrc));
      // path.push(pathChunk.charAt(indexOfBrc + 1));
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  saveAppealDetailList() {
    const appealDetailList = this.preAjaForm.value.submissions;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.copyOfAckLoc) {
          this.appealDetailItemList[i].copyOfAckLoc = item.copyOfAckLoc;
        }
        if (item.otherAttachmentsLoc) {
          this.appealDetailItemList[i].otherAttachmentsLoc = item.otherAttachmentsLoc;
        }
      })
    }
    this.preAjaForm.controls.submissions.patchValue(appealDetailList);
  }

  setAppealDetailItemList() {
    this.preAjaForm.value.submissions.forEach((submission: any, i: number) => {
      if (!submission.copyOfAckLoc) {
        submission.copyOfAckLoc = this.appealDetailItemList[i]?.copyOfAckLoc;
      }

      if (!submission.otherAttachmentsLoc) {
        submission.otherAttachmentsLoc = this.appealDetailItemList[i]?.otherAttachmentsLoc;
      }
    })
  }

  savePortestlDetailList() {
    const appealDetailList = this.preAjaForm.value.protestDetails;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.supportingDocLoc) {
          this.appealDetailItemList[i].supportingDocLoc = item.supportingDocLoc;
        }
      })
    }
    this.preAjaForm.controls.protestDetails.patchValue(appealDetailList);
  }
  setPortestlDetailList() {
    this.preAjaForm.value.protestDetails.forEach((data: any, i: number) => {
      if (!data.supportingDocLoc) {
        data.supportingDocLoc = this.appealDetailItemList[i]?.supportingDocLoc;
      }
    })
  }
  // lability data
  saveLiabilityDetailsList() {
    const appealDetailList = this.preAjaForm.value.liabilityDetails;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.copyOfFormLoc) {
          this.appealDetailItemList[i].copyOfFormLoc = item.copyOfFormLoc;
        }
      })
    }
    this.preAjaForm.controls.liabilityDetails.patchValue(appealDetailList);
  }
  setLiabilityDetailsList() {
    this.preAjaForm.value.liabilityDetails.forEach((data: any, i: number) => {
      if (!data.copyOfFormLoc) {
        data.copyOfFormLoc = this.appealDetailItemList[i]?.copyOfFormLoc;
      }
    })
  }


  //    saveConsultantChargesList
  saveConsultantChargesList() {
    const appealDetailList = this.preAjaForm.value.consultantCharges;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.otherAttachmentLoc) {
          this.appealDetailItemList[i].otherAttachmentLoc = item.otherAttachmentLoc;
        }
      })
    }
    this.preAjaForm.controls.consultantCharges.patchValue(appealDetailList);
  }
  setConsultantChargesList() {
    this.preAjaForm.value.consultantCharges.forEach((data: any, i: number) => {
      if (!data.otherAttachmentLoc) {
        data.otherAttachmentLoc = this.appealDetailItemList[i]?.otherAttachmentLoc;
      }
    })
  }

  //download file
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.preLitService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');

      } else {
      }
    })
  }

  // Divison Block
  gstinDivData() {
    this.bussinessService.getGstinDiv().subscribe((response: any) => {
      if (response.status === 1) {
        this.preGstinDivList = response.response.divList;
      }
    });
  }

  deleteUploadNoticeFile() {
    if (this.preAjaForm.get('noticeLoc')?.value) {
      this.preAjaForm.get('noticeLoc')?.setValue(null);
      this.caseData['noticeLoc'] = null;
      this.isnoticeLocFile = false;
      this.isnoticeLocText = true;
      this.isDWnoticeLoc = true;
      this.isUPnoticeLoc = true;
    }
  }

  deleteUploadAttachmentsLoc() {
    if (this.preAjaForm.get('attachmentsLoc')?.value) {
      this.preAjaForm.get('attachmentsLoc')?.setValue(null);
      this.caseData['attachmentsLoc'] = null;
      this.isattachmentsLocFile = false;
      this.isattachmentsLocText = true;
      this.isDWattachmentsLoc = true;
      this.isUPattachmentsLoc = true;
    }
  }

  deleteOioLoc() {
    if (this.preAjaForm.get('oioLoc')?.value) {
      this.preAjaForm.get('oioLoc')?.setValue(null);
      this.caseData['oioLoc'] = null;
      this.isUPoioLoc = true;
      this.isDWoioLoc = true
      this.isoioLocFile = false
      this.isoioLocText = true
    }
  }

  //upload function
  uploadnoticeLoc() {
    this.isnoticeLocFile = true;
    this.isnoticeLocText = false;
    this.isDWnoticeLoc = false;
    // this.isUPnoticeLoc = false;
  }

  uploadattachmentsLoc() {
    this.isattachmentsLocFile = true;
    this.isattachmentsLocText = false;
    this.isDWattachmentsLoc = false;
    // this.isUPattachmentsLoc = false;
  }

  uploadcopyOfAckLoc() {
    this.iscopyOfAckLocFile = true;
    this.iscopyOfAckLocText = false;
    this.isDWcopyOfAckLoc = false;
    this.isUPcopyOfAckLoc = false;
  }

  uploadotherAttachmentsLoc() {
    this.isotherAttachmentsLocFile = true;
    this.isotherAttachmentsLocText = false;
    this.isDWotherAttachmentsLoc = false;
    this.isUPotherAttachmentsLoc = false;
  }

  uploadphCopyLoc(index: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;
    var itemListArray = this.preAjaForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhCopyLocUploaded'] = true
        }
      }
    }
  }

  uploadadditionalSubmissionLoc(index: any) {
    this.isUadditionalSubmissionLocFile = true;
    this.isadditionalSubmissionLocText = false;
    this.isDWadditionalSubmissionLoc = false;
    this.isUPadditionalSubmissionLoc = false;
    var itemListArray = this.preAjaForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isAdditionalSubmissionLocUploaded'] = true
        }
      }
    }
  }

  uploadphRecordIfAnyLoc(index: any) {
    this.isphRecordIfAnyLocFile = true;
    this.isphRecordIfAnyLocText = false;
    this.isDWphRecordIfAnyLoc = false;
    this.isUPphRecordIfAnyLoc = false;
    var itemListArray = this.preAjaForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhRecordIfAnyLocUploaded'] = true
        }
      }
    }
  }

  uploadoioLoc() {
    this.isoioLocFile = true;
    this.isoioLocText = false;
    this.isDWoioLoc = false;
    this.isUPoioLoc = false;
  }


  uploadCopyOfForm() {
    this.isCopyOfFile = true;
    this.isCopyOfFileText = false;
    this.isconsultantattachmentsDWLocText = false;
    this.isconsultantattachmentsUPLocText = false;
  }

  uploadCopyOfBankChallan() {
    this.iscopyOfBankChallanFile = true;
    this.iscopyOfBankChallanText = false;
    this.iscopyOfBankChallanDWText = false;
    this.iscopyOfBankChallanUPText = false;
  }

  //  uploadCopyOfAck() {
  //   this.isCopyOfAckFile = true;
  //   this.isCopyOfAckText = false;
  //   this.isCopyOfAckDWText = true;
  //   this.isCopyOfAckUPText = false;
  // }
  // uploadotherAttachments() {
  //   this.isOtherAttachmentFile = true;
  //   this.isOtherAttachmentText = false;
  //   this.isOtherAttachmentDWText = true;
  //   this.isOtherAttachmentUPText = false;
  // }


  // uploadSupportingDoc2() {
  //   this.isSupportingDocFile2 = true;
  //   this.isSupportingDocText2 = false;
  //   this.isSupportingDocDWText2 = true;
  //   this.isSupportingDocUPText2 = false;
  // }
  uploaddrc03CopyLoc() {
    this.isdrc03CopyLocFile = true;
    this.isdrc03CopyLocText = false;
    this.isDWdrc03CopyLoc = false;
    this.isUPdrc03CopyLoc = false;
  }
  rmWeatherApplErrorBorder() {
    this.someElement = document.getElementById("whetApplPref");
    this.someElement.classList.remove("errorBorder");
  }
  rmWeatherWritErrorBorder() {
    this.someElement = document.getElementById("whetWritPref");
    this.someElement.classList.remove("errorBorder");
  }
  checkAppYes(event: any) {
    if (event.target.value === "true") {
      this.disabledWeatherWrit = true;
      this.disabledWeatherDept = true;
      this.preAjaForm.get("writAppPreferred")?.clearValidators();
      this.preAjaForm.get("writAppPreferred")?.updateValueAndValidity();
      this.preAjaForm.get("writAppPreferred")?.setValue(false);

      this.preAjaForm.get("whetherDptAppealPreferred")?.clearValidators();
      this.preAjaForm.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      this.preAjaForm.get("whetherDptAppealPreferred")?.setValue(false);
      // this.preAjaForm.controls['writAppPreferred'].setErrors({'required': false});
      // this.rmWeatherWritErrorBorder();
    } else {
      this.disabledWeatherWrit = false;
      this.disabledWeatherDept = false;
    }
    this.setForumOfAppeal();
  }

  checkDeptYes(event: any) {
    if (event.target.value === "true") {
      this.disabledWeatherAppl = true;
      this.disabledWeatherWrit = true;
      this.preAjaForm.get("writAppPreferred")?.clearValidators();
      this.preAjaForm.get("writAppPreferred")?.updateValueAndValidity();
      this.preAjaForm.get("writAppPreferred")?.setValue(false);
      this.preAjaForm.get("whetherAppealPreferred")?.clearValidators();
      this.preAjaForm.get("whetherAppealPreferred")?.updateValueAndValidity();
      this.preAjaForm.get("whetherAppealPreferred")?.setValue(false);
      // this.preAjaForm.controls['writAppPreferred'].setErrors({'required': false});
      // this.rmWeatherWritErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherWrit = false;
    }
    this.setForumOfAppeal();
  }
  checkwritAppYes(event: any) {

    if (event.target.value === "true") {
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = true;
      this.preAjaForm.get("whetherAppealPreferred")?.clearValidators();
      this.preAjaForm.get("whetherAppealPreferred")?.updateValueAndValidity();
      this.preAjaForm.get("whetherAppealPreferred")?.setValue(false);
      this.preAjaForm.get("whetherDptAppealPreferred")?.clearValidators();
      this.preAjaForm.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      this.preAjaForm.get("whetherDptAppealPreferred")?.setValue(false);
      //this.rmWeatherApplErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;

    }
    this.setForumOfAppeal();
  }
  changeOutcome(event: any = null) {
    let data;
    if (!event) {
      data = this.preAjaForm.value.outcome;
    } else {
      data = event.target.value;
    }
    if (data === "Favorable") {
      this.disabledWeatherWrit = true;
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = false;
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER.filter((x) => x.value == 'Favorable');
      this.preAjaForm.get("whetherAppealPreferred")?.clearValidators();
      this.preAjaForm.get("whetherAppealPreferred")?.updateValueAndValidity();
      this.preAjaForm.get("whetherAppealPreferred")?.setValue(false);
      this.preAjaForm.get("writAppPreferred")?.clearValidators();
      this.preAjaForm.get("writAppPreferred")?.updateValueAndValidity();
      this.preAjaForm.get("writAppPreferred")?.setValue(false);
      this.preAjaForm.get("whetherDptAppealPreferred")?.clearValidators();
      this.preAjaForm.get("whetherDptAppealPreferred")?.updateValueAndValidity();
      // this.preAjaForm.get("whetherDptAppealPreferred")?.setValue(false);
      var issues1Array = this.preAjaForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
          issues1Control.controls.status.setValue(data);
        }
      }
    } else if (data === "Unfavorable") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER.filter((x) => x.value == 'Unfavorable' || x.value == 'Admitted');
      this.preAjaForm.get("whetherAppealPreferred")?.clearValidators();
      this.preAjaForm.get("whetherAppealPreferred")?.updateValueAndValidity();
      // this.preAjaForm.get("whetherAppealPreferred")?.setValue(false);
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = true;
      // var issues1Array = this.preAjaForm.controls.issues as FormArray
      // if(issues1Array.controls.length > 0) {
      //   for(var i = 0; i < issues1Array.controls.length; i++){
      //       var issues1Control = issues1Array.controls[i] as FormGroup;
      //       // issues1Control.controls.status.setValue(data);
      //     }
      // }
    } else if (data === "Partly Favorable") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER.filter((x) => x.value == 'Partly Favorable' || x.value == 'Favorable' || x.value == 'Unfavorable' || x.value == 'Partly Unfavorable' || x.value == 'Admitted');
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
    } else if (data === "Partly Unfavorable") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER.filter((x) => x.value == 'Favorable' || x.value == 'Unfavorable' || x.value == 'Partly Favorable' || x.value == 'Partly Unfavorable' || x.value == 'Admitted');
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
    }
  }
  issueDropdown() {
    this.dataSource = PREGST_ISSUE_DROPDOWN;
  }
  savecaseItemList() {
    const itemList = this.preAjaForm.value.itemList;
    if (itemList.length > 0) {
      itemList.forEach((item: any, i: number) => {
        if (!this.caseItemList[i]) {
          this.caseItemList.push({});
        }
        if (item.phCopyLoc) {
          this.caseItemList[i].phCopyLoc = item.phCopyLoc;
        }
        if (item.additionalSubmissionLoc) {
          this.caseItemList[i].additionalSubmissionLoc = item.additionalSubmissionLoc;

        }
        if (item.phRecordIfAnyLoc) {
          this.caseItemList[i].phRecordIfAnyLoc = item.phRecordIfAnyLoc;

        }
        if (item.phAdjournmentLetterLoc) {
          this.caseItemList[i].phAdjournmentLetterLoc = item.phAdjournmentLetterLoc;
        }
      })

      // this.caseItemList.push()
    }
  }

  setCaseItemList() {
    this.preAjaForm.value.itemList.forEach((submission: any, i: number) => {
      if (!submission.phCopyLoc) {
        submission.phCopyLoc = this.caseItemList[i]?.phCopyLoc;
      }
      if (!submission.additionalSubmissionLoc) {
        submission.additionalSubmissionLoc = this.caseItemList[i]?.additionalSubmissionLoc;

      }
      if (!submission.phRecordIfAnyLoc) {
        submission.phRecordIfAnyLoc = this.caseItemList[i]?.phRecordIfAnyLoc;
      }
      if (!submission.phAdjournmentLetterLoc) {
        submission.phAdjournmentLetterLoc = this.caseItemList[i]?.phAdjournmentLetterLoc;
      }
    })
  }

  finYearSelelctEvent(i: any) {
    let issuesFrmArray = this.preAjaForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    const alreadySelectedFinYear = detailsFrmArray.value?.map((data: any) => data.finYear);
    this.finyearData.forEach(f => {
      if (alreadySelectedFinYear.includes(f.year)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }
  quarterSelectEvent(i: any) {
    let issuesFrmArray = this.preAjaForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    const alreadySelectedQuarter = detailsFrmArray.value?.map((data: any) => data.quarter);
    this.quarterList.forEach((f: any) => {
      if (alreadySelectedQuarter.includes(f.value)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }

  getValidDate(date: any): any {
    if (date.value) {
      let d = date.value.split('-');
      const dateValidation = new Date(d[1] + '-' + d[0] + '-' + d[2]);
      return dateValidation;
    } else {
      return new Date();
    }
  }

  whetherCallBookChanged() {
    this.disablePhase3 = (this.preAjaForm.value.whetherCaseCallBook === 'true' || this.preAjaForm.value.whetherCaseCallBook === true) ? true : false;
  }

  onKeyDownEvent(event: any, type: any) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      if (type === 'protestDetails') {
        this.paymentUnderProtestp1Changed();
      }
      if (type === 'issues') {
        this.issueValueChange();
      }
      if (type === 'liabilityDetails') {
        this.liabilityAddmittedAndPaidChanged();
      }
      if (type === 'consultantCharges') {
        this.feesChargedChanged()
      }
      if (type == 'remandedPaymentMade') {
        this.remandedBackPaymentValueChange();
      }
    }
  }

  issueValueChange() {
    let exciseDuty1Total = 0;
    let serviceTax1Total = 0;
    let ec1Total = 0;
    let shec1Total = 0;
    let kkc1Total = 0;
    let sbc1Total = 0;
    let vat1Total = 0;
    let cst1Total = 0;
    let bcd1Total = 0;
    let sad1Total = 0;
    let antiDumpingDuty1Total = 0;
    let igst1Total = 0;
    let ent1Total = 0;
    let interest1Total = 0;
    let penalty1Total = 0;

    let exciseDuty3Total = 0;
    let serviceTax3Total = 0
    let ec3Total = 0;
    let shec3Total = 0;
    let kkc3Total = 0;
    let sbc3Total = 0;
    let vat3Total = 0;
    let cst3Total = 0;
    let bcd3Total = 0;
    let sad3Total = 0;
    let antiDumpingDuty3Total = 0;
    let igst3Total = 0;
    let ent3Total = 0;
    let interest3Total = 0;
    let penalty3Total = 0;

    (this.preAjaForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      const exciseDuty1 = typeof c.value.exciseDuty1 === 'string' ? c.value.exciseDuty1.replace(/,/g, '') : c.value.exciseDuty1;
      const serviceTax1 = typeof c.value.serviceTax1 === 'string' ? c.value.serviceTax1.replace(/,/g, '') : c.value.serviceTax1;
      const ec1 = typeof c.value.ec1 === 'string' ? c.value.ec1.replace(/,/g, '') : c.value.ec1;
      const shec1 = typeof c.value.shec1 === 'string' ? c.value.shec1.replace(/,/g, '') : c.value.shec1;
      const kkc1 = typeof c.value.kkc1 === 'string' ? c.value.kkc1.replace(/,/g, '') : c.value.kkc1;
      const sbc1 = typeof c.value.sbc1 === 'string' ? c.value.sbc1.replace(/,/g, '') : c.value.sbc1;
      const vat1 = typeof c.value.vat1 === 'string' ? c.value.vat1.replace(/,/g, '') : c.value.vat1;
      const cst1 = typeof c.value.cst1 === 'string' ? c.value.cst1.replace(/,/g, '') : c.value.cst1;
      const bcd1 = typeof c.value.bcd1 === 'string' ? c.value.bcd1.replace(/,/g, '') : c.value.bcd1;
      const sad1 = typeof c.value.sad1 === 'string' ? c.value.sad1.replace(/,/g, '') : c.value.sad1;
      const antiDumpingDuty1 = typeof c.value.antiDumpingDuty1 === 'string' ? c.value.antiDumpingDuty1.replace(/,/g, '') : c.value.antiDumpingDuty1;
      const igst1 = typeof c.value.igst1 === 'string' ? c.value.igst1.replace(/,/g, '') : c.value.igst1;
      const ent1 = typeof c.value.ent1 === 'string' ? c.value.ent1.replace(/,/g, '') : c.value.ent1;
      const interest1 = typeof c.value.interest1 === 'string' ? c.value.interest1.replace(/,/g, '') : c.value.interest1;
      const penalty1 = typeof c.value.penalty1 === 'string' ? c.value.penalty1.replace(/,/g, '') : c.value.penalty1;

      const exciseDuty3 = typeof c.value.exciseDuty3 === 'string' ? c.value.exciseDuty3.replace(/,/g, '') : c.value.exciseDuty3;
      const serviceTax3 = typeof c.value.serviceTax3 === 'string' ? c.value.serviceTax3.replace(/,/g, '') : c.value.serviceTax3;
      const ec3 = typeof c.value.ec3 === 'string' ? c.value.ec3.replace(/,/g, '') : c.value.ec3;
      const shec3 = typeof c.value.shec3 === 'string' ? c.value.shec3.replace(/,/g, '') : c.value.shec3;
      const kkc3 = typeof c.value.kkc3 === 'string' ? c.value.kkc3.replace(/,/g, '') : c.value.kkc3;
      const sbc3 = typeof c.value.sbc3 === 'string' ? c.value.sbc3.replace(/,/g, '') : c.value.sbc3;
      const vat3 = typeof c.value.vat3 === 'string' ? c.value.vat3.replace(/,/g, '') : c.value.vat3;
      const cst3 = typeof c.value.cst3 === 'string' ? c.value.cst3.replace(/,/g, '') : c.value.cst3;
      const bcd3 = typeof c.value.bcd3 === 'string' ? c.value.bcd3.replace(/,/g, '') : c.value.bcd3;
      const sad3 = typeof c.value.sad3 === 'string' ? c.value.sad3.replace(/,/g, '') : c.value.sad3;
      const antiDumpingDuty3 = typeof c.value.antiDumpingDuty3 === 'string' ? c.value.antiDumpingDuty3.replace(/,/g, '') : c.value.antiDumpingDuty3;
      const igst3 = typeof c.value.igst3 === 'string' ? c.value.igst3.replace(/,/g, '') : c.value.igst3;
      const ent3 = typeof c.value.ent3 === 'string' ? c.value.ent3.replace(/,/g, '') : c.value.ent3;
      const interest3 = typeof c.value.interest3 === 'string' ? c.value.interest3.replace(/,/g, '') : c.value.interest3;
      const penalty3 = typeof c.value.penalty3 === 'string' ? c.value.penalty3.replace(/,/g, '') : c.value.penalty3;

      exciseDuty1Total += Number(exciseDuty1);
      serviceTax1Total += Number(serviceTax1);
      ec1Total += Number(ec1);
      shec1Total += Number(shec1);
      kkc1Total += Number(kkc1);
      sbc1Total += Number(sbc1);
      vat1Total += Number(vat1);
      cst1Total += Number(cst1);
      bcd1Total += Number(bcd1);
      sad1Total += Number(sad1);
      antiDumpingDuty1Total += Number(antiDumpingDuty1);
      igst1Total += Number(igst1);
      ent1Total += Number(ent1);
      interest1Total += Number(interest1);
      penalty1Total += Number(penalty1);

      exciseDuty3Total += Number(exciseDuty3);
      serviceTax3Total += Number(serviceTax3);
      ec3Total += Number(ec3);
      shec3Total += Number(shec3);
      kkc3Total += Number(kkc3);
      sbc3Total += Number(sbc3);
      vat3Total += Number(vat3);
      cst3Total += Number(cst3);
      bcd3Total += Number(bcd3);
      sad3Total += Number(sad3);
      antiDumpingDuty3Total += Number(antiDumpingDuty3);
      igst3Total += Number(igst3);
      ent3Total += Number(ent3);
      interest3Total += Number(interest3);
      penalty3Total += Number(penalty3);


      
      c.patchValue({ total1: this.inrService.formatCurrency(String(Number(serviceTax1) + Number(exciseDuty1) + Number(ec1) + Number(shec1) + Number(kkc1) + Number(sbc1) + Number(vat1) + Number(cst1) + Number(bcd1) + Number(sad1) + Number(antiDumpingDuty1) + Number(igst1) + Number(ent1) + Number(interest1) + Number(penalty1))) });
      c.patchValue({ total3: this.inrService.formatCurrency(String(Number(serviceTax3) + Number(exciseDuty3) + Number(ec3) + Number(shec3) + Number(kkc3) + Number(sbc3) + Number(vat3) + Number(cst3) + Number(bcd3) + Number(sad3) + Number(antiDumpingDuty3) + Number(igst3) + Number(ent3) + Number(interest3) + Number(penalty3))) });

    })

    this.preAjaForm.controls['exciseDuty1Total'].setValue(this.inrService.formatCurrency(String(exciseDuty1Total)));
    this.preAjaForm.controls['serviceTax1Total'].setValue(this.inrService.formatCurrency(String(serviceTax1Total)));
    this.preAjaForm.controls['ec1Total'].setValue(this.inrService.formatCurrency(String(ec1Total)));
    this.preAjaForm.controls['shec1Total'].setValue(this.inrService.formatCurrency(String(shec1Total)));
    this.preAjaForm.controls['kkc1Total'].setValue(this.inrService.formatCurrency(String(kkc1Total)));
    this.preAjaForm.controls['sbc1Total'].setValue(this.inrService.formatCurrency(String(sbc1Total)));
    this.preAjaForm.controls['vat1Total'].setValue(this.inrService.formatCurrency(String(vat1Total)));
    this.preAjaForm.controls['cst1Total'].setValue(this.inrService.formatCurrency(String(cst1Total)));
    this.preAjaForm.controls['bcd1Total'].setValue(this.inrService.formatCurrency(String(bcd1Total)));
    this.preAjaForm.controls['sad1Total'].setValue(this.inrService.formatCurrency(String(sad1Total)));
    this.preAjaForm.controls['antiDumpingDuty1Total'].setValue(this.inrService.formatCurrency(String(antiDumpingDuty1Total)));
    this.preAjaForm.controls['igst1Total'].setValue(this.inrService.formatCurrency(String(igst1Total)));
    this.preAjaForm.controls['ent1Total'].setValue(this.inrService.formatCurrency(String(ent1Total)));
    this.preAjaForm.controls['interest1Total'].setValue(this.inrService.formatCurrency(String(interest1Total)));
    this.preAjaForm.controls['penalty1Total'].setValue(this.inrService.formatCurrency(String(penalty1Total)));

    this.preAjaForm.controls['exciseDuty3Total'].setValue(this.inrService.formatCurrency(String(exciseDuty3Total)));
    this.preAjaForm.controls['serviceTax3Total'].setValue(this.inrService.formatCurrency(String(serviceTax3Total)));
    this.preAjaForm.controls['ec3Total'].setValue(this.inrService.formatCurrency(String(ec3Total)));
    this.preAjaForm.controls['shec3Total'].setValue(this.inrService.formatCurrency(String(shec3Total)));
    this.preAjaForm.controls['kkc3Total'].setValue(this.inrService.formatCurrency(String(kkc3Total)));
    this.preAjaForm.controls['sbc3Total'].setValue(this.inrService.formatCurrency(String(sbc3Total)));
    this.preAjaForm.controls['vat3Total'].setValue(this.inrService.formatCurrency(String(vat3Total)));
    this.preAjaForm.controls['cst3Total'].setValue(this.inrService.formatCurrency(String(cst3Total)));
    this.preAjaForm.controls['bcd3Total'].setValue(this.inrService.formatCurrency(String(bcd3Total)));
    this.preAjaForm.controls['sad3Total'].setValue(this.inrService.formatCurrency(String(sad3Total)));
    this.preAjaForm.controls['antiDumpingDuty3Total'].setValue(this.inrService.formatCurrency(String(antiDumpingDuty3Total)));
    this.preAjaForm.controls['igst3Total'].setValue(this.inrService.formatCurrency(String(igst3Total)));
    this.preAjaForm.controls['ent3Total'].setValue(this.inrService.formatCurrency(String(ent3Total)));
    this.preAjaForm.controls['interest3Total'].setValue(this.inrService.formatCurrency(String(interest3Total)));
    this.preAjaForm.controls['penalty3Total'].setValue(this.inrService.formatCurrency(String(penalty3Total)));

    this.preAjaForm.controls['totOfTotal1'].setValue(this.inrService.formatCurrency(String(Number((+exciseDuty1Total) + (+serviceTax1Total) + (+ec1Total) + (+shec1Total) + (+kkc1Total) + (+sbc1Total) + (+vat1Total) + (+cst1Total) + (+bcd1Total)  + (+sad1Total)  + (+antiDumpingDuty1Total)  + (+igst1Total) + (+ent1Total) + (+interest1Total) + (+penalty1Total)))));
    this.preAjaForm.controls['totOfTotal3'].setValue(this.inrService.formatCurrency(String(Number((+exciseDuty3Total) + (+serviceTax3Total) + (+ec3Total) + (+shec3Total) + (+kkc3Total) + (+sbc3Total) + (+vat3Total) + (+cst3Total) + (+bcd3Total) + (+sad3Total)  + (+antiDumpingDuty3Total)  + (+igst3Total) + (+ent3Total) + (+interest3Total) + (+penalty3Total)))));


  }

  consultantInvArr(): UntypedFormGroup[] {
    return (this.preAjaForm.get('consultantCharges') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  // getSubmissionDetailsArr(): FormGroup[] {
  //   return (this.preAjaForm.get('submissions') as FormArray).controls as FormGroup[];
  // }

  // Consultant Charges Table

  ConsultantCharges(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      firm: [data ? data.firm : null],
      consultant: [data ? data.consultant : null],
      natureOfWork: [data ? data.natureOfWork : null],
      fees: [data ? data.fees : 0],
      otherAttachmentLoc: new UntypedFormArray([]),
      email: [data ? data.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [data ? data.contact : null]
    })
  }

  addConsultantInv() {
    let detailOfConsultant = this.ConsultantCharges();
    (this.preAjaForm.get('consultantCharges') as UntypedFormArray).push(detailOfConsultant);
  }

  rmConsultantInv(i: number) {
    (this.preAjaForm.get('consultantCharges') as UntypedFormArray).removeAt(i);
    this.rmvConsultantModalRef.close();
    this.feesChargedChanged();
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (this.preAjaForm.get('consultantCharges') as UntypedFormArray).controls.forEach((c: any) => {
      const fees = typeof c.value.fees === 'string' ? c.value.fees.replace(/,/g, '') : c.value.fees;
      feesChargedTotal += Number(fees);
    });

    this.feesChargedTotal = feesChargedTotal;
  }

  openRmvconsultantPop(content: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }

  uploadoConsultantAttachmentsLoc(i: any,) {
    var consultantArray = this.preAjaForm.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (var index = 0; index < consultantArray.controls.length; index++) {
        if (index === i) {
          var consultantControl = consultantArray.controls[index] as UntypedFormGroup;

          consultantControl.value['isOtherAttachmentLocDocUploadedClicked'] = true
        }
      }
    }
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLocText = true;
    this.isconsultantUPattachmentsLocText = false;
  }
  deleteConsultantFile(i: any) {
    let consultantArray = this.preAjaForm.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentLoc.setValue(null);
          issuesControl.value['isOtherAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }




  // uploadoConsultantAttachmentsLoc() {
  //   this.isconsultantattachmentsLocFile = true;
  //   this.isconsultantattachmentsLocText = false;
  //   this.isconsultantDWattachmentsLocText = true;
  //   this.isconsultantUPattachmentsLocText = false;
  // }

  // uploadSupportingDoc() {
  //   this.isSupportingDocFile = true;
  //   this.isSupportingDocText = false;
  //   this.isSupportingDocDWText = true;
  //   this.isSupportingDocUPText = false;
  // }

  onPaste(event: any, id: any, tableName: any, index: any, label: any = null) {
    let keyCodeEvent = { keyCode: 8 }
    event.preventDefault();
    let value: any = Math.round(+event.clipboardData.getData('Text'));
    let data = this.preAjaForm.get(tableName)?.value;
    if (tableName === 'remandedPaymentMade') {
      this.setRemandBackTableData(label, id, value);
    } else {
      data[index][id] = value;
    }
    this.preAjaForm.get(tableName)?.setValue(data)
    this.onKeyDownEvent(keyCodeEvent, tableName);
  }

  setRemandBackTableData(label: any, id: any, value: any) {
    (this.preAjaForm.get('remandedPaymentMade') as UntypedFormArray).controls.forEach((c: any) => {
      if (c.value.label === label) {
        c.value[id] = value
      }
    });
  }

  paymentUnderProtestp1Changed() {

    let taxTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let protestDetailTotal = 0;

    (this.preAjaForm.get('protestDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const tax = typeof c.value.tax === 'string' ? c.value.tax.replace(/,/g, '') : c.value.tax;
      const interest = typeof c.value.interest === 'string' ? c.value.interest.replace(/,/g, '') : c.value.interest;
      const penalty = typeof c.value.penalty === 'string' ? c.value.penalty.replace(/,/g, '') : c.value.penalty;
      taxTotal += Number(tax);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(tax)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = typeof c.value.total === 'string' ? c.value.total.replace(/,/g, '') : c.value.total;
      protestDetailTotal += Number(total);
    });
    this.protestTaxTotal = taxTotal;
    this.protestInterestTotal = interestTotal;
    this.protestPenaltyTotal = penaltyTotal;
    this.protestTotal = protestDetailTotal;

  }


  selectedRemandedBackCaseId(value: any) {
    if (value) {
      this.selectedRemandedBackCaseDetail = this.remandedCaseObj.find((c: any) => c.value === value.target.value);
      this.initializeRemandedBackPaymentData(this.selectedRemandedBackCaseDetail.cumulative)
      this.setCummulativeCount();
    }
  }


  setCummulativeCount(oldArr: any = null, newArr: any = null, tableName: any = null) {
    //   let protestPaymentTax = 0;
    //   let protestPaymentPenalty   = 0;
    //   let protestPaymentInterest = 0;
    //   let protestPaymentTotal = 0;

    //   let liabilityPaymentTax = 0
    //   let liabilityPaymentPenalty = 0;
    //   let liabilityPaymentInterest = 0;
    //   let liabilityPaymentTotal = 0;

    //   let consultantFees = 0;

    //   let oldTotalTax :any= 0, oldTotalInterrest :any= 0 , oldTotalPenalty:any = 0;
    //   let newTotalTax :any= 0, newTotalInterrest :any= 0 , newTotalPenalty:any = 0;
    //   let oldFees :any=0, newFees:any= 0;

    //   if(this.selectedRemandedBackCaseDetail && this.selectedRemandedBackCaseDetail?.cumulative?.length > 0){
    //     this.selectedRemandedBackCaseDetail.cumulative.forEach((p: any) =>{
    //       if(p.label === 'Cumulative payment made under protest'){
    //         p.tax ? protestPaymentTax += p.tax : null;
    //         p.penalty ? protestPaymentPenalty += p.penalty : null;
    //         p.interest ? protestPaymentInterest += p.interest : null;
    //         p.protestPaymentTotal ? protestPaymentTotal +=p.protestPaymentTotal : null;
    //       }else if(p.label === 'Cumulative payment made for admitted liability'){
    //         p.tax ? liabilityPaymentTax += p.tax : null;
    //         p.penalty ? liabilityPaymentPenalty += p.penalty : null;
    //         p.interest ? liabilityPaymentInterest += p.interest : null;
    //         p.total ? liabilityPaymentTotal +=p.total : null;
    //       }else if(p.label === 'Cumulative Consultant Charges'){
    //         p.fees ? consultantFees += p.fees : null;
    //       }
    //       })
    //   }

    //   if(this.caseData?.cumulative && this.caseData.cumulative.length > 0 && this.caseData.status != 'MOVED'){
    //     this.caseData.cumulative.forEach((p: any) =>{
    //       if(p.label === 'Cumulative payment made under protest'){
    //         p.tax ? protestPaymentTax += p.tax : null;
    //         p.penalty ? protestPaymentPenalty += p.penalty : null;
    //         p.interest ? protestPaymentInterest += p.interest : null;
    //         p.total ? protestPaymentTotal +=p.total : null;
    //       }else if(p.label === 'Cumulative payment made for admitted liability'){
    //         p.tax ? liabilityPaymentTax += p.tax : null;
    //         p.penalty ? liabilityPaymentPenalty += p.penalty : null;
    //         p.interest ? liabilityPaymentInterest += p.interest : null;
    //         p.total ? liabilityPaymentTotal +=p.total : null;
    //       }else if(p.label === 'Cumulative Consultant Charges'){
    //         p.total ? consultantFees += p.total : null;
    //       }
    //       })
    //    }

    //   //array is not null and empty
    //   if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== 'consultantCharges'){
    //     oldArr.forEach((p : any) =>{
    //       if( p.tax != null)
    //       oldTotalTax +=  p.tax ;
    //       if( p.penalty != null)
    //       oldTotalPenalty += p.penalty;
    //       if( p.interest != null)
    //       oldTotalInterrest += p.interest;

    //     });
    //     newArr.forEach((p : any) =>{
    //       if( p.tax != null)
    //       newTotalTax +=  p.tax ;
    //       if( p.penalty != null)
    //       newTotalPenalty += p.penalty;
    //       if( p.interest != null)
    //       newTotalInterrest += p.interest;
    //     });
    //   }else if(tableName === 'consultantCharges'){
    //     oldArr.forEach((p : any) =>{
    //      if(p.fees != null){
    //        oldFees += p.fees;
    //      }
    //     });

    //     newArr.forEach((p : any) =>{
    //       if(p.fees != null){
    //         newFees += p.fees;
    //       }
    //     })
    //   }

    //   if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== null){
    //     this.cumulativeCount.forEach((c: any) =>{
    //     if(c.label === 'Cumulative payment made under protest' &&  tableName === 'protestDetails'){
    //       c.tax = protestPaymentTax - oldTotalTax + newTotalTax  ;
    //       c.penalty = protestPaymentPenalty - oldTotalPenalty + newTotalPenalty  ;
    //       c.interest = protestPaymentInterest - oldTotalInterrest + newTotalInterrest  ;
    //       c.total = c.tax + c.penalty + c.interest  ;
    //     }
    //     else if(c.label === 'Cumulative payment made for admitted liability' && tableName === 'liabilityDetails'){
    //       c.tax = liabilityPaymentTax - oldTotalTax + newTotalTax  ;
    //       c.penalty = liabilityPaymentPenalty - oldTotalPenalty + newTotalPenalty ;
    //       c.interest = liabilityPaymentInterest - oldTotalInterrest + newTotalInterrest ;
    //       c.total = c.tax + c.penalty + c.interest  ;
    //     }else if(c.label === 'Cumulative Consultant Charges' && tableName === 'consultantCharges'){
    //       c.total = consultantFees - oldFees + newFees  ;
    //     }
    //     });
    //     this.caseData.cumulative = this.cumulativeCount;
    //   }else{
    //     this.cumulativeCount.forEach((c: any) =>{
    //       if(c.label === 'Cumulative payment made under protest'){
    //         protestPaymentTax ?  c.tax = protestPaymentTax  : null;
    //         protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
    //         protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
    //         protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
    //       }else if(c.label === 'Cumulative payment made for admitted liability'){
    //         liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
    //         liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
    //         liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
    //         liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
    //       }else if(c.label === 'Cumulative Consultant Charges'){
    //         consultantFees ? c.total = consultantFees  : null;
    //       }
    //     });
    //   }
    //   if(tableName === 'protestDetails') this.paymentUnderProtestp1Changed();
    //   // if(tableName === 'predepositDetails') this.preDepositeChanged();
    //   if(tableName === 'consultantCharges') this.feesChargedChanged();
    //   if(tableName === 'liabilityDetails') this.liabilityAddmittedAndPaidChanged();
  }

  formatTableValues() {
    const issues1Array = this.preAjaForm.controls.issues as UntypedFormArray
    const liabilityDetailsArray = this.preAjaForm.controls.liabilityDetails as UntypedFormArray
    const protestDetailsArray = this.preAjaForm.controls.protestDetails as UntypedFormArray
    const consultantChargesArray = this.preAjaForm.controls.consultantCharges as UntypedFormArray

    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (var index = 0; index < consultantChargesArray.controls.length; index++) {
        const consultantChargesControl = consultantChargesArray.controls[index] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(this.inrService.formatCurrency(consultantChargesControl.value.fees))
      }
    }

    if (protestDetailsArray && protestDetailsArray.controls.length) {
      for (var index = 0; index < protestDetailsArray.controls.length; index++) {
        const protestDetailsControl = protestDetailsArray.controls[index] as UntypedFormGroup;
        protestDetailsControl.controls['tax'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.tax))
        protestDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.interest))
        protestDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.penalty))
        protestDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.total))
      }
    }

    if (liabilityDetailsArray.controls.length > 0) {
      for (let index = 0; index < liabilityDetailsArray.controls.length; index++) {
        const liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
        liabilityDetailsControl.controls['tax'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.tax))
        liabilityDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.interest))
        liabilityDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.penalty))
        liabilityDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.total))
      }
    }


    if (issues1Array.controls.length > 0) {
      for (let i = 0; i < issues1Array.controls.length; i++) {
        const issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        issues1Control.controls['exciseDuty1'].setValue(this.inrService.formatCurrency(issues1Control.value.exciseDuty1))
        issues1Control.controls['serviceTax1'].setValue(this.inrService.formatCurrency(issues1Control.value.serviceTax1))
        issues1Control.controls['ec1'].setValue(this.inrService.formatCurrency(issues1Control.value.ec1))
        issues1Control.controls['shec1'].setValue(this.inrService.formatCurrency(issues1Control.value.shec1))
        issues1Control.controls['kkc1'].setValue(this.inrService.formatCurrency(issues1Control.value.kkc1))
        issues1Control.controls['sbc1'].setValue(this.inrService.formatCurrency(issues1Control.value.sbc1))
        issues1Control.controls['vat1'].setValue(this.inrService.formatCurrency(issues1Control.value.vat1))
        issues1Control.controls['cst1'].setValue(this.inrService.formatCurrency(issues1Control.value.cst1))
        issues1Control.controls['bcd1'].setValue(this.inrService.formatCurrency(issues1Control.value.bcd1))
        issues1Control.controls['sad1'].setValue(this.inrService.formatCurrency(issues1Control.value.sad1))
        issues1Control.controls['antiDumpingDuty1'].setValue(this.inrService.formatCurrency(issues1Control.value.antiDumpingDuty1))
        issues1Control.controls['igst1'].setValue(this.inrService.formatCurrency(issues1Control.value.igst1))
        issues1Control.controls['ent1'].setValue(this.inrService.formatCurrency(issues1Control.value.ent1))
        issues1Control.controls['interest1'].setValue(this.inrService.formatCurrency(issues1Control.value.interest1))
        issues1Control.controls['penalty1'].setValue(this.inrService.formatCurrency(issues1Control.value.penalty1))
        issues1Control.controls['total1'].setValue(this.inrService.formatCurrency(issues1Control.value.total1))

        issues1Control.controls['exciseDuty3'].setValue(this.inrService.formatCurrency(issues1Control.value.exciseDuty3))
        issues1Control.controls['serviceTax3'].setValue(this.inrService.formatCurrency(issues1Control.value.serviceTax3))
        issues1Control.controls['ec3'].setValue(this.inrService.formatCurrency(issues1Control.value.ec3))
        issues1Control.controls['shec3'].setValue(this.inrService.formatCurrency(issues1Control.value.shec3))
        issues1Control.controls['kkc3'].setValue(this.inrService.formatCurrency(issues1Control.value.kkc3))
        issues1Control.controls['sbc3'].setValue(this.inrService.formatCurrency(issues1Control.value.sbc3))
        issues1Control.controls['vat3'].setValue(this.inrService.formatCurrency(issues1Control.value.vat3))
        issues1Control.controls['cst3'].setValue(this.inrService.formatCurrency(issues1Control.value.cst3))
        issues1Control.controls['bcd3'].setValue(this.inrService.formatCurrency(issues1Control.value.bcd3))
        issues1Control.controls['sad3'].setValue(this.inrService.formatCurrency(issues1Control.value.sad3))
        issues1Control.controls['antiDumpingDuty3'].setValue(this.inrService.formatCurrency(issues1Control.value.antiDumpingDuty3))
        issues1Control.controls['igst3'].setValue(this.inrService.formatCurrency(issues1Control.value.igst3))
        issues1Control.controls['ent3'].setValue(this.inrService.formatCurrency(issues1Control.value.ent3))
        issues1Control.controls['interest3'].setValue(this.inrService.formatCurrency(issues1Control.value.interest3))
        issues1Control.controls['penalty3'].setValue(this.inrService.formatCurrency(issues1Control.value.penalty3))
        issues1Control.controls['total3'].setValue(this.inrService.formatCurrency(issues1Control.value.total3))
      }
    }
  }


  changeRemandedBackForum(event: any) {
    let data: any = {};
    this.remandedCaseObj = [];
    data.cumulative = this.cumulativeCount;
    let model: any = {};
    const criteriaArray: any = [{
      "p": "type",
      "o": "eq",
      "v": event.target.value
    }]
    model.criterias = criteriaArray;
    model.companyId = localStorage.getItem('selectedId'),
      this.preLitService.getRemandedBackForumPregstOptions(model).subscribe((response: any) => {
        if (response) {
          this.remandBackCaseList = response.response;
          response?.response.forEach((res: any) => {
            let obj = { 'key': res.caseId, 'value': res.caseId, cumulative: res.cumulative, issues: res.issues }
            this.remandedCaseObj.push(obj)
          });
        }
      });
  }

  setRemandBackData(event: any) {
    // this.disabledOnload = true;
    let remandBackCaseData: any = this.remandBackCaseList.find((data: any) => data.caseId == this.preAjaForm.value.remandedCaseId);
    this.preAjaForm.controls['caseId'].setValue(remandBackCaseData?.caseId);
    this.preAjaForm.controls['actType'].setValue(remandBackCaseData?.actType);
    this.changeTax(null);
    this.preAjaForm.controls['state'].setValue(remandBackCaseData?.state);
    this.changeState(null);
    this.preAjaForm.controls['regNo'].setValue(remandBackCaseData?.regNo);
    if (remandBackCaseData?.issues?.length) this.rmIssueInv(0, true);
    this.initializeIssueList(remandBackCaseData);

  }
  setForumOfAppeal() {

    if ((this.preAjaForm.value.whetherAppealPreferred === true || this.preAjaForm.value.whetherAppealPreferred === "true") &&
      (this.preAjaForm.value.whetherDptAppealPreferred === false || this.preAjaForm.value.whetherDptAppealPreferred === "false") &&
      (this.preAjaForm.value.writAppPreferred === false || this.preAjaForm.value.writAppPreferred === "false")) {
      this.forumAppeal = [
        { key: "preapa", value: " Commissioner Appeal" },
        { key: "preapt", value: "Appellate Tribunal" }
      ];
      return;
    }
    if ((this.preAjaForm.value.whetherAppealPreferred === false || this.preAjaForm.value.whetherAppealPreferred === "false") &&
      (this.preAjaForm.value.whetherDptAppealPreferred === false || this.preAjaForm.value.whetherDptAppealPreferred === "false") &&
      (this.preAjaForm.value.writAppPreferred === false || this.preAjaForm.value.writAppPreferred === "false")) {
      this.forumAppeal = [
        { key: "Appeal/Writ", value: "Appeal/Writ not Filed" }
      ];
      return;
    }
    if ((this.preAjaForm.value.whetherAppealPreferred === false || this.preAjaForm.value.whetherAppealPreferred === "false") &&
      (this.preAjaForm.value.whetherDptAppealPreferred === false || this.preAjaForm.value.whetherDptAppealPreferred === "false") &&
      (this.preAjaForm.value.writAppPreferred === true || this.preAjaForm.value.writAppPreferred === "true")) {
      this.forumAppeal = [
        { key: "prehc", value: "High Court " },
        { key: "presc", value: "Supreme Court" }
      ];
      return;
    }
    if ((this.preAjaForm.value.whetherAppealPreferred === false || this.preAjaForm.value.whetherAppealPreferred === "false") &&
      (this.preAjaForm.value.whetherDptAppealPreferred === true || this.preAjaForm.value.whetherDptAppealPreferred === "true") &&
      (this.preAjaForm.value.writAppPreferred === false || this.preAjaForm.value.writAppPreferred === "false")) {
      this.forumAppeal = [
        { key: "preapa", value: " Commissioner Appeal" },
        { key: "preapt", value: "Appellate Tribunal" }
      ];
      return;
    }

    this.forumAppeal = FORUM_APPEAL;

  }

  getFormName() {
    return (this.preAdjType.find(type => type.key === this.preAjaForm?.get('remandedBackForum')?.value))?.value
  }

  newRemandedPaymentMade(data: any): UntypedFormGroup {
    return this.fBuild.group({
      label: [data ? data?.label : ''],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)]],
    })
  }

  initializeRemandedBackPaymentData(data: any) {
    data.forEach((cumulative: any) => {
      let detailOfCumulative = this.newRemandedPaymentMade(cumulative);
      (this.preAjaForm.get('remandedPaymentMade') as UntypedFormArray).push(detailOfCumulative);
    });
    console.log(this.preAjaForm.get('remandedPaymentMade'));
  }

  getREmandedBackData() {
    return (this.preAjaForm.get('remandedPaymentMade') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  remandedBackPaymentValueChange() {
    (this.preAjaForm.get('remandedPaymentMade') as UntypedFormArray).controls.forEach((c: any) => {
      if (c.value.label !== 'Cumulative Consultant Charges') {
        c.patchValue({ total: (+c.value.tax) + (+c.value.interest) + (+c.value.penalty) }, { emitEvent: false, onlySelf: true });
      } else {
        c.patchValue({ total: +(c.value.total) })
      }
    });
  }

  calculateRemandBackTotal() {
    (this.preAjaForm.get('remandedPaymentMade') as UntypedFormArray).controls.forEach((c: any) => {
      if (c.value.label !== 'Cumulative Consultant Charges') {
        c.patchValue({ total: (+c.value.tax) + (+c.value.interest) + (+c.value.penalty) });
      } else {
        c.patchValue({ total: +(c.value.total) })
      }
    })
  }

  refreshCumulativeTable(event: any) {
    event.stopPropagation();
    let refreshValue = this.preAjaForm.value;
    refreshValue['cumulative'] = this.caseData.cumulative
    refreshValue['prevStage'] = this.prevStage
    if (refreshValue.protestDetails && refreshValue.protestDetails.length) {
      refreshValue.protestDetails.forEach((protestDetail: any) => {
        protestDetail.tax = this.inrService.removeCommasAndParseNumber(protestDetail.tax)
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
        protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
      })
    }
    if (refreshValue.consultantCharges && refreshValue.consultantCharges.length) {
      refreshValue.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
    }
    if (refreshValue.liabilityDetails && refreshValue.liabilityDetails.length) {
      refreshValue.liabilityDetails.forEach((liability: any) => {
        liability.tax = this.inrService.removeCommasAndParseNumber(liability.tax)
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
      })
    }
    if (refreshValue.issues && refreshValue.issues.length) {
      refreshValue.issues.forEach((issue: any) => {
        issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty1)
        issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(issue.serviceTax1)
        issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1)
        issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1)
        issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1)
        issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1)
        issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1)
        issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1)
        issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1)
        issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1)
        issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1)
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
        issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1)
        issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)

        issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty3)
        issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(issue.serviceTax3)
        issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3)
        issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3)
        issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3)
        issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3)
        issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3)
        issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3)
        issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3)
        issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3)
        issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3)
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
        issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3)
        issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)
        if (issue.details && issue.details.length) {
          issue.details.forEach((details: any) => {
            details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(details.provisionAmt1)
            details.contLiabilityAmt1 = this.inrService.removeCommasAndParseNumber(details.contLiabilityAmt1)
            details.otherLiability1 = this.inrService.removeCommasAndParseNumber(details.otherLiability1)
            details.caroAmt1 = this.inrService.removeCommasAndParseNumber(details.caroAmt1)
          })
        }
      })
    }

    refreshValue.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(refreshValue.exciseDuty1Total)
    refreshValue.serviceTax1Total = this.inrService.removeCommasAndParseNumber(refreshValue.serviceTax1Total)
    refreshValue.ec1Total = this.inrService.removeCommasAndParseNumber(refreshValue.ec1Total)
    refreshValue.shec1Total = this.inrService.removeCommasAndParseNumber(refreshValue.shec1Total)
    refreshValue.kkc1Total = this.inrService.removeCommasAndParseNumber(refreshValue.kkc1Total)
    refreshValue.vat1Total = this.inrService.removeCommasAndParseNumber(refreshValue.vat1Total)
    refreshValue.cst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.cst1Total)
    refreshValue.sbc1Total = this.inrService.removeCommasAndParseNumber(refreshValue.sbc1Total)
    refreshValue.bcd1Total = this.inrService.removeCommasAndParseNumber(refreshValue.bcd1Total)
    refreshValue.sad1Total = this.inrService.removeCommasAndParseNumber(refreshValue.sad1Total)
    refreshValue.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(refreshValue.antiDumpingDuty1Total)
    refreshValue.igst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.igst1Total)
    refreshValue.ent1Total = this.inrService.removeCommasAndParseNumber(refreshValue.ent1Total)
    refreshValue.interest1Total = this.inrService.removeCommasAndParseNumber(refreshValue.interest1Total)
    refreshValue.penalty1Total = this.inrService.removeCommasAndParseNumber(refreshValue.penalty1Total)
    refreshValue.totOfTotal1 = this.inrService.removeCommasAndParseNumber(refreshValue.totOfTotal1)

    refreshValue.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(refreshValue.exciseDuty3Total)
    refreshValue.serviceTax3Total = this.inrService.removeCommasAndParseNumber(refreshValue.serviceTax3Total)
    refreshValue.ec3Total = this.inrService.removeCommasAndParseNumber(refreshValue.ec3Total)
    refreshValue.shec3Total = this.inrService.removeCommasAndParseNumber(refreshValue.shec3Total)
    refreshValue.kkc3Total = this.inrService.removeCommasAndParseNumber(refreshValue.kkc3Total)
    refreshValue.vat3Total = this.inrService.removeCommasAndParseNumber(refreshValue.vat3Total)
    refreshValue.cst3Total = this.inrService.removeCommasAndParseNumber(refreshValue.cst3Total)
    refreshValue.sbc3Total = this.inrService.removeCommasAndParseNumber(refreshValue.sbc3Total)
    refreshValue.bcd3Total = this.inrService.removeCommasAndParseNumber(refreshValue.bcd3Total)
    refreshValue.sad3Total = this.inrService.removeCommasAndParseNumber(refreshValue.sad3Total)
    refreshValue.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(refreshValue.antiDumpingDuty3Total)
    refreshValue.igst3Total = this.inrService.removeCommasAndParseNumber(refreshValue.igst3Total)
    refreshValue.ent3Total = this.inrService.removeCommasAndParseNumber(refreshValue.ent3Total)
    refreshValue.interest3Total = this.inrService.removeCommasAndParseNumber(refreshValue.interest3Total)
    refreshValue.penalty3Total = this.inrService.removeCommasAndParseNumber(refreshValue.penalty3Total)
    refreshValue.totOfTotal3 = this.inrService.removeCommasAndParseNumber(refreshValue.totOfTotal3)
    this.litService.getCumulativeCount(this.tabType[0].key, refreshValue).subscribe((response: any) => {
      this.formatTableValues();
      this.caseData.cumulative = response.response;
      this.cumulativeCount = response.response;
      this.toaster.showSuccess(response.message);
    })
  }

  inrFormattingRemoving() {
    const data = this.preAjaForm.value;
    //phase 2 form
    if (data.bankGuarantee && data.bankGuarantee.length) {
      data.bankGuarantee.forEach((bank: any) => {
        bank.bgAmount = this.inrService.removeCommasAndParseNumber(bank.bgAmount)
        bank.fee = this.inrService.removeCommasAndParseNumber(bank.fee)
      })
    }
    if (data.consultantCharges && data.consultantCharges.length) {
      data.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
    }
    //phase 1 form
    if (data.protestDetails && data.protestDetails.length) {
      data.protestDetails.forEach((protestDetail: any) => {
        protestDetail.tax = this.inrService.removeCommasAndParseNumber(protestDetail.tax)
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
        protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
      })
    }
    if (data.depositDetails && data.depositDetails.length) {
      data.depositDetails.forEach((depositDetails: any) => {
        depositDetails.tax = this.inrService.removeCommasAndParseNumber(depositDetails.tax)
        depositDetails.interest = this.inrService.removeCommasAndParseNumber(depositDetails.interest)
        depositDetails.penalty = this.inrService.removeCommasAndParseNumber(depositDetails.penalty)
        depositDetails.total = this.inrService.removeCommasAndParseNumber(depositDetails.total)
      })
    }

    //phase 3 form
    if (data.liabilityDetails && data.liabilityDetails.length) {
      data.liabilityDetails.forEach((liability: any) => {
        liability.tax = this.inrService.removeCommasAndParseNumber(liability.tax)
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
      })
    }
    // //phase 1 and 3 form
    if (data.issues && data.issues.length) {
      data.issues.forEach((issue: any) => {
        issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty1)
        issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(issue.serviceTax1)
        issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1)
        issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1)
        issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1)
        issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1)
        issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1)
        issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1)
        issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1)
        issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1)
        issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1)
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
        issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1)
        issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)

        issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty3)
        issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(issue.serviceTax3)
        issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3)
        issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3)
        issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3)
        issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3)
        issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3)
        issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3)
        issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3)
        issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3)
        issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3)
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
        issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3)
        issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.totlal3)

        if (issue.details && issue.details.length) {
          issue.details.forEach((details: any) => {
            details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(details.provisionAmt1)
            details.contLiabilityAmt1 = this.inrService.removeCommasAndParseNumber(details.contLiabilityAmt1)
            details.otherLiability1 = this.inrService.removeCommasAndParseNumber(details.otherLiability1)
            details.caroAmt1 = this.inrService.removeCommasAndParseNumber(details.caroAmt1)
          })
        }
      })
    }
    // //phase 1 and 3 form
    data.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty1Total)
    data.serviceTax1Total = this.inrService.removeCommasAndParseNumber(data.serviceTax1Total)
    data.ec1Total = this.inrService.removeCommasAndParseNumber(data.ec1Total)
    data.shec1Total = this.inrService.removeCommasAndParseNumber(data.shec1Total)
    data.kkc1Total = this.inrService.removeCommasAndParseNumber(data.kkc1Total)
    data.vat1Total = this.inrService.removeCommasAndParseNumber(data.vat1Total)
    data.cst1Total = this.inrService.removeCommasAndParseNumber(data.cst1Total)
    data.sbc1Total = this.inrService.removeCommasAndParseNumber(data.sbc1Total)
    data.bcd1Total = this.inrService.removeCommasAndParseNumber(data.bcd1Total)
    data.sad1Total = this.inrService.removeCommasAndParseNumber(data.sad1Total)
    data.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty1Total)
    data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)
    data.ent1Total = this.inrService.removeCommasAndParseNumber(data.ent1Total)
    data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total)
    data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total)
    data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1)

    data.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty3Total)
    data.serviceTax3Total = this.inrService.removeCommasAndParseNumber(data.serviceTax3Total)
    data.ec3Total = this.inrService.removeCommasAndParseNumber(data.ec3Total)
    data.shec3Total = this.inrService.removeCommasAndParseNumber(data.shec3Total)
    data.kkc3Total = this.inrService.removeCommasAndParseNumber(data.kkc3Total)
    data.vat3Total = this.inrService.removeCommasAndParseNumber(data.vat3Total)
    data.cst3Total = this.inrService.removeCommasAndParseNumber(data.cst3Total)
    data.sbc3Total = this.inrService.removeCommasAndParseNumber(data.sbc3Total)
    data.bcd3Total = this.inrService.removeCommasAndParseNumber(data.bcd3Total)
    data.sad3Total = this.inrService.removeCommasAndParseNumber(data.sad3Total)
    data.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty3Total)
    data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total)
    data.ent3Total = this.inrService.removeCommasAndParseNumber(data.ent3Total)
    data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total)
    data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total)
    data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3)
  }

  handlePaste(event: any) {
    this.inrService.handlePaste(event)
  } 
  goToSummary(){
    this.router.navigate(['/preGstSummary']);
    this.modalService.dismissAll();
  }
    selectedIssues:any
  getissueData(content: any, i:any){
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    this.selectedIssues = this.preAjaForm.value.issues[i].issue;
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "updatedOn",
    model.sortdir = "ASC",
    model.companyId =  "7437",
    model.module = "PREGST",
    model.role = "issueRef",
    model.criterias= [
      {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
      },
  ]
  console.log(this.selectedIssues,"this.preAjaForm.value.issue");
  this.litService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
    if (response.status === 1) {
      this.issueData = response?.response;
      if(this.issueData.length === 0){
          this.hideData =  false
      }else{
        this.hideData =  true
      }
    }      
    });
  
  }
  exportAsXLSX() {
    if (this.issueData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    const data= this.issueData.map((source: any) => ({
      'PAN Name': source.panName,
      'Case id': source.caseId,
      'Case Label': source.caseLabel,
      'State': source.state,
      'ACT': source.actyType,
      'Registration number': source.regNo,
      'Period From': source.periodFromDate,
      'Period to': source.periodToDate,
      'Person Responsible': source.personResponsible,

    }));
    this.excelService.exportAsExcelFile(data, 'Issue Reference');
  }
  SortingIssue(column: string) {
    switch (column) {
      case SORT_ISSUE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'asc'
          );
        } else {
          this.sorting.caseId = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.STATE: {
        if (!this.sorting.state) {
          this.sorting.state = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'asc'
          );
        } else {
          this.sorting.state = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PAN: {
        if (!this.sorting.panName) {
          this.sorting.panName = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actType',
            'asc'
          );
        } else {
          this.sorting.panName = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actType',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.CASE_LABEL: {
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'asc'
          );
        } else {
          this.sorting.caseLabel = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.ACT: {
        if (!this.sorting.actyType) {
          this.sorting.actyType = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actyType',
            'asc'
          );
        } else {
          this.sorting.actyType = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actyType',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.RGENO: {
        if (!this.sorting.regNo) {
          this.sorting.regNo = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'regNo',
            'asc'
          );
        } else {
          this.sorting.regNo = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'regNo',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERIOD_FROM: {
        if (!this.sorting.periodFromDate) {
          this.sorting.periodFromDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'asc'
          );
        } else {
          this.sorting.periodFromDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'desc'
          );
        }
        break;
      }

      case SORT_ISSUE.PERIOD_TO: {
        if (!this.sorting.periodToDate) {
          this.sorting.periodToDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'asc'
          );
        } else {
          this.sorting.periodToDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERSON_RESPONSIBLE: {
        if (!this.sorting.personResponsible) {
          this.sorting.personResponsible = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,'personResponsible','asc');
        } else {
          this.sorting.personResponsible = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,'personResponsible', 'desc' );
        }
        break;
      }
    }
  }

  getperiodToValue(content: any){
    if(this.action === "create"){
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = localStorage.getItem('selectedId'),
    model.module = "PREGST",
    model.criterias= [
      {
          "p": "periodFromDate",
          "o": "between",
          "v1": this.preAjaForm.value.periodFromDate,
          "v2":  this.preAjaForm.value.periodToDate,
      },{
          "p": "periodToDate",
          "o": "between",
          "v1": this.preAjaForm.value.periodFromDate,
          "v2": this.preAjaForm.value.periodToDate,
      },
      {
        "p" : "actType",
        "o" : "in",
        "v" :  this.selectedActType,
    }
  ]
  this.litService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.response.length === 0) {
        this.modalService.dismissAll();
      }
      else{
        this.duplicateData = response?.response;
        this.duplicateData?.forEach((obj: any, index: number) => {
          if (obj.forum === 'Appellate Authority')
            this.duplicateData[index].forum = 'Commissioner (A)'
        })
       this.modalService.open(content)
      }
      
    });
  }
  }
}
  
