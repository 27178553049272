import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { DT_ISSUE_DROPDOWN, ISSUE_GST } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { TaxServiceService } from '../service/tax-service.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-financial-information',
  templateUrl: './financial-information.component.html',
  styleUrls: ['./financial-information.component.css'],
})
export class FinancialInformationComponent {

  navContext = { entityName: '', isTaxPayer: false, entityType: '', pan: '', gstin: '' };
  financialInfoFormGroup!: UntypedFormGroup;
  selectedField: any;
  selectedFile: any;
  matches: any;
  fileUrl: any;
  caseId: any;
  selectedPan: any;
  selectedPanName: any;
  selectedFileName: any;
  getRemarks: any;
  itrValue: any
  pan: any;
  fiData: any;
  dataSource: any;
  role: any;
  taxPopModalRef!: NgbModalRef;
  rmvItrFormLocModalRef!: NgbModalRef;
  rmvtotIncomeCompFrmArrayModalRef!: NgbModalRef;
  rmvFinLocFrmArrayModalRef!: NgbModalRef;
  rmStatAuditRepFrmArrayModalRef!: NgbModalRef;
  rmTaxAuditRepFrmArrayModalRef!: NgbModalRef;
  rmTransPricingAudRepFrmArrayModalRef!: NgbModalRef;

  isSubmitted: boolean = false;
  formData: FormData = new FormData();
  finyearData: any[] = [];
  isUPdocLoc: boolean = true;
  isDWdocLoc: boolean = true;
  isdocLocFile: boolean = false;
  isdocLocText: boolean = true;
  isFinancialInformationCreated: boolean = false;

  constructor(
    private fBuild: UntypedFormBuilder,
    private taxService: TaxServiceService,
    private toaster: ToasterService,
    private GS: GetterSetterService,
    private modalService: NgbModal,
    private commonServ: CommonService,
    private router: Router,
    private http: HttpClient,
  ) { }

  ngOnInit() {
    this.navContext = this.commonServ.getNavContext();
    this.selectedPan = this.navContext.pan;
    this.role = localStorage.getItem('role');
    if (this.navContext.entityType === 'Business') {
      this.selectedPanName = sessionStorage.getItem('company');
    } else {
      this.selectedPanName = this.navContext.entityName;
    }
    this.GS.checkEntityType.subscribe((value) => {
      this.navContext = value;
      if (this.fiData && (this.navContext.entityType === 'Legal') && this.router.url === '/auditTaxPosition') {
        this.reloadCurrentRoute();
      }
    });
    this.initializeData();
    this.formInitialize();
    this.issueDropdown();
    this.getYears();
  }

  formInitialize() {
    this.financialInfoFormGroup = this.fBuild.group({
      pan: [this.selectedPan],
      panName: [this.selectedPanName],
      itemList: new UntypedFormArray([]),
    })
  }

  get formGrp() {
    return this.financialInfoFormGroup.controls;
  }

  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2009;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push(financialYrRange);
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }

  getFileNameDta(fileUrl: any) {
    if (fileUrl)
      return this.taxService.getFileName(fileUrl);
  }

  /*upload file selection */
  onFileSelect(event: any) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }

  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  onUpload(selectedfield: any) {
    let urlData = {
      gstinOrPan: this.navContext.pan,
      type: 'dtfininfo',
      field: selectedfield,
      contentType: this.selectedFile.type
    }
    this.taxService.uploadFile(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFileUrl(response.response.url, response.response.path, selectedfield);
      } else {
      }
    })
  }

  getFileUrl(url: any, path: any, selectedfield: any) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      if (selectedfield) {
        // if(formGroup == 'itrFromGrp'){
        //   const b = (financialInfoGrp.get('itrFormLoc') as FormArray).controls as FormGroup[];
        //   b[index].controls["fileName"].setValue(this.selectedFile.name);
        //   }else if(formGroup == 'totIncomeCompFromGrp'){
        //     const b = (financialInfoGrp.get('totIncomeCompLoc') as FormArray).controls as FormGroup[];
        //     b[index].controls["fileName"].setValue(this.selectedFile.name);
        //   }else if(formGroup == 'finLocFrmGrp'){
        //     const b = (financialInfoGrp.get('finLoc') as FormArray).controls as FormGroup[];
        //     b[index].controls["fileName"].setValue(this.selectedFile.name);
        //   }else if(formGroup == 'statAuditRepFrmGrp'){
        //     const b = (financialInfoGrp.get('statAuditRepLoc') as FormArray).controls as FormGroup[];
        //     b[index].controls["fileName"].setValue(this.selectedFile.name);
        //   }else if(formGroup == 'taxAuditReportFrmGrp'){
        //     const b = (financialInfoGrp.get('taxAuditReportLoc') as FormArray).controls as FormGroup[];
        //     b[index].controls["fileName"].setValue(this.selectedFile.name);
        //   }else if(formGroup == 'transPricingAudRepFrmGrp'){
        //     const b = (financialInfoGrp.get('transPricingAudRepLoc') as FormArray).controls as FormGroup[];
        //     b[index].controls["fileName"].setValue(this.selectedFile.name);
        //   }
        var toupdate = this.financialInfoFormGroup.value;
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.financialInfoFormGroup.patchValue(this.financialInfoFormGroup.value);

      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }
  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }

  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.taxService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }

  getFinancialInformation(): UntypedFormGroup[] {
    return (this.financialInfoFormGroup.get('itemList') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  initFinancialInfoGrp(): UntypedFormGroup {
    return this.fBuild.group({
      pan: [this.selectedPan],
      panName: [this.selectedPanName],
      ay: [null, [Validators.required]],
      itrFormLoc: new UntypedFormArray([]),
      totIncomeCompLoc: new UntypedFormArray([]),
      finLoc: new UntypedFormArray([]),
      statAuditRepLoc: new UntypedFormArray([]),
      taxAuditReportLoc: new UntypedFormArray([]),
      transPricingAudRepLoc: new UntypedFormArray([]),
    })
  }

  addRow() {
    let financialInfoGrp = this.initFinancialInfoGrp();
    (financialInfoGrp.get('itrFormLoc') as UntypedFormArray).push(this.initItrFrmGrp());
    (this.financialInfoFormGroup.get('itemList') as UntypedFormArray).push(financialInfoGrp);
  }

  rmProv(i: number) {
    (this.financialInfoFormGroup.get('itemList') as UntypedFormArray).removeAt(i);
    this.taxPopModalRef.close();
  }

  openDetailPop(content: any, i: any) {
    this.addItrDiv(i, 0);
    this.addTotIncomeCompDiv(i, 0);
    this.addStatAuditRepDiv(i, 0);
    this.addFinLocDiv(i, 0);
    this.addTaxAuditReportDiv(i, 0);
    this.addTransPricingAudRepDiv(i, 0)
    this.modalService.open(content);
  }

  /*itrFormLoc array p2*/
  getItr(financialInfoGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (financialInfoGrp.get('itrFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getItrLength(j?: any) {
    let count = 0;
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let itrFrmArray = itemListFrmArray.controls[j]?.get('itrFormLoc') as UntypedFormArray;
    let itrcount = itrFrmArray.value.filter((x: any) => x.fileLoc != "");
    return itrcount.length;
  }
  initItrFrmGrp() {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl('', []),
      fileName: new UntypedFormControl('', []),
      remarks: new UntypedFormControl(''),
    })
  }
  addItrDiv(j: any, i: any) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let itrFrmArray = itemListFrmArray.controls[j]?.get('itrFormLoc') as UntypedFormArray;
    if (itrFrmArray.length > 0) {
      let length = itrFrmArray.length
      while (i < 5 - length) {
        itrFrmArray.push(this.initItrFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        itrFrmArray.push(this.initItrFrmGrp());
        i++;
      }
    }
  }
  rmItrDiv(i: number, k: number) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let itrFrmArray = itemListFrmArray.controls[i].get('itrFormLoc') as UntypedFormArray;
    itrFrmArray.removeAt(k);
    this.rmvItrFormLocModalRef.close();
  }
  openItrPop(content: any, i: any) {
    this.rmvItrFormLocModalRef = this.modalService.open(content);
  }

  uploadItr(itemListIndex: any, judicialIndex: any) {
    this.isdocLocFile = true;
    this.isdocLocText = false;
    this.isDWdocLoc = false;
    this.isUPdocLoc = false;
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let itrFrmArray = itemListsArray.controls[itemListIndex].get('itrFormLoc') as UntypedFormArray;
    if (itrFrmArray.controls.length > 0) {
      for (let index = 0; index < itrFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = itrFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteItrCopyFile(itemListIndex: any, ItrIndex: any) {
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let itrFrmArray = itemListsArray.controls[itemListIndex].get('itrFormLoc') as UntypedFormArray;
    if (itrFrmArray.controls.length > 0) {
      for (let index = 0; index < itrFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = itrFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*itrFormLoc array */

  /*totIncomeCompLoc array p2*/
  getTotIncomeCompFrmGrp(financialInfoGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (financialInfoGrp.get('totIncomeCompLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getTotIncomeCompLength(j?: any) {
    let count = 0;
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let itrFrmArray = itemListFrmArray.controls[j]?.get('totIncomeCompLoc') as UntypedFormArray;
    let totIncomeCompCount = itrFrmArray.value.filter((x: any) => x.fileLoc != "");
    return totIncomeCompCount.length;
  }

  initTotIncomeCompFrmGrp() {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl('', []),
      // fileName: new FormControl('', [Validators.required]),
      remarks: new UntypedFormControl(''),
    })
  }
  addTotIncomeCompDiv(j: any, i: any) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let totIncomeCompFrmArray = itemListFrmArray.controls[j].get('totIncomeCompLoc') as UntypedFormArray;
    if (totIncomeCompFrmArray.length > 0) {
      let length = totIncomeCompFrmArray.length
      while (i < 5 - length) {
        totIncomeCompFrmArray.push(this.initTotIncomeCompFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        totIncomeCompFrmArray.push(this.initTotIncomeCompFrmGrp());
        i++;
      }
    }
  }
  rmtotIncomeCompDiv(i: number, k: number) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let totIncomeCompFrmArray = itemListFrmArray.controls[i].get('totIncomeCompLoc') as UntypedFormArray;
    totIncomeCompFrmArray.removeAt(k);
    this.rmvtotIncomeCompFrmArrayModalRef.close();
  }
  openTotIncomeCompPop(content: any, i: any) {
    this.rmvtotIncomeCompFrmArrayModalRef = this.modalService.open(content);
  }

  uploadtotIncomeComp(itemListIndex: any, judicialIndex: any) {
    this.isdocLocFile = true;
    this.isdocLocText = false;
    this.isDWdocLoc = false;
    this.isUPdocLoc = false;
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let totIncomeCompFrmArray = itemListsArray.controls[itemListIndex].get('totIncomeCompLoc') as UntypedFormArray;
    if (totIncomeCompFrmArray.controls.length > 0) {
      for (let index = 0; index < totIncomeCompFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = totIncomeCompFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteTotIncomeCopyFile(itemListIndex: any, TotIncomeCompIndex: any) {
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let totIncomeCompFrmArray = itemListsArray.controls[itemListIndex].get('totIncomeCompLoc') as UntypedFormArray;
    if (totIncomeCompFrmArray.controls.length > 0) {
      for (let index = 0; index < totIncomeCompFrmArray.controls.length; index++) {
        if (index === TotIncomeCompIndex) {
          let itemListuploadControl = totIncomeCompFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*totIncomeCompLoc array p2*/


  /*FinLoc array p2*/
  getFinLocFrmGrp(financialInfoGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (financialInfoGrp.get('finLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getFinLocFinLocLength(j?: any) {
    let count = 0;
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let itrFrmArray = itemListFrmArray.controls[j]?.get('finLoc') as UntypedFormArray;
    let finLocCount = itrFrmArray.value.filter((x: any) => x.fileLoc != "");
    return finLocCount.length;
  }
  initFinLocFrmGrp() {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl('', []),
      fileName: new UntypedFormControl('', []),
      remarks: new UntypedFormControl(''),
    })
  }
  addFinLocDiv(j: any, i: any) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let finFrmArray = itemListFrmArray.controls[j].get('finLoc') as UntypedFormArray;
    if (finFrmArray.length > 0) {
      let length = finFrmArray.length
      while (i < 5 - length) {
        finFrmArray.push(this.initFinLocFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        finFrmArray.push(this.initFinLocFrmGrp());
        i++;
      }
    }
  }
  rmFinLocDiv(i: number, k: number) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let finFrmArray = itemListFrmArray.controls[i].get('finLoc') as UntypedFormArray;
    finFrmArray.removeAt(k);
    this.rmvFinLocFrmArrayModalRef.close();
  }
  openFinLocPop(content: any, i: any) {
    this.rmvFinLocFrmArrayModalRef = this.modalService.open(content);
  }

  uploadFinLoc(itemListIndex: any, judicialIndex: any) {
    this.isdocLocFile = true;
    this.isdocLocText = false;
    this.isDWdocLoc = false;
    this.isUPdocLoc = false;
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let finFrmArray = itemListsArray.controls[itemListIndex].get('finLoc') as UntypedFormArray;
    if (finFrmArray.controls.length > 0) {
      for (let index = 0; index < finFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = finFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteFinIndexCopyFile(itemListIndex: any, finIndex: any) {
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let finFrmArray = itemListsArray.controls[itemListIndex].get('finLoc') as UntypedFormArray;
    if (finFrmArray.controls.length > 0) {
      for (let index = 0; index < finFrmArray.controls.length; index++) {
        if (index === finIndex) {
          let itemListuploadControl = finFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*FinLoc array p2*/

  /*StatAuditRep array p2*/
  getStatAuditRepFrmGrp(financialInfoGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (financialInfoGrp.get('statAuditRepLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getStatAuditRepLength(j?: any) {
    let count = 0;
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let itrFrmArray = itemListFrmArray.controls[j]?.get('statAuditRepLoc') as UntypedFormArray;
    let statAuditRepLCount = itrFrmArray.value.filter((x: any) => x.fileLoc != "");
    return statAuditRepLCount.length;
  }
  initStatAuditRepFrmGrp() {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl('', []),
      fileName: new UntypedFormControl('', []),
      remarks: new UntypedFormControl(''),
    })
  }
  addStatAuditRepDiv(j: any, i: any) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let statAuditRepFrmArray = itemListFrmArray.controls[j].get('statAuditRepLoc') as UntypedFormArray;
    if (statAuditRepFrmArray.length > 0) {
      let length = statAuditRepFrmArray.length
      while (i < 5 - length) {
        statAuditRepFrmArray.push(this.initStatAuditRepFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        statAuditRepFrmArray.push(this.initStatAuditRepFrmGrp());
        i++;
      }
    }
  }
  rmStatAuditRepDiv(i: number, k: number) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let statAuditRepFrmArray = itemListFrmArray.controls[i].get('statAuditRepLoc') as UntypedFormArray;
    statAuditRepFrmArray.removeAt(k);
    this.rmStatAuditRepFrmArrayModalRef.close();
  }
  openStatAuditRepPop(content: any, i: any) {
    this.rmStatAuditRepFrmArrayModalRef = this.modalService.open(content);
  }

  uploadStatAuditRep(itemListIndex: any, judicialIndex: any) {
    this.isdocLocFile = true;
    this.isdocLocText = false;
    this.isDWdocLoc = false;
    this.isUPdocLoc = false;
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let statAuditRepFrmArray = itemListsArray.controls[itemListIndex].get('statAuditRepLoc') as UntypedFormArray;
    if (statAuditRepFrmArray.controls.length > 0) {
      for (let index = 0; index < statAuditRepFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = statAuditRepFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteStatAuditRepCopyFile(itemListIndex: any, statAuditRepIndex: any) {
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let statAuditRepFrmArray = itemListsArray.controls[itemListIndex].get('statAuditRepLoc') as UntypedFormArray;
    if (statAuditRepFrmArray.controls.length > 0) {
      for (let index = 0; index < statAuditRepFrmArray.controls.length; index++) {
        if (index === statAuditRepIndex) {
          let itemListuploadControl = statAuditRepFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*StatAuditRep array p2*/


  /*taxAuditReportLoc array p2*/
  getTaxAuditReportFrmGrp(financialInfoGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (financialInfoGrp.get('taxAuditReportLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getTaxAuditRepLength(j?: any) {
    let count = 0;
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let itrFrmArray = itemListFrmArray.controls[j]?.get('taxAuditReportLoc') as UntypedFormArray;
    let taxAuditRepCount = itrFrmArray.value.filter((x: any) => x.fileLoc != "");
    return taxAuditRepCount.length;
  }
  initTaxAuditReportFrmGrp() {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl('', []),
      fileName: new UntypedFormControl('', []),
      remarks: new UntypedFormControl(''),
    })
  }
  addTaxAuditReportDiv(j: any, i: any) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let taxAuditReportFrmArray = itemListFrmArray.controls[j].get('taxAuditReportLoc') as UntypedFormArray;
    if (taxAuditReportFrmArray.length > 0) {
      let length = taxAuditReportFrmArray.length
      while (i < 5 - length) {
        taxAuditReportFrmArray.push(this.initTaxAuditReportFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        taxAuditReportFrmArray.push(this.initTaxAuditReportFrmGrp());
        i++;
      }
    }
  }
  rmTaxAuditReportRepDiv(i: number, k: number) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let taxAuditReportFrmArray = itemListFrmArray.controls[i].get('taxAuditReportLoc') as UntypedFormArray;
    taxAuditReportFrmArray.removeAt(k);
    this.rmTaxAuditRepFrmArrayModalRef.close();
  }
  openTaxAuditReportpPop(content: any, i: any) {
    this.rmTaxAuditRepFrmArrayModalRef = this.modalService.open(content);
  }

  uploadTaxAuditReportRep(itemListIndex: any, judicialIndex: any) {
    this.isdocLocFile = true;
    this.isdocLocText = false;
    this.isDWdocLoc = false;
    this.isUPdocLoc = false;
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let taxAuditReportFrmArray = itemListsArray.controls[itemListIndex].get('taxAuditReportLoc') as UntypedFormArray;
    if (taxAuditReportFrmArray.controls.length > 0) {
      for (let index = 0; index < taxAuditReportFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = taxAuditReportFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteTaxAuditReportFile(itemListIndex: any, taxAuditReportIndex: any) {
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let taxAuditReportFrmArray = itemListsArray.controls[itemListIndex].get('taxAuditReportLoc') as UntypedFormArray;
    if (taxAuditReportFrmArray.controls.length > 0) {
      for (let index = 0; index < taxAuditReportFrmArray.controls.length; index++) {
        if (index === taxAuditReportIndex) {
          let itemListuploadControl = taxAuditReportFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*taxAuditReportLoc array p2*/

  /*TransPricingAudRep array p2*/
  getTransPricingAudRepFrmGrp(financialInfoGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (financialInfoGrp.get('transPricingAudRepLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getTransPricingAudRepLength(j?: any) {
    let count = 0;
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let itrFrmArray = itemListFrmArray.controls[j]?.get('transPricingAudRepLoc') as UntypedFormArray;
    let transPricingAudRepCount = itrFrmArray.value.filter((x: any) => x.fileLoc != "");
    return transPricingAudRepCount.length;
  }
  initTransPricingAudRepFrmGrp() {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl('', []),
      fileName: new UntypedFormControl('', []),
      remarks: new UntypedFormControl(''),
    })
  }
  addTransPricingAudRepDiv(j: any, i: any) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let transPricingAudRepFrmArray = itemListFrmArray.controls[j].get('transPricingAudRepLoc') as UntypedFormArray;
    if (transPricingAudRepFrmArray.length > 0) {
      let length = transPricingAudRepFrmArray.length
      while (i < 5 - length) {
        transPricingAudRepFrmArray.push(this.initTransPricingAudRepFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        transPricingAudRepFrmArray.push(this.initTransPricingAudRepFrmGrp());
        i++;
      }
    }
  }
  rmTransPricingAudRepDiv(i: number, k: number) {
    let itemListFrmArray = this.financialInfoFormGroup.get('itemList') as UntypedFormArray;
    let transPricingAudRepFrmArray = itemListFrmArray.controls[i].get('transPricingAudRepLoc') as UntypedFormArray;
    transPricingAudRepFrmArray.removeAt(k);
    this.rmTransPricingAudRepFrmArrayModalRef.close();
  }
  openTransPricingAudRepPop(content: any, i: any) {
    this.rmTransPricingAudRepFrmArrayModalRef = this.modalService.open(content);
  }

  uploadTransPricingAudRep(itemListIndex: any, judicialIndex: any) {
    this.isdocLocFile = true;
    this.isdocLocText = false;
    this.isDWdocLoc = false;
    this.isUPdocLoc = false;
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let transPricingAudRepFrmArray = itemListsArray.controls[itemListIndex].get('transPricingAudRepLoc') as UntypedFormArray;
    if (transPricingAudRepFrmArray.controls.length > 0) {
      for (let index = 0; index < transPricingAudRepFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = transPricingAudRepFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deletetaxAuditReportFile(itemListIndex: any, taxAuditReportIndex: any) {
    let itemListsArray = this.financialInfoFormGroup.controls.itemList as UntypedFormGroup;
    let transPricingAudRepFrmArray = itemListsArray.controls[itemListIndex].get('transPricingAudRepLoc') as UntypedFormArray;
    if (transPricingAudRepFrmArray.controls.length > 0) {
      for (let index = 0; index < transPricingAudRepFrmArray.controls.length; index++) {
        if (index === taxAuditReportIndex) {
          let itemListuploadControl = transPricingAudRepFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*TransPricingAudRep array p2*/

  initializeData() {
    this.taxService.getFinancialInformation(this.navContext.pan).subscribe({
      next: (response: any) => {
        if (response.response === null) {
          this.isFinancialInformationCreated = false;
        } else {
          this.isFinancialInformationCreated = true;
          this.fiData = response.response;
          if (this.fiData.itemList.length > 0) {
            this.fiData.itemList.forEach((element: any) => {
              element.isActive = true;
            });
            this.initItemListData();
          } else {
            this.addRow();
          }
        }
      },
    });
  }

  initItemListData() {
    this.fiData.itemList.forEach((noticeData: any) => {
      let itemListGrp: UntypedFormGroup = this.initFinancialInfoGrp();
      (this.financialInfoFormGroup.get('itemList') as UntypedFormArray).push(itemListGrp);
      noticeData.itrFormLoc.forEach((issue: any) => {
        let ItrFrmGrp: UntypedFormGroup = this.initItrFrmGrp();
        (itemListGrp.get('itrFormLoc') as UntypedFormArray).push(ItrFrmGrp);
      });

      noticeData.totIncomeCompLoc.forEach((tot: any) => {
        let totIncomeFrmGrp: UntypedFormGroup = this.initTotIncomeCompFrmGrp();
        (itemListGrp.get('totIncomeCompLoc') as UntypedFormArray).push(totIncomeFrmGrp);
      });

      noticeData.finLoc.forEach((fin: any) => {
        let ItrFrmGrp: UntypedFormGroup = this.initFinLocFrmGrp();
        (itemListGrp.get('finLoc') as UntypedFormArray).push(ItrFrmGrp);
      });

      noticeData.statAuditRepLoc.forEach((stat: any) => {
        let ItrFrmGrp: UntypedFormGroup = this.initStatAuditRepFrmGrp();
        (itemListGrp.get('statAuditRepLoc') as UntypedFormArray).push(ItrFrmGrp);
      });

      noticeData.taxAuditReportLoc.forEach((taxAudit: any) => {
        let ItrFrmGrp: UntypedFormGroup = this.initTaxAuditReportFrmGrp();
        (itemListGrp.get('taxAuditReportLoc') as UntypedFormArray).push(ItrFrmGrp);
      });

      noticeData.transPricingAudRepLoc.forEach((trans: any) => {
        let ItrFrmGrp: UntypedFormGroup = this.initTransPricingAudRepFrmGrp();
        (itemListGrp.get('transPricingAudRepLoc') as UntypedFormArray).push(ItrFrmGrp);
      });
    });
    this.financialInfoFormGroup.patchValue(this.fiData);
  }


  /*fetch function */
  /*save function */
  // saveTaxPosData() {
  //   this.isSubmitted = true;
  //   let data: any = {};
  //   if (this.financialInfoFormGroup.valid) {
  //     data = this.financialInfoFormGroup.value;
  //    if (this.navContext.entityType == 'LEGAL' || this.navContext.entityType === 'Business') {
  //       this.pan = this.navContext.pan;
  //     }
  //     this.taxService.saveTaxPosData(data).subscribe((response: any) => {
  //       this.isSubmitted = false;
  //       if (response.status === 1) {
  //         if (data.itemList.length > 0) {
  //           data.itemList.forEach((e: any) => {
  //             e.isActive = true;
  //           })
  //         }
  //         this.toaster.showSuccess(response.message);

  //       } else {
  //         this.toaster.showSuccess(response.message);
  //       }
  //     });
  //   }

  // }
  /*save function */
  /*on issue selection display upload field */
  updateFinancialInformation(event: any) {
    this.isSubmitted = true;
    let data: any = {
      pan: this.getFinancialInformation()[0].controls.pan.value,
      panName: this.getFinancialInformation()[0].controls.panName.value,
      itemList: this.financialInfoFormGroup.value.itemList.map((x: any) => {
        delete x.pan
        delete x.panName
        return x
      })
    };
    var itemData: any = {
      ay: "",
      finLoc: [],
      itrFormLoc: [],
      statAuditRepLoc: [],
      taxAuditReportLoc: [],
      totIncomeCompLoc: [],
      transPricingAudRepLoc: []
    };

    var finlockdata = {
      fileLoc: "",
      fileName: "",
      remarks: ""
    }
    var itemListArr = [];

    for (var i = 0; i < data.itemList.length; i++) {
      itemData.ay = data.itemList[i].ay;
      for (var j = 0; j < data.itemList[i].finLoc.length; j++) {
        if (data.itemList[i].finLoc[j].fileLoc != null && data.itemList[i].finLoc[j].fileLoc != "" && data.itemList[i].finLoc[j].fileLoc != undefined) {
          finlockdata = {
            fileLoc: data.itemList[i].finLoc[j].fileLoc,
            fileName: data.itemList[i].finLoc[j].fileName,
            remarks: data.itemList[i].finLoc[j].remarks
          }
          itemData.finLoc.push(finlockdata);
        }
      }

      for (var j = 0; j < data.itemList[i].itrFormLoc.length; j++) {
        if (data.itemList[i].itrFormLoc[j].fileLoc != null && data.itemList[i].itrFormLoc[j].fileLoc != "" && data.itemList[i].itrFormLoc[j].fileLoc != undefined) {
          finlockdata = {
            fileLoc: data.itemList[i].itrFormLoc[j].fileLoc,
            fileName: data.itemList[i].itrFormLoc[j].fileName,
            remarks: data.itemList[i].itrFormLoc[j].remarks
          }
          itemData.itrFormLoc.push(finlockdata);
        }
      }

      for (var j = 0; j < data.itemList[i].statAuditRepLoc.length; j++) {
        if (data.itemList[i].statAuditRepLoc[j].fileLoc != null && data.itemList[i].statAuditRepLoc[j].fileLoc != "" && data.itemList[i].statAuditRepLoc[j].fileLoc != undefined) {
          finlockdata = {
            fileLoc: data.itemList[i].statAuditRepLoc[j].fileLoc,
            fileName: data.itemList[i].statAuditRepLoc[j].fileName,
            remarks: data.itemList[i].statAuditRepLoc[j].remarks
          }
          itemData.statAuditRepLoc.push(finlockdata);
        }
      }

      for (var j = 0; j < data.itemList[i].taxAuditReportLoc.length; j++) {
        if (data.itemList[i].taxAuditReportLoc[j].fileLoc != null && data.itemList[i].taxAuditReportLoc[j].fileLoc != "" && data.itemList[i].taxAuditReportLoc[j].fileLoc != undefined) {
          finlockdata = {
            fileLoc: data.itemList[i].taxAuditReportLoc[j].fileLoc,
            fileName: data.itemList[i].taxAuditReportLoc[j].fileName,
            remarks: data.itemList[i].taxAuditReportLoc[j].remarks
          }
          itemData.taxAuditReportLoc.push(finlockdata);
        }
      }

      for (var j = 0; j < data.itemList[i].totIncomeCompLoc.length; j++) {
        if (data.itemList[i].totIncomeCompLoc[j].fileLoc != null && data.itemList[i].totIncomeCompLoc[j].fileLoc != "" && data.itemList[i].totIncomeCompLoc[j].fileLoc != undefined) {
          finlockdata = {
            fileLoc: data.itemList[i].totIncomeCompLoc[j].fileLoc,
            fileName: data.itemList[i].totIncomeCompLoc[j].fileName,
            remarks: data.itemList[i].totIncomeCompLoc[j].remarks
          }
          itemData.totIncomeCompLoc.push(finlockdata);
        }
      }

      for (var j = 0; j < data.itemList[i].transPricingAudRepLoc.length; j++) {
        if (data.itemList[i].transPricingAudRepLoc[j].fileLoc != null && data.itemList[i].transPricingAudRepLoc[j].fileLoc != "" && data.itemList[i].transPricingAudRepLoc[j].fileLoc != undefined) {
          finlockdata = {
            fileLoc: data.itemList[i].transPricingAudRepLoc[j].fileLoc,
            fileName: data.itemList[i].transPricingAudRepLoc[j].fileName,
            remarks: data.itemList[i].transPricingAudRepLoc[j].remarks
          }
          itemData.transPricingAudRepLoc.push(finlockdata);
        }
      }
      itemListArr.push(itemData);
      itemData = {
        ay: "",
        finLoc: [],
        itrFormLoc: [],
        statAuditRepLoc: [],
        taxAuditReportLoc: [],
        totIncomeCompLoc: [],
        transPricingAudRepLoc: []
      };
    }
    // data = this.financialInfoFormGroup.value;
    let payLoad: any = {
      pan: this.getFinancialInformation()[0].controls.pan.value,
      panName: this.getFinancialInformation()[0].controls.panName.value,
      itemList: itemListArr
    };
    this.taxService.updateFinancialInformation(payLoad).subscribe((response: any) => {
      this.isSubmitted = false;
      if (response.status === 1) {
        this.toaster.showSuccess(response.message);

      } else {
        this.toaster.showSuccess(response.message);
      }
    });
  }
  /*on issue selection display upload field */


  issueDropdown() {
    this.dataSource = DT_ISSUE_DROPDOWN;
  }
  opentaxPop(content: any, i: any) {
    this.taxPopModalRef = this.modalService.open(content);
  }

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('itemList')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  // onSaveClick() {
  //   this.rmvjudicialModalRef.close(this.financialInfoFormGroup.value);
  // }




  // addRow() {
  //   const row = this.formBuilder.group({
  //     srNo: [this.itemListdata.length + 1],
  //     panName: [''],
  //     ay: [''],
  //     ITRForm:[''],
  //     ComputationTotalIncome:[''],
  //     Financials:[''],
  //     StatutoryAuditReport:[''],
  //     TransferPricingAuditReport:[''],
  //   });
  //   this.financialForm.push(row);
  // }
  // setFormData(value: any, common: any) {
  //   const attachmentFormGroup = this.formBuilder.group({
  //     srNo: [value.srNo || ''],
  //     panName: [common.panName || ''],
  //     ay: [value.ay || ''],
  //     ITRForm:[value.itrFormLoc||''],
  //     ComputationTotalIncome:[value.totIncomeCompLoc||''],
  //     Financials: [value.finLoc||''],
  //     StatutoryAuditReport: [value.statAuditRepLoc||''],
  //     TransferPricingAuditReport: [value.transPricingAudRepLoc||''],
  //   });
  //   this.financialForm.push(attachmentFormGroup);
  // }

}
