<!-- <span class="nav-data" *ngIf="caseData"><span class="text-data"> Last Updated By : </span>{{caseData.updatedBy}} &nbsp; <span  class="text-data"> On : </span> {{caseData.updatedOn}} </span> -->
<div class="row">
  <div class="col-md-4">
    <ul class="nav nav-tabs iris-gst-display-flex b1" role="tablist">
      <li class="nav-item">
        <a class="nav-link " id="adjudformGrp-tab" data-toggle="tab" (click)="tabChanged('tab1')"
          [ngClass]="(selectedTab === 'tab1') ? 'active': ''" role="tab" aria-controls="adjudformGrp-1"
          aria-selected="true">Phase 1</a>
      </li>
      <!-- <li class="nav-item"  > -->
      <li class="nav-item"
        [ngClass]="{'disabled': (!isPhase2 && !(action == 'update' && getAccessTabData.ajaFlag) || disablePhase2)}">
        <a class="nav-link" id="adjudp2-tab" data-toggle="tab" (click)="tabChanged('tab2')"
          [ngClass]="(selectedTab === 'tab2') ? 'active': ''" role="tab" aria-controls="adjudp2-1"
          aria-selected="false">Phase 2</a>
      </li>
      <li class="nav-item"
        [ngClass]="{'disabled': (!isPhase3  && !(action == 'update' && getAccessTabData.ajaFlag) || disablePhase3)}">
        <!-- <li class="nav-item" > -->
        <a class="nav-link" id="adjudp3-tab" data-toggle="tab" (click)="tabChanged('tab3')"
          [ngClass]="(selectedTab === 'tab3') ? 'active': ''" role="tab" aria-controls="adjudp3-1"
          aria-selected="false">Phase 3</a>
      </li>
    </ul>
  </div>
  <div class="col-md-4">
    <div class="textCenter pt11">
      <span class="infoVal fs16" *ngIf="isUpdate" title="Case ID">{{caseData.caseId}}</span>
    </div>
  </div>
  <div class="col-md-4">
    <span class="computationBtn" *ngIf="caseData">
      <span class="nav-data" *ngIf="caseData"><span class="text-data"> Last Updated By : </span>{{caseData.updatedBy}}
        &nbsp;
        <span class="text-data"> On : </span> {{caseData.updatedOn}} </span>
    </span>
  </div>
</div>


<div class="tab-content nopadding">
  <form [formGroup]="preAjaForm" (keydown.enter)="$event.preventDefault()">
    <div class="tab-pane fade show active" id="adjudformGrp-1" role="tabpanel" aria-labelledby="adjudformGrp-tab">
      <div class="card borderOrange" [hidden]="(selectedTab === 'tab2') || (selectedTab === 'tab3')"
        [ngClass]="{'bgGray': (action == 'view' || (action == 'update' && getAccessTabData.ajaFlag))} ">
        <div class="card-body">
          <fieldset [disabled]="action == 'view' || (action == 'update' && getAccessTabData.ajaFlag) ">

            <div class="row" *ngIf="caseType === 'REMANDED'">
              <span class="nav-item remanded-back" *ngIf="caseType === 'REMANDED'">
                Remanded Back Case
              </span>

              <!-- Whether the Issue/Case is remanded back? * -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleInputPassword1">From which forum case is remanded back? <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input *ngIf="isUpdate" type="text" class="form-control" id="remandedCaseId" placeholder="" readonly
                    [value]="getFormName()">
                  <select *ngIf="!isUpdate" class="form-control form-select" (change)="changeRemandedBackForum($event)"
                    formControlName="remandedBackForum"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.remandedBackForum.errors }">
                    <option [value]=''>-- Select --</option>
                    <ng-container *ngFor="let obj of preAdjType">
                      <option [value]="obj.key">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && formGrp.remandedBackForum.errors">
                    <span class="text-danger" *ngIf="formGrp.remandedBackForum.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <!-- Payment under remanded back -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleInputPassword1">ID of the case remanded back <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input *ngIf="isUpdate" class="form-control" type="text" readonly formControlName="remandedCaseId">
                  <select *ngIf="!isUpdate" class="form-control form-select" id="remandedCaseId"
                    (change)="setRemandBackData($event)" placeholder="" readonly formControlName="remandedCaseId"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.remandedCaseId.errors }"
                    (change)="selectedRemandedBackCaseId($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of remandedCaseObj">
                      <option [value]="obj.key">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && formGrp.remandedCaseId.errors">
                    <span class="text-danger" *ngIf="formGrp.remandedCaseId.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div data-toggle="collapse" data-target="#paymentUnderRemandedBack" class="expansion-div mb-15"
                (click)="paymentUnderRemandedBackExpansion = !paymentUnderRemandedBackExpansion">
                Details of Payment Made Before Remanded Back
                <span class="consultant-icons"> <i *ngIf="!paymentUnderRemandedBackExpansion" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="paymentUnderRemandedBackExpansion" class="fa fa-angle-up"
                    aria-hidden="true"></i></span>
              </div>
              <div id="paymentUnderRemandedBack" class="collapse">
                <!--Payment table  -->
                <div class="row mb-15">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleInputPassword1"> </label>
                      <div class=" row">
                        <div class="col-md-12">
                          <div class="search-table-outter wrapper">
                            <table class="search-table inner w-100 tablepop">
                              <thead>
                                <tr class="bgGred">
                                  <th class="w-25">Particulars</th>
                                  <th>Tax (₹)</th>
                                  <th>Interest (₹)</th>
                                  <th>Penalty (₹)</th>
                                  <th>Total (₹)</th>
                                </tr>

                              </thead>
                              <tbody>
                                <ng-container>
                                  <tr *ngFor="let payment of getREmandedBackData()" [formGroup]="payment">
                                    <td class=""><textarea class="label-cls w-100" type="text"
                                        formControlName="label"></textarea> </td>
                                    <td>
                                      <input type="text" class="form-control" id="tax" placeholder=""
                                        formControlName="tax" maxlength="18" currencyMask
                                        [options]="{ prefix: '', thousands: ',', precision: 0 }"
                                        (keypress)="remandedBackPaymentValueChange()"
                                        (keydown)="onKeyDownEvent($event, 'remandedPaymentMade')"
                                        (paste)="onPaste($event, 'tax','remandedPaymentMade',null,payment.value.label)"
                                        *ngIf="payment.value.label != 'Cumulative Consultant Charges'">
                                      <div *ngIf="payment.value.label == 'Cumulative Consultant Charges'">-</div>
                                    </td>
                                    <td>
                                      <input type="text" class="form-control" id="interest" placeholder=""
                                        formControlName="interest" maxlength="18" currencyMask
                                        [options]="{ prefix: '', thousands: ',', precision: 0 }"
                                        (keypress)="remandedBackPaymentValueChange()"
                                        (keydown)="onKeyDownEvent($event, 'remandedPaymentMade')"
                                        (paste)="onPaste($event, 'interest','remandedPaymentMade',null,payment.value.label)"
                                        *ngIf="payment.value.label != 'Cumulative Consultant Charges'">
                                      <div *ngIf="payment.value.label == 'Cumulative Consultant Charges'">-</div>
                                    </td>
                                    <td>
                                      <input type="text" class="form-control" id="penalty" placeholder=""
                                        formControlName="penalty" maxlength="18" currencyMask
                                        [options]="{ prefix: '', thousands: ',', precision: 0 }"
                                        (keypress)="remandedBackPaymentValueChange()"
                                        (keydown)="onKeyDownEvent($event, 'remandedPaymentMade')"
                                        (paste)="onPaste($event, 'penalty','remandedPaymentMade',null,payment.value.label)"
                                        *ngIf="payment.value.label != 'Cumulative Consultant Charges'">
                                      <div *ngIf="payment.value.label == 'Cumulative Consultant Charges'">-</div>
                                    </td>
                                    <td><input type="text" class="form-control" id="total1" placeholder=""
                                        formControlName="total" maxlength="18" currencyMask
                                        [options]="{ prefix: '', thousands: ',', precision: 0 }"
                                        (keypress)="remandedBackPaymentValueChange()"
                                        (paste)="onPaste($event, 'total','remandedPaymentMade',null,payment.value.label)"
                                        [disabled]="payment.value.label != 'Cumulative Consultant Charges'"></td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                </div>
                <!-- Payment table -->
              </div>

              <!-- pre deposit paid  -->
              <!-- <div class="col-md-3" >
                  <div class="form-group">
                    <label for="exampleInputPassword1">Pre deposit paid before remanded back <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                    <input type="text" class="form-control" id="predeposit" placeholder=""  formControlName="predeposit">
                  </div>
                </div> -->

              <!-- Payment made under protest -->
              <!-- <div class="col-md-6" >
                  <div class="form-group">
                    <label for="exampleInputPassword1">Payment made under protest before remanded back <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                    <input type="text" class="form-control" id="paymentUnderProtest" placeholder=""  formControlName="paymentUnderProtest">
                  </div>
                </div> -->

            </div>

            <div class="row">
              <div class="col-md-3" *ngIf="isUpdate">
                <div class="form-group">
                  <label for="exampleInputPassword1">Case ID <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="caseId" placeholder="" readonly formControlName="caseId"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.caseId.errors }">
                  <div *ngIf="isSubmitted && formGrp.caseId.errors">
                    <span class="text-danger" *ngIf="formGrp.caseId.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 disabledSelect" *ngIf="isActdisable">
                <div class="form-group">
                  <label for="exampleInputPassword1">Type of Act <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" formControlName="actType" (change)="changeTax($event)"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.actType.errors, 'disabled': disabledOnload }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of actTypes">
                      <option [value]="obj">{{obj}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && formGrp.actType.errors">
                    <span class="text-danger" *ngIf="formGrp.actType.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3 disabledSelect" *ngIf="!isActdisable">
                <div class="form-group">
                  <label for="exampleInputPassword1">Type of Act <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" formControlName="actType" (change)="changeTax($event)"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.actType.errors, 'disabled': disabledOnload }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of actTypes">
                      <option [value]="obj.value">{{obj.key}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && formGrp.actType.errors">
                    <span class="text-danger" *ngIf="formGrp.actType.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3 disabledSelect">
                <div class="form-group">
                  <label for="exampleInputPassword1">State <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" formControlName="state" (change)="changeState($event)"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.state.errors, 'disabled': disabledOnload  }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of actStateList">
                      <option [value]="obj">{{obj | uppercase}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && formGrp.state.errors">
                    <span class="text-danger" *ngIf="formGrp.state.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 disabledSelect">
                <div class="form-group">
                  <label for="exampleInputPassword1">Registration number <span class="mandate"><i
                        class="fa fa-asterisk"></i></span><a class="clrOrange infoIcon"
                      title="To add Pre Gst registrations go to Setting >> + Pre - GST"><i
                        class="fa fa-info-circle"></i></a></label>
                  <select class="form-control form-select" formControlName="regNo"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.state.errors, 'disabled': disabledOnload  }">
                    <ng-container *ngFor="let obj of regNumRes">
                      <option [value]="obj.regNo">{{obj.regNo}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && formGrp.regNo.errors">
                    <span class="text-danger" *ngIf="formGrp.regNo.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3 disabledSelect">
                <div class="form-group">
                  <label for="exampleInputPassword1">Mode of receipt of notice</label>
                  <select class="form-control form-select" formControlName="orderReceiptMode1">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of modeOfAppealOptions">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">DIN</label>
                  <input type="text" class="form-control" id="din1" placeholder="" formControlName="din1">
                  <!-- <div *ngIf="isSubmitted && formGrp.din.errors">
                      <span class="text-danger" *ngIf="formGrp.din.errors.required"> Required</span>
                    </div> -->
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Notice Number<span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="noticeNo"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.noticeNo.errors }">
                  <div *ngIf="isSubmitted && formGrp.noticeNo.errors">
                    <span class="text-danger" *ngIf="formGrp.noticeNo.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of Notice <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="noticeDate"
                      [ngClass]="{ 'errorBorder': isSubmitted && formGrp.noticeDate.errors }" [maxDate]="maxDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && formGrp.noticeDate.errors">
                    <span class="text-danger" *ngIf="formGrp.noticeDate.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of receipt of Notice <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" [maxDate]="maxDate" formControlName="noticeReceiptDate"
                      [ngClass]="{ 'errorBorder': isSubmitted && formGrp.noticeReceiptDate.errors }"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && formGrp.noticeReceiptDate.errors">
                    <span class="text-danger" *ngIf="formGrp.noticeReceiptDate.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Due date to reply <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dueDateOfReply"
                      [ngClass]="{ 'errorBorder': isSubmitted && formGrp.dueDateOfReply.errors }"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && formGrp.dueDateOfReply.errors">
                    <span class="text-danger" *ngIf="formGrp.dueDateOfReply.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Internal due date </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="internalDueDate" [monthNavigator]="true"
                      [yearNavigator]="true" yearRange="2000:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                      dataType="string">
                    </p-calendar>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Period From <span class="mandate"><i
                        class="fa fa-asterisk"></i></span> </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="periodFromDate"
                      [ngClass]="{ 'errorBorder': isSubmitted && formGrp.periodFromDate.errors }" [maxDate]="maxDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && formGrp.periodFromDate.errors">
                    <span class="text-danger" *ngIf="formGrp.periodFromDate.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Period To <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="periodToDate" [maxDate]="maxDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && formGrp.periodToDate.errors }"
                      (onSelect)="getperiodToValue(periodToPop)"></p-calendar>
                  </div>
                </div>
                <div *ngIf="isSubmitted && formGrp.periodToDate.errors">
                  <span class="text-danger" *ngIf="formGrp.periodToDate.errors.required"> Required</span>
                </div>
                <ng-template #periodToPop let-modal>
                  <div class="modal-header" style="border: none; padding-bottom: 15px; padding-top:9px" >
                    <button type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                    </button>
                  </div>
                  <div class="col-md-12" >
                    <div class="table-responsive">
                      <p class="title-data">Following cases are already exist for same selected period, <span style="font-weight: bold;">Do you want to still proceed?</span></p>
                      <table style="margin-left: 5px;">
                        <thead>
                          <tr class="bg-primary text-dblue">
                            <th class="sl-no-cla">Sr No</th>
                            <th class="clas">Case id</th>
                            <th>Case label</th>
                            <th class="clas">Financial Year</th>
                            <th class="clas">Period From</th>
                            <th class="clas">Period To </th>
                            <th>Forum</th>
                            <th>ACT</th>
                            <th class="ac-no-clas">Total Demand(₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let obj of duplicateData  let i = index">
                            <td class="sl-no-cla">{{i + 1}}</td>
                            <td class="clas">{{obj.caseId}}</td>
                            <td>{{obj.caseLabel}}</td>
                            <td class="clas">{{obj.fy}}</td>
                            <td class="clas">{{obj.periodFromDate}}</td>
                            <td class="clas">{{obj.periodToDate}}</td>
                            <td>{{obj.forum}}</td>
                            <td>{{obj.actyType}}</td>
                            <td class="ac-no-clas" style="text-align: right;">{{obj.demandAmount | INR: 0}} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="modal-footer" style="border: none; padding-top: 5px;" >
                    <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                    (click)="modal.close('No click')">Yes</button>
                      <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                      (click)="goToSummary()">No</button>
                  </div>
                </ng-template>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Consultant</label>
                  <input type="text" class="form-control" id="" placeholder="" formControlName="consultant1">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Person responsible <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>

                  <div *ngIf="isToggleDropdown && action==='update' || action==='view'" class="input-group">
                    <input class="form-control" type="text" formControlName="personResponsible1" readonly>
                    <button *ngIf="isToggleDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleDropdown()">
                      <i class="fa fa-pencil"></i>
                    </button>
                  </div>

                  <select *ngIf="!isToggleDropdown || action==='create'" class="form-control form-select" id="personResponsible1" formControlName="personResponsible1" (change)="onClickToggleDropdown()"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.personResponsible1.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of userRoleLs">
                      <option [value]="obj.username">{{obj.username}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && formGrp.personResponsible1.errors">
                    <span class="text-danger" *ngIf="formGrp.personResponsible1.errors.required"> Required
                    </span>
                  </div>
                </div>
              </div>


              <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Type of Authority <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                    <select class="form-control form-select" id="typeOfAuthority" formControlName="typeOfAuthority" >
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of typeOfAuthorityDropdown">
                        <option [value]="obj.value">{{obj.key}}</option>
                      </ng-container>
                    </select>                     -->
              <!-- <div *ngIf="isSubmitted && formGrp.personResponsible1.errors">
                      <span class="text-danger" *ngIf="formGrp.personResponsible1.errors.required"> Required
                      </span>
                    </div> -->
              <!-- </div>
                </div> -->

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Extension of time granted</label>
                  <select class="form-control form-select" formControlName="isExtensionGranted">
                    <ng-container *ngFor="let obj of yesNoDropdown">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                  <!-- <div *ngIf="isSubmitted && formGrp.din.errors">
                      <span class="text-danger" *ngIf="formGrp.din.errors.required"> Required</span>
                    </div> -->
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Extension of time granted in days</label>
                  <input type="text" class="form-control" id="extensionDays" placeholder=""
                    formControlName="extensionDays" maxlength="3"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Extended period of limitation invoked or not ?</label>
                  <select class="form-control form-select" formControlName="extPeriodOfLimitationInvoked">
                    <ng-container *ngFor="let obj of yesNoDropdown">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-md-3" *ngIf="isCustomsDuty">
                <div class="form-group">
                  <label for="exampleInputPassword1">Port code</label>
                  <input type="text" class="form-control uppercase" id="portCode" placeholder=""
                    formControlName="portCode" maxlength="6">
                </div>
              </div>

              <div class="col-md-3" *ngIf="isCustomsDuty">
                <div class="form-group">
                  <label for="exampleInputPassword1">Port Name</label>
                  <input type="text" class="form-control" id="portName" placeholder=""
                  formControlName="portName">
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Case Label <span class="mandate"><i
                        class="fa fa-asterisk"></i></span><a class="clrOrange infoIcon"
                      title="Please give a one line description to identify the case."><i class="fa fa-info-circle"
                        style="margin-left:3px;"></i></a></label>
                  <textarea class="form-control" id="exampleTextarea1" rows="4" formControlName="caseLabel"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.caseLabel.errors }"></textarea>
                </div>
                <div *ngIf="isSubmitted && formGrp.caseLabel.errors">
                  <span class="text-danger" *ngIf="formGrp.caseLabel.errors.required"> Required
                  </span>
                  <p class="text-danger" *ngIf="preAjaForm.controls['caseLabel'].hasError('minlength')">
                    Please enter Min 5 Char & Max 100 Char.
                  </p>
                  <p class="text-danger" *ngIf="preAjaForm.controls['caseLabel'].hasError('maxlength')">
                    Please enter Min 5 Char & Max 100 Char.
                  </p>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Issue Wise Demand<a class=" addExtLetter"
                      (click)="addIssueInv()"><i class="fa fa-plus"></i></a> </label>
                  <div class=" row">
                    <div class="col-md-12">
                      <div class="search-table-outter wrapper">
                        <table class="search-table inner ">
                          <thead>
                            <tr class="bgGred">
                              <th class="sl-no-cla" *ngIf="isUpdateBtn">Info</th>
                              <th>Issue <span class="mandate top7"><i class="fa fa-asterisk"></i></span></th>
                              <th>Division</th>
                              <th>Keyword <a class="clrOrange infoIcon"
                                  title="Maximum Length Limit is 150 Characters."><i class="fa fa-info-circle"></i></a>
                              </th>
                              <th>Risk Parameter <span class="mandate top7"><i class="fa fa-asterisk"></i></span></th>
                              <th *ngIf="isExcise">Excise Duty (₹)</th>
                              <th *ngIf="isExcise">EC (₹)</th>
                              <th *ngIf="isExcise">SHEC(₹)</th>
                              <th *ngIf="isServiceTax">Service Tax (₹)</th>
                              <th *ngIf="isServiceTax">EC (₹)</th>
                              <th *ngIf="isServiceTax">SHEC (₹)</th>
                              <th *ngIf="isServiceTax">KKC (₹)</th>
                              <th *ngIf="isServiceTax">SBC (₹)</th>
                              <th *ngIf="isVat">VAT (₹)</th>
                              <th *ngIf="isCst">CST (₹)</th>
                              <th *ngIf="isCustomsDuty">BCD (₹)</th>
                              <th *ngIf="isCustomsDuty">SAD (₹)</th>
                              <th *ngIf="isCustomsDuty">Anti-Dumping (₹)</th>
                              <th *ngIf="isCustomsDuty">IGST (₹)</th>
                              <th *ngIf="isEntryTax">Tax (₹)</th>
                              <th>Interest (₹) </th>
                              <th>Penalty (₹) </th>
                              <th>Total (₹) </th>
                              <th>Remark</th>
                              <th>Add Provision Details </th>
                              <th>Remove</th>
                            </tr>

                          </thead>
                          <tbody>
                            <ng-container>
                              <tr *ngFor="let div of issuInvArr(); let i=index" [formGroup]="div"
                                (keydown.enter)="$event.preventDefault()">
                                <td  class="sl-no-cla" *ngIf="isUpdateBtn">
                                  <p (click)="getissueData(issuePop,i)" class="pr5"><span
                                    class="badge-clo" title="Cases with similar issue"><i class="fa fa-info-circle"></i></span>
                                  </p>
                              </td>
                                <td class="w250">
                                  <select class="form-control form-select" formControlName="issue"
                                    [title]="div.value.issue"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }">
                                    <option [value]="null" disabled>-- Select --</option>
                                    <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                      <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                        {{item.name}}</option>
                                    </optgroup>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('issue')?.errors">
                                    <span class="text-danger" *ngIf="div.get('issue')?.errors?.required "> Required
                                    </span>
                                  </div>
                                </td>
                                <!-- Division -->
                                <td>
                                  <select class="form-control form-select" id="status" formControlName="div"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('div')?.errors  }">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of preGstinDivList">
                                      <option [value]="obj">{{obj}}</option>
                                    </ng-container>
                                  </select>

                                </td>

                                <td><input type="text" class="form-control" id="tax" placeholder=""
                                    formControlName="keyword" maxlength="150" [title]="div.value.keyword"></td>
                                <td>
                                  <select class="form-control form-select" id="status" formControlName="riskParam"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('riskParam')?.errors  }">
                                    <ng-container *ngFor="let obj of riskParam">
                                      <option [value]="obj.value">{{obj.value}}</option>
                                    </ng-container>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('riskParam')?.errors">
                                    <span class="text-danger" *ngIf="div.get('riskParam')?.errors?.required "> Required
                                    </span>
                                  </div>
                                </td>
                                <td *ngIf="isExcise"><input type="text" class="form-control align-right"
                                    formControlName="exciseDuty1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isExcise"><input type="text" class="form-control align-right"
                                    formControlName="ec1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isExcise"><input type="text" class="form-control align-right"
                                    formControlName="shec1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control align-right"
                                    formControlName="serviceTax1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control align-right"
                                    formControlName="ec1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control align-right"
                                    formControlName="shec1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control align-right"
                                    formControlName="kkc1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control align-right"
                                    formControlName="sbc1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isVat"><input type="text" class="form-control align-right"
                                    formControlName="vat1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isCst"><input type="text" class="form-control align-right"
                                    formControlName="cst1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control align-right"
                                    formControlName="bcd1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                               <td *ngIf="isCustomsDuty"><input type="text" class="form-control align-right"
                                      formControlName="sad1" id="tax" placeholder="" maxlength="19" InrFormat
                                      (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                               <td *ngIf="isCustomsDuty"><input type="text" class="form-control align-right"
                                        formControlName="antiDumpingDuty1" id="tax" placeholder="" maxlength="19" InrFormat
                                        (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                               <td *ngIf="isCustomsDuty"><input type="text" class="form-control align-right"
                                          formControlName="igst1" id="tax" placeholder="" maxlength="19" InrFormat
                                          (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isEntryTax"><input type="text" class="form-control align-right"
                                    formControlName="ent1" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td><input type="text" class="form-control align-right" formControlName="interest1"
                                    id="tax" placeholder="" maxlength="19" InrFormat (input)="issueValueChange()"
                                    (paste)="handlePaste($event)"></td>
                                <td><input type="text" class="form-control align-right" formControlName="penalty1"
                                    id="tax" placeholder="" maxlength="19" InrFormat (input)="issueValueChange()"
                                    (paste)="handlePaste($event)"></td>
                                <td><input type="text" class="form-control align-right" formControlName="total1"
                                    id="tax" readonly placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control" id="remark1" placeholder=""
                                    formControlName="remark1" [title]="div.value.remark1 ? div.value.remark1:''">
                                  <div *ngIf="div.value.remark1 && div.value.remark1.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                                <td><span (click)="openDetailPop(detailPop, i)"><i
                                      class="icon  fa fa-file-text-o"></i></span></td>
                                <td><a class=" addExtLetter" (click)="openRmvIssuePop(removeIssuePopup, i)"><i
                                      class="fa fa-minus"></i></a></td>

                                <ng-template #removeIssuePopup let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p>Are you sure, you want to remove?</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="rmIssueInv(i)">Remove</button>
                                  </div>
                                </ng-template>
                                <ng-template #detailPop let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Details</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p><a class="pl5 addExtLetter" title="Add More Detail" (click)="addDetail(i)"><i
                                          class="fa fa-plus"></i> Add another row to update the next quarter provision
                                        entry</a></p>
                                    <div class="table-responsive autoScroll">
                                      <table id="" class="table table-bordered">
                                        <thead>
                                          <tr class="bg-primary text-dblue">
                                            <th>Financial Year </th>
                                            <th>Quarter </th>
                                            <th>Provision (₹)</th>
                                            <th>Contingent Liability (₹)</th>
                                            <th>Other Liability (₹)</th>
                                            <th>Reason for change in the Provision</th>
                                            <th>Reason for provision not made, fully or partly made</th>
                                            <th>Reason for Cont. Liab.</th>
                                            <th>Reason for disclosure in Other Liability</th>
                                            <th>Consider in CARO Report</th>
                                            <th>Amount of CARO (₹)</th>
                                            <th>Remove</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <ng-container>
                                            <tr *ngFor="let detailsGrp of getdetails(div); let k=index"
                                              [formGroup]="detailsGrp" (keydown.enter)="$event.preventDefault()">
                                              <td>
                                                <select class="form-control form-select" id="gstin"
                                                  formControlName="finYear">
                                                  <option [value]="null">-- Select --</option>
                                                  <ng-container *ngFor="let obj of finyearData">
                                                    <option [value]="obj.year">{{obj.year}}</option>
                                                  </ng-container>
                                                </select>
                                              </td>
                                              <td>
                                                <select class="form-control form-select" id="gstin"
                                                  formControlName="quarter">
                                                  <option [value]="null">-- Select --</option>
                                                  <ng-container *ngFor="let obj of quarterList">
                                                    <option [value]="obj.value">{{obj.value}}</option>
                                                  </ng-container>
                                                </select>
                                              </td>
                                              <td><input type="text" class="form-control align-right"
                                                  formControlName="provisionAmt1" id="tax" placeholder="" maxlength="19"
                                                  InrFormat></td>
                                              <td><input type="text" class="form-control align-right"
                                                  formControlName="contLiabilityAmt1" id="tax" placeholder=""
                                                  maxlength="19" InrFormat></td>
                                              <td><input type="text" class="form-control align-right"
                                                  formControlName="otherLiability1" id="tax" placeholder=""
                                                  maxlength="19" InrFormat></td>
                                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.provisionChangeReason1.value"
                                                  formControlName="provisionChangeReason1"
                                                  (click)="openTextBoxModal('Reason for change in the Provision', 'provisionChangeReason1',div,k)">
                                              </td>
                                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.provisionNotMadeReason1.value"
                                                  formControlName="provisionNotMadeReason1"
                                                  (click)="openTextBoxModal('Reason for provision not made, fully or partly made', 'provisionNotMadeReason1',div,k)">
                                              </td>
                                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.contLiabReason1.value"
                                                  formControlName="contLiabReason1"
                                                  (click)="openTextBoxModal('Reason for Cont. Liab.', 'contLiabReason1',div,k)">
                                              </td>
                                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.otherLiabReason1.value"
                                                  formControlName="otherLiabReason1"
                                                  (click)="openTextBoxModal('Reason for disclosure in Other Liability', 'otherLiabReason1',div,k)">
                                              </td>

                                              <td>
                                                <select class="form-control form-select" id="gstin"
                                                  formControlName="considerInCaroReport1">
                                                  <option [value]="null">-- Select --</option>
                                                  <ng-container *ngFor="let obj of yesNoList">
                                                    <option [value]="obj.value">{{obj.label}}</option>
                                                  </ng-container>
                                                </select>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control align-right "
                                                  formControlName="caroAmt1" id="tax" placeholder="" maxlength="19"
                                                  InrFormat>
                                                <div class="error-msg"
                                                  *ngIf="detailsGrp.value.considerInCaroReport1 =='true' && ((detailsGrp.value.caroAmt1 === null) || (detailsGrp.value.caroAmt1 == 0))">
                                                  If "Consider in CARO Report" is Yes then Amount of Caro column cannot
                                                  be zero.</div>
                                                <div class="error-msg"
                                                  *ngIf="(detailsGrp.value.considerInCaroReport1 == 'false') && (detailsGrp.value.caroAmt1 && (detailsGrp.value.caroAmt1 != 0))">
                                                  If "Consider in CARO Report" is No then Amount of Caro should be zero.
                                                </div>
                                              </td>
                                              <td><a class="addExtLetter"
                                                  (click)="openRmvDetailPop(removeDetailPopup, i, k)"><i
                                                    class="fa fa-minus"></i></a></td>
                                              <ng-template #removeDetailPopup let-modal>
                                                <div class="modal-header">
                                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                                  <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">×</span>
                                                  </button>
                                                </div>
                                                <div class="modal-body">
                                                  <p>Are you sure, you want to remove?</p>
                                                </div>
                                                <div class="modal-footer">
                                                  <button type="button" class="btn btn-outline-dark"
                                                    (click)="rmDetail(i, k)">Remove</button>
                                                </div>
                                              </ng-template>

                                            </tr>
                                          </ng-container>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>

                                <ng-template #issuePop let-modal>
                                  <div class="modal-header">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12" >
                                    <div class="table-responsive">
                                      <p class="title-data">For your reference following latest 15 cases are exist in LMS with selected issue: <span style="font-weight: bold;">{{div.value.issue}}</span></p>
                                      <div class="btn btn-outline-success btn-download btnRep" (click)="exportAsXLSX()" >
                                        <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
                                      </div>
                                      <table *ngIf="this.hideData">
                                        <thead >
                                          <tr class="bgGred1">
                                            <th class="sl-no-cla">Sr No</th>
                                            <th><span>PAN</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer" 
                                                  aria-hidden="true" *ngIf="!sorting.panName"
                                                  (click)="SortingIssue(issueSorting.PAN)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.panName"
                                                  (click)="SortingIssue(issueSorting.PAN)"></i>
                                            </th>
                                            <th><span>Case ID</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.caseId"
                                                  (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.caseId"
                                                  (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                          </th>
                                            <th class="clas"><span>Case Label</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.caseLabel"
                                                  (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.caseLabel"
                                                  (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                            </th>
                                            <th ><span>State</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="!sorting.state"
                                                  (click)="SortingIssue(issueSorting.STATE)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="sorting.state"
                                                  (click)="SortingIssue(issueSorting.STATE)"></i>
                                          </th>
                                          <th ><span>ACT</span>&nbsp;
                                            <i class="fa fa-arrow-up cursor-pointer"
                                                aria-hidden="true"
                                                *ngIf="!sorting.actyType"
                                                (click)="SortingIssue(issueSorting.ACT)"></i>
                                            <i class="fa fa-arrow-down cursor-pointer"
                                                aria-hidden="true"
                                                *ngIf="sorting.actyType"
                                                (click)="SortingIssue(issueSorting.ACT)"></i>
                                        </th>
                                        <th ><span>Registration number</span>&nbsp;
                                          <i class="fa fa-arrow-up cursor-pointer"
                                              aria-hidden="true"
                                              *ngIf="!sorting.regNo"
                                              (click)="SortingIssue(issueSorting.RGENO)"></i>
                                          <i class="fa fa-arrow-down cursor-pointer"
                                              aria-hidden="true"
                                              *ngIf="sorting.regNo"
                                              (click)="SortingIssue(issueSorting.RGENO)"></i>
                                      </th>
                                            <th ><span>Period From</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="!sorting.periodFromDate"
                                                  (click)="SortingIssue(issueSorting.PERIOD_FROM)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="sorting.periodFromDate"
                                                  (click)="SortingIssue(issueSorting.PERIOD_FROM)"></i>
                                          </th>
                                          <th ><span>Period To</span>&nbsp;
                                            <i class="fa fa-arrow-up cursor-pointer"
                                                aria-hidden="true"
                                                *ngIf="!sorting.periodToDate"
                                                (click)="SortingIssue(issueSorting.PERIOD_TO)"></i>
                                            <i class="fa fa-arrow-down cursor-pointer"
                                                aria-hidden="true"
                                                *ngIf="sorting.periodToDate"
                                                (click)="SortingIssue(issueSorting.PERIOD_TO)"></i>
                                        </th>
                                            <th><span>Person Responsible</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="!sorting.personResponsible"
                                                  (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="sorting.personResponsible"
                                                  (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                          </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            *ngFor="let obj of issueData  let i = index">
                                            <td class="sl-no-cla">{{i + 1}}</td>
                                            <td >{{obj.panName}}</td>
                                            <td >{{obj.caseId}}</td>
                                            <td class="clas">{{obj.caseLabel}}</td>
                                            <td>{{obj.state}}</td>
                                            <td>{{obj.actyType}}</td>
                                            <td>{{obj.regNo}}</td>
                                            <td >{{obj.periodFromDate}}</td>
                                            <td >{{obj.periodToDate}}</td>
                                            <td>{{obj.personResponsible}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div *ngIf="!this.hideData" style="font-size: large;text-align: center;font-weight: bold;">
                                        No data found.
                                      </div>
                                      
                                    </div>
                                  </div>
                                </ng-template>

                              </tr>
                            </ng-container>
                            <ng-container>
                              <tr>
                                <th colspan="5" *ngIf="isUpdateBtn">Total</th>
                                <th colspan="4"  *ngIf="!isUpdateBtn">Total</th>
                                <td *ngIf="isExcise"><input type="text" class="form-control bold-font align-right"
                                    formControlName="exciseDuty1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isExcise"><input type="text" class="form-control bold-font align-right"
                                    formControlName="ec1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isExcise"><input type="text" class="form-control bold-font align-right"
                                    formControlName="shec1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="serviceTax1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="ec1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="shec1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="kkc1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="sbc1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isVat"><input type="text" class="form-control bold-font align-right"
                                    formControlName="vat1Total" id="vat" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isCst"><input type="text" class="form-control bold-font align-right"
                                    formControlName="cst1Total" id="cst" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control bold-font align-right"
                                    formControlName="bcd1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control bold-font align-right"
                                    formControlName="sad1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                      readonly></td>
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control bold-font align-right"
                                        formControlName="antiDumpingDuty1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                        readonly></td>
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control bold-font align-right"
                                          formControlName="igst1Total" id="tax" placeholder="" InrFormat maxlength="19"
                                          readonly></td>
                                <td *ngIf="isEntryTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="ent1Total" id="tax" InrFormat maxlength="19" readonly></td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="interest1Total" InrFormat maxlength="19" placeholder="" readonly>
                                </td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="penalty1Total" InrFormat maxlength="19" placeholder="" readonly>
                                </td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="totOfTotal1" InrFormat maxlength="19" readonly></td>
                                <td colspan="9">&nbsp;</td>
                              </tr>
                            </ng-container>

                          </tbody>
                        </table>
                      </div>
                    </div>


                  </div>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Upload Notice<span class="mandate"><i
                        class="fa fa-asterisk"></i></span>
                  </label>
                  <input type="file" class="form-control file-upload-default" #file>
                  <div class="attachment-block" [ngClass]="{'errorBorder': isSubmitted && getNoticeLocLength() === 0 }">
                    <span (click)="openNoticeLocModel(noticeCopyLocPop)" style="margin-left: 5px;">
                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span><span
                      *ngIf="getNoticeLocLength() != 0"> {{getNoticeLocLength()}} Attachment</span>
                  </div>
                  <div *ngIf="isSubmitted && getNoticeLocLength() === 0">
                    <span class="text-danger"> Required</span>
                  </div>
                  <div>
                    <ng-template #noticeCopyLocPop let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment</label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                                <th style="width:50%;">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let noticeLocGrp of getNoticeLoc(); let i = index" [formGroup]="noticeLocGrp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="noticeLocGrp.value.isdocLocUploadedClicked" type="file"
                                          id="noticeLoc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                          (change)="onFileSelect($event)">
                                        <p *ngIf="!noticeLocGrp.value.isdocLocUploadedClicked || noticeLocGrp.value.fileLoc"
                                          class="form-control pt10"> {{getFileNameDta(noticeLocGrp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="noticeLocGrp.value.fileLoc" title="Download file"
                                          (click)="download(noticeLocGrp.value.fileLoc)"><i
                                            class="fa fa-download"></i></a>
                                        <a class="downupIcon"
                                          *ngIf="!(noticeLocGrp.value.fileLoc || noticeLocGrp.value.fileLoc) && isDisableIcons"
                                          title="Upload file" (click)="uploadNoticeLoc(i)"><i
                                            class="fa fa-upload"></i></a>
                                        <a class="downupIcon" (click)="deleteNoticeLoc(i)"
                                          *ngIf="(noticeLocGrp.value.fileLoc) && isDisableIcons"><i class="fa fa-trash"
                                            aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input type="text" class="form-control" formControlName="remarks"
                                    [title]="noticeLocGrp.value.remarks ? noticeLocGrp.value.remarks:''">
                                  <div *ngIf="noticeLocGrp.value.remarks && noticeLocGrp.value.remarks.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Attachments</label>
                  <input type="file" class="form-control file-upload-default" #file>
                  <div class="attachment-block">
                    <span (click)="openAttachmentsLocModel(attachmentLocPop)" style="margin-left: 5px;">
                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span><span
                      *ngIf="getAttachmentsLocLength() != 0"> {{getAttachmentsLocLength()}} Attachment</span>
                  </div>
                  <div>
                    <ng-template #attachmentLocPop let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment</label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                                <th style="width:50%;">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let attachLocGrp of getAttachmentsLoc(); let i = index"
                                [formGroup]="attachLocGrp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="attachLocGrp.value.isdocLocUploadedClicked" type="file"
                                          id="attachmentsLoc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                          (change)="onFileSelect($event)">
                                        <p *ngIf="!attachLocGrp.value.isdocLocUploadedClicked || attachLocGrp.value.fileLoc"
                                          class="form-control pt10"> {{getFileNameDta(attachLocGrp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="attachLocGrp.value.fileLoc" title="Download file"
                                          (click)="download(attachLocGrp.value.fileLoc)"><i
                                            class="fa fa-download"></i></a>
                                        <a class="downupIcon"
                                          *ngIf="!(attachLocGrp.value.fileLoc || attachLocGrp.value.fileLoc) && isDisableIcons"
                                          title="Upload file" (click)="uploadOtherAttach(i)"><i
                                            class="fa fa-upload"></i></a>
                                        <a class="downupIcon" (click)="deleteOtherAttach(i)"
                                          *ngIf="(attachLocGrp.value.fileLoc) && isDisableIcons"><i class="fa fa-trash"
                                            aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input type="text" class="form-control" formControlName="remarks"
                                    [title]="attachLocGrp.value.remarks ? attachLocGrp.value.remarks:''">
                                  <div *ngIf="attachLocGrp.value.remarks && attachLocGrp.value.remarks.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-md-12 nopadding">
                <div class="form-group">
                  <label for="exampleInputPassword1">Case Summary </label>
                  <textarea class="form-control" id="exampleTextarea1" rows="4"
                    formControlName="caseSummary"></textarea>
                </div>
              </div>
              <div class="col-md-12 nopadding">
                <div class="form-group">
                  <label for="exampleInputPassword1">Internal Remarks </label>
                  <textarea class="form-control" id="exampleTextarea1" rows="4"
                    formControlName="internalRemarks"></textarea>
                </div>
              </div>

              <!--Details of Issuing Authority   -->
              <div data-toggle="collapse" data-target="#issuingAuthority" class="expansion-div mb-15"
                (click)="detailsOfIssuingAuthority = !detailsOfIssuingAuthority">
                Details of Issuing Authority
                <span class="consultant-icons"> <i *ngIf="!detailsOfIssuingAuthority" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="detailsOfIssuingAuthority" class="fa fa-angle-up"
                    aria-hidden="true"></i></span>
              </div>
              <div id="issuingAuthority" class="collapse">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Name of the Officer </label>
                      <input class="form-control" id="officer" formControlName="officer" />

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Designation </label>
                      <!-- <input class="form-control" id="designation" formControlName="designation"/>  -->
                      <select class="form-control form-select" formControlName="designation">
                        <ng-container *ngFor="let obj of designation">
                          <option [value]="obj.value">{{obj.key}}</option>
                        </ng-container>
                      </select>

                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Jurisdiction </label>
                      <input class="form-control" id="jurisdiction" formControlName="jurisdiction" />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Address </label>
                      <input class="form-control" id="address" formControlName="address" />
                    </div>
                  </div>


                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Contact No. </label>
                      <input class="form-control" id="contact" formControlName="contact" maxlength="12"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                    </div>
                  </div>


                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">E-Mail. </label>
                      <input class="form-control" id="email" formControlName="email" />
                    </div>
                    <div *ngIf="formGrp.email.errors">
                      <span class="text-danger" *ngIf="formGrp.email.errors.pattern"> Please Enter Valid Email ID</span>
                    </div>
                    <div *ngIf="isSubmitted && formGrp.email.errors">
                      <span class="text-danger" *ngIf="formGrp.email.errors.maxlength">Cannot enter more than 100
                        chars.</span>
                    </div>
                  </div>
                </div>
              </div>
              <!--  -->

              <!-- table payment under protest-->


                        
              <div data-toggle="collapse" data-target="#demo" class="expansion-div mb-15"
                (click)="protestDetailsExpansion = !protestDetailsExpansion">
                Details of Payments Made Under Protest
                <span class="consultant-icons"> <i *ngIf="!protestDetailsExpansion" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="protestDetailsExpansion" class="fa fa-angle-up"
                    aria-hidden="true"></i></span>
              </div>
              <div id="demo" class="collapse">
                <div class=" row">
                  <div class="col-md-12">
                    <div class="search-table-outter wrapper">
                      <table class="search-table inner">
                        <thead>
                          <tr class="bgGred">
                            <th class="sl-no-cls">Sr No.</th>
                            <th>Mode of Payment</th>
                            <th>Reference / Challan No.</th>
                            <th>Tax (₹)</th>
                            <th>Interest (₹)</th>
                            <th>Penalty (₹)</th>
                            <th>Total Payment (₹)</th>
                            <th>Accounting Reference</th>
                            <th>Supporting Document </th>
                            <th class="ac-no-cls">Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container>

                            <tr *ngFor="let div of getDetailOfPaymentArr(); let i=index" [formGroup]="div"
                              (keydown.enter)="$event.preventDefault()">
                              <td class="sl-no-cls"> {{i + 1}}</td>
                              <!-- Mode of payment -->
                              <td>
                                <select class="form-control form-select" id="status" formControlName="modeOfPayment"
                                  [ngClass]="{ 'errorBorder': isSubmitted && div.get('modeOfPayment')?.errors  }">
                                  <option [value]="null">-- Select --</option>
                                  <option *ngFor="let obj of modeOfPayment" [value]="obj.value">{{obj.key}}</option>
                                </select>
                              </td>
                              <!-- Challan No. / Ref. No. -->
                              <td>
                                <input class="form-control" id="challanNo" formControlName="challanNo">
                                  <!-- [ngClass]="{ 'errorBorder': isSubmitted && getProtestCopyLength(i) === 0}" /> -->
                                <!-- <div *ngIf="isSubmitted && getProtestCopyLength(i) === 0 ">
                                  <span class="text-danger"> Please Upload Copy of Challan</span>
                                </div> -->
                                <!-- <div *ngIf="isSubmitted && div.get('supportingDocLoc')?.errors">
                                  <span class="text-danger" *ngIf="div.get('supportingDocLoc')?.errors?.required">Please
                                    Upload Copy of Challan</span>
                                </div> -->
                              </td>
                              <td><input type="text" class="form-control align-right" formControlName="tax" id="tax"
                                  placeholder="" maxlength="19" InrFormat (input)="paymentUnderProtestp1Changed()"
                                  (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" formControlName="interest"
                                  id="interest" placeholder="" maxlength="19" InrFormat
                                  (input)="paymentUnderProtestp1Changed()" (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" formControlName="penalty"
                                  id="penalty" placeholder="" maxlength="19" InrFormat
                                  (input)="paymentUnderProtestp1Changed()" (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" formControlName="total" id="total"
                                  readonly placeholder="" maxlength="19" InrFormat></td>
                                  <!-- <td>
                                    <input type="text" class="form-control" formControlName="bgNo"  
                                    [title]="div.value.bgNo ? div.value.bgNo:''"
                                    [ngClass]="{ 'errorBorder': div.value.bgNo.length > 100 }">
                                    <div  *ngIf="div.value.bgNo && div.value.bgNo.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td> -->
                                  <td>
                                    <input type="text" class="form-control" formControlName="accountingRef"
                                    [ngClass]="{ 'errorBorder': div.value.accountingRef?.length > 100 }"
                                      [title]="div.value.accountingRef ? div.value.accountingRef:''">
                                    <div
                                      *ngIf="div.value.accountingRef && div.value.accountingRef.length > 100">
                                      <span class="text-danger">Max character Length 100</span>
                                    </div>
                                </td>

                              <td class="upload-column-cls">
                                <div class="row">
                                  <div class="col-sm-9 nopadding">
                                    <span style="cursor: pointer;"
                                      (click)="openProtestSupportingDocLoctAttach(supportingDocLocPop, i)"> <i
                                        class="icon fa fa fa-paperclip" title="Attach file"
                                        style="cursor: pointer; margin-left: 10px;"></i></span>
                                    <span *ngIf="getProtestCopyLength(i) != 0">
                                      {{getProtestCopyLength(i)}} Attachment
                                    </span>
                                    <ng-template #supportingDocLocPop let-modal>
                                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="col-md-12">
                                        <label> Attachment</label>
                                        <div class="table-responsive">
                                          <table class="table table-bordered tablepop">
                                            <thead>
                                              <tr class="bgGred">
                                                <th>Upload</th>
                                                <th style="width:50%;">Remark</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                *ngFor="let supportingDocGrp of getProtestSupportingDocLocAttachment(div); let j = index"
                                                [formGroup]="supportingDocGrp">
                                                <td>
                                                  <div class="row">
                                                    <div class="col-md-9 nopadding">
                                                      <div class="form-group">
                                                        <input *ngIf="supportingDocGrp.value.isdocLocUploadedClicked"
                                                          type="file"
                                                          id="protestDetails({{i}}).supportingDocLoc({{j}}).fileLoc"
                                                          class="filetype form-control" name="myfile"
                                                          (change)="onFileSelect($event)">
                                                        <p *ngIf="!supportingDocGrp.value.isdocLocUploadedClicked || supportingDocGrp.value.fileLoc"
                                                          class="form-control pt10">
                                                          {{getFileNameDta(supportingDocGrp.value.fileLoc)}}
                                                        </p>
                                                      </div>
                                                    </div>

                                                    <div class="col-md-3 nopadding">
                                                      <label for="exampleInputPassword1">
                                                        <a class="downupIcon" *ngIf="supportingDocGrp.value.fileLoc"
                                                          title="Download file"
                                                          (click)="download(supportingDocGrp.value.fileLoc)"><i
                                                            class="fa fa-download"></i></a>
                                                        <a class="downupIcon"
                                                          *ngIf="!(supportingDocGrp.value.fileLoc || supportingDocGrp.value.fileLoc) && isDisableIcons"
                                                          title="Upload file"
                                                          (click)="uploadProtestSupportingDoc(i,j)"><i
                                                            class="fa fa-upload"></i></a>
                                                        <a class="downupIcon" (click)="deleteProtestSupportingDoc(i,j)"
                                                          *ngIf="(supportingDocGrp.value.fileLoc) && isDisableIcons"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type="text" class="form-control" formControlName="remarks"
                                                    [title]="supportingDocGrp.value.remarks ? supportingDocGrp.value.remarks:''">
                                                  <div
                                                    *ngIf="supportingDocGrp.value.remarks && supportingDocGrp.value.remarks.length > 100">
                                                    <span class="text-danger">Max character Length 100</span>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Save</button>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="div.get('challanNo')?.value && getProtestCopyLength(i) === 0 ">
                                    <span class="text-danger"> Required.</span>
                                  </div>
                                </div>
                              </td>

                              <td class="ac-no-cls"><a class=" addExtLetter" (click)="addDetailOfPayment()"><i
                                    class="fa fa-plus"></i></a>
                                <a class="addExtLetter" *ngIf="i !=0"
                                  (click)="openRemoveDetailsOfPaymentPop(rmvDetailsOfPaymentRef)"><i
                                    class="fa fa-minus"></i></a>
                              </td>
                              <ng-template #rmvDetailsOfPaymentRef let-modal>
                                <div class="modal-header">
                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <p>Are you sure, you want to remove?</p>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="rmPaymentUnderProtest(i)">Remove</button>
                                </div>
                              </ng-template>
                            </tr>

                            <tr>
                              <th colspan="3">Total</th>
                              <td class="align-text-right bold-font">{{protestTaxTotal | INR: 0}}</td>
                              <td class="align-text-right bold-font">{{protestInterestTotal | INR: 0}}</td>
                              <td class="align-text-right bold-font">{{protestPenaltyTotal| INR: 0}}</td>
                              <td class="align-text-right bold-font">{{protestTotal | INR: 0 }}</td>
                            </tr>
                          </ng-container>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div data-toggle="collapse" data-target="#demo12" class="expansion-div mb-15"
              (click)="guaranteDetailsExpansion = !guaranteDetailsExpansion">
              Details of Bank Guarantee
              <span class="consultant-icons"> <i *ngIf="!guaranteDetailsExpansion" class="fa fa-angle-down"  aria-hidden="true"></i> 
                <i *ngIf="guaranteDetailsExpansion" class="fa fa-angle-up" aria-hidden="true"></i></span>
            </div>
            <div id="demo12" class="collapse">
              <div class=" row">
                <div class="col-md-12">
                  <div class="search-table-outter wrapper">
                    <table class="search-table inner">
                      <thead>
                        <tr class="bgGred">
                          <th>BG Number</th>
                          <th>Issue Date</th>
                          <th> Expiry date</th>
                          <th>Amount (₹)</th>
                          <th>Fees/Commission (₹)</th>
                          <th>Status of BG</th>
                          <th>Remarks</th>
                          <th class="ac-no-cls">Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container>
                          <tr *ngFor="let div of getDetailOfBankGuaranteeArr(); let i=index" [formGroup]="div"
                            (keydown.enter)="$event.preventDefault()">
                            <td>
                              <input type="text" class="form-control" formControlName="bgNo"  [title]="div.value.bgNo ? div.value.bgNo:''"
                              [ngClass]="{ 'errorBorder': div.value.bgNo?.length > 100 }">
                              <div  *ngIf="div.value.bgNo && div.value.bgNo.length > 100">
                              <span class="text-danger">Max character Length 100</span>
                            </div>
                          </td>
                            <td>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker tbldatePicker">
                                <p-calendar appendTo="body" class="form-control" formControlName="issueDate"
                                  [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate"
                                  yearRange="2000:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                  dataType="string">
                                </p-calendar>
                              </div>
                            </td>

                            <td>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker tbldatePicker">
                                <p-calendar appendTo="body" class="form-control" formControlName="expiryDate"
                                  [monthNavigator]="true" [yearNavigator]="true"  
                                  yearRange="2000:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                  dataType="string">
                                </p-calendar>
                              </div>
                            </td>

                            <td><input type="text" class="form-control align-right" formControlName="bgAmount" id="bgAmount"
                                placeholder="" maxlength="16" InrFormat>
                            </td>

                            <td><input type="text" class="form-control align-right" formControlName="fee"
                                id="fee" placeholder="" maxlength="16" InrFormat>
                              </td>
                             <td>
                                <select class="form-control form-select" id="bgStatus"
                                  formControlName="bgStatus">
                                  <option [value]="null">-- Select --</option>
                                  <option *ngFor="let obj of bgStatusOptions" 
                                  [value]="obj.value">{{obj.value}}
                                  </option>
                                </select>
                              </td>
                              <td>
                                <input type="text" class="form-control" formControlName="remark"
                                [ngClass]="{ 'errorBorder': div.value.remark?.length > 100 }"
                                  [title]="div.value.remark ? div.value.remark:''">
                                <div
                                  *ngIf="div.value.remark && div.value.remark.length > 100">
                                  <span class="text-danger">Max character Length 100</span>
                                </div>
                              </td>
                            <td class="ac-no-cls"><a class=" addExtLetter" (click)="addDetailOfBankGuarantee()"><i
                                  class="fa fa-plus"></i></a>
                              <a class="addExtLetter" *ngIf="i !=0"
                                (click)="openRemoveBankGuaranteePop(rmvbankGuaranteeRef)"><i
                                  class="fa fa-minus"></i></a>
                            </td>
                            <ng-template #rmvbankGuaranteeRef let-modal>
                              <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                <button type="button" class="close" aria-label="Close"
                                  (click)="modal.dismiss('Cross click')">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <p>Are you sure, you want to remove?</p>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-outline-dark"
                                  (click)="rmBankGuarantee(i)">Remove</button>
                              </div>
                            </ng-template>
                          </tr>
                        </ng-container>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
              <div class="row" *ngIf="isDataSaved">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exampleInputPassword1" style="margin-top: 10px;">Whether writ application preferred to
                      High Court</label>
                    <select class="form-control form-select" formControlName="whetherWritPreferredHc">
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of yesNoDropdown">
                        <option [value]="obj.value">{{obj.label}}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>

              <!-- table payment under protest ends -->

              <div class="col-md-12 mt15 nopadding">
                <button class="btn btn-outline-primary mr-1" (click)="save('p1')" *ngIf="isCreateBtn">Save & Next</button>
                <button class="btn btn-outline-primary mr-1" (click)="updateCase('p1')"
                  *ngIf="isUpdateBtn">Update</button>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show active" id="adjudp2-1" role="tabpanel" aria-labelledby="adjudp2-tab">
      <div class="card borderOrange" [hidden]="(selectedTab === 'tab1') || (selectedTab === 'tab3')"
        [ngClass]="{'bgGray': (action == 'view' || (action == 'update' && getAccessTabData.ajaFlag))} ">
        <div class="card-body">
          <fieldset [disabled]="action == 'view' || (action == 'update' && getAccessTabData.ajaFlag) ">
            <div class="row">
              <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Date of submission <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                    <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                      <p-calendar class="form-control" formControlName="dateOfSubmission"  [ngClass]="{ 'errorBorder': isSubmitted && formGrp.dateOfSubmission.errors }" [maxDate]="maxDate" [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" ></p-calendar>
                    </div>
                    <div *ngIf="isSubmitted && formGrp.dateOfSubmission.errors">
                      <span class="text-danger" *ngIf="formGrp.dateOfSubmission.errors.required"> Required</span>
                    </div>
                  </div>
                </div> -->
              <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Consultant</label>
                    <input type="text" class="form-control" id="" placeholder="" formControlName="consultant2">
                  </div>
                </div> -->
              <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Person responsible <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                    <select class="form-control form-select" id="personResponsible" formControlName="personResponsible2" [ngClass]="{ 'errorBorder': isSubmitted && formGrp.personResponsible2.errors }">
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of userRoleLs">
                        <option [value]="obj.email">{{obj.email}}</option>
                      </ng-container>
                    </select>
                    <div *ngIf="isSubmitted && formGrp.personResponsible2.errors">
                      <span class="text-danger" *ngIf="formGrp.personResponsible2.errors.required"> Required
                      </span>
                    </div>
                  </div>
                </div> -->

              <div data-toggle="collapse" data-target="#detailOfSubmission" class="expansion-div mb-15"
                (click)="detailsOfSubmissionsExpansion = !detailsOfSubmissionsExpansion">
                Details of Submissions
                <span class="consultant-icons"> <i *ngIf="!detailsOfSubmissionsExpansion" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="detailsOfSubmissionsExpansion" class="fa fa-angle-up"
                    aria-hidden="true"></i></span>
              </div>
              <div id="detailOfSubmission" class="collapse show multi-collapse">
                <div class=" row">
                  <div class="col-md-12">
                    <div class="search-table-outter wrapper">
                      <table class="search-table inner w-100">
                        <thead>
                          <tr class="bgGred">
                            <th class="col-md-1">Sr No.</th>
                            <th class="col-md-4">Submission Ref. No <span class="mandate mandate-text"><i
                                  class="fa fa-asterisk"></i></span></th>
                            <th class="col-md-4">Date of Submission <span class="mandate mandate-text"><i
                                  class="fa fa-asterisk"></i></span></th>
                            <th class="col-md-6">Mode of Submission</th>
                            <th class="col-md-4">Copy of Acknowledgement <span class="mandate mandate-text"><i
                                  class="fa fa-asterisk"></i></span></th>
                            <th class="col-md-4">Other Attachments</th>
                            <th class="col-md-2"> Actions</th>
                          </tr>

                        </thead>
                        <tbody>
                          <ng-container>

                            <tr *ngFor="let div of getSubmissionDetailsArr(); let i=index" [formGroup]="div"
                              (keydown.enter)="$event.preventDefault()">
                              <td class="sl-no-cls">{{i + 1}}</td>
                              <!-- Mode of payment -->
                              <td><input class="form-control" id="refNo" formControlName="refNo"
                                  [title]="div.value.refNo"
                                  [ngClass]="{ 'errorBorder': isSubmitted && div.get('refNo')?.errors}" />
                                <div *ngIf="isSubmitted && div.get('refNo')?.errors">
                                  <span class="text-danger" *ngIf="div.get('refNo')?.errors?.required"> Required</span>
                                </div>
                              </td>
                              <!-- Challan No. / Ref. No. -->
                              <td>
                                <div id="iris-gst-filter-frmDate" class="input-group date datepicker tbldatePicker"
                                  [ngClass]="{ 'errorBorder': isSubmitted && div.get('submissionDate')?.errors}">
                                  <p-calendar appendTo="body" class="form-control" formControlName="submissionDate"
                                    dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:{{currentYear}}"
                                    dateFormat="dd-mm-yy"></p-calendar>
                                </div>
                                <div *ngIf="isSubmitted && div.get('submissionDate')?.errors">
                                  <span class="text-danger" *ngIf="div.get('submissionDate')?.errors?.required">
                                    Required</span>
                                </div>
                              </td>

                              <td>
                                <select class="form-control form-select" id="submissionMode"
                                  formControlName="submissionMode">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of modeOfAppealOptions">
                                    <option [value]="obj.value">{{obj.value}}</option>
                                  </ng-container>
                                </select>
                              </td>
                              <td class="col-md-4">
                                <div class="row">
                                  <div class="col-sm-9 nopadding">
                                    <span (click)="openCopyOfAckLoc(copyOfAckLocPop, i)" style="margin-left: 5px;">
                                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                        title="Attach file"></i></span><span *ngIf="getCopyOfAckLocLength(i) != 0">
                                      {{getCopyOfAckLocLength(i)}} Attachment</span>

                                    <ng-template #copyOfAckLocPop let-modal>
                                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="col-md-12">
                                        <label> Attachment</label>
                                        <div class="table-responsive">
                                          <table class="table table-bordered tablepop">
                                            <thead>
                                              <tr class="bgGred">
                                                <th>Upload</th>
                                                <th style="width:50%;">Remark</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                *ngFor="let copyOfAckLocGrp of getcopyOfAckLocAttachment(div); let j = index"
                                                [formGroup]="copyOfAckLocGrp">
                                                <td>
                                                  <div class="row">
                                                    <div class="col-md-9 nopadding">
                                                      <div class="form-group">
                                                        <input *ngIf="copyOfAckLocGrp.value.isdocLocUploadedClicked"
                                                          type="file"
                                                          id="submissions({{i}}).copyOfAckLoc({{j}}).fileLoc"
                                                          class="filetype form-control" name="myfile"
                                                          (change)="onFileSelect($event)">
                                                        <p *ngIf="!copyOfAckLocGrp.value.isdocLocUploadedClicked || copyOfAckLocGrp.value.fileLoc"
                                                          class="form-control pt10">
                                                          {{getFileNameDta(copyOfAckLocGrp.value.fileLoc)}}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 nopadding">
                                                      <label for="exampleInputPassword1">
                                                        <a class="downupIcon" *ngIf="copyOfAckLocGrp.value.fileLoc"
                                                          title="Download file"
                                                          (click)="download(copyOfAckLocGrp.value.fileLoc)"><i
                                                            class="fa fa-download"></i></a>
                                                        <a class="downupIcon"
                                                          *ngIf="!(copyOfAckLocGrp.value.fileLoc || copyOfAckLocGrp.value.fileLoc) && isDisableIcons"
                                                          title="Upload file" (click)="uploadCopyOfAckLoc(i,j)"><i
                                                            class="fa fa-upload"></i></a>
                                                        <a class="downupIcon" (click)="deleteCopyOfAckLoc(i,j)"
                                                          *ngIf="(copyOfAckLocGrp.value.fileLoc) && isDisableIcons"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type="text" class="form-control" formControlName="remarks"
                                                    [title]="copyOfAckLocGrp.value.remarks ? copyOfAckLocGrp.value.remarks:''">
                                                  <div
                                                    *ngIf="copyOfAckLocGrp.value.remarks && copyOfAckLocGrp.value.remarks.length > 100">
                                                    <span class="text-danger">Max character Length 100</span>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Save</button>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="isSubmitted && getCopyOfAckLocLength(i) === 0 ">
                                    <span class="text-danger"> Required</span>
                                  </div>
                                </div>
                              </td>
                              <td class="col-md-4">
                                <!-- other attachment -->
                                <div class="row">
                                  <div class="col-sm-9 nopadding">
                                    <span (click)="openOtherAttachmentsLoc(otherAttachmentsLocPop, i)"
                                      style="margin-left: 5px;">
                                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                        title="Attach file"></i></span>
                                    <span *ngIf="getOtherAttachmentsLocLength(i) != 0">
                                      {{getOtherAttachmentsLocLength(i)}} Attachment</span>
                                    <ng-template #otherAttachmentsLocPop let-modal>
                                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="col-md-12">
                                        <label> Attachment</label>
                                        <div class="table-responsive">
                                          <table class="table table-bordered tablepop">
                                            <thead>
                                              <tr class="bgGred">
                                                <th>Upload</th>
                                                <th style="width:50%;">Remark</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                *ngFor="let otherAttachmentsLocPopGrp of getOtherAttachments(div); let j = index"
                                                [formGroup]="otherAttachmentsLocPopGrp">
                                                <td>
                                                  <div class="row">
                                                    <div class="col-md-9 nopadding">
                                                      <div class="form-group">
                                                        <input
                                                          *ngIf="otherAttachmentsLocPopGrp.value.isdocLocUploadedClicked"
                                                          type="file"
                                                          id="submissions({{i}}).otherAttachmentsLoc({{j}}).fileLoc"
                                                          class="filetype form-control" name="myfile"
                                                          (change)="onFileSelect($event)">
                                                        <p *ngIf="!otherAttachmentsLocPopGrp.value.isdocLocUploadedClicked || otherAttachmentsLocPopGrp.value.fileLoc"
                                                          class="form-control pt10">
                                                          {{getFileNameDta(otherAttachmentsLocPopGrp.value.fileLoc)}}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 nopadding">
                                                      <label for="exampleInputPassword1">
                                                        <a class="downupIcon"
                                                          *ngIf="otherAttachmentsLocPopGrp.value.fileLoc"
                                                          title="Download file"
                                                          (click)="download(otherAttachmentsLocPopGrp.value.fileLoc)"><i
                                                            class="fa fa-download"></i></a>
                                                        <a class="downupIcon"
                                                          *ngIf="!(otherAttachmentsLocPopGrp.value.fileLoc || otherAttachmentsLocPopGrp.value.fileLoc) && isDisableIcons"
                                                          title="Upload file"
                                                          (click)="uploadOtherAttachmentsLoc(i,j)"><i
                                                            class="fa fa-upload"></i></a>
                                                        <a class="downupIcon" (click)="deleteOtherAttachmentsLoc(i,j)"
                                                          *ngIf="(otherAttachmentsLocPopGrp.value.fileLoc) && isDisableIcons"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type="text" class="form-control" formControlName="remarks"
                                                    [title]="otherAttachmentsLocPopGrp.value.remarks ? otherAttachmentsLocPopGrp.value.remarks:''">
                                                  <div
                                                    *ngIf="otherAttachmentsLocPopGrp.value.remarks && otherAttachmentsLocPopGrp.value.remarks.length > 100">
                                                    <span class="text-danger">Max character Length 100</span>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Save</button>
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>

                                <!-- other attachment ends -->
                              </td>

                              <td><a class=" addExtLetter" (click)="addSubmissionDetailsArr()"><i
                                    class="fa fa-plus"></i></a>
                                <a class="addExtLetter" *ngIf="i !=0"
                                  (click)="openRemoveSubmissionDetailsPopup(rmvSubmissionDetailRef)"><i
                                    class="fa fa-minus"></i></a>
                              </td>
                              <ng-template #rmvSubmissionDetailRef let-modal>
                                <div class="modal-header">
                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <p>Are you sure, you want to remove?</p>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="rmSubmissionDetailProtest(i)">Remove</button>
                                </div>
                              </ng-template>
                            </tr>
                          </ng-container>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Copy of the acknowledgement <span class="mandate"><i class="fa fa-asterisk"></i></span> <a class="clrOrange infoIcon" title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                     <a class="downupIcon" *ngIf="isDWcopyOfAckLoc && formGrp.copyOfAckLoc.value" title="Download file"
                        (click)="download(formGrp.copyOfAckLoc.value)"><i class="fa fa-download"></i></a>
                      <a class="downupIcon" *ngIf="isUPcopyOfAckLoc && !(action == 'update' && getAccessTabData.ajaFlag)" title="Upload file" (click)="uploadcopyOfAckLoc()"><i
                          class="fa fa-upload"></i></a>
                    </label>
                    <input type="file"[hidden]="!iscopyOfAckLocFile" id="copyOfAckLoc" class="filetype form-control" name="myfile" (change)='onFileSelect($event)' [ngClass]="{ 'errorBorder': isSubmitted && copyOfAckLocReq }">
                      <p [hidden]="!iscopyOfAckLocText" class="form-control pt10" [ngClass]="{ 'errorBorder': isSubmitted && copyOfAckLocReq }">{{getFileNameDta(formGrp.copyOfAckLoc.value)}}</p>
                      <div *ngIf="isSubmitted && copyOfAckLocReq">
                        <span class="text-danger" *ngIf="copyOfAckLocReq"> Required</span>
                      </div>
                  </div>
                </div> -->
              <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Other Attachments  <a class="clrOrange infoIcon" title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                     <a class="downupIcon" *ngIf="isDWotherAttachmentsLoc && formGrp.otherAttachmentsLoc.value"
                        title="Download file" (click)="download(formGrp.otherAttachmentsLoc.value)"><i
                          class="fa fa-download"></i></a>
                      <a class="downupIcon" *ngIf="isUPotherAttachmentsLoc && !(action == 'update' && getAccessTabData.ajaFlag)" title="Upload file"
                        (click)="uploadotherAttachmentsLoc()"><i class="fa fa-upload"></i></a>
                    </label>
                    <input type="file"[hidden]="!isotherAttachmentsLocFile" id="otherAttachmentsLoc"
                      class="filetype form-control" name="myfile" (change)='onFileSelect($event)'>
                      <p [hidden]="!isotherAttachmentsLocText" class="form-control pt10" >{{getFileNameDta(formGrp.otherAttachmentsLoc.value)}}</p>
                  </div>
                </div> -->
            </div>
            <div class="card-body pt0 nopadding">
              <div formArrayName="itemList">
                <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                  <li class="nav-item" id="adjPhTab{{i}}" *ngFor="let adjPHtab of phDivArr().controls; let i=index"
                    [formGroupName]="i">
                    <a class="nav-link" id="adjudph1-tab" data-toggle="tab" (click)="phaseChanged('phase' + i, i)"
                      [ngClass]="(selectedPhase === 'phase' + i) ? 'active': ''" role="tab" aria-controls="adjudph1-1"
                      aria-selected="true">PH {{i+1}} <span *ngIf="i > 0" (click)="removeLetter(i)"
                        class="closeTab">x</span></a>
                  </li>
                </ul>
                <div class="tab-content nopadding">
                  <div class="tab-pane fade show active" id="adjudph1-1" role="tabpanel" aria-labelledby="adjudph1-tab">
                    <div class=" ml5" id="adjPhDiv{{i}}" *ngFor="let adjPHdiv of phDivArr().controls; let i=index"
                      [formGroupName]="i">
                      <div *ngIf="selectedPhase === 'phase' + i">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice Ref.No </label>
                              <input type="text" class="form-control" id="phRefNo" placeholder="" formControlName="phRefNo">
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice Date </label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="phNoticeDate"
                                  [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice
                              </label>
                              <input type="file" class="form-control file-upload-default" #file>
                              <div class="attachment-block">
                                <span (click)="openphCopyLoc(phCopyLoc, i)" style="margin-left: 5px;">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getPhCopyLocLength(i) != 0">
                                  {{getPhCopyLocLength(i)}} Attachment</span>
                              </div>
                              <div>
                                <ng-template #phCopyLoc let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let phCopyGrp of getphCopyLoc(adjPHdiv); let j = index"
                                            [formGroup]="phCopyGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="phCopyGrp.value.isdocLocUploadedClicked" type="file"
                                                      id="itemList({{i}}).phCopyLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)">
                                                    <p *ngIf="!phCopyGrp.value.isdocLocUploadedClicked || phCopyGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(phCopyGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="phCopyGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(phCopyGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(phCopyGrp.value.fileLoc || phCopyGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadPhCopy(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deletePhCopy(i,j)"
                                                      *ngIf="(phCopyGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" formControlName="remarks"
                                                [title]="phCopyGrp.value.remarks ? phCopyGrp.value.remarks:''">
                                              <div
                                                *ngIf="phCopyGrp.value.remarks && phCopyGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Date of PH scheduled </label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="dateOfPhScheduled"
                                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                          </div>
                          <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Time of PH </label>
                              <select class="form-control form-select" id="phTime" formControlName="phTime">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfTime">
                                  <option [value]="obj.value">{{obj.key}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Mode of PH </label>
                              <select class="form-control form-select" id="gstin" formControlName="mode">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfPH">
                                  <option [value]="obj.value">{{obj.key}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Attended </label>
                              <select class="form-control form-select" id="gstin" formControlName="attended">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of adjAttend">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                         

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="">Adjourned/ New PH </label>
                              <select class="form-control form-select" id="gstin" formControlName="adjournedOrNot"
                                (change)="changeadjOrNot($event, i)">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of adjPHAdjuorNotList">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Adjournment Letter</label>
                            <input type="file" class="form-control file-upload-default" #file>
                            <div class="attachment-block">

                              <span (click)="openAdjournmentLetter(phAdjournmentLetterLoc, i)" style="margin-left: 5px;">
                                <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span><span *ngIf="getphAdjournmentLetterLocLength(i) != 0">
                                {{getphAdjournmentLetterLocLength(i)}} Attachment</span>
                            </div>
                            <div>
                              <ng-template #phAdjournmentLetterLoc let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let phAdjournmentLetterGrp of getAdjournmentLetter(adjPHdiv); let j = index"
                                          [formGroup]="phAdjournmentLetterGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="phAdjournmentLetterGrp.value.isdocLocUploadedClicked" type="file"
                                                    id="itemList({{i}}).phAdjournmentLetterLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!phAdjournmentLetterGrp.value.isdocLocUploadedClicked || phAdjournmentLetterGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(phAdjournmentLetterGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="phAdjournmentLetterGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(phAdjournmentLetterGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(phAdjournmentLetterGrp.value.fileLoc || phAdjournmentLetterGrp.value.fileLoc) && isDisableIcons"
                                                    title="Upload file" (click)="uploadAdjournmentLetter(i,j)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAdjournmentLetter(i,j)"
                                                    *ngIf="(phAdjournmentLetterGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="phAdjournmentLetterGrp.value.remarks ? phAdjournmentLetterGrp.value.remarks:''">
                                            <div
                                              *ngIf="phAdjournmentLetterGrp.value.remarks && phAdjournmentLetterGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Date of Additional Written Submissions</label>
                            <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                              <p-calendar class="form-control" formControlName="dateOfsubmission"
                                [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate"
                                yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                dataType="string">
                              </p-calendar>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Upload the additional submission</label>
                            <input type="file" class="form-control file-upload-default" #file>
                            <div class="attachment-block">
                              <span (click)="openAdditionSub(additionalSubLoc, i)" style="margin-left: 5px;">
                                <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span><span
                                *ngIf="getAdditionalSubmissionLocLength(i) != 0">
                                {{getAdditionalSubmissionLocLength(i)}} Attachment</span>
                              <!-- <span style="cursor: pointer;" (click)="openAdditionSub(additionalSubLoc,i)"> <i
                              class="icon fa fa fa-paperclip" style="cursor: pointer;"  
                              title="Attach file"></i></span><span style="cursor: pointer;" (click)="openAdditionSub(additionalSubLoc,i)">Attachment</span> -->
                            </div>
                            <div>
                              <ng-template #additionalSubLoc let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let additionaSubGrp of getAdditionalSub(adjPHdiv); let j = index"
                                          [formGroup]="additionaSubGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="additionaSubGrp.value.isdocLocUploadedClicked"
                                                    type="file"
                                                    id="itemList({{i}}).additionalSubmissionLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!additionaSubGrp.value.isdocLocUploadedClicked || additionaSubGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(additionaSubGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="additionaSubGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(additionaSubGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(additionaSubGrp.value.fileLoc || additionaSubGrp.value.fileLoc) && isDisableIcons"
                                                    title="Upload file" (click)="uploadAdditionalSub(i,j)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAdditionalSub(i,j)"
                                                    *ngIf="(additionaSubGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="additionaSubGrp.value.remarks ? additionaSubGrp.value.remarks:''">
                                            <div
                                              *ngIf="additionaSubGrp.value.remarks && additionaSubGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Consultant</label>
                            <input type="text" class="form-control" id="caseId" formControlName="consultant">
                          </div>
                        </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH record, if any
                              </label>
                              <input type="file" class="form-control file-upload-default" #file>
                              <div class="attachment-block">

                                <span (click)="openPhRecord(phRecordLoc, i)" style="margin-left: 5px;">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getPhRecordIfAnyLocLength(i) != 0">
                                  {{getPhRecordIfAnyLocLength(i)}} Attachment</span>

                                <!-- <span style="cursor: pointer;" (click)="openPhRecord(phRecordLoc,i)"> <i
                                class="icon fa fa fa-paperclip" style="cursor: pointer;"  
                                title="Attach file"></i></span><span style="cursor: pointer;" (click)="openPhRecord(phRecordLoc,i)">Attachment</span> -->

                              </div>
                              <div>
                                <ng-template #phRecordLoc let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let phRecordGrp of getPhRecord(adjPHdiv); let j = index"
                                            [formGroup]="phRecordGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="phRecordGrp.value.isdocLocUploadedClicked" type="file"
                                                      id="itemList({{i}}).phRecordIfAnyLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)">
                                                    <p *ngIf="!phRecordGrp.value.isdocLocUploadedClicked || phRecordGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(phRecordGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="phRecordGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(phRecordGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(phRecordGrp.value.fileLoc || phRecordGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadPhRecord(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deletePhRecord(i,j)"
                                                      *ngIf="(phRecordGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" formControlName="remarks"
                                                [title]="phRecordGrp.value.remarks ? phRecordGrp.value.remarks:''">
                                              <div
                                                *ngIf="phRecordGrp.value.remarks && phRecordGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH discussion notes</label>
                              <textarea class="form-control" id="exampleTextarea1" rows="4"
                                formControlName="phDiscussionNotes"></textarea>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <!-- Consultant charges table -->
            <div data-toggle="collapse" data-target="#demo" class="expansion-div mb-15"
              (click)="consultantPanleExpansion != consultantPanleExpansion">Details of Consultant Charges <span
                class="consultant-icons"> <i *ngIf="!consultantPanleExpansion" class="fa fa-angle-down"
                  aria-hidden="true"></i> <i *ngIf="consultantPanleExpansion" class="fa fa-angle-up"
                  aria-hidden="true"></i></span> </div>
            <br>
            <div id="demo" class="collapse">
              <div class=" row">
                <div class="col-md-12">
                  <div class="search-table-outter wrapper p-15">
                    <table class="search-table inner">
                      <thead>
                        <tr class="bgGred">
                          <th class="sl-no-cls">Sr.No.</th>
                          <th>Name of Consulting Firm/ Legal Counsel</th>
                          <th>Name of the Consultant Responsible</th>
                          <th>Nature of Work</th>
                          <th>Fees Charged (₹)</th>
                          <th>Supporting Document</th>
                          <th>Email id</th>
                          <th>Contact Number</th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container>
                          <tr *ngFor="let div of consultantInvArr() let i=index;" [formGroup]="div">
                            <td class="sl-no-cls">{{i+1}}</td>
                            <td><input type="text" class="form-control" id="tax" placeholder="" formControlName="firm"
                                [title]="div.value.firm"
                                [ngClass]="{ 'errorBorder': isSubmitted && div.get('firm')?.errors}">
                              <div *ngIf="isSubmitted && div.get('firm')?.errors">
                                <span class="text-danger" *ngIf="div.get('firm')?.errors?.required"> Name of Consulting
                                  Firm Required</span>
                              </div>
                            </td>
                            <td><input type="text" class="form-control" id="tax" placeholder=""
                                formControlName="consultant" [title]="div.value.consultant"></td>
                            <td><input type="text" class="form-control" id="tax" placeholder=""
                                formControlName="natureOfWork" [title]="div.value.natureOfWork"></td>
                            <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                formControlName="fees" maxlength="19" InrFormat (input)="feesChargedChanged()"
                                (paste)="handlePaste($event)"></td>
                            <td>
                              <div class="row">
                                <div class="col-sm-9 nopadding">
                                  <span (click)="openConsultotherAttachmentLoc(consultOtherAttachPop, i)"
                                    style="margin-left: 5px;">
                                    <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                      title="Attach file"></i></span><span
                                    *ngIf="getConsultOtherAttachmentLength(i) != 0">
                                    {{getConsultOtherAttachmentLength(i)}} Attachment</span>

                                  <ng-template #consultOtherAttachPop let-modal>
                                    <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                      <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="col-md-12">
                                      <label> Attachment</label>
                                      <div class="table-responsive">
                                        <table class="table table-bordered tablepop">
                                          <thead>
                                            <tr class="bgGred">
                                              <th>Upload</th>
                                              <th style="width:50%;">Remark</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              *ngFor="let consultOtherAttachGrp of getConsultOtherAttachment(div); let j = index"
                                              [formGroup]="consultOtherAttachGrp">
                                              <td>
                                                <div class="row">
                                                  <div class="col-md-9 nopadding">
                                                    <div class="form-group">
                                                      <input *ngIf="consultOtherAttachGrp.value.isdocLocUploadedClicked"
                                                        type="file"
                                                        id="consultantCharges({{i}}).otherAttachmentLoc({{j}}).fileLoc"
                                                        class="filetype form-control" name="myfile"
                                                        (change)="onFileSelect($event)">
                                                      <p *ngIf="!consultOtherAttachGrp.value.isdocLocUploadedClicked || consultOtherAttachGrp.value.fileLoc"
                                                        class="form-control pt10">
                                                        {{getFileNameDta(consultOtherAttachGrp.value.fileLoc)}}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-3 nopadding">
                                                    <label for="exampleInputPassword1">
                                                      <a class="downupIcon" *ngIf="consultOtherAttachGrp.value.fileLoc"
                                                        title="Download file"
                                                        (click)="download(consultOtherAttachGrp.value.fileLoc)"><i
                                                          class="fa fa-download"></i></a>
                                                      <a class="downupIcon"
                                                        *ngIf="!(consultOtherAttachGrp.value.fileLoc || consultOtherAttachGrp.value.fileLoc) && isDisableIcons"
                                                        title="Upload file" (click)="uploadConsulOthertAttach(i,j)"><i
                                                          class="fa fa-upload"></i></a>
                                                      <a class="downupIcon" (click)="deleteConsulOthertAttach(i,j)"
                                                        *ngIf="(consultOtherAttachGrp.value.fileLoc) && isDisableIcons"><i
                                                          class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </label>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control" formControlName="remarks"
                                                  [title]="consultOtherAttachGrp.value.remarks ? consultOtherAttachGrp.value.remarks:''">
                                                <div
                                                  *ngIf="consultOtherAttachGrp.value.remarks && consultOtherAttachGrp.value.remarks.length > 100">
                                                  <span class="text-danger">Max character Length 100</span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="modal-footer" style="border: none; padding-top: 0px;">
                                      <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Save</button>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>
                            </td>
                            <td><input type="text" class="form-control" placeholder="" formControlName="email">
                              <div *ngIf="div.get('email')?.errors">
                                <span class="text-danger" *ngIf="div.get('email')?.errors?.pattern"> Please Enter
                                  Valid Email ID</span>
                              </div>
                            </td>
                            <td><input class="form-control" id="contact" formControlName="contact"
                                [title]="div.value.contact ? div.value.contact:''" maxlength="12"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                            </td>

                            <td>

                              <a class=" addExtLetter" (click)="addConsultantInv()"><i class="fa fa-plus"></i></a>
                              <a class=" addExtLetter" *ngIf="i !=0"
                                (click)="openRmvconsultantPop(removeConsultantPopup)"><i class="fa fa-minus"></i></a>
                              <ng-template #removeConsultantPopup let-modal>
                                <div class="modal-header">
                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <p>Are you sure, you want to remove?</p>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="rmConsultantInv(i)">Remove</button>
                                </div>
                              </ng-template>
                            </td>
                          </tr>
                          <tr>
                            <th colspan="4">Total</th>

                            <td class="align-right bold-font">{{feesChargedTotal | INR: 0 }}</td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Consultant charges table 2 -->
            <div class="row">
              <div class="col-md-3">
                <label>Whether the case is kept in Call book ? <a class="clrOrange infoIcon"
                    title="Please select this option as No if you want to update Phase 3"><i class="fa fa-info-circle"
                      style="margin-left:-6px;"></i></a></label>
                <select class="form-control form-select" id="whetherCaseCallBook"
                  [ngClass]="{'disabled': callBookDisabled }" formControlName="whetherCaseCallBook"
                  (change)="whetherCallBookChanged()">
                  <ng-container *ngFor="let obj of keepCaseInCallBookOptions">
                    <option [value]="obj.value">{{obj.label}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-md-12 mt15 nopadding">
              <button class="btn btn-outline-primary mr-1" (click)="updateCase('p2')"
                *ngIf="isUpdateBtn">Update</button>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show active" id="adjudp3-1" role="tabpanel" aria-labelledby="adjudp3-tab">
      <div class="card" [hidden]="(selectedTab === 'tab1') || (selectedTab === 'tab2')"
        [ngClass]="{'bgGray': (action == 'view' || (action == 'update' && getAccessTabData.ajaFlag))} ">
        <div class="card-body">
          <fieldset [disabled]="action == 'view' || (action == 'update' && getAccessTabData.ajaFlag) ">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">O-i-O Number <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="oioNo" formControlName="oioNo"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.oioNo.errors }">
                  <div *ngIf="isSubmitted && formGrp.oioNo.errors">
                    <span class="text-danger" *ngIf="formGrp.oioNo.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <!-- DIN phase 3 -->
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">DIN</label>
                  <input type="text" class="form-control" id="din3" placeholder="" formControlName="din3">
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of O-i-O <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfOio" [maxDate]="maxDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && formGrp.dateOfOio.errors }"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && formGrp.dateOfOio.errors">
                    <span class="text-danger" *ngIf="formGrp.dateOfOio.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of receipt of O-i-O <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfReceiptOfOio"
                      [ngClass]="{ 'errorBorder': isSubmitted && formGrp.dateOfReceiptOfOio.errors }"
                      [maxDate]="maxDate" [monthNavigator]="true" [yearNavigator]="true"
                      yearRange="2000:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                      dataType="string"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && formGrp.dateOfReceiptOfOio.errors">
                    <span class="text-danger" *ngIf="formGrp.dateOfReceiptOfOio.errors.required"> Required</span>
                  </div>

                </div>
              </div>

              <div class="col-md-3 disabledSelect">
                <div class="form-group">
                  <label for="exampleInputPassword1">Mode of Receipt of Order</label>
                  <select class="form-control form-select" formControlName="orderReceiptMode3">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of modeOfAppealOptions">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Copy of O-i-O<span class="mandate"><i
                        class="fa fa-asterisk"></i></span>
                  </label>
                  <input type="file" class="form-control file-upload-default" #file>
                  <div class="attachment-block" [ngClass]="{'errorBorder': isSubmitted && getOioLocLength() === 0 }">
                    <span (click)="openOioLocModel(oioLocPop)" style="margin-left: 5px;">
                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span><span
                      *ngIf="getOioLocLength() != 0"> {{getOioLocLength()}} Attachment</span>
                  </div>
                  <div *ngIf="isSubmitted &&  getOioLocLength() === 0">
                    <span class="text-danger"> Required</span>
                  </div>
                  <div>
                    <ng-template #oioLocPop let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment</label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                                <th style="width:50%;">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let oioLocGrp of getoioLoc(); let i = index" [formGroup]="oioLocGrp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="oioLocGrp.value.isdocLocUploadedClicked" type="file"
                                          id="oioLoc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                          (change)="onFileSelect($event)">
                                        <p *ngIf="!oioLocGrp.value.isdocLocUploadedClicked || oioLocGrp.value.fileLoc"
                                          class="form-control pt10"> {{getFileNameDta(oioLocGrp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="oioLocGrp.value.fileLoc" title="Download file"
                                          (click)="download(oioLocGrp.value.fileLoc)"><i class="fa fa-download"></i></a>
                                        <a class="downupIcon"
                                          *ngIf="!(oioLocGrp.value.fileLoc || oioLocGrp.value.fileLoc) && isDisableIcons"
                                          title="Upload file" (click)="uploadOioLoc(i)"><i class="fa fa-upload"></i></a>
                                        <a class="downupIcon" (click)="deleteOioLocF(i)"
                                          *ngIf="(oioLocGrp.value.fileLoc) && isDisableIcons"><i class="fa fa-trash"
                                            aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input type="text" class="form-control" formControlName="remarks"
                                    [title]="oioLocGrp.value.remarks ? oioLocGrp.value.remarks:''">
                                  <div *ngIf="oioLocGrp.value.remarks && oioLocGrp.value.remarks.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>
                </div>

              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Order Passed by <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="gstin" formControlName="orderPassedBy"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.orderPassedBy.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of orderPassedBy">
                      <option [value]="obj.key">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && formGrp.orderPassedBy.errors">
                    <span class="text-danger" *ngIf="formGrp.orderPassedBy.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label>Outcome <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="gstin" formControlName="outcome"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.outcome.errors }"
                    (change)="changeOutcome($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of adjOutcome">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && formGrp.outcome.errors">
                    <span class="text-danger" *ngIf="formGrp.outcome.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether appeal preferred </label>
                  <select class="form-control form-select" id="whetApplPref" formControlName="whetherAppealPreferred"
                    (change)="checkAppYes($event)" [ngClass]="{ 'disabled': disabledWeatherAppl }">
                    <!-- <option [value]="null">-- Select --</option>        -->
                    <ng-container *ngFor="let obj of whetherAppealPreferred">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether Writ application preferred </label>
                  <select class="form-control form-select" id="whetWritPref" formControlName="writAppPreferred"
                    (change)="checkwritAppYes($event)" [ngClass]="{'disabled': disabledWeatherWrit }">
                    <!-- <option [value]="null">-- Select --</option> -->
                    <ng-container *ngFor="let obj of writAppPreferred">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether department has preferred appeal </label>
                  <select class="form-control form-select" id="whetDeptPref" formControlName="whetherDptAppealPreferred"
                    (change)="checkDeptYes($event)" [ngClass]="{ 'disabled': disabledWeatherDept }">
                    <!-- <option [value]="false">-- Select --</option> -->
                    <ng-container *ngFor="let obj of whetherDptAppealPreferred">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>

                </div>
              </div>

              <!-- Forum before which appeal preffered -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleInputPassword1">Forum before which Appeal / Writ has been preferred <span
                      class="forum-appeal-star-cls mandate"><i class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="gstin" formControlName="forumOfAppeal"
                    (change)="openProceedWithPreferredForumAppealPop(proceedWithPreferredForumAppeal)"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.forumOfAppeal.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of forumAppeal">
                      <option [value]="obj.key">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && formGrp.forumOfAppeal.errors">
                    <span class="text-danger" *ngIf="formGrp.forumOfAppeal.errors.required"> Required</span>
                  </div>
                </div>
                <ng-template #proceedWithPreferredForumAppeal let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Warning!</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>You have selected <span class="bold-font">{{getForumAppealValue(formGrp.forumOfAppeal.value)}}
                      </span> as the forum before which the appeal / writ has been preferred. Do you want to proceed ?
                    </p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark"
                      (click)="rmProceedWithPreferredForumAppeal()">Yes</button>
                    <button type="button" class="btn btn-outline-dark"
                      (click)="rmProceedWithPreferredForumAppeal(true)">No</button>
                  </div>
                </ng-template>
              </div>


              <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Tax Challan <a class="clrOrange infoIcon" title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                      <a class="downupIcon" *ngIf="isDWdrc03CopyLoc && formGrp.drc03CopyLoc.value" title="Download file" (click)="download(formGrp.drc03CopyLoc.value)"><i class="fa fa-download"></i></a>
                      <a class="downupIcon" *ngIf="isUPdrc03CopyLoc && !(action == 'update' && getAccessTabData.ajaFlag)" title="Upload file"
                        (click)="uploaddrc03CopyLoc()"><i class="fa fa-upload"></i></a>
                    </label>
                    <input type="file" [hidden]="!isdrc03CopyLocFile" id="drc03CopyLoc" class="filetype form-control" name="myfile" (change)='onFileSelect($event)'>
                    <p [hidden]="!isdrc03CopyLocText" class="form-control pt10" >{{getFileNameDta(formGrp.drc03CopyLoc.value)}}</p>
                  </div>
                </div> -->
              <!-- <div class="col-md-3" >
                  <div class="form-group">
                    <label for="exampleInputPassword1">Tax Paid </label>
                    <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Tax" maxlength="18" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder': formGrp.drc03Tax.errors }">
                    <div *ngIf="formGrp.drc03Tax.errors">
                      <span class="text-danger" *ngIf="formGrp.drc03Tax.errors.pattern"> Negative value not allowed</span>
                    </div>
                  </div>
                </div> -->
              <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Interest Paid </label>
                    <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Interest" maxlength="18" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder': formGrp.drc03Interest.errors }">
                    <div *ngIf="formGrp.drc03Interest.errors">
                      <span class="text-danger" *ngIf="formGrp.drc03Interest.errors.pattern"> Negative value not allowed</span>
                    </div>
                  </div>
                </div> -->
              <!-- <div class="col-md-3">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Penalty Paid </label>
                    <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Penalty" maxlength="18" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder': formGrp.drc03Penalty.errors }">
                    <div *ngIf="formGrp.drc03Penalty.errors">
                      <span class="text-danger" *ngIf="formGrp.drc03Penalty.errors.pattern"> Negative value not allowed</span>
                    </div>
                  </div>
                </div> -->

              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Order Summary</label>
                  <textarea class="form-control" id="exampleTextarea1" rows="4"
                    formControlName="orderSummery"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Issue Wise Details </label>
                  <div class=" row">
                    <div class="col-md-12">
                      <div class="search-table-outter wrapper">
                        <table class="search-table inner">
                          <thead>
                            <tr class="bgGred">
                              <th>Issue</th>
                              <th>Division</th>
                              <th>Keyword <a class="clrOrange infoIcon"
                                  title="Maximum Length Limit is 150 Characters."><i class="fa fa-info-circle"></i></a>
                              </th>
                              <th>Conclusion <span class="mandate top7"><i class="fa fa-asterisk"></i></span></th>
                              <th *ngIf="isExcise">Excise Duty (₹)</th>
                              <th *ngIf="isExcise">EC (₹)</th>
                              <th *ngIf="isExcise">SHEC (₹)</th>
                              <th *ngIf="isServiceTax">Service Tax (₹)</th>
                              <th *ngIf="isServiceTax">EC (₹)</th>
                              <th *ngIf="isServiceTax">SHEC (₹)</th>
                              <th *ngIf="isServiceTax">KKC (₹)</th>
                              <th *ngIf="isServiceTax">SBC (₹)</th>
                              <th *ngIf="isVat">VAT (₹)</th>
                              <th *ngIf="isCst">CST (₹)</th>
                              <th *ngIf="isCustomsDuty">BCD (₹)</th>
                              <th *ngIf="isCustomsDuty">SAD (₹)</th>
                              <th *ngIf="isCustomsDuty">Anti-Dumping (₹)</th>
                              <th *ngIf="isCustomsDuty">IGST (₹)</th>
                              <th *ngIf="isEntryTax">Tax(₹)</th>
                              <th>Interest (₹) </th>
                              <th>Penalty (₹) </th>
                              <th>Total (₹) </th>
                            </tr>

                          </thead>
                          <tbody>
                            <ng-container>
                              <tr *ngFor="let div of issuInvArr(); let i=index" [formGroup]="div"
                                (keydown.enter)="$event.preventDefault()">

                                <td class="w250">
                                  <select class="form-control form-select" formControlName="issue"
                                    [title]="div.value.issue"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }">
                                    <option [value]="null" disabled>-- Select --</option>
                                    <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                      <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                        {{item.name}}</option>
                                    </optgroup>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('issue')?.errors">
                                    <span class="text-danger" *ngIf="div.get('issue')?.errors?.required "> Required
                                    </span>
                                  </div>
                                </td>
                                <!-- Division -->
                                <td>
                                  <select class="form-control form-select" formControlName="div"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('div')?.errors  }">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of preGstinDivList">
                                      <option [value]="obj">{{obj}}</option>
                                    </ng-container>
                                  </select>

                                </td>
                                <td><input type="text" class="form-control" id="tax" placeholder=""
                                    formControlName="keyword" maxlength="150" [title]="div.value.keyword"></td>
                                <td>
                                  <select class="form-control form-select" id="status" formControlName="status"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('status')?.errors  }">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of issueConclusion">
                                      <option [value]="obj.value">{{obj.value}}</option>
                                    </ng-container>
                                  </select>

                                  <div *ngIf="isSubmitted && div.get('status')?.errors">
                                    <span class="text-danger" *ngIf="div.get('status')?.errors?.required "> Required
                                    </span>
                                  </div>
                                </td>
                                <td *ngIf="isExcise"><input type="text" class="form-control align-right"
                                    formControlName="exciseDuty3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isExcise"><input type="text" class="form-control align-right"
                                    formControlName="ec3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isExcise"><input type="text" class="form-control align-right"
                                    formControlName="shec3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control align-right"
                                    formControlName="serviceTax3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control align-right"
                                    formControlName="ec3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control align-right"
                                    formControlName="shec3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control align-right"
                                    formControlName="kkc3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control align-right"
                                    formControlName="sbc3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isVat"><input type="text" class="form-control align-right"
                                    formControlName="vat3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isCst"><input type="text" class="form-control align-right"
                                    formControlName="cst3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control align-right"
                                    formControlName="bcd3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control align-right"
                                      formControlName="sad3" id="tax" placeholder="" maxlength="19" InrFormat
                                      (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control align-right"
                                        formControlName="antiDumpingDuty3" id="tax" placeholder="" maxlength="19" InrFormat
                                        (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control align-right"
                                          formControlName="igst3" id="tax" placeholder="" maxlength="19" InrFormat
                                          (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td *ngIf="isEntryTax"><input type="text" class="form-control align-right"
                                    formControlName="ent3" id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()" (paste)="handlePaste($event)"></td>
                                <td><input type="text" class="form-control align-right" formControlName="interest3"
                                    id="tax" placeholder="" maxlength="19" InrFormat (input)="issueValueChange()"
                                    (paste)="handlePaste($event)"></td>
                                <td><input type="text" class="form-control align-right" formControlName="penalty3"
                                    id="tax" placeholder="" maxlength="19" InrFormat (input)="issueValueChange()"
                                    (paste)="handlePaste($event)"></td>
                                <td><input type="text" class="form-control align-right" formControlName="total3"
                                    readonly id="tax" placeholder="" maxlength="19" InrFormat
                                    (input)="issueValueChange()"></td>
                              </tr>
                            </ng-container>
                            <ng-container>
                              <tr>
                                <th colspan="4">Total</th>
                                <td *ngIf="isExcise"><input type="text" class="form-control bold-font align-right"
                                    formControlName="exciseDuty3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isExcise"><input type="text" class="form-control bold-font align-right"
                                    formControlName="ec3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isExcise"><input type="text" class="form-control bold-font align-right"
                                    formControlName="shec3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="serviceTax3Total" id="tax" placeholder="serv" InrFormat
                                    maxlength="19" readonly></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="ec3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="shec3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="kkc3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isServiceTax"><input type="text" class="form-control bold-fon t align-right"
                                    formControlName="sbc3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isVat"><input type="text" class="form-control bold-font align-right"
                                    formControlName="vat3Total" id="vat" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isCst"><input type="text" class="form-control bold-font align-right"
                                    formControlName="cst3Total" id="cst" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control bold-font align-right"
                                    formControlName="bcd3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                               <td *ngIf="isCustomsDuty"><input type="text" class="form-control bold-font align-right"
                                    formControlName="sad3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                      readonly></td> 
                                <td *ngIf="isCustomsDuty"><input type="text" class="form-control bold-font align-right"
                                        formControlName="antiDumpingDuty3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                        readonly></td>
                               <td *ngIf="isCustomsDuty"><input type="text" class="form-control bold-font align-right"
                                          formControlName="igst3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                          readonly></td>
                                <td *ngIf="isEntryTax"><input type="text" class="form-control bold-font align-right"
                                    formControlName="ent3Total" id="tax" placeholder="" InrFormat maxlength="19"
                                    readonly></td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="interest3Total" InrFormat maxlength="19" readonly></td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="penalty3Total" InrFormat maxlength="19" readonly></td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="totOfTotal3" InrFormat maxlength="19" readonly></td>

                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <!-- Details of Liability Admitted and Paid  -->
              <div data-toggle="collapse" data-target="#detailsOfLiabilityAddmittedAndPaid" class="expansion-div mb-15"
                (click)="detailsOfLiabilityAddmittedAndPaidExpanded = !detailsOfLiabilityAddmittedAndPaidExpanded">
                Details of Liability Admitted and Paid
                <span class="consultant-icons"> <i *ngIf="!detailsOfLiabilityAddmittedAndPaidExpanded"
                    class="fa fa-angle-down" aria-hidden="true"></i> <i
                    *ngIf="detailsOfLiabilityAddmittedAndPaidExpanded" class="fa fa-angle-up"
                    aria-hidden="true"></i></span>
              </div>
              <div id="detailsOfLiabilityAddmittedAndPaid" class="collapse">
                <div class="row">
                  <div class="col-md- 12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="search-table-outter wrapper">
                            <table class="search-table inner">
                              <thead>
                                <tr class="bgGred">
                                  <th class="sl-no-cls">Sr. No.</th>
                                  <th>Mode of Payment</th>
                                  <th>Reference / Challan No.</th>
                                  <th>Tax (₹)</th>
                                  <th>Interest (₹)</th>
                                  <th>Penalty (₹)</th>
                                  <th>Total Payment (₹)</th>
                                  <th>Accounting Reference</th>
                                  <th>Supporting Document</th>
                                  <th class="ac-no-cls">Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container>

                                  <tr *ngFor="let div of detailsOfLiabilityAddmittedAndPaidArr(); let i=index"
                                    [formGroup]="div" (keydown.enter)="$event.preventDefault()">
                                    <td class="sl-no-cls">{{i + 1}}</td>
                                    <!-- Mode of payment -->
                                    <td>
                                      <select class="form-control form-select" id="status"
                                        formControlName="modeOfPayment">
                                        <option [value]="null">-- Select --</option>
                                        <option *ngFor="let obj of modeOfPaymentObjectLiabilityAdmitted"
                                          [value]="obj.value">{{obj.key}}</option>
                                      </select>
                                    </td>

                                    <td>
                                      <input class="form-control" id="challanNo" formControlName="challanNo"
                                        [ngClass]="{ 'errorBorder': isSubmitted && div.get('copyOfFormLoc')?.errors  }" />
                                      <div *ngIf="isSubmitted && div.get('copyOfFormLoc')?.errors">
                                        <span class="text-danger"
                                          *ngIf="div.get('copyOfFormLoc')?.errors?.required">Please Upload Copy of
                                          Challan</span>
                                      </div>
                                    </td>
                                    <td><input type="text" class="form-control align-right" formControlName="tax"
                                        id="tax" placeholder="" maxlength="19" InrFormat
                                        (input)="liabilityAddmittedAndPaidChanged()" (paste)="handlePaste($event)"></td>
                                    <td><input type="text" class="form-control align-right" formControlName="interest"
                                        id="interest" placeholder="" maxlength="19" InrFormat
                                        (input)="liabilityAddmittedAndPaidChanged()" (paste)="handlePaste($event)"></td>
                                    <td><input type="text" class="form-control align-right" formControlName="penalty"
                                        id="penalty" placeholder="" maxlength="19" InrFormat
                                        (input)="liabilityAddmittedAndPaidChanged()" (paste)="handlePaste($event)"></td>
                                    <td><input type="text" class="form-control align-right" formControlName="total"
                                        id="total" readonly placeholder="" maxlength="19" InrFormat></td>
                                        <td>
                                          <input type="text" class="form-control" formControlName="accountingRef"
                                          [ngClass]="{ 'errorBorder': div.value.accountingRef?.length > 100 }"
                                            [title]="div.value.accountingRef ? div.value.accountingRef:''">
                                          <div
                                            *ngIf="div.value.accountingRef && div.value.accountingRef.length > 100">
                                            <span class="text-danger">Max character Length 100</span>
                                          </div>
                                      </td>

                                        
                                    <td class="upload-column-cls">
                                      <!-- supporting attachment -->
                                      <div class="row">
                                        <div class="col-sm-9 nopadding">
                                          <span style="cursor: pointer;"
                                            (click)="openLiabilitySupportingDocLocAttachment(supportingDocLocPop, i)">
                                            <i class="icon fa fa fa-paperclip" title="Attach file"
                                              style="cursor: pointer;"></i></span>
                                          <span *ngIf="getLiabilityCopyFormLength(i) != 0">
                                            {{getLiabilityCopyFormLength(i)}} Attachment</span>
                                          <ng-template #supportingDocLocPop let-modal>
                                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                              <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')"> <span
                                                  aria-hidden="true">×</span> </button>
                                            </div>
                                            <div class="col-md-12">
                                              <label> Attachment</label>
                                              <div class="table-responsive">
                                                <table class="table table-bordered tablepop">
                                                  <thead>
                                                    <tr class="bgGred">
                                                      <th>Upload</th>
                                                      <th style="width:50%;">Remark</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr
                                                      *ngFor="let supportingDocGrp of getLiabilitySupportingDocLocAttachment(div); let j = index"
                                                      [formGroup]="supportingDocGrp">
                                                      <td>
                                                        <div class="row">
                                                          <div class="col-md-9 nopadding">
                                                            <div class="form-group">
                                                              <input
                                                                *ngIf="supportingDocGrp.value.isdocLocUploadedClicked"
                                                                type="file"
                                                                id="liabilityDetails({{i}}).copyOfFormLoc({{j}}).fileLoc"
                                                                class="filetype form-control" name="myfile"
                                                                (change)="onFileSelect($event)">
                                                              <p *ngIf="!supportingDocGrp.value.isdocLocUploadedClicked || supportingDocGrp.value.fileLoc"
                                                                class="form-control pt10">
                                                                {{getFileNameDta(supportingDocGrp.value.fileLoc)}}
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <div class="col-md-3 nopadding">
                                                            <label for="exampleInputPassword1">
                                                              <a class="downupIcon"
                                                                *ngIf="supportingDocGrp.value.fileLoc"
                                                                title="Download file"
                                                                (click)="download(supportingDocGrp.value.fileLoc)"><i
                                                                  class="fa fa-download"></i></a>
                                                              <a class="downupIcon"
                                                                *ngIf="!(supportingDocGrp.value.fileLoc || supportingDocGrp.value.fileLoc) && isDisableIcons"
                                                                title="Upload file"
                                                                (click)="uploadLiabilitySupportingDoc(i,j)"><i
                                                                  class="fa fa-upload"></i></a>
                                                              <a class="downupIcon"
                                                                (click)="deleteLiabilitySupportingDoc(i,j)"
                                                                *ngIf="(supportingDocGrp.value.fileLoc)  && isDisableIcons"><i
                                                                  class="fa fa-trash" aria-hidden="true"></i></a>
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <input type="text" class="form-control"
                                                          formControlName="remarks"
                                                          [title]="supportingDocGrp.value.remarks ? supportingDocGrp.value.remarks:''">
                                                        <div
                                                          *ngIf="supportingDocGrp.value.remarks && supportingDocGrp.value.remarks.length > 100">
                                                          <span class="text-danger">Max character Length 100</span>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                                              <button type="button" class="btn btn-outline-dark"
                                                (click)="modal.close('Save click')">Save</button>
                                            </div>
                                          </ng-template>
                                        </div>
                                      </div>

                                      <!-- supporting attachment ends -->
                                    </td>
                                    <td class="ac-no-cls"><a class=" addExtLetter"
                                        (click)="addDetailsOfLiabilityAddmittedAndPaid()"><i class="fa fa-plus"></i></a>
                                      <a class="addExtLetter" *ngIf="i !=0"
                                        (click)="openLiabilityAddmittedAndPaidPop(rmvLiabilityAddmittedAndPaidModalRef)"><i
                                          class="fa fa-minus"></i></a>
                                    </td>
                                    <ng-template #rmvLiabilityAddmittedAndPaidModalRef let-modal>
                                      <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')">
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <p>Are you sure, you want to remove?</p>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="rmLiabilityAddmittedAndPaidInv(i)">Remove</button>
                                      </div>
                                    </ng-template>
                                  </tr>

                                  <tr>
                                    <th colspan="3">Total</th>
                                    <td class="align-right bold-font">
                                      {{liabilityAddmittedAndPaidTaxTotal| INR: 0}}</td>
                                    <td class="align-right bold-font">
                                      {{liabilityAddmittedAndPaidInterestTotal| INR: 0 }}</td>
                                    <td class="align-right bold-font">
                                      {{liabilityAddmittedAndPaidPenaltyTotal | INR: 0}}</td>
                                    <td class="align-right bold-font">
                                      {{liabilityAddmittedAndPaidTotal | INR: 0}}
                                    </td>
                                  </tr>
                                </ng-container>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- Details of Liability Admitted and Paid -->


              <div data-toggle="collapse" data-target="#detailsOfAllPaymentMadeExpansion" class="expansion-div mb-15"
                (click)="detailsOfAllPaymentMadeExpansion = !detailsOfAllPaymentMadeExpansion">
                Details of All Payments Made (Cumulative) <span class="consultant-icons"><span
                    (click)="refreshCumulativeTable($event)" class="refresh-icon-cls"><i class="fa fa-refresh me-1"
                      aria-hidden="true"></i><span class="refresh-text">Refresh Cumulative Count</span></span> <i
                    *ngIf="!detailsOfAllPaymentMadeExpansion" class="fa fa-angle-down" aria-hidden="true"></i> <i
                    *ngIf="detailsOfAllPaymentMadeExpansion" class="fa fa-angle-up" aria-hidden="true"></i></span>
              </div>
              <div class="collapse" id="detailsOfAllPaymentMadeExpansion">
                <div class="row mb-15">
                  <div class="col-md-12">
                    <div class="search-table-outter wrapper">
                      <table class="search-table inner" style="width: 100%;">
                        <thead>
                          <tr class="bgGred">
                            <th>Label</th>
                            <th>Tax (₹)</th>
                            <th>Interest (₹)</th>
                            <th>Penalty (₹)</th>
                            <th>Total (₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container>
                            <tr *ngFor="let c of cumulativeCount">
                              <td>{{c.label}}</td>
                              <td class="align-right">{{c.tax | INR: 0}}</td>
                              <td class="align-right">{{c.interest | INR: 0}}</td>
                              <td class="align-right">{{c.penalty | INR: 0}}</td>
                              <td class="align-right bold-font">{{c.total | INR: 0}}</td>
                            </tr>
                          </ng-container>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div data-toggle="collapse" data-target="#detailOfIssuingAuthorityExpansion" class="expansion-div mb-15"
                (click)="detailOfIssuingAuthorityExpansion = !detailOfIssuingAuthorityExpansion">
                Details of Issuing Authority
                <span class="consultant-icons"> <i *ngIf="!detailOfIssuingAuthorityExpansion" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="detailsOfAllPaymentMadeExpansion" class="fa fa-angle-up"
                    aria-hidden="true"></i></span>
              </div>
              <div class="collapse" id="detailOfIssuingAuthorityExpansion">
                <div class="row">
                  <!-- name of officer -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Name of the Officer</label>
                      <input type="text" class="form-control" id="officer3" placeholder="" formControlName="officer3">
                    </div>
                  </div>
                  <!-- Designation -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Designation </label>
                      <!-- <input class="form-control" id="designation" formControlName="designation"/>  -->
                      <select class="form-control form-select" formControlName="designation3">
                        <ng-container *ngFor="let obj of designation">
                          <option [value]="obj.value">{{obj.key}}</option>
                        </ng-container>
                      </select>

                    </div>
                  </div>
                  <!-- Jurisdiction -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Jurisdiction </label>
                      <input class="form-control" id="designation3" formControlName="jurisdiction3" />
                    </div>
                  </div>
                  <!-- Address -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Address </label>
                      <input class="form-control" id="address3" formControlName="address3" />
                    </div>
                  </div>


                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Contact No. </label>
                      <input class="form-control" id="contactNo3" formControlName="contact3" maxlength="12"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">E-Mail. </label>
                      <input class="form-control" id="email3" formControlName="email3" />
                    </div>
                    <div *ngIf="formGrp.email3.errors">
                      <span class="text-danger" *ngIf="formGrp.email3.errors.pattern"> Please Enter Valid Email
                        ID</span>
                    </div>
                    <div *ngIf="isSubmitted && formGrp.email3.errors">
                      <span class="text-danger" *ngIf="formGrp.email3.errors.maxlength">Cannot enter more than 100
                        chars.</span>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-md-12">
                <button class="btn btn-outline-primary mr-1" (click)="updateCase('p3')">Submit</button>
                <!-- <button class="btn btn-outline-primary mr-1" (click)="updateCase('p3')" *ngIf="isUpdateBtn">Submit</button>  -->
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </form>
</div>
