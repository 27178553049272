<div class="chart-box" style="min-height: 320px; width: 100%" *ngIf="values.length > 0">
  <canvas
    baseChart
    class="chartjs-length"
    [type]="'bar'"
    [data]="chartdata"
    [options]="options"
    (chartClick)="handleClick($event)"
  >
  </canvas>
</div>

<ng-container *ngIf="values.length < 1">
  <div class="text-center no-records-found">
    <i class="fa fa-bar-chart" aria-hidden="true"></i>
    <p class="text-md text-gray">No records found.</p>
  </div>
</ng-container>
