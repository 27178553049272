<div class="tab-pane fade show active" id="intimation-01" role="tabpanel" aria-labelledby="intimation01-tab">
   <div class="row">
      <div class="col-md-4">
         <div class="d-flex justify-content-between">
            <ul class="nav nav-tabs nav nav-tabs iris-gst-display-flex" role="tablist" style="border: none">
               <li class="nav-item">
                  <a class="nav-link active" id="intimationp1-tab" data-toggle="tab" href="#intimationp1-1" role="tab"
                     aria-controls="intimation-1" aria-selected="true" (click)="tabChanged('tab1')">Phase 1</a>
               </li>
               <li class="nav-item" [ngClass]="{'disabled': !isPhase2 && !(action == 'update')}">
                  <a class="nav-link" data-toggle="tab" role="tab" aria-controls="intimationp02-1"
                     href="#intimationp02-1" aria-selected="false" (click)="tabChanged('tab2')" #subTab>Phase 2</a>
               </li>
            </ul>
         </div>
      </div>
      <div class="col-md-4">
         <div class="textCenter pt10">
            <span class="infoVal fs16" *ngIf="isVisibleCaseId" title="Case ID">{{caseData.caseId}}</span>
         </div>
      </div>
      <div class="col-md-4">
         <div>
            <span class="computationBtn" *ngIf="caseData">
               <span class="infoTitle">Last Updated By :</span>{{caseData.updatedBy}} <span class="infoVal mr5"></span>
               <span class="infoTitle">On:</span> {{caseData.updateDate}} <span class="infoVal"></span>
            </span>
         </div>
      </div>
   </div>
   <div class="tab-content nopadding">
      <div class="tab-pane fade show active" id="intimationp1-1" role="tabpanel" aria-labelledby="intimationp1-tab">
         <div class="card borderOrange">
            <div class="card-body ptl10 plr010">
               <div class="row">
                  <div class="col-2" id="verticalTab" *ngIf="!sidemenu">
                     <ul class="nav nav-tabs nav-tabs-vertical" role="tablist">
                        <li class="nav-item">
                           <a class="nav-link active" id="gi-tab-vertical" data-toggle="tab" href="#gi-2" role="tab"
                              aria-controls="gi-2" aria-selected="true"> General Info</a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab2Disable && !(action == 'update') && !(action == 'view'))}">
                           <a class="nav-link" id="noticeTab" data-toggle="tab" href="#notice-2" role="tab"
                              aria-controls="notice-2" aria-selected="false" #noticeTab> Notice </a>
                        </li>
                        <li class="nav-item" *ngIf="!updateBtn">
                           <a class="btn btn-outline-primary w100"
                              [ngClass]="{'disabled': (!tab3Disable && !(action == 'update' ))}" (click)="saveBtn()"
                              #save1> Save </a>
                        </li>
                        <li class="nav-item" *ngIf="updateBtn">
                           <a class="btn btn-outline-primary w100" [ngClass]="{'disabled': (action == 'view')}"
                              (click)=" updatePhase1Btton()"> Update</a>
                        </li>
                     </ul>
                  </div>
                  <div class="col-10 border " id="tabArea" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
                     <div class="tab-content tab-content-vertical">
                        <div class="tab-pane fade show active" id="gi-2" role="tabpanel"
                           aria-labelledby="gi-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div [formGroup]="generalInfo">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <p class="textCenter">General Info</p>
                                       <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                          <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                          <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                       </span>
                                    </div>
                                    <div class="col-md-3" *ngIf="isVisibleCaseId">
                                       <div class="form-group">
                                          <label>Case ID
                                             <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="caseId" class="form-control" id="caseId"
                                             readonly />
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>PAN<span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="pan" class="form-control" readonly id=""
                                             placeholder="" id="pan" />
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Legal PAN Name <span class="mandate"> <i
                                                   class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="panName" class="form-control" readonly
                                             placeholder="" id="panName" />
                                       </div>
                                    </div>
                                    <div class="col-md-3" [ngClass]="{'disabled':isDisabledSelect}">
                                       <div class="form-group">
                                          <label>Assessment Year
                                             <span class="mandate"><i class="fa fa-asterisk"></i></span> </label>
                                          <select class="form-control form-select sd" id="ay" formControlName="ay"
                                          (change)="getperiodToValue(ayPop)"
                                             [ngClass]="{errorBorder:isNextBtnClicked && !generalInfo.controls.ay.value }">
                                             <ng-container *ngFor="let obj of finyearData">
                                                <option [value]="obj">{{ obj }}</option>
                                             </ng-container>
                                          </select>
                                          <div *ngIf="isNextBtnClicked && !generalInfo.controls.ay.value">
                                             <span class="text-danger">Assessment Year Required</span>
                                          </div>
                                          <ng-template #ayPop let-modal>
                                             <div class="modal-header" style="border: none; padding-bottom: 15px; padding-top:9px" >
                                               <button type="button" class="close" aria-label="Close"
                                                 (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                                               </button>
                                             </div>
                                             <div class="col-md-12" >
                                               <div class="table-responsive">
                                                 <p class="title-data">Following cases are already exist for same selected Assessment Year,<span style="font-weight: bold;">Do you want to still proceed?</span></p>
                                                 <table style="margin-left: 5px;">
                                                   <thead>
                                                     <tr class="bg-primary text-dblue">
                                                       <th class="sl-no-cla">Sr No</th>
                                                       <th class="clas">Case id</th>
                                                       <th>Case label</th>
                                                       <th class="clas">Assessment Year</th> 
                                                       <th>Forum</th>
                                                       <th class="ac-no-clas">Total Demand(₹)</th>
                                                     </tr>
                                                   </thead>
                                                   <tbody>
                                                     <tr
                                                       *ngFor="let obj of duplicateData  let i = index">
                                                       <td class="sl-no-cla">{{i + 1}}</td>
                                                       <td class="clas">{{obj.caseId}}</td>
                                                       <td>{{obj.caseLabel}}</td>
                                                       <td class="clas">{{obj.ay}}</td>
                                                       <td>{{obj.forum}}</td>
                                                       <td class="ac-no-clas" style="text-align: right;">{{obj.demandAmount | INR: 0}} </td>
                                                     </tr>
                                                   </tbody>
                                                 </table>
                                               </div>
                                             </div>
                           
                                             <div class="modal-footer" style="border: none; padding-top: 5px;" >
                                               <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                                               (click)="modal.close('No click')">Yes</button>
                                                 <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                                                 (click)="goToSummary()">NO</button>
                                             </div>
                                           </ng-template>
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Financial Year<span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="finYear" readonly class="form-control"
                                             placeholder="" id="finYear"
                                             [ngClass]="{errorBorder: isNextBtnClicked && !generalInfo.controls.finYear.value}" />
                                          <div *ngIf="isNextBtnClicked && !generalInfo.controls.finYear.value">
                                             <span class="text-danger">Financial Year Required</span>
                                          </div>
                                       </div>
                                    </div>
                                    <!-- <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Consultant Name</label>
                                          <input type="text" formControlName="consultant" id="consultant"
                                             class="form-control" placeholder=""
                                             [ngClass]="{ errorBorder: generalInfo.controls.consultant.hasError('maxlength')}" />
                                       </div>
                                       <div class="text-danger"
                                          *ngIf="generalInfo.controls.consultant.touched &&  generalInfo.controls.consultant.hasError('maxlength')">
                                          Max 100 Character allowed
                                       </div>
                                    </div> -->

                                    <!-- <div class="col-md-3">
                                       <div class="form-group">
                                         <label for="exampleInputPassword1">Consultant</label>
                       
                                         <div *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" class="input-group">
                                           <input type="text" class="form-control"
                                             *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" placeholder=""
                                             formControlName="consultant" readonly>
                                           <button *ngIf="isToggleConsultantDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown()">
                                             <i class="fa fa-pencil"></i>
                                           </button> 
                                         </div>
                       
                                         <select class="form-control form-select" id="consultant"
                                             *ngIf="!isToggleConsultantDropdown || action==='create'" formControlName="consultant">
                                             <option [value]="null">-- Select --</option>
                                             <ng-container *ngFor="let obj of consultantList">
                                               <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                             </ng-container>
                                           </select>
                                       </div>
                                    </div> -->

                                    <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create' )">
                                       <div class="form-group">
                                         <label for="exampleInputPassword1">Consultant</label>
                                     
                                         <!-- For Consultant Role: Create, View, and Update -->
                                         <div *ngIf="roleName === 'Consultant' && action === 'create'">
                                           <input 
                                             type="text" 
                                             class="form-control" 
                                             placeholder="Consultant Name" 
                                             formControlName="consultant" 
                                             [readOnly]="action === 'create'">
                                         </div>
                                     
                                         <!-- For Non-Consultant Role -->
                                         <div *ngIf="roleName !== 'Consultant'">
                                           <div *ngIf="isToggleConsultantDropdown && (action === 'update' || action === 'view')" class="input-group">
                                             <input 
                                               type="text" 
                                               class="form-control" 
                                               placeholder="" 
                                               formControlName="consultant" 
                                               readonly>
                                             <button 
                                               *ngIf="isToggleConsultantDropdown && action === 'update'" 
                                               class="btn btn-outline-secondary" 
                                               type="button" 
                                               (click)="onClickToggleConsultantDropdown('p1')">
                                               <i class="fa fa-pencil"></i>
                                             </button>
                                           </div>
                                     
                                           <select 
                                             class="form-control form-select" 
                                             id="consultant" 
                                             *ngIf="!isToggleConsultantDropdown || action === 'create'" 
                                             formControlName="consultant">
                                             <option [value]="null">-- Select --</option>
                                             <ng-container *ngFor="let obj of consultantList">
                                               <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                                             </ng-container>
                                           </select>
                                         </div>
                                       </div>
                                    </div>
                                     

                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Person responsible <span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span></label>
                                                   
                                                   <div *ngIf="isToggleDropdown && action==='update' || action==='view'" class="input-group">
																		<input class="form-control" type="text" formControlName="personResp" readonly>
																		<button *ngIf="isToggleDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleDropdown()">
																		  <i class="fa fa-pencil"></i>
																		</button>
																	  </div>
													
																	  <select *ngIf="!isToggleDropdown || action==='create'" class="form-control form-select" id="personResp" formControlName="personResp" (change)="onClickToggleDropdown()"
																		[ngClass]="{ 'errorBorder': isNextBtnClicked && generalInfo.controls.personResp.errors }">
																		<option [value]="null">-- Select --</option>
																		<ng-container *ngFor="let obj of userRoleLs">
																		  <option [value]="obj.username">{{obj.username}}</option>
																		</ng-container>
																	  </select>
                                          <div *ngIf="isNextBtnClicked && !generalInfo.controls.personResp.value">
                                             <span class="text-danger">Person responsible Required</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="row">
                                       <div class="col-md-12">
                                          <div class="form-group">
                                             <label>Case Label
                                                <span class="mandate"><i class="fa fa-asterisk"></i></span><a
                                                   class="clrOrange infoIcon"
                                                   title="Please give a one line description to identify the case."><i
                                                      class="fa fa-info-circle"
                                                      style="margin-left: 3px"></i></a></label>
                                             <textarea class="form-control" id="caseLabel" rows="4"
                                                formControlName="caseLabel"
                                                [ngClass]="{ errorBorder:(isNextBtnClicked && !generalInfo.controls.caseLabel.value) || (isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length < 5) || (isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length > 125) }"></textarea>
                                          </div>
                                          <div *ngIf="isNextBtnClicked &&  !generalInfo.controls.caseLabel.value">
                                             <span class="text-danger">Case Label Required</span>
                                          </div>
                                          <div
                                             *ngIf="isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length < 5">
                                             <span class="text-danger">Please Enter Min 5 characters</span>
                                          </div>
                                          <div
                                             *ngIf="isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length > 125">
                                             <span class="text-danger">Please Enter Max 125 characters</span>
                                          </div>
                                       </div>

                                       <div class="col-md-12">
                                          <div class="form-group">
                                             <label>Case Summary </label>
                                             <textarea class="form-control" id="caseSummary"
                                                formControlName="caseSummary" rows="4"
                                                [ngClass]="{ errorBorder: generalInfo.controls.caseSummary.hasError('maxlength')}">
                                          </textarea>
                                          </div>
                                          <div class="text-danger"
                                             *ngIf="generalInfo.controls.caseSummary.touched &&  generalInfo.controls.caseSummary.hasError('maxlength')">
                                             Maximum of 1000 characters
                                          </div>
                                       </div>

                                       <div class="col-md-12">
                                          <div class="form-group">
                                             <label>Internal Remarks </label>
                                             <textarea class="form-control" id="internalRemarks" rows="4"
                                                formControlName="internalRemarks"
                                                [ngClass]="{ errorBorder: generalInfo.controls.internalRemarks.hasError('maxlength')}"></textarea>
                                          </div>
                                          <div class="text-danger"
                                             *ngIf="generalInfo.controls.internalRemarks.touched &&  generalInfo.controls.internalRemarks.hasError('maxlength')">
                                             Maximum of 1000 characters
                                          </div>
                                       </div>

                                       <div class="col-md-12 mt15 mb-3">
                                          <button class="btn btn-outline-primary mr-1" (click)="submitgeneralInfo()">
                                             Next </button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </fieldset>
                        </div>
                        <div class="tab-pane fade" id="notice-2" role="tabpanel" aria-labelledby="notice-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="row">
                              <div class="col-md-12">
                                 <p class="textCenter">Notice</p>
                                 <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                    <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                    <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                 </span>
                              </div>

                              <div class="col-md-12">
                                 <fieldset [disabled]="action == 'view'">
                                    <form [formGroup]="noticeForm">
                                       <div formArrayName="dtNotices">
                                          <ul class="nav nav-pills iris-gst-display-flex" role="tablist">
                                             <li class="nav-item" id="noticeTab{{i}}"
                                                *ngFor="let div of getFormControlNotice(); let i=index"
                                                [formGroupName]="i">
                                                <a class="nav-link" id="notice1-tab" data-toggle="tab" role="tab"
                                                   (click)="phaseChanged('phase' + i, i)"
                                                   [ngClass]="(selectedNotice === 'phase' + i) ? 'active': ''"
                                                   aria-controls="notice1-1" aria-selected="true">Notice{{i+1}}</a>
                                             </li>
                                          </ul>
                                          <div class="tab-content nopadding">
                                             <div class="tab-pane fade show active" id="notice1-1" role="tabpanel"
                                                aria-labelledby="notice1-tab">
                                                <div class="col-md-12 noPadding " id="noticeTabDiv{{i}}"
                                                   *ngFor="let noticeTabdiv of getFormControlNotice(); let i=index"
                                                   [formGroupName]="i">
                                                   <div *ngIf="selectedNotice === 'phase' + i" class="show-tab-content">
                                                      <div class="accordion accordion-solid-header " id="accordion-16"
                                                         role="tablist">
                                                         <div id="collapse-16" class="collapse show" role="tabpanel"
                                                            aria-labelledby="heading-16" data-parent="#accordion-16">
                                                            <div class="card-body noPadding">
                                                               <div class="row">
                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Type of Issuing Authority <span
                                                                              class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span></label>
                                                                        <select class="form-control form-select"
                                                                           [ngClass]="{errorBorder: isSubmittedNotice && !getFormControlNotice()[i].controls['issuingAuth'].value}"
                                                                           id="authority" formControlName="issuingAuth">
                                                                           <option [value]="null"> -- Select --
                                                                           </option>
                                                                           <ng-container
                                                                              *ngFor="let obj of preLitPH1Preceded">
                                                                              <option [value]="obj.value">{{ obj.label}}
                                                                              </option>
                                                                           </ng-container>
                                                                        </select>
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedNotice && !getFormControlNotice()[i].controls['issuingAuth'].value">
                                                                        <span class="text-danger"
                                                                           *ngIf="!getFormControlNotice()[i].controls['issuingAuth'].value">Type
                                                                           of Issuing Authority Required</span>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Notice Reference Number / DIN <span
                                                                              class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span></label>
                                                                        <input type="text" formControlName="noticeRefNo"
                                                                           class="form-control" placeholder=""
                                                                           [ngClass]="{errorBorder: isSubmittedNotice && !getFormControlNotice()[i].controls['noticeRefNo'].value }" />
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedNotice && !getFormControlNotice()[i].controls['noticeRefNo'].value">
                                                                        <span class="text-danger"
                                                                           *ngIf="!getFormControlNotice()[i].controls['noticeRefNo'].value">Notice
                                                                           Reference Number / DIN Required</span>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Notice Type </label>
                                                                        <select class="form-control form-select"
                                                                           formControlName="noticeType" id="noticeType">
                                                                           <option [value]="null"> -- Select --
                                                                           </option>
                                                                           <ng-container
                                                                              *ngFor=" let obj of typeOfNotice">
                                                                              <option [value]="obj.value"> {{ obj.label
                                                                                 }} </option>
                                                                           </ng-container>
                                                                        </select>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Date of Notice <span class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span></label>
                                                                        <div id="iris-gst-filter-frmDate"
                                                                           class="input-group date datepicker">
                                                                           <p-calendar class="form-control"
                                                                              formControlName="noticeDate"
                                                                              [maxDate]="maxDate"
                                                                              [monthNavigator]="true"
                                                                              [yearNavigator]="true"
                                                                              yearRange="2010:{{currentYear}}"
                                                                              dateFormat="dd-mm-yy" [showIcon]="true"
                                                                              dataType="string"
                                                                              [ngClass]="{errorBorder: isSubmittedNotice && !getFormControlNotice()[i].controls['noticeDate'].value}"></p-calendar>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedNotice &&!getFormControlNotice()[i].controls['noticeDate'].value">
                                                                        <span class="text-danger"
                                                                           *ngIf="!getFormControlNotice()[i].controls['noticeDate'].value">
                                                                           Date of Notice Required</span>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Date of Receipt of Notice<span
                                                                              class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span></label>
                                                                        <div id="iris-gst-filter-frmDate"
                                                                           class="input-group date datepicker">
                                                                           <p-calendar class="form-control"
                                                                              formControlName="noticeReceiptDate"
                                                                              [maxDate]="maxDate"
                                                                              [monthNavigator]="true"
                                                                              [yearNavigator]="true"
                                                                              yearRange="2010:{{currentYear}}"
                                                                              dateFormat="dd-mm-yy" [showIcon]="true"
                                                                              dataType="string"
                                                                              [ngClass]="{errorBorder: isSubmittedNotice && !getFormControlNotice()[i].controls['noticeReceiptDate'].value}"></p-calendar>
                                                                        </div>
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedNotice &&!getFormControlNotice()[i].controls['noticeReceiptDate'].value">
                                                                        <span class="text-danger"
                                                                           *ngIf="!getFormControlNotice()[i].controls['noticeReceiptDate'].value">
                                                                           Date of Receipt of Notice Required</span>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3 disabledSelect">
                                                                     <div class="form-group">
                                                                        <label>Mode of Receipt of Notice</label>
                                                                        <select class="form-control form-select"
                                                                           formControlName="noticeReceiptMode">
                                                                           <option [value]="null"> -- Select --</option>
                                                                           <ng-container
                                                                              *ngFor=" let obj of modeOfAppealOptions">
                                                                              <option [value]="obj.value"> {{
                                                                                 obj.value}} </option>
                                                                           </ng-container>
                                                                        </select>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <div class="form-group-Extension">
                                                                           <label>Reply Due Date<span
                                                                                 class="mandate-icon"><i
                                                                                    class="fa fa-asterisk"></i></span></label>
                                                                           <div id="iris-gst-filter-frmDate"
                                                                              class="input-group date datepicker">
                                                                              <p-calendar class="form-control"
                                                                                 formControlName="legalDueDate"
                                                                                 [monthNavigator]="true"
                                                                                 [yearNavigator]="true"
                                                                                 yearRange="2010:{{currentYear}}"
                                                                                 dateFormat="dd-mm-yy" [showIcon]="true"
                                                                                 dataType="string"
                                                                                 [ngClass]="{ errorBorder:isSubmittedNotice &&!getFormControlNotice()[i].controls['legalDueDate'].value}">
                                                                              </p-calendar>
                                                                           </div>
                                                                           <div
                                                                              *ngIf="isSubmittedNotice &&!getFormControlNotice()[i].controls['legalDueDate'].value">
                                                                              <span class="text-danger"
                                                                                 *ngIf="!getFormControlNotice()[i].controls['legalDueDate'].value">
                                                                                 Reply due date Required</span>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <div class="form-group-Extension">
                                                                           <label>Internal Due Date</label>
                                                                           <div id="iris-gst-filter-frmDate"
                                                                              class="input-group date datepicker">
                                                                              <p-calendar class="form-control"
                                                                                 formControlName="internalDueDate"
                                                                                 [monthNavigator]="true"
                                                                                 [yearNavigator]="true"
                                                                                 yearRange="2010:{{currentYear}}"
                                                                                 dateFormat="dd-mm-yy" [showIcon]="true"
                                                                                 dataType="string">
                                                                              </p-calendar>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Is Adjournment/Extension Applied?</label>
                                                                        <select class="form-control form-select"
                                                                           id="adjApp1" formControlName="isExtApplied">
                                                                           <ng-container
                                                                              *ngFor=" let obj of ajaSubDetailsOptions">
                                                                              <option [value]="obj.value">{{obj.label}}
                                                                              </option>
                                                                           </ng-container>
                                                                        </select>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3" id="showAdjExtDueDate1"
                                                                     *ngIf="getFormControlNotice()[i].controls['isExtApplied'].value == 'true' || getFormControlNotice()[i].controls['isExtApplied'].value == true">
                                                                     <div class="form-group">
                                                                        <label>Adjourned/Extended Due Date <span
                                                                              class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span></label>
                                                                        <div id="iris-gst-filter-frmDate"
                                                                           class="input-group date datepicker">
                                                                           <p-calendar class="form-control"
                                                                              formControlName="extDueDate"
                                                                              [monthNavigator]="true"
                                                                              [yearNavigator]="true"
                                                                              yearRange="2010:{{currentYear}}"
                                                                              dateFormat="dd-mm-yy" [showIcon]="true"
                                                                              dataType="string"
                                                                              [ngClass]="{ errorBorder:isSubmittedNotice &&!getFormControlNotice()[i].controls['extDueDate'].value}">
                                                                           </p-calendar>
                                                                        </div>
                                                                        <div
                                                                           *ngIf="isSubmittedNotice && !getFormControlNotice()[i].controls['extDueDate'].value">
                                                                           <span class="text-danger"
                                                                              *ngIf="!getFormControlNotice()[i].controls['extDueDate'].value">
                                                                              Adjourned/Extended Due Date Required
                                                                           </span>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-12" id="showAdjTbl1"
                                                                     *ngIf="getFormControlNotice()[i].controls['isExtApplied'].value == 'true' || getFormControlNotice()[i].controls['isExtApplied'].value == true">
                                                                     <div class="accordion accordion-solid-header"
                                                                        id="accordion-02" role="tablist">
                                                                        <div class="card border-bottom">
                                                                           <div class="card-header" role="tab"
                                                                              id="heading-02">
                                                                              <h6 class="mb-0">
                                                                                 <a data-toggle="collapse"
                                                                                    href="#collapse-02"
                                                                                    aria-expanded="true"
                                                                                    aria-controls="collapse-2">
                                                                                    Adjournment Submission Details </a>
                                                                              </h6>
                                                                           </div>
                                                                           <div id="collapse-02" class="collapse show"
                                                                              role="tabpanel"
                                                                              aria-labelledby="heading-02"
                                                                              data-parent="#accordion-02">
                                                                              <div class="card-body">
                                                                                 <div class="row">
                                                                                    <div class="table-responsive">
                                                                                       <table
                                                                                          class="search-table inner">
                                                                                          <thead>
                                                                                             <tr class="bgGred">
                                                                                                <th class="w-40">Sr.No
                                                                                                </th>
                                                                                                <th>Date of Adjournment
                                                                                                   Filed</th>
                                                                                                <th>New Adjourned Date
                                                                                                </th>
                                                                                                <th>Reason For
                                                                                                   Adjournment</th>
                                                                                                <th>Granted</th>
                                                                                                <th>Upload </th>
                                                                                                <th class="w-80">Action
                                                                                                </th>
                                                                                             </tr>
                                                                                          </thead>
                                                                                          <tbody>
                                                                                             <tr *ngFor="let ajaSubDetailsFrmGrp of getAjaSubDetails(noticeTabdiv); let k=index"
                                                                                                [formGroup]="ajaSubDetailsFrmGrp">
                                                                                                <td
                                                                                                   class="sl-no-cls w-40">
                                                                                                   {{k + 1}}</td>
                                                                                                <td>
                                                                                                   <div
                                                                                                      id="iris-gst-filter-frmDate"
                                                                                                      class="input-group date datepicker"
                                                                                                      [title]="ajaSubDetailsFrmGrp.value.datepicker ? ajaSubDetailsFrmGrp.value.datepicker:''">
                                                                                                      <p-calendar
                                                                                                         appendTo="body"
                                                                                                         class="form-control"
                                                                                                         formControlName="adjFiledDate"
                                                                                                         dateFormat="dd-mm-yy"
                                                                                                         [showIcon]="true"
                                                                                                         dataType="string"
                                                                                                         [maxDate]="maxDate"
                                                                                                         [monthNavigator]="true"
                                                                                                         [yearNavigator]="true"
                                                                                                         yearRange="2010:{{currentYear}}"
                                                                                                         dateFormat="dd-mm-yy"></p-calendar>
                                                                                                   </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                   <div
                                                                                                      id="iris-gst-filter-frmDate"
                                                                                                      class="input-group date datepicker"
                                                                                                      [title]="ajaSubDetailsFrmGrp.value.newAdjDate ? ajaSubDetailsFrmGrp.value.newAdjDate:''"
                                                                                                      [ngClass]="{ 'errorBorder': isSubmittedNotice && ajaSubDetailsFrmGrp.get('newAdjDate')?.errors}">
                                                                                                      <p-calendar
                                                                                                         appendTo="body"
                                                                                                         class="form-control"
                                                                                                         formControlName="newAdjDate"
                                                                                                         dateFormat="dd-mm-yy"
                                                                                                         [showIcon]="true"
                                                                                                         dataType="string"
                                                                                                         [monthNavigator]="true"
                                                                                                         [yearNavigator]="true"
                                                                                                         yearRange="2010:{{currentYear}}"
                                                                                                         dateFormat="dd-mm-yy"></p-calendar>
                                                                                                   </div>
                                                                                                </td>
                                                                                                <td><input type="text"
                                                                                                      class="form-control"
                                                                                                      placeholder=""
                                                                                                      formControlName="adjReason"
                                                                                                      [ngClass]="{ errorBorder: ajaSubDetailsFrmGrp.get('adjReason')?.touched && ajaSubDetailsFrmGrp.get('adjReason')?.hasError('maxlength')}"
                                                                                                      [title]="ajaSubDetailsFrmGrp.value.adjReason ? ajaSubDetailsFrmGrp.value.adjReason: ''" />
                                                                                                   <div
                                                                                                      class="text-danger"
                                                                                                      *ngIf="ajaSubDetailsFrmGrp.get('adjReason')?.touched && ajaSubDetailsFrmGrp.get('adjReason')?.hasError('maxlength')">
                                                                                                      Max 100 Character
                                                                                                      allowed
                                                                                                   </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                   <select
                                                                                                      class="form-control form-select"
                                                                                                      id="isGranted"
                                                                                                      formControlName="isGranted"
                                                                                                      [title]="ajaSubDetailsFrmGrp.value.isGranted ? ajaSubDetailsFrmGrp.value.isGranted: ''">
                                                                                                      <option
                                                                                                         [value]="null">
                                                                                                         -- Select --
                                                                                                      </option>
                                                                                                      <ng-container
                                                                                                         *ngFor="let obj of grantedOptions">
                                                                                                         <option
                                                                                                            [value]="obj.value">
                                                                                                            {{obj.label}}
                                                                                                         </option>
                                                                                                      </ng-container>
                                                                                                   </select>
                                                                                                </td>
                                                                                                <td><span
                                                                                                      (click)="openDetailPop(adjDocLocFormPop, i)"><i
                                                                                                         class="icon fa fa fa-paperclip"
                                                                                                         style="cursor: pointer;"
                                                                                                         title="Attach file"></i>
                                                                                                      <span
                                                                                                         *ngIf="getAdjLength(i, k) != 0">
                                                                                                         {{getAdjLength(i,
                                                                                                         k)}}
                                                                                                         Attachment</span></span>
                                                                                                </td>
                                                                                                <td class="w-80"> <a
                                                                                                      class="addExtLetter"
                                                                                                      (click)="addAjaSubDetails(i) "><i
                                                                                                         class="fa fa-plus"></i></a>
                                                                                                   <a class="addExtLetter"
                                                                                                      *ngIf="k!= 0"
                                                                                                      (click)="openRemoveAjaSubDetailsPop(rmvadjSubDetailsModalRef,i)"><i
                                                                                                         class="fa fa-minus"></i></a>
                                                                                                   <ng-template
                                                                                                      #rmvadjSubDetailsModalRef
                                                                                                      let-modal>
                                                                                                      <div
                                                                                                         class="modal-header">
                                                                                                         <h4 class="modal-title"
                                                                                                            id="modal-basic-title">
                                                                                                            Remove
                                                                                                            Warning!
                                                                                                         </h4>
                                                                                                         <button
                                                                                                            type="button"
                                                                                                            class="close"
                                                                                                            aria-label="Close"
                                                                                                            (click)="modal.dismiss('Cross click' )"><span
                                                                                                               aria-hidden="true">×</span>
                                                                                                         </button>
                                                                                                      </div>
                                                                                                      <div
                                                                                                         class="modal-body">
                                                                                                         <p> Are you
                                                                                                            sure, you
                                                                                                            want to
                                                                                                            remove?</p>
                                                                                                      </div>
                                                                                                      <div
                                                                                                         class="modal-footer">
                                                                                                         <button
                                                                                                            type="button"
                                                                                                            class="btn btn-outline-dark"
                                                                                                            (click)="rmAjaSubInv(i,k)">
                                                                                                            Remove</button>
                                                                                                      </div>
                                                                                                   </ng-template>
                                                                                                   <ng-template
                                                                                                      #adjDocLocFormPop
                                                                                                      let-modal>
                                                                                                      <div
                                                                                                         class="modal-header"
                                                                                                         style="border: none; padding-bottom: 0px;">
                                                                                                         <button
                                                                                                            type="button"
                                                                                                            class="close"
                                                                                                            aria-label="Close"
                                                                                                            (click)="modal.dismiss('Cross click')">
                                                                                                            <span
                                                                                                               aria-hidden="true">×</span>
                                                                                                         </button>
                                                                                                      </div>
                                                                                                      <div
                                                                                                         class="col-md-12">
                                                                                                         <label>Attachment</label>
                                                                                                         <div
                                                                                                            class="table-responsive">
                                                                                                            <table
                                                                                                               class="table table-bordered">
                                                                                                               <thead>
                                                                                                                  <tr
                                                                                                                     class="bgGred">
                                                                                                                     <th>
                                                                                                                        Upload
                                                                                                                     </th>
                                                                                                                     <th
                                                                                                                        style="width:50%;">
                                                                                                                        Remark
                                                                                                                        (Max
                                                                                                                        100
                                                                                                                        Character
                                                                                                                        allowed)
                                                                                                                     </th>
                                                                                                                  </tr>
                                                                                                               </thead>
                                                                                                               <tbody>
                                                                                                                  <tr *ngFor="let getadjDocFromGrp of getadjDocLoc(ajaSubDetailsFrmGrp); let j=index"
                                                                                                                     [formGroup]="getadjDocFromGrp">
                                                                                                                     <td>
                                                                                                                        <div
                                                                                                                           class="row">
                                                                                                                           <div
                                                                                                                              class="col-md-9 nopadding">
                                                                                                                              <div
                                                                                                                                 class="form-group">
                                                                                                                                 <input
                                                                                                                                    *ngIf="getadjDocFromGrp.value.isdocLocUploadedClicked"
                                                                                                                                    type="file"
                                                                                                                                    id="dtNotices({{i}}).adjSubDetails({{k}}).adjDocsLoc({{j}}).fileLoc"
                                                                                                                                    class="filetype form-control"
                                                                                                                                    name="myfile"
                                                                                                                                    (change)="onFileSelect($event,'notice')">
                                                                                                                                 <p *ngIf="!getadjDocFromGrp.value.isdocLocUploadedClicked || getadjDocFromGrp.value.fileLoc"
                                                                                                                                    class="form-control pt10">
                                                                                                                                    {{getFileNameDta(getadjDocFromGrp.value.fileLoc)}}
                                                                                                                                 </p>
                                                                                                                              </div>
                                                                                                                           </div>
                                                                                                                           <div
                                                                                                                              class="col-md-3 nopadding">
                                                                                                                              <label>
                                                                                                                                 <a class="downupIcon"
                                                                                                                                    *ngIf="getadjDocFromGrp.value.fileLoc"
                                                                                                                                    title="Download file"
                                                                                                                                    (click)="download(getadjDocFromGrp.value.fileLoc)"><i
                                                                                                                                       class="fa fa-download"></i></a>
                                                                                                                                 <a class="downupIcon"
                                                                                                                                    *ngIf="!(getadjDocFromGrp.value.fileLoc || getadjDocFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                                                    title="Upload file"
                                                                                                                                    (click)="uploadAdjDetails(i,j,k)"><i
                                                                                                                                       class="fa fa-upload"></i></a>
                                                                                                                                 <a class="downupIcon"
                                                                                                                                    (click)="deletedocLocFile(i,j,k)"
                                                                                                                                    *ngIf="(getadjDocFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                                                       class="fa fa-trash"
                                                                                                                                       aria-hidden="true"></i></a>
                                                                                                                              </label>

                                                                                                                           </div>
                                                                                                                        </div>
                                                                                                                     </td>
                                                                                                                     <td>
                                                                                                                        <input
                                                                                                                           type="text"
                                                                                                                           class="form-control"
                                                                                                                           placeholder=""
                                                                                                                           id="remarks"
                                                                                                                           formControlName="remarks"
                                                                                                                           [title]="getadjDocFromGrp.value.remarks ? getadjDocFromGrp.value.remarks: ''"
                                                                                                                           maxlength="100" />
                                                                                                                     </td>
                                                                                                                  </tr>
                                                                                                               </tbody>
                                                                                                            </table>
                                                                                                         </div>
                                                                                                      </div>
                                                                                                      <div
                                                                                                         class="modal-footer"
                                                                                                         style="border: none; padding-top: 0px;">
                                                                                                         <button
                                                                                                            type="button"
                                                                                                            class="btn btn-outline-dark"
                                                                                                            (click)="modal.close('Save click')">Save</button>
                                                                                                      </div>
                                                                                                   </ng-template>
                                                                                                </td>
                                                                                             </tr>
                                                                                          </tbody>
                                                                                       </table>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-12">
                                                                     <div class="accordion accordion-solid-header"
                                                                        id="accordion-03" role="tablist">
                                                                        <div class="card border-bottom">
                                                                           <div class="card-header" role="tab"
                                                                              id="heading-03">
                                                                              <h6 class="mb-0">
                                                                                 <a data-toggle="collapse"
                                                                                    href="#collapse-03"
                                                                                    aria-expanded="true"
                                                                                    aria-controls="collapse-03">
                                                                                    List of Details/Documents Required
                                                                                 </a>
                                                                              </h6>
                                                                           </div>
                                                                           <div id="collapse-03" class="collapse show"
                                                                              role="tabpanel"
                                                                              aria-labelledby="heading-03"
                                                                              data-parent="#accordion-03">
                                                                              <div class="card-body">
                                                                                 <div class="row">
                                                                                    <div class="table-responsive">
                                                                                       <table
                                                                                          class="table table-bordered">
                                                                                          <tr class="bgGred">
                                                                                             <th class="w-40">Sr.No.
                                                                                             </th>
                                                                                             <th
                                                                                                style="min-width:444px;">
                                                                                                Document/ Details
                                                                                                Required</th>
                                                                                             <th
                                                                                                style="min-width:400px;">
                                                                                                Remarks</th>
                                                                                             <th class="w-80">Action
                                                                                             </th>
                                                                                          </tr>
                                                                                          <tr *ngFor="let div of getreqDetails(noticeTabdiv); let j= index"
                                                                                             [formGroup]="div">
                                                                                             <td class="sl-no-cls w-40">
                                                                                                {{j + 1}}</td>
                                                                                             <td> <input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="docReq"
                                                                                                   [title]="div.value.docReq ? div.value.docReq: '' "
                                                                                                   [ngClass]="{ errorBorder:div.get('docReq')?.errors?.pattern}" />
                                                                                                <div class="text-danger"
                                                                                                   *ngIf="div.get('docReq')?.errors?.pattern">
                                                                                                   Enter atleast 3
                                                                                                   characters </div>
                                                                                             </td>
                                                                                             <td> <input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   id="remarks"
                                                                                                   formControlName="remarks"
                                                                                                   [title]="div.value.remarks ? div.value.remarks: ''">
                                                                                                <div class="text-danger"
                                                                                                   *ngIf="div.get('remarks')?.touched && div.get('remarks')?.hasError('maxlength')">
                                                                                                   Maximum of 150
                                                                                                   characters
                                                                                                </div>
                                                                                             </td>
                                                                                             <td class="w-80">
                                                                                                <a class="addExtLetter"
                                                                                                   (click)="addreqDetails(i) "><i
                                                                                                      class="fa fa-plus"></i></a>
                                                                                                <a class="addExtLetter"
                                                                                                   *ngIf="j!= 0"
                                                                                                   (click)="openRemovereqDetailsPop(rmreqDetailsPop,i)"><i
                                                                                                      class="fa fa-minus"></i></a>
                                                                                             </td>
                                                                                             <ng-template
                                                                                                #rmreqDetailsPop
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header">
                                                                                                   <h4 class="modal-title"
                                                                                                      id="modal-basic-title">
                                                                                                      Remove Warning!
                                                                                                   </h4>
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="modal-body">
                                                                                                   <p>Are you sure, you
                                                                                                      want to remove?
                                                                                                   </p>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="rmreqDetailsInv(i,j)">Remove</button>
                                                                                                </div>
                                                                                             </ng-template>
                                                                                          </tr>
                                                                                       </table>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>


                                                                  <div class="col-md-12">
                                                                     <div class="form-group">
                                                                        <label>Summary of the correspondence </label>
                                                                        <textarea id="noticeSummary" rows="4"
                                                                           class="form-control ng-pristine ng-valid ng-touched"
                                                                           formControlName="noticeSummary"
                                                                           [title]="noticeTabdiv.value.noticeSummary ? noticeTabdiv.value.noticeSummary: ''"
                                                                           [ngClass]="{ errorBorder:noticeTabdiv.get('noticeSummary')?.hasError('maxlength')}">
                                                                     </textarea>
                                                                     </div>
                                                                     <div class="text-danger"
                                                                        *ngIf="noticeTabdiv.get('noticeSummary')?.touched && noticeTabdiv.get('noticeSummary')?.hasError('maxlength')">
                                                                        Maximum of 1000 characters
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <div class="form-group">
                                                                        <label>Internal Remarks</label>
                                                                        <textarea id="internalRemarks" rows="4"
                                                                           class="form-control ng-pristine ng-valid ng-touched"
                                                                           formControlName="internalRemarks"
                                                                           [title]="noticeTabdiv.value.internalRemarks ? noticeTabdiv.value.internalRemarks :''"
                                                                           [ngClass]="{ errorBorder:noticeTabdiv.get('internalRemarks')?.hasError('maxlength')}"></textarea>
                                                                     </div>
                                                                     <div class="text-danger"
                                                                        *ngIf="noticeTabdiv.get('internalRemarks')?.touched && noticeTabdiv.get('internalRemarks')?.hasError('maxlength')">
                                                                        Maximum of 1000 characters
                                                                     </div>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <a class="showAuthDetailTbl anchorClick"
                                                                        (click)="detailsOfIssuingAuthority = !detailsOfIssuingAuthority"><i
                                                                           class="fa fa-id-badge mr5"></i>Add Authority
                                                                        Details</a>
                                                                  </div>
                                                                  <div class="col-md-12 AuthDetailTbl"
                                                                     *ngIf="detailsOfIssuingAuthority">
                                                                     <div class="accordion accordion-solid-header"
                                                                        id="accordion-03" role="tablist">
                                                                        <div class="card border-bottom">
                                                                           <div id="collapse-03" class="collapse show"
                                                                              role="tabpanel"
                                                                              aria-labelledby="heading-03"
                                                                              data-parent="#accordion-03">
                                                                              <div class="card-body">
                                                                                 <div class="row">
                                                                                    <div class="table-responsive">
                                                                                       <table
                                                                                          class="table table-bordered">
                                                                                          <tr class="bgGred">
                                                                                             <th>Notice Number/ DIN</th>
                                                                                             <th>Officer Name </th>
                                                                                             <th>Designation</th>
                                                                                             <th>Jurisdiction</th>
                                                                                             <th>Address </th>
                                                                                             <th>Contact Number </th>
                                                                                             <th>Email ID </th>
                                                                                             <th>Action</th>
                                                                                          </tr>
                                                                                          <tr *ngFor="let div of getIssuingAuthority(noticeTabdiv); let j=index"
                                                                                             [formGroup]="div">
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="noticeNo"
                                                                                                   [title]="div.value.noticeNo ? div.value.noticeNo: ''">
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="officer"
                                                                                                   [title]="div.value.officer ? div.value.officer:''">
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="designation"
                                                                                                   [title]="div.value.designation ? div.value.designation :''">
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="jurisdiction"
                                                                                                   [title]="div.value.jurisdiction" />
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="address"
                                                                                                   [title]="div.value.address ? div.value.address: ''">
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="contact"
                                                                                                   maxlength="12"
                                                                                                   oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                                             </td>
                                                                                             <td><input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="email"
                                                                                                   [title]="div.value.email ? div.value.email: ''">
                                                                                                <div
                                                                                                   *ngIf="div.get('email')?.errors">
                                                                                                   <span
                                                                                                      class="text-danger"
                                                                                                      *ngIf="div.get('email')?.errors?.pattern">
                                                                                                      Please Enter Valid
                                                                                                      Email ID</span>
                                                                                                </div>
                                                                                             </td>
                                                                                             <td>
                                                                                                <a class="addExtLetter"
                                                                                                   (click)="addIssuingAuthority(i) "><i
                                                                                                      class="fa fa-plus"></i></a>
                                                                                                <a class="addExtLetter"
                                                                                                   *ngIf="j != 0"
                                                                                                   (click)="openIssuingAuthorityPop(rmissuingAuthorityPop,i)"><i
                                                                                                      class="fa fa-minus"></i></a>
                                                                                             </td>
                                                                                             <ng-template
                                                                                                #rmissuingAuthorityPop
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header">
                                                                                                   <h4 class="modal-title"
                                                                                                      id="modal-basic-title">
                                                                                                      Remove Warning!
                                                                                                   </h4>
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="modal-body">
                                                                                                   <p>Are you sure, you
                                                                                                      want to remove?
                                                                                                   </p>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="rmIssuingAuthorityInv(i,j)">Remove</button>
                                                                                                </div>
                                                                                             </ng-template>
                                                                                          </tr>
                                                                                       </table>
                                                                                    </div>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>

                                                                  <div class="col-md-3">
                                                                     <div class="form-group">
                                                                        <label>Notice Copy<span class="mandate"><i
                                                                                 class="fa fa-asterisk"></i></span>
                                                                        </label>
                                                                        <input type="file" class="file-upload-default"
                                                                           #file>
                                                                        <div class="attachment-block"
                                                                           [ngClass]="{'errorBorder': isSubmittedNotice &&  getnoticeLength(i) === 0 }">
                                                                           <span
                                                                              (click)="openNoticeLocPop(noticeCopyFormPop, i)">
                                                                              <i class="icon fa fa fa-paperclip"
                                                                                 style="cursor: pointer;"
                                                                                 title="Attach file"></i></span><span
                                                                              *ngIf="getnoticeLength(i) != 0">
                                                                              {{getnoticeLength(i)}} Attachment</span>
                                                                        </div>
                                                                        <div
                                                                           *ngIf="isSubmittedNotice && getnoticeLength(i) === 0">
                                                                           <span class="text-danger">Notice Copy
                                                                              Required</span>
                                                                        </div>

                                                                        <div>
                                                                           <ng-template #noticeCopyFormPop let-modal>
                                                                              <div class="modal-header"
                                                                                 style="border: none; padding-bottom: 0px;">
                                                                                 <button type="button" class="close"
                                                                                    aria-label="Close"
                                                                                    (click)="modal.dismiss('Cross click')">
                                                                                    <span aria-hidden="true">×</span>
                                                                                 </button>
                                                                              </div>
                                                                              <div class="col-md-12">
                                                                                 <label> Attachment</label>
                                                                                 <div class="table-responsive">
                                                                                    <table class="table table-bordered">
                                                                                       <thead>
                                                                                          <tr class="bgGred">
                                                                                             <th>Upload</th>
                                                                                             <th style="width:50%;">
                                                                                                Remark (Max 100
                                                                                                Character allowed)</th>
                                                                                          </tr>
                                                                                       </thead>
                                                                                       <tbody>
                                                                                          <tr *ngFor="let noticeFromGrp of getnoticeLoc(noticeTabdiv); let j = index"
                                                                                             [formGroup]="noticeFromGrp">
                                                                                             <td>
                                                                                                <div class="row">
                                                                                                   <div
                                                                                                      class="col-md-9 nopadding">
                                                                                                      <div
                                                                                                         class="form-group">
                                                                                                         <input
                                                                                                            *ngIf="noticeFromGrp.value.isdocLocUploadedClicked"
                                                                                                            type="file"
                                                                                                            id="dtNotices({{i}}).noticeLoc({{j}}).fileLoc"
                                                                                                            class="filetype form-control"
                                                                                                            name="myfile"
                                                                                                            (change)="onFileSelect($event,'notice')">
                                                                                                         <p *ngIf="!noticeFromGrp.value.isdocLocUploadedClicked || this.noticeForm.value.dtNotices[i].noticeLoc[j].fileLoc"
                                                                                                            class="form-control pt10">
                                                                                                            {{getFileNameDta(this.noticeForm.value.dtNotices[i].noticeLoc[j].fileLoc)}}
                                                                                                         </p>
                                                                                                      </div>
                                                                                                   </div>
                                                                                                   <div
                                                                                                      class="col-md-3 nopadding">
                                                                                                      <label>
                                                                                                         <a class="downupIcon"
                                                                                                            *ngIf="this.noticeForm.value.dtNotices[i].noticeLoc[j].fileLoc"
                                                                                                            title="Download file"
                                                                                                            (click)="download(this.noticeForm.value.dtNotices[i].noticeLoc[j].fileLoc)"><i
                                                                                                               class="fa fa-download"></i></a>
                                                                                                         <a class="downupIcon"
                                                                                                            *ngIf="(!this.noticeForm.value.dtNotices[i].noticeLoc[j].fileLoc || this.noticeForm.value.dtNotices[i].noticeLoc[j].fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                            title="Upload file"
                                                                                                            (click)="uploadNticeLocCopy(i,j)"><i
                                                                                                               class="fa fa-upload"></i></a>
                                                                                                         <a class="downupIcon"
                                                                                                            (click)="deletenNticeLocCopyFile(i,j)"
                                                                                                            *ngIf="(noticeFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                               class="fa fa-trash"
                                                                                                               aria-hidden="true"></i></a>
                                                                                                      </label>
                                                                                                   </div>
                                                                                                </div>
                                                                                             </td>
                                                                                             <td> <input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   id="remarks"
                                                                                                   formControlName="remarks"
                                                                                                   [title]="noticeFromGrp.value.remarks ? noticeFromGrp.value.remarks: ''"
                                                                                                   maxlength="100" />

                                                                                             </td>

                                                                                          </tr>
                                                                                       </tbody>
                                                                                    </table>
                                                                                 </div>
                                                                              </div>
                                                                              <div class="modal-footer"
                                                                                 style="border: none; padding-top: 0px;">
                                                                                 <button type="button"
                                                                                    class="btn btn-outline-dark"
                                                                                    (click)="modal.close('Save click')">Save</button>
                                                                              </div>
                                                                           </ng-template>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                         <div class="col-md-12 mt15 mb-3">
                                                            <button class="btn btn-outline-primary mr-1 addNoticeTab"
                                                               (click)="addNotice(i)">Add Notice</button>
                                                            <button class="btn btn-outline-primary mr-1 " *ngIf="i!= 0"
                                                               (click)="openRmvNoticePop(removeNoticePopup, i)">Remove
                                                               Notice</button>
                                                            <ng-template #removeNoticePopup let-modal>
                                                               <div class="modal-header">
                                                                  <h4 class="modal-title" id="modal-basic-title">Remove
                                                                     Warning!</h4>
                                                                  <button type="button" class="close" aria-label="Close"
                                                                     (click)="modal.dismiss('Cross click')">
                                                                     <span aria-hidden="true">×</span>
                                                                  </button>
                                                               </div>
                                                               <div class="modal-body">
                                                                  <p>Are You Sure, Do you want to remove the Notice?</p>
                                                               </div>
                                                               <div class="modal-footer">
                                                                  <button type="button" class="btn btn-outline-dark"
                                                                     (click)="removeLetter(i)">Remove</button>
                                                               </div>
                                                            </ng-template>
                                                         </div>

                                                      </div>
                                                      <div class="col-md-12 mt15 mb-3" *ngIf="!updateBtn">
                                                         <button class="btn btn-outline-primary mr-1 savePhase1"
                                                            id="isNext" (click)="submitNoticeForm(i)"
                                                            #nextBtn>Save</button>
                                                      </div>
                                                      <div class="col-md-12 mt15 mb-3" *ngIf="updateBtn">
                                                         <button class="btn btn-outline-primary mr-1 savePhase1"
                                                            id="isupdate" (click)="submitNoticeFormUpdate(1)"
                                                            #update>Update</button>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </form>
                                 </fieldset>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <!-- Phase 2 -->
      <div class="tab-pane fade" id="intimationp02-1" role="tabpanel" aria-labelledby="intimationp02-tab">
         <div class="card borderOrange">
            <div class="card-body ptl10 plr010">
               <div class="row">
                  <div class="col-2" id="verticalTab2" *ngIf="!sidemenu">
                     <ul class="nav nav-tabs nav-tabs-vertical" role="tablist">
                        <li class="nav-item">
                           <a class="nav-link active" id="subTab" data-toggle="tab" href="#sub-2" role="tab"
                              aria-controls="sub-2" aria-selected="true">Submision</a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab4Disable && !(action == 'update' ) && !(action == 'view'))}">
                           <a class="nav-link" id="hd-tab-vertical" data-toggle="tab" href="#hd-2" role="tab"
                              aria-controls="hd-2" aria-selected="false" #hearingTab>
                              Hearing Details
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab5Disable && !(action == 'update' ) && !(action == 'view'))}">
                           <a class="nav-link" id="cc-tab-vertical" data-toggle="tab" href="#cc-2" role="tab"
                              aria-controls="cc-2" aria-selected="false" #consultantTab>
                              Consultant Charges
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab6Disable && !(action == 'update' ) && !(action == 'view'))}">
                           <a class="nav-link" id="con-tab-vertical" data-toggle="tab" href="#con-2" role="tab"
                              aria-controls="con-2" aria-selected="false" #conclusionTab>
                              Conclusion
                           </a>
                        </li>
                        <li class="nav-item">
                           <a class="btn btn-outline-primary w100" (click)="updatePhaseTwo(2)"
                              [ngClass]="{'disabled': (action == 'view')}"> Update </a>
                        </li>
                     </ul>
                  </div>
                  <div class="col-10 border" id="tabArea2" [ngClass]="[sidemenu ? 'col-12' : 'col-10']"
                     [ngClass]="{'bgGray': (action == 'view')}">
                     <div class="tab-content tab-content-vertical">
                        <div class="tab-pane fade show active" id="sub-2" role="tabpanel"
                           aria-labelledby="sub-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="row">
                              <div class="col-md-12">
                                 <p class="textCenter">Submision</p>
                                 <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                    <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                    <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                 </span>
                              </div>
                              <fieldset [disabled]="action == 'view'">
                                 <form [formGroup]="noticeForm">
                                    <div formArrayName="dtNotices">
                                       <ul class="nav nav-pills iris-gst-display-flex" role="tablist">
                                          <li class="nav-item" id="noticeTab{{i}}"
                                             *ngFor="let div of getFormControlNotice(); let i=index"
                                             [formGroupName]="i">
                                             <a class="nav-link" id="notice1-tab" data-toggle="tab" role="tab"
                                                (click)="phaseRNChanged('phase' + i, i)"
                                                [ngClass]="(selectedReplyNotice === 'phase' + i) ? 'active': ''"
                                                aria-controls="notice1-1" aria-selected="true"> Reply to Notice
                                                {{i+1}}</a>
                                          </li>
                                       </ul>
                                       <div class="tab-content nopadding">
                                          <div class="tab-pane fade show active" id="notice1-1" role="tabpanel"
                                             aria-labelledby="notice1-tab" [ngClass]="{'bgGray': (action == 'view')}">
                                             <div class="col-md-12 noPadding " id="noticeTabDiv{{i}}"
                                                *ngFor="let noticeTabdiv of getFormControlNotice(); let i=index"
                                                [formGroup]="noticeTabdiv">
                                                <div *ngIf="selectedReplyNotice === 'phase' + i"
                                                   class="show-tab-content">

                                                   <div class="col-md-12 nopadding">
                                                      <button class="btn btn-outline-primary mb15 "
                                                         (click)="addSubmissions(i)">Add Submission</button>
                                                   </div>

                                                   <div
                                                      *ngFor="let SubmisionFrmGrp of getSubmissions(noticeTabdiv); let k=index"
                                                      [formGroup]="SubmisionFrmGrp">
                                                      <div id="rnotice1-1" role="tabpanel"
                                                         aria-labelledby="rnotice1-tab">
                                                         <div class="subDiv col-md-12">
                                                            <div class="row">
                                                               <div class="col-md-12 mb-1">
                                                                  <a class="card-title"
                                                                     (click)="addSubmissions(i)">Submission {{k+1}} <i
                                                                        class="fa fa-plus" title="Add"></i></a>
                                                                  <a class="addExtLetter pull-right"
                                                                     (click)="openRemoveSubmissionsPop(removeSubPopup, i, k)"><i
                                                                        class="fa fa-minus"></i></a>
                                                               </div>
                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Date of Submission <span class="mandate"><i
                                                                              class="fa fa-asterisk"></i></span></label>
                                                                     <div id="iris-gst-filter-frmDate"
                                                                        class="input-group date datepicker">
                                                                        <p-calendar class="form-control"
                                                                           formControlName="subDate"
                                                                           [ngClass]="{errorBorder:isSubmittedSubmission && SubmisionFrmGrp.get('subDate')?.errors}"
                                                                           [maxDate]="maxDate" [monthNavigator]="true"
                                                                           [yearNavigator]="true"
                                                                           yearRange="2010:{{currentYear}}"
                                                                           dateFormat="dd-mm-yy" [showIcon]="true"
                                                                           dataType="string"></p-calendar>
                                                                     </div>
                                                                  </div>
                                                                  <div
                                                                     *ngIf="isSubmittedSubmission && SubmisionFrmGrp.get('subDate')?.errors">
                                                                     <span class="text-danger"
                                                                        *ngIf="SubmisionFrmGrp.get('subDate')?.errors?.required ">
                                                                        Date of Submission Required</span>
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Mode of Submission</label>
                                                                     <select class="form-control form-select"
                                                                        formControlName="subMode">
                                                                        <option [value]="null"> -- Select --</option>
                                                                        <ng-container
                                                                           *ngFor=" let obj of modeOfAppealOptions">
                                                                           <option [value]="obj.value"> {{ obj.value}}
                                                                           </option>
                                                                        </ng-container>
                                                                     </select>
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Response Type <span class="mandate"><i
                                                                              class="fa fa-asterisk"></i></span></label>
                                                                     <select class="form-control form-select"
                                                                        formControlName="respType"
                                                                        [ngClass]="{errorBorder:isSubmittedSubmission && SubmisionFrmGrp.get('respType')?.errors}">
                                                                        <option [value]="null"> -- Select --</option>
                                                                        <ng-container *ngFor=" let obj of TypeOfRespo">
                                                                           <option [value]="obj.value"> {{ obj.value}}
                                                                           </option>
                                                                        </ng-container>
                                                                     </select>
                                                                  </div>
                                                                  <div
                                                                     *ngIf="isSubmittedSubmission && SubmisionFrmGrp.get('respType')?.errors">
                                                                     <span class="text-danger"
                                                                        *ngIf="SubmisionFrmGrp.get('respType')?.errors?.required ">Response
                                                                        Type Required</span>
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Acknowledgement No. <span class="mandate"><i
                                                                              class="fa fa-asterisk"></i></span>
                                                                     </label>
                                                                     <input type="text" class="form-control"
                                                                        placeholder="" formControlName="ackNo"
                                                                        [ngClass]="{errorBorder:isSubmittedSubmission && SubmisionFrmGrp.get('ackNo')?.errors}">
                                                                  </div>
                                                                  <div
                                                                     *ngIf="isSubmittedSubmission && SubmisionFrmGrp.get('ackNo')?.errors">
                                                                     <span class="text-danger"
                                                                        *ngIf="SubmisionFrmGrp.get('ackNo')?.errors?.required ">Acknowledgement
                                                                        No Required</span>
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Copy of Acknowledgement <span
                                                                           class="mandate"><i
                                                                              class="fa fa-asterisk"></i></span>
                                                                     </label>
                                                                     <input type="file" class="file-upload-default"
                                                                        #file>
                                                                     <div class="attachment-block"
                                                                        [ngClass]="{'errorBorder': isSubmittedSubmission && getAckLength(i, k) === 0 }">
                                                                        <span
                                                                           (click)="openSubDetailPop(copyAcknowledgementFormPop, i)">
                                                                           <i class="icon fa fa fa-paperclip"
                                                                              style="cursor: pointer;"
                                                                              title="Attach file"></i></span> <span
                                                                           *ngIf="getAckLength(i, k) != 0">
                                                                           {{getAckLength(i, k)}} Attachment</span>
                                                                     </div>
                                                                     <div
                                                                        *ngIf="isSubmittedSubmission && getAckLength(i, k) === 0">
                                                                        <span class="text-danger">Copy of
                                                                           Acknowledgement Required</span>
                                                                     </div>
                                                                     <div>
                                                                        <ng-template #copyAcknowledgementFormPop
                                                                           let-modal>
                                                                           <div class="modal-header"
                                                                              style="border: none; padding-bottom: 0px;">
                                                                              <button type="button" class="close"
                                                                                 aria-label="Close"
                                                                                 (click)="modal.dismiss('Cross click')">
                                                                                 <span aria-hidden="true">×</span>
                                                                              </button>
                                                                           </div>
                                                                           <div class="col-md-12">
                                                                              <label> Attachment</label>
                                                                              <div class="table-responsive">
                                                                                 <table class="table table-bordered">
                                                                                    <thead>
                                                                                       <tr class="bgGred">
                                                                                          <th>Upload</th>
                                                                                          <th style="width:50%;">Remark
                                                                                             (Max 100 Character allowed)
                                                                                          </th>
                                                                                       </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                       <tr *ngFor="let acknowledgeFromGrp of getAckCopyLoc(SubmisionFrmGrp); let j = index"
                                                                                          [formGroup]="acknowledgeFromGrp">
                                                                                          <td>
                                                                                             <div class="row">
                                                                                                <div
                                                                                                   class="col-md-9 nopadding">
                                                                                                   <div
                                                                                                      class="form-group">
                                                                                                      <input
                                                                                                         *ngIf="acknowledgeFromGrp.value.isdocLocUploadedClicked"
                                                                                                         type="file"
                                                                                                         id="dtNotices({{i}}).submissions({{k}}).ackCopyLoc({{j}}).fileLoc"
                                                                                                         class="filetype form-control"
                                                                                                         name="myfile"
                                                                                                         (change)="onFileSelect($event,'notice')">
                                                                                                      <p *ngIf="!acknowledgeFromGrp.value.isdocLocUploadedClicked || acknowledgeFromGrp.value.fileLoc"
                                                                                                         class="form-control pt10">
                                                                                                         {{getFileNameDta(acknowledgeFromGrp.value.fileLoc)}}
                                                                                                      </p>
                                                                                                   </div>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="col-md-3 nopadding">
                                                                                                   <label>
                                                                                                      <a class="downupIcon"
                                                                                                         *ngIf="acknowledgeFromGrp.value.fileLoc"
                                                                                                         title="Download file"
                                                                                                         (click)="download(acknowledgeFromGrp.value.fileLoc)"><i
                                                                                                            class="fa fa-download"></i></a>
                                                                                                      <a class="downupIcon"
                                                                                                         *ngIf="!(acknowledgeFromGrp.value.fileLoc || acknowledgeFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                         title="Upload file"
                                                                                                         (click)="uploadAckCopyLoc(i,j,k)"><i
                                                                                                            class="fa fa-upload"></i></a>
                                                                                                      <a class="downupIcon"
                                                                                                         (click)="deleteAckCopyLocFile(i,j,k)"
                                                                                                         *ngIf="(acknowledgeFromGrp.value.fileLoc) && (!['DT_Viewer'].includes(roleName))"><i
                                                                                                            class="fa fa-trash"
                                                                                                            aria-hidden="true"></i></a>
                                                                                                   </label>

                                                                                                </div>
                                                                                             </div>
                                                                                          </td>
                                                                                          <td> <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder=""
                                                                                                id="remarks"
                                                                                                formControlName="remarks"
                                                                                                [title]="acknowledgeFromGrp.value.remarks ? acknowledgeFromGrp.value.remarks : ''"
                                                                                                maxlength="100" />
                                                                                          </td>

                                                                                       </tr>
                                                                                    </tbody>
                                                                                 </table>
                                                                              </div>
                                                                           </div>
                                                                           <div class="modal-footer"
                                                                              style="border: none; padding-top: 0px;">
                                                                              <button type="button"
                                                                                 class="btn btn-outline-dark"
                                                                                 (click)="modal.close('Save click')">Save</button>
                                                                           </div>
                                                                        </ng-template>
                                                                     </div>
                                                                  </div>
                                                               </div>


                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Submission Copy</label>
                                                                     <input type="file" class="file-upload-default"
                                                                        #file>
                                                                     <div class="attachment-block">
                                                                        <span
                                                                           (click)="openSubDetailPop(submisionFormPop, i)">
                                                                           <i class="icon fa fa fa-paperclip"
                                                                              style="cursor: pointer;"
                                                                              title="Attach file"></i> <span
                                                                              *ngIf="getSubLength(i, k) != 0">
                                                                              {{getSubLength(i, k)}}
                                                                              Attachment</span></span>
                                                                     </div>
                                                                     <div>
                                                                        <ng-template #submisionFormPop let-modal>
                                                                           <div class="modal-header"
                                                                              style="border: none; padding-bottom: 0px;">
                                                                              <button type="button" class="close"
                                                                                 aria-label="Close"
                                                                                 (click)="modal.dismiss('Cross click')">
                                                                                 <span aria-hidden="true">×</span>
                                                                              </button>
                                                                           </div>
                                                                           <div class="col-md-12">
                                                                              <label> Attachment</label>
                                                                              <div class="table-responsive">
                                                                                 <table class="table table-bordered">
                                                                                    <thead>
                                                                                       <tr class="bgGred">
                                                                                          <th>Upload</th>
                                                                                          <th style="width:50%;">Remark
                                                                                             (Max 100 Character allowed)
                                                                                          </th>
                                                                                       </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                       <tr *ngFor="let subCopyFromGrp of getSubLoc(SubmisionFrmGrp); let j = index"
                                                                                          [formGroup]="subCopyFromGrp">
                                                                                          <td>
                                                                                             <div class="row">
                                                                                                <div
                                                                                                   class="col-md-9 nopadding">
                                                                                                   <div
                                                                                                      class="form-group">
                                                                                                      <input
                                                                                                         *ngIf="subCopyFromGrp.value.isdocLocUploadedClicked"
                                                                                                         type="file"
                                                                                                         id="dtNotices({{i}}).submissions({{k}}).subCopyLoc({{j}}).fileLoc"
                                                                                                         class="filetype form-control"
                                                                                                         name="myfile"
                                                                                                         (change)="onFileSelect($event,'notice')">
                                                                                                      <p *ngIf="!subCopyFromGrp.value.isdocLocUploadedClicked || subCopyFromGrp.value.fileLoc"
                                                                                                         class="form-control pt10">
                                                                                                         {{getFileNameDta(subCopyFromGrp.value.fileLoc)}}
                                                                                                      </p>
                                                                                                   </div>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="col-md-3 nopadding">
                                                                                                   <label>
                                                                                                      <a class="downupIcon"
                                                                                                         *ngIf="subCopyFromGrp.value.fileLoc"
                                                                                                         title="Download file"
                                                                                                         (click)="download(subCopyFromGrp.value.fileLoc)"><i
                                                                                                            class="fa fa-download"></i></a>
                                                                                                      <a class="downupIcon"
                                                                                                         *ngIf="!(subCopyFromGrp.value.fileLoc || subCopyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                         title="Upload file"
                                                                                                         (click)="uploadSubLoc(i,j,k)"><i
                                                                                                            class="fa fa-upload"></i></a>
                                                                                                      <a class="downupIcon"
                                                                                                         (click)="deleteSubLoc(i,j,k)"
                                                                                                         *ngIf="(subCopyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                            class="fa fa-trash"
                                                                                                            aria-hidden="true"></i></a>
                                                                                                   </label>

                                                                                                </div>
                                                                                             </div>
                                                                                          </td>
                                                                                          <td> <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder=""
                                                                                                id="remarks"
                                                                                                formControlName="remarks"
                                                                                                [title]="subCopyFromGrp.value.remarks ? subCopyFromGrp.value.remarks: ''"
                                                                                                maxlength="100" />
                                                                                          </td>
                                                                                       </tr>
                                                                                    </tbody>
                                                                                 </table>
                                                                              </div>
                                                                           </div>
                                                                           <div class="modal-footer"
                                                                              style="border: none; padding-top: 0px;">
                                                                              <button type="button"
                                                                                 class="btn btn-outline-dark"
                                                                                 (click)="modal.close('Save click')">Save</button>
                                                                           </div>
                                                                        </ng-template>
                                                                     </div>
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-3">
                                                                  <div class="form-group">
                                                                     <label>Person Who Submitted </label>
                                                                     <input type="text" class="form-control"
                                                                        placeholder=""
                                                                        formControlName="personWhoSubmitted">
                                                                  </div>
                                                               </div>
                                                               <div class="col-md-12">
                                                                  <div class="form-group">
                                                                     <label>Submission Summary</label>
                                                                     <textarea id="subSummary" rows="4"
                                                                        class="form-control ng-pristine ng-valid ng-touched"
                                                                        placeholder="" formControlName="subSummary"
                                                                        [title]="SubmisionFrmGrp.value.subSummary ? SubmisionFrmGrp.value.subSummary: ''"
                                                                        [ngClass]="{'errorBorder':SubmisionFrmGrp.get('subSummary')?.touched && SubmisionFrmGrp.get('subSummary')?.hasError('maxlength') }"></textarea>
                                                                  </div>
                                                                  <div class="text-danger"
                                                                     *ngIf="SubmisionFrmGrp.get('subSummary')?.touched && SubmisionFrmGrp.get('subSummary')?.hasError('maxlength')">
                                                                     Maximum of 1000 characters
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-12">
                                                                  <div class="form-group">
                                                                     <label>Internal Remarks</label>
                                                                     <textarea id="internalRemarks" rows="4"
                                                                        class="form-control ng-pristine ng-valid ng-touched"
                                                                        placeholder="" formControlName="internalRemarks"
                                                                        [title]="SubmisionFrmGrp.value.internalRemarks ? SubmisionFrmGrp.value.internalRemarks : ''"
                                                                        [ngClass]="{'errorBorder': SubmisionFrmGrp.get('internalRemarks')?.hasError('maxlength') }"></textarea>
                                                                  </div>
                                                                  <div class="text-danger"
                                                                     *ngIf="SubmisionFrmGrp.get('internalRemarks')?.touched && SubmisionFrmGrp.get('internalRemarks')?.hasError('maxlength')">
                                                                     Maximum of 1000 characters
                                                                  </div>
                                                               </div>

                                                               <div class="col-md-12">
                                                                  <div class="accordion accordion-solid-header"
                                                                     id="accordion-04" role="tablist">
                                                                     <div class="card border-bottom">
                                                                        <div class="card-header" role="tab"
                                                                           id="heading-04">
                                                                           <h6 class="mb-0">
                                                                              <a data-toggle="collapse"
                                                                                 href="#collapse-04"
                                                                                 aria-expanded="true"
                                                                                 aria-controls="collapse-04"> Details of
                                                                                 Submission </a>
                                                                           </h6>
                                                                        </div>
                                                                        <div id="collapse-04" class="collapse show"
                                                                           role="tabpanel" aria-labelledby="heading-04"
                                                                           data-parent="#accordion-04">
                                                                           <div class="card-body">
                                                                              <div class="row">
                                                                                 <div class="table-responsive">
                                                                                    <table class="table table-bordered">
                                                                                       <tr class="bgGred">
                                                                                          <th class="w-40">Sr. No.</th>
                                                                                          <th>Document / Details
                                                                                             Submitted </th>
                                                                                          <th>Remark </th>
                                                                                          <th>Attachment</th>
                                                                                          <th class="w-80">Action</th>
                                                                                       </tr>
                                                                                       <ng-container>
                                                                                          <tr *ngFor="let subDetailsFormGrp of getsubDetails(SubmisionFrmGrp); let l=index"
                                                                                             [formGroup]="subDetailsFormGrp">
                                                                                             <td class="w-40">{{l+1}}
                                                                                             </td>

                                                                                             <td> <input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   formControlName="docReq"
                                                                                                   [title]="subDetailsFormGrp.value.docReq ? subDetailsFormGrp.value.docReq: ''"
                                                                                                   [ngClass]="{ errorBorder:subDetailsFormGrp.get('docReq')?.errors?.pattern}" />
                                                                                                <div class="text-danger"
                                                                                                   *ngIf="subDetailsFormGrp.get('docReq')?.errors?.pattern">
                                                                                                   Enter atleast 3
                                                                                                   characters </div>
                                                                                             </td>

                                                                                             <td> <input type="text"
                                                                                                   class="form-control"
                                                                                                   placeholder=""
                                                                                                   id="remarks"
                                                                                                   formControlName="remarks"
                                                                                                   [title]="subDetailsFormGrp.value.remarks ? subDetailsFormGrp.value.remarks : ''"
                                                                                                   maxlength="100" />
                                                                                             </td>

                                                                                             <td><span
                                                                                                   (click)="openSubDetailsPop(docLocFormPop, i)"><i
                                                                                                      class="icon fa fa fa-paperclip"
                                                                                                      id="paperclip"
                                                                                                      title="Attach file"></i></span>
                                                                                                <span
                                                                                                   *ngIf="getDocLoength(i, k,l) != 0">
                                                                                                   {{getDocLoength(i,
                                                                                                   k,l)}}
                                                                                                   Attachment</span>
                                                                                             </td>

                                                                                             <td class="w-80"><a
                                                                                                   class=" addExtLetter"
                                                                                                   (click)="addSubDetails(i,k)"><i
                                                                                                      class="fa fa-plus"></i></a>
                                                                                                <a class="addExtLetter"
                                                                                                   *ngIf="l!=0"
                                                                                                   (click)="openRemoveSubDetailsPop(rmvSubDetailsRef,i)"><i
                                                                                                      class="fa fa-minus"></i></a>
                                                                                             </td>
                                                                                             <ng-template
                                                                                                #rmvSubDetailsRef
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header">
                                                                                                   <h4 class="modal-title"
                                                                                                      id="modal-basic-title">
                                                                                                      Remove Warning!
                                                                                                   </h4>
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="modal-body">
                                                                                                   <p>Are you sure, you
                                                                                                      want to remove?
                                                                                                   </p>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="rmSubDetailstInv(i,k,l)">Remove</button>
                                                                                                </div>
                                                                                             </ng-template>

                                                                                             <ng-template #docLocFormPop
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header"
                                                                                                   style="border: none; padding-bottom: 0px;">
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="col-md-12">
                                                                                                   <label>Attachment</label>
                                                                                                   <div
                                                                                                      class="table-responsive">
                                                                                                      <table
                                                                                                         class="table table-bordered">
                                                                                                         <thead>
                                                                                                            <tr
                                                                                                               class="bgGred">
                                                                                                               <th>
                                                                                                                  Upload
                                                                                                               </th>
                                                                                                               <th
                                                                                                                  style="width:50%;">
                                                                                                                  Remark
                                                                                                                  (Max
                                                                                                                  100
                                                                                                                  Character
                                                                                                                  allowed)
                                                                                                               </th>
                                                                                                            </tr>
                                                                                                         </thead>
                                                                                                         <tbody>
                                                                                                            <tr *ngFor="let docLocFromGrp of getDocLoc(subDetailsFormGrp); let m=index"
                                                                                                               [formGroup]="docLocFromGrp">
                                                                                                               <td>
                                                                                                                  <div
                                                                                                                     class="row">
                                                                                                                     <div
                                                                                                                        class="col-md-9 nopadding">
                                                                                                                        <div
                                                                                                                           class="form-group">
                                                                                                                           <input
                                                                                                                              *ngIf="docLocFromGrp.value.isdocLocUploadedClicked"
                                                                                                                              type="file"
                                                                                                                              id="dtNotices({{i}}).submissions({{k}}).subDetails({{l}}).docLoc({{m}}).fileLoc"
                                                                                                                              class="filetype form-control"
                                                                                                                              name="myfile"
                                                                                                                              (change)="onFileSelect($event,'notice')">
                                                                                                                           <p *ngIf="!docLocFromGrp.value.isdocLocUploadedClicked || docLocFromGrp.value.fileLoc"
                                                                                                                              class="form-control pt10">
                                                                                                                              {{getFileNameDta(docLocFromGrp.value.fileLoc)}}
                                                                                                                           </p>
                                                                                                                        </div>
                                                                                                                     </div>
                                                                                                                     <div
                                                                                                                        class="col-md-3 nopadding">
                                                                                                                        <label>
                                                                                                                           <a class="downupIcon"
                                                                                                                              *ngIf="docLocFromGrp.value.fileLoc"
                                                                                                                              title="Download file"
                                                                                                                              (click)="download(docLocFromGrp.value.fileLoc)"><i
                                                                                                                                 class="fa fa-download"></i></a>
                                                                                                                           <a class="downupIcon"
                                                                                                                              *ngIf="!(docLocFromGrp.value.fileLoc || docLocFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                                              title="Upload file"
                                                                                                                              (click)="uploadDocLoc(i,l,k,m)"><i
                                                                                                                                 class="fa fa-upload"></i></a>
                                                                                                                           <a class="downupIcon"
                                                                                                                              (click)="deleteDocLo(i,l,k,m)"
                                                                                                                              *ngIf="(docLocFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                                                 class="fa fa-trash"
                                                                                                                                 aria-hidden="true"></i></a>
                                                                                                                        </label>

                                                                                                                     </div>
                                                                                                                  </div>
                                                                                                               </td>
                                                                                                               <td>
                                                                                                                  <input
                                                                                                                     type="text"
                                                                                                                     class="form-control"
                                                                                                                     placeholder=""
                                                                                                                     id="remarks"
                                                                                                                     formControlName="remarks"
                                                                                                                     [title]="docLocFromGrp.value.remarks ? docLocFromGrp.value.remarks: ''"
                                                                                                                     maxlength="100" />
                                                                                                               </td>
                                                                                                            </tr>
                                                                                                         </tbody>
                                                                                                      </table>
                                                                                                   </div>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer"
                                                                                                   style="border: none; padding-top: 0px;">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="modal.close('Save click')">Save</button>
                                                                                                </div>
                                                                                             </ng-template>
                                                                                          </tr>
                                                                                       </ng-container>
                                                                                    </table>
                                                                                 </div>
                                                                              </div>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                               <ng-template #removeSubPopup let-modal>
                                                                  <div class="modal-header">
                                                                     <h4 class="modal-title" id="modal-basic-title">
                                                                        Remove Warning!</h4>
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')">
                                                                        <span aria-hidden="true">×</span>
                                                                     </button>
                                                                  </div>
                                                                  <div class="modal-body">
                                                                     <p>Are you sure, you want to remove?</p>
                                                                  </div>
                                                                  <div class="modal-footer">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="rmSubmissionDiv(i, k)">Remove</button>
                                                                  </div>
                                                               </ng-template>

                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>

                                                   <div class="col-md-12">
                                                      <div class="accordion accordion-solid-header" id="accordion-04"
                                                         role="tablist">
                                                         <div class="card border-bottom">
                                                            <div class="card-header" role="tab" id="heading-04">
                                                               <h6 class="mb-0">
                                                                  <a data-toggle="collapse" href="#collapse-04"
                                                                     aria-expanded="true" aria-controls="collapse-04">

                                                                  </a>
                                                               </h6>
                                                            </div>Details of Physical Meeting/ Video Conferencing
                                                            Department
                                                            <div id="collapse-04" class="collapse show" role="tabpanel"
                                                               aria-labelledby="heading-04" data-parent="#accordion-04">
                                                               <div class="card-body">
                                                                  <div class="row">
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <tr class="bgGred">
                                                                              <th class="w-40">Sr. No. </th>
                                                                              <th style="width: 171px;">Date of Meeting
                                                                              </th>
                                                                              <th>Persons who attended</th>
                                                                              <th>Hearing details <a
                                                                                    class="clrOrange infoIcon"
                                                                                    title="Max 500 Character allowed."><i
                                                                                       class="fa fa-info-circle"></i></a>
                                                                              </th>
                                                                              <th>Copy of Submissions made</th>
                                                                              <th class="w-80">Action</th>
                                                                           </tr>
                                                                           <ng-container>
                                                                              <tr *ngFor="let departmentFromGrp of departmentInvArr(noticeTabdiv); let k=index"
                                                                                 [formGroup]="departmentFromGrp">
                                                                                 <td class="w-40">{{k+1}}</td>
                                                                                 <td>
                                                                                    <div id="iris-gst-filter-frmDate"
                                                                                       class="input-group date datepicker">
                                                                                       <p-calendar appendTo="body"
                                                                                          class="form-control"
                                                                                          formControlName="dateOfMeeting"
                                                                                          name="dateOfMeeting"
                                                                                          [title]="departmentFromGrp.value.dateOfMeeting ? departmentFromGrp.value.dateOfMeeting : ''"
                                                                                          [monthNavigator]="true"
                                                                                          [yearNavigator]="true"
                                                                                          yearRange="2010:{{currentYear}}"
                                                                                          dateFormat="dd-mm-yy"
                                                                                          [showIcon]="true"
                                                                                          dataType="string"
                                                                                          [maxDate]="maxDate">
                                                                                       </p-calendar>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td><input type="text"
                                                                                       class="form-control"
                                                                                       id="personAttended"
                                                                                       placeholder=""
                                                                                       formControlName="personAttended"
                                                                                       [title]="departmentFromGrp.value.personAttended ? departmentFromGrp.value.personAttended: ''"
                                                                                       [ngClass]="{'errorBorder': departmentFromGrp.get('personAttended')?.hasError('maxlength') }">
                                                                                    <div class="text-danger"
                                                                                       *ngIf="departmentFromGrp.get('personAttended')?.touched && departmentFromGrp.get('personAttended')?.hasError('maxlength')">
                                                                                       Max 100 Character allowed
                                                                                    </div>
                                                                                 </td>
                                                                                 <td><input type="text"
                                                                                       class="form-control"
                                                                                       id="hearingDetails"
                                                                                       placeholder=""
                                                                                       formControlName="hearingDetails"
                                                                                       [title]="departmentFromGrp.value.hearingDetails ? departmentFromGrp.value.hearingDetails: ''"
                                                                                       [ngClass]="{'errorBorder': departmentFromGrp.get('hearingDetails')?.hasError('maxlength') }">
                                                                                    <div class="text-danger"
                                                                                       *ngIf="departmentFromGrp.get('hearingDetails')?.touched && departmentFromGrp.get('hearingDetails')?.hasError('maxlength')">
                                                                                       Maximum of 500 characters
                                                                                    </div>
                                                                                 </td>
                                                                                 <td><span
                                                                                       (click)="openDepartmentPop(copyOfSubFormPop, i)"><i
                                                                                          class="icon fa fa fa-paperclip"
                                                                                          id="paperclip"
                                                                                          title="Attach file"></i></span><span
                                                                                       *ngIf="getCopyOfSubLength(i, k) != 0">
                                                                                       {{getCopyOfSubLength(i, k)}}
                                                                                       Attachment</span></td>

                                                                                 <td class="w-80">
                                                                                    <a class=" addExtLetter"
                                                                                       (click)="addDepartmentInv(i)"><i
                                                                                          class="fa fa-plus"></i></a>
                                                                                    <a class="addExtLetter" *ngIf="k!=0"
                                                                                       (click)="openRemovDepartmentPop(rmvDepartmentRef,i,k)"><i
                                                                                          class="fa fa-minus"></i></a>
                                                                                 </td>
                                                                                 <ng-template #rmvDepartmentRef
                                                                                    let-modal>
                                                                                    <div class="modal-header">
                                                                                       <h4 class="modal-title"
                                                                                          id="modal-basic-title">Remove
                                                                                          Warning!</h4>
                                                                                       <button type="button"
                                                                                          class="close"
                                                                                          aria-label="Close"
                                                                                          (click)="modal.dismiss('Cross click')">
                                                                                          <span
                                                                                             aria-hidden="true">×</span>
                                                                                       </button>
                                                                                    </div>
                                                                                    <div class="modal-body">
                                                                                       <p>Are you sure, you want to
                                                                                          remove?</p>
                                                                                    </div>
                                                                                    <div class="modal-footer">
                                                                                       <button type="button"
                                                                                          class="btn btn-outline-dark"
                                                                                          (click)="rmDepartmentInv(i,k)">Remove</button>
                                                                                    </div>
                                                                                 </ng-template>

                                                                                 <ng-template #copyOfSubFormPop
                                                                                    let-modal>
                                                                                    <div class="modal-header"
                                                                                       style="border: none; padding-bottom: 0px;">
                                                                                       <button type="button"
                                                                                          class="close"
                                                                                          aria-label="Close"
                                                                                          (click)="modal.dismiss('Cross click')">
                                                                                          <span
                                                                                             aria-hidden="true">×</span>
                                                                                       </button>
                                                                                    </div>
                                                                                    <div class="col-md-12">
                                                                                       <label>Attachment</label>
                                                                                       <div class="table-responsive">
                                                                                          <table
                                                                                             class="table table-bordered">
                                                                                             <thead>
                                                                                                <tr class="bgGred">
                                                                                                   <th> Upload </th>
                                                                                                   <th
                                                                                                      style="width:50%;">
                                                                                                      Remark (Max 100
                                                                                                      Character allowed)
                                                                                                   </th>
                                                                                                </tr>
                                                                                             </thead>
                                                                                             <tbody>
                                                                                                <tr *ngFor="let  CopyOfSubFromGrp  of getCopyOfSub(departmentFromGrp); let j=index"
                                                                                                   [formGroup]="CopyOfSubFromGrp">
                                                                                                   <td>
                                                                                                      <div class="row">
                                                                                                         <div
                                                                                                            class="col-md-9 nopadding">
                                                                                                            <div
                                                                                                               class="form-group">
                                                                                                               <input
                                                                                                                  *ngIf="CopyOfSubFromGrp.value.isdocLocUploadedClicked"
                                                                                                                  type="file"
                                                                                                                  id="dtNotices({{i}}).meetingDetails({{k}}).copyOfSubmissionLoc({{j}}).fileLoc"
                                                                                                                  class="filetype form-control"
                                                                                                                  name="myfile"
                                                                                                                  (change)="onFileSelect($event,'notice')">
                                                                                                               <p *ngIf="!CopyOfSubFromGrp.value.isdocLocUploadedClicked || CopyOfSubFromGrp.value.fileLoc"
                                                                                                                  class="form-control pt10">
                                                                                                                  {{getFileNameDta(CopyOfSubFromGrp.value.fileLoc)}}
                                                                                                               </p>
                                                                                                            </div>
                                                                                                         </div>
                                                                                                         <div
                                                                                                            class="col-md-3 nopadding">
                                                                                                            <label>
                                                                                                               <a class="downupIcon"
                                                                                                                  *ngIf="CopyOfSubFromGrp.value.fileLoc"
                                                                                                                  title="Download file"
                                                                                                                  (click)="download(CopyOfSubFromGrp.value.fileLoc)"><i
                                                                                                                     class="fa fa-download"></i></a>
                                                                                                               <a class="downupIcon"
                                                                                                                  *ngIf="!(CopyOfSubFromGrp.value.fileLoc || CopyOfSubFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                                  title="Upload file"
                                                                                                                  (click)="uploadCopyOfSub(i,j,k)"><i
                                                                                                                     class="fa fa-upload"></i></a>
                                                                                                               <a class="downupIcon"
                                                                                                                  (click)="deleteCopyOfSub(i,j,k)"
                                                                                                                  *ngIf="(CopyOfSubFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                                     class="fa fa-trash"
                                                                                                                     aria-hidden="true"></i></a>
                                                                                                            </label>
                                                                                                         </div>
                                                                                                      </div>
                                                                                                   </td>
                                                                                                   <td> <input
                                                                                                         type="text"
                                                                                                         class="form-control"
                                                                                                         placeholder=""
                                                                                                         id="remarks"
                                                                                                         formControlName="remarks"
                                                                                                         [title]="CopyOfSubFromGrp.value.remarks ? CopyOfSubFromGrp.value.remarks: ''"
                                                                                                         maxlength="100" />
                                                                                                   </td>
                                                                                                </tr>
                                                                                             </tbody>
                                                                                          </table>
                                                                                       </div>
                                                                                    </div>
                                                                                    <div class="modal-footer"
                                                                                       style="border: none; padding-top: 0px;">
                                                                                       <button type="button"
                                                                                          class="btn btn-outline-dark"
                                                                                          (click)="modal.close('Save click')">Save</button>
                                                                                    </div>
                                                                                 </ng-template>


                                                                              </tr>
                                                                           </ng-container>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div class="col-md-12 mt15  mb-3">
                                                      <button class="btn btn-outline-primary mr-1"
                                                         (click)="submitSubmissionForm()">Next</button>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </fieldset>
                           </div>
                        </div>

                        <div class="tab-pane fade" id="hd-2" role="tabpanel" aria-labelledby="hd-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="col-md-12 nopadding">
                              <p class="textCenter">Hearing - Personal/ Video Conf.</p>
                              <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                 <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                 <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                              </span>
                           </div>

                           <!-- <div class="col-md-12 nopadding">
                              <fieldset [disabled]="action == 'view'">
                                 <form [formGroup]="hearingForm">
                                    <div formArrayName="hearingDetails" [ngClass]="{'bgGray': (action == 'view')}">
                                       <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                                          <li class="nav-item" id="adjPhTab{{i}}"
                                             *ngFor="let adjPHtab of phDivArr(); let i=index" [formGroupName]="i">
                                             <a class="nav-link" id="adjudph1-tab" data-toggle="tab" role="tab"
                                                aria-controls="adjudph1-1" (click)="phaseChangedph('phase' + i, i)"
                                                [ngClass]="(selectedPhase === 'phase' + i) ? 'active': ''"
                                                aria-selected="true">Hearing {{i+1}} <span *ngIf="i > 0"
                                                   (click)="removephArr(i)" class="closeTab">x</span></a>
                                          </li>
                                       </ul>

                                       <div class="tab-content nopadding">
                                          <div class="tab-pane fade show active" id="adjudph1-1" role="tabpanel"
                                             aria-labelledby="adjudph1-tab" [ngClass]="{'bgGray': (action == 'view')}">
                                             <div class="ml5" id="adjPhDiv{{i}}"
                                                *ngFor="let adjPHdiv of phDivArr(); let i=index" [formGroupName]="i">
                                                   <div *ngIf="selectedPhase === 'phase' + i" class="show-tab-content">
                                                      <div class="row">
                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                            <label for="exampleInputPassword1">Hearing Ref.No </label>
                                                            <input type="text" class="form-control" id="phRefNo" placeholder="" formControlName="phRefNo">
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                            <label for="exampleInputPassword1">Hearing Notice Date </label>
                                                            <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                                               <p-calendar class="form-control" formControlName="phNoticeDate"
                                                                  [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}"
                                                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                                                            </div>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Hearing Notice </label>
                                                               <input type="file" class="file-upload-default" #file>
                                                               <div class="attachment-block">
                                                                  <span (click)="openPhNoticePop(phNoticeFormPop, i)"> <i
                                                                        class="icon fa fa fa-paperclip"
                                                                        style="cursor: pointer;"
                                                                        title="Attach file"></i><span
                                                                        *ngIf="getPhNoticeLength(i) != 0">
                                                                        {{getPhNoticeLength(i)}} Attachment</span></span>
                                                               </div>
                                                               <div>
                                                                  <ng-template #phNoticeFormPop let-modal>
                                                                     <div class="modal-header"
                                                                        style="border: none; padding-bottom: 0px;">
                                                                        <button type="button" class="close"
                                                                           aria-label="Close"
                                                                           (click)="modal.dismiss('Cross click')"> <span
                                                                              aria-hidden="true">×</span> </button>
                                                                     </div>
                                                                     <div class="col-md-12">
                                                                        <label> Attachment</label>
                                                                        <div class="table-responsive">
                                                                           <table class="table table-bordered">
                                                                              <thead>
                                                                                 <tr class="bgGred">
                                                                                    <th>Upload</th>
                                                                                    <th style="width:50%;">Remark (Max 100
                                                                                       Character allowed)</th>
                                                                                 </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                                 <tr *ngFor="let phNoticeFromGrp of getPhNotice(adjPHdiv); let j = index"
                                                                                    [formGroup]="phNoticeFromGrp">
                                                                                    <td>
                                                                                       <div class="row">
                                                                                          <div class="col-md-9 nopadding">
                                                                                             <div class="form-group">
                                                                                                <input
                                                                                                   *ngIf="phNoticeFromGrp.value.isdocLocUploadedClicked"
                                                                                                   type="file"
                                                                                                   id="hearingDetails({{i}}).phNoticeLoc({{j}}).fileLoc"
                                                                                                   class="filetype form-control"
                                                                                                   name="myfile"
                                                                                                   (change)="onFileSelect($event,'hearing')">
                                                                                                <p *ngIf="!phNoticeFromGrp.value.isdocLocUploadedClicked || phNoticeFromGrp.value.fileLoc"
                                                                                                   class="form-control pt10">
                                                                                                   {{getFileNameDta(phNoticeFromGrp.value.fileLoc)}}
                                                                                                </p>
                                                                                             </div>
                                                                                          </div>
                                                                                          <div class="col-md-3 nopadding">
                                                                                             <label>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="phNoticeFromGrp.value.fileLoc"
                                                                                                   title="Download file"
                                                                                                   (click)="download(phNoticeFromGrp.value.fileLoc)"><i
                                                                                                      class="fa fa-download"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="(!phNoticeFromGrp.value.fileLoc || phNoticeFromGrp.value.fileLoc)  && !['DT_Viewer'].includes(roleName)"
                                                                                                   title="Upload file"
                                                                                                   (click)="uploadPhNoticeCopy(i,j)"><i
                                                                                                      class="fa fa-upload"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   (click)="deletePhNoticeCopyFile(i,j)"
                                                                                                   *ngIf="(phNoticeFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                      class="fa fa-trash"
                                                                                                      aria-hidden="true"></i></a>
                                                                                             </label>
                                                                                          </div>
                                                                                       </div>
                                                                                    </td>
                                                                                    <td> <input type="text"
                                                                                          class="form-control"
                                                                                          placeholder="" id="remarks"
                                                                                          formControlName="remarks"
                                                                                          [title]="phNoticeFromGrp.value.remarks ? phNoticeFromGrp.value.remarks: ''"
                                                                                          maxlength="100" />
                                                                                    </td>
                                                                                 </tr>
                                                                              </tbody>
                                                                           </table>
                                                                        </div>
                                                                     </div>
                                                                     <div class="modal-footer"
                                                                        style="border: none; padding-top: 0px;">
                                                                        <button type="button" class="btn btn-outline-dark"
                                                                           (click)="modal.close('Save click')">Save</button>
                                                                     </div>
                                                                  </ng-template>
                                                               </div>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label for="exampleInputPassword1">Hearing Date</label>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar class="form-control"
                                                                     formControlName="phScheduledDate"
                                                                     [monthNavigator]="true" [yearNavigator]="true"
                                                                     yearRange="2000:{{currentYear}}" dateFormat="dd-mm-yy"
                                                                     [showIcon]="true" dataType="string">
                                                                  </p-calendar>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div class="row">
                                                         
                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                            <label for="exampleInputPassword1">Time of Hearing  </label>
                                                            <select class="form-control form-select" id="phTime" formControlName="phTime">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of modeOfTime">
                                                                  <option [value]="obj.value">{{obj.key}}</option>
                                                               </ng-container>
                                                            </select>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                            <label for="exampleInputPassword1">Mode of Hearing  </label>
                                                            <select class="form-control form-select" id="gstin" formControlName="mode">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of modeOfPH">
                                                                  <option [value]="obj.value">{{obj.key}}</option>
                                                               </ng-container>
                                                            </select>
                                                            </div>
                                                         </div>


                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label for="exampleInputPassword1">Attended </label>
                                                               <select class="form-control form-select" id="attended"
                                                                  formControlName="attended">
                                                                  <option [value]="null">-- Select --</option>
                                                                  <ng-container *ngFor="let obj of adjAttend">
                                                                     <option [value]="obj.value">{{obj.label}}</option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                         </div>
                                                         
                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label for="exampleInputPassword1">Adjourned/ New
                                                                  Hearing</label>
                                                               <select class="form-control form-select" id="adjournedOrNot"
                                                                  formControlName="adjournedOrNot"
                                                                  (change)="changeadjOrNot($event, i)">
                                                                  <option [value]="null">-- Select --</option>
                                                                  <ng-container *ngFor="let obj of adjPHAdjuorNotList">
                                                                     <option [value]="obj.value">{{obj.label}}</option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                            <label for="exampleInputPassword1">Adjournment Letter</label>
                                                            <input type="file" class="form-control file-upload-default" #file>
                                                            <div class="attachment-block">
                                 
                                                               <span (click)="openPhAdjournmentLetterLocPop(phAdjournmentLetterLoc, i)" style="margin-left: 5px;">
                                                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                                                  title="Attach file"></i></span><span *ngIf="getPhAdjournmentLetterLocLength(i) != 0">
                                                                  {{getPhAdjournmentLetterLocLength(i)}} Attachment</span>
                                                            </div>
                                                            <div>
                                                               <ng-template #phAdjournmentLetterLoc let-modal>
                                                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                                                  <button type="button" class="close" aria-label="Close"
                                                                     (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                                                  </button>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                  <label> Attachment</label>
                                                                  <div class="table-responsive">
                                                                     <table class="table table-bordered tablepop">
                                                                        <thead>
                                                                        <tr class="bgGred">
                                                                           <th>Upload</th>
                                                                           <th style="width:50%;">Remark</th>
                                                                        </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                        <tr *ngFor="let phAdjournmentLetterGrp of getPhAdjournmentLetterLoc(adjPHdiv); let j = index"
                                                                           [formGroup]="phAdjournmentLetterGrp">
                                                                           <td>
                                                                              <div class="row">
                                                                              <div class="col-md-9 nopadding">
                                                                                 <div class="form-group">
                                                                                    <input *ngIf="phAdjournmentLetterGrp.value.isdocLocUploadedClicked" type="file"
                                                                                    id="hearingDetails({{i}}).phAdjournmentLetterLoc({{j}}).fileLoc"
                                                                                    class="filetype form-control" name="myfile"
                                                                                    (change)="onFileSelect($event,'hearing')">
                                                                                    <p *ngIf="!phAdjournmentLetterGrp.value.isdocLocUploadedClicked || phAdjournmentLetterGrp.value.fileLoc"
                                                                                    class="form-control pt10">
                                                                                    {{getFileNameDta(phAdjournmentLetterGrp.value.fileLoc)}}
                                                                                    </p>
                                                                                 </div>
                                                                              </div>
                                                                              <div class="col-md-3 nopadding">
                                                                                 <label for="exampleInputPassword1">
                                                                                    <a class="downupIcon" *ngIf="phAdjournmentLetterGrp.value.fileLoc"
                                                                                    title="Download file"
                                                                                    (click)="download(phAdjournmentLetterGrp.value.fileLoc)"><i
                                                                                       class="fa fa-download"></i></a>
                                                                                    <a class="downupIcon"
                                                                                    *ngIf="!(phAdjournmentLetterGrp.value.fileLoc || phAdjournmentLetterGrp.value.fileLoc)"
                                                                                    title="Upload file" (click)="uploadPhAdjournmentLetterLocCopy(i,j)"><i
                                                                                       class="fa fa-upload"></i></a>
                                                                                    <a class="downupIcon" (click)="deletePhAdjournmentLetterLocFile(i,j)"
                                                                                    *ngIf="(phAdjournmentLetterGrp.value.fileLoc)"><i
                                                                                       class="fa fa-trash" aria-hidden="true"></i></a>
                                                                                 </label>
                                                                              </div>
                                                                              </div>
                                                                           </td>
                                                                           <td>
                                                                              <input type="text" class="form-control" formControlName="remarks"
                                                                              [title]="phAdjournmentLetterGrp.value.remarks ? phAdjournmentLetterGrp.value.remarks:''">
                                                                              <div
                                                                              *ngIf="phAdjournmentLetterGrp.value.remarks && phAdjournmentLetterGrp.value.remarks.length > 100">
                                                                              <span class="text-danger">Max character Length 100</span>
                                                                              </div>
                                                                           </td>
                                                                        </tr>
                                                                        </tbody>
                                                                     </table>
                                                                  </div>
                                                                  </div>
                                                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                                                  <button type="button" class="btn btn-outline-dark"
                                                                     (click)="modal.close('Save click')">Save</button>
                                                                  </div>
                                                               </ng-template>
                                                            </div>
                                                            </div>
                                                         </div>
                                                                                                            <div class="col-md-3">
                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label for="exampleInputPassword1">Date of Additional
                                                                  Written Submissions</label>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar class="form-control"
                                                                     formControlName="addWrittenSubDate"
                                                                     [monthNavigator]="true" [yearNavigator]="true"
                                                                     [maxDate]="maxDate" yearRange="2000:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string">
                                                                  </p-calendar>
                                                               </div>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Upload the additional submission </label>
                                                               <input type="file" class="file-upload-default" #file>
                                                               <div class="attachment-block">
                                                                  <span (click)="openAddSubPop(addSubFormPop, i)"> <i
                                                                        class="icon fa fa fa-paperclip"
                                                                        style="cursor: pointer;"
                                                                        title="Attach file"></i><span
                                                                        *ngIf="getAddSubLength(i) != 0">
                                                                        {{getAddSubLength(i)}} Attachment</span></span>
                                                               </div>
                                                               <div>
                                                                  <ng-template #addSubFormPop let-modal>
                                                                     <div class="modal-header"
                                                                        style="border: none; padding-bottom: 0px;">
                                                                        <button type="button" class="close"
                                                                           aria-label="Close"
                                                                           (click)="modal.dismiss('Cross click')"> <span
                                                                              aria-hidden="true">×</span> </button>
                                                                     </div>
                                                                     <div class="col-md-12">
                                                                        <label> Attachment</label>
                                                                        <div class="table-responsive">
                                                                           <table class="table table-bordered">
                                                                              <thead>
                                                                                 <tr class="bgGred">
                                                                                    <th>Upload</th>
                                                                                    <th style="width:50%;">Remark (Max 100
                                                                                       Character allowed)</th>
                                                                                 </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                                 <tr *ngFor="let addSubFromGrp of getAddSub(adjPHdiv); let j = index"
                                                                                    [formGroup]="addSubFromGrp">
                                                                                    <td>
                                                                                       <div class="row">
                                                                                          <div class="col-md-9 nopadding">
                                                                                             <div class="form-group">
                                                                                                <input
                                                                                                   *ngIf="addSubFromGrp.value.isdocLocUploadedClicked"
                                                                                                   type="file"
                                                                                                   id="hearingDetails({{i}}).addSubLoc({{j}}).fileLoc"
                                                                                                   class="filetype form-control"
                                                                                                   name="myfile"
                                                                                                   (change)="onFileSelect($event,'hearing')">
                                                                                                <p *ngIf="!addSubFromGrp.value.isdocLocUploadedClicked || addSubFromGrp.value.fileLoc"
                                                                                                   class="form-control pt10">
                                                                                                   {{getFileNameDta(addSubFromGrp.value.fileLoc)}}
                                                                                                </p>
                                                                                             </div>
                                                                                          </div>
                                                                                          <div class="col-md-3 nopadding">
                                                                                             <label>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="addSubFromGrp.value.fileLoc"
                                                                                                   title="Download file"
                                                                                                   (click)="download(addSubFromGrp.value.fileLoc)"><i
                                                                                                      class="fa fa-download"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="(!addSubFromGrp.value.fileLoc || addSubFromGrp.value.fileLoc)  && !['DT_Viewer'].includes(roleName)"
                                                                                                   title="Upload file"
                                                                                                   (click)="uploadAddSubCopy(i,j)"><i
                                                                                                      class="fa fa-upload"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   (click)="deleteAddSubFile(i,j)"
                                                                                                   *ngIf="(addSubFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                      class="fa fa-trash"
                                                                                                      aria-hidden="true"></i></a>
                                                                                             </label>
                                                                                          </div>
                                                                                       </div>
                                                                                    </td>
                                                                                    <td> <input type="text"
                                                                                          class="form-control"
                                                                                          placeholder="" id="remarks"
                                                                                          formControlName="remarks"
                                                                                          [title]="addSubFromGrp.value.remarks ? addSubFromGrp.value.remarks: ''"
                                                                                          maxlength="100" />
                                                                                    </td>
                                                                                 </tr>
                                                                              </tbody>
                                                                           </table>
                                                                        </div>
                                                                     </div>
                                                                     <div class="modal-footer"
                                                                        style="border: none; padding-top: 0px;">
                                                                        <button type="button" class="btn btn-outline-dark"
                                                                           (click)="modal.close('Save click')">Save</button>
                                                                     </div>
                                                                  </ng-template>
                                                               </div>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label for="exampleInputPassword1">Consultant</label>
                                                               <input type="text" class="form-control" id="caseId"
                                                                  formControlName="consultant">
                                                            </div>
                                                         </div>

                                                         

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Hearing Record, If Any</label>
                                                               <input type="file" class="file-upload-default" #file>
                                                               <div class="attachment-block">
                                                                  <span
                                                                     (click)="openPhRecordIfAnyPop(phRecordIfAnyFormPop, i)">
                                                                     <i class="icon fa fa fa-paperclip"
                                                                        style="cursor: pointer;"
                                                                        title="Attach file"></i><span
                                                                        *ngIf="getPhRecordIfAnyLength(i) != 0">
                                                                        {{getPhRecordIfAnyLength(i)}}
                                                                        Attachment</span></span>
                                                               </div>
                                                               <div>
                                                                  <ng-template #phRecordIfAnyFormPop let-modal>
                                                                     <div class="modal-header"
                                                                        style="border: none; padding-bottom: 0px;">
                                                                        <button type="button" class="close"
                                                                           aria-label="Close"
                                                                           (click)="modal.dismiss('Cross click')"> <span
                                                                              aria-hidden="true">×</span> </button>
                                                                     </div>
                                                                     <div class="col-md-12">
                                                                        <label> Attachment</label>
                                                                        <div class="table-responsive">
                                                                           <table class="table table-bordered">
                                                                              <thead>
                                                                                 <tr class="bgGred">
                                                                                    <th>Upload</th>
                                                                                    <th style="width:50%;">Remark (Max 100
                                                                                       Character allowed)</th>
                                                                                 </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                                 <tr *ngFor="let PhRecordIfAnyFromGrp of getPhRecordIfAny(adjPHdiv); let j = index"
                                                                                    [formGroup]="PhRecordIfAnyFromGrp">
                                                                                    <td>
                                                                                       <div class="row">
                                                                                          <div class="col-md-9 nopadding">
                                                                                             <div class="form-group">
                                                                                                <input
                                                                                                   *ngIf="PhRecordIfAnyFromGrp.value.isdocLocUploadedClicked"
                                                                                                   type="file"
                                                                                                   id="hearingDetails({{i}}).phRecordIfAnyLoc({{j}}).fileLoc"
                                                                                                   class="filetype form-control"
                                                                                                   name="myfile"
                                                                                                   (change)="onFileSelect($event,'hearing')">
                                                                                                <p *ngIf="!PhRecordIfAnyFromGrp.value.isdocLocUploadedClicked || PhRecordIfAnyFromGrp.value.fileLoc"
                                                                                                   class="form-control pt10">
                                                                                                   {{getFileNameDta(PhRecordIfAnyFromGrp.value.fileLoc)}}
                                                                                                </p>
                                                                                             </div>
                                                                                          </div>
                                                                                          <div class="col-md-3 nopadding">
                                                                                             <label>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="PhRecordIfAnyFromGrp.value.fileLoc"
                                                                                                   title="Download file"
                                                                                                   (click)="download(PhRecordIfAnyFromGrp.value.fileLoc)"><i
                                                                                                      class="fa fa-download"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="(!PhRecordIfAnyFromGrp.value.fileLoc || PhRecordIfAnyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                   title="Upload file"
                                                                                                   (click)="uploadPhRecordIfAnyCopy(i,j)"><i
                                                                                                      class="fa fa-upload"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   (click)="deletePhRecordIfAnyFile(i,j)"
                                                                                                   *ngIf="(PhRecordIfAnyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                      class="fa fa-trash"
                                                                                                      aria-hidden="true"></i></a>
                                                                                             </label>
                                                                                          </div>
                                                                                       </div>
                                                                                    </td>
                                                                                    <td> <input type="text"
                                                                                          class="form-control"
                                                                                          placeholder="" id="remarks"
                                                                                          formControlName="remarks"
                                                                                          [title]="PhRecordIfAnyFromGrp.value.remarks ? PhRecordIfAnyFromGrp.value.remarks: ''"
                                                                                          maxlength="100" />
                                                                                    </td>
                                                                                 </tr>
                                                                              </tbody>
                                                                           </table>
                                                                        </div>
                                                                     </div>
                                                                     <div class="modal-footer"
                                                                        style="border: none; padding-top: 0px;">
                                                                        <button type="button" class="btn btn-outline-dark"
                                                                           (click)="modal.close('Save click')">Save</button>
                                                                     </div>
                                                                  </ng-template>
                                                               </div>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-12">
                                                            <div class="form-group">
                                                               <label for="exampleInputPassword1">Hearing discussion
                                                                  notes</label>
                                                               <textarea class="form-control" id="phDiscNotes" rows="4"
                                                                  formControlName="phDiscNotes"
                                                                  [ngClass]="{'errorBorder': adjPHdiv.get('phDiscNotes')?.hasError('maxlength') }"></textarea>
                                                            </div>
                                                            <div class="text-danger"
                                                               *ngIf=" adjPHdiv.get('phDiscNotes')?.touched && adjPHdiv.get('phDiscNotes')?.hasError('maxlength')">
                                                               Maximum of 1000 characters
                                                            </div>
                                                         </div>

                                                      </div>
                                                   </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-md-12 noPadding mt15 mb-3">
                                          <button class="btn btn-outline-primary mr-1"
                                             (click)="hearingSubmit()">Next</button>
                                       </div>
                                    </div>
                                 </form>
                              </fieldset>
                           </div> -->

                           <div class="col-md-12 nopadding">
                              <fieldset [disabled]="action == 'view'">
                                 <form [formGroup]="hearingForm">
                                    <div formArrayName="hearingDetails" [ngClass]="{'bgGray': (action == 'view')}">
                                       <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                                          <li class="nav-item" id="adjPhTab{{i}}"
                                             *ngFor="let adjPHtab of phDivArr(); let i=index" [formGroupName]="i">
                                             <a class="nav-link" id="adjudph1-tab" data-toggle="tab" role="tab"
                                                aria-controls="adjudph1-1" (click)="phaseChangedph('phase' + i, i)"
                                                [ngClass]="(selectedPhase === 'phase' + i) ? 'active': ''"
                                                aria-selected="true">Hearing {{i+1}} <span *ngIf="i > 0"
                                                   (click)="removephArr(i)" class="closeTab">x</span></a>
                                          </li>
                                       </ul>

                                       <div class="tab-content nopadding">
                                          <div class="tab-pane fade show active" id="adjudph1-1" role="tabpanel"
                                             aria-labelledby="adjudph1-tab" [ngClass]="{'bgGray': (action == 'view')}">
                                             <div class="ml5" id="adjPhDiv{{i}}"
                                                *ngFor="let adjPHdiv of phDivArr(); let i=index" [formGroupName]="i">
                                                <div *ngIf="selectedPhase === 'phase' + i" class="show-tab-content">
                                                   <div class="row">
                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Hearing Ref.No </label>
                                                           <input type="text" class="form-control" id="phRefNo" placeholder="" formControlName="phRefNo">
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Hearing Notice Date </label>
                                                           <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                                             <p-calendar class="form-control" formControlName="phNoticeDate"
                                                               [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}"
                                                               dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                                                           </div>
                                                         </div>
                                                       </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Hearing Notice </label>
                                                            <input type="file" class="file-upload-default" #file>
                                                            <div class="attachment-block">
                                                               <span (click)="openPhNoticePop(phNoticeFormPop, i)"> <i
                                                                     class="icon fa fa fa-paperclip"
                                                                     style="cursor: pointer;"
                                                                     title="Attach file"></i><span
                                                                     *ngIf="getPhNoticeLength(i) != 0">
                                                                     {{getPhNoticeLength(i)}} Attachment</span></span>
                                                            </div>
                                                            <div>
                                                               <ng-template #phNoticeFormPop let-modal>
                                                                  <div class="modal-header"
                                                                     style="border: none; padding-bottom: 0px;">
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')"> <span
                                                                           aria-hidden="true">×</span> </button>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <label> Attachment</label>
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <thead>
                                                                              <tr class="bgGred">
                                                                                 <th>Upload</th>
                                                                                 <th style="width:50%;">Remark (Max 100
                                                                                    Character allowed)</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr *ngFor="let phNoticeFromGrp of getPhNotice(adjPHdiv); let j = index"
                                                                                 [formGroup]="phNoticeFromGrp">
                                                                                 <td>
                                                                                    <div class="row">
                                                                                       <div class="col-md-9 nopadding">
                                                                                          <div class="form-group">
                                                                                             <input
                                                                                                *ngIf="phNoticeFromGrp.value.isdocLocUploadedClicked"
                                                                                                type="file"
                                                                                                id="hearingDetails({{i}}).phNoticeLoc({{j}}).fileLoc"
                                                                                                class="filetype form-control"
                                                                                                name="myfile"
                                                                                                (change)="onFileSelect($event,'hearing')">
                                                                                             <p *ngIf="!phNoticeFromGrp.value.isdocLocUploadedClicked || phNoticeFromGrp.value.fileLoc"
                                                                                                class="form-control pt10">
                                                                                                {{getFileNameDta(phNoticeFromGrp.value.fileLoc)}}
                                                                                             </p>
                                                                                          </div>
                                                                                       </div>
                                                                                       <div class="col-md-3 nopadding">
                                                                                          <label>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="phNoticeFromGrp.value.fileLoc"
                                                                                                title="Download file"
                                                                                                (click)="download(phNoticeFromGrp.value.fileLoc)"><i
                                                                                                   class="fa fa-download"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="(!phNoticeFromGrp.value.fileLoc || phNoticeFromGrp.value.fileLoc)  && !['DT_Viewer'].includes(roleName)"
                                                                                                title="Upload file"
                                                                                                (click)="uploadPhNoticeCopy(i,j)"><i
                                                                                                   class="fa fa-upload"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                (click)="deletePhNoticeCopyFile(i,j)"
                                                                                                *ngIf="(phNoticeFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                   class="fa fa-trash"
                                                                                                   aria-hidden="true"></i></a>
                                                                                          </label>
                                                                                       </div>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td> <input type="text"
                                                                                       class="form-control"
                                                                                       placeholder="" id="remarks"
                                                                                       formControlName="remarks"
                                                                                       [title]="phNoticeFromGrp.value.remarks ? phNoticeFromGrp.value.remarks: ''"
                                                                                       maxlength="100" />
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                                  <div class="modal-footer"
                                                                     style="border: none; padding-top: 0px;">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="modal.close('Save click')">Save</button>
                                                                  </div>
                                                               </ng-template>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label for="exampleInputPassword1">Hearing Date</label>
                                                            <div id="iris-gst-filter-frmDate"
                                                               class="input-group date datepicker">
                                                               <p-calendar class="form-control"
                                                                  formControlName="phScheduledDate"
                                                                  [monthNavigator]="true" [yearNavigator]="true"
                                                                  yearRange="2000:{{currentYear}}" dateFormat="dd-mm-yy"
                                                                  [showIcon]="true" dataType="string">
                                                               </p-calendar>
                                                            </div>
                                                         </div>
                                                      </div>

                                                   </div>
                                                   <div class="row">
                                                      
                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Time of Hearing  </label>
                                                           <select class="form-control form-select" id="phTime" formControlName="phTime">
                                                             <option [value]="null">-- Select --</option>
                                                             <ng-container *ngFor="let obj of modeOfTime">
                                                               <option [value]="obj.value">{{obj.key}}</option>
                                                             </ng-container>
                                                           </select>
                                                         </div>
                                                       </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                         <label for="exampleInputPassword1">Mode of Hearing  </label>
                                                         <select class="form-control form-select" id="gstin" formControlName="mode">
                                                            <option [value]="null">-- Select --</option>
                                                            <ng-container *ngFor="let obj of modeOfPH">
                                                               <option [value]="obj.value">{{obj.key}}</option>
                                                            </ng-container>
                                                         </select>
                                                         </div>
                                                      </div>


                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label for="exampleInputPassword1">Attended </label>
                                                            <select class="form-control form-select" id="attended"
                                                               formControlName="attended">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of adjAttend">
                                                                  <option [value]="obj.value">{{obj.label}}</option>
                                                               </ng-container>
                                                            </select>
                                                         </div>
                                                      </div>
                                                      
                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label for="exampleInputPassword1">Adjourned/ New
                                                               Hearing</label>
                                                            <select class="form-control form-select" id="adjournedOrNot"
                                                               formControlName="adjournedOrNot"
                                                               (change)="changeadjOrNot($event, i)">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of adjPHAdjuorNotList">
                                                                  <option [value]="obj.value">{{obj.label}}</option>
                                                               </ng-container>
                                                            </select>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">

                                                            <label for="exampleInputPassword1">Attended </label>
                                                            <select class="form-control form-select" id="attended"
                                                               formControlName="attended">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of adjAttend">
                                                                  <option [value]="obj.value">{{obj.label}}</option>
                                                               </ng-container>
                                                            </select>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div class="row">
                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label for="exampleInputPassword1">Date of Additional
                                                               Written Submissions</label>
                                                            <div id="iris-gst-filter-frmDate"
                                                               class="input-group date datepicker">
                                                               <p-calendar class="form-control"
                                                                  formControlName="addWrittenSubDate"
                                                                  [monthNavigator]="true" [yearNavigator]="true"
                                                                  [maxDate]="maxDate" yearRange="2000:{{currentYear}}"
                                                                  dateFormat="dd-mm-yy" [showIcon]="true"
                                                                  dataType="string">
                                                               </p-calendar>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Upload the additional submission </label>
                                                            <input type="file" class="file-upload-default" #file>
                                                            <div class="attachment-block">
                                                               <span (click)="openAddSubPop(addSubFormPop, i)"> <i
                                                                     class="icon fa fa fa-paperclip"
                                                                     style="cursor: pointer;"
                                                                     title="Attach file"></i><span
                                                                     *ngIf="getAddSubLength(i) != 0">
                                                                     {{getAddSubLength(i)}} Attachment</span></span>
                                                            </div>
                                                            <div>
                                                               <ng-template #addSubFormPop let-modal>
                                                                  <div class="modal-header"
                                                                     style="border: none; padding-bottom: 0px;">
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')"> <span
                                                                           aria-hidden="true">×</span> </button>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <label> Attachment</label>
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <thead>
                                                                              <tr class="bgGred">
                                                                                 <th>Upload</th>
                                                                                 <th style="width:50%;">Remark (Max 100
                                                                                    Character allowed)</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr *ngFor="let addSubFromGrp of getAddSub(adjPHdiv); let j = index"
                                                                                 [formGroup]="addSubFromGrp">
                                                                                 <td>
                                                                                    <div class="row">
                                                                                       <div class="col-md-9 nopadding">
                                                                                          <div class="form-group">
                                                                                             <input
                                                                                                *ngIf="addSubFromGrp.value.isdocLocUploadedClicked"
                                                                                                type="file"
                                                                                                id="hearingDetails({{i}}).addSubLoc({{j}}).fileLoc"
                                                                                                class="filetype form-control"
                                                                                                name="myfile"
                                                                                                (change)="onFileSelect($event,'hearing')">
                                                                                             <p *ngIf="!addSubFromGrp.value.isdocLocUploadedClicked || addSubFromGrp.value.fileLoc"
                                                                                                class="form-control pt10">
                                                                                                {{getFileNameDta(addSubFromGrp.value.fileLoc)}}
                                                                                             </p>
                                                                                          </div>
                                                                                       </div>
                                                                                       <div class="col-md-3 nopadding">
                                                                                          <label>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="addSubFromGrp.value.fileLoc"
                                                                                                title="Download file"
                                                                                                (click)="download(addSubFromGrp.value.fileLoc)"><i
                                                                                                   class="fa fa-download"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="(!addSubFromGrp.value.fileLoc || addSubFromGrp.value.fileLoc)  && !['DT_Viewer'].includes(roleName)"
                                                                                                title="Upload file"
                                                                                                (click)="uploadAddSubCopy(i,j)"><i
                                                                                                   class="fa fa-upload"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                (click)="deleteAddSubFile(i,j)"
                                                                                                *ngIf="(addSubFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                   class="fa fa-trash"
                                                                                                   aria-hidden="true"></i></a>
                                                                                          </label>
                                                                                       </div>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td> <input type="text"
                                                                                       class="form-control"
                                                                                       placeholder="" id="remarks"
                                                                                       formControlName="remarks"
                                                                                       [title]="addSubFromGrp.value.remarks ? addSubFromGrp.value.remarks: ''"
                                                                                       maxlength="100" />
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                                  <div class="modal-footer"
                                                                     style="border: none; padding-top: 0px;">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="modal.close('Save click')">Save</button>
                                                                  </div>
                                                               </ng-template>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <!-- <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label for="exampleInputPassword1">Consultant</label>
                                                            <input type="text" class="form-control" id="caseId"
                                                               formControlName="consultant">
                                                         </div>
                                                      </div> -->

                                                      <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create')">
                                                         <div class="form-group">
                                                           <label for="exampleInputPassword1">Consultant</label>
                                                       
                                                           <!-- For Consultant Role: Create -->
                                                           <div *ngIf="roleName === 'Consultant' && action === 'create'">
                                                            <p>{{roleName}}--{{action}}</p>
                                                             <input 
                                                               type="text" 
                                                               class="form-control" 
                                                               formControlName="consultant" 
                                                               [readOnly]="action === 'create'">
                                                           </div>
                                                       
                                                           <!-- For Non-Consultant Role -->
                                                           <div *ngIf="roleName !== 'Consultant'">
                                                             <div *ngIf="isToggleConsultantDropdown2 && (action === 'update' || action === 'view')" class="input-group">
                                                               <input 
                                                                 type="text" 
                                                                 class="form-control" 
                                                                 placeholder="" 
                                                                 formControlName="consultant" 
                                                                 readonly>
                                                               <button 
                                                                 *ngIf="isToggleConsultantDropdown2 && action === 'update'" 
                                                                 class="btn btn-outline-secondary" 
                                                                 type="button" 
                                                                 (click)="onClickToggleConsultantDropdown('p2')">
                                                                 <i class="fa fa-pencil"></i>
                                                               </button>
                                                             </div>
                                                       
                                                             <select 
                                                               class="form-control form-select" 
                                                               id="consultant" 
                                                               *ngIf="!isToggleConsultantDropdown2 || action === 'create'" 
                                                               formControlName="consultant">
                                                               <option [value]="null">-- Select --</option>
                                                               <ng-container *ngFor="let obj of consultantList">
                                                                 <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                                                               </ng-container>
                                                             </select>
                                                           </div>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-3">
                                                         <div class="form-group">
                                                            <label>Hearing Record, If Any</label>
                                                            <input type="file" class="file-upload-default" #file>
                                                            <div class="attachment-block">
                                                               <span
                                                                  (click)="openPhRecordIfAnyPop(phRecordIfAnyFormPop, i)">
                                                                  <i class="icon fa fa fa-paperclip"
                                                                     style="cursor: pointer;"
                                                                     title="Attach file"></i><span
                                                                     *ngIf="getPhRecordIfAnyLength(i) != 0">
                                                                     {{getPhRecordIfAnyLength(i)}}
                                                                     Attachment</span></span>
                                                            </div>
                                                            <div>
                                                               <ng-template #phRecordIfAnyFormPop let-modal>
                                                                  <div class="modal-header"
                                                                     style="border: none; padding-bottom: 0px;">
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')"> <span
                                                                           aria-hidden="true">×</span> </button>
                                                                  </div>
                                                                  <div class="col-md-12">
                                                                     <label> Attachment</label>
                                                                     <div class="table-responsive">
                                                                        <table class="table table-bordered">
                                                                           <thead>
                                                                              <tr class="bgGred">
                                                                                 <th>Upload</th>
                                                                                 <th style="width:50%;">Remark (Max 100
                                                                                    Character allowed)</th>
                                                                              </tr>
                                                                           </thead>
                                                                           <tbody>
                                                                              <tr *ngFor="let PhRecordIfAnyFromGrp of getPhRecordIfAny(adjPHdiv); let j = index"
                                                                                 [formGroup]="PhRecordIfAnyFromGrp">
                                                                                 <td>
                                                                                    <div class="row">
                                                                                       <div class="col-md-9 nopadding">
                                                                                          <div class="form-group">
                                                                                             <input
                                                                                                *ngIf="PhRecordIfAnyFromGrp.value.isdocLocUploadedClicked"
                                                                                                type="file"
                                                                                                id="hearingDetails({{i}}).phRecordIfAnyLoc({{j}}).fileLoc"
                                                                                                class="filetype form-control"
                                                                                                name="myfile"
                                                                                                (change)="onFileSelect($event,'hearing')">
                                                                                             <p *ngIf="!PhRecordIfAnyFromGrp.value.isdocLocUploadedClicked || PhRecordIfAnyFromGrp.value.fileLoc"
                                                                                                class="form-control pt10">
                                                                                                {{getFileNameDta(PhRecordIfAnyFromGrp.value.fileLoc)}}
                                                                                             </p>
                                                                                          </div>
                                                                                       </div>
                                                                                       <div class="col-md-3 nopadding">
                                                                                          <label>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="PhRecordIfAnyFromGrp.value.fileLoc"
                                                                                                title="Download file"
                                                                                                (click)="download(PhRecordIfAnyFromGrp.value.fileLoc)"><i
                                                                                                   class="fa fa-download"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                *ngIf="(!PhRecordIfAnyFromGrp.value.fileLoc || PhRecordIfAnyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                title="Upload file"
                                                                                                (click)="uploadPhRecordIfAnyCopy(i,j)"><i
                                                                                                   class="fa fa-upload"></i></a>
                                                                                             <a class="downupIcon"
                                                                                                (click)="deletePhRecordIfAnyFile(i,j)"
                                                                                                *ngIf="(PhRecordIfAnyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                   class="fa fa-trash"
                                                                                                   aria-hidden="true"></i></a>
                                                                                          </label>
                                                                                       </div>
                                                                                    </div>
                                                                                 </td>
                                                                                 <td> <input type="text"
                                                                                       class="form-control"
                                                                                       placeholder="" id="remarks"
                                                                                       formControlName="remarks"
                                                                                       [title]="PhRecordIfAnyFromGrp.value.remarks ? PhRecordIfAnyFromGrp.value.remarks: ''"
                                                                                       maxlength="100" />
                                                                                 </td>
                                                                              </tr>
                                                                           </tbody>
                                                                        </table>
                                                                     </div>
                                                                  </div>
                                                                  <div class="modal-footer"
                                                                     style="border: none; padding-top: 0px;">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="modal.close('Save click')">Save</button>
                                                                  </div>
                                                               </ng-template>
                                                            </div>
                                                         </div>
                                                      </div>

                                                      <div class="col-md-12">
                                                         <div class="form-group">
                                                            <label for="exampleInputPassword1">Hearing discussion
                                                               notes</label>
                                                            <textarea class="form-control" id="phDiscNotes" rows="4"
                                                               formControlName="phDiscNotes"
                                                               [ngClass]="{'errorBorder': adjPHdiv.get('phDiscNotes')?.hasError('maxlength') }"></textarea>
                                                         </div>
                                                         <div class="text-danger"
                                                            *ngIf=" adjPHdiv.get('phDiscNotes')?.touched && adjPHdiv.get('phDiscNotes')?.hasError('maxlength')">
                                                            Maximum of 1000 characters
                                                         </div>
                                                      </div>

                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-md-12 noPadding mt15 mb-3">
                                          <button class="btn btn-outline-primary mr-1"
                                             (click)="hearingSubmit()">Next</button>
                                       </div>
                                    </div>
                                 </form>
                              </fieldset>
                           </div>
                        </div>

                        <div class="tab-pane fade" id="cc-2" role="tabpanel" aria-labelledby="cc-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="col-md-12 nopadding">
                              <p class="textCenter">Consultant Charges</p>
                              <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                 <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                 <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                              </span>
                           </div>
                           <fieldset [disabled]="action == 'view'">
                              <form [formGroup]="consultantForm">
                                 <div formArrayName="cc">
                                    <div class="col-md-12 nopadding">
                                       <div class="row">
                                          <div class="col-md-12 table-responsive">
                                             <table class="search-table inner">
                                                <tr class="bgGred">
                                                   <th class=" w-40">Sr.No.</th>
                                                   <th>Name of the Consultant</th>
                                                   <th>Name of Consulting Firm</th>
                                                   <th>Nature of Work</th>
                                                   <th>Fees Charged (₹)</th>
                                                   <th class="w-300">Supporting Document</th>
                                                   <th>Email id</th>
                                                   <th>Contact Number</th>
                                                   <th class="w-80"> Action </th>
                                                </tr>
                                                <ng-container>
                                                   <tr *ngFor="let div of consultantInvArr() let i=index;"
                                                      [formGroup]="div">
                                                      <td class="w-40">{{i+1}}</td>
                                                      <td>
                                                         <!-- <input type="text" class="form-control"
                                                         formControlName="consultant" [title]="div.value.consultant"> -->
                                                         <div *ngIf="isToggleConsultantDropdownCC && action==='update' || action==='view'" class="input-group">
                                                            <input type="text" class="form-control"
                                                              *ngIf="isToggleConsultantDropdownCC && action==='update' || action==='view'" placeholder=""
                                                              formControlName="consultant" readonly>
                                                            <button *ngIf="isToggleConsultantDropdownCC && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('CC')">
                                                              <i class="fa fa-pencil"></i>
                                                            </button> 
                                                         </div>
                                                  
                                                         <select class="form-control form-select" id="consultant"
                                                            *ngIf="!isToggleConsultantDropdownCC || action==='create'" formControlName="consultant" (change)="consultantDataAutofil(i)">
                                                            <option [value]="null">-- Select --</option>
                                                            <ng-container *ngFor="let obj of consultantList">
                                                            <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                                            </ng-container>
                                                         </select> 
                                                      </td>
                                                      <td>
                                                         <input type="text" class="form-control" id="tax" placeholder="" formControlName="firm" [title]="div.value.firm" readonly style="cursor: not-allowed;">
                                                      </td>
                                                      <!-- <td><input type="text" class="form-control" id="consultant" placeholder="" formControlName="consultant" [title]="div.value.consultant"></td> -->
                                                      <td><input type="text" class="form-control" id="natureOfWork"
                                                            placeholder="" formControlName="natureOfWork"
                                                            [title]="div.value.natureOfWork ? div.value.natureOfWork:''">
                                                      </td>
                                                      <td><input type="text" class="form-control align-right" id="fees"
                                                            placeholder="" formControlName="fees"
                                                            (input)="feesChargedChanged()" maxlength="19" InrFormat
                                                            (paste)="handlePaste($event)"></td>
                                                      <td><span (click)="openCCDetailPop(consultantFormPop, i)"><i
                                                               class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                                               title="Attach file"></i><span
                                                               *ngIf="getConsultantLength(i) != 0">{{getConsultantLength(i)}}
                                                               Attachment</span></span></td>
                                                      <td>
                                                         <input 
                                                         type="text" 
                                                         class="form-control" 
                                                         placeholder="" 
                                                         formControlName="email" 
                                                         [title]="div.value.email ? div.value.email: ''" 
                                                         readonly style="cursor: not-allowed;">
                                                      </td>
                                                      <td>
                                                         <input class="form-control" id="contact"
                                                         formControlName="contact"
                                                         [title]="div.value.contact ? div.value.contact: ''"
                                                         maxlength="12"
                                                         readonly style="cursor: not-allowed;" >
                                                      </td>
                                                      <td class="w-80">
                                                         <a class=" addExtLetter" (click)="addConsultantInv()"><i
                                                               class="fa fa-plus" title="Add row"></i></a>
                                                         <a class=" addExtLetter" *ngIf="i != 0"
                                                            (click)="openRmvconsultantPop(removeConsultantPopup)"><i
                                                               class="fa fa-minus"></i></a>
                                                         <ng-template #removeConsultantPopup let-modal>
                                                            <div class="modal-header">
                                                               <h4 class="modal-title" id="modal-basic-title">Remove
                                                                  Warning!</h4>
                                                               <button type="button" class="close" aria-label="Close"
                                                                  (click)="modal.dismiss('Cross click')">
                                                                  <span aria-hidden="true">×</span>
                                                               </button>
                                                            </div>
                                                            <div class="modal-body">
                                                               <p>Are you sure, you want to remove?</p>
                                                            </div>
                                                            <div class="modal-footer">
                                                               <button type="button" class="btn btn-outline-dark"
                                                                  (click)="rmConsultantInv(i)">Remove</button>
                                                            </div>
                                                         </ng-template>
                                                      </td>
                                                      <ng-template #consultantFormPop let-modal>
                                                         <div class="modal-header"
                                                            style="border: none; padding-bottom: 0px;">
                                                            <button type="button" class="close" aria-label="Close"
                                                               (click)="modal.dismiss('Cross click')"> <span
                                                                  aria-hidden="true">×</span> </button>
                                                         </div>
                                                         <div class="col-md-12"> <label>Attachment</label>
                                                            <div class="table-responsive">
                                                               <table class="table table-bordered">
                                                                  <thead>
                                                                     <tr class="bgGred">
                                                                        <th>Upload </th>
                                                                        <th style="width:50%;">Remark (Max 100 Character
                                                                           allowed)</th>
                                                                     </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                     <tr *ngFor="let consultantFromGrp of getConsultant(div); let j=index"
                                                                        [formGroup]="consultantFromGrp">
                                                                        <td>
                                                                           <div class="row">
                                                                              <div class="col-md-9 nopadding">
                                                                                 <div class="form-group">
                                                                                    <input
                                                                                       *ngIf="consultantFromGrp.value.isdocLocUploadedClicked"
                                                                                       type="file"
                                                                                       id="cc({{i}}).supportingDocsLoc({{j}}).fileLoc"
                                                                                       class="filetype form-control"
                                                                                       name="myfile"
                                                                                       (change)="onFileSelect($event,'consultantData')">
                                                                                    <p *ngIf="!consultantFromGrp.value.isdocLocUploadedClicked || consultantFromGrp.value.fileLoc"
                                                                                       class="form-control pt10">
                                                                                       {{getFileNameDta(consultantFromGrp.value.fileLoc)}}
                                                                                    </p>
                                                                                 </div>
                                                                              </div>
                                                                              <div class="col-md-3 nopadding">
                                                                                 <label>
                                                                                    <a class="downupIcon"
                                                                                       *ngIf="consultantFromGrp.value.fileLoc"
                                                                                       title="Download file"
                                                                                       (click)="download(consultantFromGrp.value.fileLoc)"><i
                                                                                          class="fa fa-download"></i></a>
                                                                                    <a class="downupIcon"
                                                                                       *ngIf="!(consultantFromGrp.value.fileLoc || consultantFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                       title="Upload file"
                                                                                       (click)="uploadConsultant(i,j)"><i
                                                                                          class="fa fa-upload"></i></a>
                                                                                    <a class="downupIcon"
                                                                                       (click)="deleteConsultant(i,j)"
                                                                                       *ngIf="(consultantFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                          class="fa fa-trash"
                                                                                          aria-hidden="true"></i></a>
                                                                                 </label>

                                                                              </div>
                                                                           </div>
                                                                        </td>
                                                                        <td> <input type="text" class="form-control"
                                                                              placeholder="" id="remarks"
                                                                              formControlName="remarks"
                                                                              [title]="consultantFromGrp.value.remarks ? consultantFromGrp.value.remarks: ''"
                                                                              maxlength="100" />
                                                                        </td>
                                                                     </tr>
                                                                  </tbody>
                                                               </table>
                                                            </div>
                                                         </div>
                                                         <div class="modal-footer"
                                                            style="border: none; padding-top: 0px;">
                                                            <button type="button" class="btn btn-outline-dark"
                                                               (click)="modal.close('Save click')">Save</button>
                                                         </div>
                                                      </ng-template>
                                                   </tr>
                                                   <tr>
                                                      <th colspan="4">Total</th>
                                                      <td class="align-right bold-font">{{feesChargedTotal | INR : 0}}
                                                      </td>
                                                   </tr>
                                                </ng-container>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-12 noPadding mt15 mb-3">
                                       <button class="btn btn-outline-primary mr-1"
                                          (click)="consultantSubmit()">Next</button>
                                    </div>
                                 </div>
                              </form>
                           </fieldset>
                        </div>

                        <div class="tab-pane fade" id="con-2" role="tabpanel" aria-labelledby="con-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <div class="col-md-12 nopadding">
                              <p class="textCenter">Conclusion</p>
                              <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                 <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                 <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                              </span>
                           </div>
                           <fieldset [disabled]="action == 'view'">
                              <form [formGroup]="conclusionForm">
                                 <div class="col-md-12 nopadding">
                                    <div class="accordion accordion-solid-header" id="accordion-04" role="tablist">
                                       <div class="card border-bottom">
                                          <div class="card-header" role="tab" id="heading-04">
                                             <h6 class="mb-0">
                                                <a data-toggle="collapse" href="#collapse-04" aria-expanded="true"
                                                   aria-controls="collapse-04">
                                                   Details of Response From Department
                                                </a>
                                             </h6>
                                          </div>
                                          <div id="collapse-04" class="collapse show" role="tabpanel"
                                             aria-labelledby="heading-04" data-parent="#accordion-04">
                                             <div class="card-body">
                                                <div class="row">
                                                   <div class="table-responsive">
                                                      <table class="table table-bordered">
                                                         <tr class="bgGred">
                                                            <th class=" w-40">Sr.No.</th>
                                                            <th>Response Date</th>
                                                            <th>DIN</th>
                                                            <th>Date of Receipt of Response</th>
                                                            <th>Due Date of Reply/Appeal</th>
                                                            <th>Summary of Response </th>
                                                            <th>Attachment </th>
                                                            <th class="w-80">Action </th>
                                                         </tr>
                                                         <tr *ngFor="let conclusionFrm of getConclusion() let i=index;"
                                                            [formGroup]="conclusionFrm">
                                                            <td class="w-40">{{i+1}}</td>

                                                            <td>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar appendTo="body" class="form-control"
                                                                     formControlName="respDate" name="respDate"
                                                                     [monthNavigator]="true" [yearNavigator]="true"
                                                                     yearRange="2010:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string" [maxDate]="maxDate"
                                                                     [title]="conclusionFrm.value.respDate ? conclusionFrm.value.respDate: ''">
                                                                  </p-calendar>
                                                               </div>
                                                            </td>

                                                            <td><input type="text" class="form-control" placeholder=""
                                                                  formControlName="din"
                                                                  [title]="conclusionFrm.value.din ? conclusionFrm.value.din: ''">
                                                            </td>
                                                            <td>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar appendTo="body" class="form-control"
                                                                     formControlName="respReceiptDate"
                                                                     name="respReceiptDate" [monthNavigator]="true"
                                                                     [yearNavigator]="true"
                                                                     yearRange="2010:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string" [maxDate]="maxDate"
                                                                     [title]="conclusionFrm.value.respReceiptDate ? conclusionFrm.value.respReceiptDate: ''"
                                                                     [ngClass]="{errorBorder: isSubmittedAppeal && conclusionFrm.get('respReceiptDate')?.errors}">
                                                                  </p-calendar>
                                                               </div>
                                                            </td>
                                                            <td>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar appendTo="body" class="form-control"
                                                                     formControlName="replyDueDate" name="replyDueDate"
                                                                     [monthNavigator]="true" [yearNavigator]="true"
                                                                     yearRange="2010:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string" [maxDate]="maxDate"
                                                                     [title]="conclusionFrm.value.replyDueDate ? conclusionFrm.value.replyDueDate: ''">
                                                                  </p-calendar>
                                                               </div>
                                                            </td>

                                                            <td><input type="text" class="form-control" placeholder=""
                                                                  formControlName="respSummary"
                                                                  [title]="conclusionFrm.value.respSummary ? conclusionFrm.value.respSummary :''">
                                                            </td>

                                                            <td><span
                                                                  (click)="openConclusionDetailPop(conclusionFormPop, i)"><i
                                                                     class="icon fa fa fa-paperclip"
                                                                     style="cursor: pointer;"
                                                                     title="Attach file"></i><span
                                                                     *ngIf="getAttchmentLength(i) != 0">{{getAttchmentLength(i)}}
                                                                     Attachment</span></span></td>

                                                            <td class="w-80">
                                                               <a class=" addExtLetter" (click)="addConclusionInv()"><i
                                                                     class="fa fa-plus" title="Add row"></i></a>
                                                               <a class=" addExtLetter" *ngIf="i != 0"
                                                                  (click)="openRmvConclusionPop(removeConclusionPopup)"><i
                                                                     class="fa fa-minus"></i></a>
                                                               <ng-template #removeConclusionPopup let-modal>
                                                                  <div class="modal-header">
                                                                     <h4 class="modal-title" id="modal-basic-title">
                                                                        Remove Warning!</h4>
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')">
                                                                        <span aria-hidden="true">×</span>
                                                                     </button>
                                                                  </div>
                                                                  <div class="modal-body">
                                                                     <p>Are you sure, you want to remove?</p>
                                                                  </div>
                                                                  <div class="modal-footer">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="rmConclusionInv(i)">Remove</button>
                                                                  </div>
                                                               </ng-template>
                                                            </td>
                                                            <ng-template #conclusionFormPop let-modal>
                                                               <div class="modal-header"
                                                                  style="border: none; padding-bottom: 0px;">
                                                                  <button type="button" class="close" aria-label="Close"
                                                                     (click)="modal.dismiss('Cross click')"> <span
                                                                        aria-hidden="true">×</span> </button>
                                                               </div>
                                                               <div class="col-md-12"> <label>Attachment</label>
                                                                  <div class="table-responsive">
                                                                     <table class="table table-bordered">
                                                                        <thead>
                                                                           <tr class="bgGred">
                                                                              <th>Upload </th>
                                                                              <th style="width:50%;">Remark (Max 100
                                                                                 Character allowed)</th>
                                                                           </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                           <tr *ngFor="let attchmentFrmGrp of getAttchment(conclusionFrm); let j=index"
                                                                              [formGroup]="attchmentFrmGrp">
                                                                              <td>
                                                                                 <div class="row">
                                                                                    <div class="col-md-9 nopadding">
                                                                                       <div class="form-group">
                                                                                          <input
                                                                                             *ngIf="attchmentFrmGrp.value.isdocLocUploadedClicked"
                                                                                             type="file"
                                                                                             id="deptResponseDetails({{i}}).attchmentsLoc({{j}}).fileLoc"
                                                                                             class="filetype form-control"
                                                                                             name="myfile"
                                                                                             (change)="onFileSelect($event,'conclusion')">
                                                                                          <p *ngIf="!attchmentFrmGrp.value.isdocLocUploadedClicked || attchmentFrmGrp.value.fileLoc"
                                                                                             class="form-control pt10">
                                                                                             {{getFileNameDta(attchmentFrmGrp.value.fileLoc)}}
                                                                                          </p>
                                                                                       </div>
                                                                                    </div>
                                                                                    <div class="col-md-3 nopadding">
                                                                                       <label>
                                                                                          <a class="downupIcon"
                                                                                             *ngIf="attchmentFrmGrp.value.fileLoc"
                                                                                             title="Download file"
                                                                                             (click)="download(attchmentFrmGrp.value.fileLoc)"><i
                                                                                                class="fa fa-download"></i></a>
                                                                                          <a class="downupIcon"
                                                                                             *ngIf="!(attchmentFrmGrp.value.fileLoc || attchmentFrmGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                             title="Upload file"
                                                                                             (click)="uploadAttchment(i,j)"><i
                                                                                                class="fa fa-upload"></i></a>
                                                                                          <a class="downupIcon"
                                                                                             (click)="deleteAttchment(i,j)"
                                                                                             *ngIf="(attchmentFrmGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                class="fa fa-trash"
                                                                                                aria-hidden="true"></i></a>
                                                                                       </label>

                                                                                    </div>
                                                                                 </div>
                                                                              </td>
                                                                              <td> <input type="text"
                                                                                    class="form-control" placeholder=""
                                                                                    id="remarks"
                                                                                    formControlName="remarks"
                                                                                    [title]="attchmentFrmGrp.value.remarks ? attchmentFrmGrp.value.remarks: ''"
                                                                                    maxlength="100" />
                                                                              </td>
                                                                           </tr>
                                                                        </tbody>
                                                                     </table>
                                                                  </div>
                                                               </div>
                                                               <div class="modal-footer"
                                                                  style="border: none; padding-top: 0px;">
                                                                  <button type="button" class="btn btn-outline-dark"
                                                                     (click)="modal.close('Save click')">Save</button>
                                                               </div>
                                                            </ng-template>
                                                         </tr>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-10">
                                    <div class="accordion accordion-solid-header">
                                       <div class="card border-bottom">
                                          <div class="card-header" role="tab" id="heading-04">
                                             <h6 class="mb-0" class="table-heder"> Appeal/Writ Details </h6>
                                          </div>
                                          <div>
                                             <div class="card-body">
                                                <div class="row">
                                                   <div class="table-responsive">
                                                      <table class="table table-bordered">
                                                         <tr class="bgGred">
                                                            <th class="w-40">Sr. No. </th>
                                                            <th style="width: 250px;">Action <span class="mandatetd"><i
                                                                     class="fa fa-asterisk"
                                                                     style="font-size: 7px; margin-left:-7px; margin-top: 6px"></i></span>
                                                            </th>
                                                            <th>Forum</th>
                                                         </tr>
                                                         <ng-container>
                                                            <tr *ngFor="let appealFromGrp of getappealDetail(); let i=index"
                                                               [formGroup]="appealFromGrp">
                                                               <td class="w-40">{{i+1}}</td>
                                                               <td>
                                                                  <select class="form-control form-select" id="action"
                                                                     formControlName="action"
                                                                     (change)="setForumOfAppeal()"
                                                                     [title]="appealFromGrp.value.action ? appealFromGrp.value.action: ''"
                                                                     [ngClass]="{errorBorder: isSubmittedAppeal && appealFromGrp.get('action')?.errors?.required}">
                                                                     <ng-container *ngFor="let obj of actionData">
                                                                        <option [value]="obj.value">{{obj.label}}
                                                                        </option>
                                                                     </ng-container>
                                                                  </select>
                                                                  <div
                                                                     *ngIf="isSubmittedAppeal && appealFromGrp.get('action')?.errors">
                                                                     <span class="text-danger"
                                                                        *ngIf="appealFromGrp.get('action')?.errors?.required ">
                                                                        Required </span>
                                                                  </div>
                                                               </td>
                                                               <td>
                                                                  <select class="form-control form-select"
                                                                     formControlName="forum"
                                                                     [title]="appealFromGrp.value.forum ? appealFromGrp.value.forum:''"
                                                                     [attr.disabled]="isSelectDisabled">
                                                                     <ng-container *ngFor="let obj of forumAppeal">
                                                                        <option [value]="obj.value">{{obj.label}}
                                                                        </option>
                                                                     </ng-container>
                                                                  </select>
                                                               </td>
                                                            </tr>
                                                         </ng-container>
                                                      </table>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-12 mt15 mb-3">
                                    <button class="btn btn-outline-primary mr-1"
                                       (click)="conclusionSubmitForm(2)">Submit</button>
                                 </div>
                              </form>
                           </fieldset>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
<!-- </div> -->
