import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { AuditService } from '../service/audit.service';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { ISSUE_GST, VALID_FILE_TYPE } from 'src/app/shared/constant';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-audit-tax-position',
  templateUrl: './audit-tax-position.component.html',
  styleUrls: ['./audit-tax-position.component.css']
})
export class AuditTaxPositionComponent implements OnInit {
  navContext = { entityName: '', isTaxPayer: false, entityType: '', pan: '', gstin: '' };
  auditTaxPosFormGrp!: UntypedFormGroup;
  selectedField: any;
  selectedFile: any;
  matches: any;
  fileUrl: any;
  caseId: any;
  selectedGstin: any;
  selectedFilling: any;
  taxPosData: any;
  dataSource: any;
  role: any;
  isGSTRole: any;
  taxPopModalRef!: NgbModalRef;
  rmvjudicialModalRef!: NgbModalRef;
  rmvlegalModalRef!: NgbModalRef;
  isSubmitted: boolean = false;
  formData: FormData = new FormData();

  isUPjpLoc: boolean = true;
  isDWjpLoc: boolean = true;
  isjpLocFile: boolean = false;
  isjpLocText: boolean = true;

  isUPloLoc: boolean = true;
  isDWloLoc: boolean = true;
  isloLocFile: boolean = false;
  isloLocText: boolean = true;

  isGstin = false;
  validFileTypeList: string[] = [];

  constructor(
    private fBuild: UntypedFormBuilder,
    private auditService: AuditService,
    private toaster: ToasterService,
    private GS: GetterSetterService,
    private modalService: NgbModal,
    private router: Router,
    private http: HttpClient,
    private US: UtilityService

  ) {
    this.dataSource = ISSUE_GST;
    this.validFileTypeList = VALID_FILE_TYPE;
  }

  ngOnInit() {
    this.fetchBusinessData();
    this.formInitialize();
    this.role = localStorage.getItem('roleName');
  }

  fetchBusinessData() {
    this.auditService.selectedFilling$.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((data) => {
      if (data) {
        this.selectedFilling = data;
        this.selectedGstin = this.selectedFilling.gstin;
        this.GS.checkEntityType.subscribe((value) => {
          this.navContext = value;
          if (this.navContext.entityType === 'FILING') {
            this.isGstin = true;
          } else {
            this.isGstin = false;
          }
          if (this.taxPosData && (this.navContext.entityType === 'FILING' || this.navContext.entityType === 'Legal') && this.router.url === '/auditTaxPosition') {
            this.reloadCurrentRoute();
          }
        });
      }
      this.initializeData();
    });
  }

  getGstinOrPan() {
    return this.navContext.entityType == 'FILING' ? this.navContext.gstin : this.navContext.pan;
  }

  formInitialize() {
    this.auditTaxPosFormGrp = this.fBuild.group({
      itemList: new UntypedFormArray([]),
    })
  }

  /*fetch function */
  initializeData() {
    let model = {
      gstinOrPan: this.getGstinOrPan()
    };
    this.auditService.getTaxPosData(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.taxPosData = response.response;
        if (this.taxPosData && this.taxPosData.itemList.length > 0) {
          this.taxPosData.itemList.forEach((element: any) => {
            element.isActive = true;
          });
          this.initItemListData();
        } else {
          this.addRow();
        }
      }
    });
  }
  /*fetch function */
  /* Set item List Data */
  initItemListData() {
    if (this.taxPosData.itemList) {
      this.taxPosData.itemList.forEach((posData: any) => {
        let itemListGrp: UntypedFormGroup = this.inittaxPositionGrp();
        (this.auditTaxPosFormGrp.get('itemList') as UntypedFormArray).push(itemListGrp);
        if (posData.judicialPrecedencesLoc) {
          posData.judicialPrecedencesLoc.forEach((issue: any) => {
            const fileFrmGrp: UntypedFormGroup = this.initFileFrmGrp();
            (itemListGrp.get('judicialPrecedencesLoc') as UntypedFormArray).push(fileFrmGrp);
          });
        }
        if (posData.legalOpinionLoc) {
          posData.legalOpinionLoc.forEach((legalOpinion: any) => {
            const fileFrmGrp: UntypedFormGroup = this.initFileFrmGrp();
            (itemListGrp.get('legalOpinionLoc') as UntypedFormArray).push(fileFrmGrp);
          });
        }
      });
      this.auditTaxPosFormGrp.patchValue(this.taxPosData);
    }

  }
  /* Set item List Data */

  // get p1() { return this.auditTaxPosFormGrp.controls; }

  /*add tax array row */
  gettaxPosition(): UntypedFormGroup[] {
    return (this.auditTaxPosFormGrp.get('itemList') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getjudicialPrecedencesLoc(taxPositionGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (taxPositionGrp.get('judicialPrecedencesLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLegalOpinion(taxPositionGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (taxPositionGrp.get('legalOpinionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  initFileFrmGrp() {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl('', []),
      fileName: new UntypedFormControl('', []),
      remarks: new UntypedFormControl('', [Validators.maxLength(100)]),
    })
  }
  inittaxPositionGrp(): UntypedFormGroup {
    return this.fBuild.group({
      issue: [null, [Validators.required]],
      keywords: [null],
      period: [null],
      taxPosTaken: [null, [Validators.required]],
      judicialPrecedencesLoc: new UntypedFormArray([]),
      legalOpinionLoc: new UntypedFormArray([]),
      isActive: new UntypedFormControl(false),
    })
  }
  addRow() {
    let taxPositionGrp = this.inittaxPositionGrp();
    (this.auditTaxPosFormGrp.get('itemList') as UntypedFormArray).push(taxPositionGrp);
    // (taxPositionGrp.get('judicialPrecedencesLoc') as FormArray).push(this.initFileFrmGrp());
    // (taxPositionGrp.get('legalOpinionLoc') as FormArray).push(this.initFileFrmGrp());
  }
  rmProv(i: number) {
    (this.auditTaxPosFormGrp.get('itemList') as UntypedFormArray).removeAt(i);
    this.taxPopModalRef.close();
  }

  openDetailPop(content: any, i: any) {
    this.addLegalOpinionDiv(i, 0);
    this.addJudicialDiv(i, 0);
    this.modalService.open(content);
  }

  addJudicialDiv(j: any, i: any) {
    let itemListFrmArray = this.auditTaxPosFormGrp.get('itemList') as UntypedFormArray;
    let judicialFrmArray = itemListFrmArray.controls[j].get('judicialPrecedencesLoc') as UntypedFormArray;
    if (judicialFrmArray.length > 0) {
      let length = judicialFrmArray.length
      while (i < 5 - length) {
        judicialFrmArray.push(this.initFileFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        judicialFrmArray.push(this.initFileFrmGrp());
        i++;
      }
    }
  }
  rmJudicialDiv(i: number, k: number) {
    let itemListFrmArray = this.auditTaxPosFormGrp.get('itemList') as UntypedFormArray;
    let judicialFrmArray = itemListFrmArray.controls[i].get('judicialPrecedencesLoc') as UntypedFormArray;
    judicialFrmArray.removeAt(k);
    this.rmvjudicialModalRef.close();
  }

  addLegalOpinionDiv(j: any, i: any) {
    let itemListFrmArray = this.auditTaxPosFormGrp.get('itemList') as UntypedFormArray;
    let legalOpinionFrmArray = itemListFrmArray.controls[j].get('legalOpinionLoc') as UntypedFormArray;
    if (legalOpinionFrmArray.length > 0) {
      let length = legalOpinionFrmArray.length
      while (i < 5 - length) {
        legalOpinionFrmArray.push(this.initFileFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        legalOpinionFrmArray.push(this.initFileFrmGrp());
        i++;
      }
    }
  }
  rmLegalOpinionDiv(i: number, k: number) {
    let itemListFrmArray = this.auditTaxPosFormGrp.get('itemList') as UntypedFormArray;
    let legalOpinionFrmArray = itemListFrmArray.controls[i].get('legalOpinionLoc') as UntypedFormArray;
    legalOpinionFrmArray.removeAt(k);
    this.rmvlegalModalRef.close();
  }

  uploadjpLoc(i: any, j: any) {
    let itemListsArray = this.auditTaxPosFormGrp.controls.itemList as UntypedFormGroup;
    let judicialFrmArray = itemListsArray.controls[i].get('judicialPrecedencesLoc') as UntypedFormArray;
    if (judicialFrmArray.controls.length > 0) {
      for (let index = 0; index < judicialFrmArray.controls.length; index++) {
        if (index === j) {
          let itemListuploadControl = judicialFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
    this.isjpLocFile = true;
    this.isjpLocText = false;
    this.isDWjpLoc = false;
    this.isUPjpLoc = false;
  }

  deleteJudicialPredFile(itemListIndex: any, ItrIndex: any) {
    let itemListsArray = this.auditTaxPosFormGrp.controls.itemList as UntypedFormGroup;
    let judicialFrmArray = itemListsArray.controls[itemListIndex].get('judicialPrecedencesLoc') as UntypedFormArray;
    if (judicialFrmArray.controls.length > 0) {
      for (let index = 0; index < judicialFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = judicialFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadLegalOpinion(itemListIndex: any, legalOpinionIndex: any) {
    this.isjpLocFile = true;
    this.isjpLocText = false;
    this.isDWjpLoc = false;
    this.isUPjpLoc = false;

    let itemListsArray = this.auditTaxPosFormGrp.controls.itemList as UntypedFormGroup;
    let legalOpinionFrmArray = itemListsArray.controls[itemListIndex].get('legalOpinionLoc') as UntypedFormArray;
    if (legalOpinionFrmArray.controls.length > 0) {
      for (let index = 0; index < legalOpinionFrmArray.controls.length; index++) {
        if (index === legalOpinionIndex) {
          let itemListuploadControl = legalOpinionFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLegalOpinionFile(itemListIndex: any, ItrIndex: any) {
    let itemListsArray = this.auditTaxPosFormGrp.controls.itemList as UntypedFormGroup;
    let legalOpFrmArray = itemListsArray.controls[itemListIndex].get('legalOpinionLoc') as UntypedFormArray;
    if (legalOpFrmArray.controls.length > 0) {
      for (let index = 0; index < legalOpFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = legalOpFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /* get download file path function */
  getFileNameDta(fileUrl: any) {
    if (fileUrl)
      return this.auditService.getFileName(fileUrl);
  }
  /* get download file path function */
  /*upload file selection */
  onFileSelect(event: any, formGroup: any, index: any, taxPositionGrp: UntypedFormGroup) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.US.checkExtensionFunction(event.target.files[0], this.validFileTypeList)) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;  //GET THE VALUE of (0) FROM itemList(0).letterLOC
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, formGroup, index, taxPositionGrp);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }
  onUpload(selectedfield: any, formGroup: any, index: any, taxPositionGrp: UntypedFormGroup) {
    let urlData = {
      gstinOrPan: this.getGstinOrPan(),
      type: 'taxpos',
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.auditService.uploadFiles(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFileUrl(response.response.url, response.response.path, selectedfield, formGroup, index, taxPositionGrp);
      } else {
      }
    })
  }
  //  setFormData(path: any, selectedfield: any) {
  getFileUrl(url: any, path: any, selectedfield: any, formGroup: any, index: any, taxPositionGrp: UntypedFormGroup) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      if (selectedfield) {
        if (formGroup == 'judicialFromGrp') {
          const b = (taxPositionGrp.get('judicialPrecedencesLoc') as UntypedFormArray).controls as UntypedFormGroup[];
          b[index].controls["fileName"].setValue(this.selectedFile.name);
        } else {
          const b = (taxPositionGrp.get('legalOpinionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
          b[index].controls["fileName"].setValue(this.selectedFile.name);
        }
        var toupdate = this.auditTaxPosFormGrp.value;
        let pathToVeriabl = this.US.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.auditTaxPosFormGrp.patchValue(this.auditTaxPosFormGrp.value);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }

  /*upload file selection */
  /*download file function */
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.auditService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }
  /*download file function */

  manipulateItemList(dataList: any) {
    dataList.forEach((item: any, index: number) => {

      item.judicialPrecedencesLoc = item.judicialPrecedencesLoc.filter((obj: any) => {
        return (obj.fileLoc !== null && obj.fileLoc !== '');
      });
      item.legalOpinionLoc = item.legalOpinionLoc.filter((obj: any) => {
        return (obj.fileLoc !== null && obj.fileLoc !== '');
      });
      item['srNo'] = index + 1;
      delete item.isActive;
    });
    return dataList;
  }


  /*save function */
  saveTaxPosData() {
    this.isSubmitted = true;
    if (this.auditTaxPosFormGrp.valid) {
      let data: any = {
        gstinOrPan: this.getGstinOrPan(),
        itemList: this.manipulateItemList(this.auditTaxPosFormGrp.value.itemList)
      };
      this.auditService.saveTaxPosData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        if (response.status === 1) {
          if (data.itemList.length > 0) {
            data.itemList.forEach((e: any) => {
              e.isActive = true;
            })
          }
          this.toaster.showSuccess(response.message);
        } else {
          this.toaster.showError(response.message);
        }
        this.formInitialize();
        this.initializeData();
      });
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }

  }
  /*save function */
  /*on issue selection display upload field */
  changeIssueDrop(event: any) {
    this.isSubmitted = true;
    this.saveTaxPosData();
  }
  /*on issue selection display upload field */


  opentaxPop(content: any, i: any) {
    this.taxPopModalRef = this.modalService.open(content);
  }

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('itemList')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  getFileLength(j: any, type: string) {
    let fileFrmArray = null;
    let itemListFrmArray = this.auditTaxPosFormGrp.get('itemList') as UntypedFormArray;
    if (type == 'judicialPrecedencesLoc') {
      fileFrmArray = itemListFrmArray.controls[j]?.get('judicialPrecedencesLoc') as UntypedFormArray;
    } else {
      fileFrmArray = itemListFrmArray.controls[j]?.get('legalOpinionLoc') as UntypedFormArray;
    }
    let filteredList = fileFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);

    return filteredList.length;
  }
}
