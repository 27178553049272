<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
      <div class="content-wrapper">
        <div class="row " style="justify-content:center">
            <div class="col-md-10">
                <div class="card" style="background-color: #f4f4f4 !important;">
                    <div>
                        <div class="print-btn" style="margin-right: 20px;">
                            <button type="button" class="close" aria-label="Close" (click)="goBack()">
                                <span aria-hidden="true" style="color: red;">&times;</span>
                              </button>
                        </div>
                        <div>
                            <a (click)="generatePdf()" class="ml-3 print-btn">
                                <button title="Print" type="button" class="btn btn-outline-primary btn-sm" style="background: none;
                                border-radius: 5px;">
                                    <i class="fa fa-print" aria-hidden="true" style="color: black;
                                font-size: medium;"></i></button>
                                </a>
                        </div>
    

<div #content class="card-body">
    <div class="container mt-4">
        <div class="row">
            <div class="col-7 d-flex justify-content-between">
                <img class="logo-card-info" width="58" src="../../../assets/images/IRISGST-logo.png" alt="">
                <h2 class="text-right">LITIGATION REPORT</h2>
            </div>
            <div class="col-5">
                <div class="row md-2 text-right">
                    <p class="md-text-right">(All values are in crores)</p>
                </div>
                <div class="row text-right mt-4">
                    <span class="computationBtn">
                        <span class="nav-data"><span class="text-data"> Last Updated By :
                          </span>{{currentForum?.updatedBy}} &nbsp;
                          <span class="text-data"> On : </span> {{currentForum?.updatedOn}} </span>
                      </span>
                </div>
            </div>
        </div>
    </div>
    <div class="card custom-card" id="caseInfo" >
        <div class="card-header d-flex justify-content-between">
          <p style="margin-right: auto; font-size: medium;">
            Case Information
          </p>  

          @if(currentForum?.status == "CLOSED")
          {
            <p style="margin-bottom: 0.1rem;">Demand Amount &nbsp;<i class="fa fa-rupee rupee-icon"></i>:
                 <span class="amount-title">{{currentForum?.demandConfirmed3}} Crore</span></p> 
          }
        @else{
            <p style="margin-bottom: 0.1rem;">Amount under litigation <i class="fa fa-rupee rupee-icon"></i>:
                <span class="amount-title">{{currentForum?.totOfTotal1}} Crore</span>
                </p> 
        }

        </div>
        @if(module == 'GST') {
        <div class="additional-content p-2 text-dark bg-opacity-10" style="background: #a9a9a9;">
         <b>{{currentForum?.gstinDiv}}__{{currentForum?.gstinName}}__{{currentForum?.gstin}}</b>
         </div>
        }
        <div class="additional-content p-2 text-dark bg-opacity-10 bgGred">
            <div class="row case-info-div">
                <div class="col-md-5 case-details">
                    <label>Case Id</label>
                    <p>Current Forum</p>
                </div>
                <div class="col-md-5 case-details">
                    <p>Case Label</p>   
                </div>
                <div class="col-md-2 case-details">
                    <p>Case Status</p> 
                </div>
            </div>

            <div class="row">
                <div class="col-md-5 case-details">
                  <label for="">{{currentForum?.caseId}}</label>    
                  @if(currentForum?.currentForum == adjudicationAuthority)
                    {
                        <label>Adjudication</label>
                    }
                    @else{
                        <label>{{currentForum?.currentForum}}</label>
                    }
                </div>
                <div class="col-md-5 case-details">
                    <label class="w-auto">{{currentForum?.caseLabel}}</label>
                </div>
                <div class="col-md-2 case-details">
                    @if(currentForum?.status != "CLOSED"){
                        <label class="badge badge-warning status-lbl">{{currentForum?.status}}</label>
                    }
                    @else{
                        <label class="badge badge-success status-lbl">{{currentForum?.status}}</label>
                    } 
                </div>
            </div>


        </div>
            
                                <div class="card-body">
                                        <div class="row">
                                            <div class="col-md-4 case-details">
                                                @if(currentForum?.currentForum === prelitigationLabel){
                                                    <label><b>Notice/Letter:</b></label>
                                                } @else if(currentForum?.currentForum === adjudicationAuthority){
                                                    <label><b>SCN Details:</b></label>
                                                } @else {
                                                    <label><b>Appealable Order:</b></label>
                                                }
                                            </div>
                                            <div class="col-md-4 case-details">
                                                @if(currentForum?.currentForum === prelitigationLabel){
                                                    <label><b>Submission/Reply:</b></label>
                                                } @else {
                                                    <label><b>Submission/Hearing:</b></label> 
                                                }                
                                            </div>
                                            <div class="col-md-4 case-details">
                                                @if(currentForum?.currentForum === prelitigationLabel){
                                                    <label><b>Order/SCN Details:</b></label>
                                                } @else if(currentForum?.currentForum === adjudicationAuthority){
                                                    <label><b>Order Against SCN:</b></label>
                                                } @else {
                                                    <label><b>Order Against Appeal:</b></label>
                                                }
                                            </div>
                                        </div>

                                        @if(currentForum?.currentForum === prelitigationLabel){
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <label>Notice/Letter Type</label>                
                                                    <p>{{currentForum?.noticeType}}</p>
                                                </div>
                                                <div class="col-md-4 case-details">
                                                    <label>Reply Filed on</label>   
                                                    <p>{{currentForum?.submissionDate}}</p>
                                                </div>
                                                @if(currentForum?.status == 'CLOSED')
                                                {
                                                    <div class="col-md-4 case-details">
                                                        <label class="text-nowrap">Order Number</label>   
                                                        <p class="text-wrap widht">{{currentForum?.orderNumber3}}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <label class="text-nowrap">Notice/Letter Number</label>
                                                    <p class="text-wrap widht">{{currentForum?.letterRefNo}}</p>
                                                    @if(currentForum?.status == 'CLOSED'){
                                                    }    
                                                    <!-- <p>{{currentForum?.scnNo}}</p> -->
                                                </div>

                                                <div class="col-md-4 case-details">
                                                    <label>Hearing Date</label>   
                                                    <p>{{currentForum?.hearingDate}}</p>
                                                </div>
                                                @if(currentForum?.status == "CLOSED" )
                                                {
                                                    <div class="col-md-4 case-details">
                                                            <label>Date of Order</label>   
                                                            <p>{{currentForum?.orderDate3}}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <label class="text-nowrap">Date of Notice/Letter</label>                
                                                    <p>{{currentForum?.letterDate}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <label class="text-nowrap">Date of Receipt</label>                
                                                    <p class="text-wrap widht">{{currentForum?.dateOfRecieptOfLetter}}</p>
                                                </div>
                                                <div class="col-md-4 case-details">
                                                    <label class="text-nowrap">Hearing Staus</label>                
                                                    <p>{{currentForum?.hearingStatus}}</p>
                                                </div>

                                                @if(currentForum?.status == 'CLOSED')
                                                {
                                                    <div class="col-md-4 case-details">
                                                        <label class="text-nowrap">Date of Receipt</label>                
                                                        <p>{{currentForum?.dateOfRecieptOfOrder3}}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div class="row">
                                                <div class="col-md-8 case-details">
                                                    <label class="text-nowrap">Due Date of Reply</label>                
                                                    <p>{{currentForum?.legalDueDate}}</p>
                                                </div>

                                                @if(currentForum?.status == 'CLOSED')
                                                {
                                                    <div class="col-md-4 case-details">
                                                        <label class="text-nowrap">Demand Confirmed</label>                
                                                        <p>{{currentForum?.demandConfirmed3}}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div class="row">
                                                <div class="col-md-8 case-details">
                                                    <label>Period From</label>                
                                                    <p>{{currentForum?.periodFromDate}}</p>
                                                </div>
                                                @if(currentForum?.status == 'CLOSED')
                                                {
                                                    <div class="col-md-4 case-details">
                                                        <label class="text-nowrap">Demand Dropped</label>                
                                                        <p>{{currentForum?.droppedAmount3}}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 case-details">
                                                    <label>Period To</label>                
                                                    <p>{{currentForum?.periodToDate}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-5 case-details">
                                                    <label class="text-nowrap">Demand Amount</label>                
                                                    <p>{{currentForum?.totOfTotal1}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-5 case-details">
                                                    <label>Person Responsible</label>                
                                                    <p class="text-nowrap">{{currentForum?.personResponsible}}</p>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-md-5 case-details">
                                                    <label>Consultant</label>                
                                                    <p>{{currentForum?.consultant}}</p>
                                                </div>
                                            </div>
                                        }
                                        
                                        @else if(currentForum?.currentForum===(adjudicationAuthority || preGst_adjudicationAuthority)) {
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                        <label class="text-nowrap">SCN Number</label>                    
                                                    @if(currentForum?.currentForum == adjudicationAuthority)
                                                    {
                                                        <p class="text-wrap widht">{{currentForum?.scnNo}}</p>
                                                    }
                                                    @else{
                                                        <p class="text-wrap widht">{{currentForum?.noticeNumber}}</p>
                                                    }
                                                </div>
                                                <div class="col-md-4 case-details normal-col-gap">
                                                    <label>Reply Filled on:</label>                
                                                    <p>{{currentForum?.submissionDate}}</p>
                                                </div>
                                                <!-- <div class="col-md-3 case-details normal-col-gap">
                                                    <label>Hearing date</label>                
                                                    <p>{{currentForum?.hearingDate}}</p>
                                                </div> -->
                                                    @if(currentForum?.status == 'CLOSED')
                                                    {
                                                        <div class="col-md-4 case-details">
                                                            <label class="text-nowrap">Order Number</label>   
                                                            <p class="text-wrap widht">{{currentForum?.orderNumber3}}</p>
                                                        </div>
                                                    }
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                        <label>Date of SCN</label>                 
                                                    
                                                    @if(currentForum?.currentForum == adjudicationAuthority)
                                                    {
                                                        <p>{{currentForum?.dateOfScn1}}</p>
                                                    }
                                                    @else{
                                                        <p>{{currentForum?.noticeDate}}</p>
                                                    }
                                                </div>
                                                <div class="col-md-4 case-details normal-col-gap">
                                                    <label>Hearing date</label>                
                                                    <p>{{currentForum?.hearingDate}}</p>
                                                </div>
                                                <!-- <div class="col-md-3 case-details">
                                                    <label>Hearing Attended</label>  
                                                    @if(currentForum?.attended){
                                                        <p>YES</p>
                                                    }   
                                                    @else{
                                                        <p>No</p>
                                                    } 
                                                </div> -->
                                                @if(currentForum?.status == "CLOSED" )
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Date of Order</label>                
                                                        <p>{{currentForum?.orderDate3}}</p>
                                                    </div>
                                                }
                                                
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <label>Date of Receipt </label> 
                                                    @if(currentForum?.currentForum == adjudicationAuthority)
                                                    {
                                                        <p>{{currentForum?.dateOfReceiptOfScn}}</p>
                                                    }
                                                    @else{
                                                        <p>{{currentForum?.dateOfRecieptOfNotice}}</p>
                                                    }
                                                </div>
                                                <div class="col-md-4 case-details">
                                                    <label>Hearing Attended</label>  
                                                    @if(currentForum?.attended){
                                                        <p>YES</p>
                                                    }   
                                                    @else{
                                                        <p>No</p>
                                                    } 
                                                </div>
                                                @if(currentForum?.status == "CLOSED")
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Date of Receipt</label>                
                                                        <p>{{currentForum?.dateOfRecieptOfOrder3}}</p>
                                                    </div>
                                                }
                                            </div>

                                            <div class="row">
                                                <div class="col-md-8 case-details">
                                                    <label class="text-nowrap">Due date to file Reply</label> 
                                                    <p>{{currentForum?.dueDateOfReply}}</p>
                                                </div>
                                                
                                                @if(currentForum?.status === "CLOSED")
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Demand Confirmed</label>                
                                                        <p>{{currentForum?.demandConfirmed3}}</p>
                                                    </div>
                                                }
                                                
                                            </div>
                                            <div class="row">
                                                <div class="col-md-8 case-details">
                                                    <label>Period From</label>                
                                                    <p>{{currentForum?.periodFromDate}}</p>
                                                </div>
                                                @if(currentForum?.status == "CLOSED")
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Demand Dropped</label>                
                                                        <p>{{currentForum?.droppedAmount3}}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <label>Period To</label>                
                                                    <p>{{currentForum?.periodToDate}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <label class="text-nowrap">Demand Amount</label>                
                                                    <p>{{currentForum?.totOfTotal1}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-5 case-details">
                                                    <label>Person Responsible</label>                
                                                    <p class="text-nowrap">{{currentForum?.personResponsible}}</p>
                                                </div>
                                                <div class="col-md-3 case-details normal-col-gap">
                                                    
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-5 case-details">
                                                    <label>Consultant</label>                
                                                    <p>{{currentForum?.consultant}}</p>
                                                </div>
                                            </div>
                                        }

                                        @else if(currentForum?.currentForum === appellateTribunal
                                        || currentForum?.currentForum === commissioner 
                                        || currentForum?.currentForum === commissioner_ca 
                                        || currentForum?.currentForum === commissioner_A
                                        || currentForum?.currentForum === appellateTribunal 
                                        || currentForum?.currentForum === highCourt 
                                        || currentForum?.currentForum === supremeCourt 
                                        || currentForum?.currentForum === preGst_appellateTribunal
                                        || currentForum?.currentForum === preGst_appellateAuthority
                                        || currentForum?.currentForum === preGst_highCourt
                                        || currentForum?.currentForum === preGst_supremeCourt
                                        ){
                                            <div class="row">
                                                <div class="col-md-4 case-details">

                                                    <!-- @if(currentForum?.status != "CLOSED")
                                                    {
                                                        <label>Notice/Letter No</label>                
                                                    }
                                                    @else{ -->
                                                        <label class="text-nowrap">Order Number</label>                
                                                    <!-- } -->
                                                        <p class="text-wrap widht">{{currentForum?.orderNumber}}</p>
                                                </div>
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label class="text-nowrap">Appeal filed on</label>    
                                                        <p>{{currentForum?.appealFilingDate}}</p>
                                                    </div>
                                                    @if(currentForum?.status == 'CLOSED')
                                                    {
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label class="text-nowrap">Order Number</label>   
                                                            <p class="text-wrap widht">{{currentForum?.orderNumber3}}</p>
                                                        </div>
                                                    }
                                            </div>

                                            <div class="row">
                                                <div class="col-md-4 case-details normal-col-gap">

                                                    <!-- @if(currentForum?.status != "CLOSED")
                                                    {
                                                        <label>Date of Notice/ Order</label>                
                                                    }
                                                    @else{ -->
                                                        <label>Date of Order</label>                
                                                    <!-- } -->
                                                    <p>{{currentForum?.orderDate}}</p>
                                                </div>
                                                <!-- <div class="col-md-5 case-details">
                                                    @if(currentForum?.status != "CLOSED")
                                                    {
                                                        <label class="text-nowrap">Date of Recipt of Notice/ Letter</label>                
                                                    }
                                                    @else{
                                                        <label>Date of Receipt</label>                 
                                                    }            
                                                    <p>{{currentForum?.dateOfRecieptOfOrder}}</p>
                                                </div> -->
                                                <div class="col-md-4 case-details">
                                                    <label>Hearing date</label>                
                                                    <p>{{currentForum?.hearingDate}}</p>
                                                </div>
                                                @if(currentForum?.status == "CLOSED" )
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Date of Order</label>                
                                                        <p>{{currentForum?.orderDate3}}</p>
                                                    </div>
                                                }
                                                
                                            </div>

                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <!-- @if(currentForum?.status != "CLOSED")
                                                    {
                                                        <label>Date of Recipt of Notice/ Letter</label>                
                                                    }
                                                    @else{ -->
                                                        <label>Date of Receipt</label>                 
                                                    <!-- }             -->
                                                    <p>{{currentForum?.dateOfRecieptOfOrder}}</p>
                                                </div>
                                                <!-- <div class="col-md-5 case-details normal-col-gap">

                                                    @if(currentForum?.status != "CLOSED")
                                                    {
                                                        <label>Date of Notice/ Letter</label>                
                                                    }
                                                    @else{
                                                        <label>Date of Order</label>                
                                                    }
                                                    <p>{{currentForum?.orderDate}}</p>
                                                </div> -->
                                                <div class="col-md-4 case-details">
                                                    <label>Hearing Attended</label>                
                                                    @if(currentForum?.attended){
                                                        <p>YES</p>
                                                    }   
                                                    @else{
                                                        <p>No</p>
                                                    } 
                                                </div>
                                                @if(currentForum?.status == "CLOSED")
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Date of Receipt</label>                
                                                        <p>{{currentForum?.dateOfRecieptOfOrder3}}</p>
                                                    </div>
                                                }
                                            </div>

                                            <div class="row">
                                                <div class="col-md-8 case-details">
                                                    <label class="text-nowrap">Due date to Appeal</label>    
                                                    @if (currentForum?.currentForum === appellateTribunal || currentForum?.currentForum === commissioner_A) {
                                                        <p>{{currentForum?.dueDateOfAppeal}}</p>
                                                    } @else {
                                                        <p>{{currentForum?.dueDateToAppeal}}</p>
                                                    }
                                                </div>
                                                <!-- <div class="col-md-3 case-details">
                                                    <label>Period To</label>                
                                                    <p>{{currentForum?.periodToDate}}</p>
                                                </div> -->

                                                @if(currentForum?.status == "CLOSED")
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Demand Confirmed</label>                
                                                        <p>{{currentForum?.demandConfirmed3}}</p>
                                                    </div>
                                                }
                                            </div> 
                                            <div class="row">
                                                <div class="col-md-8 case-details">
                                                    <label>Period From</label>                
                                                    <p>{{currentForum?.periodFromDate}}</p>
                                                </div>
                                                @if(currentForum?.status == "CLOSED")
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Demand Dropped</label>                
                                                        <p>{{currentForum?.droppedAmount3}}</p>
                                                    </div>
                                                }
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <label>Period To</label>                
                                                    <p>{{currentForum?.periodToDate}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <label class="text-nowrap">Demand Amount</label>                
                                                    <p>{{currentForum?.totOfTotal1}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-8 case-details">
                                                    <label>Person Responsible</label>                
                                                    <p class="text-nowrap">{{currentForum?.personResponsible}}</p>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-4 case-details">
                                                    <label>Consultant</label>                
                                                    <p>{{currentForum?.consultant}}</p>
                                                </div>
                                            </div>
                                        }

                                        <!-- @else if(currentForum?.currentForum === supremeCourt || currentForum?.currentForum === preGst_supremeCourt){
                                            <div class="row">
                                                <div class="col-md-5 case-details">
                                                    @if(currentForum?.status != "CLOSED")
                                                    {
                                                        <label>Notice/Letter No</label>                
                                                    }
                                                    @else{
                                                        <label>Order Number</label>                
                                                    }
                                                    <p>{{currentForum?.orderNumber}}</p>
                                                </div>
                                                <div class="col-md-3 case-details normal-col-gap">
                                                    <label>Appeal filed on</label>                
                                                    <p>{{currentForum?.AppealFilingDate}}</p>
                                                </div>

                                                @if(currentForum?.status == "CLOSED")
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Order Number</label>                
                                                        <p>{{currentForum?.orderNumber3}}</p>
                                                    </div>
                                                }
                                            

                                            </div>


                                            <div class="row">
                                                @if(currentForum?.status == "CLOSED")
                                                {
                                                    <div class="col-md-5 case-details normal-col-gap">
                                                        <label>Date of Receipt</label>                
                                                        <p>{{currentForum?.dateOfRecieptOfOrder}}</p>
                                                    </div>
                                                    <div class="col-md-3 case-details normal-col-gap">
                                                        <label>Hearing date</label>                
                                                        <p>{{currentForum?.hearingDate}}</p>
                                                    </div>
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Date of Order</label>                
                                                        <p>{{currentForum?.orderDate3}}</p>
                                                    </div>
                                                }
                                            </div>

                                            <div class="row">
                                                <div class="col-md-5 case-details">
                                                    <label class="text-nowrap">Due date to file Appeal</label>                
                                                    <p>{{currentForum?.dueDateToAppeal}}</p>
                                                </div>

                                                @if(currentForum?.status != "CLOSED")
                                                {
                                                    <div class="col-md-3 case-details normal-col-gap">
                                                        <label>Hearing date</label>                
                                                        <p>{{currentForum?.hearingDate}}</p>
                                                    </div>
                                                }
                                                @else {
                                                    <div class="col-3 case-details">
                                                        <label>Hearing Attended</label>                
                                                        @if(currentForum?.attended){
                                                            <p>YES</p>
                                                        }   
                                                        @else{
                                                            <p>No</p>
                                                        } 
                                                    </div>

                                                }

                                                @if(currentForum?.status == "CLOSED")
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Date of Receipt</label>                
                                                        <p>{{currentForum?.dateOfRecieptOfOrder3}}</p>
                                                    </div>
                                                }

                                            </div>

                                            <div class="row">
                                                <div class="col-md-5 case-details">
                                                    <label>Demand Amount</label>                
                                                    <p>{{currentForum?.totOfTotal1}}</p>
                                                </div>
                                                <div class="col-3 case-details">
                                                @if(currentForum?.status != "CLOSED")
                                                {
                                                        <label>Hearing Attended</label>                
                                                        @if(currentForum?.attended){
                                                            <p>YES</p>
                                                        }   
                                                        @else{
                                                            <p>No</p>
                                                        } 
                                                }
                                                </div>
                                                @if(currentForum?.status == "CLOSED")
                                                {
                                                    <div class="col-md-4 case-details normal-col-gap">
                                                        <label>Demand Confirmed</label>                
                                                        <p>{{currentForum?.totalOfTotal3}}</p>
                                                    </div>
                                                }
                                            </div>
                                        

                                <div class="row">
                                    <div class="col-5 case-details">
                                        <label>Person Responsible</label>                
                                        <p>{{currentForum?.personResponsible}}</p>
                                    </div>
                                    
                                    @if(currentForum?.status == "CLOSED")
                                    {
                                        <div class="col-md-4 case-details normal-col-gap">
                                            <label>Demand Dropped</label>                
                                            <p>{{currentForum?.droppedAmount3}}</p>
                                        </div>
                                    }
                                </div>

                                <div class="row">
                                    <div class="col-md-5 case-details">
                                        <label>Consultant</label>                
                                        <p>{{currentForum?.consultant}}</p>
                                    </div>
                                    <div class="col-md-4 case-details normal-col-gap">
                                    </div>
                                </div>
                            } -->
                                </div>
           
</div>
                                <div>
                                    <div class="head-table mb-0">
                                        <div class="row">
                                            <div class="text-md-size">Time Line</div>
                                        </div>
                                    </div>
                                    <div class="card custom-card custom-border-radius mt-0">
                                    <div class="card-body">
                                            <div class="row">
                                            @for(item of caseHistoryForBind; track item ; let idx = $index) {
                                                <div class="stepper-wrapper">
                                                    <div class="stepper-item completed">
                                                    
                                                    <div class="step-counter m-2">
                                                        <span>
                                                            @if (item.currentForum && !item.closed) {
                                                                <i class="fa fa-circle" style="font-size: x-large; color: #007057;" aria-hidden="true"></i>
                                                            }
                                                            @else{
                                                                <i class="fa fa-check" aria-hidden="true"></i>
                                                            }
                                                        </span>
                                                    </div>
                                                    <div class="step-name">

                                                        @if(item.currentForum){
                                                            <h5 class="text-center">{{item.forumText}}</h5>
                                                        }
                                                        @else{
                                                            <p class="text-center fs-6">{{item.forumText}}</p>
                                                        }
                                                        </div>
                                                        @if ( item?.forumText === "Pre Litigation" || currentForum?.currentForum == prelitigationLabel) {
                                                            @if(item.currentForum){
                                                                <label><b>Notice/Letter Received On:</b></label>
                                                            } @else {
                                                                <label>Notice/Letter Received On:</label>
                                                            }
                                                        }
                                                        @else if ( item?.forumText === 'Audit Findings Letter'|| currentForum?.currentForum === 'Audit Findings Letter') {
                                                            @if(item.currentForum){
                                                                <label><b>Audit Starts on:</b></label>
                                                            } @else {
                                                                <label>Audit Start on:</label>
                                                            }
                                                        }
                                                        @else if ( item?.forumText === adjudication || currentForum?.currentForum === adjudicationAuthority) {
                                                            @if(item.currentForum){
                                                                <label><b>SCN Recived On:</b></label>
                                                            } @else {
                                                                <label>SCN Recived On:</label>
                                                            }
                                                        }
                                                        @else {
                                                            @if(item.currentForum){
                                                                <label><b>Appeal Filled On:</b></label>
                                                            } @else {
                                                                <label>Appeal Filled On: </label>
                                                            }
                                                        }
                                                        @if (item.date) {
                                                            @if(item.currentForum){
                                                                <div> <b> {{item?.date}}</b></div>
                                                            }
                                                            @else{
                                                                <div> {{item?.date}}</div>
                                                            } 
                                                        }@else {
                                                            <div>-----</div>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                @if(currentForum?.caseSummary || currentForum?.internalRemarks || currentForum?.orderSummary){
                                    <div class="card custom-card mt-4">
                                        <div class="card-body">  
                                            @if(currentForum?.caseSummary)
                                            {    
                                                <div class="row mb-2">
                                                    <div class="col-12">
                                                        <h4>Case Summary</h4>
                                                        <p>{{currentForum?.caseSummary}}</p>
                                                    </div>
                                                </div>
                                            }
                                    
                                            @if(currentForum?.internalRemarks)
                                            {
                                                <div class="row mb-2">
                                                    <div class="col-12">
                                                    <h4>Internal Remarks</h4>
                                                    <p>{{currentForum?.internalRemarks}}</p>
                                                    </div>
                                                </div>
                                            }
                                            
                                            @if(currentForum?.status === "CLOSED" || currentForum?.orderSummary)
                                            {
                                                <div class="row">
                                                    <div class="col-12">
                                                    <h4>Order Summary</h4>
                                                    <p> {{currentForum?.orderSummary}}</p>
                                                    </div>
                                                </div>

                                            }
                                            
                                        </div>  
                                    </div>
                                }

                                @if(currentForum?.totOfTotal1) {
                                    <div class="head-table mb-0" style="margin-left: 1.5px;">
                                                <div class="row">
                                                    <div class="text-md-size">Demand Summary</div>
                                                </div>
                                    </div>

                                    @if(module == 'GST')
                                    {

                                        <div class="row nopadding">
                                            <div class="table-responsive">
                                            <table class="table report-summary text-center" style="border: 1px solid rgb(221 227 227); border-collapse: collapse;">
                                                <thead>
                                                <tr class="bgGred">
                                                    <th class="text-left pl-5" >Phase</th>
                                                    <!-- <th class="sl-no-cls"></th> -->
                                                    <th>IGST</th>
                                                    <th>CGST</th>
                                                    <th>SCST</th>
                                                    <th>CESS</th>
                                                    <th>Interest</th>
                                                    <th>Penalty</th>
                                                    <th>Total</th>
                                                </tr> 
                                                </thead>
                                                <tbody class="bg-color">
                                                    <tr>
                                                        <td class="text-left pl-5">
                                                            @if (currentForum?.currentForum == adjudicationAuthority) {
                                                                <b>As Per SCN Details:</b>
                                                            } @else {
                                                                <b>As Per Appealable Order:</b>
                                                            }
                                                        </td>
                                                        <td>{{currentForum?.igst1Total}}</td>
                                                        <td>{{currentForum?.cgst1Total}}</td>
                                                        <td>{{currentForum?.sgst1Total}}</td>
                                                        <td>{{currentForum?.cess1Total}}</td>
                                                        <td>{{currentForum?.interest1Total}}</td>
                                                        <td>{{currentForum?.penalty1Total}}</td>
                                                        <td>{{currentForum?.totOfTotal1}}</td>
                                                    </tr>

                                                    @if (currentForum?.status === "CLOSED") {
                                                        <tr>
                                                            <td class="text-left pl-5">
                                                                @if (currentForum?.currentForum == adjudicationAuthority) {
                                                                    <b>As Per Order Against SCN:</b>
                                                                } @else {
                                                                    <b>As Per Order Against Appeal:</b>
                                                                }
                                                            </td>
                                                            <td>{{currentForum?.igst3DemandConfirmedTotal}}</td>
                                                            <td>{{currentForum?.cgst3DemandConfirmedTotal}}</td>
                                                            <td>{{currentForum?.sgst3DemandConfirmedTotal}}</td>
                                                            <td>{{currentForum?.cess3DemandConfirmedTotal}}</td>
                                                            <td>{{currentForum?.interest3DemandConfirmedTotal}}</td>
                                                            <td>{{currentForum?.penalty3DemandConfirmedTotal}}</td>
                                                            <td>{{currentForum?.demandConfirmed3}}</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                        

                                    }
                                
                                    @if(module == 'PREGST'){
                                        <div class="row nopadding">
                                            <div class="table-responsive">
                                            <table class="table report-summary text-center" style="border: 1px solid rgb(221 227 227); border-collapse: collapse;">
                                                <thead>
                                                <tr class="bgGred">
                                                    <th class="sl-no-cls"></th>
                                                    @if(currentForum?.actType == 'EXCISE'){
                                                        <th>Excise Duty</th>
                                                        <th>EC</th>
                                                        <th>SHEC</th>
                                                    }

                                                    @if(currentForum?.actType == 'CUSTOMS DUTY'){
                                                        <th>BCD</th>
                                                    }

                                                    @if(currentForum?.actType == 'VAT'){
                                                        <th>VAT</th>
                                                    }

                                                    @if(currentForum?.actType == 'SERVICE TAX'){
                                                        <th>Service Tax</th>
                                                        <th>EC</th>
                                                        <th>SHEC</th>
                                                        <th>KKC</th>
                                                        <th>SBC</th>
                                                    }

                                                    @if(currentForum?.actType == 'CST'){
                                                        <th>CST</th>
                                                    }

                                                    @if(currentForum?.actType == 'ENTRY TAX')
                                                    {
                                                        <th>Tax</th>
                                                    }
                                                    
                                                    <th>INTEREST</th>
                                                    <th>PENALTY</th>
                                                    <th>TOTAL</th>
                                                </tr>
                                                </thead>
                                                <tbody class="bg-color">
                                                    <tr>
                                                        <td></td>
                                                        @if(currentForum?.actType == 'EXCISE'){
                                                            <td>{{currentForum?.exciseDuty1Total}}</td>
                                                            <td>{{currentForum?.ec1Total}}</td>
                                                            <td>{{currentForum?.shec1Total}}</td>
                                                        }
                                
                                                        @if(currentForum?.actType == 'CUSTOMS DUTY'){
                                                            <td>{{currentForum?.bcd1Total}}</td>
                                                        }
                                
                                                        @if(currentForum?.actType == 'VAT'){
                                                            <td>{{currentForum?.exciseDuty1Total}}</td>
                                                        }
                                
                                                        @if(currentForum?.actType == 'SERVICE TAX'){
                                                            <td>{{currentForum?.serviceTax1Total}}</td>
                                                            <td>{{currentForum?.ec1Total}}</td>
                                                            <td>{{currentForum?.shec1Total}}</td>
                                                            <td>{{currentForum?.kkc1Total}}</td>
                                                            <td>{{currentForum?.sbc1Total}}</td>
                                                        }
                                
                                                        @if(currentForum?.actType == 'CST'){
                                                            <td>{{currentForum?.cst1Total}}</td>
                                                        }
                                
                                                        @if(currentForum?.actType == 'ENTRY TAX')
                                                        {
                                                            <td>{{currentForum?.ent1Total}}</td>
                                                        }

                                                        <td>{{currentForum?.interest1Total}}</td>
                                                        <td>{{currentForum?.penalty1Total}}</td>
                                                        <td>{{currentForum?.totOfTotal1}}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    }
                                }

                                @if(cumulativePaymentDetails?.length){
                                <div>
                                    <div class="head-table mb-0" style="margin-left: 1.5px;">
                                        <div class="row">
                                            <div class="text-md-size">Payment Details</div>
                                        </div>
                                    </div>

                                    <div class="row nopadding">
                                        <div class="table-responsive">
                                        <table class="table report-summary text-center" style="border: 1px solid rgb(221 227 227);
                                    border-collapse: collapse;">
                                            <thead>
                                            <tr class="bgGred">
                                                <th></th>
                                                @for(timeline of caseHistoryForBind; track item ; let idx = $index) {
                                                    @if (timeline?.forumText !== 'Audit Findings Letter') {
                                                        <th>{{timeline?.forumText}}</th>
                                                    }
                                                }
                                                <th>Total</th>
                                            </tr>
                                            </thead>
                                            <tbody class="bg-color">
                                                @for(item of cumulativePaymentDetails; track item ; let idx = $index){
                                                    <tr>
                                                        <td class="text-capitalize text-left font-weight-bold">{{item.label}}</td>
                                                        @if(item?.scn || item?.scn == 0){
                                                            <td>{{item.scn}}</td>
                                                        }
                                                        @if(item?.aja || item?.aja == 0){
                                                            <td>{{item.aja}}</td>
                                                        }
                                                        @if(item?.apa || item?.apa == 0){
                                                            <td>{{item.apa}}</td>
                                                        }
                                                        @if(item?.apt || item?.apt == 0){
                                                            <td>{{item.apt}}</td>
                                                        }
                                                        @if(item?.hc || item?.hc == 0){
                                                            <td>{{item.hc}}</td>
                                                        }
                                                        @if(item?.sc || item?.sc == 0){
                                                            <td>{{item.sc}}</td>
                                                        }
                                                        <td>{{item.total}}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                                }
    
                                @if(consultantChargesList?.length){
                                    <div class="pb-4">
                                        <div class="head-table mb-0" style="margin-left: 1.5px;">
                                            <div class="row">
                                                <div class="text-md-size">Consultant Cost</div>
                                            </div>
                                        </div>
                                        <div class="row nopadding">
                                            <div class="table-responsive">
                                            <table class="table report-summary text-center" style="border: 1px solid rgb(221 227 227);border-collapse: collapse;">
                                            <thead>
                                                <tr class="bgGred">
                                                    <th></th>
                                                    @for(timeline of caseHistoryForBind; track item ; let idx = $index) {
                                                        @if (timeline?.forumText !== 'Audit Findings Letter') {
                                                            <th>{{timeline?.forumText}}</th>
                                                        }
                                                    }
                                                    <th>Total</th>
                                                </tr>
                                                </thead>
                                                <tbody class="bg-color">
                                                    @for(item of consultantChargesList; track item ; let idx = $index){
                                                        <tr>
                                                            <td class="text-capitalize text-left font-weight-bold">{{item.label}}</td>
                                                            @if(item?.scn || item?.scn == 0){
                                                                <td>{{item.scn}}</td>
                                                            }
                                                            @if(item?.aja || item?.aja == 0){
                                                                <td>{{item.aja}}</td>
                                                            }
                                                            @if(item?.apa || item?.apa == 0){
                                                                <td>{{item.apa}}</td>
                                                            }
                                                            @if(item?.apt || item?.apt == 0){
                                                                <td>{{item.apt}}</td>
                                                            }
                                                            @if(item?.hc || item?.hc == 0){
                                                                <td>{{item.hc}}</td>
                                                            }
                                                            @if(item?.sc || item?.sc == 0){
                                                                <td>{{item.sc}}</td>
                                                            }
                                                            <td>{{item.total}}</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                }
    
                                @if(issuesDetails?.length){   
                                <div>
                                    <div class="head-table mb-0" style="margin-left: 1.5px;">
                                        <div class="row">
                                            <div class="text-md-size">Issue Involved</div>
                                        </div>
                                    </div>

                                    @if(module == 'GST')
                                    {
                                        <div class="row nopadding">
                                            <div class="table-responsive">
                                            <table class="table report-summary text-center" style="border: 1px solid rgb(221 227 227);
                                        border-collapse: collapse;">
                                                <thead>
                                                        <tr class="bgGred">
                                                            <th class="sl-no-cls">Sr.No</th>
                                                            <th class="text-left">ISSUE</th>
                                                            <th>IGST</th>
                                                            <th>CGST</th>
                                                            <th>SCST</th>
                                                            <th>CESS</th>
                                                            <th>Interest</th>
                                                            <th>Penalty</th>
                                                            <th>Total</th>
                                                            <th>Status</th>
                                                        </tr>
                                                </thead>
                                                <tbody class="bg-color">
                                
                                                    @for (item of issuesDetails; track item ; let idx = $index) {
                                                        @if(currentForum.status !== 'CLOSED') {
                                                                <tr>
                                                                    <td>{{idx + 1}}</td>
                                                                    <td class="text-left">{{item?.issue}}</td>
                                                                    <td>{{item?.igst1}}</td>
                                                                    <td>{{item?.cgst1}}</td>
                                                                    <td>{{item?.sgst1}}</td>
                                                                    <td>{{item?.cess1}}</td>
                                                                    <td>{{item?.interest1}}</td>
                                                                    <td>{{item?.penalty1}}</td>
                                                                    <td>{{item?.total1}}</td>
                                                                    <td>-----</td>
                                                                </tr>
                                                            
                                                        }
                                                        @else {
                                
                                                            <tr>
                                                                <td>{{idx + 1}}</td>
                                                                <td class="text-left">{{item?.issue}}</td>
                                                                <td>{{item?.igst3}}</td>
                                                                <td>{{item?.cgst3}}</td>
                                                                <td>{{item?.sgst3}}</td>
                                                                <td>{{item?.cess3}}</td>
                                                                <td>{{item?.interest3}}</td>
                                                                <td>{{item?.penalty3}}</td>
                                                                <td>{{item?.total3}}</td>
                                                                <td>
                                                                @if(item?.status==='Partly Favorable' || item?.status==='Favorable' ){
                                                                        <div class="badge badge-success rounded-pill" style="padding: inherit;">
                                                                            {{item?.status}}
                                                                        </div>
                                                                } @else{
                                                                        <div class="badge badge-warning rounded-pill" style="padding: inherit;">
                                                                            {{item?.status}}
                                                                        </div>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        
                                                        }
                                                    }
                                                </tbody>
                                            </table>
                                            </div>    
                                    </div>
                                }

                                @if(module == 'PREGST')
                                {


                                    <div class="row nopadding">
                                        <div class="table-responsive">
                                        <table class="table report-summary text-center" style="border: 1px solid rgb(221 227 227);
                                    border-collapse: collapse;">
                                            <thead>
                                                <tr class="bgGred">
                                                    <th class="sl-no-cls">Sr.No</th>
                                                    <th class="text-left">ISSUE</th>
                                                    @if(currentForum?.actType == 'EXCISE'){
                                                        <th>Excise Duty</th>
                                                        <th>EC</th>
                                                        <th>SHEC</th>
                                                    }
                                                    @if(currentForum?.actType == 'CUSTOMS DUTY'){
                                                        <th>BCD</th>
                                                    }

                                                    @if(currentForum?.actType == 'VAT'){
                                                        <th>VAT</th>
                                                    }
                                                    @if(currentForum?.actType == 'SERVICE TAX'){
                                                        <th>Service Tax</th>
                                                        <th>EC</th>
                                                        <th>SHEC</th>
                                                        <th>KKC</th>
                                                        <th>SBC</th>
                                                    }
                                                    @if(currentForum?.actType == 'CST'){
                                                        <th>CST</th>
                                                    }

                                                    @if(currentForum?.actType == 'ENTRY TAX')
                                                    {
                                                        <th>Tax</th>
                                                    }
                                                    <th>Interest</th>
                                                    <th>Penaltyh>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                @for (item of issuesDetails; track item ; let idx = $index) {
                                                    @if(currentForum.status !== 'CLOSED') {
                                                        <tr>
                                                            <td>{{idx + 1}}</td>
                                                            <td class="text-left">{{item?.issue}}</td>
                                                            @if(currentForum?.actType == 'EXCISE'){
                                                                <td>{{item?.exciseDuty1}}</td>
                                                                <td>{{item?.ec1}}</td>
                                                                <td>{{item?.shec1}}</td>
                                                            }
                                    
                                                            @if(currentForum?.actType == 'CUSTOMS DUTY'){
                                                                <td>{{item?.bcd1}}</td>
                                                            }
                                    
                                                            @if(currentForum?.actType == 'VAT'){
                                                                <td>{{item?.exciseDuty1}}</td>
                                                            }
                                    
                                                            @if(currentForum?.actType == 'SERVICE TAX'){
                                                                <td>{{item?.serviceTax1}}</td>
                                                                <td>{{item?.ec1}}</td>
                                                                <td>{{item?.shec1}}</td>
                                                                <td>{{item?.kkc1}}</td>
                                                                <td>{{item?.sbc1}}</td>
                                                            }
                                    
                                                            @if(currentForum?.actType == 'CST'){
                                                                <td>{{item?.cst1}}</td>
                                                            }
                                    
                                                            @if(currentForum?.actType == 'ENTRY TAX')
                                                            {
                                                                <td>{{item?.ent1}}</td>
                                                            }
                                
                                                            <td>{{item?.interest1}}</td>
                                                            <td>{{item?.penalty1}}</td>
                                                            <td>{{item?.total1}}</td>
                                                        </tr>
                                                    }
                                                    @else {
                                                        <tr>
                                                            <td>{{idx + 1}}</td>
                                                            <td class="text-left">{{item?.issue}}</td>
                                                            @if(currentForum?.actType == 'EXCISE'){
                                                                <td>{{item?.exciseDuty3}}</td>
                                                                <td>{{item?.ec3}}</td>
                                                                <td>{{item?.shec3}}</td>
                                                            }
                                    
                                                            @if(currentForum?.actType == 'CUSTOMS DUTY'){
                                                                <td>{{item?.bcd3}}</td>
                                                            }
                                    
                                                            @if(currentForum?.actType == 'VAT'){
                                                                <td>{{item?.exciseDuty3}}</td>
                                                            }
                                    
                                                            @if(currentForum?.actType == 'SERVICE TAX'){
                                                                <td>{{item?.serviceTax3}}</td>
                                                                <td>{{item?.ec3}}</td>
                                                                <td>{{item?.shec3}}</td>
                                                                <td>{{item?.kkc3}}</td>
                                                                <td>{{item?.sbc3}}</td>
                                                            }
                                    
                                                            @if(currentForum?.actType == 'CST'){
                                                                <td>{{item?.cst3}}</td>
                                                            }
                                    
                                                            @if(currentForum?.actType == 'ENTRY TAX')
                                                            {
                                                                <td>{{item?.ent3}}</td>
                                                            }
                                
                                                            <td>{{item?.interest3}}</td>
                                                            <td>{{item?.penalty3}}</td>
                                                            <td>{{item?.total3}}</td>
                                                        </tr>
                                                        
                                                    }
                                                }
                                            </tbody>
                                        </table>
                                        </div>    
                                </div>
                                }





                            </div>
                                }   

                                @if(currentForum?.issues?.length || closedIssueList?.length || forumData?.length){
                                    <div class="container">
                                        <div class="row justify-content-center">
                                            <div class="col-12 text-center mt-5">
                                                @if(currentForum?.issues?.length || closedIssueList?.length || forumData?.length){
                                                    <h2 style="font-weight: 700;">Previous Forum Details</h2>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                <!-- Closed Issue -->
                                @if(closedIssueList?.length){            
                                    <div class="head-table mb-0" style="margin-left: 1.5px;">
                                            <div class="row">
                                                <div class="text-md-size">Closed Issue</div>
                                            </div>
                                    </div>
                                    <div class="row nopadding">
                                        <div class="table-responsive">
                                        <table class="table report-summary text-center" style="border: 1px solid rgb(221 227 227);
                                    border-collapse: collapse;">
                                            <thead>
                                                <tr class="bgGred">
                                                    <th class="text-left" style="width: 620px;">Issue</th>
                                                    <th class="text-right">Value</th>
                                                    <th class="text-center">Status</th>
                                                    <th class="text-left">Forum</th>
                                                </tr>
                                            </thead>
                                            <tbody class="bg-color">

                                                @for (closeIssue of closedIssueList; track closeIssue ; let idx = $index) {
                                                    <tr>
                                                        <td class="text-left" style="width: 620px;">{{closeIssue.issue}}</td>
                                                        <td class="text-right">{{closeIssue.value}}</td>
                                                        <td class="text-center">
                                                            @if (closeIssue.status==='Admitted') {
                                                                <div class="badge badge-warning rounded-pill" style="padding: inherit;">
                                                                    {{closeIssue.status}}
                                                                </div>
                                                            } @else {
                                                                <div class="badge badge-success rounded-pill" style="padding: inherit;">
                                                                    {{closeIssue.status}}
                                                                </div>
                                                            }
                                                        </td>
                                                        <td class="text-left">{{closeIssue.forum}}</td>
                                                    </tr>
                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>  
                                }    

                                        <!-- Previous Forum -->
                                @if(forumData?.length){   
                                    <div>
                                        <div class="head-table mb-0" style="top: 21px;
                                position: relative;">
                                                <div class="row">
                                                    <div class="text-md-size">Previous Forum</div>
                                                </div>
                                        </div>
                                        @for (item of forumData; track item ; let idx = $index) {
                                        <div class="previous-forum">
                                            <div class="card custom-card custom-border-radius mt-4">
                                                <div class="card-header tbl-head bgGred">
                                                    <div class="row justify-content-start">

                                                        @if(item?.stageName)
                                                        {
                                                            <div class="col-md-auto">{{item?.stageName}}</div>                    
                                                        }
                                                        @else{
                                                            <div class="col-md-auto">{{item?.forum}}</div>                    
                                                        }

                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    @if (item?.forum==="Audit Findings Letter") {
                                                        <div class="col-md-4 case-details">
                                                                <label><b>Audit Details</b></label>   
                                                        </div> 
                                                    }@else {
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                @if(item?.stageName==="Pre Litigation"){
                                                                    <label><b>Notice/Letter:</b></label>
                                                                }
                                                                @else if(item?.stageName===adjudication){
                                                                    <label><b>SCN Details:</b></label>
                                                                }
                                                                @else {
                                                                    <label><b>Appealable Order:</b></label>
                                                                }                
                                                            </div>
                                                            <div class="col-md-4 case-details">
                                                                <label><b>Submission/Hearing:</b></label>                
                                                            </div>
                                                            <div class="col-md-4 case-details">
                                                                @if(item?.stageName==="Pre Litigation"){
                                                                    <label><b>Show Cause Notice:</b></label>
                                                                }
                                                                @else if(item?.stageName===adjudication){
                                                                    <label><b>Order Against SCN:</b></label>                
                                                                }
                                                                @else {
                                                                    <label><b>Order Against Appeal:</b></label>                
                                                                } 
                                                            </div>
                                                        </div>
                                                    }
                                                @if(item?.forum === prelitigationLabel) {
                                                    <div class="row">
                                                        <div class="col-md-4 case-details">
                                                            <label>Notice/Letter Type</label>                
                                                            <p>{{item?.noticeType}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label>Reply Submitted on</label>                
                                                            <p>{{item?.submissionDate}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details">
                                                            <label class="text-nowrap">SCN Number</label>   
                                                            <p class="text-wrap widht">{{item?.scnNo}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label class="text-wrap">Notice/Letter Number </label>                
                                                            <p class="text-wrap widht">{{item?.letterRefNo}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label>Hearing Date</label>                
                                                            <p>{{item?.hearingDate}}</p>
                                                        </div>
                                                        <div class="col-md-3 case-details normal-col-gap">
                                                            <label>Date of Recipt</label>                
                                                            <p>{{item?.dateOfReceiptOfScn}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4 case-details">
                                                            <label>Date of Notice/Letter</label>                
                                                            <p>{{item?.letterDate}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label>Hearing Status</label>                
                                                            <p>{{item?.hearingStatus}}</p>
                                                        </div>
                                                        <div class="col-md-3 case-details normal-col-gap">
                                                            <label>Demand As per SCN</label>                
                                                            <p>{{item?.demandConfirmed3}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label>Date of Recipt</label>                
                                                            <p>{{item?.dateOfRecieptOfLetter}}</p>
                                                        </div>
                                                    </div>
                                                }
                                                @else if(item?.forum === adjudicationAuthority 
                                                        || item?.forum === 'Adjudication  Authority'
                                                        || item?.forum === adjudication
                                                        || item?.forum === preGst_adjudicationAuthority
                                                        || item?.forum === 'PreGst Adjudiication Authority') {

                                                    <div class="row">
                                                        <div class="col-md-4 case-details">
                                                            <label class="text-nowrap">SCN Number</label>                
                                                            <p class="text-wrap widht">{{item?.scnNo}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label>Reply Submitted On</label>                
                                                            <p>{{item?.submissionDate}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details">
                                                            <label class="text-nowrap">Order Number</label>   
                                                            <p class="text-wrap widht">{{item?.orderNumber3}}</p>
                                                        </div>
                                                        
                                                    </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                <label>SCN Date</label>                
                                                                <p>{{item?.dateOfScn1}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Hearing Date</label>                
                                                                <p>{{item?.hearingDate}}</p>
                                                            </div>

                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Date of Order</label>                
                                                                <p>{{item?.orderDate3}}</p>
                                                            </div>
                                                        
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                <label>Date of Receipt</label>                
                                                                <p>{{item?.dateOfReceiptOfScn}}</p>
                                                            </div>

                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Hearing Status</label>                
                                                                <p>{{item?.hearingStatus}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Date of Receipt</label>                
                                                                <p>{{item?.dateOfRecieptOfOrder3}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                <label>Disputed Demand</label>      
                                                                <p>{{item?.totOfTotal1}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                            </div>
                                                                <div class="col-md-4 case-details normal-col-gap">
                                                                    <label>Demand Confirmed</label>                
                                                                    <p>{{item?.demandConfirmed3}}</p>
                                                                </div>  
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-8 case-details">
                                                            </div>
                                                                <div class="col-md-4 case-details normal-col-gap">
                                                                    <label>Demand Dropped</label>                
                                                                    <p>{{item?.droppedAmount3}}</p>
                                                                </div>
                                                        </div>
                                                            
                                                }

                                                @else if(item?.forum === commissioner  || item?.forum === appellateAuthority 
                                                ||item?.forum === commissioner_ca 
                                                ||item?.forum === commissioner_A
                                                ||item?.forum === preGst_appellateAuthority) {

                                                    <div class="row">
                                                
                                                        <div class="col-md-4 case-details">
                                                            <label class="text-nowrap">Order Number</label>                
                                                            <p class="text-wrap widht">{{item?.orderNumber}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details  normal-col-gap">
                                                            <label>Appeal Filed On</label>                
                                                            <p>{{item?.appealFilingDate}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details">
                                                            <label class="text-nowrap">Order Number</label>   
                                                            <p class="text-wrap widht">{{item?.orderNumber3}}</p>
                                                        </div>
                                                    
                                                    </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                <label>Date of Order</label>                
                                                                <p>{{item?.orderDate}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Hearing Date</label> 
                                                                <p>{{item?.hearingDate}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Date of Order</label>                
                                                                <p>{{item?.orderDate3}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                <label>Date of Receipt</label>                
                                                                <p>{{item?.dateOfRecieptOfOrder}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Hearing Status</label> 
                                                                <p>{{item?.hearingStatus}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Date of Receipt</label>                
                                                                <p>{{item?.dateOfRecieptOfOrder3}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                <label class="text-nowrap">Disputed Demand</label>                
                                                                <p>{{item?.totOfTotal1}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details">
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Demand Confirmed</label>                
                                                                <p>{{item?.demandConfirmed3}}</p>
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-8 case-details">
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Demand Dropped</label>                
                                                                <p>{{item?.droppedAmount3}}</p>
                                                            </div>
                                                        </div>
                                                            
                                                }
                                                

                                                @else if(item?.forum === appellateTribunal 
                                                ||item?.forum === preGst_appellateTribunal) {

                                                    <div class="row">
                                                        <div class="col-md-4 case-details">
                                                            <label class="text-nowrap">Order Number</label>                
                                                            <p class="text-wrap widht">{{item?.orderNumber}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label>Appeal Filed On</label>                
                                                            <p>{{item?.appealFilingDate}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label class="text-nowrap">Order Number</label>                
                                                            <p class="text-wrap widht">{{item?.orderNumber3}}</p>
                                                        </div>
                                                    </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                <label>Date of Order</label>                
                                                                <p>{{item?.orderDate}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Hearing Date</label>                
                                                                <p>{{item?.hearingDate}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Date of Order</label>                
                                                                <p>{{item?.orderDate3}}</p>
                                                            </div>
                                                        
                                                        </div>
                                                        <div class="row">

                                                            <div class="col-md-4 case-details">
                                                                <label>Date of Receipt</label>                
                                                                <p>{{item?.dateOfRecieptOfOrder}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Hearing Status</label>                
                                                                <p>{{item?.hearingStatus}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Date of Receipt</label>                
                                                                <p>{{item?.dateOfRecieptOfOrder3}}</p>
                                                            </div>
                                                    
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label class="text-nowrap">Disputed Demand</label>                
                                                                <p>{{item?.totOfTotal1}}</p>
                                                                <!-- <label>Demand as per Order</label>                
                                                                <p>{{item?.DemandAsPerOrder}}</p> -->
                                                            </div>
                                                            <div class="col-md-4 case-details">
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Demand Confirmed</label>                
                                                                <p>{{item?.demandConfirmed3}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                <!-- <label class="text-nowrap">Disputed Demand</label>                
                                                                <p>{{item?.totOfTotal1}}</p> -->
                                                            </div>
                                                            <div class="col-md-4 case-details">
                                                            
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Demand Dropped</label>                
                                                                <p>{{item?.droppedAmount3}}</p>
                                                            </div>
                                                        </div>
                                                            
                                                }

                                                @else if(item?.forum === highCourt ||item?.forum === preGst_highCourt) {

                                                    <div class="row">
                                                        <div class="col-md-4 case-details">
                                                            <label class="text-nowrap">Order Number</label>                
                                                            <p class="text-wrap widht">{{item?.orderNumber}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label>Appeal Filed On</label>                
                                                            <p>{{item?.appealFilingDate}}</p>
                                                        </div>
                                                        <div class="col-md-4 case-details normal-col-gap">
                                                            <label class="text-nowrap">Order Number</label>                
                                                            <p class="text-wrap widht">{{item?.orderNumber3}}</p>
                                                        </div>
                                                    </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                <label>Date of Order</label>                
                                                                <p>{{item?.orderDate}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Hearing Date</label>                
                                                                <p>{{item?.hearingDate}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Date of Order</label>                
                                                                <p>{{item?.orderDate3}}</p>
                                                            </div>
                                                        
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Date of Receipt</label>                
                                                                <p>{{item?.dateOfRecieptOfOrder}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details normal-col-gap">
                                                                <label>Hearing Status</label>                
                                                                <p>{{item?.hearingStatus}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details">
                                                                <label class="text-nowrap">Date of Receipt</label>                
                                                                <p>{{item?.dateOfRecieptOfOrder3}}</p>
                                                            </div>
                                                        
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-4 case-details">
                                                                <label class="text-nowrap">Disputed Demand</label>                
                                                                <p>{{item?.totOfTotal1}}</p>
                                                            </div>
                                                            <div class="col-md-4 case-details">
                                                                
                                                            </div>
                                                            <div class="col-md-4 case-details">
                                                                <label class="text-nowrap">Demand Confirmed</label>                
                                                                <p>{{item?.demandConfirmed3}}</p>
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-8 case-details">
                                                            </div>
                                                            <div class="col-md-4 case-details">
                                                                <label class="text-nowrap">Demand Dropped</label>                
                                                                <p>{{item?.droppedAmount3}}</p>
                                                            </div>
                                                        </div>
                                                        
                                                            
                                                }

                                                @else if(item?.forum === 'Audit Findings Letter') {
                                                    <div class="row">
                                                        <div class="col-md-4 case-details">
                                                            <label>Audit Starts Date</label>                
                                                            <p>{{item?.auditStartDate}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4 case-details">
                                                            <label>SCN Reccived on</label> 
                                                            @if(currentForum?.currentForum == adjudicationAuthority){
                                                                {{currentForum?.dateOfReceiptOfScn}}
                                                            } @else {
                                                                <p>{{item?.dateOfReceiptOfScn}}</p>
                                                            }           
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-4 case-details">
                                                            <label class="text-nowrap">SCN Number</label>                
                                                            @if(currentForum?.currentForum == adjudicationAuthority){
                                                               <p class="text-wrap widht">{{currentForum?.scnNo}}</p>
                                                            } @else {
                                                                <p class="text-wrap widht">{{item?.scnNo}}</p>
                                                            }
                                                        </div>
                                                    </div>
                                                }

                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div> 
                                }
                        

                                <a (click)="generatePdf()"  class="mt-3 print-bottom-btn">
                                    <button title="Print" type="button" class="btn btn-outline-primary btn-sm" style="background: none;
                                    border-radius: 5px;">
                                        <i class="fa fa-print" aria-hidden="true" style="    color: black;
                                    font-size: medium;"></i></button>
                                </a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
