<div class="panel-wrapper">
    <div class="panel-head">
        <div class="page-title">
            <h1><a [routerLink]="'/dtNmAdj'">Adjournment</a> / <span>Detail View</span></h1>
            <div class="action-bar">
                <a class="iris-gst-pull-right close" [routerLink]="'/dtNmAdj'" id="download" title="close">x</a>

            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-header">
                                <i class="fa fa-info-circle"></i> Basic Information
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Adjournment Request Id</label>
                                        <p>{{adjData?.data.adjVcReqstId}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Adjournment Request Date</label>
                                        <p>{{adjData?.data.parsedRequestDate}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Adjournment sought up to</label>
                                        <p>{{adjData?.data.parsedResDueDate}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Status</label>
                                        <p><span *ngIf="adjData?.data.vcStatus == 'O'">Open</span><span
                                                *ngIf="adjData?.data.vcStatus == 'C'">Close</span></p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Reason For Seeking Adjournment</label>
                                        <p>{{adjData?.data.adjReason}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Notice ID</label>
                                        <p><a class="anchor"
                                                (click)="toNoticeDetail(adjData?.data.noticeId, adjData?.data.headerSeqNo)">{{adjData?.data.noticeId}}</a>
                                        </p>
                                    </div>
                                    <div class="col-md-12">
                                        <label>Assesment Remark</label>
                                        <p>{{adjData?.data.assesseeRemarks}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>




    </div>
</div>