import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-audit-case-master',
  templateUrl: './audit-case-master.component.html',
  styleUrls: ['./audit-case-master.component.css']
})
export class AuditCaseMasterComponent implements OnInit {
  selectedTab: any;
  tabDisableFlag= {'tab1':false, 'tab2':false};
  type: any;
  tab: any;
  someElement: any;
  selectedCaseId:any;
  constructor(
    private activeSnapShot: ActivatedRoute,
    private router: Router,
  ) {
    this.type = this.activeSnapShot.snapshot.params.type;
    this.tab = this.activeSnapShot.snapshot.params.tab;
   }

  ngOnInit() {
    this.selectedTab = 'tab1';
    if(this.type === 'ail' || this.tab === 'tab1'){
      this.selectedTab = 'tab1';
    }else if(this.type === 'afl' || this.tab === 'tab2'){
      this.selectedTab = 'tab2';
    }
    this.tabChanged(this.tab);
  }
  tabChanged(tab:any) {
    this.selectedTab = tab;
    this.disabledTab();
    this.enabledTab(this.tab);
  }
  disabledTab(){
    switch (this.selectedTab) {
      case 'tab1':
       this.tabDisableFlag = {'tab1':true, 'tab2':false};
      break;
      case 'tab2':
        this.tabDisableFlag = {'tab1':true, 'tab2':true};
      break; 
    }

  }
  gotoSummary(){
    this.router.navigate(['/auditSummary'])
  }
  enabledTab(tab:any){
    if(tab === 'tab1'){
      this.tabDisableFlag = {'tab1':true, 'tab2':false};
      this.someElement= document.getElementById("intimation-tab");
      this.someElement.classList.remove("disabled");
    }
    if(tab === 'tab2'){
      this.tabDisableFlag = {'tab1':true, 'tab2':true};
      this.someElement= document.getElementById("findings-tab");
      this.someElement.classList.remove("disabled");
    }
  }

  saveCaseId(event : any){
    this.selectedCaseId = event;
  }
  

}
