import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pre-case-master',
  templateUrl: './pre-case-master.component.html',
  styleUrls: ['./pre-case-master.component.css']
})
export class PreCaseMasterComponent implements OnInit {
  selectedTab: any;
  type: any;
  tab: any;
  isActionUpdate:any;
  isActionView:any;
  activeStages: string[] | null = null;
  getAccessTabData: any;
  someElement: any;
  selectedCaseId: any;
  tabDisableFlag= {'tab1':false, 'tab2':false, 'tab3':false, 'tab4':false, 'tab5':false};
  constructor(
    private activeSnapShot: ActivatedRoute,
    private router: Router,
  ) { 
    this.type = this.activeSnapShot.snapshot.params.type;
    this.tab = this.activeSnapShot.snapshot.params.tab;
    this.isActionUpdate = this.activeSnapShot.snapshot.params.action === 'update' ? true :false; 
    this.isActionView = this.activeSnapShot.snapshot.params.action === 'view' ? true :false; 
  }

  ngOnInit() {
    this.tabChanged(this.tab);
    
  }
  tabChanged(tab:any, check?:any,isMoveToHC:any=false) {
    this.selectedTab = tab;
    this.disabledTab();
    this.enabledTab(this.tab);
    if(isMoveToHC){
      this.tabDisableFlag.tab1 = false;
      this.tabDisableFlag.tab2 = false;
      this.tabDisableFlag.tab3 = false;
    } 
  }
  disabledTab(){ 
    switch (this.selectedTab) {
      case 'tab1':
       this.tabDisableFlag = {'tab1':true, 'tab2':false, 'tab3':false, 'tab4':false, 'tab5':false};
      break;
      case 'tab2':
        this.tabDisableFlag = {'tab1':true, 'tab2':true, 'tab3':false, 'tab4':false, 'tab5':false};
      break;
      case 'tab3':
        this.tabDisableFlag = {'tab1':true, 'tab2':true, 'tab3':true, 'tab4':false, 'tab5':false};
      break;
      case 'tab4':
        this.tabDisableFlag = {'tab1':true, 'tab2':true, 'tab3':true, 'tab4':true, 'tab5':false};
      break;
      case 'tab5':
        this.tabDisableFlag = {'tab1':true, 'tab2':true, 'tab3':true, 'tab4':true, 'tab5':true};
      break;         
    }
    
  }
  enabledTab(tab:any){
    if(tab === 'tab1'){
      this.tabDisableFlag = {'tab1':true, 'tab2':false, 'tab3':false, 'tab4':false, 'tab5':false};
      this.someElement= document.getElementById("adjudication-tab");
      this.someElement.classList.remove("disabled");
    }
    if(tab === 'tab2'){
      this.tabDisableFlag = {'tab1':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab2':true, 'tab3':false, 'tab4':false, 'tab5':false};
      this.someElement= document.getElementById("AppelAuth-tab");
      this.someElement.classList.remove("disabled");
    }
    if(tab === 'tab3'){
      this.tabDisableFlag = {'tab1':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab2':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab3':true, 'tab4':false, 'tab5':false};
      this.someElement= document.getElementById("AppelTrib-tab");
      this.someElement.classList.remove("disabled");
    }
    if(tab === 'tab4'){
      this.tabDisableFlag = {'tab1':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab2':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab3':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab4':true, 'tab5':false};
      this.someElement= document.getElementById("HC-tab");
      this.someElement.classList.remove("disabled");
    }
    if(tab === 'tab5'){
      this.tabDisableFlag = {'tab1':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab2':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab3':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab4':(this.isActionUpdate ? false :true || this.isActionView ? false :true), 'tab5':true};
      this.someElement= document.getElementById("SC-tab");
      this.someElement.classList.remove("disabled");
    } 

  }
  gotoSummary(){
    this.router.navigate(['/preGstSummary'])
  }
  saveCaseId(event : any){
    this.selectedCaseId = event;
  }

  enablePrevStageTab(stage:any){
    this.activeStages = stage;
    if (this.activeStages && this.activeStages.length) {
    for(let  i = 0; i < stage.length; i++){
    switch(stage[i]){
      case 'preaja' : this.tabDisableFlag.tab1 = true;
                   break;
      case 'preapa' : this.tabDisableFlag.tab2 = true;
                   break;
      case 'preapt' : this.tabDisableFlag.tab3 = true;
                   break;
      case 'prehc' : this.tabDisableFlag.tab4 = true;
                   break;
      case 'presc' : this.tabDisableFlag.tab5 = true;
                   break;
    }
  }
  }}
}
