import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/shared/services/token.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-session-clear',
  templateUrl: './session-clear.component.html',
  styleUrls: ['./session-clear.component.css']
})
export class SessionClearComponent implements OnInit {

  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.logout();
  }
  logout() {
    if (this.tokenService.destroy()) {
      this.authService.setLoginIndicator(0);
      //this.router.navigate(['dashboard']);
      window.open(this.tokenService.getUumUrl(), '_self');
    }
  }

}
