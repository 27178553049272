import { Component, EventEmitter, model, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { thru } from 'lodash-es';
import { STATE_LIST } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { PreGstServiceService } from '../services/pre-gst-service.service';
import { A1ChartComponent } from './charts/a1-chart/a1-chart.component';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { ChartOptions } from 'chart.js';
import { ActWiseTableComponent } from './charts/act-wise-table/act-wise-table.component';

interface Filters {
  p: 'state' ;
  o: 'in';
  v?: string | number | null; 
}
@Component({
  selector: 'app-pre-analytics',
  templateUrl: './pre-analytics.component.html',
  styleUrls: ['./pre-analytics.component.css']
})
export class PreAnalyticsComponent implements OnInit {
  // navContext;
  pan: any;
  b2PieChartData: any;
  state: any;
  companyId: any
  selectedCompanyId: any;


  public barChartOptions: ChartOptions<'bar'> = {
    // scaleShowVerticalLines: false,
    responsive: true,
    scales: {
      x: {
        stacked: true
      },
      y: { stacked: true }
    }
  };




  a1BarChartLabels: any = [];
  a1BarChartLegend = true;
  skipLocationChange = true;
  a1BarChartData: any = [];
  selectedState: any;
  data: any = null;
  stateList: any;
  stateNames = STATE_LIST.map((state) => ({id: state.id, stateName: state.stateName}));
  navContext: any;
  entityType: any;
  currentIssueLabel: any;
  excelData: any = [];
  filters: { [key: string]: Filters } = this.initFilters();
  selectedIRN: boolean = false;
  isinCR: boolean = false;
  isinLakh: boolean = true;
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'stateName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
  };


  constructor(private commonServ: CommonService,
    private pregstService: PreGstServiceService,
    private loaderService: LoaderService,
    private router: Router,
    private toaster: ToasterService,
    private eEmit: EventEmitterService,
    private getterSetter: GetterSetterService,
    private excelService: ExcelService,) {
  }

  ngOnInit() {
    this.filters = <any>JSON.parse(sessionStorage.getItem('preAnalyticFilter') || '{}');
    if ((this.filters && Object.keys(this.filters).length == 0) || !this.filters) {
      this.filters = this.initFilters();
    }
    this.selectedCompanyId = localStorage.getItem('selectedId');
    this.navContext = this.commonServ.getNavContext();
    this.pan = this.navContext.pan;
    this.entityType = this.navContext.entityType;
    this.stateList = STATE_LIST;
    this.preAnalytics();
  }

  initFilters(): { [key: string]: Filters } {
    return {
      state: { p: 'state', o: 'in', v: null },
    }
  }

  getFilters() {  
    let arrReturn=this.initFilters(); 
    sessionStorage.setItem('preAnalyticFilter', JSON.stringify(this.filters));
      if(Array.isArray(this.filters?.state?.v)){
        if (this.filters?.state?.v.length > 0){
        let arr:any=[];
        this.filters?.state?.v.forEach((element:any) => {
           arr.push(element.stateName);
         });
         if(arr.length>0){
          arrReturn.state.v= arr.join(',');
         }
      }else{
        this.clearFilter('state')
      }
      }
      return Object.values(arrReturn).filter((el) => !!el?.v);
    }

  changeStatsValue(selected: any) {
    if (selected == false) {
      this.isinCR = false;
      this.isinLakh = true;
    } else if (selected == true) {
      this.isinCR = true;
      this.isinLakh = false;
    }
  }

  preAnalytics() {
    this.loaderService.show();
    // this.navContext = this.commonServ.getNavContext();
    // if(Array.isArray(this.filters?.state?.v)){
    //   if (this.filters?.state?.v.length > 0){
    //   let arr:any=[];
    //   this.filters?.state?.v.forEach((element:any) => {
    //      arr.push(element);
    //    });
    //    if(arr.length>0){
    //      this.filters.state.v= arr.join(',');
    //    }
    // }else{
    //   this.clearPreAnalytic();
    // }
    // }
    let model = {
      criterias: this.getFilters(),
      pan: this.navContext.entityType === 'LEGAL' ? this.pan : null,
      page: 0,
      size: 1000,
      sortfield: "createdOn",
      sortdir: "ASC",
      companyId: this.selectedCompanyId
    }
    this.pregstService.getPreAnalytics(model).subscribe((res: any) => {
      delete Object.assign(res.response.a1, { 'Other Tax': res.response.a1['Entry Tax'] })['Entry Tax'];
      delete Object.assign(res.response.a2, { 'Other Tax': res.response.a2['Entry Tax'] })['Entry Tax'];
      
      this.data = res.response;
      this.loaderService.hide();
    }, (err) => {
      this.loaderService.hide();
    })
  }

  exportAsXLSX(){
    let model = {
      criterias: this.getFilters(),
      companyId: this.selectedCompanyId
    }
    console.log('model:', model)
    this.pregstService.exportPreGstAnalytic(model).subscribe((res: Blob) => {
      const blobUrl = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = 'PreGstAnalyticsReport.xlsx'; 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);     
    })
  }

  onDTtemSelect(){
    this.preAnalytics();
  }
  setState() {
    this.preAnalytics();
    this.relopageRefresh();
  }

  clearFilter(inputName: string) {
    switch (inputName) {
      case 'state':
        this.filters.state.v = null;
        break;
      default:
        break;
    }
  this.preAnalytics();
  this.relopageRefresh();
  }
  clearPreAnalytic() {
    let model = {
      criterias: [],
      pan: this.navContext.entityType === 'LEGAL' ? this.pan : null,
      page: 0,
      size: 1000,
      sortfield: "createdOn",
      sortdir: "ASC",
      companyId: this.selectedCompanyId
    }
    this.pregstService.clearPreAnalyticData(model).subscribe((response:any) => {
      delete Object.assign(response.response.a1, { 'Other Tax': response.response.a1['Entry Tax'] })['Entry Tax'];
      delete Object.assign(response.response.a2, { 'Other Tax': response.response.a2['Entry Tax'] })['Entry Tax'];
      this.data = response.response;
      this.loaderService.hide();
    }, (err) => {
      this.loaderService.hide();
    })
  }
  getIssueLabel(key: string, e: any) {
    this.currentIssueLabel = key;
    this.router.navigate(['/preGstSummary']);
    localStorage.setItem('preAnalyticIssueLbl', this.currentIssueLabel);
  }


  relopageRefresh() {
    if (this.router.url.includes('preGstAnalytics')) {
      this.commonServ.reloadCurrentRoute();
    }
  }

}
export const DATA = {
  "a1": {
    "Service Tax": {
      "total": 84704.218,
      "details": {
        "Non-payment/ Short payment of Service Tax on advances ": {
          "tax": 2651.489,
          "interest": 16.44,
          "penalty": 1892.279,
          "total": 4560.208,
          "deposit": 0
        },
        "CENVAT Credit Dispute": {
          "tax": 59407.611,
          "interest": 0,
          "penalty": 3927.193,
          "total": 63334.804,
          "deposit": 0
        },
        "Service Tax to be paid under RCM": {
          "tax": 194.448,
          "interest": 0,
          "penalty": 0,
          "total": 194.448,
          "deposit": 0
        },
        "Composition/abatement scheme": {
          "tax": 2687.571,
          "interest": 0,
          "penalty": 2687.571,
          "total": 5375.142,
          "deposit": 0
        },
        "Disallowance of exemption claimed": {
          "tax": 5619.708,
          "interest": 0,
          "penalty": 5619.908,
          "total": 11239.616,
          "deposit": 0
        }
      }
    },
    "Customs": {
      "total": 0,
      "details": {}
    },
    "CST": {
      "total": 6817.549,
      "details": {
        "ITC disallowed ": {
          "tax": 2126.533,
          "interest": 2622.282,
          "penalty": 0,
          "total": 4748.815,
          "deposit": 0
        },
        "Sale in transit": {
          "tax": 300.917,
          "interest": 559,
          "penalty": 0,
          "total": 859.917,
          "deposit": 0
        },
        "Any other miscellaneous disallowance": {
          "tax": 0.057,
          "interest": 0,
          "penalty": 0,
          "total": 0.057,
          "deposit": 0
        },
        "Submission of all declaration forms": {
          "tax": 1208.76,
          "interest": 0,
          "penalty": 0,
          "total": 1208.76,
          "deposit": 0
        }
      }
    },
    "Excise Duty": {
      "total": 32429.08,
      "details": {
        "Applicability of Excise duty": {
          "tax": 16161.273,
          "interest": 0,
          "penalty": 16161.273,
          "total": 32322.546,
          "deposit": 0
        },
        "Applicability of Central Excise Exemption": {
          "tax": 71.534,
          "interest": 0,
          "penalty": 35,
          "total": 106.534,
          "deposit": 0
        }
      }
    },
    "Vat": {
      "total": 30654.217,
      "details": {
        "ITC disallowed ": {
          "tax": 5882.856,
          "interest": 0,
          "penalty": 0,
          "total": 5882.856,
          "deposit": 0
        },
        "Subcontractor Turnover": {
          "tax": 343.96,
          "interest": 0,
          "penalty": 0,
          "total": 343.96,
          "deposit": 0
        },
        "WCT Tds": {
          "tax": 445.683,
          "interest": 0,
          "penalty": 0,
          "total": 445.683,
          "deposit": 0
        },
        "Any other miscellaneous disallowance": {
          "tax": 7,
          "interest": 0,
          "penalty": 0,
          "total": 7,
          "deposit": 0
        },
        "Labour and like charges disallowed": {
          "tax": 18944.484,
          "interest": 4964.688,
          "penalty": 65.546,
          "total": 23974.718,
          "deposit": 0
        }
      }
    }
  },
  "a2": {
    "Service Tax": {
      "total": 84704.218,
      "details": {
        "Corporate": {
          "tax": 194.448,
          "interest": 0,
          "penalty": 0,
          "total": 194.448,
          "deposit": 0
        },
        "Infrastructure": {
          "tax": 66439.186,
          "interest": 16.44,
          "penalty": 10199.758,
          "total": 76655.384,
          "deposit": 0
        },
        "Defence": {
          "tax": 3927.193,
          "interest": 0,
          "penalty": 3927.193,
          "total": 7854.386,
          "deposit": 0
        }
      }
    },
    "Customs": {
      "total": 0,
      "details": {}
    },
    "CST": {
      "total": 0,
      "details": {}
    },
    "Excise Duty": {
      "total": 32429.08,
      "details": {
        "CMB": {
          "tax": 16161.273,
          "interest": 0,
          "penalty": 16161.273,
          "total": 32322.546,
          "deposit": 0
        },
        "Defence": {
          "tax": 71.534,
          "interest": 0,
          "penalty": 35,
          "total": 106.534,
          "deposit": 0
        }
      }
    },
    "Vat": {
      "total": 0,
      "details": {}
    }
  },
  "a4": {
    "Service Tax": {
      "total": 84704.218,
      "details": {
        "Karnataka": {
          "tax": 56239.728,
          "interest": 0,
          "penalty": 0,
          "total": 56239.728,
          "deposit": 0
        },
        "Telangana": {
          "tax": 2659.226,
          "interest": 0,
          "penalty": 2659.326,
          "total": 5318.552,
          "deposit": 0
        },
        "Tamil Nadu": {
          "tax": 6614.764,
          "interest": 0,
          "penalty": 6614.764,
          "total": 13229.528,
          "deposit": 0
        },
        "West Bengal": {
          "tax": 1892.179,
          "interest": 16.44,
          "penalty": 1892.279,
          "total": 3800.898,
          "deposit": 0
        },
        "Maharashtra": {
          "tax": 3154.93,
          "interest": 0,
          "penalty": 2960.582,
          "total": 6115.512,
          "deposit": 0
        }
      }
    },
    "Customs": {
      "total": 0,
      "details": {}
    },
    "CST": {
      "total": 6817.549,
      "details": {
        "Gujarat": {
          "tax": 43.084,
          "interest": 0,
          "penalty": 0,
          "total": 43.084,
          "deposit": 0
        },
        "Madhya Pradesh": {
          "tax": 618,
          "interest": 0,
          "penalty": 0,
          "total": 618,
          "deposit": 0
        },
        "Maharashtra": {
          "tax": 2975.183,
          "interest": 3181.282,
          "penalty": 0,
          "total": 6156.465,
          "deposit": 0
        }
      }
    },
    "Excise Duty": {
      "total": 32429.08,
      "details": {
        "Karnataka": {
          "tax": 71.534,
          "interest": 0,
          "penalty": 35,
          "total": 106.534,
          "deposit": 0
        },
        "Maharashtra": {
          "tax": 16161.273,
          "interest": 0,
          "penalty": 16161.273,
          "total": 32322.546,
          "deposit": 0
        }
      }
    },
    "Vat": {
      "total": 30654.217,
      "details": {
        "Gujarat": {
          "tax": 336.678,
          "interest": 0,
          "penalty": 0,
          "total": 336.678,
          "deposit": 0
        },
        "Madhya Pradesh": {
          "tax": 1232.486,
          "interest": 0,
          "penalty": 0,
          "total": 1232.486,
          "deposit": 0
        },
        "Maharashtra": {
          "tax": 24054.819,
          "interest": 4964.688,
          "penalty": 65.546,
          "total": 29085.053,
          "deposit": 0
        }
      }
    }
  },
  "a5": {
    "SERVICES": {
      "taxdemand": 4774.198,
      "totaldemand": 9370.487,
      "totaldeposit": 0,
      "regdetails": {
        "AAACL0140PST042": {
          "taxdemand": 1892.179,
          "totaldemand": 3800.897,
          "totaldeposit": 0,
          "details": {
            "apt": {
              "taxdemand": 1892.179,
              "totaldemand": 3800.897,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140PST047": {
          "taxdemand": 194.448,
          "totaldemand": 194.448,
          "totaldeposit": 0,
          "details": {
            "adj": {
              "taxdemand": 194.448,
              "totaldemand": 194.448,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140P022": {
          "taxdemand": 2687.571,
          "totaldemand": 5375.142,
          "totaldeposit": 0,
          "details": {
            "apt": {
              "taxdemand": 2687.571,
              "totaldemand": 5375.142,
              "totaldeposit": 0
            }
          }
        }
      },
      "phdetails": {
        "apt": {
          "tax": 4579.75,
          "interest": 0,
          "penalty": 0,
          "total": 9176.039,
          "deposit": 0
        },
        "adj": {
          "tax": 194.448,
          "interest": 0,
          "penalty": 0,
          "total": 194.448,
          "deposit": 0
        }
      }
    },
    "_blank": {
      "taxdemand": 54968.422,
      "totaldemand": 88888.308,
      "totaldeposit": 0,
      "regdetails": {
        "27850300132V": {
          "taxdemand": 24054.819,
          "totaldemand": 29085.052,
          "totaldeposit": 0,
          "details": {
            "apa": {
              "taxdemand": 7740.867,
              "totaldemand": 12428.867,
              "totaldeposit": 0
            },
            "adj": {
              "taxdemand": 16313.952,
              "totaldemand": 16656.185,
              "totaldeposit": 0
            }
          }
        },
        "24073300256V": {
          "taxdemand": 336.678,
          "totaldemand": 336.678,
          "totaldeposit": 0,
          "details": {
            "apa": {
              "taxdemand": 336.678,
              "totaldemand": 336.678,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140PSHIPB": {
          "taxdemand": 3927.193,
          "totaldemand": 7854.385,
          "totaldeposit": 0,
          "details": {
            "hc": {
              "taxdemand": 3927.193,
              "totaldemand": 7854.385,
              "totaldeposit": 0
            }
          }
        },
        "27850300132C": {
          "taxdemand": 2975.181,
          "totaldemand": 6156.462,
          "totaldeposit": 0,
          "details": {
            "apa": {
              "taxdemand": 340.603,
              "totaldemand": 899.603,
              "totaldeposit": 0
            },
            "adj": {
              "taxdemand": 2634.578,
              "totaldemand": 5256.859,
              "totaldeposit": 0
            }
          }
        },
        "23654000082C": {
          "taxdemand": 618,
          "totaldemand": 618,
          "totaldeposit": 0,
          "details": {
            "apa": {
              "taxdemand": 618,
              "totaldemand": 618,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140PEM095": {
          "taxdemand": 16161.273,
          "totaldemand": 32322.546,
          "totaldeposit": 0,
          "details": {
            "hc": {
              "taxdemand": 16161.273,
              "totaldemand": 32322.546,
              "totaldeposit": 0
            }
          }
        },
        "24073300256C": {
          "taxdemand": 43.084,
          "totaldemand": 43.084,
          "totaldeposit": 0,
          "details": {
            "apa": {
              "taxdemand": 29.497,
              "totaldemand": 29.497,
              "totaldeposit": 0
            },
            "adj": {
              "taxdemand": 13.587,
              "totaldemand": 13.587,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140PST047": {
          "taxdemand": 2960.482,
          "totaldemand": 5921.064,
          "totaldeposit": 0,
          "details": {
            "apt": {
              "taxdemand": 2960.482,
              "totaldemand": 5921.064,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140PST015": {
          "taxdemand": 2659.226,
          "totaldemand": 5318.551,
          "totaldeposit": 0,
          "details": {
            "apt": {
              "taxdemand": 2659.226,
              "totaldemand": 5318.551,
              "totaldeposit": 0
            },
            "hc": {
              "taxdemand": 0,
              "totaldemand": 0,
              "totaldeposit": 0
            }
          }
        },
        "23654000082V": {
          "taxdemand": 1232.486,
          "totaldemand": 1232.486,
          "totaldeposit": 0,
          "details": {
            "apa": {
              "taxdemand": 1232.486,
              "totaldemand": 1232.486,
              "totaldeposit": 0
            }
          }
        }
      },
      "phdetails": {
        "apa": {
          "tax": 10298.131,
          "interest": 0,
          "penalty": 0,
          "total": 15545.131,
          "deposit": 0
        },
        "apt": {
          "tax": 5619.708,
          "interest": 0,
          "penalty": 0,
          "total": 11239.615,
          "deposit": 0
        },
        "adj": {
          "tax": 18962.117,
          "interest": 0,
          "penalty": 0,
          "total": 21926.631,
          "deposit": 0
        },
        "hc": {
          "tax": 20088.466,
          "interest": 0,
          "penalty": 0,
          "total": 40176.931,
          "deposit": 0
        }
      }
    },
    "Corporate": {
      "taxdemand": 55551.952,
      "totaldemand": 55586.952,
      "totaldeposit": 0,
      "regdetails": {
        "AAACL0140PXMDEF": {
          "taxdemand": 71.534,
          "totaldemand": 106.534,
          "totaldeposit": 0,
          "details": {
            "apt": {
              "taxdemand": 71.534,
              "totaldemand": 106.534,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140PST014": {
          "taxdemand": 55480.418,
          "totaldemand": 55480.418,
          "totaldeposit": 0,
          "details": {
            "adj": {
              "taxdemand": 55480.418,
              "totaldemand": 55480.418,
              "totaldeposit": 0
            }
          }
        }
      },
      "phdetails": {
        "apt": {
          "tax": 71.534,
          "interest": 0,
          "penalty": 0,
          "total": 106.534,
          "deposit": 0
        },
        "adj": {
          "tax": 55480.418,
          "interest": 0,
          "penalty": 0,
          "total": 55480.418,
          "deposit": 0
        }
      }
    },
    "Infrastructure": {
      "taxdemand": 759.31,
      "totaldemand": 759.31,
      "totaldeposit": 0,
      "regdetails": {
        "AAACL0140PST014": {
          "taxdemand": 759.31,
          "totaldemand": 759.31,
          "totaldeposit": 0,
          "details": {
            "adj": {
              "taxdemand": 759.31,
              "totaldemand": 759.31,
              "totaldeposit": 0
            }
          }
        }
      },
      "phdetails": {
        "adj": {
          "tax": 759.31,
          "interest": 0,
          "penalty": 0,
          "total": 759.31,
          "deposit": 0
        }
      }
    }
  },
  "a6": {
    "SERVICES": {
      "taxdemand": 4774.198,
      "totaldemand": 9370.487,
      "totaldeposit": 0,
      "regdetails": {
        "AAACL0140PST042": {
          "taxdemand": 1892.179,
          "totaldemand": 3800.897,
          "totaldeposit": 0,
          "details": {
            "Service Tax": {
              "taxdemand": 1892.179,
              "totaldemand": 3800.897,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140PST047": {
          "taxdemand": 194.448,
          "totaldemand": 194.448,
          "totaldeposit": 0,
          "details": {
            "Service Tax": {
              "taxdemand": 194.448,
              "totaldemand": 194.448,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140P022": {
          "taxdemand": 2687.571,
          "totaldemand": 5375.142,
          "totaldeposit": 0,
          "details": {
            "Service Tax": {
              "taxdemand": 2687.571,
              "totaldemand": 5375.142,
              "totaldeposit": 0
            }
          }
        }
      },
      "phdetails": {
        "Service Tax": {
          "tax": 4774.198,
          "interest": 0,
          "penalty": 0,
          "total": 9370.487,
          "deposit": 0
        }
      }
    },
    "_blank": {
      "taxdemand": 54968.424,
      "totaldemand": 88888.31,
      "totaldeposit": 8503.969,
      "regdetails": {
        "27850300132V": {
          "taxdemand": 24054.819,
          "totaldemand": 29085.052,
          "totaldeposit": 0,
          "details": {
            "Vat": {
              "taxdemand": 24054.819,
              "totaldemand": 29085.052,
              "totaldeposit": 0
            }
          }
        },
        "24073300256V": {
          "taxdemand": 336.678,
          "totaldemand": 336.678,
          "totaldeposit": 0,
          "details": {
            "Vat": {
              "taxdemand": 336.678,
              "totaldemand": 336.678,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140PSHIPB": {
          "taxdemand": 3927.193,
          "totaldemand": 7854.385,
          "totaldeposit": 0,
          "details": {
            "Service Tax": {
              "taxdemand": 3927.193,
              "totaldemand": 7854.385,
              "totaldeposit": 0
            }
          }
        },
        "27850300132C": {
          "taxdemand": 2975.183,
          "totaldemand": 6156.464,
          "totaldeposit": 1836,
          "details": {
            "CST": {
              "taxdemand": 2975.183,
              "totaldemand": 6156.464,
              "totaldeposit": 1836
            }
          }
        },
        "23654000082C": {
          "taxdemand": 618,
          "totaldemand": 618,
          "totaldeposit": 618,
          "details": {
            "CST": {
              "taxdemand": 618,
              "totaldemand": 618,
              "totaldeposit": 618
            }
          }
        },
        "AAACL0140PEM095": {
          "taxdemand": 16161.273,
          "totaldemand": 32322.546,
          "totaldeposit": 2700,
          "details": {
            "Excise Duty": {
              "taxdemand": 16161.273,
              "totaldemand": 32322.546,
              "totaldeposit": 2700
            }
          }
        },
        "24073300256C": {
          "taxdemand": 43.084,
          "totaldemand": 43.084,
          "totaldeposit": 0,
          "details": {
            "CST": {
              "taxdemand": 43.084,
              "totaldemand": 43.084,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140PST047": {
          "taxdemand": 2960.482,
          "totaldemand": 5921.064,
          "totaldeposit": 222.037,
          "details": {
            "Service Tax": {
              "taxdemand": 2960.482,
              "totaldemand": 5921.064,
              "totaldeposit": 222.037
            }
          }
        },
        "AAACL0140PST015": {
          "taxdemand": 2659.226,
          "totaldemand": 5318.551,
          "totaldeposit": 0,
          "details": {
            "Service Tax": {
              "taxdemand": 2659.226,
              "totaldemand": 5318.551,
              "totaldeposit": 0
            }
          }
        },
        "23654000082V": {
          "taxdemand": 1232.486,
          "totaldemand": 1232.486,
          "totaldeposit": 3127.932,
          "details": {
            "Vat": {
              "taxdemand": 1232.486,
              "totaldemand": 1232.486,
              "totaldeposit": 3127.932
            }
          }
        }
      },
      "phdetails": {
        "Service Tax": {
          "tax": 9546.901,
          "interest": 0,
          "penalty": 0,
          "total": 19094,
          "deposit": 222.037
        },
        "CST": {
          "tax": 3636.267,
          "interest": 0,
          "penalty": 0,
          "total": 6817.548,
          "deposit": 2454
        },
        "Excise Duty": {
          "tax": 16161.273,
          "interest": 0,
          "penalty": 0,
          "total": 32322.546,
          "deposit": 2700
        },
        "Vat": {
          "tax": 25623.983,
          "interest": 0,
          "penalty": 0,
          "total": 30654.216,
          "deposit": 3127.932
        }
      }
    },
    "Corporate": {
      "taxdemand": 55551.952,
      "totaldemand": 55586.952,
      "totaldeposit": 0,
      "regdetails": {
        "AAACL0140PXMDEF": {
          "taxdemand": 71.534,
          "totaldemand": 106.534,
          "totaldeposit": 0,
          "details": {
            "Excise Duty": {
              "taxdemand": 71.534,
              "totaldemand": 106.534,
              "totaldeposit": 0
            }
          }
        },
        "AAACL0140PST014": {
          "taxdemand": 55480.418,
          "totaldemand": 55480.418,
          "totaldeposit": 0,
          "details": {
            "Service Tax": {
              "taxdemand": 55480.418,
              "totaldemand": 55480.418,
              "totaldeposit": 0
            }
          }
        }
      },
      "phdetails": {
        "Service Tax": {
          "tax": 55480.418,
          "interest": 0,
          "penalty": 0,
          "total": 55480.418,
          "deposit": 0
        },
        "Excise Duty": {
          "tax": 71.534,
          "interest": 0,
          "penalty": 0,
          "total": 106.534,
          "deposit": 0
        }
      }
    },
    "Infrastructure": {
      "taxdemand": 759.31,
      "totaldemand": 759.31,
      "totaldeposit": 0,
      "regdetails": {
        "AAACL0140PST014": {
          "taxdemand": 759.31,
          "totaldemand": 759.31,
          "totaldeposit": 0,
          "details": {
            "Service Tax": {
              "taxdemand": 759.31,
              "totaldemand": 759.31,
              "totaldeposit": 0
            }
          }
        }
      },
      "phdetails": {
        "Service Tax": {
          "tax": 759.31,
          "interest": 0,
          "penalty": 0,
          "total": 759.31,
          "deposit": 0
        }
      }
    }
  },
  "d2": {
    "currprovision": 0,
    "currliability": 0,
    "prevprovision": 0,
    "prevliability": 0,
    "incprovision": 0,
    "incliability": 0,
    "details": {
      "SERVICES": {
        "currProv": 0,
        "currLiab": 0,
        "prevProv": 0,
        "prevLiab": 0,
        "diffProv": 0,
        "diffLiab": 0
      },
      "_blank": {
        "currProv": 0,
        "currLiab": 0,
        "prevProv": 0,
        "prevLiab": 0,
        "diffProv": 0,
        "diffLiab": 0
      },
      "Corporate": {
        "currProv": 0,
        "currLiab": 0,
        "prevProv": 0,
        "prevLiab": 0,
        "diffProv": 0,
        "diffLiab": 0
      },
      "Infrastructure": {
        "currProv": 0,
        "currLiab": 0,
        "prevProv": 0,
        "prevLiab": 0,
        "diffProv": 0,
        "diffLiab": 0
      }
    }
  },
  "b1": {
    "total": 154605.057,
    "details": {
      "3 to 5 years": {
        "count": 0,
        "total": 0,
        "details": {}
      },
      "5 to 7 years": {
        "count": 6,
        "total": 22713.57,
        "details": {
          "27850300132V": {
            "count": 2,
            "total": 16656.185
          },
          "27850300132C": {
            "count": 2,
            "total": 5256.859
          },
          "23654000082C": {
            "count": 1,
            "total": 231
          },
          "23654000082V": {
            "count": 1,
            "total": 569.526
          }
        }
      },
      "More than 10 years": {
        "count": 9,
        "total": 58845.08,
        "details": {
          "AAACL0140PXMDEF": {
            "count": 1,
            "total": 106.534
          },
          "AAACL0140PEM095": {
            "count": 1,
            "total": 32322.546
          },
          "AAACL0140PST042": {
            "count": 1,
            "total": 3800.897
          },
          "AAACL0140PST047": {
            "count": 1,
            "total": 194.448
          },
          "AAACL0140PST014": {
            "count": 2,
            "total": 11726.962
          },
          "AAACL0140PST015": {
            "count": 2,
            "total": 5318.551
          },
          "AAACL0140P022": {
            "count": 1,
            "total": 5375.142
          }
        }
      },
      "Less than 3 years": {
        "count": 0,
        "total": 0,
        "details": {}
      },
      "_blank": {
        "count": 0,
        "total": 0,
        "details": {}
      },
      "7 to 10 years": {
        "count": 14,
        "total": 73046.407,
        "details": {
          "24073300256V": {
            "count": 2,
            "total": 336.678
          },
          "27850300132V": {
            "count": 1,
            "total": 12428.867
          },
          "AAACL0140PSHIPB": {
            "count": 1,
            "total": 7854.385
          },
          "27850300132C": {
            "count": 1,
            "total": 899.603
          },
          "23654000082C": {
            "count": 1,
            "total": 387
          },
          "24073300256C": {
            "count": 3,
            "total": 43.084
          },
          "AAACL0140PST014": {
            "count": 3,
            "total": 44512.766
          },
          "AAACL0140PST047": {
            "count": 1,
            "total": 5921.064
          },
          "23654000082V": {
            "count": 1,
            "total": 662.96
          }
        }
      }
    }
  },
  "b2": {
    "total": 154605.057,
    "details": {
      "3 to 5 years": {
        "count": 6,
        "total": 26774.552,
        "details": [
          {
            "caseid": "PRE-2022-07-07-421906",
            "total": 13.587
          },
          {
            "caseid": "PRE-2022-07-01-903001",
            "total": 25454.996
          },
          {
            "caseid": "PRE-2022-07-07-074316",
            "total": 662.96
          },
          {
            "caseid": "PRE-2022-07-07-279451",
            "total": 15.91
          },
          {
            "caseid": "PRE-2022-07-07-938431",
            "total": 240.099
          },
          {
            "caseid": "PRE-2022-07-07-446506",
            "total": 387
          }
        ]
      },
      "5 to 7 years": {
        "count": 1,
        "total": 8665.931,
        "details": [
          {
            "caseid": "PRE-2022-07-01-525236",
            "total": 8665.931
          }
        ]
      },
      "More than 10 years": {
        "count": 2,
        "total": 953.758,
        "details": [
          {
            "caseid": "PRE-2022-06-30-913456",
            "total": 194.448
          },
          {
            "caseid": "PRE-2022-07-01-079538",
            "total": 759.31
          }
        ]
      },
      "Less than 3 years": {
        "count": 11,
        "total": 44006.591,
        "details": [
          {
            "caseid": "PRE-2022-07-07-345182",
            "total": 5108.802
          },
          {
            "caseid": "PRE-2022-07-07-342054",
            "total": 6532.751
          },
          {
            "caseid": "PRE-2022-07-07-659416",
            "total": 10123.434
          },
          {
            "caseid": "PRE-2022-07-07-618362",
            "total": 148.057
          },
          {
            "caseid": "PRE-2022-07-07-782008",
            "total": 231
          },
          {
            "caseid": "PRE-2022-07-07-794952",
            "total": 12428.867
          },
          {
            "caseid": "PRE-2022-07-07-145337",
            "total": 96.579
          },
          {
            "caseid": "PRE-2022-07-07-186017",
            "total": 569.526
          },
          {
            "caseid": "PRE-2022-07-07-584545",
            "total": 899.603
          },
          {
            "caseid": "PRE-2022-07-07-003141",
            "total": 13.587
          },
          {
            "caseid": "PRE-2022-07-01-453677",
            "total": 7854.385
          }
        ]
      },
      "_blank": {
        "count": 4,
        "total": 20415.654,
        "details": [
          {
            "caseid": "PRE-2022-07-04-833322",
            "total": 5921.064
          },
          {
            "caseid": "PRE-2022-07-01-064640",
            "total": 5318.551
          },
          {
            "caseid": "PRE-2022-06-30-796159",
            "total": 5375.142
          },
          {
            "caseid": "PRE-2022-07-01-206604",
            "total": 3800.897
          }
        ]
      },
      "7 to 10 years": {
        "count": 5,
        "total": 53788.571,
        "details": [
          {
            "caseid": "PRE-2022-07-01-120103",
            "total": 10391.839
          },
          {
            "caseid": "PRE-2022-07-01-159351",
            "total": 10967.652
          },
          {
            "caseid": "PRE-2022-07-04-304592",
            "total": 106.534
          },
          {
            "caseid": "PRE-2022-07-01-791743",
            "total": 0
          },
          {
            "caseid": "PRE-2022-07-01-261248",
            "total": 32322.546
          }
        ]
      }
    }

  }

}
function getAnalyticData() {
  throw new Error('Function not implemented.');
}

