const displayFY = (year: number): string => `${year} - ${year + 1}`;

export function yearRange(start: number = 2009, end?: number): number[] {
  if (!end) end = new Date().getFullYear();
  return [...Array(end - start + 1)].map(() => start++).reverse();
}

export function asessmentYears(start: number = 2009, end?: number) {
  return yearRange(start, end).map((y) => ({
    value: displayFY(y).replace(/\s/g, ''),
    label: displayFY(y)
  }));
}
