import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DtNmStats, NoticeServiceService } from '../../services/notification.service';
import { asessmentYears } from 'src/app/helpers/date.utils';
import { DT_NM_STATUS } from 'src/app/shared/constant';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
export enum ADJ_SORT {
  PAN = 'Pan',
  ADJOURNMENT_REQUEST_ID = 'Adjournment Request ID',
  REASON = 'Reason',
  ADJOURNMENT_REQUEST_DATE = 'Adjournment Request Date',
  ADJOURNMENT_SOUGHT_UP_TO = 'Adjournment Sought Up to',
}
interface Filters {
  p: 'parsedRequestDate' | 'parsedResDueDate' | 'pan';
  o: 'in' | 'eq' | 'between';
  v?: string | number | null;
  v1?: string | number | null;
  v2?: string | number | null;
}

@Component({
  selector: 'app-adjournment',
  templateUrl: './adjournment.component.html',
  styleUrls: ['./adjournment.component.css']
})
export class AdjournmentComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal!: ElementRef;
  @ViewChild('closeUnModal', { static: false }) closeUnModal!: ElementRef;
  dtNmStats: null | DtNmStats = null;

  isMarkRead: boolean = false;
  proceedCnt: any = Number(sessionStorage.getItem('DtproceedCnt') ?? 10);
  rootCompanyId: number = Number(sessionStorage.getItem('companyId'));
  companyId: number = Number(localStorage.getItem('selectedId'));
  currentYear: any = ((new Date()).getFullYear() + 1);
  childCompanyId: number | null = null;
  adjData: any;
  totalCount: any;
  perPageLimitSection1 = 10;
  pageSection1: number = 1;
  tableSize: number = 10;
  assessYears = asessmentYears();
  status = DT_NM_STATUS;
  filterDropDown: any = [];
  adjReqDateArray: Array<string> | null = null;
  adjSoughtDateArray: Array<string> | null = null;
  filters: { [key: string]: Filters } = this.initFilters();
  adjConfig = {
    itemsPerPage: this.perPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'adjConfig',
  };
  userActionData: {
    "_id": string,
    "companyId": number,
    "type": string,
    "userAction": string
  } = {
      _id: '',
      companyId: 0,
      type: '',
      userAction: ''
    };
  adjSort = ADJ_SORT;
  sorting: { pan: boolean, adjVcReqstId: boolean, adjReason: boolean, parsedRequestDate: boolean, parsedResDueDate: boolean } =
    { pan: false, adjVcReqstId: false, adjReason: false, parsedRequestDate: false, parsedResDueDate: false };

  constructor(
    private noticeService: NoticeServiceService,
    private toaster: ToasterService,
    private router: Router,
    private commonServ: CommonService,
  ) {
    const navContext = JSON.parse(sessionStorage.getItem('navContext') ?? '{}');
    this.childCompanyId = navContext.companyId;
  }
  ngOnInit(): void {
    this.filters = <any>JSON.parse(sessionStorage.getItem('dtNmAdjFilter') || '{}');
    if ((this.filters && Object.keys(this.filters).length == 0) || !this.filters) {
      this.filters = this.initFilters();
    }
    this.countData();
    this.adjListData();
    this.filterValue();
    this.adjConfig.totalItems = this.proceedCnt;
    this.tableSize = this.proceedCnt;
  }
  initFilters(): { [key: string]: Filters } {
    return {
      parsedRequestDate: { p: 'parsedRequestDate', o: 'between', v1: null, v2: null },
      parsedResDueDate: { p: 'parsedResDueDate', o: 'between', v1: null, v2: null },
      pan: { p: 'pan', o: 'in', v: null },
    }
  }
  getFilters() {
    sessionStorage.setItem('dtNmAdjFilter', JSON.stringify(this.filters));
    return Object.values(this.filters).filter((el) => !!el.v || !!el.v1 || !!el.v2);
  }
  //count api 
  countData() {
    let model: any = {};
    model.companyId = sessionStorage.getItem('companyId');
    model.sortdir = "ASC",
      model.sortfield = "createdAt",
      model.module = "DT",
      this.noticeService.getDtNmCount(model).subscribe((response:any) => {
        if (response.status === 1) {
            this.dtNmStats = response.response;
        }
      });
  }
  //count api
  //adj list data api
  adjListData() {
    if (this.adjReqDateArray && this.adjReqDateArray.length > 0) {
      this.filters.parsedRequestDate.v1 = this.adjReqDateArray[0];
      if (!this.adjReqDateArray[1]) {
        return;
      } else {
        this.filters.parsedRequestDate.v2 = this.adjReqDateArray[1];
      }
    }
    if (this.adjSoughtDateArray && this.adjSoughtDateArray.length > 0) {
      this.filters.parsedResDueDate.v1 = this.adjSoughtDateArray[0];
      if (!this.adjSoughtDateArray[1]) {
        return;
      } else {
        this.filters.parsedResDueDate.v2 = this.adjSoughtDateArray[1];
      }
    }
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.adjConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      module: "DT",
      criterias: this.getFilters(),
      newRecord: isNewCase === "Newnotice"
    }
    this.noticeService.getadjListData(payload).subscribe((res) => {
      this.adjData = res.response.results;
      this.adjConfig.totalItems = res.response.count;
      this.totalCount = res.response.count;

    });
  }
  //adj list data api

  // set user action
  setAction(data: any) {
    this.userActionData = {
      _id: '',
      companyId: 0,
      type: '',
      userAction: ''

    };

    this.userActionData._id = data._id;
    this.userActionData.companyId = this.companyId;
    this.userActionData.type = 'DtAdjournment';
    if (data.userAction === 'Yet to read' || !data.userAction) {
      this.userActionData.userAction = 'Read by user';
    } else {
      this.userActionData.userAction = 'Yet to read';
    }
  }
  confirmMarkRead() {
    this.noticeService.confirmDtUserAction(this.userActionData).subscribe((response: any) => {
      if (response.status == 1) {
        this.adjListData();
        this.isMarkRead = true;
        this.closeModal.nativeElement.click();
        this.toaster.showSuccess(response.response);
      } else {
        this.toaster.showError(response.errorList[0].message);
      }

    });

  }
  confirmMarkUnRead() {
    this.noticeService.confirmDtUserAction(this.userActionData).subscribe((response: any) => {
      if (response.status == 1) {
        this.adjListData();
        this.isMarkRead = true;
        this.closeUnModal.nativeElement.click();
        this.toaster.showSuccess(response.response);
      } else {
        this.toaster.showError(response.errorList[0].message);
      }

    });

  }
  // set user action

  //filter dropdown Values
  filterValue() {
    const payload: any = {
      type: 'DtNotice',
      module: "DT",
    }
    this.noticeService.getFilterValue(payload).subscribe((res) => {
      this.filterDropDown = res.response;
    });

  }
  //filter  dropdown Values

  //Export to excel download
  downloadEXFile() {
    this.getFilters();
    this.dwdExpExcel();
  }
  dwdExpExcel() {
    const payload: any = {
      page: this.adjConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      reportType: 'DtAdjournment',
      sortfield: 'parsedRequestDate',
      module: "DT",
      criterias: this.getFilters()
    }
    this.noticeService.dwdDtExportExcel(payload).subscribe((response: any) => {
      this.downloadExFile(response);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadExFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `Proceedings.xlsx`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  //Export to excel download

  //pagination  
  onTableDataChangeAdj(pageNumber: number): void {
    this.adjConfig.currentPage = pageNumber;
    this.adjListData();
  }
  getadjData(perPageLimitSection1: any, section1page: number) {
    const payload: any = {
      page: section1page == 1 ? 0 : section1page - 1,
      size: perPageLimitSection1,
      module: "DT",
      criterias: this.getFilters()
    }
    this.noticeService.getadjListData(payload).subscribe((res) => {
      this.adjData = res.response.results;
      this.adjConfig.totalItems = res.response.count;
      this.adjConfig.itemsPerPage = perPageLimitSection1;
      this.adjConfig.currentPage = payload.pageNumber + 1;
    });
  }
  //pagination  

  toAdjDetail(adjVcReqstId: number) {
    this.router.navigate(['/dtNmAdjDetail', adjVcReqstId]);
  }

  getAdjData() {
    const payload: any = {
      page: this.adjConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      module: "DT",
      criterias: this.getFilters()
    }
    this.noticeService.getadjListData(payload).subscribe((res) => {
      this.adjData = res.response.results;
      this.adjConfig.totalItems = res.response.count;
      this.totalCount = res.response.count;


    });
  }
  clearDt1() {
    if (this.adjReqDateArray && this.adjReqDateArray.length > 0) {
      this.filters.parsedRequestDate.v1 = null;
      this.filters.parsedRequestDate.v2 = null;
    }
    this.getAdjData();
    this.adjReqDateArray = null;
  }
  clearDt2() {
    if (this.adjSoughtDateArray && this.adjSoughtDateArray.length > 0) {
      this.filters.parsedResDueDate.v1 = null;
      this.filters.parsedResDueDate.v2 = null;
    }
    this.getAdjData();
    this.adjSoughtDateArray = null;
  }
  clearFilter(inputName: string) {
    switch (inputName) {
      case 'pan':
        this.filters.pan.v = null;
        break;
      default:
        break;
    }
    this.getAdjData();
  }

  sort(column: string) {
    switch (column) {
      case ADJ_SORT.PAN: {
        if (!this.sorting.pan) {
          this.sorting.pan = true;
          this.adjData = this.commonServ.sortProcAdj(this.adjData, 'data', 'pan', 'asc');
        } else {
          this.sorting.pan = false;
          this.adjData = this.commonServ.sortProcAdj(this.adjData, 'data', 'pan', 'desc');
        }
        break;
      }
      case ADJ_SORT.ADJOURNMENT_REQUEST_ID: {
        if (!this.sorting.adjVcReqstId) {
          this.sorting.adjVcReqstId = true;
          this.adjData = this.commonServ.sortProcAdj(this.adjData, 'data', 'adjVcReqstId', 'asc');
        } else {
          this.sorting.adjVcReqstId = false;
          this.adjData = this.commonServ.sortProcAdj(this.adjData, 'data', 'adjVcReqstId', 'desc');
        }
        break;
      }
      case ADJ_SORT.REASON: {
        if (!this.sorting.adjReason) {
          this.sorting.adjReason = true;
          this.adjData = this.commonServ.sortProcAdj(this.adjData, 'data', 'adjReason', 'asc');
        } else {
          this.sorting.adjReason = false;
          this.adjData = this.commonServ.sortProcAdj(this.adjData, 'data', 'adjReason', 'desc');
        }
        break;
      }
      case ADJ_SORT.ADJOURNMENT_REQUEST_DATE: {
        if (!this.sorting.parsedRequestDate) {
          this.sorting.parsedRequestDate = true;
          this.adjData = this.commonServ.sortProcAdj(this.adjData, 'data', 'parsedRequestDT', 'asc');
        } else {
          this.sorting.parsedRequestDate = false;
          this.adjData = this.commonServ.sortProcAdj(this.adjData, 'data', 'parsedRequestDT', 'desc');
        }
        break;
      }
      case ADJ_SORT.ADJOURNMENT_SOUGHT_UP_TO: {
        if (!this.sorting.parsedResDueDate) {
          this.sorting.parsedResDueDate = true;
          this.adjData = this.commonServ.sortProcAdj(this.adjData, 'data', 'parsedResDueDT', 'asc');
        } else {
          this.sorting.parsedResDueDate = false;
          this.adjData = this.commonServ.sortProcAdj(this.adjData, 'data', 'parsedResDueDT', 'desc');
        }
        break;
      }


    }
  }

}
