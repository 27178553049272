import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';


@Component({
  selector: 'app-d2',
  templateUrl: './d2.component.html',
  styleUrls: ['./d2.component.css']
})
export class D2Component implements OnInit {
  @Input() d2Data : any;
  data : any =[];
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.initializeData();
    
  }

  initializeData(){
    const mainLabels =  Object.keys(this.d2Data.details);
    const total = {
      currLiab:0,
      currProv:0,
      diffLiab:0,
      diffProv:0,
      prevLiab:0,
      prevProv:0}
    mainLabels.forEach(label =>{
      const data =this.d2Data.details[label];
      this.data.push({label:label, data: data});
      total.currLiab = total.currLiab + data.currLiab;
      total.currProv = total.currProv + data.currProv;
      total.diffLiab = total.diffLiab + data.diffLiab;
      total.prevLiab = total.prevLiab + data.prevLiab;
      total.prevProv = total.prevProv + data.prevProv;
    });
    this.data.push({label:'Subtotal (A)',data: total })
  }

}
