import { Component, OnDestroy, OnInit } from '@angular/core';
import { LitigationServiceService } from '../services/litigation-service.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { ISSUING_AUTHORITY_TYPE, MODE_OF_RECEIPT } from 'src/app/shared/constant';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';

export enum STATUS {
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
}

@Component({
  selector: 'app-smart-upload',
  templateUrl: './smart-upload.component.html',
  styleUrls: ['./smart-upload.component.css'],
})
export class SmartUploadComponent implements OnInit, OnDestroy {
  alive: boolean = true;
  form!: UntypedFormGroup;
  progressStatus: '' | 'analysing' | 'in progress' | 'complete' = '';
  caseData: any;
  selectedField: any;

  currentYear: any;
  maxDate = new Date();
  selectedFile: any;
  selectedFileUrl: any;
  process_id: string = '';
  fileUrl: any;
  companyId: any;
  selectedGstin: any;
  selectedCase_label: any;
  selectedStateName: any;
  selectedStateCode: any;
  filePath: any;
  Status: any;
  navContext: any;
  matches: any;
  showfooter: boolean = true;

  isUPpanCompLoc: boolean = true;
  isDWpanCompLoc: boolean = true;
  ispanCompLocFile: boolean = false;
  ispanCompLocText: boolean = true;
  intervalId: any;
  userRoleLs: any;
  preLitPH1Preceded: { label: string; value: string }[];
  selectedFilling: any;
  navData: any;
  isCaseCreated: boolean = false;
  isSubmitted: boolean = false;
  modeOfReceiptNotice = MODE_OF_RECEIPT;
  constructor(
    private litService: LitigationServiceService,
    private toaster: ToasterService,
    private loaderService: LoaderService,
    private commonServ: CommonService,
    private router: Router,
    private fBuild: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private GS: GetterSetterService
  ) {
    this.createForm();
    this.preLitPH1Preceded = ISSUING_AUTHORITY_TYPE;
  }

  ngOnInit() {
    this.navContext = this.commonServ.getNavContext();
    this.selectedFilling = JSON.parse(
      sessionStorage.getItem('selectedFilling') || '{}'
    );
    this.navData = JSON.parse(this.GS.getNavContextData() || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.loaderService.hide();
    this.personResponsibleData();
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  createForm() {
    this.form = this.fBuild.group({
      letterLoc: [null],
      gstin: [null,Validators.required],
      state: [null,Validators.required],
      case_label: [null,Validators.required],
      summary: [null],
      ref_number: [null,Validators.required],
      notice_date: [null, Validators.required],
      receipt_notice_date:[null,Validators.required],
      notice_type: [null],
      address: [null],
      designation: [null],
      jurisdiction: [null],
      from_period: [null,Validators.required],
      to_period: [null,Validators.required],
      due_date: [null,Validators.required],
      section: [null],
      officer: [null],
      personResponsible: [null,Validators.required],
      typeOfAuthority: [null],
      caseId: [null],
      orderReceiptMode: [null],
      din: [null],
      caseSummary: [null],
      remark:[null]
    });
  }
  personResponsibleData() {
    let model: any = {};
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    this.litService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });

    const userEmailId = sessionStorage.getItem('UserId');
    this.form.controls.personResponsible.patchValue(userEmailId);
  }
  setFormValues(values: any) {
    this.form.patchValue({
      gstin: values.gstin,
      state: this.getStateNameByGstin(values.gstin),
      case_label: values.case_label,
      summary: values.summary,
      ref_number: values.ref_number,
      notice_date: values.notice_date,
      receipt_notice_date:values.notice_date,
      notice_type: values.notice_type,
      address: values.address,
      designation: values.designation,
      jurisdiction: values.jurisdiction,
      from_period: values.from_period,
      to_period: values.to_period,
      due_date: values.due_date,
      section: values.section,
      officer: values.officer,
      personResponsible: values.personResponsible,
      typeOfAuthority: values.typeOfAuthority,
      orderReceiptMode: values.orderReceiptMode,
      din: values.din,
      caseSummary: values.summary,
      remark:values.remark
      // contact: values.contact,
      // email: values.email,
    });
  }

  onFileSelect(event: any) {
    if (event.target.files && event.target.files.length === 0) {
      return;
    }
    if (this.selectedFile) {
      this.alive = true;
      this.progressStatus = '';
      this.caseData = null;
      this.form.reset();
    }
    this.selectedField = event.target.getAttribute('id');
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['pdf'])) {
        this.selectedFile = event.target.files[0];
        this.selectedFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          URL.createObjectURL(this.selectedFile));
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace('&', 'And')
        this.selectedFile = new File([originalFile], modifiedFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedFile);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported');
      }
    } else {
      this.selectedFile = null;
      this.relopageRefresh();
      clearInterval(this.intervalId);
    }
    return null;
  }

  onUpload(selectedFile: any) {
    this.progressStatus = 'analysing';
    this.loaderService.hide();
    let requestBody = new FormData();
    requestBody.append('file', selectedFile);
    this.litService.getProcessId(requestBody).subscribe((response: any) => {
      this.progressStatus = 'in progress';
      this.showfooter = true;
      this.process_id = response.process_id;
      this.litService
        .pollStatusUntilComplete(this.process_id)
        .pipe(takeWhile(() => this.alive))
        .subscribe({
          next: (response: any) => {
            if (response.status === STATUS.COMPLETE) {
              this.caseData = response;
              this.setFormValues(response);
              const userEmailId = sessionStorage.getItem('UserId');
              this.form.controls.personResponsible.patchValue(userEmailId);
              this.alive = false;
              this.showfooter = false;
              this.progressStatus = 'complete';
            }
          },
        });
    });
  }

  /*create case (submit function)*/
  saveLetter() {
    let data: any = {};
    data = this.form.value;

    this.litService.saveNoticeLetterData(data,).subscribe((response: any) => {
      if (response.status === 1) {
        this.toaster.showSuccess(response.message);
      }
    });
  }

  getStateNameByGstin(gstin: string) {
    const stateCode = gstin.slice(0, 2);
    return this.commonServ.getStateName(stateCode);
  }

  checkExtensionFunction(
    selectedFile: { name: string },
    extensionAllowedArray: any[]
  ) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  getFileNameDta(fileUrl: any) {
    if (fileUrl && typeof fileUrl == 'string')
      return this.litService.getFileName(fileUrl);
  }

  uploadpanCompLoc() {
    this.ispanCompLocFile = true;
    this.ispanCompLocText = false;
    this.isDWpanCompLoc = false;
    this.isUPpanCompLoc = false;
  }

  setDate() {
    this.currentYear = new Date().getFullYear() + 1;
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }

  relopageRefresh() {
    if (this.router.url.includes('litigationSmartUpload')) {
      this.commonServ.reloadCurrentRoute();
    }
  }
  fetchCaseData: any;
  createCase() {
    this.isSubmitted = true;
    const payload = {
     
      gstin: this.form.controls.gstin.value,
      state: this.form.controls.state.value,
      personResponsible: this.form.controls.personResponsible.value,
      caseLabel: this.form.controls.case_label.value,
      orderSummary: this.form.controls.summary.value,
      internalRemarks:this.form.controls.remark.value,
      itemList: [{
        letterRefNo: this.form.controls.ref_number.value,
        dateOfLetter: this.form.controls.notice_date.value,
        issue: this.form.controls.section.value,
        typeOfForm: this.form.controls.notice_type.value,
        periodFromDate: this.form.controls.from_period.value,
        periodToDate: this.form.controls.to_period.value,
        legalDueDate: this.form.controls.due_date.value,
        typeOfAuthority: this.form.controls.typeOfAuthority.value,
        summaryOfCorrespondence: this.form.controls.summary.value,
        din: this.form.controls.din.value,
        orderReceiptMode:this.form.controls.orderReceiptMode.value,
        letterLoc: [
          {
              fileLoc: this.selectedFile.name,
              fileName: this.selectedFile.name,
              remarks: null
          }
        ],
        issuingAuth: {
          officer: this.form.controls.officer.value,
          designation: this.form.controls.designation.value,
          jurisdiction: this.form.controls.jurisdiction.value,
          address: this.form.controls.address.value,
          // contact: this.form.controls.contact.value,
          // email: this.form.controls.email.value,
        }
      }]
    }
    Object.assign(payload,{action:'CREATE'},{ phase:'1'},{keyword:'keyword1'})
    const gstinData:any = sessionStorage.getItem('selectedFilling');
    const parseData = JSON.parse(gstinData);
    console.log("Form", this.form);
    if (this.form.status != "VALID") {
      this.toaster.showError("Mandatory Fields are required");
    } else {
      if (payload.gstin == parseData.gstin) {   
        this.litService.saveNoticeLetterData(payload).subscribe((response: any) => {
          if (response.status === 1) { 
            this.isCaseCreated = true;
            this.form.controls.caseId.patchValue(response.response);
            this.toaster.showSuccess(response.message);
            const model = {
              caseId: this.form.controls.caseId.value
            }
            this.litService.getCaseNoticeData(model).subscribe((response: any) => {
              if (response.status === 1) { 
                this.fetchCaseData = response.response;
              }
            })
          } else {
            this.toaster.showError(response.message);
          }
         })
      } else {
        this.toaster.showError("Uploaded file belongs to different GSTIN, Please upload file belong to selected GSTIN");
      }
    }
 

  }
  navigateToSummary() {
    this.router.navigate(['/litigationSummary']);
  }
  get formControls() {
    return this.form.controls;
  }
}
