import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { NoticeServiceService } from '../../services/notification.service';

@Component({
  selector: 'app-procd-detail-view',
  templateUrl: './procd-detail-view.component.html',
  styleUrl: './procd-detail-view.component.css'
})
export class ProcdDetailViewComponent implements OnInit{

  noticeId: any;
  parentNoticeheaderSeqNo: any;
  noticeData: any;
  noticeDataCount: any;
  adjData: any;
  vcData: any;
  adjDataCount: any;
  vcDataCount: any;
  noticeFiles: any[] = [];
  vcFiles: any[] = [];
  constructor(
    private noticeService: NoticeServiceService,
    private activeSnapShot: ActivatedRoute,
    private toaster: ToasterService,
    private router: Router,
  ) {
    this.noticeId = this.activeSnapShot.snapshot.params.noticeId;
    this.parentNoticeheaderSeqNo = this.activeSnapShot.snapshot.params.parentNoticeheaderSeqNo;
  }

  ngOnInit(): void {
    this.noticeListData();
    this.adjListData();
    this.vcListData();
  }
  splitfilename(filename: string) {
    const parts = filename.split('_');
    const datePart = parts[parts.length - 1].split('(')[0];
    return `${parts[0]}_xxx_${datePart}`;
  }
  getresTypeLabel(caseFolderTypeCd: string): string {
    switch (caseFolderTypeCd) {
      case 'FR':
        return 'Full';
      case 'PR':
        return 'Partial';
      case 'P':
        return 'Partial';
      case 'Y':
        return 'Full';
      default:
        return '';
    }
  }
  //notice list data api
  noticeListData() {
    const payload: any = {
      page: 0,
      size: 50,
      module: "DT",
      criterias: [{
        "p": "headerSeqNo",
        "o": "in",
        "v": this.parentNoticeheaderSeqNo
      },
      {
        "p": "noticeId",
        "o": "in",
        "v": this.noticeId
      }]
    }
    this.noticeService.getNoticeListData(payload).subscribe((res) => {
      this.noticeData = res.response.results[0];
      this.noticeDataCount = res.response.count;

    });
  }
  //notice list data api

  //adj list data api
  adjListData() {
    const payload: any = {
      page: 0,
      size: 50,
      module: "DT",
      criterias: [{
        "p": "noticeId",
        "o": "in",
        "v": this.noticeId
      }]
    }
    this.noticeService.getadjListData(payload).subscribe((res) => {
      this.adjData = res.response.results;
      this.adjDataCount = res.response.count;
    });
  }
  //adj list data api

  //vc list data api
  vcListData() {
    const payload: any = {
      page: 0,
      size: 50,
      module: "DT",
      criterias: [{
        "p": "parentNoticeheaderSeqNo",
        "o": "in",
        "v": this.parentNoticeheaderSeqNo
      }]
    }
    this.noticeService.getVcListData(payload).subscribe((res) => {
      this.vcData = res.response.results;
      this.vcDataCount = res.response.count;

    });
  }
  //vc list data api

  //file download
  onClickFileName(NoticeFileData: any[] = []) {
    this.noticeFiles = [];
    this.noticeFiles = NoticeFileData;
  }
  download(key: any, id: any, fileName: string) {
    let urlData = {
      s3Key: key,
      id: id
    }
    this.noticeService.dwdDTNoticesFile(urlData).subscribe((response: any) => {
      this.downloadcontFile(response, fileName);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadcontFile(data: any, fileName: any) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${fileName}`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  onClickFileVCName(NoticeFileData: any[] = []) {
    this.vcFiles = [];
    this.vcFiles = NoticeFileData;
  }
  downloadvc(key: any, id: any, fileName: string) {
    let urlData = {
      s3Key: key,
      id: id
    }
    this.noticeService.dwdDTVcFile(urlData).subscribe((response: any) => {
      this.downloadvcFile(response, fileName);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadvcFile(data: any, fileName: any) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${fileName}`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  //file download
  toAdjDetail(adjVcReqstId: number) {
    this.router.navigate(['/dtNmAdjDetail', adjVcReqstId]);
  }
  toVcDetail(adjVcReqstId: number) {
    this.router.navigate(['/dtNmVcDetail', adjVcReqstId]);
  }

  //to previous Page 
  toPreviousPage(id:number) {
    this.router.navigate(['/dtNmProcDetail', id]);
  }
}
