import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DirectTaxService {
  rootCompanyId: number = Number(sessionStorage.getItem('companyId'));

  constructor(private http: HttpClient) {}

  getCompanyId() {
    const navContext = JSON.parse(sessionStorage.getItem('navContext') ?? '{}');
    return navContext?.companyId ?? this.rootCompanyId;
  }

  getPanWiseAnalysis(filters: any = []) {
    const payload = {
      companyId: this.getCompanyId(),
      criterias: filters
    };
    payload.criterias.push({ p: 'status', o: 'nin', v: 'MOVED,CLOSED' });

    return this.http
      .post(`${environment.apiUrl}/lms2/dt/analytics/panwise`, payload)
      .pipe(
        map((resp: any) => {
          let casesMap: { [key: string]: any[] } = {};

          const data =
            resp.response.panDtls?.map((pan: any) => {
              const ayData = pan.ayDtls?.map((ay: any) => {
                if (!casesMap[pan.pan]) casesMap[pan.pan] = [];
                casesMap[pan.pan].push(...ay.caseDtls);

                const mapKey = `${pan.pan}-${ay.ay}`;
                casesMap[mapKey] = ay.caseDtls ?? [];
                return { ...ay, label: ay.ay, key: mapKey, caseDtls: undefined };
              });

              return {
                ...pan,
                label: pan.pan,
                key: pan.pan,
                collapse: true,
                ayDtls: ayData
              };
            }) ?? [];

          const total: any = _.omit(resp.response, 'panDtls');
          data.push({ ...total, label: 'Total' });

          resp = { data: data, casesMap: casesMap };
          return resp;
        })
      );
  }

  getIssueWiseAnalysis(filters: any = []) {
    const payload = {
      companyId: this.getCompanyId(),
      criterias: filters
    };
    payload.criterias.push({ p: 'status', o: 'nin', v: 'MOVED,CLOSED' });

    return this.http
      .post(`${environment.apiUrl}/lms2/dt/analytics/issuewise`, payload)
      .pipe(
        map((resp: any) => {
          let casesMap: { [key: string]: any[] } = {};

          const data =
            resp.response.map((record: any) => {
              casesMap[record.issue] = record.caseDtls ?? [];
              return { ...record, caseDtls: undefined };
            }) ?? [];

          resp = { data: data, casesMap: casesMap };
          return resp;
        })
      );
  }

  getAgingInception(filters: any = []) {
    const payload = {
      companyId: this.getCompanyId(),
      criterias: filters
    };
    payload.criterias.push({ p: 'status', o: 'nin', v: 'CLOSED' });

    return this.http
      .post(`${environment.apiUrl}/lms2/dt/analytics/ageing/inception`, payload)
      .pipe(
        map((resp: any) => {
          let casesMap: { [key: string]: any[] } = {};

          const data =
            resp.response.map((record: any) => {
              casesMap[record.title] = record.caseDtls ?? [];
              return { ...record, caseDtls: undefined };
            }) ?? [];

          resp = { data: data, casesMap: casesMap };
          return resp;
        })
      );
  }

  getAgingForum(filters: any = []) {
    const payload = {
      companyId: this.getCompanyId(),
      criterias: filters
    };
    payload.criterias.push({ p: 'status', o: 'nin', v: 'MOVED,CLOSED' });

    return this.http
      .post(`${environment.apiUrl}/lms2/dt/analytics/ageing/currentforum`, payload)
      .pipe(
        map((resp: any) => {
          let casesMap: { [key: string]: any[] } = {};

          const data =
            resp.response.map((record: any) => {
              casesMap[record.title] = record.caseDtls ?? [];
              return { ...record, caseDtls: undefined };
            }) ?? [];

          resp = { data: data, casesMap: casesMap };
          return resp;
        })
      );
  }
}
