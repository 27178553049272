import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseResponse } from 'src/app/models/base-response.interface';



@Injectable({
  providedIn: 'root'
})
export class TaxServiceService {

  AccessTabData$ = new BehaviorSubject<any>({ preajaFlag: false, preapaFlag: false, preaptFlag: false, prehcFlag: false, prescFlag: false });
  constructor(
    private http: HttpClient
  ) { }
  setAccessTabData(data: any) {
    this.AccessTabData$.next(data);
  }
  getAccessTabData() {
    return this.AccessTabData$.asObservable();
  }

  /*Person responsible data */
  getUserResList(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');

    return this.http.get(`${environment.apiUrl}/mgmt/company/users?companyid=${companyId}`).pipe(map((resp) => {
      return resp;
    }));
  }
  /*Person responsible data */
  /*PAN data */
  getPanList(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');

    return this.http.get(`${environment.apiUrl}/mgmt/user/company/assignedLegalPansByUniqueCmpId?uniqueCompanyId=${companyId}&userMailId=${data.userMailId}`).pipe(map((resp) => {
      return resp;
    }));
  }
  /*upload file */
  uploadFile(data: any, urlData: any) {
    let options = {
      headers: new HttpHeaders({
        // 'Content-Type': data.type,
        "Content-Type": "application/json",
      }),
    };
    return this.http.get(`${environment.apiUrl}/lms2/common/upload?gstinOrPan=${urlData.gstinOrPan}&type=${urlData.type}&field=${urlData.field}&fname=${data.name}`, options).pipe(map((resp) => {
      return resp;
    }));
  }
  /*upload file */

  /*get file name */
  getFileName(fileUrl: any) {
    if (fileUrl) {
      let index = fileUrl.lastIndexOf('/');
      return fileUrl.substring(index + 1);
    }
  }
  /*get file name */
  /*download file */
  downloadFile(urlData: any) {
    let options = {
      headers: new HttpHeaders({
        //'companyId': '7676'
      }),

    };
    return this.http.get(`${environment.apiUrl}/lms2/common/download?fname=${urlData.fname}`, options).pipe(map((resp) => {
      return resp;
    }));
  }

  getFinancialInformation(pan: string): Observable<BaseResponse> {
    return this.http.get<BaseResponse>(`${environment.apiUrl}/lms2/dt/fininfo/get?pan=${pan}`).pipe(map((resp) => {
      return resp;
    }));
  }

  createFinancialInformation(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/fininfo/create`, requestBody).pipe(map((response) => {
      return response;
    }));
  }

  updateFinancialInformation(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/fininfo/save`, requestBody).pipe(map((response) => {
      return response;
    }));
  }

  /*fetch tax post details */
  getTaxPosData(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/dt/taxpos/get?pan=${data.pan}`).pipe(map((resp) => {
      return resp;
    }));
  }
  /*fetch tax post details */

  /*save tax post details */
  saveTaxPosData(data: any,) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/taxpos/create`, data).pipe(map((response) => {
      return response;
    }));
  }
  /*save tax post details */


  /*update tax post details */
  updateTaxPosData(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/taxpos/save`, data).pipe(map((response) => {
      return response;
    }));
  }
  //  Summary 
  // getDtSummary(requestBody: any, endpoint: string) {
  //   return this.http.post(`${environment.apiUrl}/lms2/dt/query/${endpoint}`,requestBody).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }

  getDtSummaryEnqproceeding(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/enqproceeding`, data);
  }
  getDtSummaryIntorder(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/intorder`, data);
  }
  getDtSummaryAssessment(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/assessment`, data);
  }
  getDtSummaryCommissioner(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/commissioner`, data);
  }
  getDtSummaryItat(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/itat`, data);
  }
  getDtSummaryHc(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/hc`, data);
  }
  getDtSummarySc(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/sc`, data);
  }

  DtCaseCount(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/query/count`, data);
  }

  /* Delete api */
  deleteEnqproceeding(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/dt/delete/enqproceeding`, httpOptions);
  }
  deleteIntorder(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/dt/delete/intorder`, httpOptions);
  } deleteAssessment(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/dt/delete/assessment`, httpOptions);
  } deleteCommissioner(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/dt/delete/commissioner`, httpOptions);
  } deleteItat(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/dt/delete/itat`, httpOptions);
  } deleteHc(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/dt/delete/hc`, httpOptions);
  } deleteSc(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/dt/delete/sc`, httpOptions);
  }
  // DtCaseCount(requestBody: any) {
  //   return this.http.post(`${environment.apiUrl}/lms2/dt/query/count`, requestBody).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }

  /*create case*/
  CreateEnquiryproceeding(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/create/enqproceeding`, requestBody).pipe(map((response) => {
      return response;
    }));
  }

  CreateIntimation(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/create/intorder`, requestBody).pipe(map((response) => {
      return response;
    }));
  }

  CreateAssessment(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/create/assessment`, requestBody).pipe(map((response) => {
      return response;
    }));
  }

  CreateCommissionor(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/create/commissioner`, requestBody).pipe(map((response) => {
      return response;
    }));
  }
  CreateItat(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/create/itat`, requestBody).pipe(map((response) => {
      return response;
    }));
  }
  CreateHC(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/create/hc`, requestBody).pipe(map((response) => {
      return response;
    }));
  }
  CreateSc(requestBody: any) {
    return this.http.post(`${environment.apiUrl}/lms2/dt/create/sc`, requestBody).pipe(map((response) => {
      return response;
    }));
  }

  /*get case stage1 */
  getCaseData(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/dt/get/enqproceeding?caseId=${data.caseId}`).pipe(map((resp) => {
      return resp;
    }));
  }

  /*get case stage2*/
  getGetIntimationcase(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/dt/get/intorder?caseId=${data.caseId}`).pipe(map((resp) => {
      return resp;
    }));
  }
  /*get case stage3*/
  getGetAssessment(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/dt/get/assessment?caseId=${data.caseId}`).pipe(map((resp) => {
      return resp;
    }));
  }

  /*get case stage4*/
  getGetCommissionor(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/dt/get/commissioner?caseId=${data.caseId}`).pipe(map((resp) => {
      return resp;
    }));
  }

  /*get case stage5*/
  getGetItat(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/dt/get/itat?caseId=${data.caseId}`).pipe(map((resp) => {
      return resp;
    }));
  }

  /*get case stage5*/
  getGetHC(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/dt/get/hc?caseId=${data.caseId}`).pipe(map((resp) => {
      return resp;
    }));
  }

  /*get case stage6*/
  getGetSC(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/dt/get/sc?caseId=${data.caseId}`).pipe(map((resp) => {
      return resp;
    }));
  }


  /*update case*/
  updateEnquiryproceeding(requestBody: any) {
    return this.http.put(`${environment.apiUrl}/lms2/dt/update/enqproceeding`, requestBody).pipe(map((response) => {
      return response;
    }));
  }
  updateIntorder(requestBody: any) {
    return this.http.put(`${environment.apiUrl}/lms2/dt/update/intorder`, requestBody).pipe(map((response) => {
      return response;
    }));
  } updateAssessment(requestBody: any) {
    return this.http.put(`${environment.apiUrl}/lms2/dt/update/assessment`, requestBody).pipe(map((response) => {
      return response;
    }));
  } updateCommissioner(requestBody: any) {
    return this.http.put(`${environment.apiUrl}/lms2/dt/update/commissioner`, requestBody).pipe(map((response) => {
      return response;
    }));
  } updateItat(requestBody: any) {
    return this.http.put(`${environment.apiUrl}/lms2/dt/update/itat`, requestBody).pipe(map((response) => {
      return response;
    }));
  } updateHc(requestBody: any) {
    return this.http.put(`${environment.apiUrl}/lms2/dt/update/hc`, requestBody).pipe(map((response) => {
      return response;
    }));
  }

  updateSc(requestBody: any) {
    return this.http.put(`${environment.apiUrl}/lms2/dt/update/sc`, requestBody).pipe(map((response) => {
      return response;
    }));
  }
  isEmpty(data: any): boolean {
    return data !== undefined && data !== null && data !== '';
  }
  isEmptyArray(data: any): boolean {
    return data.length !== 0;
  }
  generateMasterReport(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/reports/generate`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  generatesMasterReport(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/reports/generate`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  statusMasterReport() {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    const headers = new HttpHeaders()
      .set('companyId', String(companyId));
    return this.http.get(`${environment.apiUrl}/lms2/reports/status`, { 'headers': headers }).pipe(map((resp) => {
      return resp;
    }));
  }
  downLoadMasterReports() {
    return this.http.get(`${environment.apiUrl}/lms2/reports/download`, { responseType: 'blob' });
  }
  getIssueRefData(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/api/issueRef`, data);
  }

  getDuplicateData(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/api/duplicate/check`, data);
  }
}
