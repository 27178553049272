import { Component, OnInit } from '@angular/core';
import { LitigationServiceService } from '../services/litigation-service.service';
import { ChartData, ChartOptions, ChartType } from 'chart.js';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { STATE_LIST } from 'src/app/shared/constant';
import { BusinessService } from 'src/app/businessManagement/services/business.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { event } from 'jquery';
import _ from 'lodash';
import { log } from 'console';
interface Filters {
  p: 'state' | 'gstinDiv' ;
  o: 'in';
  v?: any;

}
export enum DIVISION {
  DIVISIONS = 'division',
  STATE = 'State',
  FORUM = 'forum',
  GSTIN = 'GSTIN',
  CASE_ID = 'Case ID',
  CASE_LABEL = 'Case Label',
  DEMAND_AMOUNT = "Demand Amount"
}
export enum INCEPTION {
  DIVISION = 'division',
  STATE = 'State',
  FORUM = 'forum',
  GSTIN = 'GSTIN',
  CASE_ID = 'Case ID',
  CASE_LABEL = 'Case Label',
  DEMAND_AMOUNT = "Demand Amount",
  INCEPTION_FORUM = "Inception Forum ",
  INCEPTION_DATE = "Inception Date ",
}

export enum CURRENT_DATA {
  DIVISION = 'division',
  STATE = 'State',
  FORUM = 'forum',
  GSTIN = 'GSTIN',
  CASE_ID = 'Case ID',
  CASE_LABEL = 'Case Label',
  DEMAND_AMOUNT = "Demand Amount",
  INCEPTION_DATE = "Inception Date ",
}
interface Legends {
  label: string;
  color: string;
  value: number;
  percent?: string
}
interface LegendData {
  label: string;
  color: string;
  value: number;
  percent?: string
}
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.css']
})
export class AnalyticsComponent implements OnInit {
  isExpanded: boolean = false;
  RWAnalytic: any = [];
  divSumColTotal: any;
  divSumColTotalSum: any;
  liabilityTotal: any;
  paymentCumlData: any = [];
  contLiabilityReport: any = [];
  //Region wise transpose data
  gtd: any = [];
  selectedClass: string = '';
  tabledata: any = [];
  transposeData: any = [];
  transposeDataType: any = [];
  regionKey: any = [];
  regionValue: any = [];
  isAnalyticsConOne: boolean = false;
  isAnalyticsSort: boolean = false;
  isSortCurrentData: boolean = false;
  selectedIRN: boolean = false;
  isinCR: boolean = false;
  isinLakh: boolean = true;


  transBarChartOptions: ChartOptions = {};
  transBarChartLabels: any = [];
  transBarChartType: ChartType = 'bar';
  transBarChartLegend: boolean = true;
  transBarChartPlugins: any = [];
  transBbarChartData: any = [];
  transBarChartColors: any = [];


  //Region wise transpose data
  //issue wise demand
  listofCaseReport: any = [];
  ListOfIssue: any = [];
  ListOfIssueInCr: any = [];
  issueChartLabel: any = [];
  issueChartData: any = [];
  contGstin: any = [];
  listIssueBarChartOptions: ChartOptions = {};
  listIssueBarChartLabels: any = [];
  listIssueBarChartType: ChartType = 'bar';
  listIssueBarChartLegend: boolean = true;
  listIssueBarChartPlugins: any = [];
  listIssueBbarChartData!: ChartData<'bar'>;
  listIssueBarChartColors: any = [];
  //issue wise demand

  //litigation aging analysis
  ageingCount: any = [];
  ageingSummary: any = [];
  AgeingSummaryData: any = [];
  ageingPieChartLabel: string[] = [];
  laaPieChartOptions: ChartOptions<'pie'> = {};
  laaPieChartLabels: any = [];
  laaPieChartType: ChartType = 'doughnut';
  // laaPieChartType: 'pie' | 'doughnut' = 'doughnut';

  laaPieChartLegend: boolean = true;
  laaPieChartPlugins: any = [];
  laaPieChartData: any = [];
  laaPieChartColors: any = [];
  agingAnalysisRef!: NgbModalRef;
  ageingPopSummary: any;

  //litigation aging analysis
  //litigation aging analysis- inception
  ageingInCount: any = [];
  ageingInSummary: any = [];
  AgeingInSummaryData: any = [];
  ageingInPieChartLabel: string[] = [];
  laaInPieChartOptions: ChartOptions = {};
  laaInPieChartLabels: any = [];
  laaInPieChartType: ChartType = 'doughnut';
  // laaInPieChartType: 'pie' | 'doughnut' = 'doughnut';
  laaInPieChartPlugins: any = [];
  laaInPieChartData!: ChartData<'pie', number[], string | string[]>;
  laaInPieChartColors: any = [];
  ageingInPopSummary: any;
  agingInAnalysisRef!: NgbModalRef;
  //litigation aging analysis
  conttabledata: any = [];
  closeResult: any = [];
  detaildata: any = [];
  currentLabel: any = [];
  agingTabledata: any = [];
  regiondata: any = [];
  paymentRegiondata: any = [];
  stateList: any = [];
  divisionList = DIVISION;
  agingList = INCEPTION;
  currentForum = CURRENT_DATA;
  sorting: { division: boolean, state: boolean, type: boolean, currentForum: boolean, amountInLakhs: boolean, inceptionForum: boolean, inceptionDate: boolean, gstin: boolean, caseId: boolean, label: boolean, amount: boolean } = { division: false, state: false, type: false, currentForum: false, gstin: false, caseId: false, label: false, amount: false, inceptionForum: false, amountInLakhs: false, inceptionDate: false };
  state: any;
  gstinDiv: any;
  division: any;
  selectedState: any;
  selectedDiv: any;
  openCaseRef!: NgbModalRef;
  openCaseData: any;
  gstinDivList: any;
  navContext: any;
  pan: any;
  entityType: any;
  currentIssueLabel: any;
  rowDivCountTotal: any;
  rowDivDemandTotal: any;
  amountMetric: 'lakh' | 'crore' = 'lakh';
  companyID = '';
  userId = '';
  stateNames = STATE_LIST.map((state) => ({id: state.id, stateName: state.stateName}));
  currentPage: number = 0; 
  pageSize: number = 1000;
  data: any[] = [];
  filters: { [key: string]: Filters } = this.initFilters();
  uniqueGstinDivList: any[] = [];
  colors: { backgroundColor?: string[] }[] = [];
  legends: Legends[] = [];
  legend: LegendData[] = [];
  labels: string[] = [];
  values: number[] = [];
  dropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'stateName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
  };

  dropdownSettings1 = {
    singleSelection: false,
    idField: 'id',
    textField: 'name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
  };
  IssueWiseDemandBarColors = ["#e51c23", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#5677fc", "#03a9f4", "#00bcd4",
    "#009688", "#259b24", "#8bc34a", "#afb42b","#ff9800", "#ff5722", "#795548", "#607d8b"]

    issueCount: any =[];
    issueDetails: any;
    iwdlegend: LegendData[] = [];
    iwdIssueDetailsData: any = [];
    iwdIssueDetailsDataSorted: any = [];
    issueWisePieChartLabel: string[] = [];
    iwdInPieChartData!: ChartData<'pie', number[], string | string[]>;
    iwdInPeiChartType: ChartType = 'doughnut';
    iwdInPieChartOptions: ChartOptions = {};
    iwdInPieChartLabels: any = [];

  constructor(
    private litService: LitigationServiceService,
    private modalService: NgbModal,
    private router: Router,
    private toaster: ToasterService,
    private excelService: ExcelService,
    private loaderService: LoaderService,
    private businessSerivce: BusinessService,
    private commonServ: CommonService,
    private getterSetter: GetterSetterService,

  ) { }

  ngOnInit() {
    this.filters = <any>JSON.parse(sessionStorage.getItem('analyticsFilter') || '{}');
    if ((this.filters && Object.keys(this.filters).length == 0) || !this.filters) {
      this.filters = this.initFilters();
    }
    this.companyID = <string>localStorage.getItem('selectedId');
    this.entityType = <string>localStorage.getItem('entityType');
    this.userId = <string>sessionStorage.getItem('UserId');
    this.navContext = this.commonServ.getNavContext();
    this.pan = this.navContext.pan;
    this.entityType = this.navContext.entityType;
    this.getagingInData();
    this.getAnalyticData();
    // this.getagingInStateDivData();
    this.stateList = STATE_LIST;
    this.gstinDivData();
    this.loaderService.show();

  }
  

  initFilters(): { [key: string]: Filters } {
    return {
      state: { p: 'state', o: 'in', v: null },
      gstinDiv: { p: 'gstinDiv', o: 'in', v: null },
    }
  }

  getFilters() { 
    let arrReturn=this.initFilters(); 
    sessionStorage.setItem('analyticsFilter', JSON.stringify(this.filters));
      if(Array.isArray(this.filters?.state?.v)){
        if (this.filters?.state?.v.length > 0){
        let arr:any=[];
        this.filters?.state?.v.forEach((element:any) => {
           arr.push(element.stateName);
         });
         if(arr.length>0){
          arrReturn.state.v= arr.join(',');
          this.selectedState = arrReturn.state.v;
         }
      }else{
        this.clearFilter('state')
      }
   console.log(this.selectedState,"this.selectedState");
      }
    
     if(Array.isArray(this.filters?.gstinDiv?.v)){
      if (this.filters?.gstinDiv?.v.length > 0){
        let arr:any=[];
        this.filters?.gstinDiv?.v.forEach((element:any) => {
           arr.push(element.name);
         });
         if(arr.length>0){
          arrReturn.gstinDiv.v= arr.join(',');
         }
      }else{
        this.clearFilter('gstinDiv')
      }
    }
    return Object.values(arrReturn).filter((el) => !!el?.v);
  }

  onDTtemSelect(){
    this.getAnalyticData();
    this.getagingInData();
  }
  getAnalyticData() {
    const navContext = JSON.parse(this.getterSetter.getNavContextData() || '{}');
    let model = {
    criterias: this.getFilters(),
    page: 0,
    size: 1000,
    sortfield: "createdOn",
    sortdir: "ASC",
      companyId: this.companyID,
      pan: this.navContext.entityType === 'LEGAL' ? this.pan : null
    }
    this.litService.getRWAnalyticData(model).subscribe(
      (response: any) => {
        if (response != null) {
          this.RWAnalytic = response.response.divSummary;
          this.divSumColTotal = response.response.phaseSummary;
          this.divSumColTotalSum = this.divSumColTotal.phaseSummary;
          this.liabilityTotal = response.response.liabilityTotals
          this.issueDetails = response.response.issueDetails;
          var rowsTitle = Object.keys(this.RWAnalytic); // region stored in rowsTitle
          this.regiondata = [];
          this.getDivisoinColData(this.RWAnalytic);
          this.paymentCumlData = response.response.liabilitySummary;
          this.getPaymentModeData(this.paymentCumlData);

          //Region Wise Analytics
          //Transpose presentation
          this.transposeData = response.response.divSummaryTranspose;
          this.transposeDataType = Object.keys(this.transposeData ?? {});
          var maxdatacount = 0;
          var maxdatakeyarr: any[] = [];
          for (var i = 0; i < this.transposeDataType.length; i++) {
            var data = this.transposeData[this.transposeDataType[i]];
            if (maxdatacount < Object.keys(data).length) {
              maxdatacount = Object.keys(data).length;
              maxdatakeyarr = Object.keys(data);
            }
          }
          var multibarchartdata = [];
          for (var j = 0; j < maxdatakeyarr.length; j++) {
            var regiondata = [];
            for (var i = 0; i < this.transposeDataType.length; i++) {
              var tempData = this.transposeData[this.transposeDataType[i]];
              var regionlabel = maxdatakeyarr[j];
              if (tempData.hasOwnProperty(maxdatakeyarr[j]))
                regiondata.push(tempData[maxdatakeyarr[j]]);
              else
                regiondata.push(0);
            }
            multibarchartdata.push({ label: regionlabel, data: regiondata });
          }
          this.transBbarChartData = multibarchartdata;
          this.transBarChartOptions = {
            responsive: true,
            scales: {
              x: {
                title: { display: true, text: 'Forum' },
                display: true,
                position: 'bottom',
                ticks: {
                  autoSkip: false,
                  maxRotation: 90,
                  minRotation: 90
                }
              }, 
              y: {
                title: { display: true, text: 'Demand (₹) in Lakhs' }
              }
            },
            maintainAspectRatio: true,
          };
          this.transBarChartLabels = this.transposeDataType;
          this.transBarChartColors = [
            {
              backgroundColor: '#00bcd4',
              borderColor: "#00bcd4",
            }, {
              backgroundColor: '#009688',
              borderColor: "#009688"
            }, {
              backgroundColor: '#795548',
              borderColor: "#795548"
            }, {
              backgroundColor: '#607d8b',
              borderColor: "#607d8b"
            }, {
              backgroundColor: '#3f51b5',
              borderColor: "#3f51b5"
            }, {
              backgroundColor: '#afb42b',
              borderColor: "#afb42b"
            }
          ]
          //Transpose presentation
          //litigation aging analysis
          this.laaPieChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: { legend: { display: false }, tooltip: { enabled: true, mode: 'nearest' } },
          };
          let value:any=[];
          let colors: string[] = [];

          this.AgeingSummaryData = response.response.ageingSummary;
          this.ageingPieChartLabel = Object.keys(this.AgeingSummaryData);

          for (var i = 0; i < this.ageingPieChartLabel.length; i++) {
            var tempData = this.AgeingSummaryData[this.ageingPieChartLabel[i]];
            value.push(tempData.count);
            this.ageingSummary = tempData.ageingSummary;
          }
          
          this.laaPieChartLabels = this.ageingPieChartLabel;
          this.laaPieChartData = { labels: this.laaPieChartLabels, datasets: [{data: value,backgroundColor: colors }] };
          this.laaPieChartLabels.forEach((labels: string) => {
            colors.push(labels.toColor(colors));
          });
      
          this.colors = [{ backgroundColor: colors }];
          const totalValue = _.sum(value);
          this.legend = this.ageingPieChartLabel.map((el, index) => {
            return {
              label: el,
              value: value[index],
              color: colors[index],
              percent: ((value[index] / totalValue) * 100).toFixed(1)
            };
          });          

          //litigation aging analysis

          //List of Issues    

           if(this.isinCR === true){
            this.ListOfIssueInCr = response.response.issueSummaryInCr;
            this.issueChartLabel = Object.keys(this.ListOfIssueInCr);
            this.issueChartData = Object.values(this.ListOfIssueInCr);
  
            this.listIssueBarChartOptions = {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  title: { display: true, text: 'Issues' },
                  ticks: { autoSkip: false, maxRotation: 90, minRotation: 90},
                },
                y: { title: { display: true, text: 'Demand (₹) in Crores' } }
              },
              plugins: { legend: { display: false } }
            };
  
            this.listIssueBarChartLabels = this.issueChartLabel;
            this.listIssueBbarChartData = { 
              labels: this.listIssueBarChartLabels, 
              datasets: [{ data: this.issueChartData, backgroundColor: this.IssueWiseDemandBarColors }] 
            };
  
            this.listIssueBarChartColors = [
              {
                backgroundColor: '#00bcd4',
                borderColor: "#00bcd4"
              }
            ]
           }
           else{
            this.ListOfIssue = response.response.issueSummaryInLkhs;
            this.issueChartLabel = Object.keys(this.ListOfIssue);
            this.issueChartData = Object.values(this.ListOfIssue);
  
            this.listIssueBarChartOptions = {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  title: { display: true, text: 'Issues' },
                  ticks: { autoSkip: false, maxRotation: 90, minRotation: 90 }
                },
                y: { title: { display: true, text: 'Demand (₹) in Lakhs' } }
              },
              plugins: { legend: { display: false } }
            };
  
            this.listIssueBarChartLabels = this.issueChartLabel;
            this.listIssueBbarChartData = { 
              labels: this.listIssueBarChartLabels, 
              datasets: [{ data: this.issueChartData, backgroundColor: this.IssueWiseDemandBarColors }] 
            };
  
            this.listIssueBarChartColors = [
              {
                backgroundColor: '#00bcd4',
                borderColor: "#00bcd4"
              }
            ]
           }

           //issue wise demand
           this.iwdInPieChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: { 
              legend: { display: false }, 
              tooltip: { 
                enabled: true, 
                mode: 'nearest',
                callbacks: {
                  label: (tooltipItem) => {
                    const dataset = tooltipItem.dataset.data;
                    const index = tooltipItem.dataIndex;
                    const label = this.iwdInPieChartLabels[index];
                    const amountMetric = this.amountMetric === 'crore' ? 'amountInCr' : 'amount';
                    const amount = this.iwdIssueDetailsDataSorted[label][amountMetric]; // Use dynamic metric
                    const unit = this.amountMetric === 'crore' ? 'Cr' : 'L';
                    return `Demand = ${amount} ${unit}`;
                  },
                }
              } 
            },
          };


          let iwdvalue:any=[];
          let iwdcolors: string[] = [];
          this.iwdIssueDetailsData = response.response.issueDetailsPieChart;

          const isCrore = this.amountMetric === 'crore';
          const sortedEntries = Object.entries(this.iwdIssueDetailsData).sort(
            (a: any, b: any) => {
              const amountA = isCrore ? a[1].amountInCr : a[1].amount;
              const amountB = isCrore ? b[1].amountInCr : b[1].amount;
              return amountB - amountA;
            }
          );
          this.iwdIssueDetailsDataSorted = Object.fromEntries(sortedEntries);
          this.issueWisePieChartLabel = Object.keys(this.iwdIssueDetailsDataSorted);

          for (let i = 0; i < this.issueWisePieChartLabel.length; i++) {
            const label = this.issueWisePieChartLabel[i];
            const tempData = this.iwdIssueDetailsDataSorted[label];
            iwdvalue.push(tempData.amount);
          }

          this.iwdInPieChartLabels = this.issueWisePieChartLabel;
          this.iwdInPieChartData = { labels: this.iwdInPieChartLabels, datasets: [{data: iwdvalue,backgroundColor: iwdcolors }] };
          this.iwdInPieChartLabels.forEach((labels: string) => {
            iwdcolors.push(labels.toColor(iwdcolors));
          });
     
          //List of Issues
          //CONTIGENT LIABILITY REPORT
          this.contLiabilityReport = response.response.contigencySummary;
          this.getCLColData(response.response.contigencySummary);
          //CONTIGENT LIABILITY REPORT

        }
      }, (err) => {
        this.loaderService.hide();
      });
  } 


  demoFunc(val1:any, val2: any): string  {
    alert(val1 +" "+ val2);

    return "test";
  }
  

  //Region Wise Analytics
  //aging inception
  getagingInData() {
    let model: any = {};
    model.criterias = this.getFilters(),
    model.page= 0,
    model.size= 1000,
    model.sortfield= "createdOn",
    model.sortdir= "ASC",
    model.companyId = this.companyID,
    model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : null
    this.litService.getagingInceptionData(model).subscribe((response: any) => {
      if (response != null) {
        let value:any=[];
        let colors: string[] = [];
        this.AgeingInSummaryData = response.response;
        this.ageingInPieChartLabel = Object.keys(this.AgeingInSummaryData);
        for (var i = 0; i < this.ageingInPieChartLabel.length; i++) {
          var tempData = this.AgeingInSummaryData[this.ageingInPieChartLabel[i]];
          value.push(tempData.count);
          this.ageingInSummary = tempData.ageingSummary;

        }
        this.laaInPieChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: { legend: { display: false }, tooltip: { enabled: true, mode: 'nearest' } },
        };
    
        this.laaInPieChartLabels = this.ageingInPieChartLabel;
        this.laaInPieChartData = { labels: this.laaInPieChartLabels, datasets: [{data: value,backgroundColor: colors }] };
        this.laaInPieChartLabels.forEach((labels: string) => {
          colors.push(labels.toColor(colors));
        });
        this.colors = [{ backgroundColor: colors }];
        const totalValue = _.sum(value);

        this.legends = this.ageingInPieChartLabel.map((el, index) => {
          return {
            label: el,
            value: value[index],
            color: colors[index],
            percent: ((value[index] / totalValue) * 100).toFixed(1)
          };
        }); 


        //litigation aging analysis

      }
    })
  }
  getagingInStateDivData() {
    let model = {
      criterias: this.getFilters(),
       page: 0,
      size: 1000,
      sortfield: "createdOn",
      sortdir: "ASC",
      companyId: this.companyID,
      pan: this.navContext.entityType === 'LEGAL' ? this.pan : null
    }
    this.litService.agingInStateDivData(model).subscribe((response: any) => {
      if (response != null) {
        this.AgeingInSummaryData = response.response;
        this.ageingInPieChartLabel = Object.keys(this.AgeingInSummaryData);
        var countArray = [];
        for (var i = 0; i < this.ageingInPieChartLabel.length; i++) {
          var tempData = this.AgeingInSummaryData[this.ageingInPieChartLabel[i]];
          countArray.push(tempData.count);
          this.ageingInSummary = tempData.ageingSummary;

        }
        this.laaInPieChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            // labels: {
            //   render: 'value'
            // },
            legend: { position: 'right' },
            tooltip: { enabled: true, mode: 'nearest' },
          },
        };
        this.laaInPieChartLabels = this.ageingInPieChartLabel;
        //this.laaInPieChartData = countArray;
        this.laaInPieChartData = { labels: this.laaInPieChartLabels, datasets: [{data: countArray}] };
        this.laaInPieChartColors = [
          {
            //backgroundColor: ['#2581c5', '#48d257', '#f6c21a', '#f58913', '#4948cc', '#b5b3b3'],
            // backgroundColor: ['#087168', '#0f978b', '#17c8b9', '#48d7cb', '#73d9d0', '#a7e9e3'],
            backgroundColor: ['#e51c23', '#ff5722', '#c6d0d5', '#8bc34a', '#9c27b0'],
          },
        ];

        //litigation aging analysis

      }
    })
  }
 
  
  //aging inception
  getDivisoinColData(RWAnalytic: any) {
    var regionKeys = Object.keys(RWAnalytic);
    this.regiondata = [];
    for (var i = 0; i < regionKeys.length; i++) {
      let regiondata = RWAnalytic[regionKeys[i]];
      let row: any = {
        division: regionKeys[i],
        phases: regiondata.phases,
        totalCases: regiondata.totalCases,
        totalDivision: regiondata.totalDivision,
        totalDivisionInCr: regiondata.totalDivisionInCr
      };
      let gstinDataArray = [];
      for (let gstin in regiondata.gstins) {
        let rawGstinData = regiondata.gstins[gstin];
        let gstinData = {
          gstin: gstin,
          phaseSummary: rawGstinData.phaseSummary,
          totalNotices: rawGstinData.totalNotices,
          totalAmount: rawGstinData.totalAmount,
          totalAmountInCr: rawGstinData.totalAmountInCr
        }
        gstinDataArray.push(gstinData);
      }
      row.gstinData = gstinDataArray;
      this.regiondata.push(row);


    }
    this.regiondata = this.commonServ.sortObjectsByKey(this.regiondata, 'division');
    if (this.regiondata.length > 0) {
      this.regiondata.forEach((element: any) => {
        element.expanded = false;

      });
    }
  }

  exportAsXLSX() {
    if (this.regiondata.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    if (this.isinCR === true) {
      const data = this.regiondata.flatMap((region: any) => {
        const { phases } = region;
        let finalData = [];
        const transformedValues = {
          "Division": region.division,
          "Pre-Litigation - Open Cases": phases?.scn?.count,
          "Pre-Litigation - Demand (₹)": phases?.scn?.totalInCr,
          "Adjudication - Open Cases": phases?.aja?.count,
          "Adjudication - Demand (₹)": phases?.aja?.totalInCr,
          "Commissioner (A) - Open Cases": phases?.apa?.count,
          "Commissioner (A) - Demand (₹)": phases?.apa?.totalInCr,
          "Appellate Tribunal - Open Cases": phases?.apt?.count,
          "Appellate Tribunal - Demand (₹)": phases?.apt?.totalInCr,
          "High Court - Open Cases": phases?.hc?.count,
          "High Court - Demand (₹)": phases?.hc?.totalInCr,
          "Supreme Court - Open Cases": phases?.sc?.count,
          "Supreme Court - Demand (₹)": phases?.sc?.totalInCr,
          "Total - Open Cases": region.totalCases,
          "Total - Demand (₹)": region.totalDivisionInCr,
        };
        finalData.push(transformedValues)

        if (region.gstinData.length > 0) {
          region.gstinData.forEach((subRow: any) => {
            let tempVar = {
              "Division": `  ${this.getStateRTOCodeFromGSTIN(subRow.gstin)} - ${subRow.gstin}`,
              "Pre-Litigation - Open Cases": subRow?.phaseSummary?.scn?.numberOfNotices,
              "Pre-Litigation - Demand (₹)": subRow?.phaseSummary?.scn?.amounInCr,
              "Adjudication - Open Cases": subRow?.phaseSummary?.aja?.numberOfNotices,
              "Adjudication - Demand (₹)": subRow?.phaseSummary?.aja?.amounInCr,
              "Commissioner (A) - Open Cases": subRow?.phaseSummary?.apa?.numberOfNotices,
              "Commissioner (A) - Demand (₹)": subRow?.phaseSummary?.apa?.amounInCr,
              "Appellate Tribunal - Open Cases": subRow?.phaseSummary?.apt?.numberOfNotices,
              "Appellate Tribunal - Demand (₹)": subRow?.phaseSummary?.apt?.amounInCr,
              "High Court - Open Cases": subRow?.phaseSummary?.hc?.numberOfNotices,
              "High Court - Demand (₹)": subRow?.phaseSummary?.hc?.amounInCr,
              "Supreme Court - Open Cases": subRow?.phaseSummary?.sc?.numberOfNotices,
              "Supreme Court - Demand (₹)": subRow?.phaseSummary?.sc?.amounInCr,
              "Total - Open Cases": subRow.totalNotices,
              "Total - Demand (₹)": subRow.totalAmount,
            }
            finalData.push(tempVar)
          })
        }
        return finalData;
      });

      const totalRow = {
        "Division": "Total",
        "Pre-Litigation - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.scn?.count || 0), 0),
        "Pre-Litigation - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { scn: { totalInCr: any; }; }; }) => acc + (region.phases?.scn?.totalInCr ?? 0), 0),
        "Adjudication - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.aja?.count || 0), 0),
        "Adjudication - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { aja: { totalInCr: any; }; }; }) => acc + (region.phases?.aja?.totalInCr ?? 0), 0),
        "Commissioner (A) - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.apa?.count || 0), 0),
        "Commissioner (A) - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { apa: { totalInCr: any; }; }; }) => acc + (region.phases?.apa?.totalInCr ?? 0), 0),
        "Appellate Tribunal - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.apt?.count || 0), 0),
        "Appellate Tribunal - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { apt: { totalInCr: any; }; }; }) => acc + (region.phases?.apt?.totalInCr ?? 0), 0),
        "High Court - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.hc?.count || 0), 0),
        "High Court - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { hc: { totalInCr: any; }; }; }) => acc + (region.phases?.hc?.totalInCr ?? 0), 0),
        "Supreme Court - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.sc?.count || 0), 0),
        "Supreme Court - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { sc: { totalInCr: any; }; }; }) => acc + (region.phases?.sc?.totalInCr ?? 0), 0),
        "Total - Open Cases": this.regiondata.reduce((acc: any, region: { totalCases: any; }) => acc + region.totalCases, 0),
        "Total - Demand (₹)": this.regiondata.reduce((acc: any, region: { totalDivisionInCr: any; }) => acc + region.totalDivisionInCr, 0),
      };
      data.push(totalRow);
      this.excelService.exportAsExcelFile(data, 'Division Wise Demand Analytics');
    }
    else {


      const data = this.regiondata.flatMap((region: any) => {
        const { phases } = region;
        let finalData = [];
        const transformedValues = {
          "Division": region.division,
          "Pre-Litigation - Open Cases": phases?.scn?.count,
          "Pre-Litigation - Demand (₹)": phases?.scn?.total,
          "Adjudication - Open Cases": phases?.aja?.count,
          "Adjudication - Demand (₹)": phases?.aja?.total,
          "Commissioner (A) - Open Cases": phases?.apa?.count,
          "Commissioner (A) - Demand (₹)": phases?.apa?.total,
          "Appellate Tribunal - Open Cases": phases?.apt?.count,
          "Appellate Tribunal - Demand (₹)": phases?.apt?.total,
          "High Court - Open Cases": phases?.hc?.count,
          "High Court - Demand (₹)": phases?.hc?.total,
          "Supreme Court - Open Cases": phases?.sc?.count,
          "Supreme Court - Demand (₹)": phases?.sc?.total,
          "Total - Open Cases": region.totalCases,
          "Total - Demand (₹)": region.totalDivision,
        };
        finalData.push(transformedValues)

        if (region.gstinData.length > 0) {
          region.gstinData.forEach((subRow: any) => {
            let tempVar = {
              "Division": `  ${this.getStateRTOCodeFromGSTIN(subRow.gstin)} - ${subRow.gstin}`,
              "Pre-Litigation - Open Cases": subRow?.phaseSummary?.scn?.numberOfNotices,
              "Pre-Litigation - Demand (₹)": subRow?.phaseSummary?.scn?.amount,
              "Adjudication - Open Cases": subRow?.phaseSummary?.aja?.numberOfNotices,
              "Adjudication - Demand (₹)": subRow?.phaseSummary?.aja?.amount,
              "Commissioner (A) - Open Cases": subRow?.phaseSummary?.apa?.numberOfNotices,
              "Commissioner (A) - Demand (₹)": subRow?.phaseSummary?.apa?.amount,
              "Appellate Tribunal - Open Cases": subRow?.phaseSummary?.apt?.numberOfNotices,
              "Appellate Tribunal - Demand (₹)": subRow?.phaseSummary?.apt?.amount,
              "High Court - Open Cases": subRow?.phaseSummary?.hc?.numberOfNotices,
              "High Court - Demand (₹)": subRow?.phaseSummary?.hc?.amount,
              "Supreme Court - Open Cases": subRow?.phaseSummary?.sc?.numberOfNotices,
              "Supreme Court - Demand (₹)": subRow?.phaseSummary?.sc?.amount,
              "Total - Open Cases": subRow.totalNotices,
              "Total - Demand (₹)": subRow.totalAmount,
            }
            finalData.push(tempVar)
          })
        }
        return finalData;
      });

      const totalRow = {
        "Division": "Total",
        "Pre-Litigation - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.scn?.count || 0), 0),
        "Pre-Litigation - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { scn: { total: any; }; }; }) => acc + (region.phases?.scn?.total ?? 0), 0),
        "Adjudication - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.aja?.count || 0), 0),
        "Adjudication - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { aja: { total: any; }; }; }) => acc + (region.phases?.aja?.total ?? 0), 0),
        "Commissioner (A) - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.apa?.count || 0), 0),
        "Commissioner (A) - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { apa: { total: any; }; }; }) => acc + (region.phases?.apa?.total ?? 0), 0),
        "Appellate Tribunal - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.apt?.count || 0), 0),
        "Appellate Tribunal - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { apt: { total: any; }; }; }) => acc + (region.phases?.apt?.total ?? 0), 0),
        "High Court - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.hc?.count || 0), 0),
        "High Court - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { hc: { total: any; }; }; }) => acc + (region.phases?.hc?.total ?? 0), 0),
        "Supreme Court - Open Cases": this.regiondata.reduce((acc: any, region: { phases: any; }) => acc + (region.phases?.sc?.count || 0), 0),
        "Supreme Court - Demand (₹)": this.regiondata.reduce((acc: any, region: { phases: { sc: { total: any; }; }; }) => acc + (region.phases?.sc?.total ?? 0), 0),
        "Total - Open Cases": this.regiondata.reduce((acc: any, region: { totalCases: any; }) => acc + region.totalCases, 0),
        "Total - Demand (₹)": this.regiondata.reduce((acc: any, region: { totalDivision: any; }) => acc + region.totalDivision, 0),
      };
      data.push(totalRow);
      this.excelService.exportAsExcelFile(data, 'Division Wise Demand Analytics');
    }
  }

  issueWiseExportToExcel(){
    const payload = {
      criterias: this.getFilters(),
      page: 0,
      size: 1000,
      sortfield: "createdOn",
      sortdir: "DESC",
      companyId: this.companyID,
      pan: this.navContext.entityType === 'LEGAL' ? this.pan : null,
      isLakhs: this.isinLakh
    }
    this.litService.issueExportToExcel(payload).subscribe((res: any) => {
      const blobUrl = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = blobUrl;
      if(this.isinLakh){
        link.download = 'Issue Wise Demand Analytics (INR LAKHS).xlsx';
      }else{
        link.download = 'Issue Wise Demand Analytics (INR CRORES).xlsx';
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    })
  }

  getPaymentModeData(paymentCumlData: any) {
    var regionKeys = Object.keys(paymentCumlData);
    this.paymentRegiondata = [];
    for (var i = 0; i < regionKeys.length; i++) {
      let paymentRegiondata = paymentCumlData[regionKeys[i]];
      let row: any = {
        division: regionKeys[i],
        demand: paymentRegiondata.demand,
        cPredeposit:paymentRegiondata.cPredeposit,
        aLiability:paymentRegiondata.aLiability,
        pAmount:paymentRegiondata.pAmount,

        demandInCr: paymentRegiondata.demandInCr,
        cPredepositInCr:paymentRegiondata.cPredepositInCr,
        aLiabilityInCr:paymentRegiondata.aLiabilityInCr,
        pAmountInCr:paymentRegiondata.pAmountInCr
      };
      let paymentGstinDataArray = [];
      for (let gstin in paymentRegiondata.gstinsMap) {
        let rawGstinData = paymentRegiondata.gstinsMap[gstin];
        let paymentGstinData = {
          gstin: gstin,
          rowSummary: rawGstinData,
        }
        paymentGstinDataArray.push(paymentGstinData);
      }
      row.paymentGstinData = paymentGstinDataArray;
      this.paymentRegiondata.push(row);


    }
    this.paymentRegiondata = this.commonServ.sortObjectsByKey(this.paymentRegiondata, 'division');
    if (this.paymentRegiondata.length > 0) {
      this.paymentRegiondata.forEach((element: any) => {
        element.expanded = false;

      });
    }
  }
  getRegionClass(item: any) {
    if (item && item.toString().indexOf('|') >= 0) {
      return item.split('|')[1];
    } else {
      return '';
    }
  }
  expandRegion(item: any) {
    if (item && item.toString().indexOf('|') >= 0) {
      this.selectedClass = '';
    } else {
      if (this.selectedClass == item)
        this.selectedClass = '';
      else
        this.selectedClass = item;
    }
  }
  isHiddenRow(firstObj: any) {
    if (firstObj.indexOf('|') >= 0) {
      if (this.selectedClass == firstObj.split('|')[1])
        return false;
      else
        return true;
    }
    return false;
  }
  splitGstin(item: any) {
    if (item.toString().indexOf('|') >= 0) {
      return item.split('|')[0];
    } else {
      return item;
    }
  }
  isExpand(firstObj: any) {
    if (firstObj.indexOf('|') == -1) {
      if (this.selectedClass == '') {
        return false;
      } else if (this.selectedClass == firstObj) {
        return true;
      }
    }
    return false;
  }
  //Region Wise Analytics



  openPieChart(content: any, e: any) {
    const index = e.active?.[0]?.index ?? -1;
    this.currentLabel = e.event.chart?.data?.labels?.[index] ?? '';

    this.agingAnalysisRef = this.modalService.open(content, { windowClass: 'analytics-pop' });
    var agingSummaryData = this.AgeingSummaryData[this.currentLabel].ageingSummary;
    this.ageingPopSummary = agingSummaryData;
    this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'state');
    this.ageingPopSummary.forEach((x: any) => {
      if (x.type == "scn") {
        x.type = 'Pre-Litigation';
      } else if (x.type == "aja") {
        x.type = 'Adjudication';
      } else if (x.type == "apa") {
        x.type = 'Commissioner (A)';
      } else if (x.type == "apt") {
        x.type = 'Appellate Tribunal';
      } else if (x.type == "hc") {
        x.type = 'High Court';
      } else if (x.type == "sc") {
        x.type = 'Supreme  Court';
      }
    });
  }

  openInceptPieChart(content: any, e: any) {
    const index = e.active?.[0]?.index ?? -1;
    this.currentLabel = e.event.chart?.data?.labels?.[index] ?? '';

    this.agingInAnalysisRef = this.modalService.open(content, { windowClass: 'analytics-pop' });
    var agingInSummaryData = this.AgeingInSummaryData[this.currentLabel].ageingSummary;
    this.ageingInPopSummary = agingInSummaryData;
    this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'state');
    this.ageingInPopSummary.forEach((x: any) => {
     if (x.currentForum == "scn") {
        x.currentForum = 'Pre-Litigation';
      } else if (x.currentForum == "aja") {
        x.currentForum = 'Adjudication';
      } else if (x.currentForum == "apa") {
        x.currentForum = 'Commissioner (A)';
      } else if (x.currentForum == "apt") {
        x.currentForum = 'Appellate Tribunal';
      } else if (x.currentForum == "hc") {
        x.currentForum = 'High Court';
      } else if (x.currentForum == "sc") {
        x.currentForum = 'Supreme Court';
      }
    });

    this.ageingInPopSummary.forEach((x: any) => {
      if (x.inceptionForum == "scn") {
        x.inceptionForum = 'Pre-Litigation';
      } else if (x.inceptionForum == "aja") {
        x.inceptionForum = 'Adjudication';
      } else if (x.inceptionForum == "apa") {
        x.inceptionForum = 'Commissioner (A)';
      } else if (x.inceptionForum == "apt") {
        x.inceptionForum = 'Appellate Tribunal';
      } else if (x.inceptionForum == "hc") {
        x.inceptionForum = 'High Court';
      } else if (x.inceptionForum == "sc") {
        x.inceptionForum = 'Supreme Court';
      }
    });
  }


  //Demand amount analysis
  daaBarChartOptions: ChartOptions = {
    responsive: true,
    scales: { x: {}, y: {} },
  };

  daaBarChartType: ChartType = 'bar';
  daaBarChartLegend = true;
  daaBarChartPlugins = [];

  daaBarChartColors: any[] = [
    {
      backgroundColor: '#00bcd4',
      borderColor: "#00bcd4"
    },
    {
      backgroundColor: '#009688',
      borderColor: "#009688"
    }
  ]
  //Demand amount analysis
  //CONTIGENT LIABILITY REPORT
  getCLColData(contLiabilityReport: any) {
    var order = ['caseId', 'statute', 'forum', 'year', 'details'];

    var gstinsKeys = Object.keys(contLiabilityReport);
    this.conttabledata = [];
    for (var i = 0; i < gstinsKeys.length; i++) {
      var gstindata = contLiabilityReport[gstinsKeys[i]];
      var selectedgstin = gstinsKeys[i];
      for (var j = 0; j < gstindata.length; j++) {
        gstindata[j].gstinNo = selectedgstin;
        this.conttabledata.push(gstindata[j]);
      }

    }
  }
  isArray(item: any) {
    return Array.isArray(item);
  }

  openCaseReport(content: any, obj: any) {
    this.detaildata = obj.details || [];
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {

    });
  }

  //CONTIGENT LIABILITY REPORT
  getIssueLabel(content:any, label: string, e: any) {
    const index = e.active?.[0]?.index ?? -1;
    this.currentIssueLabel = e.event.chart?.data?.labels?.[index] ?? '';

    console.log(this.currentIssueLabel, e.event.chart);

    this.openCaseData = [];

    if(this.issueDetails)
    {
        let issueList = this.issueDetails[this.currentIssueLabel];
        if(!issueList?.issueName){
          return;
        }
        this.openCaseRef = this.modalService.open(content, { windowClass: 'analytics-pop' });
        let keys = Object.keys(issueList.issueName);
        keys.forEach((item:any) => {
          let obj = issueList.issueName[item];

          if (obj.forum == "Pre-Litigation") {
            obj.type = 'scn';
          } else if (obj.forum =="Adjudication") {
            obj.type = 'aja';
          } else if (obj.forum == "Commissioner(A)") {
            obj.type = 'apa';
          } else if (obj.forum == "Appellate Tribunal") {
            obj.type = 'apt';
          } else if (obj.forum == "High Court") {
            obj.type = 'hc';
          } else if (obj.forum =="Supreme Court") {
            obj.type = 'sc';
          }
         obj.amountInCr = obj.amountInCrores;
          this.openCaseData.push(obj);
        });

    }

    //this.router.navigate(['/litigationSummary']);
    localStorage.setItem('analyticIssueLbl', this.currentIssueLabel);
  }

  getagingCaseData(caseId: number, action: string, type: string, tab: string) {
    this.router.navigate(['/notice', caseId, { action: action, type: type, tab: tab }]);
    this.agingAnalysisRef.close();

  }
  getagingInCaseData(caseId: number, action: string, type: string, tab: string) {
    this.router.navigate(['/notice', caseId, { action: action, type: type, tab: tab }]);
    this.agingInAnalysisRef.close();

  }
  setState() {
    this.getagingInData();
    this.getAnalyticData();
    this.relopageRefresh();
  }
  setDiv() {
      this.getagingInData();
      this.getAnalyticData();
      this.relopageRefresh();
  }

    relopageRefresh() {
      if (this.router.url.includes('litigationAnalytics')) {
        this.commonServ.reloadCurrentRoute();
      }
    }


  clearFilter(inputName: string) {
    switch (inputName) {
      case 'state':
        this.filters.state.v = null;
        break;
      case 'gstinDiv':
        this.filters.gstinDiv.v = null;
        break;
      default:
        break;
    }  
    this.getAnalyticData();
    this.getagingInData();
      
  }
 
  gstinDivData() {
    this.businessSerivce.getDivisionMapping(this.companyID, this.userId, this.currentPage, this.pageSize).subscribe({
      next: (res: any) => {
        if (res.response) {
          this.data = res.response.list.map((user: { gstinDiv: any; }) => user.gstinDiv);
          this.gstinDivList = this.data;
          this.uniqueGstinDivList = Array.from(new Set(this.gstinDivList.filter((gstinDivList: undefined) => gstinDivList !== undefined)));
         
          this.gstinDivList = this.uniqueGstinDivList.map((div,index) => ({
          id: index + 1,
          name:div
          }));
          console.log(this.gstinDivList,"this.data")
        }
      }
    });
  }
  openCase(content: any, division: string, stage: string, gstin: string) {
    this.openCaseRef = this.modalService.open(content, { windowClass: 'analytics-pop' });
    let model: any = {};
     if (gstin && division) {
      model.stage = stage,
        model.criterias = [{
          "p": "gstin",
          "o": "in",
          "v":  gstin
        }, {
          "p": "gstinDiv",
          "o": "in",
          "v": division
        }
      ],
        model.page= 0,
         model.size= 1000,
         model.sortdir= "ASC",
         model.sortfield ="createdOn",
         model.companyId = this.companyID,
        model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    } else if (division) {
      model.stage = stage,
        model.criterias = [{
          "p": "gstinDiv",
          "o": "in",
          "v": division
        }],
        model.page= 0,
         model.size= 1000,
         model.sortdir= "ASC",
         model.sortfield ="createdOn",
         model.companyId = this.companyID,
        model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    } 
   
    this.litService.getOpenCase(model).subscribe((response: any) => {
      if (response != null) {
        this.openCaseData = response.response;
        this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'amount', 'desc');

      }
    })

  }
  openCaseState(content: any, division: string, stage: string, state: string) {
    this.openCaseRef = this.modalService.open(content, { windowClass: 'analytics-pop' });
    let model: any = {};
    if (this.filters?.state?.v) {
      model.stage = stage,
      model.criterias = [{
        "p": "gstinDiv",
        "o": "in",
        "v": division
      },
      {
        "p": "state",
        "o": "in",
        "v":  this.selectedState
    }],
    model.page= 0,
    model.size= 1000,
    model.sortdir= "ASC",
    model.sortfield ="createdOn",
    model.companyId = this.companyID,
   model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    }else{
        model.stage = stage,
          model.criterias = [{
            "p": "gstinDiv",
            "o": "in",
            "v": division
          }],
          model.page= 0,
           model.size= 1000,
           model.sortdir= "ASC",
           model.sortfield ="createdOn",
           model.companyId = this.companyID,
          model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    }
    this.litService.getOpenCaseState(model).subscribe((response: any) => {
      if (response != null) {
        this.openCaseData = response.response;
      }
    })

  }
  allopenCaseState(content: any, division: string) {
    this.openCaseRef = this.modalService.open(content, { windowClass: 'analytics-pop' });
    let model: any = {};
    if (this.filters?.state?.v) {
      model.criterias = [{
        "p": "gstinDiv",
        "o": "in",
        "v": division
      },
      {
        "p": "state",
        "o": "in",
        "v":  this.selectedState
    }],
    model.page= 0,
    model.size= 1000,
    model.sortdir= "ASC",
    model.sortfield ="createdOn",
    model.companyId = this.companyID,
   model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    }else{
          model.criterias = [{
            "p": "gstinDiv",
            "o": "in",
            "v": division
          }],
          model.page= 0,
           model.size= 1000,
           model.sortdir= "ASC",
           model.sortfield ="createdOn",
           model.companyId = this.companyID,
          model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    }
    this.litService.getOpenCaseState(model).subscribe((response: any) => {
      if (response != null) {
        this.openCaseData = response.response;
        this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'amount', 'desc');
        this.openCaseData.forEach((x: any) => {
          if (x.type == "scn") {
            x.type = 'Pre-Litigation';
          } else if (x.type == "aja") {
            x.type = 'Adjudication';
          } else if (x.type == "apa") {
            x.type = 'Commissioner (A)';
          } else if (x.type == "apt") {
            x.type = 'Appellate Tribunal';
          } else if (x.type == "hc") {
            x.type = 'High Court';
          } else if (x.type == "sc") {
            x.type = 'Supreme  Court';
          }
        });
      }
    })

  }
  allOpenCase(content: any, gstin: string,division: string) {
    this.openCaseRef = this.modalService.open(content, { windowClass: 'analytics-pop' });
    let model: any = {};
     if (gstin && division) {
      model.criterias = [{
        "p": "gstin",
        "o": "in",
        "v": gstin
      },
      {
        "p": "gstinDiv",
        "o": "in",
        "v": division
      }
    ],
      model.page= 0,
       model.size= 1000,
       model.sortdir= "ASC",
       model.sortfield ="createdOn",
       model.companyId = this.companyID,
      model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    } else if (division) {
      model.criterias = [{
        "p": "gstinDiv",
        "o": "in",
        "v": division
      }],
      model.page= 0,
       model.size= 1000,
       model.sortdir= "ASC",
       model.sortfield ="createdOn",
       model.companyId = this.companyID,
      model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
  } 
    this.litService.getOpenCase(model).subscribe((response: any) => {
      if (response != null) {
        this.openCaseData = response.response;
        this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'amount', 'desc');
        this.openCaseData.forEach((x: any) => {
          if (x.type == "scn") {
            x.type = 'Pre-Litigation';
          } else if (x.type == "aja") {
            x.type = 'Adjudication';
          } else if (x.type == "apa") {
            x.type = 'Commissioner (A)';
          } else if (x.type == "apt") {
            x.type = 'Appellate Tribunal';
          } else if (x.type == "hc") {
            x.type = 'High Court';
          } else if (x.type == "sc") {
            x.type = 'Supreme  Court';
          }
        });
      }
    })

  }
  getOpenCase(caseId: number, action: string, type: string, tab: string) {
    this.router.navigate(['/notice', caseId, { action: action, type: type, tab: tab }]);
    this.openCaseRef.close();

  }
  // sortValue(value: number) {
  //   if (value) {
  //     return (value).toFixed(2);
  //   }
  //   return 0;
  // }
  changeStatsValue(selected: any, event: Event) {
    const isCrore = (event.target as HTMLInputElement).checked;
    this.amountMetric = isCrore ? 'crore' : 'lakh';
    if (selected == false) {
      this.isinCR = false;
      this.isinLakh = true;
    }
    else if (selected == true) {
      this.isinCR = true;
      this.isinLakh = false;
    }
    this.getAnalyticData();
  }

  sortAging(column: string) {
    switch (column) {
      case INCEPTION.DIVISION: {
        if (!this.sorting.division) {
          this.isAnalyticsConOne = true;
          this.sorting.division = true;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'division', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.division = false;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'division', 'desc');
        }
        break;
      }

      case INCEPTION.STATE: {
        if (!this.sorting.state) {
          this.isAnalyticsConOne = true;
          this.sorting.state = true;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'state', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.state = false;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'state', 'desc');
        }
        break;
      }

      case INCEPTION.FORUM: {
        if (!this.sorting.currentForum) {
          this.isAnalyticsConOne = true;
          this.sorting.currentForum = true;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'currentForum', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.currentForum = false;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'currentForum', 'desc');
        }
        break;
      }

      case INCEPTION.GSTIN: {
        if (!this.sorting.gstin) {
          this.isAnalyticsConOne = true;
          this.sorting.gstin = true;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'gstin', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.gstin = false;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'gstin', 'desc');
        }
        break;
      }

      case INCEPTION.CASE_ID: {
        if (!this.sorting.caseId) {
          this.isAnalyticsConOne = true;
          this.sorting.caseId = true;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'caseId', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.caseId = false;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'caseId', 'desc');
        }
        break;
      }

      case INCEPTION.CASE_LABEL: {
        if (!this.sorting.label) {
          this.isAnalyticsConOne = true;
          this.sorting.label = true;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'label', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.label = false;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'label', 'desc');
        }
        break;
      }

      case INCEPTION.DEMAND_AMOUNT: {
        if (!this.sorting.amountInLakhs) {
          this.isAnalyticsConOne = true;
          this.sorting.amountInLakhs = true;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'amountInLakhs', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.amountInLakhs = false;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'amountInLakhs', 'desc');
        }
        break;
      }
      case INCEPTION.INCEPTION_FORUM: {
        if (!this.sorting.inceptionForum) {
          this.isAnalyticsConOne = true;
          this.sorting.inceptionForum = true;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'inceptionForum', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.inceptionForum = false;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'inceptionForum', 'desc');
        }
        break;
      }
      case INCEPTION.INCEPTION_DATE: {
        if (!this.sorting.inceptionDate) {
          this.isAnalyticsConOne = true;
          this.sorting.inceptionDate = true;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'inceptionDate', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.inceptionDate = false;
          this.ageingInPopSummary = this.commonServ.sortObjectsByKey(this.ageingInPopSummary, 'inceptionDate', 'desc');
        }
        break;
      }
    }
  }

  sort(column: string) {
    switch (column) {

      case DIVISION.DIVISIONS: {
        if (!this.sorting.division) {
          this.isAnalyticsSort = true;
          this.sorting.division = true;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'division', 'asc');
        } else {
          this.isAnalyticsSort = false;
          this.sorting.division = false;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'division', 'desc');
        }
        break;
      }

      case DIVISION.STATE: {
        if (!this.sorting.state) {
          this.isAnalyticsSort = true;
          this.sorting.state = true;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'state', 'asc');
        } else {
          this.isAnalyticsSort = false;
          this.sorting.state = false;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'state', 'desc');
        }
        break;
      }

      case DIVISION.GSTIN: {
        if (!this.sorting.gstin) {
          this.isAnalyticsSort = true;
          this.sorting.gstin = true;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'gstin', 'asc');
        } else {
          this.isAnalyticsSort = false;
          this.sorting.gstin = false;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'gstin', 'desc');
        }
        break;
      }

      case DIVISION.CASE_ID: {
        if (!this.sorting.caseId) {
          this.isAnalyticsSort = true;
          this.sorting.caseId = true;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'caseId', 'asc');
        } else {
          this.isAnalyticsSort = false;
          this.sorting.caseId = false;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'caseId', 'desc');
        }
        break;
      }

      case DIVISION.CASE_LABEL: {
        if (!this.sorting.label) {
          this.isAnalyticsSort = true;
          this.sorting.label = true;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'label', 'asc');
        } else {
          this.isAnalyticsSort = false;
          this.sorting.label = false;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'label', 'desc');
        }
        break;
      }

      case DIVISION.DEMAND_AMOUNT: {
        if (!this.sorting.amount) {
          this.isAnalyticsSort = true;
          this.sorting.amount = true;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'amount', 'asc');
        } else {
          this.isAnalyticsSort = false;
          this.sorting.amount = false;
          this.openCaseData = this.commonServ.sortObjectsByKey(this.openCaseData, 'amount', 'desc');
        }
        break;
      }
      case DIVISION.FORUM: {
        if (!this.sorting.type) {
          this.isSortCurrentData = true;
          this.sorting.type = true;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'type', 'asc');
        } else {
          this.isSortCurrentData = false;
          this.sorting.type = false;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'type', 'desc');
        }
        break;
      }
    }
  }

  sortCurrentData(column: string) {
    switch (column) {

      case CURRENT_DATA.DIVISION: {
        if (!this.sorting.division) {
          this.isSortCurrentData = true;
          this.sorting.division = true;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'division', 'asc');
        } else {

          this.isSortCurrentData = false;
          this.sorting.division = false;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'division', 'desc');
        }
        break;
      }

      case CURRENT_DATA.STATE: {
        if (!this.sorting.state) {
          this.isSortCurrentData = true;
          this.sorting.state = true;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'state', 'asc');
        } else {
          this.isSortCurrentData = false;
          this.sorting.state = false;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'state', 'desc');
        }
        break;
      }

      case CURRENT_DATA.FORUM: {
        if (!this.sorting.type) {
          this.isSortCurrentData = true;
          this.sorting.type = true;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'type', 'asc');
        } else {
          this.isSortCurrentData = false;
          this.sorting.type = false;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'type', 'desc');
        }
        break;
      }

      case CURRENT_DATA.GSTIN: {
        if (!this.sorting.gstin) {
          this.isSortCurrentData = true;
          this.sorting.gstin = true;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'gstin', 'asc');
        } else {
          this.isSortCurrentData = false;
          this.sorting.gstin = false;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'gstin', 'desc');
        }
        break;
      }

      case CURRENT_DATA.CASE_ID: {
        if (!this.sorting.caseId) {
          this.isSortCurrentData = true;
          this.sorting.caseId = true;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'caseId', 'asc');
        } else {
          this.isSortCurrentData = false;
          this.sorting.caseId = false;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'caseId', 'desc');
        }
        break;
      }

      case CURRENT_DATA.CASE_LABEL: {
        if (!this.sorting.label) {
          this.isSortCurrentData = true;
          this.sorting.label = true;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'label', 'asc');
        } else {
          this.isSortCurrentData = false;
          this.sorting.label = false;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'label', 'desc');
        }
        break;
      }

      case CURRENT_DATA.DEMAND_AMOUNT: {
        if (!this.sorting.amount) {
          this.isSortCurrentData = true;
          this.sorting.amount = true;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'amount', 'asc');
        } else {
          this.isSortCurrentData = false;
          this.sorting.amount = false;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'amount', 'desc');
        }
        break;
      }
      case CURRENT_DATA.INCEPTION_DATE: {
        if (!this.sorting.inceptionDate) {
          this.isSortCurrentData = true;
          this.sorting.inceptionDate = true;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'inceptionDate', 'asc');
        } else {
          this.isSortCurrentData = false;
          this.sorting.inceptionDate = false;
          this.ageingPopSummary = this.commonServ.sortObjectsByKey(this.ageingPopSummary, 'inceptionDate', 'desc');
        }
        break;
      }
    }
  }

  getStateRTOCodeFromGSTIN(gstin: string) {
    const stateCode = gstin.substring(0, 2);
    const stateRtoCode = this.stateList.find((state: any) => state.stateCode === stateCode)?.shortCode || '';
    return `${stateRtoCode}`;
  }

  agingAnalysisExportAsXLSX(type:any) {
    let dataForXlsx = this.ageingPopSummary;
    let title = 'Litigation Aging Analysis -'+ type +' - '+ this.currentLabel;

    if(type == 'Inception'){
        dataForXlsx = this.ageingInPopSummary;
    }
    else if(type == 'Open Cases'){
      dataForXlsx = this.openCaseData;
      title = 'Division wise Demand - Open Cases - '+ this.currentLabel;
    }
    else if(type == 'All Open Cases'){
      dataForXlsx = this.openCaseData;
      title = 'Division wise Demand - Open Cases - '+ this.currentLabel;
    }

    this.data = [];
    dataForXlsx.forEach((obj: any, index:any) => {
        let demandAmount = 0;
        let forum;
        if(type == 'Inception' || type == 'Current Forum') {
          demandAmount = this.isinLakh ? obj.amountInLakhs?.toFixed(2) : obj.amountInCrores?.toFixed(2);
          forum = obj.currentForum;
        }
        else{
          demandAmount = this.isinLakh ? obj.amount?.toFixed(2) : obj.amountInCr?.toFixed(2);
          forum = obj.type;
        }

        let objForElsx = {
            'Sr No.': index + 1,
            'Division': obj.division,
            'State': obj.state,
            'Forum': forum,
            'GSTIN': obj.gstin,
            'Case ID': obj.caseId,
            'Case Label': obj.label,
            'Demand Amount': demandAmount,
            'Inception Forum': obj.inceptionForum,
            'Inception Date': obj.inceptionDate
        }

        if(type != 'Inception'){
            delete objForElsx['Inception Forum'];
        }

        if(type == 'Open Cases'){
            delete objForElsx['Inception Date'];
            delete objForElsx.Forum;
        }
        
        if(type == 'All Open Cases'){
          delete objForElsx['Inception Date'];
      }
        this.data.push(objForElsx);
    });
    this.excelService.exportAsExcelFile(this.data, title);
  }  
}


