import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ChartConfiguration, ChartData, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { PieChartData } from '../pie-chart/pie-chart.component';
import _ from 'lodash-es';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.css']
})
export class BarChartComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart!: BaseChartDirective;

  @Input() data: PieChartData[] = [];
  @Input() xtitle: string = '';
  @Input() ytitle: string = '';
  @Output() clickEvent = new EventEmitter<string>();

  legend: boolean = true;
  labels: string[] = [];
  values: number[] = [];
  colors: string[] = [];

  chartdata!: ChartData<'bar'>;
  options: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          callback: (value, index) => this.shortLabel(index)
        },
        title: { display: true, text: this.xtitle }
      },
      y: {
        beginAtZero: true,
        ticks: { sampleSize: 10 },
        title: { display: true, text: this.ytitle }
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true, intersect: false, position: 'nearest' }
    }
  };

  constructor() { }

  ngOnInit(): void {
    const notEmpty = this.data?.find((el) => el.value !== 0);
    if (!notEmpty) return;

    const data = _.sortBy(this.data, 'value').reverse();

    let colors: string[] = [];
    data.forEach((el) => {
      colors.push(el.label.toColor(colors));
    });

    this.labels = data.map((el) => el.label);
    this.values = data.map((el) => el.value);
    this.colors = colors;

    // fill empty values in labels to fix bar width
    [...Array(20 - this.labels.length)].forEach((el) => this.labels.push(''));

    this.chartdata = {
      labels: this.labels,
      datasets: [{ data: this.values, backgroundColor: colors }]
    };

    this.options!.scales!.x!.title!.text = this.xtitle;
    this.options!.scales!.y!.title!.text = this.ytitle;
  }

  shortLabel(index: number): string | null {
    const lbl = this.labels[index];
    if (typeof lbl === 'string' && lbl.length > 10) {
      return `${lbl.substring(0, 10)}...`;
    }
    return lbl;
  }

  handleClick(e: any) {
    const index = e.active?.[0]?.index ?? -1;
    if (index > -1) {
      this.clickEvent.emit(this.labels[index]);
    }
  }
}
