import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tax-case-master',
  templateUrl: './tax-case-master.component.html',
  styleUrls: ['./tax-case-master.component.css']
})
export class TaxCaseMasterComponent implements OnInit {

  selectedTab: any;
  type: any;
  tab: any;
  isActionUpdate: any;
  isActionView: any;
  getAccessTabData: any;
  someElement: any;
  tabDisableFlag = { 'tab1': false, 'tab2': false, 'tab3': false, 'tab4': false, 'tab5': false, 'tab6': false, 'tab7': false, };
  selectedCaseId: any;
  isRemandedUpdated: any
  activeStages: string[] | null = null;
  constructor(
    private activeSnapShot: ActivatedRoute,
    private router: Router,
  ) {
    // this.type = this.activeSnapShot.snapshot.params.type;
    this.tab = this.activeSnapShot.snapshot.params.tab;
    this.isActionUpdate = this.activeSnapShot.snapshot.params.action === 'update' ? true : false;
    this.isActionView = this.activeSnapShot.snapshot.params.action === 'view' ? true : false;
  }

  ngOnInit() {
    this.selectedTab = 'tab1';
    if (this.type === 'dtenqproc' || this.tab === 'tab1') {
      this.selectedTab = 'tab1';
    } else if (this.type === 'dtintimation' || this.tab === 'tab2') {
      this.selectedTab = 'tab2';
    } else if (this.type === ' dtassessment' || this.tab === 'tab3') {
      this.selectedTab = 'tab3';
    } else if (this.type === 'dtcommissioner' || this.tab === 'tab4') {
      this.selectedTab = 'tab4';
    } else if (this.type === 'dtitat' || this.tab === 'tab5') {
      this.selectedTab = 'tab5';
    } else if (this.type === 'dthc' || this.tab === 'tab6') {
      this.selectedTab = 'tab6';
    } else if (this.type === 'dtsc' || this.tab === 'tab7') {
      this.selectedTab = 'tab7';
    }
    this.tabChanged(this.tab);

  }

    tabChanged(tab:any, check?:any,isMoveToHC:any=false) {
    this.selectedTab = tab;
    this.disabledTab();
    this.enabledTab(this.tab);
    if(isMoveToHC){
      this.tabDisableFlag.tab1 = false;
      this.tabDisableFlag.tab2 = false;
      this.tabDisableFlag.tab3 = false;
      this.tabDisableFlag.tab4 = false;
      this.tabDisableFlag.tab5 = false;
    } 
  }

  disabledTab() {
    switch (this.selectedTab) {
      case 'tab1':
        this.tabDisableFlag = { 'tab1': true, 'tab2': false, 'tab3': false, 'tab4': false, 'tab5': false, 'tab6': false, 'tab7': false, };
        break;
      case 'tab2':
        this.tabDisableFlag = { 'tab1': true, 'tab2': true, 'tab3': false, 'tab4': false, 'tab5': false, 'tab6': false, 'tab7': false, };
        break;
      case 'tab3':
        this.tabDisableFlag = { 'tab1': true, 'tab2': true, 'tab3': true, 'tab4': false, 'tab5': false, 'tab6': false, 'tab7': false, };
        break;
      case 'tab4':
        this.tabDisableFlag = { 'tab1': true, 'tab2': true, 'tab3': true, 'tab4': true, 'tab5': false, 'tab6': false, 'tab7': false, };
        break;
      case 'tab5':
        this.tabDisableFlag = { 'tab1': true, 'tab2': true, 'tab3': true, 'tab4': true, 'tab5': true, 'tab6': false, 'tab7': false, };
        break;
      case 'tab6':
        this.tabDisableFlag = { 'tab1': true, 'tab2': true, 'tab3': true, 'tab4': true, 'tab5': true, 'tab6': true, 'tab7': false, };
        break;
      case 'tab7':
        this.tabDisableFlag = { 'tab1': true, 'tab2': true, 'tab3': true, 'tab4': true, 'tab5': true, 'tab6': true, 'tab7': true, };
        break;
    }

  }

  enabledTab(tab: any) {
    if (tab === 'tab1') {
      this.tabDisableFlag = { 'tab1': true, 'tab2': false, 'tab3': false, 'tab4': false, 'tab5': false, 'tab6': false, 'tab7': false, };
      this.someElement = document.getElementById("Enquiry-tab");
      this.someElement.classList.remove("disabled");
    }
    if (tab === 'tab2') {
      this.tabDisableFlag = { 'tab1': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab2': true, 'tab3': false, 'tab4': false, 'tab5': false, 'tab6': false, 'tab7': false, };
      this.someElement = document.getElementById("Intimation-tab");
      this.someElement.classList.remove("disabled");
    }
    if (tab === 'tab3') {
      this.tabDisableFlag = { 'tab1': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab2': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab3': true, 'tab4': false, 'tab5': false, 'tab6': false, 'tab7': false, };
      this.someElement = document.getElementById("assessment-tab");
      this.someElement.classList.remove("disabled");
    }
    if (tab === 'tab4') {
      this.tabDisableFlag = { 'tab1': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab2': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab3': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab4': true, 'tab5': false, 'tab6': false, 'tab7': false, };
      this.someElement = document.getElementById("Commissioner-tab");
      this.someElement.classList.remove("disabled");
    }
    if (tab === 'tab5') {
      this.tabDisableFlag = { 'tab1': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab2': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab3': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab4': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab5': true, 'tab6': false, 'tab7': false, };
      this.someElement = document.getElementById("AppelTrib-tab");
      this.someElement.classList.remove("disabled");
    }
    if (tab === 'tab6') {
      this.tabDisableFlag = { 'tab1': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab2': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab3': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab4': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab5': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab6': true, 'tab7': false };
      this.someElement = document.getElementById("HC-tab");
      this.someElement.classList.remove("disabled");
    }
    if (tab === 'tab7') {
      this.tabDisableFlag = { 'tab1': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab2': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab3': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab4': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab5': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab6': (this.isActionUpdate ? false : true || this.isActionView ? false : true), 'tab7': true };
      this.someElement = document.getElementById("SC-tab");
      this.someElement.classList.remove("disabled");
    }
  }

  saveCaseId(event: any) {
    this.selectedCaseId = event;
  }
  gotoSummary() {
    this.router.navigate(['/directTaxSummary'])
  }

  enablePrevStageTab(stage: any) {
    this.activeStages = stage;
    if (this.activeStages && this.activeStages.length) {
      for (let i = 0; i < stage.length; i++) {
        switch (stage[i]) {
          case 'dtenqproc': this.tabDisableFlag.tab1 = true;
            break;
          case 'dtintimation': this.tabDisableFlag.tab2 = true;
            break;
          case 'dtassessment': this.tabDisableFlag.tab3 = true;
            break;
          case 'dtcommissioner': this.tabDisableFlag.tab4 = true;
            break;
          case 'dtitat': this.tabDisableFlag.tab5 = true;
            break;
          case 'dthc': this.tabDisableFlag.tab6 = true;
            break;
          case 'dtsc': this.tabDisableFlag.tab7 = true;
            break;
        }
      }
    }
  }

}
