<div class="switch-box">
  <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
  <div class="form-check form-switch m-0">
    <input [(ngModel)]="selectedIRN" (change)="changeStatsValue(selectedIRN,$event)" class="form-check-input"
      type="checkbox" role="switch" [checked]="amountMetric === 'crore'" />
  </div>
  <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
</div>
<div class="row" *ngIf="isinLakh">
  <div class="table-responsive">
    <table class="table table-bordered divTbl">
      <thead>
        <tr class="bgGred">
          <th style="width:170px;">State</th>
          <th>Current Stage Demand Amount</th>
          <th>Pre Deposit Amount</th>
          <th>Admitted Liability Amount</th>
          <th>Paid under Protest Amount</th>
        </tr>

      </thead>
      <tbody class="divTbody2">
        <ng-container *ngFor="let obj of paymentRegiondata">
          <tr>
            <td style="background: #eee;"> <a class="letterTbl"
                (click)="obj.expanded = !obj.expanded">{{obj.state}}<span class="expand">{{ obj.expanded ? '&ndash;' :
                  '+'}}</span></a></td>
                  <td class="textRight">
                    {{obj.demand | INR : 2}}
                  </td>
                  <td class="textRight">
                    {{obj.pDeposit | INR : 2}}
                  </td>
                  <td class="textRight">
                    {{obj.lAmount | INR : 2}}
                  </td>
                  <td class="textRight">
                    {{obj.pAmount | INR : 2}}
                  </td>
          </tr>
          <ng-container *ngIf="obj.expanded">
            <tr *ngFor="let item of obj.paymentGstinData" class="expandTr">
              <td class="pl-4">{{item.regNo}}</td>
              <td class="textRight">{{item.rowSummary.demand | INR : 2}}</td>
              <td class="textRight">{{(item.rowSummary.pDeposit | INR : 2)}}</td>
              <td class="textRight">{{(item.rowSummary.lAmount) | INR : 2}}</td>
              <td class="textRight">{{(item.rowSummary.pAmount) | INR : 2}}</td>

            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="divCumulativeTotal">
          <tr style="background: #ede8e8;font-weight: 600;">
            <td>TOTAL</td>
            <td class="textRight">{{(divCumulativeTotal.DEMAND) | INR : 2}}</td>
            <td class="textRight">{{(divCumulativeTotal.PREDEPOSIT) | INR : 2}}</td>
            <td class="textRight">{{(divCumulativeTotal.ALIABILITY) | INR : 2}}</td>
            <td class="textRight">{{(divCumulativeTotal.PROTESTAMOUNT) | INR : 2}}</td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</div>

<div class="row" *ngIf="isinCR">
  <div class="table-responsive">
    <table class="table table-bordered divTbl">
      <thead>
        <tr class="bgGred">
          <th style="width:170px;">State</th>
          <th>Current Stage Demand Amount</th>
          <th>Pre Deposit Amount</th>
          <th>Admitted Liability Amount</th>
          <th>Paid under Protest Amount</th>
        </tr>

      </thead>
      <tbody class="divTbody2">
        <ng-container *ngFor="let obj of paymentRegiondata">
          <tr>
            <td style="background: #eee;"> <a class="letterTbl"
                (click)="obj.expanded = !obj.expanded">{{obj.state}}<span class="expand">{{ obj.expanded ? '&ndash;' :
                  '+'}}</span></a></td>
                  <td class="textRight">
                    {{obj.demandInCr| INR : 2 }}
                  </td>
                  <td class="textRight">
                    {{obj.pDepositInCr | INR : 2 }}
                  </td>
                  <td class="textRight">
                    {{obj.lAmountInCr | INR : 2}}
                  </td>
                  <td class="textRight">
                    {{obj.pAmountInCr | INR : 2}}
                  </td>
          </tr>
          <ng-container *ngIf="obj.expanded">
            <tr *ngFor="let item of obj.paymentGstinData" class="expandTr">
              <td class="pl-4">{{item.regNo}}</td>
              <td class="textRight">{{(item.rowSummary.demandInCr) | INR : 2}}</td>
              <td class="textRight">{{(item.rowSummary.pDepositInCr) | INR : 2}}</td>
              <td class="textRight">{{(item.rowSummary.lAmountInCr) | INR : 2}}</td>
              <td class="textRight">{{(item.rowSummary.pAmountInCr) | INR : 2}}</td>

            </tr>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="divCumulativeTotalCr">
          <tr style="background: #ede8e8;font-weight: 600;">
            <td>TOTAL</td>
            <td class="textRight">{{(divCumulativeTotalCr.DEMANDINCR) | INR : 2}}</td>
            <td class="textRight">{{(divCumulativeTotalCr.PREDEPOSITINCR) | INR : 2}}</td>
            <td class="textRight">{{(divCumulativeTotalCr.ALIABILITYINCR) | INR : 2}}</td>
            <td class="textRight">{{(divCumulativeTotalCr.PROTESTAMOUNTINCR) | INR : 2}}</td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</div>
