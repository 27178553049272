import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../shared/services/token.service';
import { CommonService } from '../shared/services/common.service';
import { GetterSetterService } from '../shared/services/getter-setter.service';
import { DUE_DATE_SPAN } from '../shared/constant';
import { EventEmitterService } from '../shared/services/event-emitter.service';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { LitigationServiceService } from '../litigation/services/litigation-service.service';
import { DatePipe, formatDate } from '@angular/common';
import { AuditService } from '../audit/service/audit.service';
import { ToasterService } from '../shared/services/toaster.service';
import { ExcelService } from '../shared/services/excel.service';
import _ from 'lodash';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  newsList: any;
  marquee: any;
  count: any;
  pregstcount: any;
  auditcntOPEN: any;
  dtCount: any;
  navContext: any;
  selectedFilling: any;
  selectedGstin: any;
  amtSCN: any;
  amtADJ: any;
  amtAPA: any;
  amtAPT: any;
  amtHC: any;
  amtSC: any;
  total: any;
  preGstTotal: any;
  dtTotal: any;
  dueDateSpan: { key: string; value: string; }[];
  dueData: any;
  pan: any;
  ajamarqueeData: any;
  hmarqueeData: any;
  adueData: any;
  hearingData: any;
  auditData: any;
  dueDays: any;
  adueDays: any;
  hdueDays: any;
  todaysDate: any
  apaDueDate: any
  someElement: any;
  convertApaDueDate: any;
  convertTodaysDate: any;
  selectedCompanyId: any;
  role: any;
  scnDueDate: any;
  ailDueDate: any;
  aflDueDate: any;
  convertScnDueDate: any;
  ajaDueDate: any;
  inqDueDate: any;
  intDueDate: any
  assDueDate: any
  convertAjaDueDate: any;
  convertAptDueDate: any;
  aptDueDate: any;
  hcDueDate: any;
  userData: any
  convertHcDueDate: any;
  scDueDate: any;
  convertScDueDate: any;
  subscription: any;
  viewDataScn: any;
  viewDataAja: any
  viewDataInq: any;
  viewDataInt: any;
  viewDataApa: any;
  viewDataApt: any;
  viewDataHc: any;
  viewDataSc: any;
  hearingDataInq: any;
  hearingDataAss: any;
  hearingDataInt: any;
  hearingDataScn: any;
  hearingDataAja: any;
  hearingDataApa: any;
  hearingDataApt: any;
  hearingDataHc: any;
  hearingDataSc: any;
  HideDueDate: boolean = false;
  hideDueDt: boolean = false
  isGstSelected: boolean = false;
  isPreGstSelected: boolean = false;
  isDtSelected: boolean = false;
  isAllSelected: boolean = true;
  activeLink: string = 'Dashboard';
  value: string = '';
  dataLoaded = true;
  checked: any;
  loadingText: string = 'Coming Soon';
  selectedIRN: boolean = false;
  isinCR: boolean = false;
  isinLakh: boolean = true;
  isGstStats: boolean = true;
  isPreStats: boolean = false;
  isDtStats: boolean = false;
  selectedStatsTab: any;
  isGSTRole: any;
  isPREGSTRole: any;
  isDTRole: any;
  page: number = 0;
  pagePerLimit: number = 1000;
  totalAmmounts: any;
  dashboardCount: any;
  dashboardPreGstCount: any;
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private toaster: ToasterService,
    private CommonService: CommonService,
    private GS: GetterSetterService,
    private eEmit: EventEmitterService,
    private litService: LitigationServiceService,
    private datePipe: DatePipe,
    private auditService: AuditService,
    private excelService: ExcelService,

  ) {
    this.dueDateSpan = DUE_DATE_SPAN
    this.dueDateSpan = DUE_DATE_SPAN
    this.dueDateSpan = DUE_DATE_SPAN
    this.value = (localStorage.getItem('entityType')) as string;
  }

  ngOnInit() {
    this.dueDays = this.dueDateSpan[0].value;
    this.adueDays = this.dueDateSpan[0].value;
    this.hdueDays = this.dueDateSpan[0].value;
    this.role = localStorage.getItem('roleName');
    this.checkRole(this.role)
    this.selectedCompanyId = localStorage.getItem('selectedId');
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.navContext = this.CommonService.getNavContext();

    this.pan = this.navContext.pan;
    window.scrollTo(0, 0);
    this.currentDate();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.litService.selectedFilling$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((data) => {
      if (this.router.url === '/dashboard') {
        if (data) {
          this.selectedFilling = data;
          this.selectedGstin = this.selectedFilling.gstin;
          this.GS.checkEntityType.subscribe((value) => {
            this.navContext = value;
          });
        }
        this.gstDueDate();
        this.gstPregstDueDate();
        this.gstPrestHearingDate();
        this.gstHearingDate();
        this.getAuditDateData();
        this.newsUpdateData();
        this.getDtCaseCount();
        this.getDtHearingDate();
        this.getDtStasticAmount()
        // this.getpregstCaseCount();
        this.getMarqueeData();
        this.getMarqueeHData();
        this.getAuditOpenCount();
        //this.getpreGSTstatsAmount();
        this.animateDots();



        // // this.getTotalCaseCount();
        // // this.getpregstCaseCount();
        // this.getDtCaseCount();
        // // this.getStatsAmount();
        // this.getAllDtDueDate();
        // this.getDtHearingDate();

        // this.getMarqueeData();
        // this.getMarqueeHData();
        // this.getAuditOpenCount();
        // // this.getpreGSTstatsAmount();
        // this.getDtStasticAmount()
        // this.animateDots();
      }
    });
    if (this.role == 'DT_Admin' || this.role == 'DT_Viewer' || this.role == 'DT_Preparer') {
      this.isGstStats = false;
      this.isPreStats = false;
      this.isDtStats = true;
    } if (this.role == 'GST_Admin' || this.role == 'GST_Viewer' || this.role == 'GST_Preparer'
      || this.role == 'IDT_Admin' || this.role == 'IDT_Viewer' || this.role == 'IDT_Preparer'
    ) {
      this.isGstStats = true;
      this.isPreStats = false;
      this.isDtStats = false;
    } if (this.role == 'PREGST_Admin' || this.role == 'PREGST_Viewer' || this.role == 'PREGST_Preparer'
      || this.role == 'IDT_Admin' || this.role == 'IDT_Viewer' || this.role == 'IDT_Preparer') {
      this.isGstStats = false;
      this.isPreStats = true;
      this.isDtStats = false;
    }

    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    const compId = sessionStorage.getItem('companyId');
    const data = {
      criterias: [
        {
          "p": "status",
          "o": "nin",
          "v": "MOVED,CLOSED"
        }
      ],
      page: this.page,
      size: this.pagePerLimit,
      sortfield: 'caseId',
      sortdir: 'DESC',
      companyId: this.selectedCompanyId

    }
    this.litService.getAllGstDashboardCount(data).subscribe((res: any) => {
      this.totalAmmounts = res.response;
    })
    this.litService.getAllCount(data).subscribe((res: any) => {
      this.dashboardCount = res.response.openCaseCnt;
    })
    this.litService.getAllPreGstDashboardCount(data).subscribe((res: any) => {
      this.preGstTotal = res.response;
    })
    this.litService.getAllDashPreGstCount(data).subscribe((res: any) => {
      this.pregstcount = res.response.openCaseCnt;
    })
  }
  uniqueList(arr1: any[], arr2: any[], arr3?: any[]) {

    if (!arr1 || !arr2) return [];
    let temp = _.uniqBy([...arr1, ...arr2], item => item.caseId + item.dueDate);
    if (arr3) {
      temp = _.uniqBy([...temp, ...arr3], item => item.caseId + item.dueDate);
    }
    return temp
  }



  gstDueDates() {
    this.isGstSelected = true;
    this.HideDueDate = false;
    this.hideDueDt = false;
    this.activeLink = 'filter';
    this.getGstDueDateData();
    this.getHearingDateData();
    this.isPreGstSelected = this.isAllSelected = false;
    this.isDtSelected = this.isAllSelected = false;
    this.setNullValue();

  }

  preGstDueDates() {
    this.isPreGstSelected = true;
    this.HideDueDate = true;
    this.hideDueDt = true;
    this.activeLink = 'filter';
    this.getPreGstDueDateData();
    this.getPreGstHearingDateData();
    this.isGstSelected = this.isAllSelected = false;
    this.isDtSelected = this.isAllSelected = false;
    this.setNullValue();
  }

  dtDueDates() {
    this.isDtSelected = true;
    this.HideDueDate = true;
    this.hideDueDt = false;
    this.activeLink = 'filter';
    this.getDtDueDateData();
    this.getDtHearingDateData();
    this.isGstSelected = this.isPreGstSelected = this.isAllSelected = false;
    // this.isPreGstSelected = this.isAllSelected = false;
    this.isAllSelected = false;
  }
  setNullValue() {
    this.viewDtApaData = null;
    this.viewDtAjaData = null;
    this.viewDtScnData = null;
    this.viewDtAptData = null;
    this.viewDtHcData = null;
    this.viewDtScData = null;
    this.hearingDtAjaData = null;
    this.hearingDtApaData = null;
    this.hearingDtAptData = null;
    this.hearingDtHcData = null;
    this.hearingDtScData = null;
  }
  gstAndPregstDueDates() {
    this.isAllSelected = true;
    this.HideDueDate = false;
    this.hideDueDt = false;
    this.activeLink = 'filter';
    this.gstDueDate();
    this.gstPregstDueDate();
    this.gstPrestHearingDate();
    this.gstHearingDate();
    // this.getAllDtDueDate();
    // this.getDtHearingDate();
    this.isGstSelected = this.isPreGstSelected = this.isDtSelected = false;
  }

  getDtCaseCount() {
    const sessionData: any = sessionStorage.getItem('navContext');
    const parseData = JSON.parse(sessionData)
    const companyId = sessionStorage.getItem('companyId');
    const payloadData = {
      criterias: [
        {
          p: "status",
          o: "nin",
          v: "CLOSED,MOVED"
        }
      ],
      page: 0,
      size: 10,
      sortfield: 'caseId',
      sortdir: 'ASC',
      companyId: parseData.companyId ? parseData.companyId : companyId
    }

    this.CommonService.getAllcountDt(payloadData).subscribe((res: any) => {
      this.dtCount = res.response.openCaseCnt;
    })
  }


  getDtStasticAmount() {
    const sessionData: any = sessionStorage.getItem('navContext');
    const parseData = JSON.parse(sessionData)
    const companyId = sessionStorage.getItem('companyId');
    const staticAmtPayload = {
      criterias: [
        {
          p: "status",
          o: "nin",
          v: "CLOSED,MOVED"
        }
      ],
      page: 0,
      size: 1000,
      sortfield: 'caseId',
      sortdir: 'ASC',
      companyId: parseData.companyId ? parseData.companyId : companyId
    }
    this.CommonService.getAmmountDt(staticAmtPayload).subscribe((res: any) => {
      this.dtTotal = res.response;
    })
  }
  // getpregstCaseCount() {
  //   let model: any = {};
  //   if (this.navContext.entityType === 'FILING') {
  //   }else  if (this.navContext.entityType === 'Business') {
  //     model.pan = '';
  //   } else  if (this.navContext.entityType === 'LEGAL'){
  //     model.pan = this.navContext.pan;
  //   }
  //   else {
  //     model.pan = this.navContext.pan;
  //   }
  //   this.CommonService.pregstCaseCount(model).subscribe((response: any) => {
  //     if (response.status === 1) {
  //       this.pregstcount = response.response;
  //     }
  //   });

  // }
  // getStatsAmount() {
  //   let model: any = {};
  //   if (this.navContext.entityType === 'FILING') {
  //     model.gstin = this.selectedGstin;
  //   } else {
  //     model.gstin = '';
  //   }
  //   this.CommonService.statsAmount(model).subscribe((response: any) => {
  //     if (response.status === 1) {
  //       this.total = response.response;
  //     }
  //   });
  // }

  // PRE-GST STATISTICS
  // getpreGSTstatsAmount() {
  //   let model: any = {};
  //   if (this.navContext.entityType === 'FILING') {
  //     model.pan = this.navContext.gstin;
  //   } else if (this.navContext.entityType === 'LEGAL') {
  //     model.pan = this.navContext.pan;
  //   }else if (this.navContext.entityType === 'Business') {
  //     model.pan = '';
  //   }
  //   this.CommonService.preGSTstatsAmount(model).subscribe((response: any) => {
  //     if (response.status === 1) {
  //       this.preGstTotal = response.response;
  //     }
  //   });
  // }


  /*current date */
  currentDate() {
    this.todaysDate = new Date();
  }

  getDueDateData() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    model.days = this.dueDays;
    this.CommonService.getGstAllDueDate(modelObj).subscribe((response: any) => {
      if (response.status === 1) {
        this.dueData = response.response;
        this.dueData.scn.forEach((element: any) => {
          this.scnDueDate = new Date(element.dueDate);
          if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.dueData.aja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.dueData.apa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.dueData.apt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.dueData.hc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.dueData.sc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
      }
    });

  }

  gstDueDate() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.gstin = '';
    } else if (this.navContext.entityType === 'Business') {
      model.gstin = '';
      model.pan = '';
    }
    const sessionData: any = sessionStorage.getItem('navContext');
    const companyId = sessionStorage.getItem('companyId');
    const parseData1 = JSON.parse(sessionData)
    const dtmodel = {
      criterias: [
        {
          p: "status",
          o: "nin",
          v: "CLOSED,MOVED"
        },
        {
          p: "days",
          o: "eq",
          v: this.dueDays
        }
      ],
      page: 0,
      size: 1000,
      sortfield: 'caseId',
      sortdir: 'ASC',
      companyId: parseData1.companyId ? parseData1.companyId : companyId,
      // days : this.dueDays
    }
    model.days = this.dueDays;
    if (this.role == 'Admin' || this.role == 'undefined' || this.role == 'NoRole' || this.role == 'Viewer' || this.role == 'Preparer') {
      combineLatest(this.CommonService.getGstAllDueDate(modelObj), this.CommonService.getPreGstAllDueDate(modelObj), this.CommonService.getDtDuedates(dtmodel)).subscribe((
        response: any) => {
        const { scn, aja: aja1, apa: apa1, apt: apt1, hc: hc1, sc: sc1 } = response[0].response;
        const { aja: aja2, apa: apa2, apt: apt2, hc: hc2, sc: sc2 } = response[1].response;
        const { enqProceedings, assessment, commissioner, apt, hc, sc } = response[2].response;
        this.viewDataScn = scn;
        this.viewDataAja = aja1;
        this.viewDataApa = apa1;
        this.viewDataApt = apt1;
        this.viewDataHc = hc1;
        this.viewDataSc = sc1;
        if (this.navContext.entityType !== 'FILING') {
          this.viewDataAja.push(...aja2);
          this.viewDataAja.push(...assessment);
          this.viewDataScn.push(...enqProceedings);
          this.viewDataApa.push(...apa2);
          this.viewDataApa.push(...commissioner);
          this.viewDataApt.push(...apt2);
          this.viewDataApt.push(...apt);
          this.viewDataHc.push(...hc2);
          this.viewDataHc.push(...hc);
          this.viewDataSc.push(...sc2);
          this.viewDataSc.push(...sc);
        }
        // this.viewDataScn = this.navContext.entityType !== 'FILING'? [...response[0].response.scn]:[...response[0].response.scn,...response[].response.enqProceedings];
        // this.viewDataAja = this.navContext.entityType !== 'FILING'? [...response[0].response.aja,...response[1].response.aja]:[...response[0].response?.aja,...response[0].response.enqProceedings];
        // this.viewDataApa = this.navContext.entityType !== 'FILING'? [...response[0].response.apa,...response[1].response.apa]:[...response[0].response.apa];
        // this.viewDataApt = this.navContext.entityType !== 'FILING'? [...response[0].response.apt,...response[1].response.apt]:[...response[0].response.apt];
        // this.viewDataHc = this.navContext.entityType !== 'FILING'? [...response[0].response.hc,...response[1].response.hc]:[...response[0].response.hc];
        // this.viewDataSc = this.navContext.entityType !== 'FILING'? [...response[0].response.sc,...response[1].response.sc]:[...response[0].response.sc];
        this.CommonService.sortObjectsByDate(this.viewDataApa, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataApt, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataHc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataSc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataScn, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataAja, 'dueDate', 'desc');

        this.viewDataScn.forEach((element: any) => {
          this.scnDueDate = new Date(element.dueDate);
          if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });

        this.viewDataAja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });

        this.viewDataApa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataApt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataHc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataSc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
      });
    }
    else if (this.role == 'GST_Admin' || this.role == 'undefined' || this.role == 'NoRole' || this.role == 'GST_Viewer' || this.role == 'GST_Preparer') {
      this.CommonService.getGstAllDueDate(modelObj).subscribe((response: any) => {
        if (response.status === 1) {
          this.dueData = response.response;
          this.viewDataScn = this.dueData.scn;
          this.viewDataAja = this.dueData.aja;
          this.viewDataApa = this.dueData.apa;
          this.viewDataApt = this.dueData.apt;
          this.viewDataHc = this.dueData.hc;
          this.viewDataSc = this.dueData.sc;

          this.CommonService.sortObjectsByDate(this.viewDataApa, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataApt, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataHc, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataSc, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataScn, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataAja, 'dueDate', 'desc');

          this.viewDataScn.forEach((element: any) => {
            this.scnDueDate = new Date(element.dueDate);
            if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataAja.forEach((element: any) => {
            this.ajaDueDate = new Date(element.dueDate);
            if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataApa.forEach((element: any) => {
            this.apaDueDate = new Date(element.dueDate);
            if (this.apaDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataApt.forEach((element: any) => {
            this.aptDueDate = new Date(element.dueDate);
            if (this.aptDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataHc.forEach((element: any) => {
            this.hcDueDate = new Date(element.dueDate);
            if (this.hcDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataSc.forEach((element: any) => {
            this.scDueDate = new Date(element.dueDate);
            if (this.scDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });

        }
      });
    }
    else if (this.role == 'PREGST_Admin' || this.role == 'undefined' || this.role == 'NoRole' || this.role == 'PREGST_Viewer' || this.role == 'PREGST_Preparer') {
      this.CommonService.getPreGstAllDueDate(modelObj).subscribe((response: any) => {
        if (response.status === 1) {
          this.dueData = response.response;
          this.viewDataAja = this.dueData.aja;
          this.viewDataApa = this.dueData.apa;
          this.viewDataApt = this.dueData.apt;
          this.viewDataHc = this.dueData.hc;
          this.viewDataSc = this.dueData.sc;

          this.CommonService.sortObjectsByDate(this.viewDataApa, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataApt, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataHc, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataSc, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataAja, 'dueDate', 'desc');

          this.viewDataAja.forEach((element: any) => {
            this.ajaDueDate = new Date(element.dueDate);
            if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataApa.forEach((element: any) => {
            this.apaDueDate = new Date(element.dueDate);
            if (this.apaDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataApt.forEach((element: any) => {
            this.aptDueDate = new Date(element.dueDate);
            if (this.aptDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataHc.forEach((element: any) => {
            this.hcDueDate = new Date(element.dueDate);
            if (this.hcDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataSc.forEach((element: any) => {
            this.scDueDate = new Date(element.dueDate);
            if (this.scDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });

        }
      });

    } else if (this.role == 'DT_Admin' || this.role == 'undefined' || this.role == 'NoRole' || this.role == 'DT_Viewer' || this.role == 'DT_Preparer') {

      this.CommonService.getDtDuedates(dtmodel).subscribe((response: any) => {
        if (response.status === 1) {
          this.dueData = response.response;

          this.viewDataScn = this.dueData.enqProceedings;
          // if (this.dueData.intimation) {
          //   this.viewDataScn = this.dueData.intimation;
          // }
          this.viewDataAja = this.dueData.assessment;
          this.viewDataApa = this.dueData.commissioner;
          this.viewDataApt = this.dueData.apt;
          this.viewDataHc = this.dueData.hc;
          this.viewDataSc = this.dueData.sc;

          this.CommonService.sortObjectsByDate(this.viewDataScn, 'dueDate', 'desc');
          // this.CommonService.sortObjectsByDate(this.viewDataScn, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataAja, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataApa, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataApt, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataHc, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.viewDataSc, 'dueDate', 'desc');


          this.viewDataScn.forEach((element: any) => {
            this.scnDueDate = new Date(element.dueDate);
            if (this.scnDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          //  this.viewDataScn.forEach((element: any) => {
          //   this.scnDueDate = new Date(element.dueDate);
          //   if (this.scnDueDate < this.todaysDate.getTime()) {
          //     element.oldDatesClr = true;
          //   }
          //  });
          this.viewDataAja.forEach((element: any) => {
            this.ajaDueDate = new Date(element.dueDate);
            if (this.ajaDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataApa.forEach((element: any) => {
            this.apaDueDate = new Date(element.dueDate);
            if (this.apaDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataApt.forEach((element: any) => {
            this.aptDueDate = new Date(element.dueDate);
            if (this.aptDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataHc.forEach((element: any) => {
            this.hcDueDate = new Date(element.dueDate);
            if (this.hcDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.viewDataSc.forEach((element: any) => {
            this.scDueDate = new Date(element.dueDate);
            if (this.scDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });

        }
      });
    }
  }

  hearingDtAjaData: any;
  hearingDtEnqData: any;
  hearingDtApaData: any;
  hearingDtAptData: any;
  hearingDtHcData: any;
  hearingDtScData: any;

  viewDtApaData: any;
  viewDtAptData: any;
  viewDtHcData: any;
  viewDtScData: any;
  viewDtScnData: any;
  viewDtAjaData: any;


  getAllDtDueDate() {
    const sessionData: any = sessionStorage.getItem('navContext');
    const parseData = JSON.parse(sessionData)
    const companyId = sessionStorage.getItem('companyId');
    const dtDueDatePayload = {
      criterias: [
        {
          p: "status",
          o: "nin",
          v: "CLOSED,MOVED"
        },
        {
          p: "days",
          o: "eq",
          v: this.dueDays
        }
      ],
      page: 0,
      size: 1000,
      sortfield: 'caseId',
      sortdir: 'ASC',
      companyId: parseData.companyId ? parseData.companyId : companyId,
      // days:this.dueDays
    }
    this.CommonService.getDtDuedates(dtDueDatePayload).subscribe((response: any) => {
      if (response.status === 1) {
        this.dueData = response.response;

        this.viewDtScnData = this.dueData.enqProceedings;
        this.viewDtAjaData = this.dueData.assessment;
        this.viewDtApaData = this.dueData.commissioner;
        this.viewDtAptData = this.dueData.apt;
        this.viewDtHcData = this.dueData.hc;
        this.viewDtScData = this.dueData.sc;
        // this.CommonService.sortObjectsByDate(this.viewDataScn, 'dueDate', 'desc');
        // this.CommonService.sortObjectsByDate(this.viewDataScn, 'dueDate', 'desc');
        // this.CommonService.sortObjectsByDate(this.viewDataAja, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDtScnData, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDtAjaData, 'dueDate', 'desc');

        this.CommonService.sortObjectsByDate(this.viewDtApaData, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDtAptData, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDtHcData, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDtScData, 'dueDate', 'desc');


        this.viewDtScnData.forEach((element: any) => {
          this.scnDueDate = new Date(element.dueDate);
          if (this.scnDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });

        this.viewDtAjaData.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDtApaData.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDtAptData.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDtHcData.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDtScData.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });

      }
    });
  }
  getDtHearingDate() {
    const sessionData: any = sessionStorage.getItem('navContext');
    const parseData = JSON.parse(sessionData)
    const companyId = sessionStorage.getItem('companyId');
    const dtHDatePayload = {
      criterias: [
        {
          p: "status",
          o: "nin",
          v: "CLOSED,MOVED"
        },
        {
          p: "days",
          o: "eq",
          v: this.dueDays
        }
      ],
      page: 0,
      size: 1000,
      sortfield: 'caseId',
      sortdir: 'ASC',
      companyId: parseData.companyId ? parseData.companyId : companyId
    }
    this.CommonService.getDtHdates(dtHDatePayload).subscribe((response: any) => {
      if (response.status === 1) {
        this.dueData = response.response;
        this.hearingDtAjaData = this.dueData.assessment;
        this.hearingDtEnqData = this.dueData.enqProceedings;
        this.hearingDtApaData = this.dueData.commissioner;
        this.hearingDtAptData = this.dueData.apt;
        this.hearingDtHcData = this.dueData.hc;
        this.hearingDtScData = this.dueData.sc;

        this.CommonService.sortObjectsByDate(this.hearingDtAjaData, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDtApaData, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDtAptData, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDtHcData, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDtScData, 'dueDate', 'desc');



        this.hearingDtAjaData.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDtApaData.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDtAptData.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDtHcData.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDtScData.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });

      }
    })

  }

  gstHearingDate() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.gstin = '';
    } else if (this.navContext.entityType === 'Business') {
      model.gstin = '';
      model.pan = this.navContext.pan;
    }
    const sessionData: any = sessionStorage.getItem('navContext');
    const parseData1 = JSON.parse(sessionData)
    const companyId = sessionStorage.getItem('companyId');
    const dtmodel = {
      criterias: [
        {
          p: "status",
          o: "nin",
          v: "CLOSED,MOVED"
        },
        {
          p: "days",
          o: "eq",
          v: this.dueDays
        }
      ],
      page: 0,
      size: 1000,
      sortfield: 'caseId',
      sortdir: 'ASC',
      companyId: parseData1.companyId ? parseData1.companyId : companyId,
      // days : this.dueDays
    }
    model.days = this.dueDays;
    if (this.role == 'Admin' || this.role == 'undefined' || this.role == 'NoRole' || this.role == 'Viewer' || this.role == 'Preparer') {
      combineLatest(this.CommonService.getGstHearingDates(modelObj), this.CommonService.getPreGstHearingDates(modelObj), this.CommonService.getDtHdates(dtmodel)).subscribe((
        response: any) => {

        const {scn:scn1, aja: aja1, apa: apa1, apt: apt1, hc: hc1, sc: sc1 } = response[0].response;
        const { aja: aja2, apa: apa2, apt: apt2, hc: hc2, sc: sc2 } = response[1].response;
        const { enqProceedings, assessment, commissioner, apt, hc, sc } = response[2].response;
        this.hearingDataScn = scn1
        this.hearingDataAja = aja1;
        this.hearingDataApa = apa1;
        this.hearingDataApt = apt1;
        this.hearingDataHc = hc1;
        this.hearingDataSc = sc1;
        if (this.navContext.entityType !== 'FILING') {
          this.hearingDataAja.push(...aja2);
          this.hearingDataAja.push(...assessment);
          this.hearingDataAja.push(...enqProceedings);
          this.hearingDataApa.push(...apa2);
          this.hearingDataApa.push(...commissioner);
          this.hearingDataApt.push(...apt2);
          this.hearingDataApt.push(...apt);
          this.hearingDataHc.push(...hc2);
          this.hearingDataHc.push(...hc);
          this.hearingDataSc.push(...sc2);
          this.hearingDataSc.push(...sc);
        }

        this.CommonService.sortObjectsByDate(this.hearingDataScn, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataAja, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataApa, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataApt, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataHc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataSc, 'dueDate', 'desc');

        this.hearingDataScn.forEach((element: any) => {
          this.scnDueDate = new Date(element.dueDate);
          if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataAja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataApa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataApt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataHc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataSc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
      });
    }
    else if (this.role == 'GST_Admin' || this.role == 'undefined' || this.role == 'NoRole' || this.role == 'GST_Viewer' || this.role == 'GST_Preparer') {
      this.CommonService.getGstHearingDates(modelObj).subscribe((response: any) => {
        if (response.status === 1) {
          this.dueData = response.response;
          this.hearingDataScn = this.dueData.scn;          
          this.hearingDataAja = this.dueData.aja;
          this.hearingDataApa = this.dueData.apa;
          this.hearingDataApt = this.dueData.apt;
          this.hearingDataHc = this.dueData.hc;
          this.hearingDataSc = this.dueData.sc;

          this.CommonService.sortObjectsByDate(this.hearingDataScn, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataAja, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataApa, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataApt, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataHc, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataSc, 'dueDate', 'desc');

          this.hearingDataScn.forEach((element: any) => {
            this.scnDueDate = new Date(element.dueDate);
            if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });

          this.hearingDataAja.forEach((element: any) => {
            this.ajaDueDate = new Date(element.dueDate);
            if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataApa.forEach((element: any) => {
            this.apaDueDate = new Date(element.dueDate);
            if (this.apaDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataApt.forEach((element: any) => {
            this.aptDueDate = new Date(element.dueDate);
            if (this.aptDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataHc.forEach((element: any) => {
            this.hcDueDate = new Date(element.dueDate);
            if (this.hcDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataSc.forEach((element: any) => {
            this.scDueDate = new Date(element.dueDate);
            if (this.scDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
        }
      });
    }

    else if (this.role == 'PREGST_Admin' || this.role == 'undefined' || this.role == 'NoRole' || this.role == 'PREGST_Viewer' || this.role == 'PREGST_Preparer') {
      this.CommonService.getPreGstHearingDates(modelObj).subscribe((response: any) => {
        if (response.status === 1) {
          this.dueData = response.response;
          this.hearingDataAja = this.dueData.aja;
          this.hearingDataApa = this.dueData.apa;
          this.hearingDataApt = this.dueData.apt;
          this.hearingDataHc = this.dueData.hc;
          this.hearingDataSc = this.dueData.sc;

          this.CommonService.sortObjectsByDate(this.hearingDataAja, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataApa, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataApt, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataHc, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataSc, 'dueDate', 'desc');

          this.hearingDataAja.forEach((element: any) => {
            this.ajaDueDate = new Date(element.dueDate);
            if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataApa.forEach((element: any) => {
            this.apaDueDate = new Date(element.dueDate);
            if (this.apaDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataApt.forEach((element: any) => {
            this.aptDueDate = new Date(element.dueDate);
            if (this.aptDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataHc.forEach((element: any) => {
            this.hcDueDate = new Date(element.dueDate);
            if (this.hcDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataSc.forEach((element: any) => {
            this.scDueDate = new Date(element.dueDate);
            if (this.scDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
        }
      });
    } else if (this.role == 'DT_Admin' || this.role == 'undefined' || this.role == 'NoRole' || this.role == 'DT_Viewer' || this.role == 'DT_Preparer') {
      this.CommonService.getDtHdates(dtmodel).subscribe((response: any) => {
        if (response.status === 1) {
          this.dueData = response.response;
          this.hearingDataInq = this.dueData.enqProceedings;
          this.hearingDataAja = this.dueData.assessment;
          this.hearingDataApa = this.dueData.commissioner;
          this.hearingDataApt = this.dueData.apt;
          this.hearingDataHc = this.dueData.hc;
          this.hearingDataSc = this.dueData.sc;

          this.CommonService.sortObjectsByDate(this.hearingDataInq, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataAja, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataApa, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataApt, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataHc, 'dueDate', 'desc');
          this.CommonService.sortObjectsByDate(this.hearingDataSc, 'dueDate', 'desc');

          this.hearingDataInq.forEach((element: any) => {
            this.scnDueDate = new Date(element.dueDate);
            if (this.scnDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          // this.hearingDataInt.forEach((element: any) => {
          //   this.scnDueDate = new Date(element.dueDate);
          //   if (this.scnDueDate < this.todaysDate.getTime()) {
          //     element.oldDatesClr = true;
          //   }
          // });
          this.hearingDataAja.forEach((element: any) => {
            this.ajaDueDate = new Date(element.dueDate);
            if (this.ajaDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });

          this.hearingDataApa.forEach((element: any) => {
            this.apaDueDate = new Date(element.dueDate);
            if (this.apaDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataApt.forEach((element: any) => {
            this.aptDueDate = new Date(element.dueDate);
            if (this.aptDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataHc.forEach((element: any) => {
            this.hcDueDate = new Date(element.dueDate);
            if (this.hcDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
          this.hearingDataSc.forEach((element: any) => {
            this.scDueDate = new Date(element.dueDate);
            if (this.scDueDate < this.todaysDate.getTime()) {
              element.oldDatesClr = true;
            }
          });
        }
      });
    }
    // this.getDtHearingDateData()
  }
  getGstDueDateData() {
    // let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    // if (this.navContext.entityType === 'FILING') {
    //   model.gstin = this.selectedGstin;
    // } else if (this.navContext.entityType === 'LEGAL') {
    //   model.pan = this.navContext.pan;
    //   model.gstin = '';
    // } else if (this.navContext.entityType === 'Business') {
    //   model.gstin = '';
    //   model.pan = '';
    // }
    // model.days = this.dueDays;
    // model.days = this.dueDays;
    this.CommonService.getGstAllDueDate(modelObj).subscribe((response: any) => {
      if (response.status === 1) {
        this.dueData = response.response;
        this.viewDataScn = this.dueData.scn;
        this.viewDataAja = this.dueData.aja;
        this.viewDataApa = this.dueData.apa;
        this.viewDataApt = this.dueData.apt;
        this.viewDataHc = this.dueData.hc;
        this.viewDataSc = this.dueData.sc;
        this.CommonService.sortObjectsByDate(this.viewDataApa, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataApt, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataHc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataSc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataScn, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataAja, 'dueDate', 'desc');

        this.viewDataScn.forEach((element: any) => {
          this.scnDueDate = new Date(element.dueDate);
          if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataAja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataApa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataApt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataHc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataSc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
      }
    });

  }
  getPreGstDueDateData() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    model.days = this.dueDays;
    this.CommonService.getPreGstAllDueDate(modelObj).subscribe((response: any) => {
      if (response.status === 1) {
        this.dueData = response.response;
        this.viewDataAja = this.dueData.aja;
        this.viewDataApa = this.dueData.apa;
        this.viewDataApt = this.dueData.apt;
        this.viewDataHc = this.dueData.hc;
        this.viewDataSc = this.dueData.sc;

        this.CommonService.sortObjectsByDate(this.viewDataApa, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataApt, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataHc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataSc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataAja, 'dueDate', 'desc');

        this.viewDataAja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataApa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataApt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataHc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataSc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });

      }
    });
  }
  getDtDueDateData() {
    const sessionData: any = sessionStorage.getItem('navContext');
    const parseData = JSON.parse(sessionData)
    const companyId = sessionStorage.getItem('companyId');
    let model: any = {
      criterias: [
        {
          p: "status",
          o: "nin",
          v: "CLOSED,MOVED"
        },

        {
          p: "days",
          o: "eq",
          v: this.dueDays
        }
      ],
      page: 0,
      size: 1000,
      sortfield: 'caseId',
      sortdir: 'ASC',
      companyId: parseData.companyId ? parseData.companyId : companyId
    };
    // model.days = this.dueDays;
    this.CommonService.getDtDuedates(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.dueData = response.response;
        this.viewDataScn = this.dueData.enqProceedings;
        // this.viewDataScn = this.dueData.intimation;
        this.viewDataAja = this.dueData.assessment;
        this.viewDataApa = this.dueData.commissioner;
        this.viewDataApt = this.dueData.apt;
        this.viewDataHc = this.dueData.hc;
        this.viewDataSc = this.dueData.sc;
        // this.CommonService.sortObjectsByDate(this.viewDataScn, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataScn, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataAja, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataApa, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataApt, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataHc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataSc, 'dueDate', 'desc');
        this.viewDataScn.forEach((element: any) => {
          this.scnDueDate = new Date(element.dueDate);
          if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });

        this.viewDataAja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataApa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataApt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataHc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataSc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
      }
    })
  }

  getMarqueeData() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    if (this.navContext?.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    model.days = 5;
    this.CommonService.getGstAllDueDate(modelObj).subscribe((response: any) => {
      if (response.status === 1) {
        this.ajamarqueeData = response.response;
        this.ajamarqueeData.scn = response.response.scn;
        this.ajamarqueeData.aja = response.response.aja;
        this.ajamarqueeData.apa = response.response.apa;
        this.ajamarqueeData.apt = response.response.apt;
        this.ajamarqueeData.hc = response.response.hc;
        this.ajamarqueeData.sc = response.response.sc;
      }
    });

  }
  getMarqueeHData() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    model.days = 5;
    this.CommonService.getGstHearingDates(modelObj).subscribe((response: any) => {
      if (response.status === 1) {
        this.hmarqueeData = response.response;
        this.hmarqueeData.scn = response.response.scn;
        this.hmarqueeData.aja = response.response.aja;
        this.hmarqueeData.apa = response.response.apa;
        this.hmarqueeData.apt = response.response.apt;
        this.hmarqueeData.hc = response.response.hc;
        this.hmarqueeData.sc = response.response.sc;
      }
    });

  }
  auditSummary() {
    this.router.navigate(['/auditSummary']);
  }

  litigationSummary() {
    this.router.navigate(['/litigationSummary']);
  }

  preGstSummary() {
    this.router.navigate(['/preGstSummary']);
  }
  dtummary() {
    this.router.navigate(['/directTaxSummary']);
  }
  dashboardPage() {
    document.getElementById('card-title')?.scrollIntoView({ behavior: 'smooth' });
    this.isGstStats = true;
    this.isPreStats = false;
    this.isDtStats = false;

  }
  dashboardRedirect() {
    document.getElementById('card-title')?.scrollIntoView({ behavior: 'smooth' });
    this.isGstStats = false;
    this.isPreStats = true;
    this.isDtStats = false;
  }

  dashboardDtRedirect() {
    document.getElementById('card-title')?.scrollIntoView({ behavior: 'smooth' });
    this.isGstStats = false;
    this.isPreStats = false;
    this.isDtStats = true;
  }

  getHearingDateData() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.gstin = '';
    } else if (this.navContext.entityType === 'Business') {
      model.gstin = '';
      model.pan = this.navContext.pan;
    }
    model.days = this.hdueDays;
    this.CommonService.getGstHearingDates(modelObj).subscribe((response: any) => {
      if (response.status === 1) {
        this.dueData = response.response;

        this.hearingDataScn = this.dueData.scn;
        this.hearingDataAja = this.dueData.aja;
        this.hearingDataApa = this.dueData.apa;
        this.hearingDataApt = this.dueData.apt;
        this.hearingDataHc = this.dueData.hc;
        this.hearingDataSc = this.dueData.sc;

        this.CommonService.sortObjectsByDate(this.hearingDataScn, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataAja, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataApa, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataApt, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataHc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataSc, 'dueDate', 'desc');

        this.hearingDataScn.forEach((element: any) => {
          this.scnDueDate = new Date(element.dueDate);
          if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataAja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataApa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataApt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataHc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataSc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
      }
    });

  }

  getPreGstHearingDateData() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else {
      model.gstin = '';
    }
    model.days = this.hdueDays;
    this.CommonService.getPreGstHearingDates(modelObj).subscribe((response: any) => {
      if (response.status === 1) {
        this.dueData = response.response;
        this.hearingDataScn = this.dueData.scn;
        this.hearingDataAja = this.dueData.aja;
        this.hearingDataApa = this.dueData.apa;
        this.hearingDataApt = this.dueData.apt;
        this.hearingDataHc = this.dueData.hc;
        this.hearingDataSc = this.dueData.sc;

        this.CommonService.sortObjectsByDate(this.hearingDataScn, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataAja, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataApa, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataApt, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataHc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataSc, 'dueDate', 'desc');

        this.hearingDataScn.forEach((element: any) => {
          this.scnDueDate = new Date(element.dueDate);
          if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataAja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataApa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataApt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataHc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataSc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
      }
    });

  }
  getDtHearingDateData() {
    const sessionData: any = sessionStorage.getItem('navContext');
    const parseData = JSON.parse(sessionData)
    const companyId = sessionStorage.getItem('companyId');
    let model: any = {
      criterias: [
        {
          p: "status",
          o: "nin",
          v: "CLOSED,MOVED"
        },
        {
          p: "days",
          o: "eq",
          v: this.dueDays
        }
      ],
      page: 0,
      size: 1000,
      sortfield: 'caseId',
      sortdir: 'ASC',
      companyId: parseData.companyId ? parseData.companyId : companyId
    };
    this.CommonService.getDtHdates(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.dueData = response.response;
        this.hearingDataScn = this.dueData.scn;
        this.hearingDataAja = this.dueData.assessment;
        this.hearingDataAja = this.dueData.enqProceedings;
        this.hearingDataApa = this.dueData.commissioner;
        this.hearingDataApt = this.dueData.apt;
        this.hearingDataHc = this.dueData.hc;
        this.hearingDataSc = this.dueData.sc;

        this.CommonService.sortObjectsByDate(this.hearingDataAja, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataApa, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataApt, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataHc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataSc, 'dueDate', 'desc');

        // this.hearingDataInq.forEach((element: any) => {
        //   this.scnDueDate = new Date(element.dueDate);
        //   if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
        //     element.oldDatesClr = true;
        //   }
        // });
        // this.hearingDataAss.forEach((element: any) => {
        //   this.assDueDate = new Date(element.dueDate);
        //   if (this.assDueDate.getTime() < this.todaysDate.getTime()) {
        //     element.oldDatesClr = true;
        //   }
        // });
        this.hearingDataAja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataApa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataApt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataHc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataSc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
      }
    })
  }
  getAuditDateData() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;
    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'ASC';
    modelObj.criterias = criteriaArray;
    // if (this.navContext.entityType === 'FILING') {
    //   model.gstin = this.selectedGstin;
    // } else {
    //   model.gstin = '';
    // }
    // model.days = this.hdueDays;
    this.CommonService.auditDate(modelObj).subscribe((response: any) => {
      if (response.status === 1) {
        this.auditData = response.response;
        this.CommonService.sortObjectsByDate(this.auditData.afl, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.auditData.ail, 'dueDate', 'desc');
        this.auditData.afl.forEach((element: any) => {
          this.aflDueDate = new Date(element.dueDate);
          if (this.aflDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.auditData.ail.forEach((element: any) => {
          this.ailDueDate = new Date(element.dueDate);
          if (this.ailDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });

      }
    });

  }
  getCaseData(caseId: number, action: string, type: string, tab: string) {
    this.router.navigate(['/notice', caseId, { action: action, type: type, tab: tab }]);
  }
  getPreGstCaseData(caseId: number, action: string, type: string, tab: string) {
    this.router.navigate(['/createPreGstCase', caseId, { action: action, type: type, tab: tab }]);
  }
  getDtCaseData(caseId: number, action: string, type: string, tab: string) {
    this.router.navigate(['/createDirectTaxCase', caseId, { action: action, type: type, tab: tab }]);
  }
  getDtCaseEnqData(caseId: number, action: string, tab: string) {
    this.router.navigate(['/createDirectTaxCase', caseId, { action: action, tab: tab }]);
  }
  getAuditCaseData(auditId: number, action: string, type: string, tab: string) {
    this.router.navigate(['/createAudit', auditId, { action: action, type: type, tab: tab }]);
  }
  newsUpdateData() {
    this.CommonService.newsUpdate().subscribe((response: any) => {
      if (response.status === "SUCCESS") {
        this.newsList = response.response.inactiveNews;
      }
    });

  }
  getAuditOpenCount() {

    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "eq",
      "v": "OPEN"
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'auditId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    this.litService.getAllAuditDashboardCount(modelObj).subscribe((res: any) => {
      this.auditcntOPEN = res.response;
    })
  }

  animateDots() {
    const dotsCount = 3;
    const delay = 500;

    let dotCount = 0;

    setInterval(() => {
      this.loadingText = 'Coming Soon' + '.'.repeat(dotCount);
      dotCount = (dotCount + 1) % (dotsCount + 1);
    }, delay);
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  statsTabClick(tab: any) {
    this.selectedStatsTab = tab;
  }
  changeStatsValue(selected: any) {
    if (selected == false) {
      this.isinCR = true;
      this.isinLakh = false;
    } else if (selected == true) {
      this.isinCR = false;
      this.isinLakh = true;
    }
  }
  checkRole(role: string) {
    if (role != undefined) {
      this.isGSTRole = role.startsWith('GST_') ? true : false;
      this.isPREGSTRole = role.startsWith('PREGST_') ? true : false;
      this.isDTRole = role.startsWith('DT') ? true : false;
    }

  }
  hideIcons:boolean= true
  showHeader: boolean = false;
  showHeader1: boolean = false;
  showStatus: boolean = true;
  showDownload: boolean = false;
  showPop: boolean = false;
  checkIcon: boolean = false;
  statusData: any;



  showHidePop() {
    this.showPop = !this.showPop;
    setTimeout(() => {
      // set it to run again after ten minutes
      this.showPop = false;
      this.toaster.showError(
        'Request is time out, please place new request after some time.'
      );
    }, 600000);
  }
  closeDiv() {
    this.showPop = false;
  }
  relopageRefresh() {
    if (this.router.url.includes('dashboard')) {
      this.CommonService.reloadCurrentRoute();
    }
  }
  statusReport() {
    this.litService.statusMasterReport().subscribe((response: any) => {
      if (response.status === 1) {
        this.statusData = response.response.status;
        if (this.statusData == 1) {
          this.showStatus = false;
          this.showDownload = true;
          this.toaster.showSuccess('Request processed successfully');
        } else {
          this.toaster.showWarning('Request in progress');
        }
      }
    });
  }

  downLoadMasterReport() {
    this.litService.downLoadReport().subscribe((response) => {
      const rootPan = sessionStorage.getItem('company');
      const date = this.CommonService.getCurrentDate();
      let fileName = `${rootPan}_Consolidated_Master_Report_${date}`;
      if (this.showHeader) {
       fileName = `${rootPan}_PROV & CONT Report_${date}`;
      }
      this.excelService.saveAsExcelFile(response, fileName, true);
      this.checkIcon = true;
      this.toaster.showSuccess('File downloaded successfully');
      this.closeDiv();
    });
  }

  downloadFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let compName = sessionStorage.getItem('company');
    let datetime = formatDate(new Date(), 'dd-MM-yyyy', 'en-US', '+0530');
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute(
      'download',
      `${compName}_GST Litigation Report_${datetime}.xlsx`
    );
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  generatedReport(option:string) {
    this.hideIcons = true;
    if (option == 'Contingency') {
      this.showHeader = true;
    } else if (option == 'Master') {
      this.showHeader = false;
    }
    this.showHidePop();
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    let model: any = {};
    const criteriaArray: any = [{
      "p": "pan",
      "o": "eq",
      "v": this.navContext.pan
    }]
    this.showStatus = true;
    this.showDownload = false;

    if (this.navContext.entityType === 'Business') {
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 8;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.role = localStorage.getItem('roleName');

    } else if (this.navContext.entityType === 'LEGAL') {
      const savedFilterCritera = savedFilters !== null ? savedFilters.criterias : [];
      const mergeCriteriaArray = [...savedFilterCritera, ...criteriaArray];
      model.companyId = localStorage.getItem('selectedId');
      model.role = localStorage.getItem('roleName');
      model.reportType = 8;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
    }
    this.litService.generateReportDashboard(model, '8').subscribe((response) => {
      this.toaster.showSuccess("Request submitted successfully");
      this.hideIcons = false;
    })
  }


  generatedMasterReport(option:string) {
    this.hideIcons = true;
    if (option == 'Contingency') {
      this.showHeader = true;
    } else if (option == 'Master') {
      this.showHeader = false;
    }
    this.showHidePop();
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    let model: any = {};
    const criteriaArray: any = [{
      "p": "pan",
      "o": "eq",
      "v": this.navContext.pan
    }]
    this.showStatus = true;
    this.showDownload = false;

    if (this.navContext.entityType === 'Business') {
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 9;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.role = localStorage.getItem('roleName');

    } else if (this.navContext.entityType === 'LEGAL') {
      const savedFilterCritera = savedFilters !== null ? savedFilters.criterias : [];
      const mergeCriteriaArray = [...savedFilterCritera, ...criteriaArray];
      model.companyId = localStorage.getItem('selectedId');
      model.role = localStorage.getItem('roleName');
      model.reportType = 9;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
    }
    this.litService.generateReportDashboard(model, '9').subscribe((response) => {
      this.toaster.showSuccess("Request submitted successfully");
      this.hideIcons = false;
    })
  }


  gstPregstDueDate() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.gstin = '';
    } else if (this.navContext.entityType === 'Business') {
      model.gstin = '';
      model.pan = '';
    }
    model.days = this.dueDays;
    if (this.role === 'IDT_Admin' || this.role === 'undefined' || this.role === 'NoRole' || this.role === 'IDT_Viewer' || this.role === 'IDT_Preparer') {
      combineLatest(this.CommonService.getGstAllDueDate(modelObj), this.CommonService.getPreGstAllDueDate(modelObj)).subscribe((
        response: any) => {
        const { scn, aja: aja1, apa: apa1, apt: apt1, hc: hc1, sc: sc1 } = response[0].response;
        const { aja: aja2, apa: apa2, apt: apt2, hc: hc2, sc: sc2 } = response[1].response;
        this.viewDataScn = scn;
        this.viewDataAja = aja1;
        this.viewDataApa = apa1;
        this.viewDataApt = apt1;
        this.viewDataHc = hc1;
        this.viewDataSc = sc1;
        if (this.navContext.entityType !== 'FILING') {
          this.viewDataAja.push(...aja2);
          this.viewDataApa.push(...apa2);
          this.viewDataApt.push(...apt2);
          this.viewDataHc.push(...hc2);
          this.viewDataSc.push(...sc2);
        }

        this.CommonService.sortObjectsByDate(this.viewDataApa, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataApt, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataHc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataSc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataScn, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.viewDataAja, 'dueDate', 'desc');

        this.viewDataScn.forEach((element: any) => {
          this.scnDueDate = new Date(element.dueDate);
          if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });

        this.viewDataAja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });

        this.viewDataApa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataApt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataHc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.viewDataSc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
      });
    }
  }

  gstPrestHearingDate() {
    let model: any = {};
    const modelObj: any = {};
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const businessCompanyId = sessionStorage.getItem('companyId');
    const parseData = JSON.parse(navCOntextData)
    const criteriaArray: any = [{
      "p": "status",
      "o": "nin",
      "v": "MOVED,CLOSED"
    },
    {
      "p": "days",
      "o": "eq",
      "v": this.dueDays
    }]
    if (this.navContext.entityType == 'Business') {
      modelObj.companyId = businessCompanyId;
    } else if (this.navContext.entityType == 'LEGAL') {
      modelObj.companyId = parseData.companyId;
    } else if (this.navContext.entityType == 'FILING') {
      modelObj.companyId = parseData.companyId;

    }
    modelObj.page = 0;
    modelObj.size = 1000;
    modelObj.sortfield = 'caseId';
    modelObj.sortdir = 'DESC';
    modelObj.criterias = criteriaArray;
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.gstin = '';
    } else if (this.navContext.entityType === 'Business') {
      model.gstin = '';
      model.pan = this.navContext.pan;
    }
    model.days = this.dueDays;
    if (this.role === 'IDT_Admin' || this.role == 'undefined' || this.role == 'NoRole' || this.role === 'IDT_Viewer' || this.role === 'IDT_Preparer') {
      combineLatest(this.CommonService.getGstHearingDates(modelObj), this.CommonService.getPreGstHearingDates(modelObj)).subscribe((
        response: any) => {

        const {scn:scn1,aja: aja1, apa: apa1, apt: apt1, hc: hc1, sc: sc1 } = response[0].response;
        const { aja: aja2, apa: apa2, apt: apt2, hc: hc2, sc: sc2 } = response[1].response;
        this.hearingDataScn = scn1;
        this.hearingDataAja = aja1;
        this.hearingDataApa = apa1;
        this.hearingDataApt = apt1;
        this.hearingDataHc = hc1;
        this.hearingDataSc = sc1;
        if (this.navContext.entityType !== 'FILING') {
          this.hearingDataAja.push(...aja2);
          this.hearingDataApa.push(...apa2);
          this.hearingDataApt.push(...apt2);
          this.hearingDataHc.push(...hc2);
          this.hearingDataSc.push(...sc2);
        }

        this.CommonService.sortObjectsByDate(this.hearingDataScn, 'dueDate', 'desc'); 
        this.CommonService.sortObjectsByDate(this.hearingDataAja, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataApa, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataApt, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataHc, 'dueDate', 'desc');
        this.CommonService.sortObjectsByDate(this.hearingDataSc, 'dueDate', 'desc');

        this.hearingDataScn.forEach((element: any) => {
          this.scnDueDate = new Date(element.dueDate);
          if (this.scnDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataAja.forEach((element: any) => {
          this.ajaDueDate = new Date(element.dueDate);
          if (this.ajaDueDate.getTime() < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataApa.forEach((element: any) => {
          this.apaDueDate = new Date(element.dueDate);
          if (this.apaDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataApt.forEach((element: any) => {
          this.aptDueDate = new Date(element.dueDate);
          if (this.aptDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataHc.forEach((element: any) => {
          this.hcDueDate = new Date(element.dueDate);
          if (this.hcDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
        this.hearingDataSc.forEach((element: any) => {
          this.scDueDate = new Date(element.dueDate);
          if (this.scDueDate < this.todaysDate.getTime()) {
            element.oldDatesClr = true;
          }
        });
      });
    }
  }
}
