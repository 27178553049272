import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import { BusinessService } from 'src/app/businessManagement/services/business.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { DtNmStats, NoticeServiceService } from '../../services/notification.service';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-dt-summary',
  templateUrl: './dt-summary.component.html',
  styleUrl: './dt-summary.component.css'
})
export class DtSummaryComponent implements OnInit{

  navContext: any;
  orgSetting!: UntypedFormGroup;
  formData: any = {};
  isSubmitted: boolean = false;
  navContextData: any;
  companyId: any;
  orgID: any;
  selectedGstin: any;
  selectedFilling: any;
  orgData: any;
  apiKey: any;
  dtNmStats: null | DtNmStats = null;

  constructor(
    private router: Router,
    private noticeService: NoticeServiceService,
    private fBuild: UntypedFormBuilder,
    private getterSetter: GetterSetterService,
    private toaster: ToasterService,
    private businessService: BusinessService,
    private cdr: ChangeDetectorRef
  ) {
    this.navContextData = JSON.parse(this.getterSetter.getNavContextData() || '{}');
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        console.log('NavigationStart:', event);
      } else if (event instanceof NavigationEnd) {
        console.log('NavigationEnd:', event);
      } else if (event instanceof NavigationError) {
        console.error('NavigationError:', event.error);
      }
    });
  }

  ngOnInit(): void {
    this.navContext = JSON.parse(sessionStorage.getItem('navContext') || '{}');
    this.companyId = this.navContextData.companyId;
    this.noticeCount();
    this.initSettingData();
  }
  toProceedings(isNew: any) {
    sessionStorage.setItem('isNewNotice', isNew)
    this.router.navigate(['/dtNmProceeding']);
  }
  
  toVideoConfrencing(isNew: any) {
    sessionStorage.setItem('isNewNotice', isNew)
    this.router.navigate(['/dtNmVc']);
  } 

  toAdjournment(isNew: any) {
    sessionStorage.setItem('isNewNotice', isNew)
    this.router.navigate(['/dtNmAdj']);
  }

  toDtNotice(isNew: any) {
    sessionStorage.setItem('isNewNotice', isNew)
    this.router.navigate(['/dtNmNotices']);
  }

  getOrgData() {
    let urlData = {
      companyId: this.getterSetter.getSetCompanyId()
    }
    this.noticeService.getOrgID(urlData).subscribe((response: any) => {
      if (response.status === 1) {
        this.orgData = response.response;
        this.settingFormInit();
      }
    });
  }

  initSettingData() {
    if (this.navContextData.companyId != null || this.navContextData.orgId != null) {
      this.companyId = this.navContextData.companyId;
    } else {
      this.companyId = this.getterSetter.getSetCompanyId();
    }
    this.businessService.viewEntity(this.companyId).subscribe((response: any) => {
      if (response.status === "SUCCESS") {
        if (response.response !== null && response.response !== "") {
          this.formData = response.response;
        }
      } else {
        this.toaster.showError(response.message);
      }
      this.settingFormInit();
    });
  }

  settingFormInit() {
    this.orgSetting = this.fBuild.group({
      companyname: [this.formData.companyName],
      pan: [this.formData.panNo],
      companyId: [this.companyId],
      orgId: [this.orgData?.orgId],
      apiKey: [this.orgData?.apiKey]
    });
  }

  saveSetting() {
    this.isSubmitted = true;
    if (this.orgSetting.valid) {
      if (this.navContextData.companyId != null) {
        this.orgSetting.value.companyid = this.formData.companyId;
      }
      this.noticeService.saveOrgId(this.orgSetting.value).subscribe((response: any) => {
        if (response.status == 1) {
          this.formData = {};
          this.toaster.showSuccess(response.response);
        } else {
          this.toaster.showWarning(response.errorList[0].message);
        }
        this.isSubmitted = false;
      });
    }
  }

  noticeCount(){
    let model: any = {};
    model.companyId = sessionStorage.getItem('companyId');
    model.sortdir = "ASC",
      model.sortfield = "createdAt",
      model.module = "DT",
      this.noticeService.getDtNmCount(model).subscribe((response:any) => {
        if (response.status === 1) {
            this.dtNmStats = response.response;
        }
      });
  }

  isOrgIdDisabled(): boolean {
    return !!this.orgSetting.controls.orgId.value;
  }
  isApiKeyDisabled(): boolean {
    return !!this.orgSetting.controls.apiKey.value;
  }
}
