export class CreateEnquiryProceedingRequest {
  phase!: 1 | 2 | 3;
  action!: string;
  caseType!: string;
  pan!: string;
  panName!: string;
  ay!: string;
  caseId!: string;
  finYear!: string;
  consultant!: string;
  personResp!: string;
  caseSummary!: string;
  internalRemarks!: string;
  caseLabel!: string;
  status!: string;
  dtNotices!: [
    {
      issuingAuth: string;
      noticeRefNo: string;
      noticeIssuedUs: string;
      noticeType: string;
      noticeDate: string;
      noticeReceiptDate: string;
      noticeReceiptMode: string;
      endOfAy: string;
      yearPassedNo: string;
      noticeIssuedInLimTime: string;
      legalDueDate: string;
      internalDueDate: string;
      isExtApplied: string;
      extDueDate: string;
      noticeSummary: string;
      internalRemarks: string;
      reqDetails: ReqDetails[];
      adjSubDetails: AdjSubDetails[];
      noticeLoc: CommonLoc[];
      issuingAuthority: IssuingAuthority[];
    }
  ];
  hearingDetails!: hearing[];
  deptResponseDetails!: [
    {
      respDate: string;
      din: string;
      respReceiptDate: string;
      replyDueDate: string;
      respSummary: string;
      attchmentsLoc: CommonLoc[];
    }
  ]
  appealDetails!: appeal[];
}

// export class dtNotices{
//   issuingAuth!: string;
//       noticeRefNo!: string;
//       noticeIssuedUs!: string;
//       noticeType!: string;
//       noticeDate!: string;
//       noticeReceiptDate!: string;
//       noticeReceiptMode!: string;
//       endOfAy!: string;
//       yearPassedNo!: string;
//       noticeIssuedInLimTime!: string;
//       legalDueDate!: string;
//       internalDueDate!: string;
//       isExtApplied!: string;
//       extDueDate!: string;
//       noticeSummary!: string;
//       internalRemarks!: string;
// }

/* Create Intimation Order Interface */
export class CreateIntimationOrderRequest {
  phase!: 1 | 2 | 3;
  action!: string;
  caseType!: string;
  pan!: string;
  panName!: string;
  caseId!: string
  ay!: string;
  status!: string;
  finYear!: string;
  consultant!: string;
  personResp!: string;
  caseLabel!: string;
  caseSummary!: string;
  internalRemarks!: string;
  orderNo!: string;
  orderDate!: string;
  orderReceiptDate!: string;
  orderReceiptMode!: string;
  noticeType!: string;
  noticeSummary!: string;
  isDeviationRetFiled!: boolean;
  isDemandRaisedOrReduced1!: string;
  demandAmt1!: string;
  taxImpactFTotal!: string;
  penaltyFTotal!: string;
  taxRateFTotal!: string;
  interestFTotal!: string;
  totOfTotal!: string;
  totOfAddnOrDisOfExpensesF!: string;
  taxImpactLTotal!: string;
  penaltyLTotal!: string;
  taxRateLTotal!: string;
  interestLTotal!: string;
  totalLTotal!: string;
  addnOrDisOfExpensesLTotal!: string;
  itrDetails!: ItrDetails[];
  issuingAuthority!: IssuingAuthority[];
  issues!: Issue[];
  orderCopyLoc!: CommonLoc[];
  libDetails1!: [
    {
      srNo: string;
      cinRefNo: string;
      date: string;
      bsrCode: string;
      tax: string;
      interest: string;
      total: string;
      isTotalRow: string;
      challanCopyLoc: CommonLoc[];
    }
  ];
  refundDetails1!: [
    {
      receiptDate: string;
      mode: string;
      tax: string;
      interest: string;
      total: string;
      isTotalRow: string;
    }
  ];
  appealDetails!: appeal[];
  officerDetails!: officerDetails[];
  isDemandRaisedOrReduced3!: string;
  demandAmt3!: string
  isRightsTransToAO!: boolean;
  cc!: consultant[];
}

/* Create Assessment Interface */
export class CreateAssessmentRequest {
  phase!: 1 | 2 | 3;
  action!: string;
  caseType!: string;
  pan!: string;
  panName!: string;
  ay!: string;
  finYear!: string;
  consultant!: string;
  personResp!: string;
  caseLabel!: string;
  caseSummary!: string;
  internalRemarks1!: string;
  taxImpactFTotal!: string;
  penaltyFTotal!: string;
  taxRateFTotal!: string;
  interestFTotal!: string;
  totOfTotal!: string;
  totOfAddnOrDisOfExpensesF!: string;
  taxImpactLTotal!: string;
  penaltyLTotal!: string;
  taxRateLTotal!: string;
  interestLTotal!: string;
  totalLTotal!: string;
  addnOrDisOfExpensesLTotal!: string;
  dtNotices!: [
    {
      issuingAuth: string;
      noticeRefNo: string;
      noticeIssuedUs: string;
      noticeType: string;
      noticeDate: string;
      noticeReceiptDate: string;
      noticeReceiptMode: string;
      endOfAy: string;
      yearPassedNo: string;
      noticeIssuedInLimTime: string;
      legalDueDate: string;
      internalDueDate: string;
      isExtApplied: string;
      extDueDate: string;
      noticeSummary: string;
      internalRemarks: string;
      issues: Issue[];
      reqDetails: ReqDetails[];
      adjSubDetails: AdjSubDetails[];
      noticeLoc: CommonLoc[];
      issuingAuthority: IssuingAuthority[];
    }
  ];
  itrDetails!: ItrDetails[];
  writToHc!: Boolean;
  issues!: Issue[];

}

/* Create Commissionor Interface */
export class CreateCommissionorRequest {
  phase!: 1 | 2 | 3;
  action!: string;
  caseType!: string;
  pan!: string;
  panName!: string;
  ay!: string;
  finYear!: string;
  consultant!: string;
  personResp!: string;
  caseLabel!: string;
  caseSummary!: string;
  reason!: string;
  caseMovedFrom!: string;
  internalRemarks1!: string;
  orderUs!: string;
  orderNo1!: string;
  orderDate1!: string;
  orderReceiptDate1!: string;
  orderReceiptMode1!: string;
  appealDueDate!: string;
  internalDueDate!: string;
  noticeType!: string;
  orderPassedBy1!: string;
  noticeSummary!: string;
  isDeviationRetFiled!: boolean;
  isDemandRaisedOrReduced1!: string;
  taxImpactFTotal!: string;
  penaltyFTotal!: string;
  taxRateFTotal!: string;
  interestFTotal!: string;
  totOfTotal!: string;
  totOfAddnOrDisOfExpensesF!: string
  issues!: Issue[];
  orderCopyLoc1!: CommonLoc[];
  demandAmt1!: string;
  itrDetails!: ItrDetails[];
  stayOrder!: StayOrder[];
  predepositDetails!: PredepositDetails[];
}

/* Create ITAT Interface */
export class ItatRequest {
  phase!: 1 | 2 | 3;
  action!: string;
  caseType!: string;
  pan!: string;
  panName!: string;
  ay!: string;
  finYear!: string;
  consultant!: string;
  personResp!: string;
  caseLabel!: string;
  caseSummary!: string;
  internalRemarks1!: string;
  reason!: string;
  caseMovedFrom!: string
  orderUs!: string;
  orderNo1!: string;
  orderDate1!: string;
  orderReceiptDate1!: string;
  orderReceiptMode1!: string;
  appealDueDate!: string;
  itatNoticeReceiptDate!: string;
  crossObjDueDate!: string;
  internalDueDate!: string;
  noticeType!: string;
  orderPassedBy!: string;
  noticeSummary!: string;
  applBookByDptLoc!: CommonLoc[];
  orderCopyLoc1!: CommonLoc[];
  issues!: Issue[];
  taxImpactFTotal!: string;
  penaltyFTotal!: string;
  taxRateFTotal!: string;
  interestFTotal!: string;
  totOfTotal!: string;
  totOfAddnOrDisOfExpensesF!: string;
  isDemandRaisedOrReduced1!: string;
  demandAmt1!: string;
  itrDetails!: ItrDetails[];
  stayOrder!: StayOrder[];
  predepositDetails!: PredepositDetails[];
}
/* Create high Court Interface */
export class HighCourtRequest {
  phase!: 1 | 2 | 3;
  action!: string;
  caseType!: string;
  pan!: string;
  panName!: string;
  ay!: string;
  finYear!: string;
  consultant!: string;
  personResp!: string;
  reason!: string;
  caseLabel!: string;
  caseSummary!: string;
  caseMovedFrom!: string;
  internalRemarks1!: string;
  orderUs!: string;
  orderPassedBy!: string;
  orderNo1!: string;
  orderDate1!: string;
  orderReceiptDate1!: string;
  orderReceiptMode1!: string;
  appealDueDate!: string;
  internalDueDate!: string;
  jurisdiction!: string;
  itatNoticeReceiptDate!: string;
  crossObjDueDate!: string;
  applBookByDptLoc!: CommonLoc[];
  orderCopyLoc1!: CommonLoc[];
  taxImpactFTotal!: string;
  penaltyFTotal!: string;
  taxRateFTotal!: string;
  interestFTotal!: string;
  totOfTotal!: string;
  totOfAddnOrDisOfExpensesF!: string;
  issues!: Issue[];
  isDemandRaisedOrReduced1!: string;
  demandAmt1!: string;
  itrDetails!: ItrDetails[];
  stayOrder!: StayOrder[];
  predepositDetails!: PredepositDetails[];
}

/* Create Supreme Court Interface */
export class SupremeCourtRequest {
  phase!: 1 | 2 | 3;
  action!: string;
  caseType!: string;
  pan!: string;
  panName!: string;
  ay!: string;
  finYear!: string;
  consultant!: string;
  personResp!: string;
  caseLabel!: string;
  caseSummary!: string;
  caseMovedFrom!: string;
  internalRemarks1!: string;
  reason!: string;
  orderUs!: string;
  orderNo1!: string;
  orderDate1!: string;
  orderReceiptDate1!: string;
  orderReceiptMode1!: string;
  appealDueDate!: string;
  itatNoticeReceiptDate!: string;
  crossObjDueDate!: string;
  internalDueDate!: string;
  noticeType!: string;
  orderPassedBy!: string;
  noticeSummary!: string;
  applBookByDptLoc!: CommonLoc[];
  orderCopyLoc1!: CommonLoc[];
  issues!: Issue[];
  taxImpactFTotal!: string;
  penaltyFTotal!: string;
  taxRateFTotal!: string;
  interestFTotal!: string;
  totOfTotal!: string;
  totOfAddnOrDisOfExpensesF!: string;
  isDemandRaisedOrReduced1!: string;
  demandAmt1!: string;
  itrDetails!: ItrDetails[];
  stayOrder!: StayOrder[];
  predepositDetails!: PredepositDetails[];
}

// Common

interface ProvAndLibDetails {
  finYear: string;
  quarter: string;
  month: string;
  provAmtF: string;
  contLibAmtF: string;
  othLibF: string;
  provChangeReasonF: string;
  provNotMadeReasonF: string;
  contLibReasonF: string;
  othLibReasonF: string;
  isConsInCaroF: string;
  caroAmtF: string;
  isTotalRow: string;
}

interface CommonLoc {
  fileName: string;
  fileLoc: string;
  remarks: string;
}

interface StayOrder {
  srNo: string;
  type: string;
  applicationDate: string;
  authority: string;
  applicationStatus: string;
  amount: string;
  validUptoDate: string;
  copyOfStayLoc: CommonLoc[];
}

interface PredepositDetails {
  srNo: string;
  cinRefNo: string;
  date: string;
  bsrCode: string;
  tax: string;
  interest: string;
  total: string;
  isTotalRow: string;
  challanCopyLoc: CommonLoc[];
}

interface Issue {
  issue: string;
  keyword: string;
  riskParam: string;
  matterType: string;
  addnOrDisOfExpensesF: string;
  taxRateF: string;
  taxImpactF: string;
  interestF: string;
  totalF: string;
  penaltyF: string;
  remarkF: string;
  status: string;
  isTotalRow: string;
  provAndLibDetails: ProvAndLibDetails[];
}
interface cc {
  firm: string;
  consultant: string;
  natureOfWork: string;
  fees: string;
  supportingDocsLoc: CommonLoc[];
  email: string;
  contact: string
}

interface hearing {
  phScheduledDate: string;
  phNoticeLoc: CommonLoc[];
  adjournedOrNot: boolean;
  attended: string;
  consultant: string;
  dateOfsubmission: string;
  addSubLoc: CommonLoc[];
  phRecordIfAnyLoc: CommonLoc[];
  phDiscNotes: string;
}

// interface rectification {
//   docNo: string;
//   rectifDate: string;
//   ackNo: string;
//   rectifMode: string;
//   rectifOptions: string;
//   rectifReason: string;
//   consultant: string;
//   personResp: string;
//   internalRemarks: string;
//   reqDocLoc: CommonLoc[];
//   rectifOrderReceived: boolean;
//   meetingDetails: meetingDetails[];
//   // Phase 3
//   // orderRefNo: string;
//   // orderDate: string;
//   // orderRecepitDate: string;
//   // orderReceiptMode: string;
//   // orderLoc: CommonLoc[];
//   // orderPassedBy: string;
//   // outcome: string;
//   // orderSummary: string;
//   // isAppliedForReRectif: boolean;
// }

interface meetingDetails {
  dateOfMeeting: string;
  personAttended: string;
  internalRemarks: string;
  copyOfSubmissionLoc: CommonLoc[];
}

interface consultant {
  firm: String;
  consultant: String;
  natureOfWork: String;
  fees: String;
  email: String;
  contact: String,
  supportingDocsLoc: CommonLoc[];
}

interface officerDetails {
  officer: string;
  designation: string;
  jurisdiction: string;
  address: string;
  email: string;
  contact: string;
}
interface ItrDetails {
  returnFiledUs: string;
  itrForm: string;
  ackNo: string;
  filingDate: string;
  totTaxIncome: string;
  totTaxLiability: string;
  totTaxPaid: string;
  refundDemand: string;
  isTotalRow: string;
}

interface IssuingAuthority {
  noticeNo: string;
  officer: string;
  designation: string;
  jurisdiction: string;
  address: string;
  email: string;
  contact: string;
}
interface appeal {
  orderNo: string;
  action: string;
  forum: string;
  isCaseCreated: boolean;
}

interface cumulative{
  label: string;
  tax: string;
  interest:string;
  penalty: string;
  total: string;
}


interface AdjSubDetails {
  adjFiledDate: string;
  newAdjDate: string;
  adjReason: string;
  isGranted: string;
  adjDocsLoc: CommonLoc[];
}

interface ReqDetails {
  srNo: string;
  docReq: string;
  remarks: string;
  docLoc: CommonLoc[];
}

// API response
export interface SummaryPageResponse {
  phase: number;
  caseId: string;
  pan: string;
  panName: string;
  ay: string;
  finYear: string;
  createDate: string;
  updateDate: string;
}

export interface Summarycount {
  stage: string,
  openCaseCnt: number,
  totCaseCnt: number
}
