import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/services/auth.service';
import { LitigationSummaryComponent } from './litigation/litigation-summary/litigation-summary.component';
import { TokenService } from './shared/services/token.service';
declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'DAA-LMS';
  showHead = false;

  // idleTime = 15 * 60 * 1000; // 15 minutes in milliseconds
  // timeoutId: any;
  
  constructor(
    private auth: AuthService,
    private router: Router,
    private tokenService: TokenService
  ) {
    this.addGAScript();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event : any) => {
      /** START : Code to Track Page View  */
       gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
       })
    });
    // this.showheader();
    this.auth.getLoginIndicator().subscribe((value) => {
      if (value === 0) {
        this.showHead = false;
      } else if (value === 1) {
        this.showHead = false;
      } else if (value === 2) {
        this.showHead = true;
        // Reset idle timeout on any user activity
        // document.addEventListener('mousemove', this.resetIdleTimeout);
        // document.addEventListener('keypress', this.resetIdleTimeout);

        // this.startTimerAgain();
      }
    });

    
  }
  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.GA_TRACKING_ID;
    document.head.prepend(gtagScript);
    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', environment.GA_TRACKING_ID, { send_page_view: false });
  } 
  
  // resetIdleTimeout = () => {
  //   clearTimeout(this.timeoutId);
  //   this.startTimerAgain();
  // }

  // startTimerAgain() {
  //    this.timeoutId = setTimeout(() => {
  //      // Logout logic here
  //      console.log('Logging out due to inactivity');
  //      if (this.tokenService.destroy()) {
  //        this.auth.setLoginIndicator(0);
  //        window.open(this.tokenService.getUumUrl(), '_self');
  //      }

  //    }, this.idleTime);
  //  }

}
