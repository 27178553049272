import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NoticeServiceService } from '../../services/notification.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { NOTICE_USER_ACTION, badgeMapInvoice } from 'src/app/shared/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
interface Filters {
  p: 'gstin' | 'parsedSubDt';
  o: 'in' | 'between';
  v?: string | number | null;
  v1?: string | number | null;
  v2?: string | number | null;
}
@Component({
  selector: 'app-gst-submission',
  templateUrl: './gst-submission.component.html',
  styleUrls: ['./gst-submission.component.css']
})
export class GstSubmissionComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal!: ElementRef;
  isAccessGiven: boolean = false;
  subOrderData: any;
  navContext: any;
  companyId: number = Number(sessionStorage.getItem('companyId'));
  selectedFilling: any;
  selectedGstin: any;
  pageSection1: number = 1;
  tableSize: number = 10;
  userAct = NOTICE_USER_ACTION;
  userActionData: {
    "gstin": string,
    "arnNo": string,
    "action": string,
    "companyId": number
  } = {
      gstin: '',
      arnNo: '',
      action: '',
      companyId: 0
    };
  submissionCount: number = Number(sessionStorage.getItem('submissionCount') ?? 10);
  perPageLimitSection1 = 10;
  tableSection1Config = {
    itemsPerPage: this.perPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'tableSection1Config',
  };

  filterObj: any = {};
  isFilterhighlited: boolean = false;
  isFilterDivOpen: boolean = false;
  filterType: string = 'GSTIN';
  badgesList: any[] = [];
  isApplyFilter!: boolean;
  isResData: boolean = false;
  isGstin: boolean = false;
  recordCount: any;
  criteria: any = [];
  criteriaObje: any = {};
  filteredQueryData: any;
  badgesMap: any = badgeMapInvoice;
  filterPayload: any = {};
  queryData: any;
  currentYear: any;
  nextWeekDt: any;
  nextMonthDt: any;
  nxtDtdd1: any;
  nxtDtdd2: any;
  nxtMonthdd1: any;
  nxtMonthdd2: any;
  gstinData: any;
  BHData: any;
  filters: { [key: string]: Filters } = this.initFilters();
  issueDateArray: Array<string> | null = null;
  badgeColorArray = [
    'border-success',
    'border-danger',
    'border-warning',
    'border-dark',
    'border-success',
    'border-danger',
    'border-warning',
    'border-dark',
    'border-success',
  ];
  textColorArray = [
    'text-success',
    'text-danger',
    'text-warning',
    'text-dark',
    'text-success',
    'text-danger',
    'text-warning',
    'text-dark',
    'text-success',
  ];
  constructor(
    private router: Router,
    private noticeService: NoticeServiceService,
    private toaster: ToasterService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    this.filters = <any>JSON.parse(sessionStorage.getItem('subFilter') || '{}');
    this.filters = this.initFilters();
    this.navContext = JSON.parse(sessionStorage.getItem('navContext') || '{}');
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.tableSection1Config.totalItems = this.submissionCount;
    this.tableSize = this.submissionCount;
    this.getSubmissionDataList();
    this.getGstinList();
    this.setDate();
  }

  backTo() {
    this.router.navigate(['/gstNoticetMSummary']);
  }
  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  toSubmissionDetail(applnId: number) {
    this.router.navigate(['/gstSubmissionDetail', applnId]);
  }

  initFilters(): { [key: string]: Filters } {
    return {
      gstin: { p: 'gstin', o: 'in', v: null },
      parsedSubDt: { p: 'parsedSubDt', o: 'between', v1: null, v2: null },
    }
  }
  getFilters() {
    sessionStorage.setItem('subFilter', JSON.stringify(this.filters));
    return Object.values(this.filters).filter((el) => !!el.v || !!el.v1 || !!el.v2);
  }

  setAction(data: any) {
    this.userActionData = {
      gstin: '',
      arnNo: '',
      action: '',
      companyId: 0
    };

    this.userActionData.gstin = data.gstin;
    this.userActionData.arnNo = data.arn;
    this.userActionData.companyId = this.companyId;
  }
  saveAccess() {
    this.noticeService.updateSubmissionPr(this.userActionData).subscribe((response: any) => {
      if (response.status == 1) {
        this.getSubmissionDataList();
        this.isAccessGiven = true;
        this.closeModal.nativeElement.click();
        this.toaster.showSuccess(response.response);
      } else {
        this.toaster.showError(response.errorList[0].message);
      }

    });
  }

  downloadExFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `Submission.xlsx`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  //pagination
  getSection1Data(perPageLimitSection1: any, section1page: number) {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      criterias: this.getFilters(),
      page: section1page == 1 ? 0 : section1page - 1,
      size: perPageLimitSection1,
      companyId: this.companyId,
      sortdir: "DESC",
      sortfield: "parsedSubDt",
      module: "GST",
      newRecord: isNewCase === "Newnotice"
    }

    const entityCrieterias : any = [{
      "p":"gstin",
      "o":"in",
      "v":this.selectedGstin
    }] 
    const entityCrieteriasPan : any = [{
      "p":"pan",
      "o":"in",
      "v":this.navContext.pan
    }]

    if (this.navContext.entityType === 'FILING') payload.criterias = [...this.getFilters(),...entityCrieterias];
    if (this.navContext.entityType === 'LEGAL') payload.criterias = [...this.getFilters(),...entityCrieteriasPan];
    console.log('payload', payload);
    this.noticeService.getSubmissionData(payload).subscribe((res: any) => {
      if (res.status == 0) {
        this.subOrderData = [];
        this.toaster.showError(res.message);
        this.isResData = true;
      } else {
        this.subOrderData = res.response.results;
        this.tableSection1Config.totalItems = res.response.count;
        this.tableSection1Config.itemsPerPage = perPageLimitSection1;
        this.recordCount = res.response.count;
        this.tableSection1Config.currentPage = payload.page + 1;
        this.isResData = false;
      }
    });
  }
  onTableDataChangeSection1(page: number): void {
    this.tableSection1Config.currentPage = page;
    this.getSubmissionDataList();
  }
  //pagination


  // filterData() {
  //   if (Object.keys(this.filterObj).length) {
  //     let body = cloneDeep(this.filterObj);
  //     if (
  //       body.subDate != null ||
  //       body.gstin != null
  //     ) {
  //       this.isFilterhighlited = true;
  //     } else {
  //       this.isFilterhighlited = false;
  //     }
  //     this.noticeService.getNoticeData(body).subscribe((response: any) => {
  //       if (response.status == 1) {
  //         this.subOrderData = response.response;
  //       }
  //     });

  //   }
  // }





  getSubmissionDataList() {
    if (this.issueDateArray && this.issueDateArray.length > 0) {
      this.filters.parsedSubDt.v1 = this.issueDateArray[0];
      if (!this.issueDateArray[1]) {
        return;
      } else {
        this.filters.parsedSubDt.v2 = this.issueDateArray[1];
      }
    }
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.tableSection1Config.currentPage - 1,
      size: this.perPageLimitSection1,
      companyId: this.companyId,
      sortdir: "DESC",
      sortfield: "parsedSubDt",
      module: "GST",
      newRecord: isNewCase === "Newnotice",
      criterias: this.getFilters()
    }

    const entityCrieterias : any = [{
      "p":"gstin",
      "o":"in",
      "v":this.selectedGstin
    }] 
    const entityCrieteriasPan : any = [{
      "p":"pan",
      "o":"in",
      "v":this.navContext.pan
    }]

    if (this.navContext.entityType === 'FILING') payload.criterias = [...this.getFilters(),...entityCrieterias];
    if (this.navContext.entityType === 'LEGAL') payload.criterias = [...this.getFilters(),...entityCrieteriasPan];
    console.log('payload', payload);
    this.noticeService.getSubmissionData(payload).subscribe((res: any) => {
      if (res.status == 0) {
        this.subOrderData = [];
        this.toaster.showError(res.message);
        this.isResData = true;
      } else {
        this.subOrderData = res.response.results;
        this.tableSection1Config.totalItems = res.response.count;
        this.recordCount = res.response.count;
        this.tableSection1Config.currentPage = payload.page + 1;
        this.isResData = false;
      }
    });
  }
  getSubmissionData() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.tableSection1Config.currentPage - 1,
      size: this.perPageLimitSection1,
      companyId: this.companyId,
      sortdir: "DESC",
      sortfield: "parsedSubDt",
      module: "GST",
      newRecord: isNewCase === "Newnotice",
      criterias: this.getFilters()
    }

    const entityCrieterias : any = [{
      "p":"gstin",
      "o":"in",
      "v":this.selectedGstin
    }] 
    const entityCrieteriasPan : any = [{
      "p":"pan",
      "o":"in",
      "v":this.navContext.pan
    }]

    if (this.navContext.entityType === 'FILING') payload.criterias = [...this.getFilters(),...entityCrieterias];
    if (this.navContext.entityType === 'LEGAL') payload.criterias = [...this.getFilters(),...entityCrieteriasPan];
    console.log('payload', payload);
    this.noticeService.getSubmissionData(payload).subscribe((res: any) => {
      if (res.status == 0) {
        this.subOrderData = [];
        this.toaster.showError(res.message);
        this.isResData = true;
      } else {
        this.subOrderData = res.response.results;
        this.tableSection1Config.totalItems = res.response.count;
        this.recordCount = res.response.count;
        this.tableSection1Config.currentPage = payload.page + 1;
        this.isResData = false;
      }
    });
  }
  clearIssueGst() {
    if (this.issueDateArray && this.issueDateArray.length > 0) {
      this.filters.parsedSubDt.v1 = null;
      this.filters.parsedSubDt.v2 = null;
    }
    this.getSubmissionData();
    this.issueDateArray = null;
  }
  clearFilter(inputName: string) {
    switch (inputName) {
      case 'gstin':
        this.filters.gstin.v = null;
        break;
      default:
        break;
    }
    this.getSubmissionData();
  }
  downloadEXFile() {
    this.getFilters();
    this.dwdExpExcel();
  }
  dwdExpExcel() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.tableSection1Config.currentPage,
      size: this.perPageLimitSection1,
      companyId: this.companyId,
      newRecord: isNewCase === "Newnotice",
       sortdir: "DESC",
      sortfield: "parsedSubDt",
      criterias: this.getFilters()
    }

    const entityCrieterias : any = [{
      "p":"gstin",
      "o":"in",
      "v":this.selectedGstin
    }] 
    const entityCrieteriasPan : any = [{
      "p":"pan",
      "o":"in",
      "v":this.navContext.pan
    }]

    if (this.navContext.entityType === 'FILING') payload.criterias = [...this.getFilters(),...entityCrieterias];
    if (this.navContext.entityType === 'LEGAL') payload.criterias = [...this.getFilters(),...entityCrieteriasPan];
    console.log('payload', payload);
    this.noticeService.downloadEXSub(payload).subscribe((response: any) => {
      this.downloadExFile(response);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }


  openModal(content: any) {
    this.filterType = 'GSTIN';
    this.modalService.open(content);
  }
  //filter
  getGstinList() {
    const payload: any = {
      companyId: this.companyId,
    }
    const entityCrieterias : any = [{
      "p":"gstin",
      "o":"in",
      "v":this.selectedGstin
    }] 
    const entityCrieteriasPan : any = [{
      "p":"pan",
      "o":"in",
      "v":this.navContext.pan
    }]

    if (this.navContext.entityType === 'FILING') payload.criterias = [...this.getFilters(),...entityCrieterias];
    if (this.navContext.entityType === 'LEGAL') payload.criterias = [...this.getFilters(),...entityCrieteriasPan];
    console.log('payload', payload);
    this.noticeService.gstGstins(payload).subscribe((res: any) => {
      if (res.status == 1) {
        if (payload.companyId && payload.gstin) {
          this.gstinData = this.selectedGstin;
          this.isGstin = true;
        } else {
          this.gstinData = res.response;
          this.isGstin = false;
        }

      }
    });
  }
}
