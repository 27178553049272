<div class="panel-wrapper">
  <div class="panel-head">
    <div class="page-title">
      <h1><a [routerLink]="'/dtNmSummary'">Notice Management Direct Tax</a>>> <span>Adjournment</span></h1>
      <div class="action-bar">

        <a title="Video Conferencing" [routerLink]="'/dtNmVc'" class="iris-gst-pull-right"><i
            class="fa fa-video-camera"></i><span>{{dtNmStats?.videoConferencingCount}}</span></a>
        <a title="Notices" [routerLink]="'/dtNmNotices'" class="iris-gst-pull-right "><i
            class="fa fa-file-text-o"></i><span>{{dtNmStats?.dtnoticesCount}}</span></a>
        <a title="Proceedings" [routerLink]="'/dtNmProceeding'" class="iris-gst-pull-right"><i
            class="fa fa-balance-scale"></i><span>{{dtNmStats?.proceedingsCount}}</span></a>
      </div>
    </div>
    <div class="filter-section">
      <div class="filters-bar">
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : adjReqDateArray !== null}">Adjournment Request Date<span
              (click)="clearDt1()" [ngClass]="{'highlightclose' : adjReqDateArray !== null}" class="clearBtn"><i
                class="fa fa-close"></i></span></label>
          <div class="datepicker">
            <p-calendar [(ngModel)]="adjReqDateArray" [monthNavigator]="true" [yearNavigator]="true"
              yearRange="2010:{{currentYear}}" selectionMode="range" placeholder="From Date-To Date"
              (ngModelChange)="adjListData()" [readonlyInput]="true" inputId="range" dateFormat="dd-mm-yy"
              dataType="string"></p-calendar>
            <i class="date-icon fa fa-calendar" aria-hidden="true"></i>
          </div>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : adjSoughtDateArray !== null}">Adjournment Sought Up to <span
              (click)="clearDt2()" [ngClass]="{'highlightclose' : adjSoughtDateArray !== null}" class="clearBtn"><i
                class="fa fa-close"></i></span></label>
          <div class="datepicker">
            <p-calendar [(ngModel)]="adjSoughtDateArray" [monthNavigator]="true" [yearNavigator]="true"
              yearRange="2010:{{currentYear}}" selectionMode="range" placeholder="From Date-To Date"
              (ngModelChange)="adjListData()" [readonlyInput]="true" inputId="range" dateFormat="dd-mm-yy"
              dataType="string"></p-calendar>
            <i class="date-icon fa fa-calendar" aria-hidden="true"></i>
          </div>
        </div>

        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.pan.v !== null}">PAN <span (click)="clearFilter('pan')"
              [ngClass]="{'highlightclose' :  filters.pan.v !== null}" class="clearBtn"><i
                class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.pan.v" (ngModelChange)="adjListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let pans of filterDropDown.panName">
              <option [value]="pans.pan">{{ pans.pan }} - {{pans.name}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <p>
        <span class="totalRecord">{{totalCount}}</span>
        <a class="iris-gst-pull-right " id="download" title="Export To Excel" (click)="downloadEXFile()"><i
            class="fa fa-file-excel-o  "></i> EXPORT TO EXCEL</a>
      </p>
    </div>


  </div>

  <div class="section nopadding" *ngIf="totalCount != 0">
    <div class="row">

      <div class="col-md-12 ">
        <div class="table-responsive nopadding">
          <table class="table">
            <thead>
              <tr>
                <th width="10px">&nbsp;</th>
                <th>PAN
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.pan"
                    (click)="sort(adjSort.PAN)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.pan"
                    (click)="sort(adjSort.PAN)"></i> -->
                </th>
                <th>Adjournment Request ID
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.adjVcReqstId"
                    (click)="sort(adjSort.ADJOURNMENT_REQUEST_ID)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.adjVcReqstId"
                    (click)="sort(adjSort.ADJOURNMENT_REQUEST_ID)"></i> -->
                </th>
                <th>Reason
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.adjReason"
                    (click)="sort(adjSort.REASON)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.adjReason"
                    (click)="sort(adjSort.REASON)"></i> -->
                </th>
                <th>Adjournment Request Date
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.parsedRequestDate"
                    (click)="sort(adjSort.ADJOURNMENT_REQUEST_DATE)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.parsedRequestDate"
                    (click)="sort(adjSort.ADJOURNMENT_REQUEST_DATE)"></i> -->
                </th>
                <th>Adjournment Sought Up to
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.parsedResDueDate"
                    (click)="sort(adjSort.ADJOURNMENT_SOUGHT_UP_TO)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.parsedResDueDate"
                    (click)="sort(adjSort.ADJOURNMENT_SOUGHT_UP_TO)"></i> -->
                </th>
                <th width="180">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let obj of adjData | paginate: adjConfig; " let i="index">
                <tr
                  [ngClass]="(!obj.data.userAction || obj.data.userAction == 'Read by user' || obj.data.userAction == 'Read by User') ? 'markUnreadBg' : 'markReadBg'">
                  <td><i class="fa fa-circle"
                      [ngClass]="(!obj.data.userAction || obj.data.userAction == 'Read by User' || obj.data.userAction == 'Read by user' ) ? 'markUnread' : 'markRead'"></i>
                  </td>
                  <td>
                    <p>{{obj.data.pan}}</p>
                    <p class="subTd">{{obj.data.panName}}</p>
                  </td>
                  <td>
                    <p><a class="anchor" (click)="toAdjDetail(obj.data.adjVcReqstId)">{{obj.data.adjVcReqstId}}</a></p>
                  </td>
                  <td>{{obj.data.adjReason}}</td>
                  <td>{{obj.data.parsedRequestDate}}</td>
                  <td>{{obj.data.parsedResDueDate}}</td>
                  <td>
                    <a *ngIf="!obj.data.userAction || obj.data.userAction == 'Read by user' ||  obj.data.userAction == 'Read by User'"
                      class="anchor" (click)="setAction(obj.data)" data-toggle="modal" data-target="#confirmModel"
                      id="confirm">Mark as
                      read</a>
                    <a *ngIf="obj.data.userAction == 'Yet to read'" class="anchor" (click)="setAction(obj.data)"
                      data-toggle="modal" data-target="#confirmUnModel" id="confirm">Mark as unread</a>
                  </td>
                </tr>

              </ng-container>
            </tbody>
          </table>
          <br />
          <div class="row noMargin plr15">
            <div class="col-md-10 nopadding">
              <pagination-controls (pageChange)="onTableDataChangeAdj($event)" id="adjConfig"></pagination-controls>
            </div>
            <div class="col-md-2 nopadding">
              <!-- <select id="itemsPerPage" [(ngModel)]="config.itemsPerPage">
                <option *ngFor="let option of [5, 10, 20, 50]">{{ option }}</option>
              </select> -->
              <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection1"
                (ngModelChange)="getadjData(perPageLimitSection1,1)" id="authority">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-12">
            <ng-container  >
               <p class="noData">No Data Found</p>
            </ng-container>
         </div> -->
    </div>
  </div>
  <ng-container *ngIf="totalCount == 0">
    <p class="noData">No Data Found</p>
  </ng-container>
  <div class="modal fade" id="confirmModel" tabindex="-1" role="dialog" aria-labelledby="confirmModelLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p>Do you want to mark this as a read ?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="confirmMarkRead()">Yes</button>
          <button type="button" class="btn btn-primary" #closeModal data-dismiss="modal" aria-label="Close">No</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="confirmUnModel" tabindex="-1" role="dialog" aria-labelledby="confirmUnModelLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p>Do you want to mark this as a unread ?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="confirmMarkUnRead()">Yes</button>
          <button type="button" class="btn btn-primary" #closeUnModal data-dismiss="modal"
            aria-label="Close">No</button>
        </div>
      </div>
    </div>
  </div>
</div>
