import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NoticeServiceService } from '../../services/notification.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

@Component({
  selector: 'app-additional-notice-detail',
  templateUrl: './additional-notice-detail.component.html',
  styleUrls: ['./additional-notice-detail.component.css']
})
export class AdditionalNoticeDetailComponent implements OnInit {
  caseId: any;
  caseType: any;
  gstinName: any;
  gstin: any;
  noticedetailData: any;
  subdetailData: any;
  navContext: any;
  selectedGstin: any;
  selectedFilling: any;
  navContextData: any;
  companyId: number = Number(localStorage.getItem('selectedId'));
  selectedTab: any;
  dwdFileName: any;
  isResData: boolean = false;
  gstNoticeFile: any[] = [];
  constructor(
    private router: Router,
    private noticeService: NoticeServiceService,
    private activeSnapShot: ActivatedRoute,
    private getterSetter: GetterSetterService,
    private toaster: ToasterService,
  ) {
    this.caseId = this.activeSnapShot.snapshot.params.applnId;
    this.caseType = this.activeSnapShot.snapshot.params.type;
    this.gstinName = this.activeSnapShot.snapshot.params.gstinName;
    this.gstin = this.activeSnapShot.snapshot.params.gstin;
  }

  ngOnInit(): void {
    this.navContext = JSON.parse(sessionStorage.getItem('navContext') || '{}');
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    //this.companyId = this.navContextData.companyId;
    this.getNoticeDetailData();
  }
  backtoOrder() {
    this.router.navigate(['/gstAdditionalOrder']);
  }
  getNoticeDetailData() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    let model: any = {};
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
      model.companyId = this.companyId;
      model.criterias = [{
        "p": "caseId",
        "o": "eq",
        "v": this.caseId
      }],
        model.page = 0;
      model.size = 100;
      model.sortdir = "DESC";
      model.sortfield = "parsedDtOfAssignment";
      model.module = "GST"
      model.newRecord = isNewCase === "Newnotice"
    } else {
      model.companyId = this.companyId;
      model.criterias = [{
        "p": "caseId",
        "o": "eq",
        "v": this.caseId
      }],
        model.page = 0;
      model.size = 100;
      model.sortdir = "DESC";
      model.sortfield = "parsedDtOfAssignment";
      model.module = "GST"
      model.newRecord = isNewCase === "Newnotice"
    }
    if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
      model.criterias = [{
        "p": "caseId",
        "o": "eq",
        "v": this.caseId
      }],
        model.page = 0;
      model.size = 100;
      model.sortdir = "DESC";
      model.sortfield = "parsedDtOfAssignment";
      model.module = "GST"
      model.newRecord = isNewCase === "Newnotice"
    }
    this.companyId = this.companyId;
    this.noticeService.getAddNoticeDetail(model).subscribe((response: any) => {
      if (response.status == 0) {
        this.isResData = true;
      } else {
        this.noticedetailData = response.response;
        this.dwdFileName = this.noticedetailData[0].gstCaseFiles[0].name;
        this.isResData = false;
      }
    });

  }
  onClickFileName(gstNoticeFileData: any[] = []) {
    this.gstNoticeFile = gstNoticeFileData;
  }
  download(key: any, id: any, fileName: string) {
    let urlData = {
      s3Key: key,
      id: id
    }
    this.noticeService.downloadANFile(urlData).subscribe((response: any) => {
      this.downloadcontFile(response, fileName);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadcontFile(data: any, fileName: string) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${fileName}`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  getCaseFolderTypeLabel(caseFolderTypeCd: string): string {
    switch (caseFolderTypeCd) {
      case 'NOTCE':
        return 'Notice';
      case 'ORDRS':
        return 'Order';
      case 'REPLY':
        return 'Reply';
      case 'RCDTL':
        return 'Recovery Detail';
      case 'APLCN':
        return 'Application';
      case 'NOTAC':
        return 'Acknowledgement';
      case 'AUDIT':
        return 'Audit History';
      case 'INTIM':
        return 'Intimation';
      case 'PRCED':
        return 'Proceeding';
      default:
        return '';
    }
  }


}
