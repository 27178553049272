export enum DT_TABS {
  'ENQUIRY_PROCEEDING' = 'Enquiry Proceeding',
  'INTIMATION_ORDER' = 'Intimation Order',
  'ASSESSMENT' = 'Assessment',
  'COMMISSIONER_A' = 'Commissioner (A)',
  'APPELLATE_TRIBUNAL' = 'Appellate Tribunal',
  'HIGH_COURT' = 'High Court',
  'SUPREME_COURT' = 'Supreme Court'
}

export interface CaseDetails {
  srNo: number;
  caseId: string;
  pan: string;
  panName: string;
  ay: string;
  finYear: string;
  caseLabel: string;
  status?: string;
  totOfTotal: number;
  totOfAddnOrDisOfExpensesF: number;
  forum: string;
  totOfTotalInLakhs: number;
  totOfTotalInCr: number;
  totOfAddnOrDisOfExpensesFInLakhs: number;
  totOfAddnOrDisOfExpensesFInCr: number;
  [key: string]: any;
}

export interface StageDemands {
  label?: string;
  pan?: string;
  ay?: string;

  panDtls?: StageDemands[];
  ayDtls?: StageDemands[];
  caseDtls?: CaseDetails[];
  collapse?: boolean;

  enqProcCount: number;
  enqProcAmt: number;
  enqProcAmtInLakhs: number;
  enqProcAmtInCr: number;
  intimationCount: number;
  intimationAmt: number;
  intimationAmtInLakhs: number;
  intimationAmtInCr: number;
  assessmentCount: number;
  assessmentAmt: number;
  assessmentAmtInLakhs: number;
  assessmentAmtInCr: number;
  commissionerCount: number;
  commissionerAmt: number;
  commissionerAmtInLakhs: number;
  commissionerAmtInCr: number;
  itatCount: number;
  itatAmt: number;
  itatAmtInLakhs: number;
  itatAmtInCr: number;
  hcCount: number;
  hcAmt: number;
  hcAmtInLakhs: number;
  hcAmtInCr: number;
  scCount: number;
  scAmt: number;
  scAmtInLakhs: number;
  scAmtInCr: number;
  totalCount: number;
  totalAmt: number;
  totalAmtInLakhs: number;
  totalAmtInCr: number;
}

export interface IssueDemands {
  issue: string;
  amt: number;
  amtInLakhs: number;
  amtInCr: number;
  count: number;
  caseDtls: IssueCase[];
}

export interface AgingIssues {
  title: string;
  count: number;
  caseDtls: InceptionCase[] | ForumCase[];
}

export interface IssueCase {
  srNo: number;
  ay: string;
  caseId: string;
  caseLabel: string;
  finYear: string;
  pan: string;
  panName: string;
  totOfAddnOrDisOfExpensesF: number;
  totOfAddnOrDisOfExpensesFInLakhs: number;
  totOfAddnOrDisOfExpensesFInCr: number;
  totOfTotal: number;
  totOfTotalInLakhs: number;
  totOfTotalInCr: number;
  [key: string]: any;
}

export interface InceptionCase {
  srNo: number;
  ay: string;
  caseId: string;
  caseLabel: string;
  caseType: string;
  finYear: string;
  forum: string;
  inceptionDate: string;
  inceptionForum: string;
  orderReceiptDate: string;
  pan: string;
  panName: string;
  status: string;
  totOfAddnOrDisOfExpensesF: number;
  totOfAddnOrDisOfExpensesFInCr: number;
  totOfAddnOrDisOfExpensesFInLakhs: number;
  totOfTotal: number;
  totOfTotalInCr: number;
  totOfTotalInLakhs: number;
  [key: string]: any;
}
export interface ForumCase {
  srNo: number;
  ay: string;
  caseId: string;
  caseLabel: string;
  caseType: string;
  finYear: string;
  forum: string;
  orderReceiptDate: string;
  pan: string;
  panName: string;
  status: string;
  totOfAddnOrDisOfExpensesF: number;
  totOfAddnOrDisOfExpensesFInCr: number;
  totOfAddnOrDisOfExpensesFInLakhs: number;
  totOfTotal: number;
  totOfTotalInCr: number;
  totOfTotalInLakhs: number;
  [key: string]: any;
}
