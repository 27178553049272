import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreGstServiceService {
  AccessTabData$ = new BehaviorSubject<any>({ preajaFlag: false, preapaFlag: false, preaptFlag: false, prehcFlag: false, prescFlag: false });
  constructor(
    private http: HttpClient
  ) { }
  setAccessTabData(data: any) {
    this.AccessTabData$.next(data);
  }
  getAccessTabData() {
    return this.AccessTabData$.asObservable();
  }
  getUserResList(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');

    return this.http.get(`${environment.apiUrl}/mgmt/company/users?companyid=${companyId}`).pipe(map((resp) => {
      return resp;
    }));
  }
  getActwiseState(data: any) {
    let companyId = +(sessionStorage.getItem('companyId') || '{}');
    return this.http.get(`${environment.apiUrl}/lms2/pregstreg/fetchstate?companyId=${companyId}&actType=${data.actType}&pan=${data.pan}`).pipe(map((resp) => {
      return resp;

    }));
  }


  preGstCaseCount(data: any) {
    let companyId = +(sessionStorage.getItem('companyId') || '{}');
    return this.http.post(`${environment.apiUrl}/lms2/pregst/query/count`, { pan: data.pan, companyId: companyId }).pipe(map((resp) => {
      return resp;
    }));
  }
  //summary count

  //summary download file and its name
  getFileName(fileUrl: any) {
    if (fileUrl) {
      let index = fileUrl.lastIndexOf('/');
      return fileUrl.substring(index + 1);
    }
  }
  //download file
  downloadFile(urlData: any) {
    let options = {
      headers: new HttpHeaders({
        //'companyId': '7676'
      }),

    };
    return this.http.get(`${environment.apiUrl}/lms2/common/download?fname=${urlData.fname}`, options).pipe(map((resp) => {
      return resp;
    }));
  }
  //upload file
  uploadFile(requestBody: any, a?: any) {
    return this.http.post(`${environment.apiUrl}/lms1/file/sfsdfsdfdgfete/upload`, requestBody).pipe(map((resp) => {
      return resp;
    }));
  }


  getUploadUrl(formdata: FormData, data: any) {
    const headers = {
      'Content-Type': data.fmime,
      'fmime': data.fmime,
      'fname': data.fname
    };
    let url = data.url;
    return this.http.put<any>(url, formdata, { headers }).pipe(map((resp) => {
      return resp;
    }));
  }
  //fetch Registration Number
  fetchRegNum(data: any) {
    return this.http.get(`${environment.apiUrl}/lms2/pregstreg/get?actType=${data.actType}&state=${data.state}&pan=${data.pan}`).pipe(map((resp) => {
      return resp;
    }));
  }

  generateReport(data: any, reportType: string) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.post(`${environment.baseUrl}/reports/generate`, { pan: data.pan, gstin: data.gstin, companyId: companyId, reportType: reportType, ...data });
  }

  // generateMasterDataReport(reportType: string) {
  //   let companyId = +(localStorage.getItem('selectedId') || '{}');
  //   return this.http.post(`${environment.baseUrl}/reports/generate`, { companyId: companyId, reportType: reportType });

  // }
  statusMasterReport() {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.get(`${environment.baseUrl}/reports/status`);
  }

  downLoadMasterReports() {
    return this.http.get(`${environment.baseUrl}/reports/download`, { responseType: 'blob' });
  }



  getPreAnalytics(data: any) {
      return this.http.post(`${environment.baseUrl}/pregst/analytics?`, data).pipe(map((resp) => {
        return resp;
      }));
    
  }

  preGstGetOpenCase(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    if (data.pan != null && data.pan != '' && data.pan != undefined) {
      return this.http.post(data.regNo ? `${environment.baseUrl}/pregst/analytics-drill?companyId=${companyId}&stage=${data.stage}&regNo=${data.regNo}&pan=${data.pan}`
        : `${environment.baseUrl}/pregst/analytics-drill?companyId=${companyId}&stage=${data.stage}&state=${data.state}&pan=${data.pan}`, options
        ,).pipe(map((resp) => {
          return resp;
        }));
    } else {
      return this.http.post(data.regNo ? `${environment.baseUrl}/pregst/analytics-drill?companyId=${companyId}&stage=${data.stage}&regNo=${data.regNo}`
        : `${environment.baseUrl}/pregst/analytics-drill?companyId=${companyId}&stage=${data.stage}&state=${data.state}`, options
        ,).pipe(map((resp) => {
          return resp;
        }));
    }
  }

  pregstGetOpenCaseState(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/analytics-drill?`, data).pipe(map((resp) => {
      return resp;
    }));
  }

  clearPreAnalyticData(data:any) {
    return this.http.post(`${environment.baseUrl}/pregst/analytics?`, data).pipe(map((resp) => {
      return resp;
    }));
  }


  //  PREGST RemandedBack
  getRemandedBackForumPregstOptions(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/pregst/query/remanded-case-details`, data);
  }

  getPreGstSummaryAja(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/query/aja`, data);
  }
  getPreGstSummaryApa(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/query/apa`, data);
  }
  getPreGstSummaryApt(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/query/apt`, data);
  }
  getPreGstSummaryHc(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/query/hc`, data);
  }
  getPreGstSummarySc(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/query/sc`, data);
  }
  getAllPreGstCaseCount(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/query/count`, data);
  }
  //upload
  commonUpload(data: any, urlData: any) {
    return this.http.get(`${environment.apiUrl}/lms2/common/upload?gstinOrPan=${urlData.gstinOrPan}&field=${urlData.field}&fname=${data.name}&type=${urlData.type}`);
  }
  //create 
  saveAjaData(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/create/aja`, data);
  }
  saveApaData(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/create/apa`, data);
  }
  saveAptData(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/create/apt`, data);
  }
  saveHcData(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/create/hc`, data);
  }
  saveScData(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/create/sc`, data);
  }

  //update
  updateAjaData(data: any) {
    return this.http.put(`${environment.baseUrl}/pregst/update/aja`, data);
  }
  updateApaData(data: any) {
    return this.http.put(`${environment.baseUrl}/pregst/update/apa`, data);
  }
  updateAptData(data: any) {
    return this.http.put(`${environment.baseUrl}/pregst/update/apt`, data);
  }
  updateHcData(data: any) {
    return this.http.put(`${environment.baseUrl}/pregst/update/hc`, data);
  }
  updateScData(data: any) {
    return this.http.put(`${environment.baseUrl}/pregst/update/sc`, data);
  }
  //get data
  getAllCaseSummaryAja(data: any) {
    return this.http.get(`${environment.baseUrl}/pregst/get/aja?caseId=${data.caseId}`);
  }
  getAllCaseSummaryApa(data: any) {
    return this.http.get(`${environment.baseUrl}/pregst/get/apa?caseId=${data.caseId}`);
  }
  getAllCaseSummaryApt(data: any) {
    return this.http.get(`${environment.baseUrl}/pregst/get/apt?caseId=${data.caseId}`);
  }
  getAllCaseSummaryHc(data: any) {
    return this.http.get(`${environment.baseUrl}/pregst/get/hc?caseId=${data.caseId}`);
  }
  getAllCaseSummarySc(data: any) {
    return this.http.get(`${environment.baseUrl}/pregst/get/sc?caseId=${data.caseId}`);
  }

  /* Delete api */
  deleteAdjudication(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/pregst/delete/aja`, httpOptions);
  }
  deleteCommissioner(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/pregst/delete/apa`, httpOptions);
  }
  deleteTribunal(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/pregst/delete/apt`, httpOptions);
  }
  deleteHc(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/pregst/delete/hc`, httpOptions);
  }
  deleteSc(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/pregst/delete/sc`, httpOptions);
  }

  exportPreGstAnalytic(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/pregst/analytics/export`, data, { responseType: 'blob' });
  }
  intCalPopData(data: any) {    
    return this.http.post(`${environment.baseUrl}/api/interest/calculation`, data);
  }
  saveIntCalPopData(data: any) {    
    return this.http.post(`${environment.baseUrl}/api/interest/calculation/save`, data);
  }
}
