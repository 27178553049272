import { Component, Input } from '@angular/core';
import { PreGstServiceService } from 'src/app/preGstLitigation/services/pre-gst-service.service';
import { STATE_LIST } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { LoaderService } from 'src/app/shared/services/loader.service';

@Component({
  selector: 'app-division-wise-table',
  templateUrl: './division-wise-table.component.html',
  styleUrl: './division-wise-table.component.css'
})
export class DivisionWiseTableComponent {
  @Input() d3Data: any;
  data: any = [];
  taxData: any = [];
  selectedState: any;
  stateList: any;
  navContext: any;
  entityType: any;
  pan: any;
  selectedIRN: boolean = false;
  isinCR: boolean = false;
  isinLakh: boolean = true;
  amountMetric: 'lakh' | 'crore' = 'lakh';
  @Input() divSumColTotal: any;
  @Input() divSumColTotals: any

  constructor(public commonService: CommonService,
    private pregstService: PreGstServiceService,
  ) { }

  ngOnInit() {
    this.stateList = STATE_LIST;
    this.selectedState = localStorage.getItem('preAnalyticState');
    // this.divSumColTotal = this.d3Data.totals;
    // this.divSumColTotals = this.d3Data.totalsInCr;
    // console.log('d3Data:', this.d3Data);
    var regionKeys = Object.keys(this.d3Data);
    var mainLabel = Object.keys(this.d3Data);
    mainLabel.forEach((label: any) => {
      this.data.push({ label: label, data: this.d3Data[label] })
    })

    // console.log('mainLabel:', mainLabel);
    // console.log('regionKeys:', mainLabel);

    this.taxData = [];
    for (var i = 0; i < regionKeys.length; i++) {
      let regiondata = this.d3Data[regionKeys[i]];
      let row: any = {
        division: regionKeys[i],
        act: regiondata.actDetails,
        total: regiondata.total,
        totalTax: regiondata.totalTax,
        totalInterest: regiondata.totalInterest,
        totalPenalty: regiondata.totalPenalty,

        totalInCr: regiondata.totalInCr,
        totalTaxInCr: regiondata.totalTaxInCr,
        totalInterestInCr: regiondata.totalInterestInCr,
        totalPenaltyInCr: regiondata.totalPenaltyInCr
      };

      let actDataArray = [];
      for (let act in regiondata.actDetails) {
        let rawActData = regiondata.actDetails[act];
        let actData = {
          act: act,
          actdata: rawActData
        }
        actDataArray.push(actData);
      }
      row.actData = actDataArray;
      this.taxData.push(row);
    }
    this.taxData = this.commonService.sortObjectsByKey(this.taxData, 'act');
    if (this.taxData.length > 0) {
      this.taxData.forEach((element: any) => {
        element.expanded = false;

      });
    }
  }

  changeStatsValue(selected: any, event: Event) {
    const isCrore = (event.target as HTMLInputElement).checked;
    this.amountMetric = isCrore ? 'crore' : 'lakh';
    if (selected == false) {
      this.isinCR = false;
      this.isinLakh = true;
    }
    else if (selected == true) {
      this.isinCR = true;
      this.isinLakh = false;
    }
  }

  setState(event: any) {
    this.selectedState = event.target.value;
    localStorage.setItem('preAnalyticState', this.selectedState);
  }

  exportAsXLSX(){

  }
}
