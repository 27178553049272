import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AUDIT_WHFOLLOWUP, DRC_PAYMENT_CHALLAN, ISSUE_CONCLUSION_NOTICE, ISSUE_GST, MODE_OF_PAYMENT, PAYMENT, PAYMENT_MODE } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { AuditService } from '../service/audit.service';
import { InrService } from 'src/app/shared/services/inr.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-audit-finding-letter',
  templateUrl: './audit-finding-letter.component.html',
  styleUrls: ['./audit-finding-letter.component.css']
})
export class AuditFindingLetterComponent implements OnInit {
  @ViewChild('scnTrigPop', { read: TemplateRef }) scnTrigPop!: TemplateRef<any>;
  @Output() moveToAJA: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveCaseId = new EventEmitter<boolean>();
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  parentCaseId: any;
  selectedTab: any;
  currentYear: any;
  auditId: any;
  action: any;
  companyId: any;
  userRoleLs: any;
  auditAflData: any;
  navContext: any;
  dataSource: any;
  selectedStateCode: any;
  selectedStateName: any;
  selectedGstinDiv: any;
  isauditAflData: boolean = true;
  maxDate = new Date();
  auditFindLtrForm!: UntypedFormGroup;
  /*save function declaration */
  isSubmitted: boolean = false;
  isPhase2: boolean = false;
  isUnfav: boolean = false;
  selectedFilling: any;
  selectedGstin: any;
  navData: any;
  savedAuditId: any;
  isSaved: boolean = false;
  isSaveBtn: boolean = false;
  isUpdateBtn: boolean = false;
  isAuditConOne: boolean = false;
  isDiplay: boolean = false;
  isAuditConTwo: boolean = false;
  showonSave: boolean = false;
  isActionView: boolean = true;
  hideCash: boolean = false;
  hideItc: boolean = false;
  isDisableIcons: boolean = false;
  /*save function declaration */
  /*upload function declaration */
  selectedField: any;
  selectedFile: any;
  fileUrl: any;
  filePath: any;
  matches: any;
  selectedCompanyId: any;
  gstinOrPan: any;
  oldLiabilityDetailValues: any = []
  issueConclusion: { key: string; value: string; }[];
  formData: FormData = new FormData();

  rmvFindingLModalRef!: NgbModalRef;
  rmvFindingsModalRef!: NgbModalRef;
  rmvsubmissionModalRef!: NgbModalRef;

  isUPdocsSubmittedLoc: boolean = true;
  isDWdocsSubmittedLoc: boolean = true;
  isdocsSubmittedLocFile: boolean = false;
  isdocsSubmittedLocText: boolean = true;

  isUPackReplyCopyLoc: boolean = true;
  isDWackReplyCopyLoc: boolean = true;
  isackReplyCopyLocFile: boolean = false;
  isackReplyCopyLocText: boolean = true;

  isUPfindingLetterLoc: boolean = true;
  isDWfindingLetterLoc: boolean = true;
  isfindingLetterLocFile: boolean = false;
  isfindingLetterLocText: boolean = true;

  isUPbankChallanLoc: boolean = true;
  isDWbankChallanLoc: boolean = true;
  isbankChallanLocFile: boolean = false;
  isbankChallanLocText: boolean = true;

  isUPdrc03CopyLoc: boolean = true;
  isDWdrc03CopyLoc: boolean = true;
  isdrc03CopyLocFile: boolean = false;
  isdrc03CopyLocText: boolean = true;

  isToggleDropdown = true;

  isUPothAttachmentLoc: boolean = true;
  isDWothAttachmentLoc: boolean = true;
  isothAttachmentLocFile: boolean = false;
  isothAttachmentLocText: boolean = true;

  isUPcopyOfAckLoc: boolean = true;
  isDWcopyOfAckLoc: boolean = true;
  iscopyOfAckLocFile: boolean = false;
  iscopyOfAckLocText: boolean = true;

  isUPotherAttac2: boolean = true;
  isDWotherAttac2: boolean = true;
  isotherAttac2File: boolean = false;
  isotherAttac2Text: boolean = true;

  isUPackCopyLoc: boolean = true;
  isDWackCopyLoc: boolean = true;
  isackCopyLocFile: boolean = false;
  isackCopyLocText: boolean = true;

  //  Details of Liability Admitted and Paid
  iscopyOfUPFormLoc: boolean = true;
  iscopyOfDWFormLoc: boolean = true;
  iscopyOfFormLocFile: boolean = false;
  iscopyOfFormLocText: boolean = true;

  iscopyOfBankUPChallanLoc: boolean = true;
  iscopyOfBankDWChallanLoc: boolean = true;
  iscopyOfBankChallanLocFile: boolean = false;
  iscopyOfBankChallanLocText: boolean = true;



  /*upload function declaration */
  auditConclusn: { key: string; value: string; }[];
  whfollowup: { label: string; value: boolean; }[];
  payMode: { key: string; value: string; }[];

  liabilityTotals = { taxTotal: 0, interestTotal: 0, penaltyTotal: 0, allTotal: 0 };
  formPayment = PAYMENT;
  modeOfPayment = MODE_OF_PAYMENT;
  liabilityExpansionPanel = false;
  rmvLiabilityModalRef: any;


  constructor(
    private fBuild: UntypedFormBuilder,
    private auditService: AuditService,
    private toaster: ToasterService,
    private router: Router,
    private activeSnapShot: ActivatedRoute,
    private GS: GetterSetterService,
    private commonServ: CommonService,
    private modalService: NgbModal,
    private inrService: InrService,
    private http: HttpClient

  ) {
    this.auditConclusn = DRC_PAYMENT_CHALLAN;
    this.payMode = PAYMENT_MODE;
    this.auditId = this.activeSnapShot.snapshot.params.auditId;
    this.action = this.activeSnapShot.snapshot.params.action;
    this.whfollowup = AUDIT_WHFOLLOWUP;
    this.issueConclusion = ISSUE_CONCLUSION_NOTICE;
  }

  ngOnInit() {
    this.selectedCompanyId = localStorage.getItem('selectedId');
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.navData = JSON.parse(this.GS.getNavContextData() || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.selectedGstinDiv = this.selectedFilling.gstinDiv;
    this.navContext = this.commonServ.getNavContext();
    this.selectedTab = 'tab1';
    this.setDate();
    this.setStateAutoPop();
    this.issueDropdown();
    if (this.auditId || this.parentCaseId) {
      this.isPhase2 = true;
      this.initializeData();
    }
    this.personResponsibleData();
    if (this.action === 'create') {
      // this.addLiabilityInv();

      this.isSaveBtn = false;
      this.isUpdateBtn = true;
      this.isDisableIcons = true;

      this.isActionView = true;
      this.isdocsSubmittedLocFile = true;
      this.isdocsSubmittedLocText = false;
      this.isUPdocsSubmittedLoc = false;

      this.isackReplyCopyLocFile = true;
      this.isackReplyCopyLocText = false;
      this.isUPackReplyCopyLoc = false;

      this.isfindingLetterLocFile = true;
      this.isfindingLetterLocText = false;
      this.isUPfindingLetterLoc = false;

      this.isdocsSubmittedLocFile = true;
      this.isdocsSubmittedLocText = false;
      this.isUPdocsSubmittedLoc = false;

      this.isackReplyCopyLocFile = true;
      this.isackReplyCopyLocText = false;
      this.isUPackReplyCopyLoc = false;

      this.isbankChallanLocFile = true;
      this.isbankChallanLocText = false;
      this.isUPbankChallanLoc = false;

      this.isdrc03CopyLocFile = true;
      this.isdrc03CopyLocText = false;
      this.isUPdrc03CopyLoc = false;

      this.isothAttachmentLocFile = true;
      this.isothAttachmentLocText = false;
      this.isUPothAttachmentLoc = false;

      this.iscopyOfAckLocFile = true;
      this.iscopyOfAckLocText = false;
      this.isUPcopyOfAckLoc = false;

      this.isackCopyLocFile = true;
      this.isackCopyLocText = false;
      this.isUPackCopyLoc = false;

      this.isotherAttac2File = true;
      this.isotherAttac2Text = false;
      this.isUPotherAttac2 = false;

    } else if (this.action === 'view') {
      this.isPhase2 = true;
      this.isActionView = false;
      this.isDisableIcons = false;



      this.isUPdocsSubmittedLoc = false;
      this.isDWdocsSubmittedLoc = true;

      this.isUPackReplyCopyLoc = false;
      this.isDWackReplyCopyLoc = true;

      this.isUPfindingLetterLoc = false;
      this.isDWfindingLetterLoc = true;

      this.isUPbankChallanLoc = false;
      this.isDWbankChallanLoc = true;

      this.isUPdrc03CopyLoc = false;
      this.isDWdrc03CopyLoc = true;

      this.isUPothAttachmentLoc = false;
      this.isDWothAttachmentLoc = true;

      this.isUPcopyOfAckLoc = false;
      this.isDWcopyOfAckLoc = true;

      this.isUPackCopyLoc = false;
      this.isDWackCopyLoc = true;

      this.isUPotherAttac2 = false;
      this.isDWotherAttac2 = true;

    } else if (this.action === 'update') {
      this.isUpdateBtn = true;
      this.isSaved = true;
      this.isActionView = true;
      this.isUPdocsSubmittedLoc = true;
      this.isUPackReplyCopyLoc = true;
      this.isDisableIcons = true;
    }
    this.formInitialize();
    this.issueValueChange();
    //this.addItemList();

  }
  tabChanged(tab: any) {
    this.selectedTab = tab;
    this.auditFindLtrForm.patchValue(this.auditFindLtrForm.value);
    if (tab === 'tab2') {
      this.initializeData();
    }
  }
  formInitialize() {
    this.auditFindLtrForm = this.fBuild.group({
      //phase 1 fields
      auditId: [this.auditAflData?.auditId],
      gstin: [this.auditAflData?.gstin ? this.auditAflData?.gstin : this.selectedGstin],
      // gstin: [this.selectedGstin],
      gstinDiv: [this.selectedGstinDiv],
      state: [this.selectedStateName],
      personResponsible: [this.auditAflData?.personResponsible, [Validators.required]],
      consultant: [this.auditAflData?.consultant],
      userName: [this.auditAflData?.userName],
      updatedOn: [this.auditAflData?.updatedOn],
      periodFromDate: [this.auditAflData?.periodFromDate, [Validators.required]],
      periodToDate: [this.auditAflData?.periodToDate, [Validators.required]],
      itemList: new UntypedFormArray([]),
      liabilityDetails: new UntypedFormArray([]),
      internalRemarks: [this.auditAflData?.internalRemarks],
      //phase 2 fields
      //replyDate: [this.auditAflData?.replyDate],
      //ackReplyCopyLoc: [this.auditAflData?.ackReplyCopyLoc],
      //auditStatus: [this.auditAflData?.auditStatus],
      status: [this.auditAflData?.status],
      // dateOfScn: [this.auditAflData?.dateOfScn],
      // dateOfReceiptOfScn: [this.auditAflData?.dateOfReceiptOfScn],
      //scnNo: [this.auditAflData?.scnNo],
      // paymentMode: [this.auditAflData?.paymentMode],
      paymentAmt: [this.auditAflData?.paymentAmt],
      // bankChallanLoc: [this.auditAflData?.bankChallanLoc],
      // paidByItc: [this.auditAflData?.paidByItc],
      // drc03CopyLoc: [this.auditAflData?.drc03CopyLoc],
      // drc03Tax: [this.auditAflData?.drc03Tax, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Interest: [this.auditAflData?.drc03Interest, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Penalty: [this.auditAflData?.drc03Penalty, [Validators.pattern(/^[1-9]\d*$/)]],

    })
    if ((this.auditFindLtrForm.value.itemList?.length === 0) && !(this.auditId)) {
      this.addFLetter();
    }

    this.auditFindLtrForm.controls['liabilityDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldLiabilityDetailValues = this.auditFindLtrForm.value['liabilityDetails'];
      });
  }
  get formGrp() { return this.auditFindLtrForm.controls; }

  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }
  getfindingLetterLoc(findingLetterLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (findingLetterLocGrp.get('findingLetterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getotherAttachmentsLoc(othAttachLocLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (othAttachLocLocGrp.get('othAttachmentLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  createfindingLetterLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOthAttachLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  getFindingLetterLocLength(j?: any) {
    let count = 0;
    let dtLettersFrmArray = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    let letterLocFrmArray = dtLettersFrmArray.controls[j]?.get('findingLetterLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getOtherAttachLength(j?: any) {
    let count = 0;
    let dtLettersFrmArray = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    let letterLocFrmArray = dtLettersFrmArray.controls[j]?.get('othAttachmentLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }

  openfindingLetterLoc(content: any, i: any) {
    this.addfindingLetterLoc(i, 0);
    this.modalService.open(content);
  }
  openOtherAttachPop(content: any, i: any) {
    this.addOthAttachLoc(i, 0);
    this.modalService.open(content);

  }
  addfindingLetterLoc(j: any, i: any) {
    let dtLettersFrmArray = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    let letterLocFrmArray = dtLettersFrmArray.controls[j].get('findingLetterLoc') as UntypedFormArray;
    if (letterLocFrmArray.length > 0) {
      let length = letterLocFrmArray.length
      while (i < 5 - length) {
        letterLocFrmArray.push(this.createfindingLetterLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        letterLocFrmArray.push(this.createfindingLetterLoc());
        i++;
      }
    }
  }
  addOthAttachLoc(j: any, i: any) {
    let dtOtheAttachFrmArray = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    let othLocFrmArray = dtOtheAttachFrmArray.controls[j].get('othAttachmentLoc') as UntypedFormArray;
    if (othLocFrmArray.length > 0) {
      let length = othLocFrmArray.length
      while (i < 5 - length) {
        othLocFrmArray.push(this.createOthAttachLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        othLocFrmArray.push(this.createOthAttachLoc());
        i++;
      }
    }
  }
  uploadfindingLetterLocF(letterListIndex: any, letterLocIndex: any) {

    this.isfindingLetterLocFile = true;
    this.isfindingLetterLocText = false;
    this.isDWfindingLetterLoc = false;
    this.isUPfindingLetterLoc = false;

    let itemListsArray = this.auditFindLtrForm.controls.itemList as UntypedFormGroup;
    let lettereLocFrmArray = itemListsArray.controls[letterListIndex].get('findingLetterLoc') as UntypedFormArray;
    if (lettereLocFrmArray.controls.length > 0) {
      for (let index = 0; index < lettereLocFrmArray.controls.length; index++) {
        if (index === letterLocIndex) {
          let itemListuploadControl = lettereLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLetterLoc(itemListIndex: any, ItrIndex: any) {

    let itemListsArray = this.auditFindLtrForm.controls.itemList as UntypedFormGroup;
    let letterLocFrmArray = itemListsArray.controls[itemListIndex].get('findingLetterLoc') as UntypedFormArray;
    if (letterLocFrmArray.controls.length > 0) {
      for (let index = 0; index < letterLocFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = letterLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadOthAttachLocCopy(otheAttachListIndex: any, othAttachLocIndex: any) {
    this.isothAttachmentLocFile = true;
    this.isothAttachmentLocText = false;
    this.isDWothAttachmentLoc = false;
    this.isUPothAttachmentLoc = false;
    let itemListsArray = this.auditFindLtrForm.controls.itemList as UntypedFormGroup;
    let othAttachLocFrmArray = itemListsArray.controls[otheAttachListIndex].get('othAttachmentLoc') as UntypedFormArray;
    if (othAttachLocFrmArray.controls.length > 0) {
      for (let index = 0; index < othAttachLocFrmArray.controls.length; index++) {
        if (index === othAttachLocIndex) {
          let itemListuploadControl = othAttachLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttach(itemListIndex: any, ItrIndex: any) {
    let consultArray = this.auditFindLtrForm.controls.consultantCharges as UntypedFormGroup;
    let otherAttachFrmArray = consultArray.controls[itemListIndex].get('othAttachmentLoc') as UntypedFormArray;
    if (otherAttachFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = otherAttachFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  getAckCOpyLoc(ackCOpyLocLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (ackCOpyLocLocGrp.get('ackCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getSubOthAttachLoc(SubOthAttachLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (SubOthAttachLocGrp.get('othAttachmentLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createAckCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', []),
    })
  }
  createSubOtherAttachCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', []),
    })
  }
  addAckCopy(j: any, i: any) {
    let itemFrmArray: any = this.auditFindLtrForm.get('itemList') as UntypedFormArray;

    for (let index = 0; index < itemFrmArray.length; index++) {
      const element = itemFrmArray.controls[index];
      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1 = submissionArray[i].controls.ackCopyLoc;
        let actualLength = element1.controls.length;
        let remainingLength = actualLength != 0 ? 5 - actualLength : 0;
        if (actualLength == 0) {
          for (let k = 0; k < 5; k++) {
            submissionArray[i].controls.ackCopyLoc.push(this.createAckCopyLoc())
          }
        }
        while (remainingLength > 0) {
          submissionArray[i].controls.ackCopyLoc.push(this.createAckCopyLoc());
          remainingLength--;
        }
      }
    }
  }
  addSubmmiOthAttachCopy(j: any, i: any) {
    let itemFrmArray: any = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    for (let index = 0; index < itemFrmArray.length; index++) {
      const element = itemFrmArray.controls[index];
      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1 = submissionArray[i].controls.othAttachmentLoc;
        let actualLength = element1.controls.length;
        let remainingLength = actualLength != 0 ? 5 - actualLength : 0;
        if (actualLength == 0) {
          for (let k = 0; k < 5; k++) {
            submissionArray[i].controls.othAttachmentLoc.push(this.createSubOtherAttachCopyLoc())
          }
        }
        while (remainingLength > 0) {
          submissionArray[i].controls.othAttachmentLoc.push(this.createSubOtherAttachCopyLoc());
          remainingLength--;
        }
      }
    }
  }

  // addSubmmiOthAttachCopy(j: any, i: any) {
  //   let itemFrmArray = this.auditFindLtrForm.get('itemList') as FormArray;
  //   let submissionFrmArray = itemFrmArray.controls[j].get('submissions') as FormArray;
  //   let othAttachmentLocFrmArray = submissionFrmArray.controls[i].get('othAttachmentLoc') as FormArray;

  //   if (othAttachmentLocFrmArray.length > 0) {
  //     let length = othAttachmentLocFrmArray.length
  //     while (i < 5 - length) {
  //       othAttachmentLocFrmArray.push(this.createSubOtherAttachCopyLoc());
  //       i++;
  //     }
  //   }
  //   else {
  //     while (i < 5) {
  //       othAttachmentLocFrmArray.push(this.createSubOtherAttachCopyLoc());
  //       i++;
  //     }
  //   }
  // }
  getAckCopyLocLength(noticeFormIndex: number, submissionFormIndex: number): number {
    const itemListFrmArray = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    const submissionFrmArray = itemListFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const ackCopyLocArray = submissionFrmArray.at(submissionFormIndex).get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray && ackCopyLocArray.length > -1) {
      let countOfackCopyLoc = 0;
      for (let i = 0; i < ackCopyLocArray.length; i++) {
        const singleAckLoc = ackCopyLocArray.value[i];
        if (singleAckLoc.fileLoc != '' && singleAckLoc.fileLoc != null) {
          countOfackCopyLoc++;
        }
      }
      return countOfackCopyLoc;
    }
    return 0;
  }
  getOthAttachmentLocLength(noticeFormIndex: number, submissionFormIndex: number): number {
    const itemListFrmArray = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    const submissionFrmArray = itemListFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const othAttachmentLocArray = submissionFrmArray.at(submissionFormIndex).get('othAttachmentLoc') as UntypedFormArray;
    if (othAttachmentLocArray && othAttachmentLocArray.length > -1) {
      let countOfackCopyLoc = 0;
      for (let i = 0; i < othAttachmentLocArray.length; i++) {
        const singleAckLoc = othAttachmentLocArray.value[i];
        if (singleAckLoc.fileLoc != '' && singleAckLoc.fileLoc != null) {
          countOfackCopyLoc++;
        }
      }
      return countOfackCopyLoc;
    }
    return 0;
  }
  openAckCopyLoc(content: any, i: any) {
    this.addAckCopy(i, 0);
    this.modalService.open(content);
  }
  openSubOthCopyLoc(content: any, i: any) {
    this.addSubmmiOthAttachCopy(i, 0);
    this.modalService.open(content);
  }
  uploadAckCopy(itemListIndex: any, ackCopyLocIndex: any, submDetailsIndex: any) {
    this.isackCopyLocFile = true;
    this.isackCopyLocText = false;
    this.isDWackCopyLoc = false;
    this.isUPackCopyLoc = false;

    let itemListFrmArray = this.auditFindLtrForm.controls.itemList as UntypedFormGroup;
    let submiDetailsArray = itemListFrmArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let ackCopyLocArray = submiDetailsArray.controls[submDetailsIndex].get('ackCopyLoc') as UntypedFormArray;

    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAckCopyFile(itemListIndex: any, ackCopyLocIndex: any, submDetailsIndex: any) {
    let itemListFrmArray = this.auditFindLtrForm.controls.itemList as UntypedFormGroup;
    let submiDetailsArray = itemListFrmArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let ackCopyLocArray = submiDetailsArray.controls[submDetailsIndex].get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadSubOtherAttach(itemListIndex: any, othAttachCopyLocIndex: any, submDetailsIndex: any) {

    this.isothAttachmentLocFile = true;
    this.isothAttachmentLocText = false;
    this.isDWothAttachmentLoc = false;
    this.isUPothAttachmentLoc = false;

    let itemListFrmArray = this.auditFindLtrForm.controls.itemList as UntypedFormGroup;
    let submiDetailsArray = itemListFrmArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let othAttachLocArray = submiDetailsArray.controls[submDetailsIndex].get('othAttachmentLoc') as UntypedFormArray;

    if (othAttachLocArray.controls.length > 0) {
      for (let index = 0; index < othAttachLocArray.controls.length; index++) {
        if (index === othAttachCopyLocIndex) {
          let itemListuploadControl = othAttachLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttachmentFile(itemListIndex: any, ackCopyLocIndex: any, submDetailsIndex: any) {
    let itemListFrmArray = this.auditFindLtrForm.controls.itemList as UntypedFormGroup;
    let submiDetailsArray = itemListFrmArray.controls[itemListIndex].get('submissions') as UntypedFormGroup;
    let subOtheAttachCopyLocArray = submiDetailsArray.controls[submDetailsIndex].get('othAttachmentLoc') as UntypedFormArray;
    if (subOtheAttachCopyLocArray.controls.length > 0) {
      for (let index = 0; index < subOtheAttachCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = subOtheAttachCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  getLiablityCOpyFormLoc(copyOfFormLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfFormLocGrp.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getBankChallanLoc(copyOfBankChallanLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfBankChallanLocGrp.get('copyOfBankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  createCopyFormLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      fileName: new UntypedFormControl(not ? not.fileName : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createBankFormLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      fileName: new UntypedFormControl(not ? not.fileName : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  getCopyOfFormLocLength(j?: any) {
    let count = 0;
    let dtLibilityFrmArray = this.auditFindLtrForm.get('liabilityDetails') as UntypedFormArray;
    let copyFormLocFrmArray = dtLibilityFrmArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = copyFormLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getCopyOfBankChallanLocLength(j?: any) {
    let count = 0;
    let dtLibilityFrmArray = this.auditFindLtrForm.get('liabilityDetails') as UntypedFormArray;
    let copyFormLocFrmArray = dtLibilityFrmArray.controls[j]?.get('copyOfBankChallanLoc') as UntypedFormArray;
    let lettercount = copyFormLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  openCopyOfForm(content: any, i: any) {
    this.addCopyFormAttach(i, 0);
    this.modalService.open(content);

  }
  openBankChallan(content: any, i: any) {
    this.addBankChallanAttach(i, 0);
    this.modalService.open(content);

  }
  addCopyFormAttach(j: any, i: any) {
    let dtLibilityFrmArray = this.auditFindLtrForm.get('liabilityDetails') as UntypedFormArray;
    let copyFormLocFrmArray = dtLibilityFrmArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.length > 0) {
      let length = copyFormLocFrmArray.length
      while (i < 5 - length) {
        copyFormLocFrmArray.push(this.createCopyFormLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyFormLocFrmArray.push(this.createCopyFormLoc());
        i++;
      }
    }
  }
  addBankChallanAttach(j: any, i: any) {
    let dtLibilityFrmArray = this.auditFindLtrForm.get('liabilityDetails') as UntypedFormArray;
    let bankChallanFrmArray = dtLibilityFrmArray.controls[j].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanFrmArray.length > 0) {
      let length = bankChallanFrmArray.length
      while (i < 5 - length) {
        bankChallanFrmArray.push(this.createBankFormLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        bankChallanFrmArray.push(this.createBankFormLoc());
        i++;
      }
    }
  }
  uploadCopyForm(letterListIndex: any, letterLocIndex: any) {
    this.iscopyOfFormLocFile = true;
    this.iscopyOfFormLocText = false;
    this.iscopyOfUPFormLoc = false;
    this.iscopyOfDWFormLoc = false;

    let liabilityDtlsArray = this.auditFindLtrForm.controls.liabilityDetails as UntypedFormGroup;
    let copyFormLocFrmArray = liabilityDtlsArray.controls[letterListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormLocFrmArray.controls.length; index++) {
        if (index === letterLocIndex) {
          let itemListuploadControl = copyFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteCopyForm(itemListIndex: any, ItrIndex: any) {
    let liablityArray = this.auditFindLtrForm.controls.liabilityDetails as UntypedFormGroup;
    let copyFormFrmArray = liablityArray.controls[itemListIndex].get('copyOfFormLoc') as UntypedFormArray;
    if (copyFormFrmArray.controls.length > 0) {
      for (let index = 0; index < copyFormFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = copyFormFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadBankChallanForm(letterListIndex: any, letterLocIndex: any) {
    this.iscopyOfBankChallanLocFile = true;
    this.iscopyOfBankChallanLocText = false;
    this.iscopyOfBankDWChallanLoc = true;
    this.iscopyOfBankUPChallanLoc = false;

    let liabilityDtlsArray = this.auditFindLtrForm.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanFrmArray = liabilityDtlsArray.controls[letterListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanFrmArray.controls.length; index++) {
        if (index === letterLocIndex) {
          let itemListuploadControl = bankChallanFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteBankChallan(itemListIndex: any, ItrIndex: any) {
    let liablityArray = this.auditFindLtrForm.controls.liabilityDetails as UntypedFormGroup;
    let bankChallanFrmArray = liablityArray.controls[itemListIndex].get('copyOfBankChallanLoc') as UntypedFormArray;
    if (bankChallanFrmArray.controls.length > 0) {
      for (let index = 0; index < bankChallanFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = bankChallanFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  p1validation() {
    var itemListArray = (this.auditFindLtrForm.get('itemList') as UntypedFormArray)
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        var itemListControl = itemListArray.controls[i] as UntypedFormGroup;
        if ((itemListControl.controls.findingLetterLoc.value == null || itemListControl.controls.findingLetterLoc.value === '')) {
          itemListControl.controls.findingLetterLoc.setErrors({ 'required': true });
        } else {
          itemListControl.controls.findingLetterLoc.setErrors(null);
        }
      }
    }

  }
  p2validation() {
    var itemListArray = this.auditFindLtrForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var itemListIndex = 0; itemListIndex < itemListArray.controls.length; itemListIndex++) {
        var submissionsArray = itemListArray.controls[itemListIndex].get('submissions') as UntypedFormArray
        //var itemListControl = itemListArray.controls[0] as FormGroup;
        for (var submissionIndex = 0; submissionIndex < submissionsArray.controls.length; submissionIndex++) {
          var submissionContol = submissionsArray.controls[submissionIndex] as UntypedFormGroup
          if (submissionContol.controls.dateOfSubmission.value === null || submissionContol.controls.dateOfSubmission.value === '') {
            submissionContol.controls.dateOfSubmission.setErrors({ 'required': true });
          } else {
            submissionContol.controls.dateOfSubmission.setErrors(null);
          }
        }
      }
    }

    if (itemListArray.controls.length > 0) {
      for (var itemListIndex = 0; itemListIndex < itemListArray.controls.length; itemListIndex++) {
        var findingsArray = itemListArray.controls[itemListIndex].get('findings') as UntypedFormArray
        for (var findingIndex = 0; findingIndex < findingsArray.controls.length; findingIndex++) {
          var findingControl = findingsArray.controls[findingIndex] as UntypedFormGroup
          if (findingControl.controls.status.value === null || findingControl.controls.status.value === '') {
            findingControl.controls.status.setErrors({ 'required': true });
          } else {
            findingControl.controls.status.setErrors(null);
          }
          if (findingControl.controls.scnNo.value === null || findingControl.controls.scnNo.value === 'null' || findingControl.controls.scnNo.value === '' && this.isAuditConOne === true) {
            findingControl.controls.scnNo.setErrors({ 'required': true });
          } else {
            findingControl.controls.scnNo.setErrors(null);
          }
          if (findingControl.controls.scnReceiptDt.value === null || findingControl.controls.scnReceiptDt.value === 'null' || findingControl.controls.scnReceiptDt.value === '' && this.isAuditConOne === true) {
            findingControl.controls.scnReceiptDt.setErrors({ 'required': true });
          } else {
            findingControl.controls.scnReceiptDt.setErrors(null);
          }
        }

      }
    }



  }

  liabilityValidation() {
    let liabilityArray = this.auditFindLtrForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.challanNo.value != null && issuesControl.controls.challanNo.value) {
          if (issuesControl.controls.copyOfFormLoc.value === null || issuesControl.controls.copyOfFormLoc.value === '') {
            issuesControl.controls.copyOfFormLoc.setErrors({ 'required': true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }
  p4validation() {
    if (this.auditFindLtrForm.value['paymentMode'] === null) {
      this.auditFindLtrForm.controls['paymentMode'].setErrors({ 'required': true });
    }
  }

  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }

  /* finding letter array */
  getfindingletter(): UntypedFormGroup[] {
    return (this.auditFindLtrForm.get('itemList') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  initfindingletterGrp() {
    return new UntypedFormGroup(
      {
        letterRefNo: new UntypedFormControl(null, [Validators.required]),
        letterDate: new UntypedFormControl(null, [Validators.required]),
        din: new UntypedFormControl(null, []),
        dateOfReceiptOfLetter: new UntypedFormControl(null, []),
        internalDueDate: new UntypedFormControl(null, []),
        legalDueDate: new UntypedFormControl(null, [Validators.required]),
        findings: new UntypedFormArray([]),
        findingLetterLoc: new UntypedFormArray([]),
        othAttachmentLoc: new UntypedFormArray([]),
        // ackCopyLoc: new FormArray([]),
        isActive: new UntypedFormControl(false),
        //phase 2
        submissions: new UntypedFormArray([]),
        replyDate: new UntypedFormControl(null, []),
        status: new UntypedFormControl(null, []),
        scnNo: new UntypedFormControl('', []),
        scnReceiptDt: new UntypedFormControl('', []),
        igstTotal: new UntypedFormControl(null, []),
        cgstTotal: new UntypedFormControl(null, []),
        sgstTotal: new UntypedFormControl(null, []),
        cessTotal: new UntypedFormControl(null, []),
        interestTotal: new UntypedFormControl(null, []),
        penaltyTotal: new UntypedFormControl(null, []),
        totOfTotals: new UntypedFormControl(null, []),


      }
    )
  }

  addFLetter() {
    let findingletterGrp = this.initfindingletterGrp();
    (findingletterGrp.get('findings') as UntypedFormArray).push(this.initFindingsGrp());
    (findingletterGrp.get('submissions') as UntypedFormArray).push(this.initSubmissionFrmGrp());
    (this.auditFindLtrForm.get('itemList') as UntypedFormArray).push(findingletterGrp);
  }
  rmFLetter(i: number) {
    (this.auditFindLtrForm.get('itemList') as UntypedFormArray).removeAt(i);
    this.rmvFindingLModalRef.close();
  }
  /*finding letter array */

  /*findings array */
  // getItemList(): FormGroup[] {
  //   return (this.auditFindLtrForm.get('findings') as FormArray).controls as FormGroup[];
  // }

  getfindings(findingletterGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (findingletterGrp.get('findings') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initFindingsGrp() {
    return new UntypedFormGroup({
      findingDetails: new UntypedFormControl(null, [Validators.required]),
      keyword: new UntypedFormControl(null, []),
      igst: new UntypedFormControl(null, []),
      cgst: new UntypedFormControl(null, []),
      sgst: new UntypedFormControl(null, []),
      cess: new UntypedFormControl(null, []),
      interest: new UntypedFormControl(null, []),
      penalty: new UntypedFormControl(null, []),
      total: new UntypedFormControl(null, []),
      status: new UntypedFormControl(null, []),
      scnNo: new UntypedFormControl('', []),
      scnReceiptDt: new UntypedFormControl('', []),


    })
  }
  addFindings(j: any) {
    let itemListFrmArray = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    let findingsFrmArray = itemListFrmArray.controls[j].get('findings') as UntypedFormArray;
    findingsFrmArray.push(this.initFindingsGrp());
  }
  rmFindings(i: number, k: number) {
    let itemListFrmArray = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    let findingsFrmArray = itemListFrmArray.controls[i].get('findings') as UntypedFormArray;
    this.issueValueChange();
    findingsFrmArray.removeAt(k);
    this.rmvFindingsModalRef.close();
  }

  /*findings array */
  /*submission array p2*/

  getSubmissions(findingletterGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (findingletterGrp.get('submissions') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initSubmissionFrmGrp(submission: any = null) {
    return new UntypedFormGroup({
      dateOfSubmission: new UntypedFormControl(null, []),
      personResponsible: new UntypedFormControl(this.auditAflData?.personResponsible, []),
      consultant: new UntypedFormControl(this.auditAflData?.consultant, []),
      ackCopyLoc: new UntypedFormArray([]),
      othAttachmentLoc: new UntypedFormArray([]),
      internalRemarks: new UntypedFormControl(null, []),
      isSubmission: new UntypedFormControl(false),
    })
  }
  addSubmissionDiv(j: any) {
    let itemListFrmArray = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    let submissionFrmArray = itemListFrmArray.controls[j].get('submissions') as UntypedFormArray;
    submissionFrmArray.push(this.initSubmissionFrmGrp());

  }
  rmSubmissionDiv(i: number, k: number) {
    let itemListFrmArray = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
    let submissionFrmArray = itemListFrmArray.controls[i].get('submissions') as UntypedFormArray;
    submissionFrmArray.removeAt(k);
    this.rmvsubmissionModalRef.close();

  }
  // Liability Table
  detailsOfLiabilityFrmGrp(liability: any = null): UntypedFormGroup {
    return this.fBuild.group({
      formOfPayment: new UntypedFormControl(liability ? liability.formOfPayment : null, []),
      challanNo: new UntypedFormControl(liability ? liability.challanNo : null, []),
      modeOfPayment: new UntypedFormControl(liability ? liability.modeOfPayment : null, []),
      copyOfFormLoc: new UntypedFormArray([]),
      copyOfBankChallanLoc: new UntypedFormArray([]),
      tax: new UntypedFormControl(liability ? liability.tax : 0, []),
      interest: new UntypedFormControl(liability ? liability.interest : 0, []),
      penalty: new UntypedFormControl(liability ? liability.penalty : 0, []),
      total: new UntypedFormControl(liability ? liability.total : 0, []),
    })
  }

  liabilityPanelExpansion() {
    this.liabilityExpansionPanel = !this.liabilityExpansionPanel;

  }
  // Details of Liability Admitted and Paid
  getLiability(): UntypedFormGroup[] {
    return (this.auditFindLtrForm.get('liabilityDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  addLiabilityInv() {
    let liabilityFrmGrp = this.detailsOfLiabilityFrmGrp();
    (this.auditFindLtrForm.get('liabilityDetails') as UntypedFormArray).push(liabilityFrmGrp);
  }
  rmLiabilityInv(i: number) {
    (this.auditFindLtrForm.get('liabilityDetails') as UntypedFormArray).removeAt(i);
    this.rmvLiabilityModalRef.close();
    this.liabilityTotalCalculation();
  }

  liabilityTotalCalculation() {
    let taxTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let liabilityPaidTotal = 0;
    (this.auditFindLtrForm.get('liabilityDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const tax = this.inrService.removeCommasAndParseNumber(c.value.tax);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);
      const penalty = this.inrService.removeCommasAndParseNumber(c.value.penalty);
      taxTotal += Number(tax);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(tax)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      liabilityPaidTotal += Number(total);
    });

    this.liabilityTotals.allTotal = liabilityPaidTotal;
    this.liabilityTotals.interestTotal = interestTotal;
    this.liabilityTotals.penaltyTotal = penaltyTotal;
    this.liabilityTotals.taxTotal = taxTotal;
  }

  uploadcopyOfForm(i: any) {
    var liabilityDetailsArray = this.auditFindLtrForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityDetailsArray.controls.length > 0) {
      for (var index = 0; index < liabilityDetailsArray.controls.length; index++) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.iscopyOfFormLocFile = true;
    this.iscopyOfFormLocText = false;
    this.iscopyOfUPFormLoc = false;
    this.iscopyOfDWFormLoc = false;
  }

  deleteCopyOfFormFile(i: any) {
    let liabilityArray = this.auditFindLtrForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  uploadcopyOfBankChallan(i: any) {
    var liabilityDetailsArray = this.auditFindLtrForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityDetailsArray.controls.length > 0) {
      for (var index = 0; index < liabilityDetailsArray.controls.length; index++) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfBankChallanLocDocUploadedClicked'] = true
        }
      }
    }
    this.iscopyOfBankChallanLocFile = true;
    this.iscopyOfBankChallanLocText = false;
    this.iscopyOfBankDWChallanLoc = false;
    this.iscopyOfBankUPChallanLoc = false;
  }
  deleteCopyOfBankChallan3File(i: any) {
    let liabilityArray = this.auditFindLtrForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfBankChallanLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }
  openRmvLiabilityPop(content: any, i: any) {
    this.rmvLiabilityModalRef = this.modalService.open(content);
  }

  /*submission array p1*/
  /*save function */
  saveFindingLetter() {
    this.isSubmitted = true;
    let data: any = {};
    if (this.auditFindLtrForm.valid) {
      data = this.auditFindLtrForm.value;
      data.state = this.selectedStateName;
      let urlData = {
        type: 'afl',
        gstin: this.selectedGstin,
        companyId: this.companyId
      }
      Object.assign(data, { action: 'CREATE' }, { phase: '1' }, { keyword: 'keyword1' })
      let findingLetterLocArray: any[] = [];
      let otherAttachmentArray: any[] = [];
      for (let i = 0; i < this.auditFindLtrForm.controls.itemList.value.length; i++) {
        findingLetterLocArray = [];
        const letterArray = this.auditFindLtrForm.controls.itemList.value[i].findingLetterLoc;
        if (letterArray.length === 0) {
          this.auditFindLtrForm.controls.itemList.value[i].findingLetterLoc.status = 'INVALID';
        }
        for (let j = 0; j < letterArray.length; j++) {
          const element = letterArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            findingLetterLocArray.push(element)
          }
        }

        this.auditFindLtrForm.controls.itemList.value[i].findingLetterLoc = findingLetterLocArray;
      }
      for (let i = 0; i < this.auditFindLtrForm.controls.itemList.value.length; i++) {
        otherAttachmentArray = [];
        const otherAttachArray = this.auditFindLtrForm.controls.itemList.value[i].othAttachmentLoc;
        for (let j = 0; j < otherAttachArray.length; j++) {
          const element = otherAttachArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            otherAttachmentArray.push(element)
          }
        }
        this.auditFindLtrForm.controls.itemList.value[i].othAttachmentLoc = otherAttachmentArray;
      }
      this.auditService.saveAflData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        if (response.status === 1) {
          this.showonSave = true;
          if (data.itemList.length > 0) {
            data.itemList.forEach((e: any) => {
              e.isActive = true;
            })
          }
          this.savedAuditId = response.response;
          this.saveCaseId.emit(this.savedAuditId);
          this.isPhase2 = true;
          this.isSaved = true;
          this.isSaveBtn = false;
          this.isUpdateBtn = true;
          this.toaster.showSuccess(response.message);

        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError("Required fields are empty");
    }
  }
  /*save function */
  /*fetch data function */
  initializeData() {
    if (this.savedAuditId) {
      this.auditId = this.savedAuditId;
    }
    if (this.parentCaseId) {
      this.auditId = this.parentCaseId;
    }
    if (this.auditId != null) {
      let model = {
        type: 'afl',
        auditId: this.auditId,
      }
      this.auditService.getAllCaseSummaryAfl(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.auditAflData = response.response;
          this.selectedStateName = response.response.state;

          if (this.auditAflData?.liabilityDetails) {
            this.auditAflData.liabilityDetails.forEach((liability: any) => {
              liability.total = this.inrService.formatCurrency(String(liability.total))
              liability.tax = this.inrService.formatCurrency(String(liability.tax))
              liability.interest = this.inrService.formatCurrency(String(liability.interest))
              liability.penalty = this.inrService.formatCurrency(String(liability.penalty))
            })
          }
          if (this.auditAflData?.itemList) {
            this.auditAflData.itemList.forEach((itemLists: any) => {
              if (itemLists?.findings) {
                itemLists.findings.forEach((issue: any) => {
                  issue.igst = this.inrService.formatCurrency(String(issue.igst))
                  issue.cgst = this.inrService.formatCurrency(String(issue.cgst))
                  issue.sgst = this.inrService.formatCurrency(String(issue.sgst))
                  issue.cess = this.inrService.formatCurrency(String(issue.cess))
                  issue.interest = this.inrService.formatCurrency(String(issue.interest))
                  issue.penalty = this.inrService.formatCurrency(String(issue.penalty))
                  issue.total = this.inrService.formatCurrency(String(issue.total))
                })
              }
            })
          }
          // this.auditAflData.state = this.selectedStateName  
          this.formInitialize();
          if (this.auditAflData.itemList && this.auditAflData.itemList.length > 0) {
            this.initializeItemList();
          } else {
            this.addFLetter();
          }
          if (this.auditAflData.liabilityDetails && this.auditAflData.liabilityDetails.length > 0) {
            this.initializeliabilityDetailsAndPaid();
            this.liabilityTotalCalculation();
          } else {
            this.addLiabilityInv();
          }
          if (this.action === 'view') {
            this.isauditAflData = false;

          } else {
            this.isauditAflData = true;

          }
          if (this.auditAflData.status == 'CLOSED SCN ISSUED') {
            this.isAuditConOne = true;

          } else if (this.auditAflData.status == 'CLOSED WITH PYMT LIB') {
            this.isAuditConTwo = true;
          }
        }
        this.issueValueChange();
      });
    }
  }
  initializeItemList() {
    if (this.auditAflData.itemList) {
      this.auditAflData.itemList.forEach((item: any, index: any) => {
        let findingletterGrp: UntypedFormGroup = this.initfindingletterGrp();
        (this.auditFindLtrForm.get('itemList') as UntypedFormArray).push(findingletterGrp);
        if (item.findingLetterLoc) {
          item.findingLetterLoc.forEach((not: any) => {
            let letterLocFrmGrp: UntypedFormGroup = this.createfindingLetterLoc(not);
            (findingletterGrp.get('findingLetterLoc') as UntypedFormArray).push(letterLocFrmGrp);

          });
        }
        if (item.othAttachmentLoc) {
          item.othAttachmentLoc.forEach((not: any) => {
            let othAttachmentLocFrmGrp: UntypedFormGroup = this.createOthAttachLoc(not);
            (findingletterGrp.get('othAttachmentLoc') as UntypedFormArray).push(othAttachmentLocFrmGrp);
          });
        }

        if (item.submissions) {
          item.submissions.forEach((submission: any) => {
            let submissionFrmGrp: UntypedFormGroup = this.initSubmissionFrmGrp(submission);
            (findingletterGrp.get('submissions') as UntypedFormArray).push(submissionFrmGrp);
            if (submission.ackCopyLoc) {
              submission.ackCopyLoc.forEach((not: any) => {
                let ackCopyLocFrmGrp: UntypedFormGroup = this.createAckCopyLoc(not);
                (submissionFrmGrp.get('ackCopyLoc') as UntypedFormArray).push(ackCopyLocFrmGrp);
              });
            }

            if (submission.othAttachmentLoc) {
              submission.othAttachmentLoc.forEach((not: any) => {
                let otherAttachLocFrmGrp: UntypedFormGroup = this.createSubOtherAttachCopyLoc(not);
                (submissionFrmGrp.get('othAttachmentLoc') as UntypedFormArray).push(otherAttachLocFrmGrp);
              });
            }

          });
        }
        if (item.findings.length > 0) {
          item.findings.forEach((finding: any) => {
            this.addFindings(index);
          })
        }
      });
      this.auditFindLtrForm.patchValue(this.auditAflData);
    }
  }

  initializeliabilityDetailsAndPaid() {
    if (this.auditAflData.liabilityDetails) {
      this.auditAflData.liabilityDetails.forEach((liability: any) => {
        let liabilityDetails = this.detailsOfLiabilityFrmGrp(liability);
        (this.auditFindLtrForm.get('liabilityDetails') as UntypedFormArray).push(liabilityDetails);

        if (liability.copyOfFormLoc) {
          liability.copyOfFormLoc.forEach((not: any) => {
            let copyFormLocFrmGrp: UntypedFormGroup = this.createCopyFormLoc(not);
            (liabilityDetails.get('copyOfFormLoc') as UntypedFormArray).push(copyFormLocFrmGrp);

          });
        }

        if (liability.copyOfBankChallanLoc) {
          liability.copyOfBankChallanLoc.forEach((not: any) => {
            let bankChallanFrmGrp: UntypedFormGroup = this.createBankFormLoc(not);
            (liabilityDetails.get('copyOfBankChallanLoc') as UntypedFormArray).push(bankChallanFrmGrp);

          });
        }


      })
      this.auditFindLtrForm.patchValue(this.auditAflData);
    }
  }



  // initializeItemList() {
  //   this.auditAflData.itemList.forEach((letter: any) => {
  //     let itemListGrp: FormGroup = this.iniFindingsGrp();
  //     (this.auditFindLtrForm.get('findings') as FormArray).push(itemListGrp);

  //   });
  //   this.auditFindLtrForm.patchValue(this.auditAflData);
  // }
  /*fetch data function */
  doValidation(phaseTab: any) {
    if (phaseTab === 'p2' && this.isAuditConOne == true) {
      this.liabilityValidation();
      this.p2validation();
    } else if (phaseTab === 'p2' && this.isAuditConTwo == true) {
      this.liabilityValidation();
      this.p2validation();
      this.p4validation();
    } else if (phaseTab === 'p2') {
      this.liabilityValidation();
      this.p2validation();
    }
  }
  /*update letter*/
  updateData(phaseTab: any) {
    this.isSubmitted = true;
    let letterAttachmentMissing = false;
    this.auditFindLtrForm.value.itemList.forEach((i: any) => {
      if (i.findingLetterLoc == null || i.findingLetterLoc == "") {
        letterAttachmentMissing = true;
      }
    })
    if (letterAttachmentMissing && phaseTab == 'p2') {
      this.toaster.showError("Please add missing attachment of Audit Findings Letter");
      return;
    }
    let data: any = {};
    this.doValidation(phaseTab);
    if (this.auditFindLtrForm.valid) {
      if (this.formGrp.itemList.value.length === 0) {
        this.toaster.showError('Letter data is required');
        return;
      }
      if (phaseTab === 'p1') {
        let formAdt1Adt3CopyFrmArray = (this.auditFindLtrForm.get('itemList') as UntypedFormArray).controls;
        let element1;
        for (let i = 0; i < formAdt1Adt3CopyFrmArray.length; i++) {
          element1 = (formAdt1Adt3CopyFrmArray[i].get('findingLetterLoc') as UntypedFormArray).controls;
        }
        let count = element1?.some((x: any) =>
          x.get('fileLoc')?.value != null || x.get('fileLoc')?.value != '');
        if (!count) {
          this.toaster.showError("Required Fields Are Empty");
          return;
        }
      }
      if (phaseTab === 'p2') {
        let itemListFrmArray: any = this.auditFindLtrForm.get('itemList') as UntypedFormArray;
        let element1;
        for (let index = 0; index < itemListFrmArray.length; index++) {
          const element = itemListFrmArray.controls[index];
          var submissionArray: any = element.controls.submissions.controls;
          for (let i = 0; i < submissionArray.length; i++) {
            element1 = (submissionArray[i].get('ackCopyLoc') as UntypedFormArray).controls;
          }
        }
        var count = element1?.some((x: any) => x.get('fileLoc')?.value != null || x.get('fileLoc')?.value != '');
        if (!count) {
          this.toaster.showError("Required Fields Are Empty");
          return;
        }
      }
      data = this.auditFindLtrForm.value;

      data.state = this.selectedStateName;
      if (this.savedAuditId) {
        data.auditId = this.savedAuditId;
      }
      let urlData = {
        type: 'afl',
        gstin: this.selectedGstin,
      }
      this.inrFormattingRemoving();
      let findingLetterLocArray: any[] = [];
      let otherAttachmentArray: any[] = [];
      let subAckArray: any[] = [];
      let subOtherAttachArray: any[] = [];
      let liabilityCopyArray: any[] = [];
      let liabilityBankChallanArray: any[] = [];
      for (let i = 0; i < this.auditFindLtrForm.controls.itemList.value.length; i++) {
        findingLetterLocArray = [];
        const letterArray = this.auditFindLtrForm.controls.itemList.value[i].findingLetterLoc;
        if (letterArray.length === 0) {
          this.auditFindLtrForm.controls.itemList.value[i].findingLetterLoc.status = 'INVALID';
        }
        for (let j = 0; j < letterArray.length; j++) {
          const element = letterArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            findingLetterLocArray.push(element)
          }
        }

        this.auditFindLtrForm.controls.itemList.value[i].findingLetterLoc = findingLetterLocArray;
      }
      for (let i = 0; i < this.auditFindLtrForm.controls.itemList.value.length; i++) {
        otherAttachmentArray = [];
        const otherAttachArray = this.auditFindLtrForm.controls.itemList.value[i].othAttachmentLoc;
        for (let j = 0; j < otherAttachArray.length; j++) {
          const element = otherAttachArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            otherAttachmentArray.push(element)
          }
        }
        this.auditFindLtrForm.controls.itemList.value[i].othAttachmentLoc = otherAttachmentArray;
      }
      for (let i = 0; i < this.auditFindLtrForm.controls.itemList.value.length; i++) {
        const submmissionArray = this.auditFindLtrForm.controls.itemList.value[i].submissions;
        for (let k = 0; k < submmissionArray.length; k++) {
          subAckArray = [];

          const elementArray = submmissionArray[k].ackCopyLoc

          for (let j = 0; j < elementArray.length; j++) {
            const element = elementArray[j];
            if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
              if (element.fileName === '') {
                element.fileName = null;
              }

              if (element.remarks === '') {
                element.remarks = null;
              }

              subAckArray.push(element)
            }
          }
          this.auditFindLtrForm.controls.itemList.value[i].submissions[k].ackCopyLoc = subAckArray;
        }

      }
      for (let i = 0; i < this.auditFindLtrForm.controls.itemList.value.length; i++) {
        const submmissionArray = this.auditFindLtrForm.controls.itemList.value[i].submissions;
        for (let k = 0; k < submmissionArray.length; k++) {
          subAckArray = [];

          const elementArray = submmissionArray[k].othAttachmentLoc

          for (let j = 0; j < elementArray.length; j++) {
            const element = elementArray[j];
            if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
              if (element.fileName === '') {
                element.fileName = null;
              }

              if (element.remarks === '') {
                element.remarks = null;
              }

              subAckArray.push(element)
            }
          }
          this.auditFindLtrForm.controls.itemList.value[i].submissions[k].othAttachmentLoc = subAckArray;
        }

      }
      for (let i = 0; i < this.auditFindLtrForm.controls.liabilityDetails.value.length; i++) {
        liabilityCopyArray = [];
        const liabilitycopyFormArray = this.auditFindLtrForm.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < liabilitycopyFormArray.length; j++) {
          const element = liabilitycopyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityCopyArray.push(element)
          }
        }
        this.auditFindLtrForm.controls.liabilityDetails.value[i].copyOfFormLoc = liabilityCopyArray;
      }

      for (let i = 0; i < this.auditFindLtrForm.controls.liabilityDetails.value.length; i++) {
        liabilityBankChallanArray = [];
        const bankChallanArray = this.auditFindLtrForm.controls.liabilityDetails.value[i].copyOfBankChallanLoc;

        for (let j = 0; j < bankChallanArray.length; j++) {
          const element = bankChallanArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            liabilityBankChallanArray.push(element)
          }
        }
        this.auditFindLtrForm.controls.liabilityDetails.value[i].copyOfBankChallanLoc = liabilityBankChallanArray;
      }

      this.auditService.updateAflData(data, urlData).subscribe((response: any) => {
        this.isSubmitted = false;
        let dataTable = this.auditFindLtrForm.controls;
        let valueType = dataTable.itemList.value[0].findings[0].status;
        if (response.status === 1) {
          this.showonSave = true;
          this.formatTableValues();
          if (data.itemList.length > 0) {
            data.itemList.forEach((e: any) => {
              e.isActive = true;
              // this.p1validation();    
            })
          }
          if (phaseTab === 'p1') {
            this.isPhase2 = true;
          }
          else if (this.auditFindLtrForm.value.status == 'CLOSED') {
            // this.router.navigate(['/auditSummary']);
          }
          // else if(valueType=='Favorable'|| valueType=='Open' || valueType=='Admitted' || valueType=='Partly Favorable' || this.auditFindLtrForm.value.status == 'CLOSED SCN ISSUED')
          // {
          //     this.toaster.showError("Please check at least one issue should be unfavorable");
          // }
          else if (this.auditFindLtrForm.value.status == 'CLOSED SCN ISSUED') {
            // this.router.navigate(['/auditSummary']);
            this.router.navigate(['/notice', this.auditId + '-1', { action: 'update', type: 'aja', tab: 'tab2' }]);
          }
          else if (this.auditFindLtrForm.value.status == 'OPEN') {
            // this.router.navigate(['/auditSummary']);
          }
          this.toaster.showSuccess(response.message);
        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  /*update letter*/
  /*upload file function */
  onFileSelect(event: any, type: any = null) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        // this.formData = new FormData();
        // this.formData.append('fname', this.selectedFile);
        var regExp = /\(([^)]+)\)/;  //GET THE VALUE of (0) FROM itemList(0).letterLOC
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, type);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  onUpload(selectedfield: any, type: any) {
    if (this.navContext.entityType === 'FILING') {
      this.gstinOrPan = this.navContext.gstin;
    } else if (this.navContext.entityType === 'LEGAL') {
      this.gstinOrPan = this.navContext.pan;
    } else if (this.navContext.entityType === 'Business') {
      this.gstinOrPan = this.navContext.pan;
    }
    let urlData = {
      gstinOrPan: this.gstinOrPan,
      auditId: this.auditId ? this.auditId : this.savedAuditId,
      type: 'afl',
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.auditService.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        //this.fileUrl = response.url;
        //this.filePath = response.path;
        this.getFileUrl(response.response.url, response.response.path, selectedfield, type);
      } else {
      }
    })
  }

  // onUpload(selectedfield:any, type :any) {
  //   let requestBody: any = new FormData();
  //   requestBody.append('caseId', this.auditId ? this.auditId : this.savedAuditId);
  //   requestBody.append('type', 'afl');
  //   requestBody.append('field', selectedfield);
  //   requestBody.append('fmime', this.selectedFile.type);
  //   requestBody.append('fname', this.selectedFile.name);
  //   requestBody.append('file', this.selectedFile);
  //   this.auditService.uploadFile(requestBody).subscribe((response: any) => {
  //     if (response && response.status === 1) {
  //       this.setFormData(response.response, selectedfield, type)
  //     }
  //   })
  // }
  // setFormData(path: any, selectedfield: any,type: any = null) {
  getFileUrl(url: any, path: any, selectedfield: any, type: any = null) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      if (selectedfield) {
        switch (selectedfield) {
          case 'certIncorpLoc':
          case 'panCompLoc':
          case 'panDirLoc':
            //this.basicDetailValue[selectedfield] = path;
            break;
          default:
            let toupdate, pathToVeriabl;
            if (selectedfield.includes('basicDetails(0)')) {
              let rmBasicDet = selectedfield.replace('basicDetails(0).', '');  //replaced basicDetails(0) with blank         
              //toupdate = this.basicDetailValue;
              pathToVeriabl = this.createPath(rmBasicDet);
            } else {
              toupdate = this.auditFindLtrForm.value;
              pathToVeriabl = this.createPath(selectedfield);
            }
            for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
              if (pathIndex !== pathToVeriabl.length - 1) {
                toupdate = toupdate[pathToVeriabl[pathIndex]];
              } else {
                toupdate[pathToVeriabl[pathIndex]] = path;
              }
            }

        }
        this.auditFindLtrForm.patchValue(this.auditFindLtrForm.value);
      }
      else if (this.selectedFile && type === 'liabilityDetails' && this.matches) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (this.auditFindLtrForm.get('liabilityDetails') as UntypedFormArray).controls.forEach((c: any, index) => {
          if (letterIndex == index) {
            c.patchValue(attrFieldNm[1], path);
            c.controls[attrFieldNm[1]].setValue(path);
          }
        });
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }


  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  /*upload function */

  /* get download file path function */
  getFileNameDta(fileUrl: any) {
    if (fileUrl && typeof (fileUrl) == 'string')
      return this.auditService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.auditService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        //this.downloadfileUrl() ;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }
  personResponsibleData() {
    let model: any = {};
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
      model.companyId = ''
    } else {
      model.companyId = this.selectedCompanyId;
      model.gstin = ''
    }
    this.auditService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });

  }
  deleteFindingLetter3File(i: any) {
    let itemListArray = this.auditFindLtrForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (let index = 0; index < itemListArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = itemListArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.findingLetterLoc.setValue(null);
          issuesControl.value['isSupportingDocLocDocUploadedClicked'] = true;
        }
      }
    }
  }

  deleteOthAttachmentFile(i: any) {
    let itemListArray = this.auditFindLtrForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (let index = 0; index < itemListArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = itemListArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.othAttachmentLoc.setValue(null);
          issuesControl.value['isSupportingDocLocDocUploadedClicked'] = true;
        }
      }
    }
  }

  // deleteAckCopyFile(itemListIndex:any,SubmissionIndex:any){
  //   let itemListsArray = this.auditFindLtrForm.controls.itemList as FormGroup;
  //   let submissionsArray = itemListsArray.controls[itemListIndex].get('submissions') as FormArray;
  //   if(submissionsArray.controls.length > 0) {
  //     for(let index = 0; index < submissionsArray.controls.length; index++){
  //         if(index === SubmissionIndex){
  //           let itemListuploadControl = submissionsArray.controls[index] as FormGroup;
  //           itemListuploadControl.value['isdocLocUploadedClicked'] = true
  //           itemListuploadControl.value['ackCopyLoc'] = null
  //         }
  //       }
  //   } 
  // }

  // deleteOtherAttachmentFile(itemListIndex:any,SubmissionIndex:any){
  //   let itemListsArray = this.auditFindLtrForm.controls.itemList as FormGroup;
  //   let submissionsArray = itemListsArray.controls[itemListIndex].get('submissions') as FormArray;
  //   if(submissionsArray.controls.length > 0) {
  //     for(let index = 0; index < submissionsArray.controls.length; index++){
  //         if(index === SubmissionIndex){
  //           let itemListuploadControl = submissionsArray.controls[index] as FormGroup;
  //           itemListuploadControl.value['isdocLocUploadedClicked'] = true
  //           itemListuploadControl.value['othAttachmentLoc'] = null
  //         }
  //       }
  //   } 
  // }


  /* get download file path function */
  uploaddocsSubmittedLoc() {
    this.isdocsSubmittedLocFile = true;
    this.isdocsSubmittedLocText = false;
    this.isDWdocsSubmittedLoc = false;
    this.isUPdocsSubmittedLoc = false;
  }
  uploadackReplyCopyLoc() {
    this.isackReplyCopyLocFile = true;
    this.isackReplyCopyLocText = false;
    this.isDWackReplyCopyLoc = false;
    this.isUPackReplyCopyLoc = false;
  }
  uploadfindingLetterLoc() {
    this.isfindingLetterLocFile = true;
    this.isfindingLetterLocText = false;
    this.isDWfindingLetterLoc = false;
    this.isUPfindingLetterLoc = false;
  }
  uploadbankChallanLoc() {
    this.isbankChallanLocFile = true;
    this.isbankChallanLocText = false;
    this.isDWbankChallanLoc = false;
    this.isUPbankChallanLoc = false;
  }
  uploadothAttachmentLoc() {
    this.isothAttachmentLocFile = true;
    this.isothAttachmentLocText = false;
    this.isDWothAttachmentLoc = false;
    this.isUPothAttachmentLoc = false;
  }
  uploaddrc03CopyLoc() {
    this.isdrc03CopyLocFile = true;
    this.isdrc03CopyLocText = false;
    this.isDWdrc03CopyLoc = false;
    this.isUPdrc03CopyLoc = false;
  }
  uploadcopyOfAckLoc() {
    this.iscopyOfAckLocFile = true;
    this.iscopyOfAckLocText = false;
    this.isDWcopyOfAckLoc = false;
    this.isUPcopyOfAckLoc = false;
  }
  uploadackCopyLoc() {
    this.isackCopyLocFile = true;
    this.isackCopyLocText = false;
    this.isDWackCopyLoc = false;
    this.isUPackCopyLoc = false;
  }
  uploadotherAttac2() {
    this.isotherAttac2File = true;
    this.isotherAttac2Text = false;
    this.isDWotherAttac2 = false;
    this.isUPotherAttac2 = false;
  }
  setStateAutoPop() {
    if (this.selectedFilling.gstin) {
      this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
    }
    this.selectedStateName = this.commonServ.getStateName(this.selectedStateCode);

  }
  checkStatus(event: any) {
    if (event.target.value === "CLOSED SCN ISSUED" || this.action === 'view') {
      this.openScnTrigFavPop(this.scnTrigPop);
      this.isAuditConOne = true;
      this.isAuditConTwo = false;
    } else if (event.target.value === "CLOSED WITH PYMT LIB" || this.action === 'view') {
      this.isAuditConTwo = true;
      this.isAuditConOne = false;
    } else {
      this.isAuditConOne = false;
      this.isAuditConTwo = false;
    }
    if (event.target.value === "CLOSED") {
      let data: any = {};
      data = this.auditFindLtrForm.value;
      if (data.itemList.length > 0) {
        data.itemList.forEach((e: any) => {
          e.findings.forEach((f: any) => {
            if (f.status === "Unfavorable") {
              this.isUnfav = true;
              this.toaster.showError("Cant close Unfavorable issues");
            }
          })
        })
      }
    } else {
      this.isUnfav = false;
    }

  }
  checkPayMode(event: any) {
    if (event.target.value === "Cash") {
      this.hideItc = true;
      this.hideCash = false;
    } else if (event.target.value === "ITC") {
      this.hideCash = true;
      this.hideItc = false;
    } else if (event.target.value === "Both") {
      this.hideCash = false;
      this.hideItc = false;
    }
  }
  issueDropdown() {
    this.dataSource = ISSUE_GST;


    // this.dataSource = [
    //   {
    //     group: 'Return scrutiny ',
    //     items: [
    //       {id: 1, name: 'GSTR2A vs GSTR3B'},
    //       {id: 2, name: 'GSTR1 vs GSTR3B'},
    //       {id: 3, name: 'E-invoice vs GSTR1'},
    //       {id: 4, name: 'E-way bill vs GSTR1'},
    //       {id: 5, name: 'GSTR 9 vs GSTR 1/3B '},
    //       {id: 6, name: 'GSTR2B vs GSTR3B'}
    //     ]
    //   },
    //   {
    //     group: 'Procedural ',
    //     items: [
    //       {id: 11, name: 'TDS/TCS issues '},
    //       {id: 12, name: 'E invoice issues'},
    //       {id: 13, name: 'Eway bill issues'}
    //     ]
    //   },
    //   {
    //     group: 'Classification/Rate ',
    //     items: [
    //       {id: 21, name: 'HSN classification '},
    //       {id: 22, name: 'SAC classification '},
    //       {id: 23, name: 'Composite Supply vs Mixed Supply '},
    //       {id: 24, name: 'Goods vs Service '},
    //       {id: 24, name: 'Taxability of Riders in Life Insurance '},
    //       {id: 25, name: 'Permanent transfer of Intellectual property '},
    //     ]
    //   },
    //   {
    //     group: 'Taxability  ',
    //     items: [
    //       {id: 31, name: 'Warranty supplies '},
    //       {id: 32, name: 'Liquidated Damages '},
    //       {id: 33, name: 'Notice Pay Recovery '},
    //       {id: 34, name: 'Toleration of an act '},
    //       {id: 34, name: 'Works Contract '},
    //       {id: 35, name: 'Canteen recoveries from employees '},
    //       {id: 36, name: 'Other recoveries from employees'},
    //       {id: 37, name: 'Carry income '},
    //       {id: 38, name: 'Commission received from overseas customer '},
    //       {id: 39, name: 'Profit share from overseas customers '},
    //       {id: 40, name: 'Other intermediary services'},
    //       {id: 41, name: 'Transfer of leasehold rights '},
    //       {id: 42, name: 'Outward FOC supplies'},
    //       {id: 43, name: 'Stock transfer on sales promotion material '},
    //       {id: 44, name: 'Donation/Grants received '},
    //       {id: 45, name: 'Supply to ship/vessels '},
    //       {id: 46, name: 'Interest on delayed receipt of consideration from customers '},
    //       {id: 47, name: 'Transfer of business as a going concern'},
    //       {id: 48, name: 'Sale of re possessed assets under SARFAESI Act'},
    //     ]
    //   },
    //   {
    //     group: 'Liability   ',
    //     items: [
    //       {id: 49, name: 'Interest on delayed payment of tax '},
    //       {id: 50, name: 'Time of supply issues  '},
    //       {id: 51, name: 'ISD distribution  '},
    //       {id: 52, name: 'Issuance of credit notes '},
    //       {id: 53, name: 'Adjustment of Credit notes'},
    //     ]
    //   },
    //   {
    //     group: 'Valuation ',
    //     items: [
    //       {id: 54, name: 'Related parties'},
    //       {id: 55, name: 'Corporate guarantees'},
    //       {id: 56, name: 'Post Sale discounts '},
    //       {id: 57, name: 'Joint Development Agreement '},
    //       {id: 58, name: 'Reimbursements in the nature of pure agent recovery'},
    //       {id: 59, name: 'Valuation on Cross charge transactions '},
    //       {id: 60, name: 'Brand and license usage given to subsidiary companies'}
    //     ]
    //   },
    //   {
    //     group: 'ITC ',
    //     items: [
    //       {id: 61, name: 'Motor Vehicles for Transportation of Passengers (Less than 13 Pax)'},
    //       {id: 62, name: 'General Insurance, Servicing, Repair & Maintenance of above'},
    //       {id: 63, name: 'Food & Beverages including Outdoor Catering '},
    //       {id: 64, name: 'Leasing, renting or hiring of motor vehicles for Passenger Transportation '},
    //       {id: 65, name: 'Life Insurance '},
    //       {id: 66, name: 'Health Insurance '},
    //       {id: 67, name: 'Works Contract Services (or) Goods/Services used in Construction – to the extent of Capitalization'},
    //       {id: 68, name: 'Goods lost, stolen, destroyed, written off or disposed of by way of gift or free samples'},
    //       {id: 69, name: 'Beauty treatment, Health services, Cosmetic and Plastic surgery'},
    //       {id: 70, name: 'Membership of a Club, Health and Fitness Centre'},
    //       {id: 71, name: 'Travel benefits to employees on vacation such as leave or home travel concession'},
    //       {id: 72, name: 'Goods or Services or both used for personal consumption.'},
    //       {id: 73, name: 'Time barred Credits'},
    //       {id: 74, name: 'Common Credit Reversals'},
    //       {id: 75, name: 'ITC reversal if payment is not made within 180 days'},
    //       {id: 76, name: 'Rule 36(4) reversals'},
    //       {id: 77, name: 'Ineligible credit - ISD'},
    //       {id: 78, name: 'ITC on promotional goods'},
    //       {id: 79, name: 'CSR credit '},
    //       {id: 81, name: 'Residential colony '},
    //       {id: 82, name: 'Canteen credit '},
    //       {id: 83, name: 'ITC on reversal of securities'},
    //       {id: 84, name: 'Excess claim of ITC'}
    //     ]
    //   },
    //   {
    //     group: 'Transition  ',
    //     items: [
    //       {id: 84, name: 'Capital goods in transit '},
    //       {id: 85, name: 'Trading inventory '},
    //       {id: 86, name: 'Edu cess '},
    //       {id: 87, name: 'Service invoice accounted after 30th June '},
    //       {id: 88, name: 'Refund under Sec 142(3) '},
    //       {id: 89, name: 'VAT C form issue '},
    //       {id: 91, name: 'Entry Tax transition issue '},
    //       {id: 92, name: 'Mismatch of Earlier returns vs TRAN - 1'},
    //       {id: 93, name: 'Pre GST inventory written off '},
    //       {id: 94, name: 'Reversal of excess ITC claimed in Trans -1 '},
    //       {id: 95, name: 'Interest on reversal of excess ITC claimed in Trans -1'},

    //     ]
    //   },
    //   {
    //     group: 'Job Work   ',
    //     items: [
    //       {id: 94, name: 'Goods not returning from JW premises after 1/3 years'},
    //       {id: 95, name: 'Loss/Scrap at JW premises '},
    //       {id: 96, name: 'ITC - 04 vs JW register '}         
    //     ]
    //   },
    //   {
    //     group: 'RCM',
    //     items: [
    //       {id: 97, name: 'RCM not paid'},     
    //     ]
    //   },
    //   {
    //     group: 'Refunds',
    //     items: [
    //       {id: 98, name: 'Rule 96A - Export of goods not being done within 105 days post issuance of invoice'},     
    //       {id: 99, name: 'Rule 96B - Export refund restriction FEMA '},     
    //       {id: 100, name: 'Rule 96(10) - Merchant export , Advance license'},     
    //       {id: 101, name: 'Time limit/Limitation issues '},     
    //     ]
    //   },
    // ];
  }
  openrmFindingLPop(content: any, i: any) {
    this.rmvFindingLModalRef = this.modalService.open(content);
  }
  openRmvFindingPop(content: any, i: any) {
    this.rmvFindingsModalRef = this.modalService.open(content);
  }
  opensubmissionPop(content: any, i: any) {
    this.rmvsubmissionModalRef = this.modalService.open(content);
  }

  openScnTrigFavPop(content: any) {
    this.modalService.open(content);
  }

  omitNegativeValue(event: any) {
    if (event.charCode >= 48 && event.charCode <= 57) {
      return true;
    }
    return false;

  }

  onPaste(event: any, id: any, tableName: any, index: any, itemListIndex: any = null) {
    let itemListArr: any
    let findingArr: any
    if (tableName === 'findings') {
      itemListArr = this.auditFindLtrForm.get('itemList') as UntypedFormGroup;
      findingArr = itemListArr.controls[index].controls.findings.value
    }
    let keyCodeEvent = { keyCode: 8 }
    event.preventDefault();
    let value: any = Math.round(+event.clipboardData.getData('Text'));
    let data = tableName === 'findings' ? findingArr : this.auditFindLtrForm.get(tableName)?.value;

    data[tableName === 'findings' ? itemListIndex : index][id] = value;
    if (tableName === 'findings') {
      itemListArr.controls[index].controls.findings.setValue(data)
    } else { this.auditFindLtrForm.get(tableName)?.setValue(data) }
    this.onKeyDownEvent(keyCodeEvent, tableName);
  }

  onKeyDownEvent(event: any, type: any) {
    if (event.keyCode === 8 || event.keyCode === 46 || event.keyCode === 86 || event.keyCode == 17) {
      if (type === 'liabilityDetails') {
        this.liabilityTotalCalculation();
      }
      if (type === 'findings') {
        this.issueValueChange();
      }
    }
  }

  issueValueChange() {
    (this.auditFindLtrForm.get('itemList') as UntypedFormArray).controls.forEach((item: any) => {
      let igstTotal: number = 0;
      let cgstTotal: number = 0;
      let sgstTotal: number = 0;
      let cessTotal: number = 0;
      let interestTotal: number = 0;
      let penaltyTotal: number = 0;


      (item.get('findings') as UntypedFormArray).controls.forEach((c) => {
        const igst = this.inrService.removeCommasAndParseNumber(c.value.igst);
        const cgst = this.inrService.removeCommasAndParseNumber(c.value.cgst);
        const sgst = this.inrService.removeCommasAndParseNumber(c.value.sgst);
        const cess = this.inrService.removeCommasAndParseNumber(c.value.cess);
        const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);
        const penalty = this.inrService.removeCommasAndParseNumber(c.value.penalty);

        igstTotal += Number(igst);
        cgstTotal += Number(cgst);
        sgstTotal += Number(sgst);
        cessTotal += Number(cess);
        interestTotal += Number(interest);
        penaltyTotal += Number(penalty);

        c.patchValue({
          total: this.inrService.formatCurrency(String(Number(igst) + Number(cgst) + Number(sgst) + Number(cess) + Number(interest) + Number(penalty))),
        });
      });

      item.controls['igstTotal'].setValue(this.inrService.formatCurrency(String(igstTotal)));
      item.controls['cgstTotal'].setValue(this.inrService.formatCurrency(String(cgstTotal)));
      item.controls['sgstTotal'].setValue(this.inrService.formatCurrency(String(sgstTotal)));
      item.controls['cessTotal'].setValue(this.inrService.formatCurrency(String(cessTotal)));
      item.controls['interestTotal'].setValue(this.inrService.formatCurrency(String(interestTotal)));
      item.controls['penaltyTotal'].setValue(this.inrService.formatCurrency(String(penaltyTotal)));

      item.controls['totOfTotals'].setValue(this.inrService.formatCurrency(String(Number(igstTotal) + Number(cgstTotal) + Number(sgstTotal) + Number(cessTotal) + Number(interestTotal) + Number(penaltyTotal))));
    });

  }



  // issueValueChange(){
  //   (this.auditFindLtrForm.get('itemList') as FormArray).controls.forEach((c : any) =>{
  //   let igstTotal : number = 0
  //     let cgstTotal  : number=0;
  //     let sgstTotal  : number=0;
  //     let cessTotal  : number=0;
  //     let interestTotal : number =0;
  //     let penaltyTotal : number =0;

  //     (c.get('findings') as FormArray).controls.forEach((c) =>{
  //       igstTotal += +(c.value.igst);
  //       cgstTotal +=  +(c.value.cgst);
  //       sgstTotal+= +(c.value.sgst);
  //       cessTotal+= +(c.value.cess);
  //       interestTotal+= +(c.value.interest);
  //       penaltyTotal+= +(c.value.penalty);

  //       c.patchValue({total: (+c.value.igst)  + (+c.value.cgst) + (+c.value.sgst) + (+c.value.cess) + (+c.value.interest) + (+c.value.penalty)});

  //     })
  //     c.controls['igstTotal'].setValue(igstTotal);
  //     c.controls['cgstTotal'].setValue(cgstTotal);
  //     c.controls['sgstTotal'].setValue(sgstTotal);
  //     c.controls['cessTotal'].setValue(cessTotal);
  //     c.controls['interestTotal'].setValue(interestTotal);
  //     c.controls['penaltyTotal'].setValue(penaltyTotal);

  //     c.controls['totOfTotals'].setValue((+igstTotal) + (+cgstTotal) + (+sgstTotal) + (+cessTotal) + (+interestTotal) + (+penaltyTotal));
  //   })
  // }


  formatTableValues() {
    const issues1Array = this.auditFindLtrForm.controls.itemList as UntypedFormArray

    const liabilityDetailsArray = this.auditFindLtrForm.controls.liabilityDetails as UntypedFormArray

    if (issues1Array.controls && issues1Array.controls.length) {
      issues1Array.controls.forEach((control: any) => {
        if (control.value && control.value.findings && control.value.findings.length) {
          control.value.findings.forEach((issue: any) => {
            issue.igst = this.inrService.formatCurrency(issue.igst)
            issue.cgst = this.inrService.formatCurrency(issue.cgst)
            issue.sgst = this.inrService.formatCurrency(issue.sgst)
            issue.cess = this.inrService.formatCurrency(issue.cess)
            issue.interest = this.inrService.formatCurrency(issue.interest)
            issue.penalty = this.inrService.formatCurrency(issue.penalty)
            issue.total = this.inrService.formatCurrency(issue.total)
          })
        }
      })
    }
    if (liabilityDetailsArray.controls.length > 0) {
      for (let index = 0; index < liabilityDetailsArray.controls.length; index++) {
        const liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
        liabilityDetailsControl.controls['tax'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.tax))
        liabilityDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.interest))
        liabilityDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.penalty))
        liabilityDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.total))
      }
    }
    this.issueValueChange();
  }
  inrFormattingRemoving() {
    const data = this.auditFindLtrForm.value;
    //phase 1 and 2 form

    if (data.itemList && data.itemList.length) {
      data.itemList.forEach((itemList: any) => {

        if (itemList.findings && itemList.findings.length) {
          itemList.findings.forEach((issue: any) => {
            issue.igst = this.inrService.removeCommasAndParseNumber(issue.igst)
            issue.cgst = this.inrService.removeCommasAndParseNumber(issue.cgst)
            issue.sgst = this.inrService.removeCommasAndParseNumber(issue.sgst)
            issue.cess = this.inrService.removeCommasAndParseNumber(issue.cess)
            issue.interest = this.inrService.removeCommasAndParseNumber(issue.interest)
            issue.penalty = this.inrService.removeCommasAndParseNumber(issue.penalty)
            issue.total = this.inrService.removeCommasAndParseNumber(issue.total)
          })
        }
      })
    }
    if (data.itemList && data.itemList.length) {
      data.itemList.forEach((data: any) => {
        data.igstTotal = this.inrService.removeCommasAndParseNumber(data.igstTotal)
        data.cgstTotal = this.inrService.removeCommasAndParseNumber(data.cgstTotal)
        data.sgstTotal = this.inrService.removeCommasAndParseNumber(data.sgstTotal)
        data.cessTotal = this.inrService.removeCommasAndParseNumber(data.cessTotal)
        data.interestTotal = this.inrService.removeCommasAndParseNumber(data.interestTotal)
        data.penaltyTotal = this.inrService.removeCommasAndParseNumber(data.penaltyTotal)
        data.totOfTotals = this.inrService.removeCommasAndParseNumber(data.totOfTotals)
      })
    }

    if (data.liabilityDetails && data.liabilityDetails.length) {
      data.liabilityDetails.forEach((liability: any) => {
        liability.tax = this.inrService.removeCommasAndParseNumber(liability.tax)
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
      })
    }
  }

  handlePaste(event: any) {
    this.inrService.handlePaste(event)
  }


  // issueValueChange(){
  //   let igstTotal = 0
  //   let cgstTotal =0;
  //   let sgstTotal =0;
  //   let cessTotal =0;
  //   let interestTotal =0;
  //   let penaltyTotal =0;

  //   (this.auditFindLtrForm.get('itemList') as FormArray).controls.forEach((c : any) =>{
  //     (c.get('findings') as FormArray).controls.forEach((f) =>{

  //     igstTotal += (+f.value.igst);
  //     cgstTotal +=  (+f.value.cgst);
  //     sgstTotal+= (+f.value.sgst);
  //     cessTotal+= (+f.value.cess);
  //     interestTotal+= (+f.value.interest);
  //     penaltyTotal+= (+f.value.penalty);

  //     c.controls['igstTotal'].setValue(igstTotal);
  //   c.controls['cgstTotal'].setValue(cgstTotal);
  //   c.controls['sgstTotal'].setValue(sgstTotal);
  //   c.controls['cessTotal'].setValue(cessTotal);
  //   c.controls['interestTotal'].setValue(interestTotal);
  //   c.controls['penaltyTotal'].setValue(penaltyTotal);
  //   c.controls['totOfTotals'].setValue((+igstTotal) + (+cgstTotal) + (+sgstTotal) + (+cessTotal) + (+interestTotal) + (+penaltyTotal));

  //     f.patchValue({total: (+f.value.igst)  + (+f.value.cgst) + (+f.value.sgst) + (+f.value.cess) + (+f.value.interest) + (+f.value.penalty)});
  //   })
  // })
  // }
}
