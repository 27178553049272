import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ChartOptions, ChartType } from 'chart.js';
import { CommonService } from 'src/app/shared/services/common.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

export enum DEMAND_VALUE {
  ISSUE = 'issue',
  TAX = 'Tax',
  INTEREST = 'Interest',
  PENALTY = 'Penalty',
  TOTAL = 'Demand Amount'
}
@Component({
  selector: 'app-a1-chart',
  templateUrl: './a1-chart.component.html',
  styleUrls: ['./a1-chart.component.css']
})
export class A1ChartComponent implements OnInit {
  @Input() a1Data: any;

  public barChartLabels: string[] = [];
  public barChartType: any = 'bar';
  public barChartLegend: boolean = true;

  ListOfTax: any = [];
  a1BarChartLabels: any = [];
  a1BarChartLegend = true;
  a1BarChartData: any = [];
  a1BarChartPlugins: any = [];
  chartLabel: any = [];
  chartData: any = [];
  chartDatIncr: any = [];
  a1BarChartOptions: ChartOptions = {};
  a1BarChartType: ChartType = 'bar';
  currentIssueLabel: any;
  chartColors: any = [];
  popRef!: NgbModalRef;
  popSummary: any = [];
  currentLabel: any;
  issueLabel: any;
  tableData: any;
  isinCR: boolean = false;
  isinLakh: boolean = true;
  selectedIRN: boolean = false;
  selectedData: boolean = false;
  amountMetric: 'lakh' | 'crore' = 'lakh';
  data: any[] = [];
  issueChartData: any = [];
  demandValue = DEMAND_VALUE;
  isAnalyticsConOne: boolean = false
  sorting: { issue: boolean, tax: boolean, interest: boolean, penalty: boolean, total: boolean } = { issue: false, tax: false, interest: false, penalty: false, total: false };
  barbackgroundColors = ['#ff5722', '#03a9f4', '#9c27b0', '#e51c23', '#607d8b', '#8bc34a'];
 
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        title: { display: true, text: 'Tax' },
        ticks: {
          autoSkip: false,
          maxRotation: 90,
          minRotation: 90 },      
      },
      y: { title: { display: true, text: '(₹) in Lakhs' } }
    },
  };
  constructor(
    private commonService: CommonService,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private toasterService: ToasterService,
    private router: Router) { }
  ngOnInit() {
    this.initializeA1Data();
  }
  changeStatsValue(selected: any, event: Event) {
    const isCrore = (event.target as HTMLInputElement).checked;
    this.amountMetric = isCrore ? 'crore' : 'lakh';
    if (selected == false) {
      this.isinCR = false;
      this.isinLakh = true;
    }
    else if (selected == true) {
      this.isinCR = true;
      this.isinLakh = false;
    }
    this.initializeA1Data();

  }

  initializeA1Data() {
    if(this.isinCR === true){
      this.ListOfTax = this.a1Data;
      this.chartLabel = Object.keys(this.ListOfTax);
      this.chartLabel.forEach((chartLabel: any) => {
        this.chartDatIncr.push(this.a1Data[chartLabel]?.totalInCr);
      })
      this.a1BarChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            title: { display: true, text: 'Demand (₹) in Crores' },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0
            }
          },
          y: { title: { display: true, text: '' } }
        },
        plugins: { legend: { display: false } },
        indexAxis: 'y'
      };
      this.a1BarChartLabels = this.chartLabel;
          this.a1BarChartData = { 
            labels: this.a1BarChartLabels, 
            datasets: [{ data: this.chartDatIncr,backgroundColor: this.barbackgroundColors, barThickness: 25}]
          };
      this.chartColors = [
        {
          backgroundColor: '#00bcd4',
          borderColor: "#00bcd4"
        }
      ]
    }else{
      this.ListOfTax = this.a1Data;
      this.chartLabel = Object.keys(this.ListOfTax);
      this.chartLabel.forEach((chartLabel: any) => {
        this.chartData.push(this.a1Data[chartLabel]?.total);
      })
      this.a1BarChartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            title: { display: true, text: 'Demand (₹) in Lakhs' },
            ticks: {
              autoSkip: false,
              maxRotation: 0,
              minRotation: 0
            }
          },
          y: { title: { display: true, text: '' } }
        },
        plugins: { legend: { display: false } },
        indexAxis: 'y'
      };
      this.a1BarChartLabels = this.chartLabel;
          this.a1BarChartData = { 
            labels: this.a1BarChartLabels, 
            datasets: [{ data: this.chartData, backgroundColor: this.barbackgroundColors, barThickness: 25}] 
          };
      this.chartColors = [
        {
          backgroundColor: '#00bcd4',
          borderColor: "#00bcd4"
        }
      ]
    }

  }

  openChart(content: any, e: any) {
    const index = e.active?.[0]?.index ?? -1;
    if (index > -1) {
      this.currentLabel = e.event.chart?.data?.labels?.[index] ?? '';    
      const keys = Object.keys(this.a1Data[this.currentLabel].details);
      
      this.tableData = [];
      keys.forEach((key: any) => {
        const values = this.a1Data[this.currentLabel].details[key];
        values?.caseDetails.forEach((a: any) => {
          a.type = this.getStageName(a.stage);
        });
  
        this.tableData.push({
          issue: key,
          tax: values.tax,
          interest: values.interest,
          penalty: values.penalty,
          total: values.total,
          deposit: values.deposit,
          totalCount: values.totalCount,

          taxInCr: values.taxInCr,
          interestInCr: values.interestInCr,
          penaltyInCr: values.penaltyInCr,
          totalInCr: values.totalInCr,
          caseDetails: values.caseDetails,
          expanded: false,
          
        });  
      })
      this.popRef = this.modalService.open(content, { windowClass: 'preanalytics-pop' });
    }
   
  }
  
  getPreGstCaseData(caseId: number, action: string, type: string, tab: string) {
    this.router.navigate(['createPreGstCase', caseId, { action: action, type: type, tab: tab }]);
    this.popRef.close();
  }
  getIssueLabel(key: string, e: any) {
    this.currentIssueLabel = key;
    this.router.navigate(['/preGstSummary']);
    localStorage.setItem('preAnalyticIssueLbl', this.currentIssueLabel);
    this.popRef.close();
  }

  sort(column: string) {
    switch (column) {
      case DEMAND_VALUE.ISSUE: {
        if (!this.sorting.issue) {
          this.sorting.issue = true;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'issue', 'asc');
        } else {
          this.sorting.issue = false;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'issue', 'desc');
        }
        break;
      }

      case DEMAND_VALUE.TAX: {
        if (!this.sorting.tax) {
          this.sorting.tax = true;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'tax', 'asc');
        } else {
          this.sorting.tax = false;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'tax', 'desc');
        }
        break;
      }

      case DEMAND_VALUE.INTEREST: {
        if (!this.sorting.interest) {
          this.sorting.interest = true;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'interest', 'asc');
        } else {
          this.sorting.interest = false;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'interest', 'desc');
        }
        break;
      }

      case DEMAND_VALUE.PENALTY: {
        if (!this.sorting.penalty) {
          this.sorting.penalty = true;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'penalty', 'asc');
        } else {
          this.sorting.penalty = false;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'penalty', 'desc');
        }
        break;
      }

      case DEMAND_VALUE.TOTAL: {
        if (!this.sorting.total) {
          this.sorting.total = true;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'total', 'asc');
        } else {
          this.sorting.total = false;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'total', 'desc');
        }
        break;

      }
    }
  }


  getStageName(key: string){

    let stageName = key;
    if (key == "scn") {
      stageName= 'Pre-Litigation';
    } else if (key == "aja") {
      stageName = 'Adjudication';
    } else if (key == "apa") {
      stageName = 'Commissioner (A)';
    } else if (key == "apt") {
      stageName = 'Appellate Tribunal';
    } else if (key == "hc") {
      stageName = 'High Court';
    } else if (key == "sc") {
      stageName = 'Supreme  Court';
    }
    return stageName;

  }

  exportAsXLSX() {
    let excelData: any = [];
    
    if (this.tableData.length === 0) {
      this.toasterService.showError('No data to export.');
      return;
    }

    this.tableData.forEach((item : any, index: any) => {
        let obj = {
          "Sr No": index + 1,
          "Issue": item.issue,
          "Tax":this.isinLakh ?  item.tax.toFixed(2) : item.taxInCr.toFixed(2),
          "Interest":  this.isinLakh ?  item.interest.toFixed(2) : item.interestInCr.toFixed(2),
          "Penalty": this.isinLakh ?  item.penalty.toFixed(2) : item.penaltyInCr.toFixed(2),
          "Total": this.isinLakh ?  item.total.toFixed(2) : item.totalInCr.toFixed(2)
        }
        excelData.push(obj);
    });

    this.excelService.exportAsExcelFile(excelData, 'Issue Wise Demand Details -' +  this.currentLabel);

  }



}

