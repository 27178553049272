import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateAssessmentRequest, CreateIntimationOrderRequest } from 'src/app/models/direct-tax.interface';
import { ISSUING_AUTHORITY, TYPE_OF_NOTICE, ADJ_DEPTPRE, GRANTED, MODE_OF_RECEIPT, ISSUE_RISK_PARAM, ISSUE_CONCLUSION_DT, TYPE_OF_MATTER, REDUCED1, RETURN_FILED, ITR_Form_Type, QUARTER, YES_NO, DT_ISSUE_DROPDOWN, NOTICE_ISSUED_US, TYPE_OF_RESPONSE, TYPE_OF_CASE, WRIT_TO_HC, ADJ_PH1_ADJUNOT, ADJ_ATTEND, TYPE_NOTICE, RECTIFICATION_MODE, TYPE_OF_ODRDER, ORDER_PASSED_BY, INTIMATION_OUTCOME, ASSESSMENT_OUTCOME, FORUM_ENQUIRY_PROCEED, ACTION_INTIMATION_ORDER3, MODE_OF_PAYMENT, ORDER_RECEIVED, TYPE_OF_NOTICE_OF_ASSESSMENT, DT_ISSUE_DROPDOWN_PENALTY, ACTION_ASSESSMENT_ORDER, MODE_OF_TIME_PH, MODE_OF_PH } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { InrService } from 'src/app/shared/services/inr.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { TaxServiceService } from '../service/tax-service.service';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { MasterService } from 'src/app/businessManagement/services/master.service';
export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  PAN = 'pan',
  CASE_LABEL = 'Case Label',
  AY = 'ay',
  PERSON_RESPONSIBLE = 'Person Responsible',
}

interface ConsultantList {
  srNo: number;
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.css']
})
export class AssessmentComponent implements OnInit {

  @Output() enablePrevStageTab: EventEmitter<string[] | null> = new EventEmitter<string[] | null>();
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToHC: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveCaseId = new EventEmitter<boolean>();
  parentCaseId: any;
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }

  @Input() activeStages: string[] | null = null;
  @ViewChild('noticeTab', { static: false }) noticeTab!: ElementRef;
  @ViewChild('itrTab') itrTab!: ElementRef;
  @ViewChild('nextBtn') nextBtn!: ElementRef;
  @ViewChild('update') update!: ElementRef;
  @ViewChild('saveTab', { static: false }) saveTab!: ElementRef;
  @ViewChild('submision') submision!: ElementRef;
  @ViewChild('WritTab') WritTab!: ElementRef;
  @ViewChild('hearingTab', { static: false }) hearingTab!: ElementRef;
  @ViewChild('consultantTab', { static: false }) consultantTab!: ElementRef;
  @ViewChild('orderReceivedTab', { static: false }) orderReceivedTab!: ElementRef;
  @ViewChild('orderTab', { static: false }) orderTab!: ElementRef;
  @ViewChild('summonTab', { static: false }) summonTab!: ElementRef;
  @ViewChild('conclusionTab', { static: false }) conclusionTab!: ElementRef;
  @ViewChild('taxTab', { static: false }) taxTab!: ElementRef;
  @ViewChild('cumulativeTab', { static: false }) cumulativeTab!: ElementRef;

  generalInfo!: UntypedFormGroup;
  noticeForm!: UntypedFormGroup;
  iTRDetailsForm!: UntypedFormGroup;
  writToHcForm!: UntypedFormGroup;
  hearingForm!: UntypedFormGroup;
  summonForm!: UntypedFormGroup;
  upperBenchForm!: UntypedFormGroup;
  consultantForm!: UntypedFormGroup;
  conclusionForm!: UntypedFormGroup;
  orderForm!: UntypedFormGroup;
  orderReceivedForm!: UntypedFormGroup;
  taxPaymentRefundDetailsForm!: UntypedFormGroup
  assessmentRequest: CreateAssessmentRequest = new CreateAssessmentRequest();

  file!: ElementRef;
  fileUrl: any;
  maxDate = new Date();
  finyearData: any[] = [];
  savedCaseId: any;
  savedPreCaseId: any
  caseType: any;
  caseId: any;
  action: any;
  selectedPhaseTab: any;
  caseData: any = {};
  selectedField: any;
  selectedFile: any;
  userRoleLs: any;
  consultantList: ConsultantList[]=[];
  isToggleConsultantDropdown = true;
  isToggleConsultantDropdown2 = true;
  isToggleConsultantDropdownCC = true;
  currentYear: any;
  companyId: any;
  matches: any;
  selectedCompanyId: any;
  selectedPanName: any;
  selectedPan: any;
  navContext: any
  activeTab: any;
  duplicateData:any;
  getAccessTabData: any;
  selectedNotice: any;
  selectedReplyNotice: any;
  selectedPhase: any;
  selectedSummon: any;
  selectedRectif: any
  dataSource: any;
  dataSourceIssue: any;
  prevStage: any;
  issueRefData: any;

  currentTab = 'dtassessment';

  formData: FormData = new FormData();
  phaseDisable: boolean = false;
  isSelectDisabled: boolean = true;
  isDisabledSelect: boolean = false;
  isSubmittedNotice: boolean = false;
  isNextBtnClicked: boolean = false;
  isSubmittedSubmission: boolean = false;
  isSubmittedScn: boolean = false;
  isSubmittedPhRecord: boolean = false;
  isSubmittedOrder: boolean = false;
  isSubmitted: boolean = false;
  isSubmittedAppeal: boolean = false;
  isSubmittedRectif: boolean = false;
  showTable: boolean = false;
  hideIssueTable: boolean = false;
  isPhase2: boolean = false;
  isPhase3: boolean = false;
  isSubmittedItr: boolean = false;
  updateBtn: boolean = false;
  isVisibleCaseId: boolean = false;
  viewIons = false;
  hideData:boolean = false;
  tab2Disable: boolean = false;
  tab3Disable: boolean = false;
  tab4Disable: boolean = false;
  tab5Disable: boolean = false;
  tab6Disable: boolean = false;
  tab7Disable: boolean = false;
  tab8Disable: boolean = false;
  tab9Disable: boolean = false;
  tab10Disable: boolean = false;
  tab11Disable: boolean = false;
  tab12Disable: boolean = false;
  saveApi: boolean = false;
  isCaseID: boolean = false;
  cumulativeData: any;
  roleName: any;



  sidemenu = false
  disablePhase3 = false;
  disablePhase2 = false;

  detailsOfSubmissionsExpansion = false;
  detailsOfIssuingAuthority = false;


  rmvLetterModalRef!: NgbModalRef;
  rmvajaSubDetailsModalRef!: NgbModalRef;
  rmvRectifDetailsModalRef!: NgbModalRef;
  rmvIssuingAuthorityModalRef!: NgbModalRef;
  rmvreqDetailsModalRef!: NgbModalRef;
  noticeLocModalRef!: NgbModalRef;
  rmvDetailModalRef!: NgbModalRef;
  rmvIssueModalRef!: NgbModalRef;
  rmvItrDetailsModalRef!: NgbModalRef;
  rmvSubmissionsModalRef!: NgbModalRef;
  rmvsubDetailsModalRef!: NgbModalRef;
  rmvdepartmentModalRef!: NgbModalRef;
  rmvConclusionRef!: NgbModalRef;
  rmvConsultantModalRef!: NgbModalRef;
  rmvorderLocModalRef!: NgbModalRef;
  rmvAppealDetailsRef!: NgbModalRef;
  rmvLibDetailsModalRef!: NgbModalRef;
  rmvChallanFormLocModalRef!: NgbModalRef;
  rmvRefundDetailsModalRef!: NgbModalRef;
  issueReferenceRef!: NgbModalRef;


  liabilityAddmittedAndPaidInterestTotal = 0;
  liabilityAddmittedAndPaidTaxTotal = 0;
  liabilityAddmittedAndPaidTotal = 0;

  refundPaidInterestTotal = 0;
  refundPaidTaxTotal = 0;
  refundPaidTotal = 0;
  caseTypeData = TYPE_OF_CASE;

  typeOfOrder = TYPE_OF_NOTICE;
  orderData = TYPE_OF_ODRDER;
  itrDetailsOptions = ADJ_DEPTPRE;
  orderReceiptMode = MODE_OF_RECEIPT;
  riskParam = ISSUE_RISK_PARAM;
  issueConclusion = ISSUE_CONCLUSION_DT;
  typeOfMatter = TYPE_OF_MATTER;
  OrReduced1 = REDUCED1;
  returnFiledUs = RETURN_FILED;
  itrFormList = ITR_Form_Type;
  quarterList = QUARTER;
  modeOfPH = MODE_OF_PH;
  modeOfTime =MODE_OF_TIME_PH;
  modeOfReceiptNotice = MODE_OF_RECEIPT;
  preLitPH1Preceded = ISSUING_AUTHORITY;
  typeOfNotice = TYPE_OF_NOTICE_OF_ASSESSMENT;
  modeOfAppealOptions = MODE_OF_RECEIPT;
  ajaSubDetailsOptions = ADJ_DEPTPRE;
  grantedOptions = GRANTED;
  yesNoList = YES_NO;
  yesNoDropdown = YES_NO;
  TypeOfRespo = TYPE_OF_RESPONSE;
  adjAttend = ADJ_ATTEND;
  noticeIssuedInLim = YES_NO;
  whetherSCNIssued = YES_NO;
  correspondence = YES_NO;
  noticeIssued = NOTICE_ISSUED_US;
  whetherRectification = ADJ_PH1_ADJUNOT;
  typeOfResponse = TYPE_OF_RESPONSE;
  writToHcOption = WRIT_TO_HC;
  OrderReceived = ORDER_RECEIVED;
  adjPHAdjuorNotList = ADJ_PH1_ADJUNOT;
  rectifModeOptions = MODE_OF_RECEIPT;
  orderPassedBy = ORDER_PASSED_BY;
  outcomeData = ASSESSMENT_OUTCOME;
  forumAppeal = FORUM_ENQUIRY_PROCEED;
  actionData = ACTION_ASSESSMENT_ORDER;
  modeOfPayemt = MODE_OF_PAYMENT;
  sorting: {
    caseId: boolean;
    panName: boolean;
    caseLabel: boolean;
    ay: boolean;
    personResponsible: boolean;
  } = {
      caseId: false,
      panName: false,
      caseLabel: false,
      ay: false,
      personResponsible: false,
    };
    issueSorting = SORT_ISSUE;






  feesChargedTotal = 0;

  isUPadjDocsLoc: boolean = true;
  isDWadjDocsLoc: boolean = true;
  isadjDocsLocFile: boolean = false;
  isadjDocsLocText: boolean = true;

  isUPnoticeLoc: boolean = true;
  isDWnoticeLoc: boolean = true;
  isnoticeLocFile: boolean = false;
  isnoticeLocText: boolean = true;

  isUPSubCopyLoc: boolean = true;
  isDWSubCopyLoc: boolean = true;
  isSubCopyLocFile: boolean = false;
  isSubCopyLocText: boolean = true;

  isUPdocLoc: boolean = true;
  isDWdocLoc: boolean = true;
  isdocLocFile: boolean = false;
  isdocLocText: boolean = true;

  isUPCopyOfSubmissionLoc: boolean = true;
  isDWCopyOfSubmissionLoc: boolean = true;
  isDWCopyOfSubmissionLocFile: boolean = false;
  isDWCopyOfSubmissionLocText: boolean = true;

  isUPackCopyLoc: boolean = true;
  isDWackCopyLoc: boolean = true;
  isackCopyLocFile: boolean = false;
  isackCopyLocText: boolean = true;

  isUPPhNotic: boolean = true;
  isDWPhNotic: boolean = true;
  isPhNoticFile: boolean = false;
  isPhNoticText: boolean = true;

  isUPPhRecord: boolean = true;
  isDWPhRecord: boolean = true;
  isPhRecordFile: boolean = false;
  isPhRecordText: boolean = true;

  isUPAddSubLoc: boolean = true;
  isDWAddSubLoc: boolean = true;
  isAddSubLocFile: boolean = false;
  isAddSubLocText: boolean = true;

  isUPSummonNotic: boolean = true;
  isDWSummonNotic: boolean = true;
  isSummonNoticFile: boolean = false;
  isSummonNoticText: boolean = true;

  isUPSummonAddSubLoc: boolean = true;
  isSummonDWAddSubLoc: boolean = true;
  isSummonAddSubLocFile: boolean = false;
  isSummonAddSubLocText: boolean = true;

  isUPSummonRecord: boolean = true;
  isDWSummonRecord: boolean = true;
  isSummonRecordFile: boolean = false;
  isSummonRecordText: boolean = true;

  isOrderLocDWText: boolean = true;
  isOrderLocUPText: boolean = true;
  isOrderLocFile: boolean = false;
  isOrderLocText: boolean = true;

  isSupportingDocDWText: boolean = true;
  isSupportingDocUPText: boolean = true;
  isSupportingDocFile: boolean = false;
  isSupportingDocText: boolean = true;
  isToggleDropdown=true;


  constructor(
    private router: Router,
    private fBuild: UntypedFormBuilder,
    private activeSnapShot: ActivatedRoute,
    private taxService: TaxServiceService,
    private toaster: ToasterService,
    private commonServ: CommonService,
    private getterSetter: GetterSetterService,
    private inrService: InrService,
    private excelService: ExcelService,
    private http: HttpClient,
    private modalService: NgbModal,
    private masterService : MasterService
  ) {
    this.action = this.activeSnapShot.snapshot.params.action;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.caseId = this.activeSnapShot.snapshot.params.caseId;
    this.activeSnapShot.snapshot.params.new == "true" ? this.caseType = 'NEW' : null;
    this.activeSnapShot.snapshot.params.penalty == "true" ? this.caseType = 'PENALTY' : null;
  }

  ngOnInit() {
    this.navContext = this.commonServ.getNavContext();
    this.selectedPan = this.navContext.pan;
    if (this.navContext.entityType === 'Business') {
      this.selectedPanName = sessionStorage.getItem('company');
    } else {
      this.selectedPanName = this.navContext.entityName;
    }
    this.formInit();
    this.setDate();
    this.getYears();
    this.personResponsibleData();
    this.consultantData();
    if (this.caseId || this.parentCaseId) {
      this.isCaseID = true;
      this.initializeData();
    }
    window.scrollTo(0, 0);
    this.issueDropdown();

    if (this.action === 'create') {
      this.addNotice();
      this.addAppealDetail();
      this.createAdjDiv();
      this.addSummon();
      this.addOrderDetails();
      this.viewIons=false;


      if (this.caseType != 'PENALTY') {
        this.addItrDetails();
        this.viewIons=false;

      }
    } else if (this.action === 'update') {
      this.updateBtn = true;
      this.isDisabledSelect = true;
      this.viewIons=true;


    } else if (this.action === 'view') {
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.viewIons=false;
    }
    this.selectedNotice = 'phase0';
    this.selectedReplyNotice = 'phase0';
    this.selectedPhase = 'phase0';
    this.selectedSummon = 'phase0';
    this.selectedRectif = 'phase0';
    this.roleName = localStorage.getItem('role');
    const userId: any = sessionStorage.getItem('user');
    this.generalInfo.controls.personResp.patchValue(userId);

    
  }

  formInit() {
    this.generalInfo = this.fBuild.group({
      updatedBy: [this.caseData?.updatedBy],
      updatedOn: [this.caseData?.updatedOn],
      caseId: [this.caseData?.caseId],
      pan: [this.caseData?.pan ? this.caseData?.pan : this.selectedPan],
      panName: [this.caseData?.panName ? this.caseData?.panName : this.selectedPanName],
      ay: [this.caseData?.ay, [Validators.required]],
      finYear: [this.caseData?.finYear, [Validators.required]],
      consultant: [this.caseData?.consultant, Validators.maxLength(100)],
      personResp: [this.caseData?.personResp, [Validators.required]],
      caseLabel: [this.caseData?.caseLabel, [Validators.required, Validators.minLength(5), Validators.maxLength(125)]],
      caseSummary: [this.caseData?.caseSummary, Validators.maxLength(1000)],
      internalRemarks1: [this.caseData?.internalRemarks1, Validators.maxLength(1000)],
      status: [this.caseData?.status],
    });
    this.noticeForm = this.fBuild.group({
      dtNotices: new UntypedFormArray([]),
    })
    if (this.caseType !== 'PENALTY') {
      this.iTRDetailsForm = this.fBuild.group({
        itrDetails: new UntypedFormArray([]),
      })
    }

    this.writToHcForm = this.fBuild.group({
      writToHc: [this.caseData?.writToHc ? this.caseData?.writToHc : false],
    })
    this.hearingForm = this.fBuild.group({
      hearingDetails: new UntypedFormArray([]),
    })
    this.summonForm = this.fBuild.group({
      summonDetails: new UntypedFormArray([]),
    })
    this.consultantForm = this.fBuild.group({
      cc: new UntypedFormArray([]),
    })
    // phase 3
    this.orderForm = this.fBuild.group({
      orderDetails: new UntypedFormArray([]),
      whetherScnIssued: [this.caseData?.whetherScnIssued],
      scnDate: [this.caseData?.scnDate],
      scnDin: [this.caseData?.scnDin],
      scnReceivedDate: [this.caseData?.scnReceivedDate],
      scnReplyDueDate: [this.caseData?.scnReplyDueDate],
      isDemandRaisedOrReduced3: [this.caseData?.isDemandRaisedOrReduced3],
      demandAmt3: [this.caseData?.demandAmt3],
      isRightsTransToAO: [this.caseData?.isRightsTransToAO],
      taxImpactLTotal: [this.caseData?.taxImpactLTotal],
      interestLTotal: [this.caseData?.interestLTotal],
      penaltyLTotal: [this.caseData?.penaltyLTotal],
      taxRateLTotal: [this.caseData?.taxRateLTotal],
      addnOrDisOfExpensesLTotal: [this.caseData?.addnOrDisOfExpensesLTotal],
      totalLTotal: [this.caseData?.totalLTotal],
      appealDetails: new UntypedFormArray([]),
    })

    this.orderReceivedForm = this.fBuild.group({
      whetherOrderReceived: [this.caseData?.whetherOrderReceived ? this.caseData?.whetherOrderReceived : false],
    })

    this.taxPaymentRefundDetailsForm = this.fBuild.group({
      libDetails: new UntypedFormArray([]),
      refundDetails: new UntypedFormArray([]),
    })

  }
  rectificationValidation() {
    var orderDetailsFormArray = this.orderForm.controls.orderDetails as UntypedFormArray
    if (orderDetailsFormArray.controls.length > 0) {
      for (let index = 0; index < orderDetailsFormArray.controls.length; index++) {
        let orderDetailsControl = orderDetailsFormArray.controls[index] as UntypedFormGroup;
        if (orderDetailsControl.controls.isAppliedForRectif.value === true || orderDetailsControl.controls.isAppliedForRectif.value === 'true') {
          var rectifDetailsFrmArray = orderDetailsFormArray.controls[index].get('rectifDetails') as UntypedFormArray
          for (var rectifDetailsIndex = 0; rectifDetailsIndex < rectifDetailsFrmArray.controls.length; rectifDetailsIndex++) {
            var rectifDetailsControl = rectifDetailsFrmArray.controls[rectifDetailsIndex] as UntypedFormGroup
            if (rectifDetailsControl.controls.rectifDate.value == null || rectifDetailsControl.controls.rectifDate.value === null || rectifDetailsControl.controls.rectifDate.value === undefined) {
              rectifDetailsControl.controls.rectifDate.setErrors({ 'required': true });
            } else {
              rectifDetailsControl.controls.rectifDate.setErrors(null);
            }
          }
        }
      }
    }
  }

  p3Validation() {
    if (this.orderForm.controls.whetherScnIssued.value === 'true' || this.orderForm.controls.whetherScnIssued.value === true) {
      if (this.orderForm.controls.scnDate.value === null || this.orderForm.controls.scnDate.value === '') {
        this.orderForm.controls.scnDate.setErrors({ 'required': true });
      } else {
        this.orderForm.controls.scnDate.setErrors(null);
      }
      if (this.orderForm.controls.scnDin.value === null || this.orderForm.controls.scnDin.value === '') {
        this.orderForm.controls.scnDin.setErrors({ 'required': true });
      } else {
        this.orderForm.controls.scnDate.setErrors(null);
      }
      if (this.orderForm.controls.scnReceivedDate.value === null || this.orderForm.controls.scnReceivedDate.value === '') {
        this.orderForm.controls.scnReceivedDate.setErrors({ 'required': true });
      } else {
        this.orderForm.controls.scnReceivedDate.setErrors(null);
      }
      if (this.orderForm.controls.scnReplyDueDate.value === null || this.orderForm.controls.scnReplyDueDate.value === '') {
        this.orderForm.controls.scnReplyDueDate.setErrors({ 'required': true });
      } else {
        this.orderForm.controls.scnReplyDueDate.setErrors(null);
      }
    }

    var dtNoticesArray = this.noticeForm.controls.dtNotices as UntypedFormArray
    if (dtNoticesArray.controls.length > 0) {
      for (var i = 0; i < dtNoticesArray.controls.length; i++) {
        var issuesArray = dtNoticesArray.controls[i].get('issues') as UntypedFormArray
        for (var issue1Index = 0; issue1Index < issuesArray.controls.length; issue1Index++) {
          var issuesControl = issuesArray.controls[issue1Index] as UntypedFormGroup
          if (issuesControl.controls.status.value === null || issuesControl.controls.status.value === '') {
            issuesControl.controls.status.setErrors({ 'required': true });
          } else {
            issuesControl.controls.status.setErrors(null);
          }
        }
      }
    }
  }

  p1IssueValidation() {
    var dtNoticesArray = this.noticeForm.controls.dtNotices as UntypedFormArray
    if (dtNoticesArray.controls.length > 0) {
      for (var i = 0; i < dtNoticesArray.controls.length; i++) {
        var itemListControl = dtNoticesArray.controls[i] as UntypedFormGroup;
        if (itemListControl.controls.doesCorrespondenceInvolvesAmts.value === 'true' || itemListControl.controls.doesCorrespondenceInvolvesAmts.value === true) {
          var issuesArray = dtNoticesArray.controls[i].get('issues') as UntypedFormArray
          for (var issue1Index = 0; issue1Index < issuesArray.controls.length; issue1Index++) {
            var issuesControl = issuesArray.controls[issue1Index] as UntypedFormGroup
            if (issuesControl.controls.issue.value === null || issuesControl.controls.issue.value === '') {
              issuesControl.controls.issue.setErrors({ 'required': true });
            } else {
              issuesControl.controls.issue.setErrors(null);
            }
            if (issuesControl.controls.riskParam.value === null || issuesControl.controls.riskParam.value === '') {
              issuesControl.controls.riskParam.setErrors({ 'required': true });
            } else {
              issuesControl.controls.riskParam.setErrors(null);
            }
          }
        }

      }
    }
    if (dtNoticesArray.controls.length > 0) {
      for (let index = 0; index < dtNoticesArray.controls.length; index++) {
        let issuesControl = dtNoticesArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.isExtApplied.value === true || issuesControl.controls.isExtApplied.value === 'true') {
          if (issuesControl.controls.extDueDate.value == null || issuesControl.controls.extDueDate.value === null || issuesControl.controls.extDueDate.value === undefined) {
            issuesControl.controls.extDueDate.setErrors({ 'required': true });
          } else {
            issuesControl.controls.extDueDate.setErrors(null);
          }
        }
      }
    }
  }

  p2validation() {
    var itemListArray = this.noticeForm.controls.dtNotices as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var itemListIndex = 0; itemListIndex < itemListArray.controls.length; itemListIndex++) {
        var submissionsArray = itemListArray.controls[itemListIndex].get('submissions') as UntypedFormArray
        for (var submissionIndex = 0; submissionIndex < submissionsArray.controls.length; submissionIndex++) {
          var submissionContol = submissionsArray.controls[submissionIndex] as UntypedFormGroup
          if (submissionContol.controls.subDate.value == null || submissionContol.controls.subDate.value === "" || submissionContol.controls.subDate.value === undefined) {
            submissionContol.controls.subDate.setErrors({ 'required': true });
          } else {
            submissionContol.controls.subDate.setErrors(null);
          }
          if (submissionContol.controls.ackNo.value === null || submissionContol.controls.ackNo.value === "") {
            submissionContol.controls.ackNo.setErrors({ 'required': true });
          } else {
            submissionContol.controls.ackNo.setErrors(null);
          }
          if (submissionContol.controls.respType.value === null || submissionContol.controls.respType.value === "") {
            submissionContol.controls.respType.setErrors({ 'required': true });
          } else {
            submissionContol.controls.respType.setErrors(null);
          }
        }
      }
    }
  }
  consultantValidation() {
    let consultantArray = this.consultantForm.controls.cc as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(issuesControl.controls.fees.value)
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (issuesControl.controls.firm.value === null || issuesControl.controls.firm.value === '') {
            issuesControl.controls.firm.setErrors({ 'required': true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }

  phaseChanged(phase: any, i: any) {
    this.selectedNotice = phase;
  }
  phaseRNChanged(phase: any, i: any) {
    this.selectedReplyNotice = phase;
  }
  phaseChangedph(phase: any, i: any) {
    this.selectedPhase = phase;
  }
  phaseChangedSummon(phase: any, i: any) {
    this.selectedSummon = phase;
  }
  phaseRectification(phase: any, i: any) {
    this.selectedRectif = phase;
  }

  tabChanged(phasetab: any, check?: any) {
    this.selectedPhaseTab = phasetab;
    if (phasetab === 'tab1') {
      this.initializeData();
    } else if (phasetab === 'tab2') {
      this.initializeData();
    } else if (phasetab === 'tab3') {
      this.initializeData();
    }
  }

  issueDropdown() {
    this.dataSourceIssue = DT_ISSUE_DROPDOWN_PENALTY;

    this.dataSource = DT_ISSUE_DROPDOWN;
  }

  personResponsibleData() {
    let model = {
      companyid: this.selectedCompanyId
    }
    this.taxService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        console.log('list:', this.consultantList);
        const userMailID: any = sessionStorage.getItem('UserId');

        // Check if userMailID exists in consultantList
        const matchingConsultant = this.consultantList.find(
          (consultant: any) => consultant.email === userMailID
        );
        if (matchingConsultant) {
          this.generalInfo.controls.consultant.patchValue(matchingConsultant.consultantName);
          // this.hearingForm.controls.consultant.patchValue(matchingConsultant.consultantName)
          const hearingDetailsArray = this.phDivArr();
          hearingDetailsArray.forEach((hearingGroup: UntypedFormGroup) => {
          if (hearingGroup && hearingGroup.controls['consultant']) {
          // Patch the consultant value
            hearingGroup.controls['consultant'].patchValue(matchingConsultant.consultantName);
            console.log(`Patched Consultant: ${hearingGroup.controls['consultant'].value}`);
        }
      });
        }
      }  
    });
  }  
  
  onClickToggleConsultantDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleConsultantDropdown = !this.isToggleConsultantDropdown;
    } else if (phase === 'p2') {
      this.isToggleConsultantDropdown2 = !this.isToggleConsultantDropdown2;
    } else if (phase === 'CC'){
      this.isToggleConsultantDropdownCC = !this.isToggleConsultantDropdownCC;
    }
  }

  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2001;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push(financialYrRange);
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
    this.finyearData = this.finyearData.map(yearRange => {
      const [start, end] = yearRange.split('-').map(Number);
      return `${start + 1}-${end + 1}`;
    });
  }

  
  


  getYearData() {
    var assYear = this.generalInfo.controls.ay.value;
    const dashIndex = assYear.indexOf('-');
    if (dashIndex !== -1) {
      var years = assYear.substring(0, dashIndex);
    }
    var convertToInt = parseInt(years);
    var year = convertToInt - 1;
    var financialYear = (year) + '-' + years
    this.generalInfo.controls.finYear.patchValue(financialYear);
  }

  getperiodToValue(content: any){
    this.getYearData();
    if(this.action === "create"){
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = localStorage.getItem('selectedId'),
    model.module = "DT",
    model.pan = this.selectedPan,
    model.criterias= [
      {
          "p": "ay",
          "o": "eq",
          "v": this.generalInfo.value.ay,
      }
  ]
  this.taxService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.response.length === 0) {
        this.modalService.dismissAll();
      }
      else{
        this.duplicateData = response?.response;
       this.modalService.open(content)
      }
      
    });
  }
  }
  
  goToSummary(){
    this.router.navigate(['/directTaxSummary']);
    this.modalService.dismissAll();
  }

  onFileSelect(event: any, identifier?: any) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        console.log(this.selectedFile, "this.selectedFile")
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, identifier);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }

  onUpload(selectedfield: any, identifier?: string) {
    let urlData = {
      gstinOrPan: this.navContext.pan,
      type: 'NEW',
      field: selectedfield,
      contentType: this.selectedFile.type
    }
    this.taxService.uploadFile(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFileUrl(response.response.url, response.response.path, selectedfield, identifier);
      } else {
      }
    })
  }

  getFileUrl(url: any, path: any, selectedfield: any, identifier?: string) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      var toupdate = this.noticeForm.value;
      var toUpdatehearing = this.hearingForm.value;
      var updateSummon = this.summonForm.value;
      var updateRectification = this.orderForm.value;
      var updateConsultant = this.consultantForm.value
      var toTaxUpdate = this.taxPaymentRefundDetailsForm.value;

      if (identifier == "notice") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.noticeForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toupdate = toupdate[pathToVeriabl[pathIndex]];
            } else {
              toupdate[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.noticeForm.patchValue(this.noticeForm.value);
      } else if (identifier == "hearing") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.hearingForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toUpdatehearing = toUpdatehearing[pathToVeriabl[pathIndex]];
            } else {
              toUpdatehearing[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.hearingForm.patchValue(this.hearingForm.value);

      } else if (identifier == "summon") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.summonForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              updateSummon = updateSummon[pathToVeriabl[pathIndex]];
            } else {
              updateSummon[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.summonForm.patchValue(this.summonForm.value);

      } else if (identifier == "consultant") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.consultantForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              updateConsultant = updateConsultant[pathToVeriabl[pathIndex]];
            } else {
              updateConsultant[pathToVeriabl[pathIndex]] = path;
            }
          }
        }

        this.consultantForm.patchValue(this.consultantForm.value);

      } else if (identifier == "rectif") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.orderForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              updateRectification = updateRectification[pathToVeriabl[pathIndex]];
            } else {
              updateRectification[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.orderForm.patchValue(this.orderForm.value);
      } else if (identifier == "tax") {
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (this.taxPaymentRefundDetailsForm.value) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toTaxUpdate = toTaxUpdate[pathToVeriabl[pathIndex]];
            } else {
              toTaxUpdate[pathToVeriabl[pathIndex]] = path;
            }
          }
        }
        this.taxPaymentRefundDetailsForm.patchValue(this.taxPaymentRefundDetailsForm.value);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }


  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  /*upload function */

  /* get download file path function */
  getFileNameDta(fileUrl: any) {
    if (fileUrl)
      return this.taxService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.taxService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        //this.downloadfileUrl() ;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }
  /* get download file path function */
  /*dtNotices Array*/
  getFormControlNotice() {
    return ((this.noticeForm.get('dtNotices') as UntypedFormArray).controls as UntypedFormGroup[])
  }
  getAllIssues() {
    const notices = this.getFormControlNotice();
    let allNoticeIssues: any[] = [];
    notices.forEach((noticeData) => {
      const temp = this.getIssues(noticeData);
      allNoticeIssues.push(...temp)
    })
    return allNoticeIssues;
  }

  get notice() {
    return this.noticeForm.get('dtNotices') as UntypedFormArray;
  }

  createNotice(letter: any = null) {
    return this.fBuild.group({
      issuingAuth: new UntypedFormControl(letter ? letter.issuingAuth : null, [Validators.required]),
      noticeRefNo: new UntypedFormControl(letter ? letter.noticeRefNo : null, [Validators.required]),
      noticeIssuedUs: new UntypedFormControl(letter ? letter.noticeIssuedUs : null, [Validators.required]),
      noticeType: new UntypedFormControl(letter ? letter.noticeType : null, []),
      noticeDate: new UntypedFormControl(letter ? letter.noticeDate : null, [Validators.required]),
      noticeReceiptDate: new UntypedFormControl(letter ? letter.noticeReceiptDate : null, [Validators.required]),
      noticeReceiptMode: new UntypedFormControl(letter ? letter.noticeReceiptMode : null, []),
      legalDueDate: new UntypedFormControl(letter ? letter.legalDueDate : null, [Validators.required]),
      internalDueDate: new UntypedFormControl(letter ? letter.internalDueDate : null, []),
      isExtApplied: new UntypedFormControl(letter ? letter.isExtApplied : null, []),
      extDueDate: new UntypedFormControl(letter ? letter.extDueDate : null),
      issues: new UntypedFormArray([]),
      noticeSummary: new UntypedFormControl(letter ? letter.noticeSummary : null, [Validators.maxLength(1000)]),
      internalRemarks: new UntypedFormControl(letter ? letter.internalRemarks : null, [Validators.maxLength(1000)]),
      doesCorrespondenceInvolvesAmts: new UntypedFormControl(letter ? letter.doesCorrespondenceInvolvesAmts : null, [Validators.required]),
      totOfAddnOrDisOfExpensesF: new UntypedFormControl(letter ? letter.totOfAddnOrDisOfExpensesF : null),
      taxRateFTotal: new UntypedFormControl(letter ? letter.taxRateFTotal : null),
      taxImpactFTotal: new UntypedFormControl(letter ? letter.taxImpactFTotal : null),
      interestFTotal: new UntypedFormControl(letter ? letter.interestFTotal : null),
      penaltyFTotal: new UntypedFormControl(letter ? letter.penaltyFTotal : null),
      totOfTotal: new UntypedFormControl(letter ? letter.totOfTotal : null),
      reqDetails: new UntypedFormArray([]),
      adjSubDetails: new UntypedFormArray([]),
      noticeLoc: new UntypedFormArray([]),
      issuingAuthority: new UntypedFormArray([]),
      //Phase 2
      submissions: new UntypedFormArray([]),
      meetingDetails: new UntypedFormArray([]),
    })
  }
  addNotice(i?: any) {
    let dtNoticesFrmGrp = this.createNotice();
    this.selectedNotice = 'phase' + (i + 1);
    window.scrollTo(0, 0);
    (dtNoticesFrmGrp.get('adjSubDetails') as UntypedFormArray).push(this.initAjaSubDetailsFrmGrp());
    (dtNoticesFrmGrp.get('reqDetails') as UntypedFormArray).push(this.initreqDetailsFrmGrp());
    (dtNoticesFrmGrp.get('noticeLoc') as UntypedFormArray).push(this.createnoticeLoc());
    (dtNoticesFrmGrp.get('issuingAuthority') as UntypedFormArray).push(this.initIssuingAuthorityFrmGrp());
    (dtNoticesFrmGrp.get('issues') as UntypedFormArray).push(this.createIssueInvArray());
    (dtNoticesFrmGrp.get('meetingDetails') as UntypedFormArray).push(this.detailsOfDepartmentFrmGrp());
    (dtNoticesFrmGrp.get('submissions') as UntypedFormArray).push(this.initSubmissionFrmGrp());
    const submissionsIndex = (dtNoticesFrmGrp.get('submissions') as UntypedFormArray).length - 1;
    ((dtNoticesFrmGrp.get('submissions') as UntypedFormArray).controls[submissionsIndex].get('subDetails') as UntypedFormArray).push(this.initsubDetailsFrmGrp());
    (this.noticeForm.get('dtNotices') as UntypedFormArray).push(dtNoticesFrmGrp);
  }

  removeLetter(i: any) {
    (this.noticeForm.get('dtNotices') as UntypedFormArray).removeAt(i);
    this.selectedNotice = 'phase' + (i - 1);
  }
  /*dtNotices array */

  /*adjSubDetails array */
  getAjaSubDetails(noticediv: any): UntypedFormGroup[] {
    return (noticediv.get('adjSubDetails') as UntypedFormArray).controls as UntypedFormGroup[];;
  }

  get adjSubDetails() {
    return this.noticeForm.get('adjSubDetails') as UntypedFormArray;
  }

  initAjaSubDetailsFrmGrp(adjSub: any = null): UntypedFormGroup {
    return this.fBuild.group({
      adjFiledDate: new UntypedFormControl(adjSub ? adjSub.adjFiledDate : null, []),
      newAdjDate: new UntypedFormControl(adjSub ? adjSub.newAdjDate : null, []),
      adjReason: new UntypedFormControl(adjSub ? adjSub.adjReason : null, []),
      isGranted: new UntypedFormControl(adjSub ? adjSub.isGranted : null, []),
      adjDocsLoc: new UntypedFormArray([]),
    })
  }

  addAjaSubDetails(j: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let adjSubDetailsFrmArray = dtNoticesFrmArray.controls[j].get('adjSubDetails') as UntypedFormArray;
    adjSubDetailsFrmArray.push(this.initAjaSubDetailsFrmGrp());
    (adjSubDetailsFrmArray.get('adjDocsLoc') as UntypedFormArray).push(this.createAdjDocDetails());
  }
  rmAdjSubDetail(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let adjSubDetailsFrmArray = dtNoticesFrmArray.controls[i].get('adjSubDetails') as UntypedFormArray;
    adjSubDetailsFrmArray.removeAt(k);
    this.rmvajaSubDetailsModalRef.close();
  }
  openRemoveAjaSubDetailsPop(content: any, i: any) {
    this.rmvajaSubDetailsModalRef = this.modalService.open(content);
  }
  openDetailPop(content: any, i: any) {
    this.addAdjDetailsDiv(i, 0);
    this.modalService.open(content);
  }

  /*adjSubDetails array end*/

  /*adjDocsLoc Array */
  getadjDocLoc(adjDocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (adjDocGrp.get('adjDocsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getadjDocLocLength(noticeFormIndex: number, adjSubDetailsFormIndex: number): number {
    const noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    const adjSubDetailsArray = noticesListFrmArray.at(noticeFormIndex).get('adjSubDetails') as UntypedFormArray;
    const adjDocsLocListArray = adjSubDetailsArray.at(adjSubDetailsFormIndex).get('adjDocsLoc') as UntypedFormArray;
    if (adjDocsLocListArray && adjDocsLocListArray.length > -1) {
      let adjcount = 0;
      for (let i = 0; i < adjDocsLocListArray.length; i++) {
        const singleAdjDocsLoc = adjDocsLocListArray.value[i];
        if (singleAdjDocsLoc.fileLoc != '' && singleAdjDocsLoc.fileLoc != null) {
          adjcount++;
        }
      }
      return adjcount;
    }
    return 0;
  }

  createAdjDocDetails(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }
  addAdjDetailsDiv(j: any, i: any) {
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];

      var adjSubDetailsArray: any = element.controls.adjSubDetails.controls;

      for (let i = 0; i < adjSubDetailsArray.length; i++) {
        const element1 = adjSubDetailsArray[i].controls.adjDocsLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            adjSubDetailsArray[i].controls.adjDocsLoc.push(this.createAdjDocDetails())
          }
        }


      }
    }
  }

  uploadAdjDetails(noticeListIndex: any, adjDocsLocIndex: any, adjSubDetailsIndex: any) {
    this.isadjDocsLocFile = true;
    this.isadjDocsLocText = false;
    this.isDWadjDocsLoc = false;
    this.isUPadjDocsLoc = false;

    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let adjSubDetailsArray = noticesListFrmArray.controls[noticeListIndex].get('adjSubDetails') as UntypedFormGroup;
    let adjDocsLocArray = adjSubDetailsArray.controls[adjSubDetailsIndex].get('adjDocsLoc') as UntypedFormArray
    if (adjDocsLocArray.controls.length > 0) {
      for (let index = 0; index < adjDocsLocArray.controls.length; index++) {
        if (index === adjDocsLocIndex) {
          let itemListuploadControl = adjDocsLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deletedocLocFile(noticeListIndex: any, adjDocsLocIndex: any, adjSubDetailsIndex: any) {
    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let adjSubDetailsArray = noticesListFrmArray.controls[noticeListIndex].get('adjSubDetails') as UntypedFormGroup;
    let adjDocsLocArray = adjSubDetailsArray.controls[adjSubDetailsIndex].get('adjDocsLoc') as UntypedFormArray
    if (adjDocsLocArray.controls.length > 0) {
      for (let index = 0; index < adjDocsLocArray.controls.length; index++) {
        if (index === adjDocsLocIndex) {
          let itemListuploadControl = adjDocsLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*adjDocsLoc Array end*/

  /*reqDetails array */
  getreqDetails(noticediv: any): UntypedFormGroup[] {
    return (noticediv.get('reqDetails') as UntypedFormArray).controls as UntypedFormGroup[];;
  }
  initreqDetailsFrmGrp(req: any = null): UntypedFormGroup {
    return this.fBuild.group({
      docReq: new UntypedFormControl(req ? req.docReq : null, [Validators.pattern(/(.*[a-zA-Z]){3}/), Validators.maxLength(1000)]),
      remarks: new UntypedFormControl(req ? req.remarks : null, []),
    })
  }
  addreqDetails(j: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let reqDetailsFrmArray = dtNoticesFrmArray.controls[j].get('reqDetails') as UntypedFormArray;
    reqDetailsFrmArray.push(this.initreqDetailsFrmGrp());
  }
  rmreqDetailsInv(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let reqDetailsFrmArray = dtNoticesFrmArray.controls[i].get('reqDetails') as UntypedFormArray;
    reqDetailsFrmArray.removeAt(k);
    this.rmvreqDetailsModalRef.close();
  }

  openRemovereqDetailsPop(content: any, i: any) {
    this.rmvreqDetailsModalRef = this.modalService.open(content);
  }
  /*reqDetails array */

  /*issuingAuthority array */
  getIssuingAuthority(issuingFrmGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (issuingFrmGrp.get('issuingAuthority') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initIssuingAuthorityFrmGrp(issuing: any = null): UntypedFormGroup {
    return this.fBuild.group({
      noticeNo: new UntypedFormControl(issuing ? issuing.noticeNo : null, []),
      officer: new UntypedFormControl(issuing ? issuing.officer : null, []),
      designation: new UntypedFormControl(issuing ? issuing.designation : null, []),
      jurisdiction: new UntypedFormControl(issuing ? issuing.jurisdiction : null, []),
      address: new UntypedFormControl(issuing ? issuing.address : null, []),
      email: new UntypedFormControl(issuing ? issuing.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]),
      contact: new UntypedFormControl(issuing ? issuing.contact : null, []),
    })
  }
  addIssuingAuthority(j: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let issuingAuthorityFrmArray = dtNoticesFrmArray.controls[j].get('issuingAuthority') as UntypedFormArray;
    issuingAuthorityFrmArray.push(this.initIssuingAuthorityFrmGrp());
  }
  rmIssuingAuthorityInv(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let issuingAuthorityFrmArray = dtNoticesFrmArray.controls[i].get('issuingAuthority') as UntypedFormArray;
    issuingAuthorityFrmArray.removeAt(k);
    this.rmvIssuingAuthorityModalRef.close();
  }
  openIssuingAuthorityPop(content: any, i: any) {
    this.rmvIssuingAuthorityModalRef = this.modalService.open(content);
  }
  /*IssuingAuthority array */

  /*noticeLoc Array */
  getnoticeLoc(noticeLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (noticeLocGrp.get('noticeLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getnoticeLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j]?.get('noticeLoc') as UntypedFormArray;
    let noticecount = noticeLocFrmArray.value.filter((x: any) => x.fileLoc != "" && x.fileLoc != null);
    return noticecount.length;
  }
  createnoticeLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addnoticeLoc(j: any, i: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j].get('noticeLoc') as UntypedFormArray;
    if (noticeLocFrmArray.length > 0) {
      let length = noticeLocFrmArray.length
      while (i < 5 - length) {
        noticeLocFrmArray.push(this.createnoticeLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        noticeLocFrmArray.push(this.createnoticeLoc());
        i++;
      }
    }
  }
  rmNoticeLoc(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[i].get('noticeLoc') as UntypedFormArray;
    noticeLocFrmArray.removeAt(k);
    this.noticeLocModalRef.close();
  }
  openNoticeLocPop(content: any, i: any) {
    this.addnoticeLoc(i, 0);
    this.modalService.open(content);
  }

  uploadNticeLocCopy(noticeListIndex: any, noticeLocIndex: any) {
    this.isnoticeLocFile = true;
    this.isnoticeLocText = false;
    this.isDWnoticeLoc = false;
    this.isUPnoticeLoc = false;
    let itemListsArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let noticeLocFrmArray = itemListsArray.controls[noticeListIndex].get('noticeLoc') as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletenNticeLocCopyFile(noticeListIndex: any, noticeLocIndex: any) {
    let noticeArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let noticeLocFrmArray = noticeArray.controls[noticeListIndex].get('noticeLoc') as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*noticeLoc Array end*/

  /*Issue Array  */
  getIssues(issuesGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (issuesGrp.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  createIssueInvArray(issue: any = null): UntypedFormGroup {
    return this.fBuild.group({
      issue: [issue ? issue.issue : null, []],
      keyword: [issue ? issue.keyword : null, [Validators.maxLength(150)]],
      riskParam: [issue ? issue.riskParam : null, []],
      status: [issue ? issue.status : null, []],
      addnOrDisOfExpensesF: [issue ? issue.addnOrDisOfExpensesF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      taxRateF: [issue ? issue.taxRateF : null, [Validators.pattern(/^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$/)]],
      taxImpactF: [issue ? issue.taxImpactF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interestF: [issue ? issue.interestF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penaltyF: [issue ? issue.penaltyF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      totalF: [issue ? issue.totalF : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      remarkF: [issue ? issue.remarkF : null, [Validators.maxLength(100)]],
      provAndLibDetails: new UntypedFormArray([]),
      // ph3
      addnOrDisOfExpensesL: [issue ? issue.addnOrDisOfExpensesL : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      taxRateL: [issue ? issue.taxRateL : null, [Validators.pattern(/^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$/)]],
      taxImpactL: [issue ? issue.taxImpactL : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      interestL: [issue ? issue.interestL : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      penaltyL: [issue ? issue.penaltyL : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      totalL: [issue ? issue.totalL : null, [Validators.pattern(/^-?[0-9,]+$/)]],
      remarkL: [issue ? issue.remarkL : null, [Validators.maxLength(100)]],
    })
  }
  addIssueInv(j: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let issueFrmGrp = dtNoticesFrmArray.controls[j].get('issues') as UntypedFormArray;
    issueFrmGrp.push(this.createIssueInvArray());
    const issueIndex = issueFrmGrp.length - 1;
  }

  rmIssueInv(i: number, k: number | null) {
    let itemListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let issueFrmArray = itemListFrmArray.controls[i].get('issues') as UntypedFormArray;
    if (k) {
      issueFrmArray.removeAt(k);
    } else {
      for (let k = 0; k < issueFrmArray.controls.length; i++) {
        issueFrmArray.removeAt(k);
      }
    }
    this.issueValueChange();
    this.rmvIssueModalRef?.close();
  }

  openRmvIssuePop(content: any, i: any, k: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }


  openRmvDetailPop(content: any, i: any,) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }
  /*Issue end  */

  /*itrDetails array */
  getItrDetails(): UntypedFormGroup[] {
    return (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createItrDetailsFrmGrp(details: any = null): UntypedFormGroup {
    return this.fBuild.group({
      returnFiledUs: new UntypedFormControl(details ? details.returnFiledUs : '', []),
      itrForm: new UntypedFormControl(details ? details.itrForm : null, []),
      ackNo: new UntypedFormControl(details ? details.ackNo : '', []),
      filingDate: new UntypedFormControl(details ? details.filingDate : '', []),
      totTaxIncome: new UntypedFormControl(details ? details.totTaxIncome : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      totTaxLiability: new UntypedFormControl(details ? details.totTaxLiability : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      totTaxPaid: new UntypedFormControl(details ? details.totTaxPaid : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      advanceTax: new UntypedFormControl(details ? details.advanceTax : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      tdsAmount: new UntypedFormControl(details ? details.tdsAmount : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      tcsAmount: new UntypedFormControl(details ? details.tcsAmount : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      selfAssessmentAmount: new UntypedFormControl(details ? details.selfAssessmentAmount : '', [Validators.pattern(/^-?[0-9,]+$/)]),
      refundDemand: new UntypedFormControl(details ? details.refundDemand : '', [Validators.pattern(/^-?[0-9,]+$/)]),
    })
  }
  addItrDetails() {
    let itrDetailsFrmGrp = this.createItrDetailsFrmGrp();
    (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).push(itrDetailsFrmGrp);
  }
  rmItrDetailsInv(i: number) {
    (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).removeAt(i);
    this.rmvItrDetailsModalRef.close();

  }
  openItrDetailsPop(content: any, i: any) {
    this.rmvItrDetailsModalRef = this.modalService.open(content);
  }
  /*itrDetails end array */

  /*getSubmissions array */
  getSubmissions(noticeTabdiv: any): UntypedFormGroup[] {
    return (noticeTabdiv.get('submissions') as UntypedFormArray).controls as UntypedFormGroup[];;
  }

  initSubmissionFrmGrp(sub: any = null): UntypedFormGroup {
    const submissionFormControl = this.fBuild.group({
      subDate: new UntypedFormControl(sub ? sub.subDate : null, []),
      subMode: new UntypedFormControl(sub ? sub.subMode : null, []),
      respType: new UntypedFormControl(sub ? sub.respType : "FULL", []),
      ackNo: new UntypedFormControl(sub ? sub.ackNo : null, []),
      personWhoSubmitted: new UntypedFormControl(sub ? sub.personWhoSubmitted : null, []),
      ackCopyLoc: new UntypedFormArray([]),
      subCopyLoc: new UntypedFormArray([]),
      subDetails: new UntypedFormArray([]),
      subSummary: new UntypedFormControl(sub ? sub.subSummary : null, [Validators.maxLength(1000)]),
      internalRemarks: new UntypedFormControl(sub ? sub.internalRemarks : null, [Validators.maxLength(1000)]),
    });
    // if (this.action === 'create') {
    //   (submissionFormControl.get('subDetails') as FormArray).push(this.initsubDetailsFrmGrp());
    // }
    return submissionFormControl;
  }
  addSubmissions(j?: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let submissionsFrmArray = dtNoticesFrmArray.controls[j].get('submissions') as UntypedFormArray;
    submissionsFrmArray.push(this.initSubmissionFrmGrp());
    const submissionsIndex = submissionsFrmArray.length - 1;
    (submissionsFrmArray.controls[submissionsIndex].get('subDetails') as UntypedFormArray).push(this.initsubDetailsFrmGrp());
  }

  rmSubmissionDiv(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let submissionFrmArray = dtNoticesFrmArray.controls[i].get('submissions') as UntypedFormArray;
    submissionFrmArray.removeAt(k);
    this.rmvSubmissionsModalRef.close();
  }

  openRemoveSubmissionsPop(content: any, i: any, k: any) {
    this.rmvSubmissionsModalRef = this.modalService.open(content);
  }
  openSubDetailPop(content: any, i: any) {
    this.addAckCopyLoc(i, 0);
    this.addSubLoc(i, 0);
    this.modalService.open(content);
  }

  /*submissions array end*/
  /*ackCopyLoc Array */
  getAckCopyLoc(ackCopyLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (ackCopyLoc.get('ackCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAckLength(noticeFormIndex: number, submissionFormIndex: number): number {
    const noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    const submissionFrmArray = noticesListFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const ackCopyLocArray = submissionFrmArray.at(submissionFormIndex).get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocArray && ackCopyLocArray.length > -1) {
      let countOfackCopyLoc = 0;
      for (let i = 0; i < ackCopyLocArray.length; i++) {
        const singleAckLoc = ackCopyLocArray.value[i];
        if (singleAckLoc.fileLoc != '' && singleAckLoc.fileLoc != null) {
          countOfackCopyLoc++;
        }
      }
      return countOfackCopyLoc;
    }

    return 0;
  }

  createAckCopyLoc(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }

  addAckCopyLoc(j: any, i: any) {
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];

      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1 = submissionArray[i].controls.ackCopyLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            submissionArray[i].controls.ackCopyLoc.push(this.createAckCopyLoc())
          }
        }


      }
    }
  }

  uploadAckCopyLoc(noticeListIndex: any, adjDocsLocIndex: any, submissionsIndex: any) {
    this.isackCopyLocFile = true;
    this.isackCopyLocText = false;
    this.isDWackCopyLoc = false;
    this.isUPackCopyLoc = false;

    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let ackCopyArray = submissionsArray.controls[submissionsIndex].get('ackCopyLoc') as UntypedFormArray
    if (ackCopyArray.controls.length > 0) {
      for (let index = 0; index < ackCopyArray.controls.length; index++) {
        if (index === adjDocsLocIndex) {
          let itemListuploadControl = ackCopyArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteAckCopyLocFile(noticeListIndex: any, adjDocsLocIndex: any, submissionsIndex: any) {
    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let ackCopyArray = submissionsArray.controls[submissionsIndex].get('ackCopyLoc') as UntypedFormArray
    if (ackCopyArray.controls.length > 0) {
      for (let index = 0; index < ackCopyArray.controls.length; index++) {
        if (index === adjDocsLocIndex) {
          let itemListuploadControl = ackCopyArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*ackCopyLoc Array end*/

  /*subCopyLoc Array */
  getSubLoc(ackCopyLoc: UntypedFormGroup): UntypedFormGroup[] {
    return (ackCopyLoc.get('subCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getSubLength(noticeFormIndex: number, submissionFormIndex: number): number {
    const noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    const submissionFrmArray = noticesListFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const subCopyLocArray = submissionFrmArray.at(submissionFormIndex).get('subCopyLoc') as UntypedFormArray;
    if (subCopyLocArray && subCopyLocArray.length > -1) {
      let countOfsubCopyLoc = 0;
      for (let i = 0; i < subCopyLocArray.length; i++) {
        const singleSubLoc = subCopyLocArray.value[i];
        if (singleSubLoc.fileLoc != '' && singleSubLoc.fileLoc != null) {
          countOfsubCopyLoc++;
        }
      }
      return countOfsubCopyLoc;
    }

    return 0;
  }

  createSubLoc(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }
  addSubLoc(j: any, i: any) {
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];

      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1 = submissionArray[i].controls.subCopyLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            submissionArray[i].controls.subCopyLoc.push(this.createSubLoc())
          }
        }


      }
    }
  }
  uploadSubLoc(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any) {
    this.isSubCopyLocFile = true;
    this.isSubCopyLocText = false;
    this.isDWSubCopyLoc = false;
    this.isUPSubCopyLoc = false;

    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let subCopysArray = submissionsArray.controls[submissionsIndex].get('subCopyLoc') as UntypedFormArray
    if (subCopysArray.controls.length > 0) {
      for (let index = 0; index < subCopysArray.controls.length; index++) {
        if (index === subCopyIndex) {
          let itemListuploadControl = subCopysArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteSubLoc(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any) {
    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let subCopysArray = submissionsArray.controls[submissionsIndex].get('subCopyLoc') as UntypedFormArray
    if (subCopysArray.controls.length > 0) {
      for (let index = 0; index < subCopysArray.controls.length; index++) {
        if (index === subCopyIndex) {
          let itemListuploadControl = subCopysArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*SubLocLoc Array end*/


  /*subDetails array */
  getsubDetails(submissionFromGrp: any): UntypedFormGroup[] {
    return (submissionFromGrp.get('subDetails') as UntypedFormArray).controls as UntypedFormGroup[];;
  }

  initsubDetailsFrmGrp(adjSub: any = null): UntypedFormGroup {
    return this.fBuild.group({
      docReq: new UntypedFormControl(adjSub ? adjSub.docReq : null, [Validators.pattern(/(.*[a-zA-Z]){3}/)]),
      remarks: new UntypedFormControl(adjSub ? adjSub.remarks : null, []),
      docLoc: new UntypedFormArray([]),
    })
  }

  addSubDetails(i: any, j: any,) {
    let noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let submissionsArray = noticesListFrmArray.controls[i].get('submissions') as UntypedFormArray;
    let subDetailsFrmArray = submissionsArray.controls[j].get('subDetails') as UntypedFormArray;
    subDetailsFrmArray.push(this.initsubDetailsFrmGrp());
  }
  rmSubDetailstInv(i: number, k: number, j: number) {
    let noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let submissionsFrmArray = noticesListFrmArray.controls[i].get('submissions') as UntypedFormArray;
    let subDetailsFrmArray = submissionsFrmArray.controls[k].get('subDetails') as UntypedFormArray;
    subDetailsFrmArray.removeAt(j);
    this.rmvsubDetailsModalRef.close();
  }
  openRemoveSubDetailsPop(content: any, i: any) {
    this.rmvsubDetailsModalRef = this.modalService.open(content);
  }
  openSubDetailsPop(content: any, i: any) {
    this.addDocLo(i, 0);
    this.modalService.open(content);
  }
  /*subDetails array end  */



  /*docLoc Array */
  getDocLoc(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('docLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getDocLoength(noticeFormIndex: number, submissionFormIndex: number, subDetailsFormIndex: number): number {
    const noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    const submissionFrmArray = noticesListFrmArray.at(noticeFormIndex).get('submissions') as UntypedFormArray;
    const subDetailsArray = submissionFrmArray.at(submissionFormIndex).get('subDetails') as UntypedFormArray;
    const docLocArray = subDetailsArray.at(subDetailsFormIndex).get('docLoc') as UntypedFormArray;
    if (docLocArray && docLocArray.length > -1) {
      let countOfdocCopyLoc = 0;
      for (let i = 0; i < docLocArray.length; i++) {
        const singleDocLoc = docLocArray.value[i];
        if (singleDocLoc.fileLoc != '' && singleDocLoc.fileLoc != null) {
          countOfdocCopyLoc++;
        }
      }
      return countOfdocCopyLoc;
    }
    return 0;
  }

  createDocLo(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }
  addDocLo(j: any, i: any) {
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];

      var submissionArray: any = element.controls.submissions.controls;

      for (let i = 0; i < submissionArray.length; i++) {
        const element1Array = submissionArray[i].controls.subDetails;
        for (let j = 0; j < element1Array.length; j++) {
          const docEle = element1Array.controls[j].controls.docLoc;
          if (docEle.controls.length === 0) {
            for (let k = 0; k < 5; k++) {
              docEle.push(this.createDocLo());
            }
          }

        }

      }
    }

  }
  uploadDocLoc(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any, docLocIndex: any,) {
    this.isdocLocFile = true;
    this.isdocLocText = false;
    this.isDWdocLoc = false;
    this.isUPdocLoc = false;

    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let subCopysArray = submissionsArray.controls[submissionsIndex].get('subDetails') as UntypedFormGroup;
    let docLocArray = subCopysArray.controls[subCopyIndex].get('docLoc') as UntypedFormArray;

    if (docLocArray.controls.length > 0) {
      for (let index = 0; index < docLocArray.controls.length; index++) {
        if (index === docLocIndex) {
          let itemListuploadControl = docLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteDocLo(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any, docLocIndex: any) {
    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let submissionsArray = noticesListFrmArray.controls[noticeListIndex].get('submissions') as UntypedFormGroup;
    let subCopysArray = submissionsArray.controls[submissionsIndex].get('subDetails') as UntypedFormGroup;
    let docLocArray = subCopysArray.controls[subCopyIndex].get('docLoc') as UntypedFormArray;

    if (docLocArray.controls.length > 0) {
      for (let index = 0; index < docLocArray.controls.length; index++) {
        if (index === docLocIndex) {
          let itemListuploadControl = docLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*DocLoLoc Array end*/
  /*meetingDetails array */

  departmentInvArr(noticediv: UntypedFormGroup): UntypedFormGroup[] {
    return (noticediv.get('meetingDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }


  detailsOfDepartmentFrmGrp(meeting: any = null): UntypedFormGroup {
    return this.fBuild.group({
      dateOfMeeting: new UntypedFormControl(meeting ? meeting.dateOfMeeting : null, []),
      personAttended: new UntypedFormControl(meeting ? meeting.personAttended : null, []),
      hearingDetails: new UntypedFormControl(meeting ? meeting.hearingDetails : null, []),
      copyOfSubmissionLoc: new UntypedFormArray([]),
    })
  }

  addDepartmentInv(j: any) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let departmentFrmArray = dtNoticesFrmArray.controls[j].get('meetingDetails') as UntypedFormArray;
    departmentFrmArray.push(this.detailsOfDepartmentFrmGrp());
    (departmentFrmArray.get('copyOfSubmissionLoc') as UntypedFormArray).push(this.createCopyOfSub());
  }

  rmDepartmentInv(i: number, k: number) {
    let dtNoticesFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    let departmentFrmArray = dtNoticesFrmArray.controls[i].get('meetingDetails') as UntypedFormArray;
    departmentFrmArray.removeAt(k);
    this.rmvdepartmentModalRef.close();
  }

  openRemovDepartmentPop(content: any, i: any, k: any) {
    this.rmvdepartmentModalRef = this.modalService.open(content);
  }
  openDepartmentPop(content: any, i: any) {
    this.addCopyOfSub(i, 0);
    this.modalService.open(content);
  }
  /*meetingDetails array end */

  /*copyOfSubmissionLoc Array */
  getCopyOfSub(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('copyOfSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getCopyOfSubLength(noticeFormIndex: number, meetingDetailsFormIndex: number): number {
    const noticesListFrmArray = this.noticeForm.get('dtNotices') as UntypedFormArray;
    const departmentFrmArray = noticesListFrmArray.at(noticeFormIndex).get('meetingDetails') as UntypedFormArray;
    const copyOfSubmissionLocArray = departmentFrmArray.at(meetingDetailsFormIndex).get('copyOfSubmissionLoc') as UntypedFormArray;
    if (copyOfSubmissionLocArray && copyOfSubmissionLocArray.length > -1) {
      let countOfcopyOfSubmissionLoc = 0;
      for (let i = 0; i < copyOfSubmissionLocArray.length; i++) {
        const singleCopyOfSubmissionLoc = copyOfSubmissionLocArray.value[i];
        if (singleCopyOfSubmissionLoc.fileLoc != '' && singleCopyOfSubmissionLoc.fileLoc != null) {
          countOfcopyOfSubmissionLoc++;
        }
      }
      return countOfcopyOfSubmissionLoc;
    }

    return 0;
  }

  createCopyOfSub(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }
  addCopyOfSub(j: any, i: any) {
    let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as UntypedFormArray;
    for (let index = 0; index < noticesListFrmArray.length; index++) {
      const element = noticesListFrmArray.controls[index];

      var departmentFrmArray: any = element.controls.meetingDetails.controls;

      for (let i = 0; i < departmentFrmArray.length; i++) {
        const element1 = departmentFrmArray[i].controls.copyOfSubmissionLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            departmentFrmArray[i].controls.copyOfSubmissionLoc.push(this.createCopyOfSub())
          }
        }


      }
    }
  }

  uploadCopyOfSub(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any) {
    this.isDWCopyOfSubmissionLocFile = true;
    this.isDWCopyOfSubmissionLocText = false;
    this.isDWCopyOfSubmissionLoc = false;
    this.isUPCopyOfSubmissionLoc = false;

    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let departmentFrmArray = noticesListFrmArray.controls[noticeListIndex].get('meetingDetails') as UntypedFormGroup;
    let subCopysArray = departmentFrmArray.controls[submissionsIndex].get('copyOfSubmissionLoc') as UntypedFormArray
    if (subCopysArray.controls.length > 0) {
      for (let index = 0; index < subCopysArray.controls.length; index++) {
        if (index === subCopyIndex) {
          let itemListuploadControl = subCopysArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteCopyOfSub(noticeListIndex: any, subCopyIndex: any, submissionsIndex: any) {
    let noticesListFrmArray = this.noticeForm.controls.dtNotices as UntypedFormGroup;
    let departmentFrmArray = noticesListFrmArray.controls[noticeListIndex].get('meetingDetails') as UntypedFormGroup;
    let subCopysArray = departmentFrmArray.controls[submissionsIndex].get('copyOfSubmissionLoc') as UntypedFormArray
    if (subCopysArray.controls.length > 0) {
      for (let index = 0; index < subCopysArray.controls.length; index++) {
        if (index === subCopyIndex) {
          let itemListuploadControl = subCopysArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*copyOfSubmissionLoc Array end*/



  // Consultant Charges Table
  consultantInvArr(): UntypedFormGroup[] {
    return (this.consultantForm.get('cc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  consultantDataAutofil(index: number) {
    console.log('index:', index);
    const consultantGrp = this.consultantInvArr()[index];
    const selectedConsultantName = consultantGrp.get('consultant')?.value;

    // Find the selected consultant in the consultantList
    const selectedConsultant = this.consultantList.find(
      (obj: any) => obj.consultantName === selectedConsultantName
    );

    if (selectedConsultant) {
      // Auto-fill the remaining fields
      consultantGrp.get('firm')?.setValue(selectedConsultant.firmName || '');
      consultantGrp.get('email')?.setValue(selectedConsultant.email || '');
      consultantGrp.get('contact')?.setValue(selectedConsultant.contact || '');
    } else {
      // Clear the fields if consultant is not found
      consultantGrp.get('firm')?.reset();
      consultantGrp.get('email')?.reset();
      consultantGrp.get('contact')?.reset();
      consultantGrp.get('natureOfWork')?.reset();
      consultantGrp.get('fees')?.reset();
      consultantGrp.get('otherAttachmentLoc')?.reset();
    }

    console.log('data populated succesfully !!')
  }

  ConsultantCharges(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      firm: [data ? data.firm : null],
      consultant: [data ? data.consultant : null],
      natureOfWork: [data ? data.natureOfWork : null],
      fees: [data ? data.fees : 0],
      supportingDocsLoc: new UntypedFormArray([]),
      email: [data ? data.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [data ? data.contact : null],

    })
  }

  addConsultantInv() {
    let detailOfConsultant = this.ConsultantCharges();
    (this.consultantForm.get('cc') as UntypedFormArray).push(detailOfConsultant);
  }

  rmConsultantInv(i: number) {
    (this.consultantForm.get('cc') as UntypedFormArray).removeAt(i);
    this.rmvConsultantModalRef.close();
    this.feesChargedChanged();
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (this.consultantForm.get('cc') as UntypedFormArray).controls.forEach((c: any) => {
      const fees = typeof c.value.fees === 'string' ? c.value.fees.replace(/,/g, '') : c.value.fees;
      feesChargedTotal += Number(fees);
    });

    this.feesChargedTotal = feesChargedTotal;
  }

  openRmvconsultantPop(content: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }

  openCCDetailPop(content: any, i: any) {
    this.addConsultant(i, 0);
    this.modalService.open(content);
  }
  // Conclusion Array end 


  /*supportingDocsLoc Array */
  getConsultant(noticeLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (noticeLocGrp.get('supportingDocsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getConsultantLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.consultantForm.get('cc') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j]?.get('supportingDocsLoc') as UntypedFormArray;
    let noticecount = noticeLocFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createConsultant(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addConsultant(j: any, i: any) {
    let dtNoticesFrmArray = this.consultantForm.get('cc') as UntypedFormArray;
    let noticeLocFrmArray = dtNoticesFrmArray.controls[j].get('supportingDocsLoc') as UntypedFormArray;
    if (noticeLocFrmArray.length > 0) {
      let length = noticeLocFrmArray.length
      while (i < 5 - length) {
        noticeLocFrmArray.push(this.createConsultant());
        i++;
      }
    }
    else {
      while (i < 5) {
        noticeLocFrmArray.push(this.createConsultant());
        i++;
      }
    }
  }

  uploadConsultant(noticeListIndex: any, noticeLocIndex: any) {
    this.isnoticeLocFile = true;
    this.isnoticeLocText = false;
    this.isDWnoticeLoc = false;
    this.isUPnoticeLoc = false;
    let itemListsArray = this.consultantForm.controls.cc as UntypedFormGroup;
    let noticeLocFrmArray = itemListsArray.controls[noticeListIndex].get('supportingDocsLoc') as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteConsultant(noticeListIndex: any, noticeLocIndex: any) {
    let noticeArray = this.consultantForm.controls.cc as UntypedFormGroup;
    let noticeLocFrmArray = noticeArray.controls[noticeListIndex].get('supportingDocsLoc') as UntypedFormArray;
    if (noticeLocFrmArray.controls.length > 0) {
      for (let index = 0; index < noticeLocFrmArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = noticeLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*supportingDocsLoc Array end*/


  /*hearingDetails start */

  phDivArr() {
    return ((this.hearingForm.get('hearingDetails') as UntypedFormArray).controls as UntypedFormGroup[])
  }

  newphArray(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      phScheduledDate: [data ? data.phScheduledDate : null, []],
      phNoticeLoc: new UntypedFormArray([]),
      adjournedOrNot: [data ? data.adjournedOrNot : false, []],
      attended: [data ? data.attended : null, []],
      consultant: [data ? data.consultant : null, []],
      addWrittenSubDate: [data ? data.addWrittenSubDate : null, []],
      addSubLoc: new UntypedFormArray([]),
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phAdjournmentLetterLoc: new UntypedFormArray([]),
      mode: [data ? data.mode : null, []],
      phRefNo:[data ? data.phRefNo : null, []],
      phTime:[data ? data.phTime : null, []],
      phNoticeDate:[data ? data.phNoticeDate : null, []],
      phDiscNotes: [data ? data.phDiscNotes : null, [Validators.maxLength(1000)]],

    })
  }

  createAdjDiv() {
    var addPh: any = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    addPh.push(this.newphArray());
  }

  removephArr(index: number) {
    (this.hearingForm.get('hearingDetails') as UntypedFormArray).removeAt(index);
    this.selectedPhase = 'phase' + (index - 1);
  }

  changeadjOrNot(event: any, i: any) {
    if (event.target.value === "true") {
      this.createAdjDiv();
    }
  }

  /*phNoticeLoc Array */
  getPhNotice(PhNoticeGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (PhNoticeGrp.get('phNoticeLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhNoticeLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j]?.get('phNoticeLoc') as UntypedFormArray;
    let noticecount = PhNoticcFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createPhNotice(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addPhNotice(j: any, i: any) {
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j].get('phNoticeLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.length > 0) {
      let length = PhNoticcFrmArray.length
      while (i < 5 - length) {
        PhNoticcFrmArray.push(this.createPhNotice());
        i++;
      }
    }
    else {
      while (i < 5) {
        PhNoticcFrmArray.push(this.createPhNotice());
        i++;
      }
    }
  }

  openPhNoticePop(content: any, i: any) {
    this.addPhNotice(i, 0);
    this.modalService.open(content);
  }

  uploadPhNoticeCopy(noticeListIndex: any, PhNoticIndex: any) {
    this.isPhNoticFile = true;
    this.isPhNoticText = false;
    this.isDWPhNotic = false;
    this.isUPPhNotic = false;

    let itemListsArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = itemListsArray.controls[noticeListIndex].get('phNoticeLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhNoticeCopyFile(noticeListIndex: any, PhNoticIndex: any) {
    let noticeArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = noticeArray.controls[noticeListIndex].get('phNoticeLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*phNoticeLoc Array end*/

  /*addSubLoc Array */
  getAddSub(AddSubGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (AddSubGrp.get('addSubLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAddSubLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j]?.get('addSubLoc') as UntypedFormArray;
    let noticecount = PhNoticcFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createAddSub(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addAddSub(j: any, i: any) {
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j].get('addSubLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.length > 0) {
      let length = PhNoticcFrmArray.length
      while (i < 5 - length) {
        PhNoticcFrmArray.push(this.createAddSub());
        i++;
      }
    }
    else {
      while (i < 5) {
        PhNoticcFrmArray.push(this.createAddSub());
        i++;
      }
    }
  }

  openAddSubPop(content: any, i: any) {
    this.addAddSub(i, 0);
    this.modalService.open(content);
  }

  uploadAddSubCopy(noticeListIndex: any, PhNoticIndex: any) {
    this.isAddSubLocFile = true;
    this.isAddSubLocText = false;
    this.isDWAddSubLoc = false;
    this.isUPAddSubLoc = false;

    let itemListsArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = itemListsArray.controls[noticeListIndex].get('addSubLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAddSubFile(noticeListIndex: any, PhNoticIndex: any) {
    let noticeArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = noticeArray.controls[noticeListIndex].get('addSubLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*addSubLoc Array end*/


  /*phRecordIfAnyLoc Array */
  getPhRecordIfAny(PhNoticeGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (PhNoticeGrp.get('phRecordIfAnyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhRecordIfAnyLength(j?: any) {
    let count = 0;
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j]?.get('phRecordIfAnyLoc') as UntypedFormArray;
    let noticecount = PhNoticcFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createPhRecordIfAny(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addPhRecordIfAny(j: any, i: any) {
    let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
    let PhNoticcFrmArray = dtNoticesFrmArray.controls[j].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.length > 0) {
      let length = PhNoticcFrmArray.length
      while (i < 5 - length) {
        PhNoticcFrmArray.push(this.createPhRecordIfAny());
        i++;
      }
    }
    else {
      while (i < 5) {
        PhNoticcFrmArray.push(this.createPhRecordIfAny());
        i++;
      }
    }
  }

  openPhRecordIfAnyPop(content: any, i: any) {
    this.addPhRecordIfAny(i, 0);
    this.modalService.open(content);
  }

  uploadPhRecordIfAnyCopy(noticeListIndex: any, PhNoticIndex: any) {
    this.isPhRecordFile = true;
    this.isPhRecordText = false;
    this.isDWPhRecord = false;
    this.isUPPhRecord = false;

    let itemListsArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = itemListsArray.controls[noticeListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhRecordIfAnyFile(noticeListIndex: any, PhNoticIndex: any) {
    let noticeArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
    let PhNoticcFrmArray = noticeArray.controls[noticeListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*phRecordIfAnyLoc Array end*/

    /*phAdjournmentLetterLoc Array */
    getPhAdjournmentLetterLoc(PhNoticeGrp: UntypedFormGroup): UntypedFormGroup[] {
      return (PhNoticeGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
    }
    getPhAdjournmentLetterLocLength(j?: any) {
      let count = 0;
      let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
      let PhNoticcFrmArray = dtNoticesFrmArray.controls[j]?.get('phAdjournmentLetterLoc') as UntypedFormArray;
      let noticecount = PhNoticcFrmArray.value.filter((x: any) => x.fileLoc != null);
      return noticecount.length;
    }
    createPhAdjournmentLetterLoc(not: any = null) {
      return this.fBuild.group({
        fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
        remarks: new UntypedFormControl(not ? not.remarks : null, []),
      })
    }
    addPhAdjournmentLetterLoc(j: any, i: any) {
      let dtNoticesFrmArray = this.hearingForm.get('hearingDetails') as UntypedFormArray;
      let PhNoticcFrmArray = dtNoticesFrmArray.controls[j].get('phAdjournmentLetterLoc') as UntypedFormArray;
      if (PhNoticcFrmArray.length > 0) {
        let length = PhNoticcFrmArray.length
        while (i < 5 - length) {
          PhNoticcFrmArray.push(this.createPhAdjournmentLetterLoc());
          i++;
        }
      }
      else {
        while (i < 5) {
          PhNoticcFrmArray.push(this.createPhAdjournmentLetterLoc());
          i++;
        }
      }
    }
  
    openPhAdjournmentLetterLocPop(content: any, i: any) {
      this.addPhAdjournmentLetterLoc(i, 0);
      this.modalService.open(content);
    }
  
    uploadPhAdjournmentLetterLocCopy(noticeListIndex: any, PhNoticIndex: any) {
      this.isPhNoticFile = true;
      this.isPhNoticText = false;
      this.isDWPhNotic = false;
      this.isUPPhNotic = false;
  
      let itemListsArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
      let PhNoticcFrmArray = itemListsArray.controls[noticeListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;
      if (PhNoticcFrmArray.controls.length > 0) {
        for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
          if (index === PhNoticIndex) {
            let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
            itemListuploadControl.value['isdocLocUploadedClicked'] = true
            itemListuploadControl.value['fileLoc'] = null
          }
        }
      }
    }
    deletePhAdjournmentLetterLocFile(noticeListIndex: any, PhNoticIndex: any) {
      let noticeArray = this.hearingForm.controls.hearingDetails as UntypedFormGroup;
      let PhNoticcFrmArray = noticeArray.controls[noticeListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;
      if (PhNoticcFrmArray.controls.length > 0) {
        for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
          if (index === PhNoticIndex) {
            let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
            itemListuploadControl.value['isdocLocUploadedClicked'] = true
            itemListuploadControl.value['fileLoc'] = null
          }
        }
      }
    }
  
    /*phAdjournmentLetterLoc Array end*/

  /*hearingDetails end*/

  /*summonDetails*/

  getSummon() {
    return ((this.summonForm.get('summonDetails') as UntypedFormArray).controls as UntypedFormGroup[])
  }

  createSummon(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      summonDate: [data ? data.summonDate : null, []],
      summonNoticeLoc: new UntypedFormArray([]),
      adjournedOrNot: [data ? data.adjournedOrNot : false, []],
      attended: [data ? data.attended : null, []],
      personWhoAttended: [data ? data.personWhoAttended : null, []],
      addWritSubDate: [data ? data.addWritSubDate : null, []],
      addSubLoc: new UntypedFormArray([]),
      deptSummonReceivedLoc: new UntypedFormArray([]),
      discNotes: [data ? data.discNotes : null, [Validators.maxLength(1000)]],
    })
  }

  addSummon(i?: any) {
    var addSummonData: any = this.summonForm.get('summonDetails') as UntypedFormArray;
    addSummonData.push(this.createSummon());
  }

  removeSummon(index: number) {
    (this.summonForm.get('summonDetails') as UntypedFormArray).removeAt(index);
    this.selectedSummon = 'phase' + (index - 1);
  }

  changeSummonOrNot(event: any, i: any) {
    if (event.target.value === "true") {
      this.addSummon();
    }
  }


  /*summonNoticeLoc Array */
  getSummonNotice(PhNoticeGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (PhNoticeGrp.get('summonNoticeLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getSummonNoticeLength(j?: any) {
    let count = 0;
    let summonArray = this.summonForm.get('summonDetails') as UntypedFormArray;
    let summonNoticcFrmArray = summonArray.controls[j]?.get('summonNoticeLoc') as UntypedFormArray;
    let noticecount = summonNoticcFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createSummonNotice(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addSummonNotice(j: any, i: any) {
    let summonArray = this.summonForm.get('summonDetails') as UntypedFormArray;
    let summonNoticcFrmArray = summonArray.controls[j].get('summonNoticeLoc') as UntypedFormArray;
    if (summonNoticcFrmArray.length > 0) {
      let length = summonNoticcFrmArray.length
      while (i < 5 - length) {
        summonNoticcFrmArray.push(this.createSummonNotice());
        i++;
      }
    }
    else {
      while (i < 5) {
        summonNoticcFrmArray.push(this.createSummonNotice());
        i++;
      }
    }
  }

  openSummonNoticePop(content: any, i: any) {
    this.addSummonNotice(i, 0);
    this.modalService.open(content);
  }

  uploadSummonNoticeCopy(summonListIndex: any, PhNoticIndex: any) {
    this.isSummonNoticFile = true;
    this.isSummonNoticText = false;
    this.isDWSummonNotic = false;
    this.isUPSummonNotic = false;

    let summonArray = this.summonForm.controls.summonDetails as UntypedFormGroup;
    let summonNoticcFrmArray = summonArray.controls[summonListIndex].get('summonNoticeLoc') as UntypedFormArray;
    if (summonNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < summonNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = summonNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteSummonNoticeCopyFile(summonListIndex: any, PhNoticIndex: any) {
    let summonArray = this.summonForm.controls.summonDetails as UntypedFormGroup;
    let summonNoticcFrmArray = summonArray.controls[summonListIndex].get('summonNoticeLoc') as UntypedFormArray;
    if (summonNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < summonNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = summonNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*SummonNotice Array end*/

  /*addSubLoc summon Array */
  getSummonAddSub(AddSubGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (AddSubGrp.get('addSubLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getSummonAddSubLength(j?: any) {
    let count = 0;
    let summonArray = this.summonForm.get('summonDetails') as UntypedFormArray;
    let summonFrmArray = summonArray.controls[j]?.get('addSubLoc') as UntypedFormArray;
    let noticecount = summonFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createSummonAddSub(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addSummonAddSub(j: any, i: any) {
    let summonArray = this.summonForm.get('summonDetails') as UntypedFormArray;
    let summonFrmArray = summonArray.controls[j].get('addSubLoc') as UntypedFormArray;
    if (summonFrmArray.length > 0) {
      let length = summonFrmArray.length
      while (i < 5 - length) {
        summonFrmArray.push(this.createSummonAddSub());
        i++;
      }
    }
    else {
      while (i < 5) {
        summonFrmArray.push(this.createSummonAddSub());
        i++;
      }
    }
  }

  openSummonAddSubPop(content: any, i: any) {
    this.addSummonAddSub(i, 0);
    this.modalService.open(content);
  }

  uploadSummonAddSubCopy(noticeListIndex: any, PhNoticIndex: any) {
    this.isSummonAddSubLocFile = true;
    this.isSummonAddSubLocText = false;
    this.isSummonDWAddSubLoc = false;
    this.isUPSummonAddSubLoc = false;

    let itemListsArray = this.summonForm.controls.summonDetails as UntypedFormGroup;
    let PhNoticcFrmArray = itemListsArray.controls[noticeListIndex].get('addSubLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteSummonAddSubFile(noticeListIndex: any, PhNoticIndex: any) {
    let itemListsArray = this.summonForm.controls.summonDetails as UntypedFormGroup;
    let PhNoticcFrmArray = itemListsArray.controls[noticeListIndex].get('addSubLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*addSubLoc summon Array end*/


  /*deptSummonReceivedLoc Array */
  getDeptSummonReceived(PhNoticeGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (PhNoticeGrp.get('deptSummonReceivedLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getDeptSummonReceivedLength(j?: any) {
    let count = 0;
    let summonArray = this.summonForm.get('summonDetails') as UntypedFormArray;
    let PhNoticcFrmArray = summonArray.controls[j]?.get('deptSummonReceivedLoc') as UntypedFormArray;
    let noticecount = PhNoticcFrmArray.value.filter((x: any) => x.fileLoc != null);
    return noticecount.length;
  }
  createDeptSummonReceived(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : null, []),
      remarks: new UntypedFormControl(not ? not.remarks : null, []),
    })
  }
  addDeptSummonReceived(j: any, i: any) {
    let summonArray = this.summonForm.get('summonDetails') as UntypedFormArray;
    let PhNoticcFrmArray = summonArray.controls[j].get('deptSummonReceivedLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.length > 0) {
      let length = PhNoticcFrmArray.length
      while (i < 5 - length) {
        PhNoticcFrmArray.push(this.createDeptSummonReceived());
        i++;
      }
    }
    else {
      while (i < 5) {
        PhNoticcFrmArray.push(this.createDeptSummonReceived());
        i++;
      }
    }
  }

  openDeptSummonReceivedPop(content: any, i: any) {
    this.addDeptSummonReceived(i, 0);
    this.modalService.open(content);
  }

  uploadDeptSummonReceivedCopy(noticeListIndex: any, PhNoticIndex: any) {
    this.isSummonRecordFile = true;
    this.isSummonRecordText = false;
    this.isDWSummonRecord = false;
    this.isUPSummonRecord = false;
    let summonArray = this.summonForm.controls.summonDetails as UntypedFormGroup;
    let PhNoticcFrmArray = summonArray.controls[noticeListIndex].get('deptSummonReceivedLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteDeptSummonReceivedFile(noticeListIndex: any, PhNoticIndex: any) {
    let summonArray = this.summonForm.controls.summonDetails as UntypedFormGroup;
    let PhNoticcFrmArray = summonArray.controls[noticeListIndex].get('deptSummonReceivedLoc') as UntypedFormArray;
    if (PhNoticcFrmArray.controls.length > 0) {
      for (let index = 0; index < PhNoticcFrmArray.controls.length; index++) {
        if (index === PhNoticIndex) {
          let itemListuploadControl = PhNoticcFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  /*deptSummonReceivedLoc Array end*/
  /*summonDetails end*/

  // Phase 3
  /*orderDetails Array*/
  getOrderDetails() {
    return ((this.orderForm.get('orderDetails') as UntypedFormArray).controls as UntypedFormGroup[])
  }
  createOrderDetails(data: any = null) {
    const orderDetailsControl = this.fBuild.group({
      orderUs: new UntypedFormControl(data ? data.orderUs : null, [Validators.required]),
      orderNo: new UntypedFormControl(data ? data.orderNo : null, [Validators.required]),
      orderDate: new UntypedFormControl(data ? data.orderDate : null, [Validators.required]),
      orderRecepitDate: new UntypedFormControl(data ? data.orderRecepitDate : null, [Validators.required]),
      orderReceiptMode: new UntypedFormControl(data ? data.orderReceiptMode : null),
      orderLoc: new UntypedFormArray([]),
      orderPassedBy: new UntypedFormControl(data ? data.orderPassedBy : null),
      outcome: new UntypedFormControl(data ? data.outcome : null, [Validators.required]),
      orderSummary: new UntypedFormControl(data ? data.orderSummary : null, [Validators.maxLength(1000)]),
      internalRemarks: new UntypedFormControl(data ? data.internalRemarks : null, [Validators.maxLength(1000)]),
      isAppliedForRectif: new UntypedFormControl(data ? data.isAppliedForRectif : null),
      rectifOrderReceived: new UntypedFormControl(data ? data.rectifOrderReceived : null),
      rectifDetails: new UntypedFormArray([]),
    })
    // if (this.action === 'create') {
    //   (orderDetailsControl.get('rectifDetails') as FormArray).push(this.createRectif());
    // }
    return orderDetailsControl;
  }
  addOrderDetails() {
    var orderDetailsFormArray: any = this.orderForm.get('orderDetails') as UntypedFormArray;
    orderDetailsFormArray.push(this.createOrderDetails());
    const rectifDetailsIndex = orderDetailsFormArray.length - 1;
    (orderDetailsFormArray.controls[rectifDetailsIndex].get('rectifDetails') as UntypedFormArray).push(this.createRectif());
  }

  removeOrderDetails(i: number) {
    (this.orderForm.get('orderDetails') as UntypedFormArray).removeAt(i);
    this.selectedRectif = 'phase' + (i - 1);
  }

  changeOrderDetails(event: any, i: any) {
    if (event.target.value === "true") {
      this.addOrderDetails();
    }
  }
  /*orderDetails array */

  /*rectifDetails  Array*/
  getFormControlRectif(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('rectifDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  createRectif(data: any = null) {
    return this.fBuild.group({
      docNo: new UntypedFormControl(data ? data.docNo : null),
      rectifDate: new UntypedFormControl(data ? data.rectifDate : null),
      rectifMode: new UntypedFormControl(data ? data.rectifMode : null),
      rectifDetails: new UntypedFormControl(data ? data.rectifDetails : null),
      appCopyLoc: new UntypedFormArray([]),
      ackCopyLoc: new UntypedFormArray([]),
    })
  }

  addRectif(j: any) {
    let orderDetailsFormArray: any = this.orderForm.get('orderDetails') as UntypedFormArray;
    let rectifDetailsFrmArray = orderDetailsFormArray.controls[j].get('rectifDetails') as UntypedFormArray;
    rectifDetailsFrmArray.push(this.createRectif());
  }
  removeRectif(i: number, k: number) {
    let orderDetailsFormArray = this.orderForm.get('orderDetails') as UntypedFormArray;
    let rectifDetailsFrmArray = orderDetailsFormArray.controls[i].get('rectifDetails') as UntypedFormArray;
    rectifDetailsFrmArray.removeAt(k);
    this.rmvRectifDetailsModalRef.close();
  }
  openRemoveRectifDetailsPop(content: any, i: any) {
    this.rmvRectifDetailsModalRef = this.modalService.open(content);
  }
  /*rectifDetails array  end*/

  /*appCopyLoc Array */
  getAppCopyLoc(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('appCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getAppCopyLocLength(orderFormIndex: number, rectifDetailsFormIndex: number): number {
    const orderDetailsFormArray = this.orderForm.get('orderDetails') as UntypedFormArray;
    const rectifDetailsArray = orderDetailsFormArray.at(orderFormIndex).get('rectifDetails') as UntypedFormArray;
    const appCopyLocListArray = rectifDetailsArray.at(rectifDetailsFormIndex).get('appCopyLoc') as UntypedFormArray;
    if (appCopyLocListArray && appCopyLocListArray.length > -1) {
      let appCopycount = 0;
      for (let i = 0; i < appCopyLocListArray.length; i++) {
        const singleAppCopyLoc = appCopyLocListArray.value[i];
        if (singleAppCopyLoc.fileLoc != '' && singleAppCopyLoc.fileLoc != null) {
          appCopycount++;
        }
      }
      return appCopycount;
    }
    return 0;
  }

  createAppCopy(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }
  addAppCopyDiv(j: any, i: any) {
    let orderDetailsFormArray: any = this.orderForm.get('orderDetails') as UntypedFormArray;
    for (let index = 0; index < orderDetailsFormArray.length; index++) {
      const element = orderDetailsFormArray.controls[index];

      var rectifDetailsArray: any = element.controls.rectifDetails.controls;

      for (let i = 0; i < rectifDetailsArray.length; i++) {
        const element1 = rectifDetailsArray[i].controls.appCopyLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            rectifDetailsArray[i].controls.appCopyLoc.push(this.createAppCopy())
          }
        }
      }
    }
  }

  uploadAppCopy(orderFormIndex: any, appCopyLocIndex: any, rectifDetailsIndex: any) {
    this.isadjDocsLocFile = true;
    this.isadjDocsLocText = false;
    this.isDWadjDocsLoc = false;
    this.isUPadjDocsLoc = false;
    let orderDetailsFormArray = this.orderForm.controls.orderDetails as UntypedFormGroup;
    let rectifDetailsArray = orderDetailsFormArray.controls[orderFormIndex].get('rectifDetails') as UntypedFormGroup;
    let appCopyLocArray = rectifDetailsArray.controls[rectifDetailsIndex].get('appCopyLoc') as UntypedFormArray
    if (appCopyLocArray.controls.length > 0) {
      for (let index = 0; index < appCopyLocArray.controls.length; index++) {
        if (index === appCopyLocIndex) {
          let itemListuploadControl = appCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteAppCopyFile(orderFormIndex: any, appCopyLocIndex: any, rectifDetailsIndex: any) {
    let orderDetailsFormArray = this.orderForm.controls.orderDetails as UntypedFormGroup;
    let rectifDetailsArray = orderDetailsFormArray.controls[orderFormIndex].get('rectifDetails') as UntypedFormGroup;
    let appCopyLocArray = rectifDetailsArray.controls[rectifDetailsIndex].get('appCopyLoc') as UntypedFormArray
    if (appCopyLocArray.controls.length > 0) {
      for (let index = 0; index < appCopyLocArray.controls.length; index++) {
        if (index === appCopyLocIndex) {
          let itemListuploadControl = appCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  openAppCopyPop(content: any, i: any) {
    this.addAppCopyDiv(i, 0);
    this.modalService.open(content);
  }
  /*appCopyLoc Array end*/




  /*ackCopyLoc Array */
  getAckCopy(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('ackCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getAckCopyLength(orderFormIndex: number, rectifDetailsFormIndex: number): number {
    const orderDetailsFormArray = this.orderForm.get('orderDetails') as UntypedFormArray;
    const rectifDetailsArray = orderDetailsFormArray.at(orderFormIndex).get('rectifDetails') as UntypedFormArray;
    const ackCopyLocListArray = rectifDetailsArray.at(rectifDetailsFormIndex).get('ackCopyLoc') as UntypedFormArray;
    if (ackCopyLocListArray && ackCopyLocListArray.length > -1) {
      let ackCopycount = 0;
      for (let i = 0; i < ackCopyLocListArray.length; i++) {
        const singleAckCopyLoc = ackCopyLocListArray.value[i];
        if (singleAckCopyLoc.fileLoc != '' && singleAckCopyLoc.fileLoc != null) {
          ackCopycount++;
        }
      }
      return ackCopycount;
    }
    return 0;
  }

  createAckCopyDetails(docSub: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(docSub ? docSub.fileLoc : null, []),
      remarks: new UntypedFormControl(docSub ? docSub.remarks : null, []),
    })
  }
  addAckCopyDiv(j: any, i: any) {
    let orderDetailsFormArray: any = this.orderForm.get('orderDetails') as UntypedFormArray;
    for (let index = 0; index < orderDetailsFormArray.length; index++) {
      const element = orderDetailsFormArray.controls[index];

      var rectifDetailsArray: any = element.controls.rectifDetails.controls;

      for (let i = 0; i < rectifDetailsArray.length; i++) {
        const element1 = rectifDetailsArray[i].controls.ackCopyLoc;

        if (element1.controls.length === 0) {
          for (let k = 0; k < 5; k++) {
            rectifDetailsArray[i].controls.ackCopyLoc.push(this.createAppCopy())
          }
        }
      }
    }
  }

  uploadAckCopy(orderFormIndex: any, ackCopyLocIndex: any, rectifDetailsIndex: any) {
    this.isadjDocsLocFile = true;
    this.isadjDocsLocText = false;
    this.isDWadjDocsLoc = false;
    this.isUPadjDocsLoc = false;
    let orderDetailsFormArray = this.orderForm.controls.orderDetails as UntypedFormGroup;
    let rectifDetailsArray = orderDetailsFormArray.controls[orderFormIndex].get('rectifDetails') as UntypedFormGroup;
    let ackCopyLocArray = rectifDetailsArray.controls[rectifDetailsIndex].get('ackCopyLoc') as UntypedFormArray
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deletedAckCopyLocFile(orderFormIndex: any, ackCopyLocIndex: any, rectifDetailsIndex: any) {
    let orderDetailsFormArray = this.orderForm.controls.orderDetails as UntypedFormGroup;
    let rectifDetailsArray = orderDetailsFormArray.controls[orderFormIndex].get('rectifDetails') as UntypedFormGroup;
    let ackCopyLocArray = rectifDetailsArray.controls[rectifDetailsIndex].get('ackCopyLoc') as UntypedFormArray
    if (ackCopyLocArray.controls.length > 0) {
      for (let index = 0; index < ackCopyLocArray.controls.length; index++) {
        if (index === ackCopyLocIndex) {
          let itemListuploadControl = ackCopyLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  openAckCopyPop(content: any, i: any) {
    this.addAckCopyDiv(i, 0);
    this.modalService.open(content);
  }

  /*ackCopyLoc Array end*/

  /*orderLoc array p2*/
  getOrderLoc(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('orderLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getOrderLocength(j?: any) {
    let count = 0;
    let rectifDetailsFrmArray = this.orderForm.get('orderDetails') as UntypedFormArray;
    let challanFrmArray = rectifDetailsFrmArray.controls[j]?.get('orderLoc') as UntypedFormArray;
    let orderLocCount = challanFrmArray.value.filter((x: any) => x.fileLoc != "" && x.fileLoc != null);
    return orderLocCount.length;
  }
  initOrderLocFrmGrp(data: any = null) {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl(data ? data?.fileLoc : null, []),
      remarks: new UntypedFormControl(data ? data?.fileLoc : null, []),
    })
  }
  addOrderLocDiv(j: any, i: any) {
    let rectifDetailsFrmArray = this.orderForm.get('orderDetails') as UntypedFormArray;
    let orderFrmArray = rectifDetailsFrmArray.controls[j]?.get('orderLoc') as UntypedFormArray;
    if (orderFrmArray.length > 0) {
      let length = orderFrmArray.length
      while (i < 5 - length) {
        orderFrmArray.push(this.initOrderLocFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        orderFrmArray.push(this.initOrderLocFrmGrp());
        i++;
      }
    }
  }
  rmOrderLocDiv(i: number, k: number) {
    let rectifDetailsFrmArray = this.orderForm.get('orderDetails') as UntypedFormArray;
    let orderFrmArray = rectifDetailsFrmArray.controls[i].get('orderLoc') as UntypedFormArray;
    orderFrmArray.removeAt(k);
    this.rmvorderLocModalRef.close();
  }
  openOrderLoc(content: any, i: any) {
    this.rmvorderLocModalRef = this.modalService.open(content);
  }

  uploadOrderLoc(rectifIndex: any, orderLocIndex: any) {
    this.isOrderLocFile = true;
    this.isOrderLocText = false;
    this.isOrderLocDWText = true;
    this.isOrderLocUPText = false;

    let rectifDetailsFrmArray = this.orderForm.controls.orderDetails as UntypedFormGroup;
    let orderFrmArray = rectifDetailsFrmArray.controls[rectifIndex].get('orderLoc') as UntypedFormArray;
    if (orderFrmArray.controls.length > 0) {
      for (let index = 0; index < orderFrmArray.controls.length; index++) {
        if (index === orderLocIndex) {
          let orderLocstuploadControl = orderFrmArray.controls[index] as UntypedFormGroup;
          orderLocstuploadControl.value['isdocLocUploadedClicked'] = true
          orderLocstuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteOrderLocFile(rectifIndex: any, orderLocIndex: any) {
    let rectifDetailsFrmArray = this.orderForm.controls.orderDetails as UntypedFormGroup;
    let orderFrmArray = rectifDetailsFrmArray.controls[rectifIndex].get('orderLoc') as UntypedFormArray;
    if (orderFrmArray.controls.length > 0) {
      for (let index = 0; index < orderFrmArray.controls.length; index++) {
        if (index === orderLocIndex) {
          let orderLocstuploadControl = orderFrmArray.controls[index] as UntypedFormGroup;
          orderLocstuploadControl.value['isdocLocUploadedClicked'] = true
          orderLocstuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  openOrderGrpPop(content: any, i: any) {
    this.addOrderLocDiv(i, 0);
    this.modalService.open(content);
  }
  /*orderLoc array */

  /*appealDetails Array */
  getappealDetail(): UntypedFormGroup[] {
    return (this.orderForm.get('appealDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  appealDetailFrmGrp(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      action: [data ? data.action : null, [Validators.required]],
      forum: [data ? data.forum : null],
      isCaseCreated: ["false"],
    })
  }

  addAppealDetail() {
    let detailOfConsultant = this.appealDetailFrmGrp();
    (this.orderForm.get('appealDetails') as UntypedFormArray).push(detailOfConsultant);
  }
  openRemovAppealPop(content: any) {
    this.rmvAppealDetailsRef = this.modalService.open(content);
  }

  rmAppealDetail(i: number) {
    (this.orderForm.get('appealDetails') as UntypedFormArray).removeAt(i);
    this.rmvAppealDetailsRef.close();
  }
  /*appealDetails Array end */

  /*refundDetails array */
  getRefundDetails(): UntypedFormGroup[] {
    return (this.taxPaymentRefundDetailsForm.get('refundDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createRefundDetailsFrmGrp(data: any = null) {
    return this.fBuild.group({
      receiptDate: [data ? data?.receiptDate : null],
      mode: [data ? data?.mode : null],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
    })
  }

  addRefundDetails() {
    let refundDetailsFrmGrp = this.createRefundDetailsFrmGrp();
    (this.taxPaymentRefundDetailsForm.get('refundDetails') as UntypedFormArray).push(refundDetailsFrmGrp);

  }

  rmRefundDetailsInv(i: number) {
    (this.taxPaymentRefundDetailsForm.get('refundDetails') as UntypedFormArray).removeAt(i);
    this.rmvRefundDetailsModalRef.close();
    this.refundDetailsChanged();
  }

  openRefundDetailsPop(content: any, i: any) {
    this.rmvRefundDetailsModalRef = this.modalService.open(content);
  }

  /*refundDetails end array */

  /*libDetails array */
  getLibDetails(): UntypedFormGroup[] {
    return (this.taxPaymentRefundDetailsForm.get('libDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createLibDetailsFrmGrp(data: any = null) {
    return this.fBuild.group({
      cinRefNo: [data ? data?.cinRefNo : null],
      date: [data ? data?.date : null],
      bsrCode: [data ? data?.bsrCode : null],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      challanCopyLoc: new UntypedFormArray([]),
    })
  }

  addLibDetails() {
    let liabilityAddmittedAndPaidFrmGrp = this.createLibDetailsFrmGrp();
    (this.taxPaymentRefundDetailsForm.get('libDetails') as UntypedFormArray).push(liabilityAddmittedAndPaidFrmGrp);

  }

  rmLibDetailsInv(i: number) {
    (this.taxPaymentRefundDetailsForm.get('libDetails') as UntypedFormArray).removeAt(i);
    this.rmvLibDetailsModalRef.close();
    this.liabilityAddmittedAndPaidChanged();
  }

  openLibDetailsPop(content: any, i: any) {
    this.rmvLibDetailsModalRef = this.modalService.open(content);
  }

  openLibDetailsGrpPop(content: any, i: any) {
    this.addChallanDiv(i, 0);
    this.modalService.open(content);
  }
  /*libDetails end array */

  /*challanCopyLoc array p2*/
  getChallan(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('challanCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getChallanLength(j?: any) {
    let count = 0;
    let libDetailstFrmArray = this.taxPaymentRefundDetailsForm.get('libDetails') as UntypedFormArray;
    let challanFrmArray = libDetailstFrmArray.controls[j]?.get('challanCopyLoc') as UntypedFormArray;
    let itrcount = challanFrmArray.value.filter((x: any) => x.fileLoc != "" && x.fileLoc != null);
    return itrcount.length;
  }
  initChallanFrmGrp(data: any = null) {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl(data ? data?.fileLoc : null, []),
      remarks: new UntypedFormControl(data ? data?.fileLoc : null, []),
    })
  }
  addChallanDiv(j: any, i: any) {
    let libDetailstFrmArray = this.taxPaymentRefundDetailsForm.get('libDetails') as UntypedFormArray;
    let challanFrmArray = libDetailstFrmArray.controls[j]?.get('challanCopyLoc') as UntypedFormArray;
    if (challanFrmArray.length > 0) {
      let length = challanFrmArray.length
      while (i < 5 - length) {
        challanFrmArray.push(this.initChallanFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        challanFrmArray.push(this.initChallanFrmGrp());
        i++;
      }
    }
  }
  rmChallanDiv(i: number, k: number) {
    let libDetailstFrmArray = this.taxPaymentRefundDetailsForm.get('libDetails') as UntypedFormArray;
    let challanFrmArray = libDetailstFrmArray.controls[i].get('challanCopyLoc') as UntypedFormArray;
    challanFrmArray.removeAt(k);
    this.rmvChallanFormLocModalRef.close();
  }
  openChallanPop(content: any, i: any) {
    this.rmvChallanFormLocModalRef = this.modalService.open(content);
  }

  uploadChallan(libDetailstIndex: any, judicialIndex: any) {
    this.isSupportingDocFile = true;
    this.isSupportingDocText = false;
    this.isSupportingDocDWText = true;
    this.isSupportingDocUPText = false;
    let libDetailstsArray = this.taxPaymentRefundDetailsForm.controls.libDetails as UntypedFormGroup;
    let challanFrmArray = libDetailstsArray.controls[libDetailstIndex].get('challanCopyLoc') as UntypedFormArray;
    if (challanFrmArray.controls.length > 0) {
      for (let index = 0; index < challanFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let libDetailstuploadControl = challanFrmArray.controls[index] as UntypedFormGroup;
          libDetailstuploadControl.value['isdocLocUploadedClicked'] = true
          libDetailstuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteChallanCopyFile(libDetailstIndex: any, ChallanIndex: any) {
    let libDetailstsArray = this.taxPaymentRefundDetailsForm.controls.libDetails as UntypedFormGroup;
    let challanFrmArray = libDetailstsArray.controls[libDetailstIndex].get('challanCopyLoc') as UntypedFormArray;
    if (challanFrmArray.controls.length > 0) {
      for (let index = 0; index < challanFrmArray.controls.length; index++) {
        if (index === ChallanIndex) {
          let libDetailstuploadControl = challanFrmArray.controls[index] as UntypedFormGroup;
          libDetailstuploadControl.value['isdocLocUploadedClicked'] = true
          libDetailstuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*challanCopyLoc array */

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('provAndLibDetails') as UntypedFormArray).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  setForumOfAppeal() {
    this.forumAppeal = FORUM_ENQUIRY_PROCEED;
    const appealDetails = this.orderForm.controls.appealDetails as UntypedFormArray;
    for (let index = 0; index < appealDetails.controls.length; index++) {
      const element: any = appealDetails.controls[index]
      console.log(element, "element")
      if (element.controls.action.value == 'AP') {
        element.controls.forum.patchValue(this.forumAppeal[0].value);

      } else if (element.controls.action.value == 'WP') {
        element.controls.forum.patchValue(this.forumAppeal[1].value);

      } else if (element.controls.action.value == 'CLOSED') {
        element.controls.forum.patchValue(this.forumAppeal[2].value);

      } else if (element.controls.action.value == 'PFRO') {
        element.controls.forum.patchValue(this.forumAppeal[3].value);

      }
    }
  }


  // save Api 
  saveNotice(caseOption: string) {
    console.log(this.noticeForm, 'generalInfo');
    if (this.noticeForm.valid && this.generalInfo.valid) {
      for (let index = 0; index < this.notice.controls.length; index++) {
        let noticeForm = this.notice.at(index) as UntypedFormGroup;
        let adjSubForm = noticeForm.get('adjSubDetails') as UntypedFormArray;
        let reqDetailsForm = noticeForm.get('reqDetails') as UntypedFormArray;
        let noticeLocForm = noticeForm.get('noticeLoc') as UntypedFormArray;
        let issueForm = noticeForm.get('issues') as UntypedFormArray;
        let issuingAuthForm = noticeForm.get('issuingAuthority') as UntypedFormArray;
        let itrdata: any;
        if (this.caseType != 'PENALTY') {
          itrdata = this.iTRDetailsForm.get('itrDetails') as UntypedFormArray;
        }

        this.inrFormattingRemoving();

        if (this.generalInfo.valid) {
          if (this.noticeForm.valid) {
            let notices = this.noticeForm.get('dtNotices') as UntypedFormArray;
            let data: any = {};
            let dataadj: any = {};
            let issueData: any = {};
            let datareqDetails: any = {};
            let datanoticeLoc: any = {};
            let dataissuingAuth: any = {};
            let dataItrDetails: any = {};
            let adjDocsLocData: any = {};

            let dtNoticesData: any = [];
            let adjArray: any = [];
            let issueArray: any = [];
            let reqDetailArray: any = [];
            let noticeLocArray: any = [];
            let issuingAuthArray: any = [];
            let itrDetailsArray: any = [];
            let adjSubArray: any = [];
            let adjDocsLocArray: any = [];


            issueForm.controls.forEach(element => {
              issueData = {
                issue: element.value.issue,
                keyword: element.value.keyword,
                riskParam: element.value.riskParam,
                addnOrDisOfExpensesF: element.value.addnOrDisOfExpensesF,
                taxRateF: element.value.taxRateF,
                taxImpactF: element.value.taxImpactF,
                interestF: element.value.interestF,
                penaltyF: element.value.penaltyF,
                totalF: element.value.totalF,
                remarkF: element.value.remarkF,
                provAndLibDetails: element.value.provAndLibDetails,
              }
              issueArray.push(issueData);
            });


            adjSubForm.controls.forEach(element => {
              adjSubArray = element.get('adjDocsLoc') as UntypedFormArray;
            })
            if (adjSubArray != null && adjSubArray != ' ') {
              adjSubArray.controls.forEach((element: any) => {
                if (element.value.fileLoc != '' && element.value.fileLoc != null) {
                  adjDocsLocData = {
                    fileLoc: element.value.fileLoc,
                    remarks: element.value.remarks,
                  }
                  adjDocsLocArray.push(adjDocsLocData);
                }
              });
            }

            adjSubForm.controls.forEach(element => {
              dataadj = {
                adjFiledDate: element.value.adjFiledDate,
                newAdjDate: element.value.newAdjDate,
                adjReason: element.value.adjReason,
                isGranted: element.value.isGranted,
                adjDocsLoc: adjDocsLocArray
              }
              adjArray.push(dataadj);
            });

            reqDetailsForm.controls.forEach(element => {
              datareqDetails = {
                docReq: element.value.docReq,
                remarks: element.value.remarks,
              }
              reqDetailArray.push(datareqDetails);
            });

            issuingAuthForm.controls.forEach(element => {
              dataissuingAuth = {
                noticeNo: element.value.noticeNo,
                officer: element.value.officer,
                designation: element.value.designation,
                jurisdiction: element.value.jurisdiction,
                address: element.value.address,
                email: element.value.email,
                contact: element.value.contact,

              }
              issuingAuthArray.push(dataissuingAuth);
            });
            noticeLocForm.controls.forEach(element => {
              if (element.value.fileLoc != '' && element.value.fileLoc != null) {
                datanoticeLoc = {
                  fileLoc: element.value.fileLoc,
                  remarks: element.value.remarks,
                }
                noticeLocArray.push(datanoticeLoc);
              }
            });

            if (this.caseType != 'PENALTY') {
              itrdata.controls.forEach((element: any) => {
                if (element.value.itrForm != '' && element.value.itrForm != null) {
                  dataItrDetails = {
                    returnFiledUs: element.value.returnFiledUs,
                    itrForm: element.value.itrForm,
                    ackNo: element.value.ackNo,
                    filingDate: element.value.filingDate,
                    totTaxIncome: element.value.totTaxIncome,
                    totTaxLiability: element.value.totTaxLiability,
                    advanceTax: element.value.advanceTax,
                    tdsAmount: element.value.tdsAmount,
                    tcsAmount: element.value.tcsAmount,
                    selfAssessmentAmount: element.value.selfAssessmentAmount,
                    totTaxPaid: element.value.totTaxPaid,
                    refundDemand: element.value.refundDemand,
                  }
                  itrDetailsArray.push(dataItrDetails);
                }
              });

            }


            notices.controls.forEach(element => {
              data = {
                issuingAuth: element.value.issuingAuth,
                noticeRefNo: element.value.noticeRefNo,
                noticeType: element.value.noticeType,
                noticeReceiptDate: element.value.noticeReceiptDate,
                noticeDate: element.value.noticeDate,
                internalDueDate: element.value.internalDueDate,
                noticeReceiptMode: element.value.noticeReceiptMode,
                noticeIssuedUs: element.value.noticeIssuedUs,
                legalDueDate: element.value.legalDueDate,
                isExtApplied: element.value.isExtApplied,
                doesCorrespondenceInvolvesAmts: element.value.doesCorrespondenceInvolvesAmts,
                extDueDate: element.value.extDueDate,
                noticeSummary: element.value.noticeSummary,
                internalRemarks: element.value.internalRemarks,
                totOfAddnOrDisOfExpensesF: element.value.totOfAddnOrDisOfExpensesF,
                taxRateFTotal: element.value.taxRateFTotal,
                taxImpactFTotal: element.value.taxImpactFTotal,
                interestFTotal: element.value.interestFTotal,
                penaltyFTotal: element.value.penaltyFTotal,
                totOfTotal: element.value.totOfTotal,
                adjSubDetails: adjArray,
                reqDetails: reqDetailArray,
                issuingAuthority: issuingAuthArray,
                noticeLoc: noticeLocArray,
                issues: element.value.doesCorrespondenceInvolvesAmts === "true" ? issueArray : null,
              }
              dtNoticesData.push(data);
            });
            this.assessmentRequest.phase = 1,
              this.assessmentRequest.action = 'CREATE',
              this.assessmentRequest.caseType = caseOption,
              this.assessmentRequest.pan = this.generalInfo.controls.pan.value,
              this.assessmentRequest.panName = this.generalInfo.controls.panName.value,
              this.assessmentRequest.ay = this.generalInfo.controls.ay.value,
              this.assessmentRequest.finYear = this.generalInfo.controls.finYear.value,
              this.assessmentRequest.consultant = this.generalInfo.controls.consultant.value,
              this.assessmentRequest.personResp = this.generalInfo.controls.personResp.value,
              this.assessmentRequest.caseSummary = this.generalInfo.controls.caseSummary.value,
              this.assessmentRequest.internalRemarks1 = this.generalInfo.controls.internalRemarks1.value,
              this.assessmentRequest.caseLabel = this.generalInfo.controls.caseLabel.value,
              this.assessmentRequest.writToHc = this.writToHcForm.controls.writToHc.value,
              this.assessmentRequest.dtNotices = dtNoticesData;
            if (this.caseType != "PENALTY") {
              this.assessmentRequest.itrDetails = itrDetailsArray;
            }
          }
        }
      }
      this.taxService.CreateAssessment(this.assessmentRequest).subscribe((response: any) => {
        if (response.status == 1) {
          this.savedCaseId = response.response;
          this.updateBtn = true;
          this.tab4Disable = true;
          this.saveCaseId.emit(this.savedCaseId);
          this.toaster.showSuccess(response.message);
          this.initializeData();
          this.formatTableValues();
        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }

  }
  // save Api  end

  //  upadet Api 
  updateCase(phaseTab: any, caseOption: string) {
    if (phaseTab === 1) {
      this.isNextBtnClicked = true;
      this.isSubmittedNotice = true;
      this.updateIssues();
      var dtNotices = this.getFormControlNotice();
      let noticeLoc;
      for (let i = 0; i < dtNotices.length; i++) {
        noticeLoc = (dtNotices[i].get('noticeLoc') as UntypedFormArray).controls;
      }
      var count = noticeLoc?.some(x =>
        x.get('fileLoc')?.value != null
      );
      if (!count) {
        this.toaster.showError("Required Fields Are Empty");
        return;
      }
    } else if (phaseTab === 2) {
      this.isSubmittedSubmission = true;
      this.isSubmitted = true;
      this.isSubmittedAppeal = true
    }
    if (this.generalInfo.valid && this.noticeForm.valid) {
      this.inrFormattingRemoving();
      let finalPayload: any = {};
      const dtNotices = this.notice.value;
      // Preparation For DT Notices 
      for (let i = 0; i < dtNotices.length; i++) {
        const dtNoticesArray = dtNotices[i];

        if ((!dtNoticesArray.doesCorrespondenceInvolvesAmts) || (dtNoticesArray.doesCorrespondenceInvolvesAmts === 'false')) {
          delete dtNoticesArray.issues;
        }

        // Manupulation For adjSubDetails
        for (let j = 0; j < dtNoticesArray.adjSubDetails.length; j++) {
          const adjSubDetailsArray = dtNoticesArray.adjSubDetails[j];
          let finalAdjDocsLocArray: any = [];
          for (let k = 0; k < adjSubDetailsArray.adjDocsLoc.length; k++) {
            const adjDocsLocArray = adjSubDetailsArray.adjDocsLoc[k];
            if (adjDocsLocArray.fileLoc != '' && adjDocsLocArray.fileLoc != null) {
              const adjDocsLocData = {
                fileLoc: adjDocsLocArray.fileLoc,
                remarks: adjDocsLocArray.remarks,
              }
              finalAdjDocsLocArray.push(adjDocsLocData);
            }
          }
          adjSubDetailsArray.adjDocsLoc = finalAdjDocsLocArray;
        }

        // Manipulation For reqDetails
        let reqDetailArray: any = [];
        for (let l = 0; l < dtNoticesArray.reqDetails.length; l++) {
          const element = dtNoticesArray.reqDetails[l];
          if (element.docReq != '' && element.docReq != null) {
            const reqData = {
              docReq: element.docReq,
              remarks: element.remarks,
            }
            reqDetailArray.push(reqData);
          }
        }
        dtNoticesArray.reqDetails = reqDetailArray;

        // Manipulation For noticeLoc
        let noticeLocArray: any = [];
        for (let l = 0; l < dtNoticesArray.noticeLoc.length; l++) {
          const element = dtNoticesArray.noticeLoc[l];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const noticeData = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            noticeLocArray.push(noticeData);
          }
        }
        dtNoticesArray.noticeLoc = noticeLocArray;


        // Manupulation For submissions
        for (let j = 0; j < dtNoticesArray.submissions.length; j++) {
          const submissionObj = dtNoticesArray.submissions[j];

          // Manipulation For ackCopyLoc
          let ackCopyLocArray: any = [];
          for (let k = 0; k < submissionObj.ackCopyLoc.length; k++) {
            const element = submissionObj.ackCopyLoc[k];
            if (element.fileLoc != '' && element.fileLoc != null) {
              const ackCopyData = {
                fileLoc: element.fileLoc,
                remarks: element.remarks,
              }
              ackCopyLocArray.push(ackCopyData);
            }
          }
          submissionObj.ackCopyLoc = ackCopyLocArray;

          // Manipulation For subCopyLoc
          let subCopyLocArray: any = [];
          for (let l = 0; l < submissionObj.subCopyLoc.length; l++) {
            const element = submissionObj.subCopyLoc[l];
            if (element.fileLoc != '' && element.fileLoc != null) {
              const ackCopyData = {
                fileLoc: element.fileLoc,
                remarks: element.remarks,
              }
              subCopyLocArray.push(ackCopyData);
            }
          }
          submissionObj.subCopyLoc = subCopyLocArray;

          for (let m = 0; m < submissionObj.subDetails.length; m++) {
            const subDetailsObj = submissionObj.subDetails[m];

            // Manipulation For docLoc
            let docLocArray: any = [];
            for (let n = 0; n < subDetailsObj.docLoc.length; n++) {
              const element = subDetailsObj.docLoc[n];
              if (element.fileLoc != '' && element.fileLoc != null) {
                const docLocData = {
                  fileLoc: element.fileLoc,
                  remarks: element.remarks,
                }
                docLocArray.push(docLocData);
              }
            }
            subDetailsObj.docLoc = docLocArray;
          }
        }

        // Manupulation For meetingDetails
        for (let j = 0; j < dtNoticesArray.meetingDetails.length; j++) {
          const meetingDetailsObj = dtNoticesArray.meetingDetails[j];

          // Manipulation For copyOfSubmissionLoc
          let copyOfSubmissionLocArray: any = [];
          for (let k = 0; k < meetingDetailsObj.copyOfSubmissionLoc.length; k++) {
            const element = meetingDetailsObj.copyOfSubmissionLoc[k];
            if (element.fileLoc != '' && element.fileLoc != null) {
              const copyOfSubmissionLocData = {
                fileLoc: element.fileLoc,
                remarks: element.remarks,
              }
              copyOfSubmissionLocArray.push(copyOfSubmissionLocData);
            }
          }
          meetingDetailsObj.copyOfSubmissionLoc = copyOfSubmissionLocArray;
        }
      }
      finalPayload.dtNotices = dtNotices;
      if (this.caseType != "PENALTY") {
        // Preparation For iTRDetailsForm
        const iTRForm = this.iTRDetailsForm.value;
        let checkiTRFormNotEmpty = false;
        for (let i = 0; i < iTRForm.itrDetails.length; i++) {
          const singleiTRForm = iTRForm.itrDetails[i];

          if ((!checkiTRFormNotEmpty) && (singleiTRForm.returnFiledUs != null || singleiTRForm.itrForm != null || singleiTRForm.ackNo != null || singleiTRForm.filingDate != null || singleiTRForm.totTaxIncome != null || singleiTRForm.totTaxLiability != null || singleiTRForm.totTaxPaid != null || singleiTRForm.advanceTax != null|| singleiTRForm.tdsAmount != null || singleiTRForm.tcsAmount != null || singleiTRForm.selfAssessmentAmount != null || singleiTRForm.refundDemand != null)) {
            checkiTRFormNotEmpty = true;
          }
        }
        if (checkiTRFormNotEmpty) {
          finalPayload.itrDetails = iTRForm.itrDetails;
        }
      }


      // Preparation For Hearing Details
      const hearingDetailsForm = this.hearingForm.value;
      let checkHearingDetailsNotEmpty = false;
      for (let i = 0; i < hearingDetailsForm.hearingDetails.length; i++) {
        const singleHearingDetails = hearingDetailsForm.hearingDetails[i];
        // Manipulation For phNoticeLoc
        const phNoticeLocArray = hearingDetailsForm.hearingDetails[i].phNoticeLoc;
        let finalPhNoticeLocArray: any = [];

        for (let j = 0; j < phNoticeLocArray.length; j++) {
          const element = phNoticeLocArray[j];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const phNoticeDetalis = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalPhNoticeLocArray.push(phNoticeDetalis);
          }
        }
        hearingDetailsForm.hearingDetails[i].phNoticeLoc = finalPhNoticeLocArray;

        // Manipulation For addSubLoc
        const addSubLocArray = hearingDetailsForm.hearingDetails[i].addSubLoc;
        let finalAddSubLocArray: any = [];

        for (let k = 0; k < addSubLocArray.length; k++) {
          const element = addSubLocArray[k];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const addSubDetalis = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalAddSubLocArray.push(addSubDetalis);
          }
        }
        hearingDetailsForm.hearingDetails[i].addSubLoc = finalAddSubLocArray;

        // Manipulation For phRecordIfAnyLoc
        const phRecordIfAnyLocArray = hearingDetailsForm.hearingDetails[i].phRecordIfAnyLoc;
        let finalPhRecordIfAnyLocArray: any = [];

        for (let l = 0; l < phRecordIfAnyLocArray.length; l++) {
          const element = phRecordIfAnyLocArray[l];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const phRecordIfAnyDetalis = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalPhRecordIfAnyLocArray.push(phRecordIfAnyDetalis);
          }
        }
        hearingDetailsForm.hearingDetails[i].phRecordIfAnyLoc = finalPhRecordIfAnyLocArray;

         // Manipulation For phAdjournmentLetterLoc
         const phAdjournmentLetterLocArray = hearingDetailsForm.hearingDetails[i].phAdjournmentLetterLoc;
         let finalPhAdjournmentLetterLocArray: any = [];
 
         for (let l = 0; l < phAdjournmentLetterLocArray.length; l++) {
           const element = phAdjournmentLetterLocArray[l];
           if (element.fileLoc != '' && element.fileLoc != null) {
             const phAdjournmentLetterLocDetalis = {
               fileLoc: element.fileLoc,
               remarks: element.remarks,
             }
             finalPhAdjournmentLetterLocArray.push(phAdjournmentLetterLocDetalis);
           }
         }
         hearingDetailsForm.hearingDetails[i].phAdjournmentLetterLoc = finalPhAdjournmentLetterLocArray;

        if ((!checkHearingDetailsNotEmpty) && (singleHearingDetails.phScheduledDate != null || singleHearingDetails.attended != null || singleHearingDetails.consultant != null || singleHearingDetails.addWrittenSubDate != null || singleHearingDetails.phDiscNotes != null || singleHearingDetails.phNoticeLoc.length > 0 || singleHearingDetails.addSubLoc.length > 0 || singleHearingDetails.phRecordIfAnyLoc.length > 0 || singleHearingDetails.phAdjournmentLetterLoc.length > 0)) {
          checkHearingDetailsNotEmpty = true;
        }
      }
      if (checkHearingDetailsNotEmpty) {
        finalPayload.hearingDetails = hearingDetailsForm.hearingDetails;
      }

      // Preparation For summon Details 
      const summonDetailsForm = this.summonForm.value;
      let checkSummonDetailsNotEmpty = false;
      for (let i = 0; i < summonDetailsForm.summonDetails.length; i++) {
        const singleSummonDetails = summonDetailsForm.summonDetails[i];
        // Manipulation For summonNoticeLoc
        const summonNoticeLocArray = summonDetailsForm.summonDetails[i].summonNoticeLoc;
        let finalSummonNoticeLocArray: any = [];

        for (let j = 0; j < summonNoticeLocArray.length; j++) {
          const element = summonNoticeLocArray[j];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const summonNoticeLoc = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalSummonNoticeLocArray.push(summonNoticeLoc);
          }
        }
        summonDetailsForm.summonDetails[i].summonNoticeLoc = finalSummonNoticeLocArray;

        // Manipulation For addSubLoc
        const addSubLocArray = summonDetailsForm.summonDetails[i].addSubLoc;
        let finalAddSubLocArray: any = [];

        for (let k = 0; k < addSubLocArray.length; k++) {
          const element = addSubLocArray[k];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const addSubDetalis = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalAddSubLocArray.push(addSubDetalis);
          }
        }
        summonDetailsForm.summonDetails[i].addSubLoc = finalAddSubLocArray;

        // Manipulation For deptSummonReceivedLoc
        const deptSummonReceivedLocArray = summonDetailsForm.summonDetails[i].deptSummonReceivedLoc;
        let finalDptSummonReceivedLocArray: any = [];

        for (let l = 0; l < deptSummonReceivedLocArray.length; l++) {
          const element = deptSummonReceivedLocArray[l];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const deptSummonReceivedLoc = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalDptSummonReceivedLocArray.push(deptSummonReceivedLoc);
          }
        }
        summonDetailsForm.summonDetails[i].deptSummonReceivedLoc = finalDptSummonReceivedLocArray;

        if ((!checkSummonDetailsNotEmpty) && (singleSummonDetails.summonDate != null || singleSummonDetails.attended != null || singleSummonDetails.addWritSubDate != null || singleSummonDetails.addWrittenSubDate != null || singleSummonDetails.discNotes != null || singleSummonDetails.summonNoticeLoc.length > 0 || singleSummonDetails.addSubLoc.length > 0 || singleSummonDetails.deptSummonReceivedLoc.length > 0)) {
          checkSummonDetailsNotEmpty = true;
        }
      }
      if (checkSummonDetailsNotEmpty) {
        finalPayload.summonDetails = summonDetailsForm.summonDetails;
      }

      // Preparation For CC
      const ccForm = this.consultantForm.value;
      let checkCCFormNotEmpty = false;
      for (let i = 0; i < ccForm.cc.length; i++) {
        const singleCcForm = ccForm.cc[i];
        let supportingDocsArray: any = [];
        for (let j = 0; j < singleCcForm.supportingDocsLoc.length; j++) {
          const element = singleCcForm.supportingDocsLoc[j];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const supportingDocDetails = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            supportingDocsArray.push(supportingDocDetails);
          }
        }

        singleCcForm.supportingDocsLoc = supportingDocsArray;
        if ((!checkCCFormNotEmpty) && (singleCcForm.firm != null || singleCcForm.consultant != null || singleCcForm.contact != null || singleCcForm.natureOfWork != null || singleCcForm.email != null || singleCcForm.supportingDocsLoc.length > 0)) {
          checkCCFormNotEmpty = true;
        }
      }
      if (checkCCFormNotEmpty) {
        finalPayload.cc = ccForm.cc;
      }

      // Preparation For libDetails
      const libDetails1Form = this.taxPaymentRefundDetailsForm.value;
      let checkLibDetailsFormNotEmpty = false;
      for (let i = 0; i < libDetails1Form.libDetails.length; i++) {
        const singleLibDetails1Form = libDetails1Form.libDetails[i];
        // Manipulation For challanCopyLoc
        const challanCopyLocArray = libDetails1Form.libDetails[i].challanCopyLoc;
        let finalchallanCopyLocArray: any = [];
        for (let j = 0; j < challanCopyLocArray.length; j++) {
          const element = challanCopyLocArray[j];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const supportingDocDetails = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            finalchallanCopyLocArray.push(supportingDocDetails);
          }
        }
        libDetails1Form.libDetails[i].challanCopyLoc = finalchallanCopyLocArray;
        if ((!checkLibDetailsFormNotEmpty) && (singleLibDetails1Form.cinRefNo != null || singleLibDetails1Form.date != null || singleLibDetails1Form.bsrCode != null || singleLibDetails1Form.tax != null || singleLibDetails1Form.interest != null || singleLibDetails1Form.penalty != null || singleLibDetails1Form.total != null || singleLibDetails1Form.challanCopyLoc.length > 0)) {
          checkLibDetailsFormNotEmpty = true;
        }
      }
      if (checkLibDetailsFormNotEmpty) {
        finalPayload.libDetails = libDetails1Form.libDetails;
      }

      // Preparation For refundDetails
      const refundDetails1Form = this.taxPaymentRefundDetailsForm.value;
      let checkrefundDetails1FormNotEmpty = false;
      for (let i = 0; i < refundDetails1Form.refundDetails.length; i++) {
        const singleRefundDetails1Form = refundDetails1Form.refundDetails[i];

        if ((!checkrefundDetails1FormNotEmpty) && (singleRefundDetails1Form.receiptDate != null || singleRefundDetails1Form.mode != null || singleRefundDetails1Form.tax != null || singleRefundDetails1Form.interest != null || singleRefundDetails1Form.total != null)) {
          checkrefundDetails1FormNotEmpty = true;
        }
      }
      if (checkrefundDetails1FormNotEmpty) {
        finalPayload.refundDetails = refundDetails1Form.refundDetails;
      }

      // Preparation For orderDetails
      const orderDetailsForm = this.orderForm.value;
      for (let i = 0; i < orderDetailsForm.length; i++) {
        const orderDetailsArray = orderDetailsForm[i];

        // Manipulation For orderLoc
        let orderLocArray: any = [];
        for (let l = 0; l < orderDetailsArray.orderLoc.length; l++) {
          const element = orderDetailsArray.orderLoc[l];
          if (element.fileLoc != '' && element.fileLoc != null) {
            const orderLocData = {
              fileLoc: element.fileLoc,
              remarks: element.remarks,
            }
            orderLocArray.push(orderLocData);
          }
        }
        orderDetailsArray.orderLoc = orderLocArray;

        // Manupulation For rectifDetails
        for (let j = 0; j < orderDetailsArray.rectifDetails.length; j++) {
          const rectifDetailsObj = orderDetailsArray.rectifDetails[j];

          // Manipulation For appCopyLoc
          let appCopyLocArray: any = [];
          for (let k = 0; k < rectifDetailsObj.appCopyLoc.length; k++) {
            const element = rectifDetailsObj.appCopyLoc[k];
            if (element.fileLoc != '' && element.fileLoc != null) {
              const appCopyLocData = {
                fileLoc: element.fileLoc,
                remarks: element.remarks,
              }
              appCopyLocArray.push(appCopyLocData);
            }
          }
          rectifDetailsObj.appCopyLoc = appCopyLocArray;
        }

        // Manupulation For rectifDetails
        for (let j = 0; j < orderDetailsArray.rectifDetails.length; j++) {
          const rectifDetailsObj = orderDetailsArray.rectifDetails[j];

          // Manipulation For ackCopyLoc
          let ackCopyLocArray: any = [];
          for (let k = 0; k < rectifDetailsObj.ackCopyLoc.length; k++) {
            const element = rectifDetailsObj.ackCopyLoc[k];
            if (element.fileLoc != '' && element.fileLoc != null) {
              const ackCopyLocData = {
                fileLoc: element.fileLoc,
                remarks: element.remarks,
              }
              ackCopyLocArray.push(ackCopyLocData);
            }
          }
          rectifDetailsObj.ackCopyLoc = ackCopyLocArray;
        }
      }

      let checkOrderDetailsIsEmpty = false;
      for (let k = 0; k < orderDetailsForm.orderDetails.length; k++) {
        const element = orderDetailsForm.orderDetails[k];

        if (orderDetailsForm.orderDetails.length === 1 && (element.orderUs === null && element.orderNo === null && element.orderDate === null && element.orderRecepitDate === null && element.orderReceiptMode === null && element.orderLoc.length === 0)) {
          checkOrderDetailsIsEmpty = true;
        } else {

          if (!element.orderReceiptMode || element.orderReceiptMode === null) {
            delete element.orderReceiptMode;
          }

          if (!element.orderPassedBy || element.orderPassedBy === null) {
            delete element.orderPassedBy;
          }

          if (!element.outcome || element.outcome === null) {
            delete element.outcome;
          }

          if (!element.orderSummary || element.orderSummary === null) {
            delete element.orderSummary;
          }

          if (!element.internalRemarks || element.internalRemarks === null) {
            delete element.internalRemarks;
          }

          if (!element.isAppliedForRectif || element.isAppliedForRectif === null) {
            delete element.isAppliedForRectif;
          }
        }

      }
      if (!checkOrderDetailsIsEmpty) {
        finalPayload.orderDetails = orderDetailsForm.orderDetails;
      }


      //  appealDetails data  
      let appealDetailsData = this.orderForm.get('appealDetails') as UntypedFormArray;
      let appealDetailsDetalis: any = {};
      let appealDetailsArray: any = [];

      appealDetailsData.controls.forEach(element => {
        appealDetailsDetalis = {
          action: element.value.action,
          forum: element.value.forum,
          isCaseCreated: element.value.isCaseCreated,
        }
        appealDetailsArray.push(appealDetailsDetalis);
      });
      if (this.orderForm.controls.appealDetails.value[0].action != null) {
        finalPayload.appealDetails = appealDetailsDetalis;
      }


      finalPayload.phase = phaseTab;
      finalPayload.action = 'UPDATE';
      finalPayload.caseType = caseOption;
      finalPayload.pan = this.generalInfo.controls.pan.value;
      finalPayload.panName = this.generalInfo.controls.panName.value;
      finalPayload.ay = this.generalInfo.controls.ay.value;
      finalPayload.caseId = this.generalInfo.controls.caseId.value;
      finalPayload.finYear = this.generalInfo.controls.finYear.value;
      finalPayload.consultant = this.generalInfo.controls.consultant.value;
      finalPayload.personResp = this.generalInfo.controls.personResp.value;
      finalPayload.caseSummary = this.generalInfo.controls.caseSummary.value;
      finalPayload.internalRemarks1 = this.generalInfo.controls.internalRemarks1.value;
      finalPayload.caseLabel = this.generalInfo.controls.caseLabel.value;
      finalPayload.status = this.generalInfo.controls.status.value;
      finalPayload.writToHc = this.writToHcForm.controls.writToHc.value;
      finalPayload.prevStage = this.caseData.prevStage;
      finalPayload.whetherScnIssued = this.orderForm.controls.whetherScnIssued.value;
      finalPayload.scnDate = this.orderForm.controls.scnDate.value;
      finalPayload.scnDin = this.orderForm.controls.scnDin.value;
      finalPayload.scnReceivedDate = this.orderForm.controls.scnReceivedDate.value;
      finalPayload.scnReplyDueDate = this.orderForm.controls.scnReplyDueDate.value;
      finalPayload.isDemandRaisedOrReduced3 = this.orderForm.controls.isDemandRaisedOrReduced3.value;
      finalPayload.demandAmt3 = this.orderForm.controls.demandAmt3.value;
      finalPayload.isRightsTransToAO = this.orderForm.controls.isRightsTransToAO.value;
      finalPayload.whetherOrderReceived = this.orderReceivedForm.controls.whetherOrderReceived.value;
      console.log(finalPayload, "finalPayload");
      this.taxService.updateAssessment(finalPayload).subscribe((response: any) => {
        this.formatTableValues();
        if (response.status === 1) {
          if (phaseTab === 1) {
            if (this.writToHcForm.value.writToHc === true || this.writToHcForm.value.writToHc === 'true') {
              this.moveToHC.emit('');
              this.isPhase2 = false;
              this.disablePhase2 = false
            } else {
              this.isPhase2 = true;
              this.tab5Disable = true;
            }
          } else if (phaseTab === 2) {
            this.whetherOrderReceivedChanged();
          } else if (phaseTab === 3) {
            const appealDetails = this.orderForm.controls.appealDetails as UntypedFormArray;
            for (let index = 0; index < appealDetails.controls.length; index++) {
              const element: any = appealDetails.controls[index]
              if (element.controls.action.value == 'AP') {
                this.callTabFunction.emit('');
              } else if (element.controls.action.value == 'WP') {
                this.moveToHC.emit('');
              }
              // else if (element.controls.action.value == 'CLOSED') {

              // }
            }
          }
          this.toaster.showSuccess(response.message);
        } else {
          this.toaster.showSuccess(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }


  /*  fetch api*/
  initializeData() {
    const userMailID: any = sessionStorage.getItem('UserId');
        const matchingConsultant = this.consultantList.find(
          (consultant: any) => consultant.email === userMailID
        );

        if (matchingConsultant) {
          const hearingDetailsArray = this.phDivArr();
          hearingDetailsArray.forEach((hearingGroup: UntypedFormGroup) => {
          if (hearingGroup && hearingGroup.controls['consultant']) {
          // Patch the consultant value
          hearingGroup.controls['consultant'].patchValue(matchingConsultant.consultantName);
          console.log(`Patched Consultant: ${hearingGroup.controls['consultant'].value}`);
        }
      });
        }


    if (this.currentTab != this.activeTab) {
      this.enablePrevStageTab.emit(this.activeStages);
    }
    if (this.savedCaseId) {
      this.caseId = this.savedCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        caseId: this.caseId,
      }
      this.taxService.getGetAssessment(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.caseData = response.response;
          this.caseType = this.caseData.caseType;
          this.cumulativeData = response.response.cumulativeTotals;
          if(this.cumulativeData.length > 0){
            this.cumulativeData[1].tax += this.cumulativeData[0].tax;
            this.cumulativeData[1].interest += this.cumulativeData[0].interest;
            this.cumulativeData[1].total += this.cumulativeData[0].total;
            this.cumulativeData.shift();
          }
          const indexToUpdate = this.cumulativeData.findIndex((obj: { label: string; }) => obj.label === "Cumulative payment made for admitted liability");
          if (indexToUpdate !== -1) {
                this.cumulativeData[indexToUpdate].label = "Cumulative payment made for assessed tax";
          }
          if (this.currentTab === this.activeTab) {
            this.enablePrevStageTab.emit(response.response?.prevStages);
          }
          this.prevStage = this.caseData.prevStage;
          this.formInit();
          this.whetherOrderReceivedChanged();
          this.whetherWritToHCChange();
          if (this.caseData.itrDetails && this.caseData.itrDetails.length) {
            this.caseData.itrDetails.forEach((itrDetails: any) => {
              itrDetails.totTaxIncome = this.inrService.formatCurrency(String(itrDetails.totTaxIncome))
              itrDetails.totTaxLiability = this.inrService.formatCurrency(String(itrDetails.totTaxLiability))
              itrDetails.totTaxPaid = this.inrService.formatCurrency(String(itrDetails.totTaxPaid))
              itrDetails.advanceTax = this.inrService.formatCurrency(String(itrDetails.advanceTax))
              itrDetails.tdsAmount = this.inrService.formatCurrency(String(itrDetails.tdsAmount))
              itrDetails.tcsAmount = this.inrService.formatCurrency(String(itrDetails.tcsAmount))
              itrDetails.selfAssessmentAmount = this.inrService.formatCurrency(String(itrDetails.selfAssessmentAmount))
              itrDetails.refundDemand = this.inrService.formatCurrency(String(itrDetails.refundDemand))
            })
          }
          if (this.caseData.cc && this.caseData.cc.length) {
            this.caseData.cc.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees))
            })
          }

          if (this.caseData?.dtNotices) {
            this.caseData.dtNotices.forEach((dtNotices: any) => {
              if (dtNotices?.issues) {
                dtNotices.issues.forEach((issue: any) => {
                  issue.addnOrDisOfExpensesF = this.inrService.formatCurrency(String(issue.addnOrDisOfExpensesF))
                  // issue.taxRateF = (String(issue.taxRateF))
                  issue.taxImpactF = this.inrService.formatCurrency(String(issue.taxImpactF))
                  issue.interestF = this.inrService.formatCurrency(String(issue.interestF))
                  issue.penaltyF = this.inrService.formatCurrency(String(issue.penaltyF))
                  issue.totalF = this.inrService.formatCurrency(String(issue.totalF))
                  issue.addnOrDisOfExpensesL = this.inrService.formatCurrency(String(issue.addnOrDisOfExpensesL))
                  // issue.taxRateL = (String(issue.taxRateL))
                  issue.taxImpactL = this.inrService.formatCurrency(String(issue.taxImpactL))
                  issue.interestL = this.inrService.formatCurrency(String(issue.interestL))
                  issue.penaltyL = this.inrService.formatCurrency(String(issue.penaltyL))
                  issue.totalL = this.inrService.formatCurrency(String(issue.totalL))
                  if (issue.provAndLibDetails && issue.provAndLibDetails.length) {
                    issue.provAndLibDetails.forEach((details: any) => {
                      details.provAmtF = this.inrService.formatCurrency(String(details.provAmtF))
                      details.contLibAmtF = this.inrService.formatCurrency(String(details.contLibAmtF))
                      details.othLibF = this.inrService.formatCurrency(String(details.othLibF))
                      details.caroAmtF = this.inrService.formatCurrency(String(details.caroAmtF))
                    })
                  }
                })
              }
            })
          }
          this.isVisibleCaseId = true;
          if (this.caseData.dtNotices && this.caseData.dtNotices.length > 0) {
            this.initializeItemList();
          } else {
            this.addNotice();
          }
          // this.initializeItemList();
          if (this.caseData.itrDetails && this.caseData.itrDetails.length > 0) {
            this.initializeItrDetails(this.caseData);
          } else {
            this.addItrDetails();
          }
          if (this.caseData.cc && this.caseData.cc.length > 0) {
            this.initializeConsultantCharges();
          } else {
            this.addConsultantInv();
          }
          if (this.caseData.hearingDetails && this.caseData.hearingDetails.length > 0) {
            this.initializeHearingDetails();
          } else {
            this.createAdjDiv();
          }
          if (this.caseData.summonDetails && this.caseData.summonDetails.length > 0) {
            this.initializeSummonDetails();
          } else {
            this.addSummon();
          }
          if (this.caseData.libDetails && this.caseData.libDetails.length > 0) {
            this.initializeLibDetails(this.caseData);
          } else {
            this.addLibDetails();
          }

          if (this.caseData.refundDetails && this.caseData.refundDetails.length > 0) {
            this.initializeRefundDetails();
          } else {
            this.addRefundDetails();
          }
          if (this.caseData.appealDetails) {
            this.initializeAppealDetails();
          } else {
            this.addAppealDetail();
          }
          if (this.caseData.orderDetails && this.caseData.orderDetails.length > 0) {
            this.initializeRectifDetails(this.caseData);
          } else {
            this.addOrderDetails();
          }

        }
      });
    }
  }

  initializeItemList() {
    this.caseData.dtNotices.forEach((letter: any) => {
      if (!letter.meetingDetails && !letter.submissions && !letter.reqDetails) {
        letter.meetingDetails = [];
        letter.submissions = [];
        letter.reqDetails = [];
      }
      let noticesListGrp: UntypedFormGroup = this.createNotice(letter);
      (this.noticeForm.get('dtNotices') as UntypedFormArray).push(noticesListGrp);
      if (letter.adjSubDetails && letter.adjSubDetails.length > 0) {
        letter.adjSubDetails.forEach((adjSub: any) => {
          let adjSubDetailsFrmGrp: UntypedFormGroup = this.initAjaSubDetailsFrmGrp(adjSub);
          (noticesListGrp.get('adjSubDetails') as UntypedFormArray).push(adjSubDetailsFrmGrp);
          if (adjSub.adjDocsLoc) {
            adjSub.adjDocsLoc.forEach((docSub: any) => {
              let adjDocsLocGrp: UntypedFormGroup = this.createAdjDocDetails(docSub);
              (adjSubDetailsFrmGrp.get('adjDocsLoc') as UntypedFormArray).push(adjDocsLocGrp);
            });
            const leftAckCopyLoc = 5 - (adjSubDetailsFrmGrp.get('adjDocsLoc') as UntypedFormArray).length;
            for (let i = 0; i < leftAckCopyLoc; i++) {
              (adjSubDetailsFrmGrp.get('adjDocsLoc') as UntypedFormArray).push(this.createAdjDocDetails());
            }
          }
        });
      }
      if (letter.reqDetails && letter.reqDetails.length > 0) {
        letter.reqDetails.forEach((req: any) => {
          let reqDetailsFrmGrp: UntypedFormGroup = this.initreqDetailsFrmGrp(req);
          (noticesListGrp.get('reqDetails') as UntypedFormArray).push(reqDetailsFrmGrp);
        });
      } else {
        (noticesListGrp.get('reqDetails') as UntypedFormArray).push(this.initreqDetailsFrmGrp());
      }
      if (letter.issues && letter.issues.length > 0) {
        letter.issues.forEach((issue: any) => {
          let issuesFrmGrp: UntypedFormGroup = this.createIssueInvArray(issue);
          (noticesListGrp.get('issues') as UntypedFormArray).push(issuesFrmGrp);
        });
      }
      // else{
      //  (noticesListGrp.get('issues') as FormArray).push(this.createIssueInvArray());
      // }
      if (letter.noticeLoc) {
        letter.noticeLoc.forEach((not: any) => {
          let noticeLocFrmGrp: UntypedFormGroup = this.createnoticeLoc(not);
          (noticesListGrp.get('noticeLoc') as UntypedFormArray).push(noticeLocFrmGrp);
        });
      }
      if (letter.issuingAuthority && letter.issuingAuthority.length > 0) {
        letter.issuingAuthority.forEach((issuing: any) => {
          let issuingAuthorityFrmGrp: UntypedFormGroup = this.initIssuingAuthorityFrmGrp(issuing);
          (noticesListGrp.get('issuingAuthority') as UntypedFormArray).push(issuingAuthorityFrmGrp);
        });
      }
      if (letter.meetingDetails && letter.meetingDetails.length > 0) {
        letter.meetingDetails.forEach((meeting: any) => {
          let meetingDetailsFrmGrp: UntypedFormGroup = this.detailsOfDepartmentFrmGrp(meeting);
          (noticesListGrp.get('meetingDetails') as UntypedFormArray).push(meetingDetailsFrmGrp);

          if (meeting.copyOfSubmissionLoc) {
            meeting.copyOfSubmissionLoc.forEach((docSub: any) => {
              let copyOfSubmissionLocGrp: UntypedFormGroup = this.createCopyOfSub(docSub);
              (meetingDetailsFrmGrp.get('copyOfSubmissionLoc') as UntypedFormArray).push(copyOfSubmissionLocGrp);
            });
            const leftcopyOfSubmissionLoc = 5 - (meetingDetailsFrmGrp.get('copyOfSubmissionLoc') as UntypedFormArray).length;
            for (let i = 0; i < leftcopyOfSubmissionLoc; i++) {
              (meetingDetailsFrmGrp.get('copyOfSubmissionLoc') as UntypedFormArray).push(this.createCopyOfSub());
            }
          }
        });
      } else {
        (noticesListGrp.get('meetingDetails') as UntypedFormArray).push(this.detailsOfDepartmentFrmGrp());
      }
      if (letter.submissions && letter.submissions.length > 0) {
        letter.submissions.forEach((sub: any) => {
          let submissionsFrmGrp: UntypedFormGroup = this.initSubmissionFrmGrp(sub);
          (noticesListGrp.get('submissions') as UntypedFormArray).push(submissionsFrmGrp);
          if (sub.ackCopyLoc) {
            sub.ackCopyLoc.forEach((docSub: any) => {
              let ackCopyLocGrp: UntypedFormGroup = this.createAckCopyLoc(docSub);
              (submissionsFrmGrp.get('ackCopyLoc') as UntypedFormArray).push(ackCopyLocGrp);
            });

            const leftAckCopyLoc = 5 - (submissionsFrmGrp.get('ackCopyLoc') as UntypedFormArray).length;
            for (let i = 0; i < leftAckCopyLoc; i++) {
              (submissionsFrmGrp.get('ackCopyLoc') as UntypedFormArray).push(this.createAckCopyLoc());
            }
          }
          if (sub.subCopyLoc) {
            sub.subCopyLoc.forEach((docSub: any) => {
              let subCopyGrp: UntypedFormGroup = this.createSubLoc(docSub);
              (submissionsFrmGrp.get('subCopyLoc') as UntypedFormArray).push(subCopyGrp);
            });

            const leftSubCopyLoc = 5 - (submissionsFrmGrp.get('subCopyLoc') as UntypedFormArray).length;
            for (let i = 0; i < leftSubCopyLoc; i++) {
              (submissionsFrmGrp.get('subCopyLoc') as UntypedFormArray).push(this.createSubLoc());
            }
          }

          if (!sub.subDetails) {
            sub.subDetails = [];
          }
          if (sub.subDetails && sub.subDetails.length > 0) {

            sub.subDetails.forEach((adjSub: any) => {
              let subDetailsFrmGep: UntypedFormGroup = this.initsubDetailsFrmGrp(adjSub);
              (submissionsFrmGrp.get('subDetails') as UntypedFormArray).push(subDetailsFrmGep);
              if (adjSub.docLoc) {
                adjSub.docLoc.forEach((docSub: any) => {
                  let docLocGrp: UntypedFormGroup = this.createDocLo(docSub);
                  (subDetailsFrmGep.get('docLoc') as UntypedFormArray).push(docLocGrp);
                });

                const leftSubCopyLoc = 5 - (subDetailsFrmGep.get('docLoc') as UntypedFormArray).length;
                for (let i = 0; i < leftSubCopyLoc; i++) {
                  (subDetailsFrmGep.get('docLoc') as UntypedFormArray).push(this.createDocLo());
                }
              }
            });
          } else {
            (submissionsFrmGrp.get('subDetails') as UntypedFormArray).push(this.initsubDetailsFrmGrp());
          }
        });
      } else {
        if (this.action === 'create') {
          (noticesListGrp.get('submissions') as UntypedFormArray).push(this.initSubmissionFrmGrp());
        }
      }
    });
    this.issueValueChange();
    this.noticeForm.patchValue(this.caseData);
  }


  initializeConsultantCharges() {
    if (this.caseData.cc != undefined) {
      this.caseData.cc.forEach((data: any) => {
        let detailOfConsultant: UntypedFormGroup = this.ConsultantCharges(data);
        (this.consultantForm.get('cc') as UntypedFormArray).push(detailOfConsultant);
        if (data.supportingDocsLoc != undefined) {
          data.supportingDocsLoc.forEach((not: any) => {
            if (data.supportingDocsLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createConsultant(not);
              (detailOfConsultant.get('supportingDocsLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }
      });
      this.feesChargedChanged();
    }
  }

  // hearingDetails functions
  initializeHearingDetails() {
    if (this.caseData.hearingDetails != undefined) {
      this.caseData.hearingDetails.forEach((data: any) => {
        let hearingDetailFrmGrp: UntypedFormGroup = this.newphArray(data);
        (this.hearingForm.get('hearingDetails') as UntypedFormArray).push(hearingDetailFrmGrp);

        if (data.phNoticeLoc != undefined) {
          data.phNoticeLoc.forEach((not: any) => {
            if (data.phNoticeLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createPhNotice(not);
              (hearingDetailFrmGrp.get('phNoticeLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }

        if (data.addSubLoc != undefined) {
          data.addSubLoc.forEach((not: any) => {
            if (data.addSubLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createAddSub(not);
              (hearingDetailFrmGrp.get('addSubLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }

        if (data.phRecordIfAnyLoc != undefined) {
          data.phRecordIfAnyLoc.forEach((not: any) => {
            if (data.phRecordIfAnyLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createPhRecordIfAny(not);
              (hearingDetailFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }
        if (data.phAdjournmentLetterLoc != undefined) {
          data.phAdjournmentLetterLoc.forEach((not: any) => {
            if (data.phAdjournmentLetterLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createPhAdjournmentLetterLoc(not);
              (hearingDetailFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }
      });
    }
  }


  // summonDetails functions
  initializeSummonDetails() {
    if (this.caseData.summonDetails != undefined) {
      this.caseData.summonDetails.forEach((data: any) => {
        let summonDetailsFrmGrp: UntypedFormGroup = this.createSummon(data);
        (this.summonForm.get('summonDetails') as UntypedFormArray).push(summonDetailsFrmGrp);

        if (data.summonNoticeLoc != undefined) {
          data.summonNoticeLoc.forEach((not: any) => {
            if (data.summonNoticeLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createSummonNotice(not);
              (summonDetailsFrmGrp.get('summonNoticeLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }

        if (data.addSubLoc != undefined) {
          data.addSubLoc.forEach((not: any) => {
            if (data.addSubLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createSummonAddSub(not);
              (summonDetailsFrmGrp.get('addSubLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }

        if (data.deptSummonReceivedLoc != undefined) {
          data.deptSummonReceivedLoc.forEach((not: any) => {
            if (data.deptSummonReceivedLoc.length > 0) {
              let detailFrmGrp: UntypedFormGroup = this.createDeptSummonReceived(not);
              (summonDetailsFrmGrp.get('deptSummonReceivedLoc') as UntypedFormArray).push(detailFrmGrp);
            }
          });
        }
      });
    }
  }

  //  initialise itrDetails array
  initializeItrDetails(data: any) {
    if (data.itrDetails != undefined) {
      this.caseData.itrDetails.forEach((details: any) => {
        let itrDetailsFrmGrp = this.createItrDetailsFrmGrp(details);
        (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).push(itrDetailsFrmGrp);
      })
    }
    this.iTRDetailsForm.patchValue(this.caseData);
  }

  //  initialise orderDetails array
  initializeRectifDetails(data: any) {
    if (!data.rectifDetails) {
      data.rectifDetails = [];
    }
    if (data.orderDetails != undefined) {
      data.orderDetails.forEach((data: any) => {
        let rectifDetailsFrmGrp: UntypedFormGroup = this.createOrderDetails(data);
        (this.orderForm.get('orderDetails') as UntypedFormArray).push(rectifDetailsFrmGrp);
        if (data.orderLoc != undefined) {
          data.orderLoc.forEach((data: any) => {
            let orderLocFrmGrp: UntypedFormGroup = this.initOrderLocFrmGrp(data);
            (rectifDetailsFrmGrp.get('orderLoc') as UntypedFormArray).push(orderLocFrmGrp);
          });
        }
        if (data.rectifDetails != undefined) {
          data.rectifDetails.forEach((data: any) => {
            let orderLocFrmGrp: UntypedFormGroup = this.createRectif(data);
            (rectifDetailsFrmGrp.get('rectifDetails') as UntypedFormArray).push(orderLocFrmGrp);
          });
        }
        // else {
        //   (rectifDetailsFrmGrp.get('rectifDetails') as FormArray).push(this.createRectif());
        // }
      });
    }
  }

  //  initialise libDetails array
  initializeLibDetails(data: any) {
    if (data.libDetails != undefined) {
      data.libDetails.forEach((data: any) => {
        let libDetails1FrmGrp: UntypedFormGroup = this.createLibDetailsFrmGrp(data);
        (this.taxPaymentRefundDetailsForm.get('libDetails') as UntypedFormArray).push(libDetails1FrmGrp);
        if (data.challanCopyLoc != undefined) {
          data.challanCopyLoc.forEach((locList: any) => {
            if (data.challanCopyLoc.length > 0) {
              let challanCopyLFrmGrp: UntypedFormGroup = this.initChallanFrmGrp(locList);
              (libDetails1FrmGrp.get('challanCopyLoc') as UntypedFormArray).push(challanCopyLFrmGrp);
            }
          });
        }
      });
    }
    this.taxPaymentRefundDetailsForm.patchValue(data);
    this.liabilityAddmittedAndPaidChanged();
  }

  //  initialise refundDetails array
  initializeRefundDetails() {
    if (this.caseData.refundDetails != undefined) {
      this.caseData.refundDetails.forEach((data: any) => {
        let refundDetailsFrmGrp = this.createRefundDetailsFrmGrp(data);
        (this.taxPaymentRefundDetailsForm.get('refundDetails') as UntypedFormArray).push(refundDetailsFrmGrp);
      })
    }
    this.refundDetailsChanged();
  }

  //appealDetails functions
  initializeAppealDetails() {
    if (this.caseData.appealDetails != undefined) {
      let appealDetailFrmGrp = this.appealDetailFrmGrp(this.caseData.appealDetails);
      (this.orderForm.get('appealDetails') as UntypedFormArray).push(appealDetailFrmGrp);
    }
  }

  updateIssues() {
    (this.noticeForm.get('dtNotices') as UntypedFormArray).controls.forEach((c) => {
      (c.get('issues') as UntypedFormArray).controls.forEach((c) => {
        c.patchValue({ addnOrDisOfExpensesL: c.value.addnOrDisOfExpensesF })
        c.patchValue({ taxRateL: c.value.taxRateF })
        c.patchValue({ taxImpactL: c.value.taxImpactF })
        c.patchValue({ interestL: c.value.interestF })
        c.patchValue({ penaltyL: c.value.penaltyF })
        c.patchValue({ totalL: c.value.totalF })
      })
    })
  }

  refundDetailsChanged() {
    let taxTotal = 0;
    let interestTotal = 0;
    let refundPaidTotal = 0;

    (this.taxPaymentRefundDetailsForm.get('refundDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const tax = this.inrService.removeCommasAndParseNumber(c.value.tax);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);

      taxTotal += Number(tax);
      interestTotal += Number(interest);

      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(tax)) + (+Number(interest)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      refundPaidTotal += Number(total);
    })

    this.refundPaidTaxTotal = taxTotal;
    this.refundPaidInterestTotal = interestTotal;
    this.refundPaidTotal = refundPaidTotal;
  }

  liabilityAddmittedAndPaidChanged() {
    let taxTotal = 0;
    let interestTotal = 0;
    let liabilityPaidTotal = 0;

    (this.taxPaymentRefundDetailsForm.get('libDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const tax = this.inrService.removeCommasAndParseNumber(c.value.tax);
      const interest = this.inrService.removeCommasAndParseNumber(c.value.interest);

      taxTotal += Number(tax);
      interestTotal += Number(interest);

      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(tax)) + (+Number(interest)))) }, { emitEvent: false, onlySelf: true });
      const total = this.inrService.removeCommasAndParseNumber(c.value.total);
      liabilityPaidTotal += Number(total);
    })
    this.liabilityAddmittedAndPaidTaxTotal = taxTotal;
    this.liabilityAddmittedAndPaidInterestTotal = interestTotal;
    this.liabilityAddmittedAndPaidTotal = liabilityPaidTotal;
  }

  itrDetailsPaidChanged() {
    let totTaxLiabilityTotal = 0;
    let totTaxPaidTotal = 0;
    let totAdvanceTax = 0;
    let totTdsAmountTotal = 0;
    let totTcsAmountTotal = 0;
    let totSelfAssessmentAmountTotal = 0;
    let refundDemandTotal = 0;

    (this.iTRDetailsForm.get('itrDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const totTaxLiability = this.inrService.removeCommasAndParseNumber(c.value.totTaxLiability);
      const totTaxPaid = this.inrService.removeCommasAndParseNumber(c.value.totTaxPaid);
      const advanceTax = this.inrService.removeCommasAndParseNumber(c.value.advanceTax);
      const tdsAmount = this.inrService.removeCommasAndParseNumber(c.value.tdsAmount);
      const tcsAmount = this.inrService.removeCommasAndParseNumber(c.value.tcsAmount);
      const selfAssessmentAmount = this.inrService.removeCommasAndParseNumber(c.value.selfAssessmentAmount);
      const refundDemand = this.inrService.removeCommasAndParseNumber(c.value.refundDemand);

      totTaxLiabilityTotal += Number(totTaxLiability);
      totAdvanceTax += Number(advanceTax);
      totTdsAmountTotal += Number(tdsAmount);
      totTcsAmountTotal += Number(tcsAmount);
      totSelfAssessmentAmountTotal += Number(selfAssessmentAmount);
      totTaxPaidTotal += Number(totTaxPaid);
      refundDemandTotal += Number(refundDemand);

      c.patchValue({ totTaxPaid: this.inrService.formatCurrency(String((+Number(advanceTax)) + (+Number(tdsAmount)) + (+Number(tcsAmount)) + (+Number(selfAssessmentAmount)) )) });
      totTaxPaidTotal += Number(this.inrService.removeCommasAndParseNumber(c.value.totTaxPaid));

      c.patchValue({ refundDemand: this.inrService.formatCurrency(String((+Number(totTaxLiability)) - (+Number(advanceTax)) - (+Number(tdsAmount)) - (+Number(tcsAmount)) - (+Number(selfAssessmentAmount) ))) });

    })
  }

  issueValueChange() {

    let addnOrDisOfExpensesLTotal = 0;
    let taxRateLTotal = 0;
    let taxImpactLTotal = 0;
    let interestLTotal = 0
    let penaltyLTotal = 0;

    (this.noticeForm.get('dtNotices') as UntypedFormArray).controls.forEach((item: any) => {
      let totOfAddnOrDisOfExpensesF: number = 0;
      let taxRateFTotal: number = 0;
      let taxImpactFTotal: number = 0;
      let interestFTotal: number = 0;
      let penaltyFTotal: number = 0;

      (item.get('issues') as UntypedFormArray).controls.forEach((c) => {
        const addnOrDisOfExpensesF = this.inrService.removeCommasAndParseNumber(c.value.addnOrDisOfExpensesF);
        const taxRateF = (c.value.taxRateF);
        const taxImpactF = this.inrService.removeCommasAndParseNumber(c.value.taxImpactF);
        const interestF = this.inrService.removeCommasAndParseNumber(c.value.interestF);
        const penaltyF = this.inrService.removeCommasAndParseNumber(c.value.penaltyF);

        const addnOrDisOfExpensesL = this.inrService.removeCommasAndParseNumber(c.value.addnOrDisOfExpensesL);
        const taxRateL = (c.value.taxRateL);
        const taxImpactL = this.inrService.removeCommasAndParseNumber(c.value.taxImpactL);
        const interestL = this.inrService.removeCommasAndParseNumber(c.value.interestL);
        const penaltyL = this.inrService.removeCommasAndParseNumber(c.value.penaltyL);

        totOfAddnOrDisOfExpensesF += Number(addnOrDisOfExpensesF);
        taxRateFTotal += Number(taxRateF);  //ph-1
        taxImpactFTotal += Number(taxImpactF);  //ph-1
        interestFTotal += Number(interestF);
        penaltyFTotal += Number(penaltyF);

        taxImpactLTotal += Number(taxImpactL);
        interestLTotal += Number(interestL); //ph-3
        penaltyLTotal += Number(penaltyL);
        taxRateLTotal += Number(taxRateL);
        addnOrDisOfExpensesLTotal += Number(addnOrDisOfExpensesL);

        // c.patchValue({ taxImpactF: this.inrService.formatCurrency((Number(addnOrDisOfExpensesF) / 100) * Number(taxRateF)) });
        // taxImpactFTotal += Number(this.inrService.removeCommasAndParseNumber(c.value.taxImpactF));

        c.patchValue({
          totalF: this.inrService.formatCurrency(String(Number(this.inrService.removeCommasAndParseNumber(c.value.taxImpactF)) + Number(interestF) + Number(penaltyF))),
        });

        // c.patchValue({ taxImpactL: this.inrService.formatCurrency((Number(addnOrDisOfExpensesL) / 100) * Number(taxRateL)) });
        // taxImpactLTotal += Number(this.inrService.removeCommasAndParseNumber(c.value.taxImpactL));

        c.patchValue({
          totalL: this.inrService.formatCurrency(String(Number(this.inrService.removeCommasAndParseNumber(c.value.taxImpactL)) + Number(interestL) + Number(penaltyL))),
        });
      });

      item.controls['totOfAddnOrDisOfExpensesF'].setValue(this.inrService.formatCurrency(String(totOfAddnOrDisOfExpensesF)));
      item.controls['taxImpactFTotal'].setValue(this.inrService.formatCurrency(String(taxImpactFTotal)));
      item.controls['interestFTotal'].setValue(this.inrService.formatCurrency(String(interestFTotal)));
      item.controls['penaltyFTotal'].setValue(this.inrService.formatCurrency(String(penaltyFTotal)));

      item.controls['totOfTotal'].setValue(this.inrService.formatCurrency(String(Number(taxImpactFTotal) + Number(interestFTotal) + Number(penaltyFTotal))));
    });

    this.orderForm.controls['taxImpactLTotal'].setValue(this.inrService.formatCurrency(String(taxImpactLTotal)));
    this.orderForm.controls['interestLTotal'].setValue(this.inrService.formatCurrency(String(interestLTotal)));
    this.orderForm.controls['penaltyLTotal'].setValue(this.inrService.formatCurrency(String(penaltyLTotal)));
    this.orderForm.controls['addnOrDisOfExpensesLTotal'].setValue(this.inrService.formatCurrency(String(addnOrDisOfExpensesLTotal)));
    this.orderForm.controls['totalLTotal'].setValue(this.inrService.formatCurrency(String(Number((+taxImpactLTotal) + (+interestLTotal) + (+penaltyLTotal)))));

  }

  formatTableValues() {
    const issues1Array = this.noticeForm.controls.dtNotices as UntypedFormArray
    const itrValueArray = this.iTRDetailsForm.controls.itrDetails as UntypedFormArray;
    const libDetails1Array = this.taxPaymentRefundDetailsForm.controls.libDetails as UntypedFormArray
    const refundDetails1Array = this.taxPaymentRefundDetailsForm.controls.refundDetails as UntypedFormArray
    const consultantChargesArray = this.consultantForm.controls.cc as UntypedFormArray

    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (var index = 0; index < consultantChargesArray.controls.length; index++) {
        const consultantChargesControl = consultantChargesArray.controls[index] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(this.inrService.formatCurrency(consultantChargesControl.value.fees))
      }
    }

    if (itrValueArray && itrValueArray.controls.length) {
      for (var index = 0; index < itrValueArray.controls.length; index++) {
        const itrControl = itrValueArray.controls[index] as UntypedFormGroup;
        itrControl.controls['totTaxIncome'].setValue(this.inrService.formatCurrency(itrControl.value.totTaxIncome))
        itrControl.controls['totTaxLiability'].setValue(this.inrService.formatCurrency(itrControl.value.totTaxLiability))
        itrControl.controls['totTaxPaid'].setValue(this.inrService.formatCurrency(itrControl.value.totTaxPaid))
        itrControl.controls['advanceTax'].setValue(this.inrService.formatCurrency(itrControl.value.advanceTax))
        itrControl.controls['tdsAmount'].setValue(this.inrService.formatCurrency(itrControl.value.tdsAmount))
        itrControl.controls['tcsAmount'].setValue(this.inrService.formatCurrency(itrControl.value.tcsAmount))
        itrControl.controls['selfAssessmentAmount'].setValue(this.inrService.formatCurrency(itrControl.value.selfAssessmentAmount))
        itrControl.controls['refundDemand'].setValue(this.inrService.formatCurrency(itrControl.value.refundDemand))
      }
    }

    if (issues1Array.controls && issues1Array.controls.length) {
      issues1Array.controls.forEach((control: any) => {
        if (control.value && control.value.issues && control.value.issues.length) {
          control.value.issues.forEach((issue: any) => {
            issue.addnOrDisOfExpensesF = this.inrService.formatCurrency(issue.addnOrDisOfExpensesF)
            issue.taxRateF = (issue.taxRateF)
            issue.taxImpactF = this.inrService.formatCurrency(issue.taxImpactF)
            issue.interestF = this.inrService.formatCurrency(issue.interestF)
            issue.penaltyF = this.inrService.formatCurrency(issue.penaltyF)
            issue.totalF = this.inrService.formatCurrency(issue.totalF)
            issue.addnOrDisOfExpensesL = this.inrService.formatCurrency(issue.addnOrDisOfExpensesL)
            issue.taxRateL = (issue.taxRateL)
            issue.taxImpactL = this.inrService.formatCurrency(issue.taxImpactL)
            issue.interestL = this.inrService.formatCurrency(issue.interestL)
            issue.penaltyL = this.inrService.formatCurrency(issue.penaltyL)
            issue.totalL = this.inrService.formatCurrency(issue.totalL)
          })
        }
      })
      this.issueValueChange();
    }

    if (libDetails1Array.controls.length > 0) {
      for (let index = 0; index < libDetails1Array.controls.length; index++) {
        const libDetails1Control = libDetails1Array.controls[index] as UntypedFormGroup;
        libDetails1Control.controls['tax'].setValue(this.inrService.formatCurrency(libDetails1Control.value.tax))
        libDetails1Control.controls['interest'].setValue(this.inrService.formatCurrency(libDetails1Control.value.interest))
        libDetails1Control.controls['total'].setValue(this.inrService.formatCurrency(libDetails1Control.value.total))
      }
    }

    if (refundDetails1Array && refundDetails1Array.controls.length) {
      for (var index = 0; index < refundDetails1Array.controls.length; index++) {
        const refundDetails1Control = refundDetails1Array.controls[index] as UntypedFormGroup;
        refundDetails1Control.controls['tax'].setValue(this.inrService.formatCurrency(refundDetails1Control.value.tax))
        refundDetails1Control.controls['interest'].setValue(this.inrService.formatCurrency(refundDetails1Control.value.interest))
        refundDetails1Control.controls['total'].setValue(this.inrService.formatCurrency(refundDetails1Control.value.total))
      }
    }
  }


  inrFormattingRemoving() {
    var data = this.noticeForm.value;
    var dataConsultan = this.consultantForm.value;
    var dataValue = this.taxPaymentRefundDetailsForm.value;


    if (this.caseType != "PENALTY") {
      var itrValue = this.iTRDetailsForm.value;
    }
    if (this.caseType != "PENALTY") {
      if (itrValue.itrDetails && itrValue.itrDetails.length) {
        itrValue.itrDetails.forEach((itrDetails: any) => {
          itrDetails.totTaxIncome = this.inrService.removeCommasAndParseNumber(itrDetails.totTaxIncome)
          itrDetails.totTaxLiability = this.inrService.removeCommasAndParseNumber(itrDetails.totTaxLiability)
          itrDetails.totTaxPaid = this.inrService.removeCommasAndParseNumber(itrDetails.totTaxPaid)
          itrDetails.advanceTax = this.inrService.removeCommasAndParseNumber(itrDetails.advanceTax)
          itrDetails.tdsAmount = this.inrService.removeCommasAndParseNumber(itrDetails.tdsAmount)
          itrDetails.tcsAmount = this.inrService.removeCommasAndParseNumber(itrDetails.tcsAmount)
          itrDetails.selfAssessmentAmount = this.inrService.removeCommasAndParseNumber(itrDetails.selfAssessmentAmount)
          itrDetails.refundDemand = this.inrService.removeCommasAndParseNumber(itrDetails.refundDemand)
        })
      }
      this.iTRDetailsForm.patchValue(itrValue);
    }


    if (dataConsultan.cc && dataConsultan.cc.length) {
      dataConsultan.cc.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
      this.consultantForm.patchValue(data);
    }

    if (dataValue.libDetails && dataValue.libDetails.length) {
      dataValue.libDetails.forEach((libDetails: any) => {
        libDetails.tax = this.inrService.removeCommasAndParseNumber(libDetails.tax)
        libDetails.interest = this.inrService.removeCommasAndParseNumber(libDetails.interest)
        libDetails.total = this.inrService.removeCommasAndParseNumber(libDetails.total)
      })
      this.taxPaymentRefundDetailsForm.patchValue(dataValue);
    }

    if (dataValue.refundDetails && dataValue.refundDetails.length) {
      dataValue.refundDetails.forEach((refundDetails: any) => {
        refundDetails.tax = this.inrService.removeCommasAndParseNumber(refundDetails.tax)
        refundDetails.interest = this.inrService.removeCommasAndParseNumber(refundDetails.interest)
        refundDetails.total = this.inrService.removeCommasAndParseNumber(refundDetails.total)
      })
      this.taxPaymentRefundDetailsForm.patchValue(dataValue);
    }


    if (data.dtNotices && data.dtNotices.length) {
      data.dtNotices.forEach((dtNotices: any) => {
        if (dtNotices.issues && dtNotices.issues.length) {
          dtNotices.issues.forEach((issue: any) => {
            issue.addnOrDisOfExpensesF = this.inrService.removeCommasAndParseNumber(issue.addnOrDisOfExpensesF)
            issue.taxRateF = (issue.taxRateF)
            issue.taxImpactF = this.inrService.removeCommasAndParseNumber(issue.taxImpactF)
            issue.interestF = this.inrService.removeCommasAndParseNumber(issue.interestF)
            issue.penaltyF = this.inrService.removeCommasAndParseNumber(issue.penaltyF)
            issue.totalF = this.inrService.removeCommasAndParseNumber(issue.totalF)
            issue.addnOrDisOfExpensesL = this.inrService.removeCommasAndParseNumber(issue.addnOrDisOfExpensesL)
            issue.taxRateL = (issue.taxRateL)
            issue.taxImpactL = this.inrService.removeCommasAndParseNumber(issue.taxImpactL)
            issue.interestL = this.inrService.removeCommasAndParseNumber(issue.interestL)
            issue.penaltyL = this.inrService.removeCommasAndParseNumber(issue.penaltyL)
            issue.totalL = this.inrService.removeCommasAndParseNumber(issue.totalL)
            if (issue.provAndLibDetails && issue.provAndLibDetails.length) {
              issue.provAndLibDetails.forEach((details: any) => {
                details.provAmtF = this.inrService.removeCommasAndParseNumber(details.provAmtF)
                details.contLibAmtF = this.inrService.removeCommasAndParseNumber(details.contLibAmtF)
                details.othLibF = this.inrService.removeCommasAndParseNumber(details.othLibF)
                details.caroAmtF = this.inrService.removeCommasAndParseNumber(details.caroAmtF)
              })
            }
          })
        }
      })
    }
    if (data.dtNotices && data.dtNotices.length) {
      data.dtNotices.forEach((data: any) => {
        data.totOfAddnOrDisOfExpensesF = this.inrService.removeCommasAndParseNumber(data.totOfAddnOrDisOfExpensesF)
        data.taxImpactFTotal = this.inrService.removeCommasAndParseNumber(data.taxImpactFTotal)
        data.interestFTotal = this.inrService.removeCommasAndParseNumber(data.interestFTotal)
        data.penaltyFTotal = this.inrService.removeCommasAndParseNumber(data.penaltyFTotal)
        data.totOfTotal = this.inrService.removeCommasAndParseNumber(data.totOfTotal)
      })
    }
    this.noticeForm.patchValue(data);
  }



  handlePaste(event: any) {
    this.inrService.handlePaste(event)
  }

  handleDecimalPaste(index: any) {
    const dtNoticesArray = this.noticeForm.controls.dtNotices as UntypedFormArray;
    const issues1Array = dtNoticesArray.controls[index].get('issues') as UntypedFormArray;
    const issues1Control = issues1Array.controls[index] as UntypedFormGroup;
    issues1Control.controls['taxRateF'].setValue((Math.round(issues1Control.value.taxRateF * 100) / 100).toFixed(2));
  }

  whetherOrderReceivedChanged() {
    if (this.orderReceivedForm.value.whetherOrderReceived === false || this.orderReceivedForm.value.whetherOrderReceived === 'false') {
      this.isPhase3 = false;
    } else {
      this.isPhase3 = true;
      this.tab10Disable = true;
    }
  }

  whetherWritToHCChange() {
    if (this.writToHcForm.value.writToHc === 'true' || this.writToHcForm.value.writToHc === true) {
      this.disablePhase3 = true;
      this.disablePhase2 = true;
    } else {
      this.disablePhase3 = false;
      this.disablePhase2 = false;
    }
  }

  isWhetherSCNIssued() {
    var data = this.orderForm.controls.whetherScnIssued.value
    if (data == "true" || data == true) {
      this.showTable = true
    } else {
      this.showTable = false
    }
  }

  //   isDeviationRetData() {
  //     var dtNoticesArray = this.noticeForm.controls.dtNotices as FormArray
  //   if (dtNoticesArray.controls.length > 0) {
  //     for (var i = 0; i < dtNoticesArray.controls.length; i++) {
  //       var itemListControl = dtNoticesArray.controls[i] as FormGroup;
  //       if (itemListControl.controls.doesCorrespondenceInvolvesAmts.value === 'false' 
  //       || itemListControl.controls.doesCorrespondenceInvolvesAmts.value === false) {
  //       this.hideIssueTable = false;

  //       }else{
  //         this.hideIssueTable = true;

  //       }
  //     }
  //   }

  // }
  submitgeneralInfo() {
    this.isNextBtnClicked = true;
    if (this.generalInfo.valid) {
      this.tab2Disable = true;
      this.noticeTab.nativeElement.click();
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }

  submitNoticeForm() {
    this.isSubmittedNotice = true;
    this.p1IssueValidation();
    var dtNotices = this.getFormControlNotice();
    let noticeLoc;
    for (let i = 0; i < dtNotices.length; i++) {
      noticeLoc = (dtNotices[i].get('noticeLoc') as UntypedFormArray).controls;
    }
    var count = noticeLoc?.some(x =>
      x.get('fileLoc')?.value != null
    );
    if (!count) {
      this.toaster.showError("Required Fields Are Empty");
      return;
    }

    if (this.noticeForm.valid) {
      this.tab3Disable = true;
      this.itrTab.nativeElement.click();
      console.log(this.noticeForm, "hello")
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  submitNoticeFormPentyle(caseOption: string) {
    console.log(this.noticeForm, "noticeForm")
    this.isSubmittedNotice = true;
    this.p1IssueValidation();
    var dtNotices = this.getFormControlNotice();
    let noticeLoc;
    for (let i = 0; i < dtNotices.length; i++) {
      noticeLoc = (dtNotices[i].get('noticeLoc') as UntypedFormArray).controls;
    }
    var count = noticeLoc?.some(x =>
      x.get('fileLoc')?.value != null
    );
    if (!count) {
      this.toaster.showError("Required Fields Are Empty");
      return;
    }
    console.log("this.noticeForm", this.noticeForm)
    if (this.noticeForm.valid) {
      this.tab3Disable = true;
      this.saveNotice(caseOption);
      if (this.saveApi === true) {
        this.tab4Disable = true;
        console.log(this.noticeForm, "hello")
      }

    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  SaveForm(caseOption: string) {
    this.isSubmittedItr = true
    if (this.iTRDetailsForm.valid) {
      console.log(this.iTRDetailsForm, "this.iTRDetailsForm")
      this.saveNotice(caseOption);
    } else {
      this.toaster.showError('Required Fields Are Empty')
    }
  }

  // this for Phase 1 next
  submitiTRDetailsForm() {
    if (this.iTRDetailsForm.valid) {
      this.tab4Disable = true;
      this.WritTab.nativeElement.click();
    } else {
      this.toaster.showError('Required Fields Are Empty')
    }
  }

  // this for Phase 1 Update api
  submitNoticeFormUpdate(phaseTab: number, caseOption: string) {
    this.isSubmittedNotice = true;
    this.p1IssueValidation();
    if (this.noticeForm.valid) {
      this.updateCase(phaseTab, caseOption);
      if (this.caseType === "PENALTY") {
        this.isPhase2 = true;
      }
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  // ph2 
  submitSubmissionForm() {
    console.log(this.noticeForm, "noticeForm")
    this.isSubmittedSubmission = true;
    this.p2validation();
    // let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as FormArray;
    // let element1;
    // for (let index = 0; index < noticesListFrmArray.length; index++) {
    //   const element = noticesListFrmArray.controls[index];
    //   var submissionArray: any = element.controls.submissions.controls;
    //   for (let i = 0; i < submissionArray.length; i++) {
    //     element1 = (submissionArray[i].get('ackCopyLoc') as FormArray).controls;
    //   }
    // }
    // var count = element1?.some((x: any) =>
    //   x.get('fileLoc')?.value != null
    // );
    // if (!count) {
    //   this.toaster.showError("Required Fields Are Empty");
    //   return;
    // }
    if (this.noticeForm.valid) {
      this.tab6Disable = true;
      this.hearingTab.nativeElement.click();
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  hearingSubmit() {
    console.log(this.hearingForm, "hearingForm")
    this.isSubmittedPhRecord = true;
    if (this.hearingForm.valid) {
      this.tab7Disable = true;
      this.summonTab.nativeElement.click();
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }


  summonSubmit() {
    console.log(this.summonForm, "summonForm")
    if (this.summonForm.valid) {
      this.tab8Disable = true;
      this.consultantTab.nativeElement.click();
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }

  consultantFormSubmit() {
    console.log(this.consultantForm, "consultantForm")
    this.isSubmitted = true;
    this.consultantValidation();
    if (this.consultantForm.valid) {
      this.tab9Disable = true;
      this.orderReceivedTab.nativeElement.click();
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  submitUpdate(phaseTab: number, caseOption: string) {
    this.isSubmittedSubmission = true;
    this.p2validation();
    // let noticesListFrmArray: any = this.noticeForm.get('dtNotices') as FormArray;
    // let element1;
    // for (let index = 0; index < noticesListFrmArray.length; index++) {
    //   const element = noticesListFrmArray.controls[index];
    //   var submissionArray: any = element.controls.submissions.controls;
    //   for (let i = 0; i < submissionArray.length; i++) {
    //     element1 = (submissionArray[i].get('ackCopyLoc') as FormArray).controls;
    //   }
    // }
    // var count = element1?.some((x: any) =>
    //   x.get('fileLoc')?.value != null
    // );
    // if (!count) {
    //   this.toaster.showError("Required Fields Are Empty");
    //   return;
    // }
    if (this.noticeForm.valid) {
      this.updateCase(phaseTab, caseOption);
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  // ph2  end


  orderReceived(phaseTab: number, caseOption: string) {
    if (this.orderReceivedForm.value.whetherOrderReceived === 'true' || this.orderReceivedForm.value.whetherOrderReceived === true) {
      this.orderTab.nativeElement.click();
      this.updateCase(phaseTab, caseOption);
    }
    else if (this.orderReceivedForm.value.whetherOrderReceived === 'false' || this.orderReceivedForm.value.whetherOrderReceived === false) {
      this.updateCase(phaseTab, caseOption);
    }
  }

  // ph3

  orderSubmit() {
    console.log(this.orderForm, "orderForm")
    this.isSubmittedOrder = true;
    this.isSubmittedAppeal = true;
    this.isSubmittedScn = true;
    this.isSubmittedRectif = true;
    this.p3Validation();
    this.rectificationValidation();
    var orderDetails = this.getOrderDetails();
    let orderLoc
    for (let i = 0; i < orderDetails.length; i++) {
      orderLoc = (orderDetails[i].get('orderLoc') as UntypedFormArray).controls;
    }
    var count = orderLoc?.some(x =>
      x.get('fileLoc')?.value != null
    );
    if (!count) {
      this.toaster.showError("Required Fields Are Empty");
      return;
    }
    if (this.orderForm.valid) {
      this.tab11Disable = true;
      this.taxTab.nativeElement.click();
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }


  taxPaymentFormSubmit() {
    if (this.taxPaymentRefundDetailsForm.valid) {
      this.tab11Disable = true;
      this.cumulativeTab.nativeElement.click();
    } else {
      this.toaster.showError('Required Fields Are Empty')
    }
  }


  cumulativeFormSubmit(phaseTab: number, caseOption: string) {
    this.isSubmittedAppeal = true;
    this.isSubmittedOrder = true;
    if (this.orderForm.valid) {
      this.updateCase(phaseTab, caseOption);
    } else {
      this.toaster.showError('Required Fields Are Empty')
    }
  }

  updatePhase1Btton() {
    let id = document.getElementById('isupdate');
    this.update.nativeElement.click();
  }

  selectedIssues:any
  getissueData(content: any, i:any,k:any){
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    
    this.selectedIssues = this.noticeForm.value.dtNotices[i].issues[k].issue;
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "updatedOn",
    model.sortdir = "ASC",
    model.companyId =  "7437",
    model.module = "DT",
    model.criterias= [
      {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
      }
  ]
  console.log(this.selectedIssues,"this.preAjaForm.value.issue");
  this.taxService.getIssueRefData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
    if (response.status === 1) {
      this.issueRefData = response?.response;
      if(this.issueRefData.length === 0){
          this.hideData =  false
      }else{
        this.hideData =  true
      }
    }      
    });
  }
  exportAsXLSX() {
    if (this.issueRefData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    const data= this.issueRefData.map((source: any) => ({
      'PAN Name': source.panName,
      'Case id': source.caseId,
      'Case Label': source.caseLabel,
      'Assessment Year': source.ay,
      'Person Responsible': source.personResponsible,

    }));
    this.excelService.exportAsExcelFile(data, 'Issue Reference');
  }
  SortingIssue(column: string) {
    switch (column) {
      case SORT_ISSUE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseId',
            'asc'
          );
        } else {
          this.sorting.caseId = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseId',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PAN: {
        if (!this.sorting.panName) {
          this.sorting.panName = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'panName',
            'asc'
          );
        } else {
          this.sorting.panName = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'panName',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.CASE_LABEL: {
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseLabel',
            'asc'
          );
        } else {
          this.sorting.caseLabel = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'caseLabel',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.AY: {
        if (!this.sorting.ay) {
          this.sorting.ay = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'ay',
            'asc'
          );
        } else {
          this.sorting.ay = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,
            'ay',
            'desc'
          );
        }
        break;
      }

      case SORT_ISSUE.PERSON_RESPONSIBLE: {
        if (!this.sorting.personResponsible) {
          this.sorting.personResponsible = true;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,'personResponsible','asc');
        } else {
          this.sorting.personResponsible = false;
          this.issueRefData = this.commonServ.sortObjectsByKey(
            this.issueRefData,'personResponsible', 'desc' );
        }
        break;
      }
    }
  }
}
