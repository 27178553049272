<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">
        <p><span class="card-title">Financial Information</span>
        </p>
        <div class="card borderOrange mt15">
          <div class="card-body">
            <form [formGroup]="financialInfoFormGroup">
              <div class="form-group">
                <div class="row col-md-12 nopadding">
                  <p><a class="pl5 addExtLetter iris-gst-pull-right"
                      [ngClass]="{'disabledText': (role == 'DT_Viewer') ||  (role === 'Viewer')}" title="Add More"
                      (click)="addRow()"><i class="fa fa-plus"></i> Add Row</a></p>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="bgGred">
                        <tr>
                          <th class="sl-no-cls">Sr.No.</th>
                          <th>Legal PAN</th>
                          <th>Legal PAN Name</th>
                          <th style="min-width: 100px">AY<span class="mandatetd"><i class="fa fa-asterisk"
                                style="font-size: 7px; margin-left:0px;"></i></span></th>
                          <th style="min-width: 100px">ITR Form</th>
                          <th style="min-width: 210px">Computation of Total Income</th>
                          <th>Financials Statements</th>
                          <th>Statutory Audit Report</th>
                          <th>Tax Audit Report</th>
                          <th style="min-width: 250px">Transfer Pricing Audit Report</th>
                          <th class="sl-no-cls">Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let fiGroup of getFinancialInformation(); let i = index" [formGroup]="fiGroup">
                          <td class="sl-no-cls">{{ i + 1 }}</td>
                          <td>
                            <input type="text" formControlName="pan" class="form-control" data-bs-toggle="tooltip"
                              data-bs-placement="top" [title]="fiGroup.value.pan">
                          </td>
                          <td>
                            <input type="text" formControlName="panName" class="form-control" data-bs-toggle="tooltip"
                              data-bs-placement="top" [title]="fiGroup.value.panName">
                          </td>
                          <td>
                            <select formControlName="ay" class="form-control form-select" id="gstin"
                              [title]="fiGroup.value.ay">
                              <option [value]="null">-- Select --</option>
                              <ng-container *ngFor="let obj of finyearData">
                                <option [value]="obj">{{ obj }}</option>
                              </ng-container>
                            </select>
                            <div *ngIf="isSubmitted && fiGroup.get('ay')?.errors">
                              <span class="text-danger" *ngIf="fiGroup.get('ay')?.errors?.required ">
                                Required
                              </span>
                            </div>
                          </td>
                          <td><span (click)="openDetailPop(itrFormPop, i)"> <i class="icon fa fa fa-paperclip"
                                title="Attach file"></i></span><span *ngIf="getItrLength(i) != 0"> {{getItrLength(i)}}
                              Attachment</span></td>

                          <td><span (click)="openDetailPop(totIncomeCompFormPop, i)"> <i class="icon fa fa-paperclip"
                                title="Attach file"></i></span><span
                              *ngIf="getTotIncomeCompLength(i) != 0">{{getTotIncomeCompLength(i)}} Attachment</span>
                          </td>

                          <td><span (click)="openDetailPop(finLocPop, i)"> <i class="icon fa fa-paperclip"
                                title="Attach file"></i></span><span
                              *ngIf="getFinLocFinLocLength(i) != 0">{{getFinLocFinLocLength(i)}} Attachment</span></td>
                          <td>
                            <span (click)="openDetailPop(statAuditRepPop, i)"><i class="icon fa fa-paperclip"
                                title="Attach file"></i></span><span
                              *ngIf="getStatAuditRepLength(i) != 0">{{getStatAuditRepLength(i)}} Attachment</span>
                          </td>
                          <td>
                            <span (click)="openDetailPop(taxAuditReportPop, i)"><i class="icon fa fa-paperclip"
                                title="Attach file"></i></span><span
                              *ngIf="getTaxAuditRepLength(i) != 0">{{getTaxAuditRepLength(i)}} Attachment</span>
                          </td>
                          <td>
                            <span (click)="openDetailPop(transPricingAudRepPop, i)"><i class="icon fa fa-paperclip"
                                title="Attach file"></i></span><span
                              *ngIf="getTransPricingAudRepLength(i) != 0">{{getTransPricingAudRepLength(i)}}
                              Attachment</span>
                          </td>
                          <td class="sl-no-cls"><a class="addExtLetter" (click)="opentaxPop(taxpop, i)"><i
                                class="fa fa-minus" title="Remove"></i> </a>
                          </td>
                          <ng-template #taxpop let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                              <button (click)="modal.dismiss('Cross click')" type="button" class="close"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p>Are you sure, you want to remove?</p>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-outline-dark" (click)="rmProv(i)">Remove</button>
                            </div>
                          </ng-template>

                          <!-- itrFormLoc FormArray -->
                          <ng-template #itrFormPop let-modal>
                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="col-md-12">
                              <label> Attachment</label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Upload</th>
                                      <th style="width:50%;">Remark</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let itrFromGrp of getItr(fiGroup); let j = index"
                                      [formGroup]="itrFromGrp">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-9 nopadding">
                                            <div class="form-group">
                                              <input *ngIf="itrFromGrp?.value?.isdocLocUploadedClicked" type="file"
                                                id="itemList({{i}}).itrFormLoc({{j}}).fileLoc"
                                                class="filetype form-control" name="myfile"
                                                (change)="onFileSelect($event)">
                                              <p *ngIf="!itrFromGrp?.value?.isdocLocUploadedClicked || this.financialInfoFormGroup.value.itemList[i].itrFormLoc[j].fileLoc"
                                                class="form-control pt10">
                                                {{getFileNameDta(this.financialInfoFormGroup.value.itemList[i].itrFormLoc[j].fileLoc)}}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-md-3 nopadding">
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon"
                                                *ngIf="this.financialInfoFormGroup.value.itemList[i].itrFormLoc[j].fileLoc"
                                                title="Download file"
                                                (click)="download(this.financialInfoFormGroup.value.itemList[i].itrFormLoc[j].fileLoc)"><i
                                                  class="fa fa-download"></i></a>
                                              <a class="downupIcon"
                                                *ngIf="!this.financialInfoFormGroup.value.itemList[i].itrFormLoc[j].fileLoc || this.financialInfoFormGroup.value.itemList[i].itrFormLoc[j].fileLoc"
                                                title="Upload file" (click)="uploadItr(i,j)"><i
                                                  class="fa fa-upload"></i></a>
                                              <a class="downupIcon" (click)="deleteItrCopyFile(i,j)"
                                                *ngIf="(itrFromGrp.value.fileLoc)"><i class="fa fa-trash"
                                                  aria-hidden="true"></i></a>
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <input type="text" class="form-control" formControlName="remarks"
                                          [title]="itrFromGrp.value.remarks">
                                      </td>
                                      <ng-template #rmitrFPop let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmItrDiv(i, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Save</button>
                            </div>
                          </ng-template>
                          <!-- itrFormLoc FormArray -->
                          <!-- totIncomeCompForm FormArray -->
                          <ng-template #totIncomeCompFormPop let-modal>
                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="col-md-12">
                              <label> Attachment</label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Upload</th>
                                      <th style="width:50%;">Remark</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      *ngFor="let totIncomeCompFromGrp of getTotIncomeCompFrmGrp(fiGroup); let j = index"
                                      [formGroup]="totIncomeCompFromGrp">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-9 nopadding">
                                            <div class="form-group">
                                              <input *ngIf="totIncomeCompFromGrp?.value?.isdocLocUploadedClicked"
                                                type="file" id="itemList({{i}}).totIncomeCompLoc({{j}}).fileLoc"
                                                class="filetype form-control" name="myfile"
                                                (change)="onFileSelect($event)">
                                              <p *ngIf="!totIncomeCompFromGrp?.value?.isdocLocUploadedClicked || this.financialInfoFormGroup.value.itemList[i].totIncomeCompLoc[j].fileLoc"
                                                class="form-control pt10">
                                                {{getFileNameDta(totIncomeCompFromGrp.value.fileLoc)}}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-md-3 nopadding">
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon"
                                                *ngIf="this.financialInfoFormGroup.value.itemList[i].totIncomeCompLoc[j].fileLoc"
                                                title="Download file"
                                                (click)="download(this.financialInfoFormGroup.value.itemList[i].totIncomeCompLoc[j].fileLoc)"><i
                                                  class="fa fa-download"></i></a>
                                              <a class="downupIcon"
                                                *ngIf="!this.financialInfoFormGroup.value.itemList[i].totIncomeCompLoc[j].fileLoc || this.financialInfoFormGroup.value.itemList[i].totIncomeCompLoc[j].fileLoc"
                                                title="Upload file" (click)="uploadtotIncomeComp(i,j)"><i
                                                  class="fa fa-upload"></i></a>
                                              <a class="downupIcon" (click)="deleteTotIncomeCopyFile(i,j)"
                                                *ngIf="(totIncomeCompFromGrp.value.fileLoc)"><i class="fa fa-trash"
                                                  aria-hidden="true"></i></a>
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      <!-- remark -->
                                      <td>
                                        <input type="text" class="form-control" formControlName="remarks"
                                          [title]="totIncomeCompFromGrp.value.remarks">
                                      </td>

                                      <!-- <td><a class="addExtLetter" (click)="openTotIncomeCompPop(rmTotIncomeCompListPop, i)"><i class="fa fa-minus"></i></a></td> -->
                                      <ng-template #rmTotIncomeCompListPop let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmtotIncomeCompDiv(i, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Save</button>
                            </div>
                          </ng-template>
                          <!-- totIncomeCompForm FormArray -->

                          <!-- finLoc FormArray -->
                          <ng-template #finLocPop let-modal>
                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="col-md-12">
                              <label> Attachment</label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Upload</th>
                                      <th style="width:50%;">Remark</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let finLocFrmGrp of getFinLocFrmGrp(fiGroup); let j = index"
                                      [formGroup]="finLocFrmGrp">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-9 nopadding">
                                            <div class="form-group">
                                              <input *ngIf="finLocFrmGrp?.value?.isdocLocUploadedClicked" type="file"
                                                id="itemList({{i}}).finLoc({{j}}).fileLoc" class="filetype form-control"
                                                name="myfile" (change)="onFileSelect($event)">
                                              <p *ngIf="!finLocFrmGrp?.value?.isdocLocUploadedClicked || this.financialInfoFormGroup.value.itemList[i].finLoc[j].fileLoc"
                                                class="form-control pt10"
                                                [ngClass]="{ 'errorBorder': isSubmitted && this.financialInfoFormGroup.value.itemList[i].finLoc[j].fileLoc.errors}"
                                                [title]="finLocFrmGrp.value.fileName">
                                                {{getFileNameDta(this.financialInfoFormGroup.value.itemList[i].finLoc[j].fileLoc)}}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-md-3 nopadding">
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon"
                                                *ngIf="this.financialInfoFormGroup.value.itemList[i].finLoc[j].fileLoc"
                                                title="Download file"
                                                (click)="download(this.financialInfoFormGroup.value.itemList[i].finLoc[j].fileLoc)"><i
                                                  class="fa fa-download"></i></a>
                                              <a class="downupIcon"
                                                *ngIf="!this.financialInfoFormGroup.value.itemList[i].finLoc[j].fileLoc || this.financialInfoFormGroup.value.itemList[i].finLoc[j].fileLoc"
                                                title="Upload file" (click)="uploadFinLoc(i,j)"><i
                                                  class="fa fa-upload"></i></a>
                                              <a class="downupIcon" (click)="deleteFinIndexCopyFile(i,j)"
                                                *ngIf="(finLocFrmGrp.value.fileLoc)"><i class="fa fa-trash"
                                                  aria-hidden="true"></i></a>
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      <!-- remark -->
                                      <td>
                                        <input type="text" class="form-control" formControlName="remarks"
                                          [title]="finLocFrmGrp.value.remarks">
                                      </td>

                                      <ng-template #rmFinLocPop let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to <b>Delete File</b>?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmFinLocDiv(i, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Save</button>
                            </div>
                          </ng-template>
                          <!-- finLoc FormArray -->

                          <!-- StatAuditRepFrmGrp FormArray -->
                          <ng-template #statAuditRepPop let-modal>
                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="col-md-12">
                              <label> Attachment</label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Upload</th>
                                      <th style="width:50%;">Remark</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let statAuditRepFrmGrp of getStatAuditRepFrmGrp(fiGroup); let j = index"
                                      [formGroup]="statAuditRepFrmGrp">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-9 nopadding">
                                            <div class="form-group">
                                              <input *ngIf="statAuditRepFrmGrp?.value?.isdocLocUploadedClicked"
                                                type="file" id="itemList({{i}}).statAuditRepLoc({{j}}).fileLoc"
                                                class="filetype form-control" name="myfile"
                                                (change)="onFileSelect($event)">
                                              <p *ngIf="!statAuditRepFrmGrp?.value?.isdocLocUploadedClicked || this.financialInfoFormGroup.value.itemList[i].statAuditRepLoc[j].fileLoc"
                                                class="form-control pt10" [title]="statAuditRepFrmGrp.value.fileName">
                                                {{getFileNameDta(this.financialInfoFormGroup.value.itemList[i].statAuditRepLoc[j].fileLoc)}}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-md-3 nopadding">
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon"
                                                *ngIf="this.financialInfoFormGroup.value.itemList[i].statAuditRepLoc[j].fileLoc"
                                                title="Download file"
                                                (click)="download(this.financialInfoFormGroup.value.itemList[i].statAuditRepLoc[j].fileLoc)"><i
                                                  class="fa fa-download"></i></a>
                                              <a class="downupIcon"
                                                *ngIf="!this.financialInfoFormGroup.value.itemList[i].statAuditRepLoc[j].fileLoc || this.financialInfoFormGroup.value.itemList[i].statAuditRepLoc[j].fileLoc"
                                                title="Upload file" (click)="uploadStatAuditRep(i,j)"><i
                                                  class="fa fa-upload"></i></a>
                                              <a class="downupIcon" (click)="deleteStatAuditRepCopyFile(i,j)"
                                                *ngIf="(statAuditRepFrmGrp.value.fileLoc)"><i class="fa fa-trash"
                                                  aria-hidden="true"></i></a>
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      <!-- remark -->
                                      <td>
                                        <input type="text" class="form-control" formControlName="remarks"
                                          [title]="statAuditRepFrmGrp.value.remarks">
                                      </td>

                                      <!-- <td><a class="addExtLetter" (click)="openStatAuditRepPop(rmStatAuditRepPop, i)"><i class="fa fa-minus"></i></a></td> -->
                                      <ng-template #rmStatAuditRepPop let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmStatAuditRepDiv(i, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Save</button>
                            </div>
                          </ng-template>
                          <!-- StatAuditRep FormArray -->


                          <!-- TaxAuditReport FormArray -->
                          <ng-template #taxAuditReportPop let-modal>
                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="col-md-12">
                              <label> Attachment</label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Upload</th>
                                      <th style="width:50%;">Remark</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      *ngFor="let taxAuditReportFrmGrp of getTaxAuditReportFrmGrp(fiGroup); let j = index"
                                      [formGroup]="taxAuditReportFrmGrp">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-9 nopadding">
                                            <div class="form-group">
                                              <input *ngIf="taxAuditReportFrmGrp?.value?.isdocLocUploadedClicked"
                                                type="file" id="itemList({{i}}).taxAuditReportLoc({{j}}).fileLoc"
                                                class="filetype form-control" name="myfile"
                                                (change)="onFileSelect($event)">
                                              <p *ngIf="!taxAuditReportFrmGrp?.value?.isdocLocUploadedClicked || this.financialInfoFormGroup.value.itemList[i].taxAuditReportLoc[j].fileLoc"
                                                class="form-control pt10" [title]="taxAuditReportFrmGrp.value.fileName">
                                                {{getFileNameDta(this.financialInfoFormGroup.value.itemList[i].taxAuditReportLoc[j].fileLoc)}}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-md-3 nopadding">
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon"
                                                *ngIf="this.financialInfoFormGroup.value.itemList[i].taxAuditReportLoc[j].fileLoc"
                                                title="Download file"
                                                (click)="download(this.financialInfoFormGroup.value.itemList[i].taxAuditReportLoc[j].fileLoc)"><i
                                                  class="fa fa-download"></i></a>
                                              <a class="downupIcon"
                                                *ngIf="!this.financialInfoFormGroup.value.itemList[i].taxAuditReportLoc[j].fileLoc || this.financialInfoFormGroup.value.itemList[i].taxAuditReportLoc[j].fileLoc"
                                                title="Upload file" (click)="uploadTaxAuditReportRep(i,j)"><i
                                                  class="fa fa-upload"></i></a>
                                              <a class="downupIcon" (click)="deleteTaxAuditReportFile(i,j)"
                                                *ngIf="(taxAuditReportFrmGrp.value.fileLoc)"><i class="fa fa-trash"
                                                  aria-hidden="true"></i></a>
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      <!-- remark -->
                                      <td>
                                        <input type="text" class="form-control" formControlName="remarks"
                                          [title]="taxAuditReportFrmGrp.value.remarks">
                                      </td>

                                      <!-- <td><a class="addExtLetter" (click)="openTaxAuditReportpPop(rmTaxAuditReportPop, i)"><i class="fa fa-minus"></i></a></td> -->
                                      <ng-template #rmTaxAuditReportPop let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmTaxAuditReportRepDiv(i, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Save</button>
                            </div>
                          </ng-template>
                          <!-- TaxAuditReport FormArray -->


                          <!-- TransPricingAudRep FormArray -->
                          <ng-template #transPricingAudRepPop let-modal>
                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="col-md-12">
                              <label> Attachment</label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Upload</th>
                                      <th style="width:50%;">Remark</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      *ngFor="let transPricingAudRepFrmGrp of getTransPricingAudRepFrmGrp(fiGroup); let j = index"
                                      [formGroup]="transPricingAudRepFrmGrp">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-9 nopadding">
                                            <div class="form-group">
                                              <input *ngIf="transPricingAudRepFrmGrp?.value?.isdocLocUploadedClicked"
                                                type="file" id="itemList({{i}}).transPricingAudRepLoc({{j}}).fileLoc"
                                                class="filetype form-control" name="myfile"
                                                (change)="onFileSelect($event)">
                                              <p *ngIf="!transPricingAudRepFrmGrp?.value?.isdocLocUploadedClicked || this.financialInfoFormGroup.value.itemList[i].transPricingAudRepLoc[j].fileLoc"
                                                class="form-control pt10"
                                                [title]="transPricingAudRepFrmGrp.value.fileName">
                                                {{getFileNameDta(this.financialInfoFormGroup.value.itemList[i].transPricingAudRepLoc[j].fileLoc)}}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-md-3 nopadding">
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon"
                                                *ngIf="this.financialInfoFormGroup.value.itemList[i].transPricingAudRepLoc[j].fileLoc"
                                                title="Download file"
                                                (click)="download(this.financialInfoFormGroup.value.itemList[i].transPricingAudRepLoc[j].fileLoc)"><i
                                                  class="fa fa-download"></i></a>
                                              <a class="downupIcon"
                                                *ngIf="!this.financialInfoFormGroup.value.itemList[i].transPricingAudRepLoc[j].fileLoc || this.financialInfoFormGroup.value.itemList[i].transPricingAudRepLoc[j].fileLoc"
                                                title="Upload file" (click)="uploadTransPricingAudRep(i,j)"><i
                                                  class="fa fa-upload"></i></a>
                                              <a class="downupIcon" (click)="deletetaxAuditReportFile(i,j)"
                                                *ngIf="(transPricingAudRepFrmGrp.value.fileLoc)"><i class="fa fa-trash"
                                                  aria-hidden="true"></i></a>
                                            </label>
                                          </div>
                                        </div>
                                      </td>

                                      <!-- remark -->
                                      <td>
                                        <input type="text" class="form-control" formControlName="remarks"
                                          [title]="transPricingAudRepFrmGrp.value.remarks">
                                      </td>

                                      <!-- <td><a class="addExtLetter" (click)="openTransPricingAudRepPop(rmTransPricingAudRepPop, i)"><i class="fa fa-minus"></i></a></td> -->
                                      <ng-template #rmTransPricingAudRepPop let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmTransPricingAudRepDiv(i, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Save</button>
                            </div>
                          </ng-template>
                          <!-- TransPricingAudRep FormArray -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-12 nopadding mt-2">
          <button class="btn btn-outline-primary"
            [ngClass]="{'disabled': (role == 'DT_Viewer') ||  (role === 'Viewer')}"
            (click)="updateFinancialInformation($event)">Save & Update</button>
        </div>

      </div>
    </div>
  </div>
</div>