<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">
        <p><span class="card-title">Tax Positions </span>
        </p>
        <div class="card borderOrange mt15">
          <div class="card-body">
            <form [formGroup]="directTaxPosFormGrp">
              <div class="form-group">
                <div class="row col-md-12 nopadding">
                  <p><a class="pl5 addExtLetter iris-gst-pull-right" title="Add More"
                      [ngClass]="{'disabledText':(role === 'DT_Viewer') ||  (role === 'Viewer')}" (click)="addRow()"><i
                        class="fa fa-plus"></i>
                      Add Row</a></p>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="bgGred">
                        <tr>
                          <th class="sl-no-cls">Sr.No.</th>
                          <th>PAN</th>
                          <th>PAN Name</th>
                          <th style="min-width: 490px;">Issue <span class="mandatetd"><i class="fa fa-asterisk"
                                style="font-size: 7px; margin-left:0px;"></i></span></th>
                          <th style="min-width: 300px;">Keywords</th>
                          <th style="min-width: 300px;">Tax Position Taken <span class="mandatetd"><i
                                class="fa fa-asterisk" style="font-size: 7px; margin-left:0px;"></i></span></th>
                          <th>Assessment Year</th>
                          <th>Judicial Precedence</th>
                          <th>Legal Opinion</th>
                          <th class="sl-no-cls">Remove
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let taxPositionGrp of getTaxPosition(); let i=index" [formGroup]="taxPositionGrp">
                          <td class="sl-no-cls">{{i+1}}</td>
                          <td><input type="text" class="form-control" data-bs-toggle="tooltip" data-bs-placement="top"
                              formControlName="pan" [title]="taxPositionGrp.value.pan">
                          </td>
                          <td><input type="text" class="form-control" data-bs-toggle="tooltip" data-bs-placement="top"
                              formControlName="panName" [title]="taxPositionGrp.value.panName">
                          </td>
                          <td>
                            <select class="form-control form-select" [title]="taxPositionGrp.value.issue"
                              formControlName="issue"
                              [ngClass]="{'errorBorder': (isSubmitted && taxPositionGrp.get('issue')?.errors)}"
                              [title]="taxPositionGrp.value.issue">
                              <option [value]="null">-- Select --</option>
                              <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                  {{item.name}}</option>
                              </optgroup>
                            </select>
                            <div *ngIf="isSubmitted && taxPositionGrp.get('issue')?.errors">
                              <span class="text-danger" *ngIf="taxPositionGrp.get('issue')?.errors?.required ">
                                Required
                              </span>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" data-bs-toggle="tooltip" data-bs-placement="top"
                              [title]="taxPositionGrp.value.keywords" formControlName="keywords">
                            <div class="text-danger"
                              *ngIf="taxPositionGrp.get('keywords')?.touched && taxPositionGrp.get('keywords')?.hasError('maxlength')">
                              Maximum of 150 characters
                            </div>
                          </td>
                          <td>
                            <input type="text" class="form-control" formControlName="taxPosTaken"
                              data-bs-toggle="tooltip" data-bs-placement="top" [readOnly]=""
                              [title]="taxPositionGrp.value.taxPosTaken" placeholder="Enter text"
                              [ngClass]="{ 'errorBorder': (isSubmitted && taxPositionGrp.get('taxPosTaken')?.errors)}"
                              (click)="openTextBoxModal('Tax Position Taken', 'taxPosTaken',directTaxPosFormGrp,i)">
                            <div *ngIf="isSubmitted && taxPositionGrp.get('taxPosTaken')?.errors">
                              <span class="text-danger" *ngIf="taxPositionGrp.get('taxPosTaken')?.errors?.required ">
                                Required
                              </span>
                            </div>
                            <div class="text-danger"
                              *ngIf="taxPositionGrp.get('taxPosTaken')?.touched && taxPositionGrp.get('taxPosTaken')?.hasError('maxlength')">
                              Maximum of 1000 characters
                            </div>
                          </td>

                          <td>
                            <select class="form-control form-select" id="gstin" formControlName="ay"
                              [ngClass]="{'errorBorder': (isSubmitted && taxPositionGrp.get('ay')?.errors)}"
                              [title]="taxPositionGrp.value.ay">
                              <option [value]="null">-- Select --</option>
                              <ng-container *ngFor="let obj of finyearData">
                                <option [value]="obj">{{obj}}</option>
                              </ng-container>
                            </select>
                          </td>
                          <td><span (click)="openDetailPop(judicialPredPop, i)"> <i class="icon fa fa fa-paperclip"
                                title="Attach file"></i></span><span *ngIf="getJudicialLength(i) != 0">
                              {{getJudicialLength(i)}} Attachment</span></td>
                          <td>
                            <span (click)="openDetailPop(legalOpinionPop, i)"> <i class="icon fa fa fa-paperclip"
                                title="Attach file"></i></span><span *ngIf="getLegalOpinionLength(i) != 0">
                              {{getLegalOpinionLength(i)}} Attachment</span>
                          </td>
                          <td class="sl-no-cls">
                            <a class="addExtLetter" (click)="opentaxPop(taxpop, i)"><i class="fa fa-minus"></i></a>
                          </td>
                          <ng-template #taxpop let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p>Are you sure, you want to remove?</p>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-outline-dark" (click)="rmProv(i)">Remove</button>
                            </div>
                          </ng-template>
                          <ng-template #judicialPredPop let-modal>
                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="col-md-12">
                              <label> Attachment </label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Upload</th>
                                      <th>Remark</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let judicialFromGrp of getJudicial(taxPositionGrp); let j=index"
                                      [formGroup]="judicialFromGrp">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-9 nopadding">
                                            <div class="form-group">
                                              <input *ngIf="judicialFromGrp?.value?.isdocLocUploadedClicked" type="file"
                                                id="itemList({{i}}).judicialPredLoc({{j}}).fileLoc"
                                                class="filetype form-control" name="myfile"
                                                (change)="onFileSelect($event,'judicialFromGrp',j,taxPositionGrp)"
                                                [ngClass]="{ 'errorBorder': isSubmitted && this.directTaxPosFormGrp.value.itemList[i].judicialPredLoc[j].fileLoc.errors}">
                                              <p *ngIf="!judicialFromGrp?.value?.isdocLocUploadedClicked || this.directTaxPosFormGrp.value.itemList[i].judicialPredLoc[j].fileLoc"
                                                class="form-control pt10"
                                                [ngClass]="{ 'errorBorder': isSubmitted && this.directTaxPosFormGrp.value.itemList[i].judicialPredLoc[j].fileLoc.errors}"
                                                [title]="judicialFromGrp.value.fileName">
                                                {{getFileNameDta(this.directTaxPosFormGrp.value.itemList[i].judicialPredLoc[j].fileLoc)}}
                                              </p>
                                              <div
                                                *ngIf="isSubmitted && this.directTaxPosFormGrp.value.itemList[i].judicialPredLoc[j].fileLoc.errors">
                                                <span class="text-danger"
                                                  *ngIf="this.directTaxPosFormGrp.value.itemList[i].judicialPredLoc[j].fileLoc.errors.required">
                                                  Required </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-3 nopadding">
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon"
                                                *ngIf="this.directTaxPosFormGrp.value.itemList[i].judicialPredLoc[j].fileLoc"
                                                title="Download file"
                                                (click)="download(this.directTaxPosFormGrp.value.itemList[i].judicialPredLoc[j].fileLoc)"><i
                                                  class="fa fa-download"></i></a>
                                              <a class="downupIcon"
                                                *ngIf="!this.directTaxPosFormGrp.value.itemList[i].judicialPredLoc[j].fileLoc || this.directTaxPosFormGrp.value.itemList[i].judicialPredLoc[j].fileLoc"
                                                title="Upload file" (click)="uploadJudicial(i,j)"><i
                                                  class="fa fa-upload"></i></a>
                                              <a class="downupIcon" (click)="deleteJudicialPredFile(i,j)"
                                                *ngIf="(judicialFromGrp.value.fileLoc)"><i class="fa fa-trash"
                                                  aria-hidden="true"></i></a>
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      <!-- remark -->
                                      <td>
                                        <input type="text" class="form-control" formControlName="remarks"
                                          [title]="judicialFromGrp.value.remarks">
                                      </td>

                                      <ng-template #rmDocSubListPop let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmJudicialDiv(i, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Save</button>
                            </div>
                          </ng-template>

                          <ng-template #legalOpinionPop let-modal>
                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="col-md-12">
                              <label> Attachment </label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Upload</th>
                                      <th>Remark</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let legalFromGrp of getLegalOpinion(taxPositionGrp); let j=index"
                                      [formGroup]="legalFromGrp">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-9 nopadding">
                                            <div class="form-group">
                                              <input *ngIf="legalFromGrp?.value?.isdocLocUploadedClicked" type="file"
                                                id="itemList({{i}}).legalOpLoc({{j}}).fileLoc"
                                                class="filetype form-control" name="myfile"
                                                (change)="onFileSelect($event,'legalFromGrp',j,taxPositionGrp)"
                                                [ngClass]="{ 'errorBorder': isSubmitted && this.directTaxPosFormGrp.value.itemList[i].legalOpLoc[j].fileLoc.errors}">
                                              <p *ngIf="!legalFromGrp?.value?.isdocLocUploadedClicked || this.directTaxPosFormGrp.value.itemList[i].legalOpLoc[j].fileLoc"
                                                class="form-control pt10"
                                                [ngClass]="{ 'errorBorder': isSubmitted && this.directTaxPosFormGrp.value.itemList[i].legalOpLoc[j].fileLoc.errors}"
                                                [title]="legalFromGrp.value.fileName">
                                                {{getFileNameDta(this.directTaxPosFormGrp.value.itemList[i].legalOpLoc[j].fileLoc)}}
                                              </p>
                                              <div
                                                *ngIf="isSubmitted && this.directTaxPosFormGrp.value.itemList[i].legalOpLoc[j].fileLoc.errors">
                                                <span class="text-danger"
                                                  *ngIf="this.directTaxPosFormGrp.value.itemList[i].legalOpLoc[j].fileLoc.errors.required">
                                                  Required </span>
                                              </div>
                                            </div>
                                          </div>
                                          <div class="col-md-3 nopadding">
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon"
                                                *ngIf="this.directTaxPosFormGrp.value.itemList[i].legalOpLoc[j].fileLoc"
                                                title="Download file"
                                                (click)="download(this.directTaxPosFormGrp.value.itemList[i].legalOpLoc[j].fileLoc)"><i
                                                  class="fa fa-download"></i></a>
                                              <a class="downupIcon"
                                                *ngIf="!this.directTaxPosFormGrp.value.itemList[i].legalOpLoc[j].fileLoc || this.directTaxPosFormGrp.value.itemList[i].legalOpLoc[j].fileLoc"
                                                title="Upload file" (click)="uploadLegalOpinion(i,j)"><i
                                                  class="fa fa-upload"></i></a>
                                              <a class="downupIcon" (click)="deleteLegalOpinionFile(i,j)"
                                                *ngIf="(legalFromGrp.value.fileLoc)"><i class="fa fa-trash"
                                                  aria-hidden="true"></i></a>
                                            </label>
                                          </div>
                                        </div>
                                      </td>

                                      <!-- remark -->
                                      <td>
                                        <input type="text" class="form-control" formControlName="remarks"
                                          [title]="legalFromGrp.value.remarks">
                                      </td>

                                      <ng-template #rmDocSubListPop1 let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmLegalOpinionDiv(i, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Save</button>
                            </div>
                          </ng-template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-12 nopadding mt-2">
          <button class="btn btn-outline-primary" (click)="updateTaxPosData($event)"
            [ngClass]="{'disabled': (role === 'DT_Viewer') ||  (role === 'Viewer')}"
            *ngIf="navContext?.entityType == 'LEGAL'|| navContext?.entityType == 'Business'">Save & Update</button>
        </div>

      </div>
    </div>
  </div>
</div>