import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NoticeServiceService } from '../../services/notification.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
const ProceedingStatus = {
  'C': 'Closed',
  'O': 'Open'
}
@Component({
  selector: 'app-adj-detail-view',
  templateUrl: './adj-detail-view.component.html',
  styleUrls: ['./adj-detail-view.component.css']
})

export class AdjDetailViewComponent implements OnInit {
  proceedingStatus = {
    'C': 'Closed',
    'O': 'Open'
  }
  adjVcReqstId: any;
  adjData: any;
  constructor(
    private noticeService: NoticeServiceService,
    private activeSnapShot: ActivatedRoute,
    private router: Router,
  ) {
    this.adjVcReqstId = this.activeSnapShot.snapshot.params.adjVcReqstId;
  }

  ngOnInit(): void {
    this.adjListData();
  }
  //adj list data api
  adjListData() {
    const payload: any = {
      page: 0,
      size: 50,
      module: "DT",
      criterias: [{
        "p": "adjVcReqstId",
        "o": "in",
        "v": this.adjVcReqstId
      }]
    }
    this.noticeService.getadjListData(payload).subscribe((res) => {
      this.adjData = res.response.results[0];
    });
  }
  //adj list data api

  toNoticeDetail(noticeId: number, parentNoticeheaderSeqNo: any) {
    this.router.navigate(['/dtNmNoticeDetail', noticeId, parentNoticeheaderSeqNo]);
  }
}
