<!-- partial -->
<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="panel-head">
        <h1>GST Analytics</h1>
        <div class="filters-bar">
          <div class="filter-div">
            <div class="filter-item" style="width: 350px;">
              <label [ngClass]="{'highlightFilter' : filters.state.v !== null}">State
                <span
                (click)="clearFilter('state')" [ngClass]="{'highlightclose' : filters.state.v !== null}"
                class="clearBtn"><i class="fa fa-close"></i></span>
              </label>
              <ng-multiselect-dropdown [(ngModel)]="filters.state.v"  (onSelect)="setState()" (onDeSelect)="onDTtemSelect()"
              (onDeSelectAll)="clearFilter('state')"
              [placeholder]="'Select'" [settings]="dropdownSettings" [data]="stateNames">
              </ng-multiselect-dropdown>
            </div>
              <!--             
            <div class="filter-item">
              <label>Division </label>
              <select class="form-select" [(ngModel)]="div" (change)="setDiv($event)">
                <option [value]="">All</option>
                <ng-container *ngFor="let obj of uniqueGstinDivList">
                  <option [value]="obj">{{ obj }}</option>
                </ng-container>
              </select>
            </div> -->

            <div class="filter-item" style="width: 350px;">
              <label [ngClass]="{'highlightFilter' : filters.gstinDiv.v !== null}">Division
                <span
                (click)="clearFilter('gstinDiv')" [ngClass]="{'highlightclose' : filters.gstinDiv.v !== null}"
                class="clearBtn"><i class="fa fa-close"></i></span>
              </label>
              <ng-multiselect-dropdown [(ngModel)]="filters.gstinDiv.v" (onSelect)="setDiv()" (onDeSelect)="onDTtemSelect()"
              [placeholder]="'Select'" [settings]="dropdownSettings1" [data]="gstinDivList">
              </ng-multiselect-dropdown>
            </div>
          </div>
          <div class="action-div">
            <!-- <span class="clearData"><a (click)="clearfilter()">Clear Filter</a></span>  -->
            <!-- <span class="">
              <label class="switch">
                <input type="checkbox" id="togBtn" [(ngModel)]="selectedIRN" (click)="changeStatsValue(selectedIRN)">
                <div class="slider round"></div>
              </label>
            </span> -->
          
          </div>
        </div>
      </div>
      <div class="col-md-12 nopadding">
        <div class="row mb-2"></div>
        <div class="card">
          <div class="card-body">
            <div class="flex-justify mb-3 heading">
              <h3>DIVISION WISE DEMAND ANALYTICS</h3>
              <div class="d-flex justify-content-end btnPos">
              <div class="switch-box">
                <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }"
                  >₹ In Lakhs</label
                >
                <div class="form-check form-switch m-0">
                  <input
                    [(ngModel)]="selectedIRN"
                    (change)="changeStatsValue(selectedIRN, $event)"
                    class="form-check-input" 
                    type="checkbox"
                    role="switch"
                    [checked]="amountMetric === 'crore'"
                  />
                </div>
                <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }"
                  >Crores</label
                >
              </div>
                <p class="btn btn-outline-success btn-download" (click)="exportAsXLSX()" >
                  <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
                </p>
              </div>
            </div>

            <div class="row" *ngIf="isinLakh">
              <div class="table-responsive bgOverride">
                <table class="table table-bordered divTbl">
                  <thead>
                    <tr class="bgGred">
                      <th rowspan="2">Division</th>
                      <th colspan="2">Pre-Litigation</th>
                      <th colspan="2">Adjudication</th>
                      <th colspan="2">Commissioner (A)</th>
                      <th colspan="2">Appellate Tribunal</th>
                      <th colspan="2">High Court</th>
                      <th colspan="2">Supreme Court</th>
                      <th colspan="2">Total</th>
                    </tr>
                    <tr class="bgGred txtCenter">
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                    </tr>
                  </thead>
                  <tbody class="divTbody" >
                    <ng-container *ngFor="let obj of regiondata">
                      <tr>
                        <td>
                          {{ obj.division }}
                          <a class="letterTbl"
                            ><span
                              class="expand"
                              (click)="obj.expanded = !obj.expanded"
                              >{{ obj.expanded ? '&ndash;' : '+' }}</span
                            ></a
                          >
                        </td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'scn',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'scn', '')
                            "
                            >{{ obj.phases?.scn?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.scn?.total | INR: 2  }}</td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'aja',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'aja', '')
                            "
                            >{{ obj.phases?.aja?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.aja?.total | INR: 2  }}</td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'apa',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'apa', '')
                            "
                            >{{ obj.phases?.apa?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.apa?.total | INR: 2  }}</td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'apt',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'apt', '')
                            "
                            >{{ obj.phases?.apt?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.apt?.total | INR: 2  }}</td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'hc',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'hc', '')
                            "
                            >{{ obj.phases?.hc?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.hc?.total | INR: 2  }}</td>
                        <td class="txtCenter">
                          <a
                            (click)=" selectedState ? openCaseState(demandOpenCase, obj.division,'sc',
                                    selectedState ): openCase(demandOpenCase, obj.division, 'sc', '')"
                            >{{ obj.phases?.sc?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.sc?.total | INR: 2  }}</td>
                        <td class="totalBold txtCenter"><a (click)=" selectedState ? allopenCaseState(demandOpenCasePop, obj.division): 
                        allOpenCase(demandOpenCasePop,'',obj.division)">{{ obj.totalCases }}</a></td>
                        <td class="totalBold">{{ obj.totalDivision | INR: 2  }}</td>
                      </tr>
                      <ng-container *ngIf="obj.expanded">
                        <tr *ngFor="let item of obj.gstinData" class="expandTr">
                          <td class="pl-4">
                            {{ item.gstin }}
                          </td>
                          <td class="txtCenter">
                            <a
                              (click)=" openCase(demandOpenCase, obj.division, 'scn', item.gstin)
                              "
                              >{{ item.phaseSummary?.scn?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.scn?.amount | INR: 2  }}</td>
                          <td class="txtCenter">
                            <a
                              (click)=" openCase(demandOpenCase, obj.division, 'aja', item.gstin)
                              "
                              >{{ item.phaseSummary?.aja?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.aja?.amount | INR: 2 }}</td>
                          <td class="txtCenter">
                            <a
                              (click)=" openCase(demandOpenCase, obj.division,'apa', item.gstin)
                              "
                              >{{ item.phaseSummary?.apa?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.apa?.amount | INR: 2  }}</td>
                          <td class="txtCenter">
                            <a
                              (click)="openCase(demandOpenCase, obj.division, 'apt', item.gstin)
                              "
                              >{{ item.phaseSummary?.apt?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.apt?.amount | INR: 2  }}</td>
                          <td class="txtCenter">
                            <a
                              (click)=" openCase(demandOpenCase, obj.division, 'hc', item.gstin)
                              "
                              >{{ item.phaseSummary?.hc?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.hc?.amount | INR: 2  }}</td>
                          <td class="txtCenter">
                            <a
                              (click)="
                                 openCase(demandOpenCase, obj.division, 'sc', item.gstin)"
                              >{{ item.phaseSummary?.sc?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.sc?.amount | INR: 2  }}</td>
                          <td class="totalBold txtCenter"><a (click)="allOpenCase(demandOpenCasePop, item.gstin,obj.division)">{{ item.totalNotices }}</a></td>
                          <td class="totalBold">{{ item.totalAmount | INR: 2 }}</td>
                        </tr>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="divSumColTotal">
                      <tr>
                        <td class="totalBold">TOTAL</td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.scn?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.scn?.total | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.aja?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.aja?.total | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.apa?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.apa?.total | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.apt?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.apt?.total | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.hc?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.hc?.total | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.sc?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.sc?.total | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotal.totalCases }}</td>
                        <td class="totalBold">
                          {{ divSumColTotal.totalDemand | INR: 2 }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row" *ngIf="isinCR">
              <div class="table-responsive">
                <table class="table table-bordered divTbl">
                  <thead>
                    <tr class="bgGred">
                      <th rowspan="2">Division</th>
                      <th colspan="2">Pre-Litigation</th>
                      <th colspan="2">Adjudication</th>
                      <th colspan="2">Commissioner (A)</th>
                      <th colspan="2">Appellate Tribunal</th>
                      <th colspan="2">High Court</th>
                      <th colspan="2">Supreme Court</th>
                      <th colspan="2">Total</th>
                    </tr>
                    <tr class="bgGred txtCenter">
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                      <th>Open Cases</th>
                      <th>Demand Amount </th>
                    </tr>
                  </thead>
                  <tbody class="divTbody" >
                    <ng-container *ngFor="let obj of regiondata">
                      <tr>
                        <td>
                          {{ obj.division }}
                          <a class="letterTbl"
                            ><span
                              class="expand"
                              (click)="obj.expanded = !obj.expanded"
                              >{{ obj.expanded ? '&ndash;' : '+' }}</span
                            ></a
                          >
                        </td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'scn',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'scn', '')
                            "
                            >{{ obj.phases?.scn?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.scn?.totalInCr | INR: 2  }}</td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'aja',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'aja', '')
                            "
                            >{{ obj.phases?.aja?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.aja?.totalInCr | INR: 2  }}</td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'apa',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'apa', '')
                            "
                            >{{ obj.phases?.apa?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.apa?.totalInCr | INR: 2  }}</td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'apt',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'apt', '')
                            "
                            >{{ obj.phases?.apt?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.apt?.totalInCr | INR: 2  }}</td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'hc',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'hc', '')
                            "
                            >{{ obj.phases?.hc?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.hc?.totalInCr | INR: 2  }}</td>
                        <td class="txtCenter">
                          <a
                            (click)="
                              selectedState
                                ? openCaseState(
                                    demandOpenCase,
                                    obj.division,
                                    'sc',
                                    selectedState
                                  )
                                : openCase(demandOpenCase, obj.division, 'sc', '')
                            "
                            >{{ obj.phases?.sc?.count }}</a
                          >
                        </td>
                        <td>{{ obj.phases?.sc?.totalInCr | INR: 2  }}</td>
                        <td class="totalBold txtCenter"><a (click)="selectedState ? allopenCaseState(demandOpenCasePop, obj.division): 
                        allOpenCase(demandOpenCasePop, '',obj.division)">{{ obj.totalCases }}</a></td>
                        <td class="totalBold">{{ obj.totalDivisionInCr | INR: 2 }}</td>
                      </tr>
                      <ng-container *ngIf="obj.expanded">
                        <tr *ngFor="let item of obj.gstinData" class="expandTr">
                          <td class="pl-4">
                            {{ item.gstin }}
                          </td>
                          <td class="txtCenter">
                            <a
                              (click)=" openCase(demandOpenCase, obj.division, 'scn', item.gstin)
                              "
                              >{{ item.phaseSummary?.scn?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.scn?.amounInCr | INR: 2  }}</td>
                          <td class="txtCenter">
                            <a
                              (click)=" openCase(demandOpenCase, obj.division, 'aja', item.gstin)"
                              >{{ item.phaseSummary?.aja?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.aja?.amounInCr | INR: 2  }}</td>
                          <td class="txtCenter">
                            <a
                              (click)=" openCase(demandOpenCase, obj.division, 'apa', item.gstin)"
                              >{{ item.phaseSummary?.apa?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.apa?.amounInCr | INR: 2  }}</td>
                          <td class="txtCenter">
                            <a
                              (click)=" openCase(demandOpenCase, obj.division, 'apt', item.gstin)
                              "
                              >{{ item.phaseSummary?.apt?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.apt?.amounInCr | INR: 2  }}</td>
                          <td class="txtCenter">
                            <a
                              (click)=" openCase(demandOpenCase, obj.division, 'hc', item.gstin)
                              "
                              >{{ item.phaseSummary?.hc?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.hc?.amounInCr | INR: 2  }}</td>
                          <td class="txtCenter">
                            <a
                              (click)=" openCase(demandOpenCase, obj.division, 'sc', item.gstin)
                              "
                              >{{ item.phaseSummary?.sc?.numberOfNotices }}</a
                            >
                          </td>
                          <td>{{ item.phaseSummary?.sc?.amounInCr | INR: 2  }}</td>
                          <td class="totalBold txtCenter"><a (click)="allOpenCase(demandOpenCasePop, item.gstin,obj.division)">{{ item.totalNotices }}</a></td>
                          <td class="totalBold">{{ item.totalAmountInCr | INR: 2  }}</td>
                        </tr>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="divSumColTotal">
                      <tr>
                        <td class="totalBold">TOTAL</td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.scn?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.scn?.totalInCr | INR: 2  }} </td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.aja?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.aja?.totalInCr | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.apa?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.apa?.totalInCr | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.apt?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.apt?.totalInCr | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.hc?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.hc?.totalInCr | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotalSum?.sc?.count }}</td>
                        <td class="totalBold">
                          {{ divSumColTotalSum?.sc?.totalInCr | INR: 2  }}
                        </td>
                        <td class="totalBold txtCenter">{{ divSumColTotal.totalCases }}</td>
                        <td class="totalBold">
                          {{ divSumColTotal.totalDemandInCr | INR: 2  }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row col-md-12 nopadding mt15">
        <div class="col-md-6">
          <div class="card" style="height: 90%;">
            <div class="card-body">
              <div class="flex-justify mb-3 heading">
                <h3 class="">
                  Aging Analysis - Inception
                  <a
                    class="clrOrange infoIcon"
                    title="This chart shows age wise classification of open cases as per the date of receipt of first Letter/Notice/Order of the case. User can drill down the case through Case ID"
                    ><i class="fa fa-info-circle" style="margin-left: 3px"></i
                  ></a>
                </h3>
              </div>

              <div class="chart-box">
                <div class="chart-canvas">
                  <canvas
                    class="chart"
                    baseChart
                    id="agingPiechart"
                    style="cursor: pointer"
                    [data]="laaInPieChartData"
                    [type]="laaInPieChartType"
                    [options]="laaInPieChartOptions"
                    (chartClick)="openInceptPieChart(agingInAnalysis, $event)"
                  >
                  </canvas>
                </div>
                <div class="chart-legends mb-5">
                  <div class="legend-item" *ngFor="let legend of legends">
                    <p><span [style]="{ color: legend.color }">&#9679;</span> {{ legend.label }}</p>
                    <p>{{ legend.value }} ({{ legend.percent }}%)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card" style="height: 90%;">
            <div class="card-body">
              <div class="flex-justify mb-3 heading">
                <h3 class="">
                  Aging Analysis - Current Forum
                  <a
                    class="clrOrange infoIcon"
                    title="This chart shows age wise classification of open cases as per the date of receipt of Letter/Notice/Order of the case as per the current forum. User can drill down the case through Case ID"
                    ><i class="fa fa-info-circle" style="margin-left: 3px"></i
                  ></a>
                </h3>
              </div>

              <div class="chart-box">
                <div class="chart-canvas">
                  <canvas
                    baseChart
                    style="cursor: pointer"
                    [data]="laaPieChartData"
                    [type]="laaPieChartType"
                    [options]="laaPieChartOptions"
                    (chartClick)="openPieChart(agingAnalysis, $event)"
                  >
                  </canvas>
                </div>
                <div class="chart-legends mb-5">
                  <div class="legend-item" *ngFor="let legend of legend">
                    <p><span [style]="{ color: legend.color }">&#9679;</span> {{ legend.label }}</p>
                    <p>{{ legend.value }} ({{ legend.percent }}%)</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card issueWise">
            <div class="card-body">
              <div class="flex-justify mb-3 heading">
                <h3 class="">
                  ISSUE WISE DEMAND - TOP 15 ISSUES
                  <a
                    class="clrOrange infoIcon"
                    title="This chart shows list of top 15 issues,  which have more demand for open cases only. List of the cases will be presented on click of particular issue bar."
                    ><i class="fa fa-info-circle" style="margin-left: 3px"></i
                  ></a>
                </h3>
                <div class="d-flex justify-content-end my-3 me-3 btnPos">
                  <div class="switch-box">
                    <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }"
                      >₹ In Lakhs</label
                    >
                    <div class="form-check form-switch m-0">
                      <input
                        [(ngModel)]="selectedIRN"
                        (change)="changeStatsValue(selectedIRN, $event)"
                        class="form-check-input" 
                        type="checkbox"
                        role="switch"
                        [checked]="amountMetric === 'crore'"
                      />
                    </div>
                    <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }"
                      >Crores</label
                    >
                  </div>
                </div>
         
              </div>

            
              <div class="chartjs-container" >
                <canvas  *ngIf="isinLakh"
                  baseChart
                  style="cursor: pointer"
                  [options]="listIssueBarChartOptions"
                  [data]="listIssueBbarChartData"
                  [type]="listIssueBarChartType"
                  (chartClick)="getIssueLabel(issuePop,listIssueBarChartLabels, $event)"
                >
                </canvas>

                <canvas  *ngIf="isinCR"
                baseChart
                style="cursor: pointer"
                [options]="listIssueBarChartOptions"
                [data]="listIssueBbarChartData"
                [type]="listIssueBarChartType"
                (chartClick)="getIssueLabel(issuePop,listIssueBarChartLabels, $event)"
              >
              </canvas>
            </div>


             

            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card" style="height: 100%;">
            <div class="card-body">
              <div class="flex-justify mb-3 heading">
                <h3 class="">
                  Issue Wise Demand- Pie Chart
                  <a
                    class="clrOrange infoIcon"
                    title="This chart shows list of all issues for open cases only. List of the cases will be presented on click of particular pie."
                    ><i class="fa fa-info-circle" style="margin-left: 3px"></i
                  ></a>
                </h3>
                <div class="d-flex justify-content-end btnPos">
                  <div class="switch-box">
                    <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }"
                      >₹ In Lakhs</label
                    >
                    <div class="form-check form-switch m-0">
                      <input
                        [(ngModel)]="selectedIRN"
                        (change)="changeStatsValue(selectedIRN, $event)"
                        class="form-check-input" 
                        type="checkbox"
                        role="switch"
                        [checked]="amountMetric === 'crore'"
                      />
                    </div>
                    <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }"
                      >Crores</label
                    >
                  </div>
                  <p class="btn btn-outline-success btn-download" (click)="issueWiseExportToExcel()" >
                    <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
                  </p>
                </div>
              </div>

              <div class="chart-box" style="display: flex; justify-content: center; align-items: center; height: 100%;">
                <div class="chart-canvas">
                  <canvas
                    class="chart"
                    baseChart
                    id="agingPiechart"
                    style="cursor: pointer"
                    [data]="iwdInPieChartData"
                    [type]="iwdInPeiChartType"
                    [options]="iwdInPieChartOptions"
                    (chartClick)="getIssueLabel(issuePop,iwdInPieChartLabels, $event)"
                  >
                  </canvas>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8 mt15">
          <div class="card">
            <div class="card-body">
              <div class="flex-justify mb-3 heading">
                <h3 class="">
                  Analysis of cumulative payment made
                  <a
                    class="clrOrange infoIcon"
                    title="This table shows GSTIN wise total demand for open cases as per current forum and cumulative payment made Under Pre-deposit, protest and for liability admitted till the date."
                    ><i class="fa fa-info-circle" style="margin-left: 3px"></i
                  ></a>
                </h3>
                <div class="d-flex justify-content-end my-3 me-3 btnPos">
                  <div class="switch-box">
                    <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }"
                      >₹ In Lakhs</label
                    >
                    <div class="form-check form-switch m-0">
                      <input
                        [(ngModel)]="selectedIRN"
                        (change)="changeStatsValue(selectedIRN, $event)"
                        class="form-check-input" 
                        type="checkbox"
                        role="switch"
                        [checked]="amountMetric === 'crore'"
                      />
                    </div>
                    <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }"
                      >Crores</label
                    >
                  </div>
                </div>
              </div>

              <div class="row" *ngIf="isinLakh">
                <div class="table-responsive">
                  <table class="table table-bordered divTbl">
                    <thead>
                      <tr class="bgGred">
                        <th>Division & GSTIN</th>
                        <th>Current Stage Demand Amount</th>
                        <th>Pre Deposit Amount</th>
                        <th>Admitted Liability Amount</th>
                        <th>Paid Under Protest Amount</th>
                      </tr>
                    </thead>
                    <tbody class="divTbody2">
                      <ng-container *ngFor="let obj of paymentRegiondata">
                        <tr>
                          <td style="background: #eee">
                            <a class="letterTbl" (click)="obj.expanded = !obj.expanded">{{ obj.division}}<span class="expand">{{
                                obj.expanded ? '&ndash;' : '+' }}</span></a
                            >
                          </td>
                          <td class="textRight">
                            {{obj.demand | INR : 2}}
                          </td>
                          <td class="textRight">
                            {{obj.cPredeposit| INR : 2}}
                          </td>
                          <td class="textRight">
                            {{obj.aLiability| INR : 2}}
                          </td>
                          <td class="textRight">
                            {{obj.pAmount| INR : 2  }}
                          </td>
                        </tr>
                        <ng-container *ngIf="obj.expanded">
                          <tr *ngFor="let item of obj.paymentGstinData" class="expandTr">
                            <td class="pl-4">
                              {{
                                 item.gstin
                              }}
                            </td>
                            <td class="textRight">
                              {{ item.rowSummary.demand | INR : 2  }}
                            </td>
                            <td class="textRight">
                              {{ item.rowSummary.cPredeposit | INR : 2 }}
                            </td>
                            <td class="textRight">
                              {{ item.rowSummary.aLiability | INR : 2 }}
                            </td>
                            <td class="textRight">
                              {{ item.rowSummary.pAmount | INR : 2 }}
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="liabilityTotal">
                        <tr style="background: #ede8e8; font-weight: 600">
                          <td>TOTAL</td>
                          <td class="textRight">{{ liabilityTotal.demand | INR : 2}}</td>
                          <td class="textRight">
                            {{ liabilityTotal.cPredeposit| INR : 2}}
                          </td>
                          <td class="textRight">
                            {{ liabilityTotal.aLiability | INR : 2}}
                          </td>
                          <td class="textRight">
                            {{ liabilityTotal.pAmount | INR : 2}}
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row" *ngIf="isinCR">
                <div class="table-responsive">
                  <table class="table table-bordered divTbl">
                    <thead>
                      <tr class="bgGred">
                        <th>Division & GSTIN</th>
                        <th>Current Stage Demand </th>
                        <th>Pre Deposit Amount</th>
                        <th>Admitted Liability Amount</th>
                        <th>Paid Under Protest Amount</th>
                      </tr>
                    </thead>
                    <tbody class="divTbody2">
                      <ng-container *ngFor="let obj of paymentRegiondata">
                        <tr>
                          <td  style="background: #eee">
                            <a class="letterTbl" (click)="obj.expanded = !obj.expanded"
                              >{{ obj.division
                              }}<span class="expand">{{
                                obj.expanded ? '&ndash;' : '+'
                              }}</span></a
                            >
                          </td>
                          <td class="textRight">
                            {{obj.demandInCr | INR : 2}}
                          </td>
                          <td class="textRight">
                            {{obj.cPredepositInCr | INR : 2}}
                          </td>
                          <td class="textRight">
                            {{obj.aLiabilityInCr | INR : 2}}
                          </td>
                          <td class="textRight">
                            {{obj.pAmountInCr | INR : 2}}
                          </td>
                        </tr>
                        <ng-container *ngIf="obj.expanded">
                          <tr *ngFor="let item of obj.paymentGstinData" class="expandTr">
                            <td class="pl-4">
                              {{
                               item.gstin
                              }}
                            </td>
                            <td class="textRight">
                              {{ item.rowSummary.demandInCr | INR : 2}}
                            </td>
                            <td class="textRight">
                              {{ item.rowSummary.cPredepositInCr | INR : 2}}
                            </td>
                            <td class="textRight">
                              {{ item.rowSummary.aLiabilityInCr | INR : 2}}
                            </td>
                            <td class="textRight">
                              {{ item.rowSummary.pAmountInCr | INR : 2}}
                            </td>
                          </tr>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="liabilityTotal">
                        <tr style="background: #ede8e8; font-weight: 600">
                          <td>TOTAL</td>
                          <td class="textRight">
                            {{ liabilityTotal.demandInCr | INR : 2}}
                          </td>
                          <td class="textRight">
                            {{ liabilityTotal.cPreDepositInCr | INR : 2}}
                          </td>
                          <td class="textRight">
                            {{ liabilityTotal.aLiabilityInCr | INR : 2}}
                          </td>
                          <td class="textRight">
                            {{ liabilityTotal.pAmountInCr | INR : 2}}
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #agingAnalysis let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Litigation Aging Analysis - Current Forum
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <div class="switch-box" style="float: inline-end">
          <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
          <div class="form-check form-switch m-0">
            <input
              [(ngModel)]="selectedIRN"
              (change)="changeStatsValue(selectedIRN, $event)"
              class="form-check-input"
              type="checkbox"
              role="switch"
              [checked]="amountMetric === 'crore'"
            />
          </div>
          <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
          <p class="btn btn-outline-success btn-download" (click)="agingAnalysisExportAsXLSX('Current Forum')" >
            <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
          </p>
        </div>
        <p class="pieChartLabel txtCenter text-uppercase">{{ currentLabel }}</p>
        <div class="table-responsive autoScroll">
          <table id="" class="table table-bordered">
            <thead class="header">
              <tr class="bgGred">
                <th>Sr No.</th>
                <th>
                  <span>Division</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.DIVISION)"
                    *ngIf="isSortCurrentData"
                  ></i>
                  <i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.DIVISION)"
                    *ngIf="!isSortCurrentData"
                  ></i>
                </th>
                <th>
                  <span>State</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.STATE)"
                    *ngIf="isSortCurrentData"
                  ></i>
                  <i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.STATE)"
                    *ngIf="!isSortCurrentData"
                  ></i>
                </th>
                <th>
                  <span>Forum</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.FORUM)"
                    *ngIf="isSortCurrentData"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.FORUM)"
                    *ngIf="!isSortCurrentData"
                  ></i>
                </th>
                <th>
                  <span>GSTIN</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.GSTIN)"
                    *ngIf="isSortCurrentData"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.GSTIN)"
                    *ngIf="!isSortCurrentData"
                  ></i>
                </th>
                <th style="min-width: 128px">
                  <span>Case ID</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.CASE_ID)"
                    *ngIf="isSortCurrentData"
                  ></i>
                  <i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.CASE_ID)"
                    *ngIf="!isSortCurrentData"
                  ></i>
                </th>
                <th>
                  <span>Case Label</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.CASE_LABEL)"
                    *ngIf="isSortCurrentData"
                  ></i>
                  <i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.CASE_LABEL)"
                    *ngIf="!isSortCurrentData"
                  ></i>
                </th>
                <th>
                  <span>Demand Amount </span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.DEMAND_AMOUNT)"
                    *ngIf="isSortCurrentData"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.DEMAND_AMOUNT)"
                    *ngIf="!isSortCurrentData"
                  ></i>
                </th>
                <th>
                  <span>Inception Date</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.INCEPTION_DATE)"
                    *ngIf="isAnalyticsConOne"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortCurrentData(agingList.INCEPTION_DATE)"
                    *ngIf="!isAnalyticsConOne"
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <tr *ngFor="let obj of ageingPopSummary; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ obj.division }}</td>
                  <td>{{ obj.state }}</td>
                  <td>{{ obj.type }}</td>
                  <td>{{ obj.gstin }}</td>
                  <td *ngIf="obj.type == 'Pre-Litigation'">
                    <a (click)="getagingCaseData(obj.caseId, 'update', 'scn', 'tab1')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'Adjudication'">
                    <a (click)="getagingCaseData(obj.caseId, 'update', 'aja', 'tab2')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'Commissioner (A)'">
                    <a (click)="getagingCaseData(obj.caseId, 'update', 'apa', 'tab3')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'Appellate Tribunal'">
                    <a (click)="getagingCaseData(obj.caseId, 'update', 'apt', 'tab4')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'High Court'">
                    <a (click)="getagingCaseData(obj.caseId, 'update', 'hc', 'tab5')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'Supreme  Court'">
                    <a (click)="getagingCaseData(obj.caseId, 'update', 'sc', 'tab6')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td>{{ obj.label }}</td>
                  <td *ngIf="isinLakh" class="textRight">{{ obj.amount | INR: 2  }}</td>
                  <td *ngIf="isinCR" class="textRight"> {{ obj.amountInCrores | INR: 2 }}</td>
                  <td>{{ obj.inceptionDate }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div> -->
    </ng-template>
    <ng-template #agingInAnalysis let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Litigation Aging Analysis - Inception
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <div class="switch-box" style="float: inline-end">
          <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
          <div class="form-check form-switch m-0">
            <input
              [(ngModel)]="selectedIRN"
              (change)="changeStatsValue(selectedIRN, $event)"
              class="form-check-input"
              type="checkbox"
              role="switch"
              [checked]="amountMetric === 'crore'"
            />
          </div>
          <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
          <p class="btn btn-outline-success btn-download" (click)="agingAnalysisExportAsXLSX('Inception')" >
            <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
          </p>
        </div>
        <p class="pieChartLabel txtCenter text-uppercase">{{ currentLabel }}</p>
        <div class="table-responsive autoScroll">
          <table id="" class="table table-bordered">
            <thead class="header">
              <tr class="bgGred">
                <th>Sr No.</th>
                <th>
                  <span>Division</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.DIVISION)"
                    *ngIf="isAnalyticsConOne"
                  ></i>
                  <i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.DIVISION)"
                    *ngIf="!isAnalyticsConOne"
                  ></i>
                </th>
                <th>
                  <span>State</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.STATE)"
                    *ngIf="isAnalyticsConOne"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.STATE)"
                    *ngIf="!isAnalyticsConOne"
                  ></i>
                </th>
                <th>
                  <span>Current Forum</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.FORUM)"
                    *ngIf="isAnalyticsConOne"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.FORUM)"
                    *ngIf="!isAnalyticsConOne"
                  ></i>
                </th>
                <th>
                  <span>GSTIN</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.GSTIN)"
                    *ngIf="isAnalyticsConOne"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.GSTIN)"
                    *ngIf="!isAnalyticsConOne"
                  ></i>
                </th>
                <th style="min-width: 128px">
                  <span>Case ID</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.CASE_ID)"
                    *ngIf="isAnalyticsConOne"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.CASE_ID)"
                    *ngIf="!isAnalyticsConOne"
                  ></i>
                </th>
                <th>
                  <span>Case Label</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.CASE_LABEL)"
                    *ngIf="isAnalyticsConOne"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.CASE_LABEL)"
                    *ngIf="!isAnalyticsConOne"
                  ></i>
                </th>
                <th>
                  <span>Demand Amount </span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.DEMAND_AMOUNT)"
                    *ngIf="isAnalyticsConOne"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.DEMAND_AMOUNT)"
                    *ngIf="!isAnalyticsConOne"
                  ></i>
                </th>
                <th>
                  <span>Inception Forum </span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.FORUM)"
                    *ngIf="isAnalyticsConOne"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.INCEPTION_FORUM)"
                    *ngIf="!isAnalyticsConOne"
                  ></i>
                </th>
                <th>
                  <span>Inception Date</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.FORUM)"
                    *ngIf="isAnalyticsConOne"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sortAging(agingList.INCEPTION_DATE)"
                    *ngIf="!isAnalyticsConOne"
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <tr *ngFor="let obj of ageingInPopSummary; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ obj.division }}</td>
                  <td>{{ obj.state }}</td>
                  <td>{{ obj.currentForum }}</td>
                  <td>{{ obj.gstin }}</td>
                  <td *ngIf="obj.currentForum == 'Pre-Litigation'">
                    <a
                      (click)="getagingInCaseData(obj.caseId, 'update', 'scn', 'tab1')"
                      >{{ obj.caseId }}</a
                    >
                  </td>
                  <td *ngIf="obj.currentForum == 'Adjudication'">
                    <a
                      (click)="getagingInCaseData(obj.caseId, 'update', 'aja', 'tab2')"
                      >{{ obj.caseId }}</a
                    >
                  </td>
                  <td *ngIf="obj.currentForum == 'Commissioner (A)'">
                    <a
                      (click)="getagingInCaseData(obj.caseId, 'update', 'apa', 'tab3')"
                      >{{ obj.caseId }}</a
                    >
                  </td>
                  <td *ngIf="obj.currentForum == 'Appellate Tribunal'">
                    <a
                      (click)="getagingInCaseData(obj.caseId, 'update', 'apt', 'tab4')"
                      >{{ obj.caseId }}</a
                    >
                  </td>
                  <td *ngIf="obj.currentForum == 'High Court'">
                    <a (click)="getagingInCaseData(obj.caseId, 'update', 'hc', 'tab5')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.currentForum == 'Supreme Court'">
                    <a (click)="getagingInCaseData(obj.caseId, 'update', 'sc', 'tab6')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td>{{ obj.label }}</td>
                  <td *ngIf="isinLakh" class="textRight">
                    {{ obj.amountInLakhs | INR: 2 }}
                  </td>
                  <td *ngIf="isinCR" class="textRight">
                    {{ obj.amountInCrores | INR: 2  }}
                  </td>
                  <td>{{ obj.inceptionForum }}</td>
                  <td>{{ obj.inceptionDate }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div> -->
    </ng-template>
    <ng-template #caseReportDetail let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Cases Report Details</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <div class="table-responsive autoScroll">
          <table id="" class="table table-bordered">
            <thead>
              <tr class="bgGred">
                <th>Issue Involved</th>
                <th>Total Demand</th>
                <th>Amount already paid</th>
                <th>Contigent Liabilty</th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <tr *ngFor="let obj of detaildata; let i = index">
                  <td>{{ obj.issue }}</td>
                  <td class="txtright">{{ obj.totalDemand | currency : '₹' : '' }}</td>
                  <td class="txtright">{{ obj.totalPaid | currency : '₹' : '' }}</td>
                  <td class="txtright">{{ obj.totalContingency }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div> -->
    </ng-template>
    <ng-template #demandOpenCase let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Division wise Demand - Open Cases
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <div class="switch-box" style="float: inline-end">
          <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
          <div class="form-check form-switch m-0">
            <input
              [(ngModel)]="selectedIRN"
              (change)="changeStatsValue(selectedIRN, $event)"
              class="form-check-input"
              type="checkbox"
              role="switch"
              [checked]="amountMetric === 'crore'"
            />
          </div>
          <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>

          <p class="btn btn-outline-success btn-download" (click)="agingAnalysisExportAsXLSX('Open Cases')" >
            <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
          </p>
        </div>
        <div class="table-responsive autoScroll">
          <table id="" class="table table-bordered">
            <thead class="header">
              <tr class="bgGred">
                <th><span>Sr No.</span></th>
                <th>
                  <span>Division</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DIVISIONS)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DIVISIONS)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>State</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.STATE)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.STATE)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>GSTIN</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.GSTIN)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.GSTIN)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>Case ID</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_ID)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_ID)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>Case Label</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_LABEL)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_LABEL)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>Demand Amount </span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DEMAND_AMOUNT)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DEMAND_AMOUNT)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <tr *ngFor="let obj of openCaseData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ obj.division }}</td>
                  <td>{{ obj.state }}</td>
                  <td>{{ obj.gstin }}</td>
                  <td *ngIf="obj.type == 'scn'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'scn', 'tab1')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'aja'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'aja', 'tab2')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'apa'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'apa', 'tab3')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'apt'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'apt', 'tab4')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'hc'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'hc', 'tab5')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'sc'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'sc', 'tab6')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td>{{ obj.label }}</td>
                  <td *ngIf="isinLakh" class="textRight">{{ obj.amount | INR: 2  }}</td>
                  <td *ngIf="isinCR" class="textRight">{{ obj.amountInCr | INR: 2 }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div> -->
    </ng-template>
    <ng-template #demandOpenCasePop let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Division wise Demand - Open Cases
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <div class="switch-box" style="float: inline-end">
          <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
          <div class="form-check form-switch m-0">
            <input
              [(ngModel)]="selectedIRN"
              (change)="changeStatsValue(selectedIRN, $event)"
              class="form-check-input"
              type="checkbox"
              role="switch"
              [checked]="amountMetric === 'crore'"
            />
          </div>
          <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>

          <p class="btn btn-outline-success btn-download" (click)="agingAnalysisExportAsXLSX('All Open Cases')" >
            <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
          </p>
        </div>
        <div class="table-responsive autoScroll">
          <table id="" class="table table-bordered">
            <thead class="header">
              <tr class="bgGred">
                <th><span>Sr No.</span></th>
                <th>
                  <span>Division</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DIVISIONS)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DIVISIONS)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>State</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.STATE)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.STATE)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>GSTIN</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.GSTIN)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.GSTIN)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>Forum</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.FORUM)"
                    *ngIf="isSortCurrentData"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.FORUM)"
                    *ngIf="!isSortCurrentData"
                  ></i>
                </th>
                <th>
                  <span>Case ID</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_ID)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_ID)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>Case Label</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_LABEL)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_LABEL)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>Demand Amount </span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DEMAND_AMOUNT)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DEMAND_AMOUNT)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <tr *ngFor="let obj of openCaseData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ obj.division }}</td>
                  <td>{{ obj.state }}</td>
                  <td>{{ obj.gstin }}</td>
                  <td>{{ obj.type }}</td>
                  <td *ngIf="obj.type == 'Pre-Litigation'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'scn', 'tab1')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'Adjudication'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'aja', 'tab2')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'Commissioner (A)'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'apa', 'tab3')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'Appellate Tribunal'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'apt', 'tab4')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'High Court'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'hc', 'tab5')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'Supreme  Court'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'sc', 'tab6')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td>{{ obj.label }}</td>
                  <td *ngIf="isinLakh" class="textRight">{{ obj.amount | INR: 2  }}</td>
                  <td *ngIf="isinCR" class="textRight">{{ obj.amountInCr | INR: 2 }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div> -->
    </ng-template>
    <ng-template #issuePop let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">
          Division wise Demand - Open Cases
        </h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
      </div>
      <div class="modal-body">
        <div class="switch-box" style="float: inline-end">
          <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
          <div class="form-check form-switch m-0">
            <input
              [(ngModel)]="selectedIRN"
              (change)="changeStatsValue(selectedIRN, $event)"
              class="form-check-input"
              type="checkbox"
              role="switch"
              [checked]="amountMetric === 'crore'"
            />
          </div>
          <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>

          <p class="btn btn-outline-success btn-download" (click)="agingAnalysisExportAsXLSX('Open Cases')" >
            <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
          </p>
        </div>
        <p class="pieChartLabel txtCenter text-uppercase">{{ currentIssueLabel }}</p>
        <div class="table-responsive autoScroll">
          <table id="" class="table table-bordered">
            <thead class="header">
              <tr class="bgGred">
                <th><span>Sr No.</span></th>
                <th>
                  <span>Division</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DIVISIONS)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DIVISIONS)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>State</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.STATE)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.STATE)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>GSTIN</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.GSTIN)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.GSTIN)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>Case ID</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_ID)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_ID)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>Case Label</span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_LABEL)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.CASE_LABEL)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
                <th>
                  <span>Demand Amount </span
                  ><i
                    class="fa fa-arrow-up cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DEMAND_AMOUNT)"
                    *ngIf="isAnalyticsSort"
                  ></i
                  ><i
                    class="fa fa-arrow-down cursor-pointer"
                    aria-hidden="true"
                    (click)="sort(divisionList.DEMAND_AMOUNT)"
                    *ngIf="!isAnalyticsSort"
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <tr *ngFor="let obj of openCaseData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ obj.division }}</td>
                  <td>{{ obj.state }}</td>
                  <td>{{ obj.gstin }}</td>
                  <td *ngIf="obj.type == 'scn'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'scn', 'tab1')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'aja'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'aja', 'tab2')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'apa'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'apa', 'tab3')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'apt'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'apt', 'tab4')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'hc'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'hc', 'tab5')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td *ngIf="obj.type == 'sc'">
                    <a (click)="getOpenCase(obj.caseId, 'update', 'sc', 'tab6')">{{
                      obj.caseId
                    }}</a>
                  </td>
                  <td>{{ obj.label }}</td>
                  <td *ngIf="isinLakh" class="textRight">{{ obj.amount | INR: 2  }}</td>
                  <td *ngIf="isinCR" class="textRight">{{ obj.amountInCr | INR: 2 }}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
      <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Ok</button>
  </div> -->
    </ng-template>
  </div>
</div>
