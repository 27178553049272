<!-- <app-filter (messageEvent)="receiveFilter($event)" [filterData]="filterObj" ></app-filter>  -->
<app-filter [isAudit]=true (messageEvent)="onFilterEvent($event)" [filterData]="filterObj"
  *ngIf="isFilterDivOpen"></app-filter>
<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <!-- <a class="notify"><i class="fa fa-bell" (click)="showHidePop()"></i></a> -->
              <div class="notifyDiv top-2" *ngIf="showPop">
                <h2 class="report-header">MASTER REPORT </h2>

                <i class="settings-close mdi mdi-close" (click)="closeDiv()"></i>
                <h6 class="progTxt" ng-show="flag2PData.downloadInProgress" *ngIf="showStatus">
                  <i class="fa fa-hourglass-start fs14 progIcon" style="margin-right: 5px; color: orange;"></i> <span
                    style="line-height: 1.4;">Download started for Master Report.Please check status.</span>
                  <button class="reports" (click)="statusReport()"> Check Latest Status</button>
                </h6>
                <h6 class="progTxt" ng-show="flag2PData.downloadCompleted" *ngIf="!showStatus">
                  <i class="fa fa-check-square-o fs14 compIcon" style="margin-right: 5px; color: #025c48;"
                    *ngIf="checkIcon"></i>
                  <i class="fa fa-check-square-o fs14 compIcon" style="margin-right: 5px; color: orange;"
                    *ngIf="!checkIcon"></i> Download Completed for Report.
                  <button class="report-link" (click)="downLoadMasterReport()">Click to download </button>
                </h6>
              </div>
              <p class="card-title">Audit Summary
                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="sortFilterLbl" title="Filter"
                  [ngClass]="{'filterapplied':isFilterhighlited}">
                  <i class="fa fa-filter clrOrange notifyIcon" [ngClass]="{'filterapplied':isFilterhighlited}"
                    (click)="openModal(filter)"></i><span (click)="openModal(filter)"> Filter</span></a>
                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="download" title="Master Report"
                  (click)="generatedReport()"><i class="fa fa-download clrOrange notifyIcon"></i> Master Report</a>
              </p>
              <div class="row m-0 highDiv">
                <div class="col-12 nopadding">
                  <ul class="action-bar">
                    <li class="bg-transparent">
                      <ng-container *ngFor="let badge of badgesList;let i=index">
                        <small [ngClass]="[badgeColorArray[i], textColorArray[i]]"
                          class="bg-opacity-10 border  d-inline-flex fw-semibold mb-3 px-2 py-1 me-2 rounded-2 ">{{badge.name}}:
                          {{ badge.value }}
                          <i type="button" class="mdi mdi-close ms-2" (click)="removeFilter(badge)"></i>
                        </small>
                      </ng-container>
                    </li>
                  </ul>
                </div>
              </div>

              <ng-template #filter let-modal>

                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Filter</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="side_nav ms-0" style="width: auto">
                        <ul class="font-size-14">

                          <li (click)="filterType = 'Audit Id'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Audit Id' }">Audit
                              ID</a>
                          </li>

                          <li (click)="filterType = 'Consultant'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Consultant' }">Consultant</a>
                          </li>

                          <li (click)="filterType = 'Divison'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Divison' }">Divison</a>
                          </li>

                          <li (click)="filterType = 'Person Responsible'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Person Responsible' }">Person Responsible</a>
                          </li>
                          <li (click)="filterType = 'Registration Number'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Registration Number' }">Registration
                              Number</a>
                          </li>
                          <!-- <li (click)="filterType = 'Status'">
                            <a class=" cursor-pointer"  [ngClass]="{ 'filter-active': filterType == 'Status' }">Status</a>
                          </li> -->
                          <li (click)="filterType = 'State'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'State' }">State</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-8 mt-2">
                      <form [formGroup]="filterForm">
                        <div *ngIf="filterType == 'Audit Id'">
                          <label>Audit ID</label>
                          <input type="text" class="form-control mt-2" formControlName="auditId" />
                        </div>

                        <div *ngIf="filterType == 'Consultant'">
                          <label>Consultant</label>
                          <input type="text" class="form-control mt-2" formControlName="consultant" />
                        </div>

                        <div *ngIf="filterType == 'Divison'">
                          <label>Divison</label>
                          <ng-multiselect-dropdown formControlName="div" [placeholder]="'Select Divison'"
                            [settings]="dropdownSettings" [data]="division">
                          </ng-multiselect-dropdown>
                        </div>
                        <div *ngIf="filterType == 'Person Responsible'">
                          <label for="exampleSelectGender">Person Responsible</label>
                          <select class="form-control form-select " id="personResponsible" formControlName="pr">
                            <option [value]="null" disabled>-- Select --</option>
                            <ng-container *ngFor="let obj of userRoleLs">
                              <option [value]="obj.userEmailId">{{obj.userEmailId}}</option>
                            </ng-container>
                          </select>
                        </div>
                        <div *ngIf="filterType == 'Registration Number'">
                          <label for="exampleSelectGender">Registration Number</label>
                          <ng-multiselect-dropdown [placeholder]="'Select Registration Number'"
                            [settings]="dropdownSettings" [data]="leagalRegNum" formControlName="gstin">
                          </ng-multiselect-dropdown>

                        </div>
                        <!-- <div *ngIf="filterType == 'Status'">
                          <label for="exampleSelectGender">Status</label>
                          <ng-multiselect-dropdown formControlName="st"
                          [placeholder]="'Select Status'"
                          [settings]="dropdownSettings"
                          [data]="filterStatus"
                         >
                      </ng-multiselect-dropdown>
                        </div> -->

                        <div *ngIf="filterType == 'State'">
                          <label for="exampleSelectGender">State</label>
                          <ng-multiselect-dropdown formControlName="state" [placeholder]="'Select state'"
                            [settings]="dropdownSettings" [data]="stateNames">
                          </ng-multiselect-dropdown>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn_primary_theme font-size-16 ms-5" (click)="clearAll()">
                    Clear All
                  </button>
                  <button type="button" class="btn btn_primary_theme font-size-16" (click)="applyFilter(true)">
                    Apply
                  </button>
                </div>

              </ng-template>
              <div class="">
                <div class="accordion" id="accordion" role="tablist">
                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-1">
                      <h6 class="mb-0">
                        <a data-toggle="collapse" href="#collapse-1" aria-expanded="true" aria-controls="collapse-1"
                          (click)="isFilterApplied('OPEN')">
                          Audit Under Process <span class="AccCount">({{cntOPEN}})</span>
                        </a>
                      </h6>
                    </div>

                    <div id="collapse-1" class="collapse show" role="tabpanel" aria-labelledby="heading-1"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10 ">
                            <a class="pr5 iris-gst-pull-right pt-2" (click)="createAuditLetter('create', 'ail', 'tab1')"
                              *ngIf="navContext?.entityType == 'FILING' && !['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                class="badge badge-outline-success " title="Create Case File"><i
                                  class="fa fa-pencil-square "></i></span></a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:195px;">Audit Id </th>
                                  <th>State</th>
                                  <th>GSTIN</th>
                                  <th>Period From</th>
                                  <th>Period To</th>
                                  <th>Letter Ref. No.</th>
                                  <th>Due Date To Reply </th>
                                  <!-- <th>Date of reply </th> -->
                                  <th>Current Status </th>
                                  <!-- <th>Person responsible</th> -->
                                  <th>Action</th>
                                </tr>

                              </thead>
                              <tbody>
                                <ng-container *ngFor="let obj of auditSumOfOPEN | paginate: tableSection1Config;" let
                                  i="index">
                                  <tr>
                                    <td class="auditID">{{obj.auditId}}<a class="letterTbl"
                                        (click)="obj.expanded = !obj.expanded"><span class="expand">{{ obj.expanded ?
                                          '&ndash;' : '+'}}</span></a></td>
                                    <td>{{setState(obj.gstin)}}</td>
                                    <td>{{obj.gstin}}</td>
                                    <td class="tdElpsis" title="{{obj.periodFromDate}}">{{obj.periodFromDate}}</td>
                                    <td class="tdElpsis" title="{{obj.periodToDate}}">{{obj.periodToDate}}</td>
                                    <td>--</td>
                                    <!-- <td>--</td> -->
                                    <td>--</td>
                                    <td>
                                      <label class="badge badge-warning"
                                        (click)="currentStatusFilter(obj.aflStatus, 'ail')"
                                        *ngIf="obj.afl == true">{{obj.aflStatus}}</label>
                                      <label class="badge badge-warning"
                                        (click)="currentStatusFilter(obj.ailStatus, 'ail')"
                                        *ngIf="obj.afl == false">{{obj.ailStatus}}</label>
                                    </td>
                                    <!-- <td class="tdElpsis" title="{{obj.personResponsible}}">{{obj.personResponsible}}</td> -->
                                    <td class="w172">
                                      <a *ngIf="obj.afl == false"
                                        (click)="getCaseDataAil(obj.auditId, 'view', obj.type, 'tab1')"
                                        class="pr5"><span class="badge badge-outline-success "
                                          title="View Audit Intimation Letter"><i class="fa fa-eye "></i></span></a>
                                      <a (click)="getCaseDataAil(obj.auditId, 'update', obj.type, 'tab1')"
                                        *ngIf="obj.afl == false " class="pr5"><span class="badge badge-outline-primary "
                                          title="Update Audit Intimation Letter"
                                          *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><i
                                            class="fa fa-pencil-square-o "></i></span></a>
                                      <a *ngIf="obj.afl == true"
                                        (click)="getCaseDataAil(obj.auditId, 'view', obj.type, 'tab2')"
                                        class="pr5"><span class="badge badge-outline-success "
                                          title="View Audit Findings Letter"><i class="fa fa-eye "></i></span></a>
                                      <a (click)="getCaseDataAil(obj.auditId, 'update', obj.type, 'tab2')"
                                        *ngIf="obj.afl == true " class="pr5"><span class="badge badge-outline-primary "
                                          title="Update Audit Findings Letter"
                                          *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><i
                                            class="fa fa-pencil-square-o "></i></span></a>
                                    </td>

                                  </tr>
                                  <ng-container *ngIf="obj.expanded">
                                    <tr *ngFor="let item of obj.itemList; let i=index">
                                      <td>
                                        <p *ngIf="item.ail == true" class="txtRight">Audit Intimation Letter
                                          {{item.srNo}}</p>
                                        <p *ngIf="item.afl == true" class="txtRight">Audit Finding Letter {{item.srNo}}
                                        </p>
                                      </td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td class="" title="{{item.letterRefNo}}">{{item.letterRefNo}}</td>
                                      <td class="tdElpsis" title="{{item.dueDateForReply}}">{{item.dueDateForReply}}
                                      </td>
                                      <td>
                                        <label class="badge badge-warning"
                                          *ngIf="item.ail == true && item.ailStatus == 'OPEN'">{{item.ailStatus}}</label>
                                        <label class="badge badge-warning"
                                          *ngIf="item.afl == true && item.aflStatus == 'OPEN'">{{item.aflStatus}}</label>
                                        <label class="badge badge-success"
                                          *ngIf="item.ail == true && item.ailStatus == 'CLOSED'">{{item.ailStatus}}</label>
                                        <label class="badge badge-success"
                                          *ngIf="item.afl == true && item.aflStatus == 'CLOSED'">{{item.aflStatus}}</label>

                                      </td>
                                      <td>--</td>
                                    </tr>
                                  </ng-container>
                                </ng-container>
                              </tbody>
                            </table>
                            <br />
                            <div class="row noMargin">
                              <div class="col-md-10 nopadding">
                                <pagination-controls (pageChange)="onTableDataChangeSection1($event)"
                                  id="tableSection1Config"></pagination-controls>
                              </div>
                              <div class="col-md-2 nopadding">
                                <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection1"
                                  (ngModelChange)="getSection1Data(perPageLimitSection1,1,'select')" id="authority">
                                  <option value="10">10</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="1000">1000</option>
                                </select>
                              </div>
                            </div>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-2">
                      <h6 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" href="#collapse-2" aria-expanded="false"
                          aria-controls="collapse-2" (click)="isFilterApplied('CLOSED')">
                          Audit Closed <span class="AccCount">({{cntCLOSED}})</span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapse-2" class="collapse " role="tabpanel" aria-labelledby="heading-2"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10"></div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:195px;">Audit Id </th>
                                  <th>State</th>
                                  <th>GSTIN</th>
                                  <th>Period From</th>
                                  <th>Period To</th>
                                  <th>Letter Ref. No.</th>
                                  <th>Due Date To Reply </th>
                                  <!-- <th>Date of reply </th> -->
                                  <th>Current Status </th>
                                  <!-- <th>Person responsible</th> -->
                                  <th>Action</th>
                                </tr>

                              </thead>
                              <tbody>
                                <ng-container *ngFor="let obj of auditSumOfCLOSED | paginate: tableSection2Config;" let
                                  i="index">
                                  <tr>
                                    <td class="auditID">{{obj.auditId}}<a class="letterTbl"
                                        (click)="obj.expanded = !obj.expanded"><span class="expand">{{ obj.expanded ?
                                          '&ndash;' : '+'}}</span></a></td>
                                    <td>{{setState(obj.gstin)}}</td>
                                    <td>{{obj.gstin}}</td>
                                    <td class="tdElpsis" title="{{obj.periodFromDate}}">{{obj.periodFromDate}}</td>
                                    <td class="tdElpsis" title="{{obj.periodToDate}}">{{obj.periodToDate}}</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <!-- <td>--</td> -->
                                    <td><label class="badge badge-success"
                                        (click)="currentStatusFilter(obj.aflStatus, obj.type)"
                                        *ngIf="obj.afl == true">{{obj.aflStatus}}</label>
                                    </td>
                                    <!-- <td class="tdElpsis" title="{{obj.personResponsible}}">{{obj.personResponsible}}</td> -->
                                    <td class="w172">
                                      <a *ngIf="obj.afl == false"
                                        (click)="getCaseDataAil(obj.auditId, 'view', obj.type, 'tab1')"
                                        class="pr5"><span class="badge badge-outline-success "
                                          title="View Audit Intimation Letter"><i class="fa fa-eye "></i></span></a>
                                      <a (click)="getCaseDataAil(obj.auditId, 'update', obj.type, 'tab1')"
                                        *ngIf="obj.afl == false" class="pr5"><span class="badge badge-outline-primary "
                                          title="Update Audit Intimation Letter"
                                          *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><i
                                            class="fa fa-pencil-square-o "></i></span></a>
                                      <a *ngIf="obj.afl == true"
                                        (click)="getCaseDataAil(obj.auditId, 'view', obj.type, 'tab2')"
                                        class="pr5"><span class="badge badge-outline-success "
                                          title="View Audit Findings Letter"><i class="fa fa-eye "></i></span></a>
                                      <a (click)="getCaseDataAil(obj.auditId, 'update', obj.type, 'tab2')"
                                        *ngIf="obj.afl == true" class="pr5"><span class="badge badge-outline-primary "
                                          title="Update Audit Findings Letter"
                                          *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><i
                                            class="fa fa-pencil-square-o "></i></span></a>
                                    </td>

                                  </tr>
                                  <ng-container *ngIf="obj.expanded">
                                    <tr *ngFor="let item of obj.itemList; let i=index">
                                      <td>
                                        <p *ngIf="item.ail == true" class="txtRight">Audit Intimation Letter
                                          {{item.srNo}}</p>
                                        <p *ngIf="item.afl == true" class="txtRight">Audit Finding Letter {{item.srNo}}
                                        </p>
                                      </td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td class="" title="{{item.letterRefNo}}">{{item.letterRefNo}}</td>
                                      <td class="tdElpsis" title="{{item.dueDateForReply}}">{{item.dueDateForReply}}
                                      </td>
                                      <!-- <td class="tdElpsis" title="{{item.dateForReply}}">{{item.dateForReply}}</td> -->
                                      <td>-- </td>
                                      <td>--</td>
                                      <!-- <td class="w172">
                                   --
                                  </td> -->
                                    </tr>
                                  </ng-container>
                                </ng-container>
                              </tbody>
                            </table>

                          </div>
                          <br />
                          <div class="row noMargin nopadding mt15">
                            <div class="col-md-10 nopadding">
                              <pagination-controls (pageChange)="onTableDataChangeSection2($event)"
                                id="tableSection2Config"></pagination-controls>
                            </div>
                            <div class="col-md-2 nopadding">

                              <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection2"
                                (ngModelChange)="getSection2Data(perPageLimitSection2,1,'select')" id="authority">
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="1000">1000</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-3">
                      <h6 class="mb-0">
                        <a class="collapsed" data-toggle="collapse" href="#collapse-3" aria-expanded="false"
                          aria-controls="collapse-3" (click)="isFilterApplied('CLOSED SCN ISSUED')">
                          Closed With SCN Triggered <span class="AccCount">({{cntSCNTRIG}})</span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapse-3" class="collapse" role="tabpanel" aria-labelledby="heading-3"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10"></div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:195px;">Audit Id </th>
                                  <th>State</th>
                                  <th>GSTIN</th>
                                  <th>Period From</th>
                                  <th>Period To</th>
                                  <th>Letter Ref. No.</th>
                                  <th>Due Date To Reply </th>
                                  <!-- <th>Date of reply </th> -->
                                  <th>Current Status </th>
                                  <!-- <th>Person responsible</th> -->
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngFor="let obj of auditSumOfSCNTRI | paginate: tableSection3Config;" let
                                  i="index">
                                  <tr>
                                    <td class="auditID">{{obj.auditId}}<a class="letterTbl"
                                        (click)="obj.expanded = !obj.expanded"><span class="expand">{{ obj.expanded ?
                                          '&ndash;' : '+'}}</span></a></td>
                                    <td>{{setState(obj.gstin)}}</td>
                                    <td>{{obj.gstin}}</td>
                                    <td class="tdElpsis" title="{{obj.periodFromDate}}">{{obj.periodFromDate}}</td>
                                    <td class="tdElpsis" title="{{obj.periodToDate}}">{{obj.periodToDate}}</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <!-- <td>--</td> -->
                                    <td><label class="badge badge-success"
                                        (click)="currentStatusFilter(obj.aflStatus, obj.type)"
                                        *ngIf="obj.afl == true">{{obj.aflStatus}}</label>
                                    </td>
                                    <!-- <td class="tdElpsis" title="{{obj.personResponsible}}">{{obj.personResponsible}}</td> -->
                                    <td class="w172">
                                      <a *ngIf="obj.afl == false"
                                        (click)="getCaseDataAil(obj.auditId, 'view', obj.type, 'tab1')"
                                        class="pr5"><span class="badge badge-outline-success "
                                          title="View Audit Intimation Letter"><i class="fa fa-eye "></i></span></a>
                                      <!-- <a (click)="getCaseDataAil(obj.auditId, 'update', obj.type, 'tab1')" *ngIf="(navContext?.entityType == 'FILING') && (obj.afl == false) " class="pr5"><span class="badge badge-outline-primary " title="Update Audit Intimation Letter"><i class="fa fa-pencil-square-o "></i></span></a>  -->
                                      <a *ngIf="obj.afl == true"
                                        (click)="getCaseDataAil(obj.auditId, 'view', obj.type, 'tab2')"
                                        class="pr5"><span class="badge badge-outline-success "
                                          title="View Audit Findings Letter"><i class="fa fa-eye "></i></span></a>
                                      <!-- <a (click)="getCaseDataAil(obj.auditId, 'update', obj.type, 'tab2')" *ngIf="(navContext?.entityType == 'FILING') && (obj.afl == true) " class="pr5"><span class="badge badge-outline-primary " title="Update Audit Findings Letter"><i class="fa fa-pencil-square-o "></i></span></a>  -->
                                    </td>

                                  </tr>
                                  <ng-container *ngIf="obj.expanded">
                                    <tr *ngFor="let item of obj.itemList; let i=index">
                                      <td>
                                        <p *ngIf="item.ail == true" class="txtRight">Audit Intimation Letter
                                          {{item.srNo}}</p>
                                        <p *ngIf="item.afl == true" class="txtRight">Audit Finding Letter {{item.srNo}}
                                        </p>
                                      </td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td class="" title="{{item.letterRefNo}}">{{item.letterRefNo}}</td>
                                      <td class="tdElpsis" title="{{item.dueDateForReply}}">{{item.dueDateForReply}}
                                      </td>
                                      <!-- <td class="tdElpsis" title="{{item.dateForReply}}">{{item.dateForReply}}</td> -->
                                      <td>-- </td>
                                      <td>--</td>
                                      <!-- <td class="w172">
                                    --
                                  </td> -->
                                    </tr>
                                  </ng-container>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <div class="row noMargin nopadding mt15">
                            <div class="col-md-10 nopadding">
                              <pagination-controls (pageChange)="onTableDataChangeSection3($event)"
                                id="tableSection3Config"></pagination-controls>
                            </div>
                            <div class="col-md-2 nopadding">

                              <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection3"
                                (ngModelChange)="getSection3Data(perPageLimitSection3,1,'select')" id="authority">
                                <option value="10">10</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="1000">1000</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
