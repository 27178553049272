<!-- <span class="nav-data" *ngIf="auditAflData"><span class="text-data"> Last Updated By : </span>{{auditAflData.updatedBy}} &nbsp; <span  class="text-data"> On : </span> {{auditAflData.updatedOn}} </span> -->
<div class="row">
  <div class="col-md-4">
    <ul class="nav nav-tabs nav nav-tabs iris-gst-display-flex" role="tablist">
      <li class="nav-item">
        <a class="nav-link" id="auditFindingPh1-tab" data-toggle="tab" (click)="tabChanged('tab1')"
          [ngClass]="(selectedTab === 'tab1') ? 'active': ''" role="tab" aria-controls="auditFindingPh1"
          aria-selected="false">Phase 1</a>
      </li>
      <li class="nav-item" [ngClass]="{'disabled': !isPhase2}">
        <a class="nav-link" id="auditFindingPh2-tab" data-toggle="tab" (click)="tabChanged('tab2')"
          [ngClass]="(selectedTab === 'tab2') ? 'active': ''" role="tab" aria-controls="auditFindingPh2"
          aria-selected="false">Phase 2 </a>
      </li>
    </ul>
  </div>
  <div class="col-md-4">
    <div class="textCenter pt10">
      <span class="infoVal fs16" title="Case ID">{{auditAflData?.auditId}}</span>
    </div>
  </div>
  <div class="col-md-4">
    <span class="computationBtn" *ngIf="auditAflData">
      <span class="nav-data" *ngIf="auditAflData"><span class="text-data"> Last Updated By :
        </span>{{auditAflData.userName}} &nbsp;
        <span class="text-data"> On : </span> {{auditAflData.updatedOn}} </span>
    </span>
  </div>
</div>

<div class="tab-content nopadding">
  <form [formGroup]="auditFindLtrForm">
    <div class="tab-pane fade show active" id="auditFindingPh1" role="tabpanel" aria-labelledby="auditFindingPh1-tab">
      <div>
        <div class="card borderOrange" [hidden]="(selectedTab === 'tab2')" [ngClass]="{'bgGray': (action == 'view')} ">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Audit ID </label>
                  <input type="text" class="form-control" id="auditId" readonly formControlName="auditId">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">GSTIN <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="" readonly placeholder="" formControlName="gstin">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">State <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="" readonly formControlName="state">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Person responsible <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <!-- <select class="form-control form-select" id="personResponsible"
                    *ngIf="navContext?.entityType == 'FILING'" formControlName="personResponsible"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.personResponsible.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of userRoleLs">
                      <option [value]="obj.userName">{{obj.userName}}</option>
                    </ng-container>
                  </select>
                  <input type="text" class="form-control"
                    *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'" placeholder=""
                    formControlName="personResponsible"> -->

                    <div *ngIf="isToggleDropdown && action==='update' || action==='view'" class="input-group">
                      <input type="text" class="form-control" placeholder="" formControlName="personResponsible" readonly>
                      <button *ngIf="isToggleDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleDropdown()">
                        <i class="fa fa-pencil"></i>
                      </button>
                      </div>
            
                      <select class="form-control form-select" id="personResponsible"
                      *ngIf="!isToggleDropdown || action==='create'" formControlName="personResponsible"
                      [ngClass]="{ 'errorBorder': isSubmitted && formGrp.personResponsible.errors }">
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of userRoleLs">
                        <option [value]="obj.userName">{{obj.userName}}</option>
                      </ng-container>
                     </select>
                     
                  <div *ngIf="isSubmitted && formGrp.personResponsible.errors">
                    <span class="text-danger" *ngIf="formGrp.personResponsible.errors.required"> Required
                    </span>
                  </div>

                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Consultant</label>
                  <input type="text" class="form-control" id="" placeholder="" formControlName="consultant">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Period From <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="periodFromDate" [monthNavigator]="true"
                      [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [maxDate]="maxDate"
                      [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && formGrp.periodFromDate.errors }">
                    </p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && formGrp.periodFromDate.errors">
                    <span class="text-danger" *ngIf="formGrp.periodFromDate.errors.required"> Required
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label>Period To <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="periodToDate" [monthNavigator]="true"
                      [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [maxDate]="maxDate"
                      [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && formGrp.periodToDate.errors }">
                    </p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && formGrp.periodToDate.errors">
                    <span class="text-danger" *ngIf="formGrp.periodToDate.errors.required"> Required
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <button class="btn btn-outline-primary" (click)="addFLetter()">Add
                  Finding letter</button>
              </div>
            </div>
            <div id="findingletter{{i}}" class="mt-2" *ngFor="let findingletterGrp of getfindingletter(); let i=index"
              [formGroup]="findingletterGrp">
              <div class="card border-bottom">
                <div class="card-header" role="tab" id="findingletter-1">
                  <h6 class="mb-0">
                    <a data-toggle="collapse" href="#findingletter-1" aria-expanded="true"
                      aria-controls="findingletter-1">
                      Finding Letter {{i+1}}</a>
                  </h6>
                </div>
                <div id="findingletter-1" class="collapse show" role="tabpanel" aria-labelledby="findingletter-1"
                  data-parent="#accordion" [ngClass]="{'bgGray': (action == 'view')} ">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="exampleInputPassword1">Letter Reference No. <span class="mandate"><i
                                class="fa fa-asterisk"></i></span></label>
                          <input type="text" class="form-control" id="" placeholder="" formControlName="letterRefNo"
                            [ngClass]="{ 'errorBorder': isSubmitted && findingletterGrp.get('letterRefNo')?.errors  }">
                          <div *ngIf="isSubmitted && findingletterGrp.get('letterRefNo')?.errors">
                            <span class="text-danger" *ngIf="findingletterGrp.get('letterRefNo')?.errors?.required ">
                              Required
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="exampleInputPassword1">DIN</label>
                          <input type="text" class="form-control" id="din1" placeholder="" formControlName="din">
                        </div>
                      </div>                     
                       <div class="col-md-3">
                        <div class="form-group">
                          <label>Date of letter <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                          <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                            <p-calendar class="form-control" formControlName="letterDate" [monthNavigator]="true"
                              [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                              [maxDate]="maxDate" [showIcon]="true" dataType="string"
                              [ngClass]="{ 'errorBorder': isSubmitted && findingletterGrp.get('letterDate')?.errors  }">
                            </p-calendar>
                            <div *ngIf="isSubmitted && findingletterGrp.get('letterDate')?.errors">
                              <span class="text-danger dateError"
                                *ngIf="findingletterGrp.get('letterDate')?.errors?.required">
                                Required
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Date of receipt of letter</label>
                          <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                            <p-calendar class="form-control" formControlName="dateOfReceiptOfLetter"
                              [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                              [maxDate]="maxDate" dateFormat="dd-mm-yy" [showIcon]="true" dataType="string">
                            </p-calendar>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Internal Due Date</label>
                          <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                            <p-calendar class="form-control" formControlName="internalDueDate" [monthNavigator]="true"
                              [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                              [showIcon]="true" dataType="string">
                            </p-calendar>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label>Legal Due Date <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                          <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                            <p-calendar class="form-control" formControlName="legalDueDate" [monthNavigator]="true"
                              [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                              [showIcon]="true" dataType="string"
                              [ngClass]="{ 'errorBorder': isSubmitted && findingletterGrp.get('legalDueDate')?.errors }">
                            </p-calendar>
                            <div *ngIf="isSubmitted && findingletterGrp.get('legalDueDate')?.errors">
                              <span class="text-danger dateError"
                                *ngIf="findingletterGrp.get('legalDueDate')?.errors?.required">
                                Required
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <label for="exampleInputPassword1">Audit Findings <a class="addExtLetter"
                            (click)="addFindings(i)" *ngIf="isActionView"><i class="fa fa-plus"></i></a> </label>
                        <div class="col-md-12 mb-2">
                          <div class="search-table-outter wrapper">
                            <table class="search-table inner">
                              <thead>
                                <tr class="bgGred">
                                  <th>Sr.No.</th>
                                  <th style="min-width: 274px;">Details of Findings</th>
                                  <th>KeyWord <a class="clrOrange infoIcon"
                                      title="Maximum Length Limit is 150 Characters."><i
                                        class="fa fa-info-circle"></i></a></th>
                                  <th>IGST (₹)</th>
                                  <th>CGST (₹)</th>
                                  <th>SGST (₹)</th>
                                  <th>Cess (₹)</th>
                                  <th>Interest (₹)</th>
                                  <th>Penalty (₹)</th>
                                  <th>Total (₹)</th>
                                  <th>Remove</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container>
                                  <tr *ngFor="let findingsGrp of getfindings(findingletterGrp); let k=index"
                                    [formGroup]="findingsGrp">
                                    <td>{{k+1}}</td>
                                    <td>
                                      <select class="form-control form-select" formControlName="findingDetails"
                                        [title]="findingsGrp.value.findingDetails ? findingsGrp.value.findingDetails:''"
                                        [ngClass]="{ 'errorBorder': isSubmitted && findingsGrp.get('findingDetails')?.errors  }">
                                        <option [value]="null">-- Select --</option>
                                        <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                          <option *ngFor='let item of grp.items' [value]="item.name"
                                            title="{{item.name}}">{{item.name}}</option>
                                        </optgroup>
                                      </select>
                                      <div *ngIf="isSubmitted && findingsGrp.get('findingDetails')?.errors">
                                        <span class="text-danger"
                                          *ngIf="findingsGrp.get('findingDetails')?.errors?.required "> Required
                                        </span>
                                      </div>
                                    </td>
                                    <td><input type="text" class="form-control" id="" formControlName="keyword"
                                        maxlength="150"
                                        [title]="findingsGrp.value.keyword ? findingsGrp.value.keyword:''"></td>
                                    <td><input type="text" class="form-control align-right" id="tax"
                                        formControlName="igst" maxlength="19" InrFormat (input)="issueValueChange()"
                                        (paste)="handlePaste($event)"></td>
                                    <td><input type="text" class="form-control align-right" id="tax"
                                        formControlName="cgst" maxlength="19" InrFormat (input)="issueValueChange()"
                                        (paste)="handlePaste($event)"></td>
                                    <td><input type="text" class="form-control align-right" id="tax"
                                        formControlName="sgst" maxlength="19" InrFormat (input)="issueValueChange()"
                                        (paste)="handlePaste($event)"></td>
                                    <td><input type="text" class="form-control align-right" id="tax"
                                        formControlName="cess" maxlength="19" InrFormat (input)="issueValueChange()"
                                        (paste)="handlePaste($event)"></td>
                                    <td><input type="text" class="form-control align-right" id="tax"
                                        formControlName="interest" maxlength="19" InrFormat (input)="issueValueChange()"
                                        (paste)="handlePaste($event)"></td>
                                    <td><input type="text" class="form-control align-right" id="tax"
                                        formControlName="penalty" maxlength="19" InrFormat (input)="issueValueChange()"
                                        (paste)="handlePaste($event)"></td>
                                    <td><input type="text" class="form-control align-right" id="tax"
                                        formControlName="total" readonly maxlength="19" InrFormat
                                        (input)="issueValueChange()"></td>
                                    <td><a class="addExtLetter" (click)="openRmvFindingPop(rmFindingPopup, i)"
                                        *ngIf="isActionView"><i class="fa fa-minus"></i></a>
                                    </td>
                                    <ng-template #rmFindingPopup let-modal>
                                      <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')">
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <p>Are you sure, you want to remove?</p>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="rmFindings(i, k)">Remove</button>
                                      </div>
                                    </ng-template>
                                  </tr>
                                </ng-container>
                                <ng-container>
                                  <tr>
                                    <th colspan="3">Total</th>
                                    <td><input type="text" class="form-control bold-font align-right"
                                        formControlName="igstTotal" id="tax" placeholder="" maxlength="19" InrFormat>
                                    </td>
                                    <td><input type="text" class="form-control bold-font align-right"
                                        formControlName="cgstTotal" id="tax" placeholder="" maxlength="19" InrFormat>
                                    </td>
                                    <td><input type="text" class="form-control bold-font align-right"
                                        formControlName="sgstTotal" id="tax" placeholder="" maxlength="19" InrFormat>
                                    </td>
                                    <td><input type="text" class="form-control bold-font align-right"
                                        formControlName="cessTotal" id="tax" placeholder="" maxlength="19" InrFormat>
                                    </td>
                                    <td><input type="text" class="form-control bold-font align-right"
                                        formControlName="interestTotal" id="tax" placeholder="" maxlength="19"
                                        InrFormat></td>
                                    <td><input type="text" class="form-control bold-font align-right"
                                        formControlName="penaltyTotal" id="tax" placeholder="" maxlength="19" InrFormat>
                                    </td>
                                    <td><input type="text" class="form-control bold-font align-right"
                                        formControlName="totOfTotals" readonly id="tax" placeholder="" maxlength="19"
                                        InrFormat></td>

                                    <td colspan="9">&nbsp;</td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="row">

                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Audit Findings Letter<span class="mandate"><i
                                  class="fa fa-asterisk"></i></span>
                            </label>
                            <input type="file" class="file-upload-default" #file>
                            <div class="attachment-block"
                              [ngClass]="{'errorBorder': isSubmitted &&  findingletterGrp.get('findingLetterLoc')?.value.length == 0  }">
                              <span style="cursor: pointer;" (click)="openfindingLetterLoc(letterCopyFormPop, i)"> <i
                                  class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span>
                              <span *ngIf="getFindingLetterLocLength(i) != 0"> {{getFindingLetterLocLength(i)}}
                                Attachment</span>

                            </div>
                            <div *ngIf="isSubmitted && findingletterGrp.get('findingLetterLoc')?.value.length === 0">
                              <span class="text-danger">Required</span>
                            </div>
                            <div>
                              <ng-template #letterCopyFormPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let letterFromGrp of getfindingLetterLoc(findingletterGrp); let j = index"
                                          [formGroup]="letterFromGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="letterFromGrp.value.isdocLocUploadedClicked" type="file"
                                                    id="itemList({{i}}).findingLetterLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!letterFromGrp.value.isdocLocUploadedClicked || letterFromGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(letterFromGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="letterFromGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(letterFromGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(letterFromGrp.value.fileLoc || letterFromGrp.value.fileLoc) && isDisableIcons"
                                                    title="Upload file" (click)="uploadfindingLetterLocF(i,j)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteLetterLoc(i,j)"
                                                    *ngIf="(letterFromGrp.value.fileLoc)  && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="letterFromGrp.value.remarks ? letterFromGrp.value.remarks:''">
                                            <div
                                              *ngIf="letterFromGrp.value.remarks && letterFromGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>

                        </div>

                        <div class="col-md-3 ">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Other Attachments
                            </label>
                            <input type="file" class="file-upload-default" #file>
                            <div class="attachment-block">
                              <span style="cursor: pointer;" (click)="openOtherAttachPop(othAttachmentFormPop, i)"> <i
                                  class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span>
                              <span *ngIf="getOtherAttachLength(i) != 0"> {{getOtherAttachLength(i)}} Attachment</span>
                            </div>
                            <div>
                              <ng-template #othAttachmentFormPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let othAttachFromGrp of getotherAttachmentsLoc(findingletterGrp); let j = index"
                                          [formGroup]="othAttachFromGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="othAttachFromGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="itemList({{i}}).othAttachmentLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!othAttachFromGrp.value.isdocLocUploadedClicked || othAttachFromGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(othAttachFromGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="othAttachFromGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(othAttachFromGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(othAttachFromGrp.value.fileLoc || othAttachFromGrp.value.fileLoc) && isDisableIcons"
                                                    title="Upload file" (click)="uploadOthAttachLocCopy(i,j)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteOtherAttach(i,j)"
                                                    *ngIf="(othAttachFromGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="othAttachFromGrp.value.remarks ? othAttachFromGrp.value.remarks:''">
                                            <div
                                              *ngIf="othAttachFromGrp.value.remarks && othAttachFromGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div class="row">
                        <div class="col-md-12 mt15 ">
                          <button class="btn btn-outline-primary ml-1" (click)="openrmFindingLPop(rmFindingLPopup, i)"
                            *ngIf="(isauditAflData) && i != 0">Remove Finding Letter</button>
                          <button class="btn btn-outline-primary ml-1" (click)="addFLetter()" *ngIf="isauditAflData">
                            Add Another Finding Letter</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-template #rmFindingLPopup let-modal>
                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Are you sure, you want to remove?</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-outline-dark" (click)="rmFLetter(i)">Remove</button>
                </div>
              </ng-template>
            </div>
            <div class="row">
              <div class="col-md-12 mt15 nopadding">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Internal remarks </label>
                    <textarea type="text" class="form-control" id="CPResponsible" placeholder="" rows="3"
                      formControlName="internalRemarks"></textarea>
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-md-12 mt15 ">
                <button class="btn btn-outline-primary mr-1" (click)="saveFindingLetter()"
                  *ngIf="isSaveBtn">Save</button>
                <button class="btn btn-outline-primary mr-1" (click)="updateData('p1')"
                  *ngIf="isUpdateBtn">Update</button>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
    <div class="tab-pane fade show" id="auditFindingPh2" role="tabpanel" aria-labelledby="auditFindingPh2-tab">
      <div class="card borderOrange" [hidden]="(selectedTab === 'tab1')" [ngClass]="{'bgGray': (action == 'view')} ">
        <div class="card-body">
          <div id="findingletter{{i}}" class="mt-2" *ngFor="let findingletterGrp of getfindingletter(); let i=index"
            [formGroup]="findingletterGrp">
            <div class="card border-bottom">
              <div class="card-header" role="tab" id="findingletter-1">
                <h6 class="mb-0">
                  <a data-toggle="collapse" href="#findingletter-1" aria-expanded="true"
                    aria-controls="findingletter-1">
                    Finding Letter {{i+1}}</a>
                </h6>
              </div>
              <div id="findingletter-1" class="collapse show" role="tabpanel" aria-labelledby="findingletter-1"
                data-parent="#accordion" [ngClass]="{'bgGray': (action == 'view')} ">
                <div class="card-body">
                  <div>
                    <p class="ml-22 card-title"><a class="addExtLetter" (click)="addSubmissionDiv(i)"
                        *ngIf="isActionView">Add Submission <i class="fa fa-plus"></i></a></p>
                    <div *ngFor="let submissionFromGrp of getSubmissions(findingletterGrp); let k=index"
                      [formGroup]="submissionFromGrp" class="row subDiv">
                      <div class="col-md-12 mb-1">
                        <a class="card-title" (click)="addSubmissionDiv(i)" *ngIf="isActionView">Submission {{k+1}} <i
                            class="fa fa-plus"></i></a>
                        <a class="addExtLetter pull-right" (click)="opensubmissionPop(rmSubmissionPop, i)"
                          *ngIf="isActionView"><i class="fa fa-minus" *ngIf="k!= 0"></i></a>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="exampleInputPassword1">Date of submission
                            <span class="mandate"><i class="fa fa-asterisk"></i></span>
                          </label>
                          <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                            <p-calendar class="form-control" formControlName="dateOfSubmission" [monthNavigator]="true"
                              [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                              [showIcon]="true" dataType="string" [maxDate]="maxDate"
                              [ngClass]="{ 'errorBorder': isSubmitted && submissionFromGrp.get('dateOfSubmission')?.errors  }">
                            </p-calendar>
                            <div *ngIf="isSubmitted &&  submissionFromGrp.get('dateOfSubmission')?.errors">
                              <span class="text-danger dateError"
                                *ngIf="submissionFromGrp.get('dateOfSubmission')?.errors?.required"> Required
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- new -->
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="exampleInputPassword1">Person who submitted </label>
                          <select class="form-control form-select" id="personResponsible"
                            formControlName="personResponsible">
                            <option [value]="null">-- Select --</option>
                            <ng-container *ngFor="let obj of userRoleLs">
                              <option [value]="obj.userEmailId">{{obj.userEmailId}}</option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="exampleInputPassword1">Consultant </label>
                          <input type="text" class="form-control" id="" placeholder="" formControlName="consultant">
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="exampleInputPassword1">Internal remarks </label>
                          <textarea type="text" class="form-control" id="internalRemarks" placeholder=""
                            formControlName="internalRemarks" style="height:38px;"></textarea>
                        </div>
                      </div>

                      <!-- new -->
                      <!-- *ngIf="findingletterGrp.value.isActive" -->
                      <div class="row col-md-12">
                        <!-- showifUpdate(i, k) -->
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Acknowledgement copy <span class="mandate"><i
                                  class="fa fa-asterisk"></i></span>
                            </label>
                            <input type="file" class="file-upload-default" #file>
                            <div class="attachment-block"
                              [ngClass]="{'errorBorder': isSubmitted && getAckCopyLocLength(i,k) == 0}">
                              <span style="cursor: pointer;" (click)="openAckCopyLoc(ackCopyFormPop, i)"> <i
                                  class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span>
                              <span *ngIf="getAckCopyLocLength(i,k) != 0"> {{getAckCopyLocLength(i,k)}}
                                Attachment</span>
                            </div>

                            <div *ngIf="isSubmitted && getAckCopyLocLength(i,k) == 0">
                              <span class="text-danger">Required</span>
                            </div>
                            <div>
                              <ng-template #ackCopyFormPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let ackFromGrp of getAckCOpyLoc(submissionFromGrp); let j = index"
                                          [formGroup]="ackFromGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="ackFromGrp.value.isdocLocUploadedClicked" type="file"
                                                    id="itemList({{i}}).submissions({{k}}).ackCopyLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!ackFromGrp.value.isdocLocUploadedClicked || ackFromGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(ackFromGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="ackFromGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(ackFromGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(ackFromGrp.value.fileLoc || ackFromGrp.value.fileLoc)&& isDisableIcons"
                                                    title="Upload file" (click)="uploadAckCopy(i,j,k)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAckCopyFile(i,j,k)"
                                                    *ngIf="(ackFromGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="ackFromGrp.value.remarks ? ackFromGrp.value.remarks:''">
                                            <div
                                              *ngIf="ackFromGrp.value.remarks && ackFromGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>


                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Other Attachments
                            </label>
                            <input type="file" class="file-upload-default" #file>
                            <div class="attachment-block">
                              <span style="cursor: pointer;" (click)="openSubOthCopyLoc(subothAttachFormPop, i)"> <i
                                  class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span>
                              <span *ngIf="getOthAttachmentLocLength(i,k) != 0"> {{getOthAttachmentLocLength(i,k)}}
                                Attachment</span>
                            </div>
                            <div>
                              <ng-template #subothAttachFormPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let subOthFromGrp of getSubOthAttachLoc(submissionFromGrp); let j = index"
                                          [formGroup]="subOthFromGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="subOthFromGrp.value.isdocLocUploadedClicked" type="file"
                                                    id="itemList({{i}}).submissions({{k}}).othAttachmentLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!subOthFromGrp.value.isdocLocUploadedClicked || subOthFromGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(subOthFromGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="subOthFromGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(subOthFromGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(subOthFromGrp.value.fileLoc || subOthFromGrp.value.fileLoc)&& isDisableIcons"
                                                    title="Upload file" (click)="uploadSubOtherAttach(i,j,k)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteOtherAttachmentFile(i,j,k)"
                                                    *ngIf="(subOthFromGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="subOthFromGrp.value.remarks ? subOthFromGrp.value.remarks:''">
                                            <div
                                              *ngIf="subOthFromGrp.value.remarks && subOthFromGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ng-template #rmSubmissionPop let-modal>
                        <div class="modal-header">
                          <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <p>Are you sure, you want to remove?</p>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-outline-dark"
                            (click)="rmSubmissionDiv(i, k)">Remove</button>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                  <div class="row">
                    <label for="exampleInputPassword1">Issue wise Details
                      <!-- <a class="addExtLetter" (click)="addFindings(i)"><i class="fa fa-plus"></i></a>  -->
                    </label>
                    <div class="col-md-12 mb-2">
                      <div class="search-table-outter wrapper">
                        <table class="search-table inner">
                          <thead>
                            <tr class="bgGred">
                              <th>Sr.no.</th>
                              <th style="min-width: 274px;">Details of Findings</th>
                              <th>KeyWord <a class="clrOrange infoIcon"
                                  title="Maximum Length Limit is 150 Characters."><i class="fa fa-info-circle"></i></a>
                              </th>
                              <th>Conclusion <span class="mandate top7"><i class="fa fa-asterisk"></i></span></th>
                              <th *ngIf="isAuditConOne">SCN Number <span class="mandate top7"><i
                                    class="fa fa-asterisk"></i></span></th>
                              <th *ngIf="isAuditConOne">Date of Receipt of SCN <span class="mandate top7"><i
                                    class="fa fa-asterisk"></i></span></th>
                              <th>IGST (₹)</th>
                              <th>CGST (₹)</th>
                              <th>SGST (₹)</th>
                              <th>Cess (₹)</th>
                              <th>Interest (₹)</th>
                              <th>Penalty (₹)</th>
                              <th>Total (₹) </th>
                              <!-- <th>Remove</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container>
                              <tr *ngFor="let findingsGrp of getfindings(findingletterGrp); let k=index"
                                [formGroup]="findingsGrp">
                                <td>{{k+1}}</td>
                                <td>
                                  <select class="form-control form-select" formControlName="findingDetails"
                                    [title]="findingsGrp.value.findingDetails ? findingsGrp.value.findingDetails:''">
                                    <option [value]="null">-- Select --</option>
                                    <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                      <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                        {{item.name}}</option>
                                    </optgroup>
                                  </select>
                                </td>
                                <td><input type="text" class="form-control" id="" formControlName="keyword"
                                    maxlength="150" [title]="findingsGrp.value.keyword ? findingsGrp.value.keyword:''">
                                </td>
                                <td>
                                  <select class="form-control form-select" id="status" formControlName="status"
                                    [ngClass]="{ 'errorBorder': isSubmitted && findingsGrp.get('status')?.errors  }">
                                    <!-- <option [value]="null">-- Select --</option> -->
                                    <ng-container *ngFor="let obj of issueConclusion">
                                      <option [value]="obj.value">{{obj.value}}</option>
                                    </ng-container>
                                  </select>
                                  <div *ngIf="isSubmitted && findingsGrp.get('status')?.errors">
                                    <span class="text-danger" *ngIf="findingsGrp.get('status')?.errors?.required ">
                                      Required
                                    </span>
                                  </div>
                                </td>
                                <td *ngIf="isAuditConOne">
                                  <input type="text" class="form-control" id="tax" placeholder=""
                                    formControlName="scnNo"
                                    [ngClass]="{ 'errorBorder': isSubmitted && findingsGrp.get('scnNo')?.errors }">
                                  <div *ngIf="isSubmitted && findingsGrp.get('scnNo')?.errors">
                                    <span class="text-danger" *ngIf="findingsGrp.get('scnNo')?.errors?.required">
                                      Required</span>
                                  </div>
                                </td>
                                <td *ngIf="isAuditConOne">
                                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                    <p-calendar appendTo="body" class="form-control" formControlName="scnReceiptDt"
                                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2000:{{currentYear}}"
                                      dateFormat="dd-mm-yy"
                                      [ngClass]="{ 'errorBorder': isSubmitted && findingsGrp.get('scnReceiptDt')?.errors }"></p-calendar>
                                  </div>
                                  <div *ngIf="isSubmitted && findingsGrp.get('scnReceiptDt')?.errors">
                                    <span class="text-danger" *ngIf="findingsGrp.get('scnReceiptDt')?.errors?.required">
                                      Required</span>
                                  </div>
                                </td>
                                <td><input type="text" class="form-control align-right" id="tax" formControlName="igst"
                                    maxlength="19" InrFormat (input)="issueValueChange()" (paste)="handlePaste($event)">
                                </td>
                                <td><input type="text" class="form-control align-right" id="tax" formControlName="cgst"
                                    maxlength="19" InrFormat (input)="issueValueChange()" (paste)="handlePaste($event)">
                                </td>
                                <td><input type="text" class="form-control align-right" id="tax" formControlName="sgst"
                                    maxlength="19" InrFormat (input)="issueValueChange()" (paste)="handlePaste($event)">
                                </td>
                                <td><input type="text" class="form-control align-right" id="tax" formControlName="cess"
                                    maxlength="19" InrFormat (input)="issueValueChange()" (paste)="handlePaste($event)">
                                </td>
                                <td><input type="text" class="form-control align-right" id="tax"
                                    formControlName="interest" maxlength="19" InrFormat (input)="issueValueChange()"
                                    (paste)="handlePaste($event)"></td>
                                <td><input type="text" class="form-control align-right" id="tax"
                                    formControlName="penalty" maxlength="19" InrFormat (input)="issueValueChange()"
                                    (paste)="handlePaste($event)"></td>
                                <td><input type="text" class="form-control align-right" id="tax" formControlName="total"
                                    readonly maxlength="19" InrFormat (input)="issueValueChange()"></td>
                                <!-- <td><a class="addExtLetter" (click)="openRmvFindingPop(rmFindingPopup, i)"><i class="fa fa-minus"></i></a>
                                </td> -->
                                <ng-template #rmFindingPopup let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p>Are you sure, you want to remove?</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="rmFindings(i, k)">Remove</button>
                                  </div>
                                </ng-template>
                              </tr>
                            </ng-container>
                            <ng-container>
                              <tr>
                                <th colspan="4" *ngIf="!isAuditConOne">Total</th>
                                <th colspan="6" *ngIf="isAuditConOne">Total</th>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="igstTotal" id="tax" placeholder="" maxlength="19" InrFormat></td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="cgstTotal" id="tax" placeholder="" maxlength="19" InrFormat></td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="sgstTotal" id="tax" placeholder="" maxlength="19" InrFormat></td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="cessTotal" id="tax" placeholder="" maxlength="19" InrFormat></td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="interestTotal" id="tax" placeholder="" maxlength="19" InrFormat>
                                </td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="penaltyTotal" id="tax" placeholder="" maxlength="19" InrFormat>
                                </td>
                                <td><input type="text" class="form-control bold-font align-right"
                                    formControlName="totOfTotals" readonly id="tax" placeholder="" maxlength="19"
                                    InrFormat></td>

                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2" id="auditConclusn">

            <!-- <div class="col-md-3" *ngIf="isAuditConOne">
              <div class="form-group">
                <label for="exampleInputPassword1">Date of SCN <span class="mandate"><i
                      class="fa fa-asterisk"></i></span></label>
                <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                  <p-calendar class="form-control" formControlName="dateOfScn" [maxDate]="maxDate"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.dateOfScn.errors }"></p-calendar>
                </div>
                <div *ngIf="isSubmitted && formGrp.dateOfScn.errors">
                  <span class="text-danger" *ngIf="formGrp.dateOfScn.errors.required"> Required</span>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-3" *ngIf="isAuditConOne">
              <div class="form-group">
                <label for="exampleInputPassword1">Date of receipt of SCN <span class="mandate"><i
                      class="fa fa-asterisk"></i></span></label>
                <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                  <p-calendar class="form-control" formControlName="dateOfReceiptOfScn" [maxDate]="maxDate"
                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                    [ngClass]="{ 'errorBorder': isSubmitted && formGrp.dateOfReceiptOfScn.errors }"></p-calendar>
                </div>
                <div *ngIf="isSubmitted && formGrp.dateOfReceiptOfScn.errors">
                  <span class="text-danger" *ngIf="formGrp.dateOfReceiptOfScn.errors.required"> Required</span>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-3" *ngIf="isAuditConOne">
              <div class="form-group">
                <label for="exampleInputPassword1">SCN Number <span class="mandate"><i
                      class="fa fa-asterisk"></i></span></label>
                <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="scnNo"
                  [ngClass]="{ 'errorBorder': isSubmitted && formGrp.scnNo.errors }">
                <div *ngIf="isSubmitted && formGrp.scnNo.errors">
                  <span class="text-danger" *ngIf="formGrp.scnNo.errors.required"> Required</span>
                </div>
              </div>
            </div> -->

            <!-- Details of Liability Admitted and Paid  -->
            <div data-toggle="collapse" data-target="#liability" class="expansion-div mb-15"
              (click)="liabilityPanelExpansion()"> Details of Liability Admitted and Paid <span
                class="consultant-icons"> <i *ngIf="!liabilityExpansionPanel" class="fa fa-angle-down"
                  aria-hidden="true"></i>
                <i *ngIf="liabilityExpansionPanel" class="fa fa-angle-up" aria-hidden="true"></i></span> </div>
            <div id="liability" class="collapse">
              <div class=" row">
                <div class="col-md-12">
                  <div class="search-table-outter wrapper p-15">
                    <table class="search-table inner">
                      <thead>
                        <tr class="bgGred">
                          <th class="col-3 w-40">Sr.No.</th>
                          <th class="col-3">Form of Payment</th>
                          <th class="col-3">Reference / Challan No.</th>
                          <th class="col-3">Mode of Payment</th>
                          <th class="col- 3"> Tax (₹) </th>
                          <th class="col- 3"> Interest (₹) </th>
                          <th class="col- 3"> Penalty (₹) </th>
                          <th class="col- 3"> Total (₹) </th>
                          <th class="upload-column-cls">Copy of Form</th>
                          <th class="upload-column-cls"> Copy of Bank Challan </th>
                          <th class="col- 3"> Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container>
                          <tr *ngFor="let detailsOfLiabilityGrp of getLiability(); let  i=index;"
                            [formGroup]=" detailsOfLiabilityGrp">
                            <td> {{i+1}} </td>
                            <td>
                              <select class="form-control form-select" id="authority" formControlName="formOfPayment">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of formPayment ">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                            </td>
                            <td><input type="text" class="form-control" id="tax" placeholder=""
                                formControlName="challanNo"
                                [ngClass]="{ 'errorBorder': isSubmitted && detailsOfLiabilityGrp.get('copyOfFormLoc')?.errors  }">
                              <div *ngIf="isSubmitted && detailsOfLiabilityGrp.get('copyOfFormLoc')?.errors">
                                <span class="text-danger"
                                  *ngIf="detailsOfLiabilityGrp.get('copyOfFormLoc')?.errors?.required">Please Upload
                                  Copy of Challan</span>
                              </div>
                            </td>
                            <td>
                              <select class="form-control form-select" id="authority" formControlName="modeOfPayment">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfPayment ">
                                  <option [value]="obj.value">{{obj.key}}</option>
                                </ng-container>
                              </select>
                            </td>

                            <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                (input)="liabilityTotalCalculation()" formControlName="tax" maxlength="19" InrFormat
                                (paste)="handlePaste($event)"></td>
                            <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                (input)="liabilityTotalCalculation()" formControlName="interest" maxlength="19"
                                InrFormat (paste)="handlePaste($event)"></td>
                            <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                (input)="liabilityTotalCalculation()" formControlName="penalty" maxlength="19" InrFormat
                                (paste)="handlePaste($event)"></td>
                            <td><input type="text" class="form-control align-right" id="tax" readonly placeholder=""
                                (input)="liabilityTotalCalculation()" formControlName="total" maxlength="19" InrFormat>
                            </td>

                            <td class="upload-column-cls">
                              <div class="row">
                                <div class="col-sm-12 nopadding">
                                  <div class="attachment-block m510"><span style="cursor: pointer;"
                                      (click)="openCopyOfForm(copyFormPop, i)"> <i class="icon fa fa fa-paperclip"
                                        title="Attach file" style="cursor: pointer;"></i></span>
                                    <span *ngIf="getCopyOfFormLocLength(i) != 0"> {{getCopyOfFormLocLength(i)}}
                                      Attachment</span>
                                  </div>

                                  <ng-template #copyFormPop let-modal>
                                    <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                      <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="col-md-12">
                                      <label> Attachment</label>
                                      <div class="table-responsive">
                                        <table class="table table-bordered tablepop">
                                          <thead>
                                            <tr class="bgGred">
                                              <th>Upload</th>
                                              <th style="width:50%;">Remark</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              *ngFor="let copyFormGrp of getLiablityCOpyFormLoc(detailsOfLiabilityGrp); let j = index"
                                              [formGroup]="copyFormGrp">
                                              <td>
                                                <div class="row">
                                                  <div class="col-md-9 nopadding">
                                                    <div class="form-group">
                                                      <input *ngIf="copyFormGrp.value.isdocLocUploadedClicked"
                                                        type="file"
                                                        id="liabilityDetails({{i}}).copyOfFormLoc({{j}}).fileLoc"
                                                        class="filetype form-control" name="myfile"
                                                        (change)="onFileSelect($event)">
                                                      <p *ngIf="!copyFormGrp.value.isdocLocUploadedClicked || copyFormGrp.value.fileLoc"
                                                        class="form-control pt10">
                                                        {{getFileNameDta(copyFormGrp.value.fileLoc)}}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-3 nopadding">
                                                    <label for="exampleInputPassword1">
                                                      <a class="downupIcon" *ngIf="copyFormGrp.value.fileLoc"
                                                        title="Download file"
                                                        (click)="download(copyFormGrp.value.fileLoc)"><i
                                                          class="fa fa-download"></i></a>
                                                      <a class="downupIcon"
                                                        *ngIf="!(copyFormGrp.value.fileLoc || copyFormGrp.value.fileLoc)&& isDisableIcons"
                                                        title="Upload file" (click)="uploadCopyForm(i,j)"><i
                                                          class="fa fa-upload"></i></a>
                                                      <a class="downupIcon" (click)="deleteCopyForm(i,j)"
                                                        *ngIf="(copyFormGrp.value.fileLoc)&& isDisableIcons"><i
                                                          class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </label>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control" formControlName="remarks"
                                                  [title]="copyFormGrp.value.remarks ? copyFormGrp.value.remarks:''">
                                                <div
                                                  *ngIf="copyFormGrp.value.remarks && copyFormGrp.value.remarks.length > 100">
                                                  <span class="text-danger">Max character Length 100</span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="modal-footer" style="border: none; padding-top: 0px;">
                                      <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Save</button>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>
                            </td>

                            <td class="upload-column-cls">
                              <div class="row">
                                <div class="col-sm-12 nopadding">
                                  <div class="attachment-block m510"><span style="cursor: pointer;"
                                      (click)="openBankChallan(bankChallanPop, i)"> <i class="icon fa fa fa-paperclip"
                                        title="Attach file" style="cursor: pointer;"></i></span>
                                    <span *ngIf="getCopyOfBankChallanLocLength(i) != 0">
                                      {{getCopyOfBankChallanLocLength(i)}} Attachment</span>
                                  </div>
                                </div>
                                <ng-template #bankChallanPop let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            *ngFor="let bankChallanGrp of getBankChallanLoc(detailsOfLiabilityGrp); let j = index"
                                            [formGroup]="bankChallanGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="bankChallanGrp.value.isdocLocUploadedClicked"
                                                      type="file"
                                                      id="liabilityDetails({{i}}).copyOfBankChallanLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)">
                                                    <p *ngIf="!bankChallanGrp.value.isdocLocUploadedClicked || bankChallanGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(bankChallanGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="bankChallanGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(bankChallanGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(bankChallanGrp.value.fileLoc || bankChallanGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadBankChallanForm(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deleteBankChallan(i,j)"
                                                      *ngIf="(bankChallanGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" formControlName="remarks"
                                                [title]="bankChallanGrp.value.remarks ? bankChallanGrp.value.remarks:''">
                                              <div
                                                *ngIf="bankChallanGrp.value.remarks && bankChallanGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </td>

                            <td>
                              <a class=" addExtLetter" (click)="addLiabilityInv()" *ngIf="isActionView"><i
                                  class="fa fa-plus"></i></a>
                              <a *ngIf="i != 0 && isActionView" class=" addExtLetter"
                                (click)="openRmvLiabilityPop(removeConsultantPopup, i)"><i class="fa fa-minus"></i></a>

                              <ng-template #removeConsultantPopup let-modal>
                                <div class="modal-header">
                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <p>Are you sure, you want to remove?</p>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="rmLiabilityInv(i)">Remove</button>
                                </div>
                              </ng-template>
                            </td>
                          </tr>
                        </ng-container>
                        <ng-container>
                          <tr>
                            <th colspan="4" class="text-center">Total</th>
                            <td class="align-right bold-font">
                              {{liabilityTotals.taxTotal | INR :0}}</td>
                            <td class="align-right bold-font">
                              {{liabilityTotals.interestTotal| INR :0 }}</td>
                            <td class="align-right bold-font">
                              {{liabilityTotals.penaltyTotal | INR :0}}</td>
                            <td class="align-right bold-font">
                              {{liabilityTotals.allTotal | INR :0}}
                            </td>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Details of Liability Admitted and Paid -->

            <!-- Audit conclusion  -->
            <div class="col-md-3" style="margin-top: 20px;">
              <div class="form-group">
                <label>Audit conclusion
                  <span class="mandate"><i class="fa fa-asterisk"></i></span>
                </label>
                <select class="form-control form-select" id="gstin" formControlName="status"
                  (change)="checkStatus($event)" [ngClass]="{'errorBorder': isSubmitted && formGrp.status.errors }">
                  <option [value]="null">-- Select --</option>
                  <ng-container *ngFor="let obj of auditConclusn">
                    <option [value]="obj.value">{{obj.value}}</option>
                  </ng-container>
                </select>
                <div *ngIf="isSubmitted && formGrp.status.errors">
                  <span class="text-danger" *ngIf="formGrp.status.errors.required"> Required
                  </span>
                </div>

              </div>
            </div>

            <!-- <div class="col-md-3" *ngIf="isAuditConTwo">
              <div class="form-group">
                <label for="exampleInputPassword1">Mode of payement<span class="mandate"><i
                  class="fa fa-asterisk"></i></span> </label>
                <select class="form-control form-select" id="gstin" formControlName="paymentMode"  [ngClass]="{ 'errorBorder': isSubmitted && formGrp.paymentMode.errors }" (change)="checkPayMode($event)" >
                  <ng-container *ngFor="let obj of payMode">
                    <option [value]="obj.value">{{obj.value}}</option>
                  </ng-container>
                </select>
                <div *ngIf="isSubmitted && formGrp.paymentMode.errors">
                  <span class="text-danger" *ngIf="formGrp.paymentMode.errors.required"> Required</span>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-3" *ngIf="isAuditConTwo" [hidden]="hideCash">
              <div class="form-group">
                <label for="exampleInputPassword1">Amount deposited </label>
                <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="paymentAmt" maxlength="18" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }">
              </div>
            </div> -->
            <!-- <div class="col-md-3" *ngIf="isAuditConTwo" [hidden]="hideCash">              
              <div class="form-group">
                <label for="exampleInputPassword1">Bank Challan <a class="clrOrange infoIcon"
                    title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                  <a class="downupIcon" *ngIf="isDWbankChallanLoc && formGrp.bankChallanLoc.value" title="Download file"
                    (click)="download(formGrp.bankChallanLoc.value)"><i class="fa fa-download"></i></a>
                  <a class="downupIcon" *ngIf="isUPbankChallanLoc "
                    title="Upload file" (click)="uploadbankChallanLoc()"><i class="fa fa-upload"></i></a>
                </label>
                <input type="file" [hidden]="!isbankChallanLocFile" id="bankChallanLoc" class="filetype form-control"
                  name="myfile" (change)='onFileSelect($event)'>
                <p [hidden]="!isbankChallanLocText" class="form-control pt10">{{getFileNameDta(formGrp.bankChallanLoc.value)}}
                </p>
              </div>
            </div> -->
            <!-- <div class="col-md-3" *ngIf="isAuditConTwo" [hidden]="hideItc">
              <div class="form-group">
                <label for="exampleInputPassword1">Paid By ITC </label>
                <input type="text" class="form-control" id="scnNum" placeholder="" (keypress)="omitNegativeValue($event)" formControlName="paidByItc" maxlength="18" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }">
              </div>
            </div> -->
            <!-- <div class="col-md-3" *ngIf="isAuditConTwo">
              <div class="form-group">
                <label for="exampleInputPassword1">DRC-03 Challan <a class="clrOrange infoIcon"
                    title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                  <a class="downupIcon" *ngIf="isDWdrc03CopyLoc && formGrp.drc03CopyLoc.value" title="Download file"
                    (click)="download(formGrp.drc03CopyLoc.value)"><i class="fa fa-download"></i></a>
                  <a class="downupIcon" *ngIf="isUPdrc03CopyLoc "
                    title="Upload file" (click)="uploaddrc03CopyLoc()"><i class="fa fa-upload"></i></a>
                </label>
                <input type="file" [hidden]="!isdrc03CopyLocFile" id="drc03CopyLoc" class="filetype form-control"
                  name="myfile" (change)='onFileSelect($event)'>
                <p [hidden]="!isdrc03CopyLocText" class="form-control pt10">{{getFileNameDta(formGrp.drc03CopyLoc.value)}}
                </p>
              </div>
            </div> -->
            <!-- <div class="col-md-3" *ngIf="isAuditConTwo">
              <div class="form-group">
                <label for="exampleInputPassword1">DRC-03 Tax </label>
                <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Tax" maxlength="18" [ngClass]="{ 'errorBorder': formGrp.drc03Tax.errors }" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }">
                <div *ngIf="formGrp.drc03Tax.errors">
                  <span class="text-danger" *ngIf="formGrp.drc03Tax.errors.pattern"> Negative value not allowed</span>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-3" *ngIf="isAuditConTwo">
              <div class="form-group">
                <label for="exampleInputPassword1">DRC-03 Interest </label>
                <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Interest" maxlength="18" [ngClass]="{ 'errorBorder': formGrp.drc03Interest.errors }" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }">
                <div *ngIf="formGrp.drc03Interest.errors">
                  <span class="text-danger" *ngIf="formGrp.drc03Interest.errors.pattern"> Negative value not allowed</span>
                </div>
              </div>
            </div> -->
            <!-- <div class="col-md-3" *ngIf="isAuditConTwo">
              <div class="form-group">
                <label for="exampleInputPassword1">DRC-03 Penalty </label>
                <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Penalty" maxlength="18" [ngClass]="{ 'errorBorder': formGrp.drc03Penalty.errors }" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }">
                <div *ngIf="formGrp.drc03Penalty.errors">
                  <span class="text-danger" *ngIf="formGrp.drc03Penalty.errors.pattern"> Negative value not allowed</span>
                </div>
              </div>
            </div> -->



          </div>

          <div class="col-md-12 mt15 nopadding">
            <button class="btn btn-outline-primary mr-1" (click)="updateData('p2')" *ngIf="isUpdateBtn"
              [ngClass]="{'disabled': isUnfav}">Update</button>
          </div>



        </div>
      </div>
    </div>
    <ng-template #scnTrigPop let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Warning! </h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Please check at least one issue should be <b style="font-size: medium;">unfavorable/ partly unfavorable</b>,
          do you still want to proceed to add SCN details?</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">OK</button>
      </div>
    </ng-template>
  </form>
</div>
