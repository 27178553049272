import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NoticeServiceService } from '../../services/notification.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { cloneDeep } from 'lodash-es';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NOTICE_USER_ACTION, badgeMapInvoice } from 'src/app/shared/constant';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BusinessEntityService } from 'src/app/shared/services/business-entity.service';
interface Filters {
  p: 'gstin' | 'userAction' | 'parsedDtOfIssue' | 'parsedDueDate';
  o: 'in' | 'eq' | 'between';
  v?: string | number | null;
  v1?: string | number | null;
  v2?: string | number | null;
}
@Component({
  selector: 'app-gst-notice-order',
  templateUrl: './gst-notice-order.component.html',
  styleUrls: ['./gst-notice-order.component.css']
})

export class GstNoticeOrderComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal!: ElementRef;
  isAccessGiven: boolean = false;
  noticeOrderData: any;
  navContext: any;
  companyId: number = Number(sessionStorage.getItem('companyId'));
  selectedFilling: any;
  selectedGstin: any;
  pageSection1: number = 1;
  tableSize: number = 10;
  filterObj: any = {};
  isFilterhighlited: boolean = false;
  isResData: boolean = false;
  isFilterDivOpen: boolean = false;
  isGstin: boolean = false;
  filterType: string = 'GSTIN';
  badgesList: any[] = [];
  isApplyFilter!: boolean;

  criteria: any = [];
  criteriaObje: any = {};
  filteredQueryData: any;
  badgesMap: any = badgeMapInvoice;
  filterPayload: any = {};
  queryData: any;
  currentYear: any;
  nextWeekDt: any;
  nextMonthDt: any;
  nxtDtdd1: any;
  nxtDtdd2: any;
  nxtMonthdd1: any;
  nxtMonthdd2: any;
  dwdFileName: any;
  recordCount: any;
  BHData: any;
  userAct = NOTICE_USER_ACTION;
  noticeCount: number = Number(sessionStorage.getItem('noticeCount') ?? 10);
  fileUrl: any;
  badgeColorArray = [
    'border-success',
    'border-danger',
    'border-warning',
    'border-dark',
    'border-success',
    'border-danger',
    'border-warning',
    'border-dark',
    'border-success',
  ];
  textColorArray = [
    'text-success',
    'text-danger',
    'text-warning',
    'text-dark',
    'text-success',
    'text-danger',
    'text-warning',
    'text-dark',
    'text-success',
  ];
  userActionData: {
    "gstin": string,
    "refId": string,
    "issuedBy": string,
    "userAction": string,
    "companyId": number
  } = {
      gstin: '',
      refId: '',
      issuedBy: '',
      userAction: '',
      companyId: 0
    };
  perPageLimitSection1 = 10;
  tableSection1Config = {
    itemsPerPage: this.perPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'tableSection1Config',
  };
  gstNoticeFile: any[] = [];
  headersWithContentType: HttpHeaders | { [header: string]: string | string[]; } | undefined;
  // gstinData: any;
  gstinData: { name: string; pan: string }[] = [];

  filters: { [key: string]: Filters } = this.initFilters();
  issueDateArray: Array<string> | null = null;
  dueDateArray: Array<string> | null = null;
  constructor(
    private router: Router,
    private noticeService: NoticeServiceService,
    private toaster: ToasterService,
    private getterSetter: GetterSetterService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private entityService: BusinessEntityService,
  ) { }

  ngOnInit() {
    this.filters = <any>JSON.parse(sessionStorage.getItem('noticeFilter') || '{}');
    if ((this.filters && Object.keys(this.filters).length == 0) || !this.filters) {
      this.filters = this.initFilters();
    }
    this.navContext = JSON.parse(sessionStorage.getItem('navContext') || '{}');
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.tableSection1Config.totalItems = this.noticeCount;
    this.tableSize = this.noticeCount;
    // this.updateFilters();
    this.getNoticeDataList();
    this.getGstinList();
    this.setDate();
  }
  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }

  initFilters(): { [key: string]: Filters } {
    return {
      gstin: { p: 'gstin', o: 'in', v: null },
      parsedDtOfIssue: { p: 'parsedDtOfIssue', o: 'between', v1: null, v2: null },
      parsedDueDate: { p: 'parsedDueDate', o: 'between', v1: null, v2: null },
      userAction: { p: 'userAction', o: 'in', v: null },
    }
  }
  getFilters() {
    sessionStorage.setItem('noticeFilter', JSON.stringify(this.filters));
    return Object.values(this.filters).filter((el) => !!el.v || !!el.v1 || !!el.v2);
  }

  getNoticeDataList() {
    if (this.issueDateArray && this.issueDateArray.length > 0) {
      this.filters.parsedDtOfIssue.v1 = this.issueDateArray[0];
      if (!this.issueDateArray[1]) {
        return;
      } else {
        this.filters.parsedDtOfIssue.v2 = this.issueDateArray[1];
      }
    }
    if (this.dueDateArray && this.dueDateArray.length > 0) {
      this.filters.parsedDueDate.v1 = this.dueDateArray[0];
      if (!this.dueDateArray[1]) {
        return;
      } else {
        this.filters.parsedDueDate.v2 = this.dueDateArray[1];
      }
    };
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.tableSection1Config.currentPage - 1,
      size: this.perPageLimitSection1,
      sortdir: "DESC",
      sortfield: "parsedDtOfIssue",
      companyId: this.companyId,
      module: "GST",
      newRecord: isNewCase === "Newnotice",
      criterias: this.getFilters()
    }
    if (this.navContext.entityType === 'FILING') payload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') payload.pan = this.navContext.pan;

    this.noticeService.getNoticeData(payload).subscribe((res: any) => {
      if (res.status == 0) {
        this.noticeOrderData = [];
        this.toaster.showError(res.message);
        this.isResData = true;
      } else {
        this.noticeOrderData = res.response.results;
        this.tableSection1Config.totalItems = res.response.count;
        this.recordCount = res.response.count;
        this.tableSection1Config.currentPage = payload.page + 1;
        this.isResData = false;
      }
    });
  }

  getNoticeData() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.tableSection1Config.currentPage - 1,
      size: this.perPageLimitSection1,
      sortdir: "DESC",
      sortfield: "parsedDtOfIssue",
      companyId: this.companyId,
      module: "GST",
      newRecord: isNewCase === "Newnotice",
      criterias: this.getFilters()
    }
    if (this.navContext.entityType === 'FILING') payload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') payload.pan = this.navContext.pan;

    this.noticeService.getNoticeData(payload).subscribe((res: any) => {
      if (res.status == 0) {
        this.noticeOrderData = [];
        this.toaster.showError(res.message);
        this.isResData = true;
      } else {
        this.noticeOrderData = res.response.results;
        this.tableSection1Config.totalItems = res.response.count;
        this.recordCount = res.response.count;
        this.tableSection1Config.currentPage = payload.page + 1;
        this.isResData = false;
      }
    });
  }

  clearIssueGst() {
    if (this.issueDateArray && this.issueDateArray.length > 0) {
      this.filters.parsedDtOfIssue.v1 = null;
      this.filters.parsedDtOfIssue.v2 = null;
    }
    this.getNoticeData();
    this.issueDateArray = null;
  }
  cleardueDateGst() {
    if (this.dueDateArray && this.dueDateArray.length > 0) {
      this.filters.parsedDueDate.v1 = null;
      this.filters.parsedDueDate.v2 = null;
    }
    this.getNoticeData();
    this.dueDateArray = null;
  }

  clearFilter(inputName: string) {
    switch (inputName) {
      case 'gstin':
        this.filters.gstin.v = null;
        break;
      case 'userAction':
        this.filters.userAction.v = null;
        break;
      default:
        break;
    }
    this.getNoticeData();
  }
  downloadEXFile() {
    this.getFilters();
    this.dwdExpExcel();
  }
  dwdExpExcel() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.tableSection1Config.currentPage,
      size: this.perPageLimitSection1,
      companyId: this.companyId,
      newRecord: isNewCase === "Newnotice",
      sortdir: "DESC",
      sortfield: "parsedDtOfIssue",
      module: "GST",
      criterias: this.getFilters()

    }

    if (this.navContext.entityType === 'FILING') payload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') payload.pan = this.navContext.pan;

    this.noticeService.downloadEXNotice(payload).subscribe((response: any) => {
      this.downloadExFile(response);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadExFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `Notice.xlsx`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }


  openModal(content: any) {
    this.filterType = 'GSTIN';
    this.modalService.open(content);
  }

  //filter
  backTo() {
    this.router.navigate(['/gstNoticetMSummary']);
  }
  toNoticeDetail(applnId: number) {
    this.router.navigate(['/gstNoticetDetail', applnId]);
  }

  saveAccess() {
    this.noticeService.updateNoticePr(this.userActionData).subscribe((response: any) => {
      if (response.status == 1) {
        this.getNoticeDataList();
        this.isAccessGiven = true;
        this.closeModal.nativeElement.click();
        this.toaster.showSuccess(response.response);
      } else {
        this.toaster.showError(response.errorList[0].message);
      }

    });
  }

  onClickFileName(gstNoticeFileData: any[] = []) {
    this.gstNoticeFile = [];
    this.gstNoticeFile = gstNoticeFileData;
    this.dwdFileName = gstNoticeFileData[0].fileName
  }

  setAction(data: any) {
    this.userActionData = {
      gstin: '',
      refId: '',
      issuedBy: '',
      userAction: '',
      companyId: 0

    };

    this.userActionData.gstin = data.gstin;
    this.userActionData.refId = data.noticeOrderId;
    this.userActionData.issuedBy = data.issuedBy;
    this.userActionData.companyId = this.companyId;

  }
  download(key: any, id: any, fileName: string) {
    let urlData = {
      s3Key: key,
      id: id
    }
    this.noticeService.downloadNFile(urlData).subscribe((response: any) => {
      this.downloadcontFile(response, fileName);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadcontFile(data: any, fileName: any) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${fileName}`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  //pagination  
  getSection1Data(perPageLimitSection1: any, section1page: number) {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      criterias: this.getFilters(),
      page: section1page == 1 ? 0 : section1page - 1,
      size: perPageLimitSection1,
      companyId: this.companyId,
      sortdir: "DESC",
      sortfield: "parsedDtOfIssue",
      module: "GST",
      newRecord: isNewCase === "Newnotice"
    }

    if (this.navContext.entityType === 'FILING') payload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') payload.pan = this.navContext.pan;

    this.noticeService.getNoticeData(payload).subscribe((res: any) => {
      if (res.status == 0) {
        this.noticeOrderData = [];
        this.toaster.showError(res.message);
        this.isResData = true;
      } else {
        this.noticeOrderData = res.response.results;
        this.tableSection1Config.totalItems = res.response.count;
        this.tableSection1Config.itemsPerPage = perPageLimitSection1;
        this.recordCount = res.response.count;
        this.tableSection1Config.currentPage = payload.page + 1;
        this.isResData = false;
      }
    });
  }
  onTableDataChangeSection1(page: number): void {
    this.tableSection1Config.currentPage = page;
    this.getNoticeDataList();
  }
  //pagination

  getGstinList() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      companyId: this.companyId,
      sortdir: "DESC",
      sortfield: "parsedDtOfIssue",
      newRecord: isNewCase === "Newnotice",
      module: "GST"
    }
    if (this.navContext.entityType === 'FILING') payload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') payload.pan = this.navContext.pan;

    this.noticeService.gstGstins(payload).subscribe((res: any) => {
      if (res.status == 1) {
        this.gstinData = res.response;
        // if (payload.companyId && payload.gstin) {
        //   this.gstinData = this.selectedGstin;
        //   // this.filterForm.controls.gstin.setValue(this.gstinData)
        //   this.isGstin = true;
        // }
        // else {
        //   this.gstinData = res.response;
        //   this.isGstin = false;
        // }

      }
    });
  }
}
