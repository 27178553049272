<div class="table-responsive">
    <table class="table table-bordered divTbl">
        <thead>
            <tr class="bgGred">
                <th rowspan="2">Division</th>
                <th colspan="3">Central Excise</th>
                <th colspan="3">Service Tax</th>
                <th colspan="3">Customs Duty</th>
                <th colspan="3">CST </th>
                <th colspan="3">VAT</th>
                <th colspan="3">Total</th>
            </tr>
            <tr class="bgGred">
                <th>Tax Demand</th>
                <th>Total Demand</th>
                <th>Total Deposit</th>
                <th>Tax Demand</th>
                <th>Total Demand</th>
                <th>Total Deposit</th>
                <th>Tax Demand</th>
                <th>Total Demand</th>
                <th>Total Deposit</th>
                <th>Tax Demand</th>
                <th>Total Demand</th>
                <th>Total Deposit</th>
                <th>Tax Demand</th>
                <th>Total Demand</th>
                <th>Total Deposit</th>
                <th>Tax Demand</th>
                <th>Total Demand</th>
                <th>Total Deposit</th>
            </tr>
        </thead>
        <tbody class="divTbody">
            <ng-container *ngFor="let obj of data">
                <tr>
                    <td class="main-row-label">{{obj.label}} <a class="letterTbl"><span class="expand"
                                (click)="obj.expanded = !obj.expanded">{{ obj.expanded ? '&ndash;' : '+'}}</span></a>
                    </td>
                    <!--  1-->
                    <td>{{commonService.sortValue(obj.total['Excise Duty'].taxdemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total['Excise Duty'].totaldemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total['Excise Duty'].totaldeposit)}}</td>
                    <!--  2-->
                    <td>{{commonService.sortValue(obj.total['Service Tax'].taxdemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total['Service Tax'].totaldemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total['Service Tax'].totaldeposit) | INR : 2}}</td>
                    <!--  3-->
                    <td>{{commonService.sortValue(obj.total['Customs Duty'].taxdemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total['Customs Duty'].totaldemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total['Customs Duty'].totaldeposit) | INR : 2}}</td>
                    <!--  4-->
                    <td>{{commonService.sortValue(obj.total['CST'].taxdemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total['CST'].totaldemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total['CST'].totaldeposit) | INR : 2}}</td>
                    <!--  5-->
                    <td>{{commonService.sortValue(obj.total['Vat'].taxdemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total['Vat'].totaldemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total['Vat'].totaldeposit) | INR : 2}}</td>
                    <!-- 6 -->
                    <td>{{commonService.sortValue(obj.total.total.taxdemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total.total.totaldemand) | INR : 2}}</td>
                    <td>{{commonService.sortValue(obj.total.total.totaldeposit) | INR : 2}}</td>
                   

                </tr>
                <ng-container *ngIf="obj.expanded">
                    <tr *ngFor="let item of obj.gstinData">
                        <td>{{item.gstin}}</td>
                        <!--  -->
                        <td>{{commonService.sortValue(item.data['Excise Duty'].taxdemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data['Excise Duty'].totaldemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data['Excise Duty'].totaldeposit) | INR : 2}}</td>
                        <!--  -->
                        <td>{{commonService.sortValue(item.data['Service Tax'].taxdemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data['Service Tax'].totaldemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data['Service Tax'].totaldeposit) | INR : 2}}</td>
                        <!--  -->
                        <td>{{commonService.sortValue(item.data['Customs Duty'].taxdemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data['Customs Duty'].totaldemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data['Customs Duty'].totaldeposit) | INR : 2}}</td>
                        <!--  -->
                        <td>{{commonService.sortValue(item.data['CST'].taxdemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data['CST'].totaldemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data['CST'].totaldeposit) | INR : 2}}</td>
                        <!--  -->
                        <td>{{commonService.sortValue(item.data['Vat'].taxdemand)| INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data['Vat'].totaldemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data['Vat'].totaldeposit) | INR : 2}}</td>
                        <!--  -->
                        <td>{{commonService.sortValue(item.data.total.taxdemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data.total.totaldemand) | INR : 2}}</td>
                        <td>{{commonService.sortValue(item.data.total.totaldeposit) | INR : 2}}</td>
                    </tr>
                </ng-container>
            </ng-container>

        </tbody>
    </table>
</div>
