<div class="container-fluid page-body-wrapper">
    <div class="breadCrumb">
        <a (click)="backtoOrder()">Submissions</a>>><span>Case Details </span>>> <span>{{applnId}}</span>
    </div>
    <div class="main-panel">
        <!-- <div class="row">
            <div class="col-md-12">
                <div class="card-body pb0">
                    <p><span class="card-title">Submission Case Detail </span> > <span class="card-title">{{applnId}}</span>
                        <a class="iris-gst-pull-right mt-1 fs14 mr-3" (click)="backtoOrder()" id="download"
                            title="Close"><i class="fa fa-times clrOrange notifyIcon"></i> </a>
                    </p>
                </div>
            </div>
        </div> -->
        <!-- <div class="row plr20 formDiv">
            <div class="col-md-12">
                <form class="" >
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Application Id</label>
                                <input type="text" class="form-control" [(ngModel)]="subdetailData.applnId" >
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Case Creation Date</label>
                                <input type="text" class="form-control" [(ngModel)]="subdetailData.applnId">
                            </div>
                        </div>
                        <!- <div class="col-md-4">
                            <div class="form-group">
                                <label>Status</label>
                                <input type="text" class="form-control" >
                            </div>
                        </div> ->
                    </div>
                </form>
            </div>
        </div> -->
        <div class="row plr20 mt15 mb15 h392"> 
            <div class="col-md-2">
              <ul class="nav nav-tabs nav-tabs-vertical" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="tab1-tab-vertical" (click)="getSubmissionDetailData()" data-toggle="tab" href="#tab1" role="tab" aria-controls="tab1" aria-selected="true">
                  Submission
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="tab2-tab-vertical" data-toggle="tab" (click)="getNoticeDetailData()" href="#tab2" role="tab" aria-controls="tab2" aria-selected="false">
                  Notice
                  </a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" id="tab3-tab-vertical" data-toggle="tab" href="#tab3" role="tab" aria-controls="tab3" aria-selected="false">
                  Reply
                  </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="tab3-tab-vertical" data-toggle="tab" href="#tab4" role="tab" aria-controls="tab4" aria-selected="false">
                    Other
                    </a>
                  </li> -->
              </ul>
            </div>
            <div class="col-md-10">
              <div class="tab-content tab-content-vertical nopadding">
                <div class="tab-pane fade show active" id="tab1" role="tabpanel" aria-labelledby="tab1-tab-vertical">
                    <div class="table-responsive nopadding">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>ARN Number</th>
                                    <th>Submission Date</th>
                                    <th>Form Number</th>
                                    <th>Status</th>
                                    <!-- <th>Attachment</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let obj of subdetailData;" let i="index">
                                    <tr>
                                    <td>{{obj.data.arn}}</td>
                                    <td>{{obj.data.parsedSubDt}}</td>
                                    <td>{{obj.data.frmno}}</td>
                                    <td>{{obj.data.sts}}</td>
                                    <!-- <td><a><i class="fa fa-download clrOrange notifyIcon"></i></a></td>  -->
                                    </tr>
                                </ng-container>
                                <!-- <tr>
                                    <td>AA091023022661P</td>
                                    <td>22-10-2023</td>
                                    <td>GST REG-14</td>
                                    <td><span class="sucess">Approved</span></td>
                                    <td><a><i class="fa fa-download clrOrange notifyIcon"></i></a></td>                               
                                </tr>     
                                <tr>
                                    <td>AA091023022661P</td>
                                    <td>22-10-2023</td>
                                    <td>GST REG-14</td>
                                    <td><span class="fail">Rejected</span></td>
                                    <td><a><i class="fa fa-download clrOrange notifyIcon"></i></a></td>                               
                                </tr>
                                <tr>
                                    <td>AA091023022661P</td>
                                    <td>22-10-2023</td>
                                    <td>GST REG-14</td>
                                    <td><span  class="warning">Pending</span></td>
                                    <td><a><i class="fa fa-download clrOrange notifyIcon"></i></a></td>                               
                                </tr>                      -->
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2-tab-vertical">
                    <div class="table-responsive nopadding">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Notice Reference Id</th>
                                    <th>Issue Date </th>
                                    <th>Due Date</th>
                                    <th>Attachment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let obj of noticedetailData;" let i="index">
                                    <tr>
                                    <td>{{obj.data.notice.noticeOrderId}}</td>
                                    <td>{{obj.data.notice.parsedDtOfIssue}}</td>
                                    <td>{{obj.data.notice.parsedDueDate}}</td>
                                    <td><a (click)="onClickFileName(obj.data.gstNoticeFile)" data-toggle="modal" data-target="#downloadModel" id="download"><i class="fa fa-download clrOrange notifyIcon"></i></a></td>  
                                    </tr>
                                </ng-container>
                                                    
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- <div class="tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3-tab-vertical">
                    <div class="table-responsive nopadding">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Order Id</th>
                                    <th>Attachment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>ZA031017010125F</td>
                                    <td><a><i class="fa fa-download clrOrange notifyIcon"></i></a></td>                               
                                </tr>     
                                <tr>
                                    <td>ZA031017010125F</td>
                                    <td><a><i class="fa fa-download clrOrange notifyIcon"></i></a></td>                                 
                                </tr>
                                <tr>
                                    <td>ZA031017010125F</td>
                                    <td><a><i class="fa fa-download clrOrange notifyIcon"></i></a></td>                                 
                                </tr>                     
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4-tab-vertical">
                    <div class="table-responsive nopadding">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Order Reference Id</th>
                                    <th>Issue date</th>
                                    <th>Form Number</th>
                                    <th>Attachment</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>ZA031017010125F</td>
                                    <td>22-10-2023</td>
                                    <td>GST REG-14</td>
                                    <td><a><i class="fa fa-download clrOrange notifyIcon"></i></a></td>                               
                                </tr>     
                                <tr>
                                    <td>ZA031017010125F</td>
                                    <td>22-10-2023</td>
                                    <td>GST REG-14</td>
                                    <td><a><i class="fa fa-download clrOrange notifyIcon"></i></a></td>                             
                                </tr>
                                <tr>
                                    <td>ZA031017010125F</td>
                                    <td>22-10-2023</td>
                                    <td>GST REG-14</td>
                                    <td><a><i class="fa fa-download clrOrange notifyIcon"></i></a></td>                                 
                                </tr>                     
                            </tbody>
                        </table>
                    </div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="modal fade" id="downloadModel" tabindex="-1" role="dialog" aria-labelledby="downloadModelLabel"
          aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="downloadModelLabel">Attachment</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true" #closeModal>&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <ul  *ngFor="let item of gstNoticeFile" >
                  <li><a (click)="download(item.s3Key, item.noticeOrderId, item.fileName)"><i class="fa fa-download clrOrange notifyIcon"></i> {{item.fileName}}</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
    </div>
</div>