<div class="panel-wrapper">
  <div class="panel-head">
    <h1>Direct Tax Analytics</h1>
    <div class="filters-bar">
      <div class="filter-item">
        <label>Assessment Year</label>
        <select class="form-select" [(ngModel)]="filters.ay.v" (ngModelChange)="loadAnalytics()">
          <option [ngValue]="null">All</option>
          <ng-container *ngFor="let ay of assessYears">
            <option [ngValue]="ay.value">AY {{ ay.label }}</option>
          </ng-container>
        </select>
      </div>
      <div class="filter-item">
        <label>PAN</label>
        <select class="form-select" [(ngModel)]="filters.pan.v" (ngModelChange)="loadAnalytics()">
          <option [ngValue]="null">All</option>
          <ng-container *ngFor="let pan of panList">
            <option [ngValue]="pan.pan">{{pan.pan}}: {{ pan.name }}</option>
          </ng-container>
        </select>
      </div>
    </div>
  </div>

  <div class="section pan-wise-demands">
    <div class="flex-justify mb-3">
      <h3 class="m-0">Pan Wise Demands</h3>

      <div class="section-actions">
        <ng-template [ngTemplateOutlet]="metricSwitch"></ng-template>
        <button class="btn btn-outline-success btn-download" (click)="exportExcel()">
          <i class="fa fa-file-excel-o" aria-hidden="true"></i> &nbsp; Export
        </button>
      </div>
    </div>

    <table>
      <thead>
        <tr>
          <th></th>
          <th class="text-center">Enquiry Proceedings</th>
          <ng-container *ngFor="let stage of directTaxStages">
            <th *ngIf="stage !== 'Enquiry Proceedings'" colspan="2">{{ stage }}</th>
          </ng-container>
          <th colspan="2">Total</th>
        </tr>
        <tr>
          <th>PAN</th>
          <th class="text-center nowrap">Open Cases</th>
          <ng-container *ngFor="let stage of directTaxStages">
            <ng-container *ngIf="stage !== 'Enquiry Proceedings'">
              <th class="text-center nowrap">Open Cases</th>
              <th class="text-right nowrap">Demand Amount</th>
            </ng-container>
          </ng-container>
          <th class="text-center nowrap">Open Cases</th>
          <th class="text-right nowrap">Demand Amount</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of panwiseData; index as key">
          <ng-template [ngTemplateOutlet]="pantable"
            [ngTemplateOutletContext]="{ item: item, opt: { parent: true, key: key } }">
          </ng-template>

          <ng-container *ngFor="let aydata of item.ayDtls">
            <ng-template [ngTemplateOutlet]="pantable" [ngTemplateOutletContext]="{
                item: aydata,
                opt: { collapse: item.collapse, parent: false, key: key }
              }">
            </ng-template>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="flex-justify">
    <div class="section flex-1 inception-based-chart">
      <h3 class="heading m-0">Ageing Analysis: Inception Based</h3>
      <div class="graph-box">
        <app-pie-chart class="graph-box" *ngIf="inceptionPieChart.length" [data]="inceptionPieChart"
          (clickEvent)="openCasesModal($event, 'inception')">
        </app-pie-chart>
      </div>
    </div>
    <div class="section flex-1 current-forum-chart">
      <h3 class="heading m-0">Ageing Analysis: Current Forum</h3>
      <div class="graph-box">
        <app-pie-chart class="graph-box" *ngIf="forumPieChart.length" [data]="forumPieChart"
          (clickEvent)="openCasesModal($event, 'forum')">
        </app-pie-chart>
      </div>
    </div>
  </div>

  <div class="section flex-1 issue-wise-demand">
    <div class="heading flex-justify">
      <h3 class="m-0">Issue Wise Demands</h3>
      <ng-template [ngTemplateOutlet]="metricSwitch"></ng-template>
    </div>
    <div class="graph-box">
      <app-bar-chart style="width: 100%" *ngIf="issueBarChart.length" [data]="issueBarChart" [xtitle]="'Issues'"
        [ytitle]="amountMetric === 'lakh' ? 'Demand in ₹ Lakh' : 'Demand in ₹ Crore'"
        (clickEvent)="openCasesModal($event, 'issuewise')"></app-bar-chart>

      <ng-container *ngIf="issueBarChart.length < 1">
        <div class="text-center no-records-found">
          <i class="fa fa-bar-chart" aria-hidden="true"></i>
          <p class="text-md text-gray">No records found.</p>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!-- Templates -->
<ng-template #metricSwitch>
  <div class="switch-box">
    <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
    <div class="form-check form-switch m-0">
      <input (change)="setMetric($event)" class="form-check-input" type="checkbox" role="switch"
        [checked]="amountMetric === 'crore'" />
    </div>
    <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
  </div>
</ng-template>

<ng-template #pantable let-item="item" let-opt="opt">
  <tr [ngClass]="{
      'hide-record': opt.collapse && !opt.parent,
      'parent-record': !!opt.parent
    }">
    <th [ngClass]="{
        'collapse-btn': opt.parent && item.ayDtls?.length,
        'items-collapsed': item.collapse
      }" (click)="toggleCollapse(opt.key, opt.parent)">
      {{ item.label }}
    </th>
    <td class="text-center" [ngClass]="{ 'td-link': item.label !== 'Total' && item.enqProcCount > 0 }"
      (click)="openCasesModal(item.key, 'panwise', 'ENQUIRY PROCEEDING')" [innerHTML]="item.enqProcCount | blurzero">
    </td>

    <td class="text-center" [ngClass]="{ 'td-link': item.label !== 'Total' && item.intimationCount > 0 }"
      (click)="openCasesModal(item.key, 'panwise', 'INTIMATION ORDER')" [innerHTML]="item.intimationCount | blurzero">
    </td>
    <td class="text-right" [innerHTML]="item.intimationAmt | inrMetric: amountMetric | formatNum | blurzero"></td>

    <td class="text-center" [ngClass]="{ 'td-link': item.label !== 'Total' && item.assessmentCount > 0 }"
      (click)="openCasesModal(item.key, 'panwise', 'ASSESSMENT')" [innerHTML]="item.assessmentCount | blurzero"></td>
    <td class="text-right" [innerHTML]="item.assessmentAmt | inrMetric: amountMetric | formatNum | blurzero"></td>

    <td class="text-center" [ngClass]="{ 'td-link': item.label !== 'Total' && item.commissionerCount > 0 }"
      (click)="openCasesModal(item.key, 'panwise', 'COMMISSIONER')" [innerHTML]="item.commissionerCount | blurzero">
    </td>
    <td class="text-right" [innerHTML]="item.commissionerAmt | inrMetric: amountMetric | formatNum | blurzero"></td>

    <td class="text-center" [ngClass]="{ 'td-link': item.label !== 'Total' && item.itatCount > 0 }"
      (click)="openCasesModal(item.key, 'panwise', 'APPELLATE TRIBUNAL')" [innerHTML]="item.itatCount | blurzero"></td>
    <td class="text-right" [innerHTML]="item.itatAmt | inrMetric: amountMetric | formatNum | blurzero"></td>

    <td class="text-center" [ngClass]="{ 'td-link': item.label !== 'Total' && item.hcCount > 0 }"
      (click)="openCasesModal(item.key, 'panwise', 'HIGH COURT')" [innerHTML]="item.hcCount | blurzero"></td>
    <td class="text-right" [innerHTML]="item.hcAmt | inrMetric: amountMetric | formatNum | blurzero"></td>

    <td class="text-center" [ngClass]="{ 'td-link': item.label !== 'Total' && item.scCount > 0 }"
      (click)="openCasesModal(item.key, 'panwise', 'SUPREME COURT')" [innerHTML]="item.scCount | blurzero"></td>
    <td class="text-right" [innerHTML]="item.scAmt | inrMetric: amountMetric | formatNum | blurzero"></td>

    <td class="text-center" [ngClass]="{ 'td-link': item.label !== 'Total' && item.totalCount > 0 }"
      (click)="openCasesModal(item.key, 'panwise')" [innerHTML]="item.totalCount | blurzero"></td>
    <td class="text-right" [innerHTML]="item.totalAmt | inrMetric: amountMetric | formatNum | blurzero"></td>
  </tr>
</ng-template>

<ng-template #casesModal let-modal>
  <div class="modal-header align-items-center">
    <h4 class="modal-title">{{ modalTitle }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="flex-justify mb-2">
      <p class="text-md m-0">
        <span class="text-gray">[*Abbreviations] &nbsp; </span>
        <b>AOI/DOE: Addition of Income/Disallownce of Exp.</b>
      </p>
      <ng-template [ngTemplateOutlet]="metricSwitch"></ng-template>
    </div>
    <table>
      <thead>
        <tr>
          <th class="text-center">#</th>
          <th width="14%">PAN</th>
          <ng-container *ngIf="activeType === 'panwise'">
            <th width="8%" class="text-center sorticon" [ngClass]="sortedColClass('ay')" (click)="sortCases('ay')">
              AY
            </th>
          </ng-container>
          <th width="14%" class="text-center sorticon" [ngClass]="sortedColClass('caseId')"
            (click)="sortCases('caseId')">
            Case ID
          </th>
          <th>Case Label</th>
          <th width="9%" class="text-right sorticon" [ngClass]="sortedColClass('totOfAddnOrDisOfExpensesF')"
            (click)="sortCases('totOfAddnOrDisOfExpensesF')">
            AOI/DOE* Amount
          </th>
          <th width="8%" class="text-right sorticon" [ngClass]="sortedColClass('totOfTotal')"
            (click)="sortCases('totOfTotal')">
            Demand Amount
          </th>
          <th width="15%" class="text-center sorticon" [ngClass]="sortedColClass('forum')" (click)="sortCases('forum')">
            Current Forum
          </th>
          <ng-container *ngIf="activeType === 'inception'">
            <th width="15%" class="text-center sorticon" [ngClass]="sortedColClass('inceptionForum')"
              (click)="sortCases('inceptionForum')">
              Inception Forum
            </th>
            <th width="8%" class="text-center sorticon" [ngClass]="sortedColClass('inceptionDate')"
              (click)="sortCases('inceptionDate')">
              Inception Date
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of modalData; index as srno">
          <td class="text-center">{{ srno + 1 }}</td>
          <td>
            <p class="m-0 lh-sm pt-1 pb-1">
              <span class="d-block weight-500 text-md">{{ item.pan }}</span>
              <span class="d-block ellipsis text-sm text-gray">{{ item.panName }}</span>
            </p>
          </td>

          <ng-container *ngIf="activeType === 'panwise'">
            <td class="text-center">{{ item.ay }}</td>
          </ng-container>

          <td class="text-center weight-500 td-link" (click)="openCaseUpdate(item.caseId, item.forum)">
            {{ item.caseId }}
          </td>
          <td>{{ item.caseLabel }}</td>
          <td class="text-right font-monospace"
            [innerHTML]="item.totOfAddnOrDisOfExpensesF | inrMetric: amountMetric | formatNum | blurzero"></td>
          <td class="text-right font-monospace"
            [innerHTML]="item.totOfTotal | inrMetric : amountMetric | formatNum | blurzero"></td>
          <td class="text-center">{{ item.forum }}</td>

          <ng-container *ngIf="activeType === 'inception'">
            <td class="text-center">{{ item.inceptionForum }}</td>
            <td class="text-center">{{ item.inceptionDate }}</td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
