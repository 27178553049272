import {
  ChangeDetectorRef,
  Component,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  DEMAND_AMT_OPTIONS,
  FILTER_STATUS,
  FILTER_STATUS_DATA,
  ISSUE_GST,
  PREGST_ISSUE_DROPDOWN,
  STATE_LIST,
  TAB_TYPE,
  badgeMapInvoice,
} from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { LitigationServiceService } from '../services/litigation-service.service';
import { cloneDeep, escape } from 'lodash-es';
import { DatePipe, formatDate } from '@angular/common';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { param } from 'jquery';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateCaseModalComponent } from 'src/app/shared/components/create-case-modal/create-case-modal.component';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { PaginationInstance } from 'ngx-pagination';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BusinessService } from 'src/app/businessManagement/services/business.service';
import { BusinessEntityService } from 'src/app/shared/services/business-entity.service';
import { log } from 'console';
import _, { sortBy } from 'lodash';
import e from 'express';
import { truncate } from 'fs';
export const LITIGATION_SUM_CACHE = 'LITIGATION_SUM_CACHE'; // used to store in session storage
export enum LITIGATION_SORT {
  CASE_ID = 'CaseId',
  STATE = 'State',
  GSTIN = 'GSTIN',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'Status',
  DEMAND = 'Demaand',
}
export enum PRELITIGATION_SORT {
  CASE_ID = 'CaseId',
  STATE = 'State',
  GSTIN = 'GSTIN',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'Status',
  NOTICE_TYPE = 'Notice type',
  DEMAND = 'Demaand',
}

export enum COMMISSIONER_STAGE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  GSTIN = 'GSTIN',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'Status',
  DEMAND = 'Demaand',
}

export enum APPELLATET_STAGE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  GSTIN = 'GSTIN',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'Status',
  DEMAND = 'Demaand',
}

export enum HIGHCOURT_STAGE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  GSTIN = 'GSTIN',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'Status',
  DEMAND = 'Demaand',
}

export enum SUPREMECOURT_STAGE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  GSTIN = 'GSTIN',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  STATUS = 'Status',
  DEMAND = 'Demaand',
}
type Forum =  'scn' | 'aja'| 'apa'|'apt' | 'hc'| 'sc';

interface SortBy {
  sortField :string; sortDir:'DESC' | 'ASC';
}
interface ForumSortMap {
   scn: SortBy,
    aja: SortBy,
    apa: SortBy,
    apt: SortBy,
    hc: SortBy,
    sc: SortBy,
}

@Component({
  selector: 'app-litigation-summary',
  templateUrl: './litigation-summary.component.html',
  styleUrls: ['./litigation-summary.component.css'],
})
export class LitigationSummaryComponent implements OnInit {
  // data: any;
 ajaLitigationSort= LITIGATION_SORT;
 litigationSort= PRELITIGATION_SORT;
  apalitigationSort = COMMISSIONER_STAGE;
  aptlitigationSort = APPELLATET_STAGE;
  hcLitigationSort = HIGHCOURT_STAGE;
  scLitigationSort = SUPREMECOURT_STAGE;

  sorting: {
    caseId: boolean;
    state: boolean;
    gstin: boolean;
    caseLabel: boolean;
    periodFromDate: boolean;
    periodToDate: boolean;
    typeOfForm:boolean;
    status: boolean;
    totOfTotal1: boolean;
  } = {
      caseId: false,
      state: false,
      gstin: false,
      caseLabel: false,
      periodFromDate: false,
      periodToDate: false,
      typeOfForm:false,
      status: false,
      totOfTotal1: false,
    };
  navContext = { entityName: '', isTaxPayer: false, entityType: '', pan: '' };
  letterSumofSCN: any = [];
  letterSumofSCNItem: any = [];
  letterSumofAJA: any = [];
  letterSumofAPA: any = [];
  letterSumofAPT: any = [];
  letterSumofHC: any = [];
  letterSumofSC: any = [];

  stateList: any = [];
  isCaseExpand: boolean = false;
  isCaseItemOpen: boolean = false;
  isCaseItemClose: boolean = true;
  isOpened: boolean = false;
  isFilterhighlited: boolean = false;
  tabType: { key: string; value: string }[];
  acctype: any;
  fileUrl: any;
  selectedFilling: any;
  selectedGstin: any;
  cntSCN: any = 0;
  cntAJA: any = 0;
  cntAPA: any = 0;
  cntAPT: any = 0;
  cntHC: any = 0;
  cntSC: any = 0;
  ajaDemandAmt: any;
  ajaKeyWordList: any = [];
  appliedFilters: any = [];
  someElement: any;
  filterObj: any = {};
  forumSortMap : ForumSortMap = {
    scn: {sortField:'createdOn', sortDir:'DESC'},
    aja: {sortField:'createdOn', sortDir:'DESC'},
    apa: {sortField:'createdOn', sortDir:'DESC'},
    apt: {sortField:'createdOn', sortDir:'DESC'},
    hc: {sortField:'createdOn', sortDir:'DESC'},
    sc: {sortField:'createdOn', sortDir:'DESC'},
  };
  isFilterDivOpen: boolean = false;
  isExpanded: boolean = false;
  companyId: any;
  roleName: any;
  statusData: any;
  report: 'master' | 'contingency' = 'master';
  showHeader: boolean = false;
  showHeader1: boolean = false;
  showStatus: boolean = true;
  showPop: boolean = false;
  checkIcon: boolean = false;
  page: number = 0;
  pageSection1: number = 1;
  pageSection2: number = 1;
  pageSection3: number = 1;
  pageSection4: number = 1;
  pageSection5: number = 1;
  pageSection6: number = 1;
  perPageLimit = 10;
  perPageLimitSection1 = 10;
  perPageLimitSection2 = 10;
  perPageLimitSection3 = 10;
  perPageLimitSection4 = 10;
  perPageLimitSection5 = 10;
  perPageLimitSection6 = 10;

  tableSize: number = 1000;
  tableSizes: any = [3, 6, 9, 12];
  contextData: any;
  currentYear: any;
  tableSection1Config = {
    itemsPerPage: this.perPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'tableSection1Config',
  };
  tableSection2Config = {
    itemsPerPage: this.perPageLimitSection2,
    currentPage: this.pageSection2,
    totalItems: 0,
    id: 'tableSection2Config',
  };
  tableSection3Config = {
    itemsPerPage: this.perPageLimitSection3,
    currentPage: this.pageSection3,
    totalItems: 0,
    id: 'tableSection3Config',
  };
  tableSection4Config = {
    itemsPerPage: this.perPageLimitSection4,
    currentPage: this.pageSection4,
    totalItems: 0,
    id: 'tableSection4Config',
  };
  tableSection5Config = {
    itemsPerPage: this.perPageLimitSection5,
    currentPage: this.pageSection5,
    totalItems: 0,
    id: 'tableSection5Config',
  };
  tableSection6Config = {
    itemsPerPage: this.perPageLimitSection6,
    currentPage: this.pageSection6,
    totalItems: 0,
    id: 'tableSection6Config',
  };
  filterForm!: UntypedFormGroup;
  filterType: string = 'Case Id';
  demandAmtOptions = DEMAND_AMT_OPTIONS;
  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'key',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    // itemsShowLimit: 3,
    // allowSearchFilter: true
  };
  division: any;
  nextWeekDt: any;
  nextMonthDt: any;
  nxtDtdd1: any;
  nxtDtdd2: any;
  nxtMonthdd1: any;
  nxtMonthdd2: any;
  amtQuery: any = {};
  issueGst: any;
  @Input() isGST: any;
  userRoleLs: any;
  selectedUserId: any;
  leagalRegNum: any;
  regGstinNum: any;
  regGstinData: any;
  stateOpt = STATE_LIST;
  stateNames = STATE_LIST.map((state) => state.stateName);
  filterStatus = FILTER_STATUS_DATA;
  badgesList!: any[];
  badgesMap: any = badgeMapInvoice;
  queryData: any;
  filteredQueryData: any;
  criteria: any = [];
  badgeColorArray = [
    'border-success',
    'border-danger',
    'border-warning',
    'border-dark',
    'border-success',
    'border-danger',
    'border-warning',
    'border-dark',
    'border-success',
  ];
  textColorArray = [
    'text-success',
    'text-danger',
    'text-warning',
    'text-dark',
    'text-success',
    'text-danger',
    'text-warning',
    'text-dark',
    'text-success',
  ];
  activeTab: any;
  criteriaObje: any = {};
  filterPayload: any = {};
  isApplyFilter!: boolean;
  cntOpSCN: any;
  cntOpAJA: any;
  cntOpAPA: any;
  cntOpAPT: any;
  cntOpHC: any;
  cntOpSC: any;
  opneCase: any;
  totalCase: any;
  criteriaArray: any;
  caseData: any;
  constructor(
    private litService: LitigationServiceService,
    private toaster: ToasterService,
    private router: Router,
    private GS: GetterSetterService,
    private common: CommonService,
    private datePipe: DatePipe,
    private eEmit: EventEmitterService,
    private modalService: NgbModal,
    private getterSetter: GetterSetterService,
    private excelService: ExcelService,
    private formBuilder: UntypedFormBuilder,
    private businessService: BusinessService,
    private entityService: BusinessEntityService
  ) {
    this.tabType = TAB_TYPE;
    this.stateList = STATE_LIST;
  }

  ngOnInit() {
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    this.setDate();
    window.scroll(0, 0);
    this.litService.selectedFilling$.pipe(debounceTime(1000), distinctUntilChanged()).subscribe((data) => {
      if (data) {
        this.selectedFilling = data;
        this.selectedGstin = this.selectedFilling.gstin;
        this.GS.checkEntityType.subscribe((value) => {
          this.navContext = value;
          this.contextData = value;
        });
      }
      this.callAllFunction();
      if (
        localStorage.getItem('analyticIssueLbl') ||
        localStorage.getItem('analyticState') ||
        localStorage.getItem('analyticDiv')
      ) {
        this.isFilterDivOpen = true;
      }
    });

    if (localStorage.getItem('roleName') === 'NoRole') {
      this.roleName = localStorage.getItem('role');
    } else {
      this.roleName = localStorage.getItem('roleName');
    }

    let countModel: any = {};
    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
    }
    this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
      this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
      this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
      this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
      this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
      this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
      this.tableSection6Config.totalItems = res.response[5].totCaseCnt;
      this.cntSCN = res.response[0].totCaseCnt;
      this.cntAJA = res.response[1].totCaseCnt;
      this.cntAPA = res.response[2].totCaseCnt;
      this.cntAPT = res.response[3].totCaseCnt;
      this.cntHC = res.response[4].totCaseCnt;
      this.cntSC = res.response[5].totCaseCnt;
      this.totalCase = res.response[6].totCaseCnt;

      this.cntOpSCN = res.response[0].openCaseCnt;
      this.cntOpAJA = res.response[1].openCaseCnt;
      this.cntOpAPA = res.response[2].openCaseCnt;
      this.cntOpAPT = res.response[3].openCaseCnt;
      this.cntOpHC = res.response[4].openCaseCnt;
      this.cntOpSC = res.response[5].openCaseCnt;
      this.opneCase = res.response[6].openCaseCnt;

    });
    this.amtQuery = {
      p: 'totOfTotal1',
      o: null,
      v: null,
    };
    this.issueGst = ISSUE_GST;
    if (
      localStorage.getItem('entityType') == 'BUSINESS' ||
      localStorage.getItem('entityType') == 'FILING'
    ) {
      this.regGstinNum = localStorage.getItem('filterRegNum');
      this.regGstinData = this.regGstinNum.split(',');
    } else if (localStorage.getItem('entityType') == 'LEGAL') {
      this.regGstinNum = localStorage.getItem('filterLegalRegNum');
      this.regGstinData = this.regGstinNum.split(',');
    }
    this.selectedUserId = this.GS.getSetUserId();
  }

  callAllFunction() {
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    this.initFilterForm(savedFilters);
    const column =  sessionStorage.getItem('gst_Column_Key');
    const storedAcctype = sessionStorage.getItem('gst_Forum_Key');
    const validAccTypes: Forum[] = ['scn', 'aja', 'apa', 'apt', 'hc', 'sc'];
    const acctype = validAccTypes.includes(storedAcctype as Forum) ? (storedAcctype as Forum) : 'scn';
    this.toggleForumTab(acctype);
    if(sessionStorage.getItem('gst_SortDir')!){
      const sortingDir = sessionStorage.getItem('gst_SortDir')!;
      this.getLitigationSumm(acctype, column, sortingDir);
    }else{
      this.getLitigationSumm(acctype, column);
    }
    this.setDivisionData();
    this.personResponsibleData();
    this.getLegalRegNum();
  }

  toggleForumTab(acctype: Forum) {
    sessionStorage.setItem('gst_Forum_Key', acctype);
    const toggleMap = {
        'scn': { toggleId: 'collapseToggle-1', contentId: 'collapse-1' },
        'aja': { toggleId: 'collapseToggle-2', contentId: 'collapse-2' },
        'apa': { toggleId: 'collapseToggle-3', contentId: 'collapse-3' },
        'apt': { toggleId: 'collapseToggle-4', contentId: 'collapse-4' },
        'hc': { toggleId: 'collapseToggle-5', contentId: 'collapse-5' },
        'sc': { toggleId: 'collapseToggle-6', contentId: 'collapse-6' }
    };

    if (acctype && toggleMap[acctype]) {
      const { toggleId, contentId } = toggleMap[acctype];
      const collapseToggle = document.getElementById(toggleId);
      const collapseContent = document.getElementById(contentId);

      if (collapseToggle && collapseContent) {
          // Toggle the isExpanded state
          this.isExpanded = !this.isExpanded;

          // Update aria-expanded attribute
          collapseToggle.setAttribute('aria-expanded', this.isExpanded.toString());

          if (this.isExpanded) {
              collapseContent.classList.remove('collapse'); 
              collapseContent.classList.add('collapsing'); 
              setTimeout(() => {
                  collapseContent.classList.remove('collapsing');
                  collapseContent.classList.add('collapse', 'show');
              }, 350); 
          } else {
              collapseContent.classList.remove('show'); 
              collapseContent.classList.add('collapsing'); 
              setTimeout(() => {
                  collapseContent.classList.remove('collapsing', 'collapse');
                  collapseContent.classList.add('collapse'); 
              }, 350);
            }
      }
    }
  }

  personResponsibleData() {
    let model: any = {};
    const navCOntextData: any = sessionStorage.getItem('navContext');
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else if (
      this.navContext.entityType === 'BUSINESS' ||
      this.navContext.entityType === 'LEGAL'
    ) {
      model.companyId = navCOntextData.companyId;
    }
    this.litService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }
  getLegalRegNum() {
    const navContext = JSON.parse(this.GS.getNavContextData() || '{}');
    let model = {
      companyId: localStorage.getItem('selectedId'),
      userMailId: this.selectedUserId,
    };
    this.entityService.getLegalRegNum(model).subscribe((response: any) => {
      if (response.status == 'SUCCESS') {
        this.leagalRegNum = response.response;
        localStorage.setItem('filterLegalRegNum', this.leagalRegNum);
      }
    });
  }
  getFormControlVal(val: any) {
    return this.filterForm.controls[val].value;
  }
  generateFilterData(isGenerateBadge: boolean = true) {
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const data = JSON.parse(navCOntextData);

    const queryData: any = {
      caseId: this.getFormControlVal('caseId'),
      caseLabel: this.getFormControlVal('caseLabel'),
      consultant: this.getFormControlVal('consultant'),
      amtQuery: this.getFormControlVal('amtQuery'),
      amtQueryValue: this.getFormControlVal('amtQueryValue'),
      div: this.getFormControlVal('div'),
      dd1: this.getFormControlVal('dd1'),
      dd2: this.getFormControlVal('dd2'),
      issue: this.getFormControlVal('issue'),
      kw: this.getFormControlVal('kw'),
      pf1: this.getFormControlVal('pf1'),
      pf2: this.getFormControlVal('pf2'),
      pt1: this.getFormControlVal('pt1'),
      pt2: this.getFormControlVal('pt2'),
      personResponsible1: this.getFormControlVal('pr'),
      gstin: this.getFormControlVal('gstin'),
      st: this.getFormControlVal('st'),
      state: this.getFormControlVal('state'),
    };
    this.criteria = [];
    for (const key in queryData) {
      // const criteriaObje: any = {};
      this.criteriaObje = {};
      if (
        queryData[key] != null &&
        key != 'dd2' &&
        key != 'amtQueryValue' &&
        key != 'pf2' &&
        key != 'pt2'
      ) {
        this.criteriaObje.p = key;
        this.criteriaObje.v = queryData[key];
        if (
          key == 'dd1' ||
          key == 'dd2' ||
          key == 'pf1' ||
          key == 'pf2' ||
          key == 'pt1' ||
          key == 'pt2'
        ) {
          this.criteriaObje.o = 'between';
        } else if (key == 'totOfTotal1') {
          if (queryData[key] === 'gt') {
            this.criteriaObje.o = 'gt';
          } else if (queryData[key] === 'gte') {
            this.criteriaObje.o = 'gte';
          } else if (queryData[key] === 'lt') {
            this.criteriaObje.o = 'lt';
          } else if (queryData[key] === 'lte') {
            this.criteriaObje.o = 'lte';
          } else if (queryData[key] === 'eq') {
            this.criteriaObje.o = 'eq';
          }
        } else if (key === 'state') {
          this.criteriaObje.o = 'in';
        } else if (key === 'st') {
          this.criteriaObje.o = 'in';
        } else if (key === 'personResponsible1') {
          this.criteriaObje.o = 'eq';
        }else if (key === 'issue') {
          this.criteriaObje.o = 'eq';
        }
        else if (key === 'div') {
          this.criteriaObje.o = 'eq';
        }
        else {
          this.criteriaObje.o = 'contains';
        }

        if (key == 'amtQuery') {
          this.criteriaObje.p = 'totOfTotal1';
          if (queryData[key] === 'gt') {
            this.criteriaObje.o = 'gt';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'gte') {
            this.criteriaObje.o = 'gte';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'lt') {
            this.criteriaObje.o = 'lt';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'lte') {
            this.criteriaObje.o = 'lte';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          } else if (queryData[key] === 'eq') {
            this.criteriaObje.o = 'eq';
            this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
          }
          this.criteriaObje.v = this.getFormControlVal('amtQueryValue');
        } else if (key == 'div') {
          this.criteriaObje.p = 'gstinDiv';
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        } else if (key == 'dd1') {
          this.criteriaObje.p = 'dueDate';
          this.criteriaObje.v1 = this.getFormControlVal('dd1');
          this.criteriaObje.v2 = this.getFormControlVal('dd2');
          delete this.criteriaObje.v;
          delete this.criteria.v;
          delete this.criteria.p;
        } else if (key == 'pf1') {
          this.criteriaObje.p = 'periodFromDate';
          this.criteriaObje.v1 = this.getFormControlVal('pf1');
          this.criteriaObje.v2 = this.getFormControlVal('pf2');
          delete this.criteriaObje.v;
          delete this.criteria.p;
        } else if (key == 'pt1') {
          this.criteriaObje.p = 'periodToDate';
          this.criteriaObje.v1 = this.getFormControlVal('pt1');
          this.criteriaObje.v2 = this.getFormControlVal('pt2');
          delete this.criteriaObje.v;
          delete this.criteria.p;
        } else if (key == 'st') {
          this.criteriaObje.p = 'status';
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        }
        else if (key == 'state') {
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        }
        else if (key == 'gstin') {
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        } else if (key == 'issue') {
          this.criteriaObje.p = 'issues.issue';
        } else if (key == 'kw') {
          this.criteriaObje.p = 'keyword';
        } else if (key == 'pr') {
          this.criteriaObje.p = 'personResponsible1';
        }

        this.criteria.push(this.criteriaObje);
      }
    }

    this.filteredQueryData = _.omitBy(
      queryData,
      (v) => _.isUndefined(v) || _.isNull(v) || v === ''
    );
    if (isGenerateBadge) {
      this.generateBadges(this.filteredQueryData);
    }
    return this.filteredQueryData;
  }
  initFilterForm(savedFilters: any) {
    this.filterForm = this.formBuilder.group({
      pf1: [savedFilters?.pf1 ? savedFilters.pf1 : null],
      pf2: [savedFilters?.pf2 ? savedFilters.pf2 : null],
      pt1: [savedFilters?.pt1 ? savedFilters.pt1 : null],
      pt2: [savedFilters?.pt2 ? savedFilters.pt2 : null],
      dd1: [savedFilters?.dd1 ? savedFilters.dd1 : null],
      dd2: [savedFilters?.dd2 ? savedFilters.dd2 : null],
      kw: [savedFilters?.kw ? savedFilters.kw : null],
      pr: [savedFilters?.pr ? savedFilters.pr : null],
      st: [savedFilters?.st ? savedFilters.st : null],
      caseId: [savedFilters?.caseId ? savedFilters.caseId : null],
      caseLabel: [savedFilters?.caseLabel ? savedFilters.caseLabel : null],
      consultant: [savedFilters?.consultant ? savedFilters.consultant : null],
      amtQuery: [savedFilters?.amtQuery ? savedFilters.amtQuery : null],
      amtQueryValue: [
        savedFilters?.amtQueryValue ? savedFilters.amtQueryValue : null,
      ],
      issue: [savedFilters?.issue ? savedFilters.issue : null],
      div: [savedFilters?.div ? savedFilters.div : null],
      act: [savedFilters?.act ? savedFilters.act : null],
      state: [savedFilters?.state ? savedFilters?.state : null],
      gstin: [savedFilters?.gstin ? savedFilters.gstin : null],
      isAnalyticIssueFilter: [
        savedFilters?.isAnalyticIssueFilter
          ? savedFilters.isAnalyticIssueFilter
          : null,
      ],
      isAnalyticStateFilter: [
        savedFilters?.isAnalyticStateFilter
          ? savedFilters.isAnalyticStateFilter
          : null,
      ],
      isAnalyticDivFilter: [
        savedFilters?.isAnalyticDivFilter
          ? savedFilters.isAnalyticDivFilter
          : null,
      ],
      showAcc: [true],
    });
  }
  setDivisionData() {
    this.businessService.getGstinDiv().subscribe((response: any) => {
      if (response.status === 1) {
        this.division = response?.response?.divList;
      }
    });
  }
  applyFilter(isAddNewFilter: boolean, acctype:Forum, column?:any, sortingDir?:any) {
    this.isApplyFilter = true;
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const data = JSON.parse(navCOntextData);
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    this.badgesList = [];
    if (isAddNewFilter) {
      this.generateFilterData();
    } else {
      if (filters) {
        savedFilters = !!filters ? JSON.parse(filters) : null;
        for (let i = 0; i < savedFilters.criterias.length; i++) {
          const element = savedFilters.criterias[i];
          const filteredQueryData: any = {};
          if (element.v1) {
            filteredQueryData[element.p] = element.v1;
          } else {
            filteredQueryData[element.p] = element.v;
          }
          this.generateBadges(filteredQueryData);
          const value = element.v;
          const keyName = element.p;
          if (keyName === 'issue') {
            // Check if 'issue' criteria exists and set its value in the form
            this.filterForm.get('issue')?.setValue(value);
          } else {
            this.filterForm.get(keyName)?.setValue(value);
          }
          // this.filterForm.get(keyName)?.setValue(value);

        }
        const demandAmountCriteria = savedFilters.criterias.find((criteria: any) => criteria.p === 'totOfTotal1');
        if (demandAmountCriteria) {
          this.filterForm.get('amtQueryValue')?.setValue(demandAmountCriteria.v);
          this.filterForm.get('amtQuery')?.setValue(demandAmountCriteria.o);
        }

        const statusCriteria = savedFilters.criterias.find((criteria: any) => criteria.p === 'status');
        if (statusCriteria) {
          this.filterForm.get('st')?.setValue(statusCriteria.v);
        }
        const issueCriteria = savedFilters.criterias.find((criteria: any) => criteria.p === 'issues.issue');
        if (issueCriteria) {
          this.filterForm.get('issue')?.setValue(issueCriteria.v);
        }

        const keywordCriteria = savedFilters.criterias.find((criteria: any) => criteria.p === 'keyword');
        if (keywordCriteria) {
          this.filterForm.get('kw')?.setValue(keywordCriteria.v);
        }

        const divCriteria = savedFilters.criterias.find((criteria: any) => criteria.p === 'gstinDiv');
        if (divCriteria) {
          this.filterForm.get('div')?.setValue(divCriteria.v);
        }

        const dueDateCriteria = savedFilters.criterias.find((criteria: any) => criteria.p === 'dueDate');
        if (dueDateCriteria) {
          this.filterForm.get('dd1')?.setValue(dueDateCriteria.v1);
          this.filterForm.get('dd2')?.setValue(dueDateCriteria.v2);
        }

        const periodFromCriteria = savedFilters.criterias.find((criteria: any) => criteria.p === 'periodFromDate');
        if (periodFromCriteria) {
          this.filterForm.get('pf1')?.setValue(periodFromCriteria.v1);
          this.filterForm.get('pf2')?.setValue(periodFromCriteria.v2);
        }

        const periodToCriteria = savedFilters.criterias.find((criteria: any) => criteria.p === 'periodToDate');
        if (periodToCriteria) {
          this.filterForm.get('pt1')?.setValue(periodToCriteria.v1);
          this.filterForm.get('pt2')?.setValue(periodToCriteria.v2);
        }
      }
    }
    if (!data.companyId) {
      data.companyId = sessionStorage.getItem('companyId');
    }

    const  {sortField, sortDir } = this.getSortValues(acctype, column, sortingDir);
    this.forumSortMap[acctype] =  {sortField: sortField, sortDir: sortDir}

    this.filterPayload = {
      page: 0,
      size: 10,
      sortfield: sortField,
      sortdir: sortDir,
      companyId: data.companyId,
      criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
    };
    sessionStorage.setItem('Gst-Filter', JSON.stringify(this.filterPayload));
    if(acctype){
      if (acctype == 'scn') {
        const payload = {
          page: 0,
          size: this.perPageLimitSection1,
          sortfield: this.forumSortMap.scn.sortField,
          sortdir: this.forumSortMap.scn.sortDir,
          companyId: data.companyId,
          criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        }
        this.litService
          .getAllCaseSummary(payload)
          .subscribe((res: any) => {
            if (res.response == null) {
              this.letterSumofSCN = [];
            } else {
              this.letterSumofSCN = res.response;
            }
          });
      } else if (acctype == 'aja') {
        const payload = {
          page: 0,
          size: this.perPageLimitSection2,
          sortfield: this.forumSortMap.aja.sortField,
          sortdir: this.forumSortMap.aja.sortDir,
          companyId: data.companyId,
          criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        }
        this.litService
          .getAllCaseSummaryAja(payload)
          .subscribe((res: any) => {
            if (res.response == null) {
              this.letterSumofAJA = [];
            } else {
              this.letterSumofAJA = res.response;
            }
          });
      } else if (acctype == 'apa') {
        const payload = {
          page: 0,
          size: this.perPageLimitSection3,
          sortfield: this.forumSortMap.apa.sortField,
          sortdir: this.forumSortMap.apa.sortDir,
          companyId: data.companyId,
          criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        }
        this.litService
          .getAllCaseSummaryCommissionar(payload)
          .subscribe((res: any) => {
            if (res.response == null) {
              this.letterSumofAPA = [];
            } else {
              this.letterSumofAPA = res.response;
            }
          });
      } else if (acctype == 'apt') {
        const payload = {
          page: 0,
          size: this.perPageLimitSection4,
          sortfield: this.forumSortMap.apt.sortField,
          sortdir: this.forumSortMap.apt.sortDir,
          companyId: data.companyId,
          criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        }
        this.litService
          .getAllCaseSummaryApt(payload)
          .subscribe((res: any) => {
            if (res.response == null) {
              this.letterSumofAPT = [];
            } else {
              this.letterSumofAPT = res.response;
            }
          });
      } else if (acctype == 'hc') {
        const payload = {
          page: 0,
          size: this.perPageLimitSection5,
          sortfield: this.forumSortMap.hc.sortField,
          sortdir: this.forumSortMap.hc.sortDir,
          companyId: data.companyId,
          criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        }
        this.litService
          .getAllCaseSummaryHc(payload)
          .subscribe((res: any) => {
            if (res.response == null) {
              this.letterSumofHC = [];
            } else {
              this.letterSumofHC = res.response;
            }
          });
      } else if (acctype == 'sc') {
        const payload = {
          page: 0,
          size: this.perPageLimitSection6,
          sortfield: this.forumSortMap.sc.sortField,
          sortdir: this.forumSortMap.sc.sortDir,
          companyId: data.companyId,
          criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
        }
        this.litService
          .getAllCaseSummarySc(payload)
          .subscribe((res: any) => {
            if (res.response == null) {
              this.letterSumofSC = [];
            } else {
              this.letterSumofSC = res.response;
            }
          });
      }
    }else{
      const forumType = sessionStorage.getItem('gst_Forum_Key')!;

      if(forumType === 'scn'){
        this.litService.getAllCaseSummary(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.letterSumofSCN = [];
        } else {
          this.letterSumofSCN = res.response;
        }
      });
    } else if(forumType === 'aja'){
      this.litService.getAllCaseSummaryAja(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.letterSumofAJA = [];
        } else {
          this.letterSumofAJA = res.response;
        }
      });
    } else if(forumType === 'apa'){
      this.litService.getAllCaseSummaryCommissionar(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.letterSumofAPA = [];
        } else {
          this.letterSumofAPA = res.response;
        }
      });
    }  else if(forumType === 'apt'){
      this.litService.getAllCaseSummaryApt(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.letterSumofAPT = [];
        } else {
          this.letterSumofAPT = res.response;
        }
      });

    } else if(forumType === 'hc'){
      this.litService.getAllCaseSummaryHc(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.letterSumofHC = [];
        } else {
          this.letterSumofHC = res.response;
        }
      });
    }else if(forumType === 'sc'){
      this.litService.getAllCaseSummarySc(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.letterSumofSC = [];
        } else {
          this.letterSumofSC = res.response;
        }
      });
    }
    }
    
    this.filterPayload = {
      companyId: localStorage.getItem('selectedId'),
      criterias: isAddNewFilter ? this.criteria : savedFilters.criterias,
    }
    this.litService.getAllCaseCount(this.filterPayload).subscribe((res: any) => {
      this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
      this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
      this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
      this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
      this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
      this.tableSection6Config.totalItems = res.response[5].totCaseCnt;
      this.cntSCN = res.response[0].totCaseCnt;
      this.cntAJA = res.response[1].totCaseCnt;
      this.cntAPA = res.response[2].totCaseCnt;
      this.cntAPT = res.response[3].totCaseCnt;
      this.cntHC = res.response[4].totCaseCnt;
      this.cntSC = res.response[5].totCaseCnt;
      this.totalCase = res.response[6].totCaseCnt;


      this.cntOpSCN = res.response[0].openCaseCnt;
      this.cntOpAJA = res.response[1].openCaseCnt;
      this.cntOpAPA = res.response[2].openCaseCnt;
      this.cntOpAPT = res.response[3].openCaseCnt;
      this.cntOpHC = res.response[4].openCaseCnt;
      this.cntOpSC = res.response[5].openCaseCnt;
      this.opneCase = res.response[6].openCaseCnt;

    });

    if (this.filterPayload) {
      sessionStorage.setItem('gst-filters-Data', JSON.stringify(this.filterPayload));
    }
    this.modalService.dismissAll();

    // this.refreshPage();
  }

  refreshPage(){
    this.router.navigate(['/litigationSummary'], { queryParams: { refresh: new Date().getTime() } });
  }
  currentStatusFilter(status: any, type: any) {
    this.badgesList = [];
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const data = JSON.parse(navCOntextData);
    const badgeObjStatus: any = {};
    badgeObjStatus.key = 'status';
    badgeObjStatus.name = 'Status';
    badgeObjStatus.value = status;
    this.badgesList.push(badgeObjStatus);
    const criteriaData = [
      {
        p: badgeObjStatus.key,
        o: 'contains',
        v: badgeObjStatus.value,
      },
    ];
    if (!data.companyId) {
      data.companyId = sessionStorage.getItem('companyId');
    }
    this.filterPayload = {
      page: 0,
      size: 10,
      sortfield: 'createdOn',
      sortdir: 'DESC',
      companyId: data.companyId,
      criterias: criteriaData,
    };
    if (type == 'scn') {
      this.litService
        .getAllCaseSummary(this.filterPayload)
        .subscribe((res: any) => {
          if (res.response == null) {
            this.letterSumofSCN = [];
          } else {
            this.letterSumofSCN = res.response;
          }
        });
    } else if (type == 'aja') {
      this.litService
        .getAllCaseSummaryAja(this.filterPayload)
        .subscribe((res: any) => {
          if (res.response == null) {
            this.letterSumofAJA = [];
          } else {
            this.letterSumofAJA = res.response;
          }
        });
    } else if (type == 'apa') {
      this.litService
        .getAllCaseSummaryCommissionar(this.filterPayload)
        .subscribe((res: any) => {
          if (res.response == null) {
            this.letterSumofAPA = [];
          } else {
            this.letterSumofAPA = res.response;
          }
        });
    } else if (type == 'apt') {
      this.litService
        .getAllCaseSummaryApt(this.filterPayload)
        .subscribe((res: any) => {
          if (res.response == null) {
            this.letterSumofAPT = [];
          } else {
            this.letterSumofAPT = res.response;
          }
        });
    } else if (type == 'hc') {
      this.litService
        .getAllCaseSummaryHc(this.filterPayload)
        .subscribe((res: any) => {
          if (res.response == null) {
            this.letterSumofHC = [];
          } else {
            this.letterSumofHC = res.response;
          }
        });
    } else if (type == 'sc') {
      this.litService
        .getAllCaseSummarySc(this.filterPayload)
        .subscribe((res: any) => {
          if (res.response == null) {
            this.letterSumofSC = [];
          } else {
            this.letterSumofSC = res.response;
          }
        });
    }
  }
  generateBadges(queryData: any) {
    debugger
    if (queryData != null) {
      if (this.badgesMap) {
        for (var key in this.badgesMap) {
          const badgeObj: any = {};
          if (queryData.hasOwnProperty(key)) {
            badgeObj.key = key;
            badgeObj.name = this.badgesMap[key];
            badgeObj.value = queryData[key];
            // this.badgesList.push(this.badgesMap[key]);
            this.badgesList.push(badgeObj);
          }
        }
      }
    }
  }
  reloadSummary() {
    this.getLitigationSumm('scn');
  }
  removeFilter(badge: any) {
    this.badgesList = this.badgesList.filter((e) => e !== badge);
    switch (badge) {
      case 'Case Id':
        delete this.queryData['caseId'];
        this.filterForm.patchValue({
          caseId: null,
        });
        break;

      case 'Case Label':
        delete this.queryData['caseLabel'];
        this.filterForm.patchValue({
          caseLabel: null,
        });
        break;

      case 'Consultant':
        delete this.queryData['consultant'];
        this.filterForm.patchValue({
          consultant: null,
        });
        break;

      case 'Demand Amount':
        delete this.queryData['amtQuery'];
        delete this.queryData['amtQueryValue'];

        this.filterForm.patchValue({
          amtQuery: null,
          amtQueryValue: null,
        });
        break;

      case 'Divison':
        delete this.queryData['div'];
        this.filterForm.patchValue({
          div: null,
        });
        break;

      case 'Due Date':
        delete this.queryData['dt1'];
        delete this.queryData['dt2'];

        this.filterForm.patchValue({
          dt1: null,
          dt2: null,
        });
        break;

      case 'Key words':
        delete this.queryData['kw'];
        this.filterForm.patchValue({
          kw: null,
        });
        break;

      case 'Period From':
        delete this.queryData['pf1'];
        delete this.queryData['pf2'];
        this.filterForm.patchValue({
          pf1: null,
          pf2: null,
        });
        break;

      case 'Period To':
        delete this.queryData['pt1'];
        delete this.queryData['pt2'];

        this.filterForm.patchValue({
          pt1: null,
          pt2: null,
        });
        break;

      case 'Person Responsible':
        delete this.queryData['pr'];

        this.filterForm.patchValue({
          pr: null,
        });
        break;

      case 'Registration Number':
        delete this.queryData['gstin'];

        this.filterForm.patchValue({
          gstin: null,
        });
        break;

      case 'Status':
        delete this.queryData['st'];

        this.filterForm.patchValue({
          st: null,
        });
        break;

      case 'State':
        delete this.queryData['state'];

        this.filterForm.patchValue({
          state: null,
        });
        break;
    }

    const BadgArray = this.badgesList;

    const filterData: any = sessionStorage.getItem('Gst-Filter');
    const parseData = JSON.parse(filterData);
    const criteriaArray: any = parseData.criterias;

    let matchedObjects = criteriaArray.filter((obj1: any) => {
      return this.badgesList.some(
        (obj2) => obj1.p === obj2.key && obj1.v === obj2.value
      );
    });

    const revisedFilter: any = sessionStorage.getItem('gst-filters-Data');
    const parseFilterData = JSON.parse(revisedFilter);
    parseData.criterias = matchedObjects;
    parseFilterData.criterias = matchedObjects;

    sessionStorage.setItem('Gst-Filter', JSON.stringify(parseData));
    sessionStorage.setItem('gst-filters-Data', JSON.stringify(parseFilterData));

    this.reloadSummary();
    this.clearAll();
  }
  getLitigationSumm(acctype: Forum, column?:any, sortingDir?:any) {
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    // sessionStorage.setItem('acctype', acctype);
    
    if (filters) {
      this.applyFilter(false, acctype, column, sortingDir);
    }
    else {
      const { sortField, sortDir } = this.getSortValues(acctype, column, sortingDir);
      this.forumSortMap[acctype]=  {sortField: sortField, sortDir: sortDir};

      this.letterSumofSCN = [];
      let mainModel: any = {};
      if (this.navContext.entityType === 'FILING') {
        mainModel.type = this.tabType[0].key;
        mainModel.gstin = this.selectedGstin;
        mainModel.companyId = this.getterSetter.getSetCompanyId();
      } else {
        mainModel.gstin = '';
        mainModel.type = this.tabType[0].key;
        mainModel.companyId = this.getterSetter.getSetCompanyId();
      }
      if (this.navContext.entityType === 'LEGAL') {
        mainModel.pan = this.navContext.pan;
      }
      if (acctype === 'scn') {
        this.letterSumofSCN = [];
        let model: any = {};
        let countModel: any = {};
        countModel.page = this.pageSection1;
        countModel.size = this.tableSize;
        countModel.sortdir = 'DESC';
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        if (parse.entityType == 'Business') {
          const cId = sessionStorage.getItem('companyId');
          countModel.companyId = cId;
        } else if (parse.entityType == 'FILING') {
          countModel.companyId = parse.companyId;
        } else if (parse.entityType == 'LEGAL') {
          countModel.companyId = parse.companyId;
        }
        // countModel.criterias = this.criteriaArray;
        let tempKeyword: any = [];
        if (this.navContext.entityType === 'FILING') {
          // model.gstin = this.selectedGstin;
          model.companyId = this.contextData.companyId;
        } else {
          // model.gstin = '';
          model.companyId = this.getterSetter.getSetCompanyId();
        }
        if (this.navContext.entityType === 'LEGAL') {
          // model.pan = this.navContext.pan;
          model.companyId = parse.companyId
        }
        // this.companyId = this.getterSetter.getSetCompanyId();

        this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
          this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
        });
        Object.assign(model, {
          page:
            this.tableSection1Config.currentPage == 1
              ? 0
              : this.tableSection1Config.currentPage - 1,
          size: this.tableSection1Config.itemsPerPage,
          sortfield: sortField,
          sortdir: sortDir,
        });
        // this.subscription.add(
        this.litService.getAllCaseSummary(model).subscribe(
          (response: any) => {
            if (response.status === 1) {
              this.litService.setAccessTabData({
                scnFlag: false,
                ajaFlag: false,
                apaFlag: false,
                aptFlag: false,
                hcFlag: false,
                scFlag: false,
              });
              this.letterSumofSCN = response.response;
              if (this.letterSumofSCN != null) {
                this.letterSumofSCN.sort(
                  (a: any, b: any) => a.caseId - b.caseId
                );
              }
              if (this.letterSumofSCN != null) {
                if (this.letterSumofSCN.length > 0) {
                  this.letterSumofSCN.forEach((element: any) => {
                    element.expanded = false;
                    tempKeyword = [];
                    if (element.itemList) {
                      element.itemList.forEach((itemEL: any) => {
                        if (itemEL.issues1 && itemEL.issues1.length > 0) {
                          //let tempKeyword: any = [];
                          itemEL.issues1.forEach((issueEl: any) => {
                            tempKeyword.push(issueEl.issue);
                          });
                          element.scnkeywordName = tempKeyword.join(', ');
                        }
                      });
                    }
                  });
                }
              }
            }
          },
          (err) => {
            this.toaster.showError('User Role Based Access Restricted');
          }
        );
        // );
      } else if (acctype === 'aja') {
        this.letterSumofAJA = [];
        let tempKeyword: any = [];
        let model: any = {};
        let countModel: any = {};
        countModel.page = this.pageSection1;
        countModel.size = this.tableSize;
        countModel.sortdir = 'DESC';
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        if (parse.entityType == 'Business') {
          const cId = sessionStorage.getItem('companyId');
          countModel.companyId = cId;
        } else if (parse.entityType == 'FILING') {
          countModel.companyId = parse.companyId;
        } else if (parse.entityType == 'LEGAL') {
          countModel.companyId = parse.companyId;
        }
        if (this.navContext.entityType === 'FILING') {
          model.companyId = parse.companyId;
        } else {
          model.companyId = this.getterSetter.getSetCompanyId();
        }
        if (this.navContext.entityType === 'LEGAL') {
          model.companyId = parse.companyId
        }
        this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
          this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
        });
        Object.assign(model, {
          page:
            this.tableSection2Config.currentPage == 1
              ? 0
              : this.tableSection2Config.currentPage - 1,
          size: this.tableSection2Config.itemsPerPage,
          sortfield: sortField,
          sortdir: sortDir,
        });

        this.litService.getAllCaseSummaryAja(model).subscribe(
          (response: any) => {
            if (response.status == 1) {
              this.letterSumofAJA = response.response;
              if (this.letterSumofAJA) {
                this.letterSumofAJA.sort(
                  (a: any, b: any) => a.caseId - b.caseId
                );
              }
              if (this.letterSumofAJA.length > 0) {
                this.letterSumofAJA.forEach((element: any) => {
                  element.expanded = false;
                  tempKeyword = [];
                  if (element.issues1 && element.issues1.length > 0) {
                    element.issues1.forEach((e1: any) => {
                      tempKeyword.push(e1.issue);
                    });
                    element.ajakeywordName = tempKeyword.toString();
                  }
                });
              }
            }
          },
          (err) => {
            this.toaster.showError('User Role Based Access Restricted');
          }
        );
      } else if (acctype === 'apa') {
        this.letterSumofAPA = [];
        let tempKeyword: any = [];
        let model: any = {};
        let countModel: any = {};
        countModel.page = this.pageSection3;
        countModel.size = this.tableSize;
        countModel.sortdir = 'DESC';
        countModel.companyId = this.contextData.companyId;
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        if (parse.entityType == 'Business') {
          const cId = sessionStorage.getItem('companyId');
          countModel.companyId = cId;
        } else if (parse.entityType == 'FILING') {
          countModel.companyId = parse.companyId;
        } else if (parse.entityType == 'LEGAL') {
          countModel.companyId = parse.companyId;
        }
        if (this.navContext.entityType === 'FILING') {
          model.companyId = parse.companyId;
        } else {
          model.companyId = this.getterSetter.getSetCompanyId();
        }
        if (this.navContext.entityType === 'LEGAL') {
          model.companyId = parse.companyId
        }
        this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
          this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
        });
        Object.assign(model, {
          page:
            this.tableSection3Config.currentPage == 1
              ? 0
              : this.tableSection3Config.currentPage - 1,
          size: this.perPageLimitSection3,
          sortfield: sortField,
          sortdir: sortDir,
        });
        this.litService.getAllCaseSummaryCommissionar(model).subscribe(
          (response: any) => {
            if (response.status == 1) {
              this.letterSumofAPA = response.response;
              if (this.letterSumofAPA) {
                this.letterSumofAPA.sort(
                  (a: any, b: any) => a.caseId - b.caseId
                );
              }
              if (this.letterSumofAPA.length > 0) {
                this.letterSumofAPA.forEach((element: any) => {
                  element.expanded = false;
                  if (element.issues1 && element.issues1.length > 0) {
                    element.issues1.forEach((e1: any) => {
                      tempKeyword.push(e1.issue);
                    });
                    element.apakeywordName = tempKeyword.toString();
                  }
                });
              }
            }
          },
          (err) => {
            this.toaster.showError('User Role Based Access Restricted');
          }
        );
      } else if (acctype === 'apt') {
        this.letterSumofAPT = [];
        let tempKeyword: any = [];
        let model: any = {};
        let countModel: any = {};
        countModel.page = this.pageSection4;
        countModel.size = this.tableSize;
        countModel.sortdir = 'DESC';
        countModel.companyId = this.contextData.companyId;
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        if (parse.entityType == 'Business') {
          const cId = sessionStorage.getItem('companyId');
          countModel.companyId = cId;
        } else if (parse.entityType == 'FILING') {
          countModel.companyId = parse.companyId;
        } else if (parse.entityType == 'LEGAL') {
          countModel.companyId = parse.companyId;
        }

        if (this.navContext.entityType === 'FILING') {
          model.companyId = this.contextData.companyId;
        } else {
          model.companyId = this.getterSetter.getSetCompanyId();
        }
        if (this.navContext.entityType === 'LEGAL') {
          model.companyId = parse.companyId
        }
        this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
          this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
        });
        Object.assign(model, {
          page:
            this.tableSection4Config.currentPage == 1 ? 0 : this.tableSection4Config.currentPage - 1,
          size: this.perPageLimitSection4,
          sortfield: sortField,
          sortdir: sortDir,
        });
        this.litService.getAllCaseSummaryApt(model).subscribe((response: any) => {
          if (response.status == 1) {
            this.letterSumofAPT = response.response;
            if (this.letterSumofAPT) {
              this.letterSumofAPT.sort(
                (a: any, b: any) => a.caseId - b.caseId
              );
            }
            if (this.letterSumofAPT.length > 0) {
              this.letterSumofAPT.forEach((element: any) => {
                element.expanded = false;
                if (element.issues1 && element.issues1.length > 0) {
                  element.issues1.forEach((e1: any) => {
                    tempKeyword.push(e1.issue);
                  });
                  element.aptkeywordName = tempKeyword.toString();
                }
              });
            }
          }
        });
      } else if (acctype === 'hc') {
        this.letterSumofHC = [];
        let tempKeyword: any = [];
        let model: any = {};
        let countModel: any = {};
        countModel.page = this.pageSection1;
        countModel.size = this.tableSize;
        countModel.sortdir = 'DESC';
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        if (parse.entityType == 'Business') {
          const cId = sessionStorage.getItem('companyId');
          countModel.companyId = cId;
        } else if (parse.entityType == 'FILING') {
          countModel.companyId = parse.companyId;
        } else if (parse.entityType == 'LEGAL') {
          countModel.companyId = parse.companyId;
        }
        if (this.navContext.entityType === 'FILING') {
          model.companyId = this.contextData.companyId;
        } else {
          model.companyId = this.getterSetter.getSetCompanyId();
        }
        if (this.navContext.entityType === 'LEGAL') {
          model.companyId = parse.companyId
        }

        this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
          this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
        });
        Object.assign(model, {
          page:
            this.tableSection5Config.currentPage == 1
              ? 0
              : this.tableSection5Config.currentPage - 1,
          size: this.perPageLimitSection5,
          sortfield: sortField,
          sortdir: sortDir,
        });
        this.litService
          .getAllCaseSummaryHc(model)
          .subscribe((response: any) => {
            if (response.status == 1) {
              this.letterSumofHC = response.response;
              if (this.letterSumofHC) {
                this.letterSumofHC.sort(
                  (a: any, b: any) => a.caseId - b.caseId
                );
              }
              if (this.letterSumofHC.length > 0) {
                this.letterSumofHC.forEach((element: any) => {
                  element.expanded = false;
                  if (element.issues1 && element.issues1.length > 0) {
                    element.issues1.forEach((e1: any) => {
                      tempKeyword.push(e1.issue);
                    });
                    element.hckeywordName = tempKeyword.toString();
                  }
                });
              }
            }
          });
      } else if (acctype === 'sc') {
        this.letterSumofSC = [];
        let tempKeyword: any = [];
        let model: any = {};
        let countModel: any = {};
        countModel.page = this.pageSection1;
        countModel.size = this.tableSize;
        countModel.sortdir = 'DESC';
        const navCOntextData: any = sessionStorage.getItem('navContext');
        const parse = JSON.parse(navCOntextData);
        if (parse.entityType == 'Business') {
          const cId = sessionStorage.getItem('companyId');
          countModel.companyId = cId;
        } else if (parse.entityType == 'FILING') {
          countModel.companyId = parse.companyId;
        } else if (parse.entityType == 'LEGAL') {
          countModel.companyId = parse.companyId;
        }
        if (this.navContext.entityType === 'FILING') {
          model.companyId = this.contextData.companyId;
        } else {
          model.companyId = this.getterSetter.getSetCompanyId();
        }
        if (this.navContext.entityType === 'LEGAL') {
          model.companyId = parse.companyId;
        }
        this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
          this.tableSection6Config.totalItems = res.response[5].totCaseCnt;
        });
        Object.assign(model, {
          page:
            this.tableSection6Config.currentPage == 1
              ? 0
              : this.tableSection6Config.currentPage - 1,
          size: this.perPageLimitSection6,
          sortfield: sortField,
          sortdir: sortDir,
        });
        this.litService.getAllCaseSummarySc(model).subscribe((response: any) => {
          if (response.status == 1) {
            this.letterSumofSC = response.response;
            if (this.letterSumofSC) {
              this.letterSumofSC.sort(
                (a: any, b: any) => a.caseId - b.caseId
              );
            }
            if (this.letterSumofSC.length > 0) {
              this.letterSumofSC.forEach((element: any) => {
                element.expanded = false;
                if (element.issues1 && element.issues1.length > 0) {
                  element.issues1.forEach((e1: any) => {
                    tempKeyword.push(e1.issue);
                  });
                  element.sckeywordName = tempKeyword.toString();
                }
              });
            }
          }
        });
      } else {
        this.litService.getAllCaseSummary(mainModel).subscribe((response: any) => {
          if (response.status == 1) {
            this.letterSumofSCN = response.response;
          }
        });
      }
    }
  }

  getSCNCount() {
    let countModel: any = {};
    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
    }
    this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
      this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
      this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
      this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
      this.tableSection4Config.totalItems = res.response[3].totCaseCnt;
      this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
      this.tableSection6Config.totalItems = res.response[5].totCaseCnt;
      this.cntSCN = res.response[0].totCaseCnt;
      this.cntAJA = res.response[1].totCaseCnt;
      this.cntAPA = res.response[2].totCaseCnt;
      this.cntAPT = res.response[3].totCaseCnt;
      this.cntHC = res.response[4].totCaseCnt;
      this.cntSC = res.response[5].totCaseCnt;
      this.totalCase = res.response[6].totCaseCnt;

      this.cntOpSCN = res.response[0].openCaseCnt;
      this.cntOpAJA = res.response[1].openCaseCnt;
      this.cntOpAPA = res.response[2].openCaseCnt;
      this.cntOpAPT = res.response[3].openCaseCnt;
      this.cntOpHC = res.response[4].openCaseCnt;
      this.cntOpSC = res.response[5].openCaseCnt;
      this.opneCase = res.response[6].openCaseCnt;

    });

  }



  getCaseData(caseId: any, action: string, type: string, tab: string) {
    if (!tab) {
      tab = 'tab1';
    }
    if (caseId) {
      this.router.navigate([
        '/notice',
        caseId,
        { action: action, type: type, tab: tab },
      ]);
    } else {
      this.router.navigate([
        '/notice',
        { action: action, type: type, tab: tab },
      ]);
    }
  }
  createCase(action: string, type: string, tab: string) {
    const modalRef = this.modalService.open(CreateCaseModalComponent, {
      size: 'lg',
      windowClass: 'modal-xl, create-pop',
    });
    modalRef.componentInstance.type = type;
    modalRef.dismissed.subscribe((data) => {
      if (data === 'remanded') {
        this.router.navigate([
          '/notice',
          { action: action, type: type, tab: tab, remanded: true },
        ]);
      } else if (data === 'new') {
        this.router.navigate([
          '/notice',
          { action: action, type: type, tab: tab },
        ]);
      } else if (data === 'pre-Appellate') {
        this.router.navigate([
          '/notice',
          { action: action, type: type, tab: tab, memorandum: true },
        ]);
      } else if (data === 'pre-HC') {
        this.router.navigate([
          '/notice',
          { action: action, type: type, tab: tab, writ: true },
        ]);
      }
      else if (data === 'smart-Upload') {
        this.router.navigate(['/litigationSmartUpload']);
      }
    });
  }
  getStateName(code: any) {
    if (code) return this.common.getStateName(code);
  }

  getFileNameDta(fileUrl: any) {
    return this.litService.getFileName(fileUrl);
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl,
    };
    this.litService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.url;
        //this.downloadfileUrl() ;
        window.open(this.fileUrl, '_blank');
      }
    });
  }


  relopageRefresh() {
    if (this.router.url.includes('litigationSummary')) {
      this.common.reloadCurrentRoute();
    }
  }
  clearAll() {
    this.filterForm.reset();
  }
  isFilterApplied(acctype: any) {
    this.activeTab = acctype;
    if (this.isApplyFilter) {

      if(acctype == 'scn'){
        const payLoad = sessionStorage.getItem('Gst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection1;
        data.sortfield = this.forumSortMap.scn.sortField;
        data.sortdir = this.forumSortMap.scn.sortDir;

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.litService.getAllCaseSummary(data).subscribe((res: any) => {
          if (res.response == null) {
            this.letterSumofSCN = [];
          } else {
            this.letterSumofSCN = res.response;
          }
        });
      }else if (acctype == 'aja') {
        const payLoad = sessionStorage.getItem('Gst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection2;
        data.sortfield = this.forumSortMap.aja.sortField;
        data.sortdir = this.forumSortMap.aja.sortDir;

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDate';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.litService.getAllCaseSummaryAja(data).subscribe((res: any) => {
          if(res.response == null){
            this.letterSumofAJA = [];
          } else{
            this.letterSumofAJA = res.response;
          }
        });
      } else if (acctype == 'apa') {
        const payLoad = sessionStorage.getItem('Gst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection3;
        data.sortfield = this.forumSortMap.apa.sortField;
        data.sortdir = this.forumSortMap.apa.sortDir;

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDateOfAppeal';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.litService.getAllCaseSummaryCommissionar(data).subscribe((res: any) => {
          if(res.response == null){
            this.letterSumofAPA = [];
          } else{
            this.letterSumofAPA = res.response;
          }
        });
      } else if (acctype == 'apt') {
        const payLoad = sessionStorage.getItem('Gst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection4;
        data.sortfield = this.forumSortMap.apt.sortField;
        data.sortdir = this.forumSortMap.apt.sortDir;

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDateOfAppeal';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.litService.getAllCaseSummaryApt(data).subscribe((res: any) => {
          if(res.response == null){
            this.letterSumofAPT = [];
          } else{
            this.letterSumofAPT = res.response;
          }
        });
      } else if (acctype == 'hc') {
        const payLoad = sessionStorage.getItem('Gst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection5;
        data.sortfield = this.forumSortMap.hc.sortField;
        data.sortdir = this.forumSortMap.hc.sortDir;

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDateOfAppeal';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.litService.getAllCaseSummaryHc(data).subscribe((res: any) => {
          if(res.response == null){
            this.letterSumofHC = [];
          } else{
            this.letterSumofHC = res.response;
          }
        });
      } else if (acctype == 'sc') {
        const payLoad = sessionStorage.getItem('Gst-Filter')!;
        const data = JSON.parse(payLoad);
        data.size = this.perPageLimitSection6;
        data.sortfield = this.forumSortMap.sc.sortField;
        data.sortdir = this.forumSortMap.sc.sortDir;

        for (let index = 0; index < data.criterias.length; index++) {
          const element = data.criterias[index];

          if (element.p == 'dueDate') {
            element.p = 'dueDateOfAppeal';
          } else if (element.p == 'periodFromDate') {
            element.p = 'periodFromDate';
          } else if (element.p == 'periodToDate') {
            element.p = 'periodToDate';
          } else if (element.p == 'issues.issue') {
            element.p = 'issues.issue';
          } else if (element.p == 'keyword') {
            element.p = 'keyword';
          }
        }
        this.litService.getAllCaseSummarySc(data).subscribe((res: any) => {
          if(res.response == null){
            this.letterSumofSC = [];
          } else{
            this.letterSumofSC = res.response;
          }
        });
      }
    } else {
      this.getLitigationSumm(acctype);
    }
  }

  set CACHE(data: any) {
    sessionStorage.setItem(LITIGATION_SUM_CACHE, JSON.stringify(data));
  }
  get CACHE() {
    let cacheData = sessionStorage.getItem(LITIGATION_SUM_CACHE);
    if (cacheData) {
      return JSON.parse(cacheData);
    } else {
      return null;
    }

    //return JSON.parse(sessionStorage.getItem(CACHINGKEY)|| '{}');
  }

  contingencyReport(option: string) {
    if (option == 'Contingency') {
      this.showHeader = true;
    } else if (option == 'Master') {
      this.showHeader = false;
    }
    this.showHidePop();
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    let model: any = {};
    const criteriaArray: any = [{
      "p": "pan",
      "o": "eq",
      "v": this.navContext.pan
    }]
    if (this.navContext.entityType === 'Business') {
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 3;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? savedFilters.criterias : [];

    } else if (this.navContext.entityType === 'LEGAL') {
      const savedFilterCritera = savedFilters !== null ? savedFilters.criterias : [];
      const mergeCriteriaArray = [...savedFilterCritera, ...criteriaArray];
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 3;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? savedFilters.criterias : [];
      // model.criterias = (savedFilters && !criteriaArray) ? this.criteria : (!savedFilters && criteriaArray) ? criteriaArray : mergeCriteriaArray;

    }
    this.litService.generateContingencyReport(model, '3').subscribe((response) => {
      this.toaster.showSuccess("Request submitted successfully");
    })
  }

  /*download master report*/
  generatedReport(option: string) {
    if (option == 'Contingency') {
      this.showHeader = true;
    } else if (option == 'Master') {
      this.showHeader = false;
    }
    this.showHidePop();
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    let model: any = {};
    const criteriaArray: any = [{
      "p": "pan",
      "o": "eq",
      "v": this.navContext.pan
    }]

    if (this.navContext.entityType === 'Business') {
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 1;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? savedFilters.criterias : [];

    } else if (this.navContext.entityType === 'LEGAL') {
      const savedFilterCritera = savedFilters !== null ? savedFilters.criterias : [];
      const mergeCriteriaArray = [...savedFilterCritera, ...criteriaArray];
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 1;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? savedFilters.criterias : [];
      // model.criterias = (savedFilters && !criteriaArray) ? this.criteria : (!savedFilters && criteriaArray) ? criteriaArray : mergeCriteriaArray;

    }
    this.litService.generateReport(model, '1').subscribe((response) => {
      this.toaster.showSuccess("Request submitted successfully");
    })
  }
  showHidePop() {
    this.showPop = !this.showPop;
    setTimeout(() => {
      // set it to run again after ten minutes
      this.showPop = false;
      this.toaster.showError(
        'Request is time out, please place new request after some time.'
      );
    }, 600000);
  }
  closeDiv() {
    this.showPop = false;
  }
  statusReport() {
    this.litService.statusMasterReport().subscribe((response: any) => {
      if (response.status === 1) {
        this.statusData = response.response.status;
        if (this.statusData == 1) {
          this.showStatus = false;
          this.toaster.showSuccess('Request processed successfully');
        } else {
          this.toaster.showWarning('Request in progress');
        }
      }
    });
  }

  downLoadMasterReport() {
    this.litService.downLoadReport().subscribe((response) => {
      const rootPan = sessionStorage.getItem('company');
      const date = this.common.getCurrentDate();
      let fileName = `${rootPan}_GST Litigation Report_${date}`;
      if (this.showHeader) {
        fileName = `${rootPan}_PROV & CONT_Litigation Report_${date}`;
      }
      this.excelService.saveAsExcelFile(response, fileName, true);
      this.checkIcon = true;
      this.toaster.showSuccess('File downloaded successfully');
      this.relopageRefresh();
    });
  }

  downloadFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let compName = sessionStorage.getItem('company');
    let datetime = formatDate(new Date(), 'dd-MM-yyyy', 'en-US', '+0530');
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute(
      'download',
      `${compName}_GST Litigation Report_${datetime}.xlsx`
    );
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  /*download Contingency  report*/
  /*download */
  downloadCReport() {
    this.litService.downloadCReport().subscribe((response: any) => {
      this.downloadcontFile(response);
      this.toaster.showSuccess('File downloaded successfully');
    });
  }

  /*download master report*/
  // downloadReport() {
  //   this.litService.downloadReport().subscribe((response:any) => {
  //     this.downloadFile(response);
  //     this.toaster.showSuccess('File downloaded successfully');
  //   });

  // }

  downloadcontFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let compName = sessionStorage.getItem('company');
    let datetime = formatDate(new Date(), 'dd-MM-yyyy', 'en-US', '+0530');
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute(
      'download', `${compName}_Prov & Cont_Report_${datetime}.xlsx`
    );
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  /*download Contingency  report*/

  getKeyword(obj: any) {
    let keyword: any = [];
    if (obj.issues1) {
      if (obj.issues1.length > 0) {
        keyword = obj.issues1.map((i: any) => i.keyword?.trim());
      }
    } else {
      if (obj.itemList.length > 0) {
        obj.itemList.forEach((item: any) => {
          const itemKeywords = item.issues1.map((i: any) => i.keyword?.trim());
          keyword = [...keyword, ...itemKeywords];
        });
      }
    }
    const filterKeyword = keyword.filter((item: any) => item);
    return filterKeyword.length > 0
      ? filterKeyword.join(', ')
        ? filterKeyword.join(', ').length > 0
          ? filterKeyword.join(', ')
          : '--'
        : '--'
      : '--';
  }
  
  navDownload(caseId: any, module:any){
    //litigation-download
    this.router.navigate(['/litigationDownload', module ,caseId]);
}

  getSortValues(acctype: Forum, column: string, sortingDir?:string ) : SortBy {
    // sessionStorage.setItem('gst_Forum_Key', acctype);
    sessionStorage.setItem('gst_Column_Key', column);

    let result: SortBy;
    switch (acctype) {
        case 'scn':
            result = this.sort(column, sortingDir);
            break
        case 'aja':
            result = this.ajaSort(column, sortingDir);
            break
        case 'apa':
            result = this.commissionerSort(column, sortingDir);
            break
        case 'apt':
            result = this.appellatetribunalSort(column, sortingDir);
            break
        case 'hc':
            result = this.highCourtSort(column, sortingDir);
            break
        case 'sc':
            result = this.supremeCourtSort(column, sortingDir);
            break
        default:
            result = { sortField: 'createdOn', sortDir: 'DESC' };
            break
    }
    sessionStorage.setItem('gst_SortDir', result.sortDir);
    sessionStorage.setItem('gst_SortField', result.sortField);

    return result;
  }

  sort(column?: string, sortingDir?:any) : SortBy {
    let sortField = '';
    let sortDir = '';

    switch (column) {
      case PRELITIGATION_SORT.CASE_ID: {
       sortField = 'caseId';
       if(sortingDir){
        if(sortingDir === 'ASC'){
          this.sorting.caseId = true;
          sortDir = 'ASC';
        } else {
          this.sorting.caseId = false;
          sortDir = 'DESC';
        }
       } else{
        if (!this.sorting.caseId) {
            this.sorting.caseId = true;
            sortDir = 'ASC';
        } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
        }
       }   
        break;
      }
      case PRELITIGATION_SORT.STATE: {
        sortField = 'state';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        } else{
            if (!this.sorting.state) {
                this.sorting.state = true;
                sortDir = 'ASC';
            } else {
                this.sorting.state = false;
                sortDir = 'DESC';
            }
          } 
        break;
      }
      case PRELITIGATION_SORT.GSTIN: {
        sortField = 'gstin';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.gstin = true;
            sortDir = 'ASC';
          } else {
            this.sorting.gstin = false;
            sortDir = 'DESC';
          }
        } else{ 
          if (!this.sorting.gstin) {
              this.sorting.gstin = true;
              sortDir = 'ASC';
          } else {
              this.sorting.gstin = false;
              sortDir = 'DESC';
          }
        } 
        break;
      }
      case PRELITIGATION_SORT.CASE_LABEL: {
        sortField = 'caseLabel';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        } else{
            if (!this.sorting.caseLabel) {
                this.sorting.caseLabel = true;
                sortDir = 'ASC';
            } else {
                this.sorting.caseLabel = false;
                sortDir = 'DESC';
            }
        }  
        break;
      }
      case PRELITIGATION_SORT.PERIOD_FROM: {
        sortField = 'itemList.periodFromDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodFromDate) {
              this.sorting.periodFromDate = true;
              sortDir = 'ASC';
          } else {
              this.sorting.periodFromDate = false;
              sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.PERIOD_TO: {
        sortField = 'itemList.periodToDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodToDate) {
              this.sorting.periodToDate = true;
              sortDir = 'ASC';
          } else {
              this.sorting.periodToDate = false;
              sortDir = 'DESC';
          }
        }
        break;
      }
      case PRELITIGATION_SORT.STATUS: {
        sortField = 'Status';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.status) {
              this.sorting.status = true;
              sortDir = 'ASC';
          } else {
              this.sorting.status = false;
              sortDir = 'DESC';
          }
        }
        break;
      }
      default: {
        sortField = 'createdOn';
        sortDir = 'DESC';
        break;
      }
    }
    return { sortField, sortDir } as SortBy;
  }
  ajaSort(column?: string, sortingDir?:any) : SortBy {
    let sortField = '';
    let sortDir = '';

    switch (column) {
      case LITIGATION_SORT.CASE_ID: {
        sortField = 'caseId';
        if(sortingDir){
         if(sortingDir === 'ASC'){
           this.sorting.caseId = true;
           sortDir = 'ASC';
         } else {
           this.sorting.caseId = false;
           sortDir = 'DESC';
         }
        } else{
         if (!this.sorting.caseId) {
             this.sorting.caseId = true;
             sortDir = 'ASC';
         } else {
             this.sorting.caseId = false;
             sortDir = 'DESC';
         }
        }
        break;
      }
      case LITIGATION_SORT.STATE: {
        sortField = 'State';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.state) {
              this.sorting.state = true;
              sortDir = 'ASC';
          } else {
              this.sorting.state = false;
              sortDir = 'DESC';
          }
        }
        break;
      }
      case LITIGATION_SORT.GSTIN: {
        sortField = 'gstin';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.gstin = true;
            sortDir = 'ASC';
          } else {
            this.sorting.gstin = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.gstin) {
              this.sorting.gstin = true;
              sortDir = 'ASC';
          } else {
              this.sorting.gstin = false;
              sortDir = 'DESC';
          }
        }
        break;
      }
      case LITIGATION_SORT.CASE_LABEL: {
        sortField = 'caseLabel';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseLabel = true;            
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseLabel) {
              this.sorting.caseLabel = true;
              sortDir = 'ASC';
          } else {
              this.sorting.caseLabel = false;
              sortDir = 'DESC';
          }
        }
        break;
      }
      case LITIGATION_SORT.PERIOD_FROM: {
        sortField = 'periodFromDate';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodFromDate) {
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        }  
        break;
      }
      case LITIGATION_SORT.PERIOD_TO: {
        sortField = 'periodToDate';
        if(sortingDir){
         if(sortingDir === 'ASC'){
           this.sorting.periodToDate = true;
           sortDir = 'ASC';
         } else {
           this.sorting.periodToDate = false;
           sortDir = 'DESC';
         }
        } else{
          if (!this.sorting.periodToDate) {
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case LITIGATION_SORT.STATUS: {
        sortField = 'Status';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.status) {
              this.sorting.status = true;
              sortDir = 'ASC';
          } else {
              this.sorting.status = false;
              sortDir = 'DESC';
          }
        }
        break;
      }
      case LITIGATION_SORT.DEMAND: {
        sortField = 'totOfTotal1';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.totOfTotal1) {
              this.sorting.totOfTotal1 = true;
              sortDir = 'ASC';
          } else {
              this.sorting.totOfTotal1 = false;
              sortDir = 'DESC';
          }
        }
        break;
      }
      default: {
        sortField = 'createdOn';
        sortDir = 'DESC';
        break;
      }
    }
    return { sortField, sortDir } as SortBy;
  }
  commissionerSort(column?: string, sortingDir?:any) : SortBy{
    let sortField = ''; // Default field
    let sortDir= ''; // Default direction
  
    switch (column) {
      case COMMISSIONER_STAGE.CASE_ID: {
        sortField = 'caseId';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.caseId) {
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.STATE: {
        sortField = 'state';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.state) {
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.GSTIN: {
        sortField = 'gstin';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.gstin = true;
            sortDir = 'ASC';
          } else {
            this.sorting.gstin = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.gstin) {
            this.sorting.gstin = true;
            sortDir = 'ASC';
          } else {
            this.sorting.gstin = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.CASE_LABEL: {
        sortField = 'caseLabel';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.caseLabel) {
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.PERIOD_FROM: {
        sortField = 'periodFromDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodFromDate) {
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.PERIOD_TO: {
        sortField = 'periodToDate';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodToDate) {
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.STATUS: {
        sortField = 'status';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.status) {
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case COMMISSIONER_STAGE.DEMAND: {
        sortField = 'totOfTotal1';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.totOfTotal1) {
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      default: {
        sortField = 'createdOn';
        sortDir = 'DESC';
        break;
      }
    }
    return { sortField, sortDir } as SortBy;
  }
  appellatetribunalSort(column?: string, sortingDir?:any) : SortBy{
    let sortField = ''; 
    let sortDir=''; 
  
    switch (column) {
      case APPELLATET_STAGE.CASE_ID: {
        sortField = 'caseId';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseId) {
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.STATE: {
        sortField = 'state';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.state) {
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.GSTIN: {
        sortField = 'gstin';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.gstin = true;
            sortDir = 'ASC';
          } else {
            this.sorting.gstin = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.gstin) {
            this.sorting.gstin = true;
            sortDir = 'ASC';
          } else {
            this.sorting.gstin = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.CASE_LABEL: {
        sortField = 'caseLabel';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseLabel) {
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.PERIOD_FROM: {
        sortField = 'periodFromDate';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodFromDate) {
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.PERIOD_TO: {
        sortField = 'periodToDate';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodToDate) {
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.STATUS: {
        sortField = 'status';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.status) {
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case APPELLATET_STAGE.DEMAND: {
        sortField = 'totOfTotal1';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.totOfTotal1) {
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      default: {
        sortField = 'createdOn';
        sortDir = 'DESC';
        break;
      }
    }
    return { sortField, sortDir } as SortBy;
  }
  highCourtSort(column?: string, sortingDir?:any) : SortBy {
    let sortField = '';
    let sortDir = ''; 
  
    switch (column) {
      case HIGHCOURT_STAGE.CASE_ID: {
        sortField = 'caseId';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.caseId) {
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case HIGHCOURT_STAGE.STATE: {
        sortField = 'state';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.state) {
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case HIGHCOURT_STAGE.GSTIN: {
        sortField = 'gstin';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.gstin = true; 
            sortDir = 'ASC';
          } else {
            this.sorting.gstin = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.gstin) {
            this.sorting.gstin = true;
            sortDir = 'ASC';
          } else {
            this.sorting.gstin = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case HIGHCOURT_STAGE.CASE_LABEL: {
        sortField = 'caseLabel';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.caseLabel) {
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case HIGHCOURT_STAGE.PERIOD_FROM: {
        sortField = 'periodFromDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodFromDate) {
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case HIGHCOURT_STAGE.PERIOD_TO: {
        sortField = 'periodToDate';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.periodToDate) {
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case HIGHCOURT_STAGE.STATUS: {
        sortField = 'status';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.status) {
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case HIGHCOURT_STAGE.DEMAND: {
        sortField = 'totOfTotal1';
        if (sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        } else{
          if (!this.sorting.totOfTotal1) {
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      default: {
        sortField = 'createdOn';
        sortDir = 'DESC';
        break;
      }
    }
    return { sortField, sortDir } as SortBy;
  }
  supremeCourtSort(column?: string, sortingDir?:any) : SortBy {
    let sortField = 'createdOn'; // Default field
    let sortDir = 'DESC'; // Default direction
  
    switch (column) {
      case SUPREMECOURT_STAGE.CASE_ID: {
        sortField = 'caseId';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.caseId) {
            this.sorting.caseId = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseId = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case SUPREMECOURT_STAGE.STATE: {
        sortField = 'state';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.state) {
            this.sorting.state = true;
            sortDir = 'ASC';
          } else {
            this.sorting.state = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case SUPREMECOURT_STAGE.GSTIN: {
        sortField = 'gstin';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.gstin = true;
            sortDir = 'ASC';
          } else {
            this.sorting.gstin = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.gstin) {
            this.sorting.gstin = true;
            sortDir = 'ASC';
          } else {
            this.sorting.gstin = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case SUPREMECOURT_STAGE.CASE_LABEL: {
        sortField = 'caseLabel';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.caseLabel) {
            this.sorting.caseLabel = true;
            sortDir = 'ASC';
          } else {
            this.sorting.caseLabel = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case SUPREMECOURT_STAGE.PERIOD_FROM: {
        sortField = 'periodFromDate';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.periodFromDate) {
            this.sorting.periodFromDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodFromDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case SUPREMECOURT_STAGE.PERIOD_TO: {
        sortField = 'periodToDate';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.periodToDate) {
            this.sorting.periodToDate = true;
            sortDir = 'ASC';
          } else {
            this.sorting.periodToDate = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case SUPREMECOURT_STAGE.STATUS: {
        sortField = 'status';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.status) {
            this.sorting.status = true;
            sortDir = 'ASC';
          } else {
            this.sorting.status = false;
            sortDir = 'DESC';
          }
        }
        break;
      }
      case SUPREMECOURT_STAGE.DEMAND: {
        sortField = 'totOfTotal1';
        if(sortingDir){
          if(sortingDir === 'ASC'){
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        }else{
          if (!this.sorting.totOfTotal1) {
            this.sorting.totOfTotal1 = true;
            sortDir = 'ASC';
          } else {
            this.sorting.totOfTotal1 = false;
            sortDir = 'DESC';
          }
        }break;
      }
      default: {
        sortField = 'createdOn';
        sortDir = 'DESC';
        break;
      }
    }
    return { sortField, sortDir } as SortBy;
  }


  getSection1Data(perPageLimitSection1: any, section1page: number, option: string) {
    this.letterSumofSCN = [];
    let model: any = {};

    let tempKeyword: any = [];

    // const sortField =  sessionStorage.getItem('sortField');
    const sortField = this.forumSortMap.scn.sortField
    const sortDir = this.forumSortMap.scn.sortDir
    

    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
      model.companyId = this.contextData.companyId;
    } else {
      model.gstin = '';
      model.companyId = this.getterSetter.getSetCompanyId();
    }
    if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
    }
    this.companyId = this.getterSetter.getSetCompanyId();
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    Object.assign(model, {
      page: section1page == 1 ? 0 : section1page - 1,
      size: perPageLimitSection1,
      sortfield: sortField ,
      sortdir: sortDir,
     criterias: savedFilters ? savedFilters.criterias : []
    });
    let countModel: any = {};
    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    countModel.sortdir = 'DESC';
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
    }
    this.litService.getAllCaseSummary(model).subscribe(
      (response: any) => {
        if (response.status === 1) {
          this.litService.setAccessTabData({
            scnFlag: false,
            ajaFlag: false,
            apaFlag: false,
            aptFlag: false,
            hcFlag: false,
            scFlag: false,
          });
          this.letterSumofSCN = response.response;
          this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
            this.tableSection1Config.totalItems = res.response[0].totCaseCnt;
          });
          this.letterSumofSCN.sort((a: any, b: any) => a.caseId - b.caseId);
          if (option === "select") {
            this.tableSection1Config.totalItems = response.response.count;
            this.tableSection1Config.itemsPerPage = perPageLimitSection1;
            this.tableSection1Config.currentPage = model.pageNumber + 1;
          }
        }
      },
      (err) => {
        this.toaster.showError('User Role Based Access Restricted');
      }
    );
  }
  getSection2Data(perPageLimitSection2: any, pageSection2: number, option: string) {
    this.letterSumofAJA = [];
    let model: any = {};
    let countModel: any = {};

    const sortField =  this.forumSortMap.aja.sortField
    const sortDir = this.forumSortMap.aja.sortDir
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;

    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }

    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
    }
    let tempKeyword: any = [];
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
      model.companyId = this.contextData.companyId;
    } else {
      model.gstin = '';
      model.companyId = this.getterSetter.getSetCompanyId();
    }
    if (this.navContext.entityType === 'LEGAL') {
      model.pan = this.navContext.pan;
    }
    this.companyId = this.getterSetter.getSetCompanyId();
    
    Object.assign(model, {
      page: pageSection2 == 1 ? 0 : pageSection2 - 1,
      size: perPageLimitSection2,
      sortfield: sortField ,
      sortdir: sortDir,
      criterias: savedFilters ? savedFilters.criterias : []
    });
    // this.subscription.add(
    this.litService.getAllCaseSummaryAja(model).subscribe(
      (response: any) => {
        if (response.status === 1) {
          this.letterSumofAJA = response.response;

          this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
            this.tableSection2Config.totalItems = res.response[1].totCaseCnt;
          });
          if (option === "select") {
            this.tableSection2Config.totalItems = response.response.count;
            this.tableSection2Config.itemsPerPage = perPageLimitSection2;
            this.tableSection2Config.currentPage = model.pageNumber + 1;
          }

        }
      },
      (err) => {
        this.toaster.showError('User Role Based Access Restricted');
      }
    );
  }
  getSection3Data(perPageLimitSection3: any, section3page: number, option: string) {
    this.letterSumofAPA = [];
    let model: any = {};
    let countModel: any = {};

    const sortField =  this.forumSortMap.apa.sortField
    const sortDir = this.forumSortMap.apa.sortDir
    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
    }
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
      model.companyId = this.contextData.companyId;
    } else {
      model.companyId = this.getterSetter.getSetCompanyId();
    }
    if (this.navContext.entityType === 'LEGAL') {
      model.companyId = parse.companyId;
    }
    this.companyId = this.getterSetter.getSetCompanyId();
    this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
      this.tableSection3Config.totalItems = res.response[2].totCaseCnt;
    });
    Object.assign(model, {
      page: section3page === 1 ? 0 : section3page - 1,
      size: perPageLimitSection3,
      sortfield: sortField ,
      sortdir: sortDir,
      criterias:  savedFilters ? savedFilters.criterias : []
    });
    this.litService.getAllCaseSummaryCommissionar(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.letterSumofAPA = response.response;
        this.letterSumofAPA.sort((a: any, b: any) => a.caseId - b.caseId);
        if (option === "select") {
          this.tableSection3Config.totalItems = response.response.count;
          this.tableSection3Config.itemsPerPage = perPageLimitSection3;
          this.tableSection3Config.currentPage = model.pageNumber + 1;
        }

      }
    },
      (err) => {
        this.toaster.showError('User Role Based Access Restricted');
      }
    );
  }
  getSectio4Data(perPageLimitSection4: any, section4Page: number, option: string) {
    this.letterSumofAPT = [];
    let tempKeyword: any = [];
    let model: any = {};

    const sortField =  this.forumSortMap.apt.sortField
    const sortDir = this.forumSortMap.apt.sortDir

    const filters = sessionStorage.getItem('gst-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    let countModel: any = {};
    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.sortdir = 'DESC';
    countModel.criterias = savedFilters ? savedFilters.criterias : [];
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
    }
    if (this.navContext.entityType === 'FILING') {
      model.companyId = this.contextData.companyId;
    } else {
      model.companyId = this.getterSetter.getSetCompanyId();
    if (this.navContext.entityType === 'LEGAL') {
      model.companyId = parse.companyId;
    }
    this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
    });
    Object.assign(model, {
      page: section4Page == 1 ? 0 : section4Page - 1,
      size: perPageLimitSection4,
      sortfield: sortField ,
      sortdir: sortDir,
      criterias: savedFilters ? savedFilters.criterias : []
    });
    this.litService.getAllCaseSummaryApt(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.letterSumofAPT = response.response;
        if (option === "select") {
          this.tableSection4Config.totalItems = response.response.count;
          this.tableSection4Config.itemsPerPage = perPageLimitSection4;
          this.tableSection4Config.currentPage = model.pageNumber + 1;
        }
      }
    });
  }
}
getSectio5Data(perPageLimitSection5: any, section5Page: number, option: string) {
  this.letterSumofHC = [];
  let tempKeyword: any = [];
  let model: any = {};

  const sortField =  this.forumSortMap.hc.sortField
  const sortDir = this.forumSortMap.hc.sortDir

  let countModel: any = {};

  const filters = sessionStorage.getItem('gst-filters-Data');
  let savedFilters = null;
  savedFilters = !!filters ? JSON.parse(filters) : null;
  if (filters) {
    savedFilters = !!filters ? JSON.parse(filters) : null;
  }
  countModel.page = this.pageSection1;
  countModel.size = this.tableSize;
  countModel.sortdir = 'DESC';
  countModel.criterias = savedFilters ? savedFilters.criterias : [];
  const navCOntextData: any = sessionStorage.getItem('navContext');
  const parse = JSON.parse(navCOntextData);
  if (parse.entityType == 'Business') {
    const cId = sessionStorage.getItem('companyId');
    countModel.companyId = cId;
  } else if (parse.entityType == 'FILING') {
    countModel.companyId = parse.companyId;
  } else if (parse.entityType == 'LEGAL') {
    countModel.companyId = parse.companyId;
  }
  if (this.navContext.entityType === 'FILING') {
    model.companyId = this.contextData.companyId;
  } else {
    model.companyId = this.getterSetter.getSetCompanyId();
  }
  if (this.navContext.entityType === 'LEGAL') {
    countModel.companyId = parse.companyId;
  }
  this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
    this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
  });
  Object.assign(model, {
    page: section5Page == 1 ? 0 : section5Page - 1,
    size: perPageLimitSection5,
    sortfield: sortField ,
    sortdir: sortDir,
    criterias: savedFilters ? savedFilters.criterias : []
  });
  this.litService.getAllCaseSummaryHc(model).subscribe((response: any) => {
    if (response.status == 1) {
      this.letterSumofHC = response.response;
      this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
        this.tableSection5Config.totalItems = res.response[4].totCaseCnt;
      });
      if (option === "select") {
        this.tableSection5Config.totalItems = response.response.count;
        this.tableSection5Config.itemsPerPage = perPageLimitSection5;
        this.tableSection5Config.currentPage = model.pageNumber + 1;
      }
    }
  });
}
getSectio6Data(perPageLimitSection6: any, section6Page: number, option: string) {
  this.letterSumofSC = [];
  let tempKeyword: any = [];
  let model: any = {};

  const sortField =  this.forumSortMap.sc.sortField
  const sortDir = this.forumSortMap.sc.sortDir

  let countModel: any = {};

  const filters = sessionStorage.getItem('gst-filters-Data');
  let savedFilters = null;
  savedFilters = !!filters ? JSON.parse(filters) : null;
  if (filters) {
    savedFilters = !!filters ? JSON.parse(filters) : null;
  }
  countModel.page = this.pageSection1;
  countModel.size = this.tableSize;
  countModel.sortdir = 'DESC';
  countModel.criterias = savedFilters ? savedFilters.criterias : [];
  const navCOntextData: any = sessionStorage.getItem('navContext');
  const compId = sessionStorage.getItem('companyId');
  const parse = JSON.parse(navCOntextData);
  if (parse.entityType == 'Business') {
    const cId = sessionStorage.getItem('companyId');
    countModel.companyId = cId;
  } else if (parse.entityType == 'FILING') {
    countModel.companyId = parse.companyId;
  } else if (parse.entityType == 'LEGAL') {
    countModel.companyId = parse.companyId;
  }
  countModel.companyId = parse.companyId ? parse.companyId : compId
  if (this.navContext.entityType === 'FILING') {
    model.companyId = this.contextData.companyId;
  } else {
    model.companyId = parse.companyId;
  }
  if (this.navContext.entityType === 'LEGAL') {
    model.companyId = parse.companyId;
  }
  this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
    this.tableSection6Config.totalItems = res.response[5].totCaseCnt;
  });
  Object.assign(model, {
    page: section6Page == 1 ? 0 : section6Page - 1,
    size: perPageLimitSection6,
    sortfield: sortField ,
    sortdir: sortDir,
    criterias: savedFilters ? savedFilters.criterias : []
  });
  model.companyId = parse.companyId ? parse.companyId : compId
  this.litService.getAllCaseSummarySc(model).subscribe((response: any) => {
    if (response.status == 1) {
      this.letterSumofSC = response.response;
      this.litService.getAllCaseCount(countModel).subscribe((res: any) => {
        this.tableSection6Config.totalItems = res.response[5].totCaseCnt;
      });
      if (option === "select") {
        this.tableSection6Config.totalItems = response.response.count;
        this.tableSection6Config.itemsPerPage = perPageLimitSection6;
        this.tableSection6Config.currentPage = model.pageNumber + 1;
      }


      this.letterSumofSC.sort((a: any, b: any) => a.caseId - b.caseId);
    }
  });
}
  onTableDataChangeSection1(pageNumber: number): void {
    this.tableSection1Config.currentPage = pageNumber;

    this.getSection1Data(
      this.tableSection1Config.itemsPerPage,
      this.tableSection1Config.currentPage, ''
    );
    // this.fetchPosts();
  }
  onTableDataChangeSection2(pageNumber: number) {
    this.tableSection2Config.currentPage = pageNumber;
    this.getSection2Data(
      this.tableSection2Config.itemsPerPage,
      this.tableSection2Config.currentPage, ''
    );
  }
  onTableDataChangeSection3(pageNumber: number) {
    this.tableSection3Config.currentPage = pageNumber;
    this.getSection3Data(
      this.tableSection3Config.itemsPerPage,
      this.tableSection3Config.currentPage, ''
    );
  }
  onTableDataChangeSection4(pageNumber: number) {
    this.tableSection4Config.currentPage = pageNumber;
    this.getSectio4Data(
      this.tableSection4Config.itemsPerPage,
      this.tableSection4Config.currentPage, ''
    );
  }
  onTableDataChangeSection5(pageNumber: number) {
    this.tableSection5Config.currentPage = pageNumber;
    this.getSectio5Data(
      this.tableSection5Config.itemsPerPage,
      this.tableSection5Config.currentPage, ''
    );
  }
  onTableDataChangeSection6(pageNumber: number) {
    this.tableSection6Config.currentPage = pageNumber;
    this.getSectio6Data(
      this.tableSection6Config.itemsPerPage,
      this.tableSection6Config.currentPage, ''
    );
  }
  openModal(content: any) {
    this.filterType = 'Case Id';
    this.modalService.open(content, { windowClass: 'filter-pop' });
  }
  nextWeek() {
    let today = new Date();
    this.nextWeekDt = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    this.nxtDtdd1 = today;
    this.nxtDtdd2 = this.nextWeekDt;
    this.filterForm.get('dd1')?.setValue(this.nxtDtdd1);
    this.filterForm.get('dd2')?.setValue(this.nxtDtdd2);
  }
  nextMonth() {
    let today = new Date();
    this.nextMonthDt = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 30
    );
    this.nxtMonthdd1 = today;
    this.nxtMonthdd2 = this.nextMonthDt;
    this.filterForm.get('dd1')?.setValue(this.nxtMonthdd1);
    this.filterForm.get('dd2')?.setValue(this.nxtMonthdd2);
  }
  custom() {
    //  this.reset();
  }
  ngOnDestroy() {
    // if (this.filterPayload) {
    //   sessionStorage.setItem("gst-filters-Data", JSON.stringify(this.filterPayload))
    // }
  }
  setDate() {
    this.currentYear = new Date().getFullYear() + 1;
  }
  deleteForum: any;
  deleteCurrentForumData: boolean = false;
  caseIdData: any;
  isSubmitted = false;
  formtype: any;
  data: any[] = [];

  submitForm() {
    this.isSubmitted = true;
    let model: any = {}
    let caseIds: any = [];
    if (this.deleteForum == 'current-forum') {
      caseIds.push(this.caseIdData);
      model.caseIds = caseIds;
      model.gstin = this.selectedGstin;
      model.deleteCurrentForumData = true;
    } else {
      caseIds.push(this.caseIdData);
      model.caseIds = caseIds;
      model.gstin = this.selectedGstin;
      model.deleteCurrentForumData = false;
    }
    if (this.formtype === 'scn') {
      this.litService.deletePrelitigation(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('scn');
      });
    }
    else if (this.formtype === 'aja') {
      this.litService.deleteAdjudication(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('aja');
      });
    }
    else if (this.formtype === 'apa') {
      this.litService.deleteCommissioner(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('apa');
      });
    }
    else if (this.formtype === 'apt') {
      this.litService.deleteTribunal(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('apt');
      });
    }
    else if (this.formtype === 'hc') {
      this.litService.deleteHc(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('hc');
      });
    }
    else if (this.formtype === 'sc') {
      this.litService.deleteSc(model).subscribe((res: any) => {
        this.data = res.response[0];
        this.toaster.showSuccess(this.data + " " + "Details related to this case id deleted successfully");
        this.isFilterApplied('sc');
      })
    }
    this.reset_filter();
    this.getSCNCount();
    this.modalService.dismissAll();
  }
  reset_filter() {
    this.deleteForum = null;
  }
  openDetailPop(content: any, type: string, caseId: any,) {
    this.formtype = type;
    this.caseIdData = caseId;
    this.modalService.open(content, { size: 'lg', windowClass: 'modal-xl, delete-pop' });
  }
}
