<!-- <span class="nav-data" *ngIf="caseData"><span class="text-data"> Last Updated By : </span>{{caseData.updatedBy}} &nbsp; <span  class="text-data"> On : </span> {{caseData.updatedOn}} </span> -->
<div class="row">
  <div class="col-5" *ngIf="!sidemenu">
    <select class="form-control form-select" id="selectedFile"
      (change)="selectedFileUrlUpdate($event.target.value)">
      <ng-container *ngFor="let obj of uploadedFileUrlList ">
        <option [value]="obj.url">{{obj.name}}</option>
      </ng-container>
    </select>
    <embed [src]="selectedFileUrl" class="w100" style="height:100%;width: 100%;" *ngIf="!sidemenu" />
  </div>

<div class="col-7"  [ngClass]="[sidemenu ? 'col-12' : 'col-6']"> 
<div class="row">
  <div class="col-md-5">
    <ul class="nav nav-tabs iris-gst-display-flex b1" role="tablist">
      <li class="nav-item">
        <a class="nav-link " id="adjudp1-tab" data-toggle="tab" (click)="tabChanged('tab1')"
          [ngClass]="(selectedTab === 'tab1') ? 'active': ''" role="tab" aria-controls="adjudp1-1"
          aria-selected="true">Phase 1</a>
      </li>
      <!-- [ngClass]="{'disabled': !isPhase2}" -->
      <li class="nav-item"
        [ngClass]="{'disabled': (!isPhase2 && !(action == 'update' && getAccessTabData.ajaFlag) )|| disablePhase2} ">
        <!-- <li class="nav-item" > -->
        <a class="nav-link" id="adjudp2-tab" data-toggle="tab" (click)="tabChanged('tab2')"
          [ngClass]="(selectedTab === 'tab2') ? 'active': ''" role="tab" aria-controls="adjudp2-1"
          aria-selected="false">Phase 2</a>
      </li>
      <!-- [ngClass]="{'disabled': !isPhase3}" -->
      <li class="nav-item"
        [ngClass]="{'disabled': (!isPhase3  && !(action == 'update' && getAccessTabData.ajaFlag)) || disablePhase3}">
        <!-- <li class="nav-item" > -->
        <a class="nav-link" id="adjudp3-tab" data-toggle="tab" (click)="tabChanged('tab3')"
          [ngClass]="(selectedTab === 'tab3') ? 'active': ''" role="tab" aria-controls="adjudp3-1"
          aria-selected="false">Phase 3</a>
      </li>
    </ul>
  </div>
  <div class="col-md-3">
    <div class="textCenter pt11">
      <span class="infoVal fs16" *ngIf="isUpdate" title="Case ID">{{caseData.caseId}}</span>
    </div>
  </div>
  <div class="col-md-4">
    <span class="computationBtn" *ngIf="caseData">
      <span class="nav-data" *ngIf="caseData"><span class="text-data"> Last Updated By : </span>{{caseData.updatedBy}}
        &nbsp;
        <span class="text-data"> On : </span> {{caseData.updatedOn}} </span>
    </span>
  </div>
</div>


<div class="tab-content nopadding">
  <form [formGroup]="adjP1Form" (keydown.enter)="$event.preventDefault()">
    <div class="tab-pane fade show active" id="adjudp1-1" role="tabpanel" aria-labelledby="adjudp1-tab">
      <div class="card borderOrange" [hidden]="(selectedTab === 'tab2') || (selectedTab === 'tab3')"
        [ngClass]="{'bgGray': (action == 'view')} ">
        <div class="card-body">
          <fieldset [disabled]="action == 'view'">
            <!-- remanded back fields -->
            <div class="row" *ngIf="isRemandedBack">
              <span class="nav-item remanded-back" *ngIf="isRemandedBack">
                Remanded Back Case
              </span>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleInputPassword1">From which forum case is remanded back? <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input *ngIf="isUpdate" type="text" class="form-control" id="remandedCaseId" placeholder="" readonly
                    [value]="getFormName()">
                  <select *ngIf="!isUpdate" class="form-control form-select" (change)="changeRemandedBackForum($event)"
                    formControlName="remandedBackForum"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.remandedBackForum.errors }">
                    <option [value]=''>-- Select --</option>
                    <ng-container *ngFor="let obj of preAdjType">
                      <option [value]="obj.key">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.remandedBackForum.errors">
                    <span class="text-danger" *ngIf="p1.remandedBackForum.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <!-- Payment under remanded back -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleInputPassword1">ID of the case remanded back <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input *ngIf="isUpdate" type="text" class="form-control" id="remandedCaseId" placeholder="" readonly
                    formControlName="remandedCaseId">
                  <select *ngIf="!isUpdate" class="form-control form-select" id="remandedCaseId" placeholder="" readonly
                    formControlName="remandedCaseId"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.remandedCaseId.errors }"
                    (change)="selectedRemandedBackCaseId($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of remandedCaseObj">
                      <option [value]="obj.key">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.remandedCaseId.errors">
                    <span class="text-danger" *ngIf="p1.remandedCaseId.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div data-toggle="collapse" data-target="#paymentUnderRemandedBack" class="expansion-div mb-15"
                (click)="paymentUnderRemandedBackExpansion = !paymentUnderRemandedBackExpansion">
                Details of Payment Made Before Remanded Back
                <span class="consultant-icons"> <i *ngIf="!paymentUnderRemandedBackExpansion" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="paymentUnderRemandedBackExpansion" class="fa fa-angle-up"
                    aria-hidden="true"></i></span>
              </div>
              <div id="paymentUnderRemandedBack" class="collapse">
                <!--Payment table  -->
                <div class="row mb-15">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleInputPassword1"> </label>
                      <div class=" row">
                        <div class="col-md-12">
                          <div class="search-table-outter wrapper">
                            <table class="search-table inner w-100 tablepop">
                              <thead>
                                <tr class="bgGred">
                                  <th class="w-25">Particulars</th>
                                  <th>Tax (₹)</th>
                                  <th>Interest (₹)</th>
                                  <th>Penalty (₹)</th>
                                  <th>Total (₹)</th>
                                </tr>

                              </thead>
                              <tbody>
                                <ng-container>
                                  <tr *ngFor="let payment of getREmandedBackData()" [formGroup]="payment">
                                    <td class=""><textarea class="label-cls w-100" type="text"
                                        formControlName="label"></textarea> </td>
                                    <td>
                                      <input type="text" class="form-control" id="tax" placeholder=""
                                        formControlName="tax" maxlength="19" (input)="remandedBackPaymentValueChange()"
                                        InrFormat (paste)="handlePaste($event)"
                                        *ngIf="payment.value.label != 'Cumulative Consultant Charges'"
                                        style="text-align: right;">
                                      <div *ngIf="payment.value.label == 'Cumulative Consultant Charges'">-</div>
                                    </td>
                                    <td>
                                      <input type="text" class="form-control" id="interest" placeholder=""
                                        formControlName="interest" maxlength="19"
                                        (input)="remandedBackPaymentValueChange()" InrFormat
                                        (paste)="handlePaste($event)"
                                        *ngIf="payment.value.label != 'Cumulative Consultant Charges'"
                                        style="text-align: right;">
                                      <div *ngIf="payment.value.label == 'Cumulative Consultant Charges'">-</div>
                                    </td>
                                    <td>
                                      <input type="text" class="form-control" id="penalty" placeholder=""
                                        formControlName="penalty" maxlength="19"
                                        (input)="remandedBackPaymentValueChange()" InrFormat
                                        (paste)="handlePaste($event)" InrFormat
                                        *ngIf="payment.value.label != 'Cumulative Consultant Charges'"
                                        style="text-align: right;">
                                      <div *ngIf="payment.value.label == 'Cumulative Consultant Charges'">-</div>
                                    </td>
                                    <td><input type="text" class="form-control" id="total1" placeholder=""
                                        formControlName="total" maxlength="19"
                                        (input)="remandedBackPaymentValueChange()" InrFormat
                                        [disabled]="payment.value.label != 'Cumulative Consultant Charges'"
                                        style="text-align: right;"></td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                </div>
                <!-- Payment table -->
              </div>


            </div>
            <!-- remanded back fields ends -->
            <div class="row">
              <div class="col-md-3" *ngIf="hideOcrFiled">
                <div class="form-group">
                   <span class="row" *ngIf="hidlabels"> <button class="btn-beta"> Beta</button></span>
                  <label for="exampleInputPassword1" style="font-weight:600;">Upload PDF SCN for AI Smart fill
                  </label>
                  <input type="file" class="form-control file-upload-default" #file>
                  <div class="attachment-block">
                    <span (click)="openPdfPopModel(scnPdfPop)" style="margin-left: 5px;">
                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span><span
                      *ngIf="getScnCopyLocLength() != 0"> {{getScnCopyLocLength()}} Attachment</span>
                  </div>
                  <div>
                    <ng-template #scnPdfPop let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment  
                          <span style="font-weight: 500;color: #025c48;">
                            <br>Note: First upload system generated notice followed by annexure
                           </span>
                        </label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let scnLocGrp of getScnLoc(); let i = index" [formGroup]="scnLocGrp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="scnLocGrp.value.isdocLocUploadedClicked" type="file"
                                          id="scnLoc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                          (change)="onFileSelectSmart($event)" style="border: 1px solid #0000;">

                                        <p *ngIf="!scnLocGrp.value.isdocLocUploadedClicked || scnLocGrp.value.fileLoc"
                                          class="form-control pt10"> {{getFileNameDta(scnLocGrp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="scnLocGrp.value.fileLoc" title="Download file"
                                          (click)="download(scnLocGrp.value.fileLoc)"><i class="fa fa-download"></i></a>

                                        <a class="downupIcon"
                                          *ngIf="!(scnLocGrp.value.fileLoc || scnLocGrp.value.fileLoc) && isDisableIcons"
                                          title="Upload file" (click)="uploadScnCopy(i)"><i
                                            class="fa fa-upload"></i></a>

                                        <a class="downupIcon" (click)="deleteScnCopyAi(i)"
                                          *ngIf="(scnLocGrp.value.fileLoc) && isDisableIcons"><i class="fa fa-trash"
                                            aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>
                </div>

              </div>
              
              <div class="col-md-3" *ngIf="hiddenbtn" style="margin-top: 26px;">                           
                <button class="btn1" (click)="onSmartUpload('p1')">AI Smart Fill</button><i class="fa fa-hand-o-left smartinfo"></i>
                <span class="blink">Please click here</span>                    
            </div>
            <div class="col-md-6">
              <div style="text-align: left; font-size:14px;margin-top:28px;" >
                <i class="fa fa-spinner fa-spin refreshIcon"  *ngIf="hideRefreshIcon">
                </i> <span  class="errmsg">{{statusData}}</span>
              </div> 
            </div>
            </div>
            
            <div class="row">
              <div class="col-md-3" *ngIf="isUpdate">
                <div class="form-group">
                  <label for="exampleInputPassword1">Case ID<span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="caseId" placeholder="" readonly formControlName="caseId">

                </div>
              </div>
          
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Audit notice reference number, if any </label>
                  <input type="text" class="form-control" id="" placeholder="" formControlName="auditNoticeRefNo">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">GSTIN <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="" readonly placeholder="" formControlName="gstin">
                </div>
              </div>
              <div class="col-md-3" [hidden]="hideDiv">
                <div class="form-group">
                  <label for="exampleInputPassword1">Division <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="hidden" class="form-control" id="" readonly placeholder="" formControlName="gstinDiv">


                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">State <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="" readonly placeholder="" formControlName="state">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">SCN Number <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="scnNo"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.scnNo.errors }">
                  <div *ngIf="isSubmitted && p1.scnNo.errors">
                    <span class="text-danger" *ngIf="p1.scnNo.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Type of Authority <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="typeOfAuthority" formControlName="typeOfAuthority1"
                    (change)="issuingAuthChange1()"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.typeOfAuthority1.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of typeOfAuthorityDropdown">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.typeOfAuthority1.errors">
                    <span class="text-danger" *ngIf="p1.typeOfAuthority1.errors.required"> Required
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">DIN</label>
                  <input type="text" class="form-control" id="din" placeholder="" formControlName="din1"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.din1.errors }">
                  <div *ngIf="isSubmitted && p1.din1.errors">
                    <span class="text-danger" *ngIf="p1.din1.errors.required"> Required
                    </span>
                  </div>
                </div>
              </div>


              <div class="col-md-3 disabledSelect">
                <div class="form-group">
                  <label for="exampleInputPassword1">Mode of receipt of notice</label>
                  <select class="form-control form-select" formControlName="orderReceiptMode1"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.orderReceiptMode1.errors}">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of modeOfReceiptNotice">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of SCN <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfScn" [maxDate]="maxDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfScn.errors }"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfScn.errors">
                    <span class="text-danger" *ngIf="p1.dateOfScn.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of receipt of SCN <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfReceiptOfScn" [maxDate]="maxDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfReceiptOfScn.errors }"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfReceiptOfScn.errors">
                    <span class="text-danger" *ngIf="p1.dateOfReceiptOfScn.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Due date of reply <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dueDateOfReply" [monthNavigator]="true"
                      [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                      dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dueDateOfReply.errors }"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dueDateOfReply.errors">
                    <span class="text-danger" *ngIf="p1.dueDateOfReply.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Internal due date </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="internalDueDate" [monthNavigator]="true"
                      [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                      dataType="string">
                    </p-calendar>
                  </div>
                </div>
              </div>

              <!-- Issued U/s -->
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Issued U/s</label>
                  <select class="form-control form-select" id="authority" formControlName="issuedUs">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of preLitIssuedPH1Preceded ">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && adjP1Form.get('issuedUs')?.errors">
                    <span class="text-danger dateError" *ngIf="adjP1Form.get('issuedUs')?.errors?.required">
                      Required
                    </span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Period From <span class="mandate"><i
                        class="fa fa-asterisk"></i></span> </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="periodFromDate"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.periodFromDate.errors }" [maxDate]="maxDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.periodFromDate.errors">
                    <span class="text-danger" *ngIf="p1.periodFromDate.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Period To
                    <span class="mandate"><i class="fa fa-asterisk"></i></span>
                  </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="periodToDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.periodToDate.errors }"
                      (onSelect)="getperiodToValue(periodToPop)"></p-calendar>
                  </div>
                </div>
                <div *ngIf="isSubmitted && p1.periodToDate.errors">
                  <span class="text-danger" *ngIf="p1.periodToDate.errors.required"> Required</span>
                </div>
                <ng-template #periodToPop let-modal>
                  <div class="modal-header" style="border: none; padding-bottom: 15px; padding-top:9px" >
                    <button type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                    </button>
                  </div>
                  <div class="col-md-12" >
                    <div class="table-responsive">
                      <p class="title-data">Following cases are already exist for same selected period, <span style="font-weight: bold;">Do you want to still proceed?</span></p>
                      <table style="margin-left: 5px;">
                        <thead>
                          <tr class="bg-primary text-dblue">
                            <th class="sl-no-cla">Sr No</th>
                            <th class="clas">Case id</th>
                            <th>Case label</th>
                            <th class="clas">Financial Year</th> 
                            <th class="clas">Period From</th>
                            <th class="clas">Period To </th>
                            <th class="clas">Forum</th>
                            <th class="ac-no-clas">Total Demand(₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let obj of duplicateData  let i = index">
                            <td class="sl-no-cla">{{i + 1}}</td>
                            <td class="clas">{{obj.caseId}}</td>
                            <td>{{obj.caseLabel}}</td>
                            <td class="clas">{{obj.fy}}</td>
                            <td class="clas">{{obj.periodFromDate}}</td>
                            <td class="clas">{{obj.periodToDate}}</td>
                            <td class="clas">{{obj.forum}}</td>
                            <td class="ac-no-clas" style="text-align: right;">{{obj.demandAmount | INR: 0}} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="modal-footer" style="border: none; padding-top: 5px;" >
                    <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                    (click)="modal.close('No click')">Yes</button>
                      <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                      (click)="goToSummary()">No</button>
                  </div>
                </ng-template>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Extension of time granted</label>
                  <select class="form-control form-select" id="location" formControlName="isExtensionGranted">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of adjPH1Preceded">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                  <!-- <input type="text" class="form-control" id="extPeriod" placeholder=""
                    formControlName=""> -->
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Extension of time granted in days</label>
                  <input type="text" class="form-control" id="extensionDays" placeholder=""
                    formControlName="extensionDays" maxlength="3"
                    oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Extended period of limitation invoked or not ?</label>
                  <select class="form-control form-select" formControlName="extPeriodOfLimitationInvoked">
                    <ng-container *ngFor="let obj of yesNoDropdown">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>



              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Consultant</label>

                  <div *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" class="input-group">
                    <input type="text" class="form-control"
                      *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" placeholder=""
                      formControlName="consultant1" readonly>
                    <button *ngIf="isToggleConsultantDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('p1')">
                      <i class="fa fa-pencil"></i>
                    </button> 
                  </div>

                  <select class="form-control form-select" id="" 
                  *ngIf="!isToggleConsultantDropdown || action==='create'" formControlName="consultant1">
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of consultantList">
                        <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                      </ng-container>
                    </select>
                </div>
              </div> -->

              <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create')">
                <div class="form-group">
                  <label for="exampleInputPassword1">Consultant</label>
              
                  <!-- For Consultant Role: Create, View, and Update -->
                  <div *ngIf="roleName === 'Consultant' && action === 'create'">
                    <input 
                      type="text" 
                      class="form-control" 
                      formControlName="consultant1" 
                      [readOnly]="action === 'create'">
                  </div>
              
                  <!-- For Non-Consultant Role -->
                  <div *ngIf="roleName !== 'Consultant'">
                    <div *ngIf="isToggleConsultantDropdown && (action === 'update' || action === 'view')" class="input-group">
                      <input 
                        type="text" 
                        class="form-control" 
                        placeholder="" 
                        formControlName="consultant1" 
                        readonly>
                      <button 
                        *ngIf="isToggleConsultantDropdown && action === 'update'" 
                        class="btn btn-outline-secondary" 
                        type="button" 
                        (click)="onClickToggleConsultantDropdown('p1')">
                        <i class="fa fa-pencil"></i>
                      </button>
                    </div>
              
                    <select 
                      class="form-control form-select" 
                      id="" 
                      *ngIf="!isToggleConsultantDropdown || action === 'create'" 
                      formControlName="consultant1">
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of consultantList">
                        <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Person responsible <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                    <div *ngIf="isToggleDropdown && action==='update' || action==='view'" class="input-group">
                      <input type="text" class="form-control"
                        *ngIf="isToggleDropdown && action==='update' || action==='view'" placeholder=""
                        formControlName="personResponsible1" readonly>
                      <button *ngIf="isToggleDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleDropdown()">
                        <i class="fa fa-pencil"></i>
                      </button> 
                    </div>

                    <select class="form-control form-select" id="personResponsible1"
                      *ngIf="!isToggleDropdown || action==='create'" formControlName="personResponsible1"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.personResponsible1.errors }">
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of userRoleLs">
                        <option [value]="obj.userName">{{obj.userName}}</option>
                      </ng-container>
                    </select>
                    <div *ngIf="isSubmitted && p1.personResponsible1.errors">
                      <span class="text-danger" *ngIf="p1.personResponsible1.errors.required"> Required
                      </span>
                    </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Case Label <span class="mandate"><i
                        class="fa fa-asterisk"></i></span><a class="clrOrange infoIcon"
                      title="Please give a one line description to identify the case."><i class="fa fa-info-circle"
                        style="margin-left:3px;"></i></a></label>
                  <textarea class="form-control" id="exampleTextarea1" rows="4" formControlName="caseLabel"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.caseLabel.errors }"></textarea>
                </div>
                <div *ngIf="isSubmitted && p1.caseLabel.errors">
                  <span class="text-danger" *ngIf="p1.caseLabel.errors.required"> Required</span>
                </div>
                <div *ngIf="isSubmitted && p1.caseLabel.errors">
                  <span class="text-danger" *ngIf="p1.caseLabel.errors.minlength">Please enter min. 5 chars.</span>
                </div>
                <div *ngIf="isSubmitted && p1.caseLabel.errors">
                  <span class="text-danger" *ngIf="p1.caseLabel.errors.maxlength">Cannot enter more than 100
                    chars.</span>
                </div>
              </div>


            </div>
            <div class="row">
              <!-- <div class="col-md-12">
                <app-issue-demand></app-issue-demand>
              </div> -->
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Issue Wise Demand<a class=" addExtLetter"
                      *ngIf="(action == 'update' || action == 'create')" (click)="addIssueInv()"><i class="fa fa-plus"
                        title="Add row"></i></a> </label>
                  <!-- <input type="text" class="form-control" id="issueInvolved	" placeholder=""
                  formControlName="issuesInvolved"> -->
                  <div class=" row">
                    <div class="col-md-12">
                      <div class="search-table-outter wrapper">
                        <table class="search-table inner">
                          <thead>
                            <tr class="bgGred">
                              <th  rowspan="3" class="sl-no-cla" *ngIf="isUpdateBtn">Info</th>
                              <th rowspan="2">Issue<span class="mandate top7"><i class="fa fa-asterisk"></i></span></th>
                              <th rowspan="2">Keyword <a class="clrOrange infoIcon"
                                  title="Maximum Length Limit is 150 Characters."><i class="fa fa-info-circle"></i></a>
                              </th>
                              <th rowspan="2" class="txtCenter">Risk Parameter <span class="mandate top7"><i
                                    class="fa fa-asterisk"></i></span></th>
                              <th rowspan="2">Tag </th>
                              <th colspan="7" class="txtCenter">Amount Demanded (₹)</th>
                              <th rowspan="2">Remark <a class="clrOrange infoIcon"
                                  title="Max 500 characters allowed."><i class="fa fa-info-circle"></i></a></th>
                              <th rowspan="2">Add Provision Details</th>
                              <th rowspan="2">Remove</th>
                            </tr>
                            <tr class="bgGred">
                              <th>IGST (₹) </th>
                              <th>CGST (₹) </th>
                              <th>SGST (₹)</th>
                              <th>CESS (₹)</th>
                              <th>Interest (₹) <a *ngIf="isUpdateBtn" title="Interest Calculation" (click)="openIntCalPop(intCalPop)"><i class="fa fa-calculator fs14"></i></a></th>
                              <th>Penalty (₹)</th>
                              <th>Total (₹)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container>
                              <tr *ngFor="let div of issuInvArr(); let i=index" [formGroup]="div"
                                (keydown.enter)="$event.preventDefault()">
                                <td  class="sl-no-cla" *ngIf="isUpdateBtn">
                                  <p (click)="getissueData(issuePop,i)" class="pr5"><span
                                    class="badge-clo" title="Cases with similar issue"><i class="fa fa-info-circle"></i></span>
                                  </p>
                              </td>

                                <td class="w250">
                                  <select class="form-control form-select" formControlName="issue"
                                    [title]="div.value.issue ? div.value.issue:''"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }">
                                    <option [value]="null" disabled>-- Select --</option>
                                    <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                      <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                        {{item.name}}</option>
                                    </optgroup>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('issue')?.errors">
                                    <span class="text-danger" *ngIf="div.get('issue')?.errors?.required "> Required
                                    </span>
                                  </div>
                                  <!-- <input type="text" class="form-control" id="tax" placeholder="" formControlName="issue"> -->
                                </td>
                                <td><input type="text" class="form-control" id="tax" placeholder=""
                                    formControlName="keyword" maxlength="150"
                                    [title]="div.value.keyword ? div.value.keyword:''"></td>
                                <td>
                                  <select class="form-control form-select" id="status" formControlName="riskParam"
                                    [title]="div.value.riskParam ? div.value.riskParam:''"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('riskParam')?.errors  }">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of riskParam">
                                      <option [value]="obj.value">{{obj.value}}</option>
                                    </ng-container>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('riskParam')?.errors">
                                    <span class="text-danger" *ngIf="div.get('riskParam')?.errors?.required "> Required
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div class="input-group">
                                  <input type="text" class="form-control" [title]="div.value.tag ? div.value.tag:''"
                                    *ngIf="isToggleSegmentDropdown && action==='update' || action==='view'" placeholder=""
                                    formControlName="tag" readonly>
                                  <button *ngIf="isToggleSegmentDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleSegmentDropdown('p1')">
                                    <i class="fa fa-pencil"></i>
                                  </button> 
                                </div>
                                  <select class="form-control form-select" id="" [title]="div.value.tag ? div.value.tag:''"
                                  *ngIf="!isToggleSegmentDropdown || action==='create'" formControlName="tag">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of tagDataList">
                                    <option [value]="obj">{{obj}}</option>
                                  </ng-container>
                                </select>
                                </td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="igst1" maxlength="19"
                                    [title]="div.value.igst1 ? div.value.igst1:''" InrFormat
                                    (paste)="handlePaste($event)" (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="cgst1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="sgst1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="cess1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="interest1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="penalty1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="total1" readonly maxlength="19"
                                    [title]="div.value.total1 ? div.value.total1:''" InrFormat></td>
                                <td><input type="text" class="form-control" id="remark1" placeholder=""
                                    formControlName="remark1" [title]="div.value.remark1 ? div.value.remark1:''">
                                  <div *ngIf="div.value.remark1 && div.value.remark1.length > 500">
                                    <span class="text-danger">Max character Length 500</span>
                                  </div>
                                </td>
                                <td><span (click)="openDetailPop(detailPop, i)"><i
                                      class="icon  fa fa-file-text-o"></i></span></td>
                                <!-- <td><a class=" addExtLetter" (click)="openRmvIssuePop(removeIssuePopup, i)" *ngIf="isIssueAdd"><i class="fa fa-minus"></i></a></td> -->
                                <td><a class=" addExtLetter" (click)="openRmvIssuePop(removeIssuePopup, i)"><i
                                      class="fa fa-minus"></i></a></td>

                                <ng-template #removeIssuePopup let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p>Are you sure, you want to remove?</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="rmIssueInv(i)">Remove</button>
                                  </div>
                                </ng-template>
                                <ng-template #detailPop let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Details</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p><a class="pl5 addExtLetter" title="Add More Detail" (click)="addDetail(i)"><i
                                          class="fa fa-plus" title="Add row"></i> Add another row to update the next
                                        quarter provision entry</a></p>
                                    <div class="table-responsive autoScroll">
                                      <table id="" class="table table-bordered">
                                        <thead>
                                          <tr class="bg-primary text-dblue">
                                            <th>Financial Year </th>
                                            <th>Quarter </th>
                                            <th>Provision (₹)</th>
                                            <th>Contingent Liability (₹)</th>
                                            <th>Other Liability (₹)</th>
                                            <th>Reason for change in the Provision</th>
                                            <th>Reason for provision not made, fully or partly made</th>
                                            <th>Reason for Cont. Liab.</th>
                                            <th>Reason for disclosure in Other Liability</th>
                                            <th>Consider in CARO Report</th>
                                            <th>Amount of CARO (₹)</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <ng-container>
                                            <tr *ngFor="let detailsGrp of getdetails(div); let k=index"
                                              [formGroup]="detailsGrp" (keydown.enter)="$event.preventDefault()">
                                              <td>
                                                <select class="form-control form-select" id="gstin"
                                                  formControlName="finYear"
                                                  [title]="detailsGrp.value.finYear ? detailsGrp.value.finYear:''">
                                                  <option [value]="null">-- Select --</option>
                                                  <ng-container *ngFor="let obj of finyearData">
                                                    <option [value]="obj.year">{{obj.year}}</option>
                                                  </ng-container>
                                                </select>
                                              </td>
                                              <td>
                                                <select class="form-control form-select" id="gstin"
                                                  formControlName="quarter"
                                                  [title]="detailsGrp.value.quarter ? detailsGrp.value.quarter:''">
                                                  <option [value]="null">-- Select --</option>
                                                  <ng-container *ngFor="let obj of quarterList">
                                                    <option [value]="obj.value">{{obj.value}}</option>
                                                  </ng-container>
                                                </select>
                                              </td>
                                              <td><input type="text" class="form-control align-right"
                                                  formControlName="provisionAmt1" id="tax"
                                                  [title]="detailsGrp.value.provisionAmt1 ? detailsGrp.value.provisionAmt1:''"
                                                  placeholder="" maxlength="19" InrFormat (paste)="handlePaste($event)">
                                              </td>
                                              <td><input type="text" class="form-control align-right"
                                                  formControlName="contLiabilityAmt1" id="tax"
                                                  [title]="detailsGrp.value.contLiabilityAmt1 ? detailsGrp.value.contLiabilityAmt1:''"
                                                  placeholder="" maxlength="19" InrFormat (paste)="handlePaste($event)">
                                              </td>
                                              <td><input type="text" class="form-control align-right"
                                                  formControlName="otherLiability1" id="tax"
                                                  [title]="detailsGrp.value.otherLiability1" placeholder=""
                                                  maxlength="19" InrFormat (paste)="handlePaste($event)"></td>
                                              <td><input type="text" class="form-control" id="provisionChangeReason1"
                                                  placeholder="" data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.provisionChangeReason1.value ? getdetails(div)[k].controls.provisionChangeReason1.value:''"
                                                  formControlName="provisionChangeReason1"
                                                  (click)="openTextBoxModal('Reason for change in the Provision', 'provisionChangeReason1',div,k)">
                                              </td>
                                              <td><input type="text" class="form-control" id="provisionNotMadeReason1"
                                                  placeholder="" data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.provisionNotMadeReason1.value ? getdetails(div)[k].controls.provisionNotMadeReason1.value:''"
                                                  formControlName="provisionNotMadeReason1"
                                                  (click)="openTextBoxModal('Reason for provision not made, fully or partly made', 'provisionNotMadeReason1',div,k)">
                                              </td>
                                              <td><input type="text" class="form-control" id="contLiabReason1"
                                                  placeholder="" data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.contLiabReason1.value ? getdetails(div)[k].controls.contLiabReason1.value:''"
                                                  formControlName="contLiabReason1"
                                                  (click)="openTextBoxModal('Reason for Cont. Liab.', 'contLiabReason1',div,k)">
                                              </td>
                                              <td><input type="text" class="form-control" id="otherLiabilityReason1"
                                                  placeholder="" data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.otherLiabilityReason1.value ? getdetails(div)[k].controls.otherLiabilityReason1.value:''"
                                                  formControlName="otherLiabilityReason1"
                                                  (click)="openTextBoxModal('Reason for disclosure in Other Liability', 'otherLiabilityReason1',div,k)">
                                              </td>
                                              <td>
                                                <select class="form-control form-select" id="gstin"
                                                  formControlName="considerInCaroReport1"
                                                  [title]="detailsGrp.value.considerInCaroReport1 ? detailsGrp.value.considerInCaroReport1:''">
                                                  <option [value]="null">-- Select --</option>
                                                  <ng-container *ngFor="let obj of yesNoList">
                                                    <option [value]="obj.value">{{obj.label}}</option>
                                                  </ng-container>
                                                </select>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control align-right"
                                                  formControlName="caroAmt1" id="tax" placeholder="" maxlength="19"
                                                  InrFormat (paste)="handlePaste($event)">
                                                <div class="error-msg"
                                                  *ngIf="detailsGrp.value.considerInCaroReport1 =='true' && ((detailsGrp.value.caroAmt1 === null) || (detailsGrp.value.caroAmt1 == 0))">
                                                  If "Consider in CARO Report" is Yes then Amount of Caro column cannot
                                                  be zero.</div>
                                                <div class="error-msg"
                                                  *ngIf="(detailsGrp.value.considerInCaroReport1 == 'false') && (detailsGrp.value.caroAmt1 && (detailsGrp.value.caroAmt1 != 0))">
                                                  If "Consider in CARO Report" is No then Amount of Caro should be zero.
                                                </div>
                                              </td>
                                              <td><a class="addExtLetter"
                                                  (click)="openRmvDetailPop(removeDetailPopup, i, k)"><i
                                                    class="fa fa-minus"></i></a></td>
                                              <ng-template #removeDetailPopup let-modal>
                                                <div class="modal-header">
                                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                                  <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    <span aria-hidden="true">×</span>
                                                  </button>
                                                </div>
                                                <div class="modal-body">
                                                  <p>Are you sure, you want to remove?</p>
                                                </div>
                                                <div class="modal-footer">
                                                  <button type="button" class="btn btn-outline-dark"
                                                    (click)="rmDetail(i, k)">Remove</button>
                                                </div>
                                              </ng-template>

                                            </tr>
                                          </ng-container>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>

                                <ng-template #issuePop let-modal>
                                  <div class="modal-header">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12" >
                                    <div class="table-responsive">
                                      <p class="title-data">The latest updated cases in the LMS with the selected issue are listed below for your reference.: <span style="font-weight: bold;">{{div.value.issue}}</span></p>
                                      <div class="btn btn-outline-success btn-download btnRep" (click)="exportAsXLSX()" >
                                        <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
                                      </div>
                                      <table *ngIf="this.hideData">
                                        <thead >
                                          <tr class="bgGred1">
                                            <th class="sl-no-cla">Sr No</th>
                                            <th><span>PAN</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.panName"
                                                  (click)="SortingIssue(issueSorting.PAN)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.panName"
                                                  (click)="SortingIssue(issueSorting.PAN)"></i>
                                            </th>
                                            <th><span>GSTIN</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.gstin"
                                                  (click)="SortingIssue(issueSorting.GSTIN)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.gstin"
                                                  (click)="SortingIssue(issueSorting.GSTIN)"></i>
                                          </th>
                                            <th><span>Division</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.gstinDiv"
                                                  (click)="SortingIssue(issueSorting.DIVISION)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.gstinDiv"
                                                  (click)="SortingIssue(issueSorting.DIVISION)"></i>
                                          </th>
                                            <th><span>Case ID</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.caseId"
                                                  (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.caseId"
                                                  (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                          </th>
                                            <th><span>Case Label</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.caseLabel"
                                                  (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.caseLabel"
                                                  (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                            </th>
                                            <th ><span>Period From</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="!sorting.periodFromDate"
                                                  (click)="SortingIssue(issueSorting.PERIOD_FROM)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="sorting.periodFromDate"
                                                  (click)="SortingIssue(issueSorting.PERIOD_FROM)"></i>
                                          </th>
                                          <th ><span>Period To</span>&nbsp;
                                            <i class="fa fa-arrow-up cursor-pointer"
                                                aria-hidden="true"
                                                *ngIf="!sorting.periodToDate"
                                                (click)="SortingIssue(issueSorting.PERIOD_TO)"></i>
                                            <i class="fa fa-arrow-down cursor-pointer"
                                                aria-hidden="true"
                                                *ngIf="sorting.periodToDate"
                                                (click)="SortingIssue(issueSorting.PERIOD_TO)"></i>
                                        </th>
                                            <th><span>Person Responsible</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="!sorting.personResponsible"
                                                  (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="sorting.personResponsible"
                                                  (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                          </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            *ngFor="let obj of issueData  let i = index">
                                            <td class="sl-no-cla">{{i + 1}}</td>
                                            <td>{{obj.panName}}</td>
                                            <td>{{obj.gstin}} 
                                             <div style="color: rgb(126 132 131);">
                                              {{obj.gstinName}} </div></td>
                                            <td>{{obj.gstinDiv}}</td>
                                            <td>{{obj.caseId}}</td>
                                            <td>{{obj.caseLabel}}</td>
                                            <td>{{obj.periodFromDate}}</td>
                                            <td>{{obj.periodToDate}}</td>
                                            <td>{{obj.personResponsible}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div *ngIf="!this.hideData" style="font-size: large;text-align: center;font-weight: bold;">
                                        No data found.
                                      </div>
                                      
                                    </div>
                                  </div>
                                </ng-template>
                              </tr>
                            </ng-container>
                            <ng-container>
                              <tr>
                                <th colspan="5" *ngIf="isUpdateBtn">Total</th>
                                <th colspan="4" *ngIf="!isUpdateBtn">Total</th>
                                
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="" placeholder="" formControlName="igst1Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="" placeholder="" formControlName="cgst1Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="sgstTotal" placeholder="" formControlName="sgst1Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="cessTotal" placeholder="" formControlName="cess1Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="interestTotal" placeholder="" formControlName="interest1Total"
                                    readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="penaltyTotal" placeholder="" formControlName="penalty1Total" readonly>
                                </td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="penaltyTotal" placeholder="" formControlName="totOfTotal1" readonly>
                                </td>
                                <td colspan="5">&nbsp;</td>
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


            </div>
            <div class="col-md-12 nopadding">
              <div class="form-group">
                <label for="exampleInputPassword1">Case Summary </label>
                <textarea class="form-control" id="exampleTextarea1" rows="4" formControlName="caseSummary"></textarea>
              </div>
            </div>
            <div class="col-md-12 nopadding">
              <div class="form-group">
                <label for="exampleInputPassword1">Internal Remarks </label>
                <textarea class="form-control" id="exampleTextarea1" rows="4"
                  formControlName="internalRemarks"></textarea>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Upload SCN Copy<span class="mandate"><i
                        class="fa fa-asterisk"></i></span>
                  </label>
                  <input type="file" class="form-control file-upload-default" #file>
                  <div class="attachment-block" [ngClass]="{'errorBorder': isSubmitted && getScnCopyLocLength() === 0}">
                    <span (click)="openScnLocModel(scnCopyLocPop)" style="margin-left: 5px;">
                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span><span
                      *ngIf="getScnCopyLocLength() != 0"> {{getScnCopyLocLength()}} Attachment</span>
                  </div>

                  <div *ngIf="isSubmitted && getScnCopyLocLength() === 0">
                    <span class="text-danger">Required</span>
                  </div>
                  <div>
                    <ng-template #scnCopyLocPop let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment</label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                                <th style="width:50%;">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let scnLocGrp of getScnLoc(); let i = index" [formGroup]="scnLocGrp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="scnLocGrp.value.isdocLocUploadedClicked" type="file"
                                          id="scnLoc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                          (change)="onFileSelect($event)" style="border: 1px solid #0000;">

                                        <p *ngIf="!scnLocGrp.value.isdocLocUploadedClicked || scnLocGrp.value.fileLoc"
                                          class="form-control pt10"> {{getFileNameDta(scnLocGrp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="scnLocGrp.value.fileLoc" title="Download file"
                                          (click)="download(scnLocGrp.value.fileLoc)"><i class="fa fa-download"></i></a>

                                        <a class="downupIcon"
                                          *ngIf="!(scnLocGrp.value.fileLoc || scnLocGrp.value.fileLoc) && isDisableIcons"
                                          title="Upload file" (click)="uploadScnCopy(i)"><i
                                            class="fa fa-upload"></i></a>

                                        <a class="downupIcon" (click)="deleteScnCopy(i)"
                                          *ngIf="(scnLocGrp.value.fileLoc) && isDisableIcons"><i class="fa fa-trash"
                                            aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input type="text" class="form-control remark" formControlName="remarks"
                                    [title]="scnLocGrp.value.remarks ? scnLocGrp.value.remarks:''">
                                  <div *ngIf="scnLocGrp.value.remarks && scnLocGrp.value.remarks.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Attachments
                  </label>
                  <input type="file" class="form-control file-upload-default" #file>
                  <div class="attachment-block">
                    <span (click)="openAttachLocModel(attachmentLocPop)" style="margin-left: 5px;">
                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span><span
                      *ngIf="getAttachmentsLocLength() != 0"> {{getAttachmentsLocLength()}} Attachment</span>

                    <!-- <span style="cursor: pointer;" (click)="openAttachLocModel(attachmentLocPop)"> <i
                      class="icon fa fa fa-paperclip" style="cursor: pointer;"
                      title="Attach file"></i></span><span style="cursor: pointer;" (click)="openAttachLocModel(attachmentLocPop)"> Attachment</span> -->

                  </div>
                  <div>
                    <ng-template #attachmentLocPop let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment</label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                                <th style="width:50%;">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let attachLocGrp of getOtherAttachmentLoc(); let i = index"
                                [formGroup]="attachLocGrp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="attachLocGrp.value.isdocLocUploadedClicked" type="file"
                                          id="attachmentsLoc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                          (change)="onFileSelect($event)">
                                        <p *ngIf="!attachLocGrp.value.isdocLocUploadedClicked || attachLocGrp.value.fileLoc"
                                          class="form-control pt10"> {{getFileNameDta(attachLocGrp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="attachLocGrp.value.fileLoc" title="Download file"
                                          (click)="download(attachLocGrp.value.fileLoc)"><i
                                            class="fa fa-download"></i></a>
                                        <a class="downupIcon"
                                          *ngIf="!(attachLocGrp.value.fileLoc || attachLocGrp.value.fileLoc) && isDisableIcons"
                                          title="Upload file" (click)="uploadOtherAttach(i)"><i
                                            class="fa fa-upload"></i></a>
                                        <a class="downupIcon" (click)="deleteOtherAttach(i)"
                                          *ngIf="(attachLocGrp.value.fileLoc) && isDisableIcons "><i class="fa fa-trash"
                                            aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input type="text" [title]="attachLocGrp.value.remarks" class="form-control"
                                    formControlName="remarks"
                                    [title]="attachLocGrp.value.remarks ? attachLocGrp.value.remarks:''">
                                  <div *ngIf="attachLocGrp.value.remarks && attachLocGrp.value.remarks.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>

            <!-- Details of issuing auth phase 1 -->
            <div data-toggle="collapse" data-target="#detailOfIssuingAuthorityExpansion" class="expansion-div mb-15"
              (click)="detailOfIssuingAuthorityExpansion = !detailOfIssuingAuthorityExpansion">
              Details of Issuing Authority
              <span class="consultant-icons"> <i *ngIf="!detailOfIssuingAuthorityExpansion" class="fa fa-angle-down"
                  aria-hidden="true"></i> <i *ngIf="detailsOfAllPaymentMadeExpansion" class="fa fa-angle-up"
                  aria-hidden="true"></i></span>
            </div>
            <div class="collapse" id="detailOfIssuingAuthorityExpansion">
              <div class="row">
                <!-- name of officer -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Name of the Officer</label>
                    <input type="text" class="form-control" id="officer" placeholder="" formControlName="officer">
                  </div>
                </div>
                <!-- Designation -->
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Designation </label>
                    <select class="form-control form-select" formControlName="designation">
                      <ng-container *ngFor="let obj of designation">
                        <option [value]="obj.value">{{obj.key}}</option>
                      </ng-container>
                    </select>

                  </div>
                </div>
                <!-- Jurisdiction -->
                <div class="col-md-4 ">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Jurisdiction </label>
                    <input class="form-control" id="designation" formControlName="jurisdiction" />
                  </div>
                </div>
                <!-- Address -->
                <div class="col-md-4 ">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Address </label>
                    <input class="form-control" id="address" formControlName="address" />
                  </div>
                </div>


                <div class="col-md-4 ">
                  <div class="form-group">
                    <label for="exampleInputPassword1">Contact No. </label>
                    <input class="form-control" id="contactNo" formControlName="contact" maxlength="12"
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                  </div>
                </div>

                <div class="col-md-4 ">
                  <div class="form-group">
                    <label for="exampleInputPassword1">E-Mail. </label>
                    <input class="form-control" id="email" formControlName="email" />
                  </div>
                  <div *ngIf="p1.email.errors">
                    <span class="text-danger" *ngIf="p1.email.errors.pattern"> Please Enter Valid Email ID</span>
                  </div>
                </div>

              </div>
            </div>
            <!-- Details of issuing auth phase 1 ends -->

            <!-- Payment under protest phase1 -->
            <div data-toggle="collapse" data-target="#demo" class="expansion-div mb-15"
              (click)="protestDetailsExpansion = !protestDetailsExpansion">
              Details of Payments Made Under Protest
              <span class="consultant-icons"> <i *ngIf="!protestDetailsExpansion" class="fa fa-angle-down"
                  aria-hidden="true"></i> <i *ngIf="protestDetailsExpansion" class="fa fa-angle-up"
                  aria-hidden="true"></i></span>
            </div>
            <div id="demo" class="collapse">
              <div class=" row">
                <div class="col-md-12">
                  <div class="search-table-outter wrapper">
                    <table class="search-table inner">
                      <thead>
                        <tr class="bgGred">
                          <th class="sl-no-clas w-40">Sr.No.</th>
                          <th>Issue</th>
                          <th>Tag</th>
                          <th>Payment Date</th>
                          <th>Form of Payment</th>
                          <th>Reference / Challan No.</th>
                          <th>Mode of Payment</th>
                          <th>IGST (₹)</th>
                          <th>CGST (₹)</th>
                          <th>SGST (₹)</th>
                          <th>CESS (₹)</th>
                          <th>Interest (₹) </th>
                          <th>Penalty (₹)</th>
                          <th>Total (₹)</th>
                          <th>Accounting Reference</th>
                          <th>Copy of Form</th>
                          <th>Copy of Bank Challan</th>
                          <!-- <th class="upload-column-cls w-300" *ngIf="caseId">Supporting Documents </th>  -->
                          <th>Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container>

                          <tr *ngFor="let div of getDetailOfPaymentArr(); let i=index" [formGroup]="div"
                            (keydown.enter)="$event.preventDefault()">
                            <td class="sl-no-clas w-40">{{i + 1}} <span *ngIf="div?.value?.markedForPayment" title="Marked for Interest Calculation"><i class="fa fa-circle isMarked"></i></span></td>
                            <td>
                              <select class="form-control form-select" formControlName="issueName"  [title]="div.value.issueName ? div.value.issueName:''" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.issueName  }">
                              <option [value]="null" disabled>-- Select --</option>
                              <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                  {{item.name}}</option>
                              </optgroup>
                            </select>
                            <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.issueName">
                              <span class="text-danger"> Required.</span>
                            </div>
                            </td>
                            <td>
                              <select class="form-control form-select" id=""  formControlName="tag" [title]="div.value.tag ? div.value.tag:''"  [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.tag  }">
                              <option [value]="null">-- Select --</option>
                              <ng-container *ngFor="let obj of tagDataList">
                                <option [value]="obj">{{obj}}</option>
                              </ng-container>
                            </select>
                            <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.tag">
                              <span class="text-danger"> Required.</span>
                            </div>
                          
                            </td>
                            <td>
                              <div id="iris-gst-filter-frmDate " class="input-group date datepicker tbldatePicker" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.dateOfPayment  }">
                                <p-calendar appendTo="body" class="form-control" formControlName="dateOfPayment"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy"></p-calendar>                                
                              </div>
                              <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.dateOfPayment">
                                <span class="text-danger"> Required.</span>
                              </div>
                            </td>
                            <td>
                              <select class="form-control form-select" id="authority" formControlName="formOfPayment" >
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of formPayment ">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                            </td>
                            <!-- Challan No. / Ref. No. -->
                            <td> 
                              <input class="form-control" id="challanNo" formControlName="challanNo"
                                [ngClass]="{ 'errorBorder': div.value.total && !div.value.challanNo  }" />
                              <!-- <div *ngIf="isSubmitted && div.get('copyOfFormLoc')?.errors">
                                <span class="text-danger" *ngIf="div.get('copyOfFormLoc')?.errors?.required">Please
                                  Upload Copy of Challan</span>
                              </div> -->
                              <div *ngIf="div.value.total && !div.value.challanNo">
                                <span class="text-danger"> Required.</span>
                              </div>
                              <div *ngIf="div.value.challanNo && getProtestCopyLength(i) === 0">
                                <span class="text-danger"> Please Upload  Copy of Form</span>
                              </div>
                            </td>
                            <!-- Mode of payment -->
                            <td>
                              <select class="form-control form-select" id="status" formControlName="modeOfPayment"
                                [ngClass]="{ 'errorBorder': isSubmitted && div.get('riskParam')?.errors  }">
                                <option [value]="null">-- Select --</option>
                                <option *ngFor="let obj of modeOfPayment" [value]="obj.value">{{obj.value}}</option>
                              </select>
                            </td>

                            <td><input type="text" class="form-control align-right" formControlName="igst" id="igst"
                                placeholder="" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                (input)="paymentUnderProtestp1Changed()"></td>

                            <td><input type="text" class="form-control align-right" id="cgst" placeholder=""
                                  (input)="paymentUnderProtestp1Changed()" formControlName="cgst" maxlength="19" InrFormat
                                  (paste)="handlePaste($event)"></td>
                            <td><input type="text" class="form-control align-right" id="sgst" placeholder=""
                                    (input)="paymentUnderProtestp1Changed()" formControlName="sgst" maxlength="19" InrFormat
                                    (paste)="handlePaste($event)"></td>
                             <td><input type="text" class="form-control align-right" id="cess" placeholder=""
                                     (input)="paymentUnderProtestp1Changed()" formControlName="cess" maxlength="19" InrFormat
                                      (paste)="handlePaste($event)"></td>

                            <td><input type="text" class="form-control align-right" formControlName="interest"
                                id="interest" placeholder="" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                (input)="paymentUnderProtestp1Changed()"></td>
                            <td><input type="text" class="form-control align-right" formControlName="penalty"
                                id="penalty" placeholder="" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                (input)="paymentUnderProtestp1Changed()"></td>
                            <td><input type="text" class="form-control align-right" formControlName="total" id="total"
                                readonly placeholder="" maxlength="19" InrFormat></td>
                              <td>
                                  <input type="text" class="form-control" formControlName="accountingRef"
                                  [ngClass]="{ 'errorBorder': div.value.accountingRef?.length > 100 }"
                                    [title]="div.value.accountingRef ? div.value.accountingRef:''">
                                  <div
                                    *ngIf="div.value.accountingRef && div.value.accountingRef.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                              </td>

                            <!-- copyOfFormLoc attachment -->
                            <td>
                              <div class="row">
                                <div class="col-sm-9 nopadding">
                                  <span style="cursor: pointer;" (click)="openCopyOfForm(proCopyFormAck, i)"> <i
                                      class="icon fa fa fa-paperclip" title="Attach file"
                                      style="cursor: pointer;"></i></span>
                                  <span *ngIf="getProtestCopyLength(i) != 0"> {{getProtestCopyLength(i)}}
                                    Attachment</span>
                                  <ng-template #proCopyFormAck let-modal>
                                    <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                      <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="col-md-12">
                                      <label> Attachment</label>
                                      <div class="table-responsive">
                                        <table class="table table-bordered tablepop">
                                          <thead>
                                            <tr class="bgGred">
                                              <th>Upload</th>
                                              <th style="width:50%;">Remark</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngFor="let procopyFormGrp of getProtestCopyForm(div); let j = index"
                                              [formGroup]="procopyFormGrp">
                                              <td>
                                                <div class="row">
                                                  <div class="col-md-9 nopadding">
                                                    <div class="form-group">
                                                      <input *ngIf="procopyFormGrp.value.isdocLocUploadedClicked"
                                                        type="file"
                                                        id="protestDetails({{i}}).copyOfFormLoc({{j}}).fileLoc"
                                                        class="filetype form-control" name="myfile"
                                                        (change)="onFileSelect($event)">
                                                      <p *ngIf="!procopyFormGrp.value.isdocLocUploadedClicked || procopyFormGrp.value.fileLoc"
                                                        class="form-control pt10">
                                                        {{getFileNameDta(procopyFormGrp.value.fileLoc)}}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-3 nopadding">
                                                    <label for="exampleInputPassword1">
                                                      <a class="downupIcon" *ngIf="procopyFormGrp.value.fileLoc"
                                                        title="Download file"
                                                        (click)="download(procopyFormGrp.value.fileLoc)"><i
                                                          class="fa fa-download"></i></a>
                                                      <a class="downupIcon"
                                                        *ngIf="!(procopyFormGrp.value.fileLoc || procopyFormGrp.value.fileLoc) && isDisableIcons"
                                                        title="Upload file" (click)="uploadProCopyForm(i,j)"><i
                                                          class="fa fa-upload"></i></a>
                                                      <a class="downupIcon" (click)="deleteProCopyForm(i,j)"
                                                        *ngIf="(procopyFormGrp.value.fileLoc && isDisableIcons)"><i
                                                          class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </label>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control" formControlName="remarks"
                                                  [title]="procopyFormGrp.value.remarks ? procopyFormGrp.value.remarks:''">
                                                <div
                                                  *ngIf="procopyFormGrp.value.remarks && procopyFormGrp.value.remarks.length > 100">
                                                  <span class="text-danger">Max character Length 100</span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="modal-footer" style="border: none; padding-top: 0px;">
                                      <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Save</button>
                                    </div>
                                  </ng-template>
                                </div>
                                <div *ngIf="(div.get('challanNo')?.value ||  div.get('total')?.value) && getProtestCopyLength(i) === 0 ">
                                  <span class="text-danger"> Required.</span>
                                </div>
                              </div>
                            </td>
                            <!-- copyOfFormLoc attachment ends -->
                            <!-- copyOfBankChallanLoc attachment -->
                            <td>
                              <div class="row">
                                <div class="col-sm-9 nopadding">
                                  <span style="cursor: pointer;"
                                    (click)="openProCopyOfBankChallan(proBankChallanPop, i)"> <i
                                      class="icon fa fa fa-paperclip" title="Attach file"
                                      style="cursor: pointer;"></i></span>
                                  <span *ngIf="getProtestBankChallanLength(i) != 0"> {{getProtestBankChallanLength(i)}}
                                    Attachment</span>
                                  <ng-template #proBankChallanPop let-modal>
                                    <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                      <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="col-md-12">
                                      <label> Attachment</label>
                                      <div class="table-responsive">
                                        <table class="table table-bordered tablepop">
                                          <thead>
                                            <tr class="bgGred">
                                              <th>Upload</th>
                                              <th style="width:50%;">Remark</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              *ngFor="let probankchallanFormGrp of getProtestBankChallan(div); let j = index"
                                              [formGroup]="probankchallanFormGrp">
                                              <td>
                                                <div class="row">
                                                  <div class="col-md-9 nopadding">
                                                    <div class="form-group">
                                                      <input *ngIf="probankchallanFormGrp.value.isdocLocUploadedClicked"
                                                        type="file"
                                                        id="protestDetails({{i}}).copyOfBankChallanLoc({{j}}).fileLoc"
                                                        class="filetype form-control" name="myfile"
                                                        (change)="onFileSelect($event)">
                                                      <p *ngIf="!probankchallanFormGrp.value.isdocLocUploadedClicked || probankchallanFormGrp.value.fileLoc"
                                                        class="form-control pt10">
                                                        {{getFileNameDta(probankchallanFormGrp.value.fileLoc)}}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-3 nopadding">
                                                    <label for="exampleInputPassword1">
                                                      <a class="downupIcon" *ngIf="probankchallanFormGrp.value.fileLoc"
                                                        title="Download file"
                                                        (click)="download(probankchallanFormGrp.value.fileLoc)"><i
                                                          class="fa fa-download"></i></a>
                                                      <a class="downupIcon"
                                                        *ngIf="!(probankchallanFormGrp.value.fileLoc || probankchallanFormGrp.value.fileLoc) && isDisableIcons"
                                                        title="Upload file" (click)="uploadProBankChallanForm(i,j)"><i
                                                          class="fa fa-upload"></i></a>
                                                      <a class="downupIcon" (click)="deleteProBankChallanForm(i,j)"
                                                        *ngIf="(probankchallanFormGrp.value.fileLoc) && isDisableIcons"><i
                                                          class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </label>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control" formControlName="remarks"
                                                  [title]="probankchallanFormGrp.value.remarks ? probankchallanFormGrp.value.remarks:''">
                                                <div
                                                  *ngIf="probankchallanFormGrp.value.remarks && probankchallanFormGrp.value.remarks.length > 100">
                                                  <span class="text-danger">Max character Length 100</span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="modal-footer" style="border: none; padding-top: 0px;">
                                      <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Save</button>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>
                            </td>
                            <!-- copyOfBankChallanLoc attachment ends -->

                            <td>
                              <a class=" addExtLetter" (click)="addDetailOfPayment()"><i class="fa fa-plus"
                                  title="Add row"></i></a>
                              <a *ngIf="i != 0" class="addExtLetter"
                                (click)="openRemoveDetailsOfPaymentPop(rmvDetailsOfPaymentRef)"><i
                                  class="fa fa-minus"></i></a>
                            </td>
                            <ng-template #rmvDetailsOfPaymentRef let-modal>
                              <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                <button type="button" class="close" aria-label="Close"
                                  (click)="modal.dismiss('Cross click')">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <p>Are you sure, you want to remove?</p>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-outline-dark"
                                  (click)="rmPaymentUnderProtest(i)">Remove</button>
                              </div>
                            </ng-template>
                          </tr>

                          <tr>
                            <th class="align-center" colspan="7">Total</th>
                            <!-- <th class="align-right" *ngIf="!isUpdate" colspan="4">Total</th> -->
                            <td class="align-text-right bold-font">{{protestIgstTotal | INR : 0}}</td>
                            <td class="align-text-right bold-font">{{protestCGSTTotal | INR : 0}}</td>
                            <td class="align-text-right bold-font">{{protestSGSTTotal | INR : 0}}</td>
                            <td class="align-text-right bold-font">{{protestCESSTotal | INR : 0}}</td>
                            <td class="align-text-right bold-font">{{protestInterestTotal | INR : 0}}</td>
                            <td class="align-text-right bold-font">{{protestPenaltyTotal | INR : 0}}</td>
                            <td class="align-text-right bold-font">{{protestTotal | INR : 0}}</td>

                          </tr>
                        </ng-container>

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- Payment under protest phase2 -->
            <div class="row" *ngIf="isUpdate">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether writ application preferred to High Court</label>
                  <select class="form-control form-select" formControlName="whetherWritPreferredHc"
                    (change)="whetherWritToHCChange()">
                    <ng-container *ngFor="let obj of yesNoDropdown">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>



            <!-- </div> -->
            <div class="col-md-12 mt15 nopadding">
              <button class="btn btn-outline-primary mr-1" (click)="createP1()" *ngIf="isCreateBtn">Save & Next</button>
              <button class="btn btn-outline-primary mr-1" (click)="updateLetter('p1')"
                *ngIf="isUpdateBtn">Update</button>
              <!-- <button class="btn btn-outline-primary mr-1">Delete</button> -->
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show active" id="adjudp2-1" role="tabpanel" aria-labelledby="adjudp2-tab">
      <div class="card borderOrange" [hidden]="(selectedTab === 'tab1') || (selectedTab === 'tab3')"
        [ngClass]="{'bgGray': (action == 'view')} ">
        <div class="card-body">
          <fieldset [disabled]="action == 'view'">
            <div class="row">
              <!-- Submission table -->
              <div data-toggle="collapse" data-target="#detailOfSubmission" class="expansion-div mb-15"
                (click)="detailsOfSubmissionsExpansion = !detailsOfSubmissionsExpansion">
                Details of Submissions
                <span class="consultant-icons"> <i *ngIf="!detailsOfSubmissionsExpansion" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="detailsOfSubmissionsExpansion" class="fa fa-angle-up"
                    aria-hidden="true"></i></span>
              </div>
              <div id="detailOfSubmission" class="collapse show multi-collapse">
                <div class=" row">
                  <div class="col-md-12">
                    <div class="search-table-outter wrapper" style="overflow-y: hidden;">
                        <div style="text-align: left; font-size:14px;margin-bottom: 10px;">
                          <i class="fa fa-spinner fa-spin refreshIcon"  *ngIf="hideRefreshIcon">
                          </i> <span  class="errmsg">{{statusData}}</span>
                        </div> 
                      <table class="search-table inner">
                        <thead>
                          <tr class="bgGred">
                            <th class="w-40">Sr.No.</th>
                            <th class="col-md-2" style="font-weight:600;"   *ngIf="hideOcrFilePh2" >
                              <span class="row" *ngIf="hidlabelsub"> <button class="btn-beta" style="margin-left: 6px;"> Beta</button></span>
                              Upload PDF of Submission for AI Smart fill</th>
                            <th class="col-md-2">Submission Ref. No <span class="mandate mandate-text-1"><i
                                  class="fa fa-asterisk"></i></span></th>
                            <th class="col-md-2">Date of Submission <span class="mandate mandate-text-1"><i
                                  class="fa fa-asterisk"></i></span></th>
                            <th class="col-md-2">Mode of Submission</th>
                            <th class="col-md-2">Copy of Acknowledgement<span class="mandate mandate-text-1"><i
                                  class="fa fa-asterisk"></i></span></th>
                            <th class="col-md-2">Other Attachments</th>
                            <th class="ac-no-cls"> Actions</th>
                          </tr>

                        </thead>
                        <tbody>
                          <ng-container>

                            <tr *ngFor="let div of getSubmissionDetailsArr(); let i=index" [formGroup]="div"
                              (keydown.enter)="$event.preventDefault()">
                              <td class="sl-no-cls w-40">{{i + 1}}</td>
                              <td style="min-width: 316px;"  *ngIf="hideOcrFilePh2" >
                                <div class="row">
                                  <div class="col-sm-9 nopadding">
                                    <span (click)="openSubmissionPdfPopModel(copyFormAck, i)" style="margin-left: 5px;">
                                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                        title="Attach file"></i></span><span *ngIf="getCopyOfAckLocLength(i) != 0">
                                      {{getCopyOfAckLocLength(i)}} Attachment</span>
                                    <ng-template #copyFormAck let-modal>
                                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="col-md-12">
                                        <label> Attachment <span style="font-weight: 500;color: #025c48;">
                                          <br>Note: First upload system generated Reply followed by annexure
                                         </span>
                                        </label>
                                        <div class="table-responsive">
                                          <table class="table table-bordered tablepop">
                                            <thead>
                                              <tr class="bgGred">
                                                <th>Upload</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let copyFormGrp of getCopyOfAck(div); let j = index"
                                                [formGroup]="copyFormGrp">
                                                <td>
                                                  <div class="row">
                                                    <div class="col-md-9 nopadding">
                                                      <div class="form-group">
                                                        <input *ngIf="copyFormGrp.value.isdocLocUploadedClicked"
                                                          type="file"
                                                          id="submissions({{i}}).copyOfAckLoc({{j}}).fileLoc"
                                                          class="filetype form-control" name="myfile"
                                                          (change)="onFileSelectSmart($event,i)">
                                                        <p *ngIf="!copyFormGrp.value.isdocLocUploadedClicked || copyFormGrp.value.fileLoc"
                                                          class="form-control pt10">
                                                          {{getFileNameDta(copyFormGrp.value.fileLoc)}}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 nopadding">
                                                      <label for="exampleInputPassword1">
                                                        <a class="downupIcon" *ngIf="copyFormGrp.value.fileLoc"
                                                          title="Download file"
                                                          (click)="download(copyFormGrp.value.fileLoc)"><i
                                                            class="fa fa-download"></i></a>
                                                        <a class="downupIcon"
                                                          *ngIf="!(copyFormGrp.value.fileLoc || copyFormGrp.value.fileLoc) && isDisableIcons"
                                                          title="Upload file" (click)="uploadCopyAckForm(i,j)"><i
                                                            class="fa fa-upload"></i></a>
                                                        <a class="downupIcon" (click)="deleteCopyAckFormAi(i,j)"
                                                          *ngIf="(copyFormGrp.value.fileLoc) && isDisableIcons"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Save</button>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div style="margin: -33px 90px; z-index: 10;"  *ngIf="hiddenbtn && (i === getSubmissionDetailsArr().length - 1)">                           
                                    <button class="btn1" (click)="onSmartUpload('p2',i)" style="font-size: 12px;">AI Smart Fill</button> <i class="fa fa-hand-o-left smartinfo" style="margin-left: -6px;"></i>
                                    <span class="blink" style="font-size: 14px;">Please click here</span>                              
                                </div>
                                </div>
                              </td>
                              <!-- Mode of payment -->
                              <td><input class="form-control" id="refNo"
                                  formControlName="refNo" [title]="div.value.refNo ?div.value.refNo:''"
                                  [ngClass]="{ 'errorBorder': isSubmitted && div.get('refNo')?.errors}" />
                                <div *ngIf="isSubmitted && div.get('refNo')?.errors">
                                  <span class="text-danger" *ngIf="div.get('refNo')?.errors?.required"> Required</span>
                                </div>
                              </td>

                              <td>
                                <div id="iris-gst-filter-frmDate " class="input-group date datepicker tbldatePicker"
                                  [ngClass]="{ 'errorBorder': isSubmitted && div.get('submissionDate')?.errors}">
                                  <p-calendar appendTo="body" class="form-control" formControlName="submissionDate"
                                    dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                    dateFormat="dd-mm-yy"></p-calendar>
                                </div>
                                <div *ngIf="isSubmitted && div.get('submissionDate')?.errors">
                                  <span class="text-danger" *ngIf="div.get('submissionDate')?.errors?.required">
                                    Required</span>
                                </div>
                              </td>

                              <td>
                                <select class="form-control form-select" id="submissionMode"
                                  formControlName="submissionMode">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of modeOfAppealOptions">
                                    <option [value]="obj.value">{{obj.value}}</option>
                                  </ng-container>
                                </select>
                              </td>
                              <td>
                                <div class="row">
                                  <div class="col-sm-9 nopadding">
                                    <span (click)="openCopyOfAck(copyFormAck, i)" style="margin-left: 5px;">
                                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                        title="Attach file"></i></span><span *ngIf="getCopyOfAckLocLength(i) != 0">
                                      {{getCopyOfAckLocLength(i)}} Attachment</span>


                                    <ng-template #copyFormAck let-modal>
                                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="col-md-12">
                                        <label> Attachment</label>
                                        <div class="table-responsive">
                                          <table class="table table-bordered tablepop">
                                            <thead>
                                              <tr class="bgGred">
                                                <th>Upload</th>
                                                <th style="width:50%;">Remark</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr *ngFor="let copyFormGrp of getCopyOfAck(div); let j = index"
                                                [formGroup]="copyFormGrp">
                                                <td>
                                                  <div class="row">
                                                    <div class="col-md-9 nopadding">
                                                      <div class="form-group">
                                                        <input *ngIf="copyFormGrp.value.isdocLocUploadedClicked"
                                                          type="file"
                                                          id="submissions({{i}}).copyOfAckLoc({{j}}).fileLoc"
                                                          class="filetype form-control" name="myfile"
                                                          (change)="onFileSelect($event)">
                                                        <p *ngIf="!copyFormGrp.value.isdocLocUploadedClicked || copyFormGrp.value.fileLoc"
                                                          class="form-control pt10">
                                                          {{getFileNameDta(copyFormGrp.value.fileLoc)}}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 nopadding">
                                                      <label for="exampleInputPassword1">
                                                        <a class="downupIcon" *ngIf="copyFormGrp.value.fileLoc"
                                                          title="Download file"
                                                          (click)="download(copyFormGrp.value.fileLoc)"><i
                                                            class="fa fa-download"></i></a>
                                                        <a class="downupIcon"
                                                          *ngIf="!(copyFormGrp.value.fileLoc || copyFormGrp.value.fileLoc) && isDisableIcons"
                                                          title="Upload file" (click)="uploadCopyAckForm(i,j)"><i
                                                            class="fa fa-upload"></i></a>
                                                        <a class="downupIcon" (click)="deleteCopyAckForm(i,j)"
                                                          *ngIf="(copyFormGrp.value.fileLoc) && isDisableIcons"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type="text" class="form-control" formControlName="remarks"
                                                    [title]="copyFormGrp.value.remarks ? copyFormGrp.value.remarks:''">
                                                  <div
                                                    *ngIf="copyFormGrp.value.remarks && copyFormGrp.value.remarks.length > 100">
                                                    <span class="text-danger">Max character Length 100</span>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Save</button>
                                      </div>
                                    </ng-template>
                                  </div>
                                  <div *ngIf="isSubmitted && getCopyOfAckLocLength(i) === 0">
                                    <span class="text-danger"> Required</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <!-- other attachment -->
                                <div class="row">
                                  <div class="col-sm-9 nopadding">

                                    <!-- <span style="cursor: pointer;" (click)="openOtherAttach2(otherAttach2, i)"> 
                                    <i class="icon fa fa fa-paperclip" title="Attach file" style="cursor: pointer;"></i></span>
                                    <span style="cursor: pointer;" (click)="openOtherAttach2(otherAttach2, i)"> Attachment</span> -->

                                    <span (click)="openOtherAttach2(otherAttach2, i)" style="margin-left: 5px;">
                                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                        title="Attach file"></i></span><span
                                      *ngIf="getOtherAttachmentsLocLength(i) != 0"> {{getOtherAttachmentsLocLength(i)}}
                                      Attachment</span>

                                    <ng-template #otherAttach2 let-modal>
                                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="col-md-12">
                                        <label> Attachment</label>
                                        <div class="table-responsive">
                                          <table class="table table-bordered tablepop">
                                            <thead>
                                              <tr class="bgGred">
                                                <th>Upload</th>
                                                <th style="width:50%;">Remark</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                *ngFor="let otherAttachFormGrp of getOtherAttachment(div); let j = index"
                                                [formGroup]="otherAttachFormGrp">
                                                <td>
                                                  <div class="row">
                                                    <div class="col-md-9 nopadding">
                                                      <div class="form-group">
                                                        <input *ngIf="otherAttachFormGrp.value.isdocLocUploadedClicked"
                                                          type="file"
                                                          id="submissions({{i}}).otherAttachmentsLoc({{j}}).fileLoc"
                                                          class="filetype form-control" name="myfile"
                                                          (change)="onFileSelect($event)">
                                                        <p *ngIf="!otherAttachFormGrp.value.isdocLocUploadedClicked || otherAttachFormGrp.value.fileLoc"
                                                          class="form-control pt10">
                                                          {{getFileNameDta(otherAttachFormGrp.value.fileLoc)}}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 nopadding">
                                                      <label for="exampleInputPassword1">
                                                        <a class="downupIcon" *ngIf="otherAttachFormGrp.value.fileLoc"
                                                          title="Download file"
                                                          (click)="download(otherAttachFormGrp.value.fileLoc)"><i
                                                            class="fa fa-download"></i></a>
                                                        <a class="downupIcon"
                                                          *ngIf="!(otherAttachFormGrp.value.fileLoc || otherAttachFormGrp.value.fileLoc) && isDisableIcons"
                                                          title="Upload file" (click)="uploadOtherAttachment2(i,j)"><i
                                                            class="fa fa-upload"></i></a>
                                                        <a class="downupIcon" (click)="deleteOtherAttachment2(i,j)"
                                                          *ngIf="(otherAttachFormGrp.value.fileLoc) && isDisableIcons"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type="text" class="form-control" formControlName="remarks"
                                                    [title]="otherAttachFormGrp.value.remarks ? otherAttachFormGrp.value.remarks:''">
                                                  <div
                                                    *ngIf="otherAttachFormGrp.value.remarks && otherAttachFormGrp.value.remarks.length > 100">
                                                    <span class="text-danger">Max character Length 100</span>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Save</button>
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>

                                <!-- other attachment ends -->
                              </td>
                              <td><a class=" addExtLetter" (click)="addSubmissionDetailsArr()"><i class="fa fa-plus"
                                    title="Add row"></i></a>
                                <a *ngIf="i != 0" class="addExtLetter"
                                  (click)="openRemoveSubmissionDetailsPopup(rmvSubmissionDetailRef)"><i
                                    class="fa fa-minus"></i></a>
                              </td>
                              <ng-template #rmvSubmissionDetailRef let-modal>
                                <div class="modal-header">
                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <p>Are you sure, you want to remove?</p>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="rmSubmissionDetailProtest(i)">Remove</button>
                                </div>
                              </ng-template>
                            </tr>
                          </ng-container>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Submission table ends -->
            </div>
            <div class="row mb-12">
              <label>Details of Hearings </label>
            </div>
            <div class="card-body pt0 nopadding">
              <div formArrayName="itemList">
                <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                  <li class="nav-item" id="adjPhTab{{i}}" *ngFor="let adjPHtab of phDivArr().controls; let i=index"
                    [formGroupName]="i">
                    <a class="nav-link" id="adjudph1-tab" data-toggle="tab" (click)="phaseChanged('phase' + i, i)"
                      [ngClass]="(selectedPhase === 'phase' + i) ? 'active': ''" role="tab" aria-controls="adjudph1-1"
                      aria-selected="true">PH {{i+1}} <span *ngIf="i > 0" (click)="removeLetter(i)"
                        class="closeTab">x</span></a>
                  </li>
                </ul>
                <div class="tab-content nopadding">
                  <div class="tab-pane fade show active" id="adjudph1-1" role="tabpanel" aria-labelledby="adjudph1-tab">
                    <div class=" ml5" id="adjPhDiv{{i}}" *ngFor="let adjPHdiv of phDivArr().controls; let i=index"
                      [formGroupName]="i">
                      <div *ngIf="selectedPhase === 'phase' + i">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice Ref.No </label>
                              <input type="text" class="form-control" id="phRefNo" placeholder="" formControlName="phRefNo">
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice Date </label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="phNoticeDate"
                                  [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice
                              </label>
                              <input type="file" class="form-control file-upload-default" #file>
                              <div class="attachment-block">
                                <span (click)="openphCopyLoc(phCopyLoc, i)" style="margin-left: 5px;">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getPhCopyLocLength(i) != 0">
                                  {{getPhCopyLocLength(i)}} Attachment</span>
                              </div>
                              <div>
                                <ng-template #phCopyLoc let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let phCopyGrp of getphCopyLoc(adjPHdiv); let j = index"
                                            [formGroup]="phCopyGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="phCopyGrp.value.isdocLocUploadedClicked" type="file"
                                                      id="itemList({{i}}).phCopyLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)">
                                                    <p *ngIf="!phCopyGrp.value.isdocLocUploadedClicked || phCopyGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(phCopyGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="phCopyGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(phCopyGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(phCopyGrp.value.fileLoc || phCopyGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadPhCopy(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deletePhCopy(i,j)"
                                                      *ngIf="(phCopyGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" formControlName="remarks"
                                                [title]="phCopyGrp.value.remarks ? phCopyGrp.value.remarks:''">
                                              <div
                                                *ngIf="phCopyGrp.value.remarks && phCopyGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Date of PH scheduled </label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="dateOfPhScheduled"
                                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                          </div>
                          <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Time of PH </label>
                              <select class="form-control form-select" id="phTime" formControlName="phTime">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfTime">
                                  <option [value]="obj.value">{{obj.key}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Mode of PH </label>
                              <select class="form-control form-select" id="gstin" formControlName="mode">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfPH">
                                  <option [value]="obj.value">{{obj.key}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Attended </label>
                              <select class="form-control form-select" id="gstin" formControlName="attended">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of adjAttend">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                         

                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="">Adjourned/ New PH </label>
                            <select class="form-control form-select" id="gstin" formControlName="adjournedOrNot"
                              (change)="changeadjOrNot($event, i)">
                              <option [value]="null">-- Select --</option>
                              <ng-container *ngFor="let obj of adjPHAdjuorNotList">
                                <option [value]="obj.value">{{obj.label}}</option>
                              </ng-container>
                            </select>
                          </div>
                        </div>

                        </div>
                        <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Adjournment Letter</label>
                            <input type="file" class="form-control file-upload-default" #file>
                            <div class="attachment-block">

                              <span (click)="openAdjournmentLetter(phAdjournmentLetterLoc, i)" style="margin-left: 5px;">
                                <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span><span *ngIf="getphAdjournmentLetterLocLength(i) != 0">
                                {{getphAdjournmentLetterLocLength(i)}} Attachment</span>
                            </div>
                            <div>
                              <ng-template #phAdjournmentLetterLoc let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let phAdjournmentLetterGrp of getAdjournmentLetter(adjPHdiv); let j = index"
                                          [formGroup]="phAdjournmentLetterGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="phAdjournmentLetterGrp.value.isdocLocUploadedClicked" type="file"
                                                    id="itemList({{i}}).phAdjournmentLetterLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!phAdjournmentLetterGrp.value.isdocLocUploadedClicked || phAdjournmentLetterGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(phAdjournmentLetterGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="phAdjournmentLetterGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(phAdjournmentLetterGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(phAdjournmentLetterGrp.value.fileLoc || phAdjournmentLetterGrp.value.fileLoc) && isDisableIcons"
                                                    title="Upload file" (click)="uploadAdjournmentLetter(i,j)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAdjournmentLetter(i,j)"
                                                    *ngIf="(phAdjournmentLetterGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="phAdjournmentLetterGrp.value.remarks ? phAdjournmentLetterGrp.value.remarks:''">
                                            <div
                                              *ngIf="phAdjournmentLetterGrp.value.remarks && phAdjournmentLetterGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Date of Additional Written Submissions</label>
                            <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                              <p-calendar class="form-control" formControlName="dateOfsubmission"
                                [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate"
                                yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                dataType="string">
                              </p-calendar>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Upload the additional submission</label>
                            <input type="file" class="form-control file-upload-default" #file>
                            <div class="attachment-block">
                              <span (click)="openAdditionSub(additionalSubLoc, i)" style="margin-left: 5px;">
                                <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span><span
                                *ngIf="getAdditionalSubmissionLocLength(i) != 0">
                                {{getAdditionalSubmissionLocLength(i)}} Attachment</span>
                              <!-- <span style="cursor: pointer;" (click)="openAdditionSub(additionalSubLoc,i)"> <i
                              class="icon fa fa fa-paperclip" style="cursor: pointer;"  
                              title="Attach file"></i></span><span style="cursor: pointer;" (click)="openAdditionSub(additionalSubLoc,i)">Attachment</span> -->
                            </div>
                            <div>
                              <ng-template #additionalSubLoc let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let additionaSubGrp of getAdditionalSub(adjPHdiv); let j = index"
                                          [formGroup]="additionaSubGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="additionaSubGrp.value.isdocLocUploadedClicked"
                                                    type="file"
                                                    id="itemList({{i}}).additionalSubmissionLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!additionaSubGrp.value.isdocLocUploadedClicked || additionaSubGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(additionaSubGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="additionaSubGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(additionaSubGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(additionaSubGrp.value.fileLoc || additionaSubGrp.value.fileLoc) && isDisableIcons"
                                                    title="Upload file" (click)="uploadAdditionalSub(i,j)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAdditionalSub(i,j)"
                                                    *ngIf="(additionaSubGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="additionaSubGrp.value.remarks ? additionaSubGrp.value.remarks:''">
                                            <div
                                              *ngIf="additionaSubGrp.value.remarks && additionaSubGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Consultant</label>
          
                            <div *ngIf="isToggleConsultantDropdown2 && action==='update' || action==='view'" class="input-group">
                              <input type="text" class="form-control"
                                *ngIf="isToggleConsultantDropdown2 && action==='update' || action==='view'" placeholder=""
                                formControlName="consultant" readonly>
                              <button *ngIf="isToggleConsultantDropdown2 && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('p2')">
                                <i class="fa fa-pencil"></i>
                              </button> 
                            </div>
          
                            <select class="form-control form-select" id="caseId"
                                *ngIf="!isToggleConsultantDropdown2 || action==='create'" formControlName="consultant">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of consultantList">
                                  <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                </ng-container>
                              </select>
                          </div>
                        </div> -->

                        <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create')">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Consultant</label>
                        
                            <!-- For Consultant Role: Create, View, and Update -->
                            <div *ngIf="roleName === 'Consultant' && action === 'create'">
                              <input 
                                type="text" 
                                class="form-control" 
                                formControlName="consultant" 
                                [readOnly]="action === 'create'">
                            </div>
                        
                            <!-- For Non-Consultant Role -->
                            <div *ngIf="roleName !== 'Consultant'">
                              <div *ngIf="isToggleConsultantDropdown2 && (action === 'update' || action === 'view')" class="input-group">
                                <input 
                                  type="text" 
                                  class="form-control" 
                                  placeholder="" 
                                  formControlName="consultant" 
                                  readonly>
                                <button 
                                  *ngIf="isToggleConsultantDropdown2 && action === 'update'" 
                                  class="btn btn-outline-secondary" 
                                  type="button" 
                                  (click)="onClickToggleConsultantDropdown('p2')">
                                  <i class="fa fa-pencil"></i>
                                </button>
                              </div>
                        
                              <select 
                                class="form-control form-select" 
                                id="caseId" 
                                *ngIf="!isToggleConsultantDropdown2 || action === 'create'" 
                                formControlName="consultant">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of consultantList">
                                  <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH record, if any
                              </label>
                              <input type="file" class="form-control file-upload-default" #file>
                              <div class="attachment-block">

                                <span (click)="openPhRecord(phRecordLoc, i)" style="margin-left: 5px;">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getPhRecordIfAnyLocLength(i) != 0">
                                  {{getPhRecordIfAnyLocLength(i)}} Attachment</span>

                                <!-- <span style="cursor: pointer;" (click)="openPhRecord(phRecordLoc,i)"> <i
                                class="icon fa fa fa-paperclip" style="cursor: pointer;"  
                                title="Attach file"></i></span><span style="cursor: pointer;" (click)="openPhRecord(phRecordLoc,i)">Attachment</span> -->

                              </div>
                              <div>
                                <ng-template #phRecordLoc let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let phRecordGrp of getPhRecord(adjPHdiv); let j = index"
                                            [formGroup]="phRecordGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="phRecordGrp.value.isdocLocUploadedClicked" type="file"
                                                      id="itemList({{i}}).phRecordIfAnyLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)">
                                                    <p *ngIf="!phRecordGrp.value.isdocLocUploadedClicked || phRecordGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(phRecordGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="phRecordGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(phRecordGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(phRecordGrp.value.fileLoc || phRecordGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadPhRecord(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deletePhRecord(i,j)"
                                                      *ngIf="(phRecordGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" formControlName="remarks"
                                                [title]="phRecordGrp.value.remarks ? phRecordGrp.value.remarks:''">
                                              <div
                                                *ngIf="phRecordGrp.value.remarks && phRecordGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>


                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH discussion notes</label>
                              <textarea class="form-control" id="exampleTextarea1" rows="4"
                                formControlName="phDiscussionNotes"></textarea>
                            </div>
                          </div>



                      </div>
                    </div>
                  </div>
                  <!-- <div class="tab-pane fade show active" id="adjudph2-1" role="tabpanel" aria-labelledby="adjudph2-tab">
      <div class=" ml5"  *ngIf="selectedPhase === 'phase2'">

              <h4>PH2 fields will come here</h4>

      </div>
  </div> -->
                </div>






              </div>

              <!-- Consultant charges table -->
              <div data-toggle="collapse" data-target="#demo" class="expansion-div mb-15"
                (click)="consultantPanleExpansion != consultantPanleExpansion">Details of Consultant Charges <span
                  class="consultant-icons"> <i *ngIf="!consultantPanleExpansion" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="consultantPanleExpansion" class="fa fa-angle-up"
                    aria-hidden="true"></i></span> </div>
              <br>
              <div id="demo" class="collapse">
                <div class=" row">
                  <div class="col-md-12">
                    <div class="search-table-outter wrapper p-15">
                      <table class="table table-bordered tablepop">
                        <thead>
                          <tr class="bgGred">
                            <th class=" w-40">Sr.No.</th>
                            <th>Name of the Consultant </th>
                            <th>Name of Consulting Firm</th>
                            <th>Nature of Work</th>
                            <th>Fees Charged (₹)</th>
                            <th>Supporting Document</th>
                            <th>Email id</th>
                            <th>Contact Number</th>
                            <th>Action </th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container>
                            <tr *ngFor="let div of consultantInvArr() let i=index;" [formGroup]="div">
                              <td class="sl-no">{{i+1}}</td>
                              <td>
                                <!-- <input type="text" class="form-control" id="tax" placeholder=""
                                  formControlName="consultant" [title]="div.value.consultant ? div.value.consultant:''"> -->
                                  <div *ngIf="isToggleConsultantDropdown3 && action==='update' || action==='view'" class="input-group">
                                    <input type="text" class="form-control"
                                      *ngIf="isToggleConsultantDropdown3 && action==='update' || action==='view'" placeholder=""
                                      formControlName="consultant" readonly>
                                    <button *ngIf="isToggleConsultantDropdown3 && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('CC')">
                                      <i class="fa fa-pencil"></i>
                                    </button> 
                                  </div>
                          
                                    <select class="form-control form-select" id="tax"
                                      *ngIf="!isToggleConsultantDropdown3 || action==='create'" formControlName="consultant" (change)="consultantDataAutofil(i)">
                                      <option [value]="null">-- Select --</option>
                                      <ng-container *ngFor="let obj of consultantList">
                                      <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                      </ng-container>
                                    </select>
                              </td>
                              <td><input type="text" class="form-control" id="tax" placeholder="" formControlName="firm"
                                  [title]="div.value.firm ? div.value.firm:''" readonly style="cursor: not-allowed;">
                              </td>
                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                  formControlName="natureOfWork"
                                  [title]="div.value.natureOfWork ? div.value.natureOfWork:''"></td>
                              <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                  formControlName="fees" (input)="feesChargedChanged()" maxlength="19" InrFormat
                                  (paste)="handlePaste($event)"></td>
                              <td>
                                <div class="row">
                                  <div class="col-sm-9 nopadding">
                                    <span (click)="openConsultAttach(consultAttachPop, i)" style="margin-left: 5px;">
                                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                        title="Attach file"></i></span><span
                                      *ngIf="getConsultOtherAttachmentLength(i) != 0">
                                      {{getConsultOtherAttachmentLength(i)}} Attachment</span>

                                    <ng-template #consultAttachPop let-modal>
                                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="col-md-12">
                                        <label> Attachment</label>
                                        <div class="table-responsive">
                                          <table class="table table-bordered tablepop">
                                            <thead>
                                              <tr class="bgGred">
                                                <th>Upload</th>
                                                <th style="width:50%;">Remark</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                *ngFor="let consultAttachGrp of getConsultOtherAttachment(div); let j = index"
                                                [formGroup]="consultAttachGrp">
                                                <td>
                                                  <div class="row">
                                                    <div class="col-md-9 nopadding">
                                                      <div class="form-group">
                                                        <input *ngIf="consultAttachGrp.value.isdocLocUploadedClicked"
                                                          type="file"
                                                          id="consultantCharges({{i}}).otherAttachmentLoc({{j}}).fileLoc"
                                                          class="filetype form-control" name="myfile"
                                                          (change)="onFileSelect($event)">
                                                        <p *ngIf="!consultAttachGrp.value.isdocLocUploadedClicked || consultAttachGrp.value.fileLoc"
                                                          class="form-control pt10">
                                                          {{getFileNameDta(consultAttachGrp.value.fileLoc)}}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 nopadding">
                                                      <label for="exampleInputPassword1">
                                                        <a class="downupIcon" *ngIf="consultAttachGrp.value.fileLoc"
                                                          title="Download file"
                                                          (click)="download(consultAttachGrp.value.fileLoc)"><i
                                                            class="fa fa-download"></i></a>
                                                        <a class="downupIcon"
                                                          *ngIf="!(consultAttachGrp.value.fileLoc || consultAttachGrp.value.fileLoc) && isDisableIcons"
                                                          title="Upload file" (click)="uploadConsultAttach(i,j)"><i
                                                            class="fa fa-upload"></i></a>
                                                        <a class="downupIcon" (click)="deleteConsultAttach(i,j)"
                                                          *ngIf="(consultAttachGrp.value.fileLoc) && isDisableIcons"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type="text" class="form-control" formControlName="remarks"
                                                    [title]="consultAttachGrp.value.remarks ? consultAttachGrp.value.remarks:''">
                                                  <div
                                                    *ngIf="consultAttachGrp.value.remarks && consultAttachGrp.value.remarks.length > 100">
                                                    <span class="text-danger">Max character Length 100</span>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Save</button>
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <input type="text" class="form-control" placeholder="" formControlName="email" readonly style="cursor: not-allowed;">
                              </td>
                              <td>
                                <input class="form-control" id="contact" formControlName="contact"
                                  [title]="div.value.contact ? div.value.contact:''" maxlength="12" readonly style="cursor: not-allowed;">
                              </td>

                              <td>
                                <a class=" addExtLetter" (click)="addConsultantInv()"><i class="fa fa-plus"
                                    title="Add row"></i></a>
                                <a class=" addExtLetter" *ngIf="i != 0"
                                  (click)="openRmvconsultantPop(removeConsultantPopup)"><i class="fa fa-minus"></i></a>
                                <ng-template #removeConsultantPopup let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p>Are you sure, you want to remove?</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="rmConsultantInv(i)">Remove</button>
                                  </div>
                                </ng-template>
                              </td>
                            </tr>
                            <tr>
                              <!-- <td class="sl-no-cls"></td> -->
                              <th colspan="4">Total</th>
                              <td class="align-right bold-font">{{feesChargedTotal | INR : 0}}</td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Consultant charges table ends -->

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether the case is kept in Call book ? <a
                      class="clrOrange infoIcon"
                      title="Please select this option as No if you want to update Phase 3"><i class="fa fa-info-circle"
                        style="margin-left: -6px;"></i></a></label>
                  <select class="form-control form-select" id="whetherCaseCallBook"
                    [ngClass]="{'disabled': callBookDisabled }" formControlName="whetherCaseCallBook"
                    (change)="whetherCallBookChanged()">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of yesNoDropdown">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-md-12 mt15 nopadding">
                <button class="btn btn-outline-primary mr-1" (click)="updateLetter('p2')"
                  *ngIf="isUpdateBtn">Update</button>
                <!-- <button class="btn btn-outline-primary mr-1">Delete</button> -->
              </div>
            </div>


          </fieldset>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show active" id="adjudp3-1" role="tabpanel" aria-labelledby="adjudp3-tab">
      <div class="card" [hidden]="(selectedTab === 'tab1') || (selectedTab === 'tab2')"
        [ngClass]="{'bgGray': (action == 'view')} ">
        <div class="card-body">
          <fieldset [disabled]="action == 'view'">
            <div class="row">
              <!-- *ngIf="hideOcrFilePh3" -->
                <div class="col-md-3" *ngIf="hideOcrFilePh3" >
                  <div class="form-group">
                    <span class="row" *ngIf="hidlabelsOio"> <button class="btn-beta"> Beta</button></span>
                    <label for="exampleInputPassword1" style="font-weight:600;">Upload PDF o-i-o Copy for AI Smart fill 
                    </label>
                    <input type="file" class="form-control file-upload-default" #file>
                    <div class="attachment-block">
                      <span (click)="openOioPdfPopModel(oioLocpdfPop)" style="margin-left: 5px;">
                        <i class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span><span
                        *ngIf="getOioLocLength() != 0"> {{getOioLocLength()}} Attachment</span>
  
                    </div>
                    <div>
                      <ng-template #oioLocpdfPop let-modal>
                        <div class="modal-header" style="border: none; padding-bottom: 0px;">
                          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">×</span> </button>
                        </div>
                        <div class="col-md-12">
                          <label> Attachment  <span style="font-weight: 500;color: #025c48;">
                           <br>Note: First upload system generated order followed by annexure
                          </span>
                          </label>
                          <div class="table-responsive">
                            <table class="table table-bordered tablepop">
                              <thead>
                                <tr class="bgGred">
                                  <th>Upload</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let oioLocGrp of getOioLoc(); let i = index" [formGroup]="oioLocGrp">
                                  <td>
                                    <div class="row">
                                      <div class="col-md-9 nopadding">
                                        <div class="form-group">
                                          <input *ngIf="oioLocGrp.value.isdocLocUploadedClicked" type="file"
                                            id="oioLoc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                            (change)="onFileSelectSmart($event)">
                                          <p *ngIf="!oioLocGrp.value.isdocLocUploadedClicked || oioLocGrp.value.fileLoc"
                                            class="form-control pt10"> {{getFileNameDta(oioLocGrp.value.fileLoc)}}
                                          </p>
                                        </div>
                                      </div>
                                      <div class="col-md-3 nopadding">
                                        <label for="exampleInputPassword1">
                                          <a class="downupIcon" *ngIf="oioLocGrp.value.fileLoc" title="Download file"
                                            (click)="download(oioLocGrp.value.fileLoc)"><i class="fa fa-download"></i></a>
                                          <a class="downupIcon"
                                            *ngIf="!(oioLocGrp.value.fileLoc || oioLocGrp.value.fileLoc) && isDisableIcons"
                                            title="Upload file" (click)="uploadOioCopy(i)"><i
                                              class="fa fa-upload"></i></a>
                                          <a class="downupIcon" (click)="deleteOioCopyAi(i)"
                                            *ngIf="(oioLocGrp.value.fileLoc)&& isDisableIcons"><i class="fa fa-trash"
                                              aria-hidden="true"></i></a>
                                        </label>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="modal-footer" style="border: none; padding-top: 0px;">
                          <button type="button" class="btn btn-outline-dark"
                            (click)="modal.close('Save click')">Save</button>
                        </div>
                      </ng-template>
                    </div>

                  </div>
                </div>
                <div class="col-md-3" *ngIf="hiddenbtn" >                           
                  <button class="btn1" (click)="onSmartUpload('p3')">AI Smart Fill</button> <i class="fa fa-hand-o-left smartinfo"></i>
                  <span class="blink">Please click here</span>                        
              </div> 

              <div class="col-md-6">
                <div style="text-align: left; font-size:14px;margin-top:28px;" >
                  <i class="fa fa-spinner fa-spin refreshIcon"  *ngIf="hideRefreshIcon">
                  </i> <span  class="errmsg">{{statusData}}</span>
                </div> 
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">O-i-O Number <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="oioNo" formControlName="oioNo"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.oioNo.errors }">
                  <div *ngIf="isSubmitted && p1.oioNo.errors">
                    <span class="text-danger" *ngIf="p1.oioNo.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of O-i-O <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfOio" [maxDate]="maxDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfOio.errors }"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfOio.errors">
                    <span class="text-danger" *ngIf="p1.dateOfOio.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of receipt of O-i-O <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfReceiptOfOio" [maxDate]="maxDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfReceiptOfOio.errors }"></p-calendar>

                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfReceiptOfOio.errors">
                    <span class="text-danger" *ngIf="p1.dateOfReceiptOfOio.errors.required"> Required</span>
                  </div>

                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Copy of O-i-O<span class="mandate"><i
                        class="fa fa-asterisk"></i></span>
                  </label>
                  <input type="file" class="form-control file-upload-default" #file>
                  <div class="attachment-block" [ngClass]="{'errorBorder': isSubmitted && getOioLocLength() === 0 }">

                    <span (click)="openOioLocModel(oioLocPop)" style="margin-left: 5px;">
                      <i class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span><span
                      *ngIf="getOioLocLength() != 0"> {{getOioLocLength()}} Attachment</span>

                  </div>
                  <!-- <div *ngIf="isSubmitted && getCopyOfAckLocLength(i) === 0">
                    <span class="text-danger"> Required</span>
                  </div> -->

                  <div *ngIf="isSubmitted &&  getOioLocLength() === 0">
                    <span class="text-danger"> Required</span>
                  </div>
                  <div>
                    <ng-template #oioLocPop let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment</label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                                <th style="width:50%;">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let oioLocGrp of getOioLoc(); let i = index" [formGroup]="oioLocGrp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="oioLocGrp.value.isdocLocUploadedClicked" type="file"
                                          id="oioLoc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                          (change)="onFileSelect($event)">
                                        <p *ngIf="!oioLocGrp.value.isdocLocUploadedClicked || oioLocGrp.value.fileLoc"
                                          class="form-control pt10"> {{getFileNameDta(oioLocGrp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="oioLocGrp.value.fileLoc" title="Download file"
                                          (click)="download(oioLocGrp.value.fileLoc)"><i class="fa fa-download"></i></a>
                                        <a class="downupIcon"
                                          *ngIf="!(oioLocGrp.value.fileLoc || oioLocGrp.value.fileLoc) && isDisableIcons"
                                          title="Upload file" (click)="uploadOioCopy(i)"><i
                                            class="fa fa-upload"></i></a>
                                        <a class="downupIcon" (click)="deleteOioCopy(i)"
                                          *ngIf="(oioLocGrp.value.fileLoc)&& isDisableIcons"><i class="fa fa-trash"
                                            aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input type="text" class="form-control" formControlName="remarks"
                                    [title]="oioLocGrp.value.remarks ? oioLocGrp.value.remarks:''">
                                  <div *ngIf="oioLocGrp.value.remarks && oioLocGrp.value.remarks.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Order Passed by <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="gstin" formControlName="orderPassedBy"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.orderPassedBy.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of orderPassedBy">
                      <option [value]="obj.key">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.orderPassedBy.errors">
                    <span class="text-danger" *ngIf="p1.orderPassedBy.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Type of Authority <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="gstin" formControlName="typeOfAuthority3"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.typeOfAuthority3.errors }"
                    (change)="issuingAuthChange3()">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of typeOfAuthorityDropdown">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.typeOfAuthority3.errors">
                    <span class="text-danger" *ngIf="p1.typeOfAuthority3.errors.required"> Required</span>
                  </div>
                </div>
              </div>


              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">DIN</label>
                  <input type="text" class="form-control" id="din3" placeholder="" formControlName="din3"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.din3.errors}">
                  <div *ngIf="isSubmitted && p1.din3.errors">
                    <span class="text-danger" *ngIf="p1.din3.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Mode of Receipt of order</label>
                  <select class="form-control form-select" formControlName="orderReceiptMode3"
                    [ngClass]="{'errorBorder': isSubmitted && p1.orderReceiptMode3.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of modeOfAppealOptions">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.orderReceiptMode3.errors">
                    <span class="text-danger" *ngIf="p1.orderReceiptMode3.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Outcome <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="gstin" formControlName="outcome"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.outcome.errors }" (change)="changeOutcome($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of outcome">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.outcome.errors">
                    <span class="text-danger" *ngIf="p1.outcome.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Further Appeal Decision <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="furtherAppealDecision" formControlName="furtherAppealDecision"
                  [ngClass]="{ 'errorBorder': isSubmitted && p1.furtherAppealDecision.errors }"  (change)="checkfurAppDeciYes($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of furtherAppealDecision">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.furtherAppealDecision.errors">
                    <span class="text-danger" *ngIf="p1.furtherAppealDecision.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether appeal preferred </label>
                  <select class="form-control form-select" id="whetApplPref" formControlName="whetherAppealPreferred"
                    (change)="checkAppYes($event)" [ngClass]="{ 'disabled': disabledWeatherAppl }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of whetherAppealPreferred">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether Writ application preferred </label>

                  <select class="form-control form-select" id="whetWritPref" formControlName="writAppPreferred"
                    [ngClass]="{'disabled': disabledWeatherWrit }" (change)="checkwritAppYes($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of writAppPreferred">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether department has preferred appeal </label>
                  <select class="form-control form-select" id="whetDeptPref" formControlName="whetherDptAppealPreferred"
                    (change)="checkDeptYes($event)" [ngClass]="{ 'disabled': disabledWeatherDept }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of whetherDptAppealPreferred">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>

                </div>
              </div>

              <!-- Forum before which appeal preffered -->
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleInputPassword1">Forum before which Appeal / Writ has been preferred <span
                      class="forum-appeal-star-cls mandate"><i class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="gstin" formControlName="forumOfAppeal"
                    (change)="openProceedWithPreferredForumAppealPop(proceedWithPreferredForumAppeal)"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.forumOfAppeal.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of forumAppeal">
                      <option [value]="obj.key">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.forumOfAppeal.errors">
                    <span class="text-danger" *ngIf="p1.forumOfAppeal.errors.required"> Required</span>
                  </div>
                </div>
                <ng-template #proceedWithPreferredForumAppeal let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Warning!</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>You have selected <span class="bold-font">{{getForumAppealValue(p1.forumOfAppeal.value)}} </span>
                      as the forum before which the appeal / writ has been preferred. Do you want to proceed ?</p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark"
                      (click)="rmProceedWithPreferredForumAppeal()">Yes</button>
                    <button type="button" class="btn btn-outline-dark"
                      (click)="rmProceedWithPreferredForumAppeal(true)">No</button>
                  </div>
                </ng-template>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Order Summary</label>
                  <textarea class="form-control" id="exampleTextarea1" rows="4"
                    formControlName="orderSummery"  [title]="p1.orderSummery.value ? p1.orderSummery.value:''"></textarea>
                </div>
              </div>
              <!-- <div class="col-md-12">
                <app-issue-demand></app-issue-demand>
              </div> -->
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Issue Wise Details </label>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr class="bgGred">
                              <th>Issue</th>
                              <th>Keyword <a class="clrOrange infoIcon"
                                  title="Maximum Length Limit is 150 Characters."><i class="fa fa-info-circle"></i></a>
                              </th>
                              <th>Conclusion <span class="mandate top7"><i class="fa fa-asterisk"></i></span></th>
                              <th>Tag</th>
                              <th>IGST (₹)</th>
                              <th>CGST (₹)</th>
                              <th>SGST (₹)</th>
                              <th>CESS (₹)</th>
                              <th>Interest (₹) <a [ngClass]="{'disabled': !isUpdateBtnDisable }"  title="Interest Calculation" (click)="openIntCalPop(intCalPop)"><i class="fa fa-calculator fs14"></i></a></th>
                              <th>Penalty (₹)</th>
                              <th>Total (₹)</th>
                              <!-- <th>Remove</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container>
                              <tr *ngFor="let div of issuInvArr(); let i=index" [formGroup]="div"
                                (keydown.enter)="$event.preventDefault()">
                                <td class="w250">
                                  <!-- <input type="text" class="form-control" id="tax" placeholder="" formControlName="issue">
                             -->
                                  <select class="form-control form-select" formControlName="issue"
                                    [title]="div.value.issue ? div.value.issue:''"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }">
                                    <option [value]="null" disabled>-- Select --</option>
                                    <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                      <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                        {{item.name}}</option>
                                    </optgroup>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('issue')?.errors">
                                    <span class="text-danger" *ngIf="div.get('issue')?.errors?.required "> Required
                                    </span>
                                  </div>
                                </td>
                                <td><input type="text" class="form-control" id="tax" placeholder=""
                                    formControlName="keyword" maxlength="150"
                                    [title]="div.value.keyword ? div.value.keyword:''"></td>
                                <td>
                                  <select class="form-control form-select" id="status" formControlName="status" 
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('status')?.errors  }">
                                    <!-- <option [value]="null">-- Select --</option> -->
                                    <option [value]="null">Select</option>
                                    <ng-container *ngFor="let obj of issueConclusion">
                                      <option [value]="obj.value">{{obj.value}}</option>
                                    </ng-container>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('status')?.errors">
                                    <span class="text-danger" *ngIf="div.get('status')?.errors?.required "> Required
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div class="input-group">
                                  <input type="text" class="form-control" [title]="div.value.tag ? div.value.tag:''"
                                    *ngIf="isToggleSegmentDropdown2 && action==='update' || action==='view'" placeholder=""
                                    formControlName="tag" readonly>
                                  <button *ngIf="isToggleSegmentDropdown2 && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleSegmentDropdown('p2')">
                                    <i class="fa fa-pencil"></i>
                                  </button> 
                                </div>
                                  <select class="form-control form-select" id="" [title]="div.value.tag ? div.value.tag:''"
                                  *ngIf="!isToggleSegmentDropdown2 || action==='create'" formControlName="tag">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of tagDataList">
                                    <option [value]="obj">{{obj}}</option>
                                  </ng-container>
                                </select>
                                </td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="igst3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="cgst3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="sgst3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="cess3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="interest3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="penalty3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="total3" readonly maxlength="19" InrFormat
                                    (paste)="handlePaste($event)" (input)="issueValueChange()"></td>

                                <!-- <td><a class=" addExtLetter" (click)="openRmvIssuePop(removeIssuePopup, i)"><i class="fa fa-minus"></i></a></td> -->
                                <ng-template #removeIssuePopup let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p>Are you sure, you want to remove?</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="rmIssueInv(i)">Remove</button>
                                  </div>
                                </ng-template>
                              </tr>
                            </ng-container>
                            <ng-container>
                              <tr (keydown.enter)="$event.preventDefault()">
                                <th colspan="4">Total</th>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="igst3Total" placeholder="" formControlName="igst3Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="cgst3Total" placeholder="" formControlName="cgst3Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="sgst3Total" placeholder="" formControlName="sgst3Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="cess3Total" placeholder="" formControlName="cess3Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="interest3Total" placeholder="" formControlName="interest3Total"
                                    readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="penaltyTotal" placeholder="" formControlName="penalty3Total" readonly>
                                </td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="totOfTotal3" placeholder="" formControlName="totOfTotal3" readonly>
                                </td>

                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div data-toggle="collapse" data-target="#liability" class="expansion-div mb-15"
                (click)="liabilityPanelExpansion()"> Details of Liability Admitted and Paid <span
                  class="consultant-icons"> <i *ngIf="!liabilityExpansionPanel" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="liabilityExpansionPanel" class="fa fa-angle-up"
                    aria-hidden="true"></i></span> </div>
              <div id="liability" class="collapse">
                <div class=" row">
                  <div class="col-md-12">
                    <div class="search-table-outter wrapper p-15">
                      <table class="search-table inner">
                        <thead>
                          <tr class="bgGred">
                            <th class="col-3 w-40">Sr.No.</th>
                            <th class="col-3">Issue</th>
                            <th class="col-3">Tag</th>
                            <th class="col-3">Payment Date</th>
                            <th class="col-3">Form of Payment</th>
                            <th class="col-3">Reference / Challan No.</th>
                            <th class="col-3">Mode of Payment</th>
                            <th class="col- 3">IGST (₹)</th>
                            <th class="col- 3">CGST (₹)</th>
                            <th class="col- 3">SGST (₹)</th>
                            <th class="col- 3">CESS (₹)</th>
                            <th class="col- 3"> Interest (₹) </th>
                            <th class="col- 3"> Penalty (₹) </th>
                            <th class="col- 3"> Total (₹) </th>
                            <th>Accounting Reference</th>
                            <th>Copy of Form</th>
                            <th> Copy of Bank Challan </th>
                            <th class="ac-no-cls"> Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container>
                            <tr *ngFor="let detailsOfLiabilityGrp of getLiability(); let  i=index;"
                              [formGroup]=" detailsOfLiabilityGrp">
                              <td> {{i+1}} <span *ngIf="detailsOfLiabilityGrp?.value?.markedForPayment" title="Marked for Interest Calculation"><i class="fa fa-circle isMarked"></i></span></td>
                              <td>
                                <select class="form-control form-select" formControlName="issueName" [ngClass]="{ 'errorBorder': (detailsOfLiabilityGrp.value.total || detailsOfLiabilityGrp.value.challanNo) && !detailsOfLiabilityGrp.value.issueName  }" [title]="detailsOfLiabilityGrp.value.issueName ? detailsOfLiabilityGrp.value.issueName:''">
                                <option [value]="null" disabled>-- Select --</option>
                                <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                  <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                    {{item.name}}</option>
                                </optgroup>
                              </select>
                              <div *ngIf="(detailsOfLiabilityGrp.value.total || detailsOfLiabilityGrp.value.challanNo) && !detailsOfLiabilityGrp.value.issueName">
                                <span class="text-danger"> Required.</span>
                              </div>
                              </td>
                              <td>
                                <select class="form-control form-select" id="" [ngClass]="{ 'errorBorder': (detailsOfLiabilityGrp.value.total || detailsOfLiabilityGrp.value.challanNo) && !detailsOfLiabilityGrp.value.tag  }" formControlName="tag" [title]="detailsOfLiabilityGrp.value.tag ? detailsOfLiabilityGrp.value.tag:''">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of tagDataList">
                                  <option [value]="obj">{{obj}}</option>
                                </ng-container>
                              </select>
                              <div *ngIf="(detailsOfLiabilityGrp.value.total || detailsOfLiabilityGrp.value.challanNo) && !detailsOfLiabilityGrp.value.tag">
                                <span class="text-danger"> Required.</span>
                              </div>
                              </td>
                              <td>
                                <div id="iris-gst-filter-frmDate " class="input-group date datepicker tbldatePicker" [ngClass]="{ 'errorBorder': (detailsOfLiabilityGrp.value.total || detailsOfLiabilityGrp.value.challanNo) && !detailsOfLiabilityGrp.value.dateOfPayment  }">
                                  <p-calendar appendTo="body" class="form-control" formControlName="dateOfPayment"
                                    dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                    dateFormat="dd-mm-yy"></p-calendar>
                                </div>
                                <div *ngIf="(detailsOfLiabilityGrp.value.total || detailsOfLiabilityGrp.value.challanNo) && !detailsOfLiabilityGrp.value.dateOfPayment">
                                  <span class="text-danger"> Required.</span>
                                </div>
                              </td>
                              <td>
                                <select class="form-control form-select" id="authority" formControlName="formOfPayment">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of formPayment ">
                                    <option [value]="obj.value">{{obj.label}}</option>
                                  </ng-container>
                                </select>
                              </td>
                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                  formControlName="challanNo" [ngClass]="{ 'errorBorder': detailsOfLiabilityGrp.value.total && !detailsOfLiabilityGrp.value.challanNo  }"> 
                                  <div *ngIf="detailsOfLiabilityGrp.value.total && !detailsOfLiabilityGrp.value.challanNo">
                                    <span class="text-danger"> Required.</span>
                                  </div>
                                  <div *ngIf="detailsOfLiabilityGrp.value.challanNo && getLiabilityCopyFormLength(i) === 0">
                                    <span class="text-danger"> Please Upload  Copy of Form</span>
                                  </div>
                               
                              </td>
                              <td>
                                <select class="form-control form-select" id="authority" formControlName="modeOfPayment">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of modeOfPayment ">
                                    <option [value]="obj.value">{{obj.key}}</option>
                                  </ng-container>
                                </select>
                              </td>

                              <td><input type="text" class="form-control align-right" id="igst" placeholder=""
                                  (input)="liabilityTotalCalculation()" formControlName="igst" maxlength="19" InrFormat
                                  (paste)="handlePaste($event)"></td>

                             <td><input type="text" class="form-control align-right" id="cgst" placeholder=""
                                    (input)="liabilityTotalCalculation()" formControlName="cgst" maxlength="19" InrFormat
                                    (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" id="sgst" placeholder=""
                                      (input)="liabilityTotalCalculation()" formControlName="sgst" maxlength="19" InrFormat
                                      (paste)="handlePaste($event)"></td>
                               <td><input type="text" class="form-control align-right" id="cess" placeholder=""
                                     (input)="liabilityTotalCalculation()" formControlName="cess" maxlength="19" InrFormat
                                        (paste)="handlePaste($event)"></td>

                              <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                  (input)="liabilityTotalCalculation()" formControlName="interest" maxlength="19"
                                  InrFormat (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                  (input)="liabilityTotalCalculation()" formControlName="penalty" maxlength="19"
                                  InrFormat (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" id="tax" readonly placeholder=""
                                  (input)="liabilityTotalCalculation()" formControlName="total" maxlength="19"
                                  InrFormat></td>
                                  <td>
                                    <input type="text" class="form-control" formControlName="accountingRef"
                                    [ngClass]="{ 'errorBorder': detailsOfLiabilityGrp.value.accountingRef?.length > 100 }"
                                      [title]="detailsOfLiabilityGrp.value.accountingRef ? detailsOfLiabilityGrp.value.accountingRef:''">
                                    <div
                                      *ngIf="detailsOfLiabilityGrp.value.accountingRef && detailsOfLiabilityGrp.value.accountingRef.length > 100">
                                      <span class="text-danger">Max character Length 100</span>
                                    </div>
                                </td>
                              <td>
                                <div class="row">
                                  <div class="col-sm-9 nopadding">
                                    <span style="cursor: pointer;"
                                      (click)="openLiabilityCopyOfForm(liabilityCopyFormAck, i)"> <i
                                        class="icon fa fa fa-paperclip" title="Attach file"
                                        style="cursor: pointer;"></i></span>
                                    <span *ngIf="getLiabilityCopyFormLength(i) != 0"> {{getLiabilityCopyFormLength(i)}}
                                      Attachment</span>
                                    <ng-template #liabilityCopyFormAck let-modal>
                                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="col-md-12">
                                        <label> Attachment</label>
                                        <div class="table-responsive">
                                          <table class="table table-bordered tablepop">
                                            <thead>
                                              <tr class="bgGred">
                                                <th>Upload</th>
                                                <th style="width:50%;">Remark</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                *ngFor="let liabilityCopyFrm of getLiabilityCopyForm(detailsOfLiabilityGrp); let j = index"
                                                [formGroup]="liabilityCopyFrm">
                                                <td>
                                                  <div class="row">
                                                    <div class="col-md-9 nopadding">
                                                      <div class="form-group">
                                                        <input *ngIf="liabilityCopyFrm.value.isdocLocUploadedClicked"
                                                          type="file"
                                                          id="liabilityDetails({{i}}).copyOfFormLoc({{j}}).fileLoc"
                                                          class="filetype form-control" name="myfile"
                                                          (change)="onFileSelect($event)">
                                                        <p *ngIf="!liabilityCopyFrm.value.isdocLocUploadedClicked || liabilityCopyFrm.value.fileLoc"
                                                          class="form-control pt10">
                                                          {{getFileNameDta(liabilityCopyFrm.value.fileLoc)}}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 nopadding">
                                                      <label for="exampleInputPassword1">
                                                        <a class="downupIcon" *ngIf="liabilityCopyFrm.value.fileLoc"
                                                          title="Download file"
                                                          (click)="download(liabilityCopyFrm.value.fileLoc)"><i
                                                            class="fa fa-download"></i></a>
                                                        <a class="downupIcon"
                                                          *ngIf="!(liabilityCopyFrm.value.fileLoc || liabilityCopyFrm.value.fileLoc) && isDisableIcons"
                                                          title="Upload file" (click)="uploadLiabilityCopyForm(i,j)"><i
                                                            class="fa fa-upload"></i></a>
                                                        <a class="downupIcon" (click)="deleteLiabilityCopyForm(i,j)"
                                                          *ngIf="(liabilityCopyFrm.value.fileLoc)&& isDisableIcons"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type="text" class="form-control" formControlName="remarks"
                                                    [title]="liabilityCopyFrm.value.remarks ? liabilityCopyFrm.value.remarks:''">
                                                  <div
                                                    *ngIf="liabilityCopyFrm.value.remarks && liabilityCopyFrm.value.remarks.length > 100">
                                                    <span class="text-danger">Max character Length 100</span>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Save</button>
                                      </div>
                                    </ng-template>
                                    <div *ngIf="(detailsOfLiabilityGrp.get('challanNo')?.value ||  detailsOfLiabilityGrp.get('total')?.value) && getLiabilityCopyFormLength(i) === 0 ">
                                      <span class="text-danger"> Required.</span>
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div class="row">
                                  <div class="col-sm-9 nopadding">
                                    <span style="cursor: pointer;"
                                      (click)="openLiabilityBankChallan(liabilityBankchallan, i)"> <i
                                        class="icon fa fa fa-paperclip" title="Attach file"
                                        style="cursor: pointer;"></i></span>
                                    <span *ngIf="getLiabilityBankChallanLength(i) != 0">
                                      {{getLiabilityBankChallanLength(i)}} Attachment</span>

                                    <ng-template #liabilityBankchallan let-modal>
                                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="col-md-12">
                                        <label> Attachment</label>
                                        <div class="table-responsive">
                                          <table class="table table-bordered tablepop">
                                            <thead>
                                              <tr class="bgGred">
                                                <th>Upload</th>
                                                <th style="width:50%;">Remark</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr
                                                *ngFor="let liabilityBankChallanFrm of getLiabilityBankChallan(detailsOfLiabilityGrp); let j = index"
                                                [formGroup]="liabilityBankChallanFrm">
                                                <td>
                                                  <div class="row">
                                                    <div class="col-md-9 nopadding">
                                                      <div class="form-group">
                                                        <input
                                                          *ngIf="liabilityBankChallanFrm.value.isdocLocUploadedClicked"
                                                          type="file"
                                                          id="liabilityDetails({{i}}).copyOfBankChallanLoc({{j}}).fileLoc"
                                                          class="filetype form-control" name="myfile"
                                                          (change)="onFileSelect($event)">
                                                        <p *ngIf="!liabilityBankChallanFrm.value.isdocLocUploadedClicked || liabilityBankChallanFrm.value.fileLoc"
                                                          class="form-control pt10">
                                                          {{getFileNameDta(liabilityBankChallanFrm.value.fileLoc)}}
                                                        </p>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-3 nopadding">
                                                      <label for="exampleInputPassword1">
                                                        <a class="downupIcon"
                                                          *ngIf="liabilityBankChallanFrm.value.fileLoc"
                                                          title="Download file"
                                                          (click)="download(liabilityBankChallanFrm.value.fileLoc)"><i
                                                            class="fa fa-download"></i></a>
                                                        <a class="downupIcon"
                                                          *ngIf="!(liabilityBankChallanFrm.value.fileLoc || liabilityBankChallanFrm.value.fileLoc)"
                                                          title="Upload file"
                                                          (click)="uploadLiabilityBankChallanForm(i,j)"><i
                                                            class="fa fa-upload"></i></a>
                                                        <a class="downupIcon"
                                                          (click)="deleteLiabilityBankChallanForm(i,j)"
                                                          *ngIf="(liabilityBankChallanFrm.value.fileLoc) && isDisableIcons"><i
                                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                                      </label>
                                                    </div>
                                                  </div>
                                                </td>
                                                <td>
                                                  <input type="text" class="form-control" formControlName="remarks"
                                                    [title]="liabilityBankChallanFrm.value.remarks ? liabilityBankChallanFrm.value.remarks:''">
                                                  <div
                                                    *ngIf="liabilityBankChallanFrm.value.remarks && liabilityBankChallanFrm.value.remarks.length > 100">
                                                    <span class="text-danger">Max character Length 100</span>
                                                  </div>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="modal.close('Save click')">Save</button>
                                      </div>
                                    </ng-template>
                                  </div>
                                </div>
                              </td>

                              <td class="ac-no-cls">
                                <a class=" addExtLetter" (click)="addLiabilityInv()"><i class="fa fa-plus"
                                    title="Add row"></i></a>
                                <a *ngIf="i != 0" class=" addExtLetter"
                                  (click)="openRmvLiabilityPop(removeConsultantPopup, i)"><i
                                    class="fa fa-minus"></i></a>


                                <ng-template #removeConsultantPopup let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p>Are you sure, you want to remove?</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="rmLiabilityInv(i)">Remove</button>
                                  </div>
                                </ng-template>
                              </td>
                            </tr>
                          </ng-container>
                          <ng-container>
                            <tr>
                        
                              <th colspan="7" style="text-align: center;">Total</th>
                              <td class="align-right bold-font">
                                <!-- <input type="text" class="form-control" maxlength="19" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" id="taxgstTotal"
                               placeholder="" formControlName="taxTotal" readonly> -->
                                {{liabilityTotals.igstTotal | INR : 0}}
                              </td>
                              <td class="align-right bold-font ">
                                {{liabilityTotals.cgstTotal | INR :0 }}
                              </td>
                              <td class="align-right bold-font ">
                                {{liabilityTotals.sgstTotal | INR :0 }}
                              </td>
                              <td class="align-right bold-font ">
                                {{liabilityTotals.cessTotal | INR :0 }}
                              </td>
                              <td class="align-right bold-font">
                                <!-- <input type="text" class="form-control" maxlength="19" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" id="interestgstTotal"
                                 placeholder="" formControlName="interestTotal" readonly> -->
                                {{liabilityTotals.interestTotal| INR : 0 }}
                              </td>
                              <td class="align-right bold-font">
                                <!-- <input type="text" class="form-control" maxlength="19" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" id="penaltygstTotal"
                                 placeholder="" formControlName="penaltyTotal" readonly> -->
                                {{liabilityTotals.penaltyTotal | INR : 0}}
                              </td>
                              <td class="align-right bold-font">
                                <!-- <input type="text" class="form-control" maxlength="19" currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }" id="totOfgstTotal"
                                   placeholder="" formControlName="totalOfTotal" readonly> -->
                                {{liabilityTotals.allTotal | INR : 0}}
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Amount of demand confirmed </label>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tr>
                        <th>Particulars</th>
                        <th>Tax (<i class="fa fa-rupee"></i>)</th>
                        <th>Interest (<i class="fa fa-rupee"></i>)</th>
                        <th>Penalty (<i class="fa fa-rupee"></i>)</th>
                      </tr>
                      <tr>
                        <th>IGST</th>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                            formControlName="igstTax3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder': p1.igstTax3.errors }">
                          <div *ngIf="p1.igstTax3.errors">
                            <span class="text-danger" *ngIf="p1.igstTax3.errors.pattern"> Numberic Value allowed</span>
                          </div>
                        </td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="igstInterest3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder': p1.igstInterest3.errors }">
                        <div *ngIf="p1.igstInterest3.errors">
                          <span class="text-danger" *ngIf="p1.igstInterest3.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="igstPenalty3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder':p1.igstPenalty3.errors }">
                        <div *ngIf="p1.igstPenalty3.errors">
                          <span class="text-danger" *ngIf="p1.igstPenalty3.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                      </tr>
                      <tr>
                        <th>CGST</th>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                            formControlName="cgstTax3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder':p1.cgstTax3.errors }">
                          <div *ngIf="p1.cgstTax3.errors">
                            <span class="text-danger" *ngIf="p1.cgstTax3.errors.pattern"> Numberic Value allowed</span>
                          </div>
                        </td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="cgstInterest3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder': p1.cgstInterest3.errors }">
                        <div *ngIf="p1.cgstInterest3.errors">
                          <span class="text-danger" *ngIf="p1.cgstInterest3.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="cgstPenalty3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder':p1.cgstPenalty3.errors }">
                        <div *ngIf="p1.cgstPenalty3.errors">
                          <span class="text-danger" *ngIf="p1.cgstPenalty3.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                      </tr>
                      <tr>
                        <th>SGST/UTGST</th>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                            formControlName="sgstTax3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder':p1.sgstTax3.errors }">
                          <div *ngIf="p1.sgstTax3.errors">
                            <span class="text-danger" *ngIf="p1.sgstTax3.errors.pattern"> Numberic Value allowed</span>
                          </div>
                        </td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="sgstInterest3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder': p1.sgstInterest3.errors }">
                        <div *ngIf="p1.sgstInterest3.errors">
                          <span class="text-danger" *ngIf="p1.sgstInterest3.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="sgstPenalty3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder':p1.sgstPenalty3.errors }">
                        <div *ngIf="p1.sgstPenalty3.errors">
                          <span class="text-danger" *ngIf="p1.sgstPenalty3.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                      </tr>
                      <tr>
                        <th>Cess</th>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                            formControlName="cessTax3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder': p1.cessTax3.errors }">
                          <div *ngIf="p1.cessTax3.errors">
                            <span class="text-danger" *ngIf="p1.cessTax3.errors.pattern"> Numberic Value allowed</span>
                          </div>
                        </td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="cessInterest3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder': p1.cessInterest3.errors }">
                        <div *ngIf="p1.cessInterest3.errors">
                          <span class="text-danger" *ngIf="p1.cessInterest3.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="cessPenalty3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" [ngClass]="{ 'errorBorder': p1.cessPenalty3.errors }">
                        <div *ngIf="p1.cessPenalty3.errors">
                          <span class="text-danger" *ngIf="p1.cessPenalty3.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                      </tr>
                      <tr *ngIf="action == 'view' || action == 'update'">
                        <th>Total</th>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                            formControlName="totalTax3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" readonly></td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="totalInterest3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" readonly></td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="totalPenalty3" currencyMask [options]="{ prefix: ' ', thousands: ',', precision: 0 }" readonly></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div> -->

              <div data-toggle="collapse" data-target="#detailsOfAllPaymentMadeExpansion" class="expansion-div mb-15"
                (click)="detailsOfAllPaymentMadeExpansion = !detailsOfAllPaymentMadeExpansion">
                Details of All Payments Made (Cumulative) <span class="consultant-icons"> <span
                    (click)="refreshCumulativeTable($event)" class="refresh-icon-cls"><i class="fa fa-refresh me-1"
                      aria-hidden="true"></i><span class="refresh-text">Refresh Cumulative Count</span></span><i
                    *ngIf="!detailsOfAllPaymentMadeExpansion" class="fa fa-angle-down" aria-hidden="true"></i> <i
                    *ngIf="detailsOfAllPaymentMadeExpansion" class="fa fa-angle-up" aria-hidden="true"></i></span>
              </div>
              <div class="collapse" id="detailsOfAllPaymentMadeExpansion">
                <div class="row mb-15">
                  <div class="col-md-12">
                    <div class="search-table-outter wrapper">
                      <table class="search-table inner" style="width: 100%;">
                        <thead>
                          <tr class="bgGred">
                            <th>Label</th>
                            <th>IGST (₹)</th>
                            <th>CGST (₹)</th>
                            <th>SGST (₹)</th>
                            <th>CESS (₹)</th>
                            <th>Interest (₹)</th>
                            <th>Penalty (₹)</th>
                            <th>Total (₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container>
                            <tr *ngFor="let c of cumulativeCount">
                              <td>{{c.label}}</td>
                              <td class="align-right">{{c.igst | INR :0 }}</td>
                              <td class="align-right">{{c.cgst | INR :0 }}</td>
                              <td class="align-right">{{c.sgst | INR :0 }}</td>
                              <td class="align-right">{{c.cess | INR :0 }}</td>
                              <td class="align-right">{{ c.interest | INR :0 }}</td>
                              <td class="align-right">{{ c.penalty | INR :0 }}</td>
                              <td class="align-right">{{ c.total | INR :0 }}</td>
                            </tr>
                          </ng-container>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div data-toggle="collapse" data-target="#detailOfIssuingAuthorityExpansion1" class="expansion-div mb-15"
                (click)="detailOfIssuingAuthorityExpansion1 = !detailOfIssuingAuthorityExpansion1">
                Details of Issuing Authority
                <span class="consultant-icons"> <i *ngIf="!detailOfIssuingAuthorityExpansion1" class="fa fa-angle-down"
                    aria-hidden="true"></i> <i *ngIf="detailsOfAllPaymentMadeExpansion1" class="fa fa-angle-up"
                    aria-hidden="true"></i></span>
              </div>
              <div class="collapse" id="detailOfIssuingAuthorityExpansion1">
                <div class="row">
                  <!-- name of officer -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Name of the Officer</label>
                      <input type="text" class="form-control" id="officer" placeholder="" formControlName="officer3">
                    </div>
                  </div>
                    <!-- Designation -->
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="exampleInputPassword1">Designation </label>
                        <select class="form-control form-select" formControlName="designation3" (change)="onDesignationChange()">
                          <ng-container *ngFor="let obj of designation">
                            <option [value]="obj.value">{{obj.key}}</option>
                          </ng-container>
                        </select>
                      </div>
                    </div>
                  <!-- Jurisdiction -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Jurisdiction </label>
                      <input class="form-control" id="designation" formControlName="jurisdiction3" />
                    </div>
                  </div>
                  <!-- Address -->
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Address </label>
                      <input class="form-control" id="address" formControlName="address3" />
                    </div>
                  </div>


                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Contact No. </label>
                      <input class="form-control" id="contactNo" formControlName="contact3" maxlength="12"
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="exampleInputPassword1">E-Mail. </label>
                      <input class="form-control" id="email3" formControlName="email3" />
                    </div>
                    <div *ngIf="p1.email3.errors">
                      <span class="text-danger" *ngIf="p1.email3.errors.pattern"> Please Enter Valid Email ID</span>
                    </div>
                  </div>

                </div>
              </div>

              <div class="col-md-12">
                <button class="btn btn-outline-primary mr-1" (click)="updateLetter('p3')"
                  *ngIf="isUpdateBtn">Submit</button>
              </div>


            </div>
          </fieldset>
        </div>
      </div>
    </div>
  </form>
</div>
<!-- </div> -->
 
</div>

<ng-template #intCalPop let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Interest Calculation</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeIntCalPop()"></button>
  </div>
  <div class="modal-body">  
    <div class="clrOrange infoIcon" *ngIf="selectedTab === 'tab1'" >    
      <div>
          <ul>
              <li>Interest will be calculated based on period from and period to date. If order has been updated in Phase 3, in this case interest calculation will be done till the date of receipt of order.</li>
              <li>Interest will not be calculated if TAG is not assigned at the Issue level.</li>
              <li>For direct writ and memorandum cases (Department Appeal), interest will not be calculated at Phase 1. It will be calculated once the order is passed against writ/appeal.</li>
          </ul>       
      </div>       
    </div>
    <div class="clrOrange infoIcon" *ngIf="selectedTab === 'tab3'" >
      <div>
        <ul>
            <li>Interest will not be calculated if TAG is not assigned at the Issue level.</li>
            <li>Interst will not be calculated for Favorable and Partly Favorable issues.</li>
        </ul>      
      </div>       
    </div>
    <div class="switch-box" style="float: inline-end;">      
      <p class="btn btn-outline-success btn-download mr-3" (click)="doIntCalData()">Calculate Interest</p>
      <p class="btn btn-outline-success btn-download" (click)="saveIntCalData()">Save</p>
    </div>  
    <!-- <p class="pieChartLabel txtCenter">{{currentLabel | uppercase}}</p> -->
    <div class="autoScroll" style="min-height: 480px !important; width: 100%;">
      <div class="header-tbl-issue-wise-demand" style="padding: 8px; border: 1px solid #e3e7ed; background-color: #8cb4d5;">      
        <div class="text-center first-div">
            <span>Issue </span>           
        </div>
        <div class="text-center">
          <span>Tag </span>       
        </div>       
        <div class="text-center">
          <span>From Date </span>
        </div>
        <div class="text-center">
          <span>To Date </span>
        </div>
        <div class="text-center">
          <span>Interest Rate </span>
        </div>
        <div class="text-center">
          <span>Total Tax </span>
        </div>
        <div class="text-center">
          <span>Tax Value adjusted against this issue </span>
        </div>
        <div class="text-center">
          <span>Calculated Interest </span>
        </div>
        <div class="text-center">
          <span>User entered Interest </span>
        </div>
        <div class="text-center">
          <span>Total Interest </span>
        </div>
      </div>
      <div class="accordion " id="accordion" role="tablist">
        <ng-container *ngFor="let obj of intCalIssueData; let i=index">
          <div class="body-tbl-issue-wise-demand" role="tab" id="heading-{{i}}" 
          style="padding: 8px;border: 1px solid #e3e7ed; background-color: #eee;">
            <div class="first-div" style="width: 200px;">
              <span class="issueToggle">
                <span>{{obj.issue}}</span>
                <a class="collapsed" *ngIf="obj.paymentDetails?.length > 0" data-toggle="collapse" href="#collapse-{{i}}" aria-expanded="false"
                aria-controls="collapse-{{i}}"></a>
              </span>
            </div>
            <div class="" >{{obj.tag}}</div>
            <div class="form-group mb0">
              <div class=" input-group date datepicker">
                  <p-calendar [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2016:{{currentYear}}" class="form-control"
                      dateFormat="dd-mm-yy" [showIcon]="true"
                      [(ngModel)]="obj.periodFromDate"  [maxDate]="maxDate" 
                      dataType="string"></p-calendar>
              </div>
          </div>          
             <div class="form-group mb0">
              <div class=" input-group date datepicker">
                  <p-calendar [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2016:{{currentYear}}" class="form-control"
                      dateFormat="dd-mm-yy" [showIcon]="true"
                      [(ngModel)]="obj.periodToDate" [maxDate]="maxDate"
                      dataType="string"></p-calendar>
              </div>
          </div>
            <div class="textRight" >
              <select class="form-control form-select" [(ngModel)]="obj.interestRate">               
                <ng-container *ngFor="let obj of intRate">
                  <option [value]="obj.value">{{obj.label}}</option>
                 </ng-container>
              </select>
            </div>
            <div class="textRight" >{{obj.totalTax | INR : 0}}</div>
            <div class="textRight" >{{obj.taxAdjustedAgainstIssue | INR : 0}}</div>
            <div class="textRight" >{{obj.calculatedInterest | INR : 0}}</div>
            <div class="textRight" ><input type="text" class="form-control align-right" [(ngModel)]="obj.userEnteredInterest" InrFormat (paste)="handlePaste($event)"></div>
            <div class="textRight" >{{obj.interest | INR : 0}}</div>
          </div>

          <div id="collapse-{{i}}" class="collapse card-body" style="border: 1px solid #eee;background:#fff;"
              role="tabpanel" aria-labelledby="heading-{{i}}"
              data-parent="#accordion">
              <div class="row">
                <div class="col-md-12 nopadding mb10 popTbl table-responsive">
                  <table class="table table-bordered tbl-issue-wise-demand">
                    <thead>
                      <tr>                     
                      <th class="">Payment type</th>
                      <th class="">Forum</th>
                      <th class="">Payment Date </th>
                      <th class="">Form of Payment</th>
                      <th class="">Reference / Challan No.</th>
                      <th class="">Mode of Payment</th>
                      <th class="text-right">IGST (₹)</th>
                      <th class="text-right">CGST (₹)</th>
                      <th class="text-right">SGST (₹)</th>
                      <th class="text-right">CESS (₹)</th>
                      <th class="text-right">Total Tax</th>
                      <th class="text-right">Mark for Interest Calculation</th>
                    </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let item of obj.paymentDetails; let j = index">
                        <td class="">{{item.type === 'PROTEST_DETAILS' ? 'Protest' : item.type === 'ADMITTED_LIABILITY' ? 'Admitted Liability' :
                          item.type === 'PREDEPOSIT_DETAILS' ? 'Predeposit' :
                          item.type}}
                        </td>
                        <td class="">{{item.stage === 'aja' ? 'Adjudication' : item.stage === 'apa' ? 'Commissioner (A)' : item.stage === 'apt' ? 'Appellate Tribunal' :  item.stage === 'hc' ? 'High Court' : item.stage === 'sc' ? 'Supreme Court' : item.stage }}</td>
                        <td class="">{{item.dateOfPayment}}</td>                      
                        <td class="">{{item.formOfPayment}}</td>
                        <td class="">{{item.challanNo}}</td>
                        <td class="">{{item.modeOfPayment}}</td>
                        <td class="text-right">{{item.igst | INR : 0}}</td>
                        <td class="text-right">{{item.cgst | INR : 0}}</td>
                        <td class="text-right">{{item.sgst | INR : 0}}</td>
                        <td class="text-right">{{item.cess | INR : 0}}</td>
                        <td class="text-right">{{item.totalTax | INR : 0}}</td>
                        <td class="">
                          <select class="form-control form-select" [(ngModel)]="item.markedForPayment">
                           <ng-container *ngFor="let obj of yesNoList">
                              <option [value]="obj.value">{{obj.label}}</option>
                           </ng-container> 
                          </select>
                        </td>                        
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>

</ng-template>
