import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NoticeServiceService } from '../../services/notification.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

@Component({
  selector: 'app-gst-notice-detail',
  templateUrl: './gst-notice-detail.component.html',
  styleUrls: ['./gst-notice-detail.component.css']
})
export class GstNoticeDetailComponent implements OnInit {
  applnId: any;
  noticedetailData: any;
  subdetailData: any;
  isDataFound: boolean = false;
  isSubmissionDataFound: boolean = false;
  gstNoticeFile: any[] = [];
  fileUrl: any;
  dwdFileName: any;
  constructor(
    private router: Router,
    private noticeService: NoticeServiceService,
    private activeSnapShot: ActivatedRoute,
    private toaster: ToasterService,
  ) {
    this.applnId = this.activeSnapShot.snapshot.params.applnId;
  }

  ngOnInit(): void {
    this.getNoticeDetailData();
  }
  backtoOrder() {
    this.router.navigate(['/gstNoticetOrder']);
  }
  getNoticeDetailData() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    if (this.applnId != null) {
      let model = {
        companyId: localStorage.getItem('selectedId'),
        page: 0,
        size: 100,
        sortdir: "DESC",
        sortfield: "parsedDtOfIssue",
        module: "GST",
        newRecord: isNewCase === "Newnotice",
        criterias: [{
          "p": "applnId",
          "o": "eq",
          "v": this.applnId
        }],
      }
      this.noticeService.getNoticeData(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.noticedetailData = response.response.results;
        } else if (response.status === 0) {
          this.isDataFound = true;
        }
      });
    }
  }
  getSubmissionDetailData() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    if (this.applnId != null) {
      let model = {
        criterias: [{
          "p": "applnId",
          "o": "eq",
          "v": this.applnId
        }],
        companyId: localStorage.getItem('selectedId'),
        page: 0,
        size: 100,
        sortdir: "DESC",
        sortfield: "parsedDtOfIssue",
        module: "GST",
        newRecord: isNewCase === "Newnotice",
      }
      this.noticeService.getSubmissionData(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.subdetailData = response.response.results;
        } else if (response.status === 0) {
          this.isSubmissionDataFound = true;
        }
      });
    }
  }
  onClickFileName(gstNoticeFileData: any[] = []) {
    this.gstNoticeFile = [];
    this.gstNoticeFile = gstNoticeFileData;
    this.dwdFileName = gstNoticeFileData[0].fileName
  }
  download(key: any, id: any, fileName: string) {
    let urlData = {
      s3Key: key,
      id: id
    }
    this.noticeService.downloadNFile(urlData).subscribe((response: any) => {
      this.downloadcontFile(response, fileName);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadcontFile(data: any, fileName: any) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${fileName}`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }




}
