<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
        <div class="content-wrapper">
            <section>
                <div class="row">
                    <div class="col-md-12">
                        <ul id="tabsJustified" class="nav nav-tabs iris-gst-display-flex">
                            <li class="nav-item"><a data-target="#intCal" data-toggle="tab"
                                    class="nav-link active">Interest Calculation</a></li>

                        </ul>
                        <br>
                    </div>
                </div>
            </section>
            <div id="tabsJustifiedContent" class="tab-content nopadding">
                <div id="intCal" class="tab-pane fade card active show">
                    <div class="main-panel h380">
                        <div class="p20">
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="lbl">GST Interest Calculation</label>
                                </div>
                                <div class="col-md-4">
                                    <label>Do you want to activate interest calculation for GST Module?
                                        <a class="clrOrange infoIcon"
                                            title="This option is given to activate or deactivate the auto scheduler for interest calculation. Once it is activated system will automatically calculate the interest in issue table for GST cases. To get the correct interest value user must enter the complete details related to admitted Liability, Under Protest and pre-deposit payment."><i
                                                class="fa fa-info-circle" style="margin-left:3px;"></i></a>
                                    </label>
                                </div>
                                <div class="col-md-1">
                                    <label class="switch">
                                        <input type="checkbox" id="togBtn" [(ngModel)]="gstSelected"
                                            (click)="changeValue(gstSelected)">
                                        <div class="slider round"></div>
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <button type="submit" class="btn btn-primary mr-2 w130" [disabled]="isDisabled"
                                        (click)="submitIntCalOptOut()">Activate GST</button>
                                    <!-- <button class="btn btn-primary" (click)="toDashboard()">Back</button> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <label class="lbl">Pre-GST Interest Calculation</label>
                                </div>
                                <div class="col-md-4">
                                    <label>Do you want to activate interest calculation for Pre-GST Module?
                                        <a class="clrOrange infoIcon"
                                            title="This option is given to activate or deactivate the auto scheduler for interest calculation. Once it is activated system will automatically calculate the interest in issue table for GST cases. To get the correct interest value user must enter the complete details related to admitted Liability, Under Protest and pre-deposit payment."><i
                                                class="fa fa-info-circle" style="margin-left:3px;"></i></a>
                                    </label>
                                </div>
                                <div class="col-md-1">
                                    <label class="switch">
                                        <input type="checkbox" id="togBtn" [(ngModel)]="preGstSelected"
                                            (click)="changeValuePre(preGstSelected)">
                                        <div class="slider round"></div>
                                    </label>
                                </div>
                                <div class="col-md-4">
                                    <button type="submit" class="btn btn-primary mr-2 w130" [disabled]="isDisabledPre"
                                        (click)="submitIntCalOptOutPre()">Activate Pre-GST</button>
                                    <!-- <button class="btn btn-primary" (click)="toDashboard()">Back</button> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>
