import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, formatDate } from '@angular/common';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ADJ_APPELPRE, ADJ_ATTEND, ADJ_OUTCOME, ADJ_PH1_ADJUNOT, APA_PH1_PAY_MODE, Apa_PH1_PREDEPOAPP, APPEAL_AGAINST, ISSUE_CONCLUSION, ISSUE_CONCLUSION_A, ISSUE_RISK_PARAM, ISSUE_DIVISION, PREGST_ISSUE_DROPDOWN, PRE_TAB_TYPE, QUARTER, STATE_LIST, TYPE_OF_ACT, YES_NO, MODE_OF_RECEIPT, MODE_OF_PAYMENT, ADJ_WRITPRE, ADJ_DEPTPRE, ISSUE_CONCLUSION_COMMISSIONERS, COMMISSIONER_OUTCOME, MODE_OF_BGSTATUS, MODE_OF_PH, MODE_OF_TIME_PH } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { PreGstServiceService } from '../services/pre-gst-service.service';
import { BusinessService } from 'src/app/businessManagement/services/business.service';
import { LitigationServiceService } from 'src/app/litigation/services/litigation-service.service';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { InrService } from 'src/app/shared/services/inr.service';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { MasterService } from 'src/app/businessManagement/services/master.service';


export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  PAN = 'pan',
  ACT = 'Act',
  RGENO = 'RgeNo',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  PERSON_RESPONSIBLE = 'Person Responsible',
}

interface ConsultantList {
  srNo: number;
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}

@Component({
  selector: 'app-pre-sc',
  templateUrl: './pre-sc.component.html',
  styleUrls: ['./pre-sc.component.css']
})
export class PreSCComponent implements OnInit {
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveCaseId = new EventEmitter<boolean>();
  @Output() enablePrevStageTab: EventEmitter<string[] | null> = new EventEmitter<string[] | null>();
  parentCaseId: any;
  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  @Input() activeStages: string[] | null = null;
  preGstinDivList: any;
  preSCForm!: UntypedFormGroup;
  selectedTab: any;
  caseId: any;
  action: any;
  getAccessTabData: any;
  currentYear: any;
  navContext: any;
  selectedGstin: any;
  userRoleLs: any;
  consultantList: ConsultantList[]=[];
  isToggleConsultantDropdown = true;
  isToggleConsultantDropdown2 = true;
  isToggleConsultantDropdown3 = true;
  dataSource: any;
  selectedPhase: any;
  selectedFilling: any;
  selectedGstinDiv: any;
  selectedCompanyId: any;
  duplicateData:any;
  selectedRegNo:any;
  pan: any;
  companyId: any;
  selectedUserId: any;
  actTypes: any;
  caseType: any;
  caseData: any = {};
  savedPreCaseId: any;
  selectedActType: any;
  selectedState: any;
  regNumRes: any;
  actStateList: any;
  resStateName: any;
  selectedStateName: any;
  selectedStateCode: any;
  selectedField: any;
  selectedFile: any;
  fileUrl: any;
  filePath: any;
  matches: any;
  currentTab = 'presc';
  activeTab: any;
  someElement: any;
  rmvDetailsOfPaymentRef: any;
  rmvDetailsOfAppealRef: any;
  rmvConsultantModalRef: any;
  rmvLiabilityAddmittedAndPaidModalRef: any;
  rmvbankGuaranteeRef:any;
  rmvIssueModalRef!: NgbModalRef;
  rmvDetailModalRef!: NgbModalRef;
  issueReferenceRef!: NgbModalRef;
  appealDetailItemList: any = [];
  formData: FormData = new FormData();
  modeOfAppealOptions = MODE_OF_RECEIPT;
  bgStatusOptions = MODE_OF_BGSTATUS
  keepCaseInCallBookOptions = YES_NO;
  modeOfPH = MODE_OF_PH;
  modeOfTime =MODE_OF_TIME_PH;
  maxDate = new Date();

  protestDetailsExpanded: boolean = false;
  consultantPanleExpanded: boolean = false;
  detailsOfLiabilityAddmittedAndPaidExpanded: Boolean = false

  guaranteDetailsExpansion = false;
  isSubmitted: boolean = false;
  isPhase2: boolean = false;
  isPhase3: boolean = false;
  isDisableIcons: boolean = false;
  isMemorandum: boolean = false;
  disablField: boolean = false;
  disablBankChallan: boolean = false;
  isExcise: boolean = false;
  isServiceTax: boolean = false;
  isVat: boolean = false;
  isCst: boolean = false;
  isCustomsDuty: boolean = false;
  isEntryTax: boolean = false;
  isUpdate: boolean = false;
  isUpdateBtn = false;
  isCreateBtn: boolean = false;
  isDataSaved = false;
  disabledOnload: boolean = false;
  appealBookLocReq: boolean = false;
  isActdisable: boolean = false;
  disablePhase3 = false;
  isToggleDropdown=true;

  disabledWeatherAppl: boolean = false;
  disabledWeatherWrit: boolean = false;
  disabledWeatherDept: boolean = false;

  copyOfAckLocReq: boolean = false;
  supremeCourtOrderLocReq: boolean = false;

  isUPbankChallanLoc1: boolean = true;
  isDWbankChallanLoc1: boolean = true;
  isbankChallanLoc1File: boolean = false;
  isbankChallanLoc1Text: boolean = true;

  isUPappealBookLoc1: boolean = true;
  isDWappealBookLoc1: boolean = true;
  isappealBookLoc1File: boolean = false;
  isappealBookLoc1Text: boolean = true;

  isUPdrc03CopyLoc1: boolean = true;
  isDWdrc03CopyLoc1: boolean = true;
  isdrc03CopyLoc1File: boolean = false;
  isdrc03CopyLoc1Text: boolean = true;

  isUPcopyOfAckLoc: boolean = true;
  isDWcopyOfAckLoc: boolean = true;
  iscopyOfAckLocFile: boolean = false;
  iscopyOfAckLocText: boolean = true;

  isUPotherAttachmentsLoc1: boolean = true;
  isDWotherAttachmentsLoc1: boolean = true;
  isotherAttachmentsLoc1File: boolean = false;
  isotherAttachmentsLoc1Text: boolean = true;

  isUPotherAttachmentsLoc2: boolean = true;
  isDWotherAttachmentsLoc2: boolean = true;
  isotherAttachmentsLoc2File: boolean = false;
  isotherAttachmentsLoc2Text: boolean = true;

  isUPphCopyLoc: boolean = true;
  isDWphCopyLoc: boolean = true;
  isphCopyLocFile: boolean = false;
  isphCopyLocText: boolean = true;

  isUPadditionalSubmissionLoc: boolean = true;
  isDWadditionalSubmissionLoc: boolean = true;
  isadditionalSubmissionLocFile: boolean = false;
  isadditionalSubmissionLocText: boolean = true;

  isUPphRecordIfAnyLoc: boolean = true;
  isDWphRecordIfAnyLoc: boolean = true;
  isphRecordIfAnyLocFile: boolean = false;
  isphRecordIfAnyLocText: boolean = true;

  isUPsupremeCourtOrderLoc: boolean = true;
  isDWsupremeCourtOrderLoc: boolean = true;
  issupremeCourtOrderLocFile: boolean = false;
  issupremeCourtOrderLocText: boolean = true;

  isUPbankChallanLocLoc: boolean = true;
  isDWbankChallanLocLoc: boolean = true;
  isbankChallanLocFile: boolean = false;
  isbankChallanLocText: boolean = true;

  isUPdrc03CopyLoc: boolean = true;
  isDWdrc03CopyLoc: boolean = true;
  isdrc03CopyLocFile: boolean = false;
  isdrc03CopyLocText: boolean = true;

  finyearData: any[] = [];
  stateList: any = [];
  caseItemList: any = [];
  preDepoApplicableList: { label: string; value: boolean; }[];
  adjPHAdjuorNotList: { label: string; value: boolean; }[];
  division: { key: string; value: string; }[];
  // whetherAppealPreferred: { label: string; value: boolean; }[];
  adjAttend: { label: string; value: boolean; }[];
  paymentMode: { key: string; value: string; }[];
  riskParam: { key: string; value: string; }[];
  outcome: { key: string; value: string; }[];
  typeOfAct: { key: string; value: string; }[];
  issueConclusion: { key: string; value: string; }[];
  quarterList: { key: string; value: string; disabled: string; }[];
  yesNoList: { label: string; value: boolean; }[];
  appAgainst: { key: string; value: string; }[];
  tabType: { key: string; value: string; }[];
  detailsOfAllPaymentMadeExpansion = false;
  sorting: {
    caseId: boolean;
    state: boolean;
    panName: boolean;
    caseLabel: boolean;
    periodFromDate: boolean;
    periodToDate: boolean;
    personResponsible: boolean;
    actyType: boolean;
    regNo: boolean;
  } = {
      caseId: false,
      state: false,
      panName: false,
      caseLabel: false,
      periodFromDate: false,
      periodToDate: false,
      personResponsible: false,
      actyType: false,
      regNo: false,
    };
    issueSorting = SORT_ISSUE;

  cumulativeCount = [
    { label: "Cumulative Pre-Deposit", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative payment made under protest", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative payment made for admitted liability", tax: 0, interest: 0, penalty: 0, total: 0 },
    { label: "Cumulative Consultant Charges", tax: null, interest: null, penalty: null, total: 0 }
  ]

  selecteNewCaseDetails: any = null;

  modeOfPayment = MODE_OF_PAYMENT;
  protestInterestTotal = 0;
  protestTaxTotal = 0;
  protestPenaltyTotal = 0;
  protestTotal = 0;

  feesChargedTotal = 0;

  modeOfPaymentObjectLiabilityAdmitted = MODE_OF_PAYMENT;
  liabilityAddmittedAndPaidInterestTotal = 0;
  liabilityAddmittedAndPaidTaxTotal = 0;
  liabilityAddmittedAndPaidPenaltyTotal = 0;
  liabilityAddmittedAndPaidTotal = 0;

  isSupportingDocFile = false;
  isSupportingDocText = true;
  isSupportingDocDWText = true;
  isSupportingDocUPText = true;

  isSupportingFile = false;
  isSupportingText = true;
  isSupportingDWText = true;
  isSupportingUPText = true;

  copyOfAckLocDocFile = false;
  copyOfAckLocDocText = true;
  copyOfAckLocDWText = true;
  copyOfAckLocUPText = true;

  isOtherAttachmentFile = false;
  isOtherAttachmentText = true;
  isOtherAttachmentDWText = true;
  isOtherAttachmentUPText = true;

  isconsultantattachmentsLocText: boolean = true;
  isconsultantDWattachmentsLoc: boolean = true;
  isconsultantattachmentsLocFile: boolean = false;
  isconsultantUPattachmentsLoc: boolean = true;
  viewIons = false;
  hideData:boolean = false;
  oldProtestDetailsValues: any = [];
  oldLiabilityDetailValues: any = []
  oldPredepositeDetailsValues: any = []
  oldConsulatntValues: any = []
  prevStage: any
  issueData: any;
  roleName: any



  constructor(
    private activeSnapShot: ActivatedRoute,
    private preLitService: PreGstServiceService,
    private litService: LitigationServiceService,
    private bussinessService: BusinessService,
    private commonServ: CommonService,
    private fBuild: UntypedFormBuilder,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private router: Router,
    private datePipe: DatePipe,
    private toaster: ToasterService,
    private getterSetter: GetterSetterService,
    private inrService: InrService,
    private http: HttpClient,
    private masterService : MasterService
  ) {
    this.caseId = this.activeSnapShot.snapshot.params.caseId;
    this.action = this.activeSnapShot.snapshot.params.action;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.activeSnapShot.snapshot.params.memorandum == "true" ? this.caseType = 'MEMORANDUM' : null;


    this.preDepoApplicableList = Apa_PH1_PREDEPOAPP;
    this.paymentMode = APA_PH1_PAY_MODE;
    this.division = ISSUE_DIVISION;
    this.riskParam = ISSUE_RISK_PARAM;
    this.adjPHAdjuorNotList = ADJ_PH1_ADJUNOT;
    this.adjAttend = ADJ_ATTEND;
    this.outcome = COMMISSIONER_OUTCOME;
    // this.whetherAppealPreferred = ADJ_APPELPRE;
    this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS;
    this.quarterList = QUARTER;
    this.yesNoList = YES_NO;
    this.typeOfAct = TYPE_OF_ACT;
    this.appAgainst = APPEAL_AGAINST;
    this.tabType = PRE_TAB_TYPE;
  }

  ngOnInit() {
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.selectedGstinDiv = this.selectedFilling.gstinDiv;
    this.selectedCompanyId = this.getterSetter.getSetCompanyId();
    this.navContext = this.commonServ.getNavContext();
    this.selectedUserId = this.getterSetter.getSetUserId();
    this.pan = this.navContext.pan;
    window.scrollTo(0, 0);
    this.stateList = STATE_LIST;
    this.gstinDivData();
    this.actTypeFetch();
    this.preLitService.getAccessTabData().subscribe((val) => {
      if (val) {
        this.getAccessTabData = val;
      }
    });
    if (this.caseId || this.parentCaseId) {
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.initializeData();
    }
    this.selectedTab = 'tab1';
    this.selectedPhase = 'phase0';
    this.getYears();
    this.setDate();
    this.personResponsibleData();
    this.consultantData();
    this.issueDropdown();
    this.formInitialize();
    if (this.action === 'create') {
      this.createAdjDiv();
      this.addIssueInv();
      this.addDetailOfPayment();
      this.addDetailOfBankGuarantee();
      this.addDetailOfAppeal();
      this.addConsultantInv();
      this.addDetailsOfLiabilityAddmittedAndPaid();

      this.isCreateBtn = true;
      this.isDisableIcons = true;
      this.viewIons = false;


      this.isbankChallanLoc1File = true;
      this.isbankChallanLoc1Text = false;
      this.isUPbankChallanLoc1 = false;

      this.isappealBookLoc1File = true;
      this.isappealBookLoc1Text = false;
      this.isUPappealBookLoc1 = false;

      this.isdrc03CopyLoc1File = true;
      this.isbankChallanLoc1Text = false;
      this.isUPbankChallanLoc1 = false;

      this.iscopyOfAckLocFile = true;
      this.iscopyOfAckLocText = false;
      this.isUPcopyOfAckLoc = false;

      this.isotherAttachmentsLoc1File = true;
      this.isotherAttachmentsLoc1Text = false;
      this.isUPotherAttachmentsLoc1 = false;

      this.isotherAttachmentsLoc2File = true;
      this.isotherAttachmentsLoc2Text = false;
      this.isUPotherAttachmentsLoc2 = false;

      this.isphCopyLocFile = true;
      this.isphCopyLocText = false;
      this.isUPphCopyLoc = false;

      this.isadditionalSubmissionLocFile = true;
      this.isadditionalSubmissionLocText = false;
      this.isUPadditionalSubmissionLoc = false;

      this.isphRecordIfAnyLocFile = true;
      this.isphRecordIfAnyLocText = false;
      this.isUPphRecordIfAnyLoc = false;

      this.issupremeCourtOrderLocFile = true;
      this.issupremeCourtOrderLocText = false;
      this.isUPsupremeCourtOrderLoc = false;

      this.isbankChallanLocFile = true;
      this.isbankChallanLocText = false;
      this.isUPbankChallanLocLoc = false;

      this.isdrc03CopyLocFile = true;
      this.isdrc03CopyLocText = false;
      this.isUPdrc03CopyLoc = false;
    } else if (this.action === 'update') {
      this.isDisableIcons = true;
      this.isUpdateBtn = true;
      this.isDataSaved = true;
      this.disabledOnload = true;
      this.viewIons =true;


    } else if (this.action === 'view') {
      this.isUpdateBtn = true;
      this.isDataSaved = true;
      this.disabledOnload = true;
      this.isDisableIcons = false;
      this.viewIons =false;




      this.isPhase2 = true;
      this.isPhase3 = true;

      this.isUPbankChallanLoc1 = false;
      this.isDWbankChallanLoc1 = true;

      this.isUPappealBookLoc1 = false;
      this.isDWappealBookLoc1 = true;

      this.isUPdrc03CopyLoc1 = false;
      this.isDWdrc03CopyLoc1 = true;

      this.isUPcopyOfAckLoc = false;
      this.isDWcopyOfAckLoc = true;

      this.isUPotherAttachmentsLoc1 = false;
      this.isDWotherAttachmentsLoc1 = true;

      this.isUPotherAttachmentsLoc2 = false;
      this.isDWotherAttachmentsLoc2 = true;

      this.isUPphCopyLoc = false;
      this.isDWphCopyLoc = true;

      this.isUPadditionalSubmissionLoc = false;
      this.isDWadditionalSubmissionLoc = true;

      this.isUPphRecordIfAnyLoc = false;
      this.isDWphRecordIfAnyLoc = true;

      this.isUPsupremeCourtOrderLoc = false;
      this.isDWsupremeCourtOrderLoc = true;

      this.isUPbankChallanLocLoc = false;
      this.isDWbankChallanLocLoc = true;

      this.isUPdrc03CopyLoc = false;
      this.isDWdrc03CopyLoc = true;

    }
    if (window.location.pathname.includes(',caseTab:move')) {
      this.disabledOnload = true;
      this.isDisableIcons = true;
    }
    this.createTab();
    const userId: any = sessionStorage.getItem('user');
    this.preSCForm.controls.personResponsible1.patchValue(userId);

    if (localStorage.getItem('roleName') === 'NoRole') {
      this.roleName = localStorage.getItem('role');
      console.log('roleName 1', this.roleName)
    } else {
      this.roleName = localStorage.getItem('roleName');
      console.log('roleName 2', this.roleName)
    }
  }


  formInitialize() {
    this.preSCForm = this.fBuild.group({
      //phase1
      companyId: [this.selectedCompanyId],
      gstinDiv: [this.selectedGstinDiv],
      // pan:[this.pan],
      pan: [this.caseData?.pan ? this.caseData?.pan : this.pan],
      prevOrderLoc: [this.caseData?.prevOrderLoc],
      status: [this.caseData?.status],
      caseId: [this.caseData?.caseId],
      actType: [this.caseData?.actType],
      state: [this.resStateName, [Validators.required]],
      regNo: [this.caseData?.regNo, [Validators.required]],
      din1: [this.caseData?.din1],
      orderReceiptMode1: [this.caseData?.orderReceiptMode1],
      //appealAgainst:[this.caseData?.appealAgainst, [Validators.required]],
      periodFromDate: [this.caseData?.periodFromDate, [Validators.required]],
      periodToDate: [this.caseData?.periodToDate, [Validators.required]],
      orderNo1: [this.caseData?.orderNo1, [Validators.required]],
      dateOfOrder1: [this.caseData?.dateOfOrder1, [Validators.required]],
      dateOfReceiptOfOrder1: [this.caseData?.dateOfReceiptOfOrder1, [Validators.required]],
      dueDateToAppeal: [this.caseData?.dueDateToAppeal, [Validators.required]],
      internalDueDate: [this.caseData?.internalDueDate],
      appealBookLoc: new UntypedFormArray([]),
      bankGuarantee: new UntypedFormArray([]),
      originalCaseId: [this.caseData?.originalCaseId],
      otherAttachmentsLoc1: new UntypedFormArray([]),
      // cPreDepositPaid:[this.caseData?.cPreDepositPaid, [Validators.pattern(/^[0-9]{1,18}(\.\d{1,2})?$/)]],
      consultant1: [this.caseData?.consultant1],
      personResponsible1: [this.caseData?.personResponsible1, [Validators.required]],
      issues: new UntypedFormArray([]),
      protestDetails: new UntypedFormArray([]),
      caseSummary: [this.caseData?.caseSummary],
      portCode:[this.caseData?.portCode],
      portName:[this.caseData?.portName],
      internalRemarks: [this.caseData?.internalRemarks],
      caseLabel: [this.caseData?.caseLabel, [Validators.required, Validators.maxLength(100), Validators.minLength(5)]],
      exciseDuty1Total: [this.action == 'update' ? '0' : this.caseData?.exciseDuty1Total],
      ec1Total: [this.action == 'update' ? '0' : this.caseData?.ec1Total],
      shec1Total: [this.action == 'update' ? '0' : this.caseData?.shec1Total],
      serviceTax1Total: [this.action == 'update' ? '0' : this.caseData?.serviceTax1Total],
      kkc1Total: [this.action == 'update' ? '0' : this.caseData?.kkc1Total],
      sbc1Total: [this.action == 'update' ? '0' : this.caseData?.sbc1Total],
      totalservTax5: [this.action == 'update' ? '0' : this.caseData?.totalservTax5],
      vat1Total: [this.action == 'update' ? '0' : this.caseData?.vat1Total],
      cst1Total: [this.action == 'update' ? '0' : this.caseData?.cst1Total],
      bcd1Total: [this.action == 'update' ? '0' : this.caseData?.bcd1Total],
      sad1Total: [this.action == 'update' ? '0' : this.caseData?.sad1Total],
      antiDumpingDuty1Total: [this.action == 'update' ? '0' : this.caseData?.antiDumpingDuty1Total],
      igst1Total: [this.action == 'update' ? '0' : this.caseData?.igst1Total],
      ent1Total: [this.action == 'update' ? '0' : this.caseData?.ent1Total],
      interest1Total: [this.action == 'update' ? '0' : this.caseData?.interest1Total],
      penalty1Total: [this.action == 'update' ? '0' : this.caseData?.penalty1Total],
      totOfTotal1: [this.action == 'update' ? '0' : this.caseData?.totOfTotal1],
      //phase2
      // dateOfSubmission2:[this.caseData?.dateOfSubmission2],
      // consultant2:[this.caseData?.consultant2],
      // personResponsible2:[this.caseData?.personResponsible2],
      // copyOfAckLoc:[this.caseData?.copyOfAckLoc],
      // otherAttachmentsLoc2:[this.caseData?.otherAttachmentsLoc2],
      itemList: this.fBuild.array([]),
      submissions: new UntypedFormArray([]),
      consultantCharges: new UntypedFormArray([]),
      whetherCaseCallBook: [this.caseData?.whetherCaseCallBook],

      //phase3
      supremeCourtOrderNo: [this.caseData?.supremeCourtOrderNo],
      dateOfOrder3: [this.caseData?.dateOfOrder3],
      dateOfReceiptOfOrder3: [this.caseData?.dateOfReceiptOfOrder3],
      // supremeCourtOrderLoc:[this.caseData?.supremeCourtOrderLoc],
      bankChallanLoc: new UntypedFormArray([]),
      outcome: [this.caseData?.outcome],
      liabilityDetails: new UntypedFormArray([]),
      // bankChallanLoc:[this.caseData?.bankChallanLoc],
      // drc03CopyLoc:[this.caseData?.drc03CopyLoc],
      // drc03Tax:[this.caseData?.drc03Tax, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Interest:[this.caseData?.drc03Interest, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Penalty:[this.caseData?.drc03Penalty, [Validators.pattern(/^[1-9]\d*$/)]],
      orderSummary: [this.caseData?.orderSummary],
      din3: [this.caseData?.din3],
      orderReceiptMode3: [this.caseData?.orderReceiptMode3],


      //issue total
      exciseDuty3Total: [this.action == 'update' ? '0' : this.caseData?.exciseDuty3Total],
      ec3Total: [this.action == 'update' ? '0' : this.caseData?.ec3Total],
      shec3Total: [this.action == 'update' ? '0' : this.caseData?.shec3Total],
      serviceTax3Total: [this.action == 'update' ? '0' : this.caseData?.serviceTax3Total],
      kkc3Total: [this.action == 'update' ? '0' : this.caseData?.kkc3Total],
      sbc3Total: [this.action == 'update' ? '0' : this.caseData?.sbc3Total],
      vat3Total: [this.action == 'update' ? '0' : this.caseData?.vat3Total],
      cst3Total: [this.action == 'update' ? '0' : this.caseData?.cst3Total],
      bcd3Total: [this.action == 'update' ? '0' : this.caseData?.bcd3Total],
      sad3Total: [this.action == 'update' ? '0' : this.caseData?.sad3Total],
      antiDumpingDuty3Total: [this.action == 'update' ? '0' : this.caseData?.antiDumpingDuty3Total],
      igst3Total: [this.action == 'update' ? '0' : this.caseData?.igst3Total],
      ent3Total: [this.action == 'update' ? '0' : this.caseData?.ent3Total],
      interest3Total: [this.action == 'update' ? '0' : this.caseData?.interest3Total],
      penalty3Total: [this.action == 'update' ? '0' : this.caseData?.penalty3Total],
      totOfTotal3: [this.action == 'update' ? '0' : this.caseData?.exciseDuty1Total],
    });
    this.preSCForm.controls['protestDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldProtestDetailsValues = this.preSCForm.value['protestDetails'];
        this.setCummulativeCount(this.oldProtestDetailsValues, selectedValue, 'protestDetails');
      });

    this.preSCForm.controls['consultantCharges']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldConsulatntValues = this.preSCForm.value['consultantCharges'];
        this.setCummulativeCount(this.oldConsulatntValues, selectedValue, 'consultantCharges');
      });

    this.preSCForm.controls['liabilityDetails']
      .valueChanges
      .subscribe(selectedValue => {
        this.oldLiabilityDetailValues = this.preSCForm.value['liabilityDetails'];
        this.setCummulativeCount(this.oldLiabilityDetailValues, selectedValue, 'liabilityDetails');
      });
  }
  get formGrp() { return this.preSCForm.controls; }

  //phase 1 starts
  getappealBookLoc(): UntypedFormGroup[] {
    return (this.preSCForm.get('appealBookLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getotherAttachmentsLoc1(): UntypedFormGroup[] {
    return (this.preSCForm.get('otherAttachmentsLoc1') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getProtestSupportingDocLocAttachment(pssupportingDocLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (pssupportingDocLocGrp.get('supportingDocLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getAppealBookLocLength() {
    let count = 0;
    let predepositFrmArray = this.preSCForm.get('appealBookLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }

  getAttachmentsLocLength() {
    let count = 0;
    let predepositFrmArray = this.preSCForm.get('otherAttachmentsLoc1') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getProtestCopyLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.preSCForm.get('protestDetails') as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get('supportingDocLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getCopyOfAckLocLength(j?: any) {
    let count = 0;
    let submissionsFrmArray = this.preSCForm.get('submissions') as UntypedFormArray;
    let letterLocFrmArray = submissionsFrmArray.controls[j]?.get('copyOfAckLoc') as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getOtherAttachmentsLocLength(j?: any) {
    let count = 0;
    let submissionsFrmArray = this.preSCForm.get('submissions') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionsFrmArray.controls[j]?.get('otherAttachmentsLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhCopyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preSCForm.get('itemList') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = phCopyLocArray.controls[j]?.get('phCopyLoc') as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getAdditionalSubmissionLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preSCForm.get('itemList') as UntypedFormArray;
    let additionalSubmissionLocFrmArray = phCopyLocArray.controls[j]?.get('additionalSubmissionLoc') as UntypedFormArray;
    let lettercount = additionalSubmissionLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getPhRecordIfAnyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preSCForm.get('itemList') as UntypedFormArray;
    let phRecordIfAnyLocFrmArray = phCopyLocArray.controls[j]?.get('phRecordIfAnyLoc') as UntypedFormArray;
    let lettercount = phRecordIfAnyLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getphAdjournmentLetterLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preSCForm.get('itemList') as UntypedFormArray;
    let phAdjournmentLetterLocFrmArray = phCopyLocArray.controls[j]?.get('phAdjournmentLetterLoc') as UntypedFormArray;
    let lettercount = phAdjournmentLetterLocFrmArray.value.filter((x: any) => x.fileLoc != '');
    return lettercount.length;
  }
  getConsultOtherAttachmentLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.preSCForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('otherAttachmentLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }
  getbankChallanLocLength() {
    let count = 0;
    let predepositFrmArray = this.preSCForm.get('bankChallanLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getLiabilityCopyFormLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.preSCForm.get('liabilityDetails') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get('copyOfFormLoc') as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return lettercount.length;
  }

  createappealBookLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createotherAttachmentsLoc1(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }

  createProtestSupportingDoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  openappealBookLoc(content: any) {
    this.addappealBookLoc();
    this.modalService.open(content);
  }
  openotherAttachmentsLoc1(content: any) {
    this.addotherAttachmentsLoc1();
    this.modalService.open(content);
  }

  openProtestSupportingDocLoctAttach(content: any, i: any) {
    this.addProtestSupportingDoc(i, 0);
    this.modalService.open(content);
  }
  addappealBookLoc() {
    let appealBookLocCopyArray = this.preSCForm.get('appealBookLoc') as UntypedFormArray;
    let i = 0;

    if (appealBookLocCopyArray.length > 0) {
      let length = appealBookLocCopyArray.length;

      while (i < 5 - length) {
        appealBookLocCopyArray.push(this.createappealBookLoc());

        i++;
      }
    } else {
      while (i < 5) {
        appealBookLocCopyArray.push(this.createappealBookLoc());

        i++;
      }
    }

  }
  addotherAttachmentsLoc1() {
    let otherAttachmentsLoc1CopyArray = this.preSCForm.get('otherAttachmentsLoc1') as UntypedFormArray;
    let i = 0;

    if (otherAttachmentsLoc1CopyArray.length > 0) {
      let length = otherAttachmentsLoc1CopyArray.length;

      while (i < 5 - length) {
        otherAttachmentsLoc1CopyArray.push(this.createotherAttachmentsLoc1());

        i++;
      }
    } else {
      while (i < 5) {
        otherAttachmentsLoc1CopyArray.push(this.createotherAttachmentsLoc1());

        i++;
      }
    }

  }

  addProtestSupportingDoc(j: any, i: any) {
    let protestDetailsFrmArray = this.preSCForm.get('protestDetails') as UntypedFormArray;
    let supportingDocLocFrmArray = protestDetailsFrmArray.controls[j].get('supportingDocLoc') as UntypedFormArray;

    if (supportingDocLocFrmArray.length > 0) {
      let length = supportingDocLocFrmArray.length
      while (i < 5 - length) {
        supportingDocLocFrmArray.push(this.createProtestSupportingDoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        supportingDocLocFrmArray.push(this.createProtestSupportingDoc());
        i++;
      }
    }
  }
  uploadappealBookLoc(appealBookLocIndex: any) {
    let appealBookLocArray = this.preSCForm.controls.appealBookLoc as UntypedFormArray;
    if (appealBookLocArray.controls.length > 0) {
      for (let index = 0; index < appealBookLocArray.controls.length; index++) {
        if (index === appealBookLocIndex) {
          let itemListuploadControl = appealBookLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteappealBookLoc(appealBookLocIndex: any) {
    let appealBookLocArray = this.preSCForm.controls.appealBookLoc as UntypedFormArray;
    if (appealBookLocArray.controls.length > 0) {
      for (let index = 0; index < appealBookLocArray.controls.length; index++) {
        if (index === appealBookLocIndex) {
          let itemListuploadControl = appealBookLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadotherAttachmentsLoc1F(otherAttachmentsLoc1Index: any) {
    let otherAttachmentsLoc1Array = this.preSCForm.controls.otherAttachmentsLoc1 as UntypedFormArray;
    if (otherAttachmentsLoc1Array.controls.length > 0) {
      for (let index = 0; index < otherAttachmentsLoc1Array.controls.length; index++) {
        if (index === otherAttachmentsLoc1Index) {
          let itemListuploadControl = otherAttachmentsLoc1Array.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteotherAttachmentsLoc1F(otherAttachmentsLoc1Index: any) {
    let otherAttachmentsLoc1Array = this.preSCForm.controls.otherAttachmentsLoc1 as UntypedFormArray;
    if (otherAttachmentsLoc1Array.controls.length > 0) {
      for (let index = 0; index < otherAttachmentsLoc1Array.controls.length; index++) {
        if (index === otherAttachmentsLoc1Index) {
          let itemListuploadControl = otherAttachmentsLoc1Array.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadProtestSupportingDoc(protestDetailsListIndex: any, supportingDocLocIndex: any) {
    let protestDetailsFrmArray = this.preSCForm.get('protestDetails') as UntypedFormArray;
    let supportingDocLocFrmArray = protestDetailsFrmArray.controls[protestDetailsListIndex].get('supportingDocLoc') as UntypedFormArray;

    if (supportingDocLocFrmArray.controls.length > 0) {
      for (let index = 0; index < supportingDocLocFrmArray.controls.length; index++) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = supportingDocLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteProtestSupportingDoc(protestDetailsListIndex: any, supportingDocLocIndex: any) {
    let protestDetailsFrmArray = this.preSCForm.get('protestDetails') as UntypedFormArray;
    let supportingDocLocFrmArray = protestDetailsFrmArray.controls[protestDetailsListIndex].get('supportingDocLoc') as UntypedFormArray;

    if (supportingDocLocFrmArray.controls.length > 0) {
      for (let index = 0; index < supportingDocLocFrmArray.controls.length; index++) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = supportingDocLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  //phase 1 ends
  //phase 2 start
  getcopyOfAckLocAttachment(copyOfAckLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfAckLocGrp.get('copyOfAckLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getOtherAttachments(otherAttachmentsLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (otherAttachmentsLocGrp.get('otherAttachmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  getphCopyLoc(phCopyLocFrmGrp: any): UntypedFormGroup[] {
    return (phCopyLocFrmGrp.get('phCopyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAdditionalSub(addSubLocFrmGrp: any): UntypedFormGroup[] {
    return (addSubLocFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getPhRecord(phRecordFrmGrp: any): UntypedFormGroup[] {
    return (phRecordFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getAdjournmentLetter(phAdjournmentLetterFrmGrp: any): UntypedFormGroup[] {
    return (phAdjournmentLetterFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getConsultOtherAttachment(consultAttachGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (consultAttachGrp.get('otherAttachmentLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createCopyOfAckLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createOtherAttachmentsLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createphCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createAdditionalSubLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhRecord(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhAdjournmentLetterLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createConsultotherAttachmentLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  openCopyOfAckLoc(content: any, i: any) {
    this.addCopyOfAckLoc(i, 0);
    this.modalService.open(content);
  }
  openOtherAttachmentsLoc(content: any, i: any) {
    this.addotherAttachmentsLoc(i, 0);
    this.modalService.open(content);
  }
  openphCopyLoc(content: any, i: any) {
    this.addphCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openAdditionSub(content: any, i: any) {
    this.addAdditionalCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openPhRecord(content: any, i: any) {
    this.addPhRecord(i, 0);
    this.modalService.open(content);
  }
  openAdjournmentLetter(content: any, i: any) {
    this.addAdjournmentLetter(i, 0);
    this.modalService.open(content);
  }
  openConsultotherAttachmentLoc(content: any, i: any) {
    this.addConsultotherAttachmentLoc(i, 0);
    this.modalService.open(content);
  }
  addCopyOfAckLoc(j: any, i: any) {
    let submissionFrmArray = this.preSCForm.get('submissions') as UntypedFormArray;
    let copyOfAckLocFrmArray = submissionFrmArray.controls[j].get('copyOfAckLoc') as UntypedFormArray;

    if (copyOfAckLocFrmArray.length > 0) {
      let length = copyOfAckLocFrmArray.length
      while (i < 5 - length) {
        copyOfAckLocFrmArray.push(this.createCopyOfAckLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyOfAckLocFrmArray.push(this.createCopyOfAckLoc());
        i++;
      }
    }
  }
  addotherAttachmentsLoc(j: any, i: any) {
    let submissionFrmArray = this.preSCForm.get('submissions') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionFrmArray.controls[j].get('otherAttachmentsLoc') as UntypedFormArray;

    if (otherAttachmentsLocFrmArray.length > 0) {
      let length = otherAttachmentsLocFrmArray.length
      while (i < 5 - length) {
        otherAttachmentsLocFrmArray.push(this.createOtherAttachmentsLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        otherAttachmentsLocFrmArray.push(this.createOtherAttachmentsLoc());
        i++;
      }
    }
  }
  addphCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.preSCForm.get('itemList') as UntypedFormArray;
    let phCopyLocFrmArray = dtCaseFrmArray.controls[j].get('phCopyLoc') as UntypedFormArray;

    if (phCopyLocFrmArray.length > 0) {
      let length = phCopyLocFrmArray.length
      while (i < 5 - length) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
  }
  addAdditionalCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.preSCForm.get('itemList') as UntypedFormArray;
    let additionalSubLocFrmArray = dtCaseFrmArray.controls[j].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.length > 0) {
      let length = additionalSubLocFrmArray.length
      while (i < 5 - length) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
  }
  addAdjournmentLetter(j: any, i: any) {
    let dtCaseFrmArray = this.preSCForm.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
  }
  addPhRecord(j: any, i: any) {
    let dtCaseFrmArray = this.preSCForm.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
  }
  addConsultotherAttachmentLoc(j: any, i: any) {
    let consultOtherAttachFrmArray = this.preSCForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultOtherAttachFrmArray.controls[j].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.length > 0) {
      let length = otherAttachmentLocFrmArray.length
      while (i < 5 - length) {
        otherAttachmentLocFrmArray.push(this.createConsultotherAttachmentLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        otherAttachmentLocFrmArray.push(this.createConsultotherAttachmentLoc());
        i++;
      }
    }
  }
  uploadCopyOfAckLocf(submissionListIndex: any, copyOfAckLocIndex: any) {
    let submissionFrmArray = this.preSCForm.get('submissions') as UntypedFormArray;
    let copyOfAckLocFrmArray = submissionFrmArray.controls[submissionListIndex].get('copyOfAckLoc') as UntypedFormArray;

    if (copyOfAckLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyOfAckLocFrmArray.controls.length; index++) {
        if (index === copyOfAckLocIndex) {
          let itemListuploadControl = copyOfAckLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteCopyOfAckLoc(submissionListIndex: any, copyOfAckLocIndex: any) {
    let submissionFrmArray = this.preSCForm.get('submissions') as UntypedFormArray;
    let copyOfAckLocFrmArray = submissionFrmArray.controls[submissionListIndex].get('copyOfAckLoc') as UntypedFormArray;

    if (copyOfAckLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyOfAckLocFrmArray.controls.length; index++) {
        if (index === copyOfAckLocIndex) {
          let itemListuploadControl = copyOfAckLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadOtherAttachmentsLoc(submissionListIndex: any, otherAttachmentsLocIndex: any) {
    let submissionFrmArray = this.preSCForm.get('submissions') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionFrmArray.controls[submissionListIndex].get('otherAttachmentsLoc') as UntypedFormArray;

    if (otherAttachmentsLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachmentsLocFrmArray.controls.length; index++) {
        if (index === otherAttachmentsLocIndex) {
          let itemListuploadControl = otherAttachmentsLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteOtherAttachmentsLoc(submissionListIndex: any, otherAttachmentsLocIndex: any) {
    let submissionFrmArray = this.preSCForm.get('submissions') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionFrmArray.controls[submissionListIndex].get('otherAttachmentsLoc') as UntypedFormArray;

    if (otherAttachmentsLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachmentsLocFrmArray.controls.length; index++) {
        if (index === otherAttachmentsLocIndex) {
          let itemListuploadControl = otherAttachmentsLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadPhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;

    let itemListsArray = this.preSCForm.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    let itemListsArray = this.preSCForm.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get('phCopyLoc') as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.preSCForm.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.preSCForm.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[addiSubListIndex].get('additionalSubmissionLoc') as UntypedFormArray;
    if (additionalSubLocFrmArray.controls.length > 0) {
      for (let index = 0; index < additionalSubLocFrmArray.controls.length; index++) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.preSCForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.preSCForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadPhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.preSCForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.preSCForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phRecordIfAnyLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadConsulOthertAttach(consultOtherAttachListIndex: any, supportingDocLocIndex: any) {
    let consultOtherAttachFrmArray = this.preSCForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultOtherAttachFrmArray.controls[consultOtherAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachmentLocFrmArray.controls.length; index++) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = otherAttachmentLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteConsulOthertAttach(consultOtherAttachListIndex: any, supportingDocLocIndex: any) {
    let consultOtherAttachFrmArray = this.preSCForm.get('consultantCharges') as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultOtherAttachFrmArray.controls[consultOtherAttachListIndex].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.controls.length > 0) {
      for (let index = 0; index < otherAttachmentLocFrmArray.controls.length; index++) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = otherAttachmentLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  //phase 2 end

  //phase 3 Starts
  getbankChallanLoc(): UntypedFormGroup[] {
    return (this.preSCForm.get('bankChallanLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getLiabilitySupportingDocLocAttachment(copyOfFormLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfFormLocGrp.get('copyOfFormLoc') as UntypedFormArray).controls as UntypedFormGroup[];

  }
  createbankChallanLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createLiabilitySupportingDocLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  openbankChallanLocModel(content: any) {
    this.addbankChallanLoc();
    this.modalService.open(content);
  }
  openLiabilitySupportingDocLocAttachment(content: any, i: any) {
    this.addLiabilitySupportingDoc(i, 0);
    this.modalService.open(content);
  }
  addbankChallanLoc() {
    let bankChallanLocArray = this.preSCForm.get('bankChallanLoc') as UntypedFormArray;
    let i = 0;

    if (bankChallanLocArray.length > 0) {
      let length = bankChallanLocArray.length;

      while (i < 5 - length) {
        bankChallanLocArray.push(this.createbankChallanLoc());

        i++;
      }
    } else {
      while (i < 5) {
        bankChallanLocArray.push(this.createbankChallanLoc());

        i++;
      }
    }

  }
  addLiabilitySupportingDoc(j: any, i: any) {
    let liabilityDetailsFrmArray = this.preSCForm.get('liabilityDetails') as UntypedFormArray;
    let copyOfFormLocFrmArray = liabilityDetailsFrmArray.controls[j].get('copyOfFormLoc') as UntypedFormArray;

    if (copyOfFormLocFrmArray.length > 0) {
      let length = copyOfFormLocFrmArray.length
      while (i < 5 - length) {
        copyOfFormLocFrmArray.push(this.createLiabilitySupportingDocLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        copyOfFormLocFrmArray.push(this.createLiabilitySupportingDocLoc());
        i++;
      }
    }
  }
  uploadbankChallanLocF(bankChallanLocIndex: any) {
    let bankChallanLocCopyArray = this.preSCForm.controls.bankChallanLoc as UntypedFormArray;
    if (bankChallanLocCopyArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocCopyArray.controls.length; index++) {
        if (index === bankChallanLocIndex) {
          let itemListuploadControl = bankChallanLocCopyArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletebankChallanLocF(bankChallanLocIndex: any) {
    let bankChallanLocCopyArray = this.preSCForm.controls.bankChallanLoc as UntypedFormArray;
    if (bankChallanLocCopyArray.controls.length > 0) {
      for (let index = 0; index < bankChallanLocCopyArray.controls.length; index++) {
        if (index === bankChallanLocIndex) {
          let itemListuploadControl = bankChallanLocCopyArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadLiabilitySupportingDoc(liabilityDetailsListIndex: any, copyOfFormLocIndex: any) {
    let liabilityDetailsFrmArray = this.preSCForm.get('liabilityDetails') as UntypedFormArray;
    let copyOfFormLocFrmArray = liabilityDetailsFrmArray.controls[liabilityDetailsListIndex].get('copyOfFormLoc') as UntypedFormArray;

    if (copyOfFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyOfFormLocFrmArray.controls.length; index++) {
        if (index === copyOfFormLocIndex) {
          let itemListuploadControl = copyOfFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLiabilitySupportingDoc(liabilityDetailsListIndex: any, copyOfFormLocIndex: any) {
    let liabilityDetailsFrmArray = this.preSCForm.get('liabilityDetails') as UntypedFormArray;
    let copyOfFormLocFrmArray = liabilityDetailsFrmArray.controls[liabilityDetailsListIndex].get('copyOfFormLoc') as UntypedFormArray;

    if (copyOfFormLocFrmArray.controls.length > 0) {
      for (let index = 0; index < copyOfFormLocFrmArray.controls.length; index++) {
        if (index === copyOfFormLocIndex) {
          let itemListuploadControl = copyOfFormLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  //Phase 3 ends


  p2validation() {
    //phase2

    var appealDetailsArr = this.preSCForm.controls.submissions as UntypedFormArray;
    if (appealDetailsArr.controls.length > 0) {
      for (var i = 0; i < appealDetailsArr.controls.length; i++) {
        var issues1Control = appealDetailsArr.controls[i] as UntypedFormGroup;
        if (issues1Control.controls.refNo.value === null || issues1Control.controls.refNo.value === '') {
          issues1Control.controls.refNo.setErrors({ 'required': true });
        } else {
          issues1Control.controls.refNo.setErrors(null);
        }
        if (issues1Control.controls.submissionDate.value === null || issues1Control.controls.submissionDate.value === '') {
          issues1Control.controls.submissionDate.setErrors({ 'required': true });
        } else {
          issues1Control.controls.submissionDate.setErrors(null);
        }
      }
    }
    return true;

  }
  p3validation() {
    var issues1Array = this.preSCForm.controls.issues as UntypedFormArray
    if (issues1Array.controls.length > 0) {
      for (var i = 0; i < issues1Array.controls.length; i++) {
        var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        if (issues1Control.controls.status.value === null || issues1Control.controls.status.value === ''
          || issues1Control.controls.status.value === "null"
        ) {
          issues1Control.controls.status.setErrors({ 'required': true });
        } else {
          issues1Control.controls.status.setErrors(null);
        }
      }
    }
    if (this.preSCForm.value['supremeCourtOrderNo'] === null) {
      this.preSCForm.controls['supremeCourtOrderNo'].setErrors({ 'required': true });
    }
    if (this.preSCForm.value['dateOfOrder3'] === null) {
      this.preSCForm.controls['dateOfOrder3'].setErrors({ 'required': true });
    }
    if (this.preSCForm.value['dateOfReceiptOfOrder3'] === null) {
      this.preSCForm.controls['dateOfReceiptOfOrder3'].setErrors({ 'required': true });
    }
    if (this.preSCForm.value['outcome'] === null) {
      this.preSCForm.controls['outcome'].setErrors({ 'required': true });
    }

    return true;
  }
  /*Issue array */

  issuInvArr(): UntypedFormGroup[] {
    return (this.preSCForm.get('issues') as UntypedFormArray).controls as UntypedFormGroup[];
  }


  newIssueInvArray(): UntypedFormGroup {
    return this.fBuild.group({
      issue: [null, [Validators.required]],
      div: [null],
      keyword: [null],
      riskParam: [null, [Validators.required]],
      remark1: [null, [Validators.maxLength(100)]],
      exciseDuty1: [this.action == 'update' ? '0' : this.caseData?.exciseDuty1, [Validators.pattern(/^-?[0-9,]+$/)]],
      ec1: [this.action == 'update' ? '0' : this.caseData?.ec1, [Validators.pattern(/^-?[0-9,]+$/)]],
      shec1: [this.action == 'update' ? '0' : this.caseData?.shec1, [Validators.pattern(/^-?[0-9,]+$/)]],
      serviceTax1: [this.action == 'update' ? '0' : this.caseData?.serviceTax1, [Validators.pattern(/^-?[0-9,]+$/)]],
      kkc1: [this.action == 'update' ? '0' : this.caseData?.kkc1, [Validators.pattern(/^-?[0-9,]+$/)]],
      sbc1: [this.action == 'update' ? '0' : this.caseData?.sbc1, [Validators.pattern(/^-?[0-9,]+$/)]],
      vat1: [this.action == 'update' ? '0' : this.caseData?.vat1, [Validators.pattern(/^-?[0-9,]+$/)]],
      cst1: [this.action == 'update' ? '0' : this.caseData?.cst1, [Validators.pattern(/^-?[0-9,]+$/)]],
      bcd1: [this.action == 'update' ? '0' : this.caseData?.bcd1, [Validators.pattern(/^-?[0-9,]+$/)]],
      sad1: [this.action == 'update' ? '0' : this.caseData?.sad1, [Validators.pattern(/^-?[0-9,]+$/)]],
      antiDumpingDuty1: [this.action == 'update' ? '0' : this.caseData?.antiDumpingDuty1, [Validators.pattern(/^-?[0-9,]+$/)]],
      igst1: [this.action == 'update' ? '0' : this.caseData?.igst1, [Validators.pattern(/^-?[0-9,]+$/)]],
      ent1: [this.action == 'update' ? '0' : this.caseData?.ent1, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest1: [this.action == 'update' ? '0' : this.caseData?.interest1, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty1: [this.action == 'update' ? '0' : this.caseData?.penalty1, [Validators.pattern(/^-?[0-9,]+$/)]],
      total1: [this.action == 'update' ? '0' : this.caseData?.total1],
      exciseDuty3: [this.action == 'update' ? '0' : this.caseData?.exciseDuty3, [Validators.pattern(/^-?[0-9,]+$/)]],
      ec3: [this.action == 'update' ? '0' : this.caseData?.ec3, [Validators.pattern(/^-?[0-9,]+$/)]],
      shec3: [this.action == 'update' ? '0' : this.caseData?.shec3, [Validators.pattern(/^-?[0-9,]+$/)]],
      serviceTax3: [this.action == 'update' ? '0' : this.caseData?.serviceTax3, [Validators.pattern(/^-?[0-9,]+$/)]],
      kkc3: [this.action == 'update' ? '0' : this.caseData?.kkc3, [Validators.pattern(/^-?[0-9,]+$/)]],
      sbc3: [this.action == 'update' ? '0' : this.caseData?.sbc3, [Validators.pattern(/^-?[0-9,]+$/)]],
      vat3: [this.action == 'update' ? '0' : this.caseData?.vat3, [Validators.pattern(/^-?[0-9,]+$/)]],
      cst3: [this.action == 'update' ? '0' : this.caseData?.cst3, [Validators.pattern(/^-?[0-9,]+$/)]],
      bcd3: [this.action == 'update' ? '0' : this.caseData?.bcd3, [Validators.pattern(/^-?[0-9,]+$/)]],
      sad3: [this.action == 'update' ? '0' : this.caseData?.sad3, [Validators.pattern(/^-?[0-9,]+$/)]],
      antiDumpingDuty3: [this.action == 'update' ? '0' : this.caseData?.antiDumpingDuty3, [Validators.pattern(/^-?[0-9,]+$/)]],
      igst3: [this.action == 'update' ? '0' : this.caseData?.igst3, [Validators.pattern(/^-?[0-9,]+$/)]],
      ent3: [this.action == 'update' ? '0' : this.caseData?.ent3, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest3: [this.action == 'update' ? '0' : this.caseData?.interest3, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty3: [this.action == 'update' ? '0' : this.caseData?.penalty3, [Validators.pattern(/^-?[0-9,]+$/)]],
      total3: [this.action == 'update' ? '0' : this.caseData?.total3],
      status: [null],
      details: new UntypedFormArray([]),

    })
  }

  createTab() {
    if (this.caseId != ' ' && this.caseId != undefined) {
      this.isCreateBtn = false;
      this.isUpdateBtn = true;
    } else {
      this.isCreateBtn = true;
    }
  }
  addIssueInv() {
    let issueFrmGrp = this.newIssueInvArray();
    (issueFrmGrp.get('details') as UntypedFormArray).push(this.newDetailsFrmGrp());
    (this.preSCForm.get('issues') as UntypedFormArray).push(issueFrmGrp);

  }
  rmIssueInv(i: number) {
    (this.preSCForm.get('issues') as UntypedFormArray).removeAt(i);
    this.rmvIssueModalRef.close();
    this.issueValueChange();
  }
  /*Issue array */
  // details array
  getdetails(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newDetailsFrmGrp() {
    return new UntypedFormGroup({
      finYear: new UntypedFormControl(null),
      quarter: new UntypedFormControl(null),
      provisionAmt1: new UntypedFormControl(null),
      contLiabilityAmt1: new UntypedFormControl(null),
      otherLiability1: new UntypedFormControl(null),
      provisionChangeReason1: new UntypedFormControl(null),
      provisionNotMadeReason1: new UntypedFormControl(null),
      contLiabReason1: new UntypedFormControl(null),
      otherLiabReason1: new UntypedFormControl(null),
      considerInCaroReport1: new UntypedFormControl(null),
      caroAmt1: new UntypedFormControl(null),

    })
  }
  addDetail(j: any) {
    let issuesFrmArray = this.preSCForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[j].get('details') as UntypedFormArray;
    detailsFrmArray.push(this.newDetailsFrmGrp());
  }
  rmDetail(i: number, k: number) {
    let issuesFrmArray = this.preSCForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    detailsFrmArray.removeAt(k);
    this.rmvDetailModalRef.close();

  }
  // details array
  /*ph div array */
  apaDivArr(): UntypedFormArray {
    return this.preSCForm.get("itemList") as UntypedFormArray
  }

  // phTabArr(): FormArray {
  //   return this.adjP1Form.get("itemList") as FormArray
  // }
  newphArray(): UntypedFormGroup {
    return this.fBuild.group({
      phNoticeDate: [null],
      dateOfPhScheduled: [null],
      phCopyLoc: new UntypedFormArray([]),
      adjournedOrNot: [false],
      attended: [null],
      consultant: [this.caseData?.consultant1, []],
      dateOfsubmission: [null],
      additionalSubmissionLoc: new UntypedFormArray([]),
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phDiscussionNotes: [null],
      phAdjournmentLetterLoc: new UntypedFormArray([]),
      mode: ['', []],
      phRefNo:['', []],
      phTime:['', []],
    })
  }
  createAdjDiv() {
    // this.adjPhTablist.push(this.newphArray());
    this.apaDivArr().push(this.newphArray());
  }
  removeLetter(i: number) {
    this.apaDivArr().removeAt(i);
  }

  deleteitemListFile(index: any) {
    var itemListArray = this.preSCForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.controls.phRecordIfAnyLoc.setValue(null);
          if (this.caseItemList.length) this.caseItemList[index].phRecordIfAnyLoc = null
        }
      }

    }
  }

  deletePHNoticeFile(index: any) {
    var itemListArray = this.preSCForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.controls.phCopyLoc.setValue(null);
          if (this.caseItemList.length) this.caseItemList[index].phCopyLoc = null
        }
      }
    }
  }

  deleteSubmissionFile(index: any) {
    var itemListArray = this.preSCForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.controls.additionalSubmissionLoc.setValue(null);
          if (this.caseItemList.length) this.caseItemList[index].additionalSubmissionLoc = null
        }
      }
    }
  }

  /*ph div array */

  tabChanged(tab: any, check?: any) {
    //this.preCommAuthForm.patchValue(this.preCommAuthForm.value);
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.initializeData();
    }
  }
  phaseChanged(phase: any, i: any) {
    this.selectedPhase = phase;
    if (phase === ('phase' + i)) {

    }
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2011;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push({ year: financialYrRange, disabled: false });
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }
  checkpreDepositNo(event: any) {
    if (event.target.value === "false") {
      this.disablField = true;
    } else {
      this.disablField = false;
    }
  }
  changePayMode(event: any) {
    if (event.target.value === "ITC") {
      this.disablBankChallan = true;
    } else {
      this.disablBankChallan = false;
    }
  }
  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  personResponsibleData() {
    let model = {
      companyid: this.selectedCompanyId
    }
    this.preLitService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });

  }

  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        console.log('list:', this.consultantList);
        const userMailID: any = sessionStorage.getItem('UserId');
        const matchingConsultant = this.consultantList.find(
          (consultant: any) => consultant.email === userMailID
        );

        if (matchingConsultant) {
          this.preSCForm.controls.consultant1.patchValue(matchingConsultant.consultantName)
        }
      }
    });
  }

  onClickToggleConsultantDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleConsultantDropdown = !this.isToggleConsultantDropdown;
    } else if (phase === 'p2') {
      this.isToggleConsultantDropdown2 = !this.isToggleConsultantDropdown2;
    } else if (phase === 'CC'){
      this.isToggleConsultantDropdown3 = !this.isToggleConsultantDropdown3;
    }
  }
  setStateAutoPop() {
    if (this.selectedFilling.gstin) {
      this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
      this.selectedStateName = this.commonServ.getStateName(this.selectedStateCode);
    }
  }
  changeadjOrNot(event: any, i: any) {
    if (event.target.value === "true") {
      this.createAdjDiv();
    }
  }

  openRmvIssuePop(content: any, i: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }
  openDetailPop(content: any, i: any) {
    this.modalService.open(content);
    this.finYearSelelctEvent(i);
    this.quarterSelectEvent(i);
  }
  openRmvDetailPop(content: any, i: any, k: any) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }

  getValidDate(date: any): any {
    if (date.value) {
      let d = date.value.split('-');
      const dateValidation = new Date(d[1] + '-' + d[0] + '-' + d[2]);
      return dateValidation;
    } else {
      return new Date();
    }
  }
  changeState(event: any) {
    this.selectedState = event.target.value;
    this.fetchRegNo();
  }
  changeTax(event: any) {
    this.selectedActType = event.target.value;
    this.fetchRegNo();
    let model = {
      companyid: this.selectedCompanyId,
      actType: this.selectedActType,
    }
    this.getState(model);
    if (event.target.value === "EXCISE") {
      this.isExcise = true;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preSCForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ serviceTax1: '0', kkc1: '0', sbc1: '0', vat1: '0', cst1: '0', bcd1: '0', ent1: '0',sad1:'0',antiDumpingDuty1:'0',igst1:'0' });
        }
      }
    } else if (event.target.value === "SERVICE TAX") {
      this.isExcise = false;
      this.isServiceTax = true;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preSCForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ exciseDuty1: '0', vat1: '0', cst1: '0', bcd1: '0', ent1: '0',sad1:'0',antiDumpingDuty1:'0',igst1:'0' });
        }
      }
    } else if (event.target.value === "VAT") {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = true;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preSCForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ exciseDuty1: '0', ec1: '0', shec1: '0', serviceTax1: '0', kkc1: '0', sbc1: '0', cst1: '0', bcd1: '0', ent1: '0',sad1:'0',antiDumpingDuty1:'0',igst1:'0' });
        }
      }
    } else if (event.target.value === "CST") {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = true;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preSCForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ exciseDuty1: '0', ec1: '0', shec1: '0', serviceTax1: '0', kkc1: '0', sbc1: '0', vat1: '0', bcd1: '0', ent1: '0',sad1:'0',antiDumpingDuty1:'0',igst1:'0' });
        }
      }
    } else if (event.target.value === "CUSTOMS DUTY") {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = true;
      this.isEntryTax = false;
      var issues1Array = this.preSCForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ exciseDuty1: '0', ec1: '0', shec1: '0', serviceTax1: '0', kkc1: '0', sbc1: '0', vat1: '0', cst1: '0' });
        }
      }
    } else if (event.target.value === "ENTRY TAX") {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = true;
      var issues1Array = this.preSCForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({ exciseDuty1: '0', ec1: '0', shec1: '0', serviceTax1: '0', kkc1: '0', sbc1: '0', vat1: '0', cst1: '0' });
        }
      }
    }
  }
  actTypeFetch() {
    // let model = {
    //   companyId: localStorage.getItem('selectedId'),
    //   userMailId: this.selectedUserId
    // }
    // this.preLitService.fetchWatchListFormData(model).subscribe((response: any) => {
    //   if (response.status === 1) {
    //     this.isActdisable = true;
    //     this.actTypes = response.response;
    //     this.toaster.showSuccess(response.message);
    //   }
    //   else {
    //     this.toaster.showError(response.message);
    //   }
    // });
    this.isActdisable = false;
    this.actTypes = this.typeOfAct
  }

  getState(model: any) {
    if (this.caseData) {
      model.pan = this.caseData.pan ? this.caseData.pan : this.pan;
    } else {
      model.pan = this.pan;
    }
    this.preLitService.getActwiseState(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.actStateList = response.response;
      }
    });
  }

  whetherCallBookChanged() {
    this.disablePhase3 = (this.preSCForm.value.whetherCaseCallBook === 'true' || this.preSCForm.value.whetherCaseCallBook === true) ? true : false;
  }

  //fetch Registration Number
  fetchRegNo() {
    let model: any = {};
    if (this.caseData) {
      // model.actType = this.caseData.actType
      // model.state = this.resStateName;
      // model.actType = this.caseData.actType ? this.caseData.actType : this.selectedActType;
      // model.state = this.resStateName ? this.resStateName :  this.selectedState;
      model.actType = this.caseData.actType ? this.caseData.actType : this.selectedActType;
      model.state = this.caseData.state ? this.caseData.state : this.selectedState;
      model.pan = this.caseData.pan ? this.caseData.pan : this.pan;

      // model.pan = this.pan;
    } else {
      model.actType = this.selectedActType;
      model.state = this.selectedState;
      model.pan = this.pan;
    }
    this.preLitService.fetchRegNum(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.regNumRes = response.response;
        this.toaster.showSuccess(response.message);
        if(this.regNumRes  !== null){
          this.selectedRegNo = this.regNumRes[0].regNo;
        }
        // this.regNumRes.forEach((element: any) => {
        //   this.preSCForm.patchValue({ regNo: element.regNo });
        // });
      } else {
        // this.toaster.showError(response.message);
        this.preSCForm.patchValue({ regNo: '' });
      }
    });

  }

  getDetailOfBankGuaranteeArr(): UntypedFormGroup[] {
    return (this.preSCForm.get('bankGuarantee') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  detailOfBankGuarantee(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      bgNo: [data ? data?.bgNo : null,[Validators.maxLength(100)]],
      issueDate: [data ? data?.issueDate : null],
      expiryDate: [data ? data?.expiryDate : null],
      bgAmount: [data ? data?.bgAmount : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      fee: [data ? data?.fee : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      bgStatus: [data ? data?.bgStatus  : null],
      remark:[data ? data?.remark  : null, [Validators.maxLength(100)]],
    })
  }

  addDetailOfBankGuarantee() {
    let bankGuaranteeDetailsFrmGrp = this.detailOfBankGuarantee();
    (this.preSCForm.get('bankGuarantee') as UntypedFormArray).push(bankGuaranteeDetailsFrmGrp);
  }

  rmBankGuarantee(i: number) {
    (this.preSCForm.get('bankGuarantee') as UntypedFormArray).removeAt(i);
    this.rmvbankGuaranteeRef.close();
  }
  openRemoveBankGuaranteePop(content: any) {
    this.rmvbankGuaranteeRef = this.modalService.open(content);

  }

  detailOfprotestDetails(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      challanNo: [data ? data?.challanNo : null],
      modeOfPayment: [data ? data?.modeOfPayment : null],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],
      copyOfFormLoc: new UntypedFormArray([]),
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      supportingDocLoc: new UntypedFormArray([])
    })
  }

  getDetailOfPaymentArr(): UntypedFormGroup[] {
    return (this.preSCForm.get('protestDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  addDetailOfPayment() {
    let protestDetailsFrmGrp = this.detailOfprotestDetails();
    (this.preSCForm.get('protestDetails') as UntypedFormArray).push(protestDetailsFrmGrp);
  }

  rmPaymentUnderProtest(i: number) {
    (this.preSCForm.get('protestDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfPaymentRef.close();
    this.paymentUnderProtestp1Changed();
  }
  openRemoveDetailsOfPaymentPop(content: any) {
    this.rmvDetailsOfPaymentRef = this.modalService.open(content);

  }

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }
  uploadProtestDetailsCopyOfFormLoc(i: any) {
    var protestDetailsArray = this.preSCForm.controls.protestDetails as UntypedFormArray
    if (protestDetailsArray.controls.length > 0) {
      for (var index = 0; index < protestDetailsArray.controls.length; index++) {
        if (index === i) {
          var protestDetailsControl = protestDetailsArray.controls[index] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.isSupportingFile = true;
    this.isSupportingText = false;
    this.isSupportingDWText = false;
    this.isSupportingUPText = false;
  }
  deleteUploadFile(i: any) {
    let protestArray = this.preSCForm.controls.protestDetails as UntypedFormArray
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.supportingDocLoc.setValue(null);
          issuesControl.value['isSupportingDocLocDocUploadedClicked'] = true;
        }
      }
    }
  }

  paymentUnderProtestp1Changed() {
    let taxTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let protestTotal = 0;

    (this.preSCForm.get('protestDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const tax = typeof c.value.tax === 'string' ? c.value.tax.replace(/,/g, '') : c.value.tax;
      const interest = typeof c.value.interest === 'string' ? c.value.interest.replace(/,/g, '') : c.value.interest;
      const penalty = typeof c.value.penalty === 'string' ? c.value.penalty.replace(/,/g, '') : c.value.penalty;
      taxTotal += Number(tax);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(tax)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = typeof c.value.total === 'string' ? c.value.total.replace(/,/g, '') : c.value.total;
      protestTotal += Number(total);

    })

    this.protestInterestTotal = interestTotal;
    this.protestTaxTotal = taxTotal;
    this.protestPenaltyTotal = penaltyTotal;
    this.protestTotal = protestTotal;
    // this.setCummulativeCount();
  }


  detailOfAppeal(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      refNo: [data ? data?.refNo : null],
      submissionDate: [data ? data?.submissionDate : null],
      submissionMode: [data ? data?.submissionMode : null],
      copyOfAckLoc: new UntypedFormArray([]),
      otherAttachmentsLoc: new UntypedFormArray([])
    })
  }

  getDetailOfAppeal() {
    return (this.preSCForm.get('submissions') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  addDetailOfAppeal() {
    let appealFrmGrp = this.detailOfAppeal();
    (this.preSCForm.get('submissions') as UntypedFormArray).push(appealFrmGrp);
  }

  openRemoveDetailsOfAppeal(content: any) {
    this.rmvDetailsOfAppealRef = this.modalService.open(content);
  }

  rmDetailsOfAppeal(i: number) {
    (this.preSCForm.get('submissions') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfAppealRef.close();
  }

  uploadCopyOfAckLoc(i: any) {
    var submissionsArray = this.preSCForm.controls.submissions as UntypedFormArray
    if (submissionsArray.controls.length > 0) {
      for (var index = 0; index < submissionsArray.controls.length; index++) {
        if (index === i) {
          var appealDetailControl = submissionsArray.controls[index] as UntypedFormGroup;
          appealDetailControl.value['isAckCopyLocDocUploadedClicked'] = true
        }
      }
    }
    this.copyOfAckLocDocFile = true;
    this.copyOfAckLocDocText = false;
    this.copyOfAckLocDWText = true;
    this.copyOfAckLocUPText = false;
  }
  deleteSubmissions2UploadFile(i: any) {
    let submissionArray = this.preSCForm.controls.submissions as UntypedFormArray
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentsLoc.setValue(null);
          issuesControl.value['isOtherAttachmentsLocUploadedClicked'] = true;
        }
      }
    }
  }

  deleteSubmissionsUploadFile(i: any) {
    let submissionArray = this.preSCForm.controls.submissions as UntypedFormArray
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfAckLoc.setValue(null);
          issuesControl.value['iscopyOfAckLocUploadedClicked'] = true;
        }
      }
    }
  }
  uploadotherAttachments(i: any) {
    var submissionsArray = this.preSCForm.controls.submissions as UntypedFormArray
    if (submissionsArray.controls.length > 0) {
      for (var index = 0; index < submissionsArray.controls.length; index++) {
        if (index === i) {
          var appealDetailControl = submissionsArray.controls[index] as UntypedFormGroup;
          appealDetailControl.value['isOtherAttachmentsLocDocUploadedClicked'] = true
        }
      }
    }
    this.isOtherAttachmentFile = true;
    this.isOtherAttachmentText = false;
    this.isOtherAttachmentDWText = true;
    this.isOtherAttachmentUPText = false;
  }

  consultantChargesList(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      firm: [data ? data.firm : null],
      consultant: [data ? data.consultant : null],
      natureOfWork: [data ? data.natureOfWork : null],
      fees: [data ? data.fees : 0],
      otherAttachmentLoc: new UntypedFormArray([]),
      email: [data ? data.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [data ? data.contact : null]
    })

  }
  consultantInvArr(): UntypedFormGroup[] {
    return (this.preSCForm.get('consultantCharges') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  consultantDataAutofil(index: number) {
    console.log('index:', index);
    const consultantGrp = this.consultantInvArr()[index];
    const selectedConsultantName = consultantGrp.get('consultant')?.value;

    // Find the selected consultant in the consultantList
    const selectedConsultant = this.consultantList.find(
      (obj: any) => obj.consultantName === selectedConsultantName
    );

    if (selectedConsultant) {
      // Auto-fill the remaining fields
      consultantGrp.get('firm')?.setValue(selectedConsultant.firmName || '');
      consultantGrp.get('email')?.setValue(selectedConsultant.email || '');
      consultantGrp.get('contact')?.setValue(selectedConsultant.contact || '');
    } else {
      // Clear the fields if consultant is not found
      consultantGrp.get('firm')?.reset();
      consultantGrp.get('email')?.reset();
      consultantGrp.get('contact')?.reset();
      consultantGrp.get('natureOfWork')?.reset();
      consultantGrp.get('fees')?.reset();
      consultantGrp.get('otherAttachmentLoc')?.reset();
    }

    console.log('data populated succesfully !!')
  }

  addConsultantInv() {
    let consutantFrmGrp = this.consultantChargesList();
    (this.preSCForm.get('consultantCharges') as UntypedFormArray).push(consutantFrmGrp);
    return false;

  }

  openRmvconsultantPop(content: any, i: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }

  rmConsultantInv(i: number) {
    (this.preSCForm.get('consultantCharges') as UntypedFormArray).removeAt(i);
    this.rmvConsultantModalRef.close();
    this.feesChargedChanged();
  }
  deleteConsultantFile(i: any) {
    let consultantArray = this.preSCForm.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentLoc.setValue(null);
          issuesControl.value['isOtherAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (this.preSCForm.get('consultantCharges') as UntypedFormArray).controls.forEach((c: any) => {
      const fees = typeof c.value.fees === 'string' ? c.value.fees.replace(/,/g, '') : c.value.fees;
      feesChargedTotal += Number(fees);
    });

    this.feesChargedTotal = feesChargedTotal;
  }

  uploadoConsultantAttachmentsLoc(i: any) {
    var consultantArray = this.preSCForm.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (var index = 0; index < consultantArray.controls.length; index++) {
        if (index === i) {
          var consultantControl = consultantArray.controls[index] as UntypedFormGroup;
          consultantControl.value['isOtherAttachmentLocDocUploadedClicked'] = true
        }
      }
    }
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLoc = true;
    this.isconsultantUPattachmentsLoc = false;
  }

  detailsOfLiabilityAddmittedAndPaid(data: any = null) {
    return this.fBuild.group({
      challanNo: [data ? data?.challanNo : null],
      modeOfPayment: [data ? data?.modeOfPayment : null],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [data ? data?.interest : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      copyOfFormLoc: new UntypedFormArray([])
    })
  }

  addDetailsOfLiabilityAddmittedAndPaid() {
    let liabilityAddmittedAndPaidFrmGrp = this.detailsOfLiabilityAddmittedAndPaid();
    (this.preSCForm.get('liabilityDetails') as UntypedFormArray).push(liabilityAddmittedAndPaidFrmGrp);

  }

  detailsOfLiabilityAddmittedAndPaidArr(): UntypedFormGroup[] {
    return (this.preSCForm.get('liabilityDetails') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  rmLiabilityAddmittedAndPaidInv(i: number) {
    (this.preSCForm.get('liabilityDetails') as UntypedFormArray).removeAt(i);
    this.rmvLiabilityAddmittedAndPaidModalRef.close();
    this.liabilityAddmittedAndPaidChanged()
  }

  openLiabilityAddmittedAndPaidPop(content: any) {
    this.rmvLiabilityAddmittedAndPaidModalRef = this.modalService.open(content);
  }

  deleteliabilityDetailsFile(i: any) {
    let liabilityArray = this.preSCForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  uploadLiabilityDetailsCopyOfForm(i: any) {
    var liabilityDetailsArray = this.preSCForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityDetailsArray.controls.length > 0) {
      for (var index = 0; index < liabilityDetailsArray.controls.length; index++) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
    this.isSupportingDocFile = true;
    this.isSupportingDocText = false;
    this.isSupportingDocDWText = true;
    this.isSupportingDocUPText = false;
  }

  liabilityAddmittedAndPaidChanged() {
    let taxTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let preDepositeTotal = 0;

    (this.preSCForm.get('liabilityDetails') as UntypedFormArray).controls.forEach((c: any) => {
      const tax = typeof c.value.tax === 'string' ? c.value.tax.replace(/,/g, '') : c.value.tax;
      const interest = typeof c.value.interest === 'string' ? c.value.interest.replace(/,/g, '') : c.value.interest;
      const penalty = typeof c.value.penalty === 'string' ? c.value.penalty.replace(/,/g, '') : c.value.penalty;
      taxTotal += Number(tax);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue({ total: this.inrService.formatCurrency(String((+Number(tax)) + (+Number(interest)) + (+Number(penalty)))) }, { emitEvent: false, onlySelf: true });
      const total = typeof c.value.total === 'string' ? c.value.total.replace(/,/g, '') : c.value.total;
      preDepositeTotal += Number(total);
    })

    this.liabilityAddmittedAndPaidInterestTotal = interestTotal;
    this.liabilityAddmittedAndPaidTaxTotal = taxTotal;
    this.liabilityAddmittedAndPaidPenaltyTotal = penaltyTotal;
    this.liabilityAddmittedAndPaidTotal = preDepositeTotal;
    // this.setCummulativeCount();

  }


  //fetch case
  initializeData() {
    if (this.savedPreCaseId) {
      this.caseId = this.savedPreCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        type: this.tabType[4].key,
        caseId: this.caseId,
      }
      this.preLitService.getAllCaseSummarySc(model).subscribe((response: any) => {
        if (response.status === 1) {
          this.activeStages = response.response?.prevStages;
          this.enablePrevStageTab.emit(this.activeStages);
          if (response.response.isMemorandum) {
            this.isMemorandum = true;
          }
          this.caseData = response.response;
          this.resStateName = response.response.state;
          this.caseType = this.caseData.caseType;
          if (this.caseData?.protestDetails) {
            this.caseData.protestDetails.forEach((protest: any) => {
              protest.total = this.inrService.formatCurrency(String(protest.total))
              protest.tax = this.inrService.formatCurrency(String(protest.tax))
              protest.interest = this.inrService.formatCurrency(String(protest.interest))
              protest.penalty = this.inrService.formatCurrency(String(protest.penalty))
            })
          }

          if (this.caseData?.consultantCharges) {
            this.caseData.consultantCharges.forEach((charge: any) => {
              charge.fees = this.inrService.formatCurrency(String(charge.fees))
            })
          }

          if (this.caseData?.bankGuarantee) {
            this.caseData.bankGuarantee.forEach((bank: any) => {
              bank.bgAmount = this.inrService.formatCurrency(String(bank.bgAmount))
              bank.fee = this.inrService.formatCurrency(String(bank.fee))
            })
          }

          if (this.caseData?.liabilityDetails) {
            this.caseData.liabilityDetails.forEach((liability: any) => {
              liability.total = this.inrService.formatCurrency(String(liability.total))
              liability.tax = this.inrService.formatCurrency(String(liability.tax))
              liability.interest = this.inrService.formatCurrency(String(liability.interest))
              liability.penalty = this.inrService.formatCurrency(String(liability.penalty))
            })
          }

          if (this.caseData?.issues) {
            this.caseData.issues.forEach((issue: any) => {
              issue.exciseDuty1 = this.inrService.formatCurrency(String(issue.exciseDuty1))
              issue.serviceTax1 = this.inrService.formatCurrency(String(issue.serviceTax1))
              issue.ec1 = this.inrService.formatCurrency(String(issue.ec1))
              issue.shec1 = this.inrService.formatCurrency(String(issue.shec1))
              issue.kkc1 = this.inrService.formatCurrency(String(issue.kkc1))
              issue.vat1 = this.inrService.formatCurrency(String(issue.vat1))
              issue.cst1 = this.inrService.formatCurrency(String(issue.cst1))
              issue.sbc1 = this.inrService.formatCurrency(String(issue.sbc1))
              issue.bcd1 = this.inrService.formatCurrency(String(issue.bcd1))
              issue.sad1 = this.inrService.formatCurrency(String(issue.sad1))
              issue.antiDumpingDuty1 = this.inrService.formatCurrency(String(issue.antiDumpingDuty1))
              issue.igst1 = this.inrService.formatCurrency(String(issue.igst1))
              issue.ent1 = this.inrService.formatCurrency(String(issue.ent1))
              issue.interest1 = this.inrService.formatCurrency(String(issue.interest1))
              issue.penalty1 = this.inrService.formatCurrency(String(issue.penalty1))
              issue.total1 = this.inrService.formatCurrency(String(issue.total1))

              issue.exciseDuty3 = this.inrService.formatCurrency(String(issue.exciseDuty3))
              issue.serviceTax3 = this.inrService.formatCurrency(String(issue.serviceTax3))
              issue.ec3 = this.inrService.formatCurrency(String(issue.ec3))
              issue.shec3 = this.inrService.formatCurrency(String(issue.shec3))
              issue.kkc3 = this.inrService.formatCurrency(String(issue.kkc3))
              issue.vat3 = this.inrService.formatCurrency(String(issue.vat3))
              issue.cst3 = this.inrService.formatCurrency(String(issue.cst3))
              issue.sbc3 = this.inrService.formatCurrency(String(issue.sbc3))
              issue.bcd3 = this.inrService.formatCurrency(String(issue.bcd3))
              issue.sad3 = this.inrService.formatCurrency(String(issue.sad3))
              issue.antiDumpingDuty3 = this.inrService.formatCurrency(String(issue.antiDumpingDuty3))
              issue.igst3 = this.inrService.formatCurrency(String(issue.igst3))
              issue.ent3 = this.inrService.formatCurrency(String(issue.ent3))
              issue.interest3 = this.inrService.formatCurrency(String(issue.interest3))
              issue.penalty3 = this.inrService.formatCurrency(String(issue.penalty3))
              issue.total3 = this.inrService.formatCurrency(String(issue.total3))

              if (issue.details && issue.details.length) {
                issue.details.forEach((details: any) => {
                  details.provisionAmt1 = this.inrService.formatCurrency(String(details.provisionAmt1))
                  details.contLiabilityAmt1 = this.inrService.formatCurrency(String(details.contLiabilityAmt1))
                  details.otherLiability1 = this.inrService.formatCurrency(String(details.otherLiability1))
                  details.caroAmt1 = this.inrService.formatCurrency(String(details.caroAmt1))
                })
              }
            })
          }
          let model = {
            companyid: this.selectedCompanyId,
            actType: this.caseData.actType,
          }
          this.getState(model);
          this.fetchRegNo();
          this.caseData = response.response;
          this.resStateName = response.response.state;
          if (this.caseData.actType === "EXCISE") {
            this.isExcise = true;
            this.isServiceTax = false;
            this.isVat = false;
            this.isCst = false;
            this.isCustomsDuty = false;
            this.isEntryTax = false;
          } else if (this.caseData.actType === "SERVICE TAX") {
            this.isExcise = false;
            this.isServiceTax = true;
            this.isVat = false;
            this.isCst = false;
            this.isCustomsDuty = false;
            this.isEntryTax = false;
          } else if (this.caseData.actType === "VAT") {
            this.isExcise = false;
            this.isServiceTax = false;
            this.isVat = true;
            this.isCst = false;
            this.isCustomsDuty = false;
            this.isEntryTax = false;
          } else if (this.caseData.actType === "CST") {
            this.isExcise = false;
            this.isServiceTax = false;
            this.isVat = false;
            this.isCst = true;
            this.isCustomsDuty = false;
            this.isEntryTax = false;
          } else if (this.caseData.actType === "CUSTOMS DUTY") {
            this.isExcise = false;
            this.isServiceTax = false;
            this.isVat = false;
            this.isCst = false;
            this.isCustomsDuty = true;
            this.isEntryTax = false;
          } else if (this.caseData.actType === "ENTRY TAX") {
            this.isExcise = false;
            this.isServiceTax = false;
            this.isVat = false;
            this.isCst = false;
            this.isCustomsDuty = false;
            this.isEntryTax = true;
          }

          this.formInitialize();
          this.whetherCallBookChanged();
          if (this.caseData.itemList && this.caseData.itemList.length > 0) {
            this.initializeItemList();
          } else {
            this.initializeItemList();
            var list: UntypedFormGroup = this.newphArray();
            this.apaDivArr().push(list);
          }
          if (this.caseData.issues && this.caseData.issues.length > 0) {
            this.initializeIssueList();
          } else {
            this.addIssueInv();
          }
          if (this.caseData.submissions && this.caseData.submissions.length > 0) {
            this.initializeDetailsOfAppealList();
          } else {
            this.addDetailOfAppeal();
          }
          if (this.caseData.appealBookLoc && this.caseData.appealBookLoc.length > 0) {
            this.initializeappealBookLoc();
          } else {
            this.addappealBookLoc();
          }
          if (this.caseData.otherAttachmentsLoc1 && this.caseData.otherAttachmentsLoc1.length > 0) {
            this.initializeotherAttachmentsLoc1();
          } else {
            this.addotherAttachmentsLoc1();
          }
          if (this.caseData.bankChallanLoc && this.caseData.bankChallanLoc.length > 0) {
            this.initializebankChallanLoc();
          } else {
            this.addbankChallanLoc();
          }
          if (this.caseData.protestDetails && this.caseData.protestDetails.length > 0) {
            this.initializePaymentDetailList();
          } else {
            this.addDetailOfPayment();
          }
          if (this.caseData.bankGuarantee && this.caseData.bankGuarantee.length > 0) {
            this.initializeBankGuarantee();
          } else {
            this.addDetailOfBankGuarantee();
          }
          this.paymentUnderProtestp1Changed();
          if (this.caseData.consultantCharges && this.caseData.consultantCharges.length > 0) {
            this.initializeConsultantList();
          } else {
            this.addConsultantInv();
          }
          if (this.caseData.liabilityDetails && this.caseData.liabilityDetails.length > 0) {
            this.initializeDetailOfLiability();
          } else {
            this.addDetailsOfLiabilityAddmittedAndPaid();
          }
          this.cumulativeCount = this.caseData.cumulative ? this.caseData.cumulative : this.cumulativeCount
          this.prevStage = this.caseData.prevStage;

          this.paymentUnderProtestp1Changed();
          this.feesChargedChanged();
          this.liabilityAddmittedAndPaidChanged();
          this.setCummulativeCount();
          this.issueValueChange();
          this.isUpdate = true;
        }
        this.changeOutcome();
      });
    }
  }
  //fetch case
  initializeappealBookLoc() {
    const appealBookLocFormArray = this.preSCForm.get('appealBookLoc') as UntypedFormArray;
    appealBookLocFormArray.clear();
    if (this.caseData.appealBookLoc) {
      this.caseData.appealBookLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createappealBookLoc(not);

        (this.preSCForm.get('appealBookLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
    }
    this.preSCForm.patchValue(this.caseData);
  }
  initializeotherAttachmentsLoc1() {
    const otherAttachmentsLoc1FormArray = this.preSCForm.get('otherAttachmentsLoc1') as UntypedFormArray;
    otherAttachmentsLoc1FormArray.clear();
    if (this.caseData.otherAttachmentsLoc1) {
      this.caseData.otherAttachmentsLoc1.forEach((not: any) => {

        let csnCopyFrmGrp = this.createotherAttachmentsLoc1(not);

        (this.preSCForm.get('otherAttachmentsLoc1') as UntypedFormArray).push(csnCopyFrmGrp);

      })
    }
    this.preSCForm.patchValue(this.caseData);
  }


  initializebankChallanLoc() {
    const bankChallanLocFormArray = this.preSCForm.get('bankChallanLoc') as UntypedFormArray;
    bankChallanLocFormArray.clear();
    if (this.caseData.bankChallanLoc) {
      this.caseData.bankChallanLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createbankChallanLoc(not);

        (this.preSCForm.get('bankChallanLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
    }
    this.preSCForm.patchValue(this.caseData);
  }
  //initialise ph div array
  initializeItemList() {
    if (this.caseData.itemList) {
      this.caseData.itemList.forEach((items: any) => {
        let itemListFrmGrp = this.newphArray();
        (this.preSCForm.get('itemList') as UntypedFormArray).push(itemListFrmGrp);
        if (items.phCopyLoc) {
          items.phCopyLoc.forEach((not: any) => {
            let phcopyFormGrp: UntypedFormGroup = this.createphCopyLoc(not);
            (itemListFrmGrp.get('phCopyLoc') as UntypedFormArray).push(phcopyFormGrp);
          });
        }
        if (items.phRecordIfAnyLoc) {
          items.phRecordIfAnyLoc.forEach((not: any) => {
            let phRecordAnyGrp: UntypedFormGroup = this.createPhRecord(not);
            (itemListFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).push(phRecordAnyGrp);
          });
        }
        if (items.phAdjournmentLetterLoc) {
          items.phAdjournmentLetterLoc.forEach((not: any) => {
            let phAdjournmentLetterLocGrp: UntypedFormGroup = this.createPhAdjournmentLetterLoc(not);
            (itemListFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).push(phAdjournmentLetterLocGrp);
          }); 
        }
        if (items.additionalSubmissionLoc) {
          items.additionalSubmissionLoc.forEach((not: any) => {
            let additionalSubmissionFrmGrp: UntypedFormGroup = this.createAdditionalSubLoc(not);
            (itemListFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).push(additionalSubmissionFrmGrp);
          });
        }
      });
      this.preSCForm.patchValue(this.caseData);
      this.initializeappealBookLoc();
      this.initializeotherAttachmentsLoc1();
      this.initializebankChallanLoc();
    }
  }
  //initialise issue array
  initializeIssueList() {
    if (this.caseData.issues) {
      this.caseData.issues.forEach((issue: any) => {
        let issuesFrmGrp: UntypedFormGroup = this.newIssueInvArray();
        (this.preSCForm.get('issues') as UntypedFormArray).push(issuesFrmGrp);
        if (issue.details) {
          issue.details.forEach((detail: any) => {
            let detailFrmGrp: UntypedFormGroup = this.newDetailsFrmGrp();
            (issuesFrmGrp.get('details') as UntypedFormArray).push(detailFrmGrp);
          });
        }


      });
      this.preSCForm.patchValue(this.caseData);
      this.issueValueChange();
    }
  }
  // initialize Protest Details array
  initializePaymentDetailList() {
    if (this.caseData.protestDetails) {
      this.caseData.protestDetails.forEach((protest: any) => {
        let protestDetailsFrmGrp = this.detailOfprotestDetails(protest);
        (this.preSCForm.get('protestDetails') as UntypedFormArray).push(protestDetailsFrmGrp);

        if (protest.supportingDocLoc) {
          protest.supportingDocLoc.forEach((not: any) => {
            let supportingDocLocFrmGrp: UntypedFormGroup = this.createProtestSupportingDoc(not);
            (protestDetailsFrmGrp.get('supportingDocLoc') as UntypedFormArray).push(supportingDocLocFrmGrp);
          });
        }
      });
      this.preSCForm.patchValue(this.caseData);
    }
  }
    initializeBankGuarantee() {
    if (this.caseData.bankGuarantee) {
      this.caseData.bankGuarantee.forEach((bank: any) => {
        let bankGuaranteeDetailsFrmGrp = this.detailOfBankGuarantee(bank);
        (this.preSCForm.get('bankGuarantee') as UntypedFormArray).push(bankGuaranteeDetailsFrmGrp);
      })
      this.preSCForm.patchValue(this.caseData);
    }
  }

  // initialise  submissions
  initializeDetailsOfAppealList() {
    if (this.caseData.submissions) {
      this.caseData.submissions.forEach((submissions: any) => {
        let appealDetailsFrmGrp: UntypedFormGroup = this.detailOfAppeal(submissions);
        (this.preSCForm.get('submissions') as UntypedFormArray).push(appealDetailsFrmGrp);

        if (submissions.copyOfAckLoc) {
          submissions.copyOfAckLoc.forEach((not: any) => {
            let submissionsFrmGrp: UntypedFormGroup = this.createCopyOfAckLoc(not);
            (appealDetailsFrmGrp.get('copyOfAckLoc') as UntypedFormArray).push(submissionsFrmGrp);
          });
        }
        if (submissions.otherAttachmentsLoc) {
          submissions.copyOfAckLoc.forEach((not: any) => {
            let submissionsFrmGrp: UntypedFormGroup = this.createOtherAttachmentsLoc(not);
            (appealDetailsFrmGrp.get('otherAttachmentsLoc') as UntypedFormArray).push(submissionsFrmGrp);
          });
        }
      });
    }
  }


  // // initialise  consultantCharges
  initializeConsultantList() {
    if (this.caseData.consultantCharges) {
      this.caseData.consultantCharges.forEach((consultantCharges: any) => {
        let consultantListFrmGrp: UntypedFormGroup = this.consultantChargesList(consultantCharges);
        (this.preSCForm.get('consultantCharges') as UntypedFormArray).push(consultantListFrmGrp);

        if (consultantCharges.otherAttachmentLoc) {
          consultantCharges.otherAttachmentLoc.forEach((not: any) => {
            let ConsultotherAttachmentFrmGrp: UntypedFormGroup = this.createConsultotherAttachmentLoc(not);
            (consultantListFrmGrp.get('otherAttachmentLoc') as UntypedFormArray).push(ConsultotherAttachmentFrmGrp);
          });
        }

      });
    }
  }

  //  initialise liabilityDetails array
  initializeDetailOfLiability() {
    if (this.caseData.liabilityDetails) {
      this.caseData.liabilityDetails.forEach((liabilityDetails: any) => {
        let liabilityAddmittedAndPaidFrmGrp = this.detailsOfLiabilityAddmittedAndPaid(liabilityDetails);
        (this.preSCForm.get('liabilityDetails') as UntypedFormArray).push(liabilityAddmittedAndPaidFrmGrp);

        if (liabilityDetails.copyOfFormLoc) {
          liabilityDetails.copyOfFormLoc.forEach((not: any) => {
            let LiabilitySupportingDocFrmGrp: UntypedFormGroup = this.createLiabilitySupportingDocLoc(not);
            (liabilityAddmittedAndPaidFrmGrp.get('copyOfFormLoc') as UntypedFormArray).push(LiabilitySupportingDocFrmGrp);
          });
        }
      })
    }
  }

  UpdateModelData() {
    if (this.caseData != null) {
      this.preSCForm.value.appealBookLoc = this.caseData['appealBookLoc'];
      // this.preSCForm.value.copyOfAckLoc = this.caseData['copyOfAckLoc'];
      this.preSCForm.value.otherAttachmentsLoc1 = this.caseData['otherAttachmentsLoc1']
      this.preSCForm.value.drc03CopyLoc = this.caseData['drc03CopyLoc'];
      this.preSCForm.value.bankChallanLoc = this.caseData['bankChallanLoc'];
      if (this.caseData.bankChallanLoc != null) {
        this.preSCForm.value.bankChallanLoc = this.caseData['bankChallanLoc'];
      } else {
        this.preSCForm.value.bankChallanLoc = null;
      }
    }
  }

  doValidation(phaseTab: any) {
    this.UpdateModelData();
    if (phaseTab === 'p1') {
      this.protestValidation();
    }
    if (phaseTab === 'p2') {
      this.consultantValidation();
      return this.p2validation();
    } else if (phaseTab === 'p3') {
      this.liabilityValidation();
      return this.p3validation();
    } else {
      return true;
    }
  }

  consultantValidation() {
    let consultantArray = this.preSCForm.controls.consultantCharges as UntypedFormArray
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(issuesControl.controls.fees.value)
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (issuesControl.controls.firm.value === null || issuesControl.controls.firm.value === '') {
            issuesControl.controls.firm.setErrors({ 'required': true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }

  // p1validation() {
  //   if (this.caseType ==='MEMORANDUM' && this.preSCForm.value['appealBookLoc'] == null || this.preSCForm.value['appealBookLoc'] === '' ) {
  //     this.appealBookLocReq = true;
  //   } else {

  //     this.appealBookLocReq = false;
  //   }
  //   return !this.appealBookLocReq;
  // }
  protestValidation() {
    let protestArray = this.preSCForm.controls.protestDetails as UntypedFormArray
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.challanNo.value != null && issuesControl.controls.challanNo.value) {
          if (issuesControl.controls.supportingDocLoc.value === null || issuesControl.controls.supportingDocLoc.value === '') {
            issuesControl.controls.supportingDocLoc.setErrors({ 'required': true });
          } else {
            issuesControl.controls.supportingDocLoc.setErrors(null);
          }
        }
      }
    }
  }

  liabilityValidation() {
    let liabilityArray = this.preSCForm.controls.liabilityDetails as UntypedFormArray
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
        if (issuesControl.controls.challanNo.value != null && issuesControl.controls.challanNo.value) {
          if (issuesControl.controls.copyOfFormLoc.value === null || issuesControl.controls.copyOfFormLoc.value === '') {
            issuesControl.controls.copyOfFormLoc.setErrors({ 'required': true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }


  updateIssues() {
    (this.preSCForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ exciseDuty3: c.value.exciseDuty1 })
      c.patchValue({ ec3: c.value.ec1 })
      c.patchValue({ shec3: c.value.shec1 })
      c.patchValue({ serviceTax3: c.value.serviceTax1 })
      c.patchValue({ kkc3: c.value.kkc1 })
      c.patchValue({ sbc3: c.value.sbc1 })
      c.patchValue({ vat3: c.value.vat1 })
      c.patchValue({ bcd3: c.value.bcd1 })
      c.patchValue({ sad3: c.value.sad1 })
      c.patchValue({ antiDumpingDuty3: c.value.antiDumpingDuty1 })
      c.patchValue({ igst3: c.value.igst1 })
      c.patchValue({ ent3: c.value.ent1 })
      c.patchValue({ interest3: c.value.interest1 })
      c.patchValue({ penalty3: c.value.penalty1 })
      c.patchValue({ total3: c.value.total1 })
    })
  }

  updateSubmissions() {
    (this.preSCForm.get('submissions') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ copyOfAckLoc: c.value.copyOfAckLoc });
      // c.patchValue()
    })
  }
  save() {
    this.isSubmitted = true;
    let data: any = {};
    if (this.preSCForm.valid) {
      data = this.preSCForm.value;
      //data.state = this.preSCForm.value.state.toUpperCase();
      let urlData = {
        type: this.tabType[4].key,
        companyId: this.companyId
      }
      let protestArray = this.preSCForm.controls.protestDetails as UntypedFormArray
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;
      
        if (issuesControl.controls.challanNo.value) {
          let supportingDocControls = (issuesControl.get('supportingDocLoc') as UntypedFormArray).controls;
          
          let hasSupportingDoc = supportingDocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
        }
      }
      data.caseType = this.caseType;
      if (data.protestDetails && data.protestDetails.length) {
        data.protestDetails.forEach((protestDetail: any) => {
          protestDetail.tax = this.inrService.removeCommasAndParseNumber(protestDetail.tax)
          protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
          protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
          protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
        })
      }

      if (data.consultantCharges && data.consultantCharges.length) {
        data.consultantCharges.forEach((charge: any) => {
          charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
        })
      }
      if (data.bankGuarantee && data.bankGuarantee.length) {
        data.bankGuarantee.forEach((bank: any) => {
          bank.bgAmount = this.inrService.removeCommasAndParseNumber(bank.bgAmount)
          bank.fee = this.inrService.removeCommasAndParseNumber(bank.fee)
        })
      }

      if (data.liabilityDetails && data.liabilityDetails.length) {
        data.liabilityDetails.forEach((liability: any) => {
          liability.tax = this.inrService.removeCommasAndParseNumber(liability.tax)
          liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
          liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
          liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
        })
      }

      if (data.issues && data.issues.length) {
        data.issues.forEach((issue: any) => {
          issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty1)
          issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(issue.serviceTax1)
          issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1)
          issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1)
          issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1)
          issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1)
          issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1)
          issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1)
          issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1)
          issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1)
          issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1)
          issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
          issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1)
          issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
          issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
          issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)

          issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty3)
          issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(issue.serviceTax3)
          issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3)
          issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3)
          issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3)
          issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3)
          issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3)
          issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3)
          issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3)
          issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3)
          issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3)
          issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
          issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3)
          issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
          issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
          issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)
        })
      }

      data.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty1Total)
      data.serviceTax1Total = this.inrService.removeCommasAndParseNumber(data.serviceTax1Total)
      data.ec1Total = this.inrService.removeCommasAndParseNumber(data.ec1Total)
      data.shec1Total = this.inrService.removeCommasAndParseNumber(data.shec1Total)
      data.kkc1Total = this.inrService.removeCommasAndParseNumber(data.kkc1Total)
      data.vat1Total = this.inrService.removeCommasAndParseNumber(data.vat1Total)
      data.cst1Total = this.inrService.removeCommasAndParseNumber(data.cst1Total)
      data.sbc1Total = this.inrService.removeCommasAndParseNumber(data.sbc1Total)
      data.bcd1Total = this.inrService.removeCommasAndParseNumber(data.bcd1Total)
      data.sad1Total = this.inrService.removeCommasAndParseNumber(data.sad1Total)
      data.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty1Total)
      data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)
      data.ent1Total = this.inrService.removeCommasAndParseNumber(data.ent1Total)
      data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total)
      data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total)
      data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1)

      data.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty3Total)
      data.serviceTax3Total = this.inrService.removeCommasAndParseNumber(data.serviceTax3Total)
      data.ec3Total = this.inrService.removeCommasAndParseNumber(data.ec3Total)
      data.shec3Total = this.inrService.removeCommasAndParseNumber(data.shec3Total)
      data.kkc3Total = this.inrService.removeCommasAndParseNumber(data.kkc3Total)
      data.vat3Total = this.inrService.removeCommasAndParseNumber(data.vat3Total)
      data.cst3Total = this.inrService.removeCommasAndParseNumber(data.cst3Total)
      data.sbc3Total = this.inrService.removeCommasAndParseNumber(data.sbc3Total)
      data.bcd3Total = this.inrService.removeCommasAndParseNumber(data.bcd3Total)
      data.sad3Total = this.inrService.removeCommasAndParseNumber(data.sad3Total)
      data.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty3Total)
      data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total)
      data.ent3Total = this.inrService.removeCommasAndParseNumber(data.ent3Total)
      data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total)
      data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total)
      data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3)


      let appealBookLocArray: any[] = [];
      let otherAttachmentsLoc1Array: any[] = [];
      let protestSupportingDocLocArray: any[] = [];
      for (let i = 0; i < this.preSCForm.controls.appealBookLoc.value.length; i++) {
        const element = this.preSCForm.controls.appealBookLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          appealBookLocArray.push(element)
        }

      }
      this.preSCForm.value.appealBookLoc = appealBookLocArray;

      for (let i = 0; i < this.preSCForm.controls.otherAttachmentsLoc1.value.length; i++) {
        const element = this.preSCForm.controls.otherAttachmentsLoc1.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachmentsLoc1Array.push(element)
        }

      }
      this.preSCForm.value.otherAttachmentsLoc1 = otherAttachmentsLoc1Array;

      for (let i = 0; i < this.preSCForm.controls.protestDetails.value.length; i++) {
        protestSupportingDocLocArray = [];
        const copyFormArray = this.preSCForm.controls.protestDetails.value[i].supportingDocLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestSupportingDocLocArray.push(element)
          }
        }
        this.preSCForm.controls.protestDetails.value[i].supportingDocLoc = protestSupportingDocLocArray;
      }

      this.inrFormattingRemoving();
      // this.letterP1Form.value.dateOfLetter = this.datePipe.transform(this.letterP1Form.value.dateOfLetter, 'yyyy-MM-dd');
      this.preLitService.saveScData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          if (this.isDataSaved === true) {
          }
          this.toaster.showSuccess(response.message);
          this.savedPreCaseId = response.response;
          this.isDataSaved = true;
          this.isPhase2 = true;
          this.isCreateBtn = false;
          this.isUpdateBtn = true;
          this.saveCaseId.emit(this.savedPreCaseId);
          this.initializeData();
        } else {
          this.toaster.showSuccess(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }

  }
  onChallanNoBlur(){
    let protestArray = this.preSCForm.controls.protestDetails as UntypedFormArray
    for (let index = 0; index < protestArray.controls.length; index++){
      let issuesControl = protestArray.controls[index] as UntypedFormGroup;
      let challanControl = issuesControl.get('challanNo') as UntypedFormControl;
      console.log('value of Challan No :',challanControl?.value)
      if (challanControl?.value === '') {
        challanControl.setValue(null);
      }
    } 
  }

  /*Update case*/
  updateCase(phaseTab: any) {
    console.log(this.preSCForm, " preSCForm")
    this.setCaseItemList();
    this.paymentUnderProtestp1Changed();
    this.issueValueChange();
    this.liabilityAddmittedAndPaidChanged();
    this.feesChargedChanged();
    if (phaseTab === 'p1') {
      this.updateIssues();
    }
    if (phaseTab === 'p2') {
      this.updateSubmissions();
      // this.p1validation();
    }
    if (phaseTab === 'p3') {
      this.p2validation();
    }
    this.isSubmitted = true;
    let data: any = {};
    let isTabValid = this.doValidation(phaseTab);
    if (this.preSCForm.valid && isTabValid) {
      if (phaseTab === 'p1') {
        if (this.caseType === 'MEMORANDUM') {
          let dataNoticeLoc = (this.preSCForm.get('appealBookLoc') as UntypedFormArray).controls;
          var count = dataNoticeLoc?.some((x) => x.get('fileLoc')?.value != null || x.get('fileLoc')?.value != "");
          if (!count) {
            this.toaster.showError('Required Fields Are Empty');
            return;
          }
        }
      
        let protestArray = this.preSCForm.controls.protestDetails as UntypedFormArray
        for (let index = 0; index < protestArray.controls.length; index++) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
        
          if (issuesControl.controls.challanNo.value) {
            let supportingDocControls = (issuesControl.get('supportingDocLoc') as UntypedFormArray).controls;
            
            let hasSupportingDoc = supportingDocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
            
            if (!hasSupportingDoc) {
              this.toaster.showError("Required Fields Are Empty for Challan No.");
              return;
            }
          }
        }
      }
      if (phaseTab === 'p2') {
        let submissionsFrmArray = (this.preSCForm.get('submissions') as UntypedFormArray).controls;
        let element1;
        for (let i = 0; i < submissionsFrmArray.length; i++) {
          element1 = (submissionsFrmArray[i].get('copyOfAckLoc') as UntypedFormArray).controls;
        }
        let count = element1?.some((x: any) =>
          x.get('fileLoc')?.value != null);
        if (!count) {
          this.toaster.showError("Required Fields Are Empty");
          return;
        }
      }
      if (phaseTab === 'p3') {
        let dataOioLoc = (this.preSCForm.get('bankChallanLoc') as UntypedFormArray).controls;
        var count = dataOioLoc?.some((x) => x.get('fileLoc')?.value != "");
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
      }
      data = this.preSCForm.value;
      //data.state = this.selectedStateCode;
      let urlData = {
        typeVal: this.tabType[4].key,
        gstin: this.selectedGstin,
      }
      data.caseType = this.caseType;
      data.cumulative = this.cumulativeCount;
      data.prevStage = this.caseData.prevStage;
      if (data.protestDetails && data.protestDetails.length) {
        data.protestDetails.forEach((protestDetail: any) => {
          protestDetail.tax = this.inrService.removeCommasAndParseNumber(protestDetail.tax)
          protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
          protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
          protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
        })
      }

      if (data.consultantCharges && data.consultantCharges.length) {
        data.consultantCharges.forEach((charge: any) => {
          charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
        })
      }
      if (data.bankGuarantee && data.bankGuarantee.length) {
        data.bankGuarantee.forEach((bank: any) => {
          bank.bgAmount = this.inrService.removeCommasAndParseNumber(bank.bgAmount)
          bank.fee = this.inrService.removeCommasAndParseNumber(bank.fee)
        })
      }

      if (data.liabilityDetails && data.liabilityDetails.length) {
        data.liabilityDetails.forEach((liability: any) => {
          liability.tax = this.inrService.removeCommasAndParseNumber(liability.tax)
          liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
          liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
          liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
        })
      }

      if (data.issues && data.issues.length) {
        data.issues.forEach((issue: any) => {
          issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty1)
          issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(issue.serviceTax1)
          issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1)
          issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1)
          issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1)
          issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1)
          issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1)
          issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1)
          issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1)
          issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1)
          issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1)
          issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
          issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1)
          issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
          issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
          issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)

          issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty3)
          issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(issue.serviceTax3)
          issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3)
          issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3)
          issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3)
          issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3)
          issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3)
          issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3)
          issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3)
          issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3)
          issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3)
          issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
          issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3)
          issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
          issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
          issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)
        })
      }

      data.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty1Total)
      data.serviceTax1Total = this.inrService.removeCommasAndParseNumber(data.serviceTax1Total)
      data.ec1Total = this.inrService.removeCommasAndParseNumber(data.ec1Total)
      data.shec1Total = this.inrService.removeCommasAndParseNumber(data.shec1Total)
      data.kkc1Total = this.inrService.removeCommasAndParseNumber(data.kkc1Total)
      data.vat1Total = this.inrService.removeCommasAndParseNumber(data.vat1Total)
      data.cst1Total = this.inrService.removeCommasAndParseNumber(data.cst1Total)
      data.sbc1Total = this.inrService.removeCommasAndParseNumber(data.sbc1Total)
      data.bcd1Total = this.inrService.removeCommasAndParseNumber(data.bcd1Total)
      data.sad1Total = this.inrService.removeCommasAndParseNumber(data.sad1Total)
      data.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty1Total)
      data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)
      data.ent1Total = this.inrService.removeCommasAndParseNumber(data.ent1Total)
      data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total)
      data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total)
      data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1)

      data.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty3Total)
      data.serviceTax3Total = this.inrService.removeCommasAndParseNumber(data.serviceTax3Total)
      data.ec3Total = this.inrService.removeCommasAndParseNumber(data.ec3Total)
      data.shec3Total = this.inrService.removeCommasAndParseNumber(data.shec3Total)
      data.kkc3Total = this.inrService.removeCommasAndParseNumber(data.kkc3Total)
      data.vat3Total = this.inrService.removeCommasAndParseNumber(data.vat3Total)
      data.cst3Total = this.inrService.removeCommasAndParseNumber(data.cst3Total)
      data.sbc3Total = this.inrService.removeCommasAndParseNumber(data.sbc3Total)
      data.bcd3Total = this.inrService.removeCommasAndParseNumber(data.bcd3Total)
      data.sad3Total = this.inrService.removeCommasAndParseNumber(data.sad3Total)
      data.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty3Total)
      data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total)
      data.ent3Total = this.inrService.removeCommasAndParseNumber(data.ent3Total)
      data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total)
      data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total)
      data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal)
      let appealBookLocArray: any[] = [];
      let otherAttachmentsLoc1Array: any[] = [];
      let protestSupportingDocLocArray: any[] = [];
      for (let i = 0; i < this.preSCForm.controls.appealBookLoc.value.length; i++) {
        const element = this.preSCForm.controls.appealBookLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          appealBookLocArray.push(element)
        }

      }
      this.preSCForm.value.appealBookLoc = appealBookLocArray;

      for (let i = 0; i < this.preSCForm.controls.otherAttachmentsLoc1.value.length; i++) {
        const element = this.preSCForm.controls.otherAttachmentsLoc1.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachmentsLoc1Array.push(element)
        }

      }
      this.preSCForm.value.otherAttachmentsLoc1 = otherAttachmentsLoc1Array;



      for (let i = 0; i < this.preSCForm.controls.protestDetails.value.length; i++) {
        protestSupportingDocLocArray = [];
        const copyFormArray = this.preSCForm.controls.protestDetails.value[i].supportingDocLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestSupportingDocLocArray.push(element)
          }
        }
        this.preSCForm.controls.protestDetails.value[i].supportingDocLoc = protestSupportingDocLocArray;
      }
      //phase 2 starts 
      let copyOfAckLocArray: any[] = [];
      let phCopyLocArray: any[] = [];
      let otherAttachmentsLocArray: any[] = [];
      let additionalSubmissionLocArray: any[] = [];
      let phRecordIfAnyLocArray: any[] = [];
      let consultotherAttachmentLocArray: any[] = [];
      let phAdjournmentLetterLocArray: any[] = [];


      for (let i = 0; i < this.preSCForm.controls.submissions.value.length; i++) {
        copyOfAckLocArray = [];
        const copyFormArray = this.preSCForm.controls.submissions.value[i].copyOfAckLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            copyOfAckLocArray.push(element)
          }
        }
        this.preSCForm.controls.submissions.value[i].copyOfAckLoc = copyOfAckLocArray;
      }
      for (let i = 0; i < this.preSCForm.controls.submissions.value.length; i++) {
        otherAttachmentsLocArray = [];
        const copyFormArray = this.preSCForm.controls.submissions.value[i].otherAttachmentsLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            otherAttachmentsLocArray.push(element)
          }
        }
        this.preSCForm.controls.submissions.value[i].otherAttachmentsLoc = otherAttachmentsLocArray;
      }
      for (let i = 0; i < this.preSCForm.controls.itemList.value.length; i++) {
        phCopyLocArray = [];
        const elementPhCopyArray = this.preSCForm.controls.itemList.value[i].phCopyLoc;

        for (let j = 0; j < elementPhCopyArray.length; j++) {
          const element = elementPhCopyArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phCopyLocArray.push(element)
          }
        }
        this.preSCForm.controls.itemList.value[i].phCopyLoc = phCopyLocArray;
      }

      for (let i = 0; i < this.preSCForm.controls.itemList.value.length; i++) {
        additionalSubmissionLocArray = [];
        const elementAddSubArray = this.preSCForm.controls.itemList.value[i].additionalSubmissionLoc;

        for (let j = 0; j < elementAddSubArray.length; j++) {
          const element = elementAddSubArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            additionalSubmissionLocArray.push(element)
          }
        }
        this.preSCForm.controls.itemList.value[i].additionalSubmissionLoc = additionalSubmissionLocArray;
      }
      for (let i = 0; i < this.preSCForm.controls.itemList.value.length; i++) {
        phAdjournmentLetterLocArray = [];
        const phAdjournmentArray = this.preSCForm.controls.itemList.value[i].phAdjournmentLetterLoc;

        for (let j = 0; j < phAdjournmentArray.length; j++) {
          const element = phAdjournmentArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phAdjournmentLetterLocArray.push(element)
          }
        }
        this.preSCForm.controls.itemList.value[i].phAdjournmentLetterLoc = phAdjournmentLetterLocArray;
      }
      for (let i = 0; i < this.preSCForm.controls.itemList.value.length; i++) {
        phRecordIfAnyLocArray = [];
        const phRecordArray = this.preSCForm.controls.itemList.value[i].phRecordIfAnyLoc;

        for (let j = 0; j < phRecordArray.length; j++) {
          const element = phRecordArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phRecordIfAnyLocArray.push(element)
          }
        }
        this.preSCForm.controls.itemList.value[i].phRecordIfAnyLoc = phRecordIfAnyLocArray;
      }
      for (let i = 0; i < this.preSCForm.controls.consultantCharges.value.length; i++) {
        consultotherAttachmentLocArray = [];
        const copyFormArray = this.preSCForm.controls.consultantCharges.value[i].otherAttachmentLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            consultotherAttachmentLocArray.push(element)
          }
        }
        this.preSCForm.controls.protestDetails.value[i].otherAttachmentLoc = consultotherAttachmentLocArray;
      }
      //phase 2 ends 
      //phase 3 starts
      let bankChallanLocArray: any[] = [];
      for (let i = 0; i < this.preSCForm.controls.bankChallanLoc.value.length; i++) {
        const element = this.preSCForm.controls.bankChallanLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          bankChallanLocArray.push(element)
        }

      }
      this.preSCForm.value.bankChallanLoc = bankChallanLocArray;
      let LiabilitySupportingDocArray: any[] = [];
      for (let i = 0; i < this.preSCForm.controls.liabilityDetails.value.length; i++) {
        LiabilitySupportingDocArray = [];
        const copyFormArray = this.preSCForm.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            LiabilitySupportingDocArray.push(element)
          }
        }
        this.preSCForm.controls.liabilityDetails.value[i].copyOfFormLoc = LiabilitySupportingDocArray;
      }
      //phase 3 ends

      this.inrFormattingRemoving();
      this.preLitService.updateScData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          if (phaseTab === 'p1') {
            this.isPhase2 = true;
          } else if (phaseTab === 'p2') {
            this.isPhase3 = true;
          } else if (phaseTab === 'p3') {
            if (this.preSCForm.value.whetherAppealPreferred === true || this.preSCForm.value.whetherAppealPreferred === 'true') {
              this.preLitService.setAccessTabData({ preajaFlag: true, preapaFlag: true, preaptFlag: true, prehcFlag: true, prescFlag: true });
              this.callTabFunction.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState("", "", url);
            } else {
              this.router.navigate(['/preGstSummary']);
            }
          }
          this.toaster.showSuccess(response.message);
          //this.isDataSaved = true;
        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }



  //fetch Registration Number case
  getFileNameDta(fileUrl: any) {
    if (fileUrl && typeof (fileUrl) == 'string')
      return this.preLitService.getFileName(fileUrl);

  }
  /*upload file selection */
  onFileSelect(event: any, isFromAppealDetails: boolean = false) {
    this.selectedField = event.target.getAttribute('id')
    if (this.preSCForm.value['bankChallanLoc'] !== null || this.preSCForm.value['bankChallanLoc'] !== '') {
      this.supremeCourtOrderLocReq = false;
    }
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        // this.formData = new FormData();
        // this.formData.append('fname', this.selectedFile);
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, isFromAppealDetails);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.selectedFile = null;
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  // onUpload(selectedfield: any, isFromAppealDetails:boolean = false) {
  //   let requestBody: any = new FormData();
  //   requestBody.append('caseId', this.caseId ? this.caseId : this.savedPreCaseId);
  //   requestBody.append('type', this.tabType[4].key);
  //   requestBody.append('field', selectedfield);
  //   requestBody.append('fmime', this.selectedFile.type);
  //   requestBody.append('fname', this.selectedFile.name);
  //   requestBody.append('file', this.selectedFile);
  //   this.preLitService.uploadFile(requestBody).subscribe((response: any) => {
  //     if (response && response.status === 1) {
  //       this.setFormData(response.response, selectedfield, isFromAppealDetails)
  //     }
  //   })
  // }

  onUpload(selectedfield: any, isFromAppealDetails: boolean = false) {
    let urlData = {
      gstinOrPan: this.preSCForm.value.pan,
      type: this.tabType[4].key,
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.preLitService.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        //this.fileUrl = response.url;
        //this.filePath = response.path;
        this.getFileUrl(response.response.url, response.response.path, selectedfield, isFromAppealDetails);
      } else {
      }
    })
  }

  // setFormData(path: any, selectedfield: any, isFromAppealDetails:boolean = false) {
  getFileUrl(url: any, path: any, selectedfield: any, isFromAppealDetails: boolean = false) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      console.log("response receved is ", response);
      if (this.selectedFile && this.formGrp.itemList.value && this.matches && !isFromAppealDetails) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.itemList.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;
              this.savecaseItemList();
            }
          }
        });
      } else if (this.selectedFile && this.matches != null && this.matches.input.includes('otherAttachmentLoc')) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.consultantCharges.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;
              this.saveConsultantChargesList();
            }
          }
        });
      } else if (this.selectedFile && this.matches != null && this.matches.input.includes('supportingDocLoc')) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.protestDetails.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;
              this.savePortestlDetailList();
            }
          }
        });
      } else if (this.selectedFile && this.matches != null && this.matches.input.includes('copyOfFormLoc')) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.liabilityDetails.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;
              this.saveLiabilityDetailsList();
            }
          }
        });
      } else if (this.selectedFile && this.matches && isFromAppealDetails) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.submissions.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path;
              this.saveAppealDetailList();
            }
          }
        });
      } if (selectedfield) {
        var toupdate = this.preSCForm.value;
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.preSCForm.patchValue(this.preSCForm.value);
      }
      else {
        let field = selectedfield;
        let adjForm = this.preSCForm.value;
        adjForm[field] = path;
        if (this.caseData == null) {
          this.caseData = {};
        }
        this.caseData[field] = path;
        this.preSCForm.patchValue(adjForm);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }

  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
      // path.push(pathChunk.substr(0, indexOfBrc));
      // path.push(pathChunk.charAt(indexOfBrc + 1));
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.preLitService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');

      } else {
      }
    })
  }
  // Divison Block
  gstinDivData() {
    this.bussinessService.getGstinDiv().subscribe((response: any) => {
      if (response.status === 1) {
        this.preGstinDivList = response.response.divList;
      }
    });
  }
  // uploadCopyOfAckLoc() {
  //   this.copyOfAckLocDocFile = true;
  //   this.copyOfAckLocDocText = false;
  //   this.copyOfAckLocDWText = true;
  //   this.copyOfAckLocUPText = false;
  // }

  // uploadotherAttachments() {
  //   this.isOtherAttachmentFile = true;
  //   this.isOtherAttachmentText = false;
  //   this.isOtherAttachmentDWText = true;
  //   this.isOtherAttachmentUPText = false;
  // }



  uploadotherattachmentsLoc() {
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLoc = true;
    this.isconsultantUPattachmentsLoc = false;
  }


  uploadbankChallanLoc1() {
    this.isbankChallanLoc1File = true;
    this.isbankChallanLoc1Text = false;
    this.isDWbankChallanLoc1 = false;
    this.isUPbankChallanLoc1 = false;
  }

  uploadappealBookLoc1() {
    this.isappealBookLoc1File = true;
    this.isappealBookLoc1Text = false;
    this.isDWappealBookLoc1 = true;
    this.isUPappealBookLoc1 = false;
  }

  uploaddrc03CopyLoc1() {
    this.isdrc03CopyLoc1File = true;
    this.isdrc03CopyLoc1Text = false;
    this.isDWdrc03CopyLoc1 = false;
    this.isUPdrc03CopyLoc1 = false;
  }

  uploadcopyOfAckLoc() {
    this.iscopyOfAckLocFile = true;
    this.iscopyOfAckLocText = false;
    this.isDWcopyOfAckLoc = false;
    this.isUPcopyOfAckLoc = false;
  }

  uploadotherAttachmentsLoc1() {
    this.isotherAttachmentsLoc1File = true;
    this.isotherAttachmentsLoc1Text = false;
    this.isDWotherAttachmentsLoc1 = false;
    this.isUPotherAttachmentsLoc1 = false;
    this.preSCForm.value.otherAttachmentsLoc1 = null;
  }

  uploadotherAttachmentsLoc2() {
    this.isotherAttachmentsLoc2File = true;
    this.isotherAttachmentsLoc2Text = false;
    this.isDWotherAttachmentsLoc2 = false;
    this.isUPotherAttachmentsLoc2 = false;
  }

  uploadphCopyLoc(index: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;
    let itemListArray = this.preSCForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (let i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          let issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhCopyLocUploaded'] = true
        }
      }
    }
  }

  uploadadditionalSubmissionLoc(index: any) {
    this.isadditionalSubmissionLocFile = true;
    this.isadditionalSubmissionLocText = false;
    this.isDWadditionalSubmissionLoc = false;
    this.isUPadditionalSubmissionLoc = false;
    var itemListArray = this.preSCForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isAdditionalSubmissionLocUploaded'] = true
        }
      }
    }
  }

  uploadphRecordIfAnyLoc(index: any) {
    this.isphRecordIfAnyLocFile = true;
    this.isphRecordIfAnyLocText = false;
    this.isDWphRecordIfAnyLoc = false;
    this.isUPphRecordIfAnyLoc = false;
    var itemListArray = this.preSCForm.controls.itemList as UntypedFormArray
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhRecordIfAnyLocUploaded'] = true
        }
      }
    }
  }
  uploadsupremeCourtOrderLoc() {
    this.issupremeCourtOrderLocFile = true;
    this.issupremeCourtOrderLocText = false;
    this.isDWsupremeCourtOrderLoc = false;
    this.isUPsupremeCourtOrderLoc = false;
  }

  uploadbankChallanLoc() {
    this.isbankChallanLocFile = true;
    this.isbankChallanLocText = false;
    this.isDWbankChallanLocLoc = false;
    this.isUPbankChallanLocLoc = false;
  }

  uploaddrc03CopyLoc() {
    this.isdrc03CopyLocFile = true;
    this.isdrc03CopyLocText = false;
    this.isDWdrc03CopyLoc = false;
    this.isUPdrc03CopyLoc = false;
  }

  // uploadSupporting() {
  //   this.isSupportingFile = true;
  //   this.isSupportingText = false;
  //   this.isSupportingDWText = true;
  //   this.isSupportingUPText = false;
  // }
  rmWeatherApplErrorBorder() {
    this.someElement = document.getElementById("whetApplPref");
    this.someElement.classList.remove("errorBorder");
  }
  rmWeatherWritErrorBorder() {
    this.someElement = document.getElementById("whetWritPref");
    this.someElement.classList.remove("errorBorder");
  }
  // checkAppYes(event: any){
  //   if (event.target.value === "true") {
  //     this.disabledWeatherWrit = true;
  //     this.disabledWeatherDept = true;
  //     this.preSCForm.get("writAppPreferred")?.clearValidators();
  //     this.preSCForm.get("writAppPreferred")?.updateValueAndValidity();
  //     this.preSCForm.get("writAppPreferred")?.setValue('false');
  //     this.preSCForm.get("whetherDptAppealPreferred")?.clearValidators();
  //     this.preSCForm.get("whetherDptAppealPreferred")?.updateValueAndValidity();
  //     this.preSCForm.get("whetherDptAppealPreferred")?.setValue('false');
  //     // this.adjP1Form.controls['writAppPreferred'].setErrors({'required': false});
  //     // this.rmWeatherWritErrorBorder();
  //   }else{
  //     this.disabledWeatherWrit = false;
  //     this.disabledWeatherDept = false;
  //   }
  // }

  getperiodToValue(content: any){
    if(this.action === "create"){
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = localStorage.getItem('selectedId'),
    model.module = "PREGST",
    model.actType = this.selectedActType,
    model.criterias= [
      {
          "p": "periodFromDate",
          "o": "between",
          "v1": this.preSCForm.value.periodFromDate,
          "v2":  this.preSCForm.value.periodToDate,
      },{
          "p": "periodToDate",
          "o": "between",
          "v1": this.preSCForm.value.periodFromDate,
          "v2": this.preSCForm.value.periodToDate,
      },
      {
        "p" : "actType",
        "o" : "in",
        "v" :  this.selectedActType,
    }
  ]
  this.litService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.response.length === 0) {
        this.modalService.dismissAll();
      }
      else{
        this.duplicateData = response?.response;
         this.duplicateData?.forEach((obj: any, index: number) => {
          if (obj.forum === 'Appellate Authority')
            this.duplicateData[index].forum = 'Commissioner (A)'
        })
       this.modalService.open(content)
      }
      
    });
  }
  }
  
  goToSummary(){
    this.router.navigate(['/preGstSummary']);
    this.modalService.dismissAll();
  }


  changeOutcome(event: any = null) {
    let data;
    if (!event) {
      data = this.preSCForm.value.outcome;
    } else {
      data = event.target.value;
    }
    if (data === "Favorable") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Favorable');
      var issues1Array = this.preSCForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
          issues1Control.controls.status.setValue(data);
        }
      }
    }
    else if (data === "Unfavorable") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Unfavorable' || x.value == 'Admitted');
      // var issues1Array = this.preSCForm.controls.issues as FormArray
      // if(issues1Array.controls.length > 0) {
      //   for(var i = 0; i < issues1Array.controls.length; i++){
      //       var issues1Control = issues1Array.controls[i] as FormGroup;
      //       issues1Control.controls.status.setValue(data);
      //   }
      // }
    } else if (data === "Remanded Back") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Remanded Back');
      var issues1Array = this.preSCForm.controls.issues as UntypedFormArray
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
          issues1Control.controls.status.setValue(data);
        }
      }
    } else if (data === "Partly Favorable") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Partly Favorable' || x.value == 'Favorable' || x.value == 'Unfavorable' || x.value == 'Partly Unfavorable' || x.value == 'Admitted');
    } else if (data === "Partly Remanded Back") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Partly Remanded Back' || x.value == 'Favorable' || x.value == 'Unfavorable' || x.value == 'Partly Favorable' || x.value == 'Partly Unfavorable' || x.value == 'Remanded Back' || x.value == 'Admitted');
    }
    else if (data === "Partly Unfavorable") {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONERS.filter((x) => x.value == 'Partly Favorable' || x.value == 'Favorable' || x.value == 'Unfavorable' || x.value == 'Partly Unfavorable' || x.value == 'Admitted');
    }
  }
  issueDropdown() {
    this.dataSource = PREGST_ISSUE_DROPDOWN;;
  }

  savecaseItemList() {
    const itemList = this.preSCForm.value.itemList;
    if (itemList.length > 0) {
      itemList.forEach((item: any, i: number) => {
        if (!this.caseItemList[i]) {
          this.caseItemList.push({});
        }
        if (item.phCopyLoc) {
          this.caseItemList[i].phCopyLoc = item.phCopyLoc;
        }
        if (item.additionalSubmissionLoc) {
          this.caseItemList[i].additionalSubmissionLoc = item.additionalSubmissionLoc;

        }
        if (item.phRecordIfAnyLoc) {
          this.caseItemList[i].phRecordIfAnyLoc = item.phRecordIfAnyLoc;

        }
        if (item.phAdjournmentLetterLoc) {
          this.caseItemList[i].phAdjournmentLetterLoc = item.phAdjournmentLetterLoc;
        }
      })

      // this.caseItemList.push()
    }
  }

  setCaseItemList() {
    this.preSCForm.value.itemList.forEach((data: any, i: number) => {
      if (!data.phCopyLoc) {
        data.phCopyLoc = this.caseItemList[i]?.phCopyLoc;
      }
      if (!data.additionalSubmissionLoc) {
        data.additionalSubmissionLoc = this.caseItemList[i]?.additionalSubmissionLoc;

      }
      if (!data.phRecordIfAnyLoc) {
        data.phRecordIfAnyLoc = this.caseItemList[i]?.phRecordIfAnyLoc;

      }
      if (!data.phAdjournmentLetterLoc) {
        data.phAdjournmentLetterLoc = this.caseItemList[i]?.phAdjournmentLetterLoc;
      }
    })
  }
  saveAppealDetailList() {
    const appealDetailList = this.preSCForm.value.submissions;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.copyOfAckLoc) {
          this.appealDetailItemList[i].copyOfAckLoc = item.copyOfAckLoc;
        }
        if (item.otherAttachmentsLoc) {
          this.appealDetailItemList[i].otherAttachmentsLoc = item.otherAttachmentsLoc;
        }
      })
    }
    this.preSCForm.controls.submissions.patchValue(appealDetailList);

  }

  setAppealDetailItemList() {
    this.preSCForm.value.submissions.forEach((data: any, i: number) => {
      if (!data.copyOfAckLoc) {
        data.copyOfAckLoc = this.appealDetailItemList[i]?.copyOfAckLoc;
      }

      if (!data.otherAttachmentsLoc) {
        data.otherAttachmentsLoc = this.appealDetailItemList[i]?.otherAttachmentsLoc;
      }
    })
  }

  saveConsultantChargesList() {
    const appealDetailList = this.preSCForm.value.consultantCharges;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.otherAttachmentLoc) {
          this.appealDetailItemList[i].otherAttachmentLoc = item.otherAttachmentLoc;
        }
      })
    }
    this.preSCForm.controls.consultantCharges.patchValue(appealDetailList);
  }
  setConsultantChargesList() {
    this.preSCForm.value.consultantCharges.forEach((data: any, i: number) => {
      if (!data.otherAttachmentLoc) {
        data.otherAttachmentLoc = this.appealDetailItemList[i]?.otherAttachmentLoc;
      }
    })
  }

  savePortestlDetailList() {
    const appealDetailList = this.preSCForm.value.protestDetails;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.supportingDocLoc) {
          this.appealDetailItemList[i].supportingDocLoc = item.supportingDocLoc;
        }
      })
    }
    this.preSCForm.controls.protestDetails.patchValue(appealDetailList);
  }
  setPortestlDetailList() {
    this.preSCForm.value.protestDetails.forEach((data: any, i: number) => {
      if (!data.supportingDocLoc) {
        data.supportingDocLoc = this.appealDetailItemList[i]?.supportingDocLoc;
      }
    })
  }

  // lability data
  saveLiabilityDetailsList() {
    const appealDetailList = this.preSCForm.value.liabilityDetails;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.copyOfFormLoc) {
          this.appealDetailItemList[i].copyOfFormLoc = item.copyOfFormLoc;
        }
      })
    }
    this.preSCForm.controls.liabilityDetails.patchValue(appealDetailList);
  }
  setLiabilityDetailsList() {
    this.preSCForm.value.liabilityDetails.forEach((data: any, i: number) => {
      if (!data.copyOfFormLoc) {
        data.copyOfFormLoc = this.appealDetailItemList[i]?.copyOfFormLoc;
      }
    })
  }


  finYearSelelctEvent(i: any) {
    let issuesFrmArray = this.preSCForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    const alreadySelectedFinYear = detailsFrmArray.value?.map((data: any) => data.finYear);
    this.finyearData.forEach(f => {
      if (alreadySelectedFinYear.includes(f.year)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }
  quarterSelectEvent(i: any) {
    let issuesFrmArray = this.preSCForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get('details') as UntypedFormArray;
    const alreadySelectedQuarter = detailsFrmArray.value?.map((data: any) => data.quarter);
    this.quarterList.forEach((f: any) => {
      if (alreadySelectedQuarter.includes(f.value)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }
  onKeyDownEvent(event: any, type: any) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      if (type === 'protestDetails') {
        this.paymentUnderProtestp1Changed();
      }
      if (type === 'issues') {
        this.issueValueChange();
      }
      if (type === 'liabilityDetails') {
        this.liabilityAddmittedAndPaidChanged();
      }
      if (type === 'consultantCharges') {
        this.feesChargedChanged()
      }
    }
  }

  onPaste(event: any, id: any, tableName: any, index: any) {
    let keyCodeEvent = { keyCode: 8 }
    event.preventDefault();
    let value: any = Math.round(+event.clipboardData.getData('Text'));
    let data = this.preSCForm.get(tableName)?.value;
    data[index][id] = value;
    this.preSCForm.get(tableName)?.setValue(data)
    this.onKeyDownEvent(keyCodeEvent, tableName);
  }

  issueValueChange() {
    let exciseDuty1Total = 0;
    let serviceTax1Total = 0;
    let ec1Total = 0;
    let shec1Total = 0;
    let kkc1Total = 0;
    let sbc1Total = 0;
    let vat1Total = 0;
    let cst1Total = 0;
    let bcd1Total = 0;
    let sad1Total = 0;
    let antiDumpingDuty1Total = 0;
    let igst1Total = 0;
    let ent1Total = 0;
    let interest1Total = 0;
    let penalty1Total = 0;

    let exciseDuty3Total = 0;
    let serviceTax3Total = 0
    let ec3Total = 0;
    let shec3Total = 0;
    let kkc3Total = 0;
    let sbc3Total = 0;
    let vat3Total = 0;
    let cst3Total = 0;
    let bcd3Total = 0;
    let sad3Total = 0;
    let antiDumpingDuty3Total = 0;
    let igst3Total = 0;
    let ent3Total = 0;
    let interest3Total = 0;
    let penalty3Total = 0;

    (this.preSCForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      const exciseDuty1 = typeof c.value.exciseDuty1 === 'string' ? c.value.exciseDuty1.replace(/,/g, '') : c.value.exciseDuty1;
      const serviceTax1 = typeof c.value.serviceTax1 === 'string' ? c.value.serviceTax1.replace(/,/g, '') : c.value.serviceTax1;
      const ec1 = typeof c.value.ec1 === 'string' ? c.value.ec1.replace(/,/g, '') : c.value.ec1;
      const shec1 = typeof c.value.shec1 === 'string' ? c.value.shec1.replace(/,/g, '') : c.value.shec1;
      const kkc1 = typeof c.value.kkc1 === 'string' ? c.value.kkc1.replace(/,/g, '') : c.value.kkc1;
      const sbc1 = typeof c.value.sbc1 === 'string' ? c.value.sbc1.replace(/,/g, '') : c.value.sbc1;
      const vat1 = typeof c.value.vat1 === 'string' ? c.value.vat1.replace(/,/g, '') : c.value.vat1;
      const cst1 = typeof c.value.cst1 === 'string' ? c.value.cst1.replace(/,/g, '') : c.value.cst1;
      const bcd1 = typeof c.value.bcd1 === 'string' ? c.value.bcd1.replace(/,/g, '') : c.value.bcd1;
      const sad1 = typeof c.value.sad1 === 'string' ? c.value.sad1.replace(/,/g, '') : c.value.sad1;
      const antiDumpingDuty1 = typeof c.value.antiDumpingDuty1 === 'string' ? c.value.antiDumpingDuty1.replace(/,/g, '') : c.value.antiDumpingDuty1;
      const igst1 = typeof c.value.igst1 === 'string' ? c.value.igst1.replace(/,/g, '') : c.value.igst1;
      const ent1 = typeof c.value.ent1 === 'string' ? c.value.ent1.replace(/,/g, '') : c.value.ent1;
      const interest1 = typeof c.value.interest1 === 'string' ? c.value.interest1.replace(/,/g, '') : c.value.interest1;
      const penalty1 = typeof c.value.penalty1 === 'string' ? c.value.penalty1.replace(/,/g, '') : c.value.penalty1;

      const exciseDuty3 = typeof c.value.exciseDuty3 === 'string' ? c.value.exciseDuty3.replace(/,/g, '') : c.value.exciseDuty3;
      const serviceTax3 = typeof c.value.serviceTax3 === 'string' ? c.value.serviceTax3.replace(/,/g, '') : c.value.serviceTax3;
      const ec3 = typeof c.value.ec3 === 'string' ? c.value.ec3.replace(/,/g, '') : c.value.ec3;
      const shec3 = typeof c.value.shec3 === 'string' ? c.value.shec3.replace(/,/g, '') : c.value.shec3;
      const kkc3 = typeof c.value.kkc3 === 'string' ? c.value.kkc3.replace(/,/g, '') : c.value.kkc3;
      const sbc3 = typeof c.value.sbc3 === 'string' ? c.value.sbc3.replace(/,/g, '') : c.value.sbc3;
      const vat3 = typeof c.value.vat3 === 'string' ? c.value.vat3.replace(/,/g, '') : c.value.vat3;
      const cst3 = typeof c.value.cst3 === 'string' ? c.value.cst3.replace(/,/g, '') : c.value.cst3;
      const bcd3 = typeof c.value.bcd3 === 'string' ? c.value.bcd3.replace(/,/g, '') : c.value.bcd3;
      const sad3 = typeof c.value.sad3 === 'string' ? c.value.sad3.replace(/,/g, '') : c.value.sad3;
      const antiDumpingDuty3 = typeof c.value.antiDumpingDuty3 === 'string' ? c.value.antiDumpingDuty3.replace(/,/g, '') : c.value.antiDumpingDuty3;
      const igst3 = typeof c.value.igst3 === 'string' ? c.value.igst3.replace(/,/g, '') : c.value.igst3;
      const ent3 = typeof c.value.ent3 === 'string' ? c.value.ent3.replace(/,/g, '') : c.value.ent3;
      const interest3 = typeof c.value.interest3 === 'string' ? c.value.interest3.replace(/,/g, '') : c.value.interest3;
      const penalty3 = typeof c.value.penalty3 === 'string' ? c.value.penalty3.replace(/,/g, '') : c.value.penalty3;

      exciseDuty1Total += Number(exciseDuty1);
      serviceTax1Total += Number(serviceTax1);
      ec1Total += Number(ec1);
      shec1Total += Number(shec1);
      kkc1Total += Number(kkc1);
      sbc1Total += Number(sbc1);
      vat1Total += Number(vat1);
      cst1Total += Number(cst1);
      bcd1Total += Number(bcd1);
      sad1Total += Number(sad1);
      antiDumpingDuty1Total += Number(antiDumpingDuty1);
      igst1Total += Number(igst1);
      ent1Total += Number(ent1);
      interest1Total += Number(interest1);
      penalty1Total += Number(penalty1);

      exciseDuty3Total += Number(exciseDuty3);
      serviceTax3Total += Number(serviceTax3);
      ec3Total += Number(ec3);
      shec3Total += Number(shec3);
      kkc3Total += Number(kkc3);
      sbc3Total += Number(sbc3);
      vat3Total += Number(vat3);
      cst3Total += Number(cst3);
      bcd3Total += Number(bcd3);
      sad3Total += Number(sad3);
      antiDumpingDuty3Total += Number(antiDumpingDuty3);
      igst3Total += Number(igst3);
      ent3Total += Number(ent3);
      interest3Total += Number(interest3);
      penalty3Total += Number(penalty3);

      c.patchValue({ total1: this.inrService.formatCurrency(String(Number(serviceTax1) + Number(exciseDuty1) + Number(ec1) + Number(shec1) + Number(kkc1) + Number(sbc1) + Number(vat1) + Number(cst1) + Number(bcd1)+ Number(sad1) + Number(antiDumpingDuty1) + Number(igst1) + Number(ent1) + Number(interest1) + Number(penalty1))) });
      c.patchValue({ total3: this.inrService.formatCurrency(String(Number(serviceTax3) + Number(exciseDuty3) + Number(ec3) + Number(shec3) + Number(kkc3) + Number(sbc3) + Number(vat3) + Number(cst3) + Number(bcd3) + Number(sad3) + Number(antiDumpingDuty3) + Number(igst3) + Number(ent3) + Number(interest3) + Number(penalty3))) });

    })

    this.preSCForm.controls['exciseDuty1Total'].setValue(this.inrService.formatCurrency(String(exciseDuty1Total)));
    this.preSCForm.controls['serviceTax1Total'].setValue(this.inrService.formatCurrency(String(serviceTax1Total)));
    this.preSCForm.controls['ec1Total'].setValue(this.inrService.formatCurrency(String(ec1Total)));
    this.preSCForm.controls['shec1Total'].setValue(this.inrService.formatCurrency(String(shec1Total)));
    this.preSCForm.controls['kkc1Total'].setValue(this.inrService.formatCurrency(String(kkc1Total)));
    this.preSCForm.controls['sbc1Total'].setValue(this.inrService.formatCurrency(String(sbc1Total)));
    this.preSCForm.controls['vat1Total'].setValue(this.inrService.formatCurrency(String(vat1Total)));
    this.preSCForm.controls['cst1Total'].setValue(this.inrService.formatCurrency(String(cst1Total)));
    this.preSCForm.controls['bcd1Total'].setValue(this.inrService.formatCurrency(String(bcd1Total)));
    this.preSCForm.controls['sad1Total'].setValue(this.inrService.formatCurrency(String(sad1Total)));
    this.preSCForm.controls['antiDumpingDuty1Total'].setValue(this.inrService.formatCurrency(String(antiDumpingDuty1Total)));
    this.preSCForm.controls['igst1Total'].setValue(this.inrService.formatCurrency(String(igst1Total)));
    this.preSCForm.controls['ent1Total'].setValue(this.inrService.formatCurrency(String(ent1Total)));
    this.preSCForm.controls['interest1Total'].setValue(this.inrService.formatCurrency(String(interest1Total)));
    this.preSCForm.controls['penalty1Total'].setValue(this.inrService.formatCurrency(String(penalty1Total)));

    this.preSCForm.controls['exciseDuty3Total'].setValue(this.inrService.formatCurrency(String(exciseDuty3Total)));
    this.preSCForm.controls['serviceTax3Total'].setValue(this.inrService.formatCurrency(String(serviceTax3Total)));
    this.preSCForm.controls['ec3Total'].setValue(this.inrService.formatCurrency(String(ec3Total)));
    this.preSCForm.controls['shec3Total'].setValue(this.inrService.formatCurrency(String(shec3Total)));
    this.preSCForm.controls['kkc3Total'].setValue(this.inrService.formatCurrency(String(kkc3Total)));
    this.preSCForm.controls['sbc3Total'].setValue(this.inrService.formatCurrency(String(sbc3Total)));
    this.preSCForm.controls['vat3Total'].setValue(this.inrService.formatCurrency(String(vat3Total)));
    this.preSCForm.controls['cst3Total'].setValue(this.inrService.formatCurrency(String(cst3Total)));
    this.preSCForm.controls['bcd3Total'].setValue(this.inrService.formatCurrency(String(bcd3Total)));
    this.preSCForm.controls['sad3Total'].setValue(this.inrService.formatCurrency(String(sad3Total)));
    this.preSCForm.controls['antiDumpingDuty3Total'].setValue(this.inrService.formatCurrency(String(antiDumpingDuty3Total)));
    this.preSCForm.controls['igst3Total'].setValue(this.inrService.formatCurrency(String(igst3Total)));
    this.preSCForm.controls['ent3Total'].setValue(this.inrService.formatCurrency(String(ent3Total)));
    this.preSCForm.controls['interest3Total'].setValue(this.inrService.formatCurrency(String(interest3Total)));
    this.preSCForm.controls['penalty3Total'].setValue(this.inrService.formatCurrency(String(penalty3Total)));

    this.preSCForm.controls['totOfTotal1'].setValue(this.inrService.formatCurrency(String(Number((+exciseDuty1Total) + (+serviceTax1Total) + (+ec1Total) + (+shec1Total) + (+kkc1Total) + (+sbc1Total) + (+vat1Total) + (+cst1Total) + (+bcd1Total) +(+sad1Total)  + (+antiDumpingDuty1Total)  + (+igst1Total) + (+ent1Total) + (+interest1Total) + (+penalty1Total)))));
    this.preSCForm.controls['totOfTotal3'].setValue(this.inrService.formatCurrency(String(Number((+exciseDuty3Total) + (+serviceTax3Total) + (+ec3Total) + (+shec3Total) + (+kkc3Total) + (+sbc3Total) + (+vat3Total) + (+cst3Total) + (+bcd3Total) +(sad3Total)  + (+antiDumpingDuty3Total)  + (+igst3Total) + (+ent3Total) + (+interest3Total) + (+penalty3Total)))));

  }

  // setCummulativeCount(){
  //   let protestPaymentTax = 0;
  //   let protestPaymentPenalty   = 0;
  //   let protestPaymentInterest = 0;
  //   let protestPaymentTotal = 0;

  //   let liabilityPaymentTax = 0
  //   let liabilityPaymentPenalty = 0;
  //   let liabilityPaymentInterest = 0;
  //   let liabilityPaymentTotal = 0;

  //   let consultantFees = 0;


  //  this.preSCForm.value.protestDetails.forEach((p : any) =>{
  //    p.tax ? protestPaymentTax += p.tax : null;
  //    p.penalty ? protestPaymentPenalty += p.penalty : null;
  //    p.interest ? protestPaymentInterest += p.interest : null;
  //    p.total ? protestPaymentTotal +=p.total : null;
  //  });
  //  this.preSCForm.value.liabilityDetails.forEach((p : any) =>{
  //    p.tax ? liabilityPaymentTax += p.tax : null;
  //    p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //    p.interest ? liabilityPaymentInterest += p.interest : null;
  //    p.total ? liabilityPaymentTotal +=p.total : null;
  //  });


  //  if(this.caseData && this.caseData?.cumulative?.length > 0){

  //   this.caseData.cumulative.forEach((p: any) =>{
  //     if(p.label === 'Cumulative payment made under protest'){
  //       p.tax ? protestPaymentTax += p.tax : null;
  //       p.penalty ? protestPaymentPenalty += p.penalty : null;
  //       p.interest ? protestPaymentInterest += p.interest : null;
  //       p.total ? protestPaymentTotal +=p.total : null;
  //     }else if(p.label === 'Cumulative payment made for admitted liability'){
  //       p.tax ? liabilityPaymentTax += p.tax : null;
  //       p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //       p.interest ? liabilityPaymentInterest += p.interest : null;
  //       p.total ? liabilityPaymentTotal +=p.total : null;
  //     }else if(p.label === 'Cumulative Consultant Charges'){
  //       p.fees ? consultantFees += p.fees : null;

  //     }
  //     })
  //  }

  //  this.preSCForm.value.consultantCharges.forEach((c : any) =>{
  //    c.fees ? consultantFees += c.fees : null;
  //  });
  //  this.cumulativeCount.forEach((c: any) =>{

  //      if(c.label === 'Cumulative payment made under protest'){
  //        protestPaymentTax ?  c.tax = protestPaymentTax  : null;
  //        protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
  //        protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
  //        protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
  //      }else if(c.label === 'Cumulative payment made for admitted liability '){
  //        liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
  //        liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
  //        liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
  //        liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
  //      }else if(c.label === 'Cumulative Consultant Charges'){
  //        consultantFees ? c.total = consultantFees  : null;
  //      }
  //  });
  // }

  setCummulativeCount(oldArr: any = null, newArr: any = null, tableName: any = null) {
    //   let protestPaymentTax = 0;
    //   let protestPaymentPenalty   = 0;
    //   let protestPaymentInterest = 0;
    //   let protestPaymentTotal = 0;

    //   let liabilityPaymentTax = 0
    //   let liabilityPaymentPenalty = 0;
    //   let liabilityPaymentInterest = 0;
    //   let liabilityPaymentTotal = 0;

    //   let consultantFees = 0;

    //   let preDepositeTax = 0;
    //   let preDepositePenalty = 0;
    //   let preDepositeInterest = 0;
    //   let preDepositeTotal = 0;

    //   let oldTotalTax :any= 0, oldTotalInterrest :any= 0 , oldTotalPenalty:any = 0;
    //   let newTotalTax :any= 0, newTotalInterrest :any= 0 , newTotalPenalty:any = 0;
    //   let oldFees :any=0, newFees:any= 0;

    //   // if(this.selectedRemandedBackCaseDetail && this.selectedRemandedBackCaseDetail?.cumulative?.length > 0){
    //   //   this.selectedRemandedBackCaseDetail.cumulative.forEach((p: any) =>{
    //   //     if(p.label === 'Cumulative payment made under protest'){
    //   //       p.tax ? protestPaymentTax += p.tax : null;
    //   //       p.penalty ? protestPaymentPenalty += p.penalty : null;
    //   //       p.interest ? protestPaymentInterest += p.interest : null;
    //   //       p.protestPaymentTotal ? protestPaymentTotal +=p.protestPaymentTotal : null;
    //   //     }else if(p.label === 'Cumulative payment made for admitted liability'){
    //   //       p.tax ? liabilityPaymentTax += p.tax : null;
    //   //       p.penalty ? liabilityPaymentPenalty += p.penalty : null;
    //   //       p.interest ? liabilityPaymentInterest += p.interest : null;
    //   //       p.total ? liabilityPaymentTotal +=p.total : null;
    //   //     }else if(p.label === 'Cumulative Consultant Charges'){
    //   //       p.fees ? consultantFees += p.fees : null;
    //   //     }
    //   //     })
    //   // }

    //   if(this.caseData.cumulative && this.caseData.cumulative.length > 0){
    //     this.caseData.cumulative.forEach((p: any) =>{
    //       if(p.label === 'Cumulative payment made under protest'){
    //         p.tax ? protestPaymentTax += p.tax : null;
    //         p.penalty ? protestPaymentPenalty += p.penalty : null;
    //         p.interest ? protestPaymentInterest += p.interest : null;
    //         p.total ? protestPaymentTotal +=p.total : null;
    //       }else if(p.label === 'Cumulative payment made for admitted liability'){
    //         p.tax ? liabilityPaymentTax += p.tax : null;
    //         p.penalty ? liabilityPaymentPenalty += p.penalty : null;
    //         p.interest ? liabilityPaymentInterest += p.interest : null;
    //         p.total ? liabilityPaymentTotal +=p.total : null;
    //       }else if(p.label === 'Cumulative Consultant Charges'){
    //         p.total ? consultantFees += p.total : null;
    //       }
    //       })
    //    }

    //   //array is not null and empty
    //   if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== 'consultantCharges'){
    //     oldArr.forEach((p : any) =>{
    //       if( p.tax != null)
    //       oldTotalTax +=  p.tax ;
    //       if( p.penalty != null)
    //       oldTotalPenalty += p.penalty;
    //       if( p.interest != null)
    //       oldTotalInterrest += p.interest;

    //     });
    //     newArr.forEach((p : any) =>{
    //       if( p.tax != null)
    //       newTotalTax +=  p.tax ;
    //       if( p.penalty != null)
    //       newTotalPenalty += p.penalty;
    //       if( p.interest != null)
    //       newTotalInterrest += p.interest;
    //     });
    //   }else if(tableName === 'consultantCharges'){
    //     oldArr.forEach((p : any) =>{
    //      if(p.fees != null){
    //        oldFees += p.fees;
    //      }
    //     });

    //     newArr.forEach((p : any) =>{
    //       if(p.fees != null){
    //         newFees += p.fees;
    //       }
    //     })
    //   }

    //   if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== null){
    //     this.cumulativeCount.forEach((c: any) =>{
    //       if(c.label === 'Cumulative Pre-Deposit' && tableName === 'depositDetails'){
    //         c.tax = preDepositeTax - oldTotalTax + newTotalTax ;
    //         c.penalty = preDepositePenalty - oldTotalPenalty + newTotalPenalty ;
    //         c.interest = preDepositeInterest - oldTotalInterrest + newTotalInterrest ;
    //         c.total = c.tax + c.penalty + c.interest  ;
    //       }
    //     if(c.label === 'Cumulative payment made under protest' &&  tableName === 'protestDetails'){
    //       c.tax = protestPaymentTax - oldTotalTax + newTotalTax  ;
    //       c.penalty = protestPaymentPenalty - oldTotalPenalty + newTotalPenalty  ;
    //       c.interest = protestPaymentInterest - oldTotalInterrest + newTotalInterrest  ;
    //       c.total = c.tax + c.penalty + c.interest  ;
    //     }
    //     else if(c.label === 'Cumulative payment made for admitted liability' && tableName === 'liabilityDetails'){
    //       c.tax = liabilityPaymentTax - oldTotalTax + newTotalTax  ;
    //       c.penalty = liabilityPaymentPenalty - oldTotalPenalty + newTotalPenalty ;
    //       c.interest = liabilityPaymentInterest - oldTotalInterrest + newTotalInterrest ;
    //       c.total = c.tax + c.penalty + c.interest  ;
    //     }else if(c.label === 'Cumulative Consultant Charges' && tableName === 'consultantCharges'){
    //       c.total = consultantFees - oldFees + newFees  ;
    //     }
    //     });
    //     this.caseData.cumulative = this.cumulativeCount;
    //   }else{
    //     this.cumulativeCount.forEach((c: any) =>{
    //       if(c.label === 'Cumulative Pre-Deposit'){
    //         preDepositeTax ?  c.tax = preDepositeTax  : null;
    //         preDepositePenalty ?  c.penalty = preDepositePenalty  : null;
    //         preDepositeInterest ?  c.interest = preDepositeInterest  : null;
    //         preDepositeTotal ?  c.total = preDepositeTotal  : null;
    //       }
    //       if(c.label === 'Cumulative payment made under protest'){
    //         protestPaymentTax ?  c.tax = protestPaymentTax  : null;
    //         protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
    //         protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
    //         protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
    //       }else if(c.label === 'Cumulative payment made for admitted liability'){
    //         liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
    //         liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
    //         liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
    //         liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
    //       }else if(c.label === 'Cumulative Consultant Charges'){
    //         consultantFees ? c.total = consultantFees  : null;
    //       }
    //     });
    //   }
    //   if(tableName === 'protestDetails') this.paymentUnderProtestp1Changed();
    //   // if(tableName === 'depositDetails') this.preDepositeChanged();
    //   if(tableName === 'consultantCharges') this.feesChargedChanged();
    //   if(tableName === 'liabilityDetails') this.liabilityAddmittedAndPaidChanged();
  }

  refreshCumulativeTable(event: any) {
    event.stopPropagation();
    let refreshValue = this.preSCForm.value;
    refreshValue['cumulative'] = this.caseData.cumulative
    refreshValue['prevStage'] = this.prevStage
    if (refreshValue.protestDetails && refreshValue.protestDetails.length) {
      refreshValue.protestDetails.forEach((protestDetail: any) => {
        protestDetail.tax = this.inrService.removeCommasAndParseNumber(protestDetail.tax)
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
        protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
      })
    }
    if (refreshValue.consultantCharges && refreshValue.consultantCharges.length) {
      refreshValue.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
    }
    if (refreshValue.liabilityDetails && refreshValue.liabilityDetails.length) {
      refreshValue.liabilityDetails.forEach((liability: any) => {
        liability.tax = this.inrService.removeCommasAndParseNumber(liability.tax)
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
      })
    }
    if (refreshValue.issues && refreshValue.issues.length) {
      refreshValue.issues.forEach((issue: any) => {
        issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty1)
        issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(issue.serviceTax1)
        issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1)
        issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1)
        issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1)
        issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1)
        issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1)
        issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1)
        issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1)
        issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1)
        issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1)
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
        issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1)
        issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)

        issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty3)
        issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(issue.serviceTax3)
        issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3)
        issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3)
        issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3)
        issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3)
        issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3)
        issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3)
        issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3)
        issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3)
        issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3)
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
        issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3)
        issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3)
        if (issue.details && issue.details.length) {
          issue.details.forEach((details: any) => {
            details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(details.provisionAmt1)
            details.contLiabilityAmt1 = this.inrService.removeCommasAndParseNumber(details.contLiabilityAmt1)
            details.otherLiability1 = this.inrService.removeCommasAndParseNumber(details.otherLiability1)
            details.caroAmt1 = this.inrService.removeCommasAndParseNumber(details.caroAmt1)
          })
        }
      })
    }

    refreshValue.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(refreshValue.exciseDuty1Total)
    refreshValue.serviceTax1Total = this.inrService.removeCommasAndParseNumber(refreshValue.serviceTax1Total)
    refreshValue.ec1Total = this.inrService.removeCommasAndParseNumber(refreshValue.ec1Total)
    refreshValue.shec1Total = this.inrService.removeCommasAndParseNumber(refreshValue.shec1Total)
    refreshValue.kkc1Total = this.inrService.removeCommasAndParseNumber(refreshValue.kkc1Total)
    refreshValue.vat1Total = this.inrService.removeCommasAndParseNumber(refreshValue.vat1Total)
    refreshValue.cst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.cst1Total)
    refreshValue.sbc1Total = this.inrService.removeCommasAndParseNumber(refreshValue.sbc1Total)
    refreshValue.bcd1Total = this.inrService.removeCommasAndParseNumber(refreshValue.bcd1Total)
    refreshValue.sad1Total = this.inrService.removeCommasAndParseNumber(refreshValue.sad1Total)
    refreshValue.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(refreshValue.antiDumpingDuty1Total)
    refreshValue.igst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.igst1Total)
    refreshValue.ent1Total = this.inrService.removeCommasAndParseNumber(refreshValue.ent1Total)
    refreshValue.interest1Total = this.inrService.removeCommasAndParseNumber(refreshValue.interest1Total)
    refreshValue.penalty1Total = this.inrService.removeCommasAndParseNumber(refreshValue.penalty1Total)
    refreshValue.totOfTotal1 = this.inrService.removeCommasAndParseNumber(refreshValue.totOfTotal1)

    refreshValue.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(refreshValue.exciseDuty3Total)
    refreshValue.serviceTax3Total = this.inrService.removeCommasAndParseNumber(refreshValue.serviceTax3Total)
    refreshValue.ec3Total = this.inrService.removeCommasAndParseNumber(refreshValue.ec3Total)
    refreshValue.shec3Total = this.inrService.removeCommasAndParseNumber(refreshValue.shec3Total)
    refreshValue.kkc3Total = this.inrService.removeCommasAndParseNumber(refreshValue.kkc3Total)
    refreshValue.vat3Total = this.inrService.removeCommasAndParseNumber(refreshValue.vat3Total)
    refreshValue.cst3Total = this.inrService.removeCommasAndParseNumber(refreshValue.cst3Total)
    refreshValue.sbc3Total = this.inrService.removeCommasAndParseNumber(refreshValue.sbc3Total)
    refreshValue.bcd3Total = this.inrService.removeCommasAndParseNumber(refreshValue.bcd3Total)
    refreshValue.sad3Total = this.inrService.removeCommasAndParseNumber(refreshValue.sad3Total)
    refreshValue.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(refreshValue.antiDumpingDuty3Total)
    refreshValue.igst3Total = this.inrService.removeCommasAndParseNumber(refreshValue.igst3Total)
    refreshValue.ent3Total = this.inrService.removeCommasAndParseNumber(refreshValue.ent3Total)
    refreshValue.interest3Total = this.inrService.removeCommasAndParseNumber(refreshValue.interest3Total)
    refreshValue.penalty3Total = this.inrService.removeCommasAndParseNumber(refreshValue.penalty3Total)
    refreshValue.totOfTotal3 = this.inrService.removeCommasAndParseNumber(refreshValue.totOfTotal3)

    this.litService.getCumulativeCount(this.tabType[4].key, refreshValue).subscribe((response: any) => {
      this.formatTableValues();
      this.caseData.cumulative = response.response;
      this.cumulativeCount = response.response;
      this.toaster.showSuccess(response.message);
    })
  }
  inrFormattingRemoving() {
    const data = this.preSCForm.value;
    //phase 2 form
    if (data.consultantCharges && data.consultantCharges.length) {
      data.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees)
      })
    }
    if (data.bankGuarantee && data.bankGuarantee.length) {
      data.bankGuarantee.forEach((bank: any) => {
        bank.bgAmount = this.inrService.removeCommasAndParseNumber(bank.bgAmount)
        bank.fee = this.inrService.removeCommasAndParseNumber(bank.fee)
      })
    }
    //phase 1 form
    if (data.protestDetails && data.protestDetails.length) {
      data.protestDetails.forEach((protestDetail: any) => {
        protestDetail.tax = this.inrService.removeCommasAndParseNumber(protestDetail.tax)
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(protestDetail.interest)
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(protestDetail.penalty)
        protestDetail.total = this.inrService.removeCommasAndParseNumber(protestDetail.total)
      })
    }
    //phase 3 form
    if (data.liabilityDetails && data.liabilityDetails.length) {
      data.liabilityDetails.forEach((liability: any) => {
        liability.tax = this.inrService.removeCommasAndParseNumber(liability.tax)
        liability.interest = this.inrService.removeCommasAndParseNumber(liability.interest)
        liability.penalty = this.inrService.removeCommasAndParseNumber(liability.penalty)
        liability.total = this.inrService.removeCommasAndParseNumber(liability.total)
      })
    }
    // //phase 1 and 3 form
    if (data.issues && data.issues.length) {
      data.issues.forEach((issue: any) => {
        issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty1)
        issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(issue.serviceTax1)
        issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1)
        issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1)
        issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1)
        issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1)
        issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1)
        issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1)
        issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1)
        issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1)
        issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1)
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
        issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1)
        issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1)
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1)
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1)

        issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty3)
        issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(issue.serviceTax3)
        issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3)
        issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3)
        issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3)
        issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3)
        issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3)
        issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3)
        issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3)
        issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3)
        issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3)
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
        issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3)
        issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3)
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3)
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.totlal3)

        if (issue.details && issue.details.length) {
          issue.details.forEach((details: any) => {
            details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(details.provisionAmt1)
            details.contLiabilityAmt1 = this.inrService.removeCommasAndParseNumber(details.contLiabilityAmt1)
            details.otherLiability1 = this.inrService.removeCommasAndParseNumber(details.otherLiability1)
            details.caroAmt1 = this.inrService.removeCommasAndParseNumber(details.caroAmt1)
          })
        }
      })
    }
    // //phase 1 and 3 form
    data.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty1Total)
    data.serviceTax1Total = this.inrService.removeCommasAndParseNumber(data.serviceTax1Total)
    data.ec1Total = this.inrService.removeCommasAndParseNumber(data.ec1Total)
    data.shec1Total = this.inrService.removeCommasAndParseNumber(data.shec1Total)
    data.kkc1Total = this.inrService.removeCommasAndParseNumber(data.kkc1Total)
    data.vat1Total = this.inrService.removeCommasAndParseNumber(data.vat1Total)
    data.bcd1Total = this.inrService.removeCommasAndParseNumber(data.bcd1Total)
    data.sad1Total = this.inrService.removeCommasAndParseNumber(data.sad1Total)
    data.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty1Total)
    data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)
    data.cst1Total = this.inrService.removeCommasAndParseNumber(data.cst1Total)
    data.sbc1Total = this.inrService.removeCommasAndParseNumber(data.sbc1Total)
    data.ent1Total = this.inrService.removeCommasAndParseNumber(data.ent1Total)
    data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total)
    data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total)
    data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1)

    data.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty3Total)
    data.serviceTax3Total = this.inrService.removeCommasAndParseNumber(data.serviceTax3Total)
    data.ec3Total = this.inrService.removeCommasAndParseNumber(data.ec3Total)
    data.shec3Total = this.inrService.removeCommasAndParseNumber(data.shec3Total)
    data.kkc3Total = this.inrService.removeCommasAndParseNumber(data.kkc3Total)
    data.vat3Total = this.inrService.removeCommasAndParseNumber(data.vat3Total)
    data.bcd3Total = this.inrService.removeCommasAndParseNumber(data.bcd3Total)
    data.sad3Total = this.inrService.removeCommasAndParseNumber(data.sad3Total)
    data.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty3Total)
    data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total)
    data.cst3Total = this.inrService.removeCommasAndParseNumber(data.cst3Total)
    data.sbc3Total = this.inrService.removeCommasAndParseNumber(data.sbc3Total)
    data.ent3Total = this.inrService.removeCommasAndParseNumber(data.ent3Total)
    data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total)
    data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total)
    data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3)
  }

  formatTableValues() {
    const issues1Array = this.preSCForm.controls.issues as UntypedFormArray
    const liabilityDetailsArray = this.preSCForm.controls.liabilityDetails as UntypedFormArray
    const protestDetailsArray = this.preSCForm.controls.protestDetails as UntypedFormArray
    const consultantChargesArray = this.preSCForm.controls.consultantCharges as UntypedFormArray

    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (var index = 0; index < consultantChargesArray.controls.length; index++) {
        const consultantChargesControl = consultantChargesArray.controls[index] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(this.inrService.formatCurrency(consultantChargesControl.value.fees))
      }
    }

    if (protestDetailsArray && protestDetailsArray.controls.length) {
      for (var index = 0; index < protestDetailsArray.controls.length; index++) {
        const protestDetailsControl = protestDetailsArray.controls[index] as UntypedFormGroup;
        protestDetailsControl.controls['tax'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.tax))
        protestDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.interest))
        protestDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.penalty))
        protestDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(protestDetailsControl.value.total))
      }
    }

    if (liabilityDetailsArray.controls.length > 0) {
      for (let index = 0; index < liabilityDetailsArray.controls.length; index++) {
        const liabilityDetailsControl = liabilityDetailsArray.controls[index] as UntypedFormGroup;
        liabilityDetailsControl.controls['tax'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.tax))
        liabilityDetailsControl.controls['interest'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.interest))
        liabilityDetailsControl.controls['penalty'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.penalty))
        liabilityDetailsControl.controls['total'].setValue(this.inrService.formatCurrency(liabilityDetailsControl.value.total))
      }
    }


    if (issues1Array.controls.length > 0) {
      for (let i = 0; i < issues1Array.controls.length; i++) {
        const issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        issues1Control.controls['exciseDuty1'].setValue(this.inrService.formatCurrency(issues1Control.value.exciseDuty1))
        issues1Control.controls['serviceTax1'].setValue(this.inrService.formatCurrency(issues1Control.value.serviceTax1))
        issues1Control.controls['ec1'].setValue(this.inrService.formatCurrency(issues1Control.value.ec1))
        issues1Control.controls['shec1'].setValue(this.inrService.formatCurrency(issues1Control.value.shec1))
        issues1Control.controls['kkc1'].setValue(this.inrService.formatCurrency(issues1Control.value.kkc1))
        issues1Control.controls['sbc1'].setValue(this.inrService.formatCurrency(issues1Control.value.sbc1))
        issues1Control.controls['vat1'].setValue(this.inrService.formatCurrency(issues1Control.value.vat1))
        issues1Control.controls['cst1'].setValue(this.inrService.formatCurrency(issues1Control.value.cst1))
        issues1Control.controls['bcd1'].setValue(this.inrService.formatCurrency(issues1Control.value.bcd1))
        issues1Control.controls['sad1'].setValue(this.inrService.formatCurrency(issues1Control.value.sad1))
        issues1Control.controls['antiDumpingDuty1'].setValue(this.inrService.formatCurrency(issues1Control.value.antiDumpingDuty1))
        issues1Control.controls['igst1'].setValue(this.inrService.formatCurrency(issues1Control.value.igst1))
        issues1Control.controls['ent1'].setValue(this.inrService.formatCurrency(issues1Control.value.ent1))
        issues1Control.controls['interest1'].setValue(this.inrService.formatCurrency(issues1Control.value.interest1))
        issues1Control.controls['penalty1'].setValue(this.inrService.formatCurrency(issues1Control.value.penalty1))
        issues1Control.controls['total1'].setValue(this.inrService.formatCurrency(issues1Control.value.total1))

        issues1Control.controls['exciseDuty3'].setValue(this.inrService.formatCurrency(issues1Control.value.exciseDuty3))
        issues1Control.controls['serviceTax3'].setValue(this.inrService.formatCurrency(issues1Control.value.serviceTax3))
        issues1Control.controls['ec3'].setValue(this.inrService.formatCurrency(issues1Control.value.ec3))
        issues1Control.controls['shec3'].setValue(this.inrService.formatCurrency(issues1Control.value.shec3))
        issues1Control.controls['kkc3'].setValue(this.inrService.formatCurrency(issues1Control.value.kkc3))
        issues1Control.controls['sbc3'].setValue(this.inrService.formatCurrency(issues1Control.value.sbc3))
        issues1Control.controls['vat3'].setValue(this.inrService.formatCurrency(issues1Control.value.vat3))
        issues1Control.controls['cst3'].setValue(this.inrService.formatCurrency(issues1Control.value.cst3))
        issues1Control.controls['bcd3'].setValue(this.inrService.formatCurrency(issues1Control.value.bcd3))
        issues1Control.controls['sad3'].setValue(this.inrService.formatCurrency(issues1Control.value.sad3))
        issues1Control.controls['antiDumpingDuty3'].setValue(this.inrService.formatCurrency(issues1Control.value.antiDumpingDuty3))
        issues1Control.controls['igst3'].setValue(this.inrService.formatCurrency(issues1Control.value.igst3))
        issues1Control.controls['ent3'].setValue(this.inrService.formatCurrency(issues1Control.value.ent3))
        issues1Control.controls['interest3'].setValue(this.inrService.formatCurrency(issues1Control.value.interest3))
        issues1Control.controls['penalty3'].setValue(this.inrService.formatCurrency(issues1Control.value.penalty3))
        issues1Control.controls['total3'].setValue(this.inrService.formatCurrency(issues1Control.value.total3))
      }
    }
  }


  deleteAppealBookLoc1() {
    if (this.preSCForm.get('appealBookLoc')?.value) {
      this.preSCForm.get('appealBookLoc')?.setValue(null);
      this.caseData['appealBookLoc'] = null;
      this.isappealBookLoc1File = false;
      this.isappealBookLoc1Text = true;
      this.isDWappealBookLoc1 = true;
      this.isUPappealBookLoc1 = true;
    }
  }

  deleteOtherAttachmentsLoc1() {
    if (this.preSCForm.get('otherAttachmentsLoc1')?.value) {
      this.preSCForm.get('otherAttachmentsLoc1')?.setValue(null);
      this.caseData['otherAttachmentsLoc1'] = null;
      this.isotherAttachmentsLoc1File = false;
      this.isotherAttachmentsLoc1Text = true;
      this.isDWotherAttachmentsLoc1 = true;
      this.isUPotherAttachmentsLoc1 = true;
    }
  }

  deleteBankChallanLoc() {
    if (this.preSCForm.get('otherAttachmentsLoc1')?.value) {
      this.preSCForm.get('otherAttachmentsLoc1')?.setValue(null);
      this.caseData['otherAttachmentsLoc1'] = null;
      this.issupremeCourtOrderLocFile = false;
      this.issupremeCourtOrderLocText = true;
      this.isDWsupremeCourtOrderLoc = true;
      this.isUPsupremeCourtOrderLoc = true;
    }
  }
  handlePaste(event: any) {
    this.inrService.handlePaste(event)
  }
  selectedIssues:any
  getissueData(content: any, i:any){
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    this.selectedIssues = this.preSCForm.value.issues[i].issue;
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "updatedOn",
    model.sortdir = "ASC",
    model.companyId = "7437",
    model.module = "PREGST",
     model.role = "issueRef",
    model.criterias= [
      {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
      }
  ]
  console.log(this.selectedIssues,"this.preSCForm.value.issue");
  this.litService.getIssueRefData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
    if (response.status === 1) {
      this.issueData = response?.response;
      if(this.issueData.length === 0){
          this.hideData =  false
      }else{
        this.hideData =  true
      }
    }      
    });
  }
  exportAsXLSX() {
    if (this.issueData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    const data= this.issueData.map((source: any) => ({
      'PAN Name': source.panName,
      'Case id': source.caseId,
      'Case Label': source.caseLabel,
      'State': source.state,
      'ACT': source.actyType,
      'Registration number': source.regNo,
      'Period From': source.periodFromDate,
      'Period to': source.periodToDate,
      'Person Responsible': source.personResponsible,

    }));
    this.excelService.exportAsExcelFile(data, 'Issue Reference');
  }
  SortingIssue(column: string) {
    switch (column) {
      case SORT_ISSUE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'asc'
          );
        } else {
          this.sorting.caseId = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.STATE: {
        if (!this.sorting.state) {
          this.sorting.state = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'asc'
          );
        } else {
          this.sorting.state = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PAN: {
        if (!this.sorting.panName) {
          this.sorting.panName = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actType',
            'asc'
          );
        } else {
          this.sorting.panName = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actType',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.CASE_LABEL: {
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'asc'
          );
        } else {
          this.sorting.caseLabel = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERIOD_FROM: {
        if (!this.sorting.periodFromDate) {
          this.sorting.periodFromDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'asc'
          );
        } else {
          this.sorting.periodFromDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'desc'
          );
        }
        break;
      }
       case SORT_ISSUE.ACT: {
        if (!this.sorting.actyType) {
          this.sorting.actyType = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actyType',
            'asc'
          );
        } else {
          this.sorting.actyType = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actyType',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.RGENO: {
        if (!this.sorting.regNo) {
          this.sorting.regNo = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'regNo',
            'asc'
          );
        } else {
          this.sorting.regNo = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'regNo',
            'desc'
          );
        }
        break;
      }

      case SORT_ISSUE.PERIOD_TO: {
        if (!this.sorting.periodToDate) {
          this.sorting.periodToDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'asc'
          );
        } else {
          this.sorting.periodToDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERSON_RESPONSIBLE: {
        if (!this.sorting.personResponsible) {
          this.sorting.personResponsible = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,'personResponsible','asc');
        } else {
          this.sorting.personResponsible = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,'personResponsible', 'desc' );
        }
        break;
      }
    }
  }
}

