import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { BusinessService } from '../../services/business.service';

@Component({
  selector: 'app-email-setting',
  templateUrl: './email-setting.component.html',
  styleUrls: ['./email-setting.component.css']
})
export class EmailSettingComponent implements OnInit {
  
  emailForm!: UntypedFormGroup;
  selected: boolean = false;
  isDisabled: boolean = true;
  emailOptOutRes:any;
  selectedOpt:any;
  changeNotification : any;
  onLoadselectedOpt:any;

  constructor(    
    private fBuild: UntypedFormBuilder,
    private router: Router,
    private toaster: ToasterService,
    private businessSerivce: BusinessService,
  ) { }

  ngOnInit() {
  this.emailOptOutData();
   this.selected = this.selectedOpt;
  }
  toDashboard() {
    this.router.navigate(['/dashboard']);
  }
 
  emailOptOutData() {    
    this.businessSerivce.getemailOptOutData().subscribe((response: any) => {
      if (response.status === 1) {
        this.emailOptOutRes = response.response;
        this.selectedOpt = this.emailOptOutRes.optOut;
        if(this.selectedOpt == false || this.selected == false){
          this.selected = true;          
        } else{
          this.selected = false;
         
        }
      }
    });

  } 
  changeValue(selected:any){ 
    this.changeNotification = selected;
    this.isDisabled = false;
  }
  submitEmailOptOut(){
  
    this.businessSerivce.submitEmailOptOutFunction(this.changeNotification).subscribe((response: any) => {     
   
      if (response.status == 1 && this.changeNotification == true) {    
        this.toaster.showSuccess('System will not send email for due date notifications');    
        
      } else {
        this.toaster.showSuccess('System will send email for due date notifications');
      }     
    });
  }
  // formInitialization() {
  //   this.emailForm = this.fBuild.group({});
  // }
}
