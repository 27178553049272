<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">
        <p><span class="card-title">Tax Positions </span>: <span *ngIf="navContext?.entityType == 'FILING'"
            class="title-heder">{{navContext.gstin}}</span><span
            *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'"
            class="title-heder">{{navContext.pan}}</span>
          <button class="btn btn-outline-primary iris-gst-pull-right" (click)="saveTaxPosData()"
            *ngIf="(navContext?.entityType != 'FILING') && !['GST_Viewer','Viewer'].includes(role)">Save
            & Update</button>

        </p>
        <div class="card borderOrange mt15">
          <div class="card-body">
            <form [formGroup]="auditTaxPosFormGrp">
              <div class="form-group">
                <div class="row col-md-12 nopadding">
                  <p><a class="pl5 addExtLetter iris-gst-pull-right" title="Add More"
                      *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'"
                      (click)="addRow()"><i class="fa fa-plus"></i> Add Row</a></p>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead class="bgGred">
                        <tr>
                          <th class="sl-no-cls">Sr.No.</th>
                          <th style="min-width: 490px;">Issue</th>
                          <th style="min-width: 300px;">Keywords</th>
                          <th style="min-width: 300px;">Tax Position Taken</th>
                          <th>Period</th>
                          <th style="min-width: 352px;">Judicial Precedence</th>
                          <th style="min-width: 300px;">Legal Opinion</th>
                          <th class="sl-no-cls"
                            *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'">Remove
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let taxPositionGrp of gettaxPosition(); let i=index" [formGroup]="taxPositionGrp">
                          <td class="sl-no-cls">{{i+1}}</td>
                          <td>
                            <!-- <input type="text" *ngIf="navContext?.entityType == 'FILING'" class="form-control" readOnly   data-bs-toggle="tooltip" data-bs-placement="top" [title]="taxPositionGrp.value.issue" formControlName="issue"> -->
                            <!-- <select class="form-control form-select" *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'" [title]="taxPositionGrp.value.issue" formControlName="issue"  [ngClass]="{'errorBorder': (isSubmitted && taxPositionGrp.get('issue')?.errors)}" (change)="changeIssueDrop($event)"> -->
                            <select class="form-control form-select"
                              [title]="taxPositionGrp.value.issue?taxPositionGrp.value.issue:''" formControlName="issue"
                              [ngClass]="{'errorBorder': (isSubmitted && taxPositionGrp.get('issue')?.errors)}"
                              (change)="changeIssueDrop($event)">
                              <option [value]="null">-- Select --</option>
                              <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                  {{item.name}}</option>
                              </optgroup>
                            </select>
                            <div *ngIf="isSubmitted && taxPositionGrp.get('issue')?.errors">
                              <span class="text-danger" *ngIf="taxPositionGrp.get('issue')?.errors?.required "> Required
                              </span>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" [readOnly]="isGstin" data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              [title]="taxPositionGrp.value.keywords?taxPositionGrp.value.keywords:''"
                              formControlName="keywords"> </td>

                          <td>
                            <input type="text" class="form-control" formControlName="taxPosTaken"
                              data-bs-toggle="tooltip" data-bs-placement="top" [readOnly]="isGstin"
                              [title]="taxPositionGrp.value.taxPosTaken?taxPositionGrp.value.taxPosTaken:''"
                              placeholder="Enter text"
                              [ngClass]="{ 'errorBorder': (isSubmitted && taxPositionGrp.get('taxPosTaken')?.errors)}"
                              (click)="openTextBoxModal('Tax Position Taken', 'taxPosTaken',auditTaxPosFormGrp,i)">
                            <div *ngIf="isSubmitted && taxPositionGrp.get('taxPosTaken')?.errors">
                              <span class="text-danger" *ngIf="taxPositionGrp.get('taxPosTaken')?.errors?.required ">
                                Required
                              </span>
                            </div>
                          </td>

                          <!-- <td><div class="row" formArrayName="JudicialPreced">
                                                <div class="col-md-12" *ngFor="let judicialDiv of JudicialPrecedArr(); let j=index" id="div{{judicialDiv}}" >
                                                    <div [formGroupName]="j">
                                                    <input type="file" id="judcialPreced" class="filetype3 form-control" name="myfile" formControlName="judcialPreced">
                                                    </div>
                                                </div>
                                            </div>
                                        </td> *ngIf="taxPositionGrp.value.isActive" -->
                          <td><input type="text" class="form-control" [readOnly]="isGstin" data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              [title]="taxPositionGrp.value.period?taxPositionGrp.value.period:''"
                              formControlName="period"> </td>
                          <td><span (click)="openDetailPop(judicialPredPop, i)"> <i class="icon fa fa fa-paperclip"
                                title="Attach file"></i></span><span
                              *ngIf="getFileLength(i, 'judicialPrecedencesLoc') != 0"> {{getFileLength(i,
                              'judicialPrecedencesLoc')}} Attachment</span></td>
                          <td><span (click)="openDetailPop(legalOpinionPop, i)"> <i class="icon fa fa fa-paperclip"
                                title="Attach file"></i></span><span *ngIf="getFileLength(i , 'legalOpinionLoc') != 0">
                              {{getFileLength(i , 'legalOpinionLoc')}} Attachment</span></td>
                          <td class="sl-no-cls"
                            *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'"><a
                              class="addExtLetter" (click)="opentaxPop(taxpop, i)"><i class="fa fa-minus"></i></a></td>
                          <ng-template #taxpop let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <p>Are you sure, you want to remove?</p>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-outline-dark" (click)="rmProv(i)">Remove</button>
                            </div>
                          </ng-template>
                          <ng-template #judicialPredPop let-modal>
                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="col-md-12">
                              <label> Attachment </label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Upload</th>
                                      <th>Remark</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      *ngFor="let judicialFromGrp of getjudicialPrecedencesLoc(taxPositionGrp); let j=index"
                                      [formGroup]="judicialFromGrp">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-9 nopadding">
                                            <div class="form-group">
                                              <input *ngIf="judicialFromGrp.value.isdocLocUploadedClicked" type="file"
                                                id="itemList({{i}}).judicialPrecedencesLoc({{j}}).fileLoc"
                                                class="filetype form-control" name="myfile"
                                                (change)="onFileSelect($event,'judicialFromGrp',j,taxPositionGrp)">
                                              <p *ngIf="!judicialFromGrp.value.isdocLocUploadedClicked || judicialFromGrp.value.fileLoc"
                                                class="form-control pt10">
                                                {{getFileNameDta(judicialFromGrp.value.fileLoc)}}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-md-3 nopadding">
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon" *ngIf="judicialFromGrp.value.fileLoc"
                                                title="Download file"
                                                (click)="download(judicialFromGrp.value.fileLoc)"><i
                                                  class="fa fa-download"></i></a>
                                              <a class="downupIcon"
                                                *ngIf="!judicialFromGrp.value.fileLoc || judicialFromGrp.value.fileLoc"
                                                title="Upload file" (click)="uploadjpLoc(i,j)"><i
                                                  class="fa fa-upload"></i></a>
                                              <a class="downupIcon" (click)="deleteJudicialPredFile(i,j)"
                                                *ngIf="judicialFromGrp.value.fileLoc"><i class="fa fa-trash"
                                                  aria-hidden="true"></i></a>
                                            </label>
                                          </div>
                                        </div>
                                      </td>
                                      <!-- remark -->
                                      <td>
                                        <input type="text" class="form-control" formControlName="remarks"
                                          [title]="judicialFromGrp.value.remarks ? judicialFromGrp.value.remarks:''">
                                        <div
                                          *ngIf="judicialFromGrp.value.remarks && judicialFromGrp.value.remarks.length > 100">
                                          <span class="text-danger">Max character Length 100</span>
                                        </div>
                                      </td>

                                      <ng-template #rmDocSubListPop let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmJudicialDiv(i, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Save</button>
                            </div>
                          </ng-template>

                          <ng-template #legalOpinionPop let-modal>
                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                              <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div class="col-md-12">
                              <label> Attachment </label>
                              <div class="table-responsive">
                                <table class="table table-bordered">
                                  <thead>
                                    <tr class="bgGred">
                                      <th>Upload</th>
                                      <th>Remark</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr *ngFor="let legalFromGrp of getLegalOpinion(taxPositionGrp); let j=index"
                                      [formGroup]="legalFromGrp">
                                      <td>
                                        <div class="row">
                                          <div class="col-md-9 nopadding">
                                            <div class="form-group">
                                              <input *ngIf="legalFromGrp.value.isdocLocUploadedClicked" type="file"
                                                id="itemList({{i}}).legalOpinionLoc({{j}}).fileLoc"
                                                class="filetype form-control" name="myfile"
                                                (change)="onFileSelect($event,'legalFromGrp',j,taxPositionGrp)">
                                              <p *ngIf="!legalFromGrp.value.isdocLocUploadedClicked || legalFromGrp.value.fileLoc"
                                                class="form-control pt10">
                                                {{getFileNameDta(legalFromGrp.value.fileLoc)}}
                                              </p>
                                            </div>
                                          </div>
                                          <div class="col-md-3 nopadding">
                                            <label for="exampleInputPassword1">
                                              <a class="downupIcon" *ngIf="legalFromGrp.value.fileLoc"
                                                title="Download file" (click)="download(legalFromGrp.value.fileLoc)"><i
                                                  class="fa fa-download"></i></a>
                                              <a class="downupIcon"
                                                *ngIf="!legalFromGrp.value.fileLoc || legalFromGrp.value.fileLoc"
                                                title="Upload file" (click)="uploadLegalOpinion(i,j)"><i
                                                  class="fa fa-upload"></i></a>
                                              <a class="downupIcon" (click)="deleteLegalOpinionFile(i,j)"
                                                *ngIf="legalFromGrp.value.fileLoc"><i class="fa fa-trash"
                                                  aria-hidden="true"></i></a>
                                            </label>
                                          </div>
                                        </div>

                                        <!-- <div class="row">
                                                            <div class="col-md-9 nopadding">                                               
                                                                <div class="form-group"> 
                                                                  <input *ngIf="legalFromGrp?.value?.isdocLocUploadedClicked" type="file" id="itemList({{i}}).legalOpinionLoc({{j}}).fileLoc" class="filetype form-control" name="myfile" (change)="onFileSelect($event,'legalFromGrp',j,taxPositionGrp)" [ngClass]="{ 'errorBorder': isSubmitted && legalFromGrp.value.itemList[i].legalOpinionLoc[j].fileLoc.errors}">
                                                                  <p *ngIf="!legalFromGrp?.value?.isdocLocUploadedClicked || legalFromGrp.value.itemList[i].legalOpinionLoc[j].fileLoc" class="form-control pt10" [ngClass]="{ 'errorBorder': isSubmitted && legalFromGrp.value.itemList[i].legalOpinionLoc[j].fileLoc.errors}" [title]="legalFromGrp.value.fileName"> {{legalFromGrp(legalFromGrp.value.itemList[i].legalOpinionLoc[j].fileLoc)}}</p>
                                                                  <div *ngIf="isSubmitted && legalFromGrp.value.itemList[i].legalOpinionLoc[j].fileLoc.errors">
                                                                  <span class="text-danger" *ngIf="legalFromGrp.value.itemList[i].legalOpinionLoc[j].fileLoc.errors.required"> Required </span>
                                                                </div>
                                                                </div> 
                                                            </div>
                                                            <div class="col-md-3 nopadding">    
                                                                <label for="exampleInputPassword1">
                                                                  <a class="downupIcon" *ngIf="legalFromGrp.value.itemList[i].legalOpinionLoc[j].fileLoc" title="Download file" (click)="download(legalFromGrp.value.itemList[i].legalOpinionLoc[j].fileLoc)"><i class="fa fa-download"></i></a>
                                                                  <a class="downupIcon" *ngIf="!legalFromGrp.value.itemList[i].legalOpinionLoc[j].fileLoc || legalFromGrp.value.itemList[i].legalOpinionLoc[j].fileLoc" title="Upload file" (click)="uploadLegalOpinion(i,j)"><i class="fa fa-upload"></i></a>
                                                                  <a  class="downupIcon" (click)="deleteLegalOpinionFile(i,j)" *ngIf="(legalFromGrp.value.fileLoc)"><i  class="fa fa-trash" aria-hidden="true"></i></a>
                                                                </label> 
                                                            </div>
                                                        </div> -->
                                      </td>

                                      <!-- remark -->
                                      <td>
                                        <input type="text" class="form-control" formControlName="remarks"
                                          [title]="legalFromGrp.value.remarks?legalFromGrp.value.remarks:''">
                                        <div
                                          *ngIf="legalFromGrp.value.remarks && legalFromGrp.value.remarks.length > 100">
                                          <span class="text-danger">Max character Length 100</span>
                                        </div>
                                      </td>

                                      <ng-template #rmDocSubListPop1 let-modal>
                                        <div class="modal-header">
                                          <h4 class="modal-title" id="modal-basic-title">Remove Warning! </h4>
                                          <button type="button" class="close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">×</span>
                                          </button>
                                        </div>
                                        <div class="modal-body">
                                          <p>Are you sure, you want to remove?</p>
                                        </div>
                                        <div class="modal-footer">
                                          <button type="button" class="btn btn-outline-dark"
                                            (click)="rmLegalOpinionDiv(i, j)">Remove</button>
                                        </div>
                                      </ng-template>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                              <button type="button" class="btn btn-outline-dark"
                                (click)="modal.close('Save click')">Save</button>
                            </div>
                          </ng-template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-12 nopadding mt-2">
          <button class="btn btn-outline-primary" (click)="saveTaxPosData()"
            *ngIf="navContext?.entityType == 'LEGAL'">Save & Update</button>
          <!-- <button class="btn btn-outline-primary ml-1" >Update</button> -->
        </div>

      </div>
    </div>
  </div>
</div>