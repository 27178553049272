import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { FormsModule } from '@angular/forms'
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { DT_ISSUE_DROPDOWN, ISSUE_GST } from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { TaxServiceService } from '../service/tax-service.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-tax-positions',
  templateUrl: './tax-positions.component.html',
  styleUrls: ['./tax-positions.component.css']
})
export class TaxPositionsComponent implements OnInit {

  navContext = { entityName: '', isTaxPayer: false, entityType: '', pan: '', gstin: '' };
  directTaxPosFormGrp!: UntypedFormGroup;
  selectedField: any;
  selectedFile: any;
  matches: any;
  fileUrl: any;
  caseId: any;
  selectedPan: any;
  selectedPanName: any;
  selectedFileName: any;
  getRemarks: any;
  pan: any;
  taxPosData: any;
  dataSource: any;
  role: any;
  taxPopModalRef!: NgbModalRef;
  rmvjudicialModalRef!: NgbModalRef;
  rmvlegalModalRef!: NgbModalRef;
  isSubmitted: boolean = false;
  formData: FormData = new FormData();
  finyearData: any[] = [];


  isUPdocLoc: boolean = true;
  isDWdocLoc: boolean = true;
  isdocLocFile: boolean = false;
  isdocLocText: boolean = true;


  isUPlegalLoc: boolean = true;
  isDWlegalLoc: boolean = true;
  islegalLocFile: boolean = false;
  islegalLocText: boolean = true;

  constructor(
    private fBuild: UntypedFormBuilder,
    private taxService: TaxServiceService,
    private toaster: ToasterService,
    private GS: GetterSetterService,
    private modalService: NgbModal,
    private commonServ: CommonService,
    private router: Router,
    private http: HttpClient,
  ) {
  }

  ngOnInit() {
    this.navContext = this.commonServ.getNavContext();
    this.selectedPan = this.navContext.pan;
    this.role = localStorage.getItem('role');
    if (this.navContext.entityType === 'Business') {
      this.selectedPanName = sessionStorage.getItem('company');
    } else {
      this.selectedPanName = this.navContext.entityName;
    }
    this.GS.checkEntityType.subscribe((value) => {
      this.navContext = value;
      if (this.taxPosData && (this.navContext.entityType === 'Legal') && this.router.url === '/auditTaxPosition') {
        this.reloadCurrentRoute();
      }
    });
    this.initializeData();
    this.formInitialize();
    this.issueDropdown();
    this.getYears();
  }
  formInitialize() {
    this.directTaxPosFormGrp = this.fBuild.group({
      pan: [this.selectedPan],
      panName: [this.selectedPanName],
      itemList: new UntypedFormArray([]),
    })
  }
  get formGrp() { return this.directTaxPosFormGrp.controls; }


  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2009;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push(financialYrRange);
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }

  /* get download file path function */
  getFileNameDta(fileUrl: any) {
    if (fileUrl)
      return this.taxService.getFileName(fileUrl);
  }
  /* get download file path function */

  /*upload file selection */
  onFileSelect(event: any, formGroup: any, index: any, taxPositionGrp: UntypedFormGroup) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace('&', 'And')
        this.selectedFile = new File([originalFile], modifiedFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, formGroup, index, taxPositionGrp);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }

  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  onUpload(selectedfield: any, formGroup: any, index: any, taxPositionGrp: UntypedFormGroup) {
    let urlData = {
      gstinOrPan: this.navContext.pan,
      type: 'dttaxpos',
      field: selectedfield,//this.selectedField,
      contentType: this.selectedFile.type
    }
    this.taxService.uploadFile(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFileUrl(response.response.url, response.response.path, selectedfield, formGroup, index, taxPositionGrp);
      } else {
      }
    })
  }

  getFileUrl(url: any, path: any, selectedfield: any, formGroup: any, index: any, taxPositionGrp: UntypedFormGroup) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      console.log("response receved is ", response);
      if (selectedfield) {
        if (formGroup == 'judicialFromGrp') {
          const b = (taxPositionGrp.get('judicialPredLoc') as UntypedFormArray).controls as UntypedFormGroup[];
          b[index].controls["fileName"].setValue(this.selectedFile.name);
        } else {
          const b = (taxPositionGrp.get('legalOpLoc') as UntypedFormArray).controls as UntypedFormGroup[];
          b[index].controls["fileName"].setValue(this.selectedFile.name);
        }
        var toupdate = this.directTaxPosFormGrp.value;
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.directTaxPosFormGrp.patchValue(this.directTaxPosFormGrp.value);

      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }
  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }

  /*upload file selection */
  /*download file function */
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.taxService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }
  /*download file function */



  /*add tax array row */
  getTaxPosition(): UntypedFormGroup[] {
    return (this.directTaxPosFormGrp.get('itemList') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  initTaxPositionGrp(): UntypedFormGroup {
    return this.fBuild.group({
      pan: [this.selectedPan],
      panName: [this.selectedPanName],
      issue: [null, [Validators.required]],
      keywords: [null, Validators.maxLength(150)],
      ay: [null],
      taxPosTaken: [null, [Validators.required, Validators.maxLength(1000)]],
      judicialPredLoc: new UntypedFormArray([]),
      legalOpLoc: new UntypedFormArray([]),

    })
  }
  addRow() {
    let taxPositionGrp = this.initTaxPositionGrp();
    (this.directTaxPosFormGrp.get('itemList') as UntypedFormArray).push(taxPositionGrp);
    (taxPositionGrp.get('judicialPredLoc') as UntypedFormArray).push(this.initJudicialFrmGrp());
    (taxPositionGrp.get('legalOpLoc') as UntypedFormArray).push(this.initLegalOpinionFrmGrp());
  }
  rmProv(i: number) {
    (this.directTaxPosFormGrp.get('itemList') as UntypedFormArray).removeAt(i);
    this.taxPopModalRef.close();
  }

  openDetailPop(content: any, i: any) {
    this.addLegalOpinionDiv(i, 0);
    this.addJudicialDiv(i, 0);
    this.modalService.open(content);
  }

  /*judicialPredLoc array p2*/
  getJudicial(taxPositionGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (taxPositionGrp.get('judicialPredLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getJudicialLength(j?: any) {
    let count = 0;
    let itemListFrmArray = this.directTaxPosFormGrp.get('itemList') as UntypedFormArray;
    let judicialFrmArray = itemListFrmArray.controls[j]?.get('judicialPredLoc') as UntypedFormArray;
    let judicialCount = judicialFrmArray.value.filter((x: any) => x.fileLoc != "");
    return judicialCount.length;
  }
  initJudicialFrmGrp() {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl(''),
      fileName: new UntypedFormControl(''),
      remarks: new UntypedFormControl(''),
    })
  }
  // addJudicialDiv(j:any) {
  //   let itemListFrmArray = this.directTaxPosFormGrp.get('itemList') as FormArray;
  //   let judicialFrmArray = itemListFrmArray.controls[j].get('judicialPredLoc') as FormArray;
  //   judicialFrmArray.push(this.initJudicialFrmGrp());
  // }
  addJudicialDiv(j: any, i: any) {
    let itemListFrmArray = this.directTaxPosFormGrp.get('itemList') as UntypedFormArray;
    let judicialFrmArray = itemListFrmArray.controls[j].get('judicialPredLoc') as UntypedFormArray;
    if (judicialFrmArray.length > 0) {
      let length = judicialFrmArray.length
      while (i < 5 - length) {
        judicialFrmArray.push(this.initJudicialFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        judicialFrmArray.push(this.initJudicialFrmGrp());
        i++;
      }
    }
  }
  rmJudicialDiv(i: number, k: number) {
    let itemListFrmArray = this.directTaxPosFormGrp.get('itemList') as UntypedFormArray;
    let judicialFrmArray = itemListFrmArray.controls[i].get('judicialPredLoc') as UntypedFormArray;
    judicialFrmArray.removeAt(k);
    this.rmvjudicialModalRef.close();
  }
  openJudicialPop(content: any, i: any) {
    this.rmvjudicialModalRef = this.modalService.open(content);
  }

  uploadJudicial(itemListIndex: any, judicialIndex: any) {
    this.isdocLocFile = true;
    this.isdocLocText = false;
    this.isDWdocLoc = false;
    this.isUPdocLoc = false;
    let itemListsArray = this.directTaxPosFormGrp.controls.itemList as UntypedFormGroup;
    let judicialFrmArray = itemListsArray.controls[itemListIndex].get('judicialPredLoc') as UntypedFormArray;
    if (judicialFrmArray.controls.length > 0) {
      for (let index = 0; index < judicialFrmArray.controls.length; index++) {
        if (index === judicialIndex) {
          let itemListuploadControl = judicialFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  deleteJudicialPredFile(itemListIndex: any, ItrIndex: any) {
    let itemListsArray = this.directTaxPosFormGrp.controls.itemList as UntypedFormGroup;
    let judicialFrmArray = itemListsArray.controls[itemListIndex].get('judicialPredLoc') as UntypedFormArray;
    if (judicialFrmArray.controls.length > 0) {
      for (let index = 0; index < judicialFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = judicialFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*judicialPredLoc array */

  /*legalOpLoc array p2*/
  getLegalOpinion(taxPositionGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (taxPositionGrp.get('legalOpLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getLegalOpinionLength(j?: any) {
    let count = 0;
    let itemListFrmArray = this.directTaxPosFormGrp.get('itemList') as UntypedFormArray;
    let legalOpinionFrmArray = itemListFrmArray.controls[j]?.get('legalOpLoc') as UntypedFormArray;
    let legalOpinionCount = legalOpinionFrmArray.value.filter((x: any) => x.fileLoc != "");
    return legalOpinionCount.length;
  }
  initLegalOpinionFrmGrp() {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl(''),
      fileName: new UntypedFormControl('',),
      remarks: new UntypedFormControl(''),
    })
  }
  // addLegalOpinionDiv(j:any) {
  //   let itemListFrmArray = this.directTaxPosFormGrp.get('itemList') as FormArray;
  //   let legalOpinionFrmArray = itemListFrmArray.controls[j].get('legalOpLoc') as FormArray;
  //   legalOpinionFrmArray.push(this.initLegalOpinionFrmGrp());
  // }
  addLegalOpinionDiv(j: any, i: any) {
    let itemListFrmArray = this.directTaxPosFormGrp.get('itemList') as UntypedFormArray;
    let legalOpinionFrmArray = itemListFrmArray.controls[j].get('legalOpLoc') as UntypedFormArray;
    if (legalOpinionFrmArray.length > 0) {
      let length = legalOpinionFrmArray.length
      while (i < 5 - length) {
        legalOpinionFrmArray.push(this.initLegalOpinionFrmGrp());
        i++;
      }
    }
    else {
      while (i < 5) {
        legalOpinionFrmArray.push(this.initLegalOpinionFrmGrp());
        i++;
      }
    }
  }
  rmLegalOpinionDiv(i: number, k: number) {
    let itemListFrmArray = this.directTaxPosFormGrp.get('itemList') as UntypedFormArray;
    let legalOpinionFrmArray = itemListFrmArray.controls[i].get('legalOpLoc') as UntypedFormArray;
    legalOpinionFrmArray.removeAt(k);
    this.rmvlegalModalRef.close();
  }
  openLegalOpinionPop(content: any, i: any) {
    this.rmvlegalModalRef = this.modalService.open(content);
  }

  uploadLegalOpinion(itemListIndex: any, legalOpinionIndex: any) {
    this.islegalLocFile = true;
    this.islegalLocText = false;
    this.isDWlegalLoc = false;
    this.isUPlegalLoc = false;

    let itemListsArray = this.directTaxPosFormGrp.controls.itemList as UntypedFormGroup;
    let legalOpinionFrmArray = itemListsArray.controls[itemListIndex].get('legalOpLoc') as UntypedFormArray;
    if (legalOpinionFrmArray.controls.length > 0) {
      for (let index = 0; index < legalOpinionFrmArray.controls.length; index++) {
        if (index === legalOpinionIndex) {
          let itemListuploadControl = legalOpinionFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLegalOpinionFile(itemListIndex: any, ItrIndex: any) {
    let itemListsArray = this.directTaxPosFormGrp.controls.itemList as UntypedFormGroup;
    let judicialFrmArray = itemListsArray.controls[itemListIndex].get('legalOpLoc') as UntypedFormArray;
    if (judicialFrmArray.controls.length > 0) {
      for (let index = 0; index < judicialFrmArray.controls.length; index++) {
        if (index === ItrIndex) {
          let itemListuploadControl = judicialFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  /*legalOpLoc array */


  /*fetch function */
  initializeData() {
    let model: any = {
      pan: this.navContext.pan
    };

    this.taxService.getTaxPosData(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.taxPosData = response.response;
        if (this.taxPosData.itemList.length > 0) {
          this.taxPosData.itemList.forEach((element: any) => {
            element.isActive = true;
          });
          this.initItemListData();
        } else {
          this.addRow();
        }
      }
    });

  }

  initItemListData() {
    this.taxPosData.itemList.forEach((noticeData: any) => {
      let itemListGrp: UntypedFormGroup = this.initTaxPositionGrp();
      (this.directTaxPosFormGrp.get('itemList') as UntypedFormArray).push(itemListGrp);
      noticeData.judicialPredLoc.forEach((issue: any) => {
        let judicialFrmGrp: UntypedFormGroup = this.initJudicialFrmGrp();
        (itemListGrp.get('judicialPredLoc') as UntypedFormArray).push(judicialFrmGrp);
      });
      noticeData.legalOpLoc.forEach((legalOpinion: any) => {
        let legalFrmGrp: UntypedFormGroup = this.initLegalOpinionFrmGrp();
        (itemListGrp.get('legalOpLoc') as UntypedFormArray).push(legalFrmGrp);
      });
    });
    this.directTaxPosFormGrp.patchValue(this.taxPosData);
  }


  /*fetch function */
  /*save function */
  // saveTaxPosData() {
  //   this.isSubmitted = true;
  //   let data: any = {};
  //   if (this.directTaxPosFormGrp.valid) {
  //     data = this.directTaxPosFormGrp.value;
  //    if (this.navContext.entityType == 'LEGAL' || this.navContext.entityType === 'Business') {
  //       this.pan = this.navContext.pan;
  //     }
  //     this.taxService.saveTaxPosData(data).subscribe((response: any) => {
  //       this.isSubmitted = false;
  //       if (response.status === 1) {
  //         if (data.itemList.length > 0) {
  //           data.itemList.forEach((e: any) => {
  //             e.isActive = true;
  //           })
  //         }
  //         this.toaster.showSuccess(response.message);

  //       } else {
  //         this.toaster.showSuccess(response.message);
  //       }
  //     });
  //   }

  // }
  /*save function */
  /*on issue selection display upload field */
  updateTaxPosData(event: any) {
    this.isSubmitted = true;
    if (this.directTaxPosFormGrp.valid) {
      let data: any = {
        pan: this.directTaxPosFormGrp.value.itemList[0].pan,
        panName: this.directTaxPosFormGrp.value.itemList[0].panName,
        itemList: this.directTaxPosFormGrp.value.itemList.map((x: any) => {
          delete x.pan
          delete x.panName
          return x
        })
      };
      var itemData: any = {
        ay: "",
        issue: "",
        keywords: "",
        taxPosTaken: "",
        judicialPredLoc: [],
        legalOpLoc: [],
      };

      var finlockdata = {
        fileLoc: "",
        fileName: "",
        remarks: ""
      }
      var itemListArr = [];

      for (var i = 0; i < data.itemList.length; i++) {
        itemData.ay = data.itemList[i].ay;
        itemData.issue = data.itemList[i].issue;
        itemData.keywords = data.itemList[i].keywords;
        itemData.taxPosTaken = data.itemList[i].taxPosTaken;



        for (var j = 0; j < data.itemList[i].judicialPredLoc.length; j++) {
          if (data.itemList[i].judicialPredLoc[j].fileLoc != null && data.itemList[i].judicialPredLoc[j].fileLoc != "" && data.itemList[i].judicialPredLoc[j].fileLoc != undefined) {
            finlockdata = {
              fileLoc: data.itemList[i].judicialPredLoc[j].fileLoc,
              fileName: data.itemList[i].judicialPredLoc[j].fileName,
              remarks: data.itemList[i].judicialPredLoc[j].remarks
            }
            itemData.judicialPredLoc.push(finlockdata);
          }
        }

        for (var j = 0; j < data.itemList[i].legalOpLoc.length; j++) {
          if (data.itemList[i].legalOpLoc[j].fileLoc != null && data.itemList[i].legalOpLoc[j].fileLoc != "" && data.itemList[i].legalOpLoc[j].fileLoc != undefined) {
            finlockdata = {
              fileLoc: data.itemList[i].legalOpLoc[j].fileLoc,
              fileName: data.itemList[i].legalOpLoc[j].fileName,
              remarks: data.itemList[i].legalOpLoc[j].remarks
            }
            itemData.legalOpLoc.push(finlockdata);
          }
        }

        itemListArr.push(itemData);
        itemData = {
          ay: "",
          issue: "",
          keywords: "",
          taxPosTaken: "",
          judicialPredLoc: [],
          legalOpLoc: [],
        };
      }
      let payLoad: any = {
        pan: this.directTaxPosFormGrp.controls.pan.value,
        panName: this.directTaxPosFormGrp.controls.panName.value,
        itemList: itemListArr
      };
      console.log(itemListArr);
      this.taxService.updateTaxPosData(payLoad).subscribe((response: any) => {
        this.isSubmitted = false;
        if (response.status === 1) {
          this.toaster.showSuccess(response.message);
        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError("Required Fields Are Empty");
    }
  }
  /*on issue selection display upload field */


  issueDropdown() {
    this.dataSource = DT_ISSUE_DROPDOWN;
  }
  opentaxPop(content: any, i: any) {
    this.taxPopModalRef = this.modalService.open(content);
  }

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('itemList')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  onSaveClick() {
    this.rmvjudicialModalRef.close(this.directTaxPosFormGrp.value);
  }


}











