import { Component, OnInit } from '@angular/core';
import { LitigationServiceService } from 'src/app/litigation/services/litigation-service.service';
import { AUDIT_ACT_TYPE, FILTER_STATUS_DATA, STATE_LIST, badgeMapAudit } from 'src/app/shared/constant';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { Router } from '@angular/router';
import { AuditService } from '../service/audit.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import _ from 'lodash-es';
import { BusinessService } from 'src/app/businessManagement/services/business.service';
import { BusinessEntityService } from 'src/app/shared/services/business-entity.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { ExcelService } from 'src/app/shared/services/excel.service';

export const FILTER_SUM_CACHE = 'FILTER_SUM_CACHE';

@Component({
  selector: 'app-audit-summary',
  templateUrl: './audit-summary.component.html',
  styleUrls: ['./audit-summary.component.css']
})
export class AuditSummaryComponent implements OnInit {
  navContext = { entityName: '', isTaxPayer: false, entityType: '', pan: '' };
  selectedFilling: any;
  selectedGstin: any;
  selectedPan: any;
  auditSumOfOPEN: any;
  auditSumOfCLOSED: any;
  auditSumOfSCNTRI: any;
  auditSumofOpnGstin: any;
  selectedStateCode: any;
  selectedStateName: any;
  selectedCompanyId: any;
  companyId: any;
  cntOPEN: any
  cntCLOSED: any
  cntSCNTRIG: any
  roleName: any;
  filterObj: any = {};
  showHeader: boolean = false;
  showHeader1: boolean = false;
  showStatus: boolean = true;
  showPop: boolean = false;
  checkIcon: boolean = false;
  statusData: any;
  isFilterDivOpen: boolean = false;
  isFilterhighlited: boolean = false;
  actType: { key: string; value: string; }[];

  tableSize: number = 1000;
  activeTab: any;
  isApplyFilter!: boolean;
  filterType: string = 'Audit Id';
  badgesList!: any[];
  badgeColorArray = ['border-success', 'border-danger', 'border-warning', 'border-dark', 'border-success', 'border-danger', 'border-warning', 'border-dark', 'border-success']
  textColorArray = ['text-success', 'text-danger', 'text-warning', 'text-dark', 'text-success', 'text-danger', 'text-warning', 'text-dark', 'text-success']
  queryData: any;
  filteredQueryData: any;
  filterForm!: UntypedFormGroup;
  criteria: any = [];
  criteriaObje: any = {};
  badgesMap: any = badgeMapAudit;
  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'key',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    // itemsShowLimit: 3,
    // allowSearchFilter: true
  };
  division: any;
  userRoleLs: any;
  selectedUserId: any;
  leagalRegNum: any;
  filterStatus = FILTER_STATUS_DATA;
  stateNames = STATE_LIST.map(state => state.stateName);
  filterPayload: any = {};
  openCriteria: any = {};
  closedCriteria: any = {};
  scnCriteria: any = {};
  perPageLimit = 10;

  perPageLimitSection1 = 10;
  perPageLimitSection2 = 10;
  perPageLimitSection3 = 10;

  pageSection1: number = 1;
  pageSection2: number = 1;
  pageSection3: number = 1;
  tableSection1Config = {
    itemsPerPage: this.perPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'tableSection1Config',
  };
  tableSection2Config = {
    itemsPerPage: this.perPageLimitSection2,
    currentPage: this.pageSection2,
    totalItems: 0,
    id: 'tableSection2Config',
  };
  tableSection3Config = {
    itemsPerPage: this.perPageLimitSection3,
    currentPage: this.pageSection3,
    totalItems: 0,
    id: 'tableSection3Config',
  };

  contextData: any;
  constructor(
    private litService: LitigationServiceService,
    private GS: GetterSetterService,
    private router: Router,
    private auditService: AuditService,
    private common: CommonService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private businessService: BusinessService,
    private entityService: BusinessEntityService,
    private toaster: ToasterService,
    private excelService: ExcelService,
  ) {
    this.actType = AUDIT_ACT_TYPE;
  }

  ngOnInit() {
    this.selectedCompanyId = localStorage.getItem('selectedId');
    this.selectedUserId = this.GS.getSetUserId();
    this.navContext = this.common.getNavContext();
    const filters = sessionStorage.getItem("audit-filters-Data");
    let savedFilters = null
    savedFilters = !!filters ? JSON.parse(filters) : null;
    this.litService.selectedFilling$.pipe(
      debounceTime(1000), distinctUntilChanged()).subscribe((data) => {
        if (data) {
          this.selectedFilling = data;
          this.selectedGstin = this.selectedFilling.gstin;
          this.GS.checkEntityType.subscribe((value) => {
            this.navContext = value;
          });
        }
      });

    if (localStorage.getItem('roleName') === 'NoRole') {
      this.roleName = localStorage.getItem('role');
    } else {
      this.roleName = localStorage.getItem('roleName');
    }
    this.callAllFunction();
    this.setDivisionData();
    this.personResponsibleData();
    this.getLegalRegNum();
  }
  callAllFunction() {
    const filters = localStorage.getItem("filters")
    let savedFilters = null
    savedFilters = !!filters ? JSON.parse(filters) : null;
    this.initFilterForm(savedFilters);
    this.getAuditSumm('OPEN');
    this.getOpenCount();
    this.getClosedCount();
    this.getSCNTriCount();
  }


  setDivisionData() {
    this.businessService.getGstinDiv().subscribe((response: any) => {
      if (response.status === 1) {
        this.division = response?.response?.divList
      }
    })
  }
  personResponsibleData() {
    let model: any = {};
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else if (parse.entityType === 'Business') {
      const cId = sessionStorage.getItem('companyId');
      model.companyId = cId;
    }
    else if (parse.entityType === 'LEGAL') {
      model.companyId = parse.companyId;
    }
    this.litService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }
  getLegalRegNum() {
    const navContext = JSON.parse(this.GS.getNavContextData() || '{}');
    let model = {
      companyId: localStorage.getItem('selectedId'),
      userMailId: this.selectedUserId
    }
    this.entityService.getLegalRegNum(model).subscribe((response: any) => {
      if (response.status == 'SUCCESS') {
        this.leagalRegNum = response.response;
        localStorage.setItem('filterLegalRegNum', this.leagalRegNum);
      }
    })
  }
  createAuditLetter(action: string, type: string, tab: string) {
    this.router.navigate(['/createAudit', { action: action, type: type, tab: tab }]);
  }
  getCaseDataAil(auditId: number, action: string, type: string, tab: string) {
    this.router.navigate(['/createAudit', auditId, { action: action, type: type, tab: tab }]);
  }
  getCaseDataAfl(auditId: number, action: string, type: string, tab: string) {
    this.router.navigate(['/createAudit', auditId, { action: action, type: type, tab: tab }]);
  }


  applyFilter(isAddNewFilter: boolean) {
    this.isApplyFilter = true;
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const data = JSON.parse(navCOntextData)
    const filters = sessionStorage.getItem('audit-filters-Data');
    let savedFilters = null;
    this.badgesList = [];
    if (isAddNewFilter) {
      this.generateFilterData();
    } else {
      if (filters) {
        savedFilters = !!filters ? JSON.parse(filters) : null;
        for (let i = 0; i < savedFilters.criterias.length; i++) {
          const element = savedFilters.criterias[i];
          const filteredQueryData: any = {};
          filteredQueryData[element.p] = element.v;
          this.generateBadges(filteredQueryData);
          const value = element.v;
          const keyName = element.p
          this.filterForm.get(keyName)?.setValue(value);
        }
      }
    }
    if (!data.companyId) {
      data.companyId = sessionStorage.getItem('companyId');
    }

    this.filterPayload = {
      page: 0,
      size: 1000,
      sortfield: 'auditId',
      sortdir: 'DESC',
      companyId: data.companyId,
      criterias: (isAddNewFilter) ? this.criteria : savedFilters.criterias
    }
    let isOpen = this.filterPayload.criterias.some((element: any) => element.v == 'OPEN');
    if (!isOpen) {
      this.filterPayload.criterias.push(this.openCriteria = {
        "p": "status",
        "o": "eq",
        "v": 'OPEN'
      });
    }
    if (this.navContext.entityType === 'FILING') this.filterPayload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') this.filterPayload.pan = this.navContext.pan;
    sessionStorage.setItem('Audit-filter', JSON.stringify(this.filterPayload))

    this.auditService.getAllCaseSummary(this.filterPayload).subscribe((res: any) => {
      if (res.response == null) {
        this.auditSumOfOPEN = []
      } else {
        this.auditSumOfOPEN = res.response;
      }
    })
    this.auditService.getAllCaseCount(this.filterPayload).subscribe((response: any) => {
      if (response.status == 1) {
        this.cntOPEN = response.response;
        this.tableSection1Config.totalItems = this.cntOPEN;
      }
    });

    if (this.filterPayload) { sessionStorage.setItem("audit-filters-Data", JSON.stringify(this.filterPayload)) }
    this.modalService.dismissAll();
    this.isFilterApplied('CLOSED');
    this.isFilterApplied('CLOSED SCN ISSUED');

  }
  isFilterApplied(acctype: any) {
    this.activeTab = acctype;
    if (this.isApplyFilter) {
      if (acctype == 'OPEN') {
        const payLoad = sessionStorage.getItem('Audit-filter')!;
        const data = JSON.parse(payLoad)
        if (data.criterias.length > 1) {
          this.auditService.getAllCaseCount(data).subscribe((response: any) => {
            if (response.status == 1) {
              this.cntOPEN = response.response;
              this.tableSection1Config.totalItems = this.cntOPEN;
            }
          });

          this.auditService.getAllCaseSummary(data).subscribe((res: any) => {
            this.auditSumOfOPEN = res.response;
          })

        }
        else {
          this.openCriteria = [{
            "p": "status",
            "o": "eq",
            "v": 'OPEN'
          }];
          this.filterPayload = {
            page: 0,
            size: 10,
            sortfield: 'auditId',
            sortdir: 'DESC',
            companyId: data.companyId,
            criterias: this.openCriteria,
          }
          if (this.navContext.entityType === 'FILING') this.filterPayload.gstin = this.selectedGstin;
          if (this.navContext.entityType === 'LEGAL') this.filterPayload.pan = this.navContext.pan;
          this.auditService.getAllCaseSummary(this.filterPayload).subscribe((res: any) => {
            this.auditSumOfOPEN = res.response;
          })
          this.filterPayload = {
            page: 0,
            size: 1000,
            sortfield: 'auditId',
            sortdir: 'DESC',
            companyId: data.companyId,
            criterias: this.openCriteria,
          }
          this.auditService.getAllCaseCount(this.filterPayload).subscribe((response: any) => {
            if (response.status == 1) {
              this.cntOPEN = response.response;
              this.tableSection1Config.totalItems = this.cntOPEN;
            }
          });
        }

      }
      else if (acctype == 'CLOSED') {
        const payLoad = sessionStorage.getItem('Audit-filter')!;
        const data = JSON.parse(payLoad)
        if (data.criterias.length > 1) {
          if (data.criterias.length === 1) {
            data.criterias[0].v = 'CLOSED';
          }
          else {
            data.criterias[1].v = 'CLOSED';
          }
          const modifiedData = data
          this.auditService.getAllCaseCount(modifiedData).subscribe((response: any) => {
            if (response.status == 1) {
              this.cntCLOSED = response.response;
              this.tableSection2Config.totalItems = this.cntCLOSED;
            }
          });
          this.auditService.getAllCaseSummary(modifiedData).subscribe((res: any) => {
            this.auditSumOfCLOSED = res.response;
          })
        }
        else {
          this.closedCriteria = [{
            "p": "status",
            "o": "eq",
            "v": 'CLOSED'
          }];
          this.filterPayload = {
            page: 0,
            size: 10,
            sortfield: 'auditId',
            sortdir: 'DESC',
            companyId: data.companyId,
            criterias: this.closedCriteria,
          }
          if (this.navContext.entityType === 'FILING') this.filterPayload.gstin = this.selectedGstin;
          if (this.navContext.entityType === 'LEGAL') this.filterPayload.pan = this.navContext.pan;
          this.auditService.getAllCaseSummary(this.filterPayload).subscribe((res: any) => {
            this.auditSumOfCLOSED = res.response;
          })
          this.filterPayload = {
            page: 0,
            size: 1000,
            sortfield: 'auditId',
            sortdir: 'DESC',
            companyId: data.companyId,
            criterias: this.closedCriteria,
          }
          this.auditService.getAllCaseCount(this.filterPayload).subscribe((response: any) => {
            if (response.status == 1) {
              this.cntCLOSED = response.response;
              this.tableSection2Config.totalItems = this.cntCLOSED;
            }
          });
        }
      }
      else if (acctype == 'CLOSED SCN ISSUED') {
        const payLoad = sessionStorage.getItem('Audit-filter')!;
        const data = JSON.parse(payLoad)
        if (data.criterias.length > 1) {
          if (data.criterias.length === 1) {
            data.criterias[0].v = 'CLOSED SCN ISSUED';
          }
          else {
            data.criterias[1].v = 'CLOSED SCN ISSUED';
          }
          const modifiedData = data
          this.auditService.getAllCaseSummary(modifiedData).subscribe((res: any) => {
            this.auditSumOfSCNTRI = res.response;
          })
          if (data.criterias.length != 0) {
            this.auditService.getAllCaseCount(modifiedData).subscribe((response: any) => {
              if (response.status == 1) {
                this.cntSCNTRIG = response.response;
                this.tableSection3Config.totalItems = this.cntSCNTRIG;
              }
            });
          }
        } else {
          this.scnCriteria = [{
            "p": "status",
            "o": "eq",
            "v": 'CLOSED SCN ISSUED'
          }];
          this.filterPayload = {
            page: 0,
            size: 10,
            sortfield: 'auditId',
            sortdir: 'DESC',
            companyId: data.companyId,
            criterias: this.scnCriteria,
          }
          if (this.navContext.entityType === 'FILING') this.filterPayload.gstin = this.selectedGstin;
          if (this.navContext.entityType === 'LEGAL') this.filterPayload.pan = this.navContext.pan;
          this.auditService.getAllCaseSummary(this.filterPayload).subscribe((res: any) => {
            this.auditSumOfSCNTRI = res.response;
          })
          this.filterPayload = {
            page: 0,
            size: 1000,
            sortfield: 'auditId',
            sortdir: 'DESC',
            companyId: data.companyId,
            criterias: this.scnCriteria,
          }
          this.auditService.getAllCaseCount(this.filterPayload).subscribe((response: any) => {
            if (response.status == 1) {
              this.cntSCNTRIG = response.response;
              this.tableSection3Config.totalItems = this.cntSCNTRIG;
            }
          });
        }
      }
    } else {
      this.getAuditSumm(acctype);
    }
  }
  openModal(content: any) {
    this.filterType = 'Audit Id';
    this.modalService.open(content, { windowClass: 'auditfilter-pop' });
  }
  initFilterForm(savedFilters: any) {
    if (savedFilters) {
    }
    this.filterForm = this.formBuilder.group({
      pr: [savedFilters?.pr ? savedFilters.pr : null],
      // st: [savedFilters?.st ? savedFilters.st : null],
      auditId: [savedFilters?.auditId ? savedFilters.auditId : null],
      consultant: [savedFilters?.consultant ? savedFilters.consultant : null],
      div: [savedFilters?.div ? savedFilters.div : null],
      state: [savedFilters?.state ? savedFilters?.state : null],
      gstin: [savedFilters?.gstin ? savedFilters.gstin : null],
      showAcc: [true],
    })
  }
  getFormControlVal(val: any) {
    return this.filterForm.controls[val].value;

  }
  generateBadges(queryData: any) {
    if (queryData != null) {
      if (this.badgesMap) {
        for (var key in this.badgesMap) {
          const badgeObj: any = {};
          if (queryData.hasOwnProperty(key)) {
            badgeObj.key = key;
            badgeObj.name = this.badgesMap[key];
            badgeObj.value = queryData[key];
            this.badgesList.push(badgeObj);
          }
        }
      }

    }

  }
  generateFilterData(isGenerateBadge: boolean = true) {
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const data = JSON.parse(navCOntextData)
    const queryData: any = {
      auditId: this.getFormControlVal('auditId'),
      consultant: this.getFormControlVal('consultant'),
      div: this.getFormControlVal('div'),
      personResponsible: this.getFormControlVal('pr'),
      gstin: this.getFormControlVal('gstin'),
      state: this.getFormControlVal('state'),
    }
    this.criteria = [];
    for (const key in queryData) {
      this.criteriaObje = {};
      if (queryData[key] != null) {

        this.criteriaObje.p = key;
        this.criteriaObje.v = queryData[key];
        if (key === 'state') {
          this.criteriaObje.o = 'in'
        }
        else if (key === 'div') {
          this.criteriaObje.o = 'eq';
        }
        else {
          this.criteriaObje.o = 'contains'
        }
        if (key == 'div') {
          this.criteriaObje.p = 'gstinDiv';
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        }
        else if (key == 'state') {
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        }
        else if (key == 'gstin') {
          let valuesArray = this.criteriaObje.v;
          let values = valuesArray.join(',');
          this.criteriaObje.v = values;
        }
        else if (key == 'pr') {
          this.criteriaObje.p = 'personResponsible';
        }


        this.criteria.push(this.criteriaObje);

      }
    }


    this.filteredQueryData = _.omitBy(queryData, (v) => _.isUndefined(v) || _.isNull(v) || v === '');
    if (isGenerateBadge) {
      this.generateBadges(this.filteredQueryData);
    }
    return this.filteredQueryData;
  }
  reloadSummary() {
    this.getAuditSumm('OPEN');
  }
  removeFilter(badge: any) {
    this.badgesList = this.badgesList.filter((e) => e !== badge);
    switch (badge) {
      case 'Audit Id':
        delete this.queryData['auditId'];
        this.filterForm.patchValue({
          auditId: null,
        });
        break;


      case 'Consultant':
        delete this.queryData['consultant'];
        this.filterForm.patchValue({
          consultant: null,
        });
        break;


      case 'Divison':
        delete this.queryData['div'];

        this.filterForm.patchValue({
          div: null,
        });
        break;

      case 'Person Responsible':
        delete this.queryData['pr'];

        this.filterForm.patchValue({
          pr: null
        });
        break;

      case 'Registration Number':
        delete this.queryData['gstin'];

        this.filterForm.patchValue({
          gstin: null
        });
        break;

      // case 'Status':
      //   delete this.queryData['st'];
      //   this.filterForm.patchValue({
      //     st: null
      //   });
      //   break;

      case 'State':
        delete this.queryData['state'];

        this.filterForm.patchValue({
          state: null
        });
        break;
    }

    const BadgArray = this.badgesList;
    const filterData: any = sessionStorage.getItem('Audit-filter');
    const parseData = JSON.parse(filterData);
    const criteriaArray: any = parseData.criterias;

    let matchedObjects = criteriaArray.filter((obj1: any) => {
      return this.badgesList.some(obj2 => obj1.p === obj2.key && obj1.v === obj2.value);
    });

    const revisedFilter: any = (sessionStorage.getItem('audit-filters-Data'));
    const parseFilterData = JSON.parse(revisedFilter);
    parseData.criterias = matchedObjects;
    parseFilterData.criterias = matchedObjects;

    sessionStorage.setItem('Audit-filter', JSON.stringify(parseData));
    sessionStorage.setItem('audit-filters-Data', JSON.stringify(parseFilterData));
    this.reloadSummary();
    this.clearAll();
  }
  clearAll() {
    this.filterForm.reset();
  }

  getSection1Data(perPageLimitSection1: any, section1page: number, option: string) {
    this.auditSumOfOPEN = [];
    let tempKeyword: any = [];
    let countModel: any = {};
    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.sortfield = 'auditId';
    countModel.sortdir = 'DESC';
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
      const businessCriteria: any = [{
        "p": "status",
        "o": "eq",
        "v": 'OPEN'
      }]
      countModel.criterias = businessCriteria;

    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
      countModel.gstin = this.selectedGstin;
      const filingCriteria: any = [{
        "p": "status",
        "o": "eq",
        "v": 'OPEN'
      }, {
        "p": "gstin",
        "o": "eq",
        "v": countModel.gstin
      }]
      countModel.criterias = filingCriteria;

    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
      countModel.pan = this.navContext.pan;
      const legalCriteria: any = [{
        "p": "status",
        "o": "eq",
        "v": 'OPEN'
      }]
      countModel.criterias = legalCriteria;
    }
    this.auditService.getAllCaseCount(countModel).subscribe((response: any) => {
      if (response.status == 1) {
        this.tableSection1Config.totalItems = this.cntOPEN;
      }
    });
    Object.assign(countModel, {
      page: section1page == 1 ? 0 : section1page - 1,
      size: perPageLimitSection1,
      sortfield: 'auditId',
      sortdir: 'DESC',
    });
    // this.subscription.add(
    this.auditService.getAllCaseSummary(countModel).subscribe(
      (response: any) => {
        if (response.status === 1) {
          this.auditSumOfOPEN = response.response;
          this.auditService.getAllCaseCount(countModel).subscribe((response: any) => {
            this.tableSection1Config.totalItems = this.cntOPEN;
          });
          this.auditSumOfOPEN.sort((a: any, b: any) => a.auditId - b.auditId);
          if (option === "select") {
            this.tableSection1Config.totalItems = response.response.count;
            this.tableSection1Config.itemsPerPage = perPageLimitSection1;
            this.tableSection1Config.currentPage = countModel.pageNumber + 1;
          }
        }
      },
      (err) => {
        this.toaster.showError('User Role Based Access Restricted');
      }
    );
  }
  getSection2Data(perPageLimitSection2: any, pageSection2: number, option: string) {
    this.auditSumOfCLOSED = [];
    let countModel: any = {};
    countModel.page = this.pageSection1;
    countModel.size = this.tableSize;
    countModel.sortfield = 'auditId';
    countModel.sortdir = 'DESC';
    const navCOntextData: any = sessionStorage.getItem('navContext');

    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
      const businessCriteria: any = [{
        "p": "status",
        "o": "eq",
        "v": 'CLOSED'
      }]
      countModel.criterias = businessCriteria;
      // countModel.gstin = '';
      // countModel.pan = '';

    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
      countModel.gstin = this.selectedGstin;
      const filingCriteria: any = [{
        "p": "status",
        "o": "eq",
        "v": 'CLOSED'
      }, {
        "p": "gstin",
        "o": "eq",
        "v": countModel.gstin
      }]
      countModel.criterias = filingCriteria;

      // countModel.pan = '';
    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
      //countModel.gstin = '';
      countModel.pan = this.navContext.pan;
      const legalCriteria: any = [{
        "p": "status",
        "o": "eq",
        "v": 'CLOSED'
      }]
      countModel.criterias = legalCriteria;
    }
    Object.assign(countModel, {
      page: pageSection2 == 1 ? 0 : pageSection2 - 1,
      size: perPageLimitSection2,
      sortfield: 'auditId',
      sortdir: 'DESC',
    });
    // this.subscription.add(
    this.auditService.getAllCaseSummary(countModel).subscribe(
      (response: any) => {
        if (response.status === 1) {
          this.auditSumOfCLOSED = response.response;
          this.tableSection2Config.totalItems = this.auditSumOfCLOSED;
          this.auditSumOfCLOSED.sort((a: any, b: any) => a.auditId - b.auditId);

          if (option === "select") {
            this.tableSection2Config.totalItems = response.response.count;
            this.tableSection2Config.itemsPerPage = perPageLimitSection2;
            this.tableSection2Config.currentPage = countModel.pageNumber + 1;
          }
        }
      },
      (err) => {
        this.toaster.showError('User Role Based Access Restricted');
      }
    );
  }
  getSection3Data(perPageLimitSection3: any, section3page: number, option: string) {
    this.auditSumOfSCNTRI = [];

    let tempKeyword: any = [];
    let countModel: any = {};
    countModel.page = this.pageSection1 - 1;
    countModel.size = this.tableSize;
    countModel.sortfield = 'auditId';
    countModel.sortdir = 'DESC';
    const navCOntextData: any = sessionStorage.getItem('navContext');

    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
      const businessCriteria: any = [{
        "p": "status",
        "o": "eq",
        "v": 'CLOSED SCN ISSUED'
      }]
      countModel.criterias = businessCriteria;
    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
      countModel.gstin = this.selectedGstin;
      const filingCriteria: any = [{
        "p": "status",
        "o": "eq",
        "v": 'CLOSED SCN ISSUED'
      }, {
        "p": "gstin",
        "o": "eq",
        "v": countModel.gstin
      }]
      countModel.criterias = filingCriteria;

    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
      countModel.pan = this.navContext.pan;
      const legalCriteria: any = [{
        "p": "status",
        "o": "eq",
        "v": 'CLOSCLOSED SCN ISSUEDED'
      }]
      countModel.criterias = legalCriteria;
    }
    Object.assign(countModel, {
      page: section3page == 1 ? 0 : section3page - 1,
      size: perPageLimitSection3,
      sortfield: 'auditId',
      sortdir: 'DESC',
    });
    // this.subscription.add(
    this.auditService.getAllCaseSummary(countModel).subscribe(
      (response: any) => {
        if (response.status === 1) {
          this.auditSumOfSCNTRI = response.response;
          this.tableSection3Config.totalItems = this.auditSumOfSCNTRI;
          this.auditSumOfSCNTRI.sort((a: any, b: any) => a.auditId - b.auditId);

          if (option === "select") {
            this.tableSection3Config.totalItems = response.response.count;
            this.tableSection3Config.itemsPerPage = perPageLimitSection3;
            this.tableSection3Config.currentPage = countModel.pageNumber + 1;
          }

          if (this.auditSumOfSCNTRI.length > 0) {
            this.auditSumOfSCNTRI.forEach((element: any) => {
              element.expanded = false;
              tempKeyword = [];
              if (element.itemList) {
                element.itemList.forEach((itemEL: any) => {
                  if (itemEL.issues1 && itemEL.issues1.length > 0) {
                    //let tempKeyword: any = [];
                    itemEL.issues1.forEach((issueEl: any) => {
                      tempKeyword.push(issueEl.issue);
                    });
                    element.scnkeywordName = tempKeyword.join(', ');
                  }
                });
              }
            });
          }
        }
      },
      (err) => {
        this.toaster.showError('User Role Based Access Restricted');
      }
    );
  }
  onTableDataChangeSection1(pageNumber: number): void {
    this.tableSection1Config.currentPage = pageNumber;
    this.getSection1Data(
      this.tableSection1Config.itemsPerPage,
      this.tableSection1Config.currentPage, ''
    );
    // this.fetchPosts();
  }
  onTableDataChangeSection2(pageNumber: number) {
    this.tableSection2Config.currentPage = pageNumber;
    this.getSection2Data(
      this.tableSection2Config.itemsPerPage,
      this.tableSection2Config.currentPage, ''
    );
  }
  onTableDataChangeSection3(pageNumber: number) {
    this.tableSection3Config.currentPage = pageNumber;
    this.getSection3Data(
      this.tableSection3Config.itemsPerPage,
      this.tableSection3Config.currentPage, ''
    );
  }
  currentStatusFilter(status: any, type: any) {
    this.badgesList = [];
    this.badgesList.push('Status');
    const navCOntextData: any = (sessionStorage.getItem('navContext'));
    const data = JSON.parse(navCOntextData);
    const badgeObjStatus: any = {};
    badgeObjStatus.key = 'status';
    badgeObjStatus.name = 'Status';
    badgeObjStatus.value = status;
    this.badgesList.push(badgeObjStatus);
    const criteriaData = [{
      p: 'status',
      o: 'eq',
      v: status
    }]
    if (!data.companyId) {
      data.companyId = sessionStorage.getItem('companyId');
    }
    if (data.pan) {
      this.filterPayload = {
        page: 0,
        size: 10,
        sortfield: 'auditId',
        sortdir: 'DESC',
        gstin: '',
        companyId: data.companyId,
        criterias: criteriaData,
        pan: data.pan
      }
    } else {
      this.filterPayload = {
        page: 0,
        size: 10,
        sortfield: 'auditId',
        sortdir: 'DESC',
        gstin: '',
        companyId: data.companyId,
        criterias: criteriaData
      }
    }
    if (type == 'OPEN') {
      this.auditService.getAllCaseSummary(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.auditSumOfOPEN = []
        } else {
          this.auditSumOfOPEN = res.response;
        }
      })
    } else if (type == 'CLOSED') {
      this.auditService.getAllCaseSummary(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.auditSumOfCLOSED = []
        } else {
          this.auditSumOfCLOSED = res.response;
        }
      })
    } else if (type == 'CLOSED SCN ISSUED') {
      this.auditService.getAllCaseSummary(this.filterPayload).subscribe((res: any) => {
        if (res.response == null) {
          this.auditSumOfSCNTRI = []
        } else {
          this.auditSumOfSCNTRI = res.response;
        }
      })
    }

  }
  /*summary data  */
  getAuditSumm(acctype: any) {
    const filters = sessionStorage.getItem('audit-filters-Data');
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    if (filters) {
      this.applyFilter(false);
    }
    else {
      if (acctype === 'OPEN') {
        this.openCriteria = {
          "p": "status",
          "o": "eq",
          "v": acctype
        }
        this.auditSumOfOPEN = [];
        let countModel: any = {};
        countModel.page = this.pageSection1 - 1;
        countModel.size = this.tableSize;
        countModel.sortfield = 'auditId';
        countModel.sortdir = 'DESC';
        const navCOntextData: any = sessionStorage.getItem('navContext');

        const parse = JSON.parse(navCOntextData);
        if (parse.entityType == 'Business') {
          const cId = sessionStorage.getItem('companyId');
          countModel.companyId = cId;
          const businessCriteria: any = [{
            "p": "status",
            "o": "eq",
            "v": acctype
          }]
          countModel.criterias = businessCriteria;
        } else if (parse.entityType == 'FILING') {
          countModel.companyId = parse.companyId;
          countModel.gstin = parse.gstin;
          const filingCriteria: any = [{
            "p": "status",
            "o": "eq",
            "v": acctype
          }, {
            "p": "gstin",
            "o": "eq",
            "v": countModel.gstin
          }]
          countModel.criterias = filingCriteria;
        } else if (parse.entityType == 'LEGAL') {
          countModel.companyId = parse.companyId;
          countModel.pan = this.navContext.pan;
          const legalCriteria: any = [{
            "p": "status",
            "o": "eq",
            "v": acctype
          }]
          countModel.criterias = legalCriteria;
        }
        this.auditService.getAllCaseCount(countModel).subscribe((response: any) => {
          if (response.status == 1) {
            this.cntOPEN = response.response;
            this.tableSection1Config.totalItems = this.cntOPEN;
          }
        });
        this.auditService.getAllCaseSummary(countModel).subscribe((response: any) => {
          if (response.status === 1) {
            this.auditSumOfOPEN = response.response;
          }
        });

      }
      else if (acctype === 'CLOSED') {
        this.closedCriteria = {
          "p": "status",
          "o": "eq",
          "v": acctype
        }
        this.auditSumOfCLOSED = [];

        let countModel: any = {};
        countModel.page = this.pageSection1 - 1;
        countModel.size = this.tableSize;
        countModel.sortfield = 'auditId';
        countModel.sortdir = 'DESC';
        const navCOntextData: any = sessionStorage.getItem('navContext');

        const parse = JSON.parse(navCOntextData);
        if (parse.entityType == 'Business') {
          const cId = sessionStorage.getItem('companyId');
          countModel.companyId = cId;
          const businessCriteria: any = [{
            "p": "status",
            "o": "eq",
            "v": acctype
          }]
          countModel.criterias = businessCriteria;

        } else if (parse.entityType == 'FILING') {
          countModel.companyId = parse.companyId;
          countModel.gstin = parse.gstin;
          const filingCriteria: any = [{
            "p": "status",
            "o": "eq",
            "v": acctype
          }, {
            "p": "gstin",
            "o": "eq",
            "v": countModel.gstin
          }]
          countModel.criterias = filingCriteria;

          countModel.pan = '';
        } else if (parse.entityType == 'LEGAL') {
          countModel.companyId = parse.companyId;
          countModel.gstin = '';
          countModel.pan = this.navContext.pan;
          const legalCriteria: any = [{
            "p": "status",
            "o": "eq",
            "v": acctype
          }]
          countModel.criterias = legalCriteria;
        }
        this.auditService.getAllCaseCount(countModel).subscribe((response: any) => {
          if (response.status == 1) {
            this.cntCLOSED = response.response;
            this.tableSection2Config.totalItems = this.cntCLOSED;
          }
        });
        this.auditService.getAllCaseSummary(countModel).subscribe((response: any) => {
          if (response.status == 1) {
            this.auditSumOfCLOSED = response.response;
          }
        });
      } else if (acctype === 'CLOSED SCN ISSUED') {
        this.scnCriteria = {
          "p": "status",
          "o": "eq",
          "v": acctype
        }
        this.auditSumOfSCNTRI = [];

        let countModel: any = {};
        countModel.page = this.pageSection1 - 1;
        countModel.size = this.tableSize;
        countModel.sortfield = 'auditId';
        countModel.sortdir = 'DESC';
        const navCOntextData: any = sessionStorage.getItem('navContext');

        const parse = JSON.parse(navCOntextData);
        if (parse.entityType == 'Business') {
          const cId = sessionStorage.getItem('companyId');
          countModel.companyId = cId;
          const businessCriteria: any = [{
            "p": "status",
            "o": "eq",
            "v": acctype
          }]
          countModel.criterias = businessCriteria;
          countModel.gstin = '';
          countModel.pan = '';

        } else if (parse.entityType == 'FILING') {
          countModel.companyId = parse.companyId;
          countModel.gstin = parse.gstin;
          const filingCriteria: any = [{
            "p": "status",
            "o": "eq",
            "v": acctype
          }, {
            "p": "gstin",
            "o": "eq",
            "v": countModel.gstin
          }]
          countModel.criterias = filingCriteria;

          countModel.pan = '';
        } else if (parse.entityType == 'LEGAL') {
          countModel.companyId = parse.companyId;
          countModel.gstin = '';
          countModel.pan = this.navContext.pan;
          const legalCriteria: any = [{
            "p": "status",
            "o": "eq",
            "v": acctype
          }]
          countModel.criterias = legalCriteria;
        }
        this.auditService.getAllCaseCount(countModel).subscribe((response: any) => {
          if (response.status == 1) {
            this.cntSCNTRIG = response.response;
            this.tableSection3Config.totalItems = this.cntSCNTRIG;
          }
        });
        this.auditService.getAllCaseSummary(countModel).subscribe((response: any) => {
          if (response.status == 1) {
            this.auditSumOfSCNTRI = response.response;
          }
        });
      }

    }
  }
  /*summary data  */
  /*convert state code to state */
  getStateName(code: any) {
    if (code)
      return this.common.getStateName(code);
  }
  /*convert state code to state */
  getFileNameDta(fileUrl: any) {
    return this.litService.getFileName(fileUrl);
  }
  setState(gstin: any) {
    if (gstin) {
      this.selectedStateCode = gstin.slice(0, 2);
      return this.common.getStateName(this.selectedStateCode);
    }

  }
  /*count api */
  getOpenCount() {
    let countModel: any = {};
    countModel.page = this.pageSection1 - 1;
    countModel.size = this.tableSize;
    countModel.sortfield = 'auditId';
    countModel.sortdir = 'DESC';
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const criteriaArray: any = [{
      "p": "status",
      "o": "eq",
      "v": "OPEN"
    }]
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
    }
    countModel.criterias = criteriaArray;
    this.auditService.getAllCaseCount(countModel).subscribe((response: any) => {
      if (response.status == 1) {
        this.cntOPEN = response.response;
        this.tableSection1Config.totalItems = this.cntOPEN;
      }
    });
  }
  getClosedCount() {
    let countModel: any = {};
    countModel.page = this.pageSection1 - 1;
    countModel.size = this.tableSize;
    countModel.sortfield = 'auditId';
    countModel.sortdir = 'DESC';
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const criteriaArray: any = [{
      "p": "status",
      "o": "eq",
      "v": "CLOSED"
    }]
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
    }
    countModel.criterias = criteriaArray;
    this.auditService.getAllCaseCount(countModel).subscribe((response: any) => {
      if (response.status == 1) {
        this.cntCLOSED = response.response;
        this.tableSection2Config.totalItems = this.cntCLOSED;
      }
    });
  }
  getSCNTriCount() {
    let countModel: any = {};
    countModel.page = this.pageSection1 - 1;
    countModel.size = this.tableSize;
    countModel.sortfield = 'auditId';
    countModel.sortdir = 'DESC';
    const navCOntextData: any = sessionStorage.getItem('navContext');
    const criteriaArray: any = [{
      "p": "status",
      "o": "eq",
      "v": "CLOSED SCN ISSUED"
    }]
    const parse = JSON.parse(navCOntextData);
    if (parse.entityType == 'Business') {
      const cId = sessionStorage.getItem('companyId');
      countModel.companyId = cId;
    } else if (parse.entityType == 'FILING') {
      countModel.companyId = parse.companyId;
    } else if (parse.entityType == 'LEGAL') {
      countModel.companyId = parse.companyId;
    }
    countModel.criterias = criteriaArray;
    this.auditService.getAllCaseCount(countModel).subscribe((response: any) => {
      if (response.status == 1) {
        this.cntSCNTRIG = response.response;
        this.tableSection3Config.totalItems = this.cntSCNTRIG;
      }
    });
  }
  /*count api */
  set CACHE(data: any) {
    sessionStorage.setItem(FILTER_SUM_CACHE, JSON.stringify(data));
  }
  get CACHE() {
    let cacheData = sessionStorage.getItem(FILTER_SUM_CACHE);
    if (cacheData) {
      return JSON.parse(cacheData);
    } else {
      return null;
    }

    //return JSON.parse(sessionStorage.getItem(CACHINGKEY)|| '{}');
  }
  openDiv() {
    this.isFilterDivOpen = true;
  }

  generatedReport() {
    this.showHidePop();
    const filters = sessionStorage.getItem("audit-filters-Data");
    let savedFilters = null;
    savedFilters = !!filters ? JSON.parse(filters) : null;
    if (filters) {
      savedFilters = !!filters ? JSON.parse(filters) : null;
    }
    let model: any = {};
    const criteriaArray: any = [{
      "p": "pan",
      "o": "eq",
      "v": this.navContext.pan
    }]

    if (this.navContext.entityType === 'Business') {
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 5;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? savedFilters.criterias : [];

    } else if (this.navContext.entityType === 'LEGAL') {
      const savedFilterCritera = savedFilters !== null ? savedFilters.criterias : [];
      const mergeCriteriaArray = [...savedFilterCritera, ...criteriaArray];
      model.companyId = localStorage.getItem('selectedId');
      model.reportType = 5;
      model.sortField = 'createdOn';
      model.sortDir = 'DESC';
      model.criterias = savedFilters ? savedFilters.criterias : [];

    }
    this.litService.generateReportDashboard(model, '5').subscribe((response) => {
      this.toaster.showSuccess("Request submitted successfully");
    })
  }
  showHidePop() {
    this.showPop = !this.showPop;
    setTimeout(() => {
      // set it to run again after ten minutes
      this.showPop = false;
      this.toaster.showError(
        'Request is time out, please place new request after some time.'
      );
    }, 600000);
  }
  closeDiv() {
    this.showPop = false;
  }
  relopageRefresh() {
    if (this.router.url.includes('auditSummary')) {
      this.common.reloadCurrentRoute();
    }
  }
  statusReport() {
    this.litService.statusMasterReport().subscribe((response: any) => {
      if (response.status === 1) {
        this.statusData = response.response.status;
        if (this.statusData == 1) {
          this.showStatus = false;
          this.toaster.showSuccess('Request processed successfully');
        } else {
          this.toaster.showWarning('Request in progress');
        }
      }
    });
  }

  downLoadMasterReport() {
    this.litService.downLoadReport().subscribe((response) => {
      const rootPan = sessionStorage.getItem('company');
      const date = this.common.getCurrentDate();
      let fileName = `${rootPan}_Audit Summary Report_${date}`;
      this.excelService.saveAsExcelFile(response, fileName, true);
      this.checkIcon = true;
      this.toaster.showSuccess('File downloaded successfully');
      this.relopageRefresh();
    });
  }


}
