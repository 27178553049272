<div class="panel-wrapper">
    <div class="panel-head">
        <div class="page-title">
            <h1><a [routerLink]="'/dtNmVc'">Video Conferencing</a> / <span>Detail View</span></h1>
            <div class="action-bar">
                <a class="iris-gst-pull-right close" [routerLink]="'/dtNmVc'" id="download" title="close">x</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="card">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card-header">
                                <i class="fa fa-info-circle"></i> Basic Information
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>Request ID</label>
                                        <p>{{vcData?.data.videoconferencingObj.adjVcReqstId}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Notice Header Sequence No</label>
                                        <p>{{vcData?.data.videoconferencingObj.parentNoticeheaderSeqNo}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Request Date</label>
                                        <p>{{vcData?.data.videoconferencingObj.parsedRequestDate}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>VC Date</label>
                                        <p>{{vcData?.data.videoconferencingObj.vcAdjRqstDate}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>VC Status</label>
                                        <p>{{vcData?.data.videoconferencingObj.vcStatus}}</p>
                                    </div>
                                    <div class="col-md-4">
                                        <label>Notice ID</label>
                                        <p>{{vcData?.data.videoconferencingObj.vcStatus}}</p>
                                    </div>
                                    <div class="col-md-12">
                                        <label>Assessee Remarks</label>
                                        <p>{{vcData?.data.videoconferencingObj.assesseeRemarks}}</p>
                                    </div>
                                    <div class="col-md-12">
                                        <label>VC Reason</label>
                                        <p>{{vcData?.data.videoconferencingObj.adjReason}}</p>
                                    </div>

                                    <div class="col-md-12">
                                        <label>VC Url</label>
                                        <p>{{vcData?.data.videoconferencingObj.vcUrl}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>




    </div>
</div>