import { Component, OnInit } from '@angular/core';
import { NoticeServiceService } from '../../services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/services/toaster.service';

const ProceedingStatus = {
  'C': 'Closed',
  'O': 'Open'
}

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.css']
})
export class DetailViewComponent implements OnInit {

  proceedingStatus = {
    'C': 'Closed',
    'O': 'Open'
  }
  proceedingData: any;
  noticeData: any
  proceedingReqId: any;
  noticeCount: any;
  noticeFiles: any[] = [];

  constructor(
    private noticeService: NoticeServiceService,
    private activeSnapShot: ActivatedRoute,
    private toaster: ToasterService,
    private router: Router,
  ) {
    this.proceedingReqId = this.activeSnapShot.snapshot.params.proceedingReqId;
  }

  ngOnInit(): void {
    this.proceedingListData();
    this.noticeListData();
  }

  splitfilename(filename: string) {
    const parts = filename.split('_');
    const datePart = parts[parts.length - 1].split('(')[0];
    return `${parts[0]}_xxx_${datePart}`;
  }


  //proceeding list data api
  proceedingListData() {
    const payload: any = {
      page: 0,
      size: 50,
      module: "DT",
      criterias: [{
        "p": "proceedingReqId",
        "o": "in",
        "v": this.proceedingReqId
      }]
    }
    this.noticeService.getProceedingListData(payload).subscribe((res) => {
      this.proceedingData = res.response.results[0];

    });
  }
  //proceeding list data api
  //notice list data api
  noticeListData() {
    const payload: any = {
      page: 0,
      size: 50,
      module: "DT",
      criterias: [{
        "p": "proceedingReqId",
        "o": "in",
        "v": this.proceedingReqId
      }]
    }
    this.noticeService.getNoticeListData(payload).subscribe((res) => {
      this.noticeData = res.response.results;
      this.noticeCount = res.response.count;

    });
  }
  //notice list data api


  //file download
  onClickFileName(NoticeFileData: any[] = []) {
    this.noticeFiles = [];
    this.noticeFiles = NoticeFileData;
  }
  download(key: any, id: any, fileName: string) {
    let urlData = {
      s3Key: key,
      id: id
    }
    this.noticeService.dwdDTProcFile(urlData).subscribe((response: any) => {
      this.downloadcontFile(response, fileName);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadcontFile(data: any, fileName: any) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${fileName}`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  onClickFileNameNotice(NoticeFileData: any[] = []) {
    this.noticeFiles = [];
    this.noticeFiles = NoticeFileData;
  }
  downloadNotice(key: any, id: any, fileName: string) {
    let urlData = {
      s3Key: key,
      id: id
    }
    this.noticeService.dwdDTNoticesFile(urlData).subscribe((response: any) => {
      this.downloadcontNoticeFile(response, fileName);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadcontNoticeFile(data: any, fileName: any) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${fileName}`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  //file download

  toNoticeDetail(noticeId: number, parentNoticeheaderSeqNo: any) {
    this.router.navigate(['/dtNmProcdNoticeDetail', noticeId, parentNoticeheaderSeqNo]);
  }

}
