import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartData, ChartOptions } from 'chart.js';
import _ from 'lodash-es';

export interface PieChartData {
  label: string;
  value: number;
}

interface Legends {
  label: string;
  value: number;
  color: string;
  percent?: string;
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.css']
})
export class PieChartComponent implements OnInit {
  @Input() type: 'pie' | 'doughnut' = 'doughnut';
  @Input() data: PieChartData[] = [];
  @Output() clickEvent = new EventEmitter<string>();

  legends: Legends[] = [];
  labels: string[] = [];
  values: number[] = [];
  colors: { backgroundColor?: string[] }[] = [];

  chartdata!: ChartData<'pie', number[], string | string[]>;
  options: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { display: false }, tooltip: { enabled: true, mode: 'nearest' } },
  };

  constructor() { }

  ngOnInit(): void {
    const notEmpty = this.data.find((el) => el.value !== 0);
    if (!notEmpty) return;
    let colors: string[] = [];
    this.data.forEach((el) => {
      colors.push(el.label.toColor(colors));
    });
    this.labels = this.data.map((el) => el.label);
    this.values = this.data.map((el) => el.value);
    this.chartdata = { labels: this.labels, datasets: [{ data: this.values }] };

    this.colors = [{ backgroundColor: colors }];

    const totalValue = _.sum(this.values);
    this.legends = this.labels.map((el, index) => {
      return {
        label: el,
        value: this.values[index],
        color: colors[index],
        percent: ((this.values[index] / totalValue) * 100).toFixed(1)
      };
    });
  }
 
  handleClick(e: any) {
    // const currentLabel = e.active?.[0]?._model?.label;
    // if (currentLabel && currentLabel != '') {
    //   this.clickEvent.emit(currentLabel);
    // }

    const index = e.active?.[0]?.index ?? -1;
    if (index > -1) {
      this.clickEvent.emit(this.labels[index]);
    }
  }
}
