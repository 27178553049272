import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe, DecimalPipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/services/auth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiInterceptor } from './shared/interceptors/api-interceptor';
import { TokenService } from './shared/services/token.service';
import { ToasterService } from './shared/services/toaster.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './base/header/header.component';
import { FooterComponent } from './base/footer/footer.component';
import { LitigationSummaryComponent } from './litigation/litigation-summary/litigation-summary.component';
import { CaseMasterComponent } from './litigation/case-master/case-master.component';
import { NoticeComponent } from './litigation/notice/notice.component';
import { AdjudicationAuthComponent } from './litigation/adjudication-auth/adjudication-auth.component';
import { AppellateAuthComponent } from './litigation/appellate-auth/appellate-auth.component';
import { AppellateTribComponent } from './litigation/appellate-trib/appellate-trib.component';
import { HCComponent } from './litigation/hc/hc.component';
import { SCComponent } from './litigation/sc/sc.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { TokenVerifyComponent } from './auth/components/token-verify/token-verify.component';
import { LoaderComponent } from './base/loader/loader.component';
import { SessionClearComponent } from './auth/components/session-clear/session-clear.component';
import { FilterComponent } from './litigation/filter/filter.component';
import { EventEmitterService } from './shared/services/event-emitter.service';
import { AuditDashboardComponent } from './audit/audit-dashboard/audit-dashboard.component';
import { AuditCompanyGSTComponent } from './audit/audit-company-gst/audit-company-gst.component';
import { AuditTaxPositionComponent } from './audit/audit-tax-position/audit-tax-position.component';
import { AuditReconComponent } from './audit/audit-recon/audit-recon.component';
import { AuditSummaryComponent } from './audit/audit-summary/audit-summary.component';
import { AuditCompanyOutputTaxComponent } from './audit/audit-company-output-tax/audit-company-output-tax.component';
import { AuditCompanyReverseChargeComponent } from './audit/audit-company-reverse-charge/audit-company-reverse-charge.component';
import { AuditCompanyInputTaxCreditComponent } from './audit/audit-company-input-tax-credit/audit-company-input-tax-credit.component';
import { AuditCompanyOtherSpecificItemComponent } from './audit/audit-company-other-specific-item/audit-company-other-specific-item.component';
import { AuditCaseMasterComponent } from './audit/audit-case-master/audit-case-master.component';
import { AuditIntimationLetterComponent } from './audit/audit-intimation-letter/audit-intimation-letter.component';
import { AuditFindingLetterComponent } from './audit/audit-finding-letter/audit-finding-letter.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { AnalyticsComponent } from './litigation/analytics/analytics.component';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SummaryComponent } from './preGstLitigation/pre-gst-summary/summary.component';
import { PreAdjudicationAuthComponent } from './preGstLitigation/pre-adjudication-auth/pre-adjudication-auth.component';
import { PreCommissionerAuthComponent } from './preGstLitigation/pre-commissioner-auth/pre-commissioner-auth.component';
import { PreAppellateTribeComponent } from './preGstLitigation/pre-appellate-tribe/pre-appellate-tribe.component';
import { PreHCComponent } from './preGstLitigation/pre-hc/pre-hc.component';
import { PreSCComponent } from './preGstLitigation/pre-sc/pre-sc.component';
import { PreCaseMasterComponent } from './preGstLitigation/pre-case-master/pre-case-master.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { PreAnalyticsComponent } from './preGstLitigation/pre-analytics/pre-analytics.component';
import { A1ChartComponent } from './preGstLitigation/pre-analytics/charts/a1-chart/a1-chart.component';
import { B1ChartComponent } from './preGstLitigation/pre-analytics/charts/b1-chart/b1-chart.component';
import { A2ChartComponent } from './preGstLitigation/pre-analytics/charts/a2-chart/a2-chart.component';
import { A4ChartComponent } from './preGstLitigation/pre-analytics/charts/a4-chart/a4-chart.component';
import { B2ChartComponent } from './preGstLitigation/pre-analytics/charts/b2-chart/b2-chart.component';

import { LevelWiseDemandAnalysisComponent } from './preGstLitigation/pre-analytics/charts/level-wise-demand-analysis/level-wise-demand-analysis.component';
import { D2Component } from './preGstLitigation/pre-analytics/charts/d2/d2.component';
import { A6ChartComponent } from './preGstLitigation/pre-analytics/charts/a6-chart/a6-chart.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ActWiseTableComponent } from './preGstLitigation/pre-analytics/charts/act-wise-table/act-wise-table.component';
import { IssueDemandComponent } from './commonComponent/issue-demand/issue-demand.component';
import { A7ChartComponent } from './preGstLitigation/pre-analytics/charts/a7-chart/a7-chart.component';
import { SmartUploadComponent } from './litigation/smart-upload/smart-upload.component';
import { TaxPositionsComponent } from './directTax/tax-positions/tax-positions.component';
import { FinancialInformationComponent } from './directTax/financial-information/financial-information.component';
import { DirectTaxSummaryComponent } from './directTax/direct-tax-summary/direct-tax-summary.component';
import { TaxCaseMasterComponent } from './directTax/tax-case-master/tax-case-master.component';
import { EnquiryProceedingComponent } from './directTax/enquiry-proceeding/enquiry-proceeding.component';
import { IntimationOrderComponent } from './directTax/intimation-order/intimation-order.component';
import { AttachmentComponent } from './directTax/common/attachment/attachment.component';
import { AttachmentsTableComponent } from './directTax/common/attachments-table/attachments-table.component';
import { EmailSettingComponent } from './businessManagement/components/email-setting/email-setting.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AssessmentComponent } from './directTax/assessment/assessment.component';
import { DtCommissionerAuthComponent } from './directTax/dt-commissioner-auth/dt-commissioner-auth.component';
import { DtAppellateTribeComponent } from './directTax/dt-appellate-tribe/dt-appellate-tribe.component';
import { DtHcComponent } from './directTax/dt-hc/dt-hc.component';
import { DtScComponent } from './directTax/dt-sc/dt-sc.component';
import { GstSummaryComponent } from './noticeManagement/gst/gst-summary/gst-summary.component';
import { GstNoticeOrderComponent } from './noticeManagement/gst/gst-notice-order/gst-notice-order.component';
import { GstNoticeDetailComponent } from './noticeManagement/gst/gst-notice-detail/gst-notice-detail.component';
import { GstSubmissionComponent } from './noticeManagement/gst/gst-submission/gst-submission.component';
import { AdditionalNoticeDetailComponent } from './noticeManagement/gst/additional-notice-detail/additional-notice-detail.component';
import { AdditionalNoticeComponent } from './noticeManagement/gst/additional-notice/additional-notice.component';
import { GstSubmissionDetailComponent } from './noticeManagement/gst/gst-submission-detail/gst-submission-detail.component';
import { DtAnalyticsComponent } from './directTax/dt-analytics/dt-analytics.component';
import { VcDetailViewComponent } from './noticeManagement/directTax/vc-detail-view/vc-detail-view.component';
import { NoticesComponent } from './noticeManagement/directTax/notices/notices.component';
import { ProceedingsComponent } from './noticeManagement/directTax/proceedings/proceedings.component';
import { DetailViewComponent } from './noticeManagement/directTax/detail-view/detail-view.component';
import { AdjournmentComponent } from './noticeManagement/directTax/adjournment/adjournment.component';
import { VideoConfComponent } from './noticeManagement/directTax/video-conf/video-conf.component';
import { AdjDetailViewComponent } from './noticeManagement/directTax/adj-detail-view/adj-detail-view.component';
import { NoticeDetailViewComponent } from './noticeManagement/directTax/notice-detail-view/notice-detail-view.component';
import { NgSelectModule } from '@ng-select/ng-select';
import './helpers/string-prototypes';
import { SharedChartsModule } from './shared/charts/charts.module';
import { GstVoluntaryPaymentComponent } from './noticeManagement/gst/gst-voluntary-payment/gst-voluntary-payment.component';
import { GstLetterOfUndertakingComponent } from './noticeManagement/gst/gst-letter-of-undertaking/gst-letter-of-undertaking.component';
import { ProcdDetailViewComponent } from './noticeManagement/directTax/procd-detail-view/procd-detail-view.component';
import { DivisionWiseTableComponent } from './preGstLitigation/pre-analytics/charts/division-wise-table/division-wise-table.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    LitigationSummaryComponent,
    CaseMasterComponent,
    NoticeComponent,
    AdjudicationAuthComponent,
    AppellateAuthComponent,
    AppellateTribComponent,
    HCComponent,
    SCComponent,
    TokenVerifyComponent,
    LoaderComponent,
    SessionClearComponent,
    FilterComponent,
    AuditDashboardComponent,
    AuditCompanyGSTComponent,
    AuditTaxPositionComponent,
    AuditReconComponent,
    AuditSummaryComponent,
    AuditCompanyOutputTaxComponent,
    AuditCompanyReverseChargeComponent,
    AuditCompanyInputTaxCreditComponent,
    AuditCompanyOtherSpecificItemComponent,
    AuditCaseMasterComponent,
    AuditIntimationLetterComponent,
    AuditFindingLetterComponent,
    AnalyticsComponent,
    SummaryComponent,
    PreAdjudicationAuthComponent,
    PreCommissionerAuthComponent,
    PreAppellateTribeComponent,
    PreHCComponent,
    PreSCComponent,
    PreCaseMasterComponent,
    PreAnalyticsComponent,
    A1ChartComponent,
    B1ChartComponent,
    A2ChartComponent,
    A4ChartComponent,
    B2ChartComponent,
    LevelWiseDemandAnalysisComponent,
    D2Component,
    A6ChartComponent,
    ActWiseTableComponent,
    DivisionWiseTableComponent,
    IssueDemandComponent,
    A7ChartComponent,
    SmartUploadComponent,
    TaxPositionsComponent,
    FinancialInformationComponent,
    DirectTaxSummaryComponent,
    TaxCaseMasterComponent,
    EnquiryProceedingComponent,
    IntimationOrderComponent,
    AttachmentComponent,
    AttachmentsTableComponent,
    EmailSettingComponent,
    AssessmentComponent,
    DtCommissionerAuthComponent,
    DtAppellateTribeComponent,
    DtHcComponent,
    DtScComponent,
    GstSummaryComponent,
    GstNoticeOrderComponent,
    GstNoticeDetailComponent,
    GstSubmissionComponent,
    AdditionalNoticeComponent,
    AdditionalNoticeDetailComponent,
    GstSubmissionDetailComponent,
    GstVoluntaryPaymentComponent,
    GstLetterOfUndertakingComponent,
    DtAnalyticsComponent,
    ProceedingsComponent,
    NoticesComponent,
    DetailViewComponent,
    AdjournmentComponent,
    VideoConfComponent,
    NoticeDetailViewComponent,
    ProcdDetailViewComponent,
    AdjDetailViewComponent,
    VcDetailViewComponent
  ],
  imports: [
    BaseChartDirective,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    SharedChartsModule,
    HttpClientModule,
    CurrencyMaskModule,
    NgbModule,
    AutocompleteLibModule,
    NgxPaginationModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule,
    DecimalPipe
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    TokenService,
    AuthService,
    ToasterService,
    DatePipe,
    EventEmitterService,
    DecimalPipe,
    provideCharts(withDefaultRegisterables())
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
