<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
        <div class="content-wrapper">
            <section>
                <div class="row">
                    <div class="col-md-12">
                        <ul id="tabsJustified" class="nav nav-tabs iris-gst-display-flex">
                            <li class="nav-item"><a data-target="#emailSetting" data-toggle="tab"
                                    class="nav-link active">Email Setting</a></li>

                        </ul>
                        <br>
                    </div>
                </div>
            </section>
            <div id="tabsJustifiedContent" class="tab-content nopadding">
                <div id="emailSetting" class="tab-pane fade card active show">
                    <div class="main-panel h380">
                        <div class="p20">
                            <div class="row">
                                <div class="col-md-3">
                                    <label>Do you want to receive email notification ?
                                        <a class="clrOrange infoIcon"
                                            title="On selection of no, user will not receive any due date reminder through email."><i
                                                class="fa fa-info-circle" style="margin-left:3px;"></i></a>
                                    </label>
                                </div>
                                <div class="col-md-2">
                                    <label class="switch">
                                        <input type="checkbox" id="togBtn" [(ngModel)]="selected"
                                            (click)="changeValue(selected)">
                                        <div class="slider round"></div>
                                    </label>
                                </div>
                                <!-- <div class="col-md-7">
                                    <span class="text-data" *ngIf="emailOptOutRes.updateDate">Last Updated on: {{emailOptOutRes.updateDate}}</span>
                                </div> -->
                                <div class="col-md-12">
                                    <button type="submit" class="btn btn-primary mr-2" [disabled]="isDisabled"
                                        (click)="submitEmailOptOut()">Submit</button>
                                    <button class="btn btn-primary" (click)="toDashboard()">Back</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>