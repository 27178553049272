<div class="container-fluid page-body-wrapper">
  <div class="breadCrumb">
    <a (click)="backTo()">Notice Management GST</a>>><span>Submissions </span>
  </div>
  <div class="main-panel">
    <div class="row">
      <div class="col-md-12">
        <div class="card-body pb0  mt-60">
          <p class="card-title">
            <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="download" title="Master Report"
              (click)="downloadEXFile()"><i class="fa fa-file-excel-o clrOrange notifyIcon"></i> EXPORT TO EXCEL</a>

          </p>
          <div class="row m-0 highDiv">
            <div class="col-12 nopadding">
              <ul class="action-bar">
                <li class="bg-transparent">
                  <ng-container *ngFor="let badge of badgesList;let i=index">
                    <small [ngClass]="[badgeColorArray[i], textColorArray[i]]"
                      class="bg-opacity-10 border  d-inline-flex fw-semibold mb-3 px-2 py-1 me-2 rounded-2 ">{{badge.name}}:
                      {{ badge.value }}
                      <i type="button" class="mdi mdi-close ms-2" (click)="removeFilter(badge)"></i>
                    </small>
                  </ng-container>
                </li>
              </ul>
            </div>
          </div>

          <div class="filter-section">

            <div class="filters-bar">

              <div class="filter-item">
                <label [ngClass]="{'highlightFilter' : filters.gstin.v !== null}">GSTIN <span
                    (click)="clearFilter('gstin')" [ngClass]="{'highlightclose' : filters.gstin.v !== null}"
                    class="clearBtn"><i class="fa fa-close"></i></span></label>
                <select class="form-select" [(ngModel)]="filters.gstin.v" (ngModelChange)="getSubmissionDataList()">
                  <option [ngValue]="null">All</option>
                  <ng-container *ngFor="let obj of gstinData">
                    <option [ngValue]="obj.gstin">{{obj.gstin}} : {{obj.name}}</option>
                  </ng-container>
                </select>
              </div>

              <div class="filter-item">
                <label [ngClass]="{'highlightFilter' : issueDateArray !== null}">Issue Date <span
                    (click)="clearIssueGst()" [ngClass]="{'highlightclose' : issueDateArray !== null}"
                    class="clearBtn"><i class="fa fa-close"></i></span></label>
                <div class="datepicker">
                  <p-calendar [(ngModel)]="issueDateArray" placeholder="From Date-To Date" [monthNavigator]="true"
                    [yearNavigator]="true" yearRange="2010:{{currentYear}}" dateFormat="dd-mm-yy" dataType="string"
                    selectionMode="range" (ngModelChange)="getSubmissionDataList()"></p-calendar>
                  <i class="date-icon fa fa-calendar" aria-hidden="true"></i>
                </div>
              </div>

            </div>
          </div>


        </div>
      </div>
    </div>
    <div class="row plr20">
      <p *ngIf="!isResData"><span class="count">Total Records: {{recordCount}}</span></p>
      <div class="col-md-12" *ngIf="!isResData">
        <div class="table-responsive nopadding">
          <table class="table">
            <thead>
              <tr>
                <th>GSTIN</th>
                <th>Form Number</th>
                <th>Date of Submission</th>
                <th>ARN Number</th>
                <th>Status</th>
                <th>User Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let obj of subOrderData | paginate: tableSection1Config;" let i="index">
                <tr>
                  <td class="clrBlue">{{obj.data.gstinName}}</td>
                  <td>{{obj.data.frmno}}</td>
                  <td>{{obj.data.parsedSubDt}}</td>
                  <td>{{obj.data.arn}}</td>
                  <td>{{obj.data.sts}}</td>
                  <!--<span class="sucess">  <span class="fail">                           -->
                  <td>
                    <!-- <a class="pr5 mt-1 fs18 mr-3" title="{{obj.data.userAction}}"
                                            *ngIf="obj.data.userAction"><i
                                                class="fa fa-check-square-o clrOrange notifyIcon"></i> </a> -->
                    <a class="pr5  mt-1 fs18 mr-3" title="{{obj.data.userAction}}"
                      *ngIf="obj.data.userAction == 'Yet to read'"><i class="fa fa-eye-slash notifyIcon colorRed"></i>
                    </a>
                    <a class="pr5  mt-1 fs18 mr-3" title="{{obj.data.userAction}}"
                      *ngIf="obj.data.userAction == 'Read by User' "><i class="fa fa-eye notifyIcon colorGreen"></i>
                    </a>
                    <a class="pr5 mt-1 fs18 mr-3" data-toggle="modal" data-target="#accessModel"
                      (click)="setAction(obj.data)"><span class="" title="Update"><i
                          class="fa fa-pencil-square-o "></i></span></a>
                  </td>
                </tr>
                <tr class="borderB">
                  <td>{{obj.data.gstin}}</td>
                  <td colspan="4">{{obj.data.frmdc}}</td>
                  <td>
                    <!-- <a class="pr5 mt-1 fs18 mr-3" data-toggle="modal" data-target="#downloadModel"
                                            id="download"><i class="fa fa-download clrOrange notifyIcon"></i> </a> -->
                    <a class="pr5 mt-1 fs18 mr-3" (click)="toSubmissionDetail(obj.data.applnId)"><span class=""
                        title="View"><i class="fa fa-external-link-square "></i></span></a>

                  </td>

                </tr>
              </ng-container>
            </tbody>
          </table>
          <br />
          <div class="row noMargin plr15">
            <div class="col-md-10 nopadding">
              <pagination-controls (pageChange)="onTableDataChangeSection1($event)"
                id="tableSection1Config"></pagination-controls>
            </div>
            <div class="col-md-2 nopadding">
              <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection1"
                (ngModelChange)="getSection1Data(perPageLimitSection1,1)" id="authority">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <ng-container *ngIf="isResData">
          <p class="noData">No Data Found</p>
        </ng-container>
      </div>
    </div>






    <div class="modal fade" id="accessModel" tabindex="-1" role="dialog" aria-labelledby="accessModelLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="accessModelLabel">Action Taken</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" #closeModal>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Action Required</label>
              <select class="form-control form-select" [(ngModel)]="userActionData.action" styleClass="showAbove"
                appendTo="body">
                <option selected value="">----Select Action----</option>
                <ng-container *ngFor="let obj of userAct" container="body">
                  <option [value]="obj.value">{{obj.label}}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="saveAccess()">Save</button>
          </div>
        </div>
      </div>
    </div>


    <div class="modal fade" id="downloadModel" tabindex="-1" role="dialog" aria-labelledby="downloadModelLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="downloadModelLabel">Attachment</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" #closeModal>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul>
              <li><a href="">PH Adjournment_U.P.pdf</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
