import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartOptions } from 'chart.js';
import _ from 'lodash';
import { CommonService } from 'src/app/shared/services/common.service';
interface Legends {
  label: string;
  color: string;
  value: number;
}
@Component({
  selector: 'app-a4-chart',
  templateUrl: './a4-chart.component.html',
  styleUrls: ['./a4-chart.component.css']
})

export class A4ChartComponent implements OnInit {
  @Input() a2Data : any;
chartLabels :string[] = [];
chartData :any = [];
pieChartLegend=true;
chartType :any = "doughnut";
currentLabel:any;
tableData:any;
isinCR: boolean = false;
  isinLakh: boolean = true;
  selectedIRN: boolean = false;
  selectedData: boolean = false;
  amountMetric: 'lakh' | 'crore' = 'lakh';
colors: { backgroundColor?: string[] }[] = [];
legends: Legends[] = [];
labels: string[] = [];
values: number[] = [];
chartOptions : ChartOptions = {
  responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { display: false }, tooltip: { enabled: true, mode: 'nearest' } },

    // plugins: {
    //   // labels: {
    //   //   render: 'value'
    //   // },
    //   legend: { position: 'right' },
    //   tooltip: { enabled: true, mode: 'nearest' },
    // }
  };
  chartColors:any =[{//backgroundColor: ['#2581c5', '#48d257', '#f6c21a', '#f58913', '#4948cc', '#b5b3b3'],
   // backgroundColor: ['#087168', '#0f978b', '#17c8b9', '#48d7cb', '#73d9d0', '#a7e9e3'],
   backgroundColor: ['#ff5722', '#03a9f4', '#9c27b0', '#e51c23', '#607d8b', '#8bc34a'],
  }];

  constructor(
    private commonService: CommonService,
    private modalService: NgbModal,
    ) { }
  ngOnInit(): void {
    this.initializeData();
  }
  changeStatsValue(selected: any, event: Event) {
    const isCrore = (event.target as HTMLInputElement).checked;
    this.amountMetric = isCrore ? 'crore' : 'lakh';
    if (selected == false) {
      this.isinCR = false;
      this.isinLakh = true;
    }
    else if (selected == true) {
      this.isinCR = true;
      this.isinLakh = false;
    }
    this.initializeData();
  }
  initializeData(){
    // let value:any=[];
    // this.chartLabels = Object.keys(this.a2Data);
    // this.chartLabels.forEach((chartLabel: any) =>{
    //   value.push(this.a2Data[chartLabel]?.total);
    // })
    // this.chartData = { labels: this.chartLabels, datasets: [{ data: value }]};
    // this.chartLabels = this.chartLabels.map(label => label.toUpperCase());

    let value:any=[];

    this.chartLabels = Object.keys(this.a2Data);
    let colors: string[] = [];
    
    if(this.isinCR){
      this.chartLabels.forEach((chartLabel: any) =>{
        value.push(this.a2Data[chartLabel]?.totalInCr);
    })
    }else{
      this.chartLabels.forEach((chartLabel: any) =>{
        value.push(this.a2Data[chartLabel]?.total);
    })
    }
    this.chartLabels.forEach((labels) => {
      colors.push(labels.toColor(colors));
    });
    this.colors = [{ backgroundColor: colors }]

    this.chartData = { labels: this.chartLabels, datasets: [{ data: value,backgroundColor: colors }]};
    this.chartLabels = this.chartLabels.map(label => label.toUpperCase());

   ;

    const totalValue = _.sum(value);
  
    this.legends = this.chartLabels.map((el, index) => {
      return {
        label: el,
        value: value[index],
        color: colors[index],
        percent: ((value[index] / totalValue) * 100).toFixed(1)
      };
    });

  }
  openPieChart(content: any, e: any){
    const index = e.active?.[0]?.index ?? -1;
    this.currentLabel = e.event.chart?.data?.labels?.[index] ?? '';
    
    this.currentLabel = e.active[0]._model.label;
    const keys = Object.keys(this.a2Data[this.currentLabel].details);
    this.tableData = [];
    keys.forEach((key : any) =>{
      this.tableData.push({key: key, value : this.a2Data[this.currentLabel].details[key].total});
    } )
    this.modalService.open(content, { windowClass: 'preanalytics-pop' })
  }

}
