<div class="panel-wrapper">
  <div class="panel-head">
    <div class="page-title">
      <h1><a [routerLink]="'/dtNmProceeding'">Proceedings</a> / <span> View</span></h1>
      <div class="action-bar">
        <a class="iris-gst-pull-right close" [routerLink]="'/dtNmProceeding'" id="download" title="close">x</a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-8">
        <div class="card">
          <div class="row">
            <div class="col-md-12">
              <div class="card-header">
                <i class="fa fa-info-circle"></i> Basic Information
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4">
                    <label>AY </label>
                    <p>{{proceedingData?.proceeding.assessmentYear}}</p>
                  </div>
                  <div class="col-md-4">
                    <label>Notice Section</label>
                    <p>{{proceedingData?.proceeding.noticeName}}</p>
                  </div>
                  <div class="col-md-4">
                    <label>Proceeding Name </label>
                    <p>{{proceedingData?.proceeding.proceedingName}}</p>
                  </div>
                  <div class="col-md-4">
                    <label>ITR Type</label>
                    <p>{{proceedingData?.proceeding.itrType}}</p>
                  </div>
                  <div class="col-md-4">
                    <label>Mark for appeal? </label>
                    <p>
                      <span *ngIf="proceedingData?.proceeding.fileAppeal == true">Yes</span>
                      <span *ngIf="proceedingData?.proceeding.fileAppeal == false">No</span>
                    </p>
                  </div>
                  <div class="col-md-4">
                    <label>Limitation Date </label>
                    <p>{{proceedingData?.proceeding.proceedingLimitationDate}}</p>
                  </div>
                  <div class="col-md-4">
                    <label>Last response Date</label>
                    <p>{{proceedingData?.proceeding.lastResponseSubmittedOn}}</p>
                  </div>
                  <div class="col-md-4">
                    <label>Closure Date </label>
                    <p>{{proceedingData?.proceeding.parsedClosureDate}}</p>
                  </div>
                  <div class="col-md-4">
                    <label>Status </label>
                    <p><span *ngIf="proceedingData?.proceeding.proceedingStatus == 'C'">Close</span>
                      <span *ngIf="proceedingData?.proceeding.proceedingStatus == 'O'">Open</span>
                    </p>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card">
          <div class="row">
            <div class="col-md-12">
              <div class="card-header">
                <i class="fa fa-cloud-download" aria-hidden="true"></i> Clousure Files
              </div>
              <div class="card-body">
                <div class="attachmentDiv">
                  <ng-container *ngIf="proceedingData?.proceedingclosureFiles">
                    <ul *ngFor="let obj of proceedingData.proceedingclosureFiles">
                      <li class="wrapText"><a title="{{obj.name}}"
                          (click)="download(obj.s3Key, obj.proceedingReqId, obj.name)"><i
                            class="fa fa-download clrOrange notifyIcon"></i> {{splitfilename(obj.name)}}</a></li>
                    </ul>
                  </ng-container>
                  <ng-container *ngIf="!proceedingData?.proceedingclosureFiles">
                    <p class="noData">No Attachments Found</p>
                  </ng-container>

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="row">
            <div class="col-md-12">
              <div class="card-header">
                <i class="fa fa-file-text-o"></i> Notices
              </div>
              <div class="card-body plr0">
                <ng-container *ngIf="noticeCount !== 0">
                  <div class="table-responsive nopadding">
                    <table class="table">
                      <thead>
                        <tr>
                          <!-- <th width="10px">&nbsp;</th> -->
                          <th>PAN</th>
                          <th>Notice ID</th>
                          <th>Document Ref. ID</th>
                          <th>Date of Issue</th>
                          <th>Due Date</th>
                          <th>Last Adjourned Date</th>
                          <th>Notice Section</th>
                          <th>Response</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container *ngFor="let obj of noticeData  ">
                          <tr [ngClass]="!obj.data.noticeObj.userAction ? 'markUnreadBg' : 'markReadBg'">
                            <!-- <td><i class="fa fa-circle" [ngClass]="!obj.data.noticeObj.userAction ? 'markUnread' : 'markRead'"  ></i></td> -->
                            <td>
                              <p>{{obj.data.noticeObj.pan}}</p>
                              <p class="subTd">{{obj.data.noticeObj.panName}}</p>
                            </td>
                            <td>
                              <p><a class="anchor"
                                  (click)="toNoticeDetail(obj.data.noticeObj.noticeId, obj.data.noticeObj.headerSeqNo)">{{obj.data.noticeObj.noticeId}}</a>
                              </p>
                              <p class="subTd">{{obj.data.noticeObj.proceedingName}}</p>
                            </td>
                            <td>{{obj.data.noticeObj.documentReferenceId}}</td>
                            <td>{{obj.data.noticeObj.parsedIssuedOn}}</td>
                            <td>{{obj.data.noticeObj.parsedResponseDueDate}}</td>

                            <td>{{obj.data.noticeObj.parsedAdjournmentDueDate}}</td>
                            <td>{{obj.data.noticeObj.noticeSection}}</td>
                            <td>
                              <span *ngIf="obj.data.noticeObj.isSubmitted == 'Y'">Replied</span>
                            </td>
                            <td>
                              <a class="mr-3 anchor" (click)="onClickFileNameNotice(obj.data.files)" data-toggle="modal"
                                data-target="#downloadModel" id="download">Downloads
                              </a> <!--
                             <a *ngIf="!obj.data.noticeObj.userAction" class="anchor" (click)="setAction(obj.data.noticeObj)" data-toggle="modal" data-target="#confirmModel" id="confirm">Mark as read</a>
                              -->
                              <!-- <a *ngIf="obj.data.noticeObj.userAction == 'Yet to read'" class="anchor" (click)="setAction(obj.data.noticeObj)" data-toggle="modal" data-target="#confirmModel" id="confirm">Mark as unread</a> -->

                            </td>
                          </tr>

                        </ng-container>
                      </tbody>
                    </table>
                    <br />
                    <!-- <div class="row noMargin plr15">
            <div class="col-md-10 nopadding">
              <pagination-controls (pageChange)="onTableDataChangeSection1($event)" id="noticeConfig"></pagination-controls>
            </div>
            <div class="col-md-2 nopadding">
              <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection1"  (ngModelChange)="getnoticeData(perPageLimitSection1,1)" id="authority">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>
          </div> -->
                  </div>
                </ng-container>
                <ng-container *ngIf="noticeCount == 0">
                  <p class="noData">No Records Found</p>
                </ng-container>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>



  </div>
</div>
<div class="modal fade" id="downloadModel" tabindex="-1" role="dialog" aria-labelledby="downloadModelLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="downloadModelLabel">Attachment</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" #closeModal>&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="noticeFiles.length !== 0">
          <ul *ngFor="let item of noticeFiles">
            <li class="wrapText"><a title="{{item.docName}}"
                (click)="downloadNotice(item.s3Key, item.noticeId, item.docName)"><i
                  class="fa fa-download clrOrange notifyIcon"></i> {{item.docName}}</a></li>
          </ul>
        </ng-container>
        <ng-container *ngIf="noticeFiles.length == 0">
          <p class="noData">No Attachments Found</p>
        </ng-container>
      </div>
    </div>
  </div>
</div>
