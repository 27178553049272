<form [formGroup]="auditCompOutputTaxFrm">
<div class="row">
    <div class="col-md-12">
        <div class="form-group">
          <label for="exampleInputPassword1">Operating & Other Income <a class="addExtLetter" (click)="addOperatingOtherIncm1()"><i class="fa fa-plus"></i></a> </label>
          <div class=" row">
              <div class="table-responsive">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>Name of Service</th>
                      <th>Description of Service</th>
                      <th>SAC Code</th>
                      <th>GST Rate</th>
                      <th>Exemption Notification</th>
                      <th>Remove</th>
                    </tr>
                  </thead>
                  <tbody formArrayName="operatingOtherIncm1">
                    <tr *ngFor="let div of operatingOtherIncm1Arr().controls; let i=index" id="div{{div}}" [formGroupName]="i">                     
                      <td><input type="text" class="form-control" formControlName="nameOfService"></td>
                      <td><input type="text" class="form-control" formControlName="descService"> </td>
                      <td><input type="text" class="form-control" formControlName="sacCode"> </td>
                      <td><input type="text" class="form-control" formControlName="gstRate"> </td>
                      <td><input type="text" class="form-control" formControlName="exempNotify"> </td>
                      <td><a class="addExtLetter" (click)="rmOperatingOtherIncm1(i)"><i class="fa fa-minus"></i></a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
           

          </div>
        </div>
      </div>
</div>
</form>