import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators, UntypedFormArray } from '@angular/forms';

@Component({
  selector: 'app-audit-company-output-tax',
  templateUrl: './audit-company-output-tax.component.html',
  styleUrls: ['./audit-company-output-tax.component.css']
})
export class AuditCompanyOutputTaxComponent implements OnInit {
  auditCompOutputTaxFrm!: UntypedFormGroup;
  constructor(
    private fBuild: UntypedFormBuilder
  ) { }

  ngOnInit(){
    this.formInitialize();
    this.addOperatingOtherIncm1();
  }
  formInitialize() {
    this.auditCompOutputTaxFrm = this.fBuild.group({     
      operatingOtherIncm1: this.fBuild.array([]),
    })
  }
  get p1() { return this.auditCompOutputTaxFrm.controls; }

  operatingOtherIncm1Arr(): UntypedFormArray {
    return this.auditCompOutputTaxFrm.get("operatingOtherIncm1") as UntypedFormArray
  }

  newOperatingOtherIncm1Arr(): UntypedFormGroup {
    return this.fBuild.group({
      nameOfService: [null],
      descService: [null],
      sacCode: [null],
      gstRate: [null],
      exempNotify: [null],

    })
  }
  addOperatingOtherIncm1() {
    this.operatingOtherIncm1Arr().push(this.newOperatingOtherIncm1Arr());
  }
  rmOperatingOtherIncm1(i: number) {
    this.operatingOtherIncm1Arr().removeAt(i);
  }
}
