import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ChartOptions } from 'chart.js';
import _ from 'lodash';
import { CommonService } from 'src/app/shared/services/common.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

export enum CASEID {
  STATE = 'State',
  FORUM = 'Forum',
  REGISTRATION_NO = 'Registration NO',
  CASE_ID = 'Case ID',
  CASE_LABEL = 'Case Label',
  TAX = "Tax",
  INTEREST = "Interest",
  PENALTY = "Penalty",
  DEMAND_AMOUNT = "Demand Amount",
  DATE = 'Inception Date'
}
interface Legends {
  label: string;
  color: string;
  value: number;
  percent?: string;
}
@Component({
  selector: 'app-b2-chart',
  templateUrl: './b2-chart.component.html',
  styleUrls: ['./b2-chart.component.css']
})
export class B2ChartComponent implements OnInit {
  @Input() b2Data: any;
  ageingPopSummary: any;
  caseId = CASEID;
  sorting: { state: boolean, phase: boolean, regno: boolean, caseid: boolean, label: boolean, tax: boolean, interest: boolean, penalty: boolean, total: boolean, dateOfReciept:boolean} = { state: false, phase: false, regno: false, caseid: false, label: false, tax: false, interest: false, penalty: false, total: false, dateOfReciept:false };

  chartLabels: string[] = [];
  chartData: any = [];
  isAnalyticsConOne: boolean = false
  pieChartLegend = true;
  chartType: any = "doughnut";
  agingAnalysisRef!: NgbModalRef;
  currentLabel: any;
  tableData: any;
  selectedIRN: boolean = false;
  isinCR: boolean = false;
  isinLakh: boolean = true;
  amountMetric: 'lakh' | 'crore' = 'lakh';
  colors: { backgroundColor?: string[] }[] = [];

  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { display: false }, tooltip: { enabled: true, mode: 'nearest' } },

    // plugins: {
    //   // labels: {
    //   //   render: 'value'
    //   // },
    //   legend: { position: 'right' },
    //   tooltip: { enabled: true, mode: 'nearest' },
    // }
  };
  chartColors: any = [{
    backgroundColor: ['#ff5722', '#03a9f4', '#9c27b0', '#e51c23', '#607d8b', '#8bc34a'],
  }];
  constructor(
    private modalService: NgbModal,
    private commonService: CommonService,
    private router: Router,
    private excelService: ExcelService,
    private toasterService: ToasterService
  ) { }

  ngOnInit() {
    this.initializeData();
  }
  legends: Legends[] = [];
  labels: string[] = [];
  values: number[] = [];

  label: string | undefined;


  initializeData() {
    let value:any=[];

    this.chartLabels = Object.keys(this.b2Data.details);
    let colors: string[] = [];
    
    this.chartLabels.forEach((labels: any) => {
      value.push(this.b2Data.details[labels]?.count);
    })
    this.chartLabels.forEach((labels) => {
      colors.push(labels.toColor(colors));
    });
    this.colors = [{ backgroundColor: colors }];

    this.chartData = { labels: this.chartLabels, datasets: [{ data: value,backgroundColor: colors }]};


    const totalValue = _.sum(value);
  
    this.legends = this.chartLabels.map((el, index) => {
      return {
        label: el,
        value: value[index],
        color: colors[index],
        percent: ((value[index] / totalValue) * 100).toFixed(1)
      };
    });
  }
  changeStatsValue(selected: any, event: Event) {
    const isCrore = (event.target as HTMLInputElement).checked;
    this.amountMetric = isCrore ? 'crore' : 'lakh';
    if (selected == false) {
      this.isinCR = false;
      this.isinLakh = true;
    }
    else if (selected == true) {
      this.isinCR = true;
      this.isinLakh = false;
    }
  }
  sortChartLabels() {
    const numericArr = this.chartLabels.filter(str => /^\d/.test(str));
    const nonNumericArr = this.chartLabels.filter(str => !/^\d/.test(str));
    this.chartLabels = [...numericArr, ...nonNumericArr];

    const lessThanThree: string | undefined = this.chartLabels.find((str: string) => str.startsWith('Less'));
    const moreThanTen: string | undefined = this.chartLabels.find((str: string) => str.startsWith('More'));
    const writ: string | undefined = this.chartLabels.find((str: string) => str.startsWith('writ'));

    if (lessThanThree) {
      const index: number = this.chartLabels.indexOf(lessThanThree);
      this.chartLabels.splice(index, 1);
      this.chartLabels.unshift(lessThanThree);
    }
    if (moreThanTen) {
      const index: number = this.chartLabels.indexOf(moreThanTen);
      this.chartLabels.splice(index, 1);
      this.chartLabels.push(moreThanTen);
    }
    if (writ) {
      const index: number = this.chartLabels.indexOf(writ);
      this.chartLabels.splice(index, 1);
      this.chartLabels.push(writ);
    }

  }

  openPieChart(content: any, e: any) {
    const index = e.active?.[0]?.index ?? -1;
    this.currentLabel = e.event.chart?.data?.labels?.[index] ?? '';
    var agingSummaryData = this.b2Data.details[this.currentLabel].details;
    this.ageingPopSummary = agingSummaryData;
    this.ageingPopSummary.forEach((x: any) => {
      if (x.phase == "aja") {
        x.phase = 'Adjudication';
      } else if (x.phase == "apa") {
        x.phase = 'Commissioner (A)';
      } else if (x.phase == "apt") {
        x.phase = 'Appellate Tribunal';
      } else if (x.phase == "hc") {
        x.phase = 'High Court';
      } else if (x.phase == "sc") {
        x.phase = 'Supreme Court';
      }
    });
    this.agingAnalysisRef = this.modalService.open(content, { windowClass: 'preanalytics-pop' });
  }
  getPreGstCaseData(caseId: number, action: string, type: string, tab: string) {
    this.router.navigate(['createPreGstCase', caseId, { action: action, type: type, tab: tab }]);
    this.agingAnalysisRef.close();
  }
  sortValue(value: number) {
    if (value) {
      return (value).toFixed(2);
    }
    return 0;
  }

  sort(column: string) {
    switch (column) {
      case CASEID.STATE: {
        if (!this.sorting.state) {
          this.isAnalyticsConOne = true;
          this.sorting.state = true;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'state', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.state = false;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'state', 'desc');
        }
        break;
      }

      case CASEID.FORUM: {
        if (!this.sorting.phase) {
          this.isAnalyticsConOne = true;
          this.sorting.phase = true;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'phase', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.phase = false;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'phase', 'desc');
        }
        break;
      }

      case CASEID.REGISTRATION_NO: {
        if (!this.sorting.regno) {
          this.isAnalyticsConOne = true;
          this.sorting.regno = true;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'regno', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.regno = false;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'regno', 'desc');
        }
        break;
      }

      case CASEID.CASE_ID: {
        if (!this.sorting.caseid) {
          this.isAnalyticsConOne = true;
          this.sorting.caseid = true;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'caseid', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.caseid = false;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'caseid', 'desc');
        }
        break;
      }

      case CASEID.CASE_LABEL: {
        if (!this.sorting.label) {
          this.isAnalyticsConOne = true;
          this.sorting.label = true;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'label', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.label = false;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'label', 'desc');
        }
        break;
      }
      case CASEID.TAX: {
        if (!this.sorting.tax) {
          this.isAnalyticsConOne = true;
          this.sorting.tax = true;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'tax', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.tax = false;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'tax', 'desc');
        }
        break;
      }

      case CASEID.INTEREST: {
        if (!this.sorting.interest) {
          this.isAnalyticsConOne = true;
          this.sorting.interest = true;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'interest', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.interest = false;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'interest', 'desc');
        }
        break;
      }

      case CASEID.PENALTY: {
        if (!this.sorting.penalty) {
          this.isAnalyticsConOne = true;
          this.sorting.penalty = true;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'penalty', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.penalty = false;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'penalty', 'desc');
        }
        break;
      }

      case CASEID.DEMAND_AMOUNT: {
        if (!this.sorting.total) {
          this.isAnalyticsConOne = true;
          this.sorting.total = true;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'total', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.total = false;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'total', 'desc');
        }
        break;
      }
      case CASEID.DATE: {
        if (!this.sorting.dateOfReciept) {
          this.sorting.dateOfReciept = true;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'dateOfReciept', 'asc');
        } else {
          this.sorting.dateOfReciept = false;
          this.ageingPopSummary = this.commonService.sortObjectsByKey(this.ageingPopSummary, 'dateOfReciept', 'desc');
        }
        break;

      }
    }
  }

  exportAsXLSX() {
    let excelData: any = [];
    
    if (this.ageingPopSummary.length === 0) {
      this.toasterService.showError('No data to export.');
      return;
    }

    this.ageingPopSummary.forEach((item : any, index: any) => {
        let obj = {
          "Sr No": index + 1,
          "State": item.state,
          "Forum": item.phase,
          "Registration.No":item.regno, 
          "Case Id":  item.caseid,
          "Case Label": item.label,
          "Tax": this.isinLakh ? item.tax.toFixed(2) :item.taxInCr.toFixed(2) ,
          "Interest":this.isinLakh ? item.interest.toFixed(2):item.interestInCr.toFixed(2),
          "Penalty":this.isinLakh ? item.penalty.toFixed(2): item.penaltyInCr.toFixed(2),
          "Total": this.isinLakh ? item.total.toFixed(2): item.totalInCr.toFixed(2),
          "Inception Date":item.dateOfReciept
        }
        excelData.push(obj);
    });

    this.excelService.exportAsExcelFile(excelData, 'Ageing Analysis - Case Id');

  }


}


