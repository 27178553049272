import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PreventGuard  {

  constructor(
    private _router: Router,
    private auth: AuthService,
    private route: ActivatedRoute
  ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.auth.isAuthenticated()) {
      this._router.navigate(['/dashboard']);
      return false;
    } else {
      this.auth.loginIndicator.next(0);
    }
    return true;
  }
}
