<div class="d-flex justify-content-end my-3 me-3 btnPos">
  <div class="switch-box">
    <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
    <div class="form-check form-switch m-0">
      <input [(ngModel)]="selectedIRN" (change)="changeStatsValue(selectedIRN,$event)" class="form-check-input"
        type="checkbox" role="switch" [checked]="amountMetric === 'crore'" />
    </div>
    <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
  </div>
  <p class="btn btn-outline-success btn-download" (click)="exportAsXLSX()">
    <i class="fa fa-file-excel-o me-2" aria-hidden="true"></i> Export
  </p>
</div>


<div class="row" *ngIf="isinLakh">
  <div class="table-responsive">
    <table class="table table-bordered divTbl">
      <thead>
        <tr class="bgGred">
          <th rowspan="2" style="padding:23px;">State</th>
          <th colspan="2">Adjudication</th>
          <th colspan="2">Commissioner (A)</th>
          <th colspan="2">Appellate Tribunal</th>
          <th colspan="2">High Court </th>
          <th colspan="2">Supreme Court</th>
          <th colspan="2">Total</th>
        </tr>
        <tr class="bgGred">
          <th>Case Count</th>
          <th>Demand Amount</th>
          <th>Case Count</th>
          <th>Demand Amount</th>
          <th>Case Count</th>
          <th>Demand Amount</th>
          <th>Case Count</th>
          <th>Demand Amount</th>
          <th>Case Count</th>
          <th>Demand Amount</th>
          <th>Case Count</th>
          <th>Demand Amount</th>
        </tr>
      </thead>
      <tbody class="divTbody">
        <ng-container *ngFor="let obj of data">
          <tr class="stateRow">
            <td class="main-row-label">{{obj.label}} <a class="letterTbl"><span class="expand"
                  (click)="obj.expanded = !obj.expanded">{{ obj.expanded ? '&ndash;' : '+'}}</span></a></td>
            <td class="txtCenter">{{obj.statePhdetails.aja?.totalCount}}</td>
            <td>{{obj.statePhdetails.aja?.total | INR : 2}}</td>
            <td class="txtCenter">{{obj.statePhdetails.apa?.totalCount}}</td>
            <td>{{obj.statePhdetails.apa?.total | INR : 2}}</td>
            <td class="txtCenter">{{obj.statePhdetails.apt?.totalCount}}</td>
            <td>{{obj.statePhdetails.apt?.total | INR : 2}}</td>
            <td class="txtCenter">{{obj.statePhdetails.hc?.totalCount}}</td>
            <td>{{obj.statePhdetails.hc?.total | INR : 2}}</td>
            <td class="txtCenter">{{obj.statePhdetails.sc?.totalCount}}</td>
            <td>{{obj.statePhdetails.sc?.total | INR : 2}}</td>
            <td class="totalBold txtCenter">{{obj.total?.totalCount}}</td>
            <td class="totalBold">{{obj.total?.totaldemand| INR : 2}}</td>
          </tr>
          <ng-container *ngIf="obj.expanded">
            <ng-container *ngFor="let actType of obj.actTypeData">
              <tr>
                <td class="main-row-label pl15">{{actType.label}} <a class="letterTbl"><span class="expand"
                      (click)="actType.expanded = !actType.expanded">{{ actType.expanded ? '&ndash;' : '+'}}</span></a>
                </td>
                <td class="txtCenter">{{actType.phdetails.aja?.totalCount}}</td>
                <td>{{actType.phdetails.aja?.total}}</td>
                <td class="txtCenter">{{actType.phdetails.apa?.totalCount}}</td>
                <td>{{actType.phdetails.apa?.total}}</td>
                <td class="txtCenter">{{actType.phdetails.apt?.totalCount}}</td>
                <td>{{actType.phdetails.apt?.total}}</td>
                <td class="txtCenter">{{actType.phdetails.hc?.totalCount}}</td>
                <td>{{actType.phdetails.hc?.total}}</td>
                <td class="txtCenter">{{actType.phdetails.sc?.totalCount}}</td>
                <td>{{actType.phdetails.sc?.total}}</td>
                <td class="totalBold txtCenter">{{actType.totalCount}}</td>
                <td class="totalBold">{{actType.totaldemand | INR : 2}}</td>

              </tr>
              <ng-container *ngIf="actType.expanded">
                <tr *ngFor="let regDetail of actType.regData" class="expandTr">
                  <td class="pl-4 fw5">{{regDetail.regNumber}}</td>
                  <td class="txtCenter"><a
                      (click)="selectedState ? openCaseState(demandOpenCase, obj.label, 'aja' , regDetail.regNumber) : openCase(demandOpenCase, '', 'aja', regDetail.regNumber,actType.label)">{{regDetail.details.aja?.totalCount}}</a>
                  </td>
                  <td>{{regDetail.details.aja?.totaldemand | INR : 2}}</td>
                  <td class="txtCenter"><a
                      (click)="selectedState ? openCaseState(demandOpenCase, obj.label, 'apa' , regDetail.regNumber) : openCase(demandOpenCase, '', 'apa', regDetail.regNumber,actType.label)">{{regDetail.details.apa?.totalCount}}</a>
                  </td>
                  <td >{{regDetail.details.apa?.totaldemand | INR : 2}}</td>
                  <td class="txtCenter"><a
                      (click)="selectedState ? openCaseState(demandOpenCase, obj.label, 'apt' , regDetail.regNumber) : openCase(demandOpenCase, '', 'apt', regDetail.regNumber,actType.label)">{{regDetail.details.apt?.totalCount}}</a>
                  </td>
                  <td>{{regDetail.details.apt?.totaldemand | INR : 2}}</td>
                  <td class="txtCenter"><a
                      (click)="selectedState ? openCaseState(demandOpenCase, obj.label, 'hc' , regDetail.regNumber) : openCase(demandOpenCase, '', 'hc', regDetail.regNumber,actType.label)">{{regDetail.details.hc?.totalCount}}</a>
                  </td>
                  <td>{{regDetail.details.hc?.totaldemand | INR : 2}}</td>
                  <td class="txtCenter"><a
                      (click)="selectedState ? openCaseState(demandOpenCase, obj.label, 'sc' , regDetail.regNumber) : openCase(demandOpenCase, '', 'sc', regDetail.regNumber,actType.label)">{{regDetail.details.sc?.totalCount}}</a>
                  </td>
                  <td>{{regDetail.details.sc?.totaldemand | INR : 2}}</td>
                  <td class="totalBold txtCenter"><a (click)="allOpenCase(demandOpenCasePOP, '', regDetail.regNumber,actType.label)">{{regDetail.totalCount}}</a></td>
                  <td class="totalBold">{{regDetail.totaldemand | INR : 2}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>

        </ng-container>
        <ng-container *ngIf="divSumColTotal">
          <tr style="background: #ede8e8;font-weight: 600;">
            <td>TOTAL</td>
            <td class="txtCenter">{{divSumColTotalSum?.aja?.totalCount}}</td>
            <td>{{divSumColTotalSum?.aja?.total | INR : 2}}</td>
            <td class="txtCenter">{{divSumColTotalSum?.apa?.totalCount}}</td>
            <td>{{divSumColTotalSum?.apa?.total | INR : 2}}</td>
            <td class="txtCenter">{{divSumColTotalSum?.apt?.totalCount}}</td>
            <td>{{divSumColTotalSum?.apt?.total | INR : 2}}</td>
            <td class="txtCenter">{{divSumColTotalSum?.hc?.totalCount}}</td>
            <td>{{divSumColTotalSum?.hc?.total | INR : 2}}</td>
            <td class="txtCenter">{{divSumColTotalSum?.sc?.totalCount}}</td>
            <td>{{divSumColTotalSum?.sc?.total | INR : 2}}</td>
            <td class="txtCenter">{{divSumColTotal.totalCount}}</td>
            <td>{{divSumColTotal.taxdemand}}</td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
</div>

<div class="row" *ngIf="isinCR">
  <div class="table-responsive">
    <table class="table table-bordered divTbl">
      <thead>
        <tr class="bgGred">
          <th rowspan="2" style="padding:23px;">State</th>
          <th colspan="2">Adjudication</th>
          <th colspan="2">Commissioner (A)</th>
          <th colspan="2">Appellate Tribunal</th>
          <th colspan="2">High Court </th>
          <th colspan="2">Supreme Court</th>
          <th colspan="2">Total</th>
        </tr>
        <tr class="bgGred">
          <th>Case Count</th>
          <th>Demand Amount</th>
          <th>Case Count</th>
          <th>Demand Amount</th>
          <th>Case Count</th>
          <th>Demand Amount</th>
          <th>Case Count</th>
          <th>Demand Amount</th>
          <th>Case Count</th>
          <th>Demand Amount</th>
          <th>Case Count</th>
          <th>Demand Amount</th>
        </tr>
      </thead>
      <tbody class="divTbody">
        <ng-container *ngFor="let obj of data">
          <tr class="stateRow">
            <td class="main-row-label">{{obj.label}} <a class="letterTbl"><span class="expand"
                  (click)="obj.expanded = !obj.expanded">{{ obj.expanded ? '&ndash;' : '+'}}</span></a></td>
            <td class="txtCenter">{{obj.statePhdetails.aja?.totalCount}}</td>
            <td>{{obj.statePhdetails.aja?.totalInCr | INR : 2}}</td>
            <td class="txtCenter">{{obj.statePhdetails.apa?.totalCount}}</td>
            <td>{{obj.statePhdetails.apa?.totalInCr | INR : 2}}</td>
            <td class="txtCenter">{{obj.statePhdetails.apt?.totalCount}}</td>
            <td>{{obj.statePhdetails.apt?.totalInCr | INR : 2}}</td>
            <td class="txtCenter">{{obj.statePhdetails.hc?.totalCount}}</td>
            <td>{{obj.statePhdetails.hc?.totalInCr | INR : 2}}</td>
            <td class="txtCenter">{{obj.statePhdetails.sc?.totalCount}}</td>
            <td>{{obj.statePhdetails.sc?.totalInCr | INR : 2}}</td>
            <td class="totalBold txtCenter">{{obj.total?.totalCount}}</td>
            <td class="totalBold">{{obj.total?.totalDemandInCr | INR : 2}}</td>
          </tr>
          <ng-container *ngIf="obj.expanded">
            <ng-container *ngFor="let actType of obj.actTypeData">
              <tr>
                <td class="main-row-label pl15">{{actType.label}} <a class="letterTbl"><span class="expand"
                      (click)="actType.expanded = !actType.expanded">{{ actType.expanded ? '&ndash;' : '+'}}</span></a>
                </td>
                <td class="txtCenter">{{actType.phdetails.aja?.totalCount}}</td>
                <td>{{actType.phdetails.aja?.totalInCr | INR : 2}}</td>
                <td class="txtCenter">{{actType.phdetails.apa?.totalCount}}</td>
                <td>{{actType.phdetails.apa?.totalInCr | INR : 2}}</td>
                <td class="txtCenter">{{actType.phdetails.apt?.totalCount}}</td>
                <td>{{actType.phdetails.apt?.totalInCr | INR : 2}}</td>
                <td class="txtCenter">{{actType.phdetails.hc?.totalCount}}</td>
                <td>{{actType.phdetails.hc?.totalInCr | INR : 2}}</td>
                <td class="txtCenter">{{actType.phdetails.sc?.totalCount}}</td>
                <td>{{actType.phdetails.sc?.totalInCr | INR : 2}}</td>
                <td class="totalBold txtCenter">{{actType.totalCount}}</td>
                <td class="totalBold">{{actType.totalDemandInCr | INR : 2}}</td>

              </tr>
              <ng-container *ngIf="actType.expanded">
                <tr *ngFor="let regDetail of actType.regData" class="expandTr">
                  <td class="pl-4 fw5">{{regDetail.regNumber}}</td>
                  <td class="txtCenter"><a
                      (click)="selectedState ? openCaseState(demandOpenCase, obj.label, 'aja' , regDetail.regNumber) : openCase(demandOpenCase, '', 'aja', regDetail.regNumber,actType.label)">{{regDetail.details.aja?.totalCount}}</a>
                  </td>
                  <td>{{regDetail.details.aja?.totalDemandInCr | INR : 2}}</td>
                  <td class="txtCenter"><a
                      (click)="selectedState ? openCaseState(demandOpenCase, obj.label, 'apa' , regDetail.regNumber) : openCase(demandOpenCase, '', 'apa', regDetail.regNumber,actType.label)">{{regDetail.details.apa?.totalCount}}</a>
                  </td>
                  <td>{{regDetail.details.apa?.totalDemandInCr | INR : 2}}</td>
                  <td class="txtCenter"><a
                      (click)="selectedState ? openCaseState(demandOpenCase, obj.label, 'apt' , regDetail.regNumber) : openCase(demandOpenCase, '', 'apt', regDetail.regNumber,actType.label)">{{regDetail.details.apt?.totalCount}}</a>
                  </td>
                  <td>{{regDetail.details.apt?.totalDemandInCr | INR : 2}}</td>
                  <td class="txtCenter"><a
                      (click)="selectedState ? openCaseState(demandOpenCase, obj.label, 'hc' , regDetail.regNumber) : openCase(demandOpenCase, '', 'hc', regDetail.regNumber,actType.label)">{{regDetail.details.hc?.totalCount}}</a>
                  </td>
                  <td>{{regDetail.details.hc?.totalDemandInCr | INR : 2}}</td>
                  <td class="txtCenter"><a
                      (click)="selectedState ? openCaseState(demandOpenCase, obj.label, 'sc' , regDetail.regNumber) : openCase(demandOpenCase, '', 'sc', regDetail.regNumber,actType.label)">{{regDetail.details.sc?.totalCount}}</a>
                  </td>
                  <td>{{regDetail.details.sc?.totalDemandInCr | INR : 2}}</td>
                  <td class="totalBold txtCenter"><a (click)="allOpenCase(demandOpenCasePOP, '', regDetail.regNumber,actType.label)">{{regDetail.totalCount}}</a></td>
                  <td class="totalBold">{{regDetail.totalDemandInCr | INR : 2}}</td>
                </tr>
              </ng-container>
            </ng-container>
          </ng-container>

        </ng-container>
        <ng-container *ngIf="divSumColTotal">
          <tr style="background: #ede8e8;font-weight: 600;">
            <td>TOTAL</td>
            <td class="txtCenter">{{divSumColTotalSum?.aja?.totalCount}}</td>
            <td>{{divSumColTotalSum?.aja?.totalInCr | INR : 2}}</td>
            <td class="txtCenter">{{divSumColTotalSum?.apa?.totalCount}}</td>
            <td>{{divSumColTotalSum?.apa?.totalInCr | INR : 2}}</td>
            <td class="txtCenter">{{divSumColTotalSum?.apt?.totalCount}}</td>
            <td>{{divSumColTotalSum?.apt?.totalInCr | INR : 2}}</td>
            <td class="txtCenter">{{divSumColTotalSum?.hc?.totalCount}}</td>
            <td>{{divSumColTotalSum?.hc?.totalInCr | INR : 2}}</td>
            <td class="txtCenter">{{divSumColTotalSum?.sc?.totalCount}}</td>
            <td>{{divSumColTotalSum?.sc?.totalInCr | INR : 2}}</td>
            <td class="txtCenter">{{(divSumColTotal.totalCount)}}</td>
            <td>{{divSumColTotal.taxDemandInCr | INR : 2}}</td>
          </tr>
        </ng-container>

      </tbody>
    </table>
  </div>
 </div>

 <ng-template #demandOpenCase let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">State wise Demand - Open Cases</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="switch-box" style="float: inline-end;">
      <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
      <div class="form-check form-switch m-0">
        <input [(ngModel)]="selectedIRN" (change)="changeStatsValue(selectedIRN,$event)" class="form-check-input"
          type="checkbox" role="switch" [checked]="amountMetric === 'crore'" />
      </div>
      <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
      <p class="btn btn-outline-success btn-download" (click)="exportToExcel('Open Cases')">
        <i class="fa fa-file-excel-o me-2" aria-hidden="true"></i> Export
      </p>
    </div>
    <p class="pieChartLabel txtCenter"></p>
    <div class="table-responsive autoScroll">
      <table id="" class="table table-bordered">
        <thead class="header">
          <tr class="bgGred">
            <th>Sr No.</th>
            <th><span>Act</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" (click)="sort(states.ACT)"
                *ngIf="isAnalyticsConOne"></i><i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                (click)="sort(states.ACT)" *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Case ID</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.CASE_ID)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(states.CASE_ID)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Case Label</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.CASE_LABEL)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(states.CASE_LABEL)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Tax Amount</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.TAX)" *ngIf="isAnalyticsConOne"></i><i class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true" (click)="sort(states.TAX)" *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Interest Amount</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.INTEREST)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(states.INTEREST)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Penalty Amount</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.PENALTY)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(states.PENALTY)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Demand Amount</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.DEMAND_AMOUNT)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(states.DEMAND_AMOUNT)"
                *ngIf="!isAnalyticsConOne"></i></th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngFor="let obj of openCaseData; let i=index">
              <td>{{i+1}}</td>
              <td>{{obj.act}}</td>
              <td *ngIf="obj.phase == 'aja'" class="txtCenter"><a
                  (click)="GetOpenCase(obj.caseId, 'update', 'preaja', 'tab1')">{{obj.caseId}}</a></td>
              <td *ngIf="obj.phase == 'apa'" class="txtCenter"><a
                  (click)="GetOpenCase(obj.caseId, 'update', 'preapa', 'tab2')">{{obj.caseId}}</a></td>
              <td *ngIf="obj.phase == 'apt'" class="txtCenter"><a
                  (click)="GetOpenCase(obj.caseId, 'update', 'preapt', 'tab3')">{{obj.caseId}}</a></td>
              <td *ngIf="obj.phase == 'hc'" class="txtCenter"><a
                  (click)="GetOpenCase(obj.caseId, 'update', 'prehc', 'tab4')">{{obj.caseId}}</a></td>
              <td *ngIf="obj.phase == 'sc'" class="txtCenter"><a
                  (click)="GetOpenCase(obj.caseId, 'update', 'presc', 'tab5')">{{obj.caseId}}</a></td>
              <td>{{obj.label}}</td>
              <td class="textRight" *ngIf="isinLakh">{{obj.tax |INR: 2}}</td>
              <td class="textRight" *ngIf="isinLakh">{{obj.interest |INR: 2}}</td>
              <td class="textRight" *ngIf="isinLakh">{{obj.penalty |INR: 2}}</td>
              <td class="textRight" *ngIf="isinLakh">{{obj.total | INR : 2}}</td>

              <td class="textRight" *ngIf="isinCR">{{obj.taxInCr |INR: 2}}</td>
              <td class="textRight" *ngIf="isinCR">{{obj.interestInCr |INR: 2}}</td>
              <td class="textRight" *ngIf="isinCR">{{obj.penaltyInCr |INR: 2}}</td>
              <td class="textRight" *ngIf="isinCR">{{obj.totalInCr | INR : 2}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #demandOpenCasePOP let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">State wise Demand - Open Cases</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="switch-box" style="float: inline-end;">
      <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
      <div class="form-check form-switch m-0">
        <input [(ngModel)]="selectedIRN" (change)="changeStatsValue(selectedIRN,$event)" class="form-check-input"
          type="checkbox" role="switch" [checked]="amountMetric === 'crore'" />
      </div>
      <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
      <p class="btn btn-outline-success btn-download" (click)="exportToExcel('All Open Cases')">
        <i class="fa fa-file-excel-o me-2" aria-hidden="true"></i> Export
      </p>
    </div>
    <p class="pieChartLabel txtCenter"></p>
    <div class="table-responsive autoScroll">
      <table id="" class="table table-bordered">
        <thead class="header">
          <tr class="bgGred">
            <th>Sr No.</th>
            <th><span>Act</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" (click)="sort(states.ACT)"
                *ngIf="isAnalyticsConOne"></i><i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                (click)="sort(states.ACT)" *ngIf="!isAnalyticsConOne"></i></th>
           <th><span>Forum</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" (click)="sort(states.FORUM)"
                  *ngIf="isAnalyticsConOne"></i><i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                  (click)="sort(states.FORUM)" *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Case ID</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.CASE_ID)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(states.CASE_ID)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Case Label</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.CASE_LABEL)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(states.CASE_LABEL)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Tax Amount</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.TAX)" *ngIf="isAnalyticsConOne"></i><i class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true" (click)="sort(states.TAX)" *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Interest Amount</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.INTEREST)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(states.INTEREST)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Penalty Amount</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.PENALTY)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(states.PENALTY)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Demand Amount</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(states.DEMAND_AMOUNT)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(states.DEMAND_AMOUNT)"
                *ngIf="!isAnalyticsConOne"></i></th>
          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngFor="let obj of openCaseData; let i=index">
              <td>{{i+1}}</td>
              <td>{{obj.act}}</td>
              <td>{{obj.phase}}</td>
              <td *ngIf="obj.phase == 'Adjudication'" class="txtCenter"><a
                  (click)="GetOpenCase(obj.caseId, 'update', 'preaja', 'tab1')">{{obj.caseId}}</a></td>
              <td *ngIf="obj.phase == 'Commissioner (A)'" class="txtCenter"><a
                  (click)="GetOpenCase(obj.caseId, 'update', 'preapa', 'tab2')">{{obj.caseId}}</a></td>
              <td *ngIf="obj.phase == 'Appellate Tribunal'" class="txtCenter"><a
                  (click)="GetOpenCase(obj.caseId, 'update', 'preapt', 'tab3')">{{obj.caseId}}</a></td>
              <td *ngIf="obj.phase == 'High Court'" class="txtCenter"><a
                  (click)="GetOpenCase(obj.caseId, 'update', 'prehc', 'tab4')">{{obj.caseId}}</a></td>
              <td *ngIf="obj.phase == 'Supreme Court'" class="txtCenter"><a
                  (click)="GetOpenCase(obj.caseId, 'update', 'presc', 'tab5')">{{obj.caseId}}</a></td>
              <td>{{obj.label}}</td>
              <td class="textRight" *ngIf="isinLakh">{{obj.tax |INR: 2}}</td>
              <td class="textRight" *ngIf="isinLakh">{{obj.interest |INR: 2}}</td>
              <td class="textRight" *ngIf="isinLakh">{{obj.penalty |INR: 2}}</td>
              <td class="textRight" *ngIf="isinLakh">{{obj.total | INR : 2}}</td>

              <td class="textRight" *ngIf="isinCR">{{obj.taxInCr |INR: 2}}</td>
              <td class="textRight" *ngIf="isinCR">{{obj.interestInCr |INR: 2}}</td>
              <td class="textRight" *ngIf="isinCR">{{obj.penaltyInCr |INR: 2}}</td>
              <td class="textRight" *ngIf="isinCR">{{obj.totalInCr | INR : 2}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
