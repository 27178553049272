<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
       <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" title="Close Case" (click)="gotoSummary()">
              <span aria-hidden="true" style="color: red;">&times;</span>
          </button>
          </div>
        <!-- <p class="card-title">SCN</p>	                 -->
          <ul class="nav nav-pills iris-gst-display-flex" role="tablist" id="pills-tab">
            <li class="nav-item">
              <a class="nav-link " (click)="tabChanged('tab1')" [ngClass]="{'disabled':!tabDisableFlag.tab1, 'active':selectedTab === 'tab1'}" id="notice-tab" data-toggle="tab" role="tab" aria-controls="notice-1" aria-selected="true">Pre Litigation</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="tabChanged('tab2')" [ngClass]="{'disabled':!tabDisableFlag.tab2, 'active':selectedTab === 'tab2'}" id="adjudication-tab" data-toggle="tab" role="tab" aria-controls="adjudication-1" aria-selected="true">Adjudication stage</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="tabChanged('tab3')" [ngClass]="{'disabled':!tabDisableFlag.tab3, 'active':selectedTab === 'tab3'}" id="AppelAuth-tab" data-toggle="tab" role="tab" aria-controls="AppelAuth-1" aria-selected="true">Commissioner (A)</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="tabChanged('tab4')" [ngClass]="{'disabled':!tabDisableFlag.tab4, 'active':selectedTab === 'tab4'}" id="AppelTrib-tab" data-toggle="tab"  role="tab" aria-controls="AppelTrib-1" aria-selected="true">Appellate Tribunal</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="tabChanged('tab5')" [ngClass]="{'disabled':!tabDisableFlag.tab5, 'active':selectedTab === 'tab5'}" id="HC-tab" data-toggle="tab"  role="tab" aria-controls="HC-1" aria-selected="true">High Court</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="tabChanged('tab6')" [ngClass]="{'disabled':!tabDisableFlag.tab6, 'active':selectedTab === 'tab6'}" id="SC-tab" data-toggle="tab"  role="tab" aria-controls="SC-1" aria-selected="true">Supreme Court</a>
            </li>
          </ul>
          <div class="tab-content">

            <div class="tab-pane fade show active" id="notice-1" role="tabpanel" aria-labelledby="notice-tab">
               <app-notice [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)" (saveCaseId)="saveCaseId($event)" [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab1'" (callTabFunction)="tabChanged('tab2')"(moveToHC)="tabChanged('tab5',null,true)" ></app-notice>
            </div>
            <div class="tab-pane fade show active" id="adjudication-1" role="tabpanel" aria-labelledby="adjudication-tab">
              <app-adjudication-auth [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)" (saveCaseId)="saveCaseId($event)" [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab2'"  (callTabFunction)="tabChanged('tab3')" (moveToHC)="tabChanged('tab5',null,true)"  (moveToAT)="tabChanged('tab4')" (moveToSC)="tabChanged('tab6')"></app-adjudication-auth>
            </div>
            <div class="tab-pane fade show active" id="AppelAuth-1" role="tabpanel" aria-labelledby="AppelAuth-tab">
                <app-appellate-auth [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)" (saveCaseId)="saveCaseId($event)" [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab3'"  (callTabFunction)="tabChanged('tab4')"   (moveToHC)="tabChanged('tab5',null,true)"  (moveToSC)="tabChanged('tab6')" [isRemandedUpdated]="isRemandedUpdated"></app-appellate-auth>
            </div>
            <div class="tab-pane fade show active" id="AppelTrib-1" role="tabpanel" aria-labelledby="AppelTrib-tab">
               <app-appellate-trib [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)"  (saveCaseId)="saveCaseId($event)"  [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab4'"  (callTabFunction)="tabChanged($event)"></app-appellate-trib>
            </div>
            <div class="tab-pane fade show active" id="HC-1" role="tabpanel" aria-labelledby="HC-tab">
               <app-hc [activeStages]="activeStages" (enablePrevStageTab)="enablePrevStageTab($event)" [selectedCaseId]="selectedCaseId" (saveCaseId)="saveCaseId($event)" *ngIf="selectedTab === 'tab5'"  (callTabFunction)="tabChanged('tab6')"></app-hc>
            </div>
            <div class="tab-pane fade show active" id="SC-1" role="tabpanel" aria-labelledby="SC-tab">
              <app-sc (enablePrevStageTab)="enablePrevStageTab($event)" (saveCaseId)="saveCaseId($event)" [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab6'"></app-sc>

            </div>
          </div>
        </div>
      </div>
    </div>
            </div>
       </div>

    </div>
    <!-- main-panel ends -->
 </div>
