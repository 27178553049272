import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DtNmStats, NoticeServiceService } from '../../services/notification.service';
import { asessmentYears } from 'src/app/helpers/date.utils';
import { DT_NM_AY, DT_NM_STATUS } from 'src/app/shared/constant';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { CommonService } from 'src/app/shared/services/common.service';
export enum PROCEEDINGS_SORT {
  PAN = 'Pan',
  PROCEEDING_ID = 'Proceeding Id',
  AY = 'AY',
  LAST_RESPONSE_DATE = 'Last Response Date',
  PROCEEDING_SECTION = 'Proceeding Section',
  ITR = 'ITR',
  STATUS = 'Status',
}
interface Filters {
  p: 'assessmentYear' | 'itrType' | 'noticeName' | 'pan' | 'proceedingStatus';
  o: 'in' | 'eq' | 'between';
  v: string | number | null;
}

const ProceedingStatus = {
  'C': 'Closed',
  'O': 'Open'
}

@Component({
  selector: 'app-proceedings',
  templateUrl: './proceedings.component.html',
  styleUrls: ['./proceedings.component.css']
})
export class ProceedingsComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal!: ElementRef;
  proceedingStatus = {
    'C': 'Closed',
    'O': 'Open'
  }
  dtNmStats: null | DtNmStats = null;
  isMarkRead: boolean = false;
  proceedCnt: any = Number(sessionStorage.getItem('DtproceedCnt') ?? 10);
  rootCompanyId: number = Number(sessionStorage.getItem('companyId'));
  childCompanyId: number | null = null;
  proceedingData: any;
  totalCount: any;
  perPageLimitSection1 = 10;
  pageSection1: number = 1;
  tableSize: number = 10;
  assessYears = asessmentYears();
  status = DT_NM_STATUS;
  ayrange = DT_NM_AY;
  filterItrVal: any = [];
  filterDropDown: any = [];
  filters: { [key: string]: Filters } = this.initFilters();
  proceedingConfig = {
    itemsPerPage: this.perPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'proceedingConfig',
  };
  proceedingsSort = PROCEEDINGS_SORT;
  sorting: { pan: boolean, proceedingReqId: boolean, assessmentYear: boolean, lastResponseSubmittedOn: boolean, noticeName: boolean, itrType: boolean, proceedingStatus: boolean } =
    { pan: false, proceedingReqId: false, assessmentYear: false, lastResponseSubmittedOn: false, noticeName: false, itrType: false, proceedingStatus: false };
panData: any;

  constructor(
    private noticeService: NoticeServiceService,
    private toaster: ToasterService,
    private router: Router,
    private commonServ: CommonService,
  ) {
    const navContext = JSON.parse(sessionStorage.getItem('navContext') ?? '{}');
    this.childCompanyId = navContext.companyId;
  }

  ngOnInit() {
    this.filters = <any>JSON.parse(sessionStorage.getItem('dtNmProcFilter') || '{}');
    if ((this.filters && Object.keys(this.filters).length == 0) || !this.filters) {
      this.filters = this.initFilters();
    }
    this.countData();
    this.proceedingListData();
    this.filterValue();
    this.proceedingConfig.totalItems = this.proceedCnt;
    this.tableSize = this.proceedCnt;
  }

  confirmMarkRead() {
    this.isMarkRead = true;
    this.closeModal.nativeElement.click();
  }
  //count api 
  // noticeCount() {
  //   const isNewCase = sessionStorage.getItem('isNewNotice')
  //   let model: any = {};
  //   model.companyId = localStorage.getItem('selectedId');
  //   model.sortdir = "ASC",
  //     model.sortfield = "createdAt",
  //     model.newRecord = isNewCase === "Newnotice",
  //     model.module = "GST",
  //     this.noticeService.getNoticeCount(model).subscribe((response: any) => {
  //       if (response.status === 1) {
  //         this.noticeCnt = response.response.noticeCount;
  //         sessionStorage.setItem('noticeCount', this.noticeCnt);
  //         this.noticeCntNew = response.response.newNoticeCount;
  //         this.subCnt = response.response.submissionCount;
  //         sessionStorage.setItem('submissionCount', this.subCnt);
  //         this.subCntNew = response.response.newSubmissionCount;
  //         this.addNoticeCnt = response.response.additionalNoticeCount;
  //         sessionStorage.setItem('addNoticeCount', this.addNoticeCnt);
  //         this.adNoticeCntNew = response.response.newAdditionalNoticeCount;
  //       }
  //     });
  // }
  countData() {
    let model: any = {};
    model.companyId = sessionStorage.getItem('companyId');
    model.sortdir = "ASC",
      model.sortfield = "createdAt",
      model.module = "DT",
      this.noticeService.getDtNmCount(model).subscribe((response:any) => {
        if (response.status === 1) {
            this.dtNmStats = response.response;
        }
      });
  }
  //count api 
  initFilters(): { [key: string]: Filters } {
    return {
      assessmentYear: { p: 'assessmentYear', o: 'in', v: null },
      itrType: { p: 'itrType', o: 'in', v: null },
      noticeName: { p: 'noticeName', o: 'in', v: null },
      pan: { p: 'pan', o: 'in', v: null },
      proceedingStatus: { p: 'proceedingStatus', o: 'in', v: null },
    };
  }
  getFilters() {
    sessionStorage.setItem('dtNmProcFilter', JSON.stringify(this.filters));
    return Object.values(this.filters).filter((el) => !!el.v);

  }

  //proceeding list data api
  proceedingListData() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.proceedingConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      module: "DT",
      criterias: this.getFilters(),
      newRecord: isNewCase === "Newnotice"
    }
    this.noticeService.getProceedingListData(payload).subscribe((res) => {
      this.proceedingData = res.response.results;
      this.proceedingConfig.totalItems = res.response.count;
      this.totalCount = res.response.count;


    });
  }
  //proceeding list data api

  //filter dropdown Values
  filterValue() {
    const payload: any = {
      type: 'DtProceedings',
      module: "DT"
    }
    this.noticeService.getFilterValue(payload).subscribe((res) => {
      this.filterDropDown = res.response;
    this.panData = Object.entries(res.response.pan);
   console.log(this.panData,"panData")
    });

  }
  //filter  dropdown Values

  //Export to excel download
  downloadEXFile() {
    this.getFilters();
    this.dwdExpExcel();
  }
  dwdExpExcel() {
    const payload: any = {
      page: this.proceedingConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      reportType: 'DtProceedings',
      sortfield: 'lastResponseSubmittedOn',
      module: "DT",
      criterias: this.getFilters()
    }
    this.noticeService.dwdDtExportExcel(payload).subscribe((response: any) => {
      this.downloadExFile(response);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadExFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `Proceedings.xlsx`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  //Export to excel download

  //pagination  
  onTableDataChangeSection1(pageNumber: number): void {
    this.proceedingConfig.currentPage = pageNumber;
    this.proceedingListData();
  }
  getProceedingData(perPageLimitSection1: any, section1page: number) {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: section1page == 1 ? 0 : section1page - 1,
      size: perPageLimitSection1,
      module: "DT",
      criterias: this.getFilters(),
      newRecord: isNewCase === "Newnotice"
    }
    this.noticeService.getProceedingListData(payload).subscribe((res) => {
      this.proceedingData = res.response.results;
      this.proceedingConfig.totalItems = res.response.count;
      this.proceedingConfig.itemsPerPage = perPageLimitSection1;
      this.proceedingConfig.currentPage = payload.pageNumber + 1;
    });
  }
  //pagination  

  toProcDetail(id: number) {
    this.router.navigate(['/dtNmProcDetail', id]);
  }
  clearFilter(inputName: string) {
    switch (inputName) {
      case 'assessmentYear':
        this.filters.assessmentYear.v = null;
        break;
      case 'itrType':
        this.filters.itrType.v = null;
        break;
      case 'noticeName':
        this.filters.noticeName.v = null;
        break;
      case 'pan':
        this.filters.pan.v = null;
        break;
      case 'proceedingStatus':
        this.filters.proceedingStatus.v = null;
        break;
      default:
        break;
    }
    this.proceedingListData();
  }

  sort(column: string) {
    switch (column) {
      case PROCEEDINGS_SORT.PAN: {
        if (!this.sorting.pan) {
          this.sorting.pan = true;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'pan', 'asc');
        } else {
          this.sorting.pan = false;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'pan', 'desc');
        }
        break;
      }
      case PROCEEDINGS_SORT.PROCEEDING_ID: {
        if (!this.sorting.proceedingReqId) {
          this.sorting.proceedingReqId = true;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'proceedingReqId', 'asc');
        } else {
          this.sorting.proceedingReqId = false;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'proceedingReqId', 'desc');
        }
        break;
      }
      case PROCEEDINGS_SORT.AY: {
        if (!this.sorting.assessmentYear) {
          this.sorting.assessmentYear = true;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'assessmentYear', 'asc');
        } else {
          this.sorting.assessmentYear = false;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'assessmentYear', 'desc');
        }
        break;
      }
      case PROCEEDINGS_SORT.LAST_RESPONSE_DATE: {
        if (!this.sorting.lastResponseSubmittedOn) {
          this.sorting.lastResponseSubmittedOn = true;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'lastRespSubOn', 'asc');
        } else {
          this.sorting.lastResponseSubmittedOn = false;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'lastRespSubOn', 'desc');
        }
        break;
      }
      case PROCEEDINGS_SORT.PROCEEDING_SECTION: {
        if (!this.sorting.noticeName) {
          this.sorting.noticeName = true;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'noticeName', 'asc');
        } else {
          this.sorting.noticeName = false;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'noticeName', 'desc');
        }
        break;
      }
      case PROCEEDINGS_SORT.ITR: {
        if (!this.sorting.itrType) {
          this.sorting.itrType = true;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'itrType', 'asc');
        } else {
          this.sorting.itrType = false;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'itrType', 'desc');
        }
        break;
      }
      case PROCEEDINGS_SORT.STATUS: {
        if (!this.sorting.proceedingStatus) {
          this.sorting.proceedingStatus = true;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'proceedingStatus', 'asc');
        } else {
          this.sorting.proceedingStatus = false;
          this.proceedingData = this.commonServ.sortProcAdj(this.proceedingData, 'proceeding', 'proceedingStatus', 'desc');
        }
        break;
      }
    }
  }
}
