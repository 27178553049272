import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray, FormArray, FormGroup } from '@angular/forms';
import { OTHER_SPECIFIC_ITEM, SPECIFIC_ITEM_TREATMENT, ITC_INTELIGIBLE_CREDIT, TAB_TYPE, REVERSE_CHARGE_SERVICE, YES_NO, SERVICE_FROM_GOV, ITC_REV_TYPE, ITC_REV_REF, GST_RATE, FIN_YEAR, VALID_FILE_TYPE } from 'src/app/shared/constant';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { AuditService } from '../service/audit.service';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { HttpClient } from '@angular/common/http';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-audit-company-gst',
  templateUrl: './audit-company-gst.component.html',
  styleUrls: ['./audit-company-gst.component.css']
})
export class AuditCompanyGSTComponent implements OnInit {
  navContext = { entityName: '', isTaxPayer: false, entityType: '', pan: '', gstin: '' };
  selectedTab: any;
  tab: any;
  maxDate = new Date();
  currentYear: any;
  selectedGstin: any;
  selectedPan: any;
  selectedFilling: any;
  gstinOrPan: any;
  role: any;
  public serialNo: any = -1;
  auditCompanyGstfrmGrp!: FormGroup;
  finPopModalRef!: NgbModalRef;
  openoperateOtherModalRef!: NgbModalRef;
  supplyofCapModalRef!: NgbModalRef;
  advancesofCapModalRef!: NgbModalRef;
  impSrcPopModalRef!: NgbModalRef;
  gsAvailPopModalRef!: NgbModalRef;
  servGovPopModalRef!: NgbModalRef;
  reverseChrgPopModalRef!: NgbModalRef;
  specificItemsPopModalRef!: NgbModalRef;
  reversalsPopModalRef!: NgbModalRef;
  itcIneligiblePopModalRef!: NgbModalRef;
  otherSpecificItemsPopModalRef!: NgbModalRef;
  removeModal!: NgbModalRef;
  finyearData: any[] = [];
  yesNoList: { label: string; value: boolean; }[];

  otherSpecficItemList: { key: string; value: string; }[];
  specificItemTreatList: { key: string; value: string; }[];
  serviceFrmGovList: { key: string; value: string; }[];
  reverseChargeList: { key: string; value: string; }[];
  itcReveRefList: { key: string; value: string; }[];
  itcRevTypeList: { key: string; value: string; }[];
  gstRateList: { key: string; value: string; }[];
  finYearList: { key: string; value: string; }[];
  finYear: any;

  itcIneligibleCreditList: { key: string; value: string; }[];
  isAdvRecYes: boolean = true;
  showAnyOther: boolean = false;
  protestDetailsExpansion: boolean = false;
  operatingExpansion: boolean = false;
  supplyExpansion: boolean = false;
  advancesReceivedExpansion: boolean = false;
  generalExpansion: boolean = false;
  servicesExpansion: boolean = false;
  importExpansion: boolean = false;
  inputCreditExpansion: boolean = false;
  reversalsExpansion: boolean = false;
  goodsServicesExpansion: boolean = false;
  specificExpansion: boolean = false


  /* upload function declaration  */
  selectedField: any;
  selectedFile: any;
  formData: FormData = new FormData();
  matches: any;
  caseId: any;
  savedCaseId: any;
  tabType: { key: string; value: string; }[];

  isUPfinFileLoc: boolean = true;
  isDWfinFileLoc: boolean = true;
  isfinFileLocFile: boolean = false;
  isfinFileLocText: boolean = true;

  isUPtbfileLoc: boolean = true;
  isDWtbfileLoc: boolean = true;
  istbFileLocFile: boolean = false;
  istbFileLocText: boolean = true;

  isUPcertIncorpLoc: boolean = true;
  isDWcertIncorpLoc: boolean = true;
  iscertIncorpLocFile: boolean = false;
  iscertIncorpLocText: boolean = true;

  isUPpanCompLoc: boolean = true;
  isDWpanCompLoc: boolean = true;
  ispanCompLocFile: boolean = false;
  ispanCompLocText: boolean = true;

  isUPpanDirLoc: boolean = true;
  isDWpanDirLoc: boolean = true;
  ispanDirLocFile: boolean = false;
  ispanDirLocText: boolean = true;

  isUPgstRegCertLoc: boolean = true;
  isDWgstRegCertLoc: boolean = true;
  isgstRegCertLocFile: boolean = false;
  isgstRegCertLocText: boolean = true;


  fileUrl: any;

  /* upload function declaration  */

  /*save function declaration */
  isSubmitted: boolean = false;
  /*save function declaration */
  /*fetch function declaration */
  compGstData: any;
  compGstBasicData: any;

  /*fetch function declaration */

  removeModalData = { type: '', i: null }
  fileType: any;
  validFileTypeList: string[];

  constructor(
    private activeSnapShot: ActivatedRoute,
    private fBuild: UntypedFormBuilder,
    private auditService: AuditService,
    private toaster: ToasterService,
    private GS: GetterSetterService,
    private modalService: NgbModal,
    private commonServ: CommonService,
    private excelService: ExcelService,
    private http: HttpClient,
    private US: UtilityService
  ) {
    this.tab = this.activeSnapShot.snapshot.params.tab;
    this.yesNoList = YES_NO;
    this.tabType = TAB_TYPE;
    this.otherSpecficItemList = OTHER_SPECIFIC_ITEM;
    this.specificItemTreatList = SPECIFIC_ITEM_TREATMENT;
    this.serviceFrmGovList = SERVICE_FROM_GOV;
    this.reverseChargeList = REVERSE_CHARGE_SERVICE;
    this.itcIneligibleCreditList = ITC_INTELIGIBLE_CREDIT;
    this.itcReveRefList = ITC_REV_REF;
    this.itcRevTypeList = ITC_REV_TYPE;
    this.gstRateList = GST_RATE;
    this.finYearList = FIN_YEAR;
    this.validFileTypeList = VALID_FILE_TYPE;


  }

  ngOnInit() {
    this.auditService.selectedFilling$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((data) => {
      if (data) {
        this.selectedFilling = data;
        this.selectedGstin = this.selectedFilling.gstin;
        this.GS.checkEntityType.subscribe((value) => {
          this.navContext = value;
          this.selectedPan = this.navContext.pan;
        });
      }
      this.initializeData();
    });
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.navContext = this.commonServ.getNavContext();
    this.selectedPan = this.navContext.pan;
    this.role = localStorage.getItem('roleName');
    this.tabChanged(this.tab);
    this.selectedTab = 'tab1';
    this.getYears();
    this.setDate();
    this.formInitialize();
  }
  tabChanged(tab: any, check?: any) {
    this.selectedTab = tab;
  }
  formInitialize() {
    this.auditCompanyGstfrmGrp = this.fBuild.group({
      basicDetails: this.initBasicGrp(),
      operAndOtherIncome: new FormArray([]),
      supplyOfCapitalGoods: new UntypedFormArray([]),
      advancesReceived: new UntypedFormArray([]),
      reverseChrgGenInfo: new UntypedFormArray([]),
      servicesFromGov: new UntypedFormArray([]),
      importOfServices: new UntypedFormArray([]),
      itcIneligibleCredit: new UntypedFormArray([]),
      anyOtherRev: new UntypedFormArray([]),
      itcReversals: new UntypedFormArray([]),
      itcReversalsAO: new UntypedFormArray([]),
      itcNotAvailed: new UntypedFormArray([]),
      specificItemTreatment: new UntypedFormArray([]),
      otherSpecificItems: new UntypedFormArray([]),
    })
    for (let lbl = 0; lbl < this.finYearList.length; lbl++) {
      let finYearLbl = this.finYearList[lbl].value;
      let financialsGrp = this.fBuild.group({
        finYear: [finYearLbl],
        balSheetLoc: new UntypedFormArray([]),
        trialBalLoc: new UntypedFormArray([]),
      });
      let basicDetailGrp = this.auditCompanyGstfrmGrp.get('basicDetails') as UntypedFormGroup;
      (basicDetailGrp.get('financials') as UntypedFormArray).push(financialsGrp);

    }
    for (let lbl = 0; lbl < this.reverseChargeList.length; lbl++) {
      let servicesFromLbl = this.reverseChargeList[lbl].value;
      let reverseChrgGenInfoGrp = this.fBuild.group({
        servicesFrom: [servicesFromLbl],
        applicability: [null],
        gstRate: [null],
        period: [null],
        remark: [null],
      });
      (this.auditCompanyGstfrmGrp.get('reverseChrgGenInfo') as UntypedFormArray).push(reverseChrgGenInfoGrp);
    }

    for (let lbl = 0; lbl < this.itcIneligibleCreditList.length; lbl++) {
      let itcIneligibleCreditLbl = this.itcIneligibleCreditList[lbl].value;
      let itcIneligibleCreditGrp = this.fBuild.group({
        item: [itcIneligibleCreditLbl],
        applicability: [null],
        period: [null],
        remark: [null],

      });
      (this.auditCompanyGstfrmGrp.get('itcIneligibleCredit') as UntypedFormArray).push(itcIneligibleCreditGrp);

    }

    for (let lbl = 0; lbl < this.otherSpecficItemList.length; lbl++) {
      let therSpecificItemsLbl = this.otherSpecficItemList[lbl].value;
      let otherSpecificItemsGrp = this.fBuild.group({
        item: [therSpecificItemsLbl],
        period: [null],
        remark: [null],
      });
      (this.auditCompanyGstfrmGrp.get('otherSpecificItems') as UntypedFormArray).push(otherSpecificItemsGrp);
    }
  }
  get formGrp() { return this.auditCompanyGstfrmGrp.controls; }
  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  /*Basic fields */
  /*Basic fields form Group*/
  initBasicGrp(): UntypedFormGroup {
    return this.fBuild.group({
      financials: new UntypedFormArray([]),
      // trialBalance: new FormArray([]),
      regAppl: [null, Validators.maxLength(1000)],
      dateOfIncorp: [null],
      incorpCertNo: [null],
      certIncorpLoc: new UntypedFormArray([]),
      panComp: [this.selectedPan],
      panCompLoc: new UntypedFormArray([]),
      gstRegCertLoc: new UntypedFormArray([]),
      breifComp: [null, Validators.maxLength(1000)],
    })
  }
  get basicDetailValue() { return (this.auditCompanyGstfrmGrp.controls.basicDetails) }
  getBasicDetailGrp(): UntypedFormGroup {
    return this.auditCompanyGstfrmGrp.get('basicDetails') as UntypedFormGroup;
  }

  /*Basic fields form Group*/
  /*Financials – Balance Sheet & Profit & Loss Account*/
  getFinancials(): UntypedFormGroup[] {
    let basicDetailGrp = this.auditCompanyGstfrmGrp.get('basicDetails') as UntypedFormGroup;
    return (basicDetailGrp.get('financials') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  initfinancialFrmGrp(): UntypedFormGroup {
    return this.fBuild.group({
      finYear: new UntypedFormControl('', []),
      balSheetLoc: new UntypedFormArray([]),
      trialBalLoc: new UntypedFormArray([]),
    })
  }
  addFinancial() {
    let financialFrmGrp = this.initfinancialFrmGrp();
    let basicDetailGrp = this.auditCompanyGstfrmGrp.get('basicDetails') as UntypedFormGroup;
    (basicDetailGrp.get('financials') as UntypedFormArray).push(financialFrmGrp);
  }
  rmFinancial(i: number) {
    let basicDetailGrp = this.auditCompanyGstfrmGrp.get('basicDetails') as UntypedFormGroup;
    (basicDetailGrp.get('financials') as UntypedFormArray).removeAt(i);
    this.finPopModalRef.close();
  }

  /*Financials – Balance Sheet & Profit & Loss Account*/
  /*Basic fields */
  /*Output Tax */
  /*Operating & Other Income*/

  getOperAndOtherIncome(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('operAndOtherIncome') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  initoperAndOtherIncomeGrp(): UntypedFormGroup {
    return this.fBuild.group({
      nameOfService: [null],
      descService: [null],
      sacCode: [null],
      period: [null],
      gstRate: [null],
      exempNotify: [null],

    })
  }
  addOperatingOtherIncm1() {
    let operAndOtherIncomeGrp = this.initoperAndOtherIncomeGrp();
    (this.auditCompanyGstfrmGrp.get('operAndOtherIncome') as UntypedFormArray).push(operAndOtherIncomeGrp);

  }
  rmOperatingOtherIncm1(i: number) {
    (this.auditCompanyGstfrmGrp.get('operAndOtherIncome') as UntypedFormArray).removeAt(i);
    this.openoperateOtherModalRef.close();
  }


  /*Operating & Other Income*/
  /*Supply of Capital Goods*/
  getSupplyOfCapitalGoods(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('supplyOfCapitalGoods') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initsupplyOfCapitalGoodsGrp(): UntypedFormGroup {
    return this.fBuild.group({
      nameOfGoods: [null],
      hsnCode: [null],
      period: [null],
      remark: [null],
      gstRate: [null],
      exempNotify: [null]
    })
  }
  addsupplyofCap() {
    let supplyOfCapitalGoodsGrp = this.initsupplyOfCapitalGoodsGrp();
    (this.auditCompanyGstfrmGrp.get('supplyOfCapitalGoods') as UntypedFormArray).push(supplyOfCapitalGoodsGrp);
  }
  rmsupplyofCap(i: number) {
    (this.auditCompanyGstfrmGrp.get('supplyOfCapitalGoods') as UntypedFormArray).removeAt(i);
    this.supplyofCapModalRef.close();
  }
  /*Supply of Capital Goods*/

  /*Advances Received From Customers*/
  getAdvancesOfReceivedGoods(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('advancesReceived') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initAdvancesOfReceivedGoodsGrp(): UntypedFormGroup {
    return this.fBuild.group({
      nameOfGoods: [null],
      period: [null],
      remark: [null]
    })
  }
  addAdvancesOfReceived() {
    let advancesOfReceivedGoodsGrp = this.initAdvancesOfReceivedGoodsGrp();
    (this.auditCompanyGstfrmGrp.get('advancesReceived') as UntypedFormArray).push(advancesOfReceivedGoodsGrp);
  }
  rmAdvancesOfReceived(i: number) {
    (this.auditCompanyGstfrmGrp.get('advancesReceived') as UntypedFormArray).removeAt(i);
    this.advancesofCapModalRef.close();
  }
  /*Advances Received From Customers*/
  /*Reverse Charge Mechanism*/
  getreverseChrgGenInfo(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('reverseChrgGenInfo') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initreverseChrgGenInfoGrp(): UntypedFormGroup {
    return this.fBuild.group({
      servicesFrom: [null],
      applicability: [null],
      gstRate: [null],
      period: [null],
      remark: [null],
    })
  }
  addReverseChrgGenInfo() {
    let reverseChrgGenInfoGrp = this.initreverseChrgGenInfoGrp();
    (this.auditCompanyGstfrmGrp.get('reverseChrgGenInfo') as UntypedFormArray).push(reverseChrgGenInfoGrp);
  }
  rmReverseChrgGenInfo(i: number) {
    (this.auditCompanyGstfrmGrp.get('reverseChrgGenInfo') as UntypedFormArray).removeAt(i);
    this.reverseChrgPopModalRef.close();
  }
  /*Reverse Charge Mechanism*/
  /*Reverse Charge Any other field*/
  // getreverseChrgGenInfoAO(): FormGroup[] {
  //   return (this.auditCompanyGstfrmGrp.get('reverseChrgGenInfoAO') as FormArray).controls as FormGroup[];
  // }
  // initreverseChrgGenInfoAOGrp(): FormGroup {
  //   return this.fBuild.group({
  //     servicesFrom: [],
  //     applicability: [null],
  //     gstRate: [null],
  //     period: [null],
  //     remark: [null],
  //   })
  // }
  // addReverseChrgGenInfoAO() {
  //   let reverseChrgGenInfoAOGrp = this.initreverseChrgGenInfoAOGrp();
  //   (this.auditCompanyGstfrmGrp.get('reverseChrgGenInfoAO') as FormArray).push(reverseChrgGenInfoAOGrp);
  // }
  // rmReverseChrgGenInfoAO(k: number) {
  //   (this.auditCompanyGstfrmGrp.get('reverseChrgGenInfoAO') as FormArray).removeAt(k);
  // }
  /*Reverse Charge Any other field*/
  checkWeatherAdvRecYes(event: any) {
    if (event.target.value === "true") {
      this.isAdvRecYes = false;
    } else {
      this.isAdvRecYes = true;
    }
  }
  /*Output Tax */
  /*Reverse Charg */
  /*Services from Government*/
  getservicesFromGov(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('servicesFromGov') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initservicesFromGovGrp(): UntypedFormGroup {
    return this.fBuild.group({
      nameOfService: [null],
      descService: [null],
      gstRate: [null],
      period: [null],
    })
  }
  addServGov() {
    let servicesFromGovGrp = this.initservicesFromGovGrp();
    (this.auditCompanyGstfrmGrp.get('servicesFromGov') as UntypedFormArray).push(servicesFromGovGrp);
  }
  rmServGov(i: number) {
    (this.auditCompanyGstfrmGrp.get('servicesFromGov') as UntypedFormArray).removeAt(i);
    this.servGovPopModalRef.close();
  }

  /*Services from Government*/
  /*Import of Services */
  getimportOfServices(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('importOfServices') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initimportOfServicesGrp(): UntypedFormGroup {
    return this.fBuild.group({
      nameOfService: [null],
      descService: [null],
      gstRate: [null],
      period: [null],
    })
  }
  addImpSrc() {
    let importOfServicesGrp = this.initimportOfServicesGrp();
    (this.auditCompanyGstfrmGrp.get('importOfServices') as UntypedFormArray).push(importOfServicesGrp);
  }
  rmImpSrc(i: number) {
    (this.auditCompanyGstfrmGrp.get('importOfServices') as UntypedFormArray).removeAt(i);
    this.impSrcPopModalRef.close();
  }
  /*Import of Services */
  /*Reverse Charg */
  /*Input tax*/
  /*Reversal >> any other reversal*/
  anyOtherRevArr(): UntypedFormArray {
    return this.auditCompanyGstfrmGrp.get("anyOtherRev") as UntypedFormArray
  }

  newAnyOtherRevArr(): UntypedFormGroup {
    return this.fBuild.group({
      aoRev: [null],
    })
  }
  addAnyOtherRev() {
    this.anyOtherRevArr().push(this.newAnyOtherRevArr());
  }
  rmAnyOtherRev(i: number) {
    this.anyOtherRevArr().removeAt(i);
  }
  /*Reversal >> any other reversal*/
  /*goods service avail*/
  getitcNotAvailed(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('itcNotAvailed') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  inititcNotAvailedGrp(): UntypedFormGroup {
    return this.fBuild.group({
      item: [null],
      reason: [null],
      period: [null],
      remark: [null],
    })
  }
  addGSAvail() {
    let itcNotAvailedGrp = this.inititcNotAvailedGrp();
    (this.auditCompanyGstfrmGrp.get('itcNotAvailed') as UntypedFormArray).push(itcNotAvailedGrp);
  }
  rmGSAvail(i: number) {
    (this.auditCompanyGstfrmGrp.get('itcNotAvailed') as UntypedFormArray).removeAt(i);
    this.gsAvailPopModalRef.close();
  }
  /*goods service avail*/
  /*Ineligible Credit as per Sec 17(5)*/
  getitcIneligibleCredit(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('itcIneligibleCredit') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  inititcIneligibleCreditGrp(): UntypedFormGroup {
    return this.fBuild.group({
      item: [null],
      applicability: [null],
      period: [null],
      remark: [null],
    })
  }
  additcIneligibleCreditArr() {
    let itcIneligibleCreditGrp = this.inititcIneligibleCreditGrp();
    (this.auditCompanyGstfrmGrp.get('itcIneligibleCredit') as UntypedFormArray).push(itcIneligibleCreditGrp);
  }
  rmitcIneligibleCreditArr(i: number) {
    (this.auditCompanyGstfrmGrp.get('itcIneligibleCredit') as UntypedFormArray).removeAt(i);
    this.itcIneligiblePopModalRef.close();
  }
  /*Ineligible Credit as per Sec 17(5)*/
  /*Reversals */
  getitcReversals(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('itcReversals') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  inititcReversalsGrp(): UntypedFormGroup {
    return this.fBuild.group({
      type: [null],
      applicability: [null],
      period: [null],
      remark: [null],
    })
  }
  addItcReversals() {
    let itcReversalsGrp = this.inititcReversalsGrp();
    (this.auditCompanyGstfrmGrp.get('itcReversals') as UntypedFormArray).push(itcReversalsGrp);
  }
  rmItcReversals(i: number) {
    (this.auditCompanyGstfrmGrp.get('itcReversals') as UntypedFormArray).removeAt(i);
    this.reversalsPopModalRef.close();
  }
  /*Reversals */
  /*Reversals any other */
  // getitcReversalsAO(): FormGroup[] {
  //   return (this.auditCompanyGstfrmGrp.get('itcReversalsAO') as FormArray).controls as FormGroup[];
  // }
  // inititcReversalsAOGrp(): FormGroup {
  //   return this.fBuild.group({
  //     type: [null],
  //     //reference: [null],
  //     applicability: [null],
  //     period:[null],
  //     remark:[null],
  //   })
  // }
  // additcReversalsAO() {
  //   let itcReversalsAOGrp = this.inititcReversalsAOGrp();
  //   (this.auditCompanyGstfrmGrp.get('itcReversalsAO') as FormArray).push(itcReversalsAOGrp);
  // }

  // rmitcReversalsAO(i: any){
  //   let itcReversalsAOGrp = this.inititcReversalsAOGrp();
  //   (this.auditCompanyGstfrmGrp.get('itcReversalsAO') as FormArray).removeAt(i);
  // }

  /*Reversals any other */
  /*Treatment of Specific Items */
  getspecificItemTreatment(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('specificItemTreatment') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initspecificItemTreatmentGrp(): UntypedFormGroup {
    return this.fBuild.group({
      item: [null],
      itcAvailed: [null],
      period: [null],
      remark: [null],
    })
  }
  addspecificItemTreatmentArr() {
    let specificItemTreatmentGrp = this.initspecificItemTreatmentGrp();
    (this.auditCompanyGstfrmGrp.get('specificItemTreatment') as UntypedFormArray).push(specificItemTreatmentGrp);
  }
  rmspecificItemTreatmentArr(i: number) {
    (this.auditCompanyGstfrmGrp.get('specificItemTreatment') as UntypedFormArray).removeAt(i);
    this.specificItemsPopModalRef.close();
  }
  /*Treatment of Specific Items */
  /*Other specific items*/

  getotherSpecificItems(): UntypedFormGroup[] {
    return (this.auditCompanyGstfrmGrp.get('otherSpecificItems') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  initotherSpecificItemsGrp(): UntypedFormGroup {
    return this.fBuild.group({
      item: [null],
      period: [null],
      remark: [null],
    })
  }
  addotherSpecificItemsArr() {
    let otherSpecificItemsGrp = this.initotherSpecificItemsGrp();
    (this.auditCompanyGstfrmGrp.get('otherSpecificItems') as UntypedFormArray).push(otherSpecificItemsGrp);
  }
  rmotherSpecificItemsArr(i: number) {
    (this.auditCompanyGstfrmGrp.get('otherSpecificItems') as UntypedFormArray).removeAt(i);
    this.otherSpecificItemsPopModalRef.close();
  }

  /*Other specific items*/
  /*Input tax */
  /* get download file path function */
  getFileNameDta(fileUrl: any) {
    if (fileUrl)
      return this.auditService.getFileName(fileUrl);

  }
  /* get download file path function */
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2011;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1);
      this.finyearData.push({ key: financialYrRange, value: financialYrRange, isHidden: false });
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }
  onYearChange(event: any) {
    //console.log("event",event.target.value);
    if (event.target.value && this.finyearData.length > 0) {
      this.finyearData.forEach((year: any) => {
        if (year.value == event.target.value) {
          year.isHidden = true;
        }
      })
    }
    if (event) {
      this.saveCompanyGst();
    }

  }
  /*upload file function */
  onFileSelect(event: any) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;  //GET THE VALUE of (0) FROM itemList(0).letterLOC
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  onUpload(selectedfield: any,) {
    let urlData = {
      gstin: this.selectedPan,
      type: 'basicdet',
      field: selectedfield,
      contentType: this.selectedFile.type
    }
    this.auditService.uploadFiles(this.selectedFile, urlData).subscribe((response: any) => {
      if (response.status == 1 && response.response !== null) {
        this.getFileUrl(response.response.url, response.response.path, selectedfield);
      } else {
      }
    })
  }

  getFileUrl(url: any, path: any, selectedfield: any) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      if (selectedfield) {
        let toupdate = this.auditCompanyGstfrmGrp.value.basicDetails;
        let pathToVeriabl = this.US.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.auditCompanyGstfrmGrp.patchValue(this.auditCompanyGstfrmGrp.value);
        this.auditCompanyGstfrmGrp.patchValue(this.auditCompanyGstfrmGrp.value);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }

  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }

  uploadFinfileLoc(i: number) {
    this.isfinFileLocFile = true;
    this.isfinFileLocText = false;
    this.isDWfinFileLoc = false;
    this.isUPfinFileLoc = false;
    let basicDetailArray = this.auditCompanyGstfrmGrp.controls.basicDetails as UntypedFormGroup;
    let financialsArray = basicDetailArray.controls.financials as UntypedFormArray
    if (financialsArray.controls.length > 0) {
      for (let index = 0; index < financialsArray.controls.length; index++) {
        if (index === i) {
          let financialsControl = financialsArray.controls[index] as UntypedFormGroup;
          financialsControl.value['isfinFileLocFileUploadedClicked'] = true
        }
      }
    }
  }

  /*  Dialog box windows   */
  openTextBoxModal1(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('reverseChrgGenInfo')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }
  operAndOtherBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('operAndOtherIncome')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  supplyOfCapitalBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('supplyOfCapitalGoods')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
        // console.log(detailArr[index].controls[id]);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }


  advancesReceivedBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('advancesReceived')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }


  servicesFromGovBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('servicesFromGov')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }


  importOfServicesBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('importOfServices')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  itcNotAvailedBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('itcNotAvailed')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  specificItemTreatmentBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('specificItemTreatment')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }

  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('itcIneligibleCredit')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }
  openTextBoxReversals(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('itcReversals')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }
  openTextBoxOtherSpecificItems(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('otherSpecificItems')).controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue = detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    })
  }
  /*  Dialog box windows   */


  uploadtbFileLoc(i: number) {
    this.istbFileLocFile = true;
    this.istbFileLocText = false;
    this.isDWtbfileLoc = false;
    this.isUPtbfileLoc = false;
    let basicDetailArray = this.auditCompanyGstfrmGrp.controls.basicDetails as UntypedFormGroup;
    let financialsArray = basicDetailArray.controls.financials as UntypedFormArray
    if (financialsArray.controls.length > 0) {
      for (let index = 0; index < financialsArray.controls.length; index++) {
        if (index === i) {
          let financialsControl = financialsArray.controls[index] as UntypedFormGroup;
          financialsControl.value['isTrialBalLocFileUploadedClicked'] = true
        }
      }
    }
  }
  deleteBalSheetLocFile(i: any) {
    let basicDetailArray = this.auditCompanyGstfrmGrp.controls.basicDetails as UntypedFormGroup;
    let financialsArray = basicDetailArray.controls.financials as UntypedFormArray
    if (financialsArray.controls.length > 0) {
      for (let index = 0; index < financialsArray.controls.length; index++) {
        if (index === i) {
          let financialsControl = financialsArray.controls[index] as UntypedFormGroup;
          financialsControl.value['isTrialBalLocFileUploadedClicked'] = true;
          financialsControl.value['balSheetLoc'] = null;
        }
      }
    }
  }

  deleteFinancialsFile(i: any) {
    let basicDetailArray = this.auditCompanyGstfrmGrp.controls.basicDetails as UntypedFormGroup;
    let financialsArray = basicDetailArray.controls.financials as UntypedFormArray
    if (financialsArray.controls.length > 0) {
      for (let index = 0; index < financialsArray.controls.length; index++) {
        if (index === i) {
          let financialsControl = financialsArray.controls[index] as UntypedFormGroup;
          financialsControl.value['isTrialBalLocFileUploadedClicked'] = true;
          financialsControl.value['trialBalLoc'] = null;
        }
      }
    }
  }

  /*upload file function */
  /*download file function */
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.auditService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }
  /*download file function */

  setFileData(dataList: any) {
    for (const key in dataList.basicDetails) {
      if (key === 'certIncorpLoc' || key === 'gstRegCertLoc' || key === 'panCompLoc') {
        if (dataList.basicDetails[key].length) {
          dataList.basicDetails[key] = dataList.basicDetails[key].filter((obj: any) => {
            return (obj.fileLoc !== null && obj.fileLoc !== '');
          });
        }
      } else if (key === 'financials') {
        // let trialBalanceData: any = [];
        // let balSheetData: any = [];
        // dataList.basicDetails[key].forEach((item: any, index: number) => {
        //   let tempList: any = [];
        //   item.balSheetLoc.forEach((obj: any) => {
        //     if (obj.fileLoc !== null && obj.fileLoc !== '') {
        //       tempList.push(obj);
        //     }
        //   });
        //   balSheetData.push({ "finYear": item.finYear, "fileLoc": tempList });
        //   let tempList2: any = [];
        //   item.trialBalLoc.forEach((obj: any) => {
        //     if (obj.fileLoc !== null && obj.fileLoc !== '') {
        //       tempList2.push(obj);
        //     }
        //   });
        //   trialBalanceData.push({ "finYear": item.finYear, "fileLoc": tempList2 });

        //   // item.balSheetLoc = item.balSheetLoc.filter((obj: any) => {
        //   //   return (obj.fileLoc !== null && obj.fileLoc !== '');
        //   // });
        //   // item.trialBalLoc = item.trialBalLoc.filter((obj: any) => {
        //   //   return (obj.fileLoc !== null && obj.fileLoc !== '');
        //   // });
        // });
        // dataList.basicDetails[key] = balSheetData;
        // dataList.basicDetails['trialBalance'] = trialBalanceData;
      }
    }
    return dataList;
  }

  /*save function */
  saveCompanyGst() {
    this.isSubmitted = true;
    let data: any = {};
    let isValidReverseChrgGenInfo = false
    this.auditCompanyGstfrmGrp.value.reverseChrgGenInfo.forEach((r: any) => {
      if (r.applicability && (r.gstRate === '0' || r.gstRate === '' || r.gstRate === null)) {
        isValidReverseChrgGenInfo = true;
      }
    });
    data = this.setFileData(this.auditCompanyGstfrmGrp.value);
    if (this.auditCompanyGstfrmGrp.valid) {
      data['gstinOrPan'] = this.getGstinOrPan();
      this.auditService.saveCompGstData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        if (response.status === 1) {

          this.toaster.showSuccess(response.message);

        } else {
          this.toaster.showSuccess(response.message);
        }
      });
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  manipulateFinancialFileData() {
    let balSheetLocData: any = [];
    this.compGstData.basicDetails.financials.forEach((item: any) => {
      let tempObj: any = {};
      tempObj["finYear"] = item.finYear;
      tempObj["balSheetLoc"] = item.fileLoc;
      tempObj["trialBalLoc"] = [];
      balSheetLocData.push(tempObj);
    });
    this.compGstData.basicDetails.financials = balSheetLocData;
  }

  setBasicDetailData() {
    this.manipulateFinancialFileData();
    this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
  }

  /*save function */
  /*fetch function */
  initializeData() {
    this.auditService.getCompGstData(this.getGstinOrPan()).subscribe((response: any) => {
      if (response.status === 1 && response.response) {
        this.compGstData = response.response;
        this.compGstBasicData = response.response.basicDetails;
        this.formInitialize();

        if (this.compGstData.basicDetails) {
          this.initBasicDetailsData();
        }
        if (this.compGstData.basicDetails.financials.length > 0) {
          this.initfinancialsData();
        } else {
          this.addFinancial();
        }
        if (this.compGstData.operAndOtherIncome && this.compGstData.operAndOtherIncome.length > 0) {
          this.initoperAndOtherIncomeData();
        }
        if (this.compGstData.supplyOfCapitalGoods && this.compGstData.supplyOfCapitalGoods.length > 0) {
          this.initsupplyOfCapitalGoods();
        }
        if (this.compGstData.advancesReceived && this.compGstData.advancesReceived.length > 0) {
          this.initadvancesOfReceivedGoods();
        }
        if (this.compGstData.itcNotAvailed && this.compGstData.itcNotAvailed.length > 0) {
          this.inititcNotAvailed();
        }
        if (this.compGstData.reverseChrgGenInfo && this.compGstData.reverseChrgGenInfo.length > 0) {
          this.initreverseChrgGenInfo();
        }
        // if(this.compGstData.reverseChrgGenInfoAO && this.compGstData.reverseChrgGenInfoAO.length > 0) {
        //   this.initreverseChrgGenInfoAO();
        // }
        if (this.compGstData.servicesFromGov && this.compGstData.servicesFromGov.length > 0) {
          this.initservicesFromGov();
        }
        if (this.compGstData.importOfServices && this.compGstData.importOfServices.length > 0) {
          this.initimportOfServices();
        }

        if (this.compGstData.itcIneligibleCredit && this.compGstData.itcIneligibleCredit.length > 0) {
          this.inititcIneligibleCredit();
        }
        if (this.compGstData.itcReversals && this.compGstData.itcReversals.length > 0) {
          this.inititcReversals();
        }
        // if(this.compGstData.itcReversalsAO && this.compGstData.itcReversalsAO.length > 0) {
        //   this.inititcReversalsAO();
        // }

        if (this.compGstData.specificItemTreatment && this.compGstData.specificItemTreatment.length > 0) {
          this.initspecificItemTreatment();
        }
        if (this.compGstData.otherSpecificItems && this.compGstData.otherSpecificItems.length > 0) {
          this.initotherSpecificItems();
        }
      }
      else {
        this.addFinancial();
        this.addOperatingOtherIncm1();
        this.addsupplyofCap();
        this.addAdvancesOfReceived();
        this.addReverseChrgGenInfo();
        this.addServGov();
        this.addImpSrc();
        this.additcIneligibleCreditArr();

        this.addGSAvail();
        this.addotherSpecificItemsArr();
        this.addItcReversals();
        this.addspecificItemTreatmentArr();
      }
    });
  }
  initoperAndOtherIncomeData() {
    if (this.compGstData.operAndOtherIncome) {
      this.compGstData.operAndOtherIncome.forEach((oandOIncome: any) => {
        let operAndOtherIncomeGrp: UntypedFormGroup = this.initoperAndOtherIncomeGrp();
        (this.auditCompanyGstfrmGrp.get('operAndOtherIncome') as UntypedFormArray).push(operAndOtherIncomeGrp);
      });
      this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
    }

  }
  initsupplyOfCapitalGoods() {
    if (this.compGstData.supplyOfCapitalGoods) {
      this.compGstData.supplyOfCapitalGoods.forEach((soc: any) => {
        let supplyOfCapitalGoodsGrp: UntypedFormGroup = this.initsupplyOfCapitalGoodsGrp();
        (this.auditCompanyGstfrmGrp.get('supplyOfCapitalGoods') as UntypedFormArray).push(supplyOfCapitalGoodsGrp);
      });
      this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
    }

  }

  initadvancesOfReceivedGoods() {
    if (this.compGstData.advancesReceived) {
      this.compGstData.advancesReceived.forEach((adv: any) => {
        let advancesOfReceivedGoodsGrp: UntypedFormGroup = this.initAdvancesOfReceivedGoodsGrp();
        (this.auditCompanyGstfrmGrp.get('advancesReceived') as UntypedFormArray).push(advancesOfReceivedGoodsGrp);
      });
      this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
    }

  }
  initreverseChrgGenInfo() {
    for (let i = this.reverseChargeList.length; i < this.compGstData.reverseChrgGenInfo.length; i++) {
      let reverseChrgGenInfoGrp: UntypedFormGroup = this.initreverseChrgGenInfoGrp();
      (this.auditCompanyGstfrmGrp.get('reverseChrgGenInfo') as UntypedFormArray).push(reverseChrgGenInfoGrp);
    }
    this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
  }

  initservicesFromGov() {
    if (this.compGstData.servicesFromGov) {
      this.compGstData.servicesFromGov.forEach((sfg: any) => {
        let servicesFromGovGrp: UntypedFormGroup = this.initservicesFromGovGrp();
        (this.auditCompanyGstfrmGrp.get('servicesFromGov') as UntypedFormArray).push(servicesFromGovGrp);
      });
      this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
    }

  }
  // Import of Services
  initimportOfServices() {
    if (this.compGstData.importOfServices) {
      this.compGstData.importOfServices.forEach((ise: any) => {
        let importOfServicesGrp: UntypedFormGroup = this.initimportOfServicesGrp();
        (this.auditCompanyGstfrmGrp.get('importOfServices') as UntypedFormArray).push(importOfServicesGrp);
      });
      this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
    }

  }
  inititcIneligibleCredit() {
    for (let i = this.itcIneligibleCreditList.length; i < this.compGstData.itcIneligibleCredit.length; i++) {
      let itcIneligibleCreditGrp: UntypedFormGroup = this.inititcIneligibleCreditGrp();
      (this.auditCompanyGstfrmGrp.get('itcIneligibleCredit') as UntypedFormArray).push(itcIneligibleCreditGrp);
    }
    this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
  }
  inititcReversals() {
    if (this.compGstData.itcReversals) {
      this.compGstData.itcReversals.forEach((itcrev: any) => {
        let itcReversalsGrp: UntypedFormGroup = this.inititcReversalsGrp();
        (this.auditCompanyGstfrmGrp.get('itcReversals') as UntypedFormArray).push(itcReversalsGrp);
      });
      this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
    }

  }

  inititcNotAvailed() {
    if (this.compGstData.itcNotAvailed) {
      this.compGstData.itcNotAvailed.forEach((itcrev: any) => {
        let itcNotAvailedGrp: UntypedFormGroup = this.inititcNotAvailedGrp();
        (this.auditCompanyGstfrmGrp.get('itcNotAvailed') as UntypedFormArray).push(itcNotAvailedGrp);
      });
      this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
    }

  }
  initspecificItemTreatment() {
    if (this.compGstData.specificItemTreatment) {
      this.compGstData.specificItemTreatment.forEach((sit: any) => {
        let specificItemTreatmentGrp: UntypedFormGroup = this.initspecificItemTreatmentGrp();
        (this.auditCompanyGstfrmGrp.get('specificItemTreatment') as UntypedFormArray).push(specificItemTreatmentGrp);
      });
      this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
    }

  }
  initotherSpecificItems() {
    for (let i = this.otherSpecficItemList.length; i < this.compGstData.otherSpecificItems.length; i++) {
      let otherSpecificItemsGrp: UntypedFormGroup = this.initotherSpecificItemsGrp();
      (this.auditCompanyGstfrmGrp.get('otherSpecificItems') as UntypedFormArray).push(otherSpecificItemsGrp);
    }
    this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
  }

  initBasicDetailsData() {
    if (this.compGstBasicData.panCompLoc && this.compGstBasicData.panCompLoc.length > 0) {
      this.compGstBasicData.panCompLoc.forEach((oandOIncome: any) => {
        let fileGrp: UntypedFormGroup = this.createPanCompLoc();
        let basicDetailGrp = this.auditCompanyGstfrmGrp.get('basicDetails') as UntypedFormGroup;
        (basicDetailGrp.get('panCompLoc') as UntypedFormArray).push(fileGrp);
      });
    }

    if (this.compGstBasicData.certIncorpLoc && this.compGstBasicData.certIncorpLoc.length > 0) {
      this.compGstBasicData.certIncorpLoc.forEach((oandOIncome: any) => {
        let fileGrp: UntypedFormGroup = this.getcertIncorpLoc();
        let basicDetailGrp = this.auditCompanyGstfrmGrp.get('basicDetails') as UntypedFormGroup;
        (basicDetailGrp.get('certIncorpLoc') as UntypedFormArray).push(fileGrp);
      });
    }

    if (this.compGstBasicData.gstRegCertLoc && this.compGstBasicData.gstRegCertLoc.length > 0) {
      this.compGstBasicData.gstRegCertLoc.forEach((oandOIncome: any) => {
        let fileGrp: UntypedFormGroup = this.createGstRegCertLoc();
        let basicDetailGrp = this.auditCompanyGstfrmGrp.get('basicDetails') as UntypedFormGroup;
        (basicDetailGrp.get('gstRegCertLoc') as UntypedFormArray).push(fileGrp);
      });
    }

    this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
    this.auditCompanyGstfrmGrp.updateValueAndValidity();
  }

  initfinancialsData() {
    if (this.compGstBasicData.financials) {
      this.compGstBasicData.financials.forEach((item: any, index1: any) => {
        let basicDetailGrp = this.auditCompanyGstfrmGrp.get('basicDetails') as UntypedFormGroup;
        let fileFrmArray = (basicDetailGrp.get('financials') as UntypedFormArray);
        item.balSheetLoc.forEach((element: any, index2: any) => {
          let fileGrp: UntypedFormGroup = this.addFileFormLoc();
          (fileFrmArray.controls[index1].get('balSheetLoc') as UntypedFormArray).push(fileGrp);
        });
        item.trialBalLoc.forEach((element: any, index2: any) => {
          let fileGrp: UntypedFormGroup = this.addFileFormLoc();
          (fileFrmArray.controls[index1].get('trialBalLoc') as UntypedFormArray).push(fileGrp);
        });
      });
    }

    this.auditCompanyGstfrmGrp.patchValue(this.compGstData);
    this.auditCompanyGstfrmGrp.updateValueAndValidity();
  }
  /*fetch function */


  openfinPop(content: any, i: any) {
    this.finPopModalRef = this.modalService.open(content);
  }
  openoperateOtherPop(content: any, i: any) {
    this.openoperateOtherModalRef = this.modalService.open(content);
  }
  opensupplyofCap(content: any, i: any) {
    this.supplyofCapModalRef = this.modalService.open(content);
  }
  openadvancesfCap(content: any, i: any) {
    this.advancesofCapModalRef = this.modalService.open(content);
  }
  openRmvservGovPop(content: any, i: any) {
    this.servGovPopModalRef = this.modalService.open(content);
  }
  openReverseChrg(content: any, i: any) {
    this.reverseChrgPopModalRef = this.modalService.open(content);
  }
  openSpecificItems(content: any, i: any) {
    this.specificItemsPopModalRef = this.modalService.open(content);
  }
  openReversals(content: any, i: any) {
    this.reversalsPopModalRef = this.modalService.open(content);
  }
  openitcIneligible(content: any, i: any) {
    this.itcIneligiblePopModalRef = this.modalService.open(content);
  }
  openOtherSpecificItems(content: any, i: any) {
    this.otherSpecificItemsPopModalRef = this.modalService.open(content);
  }
  openImpSrcPop(content: any, i: any) {
    this.impSrcPopModalRef = this.modalService.open(content);
  }
  gsAvailPop(content: any, i: any) {
    this.gsAvailPopModalRef = this.modalService.open(content);
  }

  setRemoveModalData(content: any, type: any, i: any) {
    this.removeModalData.type = type;
    this.removeModalData.i = i;
    this.removeModal = this.modalService.open(content);
  }

  removeClicked() {
    this.removeModal.close();

  }

  omitChar(event: any) {
    if (event.charCode >= 48 && event.charCode <= 57) {
      return true;
    }
    return false;
  }

  get f() {
    return this.auditCompanyGstfrmGrp.value;
  }

  exportAsXLSX(module: string) {

    let data: any[] = [];
    let filename = '';
    this.serialNo = 0;

    switch (module) {

      case 'operAndOtherIncome': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Name of Goods/Services': a.nameOfService,
            'HSN/SAC Code': a.sacCode,
            'GST Rate': a.gstRate === null ? a.gstRate : `${a.gstRate} %`,
            'Exemption Notification': a.exempNotify,
            'Period': a.period,
            'Remark': a.descService,
          }
        });
        filename = 'Operating & Other Income';
        break;
      }

      case 'supplyOfCapitalGoods': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Name of Goods': a.nameOfGoods,
            'HSN Code': a.hsnCode,
            'GST Rate': a.gstRate === null ? a.gstRate : `${a.gstRate} %`,
            'Exemption Notification': a.exempNotify,
            'Period': a.period,
            'Remark': a.remark,
          }
        });
        filename = 'Supply of Capital Goods';
        break;
      }

      case 'advancesReceived': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Name of Goods Services on Which Advances Have Taken': a.nameOfGoods,
            'Period': a.period,
            'Remark': a.remark,
          }
        });
        filename = 'Details of Advances Received From Customers';
        break;
      }
      case 'reverseChrgGenInfo': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Name of Service': a.servicesFrom,
            'Applicability?': a.applicability == "true" ? 'YES' : a.applicability == null ? '' : 'NO',
            'GST Rate': a.gstRate === null ? a.gstRate : `${a.gstRate} %`,
            'Period': a.period,
            'Remark': a.remark,
          }
        });
        filename = 'Services From';
        break;
      }

      case 'servicesFromGov': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Name of Service': a.nameOfService,
            'GST Rate': a.gstRate === null ? a.gstRate : `${a.gstRate} %`,
            'Period': a.period,
            'Remark': a.descService,
          }
        });
        filename = 'Services From Government';
        break;
      }

      case 'importOfServices': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Name of Service': a.nameOfService,
            'GST Rate': a.gstRate === null ? a.gstRate : `${a.gstRate} %`,
            'Period': a.period,
            'Remark': a.descService,
          }
        });
        filename = 'Import Of Services';
        break;
      }

      case 'itcIneligibleCredit': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Items': a.item,
            'Applicability?': a.applicability == "true" ? 'YES' : a.applicability == null ? '' : 'NO',
            'Period': a.period,
            'Remark': a.remark,
          }
        });
        filename = 'ItcIneligible Credit';
        break;
      }

      case 'itcReversals': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Type of Reversal': a.type,
            'Applicability?': a.applicability == "true" ? 'YES' : a.applicability == null ? '' : 'NO',
            'Period': a.period,
            'Remark': a.remark,
          }
        });
        filename = 'Itc Reversals';
        break;
      }

      case 'itcNotAvailed': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Items': a.item,
            'Reason': a.reason,
            'Period': a.period,
            'Remark': a.remark,
          }
        });
        filename = 'Itc Not Availed';
        break;
      }

      case 'specificItemTreatment': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Specific Items': a.item,
            'ITC Availed	': a.itcAvailed,
            'Period': a.period,
            'Remark': a.remark,
          }
        });
        filename = 'Specific Item Treatment';
        break;
      }

      case 'otherSpecificItems': {
        data = this.f[module].map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Items': a.item,
            'Period': a.period,
            'Remark': a.remark,
          }
        });
        filename = 'Other Specific Items';
        break;
      }

      case 'basicDetails': {
        data = this.f[module].financials.map((a: any) => {
          this.serialNo++;
          return {
            'Sr.No': (this.serialNo).toString(),
            'Financial Year': a.finYear,
            'Balance Sheet and Profit & Loss Account': a.balSheetLoc,
            'Trial balance': a.trialBalLoc,
          }
        });
        filename = 'Financials';
        break;
      }
    }
    this.excelService.exportAsExcelFile(data, filename);
  }

  getGstinOrPan() {
    return this.navContext && this.navContext.entityType === 'FILING' ? this.navContext.gstin : this.navContext.pan;
  }

  openCmpFilePopup(content: any, fileType: any) {
    this.fileType = fileType;
    // this.addFileFieldLoc(0);
    this.modalService.open(content);
  }


  addFileFormLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  opengstRegCertLoc(content: any) {
    this.addGstRegCertLoc();
    this.modalService.open(content);
  }
  getgstRegCertLocLength() {
    let count = 0;
    const basicDetailGrp = this.getBasicDetailGrp();
    let predepositFrmArray = basicDetailGrp.get('gstRegCertLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getGstRegCertLoc(): UntypedFormGroup[] {
    const basicDetailGrp = this.getBasicDetailGrp();
    return (basicDetailGrp.get('gstRegCertLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createGstRegCertLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addGstRegCertLoc() {
    let basicDetailGrp = this.getBasicDetailGrp();
    let fileFormArray = basicDetailGrp.get('gstRegCertLoc') as UntypedFormArray;
    let i = 0;
    if (fileFormArray.length > 0) {
      let length = fileFormArray.length
      while (i < 5 - length) {
        fileFormArray.push(this.createGstRegCertLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        fileFormArray.push(this.createGstRegCertLoc());
        i++;
      }
    }
  }

  deleteGstRegCertLoc(noticeLocIndex: any) {
    const basicDetailGrp = this.getBasicDetailGrp();
    let attachmentLocArray = basicDetailGrp.get('gstRegCertLoc') as UntypedFormArray;
    if (attachmentLocArray.controls.length > 0) {
      for (let index = 0; index < attachmentLocArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = attachmentLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadGstRegCertLoc(gstRegCertLocIndex: any) {
    const basicDetailGrp = this.getBasicDetailGrp();
    let attachmentLocArray = basicDetailGrp.get('gstRegCertLoc') as UntypedFormArray;
    if (attachmentLocArray.controls.length > 0) {
      for (let index = 0; index < attachmentLocArray.controls.length; index++) {
        if (index === gstRegCertLocIndex) {
          let itemListuploadControl = attachmentLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  // panCompLoc Array end


  openPanCompLoc(content: any) {
    this.addPanCompLoc();
    this.modalService.open(content);
  }
  getPanCompLocLength() {
    let count = 0;
    const basicDetailGrp = this.getBasicDetailGrp();
    let predepositFrmArray = basicDetailGrp.get('panCompLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getPanCompLoc(): UntypedFormGroup[] {
    const basicDetailGrp = this.getBasicDetailGrp();
    return (basicDetailGrp.get('panCompLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createPanCompLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addPanCompLoc() {
    let basicDetailGrp = this.getBasicDetailGrp();
    let fileFormArray = basicDetailGrp.get('panCompLoc') as UntypedFormArray;
    let i = 0;
    if (fileFormArray.length > 0) {
      let length = fileFormArray.length
      while (i < 5 - length) {
        fileFormArray.push(this.createPanCompLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        fileFormArray.push(this.createPanCompLoc());
        i++;
      }
    }
  }

  deletePanCompLoc(noticeLocIndex: any) {
    const basicDetailGrp = this.getBasicDetailGrp();
    let panCompLocArray = basicDetailGrp.get('panCompLoc') as UntypedFormArray;
    if (panCompLocArray.controls.length > 0) {
      for (let index = 0; index < panCompLocArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = panCompLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadtPanCompLoc(otherAttachLocIndex: any) {
    const basicDetailGrp = this.getBasicDetailGrp();
    let attachmentLocArray = basicDetailGrp.get('panCompLoc') as UntypedFormArray;
    if (attachmentLocArray.controls.length > 0) {
      for (let index = 0; index < attachmentLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = attachmentLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  // panCompLoc Array end

  openCertIncorpLocLocAttach(content: any,) {
    this.addCertIncorpLocLoc();
    this.modalService.open(content);
  }
  getNoticeLocLength() {
    let count = 0;
    const basicDetailGrp = this.getBasicDetailGrp();
    let predepositFrmArray = basicDetailGrp.get('certIncorpLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getCertIncorpLocLoc(): UntypedFormGroup[] {
    const basicDetailGrp = this.getBasicDetailGrp();
    return (basicDetailGrp.get('certIncorpLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getcertIncorpLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addCertIncorpLocLoc() {
    let basicDetailGrp = this.getBasicDetailGrp();
    let fileFormArray = basicDetailGrp.get('certIncorpLoc') as UntypedFormArray;
    let i = 0;
    if (fileFormArray.length > 0) {
      let length = fileFormArray.length
      while (i < 5 - length) {
        fileFormArray.push(this.addFileFormLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        fileFormArray.push(this.addFileFormLoc());
        i++;
      }
    }
  }
  uploadOtherAttach(otherAttachLocIndex: any) {
    const basicDetailGrp = this.getBasicDetailGrp();
    let attachmentLocArray = basicDetailGrp.get('certIncorpLoc') as UntypedFormArray;
    if (attachmentLocArray.controls.length > 0) {
      for (let index = 0; index < attachmentLocArray.controls.length; index++) {
        if (index === otherAttachLocIndex) {
          let itemListuploadControl = attachmentLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  getFileData(index: any) {
    let fileUrl = null;
    const basicDetailGrp = this.getBasicDetailGrp();
    if (!!this.fileType && typeof index === "number") {
      fileUrl = basicDetailGrp.value[this.fileType][index].fileLoc;
    }
    return fileUrl ? this.auditService.getFileName(fileUrl) : null;
  }

  uploadLoc(i: any) {
    let basicDetailGrp = this.getBasicDetailGrp();
    let fileListFrmArray = basicDetailGrp.get(this.fileType) as UntypedFormArray;
    if (fileListFrmArray.controls.length > 0) {
      const objIndex = fileListFrmArray.controls.findIndex(((obj, ind) => ind == i));
      if (objIndex > -1) {
        let itemListuploadControl = fileListFrmArray.controls[objIndex] as UntypedFormGroup;
        itemListuploadControl.value['isdocLocUploadedClicked'] = true
        itemListuploadControl.value['fileLoc'] = null
      }
    }
  }

  getFileLength(fileType: any) {
    let basicDetailGrp = this.getBasicDetailGrp();
    let fileFrmArray = basicDetailGrp.get(fileType) as UntypedFormArray;
    let filteredList = [];
    if (fileFrmArray && fileFrmArray.value.length > 0) {
      filteredList = fileFrmArray.value.filter((x: any) => x.fileLoc != "");
    }
    return filteredList.length ? filteredList.length : 0;
  }

  deleteUploadFile(ItrIndex: any) {
    let basicDetailGrp = this.getBasicDetailGrp();
    let fileListFrmArray = basicDetailGrp.get(this.fileType) as UntypedFormArray;
    let itemListuploadControl = fileListFrmArray.controls[ItrIndex] as UntypedFormGroup;
    itemListuploadControl.value['isdocLocUploadedClicked'] = true;
    itemListuploadControl.value['fileLoc'] = null;
  }

  openDetailPop(content: any, i: any, formArrayType: string) {
    this.addUploadDiv(i, 0, formArrayType);
    this.modalService.open(content);
  }

  addUploadDiv(j: any, i: any, type: string) {
    let basicDetailGrp = this.getBasicDetailGrp();
    let itemListFrmArray = basicDetailGrp.get('financials') as UntypedFormArray;
    let innerFormGrp = itemListFrmArray.controls[j] as UntypedFormGroup;
    let financialsFrmArray = innerFormGrp.controls[type] as UntypedFormArray;
    if (financialsFrmArray && financialsFrmArray.length > 0) {
      let length = financialsFrmArray.length
      while (i < 5 - length) {
        financialsFrmArray.push(this.addFileFormLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        financialsFrmArray.push(this.addFileFormLoc());
        i++;
      }
    }
  }

  // addTrialBalanceDiv(j: any, i: any) {
  //   let basicDetailGrp = this.getBasicDetailGrp();
  //   let itemListFrmArray = basicDetailGrp.get('financials') as FormArray;
  //   let innerFormGrp = itemListFrmArray.controls[j] as FormGroup;
  //   let financialsFrmArray = innerFormGrp.controls['trialBalance'] as FormArray;
  //   if (financialsFrmArray.length > 0) {
  //     let length = financialsFrmArray.length
  //     while (i < 5 - length) {
  //       financialsFrmArray.push(this.addFileFormLoc());
  //       i++;
  //     }
  //   }
  //   else {
  //     while (i < 5) {
  //       financialsFrmArray.push(this.addFileFormLoc());
  //       i++;
  //     }
  //   }
  // }

  // addFinancialDiv(j: any, i: any) {
  //   let basicDetailGrp = this.getBasicDetailGrp();
  //   let itemListFrmArray = basicDetailGrp.get('financials') as FormArray;
  //   let innerFormGrp = itemListFrmArray.controls[j] as FormGroup;
  //   let financialsFrmArray = innerFormGrp.controls['balSheetLoc'] as FormArray;
  //   if (financialsFrmArray.length > 0) {
  //     let length = financialsFrmArray.length
  //     while (i < 5 - length) {
  //       financialsFrmArray.push(this.addFileFormLoc());
  //       i++;
  //     }
  //   }
  //   else {
  //     while (i < 5) {
  //       financialsFrmArray.push(this.addFileFormLoc());
  //       i++;
  //     }
  //   }
  // }

  getFinancialLoc(taxPositionGrp: UntypedFormGroup, fileType: any): UntypedFormGroup[] {
    return (taxPositionGrp.get(fileType) as UntypedFormArray).controls as UntypedFormGroup[];
  }

  // getTrialBalanceLoc(taxPositionGrp: FormGroup): FormGroup[] {
  //   return (taxPositionGrp.get('trialBalance') as FormArray).controls as FormGroup[];
  // }

  getFinancialFileLength(j: any, type: string) {
    let fileFrmArray = null;
    let filteredList = [];
    let basicDetailGrp = this.getBasicDetailGrp();
    let itemListFrmArray = basicDetailGrp.get('financials') as UntypedFormArray;
    if (type == 'balSheetLoc') {
      fileFrmArray = itemListFrmArray.controls[j].get('balSheetLoc') as UntypedFormArray;
    } else {
      fileFrmArray = itemListFrmArray.controls[j].get('trialBalLoc') as UntypedFormArray;
    }
    if (fileFrmArray && fileFrmArray.value && fileFrmArray.value.length > 0) {
      filteredList = fileFrmArray.value.filter((x: any) => x.fileLoc != "");
    }
    return filteredList.length;
  }

  uploadbsLoc(i: any, j: any, fileType: any) {
    let basicDetailGrp = this.getBasicDetailGrp();
    let itemListFrmArray = basicDetailGrp.get('financials') as UntypedFormArray;
    let innerFormGrp = itemListFrmArray.controls[i] as UntypedFormGroup;
    let balSheetFrmArray = innerFormGrp.controls[fileType] as UntypedFormArray;

    if (balSheetFrmArray.controls.length > 0) {
      const objIndex = balSheetFrmArray.controls.findIndex(((obj, ind) => ind == j));
      if (objIndex > -1) {
        let itemListuploadControl = balSheetFrmArray.controls[objIndex] as UntypedFormGroup;
        itemListuploadControl.value['isdocLocUploadedClicked'] = true
        itemListuploadControl.value['fileLoc'] = null
      }
    }
  }

  onFileSelectFinancial(event: any, fileType: any, index: any, formGrp: UntypedFormGroup) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.US.checkExtensionFunction(event.target.files[0], this.validFileTypeList)) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace('&', 'And')
        this.selectedFile = new File([originalFile], modifiedFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUploadFinData(this.selectedField, fileType, index, formGrp);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }
  onUploadFinData(selectedfield: any, fileType: any, index: any, formGrp: UntypedFormGroup) {
    let urlData = {
      gstin: this.selectedPan,
      type: 'taxpos',
      field: selectedfield,
      contentType: this.selectedFile.type
    }
    this.auditService.uploadFiles(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFinFileUrl(response.response.url, response.response.path, selectedfield, fileType, index, formGrp);
      } else {
      }
    })
  }
  getFinFileUrl(url: any, path: any, selectedfield: any, fileType: any, index: any, formGrp: UntypedFormGroup) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      if (selectedfield) {
        var toupdate;
        // if (fileType == 'financialFromGrp') {
        const b = (formGrp.get(fileType) as UntypedFormArray).controls as UntypedFormGroup[];
        b[index].controls["fileName"].setValue(this.selectedFile.name);
        toupdate = this.auditCompanyGstfrmGrp.value.basicDetails;

        let pathToVeriabl = this.US.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.auditCompanyGstfrmGrp.patchValue(this.auditCompanyGstfrmGrp.value);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }

}
