<div class="switch-box" style="float: inline-end;">
  <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
  <div class="form-check form-switch m-0">
    <input [(ngModel)]="selectedIRN" (change)="changeStatsValue(selectedIRN,$event)" class="form-check-input"
      type="checkbox" role="switch" [checked]="amountMetric === 'crore'" />
  </div>
  <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
</div>
<div  id="chart-wrapper" class="chartjs-container">
  <div class="chart-box">
    <div class="chart-canvas">
  <canvas
   baseChart
      [data]="chartData"
      [type]="chartType"
      [options]="chartOptions"
      (chartClick)="openPieChart(details, $event)"
      >
  </canvas>
  </div>
  <div class="chart-legends">
    <div class="legend-item" *ngFor="let legend of legends">
      <p><span [style]="{ color: legend.color }">&#9679;</span> {{ legend.label }}</p>
      <p>{{ legend.value }} ({{ legend.percent }}%)</p>
    </div>
  </div>
  </div>
</div>

<ng-template #details let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Ageing Analysis - Registration No </h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <p class="pieChartLabel txtCenter">{{currentLabel}}</p>
    <div class="table-responsive autoScroll">
      <table id="" class="table table-bordered">
          <thead>
              <tr class="bg-primary text-dblue">
                  <th>Registration</th>
                  <th>Amount (<i class="fa fa-rupee rupee-icon"></i> in lakh)</th>
              </tr>
          </thead>
          <tbody>
            <ng-container>
              <tr *ngFor="let obj of tableData; let i=index">
                <td>{{obj.key}}</td>
                <td>{{obj.value | INR : 2}}</td>
              </tr>
            </ng-container>
          </tbody>
      </table>
  </div>
  </div>

</ng-template>
