<div id="chart-wrapper" class="chartjs-container">
  <div class="chart-box" >
    <div class="chart-canvas">
      <canvas baseChart 
        style="cursor: pointer;" 
        [data]="chartData"
        [type]="chartType" 
        [options]="chartOptions" 
        (chartClick)="openPieChart(details, $event)">
      </canvas>
    </div>
    <div class="chart-legends">
      <div class="legend-item" *ngFor="let legend of legends">
        <p><span [style]="{ color: legend.color }">&#9679;</span> {{ legend.label }}</p>
        <p>{{ legend.value }} ({{ legend.percent }}%)</p>
      </div>
    </div>
  </div>
 
</div>

<ng-template #details let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Ageing Analysis - Case Id</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="switch-box" style="float: inline-end;">
      <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
      <div class="form-check form-switch m-0">
        <input [(ngModel)]="selectedIRN" (change)="changeStatsValue(selectedIRN,$event)" class="form-check-input"
          type="checkbox" role="switch" [checked]="amountMetric === 'crore'" />
      </div>
      <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
      <p class="btn btn-outline-success btn-download" (click)="exportAsXLSX()">
        <i class="fa fa-file-excel-o me-2" aria-hidden="true"></i> Export
      </p>
    </div>
    <p class="pieChartLabel txtCenter">{{currentLabel| uppercase}}</p>
    <div class="table-responsive autoScroll">
      <table id="" class="table table-bordered">
        <thead class="header">
          <tr class="bgGred">
            <th>Sr. No</th>
            <th> <span>State</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(caseId.STATE)" *ngIf="isAnalyticsConOne"></i><i class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true" (click)="sort(caseId.STATE)" *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Forum</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(caseId.FORUM)" *ngIf="isAnalyticsConOne"></i><i class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true" (click)="sort(caseId.FORUM)" *ngIf="!isAnalyticsConOne"></i></th>
            <th><span> Registration.No</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(caseId.REGISTRATION_NO)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(caseId.REGISTRATION_NO)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th style="min-width: 168px;"><span>Case Id</span><i class="fa fa-arrow-up cursor-pointer"
                aria-hidden="true" (click)="sort(caseId.CASE_ID)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(caseId.CASE_ID)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Case Label</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(caseId.CASE_LABEL)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(caseId.CASE_LABEL)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Tax </span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(caseId.TAX)" *ngIf="isAnalyticsConOne"></i> <i class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true" (click)="sort(caseId.TAX)" *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Interest </span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(caseId.INTEREST)" *ngIf="isAnalyticsConOne"></i><i class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true" (click)="sort(caseId.INTEREST)" *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Penalty </span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(caseId.PENALTY)" *ngIf="isAnalyticsConOne"></i><i class="fa fa-arrow-down cursor-pointer"
                aria-hidden="true" (click)="sort(caseId.PENALTY)" *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Total</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                (click)="sort(caseId.DEMAND_AMOUNT)" *ngIf="isAnalyticsConOne"></i><i
                class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(caseId.DEMAND_AMOUNT)"
                *ngIf="!isAnalyticsConOne"></i></th>
            <th><span>Inception Date</span><i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                  (click)="sort(caseId.DATE)" *ngIf="isAnalyticsConOne"></i><i
                  class="fa fa-arrow-down cursor-pointer" aria-hidden="true" (click)="sort(caseId.DATE)"
                  *ngIf="!isAnalyticsConOne"></i></th>

          </tr>
        </thead>
        <tbody>
          <ng-container>
            <tr *ngFor="let obj of ageingPopSummary; let i=index">
              <td>{{i+1}}</td>
              <td>{{obj.state}}</td>
              <td>{{obj.phase}}</td>
              <td>{{obj.regno}}</td>
              <td *ngIf="obj.phase == 'Adjudication'"><a
                  (click)="getPreGstCaseData(obj.caseid, 'update', 'preaja', 'tab1')">{{obj.caseid}}</a></td>
              <td *ngIf="obj.phase == 'Commissioner (A)'"><a
                  (click)="getPreGstCaseData(obj.caseid, 'update', 'preapa', 'tab2')">{{obj.caseid}}</a></td>
              <td *ngIf="obj.phase == 'Appellate Tribunal'"><a
                  (click)="getPreGstCaseData(obj.caseid, 'update', 'preapt', 'tab3')">{{obj.caseid}}</a></td>
              <td *ngIf="obj.phase == 'High Court'"><a
                  (click)="getPreGstCaseData(obj.caseid, 'update', 'prehc', 'tab4')">{{obj.caseid}}</a></td>
              <td *ngIf="obj.phase == 'Supreme Court'"><a
                  (click)="getPreGstCaseData(obj.caseid, 'update', 'presc', 'tab5')">{{obj.caseid}}</a></td>
              <td>{{obj.label}}</td>
              <td *ngIf="isinLakh" class="textRight">{{obj.tax | INR : 2}}</td>
              <td *ngIf="isinLakh" class="textRight">{{obj.interest| INR : 2}}</td>
              <td *ngIf="isinLakh" class="textRight">{{obj.penalty| INR : 2}}</td>
              <td *ngIf="isinLakh" class="textRight">{{obj.total| INR : 2}}</td>
              <td *ngIf="isinCR" class="textRight">{{obj.taxInCr | INR : 2}}</td>
              <td  *ngIf="isinCR" class="textRight">{{obj.interestInCr | INR : 2}}</td>
              <td *ngIf="isinCR" class="textRight">{{obj.penaltyInCr | INR : 2}}</td>
              <td *ngIf="isinCR" class="textRight">{{obj.totalInCr | INR : 2}}</td>
              <td>{{obj.dateOfReciept}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

</ng-template>
