<div class="main-panel">
  <div class="content-wrapper">

    <div class="row">
      <div class="col-md-12">
        <p><span class="card-title">Reconciliation</span> : <span *ngIf="navContext?.entityType == 'FILING'"
            class="title-heder">{{navContext.gstin}}</span><span *ngIf="navContext?.entityType == 'LEGAL'"
            class="title-heder">{{navContext.pan}}</span>
          <button class="btn btn-outline-primary ml-1 iris-gst-pull-right" (click)="saveReconData()"
            *ngIf="!['GST_Viewer','Viewer'].includes(role)">Save &
            Update</button>
        </p>
        <div class="card borderOrange mt15">
          <div class="card-body">
            <form [formGroup]="auditReconForm">
              <div class="form-group">
                <div class="row col-md-12">
                  <p class="card-title">Reconciliations (State Wise/GSTIN Wise) with Complete Workings </p>
                </div>
                <div class="row col-md-12">
                  <div class="col-md-4 pt-2">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Financial Year </label>
                      <select class="form-control form-select" id="gstin" formControlName="finYear"
                        (change)="changeYear($event)">
                        <!-- <option [value]="null">-- Select --</option> -->
                        <ng-container *ngFor="let obj of finyearData">
                          <option [value]="obj">{{obj}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row col-md-12">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th>Documents</th>
                          <th class="w400">Upload</th>
                          <th>Remark</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th colspan="3" class="fs14 thHead">Turnover Reconciliation</th>
                        </tr>
                        <tr>
                          <th>GL tied up with Sales Register</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;"
                                (click)="openGlTiedUpWithSalesRegLoc(glTiedUpWithSalesRegLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getGlTiedUpWithSalesRegLocLength() != 0">
                                {{getGlTiedUpWithSalesRegLocLength()}} Attachment</span>
                              <ng-template #glTiedUpWithSalesRegLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let glTiedUpWithSalesRegLocGrp of getGlTiedUpWithSalesRegLocc(); let i = index"
                                          [formGroup]="glTiedUpWithSalesRegLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input
                                                    *ngIf="glTiedUpWithSalesRegLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="glTiedUpWithSalesRegLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!glTiedUpWithSalesRegLocGrp.value.isdocLocUploadedClicked || glTiedUpWithSalesRegLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(glTiedUpWithSalesRegLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="glTiedUpWithSalesRegLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(glTiedUpWithSalesRegLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!glTiedUpWithSalesRegLocGrp.value.fileLoc || glTiedUpWithSalesRegLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadGlTiedUpWithSalesRegLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteGlTiedUpWithSalesRegLoc(i)"
                                                    *ngIf="glTiedUpWithSalesRegLocGrp.value.fileLoc"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="glTiedUpWithSalesRegLocGrp.value.remarks ? glTiedUpWithSalesRegLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="glTiedUpWithSalesRegLocGrp.value.remarks && glTiedUpWithSalesRegLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>

                          </td>
                          <td><input type="text" class="form-control" formControlName="glTiedUpWithSalesRegRemark">
                          </td>
                        </tr>
                        <tr>

                          <th>Sales Register tied up with GSTR-1</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;"
                                (click)="openSalesRegTiedUpWithGstr1Loc(salesRegTiedUpWithGstr1LocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getSalesRegTiedUpWithGstr1LocLength() != 0">
                                {{getSalesRegTiedUpWithGstr1LocLength()}} Attachment</span>

                              <ng-template #salesRegTiedUpWithGstr1LocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let salesRegTiedUpWithGstr1LocGrp of getSalesRegTiedUpWithGstr1Locc(); let i = index"
                                          [formGroup]="salesRegTiedUpWithGstr1LocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input
                                                    *ngIf="salesRegTiedUpWithGstr1LocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="salesRegTiedUpWithGstr1Loc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!salesRegTiedUpWithGstr1LocGrp.value.isdocLocUploadedClicked || salesRegTiedUpWithGstr1LocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(salesRegTiedUpWithGstr1LocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon"
                                                    *ngIf="salesRegTiedUpWithGstr1LocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(salesRegTiedUpWithGstr1LocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!salesRegTiedUpWithGstr1LocGrp.value.fileLoc || salesRegTiedUpWithGstr1LocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadSalesRegTiedUpWithGstr1Loc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteSalesRegTiedUpWithGstr1Loc(i)"
                                                    *ngIf="salesRegTiedUpWithGstr1LocGrp.value.fileLoc"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="salesRegTiedUpWithGstr1LocGrp.value.remarks ? salesRegTiedUpWithGstr1LocGrp.value.remarks:''">
                                            <div
                                              *ngIf="salesRegTiedUpWithGstr1LocGrp.value.remarks && salesRegTiedUpWithGstr1LocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>

                          </td>
                          <td><input type="text" class="form-control" formControlName="salesRegTiedUpWithGstr1Remark">
                          </td>
                        </tr>
                        <tr>
                          <th>GSTR-1 tied up with GSTR-9 & 9C</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;"
                                (click)="openGstr1TiedUpWithGstr9And9CLoc(gstr1TiedUpWithGstr9And9CLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getGstr1TiedUpWithGstr9And9CLocLength() != 0">
                                {{getGstr1TiedUpWithGstr9And9CLocLength()}} Attachment</span>

                              <ng-template #gstr1TiedUpWithGstr9And9CLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let gstr1TiedUpWithGstr9And9CLocGrp of getGstr1TiedUpWithGstr9And9CLocc(); let i = index"
                                          [formGroup]="gstr1TiedUpWithGstr9And9CLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input
                                                    *ngIf="gstr1TiedUpWithGstr9And9CLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="gstr1TiedUpWithGstr9And9CLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!gstr1TiedUpWithGstr9And9CLocGrp.value.isdocLocUploadedClicked || gstr1TiedUpWithGstr9And9CLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(gstr1TiedUpWithGstr9And9CLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon"
                                                    *ngIf="gstr1TiedUpWithGstr9And9CLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(gstr1TiedUpWithGstr9And9CLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!gstr1TiedUpWithGstr9And9CLocGrp.value.fileLoc || gstr1TiedUpWithGstr9And9CLocGrp.value.fileLoc"
                                                    title="Upload file"
                                                    (click)="uploadGstr1TiedUpWithGstr9And9CLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteGstr1TiedUpWithGstr9And9CLoc(i)"
                                                    *ngIf="gstr1TiedUpWithGstr9And9CLocGrp.value.fileLoc"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="gstr1TiedUpWithGstr9And9CLocGrp.value.remarks ? gstr1TiedUpWithGstr9And9CLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="gstr1TiedUpWithGstr9And9CLocGrp.value.remarks && gstr1TiedUpWithGstr9And9CLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="gstr1TiedUpWithGstr9And9CRemark">
                          </td>
                        </tr>
                        <tr>
                          <th colspan="3" class="fs14 thHead">Liability Reconciliation</th>
                        </tr>
                        <tr>
                          <th>Liability as per GSTR-1 tied up with Liability as per GSTR-3B</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;"
                                (click)="openGstr1LibWithGstr3bLoc(gstr1LibWithGstr3bLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getGstr1LibWithGstr3bLocLength() != 0"> {{getGstr1LibWithGstr3bLocLength()}}
                                Attachment</span>

                              <ng-template #gstr1LibWithGstr3bLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let gstr1LibWithGstr3bLocGrp of getGstr1LibWithGstr3bLocc(); let i = index"
                                          [formGroup]="gstr1LibWithGstr3bLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="gstr1LibWithGstr3bLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="gstr1LibWithGstr3bLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!gstr1LibWithGstr3bLocGrp.value.isdocLocUploadedClicked || gstr1LibWithGstr3bLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(gstr1LibWithGstr3bLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="gstr1LibWithGstr3bLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(gstr1LibWithGstr3bLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!gstr1LibWithGstr3bLocGrp.value.fileLoc || gstr1LibWithGstr3bLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadGstr1LibWithGstr3bLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteGstr1LibWithGstr3bLoc(i)"
                                                    *ngIf="gstr1LibWithGstr3bLocGrp.value.fileLoc"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="gstr1LibWithGstr3bLocGrp.value.remarks ? gstr1LibWithGstr3bLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="gstr1LibWithGstr3bLocGrp.value.remarks && gstr1LibWithGstr3bLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="gstr1LibWithGstr3bRemark"> </td>
                        </tr>
                        <tr>
                          <th>GSTR-3B Liability tied up with GL</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openGstr3bLinWithGlLoc(gstr3bLinWithGlLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getGstr3bLinWithGlLocLength() != 0"> {{getGstr3bLinWithGlLocLength()}}
                                Attachment</span>
                              <ng-template #gstr3bLinWithGlLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let gstr3bLinWithGlLocGrp of getGstr3bLinWithGlLocc(); let i = index"
                                          [formGroup]="gstr3bLinWithGlLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="gstr3bLinWithGlLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="gstr3bLinWithGlLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!gstr3bLinWithGlLocGrp.value.isdocLocUploadedClicked || gstr3bLinWithGlLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(gstr3bLinWithGlLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="gstr3bLinWithGlLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(gstr3bLinWithGlLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!gstr3bLinWithGlLocGrp.value.fileLoc || gstr3bLinWithGlLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadGstr3bLinWithGlLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteGstr3bLinWithGlLoc(i)"
                                                    *ngIf="gstr3bLinWithGlLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="gstr3bLinWithGlLocGrp.value.remarks ? gstr3bLinWithGlLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="gstr3bLinWithGlLocGrp.value.remarks && gstr3bLinWithGlLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="gstr3bLinWithGlRemark"> </td>
                        </tr>
                        <tr>
                          <th>GSTR-3B Liability tied up with GSTR-9 & GSTR-9C</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;"
                                (click)="openGstr3bLibWithGstr9And9CLoc(gstr3bLibWithGstr9And9CLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getGstr3bLibWithGstr9And9CLocLength() != 0">
                                {{getGstr3bLibWithGstr9And9CLocLength()}} Attachment</span>
                              <ng-template #gstr3bLibWithGstr9And9CLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let gstr3bLibWithGstr9And9CLocGrp of getGstr3bLibWithGstr9And9CLocc(); let i = index"
                                          [formGroup]="gstr3bLibWithGstr9And9CLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input
                                                    *ngIf="gstr3bLibWithGstr9And9CLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="gstr3bLibWithGstr9And9CLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!gstr3bLibWithGstr9And9CLocGrp.value.isdocLocUploadedClicked || gstr3bLibWithGstr9And9CLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(gstr3bLibWithGstr9And9CLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon"
                                                    *ngIf="gstr3bLibWithGstr9And9CLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(gstr3bLibWithGstr9And9CLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!gstr3bLibWithGstr9And9CLocGrp.value.fileLoc || gstr3bLibWithGstr9And9CLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadGstr3bLibWithGstr9And9CLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteGstr3bLibWithGstr9And9CLoc(i)"
                                                    *ngIf="gstr3bLibWithGstr9And9CLocGrp.value.fileLoc"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="gstr3bLibWithGstr9And9CLocGrp.value.remarks ? gstr3bLibWithGstr9And9CLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="gstr3bLibWithGstr9And9CLocGrp.value.remarks && gstr3bLibWithGstr9And9CLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="gstr3bLibWithGstr9And9CRemark">
                          </td>
                        </tr>
                        <tr>
                          <th>RCM Liability Reco (RCM Register vs GST-3B)</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openRcmLibRecoLoc(rcmLibRecoLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getRcmLibRecoLocLength() != 0"> {{getRcmLibRecoLocLength()}}
                                Attachment</span>

                              <ng-template #rcmLibRecoLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let rcmLibRecoLocGrp of getRcmLibRecoLocc(); let i = index"
                                          [formGroup]="rcmLibRecoLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="rcmLibRecoLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="rcmLibRecoLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!rcmLibRecoLocGrp.value.isdocLocUploadedClicked || rcmLibRecoLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(rcmLibRecoLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="rcmLibRecoLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(rcmLibRecoLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!rcmLibRecoLocGrp.value.fileLoc || rcmLibRecoLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadRcmLibRecoLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteRcmLibRecoLoc(i)"
                                                    *ngIf="rcmLibRecoLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="rcmLibRecoLocGrp.value.remarks ? rcmLibRecoLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="rcmLibRecoLocGrp.value.remarks && rcmLibRecoLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="rcmLibRecoRemark"> </td>
                        </tr>
                        <tr>
                          <th>GST Liability on Advances (Advance Register vs GSTR-3B)</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openGstLibOnAdvancesLoc(gstLibOnAdvancesLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getGstLibOnAdvancesLocLength() != 0"> {{getGstLibOnAdvancesLocLength()}}
                                Attachment</span>

                              <ng-template #gstLibOnAdvancesLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let gstLibOnAdvancesLocGrp of getGstLibOnAdvancesLocc(); let i = index"
                                          [formGroup]="gstLibOnAdvancesLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="gstLibOnAdvancesLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="gstLibOnAdvancesLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!gstLibOnAdvancesLocGrp.value.isdocLocUploadedClicked || gstLibOnAdvancesLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(gstLibOnAdvancesLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="gstLibOnAdvancesLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(gstLibOnAdvancesLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!gstLibOnAdvancesLocGrp.value.fileLoc || gstLibOnAdvancesLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadGstLibOnAdvancesLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteGstLibOnAdvancesLoc(i)"
                                                    *ngIf="gstLibOnAdvancesLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="gstLibOnAdvancesLocGrp.value.remarks ? gstLibOnAdvancesLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="gstLibOnAdvancesLocGrp.value.remarks && gstLibOnAdvancesLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="gstLibOnAdvancesRemark"> </td>
                        </tr>
                        <tr>
                          <th>Amendments: Details of Corrections, Debit Notes & Credit Notes</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openAmendmentsLoc(amendmentsLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getAmendmentsLocLength() != 0"> {{getAmendmentsLocLength()}}
                                Attachment</span>

                              <ng-template #amendmentsLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let amendmentsLocGrp of getAmendmentsLocc(); let i = index"
                                          [formGroup]="amendmentsLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="amendmentsLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="amendmentsLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!amendmentsLocGrp.value.isdocLocUploadedClicked || amendmentsLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(amendmentsLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="amendmentsLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(amendmentsLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!amendmentsLocGrp.value.fileLoc || amendmentsLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadAmendmentsLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAmendmentsLoc(i)"
                                                    *ngIf="amendmentsLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="amendmentsLocGrp.value.remarks ? amendmentsLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="amendmentsLocGrp.value.remarks && amendmentsLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="amendmentsRemark"> </td>
                        </tr>
                        <tr>
                          <th colspan="3" class="fs14 thHead">Input Tax Reconciliation</th>
                        </tr>
                        <tr>
                          <th>ITC as per GL tied up with ITC as per Purchase Register</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openItcAsPerPurRegLoc(itcAsPerPurRegLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getItcAsPerPurRegLocLength() != 0"> {{getItcAsPerPurRegLocLength()}}
                                Attachment</span>

                              <ng-template #itcAsPerPurRegLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let getItcAsPerPurRegGrp of getItcAsPerPurRegLocc(); let i = index"
                                          [formGroup]="getItcAsPerPurRegGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="getItcAsPerPurRegGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="itcAsPerPurRegLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!getItcAsPerPurRegGrp.value.isdocLocUploadedClicked || getItcAsPerPurRegGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(getItcAsPerPurRegGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="getItcAsPerPurRegGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(getItcAsPerPurRegGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!getItcAsPerPurRegGrp.value.fileLoc || getItcAsPerPurRegGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadItcAsPerPurRegLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteItcAsPerPurRegLoc(i)"
                                                    *ngIf="getItcAsPerPurRegGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="getItcAsPerPurRegGrp.value.remarks ? getItcAsPerPurRegGrp.value.remarks:''">
                                            <div
                                              *ngIf="getItcAsPerPurRegGrp.value.remarks && getItcAsPerPurRegGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>

                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="itcAsPerGlRemark"> </td>
                        </tr>
                        <tr>
                          <th>ITC as per Purchase Register tied up with GSTR-3B</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openItcAsPerGlLoc(itcAsPerGlLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getItcAsPerGlLocLength() != 0"> {{getItcAsPerGlLocLength()}}
                                Attachment</span>

                              <ng-template #itcAsPerGlLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let itcAsPerGlLocGrp of getItcAsPerGlLocc(); let i = index"
                                          [formGroup]="itcAsPerGlLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="itcAsPerGlLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="itcAsPerGlLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!itcAsPerGlLocGrp.value.isdocLocUploadedClicked || itcAsPerGlLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(itcAsPerGlLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="itcAsPerGlLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(itcAsPerGlLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!itcAsPerGlLocGrp.value.fileLoc || itcAsPerGlLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadItcAsPerGlLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteItcAsPerGlLoc(i)"
                                                    *ngIf="itcAsPerGlLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="itcAsPerGlLocGrp.value.remarks ? itcAsPerGlLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="itcAsPerGlLocGrp.value.remarks && itcAsPerGlLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>

                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="itcAsPerPurRegRemark"> </td>
                        </tr>
                        <tr>
                          <th>GSTR-3B tied up GSTR-2A </th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;"
                                (click)="openGstr3bTiedUpGstr2aLoc(gstr3bTiedUpGstr2aLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getGstr3bTiedUpGstr2aLocLength() != 0"> {{getGstr3bTiedUpGstr2aLocLength()}}
                                Attachment</span>

                              <ng-template #gstr3bTiedUpGstr2aLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let getGstr3bTiedUpGstr2aLocGrp of getGstr3bTiedUpGstr2aLocc(); let i = index"
                                          [formGroup]="getGstr3bTiedUpGstr2aLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input
                                                    *ngIf="getGstr3bTiedUpGstr2aLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="gstr3bTiedUpGstr2aLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!getGstr3bTiedUpGstr2aLocGrp.value.isdocLocUploadedClicked || getGstr3bTiedUpGstr2aLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(getGstr3bTiedUpGstr2aLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon"
                                                    *ngIf="getGstr3bTiedUpGstr2aLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(getGstr3bTiedUpGstr2aLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!getGstr3bTiedUpGstr2aLocGrp.value.fileLoc || getGstr3bTiedUpGstr2aLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadGstr3bTiedUpGstr2aLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteGstr3bTiedUpGstr2aLoc(i)"
                                                    *ngIf="getGstr3bTiedUpGstr2aLocGrp.value.fileLoc"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="getGstr3bTiedUpGstr2aLocGrp.value.remarks ? getGstr3bTiedUpGstr2aLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="getGstr3bTiedUpGstr2aLocGrp.value.remarks && getGstr3bTiedUpGstr2aLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>

                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="gstr3bTiedUpGstr2aRemark"> </td>
                        </tr>
                        <tr>
                          <th>ITC as per GSTR-3B tied up with GSTR-9 & GSTR-9C</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openItcAsPerGstr3bLoc(itcAsPerGstr3bLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getItcAsPerGstr3bLocLength() != 0"> {{getItcAsPerGstr3bLocLength()}}
                                Attachment</span>

                              <ng-template #itcAsPerGstr3bLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let itcAsPerGstr3bLocGrp of getItcAsPerGstr3bLocc(); let i = index"
                                          [formGroup]="itcAsPerGstr3bLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="itcAsPerGstr3bLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="itcAsPerGstr3bLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!itcAsPerGstr3bLocGrp.value.isdocLocUploadedClicked || itcAsPerGstr3bLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(itcAsPerGstr3bLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="itcAsPerGstr3bLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(itcAsPerGstr3bLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!itcAsPerGstr3bLocGrp.value.fileLoc || itcAsPerGstr3bLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadItcAsPerGstr3bLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteItcAsPerGstr3bLoc(i)"
                                                    *ngIf="itcAsPerGstr3bLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="itcAsPerGstr3bLocGrp.value.remarks ? itcAsPerGstr3bLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="itcAsPerGstr3bLocGrp.value.remarks && itcAsPerGstr3bLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>

                          </td>
                          <td><input type="text" class="form-control" formControlName="itcAsPerGstr3bRemark"> </td>
                        </tr>
                        <tr>
                          <th>Reversal Computations – Ineligible Credit, Common Credit Reversal due to Exempted</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openReversalsCompLoc(reversalsCompLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getReversalsCompLocLength() != 0"> {{getReversalsCompLocLength()}}
                                Attachment</span>

                              <ng-template #reversalsCompLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let reversalsCompLocGrp of getReversalsCompLocc(); let i = index"
                                          [formGroup]="reversalsCompLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="reversalsCompLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="reversalsCompLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!reversalsCompLocGrp.value.isdocLocUploadedClicked || reversalsCompLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(reversalsCompLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="reversalsCompLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(reversalsCompLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!reversalsCompLocGrp.value.fileLoc || reversalsCompLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadReversalsCompLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteReversalsCompLoc(i)"
                                                    *ngIf="reversalsCompLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="reversalsCompLocGrp.value.remarks ? reversalsCompLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="reversalsCompLocGrp.value.remarks && reversalsCompLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="reversalsCompRemark"> </td>
                        </tr>
                        <tr>
                          <th>Turnover (or) Personal/Non-Business Use etc.</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openTurnoverLoc(turnoverLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getTurnoverLocLength() != 0"> {{getTurnoverLocLength()}} Attachment</span>

                              <ng-template #turnoverLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let turnoverLocGrp of getTurnoverLocc(); let i = index"
                                          [formGroup]="turnoverLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="turnoverLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="turnoverLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!turnoverLocGrp.value.isdocLocUploadedClicked || turnoverLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(turnoverLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="turnoverLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(turnoverLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!turnoverLocGrp.value.fileLoc || turnoverLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadTurnoverLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteTurnoverLoc(i)"
                                                    *ngIf="turnoverLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="turnoverLocGrp.value.remarks ? turnoverLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="turnoverLocGrp.value.remarks && turnoverLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="turnoverRemark"> </td>
                        </tr>
                        <tr>
                          <th>Computation of ISD Credit Distribution </th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openIsdCreditDistLoc(isdCreditDistLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getIsdCreditDistLocLength() != 0"> {{getIsdCreditDistLocLength()}}
                                Attachment</span>

                              <ng-template #isdCreditDistLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let isdCreditDistLocGrp of getIsdCreditDistLocc(); let i = index"
                                          [formGroup]="isdCreditDistLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="isdCreditDistLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="isdCreditDistLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!isdCreditDistLocGrp.value.isdocLocUploadedClicked || isdCreditDistLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(isdCreditDistLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="isdCreditDistLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(isdCreditDistLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!isdCreditDistLocGrp.value.fileLoc || isdCreditDistLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadIsdCreditDistLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteIsdCreditDistLoc(i)"
                                                    *ngIf="isdCreditDistLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="isdCreditDistLocGrp.value.remarks ? isdCreditDistLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="isdCreditDistLocGrp.value.remarks && isdCreditDistLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="isdCreditDistRemark"> </td>
                        </tr>
                        <tr>
                          <th colspan="3" class="fs14 thHead">Invoices – Soft Copy (State Wise/GSTIN Wise)</th>
                        </tr>
                        <tr>
                          <th>Sales invoices, debit notes, credit notes along with PO/Contract</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openSalesInvEtcLoc(salesInvEtcLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getSalesInvEtcLocLength() != 0"> {{getSalesInvEtcLocLength()}}
                                Attachment</span>

                              <ng-template #salesInvEtcLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let salesInvEtcLoccGrp of getSalesInvEtcLocc(); let i = index"
                                          [formGroup]="salesInvEtcLoccGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="salesInvEtcLoccGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="salesInvEtcLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!salesInvEtcLoccGrp.value.isdocLocUploadedClicked || salesInvEtcLoccGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(salesInvEtcLoccGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="salesInvEtcLoccGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(salesInvEtcLoccGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!salesInvEtcLoccGrp.value.fileLoc || salesInvEtcLoccGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadSalesInvEtcLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteSalesInvEtcLoc(i)"
                                                    *ngIf="salesInvEtcLoccGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="salesInvEtcLoccGrp.value.remarks ? salesInvEtcLoccGrp.value.remarks:''">
                                            <div
                                              *ngIf="salesInvEtcLoccGrp.value.remarks && salesInvEtcLoccGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="salesInvEtcRemark"> </td>
                        </tr>
                        <tr>
                          <th>Purchase Invoices along with PO/Contract</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openPurchaseInvEtcLoc(purchaseInvEtcLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getPurchaseInvEtcLocLength() != 0"> {{getPurchaseInvEtcLocLength()}}
                                Attachment</span>

                              <ng-template #purchaseInvEtcLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let purchaseInvEtcGrp of getPurchaseInvEtcLocc(); let i = index"
                                          [formGroup]="purchaseInvEtcGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="purchaseInvEtcGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="purchaseInvEtcLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!purchaseInvEtcGrp.value.isdocLocUploadedClicked || purchaseInvEtcGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(purchaseInvEtcGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="purchaseInvEtcGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(purchaseInvEtcGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!purchaseInvEtcGrp.value.fileLoc || purchaseInvEtcGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadPurchaseInvEtcLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deletePurchaseInvEtcLoc(i)"
                                                    *ngIf="purchaseInvEtcGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="purchaseInvEtcGrp.value.remarks ? purchaseInvEtcGrp.value.remarks:''">
                                            <div
                                              *ngIf="purchaseInvEtcGrp.value.remarks && purchaseInvEtcGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="purchaseInvEtcRemark"> </td>
                        </tr>
                        <tr>
                          <th colspan="3" class="fs14 thHead">Returns & Others– (State Wise/GSTIN Wise)</th>
                        </tr>
                        <tr>
                          <th>Monthly Returns: GSTR-1 and GSTR-3B </th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openMonRetGstr1And3bLoc(monRetGstr1And3bLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getMonRetGstr1And3bLocLength() != 0"> {{getMonRetGstr1And3bLocLength()}}
                                Attachment</span>

                              <ng-template #monRetGstr1And3bLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let monRetGstr1And3bLocGrp of getMonRetGstr1And3bLocc(); let i = index"
                                          [formGroup]="monRetGstr1And3bLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="monRetGstr1And3bLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="monRetGstr1And3bLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!monRetGstr1And3bLocGrp.value.isdocLocUploadedClicked || monRetGstr1And3bLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(monRetGstr1And3bLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="monRetGstr1And3bLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(monRetGstr1And3bLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!monRetGstr1And3bLocGrp.value.fileLoc || monRetGstr1And3bLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadMonRetGstr1And3bLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteMonRetGstr1And3bLoc(i)"
                                                    *ngIf="monRetGstr1And3bLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="monRetGstr1And3bLocGrp.value.remarks ? monRetGstr1And3bLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="monRetGstr1And3bLocGrp.value.remarks && monRetGstr1And3bLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="monRetGstr1And3bRemark"> </td>
                        </tr>
                        <tr>
                          <th>Annual Returns: GSTR-9 & 9C</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openAnnRetGstr9And9cLoc(annRetGstr9And9cLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getAnnRetGstr9And9cLocLength() != 0"> {{getAnnRetGstr9And9cLocLength()}}
                                Attachment</span>

                              <ng-template #annRetGstr9And9cLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let annRetGstr9And9cLocGrp of getAnnRetGstr9And9cLocc(); let i = index"
                                          [formGroup]="annRetGstr9And9cLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="annRetGstr9And9cLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="annRetGstr9And9cLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!annRetGstr9And9cLocGrp.value.isdocLocUploadedClicked || annRetGstr9And9cLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(annRetGstr9And9cLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="annRetGstr9And9cLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(annRetGstr9And9cLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!annRetGstr9And9cLocGrp.value.fileLoc || annRetGstr9And9cLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadAnnRetGstr9And9cLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAnnRetGstr9And9cLoc(i)"
                                                    *ngIf="annRetGstr9And9cLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="annRetGstr9And9cLocGrp.value.remarks ? annRetGstr9And9cLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="annRetGstr9And9cLocGrp.value.remarks && annRetGstr9And9cLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="annRetGstr9And9cRemark"> </td>
                        </tr>
                        <tr>
                          <th>Specific Returns: GSTR-6 & ITC – 04</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;"
                                (click)="openSpeRetGstr6AndItc04Loc(speRetGstr6AndItc04LocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getSpeRetGstr6AndItc04LocLength() != 0">
                                {{getSpeRetGstr6AndItc04LocLength()}} Attachment</span>

                              <ng-template #speRetGstr6AndItc04LocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let speRetGstr6AndItc04LocGrp of getSpeRetGstr6AndItc04Locc(); let i = index"
                                          [formGroup]="speRetGstr6AndItc04LocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="speRetGstr6AndItc04LocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="speRetGstr6AndItc04Loc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!speRetGstr6AndItc04LocGrp.value.isdocLocUploadedClicked || speRetGstr6AndItc04LocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(speRetGstr6AndItc04LocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="speRetGstr6AndItc04LocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(speRetGstr6AndItc04LocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!speRetGstr6AndItc04LocGrp.value.fileLoc || speRetGstr6AndItc04LocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadSpeRetGstr6AndItc04Loc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteSpeRetGstr6AndItc04Loc(i)"
                                                    *ngIf="speRetGstr6AndItc04LocGrp.value.fileLoc"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="speRetGstr6AndItc04LocGrp.value.remarks ? speRetGstr6AndItc04LocGrp.value.remarks:''">
                                            <div
                                              *ngIf="speRetGstr6AndItc04LocGrp.value.remarks && speRetGstr6AndItc04LocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="speRetGstr6AndItc04Remark"> </td>
                        </tr>
                        <tr>
                          <th>Transitional Credit: TRAN-1 with Computations & Copies of ST-3 Returns</th>
                          <td>
                            <div class="col-sm-9 nopadding">

                              <span style="cursor: pointer;" (click)="openTransCreditEtcLoc(transCreditEtcLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getTransCreditEtcLocLength() != 0"> {{getTransCreditEtcLocLength()}}
                                Attachment</span>

                              <ng-template #transCreditEtcLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let transCreditEtcLocGrp of getTransCreditEtcLocc(); let i = index"
                                          [formGroup]="transCreditEtcLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="transCreditEtcLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="transCreditEtcLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!transCreditEtcLocGrp.value.isdocLocUploadedClicked || transCreditEtcLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(transCreditEtcLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="transCreditEtcLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(transCreditEtcLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!transCreditEtcLocGrp.value.fileLoc || transCreditEtcLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadTransCreditEtcLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteTransCreditEtcLoc(i)"
                                                    *ngIf="transCreditEtcLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="transCreditEtcLocGrp.value.remarks ? transCreditEtcLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="transCreditEtcLocGrp.value.remarks && transCreditEtcLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="transCreditEtcRemark"> </td>
                        </tr>
                        <tr>
                          <th>Ledgers: Electronic Cash Ledger & Electronic Credit Ledgers</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openLedgersEtcLoc(ledgersEtcLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getLedgersEtcLocLength() != 0"> {{getLedgersEtcLocLength()}}
                                Attachment</span>

                              <ng-template #ledgersEtcLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let ledgersEtcLocGrp of getLedgersEtcLocc(); let i = index"
                                          [formGroup]="ledgersEtcLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="ledgersEtcLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="ledgersEtcLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!ledgersEtcLocGrp.value.isdocLocUploadedClicked || ledgersEtcLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(ledgersEtcLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="ledgersEtcLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(ledgersEtcLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!ledgersEtcLocGrp.value.fileLoc || ledgersEtcLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadLedgersEtcLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteLedgersEtcLoc(i)"
                                                    *ngIf="ledgersEtcLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="ledgersEtcLocGrp.value.remarks ? ledgersEtcLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="ledgersEtcLocGrp.value.remarks && ledgersEtcLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="ledgersEtcRemark"> </td>
                        </tr>
                        <tr>
                          <th>Refund Applications: Details of Refund Applications with Computations & Documents</th>
                          <td>
                            <div class="col-sm-9 nopadding">

                              <span style="cursor: pointer;" (click)="openRefundAppEtcLoc(refundAppEtcLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getRefundAppEtcLocLength() != 0"> {{getRefundAppEtcLocLength()}}
                                Attachment</span>

                              <ng-template #refundAppEtcLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let refundAppEtcLocGrp of getRefundAppEtcLocc(); let i = index"
                                          [formGroup]="refundAppEtcLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="refundAppEtcLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="refundAppEtcLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!refundAppEtcLocGrp.value.isdocLocUploadedClicked || refundAppEtcLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(refundAppEtcLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="refundAppEtcLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(refundAppEtcLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!refundAppEtcLocGrp.value.fileLoc || refundAppEtcLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadRefundAppEtcLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteRefundAppEtcLoc(i)"
                                                    *ngIf="refundAppEtcLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="refundAppEtcLocGrp.value.remarks ? refundAppEtcLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="refundAppEtcLocGrp.value.remarks && refundAppEtcLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="refundAppEtcRemark"> </td>
                        </tr>
                        <tr>
                          <th>Challans: Any DRC-03 Challans or Other Payment Challans</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;" (click)="openChallansEtcLoc(challansEtcLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getChallansEtcLocLength() != 0"> {{getChallansEtcLocLength()}}
                                Attachment</span>

                              <ng-template #challansEtcLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let challansEtcLocGrp of getChallansEtcLocc(); let i = index"
                                          [formGroup]="challansEtcLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="challansEtcLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="challansEtcLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!challansEtcLocGrp.value.isdocLocUploadedClicked || challansEtcLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(challansEtcLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="challansEtcLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(challansEtcLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!challansEtcLocGrp.value.fileLoc || challansEtcLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadChallansEtcLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteChallansEtcLoc(i)"
                                                    *ngIf="challansEtcLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="challansEtcLocGrp.value.remarks ? challansEtcLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="challansEtcLocGrp.value.remarks && challansEtcLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="challansEtcRemark"> </td>
                        </tr>
                        <tr>
                          <th colspan="3" class="fs14 thHead">Others (State Wise/GSTIN Wise)</th>
                        </tr>
                        <tr>
                          <th>Details of Interest Payment</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;"
                                (click)="openInterestPayDetailsLoc(interestPayDetailsLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getInterestPayDetailsLocLength() != 0"> {{getInterestPayDetailsLocLength()}}
                                Attachment</span>

                              <ng-template #interestPayDetailsLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let interestPayDetailsLocGrp of getInterestPayDetailsLocc(); let i = index"
                                          [formGroup]="interestPayDetailsLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="interestPayDetailsLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="interestPayDetailsLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!interestPayDetailsLocGrp.value.isdocLocUploadedClicked || interestPayDetailsLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(interestPayDetailsLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="interestPayDetailsLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(interestPayDetailsLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!interestPayDetailsLocGrp.value.fileLoc || interestPayDetailsLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadInterestPayDetailsLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteInterestPayDetailsLoc(i)"
                                                    *ngIf="interestPayDetailsLocGrp.value.fileLoc"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="interestPayDetailsLocGrp.value.remarks ? interestPayDetailsLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="interestPayDetailsLocGrp.value.remarks && interestPayDetailsLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="interestPayDetailsRemark"> </td>
                        </tr>
                        <tr>
                          <th>Details of Penalty Payment</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;"
                                (click)="openPenaltyPayDetailsLoc(penaltyPayDetailsLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getPenaltyPayDetailsLocLength() != 0"> {{getPenaltyPayDetailsLocLength()}}
                                Attachment</span>

                              <ng-template #penaltyPayDetailsLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let penaltyPayDetailsLocGrp of getPenaltyPayDetailsLocc(); let i = index"
                                          [formGroup]="penaltyPayDetailsLocGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="penaltyPayDetailsLocGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="penaltyPayDetailsLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!penaltyPayDetailsLocGrp.value.isdocLocUploadedClicked || penaltyPayDetailsLocGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(penaltyPayDetailsLocGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="penaltyPayDetailsLocGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(penaltyPayDetailsLocGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!penaltyPayDetailsLocGrp.value.fileLoc || penaltyPayDetailsLocGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadPenaltyPayDetailsLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deletePenaltyPayDetailsLoc(i)"
                                                    *ngIf="penaltyPayDetailsLocGrp.value.fileLoc"><i class="fa fa-trash"
                                                      aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="penaltyPayDetailsLocGrp.value.remarks ? penaltyPayDetailsLocGrp.value.remarks:''">
                                            <div
                                              *ngIf="penaltyPayDetailsLocGrp.value.remarks && penaltyPayDetailsLocGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="penaltyPayDetailsRemark"> </td>
                        </tr>
                        <tr>
                          <th>Due Date of Filing vs Date of Filing of Returns</th>
                          <td>
                            <div class="col-sm-9 nopadding">
                              <span style="cursor: pointer;"
                                (click)="openDueDateVsDateOfFilingLoc(dueDateVsDateOfFilingLocPop)">
                                <i class="icon fa fa fa-paperclip" title="Attach file"
                                  style="cursor: pointer; margin-left: 10px;"></i></span>
                              <span *ngIf="getDueDateVsDateOfFilingLocLength() != 0">
                                {{getDueDateVsDateOfFilingLocLength()}} Attachment</span>

                              <ng-template #dueDateVsDateOfFilingLocPop let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span> </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          *ngFor="let dueDateVsDateOfFilingGrp of getDueDateVsDateOfFilingLocc(); let i = index"
                                          [formGroup]="dueDateVsDateOfFilingGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="dueDateVsDateOfFilingGrp.value.isdocLocUploadedClicked"
                                                    type="file" id="dueDateVsDateOfFilingLoc({{i}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!dueDateVsDateOfFilingGrp.value.isdocLocUploadedClicked || dueDateVsDateOfFilingGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(dueDateVsDateOfFilingGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="dueDateVsDateOfFilingGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(dueDateVsDateOfFilingGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!dueDateVsDateOfFilingGrp.value.fileLoc || dueDateVsDateOfFilingGrp.value.fileLoc"
                                                    title="Upload file" (click)="uploadDueDateVsDateOfFilingLoc(i)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteDueDateVsDateOfFilingLoc(i)"
                                                    *ngIf="dueDateVsDateOfFilingGrp.value.fileLoc"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="dueDateVsDateOfFilingGrp.value.remarks ? dueDateVsDateOfFilingGrp.value.remarks:''">
                                            <div
                                              *ngIf="dueDateVsDateOfFilingGrp.value.remarks && dueDateVsDateOfFilingGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </td>
                          <td><input type="text" class="form-control" formControlName="dueDateVsDateOfFilingRemark">
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>
        <p>
          <button class="btn btn-outline-primary mt15" (click)="saveReconData()"
            *ngIf="!['GST_Viewer','Viewer'].includes(role)">Save & Update</button>
        </p>
      </div>
    </div>
  </div>
</div>