<!-- <app-filter [isGST]=true (messageEvent)="onFilterEvent($event)" [filterData]="filterObj" *ngIf="isFilterDivOpen"></app-filter> -->


<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <a class="notify"><i class="fa fa-bell" (click)="showHidePop()"></i></a>
              <div class="notifyDiv top-2" *ngIf="showPop">
                <h2 class="report-header" *ngIf="!showHeader">MASTER REPORT </h2>
                <h2 class="report-header" *ngIf="showHeader">CONTINGENCY REPORT </h2>

                <i class="settings-close mdi mdi-close" (click)="closeDiv()"></i>
                <h6 class="progTxt" ng-show="flag2PData.downloadInProgress" *ngIf="showStatus">
                  <i class="fa fa-hourglass-start fs14 progIcon" style="margin-right: 5px; color: orange;"
                    *ngIf="!showHeader"></i> <span style="line-height: 1.4;" *ngIf="!showHeader">Download started for
                    Master Report.Please check status.</span>
                  <i class="fa fa-hourglass-start fs14 progIcon" style="margin-right: 5px; color: orange;"
                    *ngIf="showHeader"></i> <span style="line-height: 1.3;" *ngIf="showHeader">Download started for
                    Contingency Report.Please check status.</span>
                  <button class="reports" (click)="statusReport()"> Check Latest Status</button>
                </h6>
                <h6 class="progTxt" ng-show="flag2PData.downloadCompleted" *ngIf="!showStatus">
                  <i class="fa fa-check-square-o fs14 compIcon" style="margin-right: 5px; color: #025c48;"
                    *ngIf="checkIcon"></i>
                  <i class="fa fa-check-square-o fs14 compIcon" style="margin-right: 5px; color: orange;"
                    *ngIf="!checkIcon"></i> Download Completed for Report.
                  <button class="report-link" (click)="downLoadMasterReport()">Click to download </button>
                </h6>
              </div>
              <p class="card-title">GST Litigation Summary <span title="Open Cases">({{opneCase}}</span>/<span
                  title="Total Cases">{{totalCase}})</span>
                <!-- <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="sortFilterLbl" title="Filter" (click)="openDiv()"  [ngClass]="{'filterapplied':isFilterhighlited}">
                  <i class="fa fa-filter clrOrange notifyIcon" [ngClass]="{'filterapplied':isFilterhighlited}"></i> Filter</a> -->
                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="sortFilterLbl" title="Filter"
                  [ngClass]="{'filterapplied':isFilterhighlited}">
                  <i class="fa fa-filter clrOrange notifyIcon" [ngClass]="{'filterapplied':isFilterhighlited}"
                    (click)="openModal(filter)"></i><span (click)="openModal(filter)"> Filter</span></a>
                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="download" title="Master Report"
                  (click)="generatedReport('Master')" *ngIf="!showHeader1"><i
                    class="fa fa-download clrOrange notifyIcon"></i> Master Report</a>
                <a class="iris-gst-pull-right mt-1 fs14 mr-3" id="download" title="Contingency Report"
                  (click)="contingencyReport('Contingency')" *ngIf="1showHeader"><i
                    class="fa fa-download clrOrange notifyIcon"></i> Contingency Report</a>

              </p>
              <div class="row m-0 highDiv">
                <div class="col-12 nopadding">
                  <ul class="action-bar">
                    <li class="bg-transparent">
                      <ng-container *ngFor="let badge of badgesList;let i=index">
                        <small [ngClass]="[badgeColorArray[i], textColorArray[i]]"
                          class="bg-opacity-10 border  d-inline-flex fw-semibold mb-3 px-2 py-1 me-2 rounded-2 ">{{badge.name}}:
                          {{ badge.value }}
                          <i type="button" class="mdi mdi-close ms-2" (click)="removeFilter(badge)"></i>
                        </small>
                      </ng-container>
                    </li>
                  </ul>
                </div>
              </div>

              <ng-template #filter let-modal>

                <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">Filter</h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true">×</span>
                  </button>
                  <!-- <h5 class="modal-title pull-left ">Filter</h5>
                  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true" class="visually-hidden">&times;</span>
                  </button> -->
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="side_nav ms-0" style="width: auto">
                        <ul class="font-size-14">

                          <li (click)="filterType = 'Case Id'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Case Id' }">Case
                              Id</a>
                          </li>
                          <li (click)="filterType = 'Case Label'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Case Label' }">Case
                              Label</a>
                          </li>
                          <li (click)="filterType = 'Consultant'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Consultant' }">Consultant</a>
                          </li>
                          <li (click)="filterType = 'Demand Amount'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Demand Amount' }">Demand Amount</a>
                          </li>
                          <li (click)="filterType = 'Divison'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Divison' }">Divison</a>
                          </li>
                          <li (click)="filterType = 'Due Date'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Due Date' }">Due
                              Date</a>
                          </li>
                          <li (click)="filterType = 'Issue'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Issue' }">Issue</a>
                          </li>
                          <li (click)="filterType = 'Key words'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Key words' }">Key
                              words</a>
                          </li>
                          <li (click)="filterType = 'Period From'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Period From' }">Period From</a>
                          </li>
                          <li (click)="filterType = 'Period To'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'Period To' }">Period
                              To</a>
                          </li>
                          <li (click)="filterType = 'Person Responsible'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Person Responsible' }">Person Responsible</a>
                          </li>
                          <li (click)="filterType = 'Registration Number'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Registration Number' }">Registration
                              Number</a>
                          </li>
                          <li (click)="filterType = 'Status'">
                            <a class=" cursor-pointer"
                              [ngClass]="{ 'filter-active': filterType == 'Status' }">Status</a>
                          </li>
                          <li (click)="filterType = 'State'">
                            <a class=" cursor-pointer" [ngClass]="{ 'filter-active': filterType == 'State' }">State</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="col-sm-9 mt-2">
                      <form [formGroup]="filterForm" class="formSec">
                        <div *ngIf="filterType == 'Case Id'">
                          <label>Case Id</label>
                          <input type="text" class="form-control mt-2" formControlName="caseId" />

                        </div>
                        <div *ngIf="filterType == 'Case Label'">
                          <label>Case Label</label>
                          <input type="text" class="form-control mt-2" formControlName="caseLabel" />

                        </div>
                        <div *ngIf="filterType == 'Consultant'">
                          <label>Consultant</label>
                          <input type="text" class="form-control mt-2" formControlName="consultant" />

                        </div>
                        <div *ngIf="filterType == 'Demand Amount'">
                          <label>Demand Amount</label>
                          <select class="form-control form-select me-3 " formControlName="amtQuery"
                            [value]="amtQuery.o">
                            <option [value]="null">-- Select --</option>
                            <ng-container *ngFor="let obj of demandAmtOptions">
                              <option [value]="obj.key">{{obj.value}}</option>
                            </ng-container>
                          </select>
                          <input type="text" class="form-control mt-2" formControlName="amtQueryValue" />
                        </div>
                        <div *ngIf="filterType == 'Divison'">
                          <label>Divison</label>
                          <ng-multiselect-dropdown formControlName="div" [placeholder]="'Select Divison'"
                            [settings]="dropdownSettings" [data]="division">
                          </ng-multiselect-dropdown>
                        </div>
                        <div *ngIf="filterType == 'Due Date'">
                          <label>Due Date</label>
                          <!-- <div class="col-md-12">
                            <button class="btn btn-outline-primary mr-1" (click)="nextWeek()">Next Week</button>
                            <button class="btn btn-outline-primary mr-1" (click)="nextMonth()">Next Month</button>
                            <button class="btn btn-outline-primary mr-1" (click)="custom()">Custom</button>
                          </div> -->
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="">Start </label>
                              <div id="dd1frmDate" class="input-group date datepicker ">
                                <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                  yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                  dataType="string" formControlName="dd1"></p-calendar>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="">End</label>
                              <div id="dd2toDate" class="input-group date datepicker ">
                                <p-calendar dateFormat="dd-mm-yy" [monthNavigator]="true" [yearNavigator]="true"
                                  yearRange="2016:{{currentYear}}" [showIcon]="true" dataType="string"
                                  formControlName="dd2"></p-calendar>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="filterType == 'Issue'">
                          <label>Issue</label>
                          <select class="form-control form-select " id="issue" formControlName="issue">
                            <option [value]="null">-- Select --</option>
                            <optgroup *ngFor='let grp of issueGst' label="{{grp.group}}">
                              <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                {{item.name}}</option>
                            </optgroup>
                          </select>
                        </div>
                        <div *ngIf="filterType == 'Key words'">
                          <label>Key Word</label>
                          <input type="text" class="form-control mt-2" formControlName="kw" />
                        </div>
                        <div *ngIf="filterType == 'Period From'">
                          <label>Period From</label>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="">Start </label>
                              <div id="pf1rmDate" class="input-group date datepicker">
                                <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                  yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                  formControlName="pf1" dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="">End</label>
                              <div id="pf2toDate" class="input-group date datepicker ">
                                <p-calendar dateFormat="dd-mm-yy" [monthNavigator]="true" [yearNavigator]="true"
                                  yearRange="2016:{{currentYear}}" [showIcon]="true" formControlName="pf2"
                                  dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="filterType == 'Period To'">
                          <label>Period To</label>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="">Start </label>
                              <div id="pt1frmDate" class="input-group date datepicker ">
                                <p-calendar [monthNavigator]="true" [yearNavigator]="true"
                                  yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                  formControlName="pt1" dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="">End</label>
                              <div id="pt2toDate" class="input-group date datepicker ">
                                <p-calendar dateFormat="dd-mm-yy" [monthNavigator]="true" [yearNavigator]="true"
                                  yearRange="2016:{{currentYear}}" [showIcon]="true" formControlName="pt2"
                                  dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="filterType == 'Person Responsible'">
                          <label for="exampleSelectGender">Person Responsible</label>
                          <select class="form-control form-select " id="personResponsible1" formControlName="pr">
                            <option [value]="null" disabled>-- Select --</option>
                            <ng-container *ngFor="let obj of userRoleLs">
                              <option [value]="obj.userEmailId">{{obj.userEmailId}}</option>
                            </ng-container>
                          </select>
                        </div>
                        <div *ngIf="filterType == 'Registration Number'">
                          <label for="exampleSelectGender">Registration Number</label>
                          <ng-multiselect-dropdown [placeholder]="'Select Registration Number'"
                            [settings]="dropdownSettings" [data]="leagalRegNum" formControlName="gstin">
                          </ng-multiselect-dropdown>
                          <!-- <ng-multiselect-dropdown  *ngIf="isAudit"
                        [placeholder]="'Select Registration Number'"
                        [settings]="dropdownSettings"
                        [data]="leagalRegNum"
                        formControlName="gstin">
                    </ng-multiselect-dropdown>
                    <ng-multiselect-dropdown  *ngIf="!isGST && !isAudit "
                    [placeholder]="'Select Registration Number'"
                    [settings]="dropdownSettings"
                    [data]="preGstregGstinData"
                    formControlName="gstin">
                </ng-multiselect-dropdown> -->
                        </div>
                        <div *ngIf="filterType == 'Status'">
                          <label for="exampleSelectGender">Status</label>
                          <ng-multiselect-dropdown formControlName="st" [placeholder]="'Select Status'"
                            [settings]="dropdownSettings" [data]="filterStatus">
                          </ng-multiselect-dropdown>
                        </div>
                        <div *ngIf="filterType == 'State'">
                          <label for="exampleSelectGender">State</label>
                          <ng-multiselect-dropdown formControlName="state" [placeholder]="'Select Status'"
                            [settings]="dropdownSettings" [data]="stateNames">
                          </ng-multiselect-dropdown>
                        </div>
                      </form>

                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn_primary_theme font-size-16 ms-5" (click)="clearAll()">
                    Clear All
                  </button>
                  <button type="button" class="btn btn_primary_theme font-size-16" (click)="applyFilter(true)">
                    Apply
                  </button>
                </div>

              </ng-template>

              <ng-template #deletePop let-modal>
                <div class="modal-header">
                  <h4 class="modal-title">
                    <p style="color: red;">WARNING!</p> The case once deleted it will get deleted permanently
                  </h4>
                  <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                    <span aria-hidden="true" style="color: red;">×</span>
                  </button>
                </div>
                <div class="modal-body m-10">
                  <form #myForm="ngForm" novalidate>
                    <div class="custom-control custom-radio">
                      <input id="current-forum" type="radio" class="custom-control-input" [(ngModel)]="deleteForum"
                        value="current-forum" name="case" ngModel required>
                      <label class="custom-control-label" for="current-forum">Do you want to delete the case from
                        <span class="bold-font">Current Forum?</span>
                      </label>
                    </div>
                    <!-- AT -->
                    <div class="custom-control custom-radio">
                      <input id="entire-case" type="radio" class="custom-control-input" [(ngModel)]="deleteForum"
                        value="entire-case" name="case" ngModel required>
                      <label class="custom-control-label" for="entire-case">Do you want to delete the
                        <span class="bold-font">Entire case?</span> </label>
                    </div>
                    <br>
                    <div class="submit-footer">
                      <button type="submit" class="btn btn-outline-primary mr-1 " [disabled]="!myForm.value.case"
                        (click)="submitForm()">Yes</button>

                      <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                        (click)="modal.close('No click')">NO</button>
                    </div>
                  </form>
                </div>
              </ng-template>

              <div class="">
                <div class="accordion" id="accordion" role="tablist">

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-1">
                      <h6 class="mb-0">
                        <a id="collapseToggle-1" data-toggle="collapse" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1"
                          (click)="toggleForumTab('scn');isFilterApplied('scn') ">
                          <!-- Pre-Litigation <span *ngIf="cntSCN > 0" class="AccCount">({{cntOpSCN}}/{{cntSCN}})</span> -->
                          Pre-Litigation <span class="AccCount">({{cntOpSCN}}/{{cntSCN}})</span>
                        </a>
                      </h6>
                    </div>

                    <div id="collapse-1" class="collapse" role="tabpanel" aria-labelledby="heading-1"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <!-- <a class="iris-gst-pull-right " (click)="getCaseData(null, 'create', 'scn',  'tab1')"><span
                                class="badge badge-outline-success btnGreenDisabled" title="Export to Excel"><i
                                  class="fa fa-file-excel-o "></i></span></a>  -->
                            <a class="pr5 iris-gst-pull-right pt-2" (click)="createCase('create', 'scn', 'tab1')"
                              *ngIf="navContext?.entityType == 'FILING' && !['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                class="badge badge-outline-success " title="Create Case File"><i
                                  class="fa fa-pencil-square "></i></span></a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="min-width:155px;"><span>Case ID</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.caseId"
                                      (click)="getLitigationSumm('scn',litigationSort.CASE_ID)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.caseId"
                                      (click)="getLitigationSumm('scn',litigationSort.CASE_ID)"></i>
                                  </th>
                                  <th><span>State</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.state"
                                      (click)="getLitigationSumm('scn',litigationSort.STATE)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.state"
                                      (click)="getLitigationSumm('scn',litigationSort.STATE)"></i>
                                  </th>
                                  <th><span> GSTIN</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.gstin"
                                      (click)="getLitigationSumm('scn',litigationSort.GSTIN)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.gstin"
                                      (click)="getLitigationSumm('scn',litigationSort.GSTIN)"></i>
                                  </th>
                                  <th><span> Case Label</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.caseLabel" (click)="getLitigationSumm('scn',litigationSort.CASE_LABEL)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.caseLabel" (click)="getLitigationSumm('scn',litigationSort.CASE_LABEL)"></i>
                                  </th>
                                  <th style="min-width:115px;"><span> Period From</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodFromDate" (click)="getLitigationSumm('scn',litigationSort.PERIOD_FROM)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodFromDate" (click)="getLitigationSumm('scn',litigationSort.PERIOD_FROM)"></i>
                                  </th>
                                  <th style="min-width:94px;"><span> Period To</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodToDate" (click)="getLitigationSumm('scn',litigationSort.PERIOD_TO)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodToDate" (click)="getLitigationSumm('scn',litigationSort.PERIOD_TO)"></i>
                                  </th>
                                  <th style="min-width:140px;"><span> Notice  Type</span></th>
                                  <th style="min-width:117px;"><span>Current Status</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.status"
                                      (click)="getLitigationSumm('scn',litigationSort.STATUS)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.status"
                                      (click)="getLitigationSumm('scn',litigationSort.STATUS)"></i>
                                  </th>
                                  <th style="width:121px;">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngFor="let obj of letterSumofSCN | paginate: tableSection1Config; let i=index">
                                  <tr>
                                    <td>
                                      <a (click)="getCaseData(obj.caseId, 'view', 'scn', 'tab1')">{{obj.caseId}}</a>
                                      <a class="letterTbl" (click)="obj.expanded = !obj.expanded"
                                        *ngIf="isCaseItemClose"><span class="expand">{{ obj.expanded ? '&ndash;' :
                                          '+'}}</span></a>
                                    </td>
                                    <td title="{{obj.state}}">{{obj.state | uppercase}}</td>
                                    <td>{{obj.gstin}}</td>
                                    <td title="{{obj.caseLabel}}">{{obj.caseLabel}}</td>
                                    <td>{{obj.periodFromDate}}</td>
                                    <td>{{obj.periodToDate}}</td>
                                    <td> </td>
                                    <td>
                                      <label class="badge badge-success" (click)="currentStatusFilter(obj.status,'scn')"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning" (click)="currentStatusFilter(obj.status,'scn')"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'scn')" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'scn')" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'scn')" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'scn')" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'scn')" title="PETITION YET TO BE FILED"
                                        *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'scn')" title="NO RESPONSE"
                                        *ngIf="obj.status == 'NO RESPONSE'" style="padding: 3px;">{{obj.status}}</label>
                                    </td>
                                    <td class="w172">
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'scn',obj.caseId)"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                          class="badge badge-outline-trash " title="Delete"><i
                                            class="fa fa-trash"></i></span>
                                      </a>
                                      <a (click)="getCaseData(obj.caseId, 'view', 'scn', 'tab1')" class="pr5"><span
                                          class="badge badge-outline-success " title="View"><i
                                            class="fa fa-eye "></i></span>
                                      </a>
                                      <a (click)="getCaseData(obj.caseId, 'update', 'scn', 'tab1')"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                        class="pr5"><span class="badge badge-outline-primary " title="Update"><i
                                            class="fa fa-pencil-square-o "></i></span>
                                      </a>
                                    </td>
                                  </tr>
                                  <ng-container *ngIf="obj.expanded">
                                    <tr *ngFor="let item of obj.itemList; let i=index">
                                      <td title="LetterRefNo" style="padding-left:26px;"> Letter Ref No: {{item.letterRefNo}}</td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td>--</td>
                                      <td class="" title="{{item.periodFromDate}}">{{item.periodFromDate}}</td>
                                      <td title="{{item.periodToDate}}">{{item.periodToDate}}</td>
                                      <td  title="{{item.typeOfForm}}">{{item.typeOfForm}}</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </ng-container>

                                </ng-container>
                              </tbody>
                            </table>
                            <br />
                            <div class="row noMargin">
                              <div class="col-md-10 nopadding">
                                <pagination-controls (pageChange)="onTableDataChangeSection1($event)"
                                  id="tableSection1Config"></pagination-controls>
                              </div>
                              <div class="col-md-2 nopadding">
                                <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection1"
                                  (ngModelChange)="getSection1Data(perPageLimitSection1,1,'select')" id="authority">
                                  <option value="10">10</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="1000">1000</option>
                                </select>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-2">
                      <h6 class="mb-0">
                        <a id="collapseToggle-2" class="collapsed" data-toggle="collapse" href="#collapse-2" aria-expanded="false"
                          aria-controls="collapse-2" (click)="toggleForumTab('aja');isFilterApplied('aja')">
                          Adjudication Stage <span class="AccCount">({{cntOpAJA}}/{{cntAJA}})</span>
                        </a>
                      </h6>
                    </div>
                    <!-- <div id="collapse-2" class="collapse " [ngClass]="{show: filterObj?.showAcc}" role="tabpanel" aria-labelledby="heading-2" data-parent="#accordion"> -->
                    <div id="collapse-2" class="collapse " role="tabpanel" aria-labelledby="heading-2"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <!-- <a class="iris-gst-pull-right "><span
                                class="badge badge-outline-success" title="Export to Excel"><i
                                  class="fa fa-file-excel-o "></i></span></a> -->

                            <a class="pr5 iris-gst-pull-right pt-2" (click)="createCase('create', 'aja', 'tab2')"
                              *ngIf="navContext?.entityType == 'FILING' && !['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "></i></span></a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:180px;"><span>Case ID</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.caseId"
                                      (click)="getLitigationSumm('aja',ajaLitigationSort.CASE_ID)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.caseId"
                                      (click)="getLitigationSumm('aja',ajaLitigationSort.CASE_ID)"></i>
                                  </th>
                                  <th><span>State</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.state"
                                      (click)="getLitigationSumm('aja',ajaLitigationSort.STATE)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.state"
                                      (click)="getLitigationSumm('aja',ajaLitigationSort.STATE)"></i>
                                  </th>
                                  <th><span>GSTIN</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.gstin"
                                      (click)="getLitigationSumm('aja',ajaLitigationSort.GSTIN)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.gstin"
                                      (click)="getLitigationSumm('aja',ajaLitigationSort.GSTIN)"></i>
                                  </th>
                                  <th><span>Case Label</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.caseLabel" (click)="getLitigationSumm('aja',ajaLitigationSort.CASE_LABEL)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.caseLabel" (click)="getLitigationSumm('aja',ajaLitigationSort.CASE_LABEL)"></i>
                                  </th>
                                  <!-- <th>Keyword</th> -->
                                  <th style="min-width: 103px;"><span>Period From</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodFromDate"
                                      (click)="getLitigationSumm('aja',ajaLitigationSort.PERIOD_FROM)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodFromDate"
                                      (click)="getLitigationSumm('aja',ajaLitigationSort.PERIOD_FROM)"></i>
                                  </th>
                                  <th style="min-width: 94px;"><span>Period To</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodToDate" (click)="getLitigationSumm('aja',ajaLitigationSort.PERIOD_TO)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodToDate" (click)="getLitigationSumm('aja',ajaLitigationSort.PERIOD_TO)"></i>
                                  </th>
                                  <th style="min-width: 99px;"><span>Demand (₹)</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.totOfTotal1" (click)="getLitigationSumm('aja',ajaLitigationSort.DEMAND)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.totOfTotal1" (click)="getLitigationSumm('aja',ajaLitigationSort.DEMAND)"></i>
                                  </th>
                                  <th style="min-width:117px;"><span>Current Status</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.status"
                                      (click)="getLitigationSumm('aja',ajaLitigationSort.STATUS)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.status"
                                      (click)="getLitigationSumm('aja',ajaLitigationSort.STATUS)"></i>
                                  </th>
                                  <th style="min-width:128px;">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngFor="let obj of letterSumofAJA | paginate: tableSection2Config;let i=index">
                                  <tr>
                                    <td title="{{obj.caseId}}"><a
                                        (click)="getCaseData(obj.caseId, 'view', 'aja', 'tab2')">{{obj.caseId}}</a></td>
                                    <!-- <td class="tdElpsis"  title="{{getStateName(obj.state)}}">{{getStateName(obj.state)}}</td> -->
                                    <td title="{{obj.state}}">{{obj.state | uppercase}}</td>
                                    <td title="{{obj.gstin}}">{{obj.gstin}}</td>
                                    <td title="{{obj.caseLabel}}">{{obj.caseLabel}}</td>
                                    <td class="" title="{{obj.periodFromDate}}">{{obj.periodFromDate}}</td>
                                    <td class="" title="{{obj.periodToDate}}">{{obj.periodToDate }}</td>
                                    <td class="txtRight">{{obj.totOfTotal1 | INR: 0}}</td>
                                    <!-- <td class="txtRight">{{(obj.igst1Total + obj.cgst1Total + obj.cess1Total + obj.sgst1Total + obj.interest1Total + obj.penalty1Total) | currency:'₹':'':'1.0'}}</td> -->
                                    <!-- <td class="tdElpsis">{{obj.caseSummary}}</td> -->
                                    <!-- <td class="tdElpsis" title="{{obj.ajakeywordName}}">
                                    {{obj.ajakeywordName}}
                                   </td> -->
                                    <!-- <td class="" title="{{obj.dueDateOfReply}}">{{obj.dueDateOfReply}}</td> -->
                                    <td>
                                      <label class="badge badge-warning tdElpsis" title="CALL BOOK"
                                        (click)="currentStatusFilter(obj.status,'aja')"
                                        *ngIf="obj.status == 'CALL BOOK'">{{obj.status}}</label>
                                      <label class="badge badge-success" (click)="currentStatusFilter(obj.status,'aja')"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning" (click)="currentStatusFilter(obj.status,'aja')"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR REPLY"
                                        (click)="currentStatusFilter(obj.status,'aja')"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR HEARING"
                                        (click)="currentStatusFilter(obj.status,'aja')"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="PENDING FOR ORDER"
                                        (click)="currentStatusFilter(obj.status,'aja')"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="APPEAL YET TO BE FILED"
                                        (click)="currentStatusFilter(obj.status,'aja')"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                    </td>
                                    <!-- <td class="tdElpsis" title="{{obj.personResponsible}}">{{obj.personResponsible}}</td> -->
                                    <!-- <td class="tdElpsis" title="{{getFileNameDta(obj.prevOrderLoc)}}"><a (click)="download(obj.prevOrderLoc)">{{getFileNameDta(obj.prevOrderLoc)}}</a></td> -->

                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'aja',obj.caseId)"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                          class="badge badge-outline-trash " title="Delete"><i
                                            class="fa fa-trash"></i></span>
                                      </a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'view', 'aja', 'tab2')"><span
                                          class="badge badge-outline-success " title="View"><i
                                            class="fa fa-eye "></i></span>
                                      </a>
                                      <a (click)="navDownload(obj.caseId,'GST')"
                                      *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                      class="pr5"><span class="badge badge-outline-success " style="border-color: #e88e30;"
                                      title="Litigation Report"><i
                                        style="color: #e88e30;"  class="fa fa-file "></i></span>
                                    </a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'update', 'aja', 'tab2')"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                          class="badge badge-outline-primary " title="Edit"><i
                                            class="fa fa-pencil-square-o "></i></span>
                                      </a>
                                    
                                      <!-- <a class="pr5" (click)="getCaseData(obj.caseId, 'update', 'aja', 'tab2')" *ngIf="(navContext?.entityType == 'FILING' ) && !['GST_Viewer','PREGST_Viewer','Viewer'].includes(roleName)"><span class="badge badge-outline-primary " title="Edit"><i
                                          class="fa fa-pencil-square-o "></i></span>
                                    </a> -->
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                            <br />
                            <div class="row noMargin">
                              <div class="col-md-10 nopadding">
                                <pagination-controls (pageChange)="onTableDataChangeSection2($event)"
                                  id="tableSection2Config"></pagination-controls>
                              </div>
                              <div class="col-md-2 nopadding">

                                <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection2"
                                  (ngModelChange)="getSection2Data(perPageLimitSection2,1,'select')" id="authority">
                                  <option value="10">10</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="1000">1000</option>
                                </select>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-3">
                      <h6 class="mb-0">
                        <a id="collapseToggle-3" class="collapsed" data-toggle="collapse" href="#collapse-3" aria-expanded="false"
                          aria-controls="collapse-3" (click)="toggleForumTab('apa');isFilterApplied('apa')">
                          Commissioner (A) <span class="AccCount">({{cntOpAPA}}/{{cntAPA}})</span>
                        </a>
                      </h6>
                    </div>
                    <div id="collapse-3" class="collapse" role="tabpanel" aria-labelledby="heading-3"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <a class="pr5 iris-gst-pull-right pt-2" (click)="createCase('create', 'apa', 'tab3')"
                              *ngIf="navContext?.entityType == 'FILING' && !['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "></i></span></a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:142px;"><span>Case ID</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.caseId"
                                      (click)="getLitigationSumm('apa',apalitigationSort.CASE_ID)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.caseId"
                                      (click)="getLitigationSumm('apa',apalitigationSort.CASE_ID)"></i>
                                  </th>
                                  <th><span>State</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.state"
                                      (click)="getLitigationSumm('apa',apalitigationSort.STATE)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.state"
                                      (click)="getLitigationSumm('apa',apalitigationSort.STATE)"></i>
                                  </th>
                                  <th><span>GSTIN</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.gstin"
                                      (click)="getLitigationSumm('apa',apalitigationSort.GSTIN)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.gstin"
                                      (click)="getLitigationSumm('apa',apalitigationSort.GSTIN)"></i>
                                  </th>
                                  <th><span>Case Label</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.caseLabel"
                                      (click)="getLitigationSumm('apa',apalitigationSort.CASE_LABEL)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.caseLabel"
                                      (click)="getLitigationSumm('apa',apalitigationSort.CASE_LABEL)"></i>
                                  </th>
                                  <!-- <th>Keyword</th> -->
                                  <th style="min-width: 103px;"><span>Period From</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodFromDate"
                                      (click)="getLitigationSumm('apa',apalitigationSort.PERIOD_FROM)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodFromDate"
                                      (click)="getLitigationSumm('apa',apalitigationSort.PERIOD_FROM)"></i>
                                  </th>
                                  <th style="min-width: 94px;"><span>Period To</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodToDate"
                                      (click)="getLitigationSumm('apa',apalitigationSort.PERIOD_TO)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodToDate"
                                      (click)="getLitigationSumm('apa',apalitigationSort.PERIOD_TO)"></i>
                                  </th>
                                  <th style="min-width: 99px;"><span>Demand (₹)</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.totOfTotal1"
                                      (click)="getLitigationSumm('apa',apalitigationSort.DEMAND)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.totOfTotal1"
                                      (click)="getLitigationSumm('apa',apalitigationSort.DEMAND)"></i>
                                  </th>
                                  <th style="min-width:117px;"><span>Current Status</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.status"
                                      (click)="getLitigationSumm('apa',apalitigationSort.STATUS)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.status"
                                      (click)="getLitigationSumm('apa',apalitigationSort.STATUS)"></i>
                                  </th>
                                  <th style="min-width:128px;">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngFor="let obj of letterSumofAPA | paginate: tableSection3Config; let i=index">
                                  <tr>
                                    <td title="{{obj.caseId}}"><a
                                        (click)="getCaseData(obj.caseId, 'view', 'apa', 'tab3')">{{obj.caseId}}</a></td>
                                    <td title="{{obj.state}}">{{obj.state | uppercase}}</td>
                                    <td title="{{obj.gstin}}">{{obj.gstin}}</td>
                                    <td title="{{obj.caseLabel}}">{{obj.caseLabel}}</td>
                                    <td title="{{obj.periodFromDate}}">{{obj.periodFromDate}}</td>
                                    <td title="{{obj.periodToDate}}">{{obj.periodToDate }}</td>
                                    <td class="txtRight">{{obj.totOfTotal1 | INR: 0}}</td>
                                    <td>
                                      <label class="badge badge-warning tdElpsis" title="CALL BOOK"
                                        (click)="currentStatusFilter(obj.status,'apa')"
                                        *ngIf="obj.status == 'CALL BOOK'">{{obj.status}}</label>
                                      <label class="badge badge-success" (click)="currentStatusFilter(obj.status,'apa')"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning" (click)="currentStatusFilter(obj.status,'apa')"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'apa')" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'apa')" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'apa')" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'apa')" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                    </td>
                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'apa',obj.caseId)"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                          class="badge badge-outline-trash " title="Delete"><i
                                            class="fa fa-trash"></i></span>
                                      </a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'view', 'apa', 'tab3')"><span
                                          class="badge badge-outline-success" title="View"><i
                                            class="fa fa-eye "></i></span>
                                      </a>
                                      <a (click)="navDownload(obj.caseId,'GST')"
                                      *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                      class="pr5"><span class="badge badge-outline-success " style="border-color: #e88e30;"
                                      title="Litigation Report"><i
                                        style="color: #e88e30;"  class="fa fa-file "></i></span>
                                    </a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'update', 'apa', 'tab3')"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                          class="badge badge-outline-primary " title="Edit"><i
                                            class="fa fa-pencil-square-o "></i></span>
                                      </a>
                                     
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                            <br />
                            <div class="row noMargin">
                              <div class="col-md-10 nopadding">
                                <pagination-controls (pageChange)="onTableDataChangeSection3($event)"
                                  id="tableSection3Config"></pagination-controls>
                              </div>
                              <div class="col-md-2 nopadding">

                                <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection3"
                                  (ngModelChange)="getSection3Data(perPageLimitSection3,1,'select')" id="authority">
                                  <option value="10">10</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="1000">1000</option>
                                </select>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-4">
                      <h6 class="mb-0">
                        <a id="collapseToggle-4" class="collapsed" data-toggle="collapse" href="#collapse-4" aria-expanded="false"
                          aria-controls="collapse-4" (click)="toggleForumTab('apt');isFilterApplied('apt')">
                          Appellate Tribunal <span class="AccCount">({{cntOpAPT}}/{{cntAPT}})</span>
                        </a>
                      </h6>
                    </div>
                    <!-- <div id="collapse-4" class="collapse" [ngClass]="{show: filterObj?.showAcc}" role="tabpanel" aria-labelledby="heading-4" data-parent="#accordion"> -->
                    <div id="collapse-4" class="collapse" role="tabpanel" aria-labelledby="heading-4"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <!-- <a class="iris-gst-pull-right "><span
                                class="badge badge-outline-success" title="Export to Excel"><i
                                  class="fa fa-file-excel-o "></i></span></a> -->
                            <a class="pr5 iris-gst-pull-right pt-2" (click)="createCase('create', 'apt', 'tab4')"
                              *ngIf="navContext?.entityType == 'FILING' && !['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square"></i></span></a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="min-width:142px;"><span>Case ID</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.caseId"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.CASE_ID)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.caseId"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.CASE_ID)"></i>
                                  </th>
                                  <th><span>State</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.state"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.STATE)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.state"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.STATE)"></i>
                                  </th>
                                  <th><span>GSTIN</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.gstin"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.GSTIN)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.gstin"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.GSTIN)"></i>
                                  </th>
                                  <th><span>Case Label</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.caseLabel"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.CASE_LABEL)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.caseLabel"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.CASE_LABEL)"></i>
                                  </th>
                                  <!-- <th>Keyword</th> -->
                                  <th style="min-width: 103px;"><span>Period From</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodFromDate"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.PERIOD_FROM)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodFromDate"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.PERIOD_FROM)"></i>
                                  </th>
                                  <th style="min-width: 94px;"><span>Period To</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodToDate"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.PERIOD_TO)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodToDate"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.PERIOD_TO)"></i>
                                  </th>
                                  <th style="min-width: 99px;"><span>Demand (₹)</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.totOfTotal1"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.DEMAND)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.totOfTotal1"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.DEMAND)"></i>
                                  </th>
                                  <th style="min-width:117px;"><span>Current Status</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.status"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.STATUS)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.status"
                                      (click)="getLitigationSumm('apt',aptlitigationSort.STATUS)"></i>
                                  </th>
                                  <th style="min-width:128px;">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngFor="let obj of letterSumofAPT | paginate: tableSection4Config;; let i=index">
                                  <tr>
                                    <td class="tdElpsis" title="{{obj.caseId}}"><a
                                        (click)="getCaseData(obj.caseId, 'view', 'apt', 'tab4')">{{obj.caseId}}</a></td>
                                    <!-- <td  class="tdElpsis" title="{{getStateName(obj.state)}}">{{getStateName(obj.state)}}</td> -->
                                    <td class="tdElpsis" title="{{obj.state}}">{{obj.state | uppercase}}</td>
                                    <td title="{{obj.gstin}}">{{obj.gstin}}</td>
                                    <td title="{{obj.caseLabel}}">{{obj.caseLabel}}</td>
                                    <td title="{{obj.periodFromDate}}">{{obj.periodFromDate}}</td>
                                    <td title="{{obj.periodToDate}}">{{obj.periodToDate }}</td>
                                    <td class="txtRight">{{obj.totOfTotal1 | INR: 0}}</td>
                                    <!-- <td class="txtRight">{{(obj.igst1Total + obj.cgst1Total + obj.cess1Total + obj.sgst1Total + obj.interest1Total + obj.penalty1Total) | currency:'₹':'':'1.0'}}</td> -->
                                    <!-- <td class="tdElpsis">{{obj.caseSummary}}</td> -->
                                    <!-- <td class="tdElpsis" title="{{obj.aptkeywordName}}">
                                    {{obj.aptkeywordName}}
                                   </td> -->
                                    <!-- <td class="" title="{{obj.dueDateOfAppeal}}">{{obj.dueDateOfAppeal}}</td> -->
                                    <td>
                                      <label class="badge badge-warning tdElpsis" title="CALL BOOK"
                                        (click)="currentStatusFilter(obj.status,'apt')"
                                        *ngIf="obj.status == 'CALL BOOK'">{{obj.status}}</label>
                                      <label class="badge badge-success" (click)="currentStatusFilter(obj.status,'apt')"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning" (click)="currentStatusFilter(obj.status,'apt')"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'apt')" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'apt')" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'apt')" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'apt')" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                    </td>
                                    <!-- <td class="tdElpsis" title="{{obj.personResponsible}}">{{obj.personResponsible}}</td> -->
                                    <!-- <td class="tdElpsis" title="{{getFileNameDta(obj.prevOrderLoc)}}"><a (click)="download(obj.prevOrderLoc)">{{getFileNameDta(obj.prevOrderLoc)}}</a></td> -->



                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'apt',obj.caseId)"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                          class="badge badge-outline-trash " title="Delete"><i
                                            class="fa fa-trash"></i></span>
                                      </a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'view', 'apt', 'tab4')"><span
                                          class="badge badge-outline-success" title="View"><i
                                            class="fa fa-eye "></i></span>
                                      </a>
                                      <a (click)="navDownload(obj.caseId,'GST')"
                                      *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                      class="pr5"><span class="badge badge-outline-success " style="border-color: #e88e30;"
                                      title="Litigation Report"><i
                                        style="color: #e88e30;"  class="fa fa-file "></i></span>
                                    </a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'update', 'apt', 'tab4')"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                          class="badge badge-outline-primary" title="update"><i
                                            class="fa fa-pencil-square-o "></i></span>
                                    </a>
                                    
                                         <!-- <a><span class="badge badge-outline-danger" title="Delete"><i
                                          class="fa fa-trash-o "></i></span></a> -->
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                            <br />
                            <div class="row noMargin">
                              <div class="col-md-10 nopadding">
                                <pagination-controls (pageChange)="onTableDataChangeSection4($event)"
                                  id="tableSection4Config"></pagination-controls>
                              </div>
                              <div class="col-md-2 nopadding">
                                <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection4"
                                  (ngModelChange)="getSectio4Data(perPageLimitSection4,1,'select')" id="authority">
                                  <option value="10">10</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="1000">1000</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-5">
                      <h6 class="mb-0">
                        <a id="collapseToggle-5" class="collapsed" data-toggle="collapse" href="#collapse-5" aria-expanded="false"
                          aria-controls="collapse-5" (click)="toggleForumTab('hc'); isFilterApplied('hc')">
                          High Court <span class="AccCount">({{cntOpHC}}/{{cntHC}})</span>
                        </a>
                      </h6>
                    </div>
                    <!-- <div id="collapse-5" class="collapse" [ngClass]="{show: filterObj?.showAcc}" role="tabpanel" aria-labelledby="heading-5" data-parent="#accordion"> -->
                    <div id="collapse-5" class="collapse" role="tabpanel" aria-labelledby="heading-5"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <!-- <a class="iris-gst-pull-right "><span
                                class="badge badge-outline-success" title="Export to Excel"><i
                                  class="fa fa-file-excel-o "></i></span></a> -->
                            <a class="pr5 iris-gst-pull-right pt-2" (click)="createCase('create', 'hc', 'tab5')"
                              *ngIf="navContext?.entityType == 'FILING' && !['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "></i></span></a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:142px;"><span>Case ID</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.caseId"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.CASE_ID)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.caseId"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.CASE_ID)"></i>
                                  </th>
                                  <th><span>State</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.state"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.STATE)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.state"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.STATE)"></i>
                                  </th>
                                  <th><span>GSTIN</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.gstin"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.GSTIN)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.gstin"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.GSTIN)"></i>
                                  </th>
                                  <th><span>Case Label</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.caseLabel"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.CASE_LABEL)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.caseLabel"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.CASE_LABEL)"></i>
                                  </th>
                                  <!-- <th>Keyword</th> -->
                                  <th style="min-width: 103x;"><span>Period From</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodFromDate"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.PERIOD_FROM)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodFromDate"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.PERIOD_FROM)"></i>
                                  </th>
                                  <th style="min-width: 94px;"><span>Period To</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodToDate"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.PERIOD_TO)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodToDate"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.PERIOD_TO)"></i>
                                  </th>
                                  <th style="min-width: 99px;"><span>Demand (₹)</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.totOfTotal1" (click)="getLitigationSumm('hc',hcLitigationSort.DEMAND)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.totOfTotal1" (click)="getLitigationSumm('hc',hcLitigationSort.DEMAND)"></i>
                                  </th>
                                  <th style="min-width:117px;"><span>Current Status</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.status"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.STATUS)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.status"
                                      (click)="getLitigationSumm('hc',hcLitigationSort.STATUS)"></i>
                                  </th>
                                  <th style="min-width:128px;">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngFor="let obj of letterSumofHC | paginate: tableSection5Config; let i=index">
                                  <tr>
                                    <td class="tdElpsis" title="{{obj.caseId}}"><a
                                        (click)="getCaseData(obj.caseId, 'view', 'hc', 'tab5')">{{obj.caseId}}</a></td>
                                    <!-- <td  class="tdElpsis" title="{{getStateName(obj.state)}}">{{getStateName(obj.state)}}</td> -->
                                    <td class="tdElpsis" title="{{obj.state}}">{{obj.state | uppercase}}</td>
                                    <td title="{{obj.gstin}}">{{obj.gstin}}</td>
                                    <td title="{{obj.caseLabel}}">{{obj.caseLabel}}</td>
                                    <td title="{{obj.periodFromDate}}">{{obj.periodFromDate}}</td>
                                    <td title="{{obj.periodToDate}}">{{obj.periodToDate }}</td>
                                    <td class="txtRight">{{obj.totOfTotal1 | INR: 0}}</td>
                                    <!-- <td class="txtRight">{{(obj.igst1Total + obj.cgst1Total + obj.cess1Total + obj.sgst1Total + obj.interest1Total + obj.penalty1Total) | currency:'₹':'':'1.0'}}</td> -->
                                    <!-- <td class="tdElpsis">{{obj.caseSummary}}</td> -->
                                    <!-- <td class="tdElpsis" title="{{obj.hckeywordName}}">
                                    {{obj.hckeywordName}}
                                   </td> -->
                                    <!-- <td class="" title="{{obj.dueDateToAppeal}}">{{obj.dueDateToAppeal}}</td> -->
                                    <td>
                                      <label class="badge badge-success" (click)="currentStatusFilter(obj.status,'hc')"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning" (click)="currentStatusFilter(obj.status,'hc')"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'hc')" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'hc')" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'hc')" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'hc')" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'hc')" title="PETITION YET TO BE FILED"
                                        *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="CALL BOOK"
                                        (click)="currentStatusFilter(obj.status,'hc')"
                                        *ngIf="obj.status == 'CALL BOOK'">{{obj.status}}</label>
                                    </td>
                                    <!-- <td class="tdElpsis" title="{{obj.personResponsible}}">{{obj.personResponsible}}</td> -->

                                    <!-- <td class="tdElpsis" title="{{getFileNameDta(obj.prevOrderLoc)}}"><a (click)="download(obj.prevOrderLoc)">{{getFileNameDta(obj.prevOrderLoc)}}</a></td> -->
                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'hc',obj.caseId)"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                          class="badge badge-outline-trash " title="Delete"><i
                                            class="fa fa-trash"></i></span>
                                      </a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'view', 'hc', 'tab5')"><span
                                          class="badge badge-outline-success" title="View"><i
                                            class="fa fa-eye "></i></span>
                                      </a>
                                      <a (click)="navDownload(obj.caseId,'GST')"
                                      *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                      class="pr5"><span class="badge badge-outline-success " style="border-color: #e88e30;"
                                      title="Litigation Report"><i
                                        style="color: #e88e30;"  class="fa fa-file "></i></span>
                                    </a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'update', 'hc', 'tab5')"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                          class="badge badge-outline-primary" title="Edit"><i
                                            class="fa fa-pencil-square-o "></i></span>
                                      </a>
                                      
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                            <br />
                            <div class="row noMargin">
                              <div class="col-md-10 nopadding">
                                <pagination-controls (pageChange)="onTableDataChangeSection5($event)"
                                  id="tableSection5Config"></pagination-controls>
                              </div>
                              <div class="col-md-2 nopadding">
                                <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection5"
                                  (ngModelChange)="getSectio5Data(perPageLimitSection5,1,'select')" id="authority">
                                  <option value="10">10</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="1000">1000</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="card border-bottom">
                    <div class="card-header nopadding" role="tab" id="heading-6">
                      <h6 class="mb-0">
                        <a id="collapseToggle-6" class="collapsed" data-toggle="collapse" href="#collapse-6" aria-expanded="false"
                          aria-controls="collapse-6" (click)="toggleForumTab('sc');isFilterApplied('sc')">
                          Supreme Court <span class="AccCount">({{cntOpSC}}/{{cntSC}})</span>
                        </a>
                      </h6>
                    </div>
                    <!-- <div id="collapse-6" class="collapse" [ngClass]="{show: filterObj?.showAcc}" role="tabpanel" aria-labelledby="heading-6" data-parent="#accordion"> -->
                    <div id="collapse-6" class="collapse" role="tabpanel" aria-labelledby="heading-6"
                      data-parent="#accordion">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-md-12 nopadding mb10">
                            <!-- <a class="iris-gst-pull-right "><span
                                class="badge badge-outline-success" title="Export to Excel"><i
                                  class="fa fa-file-excel-o "></i></span></a> -->
                            <a class="pr5 iris-gst-pull-right pt-2" (click)="createCase('create', 'sc', 'tab6')"
                              *ngIf="navContext?.entityType == 'FILING' && !['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                class="badge badge-outline-success" title="Create Case File"><i
                                  class="fa fa-pencil-square "></i></span></a>
                          </div>
                          <div class="table-responsive nopadding">
                            <table class="table table-bordered table-hover">
                              <thead>
                                <tr>
                                  <th style="width:142px;"><span>Case ID</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.caseId"
                                      (click)="getLitigationSumm('sc',scLitigationSort.CASE_ID)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.caseId"
                                      (click)="getLitigationSumm('sc',scLitigationSort.CASE_ID)"></i>
                                  </th>
                                  <th><span>State</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.state"
                                      (click)="getLitigationSumm('sc',scLitigationSort.STATE)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.state"
                                      (click)="getLitigationSumm('sc',scLitigationSort.STATE)"></i>
                                  </th>
                                  <th><span>GSTIN</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.gstin"
                                      (click)="getLitigationSumm('sc',scLitigationSort.GSTIN)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.gstin"
                                      (click)="getLitigationSumm('sc',scLitigationSort.GSTIN)"></i>
                                  </th>
                                  <th><span>Case Label</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.caseLabel"
                                      (click)="getLitigationSumm('sc',scLitigationSort.CASE_LABEL)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.caseLabel"
                                      (click)="getLitigationSumm('sc',scLitigationSort.CASE_LABEL)"></i>
                                  </th>
                                  <!-- <th>Keyword</th> -->
                                  <th style="min-width: 103px;"><span>Period From</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodFromDate"
                                      (click)="getLitigationSumm('sc',scLitigationSort.PERIOD_FROM)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodFromDate"
                                      (click)="getLitigationSumm('sc',scLitigationSort.PERIOD_FROM)"></i>
                                  </th>
                                  <th style="min-width: 94px;"><span>Period To</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.periodToDate"
                                      (click)="getLitigationSumm('sc',scLitigationSort.PERIOD_TO)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.periodToDate"
                                      (click)="getLitigationSumm('sc',scLitigationSort.PERIOD_TO)"></i>
                                  </th>
                                  <th style="min-width: 99px;"><span>Demand (₹)</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true"
                                      *ngIf="!sorting.totOfTotal1"
                                      (click)="getLitigationSumm('sc',scLitigationSort.DEMAND)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true"
                                      *ngIf="sorting.totOfTotal1"
                                      (click)="getLitigationSumm('sc',scLitigationSort.DEMAND)"></i>
                                  </th>
                                  <th style="min-width:117px;"><span>Current Status</span>
                                    <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.status"
                                      (click)="getLitigationSumm('sc',scLitigationSort.STATUS)"></i>
                                    <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.status"
                                      (click)="getLitigationSumm('sc',scLitigationSort.STATUS)"></i>
                                  </th>
                                  <th style="min-width:128px;">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container
                                  *ngFor="let obj of letterSumofSC  | paginate: tableSection6Config;; let i=index">
                                  <tr>
                                    <td class="tdElpsis" title="{{obj.caseId}}">
                                      <a (click)="getCaseData(obj.caseId, 'view', 'sc', 'tab6' )">{{obj.caseId}}</a></td>
                                    <td class="tdElpsis" title="{{obj.state}}">{{obj.state | uppercase}}</td>
                                    <td title="{{obj.gstin}}">{{obj.gstin}}</td>
                                    <td title="{{obj.caseLabel}}">{{obj.caseLabel}}</td>
                                    <td title="{{obj.periodFromDate}}">{{obj.periodFromDate}}</td>
                                    <td title="{{obj.periodToDate}}">{{obj.periodToDate }}</td>
                                    <td class="txtRight">{{obj.totOfTotal1 | INR: 0}}</td>
                                    <td>
                                      <label class="badge badge-success" (click)="currentStatusFilter(obj.status,'sc')"
                                        *ngIf="obj.status == 'CLOSED'">{{obj.status}}</label>
                                      <label class="badge badge-warning" (click)="currentStatusFilter(obj.status,'sc')"
                                        *ngIf="obj.status == 'OPEN'">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'sc')" title="PENDING FOR REPLY"
                                        *ngIf="obj.status == 'PENDING FOR REPLY'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'sc')" title="PENDING FOR HEARING"
                                        *ngIf="obj.status == 'PENDING FOR HEARING'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'sc')" title="PENDING FOR ORDER"
                                        *ngIf="obj.status == 'PENDING FOR ORDER'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'sc')" title="APPEAL YET TO BE FILED"
                                        *ngIf="obj.status == 'APPEAL YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis"
                                        (click)="currentStatusFilter(obj.status,'sc')" title="PETITION YET TO BE FILED"
                                        *ngIf="obj.status == 'PETITION YET TO BE FILED'"
                                        style="min-width: 125px; padding: 3px;">{{obj.status}}</label>
                                      <label class="badge badge-warning tdElpsis" title="CALL BOOK"
                                        (click)="currentStatusFilter(obj.status,'sc')"
                                        *ngIf="obj.status == 'CALL BOOK'">{{obj.status}}</label>
                                    </td>
                                    <td>
                                      <a class="pr5" (click)="openDetailPop(deletePop, 'sc',obj.caseId)"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','Preparer','GST_Preparer','PREGST_Preparer','IDT_Preparer'].includes(roleName)"><span
                                          class="badge badge-outline-trash " title="Delete"><i
                                            class="fa fa-trash"></i></span>
                                      </a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'view', 'sc', 'tab6')"><span
                                          class="badge badge-outline-success" title="View"><i
                                            class="fa fa-eye "></i></span>
                                      </a>
                                      <a (click)="navDownload(obj.caseId,'GST')"
                                      *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"
                                      class="pr5"><span class="badge badge-outline-success " style="border-color: #e88e30;"
                                      title="Litigation Report"><i
                                        style="color: #e88e30;"  class="fa fa-file "></i></span>
                                    </a>
                                      <a class="pr5" (click)="getCaseData(obj.caseId, 'update', 'sc', 'tab6')"
                                        *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer'].includes(roleName)"><span
                                          class="badge badge-outline-primary" title="Edit"><i
                                            class="fa fa-pencil-square-o "></i></span></a>
                                          
                                      <!-- <a><span class="badge badge-outline-danger" title="Delete"><i
                                          class="fa fa-trash-o "></i></span></a> -->
                                    </td>
                                  </tr>
                                </ng-container>
                              </tbody>
                            </table>
                            <br />
                            <div class="row noMargin">
                              <div class="col-md-10">
                                <pagination-controls (pageChange)="onTableDataChangeSection6($event)"
                                  id="tableSection6Config"></pagination-controls>
                              </div>
                              <div class="col-md-2">
                                <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection6"
                                  (ngModelChange)="getSectio6Data(perPageLimitSection6,1,'select')" id="authority">
                                  <option value="10">10</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="1000">1000</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
