import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { asessmentYears } from 'src/app/helpers/date.utils';
import { DirectTaxService } from 'src/app/services/direct-tax.service';
import { PieChartData } from 'src/app/shared/charts/pie-chart/pie-chart.component';
import { AgingIssues, IssueDemands, StageDemands } from 'src/app/enums/direct-tax.enum';
import { DirectTaxStage, dtForumTabs, dtForumType } from 'src/app/enums/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash-es';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { MasterService } from 'src/app/services/master.service';

interface Filters {
  p: 'ay' | 'pan';
  o: 'eq' | 'nin';
  v: string | number | null;
}

@Component({
  selector: 'app-dt-analytics',
  templateUrl: './dt-analytics.component.html',
  styleUrls: ['./dt-analytics.component.css']
})
export class DtAnalyticsComponent implements OnInit {
  @ViewChild('casesModal', { static: false }) casesModal!: TemplateRef<void>;
  @ViewChild('inceptionModal', { static: false }) inceptionModal!: TemplateRef<void>;
  @ViewChild('forumModal', { static: false }) forumModal!: TemplateRef<void>;
  @ViewChild('issueWiseModal', { static: false }) issueWiseModal!: TemplateRef<void>;

  activeType: 'panwise' | 'inception' | 'forum' | 'issuewise' = 'panwise';
  modalTitle: string = 'Case Details';
  modalData: any = 'Some Data';
  modalSort: 'desc' | 'asc' = 'desc';
  sortKey: string | null = null;

  sortedColClass = (key: string) =>
    this.sortKey === key ? `col-sorted-${this.modalSort}` : '';

  panList: { name: string; pan: string }[] = [];

  assessYears = asessmentYears();
  directTaxStages: DirectTaxStage[] = Object.values(DirectTaxStage);
  dtForumTypes: any = dtForumType;
  dtForumTabs: any = dtForumTabs;

  amountMetric: 'lakh' | 'crore' = 'lakh';
  filters: { [key: string]: Filters } = {
    ay: { p: 'ay', o: 'eq', v: null },
    pan: { p: 'pan', o: 'eq', v: null }
  };

  panwiseData: StageDemands[] = [];
  panwiseCaseMap: any;

  issuewiseData: IssueDemands[] = [];
  issuewiseCaseMap: any;
  issueBarChart: PieChartData[] = [];

  agingInception: AgingIssues[] = [];
  inceptionCaseMap: any;
  inceptionPieChart: PieChartData[] = [];

  agingForum: AgingIssues[] = [];
  forumCaseMap: any;
  forumPieChart: PieChartData[] = [];

  constructor(
    private router: Router,
    private ngbModal: NgbModal,
    private excelService: ExcelService,
    private masterService: MasterService,
    private directTaxService: DirectTaxService
  ) { }

  ngOnInit(): void {
    this.getPanList();
    this.loadAnalytics();
  }

  loadAnalytics() {
    this.getPanWiseDemands();
    this.getIssueWiseDemands();
    this.getAgingInception();
    this.getAgingForum();
  }

  setMetric(event: Event) {
    const isCrore = (event.target as HTMLInputElement).checked;
    this.amountMetric = isCrore ? 'crore' : 'lakh';
    this.updateCharts();
  }

  updateCharts() {
    this.issueBarChart = [];
    setTimeout(() => {
      this.issueBarChart = this.issuewiseData.map((el) => ({
        label: el.issue,
        value: this.amountMetric === 'lakh' ? el.amtInLakhs : el.amtInCr
      }));
    }, 1000);
  }

  getPanList() {
    this.masterService.getPANList().subscribe((res) => {
      this.panList = res;
    });
  }

  getFilters() {
    return Object.values(this.filters).filter((el) => !!el.v);
  }

  getPanWiseDemands() {
    this.directTaxService.getPanWiseAnalysis(this.getFilters()).subscribe((res) => {
      this.panwiseData = res.data;
      this.panwiseCaseMap = res.casesMap;
    });
  }

  getIssueWiseDemands() {
    this.directTaxService.getIssueWiseAnalysis(this.getFilters()).subscribe((res) => {
      this.issuewiseData = res.data;
      this.issuewiseCaseMap = res.casesMap;
      this.updateCharts();
    });
  }

  getAgingInception() {
    this.directTaxService.getAgingInception(this.getFilters()).subscribe((res) => {
      this.agingInception = res.data;
      this.inceptionCaseMap = res.casesMap;
      this.inceptionPieChart = [];
      setTimeout(() => {
        this.inceptionPieChart = this.agingInception.map((el) => {
          return { label: el.title, value: el.count };
        });
      }, 1000);
    });
  }

  getAgingForum() {
    this.directTaxService.getAgingForum(this.getFilters()).subscribe((res) => {
      this.agingForum = res.data;
      this.forumCaseMap = res.casesMap;
      this.forumPieChart = [];
      setTimeout(() => {
        this.forumPieChart = this.agingForum.map((el) => {
          return { label: el.title, value: el.count };
        });
      }, 1000);
    });
  }

  toggleCollapse(index: number, isParent: boolean) {
    if (!isParent) return;
    this.panwiseData[index].collapse = !this.panwiseData[index].collapse;
  }

  openCasesModal(
    key: string,
    type: 'panwise' | 'issuewise' | 'inception' | 'forum',
    forum?: string
  ): void {
    if (!key) return;

    this.sortKey = null;
    this.activeType = type;

    switch (type) {
      case 'panwise':
        this.modalTitle = 'Panwise Demands: ' + key.replace('-', ': ');
        if (forum)
          this.modalData = _.filter(this.panwiseCaseMap[key], (el) => el.forum === forum);
        else this.modalData = this.panwiseCaseMap[key];
        break;
      case 'issuewise':
        this.modalTitle = `Issue Demands: ${key}`;
        this.modalData = this.issuewiseCaseMap[key];
        break;
      case 'inception':
        this.modalTitle = `Inception Ageing: ${key}`;
        this.modalData = this.inceptionCaseMap[key];
        break;
      case 'forum':
        this.modalTitle = `Current Forum Ageing: ${key}`;
        this.modalData = this.forumCaseMap[key];
        break;
    }

    if (!this.modalData.length) return;

    this.ngbModal.open(this.casesModal, { windowClass: 'fullscreen-modal' });
  }

  openCaseUpdate(caseId: string, key: string): void {
    this.ngbModal.dismissAll();
    this.router.navigate([
      '/createDirectTaxCase',
      caseId,
      { action: 'update', type: this.dtForumTypes[key], tab: this.dtForumTabs[key] }
    ]);
  }

  sortCases(key: string) {
    this.sortKey = key;
    this.modalSort = this.modalSort === 'asc' ? 'desc' : 'asc';
    this.modalData = _.orderBy(this.modalData, (el) => el[key], this.modalSort);
  }

  exportExcel() {
    if (!this.panwiseData) return;

    // keys that are not part of excel
    const omitKeys = Object.keys(this.panwiseData[0]).filter(
      (key) => key.includes('InCr') || key.includes('InLakhs')
    );
    omitKeys.push(
      ...['collapse', 'key', 'pan', 'ayDtls', 'ay', 'caseDtls', 'enqProcAmt']
    );

    // restructuring data and removing above keys
    const data: any = [];
    data.push({});
    this.panwiseData.forEach((pan) => {
      data.push(_.omit(pan, omitKeys));
      pan.ayDtls?.forEach((ay) => {
        data.push(_.omit(ay, omitKeys));
      });
    });

    // preparing excel sheet header rows

    const stages = _.flatMap(
      this.directTaxStages
        .filter((e) => e !== 'Enquiry Proceedings')
        .map((el) => [el, ''])
    );
    const colNames = _.flatMap([...Array(7)].map((el) => ['Open Cases', 'Demands (₹)']));
    const headers = [
      ['', 'Enquiry Proceedings', ...stages, 'Total'],
      ['PAN - AY', 'Open Cases', ...colNames]
    ];

    // setting keys sequence as per columns
    const sequence = ['label', 'enqProcCount', 'intimationCount'];
    sequence.push(...['intimationAmt', 'assessmentCount', 'assessmentAmt']);
    sequence.push(...['commissionerCount', 'commissionerAmt', 'itatCount', 'itatAmt']);
    sequence.push(...['hcCount', 'hcAmt', 'scCount', 'scAmt', 'totalCount', 'totalAmt']);

    // setting cell merging
    const merges = ['C1:D1', 'E1:F1', 'G1:H1', 'I1:J1'];
    merges.push(...['K1:L1', 'M1:N1', 'O1:P1']);

    // download excel
    this.excelService.toExcel(data, sequence, headers, 'PanwiseDemandsAnalysis', merges);
  }
}
