import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, delayWhen, map, retryWhen, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject, throwError, timer } from 'rxjs';
import { data } from 'jquery';
import { TAB_TYPE } from 'src/app/shared/constant';
import { ToasterService } from 'src/app/shared/services/toaster.service';

@Injectable({
  providedIn: 'root'
})
export class LitigationServiceService {
  getAllPreGstCount(data: { page: number; size: number; sortfield: string; sortdir: string; companyId: any; }) {
    throw new Error('Method not implemented.');
  }
  tabType!: { key: string; value: string; }[];
  selectedField: any;
  selectedFile: any;
  matches: any;
  uploadFileData = new BehaviorSubject<string>("");

  AccessTabData$ = new BehaviorSubject<any>({ scnFlag: false, ajaFlag: false, apaFlag: false, aptFlag: false, hcFlag: false, scFlag: false });
  constructor(
    private http: HttpClient, private toaster: ToasterService
  ) {
    this.tabType = TAB_TYPE;

  }

  getUploadFileData(): Observable<string> {
    return this.uploadFileData.asObservable();
  }
  setUploadFileData(value: string) {
    this.uploadFileData.next(value);

  }
  httpOptions = {
    withCredentials: true
  };

  private selectedFilling: Subject<any> = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('selectedFilling') || '{}'));
  selectedFilling$ = this.selectedFilling.asObservable();
  modifyFilling(data: any) {
    this.selectedFilling.next(data);
  }

  /*Letter */
  /*create case*/
  saveLetterData(data: any, urlData: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.post(`${environment.apiUrl}/lms/case-create?type=${urlData.typeVal}&gstin=${urlData.gstin}&companyId=${companyId}`, data).pipe(map((response) => {
      return response;
    }));
  }
  saveNoticeLetterData(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/create/scn`, data);
  }
  updateNoticeLetter(data: any, urlData: any) {
    return this.http.put(`${environment.baseUrl}/gst/update/scn`, data);
  }
  saveCommissionerData(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/create/apa`, data);
  }
  updateCommissionerData(data: any) {
    return this.http.put(`${environment.baseUrl}/gst/update/apa`, data);
  }
  saveAppellateTribData(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/create/apt`, data);
  }
  updateAppellateTribDate(data: any) {
    return this.http.put(`${environment.baseUrl}/gst/update/apt`, data);
  }
  savehighcourtData(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/create/hc`, data);
  }
  updateHighCourtData(data: any) {
    return this.http.put(`${environment.baseUrl}/gst/update/hc`, data);
  }
  saveSupremeCourtData(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/create/sc`, data);
  }
  updateSupremeCourtData(data: any) {
    return this.http.put(`${environment.baseUrl}/gst/update/sc`, data);
  }
  /*case summary data*/
  getAllCaseSummary(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/scn`, data);
  }
  getAllCaseSummaryAja(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/aja`, data);
  }
  getAllCaseSummaryCommissionar(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/apa`, data);
  }
  getAllCaseSummaryApt(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/apt`, data);
  }
  getAllCaseSummaryHc(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/hc`, data);
  }
  getAllCaseSummarySc(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/sc`, data);
  }
  getAllCount(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/count/total`, data);
  }
  getAllCaseCount(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/count`, data);
  }
  getAllGstDashboardCount(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/query/amounts`, data);
  }
  getAllDashPreGstCount(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/query/count/total`, data);
  }
  getAllPreGstDashboardCount(data: any) {
    return this.http.post(`${environment.baseUrl}/pregst/query/amounts`, data);
  }
  getAllAuditDashboardCount(data: any) {
    return this.http.post(`${environment.baseUrl}/audit/count`, data);
  }

  getCaseNoticeData(data: any) {
    return this.http.get(`${environment.baseUrl}/gst/get/scn?caseId=${data.caseId}`);
  }
  getAdjudicationData(data: any) {
    return this.http.get(`${environment.baseUrl}/gst/get/aja?caseId=${data.caseId}`);

  }
  getCommissionerCaseData(data: any) {
    return this.http.get(`${environment.baseUrl}/gst/get/apa?caseId=${data.caseId}`);

  }
  getTribunalData(data: any) {
    return this.http.get(`${environment.baseUrl}/gst/get/apt?caseId=${data.caseId}`);
  }
  getHcData(data: any) {
    return this.http.get(`${environment.baseUrl}/gst/get/hc?caseId=${data.caseId}`);
  }
  getScData(data: any) {
    return this.http.get(`${environment.baseUrl}/gst/get/sc?caseId=${data.caseId}`);
  }
  createAdjudicationCase(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.post(`${environment.baseUrl}/gst/create/aja`, data);
  }
  updateAdjudicationCase(data: any) {
    return this.http.put(`${environment.baseUrl}/gst/update/aja`, data);
  }
  /*upload file*/
  commonUpload(data: any, urlData: any) {
    return this.http.get(`${environment.apiUrl}/lms2/common/upload?gstinOrPan=${urlData.gstinOrPan}&field=${urlData.field}&fname=${data.name}&type=${urlData.type}`);
  }
  uploadFile(requestBody: any, a?: any) {
    return this.http.post(`${environment.apiUrl}/lms1/file/sfsdfsdfdgfete/upload`, requestBody).pipe(map((resp) => {
      return resp;
    }));
  }

  getUploadUrl(arrayBuffer: ArrayBuffer, data: any) {
    //getUploadUrl(formdata: FormData, data: any): Observable<any> {
    const headers = {
      // 'Content-Type': data.fmime,
      // 'fmime': data.fmime,

      //  const arrayBuffer: fname.result as ArrayBuffer,

      'fname': data.fname
    };
    let url = data.url;
    return this.http.put<any>(url, arrayBuffer, { headers }).pipe(map((resp) => {
      return resp;
    }));
  }

  getUploadUrl1(data: any) {
    const headers = {
      'fname': data.fname
    };
    let url = data.url;
    const upload = this.http.put<any>(url.presinedUrl, { headers }).toPromise();
    upload.then(data => {
    })
  }

  /*upload file*/
  // getProcessId(data: any) {
  //   return this.http.get(`${environment.apiUrl}/lms2/common/upload?field=${'smart_upload'}&fname=${data.name}`).pipe(map((resp) => {
  //     return resp;
  //   }));
  // }
  getProcessId(data: any) {
    return this.http.post(`${environment.apiUrl}/lms3/upload`,data).pipe(map((resp) => {
      return resp;
    }));
  }

  getNoticeProcessId(data: any) {
    let phase = data.phase;
    let path = data.filePathData
    return this.http.post(`${environment.apiUrl}/lms2/ocr/consolidated/pdf?phase=${phase}`, path).pipe(map((resp) => {
      return resp;
    }));
  }
  pollStatusUntilComplete(processId: string,): Observable<string> {
    return timer(0, 51000).pipe(
      switchMap(() => this.checkStatus(processId)),
      retryWhen(errors => errors.pipe(delayWhen(() => timer(5000)))),
      catchError((error: any) => {
        return throwError('Error polling status');
      })
    )
  }

  checkNoticeStatus(taskId: string,phase:any): Observable<any> {
    return this.http.get(`${environment.apiUrl}/lms2/ocr/upload/status?taskId=${taskId}&phase=${phase}`).pipe(map((resp) => {
      return resp;
    }));
  }

  saveLetter(data: any, urlData: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.post(`${environment.apiUrl}/lms/case-create?type=${urlData.typeVal}&companyId=${companyId}`, data).pipe(map((response) => {
      return response;
    }));
  }

  checkStatus(processId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/lms3/get-status?id=${processId}`).pipe(map((resp) => {
      return resp;
    }));
  }

  getFileName(fileUrl: any) {
    if (fileUrl) {
      let index = fileUrl.lastIndexOf('/');
      return fileUrl.substring(index + 1);
    }
  }
  downloadFile(urlData: any) {
    let options = {
      headers: new HttpHeaders({
        //'companyId': '7676'
      }),

    };
    return this.http.get(`${environment.apiUrl}/lms2/common/download?fname=${urlData.fname}`, options).pipe(map((resp) => {
      return resp;
    }));
  }

  setAccessTabData(data: any) {
    this.AccessTabData$.next(data);
  }
  getAccessTabData() {
    return this.AccessTabData$.asObservable();
  }


  getUserResList(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    if (data.gstin) {
      return this.http.get(`${environment.apiUrl}/mgmt/user/userRoleInfo?gstin=${data.gstin}`).pipe(map((resp) => {
        return resp;
      }));
    } else {
      return this.http.get(`${environment.apiUrl}/mgmt/user/userRoleInfo?companyId=${companyId}`).pipe(map((resp) => {
        return resp;
      }));

    }

  }



  getRWAnalyticData(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
      return this.http.post(`${environment.baseUrl}/gst/analytics?`, data).pipe(map((resp) => {
        return resp;
      }));
  }

  issueExportToExcel(data:any){
    return this.http.post(`${environment.baseUrl}/gst/analytics/export`, data, { responseType: 'blob' });
  }

  //analytic page
  //Region Wise Analytics
  // getRWAnalyticData(data: any) {
  //   let companyId = +(localStorage.getItem('selectedId') || '{}');
  //   let options = {
  //     headers: new HttpHeaders({
  //     }),
  //   };
  //   if ((data.pan != null && data.pan != '' && data.pan != undefined) && (data.state != null && data.state != '' && data.state != undefined) && (data.division != null && data.division != '' && data.division != undefined)) {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics?`, { companyId: companyId, state: data.state, division: data.division, pan: data.pan, }).pipe(map((resp) => {
  //       return resp;
  //     }));
  //   }
  //   else if (data.state == null && (data.division != null && data.division != '' && data.division != undefined)) {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics?`, { companyId: companyId, state: data.state, division: data.division, }).pipe(map((resp) => {
  //       return resp;
  //     }));
  //   }
  //   else if (data.division == null && (data.state != null && data.state != '' && data.state != undefined)) {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics?`, { companyId: companyId, state: data.state, division: data.division, pan: data.pan }).pipe(map((resp) => {
  //       return resp;
  //     }));
  //   }
  //   else if ((data.state != null && data.state != '' && data.state != undefined) && (data.division != null && data.division != '' && data.division != undefined)) {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics?`, { companyId: companyId, state: data.state, division: data.division, }).pipe(map((resp) => {
  //       return resp;
  //     }));
  //   }
  //   else if (data.pan != null && data.pan != '' && data.pan != undefined) {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics?`, { companyId: companyId, pan: data.pan }).pipe(map((resp) => {

  //       return resp;
  //     }));
  //   }
  //   else {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics?`, { companyId: companyId }).pipe(map((resp) => {

  //       return resp;
  //     }));
  //   }
  // }
  //Region Wise Analytics
  //aging inception data
  getagingInceptionData(data: any) {
    return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  agingInDivData(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, { companyId: companyId, division: data.division, pan: data.pan }).pipe(map((resp) => {
      return resp;
    }));
  }
  agingInStateDivData(data: any) {
      return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, data).pipe(map((resp) => {
        return resp;
      }));
  }
  // agingInStateDivData(data: any) {
  //   let companyId = +(localStorage.getItem('selectedId') || '{}');
  //   let options = {
  //     headers: new HttpHeaders({
  //     }),
  //   };
  //   if ((data.pan != null && data.pan != '' && data.pan != undefined) && (data.state != null && data.state != '' && data.state != undefined) && (data.division != null && data.division != '' && data.division != undefined)) {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, { companyId: companyId, state: data.state, division: data.division, pan: data.pan, }).pipe(map((resp) => {
  //       return resp;
  //     }));
  //   }
  //   else if (data.state == null && (data.division != null && data.division != '' && data.division != undefined)) {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, { companyId: companyId, state: data.state, division: data.division, }).pipe(map((resp) => {
  //       return resp;
  //     }));
  //   }
  //   else if (data.division == null && (data.state != null && data.state != '' && data.state != undefined)) {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, { companyId: companyId, state: data.state, division: data.division, }).pipe(map((resp) => {
  //       return resp;
  //     }));
  //   }
  //   else if ((data.state != null && data.state != '' && data.state != undefined) && (data.division != null && data.division != '' && data.division != undefined)) {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, { companyId: companyId, state: data.state, division: data.division, }).pipe(map((resp) => {
  //       return resp;
  //     }));
  //   }
  //   else if (data.pan != null && data.pan != '' && data.pan != undefined) {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, { companyId: companyId, pan: data.pan }).pipe(map((resp) => {

  //       return resp;
  //     }));
  //   }
  //   else {
  //     return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, { companyId: companyId }).pipe(map((resp) => {

  //       return resp;
  //     }));
  //   }




  //   //     if ((data.pan != null && data.pan != '' && data.pan != undefined) && (data.state != null && data.state != '' && data.state != undefined) && (data.division != null && data.division != '' && data.division != undefined)) {
  //   //       return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`,{companyId: companyId, state:data.state,division:data.division,pan:data.pan}).pipe(map((resp) => {
  //   // return resp;
  //   //       }));
  //   //     }
  //   //     else if ((data.state != null && data.state != '' && data.state != undefined) && (data.division != null && data.division != '' && data.division != undefined)) {
  //   //       return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`,{companyId: companyId, state:data.state,division:data.division,}).pipe(map((resp) => {
  //   //  return resp;
  //   //       }));
  //   //     }
  //   //     else if (data.pan != null && data.pan != '' && data.pan != undefined) {
  //   //       return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`,{companyId: companyId, pan:data.pan}).pipe(map((resp) => {
  //   // return resp;
  //   //       }));
  //   //     }else if (data.division == null && (data.state != null && data.state != '' && data.state != undefined)) {
  //   //       return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`,{companyId: companyId, state:data.state, division:data.division,}).pipe(map((resp) => {
  //   //        return resp;
  //   //       }));
  //   //     }
  //   //     else if ((data.state != null && data.state != '' && data.state != undefined) && (data.division != null && data.division != '' && data.division != undefined)) {
  //   //       return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`,{companyId: companyId, state:data.state, division:data.division,}).pipe(map((resp) => {
  //   //        return resp;
  //   //       }));
  //   //     }
  //   //     else {
  //   //       return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`,{companyId: companyId}).pipe(map((resp) => {
  //   //  return resp;
  //   //       }));
  //   //     }
  // }
  //     return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, data).pipe(map((resp) => {

  //       return resp;
  //     }));
    

  // }


  stateAnalyticData(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(`${environment.baseUrl}/gst/analytics?`, { companyId: companyId, state: data.state, pan: data.pan }).pipe(map((resp) => {

      return resp;
    }));
  }
  divAnalyticData(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(`${environment.baseUrl}/gst/analytics?`, { companyId: companyId, division: data.division, pan: data.pan }).pipe(map((resp) => {
      return resp;
    }));
  }
  clearAnalyticData() {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(`${environment.baseUrl}/gst/analytics?`, { companyId: companyId }).pipe(map((resp) => {
      return resp;
    }));
  }
  clearAgingIncepData() {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(`${environment.baseUrl}/gst/analytics-ageing?`, { companyId: companyId }).pipe(map((resp) => {
      return resp;
    }));
  }

  getOpenCase(data: any) {
      return this.http.post(`${environment.baseUrl}/gst/analytics-drill`, data).pipe(map((resp) => {
        return resp;
      }));
  }

  //aging inception data
  //download reports
  // getOpenCase1(data: any) {
  //   let companyId = +(localStorage.getItem('selectedId') || '{}');
  //   let options = {
  //     headers: new HttpHeaders({
  //     }),
  //   };
  //   return this.http.get(data.gstin ? `${environment.apiUrl}/gst/analytics-drill?companyId=${companyId}&stage=${data.stage}&gstin=${data.gstin}&pan=${data.pan}`
  //     : `${environment.baseUrl}/gst/analytics-drill?companyId=${companyId}&stage=${data.stage}&division=${data.division}&pan=${data.pan}`
  //     ,).pipe(map((resp) => {
  //       return resp;
  //     }));
  // }



  getOpenCaseState(data: any) {
    // let companyId = +(localStorage.getItem('selectedId') || '{}');
    // let options = {
    //   headers: new HttpHeaders({
    //   }),
    // };
    return this.http.post(`${environment.baseUrl}/gst/analytics-drill`, data).pipe(map((resp) => {
      return resp;
    }));
  }
  //download reports

  generateReport(data: any, reportType: string) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.post(`${environment.baseUrl}/reports/generate`, { pan: data.pan, gstin: data.gstin, companyId: companyId, reportType: reportType, ...data });
  }

  generateReportDashboard(data: any, reportType: string) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.post(`${environment.baseUrl}/reports/generate`, { pan: data.pan, gstin: data.gstin, companyId: companyId, reportType: reportType, ...data });
  }


  generateContingencyReport(data: any, reportType: string) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.post(`${environment.baseUrl}/reports/generate`, { pan: data.pan, gstin: data.gstin, companyId: companyId, reportType: reportType, ...data });
  }

  generateMasterDataReport(reportType: string) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.post(`${environment.baseUrl}/reports/generate`, { companyId: companyId, reportType: reportType });

  }



  statusMasterReport() {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    return this.http.get(`${environment.baseUrl}/reports/status`);
  }

  downLoadReport() {
    return this.http.get(`${environment.baseUrl}/reports/download`, { responseType: 'blob' });
  }

  downloadCReport() {
    let companyId = +(localStorage.getItem('selectedId') || '{}');
    let options = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.get(`${environment.apiUrl}/lms/report-contingency?companyId=${companyId}`, { responseType: 'blob' }).pipe(map((resp) => {
      return resp;
    }));
  }
  //download Contingency  reports
  //Issue dropdown
  dataSource: any;
  issueDropdown() {
    this.dataSource = [
      {
        group: 'Return scrutiny ',
        items: [
          { id: 1, name: 'GSTR2A vs GSTR3B' },
          { id: 2, name: 'GSTR1 vs GSTR3B' },
          { id: 3, name: 'E-invoice vs GSTR1' },
          { id: 4, name: 'E-way bill vs GSTR1' },
          { id: 5, name: 'GSTR 9 vs GSTR 1/3B ' },
          { id: 6, name: 'GSTR2B vs GSTR3B' },
        ]
      },
      {
        group: 'Procedural ',
        items: [
          { id: 11, name: 'TDS/TCS issues ' },
          { id: 12, name: 'E invoice issues' },
          { id: 13, name: 'Eway bill issues' }
        ]
      },
      {
        group: 'Classification/Rate ',
        items: [
          { id: 21, name: 'HSN classification ' },
          { id: 22, name: 'SAC classification ' },
          { id: 23, name: 'Composite Supply vs Mixed Supply ' },
          { id: 24, name: 'Goods vs Service ' },
          { id: 24, name: 'Taxability of Riders in Life Insurance ' },
          { id: 25, name: 'Permanent transfer of Intellectual property ' },
        ]
      },
      {
        group: 'Taxability  ',
        items: [
          { id: 31, name: 'Warranty supplies ' },
          { id: 32, name: 'Liquidated Damages ' },
          { id: 33, name: 'Notice Pay Recovery ' },
          { id: 34, name: 'Toleration of an act ' },
          { id: 34, name: 'Works Contract ' },
          { id: 35, name: 'Canteen recoveries from employees ' },
          { id: 36, name: 'Other recoveries from employees' },
          { id: 37, name: 'Carry income ' },
          { id: 38, name: 'Commission received from overseas customer ' },
          { id: 39, name: 'Profit share from overseas customers ' },
          { id: 40, name: 'Other intermediary services' },
          { id: 41, name: 'Transfer of leasehold rights ' },
          { id: 42, name: 'Outward FOC supplies' },
          { id: 43, name: 'Stock transfer on sales promotion material ' },
          { id: 44, name: 'Donation/Grants received ' },
          { id: 45, name: 'Supply to ship/vessels ' },
          { id: 46, name: 'Interest on delayed receipt of consideration from customers ' },
          { id: 47, name: 'Transfer of business as a going concern' },
          { id: 48, name: 'Sale of re possessed assets under SARFAESI Act' },
        ]
      },
      {
        group: 'Liability   ',
        items: [
          { id: 49, name: 'Interest on delayed payment of tax ' },
          { id: 50, name: 'Time of supply issues  ' },
          { id: 51, name: 'ISD distribution  ' },
          { id: 52, name: 'Issuance of credit notes ' },
          { id: 53, name: 'Adjustment of Credit notes' },
        ]
      },
      {
        group: 'Valuation ',
        items: [
          { id: 54, name: 'Related parties' },
          { id: 55, name: 'Corporate guarantees' },
          { id: 56, name: 'Post Sale discounts ' },
          { id: 57, name: 'Joint Development Agreement ' },
          { id: 58, name: 'Reimbursements in the nature of pure agent recovery' },
          { id: 59, name: 'Valuation on Cross charge transactions ' },
          { id: 60, name: 'Brand and license usage given to subsidiary companies' }
        ]
      },
      {
        group: 'ITC ',
        items: [
          { id: 61, name: 'Motor Vehicles for Transportation of Passengers (Less than 13 Pax)' },
          { id: 62, name: 'General Insurance, Servicing, Repair & Maintenance of above' },
          { id: 63, name: 'Food & Beverages including Outdoor Catering ' },
          { id: 64, name: 'Leasing, renting or hiring of motor vehicles for Passenger Transportation ' },
          { id: 65, name: 'Life Insurance ' },
          { id: 66, name: 'Health Insurance ' },
          { id: 67, name: 'Works Contract Services (or) Goods/Services used in Construction – to the extent of Capitalization' },
          { id: 68, name: 'Goods lost, stolen, destroyed, written off or disposed of by way of gift or free samples' },
          { id: 69, name: 'Beauty treatment, Health services, Cosmetic and Plastic surgery' },
          { id: 70, name: 'Membership of a Club, Health and Fitness Centre' },
          { id: 71, name: 'Travel benefits to employees on vacation such as leave or home travel concession' },
          { id: 72, name: 'Goods or Services or both used for personal consumption.' },
          { id: 73, name: 'Time barred Credits' },
          { id: 74, name: 'Common Credit Reversals' },
          { id: 75, name: 'ITC reversal if payment is not made within 180 days' },
          { id: 76, name: 'Rule 36(4) reversals' },
          { id: 77, name: 'Ineligible credit - ISD' },
          { id: 78, name: 'ITC on promotional goods' },
          { id: 79, name: 'CSR credit ' },
          { id: 81, name: 'Residential colony ' },
          { id: 82, name: 'Canteen credit ' },
          { id: 83, name: 'ITC on reversal of securities' },
          { id: 84, name: 'Excess claim of ITC' },
        ]
      },
      {
        group: 'Transition  ',
        items: [
          { id: 84, name: 'Capital goods in transit ' },
          { id: 85, name: 'Trading inventory ' },
          { id: 86, name: 'Edu cess ' },
          { id: 87, name: 'Service invoice accounted after 30th June ' },
          { id: 88, name: 'Refund under Sec 142(3) ' },
          { id: 89, name: 'VAT C form issue ' },
          { id: 91, name: 'Entry Tax transition issue ' },
          { id: 92, name: 'Mismatch of Earlier returns vs TRAN - 1' },
          { id: 93, name: 'Pre GST inventory written off ' },
          { id: 94, name: 'Reversal of excess ITC claimed in Trans -1' },
          { id: 95, name: 'Interest on reversal of excess ITC claimed in Trans -1' },

        ]
      },
      {
        group: 'Job Work   ',
        items: [
          { id: 94, name: 'Goods not returning from JW premises after 1/3 years' },
          { id: 95, name: 'Loss/Scrap at JW premises ' },
          { id: 96, name: 'ITC - 04 vs JW register ' }
        ]
      },
      {
        group: 'RCM',
        items: [
          { id: 97, name: 'RCM not paid' },
        ]
      },
      {
        group: 'Refunds',
        items: [
          { id: 98, name: 'Rule 96A - Export of goods not being done within 105 days post issuance of invoice' },
          { id: 99, name: 'Rule 96B - Export refund restriction FEMA ' },
          { id: 100, name: 'Rule 96(10) - Merchant export , Advance license' },
          { id: 101, name: 'Time limit/Limitation issues ' },
        ]
      },
    ];
  }
  //Issue dropdown

  getCumulativeCount(type: any, data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'text/plain' }),
    }
    return this.http.put(`${environment.apiUrl}/lms2/common/cumulative-calc?type=${type}`, data, httpOptions).pipe(map((resp) => {
      return resp;
    }));
  }
  checkExtensionFunction(
    selectedFile: { name: string },
    extensionAllowedArray: any[]
  ) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }

  onFileSelect(event: any, type: any = null) {
    console.log("Type", type)
    this.selectedField = event.target.getAttribute('id');
    sessionStorage.setItem('File', this.selectedField)
    if (event.target.files[0] !== undefined) {
      if (
        this.checkExtensionFunction(event.target.files[0], [
          'csv',
          'zip',
          'pdf',
          'xlsx',
          'zip',
          'rar',
          'doc',
          'docx',
          'pptx',
          'xlsb',
          'png',
          'jpg',
          'jpeg',
          'msg',
        ])
      ) {
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace('&', 'And');
        this.selectedFile = new File([originalFile], modifiedFilename, {
          type: originalFile.type,
        });
        sessionStorage.setItem('type', type);
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUploadCommonFunction(this.selectedField, type);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported');
      }
    } else {
      this.selectedFile = null;
      this.toaster.showError('File is not selected');
    }
    return null;
  }
  onUploadCommonFunction(selectedfield: any, type: any) {
    const sectionType: any = sessionStorage.getItem('sectionType');

    const navContextData: any = sessionStorage.getItem('navContext');
    const parseData = JSON.parse(navContextData)
    let urlData = {
      gstinOrPan: parseData.gstin,
      type: sectionType,
      field: selectedfield, //this.selectedField,
      contentType: sectionType,
    };
    this.commonUpload(this.selectedFile, urlData).subscribe((response: any) => {
      if (response.response) {
        sessionStorage.setItem('fileResponse', JSON.stringify(response.response));
        sessionStorage.setItem('selectedfield', selectedfield);
        sessionStorage.setItem('type', sectionType)
        this.setUploadFileData("true");
      }
    })
    // this.litService
    // .commonUpload(this.selectedFile, urlData)
    // .subscribe((response: any) => {
    //   if (response != null) {
    //     // this.fileUrl = response.url;
    //     // this.filePath = response.path;
    //     this.getFileUrl(response.response.url, response.response.path, selectedfield, type);
    //   } else {
    //   }
    // });
  }

  commonCreatePath(str: any) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
      // path.push(pathChunk.substr(0, indexOfBrc));
      // path.push(pathChunk.charAt(indexOfBrc + 1));
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  /* Delete api */
  deletePrelitigation(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/gst/delete/scn`, httpOptions);
  }
  deleteAdjudication(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/gst/delete/aja`, httpOptions);
  }
  deleteCommissioner(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/gst/delete/apa`, httpOptions);
  }
  deleteTribunal(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/gst/delete/apt`, httpOptions);
  }
  deleteHc(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/gst/delete/hc`, httpOptions);
  }
  deleteSc(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      body: data
    }
    return this.http.delete(`${environment.apiUrl}/lms2/gst/delete/sc`, httpOptions);
  }

  //  GST RemandedBack
  getRemandedBackForumGSTOptions(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/gst/query/remanded-case-details`, data);
  }

   // Duplicate Check 
   getDuplicateData(data: any) {
    return this.http.post(`${environment.apiUrl}/lms2/api/issueRef`, data);
  }


  // GET data for single-case download

  generateSingleCaseDownload(data: any) {
    let companyId = +(localStorage.getItem('selectedId') || '{}');


    let obj = {

      page: 0,
      size:10,
      sortfield: 'caseId',
      sortdir: 'ASC',
      gstin:'',
      companyId,
      module : data.module,
      criterias : [
        {
          p: 'caseId',
          o: 'eq',
          v: data.caseId
        }

      ]
    }

    return this.http.post(`${environment.baseUrl}/single-case/download`, obj);

    // GET data for single-case download
}
}
