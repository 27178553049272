import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LitigationServiceService } from '../services/litigation-service.service';
import { DecimalPipe } from '@angular/common';
// import { DecimalPipe } from '@angular/common';


@Component({
  selector: 'app-litigation-download',
  templateUrl: './litigation-download.component.html',
  styleUrl: './litigation-download.component.css'
})
export class LitigationDownloadComponent {

  @ViewChild('content') content : ElementRef | any;

  caseId:any;
  caseData:any;
  previousUrl:any;
  issuesDetails:any;
  currentForum:any;
  forumData:any;
  caseHistoryForBind:any;
  closedIssueList: any[] = [];
  cumulativePaymentDetails: any[] = [];
  consultantChargesList: any[] = [];
  module:any;

  // stage
  prelitigationLabel:string = 'Pre-Litigation';
  adjudication: string = 'Adjudication';
  adjudicationAuthority = 'Adjudication Authority';
  commissioner: string = 'Commissioner';
  commissioner_ca : string = 'C(A)';
  commissioner_A: string = this.commissioner +" (A)";
  appellateAuthority: string = 'Appellate Authority';
  appellateTribunal : string = 'Appellate Tribunal';
  highCourt: string = 'High Court';
  supremeCourt: string = 'Supreme Court';
  auditFindingLetter: string = 'Audit Findings Letter';

    // PREGST
preGst: string = "PreGst";
preGst_adjudicationAuthority: string = this.preGst +" "+this.adjudicationAuthority;
preGst_appellateTribunal: string = this.preGst +" "+this.appellateTribunal;
preGst_appellateAuthority: string = this.preGst +" "+this.appellateAuthority;
preGst_highCourt: string = this.preGst +" "+this.highCourt;
preGst_supremeCourt: string = this.preGst +" "+this.supremeCourt;

  // status - issues
  admitted: string = 'Admitted';
  favorable: string = 'Favorable';
  partlyFavorable: string = 'Partly Favorable';
  unfavorable: string = "Unfavorable";
  partlyUnfavorable:string = "Partly Unfavorable";

  // comulative Labels
  preDeposit: string = 'Pre-Deposit';
  protest: string =  'protest';
  admittedLiability: string =  'admitted liability';
  consultantCharges: string = 'Consultant Charges';

  comulativeLabels: any[] = [this.preDeposit, this.protest, this.admittedLiability, this.consultantCharges];

  caseHistory : any[] = [
    {
      label:'First Notice Recieved on',
      forumList:[this.prelitigationLabel],
      closed:false,
      currentForum:false,
      date:'',
      forumText: 'Pre Litigation'
    },
    {
      label:'SCN Received on',
      forumList: [this.adjudication, this.adjudicationAuthority,'Adjudication  Authority', this.preGst_adjudicationAuthority],
      closed:false,
      currentForum:false,
      date:'',
      forumText: 'Adjudication'
    },
    {
      label:'Audit Start Date',
      forumList: [this.auditFindingLetter],
      closed:false,
      currentForum:false,
      date:'',
      forumText: 'Audit Findings Letter'
    },
    {
      label:'Appeal filed at C(A)',
      forumList:[this.commissioner, this.appellateAuthority, this.commissioner_ca, this.commissioner_A, this.preGst_appellateAuthority],
      closed:false,
      currentForum:false,
      date:'',
      forumText: this.commissioner_A

    },
    {
      label:'Appeal filed at Tribunal',
      forumList:[this.appellateTribunal, this.preGst_appellateTribunal],
      closed:false,
      currentForum:false,
      date:'',
      forumText: this.appellateTribunal
    },
    {
      label:'Appeal filed at High Court',
      forumList:[this.highCourt, this.preGst_highCourt],
      closed:false,
      currentForum:false,
      date:'',
      forumText: this.highCourt
    },
    {
      label:'Appeal Filed at Supreme Court',
      forumList:[this.supremeCourt, this.preGst_supremeCourt],
      closed:false,
      currentForum:false,
      date:'',
      forumText: this.supremeCourt
    }
  ];

  constructor(
    private litService: LitigationServiceService,
    private activeSnapShot: ActivatedRoute,
    private router: Router,
  ){
      this.caseId = this.activeSnapShot.snapshot.params.caseId;
      this.module = this.activeSnapShot.snapshot.params.module;
  }

  ngOnInit() {
    this.getSingleCaseDownload();
  }

  formatToTwoDecimal(value: any): string {
    return value ? parseFloat(value).toFixed(2) : '0.00';
  }

  getSingleCaseDownload(): void {
    const requestData = {
      caseId: this.caseId,
      module: this.module
    };
  
    this.litService.generateSingleCaseDownload(requestData).subscribe(
      (response: any) => {
        if (response.response) {
          this.caseData = response.response;
            console.log("caseData original:", this.caseData);
          // Apply two decimal formatting to required fields in caseData
          this.caseData.forEach((item: any) => {
            item.ccTotal = this.formatToTwoDecimal(item.ccTotal);
            item.preDepositAmount = this.formatToTwoDecimal(item.preDepositAmount);
            item.protestDetailsAmount = this.formatToTwoDecimal(item.protestDetailsAmount);
            item.admittedLiabilityAmount = this.formatToTwoDecimal(item.admittedLiabilityAmount);
            item.igst1Total = this.formatToTwoDecimal(item.igst1Total);
            item.igst3DemandConfirmedTotal = this.formatToTwoDecimal(item.igst3DemandConfirmedTotal);
            item.cgst1Total = this.formatToTwoDecimal(item.cgst1Total);
            item.cgst3DemandConfirmedTotal = this.formatToTwoDecimal(item.cgst3DemandConfirmedTotal);
            item.sgst1Total = this.formatToTwoDecimal(item.sgst1Total);
            item.sgst3DemandConfirmedTotal = this.formatToTwoDecimal(item.sgst3DemandConfirmedTotal);
            item.cess1Total = this.formatToTwoDecimal(item.cess1Total);
            item.cess3DemandConfirmedTotal = this.formatToTwoDecimal(item.cess3DemandConfirmedTotal);
            item.interest1Total = this.formatToTwoDecimal(item.interest1Total);
            item.interest3DemandConfirmedTotal = this.formatToTwoDecimal(item.interest3DemandConfirmedTotal);
            item.penalty1Total = this.formatToTwoDecimal(item.penalty1Total);
            item.penalty3DemandConfirmedTotal = this.formatToTwoDecimal(item.penalty3DemandConfirmedTotal);
            item.totOfTotal1 = this.formatToTwoDecimal(item.totOfTotal1);
            item.demandConfirmed3 = this.formatToTwoDecimal(item.demandConfirmed3);
            item.droppedAmount3 = this.formatToTwoDecimal(item.droppedAmount3);
          });

          console.log("caseData:", this.caseData);
  
          this.forumData = response.response.filter((x: any) => x?.forum);
  
          this.currentForum = response.response.find((x: any) => x?.currentForum);
          this.issuesDetails = this.currentForum?.issues;
          if (this.issuesDetails) {
            this.issuesDetails.forEach((x: any) => {
              x.igst1 = this.formatToTwoDecimal(x.igst1);
              x.igst3 = this.formatToTwoDecimal(x.igst3);
              x.cgst1 = this.formatToTwoDecimal(x.cgst1);
              x.cgst3 = this.formatToTwoDecimal(x.cgst3);
              x.sgst1 = this.formatToTwoDecimal(x.sgst1);
              x.sgst3 = this.formatToTwoDecimal(x.sgst3);
              x.cess1 = this.formatToTwoDecimal(x.cess1);
              x.cess3 = this.formatToTwoDecimal(x.cess3);
              x.interest1 = this.formatToTwoDecimal(x.interest1);
              x.interest3 = this.formatToTwoDecimal(x.interest3);
              x.penalty1 = this.formatToTwoDecimal(x.penalty1);
              x.penalty3 = this.formatToTwoDecimal(x.penalty3);
              x.total1 = this.formatToTwoDecimal(x.total1);
              x.total3 = this.formatToTwoDecimal(x.total3);
            });
            if (this.currentForum?.status === "CLOSED") {
              this.issuesDetails = this.issuesDetails?.filter((x: any) => x.status);
            }
          }
  
          this.caseHistoryForBind = [];
          this.closedIssueList = [];
          this.consultantChargesList = [];
          this.cumulativePaymentDetails = [];
          let payment = this.getCommulativeDefaultValue();
          payment.label = this.consultantCharges;
  
          this.caseData.forEach((item: any) => {
            item.hearingStatus = item.attended ? 'Yes' : 'No';
  
            let forum = this.caseHistory.find(x => x.forumList.includes(item.forum));
            if (!forum) {
              forum = this.caseHistory.find(x => x.forumList.includes(item.currentForum));
              if (forum) {
                forum.currentForum = true;
              }
            }
  
            if (forum) {
              item.stageName = forum.forumText;
              if (forum.forumList.includes(this.prelitigationLabel)) {
                forum.date = item.dateOfRecieptOfLetter;
              } else if (forum.forumList.includes(this.adjudication || this.adjudicationAuthority || this.preGst_adjudicationAuthority)) {
                forum.date = this.module == "GST" ? item.dateOfReceiptOfScn : item.dateOfRecieptOfNotice;
              } 
              else if(forum.forumList.includes(this.auditFindingLetter))
              {
                forum.date = item.auditStartDate; 
              }else {
                forum.date = item.appealFilingDate;
              }
  
              forum.closed = forum.currentForum && item.status == "CLOSED";
              this.caseHistoryForBind.push(forum);
              if (!forum.currentForum) {
                let closedIssue = item?.issues?.filter((x: any) => x.status == this.admitted || x.status == this.favorable || x.status == this.partlyFavorable);
  
                if (closedIssue && closedIssue.length) {
                  closedIssue.forEach((x: any) => {
                    let obj = {
                      value: this.formatToTwoDecimal(x.total3),
                      status: x.status,
                      issue: x.issue,
                      forum: item.stageName
                    };
                    this.closedIssueList.push(obj);
                  });
                }
              }
  
              this.mapCommulativeFieldValue(item?.stageName, item?.ccTotal ?? 0, payment);
              payment.total += item?.ccTotal ?? 0;
            }
          });

          if(this.currentForum.remandedPaymentMade){
            this.consultantChargesList.push(payment);
            payment.total = this.formatToTwoDecimal(this.currentForum.remandedPaymentMade[2].total);
          }

          let tempArray = [];
          this.comulativeLabels.forEach((label:any)=> {

            if(this.consultantCharges !== label)
            {

              let payment = this.getCommulativeDefaultValue();
              payment.label = label;

              this.caseData.forEach((obj: any) => {

                if(this.currentForum.remandedPaymentMade){
                  if(label === this.preDeposit)
                  {
                      this.mapCommulativeFieldValue(obj?.stageName, obj?.preDepositAmount ?? 0, payment);
                      payment.total = this.formatToTwoDecimal(this.currentForum.remandedPaymentMade[0].total);
                  }
                  else if(label === this.protest)
                  {
                      this.mapCommulativeFieldValue(obj?.stageName, obj?.protestDetailsAmount ?? 0, payment);
                      payment.total = this.formatToTwoDecimal(this.currentForum.remandedPaymentMade[1].total);
                  }
                  else if(label === this.admittedLiability)
                  {
                      this.mapCommulativeFieldValue(obj?.stageName, obj?.admittedLiabilityAmount?? 0, payment);
                      payment.total = this.formatToTwoDecimal(this.currentForum.remandedPaymentMade[3].total);

                  }
                } else{
                  if(label === this.preDeposit)
                    {
                        this.mapCommulativeFieldValue(obj?.stageName, obj?.preDepositAmount ?? 0, payment);
                        payment.total =this.formatToTwoDecimal('0');
                    }
                    else if(label === this.protest)
                    {
                        this.mapCommulativeFieldValue(obj?.stageName, obj?.protestDetailsAmount ?? '0', payment);
                        payment.total =this.formatToTwoDecimal('0');
                    }
                    else if(label === this.admittedLiability)
                    {
                        this.mapCommulativeFieldValue(obj?.stageName, obj?.admittedLiabilityAmount?? 0, payment);
                        payment.total =this.formatToTwoDecimal('0');
                    }
                }
              });
              if( this.consultantCharges !== label) {
                this.cumulativePaymentDetails.push(payment);
              }
            }
          });
  
          this.forumData = this.caseData.filter((x: any) => x?.forum);
        }
      }
    );
  }

  generatePdf(): void {
    window.print();
  }
  
  getCommulativeDefaultValue(){
  let payment = {
        label:"",
        total:""
  };
  return payment;
  }

  mapCommulativeFieldValue(stageName:any, consultantCost:any, payment:any){
  if(stageName == "Pre Litigation"){
      payment.scn = consultantCost;
    } else if(stageName == 'Adjudication'){
      payment.aja = consultantCost;
    }
    else if(stageName == this.commissioner_A){
      payment.apa = consultantCost;
    }
    else if(stageName == this.appellateTribunal){
      payment.apt = consultantCost;
    }
    else if(stageName == this.highCourt){
      payment.hc = consultantCost;
    }
    else if(stageName == this.supremeCourt) {
      payment.sc = consultantCost;
    }
  }

  goBack(){

  let routePath = 'litigationSummary';
  if(this.module == "PREGST")
  {
    routePath= 'preGstSummary';
  }
  this.router.navigate(['/'+ routePath]);

  }

}
