import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartOptions } from 'chart.js';
import _ from 'lodash';
import { CommonService } from 'src/app/shared/services/common.service';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';


export enum REGISTRATION {
  REGISTRATION_NO = 'Registration NO',
  DEMAND_AMOUNT = "Demand Amount"
}
interface Legends {
  label: string;
  color: string;
  value: number;
  percent?: string;
}
@Component({
  selector: 'app-b1-chart',
  templateUrl: './b1-chart.component.html',
  styleUrls: ['./b1-chart.component.css']
})
export class B1ChartComponent implements OnInit {
  @Input() b1Data: any;
  ageingPopSummary: any;
  chartLabels: string[] = [];
  chartData: any = [];
  isAnalyticsConOne: boolean = false;
  caseId = REGISTRATION;
  sorting: { key: boolean, value: boolean } = { key: false, value: false };
  pieChartLegend = true;
  chartType: any = "doughnut";
  currentLabel: any;
  tableData: any;
  selectedIRN: boolean = false;
  isinCR: boolean = false;
  isinLakh: boolean = true;
  amountMetric: 'lakh' | 'crore' = 'lakh';
  colors: { backgroundColor?: string[] }[] = [];
  legends: Legends[] = [];
  labels: string[] = [];
  values: number[] = [];

  label: string | undefined;
  chartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: { legend: { display: false }, tooltip: { enabled: true, mode: 'nearest' } },

    // plugins: {
    //   // labels: {
    //   //   render: 'value'
    //   // },
    //   legend: { position: 'right' },
    //   tooltip: { enabled: true, mode: 'nearest' },
    // },
  };
  // chartColors: any = [{
  //   backgroundColor: ['#ff5722', '#03a9f4', '#9c27b0', '#e51c23', '#607d8b', '#8bc34a'],
  // }];
  constructor(private modalService: NgbModal,
    private commonService: CommonService,
    private excelService: ExcelService,
    private toasterService: ToasterService
  ) { }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    let value:any=[];

    this.chartLabels = Object.keys(this.b1Data.details);
    let colors: string[] = [];
    
    this.chartLabels.forEach((labels: any) => {
      value.push(this.b1Data.details[labels]?.count);
    })
  
    this.chartData = { labels: this.chartLabels, datasets: [{ data: value,backgroundColor: colors }]};
    this.chartLabels.forEach((labels) => {
      colors.push(labels.toColor(colors));
    });

    this.colors = [{ backgroundColor: colors }];

    const totalValue = _.sum(value);
  
    this.legends = this.chartLabels.map((el, index) => {
      return {
        label: el,
        value: value[index],
        color: colors[index],
        percent: ((value[index] / totalValue) * 100).toFixed(1)
      };
    });

  }

  changeStatsValue(selected: any, event: Event) {
    const isCrore = (event.target as HTMLInputElement).checked;
    this.amountMetric = isCrore ? 'crore' : 'lakh';
    if (selected == false) {
      this.isinCR = false;
      this.isinLakh = true;
    }
    else if (selected == true) {
      this.isinCR = true;
      this.isinLakh = false;
    }
  }

  sortChartLabels() {
    const numericArr = this.chartLabels.filter(str => /^\d/.test(str));
    const nonNumericArr = this.chartLabels.filter(str => !/^\d/.test(str));
    this.chartLabels = [...numericArr, ...nonNumericArr];

    const lessThanThree: string | undefined = this.chartLabels.find((str: string) => str.startsWith('Less'));
    const moreThanTen: string | undefined = this.chartLabels.find((str: string) => str.startsWith('More'));
    const writ: string | undefined = this.chartLabels.find((str: string) => str.startsWith('writ'));

    if (lessThanThree) {
      const index: number = this.chartLabels.indexOf(lessThanThree);
      this.chartLabels.splice(index, 1);
      this.chartLabels.unshift(lessThanThree);
    }
    if (moreThanTen) {
      const index: number = this.chartLabels.indexOf(moreThanTen);
      this.chartLabels.splice(index, 1);
      this.chartLabels.push(moreThanTen);
    }
    if (writ) {
      const index: number = this.chartLabels.indexOf(writ);
      this.chartLabels.splice(index, 1);
      this.chartLabels.push(writ);
    }

  }

  openPieChart(content: any, e: any) {
    const index = e.active?.[0]?.index ?? -1;
    this.currentLabel = e.event.chart?.data?.labels?.[index] ?? '';    
    const keys = Object.keys(this.b1Data.details[this.currentLabel].details);
    this.tableData = [];
    keys.forEach((key: any) => {
      this.tableData.push({
        key: key,
        act: this.b1Data.details[this.currentLabel].details[key].actType,
        value: this.b1Data.details[this.currentLabel].details[key].total,
        value1: this.b1Data.details[this.currentLabel].details[key].totalInCr,
      },

      );
    })

    // this.tableData.forEach((element: any) => {
    //   element.expanded = false;
    // });
    this.modalService.open(content, { windowClass: 'preanalytics-pop' })
  }
  sortValue(value: number) {
    if (value) {
      return (value).toFixed(2);
    }
    return 0;
  }
  sort(column: string) {
    switch (column) {
      case REGISTRATION.REGISTRATION_NO: {
        if (!this.sorting.key) {
          this.isAnalyticsConOne = true;
          this.sorting.key = true;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'key', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.key = false;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'key', 'desc');
        }
        break;
      }

      case REGISTRATION.DEMAND_AMOUNT: {
        if (!this.sorting.value) {
          this.isAnalyticsConOne = true;
          this.sorting.value = true;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'value', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.value = false;
          this.tableData = this.commonService.sortObjectsByKey(this.tableData, 'value', 'desc');
        }
        break;
      }
    }
  }


  exportAsXLSX() {
    let excelData: any = [];
    
    if (this.tableData.length === 0) {
      this.toasterService.showError('No data to export.');
      return;
    }

    this.tableData.forEach((item : any, index: any) => {
        let obj = {
          "Sr No": index + 1,
          "Registration": item.key,
          "Act": item.act,
          "Total":  this.isinLakh ?  item.value.toFixed(2) : item.value1.toFixed(2)
        }
        excelData.push(obj);
    });

    this.excelService.exportAsExcelFile(excelData, 'Ageing Analysis - Registration No');

  }
}
