import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {
  invokeFirstComponentFunction = new EventEmitter();    
  invokeDashboard = new EventEmitter();    
  subsVar!: Subscription;    
  subsVarDash!: Subscription;    

  constructor(
    private router: Router,
  ) { }

  onFirstComponentButtonClick() {  
    if(this.router.url === '/litigationSummary'){
      this.invokeFirstComponentFunction.emit();  
    }
    // if(this.router.url === '/dashboard'){
    //   this.invokeDashboard.emit();  
    // }    
  }   
  onDashboardLoad() { 
    if(this.router.url === '/dashboard'){
      this.invokeDashboard.emit();  
    }    
  } 
}
