import { Component, OnInit } from '@angular/core';
import { DUE_DATE_SPAN } from 'src/app/shared/constant';
import { CommonService } from '../../shared/services/common.service';
import { AuditService } from '../service/audit.service';

@Component({
  selector: 'app-audit-dashboard',
  templateUrl: './audit-dashboard.component.html',
  styleUrls: ['./audit-dashboard.component.css']
})
export class AuditDashboardComponent implements OnInit {
  dueDateSpan: { key: string; value: string; }[];
  dueDays: any;
  navContext: any;
  selectedFilling: any;
  selectedGstin: any;
  dueData: any;
  newsList: any;
  cntOPEN: any;

  constructor(
    private CommonService: CommonService,
    private auditService: AuditService,
  ) {
    this.dueDateSpan = DUE_DATE_SPAN

  }

  ngOnInit() {
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.navContext = this.CommonService.getNavContext();
    this.dueDays = this.dueDateSpan[0].value;
    // this.getDueDateData();
    this.newsUpdateData();
    // this.getOpenCount();

  }
  // getDueDateData() {  
  //   let model:any = {};
  //   if (this.navContext.entityType === 'FILING') {       
  //     model.gstin = this.selectedGstin;     
  //   }else{    
  //     model.gstin= '';      
  //   }       
  //   model.days = this.dueDays;
  //   this.CommonService.dueDate(model).subscribe((response: any) => {
  //     if (response.status === 1 ) {      
  //       this.dueData = response.response;
  //     }
  //   });

  // }
  newsUpdateData() {
    this.CommonService.newsUpdate().subscribe((response: any) => {
      if (response.status === "SUCCESS") {
        this.newsList = response.response.news;
      }
    });

  }
  // getOpenCount() {
  //   let mainModel: any = {};
  //   if (this.navContext.entityType === 'FILING') {
  //     //mainModel.type = this.tabType[0].key;
  //     mainModel.gstin = this.selectedGstin;
  //     mainModel.st = 'OPEN';
  //   } else {
  //     //mainModel.type = this.tabType[0].key;
  //     mainModel.gstin = '';      
  //     mainModel.st = 'OPEN';
  //   }
  //   this.auditService.getCount(mainModel).subscribe((response: any) => {
  //     if (response.status == 1) {
  //       this.cntOPEN = response.response;
  //     }
  //   });
  // }
}
