import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-a7-chart',
  templateUrl: './a7-chart.component.html',
  styleUrls: ['./a7-chart.component.css']
})
export class A7ChartComponent implements OnInit {

  @Input() a7Data: any;
  @Input() totals: any
  @Input() divCumulativeTotal: any;
  @Input() divCumulativeTotalCr: any;

  data: any = [];
  paymentRegiondata: any = [];
  paymentCumlData: any = [];
  selectedIRN: boolean = false;
  isinCR: boolean = false;
  isinLakh: boolean = true;
  amountMetric: 'lakh' | 'crore' = 'lakh';
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData() {
    this.getPaymentModeData();
  }
  changeStatsValue(selected: any, event: Event) {
    const isCrore = (event.target as HTMLInputElement).checked;
    this.amountMetric = isCrore ? 'crore' : 'lakh';
    if (selected == false) {
      this.isinCR = false;
      this.isinLakh = true;
    }
    else if (selected == true) {
      this.isinCR = true;
      this.isinLakh = false;
    }
  }


  getPaymentModeData() {
    var regionKeys = Object.keys(this.a7Data);
    this.paymentRegiondata = [];
    for (var i = 0; i < regionKeys.length; i++) {
      let paymentRegiondata = this.a7Data[regionKeys[i]];
      let row: any = {
        state: regionKeys[i],
        demand: paymentRegiondata.demand,
        pDeposit:paymentRegiondata.pDeposit,
        lAmount:paymentRegiondata.lAmount,
        pAmount:paymentRegiondata.pAmount,

        demandInCr: paymentRegiondata.demandInCr,
        pDepositInCr:paymentRegiondata.pDepositInCr,
        lAmountInCr:paymentRegiondata.lAmountInCr,
        pAmountInCr:paymentRegiondata.pAmountInCr
      };
      let paymentGstinDataArray = []; 
      for (let regNo in paymentRegiondata.regDetails) {
        let rawGstinData = paymentRegiondata.regDetails[regNo];
        let paymentGstinData = {
          regNo: regNo,
          rowSummary: rawGstinData,
        }
        paymentGstinDataArray.push(paymentGstinData);
      }
      row.paymentGstinData = paymentGstinDataArray;
      this.paymentRegiondata.push(row);


    }
    this.paymentRegiondata = this.commonService.sortObjectsByKey(this.paymentRegiondata, 'state');
    if (this.paymentRegiondata.length > 0) {
      this.paymentRegiondata.forEach((element: any) => {
        element.expanded = false;
      });
    }
  }
}

