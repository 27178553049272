<div class="container-fluid page-body-wrapper">
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <div>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" title="Close Case" (click)="gotoSummary()">
                                  <span aria-hidden="true" style="color: red;">&times;</span>
                              </button>
                              </div>
                            <!-- <p class="card-title">SCN</p>	                 -->
                            <ul class="nav nav-pills iris-gst-display-flex" role="tablist" id="pills-tab">
                                <li class="nav-item">
                                    <a class="nav-link" (click)="tabChanged('tab1')" [ngClass]="{'active':selectedTab === 'tab1'}" id="intimation-tab" data-toggle="tab" role="tab" aria-controls="intimation-1" aria-selected="true">Audit Intimation Letter</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" (click)="tabChanged('tab2')" [ngClass]="{'disabled':!tabDisableFlag.tab2, 'active':selectedTab === 'tab2'}" id="findings-tab" data-toggle="tab" role="tab" aria-controls="findings-1" aria-selected="true">Audit Findings Letter</a>
                                <!-- [ngClass]="{'disabled':!tabDisableFlag.tab2, 'active':selectedTab === 'tab2'}" -->
                            </ul>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="intimation-1" role="tabpanel" aria-labelledby="intimation-tab">
                                    <app-audit-intimation-letter  (saveCaseId)="saveCaseId($event)" [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab1'" (moveToAFL)="tabChanged('tab2')"></app-audit-intimation-letter>
                                </div>
                                <div class="tab-pane fade show active" id="findings-1" role="tabpanel" aria-labelledby="findings-tab">
                                    <app-audit-finding-letter  (saveCaseId)="saveCaseId($event)"  [selectedCaseId]="selectedCaseId" *ngIf="selectedTab === 'tab2'"  (moveToAJA)="tabChanged('tab2')"></app-audit-finding-letter>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- main-panel ends -->
</div>