<div class="horizontal-menu">
   <nav class="navbar top-navbar col-lg-12 col-12 p-0">
      <div class="container">
         <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <a class="navbar-brand brand-logo" (click)="dashboard()"><img
                  src="https://irisgst-logo.s3.ap-south-1.amazonaws.com/logo.png" alt="logo" /></a>
            <a class="navbar-brand brand-logo-mini" (click)="dashboard()"><img
                  src="https://irisgst-logo.s3.ap-south-1.amazonaws.com/logo.png" alt="logo" /></a>
         </div>
         <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">

            <div id="menu1">
               <ul class="navbar-nav navbar-nav-left ml-3 ">
                  <li class="nav-item dropdown"><a (click)="GotoUserDash()"><i class="fa fa-building"></i></a></li>
                  <li class="nav-item dropdown ">
                     <a class="nav-link count-indicator dropdown-toggle d-flex  align-items-center" id="navbarDropdown"
                        data-toggle="dropdown">
                        <span class="companyName"><i class="fa fa-caret-right"></i></span>
                     </a>
                     <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                        aria-labelledby="navbarDropdown">
                        <div class="autoScroll">
                           <div *ngFor=" let business of BNBusinessList">
                              <a class="dropdown-item text-overflow"
                                 (click)="changeBusiness(business?.companyid, true)">
                                 <i class="fa fa-tree"></i> {{business.company}} </a>
                              <!-- <div class="dropdown-divider"></div> -->
                           </div>
                        </div>
                     </div>
                  </li>
                  <li class="nav-item dropdown shwHighRes" id="{{BNModel?.companyId}}">
                     <a class="nav-link count-indicator dropdown-toggle d-flex  align-items-center"
                        data-toggle="dropdown" (click)="GotoDash(BNModel)">
                        <i class="fa fa-building"></i> <span class="companyName ">{{BNModel?.companyName}} </span>
                     </a>
                  </li>
                  <li class="nav-item dropdown ">
                     <a id="iconMainComp" class="nav-link count-indicator dropdown-toggle d-flex  align-items-center"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="companyName"><i class="fa fa-caret-right"></i></span>
                     </a>
                     <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                        aria-labelledby="navbarDropdown">
                        <div class="autoScroll">
                           <div *ngFor="let com of BNModel?.childCompanies ">
                              <a *ngIf="com.visible " class="dropdown-item text-overflow" id="{{com?.companyId}}"
                                 (click)="onBNComSelected(com.companyName,com,0)"><i *ngIf="com.entityType == 'LEGAL'"
                                    class="fa fa-tree"></i><i *ngIf="com.entityType == 'FILING'" class="fa fa-leaf"></i>
                                 <span class="text-overflow">{{com.companyName}}</span></a>
                              <!-- <div class="dropdown-divider"></div> -->
                           </div>
                        </div>
                     </div>
                  </li>
                  <li class="nav-item dropdown" *ngFor="let coms of BNSelectedComs">
                     <a class="nav-link count-indicator d-flex  align-items-center"
                        (click)="GoToDashboard(coms, '', '', true)" *ngIf="coms.entityType != 'POB'"
                        title="{{coms.companyName}}">
                        <i class="fa fa-building"></i> <span class="companyName textWrap">{{coms.companyName}} </span>
                     </a>
                     <a class="nav-link count-indicator dropdown-toggle d-flex  align-items-center ml-2"
                        id="iconMainComp" data-toggle="dropdown" *ngIf="coms.childCompanies?.length > 0  ">
                        <span class="companyName"><i class="fa fa-caret-right"></i></span>
                     </a>
                     <div class="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
                        aria-labelledby="navbarDropdown">
                        <div class="autoScroll">
                           <div *ngFor="let com of coms.childCompanies; let i = index;">
                              <a class="dropdown-item text-overflow" id="{{com?.companyId}}"
                                 (click)="onBNComSelected(com.companyName,com,i+1, true)"
                                 *ngIf="com.visible && com.entityType != 'POB'"><i *ngIf="com.entityType == 'LEGAL'"
                                    class="fa fa-tree"></i><i *ngIf="com.entityType == 'FILING'" class="fa fa-leaf"></i>
                                 <span class="text-overflow">{{com.companyName}} </span>
                              </a>
                           </div>
                        </div>
                     </div>

                  </li>
                  <li>
                     <div class="ng-autocomplete">
                        <ng-autocomplete [data]="allEntity" [searchKeyword]="keyword" (selected)='selectEvent($event)'
                           [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" placeHolder="Go To...">
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item>
                           <a [innerHTML]="item.name"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                           <div [innerHTML]="notFound"></div>
                        </ng-template>
                     </div>
                  </li>

               </ul>
            </div>



            <ul class="navbar-nav navbar-nav-right shwHighRes">
               <li class="nav-item nav-profile dropdown">
                  <a class="nav-link shwHighRes" data-toggle="dropdown">
                     <img src="assets/images/nine-dots.svg" />
                  </a>
                  <ul class="dropdown-menu interOperable dropdown-menu-right navbar-dropdown mt-3 mr-2">
                     <li class="app" *ngIf="checkAccessProducts('topazAccess') == true">
                        <a (click)="navigateTo('Topaz')">
                           <i class="fa fa-truck topaz-icon" aria-hidden="true"></i>
                           <span style="display: block;">Topaz </span>
                        </a>
                     </li>
                     <li class="app" *ngIf="checkAccessProducts('sapphireAccess') == true">
                        <a (click)="navigateTo('Sapphire')">
                           <i class="fa fa-file-text-o sapphire-icon" aria-hidden="true"></i>
                           <span style="display: block;">Sapphire</span>
                        </a>
                     </li>
                     <li class="app" *ngIf="checkAccessProducts('onyxAccess') == true">
                        <a (click)="navigateTo('Onyx')">
                           <i class="fa fa-qrcode onyx-icon" aria-hidden="true"></i>
                           <span style="display: block;">Onyx</span>
                        </a>
                     </li>
                     <li class="app">
                        <a target="_blank" href="https://irisgst.com/gst-litigation-management-system/">
                           <i class="fa fa-book lms-icon" aria-hidden="true"></i>
                           <span style="display: block;">Wiki</span>
                        </a>
                     </li>
                  </ul>
               </li>
               <li class="nav-item nav-profile dropdown">
                  <a class="nav-link shwHighRes" href="#" data-toggle="dropdown" id="profileDropdown">
                     {{currentUser}} <i class="fa fa-user ml-2"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right navbar-dropdown mt-3 mr-2"
                     aria-labelledby="profileDropdown">
                     <!-- <a class="dropdown-item" (click)="myPreference(familyData)">
                        <i class="fa fa-cogs"></i>
                        My Preference
                     </a> -->
                     <!-- <a class="dropdown-item" routerLink="/profile">
                      <i class="fa fa-user"></i>
                      Profile
                   </a> -->
                     <a class="dropdown-item" (click)="settings(familyData)"
                        *ngIf="isRole == 'Admin' || isRole == 'GST_Admin'|| isRole == 'PREGST_Admin' || isRole == 'DT_Admin' || isRole == 'IDT_Admin'">
                        <i class="fa fa-cog"></i>
                        Settings
                     </a>
                     <a class="dropdown-item" (click)="emailSetting(familyData)">
                        <i class="mdi mdi-email"></i>
                        Email
                     </a>

                     <a class="dropdown-item" (click)="logout()">
                        <i class="mdi mdi-logout"></i>
                        Logout
                     </a>
                  </div>
               </li>
            </ul>
            <button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
               data-toggle="horizontal-menu-toggle">
               <span class="mdi mdi-menu"></span>
            </button>

         </div>
      </div>
   </nav>
   <nav class="bottom-navbar">
      <div class="container">
         <ul class="nav page-navigation">
            <li [ngClass]="[activeLink === 'Dashboard' ? 'nav-item active' : 'nav-item']">
               <a class="nav-link" (click)="dashboard()">
                  <i class="mdi mdi-view-dashboard-outline menu-icon"></i>
                  <span class="menu-title">Home</span>
               </a>
            </li>
            <!-- <li class="nav-item">
               <a class="nav-link" (click)="litigationSummary()">
                  <i class="fa fa-legal menu-icon"></i>
                  <span class="menu-title">Litigation</span>
               </a>
            </li> -->
            <li [ngClass]="[activeLink === 'Litigation' ? 'nav-item active' : 'nav-item']"
               *ngIf="isGSTRole || isIDTRole || (!isGSTRole && !isIDTRole && !isPREGSTRole && !isDTRole)">
               <a class="nav-link">
                  <i class="fa fa-legal menu-icon"></i>
                  <span class="menu-title">GST Litigation</span>
                  <i class="menu-arrow"></i></a>
               <div class="submenu">
                  <ul class="submenu-item">
                     <!-- <li class="nav-item"><a class="nav-link" (click)="auditDashboard()">Dashboard</a></li> -->
                     <span class="nav-item d-flex"><a class="nav-link" (click)="litigationSummary()"><i
                              class="fa fa-circle-thin"></i>&nbsp; Summary</a></span>
                     <span class="nav-item" [hidden]="navContext?.entityType == 'FILING' "><a class="nav-link"
                           (click)="litigationAnalytics()"><i class="fa fa-circle-thin"></i>&nbsp; Analytics</a></span>
                     <span class="nav-item" *ngIf="navContext?.entityType == 'FILING' || navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'"><a class="nav-link" (click)="litigationSmartUpload()"><i class="fa fa-circle-thin"></i>&nbsp; Smart Upload</a></span>
                  </ul>
               </div>
            </li>
            <!-- <li [ngClass]="[activeLink === 'Refunds' ? 'nav-item active' : 'nav-item']">
               <a class="nav-link" href="#">
                  <i class="mdi mdi-repeat menu-icon"></i>
                  <span class="menu-title">Refunds</span>
               </a>
            </li> -->
            <!-- <li class="nav-item">
               <a class="nav-link" href="#">
                  <i class="mdi mdi-file-chart menu-icon"></i>
                  <span class="menu-title">Audits</span>
               </a>
            </li> -->
            <li [ngClass]="[activeLink === 'Audit' ? 'nav-item active' : 'nav-item']"
               *ngIf="isGSTRole || isIDTRole || (!isGSTRole && !isIDTRole && !isPREGSTRole && !isDTRole)">
               <a class="nav-link">
                  <i class="mdi mdi-file-chart menu-icon paddlr75"></i>
                  <span class="menu-title">GST Audits</span>
                  <i class="menu-arrow"></i></a>
               <div class="submenu">
                  <ul class="submenu-item">
                     <!-- <li class="nav-item"><a class="nav-link" (click)="auditDashboard()">Dashboard</a></li> -->
                     <span class="nav-item"><a class="nav-link" (click)="auditSummary()"><i
                              class="fa fa-circle-thin"></i>&nbsp; Audit Summary</a></span>
                     <span class="nav-item"
                        *ngIf="navContext?.entityType == 'FILING' || navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'"><a
                           class="nav-link" (click)="auditCompGstDetail()"> <i class="fa fa-circle-thin"></i>&nbsp;
                           Company GST Details</a></span>
                     <span class="nav-item"><a class="nav-link" (click)="auditTaxPosition()"
                           *ngIf="navContext?.entityType == 'FILING' || navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'">
                           <i class="fa fa-circle-thin"></i>&nbsp; Tax Positions </a></span>
                     <span class="nav-item"><a class="nav-link" (click)="auditRecon()"
                           *ngIf="navContext?.entityType == 'FILING'"> <i class="fa fa-circle-thin"></i>&nbsp;
                           Reconciliation Folder</a></span>
                  </ul>
               </div>
            </li>
            <li [ngClass]="[activeLink === 'preGst' ? 'nav-item active' : 'nav-item']"
               *ngIf="  navContext?.entityType!= 'FILING' && (isPREGSTRole || isIDTRole || (!isGSTRole && !isIDTRole && !isDTRole))">
               <a class="nav-link">
                  <i class="mdi mdi-arrange-bring-forward menu-icon paddlr75"></i>
                  <span class="menu-title">Pre GST Litigation</span>
                  <i class="menu-arrow"></i></a>
               <div class="submenu">
                  <ul class="submenu-item">
                     <span class="nav-item"><a class="nav-link" (click)="preGstSummary()"><i
                              class="fa fa-circle-thin"></i>&nbsp; PRE GST Summary</a></span>
                     <span class="nav-item"><a class="nav-link" (click)="preGstAnalytics()"><i
                              class="fa fa-circle-thin"></i>&nbsp; PRE GST Analytics</a></span>
                  </ul>
               </div>
            </li>

            <li [ngClass]="[activeLink === 'dirTax' ? 'nav-item active' : 'nav-item']"
               *ngIf=" navContext?.entityType!= 'FILING' && (isDTRole || (!isGSTRole && !isPREGSTRole && !isIDTRole))">
               <a class="nav-link">
                  <i class="fa fa-leanpub" style="font-size: 22px;padding-right: 6px;"></i>
                  <span class="menu-title">Direct Tax</span>
                  <i class="menu-arrow"></i></a>
               <div class="submenu">
                  <ul class="submenu-item">
                     <span class="nav-item"><a class="nav-link" (click)="directTaxSummary()"><i
                              class="fa fa-circle-thin"></i>&nbsp; Summary</a></span>
                     <span class="nav-item"><a class="nav-link" (click)="directTaxAnalytics()"><i
                              class="fa fa-circle-thin"></i>&nbsp; Analytics</a></span>
                     <span class="nav-item"><a class="nav-link" (click)="dirFinInformation()"><i
                              class="fa fa-circle-thin"></i>&nbsp; Financial Information</a></span>
                     <span class="nav-item"><a class="nav-link" (click)="dirTaxPositions()"><i
                              class="fa fa-circle-thin"></i>&nbsp; Tax Position</a></span>
                  </ul>
               </div>
            </li>
            <li [ngClass]="[activeLink === 'noticeManagement' ? 'nav-item active' : 'nav-item']" *ngIf="!isPREGSTRole">
               <a class="nav-link">
                  <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                           d="M17 11C15.9391 11 14.9217 10.5786 14.1716 9.82843C13.4214 9.07828 13 8.06087 13 7C13 5.93913 13.4214 4.92172 14.1716 4.17157C14.9217 3.42143 15.9391 3 17 3C18.0609 3 19.0783 3.42143 19.8284 4.17157C20.5786 4.92172 21 5.93913 21 7C21 8.06087 20.5786 9.07828 19.8284 9.82843C19.0783 10.5786 18.0609 11 17 11ZM5 5H11V7H5V19H17V13H19V19C19 19.5304 18.7893 20.0391 18.4142 20.4142C18.0391 20.7893 17.5304 21 17 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5Z"
                           fill="white" />
                     </svg></span>
                  <span class="menu-title">Notice Management</span>
                  <i class="menu-arrow"></i></a>
               <div class="submenu">
                  <ul class="submenu-item">
                     <span class="nav-item"
                        *ngIf="isGSTRole || isIDTRole || (!isGSTRole && !isPREGSTRole && !isDTRole)"><a class="nav-link"
                           (click)="gstNotice()"><i class="fa fa-circle-thin"></i>&nbsp;
                           GST</a></span>
                     <span class="nav-item"
                        *ngIf="navContext?.entityType != 'FILING' && (( !isGSTRole && !isIDTRole) || (!isGSTRole && !isPREGSTRole && !isIDTRole))"><a
                           class="nav-link" (click)="dtNotice()"><i class="fa fa-circle-thin"></i>&nbsp; Direct
                           Tax</a></span>
                  </ul>
               </div>
            </li>



         </ul>
      </div>
   </nav>
</div>
