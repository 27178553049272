<div id="chart-wrapper" *ngIf="isinLakh">
  <div class="chart-box" >
    <div class="chart-canvas">
  <canvas 
    baseChart
    style="cursor: pointer"
    [data]="a1BarChartData"
    [type]="a1BarChartType"
    [options]="a1BarChartOptions"
    (chartClick)="openChart(details, $event)">
  </canvas>
    </div>
    </div>
</div>

<div id="chart-wrapper" *ngIf="isinCR">
  <div class="chart-box">
    <div class="chart-canvas">
  <canvas
    baseChart
    style="cursor: pointer"
    [data]="a1BarChartData"
    [type]="a1BarChartType"
    [options]="a1BarChartOptions"
    (chartClick)="openChart(details, $event)">
  </canvas>
    </div>
    </div>
</div>

<!-- <ng-container>
  <div class="text-center no-records-found">
    <i class="fa fa-bar-chart" aria-hidden="true"></i>
    <p class="text-md text-gray">No records found.</p>
  </div>
</ng-container> -->

<ng-template #details let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Issue Wise Demand Details</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="switch-box" style="float: inline-end;">
      <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">₹ In Lakhs</label>
      <div class="form-check form-switch m-0">
        <input [(ngModel)]="selectedIRN" (change)="changeStatsValue(selectedIRN,$event)" class="form-check-input"
          type="checkbox" role="switch" [checked]="amountMetric === 'crore'" />
      </div>
      <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
      <p class="btn btn-outline-success btn-download" (click)="exportAsXLSX()">
        <i class="fa fa-file-excel-o me-2" aria-hidden="true"></i> Export
      </p>
    </div>
    <p class="pieChartLabel txtCenter">{{currentLabel | uppercase}}</p>
    <div class="autoScroll" style="min-height: 480px !important; width: 100%;">
      <div class="row header-tbl-issue-wise-demand" style="padding: 8px; border: 1px solid #e3e7ed; background-color: #8cb4d5;">
        <div class="col-1" style="max-width: 5% !important;"> <span>Sr. No</span></div>
        <div class="col-7 text-center">
            <span>Issue </span>
            <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="sorting.issue"
              (click)="sort(demandValue.ISSUE)"></i>
            <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="!sorting.issue"
              (click)="sort(demandValue.ISSUE)"></i>
        </div>
        <div class="textRight col-1">
          <span>Tax </span>
          <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="sorting.tax"
            (click)="sort(demandValue.TAX)"></i>
          <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="!sorting.tax"
            (click)="sort(demandValue.TAX)"></i>
        </div>
        <div class="textRight col-1">
          <span>Interest </span>
              <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="sorting.interest"
                (click)="sort(demandValue.INTEREST)"></i>
              <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="!sorting.interest"
                (click)="sort(demandValue.INTEREST)"></i>
        </div>
        <div class="textRight col-1">
          <span>Penalty </span>
              <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="sorting.penalty"
                (click)="sort(demandValue.PENALTY)"></i>
              <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="!sorting.penalty"
                (click)="sort(demandValue.PENALTY)"></i>
        </div>
        <div class="textRight col-1">
          <span>Total </span>
            <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="sorting.total"
              (click)="sort(demandValue.TOTAL)"></i>
            <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="!sorting.total"
              (click)="sort(demandValue.TOTAL)"></i>
        </div>
      </div>
      <div class="accordion" id="accordion" role="tablist">
        <ng-container *ngFor="let obj of tableData; let i=index">
          <div class="row" role="tab" id="heading-{{i}}" 
          style="padding: 8px;border: 1px solid #e3e7ed; background-color: #eee;">
            <div class="col-1" style="max-width: 5% !important;">{{ i+1 }}</div>
            <div class="col-7" style="width: 200px;">
              <span>
                {{ obj.issue }}
                <a class="collapsed" data-toggle="collapse" href="#collapse-{{i}}" aria-expanded="false"
                aria-controls="collapse-{{i}}"></a>
              </span>
            </div>
            <div class="textRight col-1" *ngIf="isinLakh">{{ obj.tax | INR: 2 }}</div>
            <div class="textRight col-1" *ngIf="isinLakh">{{ obj.interest | INR: 2 }}</div>
            <div class="textRight col-1" *ngIf="isinLakh">{{ obj.penalty | INR: 2 }}</div>
            <div class="textRight col-1" *ngIf="isinLakh">{{ obj.total | INR: 2 }}</div>

            <div class="textRight col-1" *ngIf="isinCR">{{ obj.taxInCr | INR: 2 }}</div>
            <div class="textRight col-1" *ngIf="isinCR">{{ obj.interestInCr | INR: 2 }}</div>
            <div class="textRight col-1" *ngIf="isinCR">{{ obj.penaltyInCr | INR: 2 }}</div>
            <div class="textRight col-1" *ngIf="isinCR">{{ obj.totalInCr | INR: 2 }}</div>
          </div>

          <div id="collapse-{{i}}" class="collapse card-body" style="border: 1px solid #eee"
              role="tabpanel" aria-labelledby="heading-{{i}}"
              data-parent="#accordion">
              <div class="row">
                <div class="col-md-12 nopadding mb10">
                  <table class="table table-bordered tbl-issue-wise-demand">
                    <thead>
                      <tr>
                      <th class="col-1" style="max-width: 4% !important;">Sr. No</th>
                      <th class="col-1">State</th>
                      <th class="col-1">Registration number </th>
                      <th class="col-1" style="max-width: 12% !important;">Case Id</th>
                      <th class="col-1">Forum</th>
                      <th class="col-3">Case Label</th>
                      <th class="col-1 text-right">Tax</th>
                      <th class="col-1 text-right">Interest</th>
                      <th class="col-1 text-right">Penalty</th>
                      <th class="col-1 text-right">Total</th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of obj.caseDetails; let j =index">
                        <td class="col-1" style="max-width: 4% !important;">{{j+1}}</td>
                        <td class="col-1">{{item.state}}</td>
                        <td class="col-1">{{item.regNo}}</td>
                        <td *ngIf="item.stage == 'aja'"><a
                          (click)="getPreGstCaseData(item.caseId, 'update', 'preaja', 'tab1')">{{item.caseId}}</a></td>
                      <td *ngIf="item.stage == 'apa'"><a
                          (click)="getPreGstCaseData(item.caseId, 'update', 'preapa', 'tab2')">{{item.caseId}}</a></td>
                      <td *ngIf="item.stage == 'apt'"><a
                          (click)="getPreGstCaseData(item.caseId, 'update', 'preapt', 'tab3')">{{item.caseId}}</a></td>
                      <td *ngIf="item.stage == 'hc'"><a
                          (click)="getPreGstCaseData(item.caseId, 'update', 'prehc', 'tab4')">{{item.caseId}}</a></td>
                      <td *ngIf="item.stage == 'sc'"><a
                          (click)="getPreGstCaseData(item.caseId, 'update', 'presc', 'tab5')">{{item.caseId}}</a></td> 
                      <td class="col-1">{{item.type}}</td>
                        <td class="col-3">{{item.caseLabel}}</td>
                        <td class="text-right col-1" *ngIf="isinLakh">{{ item.tax | INR: 2}}</td>
                        <td class="text-right col-1" *ngIf="isinLakh">{{ item.interest | INR: 2}}</td>
                        <td class="text-right col-1" *ngIf="isinLakh">{{ item.penalty | INR: 2}}</td>
                        <td class="text-right col-1" *ngIf="isinLakh">{{ item.total | INR: 2}}</td>
                        <td class="text-right col-1" *ngIf="isinCR">{{ item.taxInCr | INR: 2}}</td>
                        <td class="text-right col-1" *ngIf="isinCR">{{ item.interestInCr | INR: 2}}</td>
                        <td class="text-right col-1" *ngIf="isinCR">{{ item.penaltyInCr | INR: 2}}</td>
                        <td class="text-right col-1" *ngIf="isinCR">{{ item.totalInCr | INR: 2}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>

</ng-template>
