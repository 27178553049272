import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { BusinessService } from '../../services/business.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-interest-calculation',
  templateUrl: './interest-calculation.component.html',
  styleUrl: './interest-calculation.component.css'
})
export class InterestCalculationComponent implements OnInit{
  
  emailForm!: UntypedFormGroup;
  gstSelected: boolean = false;
  preGstSelected: boolean = false;
  isDisabled: boolean = true;
  isDisabledPre: boolean = true;
  intCalOptOutResGst:any;
  gstselectedOpt:any;
  pregstselectedOpt:any;
  changegstNotification : any;
  changepreGstNotification : any;
  onLoadselectedOpt:any;

  constructor(    
    private fBuild: UntypedFormBuilder,
    private router: Router,
    private toaster: ToasterService,
    private businessSerivce: BusinessService,
  ) { }

  ngOnInit() {
    this.intCalOptOutData();
     this.gstSelected = this.gstselectedOpt;
     this.preGstSelected = this.pregstselectedOpt;
    }

  toDashboard() {
    this.router.navigate(['/dashboard']);
  }
 
  intCalOptOutData() {    
    this.businessSerivce.getIntCalOptOutData().subscribe((response: any) => {
      if (response.status === 1) {
        this.intCalOptOutResGst = response.response;
        this.gstselectedOpt = this.intCalOptOutResGst.gstModule;
        this.pregstselectedOpt = this.intCalOptOutResGst.preGstModule;
        if(this.gstselectedOpt == false || this.gstSelected == false){
          this.gstSelected = false;          
        } else{
          this.gstSelected = true;
         
        }
        if(this.pregstselectedOpt == false || this.preGstSelected == false){
          this.preGstSelected = false;          
        } else{
          this.preGstSelected = true;
         
        }
      }
    });
    

  } 
  
  changeValue(gstSelected:any){ 
    if(gstSelected == false){
      this.changegstNotification = true;
    }else{
      this.changegstNotification = false;
    }
    
    this.isDisabled = false;
  }
  changeValuePre(preGstSelected:any){ 
    if(preGstSelected == false){
      this.changepreGstNotification = true;
    }else{
      this.changepreGstNotification = false;
    }    
    this.isDisabledPre = false;
  }
  submitIntCalOptOut(){  
    this.businessSerivce.submitIntCalOptOutFunctionGst(this.changegstNotification, 'gst').subscribe((response: any) => {    
   
      if (response.status == 1 && this.changegstNotification == true) {    
        this.toaster.showSuccess('Auto schedular for GST Interest Calculation has been ACTIVATED');    
        
      } else {
        this.toaster.showSuccess('Auto schedular for GST Interest Calculation has been DEACTIVATED');
      }     
    });
  }
  submitIntCalOptOutPre(){  
    this.businessSerivce.submitIntCalOptOutFunctionGst(this.changepreGstNotification, 'pregst').subscribe((response: any) => {    
   
      if (response.status == 1 && this.changepreGstNotification == true) {    
        this.toaster.showSuccess('Auto schedular for Pre-GST Interest Calculation has been ACTIVATED');    
        
      } else {
        this.toaster.showSuccess('Auto schedular for Pre-GST Interest Calculation has been DEACTIVATED');
      }     
    });
  }
  
}
