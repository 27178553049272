import { Component, Input, OnInit } from '@angular/core';
import { PreGstServiceService } from 'src/app/preGstLitigation/services/pre-gst-service.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { LoaderService } from 'src/app/shared/services/loader.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { STATE_LIST } from 'src/app/shared/constant';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

export enum STATE {
  ACT = 'Act',
  CASE_ID = 'Case ID',
  FORUM = 'Forum',
  CASE_LABEL = 'Case Label',
  TAX = "Tax",
  INTEREST = "Interest",
  PENALTY = "Penalty",
  DEMAND_AMOUNT = "Demand Amount"
}
@Component({
  selector: 'app-level-wise-demand-analysis',
  templateUrl: './level-wise-demand-analysis.component.html',
  styleUrls: ['./level-wise-demand-analysis.component.css']
})
export class LevelWiseDemandAnalysisComponent implements OnInit {

  @Input() a5Data: any;
  data: any = [];
  actTypeData: any = [];
  selectedState: any;
  stateList: any;
  navContext: any;
  entityType: any;
  selectedCompanyId: any;
  pan: any;
  regNo: any
  state: any;
  isAnalyticsConOne: boolean = false;
  states = STATE;
  sorting: { act: boolean, caseId: boolean, label: boolean, tax: boolean, interest: boolean, penalty: boolean, total: boolean, phase: boolean } = { act: false, caseId: false, label: false, tax: false, interest: false, penalty: false, total: false,phase:false };
  currentIssueLabel: any;
  @Input() SumColTotal: any;
  @Input() divSumColTotal: any;
  @Input() divSumColTotalSum: any;
  openCaseData: any;
  stateCaseData: any;
  selectedIRN: boolean = false;
  isinCR: boolean = false;
  isinLakh: boolean = true;
  companyID = '';
  amountMetric: 'lakh' | 'crore' = 'lakh';
  openCaseRef!: NgbModalRef;

  constructor(public commonService: CommonService,
    private modalService: NgbModal,
    private pregstService: PreGstServiceService,
    private loaderService: LoaderService,
    private eEmit: EventEmitterService,
    private router: Router,
    private getterSetter: GetterSetterService,
    private excelService: ExcelService,
    private toaster: ToasterService,
  ) {
  }

  ngOnInit(): void {
    this.stateList = STATE_LIST;
    this.companyID = <string>localStorage.getItem('selectedId');
    this.selectedState = localStorage.getItem('preAnalyticState');
    this.selectedCompanyId = localStorage.getItem('selectedId');
    this.navContext = this.commonService.getNavContext();
    // this.preAnalytics();
    const mainLabel = Object.keys(this.a5Data);
    mainLabel.forEach((label: any) => {
      this.data.push({ label: label, total: this.a5Data[label], data: this.a5Data[label].actdetails, statePhdetails: this.a5Data[label].phdetails });
    })

    this.data = this.commonService.sortObjectsByKey(this.data, 'label');
    for (let i = 0; i < this.data.length; i++) {
      const element = this.data[i];
      element.actTypeData = [];
      for (const key in element.data) {
        let actLabel: any = {};
        actLabel = element.data[key];
        actLabel.label = key;
        actLabel.regData = [];
        for (const regdetailsKey in actLabel.regdetails) {
          let regDetailsData: any = {};
          regDetailsData = actLabel.regdetails[regdetailsKey];
          regDetailsData.regNumber = regdetailsKey;
          actLabel.regData.push(regDetailsData);
        }
        // delete Object.assign(actLabel.label, { 'Other Tax': actLabel.label['ENTRY TAX'] })['ENTRY TAX'];
        if (actLabel.label === 'ENTRY TAX') {
          actLabel.label = 'OTHER TAX';
        }

        element.actTypeData.push(actLabel);
      }
    }
    console.log("statedata", this.data)


    //  this.getTotalOfTotal();

  }


  returnActdetailsObjectKeys(obj: object) {
    return Object.keys(obj);
  }
  changeStatsValue(selected: any, event: Event) {
    const isCrore = (event.target as HTMLInputElement).checked;
    this.amountMetric = isCrore ? 'crore' : 'lakh';
    if (selected == false) {
      this.isinCR = false;
      this.isinLakh = true;
    }
    else if (selected == true) {
      this.isinCR = true;
      this.isinLakh = false;
    }
  }

  // preAnalytics() {
  //   this.navContext = this.commonService.getNavContext();
  //   if (this.navContext.entityType === 'FILING') {
  //     this.pan = this.navContext.gstin;
  //   } else if (this.navContext.entityType === 'LEGAL') {
  //     this.pan = this.navContext.pan;
  //   } else if (this.navContext.entityType === 'Business') {
  //     this.pan = '';
  //   }
  //   const navContext = JSON.parse(this.getterSetter.getNavContextData() || '{}');
  //   localStorage.setItem('preAnalyticState', this.selectedState);
  //   let model = {
  //     selectedState: this.selectedState,
  //     pan: this.navContext.entityType === 'LEGAL' ? this.pan : null,
  //     companyId: this.selectedCompanyId
  //   }
  //   this.pregstService.getPreAnalytics(model).subscribe((response: any) => {
  //     this.SumColTotal = response.response;
  //     this.divSumColTotal = this.SumColTotal.a5summary;
  //     this.divSumColTotalSum = this.SumColTotal.a5summary.phases;
  //   })
  // }

  exportAsXLSX() {
    let excelData: any = [];
    excelData = this.data.slice(0);
    if (excelData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    if (this.isinCR === true) {
      const exportExceldata = excelData.flatMap((region: any) => {
        let finalData = [];
        const transformedValues = {
          "State": region.label,
          "Adjudication - Case Count": region?.statePhdetails?.aja?.totalCount,
          "Adjudication - Demand (₹)": region?.statePhdetails?.aja?.totalInCr,
          "Commissioner (A) - Case Count": region?.statePhdetails?.apa?.totalCount,
          "Commissioner (A) - Demand (₹)": region?.statePhdetails?.apa?.totalInCr,
          "Appellate Tribunal - Case Count": region?.statePhdetails?.apt?.totalCount,
          "Appellate Tribunal - Demand (₹)": region?.statePhdetails?.apt?.totalInCr,
          "High Court - Case Count": region?.statePhdetails?.hc?.totalCount,
          "High Court - Demand (₹)": region?.statePhdetails?.hc?.totalInCr,
          "Supreme Court - Case Count": region?.statePhdetails?.sc?.totalCount,
          "Supreme Court - Demand (₹)": region?.statePhdetails?.sc?.totalInCr,
          "Total - Case Count": region?.total?.totalCount,
          "Total - Demand (₹)": region?.total?.totalDemandInCr,
        };
        finalData.push(transformedValues)
        if (region.actTypeData.length > 0) {
          region.actTypeData.forEach((subRow: any) => {
            let tempActData = {
              "State": `- ${subRow.label}`,
              "Adjudication - Case Count": subRow?.phdetails?.aja?.totalCount,
              "Adjudication - Demand (₹)": subRow?.phdetails?.aja?.totalInCr,
              "Commissioner (A) - Case Count": subRow?.phdetails?.apa?.totalCount,
              "Commissioner (A) - Demand (₹)": subRow?.phdetails?.apa?.totalInCr,
              "Appellate Tribunal - Case Count": subRow?.phdetails?.apt?.totalCount,
              "Appellate Tribunal - Demand (₹)": subRow?.phdetails?.apt?.totalInCr,
              "High Court - Case Count": subRow?.phdetails?.hc?.totalCount,
              "High Court - Demand (₹)": subRow?.phdetails?.hc?.totalInCr,
              "Supreme Court - Case Count": subRow?.phdetails?.sc?.totalCount,
              "Supreme Court - Demand (₹)": subRow?.phdetails?.sc?.totalInCr,
              "Total - Case Count": subRow?.totalCount,
              "Total - Demand (₹)": subRow?.totalDemandInCr,
            };
            finalData.push(tempActData)
            if (subRow.regData.length > 0) {
              subRow.regData.forEach((regRow: any) => {
                let tempRegData = {
                  "State": `-- ${regRow.regNumber}`,
                  "Adjudication - Case Count": regRow?.details?.aja?.totalCount,
                  "Adjudication - Demand (₹)": regRow?.details?.aja?.totalDemandInCr,
                  "Commissioner (A) - Case Count": regRow?.details?.apa?.totalCount,
                  "Commissioner (A) - Demand (₹)": regRow?.details?.apa?.totalDemandInCr,
                  "Appellate Tribunal - Case Count": regRow?.details?.apt?.totalCount,
                  "Appellate Tribunal - Demand (₹)": regRow?.details?.apt?.totalDemandInCr,
                  "High Court - Case Count": regRow?.details?.hc?.totalCount,
                  "High Court - Demand (₹)": regRow?.details?.hc?.totalDemandInCr,
                  "Supreme Court - Case Count": regRow?.details?.sc?.totalCount,
                  "Supreme Court - Demand (₹)": regRow?.details?.sc?.totalDemandInCr,
                  "Total - Case Count": regRow?.totalCount,
                  "Total - Demand (₹)": regRow?.totalDemandInCr,
                };
                finalData.push(tempRegData)
              })
            }
          })
        }

        return finalData;
      });
      const totalRow = {
        "State": "Total",
        "Adjudication - Case Count": this.divSumColTotalSum?.aja?.totalCount,
        "Adjudication - Demand (₹)": this.divSumColTotalSum?.aja?.totalInCr,
        "Commissioner (A) - Case Count": this.divSumColTotalSum?.apa?.totalCount,
        "Commissioner (A) - Demand (₹)": this.divSumColTotalSum?.apa?.totalInCr,
        "Appellate Tribunal - Case Count": this.divSumColTotalSum?.apt?.totalCount,
        "Appellate Tribunal - Demand (₹)": this.divSumColTotalSum?.apt?.totalInCr,
        "High Court - Case Count": this.divSumColTotalSum?.hc?.totalCount,
        "High Court - Demand (₹)": this.divSumColTotalSum?.hc?.totalInCr,
        "Supreme Court - Case Count": this.divSumColTotalSum?.sc?.totalCount,
        "Supreme Court - Demand (₹)": this.divSumColTotalSum?.sc?.totalInCr,
        "Total - Case Count": this.divSumColTotal.totalCount,
        "Total - Demand (₹)": this.divSumColTotal.totalDemandInCr,
      };
      exportExceldata.push(totalRow);
      console.log("export excel data ", exportExceldata);
      this.excelService.exportAsExcelFile(exportExceldata, 'Division Wise Demand Analytics');

    } else {

      const exportExceldata = excelData.flatMap((region: any) => {
        let finalData = [];
        const transformedValues = {
          "State": region.label,
          "Adjudication - Case Count": region?.statePhdetails?.aja?.totalCount,
          "Adjudication - Demand (₹)": region?.statePhdetails?.aja?.total,
          "Commissioner (A) - Case Count": region?.statePhdetails?.apa?.totalCount,
          "Commissioner (A) - Demand (₹)": region?.statePhdetails?.apa?.total,
          "Appellate Tribunal - Case Count": region?.statePhdetails?.apt?.totalCount,
          "Appellate Tribunal - Demand (₹)": region?.statePhdetails?.apt?.total,
          "High Court - Case Count": region?.statePhdetails?.hc?.totalCount,
          "High Court - Demand (₹)": region?.statePhdetails?.hc?.total,
          "Supreme Court - Case Count": region?.statePhdetails?.sc?.totalCount,
          "Supreme Court - Demand (₹)": region?.statePhdetails?.sc?.total,
          "Total - Case Count": region?.total?.totalCount,
          "Total - Demand (₹)": region?.total?.totaldemand,
        };
        finalData.push(transformedValues)
        if (region.actTypeData.length > 0) {
          region.actTypeData.forEach((subRow: any) => {
            let tempActData = {
              "State": `- ${subRow.label}`,
              "Adjudication - Case Count": subRow?.phdetails?.aja?.totalCount,
              "Adjudication - Demand (₹)": subRow?.phdetails?.aja?.total,
              "Commissioner (A) - Case Count": subRow?.phdetails?.apa?.totalCount,
              "Commissioner (A) - Demand (₹)": subRow?.phdetails?.apa?.total,
              "Appellate Tribunal - Case Count": subRow?.phdetails?.apt?.totalCount,
              "Appellate Tribunal - Demand (₹)": subRow?.phdetails?.apt?.total,
              "High Court - Case Count": subRow?.phdetails?.hc?.totalCount,
              "High Court - Demand (₹)": subRow?.phdetails?.hc?.total,
              "Supreme Court - Case Count": subRow?.phdetails?.sc?.totalCount,
              "Supreme Court - Demand (₹)": subRow?.phdetails?.sc?.total,
              "Total - Case Count": subRow?.totalCount,
              "Total - Demand (₹)": subRow?.totaldemand,
            };
            finalData.push(tempActData)
            if (subRow.regData.length > 0) {
              subRow.regData.forEach((regRow: any) => {
                let tempRegData = {
                  "State": `-- ${regRow.regNumber}`,
                  "Adjudication - Case Count": regRow?.details?.aja?.totalCount,
                  "Adjudication - Demand (₹)": regRow?.details?.aja?.totaldemand,
                  "Commissioner (A) - Case Count": regRow?.details?.apa?.totalCount,
                  "Commissioner (A) - Demand (₹)": regRow?.details?.apa?.totaldemand,
                  "Appellate Tribunal - Case Count": regRow?.details?.apt?.totalCount,
                  "Appellate Tribunal - Demand (₹)": regRow?.details?.apt?.totaldemand,
                  "High Court - Case Count": regRow?.details?.hc?.totalCount,
                  "High Court - Demand (₹)": regRow?.details?.hc?.totaldemand,
                  "Supreme Court - Case Count": regRow?.details?.sc?.totalCount,
                  "Supreme Court - Demand (₹)": regRow?.details?.sc?.totaldemand,
                  "Total - Case Count": regRow?.totalCount,
                  "Total - Demand (₹)": regRow?.totaldemand,
                };
                finalData.push(tempRegData)
              })
            }
          })
        }

        return finalData;
      });
      const totalRow = {
        "State": "Total",
        "Adjudication - Case Count": this.divSumColTotalSum?.aja?.totalCount,
        "Adjudication - Demand (₹)": this.divSumColTotalSum?.aja?.total,
        "Commissioner (A) - Case Count": this.divSumColTotalSum?.apa?.totalCount,
        "Commissioner (A) - Demand (₹)": this.divSumColTotalSum?.apa?.total,
        "Appellate Tribunal - Case Count": this.divSumColTotalSum?.apt?.totalCount,
        "Appellate Tribunal - Demand (₹)": this.divSumColTotalSum?.apt?.total,
        "High Court - Case Count": this.divSumColTotalSum?.hc?.totalCount,
        "High Court - Demand (₹)": this.divSumColTotalSum?.hc?.total,
        "Supreme Court - Case Count": this.divSumColTotalSum?.sc?.totalCount,
        "Supreme Court - Demand (₹)": this.divSumColTotalSum?.sc?.total,
        "Total - Case Count": this.divSumColTotal.totalCount,
        "Total - Demand (₹)": this.divSumColTotal.taxdemand,
      };
      exportExceldata.push(totalRow);
      console.log("export excel data ", exportExceldata);
      this.excelService.exportAsExcelFile(exportExceldata, 'Division Wise Demand Analytics');
    }
  }

  setState(event: any) {
    this.selectedState = event.target.value;
    localStorage.setItem('preAnalyticState', this.selectedState);
    // this.preAnalytics();
  }

  openCase(content: any, label: string, stage: string, regNo: string, actType?: string) {
    this.openCaseRef = this.modalService.open(content, { windowClass: 'preanalytics-pop' });
    let model: any = {};
    if (label && actType) {
      model.stage = stage,
        model.state = label,
        model.criterias = [{
          "p": "actType",
          "o": "eq",
          "v": actType
        }],
        model.page= 0,
        model.size= 1000,
        model.sortdir= "ASC",
        model.sortfield ="createdOn",
        model.companyId = this.companyID,
        model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    } else if (regNo) {
      model.stage = stage,
        model.criterias = [{
          "p": "regNo",
          "o": "eq",
          "v": regNo
        },{
          "p": "actType",
          "o": "eq",
          "v": actType
        }],
        model.page= 0,
        model.size= 1000,
        model.sortdir= "ASC",
        model.sortfield ="createdOn",
        model.companyId = this.companyID,
        model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    } else {
      model.stage = stage,
        model.state = [{
          "p": "regNo",
          "o": "eq",
          "v": label
        }],
        model.page= 0,
         model.size= 1000,
         model.sortdir= "ASC",
         model.sortfield ="createdOn",
         model.companyId = this.companyID,
         model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    }
    this.pregstService.pregstGetOpenCaseState(model).subscribe((response: any) => {
      if (response != null) {
        this.openCaseData = response.response;
        this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'total', 'desc');

      }
    })

  }

  allOpenCase(content: any, label: string, regNo: string, actType?: string) {
    this.openCaseRef = this.modalService.open(content, { windowClass: 'preanalytics-pop' });
    let model: any = {};
    if (label && actType) {
        model.state = label,
        model.criterias = [{
          "p": "actType",
          "o": "eq",
          "v": actType
        }],
        model.page= 0,
        model.size= 1000,
        model.sortdir= "ASC",
        model.sortfield ="createdOn",
        model.companyId = this.companyID,
        model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    } else if (regNo) {
        model.criterias = [{
          "p": "regNo",
          "o": "eq",
          "v": regNo
        },{
          "p": "actType",
          "o": "eq",
          "v": actType
        }
      ],
        model.page= 0,
        model.size= 1000,
        model.sortdir= "ASC",
        model.sortfield ="createdOn",
        model.companyId = this.companyID,
        model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    } else {
        model.state = [{
          "p": "regNo",
          "o": "eq",
          "v": label
        }],
        model.page= 0,
         model.size= 1000,
         model.sortdir= "ASC",
         model.sortfield ="createdOn",
         model.companyId = this.companyID,
         model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    }
    this.pregstService.pregstGetOpenCaseState(model).subscribe((response: any) => {
      if (response != null) {
        this.openCaseData = response.response;
        this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'total', 'desc');
        this.openCaseData.forEach((x: any) => {
          if (x.phase == "aja") {
            x.phase = 'Adjudication';
          } else if (x.phase == "apa") {
            x.phase = 'Commissioner (A)';
          } else if (x.phase == "apt") {
            x.phase = 'Appellate Tribunal';
          } else if (x.phase == "hc") {
            x.phase = 'High Court';
          } else if (x.phase == "sc") {
            x.phase = 'Supreme Court';
          }
        });
      }
    })

  }


  openCaseState(content: any, label: string, stage: string, regNo: string, actType?: string) {
    this.openCaseRef = this.modalService.open(content, { windowClass: 'preanalytics-pop' });
    let model: any = {};
    if (label && actType) {
      model.stage = stage,
        model.state = label,
        model.actType = actType,
        model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    } else if (regNo) {
      model.stage = stage,
        model.regNo = regNo,
        model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    } else {
      model.stage = stage,
        model.state = label,
        model.pan = this.navContext.entityType === 'LEGAL' ? this.pan : ''
    }
    this.pregstService.pregstGetOpenCaseState(model).subscribe((response: any) => {
      if (response != null) {
        this.openCaseData = response;

      }
    })

  }

  GetOpenCase(caseId: number, action: string, type: string, tab: string) {
    this.router.navigate(['/createPreGstCase', caseId, { action: action, type: type, tab: tab }])
    this.openCaseRef.close();

  }
  sortValue(value: number) {
    if (value) {
      return (value).toFixed(2);
    }
    return 0;
  }

  sort(column: string) {
    switch (column) {
      case STATE.ACT: {
        if (!this.sorting.act) {
          this.isAnalyticsConOne = true;
          this.sorting.act = true;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'act', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.act = false;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'act', 'desc');
        }
        break;
      }

      case STATE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.isAnalyticsConOne = true;
          this.sorting.caseId = true;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'caseId', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.caseId = false;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'caseId', 'desc');
        }
        break;
      }

      case STATE.CASE_LABEL: {
        if (!this.sorting.label) {
          this.isAnalyticsConOne = true;
          this.sorting.label = true;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'label', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.label = false;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'label', 'desc');
        }
        break;
      }
      case STATE.TAX: {
        if (!this.sorting.tax) {
          this.isAnalyticsConOne = true;
          this.sorting.tax = true;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'tax', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.tax = false;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'tax', 'desc');
        }
        break;
      }

      case STATE.INTEREST: {
        if (!this.sorting.interest) {
          this.isAnalyticsConOne = true;
          this.sorting.interest = true;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'interest', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.interest = false;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'interest', 'desc');
        }
        break;
      }

      case STATE.PENALTY: {
        if (!this.sorting.penalty) {
          this.isAnalyticsConOne = true;
          this.sorting.penalty = true;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'penalty', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.penalty = false;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'penalty', 'desc');
        }
        break;
      }

      case STATE.DEMAND_AMOUNT: {
        if (!this.sorting.total) {
          this.isAnalyticsConOne = true;
          this.sorting.total = true;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'total', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.total = false;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'total', 'desc');
        }
        break;
      }
      case STATE.FORUM: {
        if (!this.sorting.phase) {
          this.isAnalyticsConOne = true;
          this.sorting.phase = true;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'phase', 'asc');
        } else {
          this.isAnalyticsConOne = false;
          this.sorting.phase = false;
          this.openCaseData = this.commonService.sortObjectsByKey(this.openCaseData, 'phase', 'desc');
        }
        break;
      }
    }
  }

  dataExcel: any = [];
  exportToExcel(type:any) {
    let dataForXlsx = this.openCaseData;
  
     if(type == 'Open Cases'){
      dataForXlsx = this.openCaseData;
    }
    else if(type == 'All Open Cases'){
      dataForXlsx = this.openCaseData;
    }

    this.dataExcel = [];
    dataForXlsx.forEach((obj: any, index:any) => {
        let demandAmount = 0;
        let taxAmount = 0;
        let interestAmount= 0;
        let penaltyAmount= 0;
        let forum;

          demandAmount = this.isinLakh ? obj.total?.toFixed(2) : obj.totalInCr?.toFixed(2);
          taxAmount = this.isinLakh ? obj.tax?.toFixed(2) : obj.taxInCr?.toFixed(2);
          interestAmount = this.isinLakh ? obj.interest?.toFixed(2) : obj.interestInCr?.toFixed(2);
          penaltyAmount = this.isinLakh ? obj.penalty?.toFixed(2) : obj.penaltyInCr?.toFixed(2);
          forum = obj.phase;
        
        let objForElsx = {
            'Sr No.': index + 1,
            'Act': obj.act,
            'Forum': forum,
            'Case ID': obj.caseId,
            'Case Label': obj.label,
            'Tax Amount': taxAmount,
            'Interest Amount': interestAmount,
            'Penalty Amount': penaltyAmount,
            'Demand Amount': demandAmount

        }

    
        if(type == 'Open Cases'){
            delete objForElsx.Forum;
        }
        
      
        this.dataExcel.push(objForElsx);
    });
    this.excelService.exportAsExcelFile(this.dataExcel, 'State wise Demand - Open Cases');
  } 

}
