import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FILTER_STATUS, DEMAND_AMT_OPTIONS, ISSUE_GST, TYPE_OF_ACT, STATE_LIST, REGNO, PREGST_ISSUE_DROPDOWN, FILTER_STATUS_AUDIT } from 'src/app/shared/constant';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { LitigationServiceService } from '../services/litigation-service.service';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { CommonService } from 'src/app/shared/services/common.service';
import { BusinessService } from 'src/app/businessManagement/services/business.service'
import { BusinessEntityService } from 'src/app/shared/services/business-entity.service';
import { PreGstServiceService } from 'src/app/preGstLitigation/services/pre-gst-service.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.css']
})
export class FilterComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<any>();
  someElement: any;
  navContext: any;
  navData: any;
  selectedFilling: any;
  selectedGstin: any;
  userRoleLs: any;
  userRole: any;
  currentYear: any;
  filterForm!: UntypedFormGroup;
  currentDate = new Date();
  status: any;
  statusAudit: any;
  @Input() filterData: any;
  @Input() isGST: any
  @Input() isAudit: any
  @Input() isPreGST: any
  nextWeekDt: any;
  nextMonthDt: any;
  nxtDtdd1: any;
  nxtDtdd2: any;
  nxtMonthdd1: any;
  nxtMonthdd2: any;
  selectedLi: any;
  demandAmtOptions = DEMAND_AMT_OPTIONS
  division: any
  actOptions = TYPE_OF_ACT
  amtQuery: any = {}
  stateOpt = STATE_LIST
  regNoOpt: any = [];
  regGstinNum: any;
  issueGst: any
  BNBusinessList: any = [];
  BNModel: any;
  selectedCompanyId: any;
  regGstinData: any;
  preGstregGstinNum: any;
  preGstregGstinData: any;
  leagalRegNum: any;
  selectedUserId: any;
  isAnalyticIssueFilter: any;
  isAnalyticDivFilter: any;
  isAnalyticStateFilter: any;
  dropdownSettings = {
    singleSelection: false,
    idField: 'value',
    textField: 'key',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    // itemsShowLimit: 3,
    // allowSearchFilter: true
  };




  constructor(
    private GS: GetterSetterService,
    private getterSetter: GetterSetterService,
    private litService: LitigationServiceService,
    private preLitService: PreGstServiceService,
    private commonServ: CommonService,
    private businessService: BusinessService,
    private fb: UntypedFormBuilder,
    private entityService: BusinessEntityService,

  ) {
    this.status = FILTER_STATUS;
    this.statusAudit = FILTER_STATUS_AUDIT;
  }

  ngOnInit() {
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.navData = JSON.parse(this.GS.getNavContextData() || '{}');
    this.selectedUserId = this.GS.getSetUserId();
    this.selectedGstin = this.selectedFilling.gstin;
    this.navContext = this.commonServ.getNavContext();
    this.selectedCompanyId = this.getterSetter.getSetCompanyId();

    this.setDate();
    this.personResponsibleData();
    this.personResponsiblePregstData();
    this.setDivisionData();
    if (localStorage.getItem('analyticIssueLbl')) {
      this.isAnalyticIssueFilter = localStorage.getItem('analyticIssueLbl');
      this.filterData.issue = null;
    }
    if (localStorage.getItem('preAnalyticIssueLbl')) {
      this.isAnalyticIssueFilter = localStorage.getItem('preAnalyticIssueLbl');
      this.filterData.issue = null;
    }
    if (localStorage.getItem('analyticState')) {
      this.isAnalyticStateFilter = localStorage.getItem('analyticState');
      this.filterData.state = null;
    }
    if (localStorage.getItem('preAnalyticState')) {
      this.isAnalyticStateFilter = localStorage.getItem('preAnalyticState');
      this.filterData.state = null;
    }
    if (localStorage.getItem('analyticDiv')) {
      var arrayDiv = localStorage.getItem('analyticDiv');
      this.isAnalyticDivFilter = JSON.parse("[" + arrayDiv + "]");
      this.filterData.div = null;
    }
    this.formInitialize();
    this.someElement = document.getElementById("theme-settings");
    this.someElement.classList.add("open");
    this.amtQuery = {
      "p": "totOfTotal1",
      "o": null,
      "v": null
    }
    this.issueGst = this.isGST ? ISSUE_GST : PREGST_ISSUE_DROPDOWN
    if (localStorage.getItem('entityType') == 'BUSINESS' || localStorage.getItem('entityType') == 'FILING') {
      this.regGstinNum = localStorage.getItem('filterRegNum');
      this.regGstinData = this.regGstinNum.split(',');
    } else if (localStorage.getItem('entityType') == 'LEGAL') {
      this.regGstinNum = localStorage.getItem('filterLegalRegNum');
      this.regGstinData = this.regGstinNum.split(',');
    }
    this.preGstregGstinNum = localStorage.getItem('filterPreGstRegNum');
    this.preGstregGstinData = this.preGstregGstinNum.split(',');
    this.getLegalRegNum();
  }
  formInitialize() {
    this.filterForm = this.fb.group({
      pf1: [this.filterData?.pf1],
      pf2: [this.filterData?.pf2],
      pt1: [this.filterData?.pt1],
      pt2: [this.filterData?.pt2],
      dd1: [this.filterData?.dd1],
      dd2: [this.filterData?.dd2],
      kw: [this.filterData?.kw],
      pr: [this.filterData?.pr],
      st: [this.filterData?.st],
      caseId: [this.filterData?.caseId],
      caseLabel: [this.filterData?.caseLabel],
      consultant: [this.filterData?.consultant],
      amtQuery: [this.filterData.amtQuery ? this.filterData.amtQuery.v : null],
      issue: [this.filterData?.issue ? this.filterData?.issue : this.isAnalyticIssueFilter],
      div: [this.filterData?.div ? this.filterData?.div : this.isAnalyticDivFilter],
      act: [this.filterData?.act],
      state: [this.filterData?.state ? this.filterData?.state : this.isAnalyticStateFilter],
      regNo: [this.filterData?.regNo],
      isAnalyticIssueFilter: [this.filterData?.isAnalyticIssueFilter],
      isAnalyticStateFilter: [this.filterData?.isAnalyticStateFilter],
      isAnalyticDivFilter: [this.filterData?.isAnalyticDivFilter],
      showAcc: [true],
    });
  }

  sendFilter(isDataReset: any = false) {
    if ((this.filterForm.value.issue != null)) {
      localStorage.setItem('analyticIssueLbl', '');
    }
    if ((this.filterForm.value.issue != null) && this.isGST) {
      localStorage.setItem('preAnalyticIssueLbl', '');
    }
    if ((this.filterForm.value.state != null)) {
      localStorage.setItem('analyticState', '');
    }
    if ((this.filterForm.value.state != null) && this.isGST) {
      localStorage.setItem('preAnalyticState', '');
    }
    if ((this.filterForm.value.div != null)) {
      localStorage.setItem('analyticDiv', '');
    }

    if (!isDataReset) {
      this.filterForm.get('amtQuery')?.patchValue(this.amtQuery);
    }
    else {
      this.amtQuery = {
        "p": "totOfTotal1",
        "o": null,
        "v": null
      }
    }
    let trimdata = this.dataTransform();
    if (trimdata) {
      if (this.filterForm.value.caseId) {
        this.filterForm.value.caseId = this.filterForm.value.caseId.split(',')
      }
      //   const refactoredAct = this.filterForm.value.act.map((act: any) => act.value)
      // this.filterForm.value.act = refactoredAct
      if (this.filterForm.value.act === null) {

      } else {
        const refactoredAct = this.filterForm.value.act.map((act: any) => act.value)
        this.filterForm.value.act = refactoredAct
      }
      let eventData = { event: 'applyFilter', data: this.filterForm.value };
      this.messageEvent.emit(eventData);
      if (!isDataReset) this.filterForm.get('amtQuery')?.setValue(this.amtQuery.v)

    }
  }
  dataTransform() {
    if (this.filterForm.value.caseId) {
      this.filterForm.value.caseId = this.filterForm.value.caseId.trim();
    }
    if (this.filterForm.value.caseLabel) {
      this.filterForm.value.caseLabel = this.filterForm.value.caseLabel.trim();
    }
    if (this.filterForm.value.consultant) {
      this.filterForm.value.consultant = this.filterForm.value.consultant.trim();
    }
    if (this.filterForm.value.kw) {
      this.filterForm.value.kw = this.filterForm.value.kw.trim();
    }

    return this.filterForm.value;

  }

  closeDiv() {
    let eventData = { event: 'closeFilter' };
    this.messageEvent.emit(eventData);
    this.someElement = document.getElementById("theme-settings");
    this.someElement.classList.remove("open");

  }
  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }
  reset() {
    if (this.isAnalyticIssueFilter) {
      this.isAnalyticIssueFilter = null;
      localStorage.setItem('analyticIssueLbl', '');
      this.filterForm.get('issue')?.patchValue(null);
    }
    if (this.isAnalyticIssueFilter) {
      this.isAnalyticIssueFilter = null;
      localStorage.setItem('preAnalyticIssueLbl', '');
      this.filterForm.get('issue')?.patchValue(null);
    }
    if (this.isAnalyticStateFilter) {
      this.isAnalyticStateFilter = null;
      localStorage.setItem('analyticState', '');
      this.filterForm.get('state')?.patchValue(null);
    }
    if (this.isAnalyticStateFilter) {
      this.isAnalyticStateFilter = null;
      localStorage.setItem('preAnalyticState', '');
      this.filterForm.get('state')?.patchValue(null);
    }
    if (this.isAnalyticDivFilter) {
      this.isAnalyticDivFilter = null;
      localStorage.setItem('analyticDiv', '');
      this.filterForm.get('div')?.patchValue(null);
    }
    this.filterForm.reset();
    this.filterData = this.filterForm.value;
    this.formInitialize();
    this.sendFilter(true);
    //this.closeDiv();
    localStorage.setItem('clearAnalytic', 'true');

  }

  // get pr(): any {
  //   return this.filterForm.get('pr');
  // }
  resetControl(param: any) {
    this.filterForm.get(`${param}`)?.patchValue(null);
    if (this.isAnalyticIssueFilter != null && this.isAnalyticStateFilter == null && this.isAnalyticDivFilter == null) {
      this.isAnalyticIssueFilter = null;
      localStorage.setItem('analyticIssueLbl', '');
      this.filterForm.get('issue')?.patchValue(null);
    } else if (this.isAnalyticIssueFilter != null && this.isAnalyticStateFilter == null && this.isAnalyticDivFilter == null) {
      this.isAnalyticIssueFilter = null;
      localStorage.setItem('preAnalyticIssueLbl', '');
      this.filterForm.get('issue')?.patchValue(null);
    } else if (this.isAnalyticIssueFilter != null && this.isAnalyticStateFilter != null && this.isAnalyticDivFilter == null) {
      this.isAnalyticStateFilter = null;
      localStorage.setItem('analyticState', '');
      this.filterForm.get('state')?.patchValue(null);
    } else if (this.isAnalyticIssueFilter != null && this.isAnalyticStateFilter != null && this.isAnalyticDivFilter == null) {
      this.isAnalyticStateFilter = null;
      localStorage.setItem('preAnalyticState', '');
      this.filterForm.get('state')?.patchValue(null);
    }
    else if (this.isAnalyticIssueFilter != null && this.isAnalyticStateFilter != null && this.isAnalyticDivFilter != null) {
      this.isAnalyticDivFilter = null;
      localStorage.setItem('analyticDiv', '');
      this.filterForm.get('div')?.patchValue(null);
    }

  }
  personResponsibleData() {
    let model: any = {};
    if (this.navContext.entityType === 'FILING') {
      model.gstin = this.selectedGstin;
    } else if (this.navContext.entityType === 'BUSINESS' || this.navContext.entityType === 'LEGAL') {
      model.companyId = this.selectedCompanyId
    }
    this.litService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  personResponsiblePregstData() {
    let model = {
      companyid: this.selectedCompanyId
    }
    this.preLitService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRole = response.response;
        //console.log(this.userRole,"userRole");
      }
    });
  }

  nextWeek() {
    let today = new Date();
    this.nextWeekDt = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7);
    this.nxtDtdd1 = today;
    this.nxtDtdd2 = this.nextWeekDt;
    this.filterForm.get('dd1')?.setValue(this.nxtDtdd1);
    this.filterForm.get('dd2')?.setValue(this.nxtDtdd2);

  }
  nextMonth() {
    let today = new Date();
    this.nextMonthDt = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 30);
    this.nxtMonthdd1 = today;
    this.nxtMonthdd2 = this.nextMonthDt;
    this.filterForm.get('dd1')?.setValue(this.nxtMonthdd1);
    this.filterForm.get('dd2')?.setValue(this.nxtMonthdd2);

  }
  custom() {
    this.reset();
  }

  setDivisionData() {
    this.businessService.getGstinDiv().subscribe((response: any) => {
      if (response.status === 1) {
        this.division = response?.response?.divList
      }
    })
  }

  demandAmtOptChanged(event: any) {
    this.amtQuery['o'] = event.target.value;
  }

  demandAmtValueChanged() {
    this.amtQuery['v'] = this.filterForm?.get('amtQuery')?.value;
  }
  getLegalRegNum() {
    const navContext = JSON.parse(this.GS.getNavContextData() || '{}');
    let model = {
      companyId: localStorage.getItem('selectedId'),
      userMailId: this.selectedUserId
    }
    this.entityService.getLegalRegNum(model).subscribe((response: any) => {
      if (response.status == 'SUCCESS') {
        this.leagalRegNum = response.response;
        //console.log('skkkLegal', this.leagalRegNum)
        localStorage.setItem('filterLegalRegNum', this.leagalRegNum);
      }
    })
  }

  onItemSelect(item: any) {
    console.log(item);
    // this.watchlistForm.get('actTypes')?.setValue(item.value)
  }
  onSelectAll(items: any) {
    console.log(items);
  }

}
