<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <p class="card-title">Smart Upload</p>
              <div class="tab-content nopadding">
                <form [formGroup]="form">
                  <div class="col-md-3">
                    <div class="form-group">
                      <label for="exampleInputPassword1">Upload PDF Notice/Letter
                        <a class="downupIcon" *ngIf="isUPpanCompLoc" title="Upload file" (click)="uploadpanCompLoc()">
                          <i class="fa fa-upload"></i>
                        </a>
                      </label>
                      <input type="file" [hidden]="!ispanCompLocFile" id="letterLoc" class="filetype form-control"
                        name="myfile" (change)='onFileSelect($event)'>
                      <p [hidden]="!ispanCompLocText" class="form-control pt10">
                        {{ getFileNameDta(formControls.letterLoc) }}
                      </p>
                    </div>
                  </div>

                  <div class="row" *ngIf="progressStatus === 'analysing'">
                    <div class="inProgDiv">
                      <div class="">
                        Analysing
                        <span class="dot-opacity-loader">
                          <span></span>
                          <span></span>
                          <span></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="progressStatus === 'in progress'">
                    <div class="inProgDiv">
                      <div class="">
                        IN PROGRESS
                        <span class="dot-opacity-loader">
                          <span></span>
                          <span></span>
                          <span></span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="showfooter">
                    <div class="footerBottom">
                    </div>
                  </div>

                  <div class="row" *ngIf="progressStatus === 'complete'">
                    <div class="col-md-6">
                      <embed [src]="selectedFileUrl" class="w100" style="height:100%;" />
                    </div>
                    <div class="col-md-6">
                      <div class="card borderOrange shadow">
                        <div class="card-body">
                          <fieldset>
                            <div class="row">
                              <h6 class="header">GSTIN Details</h6>
                              <div class="row">
                                <div class="col-md-4" *ngIf="isCaseCreated">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1">Case ID <span class="mandate"><i
                                          class="fa fa-asterisk"></i></span></label>
                                    <input type="text" class="form-control" id="caseId" readonly
                                      formControlName="caseId">

                                  </div>

                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">GSTIN</span><span
                                        class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                    <input type="text" class="form-control" id="gstin" placeholder=""
                                      [ngClass]="{ 'errorBorder': isSubmitted && form.controls.gstin.value === '' }"
                                      formControlName="gstin" [title]="form.value.gstin">
                                  </div>
                                  <div *ngIf="isSubmitted && form.controls.gstin.value === ''">
                                    <span class="text-danger" *ngIf="form.controls.gstin.value === ''"> Required
                                    </span>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">State</span><span
                                        class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                    <input type="text" class="form-control" id="refNum" placeholder=""
                                      [ngClass]="{ 'errorBorder': isSubmitted && form.controls.state.value === '' }"
                                      formControlName="state" [title]="form.value.state">
                                  </div>
                                  <div *ngIf="isSubmitted && form.controls.state.value === ''">
                                    <span class="text-danger" *ngIf="form.controls.state.value === ''"> Required
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1">Person responsible <span class="mandate"><i
                                          class="fa fa-asterisk"></i></span></label>
                                    <select class="form-control form-select" id="personResponsible"
                                      [ngClass]="{ 'errorBorder': isSubmitted && form.controls.personResponsible.value === '' }"
                                      *ngIf="navContext?.entityType == 'FILING'" formControlName="personResponsible">
                                      <option [value]="null">-- Select --</option>
                                      <ng-container *ngFor="let obj of userRoleLs">
                                        <option [value]="obj.userEmailId">{{obj.userEmailId}}</option>
                                      </ng-container>
                                    </select>
                                    <input type="text" class="form-control"
                                      *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'"
                                      placeholder="" formControlName="personResponsible">
                                    <!-- <div *ngIf="isSubmitted && p1.personResponsible.errors">
                      <span class="text-danger" *ngIf="p1.personResponsible.errors.required"> Required
                      </span>
                    </div> -->
                                  </div>
                                  <div *ngIf="isSubmitted && form.controls.personResponsible.value === ''">
                                    <span class="text-danger" *ngIf="form.controls.personResponsible.value === ''">
                                      Required
                                    </span>
                                  </div>
                                </div>

                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"> <span class="label">Case Label</span><span
                                        class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                    <textarea class="form-control" id="case_label" placeholder="" rows="3"
                                      [ngClass]="{ 'errorBorder': isSubmitted && form.controls.case_label.value === '' }"
                                      formControlName="case_label" [title]="form.value.case_label"></textarea>
                                  </div>
                                  <div *ngIf="isSubmitted && form.controls.case_label.value === ''">
                                    <span class="text-danger" *ngIf="form.controls.case_label.value === ''"> Required
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <h6 class="headers"> Notice / Details</h6>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1 "><span class="label">Letter Reference
                                        Number</span><span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                    <input type="text" class="form-control" id="ref_number" placeholder=""
                                      [ngClass]="{ 'errorBorder': isSubmitted && form.controls.ref_number.value === '' }"
                                      formControlName="ref_number" [title]="form.value.ref_number">
                                  </div>
                                  <div *ngIf="isSubmitted && form.controls.ref_number.value === ''">
                                    <span class="text-danger" *ngIf="form.controls.ref_number.value === ''"> Required
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1">Type of Issuing Authority <span
                                        class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                    <select class="form-control form-select"
                                      [ngClass]="{ 'errorBorder': isSubmitted && (form.controls.typeOfAuthority.value === '' || form.controls.typeOfAuthority.value === undefined) }"
                                      id="authority" formControlName="typeOfAuthority">
                                      <!-- [ngClass]="{ 'errorBorder': isSubmitted && div.get('typeOfAuthority')?.errors }"> -->
                                      <option [value]="null">-- Select --</option>
                                      <ng-container *ngFor="let obj of preLitPH1Preceded ">
                                        <option [value]="obj.value">{{obj.label}}</option>
                                      </ng-container>
                                    </select>

                                  </div>
                                  <div
                                    *ngIf="isSubmitted && (form.controls.typeOfAuthority.value && form.controls.typeOfAuthority.value === '' || form.controls.typeOfAuthority.value === undefined)">
                                    <span class="text-danger"
                                      *ngIf="isSubmitted && (form.controls.typeOfAuthority.value && form.controls.typeOfAuthority.value === '' || form.controls.typeOfAuthority.value === undefined)">
                                      Required
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label"> DIN </span></label>
                                    <input type="text" class="form-control" id="section" placeholder=""
                                      [title]="form.value.din" formControlName="din">
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1">Mode of Receipt of Notice</label>
                                    <select class="form-control form-select" formControlName="orderReceiptMode">
                                      <option [value]="null">-- Select --</option>
                                      <ng-container *ngFor="let obj of modeOfReceiptNotice">
                                        <option [value]="obj.value">{{obj.value}}</option>
                                      </ng-container>
                                    </select>

                                  </div>

                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Date Of Letter</span><span
                                        class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                    <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                      <p-calendar class="form-control"
                                        [ngClass]="{ 'errorBorder': isSubmitted && form.controls.notice_date.value === '' }"
                                        formControlName="notice_date" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                        dataType="string" [maxDate]="maxDate" [title]="form.value.notice_date">
                                      </p-calendar>
                                    </div>
                                  </div>
                                  <div *ngIf="isSubmitted && form.controls.notice_date.value === ''">
                                    <span class="text-danger" *ngIf="form.controls.notice_date.value === ''"> Required
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Date Of Receipt of
                                        Notice</span><span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                    <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                      <p-calendar class="form-control"
                                        [ngClass]="{ 'errorBorder': isSubmitted && form.controls.notice_date.value === '' }"
                                        formControlName="receipt_notice_date" [monthNavigator]="true"
                                        [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                                        [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                        [title]="form.value.notice_date">
                                      </p-calendar>
                                    </div>
                                  </div>
                                  <div *ngIf="isSubmitted && form.controls.notice_date.value === ''">
                                    <span class="text-danger" *ngIf="form.controls.notice_date.value === ''"> Required
                                    </span>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Issued U/s</span></label>
                                    <input type="text" class="form-control" id="section" placeholder=""
                                      formControlName="section" [title]="form.value.section">
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Type Of
                                        Form/Notice</span></label>
                                    <input type="text" class="form-control" id="notice_type" placeholder=""
                                      formControlName="notice_type" [title]="form.value.notice_type">
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Period From</span><span
                                        class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                    <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                      <p-calendar class="form-control"
                                        [ngClass]="{ 'errorBorder': isSubmitted && form.controls.from_period.value === '' }"
                                        formControlName="from_period" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                        dataType="string" [maxDate]="maxDate" [title]="form.value.from_period">
                                      </p-calendar>
                                    </div>
                                  </div>
                                  <div *ngIf="isSubmitted && form.controls.from_period.value === ''">
                                    <span class="text-danger" *ngIf="form.controls.from_period.value === ''"> Required
                                    </span>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Period To</span><span
                                        class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                    <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                      <p-calendar class="form-control"
                                        [ngClass]="{ 'errorBorder': isSubmitted && form.controls.to_period.value === '' }"
                                        formControlName="to_period" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                        dataType="string" [maxDate]="maxDate" [title]="form.value.to_period">
                                      </p-calendar>
                                    </div>
                                  </div>
                                  <div *ngIf="isSubmitted && form.controls.to_period.value === ''">
                                    <span class="text-danger" *ngIf="form.controls.to_period.value === ''"> Required
                                    </span>
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Legal Due Date</span><span
                                        class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                    <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                      <p-calendar class="form-control"
                                        [ngClass]="{ 'errorBorder': isSubmitted && form.controls.due_date.value === '' }"
                                        formControlName="due_date" [monthNavigator]="true" [yearNavigator]="true"
                                        yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                        dataType="string" [maxDate]="maxDate" [title]="form.value.due_date">
                                      </p-calendar>
                                    </div>
                                  </div>
                                  <div *ngIf="isSubmitted && form.controls.due_date.value === ''">
                                    <span class="text-danger" *ngIf="form.controls.due_date.value === ''"> Required
                                    </span>
                                  </div>
                                </div>


                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Summary of the
                                        correspondence</span></label>
                                    <textarea class="form-control" id="summary" placeholder=""
                                      [title]="form.value.summary" rows="3" formControlName="summary"></textarea>
                                  </div>
                                </div>

                              </div>

                              <h6 class="headers">Details of Issuing Authority</h6>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label"> Name of the
                                        Officer</span></label>
                                    <input type="text" class="form-control" id="section" placeholder=""
                                      [title]="form.value.officer" formControlName="officer">
                                  </div>
                                </div>


                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Designation</span> </label>
                                    <input type="text" class="form-control" id="designation" placeholder=""
                                      formControlName="designation" [title]="form.value.designation">
                                  </div>
                                </div>

                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Jurisdiction</span></label>
                                    <input type="text" class="form-control" id="jurisdiction" placeholder=""
                                      formControlName="jurisdiction" [title]="form.value.jurisdiction">
                                  </div>
                                </div>

                                <div class="col-md-8">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Address</span></label>
                                    <input type="text" class="form-control" id="address" placeholder=""
                                      formControlName="address" [title]="form.value.address">
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Case Summary</span></label>
                                    <textarea class="form-control" id="summary" placeholder=""
                                      [title]="form.value.caseSummary" rows="3"
                                      formControlName="caseSummary"></textarea>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label for="exampleInputPassword1"><span class="label">Internal
                                        Remarks</span></label>
                                    <textarea class="form-control" id="summary" placeholder=""
                                      [title]="form.value.remark" rows="3" formControlName="remark"></textarea>
                                  </div>
                                </div>
                              </div>
                              <!-- <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exampleInputPassword1"><span class="label">Contact No.</span> </label>
                    <input type="text" class="form-control"  placeholder="" formControlName="contact">
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="exampleInputPassword1"><span class="label">E-mail ID</span></label>
                    <input type="text" class="form-control" id="jurisdiction" placeholder="" formControlName="email" >
                  </div>
                </div>
              </div> -->

                            </div>
                          </fieldset>
                        </div>
                        <div class="btn">
                          <button class="btn btn-outline-primary" (click)="createCase()">Create</button>
                        </div>
                        <div class="btn" *ngIf="isCaseCreated">
                          <button class="btn btn-outline-primary" (click)="navigateToSummary()">Go To Summary</button>
                        </div>
                      </div>
                    </div>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
