import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, UntypedFormArray } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { AuditService } from '../service/audit.service';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { VALID_FILE_TYPE } from 'src/app/shared/constant';

@Component({
  selector: 'app-audit-recon',
  templateUrl: './audit-recon.component.html',
  styleUrls: ['./audit-recon.component.css']
})
export class AuditReconComponent implements OnInit, OnDestroy {
  navContext = { entityName: '', isTaxPayer: false, entityType: '', pan: '', gstin: '' };
  auditReconForm!: UntypedFormGroup;
  fileUrl: any;
  selectedField: any;
  selectedFile: any;
  matches: any;
  role: any;
  selectedGstin: any;
  selectedFilling: any;
  auditReconData: any = {};
  financialYear: any;
  gstin: any;
  finYear: any;
  savedReconId: any;
  finyearData: any[] = [];
  formData: FormData = new FormData();
  isSubmitted: boolean = false;
  isUpdateBtn: boolean = false;
  finYearReq: boolean = false;
  validFileTypeList: string[] = [];
  fileType: any;

  constructor(
    private fBuild: UntypedFormBuilder,
    private auditService: AuditService,
    private toaster: ToasterService,
    private GS: GetterSetterService,
    private http: HttpClient,
    private modalService: NgbModal,
    private US: UtilityService
  ) {
    this.validFileTypeList = VALID_FILE_TYPE;
  }

  ngOnInit() {
    this.role = localStorage.getItem('roleName');
    this.finyearData = this.US.getYears();
    this.intializeFormAndData();
  }

  formInitialize() {
    this.auditReconForm = this.fBuild.group({
      reconId: [this.auditReconData?.reconId],
      finYear: [this.auditReconData?.finYear],
      glTiedUpWithSalesRegLoc: new UntypedFormArray([]),
      glTiedUpWithSalesRegRemark: [this.auditReconData?.glTiedUpWithSalesRegRemark],
      salesRegTiedUpWithGstr1Loc: new UntypedFormArray([]),
      salesRegTiedUpWithGstr1Remark: [this.auditReconData?.salesRegTiedUpWithGstr1Remark],
      gstr1TiedUpWithGstr9And9CLoc: new UntypedFormArray([]),
      gstr1TiedUpWithGstr9And9CRemark: [this.auditReconData?.gstr1TiedUpWithGstr9And9CRemark],
      gstr1LibWithGstr3bLoc: new UntypedFormArray([]),
      gstr1LibWithGstr3bRemark: [this.auditReconData?.gstr1LibWithGstr3bRemark],
      gstr3bLinWithGlLoc: new UntypedFormArray([]),
      gstr3bLinWithGlRemark: [this.auditReconData?.gstr3bLinWithGlRemark],
      gstr3bLibWithGstr9And9CLoc: new UntypedFormArray([]),
      gstr3bLibWithGstr9And9CRemark: [this.auditReconData?.gstr3bLibWithGstr9And9CRemark],
      rcmLibRecoLoc: new UntypedFormArray([]),
      rcmLibRecoRemark: [this.auditReconData?.rcmLibRecoRemark],
      gstLibOnAdvancesLoc: new UntypedFormArray([]),
      gstLibOnAdvancesRemark: [this.auditReconData?.gstLibOnAdvancesRemark],
      amendmentsLoc: new UntypedFormArray([]),
      amendmentsRemark: [this.auditReconData?.amendmentsRemark],
      itcAsPerGlLoc: new UntypedFormArray([]),
      itcAsPerGlRemark: [this.auditReconData?.itcAsPerGlRemark],
      itcAsPerPurRegLoc: new UntypedFormArray([]),
      itcAsPerPurRegRemark: [this.auditReconData?.itcAsPerPurRegRemark],
      gstr3bTiedUpGstr2aLoc: new UntypedFormArray([]),
      gstr3bTiedUpGstr2aRemark: [this.auditReconData?.gstr3bTiedUpGstr2aRemark],
      itcAsPerGstr3bLoc: new UntypedFormArray([]),
      itcAsPerGstr3bRemark: [this.auditReconData?.itcAsPerGstr3bRemark],
      reversalsCompLoc: new UntypedFormArray([]),
      reversalsCompRemark: [this.auditReconData?.reversalsCompRemark],
      turnoverLoc: new UntypedFormArray([]),
      turnoverRemark: [this.auditReconData?.turnoverRemark],
      isdCreditDistLoc: new UntypedFormArray([]),
      isdCreditDistRemark: [this.auditReconData?.isdCreditDistRemark],
      salesInvEtcLoc: new UntypedFormArray([]),
      salesInvEtcRemark: [this.auditReconData?.salesInvEtcRemark],
      purchaseInvEtcLoc: new UntypedFormArray([]),
      purchaseInvEtcRemark: [this.auditReconData?.purchaseInvEtcRemark],
      monRetGstr1And3bLoc: new UntypedFormArray([]),
      monRetGstr1And3bRemark: [this.auditReconData?.monRetGstr1And3bRemark],
      annRetGstr9And9cLoc: new UntypedFormArray([]),
      annRetGstr9And9cRemark: [this.auditReconData?.annRetGstr9And9cRemark],
      speRetGstr6AndItc04Loc: new UntypedFormArray([]),
      speRetGstr6AndItc04Remark: [this.auditReconData?.speRetGstr6AndItc04Remark],
      transCreditEtcLoc: new UntypedFormArray([]),
      transCreditEtcRemark: [this.auditReconData?.transCreditEtcRemark],
      ledgersEtcLoc: new UntypedFormArray([]),
      ledgersEtcRemark: [this.auditReconData?.ledgersEtcRemark],
      refundAppEtcLoc: new UntypedFormArray([]),
      refundAppEtcRemark: [this.auditReconData?.refundAppEtcRemark],
      challansEtcLoc: new UntypedFormArray([]),
      challansEtcRemark: [this.auditReconData?.challansEtcRemark],
      interestPayDetailsLoc: new UntypedFormArray([]),
      interestPayDetailsRemark: [this.auditReconData?.interestPayDetailsRemark],
      penaltyPayDetailsLoc: new UntypedFormArray([]),
      penaltyPayDetailsRemark: [this.auditReconData?.penaltyPayDetailsRemark],
      dueDateVsDateOfFilingLoc: new UntypedFormArray([]),
      dueDateVsDateOfFilingRemark: [this.auditReconData?.dueDateVsDateOfFilingRemark],
    });
  }

  initFileFrmGrp() {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl('', []),
      fileName: new UntypedFormControl('', []),
      remarks: new UntypedFormControl('', [Validators.maxLength(100)]),
    });
  }

  getUploadFileLoc(): UntypedFormGroup[] {
    return (this.auditReconForm.get(this.fileType) as UntypedFormArray).controls as UntypedFormGroup[];
  }

  intializeFormAndData() {
    this.auditReconData.finYear = localStorage.getItem('selected_recon_year');
    this.formInitialize();
    this.auditService.selectedFilling$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((data) => {
      if (data) {
        this.selectedFilling = data;
        this.selectedGstin = this.selectedFilling.gstin;
        this.GS.checkEntityType.subscribe((value) => {
          this.navContext = value;
          this.finYear = localStorage.getItem('selected_recon_year') ? localStorage.getItem('selected_recon_year') : this.finyearData[0];
          this.getReconData({ gstin: this.getGstinOrPan(), finYear: this.finYear });
        });
      }
    });
  }

  getGstinOrPan() {
    return this.navContext && this.navContext.entityType === 'FILING' ? this.navContext.gstin : this.navContext.pan;
  }

  getFileLength(type: string) {
    let fileFrmArray = this.auditReconForm.get(type) as UntypedFormArray;
    let filteredList = [];
    if (fileFrmArray.value.length > 0) {
      filteredList = fileFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    }
    return filteredList.length ? filteredList.length : 0;
  }


  openDetailPop(content: any, fileType: any) {
    this.fileType = fileType;
    // this.addUploadFileDiv(fileType, 0);
    this.modalService.open(content);
  }



  /*upload file function */
  onFileSelect(event: any) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'zip', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField);
      } else {
        this.selectedFile = null;
        this.toaster.showSuccess('This file type is not supported')
      }
    } else {
      this.selectedFile = null;
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  onUpload(selectedfield: any) {
    let urlData = {
      reconId: this.savedReconId,
      type: 'recon',
      field: selectedfield,
      contentType: this.selectedFile.type
    }
    this.auditService.uploadReconFile(this.selectedFile, urlData).subscribe((response: any) => {
      if (response.status == 1 && response.response !== null) {
        this.getFileUrl(response.response.url, response.response.path, selectedfield);
      } else {
      }
    })
  }

  getFileUrl(url: any, path: any, selectedfield: any) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      console.log("response receved is ", response);
      if (selectedfield) {
        var toupdate = this.auditReconForm.value;
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.auditReconForm.patchValue(this.auditReconForm.value);
      }
      this.toaster.showSuccess('File Uploaded Successfully');
    })
  }
  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }

  // deleteUploadFile(ItrIndex: any) {
  //   let fileListFrmArray = this.auditReconForm.get(this.fileType) as FormArray;
  //   let itemListuploadControl = fileListFrmArray.controls[ItrIndex] as FormGroup;
  //   itemListuploadControl.value['isdocLocUploadedClicked'] = true;
  //   itemListuploadControl.value['fileLoc'] = null;
  // }

  /*Manipulating File Array (removing empty file) */
  manipulateList(dataList: any) {
    for (const key in dataList) {
      let list = [];
      if (Array.isArray(dataList[key])) {
        list = dataList[key].filter((obj: any) => {
          return (obj.fileLoc !== null && obj.fileLoc !== '');
        });
        dataList[key] = list;
      }
    }
    return dataList;
  }

  /*save function */
  saveReconData() {
    this.isSubmitted = true;
    let data: any = {};
    data = this.manipulateList(this.auditReconForm.value);
    if (this.auditReconForm.valid) {
      data['gstin'] = this.getGstinOrPan();
      data['finYear'] = this.finYear;
      data['reconId'] = this.savedReconId;
      data['companyId'] = localStorage.getItem('selectedId');
      this.auditService.saveReconData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        if (response.status === 1) {
          this.getReconData({ gstin: this.getGstinOrPan(), finYear: this.finYear });
          this.toaster.showSuccess(response.message);
        } else {
          this.toaster.showSuccess(response.message);
        }
      });
    } else { this.toaster.showError('Remark Max character Length 100'); }

  }
  /*save function */


  changeYear(event: any) {
    let model: any = {};
    model.gstin = this.getGstinOrPan();
    model.finYear = event.target.value;
    localStorage.setItem('selected_recon_year', model.finYear);
    this.finYear = localStorage.getItem('selected_recon_year');
    this.getReconData(model);
  }

  getReconData(model: any) {
    this.auditService.getReconData(model).subscribe((response: any) => {
      this.auditReconData = {};
      this.savedReconId = null;
      if (response.status == 1 && response.response !== null) {
        this.auditReconData = response.response;
        this.financialYear = response.response.finYear;
        this.savedReconId = response.response.reconId;
        this.formInitialize();
        this.initializeGlTiedUpWithSalesRegLoc();
        this.initializeSalesRegTiedUpWithGstr1Loc();
        this.initializeGstr1TiedUpWithGstr9And9CLoc();
        this.initializeGstr1LibWithGstr3bLoc();
        this.initializeGstr3bLinWithGlLoc();
        this.initializeGstr3bLibWithGstr9And9CLoc();
        this.initializeRcmLibRecoLoc();
        this.initializeGstLibOnAdvancesLoc();
        this.initializeAmendmentsLoc();
        this.initializeItcAsPerGlLoc();
        this.initializeItcAsPerPurRegLoc();
        this.initializeGstr3bTiedUpGstr2aLoc();
        this.initializeItcAsPerGstr3bLoc();
        this.initializeReversalsCompLoc();
        this.initializeTurnoverLoc();
        this.initializeIsdCreditDistLoc();
        this.initializeSalesInvEtcLoc();
        this.initializePurchaseInvEtcLoc();
        this.initializeMonRetGstr1And3bLoc();
        this.initializeAnnRetGstr9And9cLoc();
        this.initializeSpeRetGstr6AndItc04Loc();
        this.initializeTransCreditEtcLoc();
        this.initializeLedgersEtcLoc();
        this.initializeRefundAppEtcLoc();
        this.initializeChallansEtcLoc();
        this.initializeInterestPayDetailsLoc();
        this.initializePenaltyPayDetailsLoc();
        this.initializeDueDateVsDateOfFilingLoc();
      } else {
        this.auditReconData.finYear = this.finYear;
        this.formInitialize();
      }
    });
  }


  /* get download file path function */
  getFileNameDta(fileUrl: any) {
    if (fileUrl)
      return this.auditService.getFileName(fileUrl);
  }
  /* get download file path function */

  /*download file function */
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.auditService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }
  ngOnDestroy() {
  }
  openGlTiedUpWithSalesRegLoc(content: any) {
    this.addGlTiedUpWithSalesRegLoc();
    this.modalService.open(content);
  }
  getGlTiedUpWithSalesRegLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('glTiedUpWithSalesRegLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getGlTiedUpWithSalesRegLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('glTiedUpWithSalesRegLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createGlTiedUpWithSalesRegLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addGlTiedUpWithSalesRegLoc() {
    let attachmentsArray = this.auditReconForm.get('glTiedUpWithSalesRegLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createGlTiedUpWithSalesRegLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createGlTiedUpWithSalesRegLoc());

        i++;
      }
    }
  }

  uploadGlTiedUpWithSalesRegLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.glTiedUpWithSalesRegLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteGlTiedUpWithSalesRegLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.glTiedUpWithSalesRegLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  openGstr1TiedUpWithGstr9And9CLoc(content: any) {
    this.addGstr1TiedUpWithGstr9And9CLoc();
    this.modalService.open(content);
  }
  getGstr1TiedUpWithGstr9And9CLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('gstr1TiedUpWithGstr9And9CLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getGstr1TiedUpWithGstr9And9CLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('gstr1TiedUpWithGstr9And9CLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createGstr1TiedUpWithGstr9And9CLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addGstr1TiedUpWithGstr9And9CLoc() {
    let attachmentsArray = this.auditReconForm.get('gstr1TiedUpWithGstr9And9CLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createGstr1TiedUpWithGstr9And9CLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createGstr1TiedUpWithGstr9And9CLoc());

        i++;
      }
    }
  }

  uploadGstr1TiedUpWithGstr9And9CLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstr1TiedUpWithGstr9And9CLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteGstr1TiedUpWithGstr9And9CLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstr1TiedUpWithGstr9And9CLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openSalesRegTiedUpWithGstr1Loc(content: any) {
    this.addSalesRegTiedUpWithGstr1Loc();
    this.modalService.open(content);
  }
  getSalesRegTiedUpWithGstr1LocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('salesRegTiedUpWithGstr1Loc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getSalesRegTiedUpWithGstr1Locc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('salesRegTiedUpWithGstr1Loc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createSalesRegTiedUpWithGstr1Loc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addSalesRegTiedUpWithGstr1Loc() {
    let attachmentsArray = this.auditReconForm.get('salesRegTiedUpWithGstr1Loc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createSalesRegTiedUpWithGstr1Loc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createSalesRegTiedUpWithGstr1Loc());

        i++;
      }
    }
  }

  uploadSalesRegTiedUpWithGstr1Loc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.salesRegTiedUpWithGstr1Loc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteSalesRegTiedUpWithGstr1Loc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.salesRegTiedUpWithGstr1Loc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openGstr1LibWithGstr3bLoc(content: any) {
    this.addGstr1LibWithGstr3bLoc();
    this.modalService.open(content);
  }
  getGstr1LibWithGstr3bLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('gstr1LibWithGstr3bLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getGstr1LibWithGstr3bLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('gstr1LibWithGstr3bLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createGstr1LibWithGstr3bLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addGstr1LibWithGstr3bLoc() {
    let attachmentsArray = this.auditReconForm.get('gstr1LibWithGstr3bLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createGstr1LibWithGstr3bLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createGstr1LibWithGstr3bLoc());

        i++;
      }
    }
  }

  uploadGstr1LibWithGstr3bLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstr1LibWithGstr3bLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteGstr1LibWithGstr3bLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstr1LibWithGstr3bLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openGstr3bLinWithGlLoc(content: any) {
    this.addGstr3bLinWithGlLoc();
    this.modalService.open(content);
  }
  getGstr3bLinWithGlLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('gstr3bLinWithGlLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getGstr3bLinWithGlLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('gstr3bLinWithGlLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createGstr3bLinWithGlLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addGstr3bLinWithGlLoc() {
    let attachmentsArray = this.auditReconForm.get('gstr3bLinWithGlLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createGstr3bLinWithGlLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createGstr3bLinWithGlLoc());

        i++;
      }
    }
  }

  uploadGstr3bLinWithGlLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstr3bLinWithGlLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteGstr3bLinWithGlLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstr3bLinWithGlLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openGstr3bLibWithGstr9And9CLoc(content: any) {
    this.addGstr3bLibWithGstr9And9CLoc();
    this.modalService.open(content);
  }
  getGstr3bLibWithGstr9And9CLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('gstr3bLibWithGstr9And9CLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getGstr3bLibWithGstr9And9CLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('gstr3bLibWithGstr9And9CLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createGstr3bLibWithGstr9And9CLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addGstr3bLibWithGstr9And9CLoc() {
    let attachmentsArray = this.auditReconForm.get('gstr3bLibWithGstr9And9CLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createGstr3bLibWithGstr9And9CLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createGstr3bLibWithGstr9And9CLoc());

        i++;
      }
    }
  }

  uploadGstr3bLibWithGstr9And9CLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstr3bLibWithGstr9And9CLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteGstr3bLibWithGstr9And9CLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstr3bLibWithGstr9And9CLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }




  openRcmLibRecoLoc(content: any) {
    this.addRcmLibRecoLoc();
    this.modalService.open(content);
  }
  getRcmLibRecoLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('rcmLibRecoLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getRcmLibRecoLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('rcmLibRecoLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createRcmLibRecoLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addRcmLibRecoLoc() {
    let attachmentsArray = this.auditReconForm.get('rcmLibRecoLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createRcmLibRecoLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createRcmLibRecoLoc());

        i++;
      }
    }
  }

  uploadRcmLibRecoLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.rcmLibRecoLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteRcmLibRecoLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.rcmLibRecoLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openGstLibOnAdvancesLoc(content: any) {
    this.addGstLibOnAdvancesLoc();
    this.modalService.open(content);
  }
  getGstLibOnAdvancesLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('gstLibOnAdvancesLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getGstLibOnAdvancesLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('gstLibOnAdvancesLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createGstLibOnAdvancesLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addGstLibOnAdvancesLoc() {
    let attachmentsArray = this.auditReconForm.get('gstLibOnAdvancesLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createGstLibOnAdvancesLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createGstLibOnAdvancesLoc());

        i++;
      }
    }
  }

  uploadGstLibOnAdvancesLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstLibOnAdvancesLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteGstLibOnAdvancesLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstLibOnAdvancesLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openAmendmentsLoc(content: any) {
    this.addAmendmentsLoc();
    this.modalService.open(content);
  }
  getAmendmentsLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('amendmentsLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getAmendmentsLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('amendmentsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createAmendmentsLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addAmendmentsLoc() {
    let attachmentsArray = this.auditReconForm.get('amendmentsLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createAmendmentsLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createAmendmentsLoc());

        i++;
      }
    }
  }

  uploadAmendmentsLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.amendmentsLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAmendmentsLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.amendmentsLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openItcAsPerGlLoc(content: any) {
    this.addItcAsPerGlLoc();
    this.modalService.open(content);
  }
  getItcAsPerGlLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('itcAsPerGlLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getItcAsPerGlLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('itcAsPerGlLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createItcAsPerGlLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addItcAsPerGlLoc() {
    let attachmentsArray = this.auditReconForm.get('itcAsPerGlLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createItcAsPerGlLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createItcAsPerGlLoc());

        i++;
      }
    }
  }

  uploadItcAsPerGlLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.itcAsPerGlLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteItcAsPerGlLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.itcAsPerGlLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openItcAsPerPurRegLoc(content: any) {
    this.addItcAsPerPurRegLoc();
    this.modalService.open(content);
  }
  getItcAsPerPurRegLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('itcAsPerPurRegLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getItcAsPerPurRegLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('itcAsPerPurRegLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createItcAsPerPurRegLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addItcAsPerPurRegLoc() {
    let attachmentsArray = this.auditReconForm.get('itcAsPerPurRegLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createItcAsPerPurRegLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createItcAsPerPurRegLoc());

        i++;
      }
    }
  }

  uploadItcAsPerPurRegLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.itcAsPerPurRegLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteItcAsPerPurRegLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.itcAsPerPurRegLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openGstr3bTiedUpGstr2aLoc(content: any) {
    this.addGstr3bTiedUpGstr2aLoc();
    this.modalService.open(content);
  }
  getGstr3bTiedUpGstr2aLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('gstr3bTiedUpGstr2aLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getGstr3bTiedUpGstr2aLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('gstr3bTiedUpGstr2aLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createGstr3bTiedUpGstr2aLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addGstr3bTiedUpGstr2aLoc() {
    let attachmentsArray = this.auditReconForm.get('gstr3bTiedUpGstr2aLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createGstr3bTiedUpGstr2aLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createGstr3bTiedUpGstr2aLoc());

        i++;
      }
    }
  }

  uploadGstr3bTiedUpGstr2aLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstr3bTiedUpGstr2aLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteGstr3bTiedUpGstr2aLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.gstr3bTiedUpGstr2aLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openItcAsPerGstr3bLoc(content: any) {
    this.addItcAsPerGstr3bLoc();
    this.modalService.open(content);
  }
  getItcAsPerGstr3bLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('itcAsPerGstr3bLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getItcAsPerGstr3bLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('itcAsPerGstr3bLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createItcAsPerGstr3bLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addItcAsPerGstr3bLoc() {
    let attachmentsArray = this.auditReconForm.get('itcAsPerGstr3bLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createItcAsPerGstr3bLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createItcAsPerGstr3bLoc());

        i++;
      }
    }
  }

  uploadItcAsPerGstr3bLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.itcAsPerGstr3bLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteItcAsPerGstr3bLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.itcAsPerGstr3bLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openReversalsCompLoc(content: any) {
    this.addReversalsCompLoc();
    this.modalService.open(content);
  }
  getReversalsCompLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('reversalsCompLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getReversalsCompLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('reversalsCompLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createReversalsCompLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addReversalsCompLoc() {
    let attachmentsArray = this.auditReconForm.get('reversalsCompLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createReversalsCompLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createReversalsCompLoc());

        i++;
      }
    }
  }

  uploadReversalsCompLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.reversalsCompLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteReversalsCompLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.reversalsCompLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openTurnoverLoc(content: any) {
    this.addTurnoverLoc();
    this.modalService.open(content);
  }
  getTurnoverLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('turnoverLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getTurnoverLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('turnoverLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createTurnoverLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addTurnoverLoc() {
    let attachmentsArray = this.auditReconForm.get('turnoverLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createTurnoverLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createTurnoverLoc());

        i++;
      }
    }
  }

  uploadTurnoverLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.turnoverLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteTurnoverLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.turnoverLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  openIsdCreditDistLoc(content: any) {
    this.addIsdCreditDistLoc();
    this.modalService.open(content);
  }
  getIsdCreditDistLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('isdCreditDistLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getIsdCreditDistLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('isdCreditDistLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createIsdCreditDistLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addIsdCreditDistLoc() {
    let attachmentsArray = this.auditReconForm.get('isdCreditDistLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createIsdCreditDistLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createIsdCreditDistLoc());

        i++;
      }
    }
  }

  uploadIsdCreditDistLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.isdCreditDistLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteIsdCreditDistLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.isdCreditDistLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openSalesInvEtcLoc(content: any) {
    this.addSalesInvEtcLoc();
    this.modalService.open(content);
  }
  getSalesInvEtcLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('salesInvEtcLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getSalesInvEtcLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('salesInvEtcLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createSalesInvEtcLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addSalesInvEtcLoc() {
    let attachmentsArray = this.auditReconForm.get('salesInvEtcLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createSalesInvEtcLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createSalesInvEtcLoc());

        i++;
      }
    }
  }

  uploadSalesInvEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.salesInvEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteSalesInvEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.salesInvEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openPurchaseInvEtcLoc(content: any) {
    this.addPurchaseInvEtcLoc();
    this.modalService.open(content);
  }
  getPurchaseInvEtcLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('purchaseInvEtcLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getPurchaseInvEtcLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('purchaseInvEtcLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createPurchaseInvEtcLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addPurchaseInvEtcLoc() {
    let attachmentsArray = this.auditReconForm.get('purchaseInvEtcLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createPurchaseInvEtcLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createPurchaseInvEtcLoc());

        i++;
      }
    }
  }

  uploadPurchaseInvEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.purchaseInvEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePurchaseInvEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.purchaseInvEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openMonRetGstr1And3bLoc(content: any) {
    this.addMonRetGstr1And3bLoc();
    this.modalService.open(content);
  }
  getMonRetGstr1And3bLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('monRetGstr1And3bLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getMonRetGstr1And3bLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('monRetGstr1And3bLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createMonRetGstr1And3bLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addMonRetGstr1And3bLoc() {
    let attachmentsArray = this.auditReconForm.get('monRetGstr1And3bLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createMonRetGstr1And3bLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createMonRetGstr1And3bLoc());

        i++;
      }
    }
  }

  uploadMonRetGstr1And3bLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.monRetGstr1And3bLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteMonRetGstr1And3bLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.monRetGstr1And3bLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openAnnRetGstr9And9cLoc(content: any) {
    this.addAnnRetGstr9And9cLoc();
    this.modalService.open(content);
  }
  getAnnRetGstr9And9cLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('annRetGstr9And9cLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getAnnRetGstr9And9cLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('annRetGstr9And9cLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createAnnRetGstr9And9cLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addAnnRetGstr9And9cLoc() {
    let attachmentsArray = this.auditReconForm.get('annRetGstr9And9cLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createAnnRetGstr9And9cLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createAnnRetGstr9And9cLoc());

        i++;
      }
    }
  }

  uploadAnnRetGstr9And9cLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.annRetGstr9And9cLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAnnRetGstr9And9cLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.annRetGstr9And9cLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openSpeRetGstr6AndItc04Loc(content: any) {
    this.addSpeRetGstr6AndItc04Loc();
    this.modalService.open(content);
  }
  getSpeRetGstr6AndItc04LocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('speRetGstr6AndItc04Loc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getSpeRetGstr6AndItc04Locc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('speRetGstr6AndItc04Loc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createSpeRetGstr6AndItc04Loc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addSpeRetGstr6AndItc04Loc() {
    let attachmentsArray = this.auditReconForm.get('speRetGstr6AndItc04Loc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createSpeRetGstr6AndItc04Loc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createSpeRetGstr6AndItc04Loc());

        i++;
      }
    }
  }

  uploadSpeRetGstr6AndItc04Loc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.speRetGstr6AndItc04Loc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteSpeRetGstr6AndItc04Loc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.speRetGstr6AndItc04Loc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openTransCreditEtcLoc(content: any) {
    this.addTransCreditEtcLoc();
    this.modalService.open(content);
  }
  getTransCreditEtcLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('transCreditEtcLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getTransCreditEtcLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('transCreditEtcLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createTransCreditEtcLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addTransCreditEtcLoc() {
    let attachmentsArray = this.auditReconForm.get('transCreditEtcLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createTransCreditEtcLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createTransCreditEtcLoc());

        i++;
      }
    }
  }

  uploadTransCreditEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.transCreditEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteTransCreditEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.transCreditEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openLedgersEtcLoc(content: any) {
    this.addLedgersEtcLoc();
    this.modalService.open(content);
  }
  getLedgersEtcLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('ledgersEtcLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getLedgersEtcLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('ledgersEtcLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createLedgersEtcLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addLedgersEtcLoc() {
    let attachmentsArray = this.auditReconForm.get('ledgersEtcLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createLedgersEtcLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createLedgersEtcLoc());

        i++;
      }
    }
  }

  uploadLedgersEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.ledgersEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteLedgersEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.ledgersEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openRefundAppEtcLoc(content: any) {
    this.addRefundAppEtcLoc();
    this.modalService.open(content);
  }
  getRefundAppEtcLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('refundAppEtcLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getRefundAppEtcLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('refundAppEtcLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createRefundAppEtcLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addRefundAppEtcLoc() {
    let attachmentsArray = this.auditReconForm.get('refundAppEtcLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createRefundAppEtcLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createRefundAppEtcLoc());

        i++;
      }
    }
  }

  uploadRefundAppEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.refundAppEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteRefundAppEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.refundAppEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openChallansEtcLoc(content: any) {
    this.addChallansEtcLoc();
    this.modalService.open(content);
  }
  getChallansEtcLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('challansEtcLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getChallansEtcLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('challansEtcLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createChallansEtcLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addChallansEtcLoc() {
    let attachmentsArray = this.auditReconForm.get('challansEtcLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createChallansEtcLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createChallansEtcLoc());

        i++;
      }
    }
  }

  uploadChallansEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.challansEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteChallansEtcLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.challansEtcLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }


  openInterestPayDetailsLoc(content: any) {
    this.addInterestPayDetailsLoc();
    this.modalService.open(content);
  }
  getInterestPayDetailsLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('interestPayDetailsLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getInterestPayDetailsLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('interestPayDetailsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createInterestPayDetailsLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addInterestPayDetailsLoc() {
    let attachmentsArray = this.auditReconForm.get('interestPayDetailsLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createInterestPayDetailsLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createInterestPayDetailsLoc());

        i++;
      }
    }
  }

  uploadInterestPayDetailsLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.interestPayDetailsLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteInterestPayDetailsLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.interestPayDetailsLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openPenaltyPayDetailsLoc(content: any) {
    this.addPenaltyPayDetailsLoc();
    this.modalService.open(content);
  }
  getPenaltyPayDetailsLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('penaltyPayDetailsLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getPenaltyPayDetailsLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('penaltyPayDetailsLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createPenaltyPayDetailsLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addPenaltyPayDetailsLoc() {
    let attachmentsArray = this.auditReconForm.get('penaltyPayDetailsLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createPenaltyPayDetailsLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createPenaltyPayDetailsLoc());

        i++;
      }
    }
  }

  uploadPenaltyPayDetailsLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.penaltyPayDetailsLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deletePenaltyPayDetailsLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.penaltyPayDetailsLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }



  openDueDateVsDateOfFilingLoc(content: any) {
    this.addDueDateVsDateOfFilingLoc();
    this.modalService.open(content);
  }
  getDueDateVsDateOfFilingLocLength() {
    let count = 0;
    let predepositFrmArray = this.auditReconForm.get('dueDateVsDateOfFilingLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }
  getDueDateVsDateOfFilingLocc(): UntypedFormGroup[] {
    return (this.auditReconForm.get('dueDateVsDateOfFilingLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  createDueDateVsDateOfFilingLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  addDueDateVsDateOfFilingLoc() {
    let attachmentsArray = this.auditReconForm.get('dueDateVsDateOfFilingLoc') as UntypedFormArray;
    let i = 0;

    if (attachmentsArray.length > 0) {
      let length = attachmentsArray.length;

      while (i < 5 - length) {
        attachmentsArray.push(this.createDueDateVsDateOfFilingLoc());

        i++;
      }
    } else {
      while (i < 5) {
        attachmentsArray.push(this.createDueDateVsDateOfFilingLoc());

        i++;
      }
    }
  }

  uploadDueDateVsDateOfFilingLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.dueDateVsDateOfFilingLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteDueDateVsDateOfFilingLoc(noticeLocIndex: any) {
    let auditReconFormArray = this.auditReconForm.controls.dueDateVsDateOfFilingLoc as UntypedFormArray;
    if (auditReconFormArray.controls.length > 0) {
      for (let index = 0; index < auditReconFormArray.controls.length; index++) {
        if (index === noticeLocIndex) {
          let itemListuploadControl = auditReconFormArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  initializeGlTiedUpWithSalesRegLoc() {
    if (this.auditReconData.glTiedUpWithSalesRegLoc) {
      this.auditReconData.glTiedUpWithSalesRegLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createGlTiedUpWithSalesRegLoc(not);

        (this.auditReconForm.get('glTiedUpWithSalesRegLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeSalesRegTiedUpWithGstr1Loc() {
    if (this.auditReconData.salesRegTiedUpWithGstr1Loc) {
      this.auditReconData.salesRegTiedUpWithGstr1Loc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createSalesRegTiedUpWithGstr1Loc(not);

        (this.auditReconForm.get('salesRegTiedUpWithGstr1Loc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeGstr1TiedUpWithGstr9And9CLoc() {
    if (this.auditReconData.gstr1TiedUpWithGstr9And9CLoc) {
      this.auditReconData.gstr1TiedUpWithGstr9And9CLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createGstr1TiedUpWithGstr9And9CLoc(not);

        (this.auditReconForm.get('gstr1TiedUpWithGstr9And9CLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeGstr1LibWithGstr3bLoc() {
    if (this.auditReconData.gstr1LibWithGstr3bLoc) {
      this.auditReconData.gstr1LibWithGstr3bLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createGstr1LibWithGstr3bLoc(not);

        (this.auditReconForm.get('gstr1LibWithGstr3bLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeGstr3bLinWithGlLoc() {
    if (this.auditReconData.gstr3bLinWithGlLoc) {
      this.auditReconData.gstr3bLinWithGlLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createGstr3bLinWithGlLoc(not);

        (this.auditReconForm.get('gstr3bLinWithGlLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeGstr3bLibWithGstr9And9CLoc() {
    if (this.auditReconData.gstr3bLibWithGstr9And9CLoc) {
      this.auditReconData.gstr3bLibWithGstr9And9CLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createGstr3bLibWithGstr9And9CLoc(not);

        (this.auditReconForm.get('gstr3bLibWithGstr9And9CLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeRcmLibRecoLoc() {
    if (this.auditReconData.rcmLibRecoLoc) {
      this.auditReconData.rcmLibRecoLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createRcmLibRecoLoc(not);

        (this.auditReconForm.get('rcmLibRecoLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeGstLibOnAdvancesLoc() {
    if (this.auditReconData.gstLibOnAdvancesLoc) {
      this.auditReconData.gstLibOnAdvancesLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createGstLibOnAdvancesLoc(not);

        (this.auditReconForm.get('gstLibOnAdvancesLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeAmendmentsLoc() {
    if (this.auditReconData.amendmentsLoc) {
      this.auditReconData.amendmentsLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createAmendmentsLoc(not);

        (this.auditReconForm.get('amendmentsLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }

  initializeItcAsPerGlLoc() {
    if (this.auditReconData.itcAsPerGlLoc) {
      this.auditReconData.itcAsPerGlLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createItcAsPerGlLoc(not);

        (this.auditReconForm.get('itcAsPerGlLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }

  initializeItcAsPerPurRegLoc() {
    if (this.auditReconData.itcAsPerPurRegLoc) {
      this.auditReconData.itcAsPerPurRegLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createItcAsPerPurRegLoc(not);

        (this.auditReconForm.get('itcAsPerPurRegLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeGstr3bTiedUpGstr2aLoc() {
    if (this.auditReconData.gstr3bTiedUpGstr2aLoc) {
      this.auditReconData.gstr3bTiedUpGstr2aLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createGstr3bTiedUpGstr2aLoc(not);

        (this.auditReconForm.get('gstr3bTiedUpGstr2aLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeItcAsPerGstr3bLoc() {
    if (this.auditReconData.itcAsPerGstr3bLoc) {
      this.auditReconData.itcAsPerGstr3bLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createItcAsPerGstr3bLoc(not);

        (this.auditReconForm.get('itcAsPerGstr3bLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeReversalsCompLoc() {
    if (this.auditReconData.reversalsCompLoc) {
      this.auditReconData.reversalsCompLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createReversalsCompLoc(not);

        (this.auditReconForm.get('reversalsCompLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeTurnoverLoc() {
    if (this.auditReconData.turnoverLoc) {
      this.auditReconData.turnoverLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createTurnoverLoc(not);

        (this.auditReconForm.get('turnoverLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeIsdCreditDistLoc() {
    if (this.auditReconData.isdCreditDistLoc) {
      this.auditReconData.isdCreditDistLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createIsdCreditDistLoc(not);

        (this.auditReconForm.get('isdCreditDistLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeSalesInvEtcLoc() {
    if (this.auditReconData.salesInvEtcLoc) {
      this.auditReconData.salesInvEtcLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createSalesInvEtcLoc(not);

        (this.auditReconForm.get('salesInvEtcLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializePurchaseInvEtcLoc() {
    if (this.auditReconData.purchaseInvEtcLoc) {
      this.auditReconData.purchaseInvEtcLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createPurchaseInvEtcLoc(not);

        (this.auditReconForm.get('purchaseInvEtcLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeMonRetGstr1And3bLoc() {
    if (this.auditReconData.monRetGstr1And3bLoc) {
      this.auditReconData.monRetGstr1And3bLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createMonRetGstr1And3bLoc(not);

        (this.auditReconForm.get('monRetGstr1And3bLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeAnnRetGstr9And9cLoc() {
    if (this.auditReconData.annRetGstr9And9cLoc) {
      this.auditReconData.annRetGstr9And9cLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createAnnRetGstr9And9cLoc(not);

        (this.auditReconForm.get('annRetGstr9And9cLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeSpeRetGstr6AndItc04Loc() {
    if (this.auditReconData.speRetGstr6AndItc04Loc) {
      this.auditReconData.speRetGstr6AndItc04Loc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createSpeRetGstr6AndItc04Loc(not);

        (this.auditReconForm.get('speRetGstr6AndItc04Loc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeTransCreditEtcLoc() {
    if (this.auditReconData.transCreditEtcLoc) {
      this.auditReconData.transCreditEtcLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createTransCreditEtcLoc(not);

        (this.auditReconForm.get('transCreditEtcLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeLedgersEtcLoc() {
    if (this.auditReconData.ledgersEtcLoc) {
      this.auditReconData.ledgersEtcLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createLedgersEtcLoc(not);

        (this.auditReconForm.get('ledgersEtcLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeRefundAppEtcLoc() {

    this.auditReconData.refundAppEtcLoc.forEach((not: any) => {

      let csnCopyFrmGrp = this.createRefundAppEtcLoc(not);

      (this.auditReconForm.get('refundAppEtcLoc') as UntypedFormArray).push(csnCopyFrmGrp);

    })
    this.auditReconForm.patchValue(this.auditReconData);
  }
  initializeChallansEtcLoc() {
    if (this.auditReconData.challansEtcLoc) {
      this.auditReconData.challansEtcLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createChallansEtcLoc(not);

        (this.auditReconForm.get('challansEtcLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeInterestPayDetailsLoc() {
    if (this.auditReconData.interestPayDetailsLoc) {
      this.auditReconData.interestPayDetailsLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createInterestPayDetailsLoc(not);

        (this.auditReconForm.get('interestPayDetailsLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializePenaltyPayDetailsLoc() {
    if (this.auditReconData.penaltyPayDetailsLoc) {
      this.auditReconData.penaltyPayDetailsLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createPenaltyPayDetailsLoc(not);

        (this.auditReconForm.get('penaltyPayDetailsLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
  initializeDueDateVsDateOfFilingLoc() {
    if (this.auditReconData.dueDateVsDateOfFilingLoc) {
      this.auditReconData.dueDateVsDateOfFilingLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createDueDateVsDateOfFilingLoc(not);

        (this.auditReconForm.get('dueDateVsDateOfFilingLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
      this.auditReconForm.patchValue(this.auditReconData);
    }

  }
}
