import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-a6-chart',
  templateUrl: './a6-chart.component.html',
  styleUrls: ['./a6-chart.component.css']
})
export class A6ChartComponent implements OnInit {
  @Input() a6Data: any;
  data: any = []
  constructor(public commonService: CommonService) { }

  ngOnInit(): void {
    this.initializeData();
  }

  initializeData(){
    const mainLabel = Object.keys(this.a6Data);
    mainLabel.forEach((label: any) => {
      this.data.push({ label: label, data: this.a6Data[label].regdetails })
    })

    this.data.forEach((d: any) => {
      const gstinKeys = Object.keys(d.data);
      d.gstinData = [];

      gstinKeys.forEach((key: any) => {
        let stageData = d.data[key].details;
        
        d.gstinData.push({gstin: key, data: 
          { 'Excise Duty': stageData['Excise Duty'] ? stageData['Excise Duty'] : {taxdemand:0, totaldemand:0,totaldeposit:0} , 
             'Service Tax':stageData['Service Tax'] ? stageData['Service Tax'] :  {taxdemand:0, totaldemand:0,totaldeposit:0},
             'Customs Duty':stageData['Customs Duty'] ? stageData['Customs Duty'] : {taxdemand:0, totaldemand:0,totaldeposit:0},
             'CST':stageData['CST'] ? stageData['CST'] : {taxdemand:0, totaldemand:0,totaldeposit:0},
             'Vat':stageData['Vat'] ? stageData['Vat'] : {taxdemand:0, totaldemand:0,totaldeposit:0},
             'total': d.data[key]
            }})
      })
      d.total = {'Excise Duty':{taxdemand:0, totaldemand:0,totaldeposit:0},'Service Tax':{taxdemand:0, totaldemand:0,totaldeposit:0},'Customs Duty':{taxdemand:0, totaldemand:0,totaldeposit:0},'CST':{taxdemand:0, totaldemand:0,totaldeposit:0}, 'Vat':{taxdemand:0, totaldemand:0,totaldeposit:0}, total:{taxdemand:0, totaldemand:0,totaldeposit:0} }
      d.gstinData.forEach((gstinList : any) => {
        d.total['Excise Duty'] =  {taxdemand : d.total['Excise Duty'].taxdemand + gstinList.data['Excise Duty'].taxdemand, totaldemand : d.total['Excise Duty'].totaldemand + gstinList.data['Excise Duty'].totaldemand, totaldeposit : d.total['Excise Duty'].totaldeposit + gstinList.data['Excise Duty'].totaldeposit};
        d.total['Service Tax'] =  {taxdemand : d.total['Service Tax'].taxdemand + gstinList.data['Service Tax'].taxdemand, totaldemand : d.total['Service Tax'].totaldemand + gstinList.data['Service Tax'].totaldemand, totaldeposit : d.total['Service Tax'].totaldeposit + gstinList.data['Service Tax'].totaldeposit};
        d.total['Customs Duty'] =  {taxdemand : d.total['Customs Duty'].taxdemand + gstinList.data['Customs Duty'].taxdemand, totaldemand : d.total['Customs Duty'].totaldemand + gstinList.data['Customs Duty'].totaldemand, totaldeposit : d.total['Customs Duty'].totaldeposit + gstinList.data['Customs Duty'].totaldeposit};
        d.total['CST'] =  {taxdemand : d.total['CST'].taxdemand + gstinList.data['CST'].taxdemand, totaldemand : d.total['CST'].totaldemand + gstinList.data['CST'].totaldemand, totaldeposit : d.total['CST'].totaldeposit + gstinList.data['CST'].totaldeposit};
        d.total['Vat'] =  {taxdemand : d.total['Vat'].taxdemand + gstinList.data['Vat'].taxdemand, totaldemand : d.total['Vat'].totaldemand + gstinList.data['Vat'].totaldemand, totaldeposit : d.total['Vat'].totaldeposit + gstinList.data['Vat'].totaldeposit};
        d.total.total =  {taxdemand : d.total.total.taxdemand + gstinList.data.total.taxdemand, totaldemand : d.total.total.totaldemand + gstinList.data.total.totaldemand, totaldeposit : d.total.total.totaldeposit + gstinList.data.total.totaldeposit};
      })
    })

  }

}
