import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InrService {

  handlePaste(event: ClipboardEvent) { 
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text');
    const cleanedValue = pastedText?.replace(/[^\d.]/g, '') || '';
  
    if (cleanedValue !== '') {
      const parsedValue = parseFloat(cleanedValue);
      if (!isNaN(parsedValue)) {
        const decimalPart = parsedValue - Math.floor(parsedValue);
        const roundedValue = decimalPart >= 0.5 ? Math.ceil(parsedValue) : Math.floor(parsedValue);
        const inputElement = event.target as HTMLInputElement;
        inputElement.value = roundedValue.toString();
        inputElement.dispatchEvent(new Event('input'));
      }
    }  
    const inputElement = event.target as HTMLInputElement;
    inputElement.focus();
  }
  
  
  formatCurrency(value: any) {
    if (value == 0 || value == null || value == '' || value == undefined) return 0
    const numericValue = parseFloat(String(value).replace(/,/g, ''));
    if (isNaN(numericValue)) return 0
    const roundedValue = Math.round(numericValue);
    const formattedInteger = new Intl.NumberFormat('en-IN').format(roundedValue);
    return formattedInteger;
  }

  removeCommasAndParseNumber(input: string): number {
    if (input == '0' || input == null || input == '' || input == undefined) return 0
    const stringValue = String(input)
    const cleanedString = stringValue.replace(/,/g, '')
    const parsedNumber = parseFloat(cleanedString)
    return parsedNumber
  }
}
