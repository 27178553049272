import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ADJ_APPELPRE,
  ADJ_ATTEND,
  ADJ_OUTCOME,
  ADJ_PH1_ADJUNOT,
  APA_PH1_PAY_MODE,
  Apa_PH1_PREDEPOAPP,
  ISSUE_RISK_PARAM,
  ISSUE_DIVISION,
  PREGST_ISSUE_DROPDOWN,
  PRE_TAB_TYPE,
  QUARTER,
  STATE_LIST,
  TYPE_OF_ACT,
  YES_NO,
  INT_RATE,
  PRE_ACT_TYPE,
  APPLICATION_STATUS,
  MODE_OF_PAYMENT,
  MODE_OF_RECEIPT,
  MULTIPLE_LETTER,
  FORUM_APPEAL_COMMISSIONER,
  DESIGNATION,
  DESIGNATION_PHASE3,
  ADJ_WRITPRE,
  ADJ_DEPTPRE,
  COMMISSIONER_OUTCOME,
  DESIGNATION_COMMISSIONER,
  ISSUE_CONCLUSION_COMMISSIONER,
  CASE_TYPES,
  TYPE_OF_STAY,
  MODE_OF_BGSTATUS,
  MODE_OF_TIME_PH,
  MODE_OF_PH,
  APPEAL_DECISION,
} from 'src/app/shared/constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { PreGstServiceService } from '../services/pre-gst-service.service';
import { BusinessService } from 'src/app/businessManagement/services/business.service';
import { LitigationServiceService } from 'src/app/litigation/services/litigation-service.service';
import { OpenTextModalComponent } from 'src/app/shared/components/open-text-modal/open-text-modal.component';
import { DatePipe } from '@angular/common';
import { InrService } from 'src/app/shared/services/inr.service';
import { HttpClient } from '@angular/common/http';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { MasterService } from 'src/app/businessManagement/services/master.service';
import { UserSettingsService } from 'src/app/businessManagement/services/user-settings.service';
import { forkJoin } from 'rxjs';

export enum SORT_ISSUE {
  CASE_ID = 'CaseId',
  STATE = 'State',
  PAN = 'pan',
  ACT = 'Act',
  RGENO = 'RgeNo',
  CASE_LABEL = 'Case Label',
  PERIOD_FROM = 'Period From',
  PERIOD_TO = 'Period To',
  PERSON_RESPONSIBLE = 'Person Responsible',
}
interface PaymentDetails {
  issueName: any; 
  tax : number;
  tag: any;
  stage: any;
  challanNo:any;
  dateOfPayment:any;
  type:any;
  totalTax:any;
  markedForPayment: boolean;
}


interface ConsultantList {
  srNo: number;
  firmName: string;
  consultantName: string;
  email: string;
  contact?: string;
  address?: string;
}
interface tagData {
  tags: string;
}
@Component({
  selector: 'app-pre-commissioner-auth',
  templateUrl: './pre-commissioner-auth.component.html',
  styleUrls: ['./pre-commissioner-auth.component.css'],
})
export class PreCommissionerAuthComponent implements OnInit {
  parentCaseId: any;
  @Output() callTabFunction: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToHC: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToAT: EventEmitter<string> = new EventEmitter<string>();
  @Output() moveToSC: EventEmitter<string> = new EventEmitter<string>();
  @Output() enablePrevStageTab: EventEmitter<string[] | null> =
    new EventEmitter<string[] | null>();
  @Output() saveCaseId = new EventEmitter<boolean>();

  @Input() set selectedCaseId(id: any) {
    this.parentCaseId = id;
  }
  @Input() activeStages: string[] | null = null;
  selectedRegDropVal:any;
  preGstinDivList: any;
  preCommAuthForm!: UntypedFormGroup;
  selectedTab: any;
  caseId: any;
  action: any;
  companyId: any;
  savedCaseId: any;
  intCalIssueData:any;
  intCalPopData:any;
  getAccessTabData: any;
  currentYear: any;
  navContext: any;
  selectedGstin: any;
  userRoleLs: any;
  consultantList: ConsultantList[]=[];
  isToggleConsultantDropdown = true;
  isToggleConsultantDropdown2 = true;
  isToggleConsultantDropdown3 = true;
  isToggleSegmentDropdown = true;
  isToggleSegmentDropdown2 = true;
  dataSource: any;
  selectedPhase: any;
  selectedFilling: any;
  selectedGstinDiv: any;
  pan: any;
  caseTab: any;
  caseData: any = {};
  selectedActType: any;
  selectedState: any;
  regNumRes: any;
  selectedUserId: any;
  actTypes: any;
  actStateList: any;
  selectedCompanyId: any;
  selectedStateName: any;
  selectedStateCode: any;
  resStateName: any;
  selectedField: any;
  selectedFile: any;
  fileUrl: any;
  filePath: any;
  duplicateData:any;
  selectedRegNo:any;
  matches: any;
  caseType: any;
  someElement: any;
  currentTab = 'preapa';
  activeTab: any;
  issueData: any;
  rmvIssueModalRef!: NgbModalRef;
  rmvDetailModalRef!: NgbModalRef;
  issueReferenceRef!: NgbModalRef;

  // isRemandedBack = false;
  paymentUnderRemandedBackExpansion = false;
  guaranteDetailsExpansion = false;
  viewIons = false;
  hideData:boolean = false;

  formData: FormData = new FormData();
  isSupportingDocFile = false;
  isSupportingDocText = true;
  isSupportingDocDWText = true;
  isSupportingDocUPText = true;
  isUpdateBtnDisable : boolean = false;

  isLiabilitySupportingDocFile = false;
  isLiabilitySupportingDocText = true;
  isLiabilitySupportingDocDWText = true;
  isLiabilitySupportingDocUPText = true;

  isSupportingFile = false;
  isSupportingText = true;
  isSupportingDWText = true;
  isSupportingUPText = true;

  isSupportingDocFile2 = false;
  isSupportingDocText2 = true;
  isSupportingDocDWText2 = true;
  isSupportingDocUPText2 = true;

  isOtherAttachmentFile = false;
  isOtherAttachmentText = true;
  isOtherAttachmentDWText = true;
  isOtherAttachmentUPText = true;

  maxDate = new Date();
  isSubmitted: boolean = false;
  isPhase2: boolean = false;
  isPhase3: boolean = false;

  // isMemorandum: boolean = false;
  disablField: boolean = false;
  disablBankChallan: boolean = false;
  isExcise: boolean = false;
  isServiceTax: boolean = false;
  isEntryTax: boolean = false;
  isVat: boolean = false;
  isCst: boolean = false;
  isCustomsDuty: boolean = false;
  isUpdate: boolean = false;
  copyOfAckLocReq: boolean = false;
  oiaLocReq: boolean = false;
  copyLocReq: boolean = false;
  disabledOnload: boolean = false;
  isDisableIcons: boolean = false;
  isActdisable: boolean = false;
  callBookDisabled = false;
  isToggleDropdown = true;

  disabledWeatherAppl: boolean = false;
  disabledWeatherWrit: boolean = false;
  disabledWeatherDept: boolean = false;

  isUPbankChallanLoc1: boolean = true;
  isDWbankChallanLoc1: boolean = true;
  isbankChallanLoc1File: boolean = false;
  isbankChallanLoc1Text: boolean = true;

  isUPappealBookLoc1: boolean = true;
  isDWappealBookLoc1: boolean = true;
  isappealBookLoc1File: boolean = false;
  isappealBookLoc1Text: boolean = true;

  isUPdrc03CopyLoc1: boolean = true;
  isDWdrc03CopyLoc1: boolean = true;
  isdrc03CopyLoc1File: boolean = false;
  isdrc03CopyLoc1Text: boolean = false;

  isUPcopyOfAckLoc: boolean = true;
  isDWcopyOfAckLoc: boolean = true;
  iscopyOfAckLocFile: boolean = false;
  iscopyOfAckLocText: boolean = true;

  isUPotherAttachmentsLoc1: boolean = true;
  isDWotherAttachmentsLoc1: boolean = true;
  isotherAttachmentsLoc1File: boolean = false;
  isotherAttachmentsLoc1Text: boolean = true;

  isUPotherAttachmentsLoc2: boolean = true;
  isDWotherAttachmentsLoc2: boolean = true;
  isotherAttachmentsLoc2File: boolean = false;
  isotherAttachmentsLoc2Text: boolean = true;

  isUPphCopyLoc: boolean = true;
  isDWphCopyLoc: boolean = true;
  isphCopyLocFile: boolean = false;
  isphCopyLocText: boolean = true;

  isUPadditionalSubmissionLoc: boolean = true;
  isDWadditionalSubmissionLoc: boolean = true;
  isadditionalSubmissionLocFile: boolean = false;
  isadditionalSubmissionLocText: boolean = true;

  isUPphRecordIfAnyLoc: boolean = true;
  isDWphRecordIfAnyLoc: boolean = true;
  isphRecordIfAnyLocFile: boolean = false;
  isphRecordIfAnyLocText: boolean = true;

  isUPoiaLoc: boolean = true;
  isDWoiaLoc: boolean = true;
  isoiaLocFile: boolean = false;
  isoiaLocText: boolean = true;

  isUPbankChallanLoc3Loc: boolean = true;
  isDWbankChallanLoc3Loc: boolean = true;
  isbankChallanLoc3File: boolean = false;
  isbankChallanLoc3Text: boolean = true;

  isUPdrc03CopyLoc3: boolean = true;
  isDWdrc03CopyLoc3: boolean = true;
  isdrc03CopyLoc3File: boolean = false;
  isdrc03CopyLoc3Text: boolean = true;

  isUPnoticeLoc: boolean = true;
  isDWnoticeLoc: boolean = true;
  isnoticeLocFile: boolean = false;
  isnoticeLocText: boolean = true;

  finyearData: any[] = [];
  caseItemList: any = [];
  stateList: any = [];
  preDepoApplicableList: { label: string; value: boolean }[];
  adjPHAdjuorNotList: { label: string; value: boolean }[];
  whetherAppealPreferred: { label: string; value: boolean }[];
  furtherAppealDecision: { label: string; value: boolean }[];
  writAppPreferred: { label: string; value: boolean }[];
  whetherDptAppealPreferred: { label: string; value: boolean }[];
  adjAttend: { label: string; value: boolean }[];
  yesNoList: { label: string; value: boolean }[];
  intRate: { label: string; value: string }[];
  paymentMode: { key: string; value: string }[];
  division: { key: string; value: string }[];
  riskParam: { key: string; value: string }[];
  outcome: { key: string; value: string }[];
  typeOfAct: { key: string; value: string }[];
  issueConclusion: { key: string; value: string }[];
  quarterList: { key: string; value: string; disabled: string }[];
  tabType: { key: string; value: string }[];
  sorting: {
    caseId: boolean;
    state: boolean;
    panName: boolean;
    caseLabel: boolean;
    periodFromDate: boolean;
    periodToDate: boolean;
    personResponsible: boolean;
    actyType: boolean;
    regNo: boolean;
  } = {
      caseId: false,
      state: false,
      panName: false,
      caseLabel: false,
      periodFromDate: false,
      periodToDate: false,
      personResponsible: false,
      actyType: false,
      regNo: false,
    };
    issueSorting = SORT_ISSUE;
  designation = DESIGNATION_COMMISSIONER;
  preAdjType = PRE_ACT_TYPE;
  remandedCaseObj: any = [];
  applicationStatusObj = APPLICATION_STATUS;
  typeOfStayData = TYPE_OF_STAY;

  rmvStayOrderDetailsRef: any;
  modeOfPayment = MODE_OF_PAYMENT;
  rmvDetailsOfPreDepositeRef: any;

  detailsOfStayOrderExpanded: boolean = false;
  detailsOfPreDepositeExpanded: boolean = false;
  protestDetailsExpanded: boolean = false;
  consultantPanleExpanded: boolean = false;
  rmvDetailsOfPaymentRef: any;
  rmvDetailsOfAppealRef: any;
  rmvbankGuaranteeRef: any;

  liabilityAddmittedAndPaidInterestTotal = 0;
  liabilityAddmittedAndPaidTaxTotal = 0;
  liabilityAddmittedAndPaidPenaltyTotal = 0;
  liabilityAddmittedAndPaidTotal = 0;
  liabilityTotals = {
    taxTotal: 0,
    interestTotal: 0,
    penaltyTotal: 0,
    allTotal: 0,
  };

  preDepositeInterestTotal = 0;
  preDepositeTaxTotal = 0;
  preDepositePenaltyTotal = 0;
  preDepositeTotal = 0;

  protestInterestTotal = 0;
  protestTaxTotal = 0;
  protestPenaltyTotal = 0;
  protestTotal = 0;

  feesChargedTotal = 0;

  modeOfAppealOptions = MODE_OF_RECEIPT;
  bgStatusOptions = MODE_OF_BGSTATUS;
  modeOfPH = MODE_OF_PH;
  modeOfTime =MODE_OF_TIME_PH;
  rmvConsultantModalRef: any;
  //  keepCaseInCallBookOptions=MULTIPLE_LETTER;
  keepCaseInCallBookOptions = YES_NO;
  disablePhase3 = false;

  //production fix , will comment late (start)
  preDepositTax = null;
  preDepositInterest = null;
  preDepositPenalty = null;
  preDepositTotal = null;
  paymentUnderProtestTax = null;
  paymentUnderProtestInterest = null;
  paymentUnderProtestPenalty = null;
  paymentUnderProtestTotal = null;
  liabilityPaidTax = null;
  liabilityPaidInterest = null;
  liabilityPaidPenalty = null;
  liabilityPaidTotal = null;
  consultantChargesTotal = null;
  //production fix , will comment late (end)

  isconsultantattachmentsLocText: boolean = true;
  isconsultantDWattachmentsLoc: boolean = true;
  isconsultantattachmentsLocFile: boolean = false;
  isconsultantUPattachmentsLoc: boolean = true;

  forumAppeal = FORUM_APPEAL_COMMISSIONER;
  rmvLiabilityAddmittedAndPaidModalRef: any;
  detailsOfLiabilityAddmittedAndPaidExpanded: Boolean = false;
  modeOfPaymentObjectLiabilityAdmitted = MODE_OF_PAYMENT;
  detailsOfIssuingAuthority = false;
  designationPhase3 = DESIGNATION_PHASE3;

  savedPreCaseId: any;
  appealDetailItemList: any = [];
  preDepositeDetailItemList: any = [];

  detailsOfAllPaymentMadeExpansion = false;
  cumulativeCount = [
    {
      label: 'Cumulative Pre-Deposit',
      tax: 0,
      interest: 0,
      penalty: 0,
      total: 0,
    },
    {
      label: 'Cumulative payment made under protest',
      tax: 0,
      interest: 0,
      penalty: 0,
      total: 0,
    },
    {
      label: 'Cumulative payment made for admitted liability',
      tax: 0,
      interest: 0,
      penalty: 0,
      total: 0,
    },
    {
      label: 'Cumulative Consultant Charges',
      tax: null,
      interest: null,
      penalty: null,
      total: 0,
    },
  ];

  proceedWithPreferredForumAppeal: any;
  copyOfAckLocDocFile = false;
  copyOfAckLocDocText = true;
  copyOfAckLocDWText = true;
  copyOfAckLocUPText = true;

  selectedRemandedBackCaseDetail: any = null;
  remandBackCaseList = [];
  oldProtestDetailsValues: any = [];
  oldLiabilityDetailValues: any = [];
  oldPredepositeDetailsValues: any = [];
  oldConsulatntValues: any = [];
  remandedPaymentMade = null;
  prevStage: any;
  roleName: any;

  tagDataList: tagData[] = [] ;
  navData:any;
  // caseTypeData = CASE_TYPES;

  constructor(
    private activeSnapShot: ActivatedRoute,
    private preLitService: PreGstServiceService,
    private litService: LitigationServiceService,
    private bussinessService: BusinessService,
    private commonServ: CommonService,
    private fBuild: UntypedFormBuilder,
    private modalService: NgbModal,
    private excelService: ExcelService,
    private router: Router,
    private datePipe: DatePipe,
    private toaster: ToasterService,
    private getterSetter: GetterSetterService,
    private inrService: InrService,
    private http: HttpClient,
    private masterService: MasterService,
    private US: UserSettingsService,
  ) {
    this.caseId = this.activeSnapShot.snapshot.params.caseId;
    this.action = this.activeSnapShot.snapshot.params.action;
    this.activeTab = this.activeSnapShot.snapshot.params.type;
    this.activeSnapShot.snapshot.params.remanded == 'true' ? (this.caseType = 'REMANDED') : null;
    // this.isRemandedBack = this.activeSnapShot.snapshot.params.remanded;
    this.preDepoApplicableList = Apa_PH1_PREDEPOAPP;
    this.paymentMode = APA_PH1_PAY_MODE;
    this.division = ISSUE_DIVISION;
    this.riskParam = ISSUE_RISK_PARAM;
    this.adjPHAdjuorNotList = ADJ_PH1_ADJUNOT;
    this.adjAttend = ADJ_ATTEND;
    this.outcome = ADJ_OUTCOME;
    this.whetherAppealPreferred = ADJ_APPELPRE;
    this.furtherAppealDecision = APPEAL_DECISION;
    this.writAppPreferred = ADJ_WRITPRE;
    this.whetherDptAppealPreferred = ADJ_DEPTPRE;
    this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER;
    this.typeOfAct = TYPE_OF_ACT;
    this.quarterList = QUARTER;
    this.yesNoList = YES_NO;
     this.intRate = INT_RATE;
    this.tabType = PRE_TAB_TYPE;
  }
  isUpdateBtn: boolean = false;
  isDataSaved: boolean = false;
  isCreateBtn: boolean = false;

  ngOnInit() {
    this.selectedFilling = JSON.parse(
      sessionStorage.getItem('selectedFilling') || '{}'
    );
    this.selectedGstin = this.selectedFilling.gstin;
    this.selectedGstinDiv = this.selectedFilling.gstinDiv;
    this.selectedCompanyId = this.getterSetter.getSetCompanyId();
    this.navContext = this.commonServ.getNavContext();
    this.navData = JSON.parse(this.getterSetter.getNavContextData() || '{}');
    this.selectedUserId = this.getterSetter.getSetUserId();
    this.pan = this.navContext.pan;
    this.stateList = STATE_LIST;
    this.gstinDivData();
    this.actTypeFetch();
    window.scrollTo(0, 0);
    this.preLitService.getAccessTabData().subscribe((val) => {
      if (val) {
        this.getAccessTabData = val;
      }
    });
    if (this.caseId || this.parentCaseId) {
      this.isPhase2 = true;
      this.isPhase3 = true;
      this.initializeData();
    }
    this.selectedTab = 'tab1';
    this.selectedPhase = 'phase0';
    this.getYears();
    this.setDate();
    this.personResponsibleData();
    this.consultantData();
    this.issueDropdown();
    this.formInitialize();
    if (this.action === 'create') {
      this.createAdjDiv();
      this.addIssueInv();
      this.addStayOrderDetails();
      this.addDetailsOfPreDeposite();
      this.addDetailOfPayment();
      this.addDetailOfBankGuarantee();
      this.addDetailOfAppeal();
      this.addConsultantInv();
      this.addDetailsOfLiabilityAddmittedAndPaid();

      this.isCreateBtn = true;
      this.isDisableIcons = true;
       this.viewIons = false;


      this.isappealBookLoc1File = true;
      this.isappealBookLoc1Text = false;
      this.isUPappealBookLoc1 = false;

      this.isbankChallanLoc1File = true;
      this.isbankChallanLoc1Text = false;
      this.isUPbankChallanLoc1 = false;

      this.isdrc03CopyLoc1File = true;
      this.isbankChallanLoc1Text = false;
      this.isUPbankChallanLoc1 = false;

      this.iscopyOfAckLocFile = true;
      this.iscopyOfAckLocText = false;
      this.isUPcopyOfAckLoc = false;

      this.isotherAttachmentsLoc1File = true;
      this.isotherAttachmentsLoc1Text = false;
      this.isUPotherAttachmentsLoc1 = false;

      this.isotherAttachmentsLoc2File = true;
      this.isotherAttachmentsLoc2Text = false;
      this.isUPotherAttachmentsLoc2 = false;

      this.isphCopyLocFile = true;
      this.isphCopyLocText = false;
      this.isUPphCopyLoc = false;

      this.isadditionalSubmissionLocFile = true;
      this.isadditionalSubmissionLocText = false;
      this.isUPadditionalSubmissionLoc = false;

      this.isphRecordIfAnyLocFile = true;
      this.isphRecordIfAnyLocText = false;
      this.isUPphRecordIfAnyLoc = false;

      this.isoiaLocFile = true;
      this.isoiaLocText = false;
      this.isUPoiaLoc = false;

      this.isbankChallanLoc3File = true;
      this.isbankChallanLoc3Text = false;
      this.isUPbankChallanLoc3Loc = false;

      this.isdrc03CopyLoc3File = true;
      this.isdrc03CopyLoc3Text = false;
      this.isUPdrc03CopyLoc3 = false;

      this.isnoticeLocFile = true;
      this.isnoticeLocText = false;
      this.isUPnoticeLoc = false;
    }
    if (window.location.pathname.includes(',caseTab:move')) {
      this.disabledOnload = true;
      this.isDisableIcons = true;
    } else if (this.action === 'update') {
      this.isUpdateBtn = true;
      this.isDataSaved = true;
      this.isDisableIcons = true;
      this.disabledOnload = true;
      this.viewIons = true;

    } else if (this.action === 'view') {
      this.isUpdateBtn = true;
      this.isDataSaved = true;
      this.disabledOnload = true;
      this.isDisableIcons = false;
      this.viewIons = false;


      this.isPhase2 = true;
      this.isPhase3 = true;

      this.isUPappealBookLoc1 = false;
      this.isDWappealBookLoc1 = true;

      this.isUPbankChallanLoc1 = false;
      this.isDWbankChallanLoc1 = true;

      this.isUPdrc03CopyLoc1 = false;
      this.isDWdrc03CopyLoc1 = true;

      this.isUPcopyOfAckLoc = false;
      this.isDWcopyOfAckLoc = true;

      this.isUPotherAttachmentsLoc1 = false;
      this.isDWotherAttachmentsLoc1 = true;

      this.isUPotherAttachmentsLoc2 = false;
      this.isDWotherAttachmentsLoc2 = true;

      this.isUPphCopyLoc = false;
      this.isDWphCopyLoc = true;

      this.isUPadditionalSubmissionLoc = false;
      this.isDWadditionalSubmissionLoc = true;

      this.isUPphRecordIfAnyLoc = false;
      this.isDWphRecordIfAnyLoc = true;

      this.isUPoiaLoc = false;
      this.isDWoiaLoc = true;

      this.isUPbankChallanLoc3Loc = false;
      this.isDWbankChallanLoc3Loc = true;

      this.isUPdrc03CopyLoc3 = false;
      this.isDWdrc03CopyLoc3 = true;

      this.isUPnoticeLoc = false;
      this.isDWnoticeLoc = true;
    }
    this.createTab();
    const userId: any = sessionStorage.getItem('user');
    this.preCommAuthForm.controls.personResponsible1.patchValue(userId);

    if (localStorage.getItem('roleName') === 'NoRole') {
      this.roleName = localStorage.getItem('role');
      console.log('roleName 1', this.roleName)
    } else {
      this.roleName = localStorage.getItem('roleName');
      console.log('roleName 2', this.roleName)
    }
  }
  formInitialize() {
    this.preCommAuthForm = this.fBuild.group({
      companyId: [this.selectedCompanyId],
      gstinDiv: [this.selectedGstinDiv],
      // pan: [this.pan],
      pan: [this.caseData?.pan ? this.caseData?.pan : this.pan],
      // prevOrderLoc: [this.caseData?.prevOrderLoc],
      status: [this.caseData?.status],
      caseId: [this.caseData?.caseId],
      actType: [this.caseData?.actType, [Validators.required]],
      state: [this.resStateName, [Validators.required]],
      regNo: [this.caseData?.regNo, [Validators.required]],
      oioNo: [this.caseData?.oioNo, [Validators.required]],
      dateOfOio: [this.caseData?.dateOfOio, [Validators.required]],
      dateOfReceiptOfOio: [
        this.caseData?.dateOfReceiptOfOio,
        [Validators.required],
      ],
      dueDateOfAppeal: [this.caseData?.dueDateOfAppeal, [Validators.required]],
      internalDueDate: [this.caseData?.internalDueDate],
      periodFromDate: [this.caseData?.periodFromDate, [Validators.required]],
      periodToDate: [this.caseData?.periodToDate, [Validators.required]],
      consultant1: [this.caseData?.consultant1],
      personResponsible1: [
        this.caseData?.personResponsible1,
        [Validators.required],
      ],
      // preDepositApplicable:[this.caseData?.preDepositApplicable, [Validators.required]],
      // preDepositPaid:[this.caseData?.preDepositPaid],
      // paymentMode:[this.caseData?.paymentMode],
      // bankChallanLoc1:[this.caseData?.bankChallanLoc1],
      // drc03CopyLoc1:[this.caseData?.drc03CopyLoc1],
      appealBookLoc: new UntypedFormArray([]),
      otherAttachmentsLoc1: new UntypedFormArray([]),
      // drc03Tax1:[this.caseData?.drc03Tax1, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Interest1:[this.caseData?.drc03Interest1, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Penalty1:[this.caseData?.drc03Penalty1, [Validators.pattern(/^[1-9]\d*$/)]],
      issues: new UntypedFormArray([]),
      caseSummary: [this.caseData?.caseSummary],
      portCode:[this.caseData?.portCode],
      portName:[this.caseData?.portName],
      internalRemarks: [this.caseData?.internalRemarks],
      whetherRemandedBack: [null],
      exciseDuty1Total: [
        this.action == 'update' ? '0' : this.caseData?.exciseDuty1Total,
      ],
      ec1Total: [this.action == 'update' ? '0' : this.caseData?.ec1Total],
      shec1Total: [this.action == 'update' ? '0' : this.caseData?.shec1Total],
      serviceTax1Total: [
        this.action == 'update' ? '0' : this.caseData?.serviceTax1Total,
      ],
      kkc1Total: [this.action == 'update' ? '0' : this.caseData?.kkc1Total],
      sbc1Total: [this.action == 'update' ? '0' : this.caseData?.sbc1Total],
      totalservTax5: [
        this.action == 'update' ? '0' : this.caseData?.totalservTax5,
      ],
      vat1Total: [this.action == 'update' ? '0' : this.caseData?.vat1Total],
      cst1Total: [this.action == 'update' ? '0' : this.caseData?.cst1Total],
      bcd1Total: [this.action == 'update' ? '0' : this.caseData?.bcd1Total],
      sad1Total: [this.action == 'update' ? '0' : this.caseData?.sad1Total],
      antiDumpingDuty1Total: [this.action == 'update' ? '0' : this.caseData?.antiDumpingDuty1Total],
      igst1Total: [this.action == 'update' ? '0' : this.caseData?.igst1Total],
      ent1Total: [this.action == 'update' ? '0' : this.caseData?.ent1Total],
      interest1Total: [
        this.action == 'update' ? '0' : this.caseData?.interest1Total,
      ],
      penalty1Total: [
        this.action == 'update' ? '0' : this.caseData?.penalty1Total,
      ],
      totOfTotal1: [this.action == 'update' ? '0' : this.caseData?.totOfTotal1],
      remandedBackForum: [null],
      remandedCaseId: [null],
      predeposit: [null],
      paymentUnderProtest: [null],
      preDepositTax: [null],
      preDepositInterest: [null],
      preDepositPenalty: [null],
      preDepositTotal: [null],
      paymentUnderProtestTax: [0],
      paymentUnderProtestInterest: [0],
      paymentUnderProtestPenalty: [0],
      paymentUnderProtestTotal: [0],
      liabilityPaidTax: [0],
      liabilityPaidInterest: [0],
      liabilityPaidPenalty: [0],
      liabilityPaidTotal: [0],
      consultantChargesTotal: [0],
      din1: [this.caseData?.din1],
      caseLabel: [
        this.caseData?.caseLabel,
        [
          Validators.required,
          Validators.maxLength(100),
          Validators.minLength(5),
        ],
      ],
      stayOrder: new UntypedFormArray([]),
      depositDetails: new UntypedFormArray([]),
      protestDetails: new UntypedFormArray([]),
      orderReceiptMode1: [this.caseData?.orderReceiptMode1],
      orderReceiptMode3: [this.caseData?.orderReceiptMode3],

      //phase2
      dateOfSubmission: [this.caseData?.dateOfSubmission],
      consultant2: [this.caseData?.consultant2],
      // personResponsible2:[this.caseData?.personResponsible2],
      // copyOfAckLoc:[this.caseData?.copyOfAckLoc],
      // otherAttachmentsLoc2:[this.caseData?.otherAttachmentsLoc2],
      itemList: this.fBuild.array([]),
      submissions: new UntypedFormArray([]),
      bankGuarantee: new UntypedFormArray([]),
      consultantCharges: new UntypedFormArray([]),
      whetherCaseCallBook: [this.caseData?.whetherCaseCallBook],
      furtherAppealDecision: [this.caseData?.furtherAppealDecision], 
      //phase3

      oiaNo: [this.caseData?.oiaNo],
      dateOfOia: [this.caseData?.dateOfOia],
      dateOfReceiptOfOia: [this.caseData?.dateOfReceiptOfOia],
      oiaLoc: new UntypedFormArray([]),
      outcome: [this.caseData?.outcome],
      whetherAppealPreferred: [this.caseData?.whetherAppealPreferred],
      writAppPreferred: [this.caseData?.writAppPreferred],
      whetherDptAppealPreferred: [this.caseData?.whetherDptAppealPreferred],
      // bankChallanLoc3:[this.caseData?.bankChallanLoc3],
      // drc03CopyLoc3:[this.caseData?.drc03CopyLoc3],
      // drc03Tax3:[this.caseData?.drc03Tax3, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Interest3:[this.caseData?.drc03Interest3, [Validators.pattern(/^[1-9]\d*$/)]],
      // drc03Penalty3:[this.caseData?.drc03Penalty3, [Validators.pattern(/^[1-9]\d*$/)]],
      orderSummary: [this.caseData?.orderSummary],

      exciseDuty3Total: [
        this.action == 'update' ? '0' : this.caseData?.exciseDuty3Total,
      ],
      ec3Total: [this.action == 'update' ? '0' : this.caseData?.ec3Total],
      shec3Total: [this.action == 'update' ? '0' : this.caseData?.shec3Total],
      serviceTax3Total: [
        this.action == 'update' ? '0' : this.caseData?.serviceTax3Total,
      ],
      kkc3Total: [this.action == 'update' ? '0' : this.caseData?.kkc3Total],
      sbc3Total: [this.action == 'update' ? '0' : this.caseData?.sbc3Total],
      vat3Total: [this.action == 'update' ? '0' : this.caseData?.vat3Total],
      cst3Total: [this.action == 'update' ? '0' : this.caseData?.cst3Total],
      bcd3Total: [this.action == 'update' ? '0' : this.caseData?.bcd3Total],
      sad3Total: [this.action == 'update' ? '0' : this.caseData?.sad3Total], 
      antiDumpingDuty3Total: [this.action == 'update' ? '0' : this.caseData?.antiDumpingDuty3Total],
      igst3Total: [this.action == 'update' ? '0' : this.caseData?.igst3Total],
      ent3Total: [this.action == 'update' ? '0' : this.caseData?.ent3Total],
      interest3Total: [
        this.action == 'update' ? '0' : this.caseData?.interest3Total,
      ],
      penalty3Total: [
        this.action == 'update' ? '0' : this.caseData?.penalty3Total,
      ],
      totOfTotal3: [
        this.action == 'update' ? '0' : this.caseData?.exciseDuty1Total,
      ],
      prevOrderLoc: new UntypedFormArray([]),
      din3: [null],
      forumOfAppeal: [null],
      liabilityDetails: new UntypedFormArray([]),
      officer3: [this.caseData?.issuingAuth3?.officer],
      designation3: [this.caseData?.issuingAuth3?.designation],
      jurisdiction3: [this.caseData?.issuingAuth3?.jurisdiction],
      address3: [this.caseData?.issuingAuth3?.address],
      contact3: [this.caseData?.issuingAuth3?.contact],
      // email3:[this.caseData?.issuingAuth3?.email],
      email3: [
        this.caseData?.issuingAuth3?.email,
        [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)],
      ],
      // orderReceiptMode:[this.caseData?.orderReceiptMode]
      remandedPaymentMade: new UntypedFormArray([]),
    });
    this.preCommAuthForm.controls['protestDetails'].valueChanges.subscribe(
      (selectedValue) => {
        this.oldProtestDetailsValues =
          this.preCommAuthForm.value['protestDetails'];
        this.setCummulativeCount(
          this.oldProtestDetailsValues,
          selectedValue,
          'protestDetails'
        );
      }
    );

    this.preCommAuthForm.controls['depositDetails'].valueChanges.subscribe(
      (selectedValue) => {
        this.oldPredepositeDetailsValues =
          this.preCommAuthForm.value['depositDetails'];
        this.setCummulativeCount(
          this.oldPredepositeDetailsValues,
          selectedValue,
          'depositDetails'
        );
      }
    );

    this.preCommAuthForm.controls['consultantCharges'].valueChanges.subscribe(
      (selectedValue) => {
        this.oldConsulatntValues =
          this.preCommAuthForm.value['consultantCharges'];
        this.setCummulativeCount(
          this.oldConsulatntValues,
          selectedValue,
          'consultantCharges'
        );
      }
    );

    this.preCommAuthForm.controls['liabilityDetails'].valueChanges.subscribe(
      (selectedValue) => {
        this.oldLiabilityDetailValues =
          this.preCommAuthForm.value['liabilityDetails'];
        this.setCummulativeCount(
          this.oldLiabilityDetailValues,
          selectedValue,
          'liabilityDetails'
        );
      }
    );
  }
  get formGrp() {
    return this.preCommAuthForm.controls;
  }
  // p1validation() {
  //   if (this.preCommAuthForm.value['prevOrderLoc'] == null || this.preCommAuthForm.value['prevOrderLoc'] === '') {
  //     this.copyLocReq = true;
  //   } else {
  //     this.copyLocReq = false;
  //   }
  //   return !this.copyLocReq;
  // }

  //phase 1
  getappealBookLoc(): UntypedFormGroup[] {
    return (this.preCommAuthForm.get('appealBookLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getotherAttachmentsLoc1(): UntypedFormGroup[] {
    return (this.preCommAuthForm.get('otherAttachmentsLoc1') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  getcopyOfOioLoc(): UntypedFormGroup[] {
    return (this.preCommAuthForm.get('prevOrderLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getStayOrdercopyOfStayLocAttach(copyOfStayLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfStayLocGrp.get('copyOfStayLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getDepositDetailsSupportingDocLocAttach(
    ddSupportingDocLocGrp: UntypedFormGroup
  ): UntypedFormGroup[] {
    return (ddSupportingDocLocGrp.get('supportingDocLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getProtestSupportingDocLocAttachment(
    pssupportingDocLocGrp: UntypedFormGroup
  ): UntypedFormGroup[] {
    return (pssupportingDocLocGrp.get('supportingDocLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  //phase 1
  //phase 2 start
  getcopyOfAckLocAttachment(copyOfAckLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (copyOfAckLocGrp.get('copyOfAckLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getOtherAttachments(otherAttachmentsLocGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (otherAttachmentsLocGrp.get('otherAttachmentsLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getphCopyLoc(phCopyLocFrmGrp: any): UntypedFormGroup[] {
    return (phCopyLocFrmGrp.get('phCopyLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getAdditionalSub(addSubLocFrmGrp: any): UntypedFormGroup[] {
    return (addSubLocFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getPhRecord(phRecordFrmGrp: any): UntypedFormGroup[] {
    return (phRecordFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getAdjournmentLetter(phAdjournmentLetterFrmGrp: any): UntypedFormGroup[] {
    return (phAdjournmentLetterFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  getConsultOtherAttachment(consultAttachGrp: UntypedFormGroup): UntypedFormGroup[] {
    return (consultAttachGrp.get('otherAttachmentLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  //phase 2 end
  //phase 3 start
  getoiaLoc(): UntypedFormGroup[] {
    return (this.preCommAuthForm.get('oiaLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getLiabilitySupportingDocLocAttachment(
    copyOfFormLocGrp: UntypedFormGroup
  ): UntypedFormGroup[] {
    return (copyOfFormLocGrp.get('copyOfFormLoc') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getAppealBookLocLength() {
    let count = 0;
    let predepositFrmArray = this.preCommAuthForm.get('appealBookLoc') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }

  getAttachmentsLocLength() {
    let count = 0;
    let predepositFrmArray = this.preCommAuthForm.get('otherAttachmentsLoc1') as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter((x: any) => x.fileLoc != '' && x.fileLoc != null);
    return itrcount.length;
  }

  getCopyOfOioLocLength() {
    let count = 0;
    let predepositFrmArray = this.preCommAuthForm.get(
      'prevOrderLoc'
    ) as UntypedFormArray;
    let itrcount = predepositFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return itrcount.length;
  }
  getStayOrderLength(j?: any) {
    let count = 0;
    let stayOrderArray = this.preCommAuthForm.get('stayOrder') as UntypedFormArray;
    let letterLocFrmArray = stayOrderArray.controls[j]?.get(
      'copyOfStayLoc'
    ) as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return lettercount.length;
  }
  getDepositDetailsLength(j?: any) {
    let count = 0;
    let depositDetailsArray = this.preCommAuthForm.get(
      'depositDetails'
    ) as UntypedFormArray;
    let letterLocFrmArray = depositDetailsArray.controls[j]?.get(
      'supportingDocLoc'
    ) as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return lettercount.length;
  }
  getProtestCopyLength(j?: any) {
    let count = 0;
    let protestDetailsArray = this.preCommAuthForm.get(
      'protestDetails'
    ) as UntypedFormArray;
    let letterLocFrmArray = protestDetailsArray.controls[j]?.get(
      'supportingDocLoc'
    ) as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return lettercount.length;
  }
  getCopyOfAckLocLength(j?: any) {
    let count = 0;
    let submissionsFrmArray = this.preCommAuthForm.get(
      'submissions'
    ) as UntypedFormArray;
    let letterLocFrmArray = submissionsFrmArray.controls[j]?.get(
      'copyOfAckLoc'
    ) as UntypedFormArray;
    let lettercount = letterLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return lettercount.length;
  }
  getOtherAttachmentsLocLength(j?: any) {
    let count = 0;
    let submissionsFrmArray = this.preCommAuthForm.get(
      'submissions'
    ) as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionsFrmArray.controls[j]?.get(
      'otherAttachmentsLoc'
    ) as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return lettercount.length;
  }
  getPhCopyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preCommAuthForm.get('itemList') as UntypedFormArray;
    let otherAttachmentsLocFrmArray = phCopyLocArray.controls[j]?.get(
      'phCopyLoc'
    ) as UntypedFormArray;
    let lettercount = otherAttachmentsLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return lettercount.length;
  }
  getAdditionalSubmissionLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preCommAuthForm.get('itemList') as UntypedFormArray;
    let additionalSubmissionLocFrmArray = phCopyLocArray.controls[j]?.get(
      'additionalSubmissionLoc'
    ) as UntypedFormArray;
    let lettercount = additionalSubmissionLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return lettercount.length;
  }
  getphAdjournmentLetterLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preCommAuthForm.get('itemList') as UntypedFormArray;
    let phAdjournmentLetterLocFrmArray = phCopyLocArray.controls[j]?.get('phAdjournmentLetterLoc') as UntypedFormArray;
    let lettercount = phAdjournmentLetterLocFrmArray.value.filter((x: any) => x.fileLoc != '');
    return lettercount.length;
  }
  getPhRecordIfAnyLocLength(j?: any) {
    let count = 0;
    let phCopyLocArray = this.preCommAuthForm.get('itemList') as UntypedFormArray;
    let phRecordIfAnyLocFrmArray = phCopyLocArray.controls[j]?.get(
      'phRecordIfAnyLoc'
    ) as UntypedFormArray;
    let lettercount = phRecordIfAnyLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return lettercount.length;
  }
  getConsultOtherAttachmentLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.preCommAuthForm.get(
      'consultantCharges'
    ) as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get(
      'otherAttachmentLoc'
    ) as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return lettercount.length;
  }
  getOiaLocLength() {
    let count = 0;
    let oiaLocFrmArray = this.preCommAuthForm.get('oiaLoc') as UntypedFormArray;
    let itrcount = oiaLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return itrcount.length;
  }
  getLiabilityCopyFormLength(j?: any) {
    let count = 0;
    let consultantChargesArray = this.preCommAuthForm.get(
      'liabilityDetails'
    ) as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultantChargesArray.controls[j]?.get(
      'copyOfFormLoc'
    ) as UntypedFormArray;
    let lettercount = otherAttachmentLocFrmArray.value.filter(
      (x: any) => x.fileLoc != '' && x.fileLoc != null
    );
    return lettercount.length;
  }
  //phase 3 end

  createCopyOfOioLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  createappealBookLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createotherAttachmentsLoc1(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createStayOrderCopyOfStayLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  createDepositSupportingDocLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  createProtestSupportingDoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  //phase 2 start
  createCopyOfAckLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  createOtherAttachmentsLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  createphCopyLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  createAdditionalSubLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  createPhAdjournmentLetterLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [Validators.maxLength(100)]),
    })
  }
  createPhRecord(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  createConsultotherAttachmentLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  //phase 2 end
  //phase 3 start
  createOiaLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  createLiabilitySupportingDocLoc(not: any = null) {
    return this.fBuild.group({
      fileLoc: new UntypedFormControl(not ? not.fileLoc : '', []),
      fileName: new UntypedFormControl(not ? not.fileName : '', []),
      remarks: new UntypedFormControl(not ? not.remarks : '', [
        Validators.maxLength(100),
      ]),
    });
  }
  //phase 3 end

  openCopyOfOioLocModel(content: any) {
    this.addCopyOfOioLoc();
    this.modalService.open(content);
  }
  openappealBookLoc(content: any) {
    this.addappealBookLoc();
    this.modalService.open(content);
  }
  openotherAttachmentsLoc1(content: any) {
    this.addotherAttachmentsLoc1();
    this.modalService.open(content);
  }
  openStayOrdercopyOfStayLoctAttachModel(content: any, i: any) {
    this.addStayOrdercopyOfStayLoc(i, 0);
    this.modalService.open(content);
  }
  openDepositDetailsSupportingDocLocModel(content: any, i: any) {
    this.addDepositDetailsSupportingDocLoc(i, 0);
    this.modalService.open(content);
  }
  openProtestSupportingDocLoctAttach(content: any, i: any) {
    this.addProtestSupportingDoc(i, 0);
    this.modalService.open(content);
  }

  //phase2 start
  openCopyOfAckLoc(content: any, i: any) {
    this.addCopyOfAckLoc(i, 0);
    this.modalService.open(content);
  }
  openOtherAttachmentsLoc(content: any, i: any) {
    this.addotherAttachmentsLoc(i, 0);
    this.modalService.open(content);
  }
  openphCopyLoc(content: any, i: any) {
    this.addphCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openAdditionSub(content: any, i: any) {
    this.addAdditionalCopyLoc(i, 0);
    this.modalService.open(content);
  }
  openPhRecord(content: any, i: any) {
    this.addPhRecord(i, 0);
    this.modalService.open(content);
  }
  openAdjournmentLetter(content: any, i: any) {
    this.addAdjournmentLetter(i, 0);
    this.modalService.open(content);
  }
  openConsultotherAttachmentLoc(content: any, i: any) {
    this.addConsultotherAttachmentLoc(i, 0);
    this.modalService.open(content);
  }
  //phase2 end
  //phase3 Start
  openOiaLocModel(content: any) {
    this.addOiaLoc();
    this.modalService.open(content);
  }
  openLiabilitySupportingDocLocAttachment(content: any, i: any) {
    this.addLiabilitySupportingDoc(i, 0);
    this.modalService.open(content);
  }
  //phase3 end

  addCopyOfOioLoc() {
    let copyOfOioLocCopyArray = this.preCommAuthForm.get(
      'prevOrderLoc'
    ) as UntypedFormArray;
    let i = 0;

    if (copyOfOioLocCopyArray.length > 0) {
      let length = copyOfOioLocCopyArray.length;

      while (i < 5 - length) {
        copyOfOioLocCopyArray.push(this.createCopyOfOioLoc());

        i++;
      }
    } else {
      while (i < 5) {
        copyOfOioLocCopyArray.push(this.createCopyOfOioLoc());

        i++;
      }
    }
  }
  addappealBookLoc() {
    let appealBookLocCopyArray = this.preCommAuthForm.get('appealBookLoc') as UntypedFormArray;
    let i = 0;

    if (appealBookLocCopyArray.length > 0) {
      let length = appealBookLocCopyArray.length;

      while (i < 5 - length) {
        appealBookLocCopyArray.push(this.createappealBookLoc());

        i++;
      }
    } else {
      while (i < 5) {
        appealBookLocCopyArray.push(this.createappealBookLoc());

        i++;
      }
    }

  }
  addotherAttachmentsLoc1() {
    let otherAttachmentsLoc1CopyArray = this.preCommAuthForm.get('otherAttachmentsLoc1') as UntypedFormArray;
    let i = 0;

    if (otherAttachmentsLoc1CopyArray.length > 0) {
      let length = otherAttachmentsLoc1CopyArray.length;

      while (i < 5 - length) {
        otherAttachmentsLoc1CopyArray.push(this.createotherAttachmentsLoc1());

        i++;
      }
    } else {
      while (i < 5) {
        otherAttachmentsLoc1CopyArray.push(this.createotherAttachmentsLoc1());

        i++;
      }
    }

  }
  addStayOrdercopyOfStayLoc(j: any, i: any) {
    let stayOrderFrmArray = this.preCommAuthForm.get('stayOrder') as UntypedFormArray;
    let copyOfStayLocFrmArray = stayOrderFrmArray.controls[j].get(
      'copyOfStayLoc'
    ) as UntypedFormArray;

    if (copyOfStayLocFrmArray.length > 0) {
      let length = copyOfStayLocFrmArray.length;
      while (i < 5 - length) {
        copyOfStayLocFrmArray.push(this.createStayOrderCopyOfStayLoc());
        i++;
      }
    } else {
      while (i < 5) {
        copyOfStayLocFrmArray.push(this.createStayOrderCopyOfStayLoc());
        i++;
      }
    }
  }
  addDepositDetailsSupportingDocLoc(j: any, i: any) {
    let depositDetailsFrmArray = this.preCommAuthForm.get(
      'depositDetails'
    ) as UntypedFormArray;
    let supportingDocLocFrmArray = depositDetailsFrmArray.controls[j].get(
      'supportingDocLoc'
    ) as UntypedFormArray;

    if (supportingDocLocFrmArray.length > 0) {
      let length = supportingDocLocFrmArray.length;
      while (i < 5 - length) {
        supportingDocLocFrmArray.push(this.createDepositSupportingDocLoc());
        i++;
      }
    } else {
      while (i < 5) {
        supportingDocLocFrmArray.push(this.createDepositSupportingDocLoc());
        i++;
      }
    }
  }
  addProtestSupportingDoc(j: any, i: any) {
    let protestDetailsFrmArray = this.preCommAuthForm.get(
      'protestDetails'
    ) as UntypedFormArray;
    let supportingDocLocFrmArray = protestDetailsFrmArray.controls[j].get(
      'supportingDocLoc'
    ) as UntypedFormArray;

    if (supportingDocLocFrmArray.length > 0) {
      let length = supportingDocLocFrmArray.length;
      while (i < 5 - length) {
        supportingDocLocFrmArray.push(this.createProtestSupportingDoc());
        i++;
      }
    } else {
      while (i < 5) {
        supportingDocLocFrmArray.push(this.createProtestSupportingDoc());
        i++;
      }
    }
  }
  //phase 2 start
  addCopyOfAckLoc(j: any, i: any) {
    let submissionFrmArray = this.preCommAuthForm.get(
      'submissions'
    ) as UntypedFormArray;
    let copyOfAckLocFrmArray = submissionFrmArray.controls[j].get(
      'copyOfAckLoc'
    ) as UntypedFormArray;

    if (copyOfAckLocFrmArray.length > 0) {
      let length = copyOfAckLocFrmArray.length;
      while (i < 5 - length) {
        copyOfAckLocFrmArray.push(this.createCopyOfAckLoc());
        i++;
      }
    } else {
      while (i < 5) {
        copyOfAckLocFrmArray.push(this.createCopyOfAckLoc());
        i++;
      }
    }
  }
  addotherAttachmentsLoc(j: any, i: any) {
    let submissionFrmArray = this.preCommAuthForm.get(
      'submissions'
    ) as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionFrmArray.controls[j].get(
      'otherAttachmentsLoc'
    ) as UntypedFormArray;

    if (otherAttachmentsLocFrmArray.length > 0) {
      let length = otherAttachmentsLocFrmArray.length;
      while (i < 5 - length) {
        otherAttachmentsLocFrmArray.push(this.createOtherAttachmentsLoc());
        i++;
      }
    } else {
      while (i < 5) {
        otherAttachmentsLocFrmArray.push(this.createOtherAttachmentsLoc());
        i++;
      }
    }
  }
  addphCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.preCommAuthForm.get('itemList') as UntypedFormArray;
    let phCopyLocFrmArray = dtCaseFrmArray.controls[j].get(
      'phCopyLoc'
    ) as UntypedFormArray;

    if (phCopyLocFrmArray.length > 0) {
      let length = phCopyLocFrmArray.length;
      while (i < 5 - length) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    } else {
      while (i < 5) {
        phCopyLocFrmArray.push(this.createphCopyLoc());
        i++;
      }
    }
  }
  addAdjournmentLetter(j: any, i: any) {
    let dtCaseFrmArray = this.preCommAuthForm.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
    else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhAdjournmentLetterLoc());
        i++;
      }
    }
  }
  addAdditionalCopyLoc(j: any, i: any) {
    let dtCaseFrmArray = this.preCommAuthForm.get('itemList') as UntypedFormArray;
    let additionalSubLocFrmArray = dtCaseFrmArray.controls[j].get(
      'additionalSubmissionLoc'
    ) as UntypedFormArray;

    if (additionalSubLocFrmArray.length > 0) {
      let length = additionalSubLocFrmArray.length;
      while (i < 5 - length) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    } else {
      while (i < 5) {
        additionalSubLocFrmArray.push(this.createAdditionalSubLoc());
        i++;
      }
    }
  }
  addPhRecord(j: any, i: any) {
    let dtCaseFrmArray = this.preCommAuthForm.get('itemList') as UntypedFormArray;
    let phRecordLocFrmArray = dtCaseFrmArray.controls[j].get(
      'phRecordIfAnyLoc'
    ) as UntypedFormArray;

    if (phRecordLocFrmArray.length > 0) {
      let length = phRecordLocFrmArray.length;
      while (i < 5 - length) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    } else {
      while (i < 5) {
        phRecordLocFrmArray.push(this.createPhRecord());
        i++;
      }
    }
  }
  addConsultotherAttachmentLoc(j: any, i: any) {
    let consultOtherAttachFrmArray = this.preCommAuthForm.get(
      'consultantCharges'
    ) as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultOtherAttachFrmArray.controls[j].get(
      'otherAttachmentLoc'
    ) as UntypedFormArray;

    if (otherAttachmentLocFrmArray.length > 0) {
      let length = otherAttachmentLocFrmArray.length;
      while (i < 5 - length) {
        otherAttachmentLocFrmArray.push(this.createConsultotherAttachmentLoc());
        i++;
      }
    } else {
      while (i < 5) {
        otherAttachmentLocFrmArray.push(this.createConsultotherAttachmentLoc());
        i++;
      }
    }
  }

  //phase 2 end
  //phase 3 Start
  addOiaLoc() {
    let oiaLocCopyArray = this.preCommAuthForm.get('oiaLoc') as UntypedFormArray;
    let i = 0;

    if (oiaLocCopyArray.length > 0) {
      let length = oiaLocCopyArray.length;

      while (i < 5 - length) {
        oiaLocCopyArray.push(this.createOiaLoc());

        i++;
      }
    } else {
      while (i < 5) {
        oiaLocCopyArray.push(this.createOiaLoc());

        i++;
      }
    }
  }
  addLiabilitySupportingDoc(j: any, i: any) {
    let liabilityDetailsFrmArray = this.preCommAuthForm.get(
      'liabilityDetails'
    ) as UntypedFormArray;
    let copyOfFormLocFrmArray = liabilityDetailsFrmArray.controls[j].get(
      'copyOfFormLoc'
    ) as UntypedFormArray;

    if (copyOfFormLocFrmArray.length > 0) {
      let length = copyOfFormLocFrmArray.length;
      while (i < 5 - length) {
        copyOfFormLocFrmArray.push(this.createLiabilitySupportingDocLoc());
        i++;
      }
    } else {
      while (i < 5) {
        copyOfFormLocFrmArray.push(this.createLiabilitySupportingDocLoc());
        i++;
      }
    }
  }
  //phase 3 end

  uploadCopyOfOioLoc(copyOfOioLocIndex: any) {
    let copyOfOioLocArray = this.preCommAuthForm.controls
      .prevOrderLoc as UntypedFormArray;
    if (copyOfOioLocArray.controls.length > 0) {
      for (let index = 0; index < copyOfOioLocArray.controls.length; index++) {
        if (index === copyOfOioLocIndex) {
          let itemListuploadControl = copyOfOioLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteCopyOfOioLocF(copyOfOioLocIndex: any) {
    let copyOfOioLocArray = this.preCommAuthForm.controls
      .prevOrderLoc as UntypedFormArray;
    if (copyOfOioLocArray.controls.length > 0) {
      for (let index = 0; index < copyOfOioLocArray.controls.length; index++) {
        if (index === copyOfOioLocIndex) {
          let itemListuploadControl = copyOfOioLocArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadappealBookLoc(appealBookLocIndex: any) {
    let appealBookLocArray = this.preCommAuthForm.controls.appealBookLoc as UntypedFormArray;
    if (appealBookLocArray.controls.length > 0) {
      for (let index = 0; index < appealBookLocArray.controls.length; index++) {
        if (index === appealBookLocIndex) {
          let itemListuploadControl = appealBookLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteappealBookLoc(appealBookLocIndex: any) {
    let appealBookLocArray = this.preCommAuthForm.controls.appealBookLoc as UntypedFormArray;
    if (appealBookLocArray.controls.length > 0) {
      for (let index = 0; index < appealBookLocArray.controls.length; index++) {
        if (index === appealBookLocIndex) {
          let itemListuploadControl = appealBookLocArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadotherAttachmentsLoc1F(otherAttachmentsLoc1Index: any) {
    let otherAttachmentsLoc1Array = this.preCommAuthForm.controls.otherAttachmentsLoc1 as UntypedFormArray;
    if (otherAttachmentsLoc1Array.controls.length > 0) {
      for (let index = 0; index < otherAttachmentsLoc1Array.controls.length; index++) {
        if (index === otherAttachmentsLoc1Index) {
          let itemListuploadControl = otherAttachmentsLoc1Array.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteotherAttachmentsLoc1F(otherAttachmentsLoc1Index: any) {
    let otherAttachmentsLoc1Array = this.preCommAuthForm.controls.otherAttachmentsLoc1 as UntypedFormArray;
    if (otherAttachmentsLoc1Array.controls.length > 0) {
      for (let index = 0; index < otherAttachmentsLoc1Array.controls.length; index++) {
        if (index === otherAttachmentsLoc1Index) {
          let itemListuploadControl = otherAttachmentsLoc1Array.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }

  uploadStayOrdercopyOfStayLoc(
    StayOrdercopyOfStayLocListIndex: any,
    copyOfStayLocIndex: any
  ) {
    let stayOrderFrmArray = this.preCommAuthForm.get('stayOrder') as UntypedFormArray;
    let copyOfStayLocFrmArray = stayOrderFrmArray.controls[
      StayOrdercopyOfStayLocListIndex
    ].get('copyOfStayLoc') as UntypedFormArray;

    if (copyOfStayLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < copyOfStayLocFrmArray.controls.length;
        index++
      ) {
        if (index === copyOfStayLocIndex) {
          let itemListuploadControl = copyOfStayLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteStayOrdercopyOfStayLoc(
    StayOrdercopyOfStayLocListIndex: any,
    copyOfStayLocIndex: any
  ) {
    let stayOrderFrmArray = this.preCommAuthForm.get('stayOrder') as UntypedFormArray;
    let copyOfStayLocFrmArray = stayOrderFrmArray.controls[
      StayOrdercopyOfStayLocListIndex
    ].get('copyOfStayLoc') as UntypedFormArray;

    if (copyOfStayLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < copyOfStayLocFrmArray.controls.length;
        index++
      ) {
        if (index === copyOfStayLocIndex) {
          let itemListuploadControl = copyOfStayLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadDepositDetailsSupportingDoc(
    depositDetailsListIndex: any,
    supportingDocLocIndex: any
  ) {
    let depositDetailsFrmArray = this.preCommAuthForm.get(
      'depositDetails'
    ) as UntypedFormArray;
    let supportingDocLocFrmArray = depositDetailsFrmArray.controls[
      depositDetailsListIndex
    ].get('supportingDocLoc') as UntypedFormArray;

    if (supportingDocLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < supportingDocLocFrmArray.controls.length;
        index++
      ) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = supportingDocLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteDepositDetailsSupportingDoc(
    depositDetailsListIndex: any,
    supportingDocLocIndex: any
  ) {
    let depositDetailsFrmArray = this.preCommAuthForm.get(
      'depositDetails'
    ) as UntypedFormArray;
    let supportingDocLocFrmArray = depositDetailsFrmArray.controls[
      depositDetailsListIndex
    ].get('supportingDocLoc') as UntypedFormArray;

    if (supportingDocLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < supportingDocLocFrmArray.controls.length;
        index++
      ) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = supportingDocLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadProtestSupportingDoc(
    protestDetailsListIndex: any,
    supportingDocLocIndex: any
  ) {
    let protestDetailsFrmArray = this.preCommAuthForm.get(
      'protestDetails'
    ) as UntypedFormArray;
    let supportingDocLocFrmArray = protestDetailsFrmArray.controls[
      protestDetailsListIndex
    ].get('supportingDocLoc') as UntypedFormArray;

    if (supportingDocLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < supportingDocLocFrmArray.controls.length;
        index++
      ) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = supportingDocLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteProtestSupportingDoc(
    protestDetailsListIndex: any,
    supportingDocLocIndex: any
  ) {
    let protestDetailsFrmArray = this.preCommAuthForm.get(
      'protestDetails'
    ) as UntypedFormArray;
    let supportingDocLocFrmArray = protestDetailsFrmArray.controls[
      protestDetailsListIndex
    ].get('supportingDocLoc') as UntypedFormArray;

    if (supportingDocLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < supportingDocLocFrmArray.controls.length;
        index++
      ) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = supportingDocLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  //phase 2 start
  uploadCopyOfAckLocf(submissionListIndex: any, copyOfAckLocIndex: any) {
    let submissionFrmArray = this.preCommAuthForm.get(
      'submissions'
    ) as UntypedFormArray;
    let copyOfAckLocFrmArray = submissionFrmArray.controls[
      submissionListIndex
    ].get('copyOfAckLoc') as UntypedFormArray;

    if (copyOfAckLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < copyOfAckLocFrmArray.controls.length;
        index++
      ) {
        if (index === copyOfAckLocIndex) {
          let itemListuploadControl = copyOfAckLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteCopyOfAckLoc(submissionListIndex: any, copyOfAckLocIndex: any) {
    let submissionFrmArray = this.preCommAuthForm.get(
      'submissions'
    ) as UntypedFormArray;
    let copyOfAckLocFrmArray = submissionFrmArray.controls[
      submissionListIndex
    ].get('copyOfAckLoc') as UntypedFormArray;

    if (copyOfAckLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < copyOfAckLocFrmArray.controls.length;
        index++
      ) {
        if (index === copyOfAckLocIndex) {
          let itemListuploadControl = copyOfAckLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadOtherAttachmentsLoc(
    submissionListIndex: any,
    otherAttachmentsLocIndex: any
  ) {
    let submissionFrmArray = this.preCommAuthForm.get(
      'submissions'
    ) as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionFrmArray.controls[
      submissionListIndex
    ].get('otherAttachmentsLoc') as UntypedFormArray;

    if (otherAttachmentsLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < otherAttachmentsLocFrmArray.controls.length;
        index++
      ) {
        if (index === otherAttachmentsLocIndex) {
          let itemListuploadControl = otherAttachmentsLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteOtherAttachmentsLoc(
    submissionListIndex: any,
    otherAttachmentsLocIndex: any
  ) {
    let submissionFrmArray = this.preCommAuthForm.get(
      'submissions'
    ) as UntypedFormArray;
    let otherAttachmentsLocFrmArray = submissionFrmArray.controls[
      submissionListIndex
    ].get('otherAttachmentsLoc') as UntypedFormArray;

    if (otherAttachmentsLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < otherAttachmentsLocFrmArray.controls.length;
        index++
      ) {
        if (index === otherAttachmentsLocIndex) {
          let itemListuploadControl = otherAttachmentsLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadPhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;

    let itemListsArray = this.preCommAuthForm.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get(
      'phCopyLoc'
    ) as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deletePhCopy(phCopyListIndex: any, phCopyLocIndex: any) {
    let itemListsArray = this.preCommAuthForm.controls.itemList as UntypedFormGroup;
    let phCopyLocFrmArray = itemListsArray.controls[phCopyListIndex].get(
      'phCopyLoc'
    ) as UntypedFormArray;
    if (phCopyLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phCopyLocFrmArray.controls.length; index++) {
        if (index === phCopyLocIndex) {
          let itemListuploadControl = phCopyLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.preCommAuthForm.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[
      addiSubListIndex
    ].get('additionalSubmissionLoc') as UntypedFormArray;

    if (additionalSubLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < additionalSubLocFrmArray.controls.length;
        index++
      ) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteAdditionalSub(addiSubListIndex: any, addiSubLocIndex: any) {
    let itemListsArray = this.preCommAuthForm.controls.itemList as UntypedFormGroup;
    let additionalSubLocFrmArray = itemListsArray.controls[
      addiSubListIndex
    ].get('additionalSubmissionLoc') as UntypedFormArray;
    if (additionalSubLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < additionalSubLocFrmArray.controls.length;
        index++
      ) {
        if (index === addiSubLocIndex) {
          let itemListuploadControl = additionalSubLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.preCommAuthForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  deleteAdjournmentLetter(phRecordListIndex: any, phAdjournmentLetterLocIndex: any) {
    let itemListsArray = this.preCommAuthForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get('phAdjournmentLetterLoc') as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (let index = 0; index < phRecordLocFrmArray.controls.length; index++) {
        if (index === phAdjournmentLetterLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[index] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true
          itemListuploadControl.value['fileLoc'] = null
        }
      }
    }
  }
  uploadPhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.preCommAuthForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get(
      'phRecordIfAnyLoc'
    ) as UntypedFormArray;

    if (phRecordLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < phRecordLocFrmArray.controls.length;
        index++
      ) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deletePhRecord(phRecordListIndex: any, phRecordLocIndex: any) {
    let itemListsArray = this.preCommAuthForm.controls.itemList as UntypedFormGroup;
    let phRecordLocFrmArray = itemListsArray.controls[phRecordListIndex].get(
      'phRecordIfAnyLoc'
    ) as UntypedFormArray;
    if (phRecordLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < phRecordLocFrmArray.controls.length;
        index++
      ) {
        if (index === phRecordLocIndex) {
          let itemListuploadControl = phRecordLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadConsulOthertAttach(
    consultOtherAttachListIndex: any,
    supportingDocLocIndex: any
  ) {
    let consultOtherAttachFrmArray = this.preCommAuthForm.get(
      'consultantCharges'
    ) as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultOtherAttachFrmArray.controls[
      consultOtherAttachListIndex
    ].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < otherAttachmentLocFrmArray.controls.length;
        index++
      ) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = otherAttachmentLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteConsulOthertAttach(
    consultOtherAttachListIndex: any,
    supportingDocLocIndex: any
  ) {
    let consultOtherAttachFrmArray = this.preCommAuthForm.get(
      'consultantCharges'
    ) as UntypedFormArray;
    let otherAttachmentLocFrmArray = consultOtherAttachFrmArray.controls[
      consultOtherAttachListIndex
    ].get('otherAttachmentLoc') as UntypedFormArray;

    if (otherAttachmentLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < otherAttachmentLocFrmArray.controls.length;
        index++
      ) {
        if (index === supportingDocLocIndex) {
          let itemListuploadControl = otherAttachmentLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  //phase 2 end
  //phase 3 start
  uploadOiaLoc(oiaLocIndex: any) {
    let oiaLocCopyArray = this.preCommAuthForm.controls.oiaLoc as UntypedFormArray;
    if (oiaLocCopyArray.controls.length > 0) {
      for (let index = 0; index < oiaLocCopyArray.controls.length; index++) {
        if (index === oiaLocIndex) {
          let itemListuploadControl = oiaLocCopyArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteOiaLocF(oiaLocIndex: any) {
    let oiaLocCopyArray = this.preCommAuthForm.controls.oiaLoc as UntypedFormArray;
    if (oiaLocCopyArray.controls.length > 0) {
      for (let index = 0; index < oiaLocCopyArray.controls.length; index++) {
        if (index === oiaLocIndex) {
          let itemListuploadControl = oiaLocCopyArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  uploadLiabilitySupportingDoc(
    liabilityDetailsListIndex: any,
    copyOfFormLocIndex: any
  ) {
    let liabilityDetailsFrmArray = this.preCommAuthForm.get(
      'liabilityDetails'
    ) as UntypedFormArray;
    let copyOfFormLocFrmArray = liabilityDetailsFrmArray.controls[
      liabilityDetailsListIndex
    ].get('copyOfFormLoc') as UntypedFormArray;

    if (copyOfFormLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < copyOfFormLocFrmArray.controls.length;
        index++
      ) {
        if (index === copyOfFormLocIndex) {
          let itemListuploadControl = copyOfFormLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  deleteLiabilitySupportingDoc(
    liabilityDetailsListIndex: any,
    copyOfFormLocIndex: any
  ) {
    let liabilityDetailsFrmArray = this.preCommAuthForm.get(
      'liabilityDetails'
    ) as UntypedFormArray;
    let copyOfFormLocFrmArray = liabilityDetailsFrmArray.controls[
      liabilityDetailsListIndex
    ].get('copyOfFormLoc') as UntypedFormArray;

    if (copyOfFormLocFrmArray.controls.length > 0) {
      for (
        let index = 0;
        index < copyOfFormLocFrmArray.controls.length;
        index++
      ) {
        if (index === copyOfFormLocIndex) {
          let itemListuploadControl = copyOfFormLocFrmArray.controls[
            index
          ] as UntypedFormGroup;
          itemListuploadControl.value['isdocLocUploadedClicked'] = true;
          itemListuploadControl.value['fileLoc'] = null;
        }
      }
    }
  }
  //phase 3 end

  p2validation() {
    var appealDetailsArr = this.preCommAuthForm.controls
      .submissions as UntypedFormArray;
    if (appealDetailsArr.controls.length > 0) {
      for (var i = 0; i < appealDetailsArr.controls.length; i++) {
        var issues1Control = appealDetailsArr.controls[i] as UntypedFormGroup;
        if (
          issues1Control.controls.refNo.value === null ||
          issues1Control.controls.refNo.value === ''
        ) {
          issues1Control.controls.refNo.setErrors({ required: true });
        } else {
          issues1Control.controls.refNo.setErrors(null);
        }
        if (
          issues1Control.controls.submissionDate.value === null ||
          issues1Control.controls.submissionDate.value === ''
        ) {
          issues1Control.controls.submissionDate.setErrors({ required: true });
        } else {
          issues1Control.controls.submissionDate.setErrors(null);
        }
      }
    }
    return true;
  }
  p3validation() {
    var issues1Array = this.preCommAuthForm.controls.issues as UntypedFormArray;
    if (issues1Array.controls.length > 0) {
      for (var i = 0; i < issues1Array.controls.length; i++) {
        var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        if (
          issues1Control.controls.status.value === null ||
          issues1Control.controls.status.value === '' || issues1Control.controls.status.value === "null"
        ) {
          issues1Control.controls.status.setErrors({ required: true });
        } else {
          issues1Control.controls.status.setErrors(null);
        }
      }
    }

    if (this.preCommAuthForm.value['oiaNo'] === null) {
      this.preCommAuthForm.controls['oiaNo'].setErrors({ required: true });
    }
    if (this.preCommAuthForm.value['dateOfOia'] === null) {
      this.preCommAuthForm.controls['dateOfOia'].setErrors({ required: true });
    }
    if (this.preCommAuthForm.value['dateOfReceiptOfOia'] === null) {
      this.preCommAuthForm.controls['dateOfReceiptOfOia'].setErrors({
        required: true,
      });
    }
    if (this.preCommAuthForm.value['outcome'] === null) {
      this.preCommAuthForm.controls['outcome'].setErrors({ required: true });
    }
    if (
      this.preCommAuthForm.value['furtherAppealDecision'] === null ||
      this.preCommAuthForm.value['furtherAppealDecision'] === 'null' ||
      this.preCommAuthForm.value['furtherAppealDecision'] === '' ||
      this.preCommAuthForm.value['furtherAppealDecision'] === undefined
    ) {
      this.preCommAuthForm.controls['furtherAppealDecision'].setErrors({ required: true });
    }
    if (this.preCommAuthForm.value['forumOfAppeal'] === null || this.preCommAuthForm.value['forumOfAppeal'] === "") {
      this.preCommAuthForm.controls['forumOfAppeal'].setErrors({
        required: true,
      });
    }
    return true;
  }

  tabChanged(tab: any, check?: any) {
    //this.preCommAuthForm.patchValue(this.preCommAuthForm.value);
    this.selectedTab = tab;
    if (tab === 'tab1') {
      this.initializeData();
    } else if (tab === 'tab2') {
    } else if (tab === 'tab3') {
    }
  }

  /*Issue array */
  issuInvArr(): UntypedFormGroup[] {
    return (this.preCommAuthForm.get('issues') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  stayOrderArr(): UntypedFormGroup[] {
    return (this.preCommAuthForm.get('stayOrder') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  addStayOrderDetails() {
    let stayOrderFrmGrp = this.detailOfstayOrder();
    (this.preCommAuthForm.get('stayOrder') as UntypedFormArray).push(stayOrderFrmGrp);
  }

  detailsOfPreDepositeArr() {
    return (this.preCommAuthForm.get('depositDetails') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  addDetailsOfPreDeposite() {
    let depositDetailsFrmGrp = this.detailOfPreDeposite();
    (this.preCommAuthForm.get('depositDetails') as UntypedFormArray).push(
      depositDetailsFrmGrp
    );
  }

  detailOfPreDeposite(data: any = null) {
    return this.fBuild.group({
      issueName: [data ? data?.issueName : null],
      tag: [data ? data?.tag : null],
      dateOfPayment: [data ? data?.dateOfPayment : null],
      modeOfPayment: [data ? data?.modeOfPayment : null,],
      challanNo: [data ? data?.challanNo : null],
      markedForPayment: [data ? data?.markedForPayment : null],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [
        data ? data?.interest : 0,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      supportingDocLoc: new UntypedFormArray([]),
    });
  }

  rmDetailsOfPreDeposite(i: number) {
    (this.preCommAuthForm.get('depositDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfPreDepositeRef.close();
    this.preDepositeChanged();
  }

  uploadcopyOfBankChallan(i: any) {
    var predepositDetailsArray = this.preCommAuthForm.controls
      .depositDetails as UntypedFormArray;
    if (predepositDetailsArray.controls.length > 0) {
      for (
        var index = 0;
        index < predepositDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          var predepositDetailsControl = predepositDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          predepositDetailsControl.value[
            'isCopyOfBankChallanLocDocUploadedClicked'
          ] = true;
        }
      }
    }
    this.isSupportingDocFile = true;
    this.isSupportingDocText = false;
    this.isSupportingDocDWText = false;
    this.isSupportingDocUPText = false;
  }
  deleteDepositFile(i: any) {
    let protestArray = this.preCommAuthForm.controls
      .depositDetails as UntypedFormArray;
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.supportingDocLoc.setValue(null);
          issuesControl.value['isSupportingDocLocDocUploadedClicked'] = true;
        }
      }
    }
  }
  // stayOrder Uplode Function
  uploadSupportingDoc2(i: any) {
    var stayOrderArray = this.preCommAuthForm.controls.stayOrder as UntypedFormArray;
    if (stayOrderArray.controls.length > 0) {
      for (var index = 0; index < stayOrderArray.controls.length; index++) {
        if (index === i) {
          var stayOrderArraysControl = stayOrderArray.controls[
            index
          ] as UntypedFormGroup;
          stayOrderArraysControl.value['isSupportingDocFile2UploadedClicked'] =
            true;
        }
      }
    }
    this.isSupportingDocFile2 = true;
    this.isSupportingDocText2 = false;
    this.isSupportingDocDWText2 = true;
    this.isSupportingDocUPText2 = false;
  }
  deletestayOrderFile(i: any) {
    let liabilityArray = this.preCommAuthForm.controls.stayOrder as UntypedFormArray;
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfStayLoc.setValue(null);
          issuesControl.value['iscopyOfStayLocUploadedClicked'] = true;
        }
      }
    }
  }

  uploadProtestDetailsCopyOfFormLoc(i: any) {
    var protestDetailsArray = this.preCommAuthForm.controls
      .protestDetails as UntypedFormArray;
    if (protestDetailsArray.controls.length > 0) {
      for (
        var index = 0;
        index < protestDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          var protestDetailsControl = protestDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          protestDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] =
            true;
        }
      }
    }
    this.isSupportingFile = true;
    this.isSupportingText = false;
    this.isSupportingDWText = false;
    this.isSupportingUPText = false;
  }

  detailOfstayOrder(data: any = null) {
    return this.fBuild.group({
      applicationDate: [data ? data?.applicationDate : null],
      applicationStatus: [data ? data?.applicationStatus : null],
      typeOfStay: [data ? data?.typeOfStay : null],
      amount: [data ? data?.amount : 0],
      validUptoDate: [data ? data?.validUptoDate : null],
      copyOfStayLoc: new UntypedFormArray([]),
    });
  }

  detailsOfLiabilityAddmittedAndPaid(data: any = null) {
    return this.fBuild.group({
      issueName: [data ? data?.issueName : null],
      tag: [data ? data?.tag : null],
      dateOfPayment: [data ? data?.dateOfPayment : null],
      challanNo: [data ? data?.challanNo : null],
      markedForPayment: [data ? data?.markedForPayment : null],
      modeOfPayment: [data ? data?.modeOfPayment : null],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [
        data ? data?.interest : 0,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      copyOfFormLoc: new UntypedFormArray([]),
    });
  }

  addDetailsOfLiabilityAddmittedAndPaid() {
    let liabilityAddmittedAndPaidFrmGrp =
      this.detailsOfLiabilityAddmittedAndPaid();
    (this.preCommAuthForm.get('liabilityDetails') as UntypedFormArray).push(
      liabilityAddmittedAndPaidFrmGrp
    );
  }

  detailsOfLiabilityAddmittedAndPaidArr(): UntypedFormGroup[] {
    return (this.preCommAuthForm.get('liabilityDetails') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  rmLiabilityAddmittedAndPaidInv(i: number) {
    (this.preCommAuthForm.get('liabilityDetails') as UntypedFormArray).removeAt(i);
    this.rmvLiabilityAddmittedAndPaidModalRef.close();
    this.liabilityAddmittedAndPaidChanged();
  }

  openLiabilityAddmittedAndPaidPop(content: any) {
    this.rmvLiabilityAddmittedAndPaidModalRef = this.modalService.open(content);
  }

  uploadLiabilityDetailsCopyOfForm(i: any) {
    var liabilityDetailsArray = this.preCommAuthForm.controls
      .liabilityDetails as UntypedFormArray;
    if (liabilityDetailsArray.controls.length > 0) {
      for (
        var index = 0;
        index < liabilityDetailsArray.controls.length;
        index++
      ) {
        if (index === i) {
          var liabilityDetailsControl = liabilityDetailsArray.controls[
            index
          ] as UntypedFormGroup;
          liabilityDetailsControl.value['isCopyOfFormLocDocUploadedClicked'] =
            true;
        }
      }
    }
    this.isLiabilitySupportingDocFile = true;
    this.isLiabilitySupportingDocText = false;
    this.isLiabilitySupportingDocDWText = true;
    this.isLiabilitySupportingDocUPText = false;
  }
  deleteliabilityDetailsFile(i: any) {
    let liabilityArray = this.preCommAuthForm.controls
      .liabilityDetails as UntypedFormArray;
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfFormLoc.setValue(null);
          issuesControl.value['isliabilityAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }
  liabilityAddmittedAndPaidChanged() {
    let taxTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let liabilityPaidTotal = 0;
    (
      this.preCommAuthForm.get('liabilityDetails') as UntypedFormArray
    ).controls.forEach((c: any) => {
      const tax =
        typeof c.value.tax === 'string'
          ? c.value.tax.replace(/,/g, '')
          : c.value.tax;
      const interest =
        typeof c.value.interest === 'string'
          ? c.value.interest.replace(/,/g, '')
          : c.value.interest;
      const penalty =
        typeof c.value.penalty === 'string'
          ? c.value.penalty.replace(/,/g, '')
          : c.value.penalty;
      taxTotal += Number(tax);
      interestTotal += Number(interest);
      penaltyTotal += Number(penalty);
      c.patchValue(
        {
          total: this.inrService.formatCurrency(
            String(+Number(tax) + +Number(interest) + +Number(penalty))
          ),
        },
        { emitEvent: false, onlySelf: true }
      );
      const total =
        typeof c.value.total === 'string'
          ? c.value.total.replace(/,/g, '')
          : c.value.total;
      liabilityPaidTotal += Number(total);
    });
    // this.setCummulativeCount();
    this.liabilityAddmittedAndPaidInterestTotal = interestTotal;
    this.liabilityAddmittedAndPaidTaxTotal = taxTotal;
    this.liabilityAddmittedAndPaidPenaltyTotal = penaltyTotal;
    this.liabilityAddmittedAndPaidTotal = liabilityPaidTotal;
  }
  createTab() {
    if (this.caseId != ' ' && this.caseId != undefined) {
      console.log(this.caseId);
      this.isCreateBtn = false;
      this.isUpdateBtn = true;
    } else {
      this.isCreateBtn = true;
      // this.isUpdateBtn = false;
    }
  }

  save() {
    console.log(this.preCommAuthForm, 'preCommAuthForm');
    if (this.caseType != 'REMANDED') {
      this.preCommAuthForm.get('remandedBackForum')?.clearValidators();
      this.preCommAuthForm.get('remandedBackForum')?.updateValueAndValidity();

      this.preCommAuthForm.get('remandedCaseId')?.clearValidators();
      this.preCommAuthForm.get('remandedCaseId')?.updateValueAndValidity();
    }
    this.isSubmitted = true;
    let data: any = {};
    if (this.preCommAuthForm.valid) {
      data = this.preCommAuthForm.value;
      data.issuingAuth1 = {
        officer: this.preCommAuthForm.value.officer,
        designation: this.preCommAuthForm.value.designation,
        jurisdiction: this.preCommAuthForm.value.jurisdiction,
        address: this.preCommAuthForm.value.address,
        contact: this.preCommAuthForm.value.contact,
        email: this.preCommAuthForm.value.email,
      };
      data.issuingAuth3 = {
        officer: this.preCommAuthForm.value.officer3,
        designation: this.preCommAuthForm.value.designation3,
        jurisdiction: this.preCommAuthForm.value.jurisdiction3,
        address: this.preCommAuthForm.value.address3,
        contact: this.preCommAuthForm.value.contact3,
        email: this.preCommAuthForm.value.email3,
      };
      //data.state = this.preAjaForm.value.state.toUpperCase();
      let urlData = {
        type: this.tabType[1].key,
        companyId: this.companyId,
      };
      let protestArray = this.preCommAuthForm.controls.protestDetails as UntypedFormArray
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;

        if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
          let copyOfFormLocControls = (issuesControl.get('supportingDocLoc') as UntypedFormArray).controls;

          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = issuesControl.controls.issueName.value;
          let tagControls = issuesControl.controls.tag.value;
          let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;        
          let challanNoControls = issuesControl.controls.challanNo.value ;
          if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
            this.toaster.showError("Required Fields Are Empty in Payment Table.");
            return;
          }
        }
       
      
      }
      let predepositArray = this.preCommAuthForm.controls.depositDetails as UntypedFormArray
      for (let index = 0; index < predepositArray.controls.length; index++) {
        let issuesControl = predepositArray.controls[index] as UntypedFormGroup;

        if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
          let copyOfFormLocControls = (issuesControl.get('supportingDocLoc') as UntypedFormArray).controls;

          let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!hasSupportingDoc) {
            this.toaster.showError("Required Fields Are Empty for Challan No.");
            return;
          }
          let issueNameControls = issuesControl.controls.issueName.value;
          let tagControls = issuesControl.controls.tag.value;
          let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;        
          let challanNoControls = issuesControl.controls.challanNo.value ;
          //let hasissueName = issueNameControls.some((docControl) => docControl.get('fileLoc')?.value != null);
          if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
            this.toaster.showError("Required Fields Are Empty for Payment table.");
            return;
          }
        }
       
      
      }
      data.caseType = this.caseType;
      console.log(data, 'data');
      this.calculateRemandBackTotal();
      data.remandedPaymentMade = this.preCommAuthForm.value.remandedPaymentMade;
      if (data.protestDetails && data.protestDetails.length) {
        data.protestDetails.forEach((protestDetail: any) => {
          protestDetail.tax = this.inrService.removeCommasAndParseNumber(
            protestDetail.tax
          );
          protestDetail.interest = this.inrService.removeCommasAndParseNumber(
            protestDetail.interest
          );
          protestDetail.penalty = this.inrService.removeCommasAndParseNumber(
            protestDetail.penalty
          );
          protestDetail.total = this.inrService.removeCommasAndParseNumber(
            protestDetail.total
          );
        });
      }

      if (data.depositDetails && data.depositDetails.length) {
        data.depositDetails.forEach((depositDetails: any) => {
          depositDetails.tax = this.inrService.removeCommasAndParseNumber(
            depositDetails.tax
          );
          depositDetails.interest = this.inrService.removeCommasAndParseNumber(
            depositDetails.interest
          );
          depositDetails.penalty = this.inrService.removeCommasAndParseNumber(
            depositDetails.penalty
          );
          depositDetails.total = this.inrService.removeCommasAndParseNumber(
            depositDetails.total
          );
        });
      }

      if (data.consultantCharges && data.consultantCharges.length) {
        data.consultantCharges.forEach((charge: any) => {
          charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees);
        });
      }
      if (data.bankGuarantee && data.bankGuarantee.length) {
        data.bankGuarantee.forEach((bank: any) => {
          bank.bgAmount = this.inrService.removeCommasAndParseNumber(bank.bgAmount)
          bank.fee = this.inrService.removeCommasAndParseNumber(bank.fee)
        })
      }

      if (data.liabilityDetails && data.liabilityDetails.length) {
        data.liabilityDetails.forEach((liability: any) => {
          liability.tax = this.inrService.removeCommasAndParseNumber(
            liability.tax
          );
          liability.interest = this.inrService.removeCommasAndParseNumber(
            liability.interest
          );
          liability.penalty = this.inrService.removeCommasAndParseNumber(
            liability.penalty
          );
          liability.total = this.inrService.removeCommasAndParseNumber(
            liability.total
          );
        });
      }

      if (data.issues && data.issues.length) {
        data.issues.forEach((issue: any) => {
          issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(
            issue.exciseDuty1
          );
          issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(
            issue.serviceTax1
          );
          issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1);
          issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1);
          issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1);
          issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1);
          issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1);
          issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1);
          issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1);
          issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1);
          issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1);
          issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1);
          issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1);
          issue.interest1 = this.inrService.removeCommasAndParseNumber(
            issue.interest1
          );
          issue.penalty1 = this.inrService.removeCommasAndParseNumber(
            issue.penalty1
          );
          issue.total1 = this.inrService.removeCommasAndParseNumber(
            issue.total1
          );

          issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(
            issue.exciseDuty3
          );
          issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(
            issue.serviceTax3
          );
          issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3);
          issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3);
          issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3);
          issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3);
          issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3);
          issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3);
          issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3);
          issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3)
          issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3)
          issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
          issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3);
          issue.interest3 = this.inrService.removeCommasAndParseNumber(
            issue.interest3
          );
          issue.penalty3 = this.inrService.removeCommasAndParseNumber(
            issue.penalty3
          );
          issue.total3 = this.inrService.removeCommasAndParseNumber(
            issue.total3
          );
        });
      }

      data.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(
        data.exciseDuty1Total
      );
      data.serviceTax1Total = this.inrService.removeCommasAndParseNumber(
        data.serviceTax1Total
      );
      data.ec1Total = this.inrService.removeCommasAndParseNumber(data.ec1Total);
      data.shec1Total = this.inrService.removeCommasAndParseNumber(
        data.shec1Total
      );
      data.kkc1Total = this.inrService.removeCommasAndParseNumber(
        data.kkc1Total
      );
      data.vat1Total = this.inrService.removeCommasAndParseNumber(
        data.vat1Total
      );
      data.cst1Total = this.inrService.removeCommasAndParseNumber(
        data.cst1Total
      );
      data.sbc1Total = this.inrService.removeCommasAndParseNumber(
        data.sbc1Total
      );
      data.bcd1Total = this.inrService.removeCommasAndParseNumber(
        data.bcd1Total
      );
      data.sad1Total = this.inrService.removeCommasAndParseNumber(
        data.sad1Total
      );
      data.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty1Total)
      data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)
      data.ent1Total = this.inrService.removeCommasAndParseNumber(
        data.ent1Total
      );
      data.interest1Total = this.inrService.removeCommasAndParseNumber(
        data.interest1Total
      );
      data.penalty1Total = this.inrService.removeCommasAndParseNumber(
        data.penalty1Total
      );
      data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(
        data.totOfTotal1
      );

      data.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(
        data.exciseDuty3Total
      );
      data.serviceTax3Total = this.inrService.removeCommasAndParseNumber(
        data.serviceTax3Total
      );
      data.ec3Total = this.inrService.removeCommasAndParseNumber(data.ec3Total);
      data.shec3Total = this.inrService.removeCommasAndParseNumber(
        data.shec3Total
      );
      data.kkc3Total = this.inrService.removeCommasAndParseNumber(
        data.kkc3Total
      );
      data.vat3Total = this.inrService.removeCommasAndParseNumber(
        data.vat3Total
      );
      data.cst3Total = this.inrService.removeCommasAndParseNumber(
        data.cst3Total
      );
      data.sbc3Total = this.inrService.removeCommasAndParseNumber(
        data.sbc3Total
      );
      data.bcd3Total = this.inrService.removeCommasAndParseNumber(
        data.bcd3Total
      );
      data.sad3Total = this.inrService.removeCommasAndParseNumber(
        data.sad3Total
      );
      data.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(
        data.antiDumpingDuty3Total
      );
      data.igst3Total = this.inrService.removeCommasAndParseNumber(
        data.igst3Total
      );
      data.ent3Total = this.inrService.removeCommasAndParseNumber(
        data.ent3Total
      );
      data.interest3Total = this.inrService.removeCommasAndParseNumber(
        data.interest3Total
      );
      data.penalty3Total = this.inrService.removeCommasAndParseNumber(
        data.penalty3Total
      );
      data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(
        data.totOfTotal3
      );

      let copyOfOioLocArray: any[] = [];
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.prevOrderLoc.value.length;
        i++
      ) {
        const element = this.preCommAuthForm.controls.prevOrderLoc.value[i];
        if (
          element.fileLoc !== '' &&
          element.fileLoc !== null &&
          element.fileLoc !== undefined
        ) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          copyOfOioLocArray.push(element);
        }
      }
      this.preCommAuthForm.value.prevOrderLoc = copyOfOioLocArray;
      let oiaLocArray: any[] = [];
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.oiaLoc.value.length;
        i++
      ) {
        const element = this.preCommAuthForm.controls.oiaLoc.value[i];
        if (
          element.fileLoc !== '' &&
          element.fileLoc !== null &&
          element.fileLoc !== undefined
        ) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          oiaLocArray.push(element);
        }
      }
      this.preCommAuthForm.value.oiaLoc = oiaLocArray;
      let StayOrderCopyOfStayLocArray: any[] = [];
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.stayOrder.value.length;
        i++
      ) {
        StayOrderCopyOfStayLocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.stayOrder.value[i].copyOfStayLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            StayOrderCopyOfStayLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.stayOrder.value[i].copyOfStayLoc =
          StayOrderCopyOfStayLocArray;
      }
      let DepositSupportingDocLocArray: any[] = [];
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.depositDetails.value.length;
        i++
      ) {
        DepositSupportingDocLocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.depositDetails.value[i]
            .supportingDocLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            DepositSupportingDocLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.depositDetails.value[i].supportingDocLoc =
          DepositSupportingDocLocArray;
      }
      let protestSupportingDocLocArray: any[] = [];
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.protestDetails.value.length;
        i++
      ) {
        protestSupportingDocLocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.protestDetails.value[i]
            .supportingDocLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestSupportingDocLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.protestDetails.value[i].supportingDocLoc =
          protestSupportingDocLocArray;
      }
      let LiabilitySupportingDocArray: any[] = [];
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.liabilityDetails.value.length;
        i++
      ) {
        LiabilitySupportingDocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            LiabilitySupportingDocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.liabilityDetails.value[i].copyOfFormLoc =
          LiabilitySupportingDocArray;
      }

      this.inrFormattingRemoving();
      // this.letterP1Form.value.dateOfLetter = this.datePipe.transform(this.letterP1Form.value.dateOfLetter, 'yyyy-MM-dd');
      this.preLitService.saveApaData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          if (this.isDataSaved === true) {
            // this.p1validation();
          }
          this.toaster.showSuccess(response.message);
          this.savedPreCaseId = response.response;
          this.isDataSaved = true;
          this.isPhase2 = true;
          this.isCreateBtn = false;
          this.isUpdateBtn = true;
          this.saveCaseId.emit(this.savedPreCaseId);
          this.initializeData();
        } else {
          this.toaster.showSuccess(response.message);
        }
      });
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }

  openProceedWithPreferredForumAppealPop(content: any) {
    this.proceedWithPreferredForumAppeal = this.modalService.open(content);
  }

  rmProceedWithPreferredForumAppeal(removeSelectedValue: any = false) {
    if (removeSelectedValue)
      this.preCommAuthForm.get('forumOfAppeal')?.setValue('');
    this.proceedWithPreferredForumAppeal.close();
  }

  getForumAppealValue(value: any) {
    let [findValue] = this.forumAppeal.filter((forum) => {
      return forum.key === value;
    });
    if (findValue) return findValue.value;
    return;
  }

  // uploadSupporting() {
  //   this.isSupportingFile = true;
  //   this.isSupportingText = false;
  //   this.isSupportingDWText = true;
  //   this.isSupportingUPText = false;
  // }

  // LiabilityuploadSupportingDoc() {
  //   this.isLiabilitySupportingDocFile = true;
  //   this.isLiabilitySupportingDocText = false;
  //   this.isLiabilitySupportingDocDWText = true;
  //   this.isLiabilitySupportingDocUPText = false;
  // }

  // uploadSupportingDoc() {
  //   this.isSupportingDocFile = true;
  //   this.isSupportingDocText = false;
  //   this.isSupportingDocDWText = true;
  //   this.isSupportingDocUPText = false;
  // }

  // uploadSupportingDoc2() {
  //   this.isSupportingDocFile2 = true;
  //   this.isSupportingDocText2 = false;
  //   this.isSupportingDocDWText2 = true;
  //   this.isSupportingDocUPText2 = false;
  // }

  // uploadotherAttachments() {
  //   this.isOtherAttachmentFile = true;
  //   this.isOtherAttachmentText = false;
  //   this.isOtherAttachmentDWText = true;
  //   this.isOtherAttachmentUPText = false;
  // }

  // uploadCopyOfAckLoc() {
  //   this.copyOfAckLocDocFile = true;
  //   this.copyOfAckLocDocText = false;
  //   this.copyOfAckLocDWText = true;
  //   this.copyOfAckLocUPText = false;
  // }

  openRemoveStayOrderDeatilPop(content: any) {
    this.rmvStayOrderDetailsRef = this.modalService.open(content);
  }

  openRemoveDetailsOfPreDepositePop(content: any) {
    this.rmvDetailsOfPreDepositeRef = this.modalService.open(content);
  }

  rmStayOrderDetails(i: number) {
    (this.preCommAuthForm.get('stayOrder') as UntypedFormArray).removeAt(i);
    this.rmvStayOrderDetailsRef.close();
  }

  getDetailOfPaymentArr(): UntypedFormGroup[] {
    return (this.preCommAuthForm.get('protestDetails') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }
  getDetailOfBankGuaranteeArr(): UntypedFormGroup[] {
    return (this.preCommAuthForm.get('bankGuarantee') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  detailOfBankGuarantee(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      bgNo: [data ? data?.bgNo : null,[Validators.maxLength(100)]],
      issueDate: [data ? data?.issueDate : null],
      expiryDate: [data ? data?.expiryDate : null],
      bgAmount: [data ? data?.bgAmount : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      fee: [data ? data?.fee : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      bgStatus: [data ? data?.bgStatus  : null],
      remark:[data ? data?.remark  : null, [Validators.maxLength(100)]],
    })
  }

  addDetailOfBankGuarantee() {
    let bankGuaranteeDetailsFrmGrp = this.detailOfBankGuarantee();
    (this.preCommAuthForm.get('bankGuarantee') as UntypedFormArray).push(bankGuaranteeDetailsFrmGrp);
  }

  rmBankGuarantee(i: number) {
    (this.preCommAuthForm.get('bankGuarantee') as UntypedFormArray).removeAt(i);
    this.rmvbankGuaranteeRef.close();
  }

  rmPaymentUnderProtest(i: number) {
    (this.preCommAuthForm.get('protestDetails') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfPaymentRef.close();
    this.paymentUnderProtestp1Changed();
  }

  addDetailOfPayment() {
    let protestDetailsFrmGrp = this.detailOfprotestDetails();
    (this.preCommAuthForm.get('protestDetails') as UntypedFormArray).push(
      protestDetailsFrmGrp
    );
  }

  deleteUploadFile(i: any) {
    let protestArray = this.preCommAuthForm.controls
      .protestDetails as UntypedFormArray;
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.supportingDocLoc.setValue(null);
          issuesControl.value['isSupportingDocLocDocUploadedClicked'] = true;
        }
      }
    }
  }

  detailOfprotestDetails(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      issueName: [data ? data?.issueName : null],
      tag: [data ? data?.tag : null],
      dateOfPayment: [data ? data?.dateOfPayment : null],
      challanNo: [data ? data?.challanNo : null],
      markedForPayment: [data ? data?.markedForPayment : null],
      modeOfPayment: [data ? data?.modeOfPayment : null],
      accountingRef:[data ? data?.accountingRef:null,[Validators.maxLength(100)]],
      tax: [data ? data?.tax : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      interest: [
        data ? data?.interest : 0,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      penalty: [data ? data?.penalty : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      total: [data ? data?.total : 0, [Validators.pattern(/^-?[0-9,]+$/)]],
      supportingDocLoc: new UntypedFormArray([]),
    });
  }

  paymentUnderProtestp1Changed() {
    let taxTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let protestTotal = 0;

    (this.preCommAuthForm.get('protestDetails') as UntypedFormArray).controls.forEach(
      (c: any) => {
        const tax =
          typeof c.value.tax === 'string'
            ? c.value.tax.replace(/,/g, '')
            : c.value.tax;
        const interest =
          typeof c.value.interest === 'string'
            ? c.value.interest.replace(/,/g, '')
            : c.value.interest;
        const penalty =
          typeof c.value.penalty === 'string'
            ? c.value.penalty.replace(/,/g, '')
            : c.value.penalty;
        taxTotal += Number(tax);
        interestTotal += Number(interest);
        penaltyTotal += Number(penalty);
        c.patchValue(
          {
            total: this.inrService.formatCurrency(
              String(+Number(tax) + +Number(interest) + +Number(penalty))
            ),
          },
          { emitEvent: false, onlySelf: true }
        );
        const total =
          typeof c.value.total === 'string'
            ? c.value.total.replace(/,/g, '')
            : c.value.total;
        protestTotal += Number(total);
      }
    );

    this.protestInterestTotal = interestTotal;
    this.protestTaxTotal = taxTotal;
    this.protestPenaltyTotal = penaltyTotal;
    this.protestTotal = protestTotal;
    // this.setCummulativeCount();
  }

  preDepositeChanged() {
    let taxTotal = 0;
    let interestTotal = 0;
    let penaltyTotal = 0;
    let preDepositeTotal = 0;

    (this.preCommAuthForm.get('depositDetails') as UntypedFormArray).controls.forEach(
      (c: any) => {
        const tax =
          typeof c.value.tax === 'string'
            ? c.value.tax.replace(/,/g, '')
            : c.value.tax;
        const interest =
          typeof c.value.interest === 'string'
            ? c.value.interest.replace(/,/g, '')
            : c.value.interest;
        const penalty =
          typeof c.value.penalty === 'string'
            ? c.value.penalty.replace(/,/g, '')
            : c.value.penalty;
        taxTotal += Number(tax);
        interestTotal += Number(interest);
        penaltyTotal += Number(penalty);
        c.patchValue(
          {
            total: this.inrService.formatCurrency(
              String(+Number(tax) + +Number(interest) + +Number(penalty))
            ),
          },
          { emitEvent: false, onlySelf: true }
        );
        const total =
          typeof c.value.total === 'string'
            ? c.value.total.replace(/,/g, '')
            : c.value.total;
        preDepositeTotal += Number(total);
      }
    );
    this.preDepositeTaxTotal = taxTotal;
    this.preDepositeInterestTotal = interestTotal;
    this.preDepositePenaltyTotal = penaltyTotal;
    this.preDepositeTotal = preDepositeTotal;
  }

  openRemoveDetailsOfPaymentPop(content: any) {
    this.rmvDetailsOfPaymentRef = this.modalService.open(content);
  }
  openRemoveBankGuaranteePop(content: any) {
    this.rmvbankGuaranteeRef = this.modalService.open(content);

  }

  getDetailOfAppeal() {
    return (this.preCommAuthForm.get('submissions') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  detailOfAppeal(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      refNo: [data ? data?.refNo : null],
      submissionDate: [data ? data?.submissionDate : null],
      submissionMode: [data ? data?.submissionMode : null],
      copyOfAckLoc: new UntypedFormArray([]),
      otherAttachmentsLoc: new UntypedFormArray([]),
    });
  }

  addDetailOfAppeal() {
    let appealFrmGrp = this.detailOfAppeal();
    (this.preCommAuthForm.get('submissions') as UntypedFormArray).push(appealFrmGrp);
  }

  openRemoveDetailsOfAppeal(content: any) {
    this.rmvDetailsOfAppealRef = this.modalService.open(content);
  }

  rmDetailsOfAppeal(i: number) {
    (this.preCommAuthForm.get('submissions') as UntypedFormArray).removeAt(i);
    this.rmvDetailsOfAppealRef.close();
  }

  uploadCopyOfAckLoc(i: any) {
    var submissionsArray = this.preCommAuthForm.controls
      .submissions as UntypedFormArray;
    if (submissionsArray.controls.length > 0) {
      for (var index = 0; index < submissionsArray.controls.length; index++) {
        if (index === i) {
          var appealDetailControl = submissionsArray.controls[
            index
          ] as UntypedFormGroup;
          appealDetailControl.value['isAckCopyLocDocUploadedClicked'] = true;
        }
      }
    }
    this.copyOfAckLocDocFile = true;
    this.copyOfAckLocDocText = false;
    this.copyOfAckLocDWText = true;
    this.copyOfAckLocUPText = false;
  }
  deleteSubmissionsUploadFile(i: any) {
    let submissionArray = this.preCommAuthForm.controls
      .submissions as UntypedFormArray;
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.copyOfAckLoc.setValue(null);
          issuesControl.value['iscopyOfAckLocUploadedClicked'] = true;
        }
      }
    }
  }

  uploadotherAttachments(i: any) {
    var submissionsArray = this.preCommAuthForm.controls
      .submissions as UntypedFormArray;
    if (submissionsArray.controls.length > 0) {
      for (var index = 0; index < submissionsArray.controls.length; index++) {
        if (index === i) {
          var appealDetailControl = submissionsArray.controls[
            index
          ] as UntypedFormGroup;
          appealDetailControl.value['isOtherAttachmentsLocDocUploadedClicked'] =
            true;
        }
      }
    }
    this.isOtherAttachmentFile = true;
    this.isOtherAttachmentText = false;
    this.isOtherAttachmentDWText = true;
    this.isOtherAttachmentUPText = false;
  }

  deleteSubmissions2UploadFile(i: any) {
    let submissionArray = this.preCommAuthForm.controls
      .submissions as UntypedFormArray;
    if (submissionArray.controls.length > 0) {
      for (let index = 0; index < submissionArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = submissionArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentsLoc.setValue(null);
          issuesControl.value['isOtherAttachmentsLocUploadedClicked'] = true;
        }
      }
    }
  }

  consultantInvArr(): UntypedFormGroup[] {
    return (this.preCommAuthForm.get('consultantCharges') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  consultantDataAutofil(index: number) {
    console.log('index:', index);
    const consultantGrp = this.consultantInvArr()[index];
    const selectedConsultantName = consultantGrp.get('consultant')?.value;

    // Find the selected consultant in the consultantList
    const selectedConsultant = this.consultantList.find(
      (obj: any) => obj.consultantName === selectedConsultantName
    );

    if (selectedConsultant) {
      // Auto-fill the remaining fields
      consultantGrp.get('firm')?.setValue(selectedConsultant.firmName || '');
      consultantGrp.get('email')?.setValue(selectedConsultant.email || '');
      consultantGrp.get('contact')?.setValue(selectedConsultant.contact || '');
    } else {
      // Clear the fields if consultant is not found
      consultantGrp.get('firm')?.reset();
      consultantGrp.get('email')?.reset();
      consultantGrp.get('contact')?.reset();
      consultantGrp.get('natureOfWork')?.reset();
      consultantGrp.get('fees')?.reset();
      consultantGrp.get('otherAttachmentLoc')?.reset();
    }

    console.log('data populated succesfully !!')
  }

  addConsultantInv() {
    let consutantFrmGrp = this.consultantChargesList();
    (this.preCommAuthForm.get('consultantCharges') as UntypedFormArray).push(
      consutantFrmGrp
    );
    return false;
  }

  consultantChargesList(data: any = null): UntypedFormGroup {
    return this.fBuild.group({
      firm: [data ? data.firm : null],
      consultant: [data ? data.consultant : null],
      natureOfWork: [data ? data.natureOfWork : null],
      fees: [data ? data.fees : 0],
      otherAttachmentLoc: new UntypedFormArray([]),
      email: [data ? data.email : null, [Validators.pattern(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)]],
      contact: [data ? data.contact : null]
    });
  }

  openRmvconsultantPop(content: any, i: any) {
    this.rmvConsultantModalRef = this.modalService.open(content);
  }

  rmConsultantInv(i: number) {
    (this.preCommAuthForm.get('consultantCharges') as UntypedFormArray).removeAt(i);
    this.rmvConsultantModalRef.close();
    this.feesChargedChanged();
  }

  feesChargedChanged() {
    let feesChargedTotal: number = 0;
    (
      this.preCommAuthForm.get('consultantCharges') as UntypedFormArray
    ).controls.forEach((c: any) => {
      const fees =
        typeof c.value.fees === 'string'
          ? c.value.fees.replace(/,/g, '')
          : c.value.fees;
      feesChargedTotal += Number(fees);
    });

    this.feesChargedTotal = feesChargedTotal;
  }

  uploadoConsultantAttachmentsLoc(i: any) {
    var consultantArray = this.preCommAuthForm.controls
      .consultantCharges as UntypedFormArray;
    if (consultantArray.controls.length > 0) {
      for (var index = 0; index < consultantArray.controls.length; index++) {
        if (index === i) {
          var consultantControl = consultantArray.controls[index] as UntypedFormGroup;
          consultantControl.value['isOtherAttachmentLocDocUploadedClicked'] =
            true;
        }
      }
    }
    this.isconsultantattachmentsLocFile = true;
    this.isconsultantattachmentsLocText = false;
    this.isconsultantDWattachmentsLoc = true;
    this.isconsultantUPattachmentsLoc = false;
  }

  deleteConsultantFile(i: any) {
    let consultantArray = this.preCommAuthForm.controls
      .consultantCharges as UntypedFormArray;
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        if (index == i) {
          let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
          issuesControl.controls.otherAttachmentLoc.setValue(null);
          issuesControl.value['isOtherAttachmentDocUploadedClicked'] = true;
        }
      }
    }
  }

  newIssueInvArray(): UntypedFormGroup {
    return this.fBuild.group({
      issue: [null, [Validators.required]],
      div: [null],
      keyword: [null],
      riskParam: [null, [Validators.required]],
      tag: [null],
      remark1: [null, [Validators.maxLength(100)]],
      exciseDuty1: [
        this.action == 'update' ? '0' : this.caseData?.exciseDuty1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      ec1: [
        this.action == 'update' ? '0' : this.caseData?.ec1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      shec1: [
        this.action == 'update' ? '0' : this.caseData?.shec1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      serviceTax1: [
        this.action == 'update' ? '0' : this.caseData?.serviceTax1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      kkc1: [
        this.action == 'update' ? '0' : this.caseData?.kkc1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      sbc1: [
        this.action == 'update' ? '0' : this.caseData?.sbc1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      vat1: [
        this.action == 'update' ? '0' : this.caseData?.vat1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      cst1: [
        this.action == 'update' ? '0' : this.caseData?.cst1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      bcd1: [
        this.action == 'update' ? '0' : this.caseData?.bcd1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      sad1: [this.action == 'update' ? '0' : this.caseData?.sad1, [Validators.pattern(/^-?[0-9,]+$/)]],
      antiDumpingDuty1: [this.action == 'update' ? '0' : this.caseData?.antiDumpingDuty1, [Validators.pattern(/^-?[0-9,]+$/)]],
      igst1: [this.action == 'update' ? '0' : this.caseData?.igst1, [Validators.pattern(/^-?[0-9,]+$/)]],
      ent1: [
        this.action == 'update' ? '0' : this.caseData?.ent1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      interest1: [
        this.action == 'update' ? '0' : this.caseData?.interest1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      penalty1: [
        this.action == 'update' ? '0' : this.caseData?.penalty1,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      total1: [this.action == 'update' ? '0' : this.caseData?.total1,
      [Validators.pattern(/^-?[0-9,]+$/)]
      ],
      exciseDuty3: [
        this.action == 'update' ? '0' : this.caseData?.exciseDuty3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      ec3: [
        this.action == 'update' ? '0' : this.caseData?.ec3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      shec3: [
        this.action == 'update' ? '0' : this.caseData?.shec3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      serviceTax3: [
        this.action == 'update' ? '0' : this.caseData?.serviceTax3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      kkc3: [
        this.action == 'update' ? '0' : this.caseData?.kkc3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      sbc3: [
        this.action == 'update' ? '0' : this.caseData?.sbc3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      vat3: [
        this.action == 'update' ? '0' : this.caseData?.vat3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      cst3: [
        this.action == 'update' ? '0' : this.caseData?.cst3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      bcd3: [
        this.action == 'update' ? '0' : this.caseData?.bcd3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      sad3: [this.action == 'update' ? '0' : this.caseData?.sad3, [Validators.pattern(/^-?[0-9,]+$/)]],
      antiDumpingDuty3: [this.action == 'update' ? '0' : this.caseData?.antiDumpingDuty3, [Validators.pattern(/^-?[0-9,]+$/)]],
      igst3: [this.action == 'update' ? '0' : this.caseData?.igst3, [Validators.pattern(/^-?[0-9,]+$/)]],
      ent3: [
        this.action == 'update' ? '0' : this.caseData?.ent3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      interest3: [
        this.action == 'update' ? '0' : this.caseData?.interest3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      penalty3: [
        this.action == 'update' ? '0' : this.caseData?.penalty3,
        [Validators.pattern(/^-?[0-9,]+$/)],
      ],
      total3: [this.action == 'update' ? '0' : this.caseData?.total3,
      [Validators.pattern(/^-?[0-9,]+$/)]
      ],
      status: [null],
      details: new UntypedFormArray([]),
      userEnteredInterest1: [null, []],
      userEnteredInterest3: [null, []],
      calculatedInterest1: [null, []],
      calculatedInterest3: [null, []],
      interestRate1: [null, []],
      interestRate3: [null, []],
      periodFromDate1: [null, []],
      periodToDate1: [null, []],
      periodFromDate3: [null, []],
      periodToDate3: [null, []],
    });
  }
  addIssueInv() {
    let issueFrmGrp = this.newIssueInvArray();
    (issueFrmGrp.get('details') as UntypedFormArray).push(this.newDetailsFrmGrp());
    (this.preCommAuthForm.get('issues') as UntypedFormArray).push(issueFrmGrp);
  }
  rmIssueInv(i: number, isRemandedBack: boolean = false) {
    (this.preCommAuthForm.get('issues') as UntypedFormArray).removeAt(i);
    if (!isRemandedBack) this.rmvIssueModalRef.close();
    this.issueValueChange();
  }
  /*Issue array */
  // details array
  getdetails(div: UntypedFormGroup): UntypedFormGroup[] {
    return (div.get('details') as UntypedFormArray).controls as UntypedFormGroup[];
  }
  newDetailsFrmGrp() {
    return new UntypedFormGroup({
      finYear: new UntypedFormControl(null),
      quarter: new UntypedFormControl(null),
      provisionAmt1: new UntypedFormControl(null),
      contLiabilityAmt1: new UntypedFormControl(null),
      otherLiability1: new UntypedFormControl(null),
      provisionChangeReason1: new UntypedFormControl(null),
      provisionNotMadeReason1: new UntypedFormControl(null),
      contLiabReason1: new UntypedFormControl(null),
      otherLiabReason1: new UntypedFormControl(null),
      considerInCaroReport1: new UntypedFormControl(null),
      caroAmt1: new UntypedFormControl(null),
    });
  }
  addDetail(j: any) {
    let issuesFrmArray = this.preCommAuthForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[j].get(
      'details'
    ) as UntypedFormArray;
    detailsFrmArray.push(this.newDetailsFrmGrp());
  }
  rmDetail(i: number, k: number) {
    let issuesFrmArray = this.preCommAuthForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get(
      'details'
    ) as UntypedFormArray;
    detailsFrmArray.removeAt(k);
    this.rmvDetailModalRef.close();
  }
  // details array
  /*ph div array */
  apaDivArr(): UntypedFormArray {
    return this.preCommAuthForm.get('itemList') as UntypedFormArray;
  }

  // phTabArr(): FormArray {
  //   return this.adjP1Form.get("itemList") as FormArray
  // }
  newphArray(): UntypedFormGroup {
    return this.fBuild.group({
      phNoticeDate: [null],
      dateOfPhScheduled: [null],
      phCopyLoc: new UntypedFormArray([]),
      adjournedOrNot: [false],
      attended: [null],
      consultant: [this.caseData?.consultant1, []],
      personResponsible: [this.caseData?.personResponsible1, []],
      dateOfsubmission: [null],
      additionalSubmissionLoc: new UntypedFormArray([]),
      phRecordIfAnyLoc: new UntypedFormArray([]),
      phDiscussionNotes: [null],
      phAdjournmentLetterLoc: new UntypedFormArray([]),
      mode: ['', []],
      phRefNo:['', []],
      phTime:['', []],
    });
  }
  createAdjDiv() {
    // this.adjPhTablist.push(this.newphArray());
    this.apaDivArr().push(this.newphArray());
  }
  removeLetter(i: number) {
    this.apaDivArr().removeAt(i);
  }

  deleteitemListFile(index: any) {
    var itemListArray = this.preCommAuthForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.controls.phRecordIfAnyLoc.setValue(null);
          if (this.caseItemList.length)
            this.caseItemList[index].phRecordIfAnyLoc = null;
        }
      }
    }
  }
  deletePHNoticeFile(index: any) {
    var itemListArray = this.preCommAuthForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.controls.phCopyLoc.setValue(null);
          if (this.caseItemList.length)
            this.caseItemList[index].phCopyLoc = null;
        }
      }
    }
  }
  deleteSubmissionFile(index: any) {
    let itemListArray = this.preCommAuthForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (let i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          let issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.controls.additionalSubmissionLoc.setValue(null);
          if (this.caseItemList.length)
            this.caseItemList[index].additionalSubmissionLoc = null;
        }
      }
    }
  }
  /*ph div array */

  phaseChanged(phase: any, i: any) {
    this.selectedPhase = phase;
    if (phase === 'phase' + i) {
    }
  }
  getCurrentYear() {
    const date = new Date();
    return date.getFullYear();
  }
  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2011;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = startYear + '-' + (startYear + 1);
      this.finyearData.push({ year: financialYrRange, disabled: false });
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }
  checkpreDepositNo(event: any) {
    if (event.target.value === 'false') {
      this.disablField = true;
    } else {
      this.disablField = false;
    }
  }
  changePayMode(event: any) {
    if (event.target.value === 'ITC') {
      this.disablBankChallan = true;
    } else {
      this.disablBankChallan = false;
    }
  }
  setDate() {
    this.currentYear = new Date().getFullYear() + 1;
  }
  setStateAutoPop() {
    if (this.selectedFilling.gstin) {
      this.selectedStateCode = this.selectedFilling.gstin.slice(0, 2);
      this.selectedStateName = this.commonServ.getStateName(
        this.selectedStateCode
      );
    }
  }
  personResponsibleData() {
    let model = {
      companyid: this.selectedCompanyId,
    };
    this.preLitService.getUserResList(model).subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.userRoleLs = response.response;
      }
    });
  }

  onClickToggleDropdown(){
    this.isToggleDropdown = !this.isToggleDropdown;
  }

  consultantData(){
    this.masterService.getAllConsultantMaster().subscribe((response: any) => {
      if (response.status === 1) {
        this.consultantList = response.response.data;
        console.log('list:', this.consultantList);
        const userMailID: any = sessionStorage.getItem('UserId');
        const matchingConsultant = this.consultantList.find(
          (consultant: any) => consultant.email === userMailID
        );

        if (matchingConsultant) {
          this.preCommAuthForm.controls.consultant1.patchValue(matchingConsultant.consultantName)
        }
      }
    });
  }

  onClickToggleConsultantDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleConsultantDropdown = !this.isToggleConsultantDropdown;
    } else if (phase === 'p2') {
      this.isToggleConsultantDropdown2 = !this.isToggleConsultantDropdown2;
    } else if (phase === 'CC'){
      this.isToggleConsultantDropdown3 = !this.isToggleConsultantDropdown3;
    }
  }
  onClickToggleSegmentDropdown(phase: string){
    if (phase === 'p1') {
      this.isToggleSegmentDropdown = !this.isToggleSegmentDropdown;
    } else if (phase === 'p2') {
      this.isToggleSegmentDropdown2 = !this.isToggleSegmentDropdown2;
    }
  }

  changeadjOrNot(event: any, i: any) {
    if (event.target.value === 'true') {
      this.createAdjDiv();
    }
  }
  openRmvIssuePop(content: any, i: any) {
    this.rmvIssueModalRef = this.modalService.open(content);
  }
  openDetailPop(content: any, i: any) {
    this.modalService.open(content);
    this.finYearSelelctEvent(i);
    this.quarterSelectEvent(i);
  }
  openRmvDetailPop(content: any, i: any, k: any) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }
  changeState(event: any) {
    this.selectedState = event
      ? event.target.value
      : this.preCommAuthForm.value.state;
    this.fetchRegNo();
  }
  changeRegNo(event: any){
    this.selectedRegDropVal = event ? event.target.value : this.preCommAuthForm.value.regNo;
   this.getCompanyTags(this.selectedRegDropVal);
  }

  changeTax(event: any) {
    this.selectedActType = event ? event.target.value : this.preCommAuthForm.value.actType;
    this.fetchRegNo();
    let model = {
      companyid: this.selectedCompanyId,
      actType: this.selectedActType,
    };
    this.getState(model);
    if (this.selectedActType === 'EXCISE') {
      this.isExcise = true;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preCommAuthForm.controls.issues as UntypedFormArray;
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({
            serviceTax1: '0',
            kkc1: '0',
            sbc1: '0',
            vat1: '0',
            cst1: '0',
            bcd1: '0',
            sad1: '0',
            antiDumpingDuty1: '0',
            igst1: '0',
            ent1: '0',
          });
        }
      }
    } else if (this.selectedActType === 'SERVICE TAX') {
      this.isExcise = false;
      this.isServiceTax = true;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preCommAuthForm.controls.issues as UntypedFormArray;
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({
            exciseDuty1: '0',
            vat1: '0',
            cst1: '0',
            bcd1: '0',
            sad1: '0',
            antiDumpingDuty1: '0',
            igst1: '0',
            ent1: '0',
          });
        }
      }
    } else if (this.selectedActType === 'VAT') {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = true;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preCommAuthForm.controls.issues as UntypedFormArray;
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({
            exciseDuty1: '0',
            ec1: '0',
            shec1: '0',
            serviceTax1: '0',
            kkc1: '0',
            sbc1: '0',
            cst1: '0',
            bcd1: '0',
            sad1: '0',
            antiDumpingDuty1: '0',
            igst1: '0',
            ent1: '0',
          });
        }
      }
    } else if (this.selectedActType === 'CST') {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = true;
      this.isCustomsDuty = false;
      this.isEntryTax = false;
      var issues1Array = this.preCommAuthForm.controls.issues as UntypedFormArray;
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({
            exciseDuty1: '0',
            ec1: '0',
            shec1: '0',
            serviceTax1: '0',
            kkc1: '0',
            sbc1: '0',
            vat1: '0',
            bcd1: '0',
            sad1: '0',
            antiDumpingDuty1: '0',
            igst1: '0',
            ent1: '0',
          });
        }
      }
    } else if (this.selectedActType === 'CUSTOMS DUTY') {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = true;
      this.isEntryTax = false;
      var issues1Array = this.preCommAuthForm.controls.issues as UntypedFormArray;
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({
            exciseDuty1: '0',
            ec1: '0',
            shec1: '0',
            serviceTax1: '0',
            kkc1: '0',
            sbc1: '0',
            vat1: '0',
            cst1: '0',
          });
        }
      }
    } else if (this.selectedActType === 'ENTRY TAX') {
      this.isExcise = false;
      this.isServiceTax = false;
      this.isVat = false;
      this.isCst = false;
      this.isCustomsDuty = false;
      this.isEntryTax = true;
      var issues1Array = this.preCommAuthForm.controls.issues as UntypedFormArray;
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[0] as UntypedFormGroup;
          issues1Control.patchValue({
            exciseDuty1: '0',
            ec1: '0',
            shec1: '0',
            serviceTax1: '0',
            kkc1: '0',
            sbc1: '0',
            vat1: '0',
            cst1: '0',
          });
        }
      }
    }
  }
  actTypeFetch() {
    // let model = {
    //   companyId: localStorage.getItem('selectedId'),
    //   userMailId: this.selectedUserId
    // }
    // this.preLitService.fetchWatchListFormData(model).subscribe((response: any) => {
    //   if (response.status === 1) {
    //     this.isActdisable = true;
    //     this.actTypes = response.response;
    //     this.toaster.showSuccess(response.message);
    //   }
    //   else {
    //     this.toaster.showError(response.message);
    //   }
    // });
    this.isActdisable = false;
    this.actTypes = this.typeOfAct;
  }

  getState(model: any) {
    if (this.caseData) {
      model.pan = this.caseData.pan ? this.caseData.pan : this.pan;
    } else {
      model.pan = this.pan;
    }
    this.preLitService.getActwiseState(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.actStateList = response.response;
      }
    });
  }
  getValidDate(date: any): any {
    if (date.value) {
      let d = date.value.split('-');
      const dateValidation = new Date(d[1] + '-' + d[0] + '-' + d[2]);
      return dateValidation;
    } else {
      return new Date();
    }
  }

  whetherCallBookChanged() {
    this.disablePhase3 =
      this.preCommAuthForm.value.whetherCaseCallBook === 'true' ||
        this.preCommAuthForm.value.whetherCaseCallBook === true
        ? true
        : false;
  }

  fetchRegNo() {
    let model: any = {};
    if (this.caseData) {
      model.actType = this.caseData.actType ? this.caseData.actType : this.selectedActType;
      model.state = this.caseData.state ? this.caseData.state : this.selectedState;
      model.pan = this.caseData.pan ? this.caseData.pan : this.pan;
    } else {
      model.actType = this.selectedActType;
      model.state = this.selectedState;
      model.pan = this.pan;
    }

    this.preLitService.fetchRegNum(model).subscribe((response: any) => {
      if (response.status === 1) {
        this.regNumRes = response.response;
        this.toaster.showSuccess(response.message);
        if(this.regNumRes  !== null){
          this.selectedRegNo = this.regNumRes[0].regNo;
        }
        this.getCompanyTags(this.selectedRegNo);
        // this.regNumRes.forEach((element: any) => {
        //   this.preCommAuthForm.patchValue({ regNo: element.regNo });
        // });
      } else {
        // this.toaster.showError(response.message);
        this.preCommAuthForm.patchValue({ regNo: '' });
      }
    });
  }

  //fetch case
  initializeData() {
    if (this.currentTab != this.activeTab) {
      this.enablePrevStageTab.emit(this.activeStages);
    }
    if (this.savedPreCaseId) {
      this.caseId = this.savedPreCaseId;
    }
    if (this.parentCaseId) {
      this.caseId = this.parentCaseId;
    }
    if (this.caseId != null) {
      let model = {
        type: this.tabType[1].key,
        caseId: this.caseId,
      };
      this.preLitService
        .getAllCaseSummaryApa(model)
        .subscribe((response: any) => {
          if (response.status === 1) {
            if (this.currentTab === this.activeTab) {
              this.enablePrevStageTab.emit(response.response?.prevStages);
            }
            this.caseData = response.response;
            this.resStateName = response.response.state;
            this.caseType = this.caseData.caseType;
            this.selectedRegDropVal = this.caseData.regNo;
            this.getCompanyTags(this.selectedRegDropVal);

            if (this.caseData.status === "MOVED") {
              this.callBookDisabled = true;
            }

            if (this.caseData?.protestDetails) {
              this.caseData.protestDetails.forEach((protest: any) => {
                protest.total = this.inrService.formatCurrency(
                  String(protest.total)
                );
                protest.tax = this.inrService.formatCurrency(
                  String(protest.tax)
                );
                protest.interest = this.inrService.formatCurrency(
                  String(protest.interest)
                );
                protest.penalty = this.inrService.formatCurrency(
                  String(protest.penalty)
                );
              });
            }

            if (this.caseData?.stayOrder) {
              this.caseData?.stayOrder.forEach((stay: any) => {
                stay.amount = this.inrService.formatCurrency(
                  String(stay.amount)
                );
              });
            }

            if (this.caseData?.consultantCharges) {
              this.caseData.consultantCharges.forEach((charge: any) => {
                charge.fees = this.inrService.formatCurrency(
                  String(charge.fees)
                );
              });
            }
            if (this.caseData?.bankGuarantee) {
              this.caseData.bankGuarantee.forEach((bank: any) => {
                bank.bgAmount = this.inrService.formatCurrency(String(bank.bgAmount))
                bank.fee = this.inrService.formatCurrency(String(bank.fee))
              })
            }

            if (this.caseData?.liabilityDetails) {
              this.caseData.liabilityDetails.forEach((liability: any) => {
                liability.total = this.inrService.formatCurrency(
                  String(liability.total)
                );
                liability.tax = this.inrService.formatCurrency(
                  String(liability.tax)
                );
                liability.interest = this.inrService.formatCurrency(
                  String(liability.interest)
                );
                liability.penalty = this.inrService.formatCurrency(
                  String(liability.penalty)
                );
              });
            }

            if (this.caseData?.depositDetails) {
              this.caseData.depositDetails.forEach((preDeposite: any) => {
                preDeposite.total = this.inrService.formatCurrency(
                  String(preDeposite.total)
                );
                preDeposite.tax = this.inrService.formatCurrency(
                  String(preDeposite.tax)
                );
                preDeposite.interest = this.inrService.formatCurrency(
                  String(preDeposite.interest)
                );
                preDeposite.penalty = this.inrService.formatCurrency(
                  String(preDeposite.penalty)
                );
              });
            }

            if (this.caseData?.issues) {
              this.caseData.issues.forEach((issue: any) => {
                issue.exciseDuty1 = this.inrService.formatCurrency(
                  String(issue.exciseDuty1)
                );
                issue.serviceTax1 = this.inrService.formatCurrency(
                  String(issue.serviceTax1)
                );
                issue.ec1 = this.inrService.formatCurrency(String(issue.ec1));
                issue.shec1 = this.inrService.formatCurrency(
                  String(issue.shec1)
                );
                issue.kkc1 = this.inrService.formatCurrency(String(issue.kkc1));
                issue.vat1 = this.inrService.formatCurrency(String(issue.vat1));
                issue.cst1 = this.inrService.formatCurrency(String(issue.cst1));
                issue.sbc1 = this.inrService.formatCurrency(String(issue.sbc1));
                issue.bcd1 = this.inrService.formatCurrency(String(issue.bcd1));
                issue.sad1 = this.inrService.formatCurrency(String(issue.sad1));
                issue.antiDumpingDuty1 = this.inrService.formatCurrency(String(issue.antiDumpingDuty1));
                issue.igst1 = this.inrService.formatCurrency(String(issue.igst1));
                issue.ent1 = this.inrService.formatCurrency(String(issue.ent1));
                issue.interest1 = this.inrService.formatCurrency(
                  String(issue.interest1)
                );
                issue.penalty1 = this.inrService.formatCurrency(
                  String(issue.penalty1)
                );
                issue.total1 = this.inrService.formatCurrency(
                  String(issue.total1)
                );

                issue.exciseDuty3 = this.inrService.formatCurrency(
                  String(issue.exciseDuty3)
                );
                issue.serviceTax3 = this.inrService.formatCurrency(
                  String(issue.serviceTax3)
                );
                issue.ec3 = this.inrService.formatCurrency(String(issue.ec3));
                issue.shec3 = this.inrService.formatCurrency(
                  String(issue.shec3)
                );
                issue.kkc3 = this.inrService.formatCurrency(String(issue.kkc3));
                issue.vat3 = this.inrService.formatCurrency(String(issue.vat3));
                issue.cst3 = this.inrService.formatCurrency(String(issue.cst3));
                issue.sbc3 = this.inrService.formatCurrency(String(issue.sbc3));
                issue.bcd3 = this.inrService.formatCurrency(String(issue.bcd3));
                issue.sad3 = this.inrService.formatCurrency(String(issue.sad3))
                issue.antiDumpingDuty3 = this.inrService.formatCurrency(String(issue.antiDumpingDuty3))
                issue.igst3 = this.inrService.formatCurrency(String(issue.igst3))
                issue.ent3 = this.inrService.formatCurrency(String(issue.ent3));
                issue.interest3 = this.inrService.formatCurrency(
                  String(issue.interest3)
                );
                issue.penalty3 = this.inrService.formatCurrency(
                  String(issue.penalty3)
                );
                issue.total3 = this.inrService.formatCurrency(
                  String(issue.total3)
                );

                if (issue.details && issue.details.length) {
                  issue.details.forEach((details: any) => {
                    details.provisionAmt1 = this.inrService.formatCurrency(
                      String(details.provisionAmt1)
                    );
                    details.contLiabilityAmt1 = this.inrService.formatCurrency(
                      String(details.contLiabilityAmt1)
                    );
                    details.otherLiability1 = this.inrService.formatCurrency(
                      String(details.otherLiability1)
                    );
                    details.caroAmt1 = this.inrService.formatCurrency(
                      String(details.caroAmt1)
                    );
                  });
                }
              });
            }

            let model = {
              companyid: this.selectedCompanyId,
              actType: this.caseData.actType,
            };
            this.getState(model);
            this.fetchRegNo();
            if (this.caseData.actType === 'EXCISE') {
              this.isExcise = true;
              this.isServiceTax = false;
              this.isVat = false;
              this.isCst = false;
              this.isEntryTax = false;
              this.isCustomsDuty = false;
            } else if (this.caseData.actType === 'SERVICE TAX') {
              this.isExcise = false;
              this.isServiceTax = true;
              this.isVat = false;
              this.isCst = false;
              this.isEntryTax = false;
              this.isCustomsDuty = false;
            } else if (this.caseData.actType === 'VAT') {
              this.isExcise = false;
              this.isServiceTax = false;
              this.isVat = true;
              this.isCst = false;
              this.isEntryTax = false;
              this.isCustomsDuty = false;
            } else if (this.caseData.actType === 'CST') {
              this.isExcise = false;
              this.isServiceTax = false;
              this.isVat = false;
              this.isCst = true;
              this.isEntryTax = false;
              this.isCustomsDuty = false;
            } else if (this.caseData.actType === 'CUSTOMS DUTY') {
              this.isExcise = false;
              this.isServiceTax = false;
              this.isVat = false;
              this.isCst = false;
              this.isEntryTax = false;
              this.isCustomsDuty = true;
            } else if (this.caseData.actType === 'ENTRY TAX') {
              this.isExcise = false;
              this.isServiceTax = false;
              this.isVat = false;
              this.isCst = false;
              this.isCustomsDuty = false;
              this.isEntryTax = true;
            }

            this.remandedPaymentMade = this.caseData.remandedPaymentMade;
            this.formInitialize();
            this.whetherCallBookChanged();

            if (this.caseData.itemList && this.caseData.itemList.length > 0) {
              this.initializeItemList();
            } else {
              this.initializeItemList();
              var list: UntypedFormGroup = this.newphArray();
              this.apaDivArr().push(list);
            }
            if (this.caseData.issues && this.caseData.issues.length > 0) {
              this.initializeIssueList(this.caseData);
            } else {
              this.addIssueInv();
            }
            if (this.caseData.appealBookLoc && this.caseData.appealBookLoc.length > 0) {
              this.initializeappealBookLoc();
            } else {
              this.addappealBookLoc();
            }
            if (this.caseData.otherAttachmentsLoc1 && this.caseData.otherAttachmentsLoc1.length > 0) {
              this.initializeotherAttachmentsLoc1();
            } else {
              this.addotherAttachmentsLoc1();
            }

            if (this.caseData.stayOrder && this.caseData.stayOrder.length > 0) {
              this.initializeStayOrderList();
            } else {
              this.addStayOrderDetails();
            }

            if (
              this.caseData.depositDetails &&
              this.caseData.depositDetails.length > 0
            ) {
              this.initializePreDepositeList();
            } else {
              this.addDetailsOfPreDeposite();
            }

            if (
              this.caseData.protestDetails &&
              this.caseData.protestDetails.length > 0
            ) {
              this.initializePaymentDetailList();
            } else {
              this.addDetailOfPayment();
            }
           if (this.caseData.bankGuarantee && this.caseData.bankGuarantee.length > 0) {
            this.initializeBankGuarantee();
          } else {
            this.addDetailOfBankGuarantee();
          }

            if (
              this.caseData.submissions &&
              this.caseData.submissions.length > 0
            ) {
              this.initializeDetailsOfAppealList();
            } else {
              this.addDetailOfAppeal();
            }

            if (
              this.caseData.consultantCharges &&
              this.caseData.consultantCharges.length > 0
            ) {
              this.initializeConsultantList();
            } else {
              this.addConsultantInv();
            }

            if (
              this.caseData.liabilityDetails &&
              this.caseData.liabilityDetails.length > 0
            ) {
              this.initializeDetailOfLiability();
              this.liabilityAddmittedAndPaidChanged();
            } else {
              this.addDetailsOfLiabilityAddmittedAndPaid();
            }
            if (
              this.caseData.prevOrderLoc &&
              this.caseData.prevOrderLoc.length > 0
            ) {
              this.initializeCopyOfOioLoc();
            } else {
              this.addCopyOfOioLoc();
            }
            if (this.caseData.oiaLoc && this.caseData.oiaLoc.length > 0) {
              this.initializeoiaLocCopy();
            } else {
              this.addOiaLoc();
            }
            if (this.caseData.writAppPreferred == true) {
              this.disabledWeatherAppl = true;
              this.disabledWeatherDept = true;
            } else {
              this.disabledWeatherAppl = false;
              this.disabledWeatherDept = false;
            }
            if (this.caseData.remandedCaseId) {
              if (this.selectedRemandedBackCaseDetail) {
                this.selectedRemandedBackCaseDetail.cumulative =
                  this.caseData.cumulative;
              } else {
                this.selectedRemandedBackCaseDetail = {
                  cumulative: this.caseData.cumulative,
                };
              }
              this.initializeRemandedBackPaymentData(
                this.caseData.remandedPaymentMade
                  ? this.caseData.remandedPaymentMade
                  : this.caseData.cumulative
              );
            }
            this.cumulativeCount = this.caseData.cumulative
              ? this.caseData.cumulative
              : this.cumulativeCount;
            this.prevStage = this.caseData.prevStage;
            this.isUpdate = true;
            this.liabilityAddmittedAndPaidChanged();
            this.paymentUnderProtestp1Changed();
            this.preDepositeChanged();
            this.feesChargedChanged();
            this.setCummulativeCount();
            this.issueValueChange();
          }
          this.changeOutcome();
        });
    }
  }

  initializeCopyOfOioLoc() {
    const copyOfOioLocFormArray = this.preCommAuthForm.get(
      'prevOrderLoc'
    ) as UntypedFormArray;
    copyOfOioLocFormArray.clear();
    if (this.caseData.prevOrderLoc) {
      this.caseData.prevOrderLoc.forEach((not: any) => {
        let csnCopyFrmGrp = this.createCopyOfOioLoc(not);

        (this.preCommAuthForm.get('prevOrderLoc') as UntypedFormArray).push(
          csnCopyFrmGrp
        );
      });
    }
    this.preCommAuthForm.patchValue(this.caseData);
  }

  initializeoiaLocCopy() {
    const oiaLocFormArray = this.preCommAuthForm.get('oiaLoc') as UntypedFormArray;
    oiaLocFormArray.clear();
    if (this.caseData.oiaLoc) {
      this.caseData.oiaLoc.forEach((not: any) => {
        let csnCopyFrmGrp = this.createOiaLoc(not);

        (this.preCommAuthForm.get('oiaLoc') as UntypedFormArray).push(csnCopyFrmGrp);
      });
      this.isUpdateBtnDisable = true;
    }
    this.preCommAuthForm.patchValue(this.caseData);
    
  }

  initializeStayOrderList() {
    if (this.caseData.stayOrder) {
      this.caseData.stayOrder.forEach((stayOrder: any) => {
        let stayOrderFrmGrp: UntypedFormGroup = this.detailOfstayOrder(stayOrder);
        (this.preCommAuthForm.get('stayOrder') as UntypedFormArray).push(
          stayOrderFrmGrp
        );

        if (stayOrder.copyOfStayLoc) {
          stayOrder.copyOfStayLoc.forEach((not: any) => {
            let copyOfStayLocFrmGrp: UntypedFormGroup =
              this.createStayOrderCopyOfStayLoc(not);
            (stayOrderFrmGrp.get('copyOfStayLoc') as UntypedFormArray).push(
              copyOfStayLocFrmGrp
            );
          });
        }
      });
      this.preCommAuthForm.patchValue(this.caseData);
    }
  }

  initializePreDepositeList() {
    if (this.caseData.depositDetails) {
      this.caseData.depositDetails.forEach((depositDetails: any) => {
        let depositDetailsFrmGrp: UntypedFormGroup =
          this.detailOfPreDeposite(depositDetails);
        (this.preCommAuthForm.get('depositDetails') as UntypedFormArray).push(
          depositDetailsFrmGrp
        );

        if (depositDetails.supportingDocLoc) {
          depositDetails.supportingDocLoc.forEach((not: any) => {
            let supportingDocLocFrmGrp: UntypedFormGroup =
              this.createDepositSupportingDocLoc(not);
            (depositDetailsFrmGrp.get('supportingDocLoc') as UntypedFormArray).push(
              supportingDocLocFrmGrp
            );
          });
        }
      });
    }
  }

  initializeBankGuarantee() {
    if (this.caseData.bankGuarantee) {
      this.caseData.bankGuarantee.forEach((bank: any) => {
        let bankGuaranteeDetailsFrmGrp = this.detailOfBankGuarantee(bank);
        (this.preCommAuthForm.get('bankGuarantee') as UntypedFormArray).push(bankGuaranteeDetailsFrmGrp);
      })
    }
  }

  initializePaymentDetailList() {
    if (this.caseData.protestDetails) {
      this.caseData.protestDetails.forEach((protestDetails: any) => {
        let protestDetailsFrmGrp = this.detailOfprotestDetails(protestDetails);
        (this.preCommAuthForm.get('protestDetails') as UntypedFormArray).push(
          protestDetailsFrmGrp
        );

        if (protestDetails.supportingDocLoc) {
          protestDetails.supportingDocLoc.forEach((not: any) => {
            let supportingDocLocFrmGrp: UntypedFormGroup =
              this.createProtestSupportingDoc(not);
            (protestDetailsFrmGrp.get('supportingDocLoc') as UntypedFormArray).push(
              supportingDocLocFrmGrp
            );
          });
        }
      });
    }
  }

  initializeDetailsOfAppealList() {
    if (this.caseData.submissions) {
      this.caseData.submissions.forEach((submissions: any) => {
        let appealDetailsFrmGrp: UntypedFormGroup = this.detailOfAppeal(submissions);
        (this.preCommAuthForm.get('submissions') as UntypedFormArray).push(
          appealDetailsFrmGrp
        );

        if (submissions.copyOfAckLoc) {
          submissions.copyOfAckLoc.forEach((not: any) => {
            let submissionsFrmGrp: UntypedFormGroup = this.createCopyOfAckLoc(not);
            (appealDetailsFrmGrp.get('copyOfAckLoc') as UntypedFormArray).push(
              submissionsFrmGrp
            );
          });
        }
        if (submissions.otherAttachmentsLoc) {
          submissions.otherAttachmentsLoc.forEach((not: any) => {
            let submissionsFrmGrp: UntypedFormGroup =
              this.createOtherAttachmentsLoc(not);
            (appealDetailsFrmGrp.get('otherAttachmentsLoc') as UntypedFormArray).push(
              submissionsFrmGrp
            );
          });
        }
      });
    }
  }

  initializeConsultantList() {
    if (this.caseData.consultantCharges) {
      this.caseData.consultantCharges.forEach((consultantCharges: any) => {
        let consultantListFrmGrp: UntypedFormGroup =
          this.consultantChargesList(consultantCharges);
        (this.preCommAuthForm.get('consultantCharges') as UntypedFormArray).push(
          consultantListFrmGrp
        );

        if (consultantCharges.otherAttachmentLoc) {
          consultantCharges.otherAttachmentLoc.forEach((not: any) => {
            let ConsultotherAttachmentFrmGrp: UntypedFormGroup =
              this.createConsultotherAttachmentLoc(not);
            (consultantListFrmGrp.get('otherAttachmentLoc') as UntypedFormArray).push(
              ConsultotherAttachmentFrmGrp
            );
          });
        }
      });
    }
  }

  initializeDetailOfLiability() {
    if (this.caseData.liabilityDetails) {
      this.caseData.liabilityDetails.forEach((liabilityDetails: any) => {
        let liabilityAddmittedAndPaidFrmGrp =
          this.detailsOfLiabilityAddmittedAndPaid(liabilityDetails);
        (this.preCommAuthForm.get('liabilityDetails') as UntypedFormArray).push(
          liabilityAddmittedAndPaidFrmGrp
        );

        if (liabilityDetails.copyOfFormLoc) {
          liabilityDetails.copyOfFormLoc.forEach((not: any) => {
            let LiabilitySupportingDocFrmGrp: UntypedFormGroup =
              this.createLiabilitySupportingDocLoc(not);
            (
              liabilityAddmittedAndPaidFrmGrp.get('copyOfFormLoc') as UntypedFormArray
            ).push(LiabilitySupportingDocFrmGrp);
          });
        }
      });
    }
  }

  //fetch case
  //initialise ph div array
  initializeItemList() {
    if (this.caseData.itemList) {
      this.caseData.itemList.forEach((items: any) => {
        let itemListFrmGrp = this.newphArray();
        (this.preCommAuthForm.get('itemList') as UntypedFormArray).push(
          itemListFrmGrp
        );
        if (items.phCopyLoc) {
          items.phCopyLoc.forEach((not: any) => {
            let phcopyFormGrp: UntypedFormGroup = this.createphCopyLoc(not);
            (itemListFrmGrp.get('phCopyLoc') as UntypedFormArray).push(phcopyFormGrp);
          });
        }
        if (items.phRecordIfAnyLoc) {
          items.phRecordIfAnyLoc.forEach((not: any) => {
            let phRecordAnyGrp: UntypedFormGroup = this.createPhRecord(not);
            (itemListFrmGrp.get('phRecordIfAnyLoc') as UntypedFormArray).push(
              phRecordAnyGrp
            );
          });
        }
        if (items.phAdjournmentLetterLoc) {
          items.phAdjournmentLetterLoc.forEach((not: any) => {
            let phAdjournmentLetterLocGrp: UntypedFormGroup = this.createPhAdjournmentLetterLoc(not);
            (itemListFrmGrp.get('phAdjournmentLetterLoc') as UntypedFormArray).push(phAdjournmentLetterLocGrp);
          }); 
        }
        if (items.additionalSubmissionLoc) {
          items.additionalSubmissionLoc.forEach((not: any) => {
            let additionalSubmissionFrmGrp: UntypedFormGroup =
              this.createAdditionalSubLoc(not);
            (itemListFrmGrp.get('additionalSubmissionLoc') as UntypedFormArray).push(
              additionalSubmissionFrmGrp
            );
          });
        }
      });
      this.preCommAuthForm.patchValue(this.caseData);
      this.initializeCopyOfOioLoc();
      //this.initializeoiaLocCopy();
    }
  }
  initializeappealBookLoc() {
    const appealBookLocFormArray = this.preCommAuthForm.get('appealBookLoc') as UntypedFormArray;
    appealBookLocFormArray.clear();
    if (this.caseData.appealBookLoc) {
      this.caseData.appealBookLoc.forEach((not: any) => {

        let csnCopyFrmGrp = this.createappealBookLoc(not);

        (this.preCommAuthForm.get('appealBookLoc') as UntypedFormArray).push(csnCopyFrmGrp);

      })
    }
    this.preCommAuthForm.patchValue(this.caseData);
  }
  initializeotherAttachmentsLoc1() {
    const otherAttachmentsLoc1FormArray = this.preCommAuthForm.get('otherAttachmentsLoc1') as UntypedFormArray;
    otherAttachmentsLoc1FormArray.clear();
    if (this.caseData.otherAttachmentsLoc1) {
      this.caseData.otherAttachmentsLoc1.forEach((not: any) => {

        let csnCopyFrmGrp = this.createotherAttachmentsLoc1(not);

        (this.preCommAuthForm.get('otherAttachmentsLoc1') as UntypedFormArray).push(csnCopyFrmGrp);

      })
    }
    this.preCommAuthForm.patchValue(this.caseData);
  }
  //initialise issue array
  initializeIssueList(data: any) {
    if (data.issues) {
      data.issues.forEach((issue: any) => {
        let issuesFrmGrp: UntypedFormGroup = this.newIssueInvArray();
        (this.preCommAuthForm.get('issues') as UntypedFormArray).push(issuesFrmGrp);
        if (issue.details) {
          issue.details.forEach((detail: any) => {
            let detailFrmGrp: UntypedFormGroup = this.newDetailsFrmGrp();
            (issuesFrmGrp.get('details') as UntypedFormArray).push(detailFrmGrp);
          });
        }

      });
      this.preCommAuthForm.patchValue(data);
      this.issueValueChange();
    }
  }
  UpdateModelData() {
    if (this.caseData != null) {
      if (this.caseData.oiaLoc != null) {
        this.preCommAuthForm.value.oiaLoc = this.caseData['oiaLoc'];
      } else {
        this.preCommAuthForm.value.oiaLoc = '';
      }
    }
  }

  doValidation(phaseTab: any) {
    this.UpdateModelData();
    if (phaseTab === 'p2') {
      this.consultantValidation();
      return this.p2validation();
    } else if (phaseTab === 'p3') {
      this.liabilityValidation();
      return this.p3validation();
    } else {
      return true;
    }
  }

  // protestValidation() {
  //   let protestArray = this.preCommAuthForm.controls
  //     .protestDetails as UntypedFormArray;
  //   if (protestArray.controls.length > 0) {
  //     for (let index = 0; index < protestArray.controls.length; index++) {
  //       let issuesControl = protestArray.controls[index] as UntypedFormGroup;
  //       if (
  //         issuesControl.controls.challanNo.value != null &&
  //         issuesControl.controls.challanNo.value
  //       ) {
  //         if (
  //           issuesControl.controls.supportingDocLoc.value === null ||
  //           issuesControl.controls.supportingDocLoc.value === ''
  //         ) {
  //           issuesControl.controls.supportingDocLoc.setErrors({
  //             required: true,
  //           });
  //         } else {
  //           issuesControl.controls.supportingDocLoc.setErrors(null);
  //         }
  //       }
  //     }
  //   }
  // }

  liabilityValidation() {
    let liabilityArray = this.preCommAuthForm.controls
      .liabilityDetails as UntypedFormArray;
    if (liabilityArray.controls.length > 0) {
      for (let index = 0; index < liabilityArray.controls.length; index++) {
        let issuesControl = liabilityArray.controls[index] as UntypedFormGroup;
        if (
          issuesControl.controls.challanNo.value != null &&
          issuesControl.controls.challanNo.value
        ) {
          if (
            issuesControl.controls.copyOfFormLoc.value === null ||
            issuesControl.controls.copyOfFormLoc.value === ''
          ) {
            issuesControl.controls.copyOfFormLoc.setErrors({ required: true });
          } else {
            issuesControl.controls.copyOfFormLoc.setErrors(null);
          }
        }
      }
    }
  }

  predepositValidation() {
    let protestArray = this.preCommAuthForm.controls
      .depositDetails as UntypedFormArray;
    if (protestArray.controls.length > 0) {
      for (let index = 0; index < protestArray.controls.length; index++) {
        let issuesControl = protestArray.controls[index] as UntypedFormGroup;
        if (
          issuesControl.controls.challanNo.value != null &&
          issuesControl.controls.challanNo.value
        ) {
          if (
            issuesControl.controls.supportingDocLoc.value === null ||
            issuesControl.controls.supportingDocLoc.value === ''
          ) {
            issuesControl.controls.supportingDocLoc.setErrors({
              required: true,
            });
          } else {
            issuesControl.controls.supportingDocLoc.setErrors(null);
          }
        }
      }
    }
  }

  consultantValidation() {
    let consultantArray = this.preCommAuthForm.controls
      .consultantCharges as UntypedFormArray;
    if (consultantArray.controls.length > 0) {
      for (let index = 0; index < consultantArray.controls.length; index++) {
        let issuesControl = consultantArray.controls[index] as UntypedFormGroup;
        const value = this.inrService.removeCommasAndParseNumber(
          issuesControl.controls.fees.value
        );
        if (issuesControl.controls.fees.value != null && value >= 1) {
          if (
            issuesControl.controls.firm.value === null ||
            issuesControl.controls.firm.value === ''
          ) {
            issuesControl.controls.firm.setErrors({ required: true });
          } else {
            issuesControl.controls.firm.setErrors(null);
          }
        }
      }
    }
  }

  updateIssues() {
    (this.preCommAuthForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      c.patchValue({ exciseDuty3: c.value.exciseDuty1 });
      c.patchValue({ ec3: c.value.ec1 });
      c.patchValue({ shec3: c.value.shec1 });
      c.patchValue({ serviceTax3: c.value.serviceTax1 });
      c.patchValue({ kkc3: c.value.kkc1 });
      c.patchValue({ sbc3: c.value.sbc1 });
      c.patchValue({ vat3: c.value.vat1 });
      c.patchValue({ bcd3: c.value.bcd1 });
      c.patchValue({ sad3: c.value.sad1 })
      c.patchValue({ antiDumpingDuty3: c.value.antiDumpingDuty1 })
      c.patchValue({ igst3: c.value.igst1 })
      c.patchValue({ ent3: c.value.ent1 });
      c.patchValue({ interest3: c.value.interest1 });
      c.patchValue({ penalty3: c.value.penalty1 });
      c.patchValue({ total3: c.value.total1 });
      c.patchValue({ userEnteredInterest3: c.value.userEnteredInterest1 })
    });
  }

  updateSubmissions() {
    (this.preCommAuthForm.get('submissions') as UntypedFormArray).controls.forEach(
      (c) => {
        c.patchValue({ copyOfAckLoc: c.value.copyOfAckLoc });
        // c.patchValue()
      }
    );
  }
  openTextBoxModal(label: any, id: any, issueForm: any, index: any) {
    let detailArr = (issueForm.get('details') as UntypedFormArray)
      .controls as UntypedFormGroup[];
    const modalRef = this.modalService.open(OpenTextModalComponent);
    modalRef.componentInstance.label = label;
    modalRef.componentInstance.id = id;
    modalRef.componentInstance.reasonValue =
      detailArr[index].controls[id].value;

    modalRef.dismissed.subscribe((data) => {
      if (data) {
        detailArr[index].controls[id].setValue(data.reason);
      }
      let input = document.getElementById(data.id);
      input?.blur();
    });
  }
  /*Update case*/
  updateCase(phaseTab: any) {
    console.log(this.preCommAuthForm, 'pre commissioner form');
    this.setCaseItemList();
    this.setAppealDetailItemList();
    this.setPortestlDetailList();
    this.setStayOrderList();
    this.setDepositDetailsList();
    this.feesChargedChanged();
    this.liabilityAddmittedAndPaidChanged();
    this.issueValueChange();
    this.paymentUnderProtestp1Changed();
    if (phaseTab === 'p1') {
      this.updateIssues();
      // this.protestValidation();
      this.predepositValidation();
    }
    if (phaseTab === 'p2') {
      this.updateSubmissions();
    }
    if (phaseTab === 'p3') {
      this.p2validation();
    }
    this.isSubmitted = true;
    let data: any = {};
    let isTabValid = this.doValidation(phaseTab);
    if (this.preCommAuthForm.valid && isTabValid) {
      if (phaseTab === 'p1') {
        if (this.caseType === 'MEMORANDUM') {
          let dataNoticeLoc = (this.preCommAuthForm.get('appealBookLoc') as UntypedFormArray).controls;
          var count = dataNoticeLoc?.some((x) => x.get('fileLoc')?.value != "");
          if (!count) {
            this.toaster.showError('Required Fields Are Empty');
            return;
          }
        }
        let protestArray = this.preCommAuthForm.controls.protestDetails as UntypedFormArray
        for (let index = 0; index < protestArray.controls.length; index++) {
          let issuesControl = protestArray.controls[index] as UntypedFormGroup;
  
          if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
            let copyOfFormLocControls = (issuesControl.get('supportingDocLoc') as UntypedFormArray).controls;
  
            let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
            if (!hasSupportingDoc) {
              this.toaster.showError("Required Fields Are Empty for Challan No.");
              return;
            }
            let issueNameControls = issuesControl.controls.issueName.value;
            let tagControls = issuesControl.controls.tag.value;
            let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;        
            let challanNoControls = issuesControl.controls.challanNo.value ;
            if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
              this.toaster.showError("Required Fields Are Empty in Payment Table.");
              return;
            }
          }
         
        
        }
        let predepositArray = this.preCommAuthForm.controls.depositDetails as UntypedFormArray
        for (let index = 0; index < predepositArray.controls.length; index++) {
          let issuesControl = predepositArray.controls[index] as UntypedFormGroup;
  
          if (issuesControl.controls.challanNo.value || issuesControl.controls.total.value) {
            let copyOfFormLocControls = (issuesControl.get('supportingDocLoc') as UntypedFormArray).controls;
  
            let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
            if (!hasSupportingDoc) {
              this.toaster.showError("Required Fields Are Empty for Challan No.");
              return;
            }
            let issueNameControls = issuesControl.controls.issueName.value;
            let tagControls = issuesControl.controls.tag.value;
            let dateOfPaymentControls = issuesControl.controls.dateOfPayment.value ;        
            let challanNoControls = issuesControl.controls.challanNo.value ;
            if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
              this.toaster.showError("Required Fields Are Empty for Payment table.");
              return;
            }
          }
         
        
        }

      }
      if (phaseTab === 'p2') {
        let submissionsFrmArray = (
          this.preCommAuthForm.get('submissions') as UntypedFormArray
        ).controls;
        let element1;
        for (let i = 0; i < submissionsFrmArray.length; i++) {
          element1 = (submissionsFrmArray[i].get('copyOfAckLoc') as UntypedFormArray)
            .controls;
        }
        let count = element1?.some((x: any) => x.get('fileLoc')?.value != "");
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
      }
      if (phaseTab === 'p3') {
        let dataOioLoc = (this.preCommAuthForm.get('oiaLoc') as UntypedFormArray).controls;
        var count = dataOioLoc?.some((x) => x.get('fileLoc')?.value != '');
        if (!count) {
          this.toaster.showError('Required Fields Are Empty');
          return;
        }
        let liabilityArray = this.preCommAuthForm.controls.liabilityDetails as UntypedFormArray
        for (let index = 0; index < liabilityArray.controls.length; index++) {
          let alControl = liabilityArray.controls[index] as UntypedFormGroup;
  
          if (alControl.controls.challanNo.value || alControl.controls.total.value) {
            let copyOfFormLocControls = (alControl.get('copyOfFormLoc') as UntypedFormArray).controls;
           
            let hasSupportingDoc = copyOfFormLocControls.some((docControl) => docControl.get('fileLoc')?.value != null);
            if (!hasSupportingDoc) {
              this.toaster.showError("Required Fields Are Empty for Challan No.");
              return;
            }
            let issueNameControls = alControl.controls.issueName.value;
            let tagControls = alControl.controls.tag.value;
            let dateOfPaymentControls = alControl.controls.dateOfPayment.value ;
            let challanNoControls = alControl.controls.challanNo.value ;
            if (!issueNameControls || !dateOfPaymentControls || !tagControls || !challanNoControls) {
              this.toaster.showError("Required Fields Are Empty in Payment Table.");
              return;
            }
          }      
         
         
        }
      }
      this.calculateRemandBackTotal();
      data.remandedPaymentMade = this.preCommAuthForm.value.remandedPaymentMade;

      //data.state = this.selectedStateCode;
      if (this.savedPreCaseId) {
        data.caseId = this.savedPreCaseId;
      }
      let urlData = {
        typeVal: this.tabType[1].key,
        gstin: this.selectedGstin,
      };

      if (data.protestDetails && data.protestDetails.length) {
        data.protestDetails.forEach((protestDetail: any) => {
          protestDetail.tax = this.inrService.removeCommasAndParseNumber(
            protestDetail.tax
          );
          protestDetail.interest = this.inrService.removeCommasAndParseNumber(
            protestDetail.interest
          );
          protestDetail.penalty = this.inrService.removeCommasAndParseNumber(
            protestDetail.penalty
          );
          protestDetail.total = this.inrService.removeCommasAndParseNumber(
            protestDetail.total
          );
        });
      }

      if (data.depositDetails && data.depositDetails.length) {
        data.depositDetails.forEach((depositDetails: any) => {
          depositDetails.tax = this.inrService.removeCommasAndParseNumber(
            depositDetails.tax
          );
          depositDetails.interest = this.inrService.removeCommasAndParseNumber(
            depositDetails.interest
          );
          depositDetails.penalty = this.inrService.removeCommasAndParseNumber(
            depositDetails.penalty
          );
          depositDetails.total = this.inrService.removeCommasAndParseNumber(
            depositDetails.total
          );
        });
      }

      if (data.consultantCharges && data.consultantCharges.length) {
        data.consultantCharges.forEach((charge: any) => {
          charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees);
        });
      }
      if (data.bankGuarantee && data.bankGuarantee.length) {
        data.bankGuarantee.forEach((bank: any) => {
          bank.bgAmount = this.inrService.removeCommasAndParseNumber(bank.bgAmount)
          bank.fee = this.inrService.removeCommasAndParseNumber(bank.fee)
        })
      }

      if (data.liabilityDetails && data.liabilityDetails.length) {
        data.liabilityDetails.forEach((liability: any) => {
          liability.tax = this.inrService.removeCommasAndParseNumber(
            liability.tax
          );
          liability.interest = this.inrService.removeCommasAndParseNumber(
            liability.interest
          );
          liability.penalty = this.inrService.removeCommasAndParseNumber(
            liability.penalty
          );
          liability.total = this.inrService.removeCommasAndParseNumber(
            liability.total
          );
        });
      }

      if (data.issues && data.issues.length) {
        data.issues.forEach((issue: any) => {
          issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(
            issue.exciseDuty1
          );
          issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(
            issue.serviceTax1
          );
          issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1);
          issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1);
          issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1);
          issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1);
          issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1);
          issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1);
          issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1);
          issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1)
          issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1)
          issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1)
          issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1);
          issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1);
          issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1);
          issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1);

          issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty3);
          issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(issue.serviceTax3);
          issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3);
          issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3);
          issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3);
          issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3);
          issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3);
          issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3);
          issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3);
          issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3)
          issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3)
          issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3)
          issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3);
          issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3);
          issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3);
          issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3);
        });
      }

      data.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty1Total);
      data.serviceTax1Total = this.inrService.removeCommasAndParseNumber(data.serviceTax1Total);
      data.ec1Total = this.inrService.removeCommasAndParseNumber(data.ec1Total);
      data.shec1Total = this.inrService.removeCommasAndParseNumber(data.shec1Total);
      data.kkc1Total = this.inrService.removeCommasAndParseNumber(data.kkc1Total);
      data.vat1Total = this.inrService.removeCommasAndParseNumber(data.vat1Total);
      data.cst1Total = this.inrService.removeCommasAndParseNumber(data.cst1Total);
      data.sbc1Total = this.inrService.removeCommasAndParseNumber(data.sbc1Total);
      data.bcd1Total = this.inrService.removeCommasAndParseNumber(data.bcd1Total);
      data.sad1Total = this.inrService.removeCommasAndParseNumber(data.sad1Total)
      data.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty1Total)
      data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total)
      data.ent1Total = this.inrService.removeCommasAndParseNumber(data.ent1Total);
      data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total);
      data.penalty1Total = this.inrService.removeCommasAndParseNumber(
        data.penalty1Total
      );
      data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(
        data.totOfTotal1
      );

      data.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(
        data.exciseDuty3Total
      );
      data.serviceTax3Total = this.inrService.removeCommasAndParseNumber(
        data.serviceTax3Total
      );
      data.ec3Total = this.inrService.removeCommasAndParseNumber(data.ec3Total);
      data.shec3Total = this.inrService.removeCommasAndParseNumber(
        data.shec3Total
      );
      data.kkc3Total = this.inrService.removeCommasAndParseNumber(
        data.kkc3Total
      );
      data.vat3Total = this.inrService.removeCommasAndParseNumber(
        data.vat3Total
      );
      data.cst3Total = this.inrService.removeCommasAndParseNumber(
        data.cst3Total
      );
      data.sbc3Total = this.inrService.removeCommasAndParseNumber(
        data.sbc3Total
      );
      data.bcd3Total = this.inrService.removeCommasAndParseNumber(
        data.bcd3Total
      );
      data.sad3Total = this.inrService.removeCommasAndParseNumber(
        data.sad3Total
      );
      data.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(
        data.antiDumpingDuty3Total
      );
      data.igst3Total = this.inrService.removeCommasAndParseNumber(
        data.igst3Total
      );
      data.ent3Total = this.inrService.removeCommasAndParseNumber(
        data.ent3Total
      );
      data.interest3Total = this.inrService.removeCommasAndParseNumber(
        data.interest3Total
      );
      data.penalty3Total = this.inrService.removeCommasAndParseNumber(
        data.penalty3Total
      );
      data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(
        data.totOfTotal3
      );

      let copyOfOioLocArray: any[] = [];
      let StayOrderCopyOfStayLocArray: any[] = [];
      let protestSupportingDocLocArray: any[] = [];

      //phase 2 starts
      let copyOfAckLocArray: any[] = [];
      let phCopyLocArray: any[] = [];
      let otherAttachmentsLocArray: any[] = [];
      let additionalSubmissionLocArray: any[] = [];
      let phRecordIfAnyLocArray: any[] = [];
      let consultotherAttachmentLocArray: any[] = [];
      let oiaLocArray: any[] = [];
      let phAdjournmentLetterLocArray: any[] = [];
      let appealBookLocArray: any[] = [];
      let otherAttachmentsLoc1Array: any[] = [];

      for (
        let i = 0;
        i < this.preCommAuthForm.controls.prevOrderLoc.value.length;
        i++
      ) {
        const element = this.preCommAuthForm.controls.prevOrderLoc.value[i];
        if (
          element.fileLoc !== '' &&
          element.fileLoc !== null &&
          element.fileLoc !== undefined
        ) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          copyOfOioLocArray.push(element);
        }
      }
      this.preCommAuthForm.value.prevOrderLoc = copyOfOioLocArray;

      for (
        let i = 0;
        i < this.preCommAuthForm.controls.stayOrder.value.length;
        i++
      ) {
        StayOrderCopyOfStayLocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.stayOrder.value[i].copyOfStayLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            StayOrderCopyOfStayLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.stayOrder.value[i].copyOfStayLoc =
          StayOrderCopyOfStayLocArray;
      }
      let DepositSupportingDocLocArray: any[] = [];
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.depositDetails.value.length;
        i++
      ) {
        DepositSupportingDocLocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.depositDetails.value[i]
            .supportingDocLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            DepositSupportingDocLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.depositDetails.value[i].supportingDocLoc =
          DepositSupportingDocLocArray;
      }

      for (
        let i = 0;
        i < this.preCommAuthForm.controls.protestDetails.value.length;
        i++
      ) {
        protestSupportingDocLocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.protestDetails.value[i]
            .supportingDocLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            protestSupportingDocLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.protestDetails.value[i].supportingDocLoc =
          protestSupportingDocLocArray;
      }
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.submissions.value.length;
        i++
      ) {
        copyOfAckLocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.submissions.value[i].copyOfAckLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            copyOfAckLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.submissions.value[i].copyOfAckLoc =
          copyOfAckLocArray;
      }
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.submissions.value.length;
        i++
      ) {
        otherAttachmentsLocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.submissions.value[i]
            .otherAttachmentsLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            otherAttachmentsLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.submissions.value[i].otherAttachmentsLoc =
          otherAttachmentsLocArray;
      }
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.itemList.value.length;
        i++
      ) {
        phCopyLocArray = [];
        const elementPhCopyArray =
          this.preCommAuthForm.controls.itemList.value[i].phCopyLoc;

        for (let j = 0; j < elementPhCopyArray.length; j++) {
          const element = elementPhCopyArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phCopyLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.itemList.value[i].phCopyLoc =
          phCopyLocArray;
      }

      for (
        let i = 0;
        i < this.preCommAuthForm.controls.itemList.value.length;
        i++
      ) {
        additionalSubmissionLocArray = [];
        const elementAddSubArray =
          this.preCommAuthForm.controls.itemList.value[i]
            .additionalSubmissionLoc;

        for (let j = 0; j < elementAddSubArray.length; j++) {
          const element = elementAddSubArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            additionalSubmissionLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.itemList.value[
          i
        ].additionalSubmissionLoc = additionalSubmissionLocArray;
      }
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.itemList.value.length;
        i++
      ) {
        phRecordIfAnyLocArray = [];
        const phRecordArray =
          this.preCommAuthForm.controls.itemList.value[i].phRecordIfAnyLoc;

        for (let j = 0; j < phRecordArray.length; j++) {
          const element = phRecordArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phRecordIfAnyLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.itemList.value[i].phRecordIfAnyLoc =
          phRecordIfAnyLocArray;
      }
      for (let i = 0; i < this.preCommAuthForm.controls.itemList.value.length; i++) {
        phAdjournmentLetterLocArray = [];
        const phAdjournmentArray = this.preCommAuthForm.controls.itemList.value[i].phAdjournmentLetterLoc;

        for (let j = 0; j < phAdjournmentArray.length; j++) {
          const element = phAdjournmentArray[j];

          if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            phAdjournmentLetterLocArray.push(element)
          }
        }
        this.preCommAuthForm.controls.itemList.value[i].phAdjournmentLetterLoc = phAdjournmentLetterLocArray;
      }
      for (let i = 0; i < this.preCommAuthForm.controls.appealBookLoc.value.length; i++) {
        const element = this.preCommAuthForm.controls.appealBookLoc.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          appealBookLocArray.push(element)
        }

      }
      this.preCommAuthForm.value.appealBookLoc = appealBookLocArray;

      for (let i = 0; i < this.preCommAuthForm.controls.otherAttachmentsLoc1.value.length; i++) {
        const element = this.preCommAuthForm.controls.otherAttachmentsLoc1.value[i];
        if (element.fileLoc !== '' && element.fileLoc !== null && element.fileLoc !== undefined) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          otherAttachmentsLoc1Array.push(element)
        }

      }
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.consultantCharges.value.length;
        i++
      ) {
        consultotherAttachmentLocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.consultantCharges.value[i]
            .otherAttachmentLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            consultotherAttachmentLocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.protestDetails.value[
          i
        ].otherAttachmentLoc = consultotherAttachmentLocArray;
      }


      let LiabilitySupportingDocArray: any[] = [];
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.liabilityDetails.value.length;
        i++
      ) {
        LiabilitySupportingDocArray = [];
        const copyFormArray =
          this.preCommAuthForm.controls.liabilityDetails.value[i].copyOfFormLoc;

        for (let j = 0; j < copyFormArray.length; j++) {
          const element = copyFormArray[j];

          if (
            element.fileLoc !== '' &&
            element.fileLoc !== null &&
            element.fileLoc !== undefined
          ) {
            if (element.fileName === '') {
              element.fileName = null;
            }

            if (element.remarks === '') {
              element.remarks = null;
            }

            LiabilitySupportingDocArray.push(element);
          }
        }
        this.preCommAuthForm.controls.liabilityDetails.value[i].copyOfFormLoc =
          LiabilitySupportingDocArray;
      }
      for (
        let i = 0;
        i < this.preCommAuthForm.controls.oiaLoc.value.length;
        i++
      ) {
        const element = this.preCommAuthForm.controls.oiaLoc.value[i];
        if (
          element.fileLoc !== '' &&
          element.fileLoc !== null &&
          element.fileLoc !== undefined
        ) {
          if (element.fileName === '') {
            element.fileName = null;
          }

          if (element.remarks === '') {
            element.remarks = null;
          }

          oiaLocArray.push(element);
        }
      }
      this.preCommAuthForm.value.oiaLoc = oiaLocArray;
      this.inrFormattingRemoving();

      data = this.preCommAuthForm.value;
      data.caseType = this.caseType;
      data.cumulative = this.cumulativeCount;
      data.prevStage = this.caseData.prevStage;

      data.issuingAuth1 = {
        officer: this.preCommAuthForm.value.officer,
        designation: this.preCommAuthForm.value.designation,
        jurisdiction: this.preCommAuthForm.value.jurisdiction,
        address: this.preCommAuthForm.value.address,
        contact: this.preCommAuthForm.value.contact,
        email: this.preCommAuthForm.value.email,
      };
      data.issuingAuth3 = {
        officer: this.preCommAuthForm.value.officer3,
        designation: this.preCommAuthForm.value.designation3,
        jurisdiction: this.preCommAuthForm.value.jurisdiction3,
        address: this.preCommAuthForm.value.address3,
        contact: this.preCommAuthForm.value.contact3,
        email: this.preCommAuthForm.value.email3,
      };

      this.preLitService.updateApaData(data).subscribe((response: any) => {
        this.isSubmitted = false;
        this.formatTableValues();
        if (response.status === 1) {
          if (phaseTab === 'p1') {
            this.isPhase2 = true;
          } else if (phaseTab === 'p2') {
            this.isPhase3 = true;
          } else if (phaseTab === 'p3') {
            if (
              this.preCommAuthForm.value.forumOfAppeal === 'prehc' &&
              (this.preCommAuthForm.value.whetherAppealPreferred === true ||
                this.preCommAuthForm.value.whetherAppealPreferred === 'true')
            ) {
              this.moveToHC.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState('', '', url);
            } else if (
              this.preCommAuthForm.value.whetherAppealPreferred === true ||
              this.preCommAuthForm.value.whetherAppealPreferred === 'true'
            ) {
              this.preLitService.setAccessTabData({
                preajaFlag: true,
                preapaFlag: true,
                preaptFlag: false,
                prehcFlag: false,
                prescFlag: false,
              });
              this.callTabFunction.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState('', '', url);
              console.log(url);
            } else if (
              this.preCommAuthForm.value.forumOfAppeal === 'presc' &&
              (this.preCommAuthForm.value.writAppPreferred === true ||
                this.preCommAuthForm.value.writAppPreferred === 'true')
            ) {
              this.moveToSC.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState('', '', url);
            } else if (
              (this.preCommAuthForm.value.writAppPreferred === true &&
                (this.preCommAuthForm.value.outcome === 'Unfavorable' ||
                  this.preCommAuthForm.value.outcome === 'Partly favorable')) ||
              (this.preCommAuthForm.value.writAppPreferred === 'true' &&
                (this.preCommAuthForm.value.outcome === 'Unfavorable' ||
                  this.preCommAuthForm.value.outcome === 'Partly favorable'))
            ) {
              this.preLitService.setAccessTabData({
                preajaFlag: true,
                preapaFlag: true,
                preaptFlag: false,
                prehcFlag: false,
                prescFlag: false,
              });
              this.moveToHC.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState('', '', url);
            } else if (
              this.preCommAuthForm.value.forumOfAppeal === 'prehc' &&
              (this.preCommAuthForm.value.whetherDptAppealPreferred ===
                'true' ||
                this.preCommAuthForm.value.whetherDptAppealPreferred === true)
            ) {
              this.moveToHC.emit('');
            } else if (
              this.preCommAuthForm.value.whetherDptAppealPreferred === true ||
              this.preCommAuthForm.value.whetherDptAppealPreferred === 'true'
            ) {
              this.preLitService.setAccessTabData({
                preajaFlag: true,
                preapaFlag: true,
                preaptFlag: false,
                prehcFlag: false,
                prescFlag: false,
              });
              this.callTabFunction.emit('');
              var url = window.location.pathname + ',caseTab:move';
              window.history.pushState('', '', url);
            } else {
              this.router.navigate(['/preGstSummary']);
            }
          }
          this.toaster.showSuccess(response.message);
        } else {
          this.toaster.showError(response.message);
        }
      });
    } else {
      this.toaster.showError('Required Fields Are Empty');
    }
  }
  //fetch Registration NumOnFileber case
  getFileNameDta(fileUrl: any) {
    if (fileUrl && typeof fileUrl == 'string')
      return this.preLitService.getFileName(fileUrl);
  }
  /*upload file selection */
  onFileSelect(
    event: any,
    isFromAppealDetails: boolean = false,
    type: any = null
  ) {
    this.selectedField = event.target.getAttribute('id');
    if (event.target.files[0] !== undefined) {
      if (
        this.checkExtensionFunction(event.target.files[0], [
          'csv',
          'zip',
          'pdf',
          'xlsx',
          'zip',
          'rar',
          'doc',
          'docx',
          'pptx',
          'xlsb',
          'png',
          'jpg',
          'jpeg',
          'msg',
        ])
      ) {
        this.selectedFile = event.target.files[0];
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        // this.formData = new FormData();
        // this.formData.append('fname', this.selectedFile);
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField, isFromAppealDetails, type);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported');
      }
    } else {
      this.selectedFile = null;
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(
    selectedFile: { name: string },
    extensionAllowedArray: any[]
  ) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  // onUpload(selectedfield: any, isFromAppealDetails:boolean = false, type: any = null) {
  //   let requestBody: any = new FormData();
  //   requestBody.append('caseId', this.caseId ? this.caseId : this.savedPreCaseId);
  //   requestBody.append('type', this.tabType[1].key);
  //   requestBody.append('field', selectedfield);
  //   requestBody.append('fmime', this.selectedFile.type);
  //   requestBody.append('fname', this.selectedFile.name);
  //   requestBody.append('file', this.selectedFile);
  //   this.preLitService.uploadFile(requestBody).subscribe((response: any) => {
  //     if (response && response.status === 1) {
  //       this.setFormData(response.response, selectedfield, isFromAppealDetails, type)
  //     }
  //   })
  // }

  onUpload(
    selectedfield: any,
    isFromAppealDetails: boolean = false,
    type: any = null
  ) {
    let urlData = {
      //caseId: this.caseId ? this.caseId : this.savedPreCaseId,
      gstinOrPan: this.preCommAuthForm.value.pan,
      type: this.tabType[1].key,
      field: selectedfield, //this.selectedField,
      contentType: this.selectedFile.type,
    };
    this.preLitService
      .commonUpload(this.selectedFile, urlData)
      .subscribe((response: any) => {
        if (response != null) {
          //this.fileUrl = response.url;
          //this.filePath = response.path;
          this.getFileUrl(
            response.response.url,
            response.response.path,
            selectedfield,
            isFromAppealDetails,
            type
          );
        } else {
        }
      });
  }

  // setFormData(path: any, selectedfield: any,isFromAppealDetails:boolean=false,type: any = null) {
  getFileUrl(
    url: any,
    path: any,
    selectedfield: any,
    isFromAppealDetails: boolean = false,
    type: any = null
  ) {
    this.http.put(url, this.selectedFile).subscribe((response) => {
      console.log('response receved is ', response);
      if (
        this.selectedFile &&
        this.formGrp.itemList.value &&
        this.matches &&
        !isFromAppealDetails
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.itemList.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path; //this.filePath;
              this.savecaseItemList();
            }
          }
        });
      } else if (
        this.selectedFile &&
        type === 'protestDetails' &&
        this.matches
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (
          this.preCommAuthForm.get('protestDetails') as UntypedFormArray
        ).controls.forEach((c: any, index) => {
          if (letterIndex == index) {
            c.patchValue(attrFieldNm[1], path);
            c.controls[attrFieldNm[1]].setValue(path);
          }
        });
      } else if (
        this.selectedFile &&
        this.matches != null &&
        this.matches.input.includes('supportingDocLoc')
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        (
          this.preCommAuthForm.get('depositDetails') as UntypedFormArray
        ).controls.forEach((c: any, index) => {
          if (letterIndex == index) {
            c.patchValue(attrFieldNm[1], path);
            c.controls[attrFieldNm[1]].setValue(path);
          }
        });
      } else if (
        this.selectedFile &&
        this.matches != null &&
        this.matches.input.includes('copyOfStayLoc')
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.stayOrder.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path; //this.filePath;
              this.saveStayOrderList();
            }
          }
        });
      } else if (
        this.selectedFile &&
        this.matches != null &&
        this.matches.input.includes('otherAttachmentLoc')
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.consultantCharges.value.forEach(
          (element: any, index: any) => {
            if (letterIndex) {
              if (letterIndex == index) {
                element[attrFieldNm[1]] = path; //this.filePath;
                this.saveConsultantChargesList();
              }
            }
          }
        );
      } else if (
        this.selectedFile &&
        this.matches != null &&
        this.matches.input.includes('copyOfFormLoc')
      ) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.liabilityDetails.value.forEach(
          (element: any, index: any) => {
            if (letterIndex) {
              if (letterIndex == index) {
                element[attrFieldNm[1]] = path; //this.filePath;
                this.saveLiabilityDetailsList();
              }
            }
          }
        );
      } else if (this.selectedFile && this.matches && isFromAppealDetails) {
        let letterIndex = this.matches[1];
        let attrFieldNm = this.matches.input.split('.');
        this.formGrp.submissions.value.forEach((element: any, index: any) => {
          if (letterIndex) {
            if (letterIndex == index) {
              element[attrFieldNm[1]] = path; //this.filePath;
              this.saveAppealDetailList();
            }
          }
        });
      }
      if (selectedfield) {
        var toupdate = this.preCommAuthForm.value;
        let pathToVeriabl = this.createPath(selectedfield);
        for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
          if (pathIndex !== pathToVeriabl.length - 1) {
            toupdate = toupdate[pathToVeriabl[pathIndex]];
          } else {
            toupdate[pathToVeriabl[pathIndex]] = path;
          }
        }
        this.preCommAuthForm.patchValue(this.preCommAuthForm.value);
      } else {
        let field = selectedfield; //this.selectedField;
        let adjForm = this.preCommAuthForm.value;
        adjForm[field] = path; //this.filePath;
        if (this.caseData == null) {
          this.caseData = {};
        }
        this.caseData[field] = path;
        this.preCommAuthForm.patchValue(adjForm);
        console.log(field, this.caseData);
      }

      this.toaster.showSuccess('File Uploaded Successfully');
    });
  }
  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (
      let splitedPathIndex = 0;
      splitedPathIndex < splitedPath.length - 1;
      splitedPathIndex++
    ) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
      // path.push(pathChunk.substr(0, indexOfBrc));
      // path.push(pathChunk.charAt(indexOfBrc + 1));
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  saveAppealDetailList() {
    const appealDetailList = this.preCommAuthForm.value.submissions;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.copyOfAckLoc) {
          this.appealDetailItemList[i].copyOfAckLoc = item.copyOfAckLoc;
        }
        if (item.otherAttachmentsLoc) {
          this.appealDetailItemList[i].otherAttachmentsLoc =
            item.otherAttachmentsLoc;
        }
      });
    }
    this.preCommAuthForm.controls.submissions.patchValue(appealDetailList);
  }

  setAppealDetailItemList() {
    this.preCommAuthForm.value.submissions.forEach((data: any, i: number) => {
      if (!data.copyOfAckLoc) {
        data.copyOfAckLoc = this.appealDetailItemList[i]?.copyOfAckLoc;
      }

      if (!data.otherAttachmentsLoc) {
        data.otherAttachmentsLoc =
          this.appealDetailItemList[i]?.otherAttachmentsLoc;
      }
    });
  }

  // lability data
  saveLiabilityDetailsList() {
    const appealDetailList = this.preCommAuthForm.value.liabilityDetails;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.copyOfFormLoc) {
          this.appealDetailItemList[i].copyOfFormLoc = item.copyOfFormLoc;
        }
      });
    }
    this.preCommAuthForm.controls.liabilityDetails.patchValue(appealDetailList);
  }
  setLiabilityDetailsList() {
    this.preCommAuthForm.value.liabilityDetails.forEach(
      (data: any, i: number) => {
        if (!data.copyOfFormLoc) {
          data.copyOfFormLoc = this.appealDetailItemList[i]?.copyOfFormLoc;
        }
      }
    );
  }

  saveConsultantChargesList() {
    const appealDetailList = this.preCommAuthForm.value.consultantCharges;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.otherAttachmentLoc) {
          this.appealDetailItemList[i].otherAttachmentLoc =
            item.otherAttachmentLoc;
        }
      });
    }
    this.preCommAuthForm.controls.consultantCharges.patchValue(
      appealDetailList
    );
  }
  setConsultantChargesList() {
    this.preCommAuthForm.value.consultantCharges.forEach(
      (data: any, i: number) => {
        if (!data.otherAttachmentLoc) {
          data.otherAttachmentLoc =
            this.appealDetailItemList[i]?.otherAttachmentLoc;
        }
      }
    );
  }

  saveStayOrderList() {
    const appealDetailList = this.preCommAuthForm.value.stayOrder;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.copyOfStayLoc) {
          this.appealDetailItemList[i].copyOfStayLoc = item.copyOfStayLoc;
        }
      });
    }
    this.preCommAuthForm.controls.stayOrder.patchValue(appealDetailList);
  }
  setStayOrderList() {
    this.preCommAuthForm.value.stayOrder.forEach((data: any, i: number) => {
      if (!data.copyOfStayLoc) {
        data.copyOfStayLoc = this.appealDetailItemList[i]?.copyOfStayLoc;
      }
    });
  }

  savePortestlDetailList() {
    const appealDetailList = this.preCommAuthForm.value.protestDetails;
    if (appealDetailList.length > 0) {
      appealDetailList.forEach((item: any, i: number) => {
        if (!this.appealDetailItemList[i]) {
          this.appealDetailItemList.push({});
        }
        if (item.supportingDocLoc) {
          this.appealDetailItemList[i].supportingDocLoc = item.supportingDocLoc;
        }
      });
    }
    this.preCommAuthForm.controls.protestDetails.patchValue(appealDetailList);
  }
  setPortestlDetailList() {
    this.preCommAuthForm.value.protestDetails.forEach(
      (data: any, i: number) => {
        if (!data.supportingDocLoc) {
          data.supportingDocLoc =
            this.appealDetailItemList[i]?.supportingDocLoc;
        }
      }
    );
  }

  saveDepositDetailsList() {
    const depositappealDetailList = this.preCommAuthForm.value.depositDetails;
    if (depositappealDetailList.length > 0) {
      depositappealDetailList.forEach((item: any, i: number) => {
        if (!this.preDepositeDetailItemList[i]) {
          this.preDepositeDetailItemList.push({});
        }
        if (item.supportingDocLoc) {
          this.preDepositeDetailItemList[i].supportingDocLoc =
            item.supportingDocLoc;
        }
      });
    }
    this.preCommAuthForm.controls.depositDetails.patchValue(
      depositappealDetailList
    );
  }
  setDepositDetailsList() {
    this.preCommAuthForm.value.depositDetails.forEach(
      (data: any, i: number) => {
        if (!data.supportingDocLoc) {
          data.supportingDocLoc =
            this.preDepositeDetailItemList[i]?.supportingDocLoc;
        }
      }
    );
  }

  //download file
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl,
    };
    this.preLitService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.response;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    });
  }
  // Divison Block
  gstinDivData() {
    this.bussinessService.getGstinDiv().subscribe((response: any) => {
      if (response.status === 1) {
        this.preGstinDivList = response.response.divList;
      }
    });
  }
  uploadbankChallanLoc1() {
    this.isbankChallanLoc1File = true;
    this.isbankChallanLoc1Text = false;
    this.isDWbankChallanLoc1 = false;
    this.isUPbankChallanLoc1 = false;
  }
  uploadappealBookLoc1() {
    this.isappealBookLoc1File = true;
    this.isappealBookLoc1Text = false;
    this.isDWappealBookLoc1 = false;
    this.isUPappealBookLoc1 = false;
  }
  uploaddrc03CopyLoc1() {
    this.isdrc03CopyLoc1File = true;
    this.isdrc03CopyLoc1Text = false;
    this.isDWdrc03CopyLoc1 = false;
    this.isUPdrc03CopyLoc1 = false;
  }

  uploadcopyOfAckLoc() {
    this.iscopyOfAckLocFile = true;
    this.iscopyOfAckLocText = false;
    this.isDWcopyOfAckLoc = false;
    this.isUPcopyOfAckLoc = false;
  }

  uploadotherAttachmentsLoc1() {
    this.isotherAttachmentsLoc1File = true;
    this.isotherAttachmentsLoc1Text = false;
    this.isDWotherAttachmentsLoc1 = false;
    this.isUPotherAttachmentsLoc1 = false;
    this.preCommAuthForm.value.otherAttachmentsLoc1 = null;
  }

  uploadotherAttachmentsLoc2() {
    this.isotherAttachmentsLoc2File = true;
    this.isotherAttachmentsLoc2Text = false;
    this.isDWotherAttachmentsLoc2 = false;
    this.isUPotherAttachmentsLoc2 = false;
  }

  // uploadotherattachmentsLoc() {
  //   this.isconsultantattachmentsLocFile = true;
  //   this.isconsultantattachmentsLocText = false;
  //   this.isconsultantDWattachmentsLoc = true;
  //   this.isconsultantUPattachmentsLoc = false;
  // }

  uploadphCopyLoc(index: any) {
    this.isphCopyLocFile = true;
    this.isphCopyLocText = false;
    this.isDWphCopyLoc = false;
    this.isUPphCopyLoc = false;
    var itemListArray = this.preCommAuthForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhCopyLocUploaded'] = true;
        }
      }
    }
  }

  uploadadditionalSubmissionLoc(index: any) {
    this.isadditionalSubmissionLocFile = true;
    this.isadditionalSubmissionLocText = false;
    this.isDWadditionalSubmissionLoc = false;
    this.isUPadditionalSubmissionLoc = false;
    var itemListArray = this.preCommAuthForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isAdditionalSubmissionLocUploaded'] = true;
        }
      }
    }
  }

  uploadphRecordIfAnyLoc(index: any) {
    this.isphRecordIfAnyLocFile = true;
    this.isphRecordIfAnyLocText = false;
    this.isDWphRecordIfAnyLoc = false;
    this.isUPphRecordIfAnyLoc = false;
    var itemListArray = this.preCommAuthForm.controls.itemList as UntypedFormArray;
    if (itemListArray.controls.length > 0) {
      for (var i = 0; i < itemListArray.controls.length; i++) {
        if (index === i) {
          var issues1Control = itemListArray.controls[i] as UntypedFormGroup;
          issues1Control.value['isPhRecordIfAnyLocUploaded'] = true;
        }
      }
    }
  }

  uploadoiaLoc() {
    this.isoiaLocFile = true;
    this.isoiaLocText = false;
    this.isDWoiaLoc = false;
    this.isUPoiaLoc = false;
  }

  uploadbankChallanLoc3() {
    this.isbankChallanLoc3File = true;
    this.isbankChallanLoc3Text = false;
    this.isDWbankChallanLoc3Loc = false;
    this.isUPbankChallanLoc3Loc = false;
  }

  uploaddrc03CopyLoc3() {
    this.isdrc03CopyLoc3File = true;
    this.isdrc03CopyLoc3Text = false;
    this.isDWdrc03CopyLoc3 = false;
    this.isUPdrc03CopyLoc3 = false;
  }
  // oio upload function
  uploadnoticeLoc() {
    this.isnoticeLocFile = true;
    this.isnoticeLocText = false;
    this.isDWnoticeLoc = false;
    this.isUPnoticeLoc = false;
  }
  rmWeatherApplErrorBorder() {
    this.someElement = document.getElementById('whetApplPref');
    this.someElement.classList.remove('errorBorder');
  }
  rmWeatherWritErrorBorder() {
    this.someElement = document.getElementById('whetWritPref');
    this.someElement.classList.remove('errorBorder');
  }
  checkAppYes(event: any) {
    if (event.target.value === 'true') {
      this.disabledWeatherWrit = true;
      this.disabledWeatherDept = true;
      this.preCommAuthForm.get('writAppPreferred')?.clearValidators();
      this.preCommAuthForm.get('writAppPreferred')?.updateValueAndValidity();
      this.preCommAuthForm.get('writAppPreferred')?.setValue(false);

      this.preCommAuthForm.get('whetherDptAppealPreferred')?.clearValidators();
      this.preCommAuthForm
        .get('whetherDptAppealPreferred')
        ?.updateValueAndValidity();
      this.preCommAuthForm.get('whetherDptAppealPreferred')?.setValue(false);
      this.preCommAuthForm.get('forumOfAppeal')?.setErrors({ required: true });
      // this.adjP1Form.controls['writAppPreferred'].setErrors({'required': false});
      // this.rmWeatherWritErrorBorder();
    } else {
      this.disabledWeatherWrit = false;
      this.disabledWeatherDept = false;
    }
    this.setForumOfAppeal();
  }

  checkDeptYes(event: any) {
    if (event.target.value === 'true') {
      this.disabledWeatherAppl = true;
      this.disabledWeatherWrit = true;
      this.preCommAuthForm.get('writAppPreferred')?.clearValidators();
      this.preCommAuthForm.get('writAppPreferred')?.updateValueAndValidity();
      this.preCommAuthForm.get('writAppPreferred')?.setValue(false);

      this.preCommAuthForm.get('whetherAppealPreferred')?.clearValidators();
      this.preCommAuthForm
        .get('whetherAppealPreferred')
        ?.updateValueAndValidity();
      this.preCommAuthForm.get('whetherAppealPreferred')?.setValue(false);
      this.preCommAuthForm.get('forumOfAppeal')?.setErrors({ required: true });
      // this.adjP1Form.controls['writAppPreferred'].setErrors({'required': false});
      // this.rmWeatherWritErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherWrit = false;
    }
    this.setForumOfAppeal();
  }
  checkwritAppYes(event: any) {
    if (event.target.value === 'true') {
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = true;
      this.preCommAuthForm.get('whetherAppealPreferred')?.clearValidators();
      this.preCommAuthForm
        .get('whetherAppealPreferred')
        ?.updateValueAndValidity();
      this.preCommAuthForm.get('whetherAppealPreferred')?.setValue(false);

      this.preCommAuthForm.get('whetherDptAppealPreferred')?.clearValidators();
      this.preCommAuthForm
        .get('whetherDptAppealPreferred')
        ?.updateValueAndValidity();
      this.preCommAuthForm.get('whetherDptAppealPreferred')?.setValue(false);
      //this.rmWeatherApplErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
    }
    this.setForumOfAppeal();
  }
  changeOutcome(event: any = null) {
    let data;
    if (!event) {
      data = this.preCommAuthForm.value.outcome;
    } else {
      data = event.target.value;
    }
    if (data === 'Favorable') {
      this.disabledWeatherWrit = true;
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = false;
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER.filter(
        (x) => x.value == 'Favorable'
      );
      this.preCommAuthForm.get('whetherAppealPreferred')?.clearValidators();
      this.preCommAuthForm
        .get('whetherAppealPreferred')
        ?.updateValueAndValidity();
      this.preCommAuthForm.get('whetherAppealPreferred')?.setValue(false);
      this.preCommAuthForm.get('writAppPreferred')?.clearValidators();
      this.preCommAuthForm.get('writAppPreferred')?.updateValueAndValidity();
      this.preCommAuthForm.get('writAppPreferred')?.setValue(false);
      this.preCommAuthForm.get('whetherDptAppealPreferred')?.clearValidators();
      this.preCommAuthForm
        .get('whetherDptAppealPreferred')
        ?.updateValueAndValidity();
      // this.preCommAuthForm.get("whetherDptAppealPreferred")?.setValue(false);
      var issues1Array = this.preCommAuthForm.controls.issues as UntypedFormArray;
      if (issues1Array.controls.length > 0) {
        for (var i = 0; i < issues1Array.controls.length; i++) {
          var issues1Control = issues1Array.controls[i] as UntypedFormGroup;
          issues1Control.controls.status.setValue(data);
        }
      }
    } else if (data === 'Unfavorable') {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER.filter(
        (x) => x.value == 'Unfavorable' || x.value == 'Admitted'
      );
      this.preCommAuthForm.get('whetherAppealPreferred')?.clearValidators();
      this.preCommAuthForm
        .get('whetherAppealPreferred')
        ?.updateValueAndValidity();
      // this.preCommAuthForm.get("whetherAppealPreferred")?.setValue(true);
      // this.preCommAuthForm.get('whetherAppealPreferred')?.enable();
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = true;
      // var issues1Array = this.preCommAuthForm.controls.issues as FormArray
      // if(issues1Array.controls.length > 0) {
      //   for(var i = 0; i < issues1Array.controls.length; i++){
      //       var issues1Control = issues1Array.controls[i] as FormGroup;
      //       issues1Control.controls.status.setValue(data);
      //     }
      // }
    } else if (data === 'Partly Favorable') {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER.filter(
        (x) =>
          x.value == 'Partly Favorable' ||
          x.value == 'Favorable' ||
          x.value == 'Unfavorable' ||
          x.value == 'Partly Unfavorable' ||
          x.value == 'Admitted'
      );
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
    } else if (data === 'Partly Unfavorable') {
      this.issueConclusion = ISSUE_CONCLUSION_COMMISSIONER.filter(
        (x) =>
          x.value == 'Favorable' ||
          x.value == 'Unfavorable' ||
          x.value == 'Partly Favorable' ||
          x.value == 'Partly Unfavorable' ||
          x.value == 'Admitted'
      );
      this.disabledWeatherWrit = false;
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
    }
  }
  checkfurAppDeciYes(event: any = null) {
    let data = null;
    if (!event) {
      data = this.preCommAuthForm.value.furtherAppealDecision;
    } else {
      data = event.target.value;
    }
    if (data === 'true' || data === true) {
      this.disabledWeatherAppl = true;
      this.disabledWeatherDept = true;
      this.disabledWeatherWrit = true;
      this.preCommAuthForm.get('whetherAppealPreferred')?.clearValidators();
      this.preCommAuthForm.get('whetherAppealPreferred')?.updateValueAndValidity();
      this.preCommAuthForm.get('whetherAppealPreferred')?.setValue('false');

      this.preCommAuthForm.get('whetherDptAppealPreferred')?.clearValidators();
      this.preCommAuthForm.get('whetherDptAppealPreferred')?.updateValueAndValidity();
      this.preCommAuthForm.get('whetherDptAppealPreferred')?.setValue('false');

      this.preCommAuthForm.get('writAppPreferred')?.clearValidators();
      this.preCommAuthForm.get('writAppPreferred')?.updateValueAndValidity();
      this.preCommAuthForm.get('writAppPreferred')?.setValue('false');
      //this.rmWeatherApplErrorBorder();
    } else {
      this.disabledWeatherAppl = false;
      this.disabledWeatherDept = false;
      this.disabledWeatherWrit = false;
    }
    this.setForumOfAppeal();
    this.preCommAuthForm.controls['forumOfAppeal'].setValue(
      this.caseData?.forumOfAppeal
    );
  }

  issueDropdown() {
    this.dataSource = PREGST_ISSUE_DROPDOWN;
  }
  savecaseItemList() {
    console.log(this.preCommAuthForm.value, 'value');
    const itemList = this.preCommAuthForm.value.itemList;
    if (itemList.length > 0) {
      itemList.forEach((item: any, i: number) => {
        if (!this.caseItemList[i]) {
          this.caseItemList.push({});
        }
        if (item.phCopyLoc) {
          this.caseItemList[i].phCopyLoc = item.phCopyLoc;
        }
        if (item.additionalSubmissionLoc) {
          this.caseItemList[i].additionalSubmissionLoc =
            item.additionalSubmissionLoc;
        }
        if (item.phRecordIfAnyLoc) {
          this.caseItemList[i].phRecordIfAnyLoc = item.phRecordIfAnyLoc;
        }
        if (item.phAdjournmentLetterLoc) {
          this.caseItemList[i].phAdjournmentLetterLoc = item.phAdjournmentLetterLoc;
        }
        console.log(this.caseItemList);
      });

      // this.caseItemList.push()
    }
  }

  setCaseItemList() {
    this.preCommAuthForm.value.itemList.forEach((data: any, i: number) => {
      if (!data.phCopyLoc) {
        data.phCopyLoc = this.caseItemList[i]?.phCopyLoc;
      }
      if (!data.additionalSubmissionLoc) {
        data.additionalSubmissionLoc =
          this.caseItemList[i]?.additionalSubmissionLoc;
      }
      if (!data.phRecordIfAnyLoc) {
        data.phRecordIfAnyLoc = this.caseItemList[i]?.phRecordIfAnyLoc;
      }
      if (!data.phAdjournmentLetterLoc) {
        data.phAdjournmentLetterLoc = this.caseItemList[i]?.phAdjournmentLetterLoc;
      }
    });
  }

  finYearSelelctEvent(i: any) {
    let issuesFrmArray = this.preCommAuthForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get(
      'details'
    ) as UntypedFormArray;
    const alreadySelectedFinYear = detailsFrmArray.value?.map(
      (data: any) => data.finYear
    );
    this.finyearData.forEach((f) => {
      if (alreadySelectedFinYear.includes(f.year)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }
  quarterSelectEvent(i: any) {
    let issuesFrmArray = this.preCommAuthForm.get('issues') as UntypedFormArray;
    let detailsFrmArray = issuesFrmArray.controls[i].get(
      'details'
    ) as UntypedFormArray;
    const alreadySelectedQuarter = detailsFrmArray.value?.map(
      (data: any) => data.quarter
    );
    this.quarterList.forEach((f: any) => {
      if (alreadySelectedQuarter.includes(f.value)) {
        f.disabled = true;
      } else {
        f.disabled = false;
      }
    });
  }

  onKeyDownEvent(event: any, type: any) {
    if (event.keyCode === 8 || event.keyCode === 46) {
      if (type === 'protestDetails') {
        this.paymentUnderProtestp1Changed();
      }
      if (type === 'issues') {
        this.issueValueChange();
      }
      if (type === 'liabilityDetails') {
        this.liabilityAddmittedAndPaidChanged();
      }
      if (type === 'consultantCharges') {
        this.feesChargedChanged();
      }
      if (type === 'depositDetails') {
        this.preDepositeChanged();
      }
      if (type == 'remandedPaymentMade') {
        this.remandedBackPaymentValueChange();
      }
    }
  }
  issueValueChange() {
    let exciseDuty1Total = 0;
    let serviceTax1Total = 0;
    let ec1Total = 0;
    let shec1Total = 0;
    let kkc1Total = 0;
    let sbc1Total = 0;
    let vat1Total = 0;
    let cst1Total = 0;
    let bcd1Total = 0;
    let sad1Total = 0;
    let antiDumpingDuty1Total = 0;
    let igst1Total = 0;
    let ent1Total = 0;
    let interest1Total = 0;
    let penalty1Total = 0;

    let exciseDuty3Total = 0;
    let serviceTax3Total = 0;
    let ec3Total = 0;
    let shec3Total = 0;
    let kkc3Total = 0;
    let sbc3Total = 0;
    let vat3Total = 0;
    let cst3Total = 0;
    let bcd3Total = 0;
    let sad3Total = 0;
    let antiDumpingDuty3Total = 0;
    let igst3Total = 0;
    let ent3Total = 0;
    let interest3Total = 0;
    let penalty3Total = 0;

    (this.preCommAuthForm.get('issues') as UntypedFormArray).controls.forEach((c) => {
      const exciseDuty1 =
        typeof c.value.exciseDuty1 === 'string'
          ? c.value.exciseDuty1.replace(/,/g, '')
          : c.value.exciseDuty1;
      const serviceTax1 =
        typeof c.value.serviceTax1 === 'string'
          ? c.value.serviceTax1.replace(/,/g, '')
          : c.value.serviceTax1;
      const ec1 =
        typeof c.value.ec1 === 'string'
          ? c.value.ec1.replace(/,/g, '')
          : c.value.ec1;
      const shec1 =
        typeof c.value.shec1 === 'string'
          ? c.value.shec1.replace(/,/g, '')
          : c.value.shec1;
      const kkc1 =
        typeof c.value.kkc1 === 'string'
          ? c.value.kkc1.replace(/,/g, '')
          : c.value.kkc1;
      const sbc1 =
        typeof c.value.sbc1 === 'string'
          ? c.value.sbc1.replace(/,/g, '')
          : c.value.sbc1;
      const vat1 =
        typeof c.value.vat1 === 'string'
          ? c.value.vat1.replace(/,/g, '')
          : c.value.vat1;
      const cst1 =
        typeof c.value.cst1 === 'string'
          ? c.value.cst1.replace(/,/g, '')
          : c.value.cst1;
      const bcd1 =
        typeof c.value.bcd1 === 'string'
          ? c.value.bcd1.replace(/,/g, '')
          : c.value.bcd1;
     const sad1 = 
     typeof c.value.sad1 === 'string'
      ? c.value.sad1.replace(/,/g, '') 
      : c.value.sad1;
     const antiDumpingDuty1 = typeof c.value.antiDumpingDuty1 === 'string' ? c.value.antiDumpingDuty1.replace(/,/g, '') : c.value.antiDumpingDuty1;
     const igst1 = typeof c.value.igst1 === 'string' ? c.value.igst1.replace(/,/g, '') : c.value.igst1;
      const ent1 =
        typeof c.value.ent1 === 'string'
          ? c.value.ent1.replace(/,/g, '')
          : c.value.ent1;
      const interest1 =
        typeof c.value.interest1 === 'string'
          ? c.value.interest1.replace(/,/g, '')
          : c.value.interest1;
      const penalty1 =
        typeof c.value.penalty1 === 'string'
          ? c.value.penalty1.replace(/,/g, '')
          : c.value.penalty1;

      const exciseDuty3 =
        typeof c.value.exciseDuty3 === 'string'
          ? c.value.exciseDuty3.replace(/,/g, '')
          : c.value.exciseDuty3;
      const serviceTax3 =
        typeof c.value.serviceTax3 === 'string'
          ? c.value.serviceTax3.replace(/,/g, '')
          : c.value.serviceTax3;
      const ec3 =
        typeof c.value.ec3 === 'string'
          ? c.value.ec3.replace(/,/g, '')
          : c.value.ec3;
      const shec3 =
        typeof c.value.shec3 === 'string'
          ? c.value.shec3.replace(/,/g, '')
          : c.value.shec3;
      const kkc3 =
        typeof c.value.kkc3 === 'string'
          ? c.value.kkc3.replace(/,/g, '')
          : c.value.kkc3;
      const sbc3 =
        typeof c.value.sbc3 === 'string'
          ? c.value.sbc3.replace(/,/g, '')
          : c.value.sbc3;
      const vat3 =
        typeof c.value.vat3 === 'string'
          ? c.value.vat3.replace(/,/g, '')
          : c.value.vat3;
      const cst3 =
        typeof c.value.cst3 === 'string'
          ? c.value.cst3.replace(/,/g, '')
          : c.value.cst3;
      const bcd3 =
        typeof c.value.bcd3 === 'string'
          ? c.value.bcd3.replace(/,/g, '')
          : c.value.bcd3;
          const sad3 = typeof c.value.sad3 === 'string' ? c.value.sad3.replace(/,/g, '') : c.value.sad3;
          const antiDumpingDuty3 = typeof c.value.antiDumpingDuty3 === 'string' ? c.value.antiDumpingDuty3.replace(/,/g, '') : c.value.antiDumpingDuty3;
          const igst3 = typeof c.value.igst3 === 'string' ? c.value.igst3.replace(/,/g, '') : c.value.igst3;
      const ent3 =
        typeof c.value.ent3 === 'string'
          ? c.value.ent3.replace(/,/g, '')
          : c.value.ent3;
      const interest3 =
        typeof c.value.interest3 === 'string'
          ? c.value.interest3.replace(/,/g, '')
          : c.value.interest3;
      const penalty3 =
        typeof c.value.penalty3 === 'string'
          ? c.value.penalty3.replace(/,/g, '')
          : c.value.penalty3;

      exciseDuty1Total += Number(exciseDuty1);
      serviceTax1Total += Number(serviceTax1);
      ec1Total += Number(ec1);
      shec1Total += Number(shec1);
      kkc1Total += Number(kkc1);
      sbc1Total += Number(sbc1);
      vat1Total += Number(vat1);
      cst1Total += Number(cst1);
      bcd1Total += Number(bcd1);
      sad1Total += Number(sad1);
      antiDumpingDuty1Total += Number(antiDumpingDuty1);
      igst1Total += Number(igst1);
      ent1Total += Number(ent1);
      interest1Total += Number(interest1);
      penalty1Total += Number(penalty1);

      exciseDuty3Total += Number(exciseDuty3);
      serviceTax3Total += Number(serviceTax3);
      ec3Total += Number(ec3);
      shec3Total += Number(shec3);
      kkc3Total += Number(kkc3);
      sbc3Total += Number(sbc3);
      vat3Total += Number(vat3);
      cst3Total += Number(cst3);
      bcd3Total += Number(bcd3);
      sad3Total += Number(sad3);
      antiDumpingDuty3Total += Number(antiDumpingDuty3);
      igst3Total += Number(igst3);
      ent3Total += Number(ent3);
      interest3Total += Number(interest3);
      penalty3Total += Number(penalty3);

      c.patchValue({
        total1: this.inrService.formatCurrency(
          String(
            Number(serviceTax1) +
            Number(exciseDuty1) +
            Number(ec1) +
            Number(shec1) +
            Number(kkc1) +
            Number(sbc1) +
            Number(vat1) +
            Number(cst1) +
            Number(bcd1) +
            Number(sad1) + 
            Number(antiDumpingDuty1) +
            Number(igst1) +
            Number(ent1) +
            Number(interest1) +
            Number(penalty1)
          )
        ),
      });
      c.patchValue({
        total3: this.inrService.formatCurrency(
          String(
            Number(serviceTax3) +
            Number(exciseDuty3) +
            Number(ec3) +
            Number(shec3) +
            Number(kkc3) +
            Number(sbc3) +
            Number(vat3) +
            Number(cst3) +
            Number(bcd3) +
            Number(sad3) + 
            Number(antiDumpingDuty3) +
            Number(igst3) +
            Number(ent3) +
            Number(interest3) +
            Number(penalty3)
          )
        ),
      });
    });

    this.preCommAuthForm.controls['exciseDuty1Total'].setValue(
      this.inrService.formatCurrency(String(exciseDuty1Total))
    );
    this.preCommAuthForm.controls['serviceTax1Total'].setValue(
      this.inrService.formatCurrency(String(serviceTax1Total))
    );
    this.preCommAuthForm.controls['ec1Total'].setValue(
      this.inrService.formatCurrency(String(ec1Total))
    );
    this.preCommAuthForm.controls['shec1Total'].setValue(
      this.inrService.formatCurrency(String(shec1Total))
    );
    this.preCommAuthForm.controls['kkc1Total'].setValue(
      this.inrService.formatCurrency(String(kkc1Total))
    );
    this.preCommAuthForm.controls['sbc1Total'].setValue(
      this.inrService.formatCurrency(String(sbc1Total))
    );
    this.preCommAuthForm.controls['vat1Total'].setValue(
      this.inrService.formatCurrency(String(vat1Total))
    );
    this.preCommAuthForm.controls['cst1Total'].setValue(
      this.inrService.formatCurrency(String(cst1Total))
    );
    this.preCommAuthForm.controls['bcd1Total'].setValue(
      this.inrService.formatCurrency(String(bcd1Total))
    );
    this.preCommAuthForm.controls['sad1Total'].setValue(
      this.inrService.formatCurrency(String(sad1Total))
    );
    this.preCommAuthForm.controls['antiDumpingDuty1Total'].setValue(
      this.inrService.formatCurrency(String(antiDumpingDuty1Total))
    );
    this.preCommAuthForm.controls['igst1Total'].setValue(
      this.inrService.formatCurrency(String(igst1Total))
    );
    this.preCommAuthForm.controls['ent1Total'].setValue(
      this.inrService.formatCurrency(String(ent1Total))
    );
    this.preCommAuthForm.controls['interest1Total'].setValue(
      this.inrService.formatCurrency(String(interest1Total))
    );
    this.preCommAuthForm.controls['penalty1Total'].setValue(
      this.inrService.formatCurrency(String(penalty1Total))
    );

    this.preCommAuthForm.controls['exciseDuty3Total'].setValue(
      this.inrService.formatCurrency(String(exciseDuty3Total))
    );
    this.preCommAuthForm.controls['serviceTax3Total'].setValue(
      this.inrService.formatCurrency(String(serviceTax3Total))
    );
    this.preCommAuthForm.controls['ec3Total'].setValue(
      this.inrService.formatCurrency(String(ec3Total))
    );
    this.preCommAuthForm.controls['shec3Total'].setValue(
      this.inrService.formatCurrency(String(shec3Total))
    );
    this.preCommAuthForm.controls['kkc3Total'].setValue(
      this.inrService.formatCurrency(String(kkc3Total))
    );
    this.preCommAuthForm.controls['sbc3Total'].setValue(
      this.inrService.formatCurrency(String(sbc3Total))
    );
    this.preCommAuthForm.controls['vat3Total'].setValue(
      this.inrService.formatCurrency(String(vat3Total))
    );
    this.preCommAuthForm.controls['cst3Total'].setValue(
      this.inrService.formatCurrency(String(cst3Total))
    );
    this.preCommAuthForm.controls['bcd3Total'].setValue(
      this.inrService.formatCurrency(String(bcd3Total))
    );
    this.preCommAuthForm.controls['sad3Total'].setValue(this.inrService.formatCurrency(String(sad3Total)));
    this.preCommAuthForm.controls['antiDumpingDuty3Total'].setValue(this.inrService.formatCurrency(String(antiDumpingDuty3Total)));
    this.preCommAuthForm.controls['igst3Total'].setValue(this.inrService.formatCurrency(String(igst3Total)));
    this.preCommAuthForm.controls['ent3Total'].setValue(
      this.inrService.formatCurrency(String(ent3Total))
    );
    this.preCommAuthForm.controls['interest3Total'].setValue(
      this.inrService.formatCurrency(String(interest3Total))
    );
    this.preCommAuthForm.controls['penalty3Total'].setValue(
      this.inrService.formatCurrency(String(penalty3Total))
    );

    this.preCommAuthForm.controls['totOfTotal1'].setValue(
      this.inrService.formatCurrency(
        String(
          Number(
            +exciseDuty1Total +
            +serviceTax1Total +
            +ec1Total +
            +shec1Total +
            +kkc1Total +
            +sbc1Total +
            +vat1Total +
            +cst1Total +
            +bcd1Total +
            +sad1Total + 
            +antiDumpingDuty1Total + 
            +igst1Total +
            +ent1Total +
            +interest1Total +
            +penalty1Total
          )
        )
      )
    );
    this.preCommAuthForm.controls['totOfTotal3'].setValue(
      this.inrService.formatCurrency(
        String(
          Number(
            +exciseDuty3Total +
            +serviceTax3Total +
            +ec3Total +
            +shec3Total +
            +kkc3Total +
            +sbc3Total +
            +vat3Total +
            +cst3Total +
            +bcd3Total +
            +sad3Total + 
            +antiDumpingDuty3Total + 
            +igst3Total +
            +ent3Total +
            +interest3Total +
            +penalty3Total
          )
        )
      )
    );
  }

  changeRemandedBackForum(event: any) {
    let data: any = {};
    this.remandedCaseObj = [];
    data.cumulative = this.cumulativeCount;
    let model: any = {};
    const criteriaArray: any = [{
      "p": "type",
      "o": "eq",
      "v": event.target.value
    }]
    model.criterias = criteriaArray;
    model.companyId = localStorage.getItem('selectedId'),
      this.preLitService.getRemandedBackForumPregstOptions(model)
        .subscribe((response: any) => {
          if (response) {
            this.remandBackCaseList = response.response;
            response?.response.forEach((res: any) => {
              let obj = {
                key: res.caseId,
                value: res.caseId,
                cumulative: res.cumulative,
                issues: res.issues,
              };
              this.remandedCaseObj.push(obj);
            });
          }
        });
  }

  setRemandBackData(event: any) {
    // this.disabledOnload = true;
    let remandBackCaseData: any = this.remandBackCaseList.find(
      (data: any) => data.caseId == this.preCommAuthForm.value.remandedCaseId
    );
    this.preCommAuthForm.controls['caseId'].setValue(
      remandBackCaseData?.caseId
    );
    this.preCommAuthForm.controls['actType'].setValue(
      remandBackCaseData?.actType
    );
    this.changeTax(null);
    this.preCommAuthForm.controls['state'].setValue(remandBackCaseData?.state);
    this.changeState(null);
    this.preCommAuthForm.controls['regNo'].setValue(remandBackCaseData?.regNo);
    if (remandBackCaseData?.issues?.length) this.rmIssueInv(0, true);
    this.initializeIssueList(remandBackCaseData);
  }

  selectedRemandedBackCaseId(event: any) {
    this.selectedRemandedBackCaseDetail = this.remandedCaseObj.find(
      (c: any) => c.value === event.target.value
    );
    this.initializeRemandedBackPaymentData(
      this.selectedRemandedBackCaseDetail.cumulative
    );

    this.setCummulativeCount();
  }

  // setCummulativeCount(){
  //   let preDepositeTax = 0;
  //   let preDepositePenalty   = 0;
  //   let preDepositeInterest = 0;
  //   let preDepositeTotal = 0;

  //   let protestPaymentTax = 0;
  //   let protestPaymentPenalty   = 0;
  //   let protestPaymentInterest = 0;
  //   let protestPaymentTotal = 0;

  //   let liabilityPaymentTax = 0
  //   let liabilityPaymentPenalty = 0;
  //   let liabilityPaymentInterest = 0;
  //   let liabilityPaymentTotal = 0;

  //   let consultantFees = 0;
  //  if(this.selectedRemandedBackCaseDetail && this.selectedRemandedBackCaseDetail.cumulative.length > 0){

  //  this.selectedRemandedBackCaseDetail.cumulative.forEach((p: any) =>{
  //    if(p.label === 'Cumulative payment made under protest'){
  //      p.tax ? protestPaymentTax += p.tax : null;
  //      p.penalty ? protestPaymentPenalty += p.penalty : null;
  //      p.interest ? protestPaymentInterest += p.interest : null;
  //      p.total ? protestPaymentTotal +=p.total : null;
  //    }else if(p.label === 'Cumulative payment made for admitted liability'){
  //      p.tax ? liabilityPaymentTax += p.tax : null;
  //      p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //      p.interest ? liabilityPaymentInterest += p.interest : null;
  //      p.total ? liabilityPaymentTotal +=p.total : null;
  //    }else if(p.label === 'Cumulative Consultant Charges'){
  //      p.fees ? consultantFees += p.fees : null;

  //    }
  //    })
  //  }

  //  if(this.caseData.cumulative && this.caseData.cumulative.length > 0){
  //   this.caseData.cumulative.forEach((p: any) =>{
  //     if(p.label === 'Cumulative payment made under protest'){
  //       p.tax ? protestPaymentTax += p.tax : null;
  //       p.penalty ? protestPaymentPenalty += p.penalty : null;
  //       p.interest ? protestPaymentInterest += p.interest : null;
  //       p.total ? protestPaymentTotal +=p.total : null;
  //     }else if(p.label === 'Cumulative payment made for admitted liability'){
  //       p.tax ? liabilityPaymentTax += p.tax : null;
  //       p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //       p.interest ? liabilityPaymentInterest += p.interest : null;
  //       p.total ? liabilityPaymentTotal +=p.total : null;
  //     }else if(p.label === 'Cumulative Consultant Charges'){
  //       p.total ? consultantFees += p.total : null;

  //     }
  //     })
  //  }

  //  this.preCommAuthForm.value.protestDetails.forEach((p : any) =>{

  //    p.tax ? protestPaymentTax += p.tax : null;
  //    p.penalty ? protestPaymentPenalty += p.penalty : null;
  //    p.interest ? protestPaymentInterest += p.interest : null;
  //    p.total ? protestPaymentTotal +=p.total : null;
  //  });
  //  this.preCommAuthForm.value.liabilityDetails.forEach((p : any) =>{
  //    p.tax ? liabilityPaymentTax += p.tax : null;
  //    p.penalty ? liabilityPaymentPenalty += p.penalty : null;
  //    p.interest ? liabilityPaymentInterest += p.interest : null;
  //    p.total ? liabilityPaymentTotal +=p.total : null;
  //  });
  //  this.preCommAuthForm.value.depositDetails.forEach((p:any) => {
  //   p.tax ? preDepositeTax += p.tax : null;
  //   p.penalty ? preDepositePenalty += p.penalty : null;
  //   p.interest ? preDepositeInterest += p.interest : null;
  //   p.total ? preDepositeTotal +=p.total : null;
  //  });

  //  this.preCommAuthForm.value.consultantCharges.forEach((c : any) =>{
  //    c.fees ? consultantFees += c.fees : null;
  //  });
  //  this.cumulativeCount.forEach((c: any) =>{
  //       if(c.label === 'Cumulative Pre-Deposit'){
  //         preDepositeTax ?  c.tax = preDepositeTax  : null;
  //        preDepositePenalty ?  c.penalty = preDepositePenalty  : null;
  //        preDepositeInterest ?  c.interest = preDepositeInterest  : null;
  //        preDepositeTotal ?  c.total = preDepositeTotal  : null;
  //       }
  //      if(c.label === 'Cumulative payment made under protest'){
  //        protestPaymentTax ?  c.tax = protestPaymentTax  : null;
  //        protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
  //        protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
  //        protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
  //      }else if(c.label === 'Cumulative payment made for admitted liability '){
  //        liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
  //        liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
  //        liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
  //        liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
  //      }else if(c.label === 'Cumulative Consultant Charges'){
  //        consultantFees ? c.total = consultantFees  : null;
  //      }
  //  });
  // }

  setCummulativeCount(
    oldArr: any = null,
    newArr: any = null,
    tableName: any = null
  ) {
    //   let protestPaymentTax = 0;
    //   let protestPaymentPenalty   = 0;
    //   let protestPaymentInterest = 0;
    //   let protestPaymentTotal = 0;
    //   let liabilityPaymentTax = 0
    //   let liabilityPaymentPenalty = 0;
    //   let liabilityPaymentInterest = 0;
    //   let liabilityPaymentTotal = 0;
    //   let consultantFees = 0;
    //   let preDepositeTax = 0;
    //   let preDepositePenalty = 0;
    //   let preDepositeInterest = 0;
    //   let preDepositeTotal = 0;
    //   let oldTotalTax :any= 0, oldTotalInterrest :any= 0 , oldTotalPenalty:any = 0;
    //   let newTotalTax :any= 0, newTotalInterrest :any= 0 , newTotalPenalty:any = 0;
    //   let oldFees :any=0, newFees:any= 0;
    //   if(this.selectedRemandedBackCaseDetail && this.selectedRemandedBackCaseDetail?.cumulative?.length > 0){
    //     this.selectedRemandedBackCaseDetail.cumulative.forEach((p: any) =>{
    //       if(p.label === 'Cumulative payment made under protest'){
    //         p.tax ? protestPaymentTax += p.tax : null;
    //         p.penalty ? protestPaymentPenalty += p.penalty : null;
    //         p.interest ? protestPaymentInterest += p.interest : null;
    //         p.protestPaymentTotal ? protestPaymentTotal +=p.protestPaymentTotal : null;
    //       }else if(p.label === 'Cumulative payment made for admitted liability'){
    //         p.tax ? liabilityPaymentTax += p.tax : null;
    //         p.penalty ? liabilityPaymentPenalty += p.penalty : null;
    //         p.interest ? liabilityPaymentInterest += p.interest : null;
    //         p.total ? liabilityPaymentTotal +=p.total : null;
    //       }else if(p.label === 'Cumulative Consultant Charges'){
    //         p.fees ? consultantFees += p.fees : null;
    //       }
    //       })
    //   }
    //   if(this.caseData.cumulative && this.caseData.cumulative.length > 0){
    //     this.caseData.cumulative.forEach((p: any) =>{
    //       if(p.label === 'Cumulative Pre-Deposit'){
    //         p.tax ? preDepositeTax += p.tax : null;
    //         p.penalty ? preDepositePenalty += p.penalty : null;
    //         p.interest ? preDepositeInterest += p.interest : null;
    //         p.total ? preDepositeTotal +=p.total : null;
    //       }
    //       if(p.label === 'Cumulative payment made under protest'){
    //         p.tax ? protestPaymentTax += p.tax : null;
    //         p.penalty ? protestPaymentPenalty += p.penalty : null;
    //         p.interest ? protestPaymentInterest += p.interest : null;
    //         p.total ? protestPaymentTotal +=p.total : null;
    //       }else if(p.label === 'Cumulative payment made for admitted liability'){
    //         p.tax ? liabilityPaymentTax += p.tax : null;
    //         p.penalty ? liabilityPaymentPenalty += p.penalty : null;
    //         p.interest ? liabilityPaymentInterest += p.interest : null;
    //         p.total ? liabilityPaymentTotal +=p.total : null;
    //       }else if(p.label === 'Cumulative Consultant Charges'){
    //         p.total ? consultantFees += p.total : null;
    //       }
    //       })
    //    }
    //   //array is not null and empty
    //   if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== 'consultantCharges'){
    //     oldArr.forEach((p : any) =>{
    //       if( p.tax != null)
    //       oldTotalTax +=  p.tax ;
    //       if( p.penalty != null)
    //       oldTotalPenalty += p.penalty;
    //       if( p.interest != null)
    //       oldTotalInterrest += p.interest;
    //     });
    //     newArr.forEach((p : any) =>{
    //       if( p.tax != null)
    //       newTotalTax +=  p.tax ;
    //       if( p.penalty != null)
    //       newTotalPenalty += p.penalty;
    //       if( p.interest != null)
    //       newTotalInterrest += p.interest;
    //     });
    //   }else if(tableName === 'consultantCharges'){
    //     oldArr.forEach((p : any) =>{
    //      if(p.fees != null){
    //        oldFees += p.fees;
    //      }
    //     });
    //     newArr.forEach((p : any) =>{
    //       if(p.fees != null){
    //         newFees += p.fees;
    //       }
    //     })
    //   }
    //   if(oldArr !== null && oldArr.length > 0 && newArr != null && newArr.length >0 && tableName !== null){
    //     this.cumulativeCount.forEach((c: any) =>{
    //       if(c.label === 'Cumulative Pre-Deposit' && tableName === 'depositDetails'){
    //         c.tax = preDepositeTax - oldTotalTax + newTotalTax ;
    //         c.penalty = preDepositePenalty - oldTotalPenalty + newTotalPenalty ;
    //         c.interest = preDepositeInterest - oldTotalInterrest + newTotalInterrest ;
    //         c.total = c.tax + c.penalty + c.interest  ;
    //       }
    //     if(c.label === 'Cumulative payment made under protest' &&  tableName === 'protestDetails'){
    //       c.tax = protestPaymentTax - oldTotalTax + newTotalTax  ;
    //       c.penalty = protestPaymentPenalty - oldTotalPenalty + newTotalPenalty  ;
    //       c.interest = protestPaymentInterest - oldTotalInterrest + newTotalInterrest  ;
    //       c.total = c.tax + c.penalty + c.interest  ;
    //     }
    //     else if(c.label === 'Cumulative payment made for admitted liability' && tableName === 'liabilityDetails'){
    //       c.tax = liabilityPaymentTax - oldTotalTax + newTotalTax  ;
    //       c.penalty = liabilityPaymentPenalty - oldTotalPenalty + newTotalPenalty ;
    //       c.interest = liabilityPaymentInterest - oldTotalInterrest + newTotalInterrest ;
    //       c.total = c.tax + c.penalty + c.interest  ;
    //     }else if(c.label === 'Cumulative Consultant Charges' && tableName === 'consultantCharges'){
    //       c.total = consultantFees - oldFees + newFees  ;
    //     }
    //     });
    //     this.caseData.cumulative = this.cumulativeCount;
    //   }else{
    //     this.cumulativeCount.forEach((c: any) =>{
    //       if(c.label === 'Cumulative Pre-Deposit'){
    //         preDepositeTax ?  c.tax = preDepositeTax  : null;
    //         preDepositePenalty ?  c.penalty = preDepositePenalty  : null;
    //         preDepositeInterest ?  c.interest = preDepositeInterest  : null;
    //         preDepositeTotal ?  c.total = preDepositeTotal  : null;
    //       }
    //       if(c.label === 'Cumulative payment made under protest'){
    //         protestPaymentTax ?  c.tax = protestPaymentTax  : null;
    //         protestPaymentPenalty ?  c.penalty = protestPaymentPenalty  : null;
    //         protestPaymentInterest ?  c.interest = protestPaymentInterest  : null;
    //         protestPaymentTotal ?  c.total = protestPaymentTotal  : null;
    //       }else if(c.label === 'Cumulative payment made for admitted liability'){
    //         liabilityPaymentTax ? c.tax = liabilityPaymentTax  : null;
    //         liabilityPaymentPenalty ? c.penalty = liabilityPaymentPenalty  : null;
    //         liabilityPaymentInterest ? c.interest = liabilityPaymentInterest  : null;
    //         liabilityPaymentTotal ? c.total = liabilityPaymentTotal  : null;
    //       }else if(c.label === 'Cumulative Consultant Charges'){
    //         consultantFees ? c.total = consultantFees  : null;
    //       }
    //     });
    //   }
    //   if(tableName === 'protestDetails') this.paymentUnderProtestp1Changed();
    //   if(tableName === 'depositDetails') this.preDepositeChanged();
    //   if(tableName === 'consultantCharges') this.feesChargedChanged();
    //   if(tableName === 'liabilityDetails') this.liabilityAddmittedAndPaidChanged();
  }

  setForumOfAppeal() {
    if (
      (this.preCommAuthForm.value.whetherAppealPreferred === true ||
        this.preCommAuthForm.value.whetherAppealPreferred === 'true') &&
      (this.preCommAuthForm.value.whetherDptAppealPreferred === false ||
        this.preCommAuthForm.value.whetherDptAppealPreferred === 'false') &&
      (this.preCommAuthForm.value.writAppPreferred === false ||
        this.preCommAuthForm.value.writAppPreferred === 'false')
    ) {
      this.forumAppeal = [
        { key: 'preapt', value: 'Appellate Tribunal' },
        { key: 'prehc', value: 'High Court' },
      ];
      return;
    }
    if (
      (this.preCommAuthForm.value.whetherAppealPreferred === false ||
        this.preCommAuthForm.value.whetherAppealPreferred === 'false') &&
      (this.preCommAuthForm.value.whetherDptAppealPreferred === false ||
        this.preCommAuthForm.value.whetherDptAppealPreferred === 'false') &&
      (this.preCommAuthForm.value.writAppPreferred === false ||
        this.preCommAuthForm.value.writAppPreferred === 'false')
    ) {
      this.forumAppeal = [
        { key: 'Appeal/Writ', value: 'Appeal/Writ not Filed' },
      ];
      return;
    }
    if (
      (this.preCommAuthForm.value.whetherAppealPreferred === false ||
        this.preCommAuthForm.value.whetherAppealPreferred === 'false') &&
      (this.preCommAuthForm.value.whetherDptAppealPreferred === false ||
        this.preCommAuthForm.value.whetherDptAppealPreferred === 'false') &&
      (this.preCommAuthForm.value.writAppPreferred === true ||
        this.preCommAuthForm.value.writAppPreferred === 'true')
    ) {
      this.forumAppeal = [
        { key: 'prehc', value: 'High Court' },
        { key: 'presc', value: 'Supreme Court' },
      ];
      return;
    }
    if (
      (this.preCommAuthForm.value.whetherAppealPreferred === false ||
        this.preCommAuthForm.value.whetherAppealPreferred === 'false') &&
      (this.preCommAuthForm.value.whetherDptAppealPreferred === true ||
        this.preCommAuthForm.value.whetherDptAppealPreferred === 'true') &&
      (this.preCommAuthForm.value.writAppPreferred === false ||
        this.preCommAuthForm.value.writAppPreferred === 'false')
    ) {
      this.forumAppeal = [
        { key: 'preapt', value: 'Appellate Tribunal' },
        { key: 'prehc', value: 'High Court' },
      ];
      return;
    }

    this.forumAppeal = FORUM_APPEAL_COMMISSIONER;
  }

  getFormName() {
    return this.preAdjType.find(
      (type) =>
        type.key === this.preCommAuthForm?.get('remandedBackForum')?.value
    )?.value;
  }

  newRemandedPaymentMade(data: any): UntypedFormGroup {
    return this.fBuild.group({
      label: [data ? data?.label : ''],
      tax: [
        data ? data?.tax : 0,
        [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)],
      ],
      interest: [
        data ? data?.interest : 0,
        [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)],
      ],
      penalty: [
        data ? data?.penalty : 0,
        [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)],
      ],
      total: [
        data ? data?.total : 0,
        [Validators.pattern(/^-?[0-9]{1,18}(\.\d{1,2})?$/)],
      ],
    });
  }

  initializeRemandedBackPaymentData(data: any) {
    data.forEach((cumulative: any) => {
      let detailOfCumulative = this.newRemandedPaymentMade(cumulative);
      (this.preCommAuthForm.get('remandedPaymentMade') as UntypedFormArray).push(
        detailOfCumulative
      );
    });
    console.log(this.preCommAuthForm.get('remandedPaymentMade'));
  }

  getREmandedBackData() {
    return (this.preCommAuthForm.get('remandedPaymentMade') as UntypedFormArray)
      .controls as UntypedFormGroup[];
  }

  remandedBackPaymentValueChange() {
    (
      this.preCommAuthForm.get('remandedPaymentMade') as UntypedFormArray
    ).controls.forEach((c: any) => {
      if (c.value.label !== 'Cumulative Consultant Charges') {
        c.patchValue(
          { total: +c.value.tax + +c.value.interest + +c.value.penalty },
          { emitEvent: false, onlySelf: true }
        );
      } else {
        console.log('test');
        c.patchValue({ total: +c.value.total });
      }
    });
  }

  calculateRemandBackTotal() {
    (
      this.preCommAuthForm.get('remandedPaymentMade') as UntypedFormArray
    ).controls.forEach((c: any) => {
      if (c.value.label !== 'Cumulative Consultant Charges') {
        c.patchValue({
          total: +c.value.tax + +c.value.interest + +c.value.penalty,
        });
      } else {
        c.patchValue({ total: +c.value.total });
      }
    });
  }

  refreshCumulativeTable(event: any) {
    event.stopPropagation();
    let refreshValue = this.preCommAuthForm.value;
    refreshValue['cumulative'] = this.caseData.cumulative;
    refreshValue['prevStage'] = this.prevStage;
    if (refreshValue.protestDetails && refreshValue.protestDetails.length) {
      refreshValue.protestDetails.forEach((protestDetail: any) => {
        protestDetail.tax = this.inrService.removeCommasAndParseNumber(
          protestDetail.tax
        );
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(
          protestDetail.interest
        );
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(
          protestDetail.penalty
        );
        protestDetail.total = this.inrService.removeCommasAndParseNumber(
          protestDetail.total
        );
      });
    }
    if (refreshValue.stayOrder && refreshValue.stayOrder.length) {
      refreshValue.stayOrder.forEach((stayOrder: any) => {
        stayOrder.amount = this.inrService.removeCommasAndParseNumber(
          stayOrder.amount
        );
      });
    }
    if (refreshValue.depositDetails && refreshValue.depositDetails.length) {
      refreshValue.depositDetails.forEach((depositDetails: any) => {
        depositDetails.tax = this.inrService.removeCommasAndParseNumber(
          depositDetails.tax
        );
        depositDetails.interest = this.inrService.removeCommasAndParseNumber(
          depositDetails.interest
        );
        depositDetails.penalty = this.inrService.removeCommasAndParseNumber(
          depositDetails.penalty
        );
        depositDetails.total = this.inrService.removeCommasAndParseNumber(
          depositDetails.total
        );
      });
    }
    if (
      refreshValue.consultantCharges &&
      refreshValue.consultantCharges.length
    ) {
      refreshValue.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees);
      });
    }
    if (refreshValue.liabilityDetails && refreshValue.liabilityDetails.length) {
      refreshValue.liabilityDetails.forEach((liability: any) => {
        liability.tax = this.inrService.removeCommasAndParseNumber(
          liability.tax
        );
        liability.interest = this.inrService.removeCommasAndParseNumber(
          liability.interest
        );
        liability.penalty = this.inrService.removeCommasAndParseNumber(
          liability.penalty
        );
        liability.total = this.inrService.removeCommasAndParseNumber(
          liability.total
        );
      });
    }
    if (refreshValue.issues && refreshValue.issues.length) {
      refreshValue.issues.forEach((issue: any) => {
        issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty1);
        issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(issue.serviceTax1);
        issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1);
        issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1);
        issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1);
        issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1);
        issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1);
        issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1);
        issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1);
        issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1);
        issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1);
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1);
        issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1);
        issue.interest1 = this.inrService.removeCommasAndParseNumber(issue.interest1);
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(issue.penalty1);
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1);
        issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(issue.exciseDuty3);
        issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(issue.serviceTax3);
        issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3);
        issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3);
        issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3);
        issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3);
        issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3);
        issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3);
        issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3);
        issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3);
        issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3);
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3);
        issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3);
        issue.interest3 = this.inrService.removeCommasAndParseNumber(
          issue.interest3
        );
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(
          issue.penalty3
        );
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.total3);
        if (issue.details && issue.details.length) {
          issue.details.forEach((details: any) => {
            details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(details.provisionAmt1)
            details.contLiabilityAmt1 = this.inrService.removeCommasAndParseNumber(details.contLiabilityAmt1)
            details.otherLiability1 = this.inrService.removeCommasAndParseNumber(details.otherLiability1)
            details.caroAmt1 = this.inrService.removeCommasAndParseNumber(details.caroAmt1)
          })
        }

      });
    }


    refreshValue.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.exciseDuty1Total
    );
    refreshValue.serviceTax1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.serviceTax1Total
    );
    refreshValue.ec1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.ec1Total
    );
    refreshValue.shec1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.shec1Total
    );
    refreshValue.kkc1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.kkc1Total
    );
    refreshValue.vat1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.vat1Total
    );
    refreshValue.cst1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.cst1Total
    );
    refreshValue.sbc1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.sbc1Total
    );
    refreshValue.bcd1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.bcd1Total
    );
    refreshValue.sad1Total = this.inrService.removeCommasAndParseNumber(refreshValue.sad1Total);
    refreshValue.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(refreshValue.antiDumpingDuty1Total);
    refreshValue.igst1Total = this.inrService.removeCommasAndParseNumber(refreshValue.igst1Total);
    refreshValue.ent1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.ent1Total
    );
    refreshValue.interest1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.interest1Total
    );
    refreshValue.penalty1Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.penalty1Total
    );
    refreshValue.totOfTotal1 = this.inrService.removeCommasAndParseNumber(
      refreshValue.totOfTotal1
    );

    refreshValue.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.exciseDuty3Total
    );
    refreshValue.serviceTax3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.serviceTax3Total
    );
    refreshValue.ec3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.ec3Total
    );
    refreshValue.shec3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.shec3Total
    );
    refreshValue.kkc3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.kkc3Total
    );
    refreshValue.vat3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.vat3Total
    );
    refreshValue.cst3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.cst3Total
    );
    refreshValue.sbc3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.sbc3Total
    );
    refreshValue.bcd3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.bcd3Total
    );
    refreshValue.sad3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.sad3Total
    );
    refreshValue.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.antiDumpingDuty3Total
    );
    refreshValue.igst3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.igst3Total
    );
    refreshValue.ent3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.ent3Total
    );
    refreshValue.interest3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.interest3Total
    );
    refreshValue.penalty3Total = this.inrService.removeCommasAndParseNumber(
      refreshValue.penalty3Total
    );
    refreshValue.totOfTotal3 = this.inrService.removeCommasAndParseNumber(
      refreshValue.totOfTotal3
    );
    this.litService.getCumulativeCount(this.tabType[1].key, refreshValue).subscribe((response: any) => {
      console.log(response.response);
      this.formatTableValues();
      this.caseData.cumulative = response.response;
      this.cumulativeCount = response.response;
      this.toaster.showSuccess(response.message);
    });
  }

  onPaste(event: any, id: any, tableName: any, index: any, label: any = null) {
    let keyCodeEvent = { keyCode: 8 };
    event.preventDefault();
    let value: any = Math.round(+event.clipboardData.getData('Text'));
    let data = this.preCommAuthForm.get(tableName)?.value;
    if (tableName === 'remandedPaymentMade') {
      this.setRemandBackTableData(label, id, value);
    } else {
      data[index][id] = value;
    }
    this.preCommAuthForm.get(tableName)?.setValue(data);
    this.onKeyDownEvent(keyCodeEvent, tableName);
  }

  setRemandBackTableData(label: any, id: any, value: any) {
    console.log(label);

    (
      this.preCommAuthForm.get('remandedPaymentMade') as UntypedFormArray
    ).controls.forEach((c: any) => {
      if (c.value.label === label) {
        c.value[id] = value;
      }
    });
  }

  formatTableValues() {
    const issues1Array = this.preCommAuthForm.controls.issues as UntypedFormArray;
    const liabilityDetailsArray = this.preCommAuthForm.controls
      .liabilityDetails as UntypedFormArray;
    const protestDetailsArray = this.preCommAuthForm.controls
      .protestDetails as UntypedFormArray;
    const consultantChargesArray = this.preCommAuthForm.controls
      .consultantCharges as UntypedFormArray;
    const preDepositeArray = this.preCommAuthForm.controls
      .depositDetails as UntypedFormArray;

    if (consultantChargesArray && consultantChargesArray.controls.length) {
      for (
        var index = 0;
        index < consultantChargesArray.controls.length;
        index++
      ) {
        const consultantChargesControl = consultantChargesArray.controls[
          index
        ] as UntypedFormGroup;
        consultantChargesControl.controls['fees'].setValue(
          this.inrService.formatCurrency(consultantChargesControl.value.fees)
        );
      }
    }

    if (protestDetailsArray && protestDetailsArray.controls.length) {
      for (
        var index = 0;
        index < protestDetailsArray.controls.length;
        index++
      ) {
        const protestDetailsControl = protestDetailsArray.controls[
          index
        ] as UntypedFormGroup;
        protestDetailsControl.controls['tax'].setValue(
          this.inrService.formatCurrency(protestDetailsControl.value.tax)
        );
        protestDetailsControl.controls['interest'].setValue(
          this.inrService.formatCurrency(protestDetailsControl.value.interest)
        );
        protestDetailsControl.controls['penalty'].setValue(
          this.inrService.formatCurrency(protestDetailsControl.value.penalty)
        );
        protestDetailsControl.controls['total'].setValue(
          this.inrService.formatCurrency(protestDetailsControl.value.total)
        );
      }
    }

    if (preDepositeArray && preDepositeArray.controls.length) {
      for (var index = 0; index < preDepositeArray.controls.length; index++) {
        const preDepositeControl = preDepositeArray.controls[
          index
        ] as UntypedFormGroup;
        preDepositeControl.controls['tax'].setValue(
          this.inrService.formatCurrency(preDepositeControl.value.tax)
        );
        preDepositeControl.controls['interest'].setValue(
          this.inrService.formatCurrency(preDepositeControl.value.interest)
        );
        preDepositeControl.controls['penalty'].setValue(
          this.inrService.formatCurrency(preDepositeControl.value.penalty)
        );
        preDepositeControl.controls['total'].setValue(
          this.inrService.formatCurrency(preDepositeControl.value.total)
        );
      }
    }

    if (liabilityDetailsArray.controls.length > 0) {
      for (
        let index = 0;
        index < liabilityDetailsArray.controls.length;
        index++
      ) {
        const liabilityDetailsControl = liabilityDetailsArray.controls[
          index
        ] as UntypedFormGroup;
        liabilityDetailsControl.controls['tax'].setValue(
          this.inrService.formatCurrency(liabilityDetailsControl.value.tax)
        );
        liabilityDetailsControl.controls['interest'].setValue(
          this.inrService.formatCurrency(liabilityDetailsControl.value.interest)
        );
        liabilityDetailsControl.controls['penalty'].setValue(
          this.inrService.formatCurrency(liabilityDetailsControl.value.penalty)
        );
        liabilityDetailsControl.controls['total'].setValue(
          this.inrService.formatCurrency(liabilityDetailsControl.value.total)
        );
      }
    }

    if (issues1Array.controls.length > 0) {
      for (let i = 0; i < issues1Array.controls.length; i++) {
        const issues1Control = issues1Array.controls[i] as UntypedFormGroup;
        issues1Control.controls['exciseDuty1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.exciseDuty1)
        );
        issues1Control.controls['serviceTax1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.serviceTax1)
        );
        issues1Control.controls['ec1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.ec1)
        );
        issues1Control.controls['shec1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.shec1)
        );
        issues1Control.controls['kkc1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.kkc1)
        );
        issues1Control.controls['sbc1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.sbc1)
        );
        issues1Control.controls['vat1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.vat1)
        );
        issues1Control.controls['cst1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.cst1)
        );
        issues1Control.controls['bcd1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.bcd1)
        );
        issues1Control.controls['sad1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.sad1)
        );
        issues1Control.controls['antiDumpingDuty1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.antiDumpingDuty1)
        );
        issues1Control.controls['igst1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.igst1)
       );
        issues1Control.controls['ent1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.ent1)
        );
        issues1Control.controls['interest1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.interest1)
        );
        issues1Control.controls['penalty1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.penalty1)
        );
        issues1Control.controls['total1'].setValue(
          this.inrService.formatCurrency(issues1Control.value.total1)
        );

        issues1Control.controls['exciseDuty3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.exciseDuty3)
        );
        issues1Control.controls['serviceTax3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.serviceTax3)
        );
        issues1Control.controls['ec3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.ec3)
        );
        issues1Control.controls['shec3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.shec3)
        );
        issues1Control.controls['kkc3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.kkc3)
        );
        issues1Control.controls['sbc3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.sbc3)
        );
        issues1Control.controls['vat3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.vat3)
        );
        issues1Control.controls['cst3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.cst3)
        );
        issues1Control.controls['bcd3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.bcd3)
        );
        issues1Control.controls['sad3'].setValue(this.inrService.formatCurrency(issues1Control.value.sad3));
        issues1Control.controls['antiDumpingDuty3'].setValue(this.inrService.formatCurrency(issues1Control.value.antiDumpingDuty3));
        issues1Control.controls['igst3'].setValue(this.inrService.formatCurrency(issues1Control.value.igst3));
        issues1Control.controls['ent3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.ent3)
        );
        issues1Control.controls['interest3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.interest3)
        );
        issues1Control.controls['penalty3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.penalty3)
        );
        issues1Control.controls['total3'].setValue(
          this.inrService.formatCurrency(issues1Control.value.total3)
        );
      }
    }
  }

  inrFormattingRemoving() {
    const data = this.preCommAuthForm.value;
    //phase 2 form
    if (data.consultantCharges && data.consultantCharges.length) {
      data.consultantCharges.forEach((charge: any) => {
        charge.fees = this.inrService.removeCommasAndParseNumber(charge.fees);
      });
    }
    if (data.bankGuarantee && data.bankGuarantee.length) {
      data.bankGuarantee.forEach((bank: any) => {
        bank.bgAmount = this.inrService.removeCommasAndParseNumber(bank.bgAmount)
        bank.fee = this.inrService.removeCommasAndParseNumber(bank.fee)
      })
    }
    //phase 1 form
    if (data.protestDetails && data.protestDetails.length) {
      data.protestDetails.forEach((protestDetail: any) => {
        protestDetail.tax = this.inrService.removeCommasAndParseNumber(
          protestDetail.tax
        );
        protestDetail.interest = this.inrService.removeCommasAndParseNumber(
          protestDetail.interest
        );
        protestDetail.penalty = this.inrService.removeCommasAndParseNumber(
          protestDetail.penalty
        );
        protestDetail.total = this.inrService.removeCommasAndParseNumber(
          protestDetail.total
        );
      });
    }

    if (data.depositDetails && data.depositDetails.length) {
      data.depositDetails.forEach((depositDetails: any) => {
        depositDetails.tax = this.inrService.removeCommasAndParseNumber(
          depositDetails.tax
        );
        depositDetails.interest = this.inrService.removeCommasAndParseNumber(
          depositDetails.interest
        );
        depositDetails.penalty = this.inrService.removeCommasAndParseNumber(
          depositDetails.penalty
        );
        depositDetails.total = this.inrService.removeCommasAndParseNumber(
          depositDetails.total
        );
      });
    }


    if (data.stayOrder && data.stayOrder.length) {
      data.stayOrder.forEach((stayOrder: any) => {
        stayOrder.amount = this.inrService.removeCommasAndParseNumber(
          stayOrder.amount
        );
      });
    }
    //phase 3 form
    if (data.liabilityDetails && data.liabilityDetails.length) {
      data.liabilityDetails.forEach((liability: any) => {
        liability.tax = this.inrService.removeCommasAndParseNumber(
          liability.tax
        );
        liability.interest = this.inrService.removeCommasAndParseNumber(
          liability.interest
        );
        liability.penalty = this.inrService.removeCommasAndParseNumber(
          liability.penalty
        );
        liability.total = this.inrService.removeCommasAndParseNumber(
          liability.total
        );
      });
    }
    // //phase 1 and 3 form
    if (data.issues && data.issues.length) {
      data.issues.forEach((issue: any) => {
        issue.exciseDuty1 = this.inrService.removeCommasAndParseNumber(
          issue.exciseDuty1
        );
        issue.serviceTax1 = this.inrService.removeCommasAndParseNumber(
          issue.serviceTax1
        );
        issue.ec1 = this.inrService.removeCommasAndParseNumber(issue.ec1);
        issue.shec1 = this.inrService.removeCommasAndParseNumber(issue.shec1);
        issue.kkc1 = this.inrService.removeCommasAndParseNumber(issue.kkc1);
        issue.vat1 = this.inrService.removeCommasAndParseNumber(issue.vat1);
        issue.cst1 = this.inrService.removeCommasAndParseNumber(issue.cst1);
        issue.sbc1 = this.inrService.removeCommasAndParseNumber(issue.sbc1);
        issue.bcd1 = this.inrService.removeCommasAndParseNumber(issue.bcd1);
        issue.sad1 = this.inrService.removeCommasAndParseNumber(issue.sad1);
        issue.antiDumpingDuty1 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty1);
        issue.igst1 = this.inrService.removeCommasAndParseNumber(issue.igst1);
        issue.ent1 = this.inrService.removeCommasAndParseNumber(issue.ent1);
        issue.interest1 = this.inrService.removeCommasAndParseNumber(
          issue.interest1
        );
        issue.penalty1 = this.inrService.removeCommasAndParseNumber(
          issue.penalty1
        );
        issue.total1 = this.inrService.removeCommasAndParseNumber(issue.total1);

        issue.exciseDuty3 = this.inrService.removeCommasAndParseNumber(
          issue.exciseDuty3
        );
        issue.serviceTax3 = this.inrService.removeCommasAndParseNumber(
          issue.serviceTax3
        );
        issue.ec3 = this.inrService.removeCommasAndParseNumber(issue.ec3);
        issue.shec3 = this.inrService.removeCommasAndParseNumber(issue.shec3);
        issue.kkc3 = this.inrService.removeCommasAndParseNumber(issue.kkc3);
        issue.vat3 = this.inrService.removeCommasAndParseNumber(issue.vat3);
        issue.cst3 = this.inrService.removeCommasAndParseNumber(issue.cst3);
        issue.sbc3 = this.inrService.removeCommasAndParseNumber(issue.sbc3);
        issue.bcd3 = this.inrService.removeCommasAndParseNumber(issue.bcd3);
        issue.sad3 = this.inrService.removeCommasAndParseNumber(issue.sad3);
        issue.antiDumpingDuty3 = this.inrService.removeCommasAndParseNumber(issue.antiDumpingDuty3);
        issue.igst3 = this.inrService.removeCommasAndParseNumber(issue.igst3);
        issue.ent3 = this.inrService.removeCommasAndParseNumber(issue.ent3);
        issue.interest3 = this.inrService.removeCommasAndParseNumber(issue.interest3);
        issue.penalty3 = this.inrService.removeCommasAndParseNumber(issue.penalty3);
        issue.total3 = this.inrService.removeCommasAndParseNumber(issue.totlal3);

        if (issue.details && issue.details.length) {
          issue.details.forEach((details: any) => {
            details.provisionAmt1 = this.inrService.removeCommasAndParseNumber(
              details.provisionAmt1
            );
            details.contLiabilityAmt1 =
              this.inrService.removeCommasAndParseNumber(
                details.contLiabilityAmt1
              );
            details.otherLiability1 =
              this.inrService.removeCommasAndParseNumber(
                details.otherLiability1
              );
            details.caroAmt1 = this.inrService.removeCommasAndParseNumber(
              details.caroAmt1
            );
          });
        }
      });
    }
    // //phase 1 and 3 form
    data.exciseDuty1Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty1Total);
    data.serviceTax1Total = this.inrService.removeCommasAndParseNumber(data.serviceTax1Total);
    data.ec1Total = this.inrService.removeCommasAndParseNumber(data.ec1Total);
    data.bcd1Total = this.inrService.removeCommasAndParseNumber(data.bcd1Total);
    data.sad1Total = this.inrService.removeCommasAndParseNumber(data.sad1Total);
    data.antiDumpingDuty1Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty1Total);
    data.igst1Total = this.inrService.removeCommasAndParseNumber(data.igst1Total);
    data.shec1Total = this.inrService.removeCommasAndParseNumber(data.shec1Total);
    data.kkc1Total = this.inrService.removeCommasAndParseNumber(data.kkc1Total);
    data.vat1Total = this.inrService.removeCommasAndParseNumber(data.vat1Total);
    data.cst1Total = this.inrService.removeCommasAndParseNumber(data.cst1Total);
    data.sbc1Total = this.inrService.removeCommasAndParseNumber(data.sbc1Total);
    data.ent1Total = this.inrService.removeCommasAndParseNumber(data.ent1Total);
    data.interest1Total = this.inrService.removeCommasAndParseNumber(data.interest1Total);
    data.penalty1Total = this.inrService.removeCommasAndParseNumber(data.penalty1Total);
    data.totOfTotal1 = this.inrService.removeCommasAndParseNumber(data.totOfTotal1);

    data.exciseDuty3Total = this.inrService.removeCommasAndParseNumber(data.exciseDuty3Total);
    data.serviceTax3Total = this.inrService.removeCommasAndParseNumber(data.serviceTax3Total);
    data.ec3Total = this.inrService.removeCommasAndParseNumber(data.ec3Total);
    data.bcd3Total = this.inrService.removeCommasAndParseNumber(data.bcd3Total)
    data.sad3Total = this.inrService.removeCommasAndParseNumber(data.sad3Total)
    data.antiDumpingDuty3Total = this.inrService.removeCommasAndParseNumber(data.antiDumpingDuty3Total)
    data.igst3Total = this.inrService.removeCommasAndParseNumber(data.igst3Total)
    data.shec3Total = this.inrService.removeCommasAndParseNumber(data.shec3Total);
    data.kkc3Total = this.inrService.removeCommasAndParseNumber(data.kkc3Total);
    data.vat3Total = this.inrService.removeCommasAndParseNumber(data.vat3Total);
    data.cst3Total = this.inrService.removeCommasAndParseNumber(data.cst3Total);
    data.sbc3Total = this.inrService.removeCommasAndParseNumber(data.sbc3Total);
    data.ent3Total = this.inrService.removeCommasAndParseNumber(data.ent3Total);
    data.interest3Total = this.inrService.removeCommasAndParseNumber(data.interest3Total);
    data.penalty3Total = this.inrService.removeCommasAndParseNumber(data.penalty3Total);
    data.totOfTotal3 = this.inrService.removeCommasAndParseNumber(data.totOfTotal3);
  }


  deleteCopyOfOioLoc() {
    if (this.preCommAuthForm.get('prevOrderLoc')?.value) {
      this.preCommAuthForm.get('prevOrderLoc')?.setValue(null);
      this.caseData['prevOrderLoc'] = null;
      this.isnoticeLocFile = false;
      this.isnoticeLocText = true;
      this.isDWnoticeLoc = true;
      this.isUPnoticeLoc = true;
    }
  }

  deleteOiaLoc() {
    if (this.preCommAuthForm.get('oiaLoc')?.value) {
      this.preCommAuthForm.get('oiaLoc')?.setValue(null);
      this.caseData['oiaLoc'] = null;
      this.isoiaLocFile = false;
      this.isoiaLocText = true;
      this.isDWoiaLoc = true;
      this.isUPoiaLoc = true;
    }
  }

  //  Copy-paste function
  handlePaste(event: any) {
    this.inrService.handlePaste(event);
  }
  selectedIssues:any
  getissueData(content: any, i:any){
    this.issueReferenceRef = this.modalService.open(content, { windowClass: 'issues-pop' });
    this.selectedIssues = this.preCommAuthForm.value.issues[i].issue;
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "updatedOn",
    model.sortdir = "ASC",
    model.companyId = "7437",
    model.module = "PREGST",
     model.role = "issueRef",
    model.criterias= [
      {
          "p": "issues.issue",
          "o": "eq",
          "v": this.selectedIssues,
      }
  ]
  console.log(this.selectedIssues,"this.preCommAuthForm.value.issue");
  this.litService.getIssueRefData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
    if (response.status === 1) {
      this.issueData = response?.response;
      if(this.issueData.length === 0){
          this.hideData =  false
      }else{
        this.hideData =  true
      }
    }      
    });
  }
  exportAsXLSX() {
    if (this.issueData.length === 0) {
      this.toaster.showError('No data to export.');
      return;
    }
    const data= this.issueData.map((source: any) => ({
      'PAN Name': source.panName,
      'Case id': source.caseId,
      'Case Label': source.caseLabel,
      'State': source.state,
      'ACT': source.actyType,
      'Registration number': source.regNo,
      'Period From': source.periodFromDate,
      'Period to': source.periodToDate,
      'Person Responsible': source.personResponsible,

    }));
    this.excelService.exportAsExcelFile(data, 'Issue Reference');
  }
  SortingIssue(column: string) {
    switch (column) {
      case SORT_ISSUE.CASE_ID: {
        if (!this.sorting.caseId) {
          this.sorting.caseId = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'asc'
          );
        } else {
          this.sorting.caseId = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseId',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.STATE: {
        if (!this.sorting.state) {
          this.sorting.state = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'asc'
          );
        } else {
          this.sorting.state = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'state',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PAN: {
        if (!this.sorting.panName) {
          this.sorting.panName = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actType',
            'asc'
          );
        } else {
          this.sorting.panName = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actType',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.CASE_LABEL: {
        if (!this.sorting.caseLabel) {
          this.sorting.caseLabel = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'asc'
          );
        } else {
          this.sorting.caseLabel = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'caseLabel',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERIOD_FROM: {
        if (!this.sorting.periodFromDate) {
          this.sorting.periodFromDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'asc'
          );
        } else {
          this.sorting.periodFromDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodFromDate',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.ACT: {
        if (!this.sorting.actyType) {
          this.sorting.actyType = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actyType',
            'asc'
          );
        } else {
          this.sorting.actyType = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'actyType',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.RGENO: {
        if (!this.sorting.regNo) {
          this.sorting.regNo = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'regNo',
            'asc'
          );
        } else {
          this.sorting.regNo = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'regNo',
            'desc'
          );
        }
        break;
      }

      case SORT_ISSUE.PERIOD_TO: {
        if (!this.sorting.periodToDate) {
          this.sorting.periodToDate = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'asc'
          );
        } else {
          this.sorting.periodToDate = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,
            'periodToDate',
            'desc'
          );
        }
        break;
      }
      case SORT_ISSUE.PERSON_RESPONSIBLE: {
        if (!this.sorting.personResponsible) {
          this.sorting.personResponsible = true;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,'personResponsible','asc');
        } else {
          this.sorting.personResponsible = false;
          this.issueData = this.commonServ.sortObjectsByKey(
            this.issueData,'personResponsible', 'desc' );
        }
        break;
      }
    }
  }
  getperiodToValue(content: any){
    if(this.action === "create"){
    let model: any = {};
    model.page = 0,
    model.size = 10,
    model.sortfield = "createdOn",
    model.sortdir = "DESC",
    model.companyId = localStorage.getItem('selectedId'),
    model.module = "PREGST",
    model.criterias= [
      {
          "p": "periodFromDate",
          "o": "between",
          "v1": this.preCommAuthForm.value.periodFromDate,
          "v2":  this.preCommAuthForm.value.periodToDate,
      },{
          "p": "periodToDate",
          "o": "between",
          "v1": this.preCommAuthForm.value.periodFromDate,
          "v2": this.preCommAuthForm.value.periodToDate,
      },
      {
        "p" : "actType",
        "o" : "in",
        "v" :  this.selectedActType,
    }
  ]
  this.litService.getDuplicateData(model).subscribe((response: any) => {
    console.log(response.response.length,"response.length");
      if (response.response.length === 0) {
        this.modalService.dismissAll();
      }
      else{
        this.duplicateData = response?.response;
         this.duplicateData?.forEach((obj: any, index: number) => {
          if (obj.forum === 'Appellate Authority')
            this.duplicateData[index].forum = 'Commissioner (A)'
        })
       this.modalService.open(content)
      }
      
    });
  }
  }
  
  goToSummary(){
    this.router.navigate(['/preGstSummary']);
    this.modalService.dismissAll();
  }

  getCompanyTags(regNo : any) {
    const regNumber = regNo; 
    this.masterService.getpregstEntityTagsCase(regNumber).subscribe((response: any) => {
      if (response.status === 1) {
        const allTags = response.response.data;
        
        this.tagDataList = allTags
          .filter((tagItem: any) => tagItem.preGstReg === regNumber)
          .flatMap((tagItem: any) =>
            tagItem.tags
              .filter((tag: any) => tag.active) 
              .map((tag: any) => tag.tag)
          );
  
      } else {        
        this.toaster.showSuccess(response.message);
      }
    });
  }
  openIntCalPop(content: any) {
    this.modalService.open(content, { windowClass: 'preanalytics-pop' });
    this.initIntCalPopData();
  }
  closeIntCalPop(){
    this.initializeData();
    this.modalService.dismissAll();
  }
  initIntCalPopData() {
    let model = {
           type: 'apa',
           phase: this.selectedTab == 'tab1'? 'PHASE-1' : 'PHASE-3',
           caseId : this.caseId,
           module : 'PREGST',
           companyId: localStorage.getItem('selectedId'),
         };
       this.litService.getIntCalPopData(model).subscribe((response: any) => {
       if (response.status === 1) {
          this.intCalPopData = response.response.issueDetails;
          if(this.intCalPopData == null) return;
          const keys = Object.keys(this.intCalPopData);
          this.intCalIssueData = [];
          keys.forEach((key: any) => {
            const valuesArray  = this.intCalPopData[key];    
            valuesArray.forEach((values: any) => {
              this.intCalIssueData.push({
                issue: values.issue,
                tag: values.tag,
                periodFromDate: this.selectedTab == 'tab1'? values.periodFromDate1 : values.periodFromDate3,
                periodToDate: this.selectedTab == 'tab1'? values.dateOfOrderRecieved ? values.dateOfOrderRecieved : values.periodToDate1 : values.periodToDate3,
                interestRate: this.selectedTab == 'tab1'? values.interestRate1 : values.interestRate3,
                totalTax: values.totalTax,
                refId: values.refId,
                igst1: values.igst1,
                cgst1: values.cgst1,
                sgst1: values.sgst1,
                cess1: values.cess1,
                igst3: values.igst3,
                cgst3: values.cgst3,
                sgst3: values.sgst3,
                cess3: values.cess3,
                ec1: values.ec1,
                shec1: values.shec1,
                serviceTax1: values.serviceTax1,
                kkc1: values.kkc1,
                sbc1: values.sbc1,
                vat1: values.vat1,
                cst1: values.cst1,
                bcd1: values.bcd1,
                sad1: values.sad1,
                antiDumpingDuty1: values.antiDumpingDuty1,
                ent1: values.ent1,
                total1: values.total1,
                exciseDuty1: values.exciseDuty1,
                exciseDuty3: values.exciseDuty3,
                ec3: values.ec3,
                shec3: values.shec3,
                serviceTax3: values.serviceTax3,
                kkc3: values.kkc3,
                sbc3: values.sbc3,
                vat3: values.vat3,
                cst3: values.cst3,
                bcd3: values.bcd3,
                sad3: values.sad3,
                antiDumpingDuty3: values.antiDumpingDuty3,
                ent3: values.cess3,
                total3: values.cess3,
                userEnteredInterest: this.selectedTab == 'tab1'? this.inrService.formatCurrency(String(values.userEnteredInterest1)) : this.inrService.formatCurrency(String(values.userEnteredInterest3)),
                interest: this.selectedTab == 'tab1'? values.interest1 : values.interest3,
                calculatedInterest: this.selectedTab == 'tab1'? values.calculatedInterest1 : values.calculatedInterest3,
                taxAdjustedAgainstIssue: values.taxAdjustedAgainstIssue,   
                paymentDetails: values.paymentDetails,                 
                expanded: false,              
                periodFromDateP1: values.periodFromDate1,
                periodToDateP1: values.periodToDate1,
                interestRateP1:values.interestRate1,
                userEnteredInterestP1:values.userEnteredInterest1,
                interestP1:values.interest1,
                calculatedInterestP1:values.calculatedInterest1,
              });
            });        
            
          })
       }       
       });
   }
   doIntCalData(){
    const payload = {
      type: 'apa',
      phase: this.selectedTab === "tab1" ? "PHASE-1" : "PHASE-3",
      caseId: this.caseId,
      module : 'PREGST',
      companyId: localStorage.getItem('selectedId'),
      preGstIssues: [],
      protestDetails: [] as PaymentDetails[],
      predepositDetails: [] as PaymentDetails[],
      admittedLiabDetails: [] as PaymentDetails[]
  };
  if(this.selectedTab === "tab1"){
  payload.preGstIssues = this.intCalIssueData.map((issue:any) => ({
    issue: issue.issue,
    tag: issue.tag,
    interestRate1: issue.interestRate ? parseFloat(issue.interestRate.toString().replace(/,/g, ''))  : 0,
    periodFromDate1: issue.periodFromDate, 
    periodToDate1: issue.periodToDate, 
    igst1: issue.igst1,
    cgst1: issue.cgst1,
    sgst1: issue.sgst1,
    cess1: issue.cess1,
    igst3: issue.igst3,
    cgst3: issue.cgst3,
    sgst3: issue.sgst3,
    cess3: issue.cess3,
    ec1: issue.ec1,
    shec1: issue.shec1,
    serviceTax1: issue.serviceTax1,
    kkc1: issue.kkc1,
    sbc1: issue.sbc1,
    vat1: issue.vat1,
    cst1: issue.cst1,
    bcd1: issue.bcd1,
    sad1: issue.sad1,
    antiDumpingDuty1: issue.antiDumpingDuty1,
    ent1: issue.ent1,
    total1: issue.total1,
    exciseDuty1: issue.exciseDuty1,
    exciseDuty3: issue.exciseDuty3,
    ec3: issue.ec3,
    shec3: issue.shec3,
    serviceTax3: issue.serviceTax3,
    kkc3: issue.kkc3,
    sbc3: issue.sbc3,
    vat3: issue.vat3,
    cst3: issue.cst3,
    bcd3: issue.bcd3,
    sad3: issue.sad3,
    antiDumpingDuty3: issue.antiDumpingDuty3,
    ent3: issue.cess3,
    total3: issue.cess3,
    userEnteredInterest1: issue.userEnteredInterest ? parseFloat(issue.userEnteredInterest.toString().replace(/,/g, ''))  : 0,
    interest1: issue.interest,
    calculatedInterest1: issue.calculatedInterest,
    taxAdjustedAgainstIssue: issue.taxAdjustedAgainstIssue,   
  }));
}else{
  payload.preGstIssues = this.intCalIssueData.map((issue:any) => ({
    issue: issue.issue,
    tag: issue.tag,    
    interestRate3: issue.interestRate ? parseFloat(issue.interestRate.toString().replace(/,/g, ''))  : 0,
    periodFromDate3: issue.periodFromDate, 
    periodToDate3: issue.periodToDate, 
    igst1: issue.igst1,
    cgst1: issue.cgst1,
    sgst1: issue.sgst1,
    cess1: issue.cess1,
    igst3: issue.igst3,
    cgst3: issue.cgst3,
    sgst3: issue.sgst3,
    cess3: issue.cess3,
    ec1: issue.ec1,
    shec1: issue.shec1,
    serviceTax1: issue.serviceTax1,
    kkc1: issue.kkc1,
    sbc1: issue.sbc1,
    vat1: issue.vat1,
    cst1: issue.cst1,
    bcd1: issue.bcd1,
    sad1: issue.sad1,
    antiDumpingDuty1: issue.antiDumpingDuty1,
    ent1: issue.ent1,
    total1: issue.total1,
    exciseDuty1: issue.exciseDuty1,
    exciseDuty3: issue.exciseDuty3,
    ec3: issue.ec3,
    shec3: issue.shec3,
    serviceTax3: issue.serviceTax3,
    kkc3: issue.kkc3,
    sbc3: issue.sbc3,
    vat3: issue.vat3,
    cst3: issue.cst3,
    bcd3: issue.bcd3,
    sad3: issue.sad3,
    antiDumpingDuty3: issue.antiDumpingDuty3,
    ent3: issue.cess3,
    total3: issue.cess3,
    userEnteredInterest3: issue.userEnteredInterest ? parseFloat(issue.userEnteredInterest.toString().replace(/,/g, ''))  : 0,
    interest3: issue.interest,
    calculatedInterest3: issue.calculatedInterest,
    taxAdjustedAgainstIssue: issue.taxAdjustedAgainstIssue, 
    periodFromDate1: issue.periodFromDateP1,
    periodToDate1: issue.periodToDate1,
    interestRate1:issue.interestRateP1,
    userEnteredInterest1:issue.userEnteredInterestP1,
    interest1:issue.interestP1,
    calculatedInterest1:issue.calculatedInterestP1,  
  }));
}
  this.intCalIssueData.forEach((issue:any) => {
    issue.paymentDetails.forEach((payment:any) => {
      const baseDetails = {
        issueName: issue.issue,
        tag: payment.tag,
        stage: payment.stage,
        challanNo:payment.challanNo,
        tax:payment.tax,
        totalTax:payment.totalTax,
        dateOfPayment: payment.dateOfPayment,
        type:payment.type,
        markedForPayment: payment.markedForPayment
      };
      
      if (payment.type === 'PROTEST_DETAILS') {
        payload.protestDetails.push(baseDetails);
      } else if (payment.type === 'PREDEPOSIT_DETAILS') {
        payload.predepositDetails.push(baseDetails);
      } else if (payment.type === 'ADMITTED_LIABILITY') {
        payload.admittedLiabDetails.push(baseDetails);
      }
    });
  });

    this.litService.intCalPopData(payload).subscribe((response: any) => {
      if (response.status === 1) {
        this.intCalPopData = response.response.issueDetails;
        if(this.intCalPopData == null) return;
        const keys = Object.keys(this.intCalPopData);
        this.intCalIssueData = [];
        keys.forEach((key: any) => {
          const valuesArray  = this.intCalPopData[key];    
          valuesArray.forEach((values: any) => {
            this.intCalIssueData.push({
              issue: values.issue,
              tag: values.tag,
              periodFromDate: this.selectedTab == 'tab1'? values.periodFromDate1 : values.periodFromDate3,
              periodToDate: this.selectedTab == 'tab1'? values.dateOfOrderRecieved ? values.dateOfOrderRecieved : values.periodToDate1 : values.periodToDate3,
              interestRate: this.selectedTab == 'tab1'? values.interestRate1 : values.interestRate3,
              totalTax: values.totalTax,
              refId: values.refId,
              igst1: values.igst1,
              cgst1: values.cgst1,
              sgst1: values.sgst1,
              cess1: values.cess1,
              igst3: values.igst3,
              cgst3: values.cgst3,
              sgst3: values.sgst3,
              cess3: values.cess3,
              ec1: values.ec1,
              shec1: values.shec1,
              serviceTax1: values.serviceTax1,
              kkc1: values.kkc1,
              sbc1: values.sbc1,
              vat1: values.vat1,
              cst1: values.cst1,
              bcd1: values.bcd1,
              sad1: values.sad1,
              antiDumpingDuty1: values.antiDumpingDuty1,
              ent1: values.ent1,
              total1: values.total1,
              exciseDuty1: values.exciseDuty1,
              exciseDuty3: values.exciseDuty3,
              ec3: values.ec3,
              shec3: values.shec3,
              serviceTax3: values.serviceTax3,
              kkc3: values.kkc3,
              sbc3: values.sbc3,
              vat3: values.vat3,
              cst3: values.cst3,
              bcd3: values.bcd3,
              sad3: values.sad3,
              antiDumpingDuty3: values.antiDumpingDuty3,
              ent3: values.cess3,
              total3: values.cess3,
              userEnteredInterest: this.selectedTab == 'tab1'? this.inrService.formatCurrency(String(values.userEnteredInterest1)) : this.inrService.formatCurrency(String(values.userEnteredInterest3)),
              interest: this.selectedTab == 'tab1'? values.interest1 : values.interest3,
              calculatedInterest: this.selectedTab == 'tab1'? values.calculatedInterest1 : values.calculatedInterest3,
              taxAdjustedAgainstIssue: values.taxAdjustedAgainstIssue,   
              paymentDetails: values.paymentDetails,                 
              expanded: false,              
              periodFromDateP1: values.periodFromDate1,
              periodToDateP1: values.periodToDate1,
              interestRateP1:values.interestRate1,
              userEnteredInterestP1:values.userEnteredInterest1,
              interestP1:values.interest1,
              calculatedInterestP1:values.calculatedInterest1,
            });
          });        
          
        })
     }     else{
        this.toaster.showError(response.message);
      }
    });
   }
   saveIntCalData(){
    const payload = {
      type: 'apa',
      phase: this.selectedTab === "tab1" ? "PHASE-1" : "PHASE-3",
      caseId: this.caseId,
      module : 'PREGST',
      companyId: localStorage.getItem('selectedId'),
      preGstIssues: [],
      protestDetails: [] as PaymentDetails[],
      predepositDetails: [] as PaymentDetails[],
      admittedLiabDetails: [] as PaymentDetails[]
  };
  if(this.selectedTab === "tab1"){
  payload.preGstIssues = this.intCalIssueData.map((issue:any) => ({
    issue: issue.issue,
    tag: issue.tag,
    interestRate1: issue.interestRate ? parseFloat(issue.interestRate.toString().replace(/,/g, ''))  : 0,
    periodFromDate1: issue.periodFromDate, 
    periodToDate1: issue.periodToDate, 
    igst1: issue.igst1,
    cgst1: issue.cgst1,
    sgst1: issue.sgst1,
    cess1: issue.cess1,
    igst3: issue.igst3,
    cgst3: issue.cgst3,
    sgst3: issue.sgst3,
    cess3: issue.cess3,
    ec1: issue.ec1,
    shec1: issue.shec1,
    serviceTax1: issue.serviceTax1,
    kkc1: issue.kkc1,
    sbc1: issue.sbc1,
    vat1: issue.vat1,
    cst1: issue.cst1,
    bcd1: issue.bcd1,
    sad1: issue.sad1,
    antiDumpingDuty1: issue.antiDumpingDuty1,
    ent1: issue.ent1,
    total1: issue.total1,
    exciseDuty1: issue.exciseDuty1,
    exciseDuty3: issue.exciseDuty3,
    ec3: issue.ec3,
    shec3: issue.shec3,
    serviceTax3: issue.serviceTax3,
    kkc3: issue.kkc3,
    sbc3: issue.sbc3,
    vat3: issue.vat3,
    cst3: issue.cst3,
    bcd3: issue.bcd3,
    sad3: issue.sad3,
    antiDumpingDuty3: issue.antiDumpingDuty3,
    ent3: issue.cess3,
    total3: issue.cess3,
    userEnteredInterest1: issue.userEnteredInterest ? parseFloat(issue.userEnteredInterest.toString().replace(/,/g, ''))  : 0,
    interest1: issue.interest,
    calculatedInterest1: issue.calculatedInterest,
    taxAdjustedAgainstIssue: issue.taxAdjustedAgainstIssue,   
  }));
}else{
  payload.preGstIssues = this.intCalIssueData.map((issue:any) => ({
    issue: issue.issue,
    tag: issue.tag,    
    interestRate3: issue.interestRate ? parseFloat(issue.interestRate.toString().replace(/,/g, ''))  : 0,
    periodFromDate3: issue.periodFromDate, 
    periodToDate3: issue.periodToDate, 
    igst1: issue.igst1,
    cgst1: issue.cgst1,
    sgst1: issue.sgst1,
    cess1: issue.cess1,
    igst3: issue.igst3,
    cgst3: issue.cgst3,
    sgst3: issue.sgst3,
    cess3: issue.cess3,
    ec1: issue.ec1,
    shec1: issue.shec1,
    serviceTax1: issue.serviceTax1,
    kkc1: issue.kkc1,
    sbc1: issue.sbc1,
    vat1: issue.vat1,
    cst1: issue.cst1,
    bcd1: issue.bcd1,
    sad1: issue.sad1,
    antiDumpingDuty1: issue.antiDumpingDuty1,
    ent1: issue.ent1,
    total1: issue.total1,
    exciseDuty1: issue.exciseDuty1,
    exciseDuty3: issue.exciseDuty3,
    ec3: issue.ec3,
    shec3: issue.shec3,
    serviceTax3: issue.serviceTax3,
    kkc3: issue.kkc3,
    sbc3: issue.sbc3,
    vat3: issue.vat3,
    cst3: issue.cst3,
    bcd3: issue.bcd3,
    sad3: issue.sad3,
    antiDumpingDuty3: issue.antiDumpingDuty3,
    ent3: issue.cess3,
    total3: issue.cess3,
    userEnteredInterest3: issue.userEnteredInterest ? parseFloat(issue.userEnteredInterest.toString().replace(/,/g, ''))  : 0,
    interest3: issue.interest,
    calculatedInterest3: issue.calculatedInterest,
    taxAdjustedAgainstIssue: issue.taxAdjustedAgainstIssue, 
    periodFromDate1: issue.periodFromDateP1,
    periodToDate1: issue.periodToDate1,
    interestRate1:issue.interestRateP1,
    userEnteredInterest1:issue.userEnteredInterestP1,
    interest1:issue.interestP1,
    calculatedInterest1:issue.calculatedInterestP1,  
  }));
}
  this.intCalIssueData.forEach((issue:any) => {
    issue.paymentDetails.forEach((payment:any) => {
      const baseDetails = {
        issueName: issue.issue,
        tag: payment.tag,
        stage: payment.stage,
        challanNo:payment.challanNo,
        tax:payment.tax,
        totalTax:payment.totalTax,
        dateOfPayment: payment.dateOfPayment,
        type:payment.type,
        markedForPayment: payment.markedForPayment
      };
      
      if (payment.type === 'PROTEST_DETAILS') {
        payload.protestDetails.push(baseDetails);
      } else if (payment.type === 'PREDEPOSIT_DETAILS') {
        payload.predepositDetails.push(baseDetails);
      } else if (payment.type === 'ADMITTED_LIABILITY') {
        payload.admittedLiabDetails.push(baseDetails);
      }
    });
  });

    this.litService.saveIntCalPopData(payload).subscribe((response: any) => {
      if (response.status === 1) {
        this.toaster.showSuccess(response.message);
        this.initIntCalPopData();
      }else{
        this.toaster.showError(response.message);
      }
    });
   }
}
