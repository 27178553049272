import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';
import { TaxServiceService } from '../../service/tax-service.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';

@Component({
  selector: 'app-attachments-table',
  templateUrl: './attachments-table.component.html',
  styleUrls: ['./attachments-table.component.css']
})
export class AttachmentsTableComponent implements OnInit {
  @Input() tableData: any[] = [];
  @Input() module: string = ''
  @Output() rowEdited: EventEmitter<UntypedFormGroup> = new EventEmitter<UntypedFormGroup>();
  navContext = { entityName: '', isTaxPayer: false, entityType: '', pan: '', gstin: '' };
  selectedField: any;
  selectedFile: any;
  matches: any;
  fileUrl: any;
  caseId: any;
  gstinOrPan: any;
  selectedFileName: any;
  formData: FormData = new FormData();
  attachmentFormGrp!: UntypedFormGroup;
  rmvDetailModalRef!: NgbModalRef;
  isSubmitted: boolean = false;

  constructor(
    private fBuild: UntypedFormBuilder,
    private toaster: ToasterService,
    private taxService: TaxServiceService,
    private CommonService: CommonService,
    private http: HttpClient,
    private modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.navContext = this.CommonService.getNavContext();
    this.formInitialize();
    if (this.tableData) {
      this.fetchData(this.tableData)
    }
  }

  formInitialize() {
    this.attachmentFormGrp = this.fBuild.group({
      attachmentList: this.fBuild.array([]),
    });
  }
  get formGrp() { return this.attachmentFormGrp.controls; }
  fetchData(attachmentData: any[]): void {
    if (attachmentData.length > 0) {
      const attachmentListFormArray = this.attachmentFormGrp.get('attachmentList') as UntypedFormArray;
      for (const data of attachmentData) {
        const attachmentFormGroup = this.fBuild.group({
          fileLoc: [data.fileLoc || ''],
          fileName: [data.fileName || ''],
          remarks: [data.remarks || ''],
        });
        attachmentListFormArray.push(attachmentFormGroup);
      }
    }
  }
  onFileSelect(event: any) {
    this.selectedField = event.target.getAttribute('id')
    if (event.target.files[0] !== undefined) {
      if (this.checkExtensionFunction(event.target.files[0], ['csv', 'zip', 'pdf', 'xlsx', 'rar', 'doc', 'docx', 'pptx', 'xlsb', 'png', 'jpg', 'jpeg', 'msg'])) {
        const originalFile = event.target.files[0];
        const modifiedFilename = originalFile.name.replace(/(\.[\w\d_-]+)$/i, '').replace('&', 'And');
        const fileExtension = originalFile.name.split('.').pop();
        const currentDate = new Date();
        const formattedDate = currentDate.getFullYear().toString() +
        (currentDate.getMonth() + 1).toString().padStart(2, '0') +
        currentDate.getDate().toString().padStart(2, '0') + '_' +
        currentDate.getHours().toString().padStart(2, '0') +
        currentDate.getMinutes().toString().padStart(2, '0') +
        currentDate.getSeconds().toString().padStart(2, '0');
        const newFilename = `${modifiedFilename}_${formattedDate}.${fileExtension}`;
        this.selectedFile = new File([originalFile], newFilename, { type: originalFile.type });
        var regExp = /\(([^)]+)\)/;
        this.matches = regExp.exec(event.target.id);
        this.onUpload(this.selectedField);
      } else {
        this.selectedFile = null;
        this.toaster.showError('This file type is not supported')
      }
    } else {
      this.toaster.showError('File is not selected');
    }
  }
  checkExtensionFunction(selectedFile: { name: string; }, extensionAllowedArray: any[]) {
    let checkExtensions = false;
    const extensionArray = selectedFile.name.split('.');
    const extension = extensionArray[extensionArray.length - 1].toLowerCase();
    extensionAllowedArray.forEach((element: any) => {
      if (element == extension) {
        checkExtensions = true;
      }
    });
    return checkExtensions;
  }
  onUpload(selectedfield: any) {
    let urlData = {
      gstinOrPan: this.navContext.pan,
      type: this.module,
      field: selectedfield,
      contentType: this.selectedFile.type
    }
    this.taxService.uploadFile(this.selectedFile, urlData).subscribe((response: any) => {
      if (response != null) {
        this.getFileUrl(response.response.url, response.response.path, selectedfield);
      }
    })
  }
  getFileUrl(fileUrl: any, path: any, selectedfield: any) {
    this.http.put(fileUrl, this.selectedFile).subscribe((response: any) => {
      if (response != null) {
        if (selectedfield) {
          var toupdate = this.attachmentFormGrp.value;
          let pathToVeriabl = this.createPath(selectedfield);
          for (let pathIndex = 0; pathIndex < pathToVeriabl.length; pathIndex++) {
            if (pathIndex !== pathToVeriabl.length - 1) {
              toupdate = toupdate[pathToVeriabl[pathIndex]];
            } else {
              toupdate[pathToVeriabl[pathIndex]] = path;
            }
          }
          this.attachmentFormGrp.patchValue(this.attachmentFormGrp.value);
        }
        this.toaster.showSuccess('File Uploaded Successfully');
      }
      else {
        this.toaster.showError('Something is Wrong');
      }
    })
  }
  getFileNameDta(fileUrl: any) {
    if (fileUrl)
      return this.taxService.getFileName(fileUrl);
  }
  createPath(str: string) {
    let path = [];
    let splitedPath = str.split('.');
    for (let splitedPathIndex = 0; splitedPathIndex < splitedPath.length - 1; splitedPathIndex++) {
      let pathChunk = splitedPath[splitedPathIndex];
      let indexOfBrc = pathChunk.indexOf('(');
      if (indexOfBrc === -1) {
        path.push(pathChunk);
      } else {
        path.push(pathChunk.substr(0, indexOfBrc));
        path.push(pathChunk.charAt(indexOfBrc + 1));
      }
    }
    path.push(splitedPath[splitedPath.length - 1]);
    return path;
  }
  download(fileUrl: any) {
    let urlData = {
      fname: fileUrl
    }
    this.taxService.downloadFile(urlData).subscribe((response: any) => {
      if (response != null) {
        this.fileUrl = response.url;
        window.open(this.fileUrl, '_blank');
      } else {
      }
    })
  }
  getAttachmentData(): UntypedFormGroup[] {
    return (this.attachmentFormGrp.get('attachmentList') as UntypedFormArray).controls as UntypedFormGroup[];
  }

  initTaxPositionGrp(): UntypedFormGroup {
    return new UntypedFormGroup({
      fileLoc: new UntypedFormControl('', [Validators.required]),
      fileName: new UntypedFormControl(this.selectedFile, [Validators.required]),
      remarks: new UntypedFormControl(''),
    })
  }
  addRow() {
    const attachmentFormGrp = (this.attachmentFormGrp.get('attachmentList') as UntypedFormArray)
    if (attachmentFormGrp.length === 5) {
      this.toaster.showError('Maximum 5 attachments can be added')
      return
    }
    let attachmentDataGrp = this.initTaxPositionGrp();
    attachmentFormGrp.push(attachmentDataGrp);
  }
  rmProv(i: number) {
    (this.attachmentFormGrp.get('attachmentList') as UntypedFormArray).removeAt(i);
    this.rmvDetailModalRef.close();
  }
  opentaxPop(content: any) {
    this.rmvDetailModalRef = this.modalService.open(content);
  }

  uploadjudicialPredLoc(k: any) {
    let attachmentArray = this.attachmentFormGrp.controls.attachmentList as UntypedFormArray
    if (attachmentArray.controls.length > 0) {
      for (let index = 0; index < attachmentArray.controls.length; index++) {
        if (index === k) {
          let attachmentControl = attachmentArray.controls[index] as UntypedFormGroup;
          attachmentControl.value['isCopyOfFormLocDocUploadedClicked'] = true
        }
      }
    }
  }

  onSaveClick() {
    this.activeModal.close(this.attachmentFormGrp.value);
  }
  closeModal() {
    this.activeModal.close(null);
  }
}
