<!-- <span class="nav-data" *ngIf="caseData"><span class="text-data"> Last Updated By : </span>{{caseData.updatedBy}} &nbsp; <span  class="text-data"> On : </span> {{caseData.updatedOn}} </span> -->
<div class="row">
  <div class="col-md-4">
    <ul class="nav nav-tabs iris-gst-display-flex b1" role="tablist">
      <li class="nav-item">
        <a class="nav-link" id="AppelTribp1-tab" data-toggle="tab" (click)="tabChanged('tab1')"
          [ngClass]="(selectedTab === 'tab1') ? 'active': ''" role="tab" aria-controls="AppelTribp1-1"
          aria-selected="true">Phase 1</a>
      </li>
      <li class="nav-item" [ngClass]="{'disabled': !isPhase2 && !(action == 'update' && getAccessTabData.apaFlag)}">
        <a class="nav-link" id="AppelTribp2-tab" data-toggle="tab" (click)="tabChanged('tab2')"
          [ngClass]="(selectedTab === 'tab2') ? 'active': ''" role="tab" aria-controls="AppelTribp2-1"
          aria-selected="false">Phase 2</a>
      </li>
      <li class="nav-item"
        [ngClass]="{'disabled': !isPhase3 && !(action == 'update' && getAccessTabData.apaFlag) || disablePhase3 }">
        <a class="nav-link" id="AppelTribp3-tab" data-toggle="tab" (click)="tabChanged('tab3')"
          [ngClass]="(selectedTab === 'tab3') ? 'active': ''" role="tab" aria-controls="AppelTribp3-1"
          aria-selected="false">Phase 3</a>
      </li>
    </ul>
  </div>
  <div class="col-md-4">
    <div class="textCenter pt11">
      <span class="infoVal fs16" *ngIf="isUpdate" title="Case ID">{{caseData.caseId}}</span>
    </div>
  </div>
  <div class="col-md-4">
    <span class="computationBtn" *ngIf="caseData">
      <span class="nav-data" *ngIf="caseData"><span class="text-data"> Last Updated By : </span>{{caseData.updatedBy}}
        &nbsp;
        <span class="text-data"> On : </span> {{caseData.updatedOn}} </span>
    </span>
  </div>
</div>


<div class="tab-content nopadding" (keydown.enter)="$event.preventDefault()">
  <form [formGroup]="authTribP1Form">
    <div class="tab-pane fade show active" id="AppelTribp1-1" role="tabpanel" aria-labelledby="AppelTribp1-tab">
      <div class="card borderOrange" [hidden]="(selectedTab === 'tab2') || (selectedTab === 'tab3')"
        [ngClass]="{'bgGray': (action == 'view')} ">
        <div class="card-body">
          <fieldset [disabled]="action == 'view'">
            <div class="row">
              <div class="col-md-12 isMemo" *ngIf="caseType === caseTypeData.MEMORANDUM"> Memorandum Tab </div>
              <div class="col-md-3" *ngIf="isUpdate">
                <div class="form-group">
                  <label for="exampleInputPassword1">Case ID <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="caseId" placeholder="" readonly formControlName="caseId">

                </div>
              </div>
              <div *ngIf="caseType == caseTypeData.MEMORANDUM" class="col-md-3">
                <div class="form-group">
                  <label for="originalCaseId">Original Case ID <a class="clrOrange infoIcon"
                      title="LMS Case ID of the Original Case - Only if new case is created for Departmental Appeal"><i
                        class="fa fa-info-circle"></i></a></label>
                  <input type="text" class="form-control" id="originalCaseId" placeholder=""
                    formControlName="originalCaseId">
                </div>
              </div>
              <div class="col-md-3" [hidden]="hideDiv">
                <div class="form-group">
                  <label for="exampleInputPassword1">Division <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="hidden" class="form-control" id="" readonly placeholder="" formControlName="gstinDiv">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">State <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="" readonly placeholder="" formControlName="state">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">GSTIN <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="" readonly placeholder="" formControlName="gstin">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Type of Authority <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="typeOfAuthority1" formControlName="typeOfAuthority1"
                    (change)="checkTypeOfAuthoritySelected($event)"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.typeOfAuthority1.errors }">
                    <ng-container *ngFor="let obj of typeOfAuthority">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                </div>
                <div *ngIf="isSubmitted && p1.typeOfAuthority1.errors">
                  <span class="text-danger" *ngIf="p1.typeOfAuthority1.errors.required">Required</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">DIN</label>
                  <input type="text" class="form-control" id="" placeholder="" formControlName="din1"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.din1.errors }">
                  <div *ngIf="isSubmitted && p1.din1.errors">
                    <span class="text-danger" *ngIf="p1.din1.errors.required">Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Mode of Receipt of Order</label>
                  <select class="form-control form-select" id="orderReceiptMode1" formControlName="orderReceiptMode1">
                    <ng-container *ngFor="let obj of orderReceiptMode">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div *ngIf="caseType !== caseTypeData.MEMORANDUM" class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Order against which appeal has been filed</label>
                  <input type="text" class="form-control" id="" placeholder="" formControlName="orderAgainst">
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Order Number <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="" placeholder="" formControlName="orderNo1"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.orderNo1.errors }">
                </div>
                <div *ngIf="isSubmitted && p1.orderNo1.errors">
                  <span class="text-danger" *ngIf="p1.orderNo1.errors.required"> Required</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of Order <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfOrder1.errors }">
                    <p-calendar class="form-control " formControlName="dateOfOrder1" [monthNavigator]="true"
                      [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                      [showIcon]="true" dataType="string"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfOrder1.errors">
                    <span class="text-danger" *ngIf="p1.dateOfOrder1.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of Receipt of Order <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfReceiptOfOrder1.errors }">
                    <p-calendar class="form-control " formControlName="dateOfReceiptOfOrder1" [monthNavigator]="true"
                      [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                      [showIcon]="true" dataType="string"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfReceiptOfOrder1.errors">
                    <span class="text-danger" *ngIf="p1.dateOfReceiptOfOrder1.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Period From
                    <span class="mandate"><i class="fa fa-asterisk"></i></span>
                  </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="periodFromDate" [maxDate]="maxDate"
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.periodFromDate.errors }"></p-calendar>
                  </div>
                </div>
                <div *ngIf="isSubmitted && p1.periodFromDate.errors">
                  <span class="text-danger" *ngIf="p1.periodFromDate.errors.required"> Required</span>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Period To
                    <span class="mandate"><i class="fa fa-asterisk"></i></span>
                  </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="periodToDate" 
                      [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                      dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.periodToDate.errors }"
                      (onSelect)="getperiodToValue(periodToPop)"></p-calendar>
                  </div>

                </div>
                <div *ngIf="isSubmitted && p1.periodToDate.errors">
                  <span class="text-danger" *ngIf="p1.periodToDate.errors.required"> Required</span>
                </div>
                <ng-template #periodToPop let-modal>
                  <div class="modal-header" style="border: none; padding-bottom: 15px; padding-top:9px" >
                    <button type="button" class="close" aria-label="Close"
                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                    </button>
                  </div>
                  <div class="col-md-12" >
                    <div class="table-responsive">
                       <p class="title-data">Following cases are already exist for same selected period, <span style="font-weight: bold;">Do you want to still proceed?</span></p>
                      <table style="margin-left: 5px;">
                        <thead>
                          <tr class="bg-primary text-dblue">
                            <th class="sl-no-cla">Sr No</th>
                            <th class="clas">Case id</th>
                            <th>Case label</th>
                            <th class="clas">Financial Year</th>
                            <th class="clas">Period From</th>
                            <th class="clas">Period To </th>
                            <th class="clas">Forum</th>
                            <th class="ac-no-clas">Total Demand(₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let obj of duplicateData  let i = index">
                            <td class="sl-no-cla">{{i + 1}}</td>
                            <td class="clas">{{obj.caseId}}</td>
                            <td>{{obj.caseLabel}}</td>
                            <td class="clas">{{obj.fy}}</td>
                            <td class="clas">{{obj.periodFromDate}}</td>
                            <td class="clas">{{obj.periodToDate}}</td>
                            <td class="clas">{{obj.forum}}</td>
                            <td class="ac-no-clas" style="text-align: right;">{{obj.demandAmount | INR: 0}} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="modal-footer" style="border: none; padding-top: 5px;" >
                    <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                    (click)="modal.close('No click')">Yes</button>
                      <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                      (click)="goToSummary()">No</button>
                  </div>
                </ng-template>
              </div>
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">O-i-O Number
                     <span class="mandate"><i class="fa fa-asterisk"></i></span>
                  </label>
                  <input type="text" class="form-control" readonly id="oioNo" placeholder="" formControlName="oioNo"
                  >
                  <div *ngIf="isSubmitted && p1.oioNo.errors">
                    <span class="text-danger" *ngIf="p1.oioNo.errors.required"> Required</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of O-i-O </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control " formControlName="dateOfOio"  [disabled]="true"
                      [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate"
                      yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                     ></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfOio.errors">
                    <span class="text-danger" *ngIf="p1.dateOfOio.errors.required"> Required</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of receipt of O-i-O
                    <span class="mandate"><i class="fa fa-asterisk"></i></span>
                      </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control " formControlName="dateOfReceiptOfOio" [disabled]="true"
                      [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate"
                      yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      ></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfReceiptOfOio.errors">
                    <span class="text-danger" *ngIf="p1.dateOfReceiptOfOio.errors.required"> Required</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">O-i-A Number
                    <span class="mandate"><i class="fa fa-asterisk"></i></span>
                      </label>
                  <input type="text" class="form-control" readonly id="oioNum" placeholder=""  formControlName="oiaNo"
                    >
                  <div *ngIf="isSubmitted && p1.oiaNo.errors">
                    <span class="text-danger" *ngIf="p1.oiaNo.errors.required"> Required</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of O-i-A </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control " formControlName="dateOfOia" [disabled]="true"
                      [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate"
                      yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfOia.errors }"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfOia.errors">
                    <span class="text-danger" *ngIf="p1.dateOfOia.errors.required"> Required</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of receipt of O-i-A
                    <span class="mandate"><i class="fa fa-asterisk"></i></span>
                      </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfReceiptOfOia"
                      [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate" [disabled]="true"
                      yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                     ></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfReceiptOfOia.errors">
                    <span class="text-danger" *ngIf="p1.dateOfReceiptOfOia.errors.required"> Required</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
              <div class="form-group">typeOfAuthority1
                <label for="exampleInputPassword1">Appealed U/s </label>
                <select class="form-control form-select" id="gstin" formControlName="appealedUs">
                  <ng-container *ngFor="let obj of appaPh1Appelus">
                    <option [value]="obj.value">{{obj.value}}</option>
                  </ng-container>
                </select>
              </div>
            </div> -->
              <div class="col-md-3" *ngIf="caseType != caseTypeData.MEMORANDUM">
                <div class="form-group">
                  <label for="exampleInputPassword1">Due date to appeal <span class="mandate"><i
                        class="fa fa-asterisk"></i></span><a class="clrOrange infoIcon"
                      title="This is a system-generated due date. Please verify once and update"><i
                        class="fa fa-info-circle"></i></a></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dueDateOfAppeal" [monthNavigator]="true"
                      [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                      dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dueDateOfAppeal.errors }"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dueDateOfAppeal.errors">
                    <span class="text-danger" *ngIf="p1.dueDateOfAppeal.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3" *ngIf="caseType == caseTypeData.MEMORANDUM">
                <div class="form-group">
                  <label for="exampleInputPassword1">Due date to File Cross Objection <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dueDateOfAppeal" [monthNavigator]="true"
                      [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                      dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dueDateOfAppeal.errors }"></p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dueDateOfAppeal.errors">
                    <span class="text-danger" *ngIf="p1.dueDateOfAppeal.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Internal due date </label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="internalDueDate" [monthNavigator]="true"
                      [yearNavigator]="true" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                      dataType="string">
                    </p-calendar>
                  </div>
                </div>
              </div>
              
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Consultant</label>

                  <div *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" class="input-group">
                    <input type="text" class="form-control"
                      *ngIf="isToggleConsultantDropdown && action==='update' || action==='view'" placeholder=""
                      formControlName="consultant1" readonly>
                    <button *ngIf="isToggleConsultantDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('p1')">
                      <i class="fa fa-pencil"></i>
                    </button> 
                  </div>

                  <select class="form-control form-select" id=""
                      *ngIf="!isToggleConsultantDropdown || action==='create'" formControlName="consultant1">
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of consultantList">
                        <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                      </ng-container>
                    </select>
                </div>
              </div> -->

              <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create')">
                <div class="form-group">
                  <label for="exampleInputPassword1">Consultant</label>
              
                  <!-- For Consultant Role: Create, View, and Update -->
                  <div *ngIf="roleName === 'Consultant' && action === 'create'">
                    <input 
                      type="text" 
                      class="form-control" 
                      formControlName="consultant1" 
                      [readOnly]="action === 'create'">
                  </div>
              
                  <!-- For Non-Consultant Role -->
                  <div *ngIf="roleName !== 'Consultant'">
                    <div *ngIf="isToggleConsultantDropdown && (action === 'update' || action === 'view')" class="input-group">
                      <input 
                        type="text" 
                        class="form-control" 
                        placeholder="" 
                        formControlName="consultant1" 
                        readonly>
                      <button 
                        *ngIf="isToggleConsultantDropdown && action === 'update'" 
                        class="btn btn-outline-secondary" 
                        type="button" 
                        (click)="onClickToggleConsultantDropdown('p1')">
                        <i class="fa fa-pencil"></i>
                      </button>
                    </div>
              
                    <select 
                      class="form-control form-select" 
                      id="" 
                      *ngIf="!isToggleConsultantDropdown || action === 'create'" 
                      formControlName="consultant1">
                      <option [value]="null">-- Select --</option>
                      <ng-container *ngFor="let obj of consultantList">
                        <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                      </ng-container>
                    </select>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Person responsible <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <!-- <select class="form-control form-select" id="personResponsible1"
                    *ngIf="navContext?.entityType == 'FILING'" formControlName="personResponsible1"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.personResponsible1.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of userRoleLs">
                      <option [value]="obj.userName">{{obj.userName}}</option>
                    </ng-container>
                  </select>
                  <input type="text" class="form-control"
                    *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'" placeholder=""
                    formControlName="personResponsible1" readonly> -->

                    <div *ngIf="isToggleDropdown && action==='update' || action==='view'" class="input-group">
                      <input type="text" class="form-control"
                        *ngIf="isToggleDropdown && action==='update' || action==='view'" placeholder=""
                        formControlName="personResponsible1" readonly>
                      <button *ngIf="isToggleDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleDropdown()">
                        <i class="fa fa-pencil"></i>
                      </button> 
                    </div>

                  <select class="form-control form-select" id="personResponsible1"
                    *ngIf="!isToggleDropdown || action==='create'" formControlName="personResponsible1"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.personResponsible1.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of userRoleLs">
                      <option [value]="obj.userName">{{obj.userName}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.personResponsible1.errors">
                    <span class="text-danger" *ngIf="p1.personResponsible1.errors.required"> Required
                    </span>
                  </div>
                </div>
              </div>
              <!-- <div class="col-md-3" [hidden]="isMemorandum">
                <div class="form-group">
                  <label for="exampleInputPassword1">  <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="location" formControlName="preDepositApplicable"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.preDepositApplicable.errors }" (change)="checkpreDepositNo($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of preDepoApplicableList">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.preDepositApplicable.errors">
                    <span class="text-danger" *ngIf="p1.preDepositApplicable.errors.required"> Required</span>
                  </div>

                </div>
              </div> -->
              <!-- <div class="col-md-3" [hidden]="isMemorandum">
                <div class="form-group">
                  <label for="exampleInputPassword1">Pre Deposit Paid
                     <span class="mandate"><i class="fa fa-asterisk"></i></span>
                    </label>
                  <input type="text" class="form-control" id="resPerson	" placeholder="" maxlength="19"  currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }"
                    formControlName="preDepositPaid" [ngClass]="{ 'disabled': disablField }">
                    <div *ngIf="p1.preDepositPaid.errors">
                      <span class="text-danger" *ngIf="p1.preDepositPaid.errors.pattern"> Numberic Value
                         allowed</span>
                   </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3" [hidden]="isMemorandum">
                <div class="form-group">
                  <label for="exampleInputPassword1">Cumulative Predeposit
                     <span class="mandate"><i class="fa fa-asterisk"></i></span>
                    </label>
                  <input type="text" class="form-control" id="resPerson	" placeholder="" maxlength="19" currencyMask  [options]="{ prefix: '', thousands: ',', precision: 0 }"
                    formControlName="cPreDepositPaid" [ngClass]="{ 'disabled': disablField }">
                    <div *ngIf="p1.cPreDepositPaid.errors">
                      <span class="text-danger" *ngIf="p1.cPreDepositPaid.errors.pattern"> Numberic Value
                         allowed</span>
                   </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3" [hidden]="isMemorandum">
                <div class="form-group">
                   <label for="exampleInputPassword1">Mode of payment </label>
                   <select class="form-control form-select" id="location" (change)="changePayMode($event)" formControlName="paymentMode" [ngClass]="{ 'disabled': disablField }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of paymentMode">
                         <option [value]="obj.value">{{obj.value}}</option>
                      </ng-container>
                   </select>

                </div>
             </div> -->
              <!-- <div class="col-md-3" [hidden]="isMemorandum">
                <div class="form-group">
                   <label for="exampleInputPassword1">Bank challan <a class="clrOrange infoIcon"
                         title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                      <a class="downupIcon" *ngIf="isDWbankChallanLoc && p1.bankChallanLoc.value"
                         title="Download file" (click)="download(p1.bankChallanLoc.value)"><i
                            class="fa fa-download"></i></a>
                      <a class="downupIcon"
                         *ngIf="isUPbankChallanLoc && !(action == 'update' && getAccessTabData.aptFlag)"
                         title="Upload file" (click)="uploadbankChallanLoc()"><i class="fa fa-upload"></i></a>
                   </label>
                   <input type="file" [hidden]="!isbankChallanLocFile" id="bankChallanLoc"
                      class="filetype form-control" name="myfile" (change)='onFileSelect($event)' [ngClass]="{'disabled1': disablField, 'disabled': disablBankChallan }">
                   <p [hidden]="!isbankChallanLocText" class="form-control pt10" [ngClass]="{'disabled1': disablField, 'disabled': disablBankChallan }">
                      {{getFileNameDta(p1.bankChallanLoc.value)}}</p>
                </div>
             </div> -->
              <!-- {{caseType === caseTypeData.MEMORANDUM }} {{ isUpdate}} -->
              <div class="col-md-3" *ngIf="caseType === caseTypeData.MEMORANDUM">
                <div class="form-group">
                  <label for="exampleInputPassword1">Appeal book submitted by department
                    <span class="mandate"><i class="fa fa-asterisk"></i></span>
                  </label>
                  <input type="file" class="form-control file-upload-default" #file>
                  <div class="attachment-block"
                    [ngClass]="{'errorBorder': isSubmitted &&  getAppealBookLocLength() === 0 }">
                    <span style="cursor: pointer;" (click)="openAppealBookLoc(appealBookLoc)"> <i
                        class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span>
                    <span *ngIf="getAppealBookLocLength() != 0"> {{getAppealBookLocLength()}} Attachment</span>

                  </div>
                  <div *ngIf="isSubmitted && getAppealBookLocLength() === 0">
                    <span class="text-danger"> Required</span>
                  </div>
                  <div>
                    <ng-template #appealBookLoc let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment</label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                                <th style="width:50%;">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let bookLocGrp of getAppealBookLoc(); let i = index" [formGroup]="bookLocGrp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="bookLocGrp.value.isdocLocUploadedClicked" type="file"
                                          id="appealBookLoc({{i}}).fileLoc" class="filetype form-control" name="myfile"
                                          (change)="onFileSelect($event)">
                                        <p *ngIf="!bookLocGrp.value.isdocLocUploadedClicked || bookLocGrp.value.fileLoc"
                                          class="form-control pt10"> {{getFileNameDta(bookLocGrp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="bookLocGrp.value.fileLoc" title="Download file"
                                          (click)="download(bookLocGrp.value.fileLoc)"><i
                                            class="fa fa-download"></i></a>
                                        <a class="downupIcon"
                                          *ngIf="!(bookLocGrp.value.fileLoc || bookLocGrp.value.fileLoc) && isDisableIcons"
                                          title="Upload file" (click)="uploadAppealBookLoc(i)"><i
                                            class="fa fa-upload"></i></a>
                                        <a class="downupIcon" (click)="deleteAppealBookLoc(i)"
                                          *ngIf="(bookLocGrp.value.fileLoc) && isDisableIcons"><i class="fa fa-trash"
                                            aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input type="text" [title]="bookLocGrp.value.remarks ? bookLocGrp.value.remarks:''"
                                    class="form-control" formControlName="remarks">
                                  <div *ngIf="bookLocGrp.value.remarks && bookLocGrp.value.remarks.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div class="col-md-3" *ngIf="caseType === caseTypeData.MEMORANDUM">
                <div class="form-group">
                  <label for="exampleInputPassword1">Other Attachments
                  </label>
                  <input type="file" class="form-control file-upload-default" #file>
                  <div class="attachment-block">
                    <span style="cursor: pointer;" (click)="openOtherAttachmentLoc1(otherAttachmentLoc1Pop)"> <i
                        class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span>
                    <span *ngIf="getOtherAttachmentLoc1Length() != 0"> {{getOtherAttachmentLoc1Length()}}
                      Attachment</span>

                  </div>
                  <div>
                    <ng-template #otherAttachmentLoc1Pop let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment</label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                                <th style="width:50%;">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let otherAttachLoc1Grp of getOtherAttachmentLoc1(); let i = index"
                                [formGroup]="otherAttachLoc1Grp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="otherAttachLoc1Grp.value.isdocLocUploadedClicked" type="file"
                                          id="otherAttachmentsLoc1({{i}}).fileLoc" class="filetype form-control"
                                          name="myfile" (change)="onFileSelect($event)">
                                        <p *ngIf="!otherAttachLoc1Grp.value.isdocLocUploadedClicked || otherAttachLoc1Grp.value.fileLoc"
                                          class="form-control pt10">
                                          {{getFileNameDta(otherAttachLoc1Grp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="otherAttachLoc1Grp.value.fileLoc"
                                          title="Download file" (click)="download(otherAttachLoc1Grp.value.fileLoc)"><i
                                            class="fa fa-download"></i></a>
                                        <a class="downupIcon"
                                          *ngIf="!(otherAttachLoc1Grp.value.fileLoc || otherAttachLoc1Grp.value.fileLoc) && isDisableIcons"
                                          title="Upload file" (click)="uploadOtherAttachmentLoc1(i)"><i
                                            class="fa fa-upload"></i></a>
                                        <a class="downupIcon" (click)="deleteOtherAttachmentLoc1(i)"
                                          *ngIf="(otherAttachLoc1Grp.value.fileLoc) && isDisableIcons"><i
                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input type="text"
                                    [title]="otherAttachLoc1Grp.value.remarks ? otherAttachLoc1Grp.value.remarks:''"
                                    class="form-control" formControlName="remarks">
                                  <div
                                    *ngIf="otherAttachLoc1Grp.value.remarks && otherAttachLoc1Grp.value.remarks.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-3" [hidden]="isMemorandum">
                <div class="form-group">
                  <label for="exampleInputPassword1">DRC03 Copy <a class="clrOrange infoIcon" title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                    <a class="downupIcon" *ngIf="isDWdrc03CopyLoc && p1.drc03CopyLoc.value" title="Download file"
                      (click)="download(p1.drc03CopyLoc.value)"><i class="fa fa-download"></i></a>
                    <a class="downupIcon" *ngIf="isUPdrc03CopyLoc && !(action == 'update' && getAccessTabData.aptFlag)" title="Upload file" (click)="uploaddrc03CopyLoc()"><i
                        class="fa fa-upload"></i></a>
                  </label>
                  <input type="file" [hidden]="!isdrc03CopyLocFile" id="drc03CopyLoc" class="filetype form-control"
                    name="myfile" (change)='onFileSelect($event)' [ngClass]="{ 'disabled': disablField }">
                  <input [hidden]="!isdrc03CopyLocText" readonly type="text" class="filetype form-control" name="myfile"
                    formControlName="drc03CopyLocFN">
                    <p [hidden]="!isdrc03CopyLocText" class="form-control pt10" [ngClass]="{ 'disabled': disablField }">{{getFileNameDta(p1.drc03CopyLoc.value)}}</p>
                </div>
              </div> -->
              <!-- <div class="col-md-3" [hidden]="isMemorandum">
                <div class="form-group">
                  <label for="exampleInputPassword1">DRC-03 Tax </label>
                  <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Tax1" currencyMask [ngClass]="{ 'disabled': disablField,'errorBorder': p1.drc03Tax1.errors }" maxlength="19"  currencyMask [options]="{ prefix: '', thousands: ',', precision: 0 }">
                  <div *ngIf="p1.drc03Tax1.errors">
                    <span class="text-danger" *ngIf="p1.drc03Tax1.errors.pattern"> Negative value not allowed</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3" [hidden]="isMemorandum">
                <div class="form-group">
                  <label for="exampleInputPassword1">DRC-03 Interest </label>
                  <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Interest1" [ngClass]="{ 'disabled': disablField, 'errorBorder': p1.drc03Interest1.errors }" maxlength="19" currencyMask  [options]="{ prefix: '', thousands: ',', precision: 0 }">
                  <div *ngIf="p1.drc03Interest1.errors">
                    <span class="text-danger" *ngIf="p1.drc03Interest1.errors.pattern"> Negative value not allowed</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3" [hidden]="isMemorandum">
                <div class="form-group">
                  <label for="exampleInputPassword1">DRC-03 Penalty </label>
                  <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Penalty1" [ngClass]="{ 'disabled': disablField, 'errorBorder': p1.drc03Penalty1.errors }" maxlength="19" currencyMask  [options]="{ prefix: '', thousands: ',', precision: 0 }">
                  <div *ngIf="p1.drc03Penalty1.errors">
                    <span class="text-danger" *ngIf="p1.drc03Penalty1.errors.pattern"> Negative value not allowed</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of submission</label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfsubmission1"
                      [monthNavigator]="true" [yearNavigator]="true"
                      yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true" dataType="string">
                    </p-calendar>
                  </div>
                </div>
              </div> -->
              <div class="col-md-12 nopadding">
                <div class="form-group">
                  <label for="exampleInputPassword1">Case Label <span class="mandate"><i
                        class="fa fa-asterisk"></i></span><a class="clrOrange infoIcon"
                      title="Please give a one line description to identify the case."><i
                        class="fa fa-info-circle"></i></a></label>
                  <textarea class="form-control" id="caseLabel" rows="4" formControlName="caseLabel" placeholder=""
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.caseLabel.errors }"></textarea>
                  <div *ngIf="isSubmitted && p1.caseLabel.errors">
                    <span class="text-danger" *ngIf="p1.caseLabel.errors.required"> Required</span>
                  </div>
                  <div *ngIf="isSubmitted && p1.caseLabel.errors">
                    <span class="text-danger" *ngIf="p1.caseLabel.errors.minlength">Please enter min. 5 chars.</span>
                  </div>
                  <div *ngIf="isSubmitted && p1.caseLabel.errors">
                    <span class="text-danger" *ngIf="p1.caseLabel.errors.maxlength">Cannot enter more than 100
                      chars.</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Amount Under Appeal <span class="mandate"><i
                        class="fa fa-asterisk"></i></span><a class=" addExtLetter" (click)="addIssueInv()"
                      *ngIf="(action == 'update' || action == 'create')"><i class="fa fa-plus"></i></a> </label>
                  <div class=" row">
                    <div class="col-md-12">
                      <div class="search-table-outter wrapper">
                        <table class="search-table inner">
                          <thead>
                            <tr class="bgGred">
                              <th  rowspan="3" class="sl-no-cla" *ngIf="isUpdateBtn">Info</th>
                              <th rowspan="2">Issue <span class="mandate top7"><i class="fa fa-asterisk"></i></span>
                              </th>
                              <th rowspan="2">Keyword <a class="clrOrange infoIcon"
                                  title="Maximum Length Limit is 150 Characters."><i class="fa fa-info-circle"></i></a>
                              </th>
                              <th rowspan="2" class="txtCenter">Risk Parameter <span class="mandate top7"><i
                                    class="fa fa-asterisk"></i></span></th>
                              <th rowspan="2">Tag</th>
                              <th colspan="7" class="txtCenter">Amount Demanded (₹)</th>
                              <th rowspan="2">Remark <a class="clrOrange infoIcon"
                                  title="Max 500 characters allowed."><i class="fa fa-info-circle"></i></a></th>
                              <th rowspan="2">Add Provision Details</th>
                              <th rowspan="2">Remove</th>
                            </tr>
                            <tr class="bgGred">
                              <th>IGST </th>
                              <th>CGST </th>
                              <th>SGST</th>
                              <th>CESS</th>
                              <th>Interest (₹) <a *ngIf="isUpdateBtn" title="Interest Calculation" (click)="openIntCalPop(intCalPop)"><i class="fa fa-calculator fs14"></i></a></th>
                              <th>Penalty</th>
                              <th>Total</th>


                            </tr>
                          </thead>
                          <tbody>
                            <ng-container>
                              <tr *ngFor="let div of issuInvArr(); let i=index" [formGroup]="div">
                                <td  class="sl-no-cla" *ngIf="isUpdateBtn">
                                  <p (click)="getissueData(issuePop,i)" class="pr5"><span
                                    class="badge-clo" title="Cases with similar issue"><i class="fa fa-info-circle"></i></span>
                                  </p>
                                </td>
                                <td class="w250">
                                  <select class="form-control form-select" formControlName="issue"
                                    [title]="div.value.issue ? div.value.issue:''"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }">
                                    <option [value]="null" disabled>-- Select --</option>
                                    <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                      <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                        {{item.name}}</option>
                                    </optgroup>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('issue')?.errors">
                                    <span class="text-danger" *ngIf="div.get('issue')?.errors?.required "> Required
                                    </span>
                                  </div>
                                  <!-- <input type="text" class="form-control" id="tax" placeholder="" formControlName="issue"> -->
                                </td>
                                <td><input type="text" class="form-control" id="tax" placeholder=""
                                    formControlName="keyword" maxlength="150"
                                    [title]="div.value.keyword ? div.value.keyword:''"></td>
                                <td>
                                  <select class="form-control form-select" id="status" formControlName="riskParam"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('riskParam')?.errors  }">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of riskParam">
                                      <option [value]="obj.value">{{obj.value}}</option>
                                    </ng-container>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('riskParam')?.errors">
                                    <span class="text-danger" *ngIf="div.get('riskParam')?.errors?.required "> Required
                                    </span>
                                  </div>
                                </td>
                                <td>
                                  <div class="input-group">
                                  <input type="text" class="form-control"  [title]="div.value.tag ? div.value.tag:''"
                                    *ngIf="isToggleSegmentDropdown && action==='update' || action==='view'" placeholder=""
                                    formControlName="tag" readonly>
                                  <button *ngIf="isToggleSegmentDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleSegmentDropdown('p1')">
                                    <i class="fa fa-pencil"></i>
                                  </button> 
                                </div>
                                  <select class="form-control form-select" id=""  [title]="div.value.tag ? div.value.tag:''"
                                  *ngIf="!isToggleSegmentDropdown || action==='create'" formControlName="tag">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of tagDataList">
                                    <option [value]="obj">{{obj}}</option>
                                  </ng-container>
                                </select>
                                </td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="igst1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="cgst1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="sgst1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="cess1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="interest1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="penalty1" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="total1" readonly maxlength="19" InrFormat
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control" id="remark1" placeholder=""
                                    formControlName="remark1" [title]="div.value.remark1 ? div.value.remark1:''">
                                  <div *ngIf="div.value.remark1 && div.value.remark1.length > 500">
                                    <span class="text-danger">Max character Length 500</span>
                                  </div>
                                </td>
                                <td><span (click)="openDetailPop(detailPop, i)"><i
                                      class="icon  fa fa-file-text-o"></i></span></td>
                                <!-- <td><a class=" addExtLetter" (click)="openRmvIssuePop(removeIssuePopup, i)" *ngIf="isIssueAdd"><i class="fa fa-minus"></i></a></td>  -->
                                 <td><a class=" addExtLetter" (click)="openRmvIssuePop(removeIssuePopup, i)"><i class="fa fa-minus"></i></a></td>
                                <ng-template #removeIssuePopup let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p>Are you sure, you want to remove?</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="rmIssueInv(i)">Remove</button>
                                  </div>
                                </ng-template>
                                <ng-template #detailPop let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Details</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p><a class="pl5 addExtLetter" title="Add More Detail" (click)="addDetail(i)"><i
                                          class="fa fa-plus"></i> Add another row to update the next quarter provision
                                        entry</a></p>
                                    <div class="table-responsive autoScroll">
                                      <table id="" class="table table-bordered">
                                        <thead>
                                          <tr class="bg-primary text-dblue">
                                            <th>Financial Year </th>
                                            <th>Quarter </th>
                                            <th>Provision (₹)</th>
                                            <th>Contingent Liability (₹)</th>
                                            <th>Other Liability (₹)</th>
                                            <th>Reason for change in the Provision</th>
                                            <th>Reason for provision not made, fully or partly made</th>
                                            <th>Reason for Cont. Liab.</th>
                                            <th>Reason for disclosure in Other Liability</th>
                                            <th>Consider in CARO Report</th>
                                            <th>Amount of CARO (₹)</th>
                                            <th>Remove</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <ng-container>
                                            <tr *ngFor="let detailsGrp of getdetails(div); let k=index"
                                              [formGroup]="detailsGrp">
                                              <td>
                                                <select class="form-control form-select" id="gstin"
                                                  formControlName="finYear"
                                                  [title]="detailsGrp.value.finYear ? detailsGrp.value.finYear:''">
                                                  <option [value]="null">-- Select --</option>
                                                  <ng-container *ngFor="let obj of finyearData">
                                                    <option [value]="obj.year">{{obj.year}}</option>
                                                  </ng-container>
                                                </select>
                                              </td>
                                              <td>
                                                <select class="form-control form-select" id="gstin"
                                                  formControlName="quarter">
                                                  <option [value]="null">-- Select --</option>
                                                  <ng-container *ngFor="let obj of quarterList">
                                                    <option [value]="obj.value">{{obj.value}}</option>
                                                  </ng-container>
                                                </select>
                                              </td>
                                              <td><input type="text" class="form-control align-right"
                                                  formControlName="provisionAmt1" id="tax" placeholder="" maxlength="19"
                                                  InrFormat (paste)="handlePaste($event)"></td>
                                              <td><input type="text" class="form-control align-right"
                                                  formControlName="contLiabilityAmt1" id="tax" placeholder=""
                                                  maxlength="19" InrFormat (paste)="handlePaste($event)"></td>
                                              <td><input type="text" class="form-control align-right"
                                                  formControlName="otherLiability1" id="tax" placeholder=""
                                                  maxlength="19" InrFormat (paste)="handlePaste($event)"></td>
                                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.provisionChangeReason1.value ? getdetails(div)[k].controls.provisionChangeReason1.value:''"
                                                  formControlName="provisionChangeReason1"
                                                  (click)="openTextBoxModal('Reason for change in the Provision', 'provisionChangeReason1',div,k)">
                                              </td>
                                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.provisionNotMadeReason1.value ? getdetails(div)[k].controls.provisionNotMadeReason1.value:''"
                                                  formControlName="provisionNotMadeReason1"
                                                  (click)="openTextBoxModal('Reason for provision not made, fully or partly made', 'provisionNotMadeReason1',div,k)">
                                              </td>
                                              <td><input type="text" class="form-control" id="tax" placeholder=""
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  [title]="getdetails(div)[k].controls.contLiabReason1.value ? getdetails(div)[k].controls.contLiabReason1.value:''"
                                                  formControlName="contLiabReason1"
                                                  (click)="openTextBoxModal('Reason for Cont. Liab.', 'contLiabReason1',div,k)">
                                              </td>
                                              <td><input type="text" class="form-control" placeholder=""
                                                  data-bs-toggle="tooltip" data-bs-placement="top"
                                                  formControlName="otherLiabilityReason1"
                                                  (click)="openTextBoxModal('Reason for disclosure in Other Liability', 'otherLiabilityReason1',div,k)">
                                              </td>
                                              <td>
                                                <select class="form-control form-select" id="gstin"
                                                  formControlName="considerInCaroReport1">
                                                  <option [value]="null">-- Select --</option>
                                                  <ng-container *ngFor="let obj of yesNoList">
                                                    <option [value]="obj.value">{{obj.label}}</option>
                                                  </ng-container>
                                                </select>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control" formControlName="caroAmt1"
                                                  id="tax" placeholder="" maxlength="19" InrFormat
                                                  (paste)="handlePaste($event)">
                                                <div class="error-msg"
                                                  *ngIf="detailsGrp.value.considerInCaroReport1 === 'true' && ((detailsGrp.value.caroAmt1 === null) || (detailsGrp.value.caroAmt1 == 0))">
                                                  If "Consider in CARO Report" is Yes then Amount of Caro column cannot
                                                  be zero.</div>
                                                <!-- <div class="error-msg" *ngIf="(detailsGrp.value.considerInCaroReport1 === 'false') && (detailsGrp.value.caroAmt1 && (detailsGrp.value.caroAmt1 != 0))"> If "Consider in CARO Report" is No then Amount of Caro should be zero.</div>  -->
                                              </td>
                                              <td><a class="addExtLetter"
                                                  (click)="openRmvDetailPop(removeDetailPopup, i, k)"><i
                                                    class="fa fa-minus"></i></a></td>
                                              <ng-template #removeDetailPopup let-modal>
                                                <div class="modal-header">
                                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                                  <button type="button" class="close" aria-label="Close"
                                                    (click)="modal.dismiss('Cross click')">
                                                    save <span aria-hidden="true">×</span>
                                                  </button>
                                                </div>
                                                <div class="modal-body">
                                                  <p>Are you sure, you want to remove?</p>
                                                </div>
                                                <div class="modal-footer">
                                                  <button type="button" class="btn btn-outline-dark"
                                                    (click)="rmDetail(i, k)">Remove</button>
                                                </div>
                                              </ng-template>

                                            </tr>
                                          </ng-container>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>

                                <ng-template #issuePop let-modal>
                                  <div class="modal-header">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12" >
                                    <div class="table-responsive">
                                      <p class="title-data">The latest updated cases in the LMS with the selected issue are listed below for your reference.: <span style="font-weight: bold;">{{div.value.issue}}</span></p>
                                      <div class="btn btn-outline-success btn-download btnRep" (click)="exportAsXLSX()" >
                                        <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
                                      </div>
                                      <table *ngIf="this.hideData">
                                        <thead >
                                          <tr class="bgGred1">
                                            <th class="sl-no-cla">Sr No</th>
                                            <th><span>PAN</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.panName"
                                                  (click)="SortingIssue(issueSorting.PAN)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.panName"
                                                  (click)="SortingIssue(issueSorting.PAN)"></i>
                                            </th>
                                            <th><span>GSTIN</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.gstin"
                                                  (click)="SortingIssue(issueSorting.GSTIN)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.gstin"
                                                  (click)="SortingIssue(issueSorting.GSTIN)"></i>
                                          </th>
                                            <th><span>Division</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.gstinDiv"
                                                  (click)="SortingIssue(issueSorting.DIVISION)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.gstinDiv"
                                                  (click)="SortingIssue(issueSorting.DIVISION)"></i>
                                          </th>
                                            <th><span>Case ID</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.caseId"
                                                  (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.caseId"
                                                  (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                          </th>
                                            <th><span>Case Label</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true" *ngIf="!sorting.caseLabel"
                                                  (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true" *ngIf="sorting.caseLabel"
                                                  (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                            </th>
                                            <th ><span>Period From</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="!sorting.periodFromDate"
                                                  (click)="SortingIssue(issueSorting.PERIOD_FROM)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="sorting.periodFromDate"
                                                  (click)="SortingIssue(issueSorting.PERIOD_FROM)"></i>
                                          </th>
                                          <th ><span>Period To</span>&nbsp;
                                            <i class="fa fa-arrow-up cursor-pointer"
                                                aria-hidden="true"
                                                *ngIf="!sorting.periodToDate"
                                                (click)="SortingIssue(issueSorting.PERIOD_TO)"></i>
                                            <i class="fa fa-arrow-down cursor-pointer"
                                                aria-hidden="true"
                                                *ngIf="sorting.periodToDate"
                                                (click)="SortingIssue(issueSorting.PERIOD_TO)"></i>
                                        </th>
                                            <th><span>Person Responsible</span>&nbsp;
                                              <i class="fa fa-arrow-up cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="!sorting.personResponsible"
                                                  (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                              <i class="fa fa-arrow-down cursor-pointer"
                                                  aria-hidden="true"
                                                  *ngIf="sorting.personResponsible"
                                                  (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                          </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            *ngFor="let obj of issueData  let i = index">
                                            <td class="sl-no-cla">{{i + 1}}</td>
                                            <td class="clas">{{obj.panName}}</td>
                                            <td class="clas">{{obj.gstin}} 
                                             <div style="color: rgb(126 132 131);">
                                              {{obj.gstinName}} </div></td>
                                            <td>{{obj.gstinDiv}}</td>
                                            <td class="clas">{{obj.caseId}}</td>
                                            <td>{{obj.caseLabel}}</td>
                                            <td class="clas">{{obj.periodFromDate}}</td>
                                            <td class="clas">{{obj.periodToDate}}</td>
                                            <td>{{obj.personResponsible}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div *ngIf="!this.hideData" style="font-size: large;text-align: center;font-weight: bold;">
                                        No data found.
                                      </div>
                                      
                                    </div>
                                  </div>
                                </ng-template>
                              </tr>
                            </ng-container>
                            <ng-container>
                              <tr>
                                <th colspan="5" *ngIf="isUpdateBtn">Total</th>
                                <th colspan="4" *ngIf="!isUpdateBtn">Total</th>
                                <!-- <th colspan="4" *ngIf="isUpdateBtn">Total</th>
                                <th colspan="3"  *ngIf="!isUpdateBtn">Total</th> -->
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="" placeholder="" formControlName="igst1Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="" placeholder="" formControlName="cgst1Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="sgstTotal" placeholder="" formControlName="sgst1Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="cessTotal" placeholder="" formControlName="cess1Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="interestTotal" placeholder="" formControlName="interest1Total"
                                    readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="penaltyTotal" placeholder="" formControlName="penalty1Total" readonly>
                                </td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="penaltyTotal" placeholder="" formControlName="totOfTotal1" readonly>
                                </td>
                                <!-- <td colspan="5">&nbsp;</td>    -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <!-- <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Details of Provisions <a class=" addExtLetter"
                      (click)="addProv()" *ngIf="action == 'update'"><i class="fa fa-plus"></i></a> </label>

                  <div formArrayName="provisionDetails">
                    <div class=" row" *ngFor="let div of provArr().controls; let i=index" id="div{{div}}"
                      [formGroupName]="i">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="exampleInputPassword1">Financial Year</label>
                          <select class="form-control form-select" id="gstin" formControlName="finYear">
                            <ng-container *ngFor="let obj of finyearData">
                              <option [value]="obj">{{obj}}</option>
                            </ng-container>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="exampleInputPassword1">Percentage of Provision</label>
                          <input type="text" class="form-control" id="resPerson	" placeholder=""
                            formControlName="percentageOfProvision">
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="exampleInputPassword1">Amount of Provision</label>
                          <input type="text" class="form-control" id="resPerson	" placeholder=""
                            formControlName="amountOfProvision">
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="exampleInputPassword1">Remove Row</label>
                          <br>
                          <a class="pl5 addExtLetter" (click)="rmProv(i)"
                      *ngIf="isProvAdd"><i class="fa fa-minus"></i></a>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="col-md-12 nopadding">
              <div class="form-group">
                <label for="exampleInputPassword1">Case Summary </label>
                <textarea class="form-control" id="exampleTextarea1" rows="4" formControlName="caseSummary"></textarea>
              </div>
            </div>
            <div class="col-md-12 nopadding">
              <div class="form-group">
                <label for="exampleInputPassword1">Internal Remarks </label>
                <textarea class="form-control" id="exampleTextarea1" rows="4"
                  formControlName="internalRemarks"></textarea>
              </div>
            </div>

            <!-- details of pre deposit -->
            <div *ngIf="caseType != caseTypeData.MEMORANDUM" data-toggle="collapse" data-target="#detailsOfPreDeposite"
              class="expansion-div mb-15" (click)="detailsOfPreDepositeExpanded = !detailsOfPreDepositeExpanded">
              Details of Pre-Deposit Paid <a *ngIf="!isUpdate" class="clrWhite infoIcon"
                title="Upload function will be available after case is saved"><i class="fa fa-info-circle"></i></a>
              <span class="consultant-icons"> <i *ngIf="!detailsOfPreDepositeExpanded" class="fa fa-angle-down"
                  aria-hidden="true"></i> <i *ngIf="detailsOfPreDepositeExpanded" class="fa fa-angle-up"
                  aria-hidden="true"></i></span>
            </div>
            <div id="detailsOfPreDeposite" class="collapse" *ngIf="caseType != caseTypeData.MEMORANDUM">
              <div class="row">
                <div class="col-md- 12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="search-table-outter wrapper">
                          <table class="search-table inner">
                            <thead>
                              <tr class="bgGred">
                                <th class="sl-no-cls">Sr.No.</th>
                                <th>Issue</th>
                                <th>Tag</th>
                                <th>Payment Date</th>
                                <th>Form of Payment</th>
                                <th>Reference / Challan No.</th>
                                <th>Mode of Payment</th>
                                <th>IGST (₹) </th>
                                <th>CGST (₹) </th>
                                <th>SGST (₹)</th>
                                <th>CESS (₹)</th>
                                <th>Interest (₹)</th>
                                <th>Penalty (₹)</th>
                                <th>Total (₹)</th>
                                <th>Accounting Reference</th>
                                <th>Copy of Form</th>
                                <th> Copy of Bank Challan </th>
                                <!-- <th class="upload-column-cls">Supporting Documents</th> -->
                                <th class="ac-no-cls">Action </th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container>

                                <tr *ngFor="let div of detailsOfPreDepositeArr(); let i=index" [formGroup]="div"
                                  (keydown.enter)="$event.preventDefault()">
                                  <td class="sl-no-cls">{{i + 1}} <span *ngIf="div.value.markedForPayment" title="Marked for Interest Calculation"><i class="fa fa-circle isMarked"></i></span></td>
                                  <td>
                                    <select class="form-control form-select" formControlName="issueName" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.issueName}"  [title]="div.value.issueName ? div.value.issueName:''">
                                    <option [value]="null" disabled>-- Select --</option>
                                    <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                      <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                        {{item.name}}</option>
                                    </optgroup>
                                  </select>
                                  <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.issueName">
                                    <span class="text-danger"> Required.</span>
                                  </div>
                                  </td>
                                  <td>
                                    <select class="form-control form-select" id=""  formControlName="tag" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.tag}" [title]="div.value.tag ? div.value.tag:''">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of tagDataList">
                                      <option [value]="obj">{{obj}}</option>
                                    </ng-container>
                                  </select>
                                  <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.tag">
                                    <span class="text-danger"> Required.</span>
                                  </div>
                                  </td>
                                  <td>
                                    <div id="iris-gst-filter-frmDate " class="input-group date datepicker tbldatePicker" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.dateOfPayment}">
                                      <p-calendar appendTo="body" class="form-control" formControlName="dateOfPayment"
                                        dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                        dateFormat="dd-mm-yy"></p-calendar>
                                    </div>
                                    <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.dateOfPayment">
                                      <span class="text-danger"> Required.</span>
                                    </div>
                                  </td>
                                  <td>
                                    <select class="form-control form-select" id="authority"
                                      formControlName="formOfPayment">
                                      <option [value]="null">-- Select --</option>
                                      <ng-container *ngFor="let obj of formOfPayment ">
                                        <option [value]="obj.value">{{obj.label}}</option>
                                      </ng-container>
                                    </select>
                                  </td>
                                  <td><input type="text" class="form-control" id="tax" placeholder="" [ngClass]="{ 'errorBorder': div.value.total && !div.value.challanNo}"
                                      formControlName="challanNo">
                                      <div *ngIf="div.value.total && !div.value.challanNo">
                                        <span class="text-danger"> Required.</span>
                                      </div>
                                      <div *ngIf="div.value.challanNo && getPreDepositCopyFormLength(i) === 0">
                                        <span class="text-danger"> Please Upload  Copy of Form</span>
                                      </div>
                                  </td>

                                  <!-- Mode of payment -->
                                  <td>
                                    <select class="form-control form-select" id="status"
                                      formControlName="modeOfPayment">
                                      <option [value]="null">-- Select --</option>
                                      <option *ngFor="let obj of modeOfPayment" [value]="obj.value">{{obj.value}}
                                      </option>
                                    </select>
                                  </td>

                            <td><input type="text" class="form-control align-right" formControlName="igst" id="igst"
                                      placeholder="" maxlength="19
                                 " InrFormat (paste)="handlePaste($event)" (input)="preDepositeChanged()"></td>
                           <td><input type="text" class="form-control align-right" id="cgst" placeholder=""
                                (input)="preDepositeChanged()" formControlName="cgst" maxlength="19" InrFormat
                                (paste)="handlePaste($event)"></td>
                          <td><input type="text" class="form-control align-right" id="sgst" placeholder=""
                                  (input)="preDepositeChanged()" formControlName="sgst" maxlength="19" InrFormat
                                  (paste)="handlePaste($event)"></td>
                           <td><input type="text" class="form-control align-right" id="cess" placeholder=""
                                   (input)="preDepositeChanged()" formControlName="cess" maxlength="19" InrFormat
                                    (paste)="handlePaste($event)"></td>
                                  <td><input type="text" class="form-control align-right" formControlName="interest"
                                      id="interest" placeholder="" maxlength="19
                              " InrFormat (paste)="handlePaste($event)" (input)="preDepositeChanged()"></td>
                                  <td><input type="text" class="form-control align-right" formControlName="penalty"
                                      id="penalty" placeholder="" maxlength="19
                              " InrFormat (paste)="handlePaste($event)" (input)="preDepositeChanged()"></td>
                                  <td><input type="text" class="form-control align-right" formControlName="total"
                                      id="total" readonly placeholder="" maxlength="19
                              " InrFormat></td>
                              <td>
                                <input type="text" class="form-control" formControlName="accountingRef"
                                [ngClass]="{ 'errorBorder': div.value.accountingRef?.length > 100 }"
                                  [title]="div.value.accountingRef ? div.value.accountingRef:''">
                                <div
                                  *ngIf="div.value.accountingRef && div.value.accountingRef.length > 100">
                                  <span class="text-danger">Max character Length 100</span>
                                </div>
                            </td>

                                  <td>
                                    <div class="row">
                                      <div class="col-sm-9 nopadding">
                                        <span style="cursor: pointer;"
                                          (click)="openPreDepoCopyForm(preDepoCopyForm, i)"> <i
                                            class="icon fa fa fa-paperclip" title="Attach file"
                                            style="cursor: pointer;"></i></span>
                                        <span style="cursor: pointer;" *ngIf="getPreDepositCopyFormLength(i) != 0">
                                          {{getPreDepositCopyFormLength(i)}} Attachment</span>
                                        <ng-template #preDepoCopyForm let-modal>
                                          <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="col-md-12">
                                            <label> Attachment</label>
                                            <div class="table-responsive">
                                              <table class="table table-bordered tablepop">
                                                <thead>
                                                  <tr class="bgGred">
                                                    <th>Upload</th>
                                                    <th style="width:50%;">Remark</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    *ngFor="let preDepoCopyGrp of getPreDepositCopyForm(div); let j = index"
                                                    [formGroup]="preDepoCopyGrp">
                                                    <td>
                                                      <div class="row">
                                                        <div class="col-md-9 nopadding">
                                                          <div class="form-group">
                                                            <input *ngIf="preDepoCopyGrp.value.isdocLocUploadedClicked"
                                                              type="file"
                                                              id="predepositDetails({{i}}).copyOfFormLoc({{j}}).fileLoc"
                                                              class="filetype form-control" name="myfile"
                                                              (change)="onFileSelect($event)">
                                                            <p *ngIf="!preDepoCopyGrp.value.isdocLocUploadedClicked || preDepoCopyGrp.value.fileLoc"
                                                              class="form-control pt10">
                                                              {{getFileNameDta(preDepoCopyGrp.value.fileLoc)}}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-3 nopadding">
                                                          <label for="exampleInputPassword1">
                                                            <a class="downupIcon" *ngIf="preDepoCopyGrp.value.fileLoc"
                                                              title="Download file"
                                                              (click)="download(preDepoCopyGrp.value.fileLoc)"><i
                                                                class="fa fa-download"></i></a>
                                                            <a class="downupIcon"
                                                              *ngIf="!(preDepoCopyGrp.value.fileLoc || preDepoCopyGrp.value.fileLoc)&& isDisableIcons"
                                                              title="Upload file"
                                                              (click)="uploadPreDepoCopyForm(i,j)"><i
                                                                class="fa fa-upload"></i></a>
                                                            <a class="downupIcon" (click)="deletePreDepoCopyForm(i,j)"
                                                              *ngIf="(preDepoCopyGrp.value.fileLoc) && isDisableIcons"><i
                                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <input type="text" class="form-control" formControlName="remarks"
                                                        [title]="preDepoCopyGrp.value.remarks ? preDepoCopyGrp.value.remarks:''">
                                                      <div
                                                        *ngIf="preDepoCopyGrp.value.remarks && preDepoCopyGrp.value.remarks.length > 100">
                                                        <span class="text-danger">Max character Length 100</span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div class="modal-footer" style="border: none; padding-top: 0px;">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="modal.close('Save click')">Save</button>
                                          </div>
                                        </ng-template>
                                        <div *ngIf="(div.get('challanNo')?.value ||  div.get('total')?.value) && getPreDepositCopyFormLength(i) === 0 ">
                                          <span class="text-danger"> Required.</span>
                                        </div>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="row">
                                      <div class="col-sm-9 nopadding">
                                        <span style="cursor: pointer;"
                                          (click)="openPreDepoBankChallanForm(preDepoBankChallan, i)"> <i
                                            class="icon fa fa fa-paperclip" title="Attach file"
                                            style="cursor: pointer;"></i></span>
                                        <span style="cursor: pointer;" *ngIf="getPreDepositBankChallanLength(i) != 0">
                                          {{getPreDepositBankChallanLength(i)}} Attachment</span>

                                        <ng-template #preDepoBankChallan let-modal>
                                          <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="col-md-12">
                                            <label> Attachment</label>
                                            <div class="table-responsive">
                                              <table class="table table-bordered tablepop">
                                                <thead>
                                                  <tr class="bgGred">
                                                    <th>Upload</th>
                                                    <th style="width:50%;">Remark</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    *ngFor="let preDepoBankGrp of getPreDepositBankChallan(div); let j = index"
                                                    [formGroup]="preDepoBankGrp">
                                                    <td>
                                                      <div class="row">
                                                        <div class="col-md-9 nopadding">
                                                          <div class="form-group">
                                                            <input *ngIf="preDepoBankGrp.value.isdocLocUploadedClicked"
                                                              type="file"
                                                              id="predepositDetails({{i}}).copyOfBankChallanLoc({{j}}).fileLoc"
                                                              class="filetype form-control" name="myfile"
                                                              (change)="onFileSelect($event)">
                                                            <p *ngIf="!preDepoBankGrp.value.isdocLocUploadedClicked || preDepoBankGrp.value.fileLoc"
                                                              class="form-control pt10">
                                                              {{getFileNameDta(preDepoBankGrp.value.fileLoc)}}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-3 nopadding">
                                                          <label for="exampleInputPassword1">
                                                            <a class="downupIcon" *ngIf="preDepoBankGrp.value.fileLoc"
                                                              title="Download file"
                                                              (click)="download(preDepoBankGrp.value.fileLoc)"><i
                                                                class="fa fa-download"></i></a>
                                                            <a class="downupIcon"
                                                              *ngIf="!(preDepoBankGrp.value.fileLoc || preDepoBankGrp.value.fileLoc)&& isDisableIcons"
                                                              title="Upload file"
                                                              (click)="uploadPreDepoBankChallan(i,j)"><i
                                                                class="fa fa-upload"></i></a>
                                                            <a class="downupIcon"
                                                              (click)="deletePreDepoBankChallan(i,j)"
                                                              *ngIf="(preDepoBankGrp.value.fileLoc)&& isDisableIcons"><i
                                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <input type="text" class="form-control" formControlName="remarks"
                                                        [title]="preDepoBankGrp.value.remarks ? preDepoBankGrp.value.remarks:''">
                                                      <div
                                                        *ngIf="preDepoBankGrp.value.remarks && preDepoBankGrp.value.remarks.length > 100">
                                                        <span class="text-danger">Max character Length 100</span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div class="modal-footer" style="border: none; padding-top: 0px;">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="modal.close('Save click')">Save</button>
                                          </div>
                                        </ng-template>
                                      </div>
                                    </div>
                                  </td>

                                  <td class="ac-no-cls"><a class=" addExtLetter" (click)="addDetailsOfPreDeposite()"><i
                                        class="fa fa-plus"></i></a>
                                    <a *ngIf="i != 0" class="addExtLetter"
                                      (click)="openRemoveDetailsOfPreDepositePop(rmvDetailsOfPreDepositeRef)"><i
                                        class="fa fa-minus"></i></a>
                                  </td>
                                  <ng-template #rmvDetailsOfPreDepositeRef let-modal>
                                    <div class="modal-header">
                                      <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                      <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <p>Are you sure, you want to remove?</p>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-outline-dark"
                                        (click)="rmDetailsOfPreDeposite(i)">Remove</button>
                                    </div>
                                  </ng-template>
                                </tr>

                                <tr>
                                  <!-- <td class="sl-no-cls"></td> -->
                                  <th *ngIf="isUpdate" colspan="7" style="text-align: center;">Total</th>
                                  <th *ngIf="!isUpdate" colspan="7" style="text-align: center;">Total</th>
                                  <td class="align-right bold-font">{{preDepositeTaxTotal | INR: 0}}</td>
                                  <td class="align-right bold-font">{{preDepositeCgstTotal | INR : 0}}</td>
                                  <td class="align-right bold-font">{{preDepositeSgstTotal | INR : 0}}</td>
                                  <td class="align-right bold-font">{{preDepositeCessTotal | INR : 0}}</td>
                                  <td class="align-right bold-font">{{preDepositeInterestTotal | INR: 0}}</td>
                                  <td class="align-right bold-font">{{preDepositePenaltyTotal | INR: 0}}</td>
                                  <td class="align-right bold-font">{{preDepositeTotal | INR: 0}}</td>
                                </tr>
                              </ng-container>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- details of pre deposit -->

            <!-- details of protest details -->
            <div data-toggle="collapse" data-target="#protestDetails" class="expansion-div mb-15"
              (click)="protestDetailsExpanded = !protestDetailsExpanded">
              Details of Payments Made Under Protest
              <span class="consultant-icons"> <i *ngIf="!protestDetailsExpanded" class="fa fa-angle-down"
                  aria-hidden="true"></i> <i *ngIf="protestDetailsExpanded" class="fa fa-angle-up"
                  aria-hidden="true"></i></span>
            </div>
            <div id="protestDetails" class="collapse">
              <div class="row">
                <div class="col-md- 12">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="search-table-outter wrapper">
                          <table class="search-table inner">
                            <thead>
                              <tr class="bgGred">
                                <th class="sl-no-cls">Sr.No.</th>
                                <th>Issue</th>
                                <th>Tag</th>
                                <th>Payment Date</th>
                                <th>Form of Payment</th>
                                <th>Reference / Challan No.</th>
                                <th>Mode of Payment</th>
                                <th>IGST (₹)</th>
                                <th>CGST (₹)</th>
                                <th>SGST (₹)</th>
                                <th>CESS (₹)</th>
                                <th>Interest (₹)</th>
                                <th>Penalty (₹)</th>
                                <th>Total (₹)</th>
                                <th>Accounting Reference</th>
                                <th>Copy of Form</th>
                                <th>Copy of Bank Challan</th>
                                <th class="ac-no-cls">Action </th>
                              </tr>
                            </thead>
                            <tbody>
                              <ng-container>

                                <tr *ngFor="let div of getDetailOfPaymentArr(); let i=index" [formGroup]="div"
                                  (keydown.enter)="$event.preventDefault()">
                                  <td class="sl-no-cls">{{i + 1}} <span *ngIf="div.value.markedForPayment" title="Marked for Interest Calculation"><i class="fa fa-circle isMarked"></i></span></td>
                                  <td>
                                    <select class="form-control form-select" formControlName="issueName" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.issueName}" [title]="div.value.issueName ? div.value.issueName:''">
                                    <option [value]="null" disabled>-- Select --</option>
                                    <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                      <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                        {{item.name}}</option>
                                    </optgroup>
                                  </select>
                                  <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.issueName">
                                    <span class="text-danger"> Required.</span>
                                  </div>
                                  </td>
                                  <td>
                                    <select class="form-control form-select" id=""  formControlName="tag" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.tag}" [title]="div.value.tag ? div.value.tag:''">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of tagDataList">
                                      <option [value]="obj">{{obj}}</option>
                                    </ng-container>
                                  </select>
                                  <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.tag">
                                    <span class="text-danger"> Required.</span>
                                  </div>
                                  </td>
                                  <td>
                                    <div id="iris-gst-filter-frmDate " class="input-group date datepicker tbldatePicker" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.dateOfPayment}">
                                      <p-calendar appendTo="body" class="form-control" formControlName="dateOfPayment"
                                        dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                        [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                        dateFormat="dd-mm-yy"></p-calendar>
                                    </div>
                                    <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.dateOfPayment">
                                      <span class="text-danger"> Required.</span>
                                    </div>
                                  </td>
                                  <td>
                                    <select class="form-control form-select" id="status"
                                      formControlName="formOfPayment">
                                      <option [value]="null">-- Select --</option>
                                      <option *ngFor="let obj of formOfPayment" [value]="obj.value">{{obj.label}}
                                      </option>
                                    </select>
                                  </td>
                                  <!-- Challan No. / Ref. No. -->
                                  <td>
                                    <input class="form-control" id="challanNo" formControlName="challanNo" [ngClass]="{ 'errorBorder': div.value.total && !div.value.challanNo}"
                                      [ngClass]="{ 'errorBorder': isSubmitted && div.get('copyOfFormLoc')?.errors  }" />
                                      <div *ngIf="div.value.total && !div.value.challanNo">
                                        <span class="text-danger"> Required.</span>
                                      </div>
                                      <div *ngIf="div.value.challanNo && getProtestCopyLength(i) === 0">
                                        <span class="text-danger"> Please Upload  Copy of Form</span>
                                      </div>
                                  </td>
                                  <!-- Mode of payment -->
                                  <td>
                                    <select class="form-control form-select" id="status"
                                      formControlName="modeOfPayment">
                                      <option [value]="null">-- Select --</option>
                                      <option *ngFor="let obj of modeOfPayment" [value]="obj.value">{{obj.key}}</option>
                                    </select>
                                  </td>
                                  <td><input type="text" class="form-control align-right" formControlName="igst" id="igst"
                                      placeholder="" maxlength="19
                                          " InrFormat (paste)="handlePaste($event)" (input)="protestDetailsChanged()">
                                  </td>
                                  <td><input type="text" class="form-control align-right" id="cgst" placeholder=""
                                    (input)="protestDetailsChanged()" formControlName="cgst" maxlength="19" InrFormat
                                    (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" id="sgst" placeholder=""
                                      (input)="protestDetailsChanged()" formControlName="sgst" maxlength="19" InrFormat
                                      (paste)="handlePaste($event)"></td>
                               <td><input type="text" class="form-control align-right" id="cess" placeholder=""
                                       (input)="protestDetailsChanged()" formControlName="cess" maxlength="19" InrFormat
                                        (paste)="handlePaste($event)"></td>
                                  <td><input type="text" class="form-control align-right" formControlName="interest"
                                      id="interest" placeholder="" maxlength="19
                                          " InrFormat (paste)="handlePaste($event)" (input)="protestDetailsChanged()">
                                  </td>
                                  <td><input type="text" class="form-control align-right" formControlName="penalty"
                                      id="penalty" placeholder="" maxlength="19
                                          " InrFormat (paste)="handlePaste($event)" (input)="protestDetailsChanged()">
                                  </td>
                                  <td><input type="text" class="form-control align-right" formControlName="total"
                                      id="total" readonly placeholder="" maxlength="19
                                          " InrFormat></td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="accountingRef"
                                            [ngClass]="{ 'errorBorder': div.value.accountingRef?.length > 100 }"
                                              [title]="div.value.accountingRef ? div.value.accountingRef:''">
                                            <div
                                              *ngIf="div.value.accountingRef && div.value.accountingRef.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                        </td>

                                  <!-- copyOfFormLoc attachment -->
                                  <td>
                                    <div class="row">
                                      <div class="col-sm-9 nopadding">
                                        <span style="cursor: pointer;"
                                          (click)="openProtestCopyForm(protestCopyForm, i)"> <i
                                            class="icon fa fa fa-paperclip" title="Attach file"
                                            style="cursor: pointer;"></i></span>
                                        <span *ngIf="getProtestCopyLength(i) != 0"> {{getProtestCopyLength(i)}}
                                          Attachment</span>

                                        <ng-template #protestCopyForm let-modal>
                                          <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="col-md-12">
                                            <label> Attachment</label>
                                            <div class="table-responsive">
                                              <table class="table table-bordered tablepop">
                                                <thead>
                                                  <tr class="bgGred">
                                                    <th>Upload</th>
                                                    <th style="width:50%;">Remark</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    *ngFor="let protestCopyFrm of getProtestCopyForm(div); let j = index"
                                                    [formGroup]="protestCopyFrm">
                                                    <td>
                                                      <div class="row">
                                                        <div class="col-md-9 nopadding">
                                                          <div class="form-group">
                                                            <input *ngIf="protestCopyFrm.value.isdocLocUploadedClicked"
                                                              type="file"
                                                              id="protestDetails({{i}}).copyOfFormLoc({{j}}).fileLoc"
                                                              class="filetype form-control" name="myfile"
                                                              (change)="onFileSelect($event)">
                                                            <p *ngIf="!protestCopyFrm.value.isdocLocUploadedClicked || protestCopyFrm.value.fileLoc"
                                                              class="form-control pt10">
                                                              {{getFileNameDta(protestCopyFrm.value.fileLoc)}}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-3 nopadding">
                                                          <label for="exampleInputPassword1">
                                                            <a class="downupIcon" *ngIf="protestCopyFrm.value.fileLoc"
                                                              title="Download file"
                                                              (click)="download(protestCopyFrm.value.fileLoc)"><i
                                                                class="fa fa-download"></i></a>
                                                            <a class="downupIcon"
                                                              *ngIf="!(protestCopyFrm.value.fileLoc || protestCopyFrm.value.fileLoc)&& isDisableIcons"
                                                              title="Upload file"
                                                              (click)="uploadProtestCopyForm(i,j)"><i
                                                                class="fa fa-upload"></i></a>
                                                            <a class="downupIcon" (click)="deleteProtestCopyForm(i,j)"
                                                              *ngIf="(protestCopyFrm.value.fileLoc)&& isDisableIcons"><i
                                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <input type="text" class="form-control" formControlName="remarks"
                                                        [title]="protestCopyFrm.value.remarks ? protestCopyFrm.value.remarks:''">
                                                      <div
                                                        *ngIf="protestCopyFrm.value.remarks && protestCopyFrm.value.remarks.length > 100">
                                                        <span class="text-danger">Max character Length 100</span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div class="modal-footer" style="border: none; padding-top: 0px;">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="modal.close('Save click')">Save</button>
                                          </div>
                                        </ng-template>
                                      </div>
                                  
                                      <div *ngIf="(div.get('challanNo')?.value ||  div.get('total')?.value) && getProtestCopyLength(i) === 0 ">
                                        <span class="text-danger"> Required.</span>
                                      </div>
                                    </div>
                                  </td>
                                  <!-- copyOfFormLoc attachment ends -->
                                  <!-- copyOfBankChallanLoc attachment -->
                                  <td>
                                    <div class="row">
                                      <div class="col-sm-9 nopadding">
                                        <span style="cursor: pointer;"
                                          (click)="openProtestBankChallan(protestBankChallan, i)"> <i
                                            class="icon fa fa fa-paperclip" title="Attach file"
                                            style="cursor: pointer;"></i></span>
                                        <span *ngIf="getProtestBankChallanLength(i) != 0">
                                          {{getProtestBankChallanLength(i)}} Attachment</span>
                                        <ng-template #protestBankChallan let-modal>
                                          <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                            <button type="button" class="close" aria-label="Close"
                                              (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div class="col-md-12">
                                            <label> Attachment</label>
                                            <div class="table-responsive">
                                              <table class="table table-bordered tablepop">
                                                <thead>
                                                  <tr class="bgGred">
                                                    <th>Upload</th>
                                                    <th style="width:50%;">Remark</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr
                                                    *ngFor="let protestBankGrp of getProtestBankChallan(div); let j = index"
                                                    [formGroup]="protestBankGrp">
                                                    <td>
                                                      <div class="row">
                                                        <div class="col-md-9 nopadding">
                                                          <div class="form-group">
                                                            <input *ngIf="protestBankGrp.value.isdocLocUploadedClicked"
                                                              type="file"
                                                              id="protestDetails({{i}}).copyOfBankChallanLoc({{j}}).fileLoc"
                                                              class="filetype form-control" name="myfile"
                                                              (change)="onFileSelect($event)">
                                                            <p *ngIf="!protestBankGrp.value.isdocLocUploadedClicked || protestBankGrp.value.fileLoc"
                                                              class="form-control pt10">
                                                              {{getFileNameDta(protestBankGrp.value.fileLoc)}}
                                                            </p>
                                                          </div>
                                                        </div>
                                                        <div class="col-md-3 nopadding">
                                                          <label for="exampleInputPassword1">
                                                            <a class="downupIcon" *ngIf="protestBankGrp.value.fileLoc"
                                                              title="Download file"
                                                              (click)="download(protestBankGrp.value.fileLoc)"><i
                                                                class="fa fa-download"></i></a>
                                                            <a class="downupIcon"
                                                              *ngIf="!(protestBankGrp.value.fileLoc || protestBankGrp.value.fileLoc)&& isDisableIcons"
                                                              title="Upload file"
                                                              (click)="uploadProtestBankChallan(i,j)"><i
                                                                class="fa fa-upload"></i></a>
                                                            <a class="downupIcon"
                                                              (click)="deleteProtestBankChallan(i,j)"
                                                              *ngIf="(protestBankGrp.value.fileLoc)&& isDisableIcons"><i
                                                                class="fa fa-trash" aria-hidden="true"></i></a>
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <input type="text" class="form-control" formControlName="remarks"
                                                        [title]="protestBankGrp.value.remarks ? protestBankGrp.value.remarks:''">
                                                      <div
                                                        *ngIf="protestBankGrp.value.remarks && protestBankGrp.value.remarks.length > 100">
                                                        <span class="text-danger">Max character Length 100</span>
                                                      </div>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                          <div class="modal-footer" style="border: none; padding-top: 0px;">
                                            <button type="button" class="btn btn-outline-dark"
                                              (click)="modal.close('Save click')">Save</button>
                                          </div>
                                        </ng-template>
                                      </div>
                                    </div>
                                  </td>
                                  <!-- copyOfBankChallanLoc attachment ends -->

                                  <td class="ac-no-cls"><a class=" addExtLetter" (click)="addDetailOfPayment()"><i
                                        class="fa fa-plus"></i></a>
                                    <a class="addExtLetter" *ngIf="i !=0"
                                      (click)="openRemoveDetailsOfPaymentPop(rmvDetailsOfPaymentRef)"><i
                                        class="fa fa-minus"></i></a>
                                  </td>
                                  <ng-template #rmvDetailsOfPaymentRef let-modal>
                                    <div class="modal-header">
                                      <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                      <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')">
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="modal-body">
                                      <p>Are you sure, you want to remove?</p>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btn btn-outline-dark"
                                        (click)="rmPaymentUnderProtest(i)">Remove</button>
                                    </div>
                                  </ng-template>
                                </tr>

                                <tr>
                                  <th class="align-center" *ngIf="isUpdate" colspan="7">Total</th>
                                  <th class="align-center" *ngIf="!isUpdate" colspan="7">Total</th>
                                  <td class="align-right bold-font">{{protestTaxTotal | INR: 0}}</td>
                                  <td class="align-text-right bold-font">{{protestcgstTotal | INR : 0}}</td>
                                  <td class="align-text-right bold-font">{{protestsgstTotal | INR : 0}}</td>
                                  <td class="align-text-right bold-font">{{protestcessTotal | INR : 0}}</td>
                                  <td class="align-right bold-font">{{protestInterestTotal | INR: 0}}</td>
                                  <td class="align-right bold-font">{{protestPenaltyTotal | INR: 0}}</td>
                                  <td class="align-right bold-font">{{protestTotal | INR: 0}}</td>
                                </tr>
                              </ng-container>

                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- details of protest deatils -->
            <!-- <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Amount under appeal </label>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tr>
                        <th>Particulars</th>
                        <th>Tax</th>
                        <th>Interest</th>
                        <th>Penalty</th>
                      </tr>
                      <tr>
                        <th>IGST</th>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                            formControlName="igstTax1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder':p1.igstTax1.errors }">
                          <div *ngIf="p1.igstTax1.errors">
                            <span class="text-danger" *ngIf="p1.igstTax1.errors.pattern"> Numberic Value allowed</span>
                          </div>
                        </td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="igstInterest1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder':p1.igstInterest1.errors }">
                        <div *ngIf="p1.igstInterest1.errors">
                          <span class="text-danger" *ngIf="p1.igstInterest1.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="igstPenalty1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder':p1.igstPenalty1.errors }">
                        <div *ngIf="p1.igstPenalty1.errors">
                          <span class="text-danger" *ngIf="p1.igstPenalty1.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                      </tr>
                      <tr>
                        <th>CGST</th>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                            formControlName="cgstTax1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder': p1.cgstTax1.errors }">
                          <div *ngIf="p1.cgstTax1.errors">
                            <span class="text-danger" *ngIf="p1.cgstTax1.errors.pattern"> Numberic Value allowed</span>
                          </div>
                        </td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="cgstInterest1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder': p1.cgstInterest1.errors }">
                        <div *ngIf="p1.cgstInterest1.errors">
                          <span class="text-danger" *ngIf="p1.cgstInterest1.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="cgstPenalty1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder': p1.cgstPenalty1.errors }">
                        <div *ngIf="p1.cgstPenalty1.errors">
                          <span class="text-danger" *ngIf="p1.cgstPenalty1.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                      </tr>
                      <tr>
                        <th>SGST/UTGST</th>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                            formControlName="sgstTax1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder':p1.sgstTax1.errors }">
                          <div *ngIf="p1.sgstTax1.errors">
                            <span class="text-danger" *ngIf="p1.sgstTax1.errors.pattern"> Numberic Value allowed</span>
                          </div>
                        </td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="sgstInterest1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder':p1.sgstInterest1.errors }">
                        <div *ngIf="p1.sgstInterest1.errors">
                          <span class="text-danger" *ngIf="p1.sgstInterest1.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="sgstPenalty1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder': p1.sgstPenalty1.errors }">
                        <div *ngIf="p1.sgstPenalty1.errors">
                          <span class="text-danger" *ngIf="p1.sgstPenalty1.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                      </tr>
                      <tr>
                        <th>Cess</th>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                            formControlName="cessTax1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder': p1.cessTax1.errors }">
                          <div *ngIf="p1.cessTax1.errors">
                            <span class="text-danger" *ngIf="p1.cessTax1.errors.pattern"> Numberic Value allowed</span>
                          </div>
                        </td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="cessInterest1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view','errorBorder': p1.cessInterest1.errors }">
                        <div *ngIf="p1.cessInterest1.errors">
                          <span class="text-danger" *ngIf="p1.cessInterest1.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                          formControlName="cessPenalty1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view', 'errorBorder': p1.cessPenalty1.errors }">
                        <div *ngIf="p1.cessPenalty1.errors">
                          <span class="text-danger" *ngIf="p1.cessPenalty1.errors.pattern"> Numberic Value allowed</span>
                        </div></td>
                      </tr>
                      <tr *ngIf="action == 'view' || action == 'update'">
                        <th>Total</th>
                        <td><input type="text" class="form-control" id="oioNum" placeholder=""
                            formControlName="totalTax1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view'}" readonly>
                        </td>
                        <td><input type="text" class="form-control" id="tax" placeholder=""
                          formControlName="totalInterest1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view'}" readonly></td>
                        <td><input type="text" class="form-control" id="tax" placeholder=""
                          formControlName="totalPenalty1"  [options]="{ prefix: '', thousands: ',', precision: 0 }" [ngClass]="{'txtRight ': action == 'view'}" readonly></td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="col-md-12 mt15 nopadding">
              <button class="btn btn-outline-primary mr-1" (click)="createP1()" *ngIf="isCreateBtn">Save & Next</button>
              <button class="btn btn-outline-primary mr-1" (click)="updateLetter('p1')"
                *ngIf="isUpdateBtn">Update</button>
              <!-- <button class="btn btn-outline-primary mr-1">Delete</button> -->
            </div>
          </fieldset>
        </div>

      </div>
    </div>
    <div class="tab-pane fade show active" id="AppelTribp2-1" role="tabpanel" aria-labelledby="AppelTribp2-tab">
      <div class="card borderOrange" [hidden]="(selectedTab === 'tab1') || (selectedTab === 'tab3')"
        [ngClass]="{'bgGray': (action == 'view')}">
        <div class="card-body">
          <fieldset [disabled]="action == 'view'">
            <div class="row">
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Date of submission <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfSubmission"
                      [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate"
                      yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfSubmission.errors }">
                    </p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfSubmission.errors">
                    <span class="text-danger" *ngIf="p1.dateOfSubmission.errors.required"> Required</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Consultant</label>
                  <input type="text" class="form-control" id="" placeholder="" formControlName="consultant2">
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Person responsible <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="personResponsible"  *ngIf="navContext?.entityType == 'FILING'" formControlName="personResponsible2" [ngClass]="{ 'errorBorder': isSubmitted && p1.personResponsible2.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of userRoleLs">
                      <option [value]="obj.userEmailId">{{obj.userEmailId}}</option>
                    </ng-container>
                  </select>
                  <input type="text" class="form-control" *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'" placeholder="" formControlName="personResponsible2">
                  <div *ngIf="isSubmitted && p1.personResponsible2.errors">
                    <span class="text-danger" *ngIf="p1.personResponsible2.errors.required"> Required
                    </span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Copy of the acknowledgement <span class="mandate"><i
                    class="fa fa-asterisk"></i></span> <a class="clrOrange infoIcon" title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                   <a class="downupIcon" *ngIf="isDWcopyOfAckLoc && p1.copyOfAckLoc.value" title="Download file"
                      (click)="download(p1.copyOfAckLoc.value)"><i class="fa fa-download"></i></a>
                    <a class="downupIcon" *ngIf="isUPcopyOfAckLoc && !(action == 'update' && getAccessTabData.aptFlag)" title="Upload file" (click)="uploadcopyOfAckLoc()"><i
                        class="fa fa-upload"></i></a>
                  </label>
                  <input type="file" [hidden]="!iscopyOfAckLocFile" id="copyOfAckLoc" class="filetype form-control"
                    name="myfile" (change)='onFileSelect($event)' [ngClass]="{ 'errorBorder': isSubmitted && copyOfAckLocReq }">
                  <input [hidden]="!iscopyOfAckLocText" readonly type="text" class="filetype form-control" name="myfile"
                    formControlName="copyOfAckLocFN">
                    <p [hidden]="!iscopyOfAckLocText" class="form-control pt10" [ngClass]="{ 'errorBorder': isSubmitted && copyOfAckLocReq }">{{getFileNameDta(p1.copyOfAckLoc.value)}}</p>
                  <div *ngIf="isSubmitted && copyOfAckLocReq">
                    <span class="text-danger" *ngIf="copyOfAckLocReq"> Required</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Other Attachments  <a class="clrOrange infoIcon" title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                    <a class="downupIcon" *ngIf="isDWotherAttachmentsLoc2 && p1.otherAttachmentsLoc2.value"
                      title="Download file" (click)="download(p1.otherAttachmentsLoc2.value)"><i
                        class="fa fa-download"></i></a>
                    <a class="downupIcon" *ngIf="isUPotherAttachmentsLoc2 && !(action == 'update' && getAccessTabData.aptFlag)" title="Upload file"
                      (click)="uploadotherAttachmentsLoc2()"><i class="fa fa-upload"></i></a>
                  </label>
                  <input type="file" [hidden]="!isotherAttachmentsLoc2File" id="otherAttachmentsLoc2"
                    class="filetype form-control" name="myfile" (change)='onFileSelect($event)'>
                  <input [hidden]="!isotherAttachmentsLoc2Text" readonly type="text" class="filetype form-control"
                    name="myfile" formControlName="otherAttachmentsLoc2FN">
                    <p [hidden]="!isotherAttachmentsLoc2Text" class="form-control pt10" >{{getFileNameDta(p1.otherAttachmentsLoc2.value)}}</p>
                </div>
              </div> -->
            </div>
            <!-- details of appeal -->
            <div *ngIf="caseType != caseTypeData.MEMORANDUM" data-toggle="collapse" data-target="#deatilsOfAppeal"
              class="expansion-div mb-15" (click)="deatilsOfAppealExpanded = !deatilsOfAppealExpanded"> Details of
              Appeal <span class="consultant-icons"> <i *ngIf="!deatilsOfAppealExpanded" class="fa fa-angle-down"
                  aria-hidden="true"></i> <i *ngIf="deatilsOfAppealExpanded" class="fa fa-angle-up"
                  aria-hidden="true"></i></span></div>
            <div *ngIf="caseType == caseTypeData.MEMORANDUM" data-toggle="collapse" data-target="#deatilsOfAppeal"
              class="expansion-div mb-15" (click)="deatilsOfAppealExpanded = !deatilsOfAppealExpanded"> Details of Cross
              Objection <span class="consultant-icons"> <i *ngIf="!deatilsOfAppealExpanded" class="fa fa-angle-down"
                  aria-hidden="true"></i> <i *ngIf="deatilsOfAppealExpanded" class="fa fa-angle-up"
                  aria-hidden="true"></i></span></div>

            <div id="deatilsOfAppeal" class="collapse show multi-collapse" state='opened'>
              <div class="row mb-15">
                <div class="col-md-12">
                  <div class="search-table-outter wrapper">
                    <table class="search-table inner">
                      <thead>
                        <tr class="bgGred">
                          <th class="sl-no-cls">Sr.No.</th>
                          <th class="col-md-3">Form Type</th>
                          <th class="col-md-3">Ref. No.<span class="mandate top7"><i class="fa fa-asterisk"></i></span>
                          </th>
                          <th class="col-md-3" *ngIf="caseType != caseTypeData.MEMORANDUM">Date of Filing Appeal <span
                              class="mandate top7"><i class="fa fa-asterisk"></i></span></th>
                          <th class="col-md-3" *ngIf="caseType == caseTypeData.MEMORANDUM">Date of Filing Cross
                            Objection
                            <span class="mandate top7"><i class="fa fa-asterisk"></i></span>
                          </th>
                          <th class="col-md-2" *ngIf="caseType !== caseTypeData.MEMORANDUM">Mode of Appeal</th>
                          <th class="col-md-2" *ngIf="caseType === caseTypeData.MEMORANDUM">Mode of Submission</th>
                          <th class="col-md-3">Copy of Acknowledgement <span class="mandate top7"><i
                                class="fa fa-asterisk"></i></span></th>
                          <th class="col-md-3">Other Attachments</th>
                          <th class="ac-no-cls"> Action</th>
                        </tr>

                      </thead>
                      <tbody>
                        <ng-container>
                          <tr *ngFor="let div of getDetailOfAppeal(); let i=index" [formGroup]="div"
                            (keydown.enter)="$event.preventDefault()">
                            <td class="sl-no-cls">{{i + 1}}</td>
                            <td>
                              <select class="form-control form-select" id="formType" formControlName="formType">
                                <option [value]="null">-- Select --</option>
                                <option *ngFor="let obj of detailsOfAppealFormType" [value]="obj.value">{{obj.key}}
                                </option>
                              </select>
                            </td>
                            <td>
                              <input class="form-control " id="appealRefNo" formControlName="appealRefNo"
                                [title]="div.value.appealRefNo ? div.value.appealRefNo:''"
                                [ngClass]="{ 'errorBorder': isSubmitted && div.get('appealRefNo')?.errors  }" />
                              <div *ngIf="isSubmitted && div.get('appealRefNo')?.errors">
                                <span class="text-danger" *ngIf="div.get('appealRefNo')?.errors?.required "> Required
                                </span>
                              </div>
                            </td>
                            <td>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker tbldatePicker"
                                [ngClass]="{ 'errorBorder': isSubmitted && div.get('filingAppealDate')?.errors  }">
                                <p-calendar appendTo="body" class="form-control" formControlName="filingAppealDate"
                                  [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate"
                                  yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                  dataType="string">
                                </p-calendar>
                              </div>
                              <div *ngIf="isSubmitted && div.get('filingAppealDate')?.errors">
                                <span class="text-danger" *ngIf="div.get('filingAppealDate')?.errors?.required ">
                                  Required
                                </span>
                              </div>
                            </td>
                            <td>
                              <select class="form-control form-select" id="appealMode" formControlName="appealMode">
                                <option [value]="null">-- Select --</option>
                                <option *ngFor="let obj of modeOfAppealOptions" [value]="obj.value">{{obj.value}}
                                </option>
                              </select>
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-sm-9 nopadding">
                                  <span style="cursor: pointer;" (click)="openAppealAckCopy(ackCopyLoc, i)"> <i
                                      class="icon fa fa fa-paperclip" title="Attach file"
                                      style="cursor: pointer;"></i></span>
                                  <span *ngIf="getAppealAckLocLength(i) != 0"> {{getAppealAckLocLength(i)}}
                                    Attachment</span>

                                  <ng-template #ackCopyLoc let-modal>
                                    <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                      <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="col-md-12">
                                      <label> Attachment</label>
                                      <div class="table-responsive">
                                        <table class="table table-bordered tablepop">
                                          <thead>
                                            <tr class="bgGred">
                                              <th>Upload</th>
                                              <th style="width:50%;">Remark</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngFor="let ackCopyLocGrp of getAppealAckLoc(div); let j = index"
                                              [formGroup]="ackCopyLocGrp">
                                              <td>
                                                <div class="row">
                                                  <div class="col-md-9 nopadding">
                                                    <div class="form-group">
                                                      <input *ngIf="ackCopyLocGrp.value.isdocLocUploadedClicked"
                                                        type="file" id="appealDetails({{i}}).ackCopyLoc({{j}}).fileLoc"
                                                        class="filetype form-control" name="myfile"
                                                        (change)="onFileSelect($event)">
                                                      <p *ngIf="!ackCopyLocGrp.value.isdocLocUploadedClicked || ackCopyLocGrp.value.fileLoc"
                                                        class="form-control pt10">
                                                        {{getFileNameDta(ackCopyLocGrp.value.fileLoc)}}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-3 nopadding">
                                                    <label for="exampleInputPassword1">
                                                      <a class="downupIcon" *ngIf="ackCopyLocGrp.value.fileLoc"
                                                        title="Download file"
                                                        (click)="download(ackCopyLocGrp.value.fileLoc)"><i
                                                          class="fa fa-download"></i></a>
                                                      <a class="downupIcon"
                                                        *ngIf="!(ackCopyLocGrp.value.fileLoc || ackCopyLocGrp.value.fileLoc)&& isDisableIcons"
                                                        title="Upload file" (click)="uploadAppealAckCopy(i,j)"><i
                                                          class="fa fa-upload"></i></a>
                                                      <a class="downupIcon" (click)="deleteAppealAckCopy(i,j)"
                                                        *ngIf="(ackCopyLocGrp.value.fileLoc)&& isDisableIcons"><i
                                                          class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </label>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control" formControlName="remarks"
                                                  [title]="ackCopyLocGrp.value.remarks ? ackCopyLocGrp.value.remarks:''">
                                                <div
                                                  *ngIf="ackCopyLocGrp.value.remarks && ackCopyLocGrp.value.remarks.length > 100">
                                                  <span class="text-danger">Max character Length 100</span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="modal-footer" style="border: none; padding-top: 0px;">
                                      <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Save</button>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>
                              <div *ngIf="isSubmitted && getAppealAckLocLength(i) === 0">
                                <span class="text-danger"> Required</span>
                              </div>
                            </td>

                            <td>
                              <!-- other attachment -->
                              <div class="row">
                                <div class="col-sm-9 nopadding">
                                  <span style="cursor: pointer;" (click)="openAppealOtherAttach(otherAttachLoc, i)"> <i
                                      class="icon fa fa fa-paperclip" title="Attach file"
                                      style="cursor: pointer;"></i></span>
                                  <span *ngIf="getAppealOtherAttachLocLength(i) != 0">
                                    {{getAppealOtherAttachLocLength(i)}} Attachment</span>

                                  <ng-template #otherAttachLoc let-modal>
                                    <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                      <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="col-md-12">
                                      <label> Attachment</label>
                                      <div class="table-responsive">
                                        <table class="table table-bordered tablepop">
                                          <thead>
                                            <tr class="bgGred">
                                              <th>Upload</th>
                                              <th style="width:50%;">Remark</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              *ngFor="let otherAttachGrp of getAppealOtherAttachLoc(div); let j = index"
                                              [formGroup]="otherAttachGrp">
                                              <td>
                                                <div class="row">
                                                  <div class="col-md-9 nopadding">
                                                    <div class="form-group">
                                                      <input *ngIf="otherAttachGrp.value.isdocLocUploadedClicked"
                                                        type="file"
                                                        id="appealDetails({{i}}).otherAttachmentsLoc({{j}}).fileLoc"
                                                        class="filetype form-control" name="myfile"
                                                        (change)="onFileSelect($event)">
                                                      <p *ngIf="!otherAttachGrp.value.isdocLocUploadedClicked || otherAttachGrp.value.fileLoc"
                                                        class="form-control pt10">
                                                        {{getFileNameDta(otherAttachGrp.value.fileLoc)}}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-3 nopadding">
                                                    <label for="exampleInputPassword1">
                                                      <a class="downupIcon" *ngIf="otherAttachGrp.value.fileLoc"
                                                        title="Download file"
                                                        (click)="download(otherAttachGrp.value.fileLoc)"><i
                                                          class="fa fa-download"></i></a>
                                                      <a class="downupIcon"
                                                        *ngIf="!(otherAttachGrp.value.fileLoc || otherAttachGrp.value.fileLoc)&& isDisableIcons"
                                                        title="Upload file" (click)="uploadAppealOtherAttach(i,j)"><i
                                                          class="fa fa-upload"></i></a>
                                                      <a class="downupIcon" (click)="deleteAppealOtherAttach(i,j)"
                                                        *ngIf="(otherAttachGrp.value.fileLoc)&& isDisableIcons"><i
                                                          class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </label>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control" formControlName="remarks"
                                                  [title]="otherAttachGrp.value.remarks ? otherAttachGrp.value.remarks:''">
                                                <div
                                                  *ngIf="otherAttachGrp.value.remarks && otherAttachGrp.value.remarks.length > 100">
                                                  <span class="text-danger">Max character Length 100</span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="modal-footer" style="border: none; padding-top: 0px;">
                                      <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Save</button>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>
                              <!-- other attachment ends -->
                            </td>

                            <td class="ac-no-cls">
                              <a class=" addExtLetter" (click)="addDetailOfAppeal()"><i class="fa fa-plus"></i></a>
                              <a class="addExtLetter" *ngIf="i !=0"
                                (click)="openRemoveDetailsOfAppeal(rmvDetailsOfAppealRef)"><i
                                  class="fa fa-minus"></i></a>
                            </td>
                            <ng-template #rmvDetailsOfAppealRef let-modal>
                              <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                <button type="button" class="close" aria-label="Close"
                                  (click)="modal.dismiss('Cross click')">
                                  <span aria-hidden="true">×</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <p>Are you sure, you want to remove?</p>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-outline-dark"
                                  (click)="rmDetailsOfAppeal(i)">Remove</button>
                              </div>
                            </ng-template>
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!-- details of appeal -->

            <div class="row mb-12">
              <label>Details of Hearings </label>
            </div>

            <div class="card-body pt0 nopadding">
              <div formArrayName="itemList">
                <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                  <li class="nav-item" id="adjPhTab{{i}}" *ngFor="let adjPHtab of aptDivArr().controls; let i=index"
                    [formGroupName]="i">
                    <a class="nav-link" id="adjudph1-tab" data-toggle="tab" (click)="phaseChanged('phase' + i, i)"
                      [ngClass]="(selectedPhase === 'phase' + i) ? 'active': ''" role="tab" aria-controls="adjudph1-1"
                      aria-selected="true">PH {{i+1}} <span *ngIf="i > 0" (click)="removeLetter(i)"
                        class="closeTab">x</span></a>
                  </li>
                </ul>

                <div class="tab-content nopadding">
                  <div class="tab-pane fade show active" id="adjudph1-1" role="tabpanel" aria-labelledby="adjudph1-tab">
                    <div class=" ml5" id="adjPhDiv{{i}}" *ngFor="let adjPHdiv of aptDivArr().controls; let i=index"
                      [formGroupName]="i">
                      <div *ngIf="selectedPhase === 'phase' + i">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice Ref.No </label>
                              <input type="text" class="form-control" id="phRefNo" placeholder="" formControlName="phRefNo">
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice Date </label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="phNoticeDate"
                                  [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH Notice
                              </label>
                              <input type="file" class="form-control file-upload-default" #file>
                              <div class="attachment-block">
                                <span (click)="openphCopyLoc(phCopyLoc, i)" style="margin-left: 5px;">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getPhCopyLocLength(i) != 0">
                                  {{getPhCopyLocLength(i)}} Attachment</span>
                              </div>
                              <div>
                                <ng-template #phCopyLoc let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let phCopyGrp of getphCopyLoc(adjPHdiv); let j = index"
                                            [formGroup]="phCopyGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="phCopyGrp.value.isdocLocUploadedClicked" type="file"
                                                      id="itemList({{i}}).phCopyLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)">
                                                    <p *ngIf="!phCopyGrp.value.isdocLocUploadedClicked || phCopyGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(phCopyGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="phCopyGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(phCopyGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(phCopyGrp.value.fileLoc || phCopyGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadPhCopy(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deletePhCopy(i,j)"
                                                      *ngIf="(phCopyGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" formControlName="remarks"
                                                [title]="phCopyGrp.value.remarks ? phCopyGrp.value.remarks:''">
                                              <div
                                                *ngIf="phCopyGrp.value.remarks && phCopyGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Date of PH scheduled </label>
                              <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                <p-calendar class="form-control" formControlName="dateOfPhScheduled"
                                  [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                  dateFormat="dd-mm-yy" [showIcon]="true" dataType="string"></p-calendar>
                              </div>
                            </div>
                          </div>
                          </div>
                          <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Time of PH </label>
                              <select class="form-control form-select" id="phTime" formControlName="phTime">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfTime">
                                  <option [value]="obj.value">{{obj.key}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Mode of PH </label>
                              <select class="form-control form-select" id="gstin" formControlName="mode">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of modeOfPH">
                                  <option [value]="obj.value">{{obj.key}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">Attended </label>
                              <select class="form-control form-select" id="gstin" formControlName="attended">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of adjAttend">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                         

                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="">Adjourned/ New PH </label>
                              <select class="form-control form-select" id="gstin" formControlName="adjournedOrNot"
                                (change)="changeadjOrNot($event, i)">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of adjPHAdjuorNotList">
                                  <option [value]="obj.value">{{obj.label}}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Adjournment Letter</label>
                            <input type="file" class="form-control file-upload-default" #file>
                            <div class="attachment-block">

                              <span (click)="openAdjournmentLetter(phAdjournmentLetterLoc, i)" style="margin-left: 5px;">
                                <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span><span *ngIf="getphAdjournmentLetterLocLength(i) != 0">
                                {{getphAdjournmentLetterLocLength(i)}} Attachment</span>
                            </div>
                            <div>
                              <ng-template #phAdjournmentLetterLoc let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let phAdjournmentLetterGrp of getAdjournmentLetter(adjPHdiv); let j = index"
                                          [formGroup]="phAdjournmentLetterGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="phAdjournmentLetterGrp.value.isdocLocUploadedClicked" type="file"
                                                    id="itemList({{i}}).phAdjournmentLetterLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!phAdjournmentLetterGrp.value.isdocLocUploadedClicked || phAdjournmentLetterGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(phAdjournmentLetterGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="phAdjournmentLetterGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(phAdjournmentLetterGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(phAdjournmentLetterGrp.value.fileLoc || phAdjournmentLetterGrp.value.fileLoc) && isDisableIcons"
                                                    title="Upload file" (click)="uploadAdjournmentLetter(i,j)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAdjournmentLetter(i,j)"
                                                    *ngIf="(phAdjournmentLetterGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="phAdjournmentLetterGrp.value.remarks ? phAdjournmentLetterGrp.value.remarks:''">
                                            <div
                                              *ngIf="phAdjournmentLetterGrp.value.remarks && phAdjournmentLetterGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Date of Additional Written Submissions</label>
                            <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                              <p-calendar class="form-control" formControlName="dateOfsubmission"
                                [monthNavigator]="true" [yearNavigator]="true" [maxDate]="maxDate"
                                yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                dataType="string">
                              </p-calendar>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Upload the additional submission</label>
                            <input type="file" class="form-control file-upload-default" #file>
                            <div class="attachment-block">
                              <span (click)="openAdditionSub(additionalSubLoc, i)" style="margin-left: 5px;">
                                <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                  title="Attach file"></i></span><span
                                *ngIf="getAdditionalSubmissionLocLength(i) != 0">
                                {{getAdditionalSubmissionLocLength(i)}} Attachment</span>
                              <!-- <span style="cursor: pointer;" (click)="openAdditionSub(additionalSubLoc,i)"> <i
                              class="icon fa fa fa-paperclip" style="cursor: pointer;"  
                              title="Attach file"></i></span><span style="cursor: pointer;" (click)="openAdditionSub(additionalSubLoc,i)">Attachment</span> -->
                            </div>
                            <div>
                              <ng-template #additionalSubLoc let-modal>
                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="col-md-12">
                                  <label> Attachment</label>
                                  <div class="table-responsive">
                                    <table class="table table-bordered tablepop">
                                      <thead>
                                        <tr class="bgGred">
                                          <th>Upload</th>
                                          <th style="width:50%;">Remark</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngFor="let additionaSubGrp of getAdditionalSub(adjPHdiv); let j = index"
                                          [formGroup]="additionaSubGrp">
                                          <td>
                                            <div class="row">
                                              <div class="col-md-9 nopadding">
                                                <div class="form-group">
                                                  <input *ngIf="additionaSubGrp.value.isdocLocUploadedClicked"
                                                    type="file"
                                                    id="itemList({{i}}).additionalSubmissionLoc({{j}}).fileLoc"
                                                    class="filetype form-control" name="myfile"
                                                    (change)="onFileSelect($event)">
                                                  <p *ngIf="!additionaSubGrp.value.isdocLocUploadedClicked || additionaSubGrp.value.fileLoc"
                                                    class="form-control pt10">
                                                    {{getFileNameDta(additionaSubGrp.value.fileLoc)}}
                                                  </p>
                                                </div>
                                              </div>
                                              <div class="col-md-3 nopadding">
                                                <label for="exampleInputPassword1">
                                                  <a class="downupIcon" *ngIf="additionaSubGrp.value.fileLoc"
                                                    title="Download file"
                                                    (click)="download(additionaSubGrp.value.fileLoc)"><i
                                                      class="fa fa-download"></i></a>
                                                  <a class="downupIcon"
                                                    *ngIf="!(additionaSubGrp.value.fileLoc || additionaSubGrp.value.fileLoc) && isDisableIcons"
                                                    title="Upload file" (click)="uploadAdditionalSub(i,j)"><i
                                                      class="fa fa-upload"></i></a>
                                                  <a class="downupIcon" (click)="deleteAdditionalSub(i,j)"
                                                    *ngIf="(additionaSubGrp.value.fileLoc) && isDisableIcons"><i
                                                      class="fa fa-trash" aria-hidden="true"></i></a>
                                                </label>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <input type="text" class="form-control" formControlName="remarks"
                                              [title]="additionaSubGrp.value.remarks ? additionaSubGrp.value.remarks:''">
                                            <div
                                              *ngIf="additionaSubGrp.value.remarks && additionaSubGrp.value.remarks.length > 100">
                                              <span class="text-danger">Max character Length 100</span>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="modal.close('Save click')">Save</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>

                        <!-- <div class="col-md-3">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Consultant</label>
          
                            <div *ngIf="isToggleConsultantDropdown2 && action==='update' || action==='view'" class="input-group">
                              <input type="text" class="form-control"
                                *ngIf="isToggleConsultantDropdown2 && action==='update' || action==='view'" placeholder=""
                                formControlName="consultant" readonly>
                              <button *ngIf="isToggleConsultantDropdown2 && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('p2')">
                                <i class="fa fa-pencil"></i>
                              </button> 
                            </div>
          
                            <select class="form-control form-select" id="caseId"
                             *ngIf="!isToggleConsultantDropdown2 || action==='create'" formControlName="consultant">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of consultantList">
                                  <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                </ng-container>
                              </select>
                          </div>
                        </div> -->
                        <div class="col-md-3" *ngIf="(roleName !== 'Consultant') || (roleName === 'Consultant' && action === 'create')">
                          <div class="form-group">
                            <label for="exampleInputPassword1">Consultant</label>
                        
                            <!-- For Consultant Role: Create, View, and Update -->
                            <div *ngIf="roleName === 'Consultant' && action === 'create'">
                              <input 
                                type="text" 
                                class="form-control" 
                                formControlName="consultant" 
                                [readOnly]="action === 'create'">
                            </div>
                        
                            <!-- For Non-Consultant Role -->
                            <div *ngIf="roleName !== 'Consultant'">
                              <div *ngIf="isToggleConsultantDropdown2 && (action === 'update' || action === 'view')" class="input-group">
                                <input 
                                  type="text" 
                                  class="form-control" 
                                  placeholder="" 
                                  formControlName="consultant" 
                                  readonly>
                                <button 
                                  *ngIf="isToggleConsultantDropdown2 && action === 'update'" 
                                  class="btn btn-outline-secondary" 
                                  type="button" 
                                  (click)="onClickToggleConsultantDropdown('p2')">
                                  <i class="fa fa-pencil"></i>
                                </button>
                              </div>
                        
                              <select 
                                class="form-control form-select" 
                                id="caseId" 
                                *ngIf="!isToggleConsultantDropdown2 || action === 'create'" 
                                formControlName="consultant">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of consultantList">
                                  <option [value]="obj.consultantName">{{ obj.consultantName }} -- {{ obj.firmName }}</option>
                                </ng-container>
                              </select>
                            </div>
                          </div>
                        </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH record, if any
                              </label>
                              <input type="file" class="form-control file-upload-default" #file>
                              <div class="attachment-block">

                                <span (click)="openPhRecord(phRecordLoc, i)" style="margin-left: 5px;">
                                  <i class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                    title="Attach file"></i></span><span *ngIf="getPhRecordIfAnyLocLength(i) != 0">
                                  {{getPhRecordIfAnyLocLength(i)}} Attachment</span>

                                <!-- <span style="cursor: pointer;" (click)="openPhRecord(phRecordLoc,i)"> <i
                                class="icon fa fa fa-paperclip" style="cursor: pointer;"  
                                title="Attach file"></i></span><span style="cursor: pointer;" (click)="openPhRecord(phRecordLoc,i)">Attachment</span> -->

                              </div>
                              <div>
                                <ng-template #phRecordLoc let-modal>
                                  <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="col-md-12">
                                    <label> Attachment</label>
                                    <div class="table-responsive">
                                      <table class="table table-bordered tablepop">
                                        <thead>
                                          <tr class="bgGred">
                                            <th>Upload</th>
                                            <th style="width:50%;">Remark</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr *ngFor="let phRecordGrp of getPhRecord(adjPHdiv); let j = index"
                                            [formGroup]="phRecordGrp">
                                            <td>
                                              <div class="row">
                                                <div class="col-md-9 nopadding">
                                                  <div class="form-group">
                                                    <input *ngIf="phRecordGrp.value.isdocLocUploadedClicked" type="file"
                                                      id="itemList({{i}}).phRecordIfAnyLoc({{j}}).fileLoc"
                                                      class="filetype form-control" name="myfile"
                                                      (change)="onFileSelect($event)">
                                                    <p *ngIf="!phRecordGrp.value.isdocLocUploadedClicked || phRecordGrp.value.fileLoc"
                                                      class="form-control pt10">
                                                      {{getFileNameDta(phRecordGrp.value.fileLoc)}}
                                                    </p>
                                                  </div>
                                                </div>
                                                <div class="col-md-3 nopadding">
                                                  <label for="exampleInputPassword1">
                                                    <a class="downupIcon" *ngIf="phRecordGrp.value.fileLoc"
                                                      title="Download file"
                                                      (click)="download(phRecordGrp.value.fileLoc)"><i
                                                        class="fa fa-download"></i></a>
                                                    <a class="downupIcon"
                                                      *ngIf="!(phRecordGrp.value.fileLoc || phRecordGrp.value.fileLoc) && isDisableIcons"
                                                      title="Upload file" (click)="uploadPhRecord(i,j)"><i
                                                        class="fa fa-upload"></i></a>
                                                    <a class="downupIcon" (click)="deletePhRecord(i,j)"
                                                      *ngIf="(phRecordGrp.value.fileLoc) && isDisableIcons"><i
                                                        class="fa fa-trash" aria-hidden="true"></i></a>
                                                  </label>
                                                </div>
                                              </div>
                                            </td>
                                            <td>
                                              <input type="text" class="form-control" formControlName="remarks"
                                                [title]="phRecordGrp.value.remarks ? phRecordGrp.value.remarks:''">
                                              <div
                                                *ngIf="phRecordGrp.value.remarks && phRecordGrp.value.remarks.length > 100">
                                                <span class="text-danger">Max character Length 100</span>
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div class="modal-footer" style="border: none; padding-top: 0px;">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="modal.close('Save click')">Save</button>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>

                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="exampleInputPassword1">PH discussion notes</label>
                              <textarea class="form-control" id="exampleTextarea1" rows="4"
                                formControlName="phDiscussionNotes"></textarea>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <!-- Consultant charges table -->

            <div data-toggle="collapse" data-target="#consultant" class="expansion-div mb-15"
              (click)="consultantPanleExpanded = !consultantPanleExpanded">Details of Consultant Charges<span
                class="consultant-icons"> <i *ngIf="!consultantPanleExpanded" class="fa fa-angle-down"
                  aria-hidden="true"></i> <i *ngIf="consultantPanleExpanded" class="fa fa-angle-up"
                  aria-hidden="true"></i></span> </div>
            <br>
            <div id="consultant" class="collapse">
              <div class=" row">
                <div class="col-md-12">
                  <div class="search-table-outter wrapper p-15">
                    <table class="search-table inner">
                      <thead>
                        <tr class="bgGred">
                          <th class="sl-no-cls">Sr.No.</th>
                          <th>Name of the Consultant</th>
                          <th class="col-3">Name of Consulting Firm</th>
                          <th>Nature of Work</th>
                          <th>Fees Charged</th>
                          <th>Supporting Document</th>
                          <th>Email id</th>
                          <th>Contact Number</th>
                          <th class="sl-no-cls">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container>
                          <tr *ngFor="let div of consultantInvArr() let i=index;" [formGroup]="div">
                            <td class="sl-no-cls">{{i+1}}</td>
                            <td>
                              <div *ngIf="isToggleConsultantDropdown3 && action==='update' || action==='view'" class="input-group">
                                <input type="text" class="form-control"
                                  *ngIf="isToggleConsultantDropdown3 && action==='update' || action==='view'" placeholder=""
                                  formControlName="consultant" readonly>
                                <button *ngIf="isToggleConsultantDropdown3 && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleConsultantDropdown('CC')">
                                  <i class="fa fa-pencil"></i>
                                </button> 
                              </div>
                      
                                <select class="form-control form-select" id="tax"
                                  *ngIf="!isToggleConsultantDropdown3 || action==='create'" formControlName="consultant" (change)="consultantDataAutofil(i)">
                                  <option [value]="null">-- Select --</option>
                                  <ng-container *ngFor="let obj of consultantList">
                                  <option [value]="obj.consultantName">{{obj.consultantName}} -- {{obj.firmName}}</option>
                                  </ng-container>
                                </select>
                            </td>
                            <td>
                              <input type="text" class="form-control" id="tax" placeholder="" formControlName="firm"
                                [title]="div.value.firm ? div.value.firm:''" readonly style="cursor: not-allowed;">
                            </td>
                            <!-- <td><input type="text" class="form-control" id="tax" placeholder=""
                                formControlName="consultant" [title]="div.value.consultant ? div.value.consultant:''">
                            </td> -->
                            <td><input type="text" class="form-control" id="tax" placeholder=""
                                formControlName="natureOfWork"
                                [title]="div.value.natureOfWork ? div.value.natureOfWork:''"></td>
                            <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                maxlength="19" InrFormat (paste)="handlePaste($event)" formControlName="fees"
                                (input)="feesChargedChanged()"></td>

                            <td>
                              <div class="row">
                                <div class="col-sm-9 nopadding">
                                  <span style="cursor: pointer;" (click)="openConsultAttach(consultAttachPop, i)"> <i
                                      class="icon fa fa fa-paperclip" title="Attach file"
                                      style="cursor: pointer;"></i></span>
                                  <span *ngIf="getConsultOtherAttachmentLength(i) != 0">
                                    {{getConsultOtherAttachmentLength(i)}} Attachment</span>
                                  <ng-template #consultAttachPop let-modal>
                                    <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                      <button type="button" class="close" aria-label="Close"
                                        (click)="modal.dismiss('Cross click')"> <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div class="col-md-12">
                                      <label> Attachment</label>
                                      <div class="table-responsive">
                                        <table class="table table-bordered tablepop">
                                          <thead>
                                            <tr class="bgGred">
                                              <th>Upload</th>
                                              <th style="width:50%;">Remark</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              *ngFor="let consultAttachGrp of getConsultOtherAttachment(div); let j = index"
                                              [formGroup]="consultAttachGrp">
                                              <td>
                                                <div class="row">
                                                  <div class="col-md-9 nopadding">
                                                    <div class="form-group">
                                                      <input *ngIf="consultAttachGrp.value.isdocLocUploadedClicked"
                                                        type="file"
                                                        id="consultantCharges({{i}}).otherAttachmentLoc({{j}}).fileLoc"
                                                        class="filetype form-control" name="myfile"
                                                        (change)="onFileSelect($event)">
                                                      <p *ngIf="!consultAttachGrp.value.isdocLocUploadedClicked || consultAttachGrp.value.fileLoc"
                                                        class="form-control pt10">
                                                        {{getFileNameDta(consultAttachGrp.value.fileLoc)}}
                                                      </p>
                                                    </div>
                                                  </div>
                                                  <div class="col-md-3 nopadding">
                                                    <label for="exampleInputPassword1">
                                                      <a class="downupIcon" *ngIf="consultAttachGrp.value.fileLoc"
                                                        title="Download file"
                                                        (click)="download(consultAttachGrp.value.fileLoc)"><i
                                                          class="fa fa-download"></i></a>
                                                      <a class="downupIcon"
                                                        *ngIf="!(consultAttachGrp.value.fileLoc || consultAttachGrp.value.fileLoc)&& isDisableIcons"
                                                        title="Upload file" (click)="uploadConsultAttach(i,j)"><i
                                                          class="fa fa-upload"></i></a>
                                                      <a class="downupIcon" (click)="deleteConsultAttach(i,j)"
                                                        *ngIf="(consultAttachGrp.value.fileLoc)&& isDisableIcons"><i
                                                          class="fa fa-trash" aria-hidden="true"></i></a>
                                                    </label>
                                                  </div>
                                                </div>
                                              </td>
                                              <td>
                                                <input type="text" class="form-control" formControlName="remarks"
                                                  [title]="consultAttachGrp.value.remarks ? consultAttachGrp.value.remarks:''">
                                                <div
                                                  *ngIf="consultAttachGrp.value.remarks && consultAttachGrp.value.remarks.length > 100">
                                                  <span class="text-danger">Max character Length 100</span>
                                                </div>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="modal-footer" style="border: none; padding-top: 0px;">
                                      <button type="button" class="btn btn-outline-dark"
                                        (click)="modal.close('Save click')">Save</button>
                                    </div>
                                  </ng-template>
                                </div>
                              </div>
                            </td>
                            <td>
                              <input type="text" class="form-control" placeholder="" formControlName="email" readonly style="cursor: not-allowed;">
                            </td>
                            <td><input class="form-control" id="contact" formControlName="contact"
                                [title]="div.value.contact ? div.value.contact:''" maxlength="12" readonly style="cursor: not-allowed;">
                            </td>

                            <td>

                              <a class=" addExtLetter" (click)="addConsultantInv()"><i class="fa fa-plus"></i></a>
                              <a class=" addExtLetter" *ngIf="i !=0"
                                (click)="openRmvconsultantPop(removeConsultantPopup, i)"><i class="fa fa-minus"></i></a>
                              <ng-template #removeConsultantPopup let-modal>
                                <div class="modal-header">
                                  <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                  <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">×</span>
                                  </button>
                                </div>
                                <div class="modal-body">
                                  <p>Are you sure, you want to remove?</p>
                                </div>
                                <div class="modal-footer">
                                  <button type="button" class="btn btn-outline-dark"
                                    (click)="rmConsultantInv(i)">Remove</button>
                                </div>
                              </ng-template>
                            </td>
                          </tr>
                          <tr>
                            <!-- <td class="sl-no-cls"></td> -->
                            <th class="align-right" colspan="4">Total</th>
                            <!-- <td></td> -->
                            <!-- <td></td> -->
                            <td class="align-right bold-font">{{feesChargedTotal | INR: 0 }}</td>
                            <!-- <td></td> -->
                          </tr>
                        </ng-container>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

            </div>
            <!-- Consultant charges table 2 -->
            <div class="row">
              <div class="col-md-3">
                <label>Whether the case is kept in Call book ? <a class="clrOrange infoIcon"
                    title="Please select this option as No if you want to update Phase 3"><i
                      class="fa fa-info-circle"></i></a></label>
                <select class="form-control form-select" [ngClass]="{'disabled': callBookDisabled }"
                  id="whetherCaseCallBook" formControlName="whetherCaseCallBook" (change)="nextPhase()">
                  <option [value]="null">-- Select --</option>
                  <ng-container *ngFor="let obj of keepCaseInCallBookOptions">
                    <option [value]="obj.value">{{obj.label}}</option>
                  </ng-container>
                </select>
              </div>
            </div>
            <div class="col-md-12 mt15">
              <button class="btn btn-outline-primary mr-1" (click)="updateLetter('p2')"
                *ngIf="isUpdateBtn">Update</button>
              <!-- <button class="btn btn-outline-primary mr-1">Delete</button> -->
            </div>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="tab-pane fade show active" id="AppelTribp3-1" role="tabpanel" aria-labelledby="AppelTribp3-tab">
      <div class="card borderOrange" [hidden]="(selectedTab === 'tab1') || (selectedTab === 'tab2')"
        [ngClass]="{'bgGray': (action == 'view')} ">
        <div class="card-body">
          <fieldset [disabled]="action == 'view'">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">{{caseType == caseTypeData.MEMORANDUM ? 'Order Number': 'GSTAT
                    Order Number'}} <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                  <input type="text" class="form-control" id="oiaNum" placeholder="" formControlName="orderNo3"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.orderNo3.errors }">
                  <div *ngIf="isSubmitted && p1.orderNo3.errors">
                    <span class="text-danger" *ngIf="p1.orderNo3.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">{{caseType == caseTypeData.MEMORANDUM? 'Date of Order': 'Date of
                    GSTAT Order'}} <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfOrder3" [monthNavigator]="true"
                      [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                      [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfOrder3.errors }">
                    </p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfOrder3.errors">
                    <span class="text-danger" *ngIf="p1.dateOfOrder3.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">{{caseType == caseTypeData.MEMORANDUM ? 'Date of Receipt of Order'
                    : 'Date of receipt of GSTAT Order'}} <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                    <p-calendar class="form-control" formControlName="dateOfReceiptOfOrder3" [monthNavigator]="true"
                      [yearNavigator]="true" [maxDate]="maxDate" yearRange="2016:{{currentYear}}" dateFormat="dd-mm-yy"
                      [showIcon]="true" dataType="string"
                      [ngClass]="{ 'errorBorder': isSubmitted && p1.dateOfReceiptOfOrder3.errors }">
                    </p-calendar>
                  </div>
                  <div *ngIf="isSubmitted && p1.dateOfReceiptOfOrder3.errors">
                    <span class="text-danger" *ngIf="p1.dateOfReceiptOfOrder3.errors.required"> Required</span>
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">{{caseType == caseTypeData.MEMORANDUM ? 'Order Copy' : 'GSTAT Order
                    Copy'}} <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                  <input type="file" class="form-control file-upload-default" #file>
                  <div class="attachment-block"
                    [ngClass]="{'errorBorder': isSubmitted &&  getgstatOrderCopyLocLength() === 0 }">
                    <span style="cursor: pointer;" (click)="opengstatOrderCopyLoc(gstrOrderCopy)"> <i
                        class="icon fa fa fa-paperclip" style="cursor: pointer;" title="Attach file"></i></span>
                    <span *ngIf="getgstatOrderCopyLocLength() != 0"> {{getgstatOrderCopyLocLength()}} Attachment</span>

                  </div>
                  <div *ngIf="isSubmitted && getgstatOrderCopyLocLength() === 0">
                    <span class="text-danger"> Required</span>
                  </div>
                  <div>
                    <ng-template #gstrOrderCopy let-modal>
                      <div class="modal-header" style="border: none; padding-bottom: 0px;">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">×</span> </button>
                      </div>
                      <div class="col-md-12">
                        <label> Attachment</label>
                        <div class="table-responsive">
                          <table class="table table-bordered tablepop">
                            <thead>
                              <tr class="bgGred">
                                <th>Upload</th>
                                <th style="width:50%;">Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let gstrOrderCopyGrp of getgstatOrderCopyLoc(); let i = index"
                                [formGroup]="gstrOrderCopyGrp">
                                <td>
                                  <div class="row">
                                    <div class="col-md-9 nopadding">
                                      <div class="form-group">
                                        <input *ngIf="gstrOrderCopyGrp.value.isdocLocUploadedClicked" type="file"
                                          id="gstatOrderCopyLoc({{i}}).fileLoc" class="filetype form-control"
                                          name="myfile" (change)="onFileSelect($event)">
                                        <p *ngIf="!gstrOrderCopyGrp.value.isdocLocUploadedClicked || gstrOrderCopyGrp.value.fileLoc"
                                          class="form-control pt10"> {{getFileNameDta(gstrOrderCopyGrp.value.fileLoc)}}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="col-md-3 nopadding">
                                      <label for="exampleInputPassword1">
                                        <a class="downupIcon" *ngIf="gstrOrderCopyGrp.value.fileLoc"
                                          title="Download file" (click)="download(gstrOrderCopyGrp.value.fileLoc)"><i
                                            class="fa fa-download"></i></a>
                                        <a class="downupIcon"
                                          *ngIf="!(gstrOrderCopyGrp.value.fileLoc || gstrOrderCopyGrp.value.fileLoc)&& isDisableIcons"
                                          title="Upload file" (click)="uploadgstatOrderCopyLocFile(i)"><i
                                            class="fa fa-upload"></i></a>
                                        <a class="downupIcon" (click)="deletegstatOrderCopyLoc(i)"
                                          *ngIf="(gstrOrderCopyGrp.value.fileLoc)&& isDisableIcons"><i
                                            class="fa fa-trash" aria-hidden="true"></i></a>
                                      </label>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <input type="text"
                                    [title]="gstrOrderCopyGrp.value.remarks ? gstrOrderCopyGrp.value.remarks:''"
                                    class="form-control" formControlName="remarks">
                                  <div
                                    *ngIf="gstrOrderCopyGrp.value.remarks && gstrOrderCopyGrp.value.remarks.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="modal-footer" style="border: none; padding-top: 0px;">
                        <button type="button" class="btn btn-outline-dark"
                          (click)="modal.close('Save click')">Save</button>
                      </div>
                    </ng-template>
                  </div>

                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Type of Authority <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="gstin" formControlName="typeOfAuthority3"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.typeOfAuthority3.errors }"
                    (change)="checkTypeOfAuthority3Selected($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of typeOfAuthority">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.typeOfAuthority3.errors">
                    <span class="text-danger" *ngIf="p1.typeOfAuthority3.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">DIN</label>
                  <input type="text" class="form-control" id="" placeholder="" formControlName="din3"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.din3.errors }">
                  <div *ngIf="isSubmitted && p1.din3.errors">
                    <span class="text-danger" *ngIf="p1.din3.errors.required">Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Mode of Receipt of Order</label>
                  <select class="form-control form-select" id="orderReceiptMode3" formControlName="orderReceiptMode3">
                    <ng-container *ngFor="let obj of orderReceiptMode">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Outcome <span class="mandate"><i
                        class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="gstin" formControlName="outcome"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.outcome.errors }" (change)="changeOutcome($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of outcome">
                      <option [value]="obj.value">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.outcome.errors">
                    <span class="text-danger" *ngIf="p1.outcome.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Further Appeal Decision <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="furtherAppealDecision" formControlName="furtherAppealDecision"
                  [ngClass]="{ 'errorBorder': isSubmitted && p1.furtherAppealDecision.errors }"  (change)="checkfurAppDeciYes($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of furtherAppealDecision">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.furtherAppealDecision.errors">
                    <span class="text-danger" *ngIf="p1.furtherAppealDecision.errors.required"> Required</span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether appeal preferred</label>
                  <select class="form-control form-select" id="gstin" formControlName="whetherAppealPreferred"
                    [ngClass]="{ 'disabled': disabledWeatherAppl }" (change)="checkAppYes($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of whetherAppealPreferred">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                  <!-- <div *ngIf="isSubmitted && p1.whetherAppealPreferred.errors">
                    <span class="text-danger" *ngIf="p1.whetherAppealPreferred.errors.required"> Required</span>
                  </div> -->
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether Writ application preferred </label>

                  <select class="form-control form-select" id="whetWritPref" formControlName="writAppPreferred"
                    [ngClass]="{'disabled': disabledWeatherWrit }" (change)="checkwritAppYes($event)">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of writAppPreferred">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">Whether department has preferred appeal </label>
                  <select class="form-control form-select" id="whetDeptPref" formControlName="whetherDptAppealPreferred"
                    (change)="checkDeptYes($event)" [ngClass]="{ 'disabled': disabledWeatherDept }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of whetherDptAppealPreferred">
                      <option [value]="obj.value">{{obj.label}}</option>
                    </ng-container>
                  </select>

                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="exampleInputPassword1">Forum before which Appeal / Writ has been preferred <span
                      class=" mandate"><i class="fa fa-asterisk"></i></span></label>
                  <select class="form-control form-select" id="gstin" formControlName="forumOfAppeal"
                    (change)="openProceedWithPreferredForumAppealPop(proceedWithPreferredForumAppeal)"
                    [ngClass]="{ 'errorBorder': isSubmitted && p1.forumOfAppeal.errors }">
                    <option [value]="null">-- Select --</option>
                    <ng-container *ngFor="let obj of forumAppeal">
                      <option [value]="obj.key">{{obj.value}}</option>
                    </ng-container>
                  </select>
                  <div *ngIf="isSubmitted && p1.forumOfAppeal.errors">
                    <span class="text-danger" *ngIf="p1.forumOfAppeal.errors.required"> Required</span>
                  </div>
                </div>
                <ng-template #proceedWithPreferredForumAppeal let-modal>
                  <div class="modal-header">
                    <h4 class="modal-title" id="modal-basic-title">Warning!</h4>
                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <p>You have selected <span class="bold-font">{{getForumAppealValue(p1.forumOfAppeal.value)}} </span>
                      as the forum before which the appeal / writ has been preferred. Do you want to proceed ?</p>
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-outline-dark"
                      (click)="rmProceedWithPreferredForumAppeal()">Yes</button>
                    <button type="button" class="btn btn-outline-dark"
                      (click)="rmProceedWithPreferredForumAppeal(true)">No</button>
                  </div>
                </ng-template>
              </div>
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">DRC-03 copy <a class="clrOrange infoIcon" title="zip/rar to be added for multiple files"><i class="fa fa-info-circle"></i></a>
                   <a class="downupIcon" *ngIf="isDWpaymentChallanLoc && p1.paymentChallanLoc.value"
                      title="Download file" (click)="download(p1.paymentChallanLoc.value)"><i
                        class="fa fa-download"></i></a>
                    <a class="downupIcon" *ngIf="isUPpaymentChallanLoc && !(action == 'update' && getAccessTabData.aptFlag)" title="Upload file"
                      (click)="uploadpaymentChallanLoc()"><i class="fa fa-upload"></i></a>
                  </label>
                  <input type="file" [hidden]="!ispaymentChallanLocFile" id="paymentChallanLoc"
                    class="filetype form-control" name="myfile" (change)='onFileSelect($event)'>
                  <input [hidden]="!ispaymentChallanLocText" readonly type="text" class="filetype form-control"
                    name="myfile" formControlName="paymentChallanLocFN">
                    <p [hidden]="!ispaymentChallanLocText" class="form-control pt10" >{{getFileNameDta(p1.paymentChallanLoc.value)}}</p>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">DRC03 Tax </label>
                  <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Tax3" [ngClass]="{ 'errorBorder': p1.drc03Tax3.errors }" maxlength="19" currencyMask   [options]="{ prefix: '', thousands: ',', precision: 0 }">
                  <div *ngIf="p1.drc03Tax3.errors">
                    <span class="text-danger" *ngIf="p1.drc03Tax3.errors.pattern"> Negative value not allowed</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">DRC03 Interest </label>
                  <input type="text" class="form-control" id="scnNum" placeholder="" formControlName="drc03Interest3" [ngClass]="{ 'errorBorder': p1.drc03Interest3.errors }" maxlength="19" currencyMask  [options]="{ prefix: '', thousands: ',', precision: 0 }">
                  <div *ngIf="p1.drc03Interest3.errors">
                    <span class="text-danger" *ngIf="p1.drc03Interest3.errors.pattern"> Negative value not allowed</span>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="exampleInputPassword1">DRC03 Penalty </label>
                  <input type="text" class="form-control" id="scnNum" placeholder=""  formControlName="drc03Penalty3" [ngClass]="{ 'errorBorder': p1.drc03Penalty3.errors }" maxlength="19" currencyMask  [options]="{ prefix: '', thousands: ',', precision: 0 }">
                  <div *ngIf="p1.drc03Penalty3.errors">
                    <span class="text-danger" *ngIf="p1.drc03Penalty3.errors.pattern"> Negative value not allowed</span>
                  </div>
                </div>
              </div> -->
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Order summary</label>
                  <textarea class="form-control" id="exampleTextarea1" rows="4"
                    formControlName="orderSummary"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label for="exampleInputPassword1">Issue Wise Details</label>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <thead>
                            <tr class="bgGred">
                              <th>Issue</th>
                              <th>Keyword <a class="clrOrange infoIcon"
                                  title="Maximum Length Limit is 150 Characters."><i class="fa fa-info-circle"></i></a>
                              </th>
                              <th>Conclusion <span class="mandate top7"><i class="fa fa-asterisk"></i></span></th>
                              <th>Tag</th>
                              <th>IGST (₹)</th>
                              <th>CGST (₹)</th>
                              <th>SGST (₹)</th>
                              <th>CESS (₹)</th>
                              <th>Interest (₹) <a [ngClass]="{'disabled': !isUpdateBtnDisable }" title="Interest Calculation" (click)="openIntCalPop(intCalPop)"><i class="fa fa-calculator fs14"></i></a></th>
                              <th>Penalty (₹)</th>
                              <th>Total (₹)</th>
                              <!-- <th>Remove</th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <ng-container>
                              <tr *ngFor="let div of issuInvArr(); let i=index" [formGroup]="div">
                                <td class="w250">
                                  <!-- <input type="text" class="form-control" id="tax" placeholder="" formControlName="issue">
                             -->
                                  <select class="form-control form-select" formControlName="issue"
                                    [title]="div.value.issue ? div.value.issue:''"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }">
                                    <option [value]="null" disabled>-- Select --</option>
                                    <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                      <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                        {{item.name}}</option>
                                    </optgroup>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('issue')?.errors">
                                    <span class="text-danger" *ngIf="div.get('issue')?.errors?.required "> Required
                                    </span>
                                  </div>
                                </td>
                                <td><input type="text" class="form-control" id="tax" placeholder=""
                                    formControlName="keyword" maxlength="150"
                                    [title]="div.value.keyword ? div.value.keyword:''"></td>
                                <td>
                                  <select class="form-control form-select" id="status" formControlName="status"
                                    [ngClass]="{ 'errorBorder': isSubmitted && div.get('status')?.errors  }">
                                    <option [value]="null">-- Select --</option>
                                    <ng-container *ngFor="let obj of issueConclusion">
                                      <option [value]="obj.value">{{obj.value}}</option>
                                    </ng-container>
                                  </select>
                                  <div *ngIf="isSubmitted && div.get('status')?.errors">
                                    <span class="text-danger" *ngIf="div.get('status')?.errors?.required "> Required
                                    </span>
                                  </div>
                                </td>
                                <td>
                                            <div class="input-group">
                                            <input type="text" class="form-control" [title]="div.value.tag ? div.value.tag:''"
                                              *ngIf="isToggleSegmentDropdown2 && action==='update' || action==='view'" placeholder=""
                                              formControlName="tag" readonly>
                                            <button *ngIf="isToggleSegmentDropdown2 && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleSegmentDropdown('p2')">
                                              <i class="fa fa-pencil"></i>
                                            </button> 
                                          </div>
                                            <select class="form-control form-select" id="" [title]="div.value.tag ? div.value.tag:''"
                                            *ngIf="!isToggleSegmentDropdown2 || action==='create'" formControlName="tag">
                                            <option [value]="null">-- Select --</option>
                                            <ng-container *ngFor="let obj of tagDataList">
                                              <option [value]="obj">{{obj}}</option>
                                            </ng-container>
                                          </select>
                                          </td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="igst3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="cgst3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="sgst3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="cess3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="interest3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="penalty3" maxlength="19" InrFormat (paste)="handlePaste($event)"
                                    (input)="issueValueChange()"></td>
                                <td><input type="text" class="form-control align-right" id="tax" placeholder=""
                                    formControlName="total3" readonly maxlength="19" InrFormat
                                    (input)="issueValueChange()"></td>

                                <!-- <td><a class=" addExtLetter" (click)="openRmvIssuePop(removeIssuePopup, i)"><i class="fa fa-minus"></i></a></td> -->
                                <ng-template #removeIssuePopup let-modal>
                                  <div class="modal-header">
                                    <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                    <button type="button" class="close" aria-label="Close"
                                      (click)="modal.dismiss('Cross click')">
                                      <span aria-hidden="true">×</span>
                                    </button>
                                  </div>
                                  <div class="modal-body">
                                    <p>Are you sure, you want to remove?</p>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="button" class="btn btn-outline-dark"
                                      (click)="rmIssueInv(i)">Remove</button>
                                  </div>
                                </ng-template>
                              </tr>
                            </ng-container>
                            <ng-container>
                              <tr>
                                <th class="align-right" colspan="4">Total</th>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="igst3Total" placeholder="" formControlName="igst3Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="cgst3Total" placeholder="" formControlName="cgst3Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="sgst3Total" placeholder="" formControlName="sgst3Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="cess3Total" placeholder="" formControlName="cess3Total" readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="interest3Total" placeholder="" formControlName="interest3Total"
                                    readonly></td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="penaltyTotal" placeholder="" formControlName="penalty3Total" readonly>
                                </td>
                                <td><input type="text" class="form-control align-right bold-font" maxlength="19"
                                    InrFormat id="totOfTotal3" placeholder="" formControlName="totOfTotal3" readonly>
                                </td>
                                <!-- <td colspan="1">&nbsp;</td>    -->
                              </tr>
                            </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <!-- Details of Liability Admitted and Paid  -->
              <div data-toggle="collapse" data-target="#detailsOfLiabilityAddmittedAndPaid" class="expansion-div mb-15"
                (click)="detailsOfLiabilityAddmittedAndPaidExpanded = !detailsOfLiabilityAddmittedAndPaidExpanded">
                Details of Liability Admitted and Paid
                <span class="consultant-icons"> <i *ngIf="!detailsOfLiabilityAddmittedAndPaidExpanded"
                    class="fa fa-angle-down" aria-hidden="true"></i> <i
                    *ngIf="detailsOfLiabilityAddmittedAndPaidExpanded" class="fa fa-angle-up"
                    aria-hidden="true"></i></span>
              </div>
              <div id="detailsOfLiabilityAddmittedAndPaid" class="collapse">
                <div class="row">
                  <div class="col-md- 12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="search-table-outter wrapper">
                            <table class="search-table inner">
                              <thead>
                                <tr class="bgGred">
                                  <th class="sl-no-cls">Sr. No.</th>
                                  <th class="col-3">Issue</th>
                                  <th class="col-3">Tag</th>
                                  <th class="col-3">Payment Date</th>
                                  <th>Form of Payment</th>
                                  <th>Reference / Challan No.</th>
                                  <th>Mode of Payment</th>
                                  <th>IGST (₹) </th>
                                  <th>CGST (₹) </th>
                                  <th>SGST (₹)</th>
                                  <th>CESS (₹)</th>
                                  <th>Interest (₹)</th>
                                  <th>Penalty (₹)</th>
                                  <th>Total (₹)</th>
                                  <th>Accounting Reference</th>
                                  <th>Copy of Form</th>
                                  <th>Copy of Bank Challan</th>
                                  <th class="ac-no-cls">Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container>

                                  <tr *ngFor="let div of detailsOfLiabilityAddmittedAndPaidArr(); let i=index"
                                    [formGroup]="div" (keydown.enter)="$event.preventDefault()">
                                    <td class="sl-no-cls">{{i + 1}} <span *ngIf="div.value.markedForPayment" title="Marked for Interest Calculation"><i class="fa fa-circle isMarked"></i></span></td>
                                    <!-- form of payment -->
                                    <td>
                                <select class="form-control form-select" formControlName="issueName" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.issueName}" [title]="div.value.issueName ? div.value.issueName:''">
                                <option [value]="null" disabled>-- Select --</option>
                                <optgroup *ngFor='let grp of dataSource' label="{{grp.group}}">
                                  <option *ngFor='let item of grp.items' [value]="item.name" title="{{item.name}}">
                                    {{item.name}}</option>
                                </optgroup>
                              </select>
                              <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.issueName">
                                    <span class="text-danger"> Required.</span>
                                  </div>
                              </td>
                              <td>
                                <select class="form-control form-select" id=""  formControlName="tag" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.tag}" [title]="div.value.tag ? div.value.tag:''">
                                <option [value]="null">-- Select --</option>
                                <ng-container *ngFor="let obj of tagDataList">
                                  <option [value]="obj">{{obj}}</option>
                                </ng-container>
                              </select>
                              <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.tag">
                                    <span class="text-danger"> Required.</span>
                                  </div>
                              </td>
                              <td>
                                <div id="iris-gst-filter-frmDate " class="input-group date datepicker tbldatePicker" [ngClass]="{ 'errorBorder': (div.value.total || div.value.challanNo) && !div.value.dateOfPayment}">
                                  <p-calendar appendTo="body" class="form-control" formControlName="dateOfPayment"
                                    dateFormat="dd-mm-yy" [showIcon]="true" dataType="string" [maxDate]="maxDate"
                                    [monthNavigator]="true" [yearNavigator]="true" yearRange="2016:{{currentYear}}"
                                    dateFormat="dd-mm-yy"></p-calendar>
                                </div>
                                <div *ngIf="(div.value.total || div.value.challanNo) && !div.value.dateOfPayment">
                                    <span class="text-danger"> Required.</span>
                                  </div>
                              </td>
                                    <td>
                                      <select class="form-control form-select" id="formOfPayment"
                                        formControlName="formOfPayment">
                                        <option [value]="null">-- Select --</option>
                                        <option *ngFor="let obj of formOfPayment" [value]="obj.value">{{obj.label}}
                                        </option>
                                      </select>
                                    </td>
                                    <td>
                                      <input class="form-control" id="challanNo" formControlName="challanNo" [ngClass]="{ 'errorBorder': div.value.total && !div.value.challanNo}"
                                        [ngClass]="{ 'errorBorder': isSubmitted && div.get('copyOfFormLoc')?.errors  }" />
                                        <div *ngIf="div.value.total && !div.value.challanNo">
                                    <span class="text-danger"> Required.</span>
                                   
                                  </div>
                                  <div *ngIf="div.value.challanNo && getLiabilityCopyFormLength(i) === 0">
                                    <span class="text-danger"> Please Upload  Copy of Form</span>
                                  </div>
                                    </td>
                                    <!-- Mode of payment -->
                                    <td>
                                      <select class="form-control form-select" id="modeOfPayment"
                                        formControlName="modeOfPayment">
                                        <option [value]="null">-- Select --</option>
                                        <option *ngFor="let obj of modeOfPaymentObjectLiabilityAdmitted"
                                          [value]="obj.key">{{obj.value}}</option>
                                      </select>
                                    </td>

                                    <td><input type="text" class="form-control align-right " formControlName="igst"
                                        id="igst" placeholder="" maxlength="19
                                " (input)="liabilityAddmittedAndPaidChanged()" InrFormat (paste)="handlePaste($event)">
                                    </td>
                                 <td><input type="text" class="form-control align-right" id="cgst" placeholder=""
                                    (input)="liabilityAddmittedAndPaidChanged()" formControlName="cgst" maxlength="19" InrFormat
                                    (paste)="handlePaste($event)"></td>
                              <td><input type="text" class="form-control align-right" id="sgst" placeholder=""
                                      (input)="liabilityAddmittedAndPaidChanged()" formControlName="sgst" maxlength="19" InrFormat
                                      (paste)="handlePaste($event)"></td>
                               <td><input type="text" class="form-control align-right" id="cess" placeholder=""
                                     (input)="liabilityAddmittedAndPaidChanged()" formControlName="cess" maxlength="19" InrFormat
                                        (paste)="handlePaste($event)"></td>
                                    <td><input type="text" class="form-control align-right " formControlName="interest"
                                        id="interest" placeholder="" maxlength="19
                                " (input)="liabilityAddmittedAndPaidChanged()" InrFormat (paste)="handlePaste($event)">
                                    </td>
                                    <td><input type="text" class="form-control align-right " formControlName="penalty"
                                        id="penalty" placeholder="" maxlength="19
                                " (input)="liabilityAddmittedAndPaidChanged()" InrFormat (paste)="handlePaste($event)">
                                    </td>
                                    <td><input type="text" class="form-control align-right " formControlName="total"
                                        id="total" readonly placeholder="" maxlength="19
                                " InrFormat></td>

                                <td>
                                  <input type="text" class="form-control" formControlName="accountingRef"
                                  [ngClass]="{ 'errorBorder': div.value.accountingRef?.length > 100 }"
                                    [title]="div.value.accountingRef ? div.value.accountingRef:''">
                                  <div
                                    *ngIf="div.value.accountingRef && div.value.accountingRef.length > 100">
                                    <span class="text-danger">Max character Length 100</span>
                                  </div>
                              </td>

                                    <!-- copyOfFormLoc -->
                                    <td>
                                      <div class="row">
                                        <div class="col-sm-9 nopadding">
                                          <span style="cursor: pointer;"
                                            (click)="openLiabilityCopyOfForm(liabilityCopyFormAck, i)"> <i
                                              class="icon fa fa fa-paperclip" title="Attach file"
                                              style="cursor: pointer;"></i></span>
                                          <span *ngIf="getLiabilityCopyFormLength(i) != 0">
                                            {{getLiabilityCopyFormLength(i)}} Attachment</span>

                                          <ng-template #liabilityCopyFormAck let-modal>
                                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                              <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')"> <span
                                                  aria-hidden="true">×</span> </button>
                                            </div>
                                            <div class="col-md-12">
                                              <label> Attachment</label>
                                              <div class="table-responsive">
                                                <table class="table table-bordered tablepop">
                                                  <thead>
                                                    <tr class="bgGred">
                                                      <th>Upload</th>
                                                      <th style="width:50%;">Remark</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr
                                                      *ngFor="let liabilityCopyFrm of getLiabilityCopyForm(div); let j = index"
                                                      [formGroup]="liabilityCopyFrm">
                                                      <td>
                                                        <div class="row">
                                                          <div class="col-md-9 nopadding">
                                                            <div class="form-group">
                                                              <input
                                                                *ngIf="liabilityCopyFrm.value.isdocLocUploadedClicked"
                                                                type="file"
                                                                id="liabilityDetails({{i}}).copyOfFormLoc({{j}}).fileLoc"
                                                                class="filetype form-control" name="myfile"
                                                                (change)="onFileSelect($event)">
                                                              <p *ngIf="!liabilityCopyFrm.value.isdocLocUploadedClicked || liabilityCopyFrm.value.fileLoc"
                                                                class="form-control pt10">
                                                                {{getFileNameDta(liabilityCopyFrm.value.fileLoc)}}
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <div class="col-md-3 nopadding">
                                                            <label for="exampleInputPassword1">
                                                              <a class="downupIcon"
                                                                *ngIf="liabilityCopyFrm.value.fileLoc"
                                                                title="Download file"
                                                                (click)="download(liabilityCopyFrm.value.fileLoc)"><i
                                                                  class="fa fa-download"></i></a>
                                                              <a class="downupIcon"
                                                                *ngIf="!(liabilityCopyFrm.value.fileLoc || liabilityCopyFrm.value.fileLoc)&& isDisableIcons"
                                                                title="Upload file"
                                                                (click)="uploadLiabilityCopyForm(i,j)"><i
                                                                  class="fa fa-upload"></i></a>
                                                              <a class="downupIcon"
                                                                (click)="deleteLiabilityCopyForm(i,j)"
                                                                *ngIf="(liabilityCopyFrm.value.fileLoc)&& isDisableIcons"><i
                                                                  class="fa fa-trash" aria-hidden="true"></i></a>
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <input type="text" class="form-control"
                                                          formControlName="remarks"
                                                          [title]="liabilityCopyFrm.value.remarks ? liabilityCopyFrm.value.remarks:''">
                                                        <div
                                                          *ngIf="liabilityCopyFrm.value.remarks && liabilityCopyFrm.value.remarks.length > 100">
                                                          <span class="text-danger">Max character Length 100</span>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                                              <button type="button" class="btn btn-outline-dark"
                                                (click)="modal.close('Save click')">Save</button>
                                            </div>
                                          </ng-template>
                                          <div *ngIf="(div.get('challanNo')?.value ||  div.get('total')?.value) && getLiabilityCopyFormLength(i) === 0 ">
                                            <span class="text-danger"> Required.</span>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                    <!-- copyOfFormLoc -->

                                    <!-- copyOfBankChallanLoc -->
                                    <td>
                                      <div class="row">
                                        <div class="col-sm-9 nopadding">
                                          <span style="cursor: pointer;"
                                            (click)="openLiabilityBankChallan(liabilityBankchallan, i)"> <i
                                              class="icon fa fa fa-paperclip" title="Attach file"
                                              style="cursor: pointer;"></i></span>
                                          <span *ngIf="getLiabilityBankChallanLength(i) != 0">
                                            {{getLiabilityBankChallanLength(i)}} Attachment</span>

                                          <ng-template #liabilityBankchallan let-modal>
                                            <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                              <button type="button" class="close" aria-label="Close"
                                                (click)="modal.dismiss('Cross click')"> <span
                                                  aria-hidden="true">×</span> </button>
                                            </div>
                                            <div class="col-md-12">
                                              <label> Attachment</label>
                                              <div class="table-responsive">
                                                <table class="table table-bordered tablepop">
                                                  <thead>
                                                    <tr class="bgGred">
                                                      <th>Upload</th>
                                                      <th style="width:50%;">Remark</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr
                                                      *ngFor="let liabilityBankChallanFrm of getLiabilityBankChallan(div); let j = index"
                                                      [formGroup]="liabilityBankChallanFrm">
                                                      <td>
                                                        <div class="row">
                                                          <div class="col-md-9 nopadding">
                                                            <div class="form-group">
                                                              <input
                                                                *ngIf="liabilityBankChallanFrm.value.isdocLocUploadedClicked"
                                                                type="file"
                                                                id="liabilityDetails({{i}}).copyOfBankChallanLoc({{j}}).fileLoc"
                                                                class="filetype form-control" name="myfile"
                                                                (change)="onFileSelect($event)">
                                                              <p *ngIf="!liabilityBankChallanFrm.value.isdocLocUploadedClicked || liabilityBankChallanFrm.value.fileLoc"
                                                                class="form-control pt10">
                                                                {{getFileNameDta(liabilityBankChallanFrm.value.fileLoc)}}
                                                              </p>
                                                            </div>
                                                          </div>
                                                          <div class="col-md-3 nopadding">
                                                            <label for="exampleInputPassword1">
                                                              <a class="downupIcon"
                                                                *ngIf="liabilityBankChallanFrm.value.fileLoc"
                                                                title="Download file"
                                                                (click)="download(liabilityBankChallanFrm.value.fileLoc)"><i
                                                                  class="fa fa-download"></i></a>
                                                              <a class="downupIcon"
                                                                *ngIf="!(liabilityBankChallanFrm.value.fileLoc || liabilityBankChallanFrm.value.fileLoc)&& isDisableIcons"
                                                                title="Upload file"
                                                                (click)="uploadLiabilityBankChallanForm(i,j)"><i
                                                                  class="fa fa-upload"></i></a>
                                                              <a class="downupIcon"
                                                                (click)="deleteLiabilityBankChallanForm(i,j)"
                                                                *ngIf="(liabilityBankChallanFrm.value.fileLoc)&& isDisableIcons"><i
                                                                  class="fa fa-trash" aria-hidden="true"></i></a>
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </td>
                                                      <td>
                                                        <input type="text" class="form-control"
                                                          formControlName="remarks"
                                                          [title]="liabilityBankChallanFrm.value.remarks ? liabilityBankChallanFrm.value.remarks:''">
                                                        <div
                                                          *ngIf="liabilityBankChallanFrm.value.remarks && liabilityBankChallanFrm.value.remarks.length > 100">
                                                          <span class="text-danger">Max character Length 100</span>
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                            <div class="modal-footer" style="border: none; padding-top: 0px;">
                                              <button type="button" class="btn btn-outline-dark"
                                                (click)="modal.close('Save click')">Save</button>
                                            </div>
                                          </ng-template>
                                        </div>
                                      </div>
                                    </td>
                                    <!-- copyOfBankChallanLoc -->

                                    <!-- copyOfBankChallanLoc -->
                                    <td class="ac-no-cls"><a class=" addExtLetter"
                                        (click)="addDetailsOfLiabilityAddmittedAndPaid()"><i class="fa fa-plus"></i></a>
                                      <a class="addExtLetter" *ngIf="i !=0"
                                        (click)="openLiabilityAddmittedAndPaidPop(rmvLiabilityAddmittedAndPaidModalRef)"><i
                                          class="fa fa-minus"></i></a>
                                    </td>
                                    <ng-template #rmvLiabilityAddmittedAndPaidModalRef let-modal>
                                      <div class="modal-header">
                                        <h4 class="modal-title" id="modal-basic-title">Remove Warning!</h4>
                                        <button type="button" class="close" aria-label="Close"
                                          (click)="modal.dismiss('Cross click')">
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div class="modal-body">
                                        <p>Are you sure, you want to remove?</p>
                                      </div>
                                      <div class="modal-footer">
                                        <button type="button" class="btn btn-outline-dark"
                                          (click)="rmLiabilityAddmittedAndPaidInv(i)">Remove</button>
                                      </div>
                                    </ng-template>
                                  </tr>

                                  <tr>
                                    <!-- <td class="sl-no-cls"></td> -->
                                    <th class="align-center" colspan="7">Total</th>
                                    <!-- <td></td> -->
                                    <td class="align-right bold-font">{{liabilityAddmittedAndPaidTaxTotal | INR: 0 }}
                                    </td>
                                    <td class="align-right bold-font">{{liabilityAddmittedAndPaidCgstTotal | INR: 0 }}
                                    </td>
                                    <td class="align-right bold-font">{{liabilityAddmittedAndPaidSgstTotal | INR: 0 }}
                                    </td>
                                    <td class="align-right bold-font">{{liabilityAddmittedAndPaidCessTotal | INR: 0 }}
                                    </td>
                                    <td class="align-right bold-font">{{liabilityAddmittedAndPaidInterestTotal | INR: 0
                                      }}</td>
                                    <td class="align-right bold-font">{{liabilityAddmittedAndPaidPenaltyTotal | INR: 0
                                      }}</td>
                                    <td class="align-right bold-font">{{liabilityAddmittedAndPaidTotal | INR: 0 }}</td>
                                    <!-- <td></td>
                                <td></td> -->
                                  </tr>
                                </ng-container>

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <!-- Details of all payment -->
              <div data-toggle="collapse" data-target="#detailsOfAllPaymentMadeExpansion" class="expansion-div mb-15"
                (click)="detailsOfAllPaymentMadeExpansion = !detailsOfAllPaymentMadeExpansion">
                Details of All Payments Made (Cumulative) <span class="consultant-icons"><span
                    (click)="refreshCumulativeTable($event)" class="refresh-icon-cls"><i class="fa fa-refresh me-1"
                      aria-hidden="true"></i><span class="refresh-text">Refresh Cumulative Count</span></span> <i
                    *ngIf="!detailsOfAllPaymentMadeExpansion" class="fa fa-angle-down" aria-hidden="true"></i> <i
                    *ngIf="detailsOfAllPaymentMadeExpansion" class="fa fa-angle-up" aria-hidden="true"></i></span>
              </div>
              <div class="collapse" id="detailsOfAllPaymentMadeExpansion">
                <div class="row mb-15">
                  <div class="col-md-12">
                    <div class="search-table-outter wrapper">
                      <table class="search-table inner" style="width: 100%;">
                        <thead>
                          <tr class="bgGred">
                            <th>Label</th>
                            <th>IGST (₹)</th>
                            <th>CGST (₹)</th>
                            <th>SGST (₹)</th>
                            <th>CESS (₹)</th>
                            <th>Interest (₹)</th>
                            <th>Penalty (₹)</th>
                            <th>Total (₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          <ng-container>
                            <tr *ngFor="let c of cumulativeCount">
                              <td>{{c.label}}</td>
                              <td class="align-right">{{c.igst | INR :0 }}</td>
                              <td class="align-right">{{c.cgst | INR :0 }}</td>
                              <td class="align-right">{{c.sgst | INR :0 }}</td>
                              <td class="align-right">{{c.cess | INR :0 }}</td>
                              <td class="align-right">{{c.interest | INR: 0}}</td>
                              <td class="align-right">{{c.penalty | INR: 0}}</td>
                              <td class="align-right">{{c.total | INR: 0}}</td>
                            </tr>
                          </ng-container>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Details of all payment -->
              <!-- Details of Liability Admitted and Paid -->
              <div class="col-md-12">
                <button class="btn btn-outline-primary mr-1" (click)="updateLetter('p3')"
                  *ngIf="isUpdateBtn">Submit</button>
                <!-- <button class="btn btn-outline-primary mr-1">Delete</button> -->
              </div>

            </div>
          </fieldset>

        </div>
      </div>
    </div>
  </form>
</div>
<ng-template #intCalPop let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Interest Calculation</h4>
    <button type="button" class="btn-close" aria-label="Close"  (click)="closeIntCalPop()"></button>
  </div>
  <div class="modal-body">  
    <div class="clrOrange infoIcon" *ngIf="selectedTab === 'tab1'" >    
      <div>
          <ul>
              <li>Interest will be calculated based on period from and period to date. If order has been updated in Phase 3, in this case interest calculation will be done till the date of receipt of order.</li>
              <li>Interest will not be calculated if TAG is not assigned at the Issue level.</li>
              <li>For direct writ and memorandum cases (Department Appeal), interest will not be calculated at Phase 1. It will be calculated once the order is passed against writ/appeal.</li>
          </ul>       
      </div>       
    </div>
    <div class="clrOrange infoIcon" *ngIf="selectedTab === 'tab3'" >
      <div>
        <ul>
            <li>Interest will not be calculated if TAG is not assigned at the Issue level.</li>
            <li>Interst will not be calculated for Favorable and Partly Favorable issues.</li>
        </ul>      
      </div>       
    </div>
    <div class="switch-box" style="float: inline-end;">      
      <p class="btn btn-outline-success btn-download mr-3" (click)="doIntCalData()">Calculate Interest</p>
      <p class="btn btn-outline-success btn-download" (click)="saveIntCalData()">Save</p>
    </div>  
    <!-- <p class="pieChartLabel txtCenter">{{currentLabel | uppercase}}</p> -->
    <div class="autoScroll" style="min-height: 480px !important; width: 100%;">
      <div class="header-tbl-issue-wise-demand" style="padding: 8px; border: 1px solid #e3e7ed; background-color: #8cb4d5;">      
        <div class="text-center first-div">
            <span>Issue </span>           
        </div>
        <div class="text-center">
          <span>Tag </span>       
        </div>       
        <div class="text-center">
          <span>From Date </span>
        </div>
        <div class="text-center">
          <span>To Date </span>
        </div>
        <div class="text-center">
          <span>Interest Rate </span>
        </div>
        <div class="text-center">
          <span>Total Tax </span>
        </div>
        <div class="text-center">
          <span>Tax Value adjusted against this issue </span>
        </div>
        <div class="text-center">
          <span>Calculated Interest </span>
        </div>
        <div class="text-center">
          <span>User entered Interest </span>
        </div>
        <div class="text-center">
          <span>Total Interest </span>
        </div>
      </div>
      <div class="accordion" id="accordion" role="tablist">
        <ng-container *ngFor="let obj of intCalIssueData; let i=index">
          <div class="body-tbl-issue-wise-demand" role="tab" id="heading-{{i}}" 
          style="padding: 8px;border: 1px solid #e3e7ed; background-color: #eee;">
            <div class="first-div" style="width: 200px;">
              <span>
                {{obj.issue}}
                <a class="collapsed" *ngIf="obj.paymentDetails.length > 0" data-toggle="collapse" href="#collapse-{{i}}" aria-expanded="false"
                aria-controls="collapse-{{i}}"></a>
              </span>
            </div>
            <div class="col-1" >{{obj.tag}}</div>
            <div class="form-group col-2 mb0">
              <div class=" input-group date datepicker">
                  <p-calendar [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2016:{{currentYear}}" class="form-control"
                      dateFormat="dd-mm-yy" [showIcon]="true"
                      [(ngModel)]="obj.periodFromDate"  [maxDate]="maxDate" 
                      dataType="string"></p-calendar>
              </div>
          </div>          
             <div class="form-group col-2 mb0">
              <div class=" input-group date datepicker">
                  <p-calendar [monthNavigator]="true"
                      [yearNavigator]="true"
                      yearRange="2016:{{currentYear}}" class="form-control"
                      dateFormat="dd-mm-yy" [showIcon]="true"
                      [(ngModel)]="obj.periodToDate" [maxDate]="maxDate"
                      dataType="string"></p-calendar>
              </div>
          </div>
            <div class="textRight col-1" >
              <select class="form-control form-select" [(ngModel)]="obj.interestRate">               
                <ng-container *ngFor="let obj of intRate">
                  <option [value]="obj.value">{{obj.label}}</option>
                 </ng-container>
              </select>
            </div>
            <div class="textRight" >{{obj.totalTax | INR : 0}}</div>
            <div class="textRight" >{{obj.taxAdjustedAgainstIssue | INR : 0}}</div>
            <div class="textRight" >{{obj.calculatedInterest | INR : 0}}</div>
            <div class="textRight" ><input type="text" class="form-control align-right" [(ngModel)]="obj.userEnteredInterest" InrFormat (paste)="handlePaste($event)"></div>
            <div class="textRight" >{{obj.interest | INR : 0}}</div>
          </div>

          <div id="collapse-{{i}}" class="collapse card-body" style="border: 1px solid #eee;background:#fff;"
              role="tabpanel" aria-labelledby="heading-{{i}}"
              data-parent="#accordion">
              <div class="row">
                <div class="col-md-12 nopadding mb10 popTbl table-responsive">
                  <table class="table table-bordered tbl-issue-wise-demand">
                    <thead>
                      <tr>                     
                      <th class="">Payment type</th>
                      <th class="">Forum</th>
                      <th class="">Payment Date </th>
                      <th class="">Form of Payment</th>
                      <th class="">Reference / Challan No.</th>
                      <th class="">Mode of Payment</th>
                      <th class="text-right">IGST (₹)</th>
                      <th class="text-right">CGST (₹)</th>
                      <th class="text-right">SGST (₹)</th>
                      <th class="text-right">CESS (₹)</th>
                      <th class="text-right">Total Tax</th>
                      <th class="text-right">Mark for Interest Calculation</th>
                    </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let item of obj.paymentDetails; let j = index">
                        <td class="">{{item.type === 'PROTEST_DETAILS' ? 'Protest' : item.type === 'ADMITTED_LIABILITY' ? 'Admitted Liability' :
                          item.type === 'PREDEPOSIT_DETAILS' ? 'Predeposit' :
                          item.type}}
                        </td>
                        <td class="">{{item.stage === 'aja' ? 'Adjudication' : item.stage === 'apa' ? 'Commissioner (A)' : item.stage === 'apt' ? 'Appellate Tribunal' :  item.stage === 'hc' ? 'High Court' : item.stage === 'sc' ? 'Supreme Court' : item.stage }}</td>
                        <td class="">{{item.dateOfPayment}}</td>                       
                        <td class="">{{item.formOfPayment}}</td>
                        <td class="">{{item.challanNo}}</td>
                        <td class="">{{item.modeOfPayment}}</td>
                        <td class="text-right">{{item.igst | INR : 0}}</td>
                        <td class="text-right">{{item.cgst | INR : 0}}</td>
                        <td class="text-right">{{item.sgst | INR : 0}}</td>
                        <td class="text-right">{{item.cess | INR : 0}}</td>
                        <td class="text-right">{{item.totalTax | INR : 0}}</td>
                        <td class="">
                          <select class="form-control form-select" [(ngModel)]="item.markedForPayment">
                           <ng-container *ngFor="let obj of yesNoList">
                              <option [value]="obj.value">{{obj.label}}</option>
                           </ng-container> 
                          </select>
                        </td>                        
                      </tr>
                      
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>

</ng-template>
