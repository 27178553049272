import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormsModule, UntypedFormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { NOTICE_USER_ACTION, badgeMapInvoice, NOTICE_TYPE } from 'src/app/shared/constant';
import { BusinessEntityService } from 'src/app/shared/services/business-entity.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { NoticeServiceService } from '../../services/notification.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgxPaginationModule } from 'ngx-pagination';
import { CalendarModule } from 'primeng/calendar';

interface Filters {
  p: 'gstin' | 'fy' | 'parsedDtOfAssignment' | 'caseTypeName';
  o: 'in' | 'eq' | 'between';
  v?: string | number | null;
  v1?: string | number | null;
  v2?: string | number | null;
}

@Component({
  selector: 'app-gst-letter-of-undertaking',
  // standalone: true,
  // imports: [BrowserModule, FormsModule, CalendarModule, NgxPaginationModule],
  templateUrl: './gst-letter-of-undertaking.component.html',
  styleUrl: './gst-letter-of-undertaking.component.css'
})
export class GstLetterOfUndertakingComponent {

  @ViewChild('closeModal', { static: false }) closeModal!: ElementRef;
  isAccessGiven: boolean = false;
  isRead: boolean = false;
  isYetToRead: boolean = false;
  hideEdit: boolean = true;
  addNoticeOrderData: any;
  navContext: any;
  companyId: number = Number(sessionStorage.getItem('companyId'));
  selectedFilling: any;
  selectedGstin: any;
  pageSection1: number = 1;
  tableSize: number = 10;
  userAct = NOTICE_USER_ACTION;
  noticedetailData: any;
  isResData: boolean = false;
  fileUrl: any;
  userActionData: {
    "gstin": string,
    "referenceId": string,
    "caseId": string,
    "userAction": string,
    "companyId": number
  } = {
      gstin: '',
      referenceId: '',
      caseId: '',
      userAction: '',
      companyId: 0
    };
  BHData: any;
  recordCount: any;
  gstinData: any;
  addnoticeCount: number = Number(sessionStorage.getItem('addNoticeCount') ?? 10);
  perPageLimitSection1 = 10;
  tableSection1Config = {
    itemsPerPage: this.perPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'tableSection1Config',
  };
  gstNoticeFile: any[] = [];
  filterObj: any = {};
  isFilterhighlited: boolean = false;
  isFilterDivOpen: boolean = false;
  isGstin: boolean = false;
  filterType: string = 'GSTIN';
  badgesList!: any[];
  isApplyFilter!: boolean;

  criteria: any = [];
  criteriaObje: any = {};
  filteredQueryData: any;
  badgesMap: any = badgeMapInvoice;
  filterPayload: any = {};
  queryData: any;
  currentYear: any;
  nextWeekDt: any;
  nextMonthDt: any;
  nxtDtdd1: any;
  nxtDtdd2: any;
  nxtMonthdd1: any;
  nxtMonthdd2: any;
  dwdFileName: any;
  dwdFileData: any;
  finyearData: any[] = [];
  caseTypeName = NOTICE_TYPE
  badgeColorArray = [
    'border-success',
    'border-danger',
    'border-warning',
    'border-dark',
    'border-success',
    'border-danger',
    'border-warning',
    'border-dark',
    'border-success',
  ];
  textColorArray = [
    'text-success',
    'text-danger',
    'text-warning',
    'text-dark',
    'text-success',
    'text-danger',
    'text-warning',
    'text-dark',
    'text-success',
  ];
  filters: { [key: string]: Filters } = this.initFilters();
  issueDateArray: Array<string> | null = null;

  constructor(
    private router: Router,
    private noticeService: NoticeServiceService,
    private toaster: ToasterService,
    private getterSetter: GetterSetterService,
    private modalService: NgbModal,
    private formBuilder: UntypedFormBuilder,
    private entityService: BusinessEntityService,

  ) { }

  ngOnInit() {
    // Initialize filters from session storage or default
    this.filters = <any>JSON.parse(sessionStorage.getItem('addNoticeFilter') || '{}');
    this.filters = this.initFilters();
    this.navContext = JSON.parse(sessionStorage.getItem('navContext') || '{}');
    this.selectedFilling = JSON.parse(sessionStorage.getItem('selectedFilling') || '{}');
    this.selectedGstin = this.selectedFilling.gstin;
    this.tableSection1Config.totalItems = this.addnoticeCount;
    this.tableSize = this.addnoticeCount;
    this.getNoticeData();
    this.getYears();
    this.getGstinList();
    this.setDate();
  }

  setDate() {
    this.currentYear = ((new Date()).getFullYear() + 1);
  }

  backTo() {
    this.router.navigate(['/gstNoticetMSummary']);
  }

  toAddionalDetail(applnId: number, type: any, gstinName: any, gstin: any) {
    this.router.navigate(['/gstAdditionalDetail', applnId, type, gstinName, gstin]);
  }

  initFilters(): { [key: string]: Filters } {
    return {
      gstin: { p: 'gstin', o: 'in', v: null },
      parsedDtOfAssignment: { p: 'parsedDtOfAssignment', o: 'between', v1: null, v2: null },
      fy: { p: 'fy', o: 'in', v: null },
      caseTypeName: { p: 'caseTypeName', o: 'eq', v: null },
    }
  }

  getFilters() {
    sessionStorage.setItem('addNoticeFilter', JSON.stringify(this.filters));
    return Object.values(this.filters).filter((el) => !!el.v || !!el.v1 || !!el.v2);
  }

  getYears() {
    this.finyearData = [];
    let d = new Date();
    let startYear = 2016;
    const currentYear = d.getFullYear();
    let loopyear = currentYear - startYear;
    while (loopyear >= 0) {
      let financialYrRange = (startYear) + '-' + (startYear + 1).toString().slice(-2);
      this.finyearData.push({ value: financialYrRange, disabled: false });
      startYear++;
      loopyear--;
    }
    this.finyearData = this.finyearData.reverse();
  }

  setAction(data: any) {
    this.userActionData = {
      gstin: '',
      referenceId: '',
      caseId: '',
      userAction: '',
      companyId: 0
    };

    this.userActionData.gstin = data.gstin;
    this.userActionData.referenceId = data.refId;
    this.userActionData.caseId = data.caseId;
    this.userActionData.companyId = this.companyId;
  }

  saveAccess() {
    this.noticeService.updateAddNoticePr(this.userActionData).subscribe((response: any) => {
      if (response.status == 1) {
        this.getNoticeDataList();
        this.isAccessGiven = true;
        this.closeModal.nativeElement.click();
        this.toaster.showSuccess(response.response);
      } else {
        this.toaster.showError(response.errorList[0].message);
      }
    });
    this.isAccessGiven = true;
    this.closeModal.nativeElement.click();
  }

  onClickFileName(gstNoticeFileData: any[] = []) {
    let urlData = {
      refId: [{
        "p": "refId",
        "o": "eq",
        "v": gstNoticeFileData
      }],
      companyId: this.companyId,
      module: 'Gst'
    }
    this.noticeService.getAddNoticeDwdList(urlData).subscribe((response: any) => {
      if (response.status === 1) {
        this.dwdFileData = response.response.gstCaseFiles;
      }
    });

  }

  download(key: any, id: any, fileName: string) {
    let urlData = {
      s3Key: key,
      id: id
    }
    this.noticeService.downloadANFile(urlData).subscribe((response: any) => {
      this.downloadcontFile(response, fileName);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }

  downloadcontFile(data: any, filename: any) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${filename}`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  downloadExFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `Additional Notice.xlsx`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  private getFixedCriterion() {
    return {
      o: "eq",
      p: "caseTypeName",
      v: "LETTER OF UNDERTAKING"
    };
  }

  getSection1Data(perPageLimitSection1: any, section1page: number) {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const fixedCriterion = this.getFixedCriterion();
    const dynamicFilters = this.getFilters();

    const payload: any = {
      criterias: [fixedCriterion, ...dynamicFilters],
      page: section1page == 1 ? 0 : section1page - 1,
      size: perPageLimitSection1,
      companyId: this.companyId,
      sortdir: "DESC",
      sortfield: "parsedDtOfAssignment",
      newRecord: isNewCase === "Newnotice"
    }

    if (this.navContext.entityType === 'FILING') payload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') payload.pan = this.navContext.pan;

    this.noticeService.getAddNoticeData(payload).subscribe((res: any) => {
      if (res.status == 0) {
        this.addNoticeOrderData = [];
        this.toaster.showError(res.message);
        this.isResData = true;
      } else {
        this.addNoticeOrderData = res.response.results;
        this.tableSection1Config.totalItems = res.response.count;
        this.tableSection1Config.itemsPerPage = perPageLimitSection1;
        this.recordCount = res.response.count;
        this.tableSection1Config.currentPage = payload.page + 1;
        this.isResData = false;
      }
    });
  }

  onTableDataChangeSection1(page: number): void {
    this.tableSection1Config.currentPage = page;
    this.getNoticeDataList();
  }

  getNoticeDataList() {
    if (this.issueDateArray && this.issueDateArray.length > 0) {
      this.filters.parsedDtOfAssignment.v1 = this.issueDateArray[0];
      if (!this.issueDateArray[1]) {
        return;
      } else {
        this.filters.parsedDtOfAssignment.v2 = this.issueDateArray[1];
      }
    }
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const fixedCriterion = this.getFixedCriterion();
    const dynamicFilters = this.getFilters();

    const payload: any = {
      page: this.tableSection1Config.currentPage - 1,
      size: this.perPageLimitSection1,
      companyId: this.companyId,
      sortdir: "DESC",
      sortfield: "parsedDtOfAssignment",
      module: "GST",
      newRecord: isNewCase === "Newnotice",
      criterias: [fixedCriterion, ...dynamicFilters] 
    }

    if (this.navContext.entityType === 'FILING') payload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') payload.pan = this.navContext.pan;

    this.noticeService.getAddNoticeData(payload).subscribe((res: any) => {
      if (res.status == 0) {
        this.addNoticeOrderData = [];
        this.toaster.showError(res.message);
        this.isResData = true;
      } else {
        this.addNoticeOrderData = res.response.results;
        console.log("data List :", this.addNoticeOrderData);
        this.tableSection1Config.totalItems = res.response.count;
        this.recordCount = res.response.count;
        this.tableSection1Config.currentPage = payload.page + 1;
        this.isResData = false;
      }
    });
  }

  getNoticeData() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const fixedCriterion = this.getFixedCriterion();
    const dynamicFilters = this.getFilters();

    const payload: any = {
      page: this.tableSection1Config.currentPage - 1,
      size: this.perPageLimitSection1,
      companyId: this.companyId,
      sortdir: "DESC",
      sortfield: "parsedDtOfAssignment",
      module: "GST",
      newRecord: isNewCase === "Newnotice",
      criterias: [fixedCriterion, ...dynamicFilters] // Include fixed criterion
    }

    if (this.navContext.entityType === 'FILING') payload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') payload.pan = this.navContext.pan;

    this.noticeService.getAddNoticeData(payload).subscribe((res: any) => {
      if (res.status == 0) {
        this.addNoticeOrderData = [];
        this.toaster.showError(res.message);
        this.isResData = true;
      } else {
        this.addNoticeOrderData = res.response.results;
        console.log("data :", this.addNoticeOrderData);
        this.tableSection1Config.totalItems = res.response.count;
        this.recordCount = res.response.count;
        this.tableSection1Config.currentPage = payload.page + 1;
        this.isResData = false;
      }
    });
  }

  downloadEXFile() {
    sessionStorage.setItem('addNoticeFilter', JSON.stringify(this.filters));
    this.dwdExpExcel();
  }

  dwdExpExcel() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const fixedCriterion = this.getFixedCriterion();
    const dynamicFilters = this.getFilters();

    const payload: any = {
      criterias: [fixedCriterion, ...dynamicFilters], // Include fixed criterion
      page: this.tableSection1Config.currentPage,
      size: this.perPageLimitSection1,
      companyId: this.companyId,
      sortdir: "DESC",
      sortfield: "parsedDtOfAssignment",
      newRecord: isNewCase === "Newnotice"
    }

    if (this.navContext.entityType === 'FILING') payload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') payload.pan = this.navContext.pan;

    this.noticeService.downloadEXAddNotice(payload).subscribe((response: any) => {
      this.downloadExFile(response);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }

  clearIssueGst() {
    if (this.issueDateArray && this.issueDateArray.length > 0) {
      this.filters.parsedDtOfAssignment.v1 = null;
      this.filters.parsedDtOfAssignment.v2 = null;
    }
    this.getNoticeData();
    this.issueDateArray = null;
  }

  clearFilter(inputName: string) {
    switch (inputName) {
      case 'fy':
        this.filters.fy.v = null;
        break;
      case 'caseTypeName':
        // If caseTypeName is a fixed criterion, you might want to prevent clearing it
        // Alternatively, ensure it remains "VOLUNTARY PAYMENT"
        // this.filters.caseTypeName.v = null; // Uncomment if you need to clear
        break;
      case 'gstin':
        this.filters.gstin.v = null;
        break;
      default:
        break;
    }
    this.getNoticeData();
  }

  openModal(content: any) {
    this.filterType = 'GSTIN';
    this.modalService.open(content);
  }

  // Filter Methods

  getGstinList() {
    const payload: any = {
      companyId: this.companyId,
    }
    if (this.navContext.entityType === 'FILING') payload.gstin = this.selectedGstin;
    if (this.navContext.entityType === 'LEGAL') payload.pan = this.navContext.pan;

    this.noticeService.gstGstins(payload).subscribe((res: any) => {
      if (res.status == 1) {
        if (payload.companyId && payload.gstin) {
          this.gstinData = this.selectedGstin;
          this.isGstin = true;
        } else {
          this.gstinData = res.response;
          this.isGstin = false;
        }

      }
    });
  }
}
