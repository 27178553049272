import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/services/auth.service';
import { BusinessService } from 'src/app/businessManagement/services/business.service';
import { LitigationSummaryComponent, LITIGATION_SUM_CACHE } from 'src/app/litigation/litigation-summary/litigation-summary.component';
import { LitigationServiceService } from 'src/app/litigation/services/litigation-service.service';
import { BaseResponse } from 'src/app/models/response';
import { UserRoleMap } from 'src/app/shared/constant';
import { BusinessEntityService } from 'src/app/shared/services/business-entity.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { EventEmitterService } from 'src/app/shared/services/event-emitter.service';
import { GetterSetterService } from 'src/app/shared/services/getter-setter.service';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { TokenService } from 'src/app/shared/services/token.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild(LitigationSummaryComponent) child!: LitigationSummaryComponent;
  navContext = { entityName: '', isTaxPayer: false, entityType: '', gstin: '', pan: '' };
  // @Output() onCompchange: EventEmitter<string> = new EventEmitter<string>();
  BNBusinessList: any = [];
  BNSelectedComs: any;
  NavEntityContext: any;
  isRole: any;
  companyId: any;
  BNModel: any;
  businessName: any;
  familyData: any;
  business_role: any;
  legal_role: any
  companyList: any;
  selectedcom: any;
  userRole: any;
  pobCodeForHeader: any;
  allEntity: any;
  pobData: any;
  currentUser: any;
  gstin_count: any;
  roleList: any
  count: any;
  selectedFilling: any;
  selectedGstin: any;
  subArrowShow: boolean = false;
  gstnMsg: boolean = false;
  activeLink: string = 'Dashboard';
  bussinessId: any;
  navContextdata: any;
  entitytype: any;
  regNoOpt: any = [];
  regGstinNum: any = [];
  reglegalGstinNum: any = [];
  selectedCompanyId: any;
  preGstRegData: any;
  preRegData: any = [];
  regList: any[] = [];
  pregstFilterRegNum: any = [];
  selectedUserId: any;
  leagalRegNum: any;
  isGSTRole: any;
  isPREGSTRole: any;
  isConsultant:any;
  isDTRole: any;
  isIDTRole: any;
  userData: any;
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private authService: AuthService,
    private toaster: ToasterService,
    private entityService: BusinessEntityService,
    private GetSet: GetterSetterService,
    private getterSetter: GetterSetterService,
    private eEmit: EventEmitterService,
    private litigationService: LitigationServiceService,
    private commonService: CommonService,
    private BS: BusinessService,
  ) {
    this.userRole = UserRoleMap;
  }

  ngOnInit() {
    this.initializeData();
    this.navContext = this.commonService.getNavContext();
    this.entitytype = this.navContext.entityType;
    this.selectedCompanyId = this.getterSetter.getSetCompanyId();
    this.selectedUserId = this.getterSetter.getSetUserId();
    localStorage.setItem('entityType', '');
    this.litigationService.selectedFilling$.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe((data) => {
      if (data) {
        this.selectedFilling = data;
        this.selectedGstin = this.selectedFilling.gstin;
        this.GetSet.checkEntityType.subscribe((value) => {
          this.navContext = value;
        });
      }

    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.urlAfterRedirects === '/dashboard') {
          this.activeLink = 'Dashboard';
        }
      }
    });
    //this.setfilterRegNum();
    // this.getPreGstReg();
    this.userRoleData();
    localStorage.setItem('analyticIssueLbl', '');
    localStorage.setItem('preAnalyticIssueLbl', '');
    localStorage.setItem('analyticState', '');
    localStorage.setItem('preAnalyticState', '');
    localStorage.setItem('analyticDiv', '');
  }
  litSumComp(reloadDash = false) {
    // if(this.router.url == '/gstNoticetMSummary'){
    //   return;
    // }
    if (this.router.url === '/litigationSummary') {
      this.eEmit.onFirstComponentButtonClick();
    }
    if (this.router.url === '/dashboard' && reloadDash) {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.router.onSameUrlNavigation = 'reload';

      this.router.navigate(['/dashboard']);
      return;
    }
    if (this.navContext.entityType === 'Business') {
      this.router.navigate(['/dashboard']);
      return
    }
    // TODO: Fix header file navigation
    if (this.selectedcom?.entityType === 'FILING' && this.router.url === '/directTaxAnalytics') {
      this.router.navigate(['/dashboard']);
      return;
    }
    if (this.selectedcom?.entityType === 'LEGAL' && this.router.url === '/directTaxAnalytics') {
      this.commonService.reloadCurrentRoute();
      return;
    }
    if (this.router.url === '/litigationAnalytics' || (this.selectedcom?.entityType === 'FILING' && this.router.url === '/preGstSummary')) {
      this.router.navigate(['/dashboard']);
    }
    if (this.router.url === '/litigationAnalytics' || (this.selectedcom?.entityType === 'FILING' && this.router.url === '/directTaxSummary')) {
      this.router.navigate(['/dashboard']);
    }
    if (this.router.url === '/litigationAnalytics' || (this.selectedcom?.entityType === 'FILING' && this.router.url === '/directTaxPositions')) {
      this.router.navigate(['/dashboard']);
    }
    if (this.router.url === '/litigationAnalytics' || (this.selectedcom?.entityType === 'FILING' && this.router.url === '/financialInformation')) {
      this.router.navigate(['/dashboard']);
    }
    if ((this.selectedcom?.entityType === 'LEGAL') && (this.router.url === '/preGstSummary') || (this.selectedcom?.entityType === 'LEGAL') && (this.router.url === '/financialInformation') || (this.selectedcom?.entityType === 'LEGAL') && (this.router.url === '/litigationAnalytics') || (this.router.url === '/litigationSummary') || (this.router.url === '/gstNoticetMSummary') || (this.router.url === '/gstNoticetOrder') || (this.router.url === '/gstSubmission') || (this.router.url === '/gstAdditionalOrder') || (this.router.url === '/auditSummary' || (this.router.url === '/auditCompGstDetail') || (this.router.url === '/auditTaxPosition') || (this.router.url === '/preGstAnalytics'))) {
      this.commonService.reloadCurrentRoute();
    }
    if ((this.selectedcom?.entityType === 'LEGAL') && (this.router.url === '/directTaxSummary') || (this.selectedcom?.entityType === 'LEGAL') && (this.router.url === '/directTaxPositions') || (this.router.url === '/litigationSummary') || (this.router.url === '/auditSummary' || (this.router.url === '/auditCompGstDetail') || (this.router.url === '/auditTaxPosition') || (this.router.url === '/preGstAnalytics'))) {
      this.commonService.reloadCurrentRoute();
    }
    // if((this.selectedcom?.entityType === 'LEGAL') && (this.router.url === '/preGstSummary') ||(this.selectedcom?.entityType === 'LEGAL') && (this.router.url === '/directTaxPositions') || (this.selectedcom?.entityType === 'LEGAL') && (this.router.url === '/financialInformation') || (this.selectedcom?.entityType === 'LEGAL') && (this.router.url === '/litigationAnalytics') || (this.router.url === '/litigationSummary') || (this.router.url === '/gstNoticetMSummary') || (this.router.url === '/gstNoticetOrder') || (this.router.url === '/auditSummary' || (this.router.url === '/auditCompGstDetail') || (this.router.url === '/auditTaxPosition') || (this.router.url === '/preGstAnalytics'))){
    //   this.commonService.reloadCurrentRoute();
    // }
    if ((this.router.url === '/auditRecon') && (this.selectedcom?.entityType === 'FILING' && this.navContext?.entityType !== 'Business')) {
      this.commonService.reloadCurrentRoute();
    }
    if ((this.navContext?.entityType === 'Business' || this.selectedcom?.entityType === 'LEGAL') && (this.router.url === '/auditRecon')) {

      this.router.navigate(['/dashboard']);
    }
    if (this.router.url.includes('/notice') || this.router.url.includes('/createPreGstCase') || (this.router.url.includes('/createAudit')) || (this.router.url.includes('/settings'))) {

      this.router.navigate(['/dashboard']);
    }
  }
  keyword = "name";

  selectEvent(item: any) {
    if (item) {
      if (this.BNModel.childCompanies != null && this.BNModel.childCompanies.length > 0) {
        let parentChildDict: any = {};
        let childParentList = [];
        let topLevelIds = [];

        for (var i = 0; i < this.BNModel.childCompanies.length; i++)
          topLevelIds.push(this.BNModel.childCompanies[i].companyId);

        function searchCompany(company: any) {
          if (company.companyId == item.id) {
            return company.companyId;
          }
          if (company.childCompanies)
            if (company.childCompanies.length > 0)
              for (var i = 0; i < company.childCompanies.length; i++) {
                // map child to parent.
                parentChildDict[company.childCompanies[i].companyId] = company.companyId;
                // call child companies in recursion.
                searchCompany(company.childCompanies[i]);
              }
        }
        searchCompany(this.BNModel);
        // var topLevelCompany = this.BNModel.childCompanies.rootCompanyId;
        var company = item.id;

        // iterate to parent of company untill toplevel comoany is found.
        childParentList.push(company);
        if (topLevelIds.indexOf(company) == -1) {
          if (topLevelIds.indexOf(parentChildDict[company]) != -1) {
            company = topLevelIds.indexOf(parentChildDict[company]);
            childParentList.push(company);
          } else {
            while (true) {
              company = parentChildDict[company];
              childParentList.push(company);
              if (topLevelIds.indexOf(parentChildDict[company]) != -1) {
                company = topLevelIds.indexOf(parentChildDict[company]);
                break;
              }
            }
          }
        } else {
          company = topLevelIds.indexOf(company);
        }

        childParentList = childParentList.reverse();

        this.onBNComSelected("", this.BNModel.childCompanies[company], 0);

        var nestedCompany = this.BNModel.childCompanies[company];

        childParentList.forEach((currentCompanyId, index) => {
          if (nestedCompany.childCompanies) {
            for (var i = 0; i < (nestedCompany.childCompanies ? nestedCompany.childCompanies.length : 0); i++) {
              if (nestedCompany.childCompanies[i].companyId == currentCompanyId) {
                nestedCompany = nestedCompany.childCompanies[i];
                this.onBNComSelected("", nestedCompany, index + 1);
              }
            }
          }
        });
      }
      // setTimeout(() => {
      //   this.openModelEwb();
      // }, 2000);
    } else {
    }
  }
  initializeData() {
    this.currentUser = sessionStorage.getItem('user');
    // let cmpId = sessionStorage.getItem('companyId');
    this.changeBusiness(sessionStorage.getItem('companyId'));
    localStorage.setItem('selectedId', (sessionStorage.getItem('companyId') || '{}'));

    // this.GotoUserDash();
  }
  initializeEntityList() {
    this.entityService.fetchEntity().subscribe((response: any) => {
      if (response.status === 'SUCCESS') {
        this.BNBusinessList = response.response;
      }
    })
  }
  GotoUserDash() {
    this.selectBusiness(null, '2222');
    this.NavEntityContext = { entityName: '', isTaxPayer: false, entityType: 'Business', pan: '' };
    this.getSetNavContext(this.NavEntityContext);
    this.BNModel = {};
    this.subArrowShow = true;
    this.BNSelectedComs = [];
    this.getBusinessHierarchy();
    this.litSumComp(true);
    if (this.GetSet.getSetCompanyId()) {
      this.viewBusinessHierarchy(this.GetSet.getSetCompanyId());

    }

    // this.router.navigate(['/dashboard']);
  }
  getSetNavContext(navContext?: any) {
    if (navContext) {
      sessionStorage.setItem('navContext', JSON.stringify(navContext));
      // this.getterSetter.checkEntityType.next(navContext);
      this.getterSetter.setBusinessData(navContext);
    } else {
      navContext = JSON.parse(sessionStorage.getItem('navContext') || '{}');
      if (!navContext) {
        navContext = { entityName: '', isTaxPayer: false }
      }
    }
    return navContext;
  }
  getBusinessHierarchy() {
    let cmpId = sessionStorage.getItem('companyId');
    let selectedEntity = null;
    this.entityService.fetchEntity().subscribe((response: any) => {
      this.BNBusinessList = response.response;
      this.BNBusinessList.sort((a: any, b: any) => a.company.localeCompare(b.company));
      if (cmpId) {
        selectedEntity = this.BNBusinessList.filter((entity: any) => entity.companyid == cmpId);
        this.GetSet.setAccessProduct('topazAccess', selectedEntity.length > 0 ? selectedEntity[0].topazAccess : false);
        this.GetSet.setAccessProduct('sapphireAccess', selectedEntity.length > 0 ? selectedEntity[0].sapphireAccess : false);
        this.GetSet.setAccessProduct('onyxAccess', selectedEntity.length > 0 ? selectedEntity[0].onyxAcess : false);

        this.entityService.fetchBusinessHierarchy(cmpId).subscribe((response: any) => {
          this.BNModel = response.response;
          this.isRole = response.response.roleName;
          localStorage.setItem('roleName', response.response.roleName);
          this.checkRole(this.isRole)
          if (this.isRole) {
            this.GetSet.set_UserRole(this.isRole);
          } else {
            this.GetSet.get_UserRole();
          }
          this.selectAllChildVal(this.BNModel, []);
          if (this.NavEntityContext && this.BNModel.entityType == 'BUSINESS') {
            this.NavEntityContext.pan = this.BNModel.pan;
          }
          if (this.BNModel.childCompanies != undefined) {
            var leng = Object.keys(this.BNModel.childCompanies).length;

            for (var i = 0; i < leng; i++) {
              this.showPath(this.BNModel.childCompanies[i]);
            }
            this.BNModel.childCompanies.sort((a: any, b: any) => a.companyName.localeCompare(b.companyName));
          }
          this.getSetNavContext(this.NavEntityContext);

          if (this.NavEntityContext && this.NavEntityContext.companyId) {
            this.companyId = this.NavEntityContext.companyId;
            var navCompanies = this.BNModel;
            this.BNSelectedComs = [];
            for (var i = 0; i < this.NavEntityContext.navBarHierarchy.length; i++) {
              navCompanies = this.findCompanyObj(this.NavEntityContext.navBarHierarchy[i], navCompanies.childCompanies);
              if (navCompanies.childCompanies != '' && navCompanies.childCompanies != null) {
                for (var j = 0; j < navCompanies.childCompanies.length; j++) {
                  this.showPath(navCompanies.childCompanies[j]);
                }
              }
              this.BNSelectedComs.push(navCompanies);
            }
          }
        }, (err) => {
          this.toaster.showError("Network Error");
        });
      }
    });
  }
  viewBusinessHierarchy(cmpId?: any, com?: any) {
    if (com) {
      this.businessName = com.companyName;
      this.familyData = com;
      this.business_role = com.roleName;
      this.isRole = com.roleName;
      if (com.childCompanies != '') {
        this.countG(com.childCompanies);
      } else {
        this.countG([com], false);
      }
    } else if (cmpId) {
      this.entityService.fetchBusinessHierarchy(cmpId).subscribe((response: any) => {
        if (response.status == 'SUCCESS' && response.response != null) {
          this.businessName = response.response.companyName;
          this.familyData = response.response;
          localStorage.setItem('roleName', response.response.roleName);
          this.business_role = response.response.roleName;
          this.checkRole(this.business_role)
          if (response.response != null && response.response.childCompanies == '') {
            this.countG([response.response]);
          } else if (response.response) {
            this.countG(response.response.childCompanies);
            var leng = Object.keys(response.response.childCompanies).length;
            let arrRegGstin = []
            for (var i = 0; i < leng; i++) {
              this.regNoOpt = response.response.childCompanies;
              arrRegGstin.push(response.response.childCompanies[i].gstin);
            }
            const regGstinNumData = this.regNoOpt.map((data: any) => data.gstin);
            this.regGstinNum = regGstinNumData.filter(function (element: any) {
              return element !== undefined;
            });
            localStorage.setItem('filterRegNum', this.regGstinNum);

          } else {
            this.gstin_count = 0;
          }
        } else {
          this.gstin_count = 0;
        }
        this.NavEntityContext = this.getSetNavContext();
        if (this.NavEntityContext && this.NavEntityContext.companyId) {
          if (this.NavEntityContext.childCompaniesNo) {
            this.gstin_count = this.NavEntityContext.childCompaniesNo;
          }
          this.companyId = this.NavEntityContext.companyId;
          let compHierarchy = this.familyData;
          let businessHierarchy;
          for (var i = 0; i < this.NavEntityContext.navBarHierarchy.length; i++) {
            compHierarchy = this.findCompanyObj(this.NavEntityContext.navBarHierarchy[i], compHierarchy.childCompanies);
            businessHierarchy = compHierarchy;

          }

          this.familyData = businessHierarchy;
          console.log('Family Data',this.familyData);
        }

      }, (err) => {
        this.toaster.showError("Network Error");
      });
    } else {
      this.toaster.showError("No Business Entity found!");
    }
  }
  userRoleData() {
    let model: any = {}
    model.companyId = this.selectedCompanyId,
      model.userMailId = this.selectedUserId
    this.commonService.userRole(model).subscribe((response: any) => {
      if (response.status === "SUCCESS") {
        this.userData = response.response[0].roleName;
        localStorage.setItem('role', response.response[0].roleName);
        this.checkRole(this.userData)
      }
    })

  }
  selectAllChildVal(item: any, childNodes: any) {
    if (item.childCompanies != null && item.childCompanies.length > 0) {
      for (var i = 0; i < item.childCompanies.length; i++) {
        if (item.childCompanies[i].roleName == 'Admin' ||
          item.childCompanies[i].roleName == 'Preparer' ||
          item.childCompanies[i].roleName == 'Consultant' ||
          item.childCompanies[i].roleName == 'Viewer' ||
          item.childCompanies[i].roleName == 'GST_Admin' ||
          item.childCompanies[i].roleName == 'GST_Viewer' ||
          item.childCompanies[i].roleName == 'GST_Preparer' ||
          item.childCompanies[i].roleName == 'PREGST_Admin' ||
          item.childCompanies[i].roleName == 'PREGST_Viewer' ||
          item.childCompanies[i].roleName == 'PREGST_Preparer' ||
          item.childCompanies[i].roleName == 'GST_Consultant' ||
          item.childCompanies[i].roleName == 'PREGST_Consultant' ||
          item.childCompanies[i].roleName == 'Consultant' ||
          item.childCompanies[i].roleName == 'DT_Admin' ||
          item.childCompanies[i].roleName == 'DT_Viewer' ||
          item.childCompanies[i].roleName == 'DT_Preparer' ||
          item.childCompanies[i].roleName == 'IDT_Admin' ||
          item.childCompanies[i].roleName == 'IDT_Viewer' ||
          item.childCompanies[i].roleName == 'IDT_Preparer' ||
          item.childCompanies[i].roleName == 'Signatory' ||
          item.childCompanies[i].roleName == 'Guest'
          || item.childCompanies[i].roleName == 'Maker') {
          if (item.childCompanies[i].entityType != 'POB') {
            childNodes.push({
              'name': item.childCompanies[i].companyName,
              'id': item.childCompanies[i].companyId
            });
          }

        }

        this.selectAllChildVal(item.childCompanies[i], childNodes);
      }
      this.allEntity = childNodes;
    }
  }
  showPath(obj: any) {
    if (obj.roleName != 'NoRole') {
      if (obj.childCompanies != null && obj.childCompanies.length > 0) {
        var l = Object.keys(obj.childCompanies).length;
        for (var i = 0; i < l; i++) {
          var state = this.showPath(obj.childCompanies[i]);
          if (state == true) {
            return obj.visible = true;
          }
        }
      }

      return obj.visible = true;
    } else {
      if (obj.childCompanies != null && obj.childCompanies.length > 0) {
        var l = Object.keys(obj.childCompanies).length;
        for (var i = 0; i < l; i++) {
          var state = this.showPath(obj.childCompanies[i]);
          if (state == true) {
            return obj.visible = true;
          }
        }
      }

      return obj.visible = false;
    }
  }
  findCompanyObj(comId: any, obj: any) {
    return obj.find((com: any) => {
      if (comId == com.companyId)
        return com;
    }
    );
  }
  countG(obj?: any, isRecursive?: any) {

    if (!isRecursive) {
      this.count = 0;
    }
    if (obj != undefined) {
      var leng = Object.keys(obj).length;
      for (var i = 0; i < leng; i++) {
        if (obj[i].entityType == "FILING" && obj[i].roleName != 'NoRole') {
          this.count++;
        } else if (obj[i].entityType == "LEGAL") {
          this.countG(obj[i].childCompanies, true);
        }
      }
    }

    this.gstin_count = this.count;
    this.NavEntityContext.childCompaniesNo = this.count;
  }
  changeBusiness(companyId: any, reloadDashboard = false) {

    this.bussinessId = companyId;
    if (companyId) {
      this.entityService.changeCompany(companyId).subscribe((response: any) => {
        this.BNSelectedComs = [];
        if (response.status === 'SUCCESS') {
          localStorage.setItem('selectedId', companyId);

          if (response.response && response.response.length > 0 && response.response[0].companyid) {
            let companyId = response.response[0].companyid;
            let company = response.response[0].company;
            this.NavEntityContext = { entityName: '', isTaxPayer: false, entityType: 'Business', pan: '' };
            this.getSetNavContext(this.NavEntityContext);
            this.subArrowShow = true;
            this.GetSet.getSetCompanyId(companyId);
            this.GetSet.getSetCompany(company);
            this.viewBusinessHierarchy(companyId);
            this.getBusinessHierarchy();
            this.userRoleData();
            this.getPreGstReg();
            this.litSumComp(reloadDashboard);
            if (response.response[0].products.length > 0) {
              this.GetSet.setProduct(response.response[0].products);
            }
            //this.toaster.showSuccess(response.message);
            if (this.router.url !== '/gstNoticetMSummary') {
              this.router.navigate(['/dashboard']);
            }

          }
        } else {
          this.toaster.showInfo(response)
        }
      }, (err) => {
        this.toaster.showError("Network Error!");
      });
    }

  }

  GotoDash(com: any) {
    localStorage.setItem('selectedId', com.companyId);
    localStorage.setItem('entityType', com.entityType);
    localStorage.setItem('filterRegNum', this.regGstinNum);
    //localStorage.setItem('filterPreGstRegNum', this.pregstFilterRegNum);
    //this.getPreGstReg();
    //this.setfilterRegNum();
    this.isRole = com.roleName;
    this.BNSelectedComs = [];
    let businessHierarchy = this.viewBusinessHierarchy(this.GetSet.getSetCompanyId());

    this.NavEntityContext = { entityName: '', isTaxPayer: false, entityType: 'Business', pan: '' };
    if (this.NavEntityContext && com.entityType == 'BUSINESS') {
      this.NavEntityContext.pan = com.pan;
    }
    this.getSetNavContext(this.NavEntityContext);
    this.gstnMsg = false;
    // this.router.navigate(['/dashboard']);
    this.litSumComp(true);
  }
  onBNComSelected(companyName: any, com: any, index: any, reloadDashboard = false) {
    this.selectBusiness(com.companyId, '1111');
    sessionStorage.removeItem(LITIGATION_SUM_CACHE);
    localStorage.setItem('entityType', com.entityType);
    this.isRole = com.roleName;
    if (com.entityType == "FILING") {
      sessionStorage.setItem('filingEntityName', companyName);
      localStorage.setItem('filterRegNum', com.gstin);
      //localStorage.setItem('filterPreGstRegNum', this.pregstFilterRegNum);
    }
    if (this.NavEntityContext && com.entityType == 'LEGAL') {
      this.NavEntityContext.pan = com.pan;
      //this.getLegalRegNum();

    }
    this.getSetNavContext(this.NavEntityContext);
    if (index == 0) {
      this.BNSelectedComs = [];
      this.NavEntityContext.navBarHierarchy = [];
    }
    for (var i = 0; i < this.BNSelectedComs.length; i++) {
      var isAvail = this.BNSelectedComs.findIndex((e: any) => e.parentCompanyId === com.parentCompanyId); //changes done function issue
      if (isAvail != -1) {
        this.BNSelectedComs.pop();
        this.NavEntityContext.navBarHierarchy.pop();
      }
    }
    if (com.childCompanies != null && com.childCompanies.length > 0) {
      for (var i = 0; i < com.childCompanies.length; i++) {
        this.showPath(com.childCompanies[i]);
      }
    }
    if (com.entityType != 'POB')
      this.BNSelectedComs.push(com);
    if (com.entityType == "FILING") {
      this.entityService.setPobVal(com.childCompanies);
    }
    if (com.entityType === 'LEGAL') {
      this.entityService.setLegalHierarchy(com.childCompanies);
      localStorage.setItem('filterRegNum', this.regGstinNum);
      this.getPreGstReg();
      this.userRoleData();

      //localStorage.setItem('filterPreGstRegNum', this.pregstFilterRegNum);
      //this.getPreGstReg();
    }
    this.entityService.fetchFillingBusiness().subscribe((response: any) => {
      if (response.status == 'FAILURE') {
        this.toaster.showError(response.errors[0].field);
      } else if (response.status == 'SUCCESS') {
        this.companyList = response.response;
        //   this.roleList = response.response[0].roleName;
        //  sessionStorage.setItem('roleName',this.roleList);
        //  this.legal_role = sessionStorage.getItem('roleName');
        this.reglegalGstinNum = this.companyList.map((data: any) => data.gstin);
        if (com.entityType == 'FILING') {
          var L = this.companyList.find((rt: any) => {
            if (rt.id == com.companyId)
              return rt;
          });
          this.getSetFilling(L);
          this.GoToDashboard(com, true, L, true);
        } else {
          this.GoToDashboard(com, true, null, true);
        }
      }
    });
  }
  getSetFilling(selectedFilling: any) {
    if (selectedFilling) {
      sessionStorage.setItem('selectedFilling', JSON.stringify(selectedFilling));
    } else {
      selectedFilling = JSON.parse('{}');
    }
    this.litigationService.modifyFilling(selectedFilling);
    return selectedFilling;
  }
  GoToDashboard(com: any, isForward: any, selectedCompObj?: any, reloadDashboard = false): any {
    localStorage.setItem('selectedId', com.companyId);
    localStorage.setItem('entityType', com.entityType);
    let companyId;
    if (!isForward) {
      for (var i = this.NavEntityContext.navBarHierarchy.length - 1; i > 0; i--) {
        if (this.NavEntityContext.navBarHierarchy[i] != com.companyId) {
          this.NavEntityContext.navBarHierarchy.pop();
        }
      }
      for (var i = this.BNSelectedComs.length - 1; i > 0; i--) {
        if (this.BNSelectedComs[i].companyId != com.companyId) {
          this.BNSelectedComs.pop();
        } else
          break;
      }
    }
    this.NavEntityContext.entityType = com.entityType;
    if (this.NavEntityContext.navBarHierarchy) {
      if (this.NavEntityContext.navBarHierarchy.indexOf(com.companyId) == -1)
        this.NavEntityContext.navBarHierarchy.push(com.companyId);
    }
    this.NavEntityContext.isTaxPayer = false;

    if (com.entityType == 'FILING') {
      localStorage.setItem('filterRegNum', com.gstin);
      //localStorage.setItem('filterPreGstRegNum', this.pregstFilterRegNum);
      this.NavEntityContext.isTaxPayer = true;
      this.validateGstn(JSON.parse(sessionStorage.getItem('selectedFilling') || '{}') != null ? JSON.parse(sessionStorage.getItem('selectedFilling') || '{}').gstin : null);
      if (isForward)
        this.NavEntityContext.gstin = selectedCompObj.gstin;
    } else {
      this.validateGstn(null);
    }
    this.NavEntityContext.entityName = com.companyName;
    if (com.pobCode) {
      this.NavEntityContext.pobCode = com.pobCode;
      this.pobCodeForHeader = com.pobCode;
    } else {
      this.NavEntityContext.pobCode = null;
      this.pobCodeForHeader = '';
    }
    if (com.companyId) {
      this.NavEntityContext.companyId = com.companyId;
      companyId = com.companyId;
    } else {
      companyId = this.GetSet.getSetCompanyId();
    }
    this.selectedcom = com;
    this.viewBusinessHierarchy(companyId, com);
    this.getSetNavContext(this.NavEntityContext);
    console.log(this.NavEntityContext, "this.NavEntityContext")
    // this.router.navigate(['/dashboard']);
    this.litSumComp(reloadDashboard);

  }
  validateGstn(gstinno: any) {
    if (gstinno != null) {
      this.entityService.validateGstnSessionUrl(gstinno).subscribe((response: any) => {
        if (response && response.status == 'SUCCESS' && response.response != null) {
          if (response.response.nicCredentialsVerified == 'N') {
            this.gstnMsg = true;
          } else {
            this.gstnMsg = false;
          }
        } else {
          this.gstnMsg = true;
        }
        this.setgstnConnection(!this.gstnMsg);
      }, (e) => {
        // this.toaster.showError("Network Error" + e);
      })
    } else {
      this.gstnMsg = false;
    }
    //}
  }
  setgstnConnection(value: any) {
    if (value) {
      sessionStorage.setItem("gstnCon", value);
    } else {
      sessionStorage.setItem("gstnCon", 'false');
    }
  }
  getCompanyList() {
    this.authService.comapnyList().subscribe((response: any) => {
      if (response.status == "SUCCESS") {

      } else {
        this.toaster.showError(response.message)
      }
    });
  }
  settings(familyDataVal: any) {
    this.getterSetter.setViewHierarchyData(familyDataVal);
    this.router.navigate(['settings']);
  }
  masterSettings(familyDataVal: any){
    console.log('master page navigated...');
    this.getterSetter.setViewHierarchyData(familyDataVal);
    this.router.navigate(['masters']);
  }
  emailSetting(familyDataVal: any) {
    this.getterSetter.setViewHierarchyData(familyDataVal);
    //
    this.router.navigate(['/emailSetting']);
  }
  logout() {
    if (this.tokenService.destroy()) {
      this.authService.setLoginIndicator(0);
      window.open(this.tokenService.getUumUrl(), '_self');
    } else {
      this.toaster.showError('Something Went wrong !');
    }
  }

  addBusiness() {
    this.router.navigate(['add-business']);
  }

  litigationSummary() {
    this.activeLink = 'Litigation';
    this.router.navigate(['/litigationSummary']);
  }
  litigationAnalytics() {
    this.activeLink = 'Litigation';
    this.router.navigate(['/litigationAnalytics']);
  }

  litigationSmartUpload() {
    this.activeLink = 'Litigation';
    this.router.navigate(['/litigationSmartUpload']);
  }
  dashboard() {
    this.activeLink = 'Dashboard';
    this.router.navigate(['/dashboard']);
  }

  // auditDashboard() {
  //   this.activeLink = 'Audit';
  //   this.router.navigate(['/auditDashboard']);
  // }
  auditSummary() {
    this.activeLink = 'Audit';
    this.router.navigate(['/auditSummary']);
  }
  auditCompGstDetail() {
    this.activeLink = 'Audit';
    this.router.navigate(['/auditCompGstDetail']);
  }
  auditTaxPosition() {
    this.activeLink = 'Audit';
    this.router.navigate(['/auditTaxPosition']);
  }
  auditRecon() {
    this.activeLink = 'Audit';
    this.router.navigate(['/auditRecon']);
  }
  preGstSummary() {
    this.activeLink = 'preGst';
    this.router.navigate(['/preGstSummary']);
  }

  preGstAnalytics() {
    this.activeLink = 'preGst';
    this.router.navigate(['/preGstAnalytics']);
  }

  directTaxSummary() {
    this.activeLink = 'dirTax';
    this.router.navigate(['/directTaxSummary']);
  }
  directTaxAnalytics() {
    this.activeLink = 'dirTax';
    this.router.navigate(['/directTaxAnalytics']);
  }
  dirTaxPositions() {
    this.activeLink = 'dirTax';
    this.router.navigate(['/directTaxPositions']);
  }

  dirFinInformation() {
    this.activeLink = 'dirTax';
    this.router.navigate(['/financialInformation']);
  }
  gstNotice() {
    this.activeLink = 'noticeManagement';
    this.router.navigate(['/gstNoticetMSummary']);
  }
  dtNotice() {
    this.activeLink = 'noticeManagement';
    // this.router.navigate(['/dtNmSummary']);
    this.router.navigate(['/dtNmSummary']);
  }


  selectBusiness(companyId: any, data: any) {
    if (companyId) {
      localStorage.setItem('selectedId', companyId);
    } else {
      localStorage.setItem('selectedId', this.bussinessId);

    }
  }
  setfilterRegNum() {
    //if(this.navContext.entityType === 'Business'){
    let cmpId = localStorage.getItem('selectedId');
    this.entityService.fetchEntity().subscribe((response: any) => {
      if (cmpId) {
        this.entityService.fetchBusinessHierarchy(cmpId).subscribe((response: any) => {
          this.BNModel = response.response;
          if (this.BNModel.childCompanies != undefined) {
            var leng = Object.keys(this.BNModel.childCompanies).length;
            let arrRegGstin = []
            for (var i = 0; i < leng; i++) {
              this.regNoOpt = this.BNModel.childCompanies;
              arrRegGstin.push(this.BNModel.childCompanies[i].gstin);
            }
            //this.regGstinNum = this.regNoOpt.filter((g : any) =>g.gstin);
            const regGstinNumData = this.regNoOpt.map((data: any) => data.gstin);
            this.regGstinNum = regGstinNumData.filter(function (element: any) {
              return element !== undefined;
            });
            localStorage.setItem('filterRegNum', this.regGstinNum);
          }
        });
      }
    });
    //    }

  }

  getLegalRegNum() {
    const navContext = JSON.parse(this.getterSetter.getNavContextData() || '{}');
    let model = {
      companyId: localStorage.getItem('selectedId'),
      userMailId: this.selectedUserId
    }
    this.entityService.getLegalRegNum(model).subscribe((response: any) => {
      if (response.status == 'SUCCESS') {
        this.leagalRegNum = response.response;
        //console.log('skkkLegal', this.leagalRegNum)
        localStorage.setItem('filterLegalRegNum', this.leagalRegNum);
      }
    })
  }
  getPreGstReg() {
    const navContext = JSON.parse(this.getterSetter.getNavContextData() || '{}');
    let model: any = {};
    if (localStorage.getItem('entityType') == 'BUSINESS') {
      model.companyId = sessionStorage.getItem('companyId');
    } else if (localStorage.getItem('entityType') == 'LEGAL') {
      model.companyId = sessionStorage.getItem('companyId');
      model.panId = navContext.pan
    }
    else if (localStorage.getItem('entityType') == '') {
      model.companyId = sessionStorage.getItem('companyId');
      //model.panId = navContext.pan
    }
    this.BS.getPreGstRegData(model).subscribe((response: any) => {
      if (response.status == 1) {
        this.preGstRegData = response.response;
        if (this.preGstRegData != null) {
          var panKeys = Object.keys(this.preGstRegData);
          this.preRegData = [];
          this.regList = [];
          for (var i = 0; i < panKeys.length; i++) {
            let preRegData = this.preGstRegData[panKeys[i]];
            let row: any = {
              pan: panKeys[i],
            };
            let stateDataArray: any = [];
            var stateDataval = Object.keys(preRegData);

            // state list
            for (let state in stateDataval) {
              let stateData = {
                state: stateDataval[state],
              }
              stateDataArray.push(stateData);
            }
            row.stateData = stateDataArray;

            // get other data related to state
            this.preRegData.push(row);
            row.stateData.forEach((s: any) => {
              s.data = response.response[row.pan][s.state];

              s.data.forEach((d: any) => {
                this.regList.push({ regNo: d.regNo, state: d.state });
              })

            })

          }
        }
        const pregstRegNum = this.regList.map((data: any) => data.regNo);
        //console.log('pregstdata', pregstRegNum)
        this.pregstFilterRegNum = pregstRegNum;
        localStorage.setItem('filterPreGstRegNum', this.pregstFilterRegNum);

      }
    });

  }

  checkRole(role: string) {
    if (role != undefined) {
      this.isGSTRole = role.startsWith('GST_') ? true : false;
      this.isPREGSTRole = role.startsWith('PREGST_') ? true : false;
      this.isConsultant = role.startsWith('Consultant') ? true : false;
      this.isDTRole = role.startsWith('DT_') ? true : false;
      this.isIDTRole = role.startsWith('IDT_') ? true : false;
    }
  }
  checkAccessProducts(params: any) {
    let flag = this.GetSet.getAccessProduct(params);
    return flag == 'true' ? true : false;
  }
  navigateTo(portal: any) {
    let authToken = sessionStorage.getItem('jwtToken') ? sessionStorage.getItem('jwtToken') : null;
    if (authToken) {
      switch (portal) {
        case 'Topaz':
          if (window.location.origin.includes("onyx-app.irisgst.com")) {
            window.open(`https://topaz-app.irisgst.com/#/tokenVerify?token=${authToken}`, '_blank');
          } else {
            window.open(`https://topaz-dev.app.irisgst.com/#/tokenVerify?token=${authToken}`, '_blank');
          }
          break;
        case 'Sapphire':
          window.open(`https://asp.sapphire-app.irisgst.com/#/tokenVerify?token=${authToken}&sapphireTenent=asp`, '_blank');
          break;
        case 'Onyx':
          if (window.location.origin.includes("dev.new.lms.irisgst.com")) {
            window.open(`https://onyx-dev.app.irisgst.com/token-verify?token=${authToken}`, '_blank');
          } else {
            window.open(`https://onyx-app.irisgst.com/token-verify?token=${authToken}`, '_blank');
          }

          break;
        case 'LMS':
          window.open(`${environment.lmsUrl}/token-verify?token=${authToken}`, '_blank');
          break;
      }
    }
  }
}
