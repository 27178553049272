import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';
import { GetterSetterService } from '../services/getter-setter.service';
import { LoaderService } from '../services/loader.service';
import { share, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})

export class ApiInterceptor {
    tokenVal : any;
    constructor(
        private tokenService: TokenService,
        private GetSet: GetterSetterService,
        private loaderService: LoaderService
    ) {
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.showLoader();
        const { url } = req;
        if (url.indexOf('/login') > -1 || url.indexOf('/registration') > -1 || url.indexOf('/checktokenvalidity') > -1 ||
            url.indexOf('/forgotpassword') > -1 || url.indexOf('/completeRegistration') > -1 || url.indexOf('/resetpassword') > -1 || url.indexOf('/confirmregistration') > -1
            || url.indexOf('/user/changepassword') > -1 || url.indexOf('/token/validate') > -1) {

            req = req.clone({
                headers: req.headers.set('tenant', 'dev')
            });
            return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.onEnd();
                }
            },
                (err: any) => {
                    this.onEnd();
                }));
        }
        this.tokenVal = this.tokenService.getter();
        req = req.clone({
            headers: req.headers.set('X-Auth-Token', this.tokenVal)
        });
        req = req.clone({
                headers: req.headers.set('PRODUCT', 'LMS')
        });

        if (this.GetSet.getSetCompanyId()) {
            req = req.clone({
                headers: req.headers.set('companyId', this.GetSet.getSetCompanyId())
            });
        }
        // if (this.GetSet.getSetOrgId()) {
        //     req = req.clone({
        //         headers: req.headers.set('orgId', this.GetSet.getSetOrgId())
        //     });
        // }
        // if (url.indexOf('/upload/invoices') > -1 || url.indexOf('/einvoice/logo/add') > -1 || url.indexOf('/upload/vendorMaster') > -1) {
        //     // req = req.clone({
        //     //     headers: req.headers.set('Content-Type', 'multipart/form-data')
        //     // });
        // } else {
        //     req = req.clone({
        //         //headers: req.headers.set('Content-Type', 'application/json')
        //     });
        // }
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.onEnd();
            }
        },
            (err: any) => {
                this.onEnd();
            })).pipe(share());
    }

    private onEnd(): void {
        this.hideLoader();
    }
    private showLoader(): void {
        this.loaderService.show();
    }
    private hideLoader(): void {
        this.loaderService.hide();
    }
}
