import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DtNmStats, NoticeServiceService } from '../../services/notification.service';
import { asessmentYears } from 'src/app/helpers/date.utils';
import { DT_NM_AY, DT_NM_RESPONSE, DT_NM_STATUS } from 'src/app/shared/constant';
import { ToasterService } from 'src/app/shared/services/toaster.service';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
export enum NOTICE_SORT {
  PAN = 'Pan',
  AY = 'AY',
  NOTICE_ID = 'Notice ID',
  DOCUMENT_REF_ID = 'Document Ref. ID',
  DATE_OF_ISSUE = 'Date of Issue',
  DUE_DATE = 'Due Date',
  LAST_ADJOURNED_DATE = 'Last Adjourned Date',
  NOTICE_SEC = 'Notice Sec',
  RESPONSE = 'Response'
}
interface Filters {
  p: 'ay' | 'parsedIssuedOn' | 'parsedResponseDueDate' | 'pan' | 'isSubmitted' | 'proceedingStatus' | 'noticeName' | 'noticeSection';
  o: 'in' | 'eq' | 'between';
  v?: string | number | null;
  v1?: string | number | null;
  v2?: string | number | null;
}
const ProceedingStatus = {
  'C': 'Closed',
  'O': 'Open'
}
@Component({
  selector: 'app-notices',
  templateUrl: './notices.component.html',
  styleUrls: ['./notices.component.css']
})
export class NoticesComponent implements OnInit {
  @ViewChild('closeModal', { static: false }) closeModal!: ElementRef;
  @ViewChild('closeUnModal', { static: false }) closeUnModal!: ElementRef;
  proceedingStatus = {
    'C': 'Closed',
    'O': 'Open'
  }
  dtNmStats: null | DtNmStats = null;
  proceedCnt: any = Number(sessionStorage.getItem('DtproceedCnt') ?? 10);
  rootCompanyId: number = Number(sessionStorage.getItem('companyId'));
  companyId: number = Number(localStorage.getItem('selectedId'));
  childCompanyId: number | null = null;
  currentYear: any = ((new Date()).getFullYear() + 1);
  noticeData: any;
  totalCount: any;
  perPageLimitSection1 = 10;
  pageSection1: number = 1;
  tableSize: number = 10;
  filterDropDown: any = [];
  assessYears = DT_NM_AY;
  status = DT_NM_STATUS;
  noticeRes = DT_NM_RESPONSE;
  issueDateArray: Array<string> | null = null;
  dueDateArray: Array<string> | null = null;
  noticeFiles: any[] = [];
  filters: { [key: string]: Filters } = this.initFilters();
  noticeConfig = {
    itemsPerPage: this.perPageLimitSection1,
    currentPage: this.pageSection1,
    totalItems: 0,
    id: 'noticeConfig',
  };
  userActionData: {
    "_id": string,
    "companyId": number,
    "type": string,
    "userAction": string
  } = {
      _id: '',
      companyId: 0,
      type: '',
      userAction: ''
    };
  noticeSort = NOTICE_SORT;
  sorting: { pan: boolean, ay: boolean, noticeId: boolean, documentReferenceId: boolean, parsedIssuedOn: boolean, parsedResponseDueDate: boolean, parsedAdjournmentDueDate: boolean, noticeSection: boolean, isSubmitted: boolean } =
    { pan: false, ay: false, noticeId: false, documentReferenceId: false, parsedIssuedOn: false, parsedResponseDueDate: false, parsedAdjournmentDueDate: false, noticeSection: false, isSubmitted: false };


  constructor(
    private noticeService: NoticeServiceService,
    private toaster: ToasterService,
    private router: Router,
    private commonServ: CommonService,
  ) { }
  ngOnInit() {
    this.filters = <any>JSON.parse(sessionStorage.getItem('dtNmNoticFilter') || '{}');
    if ((this.filters && Object.keys(this.filters).length == 0) || !this.filters) {
      this.filters = this.initFilters();
    }
    this.countData();
    this.noticeListData();
    this.filterValue();
    this.noticeConfig.totalItems = this.proceedCnt;
    this.tableSize = this.proceedCnt;
  }
  initFilters(): { [key: string]: Filters } {
    return {
      ay: { p: 'ay', o: 'in', v: null },
      parsedIssuedOn: { p: 'parsedIssuedOn', o: 'between', v1: null, v2: null },
      parsedResponseDueDate: { p: 'parsedResponseDueDate', o: 'between', v1: null, v2: null },
      pan: { p: 'pan', o: 'in', v: null },
      isSubmitted: { p: 'isSubmitted', o: 'in', v: null },
      proceedingStatus: { p: 'proceedingStatus', o: 'in', v: null },
      noticeName: { p: 'noticeName', o: 'in', v: null },
      noticeSection: { p: 'noticeSection', o: 'in', v: null },
    }
  }
  getFilters() {
    sessionStorage.setItem('dtNmNoticFilter', JSON.stringify(this.filters));
    return Object.values(this.filters).filter((el) => !!el.v || !!el.v1 || !!el.v2);
  }
  //count api 
  countData() {
    let model: any = {};
    model.companyId = sessionStorage.getItem('companyId');
    model.sortdir = "ASC",
      model.sortfield = "createdAt",
      model.module = "DT",
      this.noticeService.getDtNmCount(model).subscribe((response:any) => {
        if (response.status === 1) {
            this.dtNmStats = response.response;
        }
      });
  }
  //count api 

  //proceeding list data api
  noticeListData() {
    if (this.issueDateArray && this.issueDateArray.length > 0) {
      this.filters.parsedIssuedOn.v1 = this.issueDateArray[0];
      if (!this.issueDateArray[1]) {
        return;
      } else {
        this.filters.parsedIssuedOn.v2 = this.issueDateArray[1];
      }
    }
    if (this.dueDateArray && this.dueDateArray.length > 0) {
      this.filters.parsedResponseDueDate.v1 = this.dueDateArray[0];
      if (!this.dueDateArray[1]) {
        return;
      } else {
        this.filters.parsedResponseDueDate.v2 = this.dueDateArray[1];
      }
    }
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.noticeConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      module: "DT",
      criterias: this.getFilters(),
      newRecord: isNewCase === "Newnotice"
    }
    this.noticeService.getNoticeListData(payload).subscribe((res) => {
      this.noticeData = res.response.results;
      this.noticeConfig.totalItems = res.response.count;
      this.totalCount = res.response.count

    });
  }
  //proceeding list data api

  // set user action
  setAction(data: any) {
    this.userActionData = {
      _id: '',
      companyId: 0,
      type: '',
      userAction: ''

    };

    this.userActionData._id = data._id;
    this.userActionData.companyId = this.companyId;
    this.userActionData.type = 'DtNotice';
    if (data.userAction === 'Yet to read') {
      this.userActionData.userAction = 'Read by User';
    } else {
      this.userActionData.userAction = 'Yet to read';
    }
  }
  confirmMarkRead() {
    this.noticeService.confirmDtUserAction(this.userActionData).subscribe((response: any) => {
      if (response.status == 1) {
        this.closeModal.nativeElement.click();
        this.toaster.showSuccess(response.response);
        this.noticeListData();
      } else {
        this.toaster.showError(response.errorList[0].message);
      }

    });

  }
  confirmMarkUnRead() {
    this.noticeService.confirmDtUserAction(this.userActionData).subscribe((response: any) => {
      if (response.status == 1) {
        this.closeUnModal.nativeElement.click();
        this.toaster.showSuccess(response.response);
        this.noticeListData();
      } else {
        this.toaster.showError(response.errorList[0].message);
      }

    });

  }
  // set user action

  //filter dropdown Values
  filterValue() {
    const payload: any = {
      type: 'DtNotice',
      module: "DT",
    }
    this.noticeService.getFilterValue(payload).subscribe((res) => {
      this.filterDropDown = res.response;
    });

  }
  //filter  dropdown Values

  //Export to excel download
  downloadEXFile() {
    this.getFilters();
    this.dwdExpExcel();
  }
  dwdExpExcel() {
    const payload: any = {
      page: this.noticeConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      reportType: 'DtNotice',
      module: "DT",
      sortfield: 'ay',
      criterias: this.getFilters()
    }
    this.noticeService.dwdDtExportExcel(payload).subscribe((response: any) => {
      this.downloadExFile(response);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadExFile(data: any) {
    let blob = new Blob([data], { type: 'application/vnd.ms-excel' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `Notice.xlsx`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  //Export to excel download

  //file download
  onClickFileName(NoticeFileData: any[] = []) {
    this.noticeFiles = [];
    this.noticeFiles = NoticeFileData;
  }
  download(key: any, id: any, fileName: string) {
    let urlData = {
      s3Key: key,
      id: id
    }
    this.noticeService.dwdDTNoticesFile(urlData).subscribe((response: any) => {
      this.downloadcontFile(response, fileName);
      this.toaster.showSuccess('File downloaded successfully');
    });

  }
  downloadcontFile(data: any, fileName: any) {
    let blob = new Blob([data], { type: 'application/pdf' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", `${fileName}`);
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }
  //file download

  //pagination  
  onTableDataChangeSection1(pageNumber: number): void {
    this.noticeConfig.currentPage = pageNumber;
    this.noticeListData();
  }
  getnoticeData(perPageLimitSection1: any, section1page: number) {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: section1page == 1 ? 0 : section1page - 1,
      size: perPageLimitSection1,
      module: "DT",
      criterias: this.getFilters(),
      newRecord: isNewCase === "Newnotice"
    }
    this.noticeService.getNoticeListData(payload).subscribe((res) => {
      this.noticeData = res.response.results;
      this.noticeConfig.totalItems = res.response.count;
      this.noticeConfig.itemsPerPage = perPageLimitSection1;
      this.noticeConfig.currentPage = payload.pageNumber + 1;
    });
  }
  //pagination  
  toNoticeDetail(noticeId: number, parentNoticeheaderSeqNo: any) {
    this.router.navigate(['/dtNmNoticeDetail', noticeId, parentNoticeheaderSeqNo]);
  }
  getNoticeData() {
    const isNewCase = sessionStorage.getItem('isNewNotice')
    const payload: any = {
      page: this.noticeConfig.currentPage - 1,
      size: this.perPageLimitSection1,
      module: "DT",
      criterias: this.getFilters(),
      newRecord: isNewCase === "Newnotice"
    }
    this.noticeService.getNoticeListData(payload).subscribe((res) => {
      this.noticeData = res.response.results;
      this.noticeConfig.totalItems = res.response.count;
      this.totalCount = res.response.count;

    });
  }
  clearIssueDt() {
    if (this.issueDateArray && this.issueDateArray.length > 0) {
      this.filters.parsedIssuedOn.v1 = null;
      this.filters.parsedIssuedOn.v2 = null;
    }
    this.getNoticeData();
    this.issueDateArray = null;
  }
  cleardueDateDt() {
    if (this.dueDateArray && this.dueDateArray.length > 0) {
      this.filters.parsedResponseDueDate.v1 = null;
      this.filters.parsedResponseDueDate.v2 = null;
    }
    this.getNoticeData();
    this.dueDateArray = null;
  }
  clearFilter(inputName: string) {
    switch (inputName) {
      case 'ay':
        this.filters.ay.v = null;
        break;
      case 'pan':
        this.filters.pan.v = null;
        break;
      case 'isSubmitted':
        this.filters.isSubmitted.v = null;
        break;
      case 'noticeName':
        this.filters.noticeName.v = null;
        break;
      case 'noticeSection':
        this.filters.noticeSection.v = null;
        break;
      default:
        break;
    }
    this.getNoticeData();
  }

  sort(column: string) {
    switch (column) {
      case NOTICE_SORT.PAN: {
        if (!this.sorting.pan) {
          this.sorting.pan = true;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'pan', 'asc');
        } else {
          this.sorting.pan = false;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'pan', 'desc');
        }
        break;
      }
      case NOTICE_SORT.AY: {
        if (!this.sorting.ay) {
          this.sorting.ay = true;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'ay', 'asc');
        } else {
          this.sorting.ay = false;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'ay', 'desc');
        }
        break;
      }
      case NOTICE_SORT.NOTICE_ID: {
        if (!this.sorting.noticeId) {
          this.sorting.noticeId = true;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'noticeId', 'asc');
        } else {
          this.sorting.noticeId = false;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'noticeId', 'desc');
        }
        break;
      }
      case NOTICE_SORT.DOCUMENT_REF_ID: {
        if (!this.sorting.documentReferenceId) {
          this.sorting.documentReferenceId = true;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'documentReferenceId', 'asc');
        } else {
          this.sorting.documentReferenceId = false;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'documentReferenceId', 'desc');
        }
        break;
      }
      case NOTICE_SORT.DATE_OF_ISSUE: {
        if (!this.sorting.parsedIssuedOn) {
          this.sorting.parsedIssuedOn = true;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'parsedIssuedOnDt', 'asc');
        } else {
          this.sorting.parsedIssuedOn = false;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'parsedIssuedOnDt', 'desc');
        }
        break;
      }
      case NOTICE_SORT.DUE_DATE: {
        if (!this.sorting.parsedResponseDueDate) {
          this.sorting.parsedResponseDueDate = true;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'parsedResponseDueDt', 'asc');
        } else {
          this.sorting.parsedResponseDueDate = false;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'parsedResponseDueDt', 'desc');
        }
        break;
      }
      case NOTICE_SORT.LAST_ADJOURNED_DATE: {
        if (!this.sorting.parsedAdjournmentDueDate) {
          this.sorting.parsedAdjournmentDueDate = true;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'parsedAdjournmentDueDt', 'asc');
        } else {
          this.sorting.parsedAdjournmentDueDate = false;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'parsedAdjournmentDueDt', 'desc');
        }
        break;
      }
      case NOTICE_SORT.NOTICE_SEC: {
        if (!this.sorting.noticeSection) {
          this.sorting.noticeSection = true;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'noticeSection', 'asc');
        } else {
          this.sorting.noticeSection = false;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'noticeSection', 'desc');
        }
        break;
      }
      case NOTICE_SORT.RESPONSE: {
        if (!this.sorting.isSubmitted) {
          this.sorting.isSubmitted = true;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'isSubmitted', 'asc');
        } else {
          this.sorting.isSubmitted = false;
          this.noticeData = this.commonServ.sortNoticeVc(this.noticeData, 'data', 'noticeObj', 'isSubmitted', 'desc');
        }
        break;
      }
    }
  }
}
