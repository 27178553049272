<div class="main-panel">
  <div class="content-wrapper" *ngIf="data">
    <div class="panel-head">
      <h1 _ngcontent-nia-c116="">PRE GST Analytics</h1>
      <div class="filters-bar">
        <div class="filter-div">
          <!-- <div class="filter-item">
            <label for="exampleInputPassword1">State</label>
            <select class="form-control form-select" [(ngModel)]="selectedState" (change)="setState($event)">
              <option [value]="null" selected>All</option>
              <ng-container *ngFor="let obj of stateList">
                <option [value]="obj.stateName">{{obj.stateName}}</option>
              </ng-container>
            </select>
          </div> -->

          <div class="filter-item" style="width: 350px;">
            <label [ngClass]="{'highlightFilter' : filters.state.v !== null}">State
              <span
              (click)="clearFilter('state')" [ngClass]="{'highlightclose' : filters.state.v !== null}"
              class="clearBtn"><i class="fa fa-close"></i></span>
            </label>
            <ng-multiselect-dropdown [(ngModel)]="filters.state.v"  (onSelect)="setState()" (onDeSelect)="onDTtemSelect()"
            [placeholder]="'Select Status'" [settings]="dropdownSettings" [data]="stateNames">
            </ng-multiselect-dropdown>
          </div>

        </div>
        <div class="action-div">
          <!-- <span class="clearData"><a (click)="clearfilter()">Clear Filter</a></span> -->
          <!-- <div class="switch-box">
            <label [ngClass]="{ 'chosen-metric': amountMetric === 'lakh' }">Lakhs</label>
            <div class="form-check form-switch m-0">
              <input [(ngModel)]="selectedIRN" (change)="changeStatsValue(selectedIRN)" id="togBtn"
                class="form-check-input" type="checkbox" role="switch" [checked]="amountMetric === 'crore'" />
            </div>
            <label [ngClass]="{ 'chosen-metric': amountMetric === 'crore' }">Crores</label>
          </div> -->
        </div>
      </div>
    </div>
    <!-- A1 stacked BarChart-->
    <!-- <div class="row">
      <div class="col-md-12 d-flex align-items-center">
        <div class="col-md-1">
          <div class="clearData">State</div>
        </div>
        <div class="col-md-2">
          <select class="form-control form-select" [(ngModel)]="selectedState" (change)="setState($event)">
            <option [value]="null" disabled>Select</option>
            <ng-container *ngFor="let obj of stateList">
              <option [value]="obj.stateName">{{obj.stateName}}</option>
            </ng-container>
          </select>
        </div>
        <div class="col-md-2">
          <span class="clearData"><a (click)="clearfilter()" style="margin-left: 30px;">Clear Filter</a></span>
        </div>
        <div class="col-md-7 d-flex justify-content-end">
          <span class="clearData me-4">(₹) in Lakhs</span>
        </div>
      </div>
    </div> -->

    <div class="row col-md-12 nopadding d-flex align-items-stretch">
      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-body">
            <div class="flex-justify mb-3 heading">
              <h3 class="">Act wise Demand Analysis <a class="clrOrange infoIcon"
                  title=" This chart shows Act wise demand for open cases.">
                  <i class="fa fa-info-circle" style="margin-left:3px;"></i></a></h3>
            </div>
            <div class="chartjs-container">
              <app-a4-chart [a2Data]="data.a2"></app-a4-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-body">
            <div class="flex-justify mb-3 heading">
              <h3 class="">Act wise Demand Analysis <a class="clrOrange infoIcon"
                  title=" This table shows Act wise demand for open cases. User can drill down further to view state wise breakup.">
                  <i class="fa fa-info-circle" style="margin-left:3px;"></i></a></h3>
                  <button class="btn btn-outline-success btn-download mb-0" (click)="exportAsXLSX()">
                    <i class="fa fa-file-excel-o me-2" aria-hidden="true"></i> Export
                </button>
            </div>

            <div class="chartjs-container">
              <app-act-wise-table [divSumColTotal]="data.a2summary" [divSumColTotals]="data" #actData
                [a2Data]="data.a2"></app-act-wise-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-12 nopadding mt15">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="flex-justify mb-3 heading">
              <h3 class="">State wise Demand Analysis <a class="clrOrange infoIcon"
                  title=" This table shows state wise demand for open cases as per current forum.">
                  <i class="fa fa-info-circle" style="margin-left:3px;"></i>
                </a></h3>
            </div>
            <div class="chartjs-container">
              <!-- <app-level-wise-demand-analysis [divSumColTotal]="data.a5summary"  [divSumColTotalSum]="data.a5summary.phases"></app-level-wise-demand-analysis   > -->

              <!-- <app-level-wise-demand-analysis [SumColTotal]="data" [divSumColTotal]="data.a5summary" [divSumColTotalSum]="data.a5summary.phases" [a5Data]="data.a5"></app-level-wise-demand-analysis> -->
              <app-level-wise-demand-analysis [SumColTotal]="data"  [divSumColTotal]="data.a5summary" 
              [divSumColTotalSum]="data.a5summary.phases"  [a5Data]="data.a5"></app-level-wise-demand-analysis>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-12 nopadding mt15 d-flex align-items-stretch">
      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-body">
            <div class="flex-justify mb-3 heading">
              <h3 class="">Division wise Demand Analysis <a class="clrOrange infoIcon"
                  title=" This chart shows Division wise demand for open cases.">
                  <i class="fa fa-info-circle" style="margin-left:3px;"></i></a></h3>
            </div>
            <div class="chartjs-container">
              <app-a4-chart [a2Data]="data.d3"></app-a4-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card h-100">
          <div class="card-body">
            <div class="flex-justify mb-3 heading">
              <h3 class="">Division wise Demand Analysis <a class="clrOrange infoIcon"
                  title=" This table shows Division wise demand for open cases. User can drill down further to view Act wise breakup.">
                  <i class="fa fa-info-circle" style="margin-left:3px;"></i></a></h3>
                  <button class="btn btn-outline-success btn-download mb-0" (click)="exportAsXLSX()">
                    <i class="fa fa-file-excel-o me-2" aria-hidden="true"></i> Export
                </button>
            </div>

            <div class="chartjs-container">
              <app-division-wise-table [divSumColTotal]="data.d3Summary" [divSumColTotals]="data" #divData
              [d3Data]="data.d3"></app-division-wise-table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-12 nopadding mt15">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="flex-justify mb-3 heading">
              <h3 class="">Ageing Analysis - Registration No <a class="clrOrange infoIcon"
                  title=" This chart shows age wise classification of open cases as per the date of receipt of Letter/Notice/Order of the cases pertaining to particular registration number. On further click user can see the registration number wise demand amount.">
                  <i class="fa fa-info-circle" style="margin-left:3px;"></i></a></h3>
            </div>
            <!-- <p class="card-title">Ageing Analysis - Registration No
              <a class="clrOrange infoIcon" title=" This chart shows age wise classification of open cases as per the date of receipt of Letter/Notice/Order of the cases pertaining to particular registration number. On further click user can see the registration number wise demand amount.">
                <i class="fa fa-info-circle"  style="margin-left:3px;"></i></a>
            </p> -->
            <div class="chartjs-container">
              <app-b1-chart [b1Data]="data.b1"></app-b1-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="flex-justify mb-3 heading">
              <h3 class="">Ageing Analysis - Case Id <a class="clrOrange infoIcon"
                  title=" This chart shows age wise classification of open cases as per the date of receipt of Letter/Notice/Order of the cases pertaining to particular case Id. On further click user can see the  case Id wise demand amount.">
                  <i class="fa fa-info-circle" style="margin-left:3px;"></i></a></h3>
            </div>

            <!-- <p class="card-title">Ageing Analysis - Case Id
              <a class="clrOrange infoIcon" title=" This chart shows age wise classification of open cases as per the date of receipt of Letter/Notice/Order of the cases pertaining to particular case Id. On further click user can see the  case Id wise demand amount.">
                <i class="fa fa-info-circle"  style="margin-left:3px;"></i></a>
            </p> -->
            <div class="chartjs-container">
              <app-b2-chart [b2Data]="data.b2"></app-b2-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-md-12 nopadding mt15">
      <div class="col-md-12 ">
        <div class="card">
          <div class="card-body">
            <div class="flex-justify mb-3 heading">
              <h3 class="">Issue Wise Demand Details <a class="clrOrange infoIcon"
                  title=" This chart shows Act list which have demand for open cases only. On click of any Act bar user can see the list of issues. Further list of the cases will be visible on click of particular issue.">
                  <i class="fa fa-info-circle" style="margin-left:3px;"></i></a></h3>
            </div>
            <div class="chartjs-container">
              <app-a1-chart [a1Data]="data.a1"></app-a1-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 mt15 pl0">
      <!-- <div class="col-md-12"> -->
      <div class="card">
        <div class="card-body">
          <div class="flex-justify mb-3 heading">
            <h3 class="">Analysis of Cumulative payment made <a class="clrOrange infoIcon"
                title="This table shows State wise and registration number wise total demand for open cases as per current forum and cumulative payment made Under Pre-deposit, protest and for liability admitted till the date.">
                <i class="fa fa-info-circle" style="margin-left:3px;"></i></a></h3>
          </div>
          <!-- <p class="card-title">Analysis of Cumulative payment made <a class="clrOrange infoIcon" title="This table shows State wise and registration number wise total demand for open cases as per current forum and cumulative payment made Under Pre-deposit, protest and for liability admitted till the date.">
              <i class="fa fa-info-circle"  style="margin-left:3px;"></i></a></p> -->
          <div class="chartjs-container">
            <app-a7-chart [divCumulativeTotal]="data.totals" [divCumulativeTotalCr]="data.totalsInCr"
              [a7Data]="data.a7"></app-a7-chart>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>


    <!-- <div class="col-md-12 nopadding">
      <div class="card">
        <div class="card-body">
          <p class="card-title"></p>
          <div class="row">
            <div class="table-responsive">
              <p class="card-title">Pre Litigation analytics</p>
            </div>
          </div>
        </div>
      </div>
    </div> -->



    <!-- <div class="row col-md-12 nopadding mt15">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <p class="card-title">Pre-Deposit Analysis - Act wise & Division Wise</p><span class="info">(All Values in ₹ in Lakh)</span>
            <div class="chartjs-container">
              <app-a6-chart [a6Data]="data.a6"></app-a6-chart>
            </div>
          </div>
        </div>
      </div>
    </div> -->





    <!-- A2 stacked BarChart -->

    <div class="row col-md-12 nopadding mt15">
      <!-- <div class="col-md-6 ">
        <div class="card">
          <div class="card-body">
            <p class="card-title">Division wise Demand Analysis</p>
            <div class="chartjs-container">
              <app-a2-chart [a2Data]="data.a2"></app-a2-chart>
            </div>
          </div>
        </div>
      </div> -->

      <!-- A4 stacked BarChart
      <div class="col-md-6 ">
        <div class="card">
          <div class="card-body">
            <p class="card-title">State wise Demand Analysis</p>
            <div class="chartjs-container">
              <app-a4-chart [a4Data]="data.a4"></app-a4-chart>
            </div>
          </div>
        </div>
      </div>-->
    </div>



  </div>
</div>
