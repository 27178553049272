<div class="panel-wrapper">
  <div class="panel-head">
    <div class="page-title">
      <h1><a [routerLink]="'/dtNmSummary'">Notice Management Direct Tax</a>>> <span>Notices</span></h1>
      <div class="action-bar">
        <a title="Video Conferencing" [routerLink]="'/dtNmVc'" class="iris-gst-pull-right"><i
            class="fa fa-video-camera"></i><span>{{dtNmStats?.videoConferencingCount}}</span></a>
        <a title="Adjournment" [routerLink]="'/dtNmAdj'" class="iris-gst-pull-right"><i
            class="fa fa-legal"></i><span>{{dtNmStats?.adjournmentCount}}</span></a>
        <a title="Proceedings" [routerLink]="'/dtNmProceeding'" class="iris-gst-pull-right"><i
            class="fa fa-balance-scale"></i><span>{{dtNmStats?.proceedingsCount}}</span></a>
      </div>
    </div>
    <div class="filter-section">

      <div class="filters-bar">
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.ay.v !== null}">AY <span (click)="clearFilter('ay')"
              [ngClass]="{'highlightclose' : filters.ay.v !== null}" class="clearBtn"><i
                class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.ay.v" (ngModelChange)="noticeListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let ay of assessYears">
              <option [ngValue]="ay.value">{{ ay.label }}</option>
            </ng-container>
          </select>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : issueDateArray !== null}">Issue Date <span (click)="clearIssueDt()"
              [ngClass]="{'highlightclose' : issueDateArray !== null}" class="clearBtn"><i
                class="fa fa-close"></i></span></label>
          <div class="datepicker">
            <p-calendar [(ngModel)]="issueDateArray" placeholder="From Date-To Date" [monthNavigator]="true"
              [yearNavigator]="true" yearRange="2010:{{currentYear}}" dateFormat="dd-mm-yy" dataType="string"
              selectionMode="range" (ngModelChange)="noticeListData()"></p-calendar>
            <i class="date-icon fa fa-calendar" aria-hidden="true"></i>
          </div>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : dueDateArray !== null}">Due Date <span (click)="cleardueDateDt()"
              [ngClass]="{'highlightclose' : dueDateArray !== null}" class="clearBtn"><i
                class="fa fa-close"></i></span></label>
          <div class="datepicker">
            <p-calendar [(ngModel)]="dueDateArray" placeholder="From Date-To Date" [monthNavigator]="true"
              [yearNavigator]="true" yearRange="2010:{{currentYear}}" dateFormat="dd-mm-yy" dataType="string"
              selectionMode="range" (ngModelChange)="noticeListData()"></p-calendar>
            <i class="date-icon fa fa-calendar" aria-hidden="true"></i>
          </div>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.pan.v !== null}">PAN <span (click)="clearFilter('pan')"
              [ngClass]="{'highlightclose' : filters.pan.v !== null}" class="clearBtn"><i
                class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.pan.v" (ngModelChange)="noticeListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let pans of filterDropDown.panName">
              <option [value]="pans.pan">{{ pans.pan }} - {{pans.name}}</option>
            </ng-container>
          </select>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.isSubmitted.v !== null}">Response <span
              (click)="clearFilter('isSubmitted')" [ngClass]="{'highlightclose' : filters.isSubmitted.v !== null}"
              class="clearBtn"><i class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.isSubmitted.v" (ngModelChange)="noticeListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let obj of noticeRes">
              <option [value]="obj.value">{{ obj.label }}</option>
            </ng-container>
          </select>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.noticeName.v !== null}">Proceeding Section <span
              (click)="clearFilter('noticeName')" [ngClass]="{'highlightclose' : filters.noticeName.v !== null}"
              class="clearBtn"><i class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.noticeName.v" (ngModelChange)="noticeListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let noticeName of filterDropDown.noticeNames">
              <option [value]="noticeName">{{ noticeName }}</option>
            </ng-container>
          </select>
        </div>
        <div class="filter-item">
          <label [ngClass]="{'highlightFilter' : filters.noticeSection.v !== null}">Notice Section <span
              (click)="clearFilter('noticeSection')" [ngClass]="{'highlightclose' : filters.noticeSection.v !== null}"
              class="clearBtn"><i class="fa fa-close"></i></span></label>
          <select class="form-select" [(ngModel)]="filters.noticeSection.v" (ngModelChange)="noticeListData()">
            <option [ngValue]="null">All</option>
            <ng-container *ngFor="let noticeSec of filterDropDown.noticeSections">
              <option [value]="noticeSec">{{ noticeSec }}</option>
            </ng-container>
          </select>
        </div>



      </div>
      <p>
        <span class="totalRecord">{{totalCount}}</span>
        <a class="iris-gst-pull-right " id="download" title="Export To Excel" (click)="downloadEXFile()"><i
            class="fa fa-file-excel-o  "></i> EXPORT TO EXCEL</a>
      </p>
    </div>


  </div>

  <div class="section nopadding" *ngIf="totalCount !== 0">
    <div class="row">

      <div class="col-md-12 ">
        <div class="table-responsive nopadding">
          <table class="table">
            <thead>
              <tr>
                <th width="10px">&nbsp;</th>
                <th>PAN
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.pan"
                    (click)="sort(noticeSort.PAN)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.pan"
                    (click)="sort(noticeSort.PAN)"></i> -->
                </th>
                <th>AY
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.ay"
                    (click)="sort(noticeSort.AY)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.ay"
                    (click)="sort(noticeSort.AY)"></i> -->
                </th>
                <th>Notice ID
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.noticeId"
                    (click)="sort(noticeSort.NOTICE_ID)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.noticeId"
                    (click)="sort(noticeSort.NOTICE_ID)"></i> -->

                </th>
                <th>Document Ref. ID
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.documentReferenceId"
                    (click)="sort(noticeSort.DOCUMENT_REF_ID)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.documentReferenceId"
                    (click)="sort(noticeSort.DOCUMENT_REF_ID)"></i> -->
                </th>
                <th>Date of Issue
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.parsedIssuedOn"
                    (click)="sort(noticeSort.DATE_OF_ISSUE)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.parsedIssuedOn"
                    (click)="sort(noticeSort.DATE_OF_ISSUE)"></i> -->
                </th>
                <th>Due Date
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.parsedResponseDueDate"
                    (click)="sort(noticeSort.DUE_DATE)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.parsedResponseDueDate"
                    (click)="sort(noticeSort.DUE_DATE)"></i> -->
                </th>
                <th>Last Adjourned Date
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.parsedAdjournmentDueDate"
                    (click)="sort(noticeSort.LAST_ADJOURNED_DATE)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.parsedAdjournmentDueDate"
                    (click)="sort(noticeSort.LAST_ADJOURNED_DATE)"></i> -->
                </th>
                <th>Notice Sec.
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.noticeSection"
                    (click)="sort(noticeSort.NOTICE_SEC)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.noticeSection"
                    (click)="sort(noticeSort.NOTICE_SEC)"></i> -->
                </th>
                <th>Response
                  <!-- <i class="fa fa-arrow-up cursor-pointer" aria-hidden="true" *ngIf="!sorting.isSubmitted"
                    (click)="sort(noticeSort.RESPONSE)"></i>
                  <i class="fa fa-arrow-down cursor-pointer" aria-hidden="true" *ngIf="sorting.isSubmitted"
                    (click)="sort(noticeSort.RESPONSE)"></i> -->
                </th>
                <th width="180">Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let obj of noticeData | paginate: noticeConfig; ">
                <tr
                  [ngClass]="(!obj.data.noticeObj.userAction || obj.data.noticeObj.userAction == 'Read by User') ? 'markUnreadBg' : 'markReadBg'">
                  <td><i class="fa fa-circle"
                      [ngClass]="(!obj.data.noticeObj.userAction || obj.data.noticeObj.userAction == 'Read by User') ? 'markUnread' : 'markRead'"></i>
                  </td>
                  <td>
                    <p>{{obj.data.noticeObj.pan}}</p>
                    <p class="subTd">{{obj.data.noticeObj.panName}}</p>
                  </td>
                  <td>
                    {{obj.data.noticeObj.ay}}
                  </td>
                  <td>
                    <p><a class="anchor"
                        (click)="toNoticeDetail(obj.data.noticeObj.noticeId, obj.data.noticeObj.headerSeqNo)">{{obj.data.noticeObj.noticeId}}</a>
                    </p>
                    <p class="subTd">{{obj.data.noticeObj.proceedingName}}</p>
                  </td>
                  <td>
                    <p>{{obj.data.noticeObj.documentReferenceId}}</p>
                    <p title="{{obj.data.noticeObj.description}}" class="subTd wrapTextTd">
                      {{obj.data.noticeObj.description}}</p>
                  </td>

                  <td>{{obj.data.noticeObj.parsedIssuedOn}}</td>
                  <td>{{obj.data.noticeObj.parsedResponseDueDate}}</td>

                  <td>{{obj.data.noticeObj.parsedAdjournmentDueDate}}</td>
                  <td>{{obj.data.noticeObj.noticeSection}}</td>
                  <td><span *ngIf="obj.data.noticeObj.isSubmitted == 'Y'">Replied</span></td>
                  <td>
                    <a class="mr-3 anchor" (click)="onClickFileName(obj.data.files)" data-toggle="modal"
                      data-target="#downloadModel" id="download">Downloads
                    </a>
                    <a *ngIf="!obj.data.noticeObj.userAction || obj.data.noticeObj.userAction == 'Read by User'"
                      class="anchor" (click)="setAction(obj.data.noticeObj)" data-toggle="modal"
                      data-target="#confirmModel" id="confirm">Mark as read</a>
                    <a *ngIf="obj.data.noticeObj.userAction == 'Yet to read'" class="anchor"
                      (click)="setAction(obj.data.noticeObj)" data-toggle="modal" data-target="#confirmUnModel"
                      id="confirmUn">Mark as unread</a>
                    <!-- <a *ngIf="obj.data.noticeObj.userAction == 'Yet to read'" class="anchor" (click)="setAction(obj.data.noticeObj)" data-toggle="modal" data-target="#confirmModel" id="confirm">Mark as unread</a> -->

                  </td>
                </tr>

              </ng-container>
            </tbody>
          </table>
          <br />
          <div class="row noMargin plr15">
            <div class="col-md-10 nopadding">
              <pagination-controls (pageChange)="onTableDataChangeSection1($event)"
                id="noticeConfig"></pagination-controls>
            </div>
            <div class="col-md-2 nopadding">
              <!-- <select id="itemsPerPage" [(ngModel)]="config.itemsPerPage">
                <option *ngFor="let option of [5, 10, 20, 50]">{{ option }}</option>
              </select> -->
              <select class="form-select jumptoPagination" [(ngModel)]="perPageLimitSection1"
                (ngModelChange)="getnoticeData(perPageLimitSection1,1)" id="authority">
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="200">200</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="col-md-12">
            <ng-container  >
               <p class="noData">No Data Found</p>
            </ng-container>
         </div> -->
    </div>
  </div>
  <ng-container *ngIf="totalCount == 0">
    <p class="noData">No Data Found</p>
  </ng-container>
  <div class="modal fade" id="confirmModel" tabindex="-1" role="dialog" aria-labelledby="confirmModelLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p>Do you want to mark this as a read ?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="confirmMarkRead()">Yes</button>
          <button type="button" class="btn btn-primary" #closeModal data-dismiss="modal" aria-label="Close">No</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="confirmUnModel" tabindex="-1" role="dialog" aria-labelledby="confirmUnModelLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p>Do you want to mark this as a unread ?</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" (click)="confirmMarkUnRead()">Yes</button>
          <button type="button" class="btn btn-primary" #closeUnModal data-dismiss="modal"
            aria-label="Close">No</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="downloadModel" tabindex="-1" role="dialog" aria-labelledby="downloadModelLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="downloadModelLabel">Attachment</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" #closeModal>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ng-container *ngIf="noticeFiles.length !== 0">
            <ul *ngFor="let item of noticeFiles">
              <li class="wrapText"><a title="{{item.docName}}"
                  (click)="download(item.s3Key, item.noticeId, item.docName)"><i
                    class="fa fa-download clrOrange notifyIcon"></i> {{item.docName}}</a></li>
            </ul>
          </ng-container>
          <ng-container *ngIf="noticeFiles.length == 0">
            <p class="noData">No Attachments Found</p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
