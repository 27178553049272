<div class="tab-pane" id="intimation-1" role="tabpanel" aria-labelledby="intimation-tab">
   <div class="row">
      <div class="col-md-4">
         <ul class="nav nav-tabs nav nav-tabs iris-gst-display-flex" role="tablist" style="border: none;">
            <li class="nav-item">
               <a class="nav-link active" id="intimationp1-tab" data-toggle="tab" href="#intimationp1-1" role="tab"
                  aria-controls="intimation-1" aria-selected="true">Phase 1</a>
            </li>
            <li class="nav-item" [ngClass]="{'disabled': !isPhase2 }">
               <a class="nav-link" id="intimationp2-tab" data-toggle="tab" href="#intimationp2-1" role="tab"
                  aria-controls="intimationp2-1" aria-selected="false" #rectificationTab>Phase 2</a>
            </li>
            <li class="nav-item" [ngClass]="{'disabled': !isPhase3 }">
               <a class="nav-link" id="intimationp3-tab" data-toggle="tab" href="#intimationp3-1" role="tab"
                  aria-controls="intimationp3-1" aria-selected="false" #orderTab>Phase 3</a>
            </li>
         </ul>
      </div>
      <div class="col-md-4">
         <div class="textCenter pt10">
            <span class="infoVal fs16" *ngIf="isVisibleCaseId" title="Case ID">{{caseData.caseId}}</span>

         </div>
      </div>
      <div class="col-md-4">
         <div>
            <span class="computationBtn" *ngIf="caseData">
               <span class="infoTitle">Last Updated By :</span>{{caseData.updatedBy}} <span class="infoVal mr5"></span>
               <span class="infoTitle">On:</span> {{caseData.updateDate}} <span class="infoVal"></span>
            </span>
         </div>
      </div>

   </div>
   <div class="tab-content nopadding">
      <div class="tab-pane fade show active" id="intimationp1-1" role="tabpanel" aria-labelledby="intimationp1-tab">
         <div class="card borderOrange">
            <div class="card-body ptl10 plr010">
               <div class="row">
                  <div class="col-2" id="verticalTab3">
                     <ul class="nav nav-tabs nav-tabs-vertical" role="tablist" *ngIf="!sidemenu">
                        <li class="nav-item">
                           <a class="nav-link active" id="igi-tab-vertical" data-toggle="tab" href="#igi-2" role="tab"
                              aria-controls="igi-2" aria-selected="true">
                              General Info
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab2Disable  && !(action == 'update') && !(action == 'view'))}">
                           <a class="nav-link" id="intim-tab-vertical" data-toggle="tab" href="#intim-2" role="tab"
                              aria-controls="intim-2" aria-selected="false" #intimTab>
                              Intimation
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab3Disable  && !(action == 'update') && !(action == 'view'))}">
                           <a class="nav-link" id="itr-tab-vertical" data-toggle="tab" href="#itr-2" role="tab"
                              aria-controls="itr-2" aria-selected="false" #itrTab>
                              ITR Details
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab4Disable  && !(action == 'update') && !(action == 'view'))}">
                           <a class="nav-link" id="taxPay-tab-vertical" data-toggle="tab" href="#taxPay-2" role="tab"
                              aria-controls="taxPay-2" aria-selected="false" #taxPayTab>
                              Tax Payment and Refund Details
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': (!tab5Disable && !(action == 'update') && !(action == 'view'))}">
                           <a class="nav-link" id="conclusion-tab-vertical" data-toggle="tab" href="#conclusion-2"
                              role="tab" aria-controls="conclusion-2" aria-selected="false" #conclusionTab>
                              Conclusion
                           </a>
                        </li>
                        <li class="nav-item"
                           [ngClass]="{'disabled': ((!tab6Disable  && !(action == 'update') && !(action == 'view')) || action == 'view')}"
                           *ngIf="!updateBtn">
                           <a class="btn btn-outline-primary w100" #saveTab (click)="saveBtn()"
                              [ngClass]="{'disabled': ((!tab6Disable  && !(action == 'update') && !(action == 'view')) || action == 'view')}">
                              Save </a>
                        </li>
                        <li class="nav-item" *ngIf="updateBtn" [ngClass]="{'disabled': (action == 'view')}">
                           <a class="btn btn-outline-primary w100" (click)="updateForm(1)"> Update </a>
                        </li>
                     </ul>
                  </div>
                  <div class="col-10 border " id="tabArea3" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
                     <div class="tab-content tab-content-vertical">
                        <div class="tab-pane fade show active" id="igi-2" role="tabpanel"
                           aria-labelledby="igi-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div [formGroup]="generalInfo">
                                 <div class="row">
                                    <div class="col-md-12">
                                       <p class="textCenter">General Info</p>
                                       <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                          <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                          <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                       </span>
                                    </div>
                                    <div class="col-md-3" *ngIf="isVisibleCaseId">
                                       <div class="form-group">
                                          <label>Case ID
                                             <span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="caseId" class="form-control" id="caseId"
                                             readonly />
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>PAN<span class="mandate"><i class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="pan" class="form-control" readonly id=""
                                             placeholder="" />
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Legal PAN Name <span class="mandate"> <i
                                                   class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="panName" class="form-control" readonly
                                             placeholder="" />
                                       </div>
                                    </div>
                                    <div class="col-md-3" [ngClass]="{'disabled': isDisabledSelect}">
                                       <div class="form-group">
                                          <label>Assessment Year
                                             <span class="mandate"><i class="fa fa-asterisk"></i></span> </label>
                                          <select class="form-control form-select sd" id="gstin" formControlName="ay"
                                             [ngClass]="{errorBorder:isNextBtnClicked && !generalInfo.controls.ay.value }"
                                             (change)="getperiodToValue(ayPop)">
                                             <ng-container *ngFor="let obj of finyearData">
                                                <option [value]="obj">{{ obj }}</option>
                                             </ng-container>
                                          </select>
                                          <div *ngIf="isNextBtnClicked && !generalInfo.controls.ay.value">
                                             <span class="text-danger">Assessment Year Required</span>
                                          </div>
                                          <ng-template #ayPop let-modal>
                                             <div class="modal-header" style="border: none; padding-bottom: 15px; padding-top:9px" >
                                               <button type="button" class="close" aria-label="Close"
                                                 (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                                               </button>
                                             </div>
                                             <div class="col-md-12" >
                                               <div class="table-responsive">
                                                 <p class="title-data">Following cases are already exist for same selected Assessment Year,<span style="font-weight: bold;">Do you want to still proceed?</span></p>
                                                 <table>
                                                   <thead>
                                                     <tr class="bg-primary text-dblue">
                                                       <th class="sl-no-cla">Sr No</th>
                                                       <th class="clas">Case id</th>
                                                       <th>Case label</th>
                                                       <th class="clas">Assessment Year</th>
                                                       <th>Forum</th>
                                                       <th class="ac-no-clas">Total Demand(₹)</th>
                                                     </tr>
                                                   </thead>
                                                   <tbody>
                                                     <tr
                                                       *ngFor="let obj of duplicateData  let i = index">
                                                       <td class="sl-no-cla">{{i + 1}}</td>
                                                       <td class="clas">{{obj.caseId}}</td>
                                                       <td>{{obj.caseLabel}}</td>
                                                       <td class="clas">{{obj.ay}}</td>
                                                       <td>{{obj.forum}}</td>
                                                       <td class="ac-no-clas" style="text-align: right;">{{obj.demandAmount | INR: 0}} </td>
                                                     </tr>
                                                   </tbody>
                                                 </table>
                                               </div>
                                             </div>
                           
                                             <div class="modal-footer" style="border: none; padding-top: 5px;" >
                                               <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                                               (click)="modal.close('No click')">Yes</button>
                                                 <button type="submit" class="btn btn-outline-primary mr-1 " aria-label="Close"
                                                 (click)="goToSummary()">NO</button>
                                             </div>
                                           </ng-template>
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Financial Year<span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span></label>
                                          <input type="text" formControlName="finYear" readonly class="form-control"
                                             placeholder=""
                                             [ngClass]="{errorBorder: isNextBtnClicked && !generalInfo.controls.finYear.value}" />
                                          <div *ngIf="isNextBtnClicked && !generalInfo.controls.finYear.value">
                                             <span class="text-danger">Financial Year Required</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Consultant Name</label>
                                          <input type="text" formControlName="consultant" class="form-control"
                                             placeholder=""
                                             [ngClass]="{ errorBorder: generalInfo.controls.consultant.hasError('maxlength')}" />
                                       </div>
                                       <div class="text-danger"
                                          *ngIf="generalInfo.controls.consultant.touched &&  generalInfo.controls.consultant.hasError('maxlength')">
                                          Maximum of 100 characters
                                       </div>

                                    </div>
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Person responsible <span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span></label>

                                                   <div *ngIf="isToggleDropdown && action==='update' || action==='view'" class="input-group">
																		<input class="form-control" type="text" formControlName="personResp" readonly>
																		<button *ngIf="isToggleDropdown && action==='update'" class="btn btn-outline-secondary" type="button" (click)="onClickToggleDropdown()">
																		  <i class="fa fa-pencil"></i>
																		</button>
																	  </div>
													
																	  <select *ngIf="!isToggleDropdown || action==='create'" class="form-control form-select" id="personResp" formControlName="personResp" (change)="onClickToggleDropdown()"
																		[ngClass]="{ 'errorBorder': isNextBtnClicked && generalInfo.controls.personResp.errors }">
																		<option [value]="null">-- Select --</option>
																		<ng-container *ngFor="let obj of userRoleLs">
																		  <option [value]="obj.username">{{obj.username}}</option>
																		</ng-container>
																	  </select>
                                          <div *ngIf="isNextBtnClicked && !generalInfo.controls.personResp.value">
                                             <span class="text-danger">Person responsible Required</span>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="row">
                                       <div class="col-md-12">
                                          <div class="form-group">
                                             <label>Case Label
                                                <span class="mandate"><i class="fa fa-asterisk"></i></span><a
                                                   class="clrOrange infoIcon"
                                                   title="Please give a one line description to identify the case."><i
                                                      class="fa fa-info-circle"
                                                      style="margin-left: 3px"></i></a></label>
                                             <textarea class="form-control" id="exampleTextarea1" rows="4"
                                                formControlName="caseLabel"
                                                [ngClass]="{ errorBorder:(isNextBtnClicked && !generalInfo.controls.caseLabel.value) || (isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length < 5) || (isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length > 125) }"></textarea>
                                          </div>
                                          <div *ngIf=" isNextBtnClicked &&  !generalInfo.controls.caseLabel.value">
                                             <span class="text-danger">Case Label Required</span>
                                          </div>
                                          <div
                                             *ngIf="isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length < 5">
                                             <span class="text-danger">Please enter Min 5 Characters</span>
                                          </div>
                                          <div
                                             *ngIf=" isNextBtnClicked && generalInfo.controls.caseLabel.value && generalInfo.controls.caseLabel.value.length > 125">
                                             <span class="text-danger">Please enterMax 125 Characters</span>
                                          </div>
                                       </div>

                                       <div class="col-md-12">
                                          <div class="form-group">
                                             <label>Case Summary </label>
                                             <textarea class="form-control" id="exampleTextarea1"
                                                formControlName="caseSummary" rows="4"
                                                [ngClass]="{ errorBorder: generalInfo.controls.caseSummary.hasError('maxlength')}">
                                          </textarea>
                                          </div>
                                          <div class="text-danger"
                                             *ngIf="generalInfo.controls.caseSummary.touched &&  generalInfo.controls.caseSummary.hasError('maxlength')">
                                             Maximum of 1000 Characters
                                          </div>
                                       </div>

                                       <div class="col-md-12">
                                          <div class="form-group">
                                             <label>Internal Remarks </label>
                                             <textarea class="form-control" id="internalRemarks" rows="4"
                                                formControlName="internalRemarks"
                                                [ngClass]="{ errorBorder: generalInfo.controls.internalRemarks.hasError('maxlength')}"></textarea>
                                          </div>
                                          <div class="text-danger"
                                             *ngIf="generalInfo.controls.internalRemarks.touched &&  generalInfo.controls.internalRemarks.hasError('maxlength')">
                                             Maximum of 1000 Characters
                                          </div>
                                       </div>

                                       <div class="col-md-12 mt15 mb-3">
                                          <button class="btn btn-outline-primary mr-1" (click)="submitgeneralInfo()">
                                             Next</button>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </fieldset>
                        </div>
                        <div class="tab-pane fade" id="intim-2" role="tabpanel" aria-labelledby="intim-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div class="row">
                                 <div class="col-md-12">
                                    <p class="textCenter">Intimation 143(1)</p>
                                    <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                       <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                       <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                    </span>
                                 </div>
                                 <div [formGroup]="noticeForm" class="row">
                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Intimation Number/DIN<span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span> </label>
                                          <input type="text" class="form-control" placeholder=""
                                             formControlName="orderNo"
                                             [ngClass]="{ errorBorder:isSubmitted && !noticeForm.controls.orderNo.value }">
                                       </div>
                                       <div *ngIf="isSubmitted && !noticeForm.controls.orderNo.value">
                                          <span class="text-danger">Intimation Number/DIN Required</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Intimation Date<span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span></label>
                                          <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                             <p-calendar class="form-control" formControlName="orderDate"
                                                [ngClass]="{ errorBorder:isSubmitted && !noticeForm.controls.orderDate.value }"
                                                [maxDate]="maxDate" [monthNavigator]="true" [yearNavigator]="true"
                                                yearRange="2010:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                                dataType="string">
                                             </p-calendar>
                                          </div>
                                       </div>
                                       <div *ngIf="isSubmitted && !noticeForm.controls.orderDate.value">
                                          <span class="text-danger">Intimation Date Required</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Date of Receipt<span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span></label>
                                          <div id="iris-gst-filter-frmDate" class="input-group date datepicker">
                                             <p-calendar class="form-control" formControlName="orderReceiptDate"
                                                [ngClass]="{ errorBorder:isSubmitted && !noticeForm.controls.orderReceiptDate.value }"
                                                [maxDate]="maxDate" [monthNavigator]="true" [yearNavigator]="true"
                                                yearRange="2010:{{currentYear}}" dateFormat="dd-mm-yy" [showIcon]="true"
                                                dataType="string">
                                             </p-calendar>
                                          </div>
                                       </div>
                                       <div *ngIf="isSubmitted && !noticeForm.controls.orderReceiptDate.value">
                                          <span class="text-danger">Date of Receipt Required</span>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Mode of Receipt of Order </label>
                                          <select class="form-control form-select" formControlName="orderReceiptMode">
                                             <option [value]="null"> -- Select --</option>
                                             <ng-container *ngFor=" let obj of orderReceiptMode">
                                                <option [value]="obj.value"> {{ obj.value}} </option>
                                             </ng-container>
                                          </select>
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label> Type </label>
                                          <input type="text" formControlName="noticeType" class="form-control" readonly
                                             placeholder="" id="noticeType" />
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Intimation Copy <span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span> </label>
                                          <input type="file" class="file-upload-default" #file>
                                          <div class="attachment-block"
                                             [ngClass]="{'errorBorder': isSubmitted &&  getFormControlOrder().length == 0  }">
                                             <span (click)="openNoticeLocPop(noticeCopyFormPop)"> <i
                                                   class="icon fa fa fa-paperclip" id="paperclip"
                                                   title="Attach file"></i><span *ngIf="getOrderCopyLength() != 0">
                                                   {{getOrderCopyLength()}} Attachment</span></span>
                                          </div>

                                          <div *ngIf="isSubmitted && getFormControlOrder().length == 0">
                                             <span class="text-danger">Intimation Copy Required</span>
                                          </div>
                                          <div>
                                             <ng-template #noticeCopyFormPop let-modal>
                                                <div class="modal-header" style="border: none; padding-bottom: 0px;">
                                                   <button type="button" class="close" aria-label="Close"
                                                      (click)="modal.dismiss('Cross click')"> <span
                                                         aria-hidden="true">×</span> </button>
                                                </div>
                                                <div class="col-md-12">
                                                   <label> Attachment</label>
                                                   <div class="table-responsive">
                                                      <table class="table table-bordered">
                                                         <thead>
                                                            <tr class="bgGred">
                                                               <th>Upload</th>
                                                               <th style="width:50%;">Remark (Max 100 Character allowed)
                                                               </th>
                                                            </tr>
                                                         </thead>
                                                         <tbody>
                                                            <tr *ngFor="let orderCopyFromGrp of getFormControlOrder(); let i = index"
                                                               [formGroup]="orderCopyFromGrp">
                                                               <td>
                                                                  <div class="row">
                                                                     <div class="col-md-9 nopadding">
                                                                        <div class="form-group">
                                                                           <input
                                                                              *ngIf="orderCopyFromGrp.value.isCopyOfFormLocDocUploadedClicked"
                                                                              type="file"
                                                                              id="orderCopyLoc({{i}}).fileLoc"
                                                                              class="filetype form-control"
                                                                              name="myfile"
                                                                              (change)="onFileSelect($event,'notice')">
                                                                           <p *ngIf="!orderCopyFromGrp.value.isCopyOfFormLocDocUploadedClicked || orderCopyFromGrp.value.fileLoc"
                                                                              class="form-control pt10"
                                                                              [title]="orderCopyFromGrp.value.fileName ? orderCopyFromGrp.value.fileName:''">
                                                                              {{getFileNameDta(orderCopyFromGrp.value.fileLoc)}}
                                                                           </p>
                                                                        </div>
                                                                     </div>
                                                                     <div class="col-md-3 nopadding">
                                                                        <label>
                                                                           <a class="downupIcon"
                                                                              *ngIf="orderCopyFromGrp.value.fileLoc"
                                                                              title="Download file"
                                                                              (click)="download(orderCopyFromGrp.value.fileLoc)"><i
                                                                                 class="fa fa-download"></i></a>
                                                                           <a class="downupIcon"
                                                                              *ngIf="!(orderCopyFromGrp.value.fileLoc || orderCopyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                              title="Upload file"
                                                                              (click)="uploadOrderCopyFormLoc(i)"><i
                                                                                 class="fa fa-upload"></i></a>
                                                                           <a class="downupIcon"
                                                                              (click)="deleteUploadFile(i)"
                                                                              *ngIf="(orderCopyFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                 class="fa fa-trash"
                                                                                 aria-hidden="true"></i></a>
                                                                        </label>
                                                                     </div>
                                                                  </div>
                                                               </td>
                                                               <td> <input type="text" class="form-control"
                                                                     placeholder="" id="remarks"
                                                                     formControlName="remarks"
                                                                     [title]="orderCopyFromGrp.value.remarks ? orderCopyFromGrp.value.remarks:''"
                                                                     maxlength="100" />
                                                               </td>
                                                            </tr>
                                                         </tbody>
                                                      </table>
                                                   </div>
                                                </div>
                                                <div class="modal-footer" style="border: none; padding-top: 0px;">
                                                   <button type="button" class="btn btn-outline-dark"
                                                      (click)="modal.close('Save click')">Save</button>
                                                </div>
                                             </ng-template>
                                          </div>
                                       </div>

                                    </div>

                                    <div class="col-md-12">
                                       <div class="form-group">
                                          <label>Case Summary</label>
                                          <textarea id="exampleTextarea1" rows="4"
                                             class="form-control ng-pristine ng-valid ng-touched" placeholder=""
                                             id="noticeSummary" formControlName="noticeSummary"
                                             [ngClass]="{ errorBorder:noticeForm.get('noticeSummary')?.hasError('maxlength')}">
                                       </textarea>
                                       </div>
                                       <div class="text-danger"
                                          *ngIf="noticeForm.get('noticeSummary')?.touched && noticeForm.get('noticeSummary')?.hasError('maxlength')">
                                          Maximum of 1000 characters
                                       </div>
                                    </div>

                                    <div class="col-md-3">
                                       <div class="form-group">
                                          <label>Details of Deviation From ITR? <span class="mandate"><i
                                                   class="fa fa-asterisk"></i></span></label>
                                          <select class="form-control form-select" id="itrFrom"
                                             formControlName="isDeviationRetFiled" (change)="isDeviationRetData()"
                                             [ngClass]="{ errorBorder:isSubmitted && (noticeForm.controls.isDeviationRetFiled.value ==  null) }">
                                             <option [value]="null">-- Select --</option>
                                             <ng-container *ngFor="let obj of itrDetailsOptions">
                                                <option [value]="obj.value">{{obj.label}}</option>
                                             </ng-container>
                                          </select>
                                          <div
                                             *ngIf="isSubmitted && (noticeForm.controls.isDeviationRetFiled.value ==  null)">
                                             <span class="text-danger">Details of Deviation From ITR Required</span>
                                          </div>
                                       </div>

                                    </div>

                                    <div class="col-md-12 " id="showAdjTbl" *ngIf="showTable">
                                       <div class="accordion accordion-solid-header" id="accordion-02" role="tablist">
                                          <div class="card border-bottom">
                                             <div class="card-header" role="tab" id="heading-02">
                                                <h6 class="mb-0">
                                                   <a class="bg-transparent text-dark main p-0" data-toggle="collapse"
                                                      href="#collapse-02" aria-expanded="true"
                                                      aria-controls="collapse-2">
                                                      Issue Table
                                                   </a>
                                                </h6>
                                             </div>
                                             <div id="collapse-02" class="collapse show" role="tabpanel"
                                                aria-labelledby="heading-02" data-parent="#accordion-02">
                                                <div class="card-body">
                                                   <div class=" row">
                                                      <div class="col-md-12">
                                                         <div class="search-table-outter wrapper">
                                                            <table class="search-table inner ">
                                                               <thead>
                                                                  <tr class="bgGred">
                                                                     <th class="sl-no-cla" *ngIf="updateBtn">Info</th>
                                                                     <th style="min-width: 288px;">Issues<span
                                                                           class="mandatetd"><i class="fa fa-asterisk"
                                                                              style="font-size: 7px;"></i></span></th>
                                                                     <th>Keyword (Max 150 Character)</th>
                                                                     <th>Risk<span class="mandatetd"><i
                                                                              class="fa fa-asterisk"
                                                                              style="font-size: 7px;"></i></span></th>
                                                                     <th>Addition of Income/ Disallowance of Expenses
                                                                        (₹)
                                                                     </th>
                                                                     <th>Tax Rate (Inclusive of Surcharge and Cess)
                                                                     </th>
                                                                     <th>Tax Impact (Inclusive of Surcharge and Cess)
                                                                        (₹)
                                                                     </th>
                                                                     <th>Interest (₹) </th>
                                                                     <!-- <th>Penalty (₹) </th> -->
                                                                     <th>Total (₹)</th>
                                                                     <th>Remark <a class="clrOrange infoIcon"
                                                                           title="Max 100 characters allowed."><i
                                                                              class="fa fa-info-circle"></i></a></th>
                                                                     <th class="w-80">Action</th>
                                                                  </tr>

                                                               </thead>
                                                               <tbody>
                                                                  <ng-container>
                                                                     <tr *ngFor="let div of issuInvArr(); let i=index"
                                                                        [formGroup]="div"
                                                                        (keydown.enter)="$event.preventDefault()">
                                                                        <td  class="sl-no-cla" *ngIf="updateBtn">
                                                                           <p (click)="getissueData(issuePop,i)" class="pr5"><span
                                                                             class="badge-clo" title="Cases with similar issue"><i class="fa fa-info-circle"></i></span>
                                                                           </p>
                                                                        </td>
                                                                        <td class="w250">
                                                                           <select class="form-control form-select"
                                                                              formControlName="issue"
                                                                              [title]="div.value.issue ? div.value.issue:''"
                                                                              [ngClass]="{ 'errorBorder': isSubmitted && div.get('issue')?.errors  }">
                                                                              <optgroup *ngFor='let grp of dataSource'
                                                                                 label="{{grp.group}}">
                                                                                 <option *ngFor='let item of grp.items'
                                                                                    [value]="item.name"
                                                                                    title="{{item.name}}">{{item.name}}
                                                                                 </option>
                                                                              </optgroup>
                                                                           </select>
                                                                           <div
                                                                              *ngIf="isSubmitted && div.get('issue')?.errors">
                                                                              <span class="text-danger"
                                                                                 *ngIf="div.get('issue')?.errors?.required ">Issues
                                                                                 Required
                                                                              </span>
                                                                           </div>
                                                                        </td>
                                                                        <td><input type="text" class="form-control"
                                                                              id="tax" placeholder=""
                                                                              formControlName="keyword"
                                                                              [title]="div.value.keyword ? div.value.keyword: ''">
                                                                           <div
                                                                              *ngIf="(div.controls.keyword.value &&  div.controls.keyword.value.length > 150)">
                                                                              <span class="text-danger"
                                                                                 *ngIf="(div.controls.keyword.value &&  div.controls.keyword.value.length > 150)">Max
                                                                                 Length 150 character
                                                                              </span>
                                                                           </div>
                                                                        </td>

                                                                        <td>
                                                                           <select class="form-control form-select"
                                                                              id="status" formControlName="riskParam"
                                                                              [title]="div.value.riskParam ? div.value.riskParam: ''"
                                                                              [ngClass]="{ 'errorBorder': isSubmitted && div.get('riskParam')?.errors  }">
                                                                              <!-- <option [value]="null">-- Select --</option> -->
                                                                              <ng-container
                                                                                 *ngFor="let obj of riskParam">
                                                                                 <option [value]="obj.value">
                                                                                    {{obj.value}}
                                                                                 </option>
                                                                              </ng-container>
                                                                           </select>
                                                                           <div
                                                                              *ngIf="isSubmitted && div.get('riskParam')?.errors">
                                                                              <span class="text-danger"
                                                                                 *ngIf="div.get('riskParam')?.errors?.required ">Risk
                                                                                 Required
                                                                              </span>
                                                                           </div>
                                                                        </td>


                                                                        <td> <input type="text"
                                                                              class="form-control align-right "
                                                                              placeholder=""
                                                                              formControlName="addnOrDisOfExpensesF"
                                                                              maxlength="19" InrFormat
                                                                              (input)="issueValueChange()"
                                                                              (paste)="handlePaste($event)"></td>

                                                                        <td>
                                                                           <div class="input-container">
                                                                              <input type="text"
                                                                                 class="form-control input-field align-right"
                                                                                 placeholder=""
                                                                                 formControlName="taxRateF"
                                                                                 id="taxRateF" maxlength="19"
                                                                                 (blur)="handleDecimalPaste(i)"
                                                                                 (input)="issueValueChange();">
                                                                              <i class="icon"
                                                                                 style="position: absolute; transform: translateY(-50%);">%</i>
                                                                           </div>
                                                                           <div *ngIf="div.get('taxRateF')?.errors">
                                                                              <span class="text-danger"
                                                                                 *ngIf="div.get('taxRateF')?.errors?.pattern">
                                                                                 Please Enter 0 to 100</span>
                                                                           </div>
                                                                        </td>
                                                                        <td> <input type="text"
                                                                              class="form-control align-right "
                                                                              placeholder=""
                                                                              formControlName="taxImpactF"
                                                                              maxlength="19" InrFormat
                                                                              (input)="issueValueChange()"
                                                                              (paste)="handlePaste($event)">
                                                                        </td>

                                                                        <td> <input type="text"
                                                                              class="form-control align-right "
                                                                              placeholder="" formControlName="interestF"
                                                                              maxlength="19" InrFormat
                                                                              (input)="issueValueChange()"
                                                                              (paste)="handlePaste($event)">
                                                                        </td>

                                                                        <td> <input type="text"
                                                                              class="form-control align-right "
                                                                              placeholder="" readonly
                                                                              formControlName="totalF" maxlength="19"
                                                                              InrFormat (change)="issueValueChange()">
                                                                        </td>
                                                                        <td> <input type="text" class="form-control"
                                                                              placeholder="" formControlName="remarkF"
                                                                              [title]="div.value.remarkF ? div.value.remarkF: ''"
                                                                              id="remarkF"
                                                                              (click)="openTextBoxModal1('Remark', 'remarkF',div,i)">
                                                                           <div
                                                                              *ngIf="div.get('remarkF')?.value && div.get('remarkF')?.value.length > 100">
                                                                              <span class="text-danger"
                                                                                 *ngIf="div.get('remarkF')?.value && div.get('remarkF')?.value.length > 100">Max
                                                                                 Length is 100 character
                                                                              </span>
                                                                           </div>
                                                                        </td>
                                                                        <!-- <td><span (click)="openDetailPop(detailPop, i)"><i class="icon  fa fa-file-text-o"></i></span></td> -->
                                                                        <td class="w-80">
                                                                           <a class=" addExtLetter"
                                                                              *ngIf="(noticeForm.get('issues').controls.length - 1) == i"
                                                                              (click)="addIssueInv()"><i
                                                                                 class="fa fa-plus"
                                                                                 title="Add row"></i></a>
                                                                           <a class="removeDiv" *ngIf="i!= 0"
                                                                              data-toggle="modal"
                                                                              data-target="#removeIssuePopup"
                                                                              (click)="openRmvIssuePop(removeIssuePopup, i)"
                                                                              style="margin-left: 15%;"><i
                                                                                 class="fa fa-minus"></i></a>
                                                                        </td>
                                                                        <ng-template #removeIssuePopup let-modal>
                                                                           <div class="modal-header">
                                                                              <h4 class="modal-title"
                                                                                 id="modal-basic-title">Remove Warning!
                                                                              </h4>
                                                                              <button type="button" class="close"
                                                                                 aria-label="Close"
                                                                                 (click)="modal.dismiss('Cross click')">
                                                                                 <span aria-hidden="true">×</span>
                                                                              </button>
                                                                           </div>
                                                                           <div class="modal-body">
                                                                              <p>Are you sure, you want to remove?</p>
                                                                           </div>
                                                                           <div class="modal-footer">
                                                                              <button type="button"
                                                                                 class="btn btn-outline-dark"
                                                                                 (click)="rmIssueInv(i)">Remove</button>
                                                                           </div>
                                                                        </ng-template>
                                                                        <ng-template #issuePop let-modal>
                                                                           <div class="modal-header">
                                                                             <button type="button" class="close" aria-label="Close"
                                                                              (click)="modal.dismiss('Cross click')"> <span aria-hidden="true" style="color: red;">×</span>
                                                                             </button>
                                                                           </div>
                                                                           <div class="col-md-12" >
                                                                             <div class="table-responsive">
                                                                              <p class="title-data">For your reference following latest 15 cases are exist in LMS with selected issue: <span style="font-weight: bold;">{{div.value.issue}}</span></p>
                                                                              <div class="btn btn-outline-success btn-download btnRep" (click)="exportAsXLSX()" >
                                                                                <i aria-hidden="true" class="fa fa-file-excel-o"></i> &nbsp; Export
                                                                              </div>
                                                                              <table *ngIf="this.hideData">
                                                                                <thead >
                                                                                 <tr class="bgGred1">
                                                                                   <th class="sl-no-cla">Sr No</th>
                                                                                   <th><span>PAN</span>&nbsp;
                                                                                    <i class="fa fa-arrow-up cursor-pointer" 
                                                                                       aria-hidden="true" *ngIf="!sorting.panName"
                                                                                       (click)="SortingIssue(issueSorting.PAN)"></i>
                                                                                    <i class="fa fa-arrow-down cursor-pointer"
                                                                                       aria-hidden="true" *ngIf="sorting.panName"
                                                                                       (click)="SortingIssue(issueSorting.PAN)"></i>
                                                                                   </th>
                                                                                   <th><span>Case ID</span> &nbsp;
                                                                                    <i class="fa fa-arrow-up cursor-pointer"
                                                                                       aria-hidden="true" *ngIf="!sorting.caseId"
                                                                                       (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                                                                    <i class="fa fa-arrow-down cursor-pointer"
                                                                                       aria-hidden="true" *ngIf="sorting.caseId"
                                                                                       (click)="SortingIssue(issueSorting.CASE_ID)"></i>
                                                                                 </th>
                                                                                   <th class="clas"><span>Case Label</span>&nbsp;
                                                                                    <i class="fa fa-arrow-up cursor-pointer"
                                                                                       aria-hidden="true" *ngIf="!sorting.caseLabel"
                                                                                       (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                                                                    <i class="fa fa-arrow-down cursor-pointer"
                                                                                       aria-hidden="true" *ngIf="sorting.caseLabel"
                                                                                       (click)="SortingIssue(issueSorting.CASE_LABEL)"></i>
                                                                                   </th>
                                                                                   <th ><span>Assessment Year</span>&nbsp;
                                                                                    <i class="fa fa-arrow-up cursor-pointer"
                                                                                       aria-hidden="true"
                                                                                       *ngIf="!sorting.ay"
                                                                                       (click)="SortingIssue(issueSorting.AY)"></i>
                                                                                    <i class="fa fa-arrow-down cursor-pointer"
                                                                                       aria-hidden="true"
                                                                                       *ngIf="sorting.ay"
                                                                                       (click)="SortingIssue(issueSorting.AY)"></i>
                                                                                 </th>
                                                                                   <th><span>Person Responsible</span>&nbsp;
                                                                                    <i class="fa fa-arrow-up cursor-pointer"
                                                                                       aria-hidden="true"
                                                                                       *ngIf="!sorting.personResponsible"
                                                                                       (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                                                                    <i class="fa fa-arrow-down cursor-pointer"
                                                                                       aria-hidden="true"
                                                                                       *ngIf="sorting.personResponsible"
                                                                                       (click)="SortingIssue(issueSorting.PERSON_RESPONSIBLE)"></i>
                                                                                 </th>
                                                                                 </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                 <tr
                                                                                   *ngFor="let obj of issueRefData  let i = index">
                                                                                   <td class="sl-no-cla">{{i + 1}}</td>
                                                                                   <td >{{obj.panName}}</td>
                                                                                   <td >{{obj.caseId}}</td>
                                                                                   <td class="clas">{{obj.caseLabel}}</td>
                                                                                   <td >{{obj.ay}}</td>
                                                                                   <td>{{obj.personResponsible}}</td>
                                                                                 </tr>
                                                                                </tbody>
                                                                              </table>
                                                                              <div *ngIf="!this.hideData" style="font-size: large;text-align: center;font-weight: bold;">
                                                                                No data found.
                                                                              </div>
                                                                              
                                                                             </div>
                                                                           </div>
                                                                         </ng-template>
                                                                     </tr>
                                                                  </ng-container>
                                                                  <ng-container>
                                                                     <tr>
                                                                        <th *ngIf="!updateBtn" colspan="3"
                                                                        class="total-titel">
                                                                        Total</th>
                                                                     <th  *ngIf="updateBtn" colspan="4"
                                                                        class="total-titel">
                                                                        Total</th>                                                                        <td><input type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="totOfAddnOrDisOfExpensesF"
                                                                              id="tax" placeholder="" InrFormat
                                                                              maxlength="19" readonly></td>
                                                                        <td colspan="1">&nbsp;</td>
                                                                        <td><input type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="taxImpactFTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <td><input type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="interestFTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <!-- <td><input type="text" class="form-control bold-font align-right" formControlName="penaltyFTotal" id="tax" placeholder="" InrFormat maxlength="19" readonly></td> -->
                                                                        <td><input type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="totOfTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <td colspan="9">&nbsp;</td>
                                                                     </tr>
                                                                  </ng-container>
                                                               </tbody>

                                                            </table>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-md-12">
                                       <a class="showAuthDetailTbl anchorClick"
                                          (click)="detailsOfIssuingAuthority = !detailsOfIssuingAuthority"><i
                                             class="fa fa-id-badge mr5"></i>Add Authority Details</a>
                                    </div>
                                    <div class="col-md-12 AuthDetailTbl" *ngIf="detailsOfIssuingAuthority">
                                       <div class="accordion accordion-solid-header" id="accordion-03" role="tablist">
                                          <div class="card border-bottom">
                                             <div id="collapse-03" class="collapse show" role="tabpanel"
                                                aria-labelledby="heading-03" data-parent="#accordion-03">
                                                <div class="card-body">
                                                   <div class="row">
                                                      <div class="table-responsive">
                                                         <table class="table table-bordered">
                                                            <tr>
                                                               <th>Notice Number/ DIN</th>
                                                               <th>Officer Name </th>
                                                               <th>Designation</th>
                                                               <th>Jurisdiction</th>
                                                               <th>Address </th>
                                                               <th>Contact Number </th>
                                                               <th>Email ID </th>
                                                               <th class="w-80">Action</th>
                                                            </tr>
                                                            <tr *ngFor="let div of getIssuingAuthority(); let i=index"
                                                               [formGroup]="div">
                                                               <td><input type="text" class="form-control"
                                                                     placeholder="" formControlName="noticeNo"
                                                                     maxlength="100"
                                                                     [title]="div.value.noticeNo ? div.value.noticeNo: ''">
                                                               </td>
                                                               <td><input type="text" class="form-control"
                                                                     placeholder="" formControlName="officer"
                                                                     maxlength="100"
                                                                     [title]="div.value.officer ? div.value.officer: ''">
                                                               </td>
                                                               <td><input type="text" class="form-control"
                                                                     placeholder="" formControlName="designation"
                                                                     maxlength="100"
                                                                     [title]="div.value.designation ? div.value.designation:''">
                                                               </td>
                                                               <td><input type="text" class="form-control"
                                                                     placeholder="" formControlName="jurisdiction"
                                                                     maxlength="100"
                                                                     [title]="div.value.jurisdiction ? div.value.jurisdiction: ''" />
                                                               </td>
                                                               <td><input type="text" class="form-control"
                                                                     placeholder="" formControlName="address"
                                                                     maxlength="100"
                                                                     [title]="div.value.address ? div.value.address: ''">
                                                               </td>
                                                               <td><input type="text" class="form-control"
                                                                     placeholder="" formControlName="contact"
                                                                     maxlength="12"
                                                                     oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                               </td>
                                                               <td><input type="text" class="form-control"
                                                                     placeholder="" formControlName="email"
                                                                     [title]="div.value.email ? div.value.email: ''">
                                                                  <div *ngIf="div.get('email')?.errors">
                                                                     <span class="text-danger"
                                                                        *ngIf="div.get('email')?.errors?.pattern">
                                                                        Please
                                                                        Enter Valid Email ID</span>
                                                                  </div>
                                                               </td>
                                                               <td class="w-80">
                                                                  <a class="addExtLetter"
                                                                     (click)="addIssuingAuthority()"
                                                                     *ngIf="(noticeForm.get('issuingAuthority').controls.length - 1) == i"><i
                                                                        class="fa fa-plus"></i></a>
                                                                  <a class="addExtLetter" *ngIf="i != 0"
                                                                     (click)="openIssuingAuthorityPop(rmissuingAuthorityPop,i)"><i
                                                                        class="fa fa-minus"></i></a>
                                                               </td>
                                                               <ng-template #rmissuingAuthorityPop let-modal>
                                                                  <div class="modal-header">
                                                                     <h4 class="modal-title" id="modal-basic-title">
                                                                        Remove
                                                                        Warning! </h4>
                                                                     <button type="button" class="close"
                                                                        aria-label="Close"
                                                                        (click)="modal.dismiss('Cross click')">
                                                                        <span aria-hidden="true">×</span>
                                                                     </button>
                                                                  </div>
                                                                  <div class="modal-body">
                                                                     <p>Are you sure, you want to remove?</p>
                                                                  </div>
                                                                  <div class="modal-footer">
                                                                     <button type="button" class="btn btn-outline-dark"
                                                                        (click)="rmIssuingAuthorityInv(i)">Remove</button>
                                                                  </div>
                                                               </ng-template>
                                                            </tr>
                                                         </table>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-md-4">
                                       <div class="form-group">
                                          <label>Whether Any Demand Raised / Refund Reduced? </label>
                                          <select class="form-control form-select"
                                             formControlName="isDemandRaisedOrReduced1">
                                             <option [value]="null"> -- Select --</option>
                                             <ng-container *ngFor=" let obj of OrReduced1">
                                                <option [value]="obj.value"> {{ obj.label}} </option>
                                             </ng-container>
                                          </select>
                                       </div>
                                    </div>
                                    <div class="col-md-4">
                                       <div class="form-group">
                                          <label>Demand Raised/Refund Reduced Amount</label>
                                          <input type="text" class="form-control" id="tax" formControlName="demandAmt1"
                                             InrFormat style="width: 80%;"
                                             [ngClass]="{ errorBorder:noticeForm.controls.isDemandRaisedOrReduced1.value =='true'&& (isSubmitted && !noticeForm.controls.demandAmt1.value)}">
                                       </div>
                                       <div
                                          *ngIf="noticeForm.controls.isDemandRaisedOrReduced1.value =='true'&& (isSubmitted && !noticeForm.controls.demandAmt1.value)">
                                          <span class="text-danger">Demand Raised / Refund Reduced amount
                                             Required</span>
                                       </div>
                                    </div>
                                    <div class="col-md-12 mt15 mb-3">
                                       <button class="btn btn-outline-primary mr-1" type="submit"
                                          (click)="submitOrderForm()">Next</button>
                                    </div>
                                 </div>
                              </div>
                           </fieldset>
                        </div>
                        <div class="tab-pane fade" id="itr-2" role="tabpanel" aria-labelledby="itr-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div class="row">
                                 <div class="col-md-12">
                                    <p class="textCenter">ITR Details</p>
                                    <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                       <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                       <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                    </span>
                                 </div>
                                 <div [formGroup]="iTRDetailsForm">
                                    <div class="col-md-12">
                                       <div class="row">
                                          <div class="col-md-12 table-responsive">
                                             <table class="table table-bordered">
                                                <tr class="bgGred">
                                                   <th style="min-width:315px;">Return Filed U/s</th>
                                                   <th>ITR Form Type</th>
                                                   <th>Ack Number</th>
                                                   <th>Date of Filing</th>
                                                   <th>Total Taxable Income </th>
                                                   <th>Total Tax Liability (₹)</th>
                                                   <th>Advance Tax(₹)</th>
                                                   <th>TDS (₹)</th>
                                                   <th>TCS (₹)</th>
                                                   <th>Self Assessment Tax (₹)</th>
                                                   <th>Total Tax Paid (₹)</th>
                                                   <th>(Refund)/ Demand (₹)</th>
                                                   <th class="w-80">Action</th>
                                                </tr>
                                                <ng-container formArrayName="itrDetails">
                                                   <tr *ngFor="let itrDetailsGrp of getItrDetails(); let i=index"
                                                      [formGroup]="itrDetailsGrp">
                                                      <td>
                                                         <select class="form-control form-select" id="status"
                                                            formControlName="returnFiledUs"
                                                            [title]="itrDetailsGrp.value.returnFiledUs ? itrDetailsGrp.value.returnFiledUs: ''">
                                                            <option [value]="null">-- Select --</option>
                                                            <ng-container *ngFor="let obj of returnFiledUs">
                                                               <option [value]="obj.value">{{obj.value}}</option>
                                                            </ng-container>
                                                         </select>
                                                      </td>

                                                      <td>
                                                         <select class="form-control form-select" id="status"
                                                            formControlName="itrForm"
                                                            [title]="itrDetailsGrp.value.itrForm ? itrDetailsGrp.value.itrForm:''">
                                                            <option [value]="null">-- Select --</option>
                                                            <ng-container *ngFor="let obj of itrFormList">
                                                               <option [value]="obj.value">{{obj.value}}</option>
                                                            </ng-container>
                                                         </select>
                                                      </td>

                                                      <td><input type="text" class="form-control" placeholder=""
                                                            formControlName="ackNo" maxlength="50"
                                                            [title]="itrDetailsGrp.value.ackNo"></td>
                                                      <td>
                                                         <div id="iris-gst-filter-frmDate"
                                                            class="input-group date datepicker">
                                                            <p-calendar appendTo="body" class="form-control"
                                                               formControlName="filingDate" name="filingDate"
                                                               [title]="itrDetailsGrp.value.filingDate ? itrDetailsGrp.value.filingDate: ''"
                                                               [monthNavigator]="true" [yearNavigator]="true"
                                                               yearRange="2010:{{currentYear}}" dateFormat="dd-mm-yy"
                                                               [showIcon]="true" dataType="string" [maxDate]="maxDate">
                                                            </p-calendar>
                                                         </div>
                                                      </td>

                                                      <td><input type="text" class="form-control align-right "
                                                            placeholder="" InrFormat formControlName="totTaxIncome"
                                                            [title]="itrDetailsGrp.value.totTaxIncome ? itrDetailsGrp.value.totTaxIncome:''">
                                                      </td>
                                                      <td><input type="text" class="form-control align-right "
                                                            placeholder="" InrFormat formControlName="totTaxLiability"
                                                            (input)="itrDetailsPaidChanged()" maxlength="19"
                                                            (paste)="handlePaste($event)"
                                                            [title]="itrDetailsGrp.value.totTaxLiability ? itrDetailsGrp.value.totTaxLiability:''">
                                                      </td>
                                                      <td><input type="text" class="form-control align-right "
                                                         placeholder="" InrFormat formControlName="advanceTax"
                                                         (input)="itrDetailsPaidChanged()" maxlength="19"
                                                         (paste)="handlePaste($event)"
                                                         [title]="itrDetailsGrp.value.advanceTax ? itrDetailsGrp.value.advanceTax :''">
                                                      </td>
                                                      <td><input type="text" class="form-control align-right "
                                                         placeholder="" InrFormat formControlName="tdsAmount"
                                                         (input)="itrDetailsPaidChanged()" maxlength="19"
                                                         (paste)="handlePaste($event)"
                                                         [title]="itrDetailsGrp.value.tdsAmount ? itrDetailsGrp.value.tdsAmount :''">
                                                      </td>
                                                      <td><input type="text" class="form-control align-right "
                                                         placeholder="" InrFormat formControlName="tcsAmount"
                                                         (input)="itrDetailsPaidChanged()" maxlength="19"
                                                         (paste)="handlePaste($event)"
                                                         [title]="itrDetailsGrp.value.tcsAmount ? itrDetailsGrp.value.tcsAmount :''">
                                                      </td>
                                                      <td><input type="text" class="form-control align-right "
                                                         placeholder="" InrFormat formControlName="selfAssessmentAmount"
                                                         (input)="itrDetailsPaidChanged()" maxlength="19"
                                                         (paste)="handlePaste($event)"
                                                         [title]="itrDetailsGrp.value.selfAssessmentAmount ? itrDetailsGrp.value.selfAssessmentAmount :''">
                                                      </td>
                                                      <td><input type="text" class="form-control align-right "
                                                            placeholder="" InrFormat formControlName="totTaxPaid"
                                                            (input)="itrDetailsPaidChanged()" maxlength="19"
                                                            (paste)="handlePaste($event)"
                                                            [title]="itrDetailsGrp.value.totTaxPaid ? itrDetailsGrp.value.totTaxPaid:''">
                                                      </td>
                                                      <td><input type="text" class="form-control align-right "
                                                            placeholder="" InrFormat formControlName="refundDemand"
                                                            (input)="itrDetailsPaidChanged()" maxlength="19"
                                                            (paste)="handlePaste($event)"
                                                            [title]="itrDetailsGrp.value.refundDemand ? itrDetailsGrp.value.refundDemand:''">
                                                      </td>
                                                      <td class="w-80">
                                                         <a class="addExtLetter" (click)="addItrDetails()"
                                                            *ngIf="(iTRDetailsForm.get('itrDetails').controls.length - 1) == i"><i
                                                               class="fa fa-plus"></i></a>
                                                         <a class="addExtLetter" *ngIf="i != 0"
                                                            (click)="openItrDetailsPop(rmItrDetailsPop,i)"><i
                                                               class="fa fa-minus"></i></a>
                                                      </td>
                                                      <ng-template #rmItrDetailsPop let-modal>
                                                         <div class="modal-header">
                                                            <h4 class="modal-title" id="modal-basic-title">Remove
                                                               Warning!
                                                            </h4>
                                                            <button type="button" class="close" aria-label="Close"
                                                               (click)="modal.dismiss('Cross click')">
                                                               <span aria-hidden="true">×</span>
                                                            </button>
                                                         </div>
                                                         <div class="modal-body">
                                                            <p>Are you sure, you want to remove?</p>
                                                         </div>
                                                         <div class="modal-footer">
                                                            <button type="button" class="btn btn-outline-dark"
                                                               (click)="rmItrDetailsInv(i)">Remove</button>
                                                         </div>
                                                      </ng-template>
                                                   </tr>
                                                </ng-container>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-12 mt15 mb-3">
                                       <button class="btn btn-outline-primary mr-1" type="submit"
                                          (click)="submitiTRDetailsForm()">Next</button>
                                    </div>
                                 </div>
                              </div>
                           </fieldset>
                        </div>

                        <div class="tab-pane fade" id="taxPay-2" role="tabpanel" aria-labelledby="taxPay-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div class="row">
                                 <div class="col-md-12">
                                    <p class="textCenter">Assessed Tax and Assessed Refund details</p>
                                    <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                       <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                       <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                    </span>
                                 </div>
                                 <div [formGroup]="taxPaymentRefundDetailsForm">
                                    <div class="col-md-12">
                                       <div class="accordion accordion-solid-header" id="accordion-041" role="tablist">
                                          <div class="card border-bottom">
                                             <div class="card-header" role="tab" id="heading-041">
                                                <h6 class="mb-0" style="margin-left: 2%; margin-top: 1%;">Details of
                                                   Refund
                                                   Received</h6>
                                             </div>
                                             <div>
                                                <div class="card-body" style="margin-top:1%;">
                                                   <div class="row">
                                                      <div class="table-responsive">
                                                         <table class="search-table inner">
                                                            <tr class="bgGred">
                                                               <th class="w-40">Sr.No</th>
                                                               <th>Date of Receipt</th>
                                                               <th>Mode </th>
                                                               <th>Tax (₹)</th>
                                                               <th>Interest (₹)</th>
                                                               <th>Total (₹)</th>
                                                               <th class="w-80">Action</th>
                                                            </tr>
                                                            <ng-container>
                                                               <tr *ngFor="let refundDetailsGrp of getRefundDetails(); let i=index"
                                                                  [formGroup]="refundDetailsGrp">
                                                                  <td class="sl-no-cls w-40"> {{i+1}}</td>
                                                                  <td>
                                                                     <div id="iris-gst-filter-frmDate"
                                                                        class="input-group date datepicker">
                                                                        <p-calendar appendTo="body" class="form-control"
                                                                           formControlName="receiptDate"
                                                                           [title]="refundDetailsGrp.value.receiptDate ? refundDetailsGrp.value.receiptDate:''"
                                                                           [monthNavigator]="true"
                                                                           [yearNavigator]="true"
                                                                           yearRange="2010:{{currentYear}}"
                                                                           dateFormat="dd-mm-yy" [showIcon]="true"
                                                                           dataType="string" [maxDate]="maxDate">
                                                                        </p-calendar>
                                                                     </div>
                                                                  </td>
                                                                  <td>
                                                                     <select class="form-control form-select"
                                                                        id="status" formControlName="mode"
                                                                        [title]="refundDetailsGrp.value.mode ? refundDetailsGrp.value.mode:''">
                                                                        <option [value]="null">-- Select --</option>
                                                                        <ng-container *ngFor="let obj of modeOfPayemt">
                                                                           <option [value]="obj.value">{{obj.value}}
                                                                           </option>
                                                                        </ng-container>
                                                                     </select>
                                                                  </td>
                                                                  <td><input type="text"
                                                                        class="form-control align-right " placeholder=""
                                                                        formControlName="tax" InrFormat
                                                                        [title]="refundDetailsGrp.value.tax ? refundDetailsGrp.value.tax:''"
                                                                        maxlength="19" (input)="refundDetailsChanged()"
                                                                        (paste)="handlePaste($event)"></td>
                                                                  <td><input type="text"
                                                                        class="form-control align-right " placeholder=""
                                                                        formControlName="interest" InrFormat
                                                                        [title]="refundDetailsGrp.value.interest ? refundDetailsGrp.value.interest:''"
                                                                        maxlength="19" (input)="refundDetailsChanged()"
                                                                        (paste)="handlePaste($event)"></td>
                                                                  <td><input type="text"
                                                                        class="form-control align-right " placeholder=""
                                                                        formControlName="total" InrFormat readonly
                                                                        [title]="refundDetailsGrp.value.total ? refundDetailsGrp.value.total:''"
                                                                        maxlength="19" (input)="refundDetailsChanged()"
                                                                        (paste)="handlePaste($event)"></td>
                                                                  <td class="w-80">
                                                                     <a class="addExtLetter"
                                                                        (click)="addRefundDetails()"><i
                                                                           class="fa fa-plus"></i></a>
                                                                     <a class="addExtLetter" *ngIf="i != 0"
                                                                        (click)="openRefundDetailsPop(rmrefundDetailsop,i)"><i
                                                                           class="fa fa-minus"></i></a>
                                                                  </td>
                                                                  <ng-template #rmrefundDetailsop let-modal>
                                                                     <div class="modal-header">
                                                                        <h4 class="modal-title" id="modal-basic-title">
                                                                           Remove Warning! </h4>
                                                                        <button type="button" class="close"
                                                                           aria-label="Close"
                                                                           (click)="modal.dismiss('Cross click')">
                                                                           <span aria-hidden="true">×</span>
                                                                        </button>
                                                                     </div>
                                                                     <div class="modal-body">
                                                                        <p>Are you sure, you want to remove?</p>
                                                                     </div>
                                                                     <div class="modal-footer">
                                                                        <button type="button"
                                                                           class="btn btn-outline-dark"
                                                                           (click)="rmRefundDetailsInv(i)">Remove</button>
                                                                     </div>
                                                                  </ng-template>
                                                               </tr>
                                                               <tr>
                                                                  <th colspan="3" class="total-titel">Total</th>
                                                                  <td class="align-right bold-font">
                                                                     {{refundPaidTaxTotal|
                                                                     INR: 0}}</td>
                                                                  <td class="align-right bold-font">
                                                                     {{refundPaidInterestTotal| INR: 0 }}</td>
                                                                  <td class="align-right bold-font">{{refundPaidTotal |
                                                                     INR: 0}}</td>
                                                               </tr>
                                                            </ng-container>
                                                         </table>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-12">
                                       <div class="accordion accordion-solid-header" id="accordion-04" role="tablist">
                                          <div class="card border-bottom">
                                             <div class="card-header" role="tab" id="heading-04">
                                                <h6 class="mb-0" style="margin-left: 2%; margin-top: 1%;"> Details of
                                                   Assessed Tax Paid </h6>
                                             </div>
                                             <div>
                                                <div class="card-body" style="margin-top:1%;">
                                                   <div class="row">
                                                      <div class="table-responsive">
                                                         <table class="search-table inner">
                                                            <tr class="bgGred">
                                                               <th class="w-40">Sr.No</th>
                                                               <th>CIN/ Ref. No. </th>
                                                               <th>Date </th>
                                                               <th>BSR Code </th>
                                                               <th>Tax (₹)</th>
                                                               <th>Interest (₹)</th>
                                                               <th>Total (₹) </th>
                                                               <th>Challan Copy</th>
                                                               <th class="w-80">Action</th>
                                                            </tr>
                                                            <ng-container>
                                                               <tr *ngFor="let libDetailsGrp of getLibDetails(); let i=index"
                                                                  [formGroup]="libDetailsGrp">
                                                                  <td class="sl-no-cls w-40"> {{i+1}}</td>
                                                                  <td><input type="text" class="form-control"
                                                                        placeholder="" formControlName="cinRefNo"
                                                                        maxlength="50"
                                                                        [title]="libDetailsGrp.value.cinRefNo ? libDetailsGrp.value.cinRefNo:''">
                                                                  </td>
                                                                  <td>
                                                                     <div id="iris-gst-filter-frmDate"
                                                                        class="input-group date datepicker">
                                                                        <p-calendar appendTo="body" class="form-control"
                                                                           formControlName="date" name="date"
                                                                           [title]="libDetailsGrp.value.date ? libDetailsGrp.value.date:''"
                                                                           [monthNavigator]="true"
                                                                           [yearNavigator]="true"
                                                                           yearRange="2010:{{currentYear}}"
                                                                           dateFormat="dd-mm-yy" [showIcon]="true"
                                                                           dataType="string" [maxDate]="maxDate">
                                                                        </p-calendar>
                                                                     </div>
                                                                  </td>

                                                                  <td><input type="text" class="form-control"
                                                                        placeholder="" formControlName="bsrCode"
                                                                        maxlength="50"
                                                                        [title]="libDetailsGrp.value.bsrCode ? libDetailsGrp.value.bsrCode:''">
                                                                  </td>
                                                                  <td><input type="text"
                                                                        class="form-control align-right" placeholder=""
                                                                        formControlName="tax"
                                                                        [title]="libDetailsGrp.value.tax ? libDetailsGrp.value.tax:''"
                                                                        InrFormat
                                                                        (input)="liabilityAddmittedAndPaidChanged()"
                                                                        maxlength="19" (paste)="handlePaste($event)">
                                                                  </td>
                                                                  <td><input type="text"
                                                                        class="form-control align-right" placeholder=""
                                                                        formControlName="interest"
                                                                        [title]="libDetailsGrp.value.interest ? libDetailsGrp.value.interest:''"
                                                                        InrFormat
                                                                        (input)="liabilityAddmittedAndPaidChanged()"
                                                                        maxlength="19" (paste)="handlePaste($event)">
                                                                  </td>
                                                                  <td><input type="text"
                                                                        class="form-control align-right" placeholder=""
                                                                        formControlName="total"
                                                                        [title]="libDetailsGrp.value.total ? libDetailsGrp.value.total:''"
                                                                        InrFormat
                                                                        (input)="liabilityAddmittedAndPaidChanged()"
                                                                        maxlength="19">

                                                                  <td><span
                                                                        (click)="openLibDetailsGrpPop(ChallanDetailsFormPop, i)"><i
                                                                           class="icon fa fa fa-paperclip"
                                                                           id="paperclip"
                                                                           title="Attach file"></i></span><span
                                                                        *ngIf="getChallanLength(i) != 0">{{getChallanLength(i)}}
                                                                        Attachment</span></td>
                                                                  <td class="w-80"><a class=" addExtLetter"
                                                                        (click)="addLibDetails()"><i
                                                                           class="fa fa-plus"></i></a>
                                                                     <a class="addExtLetter" *ngIf="i !=0"
                                                                        (click)="openLibDetailsPop(rmvLiabilityAddmittedAndPaidModalRef,i)"><i
                                                                           class="fa fa-minus"></i></a>
                                                                  </td>
                                                                  <ng-template #rmvLiabilityAddmittedAndPaidModalRef
                                                                     let-modal>
                                                                     <div class="modal-header">
                                                                        <h4 class="modal-title" id="modal-basic-title">
                                                                           Remove Warning!</h4>
                                                                        <button type="button" class="close"
                                                                           aria-label="Close"
                                                                           (click)="modal.dismiss('Cross click')">
                                                                           <span aria-hidden="true">×</span>
                                                                        </button>
                                                                     </div>
                                                                     <div class="modal-body">
                                                                        <p>Are you sure, you want to remove?</p>
                                                                     </div>
                                                                     <div class="modal-footer">
                                                                        <button type="button"
                                                                           class="btn btn-outline-dark"
                                                                           (click)="rmLibDetailsInv(i)">Remove</button>
                                                                     </div>
                                                                  </ng-template>
                                                                  <ng-template #ChallanDetailsFormPop let-modal>
                                                                     <div class="modal-header"
                                                                        style="border: none; padding-bottom: 0px;">
                                                                        <button type="button" class="close"
                                                                           aria-label="Close"
                                                                           (click)="modal.dismiss('Cross click')"> <span
                                                                              aria-hidden="true">×</span> </button>
                                                                     </div>
                                                                     <div class="col-md-12"> <label>Attachment</label>
                                                                        <div class="table-responsive">
                                                                           <table class="table table-bordered">
                                                                              <thead>
                                                                                 <tr class="bgGred">
                                                                                    <th> Upload </th>
                                                                                    <th style="width:50%;">Remark (Max
                                                                                       100
                                                                                       Character allowed)</th>
                                                                                 </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                                 <tr *ngFor="let getChallanFromGrp of getChallan(libDetailsGrp); let j=index"
                                                                                    [formGroup]="getChallanFromGrp">
                                                                                    <td>
                                                                                       <div class="row">
                                                                                          <div
                                                                                             class="col-md-9 nopadding">
                                                                                             <div class="form-group">
                                                                                                <input
                                                                                                   *ngIf="getChallanFromGrp.value.isdocLocUploadedClicked"
                                                                                                   type="file"
                                                                                                   id="libDetails1({{i}}).challanCopyLoc({{j}}).fileLoc"
                                                                                                   class="filetype form-control"
                                                                                                   name="myfile"
                                                                                                   (change)="onFileSelect($event,'tax')">
                                                                                                <p *ngIf="!getChallanFromGrp.value.isdocLocUploadedClicked || getChallanFromGrp.value.fileLoc"
                                                                                                   class="form-control pt10">
                                                                                                   {{getFileNameDta(getChallanFromGrp.value.fileLoc)}}
                                                                                                </p>
                                                                                             </div>
                                                                                          </div>
                                                                                          <div
                                                                                             class="col-md-3 nopadding">
                                                                                             <label>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="getChallanFromGrp.value.fileLoc"
                                                                                                   title="Download file"
                                                                                                   (click)="download(getChallanFromGrp.value.fileLoc)"><i
                                                                                                      class="fa fa-download"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="!(getChallanFromGrp.value.fileLoc || getChallanFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                   title="Upload file"
                                                                                                   (click)="uploadChallan(i,j)"><i
                                                                                                      class="fa fa-upload"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   (click)="deleteChallanCopyFile(i,j)"
                                                                                                   *ngIf="(getChallanFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                      class="fa fa-trash"
                                                                                                      aria-hidden="true"></i></a>
                                                                                             </label>

                                                                                          </div>
                                                                                       </div>
                                                                                    </td>
                                                                                    <td> <input type="text"
                                                                                          class="form-control"
                                                                                          placeholder="" id="remarks"
                                                                                          formControlName="remarks"
                                                                                          [title]="getChallanFromGrp.value.remarks ? getChallanFromGrp.value.remarks :''"
                                                                                          maxlength="100" />
                                                                                    </td>
                                                                                 </tr>
                                                                              </tbody>
                                                                           </table>
                                                                        </div>
                                                                     </div>
                                                                     <div class="modal-footer"
                                                                        style="border: none; padding-top: 0px;">
                                                                        <button type="button"
                                                                           class="btn btn-outline-dark"
                                                                           (click)="modal.close('Save click')">Save</button>
                                                                     </div>
                                                                  </ng-template>
                                                               </tr>
                                                            </ng-container>
                                                            <ng-container>
                                                               <tr>
                                                                  <th colspan="4" class="total-titel">Total</th>
                                                                  <td class="align-right bold-font">
                                                                     {{liabilityAddmittedAndPaidTaxTotal| INR: 0}}</td>
                                                                  <td class="align-right bold-font">
                                                                     {{liabilityAddmittedAndPaidInterestTotal| INR: 0 }}
                                                                  </td>
                                                                  <td class="align-right bold-font">
                                                                     {{liabilityAddmittedAndPaidTotal | INR: 0}}</td>
                                                               </tr>
                                                            </ng-container>
                                                         </table>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-12 mt15" *ngIf="!updateBtn">
                                       <button class="btn btn-outline-primary mr-1"
                                          (click)="submittaxPaymentRefundDetailsForm()">save</button>
                                    </div>
                                    <div class="col-md-12 mt15" *ngIf="updateBtn">
                                       <button class="btn btn-outline-primary mr-1"
                                          (click)="submitTaxForm()">Next</button>
                                    </div>
                                 </div>
                              </div>
                           </fieldset>
                        </div>
                        <div class="tab-pane fade" id="conclusion-2" role="tabpanel"
                           aria-labelledby="conclusion-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div class="row">
                                 <div class="col-md-12">
                                    <p class="textCenter">Conclusion</p>
                                    <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                       <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                       <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                    </span>
                                 </div>
                                 <div [formGroup]="conclusionForm">
                                    <div role="tab" id="heading-04">
                                       <h6 class="mb-0" class="table-heder"> Appeal/Writ Details </h6>
                                    </div>
                                    <div class="col-md-10">
                                       <div class="accordion accordion-solid-header">
                                          <div class="card border-bottom">
                                             <div class="row">
                                                <div class="table-responsive">
                                                   <table class="table table-bordered">
                                                      <tr class="bgGred">
                                                         <th class="w-40">Sr. No. </th>
                                                         <th style="width: 250px;">Action <span class="mandatetd"><i
                                                                  class="fa fa-asterisk"
                                                                  style="font-size: 7px; margin-left:-7px;"></i></span>
                                                         </th>
                                                         <th>Forum</th>
                                                      </tr>
                                                      <ng-container>
                                                         <tr *ngFor="let appealFromGrp of getappealDetail(); let i=index"
                                                            [formGroup]="appealFromGrp">
                                                            <td class="w-40">{{i+1}}</td>
                                                            <td>
                                                               <select class="form-control form-select" id="action"
                                                                  formControlName="action" (change)="setForumOfAppeal()"
                                                                  [title]="appealFromGrp.value.action ? appealFromGrp.value.action:''"
                                                                  [ngClass]="{errorBorder: isSubmittedAppeal && appealFromGrp.get('action')?.errors}">
                                                                  <ng-container *ngFor="let obj of actionData">
                                                                     <option [value]="obj.value">{{obj.label}}</option>
                                                                  </ng-container>
                                                               </select>
                                                               <div
                                                                  *ngIf="isSubmittedAppeal && appealFromGrp.get('action')?.errors">
                                                                  <span class="text-danger"
                                                                     *ngIf="appealFromGrp.get('action')?.errors?.required ">
                                                                     Required </span>
                                                               </div>
                                                            </td>
                                                            <td>
                                                               <select class="form-control form-select"
                                                                  formControlName="forum"
                                                                  [title]="appealFromGrp.value.forum ? appealFromGrp.value.forum:''"
                                                                  [attr.disabled]="isSelectDisabled">
                                                                  <ng-container *ngFor="let obj of forumAppeal">
                                                                     <option [value]="obj.value">{{obj.label}}</option>
                                                                  </ng-container>
                                                               </select>
                                                            </td>
                                                         </tr>
                                                      </ng-container>
                                                   </table>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-12 mt15">
                                       <button class="btn btn-outline-primary mr-1"
                                          (click)="conclusionSubmitForm(1)">Update</button>
                                    </div>
                                 </div>
                              </div>
                           </fieldset>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="tab-pane fade" id="intimationp2-1" role="tabpanel" aria-labelledby="intimationp2-tab">
         <div class="card borderOrange">
            <div class="card-body ptl10 plr010">
               <div class="row">
                  <div class="col-2" id="verticalTab4">
                     <ul class="nav nav-tabs nav-tabs-vertical" role="tablist" *ngIf="!sidemenu">
                        <li class="nav-item">
                           <a class="nav-link active" id="rectificationTab" data-toggle="tab" href="#irec-2" role="tab"
                              aria-controls="irec-2" aria-selected="true" #rectificationTab>
                              Rectification
                           </a>
                        </li>
                        <li class="nav-item" [ngClass]="{'disabled': (!tab8Disable)}">
                           <a class="nav-link" id="cc-tab-vertical" data-toggle="tab" href="#cc-2" role="tab"
                              aria-controls="cc-2" aria-selected="false" #consultantTab>
                              Consultant Charges
                           </a>
                        </li>
                        <li class="nav-item" [ngClass]="{'disabled': (action == 'view')}">
                           <a class="btn btn-outline-primary w100" (click)="updateFormPh2(2)"
                              [ngClass]="{'disabled': (action == 'view')}"> Update </a>
                        </li>
                     </ul>
                  </div>
                  <div class="col-10 border " id="tabArea4" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
                     <div class="tab-content tab-content-vertical">
                        <div class="tab-pane fade show active" id="irec-2" role="tabpanel"
                           aria-labelledby="irec-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div class="row">
                                 <div class="col-md-12">
                                    <p class="textCenter">Rectification </p>
                                    <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                       <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                       <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                    </span>
                                 </div>

                                 <form [formGroup]="rectifForm">
                                    <div formArrayName="rectifDetails">
                                       <div class="col-md-12 noPadding">
                                          <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                                             <li class="nav-item" id="rnoticeTab{{i}}"
                                                *ngFor="let div of getFormControlRectif(); let i=index"
                                                [formGroupName]="i">
                                                <a class="nav-link" id="rnotice1-tab" data-toggle="tab"
                                                   (click)="phaseRectification('phase' + i, i)"
                                                   [ngClass]="(selectedRectif === 'phase' + i) ? 'active': ''"
                                                   role="tab" aria-controls="rnotice1-1" aria-selected="true">
                                                   Rectification
                                                   {{i+1}}</a>
                                             </li>
                                          </ul>
                                          <div class="tab-content nopadding">
                                             <div class="tab-pane fade show active" id="rnotice1-1" role="tabpanel"
                                                aria-labelledby="rnotice1-tab">
                                                <div class="ml5 noPadding" id="rnoticeDiv{{i}}"
                                                   *ngFor="let rnoticediv of getFormControlRectif(); let i=index"
                                                   [formGroupName]="i">
                                                   <div *ngIf="selectedRectif === 'phase' + i">
                                                      <div class="col-md-12 nopadding">
                                                         <button class="btn btn-outline-primary mb15 "
                                                            (click)="addRectif()">Add Rectification</button>
                                                         <button class="btn btn-outline-primary mb15 "
                                                            (click)="removeRectif(i)" *ngIf="i != 0"
                                                            style="margin-left: 5px;">Remove Rectification</button>
                                                      </div>

                                                      <div class="row">
                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Document Number Under Rectification </label>
                                                               <input type="text" class="form-control" placeholder=""
                                                                  formControlName="docNo" id="docNo">
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Date of Rectification<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar class="form-control"
                                                                     formControlName="rectifDate"
                                                                     [ngClass]="{ errorBorder:isSubmittedRectif && !getFormControlRectif()[i].controls['rectifDate'].value }"
                                                                     [maxDate]="maxDate" [monthNavigator]="true"
                                                                     [yearNavigator]="true"
                                                                     yearRange="2010:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string">
                                                                  </p-calendar>
                                                               </div>
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedRectif && !getFormControlRectif()[i].controls['rectifDate'].value">
                                                               <span class="text-danger"
                                                                  *ngIf="!getFormControlRectif()[i].controls['rectifDate'].value ">
                                                                  Date of Rectification Required</span>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Acknowledgement No.<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span> </label>
                                                               <input type="text" class="form-control" placeholder=""
                                                                  formControlName="ackNo" id="ackNo"
                                                                  [ngClass]="{errorBorder: isSubmittedRectif && rnoticediv.get('ackNo')?.errors }">
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedRectif && rnoticediv.get('ackNo')?.errors">
                                                               <span class="text-danger"
                                                                  *ngIf="rnoticediv.get('ackNo')?.errors">Acknowledgement
                                                                  No Required</span>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Mode of Rectification </label>
                                                               <select class="form-control form-select"
                                                                  formControlName="rectifMode" id="rectifMode">
                                                                  <option [value]="null"> -- Select --</option>
                                                                  <ng-container *ngFor=" let obj of rectifModeOptions">
                                                                     <option [value]="obj.value"> {{ obj.value}}
                                                                     </option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Rectification Options<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <select class="form-control form-select"
                                                                  formControlName="rectifOptions" id="rectifOptions"
                                                                  [ngClass]="{errorBorder: isSubmittedRectif && rnoticediv.get('rectifOptions')?.errors}">
                                                                  <ng-container
                                                                     *ngFor=" let obj of rectificationOptions">
                                                                     <option [value]="obj.value"> {{ obj.value}}
                                                                     </option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedRectif && rnoticediv.get('rectifOptions')?.errors">
                                                               <span class="text-danger"
                                                                  *ngIf="rnoticediv.get('rectifOptions')?.errors"> Date
                                                                  of
                                                                  Rectification Required</span>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Reason for Rectification </label>
                                                               <input type="text" class="form-control" placeholder=""
                                                                  formControlName="rectifReason" id="rectifReason"
                                                                  maxlength="1000">
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Consultant Name </label>
                                                               <input type="text" class="form-control" placeholder=""
                                                                  formControlName="consultant" id="consultant">
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Person responsible <span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <select class="form-control form-select"
                                                                  [ngClass]="{errorBorder:isSubmittedRectif && rnoticediv.get('personResp')?.errors}"
                                                                  id="personResp" formControlName="personResp">
                                                                  <ng-container *ngFor="let obj of userRoleLs">
                                                                     <option [value]="obj.email">{{ obj.email }}
                                                                     </option>
                                                                  </ng-container>
                                                               </select>
                                                               <div
                                                                  *ngIf="isSubmittedRectif && rnoticediv.get('personResp')?.errors">
                                                                  <span class="text-danger"
                                                                     *ngIf="rnoticediv.get('personResp')?.errors">Person
                                                                     responsible Required</span>
                                                               </div>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-12">
                                                            <div class="form-group">
                                                               <label>Internal Remarks </label>
                                                               <textarea class="form-control" id="internalRemarks"
                                                                  rows="4" formControlName="internalRemarks"
                                                                  [ngClass]="{ errorBorder: rnoticediv.get('internalRemarks')?.touched && rnoticediv.get('internalRemarks')?.hasError('maxlength')}"></textarea>
                                                            </div>
                                                            <div class="text-danger"
                                                               *ngIf="rnoticediv.get('internalRemarks')?.touched && rnoticediv.get('internalRemarks')?.hasError('maxlength')">
                                                               Maximum of 1000 characters
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Attachment</label>
                                                               <input type="file" class="file-upload-default" #file>
                                                               <div class="attachment-block">
                                                                  <span
                                                                     (click)="openReqDocPop(rectifDetailsFormPop, i)">
                                                                     <i class="icon fa fa fa-paperclip"
                                                                        style="cursor: pointer;"
                                                                        title="Attach file"></i><span
                                                                        *ngIf="getReqDocLength(i) != 0">
                                                                        {{getReqDocLength(i)}} Attachment</span></span>
                                                               </div>
                                                               <div>
                                                                  <ng-template #rectifDetailsFormPop let-modal>
                                                                     <div class="modal-header"
                                                                        style="border: none; padding-bottom: 0px;">
                                                                        <button type="button" class="close"
                                                                           aria-label="Close"
                                                                           (click)="modal.dismiss('Cross click')"> <span
                                                                              aria-hidden="true">×</span> </button>
                                                                     </div>
                                                                     <div class="col-md-12">
                                                                        <label> Attachment</label>
                                                                        <div class="table-responsive">
                                                                           <table class="table table-bordered">
                                                                              <thead>
                                                                                 <tr class="bgGred">
                                                                                    <th>Upload</th>
                                                                                    <th style="width:50%;">Remark (Max
                                                                                       100
                                                                                       Character allowed)</th>
                                                                                 </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                                 <tr *ngFor="let rectifFromGrp of getReqDoc(rnoticediv); let j = index"
                                                                                    [formGroup]="rectifFromGrp">
                                                                                    <td>
                                                                                       <div class="row">
                                                                                          <div
                                                                                             class="col-md-9 nopadding">
                                                                                             <div class="form-group">
                                                                                                <input
                                                                                                   *ngIf="rectifFromGrp.value.isdocLocUploadedClicked"
                                                                                                   type="file"
                                                                                                   id="rectifDetails({{i}}).reqDocLoc({{j}}).fileLoc"
                                                                                                   class="filetype form-control"
                                                                                                   name="myfile"
                                                                                                   (change)="onFileSelect($event,'rectification')">
                                                                                                <p *ngIf="!rectifFromGrp.value.isdocLocUploadedClicked || rectifFromGrp.value.fileLoc"
                                                                                                   class="form-control pt10">
                                                                                                   {{getFileNameDta(rectifFromGrp.value.fileLoc)}}
                                                                                                </p>
                                                                                             </div>
                                                                                          </div>
                                                                                          <div
                                                                                             class="col-md-3 nopadding">
                                                                                             <label>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="rectifFromGrp.value.fileLoc"
                                                                                                   title="Download file"
                                                                                                   (click)="download(rectifFromGrp.value.fileLoc)"><i
                                                                                                      class="fa fa-download"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="!(rectifFromGrp.value.fileLoc || rectifFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                   title="Upload file"
                                                                                                   (click)="uploadReqDocCopy(i,j)"><i
                                                                                                      class="fa fa-upload"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   (click)="deleteReqFile(i,j)"
                                                                                                   *ngIf="(rectifFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                      class="fa fa-trash"
                                                                                                      aria-hidden="true"></i></a>
                                                                                             </label>
                                                                                          </div>
                                                                                       </div>
                                                                                    </td>
                                                                                    <td>
                                                                                       <input type="text"
                                                                                          class="form-control"
                                                                                          formControlName="remarks"
                                                                                          [title]="rectifFromGrp.value.remarks ? rectifFromGrp.value.remarks:''">
                                                                                    </td>
                                                                                 </tr>
                                                                              </tbody>
                                                                           </table>
                                                                        </div>
                                                                     </div>
                                                                     <div class="modal-footer"
                                                                        style="border: none; padding-top: 0px;">
                                                                        <button type="button"
                                                                           class="btn btn-outline-dark"
                                                                           (click)="modal.close('Save click')">Save</button>
                                                                     </div>
                                                                  </ng-template>
                                                               </div>
                                                            </div>
                                                         </div>


                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Whether Order Received ? <span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <select class="form-control form-select"
                                                                  id="rectifOrderReceived"
                                                                  formControlName="rectifOrderReceived"
                                                                  [ngClass]="{errorBorder:isSubmittedRectif && rnoticediv.get('rectifOrderReceived')?.errors}"
                                                                  (change)="openOrderDataPop((proceedWhetherOrderReceived),$event, i)">
                                                                  <ng-container *ngFor=" let obj of orderReceived">
                                                                     <option [value]="obj.value">{{obj.label}} </option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedRectif && rnoticediv.get('rectifOrderReceived')?.errors">
                                                               <span class="text-danger"
                                                                  *ngIf="rnoticediv.get('rectifOrderReceived')?.errors">Whether
                                                                  Order Received Required</span>
                                                            </div>

                                                            <ng-template #proceedWhetherOrderReceived let-modal>
                                                               <div class="modal-header">
                                                                  <h4 class="modal-title" id="modal-basic-title">
                                                                     Warning!
                                                                  </h4>
                                                                  <button type="button" class="close" aria-label="Close"
                                                                     (click)="modal.dismiss('Cross click')">
                                                                     <span aria-hidden="true">×</span>
                                                                  </button>
                                                               </div>
                                                               <div
                                                                  *ngIf="getFormControlRectif()[i].controls['rectifOrderReceived'].value == 'true' || getFormControlRectif()[i].controls['rectifOrderReceived'].value == true">
                                                                  <div class="modal-body">
                                                                     <p>Rectification Order page is added in phase 3.
                                                                        <br>

                                                                        Please update Phase 2 and move to Phase 3 to
                                                                        provide the "Rectified Order" details.
                                                                     </p>
                                                                  </div>
                                                               </div>
                                                               <div
                                                                  *ngIf="getFormControlRectif()[i].controls['rectifOrderReceived'].value == 'false' || getFormControlRectif()[i].controls['rectifOrderReceived'].value == false">
                                                                  <div class="modal-body">
                                                                     <p>Are you sure, you want to remove <b>Rectified
                                                                           Order</b> in phase 3 ?</p>
                                                                  </div>
                                                               </div>
                                                               <div class="modal-footer">
                                                                  <button type="button" class="btn btn-outline-dark"
                                                                     (click)="modal.close('Save click')">Closed</button>
                                                               </div>
                                                            </ng-template>
                                                         </div>


                                                         <div class="col-md-12">
                                                            <div class="accordion accordion-solid-header"
                                                               id="accordion-04" role="tablist">
                                                               <div class="card border-bottom">
                                                                  <div class="card-header" role="tab" id="heading-04">
                                                                     <h6 class="mb-0">
                                                                        <a data-toggle="collapse" href="#collapse-04"
                                                                           aria-expanded="true"
                                                                           aria-controls="collapse-04">
                                                                           Details of Meeting With Department
                                                                        </a>
                                                                     </h6>
                                                                  </div>
                                                                  <div id="collapse-04" class="collapse show"
                                                                     role="tabpanel" aria-labelledby="heading-04"
                                                                     data-parent="#accordion-04">
                                                                     <div class="card-body">
                                                                        <div class="row">
                                                                           <div class="table-responsive">
                                                                              <table class="table table-bordered">
                                                                                 <tr class="bgGred">
                                                                                    <th class="w-40">Sr. No.</th>
                                                                                    <th>Date of Meeting </th>
                                                                                    <th>Person Who Attended</th>
                                                                                    <th>Internal Remarks</th>
                                                                                    <th>Copy of Submissions</th>
                                                                                    <th class="w-80">Action</th>
                                                                                 </tr>
                                                                                 <tbody>
                                                                                    <ng-container>
                                                                                       <tr *ngFor="let departmentFromGrp of departmentInvArr(rnoticediv); let k=index"
                                                                                          [formGroup]="departmentFromGrp">
                                                                                          <td class="w-40">{{k+1}}</td>
                                                                                          <td>
                                                                                             <div
                                                                                                id="iris-gst-filter-frmDate"
                                                                                                class="input-group date datepicker">
                                                                                                <p-calendar
                                                                                                   appendTo="body"
                                                                                                   class="form-control"
                                                                                                   formControlName="dateOfMeeting"
                                                                                                   name="dateOfmeeting"
                                                                                                   [monthNavigator]="true"
                                                                                                   [yearNavigator]="true"
                                                                                                   yearRange="2010:{{currentYear}}"
                                                                                                   dateFormat="dd-mm-yy"
                                                                                                   [showIcon]="true"
                                                                                                   dataType="string"
                                                                                                   [maxDate]="maxDate">
                                                                                                </p-calendar>
                                                                                             </div>
                                                                                          </td>
                                                                                          <td><input type="text"
                                                                                                class="form-control"
                                                                                                id="personAttended"
                                                                                                placeholder=""
                                                                                                formControlName="personAttended">
                                                                                          </td>
                                                                                          <td><input type="text"
                                                                                                class="form-control"
                                                                                                id="issueInternalRemarks"
                                                                                                placeholder=""
                                                                                                [title]="departmentFromGrp.value.internalRemarks ? departmentFromGrp.value.internalRemarks: ''"
                                                                                                formControlName="internalRemarks">
                                                                                          </td>
                                                                                          <td><span
                                                                                                (click)="opencopyOfSubPop(copyOfSubmissionLocFormPop, i)"><i
                                                                                                   class="icon fa fa fa-paperclip"
                                                                                                   id="paperclip"
                                                                                                   title="Attach file"></i></span><span
                                                                                                *ngIf="getCopyOfSubLength(i, k) != 0">
                                                                                                {{getCopyOfSubLength(i,
                                                                                                k)}} Attachment</span>
                                                                                             <ng-template
                                                                                                #copyOfSubmissionLocFormPop
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header"
                                                                                                   style="border: none; padding-bottom: 0px;">
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="col-md-12">
                                                                                                   <label>Attachment</label>
                                                                                                   <div
                                                                                                      class="table-responsive">
                                                                                                      <table
                                                                                                         class="table table-bordered">
                                                                                                         <thead>
                                                                                                            <tr
                                                                                                               class="bgGred">
                                                                                                               <th>
                                                                                                                  Upload
                                                                                                               </th>
                                                                                                               <th
                                                                                                                  style="width:50%;">
                                                                                                                  Remark
                                                                                                                  (Max
                                                                                                                  100
                                                                                                                  Character
                                                                                                                  allowed)
                                                                                                               </th>
                                                                                                            </tr>
                                                                                                         </thead>
                                                                                                         <tbody>
                                                                                                            <tr *ngFor="let getCopyOfSubFromGrp of getCopyOfSub(departmentFromGrp); let j=index"
                                                                                                               [formGroup]="getCopyOfSubFromGrp">
                                                                                                               <td>
                                                                                                                  <div
                                                                                                                     class="row">
                                                                                                                     <div
                                                                                                                        class="col-md-9 nopadding">
                                                                                                                        <div
                                                                                                                           class="form-group">
                                                                                                                           <input
                                                                                                                              *ngIf="getCopyOfSubFromGrp.value.isdocLocUploadedClicked"
                                                                                                                              type="file"
                                                                                                                              id="rectifDetails({{i}}).meetingDetails({{k}}).copyOfSubmissionLoc({{j}}).fileLoc"
                                                                                                                              class="filetype form-control"
                                                                                                                              name="myfile"
                                                                                                                              (change)="onFileSelect($event,'rectification')">
                                                                                                                           <p *ngIf="!getCopyOfSubFromGrp.value.isdocLocUploadedClicked || getCopyOfSubFromGrp.value.fileLoc"
                                                                                                                              class="form-control pt10">
                                                                                                                              {{getFileNameDta(getCopyOfSubFromGrp.value.fileLoc)}}
                                                                                                                           </p>
                                                                                                                        </div>
                                                                                                                     </div>
                                                                                                                     <div
                                                                                                                        class="col-md-3 nopadding">
                                                                                                                        <label>
                                                                                                                           <a class="downupIcon"
                                                                                                                              *ngIf="getCopyOfSubFromGrp.value.fileLoc"
                                                                                                                              title="Download file"
                                                                                                                              (click)="download(getCopyOfSubFromGrp.value.fileLoc)"><i
                                                                                                                                 class="fa fa-download"></i></a>
                                                                                                                           <a class="downupIcon"
                                                                                                                              *ngIf="!(getCopyOfSubFromGrp.value.fileLoc || getCopyOfSubFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                                              title="Upload file"
                                                                                                                              (click)="uploadCopyOfSub(i,j,k)"><i
                                                                                                                                 class="fa fa-upload"></i></a>
                                                                                                                           <a class="downupIcon"
                                                                                                                              (click)="deleteCopyOfSubFile(i,j,k)"
                                                                                                                              *ngIf="(getCopyOfSubFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                                                 class="fa fa-trash"
                                                                                                                                 aria-hidden="true"></i></a>
                                                                                                                        </label>

                                                                                                                     </div>
                                                                                                                  </div>
                                                                                                               </td>
                                                                                                               <td>
                                                                                                                  <input
                                                                                                                     type="text"
                                                                                                                     class="form-control"
                                                                                                                     placeholder=""
                                                                                                                     id="remarks"
                                                                                                                     formControlName="remarks"
                                                                                                                     maxlength="100"
                                                                                                                     [title]="getCopyOfSubFromGrp.value.remarks ? getCopyOfSubFromGrp.value.remarks:''" />
                                                                                                               </td>
                                                                                                            </tr>
                                                                                                         </tbody>
                                                                                                      </table>
                                                                                                   </div>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer"
                                                                                                   style="border: none; padding-top: 0px;">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="modal.close('Save click')">Save</button>
                                                                                                </div>
                                                                                             </ng-template>
                                                                                          </td>
                                                                                          <td class="w-80">
                                                                                             <a class="addExtLetter"
                                                                                                (click)="addDepartmentInv(i)"><i
                                                                                                   class="fa fa-plus"
                                                                                                   title="Add row"></i>
                                                                                             </a>
                                                                                             <a *ngIf="k != 0"
                                                                                                class="addExtLetter"
                                                                                                (click)="openRmDepartmentPop(removeDepartmentPopup,i)"><i
                                                                                                   class="fa fa-minus"></i></a>
                                                                                             <ng-template
                                                                                                #removeDepartmentPopup
                                                                                                let-modal>
                                                                                                <div
                                                                                                   class="modal-header">
                                                                                                   <h4 class="modal-title"
                                                                                                      id="modal-basic-title">
                                                                                                      Remove Warning!
                                                                                                   </h4>
                                                                                                   <button type="button"
                                                                                                      class="close"
                                                                                                      aria-label="Close"
                                                                                                      (click)="modal.dismiss('Cross click')">
                                                                                                      <span
                                                                                                         aria-hidden="true">×</span>
                                                                                                   </button>
                                                                                                </div>
                                                                                                <div class="modal-body">
                                                                                                   <p>Are you sure, you
                                                                                                      want to remove?
                                                                                                   </p>
                                                                                                </div>
                                                                                                <div
                                                                                                   class="modal-footer">
                                                                                                   <button type="button"
                                                                                                      class="btn btn-outline-dark"
                                                                                                      (click)="rmDepartmentInv(i,k)">Remove</button>
                                                                                                </div>
                                                                                             </ng-template>
                                                                                          </td>
                                                                                       </tr>
                                                                                    </ng-container>
                                                                                 </tbody>

                                                                              </table>
                                                                           </div>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>

                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="row">
                                       <div class="col-md-3">
                                          <div class="form-group">
                                             <label>Whether Rights Transferred to AO? </label>
                                             <select class="form-control form-select"
                                                formControlName="isRightsTransToAO" (change)="isRightsTransToAOData()"
                                                id="isRightsTransToAO">
                                                <option [value]="null"> -- Select -- </option>
                                                <ng-container *ngFor=" let obj of transferredAO">
                                                   <option [value]="obj.value"> {{ obj.label }} </option>
                                                </ng-container>
                                             </select>
                                          </div>
                                       </div>

                                       <div class="col-md-12" *ngIf="showTable">
                                          <div class="accordion accordion-solid-header" id="accordion-04"
                                             role="tablist">
                                             <div class="card border-bottom">
                                                <div class="card-header" role="tab" id="heading-04">
                                                   <h6 class="mb-0">
                                                      <a data-toggle="collapse" href="#collapse-05" aria-expanded="true"
                                                         aria-controls="collapse-05">
                                                         Assessing Officer details
                                                      </a>
                                                   </h6>
                                                </div>
                                                <div id="collapse-05" class="collapse show" role="tabpanel"
                                                   aria-labelledby="heading-04" data-parent="#accordion-04">
                                                   <div class="card-body">
                                                      <div class="row">
                                                         <div class="table-responsive">
                                                            <table class="table table-bordered">
                                                               <tr class="bgGred">
                                                                  <th>Name of Officer</th>
                                                                  <th>Designation</th>
                                                                  <th>Jurisdiction</th>
                                                                  <th>Address</th>
                                                                  <th>Email</th>
                                                                  <th>Contact Details</th>
                                                                  <th>Action</th>
                                                               </tr>
                                                               <tr *ngFor="let div of getOfficerDetails(); let i=index"
                                                                  [formGroup]="div">
                                                                  <td><input type="text" class="form-control"
                                                                        placeholder="" formControlName="officer"></td>
                                                                  <td><input type="text" class="form-control"
                                                                        placeholder="" formControlName="designation">
                                                                  </td>
                                                                  <td><input type="text" class="form-control"
                                                                        placeholder="" formControlName="jurisdiction" />
                                                                  </td>
                                                                  <td><input type="text" class="form-control"
                                                                        placeholder="" formControlName="address"></td>
                                                                  <td><input type="text" class="form-control"
                                                                        placeholder="" formControlName="email">
                                                                     <div *ngIf="div.get('email')?.errors">
                                                                        <span class="text-danger"
                                                                           *ngIf="div.get('email')?.errors?.pattern">
                                                                           Please Enter Valid Email ID</span>
                                                                     </div>
                                                                  </td>
                                                                  <td><input type="text" class="form-control"
                                                                        placeholder="" formControlName="contact"
                                                                        maxlength="12"
                                                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                  </td>
                                                                  <td>
                                                                     <a class="addExtLetter"
                                                                        (click)="addOfficerDetails() "><i
                                                                           class="fa fa-plus"></i></a>
                                                                     <a class="addExtLetter" *ngIf="i != 0"
                                                                        (click)="openOfficerPop(rmofficerDetailsPop)"><i
                                                                           class="fa fa-minus"></i></a>
                                                                  </td>
                                                                  <ng-template #rmofficerDetailsPop let-modal>
                                                                     <div class="modal-header">
                                                                        <h4 class="modal-title" id="modal-basic-title">
                                                                           Remove Warning! </h4>
                                                                        <button type="button" class="close"
                                                                           aria-label="Close"
                                                                           (click)="modal.dismiss('Cross click')">
                                                                           <span aria-hidden="true">×</span>
                                                                        </button>
                                                                     </div>
                                                                     <div class="modal-body">
                                                                        <p>Are you sure, you want to remove?</p>
                                                                     </div>
                                                                     <div class="modal-footer">
                                                                        <button type="button"
                                                                           class="btn btn-outline-dark"
                                                                           (click)="removeOfficerDetails(i)">Remove</button>
                                                                     </div>
                                                                  </ng-template>
                                                               </tr>
                                                            </table>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="col-md-12 mt15 mb-3">
                                          <button class="btn btn-outline-primary mr-1"
                                             (click)="rectificationSubmit()">Next</button>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </fieldset>
                        </div>

                        <div class="tab-pane fade" id="cc-2" role="tabpanel" aria-labelledby="cc-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div class="col-md-12 nopadding">
                                 <p class="textCenter">Consultant Charges</p>
                                 <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                    <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                    <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                 </span>
                              </div>
                              <form [formGroup]="consultantForm">
                                 <div formArrayName="cc">
                                    <div class="col-md-12 nopadding">
                                       <div class="row">
                                          <div class="col-md-12 table-responsive">
                                             <table class="search-table inner">
                                                <tr class="bgGred">
                                                   <th class=" w-40">Sr.No.</th>
                                                   <th>Name of Consulting Firm/ Legal Counsel</th>
                                                   <th>Name of the Consultant Responsible</th>
                                                   <th>Nature of Work</th>
                                                   <th>Fees Charged (₹)</th>
                                                   <th class="w-300">Supporting Document</th>
                                                   <th>Email id</th>
                                                   <th>Contact Number</th>
                                                   <th class="w-80"> Action </th>
                                                </tr>
                                                <ng-container>
                                                   <tr *ngFor="let div of consultantInvArr() let i=index;"
                                                      [formGroup]="div">
                                                      <td class="w-40">{{i+1}}</td>
                                                      <td><input type="text" class="form-control" id="tax"
                                                            placeholder="" formControlName="firm"
                                                            [title]="div.value.firm ? div.value.firm:''"
                                                            [ngClass]="{ 'errorBorder': isSubmittedConsultant && div.get('firm')?.errors}">
                                                         <div *ngIf="isSubmittedConsultant && div.get('firm')?.errors">
                                                            <span class="text-danger"
                                                               *ngIf="div.get('firm')?.errors?.required"> Name of
                                                               Consulting Firm Required</span>
                                                         </div>
                                                      </td>
                                                      <td><input type="text" class="form-control" id="consultant"
                                                            placeholder="" formControlName="consultant"
                                                            [title]="div.value.consultant ? div.value.consultant:''">
                                                      </td>
                                                      <td><input type="text" class="form-control" id="natureOfWork"
                                                            placeholder="" formControlName="natureOfWork"
                                                            [title]="div.value.natureOfWork ? div.value.natureOfWork:''">
                                                      </td>
                                                      <td><input type="text" class="form-control align-right" id="fees"
                                                            placeholder="" formControlName="fees"
                                                            (input)="feesChargedChanged()" maxlength="19" InrFormat
                                                            (paste)="handlePaste($event)"></td>
                                                      <td><span (click)="openCCDetailPop(consultantFormPop, i)"><i
                                                               class="icon fa fa fa-paperclip" style="cursor: pointer;"
                                                               title="Attach file"></i><span
                                                               *ngIf="getConsultantLength(i) != 0">{{getConsultantLength(i)}}
                                                               Attachment</span></span></td>
                                                      <td><input type="text" class="form-control" placeholder=""
                                                            formControlName="email">
                                                         <div *ngIf="div.get('email')?.errors">
                                                            <span class="text-danger"
                                                               *ngIf="div.get('email')?.errors?.pattern"> Please Enter
                                                               Valid Email ID</span>
                                                         </div>
                                                      </td>
                                                      <td><input class="form-control" id="contact"
                                                            formControlName="contact"
                                                            [title]="div.value.contact ? div.value.contact:''"
                                                            maxlength="12"
                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" />
                                                      </td>
                                                      <td class="w-80">
                                                         <a class=" addExtLetter" (click)="addConsultantInv()"><i
                                                               class="fa fa-plus" title="Add row"></i></a>
                                                         <a class=" addExtLetter" *ngIf="i != 0"
                                                            (click)="openRmvconsultantPop(removeConsultantPopup)"><i
                                                               class="fa fa-minus"></i></a>
                                                         <ng-template #removeConsultantPopup let-modal>
                                                            <div class="modal-header">
                                                               <h4 class="modal-title" id="modal-basic-title">Remove
                                                                  Warning!</h4>
                                                               <button type="button" class="close" aria-label="Close"
                                                                  (click)="modal.dismiss('Cross click')">
                                                                  <span aria-hidden="true">×</span>
                                                               </button>
                                                            </div>
                                                            <div class="modal-body">
                                                               <p>Are you sure, you want to remove?</p>
                                                            </div>
                                                            <div class="modal-footer">
                                                               <button type="button" class="btn btn-outline-dark"
                                                                  (click)="rmConsultantInv(i)">Remove</button>
                                                            </div>
                                                         </ng-template>
                                                      </td>
                                                      <ng-template #consultantFormPop let-modal>
                                                         <div class="modal-header"
                                                            style="border: none; padding-bottom: 0px;">
                                                            <button type="button" class="close" aria-label="Close"
                                                               (click)="modal.dismiss('Cross click')"> <span
                                                                  aria-hidden="true">×</span> </button>
                                                         </div>
                                                         <div class="col-md-12"> <label>Attachment</label>
                                                            <div class="table-responsive">
                                                               <table class="table table-bordered">
                                                                  <thead>
                                                                     <tr class="bgGred">
                                                                        <th>Upload </th>
                                                                        <th style="width:50%;">Remark (Max 100 Character
                                                                           allowed) </th>
                                                                     </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                     <tr *ngFor="let consultantFromGrp of getConsultant(div); let j=index"
                                                                        [formGroup]="consultantFromGrp">
                                                                        <td>
                                                                           <div class="row">
                                                                              <div class="col-md-9 nopadding">
                                                                                 <div class="form-group">
                                                                                    <input
                                                                                       *ngIf="consultantFromGrp.value.isdocLocUploadedClicked"
                                                                                       type="file"
                                                                                       id="cc({{i}}).supportingDocsLoc({{j}}).fileLoc"
                                                                                       class="filetype form-control"
                                                                                       name="myfile"
                                                                                       (change)="onFileSelect($event,'consultant')">
                                                                                    <p *ngIf="!consultantFromGrp.value.isdocLocUploadedClicked || consultantFromGrp.value.fileLoc"
                                                                                       class="form-control pt10">
                                                                                       {{getFileNameDta(consultantFromGrp.value.fileLoc)}}
                                                                                    </p>
                                                                                 </div>
                                                                              </div>
                                                                              <div class="col-md-3 nopadding">
                                                                                 <label>
                                                                                    <a class="downupIcon"
                                                                                       *ngIf="consultantFromGrp.value.fileLoc"
                                                                                       title="Download file"
                                                                                       (click)="download(consultantFromGrp.value.fileLoc)"><i
                                                                                          class="fa fa-download"></i></a>
                                                                                    <a class="downupIcon"
                                                                                       *ngIf="!(consultantFromGrp.value.fileLoc || consultantFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                       title="Upload file"
                                                                                       (click)="uploadConsultant(i,j)"><i
                                                                                          class="fa fa-upload"></i></a>
                                                                                    <a class="downupIcon"
                                                                                       (click)="deleteConsultant(i,j)"
                                                                                       *ngIf="(consultantFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                          class="fa fa-trash"
                                                                                          aria-hidden="true"></i></a>
                                                                                 </label>

                                                                              </div>
                                                                           </div>
                                                                        </td>
                                                                        <td> <input type="text" class="form-control"
                                                                              placeholder="" id="remarks"
                                                                              formControlName="remarks"
                                                                              [title]="consultantFromGrp.value.remarks ? consultantFromGrp.value.remarks:''"
                                                                              maxlength="100" />
                                                                        </td>
                                                                     </tr>
                                                                  </tbody>
                                                               </table>
                                                            </div>
                                                         </div>
                                                         <div class="modal-footer"
                                                            style="border: none; padding-top: 0px;">
                                                            <button type="button" class="btn btn-outline-dark"
                                                               (click)="modal.close('Save click')">Save</button>
                                                         </div>
                                                      </ng-template>
                                                   </tr>
                                                   <tr>
                                                      <th colspan="4" style="text-align: center; background: white;">
                                                         Total
                                                      </th>
                                                      <td class="align-right bold-font">{{feesChargedTotal | INR : 0}}
                                                      </td>
                                                   </tr>
                                                </ng-container>
                                             </table>
                                          </div>
                                       </div>
                                    </div>
                                    <div class="col-md-12 noPadding mt15 mb-3">
                                       <button class="btn btn-outline-primary mr-1"
                                          (click)="updateFormPh2(2)">Update</button>
                                    </div>
                                 </div>
                              </form>
                           </fieldset>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="tab-pane fade" id="intimationp3-1" role="tabpanel" aria-labelledby="intimationp3-tab">
         <div class="card borderOrange">
            <div class="card-body ptl10 plr010">
               <div class="row">
                  <div class="col-2" id="verticalTab5">
                     <ul class="nav nav-tabs nav-tabs-vertical" role="tablist" *ngIf="!sidemenu">
                        <li class="nav-item">
                           <a class="nav-link active" id="iord-tab-vertical" data-toggle="tab" href="#iord-2" role="tab"
                              aria-controls="iord-2" aria-selected="true" #orderTab> Order
                           </a>
                        </li>
                        <li class="nav-item">
                           <a class="nav-link" id="ictd-tab-vertical" data-toggle="tab" href="#ictd-2" role="tab"
                              aria-controls="ictd-2" aria-selected="false" #cumulativeTab> Cumulative Payment Details
                           </a>
                        </li>
                        <li class="nav-item" [ngClass]="{'disabled': (action == 'view')}">
                           <a class="btn btn-outline-primary w100" (click)="updateFormPh3(3)"
                              [ngClass]="{'disabled': (action == 'view')}"> Update </a>
                        </li>
                     </ul>
                  </div>
                  <div class="col-10 border " id="tabArea5" [ngClass]="[sidemenu ? 'col-12' : 'col-10']">
                     <div class="tab-content tab-content-vertical">
                        <div class="tab-pane fade show active" id="iord-2" role="tabpanel"
                           aria-labelledby="iord-tab-vertical" [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div class="row">
                                 <div class="col-md-12">
                                    <p class="textCenter">Order </p>
                                    <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                       <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                       <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                    </span>
                                 </div>
                                 <form [formGroup]="orderForm">
                                    <div formArrayName="rectifDetails1">
                                       <div class="col-md-12">
                                          <ul class="nav nav-pills iris-gst-display-flex " role="tablist">
                                             <li class="nav-item" id="rOrderTab{{i}}"
                                                *ngFor="let div of getOrderData(); let i=index" [formGroupName]="i">
                                                <a class="nav-link" id="rOrder1-tab" data-toggle="tab" role="tab"
                                                   (click)="phaseOrder('phase' + i, i)"
                                                   [ngClass]="(selectedOrder === 'phase' + i) ? 'active': ''"
                                                   aria-controls="rOrder1-1" aria-selected="true"> Rectification Order
                                                   {{i+1}}</a>
                                             </li>
                                          </ul>
                                          <div class="tab-content nopadding">
                                             <div class="tab-pane fade show active" id="rOrder1-1" role="tabpanel"
                                                aria-labelledby="rOrder1-tab">
                                                <div class="col-md-12 noPadding" id="rOrdereDiv{{i}}"
                                                   *ngFor="let rOrderdiv of getOrderData(); let i=index"
                                                   [formGroupName]="i">
                                                   <div *ngIf="selectedOrder === 'phase' + i" class="show-tab-content">
                                                      <div class="row">
                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Order Ref. Number/DIN<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <input type="text" class="form-control" placeholder=""
                                                                  formControlName="orderRefNo" id="orderRefNo"
                                                                  [ngClass]="{errorBorder: isSubmittedOrder && !getOrderData()[i].controls['orderRefNo'].value}">
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedOrder && !getOrderData()[i].controls['orderRefNo'].value">
                                                               <span class="text-danger"
                                                                  *ngIf="!getOrderData()[i].controls['orderRefNo'].value ">Order
                                                                  Ref. Number/DIN Required</span>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Date of Order<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar class="form-control"
                                                                     formControlName="orderDate" id="orderDate"
                                                                     [maxDate]="maxDate" [monthNavigator]="true"
                                                                     [yearNavigator]="true"
                                                                     yearRange="2010:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string"
                                                                     [ngClass]="{errorBorder: isSubmittedOrder && !getOrderData()[i].controls['orderDate'].value}"></p-calendar>
                                                               </div>
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedOrder && !getOrderData()[i].controls['orderDate'].value">
                                                               <span class="text-danger"
                                                                  *ngIf="!getOrderData()[i].controls['orderDate'].value ">Date
                                                                  of Order Required</span>
                                                            </div>
                                                         </div>


                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Date of Receipt of Order<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <div id="iris-gst-filter-frmDate"
                                                                  class="input-group date datepicker">
                                                                  <p-calendar class="form-control"
                                                                     formControlName="orderRecepitDate"
                                                                     id="orderRecepitDate" [maxDate]="maxDate"
                                                                     [monthNavigator]="true" [yearNavigator]="true"
                                                                     yearRange="2010:{{currentYear}}"
                                                                     dateFormat="dd-mm-yy" [showIcon]="true"
                                                                     dataType="string"
                                                                     [ngClass]="{errorBorder: isSubmittedOrder && !getOrderData()[i].controls['orderRecepitDate'].value}"></p-calendar>
                                                               </div>
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedOrder && !getOrderData()[i].controls['orderRecepitDate'].value">
                                                               <span class="text-danger"
                                                                  *ngIf="!getOrderData()[i].controls['orderRecepitDate'].value ">Date
                                                                  of Receipt of Order Required</span>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Mode of Receipt of Order </label>
                                                               <select class="form-control form-select"
                                                                  formControlName="orderReceiptMode"
                                                                  id="orderReceiptMode">
                                                                  <option [value]="null"> -- Select --</option>
                                                                  <ng-container *ngFor=" let obj of rectifModeOptions">
                                                                     <option [value]="obj.value"> {{ obj.value}}
                                                                     </option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Copy of Order<span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span> </label>
                                                               <input type="file" class="file-upload-default" #file>
                                                               <div class="attachment-block"
                                                                  [ngClass]="{'errorBorder': isSubmittedOrder &&  getOrderLocength(i) === 0 }">
                                                                  <span (click)="openOrderGrpPop(orderFormPop, i)"> <i
                                                                        class="icon fa fa fa-paperclip"
                                                                        style="cursor: pointer;"
                                                                        title="Attach file"></i><span
                                                                        *ngIf="getOrderLocength(i) != 0">
                                                                        {{getOrderLocength(i)}} Attachment</span></span>
                                                               </div>
                                                               <div
                                                                  *ngIf="isSubmittedOrder && getOrderLocength(i) == 0">
                                                                  <span class="text-danger">Copy of Order
                                                                     Required</span>
                                                               </div>
                                                               <div>
                                                                  <ng-template #orderFormPop let-modal>
                                                                     <div class="modal-header"
                                                                        style="border: none; padding-bottom: 0px;">
                                                                        <button type="button" class="close"
                                                                           aria-label="Close"
                                                                           (click)="modal.dismiss('Cross click')"> <span
                                                                              aria-hidden="true">×</span> </button>
                                                                     </div>
                                                                     <div class="col-md-12">
                                                                        <label> Attachment</label>
                                                                        <div class="table-responsive">
                                                                           <table class="table table-bordered">
                                                                              <thead>
                                                                                 <tr class="bgGred">
                                                                                    <th>Upload</th>
                                                                                    <th style="width:50%;">Remark (Max
                                                                                       100
                                                                                       Character allowed)</th>
                                                                                 </tr>
                                                                              </thead>
                                                                              <tbody>
                                                                                 <tr *ngFor="let orderFromGrp of getOrderLoc(rOrderdiv); let j=index"
                                                                                    [formGroup]="orderFromGrp">
                                                                                    <td>
                                                                                       <div class="row">
                                                                                          <div
                                                                                             class="col-md-9 nopadding">
                                                                                             <div class="form-group">
                                                                                                <input
                                                                                                   *ngIf="orderFromGrp.value.isdocLocUploadedClicked"
                                                                                                   type="file"
                                                                                                   id="rectifDetails1({{i}}).orderLoc({{j}}).fileLoc"
                                                                                                   class="filetype form-control"
                                                                                                   name="myfile"
                                                                                                   (change)="onFileSelect($event,'orderData')">
                                                                                                <p *ngIf="!orderFromGrp.value.isdocLocUploadedClicked || orderFromGrp.value.fileLoc"
                                                                                                   class="form-control pt10">
                                                                                                   {{getFileNameDta(orderFromGrp.value.fileLoc)}}
                                                                                                </p>
                                                                                             </div>
                                                                                          </div>
                                                                                          <div
                                                                                             class="col-md-3 nopadding">
                                                                                             <label>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="orderFromGrp.value.fileLoc"
                                                                                                   title="Download file"
                                                                                                   (click)="download(orderFromGrp.value.fileLoc)"><i
                                                                                                      class="fa fa-download"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   *ngIf="!(orderFromGrp.value.fileLoc || orderFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"
                                                                                                   title="Upload file"
                                                                                                   (click)="uploadOrderLoc(i,j)"><i
                                                                                                      class="fa fa-upload"></i></a>
                                                                                                <a class="downupIcon"
                                                                                                   (click)="deleteOrderLocFile(i,j)"
                                                                                                   *ngIf="(orderFromGrp.value.fileLoc) && !['DT_Viewer'].includes(roleName)"><i
                                                                                                      class="fa fa-trash"
                                                                                                      aria-hidden="true"></i></a>
                                                                                             </label>

                                                                                          </div>
                                                                                       </div>
                                                                                    </td>
                                                                                    <td> <input type="text"
                                                                                          class="form-control"
                                                                                          placeholder="" id="remarks"
                                                                                          formControlName="remarks"
                                                                                          [title]="orderFromGrp.value.remarks ? orderFromGrp.value.remarks:''"
                                                                                          maxlength="100" />
                                                                                    </td>
                                                                                 </tr>
                                                                              </tbody>
                                                                           </table>
                                                                        </div>
                                                                     </div>
                                                                     <div class="modal-footer"
                                                                        style="border: none; padding-top: 0px;">
                                                                        <button type="button"
                                                                           class="btn btn-outline-dark"
                                                                           (click)="modal.close('Save click')">Save</button>
                                                                     </div>
                                                                  </ng-template>
                                                               </div>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Order Passed By</label>
                                                               <select class="form-control form-select"
                                                                  id="orderPassedBy" formControlName="orderPassedBy">
                                                                  <option [value]="null"> -- Select --</option>
                                                                  <ng-container *ngFor=" let obj of orderPassedBy">
                                                                     <option [value]="obj.value"> {{ obj.value}}
                                                                     </option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Outcome <span class="mandate"><i
                                                                        class="fa fa-asterisk"></i></span></label>
                                                               <select class="form-control form-select" id="outcome"
                                                                  formControlName="outcome"
                                                                  [ngClass]="{errorBorder: isSubmittedOrder && !getOrderData()[i].controls['outcome'].value}">
                                                                  <option [value]="null"> -- Select --</option>
                                                                  <ng-container *ngFor=" let obj of outcomeData">
                                                                     <option [value]="obj.value"> {{ obj.value}}
                                                                     </option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                            <div
                                                               *ngIf="isSubmittedOrder && !getOrderData()[i].controls['outcome'].value">
                                                               <span class="text-danger"
                                                                  *ngIf="!getOrderData()[i].controls['outcome'].value ">Outcome
                                                                  Required</span>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-3">
                                                            <div class="form-group">
                                                               <label>Whether Re rectification Applied </label>
                                                               <select class="form-control form-select"
                                                                  id="isAppliedForReRectif"
                                                                  formControlName="isAppliedForReRectif">
                                                                  <option [value]="null">-- Select --</option>
                                                                  <ng-container
                                                                     *ngFor="let obj of whetherRectification">
                                                                     <option [value]="obj.value">{{obj.label}}</option>
                                                                  </ng-container>
                                                               </select>
                                                            </div>
                                                         </div>

                                                         <div class="col-md-12">
                                                            <div class="form-group">
                                                               <label>Order Summary </label>
                                                               <textarea class="form-control" id="orderSummary"
                                                                  formControlName="orderSummary" rows="4"
                                                                  [ngClass]="{ errorBorder: rOrderdiv.controls.orderSummary.hasError('maxlength')}">
                                                   </textarea>
                                                            </div>
                                                            <div class="text-danger"
                                                               *ngIf="rOrderdiv.get('orderSummary')?.touched && rOrderdiv.get('orderSummary')?.hasError('maxlength')">
                                                               Maximum of 1000 characters
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-md-10">
                                       <div class="accordion accordion-solid-header">
                                          <div role="tab" id="heading-04">
                                             <h6 class="mb-0" class="table-heder"> Appeal/Writ Details </h6>
                                          </div>
                                          <div class="col-md-10">
                                             <div class="accordion accordion-solid-header">
                                                <div class="card border-bottom">
                                                   <div>
                                                      <div>
                                                         <div class="row">
                                                            <div class="table-responsive">
                                                               <table class="table table-bordered">
                                                                  <tr class="bgGred">
                                                                     <th class="w-40">Sr. No. </th>
                                                                     <th style="width: 250px;">Action <span
                                                                           class="mandatetd"><i class="fa fa-asterisk"
                                                                              style="font-size: 7px; margin-left:-7px;"></i></span>
                                                                     </th>
                                                                     <th>Forum</th>
                                                                  </tr>
                                                                  <ng-container>
                                                                     <tr *ngFor="let appeal3FromGrp of getappealDetail3(); let i=index"
                                                                        [formGroup]="appeal3FromGrp">
                                                                        <td class="w-40">{{i+1}}</td>
                                                                        <td>
                                                                           <select class="form-control form-select"
                                                                              id="action" formControlName="action"
                                                                              (change)="setForumOfAppeal3()"
                                                                              [title]="appeal3FromGrp.value.action ? appeal3FromGrp.value.action:''"
                                                                              [ngClass]="{errorBorder: isSubmittedAppeal && appeal3FromGrp.get('action')?.errors?.required}">
                                                                              <ng-container
                                                                                 *ngFor="let obj of action3Data">
                                                                                 <option [value]="obj.value">
                                                                                    {{obj.label}}
                                                                                 </option>
                                                                              </ng-container>
                                                                           </select>
                                                                           <div
                                                                              *ngIf="isSubmittedAppeal && appeal3FromGrp.get('action')?.errors">
                                                                              <span class="text-danger"
                                                                                 *ngIf="appeal3FromGrp.get('action')?.errors?.required ">
                                                                                 Required </span>
                                                                           </div>
                                                                        </td>
                                                                        <td>
                                                                           <select class="form-control form-select"
                                                                              formControlName="forum"
                                                                              [title]="appeal3FromGrp.value.forum ? appeal3FromGrp.value.forum:''"
                                                                              [attr.disabled]="isSelectDisabled">
                                                                              <ng-container
                                                                                 *ngFor="let obj of forumAppeal">
                                                                                 <option [value]="obj.value">
                                                                                    {{obj.label}}
                                                                                 </option>
                                                                              </ng-container>
                                                                           </select>
                                                                        </td>
                                                                     </tr>
                                                                  </ng-container>
                                                               </table>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="col-md-12" *ngIf="showTable1">
                                       <div class="accordion accordion-solid-header" id="accordion-12" role="tablist">
                                          <div class="card border-bottom">
                                             <div class="card-header" role="tab" id="heading-02">
                                                <h6 class="mb-0">
                                                   <a class="bg-transparent text-dark main p-0" data-toggle="collapse"
                                                      href="#collapse-02" aria-expanded="true"
                                                      aria-controls="collapse-2">
                                                      Order Details
                                                   </a>
                                                </h6>
                                             </div>
                                             <div id="collapse-02" class="collapse show" role="tabpanel"
                                                aria-labelledby="heading-02" data-parent="#accordion-02">
                                                <div class="card-body">
                                                   <div class=" row">
                                                      <div class="col-md-12">
                                                         <div class="search-table-outter wrapper">
                                                            <table class="search-table inner ">
                                                               <thead>
                                                                  <tr class="bgGred">
                                                                     <th style="min-width: 288px;">Issues<span
                                                                           class="mandatetd"><i class="fa fa-asterisk"
                                                                              style="font-size: 7px;"></i></span></th>
                                                                     <th>Keyword (Max 150 Character)</th>
                                                                     <th>Issue Wise Conclusion <span
                                                                           class="mandatetd"><i class="fa fa-asterisk"
                                                                              style="font-size: 7px;"></i></span></th>
                                                                     <th>Addition of Income/ Disallowance of Expenses
                                                                        (₹)
                                                                     </th>
                                                                     <th>Tax Rate (Inclusive of Surcharge and Cess)
                                                                     </th>
                                                                     <th>Tax Impact (Inclusive of Surcharge and Cess)
                                                                        (₹)
                                                                     </th>
                                                                     <th>Interest (₹) </th>
                                                                     <th>Penalty (₹) </th>
                                                                     <th>Total (₹)</th>
                                                                     <th>Remark </th>
                                                                     <th class="w-80">Action</th>
                                                                  </tr>

                                                               </thead>
                                                               <tbody>
                                                                  <ng-container>
                                                                     <tr *ngFor="let div of issuInvArr(); let i=index"
                                                                        [formGroup]="div"
                                                                        (keydown.enter)="$event.preventDefault()">
                                                                        <td class="w250">
                                                                           <select class="form-control form-select"
                                                                              formControlName="issue"
                                                                              [title]="div.value.issue ? div.value.issue:''"
                                                                              [ngClass]="{ 'errorBorder': isSubmittedOrder && div.get('issue')?.errors  }">
                                                                              <optgroup *ngFor='let grp of dataSource'
                                                                                 label="{{grp.group}}">
                                                                                 <option *ngFor='let item of grp.items'
                                                                                    [value]="item.name"
                                                                                    title="{{item.name}}">{{item.name}}
                                                                                 </option>
                                                                              </optgroup>
                                                                           </select>
                                                                           <div
                                                                              *ngIf="isSubmittedOrder && div.get('issue')?.errors">
                                                                              <span class="text-danger"
                                                                                 *ngIf="div.get('issue')?.errors?.required ">Issues
                                                                                 Required
                                                                              </span>
                                                                           </div>
                                                                        </td>

                                                                        <td><input type="text" class="form-control"
                                                                              id="tax" placeholder=""
                                                                              formControlName="keyword"
                                                                              [title]="div.value.keyword ? div.value.keyword:''">
                                                                           <div
                                                                              *ngIf="(div.controls.keyword.value &&  div.controls.keyword.value.length > 150)">
                                                                              <span class="text-danger"
                                                                                 *ngIf="(div.controls.keyword.value &&  div.controls.keyword.value.length > 150)">Max
                                                                                 Length 150 character
                                                                              </span>
                                                                           </div>
                                                                        </td>

                                                                        <td>
                                                                           <select class="form-control form-select"
                                                                              id="status" formControlName="status"
                                                                              [title]="div.value.status ? div.value.status:''">
                                                                              <ng-container
                                                                                 *ngFor="let obj of issueConclusion">
                                                                                 <option [value]="obj.value">
                                                                                    {{obj.value}}
                                                                                 </option>
                                                                              </ng-container>
                                                                           </select>
                                                                           <div
                                                                              *ngIf="isSubmittedOrder && div.get('status')?.errors">
                                                                              <span class="text-danger"
                                                                                 *ngIf="div.get('status')?.errors?.required ">Issue
                                                                                 Wise Conclusion Required
                                                                              </span>
                                                                           </div>
                                                                        </td>

                                                                        <td> <input type="text"
                                                                              class="form-control align-right "
                                                                              placeholder=""
                                                                              formControlName="addnOrDisOfExpensesL"
                                                                              maxlength="19" InrFormat
                                                                              (input)="issueValueChange()"
                                                                              (paste)="handlePaste($event)"
                                                                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                        </td>

                                                                        <td>
                                                                           <div class="input-container">
                                                                              <input type="text"
                                                                                 class="form-control input-field align-right"
                                                                                 placeholder=""
                                                                                 formControlName="taxRateL"
                                                                                 id="taxRateL" maxlength="19"
                                                                                 (blur)="handleDecimalPaste(i)"
                                                                                 (input)="issueValueChange();"
                                                                                 oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                              <i class="icon"
                                                                                 style="position: absolute; transform: translateY(-50%);">%</i>
                                                                           </div>
                                                                           <div *ngIf="div.get('taxRateL')?.errors">
                                                                              <span class="text-danger"
                                                                                 *ngIf="div.get('taxRateL')?.errors?.pattern">
                                                                                 Please Enter 0 to 100</span>
                                                                           </div>
                                                                        </td>
                                                                        <td> <input type="text"
                                                                              class="form-control align-right "
                                                                              placeholder=""
                                                                              formControlName="taxImpactL"
                                                                              maxlength="19" InrFormat
                                                                              (input)="issueValueChange()"
                                                                              (paste)="handlePaste($event)"
                                                                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                        </td>
                                                                        <td> <input type="text"
                                                                              class="form-control align-right "
                                                                              placeholder="" formControlName="interestL"
                                                                              maxlength="19" InrFormat
                                                                              (input)="issueValueChange()"
                                                                              (paste)="handlePaste($event)"
                                                                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                        </td>
                                                                        <td> <input type="text"
                                                                              class="form-control align-right "
                                                                              placeholder="" formControlName="penaltyL"
                                                                              maxlength="19" InrFormat
                                                                              (input)="issueValueChange()"
                                                                              (paste)="handlePaste($event)"
                                                                              oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
                                                                        </td>
                                                                        <td> <input type="text"
                                                                              class="form-control align-right "
                                                                              placeholder="" readonly
                                                                              formControlName="totalL" maxlength="19"
                                                                              InrFormat (input)="issueValueChange()">
                                                                        </td>
                                                                        <td> <input type="text" class="form-control"
                                                                              placeholder="" formControlName="remarkL">
                                                                           <div
                                                                              *ngIf="div.get('remarkL')?.value && div.get('remarkL')?.value.length > 100">
                                                                              <span class="text-danger"
                                                                                 *ngIf="div.get('remarkL')?.value && div.get('remarkL')?.value.length > 100">Max
                                                                                 Length is 100 character
                                                                              </span>
                                                                           </div>
                                                                        </td>
                                                                        <td class="w-80">

                                                                           <a class="removeDiv" *ngIf="i!= 0"
                                                                              data-toggle="modal"
                                                                              data-target="#removeIssuePopup"
                                                                              (click)="openRmvIssuePop(removeIssuePopup, i)"
                                                                              style="margin-left: 15%;"><i
                                                                                 class="fa fa-minus"></i></a>
                                                                        </td>
                                                                        <ng-template #removeIssuePopup let-modal>
                                                                           <div class="modal-header">
                                                                              <h4 class="modal-title"
                                                                                 id="modal-basic-title">Remove Warning!
                                                                              </h4>
                                                                              <button type="button" class="close"
                                                                                 aria-label="Close"
                                                                                 (click)="modal.dismiss('Cross click')">
                                                                                 <span aria-hidden="true">×</span>
                                                                              </button>
                                                                           </div>
                                                                           <div class="modal-body">
                                                                              <p>Are you sure, you want to remove?</p>
                                                                           </div>
                                                                           <div class="modal-footer">
                                                                              <button type="button"
                                                                                 class="btn btn-outline-dark"
                                                                                 (click)="rmIssueInv(i)">Remove</button>
                                                                           </div>
                                                                        </ng-template>
                                                                     </tr>
                                                                  </ng-container>
                                                                  <ng-container>
                                                                     <tr>
                                                                        <th colspan="3" class="total-titel">Total</th>
                                                                        <td><input type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="addnOrDisOfExpensesLTotal"
                                                                              id="tax" placeholder="" InrFormat
                                                                              maxlength="19" readonly></td>
                                                                        <td colspan="1">&nbsp;</td>
                                                                        <td><input type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="taxImpactLTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <td><input type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="interestLTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <td><input type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="penaltyLTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <td><input type="text"
                                                                              class="form-control bold-font align-right"
                                                                              formControlName="totalLTotal" id="tax"
                                                                              placeholder="" InrFormat maxlength="19"
                                                                              readonly></td>
                                                                        <td colspan="9">&nbsp;</td>
                                                                     </tr>
                                                                  </ng-container>
                                                               </tbody>

                                                            </table>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>

                                    <div class="row">
                                       <div class="col-md-3">
                                          <div class="form-group">
                                             <label>Demand Raised/ Refund Reduced As Per order </label>
                                             <select class="form-control form-select"
                                                formControlName="isDemandRaisedOrReduced3">
                                                <option [value]="null"> -- Select --</option>
                                                <ng-container *ngFor=" let obj of OrReduced1">
                                                   <option [value]="obj.value"> {{ obj.label}} </option>
                                                </ng-container>
                                             </select>
                                          </div>
                                       </div>

                                       <div class="col-md-4">
                                          <div class="form-group">
                                             <label>Demand Raised/Refund Reduced Amount</label>
                                             <input type="text" class="form-control" id="tax"
                                                formControlName="demandAmt3" InrFormat style="width: 80%;"
                                                [ngClass]="{ errorBorder:orderForm.controls.isDemandRaisedOrReduced3.value =='true'&& (isSubmitted && !orderForm.controls.demandAmt3.value)}">
                                          </div>
                                          <div
                                             *ngIf="orderForm.controls.isDemandRaisedOrReduced3.value =='true'&& (isSubmitted && !orderForm.controls.demandAmt3.value)">
                                             <span class="text-danger">Demand Raised / Refund Reduced amount
                                                Required</span>
                                          </div>
                                       </div>

                                       <!-- <div class="col-md-3">
                                       <div class="form-group">
                                          <label >Demand Raised/ Refund Reduced Amount</label>
                                          <input type="text" class="form-control" id="tax" formControlName="demandAmt3" InrFormat>
                                       </div>
                                       <div class="error-msg" *ngIf="orderForm.controls.isDemandRaisedOrReduced3.value =='true' && ((orderForm.controls.isDemandRaisedOrReduced3.value === null) || (orderForm.controls.isDemandRaisedOrReduced3.value == 0))">Demand Raised/ Refund Reduced Amount Required</div>
                                    </div>  -->
                                    </div>

                                    <div class="col-md-12 mt15 mb-3">
                                       <button class="btn btn-outline-primary mr-1"
                                          (click)="orderSubmit()">Next</button>
                                    </div>
                                 </form>
                              </div>
                           </fieldset>
                        </div>

                        <div class="tab-pane fade" id="ictd-2" role="tabpanel" aria-labelledby="ictd-tab-vertical"
                           [ngClass]="{'bgGray': (action == 'view')}">
                           <fieldset [disabled]="action == 'view'">
                              <div class="col-md-12 nopadding">
                                 <p class="textCenter">Cumulative Payment Details</p>
                                 <span class="toggleTab" (click)="sidemenu=!sidemenu">
                                    <i class="mdi mdi mdi-chevron-double-left leftTIcon" *ngIf="!sidemenu"></i>
                                    <i class="mdi mdi-chevron-double-right rightTIcon" *ngIf="sidemenu"></i>
                                 </span>
                              </div>
                              <form>
                                 <div class="col-md-12">
                                    <div class="row">
                                       <div class="col-md-12 table-responsive">
                                          <table class="table table-bordered">
                                             <tr class="bgGred">
                                                <th>Particulars</th>
                                                <th>Tax(₹)</th>
                                                <th>Interest (₹) </th>
                                                <th>Penalty (₹) </th>
                                                <th>Total (₹) </th>
                                             </tr>
                                             <tr *ngFor="let c of cumulativeData">
                                                <td>{{c.label}}</td>
                                                <td class="align-right">{{c.tax | INR :0 }}</td>
                                                <td class="align-right">{{ c.interest | INR :0 }}</td>
                                                <td class="align-right">{{ c.penalty | INR :0 }}</td>
                                                <td class="align-right">{{ c.total | INR :0 }}</td>
                                             </tr>
                                          </table>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="col-md-12 noPadding mt15">
                                    <button class="btn btn-outline-primary mr-1"
                                       (click)="updateFormPh3(3)">Submit</button>
                                 </div>
                              </form>
                           </fieldset>
                        </div>

                     </div>
                  </div>
               </div>

            </div>
         </div>
      </div>
   </div>
</div>
