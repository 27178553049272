<!-- partial -->
<div class="container-fluid page-body-wrapper">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <!-- <div class="col-sm-12">
          <div class="fileInfo  d-flex">
            <div class="d-flex flex-column">
              <i class="case-icon fa fa-bullhorn fa-2x iconsDisign "></i>
            </div>
            <div class="">
              <p>You can check for the Acitivity logs <span class="fileinfolink">Settings > Activity Log</span> <span
                  class="newsBlink">New</span></p>
              <p>Notice Management <span class="fileinfolink">Notice Management > Direct Tax</span><span
                  class="newsBlink">New</span></p>
            </div>
          </div>
        </div> -->
        <div class="col-md-12">
          <div class="container">
            <div class="row py-2 my-2">
              <div class="col-sm-3">
                <div class="cardBorder container d-flex zoom-container">
                  <div class="d-flex flex-column">
                    <i class="case-icon fa fa-legal fa-2x iconsDisign"></i>
                  </div>
                  <div class="container-body">
                    <h5 class="container-title">GST</h5>
                    <p class="card-text">
                      Open Case:
                      <!-- <app-insight-loader *ngIf="dataLoaded" [width]="'200%'"></app-insight-loader>  -->
                      <b
                        ><span class="text-count" (click)="litigationSummary()">{{
                          dashboardCount
                        }}</span></b
                      >
                    </p>
                    <p class="card-text">
                      Demand:
                      <!-- <app-insight-loader *ngIf="dataLoaded" [width]="'800%'"></app-insight-loader> -->
                      <b
                        ><span class="text-total" (click)="dashboardPage()"
                          >{{ totalAmmounts?.totOfTotal | INR : 2 }} Lakhs</span
                        ></b
                      >
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-sm-3">
                <div
                  style="border-top-color: #007057"
                  class="cardBorder container d-flex zoom-container"
                >
                  <div class="d-flex">
                    <i class="case-icon mdi mdi-file-chart fa-2x iconsDisign"></i>
                  </div>
                  <div class="container-body">
                    <h5 class="container-title">GST AUDIT</h5>
                    <p class="card-text">
                      Open Case:
                      <!-- <app-insight-loader *ngIf="dataLoaded" [width]="'200%'"></app-insight-loader> -->
                      <b
                        ><span class="text-count" (click)="auditSummary()">{{
                          auditcntOPEN
                        }}</span></b
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div
                  style="border-top-color: #007057"
                  class="cardBorder container d-flex zoom-container"
                >
                  <div class="d-flex zoom-container">
                    <i
                      class="case-icon mdi mdi-arrange-bring-forward fa-2x iconsDisign"
                    ></i>
                  </div>
                  <div class="container-body">
                    <h5 class="container-title">PRE GST</h5>
                    <p
                      class="card-text"
                      [style]="{ display: value === 'FILING' ? 'none' : 'd-flex' }"
                    >
                      Open Case:
                      <!-- <app-insight-loader *ngIf="dataLoaded" [width]="'200%'"></app-insight-loader> -->
                      <b
                        ><span class="text-count" (click)="preGstSummary()">{{
                          pregstcount
                        }}</span></b
                      >
                    </p>
                    <p
                      class="card-text"
                      [style]="{ display: value === 'FILING' ? 'none' : 'd-flex' }"
                    >
                      Demand:
                      <!-- <app-insight-loader *ngIf="dataLoaded" [width]="'800%'"></app-insight-loader> -->
                      <b
                        ><span class="text-total" (click)="dashboardRedirect()"
                          >{{ preGstTotal?.totOfTotal | INR : 2 }} Lakhs</span
                        ></b
                      >
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-sm-3">
                <div
                  style="border-top-color: #007057"
                  class="cardBorder container d-flex zoom-container"
                >
                  <div class="d-flex">
                    <i
                      class="fa fa-leanpub"
                      style="font-size: 22px; margin-top: 17px"
                    ></i>
                  </div>
                  <div class="container-body">
                    <h5 class="container-title" style="margin-left: 18px">DIRECT TAX</h5>
                    <p
                      class="card-text"
                      [style]="{ display: value === 'FILING' ? 'none' : 'd-flex' }"
                    >
                      Open Case:
                      <b
                        ><span class="text-count" (click)="dtummary()">{{
                          dtCount
                        }}</span></b
                      >
                    </p>
                    <p
                      class="card-text"
                      [style]="{ display: value === 'FILING' ? 'none' : 'd-flex' }"
                    >
                      Demand:
                      <b
                        ><span class="text-total" (click)="dashboardDtRedirect()"
                          >{{ dtTotal?.totOfTotal | INR : 2 }} Lakhs</span
                        ></b
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid page-body-wrapper" style="margin-top: -9px">
        <div class="main-panel">
          <div class="content-wrapper">
            <div class="row">
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-4">
                        <div class="grid-margin stretch-card">
                          <div
                            class="card"
                            style="
                              margin-left: -1.625rem;
                              width: 110%;
                              box-shadow: 1px 2px 4px #ccd3e0;
                            "
                          >
                            <div class="row">
                              <div class="col-md-12 stretch-card">
                                <div class="card-body pb0 h500">
                                  <div class="card-titles fs1">
                                    Letter/SCN reply due date
                                    <ul id="menu" class="filter-header">
                                      <span>
                                        <i
                                          class="fa fa-filter clrOrange notifyIcon"
                                          [ngClass]="[
                                            activeLink === 'filter'
                                              ? 'nav-item active'
                                              : 'nav-item'
                                          ]"
                                        ></i>
                                        <ul class="menus list">
                                          <a
                                            style="margin-top: 7px"
                                            (click)="gstDueDates()"
                                          >
                                            <label for="gst">
                                              <input
                                                type="radio"
                                                id="gst"
                                                name="gst1"
                                                [(ngModel)]="isGstSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span class="radio-option" for="gst"
                                                >GST Due Date</span
                                              >
                                            </label>
                                          </a>
                                          <a (click)="preGstDueDates()">
                                            <label for="pregst">
                                              <input
                                                type="radio"
                                                id="pregst"
                                                name="pregst1"
                                                [(ngModel)]="isPreGstSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span class="radio-option" for="pregst"
                                                >PRE GST Due Date</span
                                              >
                                            </label>
                                          </a>
                                          <a (click)="dtDueDates()">
                                            <label for="dt">
                                              <input
                                                type="radio"
                                                id="dt"
                                                name="dt1"
                                                [(ngModel)]="isDtSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span class="radio-option" for="dt"
                                                >DT Due Date</span
                                              >
                                            </label>
                                          </a>
                                          <a (click)="gstAndPregstDueDates()">
                                            <label for="all">
                                              <input
                                                type="radio"
                                                id="all"
                                                name="all1"
                                                [(ngModel)]="isAllSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span for="all" class="radio-option"
                                                >All
                                              </span>
                                            </label>
                                          </a>
                                        </ul>
                                      </span>
                                    </ul>
                                    <select
                                      class="form-control dashDropdown form-select"
                                      id="gstin1"
                                      (change)="gstDueDate()"
                                      [(ngModel)]="dueDays"
                                    >
                                      <ng-container *ngFor="let obj of dueDateSpan">
                                        <option [value]="obj.value">{{ obj.key }}</option>
                                      </ng-container>
                                    </select>
                                  </div>

                                  <div class="accordion" id="accordion1" role="tablist">
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-1_1"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            data-toggle="collapse"
                                            href="#collapse-1_1"
                                            aria-ex
                                            panded="true"
                                            aria-controls="collapse-1_1"
                                            >Audit Intimation Letter
                                            <span *ngIf="auditData" [hidden]="HideDueDate"
                                              >({{ auditData.ail.length }})</span
                                            >
                                            <span
                                              *ngIf="auditData"
                                              [hidden]="!HideDueDate"
                                              >(0)</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-1_1"
                                        class="collapse show"
                                        role="tabpanel"
                                        aria-labelledby="heading-1_1"
                                        data-parent="#accordion1"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody
                                                *ngIf="auditData"
                                                [hidden]="HideDueDate"
                                              >
                                                <tr *ngFor="let item of auditData.ail">
                                                  <td>
                                                    <a
                                                      (click)="
                                                        getAuditCaseData(
                                                          item.caseId,
                                                          'update',
                                                          'ail',
                                                          'tab1'
                                                        )
                                                      "
                                                      >{{ item.caseId }}</a
                                                    >
                                                  </td>
                                                  <td
                                                    [ngClass]="{
                                                      oldDatesClr: item.oldDatesClr
                                                    }"
                                                  >
                                                    {{ item.dueDate }}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-1_2"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            data-toggle="collapse"
                                            href="#collapse-1_2"
                                            aria-expanded="false"
                                            aria-controls="collapse-1_2"
                                            >Audit Findings Letter
                                            <span *ngIf="auditData" [hidden]="HideDueDate"
                                              >({{ auditData.afl.length }})</span
                                            ><span
                                              *ngIf="auditData"
                                              [hidden]="!HideDueDate"
                                              >(0)</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-1_2"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-1_2"
                                        data-parent="#accordion1"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody
                                                *ngIf="auditData"
                                                [hidden]="HideDueDate"
                                              >
                                                <tr *ngFor="let item of auditData.afl">
                                                  <td>
                                                    <a
                                                      (click)="
                                                        getAuditCaseData(
                                                          item.caseId,
                                                          'update',
                                                          'afl',
                                                          'tab2'
                                                        )
                                                      "
                                                      >{{ item.caseId }}</a
                                                    >
                                                  </td>
                                                  <td
                                                    [ngClass]="{
                                                      oldDatesClr: item.oldDatesClr
                                                    }"
                                                  >
                                                    {{ item.dueDate }}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-1"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            data-toggle="collapse"
                                            href="#collapse-1"
                                            aria-expanded="false"
                                            aria-controls="collapse-1"
                                            >Pre litigation/Enq Pro
                                            <span
                                              *ngIf="viewDataScn && !isAllSelected"  [hidden]="hideDueDt" >({{ viewDataScn.length }})</span
                                            ><span
                                              *ngIf="viewDataScn && isAllSelected"
                                              [hidden]="hideDueDt"
                                              >({{ viewDataScn.length }})</span
                                            >
                                            <span
                                              *ngIf="viewDataScn"
                                              [hidden]="!hideDueDt"
                                              >(0)</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-1"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-1"
                                        data-parent="#accordion1"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody
                                                *ngIf="viewDataScn"
                                                [hidden]="hideDueDt"
                                              >
                                                <ng-container
                                                  *ngFor="let item of viewDataScn"
                                                >
                                                  <tr *ngIf="['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','DT_Viewer'].includes(role)">
                                                    <td *ngIf="item.type == 1"><a
                                                        (click)="getCaseData(item.caseId, 'view', 'scn', 'tab1')">{{item.caseId}}</a>
                                                    </td>
                                                    <td *ngIf="item.type == 3"><a
                                                        (click)="getDtCaseData(item.caseId, 'view','dtenqproc', 'tab1')">{{item.caseId}}</a>
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'scn',
                                                            'tab1'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dtenqproc',
                                                            'tab1'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-2"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            data-toggle="collapse"
                                            href="#collapse-2"
                                            aria-expanded="false"
                                            aria-controls="collapse-2"
                                          >
                                            Adjn/Assmt
                                            <span *ngIf="!isAllSelected && viewDataAja">
                                              ({{ viewDataAja.length }})</span
                                            >
                                            <span *ngIf="isAllSelected && viewDataAja"
                                              >({{ viewDataAja.length }})</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-2"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-2"
                                        data-parent="#accordion1"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody *ngIf="viewDataAja">
                                                <ng-container
                                                  *ngFor="let item of viewDataAja"
                                                >
                                                  <tr *ngIf="['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','DT_Viewer'].includes(role)">
                                                    <td *ngIf="item.type == 1"><a
                                                        (click)="getCaseData(item.caseId, 'view', 'aja', 'tab2')">{{item.caseId}}</a>
                                                    </td>
                                                    <td *ngIf="item.type == 2"><a
                                                        (click)="getPreGstCaseData(item.caseId, 'view', 'preaja', 'tab1')">{{item.caseId}}</a>
                                                    </td>
                                                    <td *ngIf="item.type == 3"><a
                                                        (click)="getDtCaseData(item.caseId, 'view', 'dtassessment', 'tab3')">{{item.caseId}}</a>
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'aja',
                                                            'tab2'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'preaja',
                                                            'tab1'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dtassessment',
                                                            'tab3'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="grid-margin stretch-card">
                          <div
                            class="card"
                            style="margin-left: 0.375rem; box-shadow: 1px 2px 4px #ccd3e0"
                          >
                            <div class="row">
                              <div class="col-md-12 stretch-card">
                                <div class="card-body pb0 h500">
                                  <div class="card-title fs1">
                                    Appeal filing due date
                                    <ul id="menu" class="filter-header">
                                      <span>
                                        <i
                                          class="fa fa-filter clrOrange notifyIcon"
                                          [ngClass]="[
                                            activeLink === 'filter'
                                              ? 'nav-item active'
                                              : 'nav-item'
                                          ]"
                                        ></i>
                                        <ul class="menus list">
                                          <a
                                            style="margin-top: 7px"
                                            (click)="gstDueDates()"
                                          >
                                            <label for="gst2">
                                              <input
                                                type="radio"
                                                id="gst2"
                                                name="gst2"
                                                [(ngModel)]="isGstSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span class="radio-option" for="gst2"
                                                >GST Due Date</span
                                              >
                                            </label>
                                          </a>
                                          <a (click)="preGstDueDates()">
                                            <label for="pregst2">
                                              <input
                                                type="radio"
                                                id="pregst2"
                                                name="pregst2"
                                                [(ngModel)]="isPreGstSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span class="radio-option" for="pregst2"
                                                >PRE GST Due Date</span
                                              >
                                            </label>
                                          </a>
                                          <a (click)="dtDueDates()">
                                            <label for="dt2">
                                              <input
                                                type="radio"
                                                id="dt2"
                                                name="dt2"
                                                [(ngModel)]="isDtSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span class="radio-option" for="dt2"
                                                >DT Due Date</span
                                              >
                                            </label>
                                          </a>
                                          <a (click)="gstAndPregstDueDates()">
                                            <label for="all2">
                                              <input
                                                type="radio"
                                                id="all2"
                                                name="all2"
                                                [(ngModel)]="isAllSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span for="all2" class="radio-option"
                                                >All
                                              </span>
                                            </label>
                                          </a>
                                        </ul>
                                      </span>
                                    </ul>
                                    <select
                                      class="form-control dashDropdown form-select"
                                      id="gstin2"
                                      (change)="gstDueDate()"
                                      [(ngModel)]="dueDays"
                                    >
                                      <ng-container *ngFor="let obj of dueDateSpan">
                                        <option [value]="obj.value">{{ obj.key }}</option>
                                      </ng-container>
                                    </select>
                                  </div>
                                  <div class="accordion" id="accordion2" role="tablist">
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-3"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            class="collapsed"
                                            data-toggle="collapse"
                                            href="#collapse-3"
                                            aria-expanded="true"
                                            aria-controls="collapse-3"
                                          >
                                            Commissioner (A)
                                            <span *ngIf="!isAllSelected && viewDataApa"
                                              >({{ viewDataApa.length }})</span
                                            ><span *ngIf="isAllSelected && viewDataApa"
                                              >({{ viewDataApa.length }})</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-3"
                                        class="collapse show"
                                        role="tabpanel"
                                        aria-labelledby="heading-3"
                                        data-parent="#accordion2"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody *ngIf="viewDataApa">
                                                <ng-container
                                                  *ngFor="let item of viewDataApa"
                                                >
                                                  <tr *ngIf="['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','DT_Viewer'].includes(role)">
                                                    <td *ngIf="item.type == 1"><a
                                                        (click)="getCaseData(item.caseId, 'view', 'apa', 'tab3')">{{item.caseId}}</a>
                                                    </td>
                                                    <td *ngIf="item.type == 2"><a
                                                        (click)="getPreGstCaseData(item.caseId, 'view', 'preapa', 'tab2')">{{item.caseId}}</a>
                                                    </td>
                                                    <td *ngIf="item.type == 3"><a
                                                        (click)="getDtCaseData(item.caseId, 'view', 'dtcommissioner', 'tab4')">{{item.caseId}}</a>
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'apa',
                                                            'tab3'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'preapa',
                                                            'tab2'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dtcommissioner',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-4"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            class="collapsed"
                                            data-toggle="collapse"
                                            href="#collapse-4"
                                            aria-expanded="false"
                                            aria-controls="collapse-4"
                                          >
                                            Appellate Tribunal
                                            <span *ngIf="!isAllSelected && viewDataApt"
                                              >({{ viewDataApt.length }})</span
                                            ><span *ngIf="isAllSelected && viewDataApt"
                                              >({{ viewDataApt.length }})</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-4"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-4"
                                        data-parent="#accordion2"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody *ngIf="viewDataApt">
                                                <ng-container
                                                  *ngFor="let item of viewDataApt"
                                                >
                                                  <tr *ngIf="['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','DT_Viewer'].includes(role)">
                                                    <td *ngIf="item.type == 1"><a
                                                        (click)="getCaseData(item.caseId, 'view', 'apt', 'tab4')">{{item.caseId}}</a>
                                                    </td>
                                                    <td *ngIf="item.type == 2"><a
                                                        (click)="getPreGstCaseData(item.caseId, 'view', 'preapt', 'tab3')">{{item.caseId}}</a>
                                                    </td>
                                                    <td *ngIf="item.type == 3"><a
                                                        (click)="getDtCaseData(item.caseId, 'view', 'dtitat', 'tab5')">{{item.caseId}}</a>
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>

                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'apt',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'preapt',
                                                            'tab3'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dtitat',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-5"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            class="collapsed"
                                            data-toggle="collapse"
                                            href="#collapse-5"
                                            aria-expanded="false"
                                            aria-controls="collapse-5"
                                          >
                                            High Court
                                            <span *ngIf="!isAllSelected && viewDataHc">
                                              ({{ viewDataHc.length }})</span
                                            ><span *ngIf="isAllSelected && viewDataHc"
                                              >({{ viewDataHc.length }})</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-5"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-5"
                                        data-parent="#accordion2"
                                      >
                                        <div class="card-body" style="overflow-y: scroll">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody *ngIf="viewDataHc">
                                                <ng-container
                                                  *ngFor="let item of viewDataHc"
                                                >
                                                  <tr
                                                    *ngIf="
                                                      [
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'hc',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'prehc',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'dthc',
                                                            'tab6'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'hc',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'prehc',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dthc',
                                                            'tab6'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-6"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            class="collapsed"
                                            data-toggle="collapse"
                                            href="#collapse-6"
                                            aria-expanded="false"
                                            aria-controls="collapse-6"
                                          >
                                            Supreme Court
                                            <span *ngIf="!isAllSelected && viewDataSc"
                                              >({{ viewDataSc.length }})</span
                                            ><span *ngIf="isAllSelected && viewDataSc"
                                              >({{ viewDataSc.length }})</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-6"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-6"
                                        data-parent="#accordion2"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody *ngIf="viewDataSc">
                                                <ng-container
                                                  *ngFor="let item of viewDataSc"
                                                >
                                                  <tr
                                                    *ngIf="
                                                      [
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'sc',
                                                            'tab6'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'presc',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'dtsc',
                                                            'tab7'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'sc',
                                                            'tab6'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'presc',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dtsc',
                                                            'tab7'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="grid-margin stretch-card">
                          <div
                            class="card"
                            style="margin-left: 13px; box-shadow: 1px 2px 4px #ccd3e0"
                          >
                            <div class="row">
                              <div class="col-md-12 stretch-card">
                                <div class="card-body pb0 h500">
                                  <div class="card-title fs1">
                                    Hearing due date
                                    <ul id="menu" class="filter-header">
                                      <span>
                                        <i
                                          class="fa fa-filter clrOrange notifyIcon"
                                          [ngClass]="[
                                            activeLink === 'filter'
                                              ? 'nav-item active'
                                              : 'nav-item'
                                          ]"
                                        ></i>
                                        <ul class="menus list">
                                          <a
                                            style="margin-top: 7px"
                                            (click)="gstDueDates()"
                                          >
                                            <label for="gst1">
                                              <input
                                                type="radio"
                                                id="gst1"
                                                name="gst3"
                                                [(ngModel)]="isGstSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span class="radio-option" for="gst1"
                                                >GST Due Date</span
                                              >
                                            </label>
                                          </a>
                                          <a (click)="preGstDueDates()">
                                            <label for="pregst1">
                                              <input
                                                type="radio"
                                                id="pregst1"
                                                name="pregst3"
                                                [(ngModel)]="isPreGstSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span class="radio-option" for="pregst1"
                                                >PRE GST Due Date</span
                                              >
                                            </label>
                                          </a>
                                          <a (click)="dtDueDates()">
                                            <label for="dt1">
                                              <input
                                                type="radio"
                                                id="dt1"
                                                name="dt3"
                                                [(ngModel)]="isDtSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span class="radio-option" for="dt1"
                                                >DT Due Date</span
                                              >
                                            </label>
                                          </a>
                                          <a (click)="gstAndPregstDueDates()">
                                            <label for="all1">
                                              <input
                                                type="radio"
                                                id="all1"
                                                name="all3"
                                                [(ngModel)]="isAllSelected"
                                                [value]="true"
                                                class="radio-icon"
                                              />
                                              <span for="all1" class="radio-option"
                                                >All
                                              </span>
                                            </label>
                                          </a>
                                        </ul>
                                      </span>
                                    </ul>
                                    <select
                                      class="form-control dashDropdown form-select"
                                      id="gstin"
                                      (change)="gstHearingDate()"
                                      [(ngModel)]="dueDays"
                                    >
                                      <ng-container *ngFor="let obj of dueDateSpan">
                                        <option [value]="obj.value">{{ obj.key }}</option>
                                      </ng-container>
                                    </select>
                                  </div>
                                  <div class="accordion" id="accordion3" role="tablist">
                                    <div class="card border-bottom">
                                      <div class="card-header nopadding" role="tab" id="heading-6">
                                      <h6 class="mb-0">
                                        <a  class="collapsed"  data-toggle="collapse" href="#collapse-12" aria-expanded="true" aria-controls="collapse-12">
                                          Pre litigation
                                          <span *ngIf="hearingDataScn && !isAllSelected"> ({{ hearingDataScn.length }})</span>
                                          <span *ngIf="hearingDataScn && isAllSelected">({{ hearingDataScn.length }})</span>
                                        </a>
                                      </h6>
                                    </div>
                                    <div id="collapse-12"  class="collapse show"  role="tabpanel" aria-labelledby="heading-6"
                                      data-parent="#accordion3">
                                      <div class="card-body">
                                        <div class="table-responsive">
                                          <table class="table table-bordered">
                                            <thead>
                                              <tr>
                                                <th class="header-data">Case ID</th>
                                                <th class="header-data">Due Date</th>
                                              </tr>
                                            </thead>
                                            <tbody *ngIf="hearingDataScn">
                                              <ng-container
                                                *ngFor="let item of hearingDataScn">
                                                <tr
                                                  *ngIf="['GST_Viewer', 'PREGST_Viewer','Viewer', 'IDT_Viewer','DT_Viewer' ].includes(role)">
                                                  <td *ngIf="item.type == 1">
                                                    <a (click)=" getCaseData(item.caseId,'view', 'scn','tab1')">{{ item.caseId }}</a>
                                                  </td>                                         
                                                  <td [ngClass]="{oldDatesClr: item.oldDatesClr}"> {{ item.dueDate }}</td>
                                                </tr>
                                                <tr  *ngIf="!['GST_Viewer','PREGST_Viewer','Viewer','IDT_Viewer','DT_Viewer' ].includes(role)">
                                                 <td *ngIf="item.type == 1"><a
                                                    (click)="getCaseData(item.caseId, 'update', 'scn', 'tab1')">{{item.caseId}}</a>
                                                </td>
                                                  <td [ngClass]="{ oldDatesClr: item.oldDatesClr }"> {{ item.dueDate }}</td>
                                                </tr>
                                              </ng-container>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>


                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-7"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            class="collapsed"
                                            data-toggle="collapse"
                                            href="#collapse-7"
                                            aria-expanded="true"
                                            aria-controls="collapse-7"
                                          >
                                            Adjn/Assmt/Enq Pro
                                            <span
                                              *ngIf="hearingDataAja && !isAllSelected"
                                            >
                                              ({{ hearingDataAja.length }})</span
                                            ><span *ngIf="hearingDataAja && isAllSelected"
                                              >({{ hearingDataAja.length }})</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-7"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-7"
                                        data-parent="#accordion3"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody *ngIf="hearingDataAja">
                                                <ng-container
                                                  *ngFor="let item of hearingDataAja"
                                                >
                                                  <tr
                                                    *ngIf="
                                                      [
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'aja',
                                                            'tab2'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'preaja',
                                                            'tab1'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'dtassessment',
                                                            'tab3'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'aja',
                                                            'tab2'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'preaja',
                                                            'tab1'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dtassessment',
                                                            'tab3'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                                <!-- <ng-container  *ngFor="let item of hearingDtAjaData">
                                                  <tr>
                                                    <td *ngIf="item.type == 1"><a (click)="getCaseData(item.caseId, 'update', 'aja', 'tab2')">{{item.caseId}}</a> </td>
                                                    <td *ngIf="item.type == 2"><a (click)="getPreGstCaseData(item.caseId, 'update', 'preaja', 'tab1')">{{item.caseId}}</a> </td>
                                                    <td *ngIf="item.type == 3"><a (click)="getDtCaseData(item.caseId, 'update', 'dtassessment', 'tab3')">{{item.caseId}}</a> </td>
                                                    <td [ngClass]="{'oldDatesClr': item.oldDatesClr}">{{item.dueDate}}</td>
                                                  </tr>
                                                </ng-container>
                                                  <ng-container  *ngFor="let item of hearingDtEnqData">
                                                  <tr>
                                                    <td *ngIf="item.type == 1"><a (click)="getCaseData(item.caseId, 'update', 'aja', 'tab2')">{{item.caseId}}</a> </td>
                                                    <td *ngIf="item.type == 2"><a (click)="getPreGstCaseData(item.caseId, 'update', 'preaja', 'tab1')">{{item.caseId}}</a> </td>
                                                    <td *ngIf="item.type == 3"><a (click)="getDtCaseData(item.caseId, 'update', 'dtassessment', 'tab3')">{{item.caseId}}</a> </td>
                                                    <td [ngClass]="{'oldDatesClr': item.oldDatesClr}">{{item.dueDate}}</td>
                                                  </tr>
                                                </ng-container> -->
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-8"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            class="collapsed"
                                            data-toggle="collapse"
                                            href="#collapse-8"
                                            aria-expanded="false"
                                            aria-controls="collapse-8"
                                          >
                                            Commissioner (A)
                                            <span *ngIf="hearingDataApa && !isAllSelected"
                                              >({{ hearingDataApa.length }})</span
                                            ><span *ngIf="hearingDataApa && isAllSelected"
                                              >({{ hearingDataApa.length }})</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-8"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-8"
                                        data-parent="#accordion3"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody *ngIf="hearingDataApa">
                                                <ng-container
                                                  *ngFor="let item of hearingDataApa"
                                                >
                                                  <tr
                                                    *ngIf="
                                                      [
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'apa',
                                                            'tab3'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'preapa',
                                                            'tab2'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'dtcommissioner',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'apa',
                                                            'tab3'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'preapa',
                                                            'tab2'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dtcommissioner',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                                <!-- <ng-container  *ngFor="let item of hearingDtApaData">
                                                  <tr>
                                                    <td *ngIf="item.type == 1"><a (click)="getCaseData(item.caseId, 'update', 'apa', 'tab3')">{{item.caseId}}</a> </td>
                                                    <td *ngIf="item.type == 2"><a (click)="getPreGstCaseData(item.caseId, 'update', 'preapa', 'tab2')">{{item.caseId}}</a> </td>
                                                    <td *ngIf="item.type == 3"><a (click)="getDtCaseData(item.caseId, 'update', 'dtcommissioner', 'tab4')">{{item.caseId}}</a> </td>
                                                    <td [ngClass]="{'oldDatesClr': item.oldDatesClr}">{{item.dueDate}}</td>
                                                  </tr>
                                                </ng-container> -->
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-9"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            class="collapsed"
                                            data-toggle="collapse"
                                            href="#collapse-9"
                                            aria-expanded="false"
                                            aria-controls="collapse-9"
                                          >
                                            Appellate Tribunal<span
                                              *ngIf="hearingDataApt && !isAllSelected"
                                              >({{ hearingDataApt.length }})</span
                                            ><span *ngIf="hearingDataApt && isAllSelected"
                                              >({{ hearingDataApt.length }})</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-9"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-9"
                                        data-parent="#accordion3"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody *ngIf="hearingDataApt">
                                                <ng-container
                                                  *ngFor="let item of hearingDataApt"
                                                >
                                                  <tr
                                                    *ngIf="
                                                      [
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'apt',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'preapt',
                                                            'tab3'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'dtitat',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'apt',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'preapt',
                                                            'tab3'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dtitat',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                                <!-- <ng-container  *ngFor="let item of hearingDtAptData">
                                                  <tr>
                                                    <td *ngIf="item.type == 1"><a (click)="getCaseData(item.caseId, 'update', 'apt', 'tab4')">{{item.caseId}}</a> </td>
                                                    <td *ngIf="item.type == 2"><a (click)="getPreGstCaseData(item.caseId, 'update', 'preapt', 'tab3')">{{item.caseId}}</a> </td>
                                                    <td *ngIf="item.type == 3"><a (click)="getDtCaseData(item.caseId, 'update', 'dtitat', 'tab5')">22{{item.caseId}}</a> </td>
                                                    <td [ngClass]="{'oldDatesClr': item.oldDatesClr}">{{item.dueDate}}</td>
                                                  </tr>
                                                </ng-container> -->
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card border-bottom">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-10"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            class="collapsed"
                                            data-toggle="collapse"
                                            href="#collapse-10"
                                            aria-expanded="false"
                                            aria-controls="collapse-10"
                                          >
                                            High Court
                                            <span *ngIf="hearingDataHc && !isAllSelected"
                                              >({{ hearingDataHc.length }})</span
                                            ><span *ngIf="hearingDataHc && isAllSelected"
                                              >({{ hearingDataHc.length }})</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-10"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-10"
                                        data-parent="#accordion3"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody *ngIf="hearingDataHc">
                                                <ng-container
                                                  *ngFor="let item of hearingDataHc"
                                                >
                                                  <tr
                                                    *ngIf="
                                                      [
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'hc',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'prehc',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'dthc',
                                                            'tab6'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'hc',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'prehc',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dthc',
                                                            'tab6'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                                <!-- <ng-container *ngFor="let item of hearingDtHcData">
                                                  <tr>
                                                    <td *ngIf="item.type == 1"><a (click)="getCaseData(item.caseId, 'update', 'hc', 'tab5')">{{item.caseId}}</a> </td>
                                                    <td *ngIf="item.type == 2"><a (click)="getPreGstCaseData(item.caseId, 'update', 'prehc', 'tab4')">{{item.caseId}}</a> </td>
                                                    <td *ngIf="item.type == 3"><a (click)="getDtCaseData(item.caseId, 'update', 'dthc', 'tab6')">{{item.caseId}}</a> </td>
                                                    <td [ngClass]="{'oldDatesClr': item.oldDatesClr}">{{item.dueDate}}</td>
                                                  </tr>
                                                </ng-container> -->
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="card border-bottom" id="card-title">
                                      <div
                                        class="card-header nopadding"
                                        role="tab"
                                        id="heading-11"
                                      >
                                        <h6 class="mb-0">
                                          <a
                                            class="collapsed"
                                            data-toggle="collapse"
                                            href="#collapse-11"
                                            aria-expanded="false"
                                            aria-controls="collapse-11"
                                          >
                                            Supreme Court
                                            <span *ngIf="hearingDataSc && !isAllSelected"
                                              >({{ hearingDataSc.length }})</span
                                            ><span *ngIf="hearingDataSc && isAllSelected"
                                              >({{ hearingDataSc.length }})</span
                                            >
                                          </a>
                                        </h6>
                                      </div>
                                      <div
                                        id="collapse-11"
                                        class="collapse"
                                        role="tabpanel"
                                        aria-labelledby="heading-11"
                                        data-parent="#accordion3"
                                      >
                                        <div class="card-body">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <thead>
                                                <tr>
                                                  <th class="header-data">Case ID</th>
                                                  <th class="header-data">Due Date</th>
                                                </tr>
                                              </thead>
                                              <tbody *ngIf="hearingDataSc">
                                                <ng-container
                                                  *ngFor="let item of hearingDataSc"
                                                >
                                                  <tr
                                                    *ngIf="
                                                      [
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'hc',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'prehc',
                                                            'tab4'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'view',
                                                            'dthc',
                                                            'tab6'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                  <tr
                                                    *ngIf="
                                                      ![
                                                        'GST_Viewer',
                                                        'PREGST_Viewer',
                                                        'Viewer',
                                                        'IDT_Viewer',
                                                        'DT_Viewer'
                                                      ].includes(role)
                                                    "
                                                  >
                                                    <td *ngIf="item.type == 1">
                                                      <a
                                                        (click)="
                                                          getCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'sc',
                                                            'tab6'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 2">
                                                      <a
                                                        (click)="
                                                          getPreGstCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'presc',
                                                            'tab5'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td *ngIf="item.type == 3">
                                                      <a
                                                        (click)="
                                                          getDtCaseData(
                                                            item.caseId,
                                                            'update',
                                                            'dtsc',
                                                            'tab7'
                                                          )
                                                        "
                                                        >{{ item.caseId }}</a
                                                      >
                                                    </td>
                                                    <td
                                                      [ngClass]="{
                                                        oldDatesClr: item.oldDatesClr
                                                      }"
                                                    >
                                                      {{ item.dueDate }}
                                                    </td>
                                                  </tr>
                                                </ng-container>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="grid-margin stretch-card"
                      style="margin-right: -13px; margin-left: -27px"
                    >
                      <div class="card">
                        <div class="row">
                          <div class="col-md-12 stretch-card">
                            <div class="card-body">
                              <div>
                                <span class="card-title">Statistics</span>

                                <span class="">
                                  <label class="switch">
                                    <input
                                      type="checkbox"
                                      id="togBtn"
                                      [(ngModel)]="selectedIRN"
                                      (click)="changeStatsValue(selectedIRN)"
                                    />
                                    <div class="slider round"></div>
                                  </label>
                                </span>
                                <span class="statstoggleLbl">Lakh/ Crore</span>
                              </div>
                              <div class="pt-15">
                                <ul
                                  class="nav nav-pills iris-gst-display-flex"
                                  role="tablist"
                                >
                                  <li class="nav-item">
                                    <a
                                      class="nav-link active"
                                      [ngClass]="{ active: isGstStats }"
                                      (click)="statsTabClick('gst')"
                                      id="gstStats-tab"
                                      data-toggle="tab"
                                      href="#gstStats"
                                      role="tab"
                                      aria-controls="gstStats"
                                      aria-selected="true"
                                      >GST</a
                                    >
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      [ngClass]="{ active: isPreStats }"
                                      (click)="statsTabClick('pregst')"
                                      id="pregstStats-tab"
                                      data-toggle="tab"
                                      href="#pregstStats"
                                      role="tab"
                                      aria-controls="pregstStats"
                                      aria-selected="false"
                                      >PRE GST</a
                                    >
                                  </li>
                                  <li class="nav-item">
                                    <a
                                      class="nav-link"
                                      [ngClass]="{ active: isDtStats }"
                                      (click)="statsTabClick('dt')"
                                      id="dtStats-tab"
                                      data-toggle="tab"
                                      href="#dtStats"
                                      role="tab"
                                      aria-controls="dtStats"
                                      aria-selected="false"
                                      >DT</a
                                    >
                                  </li>
                                </ul>
                                <div class="tab-content nopadding">
                                  <div
                                    class="tab-pane fade"
                                    [ngClass]="{ show: isGstStats, active: isGstStats }"
                                    id="gstStats"
                                    role="tabpanel"
                                    aria-labelledby="gstStats-tab"
                                  >
                                    <div class="row" *ngIf="isinLakh">
                                      <div class="table-responsive">
                                        <table class="table table-bordered">
                                          <tr class="bgGred">
                                            <th style="width: 201px" class="">Level</th>
                                            <th class="">
                                              Tax 
                                            </th>
                                            <th class="">
                                              Interest
                                            </th>
                                            <th class="">
                                              Penalty </th>
                                            <th class="">
                                              Total 
                                            </th>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.scn">
                                            <td class="tdGred">Pre-Litigation</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.scn.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.scn.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.scn.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.scn.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.adj">
                                            <td class="tdGred">Adjudication Stage</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.adj.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.adj.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.adj.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.adj.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.apa">
                                            <td class="tdGred">Commissioner (A)</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apa.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apa.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apa.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apa.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.apt">
                                            <td class="tdGred">Appellate Tribunal</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apt.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apt.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apt.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apt.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.hc">
                                            <td class="tdGred">High Court</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.hc.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.hc.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.hc.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.hc.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.sc">
                                            <td class="tdGred">Supreme Court</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.sc.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.sc.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.sc.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.sc.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr
                                            *ngIf="totalAmmounts"
                                            style="background: #ede8e8; font-weight: 600"
                                          >
                                            <td class="font-weight-bold tdGred">Total</td>
                                            <td class="txtRight font-weight-bold">
                                              {{ totalAmmounts?.totalTax | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ totalAmmounts?.totalInterest | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ totalAmmounts?.totalPenalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ totalAmmounts?.totOfTotal | INR : 2 }}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="row" *ngIf="isinCR">
                                      <div class="table-responsive">
                                        <table class="table table-bordered">
                                          <tr class="bgGred">
                                            <th style="width: 201px" class="">Level</th>
                                            <th class="">
                                              Tax 
                                            </th>
                                            <th class="">
                                              Interest 
                                            </th>
                                            <th class="">
                                              Penalty 
                                            </th>
                                            <th class="">
                                              Total 
                                            </th>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.scn">
                                            <td class="tdGred">Pre-Litigation</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.scn.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.scn.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.scn.penaltyInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.scn.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.adj">
                                            <td class="tdGred">Adjudication Stage</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.adj.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.adj.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.adj.penaltyInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.adj.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.apa">
                                            <td class="tdGred">Commissioner (A)</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apa.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.apa.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.apa.penaltyInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apa.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.apt">
                                            <td class="tdGred">Appellate Tribunal</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apt.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.apt.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.apt.penaltyInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.apt.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.hc">
                                            <td class="tdGred">High Court</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.hc.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.hc.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.hc.penaltyInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.hc.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="totalAmmounts?.sc">
                                            <td class="tdGred">Supreme Court</td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.sc.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.sc.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                totalAmmounts?.sc.penaltyInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{ totalAmmounts?.sc.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr
                                            *ngIf="totalAmmounts"
                                            style="background: #ede8e8; font-weight: 600"
                                          >
                                            <td class="font-weight-bold tdGred">Total</td>
                                            <td class="txtRight font-weight-bold">
                                              {{ totalAmmounts?.totalTaxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{
                                                totalAmmounts?.totalInterestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{
                                                totalAmmounts?.totalPenaltyInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{
                                                totalAmmounts?.totOfTotalInCr | INR : 2
                                              }}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="tab-pane fade"
                                    [ngClass]="{ show: isPreStats, active: isPreStats }"
                                    id="pregstStats"
                                    role="tabpanel"
                                    aria-labelledby="pregstStats-tab"
                                  >
                                    <div class="row" *ngIf="isinLakh">
                                      <div class="table-responsive">
                                        <table class="table table-bordered">
                                          <tr class="bgGred">
                                            <th style="width: 201px" class="">Level</th>
                                            <th class="">
                                              Tax 
                                            </th>
                                            <th class="">
                                              Interest 
                                            </th>
                                            <th class="">
                                              Penalty 
                                            </th>
                                            <th class="">
                                              Total 
                                            </th>
                                          </tr>

                                          <tr *ngIf="preGstTotal">
                                            <td class="tdGred">Adjudication Stage</td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.adj?.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.adj?.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.adj?.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.adj?.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="preGstTotal">
                                            <td class="tdGred">Commissioner (A)</td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apa?.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apa?.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apa?.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apa?.total | INR : 2 }}
                                            </td>
                                          </tr>

                                          <tr *ngIf="preGstTotal">
                                            <td class="tdGred">Appellate Tribunal</td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apt?.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apt?.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apt?.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apt?.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="preGstTotal">
                                            <td class="tdGred">High Court</td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.hc?.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.hc?.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.hc?.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.hc?.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="preGstTotal">
                                            <td class="tdGred">Supreme Court</td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.sc?.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.sc?.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.sc?.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.sc?.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr
                                            *ngIf="preGstTotal"
                                            style="background: #ede8e8; font-weight: 600"
                                          >
                                            <td class="font-weight-bold tdGred">Total</td>
                                            <td class="txtRight font-weight-bold">
                                              {{ preGstTotal?.totalTax | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ preGstTotal?.totalInterest | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ preGstTotal?.totalPenalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ preGstTotal?.totOfTotal | INR : 2 }}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="row" *ngIf="isinCR">
                                      <div class="table-responsive">
                                        <table class="table table-bordered">
                                          <tr class="bgGred">
                                            <th style="width: 201px" class="">Level</th>
                                            <th class="">
                                              Tax 
                                            </th>
                                            <th class="">
                                              Interest 
                                            </th>
                                            <th class="">
                                              Penalty 
                                            </th>
                                            <th class="">
                                              Total 
                                            </th>
                                          </tr>

                                          <tr *ngIf="preGstTotal">
                                            <td class="tdGred">Adjudication Stage</td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.adj.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                preGstTotal?.adj.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.adj.penaltyInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.adj.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="preGstTotal">
                                            <td class="tdGred">Commissioner (A)</td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apa.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                preGstTotal?.apa.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apa.penaltyInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apa.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>

                                          <tr *ngIf="preGstTotal">
                                            <td class="tdGred">Appellate Tribunal</td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apt.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                preGstTotal?.apt.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apt.penaltyInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.apt.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="preGstTotal">
                                            <td class="tdGred">High Court</td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.hc.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.hc.interestInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.hc.penaltyInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.hc.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="preGstTotal">
                                            <td class="tdGred">Supreme Court</td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.sc.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.sc.interestInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.sc.penaltyInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ preGstTotal?.sc.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr
                                            *ngIf="preGstTotal"
                                            style="background: #ede8e8; font-weight: 600"
                                          >
                                            <td class="font-weight-bold tdGred">Total</td>
                                            <td class="txtRight font-weight-bold">
                                              {{ preGstTotal?.totalTaxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{
                                                preGstTotal?.totalInterestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{
                                                preGstTotal?.totalPenaltyInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ preGstTotal?.totOfTotalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="tab-pane fade"
                                    [ngClass]="{ show: isDtStats, active: isDtStats }"
                                    id="dtStats"
                                    role="tabpanel"
                                    aria-labelledby="dtStats-tab"
                                  >
                                    <div class="row" *ngIf="isinLakh">
                                      <div class="table-responsive">
                                        <table class="table table-bordered">
                                          <tr class="bgGred">
                                            <th style="width: 201px" class="">Level</th>
                                            <th class="">
                                              Tax 
                                            </th>
                                            <th class="">
                                              Interest 
                                            </th>
                                            <th class="">
                                              Penalty
                                            </th>
                                            <th class="">
                                              Total 
                                            </th>
                                          </tr>

                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">Intimation Order</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.intimation.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.intimation.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.intimation.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.intimation.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">Assessment</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.assessment.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.assessment.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.assessment.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.assessment.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">Commissioner (A)</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.commissioner.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.commissioner.interest | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.commissioner.penalty | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.commissioner.total | INR : 2 }}
                                            </td>
                                          </tr>

                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">Appellate Tribunal</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.apt.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.apt.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.apt.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.apt.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">High Court</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.hc.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.hc.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.hc.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.hc.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">Supreme Court</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.sc.tax | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.sc.interest | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.sc.penalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.sc.total | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr
                                            *ngIf="dtTotal"
                                            style="background: #ede8e8; font-weight: 600"
                                          >
                                            <td class="font-weight-bold tdGred">Total</td>
                                            <td class="txtRight font-weight-bold">
                                              {{ dtTotal?.totalTax | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ dtTotal?.totalInterest | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ dtTotal?.totalPenalty | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ dtTotal?.totOfTotal | INR : 2 }}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                    <div class="row" *ngIf="isinCR">
                                      <div class="table-responsive">
                                        <table class="table table-bordered">
                                          <tr class="bgGred">
                                            <th style="width: 201px" class="">Level</th>
                                            <th class="">
                                              Tax 
                                            </th>
                                            <th class="">
                                              Interest 
                                            </th>
                                            <th class="">
                                              Penalty 
                                            </th>
                                            <th class="">
                                              Total 
                                            </th>
                                          </tr>

                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">Intimation Order</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.intimation.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.intimation.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.intimation.penaltyInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.intimation.totalInCr | INR : 2
                                              }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">Assessment</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.assessment.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.assessment.interestInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.assessment.penaltyInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.assessment.totalInCr | INR : 2
                                              }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">Commissioner (A)</td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.commissioner.taxInCr | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.commissioner.interestInCr
                                                  | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.commissioner.penaltyInCr
                                                  | INR : 2
                                              }}
                                            </td>
                                            <td class="txtRight">
                                              {{
                                                dtTotal?.commissioner.totalInCr | INR : 2
                                              }}
                                            </td>
                                          </tr>

                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">Appellate Tribunal</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.apt.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.apt.interestInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.apt.penaltyInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.apt.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">High Court</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.hc.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.hc.interestInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.hc.penaltyInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.hc.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr *ngIf="dtTotal">
                                            <td class="tdGred">Supreme Court</td>
                                            <td class="txtRight">
                                              {{ dtTotal?.sc.taxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.sc.interestInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.sc.penaltyInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight">
                                              {{ dtTotal?.sc.totalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                          <tr
                                            *ngIf="dtTotal"
                                            style="background: #ede8e8; font-weight: 600"
                                          >
                                            <td class="font-weight-bold tdGred">Total</td>
                                            <td class="txtRight font-weight-bold">
                                              {{ dtTotal?.totalTaxInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ dtTotal?.totalInterestInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ dtTotal?.totalPenaltyInCr | INR : 2 }}
                                            </td>
                                            <td class="txtRight font-weight-bold">
                                              {{ dtTotal?.totOfTotalInCr | INR : 2 }}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="grid-margin stretch-card"  *ngIf="navContext?.entityType == 'LEGAL' || navContext?.entityType == 'Business'">
                  <div
                    class="card"
                    style="margin-left: 1.375rem; box-shadow: 1px 2px 4px #ccd3e0"
                  >
                    <div class="card-body">
                      <p class="card-title">
                        <i
                          class="fa fa-file-excel-o me-2"
                          aria-hidden="true"
                          style="color: #119d59"
                        ></i
                        >CONSOLIDATED REPORTS
                      </p>
                      <div class="row">
                        <div  class="notifyDiv top-2" *ngIf="showPop" style="top: 65px; left: 0px">
                          <h2 class="report-header" *ngIf="!showHeader">MASTER REPORT </h2>
                          <h2 class="report-header" *ngIf="showHeader">CONTINGENCY REPORT </h2>

                          <i  class="settings-close mdi mdi-close"  (click)="closeDiv()"></i>

                          <h6 class="progTxt" ng-show="flag2PData.downloadInProgress" *ngIf="showStatus">
                            <i class="fa fa-refresh fa-spin" style="margin-right: 5px; color: orange;"
                              *ngIf="!showHeader && hideIcons"></i> 
                              <i class="fa fa-refresh" style="margin-right: 5px; color: orange;"
                              *ngIf="!showHeader && !hideIcons"></i>
                            <span style="line-height: 1.4;" *ngIf="!showHeader">Download started for
                              Master Report.</span>
                              <i class="fa fa-refresh fa-spin" style="margin-right: 5px; color: orange;"
                              *ngIf="hideIcons && showHeader "></i> 
                              <i class="fa fa-refresh" style="margin-right: 5px; color: orange;"
                              *ngIf="showHeader && !hideIcons"></i>
                             <span style="line-height: 1.3;" *ngIf="showHeader">Download started for
                              Contingency Report. </span>
                            <button class="reports" (click)="statusReport()"> Check Latest Status</button>
                          </h6>


                          <!-- <h6  class="progTxt" ng-show="flag2PData.downloadInProgress" *ngIf="showStatus">
                            <i class="fa fa-hourglass-start fs14 progIcon" style="margin-right: 5px; color: orange"></i>
                            <span style="line-height: 1.4" >Download started for Contingency
                              <span style="margin-left: 16px">Report</span>.</span>
                            <br />
                            <button class="reports" (click)="statusReport()">
                              Check Latest Status
                            </button>
                          </h6> -->

                          <h6 class="progTxt" ng-show="flag2PData.downloadCompleted" *ngIf="showDownload">
                            <i class="fa fa-check-square-o fs14 compIcon" style="margin-right: 5px; color: #025c48"
                              *ngIf="checkIcon" ></i>
                            <i class="fa fa-check-square-o fs14 compIcon" style="margin-right: 5px; color: #025c48"
                              *ngIf="!checkIcon" ></i>  Download Completed for Report.
                            <button class="report-link" (click)="downLoadMasterReport()"> Click to download </button>
                          </h6>
                        </div>
                        <a
                          class="iris-gst-pull-right mt-1 fs14 mr-3"
                          id="download"
                          title="Contingency Report"
                          (click)="generatedReport('Contingency')" *ngIf="1showHeader"
                          ><i class="fa fa-download clrOrange notifyIcon"></i>
                          <span class="heading-title">Contingency Report</span></a
                        >

                         <a
                          class="iris-gst-pull-right mt-1 fs14 mr-3"
                          id="download"
                          title="Master Report"
                          (click)="generatedMasterReport('Master')"  *ngIf="!showHeader1">
                          <i class="fa fa-download clrOrange notifyIcon"></i>
                          <span class="heading-title">Master Report</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>

                <div class="grid-margin stretch-card">
                  <div
                    class="card"
                    style="margin-left: 1.375rem; box-shadow: 1px 2px 4px #ccd3e0"
                  >
                    <div class="card-body car-bar">
                      <p class="card-title">News and Updates</p>
                      <div class="row">
                        <div class="col-md-12 justify-content-between mt-2 updates">
                          <ul class="dashList dashul">
                            <li *ngFor="let itr of newsList; let i = index">
                              <span
                                ><i class="fa fa-angle-double-right"></i>
                                {{ itr.newsHeader }}</span
                              >
                              <p>
                                {{ itr.newsBody }}
                              </p>
                              <p
                                *ngIf="
                                  itr.moreInfoType == 'MODAL' || itr.moreInfoType == 'URL'
                                "
                                class="knowMoreLink"
                              >
                                <a target="_blank" href="{{ itr.moreInfoExternalLink }}"
                                  >know more...</a
                                >
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="col-md-3"> -->

              <!-- </div> -->
            </div>
          </div>
          <!-- content-wrapper ends -->
          <!-- partial:partials/_footer.html -->

          <!-- partial -->
        </div>
        <!-- main-panel ends -->
      </div>
    </div>
  </div>
</div>
